import { Visitable } from '~src/data/store/visitors/visitable';
import {
  PrivateEquityFundStateVisitor,
  PrivateEquityFundVisitable,
  PrivateEquityFundVisitor,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-visitor';
import {
  ProjectObjStateVisitor,
  ProjectObjVisitable,
  ProjectObjVisitor,
} from '~src/data/store/visitors/workspace/project/project-obj-visitor';
import { parsePrivateEquityFundProject } from '~src/data/store/modules/workspaces/projects/private-equity-fund-projects/parser';
import {
  privateEquityFundProjectsActions,
  PrivateEquityFundProjectState,
} from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import {
  PrivateEquityFundProject,
  Project,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { ProjectType } from '@pladdenico/models';
import { Operation } from '@pladdenico/portfolio-api';

export const isPrivateEquityFundProject = (
  project: Project,
): project is PrivateEquityFundProject => {
  return project.project.type === ProjectType.PrivateEquityFund;
};

export interface PrivateEquityFundProjectVisitor {
  visit(privateEquityFundProject: PrivateEquityFundProjectVisitable): void;
  post(): void;
  projectObjVisitor: ProjectObjVisitor;
  privateEquityFundVisitor: PrivateEquityFundVisitor;
}

export class PrivateEquityFundProjectVisitable
  implements Visitable<PrivateEquityFundProjectVisitor>
{
  constructor(
    private _workspaceId: string,
    private _privateEquityFundProject: PrivateEquityFundProject,
  ) {}
  public accept(visitor: PrivateEquityFundProjectVisitor) {
    if (this._privateEquityFundProject.privateEquityFund) {
      const privateEquityFundVisitable = new PrivateEquityFundVisitable(
        this._workspaceId,
        this._privateEquityFundProject.privateEquityFund,
      );
      privateEquityFundVisitable.accept(visitor.privateEquityFundVisitor);
    }
    if (this._privateEquityFundProject.project) {
      const projectVisitable = new ProjectObjVisitable(
        this._workspaceId,
        this._privateEquityFundProject.project,
      );
      projectVisitable.accept(visitor.projectObjVisitor);
    }
    visitor.visit(this);
  }

  public parse(): PrivateEquityFundProjectState {
    return parsePrivateEquityFundProject(
      this._workspaceId,
      this._privateEquityFundProject,
    );
  }
}

export class PrivateEquityFundProjectHandlerVisitor
  implements PrivateEquityFundProjectVisitor
{
  constructor(
    private _handle: (holding: PrivateEquityFundProjectState) => void,
    public projectObjVisitor: ProjectObjVisitor,
    public privateEquityFundVisitor: PrivateEquityFundVisitor,
  ) {}
  public visit(holding: PrivateEquityFundProjectVisitable): void {
    this._handle(holding.parse());
  }
  public post() {
    return;
  }
}

export class PrivateEquityFundProjectStateVisitor extends PrivateEquityFundProjectHandlerVisitor {
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    super(
      (privateEquityFundProject) =>
        dispatch(
          privateEquityFundProjectsActions.upsertOneElement(
            privateEquityFundProject,
          ),
        ),
      new ProjectObjStateVisitor(
        dispatch,
        tenantId,
        workspaceId,
        subscriptions,
      ),
      new PrivateEquityFundStateVisitor(
        dispatch,
        tenantId,
        workspaceId,
        subscriptions,
      ),
    );
  }

  post() {
    this.privateEquityFundVisitor.post();
    this.projectObjVisitor.post();
  }
}
