import ReactGridLayout from 'react-grid-layout';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { PortfolioDashboardConfig } from '~src/services/graphql/workspace/client/graphql';

export function parsePortfolioDashboardConfig(
  portfolioConfigId: number,
  config: PortfolioDashboardConfig,
) {
  console.log('parsePortfolioDashboardConfig', config);
  const portfolioDashboardConfig: PortfolioDashboardConfigState = {
    id: config.id,
    portfolioConfigId,
    elementIds: config.elements ? config.elements.map((el) => el.id) : [],
    name: config.name,
    compactType: config.compactType as CompactType,
    layouts: config.layouts
      ? (JSON.parse(config.layouts) as ReactGridLayout.Layouts)
      : undefined,
  };
  return portfolioDashboardConfig;
}
