import {
  Link as RouterLink,
  Route,
  Routes,
  useMatches,
} from 'react-router-dom';
import { InvitationsBreadcrumbComponent } from '~src/domain/tenant/components/top-bar/breadcrumb/invitations-breadcrumb';
import { MembersBreadcrumbComponent } from '~src/domain/tenant/components/top-bar/breadcrumb/members-breadcrumb';
import { SettingsBreadcrumbComponent } from '~src/domain/tenant/components/top-bar/breadcrumb/settings-breadcrumb';
import { TenantBreadcrumbComponent } from '~src/domain/tenant/components/top-bar/breadcrumb/tenant-breadcrumb';

import Link, { LinkProps } from '@mui/material/Link';

import { WorkspacesBreadcrumbComponent } from './workspaces-breadcrumb';

// import { WorkspaceBreadcrumbComponent } from './workspace-breadcrumbs';

export const maxItems = 4;

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export function BreadcrumbsComponent() {
  const matches = useMatches();
  console.log(matches);

  return (
    <Routes>
      <Route
        path="workspaces/*"
        element={<WorkspacesBreadcrumbComponent last={true} />}
      />
      <Route
        path="members/*"
        element={<MembersBreadcrumbComponent last={true} />}
      />
      <Route
        path="settings/*"
        element={<SettingsBreadcrumbComponent last={true} />}
      />
      <Route
        path="invitations/*"
        element={<InvitationsBreadcrumbComponent last={true} />}
      />
      <Route path="/*" element={<TenantBreadcrumbComponent last={true} />} />
    </Routes>
  );
}
