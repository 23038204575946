import React from 'react';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';

export interface NordnetAccount {
  id: string | null;
  name: string;
}

interface Props {
  handleValue: (value: NordnetAccount) => void;
  accounts: NordnetAccount[];
  account: NordnetAccount | undefined;
  label?: string;
}

export const SelectAccount = (props: Props) => {
  const { account, handleValue, label, accounts } = props;
  const getOptionLabel = React.useCallback(
    (t: NordnetAccount) => {
      const accountId = accounts.find((account) => account?.id === t.id);
      return accountId?.name ?? '';
    },
    [accounts],
  );

  const isOptionEqualToValue = (
    option: NordnetAccount,
    value: NordnetAccount,
  ) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={false}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      options={accounts}
      value={account === null ? undefined : account}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
