import { strcmp } from '@pladdenico/common';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseProjectId {
  workspaceId: string;
  id: string;
}
// export type ProjectState = Project;

export type ProjectState = Omit<
  graphqlWorkspaceTypes.ProjectObj,
  'workspace' | 'holdings' | 'transactions'
> & {
  workspaceId: string;
};

export const initialState = [];

const selectKeyId = (project: BaseProjectId) => project.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseProjectId, b: BaseProjectId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseProjectId, ProjectState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const projectsSlice = createBaseRecordSlice('projects', adapter);
export const projectSelectors = adapter.selectors;

export const projectsReducer = projectsSlice.reducer;
export const projectsActions = projectsSlice.actions;
