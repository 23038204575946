import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TransferStatus } from '@pladdenico/models';

interface Props {
  handleValue: (status: TransferStatus) => void;
  status: TransferStatus;
  labelled: boolean;
}

export const EditStatusField = React.memo((props: Props) => {
  const handleSelectChange = (event: SelectChangeEvent<TransferStatus>) => {
    props.handleValue(event.target.value as TransferStatus);
  };

  const label = props.labelled ? (
    <InputLabel htmlFor="Type">Status</InputLabel>
  ) : undefined;

  return (
    <FormControl fullWidth margin="normal">
      {label}
      <Select
        value={props.status}
        onChange={handleSelectChange}
        inputProps={{
          name: 'status',
          id: 'status',
        }}
      >
        <MenuItem value={TransferStatus.confirmed}>Confirmed</MenuItem>
        <MenuItem value={TransferStatus.expected}>Expected</MenuItem>
        <MenuItem value={TransferStatus.cancelled}>Cancelled</MenuItem>
      </Select>
    </FormControl>
  );
});
