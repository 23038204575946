import { CryptoState } from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { Crypto } from '~src/services/graphql/workspace/client/graphql';

export function parseCrypto(
  workspaceId: string,
  assetId: string,
  crypto: Crypto,
): CryptoState {
  return {
    workspaceId,
    assetId,
    id: crypto.id,
    accountNumber: crypto.accountNumber,
    currencyId: crypto.currency && crypto.currency.id ? crypto.currency.id : '',
    bank: crypto.bank,
    symbol: crypto.symbol,
  };
}
