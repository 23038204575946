import {
  SchedulerStateVisitor,
  SchedulerVisitable,
} from '~src/data/store/visitors/workspace/project/report/scheduler/scheduler-visitor';
import { Scheduler } from '~src/services/graphql/workspace/client/graphql';

export function handleSchedulers(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Scheduler[],
) {
  const visitor = new SchedulerStateVisitor(dispatch);
  outputs.forEach((scheduler) => {
    const visitable = new SchedulerVisitable(workspaceId, scheduler);
    visitable.accept(visitor);
  });
  visitor.post();
  return outputs;
}
