import {
  fetchFundTrades,
  handleDeleteFundTrades,
} from '~src/data/store/modules/holdings/fund/trade/requests';
import { FundTradeState } from '~src/data/store/reducers/holding/holding-types/fund/fund-trade/reducer';
import { apis } from '~src/services/request/apis';

import { fundTradePath, Operation } from '@pladdenico/portfolio-api';

export function subscribeToFundTrades(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  trades: FundTradeState[],
  subscriptions: Operation[],
) {
  trades.forEach((trade) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: fundTradePath(tenantId, workspaceId).objectPath(
              trade.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchFundTrades(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: fundTradePath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchFundTrades(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: fundTradePath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteFundTrades(trade.fundHoldingId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      }
    });
  });
}
