import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getLoanById } from '~src/data/store/selectors/asset/asset-types/loans/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getLoanHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/loan/loan-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useLoanHoldingInfo(workspaceId: string, holdingId: string) {
  const loanHolding = useSelector((state: RootState) =>
    getLoanHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const loan = useSelector((state: RootState) =>
    getLoanById(selectWorkspaceData(state), {
      id: loanHolding ? loanHolding.loanId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: loan ? loan.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    loanHolding,
    asset,
    loan,
  };
}
