import moment from 'moment';
import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { EditHoldingById } from '~src/components/fields/workspace/holding/holding/edit-holding-by-id';
// import { IdComponent } from '~src/components/utils/id.component';
// import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FormValues } from '~src/domain/workspace/components/project/transaction/form/create-form.component';
// import { HoldingValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/field-config';
// import { eventParser } from '~src/utils/form/event-parser';
import { numberToNumberInput } from '~src/utils/form/input-util';

import { DatePicker } from '@mui/x-date-pickers';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ValuationStatus } from '@pladdenico/models';

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
  },
}));

export const useCreateValuationFieldsComponent = (
  workspace: WorkspaceState,
  projectId: string,
  showConfirmed: boolean,
  showHolding: boolean,
  // config?: HoldingValuationFieldConfig
) => {
  const { classes } = useStyles();

  // const handleChange =
  //   (name: keyof graphqlWorkspaceTypes.CreateValuationInputType) =>
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setValuationInput({
  //       ...valuationInput,
  //       [name]: eventParser(e),
  //     });
  //   };

  // const handleSelectChange = (event: SelectChangeEvent<number>) => {
  //   setValuationInput({
  //     ...valuationInput,
  //     [event.target.name as string]: event.target.value,
  //   });
  // };

  // const handleIdChange = (id: string) => {
  //   setValuationInput({ ...valuationInput, id });
  // };

  // const handleHoldingChange = (holding: HoldingState) => {
  //   if (holding) {
  //     setValuationInput({
  //       ...valuationInput,
  //       holdingId: holding.id,
  //     });
  //   }
  // };

  // const handleCurrencyChange = (currency: CurrencyState) => {
  //   if (currency) {
  //     setValuationInput({
  //       ...valuationInput,
  //       currencyId: currency.id,
  //     });
  //   }
  // };

  // const idElement = showId ? (
  //   <IdComponent id={valuationInput.id} setId={handleIdChange} />
  // ) : undefined;

  const holdingElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, `holdings.${number}.holdingId`>,
      _fieldState: ControllerFieldState,
    ) =>
      showHolding ? (
        <EditHoldingById
          handleValue={field.onChange}
          // handleValue={handleHoldingChange}
          projectId={projectId}
          // holding={holding}
          holdingId={field.value}
          workspace={workspace}
          label={'Holding'}
        />
      ) : undefined,
    [projectId, showHolding, workspace],
  );

  const dateElement = React.useCallback(
    (
      field: ControllerRenderProps<
        FormValues,
        `holdings.${number}.valuationInputData.date`
      >,
      fieldState: ControllerFieldState,
      disabled: boolean,
    ) => (
      <DatePicker
        showToolbar={false}
        inputFormat="DD/MM/YYYY hh:mm"
        mask="__/__/____ __:__"
        label="Date"
        value={field.value ?? null}
        // value={null}
        onChange={(date: moment.Moment | null) =>
          field.onChange(date?.toDate())
        }
        // disabled={config?.date?.disabled}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            margin="normal"
            fullWidth
            {...params}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
          />
        )}
      />
    ),
    [],
  );

  // <DatePicker
  //   showToolbar={false}
  //   inputFormat="DD/MM/YYYY hh:mm"
  //   mask="__/__/____ __:__"
  //   label="Date"
  //   onChange={field.onChange}
  //   value={field.value ?? ''}
  //   // value={moment(valuationInput.date)}
  //   // onChange={handleDateChange}
  //   renderInput={(params) => (
  //     <TextField margin="normal" fullWidth {...params} />
  //   )}
  //   disabled={config?.date?.disabled}
  //   // error={!!fieldState.error}
  //   // helperText={fieldState.error?.type ? 'Title is required' : undefined}
  // />;

  // const dateElement = (
  //   <DatePicker
  //     showToolbar={false}
  //     inputFormat="DD/MM/YYYY hh:mm"
  //     mask="__/__/____ __:__"
  //     label="Date"
  //     value={moment(valuationInput.date)}
  //     onChange={handleDateChange}
  //     renderInput={(params) => (
  //       <TextField margin="normal" fullWidth {...params} />
  //     )}
  //     disabled={config?.date?.disabled}
  //   />
  // );

  const confirmedElement = React.useCallback(
    (
      field: ControllerRenderProps<
        FormValues,
        `holdings.${number}.valuationInputData.status`
      >,
      _fieldState: ControllerFieldState,
    ) => {
      return showConfirmed ? (
        <Grid item sm={4}>
          <FormControl className={classes.textField} margin="normal">
            <InputLabel htmlFor="Type">Status</InputLabel>
            <Select
              value={field.value}
              onChange={field.onChange}
              inputProps={{
                name: 'status',
                id: 'status',
              }}
            >
              <MenuItem value={ValuationStatus.confirmed}>Confirmed</MenuItem>
              <MenuItem value={ValuationStatus.expected}>Expected</MenuItem>
              <MenuItem value={ValuationStatus.cancelled}>Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : undefined;
    },
    [classes.textField, showConfirmed],
  );

  const currencyElement = React.useCallback(
    (
      field: ControllerRenderProps<
        FormValues,
        `holdings.${number}.valuationInputData.currencyId`
      >,
      fieldState: ControllerFieldState,
    ) => (
      <EditCurrencyField
        handleValue={(currency) => field.onChange(currency.id)}
        currencyId={field.value ?? null}
        labelled={true}
        error={fieldState.error}
      />
    ),
    [],
  );

  const valueElement = React.useCallback(
    (
      field: ControllerRenderProps<
        FormValues,
        `holdings.${number}.valuationInputData.value`
      >,
      _fieldState: ControllerFieldState,
      disabled: boolean,
    ) => {
      return (
        <TextField
          margin="normal"
          fullWidth
          className={classes.textField}
          required
          id="value"
          // value={numberToNumberInput(valuationInput.value)}
          value={numberToNumberInput(field.value)}
          label="Value"
          type="number"
          // onChange={handleChange('value')}
          onChange={(event) => field.onChange(+event.target.value)}
          // disabled={config?.value?.disabled}
          disabled={disabled}
        />
      );
    },
    [classes.textField],
  );

  return {
    // idElement,
    holdingElement,
    dateElement,
    currencyElement,
    valueElement,
    confirmedElement,
  };
};
