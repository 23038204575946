import React, { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Theme } from '@mui/material';

export const useToolbarStyles = makeStyles()((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...(theme.mixins.toolbar as CSSProperties),
  },
}));

const useStyles = makeStyles()((theme: Theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

interface Props {
  desktopTopBar: JSX.Element;
  mobileTopBar: JSX.Element;
}

export const TopBarComponent = React.memo((props: Props) => {
  const { desktopTopBar, mobileTopBar } = props;
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.sectionDesktop}>{desktopTopBar}</div>
      <div className={classes.sectionMobile}>{mobileTopBar}</div>
    </>
  );
});
