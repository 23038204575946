import { GroupState } from '~src/data/store/modules/groups/state';
import { Group } from '~src/services/graphql/workspace/client/graphql';

export function parseGroup(group: Group): GroupState {
  return {
    id: group.id ? group.id : -1,
    name: group.name ? group.name : '',
    userIds: group.users
      ? group.users.map((user) => (user && user.id ? user.id : -1))
      : [],
  };
}
