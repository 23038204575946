import { Visitable } from '~src/data/store/visitors/visitable';
import { parseValuation } from '~src/data/store/modules/holdings/valuation/valuations/parser';
import {
  valuationsActions,
  ValuationState,
} from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { Valuation } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { subscribeToValuations } from '~src/data/store/modules/holdings/valuation/valuations/subscription';
import { Operation } from '@pladdenico/portfolio-api';

export interface ValuationVisitor {
  visit(valuation: ValuationVisitable): ValuationState;
  post(): void;
}

export class ValuationVisitable implements Visitable<ValuationVisitor> {
  constructor(
    private _holdingId: string,
    private _valuation: Valuation,
  ) {}
  public accept(visitor: ValuationVisitor) {
    return visitor.visit(this);
  }

  public parse(): ValuationState {
    const valuation = this._valuation;
    return parseValuation(this._holdingId, valuation);
  }
}

// export class ValuationHandlerVisitor implements ValuationVisitor {
//   constructor(private _handle: (valuation: ValuationState) => void) {}
//   visit(valuation: ValuationVisitable): ValuationState {
//     const valuationState = valuation.parse();
//     this._handle(valuationState);
//     return valuationState;
//   }
// }

// export class ValuationStateVisitor extends ValuationHandlerVisitor {
//   constructor(
//     dispatch: AppDispatch,
//     tenantId: string,
//     workspaceId: string,
//     subscriptions: Operation[]
//   ) {
//     super((valuation) => {
//       dispatch(valuationsActions.upsertOneElement(valuation));
//       subscribeToValuations(
//         dispatch,
//         tenantId,
//         workspaceId,
//         [valuation],
//         subscriptions
//       );
//     });
//   }
// }

export class ValuationStateVisitor implements ValuationVisitor {
  private _valuations: ValuationState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._valuations = [];
  }

  visit(visitable: ValuationVisitable): ValuationState {
    const valuation = visitable.parse();
    this._valuations.push(valuation);
    return valuation;
  }

  post() {
    this._dispatch(
      valuationsActions.upsertManyElements(this._valuations, {
        shouldAutobatch: true,
      }),
    );
    subscribeToValuations(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._valuations,
      this._subscriptions,
    );
  }
}
