import axios from 'axios';
import numbro from 'numbro';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { FileComponent } from '~src/components/utils/files/file.component';
import { fetchSignedUrls } from '~src/data/store/modules/obj-storage/objs/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { AppDispatch } from '~src/store/store';
import {
  primaryBackgroundColor,
  primaryFrontColor,
} from '~src/utils/common/colors/base-colors';

import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { graphqlObjStorageOperations } from '@pladdenico/obj-storage';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

const useStyles = makeStyles()((_theme: Theme) => ({
  base: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  activeStyle: {
    borderColor: '#2196f3',
  },
  acceptStyle: {
    borderColor: '#00e676',
  },
  rejectStyle: {
    borderColor: '#ff1744',
  },
}));

interface Props {
  baseId: string;
  showFiles?: boolean;
  callback: (
    id: string,
    name: string,
    description: string,
    url: string,
    type: string,
  ) => Promise<graphqlWorkspaceTypes.File[]>;
}

export const FileDropzoneComponent = (props: Props) => {
  const { callback, showFiles } = props;
  const [files, setFiles] = React.useState<
    Array<{ file: FileState; blob: string }>
  >([]);
  const dispatch = useDispatch<AppDispatch>();
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          if (reader) {
            const fileAsBuffer = reader.result;
            console.log(reader.result);
            if (fileAsBuffer) {
              const blob = new Blob([fileAsBuffer]);
              const fileBlob = URL.createObjectURL(blob);
              const id = `${props.baseId}${uuid.v1()}`;
              dispatch(
                fetchSignedUrls(
                  [id],
                  graphqlObjStorageOperations.ObjOperationType.Put,
                ),
              ).then((urls: Array<{ id: string; url: string }> | undefined) => {
                if (urls) {
                  const url = urls[0].url;
                  return axios
                    .put(url, blob)
                    .then(() => {
                      const sizeString = numbro(file.size).format({
                        output: 'byte',
                        mantissa: 2,
                        base: 'binary',
                        spaceSeparated: true,
                      });
                      return callback(
                        id,
                        file.name,
                        `${sizeString}`,
                        url,
                        file.type,
                      );
                    })
                    .then((files) => {
                      const file = files[0];
                      if (file) {
                        setFiles((files) => [
                          ...files,
                          { file, blob: fileBlob },
                        ]);
                      }
                    });
                }
              });
            }
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [callback, dispatch, props.baseId],
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const { classes, cx } = useStyles();

  const className = React.useMemo(() => {
    return cx(classes.base, {
      [classes.activeStyle]: isDragActive,
      [classes.acceptStyle]: isDragAccept,
      [classes.rejectStyle]: isDragReject,
    });
  }, [
    cx,
    classes.base,
    classes.activeStyle,
    classes.acceptStyle,
    classes.rejectStyle,
    isDragActive,
    isDragAccept,
    isDragReject,
  ]);

  const fileElements =
    showFiles &&
    files.map((file, idx) => {
      return <FileComponent key={idx} file={file.file} />;
    });
  const filesContainer =
    showFiles && files.length > 0 ? (
      <Box
        sx={{
          p: 1,
          backgroundColor: primaryBackgroundColor.light,
          color: primaryFrontColor.dark,
          // ':hover': {
          //   backgroundColor: primaryBackgroundColor.medium,
          // },
        }}
      >
        {fileElements}
      </Box>
    ) : null;

  return (
    <Box
      {...getRootProps({
        className,
      })}
      sx={{
        ':hover': {
          backgroundColor: primaryBackgroundColor.hoverLight,
        },
      }}
    >
      <input {...getInputProps()} />
      <p>Drag and drop files here, or click to select files</p>
      {filesContainer}
    </Box>
  );
};
