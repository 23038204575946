import React from 'react';
import {
  UseHoldingTabsProps,
  useHoldingTabs,
} from '~src/domain/workspace/components/project/portfolio/holdings/tab/use-holding-tabs';

export const HoldingTabsComponent = React.memo((props: UseHoldingTabsProps) => {
  const {
    holding,
    portfolio,
    portfolioProject,
    project,
    tab,
    tabs,
    workspace,
  } = props;
  return useHoldingTabs(
    workspace,
    portfolioProject,
    project,
    portfolio,
    holding,
    tabs,
    tab,
  );
});
