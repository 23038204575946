import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { getInvestmentsByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/investment/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { Grid, Typography } from '@mui/material';

import { InvestmentAvatarComponent } from './investment-avatar.component';

// import { ResponsiveContainer } from '~src/components/responsive/responsive.container';
// import i18n from '~src/i18n';
// const textStyles = require('~src/styles/text.css');

interface Props {
  fundProject: PrivateEquityFundProjectState;
}

export const InvestmentsComponent = React.memo((props: Props) => {
  const investments = useSelector((state: RootState) =>
    getInvestmentsByFundId(
      selectWorkspaceData(state),
      props.fundProject.privateEquityFundId,
    ),
  );
  const sortedInvestments = investments
    .filter((a) => !a.exit)
    .sort((a, b) => a.name.localeCompare(b.name));
  const sortedDivestments = investments
    .filter((a) => a.exit)
    .sort((a, b) => a.name.localeCompare(b.name));

  const investmentElements = sortedInvestments.map((investment) => {
    return (
      <Grid item xs={4} key={investment.id}>
        <InvestmentAvatarComponent
          workspaceId={props.fundProject.workspaceId}
          projectId={props.fundProject.projectId}
          investment={investment}
        />
      </Grid>
    );
  });

  const divestmentElements = sortedDivestments.map((investment) => {
    return (
      <Grid item xs={4} key={investment.id}>
        <InvestmentAvatarComponent
          workspaceId={props.fundProject.workspaceId}
          projectId={props.fundProject.projectId}
          investment={investment}
        />
      </Grid>
    );
  });

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Investments</Typography>
        </Grid>
        {investmentElements}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Divestments</Typography>
          {divestmentElements}
        </Grid>
      </Grid>
    </Grid>
  );
});
