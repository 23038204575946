import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

// import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createHoldings } from '~src/data/store/modules/holdings/base/requests';
import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export const useCreateHolding = (
  workspace: WorkspaceState,
  projectId: string,
  type: HoldingType,
) => {
  const [holdingInput, setHoldingInput] =
    React.useState<graphqlWorkspaceTypes.CreateHoldingInputType>({
      // HoldingInput
      id: uuid.v1(),
      description: '',
      name: '',
      projectId,
      type,
    });

  const dispatch = useDispatch<AppDispatch>();

  const handleCreateHolding = React.useCallback(() => {
    return dispatch(
      createHoldings(workspace.tenantId, workspace.id, [holdingInput]),
    ).then((holdings) => holdings[0]);
  }, [holdingInput, dispatch, workspace]);
  return { holdingInput, setHoldingInput, handleCreateHolding };
};
