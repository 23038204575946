import { MathJax } from 'better-react-mathjax';
import moment from 'moment';
import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { useForecastMenu } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-menu.component';
import { ExpressionElement } from '~src/domain/workspace/components/project/scenario/models/forecast/expression-element';
import { SequentialForecastEvaluation } from '~src/domain/workspace/components/project/scenario/models/forecast/sequential-forecast-evaluation';
import { Value } from '~src/domain/workspace/components/project/scenario/models/forecast/value';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Box, Button, colors, Grid, Paper, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  forecast: ForecastState;
  forecastExpressionElement: ExpressionElementState;
}

export const ForecastExpressionElementComponent = React.memo((props: Props) => {
  const { forecast, forecastExpressionElement: forecastElementState } = props;
  const { menuElement, menuButtonElement } = useForecastMenu(forecast);

  const forecastElement = React.useMemo(() => {
    return new ExpressionElement<Value>(
      forecast.id,
      forecast.name,
      forecastElementState.expressions,
      forecastElementState.variables,
      // element.correspondingHoldingId
    );
  }, [forecast.id, forecast.name, forecastElementState]);

  const metaString = React.useMemo(() => {
    const latex = forecastElement.getLatex();
    return latex.map((v, idx) => {
      return (
        <Box key={idx} sx={{ m: 3 }}>
          <MathJax>{v}</MathJax>
        </Box>
      );
    });
  }, [forecastElement]);

  const [values, setValues] = React.useState<Value[]>([]);

  return (
    <Box sx={{ mb: 1, background: colors.blue[200] }}>
      <Grid container padding={1}>
        <Grid item xs={3}>
          <Typography>{forecast.name}</Typography>
          <Typography variant="caption">
            <MathJax>{metaString}</MathJax>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {menuButtonElement}
          {menuElement}
          <Button
            variant="outlined"
            sx={{ mt: 1, mr: 2 }}
            onClick={() => {
              if (forecastElement == null) {
                return;
              }
              const evaluator = new SequentialForecastEvaluation<Value>([
                forecastElement,
              ]);
              const startDate = moment();
              const endDate = moment(startDate).add(1, 'year');

              const values = evaluator.evaluate(startDate, endDate);
              setValues(values);
              console.log(values);
            }}
          >
            Evaluate element
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Grid container sx={{ m: 1 }}>
              <Grid item xs={4}>
                <Typography>Date</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Transfer</Typography>
              </Grid>
              <Grid item xs={4}>
                Valuation
              </Grid>
            </Grid>
            {values.map((value, idx) => {
              return (
                <Box key={idx}>
                  <Grid container sx={{ m: 1 }}>
                    <Grid item xs={4}>
                      <Typography>{value.date.format('DD.MM.YYYY')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {value.transfer?.type}: {value.transfer?.value}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{value.valuation?.value}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});
