import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { GraphQLError } from 'graphql';
import { GraphqlResultHandler } from './graphql-result-handler';

export class GraphqlResultSimpleHandler<
  TQuery,
  TQueryVariables,
> extends GraphqlResultHandler<TQuery, TQueryVariables> {
  constructor() {
    super();
  }

  public pre(
    _node: TypedDocumentNode<TQuery, TQueryVariables>,
    _variables: TQueryVariables,
  ): void {
    // console.log('PRE');
  }

  public handleErrors(errors: readonly GraphQLError[]): GraphQLError[] {
    return [...errors];
  }

  public handleSuccess(data: TQuery): TQuery {
    return data;
  }

  public handleDone(): void {
    return;
  }

  public handleEmpty(): Error {
    console.log('NO ERRORS AND NO DATA');
    return { name: 'EmptyError', message: 'No errors and no data' };
    // return undefined;
  }
}
