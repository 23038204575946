import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from 'react-hook-form';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { useTransferCostField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-cost-field';
import { useTransferCurrencyField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-currency-field';
import { useTransferDateField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-date-field';
import { useTransferStatusField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-status-field';
import { useTransferTypeField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-type-field';
import { useTransferValueField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-value-field';

import { Grid } from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

interface Props<T extends FieldValues> {
  projectId: string;
  holding: HoldingState;
  control: Control<T>;
  getName: (name: Path<TransferInputData>) => Path<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: TransferFieldConfig;
  transferTypes?: TransferType[];
}

export const LoanTransferFieldsControlledComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { control, fieldConfig, getName, transferTypes } = props;

  const { element: typeElement, visible: typeVisible } = useTransferTypeField(
    control,
    getName('base.type'),
    undefined,
    fieldConfig?.base.type,
    transferTypes,
  );

  const { element: dateElement, visible: dateVisible } = useTransferDateField(
    control,
    getName('base.date'),
    new Date() as PathValue<T, Path<T>>,
    fieldConfig?.base.date,
  );
  const { element: currencyElement, visible: currencyVisible } =
    useTransferCurrencyField(
      control,
      getName('base.currencyId'),
      undefined,
      fieldConfig?.base.currency,
    );
  const { element: valueElement, visible: valueVisible } =
    useTransferValueField(
      control,
      getName('base.value'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.base.value,
    );
  const { element: costElement, visible: costVisible } = useTransferCostField(
    control,
    getName('base.cost'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.base.cost,
  );
  const { element: statusElement, visible: statusVisible } =
    useTransferStatusField(
      control,
      getName('base.status'),
      undefined,
      fieldConfig?.base.status,
    );

  return (
    <>
      <Grid container columnSpacing={1}>
        {typeVisible && (
          <Grid item sm={4}>
            {typeElement}
          </Grid>
        )}
        {dateVisible && (
          <Grid item sm={4}>
            {dateElement}
          </Grid>
        )}
        {currencyVisible && (
          <Grid item sm={4}>
            {currencyElement}
          </Grid>
        )}

        {costVisible && (
          <Grid item sm={4}>
            {costElement}
          </Grid>
        )}

        {valueVisible && (
          <Grid item sm={4}>
            {valueElement}
          </Grid>
        )}
        {statusVisible && (
          <Grid item sm={4}>
            {statusElement}
          </Grid>
        )}
      </Grid>
    </>
  );
};
