import { TenantRoleState } from '~src/data/store/reducers/tenant/tenant-role/tenant-roles/reducer';
import { TenantRole } from '~src/services/graphql/tenant/client/graphql';

// export function parseGroup(
//   tenantRoleId: string,
//   group: graphqlTenantTypes.Group
// ) {
//   return {
//     id: group.id,
//     name: group?.name,
//     type: group?.type,
//     tenantRoleId: tenantRoleId,
//   };
// }

// export function parseGroups(
//   tenantRoleId: string,
//   groups: graphqlTenantTypes.Group[] | null | undefined
// ) {
//   return groups
//     ? groups.map((group) => {
//         return parseGroup(tenantRoleId, group);
//       })
//     : [];
// }

export function parseTenantRole(tenantRole: TenantRole): TenantRoleState {
  return {
    id: tenantRole.id,
    name: tenantRole.name,
    permission: tenantRole.permission,
    groupId: tenantRole.group?.id,
    tenantId: tenantRole.tenant?.id,
  };
}
