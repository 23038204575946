import {
  initialPrivateEquityFundProjectsState,
  privateEquityFundProjectsReducer,
} from './private-equity-fund-projects/reducer';
import { combineReducers } from 'redux';
import {
  privateEquityFundProjectRequestStatusReducer,
  initialPrivateEquityFundProjectRequestStatusState,
} from './fetch/reducer';

export const initialPrivateEquityFundProjectState = {
  privateEquityFundProjects: initialPrivateEquityFundProjectsState,
  privateEquityFundProjectFetch:
    initialPrivateEquityFundProjectRequestStatusState,
};

export const privateEquityFundProjectReducer = combineReducers({
  privateEquityFundProjects: privateEquityFundProjectsReducer,
  privateEquityFundProjectFetch: privateEquityFundProjectRequestStatusReducer,
});
