import moment from 'moment';
import React from 'react';
import { NodeTypes, ReactFlowProvider } from 'reactflow';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { FlowEvaluator } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-evaluator';
import { initialInterestRateFlow } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/initialization/variable/interest/interest-rate';
import { InitialNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/initial-node';
import { InputNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/input-node';
import { OutputNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/output-node';
import { VariableNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/variable-node';
import { useFlowDrag } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/use-flow-drag';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useForecastMenu } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-menu.component';
import { VariableValue } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/variable-value';
import { useEvaluationState } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/use-evaluation-state';

interface Props {
  scenario: Scenario;
  budget: Budget;
  forecast: ForecastState;
  forecastElement: FlowElementState;
}

export const ScenarioVariableNumberFlowComponent = React.memo(
  (props: Props) => {
    return (
      <ReactFlowProvider>
        <_ScenarioVariableNumberFlowComponent {...props} />
      </ReactFlowProvider>
    );
  },
);

export const _ScenarioVariableNumberFlowComponent = (props: Props) => {
  const { scenario, budget, forecastElement, forecast } = props;
  const menu = useForecastMenu(forecast);

  const nodeTypes: NodeTypes = React.useMemo(() => {
    return {
      initial: InitialNode,
      output: OutputNode,
      inputType: InputNode,
      variable: VariableNode,
    };
  }, []);

  const { element: evaluationStateElement, setEvaluationState } =
    useEvaluationState();

  const initialFlow = initialInterestRateFlow;
  const { edges, flowComponent, nodes } = useFlowDrag(
    scenario,
    budget,
    forecast,
    forecastElement,
    nodeTypes,
    initialFlow,
  );

  const evaluate = React.useCallback(() => {
    try {
      const flowEvaluator = new FlowEvaluator(edges, nodes);
      flowEvaluator.prepare();
      const startDate = moment();
      const endDate = moment(startDate).add(1, 'year');
      const numberOfDaysInPeriod = endDate.diff(startDate, 'days') + 1;
      const values = new Array<VariableValue>(numberOfDaysInPeriod);
      for (let i = 0; i < numberOfDaysInPeriod; ++i) {
        const date = moment(startDate).add(i, 'day');
        const value = flowEvaluator.evaluate(values, date, i);
        if (value) {
          values[i] = { ...(value as VariableValue), date };
        }
        console.log(i + 1, numberOfDaysInPeriod);
      }
      setEvaluationState(values);
    } catch (e) {
      console.log('error evaluate', e);
    }
  }, [edges, nodes, setEvaluationState]);
  const flowElement = React.useMemo(() => {
    return (
      <Box sx={{ display: 'flex', height: 500, width: '100%' }}>
        {flowComponent}
      </Box>
    );
  }, [flowComponent]);

  return (
    <Grid container>
      <Grid xs={12} sx={{ mb: 1, ml: 1 }}>
        <Button variant="outlined" onClick={evaluate}>
          Evaluate
        </Button>
        {menu.menuButtonElement}
        {menu.menuElement}
      </Grid>
      <Grid xs={12}>{flowElement}</Grid>
      {evaluationStateElement}
    </Grid>
  );
};
