import React from 'react';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { FormulaFieldComponent } from './formula-field.component';

interface Props {
  stockGoalRuleInput: graphqlWorkspaceTypes.CreateStockGoalRuleInputType;
  setStockGoalRuleInput: (
    stockGoalRuleInput: graphqlWorkspaceTypes.CreateStockGoalRuleInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const StockGoalRuleFieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChangeGoalRule =
    (name: keyof graphqlWorkspaceTypes.GoalRuleInputType) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.setStockGoalRuleInput({
        ...props.stockGoalRuleInput,
        goalRule: {
          ...props.stockGoalRuleInput.goalRule,
          [name]: eventParser(e),
        },
      });
    };

  return (
    <>
      {/* <TextField
        margin='normal'
        className={classes.textField}
        required
        id='sharePrice'
        value={props.stockGoalRuleInfoInput.sharePrice}
        label='Share price'
        type='number'
        onChange={handleChange('sharePrice')}/> */}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.stockGoalRuleInput.goalRule.name}
        label="Name"
        type="text"
        onChange={handleChangeGoalRule('name')}
      />
      <FormulaFieldComponent
        setStockGoalRuleInput={props.setStockGoalRuleInput}
        stockGoalRuleInput={props.stockGoalRuleInput}
      />
      {/* <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.stockGoalRuleInput.goalRule.name}
        label="Name"
        type="text"
        onChange={handleChangeGoalRule('name')}
      /> */}
    </>
  );
});
