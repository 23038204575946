import { handleEntities } from '~src/data/store/modules/entities/entities/handler';
import { entitiesActions } from '~src/data/store/reducers/entity/entities/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchAndHandleEntities(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  return (dispatch: AppDispatch) => {
    return fetchEntities(tenantId, workspaceId, ids).then((entities) => {
      return handleEntities(dispatch, tenantId, workspaceId, entities, [
        Operation.create,
        Operation.delete,
        Operation.update,
        Operation.upsert,
      ]);
    });
  };
}

export function fetchEntities(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  const node = graphqlWorkspaceTypes.GetEntitiesDocument;
  const variables: graphqlWorkspaceTypes.GetEntitiesQueryVariables = {
    ids,
  };
  const context: QueryWorkspaceContext = {
    type: QueryContextTypes.workspace,
    tenantId,
    workspaceId,
  };
  return investRequest(node, variables, context).then((data) => {
    if (data.getEntities) {
      return data.getEntities;
    }
    return [];
  });
}

export function createEntities(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateEntityInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateEntitiesDocument;
    const variables: graphqlWorkspaceTypes.CreateEntitiesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createEntities) {
        return handleEntities(
          dispatch,
          tenantId,
          workspaceId,
          data.createEntities,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function updateEntities(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateEntityInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateEntitiesDocument;
    const variables: graphqlWorkspaceTypes.UpdateEntitiesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateEntities) {
        return handleEntities(
          dispatch,
          tenantId,
          workspaceId,
          data.updateEntities,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function upsertEntities(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertEntityInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertEntitiesDocument;
    const variables: graphqlWorkspaceTypes.UpsertEntitiesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertEntities) {
        return handleEntities(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertEntities,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function handleDeleteEntities(workspaceId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      entitiesActions.removeManyElements(
        ids.map((p) => {
          return {
            workspaceId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteEntities(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.DeleteEntityInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteEntitiesDocument;
    const variables: graphqlWorkspaceTypes.DeleteEntitiesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteEntities) {
        dispatch(
          entitiesActions.removeManyElements(
            data.deleteEntities.map((id) => {
              return { id, workspaceId };
            }),
          ),
        );
      }
      return [];
    });
  };
}
