import React from 'react';
import { makeStyles } from 'tss-react/mui';
import * as uuid from 'uuid';
import {
  CompactType,
  DashboardGrid,
} from '~src/components/dashboard/dashboard-grid.component';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { useAddDashboardElement } from '~src/domain/workspace/components/common/dashboard/element/use-add-dashboard-element.hook';
import { useDashboardElements } from '~src/domain/workspace/components/common/dashboard/element/use-dashboard-elements.hook';
import { useActiveElement } from '~src/domain/workspace/components/common/dashboard/hooks/use-active-element.hook';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { UsageMode } from '~src/utils/interfaces/usage-mode';
import { Box, Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  configButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },

  components: {
    position: 'relative',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

export const useComponents = <
  Item extends {
    type: string;
  },
>(
  compactType: CompactType,
  usageMode: UsageMode,
  dashboardElements: DashboardElementState[],
  dashboardComponent: (
    active: boolean,
    setActive: (id: string) => void,
    setInactive: (id: string) => void,
    remove: (id: string) => void,
    update: (
      id: string,
      element: Partial<DashboardElementState>,
    ) => Promise<graphqlWorkspaceTypes.DashboardElement>,
    element: DashboardElementState,
  ) => JSX.Element,
  layouts: ReactGridLayout.Layouts | undefined,
  updateLayouts:
    | ((
        layouts: ReactGridLayout.Layouts,
      ) => Promise<ReactGridLayout.Layouts | undefined>)
    | undefined,
  items: Item[],
  addDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.DashboardElement[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>,
  removeDashboardElements?: (
    dashboardElementIds: string[],
  ) => Promise<string[]>,
  updateDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>,
) => {
  const { classes } = useStyles();
  const { elements, add, remove, update } = useDashboardElements({
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
    dashboardElements,
  });

  const { active, setActive, setInactive } = useActiveElement();

  const addElement = React.useCallback(
    (element: DashboardElementState) => {
      return add([element]).then((elements) => {
        const element = elements[0];
        setActive(element.id);
        return element;
      });
    },
    [add, setActive],
  );

  const removeElement = React.useCallback(
    (id: string) => {
      return remove([id]);
    },
    [remove],
  );

  const updateElement = React.useCallback(
    (id: string, data: Partial<DashboardElementState>) => {
      return update([{ id, data }]).then((elements) => {
        return elements[0];
      });
    },
    [update],
  );

  const { open, dialog } = useAddDashboardElement(items, addElement);

  const createGridElements = React.useCallback(() => {
    // console.log('Create Grid elements called');
    return elements.map((element) => {
      const isActive = element.id === active;
      const component = dashboardComponent(
        isActive,
        setActive,
        setInactive,
        removeElement,
        updateElement,
        element,
      );
      return (
        <Box
          key={element.id}
          sx={{ border: element.config.border ?? '1px solid black' }}
          // data-grid={{ isResizable: usageMode === UsageMode.edit }}
        >
          {component}
        </Box>
      );
    });
  }, [
    active,
    dashboardComponent,
    elements,
    removeElement,
    setActive,
    setInactive,
    updateElement,
  ]);

  const onNewItem = React.useCallback(() => {
    return addElement({ id: uuid.v1(), name: '', config: {}, type: 'header' });
  }, [addElement]);

  const saveLayouts = React.useCallback(
    (layouts: ReactGridLayout.Layouts) => {
      if (updateLayouts) {
        return updateLayouts(layouts);
      }
      return Promise.resolve(layouts);
    },
    [updateLayouts],
  );

  const grid = React.useMemo(() => {
    return (
      <DashboardGrid
        compactType={compactType}
        isResizable={usageMode === UsageMode.edit}
        createGridElements={createGridElements}
        existingLayouts={layouts}
        onNewItem={onNewItem}
        saveLayouts={saveLayouts}
        droppableElement={false}
      />
    );
  }, [
    compactType,
    createGridElements,
    layouts,
    onNewItem,
    saveLayouts,
    usageMode,
  ]);

  const container = React.useMemo(
    () => (
      <Box
        className={classes.components}
        style={{
          height: '100%',
        }}
      >
        {grid}
      </Box>
    ),
    [classes.components, grid],
  );

  return {
    container,
    dialog,
    open,
  };
};
