import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { ImgDropzoneComponent } from '~src/components/utils/image/img-dropzone';
import { createEntityMedias } from '~src/data/store/modules/entities/media/requests';
import { createFiles } from '~src/data/store/modules/file/file/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  entityId: string;
}
const imageBaseId = (
  tenantId: string,
  workspaceId: string,
  entityId: string,
) => {
  return `${tenantId}/${workspaceId}/${entityId}/`;
};

export const EntityMediaDropzoneComponent = React.memo((props: Props) => {
  const { entityId, workspace } = props;
  const imageIdBase = React.useMemo(
    () => imageBaseId(workspace.tenantId, workspace.id, entityId),
    [entityId, workspace.id, workspace.tenantId],
  );
  const dispatch = useDispatch<AppDispatch>();
  const imgCallback = React.useCallback(
    (
      id: string,
      name: string,
      description: string,
      url: string,
      type: string,
    ) => {
      return dispatch(
        createFiles(workspace.tenantId, workspace.id, [
          {
            id,
            key: url,
            name,
            type,
            description,
          },
        ]),
      ).then((files) => {
        const file = files[0];
        return dispatch(
          createEntityMedias(workspace.tenantId, workspace.id, [
            {
              entityId,
              id: uuid.v1(),
              fileId: file.id,
            },
          ]),
        ).then(() => files);
      });
    },
    [dispatch, entityId, workspace.id, workspace.tenantId],
  );
  return (
    <>
      <ImgDropzoneComponent baseId={imageIdBase} callback={imgCallback} />
    </>
  );
});
