import { PhoneState } from '~src/data/store/reducers/person/phones/reducer';
import { Phone } from '~src/services/graphql/workspace/client/graphql';

export function parsePhone(
  workspaceId: string,
  personId: string,
  phone: Phone,
): PhoneState {
  return {
    id: phone.id,
    workspaceId,
    personId,
    value: phone.value,
  };
}
