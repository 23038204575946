import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { handleInputData } from '~src/services/xlsx/handlers/iinput-handler';
import { bankNoteHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/bank-note-holdings/mapper';
import { bondHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/bond-holdings/mapper';
import { cryptoHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/crypto-holdings/mapper';
import { fundHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/fund-holdings/mapper';
import { loanHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/loan-holdings/mapper';
import { holdingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/mapper';
import { stockHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/mapper';
import { stockGoalRuleImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/stock-goal-rules/mapper';
import { stockPositionImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/stock-positions/mapper';
import { portfolioHoldingImportHandler } from '~src/services/xlsx/handlers/workspaces/portfolios/portfolio-holdings/mapper';
import { valuationImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/valuations/mapper';
import { DataImporter } from '~src/services/xlsx/hooks/use-import';
import { StatusUpdater } from '~src/services/xlsx/hooks/use-update-status';
import { AppDispatch } from '~src/store/store';

export const importer: DataImporter = (
  workspace: WorkspaceState,
  data: Map<string, any[]>,
  statusUpdater: StatusUpdater,
) => {
  return (dispatch: AppDispatch) => {
    return handleInputData(
      data,
      holdingImportHandler(workspace, dispatch),
      statusUpdater,
    ).then(() => {
      const holdingPromises: Promise<boolean>[] = [];
      holdingPromises.push(
        handleInputData(
          data,
          stockHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => {
          const stockHoldingPromises: Promise<boolean>[] = [];
          stockHoldingPromises.push(
            handleInputData(
              data,
              stockPositionImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          stockHoldingPromises.push(
            handleInputData(
              data,
              stockGoalRuleImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          return Promise.all(stockHoldingPromises).then(() => true);
        }),
      );
      holdingPromises.push(
        handleInputData(
          data,
          bondHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      holdingPromises.push(
        handleInputData(
          data,
          fundHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      holdingPromises.push(
        handleInputData(
          data,
          bankNoteHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      holdingPromises.push(
        handleInputData(
          data,
          cryptoHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      holdingPromises.push(
        handleInputData(
          data,
          loanHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      holdingPromises.push(
        handleInputData(
          data,
          valuationImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      holdingPromises.push(
        handleInputData(
          data,
          portfolioHoldingImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
        // handleInputDataOLD<Portfolio>(data, 'portfolioHoldings', (ts) =>
        //   dispatch(upsertPortfolios(workspace.tenantId, workspace.id, ts))
        // )
      );
      return Promise.all(holdingPromises).then(() => true);
    });
  };
};
