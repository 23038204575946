import 'reactflow/dist/style.css';
import '../../index.css';

import React from 'react';
import { Node } from 'reactflow';
import { OnNodeDataChanges } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-store';
import {
  BaseNodeData,
  InitialNodeData,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Divider, TextField } from '@mui/material';
import { useExpressionFields } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/nodes/expression-fields';

interface Props {
  scenario: Scenario;
  node: Node<InitialNodeData>;
  onNodesDataChange: OnNodeDataChanges;
}

export const InitialNodeConfig = (props: Props) => {
  const { node, onNodesDataChange } = props;

  const updateData = React.useCallback(
    (data: BaseNodeData) => {
      onNodesDataChange([
        {
          change: {
            id: node.id,
            data,
          },
        },
      ]);
    },
    [node, onNodesDataChange],
  );

  const { addExpressionElement, expressionsElement, metaString } =
    useExpressionFields(node, updateData);

  const nodeConfigElement = React.useMemo(() => {
    return (
      <div className="updatenode__controls">
        <TextField
          margin="normal"
          label="Label"
          variant="outlined"
          value={node.data.label}
          onChange={(evt) => {
            const data = {
              ...node.data,
              label: evt.target.value,
            };
            updateData(data);
          }}
        />
        <TextField
          margin="normal"
          label="Initialization"
          variant="outlined"
          value={node.data.initialization}
          onChange={(evt) => {
            const data = {
              ...node.data,
              initialization: evt.target.value,
            };
            updateData(data);
          }}
        />
        <Divider />
        <div>Evaluator</div>
        <label>expression</label>
        {expressionsElement}
        {addExpressionElement}
        <div>{metaString}</div>
      </div>
    );
  }, [
    addExpressionElement,
    expressionsElement,
    metaString,
    node.data,
    updateData,
  ]);

  return nodeConfigElement;
};
