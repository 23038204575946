import React from 'react';
import { useSelector } from 'react-redux';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardElementsByIds } from '~src/data/store/selectors/common/dashboard/element/selectors';
import { getPortfolioConfigByPortfolioId } from '~src/data/store/selectors/portfolio/portfolio-config/portfolio-configs/selectors';
import { getPortfolioDashboardConfigsByPortfolioConfigId } from '~src/data/store/selectors/portfolio/portfolio-config/portfolio-dashboard-configs/selectors';
import {
  selectViewData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { AppDispatch } from '~src/store/store';

import { Button } from '@mui/material';
import { usePortfolioConfigActions } from '~src/hooks/actions/workspace/project/portfolio/portfolio-config/use-portfolio-config-actions.hook';

interface Props {
  workspace: WorkspaceState;
  portfolio: PortfolioState;
}

export const useDashboardConfig = (props: Props) => {
  const { workspace, portfolio } = props;
  // const [initialized, setInitialized] = React.useState(false);
  // const dispatch = useDispatch<AppDispatch>();
  // React.useEffect(() => {
  //   dispatch(
  //     fetchPortfolioConfigs(workspace.tenantId, workspace.id, portfolio.id),
  //   ).then(() => {
  //     setInitialized(true);
  //   });
  // }, [dispatch, portfolio.id, workspace.id, workspace.tenantId]);

  const portfolioConfig = useSelector((state: RootState) =>
    getPortfolioConfigByPortfolioId(selectWorkspaceData(state), portfolio.id),
  );
  const portfolioDashboardConfigs = useSelector((state: RootState) => {
    if (portfolioConfig) {
      return getPortfolioDashboardConfigsByPortfolioConfigId(
        selectWorkspaceData(state),
        portfolioConfig.id,
      );
    }
    return undefined;
  });
  const portfolioDashboardConfig =
    portfolioConfig && portfolioDashboardConfigs
      ? portfolioDashboardConfigs.find(
          (c) => c.id === portfolioConfig.currentDashboardConfigId,
        )
      : undefined;

  const dashboardElements = useSelector((state: RootState) => {
    return getDashboardElementsByIds(
      selectViewData(state),
      portfolioDashboardConfig?.elementIds,
    );
  });

  // const { persist } = usePersistPortfolioDashboardConfig();
  // const { create } = useConfig();
  const { create } = usePortfolioConfigActions();

  const createDashboardConfigElement = React.useMemo(() => {
    // if (!initialized) {
    //   return null;
    // }

    return (
      <div>
        <Button
          onClick={() =>
            create(
              workspace,
              portfolio.id,
              // portfolioConfig,
              // dashboardConfig,
              // []
            )
          }
        >
          Create dashboard config
        </Button>
      </div>
    );
  }, [create, portfolio.id, workspace]);
  return {
    portfolioConfig,
    portfolioDashboardConfig,
    dashboardElements,
    createDashboardConfigElement,
  };
};
