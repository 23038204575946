import { Navigate, RouteObject } from 'react-router-dom';
import { MainComponent } from '~src/domain/user/app/main.component';
import { LoginComponent } from '~src/domain/user/components/login/login.component';
import { ForgotPasswordComponent } from '~src/domain/user/components/password/forgot-password.component';
import { ResetPasswordComponent } from '~src/domain/user/components/password/reset-password.component';
import { SignupComponent } from '~src/domain/user/components/signup/signup.component';
import { UserWrapper } from '~src/domain/user/components/user/params-wrapper';
import { UserTabs } from '~src/domain/user/components/user/tab/user-tabs.component';
import {
  userLoginBasePath,
  userResetPasswordBasePath,
  userSignupBasePath,
  userTabPath,
  // userTenantsBasePath,
} from '~src/navigation/paths/user/paths';

const RedirectToBase = () => {
  return <Navigate to={userTabPath(UserTabs.Profile)} replace={true} />;
};

export const useRoot = (): RouteObject[] => {
  return [
    {
      path: `user/${userSignupBasePath}`,
      element: (
        <MainComponent>
          <SignupComponent />
        </MainComponent>
      ),
    },
    // {
    //   path: `user/${userTenantsBasePath}`,
    //   element: (
    //     <MainComponent>
    //       <TenantsComponent />
    //     </MainComponent>
    //   ),
    // },
    {
      path: `user/${userLoginBasePath}`,
      element: (
        <MainComponent>
          <LoginComponent />
        </MainComponent>
      ),
    },
    {
      path: `user/${userLoginBasePath}`,
      element: (
        <MainComponent>
          <SignupComponent />
        </MainComponent>
      ),
    },
    {
      path: `user/${userResetPasswordBasePath}`,
      element: (
        <MainComponent>
          <ResetPasswordComponent />
        </MainComponent>
      ),
    },
    {
      path: `user/${userResetPasswordBasePath}`,
      element: (
        <MainComponent>
          <ForgotPasswordComponent />
        </MainComponent>
      ),
    },
    {
      path: 'user/*',
      element: <RedirectToBase />,
    },
    {
      path: 'user/:tabId/*',
      element: (
        <MainComponent>
          <UserWrapper />
        </MainComponent>
      ),
    },
  ];
};
