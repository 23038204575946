import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  exportsSelectors,
  ExportState,
} from '~src/data/store/reducers/workspace/exports/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ExportHandler } from '~src/services/xlsx/handlers/ioutput-handler';
import { Fetcher, useFetcher } from '~src/services/xlsx/hooks/use-fetcher';

export const useExport = (
  workspace: WorkspaceState,
  dataFetcher: Fetcher,
  getHandlers: (
    workspace: WorkspaceState,
    state: ExportState | undefined,
  ) => ExportHandler<any>[] | undefined,
) => {
  const { fetcher, exportId } = useFetcher(workspace, dataFetcher);
  const exportState = useSelector((state: RootState) =>
    exportsSelectors.selectElement(
      state.data.workspace.workspace.exports.exports,
      { id: exportId },
    ),
  );
  const handlers = getHandlers(workspace, exportState);

  return {
    handlers,
    fetcher,
  };
};
