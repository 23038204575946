import { parsePrivateEquityFund } from '~src/data/store/modules/assets/private-equity-fund/private-equity-funds/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  privateEquityFundsActions,
  PrivateEquityFundState,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import {
  Asset,
  PrivateEquityFund,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { AssetType } from '@pladdenico/models';

export interface PrivateEquityFundVisitor {
  visit(privateEquityFund: PrivateEquityFundVisitable): PrivateEquityFundState;
  post(): void;
}

export const isPrivateEquityFund = (
  asset: Asset,
): asset is PrivateEquityFund => {
  return asset._type === AssetType.PrivateEquityFund;
};

export class PrivateEquityFundVisitable
  implements Visitable<PrivateEquityFundVisitor>
{
  constructor(
    private _workspaceId: string,
    private _privateEquityFund: PrivateEquityFund,
  ) {}
  public accept(visitor: PrivateEquityFundVisitor) {
    return visitor.visit(this);
  }

  public parse(): PrivateEquityFundState {
    const assetId = this._privateEquityFund.asset?.id ?? '';
    return parsePrivateEquityFund(
      this._workspaceId,
      assetId,
      this._privateEquityFund,
    );
  }
}

export class PrivateEquityFundStateVisitor implements PrivateEquityFundVisitor {
  private _privateEquityFunds: PrivateEquityFundState[];
  constructor(private _dispatch: AppDispatch) {
    this._privateEquityFunds = [];
  }

  public visit(
    privateEquityFund: PrivateEquityFundVisitable,
  ): PrivateEquityFundState {
    const privateEquityFundParsed = privateEquityFund.parse();
    this._privateEquityFunds.push(privateEquityFundParsed);
    return privateEquityFundParsed;
  }

  post() {
    this._dispatch(
      privateEquityFundsActions.upsertManyElements(this._privateEquityFunds),
    );
  }
}
