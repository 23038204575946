import { DataState, RootState } from '../reducers/reducers';

export const selectData = (state: RootState) => {
  return state.data;
};

export const selectUserDataFromData = (state: DataState) => {
  return state.user;
};

export const selectTenantDataFromData = (state: DataState) => {
  return state.tenant;
};

export const selectViewData = (state: RootState) => {
  return state.view;
};

export const selectWorkspaceDataFromData = (state: DataState) => {
  return state.workspace;
};

export const selectRequestDataFromData = (state: DataState) => {
  return state.request;
};

export const selectFinancialDataFromData = (state: DataState) => {
  return state.financial;
};

export const selectDataImportDataFromData = (state: DataState) => {
  return state.dataImport;
};

export const selectRequestData = (state: RootState) => {
  return selectRequestDataFromData(selectData(state));
};

export const selectUserData = (state: RootState) => {
  return selectUserDataFromData(selectData(state));
};

export const selectTenantData = (state: RootState) => {
  return selectTenantDataFromData(selectData(state));
};

export const selectWorkspaceData = (state: RootState) => {
  return selectWorkspaceDataFromData(selectData(state));
};

export const selectFinancialData = (state: RootState) => {
  return selectFinancialDataFromData(selectData(state));
};

export const selectDataImportData = (state: RootState) => {
  return selectDataImportDataFromData(selectData(state));
};
