import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertTransactions } from '~src/data/store/modules/holdings/transactions/transactions/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'transactions';

export interface Transaction {
  id: string;
  projectId: string;
  title?: Maybe<string>;
  description?: Maybe<string>;
}

const header: Array<keyof Transaction> = [
  'id',
  'title',
  'description',
  'projectId',
];

export const transactionExportHandler = (
  data: Transaction[],
): ExportHandler<Transaction> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const transactionImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Transaction> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertTransactions(workspace.tenantId, workspace.id, ts)),
  };
};
