export const SortOrders = {
  Increasing: 'increasing',
  Decreasing: 'decreasing',
} as const;

export type SortOrder = (typeof SortOrders)[keyof typeof SortOrders];
export function typeToString(type: SortOrder) {
  if (type === SortOrders.Increasing) {
    return 'Increasing';
  } else if (type === SortOrders.Decreasing) {
    return 'Decreasing';
  }
  return 'undefined';
}
