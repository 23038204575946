import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import {
  deleteRegionAllocations,
  updateRegionAllocations,
} from '~src/data/store/modules/entities/region-allocations/requests';

import { RegionAllocationData } from './region-allocations-table.component';
// import { updateRegionAllocations, deleteRegionAllocations } from '~src/store/modules/holdings/regionAllocations/index';
import {} from './region-allocations.component';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function useRegionAllocationTableActions(
  tenantId: string,
  workspaceId: string,
) {
  const dispatch = useDispatch<AppDispatch>();

  const onRowUpdate = (
    oldData: RegionAllocationData,
    newData: RegionAllocationData,
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.UpdateRegionAllocationInputType = {
          id: newData.id,
          entityId: newData.entityId,
          regionId: newData.regionId,
          date: newData.date,
          allocation: newData.allocation,
        };

        return dispatch(updateRegionAllocations(tenantId, workspaceId, [input]))
          .then(() => resolve())
          .catch(() => reject());
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (oldData: RegionAllocationData) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deleteRegionAllocations(tenantId, workspaceId, [
          {
            id: oldData.id,
            entityId: oldData.entityId,
          },
        ]),
      )
        .then(() => resolve())
        .catch(() => reject());
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onRowUpdate, onRowDelete };
}
