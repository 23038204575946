import { HoldingType } from '@pladdenico/models';
import { parseCryptoHolding } from '~src/data/store/modules/holdings/crypto/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  cryptoHoldingsActions,
  CryptoHoldingState,
} from '~src/data/store/reducers/holding/holding-types/crypto/crypto-holding/reducer';
import {
  CryptoHolding,
  Holding,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export const isCryptoHolding = (holding: Holding): holding is CryptoHolding => {
  return holding.holding.type === HoldingType.Crypto;
};

export interface CryptoHoldingVisitor {
  visit(cryptoHolding: CryptoHoldingVisitable): CryptoHoldingState;
  post(): void;
}

export class CryptoHoldingVisitable implements Visitable<CryptoHoldingVisitor> {
  constructor(private _cryptoHolding: CryptoHolding) {}
  public accept(visitor: CryptoHoldingVisitor) {
    return visitor.visit(this);
  }

  public parse(): CryptoHoldingState {
    return parseCryptoHolding(this._cryptoHolding);
  }
}

export class CryptoHoldingStateVisitor implements CryptoHoldingVisitor {
  private _cryptoHoldings: CryptoHoldingState[];
  constructor(private _dispatch: AppDispatch) {
    this._cryptoHoldings = [];
  }
  public visit(holding: CryptoHoldingVisitable): CryptoHoldingState {
    const cryptoHolding = holding.parse();
    this._cryptoHoldings.push(cryptoHolding);
    return cryptoHolding;
  }
  post() {
    this._dispatch(
      cryptoHoldingsActions.upsertManyElements(this._cryptoHoldings, {
        shouldAutobatch: true,
      }),
    );
  }
}
