import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { TenantTabsComponent } from '~src/domain/tenant/components/tab/tenant-tabs.component';
import { useTenantTabs } from '~src/domain/tenant/components/tab/use-tenant-tabs';

interface PropsWithTenant {
  tenant: TenantState;
}

export const TenantWrapperWithTab = React.memo((props: PropsWithTenant) => {
  const { tenant } = props;
  const tabs = useTenantTabs();
  const { tabId } = useParams<{ tabId: string }>();
  console.log(tabId);
  const tab = tabs.find((t) => t.name === tabId);

  return tab ? (
    <TenantTabsComponent tenant={tenant} tab={tab} tabs={tabs} />
  ) : (
    <div>unknown tab</div>
  );
});
