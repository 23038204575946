import { Visitable } from '~src/data/store/visitors/visitable';
import { parseTransfer } from '~src/data/store/modules/holdings/transfers/transfers/parser';
import {
  transfersActions,
  TransferState,
} from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { Transfer } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { subscribeToTransfers } from '~src/data/store/modules/holdings/transfers/transfers/subscription';
import { Operation } from '@pladdenico/portfolio-api';
// import { groupBy } from '@pladdenico/common';

export interface TransferVisitor {
  visit(visitable: TransferVisitable): void;
  post(): void;
}

export class TransferVisitable implements Visitable<TransferVisitor> {
  constructor(
    private _holdingId: string,
    private _transfer: Transfer,
  ) {}
  public accept(visitor: TransferVisitor) {
    visitor.visit(this);
  }

  public parse(): TransferState {
    const transfer = this._transfer;
    return parseTransfer(this._holdingId, transfer);
  }
}

export class TransferHandlerVisitor implements TransferVisitor {
  constructor(private _handle: (transfer: TransferState) => void) {}
  visit(visitable: TransferVisitable): void {
    this._handle(visitable.parse());
  }
  post() {
    return;
  }
}

export class TransferStateVisitor implements TransferVisitor {
  private _transfers: TransferState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._transfers = [];
  }

  visit(visitable: TransferVisitable): TransferState {
    const transfer = visitable.parse();
    this._transfers.push(transfer);
    return transfer;
  }

  post() {
    this._dispatch(
      transfersActions.upsertManyElements(this._transfers, {
        shouldAutobatch: true,
      }),
    );

    // const transferByHolding = groupBy(this._transfers, (t) => t.holdingId);

    // transferByHolding.forEach((transfers, key) => {
    //   this._dispatch({
    //     type: 'holdings',
    //     payload: {
    //       id: key,
    //       action: transfersActions.upsertManyElements(transfers),
    //     },
    //   });
    // });

    subscribeToTransfers(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._transfers,
      this._subscriptions,
    );
  }
}
