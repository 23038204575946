import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  selectedForecastsSelectors,
  BaseSelectedForecastId,
  SelectedForecastState,
} from '~src/data/store/reducers/workspace/projects/scenario/forecasts/selected/reducer';

const selectSelectedForecastState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.forecasts.selectedForecasts;

export const getSelectedForecastById = createCachedSelector(
  (state: WorkspaceDataState) => selectSelectedForecastState(state),
  (_state: WorkspaceDataState, id: BaseSelectedForecastId) => id,
  (forecasts, id): SelectedForecastState | undefined => {
    return selectedForecastsSelectors.selectElementByT(forecasts, id);
  },
)({
  keySelector: (_state, id) => `${id.budgetId}-${id.objectId}`,
  selectorCreator: createSelector,
});

export const getSelectedForecastsByIds = createCachedSelector(
  (state: WorkspaceDataState) => selectSelectedForecastState(state),
  (_state: WorkspaceDataState, ids: BaseSelectedForecastId[]) => ids,
  (forecasts, ids): SelectedForecastState[] | undefined => {
    return selectedForecastsSelectors.selectElementsByTs(forecasts, ids);
  },
)({
  keySelector: (_state, ids) => `${JSON.stringify(ids)}`,
  selectorCreator: createSelector,
});

export const getSelectedForecastByBudgetId = createCachedSelector(
  (state: WorkspaceDataState) => selectSelectedForecastState(state),
  (_state: WorkspaceDataState, budgetId: string) => budgetId,
  (forecasts, budgetId): SelectedForecastState[] | undefined => {
    return selectedForecastsSelectors.selectElementsByKey(forecasts, budgetId);
  },
)({
  keySelector: (_state, budgetId) => `${budgetId}`,
  selectorCreator: createSelector,
});
