import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import React from 'react';
import { EditFund } from '~src/components/fields/workspace/asset/edit/asset-types/edit-fund';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FundHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/fund/create-action';

interface Props {
  workspace: WorkspaceState;
  fundHoldingInput: FundHoldingInputType;
  setFundHoldingInput: (
    fundHoldingInput: graphqlWorkspaceTypes.CreateFundHoldingInputType,
  ) => void;
}

export const CreateFundHoldingFieldsComponent = React.memo((props: Props) => {
  const handleFundChange = (fundId: string) => {
    props.setFundHoldingInput({
      ...props.fundHoldingInput,
      fundId,
    });
  };

  return (
    <>
      <EditFund
        workspace={props.workspace}
        handleValue={handleFundChange}
        fundId={props.fundHoldingInput.fundId}
      />
    </>
  );
});
