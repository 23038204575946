import {
  WorkspaceStateVisitor,
  WorkspaceVisitable,
} from '~src/data/store/visitors/workspace/workspace-visitor';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';
import {
  Workspace,
  WorkspaceBaseWithRoles,
} from '~src/services/graphql/workspace/client/graphql';

export type WorkspaceOutput =
  | Omit<Workspace, '__typename'>
  | Omit<WorkspaceBaseWithRoles, '__typename'>;

export function handleWorkspace(
  dispatch: AppDispatch,
  tenantId: string,
  output: WorkspaceOutput,
  subscriptions: Operation[],
) {
  const workspaceVisitor = new WorkspaceStateVisitor(
    dispatch,
    tenantId,
    output.id,
    subscriptions,
  );
  const workspaceVisitable = new WorkspaceVisitable(tenantId, output);
  workspaceVisitable.accept(workspaceVisitor);
  workspaceVisitor.post();
  return output;
}
