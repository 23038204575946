import { compact, flatten } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  transactionsActions,
  TransactionState,
} from '~src/data/store/reducers/workspace/projects/scenario/transactions/reducer';
import {
  transfersActions,
  TransferState,
} from '~src/data/store/reducers/workspace/projects/scenario/transfers/reducer';
import {
  valuationsActions,
  ValuationState,
} from '~src/data/store/reducers/workspace/projects/scenario/valuations/reducer';
import { VariableState } from '~src/data/store/reducers/workspace/projects/scenario/variables/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getBudgetById } from '~src/data/store/selectors/workspace/projects/scenario/budgets/selectors';
import { getForecastsByIds } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/forecast/selectors';
import { getSelectedForecastByBudgetId } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/selected/selectors';
import { getVariablesByScenarioId } from '~src/data/store/selectors/workspace/projects/scenario/variables/selectors';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { useVariables } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-variables';
import { ForecastTypes } from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';
// import { ForecastElement } from '~src/domain/workspace/components/project/scenario/models/forecast/forecast-element';
import { ExpressionElement } from '~src/domain/workspace/components/project/scenario/models/forecast/expression-element';
import { FlowElement } from '~src/domain/workspace/components/project/scenario/models/forecast/flow-element';
import { ForecastEvaluator } from '~src/domain/workspace/components/project/scenario/models/forecast/forecast-evaluator';
import { ManualElement } from '~src/domain/workspace/components/project/scenario/models/forecast/manual-element';
import { Value } from '~src/domain/workspace/components/project/scenario/models/forecast/value';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { AppDispatch } from '~src/store/store';

import { TransferType } from '@pladdenico/models';

const getCorrespondingBankNoteTransferType = (type: TransferType) => {
  if (type === TransferType.Borrow) {
    return TransferType.Withdrawal;
  } else if (type === TransferType.Buy) {
    return TransferType.Withdrawal;
  } else if (type === TransferType.Cost) {
    return TransferType.Withdrawal;
  } else if (type === TransferType.Deposit) {
    return TransferType.Withdrawal;
  } else if (type === TransferType.Dividend) {
    return TransferType.Deposit;
  } else if (type === TransferType.Interest) {
    return TransferType.Deposit;
  } else if (type === TransferType.Lend) {
    return TransferType.Withdrawal;
  } else if (type === TransferType.Provision) {
    return TransferType.Deposit;
  } else if (type === TransferType.Repayment) {
    return TransferType.Deposit;
  } else if (type === TransferType.RevenueFee) {
    return TransferType.Deposit;
  } else if (type === TransferType.Sell) {
    return TransferType.Deposit;
  } else if (type === TransferType.Withdrawal) {
    return TransferType.Deposit;
  } else if (type === TransferType.WrongPayment) {
    return TransferType.Deposit;
  }
  return type;
};

export const getForecastElement = (
  forecast: ForecastState,
  variables: VariableState[] | undefined,
  getVariableValue?: (object_id: string, idx: number) => any | undefined,
) => {
  const element = forecast.element;
  if (element.type === ForecastTypes.Expression) {
    const e = element as ExpressionElementState;
    return new ExpressionElement<Value>(
      forecast.id,
      forecast.name,
      e.expressions,
      e.variables,
    );
  } else if (element.type === ForecastTypes.Manual) {
    const e = element as ManualElementState;
    return new ManualElement<Value>(
      forecast.id,
      forecast.name,
      e.values.map((v) => {
        return {
          date: moment.unix(v.unixDate),
          transfer: v.transfer,
          valuation: v.valuation,
        };
      }),
    );
  } else if (element.type === ForecastTypes.Flow) {
    const e = element as FlowElementState;
    return new FlowElement<Value>(
      forecast.id,
      forecast.name,
      e.flow,
      variables,
      getVariableValue,
    );
  }
};

export const useEvaluateForecast = (scenario: Scenario) => {
  const [values, setValues] = React.useState<Value[]>([]);

  const budget = useSelector((state: RootState) =>
    getBudgetById(selectWorkspaceData(state), {
      id: scenario.budgetId,
      scenarioId: scenario.id,
    }),
  );
  const selectedForecastIds = useSelector((state: RootState) => {
    if (budget) {
      return getSelectedForecastByBudgetId(
        selectWorkspaceData(state),
        budget.id,
      );
    }
  });

  const forecasts = useSelector((state: RootState) => {
    if (budget && selectedForecastIds) {
      return getForecastsByIds(selectWorkspaceData(state), selectedForecastIds);
    }
    return [];
  });
  // const forecastElements = useSelector((state: RootState) => {
  //   return getElementsByForecasts(selectWorkspaceData(state), forecasts);
  // });
  const dispatch = useDispatch<AppDispatch>();

  const variables = useSelector((state: RootState) => {
    return getVariablesByScenarioId(selectWorkspaceData(state), scenario.id);
  });

  const period = React.useMemo(() => {
    const startDate = moment();
    const endDate = moment(startDate).add(1, 'year');
    const numberOfDaysInPeriod = endDate.diff(startDate, 'days') + 1;
    return {
      startDate,
      endDate,
      numberOfDaysInPeriod,
    };
  }, []);

  const { evaluate: evaluateVariables, getVariableValue } = useVariables(
    scenario,
    budget,
    period.numberOfDaysInPeriod,
  );

  const forecastElements = React.useMemo(() => {
    return compact(
      forecasts.map((forecast) => {
        const element = getForecastElement(
          forecast,
          variables,
          getVariableValue,
        );
        if (element) {
          return {
            holdingId: forecast.objectId,
            element,
          };
        }
      }),
    );
  }, [forecasts, getVariableValue, variables]);

  const forecastEvaluators = React.useMemo(() => {
    return forecastElements.map((forecastElement) => {
      const evaluator = new ForecastEvaluator(
        forecastElement.element,
        period.numberOfDaysInPeriod,
      );
      return { evaluator, id: forecastElement.holdingId };
    });
  }, [forecastElements, period.numberOfDaysInPeriod]);

  const evaluate = React.useCallback(() => {
    // const elements: Array<{
    //   holdingId: string;
    //   element: ForecastElement<Value>;
    // }> = compact(
    //   forecasts.map((forecast) => {
    //     const element = getForecastElement(forecast, variables);
    //     if (element) {
    //       return {
    //         holdingId: forecast.objectId,
    //         element,
    //       };
    //     }
    //   })
    // );
    if (budget) {
      dispatch(transfersActions.clearRecord(budget.id));
      dispatch(transactionsActions.clearRecord(budget.id));
      dispatch(valuationsActions.clearRecord(budget.id));
    }
    const valuations: ValuationState[] = [];
    const transfers: TransferState[] = [];
    const transactions: TransactionState[] = [];

    for (let i = 0; i < period.numberOfDaysInPeriod; ++i) {
      const date = moment(period.startDate).add(i, 'day');
      evaluateVariables(date, i);
      for (let j = 0; j < forecastEvaluators.length; ++j) {
        forecastEvaluators[j].evaluator.evaluate(date, i);
      }
    }

    const values = flatten(
      forecastEvaluators.map((evaluator) => {
        return evaluator.evaluator.values();
      }),
    );
    forecastEvaluators.forEach((evaluator) => {
      const values = evaluator.evaluator.values();
      values.forEach((value) => {
        const transactionId = uuid.v1();
        const elementTransfers: TransferState[] = [];
        const correspondingBankNoteTransfers: TransferState[] = [];
        if (value.transfer) {
          const transfer: TransferState = {
            budgetId: budget?.id ?? '',
            currencyId: '',
            date: value.date,
            holdingId: evaluator.id,
            id: uuid.v1(),
            // id: value.transfer.id,
            type: value.transfer.type,
            value: value.transfer.value,
            transactionId,
          };
          elementTransfers.push(transfer);

          const correspondingBankNoteHoldingId = '__TBD__';
          const type: TransferType = getCorrespondingBankNoteTransferType(
            value.transfer.type,
          );
          const correspondingBankNoteTransfer = {
            budgetId: budget?.id ?? '',
            currencyId: '',
            date: value.date,
            holdingId: correspondingBankNoteHoldingId,
            id: uuid.v1(),
            type,
            value: value.transfer.value,
            transactionId,
          };
          correspondingBankNoteTransfers.push(correspondingBankNoteTransfer);
          const transaction: TransactionState = {
            description: '',
            id: transactionId,
            title: '',
            transferIds: [transfer.id, correspondingBankNoteTransfer.id],
            budgetId: budget?.id ?? '',
          };
          transactions.push(transaction);
          transfers.push(...elementTransfers);
        }
        const valuation: ValuationState = {
          budgetId: budget?.id ?? '',
          currencyId: '',
          date: value.date,
          holdingId: evaluator.id,
          // id: value.valuation.id,
          id: uuid.v1(),
          value: value.valuation.value,
        };
        valuations.push(valuation);
      });

      // const elementValuations = vs.map((value) => {
      //   const valuation: ValuationState = {
      //     budgetId: budget?.id ?? '',
      //     currencyId: '',
      //     date: value.date,
      //     holdingId: element.holdingId,
      //     id: value.valuation.id,
      //     value: value.valuation.value,
      //   };
      //   return valuation;
      // });
      // valuations.push(...elementValuations);
    });

    // const values2 = flatten(
    //   forecastEvaluators.map((evaluator) => {
    //     // const evaluator = new SequentialForecastEvaluation<Value>([
    //     //   element.element,
    //     // ]);
    //     // const startDate = moment();
    //     // const endDate = moment(startDate).add(1, 'year');
    //     // const vs = evaluator.evaluator.evaluate(
    //     //   period.startDate,
    //     //   period.endDate
    //     // );
    //     const elementTransfers: TransferState[] = [];
    //     const correspondingBankNoteTransfers: TransferState[] = [];

    //     const transactionId = uuid.v1();
    //     vs.forEach((value) => {
    //       if (value.transfer) {
    //         const transfer: TransferState = {
    //           budgetId: budget?.id ?? '',
    //           currencyId: '',
    //           date: value.date,
    //           holdingId: element.holdingId,
    //           id: value.transfer.id,
    //           type: value.transfer.type,
    //           value: value.transfer.value,
    //           transactionId,
    //         };
    //         elementTransfers.push(transfer);

    //         const correspondingBankNoteHoldingId = '__TBD__';
    //         const type: TransferType = getCorrespondingBankNoteTransferType(
    //           value.transfer.type
    //         );
    //         const correspondingBankNoteTransfer = {
    //           budgetId: budget?.id ?? '',
    //           currencyId: '',
    //           date: value.date,
    //           holdingId: correspondingBankNoteHoldingId,
    //           id: uuid.v1(),
    //           type,
    //           value: value.transfer.value,
    //           transactionId,
    //         };
    //         correspondingBankNoteTransfers.push(correspondingBankNoteTransfer);
    //         const transaction: TransactionState = {
    //           description: '',
    //           id: transactionId,
    //           title: '',
    //           transferIds: [transfer.id, correspondingBankNoteTransfer.id],
    //           budgetId: budget?.id ?? '',
    //         };
    //         transactions.push(transaction);
    //       }
    //     });
    //     transfers.push(...elementTransfers);

    //     const elementValuations = vs.map((value) => {
    //       const valuation: ValuationState = {
    //         budgetId: budget?.id ?? '',
    //         currencyId: '',
    //         date: value.date,
    //         holdingId: element.holdingId,
    //         id: value.valuation.id,
    //         value: value.valuation.value,
    //       };
    //       return valuation;
    //     });
    //     valuations.push(...elementValuations);

    //     return vs;
    //   })
    // );
    setValues(values);
    dispatch(transfersActions.upsertManyElements(transfers));
    dispatch(transactionsActions.upsertManyElements(transactions));
    dispatch(valuationsActions.upsertManyElements(valuations));

    console.log('VALUES', values);
  }, [
    budget,
    dispatch,
    evaluateVariables,
    forecastEvaluators,
    period.numberOfDaysInPeriod,
    period.startDate,
  ]);

  return {
    evaluate,
    values,
  };
};
