import * as React from 'react';
import { useMenu } from '~src/hooks/utils/use-menu.hook';

import { Menu, MenuItem } from '@mui/material';

export const useWorkspaceInvitationMenu = () => {
  const { anchorEl, handleClose, handleOpen, isOpen } = useMenu();

  const menu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      onClose={handleClose}
    >
      <MenuItem>Archive message</MenuItem>
    </Menu>
  );
  return {
    menu,
    handleOpen,
    handleClose,
    isOpen,
  };
};
