import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceSettingsRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/settings`;
};

export const workspaceSettingsBasePath = workspaceSettingsRootPath(
  tenantBaseId,
  workspaceBaseId,
);
