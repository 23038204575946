import * as React from 'react';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import { EditRegionField } from '~src/components/fields/common/edit-region-field.component';
import { ViewRegionField } from '~src/components/fields/common/view-region-field.component';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';

export type RegionAllocationData = RegionAllocationState;

export function useRegionAllocationFields(
  _workspaceId: string,
): TableField.Field<RegionAllocationData>[] {
  const fields: TableField.Field<RegionAllocationData>[] = [
    // {
    //   id: 'id',
    //   name: 'id',
    //   field: 'id',
    //   type: TableField.FieldTypes.string,
    //   iteratee: (data) => data.id,
    // },
    {
      id: 'region',
      name: 'region',
      field: 'region',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.regionId,
      update: (data, region: RegionState) => {
        return {
          ...data,
          regionId: region.id,
        };
      },
      renderView: (editProps) => {
        return <ViewRegionField regionId={editProps.data.regionId} />;
      },
      renderEdit: (editProps) => {
        return (
          <EditRegionField
            handleValue={editProps.onChange}
            regionId={editProps.data.regionId}
            labelled={false}
          />
        );
      },
    },
    {
      id: 'date',
      name: 'date',
      field: 'date',
      type: TableField.FieldTypes.date,
      iteratee: (data) => data.date,
      update: (data, date: Date) => {
        return { ...data, date };
      },
      renderEdit: (editProps) => {
        return (
          <EditDateField
            handleValue={editProps.onChange}
            date={editProps.data.date}
            labelled={false}
          />
        );
      },
    },
    {
      id: 'allocation',
      name: 'allocation',
      field: 'allocation',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.allocation,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        return { ...data, allocation: eventParser(event) as number };
      },
      renderView: (viewProps) => {
        return <>{viewProps.data.allocation}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="allocation"
            value={editProps.data.allocation}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
    },
  ];
  return fields;
}
