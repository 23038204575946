import { useDispatch } from 'react-redux';
import {
  deleteValuations,
  updateValuations,
} from '~src/data/store/modules/holdings/valuation/valuations/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ValuationData } from '~src/domain/workspace/components/project/valuation/valuations.component';
import { CreateValuationInputType } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { ValuationStatus } from '@pladdenico/models';

export function useValuationActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (oldData: ValuationData, newData: ValuationData) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const oldValuation = oldData.base?.valuation;
        const newValuation = newData.base?.valuation;
        if (oldValuation !== newValuation && newValuation) {
          const input: CreateValuationInputType = {
            id: newValuation.id,
            holdingId: newValuation.holdingId,
            currencyId: newValuation.currencyId,
            date: newValuation.date,
            // holdingType: newValuation.holdingType,
            ownershipPercentage: newValuation.ownershipPercentage ?? 0,
            status: newValuation.status ?? ValuationStatus.confirmed,
            value: newValuation.value ?? 0,
          };
          dispatch(updateValuations(workspace.tenantId, workspace.id, [input]))
            .then((_valuations) => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject();
        }
      }
    });
  };

  const onRowDelete = (oldData: ValuationData) => {
    return new Promise<void>((resolve, reject) => {
      const valuation = oldData.base?.valuation;
      if (valuation) {
        dispatch(
          deleteValuations(workspace.tenantId, workspace.id, [valuation]),
        )
          .then((_numberOfDeletes) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };
  return { onRowUpdate, onRowDelete };
}
