import {
  CryptoStateVisitor,
  CryptoVisitable,
} from '~src/data/store/visitors/asset/types/crypto/crypto-visitor';
import { Crypto } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleCryptos(
  workspaceId: string,
  dispatch: AppDispatch,
  cryptos: Crypto[],
) {
  const visitor = new CryptoStateVisitor(dispatch);
  const res = cryptos.map((crypto) => {
    const assetVisitable = new CryptoVisitable(workspaceId, crypto);
    return assetVisitable.accept(visitor);
  });
  visitor.post();
  return res;
}
