import { dashboardMediasActions } from '~src/data/store/reducers/common/dashboard/media/reducer';
import {
  DashboardMediaStateVisitor,
  DashboardMediaVisitable,
} from '~src/data/store/visitors/common/dashboard/media-visitor';
import { DashboardMedia } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export function handleDashboardMedias(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: DashboardMedia[],
  subscriptions: Operation[],
) {
  const dashboardMediaVisitor = new DashboardMediaStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  outputs.forEach((dashboardMedia) => {
    const assetVisitable = new DashboardMediaVisitable(
      dashboardMedia.element?.id ?? null,
      dashboardMedia,
    );
    assetVisitable.accept(dashboardMediaVisitor);
  });
  dashboardMediaVisitor.post();
  return outputs;
}

export function handleDeleteDashboardMedias(
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      dashboardMediasActions.removeMany(
        ids.map((p) => {
          return {
            workspaceId,
            id: p,
          };
        }),
      ),
    );
  };
}
