import { compact } from 'lodash';
import { createSelector } from 'reselect';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getBondHoldingTransfersDetailed } from '~src/data/store/selectors/finance/transfers/holding-type/bond-holding-transfer-detailed';
import { getFundHoldingTransfersDetailed } from '~src/data/store/selectors/finance/transfers/holding-type/fund-holding-transfer-detailed';
import { getStockHoldingTransfersDetailed } from '~src/data/store/selectors/finance/transfers/holding-type/stock-holding-transfer-detailed';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { TransferDetailed } from '~src/domain/workspace/components/project/transfer/algorithm/transfer-detailed';

import { HoldingType } from '@pladdenico/models';

interface Props {
  workspaceId: string;
  projectId: string;
  transfers: TransferState[];
}

export const getTransfersDetailed = createSelector(
  (state: DataState, _props: Props) => state.workspace,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.transfers,
  (workspaceData, projectId, transfers): TransferDetailed[] => {
    return compact(
      transfers.map((transfer) => {
        const holding = getHoldingById(workspaceData, {
          id: transfer.holdingId,
          projectId,
        });
        if (holding) {
          const transferDetailed: TransferDetailed = {
            holding,
            id: transfer.id,
            transfer,
            transferTyped: undefined,
          };
          if (holding?.type === HoldingType.Stock) {
            transferDetailed.transferTyped = getStockHoldingTransfersDetailed(
              workspaceData,
              { projectId, transfer },
            );
          } else if (holding?.type === HoldingType.Bond) {
            transferDetailed.transferTyped = getBondHoldingTransfersDetailed(
              workspaceData,
              { projectId, transfer },
            );
          } else if (holding?.type === HoldingType.Fund) {
            transferDetailed.transferTyped = getFundHoldingTransfersDetailed(
              workspaceData,
              { projectId, transfer },
            );
          }
          return transferDetailed;
        }
      }),
    );
  },
);
