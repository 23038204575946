import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { InvestmentState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getAssetByHoldingId } from '~src/data/store/selectors/asset/assets/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';

// import { Card, Image } from 'semantic-ui-react';
// const styles = require('~src/styles/investments/investment.css');
// const textStyles = require('~src/styles/text.css');

interface Props {
  workspaceId: string;
  projectId: string;
  investment: InvestmentState;
}

export const InvestmentAvatarComponent = React.memo((props: Props) => {
  const asset = useSelector((state: RootState) =>
    getAssetByHoldingId(selectWorkspaceData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      holdingId: props.investment.holdingId,
    }),
  );
  const exitElement = props.investment.exit ? (
    <Typography paragraph>
      Exit: {moment(props.investment.exit).format('DD/MM/YYYY')}
    </Typography>
  ) : (
    <Typography paragraph>Exit: Not yet</Typography>
  );
  return (
    <Card>
      <CardMedia
        // className={styles.investmentImage}
        image={`/images/${props.investment.image}`}
      />
      <CardContent>
        <CardHeader>{props.investment.name}</CardHeader>
        <Typography paragraph>{props.investment.description}</Typography>
      </CardContent>
      <CardContent>
        <Typography paragraph>FACTS</Typography>
        <Typography paragraph>Category: {asset?.categoryId}</Typography>
        {/* <Typography paragraph>Country: {asset.country}</Typography> */}
        <Typography paragraph>Lookup: {asset?.lookup}</Typography>
        {exitElement}
        <Typography paragraph>Asset class: {asset?.assetClass}</Typography>
      </CardContent>
    </Card>
  );
});
