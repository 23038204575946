import { exportHandlers as holdingExportHandlers } from '~src/services/xlsx/handlers/workspaces/projects/holdings/export-handlers';
import { ExportHandler } from '~src/services/xlsx/handlers/ioutput-handler';
// import { transferExportHandler } from '~src/services/xlxs/handlers/workspaces/projects/holdings/transfers/mapper';
import { transactionExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/transactions/mapper';
import { projectExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/mapper';
import { portfolioProjectExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/project-types/portfolio/mapper';
import { privateEquityFundProjectExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/project-types/private-equity-fund/mapper';
import { ExportWorkspaceStore } from '~src/domain/workspace/components/data/workspace/export-workspace-store';
import { projectSelectors } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { portfolioProjectSelectors } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { privateEquityFundProjectsSelectors } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { transactionsSelectors } from '~src/data/store/reducers/holding/transaction/transactions/reducer';

export const exportHandlers = (store: ExportWorkspaceStore) => {
  const handlers: ExportHandler<any>[] = [];
  const state = store.getState();
  const projects = projectSelectors.selectAllElements(
    state.workspace.project.projects,
  );
  handlers.push(projectExportHandler(projects));
  const portfolioProjects = portfolioProjectSelectors.selectAllElements(
    state.workspace.project.portfolioProject.portfolioProjects,
  );
  handlers.push(portfolioProjectExportHandler(portfolioProjects));
  const privateEquityFundProjects =
    privateEquityFundProjectsSelectors.selectAllElements(
      state.workspace.project.privateEquityFundProject
        .privateEquityFundProjects,
    );
  handlers.push(
    privateEquityFundProjectExportHandler(privateEquityFundProjects),
  );

  const transactions = transactionsSelectors.selectAllElements(
    state.holding.holding.transaction.transactions,
  );
  handlers.push(transactionExportHandler(transactions));
  // handlers.push(
  //   transferExportHandler(normalizedProjects.projects.transactions.transfers)
  // );

  const holdingHandlers = holdingExportHandlers(store);
  holdingHandlers && holdingHandlers.forEach((value) => handlers.push(value));

  return handlers;
};
