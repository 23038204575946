import { parseTransaction } from '~src/data/store/modules/holdings/transactions/transactions/parser';
import { subscribeToTransactions } from '~src/data/store/modules/holdings/transactions/transactions/subscription';
import {
  TransferStateVisitor,
  TransferVisitable,
  TransferVisitor,
} from '~src/data/store/visitors/holding/transfer-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  transactionsActions,
  TransactionState,
} from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { Transaction } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface TransactionVisitor {
  visit(transaction: TransactionVisitable): TransactionState;
  transferVisitor: TransferVisitor;
  post(): void;
}

export class TransactionVisitable implements Visitable<TransactionVisitor> {
  constructor(
    private _projectId: string,
    private _transaction: Transaction,
  ) {}
  public accept(visitor: TransactionVisitor) {
    this._transaction.transfers?.forEach((transfer) => {
      const transferVisitable = new TransferVisitable(
        transfer.holding?.id ?? '',
        transfer,
      );
      transferVisitable.accept(visitor.transferVisitor);
    });
    return visitor.visit(this);
  }

  public parse(): TransactionState {
    const transaction = this._transaction;
    return parseTransaction(this._projectId, transaction);
  }
}

export class TransactionHandlerVisitor implements TransactionVisitor {
  constructor(
    private _handle: (transfer: TransactionState) => void,
    public transferVisitor: TransferVisitor,
  ) {}
  public visit(transaction: TransactionVisitable): TransactionState {
    const transactionState = transaction.parse();
    this._handle(transactionState);
    return transactionState;
  }
  public post() {
    return;
  }
}

export class TransactionStateVisitor extends TransactionHandlerVisitor {
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    super(
      (transaction) => {
        dispatch(
          transactionsActions.upsertOneElement(transaction, {
            shouldAutobatch: true,
          }),
        );
        subscribeToTransactions(
          dispatch,
          tenantId,
          workspaceId,
          [transaction],
          subscriptions,
        );
      },
      new TransferStateVisitor(dispatch, tenantId, workspaceId, subscriptions),
    );
  }

  post() {
    this.transferVisitor.post();
  }
}
