import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import { QueryContextTypes, QueryUserContext } from '@pladdenico/portfolio-api';

export function fetchExchangeRates(
  baseCurrencyId: string,
  quoteCurrencyId: string,
  startPeriod: Date,
  endPeriod: Date,
  frequency: string,
) {
  const node = graphqlUserTypes.GetExchangeRatesDocument;
  const variables: graphqlUserTypes.GetExchangeRatesQueryVariables = {
    baseCurrencyId,
    quoteCurrencyId,
    startPeriod,
    endPeriod,
    frequency,
  };
  const context: QueryUserContext = {
    type: QueryContextTypes.user,
  };
  return investRequest(node, variables, context).then((data) => {
    return data.getExchangeRates;
  });
}
