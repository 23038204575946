import { handleGeneratedReports } from '~src/data/store/modules/workspaces/reports/generated-reports/handler';
import { reportsActions } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchGeneratedReports(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null = null,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetGeneratedReportsDocument;
    const variables: graphqlWorkspaceTypes.GetGeneratedReportsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context)
      .then((data) => {
        if (data.getGeneratedReports) {
          return handleGeneratedReports(
            dispatch,
            tenantId,
            workspaceId,
            data.getGeneratedReports,
            [],
          );
        }
        return undefined;
      })
      .catch((err) =>
        console.error(`Error getGeneratedReports: ', ${JSON.stringify(err)}`),
      );
  };
}

export function createGeneratedReports(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateGeneratedReportInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateGeneratedReportsDocument;
    const variables: graphqlWorkspaceTypes.CreateGeneratedReportsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createGeneratedReports) {
        return handleGeneratedReports(
          dispatch,
          tenantId,
          workspaceId,
          data.createGeneratedReports,
          [],
        );
      }
      throw new Error('Could not create report');
    });
  };
}

export function updateGeneratedReports(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateGeneratedReportInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateGeneratedReportsDocument;
    const variables: graphqlWorkspaceTypes.UpdateGeneratedReportsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateGeneratedReports) {
        return handleGeneratedReports(
          dispatch,
          tenantId,
          workspaceId,
          data.updateGeneratedReports,
          [],
        );
      }
      return [];
    });
  };
}

export function upsertGeneratedReports(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertGeneratedReportInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertGeneratedReportsDocument;
    const variables: graphqlWorkspaceTypes.UpsertGeneratedReportsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertGeneratedReports) {
        return handleGeneratedReports(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertGeneratedReports,
          [],
        );
      }
      return [];
    });
  };
}

export function handleDeleteGeneratedReports(projectId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      reportsActions.removeManyElements(
        ids.map((p) => {
          return {
            projectId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteGeneratedReports(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteGeneratedReportsDocument;
    const variables: graphqlWorkspaceTypes.DeleteGeneratedReportsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteGeneratedReports) {
        dispatch(
          handleDeleteGeneratedReports(projectId, data.deleteGeneratedReports),
        );
      }
      return [];
    });
  };
}
