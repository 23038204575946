import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { FinancialDataState } from '~src/data/store/reducers/reducers';

import { organizationsSelectors } from './reducer';

export const organizationsSelector = (state: FinancialDataState) => {
  return state.organization.organizations;
};

export const getOrganizations = createSelector(
  organizationsSelector,
  (organizations) => {
    return organizations;
  },
);

export const getOrganizationById = createCachedSelector(
  (state: FinancialDataState, _organizationId: string | undefined | null) =>
    organizationsSelector(state),
  (_state: FinancialDataState, organizationId: string | undefined | null) =>
    organizationId,
  (organizations, organizationId) => {
    if (!organizationId) {
      return undefined;
    }
    return organizationsSelectors.selectElement(organizations, {
      id: organizationId,
    });
  },
)({
  keySelector: (_state, organizationId) => organizationId,
  selectorCreator: createSelector,
});
