import { useDispatch } from 'react-redux';
import {
  deleteEntities,
  updateEntities,
} from '~src/data/store/modules/entities/entities/requests';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';
import { AppDispatch } from '~src/store/store';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';

export function useEntityActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onUpdate = (oldData: EntityState, newData: EntityState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceOperations.CreateEntityInputType = {
          id: newData.id,
          name: newData.name,
          description: newData.description,
          sector: newData.sector,
          website: newData.website,
        };
        dispatch(updateEntities(workspace.tenantId, workspace.id, [input]))
          .then((_entities) => {
            resolve();
          })
          .catch((_err) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: EntityState) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deleteEntities(workspace.tenantId, workspace.id, [{ id: oldData.id }]),
      )
        .then((_entityIds) => {
          resolve();
        })
        .catch((_err) => {
          reject();
        });
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onUpdate, onDelete };
}
