import { combineReducers } from 'redux';
import {
  initialEventState,
  eventsReducer,
} from '~src/data/store/reducers/workspace/projects/events/event/reducer';
import {
  initialSchedulerState,
  schedulersReducer,
} from '~src/data/store/reducers/workspace/projects/events/schedulers/reducer';

export const eventInitialState = {
  events: initialEventState,
  schedulers: initialSchedulerState,
};

export const eventReducer = combineReducers({
  events: eventsReducer,
  schedulers: schedulersReducer,
});
