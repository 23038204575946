import * as React from 'react';
import { EditRegionField } from '~src/components/fields/common/edit-region-field.component';
import { ViewRegionField } from '~src/components/fields/common/view-region-field.component';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getRegionById } from '~src/data/store/selectors/common/regions/selectors';
import { store } from '~src/store/store';

import { TableField } from '@pladdenico/table';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';

export function useRegionField<T>(
  regions: RegionState[],
  getRegionId: (data: T) => string,
  update: (data: T, region: RegionState) => T,
): TableField.Field<T> {
  return {
    id: 'region',
    name: 'region',
    field: 'regionId',
    type: TableField.FieldTypes.string,
    iteratee: (data) => {
      const region = getRegionById(selectWorkspaceData(store.getState()), {
        id: getRegionId(data),
      });
      return region ? region.symbol : '';
    },
    update: (data, region: RegionState) => {
      return update(data, region);
    },
    renderView: (editProps) => {
      return <ViewRegionField regionId={getRegionId(editProps.data)} />;
    },
    renderEdit: (editProps) => {
      return (
        <EditRegionField
          handleValue={editProps.onChange}
          regionId={getRegionId(editProps.data)}
          labelled={false}
        />
      );
    },
  };
}
