import { handleInvestors } from '~src/data/store/modules/assets/private-equity-fund/investors/handler';
import { investorsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchInvestors(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPrivateEquityFundInvestorsDocument;
    const variables: graphqlWorkspaceTypes.GetPrivateEquityFundInvestorsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPrivateEquityFundInvestors) {
        return handleInvestors(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.getPrivateEquityFundInvestors,
        );
      }
      return [];
    });
  };
}

export function createInvestors(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestorInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreatePrivateEquityFundInvestorsDocument;
    const variables: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestorsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPrivateEquityFundInvestors) {
        return handleInvestors(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.createPrivateEquityFundInvestors,
        );
      }
      return [];
    });
  };
}

export function updateInvestors(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestorInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestorsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestorsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePrivateEquityFundInvestors) {
        return handleInvestors(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.updatePrivateEquityFundInvestors,
        );
      }
      return [];
    });
  };
}

export function upsertInvestors(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.UpsertPrivateEquityFundInvestorInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertPrivateEquityFundInvestorsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPrivateEquityFundInvestorsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPrivateEquityFundInvestors) {
        return handleInvestors(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.upsertPrivateEquityFundInvestors,
        );
      }
      return [];
    });
  };
}

export function deleteInvestors(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  investors: Array<{ id: string; fundId: string }>,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeletePrivateEquityFundInvestorsDocument;
    investors.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeletePrivateEquityFundInvestorInputType[] =
      investors.map((privateEquityFund) => {
        return {
          id: privateEquityFund.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeletePrivateEquityFundInvestorsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePrivateEquityFundInvestors) {
        const deletedInvestors = intersectionSorted(
          investors,
          data.deletePrivateEquityFundInvestors,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, fundId };
          },
        );
        return dispatch(investorsActions.removeManyElements(deletedInvestors));
      }
      return [];
    });
  };
}
