/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Address = {
  __typename?: 'Address';
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Allocation = {
  __typename?: 'Allocation';
  assetClass?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  id: Scalars['String'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  portfolio?: Maybe<Portfolio>;
  region?: Maybe<Region>;
  sector?: Maybe<Scalars['String']>;
};

export type Asset = {
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
};

export type AssetCategory = {
  __typename?: 'AssetCategory';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type AssetObj = {
  __typename?: 'AssetObj';
  ISIN?: Maybe<Scalars['String']>;
  VPS?: Maybe<Scalars['String']>;
  assetClass?: Maybe<Scalars['String']>;
  category?: Maybe<AssetCategory>;
  description?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  id: Scalars['String'];
  lookup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  types?: Maybe<Array<Scalars['String']>>;
};

export type Commitment = {
  __typename?: 'Commitment';
  commitmentDate?: Maybe<Scalars['Date']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  transfers?: Maybe<Array<Transfer>>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CreateTenantInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreateWorkspaceInvitationInputType = {
  accepted: Scalars['Boolean'];
  email: Scalars['String'];
  workspaceRoleId: Scalars['Int'];
};

export type CreateWorkspaceRoleInputType = {
  groupId: Scalars['Int'];
  name: Scalars['String'];
  permission: WorkspacePermissionInputType;
  workspaceId: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type DashboardElement = {
  __typename?: 'DashboardElement';
  config: Scalars['String'];
  id: Scalars['String'];
  media?: Maybe<DashboardMedia>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type DashboardMedia = {
  __typename?: 'DashboardMedia';
  element?: Maybe<DashboardElement>;
  file?: Maybe<File>;
  id: Scalars['String'];
};

export type Email = {
  __typename?: 'Email';
  id: Scalars['String'];
  person?: Maybe<Person>;
  value?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Entity = {
  __typename?: 'Entity';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  media?: Maybe<Array<EntityMedia>>;
  name?: Maybe<Scalars['String']>;
  regionAllocations?: Maybe<Array<RegionAllocation>>;
  sector?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type EntityMedia = {
  __typename?: 'EntityMedia';
  entity?: Maybe<Entity>;
  file?: Maybe<File>;
  id: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type Holding = {
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type HoldingConfig = {
  __typename?: 'HoldingConfig';
  currentDashboardConfigId?: Maybe<Scalars['String']>;
  holdingDashboardConfigs?: Maybe<Array<HoldingDashboardConfig>>;
  id: Scalars['Int'];
};

export type HoldingDashboardConfig = {
  __typename?: 'HoldingDashboardConfig';
  compactType?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HoldingObj = {
  __typename?: 'HoldingObj';
  configs?: Maybe<Array<HoldingConfig>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectObj>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptWorkspaceInvitation: WorkspaceInvitation;
  createTenant?: Maybe<Tenant>;
  createWorkspaceInvitation?: Maybe<WorkspaceInvitation>;
  createWorkspaceRole?: Maybe<WorkspaceRole>;
  deleteTenant: Scalars['String'];
  deleteWorkspaceInvitation: Scalars['Int'];
  deleteWorkspaceRole: Scalars['Int'];
  updateTenant: Tenant;
  updateWorkspaceInvitation: WorkspaceInvitation;
  updateWorkspaceRole: WorkspaceRole;
  upsertWorkspaceInvitation: WorkspaceInvitation;
  upsertWorkspaceRole: WorkspaceRole;
};

export type MutationAcceptWorkspaceInvitationArgs = {
  input?: InputMaybe<Scalars['String']>;
};

export type MutationCreateTenantArgs = {
  input?: InputMaybe<CreateTenantInputType>;
};

export type MutationCreateWorkspaceInvitationArgs = {
  input?: InputMaybe<CreateWorkspaceInvitationInputType>;
};

export type MutationCreateWorkspaceRoleArgs = {
  input?: InputMaybe<CreateWorkspaceRoleInputType>;
};

export type MutationDeleteWorkspaceInvitationArgs = {
  input?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteWorkspaceRoleArgs = {
  input?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateTenantArgs = {
  input?: InputMaybe<UpdateTenantInputType>;
};

export type MutationUpdateWorkspaceInvitationArgs = {
  input?: InputMaybe<UpdateWorkspaceInvitationInputType>;
};

export type MutationUpdateWorkspaceRoleArgs = {
  input?: InputMaybe<UpdateWorkspaceRoleInputType>;
};

export type MutationUpsertWorkspaceInvitationArgs = {
  input?: InputMaybe<UpsertWorkspaceInvitationInputType>;
};

export type MutationUpsertWorkspaceRoleArgs = {
  input?: InputMaybe<UpsertWorkspaceRoleInputType>;
};

export type Person = {
  __typename?: 'Person';
  addresses?: Maybe<Array<Address>>;
  description?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Email>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Phone>>;
  roles?: Maybe<Array<Role>>;
  workspace?: Maybe<Workspace>;
};

export type Phone = {
  __typename?: 'Phone';
  id: Scalars['String'];
  person?: Maybe<Person>;
  value?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  allocations?: Maybe<Array<Allocation>>;
  description?: Maybe<Scalars['String']>;
  holdings?: Maybe<Array<Holding>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  parentPortfolio?: Maybe<Portfolio>;
  portfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  portfolios?: Maybe<Array<Portfolio>>;
};

export type PortfolioConfig = {
  __typename?: 'PortfolioConfig';
  currentDashboardConfigId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  portfolioDashboardConfigs?: Maybe<Array<PortfolioDashboardConfig>>;
};

export type PortfolioDashboardConfig = {
  __typename?: 'PortfolioDashboardConfig';
  compactType?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Project = {
  project: ProjectObj;
};

export type ProjectObj = {
  __typename?: 'ProjectObj';
  holdings?: Maybe<Array<Holding>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Transaction>>;
  type?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Query = {
  __typename?: 'Query';
  getTenant?: Maybe<Tenant>;
  getWorkspaceInvitations?: Maybe<Array<WorkspaceInvitation>>;
  getWorkspaceRoles?: Maybe<Array<WorkspaceRole>>;
  hello?: Maybe<Scalars['String']>;
};

export type QueryGetWorkspaceInvitationsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type QueryGetWorkspaceRolesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RegionAllocation = {
  __typename?: 'RegionAllocation';
  allocation?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  entity?: Maybe<Entity>;
  id: Scalars['String'];
  region?: Maybe<Region>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  workspace?: Maybe<Workspace>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tenantRoles?: Maybe<Array<TenantRole>>;
  type?: Maybe<Scalars['String']>;
  workspaces?: Maybe<Array<WorkspaceBase>>;
};

export type TenantBase = {
  __typename?: 'TenantBase';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TenantPermission = {
  __typename?: 'TenantPermission';
  addInvitations?: Maybe<Scalars['Boolean']>;
  addPortfolios?: Maybe<Scalars['Boolean']>;
  addTenantRoles?: Maybe<Scalars['Boolean']>;
  editPortfolios?: Maybe<Scalars['Boolean']>;
  editTenantProperties?: Maybe<Scalars['Boolean']>;
  remove?: Maybe<Scalars['Boolean']>;
  removeInvitations?: Maybe<Scalars['Boolean']>;
  removePortfolios?: Maybe<Scalars['Boolean']>;
  removeTenantRoles?: Maybe<Scalars['Boolean']>;
  updateInvitations?: Maybe<Scalars['Boolean']>;
  updateTenantRoles?: Maybe<Scalars['Boolean']>;
  viewPortfolios?: Maybe<Scalars['Boolean']>;
  viewTenantProperties?: Maybe<Scalars['Boolean']>;
};

export type TenantRole = {
  __typename?: 'TenantRole';
  group?: Maybe<Group>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<TenantPermission>;
  tenant?: Maybe<TenantBase>;
};

export type Transaction = {
  __typename?: 'Transaction';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  project?: Maybe<ProjectObj>;
  title?: Maybe<Scalars['String']>;
  transfers?: Maybe<Array<Transfer>>;
};

export type Transfer = {
  __typename?: 'Transfer';
  commitment?: Maybe<Commitment>;
  cost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  status?: Maybe<Scalars['Int']>;
  subtype?: Maybe<Scalars['String']>;
  transaction?: Maybe<Transaction>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type UpdateTenantInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkspaceInvitationInputType = {
  accepted: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['String'];
  workspaceRoleId: Scalars['Int'];
};

export type UpdateWorkspaceRoleInputType = {
  groupId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<WorkspacePermissionInputType>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type UpsertWorkspaceInvitationInputType = {
  accepted: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['String'];
  workspaceRoleId: Scalars['Int'];
};

export type UpsertWorkspaceRoleInputType = {
  groupId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<WorkspacePermissionInputType>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Valuation = {
  __typename?: 'Valuation';
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['Date']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  ownershipPercentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  assets?: Maybe<Array<Asset>>;
  entities?: Maybe<Array<Entity>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Person>>;
  portfolios?: Maybe<Array<Portfolio>>;
  projects?: Maybe<Array<Project>>;
  tenant?: Maybe<TenantBase>;
  type?: Maybe<Scalars['String']>;
  workspaceRoles?: Maybe<Array<WorkspaceRole>>;
};

export type WorkspaceBase = {
  __typename?: 'WorkspaceBase';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WorkspaceInvitation = {
  __typename?: 'WorkspaceInvitation';
  accepted: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['String'];
  workspaceRole?: Maybe<WorkspaceRole>;
};

export type WorkspacePermission = {
  __typename?: 'WorkspacePermission';
  addInvitations?: Maybe<Scalars['Boolean']>;
  addPortfolios?: Maybe<Scalars['Boolean']>;
  addWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  editPortfolios?: Maybe<Scalars['Boolean']>;
  editWorkspaceProperties?: Maybe<Scalars['Boolean']>;
  remove?: Maybe<Scalars['Boolean']>;
  removeInvitations?: Maybe<Scalars['Boolean']>;
  removePortfolios?: Maybe<Scalars['Boolean']>;
  removeWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  updateInvitations?: Maybe<Scalars['Boolean']>;
  updateWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  viewPortfolios?: Maybe<Scalars['Boolean']>;
  viewWorkspaceProperties?: Maybe<Scalars['Boolean']>;
};

export type WorkspacePermissionInputType = {
  addAssets: Scalars['Boolean'];
  addInvitations: Scalars['Boolean'];
  addPortfolios: Scalars['Boolean'];
  addProjects: Scalars['Boolean'];
  addWorkspaceRoles: Scalars['Boolean'];
  editAssets: Scalars['Boolean'];
  editPortfolios: Scalars['Boolean'];
  editWorkspaceProperties: Scalars['Boolean'];
  remove: Scalars['Boolean'];
  removeAssets: Scalars['Boolean'];
  removeInvitations: Scalars['Boolean'];
  removePortfolios: Scalars['Boolean'];
  removeProjects: Scalars['Boolean'];
  removeWorkspaceRoles: Scalars['Boolean'];
  updateInvitations: Scalars['Boolean'];
  updateProjects: Scalars['Boolean'];
  updateWorkspaceRoles: Scalars['Boolean'];
  viewAssets: Scalars['Boolean'];
  viewPortfolios: Scalars['Boolean'];
  viewProjects: Scalars['Boolean'];
  viewWorkspaceProperties: Scalars['Boolean'];
  viewWorkspaceRoles: Scalars['Boolean'];
};

export type WorkspaceRole = {
  __typename?: 'WorkspaceRole';
  group?: Maybe<Group>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<WorkspacePermission>;
  workspace?: Maybe<Workspace>;
};

export type GroupFragment = {
  __typename?: 'Group';
  id?: number | null;
  name?: string | null;
  users?: Array<{
    __typename?: 'User';
    id?: number | null;
    name?: string | null;
    email?: string | null;
  } | null> | null;
};

export type TenantPermissionFragment = {
  __typename?: 'TenantPermission';
  addPortfolios?: boolean | null;
  editPortfolios?: boolean | null;
  removePortfolios?: boolean | null;
  viewPortfolios?: boolean | null;
  viewTenantProperties?: boolean | null;
  editTenantProperties?: boolean | null;
  addTenantRoles?: boolean | null;
  updateTenantRoles?: boolean | null;
  removeTenantRoles?: boolean | null;
  addInvitations?: boolean | null;
  updateInvitations?: boolean | null;
  removeInvitations?: boolean | null;
  remove?: boolean | null;
};

export type TenantRoleFragment = {
  __typename?: 'TenantRole';
  id: number;
  name?: string | null;
  permission?: {
    __typename?: 'TenantPermission';
    addPortfolios?: boolean | null;
    editPortfolios?: boolean | null;
    removePortfolios?: boolean | null;
    viewPortfolios?: boolean | null;
    viewTenantProperties?: boolean | null;
    editTenantProperties?: boolean | null;
    addTenantRoles?: boolean | null;
    updateTenantRoles?: boolean | null;
    removeTenantRoles?: boolean | null;
    addInvitations?: boolean | null;
    updateInvitations?: boolean | null;
    removeInvitations?: boolean | null;
    remove?: boolean | null;
  } | null;
  group?: {
    __typename?: 'Group';
    id?: number | null;
    name?: string | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
  tenant?: { __typename?: 'TenantBase'; id: string } | null;
};

export type TenantFragment = {
  __typename?: 'Tenant';
  id: string;
  name?: string | null;
};

export type TenantWithRolesFragment = {
  __typename?: 'Tenant';
  id: string;
  name?: string | null;
  tenantRoles?: Array<{
    __typename?: 'TenantRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'TenantPermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewTenantProperties?: boolean | null;
      editTenantProperties?: boolean | null;
      addTenantRoles?: boolean | null;
      updateTenantRoles?: boolean | null;
      removeTenantRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    tenant?: { __typename?: 'TenantBase'; id: string } | null;
  }> | null;
};

export type GetTenantQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantQuery = {
  __typename?: 'Query';
  getTenant?: {
    __typename?: 'Tenant';
    id: string;
    name?: string | null;
  } | null;
};

export type CreateTenantMutationVariables = Exact<{
  input?: InputMaybe<CreateTenantInputType>;
}>;

export type CreateTenantMutation = {
  __typename?: 'Mutation';
  createTenant?: {
    __typename?: 'Tenant';
    id: string;
    name?: string | null;
    tenantRoles?: Array<{
      __typename?: 'TenantRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'TenantPermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewTenantProperties?: boolean | null;
        editTenantProperties?: boolean | null;
        addTenantRoles?: boolean | null;
        updateTenantRoles?: boolean | null;
        removeTenantRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
      tenant?: { __typename?: 'TenantBase'; id: string } | null;
    }> | null;
  } | null;
};

export type UpdateTenantMutationVariables = Exact<{
  input?: InputMaybe<UpdateTenantInputType>;
}>;

export type UpdateTenantMutation = {
  __typename?: 'Mutation';
  updateTenant: { __typename?: 'Tenant'; id: string; name?: string | null };
};

export type DeleteTenantMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteTenantMutation = {
  __typename?: 'Mutation';
  deleteTenant: string;
};

export type UserFragment = {
  __typename?: 'User';
  id?: number | null;
  name?: string | null;
  email?: string | null;
};

export type WorkspaceInvitationFragment = {
  __typename?: 'WorkspaceInvitation';
  id: string;
  email: string;
  accepted: boolean;
  workspaceRole?: {
    __typename?: 'WorkspaceRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'WorkspacePermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewWorkspaceProperties?: boolean | null;
      editWorkspaceProperties?: boolean | null;
      addWorkspaceRoles?: boolean | null;
      updateWorkspaceRoles?: boolean | null;
      removeWorkspaceRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    workspace?: { __typename?: 'Workspace'; id: string } | null;
  } | null;
};

export type GetWorkspaceInvitationsQueryVariables = Exact<{
  ids?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  userIds?: InputMaybe<
    Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>
  >;
}>;

export type GetWorkspaceInvitationsQuery = {
  __typename?: 'Query';
  getWorkspaceInvitations?: Array<{
    __typename?: 'WorkspaceInvitation';
    id: string;
    email: string;
    accepted: boolean;
    workspaceRole?: {
      __typename?: 'WorkspaceRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'WorkspacePermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewWorkspaceProperties?: boolean | null;
        editWorkspaceProperties?: boolean | null;
        addWorkspaceRoles?: boolean | null;
        updateWorkspaceRoles?: boolean | null;
        removeWorkspaceRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
      workspace?: { __typename?: 'Workspace'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateWorkspaceInvitationMutationVariables = Exact<{
  input: CreateWorkspaceInvitationInputType;
}>;

export type CreateWorkspaceInvitationMutation = {
  __typename?: 'Mutation';
  createWorkspaceInvitation?: {
    __typename?: 'WorkspaceInvitation';
    id: string;
    email: string;
    accepted: boolean;
    workspaceRole?: {
      __typename?: 'WorkspaceRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'WorkspacePermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewWorkspaceProperties?: boolean | null;
        editWorkspaceProperties?: boolean | null;
        addWorkspaceRoles?: boolean | null;
        updateWorkspaceRoles?: boolean | null;
        removeWorkspaceRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
      workspace?: { __typename?: 'Workspace'; id: string } | null;
    } | null;
  } | null;
};

export type UpdateWorkspaceInvitationMutationVariables = Exact<{
  input: UpdateWorkspaceInvitationInputType;
}>;

export type UpdateWorkspaceInvitationMutation = {
  __typename?: 'Mutation';
  updateWorkspaceInvitation: {
    __typename?: 'WorkspaceInvitation';
    id: string;
    email: string;
    accepted: boolean;
    workspaceRole?: {
      __typename?: 'WorkspaceRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'WorkspacePermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewWorkspaceProperties?: boolean | null;
        editWorkspaceProperties?: boolean | null;
        addWorkspaceRoles?: boolean | null;
        updateWorkspaceRoles?: boolean | null;
        removeWorkspaceRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
      workspace?: { __typename?: 'Workspace'; id: string } | null;
    } | null;
  };
};

export type UpsertWorkspaceInvitationMutationVariables = Exact<{
  input: UpsertWorkspaceInvitationInputType;
}>;

export type UpsertWorkspaceInvitationMutation = {
  __typename?: 'Mutation';
  upsertWorkspaceInvitation: {
    __typename?: 'WorkspaceInvitation';
    id: string;
    email: string;
    accepted: boolean;
    workspaceRole?: {
      __typename?: 'WorkspaceRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'WorkspacePermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewWorkspaceProperties?: boolean | null;
        editWorkspaceProperties?: boolean | null;
        addWorkspaceRoles?: boolean | null;
        updateWorkspaceRoles?: boolean | null;
        removeWorkspaceRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
      workspace?: { __typename?: 'Workspace'; id: string } | null;
    } | null;
  };
};

export type DeleteWorkspaceInvitationMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type DeleteWorkspaceInvitationMutation = {
  __typename?: 'Mutation';
  deleteWorkspaceInvitation: number;
};

export type AcceptWorkspaceInvitationMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type AcceptWorkspaceInvitationMutation = {
  __typename?: 'Mutation';
  acceptWorkspaceInvitation: {
    __typename?: 'WorkspaceInvitation';
    id: string;
    email: string;
    accepted: boolean;
    workspaceRole?: {
      __typename?: 'WorkspaceRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'WorkspacePermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewWorkspaceProperties?: boolean | null;
        editWorkspaceProperties?: boolean | null;
        addWorkspaceRoles?: boolean | null;
        updateWorkspaceRoles?: boolean | null;
        removeWorkspaceRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
      workspace?: { __typename?: 'Workspace'; id: string } | null;
    } | null;
  };
};

export type WorkspacePermissionFragment = {
  __typename?: 'WorkspacePermission';
  addPortfolios?: boolean | null;
  editPortfolios?: boolean | null;
  removePortfolios?: boolean | null;
  viewPortfolios?: boolean | null;
  viewWorkspaceProperties?: boolean | null;
  editWorkspaceProperties?: boolean | null;
  addWorkspaceRoles?: boolean | null;
  updateWorkspaceRoles?: boolean | null;
  removeWorkspaceRoles?: boolean | null;
  addInvitations?: boolean | null;
  updateInvitations?: boolean | null;
  removeInvitations?: boolean | null;
  remove?: boolean | null;
};

export type WorkspaceRoleFragment = {
  __typename?: 'WorkspaceRole';
  id: number;
  name?: string | null;
  permission?: {
    __typename?: 'WorkspacePermission';
    addPortfolios?: boolean | null;
    editPortfolios?: boolean | null;
    removePortfolios?: boolean | null;
    viewPortfolios?: boolean | null;
    viewWorkspaceProperties?: boolean | null;
    editWorkspaceProperties?: boolean | null;
    addWorkspaceRoles?: boolean | null;
    updateWorkspaceRoles?: boolean | null;
    removeWorkspaceRoles?: boolean | null;
    addInvitations?: boolean | null;
    updateInvitations?: boolean | null;
    removeInvitations?: boolean | null;
    remove?: boolean | null;
  } | null;
  group?: {
    __typename?: 'Group';
    id?: number | null;
    name?: string | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
  workspace?: { __typename?: 'Workspace'; id: string } | null;
};

export type GetWorkspaceRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkspaceRolesQuery = {
  __typename?: 'Query';
  getWorkspaceRoles?: Array<{
    __typename?: 'WorkspaceRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'WorkspacePermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewWorkspaceProperties?: boolean | null;
      editWorkspaceProperties?: boolean | null;
      addWorkspaceRoles?: boolean | null;
      updateWorkspaceRoles?: boolean | null;
      removeWorkspaceRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    workspace?: { __typename?: 'Workspace'; id: string } | null;
  }> | null;
};

export type CreateWorkspaceRoleMutationVariables = Exact<{
  input: CreateWorkspaceRoleInputType;
}>;

export type CreateWorkspaceRoleMutation = {
  __typename?: 'Mutation';
  createWorkspaceRole?: {
    __typename?: 'WorkspaceRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'WorkspacePermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewWorkspaceProperties?: boolean | null;
      editWorkspaceProperties?: boolean | null;
      addWorkspaceRoles?: boolean | null;
      updateWorkspaceRoles?: boolean | null;
      removeWorkspaceRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    workspace?: { __typename?: 'Workspace'; id: string } | null;
  } | null;
};

export type UpdateWorkspaceRoleMutationVariables = Exact<{
  input: UpdateWorkspaceRoleInputType;
}>;

export type UpdateWorkspaceRoleMutation = {
  __typename?: 'Mutation';
  updateWorkspaceRole: {
    __typename?: 'WorkspaceRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'WorkspacePermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewWorkspaceProperties?: boolean | null;
      editWorkspaceProperties?: boolean | null;
      addWorkspaceRoles?: boolean | null;
      updateWorkspaceRoles?: boolean | null;
      removeWorkspaceRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    workspace?: { __typename?: 'Workspace'; id: string } | null;
  };
};

export type DeleteWorkspaceRoleMutationVariables = Exact<{
  input: Scalars['Int'];
}>;

export type DeleteWorkspaceRoleMutation = {
  __typename?: 'Mutation';
  deleteWorkspaceRole: number;
};

export const TenantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Tenant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantFragment, unknown>;
export const TenantPermissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantPermissionFragment, unknown>;
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>;
export const GroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupFragment, unknown>;
export const TenantRoleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantRoleFragment, unknown>;
export const TenantWithRolesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantWithRoles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantWithRolesFragment, unknown>;
export const WorkspacePermissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspacePermissionFragment, unknown>;
export const WorkspaceInvitationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkspacePermission' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceInvitationFragment, unknown>;
export const WorkspaceRoleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceRoleFragment, unknown>;
export const GetTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTenant' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Tenant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Tenant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTenantQuery, GetTenantQueryVariables>;
export const CreateTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CreateTenantInputType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantWithRoles' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantWithRoles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantRole' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;
export const UpdateTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UpdateTenantInputType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Tenant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Tenant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Tenant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;
export const DeleteTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTenant' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deleteTenant' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTenantMutation,
  DeleteTenantMutationVariables
>;
export const GetWorkspaceInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceInvitations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkspacePermission' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceInvitationsQuery,
  GetWorkspaceInvitationsQueryVariables
>;
export const CreateWorkspaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWorkspaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateWorkspaceInvitationInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWorkspaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkspacePermission' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWorkspaceInvitationMutation,
  CreateWorkspaceInvitationMutationVariables
>;
export const UpdateWorkspaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWorkspaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateWorkspaceInvitationInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkspacePermission' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceInvitationMutation,
  UpdateWorkspaceInvitationMutationVariables
>;
export const UpsertWorkspaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertWorkspaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertWorkspaceInvitationInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertWorkspaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkspacePermission' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertWorkspaceInvitationMutation,
  UpsertWorkspaceInvitationMutationVariables
>;
export const DeleteWorkspaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWorkspaceInvitationMutation,
  DeleteWorkspaceInvitationMutationVariables
>;
export const AcceptWorkspaceInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptWorkspaceInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptWorkspaceInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceInvitation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceInvitation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceInvitation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WorkspacePermission' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Group' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptWorkspaceInvitationMutation,
  AcceptWorkspaceInvitationMutationVariables
>;
export const GetWorkspaceRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceRoles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspaceRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceRolesQuery,
  GetWorkspaceRolesQueryVariables
>;
export const CreateWorkspaceRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWorkspaceRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateWorkspaceRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWorkspaceRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWorkspaceRoleMutation,
  CreateWorkspaceRoleMutationVariables
>;
export const UpdateWorkspaceRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWorkspaceRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateWorkspaceRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceRoleMutation,
  UpdateWorkspaceRoleMutationVariables
>;
export const DeleteWorkspaceRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspaceRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspaceRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWorkspaceRoleMutation,
  DeleteWorkspaceRoleMutationVariables
>;
