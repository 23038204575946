import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseRegionId {
  id: string;
}

export type RegionState = graphqlWorkspaceTypes.Region & {
  name: string;
  symbol: string;
  type: string;
};

export const initialState: RecordState<RegionState, string>[] = [];

const selectKeyId = (region: BaseRegionId) => region.id;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseRegionId, b: BaseRegionId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseRegionId, RegionState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const regionsSlice = createBaseRecordSlice('regions', adapter);
export const regionsSelectors = adapter.selectors;

export const regionsReducer = regionsSlice.reducer;
export const regionsActions = regionsSlice.actions;
