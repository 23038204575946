import {
  DashboardElementModel,
  DashboardElementsBaseType,
  HoldingDashboardElementsType,
} from '@pladdenico/models';

export const holdingDashboardElements: DashboardElementModel<
  HoldingDashboardElementsType | DashboardElementsBaseType
>[] = [
  {
    type: 'header',
    title: 'Header',
    description: '',
  },
  {
    type: 'summary',
    title: 'Summary',
    description: '',
  },
  {
    type: 'detailedDescription',
    title: 'Detailed description',
    description: '',
  },
  {
    type: 'valuationGeographical',
    title: 'Holdings valuation map',
    description: '',
  },
  {
    type: 'irr',
    title: 'Holding IRR',
    description: '',
  },
  {
    type: 'bond',
    title: 'Bond',
    description: '',
  },
  {
    type: 'fund',
    title: 'Fund',
    description: '',
  },
  {
    type: 'costs',
    title: 'Costs',
    description: '',
  },
  {
    type: 'events',
    title: 'Events',
    description: '',
  },
  {
    type: 'ownership',
    title: 'Ownership',
    description: '',
  },
  {
    type: 'paper',
    title: 'Paper',
    description: '',
  },
  {
    type: 'goal',
    title: 'Goal',
    description: '',
  },
  {
    type: 'waterfall',
    title: 'Waterfall',
    description: '',
  },
  {
    type: 'text',
    title: 'Text',
    description: '',
  },
  {
    type: 'image',
    title: 'Image',
    description: '',
  },
  {
    type: 'commitments',
    title: 'Commitments',
    description: '',
  },
  {
    type: 'custom',
    title: 'Custom',
    description: '',
  },
];
