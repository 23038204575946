import { Edge, Node } from 'reactflow';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { NodeType } from '../../../nodes/node-type';

export const interestRateInitialNodes: Node<NodeData, NodeType>[] = [
  {
    id: 'initial',
    type: 'initial',
    data: {
      label: 'Initial',
      initialization:
        '{"initial":{"valuation":{"value":1221},"stock":{"position":11}}}',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value:
              'prevValuation: (idx > 0 ? values[idx].valuation : initial.valuation)',
          },
        ],
      },
    },
    position: { x: 180, y: 0 },
  },
  {
    id: 'baseInterestRate',
    type: 'inputType',
    data: {
      label: 'BaseInterestRate',
      variableId: null,
    },
    position: { x: 300, y: 40 },
  },
  {
    id: 'interestRate',
    type: 'variable',
    data: {
      label: 'InterestRate',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value: 'value:BaseInterestRate+0.003',
          },
        ],
      },
    },
    position: { x: 300, y: 100 },
  },
  {
    id: 'interest',
    type: 'variable',
    data: {
      label: 'Interest',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value: 'value:InterestRate.value*Initial.prevValuation.value',
          },
        ],
      },
    },
    position: { x: 250, y: 150 },
  },
  {
    id: 'transaction',
    type: 'variable',
    data: {
      label: 'transaction',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value:
              'valuation:{value: Initial.prevValuation.value + Interest.value}, transfer:{type: "deposit",value:Interest.value}',
          },
        ],
      },
    },
    position: { x: 220, y: 250 },
  },
  {
    id: 'valuation',
    type: 'output',
    data: {
      label: 'valuation',
      name: 'transaction',
    },
    position: { x: 100, y: 400 },
  },
  {
    id: 'transfer',
    type: 'output',
    data: {
      label: 'transfer',
      name: 'transaction',
    },
    position: { x: 300, y: 400 },
  },
];

export const interestRateInitialEdges: Edge[] = [
  {
    id: '1',
    source: 'initial',
    target: 'interest',
  },
  {
    id: '2',
    source: 'initial',
    target: 'transaction',
  },
  {
    id: '3',
    source: 'baseInterestRate',
    target: 'interestRate',
  },
  {
    id: '4',
    source: 'interestRate',
    target: 'interest',
  },
  {
    id: '5',
    source: 'interest',
    target: 'transaction',
  },
  {
    id: '6',
    source: 'transaction',
    target: 'valuation',
  },
  {
    id: '7',
    source: 'transaction',
    target: 'transfer',
  },
];

export const initialInterestRateFlow = {
  nodes: interestRateInitialNodes,
  edges: interestRateInitialEdges,
};
