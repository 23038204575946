import { strcmp } from '@pladdenico/common';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseBondPositionId {
  bondHoldingId: string;
  id: string;
}

export type BondPositionState = {
  id: string;
  bondHoldingId: string;
  date: Date;
  shares: number;
};

export const initialBondPositionState = [];

const selectKeyId = (bondPosition: BaseBondPositionId) =>
  bondPosition.bondHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseBondPositionId, b: BaseBondPositionId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseBondPositionId,
  BondPositionState,
  string
>(initialBondPositionState, selectKeyId, keyComparator, tComparator);

export const bondPositionsSlice = createBaseRecordSlice(
  'bondPositions',
  adapter,
);
export const selectors = adapter.selectors;

export const bondPositionsReducer = bondPositionsSlice.reducer;
export const bondPositionsActions = bondPositionsSlice.actions;
