import { compact } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import { BaseSelectedForecastId } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/selected/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getForecastsByIds } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/forecast/selectors';
import { getSelectedForecastsByIds } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/selected/selectors';
import { getVariablesByScenarioId } from '~src/data/store/selectors/workspace/projects/scenario/variables/selectors';
import { getForecastElement } from '~src/domain/workspace/components/project/scenario/dashboard/use-evaluate';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { ForecastEvaluator } from '~src/domain/workspace/components/project/scenario/models/forecast/forecast-evaluator';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

export const useVariables = (
  scenario: Scenario,
  budget: Budget | undefined,
  numberOfDaysInPeriod: number,
) => {
  const variables = useSelector((state: RootState) => {
    return getVariablesByScenarioId(selectWorkspaceData(state), scenario.id);
  });

  const variableForecastIds = React.useMemo((): BaseSelectedForecastId[] => {
    if (budget && variables) {
      return variables.map((v): BaseSelectedForecastId => {
        return {
          objectId: v.id,
          objectType: ForecastObjectType.variable,
          budgetId: budget.id,
        };
      });
    }
    return [];
  }, [budget, variables]);

  const selectedVariableForecasts = useSelector((state: RootState) => {
    return getSelectedForecastsByIds(
      selectWorkspaceData(state),
      variableForecastIds,
    );
  });

  const variableForecasts = useSelector((state: RootState) => {
    if (budget && selectedVariableForecasts) {
      return getForecastsByIds(
        selectWorkspaceData(state),
        selectedVariableForecasts,
      );
    }
    return [];
  });

  const variableForecastElements = React.useMemo(() => {
    return compact(
      variableForecasts.map((forecast) => {
        const element = getForecastElement(forecast, undefined);
        if (element) {
          return { element, id: forecast.objectId };
        }
      }),
    );
  }, [variableForecasts]);

  const variableForecastEvaluators = React.useMemo(() => {
    return variableForecastElements.map((forecastElement) => {
      const evaluator = new ForecastEvaluator(
        forecastElement.element,
        numberOfDaysInPeriod,
      );
      return { evaluator, id: forecastElement.id };
    });
  }, [numberOfDaysInPeriod, variableForecastElements]);

  const getVariableValue = React.useCallback(
    (id: string, idx: number) => {
      const evaluator = variableForecastEvaluators.find((evaluator) => {
        return evaluator.id === id;
      });
      if (evaluator) {
        return evaluator.evaluator.values()[idx];
      }
    },
    [variableForecastEvaluators],
  );

  const evaluate = React.useCallback(
    (date: moment.Moment, idx: number) => {
      for (let j = 0; j < variableForecastEvaluators.length; ++j) {
        variableForecastEvaluators[j].evaluator.evaluate(date, idx);
      }
    },
    [variableForecastEvaluators],
  );

  return {
    getVariableValue,
    evaluate,
    variableForecastEvaluators,
  };
};
