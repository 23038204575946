import { parseFundTrade } from '~src/data/store/modules/holdings/fund/trade/parser';
import { subscribeToFundTrades } from '~src/data/store/modules/holdings/fund/trade/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  fundTradesActions,
  FundTradeState,
} from '~src/data/store/reducers/holding/holding-types/fund/fund-trade/reducer';
import { FundTrade } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface FundTradeVisitor {
  visit(trade: FundTradeVisitable): FundTradeState;
  post(): void;
}

export class FundTradeVisitable implements Visitable<FundTradeVisitor> {
  constructor(private _trade: FundTrade) {}
  public accept(visitor: FundTradeVisitor) {
    return visitor.visit(this);
  }

  public parse(): FundTradeState {
    return parseFundTrade(this._trade);
  }
}

export class FundTradeStateVisitor implements FundTradeVisitor {
  private _fundTrades: FundTradeState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._fundTrades = [];
  }

  public visit(trade: FundTradeVisitable): FundTradeState {
    const tradeState = trade.parse();
    this._fundTrades.push(tradeState);
    return tradeState;
  }

  post() {
    this._dispatch(
      fundTradesActions.upsertManyElements(this._fundTrades, {
        shouldAutobatch: true,
      }),
    );
    subscribeToFundTrades(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._fundTrades,
      this._subscriptions,
    );
  }
}
