import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useEntityFields } from '~src/hooks/fields/workspace/entity/use-entity-fields';
import { EntitiesToolbar } from '~src/domain/workspace/components/entity/entity/entities-toolbar.component';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { getEntitiesByWorkspaceId } from '~src/data/store/selectors/entities/entities/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';
import { useEntityActions } from '~src/hooks/actions/workspace/entity/use-entity-actions';

interface Props {
  workspace: WorkspaceState;
}

export type EntityData = EntityState;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    // marginTop: theme.spacing(2),
  },
}));

export const EntitiesComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const entities = useSelector((state: RootState) =>
    getEntitiesByWorkspaceId(selectWorkspaceData(state), props.workspace.id),
  );

  const fields = useEntityFields(props.workspace.tenantId, props.workspace.id);

  const [initialSorters] = React.useState<TableField.Sorter<EntityData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );
  const { onUpdate, onDelete } = useEntityActions(props.workspace);

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(entities, initialSorters, initialFilters);

  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<EntityData>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        editable={{ onRowUpdate: onUpdate, onRowDelete: onDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        // handleSetUpdateState={setUpdateState}
        isSelected={isSelected}
        paginationOptions={{
          initialNumberOfRows: 1000,
          rowsPerPageOptions: [10, 100, 1000],
        }}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onDelete,
    onUpdate,
    selectedData,
    sorters,
  ]);

  return (
    <div className={classes.root}>
      <EntitiesToolbar workspace={props.workspace} />
      <div className={classes.content}>{tableComponent}</div>
    </div>
  );
});
