import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import {
  createPortfolioConfigs,
  updatePortfolioConfigs,
} from '~src/data/store/modules/portfolio-configs/portfolio-configs/requests';
import { createPortfolioDashboardConfigs } from '~src/data/store/modules/portfolio-configs/portfolio-dashboard-configs/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';

export function usePortfolioConfigActions() {
  const dispatch = useDispatch<AppDispatch>();
  const create = React.useCallback(
    (
      workspace: WorkspaceState,
      portfolioId: string,
      // portfolioDashboardConfig: graphqlWorkspaceTypes.CreatePortfolioDashboardConfigInputType
    ) => {
      const portfolioDashboardConfig: graphqlWorkspaceTypes.CreatePortfolioDashboardConfigInputType =
        {
          id: uuid.v1(),
          elementOperations: [],
          name: 'default',
          layouts: JSON.stringify({}),
          compactType: 'vertical',
          // height: 500,
        };

      const createPortfolioConfig = (
        dashboardConfig: graphqlWorkspaceTypes.PortfolioDashboardConfig,
      ) => {
        const portfolioConfigInput: graphqlWorkspaceTypes.CreatePortfolioConfigInputType =
          {
            // id: -1,
            currentDashboardConfigId: dashboardConfig.id,
            portfolioDashboardConfigOperations: [
              {
                type: MultipleRelationOperationType.add,
                id: dashboardConfig.id,
              },
            ],
          };
        return createPortfolioConfigs(
          workspace.tenantId,
          workspace.id,
          portfolioId,
          [portfolioConfigInput],
        );
      };
      return dispatch(
        createPortfolioDashboardConfigs(workspace.tenantId, workspace.id, -1, [
          portfolioDashboardConfig,
        ]),
      ).then((dashboardConfigs) => {
        const portfolioConfigState = dashboardConfigs[0];
        const portfolioConfig: graphqlWorkspaceTypes.PortfolioDashboardConfig =
          {
            ...portfolioConfigState,
            layouts: JSON.stringify(portfolioConfigState.layouts),
          };
        return dispatch(createPortfolioConfig(portfolioConfig)).then(
          (portfolioConfigs) => {
            return portfolioConfigs;
          },
        );
      });
    },
    [dispatch],
  );

  const update = React.useCallback(
    (
      workspace: WorkspaceState,
      portfolioId: string,
      input: graphqlWorkspaceTypes.UpdatePortfolioConfigInputType,
    ) => {
      // const portfolioConfigInput: graphqlWorkspaceTypes.PortfolioConfigInputType =
      //   {
      //     id: portfolioConfig.id,
      //     currentDashboardConfigId: config.id,
      //     portfolioDashboardConfigOperations: [
      //       {
      //         type: MultipleRelationOperationType.add,
      //         id: config.id,
      //       },
      //     ],
      //   };
      return dispatch(
        updatePortfolioConfigs(workspace.tenantId, workspace.id, portfolioId, [
          input,
        ]),
      );
    },
    [dispatch],
  );

  return { create, update };
}
