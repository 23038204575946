import {
  StockGoalRuleStateVisitor,
  StockGoalRuleVisitable,
} from '~src/data/store/visitors/holding/types/stock/stock-goal-rule-visitor';

import { Operation } from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function handleStockGoalRules(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: graphqlWorkspaceTypes.StockGoalRule[],
  subscriptions: Operation[],
) {
  const goalRulesVisitor = new StockGoalRuleStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  const res = outputs.map((goalRule) => {
    const goalRuleVisitable = new StockGoalRuleVisitable(goalRule);
    return goalRuleVisitable.accept(goalRulesVisitor);
  });
  goalRulesVisitor.post();
  return res;
}
