import {
  FileStateVisitor,
  FileVisitable,
} from '~src/data/store/visitors/common/file-visitor';
import { File } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleFiles(dispatch: AppDispatch, outputs: File[]) {
  const fileVisitor = new FileStateVisitor(dispatch);
  const files = outputs.map((file) => {
    const visitable = new FileVisitable(file);
    return visitable.accept(fileVisitor);
  });
  fileVisitor.post();
  return files;
}
