import {
  TransferStateVisitor,
  TransferVisitable,
} from '~src/data/store/visitors/holding/transfer-visitor';
import { Transfer } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleTransfers(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Transfer[],
  subscriptions: Operation[],
) {
  const transferVisitor = new TransferStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((transfer) => {
    const transferVisitable = new TransferVisitable(
      transfer.holding ? transfer.holding.id : '',
      transfer,
    );
    transferVisitable.accept(transferVisitor);
  });
  transferVisitor.post();
  return outputs;
}
