import { combineReducers } from 'redux';

import {
  fundHoldingsReducer,
  initialFundHoldingState,
} from './fund-holding/reducer';
import {
  fundPositionsReducer,
  initialFundPositionState,
} from './fund-position/reducer';
import { fundTradesReducer, initialFundTradeState } from './fund-trade/reducer';

export const fundHoldingInitialState = {
  fundHoldings: initialFundHoldingState,
  fundPositions: initialFundPositionState,
  fundTrades: initialFundTradeState,
};

export const fundHoldingReducer = combineReducers({
  fundHoldings: fundHoldingsReducer,
  fundPositions: fundPositionsReducer,
  fundTrades: fundTradesReducer,
});
