import { papersActions } from '~src/data/store/reducers/finance/paper/reducer';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';
import { financeRequest } from '~src/services/request/graphql-request';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryFinanceContext,
} from '@pladdenico/portfolio-api';

import { handlePapers } from './handler';

export function fetchPapersBySymbols(
  symbols: Array<{ symbol: string; exchange: string }>,
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.GetPapersBySymbolsDocument;
    const variables: graphqlFinanceTypes.GetPapersBySymbolsQueryVariables = {
      symbols,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.getPapersBySymbols) {
        return handlePapers(dispatch, data.getPapersBySymbols);
      }
      return [];
    });
  };
}

export function fetchAndHandlePapers(ids: string[] | null) {
  return (dispatch: any) => {
    return fetchPapers(ids).then((papers) => {
      if (papers) {
        return handlePapers(dispatch, papers);
      }
      return [];
    });
  };
  // return (dispatch: any) => {
  //   const node = graphqlFinanceTypes.GetPapersDocument;
  //   const variables: graphqlFinanceTypes.GetPapersQueryVariables = {
  //     ids,
  //   };
  //   const context: QueryFinanceContext = {
  //     type: QueryContextTypes.finance,
  //   };
  //   return financeRequest(node, variables, context).then((data) => {
  //     if (data.getPapers) {
  //       return handlePapers(dispatch, data.getPapers);
  //     }
  //     return [];
  // });
}

export function fetchPapers(ids: string[] | null) {
  const node = graphqlFinanceTypes.GetPapersDocument;
  const variables: graphqlFinanceTypes.GetPapersQueryVariables = {
    ids,
  };
  const context: QueryFinanceContext = {
    type: QueryContextTypes.finance,
  };
  return financeRequest(node, variables, context)
    .then((data) => {
      return data.getPapers;
    })
    .catch((err) => {
      console.error(`Error fetchPapers: ', ${JSON.stringify(err)}`);
      return undefined;
    });
}

export function createPapers(
  inputs: graphqlFinanceTypes.CreatePaperInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.CreatePapersDocument;
    const variables: graphqlFinanceTypes.CreatePapersMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.createPapers) {
        return handlePapers(dispatch, data.createPapers);
      }
      return [];
    });
  };
}

export function updatePapers(
  inputs: graphqlFinanceTypes.UpdatePaperInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.UpdatePapersDocument;
    const variables: graphqlFinanceTypes.UpdatePapersMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.updatePapers) {
        return handlePapers(dispatch, data.updatePapers);
      }
      return [];
    });
  };
}

export function upsertPapers(
  inputs: graphqlFinanceTypes.UpsertPaperInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.UpsertPapersDocument;
    const variables: graphqlFinanceTypes.UpsertPapersMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.upsertPapers) {
        return handlePapers(dispatch, data.upsertPapers);
      }
      return [];
    });
  };
}

export function deletePapers(papers: Array<{ id: string }>) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.DeletePapersDocument;
    papers.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlFinanceTypes.DeletePaperInputType[] = papers.map(
      (paper) => {
        return {
          id: paper.id,
        };
      },
    );
    const variables: graphqlFinanceTypes.DeletePapersMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.deletePapers) {
        const deletedPapers = intersectionSorted(
          papers,
          data.deletePapers,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b };
          },
        );
        return dispatch(papersActions.removeManyElements(deletedPapers));
      }
      return [];
    });
  };
}
