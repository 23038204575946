import {
  BaseFileId,
  filesActions,
} from '~src/data/store/reducers/file/files/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleFiles } from './handler';

export function fetchFiles(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetFilesDocument;
    const variables: graphqlWorkspaceTypes.GetFilesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getFiles) {
        return handleFiles(dispatch, data.getFiles);
      }
      return undefined;
    });
  };
}

export function createFiles(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateFileInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateFilesDocument;
    const variables: graphqlWorkspaceTypes.CreateFilesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createFiles) {
        return handleFiles(dispatch, data.createFiles);
      }
      throw new Error('Could not create file');
      // return undefined;
    });
  };
}

export function updateFiles(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateFileInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateFilesDocument;
    const variables: graphqlWorkspaceTypes.UpdateFilesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateFiles) {
        return handleFiles(dispatch, data.updateFiles);
      }
      return [];
    });
  };
}

export function upsertFiles(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertFileInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertFilesDocument;
    const variables: graphqlWorkspaceTypes.UpsertFilesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertFiles) {
        return handleFiles(dispatch, data.upsertFiles);
      }
      return [];
    });
  };
}

export function deleteFiles(
  tenantId: string,
  workspaceId: string,
  ids: BaseFileId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteFilesDocument;
    const variables: graphqlWorkspaceTypes.DeleteFilesMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteFiles) {
        const deletedIds = data.deleteFiles;
        dispatch(
          filesActions.removeMany(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
