import { TransferType } from '@pladdenico/models';
import React from 'react';

interface Props {
  type: TransferType;
}

export function typeToString(type: string) {
  if (type === TransferType.Buy) {
    return 'Buy';
  } else if (type === TransferType.Cost) {
    return 'Cost';
  } else if (type === TransferType.Deposit) {
    return 'Deposit';
  } else if (type === TransferType.Dividend) {
    return 'Dividend';
  } else if (type === TransferType.Interest) {
    return 'Interest';
  } else if (type === TransferType.Provision) {
    return 'Provision';
  } else if (type === TransferType.RevenueFee) {
    return 'Revenue fee';
  } else if (type === TransferType.Sell) {
    return 'Sell';
  } else if (type === TransferType.Withdrawal) {
    return 'Withdrawal';
  } else if (type === TransferType.WrongPayment) {
    return 'WrongPayment';
  } else if (type === TransferType.Borrow) {
    return 'Borrow';
  } else if (type === TransferType.Repayment) {
    return 'Repayment';
  } else if (type === TransferType.Lend) {
    return 'Lend';
  }
  return type;
}

export const ViewTypeField = React.memo((props: Props) => {
  return <>{typeToString(props.type)}</>;
});
