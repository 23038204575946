import {
  ProjectReportStateVisitor,
  ProjectReportVisitable,
} from '~src/data/store/visitors/workspace/project/report/project-report-visitor';
import { ProjectReport } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export function handleProjectReports(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  projectReports: ProjectReport[],
  subscriptions: Operation[],
) {
  const projectReportVisitor = new ProjectReportStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  const reports = projectReports.map((projectReport) => {
    const assetVisitable = new ProjectReportVisitable(
      workspaceId,
      projectReport,
    );
    return assetVisitable.accept(projectReportVisitor);
  });
  projectReportVisitor.post();
  return reports;
}
