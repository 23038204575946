import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import {
  isNumberVariable,
  isStringVariable,
  ScenarioValueType,
  ScenarioVariable,
} from '~src/domain/workspace/components/project/scenario/models/variable';
import { ScenarioVariableNumberComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/variable-number.component';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  variable: ScenarioVariable<ScenarioValueType>;
  budget: Budget;
  forecast: ForecastState;
}
export const ScenarioVariableElement = React.memo((props: Props) => {
  const { workspace, project, scenario, variable, budget, forecast } = props;

  const variableElement = React.useMemo(() => {
    if (!forecast) {
      return;
    }
    if (isNumberVariable(variable)) {
      return (
        <>
          <ScenarioVariableNumberComponent
            workspace={workspace}
            project={project}
            scenario={scenario}
            variable={variable}
            budget={budget}
            forecast={forecast}
          />
        </>
      );
    } else if (isStringVariable(variable)) {
      return (
        <Box key={variable.id}>
          <Typography variant="body2">{variable.value}</Typography>
        </Box>
      );
    }
  }, [budget, forecast, project, scenario, variable, workspace]);
  return (
    <>
      <Grid container>
        <Grid xs={10}>
          <Typography variant="body2">{forecast.description}</Typography>
        </Grid>
      </Grid>
      {variableElement}
    </>
  );
});
