import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRRComponent } from '~src/components/summary/irr.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsXIRR } from '~src/data/store/selectors/finance/xirr/holdings-xirr';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';
import { Period } from '~src/utils/period/period';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  period: Period;
  baseCurrency: CurrencyState;
  useLiveValue: boolean;
  subLabel?: string;
}

export const IRRHoldingsComponent = React.memo((props: Props) => {
  // useComponentUpdateDebug('SummaryComponent', props);
  const {
    baseCurrency,
    date,
    holdings,
    projectId,
    useLiveValue,
    workspaceId,
    period,
    subLabel,
  } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const xirr = useSelector((state: RootState) =>
    getHoldingsXIRR(selectData(state), {
      workspaceId: workspaceId,
      projectId: projectId,
      holdings: holdings,
      date: date,
      currency: baseCurrency,
      startDate: period.start,
      endDate: period.end,
      filter,
      useLiveValue: useLiveValue,
    }),
  );

  return <IRRComponent irr={xirr} subLabel={subLabel} />;
});
