import { User } from '@pladdenico/models';
import {
  LocalStorage,
  LocalStorageValueType,
} from '~src/utils/storage/local-storage';

export class AuthenticationService {
  private _storage = new LocalStorage('authentication:');

  constructor() {
    // const dbgStorage = new LocalStorage('');
    // dbgStorage.set('debug', '*', LocalStorageValueType.string);
  }

  public authenticateUser(user: User, token: string) {
    this._storage.set('user', user, LocalStorageValueType.object);
    this._storage.set('token', token, LocalStorageValueType.string);
  }

  public setWorkspaceRoleId(workspaceRoleId: number) {
    this._storage.set(
      'workspaceRoleId',
      workspaceRoleId.toString(),
      LocalStorageValueType.string,
    );
  }

  public unsetWorkspaceRoleId() {
    this._storage.unset('workspaceRoleId');
  }

  public setWorkspaceId(workspaceId: string) {
    this._storage.set(
      'workspaceId',
      workspaceId.toString(),
      LocalStorageValueType.string,
    );
  }

  public setTenantId(tenantId: string) {
    this._storage.set(
      'tenantId',
      tenantId.toString(),
      LocalStorageValueType.string,
    );
  }

  public unsetTenantId() {
    this._storage.unset('workspaceId');
  }

  public unsetWorkspaceId() {
    this._storage.unset('workspaceId');
  }

  // public setPortfolioId(portfolioId: string) {
  //   this._storage.set('portfolioId', portfolioId, LocalStorageValueType.string);
  // }

  // public unsetPortfolioId() {
  //   this._storage.unset('portfolioId');
  // }

  public setProjectId(projectId: string) {
    this._storage.set('projectId', projectId, LocalStorageValueType.string);
  }

  public unsetProjectId() {
    this._storage.unset('projectId');
  }

  public isUserAuthenticated() {
    return (
      this._storage.get('user', LocalStorageValueType.object) !== null &&
      this._storage.get('token', LocalStorageValueType.string) !== null
    );
  }

  public deauthenticateUser() {
    this._storage.unset('user');
    this._storage.unset('token');
  }

  public getToken() {
    return this._storage.get('token', LocalStorageValueType.string);
  }

  public getUser(): User | undefined {
    return this._storage.get('user', LocalStorageValueType.object);
  }

  public getTenantId(): string | undefined {
    return this._storage.get('tenantId', LocalStorageValueType.string);
  }

  public getWorkspaceId(): string | undefined {
    return this._storage.get('workspaceId', LocalStorageValueType.string);
  }

  public getWorkspaceRoleId(): number | undefined {
    return this._storage.get('workspaceRoleId', LocalStorageValueType.number);
  }

  // public getPortfolioId(): string | undefined {
  //   return this._storage.get('portfolioId', LocalStorageValueType.string);
  // }

  public getProjectId(): string | undefined {
    return this._storage.get('projectId', LocalStorageValueType.string);
  }
}

export const authenticationService = new AuthenticationService();
