import { Visitable } from '~src/data/store/visitors/visitable';
import {
  GroupStateVisitor,
  GroupVisitable,
  GroupVisitor,
} from '~src/data/store/visitors/workspace/role/group-visitor';
import { parseWorkspaceRole } from '~src/data/store/modules/workspaces/workspace-roles/workspace-roles/parser';
import {
  workspaceRolesActions,
  WorkspaceRoleState,
} from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';
import { WorkspaceRole } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface WorkspaceRoleVisitor {
  visit(workspaceRole: WorkspaceRoleVisitable): WorkspaceRoleState;
  post(): void;
  groupVisitor: GroupVisitor;
}

export class WorkspaceRoleVisitable implements Visitable<WorkspaceRoleVisitor> {
  constructor(
    private _tenantId: string,
    private _workspaceRole: WorkspaceRole,
  ) {}
  public accept(visitor: WorkspaceRoleVisitor) {
    if (this._workspaceRole.group) {
      const groupVisitable = new GroupVisitable(this._workspaceRole.group);
      groupVisitable.accept(visitor.groupVisitor);
    }
    return visitor.visit(this);
  }

  public parse(): WorkspaceRoleState {
    return parseWorkspaceRole(this._tenantId, this._workspaceRole);
  }
}

export class WorkspaceRoleStateVisitor implements WorkspaceRoleVisitor {
  private _workspaceRoles: WorkspaceRoleState[];
  public groupVisitor: GroupVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._workspaceRoles = [];
    this.groupVisitor = new GroupStateVisitor(this._dispatch);
  }
  public visit(group: WorkspaceRoleVisitable): WorkspaceRoleState {
    const workspaceRoleState = group.parse();
    this._workspaceRoles.push(workspaceRoleState);
    return workspaceRoleState;
  }
  post() {
    this._dispatch(workspaceRolesActions.addMany(this._workspaceRoles));
    this.groupVisitor.post();
  }
}
