import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CustomRouterLink } from '~src/domain/workspace/components/drawer/items/custom-router';
import {
  workspaceGraphQLBasePath,
  workspaceGraphQLRootPath,
} from '~src/navigation/paths/workspace/graph-ql/paths';
import {} from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { drawerListItemButtonSX } from '~src/domain/workspace/components/drawer/items/drawer-list-item-button-sx';

export const drawerWidth = 240;

interface Props {
  tenant: TenantState;
  workspace: WorkspaceState;
}

export const WorkspaceGraphQLItemComponent = React.memo((props: Props) => {
  const { tenant, workspace } = props;

  const match = useMatch({ path: workspaceGraphQLBasePath, end: false });

  return (
    <ListItemButton
      component={CustomRouterLink}
      selected={match != null}
      to={workspaceGraphQLRootPath(tenant.id, workspace.id)}
      sx={drawerListItemButtonSX}
    >
      <ListItemIcon>
        <AccountTreeIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={'GraphQL Api'} />
    </ListItemButton>
  );
});
