import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { OrganizationData } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/organization/element';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useOrganizationForm = (
  organizationData: OrganizationData | undefined,
  update: (organizationData: OrganizationData) => void,
) => {
  const { control, watch, handleSubmit } = useForm<OrganizationData>({
    defaultValues: {
      organizationId: organizationData?.organizationId,
    },
  });

  const onSubmit: SubmitHandler<OrganizationData> = React.useCallback(
    (data) => {
      return update(data);
    },
    [update],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  const organizationField = useTextField(
    control,
    'organizationId',
    'Organization identifier',
    'NO-',
    { required: true },
  );

  return {
    organizationField,
  };
};
