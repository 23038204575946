import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { ReportState } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  selectUserData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getUsersByIds } from '~src/data/store/selectors/user/user/selectors';
import { getReportById } from '~src/data/store/selectors/workspace/projects/report/report/selectors';
import { getSchedulerById } from '~src/data/store/selectors/workspace/projects/report/scheduler/selectors';
import { EditReportUsersComponent } from '~src/domain/workspace/components/project/portfolio/reporting/edit-users.component';
import { EditReportComponent } from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/form/edit-report.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Chip,
  colors,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { MultipleRelationOperationType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { useReportMenu } from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/report-menu.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  reportId: string;
}

export const ScheduledReportComponentById = React.memo((props: Props) => {
  const { workspace, projectId, reportId } = props;
  const report = useSelector((state: RootState) =>
    getReportById(selectWorkspaceData(state), {
      projectId,
      id: reportId,
    }),
  );
  if (!report) {
    return <div>Could not find report {reportId}</div>;
  }
  return (
    <ScheduledReportComponent
      workspace={workspace}
      projectId={projectId}
      report={report}
    />
  );
});

interface PropsImpl {
  workspace: WorkspaceState;
  projectId: string;
  report: ReportState;
}

export const ScheduledReportComponent = React.memo((props: PropsImpl) => {
  const { workspace, projectId, report } = props;
  const scheduler = useSelector((state: RootState) =>
    getSchedulerById(selectWorkspaceData(state), { id: report.schedulerId }),
  );
  const users = useSelector((state: RootState) =>
    getUsersByIds(selectUserData(state), report.userIds),
  );
  const dispatch = useDispatch<AppDispatch>();
  const handleDelete = (userId: number) => {
    const userRemoved: graphqlWorkspaceTypes.MultipleRelationOperationIntInputType[] =
      [{ id: userId, type: MultipleRelationOperationType.remove }];
    dispatch(
      updateReports(workspace.tenantId, workspace.id, [
        {
          id: report.id,
          users: userRemoved,
        },
      ]),
    );
  };

  // const editReportModal = useModal();

  const editUsersModal = useVisible();
  const {
    editing,
    // handleClose,
    // handleOpen,
    menuButtonElement,
    menuElement,
    setEditing,
  } = useReportMenu(workspace, projectId, report);

  const userElements = users.map((user) => {
    return (
      <Grid item key={user.id}>
        <Chip
          variant="outlined"
          label={user.name}
          onDelete={() => handleDelete(user.id)}
        />
      </Grid>
    );
  });

  return (
    <Paper
      variant="outlined"
      sx={{ height: '100%', display: 'flex', flexFlow: 'column' }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={{ ml: 1 }}>
          <Typography variant="h6">{report.id}</Typography>
          <Typography variant="h6">{report.name}</Typography>
        </Grid>
        {menuButtonElement}
      </Grid>
      {menuElement}
      <Typography variant="body2" sx={{ ml: 1 }}>
        {report.description}
      </Typography>
      <Typography sx={{ ml: 1 }} color="text.secondary">
        Cron: {scheduler?.cron ?? ''}
      </Typography>
      <Box
        sx={{
          flex: 1,
          backgroundColor: colors.blue[50],
          ml: 0,
          mr: 0,
          pt: 1,
          pb: 1,
        }}
      >
        <Typography sx={{ ml: 1 }} variant="caption">
          Users
        </Typography>
        <Grid container columnSpacing={1} sx={{ ml: 1 }}>
          {userElements}
          <IconButton
            aria-label="delete"
            size="small"
            onClick={editUsersModal.open}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Box>
      <EditReportComponent
        handleCancel={() => setEditing(false)}
        handleClose={() => setEditing(false)}
        isShowing={editing}
        report={report}
        workspace={workspace}
      />
      <EditReportUsersComponent
        projectId={projectId}
        report={report}
        workspace={workspace}
        handleCancel={editUsersModal.close}
        handleClose={editUsersModal.close}
        isShowing={editUsersModal.isShowing}
      />
    </Paper>
  );
});
