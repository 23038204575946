import './app.css';
import { MathJaxContext } from 'better-react-mathjax';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useRoot } from '~src/domain/use-root';
import { theme } from '~src/theme-config';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const config = {
  loader: { load: ['[tex]/html'] },
  tex: {
    packages: { '[+]': ['html'] },
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
  },
};

const dndBackend = () => {
  if (isMobile) {
    // console.log('IS MOBILE', deviceDetect());
    return TouchBackend;
  } else {
    // console.log('IS NOT MOBILE', deviceDetect());
    // return HTML5Backend;
    return TouchBackend;
  }
};

export const App = () => {
  const root = useRoot();
  const router = createBrowserRouter(root);
  return (
    <DndProvider backend={dndBackend()} options={{ enableMouseEvents: true }}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MathJaxContext version={3} config={config}>
              <RouterProvider router={router} />
            </MathJaxContext>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </DndProvider>
  );
};
