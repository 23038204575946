import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseCapitalCallId {
  id: string;
  fundId: string;
}

export type CapitalCallState =
  graphqlWorkspaceTypes.PrivateEquityFundCapitalCall & {
    fundId: string;
  };

export function privateEquityFundToPrivateEquityFundCapitalCallState(
  privateEquityFund: CapitalCallState,
) {
  return privateEquityFund;
}

export const initialState: RecordState<CapitalCallState, string>[] = [];

const selectKeyId = (capitalCall: BaseCapitalCallId) => capitalCall.fundId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseCapitalCallId, b: BaseCapitalCallId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseCapitalCallId,
  CapitalCallState,
  string
>(initialState, selectKeyId, keyComparator, tComparator);

export const capitalCallsSlice = createBaseRecordSlice('capitalCalls', adapter);
export const capitalCallsSelectors = adapter.selectors;

export const capitalCallsReducer = capitalCallsSlice.reducer;
export const capitalCallsActions = capitalCallsSlice.actions;
