import { useDispatch } from 'react-redux';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import {
  createPeople,
  deletePeople,
  updatePeople,
} from '~src/data/store/modules/people/people/requests';

export function usePersonActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onUpdate = (oldData: PersonState, newData: PersonState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.UpdatePersonInputType = {
          id: newData.id,
          name: newData.name,
          description: newData.description,
        };
        dispatch(updatePeople(workspace.tenantId, workspace.id, [input]))
          .then((_people) => {
            resolve();
          })
          .catch((_error) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: PersonState) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deletePeople(workspace.tenantId, workspace.id, [oldData.id]),
      )
        .then(() => {
          resolve();
        })
        .catch((_error) => {
          reject();
        });
    });
  };

  const onCreate = (
    personInput: graphqlWorkspaceTypes.CreatePersonInputType,
  ) => {
    return dispatch(
      createPeople(workspace.tenantId, workspace.id, [personInput]),
    );
  };

  return { onUpdate, onDelete, onCreate };
}
