import { DashboardMediaState } from '~src/data/store/reducers/common/dashboard/media/reducer';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';

export interface FileRelationEntity {
  type: 'entity';
  item: EntityState;
}

export interface FileRelationDashboard {
  type: 'dashboard';
  item: DashboardMediaState;
}

export type FileRelation = FileRelationEntity | FileRelationDashboard;

export function isEntityRelation(
  relation: FileRelation,
): relation is FileRelationEntity {
  return relation.type === 'entity';
}

export function isDashboardRelation(
  relation: FileRelation,
): relation is FileRelationDashboard {
  return relation.type === 'dashboard';
}
