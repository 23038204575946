import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  defaultBackgroundColor,
  defaultFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/events/default-colors';
import { ProjectEventsDashboardComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/events/events-dashboard.component';
import { useEventMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/events/menu.component';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  config: Config;
  period: Period;
}

const getDefaultFontColor = () => {
  return defaultFontColor;
};

const getDefaultBackgroundColor = () => {
  return defaultBackgroundColor;
};

export const EventsElement = React.memo(
  ({
    workspace,
    project,
    config,
    // period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useEventMenu(element, remove, updated);

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? getDefaultFontColor(),
          backgroundColor:
            element.config.backgroundColor ?? getDefaultBackgroundColor(),
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <ProjectEventsDashboardComponent
            workspace={workspace}
            project={project}
            // events={events}
            config={config}
            // period={period}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
