import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import {
  // ForecastType,
  ForecastTypes,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';
import { ForecastElementBase } from './forecast-element-state';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';

export interface ForecastState {
  id: string;
  objectType: ForecastObjectType;
  objectId: string;
  budgetId: string;
  name: string;
  description: string;
  // type: ForecastType;
  element: ForecastElementBase;
  // element: ManualElementState | FlowElementState | ExpressionElementState;
}

export function isExpressionType(
  e: ForecastElementBase,
): e is ExpressionElementState {
  return e.type === ForecastTypes.Expression;
}

export function isManualType(e: ForecastElementBase): e is ManualElementState {
  return e.type === ForecastTypes.Manual;
}

export function isFlowType(e: ForecastElementBase): e is FlowElementState {
  return e.type === ForecastTypes.Flow;
}
