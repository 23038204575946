import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';

export const createStockReturnValuationExpression = (): Expression[] => {
  const valuationString: Expression = {
    value:
      'valuation: { id: uuidV1(), value: price * position, stock: {position: position, price: price} }',
    inResult: true,
  };

  const expressions = [valuationString];
  return expressions;
};
