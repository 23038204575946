import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { DataState } from '~src/data/store/reducers/reducers';
import { getFundById } from '~src/data/store/selectors/asset/asset-types/funds/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';

import { getQuotesByFund } from '../selectors';

interface Props {
  workspaceId: string;
  fundId: string;
}

export const getQuotesByFundId = createCachedSelector(
  // (state: DataState) => state,
  (state: DataState) => selectFinancialDataFromData(state),
  (state: DataState, props: Props) =>
    getFundById(selectWorkspaceDataFromData(state), {
      id: props.fundId,
      workspaceId: props.workspaceId,
    }),
  (state, fund) => {
    return fund ? getQuotesByFund(state, fund) : [];
  },
)({
  keySelector: (_state, props: Props) => props.fundId,
  selectorCreator: createSelector,
});

export const getLastQuoteByFundId = createCachedSelector(
  (state: DataState, props: Props) => getQuotesByFundId(state, props),
  (quotes) => {
    if (quotes != null) {
      return quotes.length > 0 ? quotes[-1] : undefined;
    }
  },
)({
  keySelector: (_state, props: Props) => props.fundId,
  selectorCreator: createSelector,
});
