import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { eventSelectors } from '~src/data/store/reducers/workspace/projects/events/event/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';

const getEventState = (state: WorkspaceDataState) =>
  state.workspace.project.event.events;

interface Id {
  projectId: string;
  id: string;
}

export const getEventById = createCachedSelector(
  (state: WorkspaceDataState, _id: Id) => getEventState(state),
  (_state: WorkspaceDataState, id: Id) => id,
  (events, id): EventState | undefined => {
    return eventSelectors.selectElementByT(events, id);
  },
)({
  keySelector: (_state, id) => `${id.projectId}:${id.id}`,
  selectorCreator: createSelector,
});

interface Ids {
  projectId: string;
  ids: string[];
}

export const getEventsByIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: Ids) => getEventState(state),
  (_state: WorkspaceDataState, ids: Ids) => ids,
  (events, ids): EventState[] => {
    return eventSelectors.selectElementsByTs(
      events,
      ids.ids.map((id) => {
        return { projectId: ids.projectId, id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
interface ProjectId {
  projectId: string;
}

export const getEventsByProjectId = createCachedSelector(
  (state: WorkspaceDataState, _projectId: ProjectId) => getEventState(state),
  (_state: WorkspaceDataState, projectId: ProjectId) => projectId.projectId,
  (events, projectId): EventState[] => {
    return eventSelectors.selectElementsByKey(events, projectId) ?? [];
  },
)({
  keySelector: (_state, projectId) => projectId.projectId,
  selectorCreator: createSelector,
});
