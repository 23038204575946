import * as React from 'react';
import { useSelector } from 'react-redux';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getTransactionById } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { getTransfersByTransactionId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { Divider, Grid, Typography } from '@mui/material';

import { TransferComponent } from './transaction-transfer.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  transactionId: string;
}

export const TransactionByIdComponent = React.memo((props: Props) => {
  const transaction = useSelector((state: RootState) =>
    getTransactionById(selectWorkspaceData(state), {
      id: props.transactionId,
      projectId: props.projectId,
    }),
  );

  if (!transaction) {
    return <p>Not a valid transaction id</p>;
  } else {
    return (
      <TransactionComponent
        workspace={props.workspace}
        transaction={transaction}
      />
    );
  }
});

interface PropsWithTransaction {
  workspace: WorkspaceState;
  transaction: TransactionState;
}

export const TransactionComponent = React.memo(
  (props: PropsWithTransaction) => {
    const transfers = useSelector((state: RootState) => {
      return getTransfersByTransactionId(
        selectWorkspaceData(state),
        props.transaction.id,
      );
    });
    const transferElements = transfers.map((transfer) => {
      return (
        <div key={transfer.id}>
          <TransferComponent transfer={transfer} workspace={props.workspace} />
          <Divider />
        </div>
      );
    });
    return (
      <Grid container pl={1} pr={1} flexGrow={1}>
        <Grid item xs={12}>
          <Typography color="textSecondary" gutterBottom>
            {props.transaction.title}
          </Typography>
          <Typography variant="h5" component="h2">
            {props.transaction.description}
          </Typography>
          {transferElements}
        </Grid>
      </Grid>
    );
  },
);
