// import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { BondPositionState } from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import { FundPositionState } from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { Identity } from '~src/utils/interfaces/identity';

export enum ValuationType {
  base,
  stock,
  bond,
  fund,
}

export interface Value {
  id: string;
  holdingId: string;
  type: ValuationType;
  date: Date;
}

export interface ValueBase extends Value {
  type: ValuationType.base;
  valuation: ValuationState;
  exchangeRate: number;
  currencyId: string;
}

export interface ValueStock extends Value {
  type: ValuationType.stock;
  position: StockPositionState;
  quote: QuoteState;
  // paper: PaperState;
  marketValue: number;
  currencyId: string;
}

export interface ValueBond extends Value {
  type: ValuationType.bond;
  position: BondPositionState;
  quote: QuoteState;
  // paper: PaperState;
  marketValue: number;
  currencyId: string;
}

export interface ValueFund extends Value {
  type: ValuationType.fund;
  position: FundPositionState;
  quote: QuoteState;
  // paper: PaperState;
  marketValue: number;
  currencyId: string;
}

export function isValueBase(value: ValueBase | ValueBase): value is ValueBase {
  return (value as ValueBase).type === ValuationType.base;
}

export function isValueStock(value: Value): value is ValueStock {
  return (value as ValueStock).type === ValuationType.stock;
}

export interface TypedValue extends Identity {
  base?: ValueBase;
  typed?: Value;
}
