import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntitiesByIds } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EventState } from '~src/data/store/state/workspace/project/event/event';

import { Box, Grid, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  event: EventState;
}

export const ProjectEventEntitiesComponent = React.memo((props: Props) => {
  const { workspace, event } = props;

  const entityIds = React.useMemo(() => {
    return event.entityIds.map((id) => {
      return { workspaceId: workspace.id, id };
    });
  }, [event.entityIds, workspace.id]);
  const entities = useSelector((state: RootState) =>
    getEntitiesByIds(selectWorkspaceData(state), entityIds),
  );
  const entityElements = entities.map((entity) => {
    return (
      <Box key={entity.id}>
        <Typography variant="body2">{entity.name}</Typography>
      </Box>
    );
  });
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body2">Entities</Typography>
      </Grid>
      <Grid item>{entityElements}</Grid>
    </Grid>
  );
});
