import {
  PortfolioConfigStateVisitor,
  PortfolioConfigVisitable,
} from '~src/data/store/visitors/workspace/project/portfolio/config/portfolio-config-visitor';
import { PortfolioConfig } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handlePortfolioConfigs(
  dispatch: any,
  portfolioId: string,
  outputs: PortfolioConfig[],
  tenantId: string,
  workspaceId: string,
  subscriptions: Operation[],
) {
  const visitor = new PortfolioConfigStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  outputs.forEach((config) => {
    const visitable = new PortfolioConfigVisitable(portfolioId, config);
    visitable.accept(visitor);
  });
  visitor.post();
  return outputs;
}
