import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import {
  BasePersonId,
  peopleSelectors,
  PersonState,
} from '../../../reducers/person/people/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';

const getPersonState = (state: WorkspaceDataState) => state.person.people;

export const getPeopleByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getPersonState(state),
  (_state: WorkspaceDataState, workspaceId: string | null) => workspaceId,
  (personState, workspaceId): PersonState[] => {
    if (!workspaceId) {
      return [];
    }
    return peopleSelectors.selectElementsByKey(personState, workspaceId) ?? [];
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

export const getPeopleByWorkspaceIds = createCachedSelector(
  (state: WorkspaceDataState) => getPersonState(state),
  (_state: WorkspaceDataState, workspaceIds: string[]) => workspaceIds,
  (personState, workspaceIds): PersonState[] => {
    return (
      peopleSelectors.selectElementsByKeys(personState, workspaceIds) ?? []
    );
  },
)({
  keySelector: (_state, workspaceIds) => JSON.stringify(workspaceIds),
  selectorCreator: createSelector,
});

export const getPeople = createSelector(
  (state: WorkspaceDataState) => getPersonState(state),
  (people) => {
    return peopleSelectors.selectAllElements(people);
  },
);

export const getPersonById = createCachedSelector(
  (state: WorkspaceDataState) => getPersonState(state),
  (_state: WorkspaceDataState, baseId: BasePersonId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BasePersonId) => baseId.workspaceId,
  (people, id, workspaceId): PersonState | undefined => {
    return peopleSelectors.selectElementByT(people, {
      id,
      workspaceId,
    });
  },
)({
  keySelector: (_state, basePersonId) =>
    `${basePersonId.workspaceId}:${basePersonId.id}`,
  selectorCreator: createSelector,
});
