// import { IIdentity } from '~src/utils/interfaces/identity';
// export interface Data extends IIdentity {
//   id: string;
// }

export interface ArrayAction<Data, Id> {
  type: 'set' | 'add' | 'upsert' | 'delete' | 'update';
  data?: Data | Data[];
  partialData?: Partial<Data>;
  id?: Id;
}

export const useArrayReducer =
  <Data, Id = string>(getId: (data: Data) => Id) =>
  (state: Data[], action: ArrayAction<Data, Id>): Data[] => {
    switch (action.type) {
      case 'set': {
        if (action.data && Array.isArray(action.data)) {
          return [...action.data];
        }
        return state;
      }
      case 'add': {
        if (action.data && !Array.isArray(action.data)) {
          const id = getId(action.data);
          const idx = state.findIndex((f) => getId(f) === id);
          if (idx === -1 && action.data) {
            return [...state, action.data];
          }
        }
        return state;
      }
      case 'update': {
        if (action.data && !Array.isArray(action.data)) {
          const id = getId(action.data);
          const idx = state.findIndex((f) => getId(f) === id);
          if (idx !== -1) {
            return [
              ...state.slice(0, idx),
              action.data,
              ...state.slice(idx + 1),
            ];
          }
        } else if (action.partialData && !Array.isArray(action.partialData)) {
          const id = action.id;
          const idx = state.findIndex((f) => getId(f) === id);
          if (idx !== -1) {
            const data = {
              ...state[idx],
              ...action.partialData,
            };
            return [...state.slice(0, idx), data, ...state.slice(idx + 1)];
          }
        }
        return state;
      }
      case 'upsert': {
        if (action.data && !Array.isArray(action.data)) {
          const id = getId(action.data);
          const idx = state.findIndex((f) => getId(f) === id);
          if (action.data) {
            if (idx !== -1) {
              return [
                ...state.slice(0, idx),
                action.data,
                ...state.slice(idx + 1),
              ];
            }
            return [...state, action.data];
          }
        }
        return state;
      }
      case 'delete': {
        if (action.data) {
          const id = action.id;
          const idx = state.findIndex((f) => getId(f) === id);
          if (idx !== -1) {
            return [...state.slice(0, idx), ...state.slice(idx + 1)];
          }
        }
        return state;
      }
      default:
        throw new Error();
    }
  };
