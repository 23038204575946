import { handleTransactions } from '~src/data/store/modules/holdings/transactions/transactions/handler';
import {
  BaseTransactionId,
  transactionsActions,
} from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchTransactions(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetTransactionsDocument;
    const variables: graphqlWorkspaceTypes.GetTransactionsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getTransactions) {
        return handleTransactions(
          dispatch,
          tenantId,
          workspaceId,
          data.getTransactions,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return undefined;
    });
  };
}

export function createTransactions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateTransactionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateTransactionsDocument;
    const variables: graphqlWorkspaceTypes.CreateTransactionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createTransactions) {
        return handleTransactions(
          dispatch,
          tenantId,
          workspaceId,
          data.createTransactions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      throw new Error('Could not create transaction');
      // return undefined;
    });
  };
}

export function updateTransactions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateTransactionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateTransactionsDocument;
    const variables: graphqlWorkspaceTypes.UpdateTransactionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateTransactions) {
        return handleTransactions(
          dispatch,
          tenantId,
          workspaceId,
          data.updateTransactions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertTransactions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertTransactionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertTransactionsDocument;
    const variables: graphqlWorkspaceTypes.UpsertTransactionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertTransactions) {
        return handleTransactions(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertTransactions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteTransactions(ids: BaseTransactionId[]) {
  return (dispatch: AppDispatch) => {
    dispatch(transactionsActions.removeManyElements(ids));
  };
}
export function deleteTransactions(
  tenantId: string,
  workspaceId: string,
  ids: BaseTransactionId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteTransactionsDocument;
    const variables: graphqlWorkspaceTypes.DeleteTransactionsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id: id.id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteTransactions) {
        const deletedIds = data.deleteTransactions;
        dispatch(
          handleDeleteTransactions(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
