import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { handleInputData } from '~src/services/xlsx/handlers/iinput-handler';
import { importer as holdingImporter } from '~src/services/xlsx/handlers/workspaces/projects/holdings/importer';
import { transferImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/transfers/mapper';
import { projectImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/mapper';
import { portfolioProjectImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/project-types/portfolio/mapper';
import { privateEquityFundProjectImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/project-types/private-equity-fund/mapper';
import { transactionImportHandler } from '~src/services/xlsx/handlers/workspaces/projects/transactions/mapper';
import { DataImporter } from '~src/services/xlsx/hooks/use-import';
import { StatusUpdater } from '~src/services/xlsx/hooks/use-update-status';
import { AppDispatch } from '~src/store/store';

export const importer: DataImporter = (
  workspace: WorkspaceState,
  data: Map<string, any[]>,
  statusUpdater: StatusUpdater,
) => {
  return (dispatch: AppDispatch) => {
    const projectPromises: Promise<boolean>[] = [];
    projectPromises.push(
      handleInputData(
        data,
        projectImportHandler(workspace, dispatch),
        statusUpdater,
      ).then(() => true),
    );
    return Promise.all(projectPromises).then((_projects) => {
      const projectEntityPromises: Promise<boolean>[] = [];
      projectEntityPromises.push(
        handleInputData(
          data,
          portfolioProjectImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      projectEntityPromises.push(
        handleInputData(
          data,
          privateEquityFundProjectImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      projectEntityPromises.push(
        handleInputData(
          data,
          transactionImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => {
          return dispatch(holdingImporter(workspace, data, statusUpdater)).then(
            () => {
              return handleInputData(
                data,
                transferImportHandler(workspace, dispatch),
                statusUpdater,
              ).then(() => true);
            },
          );
        }),
      );
      return Promise.all(projectEntityPromises).then(() => true);
    });
  };
};
