import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FundPositionState } from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectLastPosition } from '~src/data/store/selectors/holding/holding-types/fund/fund-position/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { useCalculatePositionShares } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/use-calculate-position-shares';
import { useTransferCostField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-cost-field';
import { useTransferCurrencyField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-currency-field';
import { useTransferDateField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-date-field';
import { useTransferStatusField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-status-field';
import { useTransferTypeField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-type-field';
import { useTransferValueField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-value-field';
import { useTransferFundPositionSharesField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/fund/use-position-shares-field';
import { useTransferFundTradeSharePriceField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/fund/use-trade-share-price-field';
import { useTransferFundTradeSharesField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/fund/use-trade-shares-field';

import { Grid } from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

interface Props<T extends FieldValues> {
  projectId: string;
  holding: HoldingState;
  control: Control<T>;
  getName: (name: Path<TransferInputData>) => Path<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: TransferFieldConfig;
  transferTypes?: TransferType[];
}

export const FundTransferFieldsControlledComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { control, setValue, fieldConfig, getName, transferTypes } = props;

  const tradeShares = useWatch({
    control,
    name: getName('data.trade.shares'),
  });

  const transferType = useWatch({
    control,
    name: getName('base.type'),
  }) as TransferType;

  // const transferValue = useWatch({
  //   control,
  //   name: getName('base.value'),
  // });

  const sharePrice = useWatch({
    control,
    name: getName('data.trade.sharePrice'),
  });

  const transferDate = useWatch({
    control,
    name: getName('base.date'),
  });

  const fundHoldingId = useWatch({
    control,
    name: getName('data.position.fundHoldingId'),
  });

  const lastFundPosition: FundPositionState | undefined = useSelector(
    (state: RootState) =>
      fundHoldingId &&
      selectLastPosition(selectWorkspaceData(state), fundHoldingId),
  ) as FundPositionState;

  const positionShares = useWatch({
    control,
    name: getName('data.position.shares'),
  });

  const fundPositionDate = useWatch({
    control,
    name: getName('data.position.date'),
  });

  const newShares = useCalculatePositionShares(
    lastFundPosition?.shares ?? 0,
    tradeShares ?? 0,
    transferType,
  );

  React.useEffect(() => {
    if (newShares !== positionShares) {
      setValue(
        getName('data.position.shares'),
        newShares as PathValue<T, Path<T>>,
      );
    }
  }, [setValue, positionShares, newShares, getName]);

  React.useEffect(() => {
    const value =
      tradeShares != null && sharePrice != null
        ? tradeShares * sharePrice
        : null;
    if (value != null) {
      setValue(getName('base.value'), value as PathValue<T, Path<T>>);
    }
  }, [getName, setValue, sharePrice, tradeShares]);

  React.useEffect(() => {
    if (
      transferDate &&
      (fundPositionDate == null ||
        transferDate.getTime() !== fundPositionDate.getTime())
    ) {
      setValue(getName('data.position.date'), transferDate);
    }
  }, [setValue, transferDate, fundPositionDate, getName]);
  const { element: fundTradeSharesElement, visible: fundTradeSharesVisible } =
    useTransferFundTradeSharesField(
      control,
      getName('data.trade.shares'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.data?.fund?.tradeShares,
    );

  const {
    element: fundTradeSharePriceElement,
    visible: fundTradeSharePriceVisible,
  } = useTransferFundTradeSharePriceField(
    control,
    getName('data.trade.sharePrice'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.data?.fund?.sharePrice,
  );
  const { element: fundPositionElement, visible: fundPositionVisible } =
    useTransferFundPositionSharesField(
      control,
      getName('data.position.shares'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.data?.fund?.position,
    );
  const { element: typeElement, visible: typeVisible } = useTransferTypeField(
    control,
    getName('base.type'),
    undefined,
    fieldConfig?.base.type,
    transferTypes,
  );
  const { element: dateElement, visible: dateVisible } = useTransferDateField(
    control,
    getName('base.date'),
    new Date() as PathValue<T, Path<T>>,
    fieldConfig?.base.date,
  );
  const { element: currencyElement, visible: currencyVisible } =
    useTransferCurrencyField(
      control,
      getName('base.currencyId'),
      undefined,
      fieldConfig?.base.currency,
    );
  const { element: valueElement, visible: valueVisible } =
    useTransferValueField(
      control,
      getName('base.value'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.base.value,
    );
  const { element: costElement, visible: costVisible } = useTransferCostField(
    control,
    getName('base.cost'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.base.cost,
  );
  const { element: statusElement, visible: statusVisible } =
    useTransferStatusField(
      control,
      getName('base.status'),
      undefined,
      fieldConfig?.base.status,
    );

  return (
    <>
      <Grid container columnSpacing={1}>
        {typeVisible && (
          <Grid item sm={4}>
            {typeElement}
          </Grid>
        )}
        {dateVisible && (
          <Grid item sm={4}>
            {dateElement}
          </Grid>
        )}
        {currencyVisible && (
          <Grid item sm={4}>
            {currencyElement}
          </Grid>
        )}

        {fundTradeSharesVisible && (
          <Grid item sm={4}>
            {fundTradeSharesElement}
          </Grid>
        )}
        {fundTradeSharePriceVisible && (
          <Grid item sm={4}>
            {fundTradeSharePriceElement}
          </Grid>
        )}

        {costVisible && (
          <Grid item sm={4}>
            {costElement}
          </Grid>
        )}
        {fundPositionVisible && (
          <Grid item sm={4}>
            {fundPositionElement}
          </Grid>
        )}
        {valueVisible && (
          <Grid item sm={4}>
            {valueElement}
          </Grid>
        )}
        {statusVisible && (
          <Grid item sm={4}>
            {statusElement}
          </Grid>
        )}
      </Grid>
    </>
  );
};
