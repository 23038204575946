// import React from 'react';
// import history from '~src/navigation/history';
import { userResetPasswordPath } from '~src/navigation/paths/user/paths';

import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { User } from '@pladdenico/models';
import { useNavigate } from 'react-router-dom';

interface Props {
  user: User;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));

export function ProfileComponent(props: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const gotoResetPassword = () => {
    navigate(userResetPasswordPath);
  };

  return (
    <Grid
      container
      // spacing={2}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <Typography variant="body1">{props.user.name}</Typography>
      <Typography variant="body1">{props.user.email}</Typography>
      <Button onClick={gotoResetPassword} color="primary" variant="outlined">
        Reset password
      </Button>
    </Grid>
  );
}
