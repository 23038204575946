import React from 'react';
import { useDispatch } from 'react-redux';
// import { CreateRegionsComponent } from './regions/create-regions.component';
// import { RegionsField } from './region-allocation/regions-field';
// import { CreateRegionAllocationFieldsComponent } from './region-allocation/create-fields.component';
import * as uuid from 'uuid';
import { createEntities } from '~src/data/store/modules/entities/entities/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { FieldsComponent } from './fields.component';

interface Props {
  workspace: WorkspaceState;
  handleClose: () => void;
}
const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const FormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [entityInput, setEntityInput] =
    React.useState<graphqlWorkspaceTypes.CreateEntityInputType>({
      id: uuid.v1(),
      sector: '',
      // regions: [],
      website: '',
      description: '',
      name: '',
      regionAllocationRelations: [],
    });

  const dispatch = useDispatch<AppDispatch>();

  const handleCreateEntity = (): void => {
    dispatch(
      createEntities(props.workspace.tenantId, props.workspace.id, [
        entityInput,
      ]),
    )
      .then((_entities) => {
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', entityInput, JSON.stringify(err));
      });
  };

  const form = (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container justifyContent="stretch">
        <FieldsComponent
          workspace={props.workspace}
          entityInput={entityInput}
          setEntityInput={setEntityInput}
        />
      </Grid>
    </form>
  );

  return (
    <>
      <DialogTitle id="form-dialog-title">Create entity</DialogTitle>
      <DialogContent>{form}</DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateEntity} color="primary">
          Next
        </Button>
      </DialogActions>
    </>
  );
});
