import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { BankNoteFromAssetComponent } from '~src/domain/workspace/components/asset/types/bank-notes/bank-note.component';
import { BondFromAssetComponent } from '~src/domain/workspace/components/asset/types/bonds/bond.component';
import { CryptoFromAssetComponent } from '~src/domain/workspace/components/asset/types/cryptos/crypto.component';
import { FundFromAssetComponent } from '~src/domain/workspace/components/asset/types/funds/fund.component';
import { LoanFromAssetComponent } from '~src/domain/workspace/components/asset/types/loans/loan.component';
import { StockFromAssetComponent } from '~src/domain/workspace/components/asset/types/stocks/stock.component';
import { WarrantFromAssetComponent } from '~src/domain/workspace/components/asset/types/warrants/warrant.component';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';

import { Box, CircularProgress } from '@mui/material';
import { AssetType } from '@pladdenico/models';

interface Props {
  assetId: string;
  workspace: WorkspaceState;
}

export const AssetComponent = React.memo((props: Props) => {
  const { assetId, workspace } = props;
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: assetId,
      workspaceId: workspace.id,
    }),
  );

  const entity = useSelector((state: RootState) => {
    return getEntityById(selectWorkspaceData(state), {
      id: asset?.entityId,
      workspaceId: workspace.id,
    });
  });

  if (!asset || !entity) {
    return <CircularProgress />;
  }

  let assetTypedElement;
  if (asset.type === AssetType.Stock) {
    assetTypedElement = (
      <StockFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  } else if (asset.type === AssetType.BankNote) {
    assetTypedElement = (
      <BankNoteFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  } else if (asset.type === AssetType.Fund) {
    assetTypedElement = (
      <FundFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  } else if (asset.type === AssetType.Bond) {
    assetTypedElement = (
      <BondFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  } else if (asset.type === AssetType.Crypto) {
    assetTypedElement = (
      <CryptoFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  } else if (asset.type === AssetType.Loan) {
    assetTypedElement = (
      <LoanFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  } else if (asset.type === AssetType.Warrant) {
    assetTypedElement = (
      <WarrantFromAssetComponent
        workspace={workspace}
        entity={entity}
        asset={asset}
      />
    );
  }

  return (
    <Box sx={{ backgroundColor: primaryBackgroundColor.veryLight }}>
      {assetTypedElement}
    </Box>
  );
});
