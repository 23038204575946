export const PivotTypes = {
  Id: 'id',
  AssetClass: 'assetClass',
  Category: 'category',
  Sector: 'sector',
  Region: 'region',
  Currency: 'currency',
} as const;

export type PivotType = (typeof PivotTypes)[keyof typeof PivotTypes];
export function typeToString(type: PivotType) {
  if (type === PivotTypes.Id) {
    return 'Id';
  } else if (type === PivotTypes.AssetClass) {
    return 'Asset class';
  } else if (type === PivotTypes.Category) {
    return 'Category';
  } else if (type === PivotTypes.Sector) {
    return 'Sector';
  } else if (type === PivotTypes.Region) {
    return 'Region';
  } else if (type === PivotTypes.Currency) {
    return 'Currency';
  }
  return 'undefined';
}
