import {
  User,
  defaultUserPropertiesConfig,
  UserPropertiesConfig,
} from './user';
import { MultipleRelationOperation } from '../relation';

export interface Group {
  id: number;
  name: string;
  userIds: number[] | null;
  users: User[] | null;
}

export interface GroupCreateInput {
  name: string;
  // userIds: number[];
  // userRelations?: IRelation[];
  userRelations: MultipleRelationOperation[];
  // userOperation?: ISingleRelationOperation;
}

export interface GroupUpdateInput {
  id: number;
  name?: string;
  // userIds?: number[];
  userRelations?: MultipleRelationOperation[];

  // userRelations?: IRelation[];
  // userOperations?: IRelationOperations;
}

export interface GroupProperties {
  id?: boolean;
  name?: boolean;
  userIds?: boolean;
}

interface GroupRelationProperties {
  users?: UserPropertiesConfig;
}

export interface GroupPropertiesConfig {
  properties: GroupProperties;
  relationProperties?: GroupRelationProperties;
}

export interface GroupDeleteInput {
  id: number;
}

export function defaultGroupProperties(): GroupProperties {
  return {
    id: true,
    name: true,
    userIds: true,
  };
}

export function defaultGroupRelationProperties(): GroupRelationProperties {
  return {
    users: defaultUserPropertiesConfig(),
    // events: defaultEventProperties(),
  };
}

export function defaultGroupPropertiesConfig(): GroupPropertiesConfig {
  return {
    properties: defaultGroupProperties(),
    relationProperties: defaultGroupRelationProperties(),
  };
}
