import { combineReducers } from 'redux';

import {
  capitalCallsReducer,
  initialState as initialCapitalCallsState,
} from './capital-calls/reducer';
import {
  contactsReducer,
  initialState as initialContactsState,
} from './contacts/reducer';
import {
  initialState as initialInvestorsState,
  investorsReducer,
} from './investors/reducer';
import {
  initialState as initialPrivateEquityFundsState,
  privateEquityFundsReducer,
} from './private-equity-funds/reducer';
import { initialSetupState, setupsReducer } from './setups/reducer';
import {
  initialState as initialInvestmentsState,
  investmentsReducer,
} from './investments/reducer';

export const initialPrivateEquityFundState = {
  privateEquityFunds: initialPrivateEquityFundsState,
  investors: initialInvestorsState,
  investments: initialInvestmentsState,
  contacts: initialContactsState,
  capitalCalls: initialCapitalCallsState,
  setups: initialSetupState,
};

export const privateEquityFundReducer = combineReducers({
  privateEquityFunds: privateEquityFundsReducer,
  capitalCalls: capitalCallsReducer,
  investors: investorsReducer,
  investments: investmentsReducer,
  contacts: contactsReducer,
  setups: setupsReducer,
});
