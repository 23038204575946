import { Tab } from '~src/utils/interfaces/tab';

import { TenantTabs } from './tenant-tabs.component';
import { workspacesBasePath } from '~src/navigation/paths/workspace/paths';
import { tenantMembersBasePath } from '~src/navigation/paths/tenant/members/paths';
import { tenantInvitationsBasePath } from '~src/navigation/paths/tenant/invitations/paths';
import { tenantSettingsBasePath } from '~src/navigation/paths/tenant/settings/paths';

function createTabs(): Tab<TenantTabs>[] {
  const tabs: Tab<TenantTabs>[] = [];

  {
    const path = {
      tab: TenantTabs.Workspaces,
      exact: true,
      path: workspacesBasePath,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: TenantTabs.Members,
      exact: true,
      path: tenantMembersBasePath,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: TenantTabs.Settings,
      exact: true,
      path: tenantSettingsBasePath,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: TenantTabs.Invitations,
      exact: true,
      path: tenantInvitationsBasePath,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  return tabs;
}

export function useTenantTabs() {
  return createTabs();
}
