import * as React from 'react';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { isValueStock } from '~src/data/store/selectors/finance/valuation/valuation-types';
import { ValuationData } from '~src/domain/workspace/components/project/valuation/valuations.component';
import { useCurrencyField } from '~src/hooks/fields/financial/currency/use-currency-field';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField, Typography } from '@mui/material';
import { TableField } from '@pladdenico/table';

const updateValuation = (
  data: ValuationData,
  key: keyof ValuationState,
  v: unknown,
): ValuationData => {
  const base = data.base;
  if (base != null) {
    const valuation = base.valuation;
    if (valuation) {
      return {
        ...data,
        base: {
          ...base,
          valuation: {
            ...valuation,
            [key]: v,
          },
        },
      };
    }
  }
  return data;
};

function getCurrencyId(data: ValuationData) {
  const typed = data.typed;
  if (data.base?.valuation) {
    return data.base?.valuation?.currencyId;
  } else if (typed && isValueStock(typed)) {
    return typed.currencyId;
  }
  return '';
}

function updateWithCurrency(data: ValuationData, currency: CurrencyState) {
  return updateValuation(data, 'currencyId', currency.id);
}

export function useValuationFields(
  _workspace: WorkspaceState,
  _projectId: string,
): TableField.Field<ValuationData>[] {
  const currencyField = useCurrencyField(getCurrencyId, updateWithCurrency);
  const fields: TableField.Field<ValuationData>[] = [
    {
      ...currencyField,
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'date',
      name: 'date',
      field: 'date',
      type: TableField.FieldTypes.date,
      iteratee: (data) => {
        const typed = data.typed;
        if (data.base?.valuation) {
          return data.base?.valuation?.date;
        } else if (typed && isValueStock(typed)) {
          return typed.date;
        }
      },
      update: (data, date: Date) => {
        return updateValuation(data, 'date', date);
      },
      renderEdit: (editProps) => {
        return (
          <EditDateField
            handleValue={editProps.onChange}
            date={editProps.data.base?.valuation?.date}
            labelled={false}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    // {
    //   id: 'status',
    //   name: 'status',
    //   field: 'status',
    //   type: TableField.FieldTypes.string,
    //   iteratee: (data) => data.valuation.status,
    //   update: (data, status: ValuationStatus) => {
    //     const valuation = { ...data.valuation, status };
    //     return {
    //       ...data,
    //       valuation,
    //     };
    //   },
    //   renderView: (viewProps) => {
    //     const status = viewProps.data.valuation.status
    //       ? viewProps.data.valuation.status
    //       : ValuationStatus.expected; // Should be unknown
    //     return <ViewStatusField status={status} />;
    //   },
    //   renderEdit: (editProps) => {
    //     const status = editProps.data.valuation.status
    //       ? editProps.data.valuation.status
    //       : ValuationStatus.expected; // Should be unknown
    //     return (
    //       <EditStatusField
    //         handleValue={editProps.onChange}
    //         status={status}
    //         labelled={false}
    //       />
    //     );
    //   },
    //   style: {
    //     minWidth: 140,
    //   },
    // },
    {
      id: 'value',
      name: 'value',
      field: 'value',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => {
        const { base } = data;
        if (base?.valuation) {
          return base?.valuation?.value;
        }
      },
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        return updateValuation(data, 'value', eventParser(event) as number);
      },
      renderView: (viewProps) => {
        const { base } = viewProps.data;
        let value;
        if (base?.valuation) {
          value = base?.valuation?.value;
        }
        return <>{value}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="value"
            value={editProps.data.base?.valuation?.value}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'marketValue',
      name: 'marketValue',
      field: 'marketValue',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => {
        const { typed } = data;
        if (typed && isValueStock(typed)) {
          return typed.marketValue;
        }
      },
      // update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
      //   return updateValuation(data, 'value', eventParser(event) as number);
      // },
      renderView: (viewProps) => {
        const { typed } = viewProps.data;
        let value;
        if (typed && isValueStock(typed)) {
          value = (
            <>
              {' '}
              <Typography>Value: {typed.marketValue}</Typography>
              <Typography>Shares: {typed.position.shares}</Typography>
              <Typography>Close: {typed.quote.close}</Typography>
            </>
          );
        }
        return <div>{value}</div>;
      },
      renderEdit: (editProps) => {
        const { typed } = editProps.data;
        let value;
        if (typed && isValueStock(typed)) {
          value = typed.marketValue;
        }
        return (
          <TextField
            margin="normal"
            required
            id="marketValue"
            value={value}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'ownershipPercentage',
      name: 'ownershipPercentage',
      field: 'ownershipPercentage',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.base?.valuation?.ownershipPercentage,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        return updateValuation(
          data,
          'ownershipPercentage',
          eventParser(event) as number,
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="ownershipPercentage"
            value={editProps.data.base?.valuation?.ownershipPercentage}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return fields;
}
