import React from 'react';

interface Status {
  date: Date;
  status: string;
  error: any;
}

export enum RunStatus {
  notStarted,
  running,
  failed,
  success,
}

export type StatusUpdater = (
  status: string,
  runStatus: RunStatus,
  error: any,
) => void;

export const useUpdateStatus = () => {
  const [showStatus, setShowStatus] = React.useState(false);
  const [runStatus, setRunStatus] = React.useState<RunStatus>(
    RunStatus.notStarted,
  );
  const [status, setStatus] = React.useState<Status[]>([]);

  const clearStatus = React.useCallback(() => {
    setStatus([]);
  }, []);

  const updateStatus: StatusUpdater = React.useCallback(
    (status: string, runStatus: RunStatus, error: any) => {
      setStatus((importingStatus) => [
        ...importingStatus,
        {
          date: new Date(),
          status,
          error,
        },
      ]);
      setRunStatus(runStatus);
    },
    [],
  );

  return {
    clearStatus,
    setShowStatus,
    showStatus,
    runStatus,
    status,
    updateStatus,
  };
};
