import React from 'react';
import { FileState } from '~src/data/store/reducers/file/files/reducer';

import {
  Box,
  Button,
  IconButton,
  ImageListItemBar,
  Menu,
  MenuItem,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDeleteImage } from '~src/components/utils/image/use-delete-image';

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    // color: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    '&:hover': {
      opacity: '75%',
    },
    transition: '.5s ease',
  },
}));

const Overlay = styled(Button)(() => ({
  position: 'absolute',
  top: '25%',
  // background: 'rgba(255, 255, 255, 0.1)',
  color: '#f1f1f1',
  // width: '100%',
  transition: '.5s ease',
  opacity: 0,
  fontSize: '20px',
  padding: '20px',
  textAlign: 'center',
  zIndex: 10,
}));

interface Props {
  file: FileState;
  url: { id: string; url: string } | undefined;
  actions: {
    deleteFile?: () => void;
    selectFile?: () => void;
    editFile?: () => void;
  };
}

interface ImageListItemContainerProps extends React.PropsWithChildren<unknown> {
  selectFile?: () => void;
}

export const ImageListItemContainerComponent = (
  props: ImageListItemContainerProps,
) => {
  const [overlayStyle, setOverlayStyle] = React.useState({ opacity: 0 });
  const useElement = props.selectFile && (
    <Overlay style={{ ...overlayStyle }} variant="contained">
      Use
    </Overlay>
  );
  return (
    <Box
      sx={{
        position: 'relative',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
      }}
      onMouseEnter={(_e) => {
        setOverlayStyle({ opacity: 0.5 });
      }}
      onMouseLeave={(_e) => {
        setOverlayStyle({ opacity: 0 });
      }}
      onClick={(e) => {
        props.selectFile && props.selectFile();
        e.stopPropagation();
      }}
    >
      {useElement}
      {props.children}
    </Box>
  );
};

export const ImageComponent = (props: Props) => {
  const { classes } = useStyles();
  const { file: file, url, actions } = props;

  const useActions = React.useMemo(() => {
    return !!actions.deleteFile || !!actions.selectFile || !!actions.editFile;
  }, [actions]);

  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();

  const { confirmElement, deleteElement } = useDeleteImage({
    file,
    deleteFile: actions.deleteFile,
  });

  const menuElement = React.useMemo(
    () =>
      useActions && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          // id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isOpen}
          onClose={handleClose}
        >
          {actions.editFile && (
            <MenuItem onClick={actions.editFile}>Edit</MenuItem>
          )}
          {deleteElement}
        </Menu>
      ),
    [
      useActions,
      anchorEl,
      isOpen,
      handleClose,
      actions.editFile,
      deleteElement,
    ],
  );

  const menuButtonElement = React.useMemo(
    () =>
      actions && (
        <IconButton onClick={handleOpen} sx={{ color: 'white' }}>
          <MoreVertIcon />
        </IconButton>
      ),
    [actions, handleOpen],
  );

  return (
    <>
      <ImageListItemContainerComponent selectFile={actions.selectFile}>
        <img
          src={url?.url}
          alt={file.name ?? ''}
          className={classes.img}
          onDragStart={(e) => e.preventDefault()}
        />
        {useActions && (
          <ImageListItemBar
            title={file.name}
            classes={{
              root: classes.titleBar,
              title: classes.title,
            }}
            actionIcon={menuButtonElement}
          />
        )}
        {menuElement}
        {confirmElement}
      </ImageListItemContainerComponent>
    </>
  );
};
