import { Control } from 'react-hook-form';
import { useCurrencyField } from '~src/components/fields/financial/currency/use-currency.field';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputBankNoteData } from '~src/domain/workspace/components/asset/types/bank-notes/form/bank-note-input.data';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useBankNoteFields = (
  _workspace: WorkspaceState,
  control: Control<InputBankNoteData, any>,
) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const descriptionField = useTextField(
    control,
    'description',
    'Description',
    '',
  );
  const accountNumberField = useTextField(
    control,
    'accountNumber',
    'Account number',
    '',
  );
  const bankField = useTextField(control, 'bank', 'Bank', '');

  const currencyField = useCurrencyField(control, 'currencyId', 'Currency', '');
  return {
    nameField,
    descriptionField,
    accountNumberField,
    bankField,
    currencyField,
  };
};
