import * as React from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getBankNoteById } from '~src/data/store/selectors/asset/asset-types/bank-notes/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getBankNoteHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bank-note/bank-note-holding/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';

import { EditDialogComponent } from './form/edit-dialog.component';
import { ViewComponent } from './view.component';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const BankNoteHoldingComponent = React.memo((props: Props) => {
  const bankNoteHolding = useSelector((state: RootState) =>
    getBankNoteHoldingByHoldingId(selectWorkspaceData(state), props.holding.id),
  );
  const bankNote = useSelector((state: RootState) =>
    getBankNoteById(selectWorkspaceData(state), {
      id: bankNoteHolding ? bankNoteHolding.bankNoteId : '',
      workspaceId: props.workspace.id,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: bankNote ? bankNote.assetId : undefined,
      workspaceId: props.workspace.id,
    }),
  );
  const baseCurrencyId = useSelector((state: RootState) =>
    getSelectedCurrencyState(selectFinancialData(state)),
  );
  // const selectGetCurrencyById = React.useMemo(makeGetCurrencyById, []);
  let baseCurrency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), {
      id: baseCurrencyId ?? '',
    }),
  );

  const [editing, setEditing] = React.useState(false);

  if (!baseCurrency) {
    baseCurrency = defaultCurrency;
  }

  if (!bankNoteHolding) {
    return <div>No matching BankNote Holding for holding</div>;
  }

  const holdingElement = (
    <ViewComponent
      workspace={props.workspace}
      holding={props.holding}
      bankNoteHolding={bankNoteHolding}
      projectId={props.projectId}
      asset={asset}
      startEditing={() => setEditing(true)}
      portfolios={props.portfolios}
    />
  );

  const editDialogComponent = (
    <EditDialogComponent
      open={editing}
      handleClose={() => setEditing(false)}
      holding={props.holding}
      bankNoteHolding={bankNoteHolding}
      workspace={props.workspace}
      projectId={props.projectId}
      portfolios={props.portfolios}
    />
  );

  return (
    <>
      {holdingElement}
      {editDialogComponent}
    </>
  );
});
