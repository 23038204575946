import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertTransfers } from '~src/data/store/modules/holdings/transfers/transfers/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'transfers';

export interface Transfer {
  id: string;
  holdingId: string;
  transactionId: string;
  currencyId: string;
  date?: Date;
  value?: Maybe<number>;
  cost?: Maybe<number>;
  subtype?: Maybe<string>;
  status?: Maybe<number>;
  type?: Maybe<string>;
  description?: Maybe<string>;
}

const header: Array<keyof Transfer> = [
  'id',
  'holdingId',
  'transactionId',
  'currencyId',
  'date',
  'value',
  'cost',
  'type',
  'subtype',
  'status',
  'description',
];

export const transferExportHandler = (
  data: Transfer[],
): ExportHandler<Transfer> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const transferImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Transfer> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertTransfers(workspace.tenantId, workspace.id, ts)),
  };
};
