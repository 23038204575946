import moment from 'moment';
import React from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { Valuation } from '~src/domain/workspace/components/project/scenario/models/valuation';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  valuations: Valuation[];
}

interface Data {
  date: number;
  valuation: number;
}

const mergeData = (data: Data[]) => {
  const mergedData: Data[] = [];
  for (let i = 0; i < data.length; ++i) {
    const valuation = data[i];
    const unixTime = valuation.date;
    const prevValuation = mergedData.at(-1);
    const prevDate = prevValuation?.date;
    if (prevValuation == null || prevDate == null || prevDate < unixTime) {
      mergedData.push(valuation);
    } else {
      const prevValuationValue = prevValuation.valuation ?? 0;
      const valuationValue = valuation.valuation ?? 0;
      prevValuation.valuation = prevValuationValue + valuationValue;
    }
  }
  return mergedData;
};

const createData = (valuations: Valuation[]) => {
  let cfs = [
    ...valuations.map((valuation) => {
      return {
        date: valuation.date.unix(),
        valuation: valuation.value,
      };
    }),
  ];
  cfs = cfs.sort((a, b) => a.date - b.date);
  cfs = mergeData(cfs);
  const data: Data[] = cfs.map((v) => {
    return {
      date: v.date,
      valuation: v.valuation,
    };
  });
  const lastData = data.at(-1);
  if (lastData) {
    data.push({
      date: lastData.date + 5 * 60 * 60 * 24,
      valuation: lastData.valuation,
    });
  }
  return data;
};

export const ValuationChartComponent = React.memo((props: Props) => {
  const { valuations } = props;
  const data = createData(valuations);
  const lastDate = data.at(data.length - 1);
  const maxDate = lastDate?.date ?? moment().add(1, 'day').unix();
  const gradientOffset = () => {
    const dataMax = data.reduce((prev, curr) => {
      const valuation = curr.valuation;
      if (valuation == null) {
        return prev;
      }
      return Math.max(prev, valuation);
    }, 0);
    const dataMin = data.reduce((prev, curr) => {
      const accumulatedValuation = curr.valuation;
      if (accumulatedValuation == null) {
        return prev;
      }
      return Math.min(prev, accumulatedValuation);
    }, 0);

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();
  return (
    <ComposedChart
      width={500}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        dataKey="date"
        type="number"
        tickFormatter={(date: number) => {
          return moment.unix(date).format('DD.MM.YYYY');
        }}
        domain={[
          moment().add(-1, 'day').unix(),
          moment.unix(maxDate).add(1, 'day').unix(),
        ]}
      />
      <YAxis />
      <Tooltip />
      <Legend />
      <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
          <stop offset={off} stopColor="green" stopOpacity={1} />
          <stop offset={off} stopColor="red" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Line dataKey="valuation" fill="red" />
    </ComposedChart>
  );
});
