import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { selectedProjectsActions } from '~src/data/store/reducers/workspace/projects/selected/reducer';
// import history from '~src/navigation/history';
import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';
import { AppDispatch } from '~src/store/store';
import { getInitials } from '~src/utils/common/get-initials';

import { Avatar, colors, Link, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableField } from '@pladdenico/table';
import { useNavigate } from 'react-router-dom';

export type ProjectData = ProjectState;

const useStyles = makeStyles()((theme: Theme) => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const useAvatarColorStyles = makeStyles()((theme: Theme) => ({
  color1: {
    backgroundColor: colors.green[100],
    contrastText: theme.palette.getContrastText(colors.green[100]),
  },

  color2: {
    backgroundColor: colors.red[100],
    contrastText: theme.palette.getContrastText(colors.red[100]),
  },

  color3: {
    backgroundColor: colors.blue[100],
    contrastText: theme.palette.getContrastText(colors.blue[100]),
  },

  color4: {
    backgroundColor: colors.yellow[100],
    contrastText: theme.palette.getContrastText(colors.green[100]),
  },
}));

const getAvatarColor = (array: Array<[string, string]>, seed: string) => {
  return array[seed.length % array.length][1];
};

export function useProjectFields(
  tenantId: string,
  workspaceId: string,
): TableField.Field<ProjectData>[] {
  const { classes, cx } = useStyles();
  const { classes: avatarColorClasses } = useAvatarColorStyles();
  const avatarColorClassesArray = Object.entries(avatarColorClasses);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleProjectClick = (
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    projectId: string,
  ) => {
    // dispatch(selectProject(project.id));
    dispatch(selectedProjectsActions.set(projectId));
    navigate(workspaceProjectRootPath(tenantId, workspaceId, projectId));
  };

  const fields: TableField.Field<ProjectData>[] = [
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.name,
      renderView: (viewProps) => {
        const name = viewProps.data.name ? viewProps.data.name : '';
        return (
          <div className={classes.nameContainer}>
            <Avatar
              variant="square"
              sx={{ width: 32, height: 32 }}
              className={cx(
                classes.avatar,
                getAvatarColor(avatarColorClassesArray, name),
              )}
            >
              {/* src={user.avatarUrl} */}
              {getInitials(name)}
            </Avatar>
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                handleProjectClick(
                  dispatch,
                  tenantId,
                  workspaceId,
                  viewProps.data.id,
                )
              }
            >
              {viewProps.data.name}
            </Link>
          </div>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="name"
            value={editProps.data.name}
            onChange={editProps.onChange}
          />
        );
      },
    },
  ];
  return fields;
}
