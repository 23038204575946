import React, { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';
// import {
//   drawerWidth,
//   // WorkspaceDrawerComponent,
// } from '~src/domain/workspace/components/workspace-drawer.component';
import {
  TopBarComponent,
  useToolbarStyles,
} from '~src/components/top-bar/top-bar.component';
import { TopBarDesktopComponent } from '~src/domain/tenant/components/top-bar/top-bar.desktop.component';
import { TopBarMobileComponent } from '~src/domain/tenant/components/top-bar/top-bar.mobile.component';

import CssBaseline from '@mui/material/CssBaseline';
import { styled, Theme } from '@mui/material/styles';

const Main = styled('main')(() => ({
  // width: `calc(100% - ${drawerWidth}px)`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: '100vw',
    display: 'flex',
    flex: 1,
  },
  content: {
    // width: `calc(100% - ${drawerWidth}px)`,
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100vh',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...(theme.mixins.toolbar as CSSProperties),
  },
}));

export const MainComponent = React.memo(
  (props: React.PropsWithChildren<unknown>) => {
    const { classes } = useStyles();
    const styles = useToolbarStyles();

    const desktopTopBar = React.useMemo(() => {
      return <TopBarDesktopComponent />;
    }, []);

    const mobileTopBar = React.useMemo(() => {
      return <TopBarMobileComponent />;
    }, []);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <TopBarComponent
          desktopTopBar={desktopTopBar}
          mobileTopBar={mobileTopBar}
        />
        <Main>
          <div className={styles.classes.toolbar} />
          {props.children}
        </Main>
      </div>
    );
  },
);
