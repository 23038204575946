import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

// import { RecordState } from '@pladdenico/common';

import {
  PortfolioDashboardConfigState,
  selectors as portfolioDashboardConfigsSelectors,
} from '../../../../reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';

const getPortfolioDashboardConfigState = (state: WorkspaceDataState) =>
  state.portfolio.portfolioDashboardConfigs;

// const selectPortfolioDashboardConfigsByPortfolioConfigId = createCachedSelector(
//   (
//     state: RecordState<PortfolioDashboardConfigState, number>[],
//     _portfolioConfigId: number,
//   ) => state,
//   (
//     _state: RecordState<PortfolioDashboardConfigState, number>[],
//     portfolioConfigId: number,
//   ) => portfolioConfigId,
//   (state, portfolioConfigId): PortfolioDashboardConfigState[] | undefined => {
//     // return portfolios;
//     return portfolioDashboardConfigsSelectors.selectElementsByKey(
//       state,
//       portfolioConfigId,
//     );
//   },
// )({
//   keySelector: (_state, portfolioConfigId) => portfolioConfigId,
//   selectorCreator: createSelector,
// });

export const getPortfolioDashboardConfigsByPortfolioConfigId =
  createCachedSelector(
    (state: WorkspaceDataState) => getPortfolioDashboardConfigState(state),
    (_state: WorkspaceDataState, portfolioConfigId: number) =>
      portfolioConfigId,
    (portfolioState, portfolioConfigId): PortfolioDashboardConfigState[] => {
      return (
        portfolioDashboardConfigsSelectors.selectElementsByKey(
          portfolioState,
          portfolioConfigId,
        ) ?? []
      );
      // return selectPortfolioDashboardConfigsByPortfolioConfigId(
      //   portfolioState,
      //   portfolioConfigId,
      // );
    },
  )({
    keySelector: (_state, portfolioConfigId) => portfolioConfigId,
    selectorCreator: createSelector,
  });

export const getPortfolioDashboardConfigs = createSelector(
  (state: WorkspaceDataState) => getPortfolioDashboardConfigState(state),
  (portfolios) => {
    return portfolioDashboardConfigsSelectors.selectAllElements(portfolios);
  },
);
