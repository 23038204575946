import React from 'react';
import { Link as RouterLink, Route, Routes } from 'react-router-dom';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { InvestmentsComponent } from '../../investment/investments.component';
import { Link, Typography } from '@mui/material';
import { privateEquityFundInvestorPortalBasePath } from '~src/navigation/paths/workspace/project/private-equity-fund-project/investor-portal/paths';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
}

export const InvestorPortalComponent = React.memo((props: Props) => {
  return (
    <Routes>
      <Route
        path="*"
        // exact={true}
        element={
          <>
            <Typography variant="h5">InvestorPortal</Typography>
            <Link
              component={RouterLink}
              to={privateEquityFundInvestorPortalBasePath(
                props.workspace.tenantId,
                props.workspace.id,
                props.project.id,
              )}
            >
              Goto portal
            </Link>
          </>
        }
      />
      <Route
        path="investments/*"
        element={<InvestmentsComponent {...props} />}
      />
    </Routes>
  );
});
