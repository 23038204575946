// import PropTypes from 'prop-types';
import React from 'react';

import { Input, Paper, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
  },
}));

export const SearchInput = (props: any) => {
  const { className, onChange, style, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Paper {...rest} className={cx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        onChange={onChange}
        onFocus={props.handleOnFocus}
      />
    </Paper>
  );
};
