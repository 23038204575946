import {
  HoldingObjStateVisitor,
  HoldingObjVisitable,
} from '~src/data/store/visitors/holding/holding-obj-visitor';
import {
  Holding,
  HoldingObj,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export function handleHoldingObjs(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: HoldingObj[],
  subscriptions: Operation[],
) {
  const holdingVisitor = new HoldingObjStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  const holdings = outputs.map((holding) => {
    const visitable = new HoldingObjVisitable(holding);
    return visitable.accept(holdingVisitor);
  });
  holdingVisitor.post();
  return holdings;
}

export type HoldingHandler = (
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: Holding[],
  subscriptions: Operation[],
) => Holding[];
