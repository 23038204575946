import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/field-config';
import { getDefaultValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/valuation/default-field-config';
import { getCostTransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/transfer/cost-field-config';

export const getCostFieldConfig = (
  holding: HoldingState,
  editAll: boolean,
): HoldingFieldConfig => {
  return {
    transfer: getCostTransferFieldConfig(holding, editAll),
    valuation: getDefaultValuationFieldConfig(holding, editAll),
  };
};
