import {
  fetchAndHandleEntities,
  handleDeleteEntities,
} from '~src/data/store/modules/entities/entities/requests';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { apis } from '~src/services/request/apis';

import { entityPath, Operation } from '@pladdenico/portfolio-api';

export function subscribeToEntities(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  entities: EntityState[],
  subscriptions: Operation[],
) {
  entities.forEach((entity) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: entityPath(tenantId, workspaceId).objectPath(
              entity.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchAndHandleEntities(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to entity', operation, entity.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: entityPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchAndHandleEntities(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to entity', operation, entity.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: entityPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteEntities(entity.workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to entity', operation, entity.id);
          },
        );
      }
    });
  });
}
