import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BasePrivateEquityFundId {
  id: string;
  workspaceId: string;
}

export type PrivateEquityFundState = Omit<
  graphqlWorkspaceTypes.PrivateEquityFund,
  | 'asset'
  | 'fundSetup'
  | 'investors'
  | 'capitalCalls'
  | 'capitalDistributions'
  | '_type'
> & {
  workspaceId: string;
  assetId: string;
};

export function privateEquityFundToPrivateEquityFundState(
  privateEquityFund: PrivateEquityFundState,
) {
  return privateEquityFund;
}

export const initialState: RecordState<PrivateEquityFundState, string>[] = [];

const selectKeyId = (privateEquityFund: BasePrivateEquityFundId) =>
  privateEquityFund.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BasePrivateEquityFundId, b: BasePrivateEquityFundId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BasePrivateEquityFundId,
  PrivateEquityFundState,
  string
>(initialState, selectKeyId, keyComparator, tComparator);

export const privateEquityFundsSlice = createBaseRecordSlice(
  'privateEquityFunds',
  adapter,
);
export const privateEquityFundsSelectors = adapter.selectors;

export const privateEquityFundsReducer = privateEquityFundsSlice.reducer;
export const privateEquityFundsActions = privateEquityFundsSlice.actions;
