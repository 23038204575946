import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from 'recharts';
import { PortfolioAsset } from '~src/domain/workspace/components/finance/tools/pareto/covariances';
import { ParetoFrontierChartTooltip } from '~src/domain/workspace/components/finance/tools/pareto/pareto-frontier-chart-tooltip.component';

interface FrontierData {
  expectedReturn: number;
  volatility: number;
  weights: number[];
}

interface Props {
  data: FrontierData[];
  assets: PortfolioAsset[];
  points: {
    expectedReturn: number;
    volatility: number;
  }[];
}

export const ParetoFrontierChartComponent = (props: Props) => {
  const { data, assets, points } = props;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart
        width={400}
        height={400}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <XAxis type="number" dataKey="volatility" name="Volatility" unit="%" />
        <YAxis
          type="number"
          dataKey="expectedReturn"
          name="Expected Return"
          unit="%"
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<ParetoFrontierChartTooltip assets={assets} />}
        />
        <Legend />
        <Scatter
          name="Frontier Points"
          data={data}
          fill="#8884d8"
          line={{ stroke: 'red', strokeWidth: 2 }}
        />
        <Scatter name="Frontier Points" data={points} fill="#880000" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
