import { AssetType } from '@pladdenico/models';

export function typeToString(type: string) {
  if (type === AssetType.BankNote) {
    return 'BankNote';
  } else if (type === AssetType.Bond) {
    return 'Bond';
  } else if (type === AssetType.Crypto) {
    return 'Crypto';
  } else if (type === AssetType.Fund) {
    return 'Fund';
  } else if (type === AssetType.Loan) {
    return 'Loan';
  } else if (type === AssetType.Warrant) {
    return 'Warrant';
  } else if (type === AssetType.PrivateEquityFund) {
    return 'PrivateEquityFund';
  } else if (type === AssetType.Stock) {
    return 'Stock';
  }
  return 'unknown';
}
