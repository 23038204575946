import { memo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { useProjectReportFields } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/use-project-report-fields';

import { Grid } from '@mui/material';
import { InputData } from './project-report-input.data';

interface Props {
  handleClose: () => void;
  isShowing: boolean;
  handleCancel: () => void;
  onSave: (data: InputData) => Promise<void>;
  inputData?: InputData;
}

export const ProjectReportEditDialogComponent = memo((props: Props) => {
  const { handleClose, isShowing, handleCancel, onSave, inputData } = props;

  const {
    control,
    handleSubmit,
    setValue,
    //  setValue, handleSubmit
  } = useForm<InputData>({
    defaultValues: {
      ...inputData,
    },
  });

  const onSubmit: SubmitHandler<InputData> = (data) => {
    console.log('SAVING');
    return onSave(data);
  };

  const handleOnSave = () => {
    return handleSubmit(onSubmit)();
  };

  const { nameField } = useProjectReportFields(control, setValue);

  return (
    <DialogComponent
      dialogProps={{ open: isShowing, onClose: handleClose, fullWidth: true }}
    >
      <DialogContentComponent
        handleCancel={handleCancel}
        handleOnSave={handleOnSave}
        title="ProjectReport"
      >
        <form autoComplete="off">
          <Grid
            container
            justifyContent="center"
            columnSpacing={1}
            rowSpacing={2}
          >
            <Grid item sm={6}>
              {nameField}
            </Grid>
          </Grid>
        </form>
      </DialogContentComponent>
    </DialogComponent>
  );
});
