import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { CreatePersonComponent } from '~src/domain/workspace/components/people/form/create.component';

import FilterListIcon from '@mui/icons-material/FilterList';
import MoreIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { TableDrawers, TableOperations } from '@pladdenico/table';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { eventParser } from '~src/utils/form/event-parser';

const useStyles = makeStyles()((theme: Theme) => ({
  root: { flex: 1 },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    // marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {},
}));

interface Props {
  workspace: WorkspaceState;
  personAlgoData: TableOperations.AlgorithmData<PersonState>;
  holdingAlgoData: TableOperations.AlgorithmData<HoldingDenormalizedWithEntity>;
  level: number;
  setLevel: (level: number) => void;
}

export const PeopleToolbar = (props: Props) => {
  const { workspace, personAlgoData, holdingAlgoData, level, setLevel } = props;
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);
  const menuId = 'entity-menu';

  const { classes } = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [createPersonDialogOpened, setCreatePersonDialogeOpened] =
    React.useState(false);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleAddHolding = () => {
    setCreatePersonDialogeOpened(true);
    handleMenuClose();
  };

  function handleCloseCreatePersonDialog() {
    setCreatePersonDialogeOpened(false);
  }

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const entityMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleAddHolding}>Add person</MenuItem>
    </Menu>
  );

  const peopleFiltersComponent = personAlgoData.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={personAlgoData.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });
  const peopleSortersComponent = personAlgoData.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={personAlgoData.dispatchSorters}
        sorter={sorter}
      />
    );
  });

  const holdingsFiltersComponent = holdingAlgoData.filters.map(
    (filter, index) => {
      return (
        <TableDrawers.FilterComponent
          key={index}
          dispatchFilter={holdingAlgoData.dispatchFilters}
          filter={filter}
          textFieldProps={{ sx: { marginBottom: 1 } }}
        />
      );
    },
  );
  const holdingsSortersComponent = holdingAlgoData.sorters.map(
    (sorter, index) => {
      return (
        <TableDrawers.SorterComponent
          key={index}
          dispatchSorter={holdingAlgoData.dispatchSorters}
          sorter={sorter}
        />
      );
    },
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {/* <IconButton onClick={handlePropertiesDrawerOpen}>
          <ViewListIcon />
        </IconButton> */}
        <span className={classes.spacer} />
        <IconButton onClick={handleFilterDrawerOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton onClick={handleSortDrawerOpen}>
          <SortIcon />
        </IconButton>
        <IconButton onClick={handleMenuOpen} color="inherit">
          <MoreIcon />
        </IconButton>
      </div>
      <CreatePersonComponent
        workspace={workspace}
        open={createPersonDialogOpened}
        handleClose={handleCloseCreatePersonDialog}
      />
      {entityMenu}
      <TableDrawers.FilterDrawerComponent
        open={filterOpen}
        closeDrawer={() => setFilterOpen(false)}
        filtersComponent={
          <Box>
            <Typography variant="overline">Graph properties</Typography>
            <TextField
              margin="normal"
              required
              id="level"
              value={level ?? 1}
              label="Level"
              type="number"
              onChange={(event) => setLevel(eventParser(event) as number)}
            />
            <Typography variant="overline">Person</Typography>
            {peopleFiltersComponent}
            <Typography variant="overline">Holding</Typography>
            {holdingsFiltersComponent}
            <Divider />
          </Box>
        }
      />
      <TableDrawers.SorterDrawerComponent
        open={sortOpen}
        closeDrawer={() => setSortOpen(false)}
        sortersComponent={
          <>
            {peopleSortersComponent}
            {holdingsSortersComponent}
          </>
        }
      />
    </div>
  );
};
