import numbro from 'numbro';
import * as React from 'react';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';

import { Typography } from '@mui/material';
import { useStyles } from '~src/components/summary/styles';

interface Props {
  beta: number;
  indexPaper: PaperState | undefined;
  subLabel?: string;
}

export const BetaComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const { beta, indexPaper, subLabel } = props;

  const subLabelElement = React.useMemo(() => {
    if (subLabel) {
      return <Typography variant="caption">{subLabel}</Typography>;
    }
  }, [subLabel]);

  return (
    <>
      <div className={classes.statistic}>
        {subLabelElement}
        <Typography variant="h4" component="h4" noWrap>
          {numbro(beta).format({
            thousandSeparated: true,
            mantissa: 3,
          })}
        </Typography>
        <Typography
          component="p"
          className={classes.statisticLabel}
          variant="body2"
        >
          Beta (index {indexPaper?.symbol}.{indexPaper?.exchange})
        </Typography>
      </div>
    </>
  );
});
