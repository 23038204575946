import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { AssetCategoryState } from '~src/data/store/reducers/asset/categories/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getCategoriesByWorkspaceId,
  getCategoryById,
} from '~src/data/store/selectors/asset/categories/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useEditDialog } from '~src/hooks/fields/workspace/asset/category/use-edit-dialog';
import { FieldError } from '~src/utils/form/fields/field-error';

interface Props {
  handleValue: (t: AssetCategoryState) => void;
  handlePersist?: () => void;
  categoryId: string | undefined;
  label?: string;
  workspace: WorkspaceState;
  error?: FieldError;
}

export const EditCategory = (props: Props) => {
  const categories = useSelector((state: RootState) => {
    return getCategoriesByWorkspaceId(
      selectWorkspaceData(state),
      props.workspace.id,
    );
  });
  const category = useSelector((state: RootState) => {
    return getCategoryById(selectWorkspaceData(state), {
      id: props.categoryId,
      workspaceId: props.workspace.id,
    });
  });

  const getOptionLabel = React.useCallback(
    (t: AssetCategoryState) => {
      const category = categories.find((category) => category.id === t.id);
      return category?.name ? category.name : '';
    },
    [categories],
  );
  const { handleValue, error } = props;

  const { dialogElement, setOpen, setCategoryInput } = useEditDialog({
    handleValue,
    workspace: props.workspace,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setCategoryInput({
        id: uuid.v1(),
        name: value,
      });
    },
    [setCategoryInput],
  );

  return (
    <EditAutocomplete
      multiple={false}
      addOption={{ initDialog, setOpen, dialogElement }}
      // dialogElement={dialogElement}
      getOptionLabel={getOptionLabel}
      label={props.label}
      handleValue={handleValue}
      // initDialog={initDialog}
      options={categories}
      value={category}
      // setOpen={setOpen}
      handlePersist={props.handlePersist}
      error={error}
    />
  );
};
