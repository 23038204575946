import { UserState } from '~src/data/store/reducers/user/user/reducer';
import { User } from '~src/services/graphql/workspace/client/graphql';

export function parseUser(user: User): UserState {
  return {
    id: user.id ? user.id : -1,
    email: user.email ? user.email : '',
    name: user.name ? user.name : '',
    password: '',
  };
}
