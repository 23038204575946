import { Tab } from '~src/utils/interfaces/tab';

import { PortfolioProjectTabs } from './tabs.component';

function createTabs(): Tab<PortfolioProjectTabs>[] {
  const tabs: Tab<PortfolioProjectTabs>[] = [];

  {
    const path = {
      tab: PortfolioProjectTabs.Dashboard,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/dashboard',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  // {
  //   const path = {
  //     tab: PortfolioProjectTabs.Holdings,
  //     exact: true,
  //     path:
  //       '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings',
  //   };
  //   tabs.push({
  //     name: path.tab,
  //     path: path.path,
  //   });
  // }
  {
    const path = {
      tab: PortfolioProjectTabs.Holdings,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectTabs.Reporting,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/reporting',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }

  // {
  //   const path = {
  //     tab: PortfolioProjectTabs.Valuations,
  //     exact: true,
  //     path:
  //       '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/valuations',
  //   };
  //   tabs.push({
  //     name: path.tab,
  //     path: path.path,
  //   });
  // }
  {
    const path = {
      tab: PortfolioProjectTabs.Transactions,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/transactions',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectTabs.Commitments,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/commitments',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectTabs.Events,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/events',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectTabs.Scenarios,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/scenarios',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectTabs.Settings,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/settings',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }

  // {
  //   const path = {
  //     tab: PortfolioProjectTabs.Transfers,
  //     exact: true,
  //     path:
  //       '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/transfers',
  //   };
  //   tabs.push({
  //     name: path.tab,
  //     path: path.path,
  //   });
  // }
  // {
  //   const path = {
  //     tab: PortfolioProjectTabs.Events,
  //     exact: true,
  //     path:
  //       '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/events',
  //   };
  //   tabs.push({
  //     name: path.tab,
  //     path: path.path,
  //   });
  // }
  return tabs;
}

export function usePortfolioProjectTabs() {
  return createTabs();
}
