import { Typography } from '@mui/material';
import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

export const InitialNode = React.memo(({ data, isConnectable }: NodeProps) => {
  return (
    <>
      <div>Initial</div>
      <Typography variant="caption">{data.label}</Typography>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
    </>
  );
});
