export const defaultTotalValueBackgroundColor = '#4C1B75';
export const defaultTotalValueFontColor = '#C1B2B2';

export const defaultIrrBackgroundColor = '#6C2D95';
export const defaultIrrFontColor = '#C1B2B2';

export const defaultBetaBackgroundColor = '#813FB7';
export const defaultBetaFontColor = '#C1B2B2';

export const defaultVolatilityBackgroundColor = '#A977E1';
export const defaultVolatilityFontColor = '#642E97';

export const defaultCommitmentsBackgroundColor = '#813FB7';
export const defaultCommitmentsFontColor = '#C1B2B2';
