import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { MainComponent } from '~src/domain/home/app/main.component';
import { HeaderComponent } from '~src/domain/home/components/header.component';

// import history from '~src/navigation/history';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },

  actionButton: {
    marginBottom: '3em',
    marginTop: '3em',
  },

  infoContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    // padding: theme.spacing(2),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },

  infoPaperContainer: {
    marginTop: '3em',
    padding: '20px',
  },

  info: {
    marginBottom: '1em',
  },

  investImageContainer: {
    paddingLeft: '12%',
    paddingRight: '12%',
    paddingTop: theme.spacing(8),
  },

  investImage: {
    maxWidth: '100%',
  },

  footer: {
    marginTop: '3em',
    paddingTop: '1em',
    paddingBottom: '1em',
    background: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
}));

export const HomeComponent = React.memo(() => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const goToCaseStudies = () => {
    navigate('/caseStudies');
  };

  return (
    <MainComponent>
      <HeaderComponent />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6} className={classes.infoContainer}>
          <div className={classes.info}>
            <Typography variant="h6" gutterBottom>
              We help you understand your portfolio
            </Typography>
            <Typography variant="body1">
              We give you the necessary tools to help make the right investment
              decisions based on your portfolio. In particular we believe in
              diversification.
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography variant="h6" gutterBottom>
              No lock in. Import and export data at will.
            </Typography>
            <Typography variant="body1">
              We believe that different tools are needed for different
              objectives. Therefore we allow full transparency.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.investImageContainer}>
          <img src="/images/invest.jpg" className={classes.investImage} />
          {/* <Image
            bordered={true}
            rounded={true}
            size='large'
            src='/images/invest.jpg'
          /> */}
        </Grid>
        <Grid item className={classes.actionButton}>
          <Button variant="contained" color="primary">
            Check it out
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6} className={classes.infoContainer}>
          <Typography variant="h6" gutterBottom>
            &quot;Exactly what we needed&quot;
          </Typography>
          <Typography variant="caption">
            That is what portfolio managers say about us
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.infoContainer}>
          <Typography variant="h6">
            &quot;Finally someone that understand what I need.&quot;
          </Typography>
          <Typography variant="caption">
            <b>George</b> Chief Financial Officer - family business
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={6}>
          <Paper className={classes.infoPaperContainer}>
            <Typography variant="h6" gutterBottom>
              All your assets in one place
            </Typography>
            <Typography variant="body1" gutterBottom>
              Having the complete overview of the entire portfolio, in terms of
              assets, transactions and valuations allow a correct interpretation
              of returns, risk and expected future outcomes.
            </Typography>
            <Grid item>
              <Button variant="contained">Read More</Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.actionButton}>
          <Button variant="contained" color="primary" onClick={goToCaseStudies}>
            Case Studies
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={6} className={classes.infoContainer}>
          <Typography variant="h6" gutterBottom>
            Automatically updated data
          </Typography>
          <Typography variant="body1" gutterBottom>
            Connect to data sources to get asset information automatically up to
            date.
          </Typography>
          <Grid item>
            <Button variant="contained">Connectors</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" className={classes.footer}>
        <Grid item>
          <Typography variant="h6">About</Typography>
          <Typography variant="body1">Sitemap</Typography>
          <Typography variant="body1">Contact us</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Services</Typography>
          <Typography variant="body1">How To Access</Typography>
          <Typography variant="body1">Call us +47 48011541</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Portfolio investing</Typography>
          <Typography variant="body1">
            Portfolio investing requires professionalism. It is not easy.
          </Typography>
          <Typography variant="body1">
            The tool is as precise as needed.
          </Typography>
        </Grid>
      </Grid>
    </MainComponent>
  );
});
