import { PortfolioConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioConfig } from '~src/services/graphql/workspace/client/graphql';

export function parsePortfolioConfig(
  portfolioId: string,
  config: PortfolioConfig,
): PortfolioConfigState {
  return {
    currentDashboardConfigId: config.currentDashboardConfigId
      ? config.currentDashboardConfigId
      : null,
    id: config.id,
    portfolioDashboardConfigIds: config.portfolioDashboardConfigs
      ? config.portfolioDashboardConfigs.map((c) => c.id)
      : [],
    portfolioId,
  };
}
