import { parseInvestment } from '~src/data/store/modules/assets/private-equity-fund/investments/parser';
import {
  HoldingStateVisitor,
  HoldingVisitable,
  HoldingVisitor,
} from '~src/data/store/visitors/holding/holding-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  investmentsActions,
  InvestmentState,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { PrivateEquityFundInvestment } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface PrivateEquityFundInvestmentVisitor {
  visit(
    privateEquityFundInvestment: PrivateEquityFundInvestmentVisitable,
  ): void;
  post(): void;
  holdingVisitor: HoldingVisitor;
  // capitalCallVisitor: PrivateEquityFundInvestmentCapitalCallVisitor;
}

export class PrivateEquityFundInvestmentVisitable
  implements Visitable<PrivateEquityFundInvestmentVisitor>
{
  constructor(
    private _fundId: string,
    private _privateEquityFundInvestment: PrivateEquityFundInvestment,
  ) {}
  public accept(visitor: PrivateEquityFundInvestmentVisitor) {
    const holdingVisitable = new HoldingVisitable(
      this._privateEquityFundInvestment.holding,
    );
    holdingVisitable.accept(visitor.holdingVisitor);
    this._privateEquityFundInvestment.holding;

    visitor.visit(this);
  }

  public parse(): InvestmentState {
    return parseInvestment(this._fundId, this._privateEquityFundInvestment);
  }
}

export class PrivateEquityFundInvestmentHandlerVisitor
  implements PrivateEquityFundInvestmentVisitor
{
  constructor(
    private _handle: (investment: InvestmentState) => void,
    public holdingVisitor: HoldingVisitor,
  ) {}
  public visit(investment: PrivateEquityFundInvestmentVisitable): void {
    this._handle(investment.parse());
  }
  public post() {
    return;
  }
}

export class PrivateEquityFundInvestmentStateVisitor extends PrivateEquityFundInvestmentHandlerVisitor {
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    super(
      (privateEquityFundInvestment) =>
        dispatch(
          investmentsActions.upsertOneElement(privateEquityFundInvestment),
        ),
      new HoldingStateVisitor(dispatch, tenantId, workspaceId, subscriptions),
    );
  }

  post() {
    this.holdingVisitor.post();
    this.holdingVisitor.post();
  }
}
