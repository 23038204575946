import * as d3Request from 'd3-request';
import { scaleSqrt } from 'd3-scale';
import { compact } from 'lodash';
import numbro from 'numbro';
import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import { mapStorage } from '~src/utils/map/map-storage';
import { LocalStorageValueType } from '~src/utils/storage/local-storage';

interface Props {
  regionalValues: Map<string, number>;
  active?: boolean;
  format?: {
    fontColor?: string;
  };
}

interface Country {
  name: string;
  value: number;
  coordinates?: [number, number];
}

interface CountryData {
  country: string;
  latitude: number;
  longitude: number;
  name: string;
}

function calculateCountries(
  regionalValuations: Map<string, number>,
  countriesData?: Array<{
    country: string;
    latitude: number;
    longitude: number;
    name: string;
  }>,
) {
  const countries: Array<Country> = [];
  regionalValuations.forEach((value, name) => {
    countries.push({ name, value });
  });
  if (!countriesData) {
    return countries;
  }
  return countries.map((country) => {
    const countryData = countriesData.find((countryData) => {
      return countryData.name === country.name;
    });

    let coordinates: [number, number] | undefined;
    if (countryData) {
      coordinates = [countryData.longitude, countryData.latitude];
    }
    return {
      ...country,
      coordinates,
    };
  });
}

function updateCountriesWithData(
  countries: Country[],
  countriesData: CountryData[],
) {
  return countries.map((country) => {
    const countryData = countriesData.find((countryData: any) => {
      return countryData.name === country.name;
    });

    let coordinates: [number, number] | undefined;
    if (countryData) {
      coordinates = [countryData.longitude, countryData.latitude];
    }
    return {
      ...country,
      coordinates,
    };
  });
}

function useIsMountedRef(): React.MutableRefObject<boolean> {
  const isMountedRef = React.useRef(false);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
}

export function fetchJson(url: string) {
  return fetch(url)
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res.json();
    })
    .catch((error) => {
      console.log('There was a problem when fetching the data: ', error);
    });
}

export const RegionalMapComponent = React.memo((props: Props) => {
  const { regionalValues: regionalValues, format } = props;
  const isMountedRef = useIsMountedRef();
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [countriesData, setCountriesData] = React.useState<CountryData[]>([]);
  const [mapData, setMapData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const countries = calculateCountries(regionalValues);
    setCountries(updateCountriesWithData(countries, countriesData));
  }, [regionalValues, countriesData]);

  React.useEffect(() => {
    if (countriesData.length === 0) {
      const countriesUrl = '/data/topojson-maps/countries.json';
      const countries = mapStorage.get(
        countriesUrl,
        LocalStorageValueType.object,
      );
      if (!countries) {
        d3Request.json(countriesUrl, (error: any, countriesData: any) => {
          if (error) {
            throw error;
          }
          if (isMountedRef.current) {
            mapStorage.set(
              countriesUrl,
              countriesData,
              LocalStorageValueType.object,
            );
            setCountriesData(countriesData);
          }
        });
      } else {
        setCountriesData(countries);
      }
    }
  }, [countriesData, countriesData.length, isMountedRef]);

  React.useEffect(() => {
    if (mapData.length === 0) {
      const mapUrl = '/data/topojson-maps/world-50m.json';
      const map = mapStorage.get(mapUrl, LocalStorageValueType.object);
      if (!map) {
        fetchJson(mapUrl).then((mapData) => {
          if (isMountedRef.current) {
            mapStorage.set(mapUrl, mapData, LocalStorageValueType.object);
            setMapData(mapData);
          }
        });
      } else {
        setMapData(map);
      }
    }
  }, [isMountedRef, mapData, mapData.length]);

  const geographyStyle = {
    default: {
      fill: '#ECEFF1',
      stroke: '#607D8B',
      strokeWidth: 0.75,
      outline: 'none',
    },
    hover: {
      fill: '#CCCFC1',
      stroke: '#607D8B',
      strokeWidth: 0.75,
      outline: 'none',
    },
    pressed: {
      fill: '#9C9F91',
      stroke: '#607D8B',
      strokeWidth: 0.75,
      outline: 'none',
    },
  };

  const wrapperStyles = {
    // background: 'darkblue',
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '100%',
    width: '100%',
    // maxWidth: 980,
    // margin: '0 auto',
  };

  const geographiesElement = ({ geographies }: any) =>
    geographies.map(
      (geography: any, i: number) =>
        geography.id !== 'ATA' && (
          <Geography key={i} geography={geography} style={geographyStyle} />
        ),
    );

  const maxValue = countries.reduce((v, currentValue) => {
    return Math.max(currentValue.value, v);
  }, 0);

  const cityScale = scaleSqrt().domain([0, maxValue]).range([2, 25]);

  let unknownCountryIdx = 0;
  const markers = compact(
    countries.map((country, i) => {
      if (country.value === 0.0) {
        return undefined;
      }
      if (!country.coordinates) {
        ++unknownCountryIdx;
        return (
          <Marker key={i} coordinates={[-980.0 / 2, 0]}>
            <g>
              <circle
                cx={0}
                cy={unknownCountryIdx * 55}
                r={cityScale(country.value)}
                fill="rgba(255,87,34,0.8)"
                stroke="#607D8B"
                strokeWidth="1"
              />
              <text
                dx={50}
                dy={unknownCountryIdx * 55}
                fill={format?.fontColor ?? '#AAAAAA'}
              >
                {country.name}
              </text>
              <title>
                {country.name} (
                {numbro(country.value).format({
                  thousandSeparated: true,
                  mantissa: 1,
                })}
                )
              </title>
            </g>
          </Marker>
        );
      }

      const coordinates = country.coordinates;
      if (coordinates) {
        return (
          <Marker key={i} coordinates={coordinates}>
            <g>
              <circle
                cx={0}
                cy={0}
                r={cityScale(country.value)}
                fill="rgba(255,87,34,0.8)"
                stroke="#607D8B"
                strokeWidth="2"
              />
              <title>
                {country.name} (
                {numbro(country.value).format({
                  thousandSeparated: true,
                  mantissa: 1,
                })}
                )
              </title>
            </g>
          </Marker>
        );
      }
    }),
  );

  return (
    <ComposableMap
      // projectionConfig={{ scale: 205 }}
      projection="geoEqualEarth"
      width={900}
      height={470}
      style={wrapperStyles}
      // width={980}
      // height={551}
      // style={{ width: '100%', height: 'auto' }}
    >
      {/* <ZoomableGroup center={[0, 20]}> */}
      {/* <ZoomableGroup
          center={[0, 0]}
          zoom={1}
          // translateExtent={[
          //   [-900, -470],
          //   [900, 470],
          // ]}
          // disablePanning={!active}
          // disableZooming={!active}
        > */}
      <Geographies geography={mapData}>{geographiesElement}</Geographies>
      {markers}
      {/* </ZoomableGroup> */}
    </ComposableMap>
  );
});
