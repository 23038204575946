// import moment from 'moment';
import moment from 'moment';
import React from 'react';

import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
interface Props {
  handleValue: (date: Date) => void;
  date: Date;
  labelled: boolean;
}

export const EditDateField = React.memo((props: Props) => {
  const handleDateChange = (date: moment.Moment | null) => {
    // const handleDateChange = (dateIn: unknown) => {
    // const date = dateIn as moment.Moment;
    if (date) {
      props.handleValue(date.toDate());
    }
  };

  return (
    <DatePicker
      showToolbar={false}
      inputFormat="DD/MM/YYYY"
      label={props.labelled ? 'Date' : undefined}
      value={moment(props.date)}
      onChange={handleDateChange}
      renderInput={(params) => <TextField {...params} />}
    />
  );
});
