import { Box, Typography } from '@mui/material';

type DistributionType = 'normal';

export interface Distribution {
  type: DistributionType;
}

export interface NormalDistribution extends Distribution {
  mean: number;
  stddev: number;
}
function isNormalDistribution(
  distribution: Distribution,
): distribution is NormalDistribution {
  return distribution.type === 'normal';
}

interface Props {
  distribution: Distribution;
}

export const DistributionComponent = (props: Props) => {
  const { distribution } = props;
  if (isNormalDistribution(distribution)) {
    return (
      <Box>
        <Typography variant="body2">{distribution.type}</Typography>
        <Typography variant="subtitle2">Mean {distribution.mean}</Typography>
        <Typography variant="subtitle2">
          Standard deviation {distribution.stddev}
        </Typography>
      </Box>
    );
  }
  return <></>;
};
