import moment from 'moment';
import { ForecastElement } from './forecast-element';

export class ForecastEvaluator<Value> {
  private _values: Array<Value>;
  constructor(
    private _forecastElement: ForecastElement<Value>,
    numberOfValues: number,
  ) {
    this._values = new Array<Value>(numberOfValues);
  }

  public evaluate(date: moment.Moment, idx: number) {
    const value = this._forecastElement.evaluate({
      date,
      values: this._values,
      idx,
    });
    if (value != null) {
      this._values[idx] = value;
    }
  }

  public getForecastElementId() {
    return this._forecastElement.getId();
  }

  public values() {
    return this._values;
  }
}
