import React from 'react';
import { typeToString } from '~src/components/fields/workspace/holding/type/view-type-field';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { HoldingType } from '@pladdenico/models';

interface Props {
  handleValue: (type: HoldingType) => void;
  type: HoldingType | undefined;
  label: string | undefined;
  holdingTypes?: HoldingType[];
  disabled?: boolean;
}

export const EditTypeField = React.memo((props: Props) => {
  const handleSelectChange = (event: SelectChangeEvent<HoldingType>) => {
    props.handleValue(event.target.value as HoldingType);
  };

  const label = props.label ? (
    <InputLabel htmlFor="Type">{props.label}</InputLabel>
  ) : undefined;

  const menuItems = React.useMemo(() => {
    return Object.values(HoldingType).map((type) => {
      const disabled =
        props.holdingTypes && !props.holdingTypes?.some((t) => t === type);
      return (
        <MenuItem key={type} value={type} disabled={disabled}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, [props.holdingTypes]);

  return (
    <FormControl margin="normal" disabled={props.disabled}>
      {label}
      <Select
        value={props.type}
        onChange={handleSelectChange}
        label={props.label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
