import { WorkspaceTabs } from '../../../domain/workspace/components/workspace/tab/workspace-tabs.component';
import { tenantBaseId, tenantRootPath } from '../tenant/paths';

export const workspaceBaseId = ':workspaceId';

export const workspacesRootPath = (tenantId: string) => {
  return `${tenantRootPath(tenantId)}/workspaces`;
};
export const workspaceRootPath = (tenantId: string, workspaceId: string) => {
  return `${workspacesRootPath(tenantId)}/${workspaceId}`;
};

export const workspacesBasePath = workspacesRootPath(tenantBaseId);
export const workspaceBasePath = workspaceRootPath(
  tenantBaseId,
  workspaceBaseId,
);

export const workspaceTabPath = (
  tenantId: string,
  workspaceId: string,
  tab: WorkspaceTabs,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/${tab}`;
};
