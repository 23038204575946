import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createLoans } from '~src/data/store/modules/assets/loans/requests';
import { LoanState } from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputLoanData } from '~src/domain/workspace/components/asset/types/loans/form/loan-input.data';
import { useLoanFields } from '~src/domain/workspace/components/asset/types/loans/form/use-loan-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: InputLoanData;
  callbacks?: {
    onSuccess?: (loans: LoanState) => void;
    onFailure?: (error: any) => void;
  };
}

export const useCreateLoanForm = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (assetId: string, data: InputLoanData) => {
      return dispatch(
        createLoans(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            assetId: assetId,
            description: data.description,
            interest: data.interest,
          },
        ]),
      )
        .then((loans) => {
          const loan = loans[0];
          callbacks?.onSuccess && callbacks.onSuccess(loan);
          return loan;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<InputLoanData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useLoanFields(control);

  const onSubmit = React.useCallback(
    (
      assetId: string,
      successCallback: (loan: LoanState) => Promise<void>,
    ): SubmitHandler<InputLoanData> =>
      (data) => {
        return onSave(assetId, data).then((loan) => successCallback(loan));
      },
    [onSave],
  );

  const submit = React.useCallback(
    (assetId: string, successCallback: (loan: LoanState) => Promise<void>) => {
      return handleSubmit(onSubmit(assetId, successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
