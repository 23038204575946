import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseBankNoteHoldingId {
  holdingId: string;
  id: string;
}

export type BankNoteHoldingState = Omit<
  graphqlWorkspaceTypes.BankNoteHolding,
  'holding' | 'bankNote'
> & {
  holdingId: string;
  bankNoteId: string;
};

export const initialBankNoteHoldingState = { ts: [], idxs: [] };

const selectId = (bankNoteHolding: BaseBankNoteHoldingId) => bankNoteHolding.id;
const selectIdx = (bankNoteHolding: BaseBankNoteHoldingId) =>
  bankNoteHolding.holdingId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: BankNoteHoldingState, b: BankNoteHoldingState) => b;

const adapter = new EntityIndexedAdapter<
  BaseBankNoteHoldingId,
  BankNoteHoldingState,
  string,
  string
>(
  initialBankNoteHoldingState,
  selectId,
  selectIdx,
  merger,
  iComparator,
  idxComparator,
);

export const bankNoteHoldingsSlice = createBaseIndexedSlice(
  'bankNoteHoldings',
  adapter,
);
export const bankNoteHoldingsSelectors = adapter.selectors;
export const bankNoteHoldingsReducer = bankNoteHoldingsSlice.reducer;
export const bankNoteHoldingsActions = bankNoteHoldingsSlice.actions;
