import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseTransferId,
  transfersSelectors,
  TransferState,
} from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { Period } from '~src/utils/period/period';

import { intersectionSorted, strcmp } from '@pladdenico/common';

const selectTransferState = (state: WorkspaceDataState) =>
  state.holding.holding.transfer.transfers;

export const getTransfers = createSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (transfers): TransferState[] => {
    return transfersSelectors.selectAllElements(transfers);
  },
);

export const getTransferById = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, baseId: BaseTransferId) => baseId.holdingId,
  (_state: WorkspaceDataState, baseId: BaseTransferId) => baseId.id,
  (transfers, holdingId, id): TransferState | undefined => {
    return transfersSelectors.selectElementByT(transfers, { holdingId, id });
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getTransfersByIds = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, baseIds: BaseTransferId[]) => baseIds,
  (transfers, baseIds): TransferState[] => {
    return transfersSelectors.selectElementsByTs(transfers, baseIds);
  },
)({
  keySelector: (_state, baseIds) => baseIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getTransfersByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (transferState, holdingId): TransferState[] => {
    return (
      transfersSelectors.selectElementsByKey(transferState, holdingId) ?? []
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getTransfersByHoldingIds = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, holdingIds: string[]) => holdingIds,
  (transferState, holdingIds: string[]): TransferState[] => {
    const transfers = transfersSelectors.selectElementsByKeys(
      transferState,
      holdingIds,
    );
    return transfers ? transfers : [];
  },
)({
  keySelector: (_state, holdingIds) => holdingIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getTransfersByTransactionId = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, transactionId: string) => transactionId,
  (transferState, transactionId): TransferState[] => {
    const transfers = transfersSelectors.selectAllElements(transferState);
    return transfers.filter(
      (transfer) => transfer.transactionId === transactionId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getTransfersByTransactionIds = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, transactionIds: string[]) => transactionIds,
  (transferState, transactionIds): TransferState[] => {
    const transfers = transfersSelectors
      .selectAllElements(transferState)
      .sort((a, b) => strcmp(a.transactionId, b.transactionId));
    transactionIds = transactionIds.sort((a, b) => strcmp(a, b));
    return intersectionSorted(
      transfers,
      transactionIds,
      [(t, transactionId) => strcmp(t.transactionId, transactionId)],
      (t, _transactionId) => t,
    );
  },
)({
  keySelector: (_state, transactionIds) => transactionIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getTransfersByCommitmentIds = createCachedSelector(
  (state: WorkspaceDataState, _commitmentIds: string[]) =>
    selectTransferState(state),
  (_state: WorkspaceDataState, commitmentIds: string[]) => commitmentIds,
  (transferState, commitmentIds): TransferState[] => {
    const transfers = transfersSelectors
      .selectAllElements(transferState)
      .sort((a, b) => strcmp(a.commitmentId, b.commitmentId));
    commitmentIds = commitmentIds.sort((a, b) => strcmp(a, b));
    return intersectionSorted(
      transfers,
      commitmentIds,
      [(t, commitmentId) => strcmp(t.commitmentId, commitmentId)],
      (t, _commitmentId) => t,
    );
  },
)({
  keySelector: (_state, commitmentIds) => commitmentIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

interface Props {
  holdingIds: string[];
  period: Period;
}

export const selectHoldingsCostTransfers = createCachedSelector(
  (state: WorkspaceDataState, props: Props) =>
    getTransfersByHoldingIds(state, props.holdingIds),
  (_state: WorkspaceDataState, props: Props) => props.period,
  (transfers, period) => {
    return transfers.filter((transfer) => {
      if (
        period.start.valueOf() <= transfer.date.getTime() &&
        transfer.date.getTime() <= period.end.valueOf()
      ) {
        if (transfer.type === 'cost') {
          return true;
        } else if (transfer.cost && transfer.cost > 0) {
          return true;
        }
      }
      return false;
    });
  },
)({
  keySelector: (_state, props) => props.holdingIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
