import { parseBondHolding } from '~src/data/store/modules/holdings/bond/parser';
import {
  BondPositionStateVisitor,
  BondPositionVisitable,
  BondPositionVisitor,
} from '~src/data/store/visitors/holding/types/bond/bond-position-visitor';
import {
  BondTradeStateVisitor,
  BondTradeVisitable,
  BondTradeVisitor,
} from '~src/data/store/visitors/holding/types/bond/bond-trade-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  bondHoldingsActions,
  BondHoldingState,
} from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import { Holding } from '~src/services/graphql/user/client/graphql';
import { BondHolding } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';
import { Operation } from '@pladdenico/portfolio-api';

export const isBondHolding = (holding: Holding): holding is BondHolding => {
  return holding.holding.type === HoldingType.Bond;
};

export interface BondHoldingVisitor {
  visit(bondHolding: BondHoldingVisitable): BondHoldingState;
  post(): void;
  positionVisitor: BondPositionVisitor;
  tradeVisitor: BondTradeVisitor;
}

export class BondHoldingVisitable implements Visitable<BondHoldingVisitor> {
  constructor(private _bondHolding: BondHolding) {}
  public accept(visitor: BondHoldingVisitor) {
    if (this._bondHolding.bondPositions) {
      this._bondHolding.bondPositions.forEach((position) => {
        const positionVisitable = new BondPositionVisitable(position);
        positionVisitable.accept(visitor.positionVisitor);
      });
    }
    if (this._bondHolding.bondTrades) {
      this._bondHolding.bondTrades.forEach((trade) => {
        const tradeVisitable = new BondTradeVisitable(trade);
        tradeVisitable.accept(visitor.tradeVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): BondHoldingState {
    return parseBondHolding(this._bondHolding);
  }
}

export class BondHoldingStateVisitor implements BondHoldingVisitor {
  private _bondHoldings: BondHoldingState[];
  public positionVisitor: BondPositionVisitor;
  public tradeVisitor: BondTradeVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._bondHoldings = [];
    this.positionVisitor = new BondPositionStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    this.tradeVisitor = new BondTradeStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(holding: BondHoldingVisitable): BondHoldingState {
    const bondHolding = holding.parse();
    this._bondHoldings.push(bondHolding);
    return bondHolding;
  }
  post() {
    this._dispatch(
      bondHoldingsActions.upsertManyElements(this._bondHoldings, {
        shouldAutobatch: true,
      }),
    );
    this.positionVisitor.post();
    this.tradeVisitor.post();
  }
}
