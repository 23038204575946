import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectEventsComponent } from '~src/domain/workspace/components/project/event/events.component';
import { DashboardComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/dashboard.component';
import { PortfolioEntitiesComponent } from '~src/domain/workspace/components/project/portfolio/entities/portfolio-entities.component';
import { TabPanel } from '~src/domain/workspace/components/project/portfolio/holdings/tab/tab-panel.component';
import { ReportingComponent } from '~src/domain/workspace/components/project/portfolio/reporting/reporting.component';
import { SettingsComponent } from '~src/domain/workspace/components/project/portfolio/settings/settings.component';
import { ScenariosComponent } from '~src/domain/workspace/components/project/scenario/scenarios.component';
import { PortfolioTransactionsComponent } from '~src/domain/workspace/components/project/transaction/portfolio-transactions.component';
import { ProjectTransfersComponent } from '~src/domain/workspace/components/project/transfer/project-transfers.component';
import { portfolioProjectTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/paths';
import { Tab } from '~src/utils/interfaces/tab';

import { Avatar, Box } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ProjectCommitmentsComponent } from '~src/domain/workspace/components/project/commitment/project-commitments.component';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { getInitials } from '~src/utils/common/get-initials';
import { secondaryBackgroundColor } from '~src/utils/common/colors/base-colors';

export enum PortfolioProjectTabs {
  Dashboard = 'dashboard',
  Settings = 'settings',
  Holdings = 'holdings',
  Reporting = 'reporting',
  Transactions = 'transactions',
  Commitments = 'commitments',
  Transfers = 'transfers',
  Events = 'events',
  Scenarios = 'scenarios',
  // Data = 'data',
}

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  tabs: Tab<PortfolioProjectTabs>[];
  tab: Tab<PortfolioProjectTabs>;
}

interface PropsTab {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  tabName: string;
}

const SubComponent = React.memo((props: PropsTab) => {
  if (props.tabName === PortfolioProjectTabs.Dashboard) {
    return (
      <DashboardComponent
        workspace={props.workspace}
        project={props.project}
        portfolioProject={props.portfolioProject}
        portfolio={props.portfolio}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Settings) {
    return (
      <SettingsComponent
        workspace={props.workspace}
        project={props.project}
        portfolio={props.portfolio}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Scenarios) {
    return (
      <ScenariosComponent workspace={props.workspace} project={props.project} />
    );
  } else if (props.tabName === PortfolioProjectTabs.Transactions) {
    return (
      <PortfolioTransactionsComponent
        portfolioProject={props.portfolioProject}
        workspace={props.workspace}
        project={props.project}
        portfolio={props.portfolio}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Commitments) {
    return (
      <ProjectCommitmentsComponent
        portfolioProject={props.portfolioProject}
        workspace={props.workspace}
        project={props.project}
        portfolio={props.portfolio}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Transfers) {
    return (
      <ProjectTransfersComponent
        workspace={props.workspace}
        projectId={props.project.id}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Holdings) {
    return (
      <PortfolioEntitiesComponent
        workspace={props.workspace}
        project={props.project}
        portfolioProject={props.portfolioProject}
        portfolio={props.portfolio}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Reporting) {
    return (
      <ReportingComponent
        workspace={props.workspace}
        projectId={props.project.id}
      />
    );
  } else if (props.tabName === PortfolioProjectTabs.Events) {
    return (
      <ProjectEventsComponent
        workspace={props.workspace}
        project={props.project}
        portfolioProject={props.portfolioProject}
        portfolio={props.portfolio}
      />
    );
  }
  return <div>unknown</div>;
});

export const TabsComponent = React.memo((props: Props) => {
  // const classes = useStyles();
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newTab: PortfolioProjectTabs,
  ) {
    navigate(
      portfolioProjectTabPath(
        props.workspace.tenantId,
        props.workspace.id,
        props.portfolioProject.projectId,
        newTab,
      ),
    );
  }

  const tabElements = props.tabs.map((tab) => {
    return (
      <MuiTab
        key={tab.name}
        label={tab.name}
        value={tab.name}
        // sx={{ maxWidth: '100%' }}
      />
    );
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        // if (props.tab.name === tab.name) {
        return (
          <SubComponent
            key={index}
            portfolio={props.portfolio}
            portfolioProject={props.portfolioProject}
            tabName={tab.name}
            workspace={props.workspace}
            project={props.project}
          />
        );
        // }
        // return <></>;
      }),
    [
      props.portfolio,
      props.portfolioProject,
      props.project,
      props.tabs,
      props.workspace,
    ],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel value={props.tab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });

  return (
    <>
      <Box sx={{ backgroundColor: secondaryBackgroundColor.light }}>
        <Grid2 container>
          <Grid2 container alignItems="center" sx={{ ml: 1, mr: 2 }}>
            <Avatar variant="square" sx={{ width: 32, height: 32 }}>
              {getInitials(props.project.name ?? '')}
            </Avatar>
          </Grid2>
          <Grid2>
            <Tabs
              value={props.tab.name}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              onChange={handleChange}
              aria-label="portfolio project tabs"
              sx={{
                zIndex: (theme) => theme.zIndex.appBar,
                // flexGrow: 1,
                // width: '100%',
              }}
            >
              {tabElements}
            </Tabs>
          </Grid2>
        </Grid2>
      </Box>
      {tabPanelElements}
    </>
  );
});
