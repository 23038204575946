import { TenantTabs } from '~src/domain/tenant/components/tab/tenant-tabs.component';

export const tenantBaseId = ':tenantId';
export const tenantsRootPath = '/tenants';

export const tenantRootPath = (tenantId: string) => {
  return `${tenantsRootPath}/${tenantId}`;
};

export const tenantBasePath = tenantRootPath(tenantBaseId);

export const tenantCreateRootPath = `${tenantsRootPath}/create`;

export const tenantTabPath = (tenantId: string, tab: TenantTabs) => {
  return `${tenantRootPath(tenantId)}/${tab}`;
};
