import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseLoanId {
  id: string;
  workspaceId: string;
}

export type LoanState = Omit<graphqlWorkspaceTypes.Loan, 'asset' | '_type'> & {
  workspaceId: string;
  assetId: string;
};

export function loanToLoanState(loan: LoanState) {
  return loan;
}
const initialState: RecordState<LoanState, string>[] = [];

const selectKeyId = (loan: BaseLoanId) => loan.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseLoanId, b: BaseLoanId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseLoanId, LoanState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const loansSlice = createBaseRecordSlice('loans', adapter);
export const loansSelectors = adapter.selectors;

export const loansReducer = loansSlice.reducer;
export const loansActions = loansSlice.actions;

export const initialLoanState = {
  loans: initialState,
};

export const loanReducer = combineReducers({
  loans: loansReducer,
});
