import React from 'react';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AddElementButtonComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/add-element-button.component';
import { useConfiguration } from '~src/domain/workspace/components/project/portfolio/dashboard/menu/use-configuration';
import { useDateFilter } from '~src/domain/workspace/components/project/portfolio/dashboard/menu/use-date-filter';
import { useElements } from '~src/domain/workspace/components/project/portfolio/dashboard/menu/use-elements';
import { useFilter } from '~src/domain/workspace/components/project/portfolio/dashboard/menu/use-filter';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { Config } from '~src/utils/interfaces/config';
import { UsageMode } from '~src/utils/interfaces/usage-mode';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const useDashboard = (
  workspace: WorkspaceState,
  project: ProjectState,
  portfolioProject: PortfolioProjectState,
  portfolio: PortfolioState,
  config: Config,
  layouts: ReactGridLayout.Layouts | undefined,
  updateLayouts:
    | ((
        layouts: ReactGridLayout.Layouts,
      ) => Promise<ReactGridLayout.Layouts | undefined>)
    | undefined,
  dashboardElements: DashboardElementState[],
  addDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.DashboardElement[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>,
  removeDashboardElements?: (
    dashboardElementIds: string[],
  ) => Promise<string[]>,
  updateDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>,
) => {
  const { dialog, open, components } = useElements(
    workspace,
    project,
    portfolioProject,
    portfolio,
    config,
    layouts,
    updateLayouts,
    dashboardElements,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  );

  const { configOpen, configButton, toggleConfig } = useConfiguration();
  const { filter, filterButton } = useFilter(
    workspace,
    project,
    portfolioProject,
    portfolio,
  );
  const addElement = React.useMemo(() => {
    if (config.usageMode === UsageMode.edit) {
      return (
        <>
          <AddElementButtonComponent open={open} />
          {dialog}
        </>
      );
    }
  }, [config.usageMode, dialog, open]);
  const { dateFilter } = useDateFilter(config, configOpen);

  const menuElement = React.useMemo(
    () => (
      <Grid2
        container
        justifyContent="space-between"
        sx={{
          position: 'sticky',
          top: 64 + 8, // height of AppBar
          zIndex: (theme) => (configOpen ? 0 : theme.zIndex.modal),
        }}
      >
        <Grid2 container>
          <Grid2>{configButton}</Grid2>
          <Grid2>
            {filterButton}
            {filter}
          </Grid2>
        </Grid2>
        <Grid2>{dateFilter}</Grid2>
        <Grid2>{addElement}</Grid2>
      </Grid2>
    ),
    [addElement, configButton, configOpen, dateFilter, filter, filterButton],
  );
  return {
    menu: menuElement,
    components,
    configOpen,
    toggleConfig,
  };
};
