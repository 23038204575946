import { ValuationStatus } from '@pladdenico/models';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { AppDispatch } from '~src/store/store';
import { createValuations } from '~src/data/store/modules/holdings/valuation/valuations/requests';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function useValuationCreateAction(
  workspace: WorkspaceState,
  holdingId: string,
) {
  const [valuationInput, setValuationInput] =
    React.useState<graphqlWorkspaceTypes.CreateValuationInputType>(() => {
      return {
        id: uuid.v1(),
        currencyId: '',
        date: new Date(), // .setHours(0, 0, 0, 0),
        holdingId,
        ownershipPercentage: 0,
        status: ValuationStatus.confirmed,
        value: 0,
      };
    });
  const dispatch = useDispatch<AppDispatch>();

  const createValuation =
    (callback: (valuations: ValuationState[]) => void) => (): void => {
      dispatch(
        createValuations(workspace.tenantId, workspace.id, [valuationInput]),
      )
        .then((valuations) => {
          return callback(valuations);
        })
        .catch((err) => {
          console.info('FAILED', valuationInput, JSON.stringify(err));
          throw err;
        });
    };
  return { valuationInput, setValuationInput, createValuation };
}
