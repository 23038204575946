import React from 'react';
import { Control, FieldValues, Path, UseFormSetValue } from 'react-hook-form';

import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { CommitmentInputData } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-data-types';
import { CommitmentFieldConfig } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-field-config';
import { CommitmentFieldsComponent } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-fields.component';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

interface Props<T extends FieldValues> {
  projectId: string;
  holding: HoldingState | undefined;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  // index: number;
  fieldConfig?: CommitmentFieldConfig;
  getName: (name: Path<CommitmentInputData>) => Path<T>;
  commitmentTypes: CommitmentType[] | undefined;
}

export const FieldsComponent = <T extends FieldValues>(props: Props<T>) => {
  const {
    projectId,
    holding,
    control,
    setValue,
    fieldConfig,
    commitmentTypes,
    getName,
  } = props;

  if (holding) {
    return (
      <CommitmentFieldsComponent
        projectId={projectId}
        holding={holding}
        control={control}
        setValue={setValue}
        getName={getName}
        fieldConfig={fieldConfig}
        commitmentTypes={commitmentTypes}
      />
    );
  }

  return <></>;
};
