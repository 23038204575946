import numbro from 'numbro';
import React from 'react';
import {
  Bar,
  BarChart,
  Label,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Irr } from '~src/utils/finance/irr';

import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { IrrTooltipComponent } from './irr-tooltip.component';
import { getHoverColor } from '~src/utils/common/colors/color-utils';
import { BarChartFormat } from './bar-chart-format';

interface Props {
  irrs: Irr[];
  title: string;
  format?: BarChartFormat;
}
const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container2: {
    flex: 1,
    overflow: 'hidden',
  },
}));

interface DataProps {
  name: string;
  label: string | undefined;
  irr: number;
}

function createData(irrs: Irr[]): DataProps[] {
  const data = irrs.map((irr) => {
    const name = irr.period.name
      ? irr.period.name
      : `${irr.period.start.format('YYYY-MM-DD')}-${irr.period.end.format(
          'YYYY-MM-DD',
        )}`;
    return {
      name,
      label:
        irr.value === 0
          ? undefined
          : `Period: ${numbro(irr.value).format({
              output: 'percent',
              thousandSeparated: true,
              mantissa: 1,
            })}`,
      irr: irr.value,
    };
  });
  return data;
}

export const PeriodicIrrComponent = React.memo((props: Props) => {
  const { format } = props;
  const { classes } = useStyles();
  const [data, setData] = React.useState<DataProps[]>([]);

  React.useEffect(() => {
    const data = createData(props.irrs);
    setData(data);
  }, [props.irrs]);

  const handleMouseUp = React.useCallback((a: any, b: any, event: any) => {
    console.log(a, b, event);
    if (event.button === 0) {
      console.log('Button 0');
    } else if (event.button === 2) {
      console.log('Button 1');
    }
  }, []);

  const preventContextMenu = React.useCallback((event: any) => {
    console.log(event);
    event.preventDefault();
  }, []);

  const formatYAxis = (irrValue: number) => {
    return numbro(irrValue).format({
      output: 'percent',
      thousandSeparated: true,
      mantissa: 2,
    });
  };

  const barChart = (
    <BarChart data={data} margin={{ top: 25, right: 30, left: 20, bottom: 35 }}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop
            offset="5%"
            stopColor={format?.bar ?? '#8884d8'}
            stopOpacity={0.8}
          />
          <stop
            offset="95%"
            stopColor={format?.bar ?? '#8884d8'}
            stopOpacity={0.1}
          />
        </linearGradient>
      </defs>
      <XAxis
        dataKey="name"
        axisLine={{ stroke: format?.axis?.lineStroke ?? '#FFFFFF' }}
        // axisLine={false}
        tickLine={false}
        // stroke="#FFFFFF"
        tick={{ stroke: format?.axis?.tick ?? '#AAAAAA' }}
      >
        <Label
          value="Periods"
          position="bottom"
          fill={format?.axis?.label?.fill ?? '#FFFFFF'}
        />
      </XAxis>
      <ReferenceLine y={0} stroke={format?.axis?.lineStroke ?? '#FFFFFF'} />
      <YAxis
        axisLine={{ stroke: format?.axis?.lineStroke ?? '#FFFFFF' }}
        tick={{ stroke: format?.axis?.tick ?? '#AAAAAA' }}
        tickFormatter={formatYAxis}
      >
        <Label
          value="IRR"
          offset={-20}
          position="insideTopRight"
          fill={format?.axis?.label?.fill ?? '#FFFFFF'}
        />
      </YAxis>
      {/* <CartesianGrid strokeDasharray="3 3"/> */}
      <Tooltip
        cursor={{
          stroke: format?.tooltip?.stroke ?? 'red',
          fill: getHoverColor(format?.tooltip?.fill ?? '#180C4F', 30),
          strokeWidth: 2,
        }}
        content={
          <IrrTooltipComponent
            style={{ color: format?.tooltip?.content?.fill ?? 'red' }}
          />
        }
      />
      {/* <Legend /> */}
      {/* <ReferenceLine y={0} stroke="#FFFFFF" /> */}
      {/* <ReferenceLine x={0} stroke="#FFF" /> */}
      <Bar dataKey="irr" fill="url(#colorUv)" onMouseUp={handleMouseUp}>
        <LabelList
          dataKey="label"
          position="top"
          stroke={format?.labelList?.fill ?? '#AAAAAA'}
        />
      </Bar>
    </BarChart>
  );

  return (
    <Box p={1} display="flex" flexDirection="column" height="100%">
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        {props.title}
      </Typography>
      <Box onContextMenu={preventContextMenu} className={classes.container2}>
        <ResponsiveContainer>{barChart}</ResponsiveContainer>
      </Box>
    </Box>
  );
});
