import { parseAddress } from '~src/data/store/modules/people/addresses/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  addressesActions,
  AddressState,
} from '~src/data/store/reducers/person/addresses/reducer';
import { Address } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface AddressVisitor {
  visit(address: AddressVisitable): AddressState;
  post(): void;
}

export class AddressVisitable implements Visitable<AddressVisitor> {
  constructor(
    private _workspaceId: string,
    private _personId: string,
    private _address: Address,
  ) {}
  public accept(visitor: AddressVisitor) {
    return visitor.visit(this);
  }

  public parse(): AddressState {
    return parseAddress(this._workspaceId, this._personId, this._address);
  }
}

export class AddressStateVisitor implements AddressVisitor {
  private _addresses: AddressState[];
  constructor(private _dispatch: AppDispatch) {
    this._addresses = [];
  }

  public visit(address: AddressVisitable): AddressState {
    const addressState = address.parse();
    this._addresses.push(addressState);
    return addressState;
  }

  post() {
    this._dispatch(addressesActions.upsertManyElements(this._addresses));
  }
}
