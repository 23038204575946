import { createSelector } from 'reselect';

import createCachedSelector from 're-reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  favoriteHoldingsSelectors,
  FavoriteHoldingState,
} from '~src/data/store/reducers/holding/favorite-holding/reducer';

const getFavoriteHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.favorites;

export const selectFavoriteHoldings = createSelector(
  (state: WorkspaceDataState) =>
    favoriteHoldingsSelectors.selectAllElements(getFavoriteHoldingState(state)),
  (favoriteHoldings): FavoriteHoldingState[] => {
    return favoriteHoldings;
  },
);

export const selectFavoriteHoldingsByProjectId = createCachedSelector(
  (state: WorkspaceDataState) => getFavoriteHoldingState(state),
  (_state: WorkspaceDataState, projectId: string) => projectId,
  (favoriteHoldingsState, projectId): FavoriteHoldingState[] => {
    const favoriteHoldings = favoriteHoldingsSelectors.selectElementsByKey(
      favoriteHoldingsState,
      projectId,
    );
    return favoriteHoldings ? favoriteHoldings : [];
  },
)({
  keySelector: (_state, projectId) => projectId,
  selectorCreator: createSelector,
});

interface BaseFavoriteHoldingId {
  projectId: string;
  holdingId: string;
}

export const selectFavoriteHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getFavoriteHoldingState(state),
  (_state: WorkspaceDataState, props: BaseFavoriteHoldingId) => props.projectId,
  (_state: WorkspaceDataState, props: BaseFavoriteHoldingId) => props.holdingId,
  (favoriteHoldingsState, projectId, holdingId) =>
    favoriteHoldingsSelectors.selectElementByT(favoriteHoldingsState, {
      projectId,
      holdingId,
    }),
)({
  keySelector: (_state, props) => `${props.projectId}:${props.holdingId}`,
  selectorCreator: createSelector,
});
