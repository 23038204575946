import React from 'react';
import { EmailState } from '~src/data/store/reducers/person/emails/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { EmailFormComponent, EmailInput } from './form.component';

interface Props {
  title: string;
  open: boolean;
  emailInput: EmailInput;
  setEmailInput: (emailInput: EmailInput) => void;

  handleSaveEmail: () => Promise<EmailState>;
  handleClose: () => void;
  savedCallback: () => void;
}

export const EmailDialogComponent = React.memo((props: Props) => {
  const handleSaveEmail = (): void => {
    props
      .handleSaveEmail()
      .then((_value) => {
        props.savedCallback();
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', props.emailInput, JSON.stringify(err));
      });
  };

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <EmailFormComponent
          emailInput={props.emailInput}
          setEmailInput={props.setEmailInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveEmail} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
