import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { PaperQuotesGraphComponent } from '~src/domain/workspace/components/finance/papers/paper/paper-quotes-graph.component';
import { paperRootPath } from '~src/navigation/paths/workspace/finance/paper/paths';

import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  paper: PaperState;
}

export const PaperAssetComponent = React.memo((props: Props) => {
  const { workspace, paper } = props;

  const navigate = useNavigate();
  const paperElement = React.useMemo(() => {
    if (paper) {
      return (
        <>
          <Grid2 xs={12}>
            <Typography variant="overline">Paper</Typography>
            <IconButton
              onClick={() =>
                navigate(
                  paperRootPath(workspace.tenantId, workspace.id, paper.id),
                )
              }
            >
              <LinkIcon />
            </IconButton>
          </Grid2>
          <Grid2>
            <Typography variant="subtitle1">{paper.name}</Typography>
          </Grid2>
          <Grid2 xs={12}>
            <PaperQuotesGraphComponent paper={paper} />
          </Grid2>
        </>
      );
    }
  }, [navigate, paper, workspace.id, workspace.tenantId]);
  return paperElement;
});
