import * as graphql from 'graphql';
import * as uuid from 'uuid';
import { Api } from '~src/services/request/api';
import { Subscription } from '~src/services/request/subscription';

import { Logger, LogLevel } from '@pladdenico/common';
import { User } from '@pladdenico/models';

import { SocketListener } from './socket-listener';

export class ObjStorageApi {
  private _logger: Logger;
  private _api: Api;
  private _id: string;

  private _listeners: SocketListener[];

  constructor() {
    this._logger = new Logger('Api', LogLevel.INFO);
    this._id = uuid.v1();

    this._listeners = this._setupListeners();
    this._api = new Api('auth/objstorage');
  }

  private _setupListeners() {
    return [];
  }

  public connect(user: User, token: string) {
    this._api.connect(user, token, this._listeners);
  }

  public subscribe(subscription: Subscription, callback: () => void) {
    this._api.subscribe(subscription, callback);
  }

  public unsubscribe(subscription: Subscription) {
    this._api.unsubscribe(subscription);
  }

  public executeQuery<Context>(
    query: graphql.DocumentNode,
    variables: unknown,
    context: Context,
    callback: (result: any) => void,
  ) {
    this._api.executeGraphQLQuery('api', query, variables, context, callback);
  }
}
