import * as uuid from 'uuid';
import { HoldingComponentConfig } from '~src/domain/workspace/components/project/transaction/form/holding/holding-config';

import {
  TransferStatus,
  ValuationStatus,
  TransferType,
} from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { TransferInputData } from '../../../transfer/form/fields/transfer-data-types';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';

export interface HoldingConfigListener {
  srcHoldingInputId: string;
  destHoldingInputId: string;
  listener: (
    thisHolding: HoldingInputData,
    otherHolding: HoldingInputData,
  ) => HoldingInputData;
}

export interface HoldingInputData {
  id: string;
  holdingId: string | null;
  // holding?: HoldingState | undefined;
  transferInputData: TransferInputData;
  valuationInputData: graphqlWorkspaceTypes.CreateValuationInputType;
  config: HoldingComponentConfig;
  expectedResultingValue?: number;
  // listeners?: HoldingConfigListener[];
}

export const createHoldingInputDataFromConfig = (
  config: HoldingConfig,
  date: Date,
): HoldingInputData => {
  const holdingId = config.holdingId;
  return {
    id: config.id,
    holdingId: holdingId ?? null,
    // holding,
    transferInputData: {
      base: {
        id: uuid.v1(),
        transactionId: '',
        holdingId: holdingId ?? '',
        currencyId: '',
        date,
        // date: new Date(),
        // holding: undefined,
        cost: 0,
        description: '',
        subtype: '',
        // type: undefined,
        type: config.transferType,
        status: TransferStatus.confirmed,
        value: 0,
      },
      data: undefined,
    },
    valuationInputData: {
      currencyId: '',
      date,
      // date: new Date(),
      holdingId: holdingId ?? '',
      id: uuid.v1(),
      ownershipPercentage: 0,
      status: ValuationStatus.confirmed,
      value: 0,
    },
    config: config.component,
    // listeners,
  };
};

export const createHoldingInputData = (
  id: string,
  // transactionId: string,
  transferType: TransferType,
  config: HoldingComponentConfig,
  holdingId?: string,
  // holding?: HoldingState,
  date?: Date,
  // listeners?: HoldingConfigListener[]
): HoldingInputData => {
  return {
    id,
    holdingId: holdingId ?? null,
    // holding,
    transferInputData: {
      base: {
        id: uuid.v1(),
        transactionId: '',
        holdingId: holdingId ?? '',
        currencyId: '',
        date,
        // date: new Date(),
        // holding: undefined,
        cost: 0,
        description: '',
        subtype: '',
        // type: undefined,
        type: transferType,
        status: TransferStatus.confirmed,
        value: 0,
      },
      data: undefined,
    },
    valuationInputData: {
      currencyId: '',
      date,
      // date: new Date(),
      holdingId: holdingId ?? '',
      id: uuid.v1(),
      ownershipPercentage: 0,
      status: ValuationStatus.confirmed,
      value: 0,
    },
    config,
    // listeners,
  };
};
