import React from 'react';
import * as uuid from 'uuid';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { DashboardElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/dashboard-element-description.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { Box, Divider, Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  primaryBackgroundColor,
  primaryFrontColor,
} from '~src/utils/common/colors/base-colors';
import { sortBy } from 'lodash';

export type InputType = DashboardElementState;

export const useAddDashboardElement = (
  items: { type: string }[],
  add: (
    dasboardElement: DashboardElementState,
  ) => Promise<graphqlWorkspaceTypes.DashboardElement>,
) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { close, isShowing, open } = useVisible();

  const [selected, setSelected] = React.useState<string | undefined>(undefined);

  const handleSave = React.useCallback(() => {
    if (selected != null) {
      return add({ id: uuid.v1(), type: selected, config: {}, name: '' })
        .then((_) => {
          close();
        })
        .catch((err) => {
          console.info('FAILED', JSON.stringify(err));
        });
    }
    return Promise.resolve();
  }, [selected, add, close]);

  const elements = React.useMemo(() => {
    const sortedItems = sortBy(items, [(item) => item.type]);
    return sortedItems.map((item) => {
      const isSelected = item.type === selected;
      return (
        <Box
          key={item.type}
          sx={{
            ':hover': {
              backgroundColor: primaryBackgroundColor.hoverMedium,
            },
          }}
        >
          <Box
            onClick={() => setSelected(item.type)}
            sx={{
              p: 1,
              border: isSelected ? '1px solid black' : 'unset',
              backgroundColor: isSelected
                ? primaryBackgroundColor.hoverDark
                : 'unset',
              color: isSelected ? primaryFrontColor.light : 'unset',
            }}
          >
            <DashboardElementDescriptionComponent
              type={item.type}
              isSelected={isSelected}
            />
          </Box>
          <Divider />
        </Box>
      );
    });
  }, [items, selected]);

  const dialog = React.useMemo(
    () => (
      <DialogComponent
        dialogProps={{
          open: isShowing,
          onClose: close,
          fullWidth: true,
          fullScreen,
        }}
      >
        <DialogContentComponent
          handleCancel={close}
          handleOnSave={handleSave}
          title="Add dashboard element"
          confirmLabel="Add"
        >
          {elements}
        </DialogContentComponent>
      </DialogComponent>
    ),
    [isShowing, close, fullScreen, handleSave, elements],
  );

  return {
    dialog,
    open,
  };
};
