import { all, BlockNode, create } from 'mathjs';

import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';

export const initMath = (importObjects: math.ImportObject[] = []) => {
  const math = create(all);
  math.import(importObjects);
  return math;
};

export class ExpressionHandler<EvaluatorState, ExpressionVariables> {
  _code: math.EvalFunction | undefined;
  _node: math.MathNode | undefined;

  constructor(
    private _math: math.MathJsStatic,
    private _id: string,
    private _name: string,
    private _expressionsJS: Expression[],
    private _variables: ExpressionVariables,
  ) {
    try {
      const inResultExpression = this._expressionsJS
        .filter((expression) => expression.inResult)
        .map((expression) => expression.value)
        .join(',');
      const notInResultExpression = this._expressionsJS
        .filter((expression) => !expression.inResult)
        .map((expression) => expression.value)
        .join(';');
      const expressionsAsString = `${notInResultExpression};{${inResultExpression}}`;

      this._node = this._math.parse(expressionsAsString);
      // console.log(this._node);
      this._code = this._node.compile();
    } catch (_e) {
      console.log('err', _e);
    }
  }

  public getId() {
    return this._id;
  }

  public getName() {
    return this._name;
  }

  public getExpressionsJS() {
    return this._expressionsJS;
  }

  public getLatex() {
    // const customLaTex = (node: math.MathNode) => {
    //   console.log(node.type, node.toString());
    //   if (node.type === 'BlockNode') {
    //     node.blocks.map((block) => )
    //     return '\\\\';
    //   }
    // };
    if (this._node?.type === 'BlockNode') {
      const node = this._node as BlockNode;
      return node.blocks.map((block) => {
        return `$${block.node.toTex({
          parenthesis: 'auto',
          implicit: 'hide',
          // handler: customLaTex,
        })}$`;
      });
    }
    return [
      this._node?.toTex({
        parenthesis: 'auto',
        implicit: 'hide',
        // handler: customLaTex,
      }),
    ];
  }

  public evaluate(state: EvaluatorState): unknown {
    if (this._code == null) {
      return undefined;
    }
    const resultSet = this._code.evaluate({
      ...state,
      variables: this._variables,
    });
    return resultSet.entries[0];
  }
}
