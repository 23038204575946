import { ProjectType } from '@pladdenico/models';
import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { SelectType } from '~src/components/fields/common/select-type';

export const useProjectTypeField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  projectTypes: ProjectType[],
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<TFieldValues, TName>,
      _fieldState: ControllerFieldState,
    ) => {
      const items = projectTypes.map((projectType) => {
        return {
          type: projectType,
          title: projectType,
        };
      });
      return (
        <SelectType
          handleValue={field.onChange}
          label={'Type'}
          type={field.value as ProjectType}
          items={items}
        />
      );
    },
    [projectTypes],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => elementControlled(field, fieldState)}
      />
    );
  }, [defaultValue, elementControlled, tName, control]);

  return element;
};
