import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { typeToString } from '~src/components/fields/workspace/asset/type/type-to-string';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { AssetType } from '@pladdenico/models';

const useStyles = makeStyles()(() => ({
  types: {
    minWidth: 100,
  },
}));
interface Props {
  handleValue: (type: AssetType) => void;
  type: AssetType | undefined;
  label: string | undefined;
  disabled: boolean;
}

export const EditTypeField = React.memo((props: Props) => {
  const { classes } = useStyles();
  const handleSelectChange = (
    event: SelectChangeEvent<AssetType>,
    // event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.handleValue(event.target.value as AssetType);
  };

  const label = props.label ? (
    <InputLabel htmlFor="Type">{props.label}</InputLabel>
  ) : undefined;

  const menuItems = React.useMemo(() => {
    return Object.values(AssetType).map((type) => {
      return (
        <MenuItem key={type} value={type}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, []);

  return (
    <FormControl
      margin="dense"
      className={classes.types}
      disabled={props.disabled}
      fullWidth
    >
      {label}
      <Select
        value={props.type}
        onChange={handleSelectChange}
        label={props.label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
