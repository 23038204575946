import { InvestorState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { PrivateEquityFundInvestor } from '~src/services/graphql/workspace/client/graphql';

export function parseInvestor(
  _workspaceId: string,
  fundId: string,
  investor: PrivateEquityFundInvestor,
): InvestorState {
  return {
    id: investor.id,
    fundId,
    description: investor.description,
    name: investor.name,
  };
}
