import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { FundHoldingState } from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import { FundPositionState } from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getFundPositionsByFundHoldingId } from '~src/data/store/selectors/holding/holding-types/fund/fund-position/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useFundPositionTableActions } from '~src/hooks/actions/workspace/project/holding/types/funds/use-fund-position-table-actions';
import {
  FundPositionData,
  useFundPositionFields,
} from '~src/hooks/fields/workspace/project/holding/use-fund-position-fields';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { FundPositionsToolbar } from './fund-positions-toolbar.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState;
  fundHolding: FundHoldingState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function fundPositionsToData(fundPositions: FundPositionState[]) {
  return fundPositions;
}

export const FundPositionsComponent = React.memo((props: Props) => {
  const fundPositions = useSelector((state: RootState) =>
    getFundPositionsByFundHoldingId(
      selectWorkspaceData(state),
      props.fundHolding.id,
    ),
  );
  const { classes } = useStyles();
  const [data, setData] = React.useState<FundPositionData[]>(
    fundPositionsToData(fundPositions),
  );
  const { onRowUpdate, onRowDelete } = useFundPositionTableActions(
    props.workspace,
  );
  const fields = useFundPositionFields(
    props.workspace.tenantId,
    props.workspace,
    props.projectId,
    props.holding.id,
  );

  React.useEffect(() => {
    setData(fundPositionsToData(fundPositions));
  }, [fundPositions]);

  const lastFundPositionIdx = fundPositions.reduce(
    (prevFundPosition, currentFundPosition, idx) => {
      if (
        !prevFundPosition ||
        fundPositions[idx].date.getTime() < currentFundPosition.date.getTime()
      ) {
        return idx;
      }
      return prevFundPosition;
    },
    -1,
  );

  let fundPositionCreateInputInit: graphqlWorkspaceTypes.CreateFundPositionInputType;
  if (lastFundPositionIdx !== -1) {
    const lastFundPosition = fundPositions[lastFundPositionIdx];
    fundPositionCreateInputInit = {
      id: uuid.v1(),
      date: lastFundPosition.date ?? new Date(),
      shares: lastFundPosition.shares ?? 0,
      fundHoldingId: props.fundHolding.id,
    };
  } else {
    fundPositionCreateInputInit = {
      id: uuid.v1(),
      date: new Date(),
      shares: 0,
      fundHoldingId: props.fundHolding.id,
    };
  }

  const [initialSorters] = React.useState<
    TableField.Sorter<FundPositionData>[]
  >(() => {
    return compact([
      TableOperations.createSorterFromFields(fields, 'date', 'desc'),
    ]);
  });

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  return (
    <div className={classes.root}>
      <FundPositionsToolbar
        workspace={props.workspace}
        fundPositionCreateInputInit={fundPositionCreateInputInit}
      />
      <div className={classes.content}>
        <TableComponent<FundPositionData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
});
