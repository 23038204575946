import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHandler } from '~src/hooks/utils/use-handler';

import { Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  fileInput: graphqlWorkspaceTypes.UpdateFileInputType;
  setFileInput: (fileInput: graphqlWorkspaceTypes.UpdateFileInputType) => void;
  showId?: boolean;
}
const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  formControl: {
    minWidth: 100,
  },
}));

export const FieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const { handleEvent } = useHandler(props.fileInput, props.setFileInput);

  return (
    <>
      <Grid item sx={{ ml: 0, mr: 1 }}>
        <TextField
          autoFocus
          margin="normal"
          className={classes.textField}
          required
          id="name"
          value={props.fileInput.name}
          label="Name"
          type="text"
          onChange={handleEvent('name')}
        />
      </Grid>
      <Grid item sx={{ ml: 0, mr: 1 }}>
        <TextField
          margin="normal"
          className={classes.textField}
          required
          id="description"
          value={props.fileInput.description}
          label="Description"
          type="text"
          onChange={handleEvent('description')}
        />
      </Grid>
    </>
  );
});
