import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditFileComponent } from '~src/domain/workspace/components/file/file/edit.component';
import { ImageComponent } from '~src/components/utils/image/image.component';
import { fetchSignedUrls } from '~src/data/store/modules/obj-storage/objs/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getFileById } from '~src/data/store/selectors/file/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';
import { Config } from '~src/utils/interfaces/config';

import { Box, Typography } from '@mui/material';
import { graphqlObjStorageOperations } from '@pladdenico/obj-storage';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { fetchFiles } from '~src/data/store/modules/file/file/requests';

interface Props {
  workspace: WorkspaceState;
  config: Config;
  fileId: string | undefined;
  updateFileCallback?: (file: FileState) => Promise<void>;
}

export const WorkspaceImageComponent = React.memo((props: Props) => {
  const { fileId } = props;

  if (fileId) {
    return <WorkspaceImageComponentWithFileId {...props} fileId={fileId} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>No image set</Typography>
    </Box>
  );
});

interface WorkspaceImageComponentWithFileIdProps extends Props {
  fileId: string;
}

export const WorkspaceImageComponentWithFileId = React.memo(
  (props: WorkspaceImageComponentWithFileIdProps) => {
    const { workspace, fileId, updateFileCallback } = props;
    const file = useSelector((state: RootState) =>
      getFileById(selectWorkspaceData(state), { id: fileId }),
    );
    const [url, setUrl] = React.useState<{ id: string; url: string }>();
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      if (!file) {
        dispatch(fetchFiles(workspace.tenantId, workspace.id, [fileId]));
      }
    }, [dispatch, file, fileId, workspace.id, workspace.tenantId]);

    React.useEffect(() => {
      if (file) {
        dispatch(
          fetchSignedUrls(
            [file.id],
            graphqlObjStorageOperations.ObjOperationType.Get,
          ),
        ).then((urls) => {
          if (urls) {
            setUrl(urls[0]);
          }
        });
      }
    }, [dispatch, file]);

    const editModal = useVisible();
    const editComponent = React.useMemo(() => {
      if (file) {
        return (
          <EditFileComponent
            handleClose={editModal.close}
            isShowing={editModal.isShowing}
            file={file}
            tenantId={workspace.tenantId}
            workspace={workspace}
            updateFileCallback={updateFileCallback}
          />
        );
      }
    }, [
      editModal.close,
      editModal.isShowing,
      file,
      updateFileCallback,
      workspace,
    ]);

    if (file) {
      return (
        <>
          <ImageComponent
            file={file}
            url={url}
            actions={{ editFile: editModal.open }}
          />
          {editComponent}
        </>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>No image set</Typography>
      </Box>
    );
  },
);
