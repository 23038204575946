import { BondState } from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { Bond } from '~src/services/graphql/workspace/client/graphql';

export function parseBond(
  workspaceId: string,
  assetId: string,
  bond: Bond,
): BondState {
  return {
    workspaceId,
    assetId,
    id: bond.id,
    // bondExchange: bond.bondExchange,
    // symbol: bond.symbol,
    paperId: bond.paperId,
  };
}
