import moment from 'moment';
import numbro from 'numbro';
import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { NordnetEditTransactionComponent } from '~src/domain/workspace/components/data/adapters/nordnet/form/edit-transaction.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { NordnetTransactionState } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';

import EditIcon from '@mui/icons-material/Edit';
import { colors, Grid, IconButton, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  nordnetTransaction: NordnetTransactionState;
  active: boolean;
  onClick: (ctrlKey: boolean, transaction: NordnetTransactionState) => void;
}

export const NordnetImportedTransactionComponent = React.memo(
  (props: Props) => {
    const { workspace, projectId, nordnetTransaction, active, onClick } = props;
    // const dispatch = useDispatch<AppDispatch>();
    // const dialog = useModal();
    const editDialog = useVisible();

    // const initialFormValues: FormValues = React.useMemo(() => {
    //   const holdingConfig: HoldingConfig = getHoldingConfigDeposit();

    //   const holdingInputData = createHoldingInputDataFromConfig(
    //     holdingConfig,
    //     nordnetTransaction.date
    //   );
    //   holdingInputData.transferInputData.base.date = nordnetTransaction.date;
    //   holdingInputData.valuationInputData.date = nordnetTransaction.date;
    //   holdingInputData.transferInputData.base.value = Math.abs(
    //     nordnetTransaction.value
    //   );
    //   return {
    //     id: uuid.v1(),
    //     projectId,
    //     title: '',
    //     description: nordnetTransaction.description,
    //     date: nordnetTransaction.date,
    //     holdings: [
    //       holdingInputData,
    //       // buyHoldingInputData
    //     ],
    //     type: nordnetTransaction.transactionType,
    //     // type: '',
    //   };
    // }, [
    //   nordnetTransaction.date,
    //   nordnetTransaction.value,
    //   nordnetTransaction.description,
    //   nordnetTransaction.transactionType,
    //   projectId,
    // ]);

    // const setHandled = React.useCallback(
    //   (transaction: TransactionState) => {
    //     dispatch(
    //       nordnetTransactionsActions.upsertOneElement({
    //         ...nordnetTransaction,
    //         handled: true,
    //         transactionId: transaction.id,
    //       })
    //     );
    //   },
    //   [dispatch, nordnetTransaction]
    // );

    // const handleSkipped = React.useCallback(() => {
    //   dispatch(
    //     nordnetTransactionsActions.upsertOneElement({
    //       ...nordnetTransaction,
    //       handled: true,
    //     })
    //   );
    // }, [dispatch, nordnetTransaction]);

    // const setUnhandled = React.useCallback(() => {
    //   transactionPrevious();
    // }, [transactionPrevious]);

    // const handleSuccess = React.useCallback(
    //   (transaction: TransactionState) => {
    //     setHandled(transaction);
    //     dialog.close();
    //   },
    //   [dialog, setHandled]
    // );

    const handleEditSuccess = React.useCallback(() => {
      editDialog.close();
    }, [editDialog]);

    const backgroundColor = React.useMemo(() => {
      if (nordnetTransaction.handled) {
        if (nordnetTransaction.transactionId == null) {
          return colors.brown[50];
        } else if (active) {
          return colors.green[800];
        } else {
          return colors.green[300];
        }
      } else if (active) {
        return colors.brown[500];
      }
      return colors.brown[200];
    }, [active, nordnetTransaction.handled, nordnetTransaction.transactionId]);

    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        onClick={(event) => event && onClick(event.ctrlKey, nordnetTransaction)}
        // columnSpacing={1}
      >
        <Grid
          container
          item
          xs={1}
          alignSelf="stretch"
          // justifyContent="center"
          alignItems="center"
          sx={{ pl: 1, color: 'white', backgroundColor }}
        >
          <Typography variant="body2">Nordnet</Typography>
        </Grid>
        <Grid item xs={1} sx={{ pl: 1 }}>
          <Typography variant="body2">
            {moment(nordnetTransaction.date).format('YYYY-MM-DD')}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ pl: 1 }}>
          <Typography variant="body2">{nordnetTransaction.ISIN}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ pl: 1 }}>
          <Typography variant="body2">{nordnetTransaction.paper}</Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="body2" margin={1}>
            {numbro(nordnetTransaction.value).format('0,0')}
          </Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="body2" margin={1}>
            {numbro(nordnetTransaction.volume).format('0,0')}
          </Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="body2" margin={1}>
            {numbro(nordnetTransaction.price).format('0,0')}
          </Typography>
        </Grid>
        <Grid container item xs={2} sx={{ pl: 1 }}>
          <Typography variant="body2">
            {nordnetTransaction.transactionId}
          </Typography>
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          {/* <IconButton
            onClick={dialog.open}
            disabled={!active || nordnetTransaction.handled}
          >
            <AddCircleIcon />
          </IconButton> */}
          <IconButton
            onClick={editDialog.open}
            disabled={!active || nordnetTransaction.handled}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton
            onClick={setUnhandled}
            disabled={!active || nordnetTransaction.handled}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton
            onClick={handleSkipped}
            disabled={!active || nordnetTransaction.handled}
          >
            <SkipNextIcon />
          </IconButton> */}
        </Grid>
        {/* <CreateComponent
          workspace={workspace}
          projectId={projectId}
          // holding={holding}
          open={dialog.isShowing}
          handleClose={dialog.close}
          handleSuccess={handleSuccess}
          initialFormValues={initialFormValues}
        /> */}
        <NordnetEditTransactionComponent
          workspace={workspace}
          projectId={projectId}
          open={editDialog.isShowing}
          handleClose={editDialog.close}
          handleSuccess={handleEditSuccess}
          transaction={nordnetTransaction}
        />
      </Grid>
    );
  },
);
