import { Control } from 'react-hook-form';
import { InputBondData } from '~src/domain/workspace/components/asset/types/bonds/form/bond-input.data';
import { usePaperField } from '~src/domain/workspace/components/asset/form/fields/types/use-paper.field';

import { useTextField } from '~src/utils/form/fields/text-field';

export const useBondFields = (control: Control<InputBondData, any>) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const paperField = usePaperField(control, 'paperId', 'Paper', '');

  return {
    nameField,
    paperField,
  };
};
