import * as React from 'react';
import { UserLogin } from '~src/domain/user/components/login/user-login';

import { Alert, AlertTitle, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  userInput: UserLogin;
  setUserInput: (userInput: UserLogin) => void;
  loginError: any;
  setLoginError: (loginError: any) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginRight: theme.spacing(2),
    // width: 300,
    // flexBasis: 200,
  },
}));

export function LoginFieldsComponent(props: Props) {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof UserLogin) =>
    (event: React.ChangeEvent<{ value: unknown }>) => {
      props.setUserInput({ ...props.userInput, [name]: event.target.value });
    };

  const handleDismissFailedToLogin = () => {
    props.setLoginError(undefined);
  };

  let failedToLoginElement: React.ReactElement<unknown> | undefined;
  if (props.loginError) {
    failedToLoginElement = (
      <Alert severity="error" onClose={handleDismissFailedToLogin}>
        <AlertTitle>Login</AlertTitle>
        Failed to login: {props.loginError.message}
      </Alert>
    );
  }

  return (
    <>
      {failedToLoginElement}
      <TextField
        margin="normal"
        className={classes.textField}
        required
        autoComplete="email"
        id="input-email"
        value={props.userInput.email}
        label="Email"
        type="text"
        autoFocus
        onChange={handleChange('email')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        autoComplete="current-password"
        id="password"
        value={props.userInput.password}
        label="Password"
        type="password"
        onChange={handleChange('password')}
      />
      {/* <Button type="submit" color="primary">
        Save
      </Button> */}
    </>
  );
}
