import { bankNotesSelectors } from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import { bondsSelectors } from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { cryptosSelectors } from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { fundsSelectors } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { loansSelectors } from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { privateEquityFundsSelectors } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { stocksSelectors } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { warrantsSelectors } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { assetsSelectors } from '~src/data/store/reducers/asset/assets/reducer';
import { entitiesSelectors } from '~src/data/store/reducers/entity/entities/reducer';
import { regionAllocationSelectors } from '~src/data/store/reducers/entity/region-allocations/reducer';
import { allocationSelectors } from '~src/data/store/reducers/portfolio/allocations/reducer';
import { portfolioSelectors } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ExportWorkspaceStore } from '~src/domain/workspace/components/data/workspace/export-workspace-store';
import { ExportHandler } from '~src/services/xlsx/handlers/ioutput-handler';
import { assetExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/assets/mapper';
import { bankNoteExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/bank-notes/mapper';
import { bondExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/bonds/mapper';
import { cryptoExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/cryptos/mapper';
import { fundExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/funds/mapper';
import { loanExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/loans/mapper';
import { privateEquityFundExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/private-equity-funds/mapper';
import { stockExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/stocks/mapper';
import { warrantExportHandler } from '~src/services/xlsx/handlers/workspaces/assets/warrants/mapper';
import { entityExportHandler } from '~src/services/xlsx/handlers/workspaces/entities/entities/mapper';
import { regionAllocationExportHandler } from '~src/services/xlsx/handlers/workspaces/entities/region-allocations/mapper';
import { exportHandlers as personExportHandlers } from '~src/services/xlsx/handlers/workspaces/people/export-handlers';
import { portfolioAllocationExportHandler } from '~src/services/xlsx/handlers/workspaces/portfolios/allocations/mapper';
import {
  PortfolioHolding,
  portfolioHoldingExportHandler,
} from '~src/services/xlsx/handlers/workspaces/portfolios/portfolio-holdings/mapper';
import { portfolioExportHandler } from '~src/services/xlsx/handlers/workspaces/portfolios/portfolios/mapper';
import { exportHandlers as projectExportHandlers } from '~src/services/xlsx/handlers/workspaces/projects/export-handlers';

export const workspaceExportHandlers = (store: ExportWorkspaceStore) => {
  // if (!normalizedWorkspace) {
  //   return undefined;
  // }
  const handlers: ExportHandler<any>[] = [];

  const state = store.getState();

  const assets = assetsSelectors.selectAllElements(state.holding.asset.assets);
  handlers.push(assetExportHandler(assets));
  const bankNotes = bankNotesSelectors.selectAllElements(
    state.holding.asset.bankNote.bankNotes,
  );
  handlers.push(bankNoteExportHandler(bankNotes));
  const stocks = stocksSelectors.selectAllElements(
    state.holding.asset.stock.stocks,
  );
  handlers.push(stockExportHandler(stocks));
  const funds = fundsSelectors.selectAllElements(
    state.holding.asset.fund.funds,
  );
  handlers.push(fundExportHandler(funds));
  const loans = loansSelectors.selectAllElements(
    state.holding.asset.loan.loans,
  );
  handlers.push(loanExportHandler(loans));
  const warrants = warrantsSelectors.selectAllElements(
    state.holding.asset.warrant.warrants,
  );
  handlers.push(warrantExportHandler(warrants));
  const bonds = bondsSelectors.selectAllElements(
    state.holding.asset.bond.bonds,
  );
  handlers.push(bondExportHandler(bonds));
  const cryptos = cryptosSelectors.selectAllElements(
    state.holding.asset.crypto.cryptos,
  );
  handlers.push(cryptoExportHandler(cryptos));
  const privateEquityFunds = privateEquityFundsSelectors.selectAllElements(
    state.holding.asset.privateEquityFund.privateEquityFunds,
  );
  handlers.push(privateEquityFundExportHandler(privateEquityFunds));

  const entities = entitiesSelectors.selectAllElements(
    state.holding.entity.entities,
  );
  handlers.push(entityExportHandler(entities));
  const regionAllocations = regionAllocationSelectors.selectAllElements(
    state.holding.entity.regionAllocation.regionAllocations,
  );
  handlers.push(regionAllocationExportHandler(regionAllocations));
  const portfolios = portfolioSelectors.selectAllElements(
    state.portfolio.portfolios,
  );
  handlers.push(portfolioExportHandler(portfolios));

  handlers.push(
    portfolioHoldingExportHandler(
      portfolios.reduce<PortfolioHolding[]>((prev, curr) => {
        const portfolioHoldings = curr.holdingIds.map((id) => {
          return {
            portfolioId: curr.id,
            holdingId: id,
          };
        });
        return [...prev, ...portfolioHoldings];
      }, []),
    ),
  );
  const portfolioAllocations = allocationSelectors.selectAllElements(
    state.portfolio.allocations,
  );
  handlers.push(portfolioAllocationExportHandler(portfolioAllocations));
  // const portfolioConfigs = portfolioConfigSelectors.selectAllElements(
  //   state.portfolio.portfolioConfigs
  // );
  // handlers.push(portfolioConfigExportHandler(portfolioConfigs));

  const projectHandlers = projectExportHandlers(store);
  projectHandlers && projectHandlers.forEach((value) => handlers.push(value));

  const personHandlers = personExportHandlers(store);
  personHandlers && personHandlers.forEach((value) => handlers.push(value));

  return handlers;
};
