import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/field-config';
import { getBuyTransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/transfer/buy-field-config';
import { getBuyValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/valuation/buy-field-config';

export const getBuyFieldConfig = (
  holding: HoldingState,
  editAll: boolean,
): HoldingFieldConfig => {
  return {
    transfer: getBuyTransferFieldConfig(holding, editAll),
    valuation: getBuyValuationFieldConfig(holding, editAll),
  };
};
