import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  isOpen: boolean;
  close: () => void;
  handler: () => void;
  objectString?: string;
  text?: {
    title?: string;
    description?: string;
  };
}

export const ConfirmDialogComponent = (props: Props) => {
  const { handler, close, isOpen, objectString, text } = props;
  const descriptionText = React.useMemo(() => {
    if (text?.description == null) {
      return `Are you sure you want to remove the ${objectString}`;
    }
    return text.description;
  }, [objectString, text?.description]);
  const titleText = React.useMemo(() => {
    if (text?.title == null) {
      return 'Confirm removal';
    }
    return text.title;
  }, [text?.title]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {descriptionText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="warning">
          Disagree
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handler();
          }}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
