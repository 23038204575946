import moment from 'moment';
import { finance } from '@pladdenico/finance';
import { createSelector } from 'reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';

import { Filter } from '../../../../../utils/common/filter';
import { getHoldingsCashFlowsWithStartAndEnd } from '../transfers/holdings-cash-flows';
import { DataState } from '~src/data/store/reducers/reducers';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  startDate: moment.Moment;
  endDate: moment.Moment;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsXIRR = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.startDate,
  (_state: DataState, props: Props) => props.endDate,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    startDate,
    endDate,
    filter,
    useLiveValue,
  ) => {
    const cashFlows = getHoldingsCashFlowsWithStartAndEnd(state, {
      workspaceId,
      projectId,
      holdings,
      date,
      currency,
      startDate,
      endDate,
      filter,
      useLiveValue,
    });
    if (
      cashFlows.length === 0 ||
      cashFlows.every((cashFlow) => cashFlow.value === 0) ||
      cashFlows.every((cashFlow) => cashFlow.value <= 0) ||
      cashFlows.every((cashFlow) => cashFlow.value >= 0)
    ) {
      // ||
      // (cashFlows.length === 2 && cashFlows[0].date === cashFlows[1].date)) {
      return 0;
    }

    if (cashFlows.length === 0) {
      return 0;
    }

    const values = cashFlows.map((cashFlow) => {
      return cashFlow.value;
    });
    const dates = cashFlows.map((cashFlow) => {
      return cashFlow.date;
    });

    const res = finance.XIRR(values, dates, 0);
    return res;
  },
);
