import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { useTenantEdit } from '~src/domain/tenant/components/form/use-tenant-edit';

interface Props {
  tenant: TenantState;
}

export const TenantSettingsComponent = React.memo((props: Props) => {
  const { tenant } = props;

  const tenantEdit = useTenantEdit(tenant);

  return (
    <Grid2 container p={1}>
      <Grid2 xs={4}>{tenantEdit.tenantFields.nameField}</Grid2>
    </Grid2>
  );
});
