import * as React from 'react';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
// import history from '~src/navigation/history';

import { Link, TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';
import { stockPositionBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/stock/stock-position/paths';
import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { eventParser } from '~src/utils/form/event-parser';
import { useHoldingField } from './use-holding-field';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useNavigate } from 'react-router-dom';

export type StockPositionData = StockPositionState;

export function useStockPositionFields(
  tenantId: string,
  workspace: WorkspaceState,
  projectId: string,
  holdingId: string,
): TableField.Field<StockPositionData>[] {
  const holdingField = useHoldingField<StockPositionData>(
    () => holdingId,
    undefined,
    workspace,
    projectId,
  );
  const navigate = useNavigate();

  function handleStockPositionClick(
    tenantId: string,
    workspaceId: string,
    projectId: string,
    holdingId: string,
    stockPositionId: string,
  ) {
    navigate(
      stockPositionBasePath(
        tenantId,
        workspaceId,
        projectId,
        holdingId,
        stockPositionId,
      ),
    );
  }

  const fields: TableField.Field<StockPositionData>[] = [
    {
      id: 'id',
      name: 'id',
      field: 'id',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.id,
      renderView: (viewProps) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handleStockPositionClick(
                tenantId,
                workspace.id,
                projectId,
                holdingId,
                viewProps.data.id,
              )
            }
          >
            {viewProps.data.id}
          </Link>
        );
      },
    },
    {
      id: 'date',
      name: 'date',
      field: 'date',
      type: TableField.FieldTypes.date,
      iteratee: (data) => data.date,
      update: (data, date: Date) => {
        return { ...data, date };
      },
      renderEdit: (editProps) => {
        return (
          <EditDateField
            handleValue={editProps.onChange}
            date={editProps.data.date}
            labelled={false}
          />
        );
      },
    },
    { ...holdingField },
    {
      id: 'shares',
      name: 'shares',
      field: 'shares',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.shares,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        return { ...data, shares: eventParser(event) as number };
      },
      renderView: (viewProps) => {
        return <>{viewProps.data.shares}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="shares"
            value={editProps.data.shares}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
    },
  ];
  return fields;
}
