import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HorizontalImages } from '~src/components/utils/image/horizontal-images.component';
import { fetchFiles } from '~src/data/store/modules/file/file/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getFiles } from '~src/data/store/selectors/file/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { File } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  selectFile: (file: File) => void;
}

export const useImagePicker = (props: Props) => {
  const { workspace, selectFile } = props;
  const files = useSelector((state: RootState) =>
    getFiles(selectWorkspaceData(state)),
  );
  const visible = useVisible();
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (visible.isShowing) {
      dispatch(fetchFiles(workspace.tenantId, workspace.id, null));
    }
  }, [dispatch, visible.isShowing, workspace.id, workspace.tenantId]);

  const dialogElement = React.useMemo(
    () => (
      <Dialog
        open={visible.isShowing}
        onClose={visible.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select image</DialogTitle>
        <DialogContent>
          <HorizontalImages files={files} selectFile={selectFile} />
        </DialogContent>
        <DialogActions>
          <Button onClick={visible.close} color="warning">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [files, selectFile, visible.close, visible.isShowing],
  );
  return { visible, dialogElement };
};
