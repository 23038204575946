import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getEventById } from '~src/data/store/selectors/workspace/projects/event/event/selectors';
import {
  eventBasePath,
  eventsBasePath,
} from '~src/navigation/paths/workspace/project/event/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { useProjectBreadcrumbs } from './project-breadcrumb';
import { LinkRouter, maxItems } from './breadcrumbs.component';

function useEventBreadcrumb(_last: boolean) {
  const { tenantId, workspaceId, projectId, eventId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    eventId: string;
  }>();
  const eventsBreadcrumbs = useEventsBreadcrumb(false);
  const event = useSelector((state: RootState) => {
    if (eventId != null && projectId != null) {
      return getEventById(selectWorkspaceData(state), {
        id: eventId,
        projectId,
      });
    }
  });
  if (event && tenantId != null && workspaceId != null) {
    return [
      eventsBreadcrumbs,
      <Typography
        color="inherit"
        key={eventBasePath(tenantId, workspaceId, event.projectId, event.id)}
      >
        {event.name}
      </Typography>,
    ];
  }
  return [];
}
function useEventsBreadcrumb(last: boolean) {
  const { tenantId, workspaceId, projectId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
  }>();
  const projectBreadCrumbs = useProjectBreadcrumbs(false);
  if (tenantId != null && workspaceId != null && projectId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        key={eventsBasePath(tenantId, workspaceId, projectId)}
      >
        events
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={eventsBasePath(tenantId, workspaceId, projectId)}
        to={eventsBasePath(tenantId, workspaceId, projectId)}
      >
        events
      </LinkRouter>
    );
    return [projectBreadCrumbs, projectElement];
  }
  return [];
}
export function EventBreadcrumbComponent() {
  const eventBreadcrumbs = useEventBreadcrumb(true);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {eventBreadcrumbs}
    </Breadcrumbs>
  );
}
export function EventsBreadcrumbComponent(props: { last: boolean }) {
  const eventsBreadcrumbs = useEventsBreadcrumb(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {eventsBreadcrumbs}
    </Breadcrumbs>
  );
}
