import '~src/index.css';
import '~src/vendor';

import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
// configureStore.js
// ...
// import { createBrowserHistory } from 'history';
// import { applyMiddleware, compose, createStore } from 'redux';
// import { routerMiddleware } from 'connected-react-router';
// import rootReducer from '~src/reducers';
// import appReducer from '~src/store/reducers';
// import thunk from 'redux-thunk';
// ...
import { Provider } from 'react-redux';
import { App } from '~src/app';
// import { AppContainer } from 'react-hot-loader';
// import history from '~src/navigation/history';
// import * as serviceWorker from '~src/serviceWorker';
import {
  createPostRenderListeners,
  createPreRenderListeners,
} from '~src/data/store/listeners/listeners';
import { initializeApis } from '~src/services/request/apis';
// import { passiveEventFix } from '~src/workaroundsBugs';

import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

// const history = createBrowserHistory();

// const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(
//   appReducer(history), // root reducer with router state
//   // initialState,
//   composeEnhancers(
//     applyMiddleware(
//       routerMiddleware(history), // for dispatching history actions
//       thunk,
//       // ... other middlewares ...
//     ),
//   ),
// );
initializeApis();
createPreRenderListeners(store);

// passiveEventFix();

const container = document.getElementById('root');
const root = container && createRoot(container);
root?.render(
  <Provider store={store}>
    {/* <App history={history} /> */}
    <App />
  </Provider>,
);

// ReactDOM.render(
//   <Provider store={store}>
//     {/* <App history={history} /> */}
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();

createPostRenderListeners(store);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
