import { AddressState } from '~src/data/store/reducers/person/addresses/reducer';
import { Address } from '~src/services/graphql/workspace/client/graphql';

export function parseAddress(
  workspaceId: string,
  personId: string,
  address: Address,
): AddressState {
  return {
    id: address.id,
    workspaceId,
    personId,
    administrativeAreaLevel1: address.administrativeAreaLevel1,
    administrativeAreaLevel2: address.administrativeAreaLevel2,
    locality: address.locality,
    postalCode: address.postalCode,
    streetAddress: address.streetAddress,
    streetNumber: address.streetNumber,
  };
}
