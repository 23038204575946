import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { selectPivotHoldings } from '~src/data/store/selectors/finance/holdings';
import { selectData } from '~src/data/store/selectors/selectors';
import { ProjectValuationComponent } from '~src/domain/workspace/components/project/holding/valuation/project-valuation.component';
import { PivotOption } from '~src/utils/interfaces/pivot-option';
import { Period } from '~src/utils/period/period';

import { Grid } from '@mui/material';

import { PivotsComponent } from '../common/pivots.component';

interface Props {
  workspaceId: string;
  projectId: string;
  date: moment.Moment;
  period: Period;
  holdings: HoldingState[];
  pivotOptions: PivotOption[];
  currency: CurrencyState;
}

export const ProjectHoldingGroupValuationComponent = React.memo(
  (props: Props) => {
    const [pivots, setPivots] = React.useState(() => [
      'category',
      'sector',
      'regionAllocation',
      'currency',
    ]);
    const holdingGroups = useSelector((state: RootState) =>
      selectPivotHoldings(selectData(state), {
        workspaceId: props.workspaceId,
        projectId: props.projectId,
        date: props.date,
        holdings: props.holdings,
        pivots,
      }),
    );
    // const [portfolios, setPortfolios] = useState<IPivotPortfolios[]>([]);
    // const quoteState = useSelector((state: IStore) => state.quote.quotes);
    // const tradeState = useSelector((state: IStore) => state.trade.trades);

    // useEffect(() => {
    //   setPortfolios(
    //     createPortfolios(pivots, props.holdings, props.holdingFilter)
    //   );
    // }, [pivots, props.holdings, props.holdingFilter]);

    const pivotOptions = [
      ...props.pivotOptions,
      { key: 'assets', text: 'Assets', value: 'id' },
    ];

    // const handleFilterDropdown = (event: any, data: any) => {
    //   console.log(event);
    //   console.log(data);
    //   setPivots(data.value);
    // // };

    // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //   setPivots(event.target.value as string[]);
    // };

    // const dropdownElement = (
    //   <FormControl>
    //     <InputLabel id="portfolio-holding-group-valuation-pivots-label">Pivots</InputLabel>
    //     <Select
    //       labelId="portfolio-holding-group-valuation-pivots-label"
    //       id="portfolio-holding-group-valuation-pivots"
    //       multiple
    //       value={pivots}
    //       onChange={handleChange}
    //       input={<Input />}
    //       // MenuProps={MenuProps}
    //     >
    //       {props.pivotOptions.map(pivot => (
    //         <MenuItem key={pivot.key} value={pivot.value}>
    //           {pivot.text}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   </FormControl>
    // );

    const portfolioElements = holdingGroups.map((pivotPortfolios, index) => {
      const pivotOption = pivotOptions.find((pivot) => {
        return pivot.value === pivotPortfolios.pivot;
      });
      return (
        <Grid item xs={12} sm={6} key={index}>
          <ProjectValuationComponent
            workspaceId={props.workspaceId}
            projectId={props.projectId}
            date={props.date}
            period={props.period}
            title={pivotOption ? pivotOption.text : ''}
            pivot={pivotPortfolios.pivot}
            holdingGroups={pivotPortfolios.holdingGroups}
            currency={props.currency}
          />
        </Grid>
      );
    });

    return (
      <>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item>
            <PivotsComponent
              pivots={pivots}
              setPivots={setPivots}
              pivotOptions={props.pivotOptions}
            />
          </Grid>
        </Grid>
        <Grid container>{portfolioElements}</Grid>
      </>
    );
    // return (
    //   <Grid>
    //     <Grid.Row>
    //       <Grid.Column>
    //         {dropdownElement}
    //       </Grid.Column>
    //     </Grid.Row>
    //     <Grid.Row>
    //       {portfolioElements}
    //     </Grid.Row>
    //   </Grid>
    // );
  },
);
