import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  budgetsSelectors,
  BudgetState,
  BaseBudgetId,
} from '~src/data/store/reducers/workspace/projects/scenario/budgets/reducer';

const selectBudgetState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.budgets;

export const getBudgets = createSelector(
  (state: WorkspaceDataState) => selectBudgetState(state),
  (budgets): BudgetState[] => {
    return budgetsSelectors.selectAllElements(budgets);
  },
);

export const getBudgetById = createCachedSelector(
  (state: WorkspaceDataState) => selectBudgetState(state),
  (_state: WorkspaceDataState, id: BaseBudgetId) => id,
  (budgets, id): BudgetState | undefined => {
    return budgetsSelectors.selectElementByT(budgets, id);
  },
)({
  keySelector: (_state, id) => `${id.id}-${id.scenarioId}`,
  selectorCreator: createSelector,
});

export const getBudgetsByScenarioId = createCachedSelector(
  (state: WorkspaceDataState) => selectBudgetState(state),
  (_state: WorkspaceDataState, scenarioId: string) => scenarioId,
  (budgets, scenarioId): BudgetState[] | undefined => {
    return budgetsSelectors.selectElementsByKey(budgets, scenarioId);
  },
)({
  keySelector: (_state, scenarioId) => scenarioId,
  selectorCreator: createSelector,
});
