import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkspaceInvitations } from '~src/data/store/modules/workspaces/workspace-invitations/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectTenantData,
  selectUserData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getTenants } from '~src/data/store/selectors/tenant/tenants/selectors';
import { selectWorkspaceInvitationsByUser } from '~src/data/store/selectors/workspace/workspace-invitations/selectors';
import { WorkspaceInvitationMessageComponent } from '~src/domain/user/components/messages/workspace-invitation-message.component';
import { AppDispatch } from '~src/store/store';

export const MessageComponent = React.memo(() => {
  const user = useSelector(
    (state: RootState) => selectUserData(state).account.user,
  );
  const tenants = useSelector((state: RootState) =>
    getTenants(selectTenantData(state)),
  );
  const workspaceInvitations = useSelector((state: RootState) =>
    selectWorkspaceInvitationsByUser(selectWorkspaceData(state), user),
  );
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (user) {
      tenants.forEach((tenant) => {
        dispatch(fetchWorkspaceInvitations(tenant.id, [user.id]));
      });
    }
  }, [dispatch, tenants, user]);

  const invitationElements = workspaceInvitations.map((invitation) => {
    return (
      <WorkspaceInvitationMessageComponent
        key={invitation.id}
        invitation={invitation}
      />
    );
  });
  return <div>{invitationElements}</div>;
});
