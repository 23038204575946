import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { FieldsComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/fields.component';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

import { colors, Theme, Typography } from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    // maxWidth: 500,
  },
  row: {
    display: 'flex',
    // alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  subPartElement: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.blue[100],
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface Props<T extends FieldValues>
  extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  holdingId: string;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: TransferFieldConfig;
  getName: (name: Path<TransferInputData>) => Path<T>;
  transferTypes: TransferType[] | undefined;
}

export const TransferFormComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { classes } = useStyles();
  const {
    workspace,
    holdingId,
    projectId,
    control,
    setValue,
    // index,
    fieldConfig,
    getName,
    transferTypes,
  } = props;
  const holding = useSelector((root: RootState) => {
    return getHoldingById(selectWorkspaceData(root), {
      id: holdingId,
      projectId,
    });
  });

  const transferId = useWatch({
    control,
    name: getName('base.id'),
  });
  const transferElement = holding ? (
    <div className={classes.subPartElement}>
      <div className={classes.row}>
        <Typography variant="body1" className={classes.title}>
          Transfer
        </Typography>
        <span className={classes.spacer} />
        <Typography variant="caption" className={classes.title}>
          {transferId}
        </Typography>
      </div>
      <FieldsComponent
        workspace={workspace}
        projectId={props.projectId}
        holding={holding}
        control={control}
        setValue={setValue}
        // index={index}
        fieldConfig={fieldConfig}
        getName={getName}
        transferTypes={transferTypes}
      />
    </div>
  ) : (
    <></>
  );

  return transferElement;
};
