import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHoldingConfigs } from '~src/data/store/modules/holdings/configs/configs/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardElementsByIds } from '~src/data/store/selectors/common/dashboard/element/selectors';
import { getHoldingConfigByHoldingId } from '~src/data/store/selectors/holding/config/configs/selectors';
import { getHoldingDashboardConfigsByHoldingConfigId } from '~src/data/store/selectors/holding/config/dashboard-configs/selectors';
import {
  selectViewData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useConfig } from '~src/hooks/actions/workspace/project/holding/config/use-config.hook';
import { AppDispatch } from '~src/store/store';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
}

export const useHoldingConfig = (props: Props) => {
  const { holding, workspace } = props;
  // const { classes } = useStyles();
  const config = useSelector((state: RootState) =>
    getHoldingConfigByHoldingId(selectWorkspaceData(state), holding.id),
  );
  console.log('config', config);
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    dispatch(
      fetchHoldingConfigs(workspace.tenantId, workspace.id, holding.id),
    ).then(() => {
      setLoaded(true);
    });
  }, [dispatch, holding.id, workspace.id, workspace.tenantId]);

  const { create } = useConfig();
  const holdingDashboardConfigs = useSelector((state: RootState) => {
    if (config) {
      return getHoldingDashboardConfigsByHoldingConfigId(
        selectWorkspaceData(state),
        config.id,
      );
    }
    return [];
  });
  const dashboardConfig = config
    ? holdingDashboardConfigs.find(
        (c) => c.id === config.currentDashboardConfigId,
      )
    : undefined;

  const dashboardElements = useSelector((state: RootState) => {
    if (dashboardConfig) {
      return getDashboardElementsByIds(
        selectViewData(state),
        dashboardConfig.elementIds,
      );
    }
    return [];
  });

  React.useEffect(() => {
    if (loaded && (config == null || holdingDashboardConfigs.length == 0)) {
      create(workspace, holding.id);
    }
  }, [
    config,
    create,
    dashboardConfig,
    holding.id,
    holdingDashboardConfigs.length,
    loaded,
    workspace,
  ]);

  return {
    config,
    dashboardConfig,
    dashboardElements,
  };
};
