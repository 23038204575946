import { colors, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  row: {
    display: 'flex',
    // alignItems: 'center',
  },
  holdingField: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  holdingPaper: {
    backgroundColor: colors.blue[50],
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  subPartElement: {
    marginTop: theme.spacing(2),
    background: 'aliceblue',
    padding: theme.spacing(1),
  },
  holdingTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addHoldingButton: {
    marginTop: theme.spacing(2),
  },
  partElements: {
    flex: 1,
  },
}));
