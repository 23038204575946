import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

import { strcmp } from '@pladdenico/common';
import { DnbAccount } from '~src/services/xlsx/handlers/adapters/dnb/account/account';

export interface DnbAccountId {
  holdingId: string;
}

export type DnbAccountState = DnbAccount & {
  holdingId: string;
};

export const initialDnbAccountsState = [];

const comparator = (a: DnbAccountId, b: DnbAccountId) =>
  strcmp(a.holdingId, b.holdingId);
const merger = (_a: DnbAccountState, b: DnbAccountState) => b;

const adapter = new EntityArrayAdapter<DnbAccountId, DnbAccountState>(
  initialDnbAccountsState,
  comparator,
  merger,
);

export const dnbAccountsSlice = createBaseArraySlice('dnbAccounts', adapter);
export const dnbAccountsSelectors = adapter.selectors;

export const dnbAccountsReducer = dnbAccountsSlice.reducer;
export const dnbAccountsActions = dnbAccountsSlice.actions;
