import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EventState } from '~src/data/store/state/workspace/project/event/event';

import { Box, Grid, Typography } from '@mui/material';
import { getTransactionsByIds } from '~src/data/store/selectors/holding/transaction/transactions/selectors';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  event: EventState;
}

export const ProjectEventTransactionsComponent = React.memo((props: Props) => {
  const { project, event } = props;

  const transactionIds = React.useMemo(() => {
    return event.transactionIds.map((id) => {
      return id;
    });
  }, [event.transactionIds]);
  const transactions = useSelector((state: RootState) =>
    getTransactionsByIds(selectWorkspaceData(state), {
      projectId: project.id,
      ids: transactionIds,
    }),
  );
  const transactionElements = transactions.map((transaction) => {
    return (
      <Box key={transaction.id}>
        <Typography variant="body2">{transaction.title}</Typography>
      </Box>
    );
  });
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body2">Transactions</Typography>
      </Grid>
      <Grid item>{transactionElements}</Grid>
    </Grid>
  );
});
