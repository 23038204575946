import { handleCurrencies } from '~src/data/store/modules/currencies/handler';
import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { QueryContextTypes, QueryUserContext } from '@pladdenico/portfolio-api';

import {
  BaseCurrencyId,
  currenciesActions,
} from '../../reducers/finance/currency/currencies/reducer';

export function fetchCurrencies(ids: string[] | null) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.GetCurrenciesDocument;
    const variables: graphqlUserTypes.GetCurrenciesQueryVariables = {
      ids,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data && data.getCurrencies) {
        return handleCurrencies(dispatch, data.getCurrencies);
      }
      return undefined;
    });
  };
}

export function createCurrencies(
  inputs: graphqlUserTypes.CreateCurrencyInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.CreateCurrenciesDocument;
    const variables: graphqlUserTypes.CreateCurrenciesMutationVariables = {
      inputs,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createCurrencies) {
        return handleCurrencies(dispatch, data.createCurrencies);
      }
      throw new Error('Could not create region');
      // return undefined;
    });
  };
}

export function updateCurrencies(
  inputs: graphqlUserTypes.UpdateCurrencyInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.UpdateCurrenciesDocument;
    const variables: graphqlUserTypes.UpdateCurrenciesMutationVariables = {
      inputs,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateCurrencies) {
        return handleCurrencies(dispatch, data.updateCurrencies);
      }
      return [];
    });
  };
}

export function upsertCurrencies(
  inputs: graphqlUserTypes.UpsertCurrencyInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.UpsertCurrenciesDocument;
    const variables: graphqlUserTypes.UpsertCurrenciesMutationVariables = {
      inputs,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertCurrencies) {
        return handleCurrencies(dispatch, data.upsertCurrencies);
      }
      return [];
    });
  };
}

export function deleteCurrencies(ids: BaseCurrencyId[]) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.DeleteCurrenciesDocument;
    const variables: graphqlUserTypes.DeleteCurrenciesMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteCurrencies) {
        const deletedIds = data.deleteCurrencies;
        dispatch(
          currenciesActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
