import { ProjectType } from '@pladdenico/models';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { SnackbarFailure } from '~src/components/snackbars/snackbar-failure';
import { SnackbarSuccess } from '~src/components/snackbars/snackbar-success';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreatePortfolioProjectFormComponent } from '~src/domain/workspace/components/project/types/portfolio-project/form/create-form.component';
import { useProjectCreate } from '~src/domain/workspace/components/project/form/use-project-create';
import { UpdateState } from '~src/utils/common/update-state';
import { useUpdateState } from '~src/utils/common/use-update-state';

interface Props {
  workspace: WorkspaceState;
  handleClose: () => void;
}

export const ProjectDialogContentComponent = React.memo((props: Props) => {
  const { handleClose, workspace } = props;
  const updateStateData = useUpdateState();

  const onSuccess = React.useCallback(() => {
    updateStateData.setUpdateState(UpdateState.success);
  }, [updateStateData]);

  const onFailure = React.useCallback(
    (_error: any) => {
      updateStateData.setUpdateState(UpdateState.failed);
    },
    [updateStateData],
  );

  const projectForm = useProjectCreate({
    workspace,
    callbacks: {
      onFailure,
      onSuccess,
    },
    inputData: {
      name: '',
      type: ProjectType.Portfolio,
    },
  });

  const projectType = useWatch({
    control: projectForm.control,
    name: 'type',
  });

  const content = React.useMemo(() => {
    let content;
    if (projectType === ProjectType.Portfolio) {
      content = (
        <CreatePortfolioProjectFormComponent
          workspace={workspace}
          projectForm={projectForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          // createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (projectType === ProjectType.Fund) {
      content = (
        <CreatePortfolioProjectFormComponent
          workspace={workspace}
          projectForm={projectForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          // createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    }
    return content;
  }, [handleClose, onFailure, onSuccess, projectForm, projectType, workspace]);

  return (
    <>
      {content}
      <SnackbarFailure
        updateState={updateStateData.updateState}
        setUpdateState={updateStateData.setUpdateState}
        message={'Update failed'}
      />
      <SnackbarSuccess
        updateState={updateStateData.updateState}
        setUpdateState={updateStateData.setUpdateState}
        message={'Updated'}
      />
    </>
  );
});
