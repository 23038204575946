import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { createBondPositions } from '~src/data/store/modules/holdings/bond/position/requests';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { CreateBondPositionFormComponent } from './create-form.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  handleClose: () => void;
  bondPositionCreateInputInit: graphqlWorkspaceTypes.CreateBondPositionInputType;
}

export const CreateBondPositionDialogContentComponent = React.memo(
  (props: Props) => {
    const [bondPositionInput, setBondPositionInput] =
      React.useState<graphqlWorkspaceTypes.CreateBondPositionInputType>({
        ...props.bondPositionCreateInputInit,
      });
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      setBondPositionInput({ ...props.bondPositionCreateInputInit });
    }, [props.bondPositionCreateInputInit]);

    const handleCreatePosition = (): void => {
      dispatch(
        createBondPositions(props.workspace.tenantId, props.workspace.id, [
          bondPositionInput,
        ]),
      )
        .then((_bondPositions) => {
          props.handleClose();
        })
        .catch((err) => {
          console.info('FAILED', bondPositionInput, JSON.stringify(err));
        });
    };

    return (
      <>
        <DialogTitle id="form-dialog-title">Create valuation</DialogTitle>
        <DialogContent>
          <CreateBondPositionFormComponent
            bondPositionInput={bondPositionInput}
            setBondPositionInput={setBondPositionInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreatePosition} color="primary">
            Save
          </Button>
        </DialogActions>
      </>
    );
  },
);
