import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  AssetCategoryState,
  BaseCategoryId,
  categoriesSelectors,
} from '~src/data/store/reducers/asset/categories/reducer';

import { RecordState } from '@pladdenico/common';

import { WorkspaceDataState } from '../../../reducers/reducers';

export const selectCategories = (state: WorkspaceDataState) => {
  return state.holding.asset.category.categories;
};

export const getCategoriesByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState, _workspaceId: string | null) =>
    selectCategories(state),
  (_state: WorkspaceDataState, workspaceId: string | null) => workspaceId,
  (categoriesState, workspaceId) => {
    if (!workspaceId) {
      return [];
    }
    const categories = categoriesSelectors.selectElementsByKey(
      categoriesState,
      workspaceId,
    );
    return categories ? categories : [];
  },
)({
  keySelector: (_state, categoryId) => `${categoryId}`,
  selectorCreator: createSelector,
});

export const selectCategoryById = createCachedSelector(
  (categories: AssetCategoryState[], _categoryId: string) => categories,
  (_categories: AssetCategoryState[], categoryId: string) => categoryId,
  (categories, categoryId) => {
    return categoriesSelectors.selectElementInRecordById(categories, {
      id: categoryId,
    });
  },
)({
  keySelector: (_state, categoryId) => `${categoryId}`,
  selectorCreator: createSelector,
});

export const selectCategoriesByIds = createCachedSelector(
  (
    categories: RecordState<AssetCategoryState, string>[],
    _ids: BaseCategoryId[],
  ) => categories,
  (
    _categories: RecordState<AssetCategoryState, string>[],
    _ids: BaseCategoryId[],
  ) => _ids,
  (categories, ids) => {
    return categoriesSelectors.selectElementsByTs(categories, ids);
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getCategoryById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseCategoryId>) =>
    selectCategories(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseCategoryId>) => baseId,
  (categories, baseId) => {
    if (baseId.id && baseId.workspaceId) {
      return categoriesSelectors.selectElementByT(categories, {
        id: baseId.id,
        workspaceId: baseId.workspaceId,
      });
    }
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.id}`,
  selectorCreator: createSelector,
});
