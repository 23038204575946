import { pick } from 'lodash';
import {
  RunStatus,
  StatusUpdater,
} from '~src/services/xlsx/hooks/use-update-status';

export interface InputHandler {
  import: (data: any[], updateStatus: any) => Promise<boolean>;
}

export interface ExcelImportData {
  data: any[];
}

export type IExcelImportHandler = () => ExcelImportData;

export interface ImportHandler<T> {
  type: string;
  header: Array<keyof T>;
  handler: (t: T[]) => Promise<any>;
  headerMapper?: Map<string, keyof T>;
}

function mapHeader<T>(t: any, headerMapper: Map<string, keyof T>) {
  const res: any = {};
  for (const [key, value] of headerMapper.entries()) {
    res[value] = t[key];
  }
  return res;
}

export function handleInputData<T>(
  data: Map<string, any[]>,
  handler: ImportHandler<T>,
  statusUpdater: StatusUpdater,
) {
  const rawTs = data.get(handler.type);
  if (rawTs) {
    const ts = rawTs.map((rawT) => {
      let t = rawT;
      if (handler.headerMapper) {
        t = mapHeader(t, handler.headerMapper);
      }
      return pick(t, handler.header) as T;
    });
    statusUpdater(`Importing:${handler.type}`, RunStatus.running, undefined);
    return handler
      .handler(ts)
      .then(() => {
        statusUpdater(`Imported:${handler.type}`, RunStatus.success, undefined);
        return ts;
      })
      .catch((err) => {
        statusUpdater(
          `Import failed:${handler.type} Error: ${JSON.stringify(err)}`,
          RunStatus.failed,
          err,
        );
        // return Promise.resolve(false);
        throw err;
      });
  }
  return Promise.resolve([]);
}
