import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingsValuationRegionalComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/regional-valuation/holdings-valuation-regional.component';
import {
  defaultBackgroundColor,
  defaultFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/regional-valuation/default-colors';
import { useRegionalValuationMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/regional-valuation/menu.component';
import { Config } from '~src/utils/interfaces/config';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  config: Config;
  baseCurrency: CurrencyState;
}

export const RegionalValuationElement = React.memo(
  ({
    workspace,
    project,
    holdings,
    config,
    baseCurrency,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useRegionalValuationMenu(
      element,
      remove,
      updated,
    );
    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? defaultFontColor,
          backgroundColor:
            element.config.backgroundColor ?? defaultBackgroundColor,
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <HoldingsValuationRegionalComponent
            workspaceId={workspace.id}
            projectId={project.id}
            holdings={holdings}
            date={config.date}
            currency={baseCurrency}
            active={active}
            format={{ fontColor: element.config.color }}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
