import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseStockId {
  id: string;
  workspaceId: string;
}

export type StockState = Omit<
  graphqlWorkspaceTypes.Stock,
  'asset' | '_type'
> & {
  workspaceId: string;
  assetId: string;
};

export function stockToStockState(stock: StockState) {
  return stock;
}
const initialState: RecordState<StockState, string>[] = [];

const selectKeyId = (stock: BaseStockId) => stock.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseStockId, b: BaseStockId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseStockId, StockState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const stocksSlice = createBaseRecordSlice('stocks', adapter);
export const stocksSelectors = adapter.selectors;

export const stocksReducer = stocksSlice.reducer;
export const stocksActions = stocksSlice.actions;

export const initialStockState = {
  stocks: initialState,
};

export const stockReducer = combineReducers({
  stocks: stocksReducer,
});
