import React from 'react';
// import { DashboardMenuDiv } from '~src/components/dashboard/dashboard-menu-div';
import { PeriodConfigComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/menu/period-config.component';
import { Config } from '~src/utils/interfaces/config';

import { Box } from '@mui/material';

export const useDateFilter = (config: Config, _configOpen: boolean) => {
  const dateFilter = React.useMemo(
    () => (
      <Box sx={{ mt: 1, backgroundColor: 'rgba(255,255,255,0.0)' }}>
        {config && <PeriodConfigComponent config={config} />}
      </Box>
    ),
    [config],
  );
  return { dateFilter };
};
