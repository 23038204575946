import { parseStock } from '~src/data/store/modules/assets/stocks/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  stocksActions,
  StockState,
} from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { Asset, Stock } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { AssetType } from '@pladdenico/models';

export interface StockVisitor {
  visit(stock: StockVisitable): StockState;
  post(): void;
}

export const isStock = (asset: Asset): asset is Stock => {
  return asset._type === AssetType.Stock;
};

export class StockVisitable implements Visitable<StockVisitor> {
  constructor(
    private _workspaceId: string,
    private _stock: Stock,
  ) {}
  public accept(visitor: StockVisitor) {
    return visitor.visit(this);
  }

  public parse(): StockState {
    const assetId = this._stock.asset?.id ?? '';
    return parseStock(this._workspaceId, assetId, this._stock);
  }
}

export class StockStateVisitor implements StockVisitor {
  private _stocks: StockState[];
  constructor(private _dispatch: AppDispatch) {
    this._stocks = [];
  }

  public visit(stock: StockVisitable): StockState {
    const stockParsed = stock.parse();
    this._stocks.push(stockParsed);
    return stockParsed;
  }

  post() {
    this._dispatch(
      stocksActions.upsertManyElements(this._stocks, {
        shouldAutobatch: true,
      }),
    );
  }
}
