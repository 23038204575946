import { AppDispatch } from '~src/store/store';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import {
  EntityMediumStateVisitor,
  EntityMediumVisitable,
} from '~src/data/store/visitors/workspace/entity/entity-medium-visitor';

export function handleEntityMedias(
  dispatch: AppDispatch,
  outputs: graphqlWorkspaceTypes.EntityMedia[],
) {
  const entityMediumVisitor = new EntityMediumStateVisitor(dispatch);
  outputs.forEach((medium) => {
    const visitable = new EntityMediumVisitable(
      medium.entity?.id ?? '',
      medium,
    );
    visitable.accept(entityMediumVisitor);
  });
  entityMediumVisitor.post();
}
