import { max, min } from 'lodash';
import React from 'react';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  covariancesFromResearch,
  PortfolioAsset,
  portfolioAssetsFromResearch,
} from '~src/domain/workspace/components/finance/tools/pareto/covariances';
import { ParetoFrontierChartComponent } from '~src/domain/workspace/components/finance/tools/pareto/pareto-frontier-chart.component';
import { useCovarianceInput } from '~src/domain/workspace/components/finance/tools/pareto/use-covariance-input';
import { useCovarianceMatrix } from '~src/domain/workspace/components/finance/tools/pareto/use-covariance-matrix';
import { useInputVectors } from '~src/domain/workspace/components/finance/tools/pareto/use-input-vectors';
import { useVectorsInput } from '~src/domain/workspace/components/finance/tools/pareto/use-vectors-input';
import { useHoldingsWeights } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/efficient-frontier/use-holdings-weights';
import { apis } from '~src/services/request/apis';
import { ParetoOptimalOutput } from '~src/services/request/portfolio-optimization-api';
import { Period } from '~src/utils/period/period';

import { Box, Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

// import { strcmp, unionSorted } from '@pladdenico/common';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  holdings: HoldingState[];
  period: Period;
  valuationDate: moment.Moment;
  baseCurrency: CurrencyState;
  format: {
    color: string;
    backgroundColor: string;
  };
}
// function sortArrayBasedOnAnother<T1, T2>(
//   arrayToSort: T1[],
//   getT1Id: (t1: T1) => string,
//   sortOrderArray: T2[],
//   getT2Id: (t2: T2) => string,
// ): T1[] {
//   // Create a lookup table where the key is the value from sortOrderArray, and the value is its index
//   const sortOrderLookup: { [key: string]: number } = {};
//   sortOrderArray.forEach((item, index) => {
//     sortOrderLookup[getT2Id(item)] = index;
//   });

//   // Clone arrayToSort to avoid mutating the original array
//   const sortedArray = [...arrayToSort];

//   sortedArray.sort((a, b) => {
//     const indexA = sortOrderLookup[getT1Id(a)];
//     const indexB = sortOrderLookup[getT1Id(b)];
//     return indexA - indexB;
//   });

//   return sortedArray;
// }

export const EfficientFrontierComponent = React.memo((props: Props) => {
  const {
    baseCurrency,
    format,
    holdings,
    portfolioProject,
    valuationDate,
    workspace,
  } = props;

  const [portfolioAssets] = React.useState<PortfolioAsset[]>(
    portfolioAssetsFromResearch,
  );

  const { hessian, handleCovarianceChange } = useCovarianceMatrix({
    covariances: covariancesFromResearch,
    portfolioAssets,
  });

  const {
    handleInputVectorsChange,
    expectedReturns,
    lowerBounds,
    upperBounds,
  } = useInputVectors({
    portfolioAssets,
  });

  const categoryWeights = useHoldingsWeights({
    baseCurrency,
    format,
    holdings,
    portfolioProject,
    showFilteredOut: true,
    useMarketValue: true,
    valuationDate,
    workspace,
  });

  const weights = React.useMemo(() => {
    const lookup = new Map<string, number>();
    const weights = portfolioAssets.map((a) => {
      return {
        id: a.id,
        weight: 0,
      };
    });
    for (let i = 0; i < weights.length; ++i) {
      lookup.set(weights[i].id, i);
    }
    for (let i = 0; i < categoryWeights.length; ++i) {
      const w = categoryWeights[i];
      if (w.id === 'Bond Fund' || w.id === 'Bond') {
        const idx = lookup.get('BondsID');
        if (idx !== undefined) {
          weights[idx].weight += w.weight;
        }
      } else if (
        w.id === 'Equity' ||
        w.id === 'Equity Fund' ||
        w.id === 'Crypto'
      ) {
        const idx = lookup.get('EquitiesID');
        if (idx !== undefined) {
          weights[idx].weight += w.weight;
        }
      } else if (w.id === 'Cash') {
        const idx = lookup.get('CashID');
        if (idx !== undefined) {
          weights[idx].weight += w.weight;
        }
      } else if (w.id === 'RealEstate') {
        const idx = lookup.get('RealEstateID');
        if (idx !== undefined) {
          weights[idx].weight += w.weight;
        }
      } else if (w.id === 'Private Equity' || w.id === 'Venture Capital') {
        const idx = lookup.get('PEID');
        if (idx !== undefined) {
          weights[idx].weight += w.weight;
        }
      } else if (w.id === 'Loan') {
        const idx = lookup.get('EquitiesID');
        if (idx !== undefined) {
          weights[idx].weight += w.weight;
        }
      }
    }
    return weights;
  }, [portfolioAssets, categoryWeights]);

  const expectedHoldingsReturn = React.useMemo(() => {
    let ret = 0;
    for (let i = 0; i < weights.length; ++i) {
      ret += weights[i].weight * expectedReturns[i];
    }
    return ret;
  }, [expectedReturns, weights]);

  const volatilityHoldingsReturn = React.useMemo(() => {
    let variance = 0;
    const n = weights.length;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        // Multiply the weight of asset i by the weight of asset j,
        // and then by the covariance between assets i and j
        variance += weights[i].weight * weights[j].weight * hessian[i + j * n];
      }
    }
    return Math.sqrt(variance);
  }, [hessian, weights]);

  const [targetReturnsInterval] = React.useState<[number, number]>([
    min(expectedReturns) ?? 0,
    max(expectedReturns) ?? Infinity,
  ]);

  const vectorsInputElement = useVectorsInput({
    expectedReturns,
    handleInputVectorsChange,
    lowerBounds,
    portfolioAssets,
    upperBounds,
  });

  const covarianceInputElement = useCovarianceInput({
    handleCovarianceChange,
    hessian,
    portfolioAssets,
  });

  const numberOfSteps = 50;
  const [results, setResults] = React.useState<ParetoOptimalOutput[]>(
    Array.from({ length: numberOfSteps }, () => {
      return {
        weights: Array.from({ length: portfolioAssets.length }),
        expectedReturn: 0,
        volatility: 0,
      };
    }),
  );

  const calculateParetoOptimalFrontier = React.useCallback(() => {
    apis.portfolioOptimization.executeParetoEfficientFrontierQuery(
      {
        target_return_minimum: targetReturnsInterval[0],
        target_return_maximum: targetReturnsInterval[1],
        expected_returns: expectedReturns,
        H: hessian,
        num_steps: numberOfSteps,
        lower_bounds: lowerBounds,
        upper_bounds: upperBounds,
      },
      (result) => {
        const results: ParetoOptimalOutput[] = result.weights.map((item) => {
          return {
            expectedReturn: item.expected_return,
            volatility: item.volatility,
            weights: item.weights,
          };
        });
        setResults(results);
      },
    );
  }, [
    expectedReturns,
    hessian,
    lowerBounds,
    targetReturnsInterval,
    upperBounds,
  ]);

  const paretoFrontierButton = (
    <Button
      onClick={() => calculateParetoOptimalFrontier()}
      variant="contained"
    >
      Calculate pareto frontier
    </Button>
  );

  return (
    <div>
      <Typography m={2} variant="h6">
        Pareto - Efficient frontier
      </Typography>
      <Box>
        <Grid2 container>
          {vectorsInputElement}
          {covarianceInputElement}
        </Grid2>
        {/* Data:
        {matrixDa127.0.0.1ta ? (
          <pre>{JSON.stringify(matrixData, null, 2)}</pre>
        ) : undefined} */}
      </Box>

      <Grid2 container>
        <Grid2 xs={12} margin={2}>
          {paretoFrontierButton}
          <ParetoFrontierChartComponent
            assets={portfolioAssets}
            data={results}
            points={[
              {
                expectedReturn: expectedHoldingsReturn,
                volatility: volatilityHoldingsReturn,
              },
            ]}
          />
          <Box>
            <Typography variant="body2">
              Expected Return: {expectedHoldingsReturn}
            </Typography>
            <Typography variant="body2">
              Variance: {volatilityHoldingsReturn}
            </Typography>
            Results:{' '}
            {results ? (
              <pre>{JSON.stringify(results, null, 2)}</pre>
            ) : undefined}
          </Box>
        </Grid2>
      </Grid2>
    </div>
  );
});
