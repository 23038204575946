import { Visitable } from '~src/data/store/visitors/visitable';
import { parseEvent } from '~src/data/store/modules/workspaces/projects/events/parser';
import { eventsActions } from '~src/data/store/reducers/workspace/projects/events/event/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { Event } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

interface EventVisitor {
  visit(event: EventVisitable): void;
  post(): void;
}

export class EventVisitable implements Visitable<EventVisitor> {
  constructor(
    private _workspaceId: string,
    private _event: Event,
  ) {}
  public accept(visitor: EventVisitor) {
    visitor.visit(this);
  }

  public parse(): EventState {
    return parseEvent(this._event);
  }
}

export class EventStateVisitor implements EventVisitor {
  private _events: EventState[];
  constructor(private _dispatch: AppDispatch) {
    this._events = [];
  }
  public visit(event: EventVisitable): void {
    this._events.push(event.parse());
  }
  post() {
    this._dispatch(
      eventsActions.upsertManyElements(this._events, {
        shouldAutobatch: true,
      }),
    );
  }
}
