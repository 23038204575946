import { handlePeople } from '~src/data/store/modules/people/people/handlers';
import { peopleActions } from '~src/data/store/reducers/person/people/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchPeople(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  const node = graphqlWorkspaceTypes.GetPeopleDocument;
  const variables: graphqlWorkspaceTypes.GetPeopleQueryVariables = {
    ids,
  };
  const context: QueryWorkspaceContext = {
    type: QueryContextTypes.workspace,
    tenantId,
    workspaceId,
  };
  return investRequest(node, variables, context).then((data) => {
    if (data.getPeople) {
      return data.getPeople;
    }
    return undefined;
  });
}

export function createPeople(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreatePersonInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreatePeopleDocument;
    const variables: graphqlWorkspaceTypes.CreatePeopleMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPeople) {
        return handlePeople(dispatch, tenantId, workspaceId, data.createPeople);
      }
      throw new Error('Could not create person');
      // return undefined;
    });
  };
}

export function updatePeople(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdatePersonInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdatePeopleDocument;
    const variables: graphqlWorkspaceTypes.UpdatePeopleMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePeople) {
        return handlePeople(dispatch, tenantId, workspaceId, data.updatePeople);
      }
      return [];
    });
  };
}

export function upsertPeople(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertPersonInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertPeopleDocument;
    const variables: graphqlWorkspaceTypes.UpsertPeopleMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPeople) {
        return handlePeople(dispatch, tenantId, workspaceId, data.upsertPeople);
      }
      return [];
    });
  };
}

export function deletePeople(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeletePeopleDocument;
    const variables: graphqlWorkspaceTypes.DeletePeopleMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePeople) {
        const deletedIds = data.deletePeople;
        dispatch(
          peopleActions.removeManyElements(
            ids
              .filter((id) => deletedIds.some((p) => p === id))
              .map((id) => {
                return {
                  id,
                  workspaceId,
                };
              }),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
