import { AssetCategoryState } from '~src/data/store/reducers/asset/categories/reducer';
import { AssetCategory } from '~src/services/graphql/workspace/client/graphql';

export function parseAssetCategory(
  workspaceId: string,
  category: AssetCategory,
): AssetCategoryState {
  return {
    id: category.id,
    workspaceId,
    name: category.name,
  };
}
