import { parsePerson } from '~src/data/store/modules/people/people/parser';
import {
  AddressStateVisitor,
  AddressVisitable,
  AddressVisitor,
} from '~src/data/store/visitors/person/address-visitor';
import {
  EmailStateVisitor,
  EmailVisitable,
  EmailVisitor,
} from '~src/data/store/visitors/person/email-visitor';
import {
  PhoneStateVisitor,
  PhoneVisitable,
  PhoneVisitor,
} from '~src/data/store/visitors/person/phone-visitor';
import {
  RoleStateVisitor,
  RoleVisitable,
  RoleVisitor,
} from '~src/data/store/visitors/person/role-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  peopleActions,
  PersonState,
} from '~src/data/store/reducers/person/people/reducer';
import { Person } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface PersonVisitor {
  visit(person: PersonVisitable): PersonState;
  post(): void;
  emailVisitor: EmailVisitor;
  addressVisitor: AddressVisitor;
  phoneVisitor: PhoneVisitor;
  roleVisitor: RoleVisitor;
}

export class PersonVisitable implements Visitable<PersonVisitor> {
  constructor(
    private _tenantId: string,
    private _workspaceId: string,
    private _person: Person,
  ) {}
  public accept(visitor: PersonVisitor) {
    if (this._person.addresses) {
      this._person.addresses.forEach((address) => {
        const addressVisitable = new AddressVisitable(
          this._workspaceId,
          this._person.id,
          address,
        );
        addressVisitable.accept(visitor.addressVisitor);
      });
    }
    if (this._person.emails) {
      this._person.emails.forEach((email) => {
        const emailVisitable = new EmailVisitable(
          this._workspaceId,
          this._person.id,
          email,
        );
        emailVisitable.accept(visitor.emailVisitor);
      });
    }
    if (this._person.phones) {
      this._person.phones.forEach((phone) => {
        const phoneVisitable = new PhoneVisitable(
          this._workspaceId,
          this._person.id,
          phone,
        );
        phoneVisitable.accept(visitor.phoneVisitor);
      });
    }
    if (this._person.roles) {
      this._person.roles.forEach((role) => {
        const roleVisitable = new RoleVisitable(
          this._workspaceId,
          this._person.id,
          role,
        );
        roleVisitable.accept(visitor.roleVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): PersonState {
    return parsePerson(this._tenantId, this._workspaceId, this._person);
  }
}

export class PersonStateVisitor implements PersonVisitor {
  private _people: PersonState[];
  public emailVisitor: EmailVisitor;
  public addressVisitor: AddressVisitor;
  public phoneVisitor: PhoneVisitor;
  public roleVisitor: RoleVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._people = [];
    this.emailVisitor = new EmailStateVisitor(this._dispatch);
    this.addressVisitor = new AddressStateVisitor(this._dispatch);
    this.phoneVisitor = new PhoneStateVisitor(this._dispatch);
    this.roleVisitor = new RoleStateVisitor(this._dispatch);
  }
  public visit(person: PersonVisitable): PersonState {
    const personState = person.parse();
    this._people.push(personState);
    return personState;
  }

  post() {
    this._dispatch(
      peopleActions.upsertManyElements(this._people, {
        shouldAutobatch: true,
      }),
    );
    this.addressVisitor.post();
    this.emailVisitor.post();
    this.phoneVisitor.post();
    this.roleVisitor.post();
  }
}
