import { Box, Typography } from '@mui/material';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  WaterfallColumn,
  WaterfallComponent,
} from '~src/components/charts/waterfall/waterfall.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState, RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingGroups } from '~src/data/store/selectors/finance/holding-groups';
import { getHoldingsValue } from '~src/data/store/selectors/finance/valuation/holdings-value';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { isIn, Filter } from '~src/utils/common/filter';
import { Period } from '~src/utils/period/period';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  filterKey: string;
  period: Period;
  date: moment.Moment;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
}

interface DataProps {
  workspaceId: string;
  projectId: string;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
  filter: Filter;
  showFilteredOut: boolean;
  names: string[];
  filterKey: string;
  date: moment.Moment;
  period: Period;
  currency: CurrencyState;
  minValuationPercent: number;
}

const getColumns = createCachedSelector(
  (state: DataState) => state,
  (_state: DataState, props: DataProps) => props.workspaceId,
  (_state: DataState, props: DataProps) => props.projectId,
  (_state: DataState, props: DataProps) => props.holdingGroups,
  (_state: DataState, props: DataProps) => props.filter,
  (_state: DataState, props: DataProps) => props.showFilteredOut,
  (_state: DataState, props: DataProps) => props.names,
  (_state: DataState, props: DataProps) => props.filterKey,
  (_state: DataState, props: DataProps) => props.period,
  (_state: DataState, props: DataProps) => props.date,
  (_state: DataState, props: DataProps) => props.currency,
  (_state: DataState, props: DataProps) => props.minValuationPercent,
  (
    state,
    workspaceId,
    projectId,
    holdingGroups,
    filter,
    showFilteredOut,
    names,
    filterKey,
    period,
    date,
    baseCurrency,
  ) => {
    return createColumns(
      state,
      workspaceId,
      projectId,
      holdingGroups,
      filter,
      showFilteredOut,
      filterKey,
      period,
      date,
      baseCurrency,
    );
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}-${props.projectId}-${props.filter}-${props.names}-${props.showFilteredOut}-${props.filterKey}`,
  selectorCreator: createSelector,
});

function createColumns(
  state: DataState,
  workspaceId: string,
  projectId: string,
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>,
  filter: Filter,
  showFilteredOut: boolean,
  filterKey: string,
  period: Period,
  _date: moment.Moment,
  baseCurrency: CurrencyState,
): WaterfallColumn[] {
  const data: WaterfallColumn[] = [];
  let totalValue = 0;
  holdingGroups.forEach((group, filterValue) => {
    const filteredOut = !isIn(filter, {
      key: filterKey,
      value: filterValue,
    });

    if (!filteredOut || showFilteredOut) {
      const startValue = getHoldingsValue(state, {
        workspaceId,
        projectId,
        // currency: baseCurrency,
        currency: baseCurrency,
        date: period.start,
        filter,
        holdings: group.holdings,
        useLiveValue: true, // XXX: Should come from config.
      });
      const endValue = getHoldingsValue(state, {
        workspaceId,
        projectId,
        // currency: baseCurrency,
        currency: baseCurrency,
        date: period.end,
        filter,
        holdings: group.holdings,
        useLiveValue: true, // XXX: Should come from config.
      });
      // const value = getHoldingGroupValue(state, {
      //   workspaceId,
      //   projectId,
      //   currency: baseCurrency,
      //   date,
      //   filter,
      //   holdings: group.holdings,
      //   useLiveValue: true, // XXX: Should come from config.
      //   filterValue,
      //   filterKey,
      // });
      const value = endValue - startValue;
      totalValue += value;
      data.push({
        key: filterKey,
        name: group.name,
        id: filterValue,
        value: endValue - startValue, // : holdings.value(date.toDate(), exchangeRateState, baseCurrency),
        filteredOut,
      });
    }
  });
  data.push({
    key: filterKey,
    name: 'total',
    id: '__total__',
    value: totalValue,
    filteredOut: false,
    absolute: true,
  });
  return data;
}

export function HoldingsReturnWaterfallComponent(props: Props) {
  const [showFilteredOut] = React.useState(true);
  const [minValuationPercent] = React.useState(5);
  const [names, setNames] = React.useState<string[]>([]);
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const holdingGroupsProps = React.useMemo(() => {
    return {
      workspaceId: props.workspace.id,
      projectId: props.projectId,
      date: props.date,
      filterKey: props.filterKey,
      holdings: props.holdings,
    };
  }, [
    props.date,
    props.filterKey,
    props.holdings,
    props.projectId,
    props.workspace.id,
  ]);

  const holdingGroups = useSelector((state: RootState) => {
    return getHoldingGroups(selectData(state), holdingGroupsProps);
  });

  React.useEffect(() => {
    const keys = Array.from(holdingGroups.keys());
    setNames(keys);
  }, [holdingGroups, props.filterKey]);

  const dataProps = React.useMemo(() => {
    return {
      workspaceId: props.workspace.id,
      projectId: props.projectId,
      holdingGroups,
      filter,
      showFilteredOut,
      names,
      period: props.period,
      filterKey: props.filterKey,
      date: props.date,
      currency: props.baseCurrency,
      minValuationPercent,
    };
  }, [
    filter,
    holdingGroups,
    minValuationPercent,
    names,
    props.baseCurrency,
    props.date,
    props.filterKey,
    props.period,
    props.projectId,
    props.workspace.id,
    showFilteredOut,
  ]);

  const columns = useSelector((state: RootState) => {
    return getColumns(selectData(state), dataProps);
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h5" align="center">
        Diff - {props.filterKey}
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        pb={1}
        pt={1}
      >
        <WaterfallComponent columns={columns} />
      </Box>
    </Box>
  );
}
