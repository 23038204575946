import React from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  // useWatch,
} from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  FormContext,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { HoldingSubFormComponent } from '~src/domain/workspace/components/project/transaction/form/holding/holding-sub-form.component';
import { useHoldingConfigs } from '~src/domain/workspace/components/project/transaction/form/holding/use-holding-configs';
import { useTransactionHoldings } from '~src/domain/workspace/components/project/transaction/form/use-transaction-holdings';
import { TransactionType } from '~src/utils/finance/transaction-type';

import AddIcon from '@mui/icons-material/Add';
import { Grid, IconButton } from '@mui/material';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  date: Date;
  control: Control<FormValues, FormContext>;
  getValues: UseFormGetValues<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  watch: UseFormWatch<FormValues>;
  transactionType: TransactionType;
}

export const TransactionComponent = (props: Props) => {
  const {
    control,
    getValues,
    setValue,
    watch,
    workspace,
    projectId,
    date,
    transactionType,
  } = props;

  const configs = useHoldingConfigs(transactionType);

  const { onAddHolding, onDeleteHolding, fields } = useTransactionHoldings(
    control,
    getValues,
    setValue,
    watch,
    configs.holdings,
    configs.listeners,
    date,
  );

  // const fields = useWatch({
  //   name: 'holdings',
  //   control,
  //   // defaultValue: [],
  // });

  const addButtonElement = React.useMemo(() => {
    return transactionType === TransactionType.Custom ? (
      <Grid item sm={12} key="addHoldingButton">
        <IconButton onClick={onAddHolding}>
          <AddIcon />
        </IconButton>
      </Grid>
    ) : undefined;
  }, [onAddHolding, transactionType]);

  const holdingElements = React.useMemo(() => {
    return (
      fields
        // ?.filter((field) => field.fieldId != null)
        ?.map((field, index) => {
          return (
            <Grid item sm={6} key={field.id}>
              <HoldingSubFormComponent
                workspace={workspace}
                projectId={projectId}
                deleteHolding={onDeleteHolding}
                control={control}
                setValue={setValue}
                index={index}
                date={date}
              />
            </Grid>
          );
        })
    );
  }, [control, date, fields, onDeleteHolding, projectId, setValue, workspace]);

  return (
    <>
      {holdingElements} {addButtonElement}
    </>
  );
};
