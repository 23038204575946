import { Currency } from '~src/services/graphql/user/client/graphql';
import { CurrencyState } from '../../reducers/finance/currency/currencies/reducer';

export function parseCurrency(region: Currency): CurrencyState {
  return {
    id: region.id,
    country: region.country ?? '',
    symbol: region.symbol ?? '',
  };
}

export function parseCurrencies(currencies: Currency[] | null | undefined) {
  return currencies
    ? currencies.map((region) => {
        return parseCurrency(region);
      })
    : [];
}
