import { handleBonds } from '~src/data/store/modules/assets/bonds/handler';
import { bondsActions } from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchBonds(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetBondsDocument;
    const variables: graphqlWorkspaceTypes.GetBondsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getBonds) {
        return handleBonds(workspaceId, dispatch, data.getBonds);
      }
      return [];
    });
  };
}

export function createBonds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateBondInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateBondsDocument;
    const variables: graphqlWorkspaceTypes.CreateBondsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createBonds) {
        return handleBonds(workspaceId, dispatch, data.createBonds);
      }
      return [];
    });
  };
}

export function updateBonds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateBondInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateBondsDocument;
    const variables: graphqlWorkspaceTypes.UpdateBondsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateBonds) {
        return handleBonds(workspaceId, dispatch, data.updateBonds);
      }
      return [];
    });
  };
}

export function upsertBonds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertBondInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertBondsDocument;
    const variables: graphqlWorkspaceTypes.UpsertBondsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertBonds) {
        return handleBonds(workspaceId, dispatch, data.upsertBonds);
      }
      return [];
    });
  };
}

export function deleteBonds(
  tenantId: string,
  workspaceId: string,
  bonds: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteBondsDocument;
    bonds.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteBondInputType[] = bonds.map(
      (bond) => {
        return {
          id: bond.id,
        };
      },
    );
    const variables: graphqlWorkspaceTypes.DeleteBondsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteBonds) {
        const deletedBonds = intersectionSorted(
          bonds,
          data.deleteBonds,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(bondsActions.removeManyElements(deletedBonds));
      }
      return [];
    });
  };
}
