// import { Crypto } from '@pladdenico/models';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  cryptosSelectors,
  CryptoState,
  BaseCryptoId,
} from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getCryptos = (state: WorkspaceDataState) => {
  return state.holding.asset.crypto.cryptos;
};

export const getCryptosByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getCryptos(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (cryptosState, workspaceId) => {
    const cryptos = cryptosSelectors.selectElementsByKey(
      cryptosState,
      workspaceId,
    );
    return cryptos ? cryptos : [];
  },
)({
  keySelector: (_state, cryptoId) => cryptoId,
  selectorCreator: createSelector,
});

// export const cryptoByIdSelector = (cryptos: Crypto[], cryptoId: string) => {
//   return __OLDsortedFind(cryptos, { id: cryptoId } as Crypto, (a) => a.id);
// };

export const cryptoByAccountSelector = (
  cryptos: CryptoState[],
  accountNumber: string,
) => {
  if (!accountNumber) {
    return undefined;
  }
  return cryptos.find((crypto) => {
    if (crypto.accountNumber) {
      return crypto.accountNumber === accountNumber;
    }
    return false;
  });
};

export const getCryptoById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseCryptoId>) =>
    getCryptos(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseCryptoId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseCryptoId>) => baseId.id,
  (cryptos, workspaceId, cryptoId) => {
    if (!workspaceId || !cryptoId) {
      return undefined;
    }
    return cryptosSelectors.selectElementByT(cryptos, {
      workspaceId,
      id: cryptoId,
    });
  },
)({
  keySelector: (_state, workspaceId, cryptoId) => `${workspaceId}-${cryptoId}`,
  selectorCreator: createSelector,
});

interface CryptoAssetId {
  workspaceId: string;
  assetId: string;
}

export const getCryptoByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<CryptoAssetId>) =>
    getCryptos(state),
  (_state: WorkspaceDataState, baseId: Partial<CryptoAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<CryptoAssetId>) =>
    baseId.assetId,
  (cryptos, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceCryptos = cryptosSelectors.selectElementsByKey(
      cryptos,
      workspaceId,
    );
    return workspaceCryptos?.find((crypto) => crypto.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
