import moment from 'moment';
import React from 'react';
import { IdComponent } from '~src/components/utils/id.component';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useCreateHoldingForm } from '~src/domain/workspace/components/project/portfolio/holdings/use-create-holding-form';
import { HoldingDataInput } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';
import { usePortfolioHoldings } from '~src/hooks/actions/workspace/project/portfolio/holding/use-portfolio-holdings';
import { useHandler } from '~src/hooks/utils/use-handler';

import { DatePicker } from '@mui/x-date-pickers';
import { Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

const initialPortfolios: PortfolioState[] = [];

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  projectId: string;
  investmentInput: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType;
  setInvestmentInput: (
    input: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType,
  ) => void;
  // holding: Holding | undefined;

  holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType;
  setHoldingInput: (
    input: graphqlWorkspaceTypes.CreateHoldingInputType,
  ) => void;

  holdingDataInput: HoldingDataInput;
  setHoldingDataInput: (input: HoldingDataInput) => void;

  showId: boolean;
  showConfirmed: boolean;
  showHolding: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const CreateInvestmentFieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const { handleUpdate, handleEvent } = useHandler(
    props.investmentInput,
    props.setInvestmentInput,
  );

  const handleExitDateChange = (date: moment.Moment | null) => {
    if (date) {
      props.setInvestmentInput({
        ...props.investmentInput,
        exit: date.toDate(),
      });
    }
  };

  const idElement = props.showId ? (
    <IdComponent id={props.investmentInput.id} setId={handleUpdate('id')} />
  ) : undefined;
  const { portfolios, setPortfolios } = usePortfolioHoldings(
    props.workspace,
    initialPortfolios,
  );
  const { content } = useCreateHoldingForm(
    props.workspace,
    props.projectId,
    props.holdingInput,
    props.setHoldingInput,
    props.holdingDataInput,
    props.setHoldingDataInput,
    portfolios,
    setPortfolios,
  );

  return (
    <>
      <Grid container spacing={1}>
        {idElement}
        {/* {holdingElement} */}
        {content}
        <Grid item sm={4}>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label="Exit"
            value={
              props.investmentInput.exit
                ? moment(props.investmentInput.exit)
                : undefined
            }
            onChange={handleExitDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="name"
            value={props.investmentInput.name}
            label="Name"
            type="text"
            onChange={handleEvent('name')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="description"
            value={props.investmentInput.description}
            label="Description"
            type="text"
            onChange={handleEvent('description')}
          />
        </Grid>
      </Grid>
    </>
  );
});
