import * as React from 'react';
import { useSelector } from 'react-redux';
import { DrawerHeader } from '~src/components/dashboard/drawer-header';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { PortfolioConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { PortfolioBaseConfigComponent } from '~src/domain/workspace/components/project/portfolio/config/dashboard/portfolio-base-config.component';
import { PortfolioDashboardConfigComponent } from '~src/domain/workspace/components/project/portfolio/config/dashboard/portfolio-dashboard-config.component';
import { Config } from '~src/utils/interfaces/config';

import { Box } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  portfolioConfig: PortfolioConfigState | undefined;
  dashboardConfig: PortfolioDashboardConfigState | undefined;
}

export const PortfolioConfigComponent = React.memo((props: Props) => {
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );

  if (config) {
    return <PortfolioConfigComponentWithConfig {...props} config={config} />;
  }
  return <div>No valid config</div>;
});

interface PropsWithConfig extends Props {
  config: Config;
}

export const PortfolioConfigComponentWithConfig = React.memo(
  (props: PropsWithConfig) => {
    const {
      workspace,
      portfolioProject,
      portfolio,
      portfolioConfig,
      dashboardConfig,
      config,
    } = props;

    return (
      <>
        <DrawerHeader />
        <Box ml={2} mr={2} mt={2}>
          <PortfolioBaseConfigComponent
            workspace={workspace}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            config={config}
          />
          {dashboardConfig && (
            <PortfolioDashboardConfigComponent
              workspace={workspace}
              portfolioProject={portfolioProject}
              portfolio={portfolio}
              dashboardConfig={dashboardConfig}
              portfolioConfig={portfolioConfig}
            />
          )}
        </Box>
      </>
    );
  },
);
