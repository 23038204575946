import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ScenarioInput } from '~src/domain/workspace/components/project/scenario/form/scenario-input';
import { useScenarioFieldsComponent } from '~src/domain/workspace/components/project/scenario/form/use-fields.component';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  scenarioInput: ScenarioInput;
  setScenarioInput: (scenarioInput: ScenarioInput) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateScenarioFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { nameElement } = useScenarioFieldsComponent({
    scenarioInput: props.scenarioInput,
    setScenarioInput: props.setScenarioInput,
  });

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {nameElement}
    </form>
  );
});
