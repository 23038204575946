import React from 'react';
import { useSelector } from 'react-redux';
import { useEditDialog } from '~src/components/fields/workspace/asset/edit/use-edit-dialog';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getAssetsByIds } from '~src/data/store/selectors/asset/assets/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { AssetType } from '@pladdenico/models';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';

interface WithAssetId {
  assetId: string;
}

interface Props<T extends WithAssetId> {
  handleValue: (t: T) => void;
  handlePersist?: () => void;
  value: T | undefined;
  label?: string;
  assetType: AssetType;
  workspace: WorkspaceState;
  options: T[];

  getDialogContent: (
    assetInput: AssetCreateInputData,
    callback: (t: T) => void,
    setOpen: (open: boolean) => void,
  ) => JSX.Element;
}

export const EditAsset = <T extends WithAssetId>(props: Props<T>) => {
  const { handleValue, getDialogContent, assetType } = props;
  const assetIds = React.useMemo(
    () =>
      props.options.map((t) => {
        return { workspaceId: props.workspace.id, id: t.assetId };
      }),
    [props.options, props.workspace.id],
  );
  const assets = useSelector((state: RootState) => {
    return getAssetsByIds(selectWorkspaceData(state), assetIds);
  });

  const getOptionLabel = React.useCallback(
    (t: T) => {
      const asset = assets.find((asset) => asset.id === t.assetId);
      return asset?.name ? asset.name : '';
    },
    [assets],
  );

  const { dialogElement, setOpen, initDialog } = useEditDialog({
    handleValue,
    workspace: props.workspace,
    assetType,
    getDialogContent,
  });
  return (
    <EditAutocomplete
      multiple={false}
      addOption={{ initDialog, setOpen, dialogElement }}
      // dialogElement={dialogElement}
      // initDialog={initDialog}
      // setOpen={setOpen}
      getOptionLabel={getOptionLabel}
      label={props.label}
      handleValue={handleValue}
      options={props.options}
      value={props.value}
      handlePersist={props.handlePersist}
    />
  );
};
