import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

export interface BaseCommitmentId {
  holdingId: string;
  id: string;
}

export type CommitmentState = Omit<
  graphqlWorkspaceTypes.Commitment,
  'holding' | 'currency' | 'transfers'
> & {
  holdingId: string;
  currencyId: string;
  transferIds: string[];
  commitmentDate: Date;
  startDate: Date;
  endDate: Date;
  type: CommitmentType;
};

export const initialCommitmentsState = [];

const selectKeyId = (commitment: BaseCommitmentId) => commitment.holdingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseCommitmentId, b: BaseCommitmentId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseCommitmentId,
  CommitmentState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const commitmentsSlice = createBaseRecordSlice('commitments', adapter);
export const commitmentsSelectors = adapter.selectors;

export const commitmentsReducer = commitmentsSlice.reducer;
export const commitmentsActions = commitmentsSlice.actions;
