import { Visitable } from '~src/data/store/visitors/visitable';
import {
  isPortfolioProject,
  PortfolioProjectStateVisitor,
  PortfolioProjectVisitable,
  PortfolioProjectVisitor,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-project-visitor';
import {
  isPrivateEquityFundProject,
  PrivateEquityFundProjectStateVisitor,
  PrivateEquityFundProjectVisitable,
  PrivateEquityFundProjectVisitor,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-project-visitor';
// import {
//   // ProjectObjStateVisitor,
//   // ProjectObjVisitable,
//   ProjectObjVisitor,
// } from '~src/data/store/visitors/workspace/project/project-obj-visitor';
import { Project } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface ProjectVisitor {
  post(): void;
  // holdingVisitor: HoldingVisitor;
  // transactionVisitor: TransactionVisitor;
  // projectObjVisitor: ProjectObjVisitor;
  portfolioProjectVisitor: PortfolioProjectVisitor;
  privateEquityFundProjectVisitor: PrivateEquityFundProjectVisitor;
}

export class ProjectVisitable implements Visitable<ProjectVisitor> {
  constructor(
    private _workspaceId: string,
    private _project: Project,
  ) {}
  public accept(visitor: ProjectVisitor) {
    // const projectObjVisitable = new ProjectObjVisitable(
    //   this._workspaceId,
    //   this._project.project,
    // );
    // projectObjVisitable.accept(visitor.projectObjVisitor);
    if (isPortfolioProject(this._project)) {
      const portfolioProjectVisitable = new PortfolioProjectVisitable(
        this._workspaceId,
        this._project,
      );
      portfolioProjectVisitable.accept(visitor.portfolioProjectVisitor);
    } else if (isPrivateEquityFundProject(this._project)) {
      const privateEquityFundProjectVisitable =
        new PrivateEquityFundProjectVisitable(this._workspaceId, this._project);
      privateEquityFundProjectVisitable.accept(
        visitor.privateEquityFundProjectVisitor,
      );
    }
  }
}

export class ProjectHandlerVisitor implements ProjectVisitor {
  constructor(
    // public projectObjVisitor: ProjectObjVisitor,
    public portfolioProjectVisitor: PortfolioProjectVisitor,
    public privateEquityFundProjectVisitor: PrivateEquityFundProjectVisitor,
  ) {}

  post() {
    return;
  }
}

export class ProjectStateVisitor extends ProjectHandlerVisitor {
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    super(
      // new ProjectObjStateVisitor(
      //   dispatch,
      //   tenantId,
      //   workspaceId,
      //   subscriptions,
      // ),
      new PortfolioProjectStateVisitor(
        dispatch,
        tenantId,
        workspaceId,
        subscriptions,
      ),
      new PrivateEquityFundProjectStateVisitor(
        dispatch,
        tenantId,
        workspaceId,
        subscriptions,
      ),
    );
  }

  post() {
    this.privateEquityFundProjectVisitor.post();
    // this.projectObjVisitor.post();
    this.portfolioProjectVisitor.post();
  }
}
