import { HoldingType } from '@pladdenico/models';
import React from 'react';
import { EditHolding } from '~src/components/fields/workspace/holding/holding/edit-holding';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DnbImportComponent } from '~src/domain/workspace/components/data/adapters/dnb/import.component';
// import { DnbImportedTransactionsComponent } from '~src/domain/workspace/components/data/adapters/dnb/imported-transactions.component';
import { dnbImporter } from '~src/services/xlsx/handlers/adapters/dnb/importer';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

export const DnbTransactionImporterComponent = React.memo((props: Props) => {
  const { workspace, projectId } = props;
  const [holding, setHolding] = React.useState<HoldingState | undefined>();

  const importElement = holding ? (
    <DnbImportComponent
      workspace={workspace}
      dataImporter={dnbImporter(holding.id)}
    />
  ) : undefined;

  // const importedTransactionsElement = holding && (
  //   <DnbImportedTransactionsComponent
  //     workspace={workspace}
  //     projectId={projectId}
  //     holding={holding}
  //   />
  // );

  return (
    <>
      <EditHolding
        handleValue={setHolding}
        projectId={projectId}
        holding={holding}
        holdingTypes={[HoldingType.BankNote]}
        // holdingTypes={}
        workspace={workspace}
        label={'Holding'}
      />
      {importElement}
      {/* {importedTransactionsElement} */}
    </>
  );
});
