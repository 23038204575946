import { handleEmails } from '~src/data/store/modules/people/emails/handlers';
import {
  BaseEmailId,
  emailsActions,
} from '~src/data/store/reducers/person/emails/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchEmails(
  tenantId: string,
  workspaceId: string,
  personId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetEmailsDocument;
    const variables: graphqlWorkspaceTypes.GetEmailsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getEmails) {
        return handleEmails(dispatch, tenantId, workspaceId, data.getEmails);
      }
      return undefined;
    });
  };
}

export function createEmails(
  tenantId: string,
  workspaceId: string,
  personId: string,
  inputs: graphqlWorkspaceTypes.CreateEmailInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateEmailsDocument;
    const variables: graphqlWorkspaceTypes.CreateEmailsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createEmails) {
        return handleEmails(dispatch, tenantId, workspaceId, data.createEmails);
      }
      throw new Error('Could not create email');
      // return undefined;
    });
  };
}

export function updateEmails(
  tenantId: string,
  workspaceId: string,
  personId: string,
  inputs: graphqlWorkspaceTypes.UpdateEmailInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateEmailsDocument;
    const variables: graphqlWorkspaceTypes.UpdateEmailsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateEmails) {
        return handleEmails(dispatch, tenantId, workspaceId, data.updateEmails);
      }
      return [];
    });
  };
}

export function upsertEmails(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertEmailInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertEmailsDocument;
    const variables: graphqlWorkspaceTypes.UpsertEmailsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertEmails) {
        return handleEmails(dispatch, tenantId, workspaceId, data.upsertEmails);
      }
      return [];
    });
  };
}

export function deleteEmails(
  tenantId: string,
  workspaceId: string,
  ids: BaseEmailId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteEmailsDocument;
    const variables: graphqlWorkspaceTypes.DeleteEmailsMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteEmails) {
        const deletedIds = data.deleteEmails;
        dispatch(
          emailsActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
