import React from 'react';
import { EditLoan } from '~src/components/fields/workspace/asset/edit/asset-types/edit-loan';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { LoanHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/loan/create-action';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  loanHoldingInput: LoanHoldingInputType;
  setLoanHoldingInput: (
    loanHoldingInput: graphqlWorkspaceTypes.CreateLoanHoldingInputType,
  ) => void;
}

export const CreateLoanHoldingFieldsComponent = React.memo((props: Props) => {
  const handleLoanChange = (loanId: string) => {
    props.setLoanHoldingInput({
      ...props.loanHoldingInput,
      loanId,
    });
  };

  return (
    <EditLoan
      handleValue={handleLoanChange}
      loanId={props.loanHoldingInput.loanId}
      workspace={props.workspace}
    />
  );
});
