import { DataImportDataState } from '~src/data/store/reducers/reducers';
import { dnbTransactionsSelectors } from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';

import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';
import { sortBy } from 'lodash';

export const selectDnbTransactions = (state: DataImportDataState) => {
  return state.adapter.dnb.dnbTransactions;
};

export const getDnbTransactions = createSelector(
  (state: DataImportDataState, _id: string | null) =>
    selectDnbTransactions(state),
  (_state: DataImportDataState, id: string | null) => id,
  (transactions, id) => {
    if (id !== null) {
      return dnbTransactionsSelectors.selectElementsByKey(transactions, id);
    }
    return dnbTransactionsSelectors.selectAllElements(transactions);
  },
);

export const getSortedDnbTransactions = createCachedSelector(
  (state: DataImportDataState, id: string | null) =>
    getDnbTransactions(state, id),
  (transactions) => {
    return sortBy(transactions, (a) => a.date.getTime());
  },
)({
  keySelector: (_state, id) => (id == null ? '' : id),
  selectorCreator: createSelector,
});

export const getDnbAccounts = createSelector(
  (state: DataImportDataState) => selectDnbTransactions(state),
  (transactions) => {
    return dnbTransactionsSelectors
      .selectAllRecords(transactions)
      .map((v) => v.id);
  },
);
