import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { PieChartComponent } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/pie-chart.component';
import { useAllocationData } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-allocation-data';
import { usePieChartMenuItems } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-pie-chart-menu-items';
import {
  defaultAssetClassBackgroundColor,
  defaultAssetClassFontColor,
  defaultCategoryBackgroundColor,
  defaultCategoryFontColor,
  defaultCurrencyBackgroundColor,
  defaultCurrencyFontColor,
  defaultIdBackgroundColor,
  defaultIdFontColor,
  defaultRegionBackgroundColor,
  defaultRegionFontColor,
  defaultSectorBackgroundColor,
  defaultSectorFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation/default-colors';
import { useAllocationMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation/menu.component';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  portfolio: PortfolioState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  baseCurrency: CurrencyState;
  period: Period;
}

const getDefaultFontColor = (type: string) => {
  if (type === 'id') {
    return defaultIdFontColor;
  } else if (type === 'category') {
    return defaultCategoryFontColor;
  } else if (type === 'assetClass') {
    return defaultAssetClassFontColor;
  } else if (type === 'sector') {
    return defaultSectorFontColor;
  } else if (type === 'region') {
    return defaultRegionFontColor;
  } else if (type === 'currency') {
    return defaultCurrencyFontColor;
  }
  return '#FFFFFF';
};

const getDefaultBackgroundColor = (type: string) => {
  if (type === 'id') {
    return defaultIdBackgroundColor;
  } else if (type === 'category') {
    return defaultCategoryBackgroundColor;
  } else if (type === 'assetClass') {
    return defaultAssetClassBackgroundColor;
  } else if (type === 'sector') {
    return defaultSectorBackgroundColor;
  } else if (type === 'region') {
    return defaultRegionBackgroundColor;
  } else if (type === 'currency') {
    return defaultCurrencyBackgroundColor;
  }
  return '#FFFFFF';
};

export const AllocationElement = React.memo(
  ({
    workspace,
    // portfolio,
    projectId,
    holdings,
    config,
    baseCurrency,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    // useComponentUpdateDebug(`AllocationElement ${id}`, {
    //   workspace,
    //   portfolio,
    //   projectId,
    //   holdings,
    //   config,
    //   baseCurrency,
    //   active,
    //   setActive,
    //   setInactive,
    //   loading,
    //   id,
    //   remove,
    //   element,
    //   updated,
    // });
    const [holdingFilterId] = React.useState(0);
    const filter = useSelector((state: RootState) =>
      getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
    );

    const [showFilteredOut, setShowFilteredOut] = React.useState(true);
    const useMarketValue = element.config.useMarketValue as boolean;

    const filterKey = (element.config.type as string) ?? 'category';
    const type = filterKey;
    const { data } = useAllocationData(
      workspace.id,
      projectId,
      filterKey,
      config.date,
      holdings,
      baseCurrency,
      useMarketValue,
      filter,
      showFilteredOut,
    );

    const menuItems = usePieChartMenuItems(
      data,
      filter,
      filterKey,
      showFilteredOut,
      setShowFilteredOut,
    );
    const { menu, handleOpen, isOpen } = useAllocationMenu(
      element,
      menuItems,
      remove,
      updated,
    );

    const getColorIndex = React.useCallback(
      (id: string) => {
        const idx = data.findIndex((value) => value.id === id);
        if (idx !== -1) {
          return idx;
        }
        return 0;
      },
      [data],
    );

    const content = React.useMemo(() => {
      return (
        <PieChartComponent
          data={data}
          filter={filter}
          filterKey={filterKey}
          showFilteredOut={showFilteredOut}
          setShowFilteredOut={setShowFilteredOut}
          getColorIndex={getColorIndex}
        />
      );
    }, [data, filter, filterKey, getColorIndex, showFilteredOut]);

    // const content = useAssetGroupPieChart(
    //   data,
    //   allocationGoalData,
    //   filter,
    //   filterKey,
    //   showFilteredOut,
    //   setShowFilteredOut
    // );

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? getDefaultFontColor(type),
          backgroundColor:
            element.config.backgroundColor ?? getDefaultBackgroundColor(type),
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
      type,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          {content}
        </ElementComponent>
        {menu}
      </>
    );
  },
);
