import { handleSchedulers } from '~src/data/store/modules/schedulers/handler';
import { schedulersActions } from '~src/data/store/reducers/workspace/projects/reports/schedulers/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchSchedulers(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
  handler: (
    dispatch: any,
    tenantId: string,
    workspaceId: string,
    outputs: graphqlWorkspaceTypes.Scheduler[],
    subscriptions: Operation[],
  ) => graphqlWorkspaceTypes.Scheduler[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetSchedulersDocument;
    const variables: graphqlWorkspaceTypes.GetSchedulersQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context)
      .then((data) => {
        if (data.getSchedulers) {
          return handler(dispatch, tenantId, workspaceId, data.getSchedulers, [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ]);
          // return handleSchedulers(
          //   dispatch,
          //   tenantId,
          //   workspaceId,
          //   data.getSchedulers,
          //   [
          //     Operation.create,
          //     Operation.delete,
          //     Operation.update,
          //     Operation.upsert,
          //   ]
          // );
        }
        return undefined;
      })
      .catch((err) =>
        console.error(`Error getSchedulers: ', ${JSON.stringify(err)}`),
      );
  };
}

export function createSchedulers(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateSchedulerInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateSchedulersDocument;
    const variables: graphqlWorkspaceTypes.CreateSchedulersMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createSchedulers) {
        return handleSchedulers(
          dispatch,
          tenantId,
          workspaceId,
          data.createSchedulers,
        );
      }
      throw new Error('Could not create scheduler');
    });
  };
}

export function updateSchedulers(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateSchedulerInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateSchedulersDocument;
    const variables: graphqlWorkspaceTypes.UpdateSchedulersMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateSchedulers) {
        return handleSchedulers(
          dispatch,
          tenantId,
          workspaceId,
          data.updateSchedulers,
        );
      }
      return [];
    });
  };
}

export function upsertSchedulers(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertSchedulerInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertSchedulersDocument;
    const variables: graphqlWorkspaceTypes.UpsertSchedulersMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertSchedulers) {
        return handleSchedulers(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertSchedulers,
        );
      }
      return [];
    });
  };
}

export function handleDeleteSchedulers(projectId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      schedulersActions.removeMany(
        ids.map((p) => {
          return {
            projectId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteSchedulers(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteSchedulersDocument;
    const variables: graphqlWorkspaceTypes.DeleteSchedulersMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteSchedulers) {
        dispatch(handleDeleteSchedulers(projectId, data.deleteSchedulers));
      }
      return [];
    });
  };
}
