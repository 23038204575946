// import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { LOGGED_OUT } from '~src/data/store/modules/account';
import {
  accountInitialState,
  accountReducer,
} from '~src/data/store/modules/account/reducer';
import {
  configReducer,
  initialState as configInitialState,
} from '~src/data/store/modules/config/reducer';
import {
  organizationInitialState,
  organizationReducer,
} from '~src/data/store/modules/organizations/reducer';
import {
  ownerInitialState,
  ownerReducer,
} from '~src/data/store/modules/owners/reducer';
import {
  assetInitialState,
  assetReducer,
} from '~src/data/store/reducers/asset/reducer';
import {
  commonInitialState,
  commonReducer,
} from '~src/data/store/reducers/common/reducer';
import {
  initialRequestStatusState,
  requestStatusReducer,
} from '~src/data/store/reducers/common/request-status/reducer';
import {
  entityInitialState,
  entityReducer,
} from '~src/data/store/reducers/entity/reducer';
import {
  filesReducer,
  initialState as initialMediaState,
} from '~src/data/store/reducers/file/files/reducer';
import {
  holdingInitialState,
  holdingReducer,
} from '~src/data/store/reducers/holding/reducer';
import {
  portfolioInitialState,
  portfolioReducer,
} from '~src/data/store/reducers/portfolio/reducers';
// import {
//   currencyInitialState,
//   currencyReducer,
// } from '~src/data/store/reducers/workspace/common/com';
// import {
//   eventInitialState,
//   eventReducer,
// } from '~src/data/store/modules/events.OLD/reducer';
// import {
//   exchangeRateInitialState,
//   exchangeRateReducer,
// } from '~src/data/store/modules/exchange-rates/reducer';
import {
  groupInitialState,
  groupReducer,
} from '~src/data/store/reducers/user/group/reducer';
// import {
//   initialQuoteState,
//   quoteReducer,
// } from '~src/data/store/reducers/finance/quote/reducer';
import {
  userInitialState,
  userReducer,
} from '~src/data/store/reducers/user/user/reducer';
import {
  workspaceInitialState,
  workspaceReducer,
} from '~src/data/store/reducers/workspace/reducer';
// import {
//   reportInitialState,
//   reportReducer,
// } from '~src/data/store/reducers/workspace/projects/reports/reducer';
// import {
//   eventInitialState,
//   eventReducer,
// } from '~src/data/store/reducers/workspace/projects/events/reducer';
import {
  adapterReducer,
  initialAdapterState,
} from '~src/services/xlsx/handlers/adapters/reducer';

import { financeReducer, initialFinanceState } from './finance/reducer';
import { personInitialState, personReducer } from './person/reducer';
import { tenantInitialState, tenantReducer } from './tenant/reducer';
import { viewInitialState, viewReducer } from './view/reducer';
import {
  holdingsReducer,
  initialHoldingsState,
} from '~src/data/store/reducers/holding/new-reducer';
// import {
//   holdingsReducer,
//   initialHoldings,
// } from '~src/data/store/reducers/holding/new-reducer';

const dataImportDataReducer = combineReducers({
  adapter: adapterReducer,
});

const userDataReducer = combineReducers({
  user: userReducer,
  group: groupReducer,
  account: accountReducer,
});

const tenantDataReducer = combineReducers({
  tenant: tenantReducer,
});

const requestDataReducer = combineReducers({
  requestStatus: requestStatusReducer,
});

const viewDataReducer = combineReducers({
  view: viewReducer,
});

const holdingDataReducer = combineReducers({
  asset: assetReducer,
  entity: entityReducer,
  holding: holdingReducer,
  holdings: holdingsReducer,
});

export const workspaceDataReducer = combineReducers({
  common: commonReducer,
  config: configReducer,
  owner: ownerReducer,
  workspace: workspaceReducer,
  portfolio: portfolioReducer,
  person: personReducer,
  media: filesReducer,
  holding: holdingDataReducer,
});

const financialDataReducer = combineReducers({
  finance: financeReducer,
  organization: organizationReducer,
});

const dataReducer = combineReducers({
  tenant: tenantDataReducer,
  workspace: workspaceDataReducer,
  user: userDataReducer,
  financial: financialDataReducer,
  dataImport: dataImportDataReducer,
  request: requestDataReducer,
});

const rootReducer = () => {
  return combineReducers({
    data: dataReducer,
    view: viewDataReducer,
  });
};

export type HoldingDataState = ReturnType<typeof holdingDataReducer>;
export type UserDataState = ReturnType<typeof userDataReducer>;
export type RequestDataState = ReturnType<typeof requestDataReducer>;
export type TenantDataState = ReturnType<typeof tenantDataReducer>;
export type ViewDataState = ReturnType<typeof viewDataReducer>;
export type WorkspaceDataState = ReturnType<typeof workspaceDataReducer>;
export type FinancialDataState = ReturnType<typeof financialDataReducer>;
export type DataImportDataState = ReturnType<typeof dataImportDataReducer>;

export type DataState = ReturnType<typeof dataReducer>;

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export const initialReducer = (_state: RootState | undefined) => {
  return {
    // router: state ? state.router : ({} as any), // XXX: Should use initial route for connectedRouter
    view: { view: viewInitialState },
    data: {
      user: {
        user: userInitialState,
        group: groupInitialState,
        account: accountInitialState,
      },
      financial: {
        // currency: currencyInitialState,
        finance: initialFinanceState,
        // quote: initialQuoteState,
        // exchangeRate: exchangeRateInitialState,
        organization: organizationInitialState,
      },
      tenant: {
        tenant: tenantInitialState,
      },
      workspace: {
        holding: {
          asset: assetInitialState,
          entity: entityInitialState,
          holding: holdingInitialState,
          holdings: initialHoldingsState,
        },
        common: commonInitialState,
        config: configInitialState,
        // holdings: initialHoldings,
        owner: ownerInitialState,
        group: groupInitialState,
        person: personInitialState,
        // event: eventInitialState,
        workspace: workspaceInitialState,
        portfolio: portfolioInitialState,
        media: initialMediaState,
      },
      dataImport: {
        adapter: initialAdapterState,
      },
      request: {
        requestStatus: initialRequestStatusState,
      },
    },
  };
};

export const appReducer = () => {
  return (state: RootState | undefined, action: any) => {
    if (action.type === LOGGED_OUT) {
      state = initialReducer(state);
    }
    return rootReducer()(state, action);
  };
};
