import {
  LoanHoldingStateVisitor,
  LoanHoldingVisitable,
} from '~src/data/store/visitors/holding/types/loan/loan-holding-visitor';
import { LoanHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleLoanHoldings(dispatch: any, outputs: LoanHolding[]) {
  const loanHoldingVisitor = new LoanHoldingStateVisitor(dispatch);

  outputs.forEach((loanHolding) => {
    const loanHoldingVisitable = new LoanHoldingVisitable(loanHolding);
    loanHoldingVisitable.accept(loanHoldingVisitor);
  });
  loanHoldingVisitor.post();
  return outputs;
}
