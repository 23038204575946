import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DashboardMenuDiv } from '~src/components/dashboard/dashboard-menu-div';
import { useHoldingsAlgorithm } from '~src/components/fields/workspace/holding/algorithm/use-algorithm';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getScenarioById } from '~src/data/store/selectors/workspace/projects/scenario/scenarios/selectors';
import { PortfolioConfigComponent } from '~src/domain/workspace/components/project/portfolio/config/portfolio-config.component';
import { useConfigButton } from '~src/domain/workspace/components/project/portfolio/holdings/use-config-button';
import { useToolbar } from '~src/domain/workspace/components/project/scenario/holdings/use-toolbar';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { ProjectScenarioDashboardComponent } from '~src/domain/workspace/components/project/scenario/dashboard/scenario-dashboard';
import { ProjectScenarioHoldingsComponent } from '~src/domain/workspace/components/project/scenario/holdings/scenario-holdings.component';
import { PortfolioProjectScenarioTabs } from '~src/domain/workspace/components/project/scenario/tabs/tabs';
import { Config } from '~src/utils/interfaces/config';

import { Box, Drawer, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ProjectScenarioSettingsComponent } from '~src/domain/workspace/components/project/scenario/settings/scenario-settings.component';
import { getHoldingsByPortfolioId } from '~src/data/store/selectors/portfolio/portfolios/selectors';

const useStyles = makeStyles()((_theme: Theme) => ({
  root: {
    position: 'relative',
    top: 52,
    flex: 1,
    flexDirection: 'column',
  },
}));

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  tabName: PortfolioProjectScenarioTabs;
}

export const ScenarioTabComponent = React.memo((props: Props) => {
  const { project } = props;
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const scenario = useSelector((state: RootState) => {
    if (scenarioId != null) {
      return getScenarioById(selectWorkspaceData(state), {
        id: scenarioId,
        projectId: project.id,
      });
    }
  });
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );

  console.log('ProjectScenarioComponent');
  if (!scenario || !config) {
    return <div>Did not find scenario</div>;
  }

  return (
    <ScenarioTabComponentWithScenario
      scenario={scenario}
      config={config}
      {...props}
    />
  );
});

interface PropsWithScenario extends Props {
  scenario: Scenario;
  config: Config;
}

export const ScenarioTabComponentWithScenario = React.memo(
  (props: PropsWithScenario) => {
    const {
      workspace,
      project,
      portfolio,
      portfolioProject,
      scenario,
      tabName,
      config,
    } = props;
    const { classes } = useStyles();

    const {
      button: configButton,
      open: configOpen,
      toggle: toggleConfig,
    } = useConfigButton();

    const holdings = useSelector((state: RootState) =>
      getHoldingsByPortfolioId(selectWorkspaceData(state), {
        workspaceId: workspace.id,
        projectId: project.id,
        id: portfolio.id,
      }),
    );
    const { algoData } = useHoldingsAlgorithm(workspace, holdings);

    const { filterDrawer, actions, sorterDrawer } = useToolbar(
      workspace,
      project,
      algoData,
    );

    const tab = React.useMemo(() => {
      if (tabName === PortfolioProjectScenarioTabs.Dashboard) {
        return (
          <ProjectScenarioDashboardComponent
            workspace={workspace}
            project={project}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            algoData={algoData}
            config={config}
            scenario={scenario}
          />
        );
        // } else if (tabName === PortfolioProjectScenarioTabs.Settings) {
        //   return (
        //     <SettingsComponent
        //       workspace={workspace}
        //       projectId={project.id}
        //       portfolio={portfolio}
        //     />
        //   );
      } else if (tabName === PortfolioProjectScenarioTabs.Holdings) {
        return (
          <ProjectScenarioHoldingsComponent
            workspace={workspace}
            project={project}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            scenario={scenario}
            algoData={algoData}
          />
        );
      } else if (tabName === PortfolioProjectScenarioTabs.Settings) {
        return (
          <ProjectScenarioSettingsComponent
            workspace={workspace}
            project={project}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            scenario={scenario}
            algoData={algoData}
          />
        );
      }
      return undefined;
    }, [
      tabName,
      workspace,
      project,
      portfolioProject,
      portfolio,
      algoData,
      config,
      scenario,
    ]);

    return (
      <>
        <DashboardMenuDiv
          config={{ open: configOpen, alignSelf: 'flex-start' }}
        >
          <Box sx={{ mt: 1, ml: 1 }}>{configButton}</Box>
        </DashboardMenuDiv>
        <DashboardMenuDiv config={{ open: configOpen, alignSelf: 'center' }}>
          {actions}
        </DashboardMenuDiv>
        <div className={classes.root}>
          {filterDrawer}
          {sorterDrawer}
          {tab}
          <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
            <PortfolioConfigComponent
              workspace={workspace}
              portfolioProject={portfolioProject}
              portfolio={portfolio}
              portfolioConfig={undefined}
              dashboardConfig={undefined}
            />
          </Drawer>
        </div>
      </>
    );
  },
);
