import { compact } from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import { transferCashSign } from '~src/utils/finance/transfer-cash-sign';
import { TransferType } from '@pladdenico/models';
import { getExchangeRateState } from '../exchange-rates/selectors';
import { createSelector } from 'reselect';
// import { transferSign } from '~src/utils/finance/transfer-sign';

export interface Props {
  workspaceId: string;
  holding: HoldingState;
  currency: CurrencyState;
  date: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  useLiveValue: boolean;
}

export const getHoldingTransfers = createCachedSelector(
  (state: DataState, props: Props) =>
    getTransfersByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.startDate,
  (_state: DataState, props: Props) => props.endDate,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (_state: DataState, props: Props) => props.currency,
  (
    transfers,
    date,
    startDate,
    endDate,
    exchangeRateState,
    currency,
  ): TransferState[] => {
    let filteredTransfers: TransferState[] = [];

    filteredTransfers = transfers.filter((value) => {
      if (!value.date) {
        return false;
      }
      return startDate < value.date && value.date < endDate;
    });

    let cashFlows = [];

    cashFlows = compact(
      filteredTransfers.map((transfer) => {
        if (transfer.value && transfer.currencyId && transfer.date) {
          const sign = transfer.type
            ? // ? transferCashSign(transfer.type as TransferType)
              transferCashSign(transfer.type as TransferType)
            : 1;
          return {
            ...transfer,
            value:
              transfer.value *
              convert(
                exchangeRateState,
                transfer.currencyId,
                currency.id,
                date.toDate(),
              ) *
              sign,
            date: transfer.date,
          };
        }
        return undefined;
      }),
    ).sort((a, b) => a.date.getTime() - b.date.getTime());

    return cashFlows;
  },
)({
  keySelector: (_state: DataState, props: Props) =>
    `${props.holding.id}:${
      props.currency.id
    }:${props.date.unix()}:${props.startDate.unix()}:${props.endDate.unix()}:${
      props.useLiveValue
    }`,
  selectorCreator: createSelector,
});
