import { strcmp } from '@pladdenico/common';
import { RequestStatus } from '~src/services/request/request-status';
import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

export interface RequestStatusId {
  id: string;
}

export type PortoflioProjectRequestStatusState = RequestStatus;

export function privateEquityFundProjectRequestStatusToPortoflioProjectRequestStatusState(
  privateEquityFundProjectRequestStatus: PortoflioProjectRequestStatusState,
) {
  return privateEquityFundProjectRequestStatus;
}
export const initialPrivateEquityFundProjectRequestStatusState = [];

const comparator = (a: RequestStatusId, b: RequestStatusId) =>
  strcmp(a.id, b.id);
const merger = (
  _a: PortoflioProjectRequestStatusState,
  b: PortoflioProjectRequestStatusState,
) => b;

const adapter = new EntityArrayAdapter<
  RequestStatusId,
  PortoflioProjectRequestStatusState
>(initialPrivateEquityFundProjectRequestStatusState, comparator, merger);

export const privateEquityFundProjectRequestStatusSlice = createBaseArraySlice(
  'privateEquityFundProjectRequestStatus',
  adapter,
);
export const privateEquityFundProjectRequestStatusSelectors = adapter.selectors;

export const privateEquityFundProjectRequestStatusReducer =
  privateEquityFundProjectRequestStatusSlice.reducer;
export const privateEquityFundProjectRequestStatusActions =
  privateEquityFundProjectRequestStatusSlice.actions;
