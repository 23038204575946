import {
  findSorted,
  intersectionSorted,
  removeManySorted,
  removeOneSorted,
  unionSorted,
  uniqSorted,
  updateOneSorted,
  upsertOneSorted,
} from '@pladdenico/common';

export interface EntitySelectors<B, T extends B> {
  selectAllElements: (state: T[]) => T[];

  selectElement: (state: T[], t: B) => T | undefined;
  selectElements: (state: T[], t: B[]) => T[];
}

export class EntityArrayAdapter<B, T extends B> {
  private _initialState: T[];
  private _comparator: (a: B, b: B) => number;
  private _merger: (a: T, b: T) => T;
  private _selectors: EntitySelectors<B, T>;
  private _ensureUniqueness: boolean;

  constructor(
    initialState: T[],
    comparator: (a: B, b: B) => number,
    merger: (a: T, b: T) => T,
    ensureUniqueness = true,
  ) {
    this._initialState = initialState;
    this._comparator = comparator;
    this._merger = merger;
    this._selectors = {
      selectElement: this.selectElement,
      selectElements: this.selectElements,
      selectAllElements: this.selectAllElements,
    };
    this._ensureUniqueness = ensureUniqueness;
  }

  get initialState() {
    return this._initialState;
  }

  get comparator() {
    return this._comparator;
  }

  get selectors(): EntitySelectors<B, T> {
    return this._selectors;
  }

  private selectAllElements = (state: T[]) => {
    return state;
  };

  private selectElement = (state: T[], t: B) => {
    return findSorted(state, t, this._comparator).item;
  };

  private selectElements = (state: T[], ts: B[]) => {
    return intersectionSorted(state, ts, [this._comparator], (a, _b) => a);
  };

  public setAllReducer = () => {
    return (_state: T[], action: { payload: T[] }): T[] => {
      let ts = action.payload;
      ts = ts.sort(this._comparator);
      if (this._ensureUniqueness) {
        ts = uniqSorted(ts, [this._comparator]);
      }
      return [...ts];
    };
  };

  public upsertOneElementReducer = () => {
    return (state: T[], action: { payload: T }): T[] => {
      const t = action.payload;
      const res = [...state];
      return upsertOneSorted(
        res,
        t,
        this._comparator,
        this._merger,
        // (_a, b) => b,
        (a) => a,
      ).items;
    };
  };

  public updateOneElementReducer = () => {
    return (state: T[], action: { payload: T }): T[] => {
      const t = action.payload;
      const res = [...state];
      return updateOneSorted(res, t, this._comparator, this._merger).items;
    };
  };

  public upsertManyElementsReducer = () => {
    return (state: T[], action: { payload: T[] }) => {
      let ts = action.payload;
      ts = ts.sort(this._comparator);
      if (this._ensureUniqueness) {
        ts = uniqSorted(ts, [this._comparator]);
      }
      return unionSorted(
        state,
        ts,
        [this._comparator],
        this._merger,
        // (a: T, _b: T) => a,
        // this._merger, XXX : should be this one??
        (t: T) => t,
        (t: T) => t,
      );
    };
  };

  public removeOneElementReducer = () => {
    return (state: T[], action: { payload: B }) => {
      const res = [...state];
      const t = action.payload;
      return removeOneSorted(res, t, this._comparator).items;
    };
  };

  public removeManyElementsReducer = () => {
    return (state: T[], action: { payload: B[] }) => {
      // const res = [...state];
      let ts = action.payload;
      ts = ts.sort(this._comparator);
      return removeManySorted(state, ts, this._comparator).items;
    };
  };

  public clearReducer = () => {
    return (_state: T[], _action: { payload: undefined }) => {
      return [];
    };
  };
}
