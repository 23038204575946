import { difference } from 'lodash';

import { MultipleRelationOperationType } from '@pladdenico/models';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

export const getDiffRelationInput = <T>(
  oldIds: T[],
  newIds: T[],
  tToId: (t: T) => string,
) => {
  const removed = difference(oldIds, newIds);
  const added = difference(newIds, oldIds);
  const ids: graphqlWorkspaceOperations.MultipleRelationOperationStringInputType[] =
    [];
  removed.forEach((id) => {
    ids.push({
      id: tToId(id),
      type: MultipleRelationOperationType.remove,
    });
  });
  added.forEach((id) => {
    ids.push({
      id: tToId(id),
      type: MultipleRelationOperationType.add,
    });
  });
  return ids;
};
