import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  fundHoldingsSelectors,
  FundHoldingState,
} from '../../../../../reducers/holding/holding-types/fund/fund-holding/reducer';

const getFundHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.fundHolding.fundHoldings;

export const getFundHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getFundHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (fundHoldingState, holdingId): FundHoldingState | undefined => {
    return fundHoldingsSelectors.selectElementByIdx(
      fundHoldingState,
      holdingId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getFundHoldings = createSelector(
  (state: WorkspaceDataState) => getFundHoldingState(state),
  (fundHoldings) => {
    return fundHoldingsSelectors.selectAllElements(fundHoldings);
  },
);

export const getFundHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getFundHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (fundHoldings, id): FundHoldingState | undefined => {
    return fundHoldingsSelectors.selectElementByI(fundHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getFundHoldingsByFundId = createCachedSelector(
  (state: WorkspaceDataState) => getFundHoldings(state),
  (_state: WorkspaceDataState, fundId: string) => fundId,
  (fundHoldings, fundId): FundHoldingState[] => {
    return fundHoldings.filter((h) => h.fundId === fundId);
  },
)({
  keySelector: (_state, fundId) => `${fundId}`,
  selectorCreator: createSelector,
});
