import React from 'react';
import { EditableMatrix } from '~src/domain/workspace/components/finance/tools/editable-matrix';
import { PortfolioAsset } from '~src/domain/workspace/components/finance/tools/pareto/covariances';

import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  portfolioAssets: PortfolioAsset[];
  expectedReturns: number[];
  lowerBounds: number[];
  upperBounds: number[];
  handleInputVectorsChange: (row: number, col: number, value: number) => void;
}

export const useVectorsInput = (props: Props) => {
  const {
    portfolioAssets,
    expectedReturns,
    lowerBounds,
    upperBounds,
    handleInputVectorsChange,
  } = props;

  const inputVectors = React.useMemo(() => {
    const n = portfolioAssets.length;
    const matrix: number[][] = Array.from({ length: 3 }, () =>
      Array.from({ length: n }),
    );
    for (let i = 0; i < n; ++i) {
      matrix[0][i] = expectedReturns[i];
      matrix[1][i] = lowerBounds[i];
      matrix[2][i] = upperBounds[i];
    }
    return matrix;
  }, [expectedReturns, lowerBounds, portfolioAssets.length, upperBounds]);

  const assetHeaders = React.useMemo(() => {
    return portfolioAssets.map((asset) => {
      return (
        <Typography key={asset.id} variant="body2">
          {asset.name}
        </Typography>
      );
    });
  }, [portfolioAssets]);

  const inputVectorsRowHeaders = React.useMemo(() => {
    return [
      <Typography variant="body2" key="return">
        Exptected Return
      </Typography>,
      <Typography variant="body2" key="lower_bounds">
        Lower bounds
      </Typography>,
      <Typography variant="body2" key="upper_bounds">
        Upper bounds
      </Typography>,
    ];
  }, []);

  return (
    <Grid2 container>
      <Grid2 xs={12} margin={2}>
        <EditableMatrix
          title={<Typography variant="body1">Input</Typography>}
          columnHeaders={assetHeaders}
          rowHeaders={inputVectorsRowHeaders}
          data={inputVectors}
          handleChange={handleInputVectorsChange}
        />
      </Grid2>
    </Grid2>
  );
};
