import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { DataState } from '~src/data/store/reducers/reducers';
import { getStockById } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';

import { getQuotesByStock } from '../selectors';

interface Props {
  workspaceId: string;
  stockId: string;
}

export const getQuotesByStockId = createCachedSelector(
  (state: DataState) => selectFinancialDataFromData(state),
  (state: DataState, props: Props) =>
    getStockById(selectWorkspaceDataFromData(state), {
      id: props.stockId,
      workspaceId: props.workspaceId,
    }),
  (state, stock) => {
    return stock ? getQuotesByStock(state, stock) : [];
  },
)({
  keySelector: (_state, props: Props) => props.stockId,
  selectorCreator: createSelector,
});

export const getLastQuoteByStockId = createCachedSelector(
  (state: DataState, props: Props) => getQuotesByStockId(state, props),
  (quotes) => {
    if (quotes != null) {
      return quotes.length > 0 ? quotes[-1] : undefined;
    }
  },
)({
  keySelector: (_state, props: Props) => props.stockId,
  selectorCreator: createSelector,
});
