import { sortBy } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createFilter } from '~src/data/store/modules/holdings/filter';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { AppDispatch } from '~src/store/store';
import {
  Filter,
  isExcludedExplicitly,
  isIncludedExplicitly,
  setExcludeOne,
  setIncludeOne,
} from '~src/utils/common/filter';

import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Chip,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

interface Props {
  filter: Filter;
  groups: Map<
    string,
    {
      name: string;
      holdings: HoldingState[];
    }
  >;
  pivot: string;
}

export const SubFilterComponent = (props: Props) => {
  const { filter, groups, pivot } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [query, setQuery] = React.useState('');

  const onChipClick = React.useCallback(
    (
      included: boolean | undefined,
      excluded: boolean | undefined,
      propertyFilter: { key: string; value: string },
    ) => {
      if (included) {
        setExcludeOne(filter, propertyFilter);
      } else if (excluded) {
        setIncludeOne(filter, propertyFilter, true);
      } else {
        setExcludeOne(filter, propertyFilter);
      }

      dispatch(createFilter(0, filter));
    },
    [filter, dispatch],
  );

  const filteredGroups = React.useMemo(() => {
    const newGroups: {
      group: {
        name: string;
        holdings: HoldingState[];
      };
      groupKey: string;
    }[] = [];
    groups.forEach((group, groupKey) => {
      if (group.name.toLowerCase().startsWith(query.toLowerCase())) {
        newGroups.push({ group, groupKey });
      }
    });
    return newGroups;
  }, [groups, query]);

  const sortedGroups = React.useMemo(() => {
    return sortBy(filteredGroups, [(a) => a.group.name.toLowerCase()]);
  }, [filteredGroups]);

  const subElements = React.useMemo(() => {
    const subElements: JSX.Element[] = [];

    sortedGroups.forEach((group) => {
      const includedExplicitly = isIncludedExplicitly(filter, {
        key: pivot,
        value: group.groupKey,
      });
      const excludedExplicitly = isExcludedExplicitly(filter, {
        key: pivot,
        value: group.groupKey,
      });
      if (includedExplicitly) {
        subElements.push(
          <Chip
            key={group.groupKey}
            color="primary"
            variant="filled"
            sx={{ mr: 1, mb: 1 }}
            label={group.group.name}
            onClick={() => {
              onChipClick(includedExplicitly, excludedExplicitly, {
                key: pivot,
                value: group.groupKey,
              });
            }}
          />,
        );
      } else if (excludedExplicitly) {
        subElements.push(
          <Chip
            key={group.groupKey}
            color="secondary"
            variant="filled"
            sx={{ mr: 1, mb: 1 }}
            label={group.group.name}
            onClick={() => {
              onChipClick(includedExplicitly, excludedExplicitly, {
                key: pivot,
                value: group.groupKey,
              });
            }}
          />,
        );
      } else {
        subElements.push(
          <Chip
            key={group.groupKey}
            color="default"
            sx={{ mr: 1, mb: 1 }}
            variant="outlined"
            label={group.group.name}
            onClick={() => {
              onChipClick(includedExplicitly, excludedExplicitly, {
                key: pivot,
                value: group.groupKey,
              });
            }}
          />,
        );
      }
    });
    return subElements;
  }, [filter, sortedGroups, onChipClick, pivot]);

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const query = event.target.value;
    setQuery(query);
  };

  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid item xs={1}>
        <Typography sx={{ mb: 1 }}>{pivot}</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="sub filter"
          size="small"
          variant="outlined"
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon
                  sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={9} sx={{ pl: 2 }}>
        {subElements}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
    </Grid>
  );
};
