import React from 'react';
import { ScenarioInput } from '~src/domain/workspace/components/project/scenario/form/scenario-input';
import { useHandler } from '~src/hooks/utils/use-handler';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  scenarioInput: ScenarioInput;
  setScenarioInput: (scenarioInput: ScenarioInput) => void;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
  },
}));

export const useScenarioFieldsComponent = (props: Props) => {
  const { classes } = useStyles();

  const { handleEvent } = useHandler(
    props.scenarioInput,
    props.setScenarioInput,
  );
  const nameElement = React.useMemo(
    () => (
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.scenarioInput.name}
        label="Name"
        type="text"
        onChange={handleEvent('name')}
      />
    ),
    [classes.textField, handleEvent, props.scenarioInput.name],
  );

  return {
    nameElement,
  };
};
