import * as React from 'react';
import { useSelector } from 'react-redux';
import { StockState } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { StockHoldingState } from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPaperById } from '~src/data/store/selectors/finance/papers/selectors';
import { selectLastPosition } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { BaseHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/base-holding-card.component';
import { useActions } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/use-actions';

import { Typography } from '@mui/material';

import { EditDialogComponent } from '../../../../holding/holding-types/stock-holdings/form/edit-dialog.component';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  stockHolding: StockHoldingState;
  stock: StockState | undefined;
  asset: AssetState | undefined;
}

export const StockHoldingCardComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holding,
    portfolios,
    stockHolding,
    stock,
    asset,
  } = props;
  const paper = useSelector((state: RootState) =>
    getPaperById(selectFinancialData(state), stock?.paperId),
  );

  const position = useSelector((state: RootState) =>
    selectLastPosition(selectWorkspaceData(state), stockHolding.id),
  );

  const getEditDialogComponent = React.useCallback(
    (editing: boolean, handleClose: () => void) => {
      return (
        <EditDialogComponent
          open={editing}
          handleClose={handleClose}
          holding={holding}
          stockHolding={stockHolding}
          workspace={workspace}
          projectId={projectId}
          portfolios={portfolios}
        />
      );
    },
    [holding, portfolios, projectId, workspace, stockHolding],
  );

  const { deleteHolding } = useActions(
    workspace,
    projectId,
    holding,
    stockHolding,
  );

  const stockPositionElement = position ? (
    <Typography variant="subtitle2">Shares: {position.shares}</Typography>
  ) : undefined;

  const paperString =
    stock && paper ? `Paper: ${paper?.exchange}-${paper?.symbol}` : undefined;
  const paperElement = paperString ? (
    <>
      <Typography variant="subtitle2">{paperString}</Typography>
      {stockPositionElement}
    </>
  ) : (
    <></>
  );

  return (
    <BaseHoldingCardComponent
      asset={asset}
      projectId={projectId}
      assetDetailElement={paperElement}
      getDialogComponent={getEditDialogComponent}
      holding={holding}
      portfolios={portfolios}
      workspace={workspace}
      deleteHolding={deleteHolding}
    />
  );
});
