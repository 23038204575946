import { transactionPath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchTransactions,
  handleDeleteTransactions,
} from '~src/data/store/modules/holdings/transactions/transactions/requests';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToTransactions(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  transactions: TransactionState[],
  subscriptions: Operation[],
) {
  subscriptions.forEach((operation) => {
    if (operation === Operation.create) {
      apis.invest.subscribe(
        {
          path: transactionPath(tenantId, workspaceId).domainPath(operation),
          handle: (res: { id: string }) => {
            dispatch(fetchTransactions(tenantId, workspaceId, [res.id]));
          },
        },
        () => {
          // console.log('subscribed to transaction', operation, transaction.id);
        },
      );
    }
    transactions.forEach((transaction) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: transactionPath(tenantId, workspaceId).objectPath(
              transaction.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchTransactions(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to transaction', operation, transaction.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: transactionPath(tenantId, workspaceId).objectPath(
              transaction.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteTransactions([
                  { id: res.id, projectId: transaction.projectId },
                ]),
              );
            },
          },
          () => {
            // console.log('subscribed to transaction', operation, transaction.id);
          },
        );
      }
    });
  });
}
