import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { useTenantCreate } from '~src/domain/tenant/components/form/use-tenant-create';
import { tenantRootPath } from '~src/navigation/paths/tenant/paths';

import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const CreateTenantComponent = memo(() => {
  const navigate = useNavigate();

  const handleGotoTenant = React.useCallback(
    (tenant: TenantState) => {
      navigate(tenantRootPath(tenant.id));
    },
    [navigate],
  );

  const { submit, tenantFields } = useTenantCreate();

  return (
    <Grid2 sx={{ minHeight: '90vh' }}>
      <Grid2
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        mt="30vh"
      >
        <Typography variant="h2">Create tenant</Typography>
      </Grid2>
      <Grid2 container justifyContent="center" mt={2}>
        <Grid2>
          <form>
            <Grid2 container justifyContent="center">
              <Grid2 xs={12}>{tenantFields.nameField}</Grid2>
              <Grid2 xs={3} m={3}>
                <Button
                  variant="contained"
                  onClick={() => submit(handleGotoTenant)}
                  color="primary"
                >
                  Create
                </Button>
              </Grid2>
            </Grid2>
          </form>
        </Grid2>
      </Grid2>
    </Grid2>
  );
});
