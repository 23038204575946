import { EntityAdapter } from './simple-entity-adapter';
import { createSlice, createReducer } from '../slice';

export function createBaseSlice<T>(domain: string, adapter: EntityAdapter<T>) {
  const slices = {
    set: createSlice(domain, 'set', adapter.setElementReducer()),
    unset: createSlice<T | null, undefined>(
      domain,
      'unset',
      adapter.unsetElementReducer(),
    ),
  };

  const actions = {
    set: slices.set.action,
    unset: slices.unset.action,
  };

  const reducer = createReducer(adapter.initialState, slices);

  return {
    actions,
    reducer,
  };
}
