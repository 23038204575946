import * as React from 'react';
import { useSelector } from 'react-redux';
import { BondState } from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { BondHoldingState } from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPaperById } from '~src/data/store/selectors/finance/papers/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { BaseHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/base-holding-card.component';
import { useActions } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/use-actions';

import { Typography } from '@mui/material';

import { EditDialogComponent } from '../../../../holding/holding-types/bond-holdings/form/edit-dialog.component';
import { selectLastPosition } from '~src/data/store/selectors/holding/holding-types/bond/bond-position/selectors';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  bondHolding: BondHoldingState;
  bond: BondState | undefined;
  asset: AssetState | undefined;
}

export const BondHoldingCardComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holding,
    portfolios,
    bondHolding,
    bond,
    asset,
  } = props;
  const paper = useSelector((state: RootState) =>
    getPaperById(selectFinancialData(state), bond?.paperId),
  );

  const position = useSelector((state: RootState) =>
    selectLastPosition(selectWorkspaceData(state), bondHolding.id),
  );

  const getEditDialogComponent = React.useCallback(
    (editing: boolean, handleClose: () => void) => {
      return (
        <EditDialogComponent
          open={editing}
          handleClose={handleClose}
          holding={holding}
          bondHolding={bondHolding}
          workspace={workspace}
          projectId={projectId}
          portfolios={portfolios}
        />
      );
    },
    [holding, portfolios, projectId, workspace, bondHolding],
  );

  const { deleteHolding } = useActions(
    workspace,
    projectId,
    holding,
    bondHolding,
  );
  const bondPositionElement = position ? (
    <Typography variant="subtitle2">Shares: {position.shares}</Typography>
  ) : undefined;

  const paperElement = paper && (
    <Typography variant="subtitle2">
      Paper: ${paper?.exchange}-${paper?.symbol}
    </Typography>
  );

  const assetDetailElement = (
    <>
      {paperElement}
      {bondPositionElement}
    </>
  );

  return (
    <BaseHoldingCardComponent
      asset={asset}
      projectId={projectId}
      assetDetailElement={assetDetailElement}
      getDialogComponent={getEditDialogComponent}
      holding={holding}
      portfolios={portfolios}
      workspace={workspace}
      deleteHolding={deleteHolding}
    />
  );
});
