import React from 'react';
import { SearchInput } from '~src/components/search/search-input';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { CreateBondPositionComponent } from './create.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  bondPositionCreateInputInit: graphqlWorkspaceTypes.CreateBondPositionInputType;
}

export const BondPositionsToolbar = (props: Props) => {
  // const { className, ...rest } = props;
  const [createBondPositionDialogOpened, setCreateBondPositionDialogeOpened] =
    React.useState(false);

  const { classes } = useStyles();

  const handleAddBondPosition = () => {
    setCreateBondPositionDialogeOpened(true);
  };

  function handleCloseCreateBondPositionDialog() {
    setCreateBondPositionDialogeOpened(false);
  }

  return (
    <div
      // {...rest}
      // className={clsx(classes.root, className)}
      className={classes.root}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddBondPosition}
        >
          Add bondPosition
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search bondPosition"
        />
      </div>
      <CreateBondPositionComponent
        workspace={props.workspace}
        open={createBondPositionDialogOpened}
        handleClose={handleCloseCreateBondPositionDialog}
        bondPositionCreateInputInit={props.bondPositionCreateInputInit}
      />
    </div>
  );
};
