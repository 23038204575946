import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BasePaperIdx,
  papersSelectors,
  selectIdx,
} from '~src/data/store/reducers/finance/paper/reducer';
import {
  DataState,
  FinancialDataState,
} from '~src/data/store/reducers/reducers';
import { getBondById } from '~src/data/store/selectors/asset/asset-types/bonds/selectors';
import { getFundById } from '~src/data/store/selectors/asset/asset-types/funds/selectors';
import { getStockById } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';

export const getPapers = (state: FinancialDataState) => {
  return state.finance.papers.papers;
};

export const getAllPapers = createSelector(
  (state: FinancialDataState) => getPapers(state),
  (papers) => {
    return papersSelectors.selectAllElements(papers);
  },
);

export const getPaperById = createCachedSelector(
  (state: FinancialDataState, _id: string | undefined | null) =>
    getPapers(state),
  (_state: FinancialDataState, id: string | undefined | null) => id,
  (papers, id) => {
    if (id == null) {
      return undefined;
    }
    return papersSelectors.selectElementByI(papers, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getPaperBySymbol = createCachedSelector(
  (state: FinancialDataState, _baseId: Partial<BasePaperIdx>) =>
    getPapers(state),
  (_state: FinancialDataState, baseId: Partial<BasePaperIdx>) =>
    selectIdx(baseId),
  (papers, idx) => {
    return papersSelectors.selectElementByI(papers, idx);
    // return papersSelectors.selectElementByIdx(papers, idx);
  },
)({
  keySelector: (_state, baseId) => `${baseId.exchange}.${baseId.symbol}`,
  selectorCreator: createSelector,
});

interface GetPaperByStockIdProps {
  workspaceId: string;
  stockId: string;
}

export const getPaperByStockId = createCachedSelector(
  (state: DataState) => getPapers(selectFinancialDataFromData(state)),
  (state: DataState, props: GetPaperByStockIdProps) =>
    getStockById(selectWorkspaceDataFromData(state), {
      id: props.stockId,
      workspaceId: props.workspaceId,
    }),
  (papers, stock) => {
    return stock && stock.paperId
      ? papersSelectors.selectElementByI(papers, stock.paperId)
      : undefined;
    // selectPaperById(papers, stock.paperId) : undefined;
  },
)({
  keySelector: (_state, props: GetPaperByStockIdProps) => props.stockId,
  selectorCreator: createSelector,
});

interface GetPaperByFundIdProps {
  workspaceId: string;
  fundId: string;
}

export const getPaperByFundId = createCachedSelector(
  (state: DataState) => getPapers(selectFinancialDataFromData(state)),
  (state: DataState, props: GetPaperByFundIdProps) =>
    getFundById(selectWorkspaceDataFromData(state), {
      id: props.fundId,
      workspaceId: props.workspaceId,
    }),
  (papers, fund) => {
    return fund && fund.paperId
      ? papersSelectors.selectElementByI(papers, fund.paperId)
      : undefined;
  },
)({
  keySelector: (_state, props: GetPaperByFundIdProps) => props.fundId,
  selectorCreator: createSelector,
});

interface GetPaperByBondIdProps {
  workspaceId: string;
  bondId: string;
}

export const getPaperByBondId = createCachedSelector(
  (state: DataState) => getPapers(selectFinancialDataFromData(state)),
  (state: DataState, props: GetPaperByBondIdProps) =>
    getBondById(selectWorkspaceDataFromData(state), {
      id: props.bondId,
      workspaceId: props.workspaceId,
    }),
  (papers, bond) => {
    return bond && bond.paperId
      ? papersSelectors.selectElementByI(papers, bond.paperId)
      : undefined;
  },
)({
  keySelector: (_state, props: GetPaperByBondIdProps) => props.bondId,
  selectorCreator: createSelector,
});
