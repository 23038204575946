import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { selectPortfolio } from '~src/data/store/modules/portfolios/portfolios/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getPortfolioById,
  getPortfoliosByWorkspaceId,
  getSelectedPortfolio,
} from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
// npm install --save @mui/x-tree-view
// import { TreeView } from '@mui/x-tree-view'
import { createNode, Node, traverseDepthFirst } from '@pladdenico/common';

interface PropsPortfolioSelect {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
}

const useStyles = makeStyles()({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 400,
  },
});

function findChildren(portfolios: PortfolioState[], portfolio: PortfolioState) {
  return portfolios.filter((p) => p.parentPortfolioId === portfolio.id);
}

export const PortfolioSelectComponent = React.memo(
  (props: PropsPortfolioSelect) => {
    const { portfolioProject, workspace } = props;
    const portfolio = useSelector((state: RootState) =>
      getPortfolioById(selectWorkspaceData(state), {
        id: portfolioProject.portfolioId,
        workspaceId: workspace.id,
      }),
    );
    if (portfolio) {
      return (
        <PortfolioSelectComponentWithPortfolio
          {...props}
          portfolio={portfolio}
        />
      );
    }
    return <ProgressComponent />;
  },
);

interface PropsPortfolioSelectWithPortfolio extends PropsPortfolioSelect {
  portfolio: PortfolioState;
}

export const PortfolioSelectComponentWithPortfolio = React.memo(
  (props: PropsPortfolioSelectWithPortfolio) => {
    const { portfolio } = props;
    const { classes } = useStyles();
    const portfolios = useSelector((state: RootState) =>
      getPortfoliosByWorkspaceId(
        selectWorkspaceData(state),
        props.workspace.id,
      ),
    );
    const selectedPortfolio = useSelector((state: RootState) =>
      getSelectedPortfolio(selectWorkspaceData(state), props.workspace.id),
    );
    const [portfolioTree, setPortfolioTree] =
      React.useState<Node<PortfolioState>>();

    const [expanded, setExpanded] = React.useState<string[]>(() => {
      const ret = [portfolio.id];
      if (selectedPortfolio) {
        if (selectedPortfolio.parentPortfolioId) {
          ret.push(selectedPortfolio.parentPortfolioId);
        }
        ret.push(selectedPortfolio.id);
      }
      return ret;
    });
    const [selected, setSelected] = React.useState<string>(() => {
      return selectedPortfolio ? selectedPortfolio.id : '';
    });

    const dispatch = useDispatch<AppDispatch>();

    const handleToggle = (
      _event: React.ChangeEvent<unknown>,
      nodeIds: string[],
    ) => {
      setExpanded(nodeIds);
    };

    const handleSelect = (
      _event: React.ChangeEvent<unknown>,
      nodeId: string,
    ) => {
      setSelected(nodeId);
    };

    React.useEffect(() => {
      const selectedPortfolio = portfolios.find((p) => p.id === selected);
      if (selectedPortfolio) {
        dispatch(selectPortfolio(selectedPortfolio.id));
      }
    }, [selected, portfolios, dispatch]);

    React.useEffect(() => {
      setPortfolioTree(createNode(portfolios, portfolio, findChildren));
    }, [portfolios, portfolio]);

    const treeItemElements = portfolioTree
      ? traverseDepthFirst(
          portfolioTree,
          (
            portfolio: PortfolioState,
            childElements: React.ReactElement<any>[],
          ) => {
            return React.createElement(
              TreeItem,
              { nodeId: portfolio.id, label: portfolio.name },
              ...childElements,
            );
          },
        )
      : [];

    return (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {treeItemElements}
      </TreeView>
    );
  },
);
