import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getExchangeRateState } from '~src/data/store/selectors/finance/exchange-rates/selectors';
import {
  ValueBase,
  ValuationType,
} from '~src/data/store/selectors/finance/valuation/valuation-types';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

interface Props {
  workspaceId: string;
  holding: HoldingState;
  currency: CurrencyState;
}

export const getBaseValuations = createCachedSelector(
  (state: DataState, props: Props) =>
    getValuationsByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.holding,
  (valuations, exchangeRateState, currency, holding): ValueBase[] => {
    return valuations.map((valuation) => {
      const exchangeRate = convert(
        exchangeRateState,
        currency.id,
        valuation.currencyId,
        valuation.date,
      );
      return {
        id: valuation.id,
        type: ValuationType.base,
        date: valuation.date,
        holdingId: holding.id,
        valuation,
        currencyId: currency.id,
        exchangeRate,
      };
    });
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holding.id}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});
