import { combineReducers } from 'redux';
import {
  budgetsReducer,
  initialBudgetsState,
} from '~src/data/store/reducers/workspace/projects/scenario/budgets/reducer';
import {
  forecastInitialState,
  forecastReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/forecasts/reducer';
import {
  initialScenariosState,
  scenariosReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/scenarios/reducer';
import {
  initialTransactionsState,
  transactionsReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/transactions/reducer';
import {
  initialTransfersState,
  transfersReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/transfers/reducer';
import {
  initialValuationsState,
  valuationsReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/valuations/reducer';
import {
  initialVariablesState,
  variablesReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/variables/reducer';

export const scenarioInitialState = {
  scenarios: initialScenariosState,
  budgets: initialBudgetsState,
  transactions: initialTransactionsState,
  variables: initialVariablesState,
  transfers: initialTransfersState,
  valuations: initialValuationsState,
  forecasts: forecastInitialState,
};

export const scenarioReducer = combineReducers({
  scenarios: scenariosReducer,
  budgets: budgetsReducer,
  transactions: transactionsReducer,
  variables: variablesReducer,
  transfers: transfersReducer,
  valuations: valuationsReducer,
  forecasts: forecastReducer,
});
