import createCachedSelector, { FlatMapCache } from 're-reselect';
import {
  ReportState,
  selectors,
} from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { createSelector } from 'reselect';

const getReportState = (state: WorkspaceDataState) =>
  state.workspace.project.report.reports;

interface Id {
  projectId: string;
  id: string;
}

export const getReportById = createCachedSelector(
  (state: WorkspaceDataState, _id: Id) => getReportState(state),
  (_state: WorkspaceDataState, id: Id) => id,
  (reports, id): ReportState | undefined => {
    return selectors.selectElementByT(reports, id);
  },
)({
  keySelector: (_state, id) => `${id.projectId}:${id.id}`,
  selectorCreator: createSelector,
});

interface Ids {
  projectId: string;
  ids: string[];
}

export const getReportsByIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: Ids) => getReportState(state),
  (_state: WorkspaceDataState, ids: Ids) => ids,
  (reports, ids): ReportState[] => {
    return selectors.selectElementsByTs(
      reports,
      ids.ids.map((id) => {
        return { projectId: ids.projectId, id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
interface ProjectId {
  projectId: string;
}

export const getReportsByProjectId = createCachedSelector(
  (state: WorkspaceDataState, _projectId: ProjectId) => getReportState(state),
  (_state: WorkspaceDataState, projectId: ProjectId) => projectId.projectId,
  (reports, projectId): ReportState[] | undefined => {
    return selectors.selectElementsByKey(reports, projectId);
  },
)({
  keySelector: (_state, projectId) => projectId.projectId,
  selectorCreator: createSelector,
});
