import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseHoldingId {
  projectId: string;
  id: string;
}

export type HoldingState = Omit<graphqlWorkspaceTypes.HoldingObj, 'project'> & {
  projectId: string;
};

export const initialHoldingState = [];

const selectKeyId = (holding: BaseHoldingId) => holding.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseHoldingId, b: BaseHoldingId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseHoldingId, HoldingState, string>(
  initialHoldingState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const holdingsSlice = createBaseRecordSlice('holdings', adapter);
export const holdingSelectors = adapter.selectors;

export const holdingsReducer = holdingsSlice.reducer;
export const holdingsActions = holdingsSlice.actions;
