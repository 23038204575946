import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { Adapter } from '~src/services/xlsx/handlers/adapters/adapter';
import { SelectAdapter } from '~src/domain/workspace/components/project/transaction/importer/select-adapter';
import { DnbTransactionImporterComponent } from '~src/domain/workspace/components/project/transaction/importer/adapter/dnb/importer.component';
import { VpsTransactionImporterComponent } from '~src/domain/workspace/components/project/transaction/importer/adapter/vps/importer.component';
import { ImportedTransactionsComponent } from '~src/domain/workspace/components/data/adapters/imported-transactions.component';
import { NordnetTransactionImporterComponent } from '~src/domain/workspace/components/project/transaction/importer/adapter/nordnet/importer.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  open: boolean;
  handleClose: () => void;
}

export const TransactionImporterComponent = React.memo((props: Props) => {
  const { handleClose, open, workspace, projectId } = props;
  const [adapter, setAdapter] = React.useState<Adapter>();

  const importerElement = React.useMemo(() => {
    if (adapter?.id === 'dnb') {
      return (
        <DnbTransactionImporterComponent
          workspace={workspace}
          projectId={projectId}
        />
      );
    } else if (adapter?.id === 'vps') {
      return (
        <VpsTransactionImporterComponent
          workspace={workspace}
          projectId={projectId}
        />
      );
    } else if (adapter?.id === 'nordnet') {
      return (
        <NordnetTransactionImporterComponent
          workspace={workspace}
          projectId={projectId}
        />
      );
    }
  }, [adapter, workspace, projectId]);

  return (
    <DialogComponent
      dialogProps={{
        open,
        onClose: handleClose,
        fullWidth: true,
        maxWidth: 'lg',
      }}
    >
      <DialogContentComponent
        handleClose={handleClose}
        title="Import transactions"
      >
        <SelectAdapter
          adapter={adapter}
          handleValue={setAdapter}
          label="Adapter"
        />
        {importerElement}
        <ImportedTransactionsComponent
          workspace={workspace}
          projectId={projectId}
        />
      </DialogContentComponent>
    </DialogComponent>
  );
});
