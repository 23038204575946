import { parseRole } from '~src/data/store/modules/people/roles/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  rolesActions,
  RoleState,
} from '~src/data/store/reducers/person/roles/reducer';
import { Role } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface RoleVisitor {
  visit(role: RoleVisitable): RoleState;
  post(): void;
}

export class RoleVisitable implements Visitable<RoleVisitor> {
  constructor(
    private _workspaceId: string,
    private _personId: string,
    private _role: Role,
  ) {}
  public accept(visitor: RoleVisitor) {
    return visitor.visit(this);
  }

  public parse(): RoleState {
    return parseRole(this._workspaceId, this._role);
  }
}
export class RoleStateVisitor implements RoleVisitor {
  private _roles: RoleState[];
  constructor(private _dispatch: AppDispatch) {
    this._roles = [];
  }

  public visit(role: RoleVisitable): RoleState {
    const roleState = role.parse();
    this._roles.push(roleState);
    return roleState;
  }

  post() {
    this._dispatch(rolesActions.upsertManyElements(this._roles));
  }
}
