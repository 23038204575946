import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';

import { strcmp } from '@pladdenico/common';

export interface BaseWorkspaceInvitationId {
  id: string;
}

export type WorkspaceInvitationState = Omit<
  graphqlTenantTypes.WorkspaceInvitation,
  'workspaceRole'
> & {
  tenantId: string;
  workspaceRoleId: number;
};

export function workspaceInvitationToWorkspaceInvitationState(
  workspaceInvitation: WorkspaceInvitationState,
) {
  return workspaceInvitation;
}
export const workspaceInvitationsInitialState = [];

const tComparator = (
  a: BaseWorkspaceInvitationId,
  b: BaseWorkspaceInvitationId,
) => strcmp(a.id, b.id);

const adapter = new EntityArrayAdapter<
  BaseWorkspaceInvitationId,
  WorkspaceInvitationState
>(workspaceInvitationsInitialState, tComparator, (_a, b) => b);

export const workspaceInvitationsSlice = createBaseArraySlice(
  'workspaceInvitations',
  adapter,
);
export const workspaceInvitationSelectors = adapter.selectors;

export const workspaceInvitationsReducer = workspaceInvitationsSlice.reducer;
export const workspaceInvitationsActions = workspaceInvitationsSlice.actions;
