import { sortedIndexBy } from 'lodash';
import moment from 'moment';
import numbro from 'numbro';
import {
  // IFavoriteHolding,
  Quote,
  Trade,
} from '@pladdenico/models';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { HoldingProps } from '~src/models/interfaces/iholding-props';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';
import { selectFavoriteHoldingsByProjectId } from '~src/data/store/selectors/holding/favorite-holding/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { getHoldingsByIdsForProject } from '~src/data/store/selectors/holding/holdings/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

// import { compact } from 'lodash';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { HoldingsActionsComponent } from './holdings-actions.component';

interface Props {
  date: moment.Moment;
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
}

// interface HoldingWithFavoriteHolding {
//   holding: HoldingProps;
//   favoriteHolding: IFavoriteHolding;
// }

// const createFavoriteHoldings = (
//   holdings: HoldingState[],
//   favoriteHoldings: IFavoriteHolding[]
// ): HoldingWithFavoriteHolding[] => {
//   return __OLDintersectionMergeSorted(
//     holdings.holdings,
//     favoriteHoldings,
//     (h) => h.holding.holding.id,
//     (fh) => fh.holdingId,
//     (h, fh) => {
//       return {
//         holding: h.holding,
//         favoriteHolding: fh,
//       };
//     }
//   );
// };

export const FavoriteHoldingsComponent = React.memo((props: Props) => {
  const favoriteHoldings = useSelector((state: RootState) =>
    selectFavoriteHoldingsByProjectId(
      selectWorkspaceData(state),
      props.project.id,
    ),
  );

  const holdingIds = React.useMemo(
    () => favoriteHoldings.map((h) => h.holdingId),
    [favoriteHoldings],
  );
  const holdings = useSelector((state: RootState) =>
    getHoldingsByIdsForProject(selectWorkspaceData(state), {
      ids: holdingIds,
      projectId: props.project.id,
    }),
  );

  // const [favoriteHoldings, setFavoriteHoldings] = React.useState<
  //   HoldingWithFavoriteHolding[]
  // >(createFavoriteHoldings(props.holdings, favoriteHoldingForPortfolio));
  // const [prevFavoriteHoldings, setPrevFavoriteHoldings] = React.useState(
  //   favoriteHoldings
  // );
  // const dispatch = useDispatch<AppDispatch>();

  // React.useEffect(() => {
  //   const favoriteHoldings = createFavoriteHoldings(
  //     props.holdings,
  //     favoriteHoldingForPortfolio
  //   );
  //   setFavoriteHoldings(favoriteHoldings);
  // }, [props.date, props.holdings, favoriteHoldingForPortfolio]);

  // React.useEffect(() => {
  //   const newFavoriteHoldings = __OLDsortedDifferenceBy(
  //     favoriteHoldings,
  //     prevFavoriteHoldings.map((fh) => fh.holding.holding.id),
  //     (a) => a.holding.holding.id
  //   );
  //   function fetchPapers(holdings: HoldingWithFavoriteHolding[], date: Date) {
  //     const symbol = ''; // holding.asset.symbol;
  //     const stockExchange = ''; // holding.asset.stockExchange;
  //     const papers = holdings
  //       .filter((_holding) => stockExchange)
  //       .map((_holding) => {
  //         return {
  //           paper: symbol,
  //           exchange: stockExchange,
  //         };
  //       });

  //     const fromDate = moment().add(-1, 'year').toDate();
  //     const toDate = date;

  //     papers.forEach((paper) => {
  //       dispatch(fetchQuotes(paper, fromDate, toDate));
  //       dispatch(fetchLastPaperTrades(paper));
  //     });
  //   }

  //   fetchPapers(newFavoriteHoldings, props.date.toDate());
  //   setPrevFavoriteHoldings(favoriteHoldings);
  // }, [favoriteHoldings, prevFavoriteHoldings, props.date, dispatch]);

  // fetchPapers(favoriteHoldings, props.date.toDate());

  const quoteElements = holdings.map((holding) => {
    const quotes: Quote[] = [];
    const trades: Trade[] = [];
    let quote;
    if (quotes && quotes.length > 0) {
      const quoteIdx = sortedIndexBy(
        quotes,
        { date: props.date.toDate() } as any,
        (quote) => quote.date.getTime(),
      );
      quote = quotes[Math.max(0, quoteIdx - 1)];
    }

    let trade;
    if (trades && trades.length > 0) {
      const tradeIdx = sortedIndexBy(
        trades,
        { time: moment(props.date).add(1, 'day').toDate() } as any,
        (trade) => trade.time.getTime(),
      );
      trade = trades[Math.max(0, tradeIdx - 1)];
    }

    return (
      <TableRow key={holding.id}>
        <TableCell>
          <Link
            to={holdingBasePath(
              props.workspace.tenantId,
              props.workspace.id,
              props.project.id,
              holding.id,
            )}
          >
            {holding.name}
          </Link>
        </TableCell>
        <TableCell>
          {quote ? moment(quote.date).format('DD/MM/YYYY') : undefined}
        </TableCell>
        <TableCell>
          {quote
            ? numbro(quote.value).format({
                thousandSeparated: true,
                mantissa: 4,
                optionalMantissa: true,
              })
            : undefined}
        </TableCell>
        <TableCell>{quote ? quote.volume : undefined}</TableCell>
        {/* <Table.Cell>{quote.exch}</Table.Cell> */}
        <TableCell>
          {quote
            ? numbro(quote.open).format({
                thousandSeparated: true,
                mantissa: 4,
                optionalMantissa: true,
              })
            : undefined}
        </TableCell>
        <TableCell>
          {quote
            ? numbro(quote.close).format({
                thousandSeparated: true,
                mantissa: 4,
                optionalMantissa: true,
              })
            : undefined}
        </TableCell>
        <TableCell>
          {quote
            ? numbro(quote.low).format({
                thousandSeparated: true,
                mantissa: 4,
                optionalMantissa: true,
              })
            : undefined}
        </TableCell>
        <TableCell>
          {quote
            ? numbro(quote.high).format({
                thousandSeparated: true,
                mantissa: 4,
                optionalMantissa: true,
              })
            : undefined}
        </TableCell>
        <TableCell>
          {trade ? moment(trade.time).format('DD/MM/YYYY HH:mm:ss') : undefined}
        </TableCell>
        <TableCell>
          {trade
            ? numbro(trade.price).format({
                thousandSeparated: true,
                mantissa: 4,
                optionalMantissa: true,
              })
            : undefined}
        </TableCell>
        <TableCell>{trade ? trade.quantity : undefined}</TableCell>
        <TableCell>{trade ? trade.buyer : undefined}</TableCell>
        <TableCell>{trade ? trade.seller : undefined}</TableCell>
        <TableCell>
          <HoldingsActionsComponent
            tenantId={props.workspace.tenantId}
            workspaceId={props.workspace.id}
            projectId={props.project.id}
            holding={holding}
            favoriteHolding={favoriteHoldings.find(
              (fh) => fh.holdingId === holding.id,
            )}
          />
        </TableCell>
      </TableRow>
    );
  });

  // return (
  //   <div style={{overflowX: 'auto', overflowY: 'visible' }}>
  //     <Table collapsing={true} basic="very" unstackable={true}>
  //       <Table.Header>
  //         <Table.Row>
  //           <Table.HeaderCell>Holding</Table.HeaderCell>
  //           <Table.HeaderCell>Date</Table.HeaderCell>
  //           <Table.HeaderCell>Value</Table.HeaderCell>
  //           <Table.HeaderCell>Volume</Table.HeaderCell>
  //           <Table.HeaderCell>Open</Table.HeaderCell>
  //           <Table.HeaderCell>Close</Table.HeaderCell>
  //           <Table.HeaderCell>Low</Table.HeaderCell>
  //           <Table.HeaderCell>High</Table.HeaderCell>
  //           <Table.HeaderCell>Time</Table.HeaderCell>
  //           <Table.HeaderCell>Price</Table.HeaderCell>
  //           <Table.HeaderCell>Volume</Table.HeaderCell>
  //           <Table.HeaderCell>Buyer</Table.HeaderCell>
  //           <Table.HeaderCell>Seller</Table.HeaderCell>
  //           <Table.HeaderCell/>
  //         </Table.Row>
  //       </Table.Header>
  //       <Table.Body>
  //         {quoteElements}
  //       </Table.Body>
  //     </Table>
  //   </div>
  // );

  return (
    <Box p={1}>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        Favorites
      </Typography>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <TableContainer>
          <Table aria-label="favoriteHoldingsTable">
            <TableHead>
              <TableRow>
                <TableCell>Holding</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell align="right">Volume</TableCell>
                <TableCell align="right">Open</TableCell>
                <TableCell align="right">Close</TableCell>
                <TableCell align="right">Low</TableCell>
                <TableCell align="right">High</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Volume</TableCell>
                <TableCell align="right">Buyer</TableCell>
                <TableCell align="right">Seller</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{quoteElements}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
});
