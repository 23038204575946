import { parsePaper } from '~src/data/store/modules/finance/papers/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  papersActions,
  PaperState,
} from '~src/data/store/reducers/finance/paper/reducer';
import { Paper } from '~src/services/graphql/finance/client/graphql';
import { AppDispatch } from '~src/store/store';

interface PaperVisitor {
  visit(papers: PaperVisitable): PaperState;
  post(): void;
}

export class PaperVisitable implements Visitable<PaperVisitor> {
  constructor(private _paper: Paper) {}
  public accept(visitor: PaperVisitor) {
    return visitor.visit(this);
  }

  public parse(): PaperState {
    return parsePaper(this._paper);
  }
}

export class PaperStateVisitor implements PaperVisitor {
  private _papers: PaperState[];
  constructor(private _dispatch: AppDispatch) {
    this._papers = [];
  }
  public visit(papers: PaperVisitable): PaperState {
    const papersState = papers.parse();
    this._papers.push(papersState);
    return papersState;
  }
  post() {
    this._dispatch(
      papersActions.upsertManyElements(this._papers, {
        shouldAutobatch: true,
      }),
    );
  }
}
