import React from 'react';
import * as uuid from 'uuid';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { useQuoteActions } from '~src/hooks/actions/finance/use-quote-actions';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';

import { QuoteDialogComponent } from './dialog.component';
import { IQI, QuoteFieldsComponent } from './fields.component';

interface Props {
  paper: PaperState;
  open: boolean;
  handleClose: () => void;
}

export const CreateQuoteComponent = React.memo((props: Props) => {
  const [quoteInput, setQuoteInput] =
    React.useState<graphqlFinanceTypes.CreateQuoteInputType>({
      id: '',
      close: 0,
      paperId: props.paper.id,
      date: new Date(),
      high: 0,
      low: 0,
      open: 0,
      value: 0,
      volume: 0,
    });
  // const [quoteDataInput, setQuoteDataInput] = React.useState<
  //   QuoteDataInput
  // >(undefined);
  const { onCreate } = useQuoteActions();

  const setQuoteInputTyped = (quoteInput: IQI) => {
    setQuoteInput({
      ...quoteInput,
      close: quoteInput.close ?? 0,
      open: quoteInput.open ?? 0,
      low: quoteInput.low ?? 0,
      high: quoteInput.high ?? 0,
      date: quoteInput.date ?? 0,
      value: quoteInput.value ?? 0,
      volume: quoteInput.volume ?? 0,
    });
  };

  return (
    <QuoteDialogComponent
      title={'Create quote'}
      open={props.open}
      handleClose={props.handleClose}
      handleSaveQuote={() => {
        quoteInput.id = uuid.v1();
        return onCreate(quoteInput).then(() => props.handleClose());
      }}
    >
      {/* {content} */}
      <form>
        <QuoteFieldsComponent
          hasIdField={false}
          quoteInput={quoteInput}
          setQuoteInput={setQuoteInputTyped}
        />
      </form>
    </QuoteDialogComponent>
  );
});
