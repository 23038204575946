import { Operation } from '@pladdenico/portfolio-api';
import { parseStockPosition } from '~src/data/store/modules/holdings/stock/position/parser';
import { subscribeToStockPositions } from '~src/data/store/modules/holdings/stock/position/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  stockPositionsActions,
  StockPositionState,
} from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { StockPosition } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface StockPositionVisitor {
  visit(position: StockPositionVisitable): StockPositionState;
  post(): void;
}

export class StockPositionVisitable implements Visitable<StockPositionVisitor> {
  constructor(private _position: StockPosition) {}
  public accept(visitor: StockPositionVisitor) {
    return visitor.visit(this);
  }

  public parse(): StockPositionState {
    return parseStockPosition(this._position);
  }
}

export class StockPositionStateVisitor implements StockPositionVisitor {
  private _stockPositions: StockPositionState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._stockPositions = [];
  }

  public visit(position: StockPositionVisitable): StockPositionState {
    // this._stockPositions.push(position.parse());
    const positionState = position.parse();
    this._stockPositions.push(positionState);
    return positionState;
  }

  post() {
    this._dispatch(
      stockPositionsActions.upsertManyElements(this._stockPositions, {
        shouldAutobatch: true,
      }),
    );
    subscribeToStockPositions(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._stockPositions,
      this._subscriptions,
    );
  }
}
