import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceMembersRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/members`;
};
export const workspaceMemberRootPath = (
  tenantId: string,
  workspaceId: string,
  memberId: number,
) => {
  return `${workspaceMembersRootPath(tenantId, workspaceId)}/${memberId}`;
};

export const workspaceMembersBasePath = workspaceMembersRootPath(
  tenantBaseId,
  workspaceBaseId,
);
