import React from 'react';
import { PortfolioAsset } from '~src/domain/workspace/components/finance/tools/pareto/covariances';

interface Props {}

interface Props {
  portfolioAssets: PortfolioAsset[];
}

export const useInputVectors = (props: Props) => {
  const { portfolioAssets } = props;

  const [expectedReturns, setExpectedReturns] = React.useState(
    portfolioAssets.map((a) => a.expectedReturn),
  );

  const [lowerBounds, setLowerBounds] = React.useState([
    0.15, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ]);

  const [upperBounds, setUpperBounds] = React.useState([
    1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
  ]);

  const handleInputVectorsChange = (
    row: number,
    col: number,
    value: number,
  ) => {
    if (row === 0) {
      setExpectedReturns((expectedReturns) => {
        const newExpectedReturn = [...expectedReturns];
        newExpectedReturn[col * portfolioAssets.length] = value;
        return newExpectedReturn;
      });
    } else if (row === 1) {
      setLowerBounds((lowerBounds) => {
        const newLowerBounds = [...lowerBounds];
        newLowerBounds[col * portfolioAssets.length] = value;
        return newLowerBounds;
      });
    } else if (row === 2) {
      setUpperBounds((lowerBounds) => {
        const newUpperBounds = [...lowerBounds];
        newUpperBounds[col * portfolioAssets.length] = value;
        return newUpperBounds;
      });
    }
  };

  return {
    expectedReturns,
    lowerBounds,
    upperBounds,
    handleInputVectorsChange,
  };
};
