import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createFunds } from '~src/data/store/modules/assets/funds/requests';
import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputFundData } from '~src/domain/workspace/components/asset/types/funds/form/fund-input.data';
import { useFundFields } from '~src/domain/workspace/components/asset/types/funds/form/use-fund-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: InputFundData;
  callbacks?: {
    onSuccess?: (funds: FundState) => void;
    onFailure?: (error: any) => void;
  };
}

export const useCreateFundForm = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (assetId: string, data: InputFundData) => {
      return dispatch(
        createFunds(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            assetId: assetId,
            paperId: data.paperId,
          },
        ]),
      )
        .then((funds) => {
          const fund = funds[0];
          callbacks?.onSuccess && callbacks.onSuccess(fund);
          return fund;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<InputFundData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useFundFields(control);

  const onSubmit = React.useCallback(
    (
      assetId: string,
      successCallback: (fund: FundState) => Promise<void>,
    ): SubmitHandler<InputFundData> =>
      (data) => {
        return onSave(assetId, data).then((fund) => successCallback(fund));
      },
    [onSave],
  );

  const submit = React.useCallback(
    (assetId: string, successCallback: (fund: FundState) => Promise<void>) => {
      return handleSubmit(onSubmit(assetId, successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
