import { bondPositionsActions } from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleBondPositions } from './handler';

export function fetchBondPositions(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetBondPositionsDocument;
    const variables: graphqlWorkspaceTypes.GetBondPositionsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getBondPositions) {
        return handleBondPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.getBondPositions,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createBondPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateBondPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateBondPositionsDocument;
    const variables: graphqlWorkspaceTypes.CreateBondPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createBondPositions) {
        return handleBondPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.createBondPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateBondPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateBondPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateBondPositionsDocument;
    const variables: graphqlWorkspaceTypes.UpdateBondPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateBondPositions) {
        return handleBondPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.updateBondPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertBondPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertBondPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertBondPositionsDocument;
    const variables: graphqlWorkspaceTypes.UpsertBondPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertBondPositions) {
        return handleBondPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertBondPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteBondPositions(
  bondHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      bondPositionsActions.removeManyElements(
        ids.map((id) => {
          return {
            bondHoldingId,
            id,
          };
        }),
      ),
    );
  };
}

export function deleteBondPositions(
  tenantId: string,
  workspaceId: string,
  bondHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteBondPositionsDocument;
    const variables: graphqlWorkspaceTypes.DeleteBondPositionsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteBondPositions) {
        dispatch(
          handleDeleteBondPositions(bondHoldingId, data.deleteBondPositions),
        );
      }
      return [];
    });
  };
}
