import { Control } from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputWarrantData } from '~src/domain/workspace/components/asset/types/warrants/form/warrant-input.data';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useWarrantFields = (
  _workspace: WorkspaceState,
  control: Control<InputWarrantData, any>,
) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const descriptionField = useTextField(
    control,
    'description',
    'Description',
    '',
  );
  return {
    nameField,
    descriptionField,
  };
};
