import { DataImportDataState } from '~src/data/store/reducers/reducers';
import { nordnetTransactionsSelectors } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';

import { createSelector } from '@reduxjs/toolkit';

export const selectNordnetTransactions = (state: DataImportDataState) => {
  return state.adapter.nordnet.nordnetTransactions;
};

export const getNordnetTransactions = createSelector(
  (state: DataImportDataState, _id: string | null) =>
    selectNordnetTransactions(state),
  (_state: DataImportDataState, id: string | null) => id,
  (transactions, id) => {
    if (id !== null) {
      return nordnetTransactionsSelectors.selectElementsByKey(transactions, id);
    }
    return nordnetTransactionsSelectors.selectAllElements(transactions);
  },
);

export const getNordnetAccounts = createSelector(
  (state: DataImportDataState) => selectNordnetTransactions(state),
  (transactions) => {
    return nordnetTransactionsSelectors
      .selectAllRecords(transactions)
      .map((v) => v.id);
  },
);
