import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertFunds } from '~src/data/store/modules/assets/funds/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'funds';

export interface Fund {
  id: string;
  assetId: string;
  fundExchange?: Maybe<string>;
  symbol?: Maybe<string>;
  paperId?: Maybe<string>;
}

const header: Array<keyof Fund> = [
  'id',
  'assetId',
  'fundExchange',
  'symbol',
  'paperId',
];

export const fundExportHandler = (data: Fund[]): ExportHandler<Fund> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const fundImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Fund> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertFunds(workspace.tenantId, workspace.id, ts)),
  };
};
