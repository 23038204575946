import React from 'react';
import { useSelector } from 'react-redux';
import { VolatilityComponent } from '~src/components/summary/volatility.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsSTDDEV } from '~src/data/store/selectors/finance/stddev/holdings-stddev';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';
import { Period } from '~src/utils/period/period';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  period: Period;
  baseCurrency: CurrencyState;
  useLiveValue: boolean;
  subLabel?: string;
}

export const VolatilityHoldingsComponent = React.memo((props: Props) => {
  // useComponentUpdateDebug('SummaryComponent', props);
  const { holdings, period, projectId, useLiveValue, workspaceId, subLabel } =
    props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const volatility = useSelector((state: RootState) =>
    getHoldingsSTDDEV(selectData(state), {
      workspaceId,
      projectId,
      holdings,
      period,
      filter,
      useLiveValue,
    }),
  );
  return <VolatilityComponent volatility={volatility} subLabel={subLabel} />;
});
