import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';

export function parseRegionAllocation(
  entityId: string,
  regionAllocation: graphqlWorkspaceTypes.RegionAllocation,
): RegionAllocationState {
  return {
    id: regionAllocation.id,
    entityId,
    regionId: regionAllocation.region?.id,
    allocation: regionAllocation.allocation,
    date: regionAllocation.date,
  };
}

export function parseRegionAllocations(
  entityId: string,
  regionAllocations:
    | graphqlWorkspaceTypes.RegionAllocation[]
    | null
    | undefined,
) {
  return regionAllocations
    ? regionAllocations.map((regionAllocation) =>
        parseRegionAllocation(entityId, regionAllocation),
      )
    : [];
}
