// import data from './data.json';
import { isEqual, uniq } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { useHoldingsAlgorithm } from '~src/components/fields/workspace/holding/algorithm/use-algorithm';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsByIds } from '~src/data/store/selectors/holding/holdings/selectors';
import { getPeopleByWorkspaceId } from '~src/data/store/selectors/person/person/selectors';
import { getRolesByPeopleIds } from '~src/data/store/selectors/person/role/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { usePeopleAlgorithm } from '~src/domain/workspace/components/people/algorithm/use-algorithm';
import {
  Node,
  peopleGraph,
  PeopleGraphData,
} from '~src/domain/workspace/components/people/people-graph';
import { PersonComponent } from '~src/domain/workspace/components/people/person.component';
import { HoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/holding-card.component';
import { usePersonActions } from '~src/hooks/actions/workspace/person/use-person-actions';

import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { strcmp, uniqSorted } from '@pladdenico/common';
import { TableComponent, TableOperations } from '@pladdenico/table';

import { PeopleToolbar } from './people-toolbar.component';

interface Props {
  workspace: WorkspaceState;
}

export type PersonData = PersonState;

export const PeopleComponent = memo((props: Props) => {
  const { workspace } = props;
  const people = useSelector((state: RootState) =>
    getPeopleByWorkspaceId(selectWorkspaceData(state), workspace.id),
  );

  const { onUpdate, onDelete } = usePersonActions(workspace);

  const { fields, algoData: personAlgoData } = usePeopleAlgorithm(
    workspace.tenantId,
    people,
  );

  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(personAlgoData.filtered);

  const peopleIds = React.useMemo(() => {
    return people.map((person) => person.id);
  }, [people]);

  const roles = useSelector((state: RootState) => {
    return getRolesByPeopleIds(selectWorkspaceData(state), peopleIds);
  });

  const holdingIds = React.useMemo(() => {
    return uniqSorted(
      roles
        .map((role) => {
          return {
            id: role.holdingId,
            projectId: role.projectId,
          };
        })
        .sort((a, b) => strcmp(a.id, b.id)),
      [(a, b) => strcmp(a.id, b.id)],
    );
  }, [roles]);

  const holdings = useSelector((state: RootState) => {
    return getHoldingsByIds(selectWorkspaceData(state), holdingIds);
  });
  const data = React.useMemo((): PeopleGraphData => {
    const data: PeopleGraphData = {
      links: [],
      nodes: [],
    };
    holdings.forEach((holding) => {
      data.nodes.push({
        group: 'holding',
        id: holding.id,
        name: holding.name ?? '',
      });
    });
    people.forEach((person) => {
      const personRoles = roles.filter((role) => role.personId === person.id);
      data.nodes.push({
        group: 'people',
        name: person.name ?? '',
        id: person.id,
      });
      personRoles.forEach((role) => {
        data.links.push({
          id: uuid.v1(),
          source: person.id,
          target: role.holdingId,
          value: 1,
          description: role.name ?? '',
        });
      });
    });

    // for (let i = 1; i < 100; ++i) {
    //   data.nodes.push({
    //     group: 'holding',
    //     id: `holding-id-${i}`,
    //     name: `holding-name - ${i}`,
    //   });
    //   data.nodes.push({
    //     group: 'people',
    //     name: `person-name-${i}`,
    //     id: `person-id-${i}`,
    //   });
    // }
    // for (let j = 1; j < 100; ++j) {
    //   const sourceId = (j % 99) + 1;
    //   const targetId = ((j * 3) % 91) + 1;
    //   data.links.push({
    //     source: `person-id-${sourceId}`,
    //     target: `holding-id-${targetId}`,
    //     value: 1,
    //     description: `role - ${j}`,
    //     id: uuid.v1(),
    //   });
    // }

    return data;
  }, [holdings, people, roles]);

  const { algoData: holdingAlgoData, holdingsDenormalized } =
    useHoldingsAlgorithm(workspace, holdings);
  const [activeNodeId, setActiveNodeId] = React.useState<{
    nodeId: string;
    group: string;
  } | null>(null);

  const [preFilteredNodes, setPreFilteredNodes] = React.useState<Node[]>([]);
  const [useActiveAsFilter] = React.useState<boolean>(true);
  const [level, setLevel] = React.useState(1);

  React.useEffect(() => {
    let nodes = data.nodes;
    if (useActiveAsFilter && activeNodeId != null) {
      const idx = nodes.findIndex((node) => node.id === activeNodeId.nodeId);
      if (idx !== -1) {
        nodes = [nodes[idx]];
      }
    } else {
      const personFilter = (node: Node) => {
        return (
          node.group !== 'people' ||
          personAlgoData.filtered.some((h) => h.id === node.id)
        );
      };
      const holdingFilter = (node: Node) => {
        return (
          node.group !== 'holding' ||
          holdingAlgoData.filtered.some((h) => h.holding.id === node.id)
        );
      };

      nodes = nodes
        .filter((node) => personFilter(node))
        .filter((node) => holdingFilter(node));
    }
    if (!isEqual(nodes, preFilteredNodes)) {
      setPreFilteredNodes(nodes);
    }
  }, [
    activeNodeId,
    data.nodes,
    holdingAlgoData.filtered,
    personAlgoData.filtered,
    preFilteredNodes,
    useActiveAsFilter,
  ]);

  const filteredData = React.useMemo(() => {
    let newNodeIds = preFilteredNodes.map((n) => n.id);
    let nodeIds: string[] = [...newNodeIds];
    let linkIds: string[] = [];
    for (let i = 0; i < level; ++i) {
      const newLinks = data.links.filter((link) =>
        newNodeIds.some((id) => id === link.source || id === link.target),
      );
      newNodeIds = newLinks.reduce<string[]>((prev, curr) => {
        return [...prev, curr.source as string, curr.target as string];
      }, []);
      nodeIds = uniq([...nodeIds, ...newNodeIds]);
      linkIds = uniq([...linkIds, ...newLinks.map((l) => l.id)]);
    }
    const nodes = data.nodes.filter((node) =>
      nodeIds.some((id) => id === node.id),
    );
    const links = data.links.filter((link) =>
      linkIds.some((id) => id === link.id),
    );
    console.log('people.component: links', links, linkIds);
    return {
      nodes,
      links,
    };
  }, [data.links, data.nodes, level, preFilteredNodes]);

  const graphSizes = React.useMemo(() => {
    const radius = 25;
    const areaForCircles =
      (filteredData.nodes.length + filteredData.links.length) *
      radius *
      radius *
      Math.PI;
    const areaTotal = areaForCircles * 10;
    const sqrtAreaTotal = Math.sqrt(areaTotal);
    const width = Math.max(sqrtAreaTotal * 1.2, 1024);
    const height = Math.max(areaTotal / width, 900);

    console.log(areaForCircles, areaTotal, width, height);
    return {
      radius,
      width,
      height,
    };
  }, [filteredData.links.length, filteredData.nodes.length]);

  const onClick = React.useCallback(
    (nodeId: string, group: string) => {
      setActiveNodeId((activeNodeId) => {
        if (activeNodeId && activeNodeId.nodeId === nodeId) {
          return null;
        } else {
          return { nodeId, group };
        }
      });
    },
    [setActiveNodeId],
  );

  const nodeElement = React.useMemo(() => {
    if (activeNodeId != null) {
      if (activeNodeId.group === 'holding') {
        const node = holdings.find((h) => h.id === activeNodeId.nodeId);
        if (node) {
          const holding = holdingsDenormalized.find(
            (h) => h.holding.id === node.id,
          );
          if (holding) {
            return (
              <div>
                <HoldingCardComponent
                  holding={holding.holding}
                  portfolios={holding.portfolios}
                  workspace={workspace}
                  projectId={holding.holding.projectId}
                />
              </div>
            );
          }
        }
      } else if (activeNodeId.group === 'people') {
        const node = people.find((p) => p.id === activeNodeId.nodeId);
        if (node) {
          return (
            <div>
              <PersonComponent personId={node.id} workspace={workspace} />
            </div>
          );
        }
      }
    }
  }, [activeNodeId, holdings, holdingsDenormalized, people, workspace]);

  const graph = React.useMemo(
    () =>
      peopleGraph(
        // filteredData,
        graphSizes.radius,
        graphSizes.width,
        graphSizes.height,
        onClick,
        // activeNodeId?.nodeId
      ),
    [
      // activeNodeId,
      // filteredData,
      graphSizes.height,
      graphSizes.radius,
      graphSizes.width,
      onClick,
    ],
  );

  React.useEffect(() => {
    graph.update(filteredData.nodes, filteredData.links, activeNodeId?.nodeId);
  }, [activeNodeId, filteredData.links, filteredData.nodes, graph]);

  const svgRef = React.useRef(null);
  React.useEffect(() => {
    // if (svgRef.current && (svgRef.current as any).innerHTML === '') {
    if (svgRef.current) {
      const svg = svgRef.current as any;
      while (svg.firstChild) {
        svg.removeChild(svg.firstChild);
      }
      // (svgRef.current as any).appendChild(graph);
      svg.appendChild(graph.node);
    }
    // if (svgRef.current) {
    //   svgRef.current.appendChild(graph);
    // }
  }, [graph]);

  return (
    <Box>
      <PeopleToolbar
        workspace={workspace}
        personAlgoData={personAlgoData}
        holdingAlgoData={holdingAlgoData}
        level={level}
        setLevel={setLevel}
      />

      <Grid container>
        <Grid xs={8} sx={{ position: 'relative' }}>
          <Box ref={svgRef} width={'100%'} />
          <Button
            sx={{ position: 'absolute', bottom: 8, right: 8 }}
            onClick={graph.center}
          >
            Center
          </Button>
        </Grid>
        <Grid xs={4}>{nodeElement}</Grid>
      </Grid>
      <Box>
        <TableComponent<PersonData>
          data={personAlgoData.filtered}
          fields={[fields.name, fields.description]}
          selectedData={selectedData}
          selectable={false}
          filters={personAlgoData.filters}
          dispatchFilters={personAlgoData.dispatchFilters}
          sorters={personAlgoData.sorters}
          dispatchSorters={personAlgoData.dispatchSorters}
          editable={{ onRowUpdate: onUpdate, onRowDelete: onDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </Box>
    </Box>
  );
});
