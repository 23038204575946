import { RecordState } from '@pladdenico/common';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import {
  StockTradeState,
  stockTradesSelectors as stockTradesSelectors,
  BaseStockTradeId,
} from '../../../../../reducers/holding/holding-types/stock/stock-trade/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

const getStockTradeState = (state: WorkspaceDataState) =>
  state.holding.holding.stockHolding.stockTrades;

export const selectStockTradesByStockHoldingId = createCachedSelector(
  (state: RecordState<StockTradeState, string>[], stockHoldingId: string) =>
    stockTradesSelectors.selectElementsByKey(state, stockHoldingId),
  (stockTrades: StockTradeState[] | undefined): StockTradeState[] => {
    return stockTrades ? stockTrades : [];
  },
)({
  keySelector: (_state, stockHoldingId) => stockHoldingId,
  selectorCreator: createSelector,
});

export const getStockTradesByStockHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getStockTradeState(state),
  (_state: WorkspaceDataState, stockHoldingId: string) => stockHoldingId,
  (stockTradeState, stockHoldingId): StockTradeState[] => {
    return selectStockTradesByStockHoldingId(stockTradeState, stockHoldingId);
  },
)({
  keySelector: (_state, stockHoldingId) => stockHoldingId,
  selectorCreator: createSelector,
});

export const getStockTrades = createSelector(
  (state: WorkspaceDataState) => getStockTradeState(state),
  (stockTrades) => {
    return stockTradesSelectors.selectAllElements(stockTrades);
  },
);

export const getStockTradeById = createCachedSelector(
  (state: WorkspaceDataState) => getStockTradeState(state),
  (_state: WorkspaceDataState, baseId: BaseStockTradeId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseStockTradeId) =>
    baseId.stockHoldingId,
  (stockTrades, id, stockHoldingId): StockTradeState | undefined => {
    return stockTradesSelectors.selectElementByT(stockTrades, {
      id,
      stockHoldingId,
    });
  },
)({
  keySelector: (_state, stockHoldingId, stockTradeId) =>
    `${stockHoldingId}:${stockTradeId}`,
  selectorCreator: createSelector,
});

interface StockTradeTransferId {
  stockHoldingId: string;
  transferId: string;
}

export const getStockTradeByTransferId = createCachedSelector(
  (state: WorkspaceDataState, baseId: StockTradeTransferId) =>
    getStockTradesByStockHoldingId(state, baseId.stockHoldingId),
  (_state: WorkspaceDataState, baseId: StockTradeTransferId) =>
    baseId.transferId,
  (stockTrades, transferId): StockTradeState | undefined => {
    return stockTrades.find((t) => t.transferId === transferId);
  },
)({
  keySelector: (_state, stockHoldingId, stockTradeId) =>
    `${stockHoldingId}:${stockTradeId}`,
  selectorCreator: createSelector,
});

interface StockHoldingMultipleIds {
  stockHoldingId: string;
  ids: string[];
}

export const getStockTradesByIds = createCachedSelector(
  (state: WorkspaceDataState) => getStockTradeState(state),
  (_state: WorkspaceDataState, ids: StockHoldingMultipleIds) =>
    ids.stockHoldingId,
  (_state: WorkspaceDataState, ids: StockHoldingMultipleIds) => ids.ids,
  (stockTrades, stockHoldingId, ids): StockTradeState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        stockHoldingId,
      };
    });
    return stockTradesSelectors.selectElementsByTs(stockTrades, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.stockHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});
