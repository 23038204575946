import {
  BankNoteHoldingStateVisitor,
  BankNoteHoldingVisitable,
} from '~src/data/store/visitors/holding/types/bank-note/bank-note-holding-visitor';
import { BankNoteHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleBankNoteHoldings(
  dispatch: any,
  outputs: BankNoteHolding[],
) {
  const bankNoteHoldingVisitor = new BankNoteHoldingStateVisitor(dispatch);
  const bankNotes = outputs.map((bankNoteHolding) => {
    const visitable = new BankNoteHoldingVisitable(bankNoteHolding);
    return visitable.accept(bankNoteHoldingVisitor);
  });
  bankNoteHoldingVisitor.post();
  return bankNotes;
}
