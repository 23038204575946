import { strcmp } from '@pladdenico/common';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseValuationId {
  holdingId: string;
  id: string;
}

export type ValuationState = Omit<
  graphqlWorkspaceTypes.Valuation,
  'holding' | 'currency'
> & {
  holdingId: string;
  currencyId: string;
};

export const initialValuationsState = [];

const selectKeyId = (valuation: BaseValuationId) => valuation.holdingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseValuationId, b: BaseValuationId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseValuationId,
  ValuationState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const valuationsSlice = createBaseRecordSlice('valuations', adapter);
export const valuationsSelectors = adapter.selectors;

export const valuationsReducer = valuationsSlice.reducer;
export const valuationsActions = valuationsSlice.actions;
