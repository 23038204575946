import { RecordState } from '@pladdenico/common';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { WorkspaceDataState } from '../../../reducers/reducers';
import {
  BaseAddressId,
  AddressState,
  addressesSelectors,
} from '../../../reducers/person/addresses/reducer';

const getAddressState = (state: WorkspaceDataState) => state.person.addresses;

export const selectAddressesByPersonId = createCachedSelector(
  (state: RecordState<AddressState, string>[], personId: string) =>
    addressesSelectors.selectElementsByKey(state, personId),
  (addresses: AddressState[] | undefined): AddressState[] => {
    return addresses ? addresses : [];
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getAddressesByPersonId = createCachedSelector(
  (state: WorkspaceDataState) => getAddressState(state),
  (_state: WorkspaceDataState, personId: string | null) => personId,
  (personState, personId): AddressState[] => {
    if (!personId) {
      return [];
    }
    return selectAddressesByPersonId(personState, personId);
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getAddresses = createSelector(
  (state: WorkspaceDataState) => getAddressState(state),
  (addresses) => {
    return addressesSelectors.selectAllElements(addresses);
  },
);

export const getAddressById = createCachedSelector(
  (state: WorkspaceDataState) => getAddressState(state),
  (_state: WorkspaceDataState, baseId: BaseAddressId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseAddressId) => baseId.personId,
  (addresses, id, personId): AddressState | undefined => {
    return addressesSelectors.selectElementByT(addresses, {
      id,
      personId,
    });
  },
)({
  keySelector: (_state, personBaseId) =>
    `${personBaseId.personId}:${personBaseId.id}`,
  selectorCreator: createSelector,
});
