/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Address = {
  __typename?: 'Address';
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Allocation = {
  __typename?: 'Allocation';
  assetClass?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  id: Scalars['String'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  portfolio?: Maybe<Portfolio>;
  region?: Maybe<Region>;
  sector?: Maybe<Scalars['String']>;
};

export type Asset = {
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
};

export type AssetCategory = {
  __typename?: 'AssetCategory';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type AssetObj = {
  __typename?: 'AssetObj';
  ISIN?: Maybe<Scalars['String']>;
  VPS?: Maybe<Scalars['String']>;
  assetClass?: Maybe<Scalars['String']>;
  category?: Maybe<AssetCategory>;
  description?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  id: Scalars['String'];
  lookup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  types?: Maybe<Array<Scalars['String']>>;
};

export type AssetSnapshotsInputType = {
  assets?: InputMaybe<Array<UpsertAssetInputType>>;
  bankNotes?: InputMaybe<Array<UpsertBankNoteInputType>>;
  bonds?: InputMaybe<Array<UpsertBondInputType>>;
  cryptos?: InputMaybe<Array<UpsertCryptoInputType>>;
  funds?: InputMaybe<Array<UpsertFundInputType>>;
  loans?: InputMaybe<Array<UpsertLoanInputType>>;
  stocks?: InputMaybe<Array<UpsertStockInputType>>;
};

export type BankNote = Asset & {
  __typename?: 'BankNote';
  _type: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
  asset?: Maybe<AssetObj>;
  bank?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type BankNoteHolding = Holding & {
  __typename?: 'BankNoteHolding';
  bankNote?: Maybe<BankNote>;
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  id: Scalars['String'];
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type Bond = Asset & {
  __typename?: 'Bond';
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
  id: Scalars['String'];
  paperId?: Maybe<Scalars['String']>;
};

export type BondHolding = Holding & {
  __typename?: 'BondHolding';
  bond?: Maybe<Bond>;
  bondPositions?: Maybe<Array<BondPosition>>;
  bondTrades?: Maybe<Array<BondTrade>>;
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  id: Scalars['String'];
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type BondHoldingBase = {
  __typename?: 'BondHoldingBase';
  id: Scalars['String'];
};

export type BondPosition = {
  __typename?: 'BondPosition';
  bondHolding?: Maybe<BondHoldingBase>;
  date?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  shares?: Maybe<Scalars['Float']>;
};

export type BondTrade = {
  __typename?: 'BondTrade';
  bondHolding?: Maybe<BondHoldingBase>;
  id: Scalars['String'];
  position?: Maybe<BondPosition>;
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transfer?: Maybe<Transfer>;
};

export type Commitment = {
  __typename?: 'Commitment';
  commitmentDate?: Maybe<Scalars['Date']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  transfers?: Maybe<Array<Transfer>>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CreateAddressInputType = {
  administrativeAreaLevel1?: InputMaybe<Scalars['String']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type CreateAllocationInputType = {
  assetClass?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  portfolioId: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['String']>;
};

export type CreateAssetCategoryInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type CreateAssetInputType = {
  ISIN?: InputMaybe<Scalars['String']>;
  VPS?: InputMaybe<Scalars['String']>;
  assetClass?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
  id: Scalars['String'];
  lookup?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateBankNoteHoldingInputType = {
  bankNoteId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateBankNoteInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type CreateBondHoldingInputType = {
  bondId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateBondInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type CreateBondPositionInputType = {
  bondHoldingId: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['String'];
  shares: Scalars['Float'];
};

export type CreateBondTradeInputType = {
  bondHoldingId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transferId: Scalars['String'];
};

export type CreateCommitmentInputType = {
  commitmentDate?: InputMaybe<Scalars['Date']>;
  currencyId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  transferIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateCryptoHoldingInputType = {
  cryptoId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateCryptoInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
};

export type CreateDashboardElementInputType = {
  config: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateDashboardMediaInputType = {
  dashboardElementId: Scalars['String'];
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateEmailInputType = {
  id: Scalars['String'];
  personId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type CreateEntityInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  regionAllocationRelations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  sector?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateEntityMediaInputType = {
  entityId: Scalars['String'];
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateEventInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  fileIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  holdingIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  portfolioIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  projectId: Scalars['String'];
  transactionIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type CreateFavoriteHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateFileInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateFundHoldingInputType = {
  fundId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type CreateFundInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type CreateFundPositionInputType = {
  date: Scalars['Date'];
  fundHoldingId: Scalars['String'];
  id: Scalars['String'];
  shares: Scalars['Float'];
};

export type CreateFundTradeInputType = {
  fundHoldingId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transferId: Scalars['String'];
};

export type CreateGeneratedReportInputType = {
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  objectKey: Scalars['String'];
  projectId: Scalars['String'];
  reportId?: InputMaybe<Scalars['String']>;
};

export type CreateHoldingConfigInputType = {
  currentDashboardConfigId?: InputMaybe<Scalars['String']>;
  holdingDashboardConfigOperations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type CreateHoldingDashboardConfigInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations: Array<MultipleRelationOperationStringInputType>;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateHoldingInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  type: Scalars['String'];
};

export type CreateLoanHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  loanId: Scalars['String'];
};

export type CreateLoanInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  interest?: InputMaybe<Scalars['Float']>;
};

export type CreatePersonInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type CreatePhoneInputType = {
  id: Scalars['String'];
  personId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type CreatePortfolioConfigInputType = {
  currentDashboardConfigId?: InputMaybe<Scalars['String']>;
  portfolioDashboardConfigOperations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type CreatePortfolioDashboardConfigInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations: Array<MultipleRelationOperationStringInputType>;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreatePortfolioInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingRelations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  parentPortfolioId?: InputMaybe<Scalars['String']>;
  portfoliosIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreatePortfolioProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
  portfolioId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type CreatePrivateEquityFundCapitalCallInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  fundId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreatePrivateEquityFundCapitalCallTransferInputType = {
  capitalCallId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  transferId?: InputMaybe<Scalars['String']>;
};

export type CreatePrivateEquityFundCapitalCommitmentInputType = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreatePrivateEquityFundCapitalDistributionInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreatePrivateEquityFundCapitalDistributionTransferInputType = {
  capitalDistributionId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  transferId?: InputMaybe<Scalars['String']>;
};

export type CreatePrivateEquityFundContactInputType = {
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePrivateEquityFundInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type CreatePrivateEquityFundInvestmentInputType = {
  description: Scalars['String'];
  exit?: InputMaybe<Scalars['Date']>;
  fundId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreatePrivateEquityFundInvestorInputType = {
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePrivateEquityFundProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
  privateEquityFundId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type CreatePrivateEquityFundTeamMemberInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreateProjectInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateProjectReportInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations: Array<MultipleRelationOperationStringInputType>;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  periodEnd?: InputMaybe<Scalars['Date']>;
  periodStart?: InputMaybe<Scalars['Date']>;
  projectId: Scalars['String'];
  published?: InputMaybe<Scalars['Boolean']>;
};

export type CreateRegionAllocationInputType = {
  allocation?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['Date']>;
  entityId: Scalars['String'];
  id: Scalars['String'];
  regionId: Scalars['String'];
};

export type CreateReportInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  schedulerId: Scalars['String'];
  users?: InputMaybe<Array<InputMaybe<MultipleRelationOperationIntInputType>>>;
};

export type CreateRoleInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
};

export type CreateSchedulerInputType = {
  cron: Scalars['String'];
  id: Scalars['String'];
};

export type CreateStockGoalRuleInputType = {
  goalRule: GoalRuleInputType;
  id: Scalars['String'];
  stockHoldingId: Scalars['String'];
};

export type CreateStockHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  stockId: Scalars['String'];
};

export type CreateStockInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type CreateStockPositionInputType = {
  date: Scalars['Date'];
  id: Scalars['String'];
  shares: Scalars['Float'];
  stockHoldingId: Scalars['String'];
};

export type CreateStockTradeInputType = {
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  stockHoldingId: Scalars['String'];
  transferId: Scalars['String'];
};

export type CreateTransactionInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type CreateTransferInputType = {
  commitmentId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  currencyId: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateValuationInputType = {
  currencyId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  ownershipPercentage?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateWarrantHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  warrantId: Scalars['String'];
};

export type CreateWarrantInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type CreateWorkspaceInputType = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Crypto = Asset & {
  __typename?: 'Crypto';
  _type: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
  asset?: Maybe<AssetObj>;
  bank?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type CryptoHolding = Holding & {
  __typename?: 'CryptoHolding';
  commitments?: Maybe<Array<Commitment>>;
  crypto?: Maybe<Crypto>;
  holding: HoldingObj;
  id: Scalars['String'];
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type Currency = {
  __typename?: 'Currency';
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type DashboardElement = {
  __typename?: 'DashboardElement';
  config: Scalars['String'];
  id: Scalars['String'];
  media?: Maybe<DashboardMedia>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type DashboardMedia = {
  __typename?: 'DashboardMedia';
  element?: Maybe<DashboardElement>;
  file?: Maybe<File>;
  id: Scalars['String'];
};

export type DeleteAddressInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteAllocationInputType = {
  id: Scalars['String'];
};

export type DeleteAssetCategoryInputType = {
  id: Scalars['String'];
};

export type DeleteAssetInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteBankNoteHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteBankNoteInputType = {
  id: Scalars['String'];
};

export type DeleteBondHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteBondInputType = {
  id: Scalars['String'];
};

export type DeleteBondPositionInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteBondTradeInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteCommitmentInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteCryptoHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteCryptoInputType = {
  id: Scalars['String'];
};

export type DeleteEmailInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteEntityInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteEntityMediaInputType = {
  id: Scalars['String'];
};

export type DeleteEventInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteFavoriteHoldingInputType = {
  holdingId?: InputMaybe<Scalars['String']>;
};

export type DeleteFileInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteFundHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteFundInputType = {
  id: Scalars['String'];
};

export type DeleteFundPositionInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteFundTradeInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteGeneratedReportInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteLoanHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteLoanInputType = {
  id: Scalars['String'];
};

export type DeletePersonInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeletePhoneInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeletePortfolioConfigInputType = {
  id: Scalars['Int'];
};

export type DeletePortfolioInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeletePortfolioProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeletePrivateEquityFundCapitalCallInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundCapitalCallTransferInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundCapitalCommitmentInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundCapitalDistributionInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundCapitalDistributionTransferInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundContactInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundInvestmentInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundInvestorInputType = {
  id: Scalars['String'];
};

export type DeletePrivateEquityFundProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeletePrivateEquityFundTeamMemberInputType = {
  id: Scalars['String'];
};

export type DeleteProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteProjectReportInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteRegionAllocationInputType = {
  id: Scalars['String'];
};

export type DeleteReportInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteRoleInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteSchedulerInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteStockGoalRuleInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteStockHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteStockInputType = {
  id: Scalars['String'];
};

export type DeleteStockPositionInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteStockTradeInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteTransactionInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteTransferInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteValuationInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteWarrantHoldingInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteWarrantInputType = {
  id: Scalars['String'];
};

export type Email = {
  __typename?: 'Email';
  id: Scalars['String'];
  person?: Maybe<Person>;
  value?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Entity = {
  __typename?: 'Entity';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  media?: Maybe<Array<EntityMedia>>;
  name?: Maybe<Scalars['String']>;
  regionAllocations?: Maybe<Array<RegionAllocation>>;
  sector?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type EntityMedia = {
  __typename?: 'EntityMedia';
  entity?: Maybe<Entity>;
  file?: Maybe<File>;
  id: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  entities?: Maybe<Array<Entity>>;
  files?: Maybe<Array<File>>;
  holdings?: Maybe<Array<HoldingObj>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  portfolios?: Maybe<Array<Portfolio>>;
  project?: Maybe<ProjectObj>;
  transactions?: Maybe<Array<Transaction>>;
};

export type FavoriteHolding = {
  __typename?: 'FavoriteHolding';
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type File = {
  __typename?: 'File';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Fund = Asset & {
  __typename?: 'Fund';
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
  id: Scalars['String'];
  paperId?: Maybe<Scalars['String']>;
};

export type FundHolding = Holding & {
  __typename?: 'FundHolding';
  commitments?: Maybe<Array<Commitment>>;
  fund?: Maybe<Fund>;
  fundPositions?: Maybe<Array<FundPosition>>;
  fundTrades?: Maybe<Array<FundTrade>>;
  holding: HoldingObj;
  id: Scalars['String'];
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type FundHoldingBase = {
  __typename?: 'FundHoldingBase';
  id: Scalars['String'];
};

export type FundPosition = {
  __typename?: 'FundPosition';
  date?: Maybe<Scalars['Date']>;
  fundHolding?: Maybe<FundHoldingBase>;
  id: Scalars['String'];
  shares?: Maybe<Scalars['Float']>;
};

export type FundTrade = {
  __typename?: 'FundTrade';
  fundHolding?: Maybe<FundHoldingBase>;
  id: Scalars['String'];
  position?: Maybe<FundPosition>;
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transfer?: Maybe<Transfer>;
};

export type GeneratedReport = {
  __typename?: 'GeneratedReport';
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  objectKey: Scalars['String'];
  project?: Maybe<ProjectObj>;
  report?: Maybe<Report>;
};

export type GoalRule = {
  __typename?: 'GoalRule';
  formula: Scalars['String'];
  name: Scalars['String'];
};

export type GoalRuleInputType = {
  formula: Scalars['String'];
  name: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type Holding = {
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type HoldingConfig = {
  __typename?: 'HoldingConfig';
  currentDashboardConfigId?: Maybe<Scalars['String']>;
  holdingDashboardConfigs?: Maybe<Array<HoldingDashboardConfig>>;
  id: Scalars['Int'];
};

export type HoldingDashboardConfig = {
  __typename?: 'HoldingDashboardConfig';
  compactType?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HoldingObj = {
  __typename?: 'HoldingObj';
  configs?: Maybe<Array<HoldingConfig>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectObj>;
  type?: Maybe<Scalars['String']>;
};

export type HoldingSnapshotsInputType = {
  bankNoteHoldings?: InputMaybe<Array<UpsertBankNoteHoldingInputType>>;
  bondHoldings?: InputMaybe<Array<UpsertBondHoldingInputType>>;
  cryptoHoldings?: InputMaybe<Array<UpsertCryptoHoldingInputType>>;
  fundHoldings?: InputMaybe<Array<UpsertFundHoldingInputType>>;
  holdings?: InputMaybe<Array<UpsertHoldingInputType>>;
  stockHoldings?: InputMaybe<Array<UpsertStockHoldingInputType>>;
};

export type Loan = Asset & {
  __typename?: 'Loan';
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interest?: Maybe<Scalars['Float']>;
};

export type LoanHolding = Holding & {
  __typename?: 'LoanHolding';
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  id: Scalars['String'];
  loan?: Maybe<Loan>;
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type MultipleRelationOperationIntInputType = {
  id: Scalars['Int'];
  type: Scalars['Int'];
};

export type MultipleRelationOperationStringInputType = {
  id: Scalars['String'];
  type: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  applyWorkspaceSnapshot: Workspace;
  createAddresses?: Maybe<Array<Address>>;
  createAllocations?: Maybe<Array<Allocation>>;
  createAssetCategories?: Maybe<Array<AssetCategory>>;
  createAssets?: Maybe<Array<AssetObj>>;
  createBankNoteHoldings?: Maybe<Array<BankNoteHolding>>;
  createBankNotes?: Maybe<Array<BankNote>>;
  createBondHoldings?: Maybe<Array<BondHolding>>;
  createBondPositions?: Maybe<Array<BondPosition>>;
  createBondTrades?: Maybe<Array<BondTrade>>;
  createBonds?: Maybe<Array<Bond>>;
  createCommitments?: Maybe<Array<Commitment>>;
  createCryptoHoldings?: Maybe<Array<CryptoHolding>>;
  createCryptos?: Maybe<Array<Crypto>>;
  createDashboardElements?: Maybe<Array<DashboardElement>>;
  createDashboardMedias?: Maybe<Array<DashboardMedia>>;
  createEmails?: Maybe<Array<Email>>;
  createEntities?: Maybe<Array<Entity>>;
  createEntityMedias?: Maybe<Array<EntityMedia>>;
  createEvents?: Maybe<Array<Event>>;
  createFavoriteHoldings?: Maybe<Array<FavoriteHolding>>;
  createFiles?: Maybe<Array<File>>;
  createFundHoldings?: Maybe<Array<FundHolding>>;
  createFundPositions?: Maybe<Array<FundPosition>>;
  createFundTrades?: Maybe<Array<FundTrade>>;
  createFunds?: Maybe<Array<Fund>>;
  createGeneratedReports?: Maybe<Array<GeneratedReport>>;
  createHoldingConfigs?: Maybe<Array<HoldingConfig>>;
  createHoldingDashboardConfigs?: Maybe<Array<HoldingDashboardConfig>>;
  createHoldings?: Maybe<Array<HoldingObj>>;
  createLoanHoldings?: Maybe<Array<LoanHolding>>;
  createLoans?: Maybe<Array<Loan>>;
  createPeople?: Maybe<Array<Person>>;
  createPhones?: Maybe<Array<Phone>>;
  createPortfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  createPortfolioDashboardConfigs?: Maybe<Array<PortfolioDashboardConfig>>;
  createPortfolioProjects?: Maybe<Array<PortfolioProject>>;
  createPortfolios?: Maybe<Array<Portfolio>>;
  createPrivateEquityFundCapitalCallTransfers?: Maybe<
    Array<PrivateEquityFundCapitalCallTransfer>
  >;
  createPrivateEquityFundCapitalCalls?: Maybe<
    Array<PrivateEquityFundCapitalCall>
  >;
  createPrivateEquityFundCapitalCommitments?: Maybe<
    Array<PrivateEquityFundCapitalCommitment>
  >;
  createPrivateEquityFundCapitalDistributionTransfers?: Maybe<
    Array<PrivateEquityFundCapitalDistributionTransfer>
  >;
  createPrivateEquityFundCapitalDistributions?: Maybe<
    Array<PrivateEquityFundCapitalDistribution>
  >;
  createPrivateEquityFundContacts?: Maybe<Array<PrivateEquityFundContact>>;
  createPrivateEquityFundInvestments?: Maybe<
    Array<PrivateEquityFundInvestment>
  >;
  createPrivateEquityFundInvestors?: Maybe<Array<PrivateEquityFundInvestor>>;
  createPrivateEquityFundProjects?: Maybe<Array<PrivateEquityFundProject>>;
  createPrivateEquityFundTeamMembers?: Maybe<
    Array<PrivateEquityFundTeamMember>
  >;
  createPrivateEquityFunds?: Maybe<Array<PrivateEquityFund>>;
  createProjectReports?: Maybe<Array<ProjectReport>>;
  createProjects?: Maybe<Array<ProjectObj>>;
  createRegionAllocations?: Maybe<Array<RegionAllocation>>;
  createReports?: Maybe<Array<Report>>;
  createRoles?: Maybe<Array<Role>>;
  createSchedulers?: Maybe<Array<Scheduler>>;
  createStockGoalRules?: Maybe<Array<StockGoalRule>>;
  createStockHoldings?: Maybe<Array<StockHolding>>;
  createStockPositions?: Maybe<Array<StockPosition>>;
  createStockTrades?: Maybe<Array<StockTrade>>;
  createStocks?: Maybe<Array<Stock>>;
  createTransactions?: Maybe<Array<Transaction>>;
  createTransfers?: Maybe<Array<Transfer>>;
  createValuations?: Maybe<Array<Valuation>>;
  createWarrantHoldings?: Maybe<Array<WarrantHolding>>;
  createWarrants?: Maybe<Array<Warrant>>;
  createWorkspace?: Maybe<WorkspaceBaseWithRoles>;
  deleteAddresses?: Maybe<Array<Scalars['String']>>;
  deleteAllocations?: Maybe<Array<Scalars['String']>>;
  deleteAssetCategories?: Maybe<Array<Scalars['String']>>;
  deleteAssets?: Maybe<Array<Scalars['String']>>;
  deleteBankNoteHoldings?: Maybe<Array<Scalars['String']>>;
  deleteBankNotes?: Maybe<Array<Scalars['String']>>;
  deleteBondHoldings?: Maybe<Array<Scalars['String']>>;
  deleteBondPositions?: Maybe<Array<Scalars['String']>>;
  deleteBondTrades?: Maybe<Array<Scalars['String']>>;
  deleteBonds?: Maybe<Array<Scalars['String']>>;
  deleteCommitments?: Maybe<Array<Scalars['String']>>;
  deleteCryptoHoldings?: Maybe<Array<Scalars['String']>>;
  deleteCryptos?: Maybe<Array<Scalars['String']>>;
  deleteDashboardElements?: Maybe<Array<Scalars['String']>>;
  deleteDashboardMedias?: Maybe<Array<Scalars['String']>>;
  deleteEmails?: Maybe<Array<Scalars['String']>>;
  deleteEntities?: Maybe<Array<Scalars['String']>>;
  deleteEntityMedias?: Maybe<Array<Scalars['String']>>;
  deleteEvents?: Maybe<Array<Scalars['String']>>;
  deleteFavoriteHoldings?: Maybe<Array<Scalars['String']>>;
  deleteFiles?: Maybe<Array<Scalars['String']>>;
  deleteFundHoldings?: Maybe<Array<Scalars['String']>>;
  deleteFundPositions?: Maybe<Array<Scalars['String']>>;
  deleteFundTrades?: Maybe<Array<Scalars['String']>>;
  deleteFunds?: Maybe<Array<Scalars['String']>>;
  deleteGeneratedReports?: Maybe<Array<Scalars['String']>>;
  deleteHoldingConfigs?: Maybe<Array<Scalars['Int']>>;
  deleteHoldingDashboardConfigs?: Maybe<Array<Scalars['String']>>;
  deleteHoldings?: Maybe<Array<Scalars['String']>>;
  deleteLoanHoldings?: Maybe<Array<Scalars['String']>>;
  deleteLoans?: Maybe<Array<Scalars['String']>>;
  deletePeople?: Maybe<Array<Scalars['String']>>;
  deletePhones?: Maybe<Array<Scalars['String']>>;
  deletePortfolioConfigs?: Maybe<Array<Scalars['Int']>>;
  deletePortfolioDashboardConfigs?: Maybe<Array<Scalars['String']>>;
  deletePortfolioProjects?: Maybe<Array<Scalars['String']>>;
  deletePortfolios?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundCapitalCallTransfers?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundCapitalCalls?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundCapitalCommitments?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundCapitalDistributionTransfers?: Maybe<
    Array<Scalars['String']>
  >;
  deletePrivateEquityFundCapitalDistributions?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundContacts?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundInvestments?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundInvestors?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundProjects?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFundTeamMembers?: Maybe<Array<Scalars['String']>>;
  deletePrivateEquityFunds?: Maybe<Array<Scalars['String']>>;
  deleteProjectReports?: Maybe<Array<Scalars['String']>>;
  deleteProjects?: Maybe<Array<Scalars['String']>>;
  deleteRegionAllocations?: Maybe<Array<Scalars['String']>>;
  deleteReports?: Maybe<Array<Scalars['String']>>;
  deleteRoles?: Maybe<Array<Scalars['String']>>;
  deleteSchedulers?: Maybe<Array<Scalars['String']>>;
  deleteStockGoalRules?: Maybe<Array<Scalars['String']>>;
  deleteStockHoldings?: Maybe<Array<Scalars['String']>>;
  deleteStockPositions?: Maybe<Array<Scalars['String']>>;
  deleteStockTrades?: Maybe<Array<Scalars['String']>>;
  deleteStocks?: Maybe<Array<Scalars['String']>>;
  deleteTransactions?: Maybe<Array<Scalars['String']>>;
  deleteTransfers?: Maybe<Array<Scalars['String']>>;
  deleteValuations?: Maybe<Array<Scalars['String']>>;
  deleteWarrantHoldings?: Maybe<Array<Scalars['String']>>;
  deleteWarrants?: Maybe<Array<Scalars['String']>>;
  deleteWorkspace: Scalars['String'];
  updateAddresses?: Maybe<Array<Address>>;
  updateAllocations?: Maybe<Array<Allocation>>;
  updateAssetCategories?: Maybe<Array<AssetCategory>>;
  updateAssets?: Maybe<Array<AssetObj>>;
  updateBankNoteHoldings?: Maybe<Array<BankNoteHolding>>;
  updateBankNotes?: Maybe<Array<BankNote>>;
  updateBondHoldings?: Maybe<Array<BondHolding>>;
  updateBondPositions?: Maybe<Array<BondPosition>>;
  updateBondTrades?: Maybe<Array<BondTrade>>;
  updateBonds?: Maybe<Array<Bond>>;
  updateCommitments?: Maybe<Array<Commitment>>;
  updateCryptoHoldings?: Maybe<Array<CryptoHolding>>;
  updateCryptos?: Maybe<Array<Crypto>>;
  updateDashboardElements?: Maybe<Array<DashboardElement>>;
  updateDashboardMedias?: Maybe<Array<DashboardMedia>>;
  updateEmails?: Maybe<Array<Email>>;
  updateEntities?: Maybe<Array<Entity>>;
  updateEntityMedias?: Maybe<Array<EntityMedia>>;
  updateEvents?: Maybe<Array<Event>>;
  updateFavoriteHoldings?: Maybe<Array<FavoriteHolding>>;
  updateFiles?: Maybe<Array<File>>;
  updateFundHoldings?: Maybe<Array<FundHolding>>;
  updateFundPositions?: Maybe<Array<FundPosition>>;
  updateFundTrades?: Maybe<Array<FundTrade>>;
  updateFunds?: Maybe<Array<Fund>>;
  updateGeneratedReports?: Maybe<Array<GeneratedReport>>;
  updateHoldingConfigs?: Maybe<Array<HoldingConfig>>;
  updateHoldingDashboardConfigs?: Maybe<Array<HoldingDashboardConfig>>;
  updateHoldings?: Maybe<Array<HoldingObj>>;
  updateLoanHoldings?: Maybe<Array<LoanHolding>>;
  updateLoans?: Maybe<Array<Loan>>;
  updatePeople?: Maybe<Array<Person>>;
  updatePhones?: Maybe<Array<Phone>>;
  updatePortfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  updatePortfolioDashboardConfigs?: Maybe<Array<PortfolioDashboardConfig>>;
  updatePortfolioProjects?: Maybe<Array<PortfolioProject>>;
  updatePortfolios?: Maybe<Array<Portfolio>>;
  updatePrivateEquityFundCapitalCallTransfers?: Maybe<
    Array<PrivateEquityFundCapitalCallTransfer>
  >;
  updatePrivateEquityFundCapitalCalls?: Maybe<
    Array<PrivateEquityFundCapitalCall>
  >;
  updatePrivateEquityFundCapitalCommitments?: Maybe<
    Array<PrivateEquityFundCapitalCommitment>
  >;
  updatePrivateEquityFundCapitalDistributionTransfers?: Maybe<
    Array<PrivateEquityFundCapitalDistributionTransfer>
  >;
  updatePrivateEquityFundCapitalDistributions?: Maybe<
    Array<PrivateEquityFundCapitalDistribution>
  >;
  updatePrivateEquityFundContacts?: Maybe<Array<PrivateEquityFundContact>>;
  updatePrivateEquityFundInvestments?: Maybe<
    Array<PrivateEquityFundInvestment>
  >;
  updatePrivateEquityFundInvestors?: Maybe<Array<PrivateEquityFundInvestor>>;
  updatePrivateEquityFundProjects?: Maybe<Array<PrivateEquityFundProject>>;
  updatePrivateEquityFundTeamMembers?: Maybe<
    Array<PrivateEquityFundTeamMember>
  >;
  updatePrivateEquityFunds?: Maybe<Array<PrivateEquityFund>>;
  updateProjectReports?: Maybe<Array<ProjectReport>>;
  updateProjects?: Maybe<Array<ProjectObj>>;
  updateRegionAllocations?: Maybe<Array<RegionAllocation>>;
  updateReports?: Maybe<Array<Report>>;
  updateRoles?: Maybe<Array<Role>>;
  updateSchedulers?: Maybe<Array<Scheduler>>;
  updateStockGoalRules?: Maybe<Array<StockGoalRule>>;
  updateStockHoldings?: Maybe<Array<StockHolding>>;
  updateStockPositions?: Maybe<Array<StockPosition>>;
  updateStockTrades?: Maybe<Array<StockTrade>>;
  updateStocks?: Maybe<Array<Stock>>;
  updateTransactions?: Maybe<Array<Transaction>>;
  updateTransfers?: Maybe<Array<Transfer>>;
  updateValuations?: Maybe<Array<Valuation>>;
  updateWarrantHoldings?: Maybe<Array<WarrantHolding>>;
  updateWarrants?: Maybe<Array<Warrant>>;
  updateWorkspace: Workspace;
  upsertAddresses?: Maybe<Array<Address>>;
  upsertAllocations?: Maybe<Array<Allocation>>;
  upsertAssetCategories?: Maybe<Array<AssetCategory>>;
  upsertAssets?: Maybe<Array<AssetObj>>;
  upsertBankNoteHoldings?: Maybe<Array<BankNoteHolding>>;
  upsertBankNotes?: Maybe<Array<BankNote>>;
  upsertBondHoldings?: Maybe<Array<BondHolding>>;
  upsertBondPositions?: Maybe<Array<BondPosition>>;
  upsertBondTrades?: Maybe<Array<BondTrade>>;
  upsertBonds?: Maybe<Array<Bond>>;
  upsertCommitments?: Maybe<Array<Commitment>>;
  upsertCryptoHoldings?: Maybe<Array<CryptoHolding>>;
  upsertCryptos?: Maybe<Array<Crypto>>;
  upsertDashboardElements?: Maybe<Array<DashboardElement>>;
  upsertDashboardMedias?: Maybe<Array<DashboardMedia>>;
  upsertEmails?: Maybe<Array<Email>>;
  upsertEntities?: Maybe<Array<Entity>>;
  upsertEntityMedias?: Maybe<Array<EntityMedia>>;
  upsertEvents?: Maybe<Array<Event>>;
  upsertFavoriteHoldings?: Maybe<Array<FavoriteHolding>>;
  upsertFiles?: Maybe<Array<File>>;
  upsertFundHoldings?: Maybe<Array<FundHolding>>;
  upsertFundPositions?: Maybe<Array<FundPosition>>;
  upsertFundTrades?: Maybe<Array<FundTrade>>;
  upsertFunds?: Maybe<Array<Fund>>;
  upsertGeneratedReports?: Maybe<Array<GeneratedReport>>;
  upsertHoldingConfigs?: Maybe<Array<HoldingConfig>>;
  upsertHoldingDashboardConfigs?: Maybe<Array<HoldingDashboardConfig>>;
  upsertHoldings?: Maybe<Array<HoldingObj>>;
  upsertLoanHoldings?: Maybe<Array<LoanHolding>>;
  upsertLoans?: Maybe<Array<Loan>>;
  upsertPeople?: Maybe<Array<Person>>;
  upsertPhones?: Maybe<Array<Phone>>;
  upsertPortfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  upsertPortfolioDashboardConfigs?: Maybe<Array<PortfolioDashboardConfig>>;
  upsertPortfolioProjects?: Maybe<Array<PortfolioProject>>;
  upsertPortfolios?: Maybe<Array<Portfolio>>;
  upsertPrivateEquityFundCapitalCallTransfers?: Maybe<
    Array<PrivateEquityFundCapitalCallTransfer>
  >;
  upsertPrivateEquityFundCapitalCalls?: Maybe<
    Array<PrivateEquityFundCapitalCall>
  >;
  upsertPrivateEquityFundCapitalCommitments?: Maybe<
    Array<PrivateEquityFundCapitalCommitment>
  >;
  upsertPrivateEquityFundCapitalDistributionTransfers?: Maybe<
    Array<PrivateEquityFundCapitalDistributionTransfer>
  >;
  upsertPrivateEquityFundCapitalDistributions?: Maybe<
    Array<PrivateEquityFundCapitalDistribution>
  >;
  upsertPrivateEquityFundContacts?: Maybe<Array<PrivateEquityFundContact>>;
  upsertPrivateEquityFundInvestments?: Maybe<
    Array<PrivateEquityFundInvestment>
  >;
  upsertPrivateEquityFundInvestors?: Maybe<Array<PrivateEquityFundInvestor>>;
  upsertPrivateEquityFundProjects?: Maybe<Array<PrivateEquityFundProject>>;
  upsertPrivateEquityFundTeamMembers?: Maybe<
    Array<PrivateEquityFundTeamMember>
  >;
  upsertPrivateEquityFunds?: Maybe<Array<PrivateEquityFund>>;
  upsertProjectReports?: Maybe<Array<ProjectReport>>;
  upsertProjects?: Maybe<Array<ProjectObj>>;
  upsertRegionAllocations?: Maybe<Array<RegionAllocation>>;
  upsertReports?: Maybe<Array<Report>>;
  upsertRoles?: Maybe<Array<Role>>;
  upsertSchedulers?: Maybe<Array<Scheduler>>;
  upsertStockGoalRules?: Maybe<Array<StockGoalRule>>;
  upsertStockHoldings?: Maybe<Array<StockHolding>>;
  upsertStockPositions?: Maybe<Array<StockPosition>>;
  upsertStockTrades?: Maybe<Array<StockTrade>>;
  upsertStocks?: Maybe<Array<Stock>>;
  upsertTransactions?: Maybe<Array<Transaction>>;
  upsertTransfers?: Maybe<Array<Transfer>>;
  upsertValuations?: Maybe<Array<Valuation>>;
  upsertWarrantHoldings?: Maybe<Array<WarrantHolding>>;
  upsertWarrants?: Maybe<Array<Warrant>>;
};

export type MutationApplyWorkspaceSnapshotArgs = {
  input?: InputMaybe<WorkspaceSnapshotInputType>;
};

export type MutationCreateAddressesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateAddressInputType>>>;
};

export type MutationCreateAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateAllocationInputType>>>;
};

export type MutationCreateAssetCategoriesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateAssetCategoryInputType>>>;
};

export type MutationCreateAssetsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateAssetInputType>>>;
};

export type MutationCreateBankNoteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateBankNoteHoldingInputType>>>;
};

export type MutationCreateBankNotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateBankNoteInputType>>>;
};

export type MutationCreateBondHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateBondHoldingInputType>>>;
};

export type MutationCreateBondPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateBondPositionInputType>>>;
};

export type MutationCreateBondTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateBondTradeInputType>>>;
};

export type MutationCreateBondsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateBondInputType>>>;
};

export type MutationCreateCommitmentsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateCommitmentInputType>>>;
};

export type MutationCreateCryptoHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateCryptoHoldingInputType>>>;
};

export type MutationCreateCryptosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateCryptoInputType>>>;
};

export type MutationCreateDashboardElementsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateDashboardElementInputType>>>;
};

export type MutationCreateDashboardMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateDashboardMediaInputType>>>;
};

export type MutationCreateEmailsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateEmailInputType>>>;
};

export type MutationCreateEntitiesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateEntityInputType>>>;
};

export type MutationCreateEntityMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateEntityMediaInputType>>>;
};

export type MutationCreateEventsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateEventInputType>>>;
};

export type MutationCreateFavoriteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateFavoriteHoldingInputType>>>;
  projectId: Scalars['String'];
};

export type MutationCreateFilesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateFileInputType>>>;
};

export type MutationCreateFundHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateFundHoldingInputType>>>;
};

export type MutationCreateFundPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateFundPositionInputType>>>;
};

export type MutationCreateFundTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateFundTradeInputType>>>;
};

export type MutationCreateFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateFundInputType>>>;
};

export type MutationCreateGeneratedReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateGeneratedReportInputType>>>;
};

export type MutationCreateHoldingConfigsArgs = {
  holdingId?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<Array<InputMaybe<CreateHoldingConfigInputType>>>;
};

export type MutationCreateHoldingDashboardConfigsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateHoldingDashboardConfigInputType>>>;
};

export type MutationCreateHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateHoldingInputType>>>;
};

export type MutationCreateLoanHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateLoanHoldingInputType>>>;
};

export type MutationCreateLoansArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateLoanInputType>>>;
};

export type MutationCreatePeopleArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePersonInputType>>>;
};

export type MutationCreatePhonesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePhoneInputType>>>;
};

export type MutationCreatePortfolioConfigsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePortfolioConfigInputType>>>;
  portfolioId?: InputMaybe<Scalars['String']>;
};

export type MutationCreatePortfolioDashboardConfigsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePortfolioDashboardConfigInputType>>
  >;
};

export type MutationCreatePortfolioProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePortfolioProjectInputType>>>;
};

export type MutationCreatePortfoliosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePortfolioInputType>>>;
};

export type MutationCreatePrivateEquityFundCapitalCallTransfersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundCapitalCallTransferInputType>>
  >;
};

export type MutationCreatePrivateEquityFundCapitalCallsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundCapitalCallInputType>>
  >;
};

export type MutationCreatePrivateEquityFundCapitalCommitmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundCapitalCommitmentInputType>>
  >;
};

export type MutationCreatePrivateEquityFundCapitalDistributionTransfersArgs = {
  inputs?: InputMaybe<
    Array<
      InputMaybe<CreatePrivateEquityFundCapitalDistributionTransferInputType>
    >
  >;
};

export type MutationCreatePrivateEquityFundCapitalDistributionsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundCapitalDistributionInputType>>
  >;
};

export type MutationCreatePrivateEquityFundContactsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundContactInputType>>
  >;
};

export type MutationCreatePrivateEquityFundInvestmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundInvestmentInputType>>
  >;
};

export type MutationCreatePrivateEquityFundInvestorsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundInvestorInputType>>
  >;
};

export type MutationCreatePrivateEquityFundProjectsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundProjectInputType>>
  >;
};

export type MutationCreatePrivateEquityFundTeamMembersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePrivateEquityFundTeamMemberInputType>>
  >;
};

export type MutationCreatePrivateEquityFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePrivateEquityFundInputType>>>;
};

export type MutationCreateProjectReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateProjectReportInputType>>>;
};

export type MutationCreateProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateProjectInputType>>>;
};

export type MutationCreateRegionAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateRegionAllocationInputType>>>;
};

export type MutationCreateReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateReportInputType>>>;
};

export type MutationCreateRolesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateRoleInputType>>>;
};

export type MutationCreateSchedulersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateSchedulerInputType>>>;
};

export type MutationCreateStockGoalRulesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateStockGoalRuleInputType>>>;
};

export type MutationCreateStockHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateStockHoldingInputType>>>;
};

export type MutationCreateStockPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateStockPositionInputType>>>;
};

export type MutationCreateStockTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateStockTradeInputType>>>;
};

export type MutationCreateStocksArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateStockInputType>>>;
};

export type MutationCreateTransactionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateTransactionInputType>>>;
};

export type MutationCreateTransfersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateTransferInputType>>>;
};

export type MutationCreateValuationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateValuationInputType>>>;
};

export type MutationCreateWarrantHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateWarrantHoldingInputType>>>;
};

export type MutationCreateWarrantsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateWarrantInputType>>>;
};

export type MutationCreateWorkspaceArgs = {
  input?: InputMaybe<CreateWorkspaceInputType>;
};

export type MutationDeleteAddressesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteAddressInputType>>>;
};

export type MutationDeleteAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteAllocationInputType>>>;
};

export type MutationDeleteAssetCategoriesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteAssetCategoryInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteAssetsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteAssetInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteBankNoteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteBankNoteHoldingInputType>>>;
};

export type MutationDeleteBankNotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteBankNoteInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteBondHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteBondHoldingInputType>>>;
};

export type MutationDeleteBondPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteBondPositionInputType>>>;
};

export type MutationDeleteBondTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteBondTradeInputType>>>;
};

export type MutationDeleteBondsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteBondInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteCommitmentsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteCommitmentInputType>>>;
};

export type MutationDeleteCryptoHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteCryptoHoldingInputType>>>;
};

export type MutationDeleteCryptosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteCryptoInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteDashboardElementsArgs = {
  inputs?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationDeleteDashboardMediasArgs = {
  inputs?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationDeleteEmailsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteEmailInputType>>>;
};

export type MutationDeleteEntitiesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteEntityInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteEntityMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteEntityMediaInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteEventsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteEventInputType>>>;
};

export type MutationDeleteFavoriteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteFavoriteHoldingInputType>>>;
  projectId: Scalars['String'];
};

export type MutationDeleteFilesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteFileInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteFundHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteFundHoldingInputType>>>;
};

export type MutationDeleteFundPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteFundPositionInputType>>>;
};

export type MutationDeleteFundTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteFundTradeInputType>>>;
};

export type MutationDeleteFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteFundInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteGeneratedReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteGeneratedReportInputType>>>;
};

export type MutationDeleteHoldingConfigsArgs = {
  holdingId?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationDeleteHoldingDashboardConfigsArgs = {
  inputs?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationDeleteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteHoldingInputType>>>;
};

export type MutationDeleteLoanHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteLoanHoldingInputType>>>;
};

export type MutationDeleteLoansArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteLoanInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePeopleArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeletePersonInputType>>>;
};

export type MutationDeletePhonesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeletePhoneInputType>>>;
};

export type MutationDeletePortfolioConfigsArgs = {
  inputs?: InputMaybe<Array<DeletePortfolioConfigInputType>>;
  portfolioId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePortfolioDashboardConfigsArgs = {
  inputs?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationDeletePortfolioProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeletePortfolioProjectInputType>>>;
};

export type MutationDeletePortfoliosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeletePortfolioInputType>>>;
};

export type MutationDeletePrivateEquityFundCapitalCallTransfersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundCapitalCallTransferInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundCapitalCallsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundCapitalCallInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundCapitalCommitmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundCapitalCommitmentInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundCapitalDistributionTransfersArgs = {
  inputs?: InputMaybe<
    Array<
      InputMaybe<DeletePrivateEquityFundCapitalDistributionTransferInputType>
    >
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundCapitalDistributionsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundCapitalDistributionInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundContactsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundContactInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundInvestmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundInvestmentInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundInvestorsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundInvestorInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundProjectsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundProjectInputType>>
  >;
};

export type MutationDeletePrivateEquityFundTeamMembersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePrivateEquityFundTeamMemberInputType>>
  >;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeletePrivateEquityFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeletePrivateEquityFundInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteProjectReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteProjectReportInputType>>>;
};

export type MutationDeleteProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteProjectInputType>>>;
};

export type MutationDeleteRegionAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteRegionAllocationInputType>>>;
};

export type MutationDeleteReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteReportInputType>>>;
};

export type MutationDeleteRolesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteRoleInputType>>>;
};

export type MutationDeleteSchedulersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteSchedulerInputType>>>;
};

export type MutationDeleteStockGoalRulesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteStockGoalRuleInputType>>>;
};

export type MutationDeleteStockHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteStockHoldingInputType>>>;
};

export type MutationDeleteStockPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteStockPositionInputType>>>;
};

export type MutationDeleteStockTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteStockTradeInputType>>>;
};

export type MutationDeleteStocksArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteStockInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteTransactionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteTransactionInputType>>>;
};

export type MutationDeleteTransfersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteTransferInputType>>>;
};

export type MutationDeleteValuationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteValuationInputType>>>;
};

export type MutationDeleteWarrantHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteWarrantHoldingInputType>>>;
};

export type MutationDeleteWarrantsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteWarrantInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAddressesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateAddressInputType>>>;
};

export type MutationUpdateAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateAllocationInputType>>>;
};

export type MutationUpdateAssetCategoriesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateAssetCategoryInputType>>>;
};

export type MutationUpdateAssetsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateAssetInputType>>>;
};

export type MutationUpdateBankNoteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateBankNoteHoldingInputType>>>;
};

export type MutationUpdateBankNotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateBankNoteInputType>>>;
};

export type MutationUpdateBondHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateBondHoldingInputType>>>;
};

export type MutationUpdateBondPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateBondPositionInputType>>>;
};

export type MutationUpdateBondTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateBondTradeInputType>>>;
};

export type MutationUpdateBondsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateBondInputType>>>;
};

export type MutationUpdateCommitmentsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateCommitmentInputType>>>;
};

export type MutationUpdateCryptoHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateCryptoHoldingInputType>>>;
};

export type MutationUpdateCryptosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateCryptoInputType>>>;
};

export type MutationUpdateDashboardElementsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateDashboardElementInputType>>>;
};

export type MutationUpdateDashboardMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateDashboardMediaInputType>>>;
};

export type MutationUpdateEmailsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateEmailInputType>>>;
};

export type MutationUpdateEntitiesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateEntityInputType>>>;
};

export type MutationUpdateEntityMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateEntityMediaInputType>>>;
};

export type MutationUpdateEventsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateEventInputType>>>;
};

export type MutationUpdateFavoriteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateFavoriteHoldingInputType>>>;
  projectId: Scalars['String'];
};

export type MutationUpdateFilesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateFileInputType>>>;
};

export type MutationUpdateFundHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateFundHoldingInputType>>>;
};

export type MutationUpdateFundPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateFundPositionInputType>>>;
};

export type MutationUpdateFundTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateFundTradeInputType>>>;
};

export type MutationUpdateFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateFundInputType>>>;
};

export type MutationUpdateGeneratedReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateGeneratedReportInputType>>>;
};

export type MutationUpdateHoldingConfigsArgs = {
  holdingId?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<Array<InputMaybe<UpdateHoldingConfigInputType>>>;
};

export type MutationUpdateHoldingDashboardConfigsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateHoldingDashboardConfigInputType>>>;
};

export type MutationUpdateHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateHoldingInputType>>>;
};

export type MutationUpdateLoanHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateLoanHoldingInputType>>>;
};

export type MutationUpdateLoansArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateLoanInputType>>>;
};

export type MutationUpdatePeopleArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePersonInputType>>>;
};

export type MutationUpdatePhonesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePhoneInputType>>>;
};

export type MutationUpdatePortfolioConfigsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePortfolioConfigInputType>>>;
  portfolioId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePortfolioDashboardConfigsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePortfolioDashboardConfigInputType>>
  >;
};

export type MutationUpdatePortfolioProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePortfolioProjectInputType>>>;
};

export type MutationUpdatePortfoliosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePortfolioInputType>>>;
};

export type MutationUpdatePrivateEquityFundCapitalCallTransfersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundCapitalCallTransferInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundCapitalCallsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundCapitalCallInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundCapitalCommitmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundCapitalCommitmentInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundCapitalDistributionTransfersArgs = {
  inputs?: InputMaybe<
    Array<
      InputMaybe<UpdatePrivateEquityFundCapitalDistributionTransferInputType>
    >
  >;
};

export type MutationUpdatePrivateEquityFundCapitalDistributionsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundCapitalDistributionInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundContactsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundContactInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundInvestmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundInvestmentInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundInvestorsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundInvestorInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundProjectsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundProjectInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundTeamMembersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePrivateEquityFundTeamMemberInputType>>
  >;
};

export type MutationUpdatePrivateEquityFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePrivateEquityFundInputType>>>;
};

export type MutationUpdateProjectReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateProjectReportInputType>>>;
};

export type MutationUpdateProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateProjectInputType>>>;
};

export type MutationUpdateRegionAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateRegionAllocationInputType>>>;
};

export type MutationUpdateReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateReportInputType>>>;
};

export type MutationUpdateRolesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateRoleInputType>>>;
};

export type MutationUpdateSchedulersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateSchedulerInputType>>>;
};

export type MutationUpdateStockGoalRulesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateStockGoalRuleInputType>>>;
};

export type MutationUpdateStockHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateStockHoldingInputType>>>;
};

export type MutationUpdateStockPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateStockPositionInputType>>>;
};

export type MutationUpdateStockTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateStockTradeInputType>>>;
};

export type MutationUpdateStocksArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateStockInputType>>>;
};

export type MutationUpdateTransactionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateTransactionInputType>>>;
};

export type MutationUpdateTransfersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateTransferInputType>>>;
};

export type MutationUpdateValuationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateValuationInputType>>>;
};

export type MutationUpdateWarrantHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateWarrantHoldingInputType>>>;
};

export type MutationUpdateWarrantsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateWarrantInputType>>>;
};

export type MutationUpdateWorkspaceArgs = {
  input?: InputMaybe<UpdateWorkspaceInputType>;
};

export type MutationUpsertAddressesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertAddressInputType>>>;
};

export type MutationUpsertAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertAllocationInputType>>>;
};

export type MutationUpsertAssetCategoriesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertAssetCategoryInputType>>>;
};

export type MutationUpsertAssetsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertAssetInputType>>>;
};

export type MutationUpsertBankNoteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertBankNoteHoldingInputType>>>;
};

export type MutationUpsertBankNotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertBankNoteInputType>>>;
};

export type MutationUpsertBondHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertBondHoldingInputType>>>;
};

export type MutationUpsertBondPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertBondPositionInputType>>>;
};

export type MutationUpsertBondTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertBondTradeInputType>>>;
};

export type MutationUpsertBondsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertBondInputType>>>;
};

export type MutationUpsertCommitmentsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertCommitmentInputType>>>;
};

export type MutationUpsertCryptoHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertCryptoHoldingInputType>>>;
};

export type MutationUpsertCryptosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertCryptoInputType>>>;
};

export type MutationUpsertDashboardElementsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertDashboardElementInputType>>>;
};

export type MutationUpsertDashboardMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertDashboardMediaInputType>>>;
};

export type MutationUpsertEmailsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertEmailInputType>>>;
};

export type MutationUpsertEntitiesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertEntityInputType>>>;
};

export type MutationUpsertEntityMediasArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertEntityMediaInputType>>>;
};

export type MutationUpsertEventsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertEventInputType>>>;
};

export type MutationUpsertFavoriteHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertFavoriteHoldingInputType>>>;
  projectId: Scalars['String'];
};

export type MutationUpsertFilesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertFileInputType>>>;
};

export type MutationUpsertFundHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertFundHoldingInputType>>>;
};

export type MutationUpsertFundPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertFundPositionInputType>>>;
};

export type MutationUpsertFundTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertFundTradeInputType>>>;
};

export type MutationUpsertFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertFundInputType>>>;
};

export type MutationUpsertGeneratedReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertGeneratedReportInputType>>>;
};

export type MutationUpsertHoldingConfigsArgs = {
  holdingId?: InputMaybe<Scalars['String']>;
  inputs?: InputMaybe<Array<InputMaybe<UpsertHoldingConfigInputType>>>;
};

export type MutationUpsertHoldingDashboardConfigsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertHoldingDashboardConfigInputType>>>;
};

export type MutationUpsertHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertHoldingInputType>>>;
};

export type MutationUpsertLoanHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertLoanHoldingInputType>>>;
};

export type MutationUpsertLoansArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertLoanInputType>>>;
};

export type MutationUpsertPeopleArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPersonInputType>>>;
};

export type MutationUpsertPhonesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPhoneInputType>>>;
};

export type MutationUpsertPortfolioConfigsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPortfolioConfigInputType>>>;
  portfolioId?: InputMaybe<Scalars['String']>;
};

export type MutationUpsertPortfolioDashboardConfigsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPortfolioDashboardConfigInputType>>
  >;
};

export type MutationUpsertPortfolioProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPortfolioProjectInputType>>>;
};

export type MutationUpsertPortfoliosArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPortfolioInputType>>>;
};

export type MutationUpsertPrivateEquityFundCapitalCallTransfersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundCapitalCallTransferInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundCapitalCallsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundCapitalCallInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundCapitalCommitmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundCapitalCommitmentInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundCapitalDistributionTransfersArgs = {
  inputs?: InputMaybe<
    Array<
      InputMaybe<UpsertPrivateEquityFundCapitalDistributionTransferInputType>
    >
  >;
};

export type MutationUpsertPrivateEquityFundCapitalDistributionsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundCapitalDistributionInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundContactsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundContactInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundInvestmentsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundInvestmentInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundInvestorsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundInvestorInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundProjectsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundProjectInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundTeamMembersArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPrivateEquityFundTeamMemberInputType>>
  >;
};

export type MutationUpsertPrivateEquityFundsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPrivateEquityFundInputType>>>;
};

export type MutationUpsertProjectReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertProjectReportInputType>>>;
};

export type MutationUpsertProjectsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertProjectInputType>>>;
};

export type MutationUpsertRegionAllocationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertRegionAllocationInputType>>>;
};

export type MutationUpsertReportsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertReportInputType>>>;
};

export type MutationUpsertRolesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertRoleInputType>>>;
};

export type MutationUpsertSchedulersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertSchedulerInputType>>>;
};

export type MutationUpsertStockGoalRulesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertStockGoalRuleInputType>>>;
};

export type MutationUpsertStockHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertStockHoldingInputType>>>;
};

export type MutationUpsertStockPositionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertStockPositionInputType>>>;
};

export type MutationUpsertStockTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertStockTradeInputType>>>;
};

export type MutationUpsertStocksArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertStockInputType>>>;
};

export type MutationUpsertTransactionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertTransactionInputType>>>;
};

export type MutationUpsertTransfersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertTransferInputType>>>;
};

export type MutationUpsertValuationsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertValuationInputType>>>;
};

export type MutationUpsertWarrantHoldingsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertWarrantHoldingInputType>>>;
};

export type MutationUpsertWarrantsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertWarrantInputType>>>;
};

export type Person = {
  __typename?: 'Person';
  addresses?: Maybe<Array<Address>>;
  description?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Email>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Phone>>;
  roles?: Maybe<Array<Role>>;
  workspace?: Maybe<Workspace>;
};

export type Phone = {
  __typename?: 'Phone';
  id: Scalars['String'];
  person?: Maybe<Person>;
  value?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  allocations?: Maybe<Array<Allocation>>;
  description?: Maybe<Scalars['String']>;
  holdings?: Maybe<Array<Holding>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  parentPortfolio?: Maybe<Portfolio>;
  portfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  portfolios?: Maybe<Array<Portfolio>>;
};

export type PortfolioConfig = {
  __typename?: 'PortfolioConfig';
  currentDashboardConfigId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  portfolioDashboardConfigs?: Maybe<Array<PortfolioDashboardConfig>>;
};

export type PortfolioDashboardConfig = {
  __typename?: 'PortfolioDashboardConfig';
  compactType?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PortfolioProject = Project & {
  __typename?: 'PortfolioProject';
  id: Scalars['String'];
  portfolio?: Maybe<Portfolio>;
  project: ProjectObj;
};

export type PrivateEquityFund = Asset & {
  __typename?: 'PrivateEquityFund';
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
  capitalCalls?: Maybe<Array<PrivateEquityFundCapitalCall>>;
  capitalDistributions?: Maybe<Array<PrivateEquityFundCapitalDistribution>>;
  description?: Maybe<Scalars['String']>;
  fundSetup?: Maybe<PrivateEquityFundSetup>;
  id: Scalars['String'];
  investments?: Maybe<Array<PrivateEquityFundInvestment>>;
  investors?: Maybe<Array<PrivateEquityFundInvestor>>;
  team?: Maybe<Array<PrivateEquityFundTeamMember>>;
};

export type PrivateEquityFundCapitalCall = {
  __typename?: 'PrivateEquityFundCapitalCall';
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type PrivateEquityFundCapitalCallTransfer = {
  __typename?: 'PrivateEquityFundCapitalCallTransfer';
  capitalCall?: Maybe<PrivateEquityFundCapitalCall>;
  id: Scalars['String'];
  investor?: Maybe<PrivateEquityFundInvestor>;
  transfer?: Maybe<Transfer>;
};

export type PrivateEquityFundCapitalCommitment = {
  __typename?: 'PrivateEquityFundCapitalCommitment';
  date?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  investor?: Maybe<PrivateEquityFundInvestor>;
  value?: Maybe<Scalars['Float']>;
};

export type PrivateEquityFundCapitalDistribution = {
  __typename?: 'PrivateEquityFundCapitalDistribution';
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type PrivateEquityFundCapitalDistributionTransfer = {
  __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
  capitalDistribution?: Maybe<PrivateEquityFundCapitalDistribution>;
  id: Scalars['String'];
  investor?: Maybe<PrivateEquityFundInvestor>;
  transfer?: Maybe<Transfer>;
};

export type PrivateEquityFundContact = {
  __typename?: 'PrivateEquityFundContact';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type PrivateEquityFundInvestment = {
  __typename?: 'PrivateEquityFundInvestment';
  description: Scalars['String'];
  exit?: Maybe<Scalars['Date']>;
  holding: Holding;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PrivateEquityFundInvestor = {
  __typename?: 'PrivateEquityFundInvestor';
  capitalCallTransfer?: Maybe<Array<PrivateEquityFundCapitalCallTransfer>>;
  capitalDistributionTransfer?: Maybe<
    Array<PrivateEquityFundCapitalDistributionTransfer>
  >;
  commitments?: Maybe<Array<PrivateEquityFundCapitalCommitment>>;
  contacts?: Maybe<Array<PrivateEquityFundContact>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
};

export type PrivateEquityFundProject = Project & {
  __typename?: 'PrivateEquityFundProject';
  id: Scalars['String'];
  privateEquityFund?: Maybe<PrivateEquityFund>;
  project: ProjectObj;
};

export type PrivateEquityFundSetup = {
  __typename?: 'PrivateEquityFundSetup';
  carriedInterestType: Scalars['String'];
  carry: Scalars['Float'];
  description: Scalars['String'];
  endDate: Scalars['Date'];
  endInvestmentDate: Scalars['Date'];
  hurdleRate: Scalars['Float'];
  id: Scalars['String'];
  managementFee: Scalars['Float'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
};

export type PrivateEquityFundTeamMember = {
  __typename?: 'PrivateEquityFundTeamMember';
  description?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Project = {
  project: ProjectObj;
};

export type ProjectObj = {
  __typename?: 'ProjectObj';
  holdings?: Maybe<Array<Holding>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Transaction>>;
  type?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type ProjectReport = {
  __typename?: 'ProjectReport';
  compactType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['Date']>;
  periodStart?: Maybe<Scalars['Date']>;
  project?: Maybe<ProjectObj>;
  published?: Maybe<Scalars['Boolean']>;
};

export type ProjectSnapshotsInputType = {
  holdings: HoldingSnapshotsInputType;
  portfolioProjects?: InputMaybe<Array<UpsertPortfolioProjectInputType>>;
  projects?: InputMaybe<Array<UpsertProjectInputType>>;
};

export type Query = {
  __typename?: 'Query';
  getAddresses?: Maybe<Array<Address>>;
  getAllocations?: Maybe<Array<Allocation>>;
  getAssetCategories?: Maybe<Array<AssetCategory>>;
  getAssetObjs?: Maybe<Array<AssetObj>>;
  getAssets?: Maybe<Array<Asset>>;
  getBankNoteHoldings?: Maybe<Array<BankNoteHolding>>;
  getBankNotes?: Maybe<Array<BankNote>>;
  getBondHoldings?: Maybe<Array<BondHolding>>;
  getBondPositions?: Maybe<Array<BondPosition>>;
  getBondTrades?: Maybe<Array<BondTrade>>;
  getBonds?: Maybe<Array<Bond>>;
  getCommitments?: Maybe<Array<Commitment>>;
  getCryptoHoldings?: Maybe<Array<CryptoHolding>>;
  getCryptos?: Maybe<Array<Crypto>>;
  getDashboardElements?: Maybe<Array<DashboardElement>>;
  getDashboardMedias?: Maybe<Array<DashboardMedia>>;
  getEmails?: Maybe<Array<Email>>;
  getEntities?: Maybe<Array<Entity>>;
  getEntityMedias?: Maybe<Array<EntityMedia>>;
  getEvents?: Maybe<Array<Event>>;
  getEventsByProject?: Maybe<Array<Event>>;
  getFavoriteHoldings?: Maybe<Array<FavoriteHolding>>;
  getFiles?: Maybe<Array<File>>;
  getFundHoldings?: Maybe<Array<FundHolding>>;
  getFundPositions?: Maybe<Array<FundPosition>>;
  getFundTrades?: Maybe<Array<FundTrade>>;
  getFunds?: Maybe<Array<Fund>>;
  getGeneratedReports?: Maybe<Array<GeneratedReport>>;
  getGeneratedReportsByProject?: Maybe<Array<GeneratedReport>>;
  getHoldingConfigs?: Maybe<Array<HoldingConfig>>;
  getHoldingObjs?: Maybe<Array<HoldingObj>>;
  getHoldings?: Maybe<Array<Holding>>;
  getLoanHoldings?: Maybe<Array<LoanHolding>>;
  getLoans?: Maybe<Array<Loan>>;
  getPeople?: Maybe<Array<Person>>;
  getPhones?: Maybe<Array<Phone>>;
  getPortfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  getPortfolioProjectByProjectId?: Maybe<PortfolioProject>;
  getPortfolioProjects?: Maybe<Array<PortfolioProject>>;
  getPortfolios?: Maybe<Array<Portfolio>>;
  getPrivateEquityFundCapitalCallTransfers?: Maybe<
    Array<PrivateEquityFundCapitalCallTransfer>
  >;
  getPrivateEquityFundCapitalCalls?: Maybe<Array<PrivateEquityFundCapitalCall>>;
  getPrivateEquityFundCapitalCommitments?: Maybe<
    Array<PrivateEquityFundCapitalCommitment>
  >;
  getPrivateEquityFundCapitalDistributionTransfers?: Maybe<
    Array<PrivateEquityFundCapitalDistributionTransfer>
  >;
  getPrivateEquityFundCapitalDistributions?: Maybe<
    Array<PrivateEquityFundCapitalDistribution>
  >;
  getPrivateEquityFundContacts?: Maybe<Array<PrivateEquityFundContact>>;
  getPrivateEquityFundInvestments?: Maybe<Array<PrivateEquityFundInvestment>>;
  getPrivateEquityFundInvestors?: Maybe<Array<PrivateEquityFundInvestor>>;
  getPrivateEquityFundProjectByProjectId?: Maybe<PrivateEquityFundProject>;
  getPrivateEquityFundProjects?: Maybe<Array<PrivateEquityFundProject>>;
  getPrivateEquityFundTeamMembers?: Maybe<Array<PrivateEquityFundTeamMember>>;
  getPrivateEquityFunds?: Maybe<Array<PrivateEquityFund>>;
  getProjectObjs?: Maybe<Array<ProjectObj>>;
  getProjectReports?: Maybe<Array<ProjectReport>>;
  getProjectReportsByProject?: Maybe<Array<ProjectReport>>;
  getProjects?: Maybe<Array<Project>>;
  getRegionAllocations?: Maybe<Array<RegionAllocation>>;
  getReports?: Maybe<Array<Report>>;
  getReportsByProject?: Maybe<Array<Report>>;
  getRoles?: Maybe<Array<Role>>;
  getSchedulers?: Maybe<Array<Scheduler>>;
  getStockGoalRules?: Maybe<Array<StockGoalRule>>;
  getStockHoldings?: Maybe<Array<StockHolding>>;
  getStockPositions?: Maybe<Array<StockPosition>>;
  getStockTrades?: Maybe<Array<StockTrade>>;
  getStocks?: Maybe<Array<Stock>>;
  getTransactions?: Maybe<Array<Transaction>>;
  getTransfers?: Maybe<Array<Transfer>>;
  getValuations?: Maybe<Array<Valuation>>;
  getWarrantHoldings?: Maybe<Array<WarrantHolding>>;
  getWarrants?: Maybe<Array<Warrant>>;
  getWorkspace?: Maybe<Workspace>;
  hello?: Maybe<Scalars['String']>;
};

export type QueryGetAddressesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetAllocationsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetAssetCategoriesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetAssetObjsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetAssetsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetBankNoteHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetBankNotesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetBondHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetBondPositionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetBondTradesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetBondsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetCommitmentsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetCryptoHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetCryptosArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetDashboardElementsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetDashboardMediasArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetEmailsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetEntitiesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetEntityMediasArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetEventsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetEventsByProjectArgs = {
  projectId: Scalars['String'];
};

export type QueryGetFavoriteHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectId: Scalars['String'];
};

export type QueryGetFilesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetFundHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetFundPositionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetFundTradesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetFundsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetGeneratedReportsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetGeneratedReportsByProjectArgs = {
  projectId: Scalars['String'];
};

export type QueryGetHoldingConfigsArgs = {
  holdingId?: InputMaybe<Scalars['String']>;
};

export type QueryGetHoldingObjsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetLoanHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetLoansArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPeopleArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPhonesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPortfolioConfigsArgs = {
  portfolioId?: InputMaybe<Scalars['String']>;
};

export type QueryGetPortfolioProjectByProjectIdArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type QueryGetPortfolioProjectsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPortfoliosArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundCapitalCallTransfersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundCapitalCallsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundCapitalCommitmentsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundCapitalDistributionTransfersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundCapitalDistributionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundContactsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundInvestmentsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundInvestorsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundProjectByProjectIdArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};

export type QueryGetPrivateEquityFundProjectsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundTeamMembersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPrivateEquityFundsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetProjectObjsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetProjectReportsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetProjectReportsByProjectArgs = {
  projectId: Scalars['String'];
};

export type QueryGetProjectsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetRegionAllocationsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetReportsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetReportsByProjectArgs = {
  projectId: Scalars['String'];
};

export type QueryGetRolesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetSchedulersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetStockGoalRulesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetStockHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetStockPositionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetStockTradesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetStocksArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetTransactionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetTransfersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetValuationsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetWarrantHoldingsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetWarrantsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RegionAllocation = {
  __typename?: 'RegionAllocation';
  allocation?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  entity?: Maybe<Entity>;
  id: Scalars['String'];
  region?: Maybe<Region>;
};

export type Report = {
  __typename?: 'Report';
  description?: Maybe<Scalars['String']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectObj>;
  scheduler?: Maybe<Scheduler>;
  users?: Maybe<Array<User>>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  workspace?: Maybe<Workspace>;
};

export type Scheduler = {
  __typename?: 'Scheduler';
  cron: Scalars['String'];
  id: Scalars['String'];
};

export type Stock = Asset & {
  __typename?: 'Stock';
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
  id: Scalars['String'];
  paperId?: Maybe<Scalars['String']>;
};

export type StockGoalRule = {
  __typename?: 'StockGoalRule';
  goalRule: GoalRule;
  id: Scalars['String'];
  stockHolding?: Maybe<StockHoldingBase>;
};

export type StockHolding = Holding & {
  __typename?: 'StockHolding';
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  id: Scalars['String'];
  stock?: Maybe<Stock>;
  stockGoalRules?: Maybe<Array<StockGoalRule>>;
  stockPositions?: Maybe<Array<StockPosition>>;
  stockTrades?: Maybe<Array<StockTrade>>;
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type StockHoldingBase = {
  __typename?: 'StockHoldingBase';
  id: Scalars['String'];
};

export type StockPosition = {
  __typename?: 'StockPosition';
  date?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  shares?: Maybe<Scalars['Float']>;
  stockHolding?: Maybe<StockHoldingBase>;
};

export type StockTrade = {
  __typename?: 'StockTrade';
  id: Scalars['String'];
  position?: Maybe<StockPosition>;
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  stockHolding?: Maybe<StockHoldingBase>;
  transfer?: Maybe<Transfer>;
};

export type TenantBase = {
  __typename?: 'TenantBase';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  project?: Maybe<ProjectObj>;
  title?: Maybe<Scalars['String']>;
  transfers?: Maybe<Array<Transfer>>;
};

export type Transfer = {
  __typename?: 'Transfer';
  commitment?: Maybe<Commitment>;
  cost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  status?: Maybe<Scalars['Int']>;
  subtype?: Maybe<Scalars['String']>;
  transaction?: Maybe<Transaction>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type UpdateAddressInputType = {
  administrativeAreaLevel1?: InputMaybe<Scalars['String']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateAllocationInputType = {
  assetClass?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  portfolioId: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetCategoryInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetInputType = {
  ISIN?: InputMaybe<Scalars['String']>;
  VPS?: InputMaybe<Scalars['String']>;
  assetClass?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lookup?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateBankNoteHoldingInputType = {
  bankNoteId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateBankNoteInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateBondHoldingInputType = {
  bondId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateBondInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type UpdateBondPositionInputType = {
  bondHoldingId: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['String'];
  shares: Scalars['Float'];
};

export type UpdateBondTradeInputType = {
  bondHoldingId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transferId: Scalars['String'];
};

export type UpdateCommitmentInputType = {
  commitmentDate?: InputMaybe<Scalars['Date']>;
  currencyId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  transferIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateCryptoHoldingInputType = {
  cryptoId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateCryptoInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
};

export type UpdateDashboardElementInputType = {
  config?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateDashboardMediaInputType = {
  dashboardElementId: Scalars['String'];
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateEmailInputType = {
  id: Scalars['String'];
  personId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateEntityInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  regionAllocationRelations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  sector?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateEntityMediaInputType = {
  entityId?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateEventInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  fileIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  holdingIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  portfolioIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  projectId?: InputMaybe<Scalars['String']>;
  transactionIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type UpdateFavoriteHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateFileInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateFundHoldingInputType = {
  fundId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateFundInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type UpdateFundPositionInputType = {
  date: Scalars['Date'];
  fundHoldingId: Scalars['String'];
  id: Scalars['String'];
  shares: Scalars['Float'];
};

export type UpdateFundTradeInputType = {
  fundHoldingId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transferId: Scalars['String'];
};

export type UpdateGeneratedReportInputType = {
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  objectKey?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  reportId?: InputMaybe<Scalars['String']>;
};

export type UpdateHoldingConfigInputType = {
  currentDashboardConfigId?: InputMaybe<Scalars['String']>;
  holdingDashboardConfigOperations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['Int'];
};

export type UpdateHoldingDashboardConfigInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations?: InputMaybe<
    Array<MultipleRelationOperationStringInputType>
  >;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateHoldingInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateLoanHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  loanId: Scalars['String'];
};

export type UpdateLoanInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  interest?: InputMaybe<Scalars['Float']>;
};

export type UpdatePersonInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type UpdatePhoneInputType = {
  id: Scalars['String'];
  personId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type UpdatePortfolioConfigInputType = {
  currentDashboardConfigId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  portfolioDashboardConfigOperations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type UpdatePortfolioDashboardConfigInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations?: InputMaybe<
    Array<MultipleRelationOperationStringInputType>
  >;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePortfolioInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingRelations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  parentPortfolioId?: InputMaybe<Scalars['String']>;
  portfoliosIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdatePortfolioProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
  portfolioId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type UpdatePrivateEquityFundCapitalCallInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  fundId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdatePrivateEquityFundCapitalCallTransferInputType = {
  capitalCallId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  transferId?: InputMaybe<Scalars['String']>;
};

export type UpdatePrivateEquityFundCapitalCommitmentInputType = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdatePrivateEquityFundCapitalDistributionInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdatePrivateEquityFundCapitalDistributionTransferInputType = {
  capitalDistributionId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  transferId?: InputMaybe<Scalars['String']>;
};

export type UpdatePrivateEquityFundContactInputType = {
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatePrivateEquityFundInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdatePrivateEquityFundInvestmentInputType = {
  description: Scalars['String'];
  exit?: InputMaybe<Scalars['Date']>;
  fundId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdatePrivateEquityFundInvestorInputType = {
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatePrivateEquityFundProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
  privateEquityFundId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type UpdatePrivateEquityFundTeamMemberInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateProjectInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProjectReportInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations: Array<MultipleRelationOperationStringInputType>;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  periodEnd?: InputMaybe<Scalars['Date']>;
  periodStart?: InputMaybe<Scalars['Date']>;
  projectId?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRegionAllocationInputType = {
  allocation?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['Date']>;
  entityId: Scalars['String'];
  id: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
};

export type UpdateReportInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  schedulerId?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<MultipleRelationOperationIntInputType>>>;
};

export type UpdateRoleInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
};

export type UpdateSchedulerInputType = {
  cron: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateStockGoalRuleInputType = {
  goalRule: GoalRuleInputType;
  id: Scalars['String'];
  stockHoldingId: Scalars['String'];
};

export type UpdateStockHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  stockId: Scalars['String'];
};

export type UpdateStockInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type UpdateStockPositionInputType = {
  date: Scalars['Date'];
  id: Scalars['String'];
  shares: Scalars['Float'];
  stockHoldingId: Scalars['String'];
};

export type UpdateStockTradeInputType = {
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  stockHoldingId: Scalars['String'];
  transferId: Scalars['String'];
};

export type UpdateTransactionInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateTransferInputType = {
  commitmentId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateValuationInputType = {
  currencyId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  ownershipPercentage?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateWarrantHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  warrantId: Scalars['String'];
};

export type UpdateWarrantInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateWorkspaceInputType = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpsertAddressInputType = {
  administrativeAreaLevel1?: InputMaybe<Scalars['String']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type UpsertAllocationInputType = {
  assetClass?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  portfolioId: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['String']>;
};

export type UpsertAssetCategoryInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpsertAssetInputType = {
  ISIN?: InputMaybe<Scalars['String']>;
  VPS?: InputMaybe<Scalars['String']>;
  assetClass?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lookup?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type UpsertBankNoteHoldingInputType = {
  bankNoteId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertBankNoteInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpsertBondHoldingInputType = {
  bondId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertBondInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type UpsertBondPositionInputType = {
  bondHoldingId: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['String'];
  shares: Scalars['Float'];
};

export type UpsertBondTradeInputType = {
  bondHoldingId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transferId: Scalars['String'];
};

export type UpsertCommitmentInputType = {
  commitmentDate?: InputMaybe<Scalars['Date']>;
  currencyId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  transferIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpsertCryptoHoldingInputType = {
  cryptoId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertCryptoInputType = {
  accountNumber?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  bank?: InputMaybe<Scalars['String']>;
  currencyId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
};

export type UpsertDashboardElementInputType = {
  config: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type UpsertDashboardMediaInputType = {
  dashboardElementId: Scalars['String'];
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertEmailInputType = {
  id: Scalars['String'];
  personId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type UpsertEntityInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  regionAllocationRelations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  sector?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpsertEntityMediaInputType = {
  entityId?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpsertEventInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  fileIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  holdingIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  portfolioIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  projectId?: InputMaybe<Scalars['String']>;
  transactionIds?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type UpsertFavoriteHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertFileInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpsertFundHoldingInputType = {
  fundId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertFundInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type UpsertFundPositionInputType = {
  date: Scalars['Date'];
  fundHoldingId: Scalars['String'];
  id: Scalars['String'];
  shares: Scalars['Float'];
};

export type UpsertFundTradeInputType = {
  fundHoldingId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  transferId: Scalars['String'];
};

export type UpsertGeneratedReportInputType = {
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  objectKey?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  reportId?: InputMaybe<Scalars['String']>;
};

export type UpsertHoldingConfigInputType = {
  currentDashboardConfigId?: InputMaybe<Scalars['String']>;
  holdingDashboardConfigOperations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['Int'];
};

export type UpsertHoldingDashboardConfigInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations?: InputMaybe<
    Array<MultipleRelationOperationStringInputType>
  >;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpsertHoldingInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type UpsertLoanHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  loanId: Scalars['String'];
};

export type UpsertLoanInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  interest?: InputMaybe<Scalars['Float']>;
};

export type UpsertPersonInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type UpsertPhoneInputType = {
  id: Scalars['String'];
  personId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type UpsertPortfolioConfigInputType = {
  currentDashboardConfigId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  portfolioDashboardConfigOperations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
};

export type UpsertPortfolioDashboardConfigInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations?: InputMaybe<
    Array<MultipleRelationOperationStringInputType>
  >;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpsertPortfolioInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingRelations?: InputMaybe<
    Array<InputMaybe<MultipleRelationOperationStringInputType>>
  >;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  parentPortfolioId?: InputMaybe<Scalars['String']>;
  portfoliosIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpsertPortfolioProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
  portfolioId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type UpsertPrivateEquityFundCapitalCallInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  fundId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpsertPrivateEquityFundCapitalCallTransferInputType = {
  capitalCallId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  transferId?: InputMaybe<Scalars['String']>;
};

export type UpsertPrivateEquityFundCapitalCommitmentInputType = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpsertPrivateEquityFundCapitalDistributionInputType = {
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpsertPrivateEquityFundCapitalDistributionTransferInputType = {
  capitalDistributionId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  investorId?: InputMaybe<Scalars['String']>;
  transferId?: InputMaybe<Scalars['String']>;
};

export type UpsertPrivateEquityFundContactInputType = {
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpsertPrivateEquityFundInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpsertPrivateEquityFundInvestmentInputType = {
  description: Scalars['String'];
  exit?: InputMaybe<Scalars['Date']>;
  fundId: Scalars['String'];
  holdingId: Scalars['String'];
  id: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpsertPrivateEquityFundInvestorInputType = {
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpsertPrivateEquityFundProjectInputType = {
  id?: InputMaybe<Scalars['String']>;
  privateEquityFundId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type UpsertPrivateEquityFundTeamMemberInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpsertProjectInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpsertProjectReportInputType = {
  compactType?: InputMaybe<Scalars['String']>;
  elementOperations: Array<MultipleRelationOperationStringInputType>;
  id: Scalars['String'];
  layouts?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  periodEnd?: InputMaybe<Scalars['Date']>;
  periodStart?: InputMaybe<Scalars['Date']>;
  projectId?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
};

export type UpsertRegionAllocationInputType = {
  allocation?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['Date']>;
  entityId: Scalars['String'];
  id: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
};

export type UpsertReportInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  schedulerId?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<MultipleRelationOperationIntInputType>>>;
};

export type UpsertRoleInputType = {
  description?: InputMaybe<Scalars['String']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
};

export type UpsertSchedulerInputType = {
  cron: Scalars['String'];
  id: Scalars['String'];
};

export type UpsertStockGoalRuleInputType = {
  goalRule: GoalRuleInputType;
  id: Scalars['String'];
  stockHoldingId: Scalars['String'];
};

export type UpsertStockHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  stockId: Scalars['String'];
};

export type UpsertStockInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paperId?: InputMaybe<Scalars['String']>;
};

export type UpsertStockPositionInputType = {
  date: Scalars['Date'];
  id: Scalars['String'];
  shares: Scalars['Float'];
  stockHoldingId: Scalars['String'];
};

export type UpsertStockTradeInputType = {
  id: Scalars['String'];
  positionId: Scalars['String'];
  sharePrice: Scalars['Float'];
  shares: Scalars['Float'];
  stockHoldingId: Scalars['String'];
  transferId: Scalars['String'];
};

export type UpsertTransactionInputType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  projectId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpsertTransferInputType = {
  commitmentId?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  currencyId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  holdingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpsertValuationInputType = {
  currencyId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  holdingId: Scalars['String'];
  id: Scalars['String'];
  ownershipPercentage?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpsertWarrantHoldingInputType = {
  holdingId: Scalars['String'];
  id: Scalars['String'];
  warrantId: Scalars['String'];
};

export type UpsertWarrantInputType = {
  assetId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Valuation = {
  __typename?: 'Valuation';
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['Date']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  ownershipPercentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type Warrant = Asset & {
  __typename?: 'Warrant';
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type WarrantHolding = Holding & {
  __typename?: 'WarrantHolding';
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  id: Scalars['String'];
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
  warrant?: Maybe<Warrant>;
};

export type Workspace = {
  __typename?: 'Workspace';
  assets?: Maybe<Array<Asset>>;
  entities?: Maybe<Array<Entity>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Person>>;
  portfolios?: Maybe<Array<Portfolio>>;
  projects?: Maybe<Array<Project>>;
  tenant?: Maybe<TenantBase>;
  type?: Maybe<Scalars['String']>;
  workspaceRoles?: Maybe<Array<WorkspaceRole>>;
};

export type WorkspaceBaseWithRoles = {
  __typename?: 'WorkspaceBaseWithRoles';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspaceRoles?: Maybe<Array<WorkspaceRole>>;
};

export type WorkspacePermission = {
  __typename?: 'WorkspacePermission';
  addInvitations?: Maybe<Scalars['Boolean']>;
  addPortfolios?: Maybe<Scalars['Boolean']>;
  addWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  editPortfolios?: Maybe<Scalars['Boolean']>;
  editWorkspaceProperties?: Maybe<Scalars['Boolean']>;
  remove?: Maybe<Scalars['Boolean']>;
  removeInvitations?: Maybe<Scalars['Boolean']>;
  removePortfolios?: Maybe<Scalars['Boolean']>;
  removeWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  updateInvitations?: Maybe<Scalars['Boolean']>;
  updateWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  viewPortfolios?: Maybe<Scalars['Boolean']>;
  viewWorkspaceProperties?: Maybe<Scalars['Boolean']>;
};

export type WorkspaceRole = {
  __typename?: 'WorkspaceRole';
  group?: Maybe<Group>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<WorkspacePermission>;
  workspace?: Maybe<Workspace>;
};

export type WorkspaceSnapshotInputType = {
  assets: AssetSnapshotsInputType;
  name: Scalars['String'];
  portfolios?: InputMaybe<Array<UpsertPortfolioInputType>>;
  projects: ProjectSnapshotsInputType;
};

export type AssetObjFragment = {
  __typename?: 'AssetObj';
  id: string;
  type: string;
  name?: string | null;
  description?: string | null;
  types?: Array<string> | null;
  assetClass?: string | null;
  ISIN?: string | null;
  VPS?: string | null;
  lookup?: string | null;
  category?: { __typename?: 'AssetCategory'; id: string } | null;
  entity?: { __typename?: 'Entity'; id: string } | null;
};

type Asset_BankNote_Fragment = {
  __typename: 'BankNote';
  _type: string;
  id: string;
  symbol?: string | null;
  accountNumber?: string | null;
  bank?: string | null;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

type Asset_Bond_Fragment = {
  __typename: 'Bond';
  _type: string;
  id: string;
  paperId?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

type Asset_Crypto_Fragment = {
  __typename: 'Crypto';
  _type: string;
  id: string;
  symbol?: string | null;
  accountNumber?: string | null;
  bank?: string | null;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

type Asset_Fund_Fragment = {
  __typename: 'Fund';
  _type: string;
  id: string;
  paperId?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

type Asset_Loan_Fragment = {
  __typename: 'Loan';
  _type: string;
  id: string;
  interest?: number | null;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

type Asset_PrivateEquityFund_Fragment = {
  __typename: 'PrivateEquityFund';
  _type: string;
  id: string;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

type Asset_Stock_Fragment = {
  __typename: 'Stock';
  _type: string;
  id: string;
  paperId?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

type Asset_Warrant_Fragment = {
  __typename: 'Warrant';
  _type: string;
  id: string;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type AssetFragment =
  | Asset_BankNote_Fragment
  | Asset_Bond_Fragment
  | Asset_Crypto_Fragment
  | Asset_Fund_Fragment
  | Asset_Loan_Fragment
  | Asset_PrivateEquityFund_Fragment
  | Asset_Stock_Fragment
  | Asset_Warrant_Fragment;

export type BankNoteFragment = {
  __typename: 'BankNote';
  _type: string;
  id: string;
  symbol?: string | null;
  accountNumber?: string | null;
  bank?: string | null;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

export type GetBankNotesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetBankNotesQuery = {
  __typename?: 'Query';
  getBankNotes?: Array<{
    __typename: 'BankNote';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type CreateBankNotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateBankNoteInputType>>
    | InputMaybe<CreateBankNoteInputType>
  >;
}>;

export type CreateBankNotesMutation = {
  __typename?: 'Mutation';
  createBankNotes?: Array<{
    __typename: 'BankNote';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpdateBankNotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateBankNoteInputType>>
    | InputMaybe<UpdateBankNoteInputType>
  >;
}>;

export type UpdateBankNotesMutation = {
  __typename?: 'Mutation';
  updateBankNotes?: Array<{
    __typename: 'BankNote';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpsertBankNotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertBankNoteInputType>>
    | InputMaybe<UpsertBankNoteInputType>
  >;
}>;

export type UpsertBankNotesMutation = {
  __typename?: 'Mutation';
  upsertBankNotes?: Array<{
    __typename: 'BankNote';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type DeleteBankNotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteBankNoteInputType>>
    | InputMaybe<DeleteBankNoteInputType>
  >;
}>;

export type DeleteBankNotesMutation = {
  __typename?: 'Mutation';
  deleteBankNotes?: Array<string> | null;
};

export type BondFragment = {
  __typename: 'Bond';
  _type: string;
  id: string;
  paperId?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type GetBondsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetBondsQuery = {
  __typename?: 'Query';
  getBonds?: Array<{
    __typename: 'Bond';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateBondsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateBondInputType>> | InputMaybe<CreateBondInputType>
  >;
}>;

export type CreateBondsMutation = {
  __typename?: 'Mutation';
  createBonds?: Array<{
    __typename: 'Bond';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdateBondsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateBondInputType>> | InputMaybe<UpdateBondInputType>
  >;
}>;

export type UpdateBondsMutation = {
  __typename?: 'Mutation';
  updateBonds?: Array<{
    __typename: 'Bond';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertBondsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertBondInputType>> | InputMaybe<UpsertBondInputType>
  >;
}>;

export type UpsertBondsMutation = {
  __typename?: 'Mutation';
  upsertBonds?: Array<{
    __typename: 'Bond';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type DeleteBondsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteBondInputType>> | InputMaybe<DeleteBondInputType>
  >;
}>;

export type DeleteBondsMutation = {
  __typename?: 'Mutation';
  deleteBonds?: Array<string> | null;
};

export type CryptoFragment = {
  __typename: 'Crypto';
  _type: string;
  id: string;
  symbol?: string | null;
  accountNumber?: string | null;
  bank?: string | null;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

export type GetCryptosQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCryptosQuery = {
  __typename?: 'Query';
  getCryptos?: Array<{
    __typename: 'Crypto';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type CreateCryptosMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateCryptoInputType>> | InputMaybe<CreateCryptoInputType>
  >;
}>;

export type CreateCryptosMutation = {
  __typename?: 'Mutation';
  createCryptos?: Array<{
    __typename: 'Crypto';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpdateCryptosMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateCryptoInputType>> | InputMaybe<UpdateCryptoInputType>
  >;
}>;

export type UpdateCryptosMutation = {
  __typename?: 'Mutation';
  updateCryptos?: Array<{
    __typename: 'Crypto';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpsertCryptosMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertCryptoInputType>> | InputMaybe<UpsertCryptoInputType>
  >;
}>;

export type UpsertCryptosMutation = {
  __typename?: 'Mutation';
  upsertCryptos?: Array<{
    __typename: 'Crypto';
    _type: string;
    id: string;
    symbol?: string | null;
    accountNumber?: string | null;
    bank?: string | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type DeleteCryptosMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteCryptoInputType>> | InputMaybe<DeleteCryptoInputType>
  >;
}>;

export type DeleteCryptosMutation = {
  __typename?: 'Mutation';
  deleteCryptos?: Array<string> | null;
};

export type FundFragment = {
  __typename: 'Fund';
  _type: string;
  id: string;
  paperId?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type GetFundsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFundsQuery = {
  __typename?: 'Query';
  getFunds?: Array<{
    __typename: 'Fund';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateFundInputType>> | InputMaybe<CreateFundInputType>
  >;
}>;

export type CreateFundsMutation = {
  __typename?: 'Mutation';
  createFunds?: Array<{
    __typename: 'Fund';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdateFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateFundInputType>> | InputMaybe<UpdateFundInputType>
  >;
}>;

export type UpdateFundsMutation = {
  __typename?: 'Mutation';
  updateFunds?: Array<{
    __typename: 'Fund';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertFundInputType>> | InputMaybe<UpsertFundInputType>
  >;
}>;

export type UpsertFundsMutation = {
  __typename?: 'Mutation';
  upsertFunds?: Array<{
    __typename: 'Fund';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type DeleteFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteFundInputType>> | InputMaybe<DeleteFundInputType>
  >;
}>;

export type DeleteFundsMutation = {
  __typename?: 'Mutation';
  deleteFunds?: Array<string> | null;
};

export type LoanFragment = {
  __typename: 'Loan';
  _type: string;
  id: string;
  interest?: number | null;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type GetLoansQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetLoansQuery = {
  __typename?: 'Query';
  getLoans?: Array<{
    __typename: 'Loan';
    _type: string;
    id: string;
    interest?: number | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateLoansMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateLoanInputType>> | InputMaybe<CreateLoanInputType>
  >;
}>;

export type CreateLoansMutation = {
  __typename?: 'Mutation';
  createLoans?: Array<{
    __typename: 'Loan';
    _type: string;
    id: string;
    interest?: number | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdateLoansMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateLoanInputType>> | InputMaybe<UpdateLoanInputType>
  >;
}>;

export type UpdateLoansMutation = {
  __typename?: 'Mutation';
  updateLoans?: Array<{
    __typename: 'Loan';
    _type: string;
    id: string;
    interest?: number | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertLoansMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertLoanInputType>> | InputMaybe<UpsertLoanInputType>
  >;
}>;

export type UpsertLoansMutation = {
  __typename?: 'Mutation';
  upsertLoans?: Array<{
    __typename: 'Loan';
    _type: string;
    id: string;
    interest?: number | null;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type DeleteLoansMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteLoanInputType>> | InputMaybe<DeleteLoanInputType>
  >;
}>;

export type DeleteLoansMutation = {
  __typename?: 'Mutation';
  deleteLoans?: Array<string> | null;
};

export type PrivateEquityFundCapitalCallTransferFragment = {
  __typename?: 'PrivateEquityFundCapitalCallTransfer';
  id: string;
  capitalCall?: {
    __typename?: 'PrivateEquityFundCapitalCall';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  } | null;
  transfer?: {
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  } | null;
  investor?: { __typename?: 'PrivateEquityFundInvestor'; id: string } | null;
};

export type PrivateEquityFundCapitalCallFragment = {
  __typename?: 'PrivateEquityFundCapitalCall';
  id: string;
  name?: string | null;
  description?: string | null;
  date?: any | null;
  value?: number | null;
};

export type GetPrivateEquityFundCapitalCallsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPrivateEquityFundCapitalCallsQuery = {
  __typename?: 'Query';
  getPrivateEquityFundCapitalCalls?: Array<{
    __typename?: 'PrivateEquityFundCapitalCall';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  }> | null;
};

export type CreatePrivateEquityFundCapitalCallsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundCapitalCallInputType>>
    | InputMaybe<CreatePrivateEquityFundCapitalCallInputType>
  >;
}>;

export type CreatePrivateEquityFundCapitalCallsMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFundCapitalCalls?: Array<{
    __typename?: 'PrivateEquityFundCapitalCall';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  }> | null;
};

export type UpdatePrivateEquityFundCapitalCallsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundCapitalCallInputType>>
    | InputMaybe<UpdatePrivateEquityFundCapitalCallInputType>
  >;
}>;

export type UpdatePrivateEquityFundCapitalCallsMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFundCapitalCalls?: Array<{
    __typename?: 'PrivateEquityFundCapitalCall';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  }> | null;
};

export type UpsertPrivateEquityFundCapitalCallsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundCapitalCallInputType>>
    | InputMaybe<UpsertPrivateEquityFundCapitalCallInputType>
  >;
}>;

export type UpsertPrivateEquityFundCapitalCallsMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFundCapitalCalls?: Array<{
    __typename?: 'PrivateEquityFundCapitalCall';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  }> | null;
};

export type DeletePrivateEquityFundCapitalCallsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundCapitalCallInputType>>
    | InputMaybe<DeletePrivateEquityFundCapitalCallInputType>
  >;
}>;

export type DeletePrivateEquityFundCapitalCallsMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFundCapitalCalls?: Array<string> | null;
};

export type PrivateEquityFundCapitalCommitmentFragment = {
  __typename?: 'PrivateEquityFundCapitalCommitment';
  id: string;
  date?: any | null;
  value?: number | null;
  investor?: { __typename?: 'PrivateEquityFundInvestor'; id: string } | null;
};

export type PrivateEquityFundCapitalDistributionFragment = {
  __typename?: 'PrivateEquityFundCapitalDistribution';
  id: string;
  name?: string | null;
  description?: string | null;
  date?: any | null;
  value?: number | null;
};

export type PrivateEquityFundCapitalDistributionTransferFragment = {
  __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
  id: string;
  capitalDistribution?: {
    __typename?: 'PrivateEquityFundCapitalDistribution';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  } | null;
  transfer?: {
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  } | null;
  investor?: { __typename?: 'PrivateEquityFundInvestor'; id: string } | null;
};

export type PrivateEquityFundContactFragment = {
  __typename?: 'PrivateEquityFundContact';
  id: string;
  name?: string | null;
  email?: string | null;
};

export type GetPrivateEquityFundContactsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPrivateEquityFundContactsQuery = {
  __typename?: 'Query';
  getPrivateEquityFundContacts?: Array<{
    __typename?: 'PrivateEquityFundContact';
    id: string;
    name?: string | null;
    email?: string | null;
  }> | null;
};

export type CreatePrivateEquityFundContactsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundContactInputType>>
    | InputMaybe<CreatePrivateEquityFundContactInputType>
  >;
}>;

export type CreatePrivateEquityFundContactsMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFundContacts?: Array<{
    __typename?: 'PrivateEquityFundContact';
    id: string;
    name?: string | null;
    email?: string | null;
  }> | null;
};

export type UpdatePrivateEquityFundContactsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundContactInputType>>
    | InputMaybe<UpdatePrivateEquityFundContactInputType>
  >;
}>;

export type UpdatePrivateEquityFundContactsMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFundContacts?: Array<{
    __typename?: 'PrivateEquityFundContact';
    id: string;
    name?: string | null;
    email?: string | null;
  }> | null;
};

export type UpsertPrivateEquityFundContactsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundContactInputType>>
    | InputMaybe<UpsertPrivateEquityFundContactInputType>
  >;
}>;

export type UpsertPrivateEquityFundContactsMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFundContacts?: Array<{
    __typename?: 'PrivateEquityFundContact';
    id: string;
    name?: string | null;
    email?: string | null;
  }> | null;
};

export type DeletePrivateEquityFundContactsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundContactInputType>>
    | InputMaybe<DeletePrivateEquityFundContactInputType>
  >;
}>;

export type DeletePrivateEquityFundContactsMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFundContacts?: Array<string> | null;
};

export type PrivateEquityFundInvestmentFragment = {
  __typename?: 'PrivateEquityFundInvestment';
  id: string;
  name: string;
  description: string;
  exit?: any | null;
  image?: string | null;
  holding:
    | {
        __typename: 'BankNoteHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'BondHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        bond?: { __typename: 'Bond'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        bondPositions?: Array<{
          __typename?: 'BondPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
        }> | null;
        bondTrades?: Array<{
          __typename?: 'BondTrade';
          id: string;
          shares: number;
          sharePrice: number;
          bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          position?: { __typename?: 'BondPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
      }
    | {
        __typename: 'CryptoHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'FundHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        fund?: { __typename: 'Fund'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        fundPositions?: Array<{
          __typename?: 'FundPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
        }> | null;
        fundTrades?: Array<{
          __typename?: 'FundTrade';
          id: string;
          shares: number;
          sharePrice: number;
          fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          position?: { __typename?: 'FundPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
      }
    | {
        __typename: 'LoanHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        loan?: { __typename: 'Loan'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'StockHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        stock?: { __typename: 'Stock'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        stockPositions?: Array<{
          __typename?: 'StockPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
        }> | null;
        stockTrades?: Array<{
          __typename?: 'StockTrade';
          id: string;
          shares: number;
          sharePrice: number;
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
          position?: { __typename?: 'StockPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
        stockGoalRules?: Array<{
          __typename?: 'StockGoalRule';
          id: string;
          goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
        }> | null;
      }
    | {
        __typename: 'WarrantHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      };
};

export type GetPrivateEquityFundInvestmentsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPrivateEquityFundInvestmentsQuery = {
  __typename?: 'Query';
  getPrivateEquityFundInvestments?: Array<{
    __typename?: 'PrivateEquityFundInvestment';
    id: string;
    name: string;
    description: string;
    exit?: any | null;
    image?: string | null;
    holding:
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        };
  }> | null;
};

export type CreatePrivateEquityFundInvestmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundInvestmentInputType>>
    | InputMaybe<CreatePrivateEquityFundInvestmentInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePrivateEquityFundInvestmentsMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFundInvestments?: Array<{
    __typename?: 'PrivateEquityFundInvestment';
    id: string;
    name: string;
    description: string;
    exit?: any | null;
    image?: string | null;
    holding:
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        };
  }> | null;
};

export type UpdatePrivateEquityFundInvestmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundInvestmentInputType>>
    | InputMaybe<UpdatePrivateEquityFundInvestmentInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePrivateEquityFundInvestmentsMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFundInvestments?: Array<{
    __typename?: 'PrivateEquityFundInvestment';
    id: string;
    name: string;
    description: string;
    exit?: any | null;
    image?: string | null;
    holding:
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        };
  }> | null;
};

export type UpsertPrivateEquityFundInvestmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundInvestmentInputType>>
    | InputMaybe<UpsertPrivateEquityFundInvestmentInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertPrivateEquityFundInvestmentsMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFundInvestments?: Array<{
    __typename?: 'PrivateEquityFundInvestment';
    id: string;
    name: string;
    description: string;
    exit?: any | null;
    image?: string | null;
    holding:
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        };
  }> | null;
};

export type DeletePrivateEquityFundInvestmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundInvestmentInputType>>
    | InputMaybe<DeletePrivateEquityFundInvestmentInputType>
  >;
}>;

export type DeletePrivateEquityFundInvestmentsMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFundInvestments?: Array<string> | null;
};

export type PrivateEquityFundInvestorFragment = {
  __typename?: 'PrivateEquityFundInvestor';
  id: string;
  name?: string | null;
  description?: string | null;
  commitments?: Array<{
    __typename?: 'PrivateEquityFundCapitalCommitment';
    id: string;
    date?: any | null;
    value?: number | null;
    investor?: { __typename?: 'PrivateEquityFundInvestor'; id: string } | null;
  }> | null;
  capitalCallTransfer?: Array<{
    __typename?: 'PrivateEquityFundCapitalCallTransfer';
    id: string;
    capitalCall?: {
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    } | null;
    transfer?: {
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    } | null;
    investor?: { __typename?: 'PrivateEquityFundInvestor'; id: string } | null;
  }> | null;
  capitalDistributionTransfer?: Array<{
    __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
    id: string;
    capitalDistribution?: {
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    } | null;
    transfer?: {
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    } | null;
    investor?: { __typename?: 'PrivateEquityFundInvestor'; id: string } | null;
  }> | null;
  contacts?: Array<{
    __typename?: 'PrivateEquityFundContact';
    id: string;
    name?: string | null;
    email?: string | null;
  }> | null;
  users?: Array<{
    __typename?: 'User';
    id?: number | null;
    name?: string | null;
    email?: string | null;
  }> | null;
};

export type GetPrivateEquityFundInvestorsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPrivateEquityFundInvestorsQuery = {
  __typename?: 'Query';
  getPrivateEquityFundInvestors?: Array<{
    __typename?: 'PrivateEquityFundInvestor';
    id: string;
    name?: string | null;
    description?: string | null;
    commitments?: Array<{
      __typename?: 'PrivateEquityFundCapitalCommitment';
      id: string;
      date?: any | null;
      value?: number | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalCallTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalCallTransfer';
      id: string;
      capitalCall?: {
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalDistributionTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
      id: string;
      capitalDistribution?: {
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    contacts?: Array<{
      __typename?: 'PrivateEquityFundContact';
      id: string;
      name?: string | null;
      email?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    }> | null;
  }> | null;
};

export type CreatePrivateEquityFundInvestorsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundInvestorInputType>>
    | InputMaybe<CreatePrivateEquityFundInvestorInputType>
  >;
}>;

export type CreatePrivateEquityFundInvestorsMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFundInvestors?: Array<{
    __typename?: 'PrivateEquityFundInvestor';
    id: string;
    name?: string | null;
    description?: string | null;
    commitments?: Array<{
      __typename?: 'PrivateEquityFundCapitalCommitment';
      id: string;
      date?: any | null;
      value?: number | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalCallTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalCallTransfer';
      id: string;
      capitalCall?: {
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalDistributionTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
      id: string;
      capitalDistribution?: {
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    contacts?: Array<{
      __typename?: 'PrivateEquityFundContact';
      id: string;
      name?: string | null;
      email?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    }> | null;
  }> | null;
};

export type UpdatePrivateEquityFundInvestorsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundInvestorInputType>>
    | InputMaybe<UpdatePrivateEquityFundInvestorInputType>
  >;
}>;

export type UpdatePrivateEquityFundInvestorsMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFundInvestors?: Array<{
    __typename?: 'PrivateEquityFundInvestor';
    id: string;
    name?: string | null;
    description?: string | null;
    commitments?: Array<{
      __typename?: 'PrivateEquityFundCapitalCommitment';
      id: string;
      date?: any | null;
      value?: number | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalCallTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalCallTransfer';
      id: string;
      capitalCall?: {
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalDistributionTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
      id: string;
      capitalDistribution?: {
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    contacts?: Array<{
      __typename?: 'PrivateEquityFundContact';
      id: string;
      name?: string | null;
      email?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    }> | null;
  }> | null;
};

export type UpsertPrivateEquityFundInvestorsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundInvestorInputType>>
    | InputMaybe<UpsertPrivateEquityFundInvestorInputType>
  >;
}>;

export type UpsertPrivateEquityFundInvestorsMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFundInvestors?: Array<{
    __typename?: 'PrivateEquityFundInvestor';
    id: string;
    name?: string | null;
    description?: string | null;
    commitments?: Array<{
      __typename?: 'PrivateEquityFundCapitalCommitment';
      id: string;
      date?: any | null;
      value?: number | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalCallTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalCallTransfer';
      id: string;
      capitalCall?: {
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalDistributionTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
      id: string;
      capitalDistribution?: {
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    contacts?: Array<{
      __typename?: 'PrivateEquityFundContact';
      id: string;
      name?: string | null;
      email?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    }> | null;
  }> | null;
};

export type DeletePrivateEquityFundInvestorsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundInvestorInputType>>
    | InputMaybe<DeletePrivateEquityFundInvestorInputType>
  >;
}>;

export type DeletePrivateEquityFundInvestorsMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFundInvestors?: Array<string> | null;
};

export type PrivateEquityFundShallowFragment = {
  __typename: 'PrivateEquityFund';
  _type: string;
  id: string;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type PrivateEquityFundFragment = {
  __typename: 'PrivateEquityFund';
  _type: string;
  id: string;
  description?: string | null;
  fundSetup?: {
    __typename?: 'PrivateEquityFundSetup';
    id: string;
    name: string;
    description: string;
    managementFee: number;
    hurdleRate: number;
    carry: number;
    carriedInterestType: string;
    startDate: any;
    endDate: any;
    endInvestmentDate: any;
  } | null;
  investors?: Array<{
    __typename?: 'PrivateEquityFundInvestor';
    id: string;
    name?: string | null;
    description?: string | null;
    commitments?: Array<{
      __typename?: 'PrivateEquityFundCapitalCommitment';
      id: string;
      date?: any | null;
      value?: number | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalCallTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalCallTransfer';
      id: string;
      capitalCall?: {
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    capitalDistributionTransfer?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
      id: string;
      capitalDistribution?: {
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      } | null;
      transfer?: {
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      } | null;
      investor?: {
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
      } | null;
    }> | null;
    contacts?: Array<{
      __typename?: 'PrivateEquityFundContact';
      id: string;
      name?: string | null;
      email?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    }> | null;
  }> | null;
  investments?: Array<{
    __typename?: 'PrivateEquityFundInvestment';
    id: string;
    name: string;
    description: string;
    exit?: any | null;
    image?: string | null;
    holding:
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        };
  }> | null;
  capitalCalls?: Array<{
    __typename?: 'PrivateEquityFundCapitalCall';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  }> | null;
  capitalDistributions?: Array<{
    __typename?: 'PrivateEquityFundCapitalDistribution';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    value?: number | null;
  }> | null;
  team?: Array<{
    __typename?: 'PrivateEquityFundTeamMember';
    id: string;
    name?: string | null;
    email?: string | null;
    tel?: string | null;
    title?: string | null;
    description?: string | null;
    education?: string | null;
  }> | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type GetPrivateEquityFundsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPrivateEquityFundsQuery = {
  __typename?: 'Query';
  getPrivateEquityFunds?: Array<{
    __typename: 'PrivateEquityFund';
    _type: string;
    id: string;
    description?: string | null;
    fundSetup?: {
      __typename?: 'PrivateEquityFundSetup';
      id: string;
      name: string;
      description: string;
      managementFee: number;
      hurdleRate: number;
      carry: number;
      carriedInterestType: string;
      startDate: any;
      endDate: any;
      endInvestmentDate: any;
    } | null;
    investors?: Array<{
      __typename?: 'PrivateEquityFundInvestor';
      id: string;
      name?: string | null;
      description?: string | null;
      commitments?: Array<{
        __typename?: 'PrivateEquityFundCapitalCommitment';
        id: string;
        date?: any | null;
        value?: number | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalCallTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalCallTransfer';
        id: string;
        capitalCall?: {
          __typename?: 'PrivateEquityFundCapitalCall';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalDistributionTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
        id: string;
        capitalDistribution?: {
          __typename?: 'PrivateEquityFundCapitalDistribution';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      contacts?: Array<{
        __typename?: 'PrivateEquityFundContact';
        id: string;
        name?: string | null;
        email?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      }> | null;
    }> | null;
    investments?: Array<{
      __typename?: 'PrivateEquityFundInvestment';
      id: string;
      name: string;
      description: string;
      exit?: any | null;
      image?: string | null;
      holding:
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          };
    }> | null;
    capitalCalls?: Array<{
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    capitalDistributions?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    team?: Array<{
      __typename?: 'PrivateEquityFundTeamMember';
      id: string;
      name?: string | null;
      email?: string | null;
      tel?: string | null;
      title?: string | null;
      description?: string | null;
      education?: string | null;
    }> | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type CreatePrivateEquityFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundInputType>>
    | InputMaybe<CreatePrivateEquityFundInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePrivateEquityFundsMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFunds?: Array<{
    __typename: 'PrivateEquityFund';
    _type: string;
    id: string;
    description?: string | null;
    fundSetup?: {
      __typename?: 'PrivateEquityFundSetup';
      id: string;
      name: string;
      description: string;
      managementFee: number;
      hurdleRate: number;
      carry: number;
      carriedInterestType: string;
      startDate: any;
      endDate: any;
      endInvestmentDate: any;
    } | null;
    investors?: Array<{
      __typename?: 'PrivateEquityFundInvestor';
      id: string;
      name?: string | null;
      description?: string | null;
      commitments?: Array<{
        __typename?: 'PrivateEquityFundCapitalCommitment';
        id: string;
        date?: any | null;
        value?: number | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalCallTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalCallTransfer';
        id: string;
        capitalCall?: {
          __typename?: 'PrivateEquityFundCapitalCall';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalDistributionTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
        id: string;
        capitalDistribution?: {
          __typename?: 'PrivateEquityFundCapitalDistribution';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      contacts?: Array<{
        __typename?: 'PrivateEquityFundContact';
        id: string;
        name?: string | null;
        email?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      }> | null;
    }> | null;
    investments?: Array<{
      __typename?: 'PrivateEquityFundInvestment';
      id: string;
      name: string;
      description: string;
      exit?: any | null;
      image?: string | null;
      holding:
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          };
    }> | null;
    capitalCalls?: Array<{
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    capitalDistributions?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    team?: Array<{
      __typename?: 'PrivateEquityFundTeamMember';
      id: string;
      name?: string | null;
      email?: string | null;
      tel?: string | null;
      title?: string | null;
      description?: string | null;
      education?: string | null;
    }> | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdatePrivateEquityFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundInputType>>
    | InputMaybe<UpdatePrivateEquityFundInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePrivateEquityFundsMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFunds?: Array<{
    __typename: 'PrivateEquityFund';
    _type: string;
    id: string;
    description?: string | null;
    fundSetup?: {
      __typename?: 'PrivateEquityFundSetup';
      id: string;
      name: string;
      description: string;
      managementFee: number;
      hurdleRate: number;
      carry: number;
      carriedInterestType: string;
      startDate: any;
      endDate: any;
      endInvestmentDate: any;
    } | null;
    investors?: Array<{
      __typename?: 'PrivateEquityFundInvestor';
      id: string;
      name?: string | null;
      description?: string | null;
      commitments?: Array<{
        __typename?: 'PrivateEquityFundCapitalCommitment';
        id: string;
        date?: any | null;
        value?: number | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalCallTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalCallTransfer';
        id: string;
        capitalCall?: {
          __typename?: 'PrivateEquityFundCapitalCall';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalDistributionTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
        id: string;
        capitalDistribution?: {
          __typename?: 'PrivateEquityFundCapitalDistribution';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      contacts?: Array<{
        __typename?: 'PrivateEquityFundContact';
        id: string;
        name?: string | null;
        email?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      }> | null;
    }> | null;
    investments?: Array<{
      __typename?: 'PrivateEquityFundInvestment';
      id: string;
      name: string;
      description: string;
      exit?: any | null;
      image?: string | null;
      holding:
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          };
    }> | null;
    capitalCalls?: Array<{
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    capitalDistributions?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    team?: Array<{
      __typename?: 'PrivateEquityFundTeamMember';
      id: string;
      name?: string | null;
      email?: string | null;
      tel?: string | null;
      title?: string | null;
      description?: string | null;
      education?: string | null;
    }> | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertPrivateEquityFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundInputType>>
    | InputMaybe<UpsertPrivateEquityFundInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertPrivateEquityFundsMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFunds?: Array<{
    __typename: 'PrivateEquityFund';
    _type: string;
    id: string;
    description?: string | null;
    fundSetup?: {
      __typename?: 'PrivateEquityFundSetup';
      id: string;
      name: string;
      description: string;
      managementFee: number;
      hurdleRate: number;
      carry: number;
      carriedInterestType: string;
      startDate: any;
      endDate: any;
      endInvestmentDate: any;
    } | null;
    investors?: Array<{
      __typename?: 'PrivateEquityFundInvestor';
      id: string;
      name?: string | null;
      description?: string | null;
      commitments?: Array<{
        __typename?: 'PrivateEquityFundCapitalCommitment';
        id: string;
        date?: any | null;
        value?: number | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalCallTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalCallTransfer';
        id: string;
        capitalCall?: {
          __typename?: 'PrivateEquityFundCapitalCall';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalDistributionTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
        id: string;
        capitalDistribution?: {
          __typename?: 'PrivateEquityFundCapitalDistribution';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      contacts?: Array<{
        __typename?: 'PrivateEquityFundContact';
        id: string;
        name?: string | null;
        email?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      }> | null;
    }> | null;
    investments?: Array<{
      __typename?: 'PrivateEquityFundInvestment';
      id: string;
      name: string;
      description: string;
      exit?: any | null;
      image?: string | null;
      holding:
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          };
    }> | null;
    capitalCalls?: Array<{
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    capitalDistributions?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    team?: Array<{
      __typename?: 'PrivateEquityFundTeamMember';
      id: string;
      name?: string | null;
      email?: string | null;
      tel?: string | null;
      title?: string | null;
      description?: string | null;
      education?: string | null;
    }> | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type DeletePrivateEquityFundsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundInputType>>
    | InputMaybe<DeletePrivateEquityFundInputType>
  >;
}>;

export type DeletePrivateEquityFundsMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFunds?: Array<string> | null;
};

export type PrivateEquityFundSetupFragment = {
  __typename?: 'PrivateEquityFundSetup';
  id: string;
  name: string;
  description: string;
  managementFee: number;
  hurdleRate: number;
  carry: number;
  carriedInterestType: string;
  startDate: any;
  endDate: any;
  endInvestmentDate: any;
};

export type PrivateEquityFundTeamMemberFragment = {
  __typename?: 'PrivateEquityFundTeamMember';
  id: string;
  name?: string | null;
  email?: string | null;
  tel?: string | null;
  title?: string | null;
  description?: string | null;
  education?: string | null;
};

export type GetPrivateEquityFundTeamMembersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPrivateEquityFundTeamMembersQuery = {
  __typename?: 'Query';
  getPrivateEquityFundTeamMembers?: Array<{
    __typename?: 'PrivateEquityFundTeamMember';
    id: string;
    name?: string | null;
    email?: string | null;
    tel?: string | null;
    title?: string | null;
    description?: string | null;
    education?: string | null;
  }> | null;
};

export type CreatePrivateEquityFundTeamMembersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundTeamMemberInputType>>
    | InputMaybe<CreatePrivateEquityFundTeamMemberInputType>
  >;
}>;

export type CreatePrivateEquityFundTeamMembersMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFundTeamMembers?: Array<{
    __typename?: 'PrivateEquityFundTeamMember';
    id: string;
    name?: string | null;
    email?: string | null;
    tel?: string | null;
    title?: string | null;
    description?: string | null;
    education?: string | null;
  }> | null;
};

export type UpdatePrivateEquityFundTeamMembersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundTeamMemberInputType>>
    | InputMaybe<UpdatePrivateEquityFundTeamMemberInputType>
  >;
}>;

export type UpdatePrivateEquityFundTeamMembersMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFundTeamMembers?: Array<{
    __typename?: 'PrivateEquityFundTeamMember';
    id: string;
    name?: string | null;
    email?: string | null;
    tel?: string | null;
    title?: string | null;
    description?: string | null;
    education?: string | null;
  }> | null;
};

export type UpsertPrivateEquityFundTeamMembersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundTeamMemberInputType>>
    | InputMaybe<UpsertPrivateEquityFundTeamMemberInputType>
  >;
}>;

export type UpsertPrivateEquityFundTeamMembersMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFundTeamMembers?: Array<{
    __typename?: 'PrivateEquityFundTeamMember';
    id: string;
    name?: string | null;
    email?: string | null;
    tel?: string | null;
    title?: string | null;
    description?: string | null;
    education?: string | null;
  }> | null;
};

export type DeletePrivateEquityFundTeamMembersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundTeamMemberInputType>>
    | InputMaybe<DeletePrivateEquityFundTeamMemberInputType>
  >;
}>;

export type DeletePrivateEquityFundTeamMembersMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFundTeamMembers?: Array<string> | null;
};

export type StockFragment = {
  __typename: 'Stock';
  _type: string;
  id: string;
  paperId?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type GetStocksQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetStocksQuery = {
  __typename?: 'Query';
  getStocks?: Array<{
    __typename: 'Stock';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateStocksMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateStockInputType>> | InputMaybe<CreateStockInputType>
  >;
}>;

export type CreateStocksMutation = {
  __typename?: 'Mutation';
  createStocks?: Array<{
    __typename: 'Stock';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdateStocksMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateStockInputType>> | InputMaybe<UpdateStockInputType>
  >;
}>;

export type UpdateStocksMutation = {
  __typename?: 'Mutation';
  updateStocks?: Array<{
    __typename: 'Stock';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertStocksMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertStockInputType>> | InputMaybe<UpsertStockInputType>
  >;
}>;

export type UpsertStocksMutation = {
  __typename?: 'Mutation';
  upsertStocks?: Array<{
    __typename: 'Stock';
    _type: string;
    id: string;
    paperId?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type DeleteStocksMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteStockInputType>> | InputMaybe<DeleteStockInputType>
  >;
}>;

export type DeleteStocksMutation = {
  __typename?: 'Mutation';
  deleteStocks?: Array<string> | null;
};

export type WarrantFragment = {
  __typename: 'Warrant';
  _type: string;
  id: string;
  description?: string | null;
  asset?: {
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  } | null;
};

export type GetWarrantsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetWarrantsQuery = {
  __typename?: 'Query';
  getWarrants?: Array<{
    __typename: 'Warrant';
    _type: string;
    id: string;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateWarrantsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateWarrantInputType>>
    | InputMaybe<CreateWarrantInputType>
  >;
}>;

export type CreateWarrantsMutation = {
  __typename?: 'Mutation';
  createWarrants?: Array<{
    __typename: 'Warrant';
    _type: string;
    id: string;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdateWarrantsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateWarrantInputType>>
    | InputMaybe<UpdateWarrantInputType>
  >;
}>;

export type UpdateWarrantsMutation = {
  __typename?: 'Mutation';
  updateWarrants?: Array<{
    __typename: 'Warrant';
    _type: string;
    id: string;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertWarrantsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertWarrantInputType>>
    | InputMaybe<UpsertWarrantInputType>
  >;
}>;

export type UpsertWarrantsMutation = {
  __typename?: 'Mutation';
  upsertWarrants?: Array<{
    __typename: 'Warrant';
    _type: string;
    id: string;
    description?: string | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  }> | null;
};

export type DeleteWarrantsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteWarrantInputType>>
    | InputMaybe<DeleteWarrantInputType>
  >;
}>;

export type DeleteWarrantsMutation = {
  __typename?: 'Mutation';
  deleteWarrants?: Array<string> | null;
};

export type GetAssetsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAssetsQuery = {
  __typename?: 'Query';
  getAssets?: Array<
    | {
        __typename: 'BankNote';
        _type: string;
        id: string;
        symbol?: string | null;
        accountNumber?: string | null;
        bank?: string | null;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
      }
    | {
        __typename: 'Bond';
        _type: string;
        id: string;
        paperId?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Crypto';
        _type: string;
        id: string;
        symbol?: string | null;
        accountNumber?: string | null;
        bank?: string | null;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
      }
    | {
        __typename: 'Fund';
        _type: string;
        id: string;
        paperId?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Loan';
        _type: string;
        id: string;
        interest?: number | null;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'PrivateEquityFund';
        _type: string;
        id: string;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Stock';
        _type: string;
        id: string;
        paperId?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Warrant';
        _type: string;
        id: string;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
  > | null;
};

export type GetAssetObjsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAssetObjsQuery = {
  __typename?: 'Query';
  getAssetObjs?: Array<{
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type CreateAssetsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateAssetInputType>> | InputMaybe<CreateAssetInputType>
  >;
}>;

export type CreateAssetsMutation = {
  __typename?: 'Mutation';
  createAssets?: Array<{
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type UpdateAssetsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateAssetInputType>> | InputMaybe<UpdateAssetInputType>
  >;
}>;

export type UpdateAssetsMutation = {
  __typename?: 'Mutation';
  updateAssets?: Array<{
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type UpsertAssetsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertAssetInputType>> | InputMaybe<UpsertAssetInputType>
  >;
}>;

export type UpsertAssetsMutation = {
  __typename?: 'Mutation';
  upsertAssets?: Array<{
    __typename?: 'AssetObj';
    id: string;
    type: string;
    name?: string | null;
    description?: string | null;
    types?: Array<string> | null;
    assetClass?: string | null;
    ISIN?: string | null;
    VPS?: string | null;
    lookup?: string | null;
    category?: { __typename?: 'AssetCategory'; id: string } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type DeleteAssetsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteAssetInputType>> | InputMaybe<DeleteAssetInputType>
  >;
}>;

export type DeleteAssetsMutation = {
  __typename?: 'Mutation';
  deleteAssets?: Array<string> | null;
};

export type GetAssetCategoriesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAssetCategoriesQuery = {
  __typename?: 'Query';
  getAssetCategories?: Array<{
    __typename?: 'AssetCategory';
    id: string;
    name?: string | null;
  }> | null;
};

export type CreateAssetCategoriesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateAssetCategoryInputType>>
    | InputMaybe<CreateAssetCategoryInputType>
  >;
}>;

export type CreateAssetCategoriesMutation = {
  __typename?: 'Mutation';
  createAssetCategories?: Array<{
    __typename?: 'AssetCategory';
    id: string;
    name?: string | null;
  }> | null;
};

export type UpdateAssetCategoriesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateAssetCategoryInputType>>
    | InputMaybe<UpdateAssetCategoryInputType>
  >;
}>;

export type UpdateAssetCategoriesMutation = {
  __typename?: 'Mutation';
  updateAssetCategories?: Array<{
    __typename?: 'AssetCategory';
    id: string;
    name?: string | null;
  }> | null;
};

export type UpsertAssetCategoriesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertAssetCategoryInputType>>
    | InputMaybe<UpsertAssetCategoryInputType>
  >;
}>;

export type UpsertAssetCategoriesMutation = {
  __typename?: 'Mutation';
  upsertAssetCategories?: Array<{
    __typename?: 'AssetCategory';
    id: string;
    name?: string | null;
  }> | null;
};

export type DeleteAssetCategoriesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteAssetCategoryInputType>>
    | InputMaybe<DeleteAssetCategoryInputType>
  >;
}>;

export type DeleteAssetCategoriesMutation = {
  __typename?: 'Mutation';
  deleteAssetCategories?: Array<string> | null;
};

export type AssetCategoryFragment = {
  __typename?: 'AssetCategory';
  id: string;
  name?: string | null;
};

export type DashboardElementFragment = {
  __typename?: 'DashboardElement';
  id: string;
  name: string;
  type: string;
  config: string;
  media?: {
    __typename?: 'DashboardMedia';
    id: string;
    file?: { __typename?: 'File'; id: string } | null;
  } | null;
};

export type GetDashboardElementsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetDashboardElementsQuery = {
  __typename?: 'Query';
  getDashboardElements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateDashboardElementsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateDashboardElementInputType>>
    | InputMaybe<CreateDashboardElementInputType>
  >;
}>;

export type CreateDashboardElementsMutation = {
  __typename?: 'Mutation';
  createDashboardElements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type UpdateDashboardElementsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateDashboardElementInputType>>
    | InputMaybe<UpdateDashboardElementInputType>
  >;
}>;

export type UpdateDashboardElementsMutation = {
  __typename?: 'Mutation';
  updateDashboardElements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type UpsertDashboardElementsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertDashboardElementInputType>>
    | InputMaybe<UpsertDashboardElementInputType>
  >;
}>;

export type UpsertDashboardElementsMutation = {
  __typename?: 'Mutation';
  upsertDashboardElements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type DeleteDashboardElementsMutationVariables = Exact<{
  inputs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type DeleteDashboardElementsMutation = {
  __typename?: 'Mutation';
  deleteDashboardElements?: Array<string> | null;
};

export type GetDashboardMediasQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetDashboardMediasQuery = {
  __typename?: 'Query';
  getDashboardMedias?: Array<{
    __typename?: 'DashboardMedia';
    id: string;
    file?: { __typename?: 'File'; id: string } | null;
  }> | null;
};

export type CreateDashboardMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateDashboardMediaInputType>>
    | InputMaybe<CreateDashboardMediaInputType>
  >;
}>;

export type CreateDashboardMediasMutation = {
  __typename?: 'Mutation';
  createDashboardMedias?: Array<{
    __typename?: 'DashboardMedia';
    id: string;
    file?: { __typename?: 'File'; id: string } | null;
  }> | null;
};

export type UpdateDashboardMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateDashboardMediaInputType>>
    | InputMaybe<UpdateDashboardMediaInputType>
  >;
}>;

export type UpdateDashboardMediasMutation = {
  __typename?: 'Mutation';
  updateDashboardMedias?: Array<{
    __typename?: 'DashboardMedia';
    id: string;
    file?: { __typename?: 'File'; id: string } | null;
  }> | null;
};

export type UpsertDashboardMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertDashboardMediaInputType>>
    | InputMaybe<UpsertDashboardMediaInputType>
  >;
}>;

export type UpsertDashboardMediasMutation = {
  __typename?: 'Mutation';
  upsertDashboardMedias?: Array<{
    __typename?: 'DashboardMedia';
    id: string;
    file?: { __typename?: 'File'; id: string } | null;
  }> | null;
};

export type DeleteDashboardMediasMutationVariables = Exact<{
  inputs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type DeleteDashboardMediasMutation = {
  __typename?: 'Mutation';
  deleteDashboardMedias?: Array<string> | null;
};

export type DashboardMediaFragment = {
  __typename?: 'DashboardMedia';
  id: string;
  file?: { __typename?: 'File'; id: string } | null;
};

export type SchedulerFragment = {
  __typename?: 'Scheduler';
  id: string;
  cron: string;
};

export type GetSchedulersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetSchedulersQuery = {
  __typename?: 'Query';
  getSchedulers?: Array<{
    __typename?: 'Scheduler';
    id: string;
    cron: string;
  }> | null;
};

export type CreateSchedulersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateSchedulerInputType>>
    | InputMaybe<CreateSchedulerInputType>
  >;
}>;

export type CreateSchedulersMutation = {
  __typename?: 'Mutation';
  createSchedulers?: Array<{
    __typename?: 'Scheduler';
    id: string;
    cron: string;
  }> | null;
};

export type UpdateSchedulersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateSchedulerInputType>>
    | InputMaybe<UpdateSchedulerInputType>
  >;
}>;

export type UpdateSchedulersMutation = {
  __typename?: 'Mutation';
  updateSchedulers?: Array<{
    __typename?: 'Scheduler';
    id: string;
    cron: string;
  }> | null;
};

export type UpsertSchedulersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertSchedulerInputType>>
    | InputMaybe<UpsertSchedulerInputType>
  >;
}>;

export type UpsertSchedulersMutation = {
  __typename?: 'Mutation';
  upsertSchedulers?: Array<{
    __typename?: 'Scheduler';
    id: string;
    cron: string;
  }> | null;
};

export type DeleteSchedulersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteSchedulerInputType>>
    | InputMaybe<DeleteSchedulerInputType>
  >;
}>;

export type DeleteSchedulersMutation = {
  __typename?: 'Mutation';
  deleteSchedulers?: Array<string> | null;
};

export type GetEntitiesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetEntitiesQuery = {
  __typename?: 'Query';
  getEntities?: Array<{
    __typename: 'Entity';
    id: string;
    name?: string | null;
    description?: string | null;
    sector?: string | null;
    website?: string | null;
    regionAllocations?: Array<{
      __typename?: 'RegionAllocation';
      id: string;
      date?: any | null;
      allocation?: number | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    media?: Array<{
      __typename?: 'EntityMedia';
      id: string;
      file?: {
        __typename?: 'File';
        id: string;
        key?: string | null;
        name?: string | null;
        type?: string | null;
        description?: string | null;
      } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    }> | null;
  }> | null;
};

export type CreateEntitiesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateEntityInputType>> | InputMaybe<CreateEntityInputType>
  >;
}>;

export type CreateEntitiesMutation = {
  __typename?: 'Mutation';
  createEntities?: Array<{
    __typename: 'Entity';
    id: string;
    name?: string | null;
    description?: string | null;
    sector?: string | null;
    website?: string | null;
    regionAllocations?: Array<{
      __typename?: 'RegionAllocation';
      id: string;
      date?: any | null;
      allocation?: number | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    media?: Array<{
      __typename?: 'EntityMedia';
      id: string;
      file?: {
        __typename?: 'File';
        id: string;
        key?: string | null;
        name?: string | null;
        type?: string | null;
        description?: string | null;
      } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpdateEntitiesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateEntityInputType>> | InputMaybe<UpdateEntityInputType>
  >;
}>;

export type UpdateEntitiesMutation = {
  __typename?: 'Mutation';
  updateEntities?: Array<{
    __typename: 'Entity';
    id: string;
    name?: string | null;
    description?: string | null;
    sector?: string | null;
    website?: string | null;
    regionAllocations?: Array<{
      __typename?: 'RegionAllocation';
      id: string;
      date?: any | null;
      allocation?: number | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    media?: Array<{
      __typename?: 'EntityMedia';
      id: string;
      file?: {
        __typename?: 'File';
        id: string;
        key?: string | null;
        name?: string | null;
        type?: string | null;
        description?: string | null;
      } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpsertEntitiesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertEntityInputType>> | InputMaybe<UpsertEntityInputType>
  >;
}>;

export type UpsertEntitiesMutation = {
  __typename?: 'Mutation';
  upsertEntities?: Array<{
    __typename: 'Entity';
    id: string;
    name?: string | null;
    description?: string | null;
    sector?: string | null;
    website?: string | null;
    regionAllocations?: Array<{
      __typename?: 'RegionAllocation';
      id: string;
      date?: any | null;
      allocation?: number | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    media?: Array<{
      __typename?: 'EntityMedia';
      id: string;
      file?: {
        __typename?: 'File';
        id: string;
        key?: string | null;
        name?: string | null;
        type?: string | null;
        description?: string | null;
      } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    }> | null;
  }> | null;
};

export type DeleteEntitiesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteEntityInputType>> | InputMaybe<DeleteEntityInputType>
  >;
}>;

export type DeleteEntitiesMutation = {
  __typename?: 'Mutation';
  deleteEntities?: Array<string> | null;
};

export type EntityFragment = {
  __typename: 'Entity';
  id: string;
  name?: string | null;
  description?: string | null;
  sector?: string | null;
  website?: string | null;
  regionAllocations?: Array<{
    __typename?: 'RegionAllocation';
    id: string;
    date?: any | null;
    allocation?: number | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
  media?: Array<{
    __typename?: 'EntityMedia';
    id: string;
    file?: {
      __typename?: 'File';
      id: string;
      key?: string | null;
      name?: string | null;
      type?: string | null;
      description?: string | null;
    } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type EntityMediaFragment = {
  __typename?: 'EntityMedia';
  id: string;
  file?: {
    __typename?: 'File';
    id: string;
    key?: string | null;
    name?: string | null;
    type?: string | null;
    description?: string | null;
  } | null;
  entity?: { __typename?: 'Entity'; id: string } | null;
};

export type GetEntityMediasQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetEntityMediasQuery = {
  __typename?: 'Query';
  getEntityMedias?: Array<{
    __typename?: 'EntityMedia';
    id: string;
    file?: {
      __typename?: 'File';
      id: string;
      key?: string | null;
      name?: string | null;
      type?: string | null;
      description?: string | null;
    } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type CreateEntityMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateEntityMediaInputType>>
    | InputMaybe<CreateEntityMediaInputType>
  >;
}>;

export type CreateEntityMediasMutation = {
  __typename?: 'Mutation';
  createEntityMedias?: Array<{
    __typename?: 'EntityMedia';
    id: string;
    file?: {
      __typename?: 'File';
      id: string;
      key?: string | null;
      name?: string | null;
      type?: string | null;
      description?: string | null;
    } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type UpdateEntityMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateEntityMediaInputType>>
    | InputMaybe<UpdateEntityMediaInputType>
  >;
}>;

export type UpdateEntityMediasMutation = {
  __typename?: 'Mutation';
  updateEntityMedias?: Array<{
    __typename?: 'EntityMedia';
    id: string;
    file?: {
      __typename?: 'File';
      id: string;
      key?: string | null;
      name?: string | null;
      type?: string | null;
      description?: string | null;
    } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type UpsertEntityMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertEntityMediaInputType>>
    | InputMaybe<UpsertEntityMediaInputType>
  >;
}>;

export type UpsertEntityMediasMutation = {
  __typename?: 'Mutation';
  upsertEntityMedias?: Array<{
    __typename?: 'EntityMedia';
    id: string;
    file?: {
      __typename?: 'File';
      id: string;
      key?: string | null;
      name?: string | null;
      type?: string | null;
      description?: string | null;
    } | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
  }> | null;
};

export type DeleteEntityMediasMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteEntityMediaInputType>>
    | InputMaybe<DeleteEntityMediaInputType>
  >;
}>;

export type DeleteEntityMediasMutation = {
  __typename?: 'Mutation';
  deleteEntityMedias?: Array<string> | null;
};

export type RegionAllocationFragment = {
  __typename?: 'RegionAllocation';
  id: string;
  date?: any | null;
  allocation?: number | null;
  entity?: { __typename?: 'Entity'; id: string } | null;
  region?: { __typename?: 'Region'; id: string } | null;
};

export type GetRegionAllocationsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetRegionAllocationsQuery = {
  __typename?: 'Query';
  getRegionAllocations?: Array<{
    __typename?: 'RegionAllocation';
    id: string;
    date?: any | null;
    allocation?: number | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
};

export type CreateRegionAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateRegionAllocationInputType>>
    | InputMaybe<CreateRegionAllocationInputType>
  >;
}>;

export type CreateRegionAllocationsMutation = {
  __typename?: 'Mutation';
  createRegionAllocations?: Array<{
    __typename?: 'RegionAllocation';
    id: string;
    date?: any | null;
    allocation?: number | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
};

export type UpdateRegionAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateRegionAllocationInputType>>
    | InputMaybe<UpdateRegionAllocationInputType>
  >;
}>;

export type UpdateRegionAllocationsMutation = {
  __typename?: 'Mutation';
  updateRegionAllocations?: Array<{
    __typename?: 'RegionAllocation';
    id: string;
    date?: any | null;
    allocation?: number | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
};

export type UpsertRegionAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertRegionAllocationInputType>>
    | InputMaybe<UpsertRegionAllocationInputType>
  >;
}>;

export type UpsertRegionAllocationsMutation = {
  __typename?: 'Mutation';
  upsertRegionAllocations?: Array<{
    __typename?: 'RegionAllocation';
    id: string;
    date?: any | null;
    allocation?: number | null;
    entity?: { __typename?: 'Entity'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
};

export type DeleteRegionAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteRegionAllocationInputType>>
    | InputMaybe<DeleteRegionAllocationInputType>
  >;
}>;

export type DeleteRegionAllocationsMutation = {
  __typename?: 'Mutation';
  deleteRegionAllocations?: Array<string> | null;
};

export type EventFragment = {
  __typename?: 'Event';
  id: string;
  name?: string | null;
  description?: string | null;
  date?: any | null;
  project?: { __typename?: 'ProjectObj'; id: string } | null;
  portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
  entities?: Array<{ __typename?: 'Entity'; id: string }> | null;
  holdings?: Array<{ __typename?: 'HoldingObj'; id: string }> | null;
  transactions?: Array<{ __typename?: 'Transaction'; id: string }> | null;
  files?: Array<{ __typename?: 'File'; id: string }> | null;
};

export type GetEventsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetEventsQuery = {
  __typename?: 'Query';
  getEvents?: Array<{
    __typename?: 'Event';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
    entities?: Array<{ __typename?: 'Entity'; id: string }> | null;
    holdings?: Array<{ __typename?: 'HoldingObj'; id: string }> | null;
    transactions?: Array<{ __typename?: 'Transaction'; id: string }> | null;
    files?: Array<{ __typename?: 'File'; id: string }> | null;
  }> | null;
};

export type GetEventsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type GetEventsByProjectQuery = {
  __typename?: 'Query';
  getEventsByProject?: Array<{
    __typename?: 'Event';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
    entities?: Array<{ __typename?: 'Entity'; id: string }> | null;
    holdings?: Array<{ __typename?: 'HoldingObj'; id: string }> | null;
    transactions?: Array<{ __typename?: 'Transaction'; id: string }> | null;
    files?: Array<{ __typename?: 'File'; id: string }> | null;
  }> | null;
};

export type CreateEventsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateEventInputType>> | InputMaybe<CreateEventInputType>
  >;
}>;

export type CreateEventsMutation = {
  __typename?: 'Mutation';
  createEvents?: Array<{
    __typename?: 'Event';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
    entities?: Array<{ __typename?: 'Entity'; id: string }> | null;
    holdings?: Array<{ __typename?: 'HoldingObj'; id: string }> | null;
    transactions?: Array<{ __typename?: 'Transaction'; id: string }> | null;
    files?: Array<{ __typename?: 'File'; id: string }> | null;
  }> | null;
};

export type UpdateEventsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateEventInputType>> | InputMaybe<UpdateEventInputType>
  >;
}>;

export type UpdateEventsMutation = {
  __typename?: 'Mutation';
  updateEvents?: Array<{
    __typename?: 'Event';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
    entities?: Array<{ __typename?: 'Entity'; id: string }> | null;
    holdings?: Array<{ __typename?: 'HoldingObj'; id: string }> | null;
    transactions?: Array<{ __typename?: 'Transaction'; id: string }> | null;
    files?: Array<{ __typename?: 'File'; id: string }> | null;
  }> | null;
};

export type UpsertEventsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertEventInputType>> | InputMaybe<UpsertEventInputType>
  >;
}>;

export type UpsertEventsMutation = {
  __typename?: 'Mutation';
  upsertEvents?: Array<{
    __typename?: 'Event';
    id: string;
    name?: string | null;
    description?: string | null;
    date?: any | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
    entities?: Array<{ __typename?: 'Entity'; id: string }> | null;
    holdings?: Array<{ __typename?: 'HoldingObj'; id: string }> | null;
    transactions?: Array<{ __typename?: 'Transaction'; id: string }> | null;
    files?: Array<{ __typename?: 'File'; id: string }> | null;
  }> | null;
};

export type DeleteEventsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteEventInputType>> | InputMaybe<DeleteEventInputType>
  >;
}>;

export type DeleteEventsMutation = {
  __typename?: 'Mutation';
  deleteEvents?: Array<string> | null;
};

export type FileFragment = {
  __typename?: 'File';
  id: string;
  key?: string | null;
  name?: string | null;
  type?: string | null;
  description?: string | null;
};

export type GetFilesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFilesQuery = {
  __typename?: 'Query';
  getFiles?: Array<{
    __typename?: 'File';
    id: string;
    key?: string | null;
    name?: string | null;
    type?: string | null;
    description?: string | null;
  }> | null;
};

export type CreateFilesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateFileInputType>> | InputMaybe<CreateFileInputType>
  >;
}>;

export type CreateFilesMutation = {
  __typename?: 'Mutation';
  createFiles?: Array<{
    __typename?: 'File';
    id: string;
    key?: string | null;
    name?: string | null;
    type?: string | null;
    description?: string | null;
  }> | null;
};

export type UpdateFilesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateFileInputType>> | InputMaybe<UpdateFileInputType>
  >;
}>;

export type UpdateFilesMutation = {
  __typename?: 'Mutation';
  updateFiles?: Array<{
    __typename?: 'File';
    id: string;
    key?: string | null;
    name?: string | null;
    type?: string | null;
    description?: string | null;
  }> | null;
};

export type UpsertFilesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertFileInputType>> | InputMaybe<UpsertFileInputType>
  >;
}>;

export type UpsertFilesMutation = {
  __typename?: 'Mutation';
  upsertFiles?: Array<{
    __typename?: 'File';
    id: string;
    key?: string | null;
    name?: string | null;
    type?: string | null;
    description?: string | null;
  }> | null;
};

export type DeleteFilesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteFileInputType>> | InputMaybe<DeleteFileInputType>
  >;
}>;

export type DeleteFilesMutation = {
  __typename?: 'Mutation';
  deleteFiles?: Array<string> | null;
};

export type GroupFragment = {
  __typename?: 'Group';
  id?: number | null;
  name?: string | null;
  users?: Array<{
    __typename?: 'User';
    id?: number | null;
    name?: string | null;
    email?: string | null;
  } | null> | null;
};

export type HelloQueryVariables = Exact<{ [key: string]: never }>;

export type HelloQuery = { __typename?: 'Query'; hello?: string | null };

export type CommitmentFragment = {
  __typename?: 'Commitment';
  id: string;
  commitmentDate?: any | null;
  startDate?: any | null;
  endDate?: any | null;
  value?: number | null;
  type?: string | null;
  description?: string | null;
  holding?: { __typename?: 'HoldingObj'; id: string } | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
  }> | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

export type GetCommitmentsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCommitmentsQuery = {
  __typename?: 'Query';
  getCommitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type CreateCommitmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateCommitmentInputType>>
    | InputMaybe<CreateCommitmentInputType>
  >;
}>;

export type CreateCommitmentsMutation = {
  __typename?: 'Mutation';
  createCommitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpdateCommitmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateCommitmentInputType>>
    | InputMaybe<UpdateCommitmentInputType>
  >;
}>;

export type UpdateCommitmentsMutation = {
  __typename?: 'Mutation';
  updateCommitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpsertCommitmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertCommitmentInputType>>
    | InputMaybe<UpsertCommitmentInputType>
  >;
}>;

export type UpsertCommitmentsMutation = {
  __typename?: 'Mutation';
  upsertCommitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type DeleteCommitmentsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteCommitmentInputType>>
    | InputMaybe<DeleteCommitmentInputType>
  >;
}>;

export type DeleteCommitmentsMutation = {
  __typename?: 'Mutation';
  deleteCommitments?: Array<string> | null;
};

export type HoldingConfigFragment = {
  __typename?: 'HoldingConfig';
  id: number;
  currentDashboardConfigId?: string | null;
  holdingDashboardConfigs?: Array<{
    __typename?: 'HoldingDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type GetHoldingConfigsQueryVariables = Exact<{
  holdingId: Scalars['String'];
}>;

export type GetHoldingConfigsQuery = {
  __typename?: 'Query';
  getHoldingConfigs?: Array<{
    __typename?: 'HoldingConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    holdingDashboardConfigs?: Array<{
      __typename?: 'HoldingDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type CreateHoldingConfigsMutationVariables = Exact<{
  holdingId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateHoldingConfigInputType>>
    | InputMaybe<CreateHoldingConfigInputType>
  >;
}>;

export type CreateHoldingConfigsMutation = {
  __typename?: 'Mutation';
  createHoldingConfigs?: Array<{
    __typename?: 'HoldingConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    holdingDashboardConfigs?: Array<{
      __typename?: 'HoldingDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UpdateHoldingConfigsMutationVariables = Exact<{
  holdingId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateHoldingConfigInputType>>
    | InputMaybe<UpdateHoldingConfigInputType>
  >;
}>;

export type UpdateHoldingConfigsMutation = {
  __typename?: 'Mutation';
  updateHoldingConfigs?: Array<{
    __typename?: 'HoldingConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    holdingDashboardConfigs?: Array<{
      __typename?: 'HoldingDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UpsertHoldingConfigsMutationVariables = Exact<{
  holdingId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertHoldingConfigInputType>>
    | InputMaybe<UpsertHoldingConfigInputType>
  >;
}>;

export type UpsertHoldingConfigsMutation = {
  __typename?: 'Mutation';
  upsertHoldingConfigs?: Array<{
    __typename?: 'HoldingConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    holdingDashboardConfigs?: Array<{
      __typename?: 'HoldingDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type DeleteHoldingConfigsMutationVariables = Exact<{
  holdingId: Scalars['String'];
  inputs?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteHoldingConfigsMutation = {
  __typename?: 'Mutation';
  deleteHoldingConfigs?: Array<number> | null;
};

export type HoldingDashboardConfigFragment = {
  __typename?: 'HoldingDashboardConfig';
  id: string;
  name?: string | null;
  layouts?: string | null;
  compactType?: string | null;
  elements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type CreateHoldingDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateHoldingDashboardConfigInputType>>
    | InputMaybe<CreateHoldingDashboardConfigInputType>
  >;
}>;

export type CreateHoldingDashboardConfigsMutation = {
  __typename?: 'Mutation';
  createHoldingDashboardConfigs?: Array<{
    __typename?: 'HoldingDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type UpdateHoldingDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateHoldingDashboardConfigInputType>>
    | InputMaybe<UpdateHoldingDashboardConfigInputType>
  >;
}>;

export type UpdateHoldingDashboardConfigsMutation = {
  __typename?: 'Mutation';
  updateHoldingDashboardConfigs?: Array<{
    __typename?: 'HoldingDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type UpsertHoldingDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertHoldingDashboardConfigInputType>>
    | InputMaybe<UpsertHoldingDashboardConfigInputType>
  >;
}>;

export type UpsertHoldingDashboardConfigsMutation = {
  __typename?: 'Mutation';
  upsertHoldingDashboardConfigs?: Array<{
    __typename?: 'HoldingDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type DeleteHoldingDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type DeleteHoldingDashboardConfigsMutation = {
  __typename?: 'Mutation';
  deleteHoldingDashboardConfigs?: Array<string> | null;
};

export type FavoriteHoldingFragment = {
  __typename?: 'FavoriteHolding';
  id: string;
  holding?: { __typename?: 'HoldingObj'; id: string } | null;
};

export type GetFavoriteHoldingsQueryVariables = Exact<{
  projectId: Scalars['String'];
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFavoriteHoldingsQuery = {
  __typename?: 'Query';
  getFavoriteHoldings?: Array<{
    __typename?: 'FavoriteHolding';
    id: string;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
  }> | null;
};

export type CreateFavoriteHoldingsMutationVariables = Exact<{
  projectId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateFavoriteHoldingInputType>>
    | InputMaybe<CreateFavoriteHoldingInputType>
  >;
}>;

export type CreateFavoriteHoldingsMutation = {
  __typename?: 'Mutation';
  createFavoriteHoldings?: Array<{
    __typename?: 'FavoriteHolding';
    id: string;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
  }> | null;
};

export type UpdateFavoriteHoldingsMutationVariables = Exact<{
  projectId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateFavoriteHoldingInputType>>
    | InputMaybe<UpdateFavoriteHoldingInputType>
  >;
}>;

export type UpdateFavoriteHoldingsMutation = {
  __typename?: 'Mutation';
  updateFavoriteHoldings?: Array<{
    __typename?: 'FavoriteHolding';
    id: string;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
  }> | null;
};

export type UpsertFavoriteHoldingsMutationVariables = Exact<{
  projectId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertFavoriteHoldingInputType>>
    | InputMaybe<UpsertFavoriteHoldingInputType>
  >;
}>;

export type UpsertFavoriteHoldingsMutation = {
  __typename?: 'Mutation';
  upsertFavoriteHoldings?: Array<{
    __typename?: 'FavoriteHolding';
    id: string;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
  }> | null;
};

export type DeleteFavoriteHoldingsMutationVariables = Exact<{
  projectId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteFavoriteHoldingInputType>>
    | InputMaybe<DeleteFavoriteHoldingInputType>
  >;
}>;

export type DeleteFavoriteHoldingsMutation = {
  __typename?: 'Mutation';
  deleteFavoriteHoldings?: Array<string> | null;
};

export type BankNoteHoldingFragment = {
  __typename: 'BankNoteHolding';
  id: string;
  bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

export type GetBankNoteHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetBankNoteHoldingsQuery = {
  __typename?: 'Query';
  getBankNoteHoldings?: Array<{
    __typename: 'BankNoteHolding';
    id: string;
    bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type CreateBankNoteHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateBankNoteHoldingInputType>>
    | InputMaybe<CreateBankNoteHoldingInputType>
  >;
}>;

export type CreateBankNoteHoldingsMutation = {
  __typename?: 'Mutation';
  createBankNoteHoldings?: Array<{
    __typename: 'BankNoteHolding';
    id: string;
    bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpdateBankNoteHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateBankNoteHoldingInputType>>
    | InputMaybe<UpdateBankNoteHoldingInputType>
  >;
}>;

export type UpdateBankNoteHoldingsMutation = {
  __typename?: 'Mutation';
  updateBankNoteHoldings?: Array<{
    __typename: 'BankNoteHolding';
    id: string;
    bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpsertBankNoteHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertBankNoteHoldingInputType>>
    | InputMaybe<UpsertBankNoteHoldingInputType>
  >;
}>;

export type UpsertBankNoteHoldingsMutation = {
  __typename?: 'Mutation';
  upsertBankNoteHoldings?: Array<{
    __typename: 'BankNoteHolding';
    id: string;
    bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type DeleteBankNoteHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteBankNoteHoldingInputType>>
    | InputMaybe<DeleteBankNoteHoldingInputType>
  >;
}>;

export type DeleteBankNoteHoldingsMutation = {
  __typename?: 'Mutation';
  deleteBankNoteHoldings?: Array<string> | null;
};

export type BondHoldingFragment = {
  __typename: 'BondHolding';
  id: string;
  bond?: { __typename: 'Bond'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
  bondPositions?: Array<{
    __typename?: 'BondPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  }> | null;
  bondTrades?: Array<{
    __typename?: 'BondTrade';
    id: string;
    shares: number;
    sharePrice: number;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    position?: { __typename?: 'BondPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type BondPositionFragment = {
  __typename?: 'BondPosition';
  id: string;
  shares?: number | null;
  date?: any | null;
  bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
};

export type BondTradeFragment = {
  __typename?: 'BondTrade';
  id: string;
  shares: number;
  sharePrice: number;
  bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  position?: { __typename?: 'BondPosition'; id: string } | null;
  transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
};

export type GetBondHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetBondHoldingsQuery = {
  __typename?: 'Query';
  getBondHoldings?: Array<{
    __typename: 'BondHolding';
    id: string;
    bond?: { __typename: 'Bond'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    bondPositions?: Array<{
      __typename?: 'BondPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    }> | null;
    bondTrades?: Array<{
      __typename?: 'BondTrade';
      id: string;
      shares: number;
      sharePrice: number;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
      position?: { __typename?: 'BondPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type CreateBondHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateBondHoldingInputType>>
    | InputMaybe<CreateBondHoldingInputType>
  >;
}>;

export type CreateBondHoldingsMutation = {
  __typename?: 'Mutation';
  createBondHoldings?: Array<{
    __typename: 'BondHolding';
    id: string;
    bond?: { __typename: 'Bond'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    bondPositions?: Array<{
      __typename?: 'BondPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    }> | null;
    bondTrades?: Array<{
      __typename?: 'BondTrade';
      id: string;
      shares: number;
      sharePrice: number;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
      position?: { __typename?: 'BondPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type UpdateBondHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateBondHoldingInputType>>
    | InputMaybe<UpdateBondHoldingInputType>
  >;
}>;

export type UpdateBondHoldingsMutation = {
  __typename?: 'Mutation';
  updateBondHoldings?: Array<{
    __typename: 'BondHolding';
    id: string;
    bond?: { __typename: 'Bond'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    bondPositions?: Array<{
      __typename?: 'BondPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    }> | null;
    bondTrades?: Array<{
      __typename?: 'BondTrade';
      id: string;
      shares: number;
      sharePrice: number;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
      position?: { __typename?: 'BondPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type UpsertBondHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertBondHoldingInputType>>
    | InputMaybe<UpsertBondHoldingInputType>
  >;
}>;

export type UpsertBondHoldingsMutation = {
  __typename?: 'Mutation';
  upsertBondHoldings?: Array<{
    __typename: 'BondHolding';
    id: string;
    bond?: { __typename: 'Bond'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    bondPositions?: Array<{
      __typename?: 'BondPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    }> | null;
    bondTrades?: Array<{
      __typename?: 'BondTrade';
      id: string;
      shares: number;
      sharePrice: number;
      bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
      position?: { __typename?: 'BondPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type DeleteBondHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteBondHoldingInputType>>
    | InputMaybe<DeleteBondHoldingInputType>
  >;
}>;

export type DeleteBondHoldingsMutation = {
  __typename?: 'Mutation';
  deleteBondHoldings?: Array<string> | null;
};

export type GetBondPositionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetBondPositionsQuery = {
  __typename?: 'Query';
  getBondPositions?: Array<{
    __typename?: 'BondPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  }> | null;
};

export type CreateBondPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateBondPositionInputType>>
    | InputMaybe<CreateBondPositionInputType>
  >;
}>;

export type CreateBondPositionsMutation = {
  __typename?: 'Mutation';
  createBondPositions?: Array<{
    __typename?: 'BondPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  }> | null;
};

export type UpdateBondPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateBondPositionInputType>>
    | InputMaybe<UpdateBondPositionInputType>
  >;
}>;

export type UpdateBondPositionsMutation = {
  __typename?: 'Mutation';
  updateBondPositions?: Array<{
    __typename?: 'BondPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  }> | null;
};

export type UpsertBondPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertBondPositionInputType>>
    | InputMaybe<UpsertBondPositionInputType>
  >;
}>;

export type UpsertBondPositionsMutation = {
  __typename?: 'Mutation';
  upsertBondPositions?: Array<{
    __typename?: 'BondPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  }> | null;
};

export type DeleteBondPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteBondPositionInputType>>
    | InputMaybe<DeleteBondPositionInputType>
  >;
}>;

export type DeleteBondPositionsMutation = {
  __typename?: 'Mutation';
  deleteBondPositions?: Array<string> | null;
};

export type GetBondTradesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetBondTradesQuery = {
  __typename?: 'Query';
  getBondTrades?: Array<{
    __typename?: 'BondTrade';
    id: string;
    shares: number;
    sharePrice: number;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    position?: { __typename?: 'BondPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type CreateBondTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateBondTradeInputType>>
    | InputMaybe<CreateBondTradeInputType>
  >;
}>;

export type CreateBondTradesMutation = {
  __typename?: 'Mutation';
  createBondTrades?: Array<{
    __typename?: 'BondTrade';
    id: string;
    shares: number;
    sharePrice: number;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    position?: { __typename?: 'BondPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type UpdateBondTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateBondTradeInputType>>
    | InputMaybe<UpdateBondTradeInputType>
  >;
}>;

export type UpdateBondTradesMutation = {
  __typename?: 'Mutation';
  updateBondTrades?: Array<{
    __typename?: 'BondTrade';
    id: string;
    shares: number;
    sharePrice: number;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    position?: { __typename?: 'BondPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type UpsertBondTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertBondTradeInputType>>
    | InputMaybe<UpsertBondTradeInputType>
  >;
}>;

export type UpsertBondTradesMutation = {
  __typename?: 'Mutation';
  upsertBondTrades?: Array<{
    __typename?: 'BondTrade';
    id: string;
    shares: number;
    sharePrice: number;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    position?: { __typename?: 'BondPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type DeleteBondTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteBondTradeInputType>>
    | InputMaybe<DeleteBondTradeInputType>
  >;
}>;

export type DeleteBondTradesMutation = {
  __typename?: 'Mutation';
  deleteBondTrades?: Array<string> | null;
};

export type CryptoHoldingFragment = {
  __typename: 'CryptoHolding';
  id: string;
  crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

export type GetCryptoHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCryptoHoldingsQuery = {
  __typename?: 'Query';
  getCryptoHoldings?: Array<{
    __typename: 'CryptoHolding';
    id: string;
    crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type CreateCryptoHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateCryptoHoldingInputType>>
    | InputMaybe<CreateCryptoHoldingInputType>
  >;
}>;

export type CreateCryptoHoldingsMutation = {
  __typename?: 'Mutation';
  createCryptoHoldings?: Array<{
    __typename: 'CryptoHolding';
    id: string;
    crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpdateCryptoHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateCryptoHoldingInputType>>
    | InputMaybe<UpdateCryptoHoldingInputType>
  >;
}>;

export type UpdateCryptoHoldingsMutation = {
  __typename?: 'Mutation';
  updateCryptoHoldings?: Array<{
    __typename: 'CryptoHolding';
    id: string;
    crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpsertCryptoHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertCryptoHoldingInputType>>
    | InputMaybe<UpsertCryptoHoldingInputType>
  >;
}>;

export type UpsertCryptoHoldingsMutation = {
  __typename?: 'Mutation';
  upsertCryptoHoldings?: Array<{
    __typename: 'CryptoHolding';
    id: string;
    crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type DeleteCryptoHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteCryptoHoldingInputType>>
    | InputMaybe<DeleteCryptoHoldingInputType>
  >;
}>;

export type DeleteCryptoHoldingsMutation = {
  __typename?: 'Mutation';
  deleteCryptoHoldings?: Array<string> | null;
};

export type FundHoldingFragment = {
  __typename: 'FundHolding';
  id: string;
  fund?: { __typename: 'Fund'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
  fundPositions?: Array<{
    __typename?: 'FundPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  }> | null;
  fundTrades?: Array<{
    __typename?: 'FundTrade';
    id: string;
    shares: number;
    sharePrice: number;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    position?: { __typename?: 'FundPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type FundPositionFragment = {
  __typename?: 'FundPosition';
  id: string;
  shares?: number | null;
  date?: any | null;
  fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
};

export type FundTradeFragment = {
  __typename?: 'FundTrade';
  id: string;
  shares: number;
  sharePrice: number;
  fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  position?: { __typename?: 'FundPosition'; id: string } | null;
  transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
};

export type GetFundHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFundHoldingsQuery = {
  __typename?: 'Query';
  getFundHoldings?: Array<{
    __typename: 'FundHolding';
    id: string;
    fund?: { __typename: 'Fund'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    fundPositions?: Array<{
      __typename?: 'FundPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    }> | null;
    fundTrades?: Array<{
      __typename?: 'FundTrade';
      id: string;
      shares: number;
      sharePrice: number;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
      position?: { __typename?: 'FundPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type CreateFundHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateFundHoldingInputType>>
    | InputMaybe<CreateFundHoldingInputType>
  >;
}>;

export type CreateFundHoldingsMutation = {
  __typename?: 'Mutation';
  createFundHoldings?: Array<{
    __typename: 'FundHolding';
    id: string;
    fund?: { __typename: 'Fund'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    fundPositions?: Array<{
      __typename?: 'FundPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    }> | null;
    fundTrades?: Array<{
      __typename?: 'FundTrade';
      id: string;
      shares: number;
      sharePrice: number;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
      position?: { __typename?: 'FundPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type UpdateFundHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateFundHoldingInputType>>
    | InputMaybe<UpdateFundHoldingInputType>
  >;
}>;

export type UpdateFundHoldingsMutation = {
  __typename?: 'Mutation';
  updateFundHoldings?: Array<{
    __typename: 'FundHolding';
    id: string;
    fund?: { __typename: 'Fund'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    fundPositions?: Array<{
      __typename?: 'FundPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    }> | null;
    fundTrades?: Array<{
      __typename?: 'FundTrade';
      id: string;
      shares: number;
      sharePrice: number;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
      position?: { __typename?: 'FundPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type UpsertFundHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertFundHoldingInputType>>
    | InputMaybe<UpsertFundHoldingInputType>
  >;
}>;

export type UpsertFundHoldingsMutation = {
  __typename?: 'Mutation';
  upsertFundHoldings?: Array<{
    __typename: 'FundHolding';
    id: string;
    fund?: { __typename: 'Fund'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    fundPositions?: Array<{
      __typename?: 'FundPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    }> | null;
    fundTrades?: Array<{
      __typename?: 'FundTrade';
      id: string;
      shares: number;
      sharePrice: number;
      fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
      position?: { __typename?: 'FundPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
  }> | null;
};

export type DeleteFundHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteFundHoldingInputType>>
    | InputMaybe<DeleteFundHoldingInputType>
  >;
}>;

export type DeleteFundHoldingsMutation = {
  __typename?: 'Mutation';
  deleteFundHoldings?: Array<string> | null;
};

export type GetFundPositionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFundPositionsQuery = {
  __typename?: 'Query';
  getFundPositions?: Array<{
    __typename?: 'FundPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  }> | null;
};

export type CreateFundPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateFundPositionInputType>>
    | InputMaybe<CreateFundPositionInputType>
  >;
}>;

export type CreateFundPositionsMutation = {
  __typename?: 'Mutation';
  createFundPositions?: Array<{
    __typename?: 'FundPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  }> | null;
};

export type UpdateFundPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateFundPositionInputType>>
    | InputMaybe<UpdateFundPositionInputType>
  >;
}>;

export type UpdateFundPositionsMutation = {
  __typename?: 'Mutation';
  updateFundPositions?: Array<{
    __typename?: 'FundPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  }> | null;
};

export type UpsertFundPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertFundPositionInputType>>
    | InputMaybe<UpsertFundPositionInputType>
  >;
}>;

export type UpsertFundPositionsMutation = {
  __typename?: 'Mutation';
  upsertFundPositions?: Array<{
    __typename?: 'FundPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  }> | null;
};

export type DeleteFundPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteFundPositionInputType>>
    | InputMaybe<DeleteFundPositionInputType>
  >;
}>;

export type DeleteFundPositionsMutation = {
  __typename?: 'Mutation';
  deleteFundPositions?: Array<string> | null;
};

export type GetFundTradesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetFundTradesQuery = {
  __typename?: 'Query';
  getFundTrades?: Array<{
    __typename?: 'FundTrade';
    id: string;
    shares: number;
    sharePrice: number;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    position?: { __typename?: 'FundPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type CreateFundTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateFundTradeInputType>>
    | InputMaybe<CreateFundTradeInputType>
  >;
}>;

export type CreateFundTradesMutation = {
  __typename?: 'Mutation';
  createFundTrades?: Array<{
    __typename?: 'FundTrade';
    id: string;
    shares: number;
    sharePrice: number;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    position?: { __typename?: 'FundPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type UpdateFundTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateFundTradeInputType>>
    | InputMaybe<UpdateFundTradeInputType>
  >;
}>;

export type UpdateFundTradesMutation = {
  __typename?: 'Mutation';
  updateFundTrades?: Array<{
    __typename?: 'FundTrade';
    id: string;
    shares: number;
    sharePrice: number;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    position?: { __typename?: 'FundPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type UpsertFundTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertFundTradeInputType>>
    | InputMaybe<UpsertFundTradeInputType>
  >;
}>;

export type UpsertFundTradesMutation = {
  __typename?: 'Mutation';
  upsertFundTrades?: Array<{
    __typename?: 'FundTrade';
    id: string;
    shares: number;
    sharePrice: number;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    position?: { __typename?: 'FundPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type DeleteFundTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteFundTradeInputType>>
    | InputMaybe<DeleteFundTradeInputType>
  >;
}>;

export type DeleteFundTradesMutation = {
  __typename?: 'Mutation';
  deleteFundTrades?: Array<string> | null;
};

export type LoanHoldingFragment = {
  __typename: 'LoanHolding';
  id: string;
  loan?: { __typename: 'Loan'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

export type GetLoanHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetLoanHoldingsQuery = {
  __typename?: 'Query';
  getLoanHoldings?: Array<{
    __typename: 'LoanHolding';
    id: string;
    loan?: { __typename: 'Loan'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type CreateLoanHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateLoanHoldingInputType>>
    | InputMaybe<CreateLoanHoldingInputType>
  >;
}>;

export type CreateLoanHoldingsMutation = {
  __typename?: 'Mutation';
  createLoanHoldings?: Array<{
    __typename: 'LoanHolding';
    id: string;
    loan?: { __typename: 'Loan'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpdateLoanHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateLoanHoldingInputType>>
    | InputMaybe<UpdateLoanHoldingInputType>
  >;
}>;

export type UpdateLoanHoldingsMutation = {
  __typename?: 'Mutation';
  updateLoanHoldings?: Array<{
    __typename: 'LoanHolding';
    id: string;
    loan?: { __typename: 'Loan'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpsertLoanHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertLoanHoldingInputType>>
    | InputMaybe<UpsertLoanHoldingInputType>
  >;
}>;

export type UpsertLoanHoldingsMutation = {
  __typename?: 'Mutation';
  upsertLoanHoldings?: Array<{
    __typename: 'LoanHolding';
    id: string;
    loan?: { __typename: 'Loan'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type DeleteLoanHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteLoanHoldingInputType>>
    | InputMaybe<DeleteLoanHoldingInputType>
  >;
}>;

export type DeleteLoanHoldingsMutation = {
  __typename?: 'Mutation';
  deleteLoanHoldings?: Array<string> | null;
};

export type GoalRuleFragment = {
  __typename?: 'GoalRule';
  name: string;
  formula: string;
};

export type StockGoalRuleFragment = {
  __typename?: 'StockGoalRule';
  id: string;
  goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
  stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
};

export type StockHoldingFragment = {
  __typename: 'StockHolding';
  id: string;
  stock?: { __typename: 'Stock'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
  stockPositions?: Array<{
    __typename?: 'StockPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
  stockTrades?: Array<{
    __typename?: 'StockTrade';
    id: string;
    shares: number;
    sharePrice: number;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    position?: { __typename?: 'StockPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
  stockGoalRules?: Array<{
    __typename?: 'StockGoalRule';
    id: string;
    goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type StockPositionFragment = {
  __typename?: 'StockPosition';
  id: string;
  shares?: number | null;
  date?: any | null;
  stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
};

export type StockTradeFragment = {
  __typename?: 'StockTrade';
  id: string;
  shares: number;
  sharePrice: number;
  stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  position?: { __typename?: 'StockPosition'; id: string } | null;
  transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
};

export type GetStockGoalRulesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetStockGoalRulesQuery = {
  __typename?: 'Query';
  getStockGoalRules?: Array<{
    __typename?: 'StockGoalRule';
    id: string;
    goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type CreateStockGoalRulesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateStockGoalRuleInputType>>
    | InputMaybe<CreateStockGoalRuleInputType>
  >;
}>;

export type CreateStockGoalRulesMutation = {
  __typename?: 'Mutation';
  createStockGoalRules?: Array<{
    __typename?: 'StockGoalRule';
    id: string;
    goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type UpdateStockGoalRulesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateStockGoalRuleInputType>>
    | InputMaybe<UpdateStockGoalRuleInputType>
  >;
}>;

export type UpdateStockGoalRulesMutation = {
  __typename?: 'Mutation';
  updateStockGoalRules?: Array<{
    __typename?: 'StockGoalRule';
    id: string;
    goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type UpsertStockGoalRulesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertStockGoalRuleInputType>>
    | InputMaybe<UpsertStockGoalRuleInputType>
  >;
}>;

export type UpsertStockGoalRulesMutation = {
  __typename?: 'Mutation';
  upsertStockGoalRules?: Array<{
    __typename?: 'StockGoalRule';
    id: string;
    goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type DeleteStockGoalRulesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteStockGoalRuleInputType>>
    | InputMaybe<DeleteStockGoalRuleInputType>
  >;
}>;

export type DeleteStockGoalRulesMutation = {
  __typename?: 'Mutation';
  deleteStockGoalRules?: Array<string> | null;
};

export type GetStockHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetStockHoldingsQuery = {
  __typename?: 'Query';
  getStockHoldings?: Array<{
    __typename: 'StockHolding';
    id: string;
    stock?: { __typename: 'Stock'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    stockPositions?: Array<{
      __typename?: 'StockPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
    stockTrades?: Array<{
      __typename?: 'StockTrade';
      id: string;
      shares: number;
      sharePrice: number;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
      position?: { __typename?: 'StockPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
    stockGoalRules?: Array<{
      __typename?: 'StockGoalRule';
      id: string;
      goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
  }> | null;
};

export type CreateStockHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateStockHoldingInputType>>
    | InputMaybe<CreateStockHoldingInputType>
  >;
}>;

export type CreateStockHoldingsMutation = {
  __typename?: 'Mutation';
  createStockHoldings?: Array<{
    __typename: 'StockHolding';
    id: string;
    stock?: { __typename: 'Stock'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    stockPositions?: Array<{
      __typename?: 'StockPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
    stockTrades?: Array<{
      __typename?: 'StockTrade';
      id: string;
      shares: number;
      sharePrice: number;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
      position?: { __typename?: 'StockPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
    stockGoalRules?: Array<{
      __typename?: 'StockGoalRule';
      id: string;
      goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpdateStockHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateStockHoldingInputType>>
    | InputMaybe<UpdateStockHoldingInputType>
  >;
}>;

export type UpdateStockHoldingsMutation = {
  __typename?: 'Mutation';
  updateStockHoldings?: Array<{
    __typename: 'StockHolding';
    id: string;
    stock?: { __typename: 'Stock'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    stockPositions?: Array<{
      __typename?: 'StockPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
    stockTrades?: Array<{
      __typename?: 'StockTrade';
      id: string;
      shares: number;
      sharePrice: number;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
      position?: { __typename?: 'StockPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
    stockGoalRules?: Array<{
      __typename?: 'StockGoalRule';
      id: string;
      goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpsertStockHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertStockHoldingInputType>>
    | InputMaybe<UpsertStockHoldingInputType>
  >;
}>;

export type UpsertStockHoldingsMutation = {
  __typename?: 'Mutation';
  upsertStockHoldings?: Array<{
    __typename: 'StockHolding';
    id: string;
    stock?: { __typename: 'Stock'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
    stockPositions?: Array<{
      __typename?: 'StockPosition';
      id: string;
      shares?: number | null;
      date?: any | null;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
    stockTrades?: Array<{
      __typename?: 'StockTrade';
      id: string;
      shares: number;
      sharePrice: number;
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
      position?: { __typename?: 'StockPosition'; id: string } | null;
      transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
    }> | null;
    stockGoalRules?: Array<{
      __typename?: 'StockGoalRule';
      id: string;
      goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
      stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    }> | null;
  }> | null;
};

export type DeleteStockHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteStockHoldingInputType>>
    | InputMaybe<DeleteStockHoldingInputType>
  >;
}>;

export type DeleteStockHoldingsMutation = {
  __typename?: 'Mutation';
  deleteStockHoldings?: Array<string> | null;
};

export type GetStockPositionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetStockPositionsQuery = {
  __typename?: 'Query';
  getStockPositions?: Array<{
    __typename?: 'StockPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type CreateStockPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateStockPositionInputType>>
    | InputMaybe<CreateStockPositionInputType>
  >;
}>;

export type CreateStockPositionsMutation = {
  __typename?: 'Mutation';
  createStockPositions?: Array<{
    __typename?: 'StockPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type UpdateStockPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateStockPositionInputType>>
    | InputMaybe<UpdateStockPositionInputType>
  >;
}>;

export type UpdateStockPositionsMutation = {
  __typename?: 'Mutation';
  updateStockPositions?: Array<{
    __typename?: 'StockPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type UpsertStockPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertStockPositionInputType>>
    | InputMaybe<UpsertStockPositionInputType>
  >;
}>;

export type UpsertStockPositionsMutation = {
  __typename?: 'Mutation';
  upsertStockPositions?: Array<{
    __typename?: 'StockPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

export type DeleteStockPositionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteStockPositionInputType>>
    | InputMaybe<DeleteStockPositionInputType>
  >;
}>;

export type DeleteStockPositionsMutation = {
  __typename?: 'Mutation';
  deleteStockPositions?: Array<string> | null;
};

export type GetStockTradesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetStockTradesQuery = {
  __typename?: 'Query';
  getStockTrades?: Array<{
    __typename?: 'StockTrade';
    id: string;
    shares: number;
    sharePrice: number;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    position?: { __typename?: 'StockPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type CreateStockTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateStockTradeInputType>>
    | InputMaybe<CreateStockTradeInputType>
  >;
}>;

export type CreateStockTradesMutation = {
  __typename?: 'Mutation';
  createStockTrades?: Array<{
    __typename?: 'StockTrade';
    id: string;
    shares: number;
    sharePrice: number;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    position?: { __typename?: 'StockPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type UpdateStockTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateStockTradeInputType>>
    | InputMaybe<UpdateStockTradeInputType>
  >;
}>;

export type UpdateStockTradesMutation = {
  __typename?: 'Mutation';
  updateStockTrades?: Array<{
    __typename?: 'StockTrade';
    id: string;
    shares: number;
    sharePrice: number;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    position?: { __typename?: 'StockPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type UpsertStockTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertStockTradeInputType>>
    | InputMaybe<UpsertStockTradeInputType>
  >;
}>;

export type UpsertStockTradesMutation = {
  __typename?: 'Mutation';
  upsertStockTrades?: Array<{
    __typename?: 'StockTrade';
    id: string;
    shares: number;
    sharePrice: number;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    position?: { __typename?: 'StockPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

export type DeleteStockTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteStockTradeInputType>>
    | InputMaybe<DeleteStockTradeInputType>
  >;
}>;

export type DeleteStockTradesMutation = {
  __typename?: 'Mutation';
  deleteStockTrades?: Array<string> | null;
};

export type WarrantHoldingFragment = {
  __typename: 'WarrantHolding';
  id: string;
  warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

export type GetWarrantHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetWarrantHoldingsQuery = {
  __typename?: 'Query';
  getWarrantHoldings?: Array<{
    __typename: 'WarrantHolding';
    id: string;
    warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type CreateWarrantHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateWarrantHoldingInputType>>
    | InputMaybe<CreateWarrantHoldingInputType>
  >;
}>;

export type CreateWarrantHoldingsMutation = {
  __typename?: 'Mutation';
  createWarrantHoldings?: Array<{
    __typename: 'WarrantHolding';
    id: string;
    warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpdateWarrantHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateWarrantHoldingInputType>>
    | InputMaybe<UpdateWarrantHoldingInputType>
  >;
}>;

export type UpdateWarrantHoldingsMutation = {
  __typename?: 'Mutation';
  updateWarrantHoldings?: Array<{
    __typename: 'WarrantHolding';
    id: string;
    warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type UpsertWarrantHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertWarrantHoldingInputType>>
    | InputMaybe<UpsertWarrantHoldingInputType>
  >;
}>;

export type UpsertWarrantHoldingsMutation = {
  __typename?: 'Mutation';
  upsertWarrantHoldings?: Array<{
    __typename: 'WarrantHolding';
    id: string;
    warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
    holding: {
      __typename: 'HoldingObj';
      id: string;
      type?: string | null;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    };
  }> | null;
};

export type DeleteWarrantHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteWarrantHoldingInputType>>
    | InputMaybe<DeleteWarrantHoldingInputType>
  >;
}>;

export type DeleteWarrantHoldingsMutation = {
  __typename?: 'Mutation';
  deleteWarrantHoldings?: Array<string> | null;
};

export type HoldingObjFragment = {
  __typename: 'HoldingObj';
  id: string;
  type?: string | null;
  name?: string | null;
  description?: string | null;
  project?: { __typename?: 'ProjectObj'; id: string } | null;
};

type Holding_BankNoteHolding_Fragment = {
  __typename: 'BankNoteHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

type Holding_BondHolding_Fragment = {
  __typename: 'BondHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  bond?: { __typename: 'Bond'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
  bondPositions?: Array<{
    __typename?: 'BondPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
  }> | null;
  bondTrades?: Array<{
    __typename?: 'BondTrade';
    id: string;
    shares: number;
    sharePrice: number;
    bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
    position?: { __typename?: 'BondPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

type Holding_CryptoHolding_Fragment = {
  __typename: 'CryptoHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

type Holding_FundHolding_Fragment = {
  __typename: 'FundHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  fund?: { __typename: 'Fund'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
  fundPositions?: Array<{
    __typename?: 'FundPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
  }> | null;
  fundTrades?: Array<{
    __typename?: 'FundTrade';
    id: string;
    shares: number;
    sharePrice: number;
    fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
    position?: { __typename?: 'FundPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
};

type Holding_LoanHolding_Fragment = {
  __typename: 'LoanHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  loan?: { __typename: 'Loan'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

type Holding_StockHolding_Fragment = {
  __typename: 'StockHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  stock?: { __typename: 'Stock'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
  stockPositions?: Array<{
    __typename?: 'StockPosition';
    id: string;
    shares?: number | null;
    date?: any | null;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
  stockTrades?: Array<{
    __typename?: 'StockTrade';
    id: string;
    shares: number;
    sharePrice: number;
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
    position?: { __typename?: 'StockPosition'; id: string } | null;
    transfer?: { __typename?: 'Transfer'; id: string; hash: string } | null;
  }> | null;
  stockGoalRules?: Array<{
    __typename?: 'StockGoalRule';
    id: string;
    goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
    stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
  }> | null;
};

type Holding_WarrantHolding_Fragment = {
  __typename: 'WarrantHolding';
  id: string;
  valuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  commitments?: Array<{
    __typename?: 'Commitment';
    id: string;
    commitmentDate?: any | null;
    startDate?: any | null;
    endDate?: any | null;
    value?: number | null;
    type?: string | null;
    description?: string | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
    }> | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
  warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
  holding: {
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  };
};

export type HoldingFragment =
  | Holding_BankNoteHolding_Fragment
  | Holding_BondHolding_Fragment
  | Holding_CryptoHolding_Fragment
  | Holding_FundHolding_Fragment
  | Holding_LoanHolding_Fragment
  | Holding_StockHolding_Fragment
  | Holding_WarrantHolding_Fragment;

export type GetHoldingsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetHoldingsQuery = {
  __typename?: 'Query';
  getHoldings?: Array<
    | {
        __typename: 'BankNoteHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'BondHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        bond?: { __typename: 'Bond'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        bondPositions?: Array<{
          __typename?: 'BondPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
        }> | null;
        bondTrades?: Array<{
          __typename?: 'BondTrade';
          id: string;
          shares: number;
          sharePrice: number;
          bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          position?: { __typename?: 'BondPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
      }
    | {
        __typename: 'CryptoHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'FundHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        fund?: { __typename: 'Fund'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        fundPositions?: Array<{
          __typename?: 'FundPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
        }> | null;
        fundTrades?: Array<{
          __typename?: 'FundTrade';
          id: string;
          shares: number;
          sharePrice: number;
          fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          position?: { __typename?: 'FundPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
      }
    | {
        __typename: 'LoanHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        loan?: { __typename: 'Loan'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'StockHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        stock?: { __typename: 'Stock'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        stockPositions?: Array<{
          __typename?: 'StockPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
        }> | null;
        stockTrades?: Array<{
          __typename?: 'StockTrade';
          id: string;
          shares: number;
          sharePrice: number;
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
          position?: { __typename?: 'StockPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
        stockGoalRules?: Array<{
          __typename?: 'StockGoalRule';
          id: string;
          goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
        }> | null;
      }
    | {
        __typename: 'WarrantHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
  > | null;
};

export type GetHoldingObjsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetHoldingObjsQuery = {
  __typename?: 'Query';
  getHoldingObjs?: Array<{
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  }> | null;
};

export type CreateHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateHoldingInputType>>
    | InputMaybe<CreateHoldingInputType>
  >;
}>;

export type CreateHoldingsMutation = {
  __typename?: 'Mutation';
  createHoldings?: Array<{
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  }> | null;
};

export type UpdateHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateHoldingInputType>>
    | InputMaybe<UpdateHoldingInputType>
  >;
}>;

export type UpdateHoldingsMutation = {
  __typename?: 'Mutation';
  updateHoldings?: Array<{
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  }> | null;
};

export type UpsertHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertHoldingInputType>>
    | InputMaybe<UpsertHoldingInputType>
  >;
}>;

export type UpsertHoldingsMutation = {
  __typename?: 'Mutation';
  upsertHoldings?: Array<{
    __typename: 'HoldingObj';
    id: string;
    type?: string | null;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  }> | null;
};

export type DeleteHoldingsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteHoldingInputType>>
    | InputMaybe<DeleteHoldingInputType>
  >;
}>;

export type DeleteHoldingsMutation = {
  __typename?: 'Mutation';
  deleteHoldings?: Array<string> | null;
};

export type TransactionFragment = {
  __typename?: 'Transaction';
  id: string;
  title?: string | null;
  description?: string | null;
  project?: { __typename?: 'ProjectObj'; id: string } | null;
  transfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
};

export type GetTransactionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTransactionsQuery = {
  __typename?: 'Query';
  getTransactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    title?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    }> | null;
  }> | null;
};

export type CreateTransactionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateTransactionInputType>>
    | InputMaybe<CreateTransactionInputType>
  >;
}>;

export type CreateTransactionsMutation = {
  __typename?: 'Mutation';
  createTransactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    title?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpdateTransactionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateTransactionInputType>>
    | InputMaybe<UpdateTransactionInputType>
  >;
}>;

export type UpdateTransactionsMutation = {
  __typename?: 'Mutation';
  updateTransactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    title?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpsertTransactionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertTransactionInputType>>
    | InputMaybe<UpsertTransactionInputType>
  >;
}>;

export type UpsertTransactionsMutation = {
  __typename?: 'Mutation';
  upsertTransactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    title?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    }> | null;
  }> | null;
};

export type DeleteTransactionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteTransactionInputType>>
    | InputMaybe<DeleteTransactionInputType>
  >;
}>;

export type DeleteTransactionsMutation = {
  __typename?: 'Mutation';
  deleteTransactions?: Array<string> | null;
};

export type TransferFragment = {
  __typename?: 'Transfer';
  id: string;
  hash: string;
  date?: any | null;
  type?: string | null;
  subtype?: string | null;
  description?: string | null;
  value?: number | null;
  cost?: number | null;
  status?: number | null;
  holding?: { __typename?: 'HoldingObj'; id: string } | null;
  transaction?: { __typename?: 'Transaction'; id: string } | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
  commitment?: { __typename?: 'Commitment'; id: string } | null;
};

export type GetTransfersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTransfersQuery = {
  __typename?: 'Query';
  getTransfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
};

export type CreateTransfersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateTransferInputType>>
    | InputMaybe<CreateTransferInputType>
  >;
}>;

export type CreateTransfersMutation = {
  __typename?: 'Mutation';
  createTransfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
};

export type UpdateTransfersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateTransferInputType>>
    | InputMaybe<UpdateTransferInputType>
  >;
}>;

export type UpdateTransfersMutation = {
  __typename?: 'Mutation';
  updateTransfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
};

export type UpsertTransfersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertTransferInputType>>
    | InputMaybe<UpsertTransferInputType>
  >;
}>;

export type UpsertTransfersMutation = {
  __typename?: 'Mutation';
  upsertTransfers?: Array<{
    __typename?: 'Transfer';
    id: string;
    hash: string;
    date?: any | null;
    type?: string | null;
    subtype?: string | null;
    description?: string | null;
    value?: number | null;
    cost?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    transaction?: { __typename?: 'Transaction'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    commitment?: { __typename?: 'Commitment'; id: string } | null;
  }> | null;
};

export type DeleteTransfersMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteTransferInputType>>
    | InputMaybe<DeleteTransferInputType>
  >;
}>;

export type DeleteTransfersMutation = {
  __typename?: 'Mutation';
  deleteTransfers?: Array<string> | null;
};

export type ValuationFragment = {
  __typename?: 'Valuation';
  id: string;
  date?: any | null;
  value?: number | null;
  ownershipPercentage?: number | null;
  status?: number | null;
  holding?: { __typename?: 'HoldingObj'; id: string } | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

export type GetValuationsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetValuationsQuery = {
  __typename?: 'Query';
  getValuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type CreateValuationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateValuationInputType>>
    | InputMaybe<CreateValuationInputType>
  >;
}>;

export type CreateValuationsMutation = {
  __typename?: 'Mutation';
  createValuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpdateValuationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateValuationInputType>>
    | InputMaybe<UpdateValuationInputType>
  >;
}>;

export type UpdateValuationsMutation = {
  __typename?: 'Mutation';
  updateValuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpsertValuationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertValuationInputType>>
    | InputMaybe<UpsertValuationInputType>
  >;
}>;

export type UpsertValuationsMutation = {
  __typename?: 'Mutation';
  upsertValuations?: Array<{
    __typename?: 'Valuation';
    id: string;
    date?: any | null;
    value?: number | null;
    ownershipPercentage?: number | null;
    status?: number | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type DeleteValuationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteValuationInputType>>
    | InputMaybe<DeleteValuationInputType>
  >;
}>;

export type DeleteValuationsMutation = {
  __typename?: 'Mutation';
  deleteValuations?: Array<string> | null;
};

export type AddressFragment = {
  __typename?: 'Address';
  id: string;
  streetAddress?: string | null;
  streetNumber?: string | null;
  administrativeAreaLevel1?: string | null;
  administrativeAreaLevel2?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  person?: { __typename?: 'Person'; id: string } | null;
};

export type GetAddressesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAddressesQuery = {
  __typename?: 'Query';
  getAddresses?: Array<{
    __typename?: 'Address';
    id: string;
    streetAddress?: string | null;
    streetNumber?: string | null;
    administrativeAreaLevel1?: string | null;
    administrativeAreaLevel2?: string | null;
    locality?: string | null;
    postalCode?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type CreateAddressesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateAddressInputType>>
    | InputMaybe<CreateAddressInputType>
  >;
}>;

export type CreateAddressesMutation = {
  __typename?: 'Mutation';
  createAddresses?: Array<{
    __typename?: 'Address';
    id: string;
    streetAddress?: string | null;
    streetNumber?: string | null;
    administrativeAreaLevel1?: string | null;
    administrativeAreaLevel2?: string | null;
    locality?: string | null;
    postalCode?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpdateAddressesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateAddressInputType>>
    | InputMaybe<UpdateAddressInputType>
  >;
}>;

export type UpdateAddressesMutation = {
  __typename?: 'Mutation';
  updateAddresses?: Array<{
    __typename?: 'Address';
    id: string;
    streetAddress?: string | null;
    streetNumber?: string | null;
    administrativeAreaLevel1?: string | null;
    administrativeAreaLevel2?: string | null;
    locality?: string | null;
    postalCode?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpsertAddressesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertAddressInputType>>
    | InputMaybe<UpsertAddressInputType>
  >;
}>;

export type UpsertAddressesMutation = {
  __typename?: 'Mutation';
  upsertAddresses?: Array<{
    __typename?: 'Address';
    id: string;
    streetAddress?: string | null;
    streetNumber?: string | null;
    administrativeAreaLevel1?: string | null;
    administrativeAreaLevel2?: string | null;
    locality?: string | null;
    postalCode?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type DeleteAddressesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteAddressInputType>>
    | InputMaybe<DeleteAddressInputType>
  >;
}>;

export type DeleteAddressesMutation = {
  __typename?: 'Mutation';
  deleteAddresses?: Array<string> | null;
};

export type EmailFragment = {
  __typename?: 'Email';
  id: string;
  value?: string | null;
  person?: { __typename?: 'Person'; id: string } | null;
};

export type GetEmailsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetEmailsQuery = {
  __typename?: 'Query';
  getEmails?: Array<{
    __typename?: 'Email';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type CreateEmailsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateEmailInputType>> | InputMaybe<CreateEmailInputType>
  >;
}>;

export type CreateEmailsMutation = {
  __typename?: 'Mutation';
  createEmails?: Array<{
    __typename?: 'Email';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpdateEmailsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateEmailInputType>> | InputMaybe<UpdateEmailInputType>
  >;
}>;

export type UpdateEmailsMutation = {
  __typename?: 'Mutation';
  updateEmails?: Array<{
    __typename?: 'Email';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpsertEmailsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertEmailInputType>> | InputMaybe<UpsertEmailInputType>
  >;
}>;

export type UpsertEmailsMutation = {
  __typename?: 'Mutation';
  upsertEmails?: Array<{
    __typename?: 'Email';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type DeleteEmailsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteEmailInputType>> | InputMaybe<DeleteEmailInputType>
  >;
}>;

export type DeleteEmailsMutation = {
  __typename?: 'Mutation';
  deleteEmails?: Array<string> | null;
};

export type GetPeopleQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPeopleQuery = {
  __typename?: 'Query';
  getPeople?: Array<{
    __typename?: 'Person';
    id: string;
    name?: string | null;
    description?: string | null;
    roles?: Array<{
      __typename?: 'Role';
      id: string;
      name?: string | null;
      description?: string | null;
      holding?: {
        __typename?: 'HoldingObj';
        id: string;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
      } | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    phones?: Array<{
      __typename?: 'Phone';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    emails?: Array<{
      __typename?: 'Email';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    addresses?: Array<{
      __typename?: 'Address';
      id: string;
      streetAddress?: string | null;
      streetNumber?: string | null;
      administrativeAreaLevel1?: string | null;
      administrativeAreaLevel2?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
  }> | null;
};

export type CreatePeopleMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePersonInputType>> | InputMaybe<CreatePersonInputType>
  >;
}>;

export type CreatePeopleMutation = {
  __typename?: 'Mutation';
  createPeople?: Array<{
    __typename?: 'Person';
    id: string;
    name?: string | null;
    description?: string | null;
    roles?: Array<{
      __typename?: 'Role';
      id: string;
      name?: string | null;
      description?: string | null;
      holding?: {
        __typename?: 'HoldingObj';
        id: string;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
      } | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    phones?: Array<{
      __typename?: 'Phone';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    emails?: Array<{
      __typename?: 'Email';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    addresses?: Array<{
      __typename?: 'Address';
      id: string;
      streetAddress?: string | null;
      streetNumber?: string | null;
      administrativeAreaLevel1?: string | null;
      administrativeAreaLevel2?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpdatePeopleMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePersonInputType>> | InputMaybe<UpdatePersonInputType>
  >;
}>;

export type UpdatePeopleMutation = {
  __typename?: 'Mutation';
  updatePeople?: Array<{
    __typename?: 'Person';
    id: string;
    name?: string | null;
    description?: string | null;
    roles?: Array<{
      __typename?: 'Role';
      id: string;
      name?: string | null;
      description?: string | null;
      holding?: {
        __typename?: 'HoldingObj';
        id: string;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
      } | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    phones?: Array<{
      __typename?: 'Phone';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    emails?: Array<{
      __typename?: 'Email';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    addresses?: Array<{
      __typename?: 'Address';
      id: string;
      streetAddress?: string | null;
      streetNumber?: string | null;
      administrativeAreaLevel1?: string | null;
      administrativeAreaLevel2?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
  }> | null;
};

export type UpsertPeopleMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPersonInputType>> | InputMaybe<UpsertPersonInputType>
  >;
}>;

export type UpsertPeopleMutation = {
  __typename?: 'Mutation';
  upsertPeople?: Array<{
    __typename?: 'Person';
    id: string;
    name?: string | null;
    description?: string | null;
    roles?: Array<{
      __typename?: 'Role';
      id: string;
      name?: string | null;
      description?: string | null;
      holding?: {
        __typename?: 'HoldingObj';
        id: string;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
      } | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    phones?: Array<{
      __typename?: 'Phone';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    emails?: Array<{
      __typename?: 'Email';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    addresses?: Array<{
      __typename?: 'Address';
      id: string;
      streetAddress?: string | null;
      streetNumber?: string | null;
      administrativeAreaLevel1?: string | null;
      administrativeAreaLevel2?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
  }> | null;
};

export type DeletePeopleMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePersonInputType>> | InputMaybe<DeletePersonInputType>
  >;
}>;

export type DeletePeopleMutation = {
  __typename?: 'Mutation';
  deletePeople?: Array<string> | null;
};

export type PersonFragment = {
  __typename?: 'Person';
  id: string;
  name?: string | null;
  description?: string | null;
  roles?: Array<{
    __typename?: 'Role';
    id: string;
    name?: string | null;
    description?: string | null;
    holding?: {
      __typename?: 'HoldingObj';
      id: string;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    } | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
  phones?: Array<{
    __typename?: 'Phone';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
  emails?: Array<{
    __typename?: 'Email';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
  addresses?: Array<{
    __typename?: 'Address';
    id: string;
    streetAddress?: string | null;
    streetNumber?: string | null;
    administrativeAreaLevel1?: string | null;
    administrativeAreaLevel2?: string | null;
    locality?: string | null;
    postalCode?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type PhoneFragment = {
  __typename?: 'Phone';
  id: string;
  value?: string | null;
  person?: { __typename?: 'Person'; id: string } | null;
};

export type GetPhonesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetPhonesQuery = {
  __typename?: 'Query';
  getPhones?: Array<{
    __typename?: 'Phone';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type CreatePhonesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePhoneInputType>> | InputMaybe<CreatePhoneInputType>
  >;
}>;

export type CreatePhonesMutation = {
  __typename?: 'Mutation';
  createPhones?: Array<{
    __typename?: 'Phone';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpdatePhonesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePhoneInputType>> | InputMaybe<UpdatePhoneInputType>
  >;
}>;

export type UpdatePhonesMutation = {
  __typename?: 'Mutation';
  updatePhones?: Array<{
    __typename?: 'Phone';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpsertPhonesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPhoneInputType>> | InputMaybe<UpsertPhoneInputType>
  >;
}>;

export type UpsertPhonesMutation = {
  __typename?: 'Mutation';
  upsertPhones?: Array<{
    __typename?: 'Phone';
    id: string;
    value?: string | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type DeletePhonesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePhoneInputType>> | InputMaybe<DeletePhoneInputType>
  >;
}>;

export type DeletePhonesMutation = {
  __typename?: 'Mutation';
  deletePhones?: Array<string> | null;
};

export type RoleFragment = {
  __typename?: 'Role';
  id: string;
  name?: string | null;
  description?: string | null;
  holding?: {
    __typename?: 'HoldingObj';
    id: string;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
  } | null;
  person?: { __typename?: 'Person'; id: string } | null;
};

export type GetRolesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetRolesQuery = {
  __typename?: 'Query';
  getRoles?: Array<{
    __typename?: 'Role';
    id: string;
    name?: string | null;
    description?: string | null;
    holding?: {
      __typename?: 'HoldingObj';
      id: string;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    } | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type CreateRolesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateRoleInputType>> | InputMaybe<CreateRoleInputType>
  >;
}>;

export type CreateRolesMutation = {
  __typename?: 'Mutation';
  createRoles?: Array<{
    __typename?: 'Role';
    id: string;
    name?: string | null;
    description?: string | null;
    holding?: {
      __typename?: 'HoldingObj';
      id: string;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    } | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpdateRolesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateRoleInputType>> | InputMaybe<UpdateRoleInputType>
  >;
}>;

export type UpdateRolesMutation = {
  __typename?: 'Mutation';
  updateRoles?: Array<{
    __typename?: 'Role';
    id: string;
    name?: string | null;
    description?: string | null;
    holding?: {
      __typename?: 'HoldingObj';
      id: string;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    } | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type UpsertRolesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertRoleInputType>> | InputMaybe<UpsertRoleInputType>
  >;
}>;

export type UpsertRolesMutation = {
  __typename?: 'Mutation';
  upsertRoles?: Array<{
    __typename?: 'Role';
    id: string;
    name?: string | null;
    description?: string | null;
    holding?: {
      __typename?: 'HoldingObj';
      id: string;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
    } | null;
    person?: { __typename?: 'Person'; id: string } | null;
  }> | null;
};

export type DeleteRolesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteRoleInputType>> | InputMaybe<DeleteRoleInputType>
  >;
}>;

export type DeleteRolesMutation = {
  __typename?: 'Mutation';
  deleteRoles?: Array<string> | null;
};

export type PortfolioProjectFragment = {
  __typename?: 'PortfolioProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  };
  portfolio?: {
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  } | null;
};

export type PortfolioProjectShallowFragment = {
  __typename?: 'PortfolioProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
  };
};

export type GetPortfolioProjectsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPortfolioProjectsQuery = {
  __typename?: 'Query';
  getPortfolioProjects?: Array<{
    __typename?: 'PortfolioProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    portfolio?: {
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    } | null;
  }> | null;
};

export type CreatePortfolioProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePortfolioProjectInputType>>
    | InputMaybe<CreatePortfolioProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePortfolioProjectsMutation = {
  __typename?: 'Mutation';
  createPortfolioProjects?: Array<{
    __typename?: 'PortfolioProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    portfolio?: {
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    } | null;
  }> | null;
};

export type UpdatePortfolioProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePortfolioProjectInputType>>
    | InputMaybe<UpdatePortfolioProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePortfolioProjectsMutation = {
  __typename?: 'Mutation';
  updatePortfolioProjects?: Array<{
    __typename?: 'PortfolioProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    portfolio?: {
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    } | null;
  }> | null;
};

export type UpsertPortfolioProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPortfolioProjectInputType>>
    | InputMaybe<UpsertPortfolioProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertPortfolioProjectsMutation = {
  __typename?: 'Mutation';
  upsertPortfolioProjects?: Array<{
    __typename?: 'PortfolioProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    portfolio?: {
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    } | null;
  }> | null;
};

export type DeletePortfolioProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePortfolioProjectInputType>>
    | InputMaybe<DeletePortfolioProjectInputType>
  >;
}>;

export type DeletePortfolioProjectsMutation = {
  __typename?: 'Mutation';
  deletePortfolioProjects?: Array<string> | null;
};

export type GetPortfolioProjectByProjectIdQueryVariables = Exact<{
  projectId: Scalars['String'];
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPortfolioProjectByProjectIdQuery = {
  __typename?: 'Query';
  getPortfolioProjectByProjectId?: {
    __typename?: 'PortfolioProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    portfolio?: {
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    } | null;
  } | null;
};

export type AllocationFragment = {
  __typename?: 'Allocation';
  id: string;
  min?: number | null;
  max?: number | null;
  assetType?: string | null;
  assetClass?: string | null;
  category?: string | null;
  sector?: string | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
  region?: { __typename?: 'Region'; id: string } | null;
};

export type AllocationWithPortfolioFragment = {
  __typename?: 'Allocation';
  id: string;
  min?: number | null;
  max?: number | null;
  assetType?: string | null;
  assetClass?: string | null;
  category?: string | null;
  sector?: string | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
  region?: { __typename?: 'Region'; id: string } | null;
  portfolio?: { __typename?: 'Portfolio'; id: string } | null;
};

export type GetAllocationsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAllocationsQuery = {
  __typename?: 'Query';
  getAllocations?: Array<{
    __typename?: 'Allocation';
    id: string;
    min?: number | null;
    max?: number | null;
    assetType?: string | null;
    assetClass?: string | null;
    category?: string | null;
    sector?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
    portfolio?: { __typename?: 'Portfolio'; id: string } | null;
  }> | null;
};

export type CreateAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateAllocationInputType>>
    | InputMaybe<CreateAllocationInputType>
  >;
}>;

export type CreateAllocationsMutation = {
  __typename?: 'Mutation';
  createAllocations?: Array<{
    __typename?: 'Allocation';
    id: string;
    min?: number | null;
    max?: number | null;
    assetType?: string | null;
    assetClass?: string | null;
    category?: string | null;
    sector?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
    portfolio?: { __typename?: 'Portfolio'; id: string } | null;
  }> | null;
};

export type UpdateAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateAllocationInputType>>
    | InputMaybe<UpdateAllocationInputType>
  >;
}>;

export type UpdateAllocationsMutation = {
  __typename?: 'Mutation';
  updateAllocations?: Array<{
    __typename?: 'Allocation';
    id: string;
    min?: number | null;
    max?: number | null;
    assetType?: string | null;
    assetClass?: string | null;
    category?: string | null;
    sector?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
    portfolio?: { __typename?: 'Portfolio'; id: string } | null;
  }> | null;
};

export type UpsertAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertAllocationInputType>>
    | InputMaybe<UpsertAllocationInputType>
  >;
}>;

export type UpsertAllocationsMutation = {
  __typename?: 'Mutation';
  upsertAllocations?: Array<{
    __typename?: 'Allocation';
    id: string;
    min?: number | null;
    max?: number | null;
    assetType?: string | null;
    assetClass?: string | null;
    category?: string | null;
    sector?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
    portfolio?: { __typename?: 'Portfolio'; id: string } | null;
  }> | null;
};

export type DeleteAllocationsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteAllocationInputType>>
    | InputMaybe<DeleteAllocationInputType>
  >;
}>;

export type DeleteAllocationsMutation = {
  __typename?: 'Mutation';
  deleteAllocations?: Array<string> | null;
};

export type PortfolioConfigFragment = {
  __typename?: 'PortfolioConfig';
  id: number;
  currentDashboardConfigId?: string | null;
  portfolioDashboardConfigs?: Array<{
    __typename?: 'PortfolioDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type GetPortfolioConfigsQueryVariables = Exact<{
  portfolioId: Scalars['String'];
}>;

export type GetPortfolioConfigsQuery = {
  __typename?: 'Query';
  getPortfolioConfigs?: Array<{
    __typename?: 'PortfolioConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    portfolioDashboardConfigs?: Array<{
      __typename?: 'PortfolioDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type CreatePortfolioConfigsMutationVariables = Exact<{
  portfolioId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePortfolioConfigInputType>>
    | InputMaybe<CreatePortfolioConfigInputType>
  >;
}>;

export type CreatePortfolioConfigsMutation = {
  __typename?: 'Mutation';
  createPortfolioConfigs?: Array<{
    __typename?: 'PortfolioConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    portfolioDashboardConfigs?: Array<{
      __typename?: 'PortfolioDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UpdatePortfolioConfigsMutationVariables = Exact<{
  portfolioId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePortfolioConfigInputType>>
    | InputMaybe<UpdatePortfolioConfigInputType>
  >;
}>;

export type UpdatePortfolioConfigsMutation = {
  __typename?: 'Mutation';
  updatePortfolioConfigs?: Array<{
    __typename?: 'PortfolioConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    portfolioDashboardConfigs?: Array<{
      __typename?: 'PortfolioDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UpsertPortfolioConfigsMutationVariables = Exact<{
  portfolioId: Scalars['String'];
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPortfolioConfigInputType>>
    | InputMaybe<UpsertPortfolioConfigInputType>
  >;
}>;

export type UpsertPortfolioConfigsMutation = {
  __typename?: 'Mutation';
  upsertPortfolioConfigs?: Array<{
    __typename?: 'PortfolioConfig';
    id: number;
    currentDashboardConfigId?: string | null;
    portfolioDashboardConfigs?: Array<{
      __typename?: 'PortfolioDashboardConfig';
      id: string;
      name?: string | null;
      layouts?: string | null;
      compactType?: string | null;
      elements?: Array<{
        __typename?: 'DashboardElement';
        id: string;
        name: string;
        type: string;
        config: string;
        media?: {
          __typename?: 'DashboardMedia';
          id: string;
          file?: { __typename?: 'File'; id: string } | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type DeletePortfolioConfigsMutationVariables = Exact<{
  portfolioId: Scalars['String'];
  inputs?: InputMaybe<
    Array<DeletePortfolioConfigInputType> | DeletePortfolioConfigInputType
  >;
}>;

export type DeletePortfolioConfigsMutation = {
  __typename?: 'Mutation';
  deletePortfolioConfigs?: Array<number> | null;
};

export type PortfolioDashboardConfigFragment = {
  __typename?: 'PortfolioDashboardConfig';
  id: string;
  name?: string | null;
  layouts?: string | null;
  compactType?: string | null;
  elements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type CreatePortfolioDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePortfolioDashboardConfigInputType>>
    | InputMaybe<CreatePortfolioDashboardConfigInputType>
  >;
}>;

export type CreatePortfolioDashboardConfigsMutation = {
  __typename?: 'Mutation';
  createPortfolioDashboardConfigs?: Array<{
    __typename?: 'PortfolioDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type UpdatePortfolioDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePortfolioDashboardConfigInputType>>
    | InputMaybe<UpdatePortfolioDashboardConfigInputType>
  >;
}>;

export type UpdatePortfolioDashboardConfigsMutation = {
  __typename?: 'Mutation';
  updatePortfolioDashboardConfigs?: Array<{
    __typename?: 'PortfolioDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type UpsertPortfolioDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPortfolioDashboardConfigInputType>>
    | InputMaybe<UpsertPortfolioDashboardConfigInputType>
  >;
}>;

export type UpsertPortfolioDashboardConfigsMutation = {
  __typename?: 'Mutation';
  upsertPortfolioDashboardConfigs?: Array<{
    __typename?: 'PortfolioDashboardConfig';
    id: string;
    name?: string | null;
    layouts?: string | null;
    compactType?: string | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type DeletePortfolioDashboardConfigsMutationVariables = Exact<{
  inputs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type DeletePortfolioDashboardConfigsMutation = {
  __typename?: 'Mutation';
  deletePortfolioDashboardConfigs?: Array<string> | null;
};

export type PortfolioBaseFragment = {
  __typename?: 'Portfolio';
  id: string;
  name?: string | null;
  description?: string | null;
  parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
  allocations?: Array<{
    __typename?: 'Allocation';
    id: string;
    min?: number | null;
    max?: number | null;
    assetType?: string | null;
    assetClass?: string | null;
    category?: string | null;
    sector?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
  holdings?: Array<
    | {
        __typename: 'BankNoteHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'BondHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'CryptoHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'FundHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'LoanHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'StockHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'WarrantHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
  > | null;
};

export type PortfolioFragment = {
  __typename?: 'Portfolio';
  id: string;
  name?: string | null;
  description?: string | null;
  portfolios?: Array<{
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  }> | null;
  parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
  allocations?: Array<{
    __typename?: 'Allocation';
    id: string;
    min?: number | null;
    max?: number | null;
    assetType?: string | null;
    assetClass?: string | null;
    category?: string | null;
    sector?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    region?: { __typename?: 'Region'; id: string } | null;
  }> | null;
  holdings?: Array<
    | {
        __typename: 'BankNoteHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'BondHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'CryptoHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'FundHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'LoanHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'StockHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
    | {
        __typename: 'WarrantHolding';
        holding: { __typename?: 'HoldingObj'; id: string };
      }
  > | null;
};

export type GetPortfoliosQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPortfoliosQuery = {
  __typename?: 'Query';
  getPortfolios?: Array<{
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  }> | null;
};

export type CreatePortfoliosMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePortfolioInputType>>
    | InputMaybe<CreatePortfolioInputType>
  >;
}>;

export type CreatePortfoliosMutation = {
  __typename?: 'Mutation';
  createPortfolios?: Array<{
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  }> | null;
};

export type UpdatePortfoliosMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePortfolioInputType>>
    | InputMaybe<UpdatePortfolioInputType>
  >;
}>;

export type UpdatePortfoliosMutation = {
  __typename?: 'Mutation';
  updatePortfolios?: Array<{
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  }> | null;
};

export type UpsertPortfoliosMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPortfolioInputType>>
    | InputMaybe<UpsertPortfolioInputType>
  >;
}>;

export type UpsertPortfoliosMutation = {
  __typename?: 'Mutation';
  upsertPortfolios?: Array<{
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  }> | null;
};

export type DeletePortfoliosMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePortfolioInputType>>
    | InputMaybe<DeletePortfolioInputType>
  >;
}>;

export type DeletePortfoliosMutation = {
  __typename?: 'Mutation';
  deletePortfolios?: Array<string> | null;
};

export type PrivateEquityFundProjectFragment = {
  __typename?: 'PrivateEquityFundProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  };
  privateEquityFund?: {
    __typename: 'PrivateEquityFund';
    _type: string;
    id: string;
    description?: string | null;
    fundSetup?: {
      __typename?: 'PrivateEquityFundSetup';
      id: string;
      name: string;
      description: string;
      managementFee: number;
      hurdleRate: number;
      carry: number;
      carriedInterestType: string;
      startDate: any;
      endDate: any;
      endInvestmentDate: any;
    } | null;
    investors?: Array<{
      __typename?: 'PrivateEquityFundInvestor';
      id: string;
      name?: string | null;
      description?: string | null;
      commitments?: Array<{
        __typename?: 'PrivateEquityFundCapitalCommitment';
        id: string;
        date?: any | null;
        value?: number | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalCallTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalCallTransfer';
        id: string;
        capitalCall?: {
          __typename?: 'PrivateEquityFundCapitalCall';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalDistributionTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
        id: string;
        capitalDistribution?: {
          __typename?: 'PrivateEquityFundCapitalDistribution';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      contacts?: Array<{
        __typename?: 'PrivateEquityFundContact';
        id: string;
        name?: string | null;
        email?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      }> | null;
    }> | null;
    investments?: Array<{
      __typename?: 'PrivateEquityFundInvestment';
      id: string;
      name: string;
      description: string;
      exit?: any | null;
      image?: string | null;
      holding:
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          };
    }> | null;
    capitalCalls?: Array<{
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    capitalDistributions?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    team?: Array<{
      __typename?: 'PrivateEquityFundTeamMember';
      id: string;
      name?: string | null;
      email?: string | null;
      tel?: string | null;
      title?: string | null;
      description?: string | null;
      education?: string | null;
    }> | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  } | null;
};

export type PrivateEquityFundProjectShallowFragment = {
  __typename?: 'PrivateEquityFundProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
  };
};

export type GetPrivateEquityFundProjectsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPrivateEquityFundProjectsQuery = {
  __typename?: 'Query';
  getPrivateEquityFundProjects?: Array<{
    __typename?: 'PrivateEquityFundProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    privateEquityFund?: {
      __typename: 'PrivateEquityFund';
      _type: string;
      id: string;
      description?: string | null;
      fundSetup?: {
        __typename?: 'PrivateEquityFundSetup';
        id: string;
        name: string;
        description: string;
        managementFee: number;
        hurdleRate: number;
        carry: number;
        carriedInterestType: string;
        startDate: any;
        endDate: any;
        endInvestmentDate: any;
      } | null;
      investors?: Array<{
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
        name?: string | null;
        description?: string | null;
        commitments?: Array<{
          __typename?: 'PrivateEquityFundCapitalCommitment';
          id: string;
          date?: any | null;
          value?: number | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalCallTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalCallTransfer';
          id: string;
          capitalCall?: {
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalDistributionTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
          id: string;
          capitalDistribution?: {
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        contacts?: Array<{
          __typename?: 'PrivateEquityFundContact';
          id: string;
          name?: string | null;
          email?: string | null;
        }> | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        }> | null;
      }> | null;
      investments?: Array<{
        __typename?: 'PrivateEquityFundInvestment';
        id: string;
        name: string;
        description: string;
        exit?: any | null;
        image?: string | null;
        holding:
          | {
              __typename: 'BankNoteHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bankNote?: {
                __typename: 'BankNote';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'BondHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bond?: { __typename: 'Bond'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              bondPositions?: Array<{
                __typename?: 'BondPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
              }> | null;
              bondTrades?: Array<{
                __typename?: 'BondTrade';
                id: string;
                shares: number;
                sharePrice: number;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'BondPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'CryptoHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              crypto?: {
                __typename: 'Crypto';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'FundHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              fund?: { __typename: 'Fund'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              fundPositions?: Array<{
                __typename?: 'FundPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
              }> | null;
              fundTrades?: Array<{
                __typename?: 'FundTrade';
                id: string;
                shares: number;
                sharePrice: number;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'FundPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'LoanHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              loan?: { __typename: 'Loan'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'StockHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              stock?: { __typename: 'Stock'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              stockPositions?: Array<{
                __typename?: 'StockPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
              stockTrades?: Array<{
                __typename?: 'StockTrade';
                id: string;
                shares: number;
                sharePrice: number;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'StockPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
              stockGoalRules?: Array<{
                __typename?: 'StockGoalRule';
                id: string;
                goalRule: {
                  __typename?: 'GoalRule';
                  name: string;
                  formula: string;
                };
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'WarrantHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              warrant?: {
                __typename: 'Warrant';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            };
      }> | null;
      capitalCalls?: Array<{
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      capitalDistributions?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      team?: Array<{
        __typename?: 'PrivateEquityFundTeamMember';
        id: string;
        name?: string | null;
        email?: string | null;
        tel?: string | null;
        title?: string | null;
        description?: string | null;
        education?: string | null;
      }> | null;
      asset?: {
        __typename?: 'AssetObj';
        id: string;
        type: string;
        name?: string | null;
        description?: string | null;
        types?: Array<string> | null;
        assetClass?: string | null;
        ISIN?: string | null;
        VPS?: string | null;
        lookup?: string | null;
        category?: { __typename?: 'AssetCategory'; id: string } | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
      } | null;
    } | null;
  }> | null;
};

export type CreatePrivateEquityFundProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreatePrivateEquityFundProjectInputType>>
    | InputMaybe<CreatePrivateEquityFundProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePrivateEquityFundProjectsMutation = {
  __typename?: 'Mutation';
  createPrivateEquityFundProjects?: Array<{
    __typename?: 'PrivateEquityFundProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    privateEquityFund?: {
      __typename: 'PrivateEquityFund';
      _type: string;
      id: string;
      description?: string | null;
      fundSetup?: {
        __typename?: 'PrivateEquityFundSetup';
        id: string;
        name: string;
        description: string;
        managementFee: number;
        hurdleRate: number;
        carry: number;
        carriedInterestType: string;
        startDate: any;
        endDate: any;
        endInvestmentDate: any;
      } | null;
      investors?: Array<{
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
        name?: string | null;
        description?: string | null;
        commitments?: Array<{
          __typename?: 'PrivateEquityFundCapitalCommitment';
          id: string;
          date?: any | null;
          value?: number | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalCallTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalCallTransfer';
          id: string;
          capitalCall?: {
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalDistributionTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
          id: string;
          capitalDistribution?: {
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        contacts?: Array<{
          __typename?: 'PrivateEquityFundContact';
          id: string;
          name?: string | null;
          email?: string | null;
        }> | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        }> | null;
      }> | null;
      investments?: Array<{
        __typename?: 'PrivateEquityFundInvestment';
        id: string;
        name: string;
        description: string;
        exit?: any | null;
        image?: string | null;
        holding:
          | {
              __typename: 'BankNoteHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bankNote?: {
                __typename: 'BankNote';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'BondHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bond?: { __typename: 'Bond'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              bondPositions?: Array<{
                __typename?: 'BondPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
              }> | null;
              bondTrades?: Array<{
                __typename?: 'BondTrade';
                id: string;
                shares: number;
                sharePrice: number;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'BondPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'CryptoHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              crypto?: {
                __typename: 'Crypto';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'FundHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              fund?: { __typename: 'Fund'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              fundPositions?: Array<{
                __typename?: 'FundPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
              }> | null;
              fundTrades?: Array<{
                __typename?: 'FundTrade';
                id: string;
                shares: number;
                sharePrice: number;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'FundPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'LoanHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              loan?: { __typename: 'Loan'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'StockHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              stock?: { __typename: 'Stock'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              stockPositions?: Array<{
                __typename?: 'StockPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
              stockTrades?: Array<{
                __typename?: 'StockTrade';
                id: string;
                shares: number;
                sharePrice: number;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'StockPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
              stockGoalRules?: Array<{
                __typename?: 'StockGoalRule';
                id: string;
                goalRule: {
                  __typename?: 'GoalRule';
                  name: string;
                  formula: string;
                };
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'WarrantHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              warrant?: {
                __typename: 'Warrant';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            };
      }> | null;
      capitalCalls?: Array<{
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      capitalDistributions?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      team?: Array<{
        __typename?: 'PrivateEquityFundTeamMember';
        id: string;
        name?: string | null;
        email?: string | null;
        tel?: string | null;
        title?: string | null;
        description?: string | null;
        education?: string | null;
      }> | null;
      asset?: {
        __typename?: 'AssetObj';
        id: string;
        type: string;
        name?: string | null;
        description?: string | null;
        types?: Array<string> | null;
        assetClass?: string | null;
        ISIN?: string | null;
        VPS?: string | null;
        lookup?: string | null;
        category?: { __typename?: 'AssetCategory'; id: string } | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
      } | null;
    } | null;
  }> | null;
};

export type UpdatePrivateEquityFundProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdatePrivateEquityFundProjectInputType>>
    | InputMaybe<UpdatePrivateEquityFundProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePrivateEquityFundProjectsMutation = {
  __typename?: 'Mutation';
  updatePrivateEquityFundProjects?: Array<{
    __typename?: 'PrivateEquityFundProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    privateEquityFund?: {
      __typename: 'PrivateEquityFund';
      _type: string;
      id: string;
      description?: string | null;
      fundSetup?: {
        __typename?: 'PrivateEquityFundSetup';
        id: string;
        name: string;
        description: string;
        managementFee: number;
        hurdleRate: number;
        carry: number;
        carriedInterestType: string;
        startDate: any;
        endDate: any;
        endInvestmentDate: any;
      } | null;
      investors?: Array<{
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
        name?: string | null;
        description?: string | null;
        commitments?: Array<{
          __typename?: 'PrivateEquityFundCapitalCommitment';
          id: string;
          date?: any | null;
          value?: number | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalCallTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalCallTransfer';
          id: string;
          capitalCall?: {
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalDistributionTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
          id: string;
          capitalDistribution?: {
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        contacts?: Array<{
          __typename?: 'PrivateEquityFundContact';
          id: string;
          name?: string | null;
          email?: string | null;
        }> | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        }> | null;
      }> | null;
      investments?: Array<{
        __typename?: 'PrivateEquityFundInvestment';
        id: string;
        name: string;
        description: string;
        exit?: any | null;
        image?: string | null;
        holding:
          | {
              __typename: 'BankNoteHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bankNote?: {
                __typename: 'BankNote';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'BondHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bond?: { __typename: 'Bond'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              bondPositions?: Array<{
                __typename?: 'BondPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
              }> | null;
              bondTrades?: Array<{
                __typename?: 'BondTrade';
                id: string;
                shares: number;
                sharePrice: number;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'BondPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'CryptoHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              crypto?: {
                __typename: 'Crypto';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'FundHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              fund?: { __typename: 'Fund'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              fundPositions?: Array<{
                __typename?: 'FundPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
              }> | null;
              fundTrades?: Array<{
                __typename?: 'FundTrade';
                id: string;
                shares: number;
                sharePrice: number;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'FundPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'LoanHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              loan?: { __typename: 'Loan'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'StockHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              stock?: { __typename: 'Stock'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              stockPositions?: Array<{
                __typename?: 'StockPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
              stockTrades?: Array<{
                __typename?: 'StockTrade';
                id: string;
                shares: number;
                sharePrice: number;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'StockPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
              stockGoalRules?: Array<{
                __typename?: 'StockGoalRule';
                id: string;
                goalRule: {
                  __typename?: 'GoalRule';
                  name: string;
                  formula: string;
                };
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'WarrantHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              warrant?: {
                __typename: 'Warrant';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            };
      }> | null;
      capitalCalls?: Array<{
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      capitalDistributions?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      team?: Array<{
        __typename?: 'PrivateEquityFundTeamMember';
        id: string;
        name?: string | null;
        email?: string | null;
        tel?: string | null;
        title?: string | null;
        description?: string | null;
        education?: string | null;
      }> | null;
      asset?: {
        __typename?: 'AssetObj';
        id: string;
        type: string;
        name?: string | null;
        description?: string | null;
        types?: Array<string> | null;
        assetClass?: string | null;
        ISIN?: string | null;
        VPS?: string | null;
        lookup?: string | null;
        category?: { __typename?: 'AssetCategory'; id: string } | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
      } | null;
    } | null;
  }> | null;
};

export type UpsertPrivateEquityFundProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertPrivateEquityFundProjectInputType>>
    | InputMaybe<UpsertPrivateEquityFundProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertPrivateEquityFundProjectsMutation = {
  __typename?: 'Mutation';
  upsertPrivateEquityFundProjects?: Array<{
    __typename?: 'PrivateEquityFundProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    privateEquityFund?: {
      __typename: 'PrivateEquityFund';
      _type: string;
      id: string;
      description?: string | null;
      fundSetup?: {
        __typename?: 'PrivateEquityFundSetup';
        id: string;
        name: string;
        description: string;
        managementFee: number;
        hurdleRate: number;
        carry: number;
        carriedInterestType: string;
        startDate: any;
        endDate: any;
        endInvestmentDate: any;
      } | null;
      investors?: Array<{
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
        name?: string | null;
        description?: string | null;
        commitments?: Array<{
          __typename?: 'PrivateEquityFundCapitalCommitment';
          id: string;
          date?: any | null;
          value?: number | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalCallTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalCallTransfer';
          id: string;
          capitalCall?: {
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalDistributionTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
          id: string;
          capitalDistribution?: {
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        contacts?: Array<{
          __typename?: 'PrivateEquityFundContact';
          id: string;
          name?: string | null;
          email?: string | null;
        }> | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        }> | null;
      }> | null;
      investments?: Array<{
        __typename?: 'PrivateEquityFundInvestment';
        id: string;
        name: string;
        description: string;
        exit?: any | null;
        image?: string | null;
        holding:
          | {
              __typename: 'BankNoteHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bankNote?: {
                __typename: 'BankNote';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'BondHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bond?: { __typename: 'Bond'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              bondPositions?: Array<{
                __typename?: 'BondPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
              }> | null;
              bondTrades?: Array<{
                __typename?: 'BondTrade';
                id: string;
                shares: number;
                sharePrice: number;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'BondPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'CryptoHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              crypto?: {
                __typename: 'Crypto';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'FundHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              fund?: { __typename: 'Fund'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              fundPositions?: Array<{
                __typename?: 'FundPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
              }> | null;
              fundTrades?: Array<{
                __typename?: 'FundTrade';
                id: string;
                shares: number;
                sharePrice: number;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'FundPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'LoanHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              loan?: { __typename: 'Loan'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'StockHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              stock?: { __typename: 'Stock'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              stockPositions?: Array<{
                __typename?: 'StockPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
              stockTrades?: Array<{
                __typename?: 'StockTrade';
                id: string;
                shares: number;
                sharePrice: number;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'StockPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
              stockGoalRules?: Array<{
                __typename?: 'StockGoalRule';
                id: string;
                goalRule: {
                  __typename?: 'GoalRule';
                  name: string;
                  formula: string;
                };
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'WarrantHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              warrant?: {
                __typename: 'Warrant';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            };
      }> | null;
      capitalCalls?: Array<{
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      capitalDistributions?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      team?: Array<{
        __typename?: 'PrivateEquityFundTeamMember';
        id: string;
        name?: string | null;
        email?: string | null;
        tel?: string | null;
        title?: string | null;
        description?: string | null;
        education?: string | null;
      }> | null;
      asset?: {
        __typename?: 'AssetObj';
        id: string;
        type: string;
        name?: string | null;
        description?: string | null;
        types?: Array<string> | null;
        assetClass?: string | null;
        ISIN?: string | null;
        VPS?: string | null;
        lookup?: string | null;
        category?: { __typename?: 'AssetCategory'; id: string } | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
      } | null;
    } | null;
  }> | null;
};

export type DeletePrivateEquityFundProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeletePrivateEquityFundProjectInputType>>
    | InputMaybe<DeletePrivateEquityFundProjectInputType>
  >;
}>;

export type DeletePrivateEquityFundProjectsMutation = {
  __typename?: 'Mutation';
  deletePrivateEquityFundProjects?: Array<string> | null;
};

export type GetPrivateEquityFundProjectByProjectIdQueryVariables = Exact<{
  projectId: Scalars['String'];
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPrivateEquityFundProjectByProjectIdQuery = {
  __typename?: 'Query';
  getPrivateEquityFundProjectByProjectId?: {
    __typename?: 'PrivateEquityFundProject';
    id: string;
    project: {
      __typename: 'ProjectObj';
      id: string;
      name?: string | null;
      type?: string | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
      > | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id: string;
        title?: string | null;
        description?: string | null;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
      }> | null;
    };
    privateEquityFund?: {
      __typename: 'PrivateEquityFund';
      _type: string;
      id: string;
      description?: string | null;
      fundSetup?: {
        __typename?: 'PrivateEquityFundSetup';
        id: string;
        name: string;
        description: string;
        managementFee: number;
        hurdleRate: number;
        carry: number;
        carriedInterestType: string;
        startDate: any;
        endDate: any;
        endInvestmentDate: any;
      } | null;
      investors?: Array<{
        __typename?: 'PrivateEquityFundInvestor';
        id: string;
        name?: string | null;
        description?: string | null;
        commitments?: Array<{
          __typename?: 'PrivateEquityFundCapitalCommitment';
          id: string;
          date?: any | null;
          value?: number | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalCallTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalCallTransfer';
          id: string;
          capitalCall?: {
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        capitalDistributionTransfer?: Array<{
          __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
          id: string;
          capitalDistribution?: {
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          } | null;
          investor?: {
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
          } | null;
        }> | null;
        contacts?: Array<{
          __typename?: 'PrivateEquityFundContact';
          id: string;
          name?: string | null;
          email?: string | null;
        }> | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        }> | null;
      }> | null;
      investments?: Array<{
        __typename?: 'PrivateEquityFundInvestment';
        id: string;
        name: string;
        description: string;
        exit?: any | null;
        image?: string | null;
        holding:
          | {
              __typename: 'BankNoteHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bankNote?: {
                __typename: 'BankNote';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'BondHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              bond?: { __typename: 'Bond'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              bondPositions?: Array<{
                __typename?: 'BondPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
              }> | null;
              bondTrades?: Array<{
                __typename?: 'BondTrade';
                id: string;
                shares: number;
                sharePrice: number;
                bondHolding?: {
                  __typename?: 'BondHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'BondPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'CryptoHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              crypto?: {
                __typename: 'Crypto';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'FundHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              fund?: { __typename: 'Fund'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              fundPositions?: Array<{
                __typename?: 'FundPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
              }> | null;
              fundTrades?: Array<{
                __typename?: 'FundTrade';
                id: string;
                shares: number;
                sharePrice: number;
                fundHolding?: {
                  __typename?: 'FundHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'FundPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'LoanHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              loan?: { __typename: 'Loan'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            }
          | {
              __typename: 'StockHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              stock?: { __typename: 'Stock'; _type: string; id: string } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
              stockPositions?: Array<{
                __typename?: 'StockPosition';
                id: string;
                shares?: number | null;
                date?: any | null;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
              stockTrades?: Array<{
                __typename?: 'StockTrade';
                id: string;
                shares: number;
                sharePrice: number;
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
                position?: { __typename?: 'StockPosition'; id: string } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                } | null;
              }> | null;
              stockGoalRules?: Array<{
                __typename?: 'StockGoalRule';
                id: string;
                goalRule: {
                  __typename?: 'GoalRule';
                  name: string;
                  formula: string;
                };
                stockHolding?: {
                  __typename?: 'StockHoldingBase';
                  id: string;
                } | null;
              }> | null;
            }
          | {
              __typename: 'WarrantHolding';
              id: string;
              valuations?: Array<{
                __typename?: 'Valuation';
                id: string;
                date?: any | null;
                value?: number | null;
                ownershipPercentage?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              commitments?: Array<{
                __typename?: 'Commitment';
                id: string;
                commitmentDate?: any | null;
                startDate?: any | null;
                endDate?: any | null;
                value?: number | null;
                type?: string | null;
                description?: string | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                }> | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
              }> | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
              warrant?: {
                __typename: 'Warrant';
                _type: string;
                id: string;
              } | null;
              holding: {
                __typename: 'HoldingObj';
                id: string;
                type?: string | null;
                name?: string | null;
                description?: string | null;
                project?: { __typename?: 'ProjectObj'; id: string } | null;
              };
            };
      }> | null;
      capitalCalls?: Array<{
        __typename?: 'PrivateEquityFundCapitalCall';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      capitalDistributions?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistribution';
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        value?: number | null;
      }> | null;
      team?: Array<{
        __typename?: 'PrivateEquityFundTeamMember';
        id: string;
        name?: string | null;
        email?: string | null;
        tel?: string | null;
        title?: string | null;
        description?: string | null;
        education?: string | null;
      }> | null;
      asset?: {
        __typename?: 'AssetObj';
        id: string;
        type: string;
        name?: string | null;
        description?: string | null;
        types?: Array<string> | null;
        assetClass?: string | null;
        ISIN?: string | null;
        VPS?: string | null;
        lookup?: string | null;
        category?: { __typename?: 'AssetCategory'; id: string } | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type ProjectObjShallowFragment = {
  __typename: 'ProjectObj';
  id: string;
  name?: string | null;
  type?: string | null;
};

export type ProjectObjFragment = {
  __typename: 'ProjectObj';
  id: string;
  name?: string | null;
  type?: string | null;
  holdings?: Array<
    | {
        __typename: 'BankNoteHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        bankNote?: { __typename: 'BankNote'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'BondHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        bond?: { __typename: 'Bond'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        bondPositions?: Array<{
          __typename?: 'BondPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
        }> | null;
        bondTrades?: Array<{
          __typename?: 'BondTrade';
          id: string;
          shares: number;
          sharePrice: number;
          bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          position?: { __typename?: 'BondPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
      }
    | {
        __typename: 'CryptoHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'FundHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        fund?: { __typename: 'Fund'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        fundPositions?: Array<{
          __typename?: 'FundPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
        }> | null;
        fundTrades?: Array<{
          __typename?: 'FundTrade';
          id: string;
          shares: number;
          sharePrice: number;
          fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          position?: { __typename?: 'FundPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
      }
    | {
        __typename: 'LoanHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        loan?: { __typename: 'Loan'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
    | {
        __typename: 'StockHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        stock?: { __typename: 'Stock'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
        stockPositions?: Array<{
          __typename?: 'StockPosition';
          id: string;
          shares?: number | null;
          date?: any | null;
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
        }> | null;
        stockTrades?: Array<{
          __typename?: 'StockTrade';
          id: string;
          shares: number;
          sharePrice: number;
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
          position?: { __typename?: 'StockPosition'; id: string } | null;
          transfer?: {
            __typename?: 'Transfer';
            id: string;
            hash: string;
          } | null;
        }> | null;
        stockGoalRules?: Array<{
          __typename?: 'StockGoalRule';
          id: string;
          goalRule: { __typename?: 'GoalRule'; name: string; formula: string };
          stockHolding?: { __typename?: 'StockHoldingBase'; id: string } | null;
        }> | null;
      }
    | {
        __typename: 'WarrantHolding';
        id: string;
        valuations?: Array<{
          __typename?: 'Valuation';
          id: string;
          date?: any | null;
          value?: number | null;
          ownershipPercentage?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        commitments?: Array<{
          __typename?: 'Commitment';
          id: string;
          commitmentDate?: any | null;
          startDate?: any | null;
          endDate?: any | null;
          value?: number | null;
          type?: string | null;
          description?: string | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
          }> | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }> | null;
        transfers?: Array<{
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        }> | null;
        warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
        holding: {
          __typename: 'HoldingObj';
          id: string;
          type?: string | null;
          name?: string | null;
          description?: string | null;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        };
      }
  > | null;
  transactions?: Array<{
    __typename?: 'Transaction';
    id: string;
    title?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    transfers?: Array<{
      __typename?: 'Transfer';
      id: string;
      hash: string;
      date?: any | null;
      type?: string | null;
      subtype?: string | null;
      description?: string | null;
      value?: number | null;
      cost?: number | null;
      status?: number | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      transaction?: { __typename?: 'Transaction'; id: string } | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      commitment?: { __typename?: 'Commitment'; id: string } | null;
    }> | null;
  }> | null;
};

type ProjectShallow_PortfolioProject_Fragment = {
  __typename: 'PortfolioProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
  };
};

type ProjectShallow_PrivateEquityFundProject_Fragment = {
  __typename: 'PrivateEquityFundProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
  };
};

export type ProjectShallowFragment =
  | ProjectShallow_PortfolioProject_Fragment
  | ProjectShallow_PrivateEquityFundProject_Fragment;

type Project_PortfolioProject_Fragment = {
  __typename: 'PortfolioProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  };
  portfolio?: {
    __typename?: 'Portfolio';
    id: string;
    name?: string | null;
    description?: string | null;
    portfolios?: Array<{
      __typename?: 'Portfolio';
      id: string;
      name?: string | null;
      description?: string | null;
      portfolios?: Array<{
        __typename?: 'Portfolio';
        id: string;
        name?: string | null;
        description?: string | null;
        portfolios?: Array<{ __typename?: 'Portfolio'; id: string }> | null;
        parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
        allocations?: Array<{
          __typename?: 'Allocation';
          id: string;
          min?: number | null;
          max?: number | null;
          assetType?: string | null;
          assetClass?: string | null;
          category?: string | null;
          sector?: string | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          region?: { __typename?: 'Region'; id: string } | null;
        }> | null;
        holdings?: Array<
          | {
              __typename: 'BankNoteHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'BondHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'CryptoHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'FundHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'LoanHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'StockHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
          | {
              __typename: 'WarrantHolding';
              holding: { __typename?: 'HoldingObj'; id: string };
            }
        > | null;
      }> | null;
      parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
      allocations?: Array<{
        __typename?: 'Allocation';
        id: string;
        min?: number | null;
        max?: number | null;
        assetType?: string | null;
        assetClass?: string | null;
        category?: string | null;
        sector?: string | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      holdings?: Array<
        | {
            __typename: 'BankNoteHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'BondHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'CryptoHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'FundHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'LoanHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'StockHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
        | {
            __typename: 'WarrantHolding';
            holding: { __typename?: 'HoldingObj'; id: string };
          }
      > | null;
    }> | null;
    parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
    allocations?: Array<{
      __typename?: 'Allocation';
      id: string;
      min?: number | null;
      max?: number | null;
      assetType?: string | null;
      assetClass?: string | null;
      category?: string | null;
      sector?: string | null;
      currency?: { __typename?: 'Currency'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'BondHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'CryptoHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'FundHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'LoanHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'StockHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
      | {
          __typename: 'WarrantHolding';
          holding: { __typename?: 'HoldingObj'; id: string };
        }
    > | null;
  } | null;
};

type Project_PrivateEquityFundProject_Fragment = {
  __typename: 'PrivateEquityFundProject';
  id: string;
  project: {
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  };
  privateEquityFund?: {
    __typename: 'PrivateEquityFund';
    _type: string;
    id: string;
    description?: string | null;
    fundSetup?: {
      __typename?: 'PrivateEquityFundSetup';
      id: string;
      name: string;
      description: string;
      managementFee: number;
      hurdleRate: number;
      carry: number;
      carriedInterestType: string;
      startDate: any;
      endDate: any;
      endInvestmentDate: any;
    } | null;
    investors?: Array<{
      __typename?: 'PrivateEquityFundInvestor';
      id: string;
      name?: string | null;
      description?: string | null;
      commitments?: Array<{
        __typename?: 'PrivateEquityFundCapitalCommitment';
        id: string;
        date?: any | null;
        value?: number | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalCallTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalCallTransfer';
        id: string;
        capitalCall?: {
          __typename?: 'PrivateEquityFundCapitalCall';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      capitalDistributionTransfer?: Array<{
        __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
        id: string;
        capitalDistribution?: {
          __typename?: 'PrivateEquityFundCapitalDistribution';
          id: string;
          name?: string | null;
          description?: string | null;
          date?: any | null;
          value?: number | null;
        } | null;
        transfer?: {
          __typename?: 'Transfer';
          id: string;
          hash: string;
          date?: any | null;
          type?: string | null;
          subtype?: string | null;
          description?: string | null;
          value?: number | null;
          cost?: number | null;
          status?: number | null;
          holding?: { __typename?: 'HoldingObj'; id: string } | null;
          transaction?: { __typename?: 'Transaction'; id: string } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
          commitment?: { __typename?: 'Commitment'; id: string } | null;
        } | null;
        investor?: {
          __typename?: 'PrivateEquityFundInvestor';
          id: string;
        } | null;
      }> | null;
      contacts?: Array<{
        __typename?: 'PrivateEquityFundContact';
        id: string;
        name?: string | null;
        email?: string | null;
      }> | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      }> | null;
    }> | null;
    investments?: Array<{
      __typename?: 'PrivateEquityFundInvestment';
      id: string;
      name: string;
      description: string;
      exit?: any | null;
      image?: string | null;
      holding:
        | {
            __typename: 'BankNoteHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bankNote?: {
              __typename: 'BankNote';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'BondHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            bond?: { __typename: 'Bond'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            bondPositions?: Array<{
              __typename?: 'BondPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
            }> | null;
            bondTrades?: Array<{
              __typename?: 'BondTrade';
              id: string;
              shares: number;
              sharePrice: number;
              bondHolding?: {
                __typename?: 'BondHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'BondPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'CryptoHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'FundHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            fund?: { __typename: 'Fund'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            fundPositions?: Array<{
              __typename?: 'FundPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
            }> | null;
            fundTrades?: Array<{
              __typename?: 'FundTrade';
              id: string;
              shares: number;
              sharePrice: number;
              fundHolding?: {
                __typename?: 'FundHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'FundPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'LoanHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            loan?: { __typename: 'Loan'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          }
        | {
            __typename: 'StockHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            stock?: { __typename: 'Stock'; _type: string; id: string } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
            stockPositions?: Array<{
              __typename?: 'StockPosition';
              id: string;
              shares?: number | null;
              date?: any | null;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
            stockTrades?: Array<{
              __typename?: 'StockTrade';
              id: string;
              shares: number;
              sharePrice: number;
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
              position?: { __typename?: 'StockPosition'; id: string } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
              } | null;
            }> | null;
            stockGoalRules?: Array<{
              __typename?: 'StockGoalRule';
              id: string;
              goalRule: {
                __typename?: 'GoalRule';
                name: string;
                formula: string;
              };
              stockHolding?: {
                __typename?: 'StockHoldingBase';
                id: string;
              } | null;
            }> | null;
          }
        | {
            __typename: 'WarrantHolding';
            id: string;
            valuations?: Array<{
              __typename?: 'Valuation';
              id: string;
              date?: any | null;
              value?: number | null;
              ownershipPercentage?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            commitments?: Array<{
              __typename?: 'Commitment';
              id: string;
              commitmentDate?: any | null;
              startDate?: any | null;
              endDate?: any | null;
              value?: number | null;
              type?: string | null;
              description?: string | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
              }> | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
            }> | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
            warrant?: {
              __typename: 'Warrant';
              _type: string;
              id: string;
            } | null;
            holding: {
              __typename: 'HoldingObj';
              id: string;
              type?: string | null;
              name?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
            };
          };
    }> | null;
    capitalCalls?: Array<{
      __typename?: 'PrivateEquityFundCapitalCall';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    capitalDistributions?: Array<{
      __typename?: 'PrivateEquityFundCapitalDistribution';
      id: string;
      name?: string | null;
      description?: string | null;
      date?: any | null;
      value?: number | null;
    }> | null;
    team?: Array<{
      __typename?: 'PrivateEquityFundTeamMember';
      id: string;
      name?: string | null;
      email?: string | null;
      tel?: string | null;
      title?: string | null;
      description?: string | null;
      education?: string | null;
    }> | null;
    asset?: {
      __typename?: 'AssetObj';
      id: string;
      type: string;
      name?: string | null;
      description?: string | null;
      types?: Array<string> | null;
      assetClass?: string | null;
      ISIN?: string | null;
      VPS?: string | null;
      lookup?: string | null;
      category?: { __typename?: 'AssetCategory'; id: string } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    } | null;
  } | null;
};

export type ProjectFragment =
  | Project_PortfolioProject_Fragment
  | Project_PrivateEquityFundProject_Fragment;

export type GetProjectsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  getProjects?: Array<
    | {
        __typename: 'PortfolioProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bankNote?: {
                  __typename: 'BankNote';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'BondHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bond?: { __typename: 'Bond'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                bondPositions?: Array<{
                  __typename?: 'BondPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                bondTrades?: Array<{
                  __typename?: 'BondTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'BondPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'CryptoHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                crypto?: {
                  __typename: 'Crypto';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'FundHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                fund?: { __typename: 'Fund'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                fundPositions?: Array<{
                  __typename?: 'FundPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                fundTrades?: Array<{
                  __typename?: 'FundTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'FundPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'LoanHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                loan?: { __typename: 'Loan'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'StockHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                stock?: {
                  __typename: 'Stock';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                stockPositions?: Array<{
                  __typename?: 'StockPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                stockTrades?: Array<{
                  __typename?: 'StockTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                  position?: {
                    __typename?: 'StockPosition';
                    id: string;
                  } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
                stockGoalRules?: Array<{
                  __typename?: 'StockGoalRule';
                  id: string;
                  goalRule: {
                    __typename?: 'GoalRule';
                    name: string;
                    formula: string;
                  };
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'WarrantHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                warrant?: {
                  __typename: 'Warrant';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
          > | null;
          transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            title?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
          }> | null;
        };
        portfolio?: {
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{
            __typename?: 'Portfolio';
            id: string;
            name?: string | null;
            description?: string | null;
            portfolios?: Array<{
              __typename?: 'Portfolio';
              id: string;
              name?: string | null;
              description?: string | null;
              portfolios?: Array<{
                __typename?: 'Portfolio';
                id: string;
              }> | null;
              parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
              allocations?: Array<{
                __typename?: 'Allocation';
                id: string;
                min?: number | null;
                max?: number | null;
                assetType?: string | null;
                assetClass?: string | null;
                category?: string | null;
                sector?: string | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                region?: { __typename?: 'Region'; id: string } | null;
              }> | null;
              holdings?: Array<
                | {
                    __typename: 'BankNoteHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'BondHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'CryptoHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'FundHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'LoanHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'StockHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'WarrantHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
              > | null;
            }> | null;
            parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
            allocations?: Array<{
              __typename?: 'Allocation';
              id: string;
              min?: number | null;
              max?: number | null;
              assetType?: string | null;
              assetClass?: string | null;
              category?: string | null;
              sector?: string | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              region?: { __typename?: 'Region'; id: string } | null;
            }> | null;
            holdings?: Array<
              | {
                  __typename: 'BankNoteHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'BondHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'CryptoHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'FundHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'LoanHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'StockHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'WarrantHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
            > | null;
          }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        } | null;
      }
    | {
        __typename: 'PrivateEquityFundProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bankNote?: {
                  __typename: 'BankNote';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'BondHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bond?: { __typename: 'Bond'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                bondPositions?: Array<{
                  __typename?: 'BondPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                bondTrades?: Array<{
                  __typename?: 'BondTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'BondPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'CryptoHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                crypto?: {
                  __typename: 'Crypto';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'FundHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                fund?: { __typename: 'Fund'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                fundPositions?: Array<{
                  __typename?: 'FundPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                fundTrades?: Array<{
                  __typename?: 'FundTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'FundPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'LoanHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                loan?: { __typename: 'Loan'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'StockHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                stock?: {
                  __typename: 'Stock';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                stockPositions?: Array<{
                  __typename?: 'StockPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                stockTrades?: Array<{
                  __typename?: 'StockTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                  position?: {
                    __typename?: 'StockPosition';
                    id: string;
                  } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
                stockGoalRules?: Array<{
                  __typename?: 'StockGoalRule';
                  id: string;
                  goalRule: {
                    __typename?: 'GoalRule';
                    name: string;
                    formula: string;
                  };
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'WarrantHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                warrant?: {
                  __typename: 'Warrant';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
          > | null;
          transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            title?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
          }> | null;
        };
        privateEquityFund?: {
          __typename: 'PrivateEquityFund';
          _type: string;
          id: string;
          description?: string | null;
          fundSetup?: {
            __typename?: 'PrivateEquityFundSetup';
            id: string;
            name: string;
            description: string;
            managementFee: number;
            hurdleRate: number;
            carry: number;
            carriedInterestType: string;
            startDate: any;
            endDate: any;
            endInvestmentDate: any;
          } | null;
          investors?: Array<{
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
            name?: string | null;
            description?: string | null;
            commitments?: Array<{
              __typename?: 'PrivateEquityFundCapitalCommitment';
              id: string;
              date?: any | null;
              value?: number | null;
              investor?: {
                __typename?: 'PrivateEquityFundInvestor';
                id: string;
              } | null;
            }> | null;
            capitalCallTransfer?: Array<{
              __typename?: 'PrivateEquityFundCapitalCallTransfer';
              id: string;
              capitalCall?: {
                __typename?: 'PrivateEquityFundCapitalCall';
                id: string;
                name?: string | null;
                description?: string | null;
                date?: any | null;
                value?: number | null;
              } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              } | null;
              investor?: {
                __typename?: 'PrivateEquityFundInvestor';
                id: string;
              } | null;
            }> | null;
            capitalDistributionTransfer?: Array<{
              __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
              id: string;
              capitalDistribution?: {
                __typename?: 'PrivateEquityFundCapitalDistribution';
                id: string;
                name?: string | null;
                description?: string | null;
                date?: any | null;
                value?: number | null;
              } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              } | null;
              investor?: {
                __typename?: 'PrivateEquityFundInvestor';
                id: string;
              } | null;
            }> | null;
            contacts?: Array<{
              __typename?: 'PrivateEquityFundContact';
              id: string;
              name?: string | null;
              email?: string | null;
            }> | null;
            users?: Array<{
              __typename?: 'User';
              id?: number | null;
              name?: string | null;
              email?: string | null;
            }> | null;
          }> | null;
          investments?: Array<{
            __typename?: 'PrivateEquityFundInvestment';
            id: string;
            name: string;
            description: string;
            exit?: any | null;
            image?: string | null;
            holding:
              | {
                  __typename: 'BankNoteHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bankNote?: {
                    __typename: 'BankNote';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'BondHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bond?: {
                    __typename: 'Bond';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  bondPositions?: Array<{
                    __typename?: 'BondPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  bondTrades?: Array<{
                    __typename?: 'BondTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'BondPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'CryptoHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  crypto?: {
                    __typename: 'Crypto';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'FundHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  fund?: {
                    __typename: 'Fund';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  fundPositions?: Array<{
                    __typename?: 'FundPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  fundTrades?: Array<{
                    __typename?: 'FundTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'FundPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'LoanHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  loan?: {
                    __typename: 'Loan';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'StockHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  stock?: {
                    __typename: 'Stock';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  stockPositions?: Array<{
                    __typename?: 'StockPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  stockTrades?: Array<{
                    __typename?: 'StockTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'StockPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                  stockGoalRules?: Array<{
                    __typename?: 'StockGoalRule';
                    id: string;
                    goalRule: {
                      __typename?: 'GoalRule';
                      name: string;
                      formula: string;
                    };
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'WarrantHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  warrant?: {
                    __typename: 'Warrant';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                };
          }> | null;
          capitalCalls?: Array<{
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          }> | null;
          capitalDistributions?: Array<{
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          }> | null;
          team?: Array<{
            __typename?: 'PrivateEquityFundTeamMember';
            id: string;
            name?: string | null;
            email?: string | null;
            tel?: string | null;
            title?: string | null;
            description?: string | null;
            education?: string | null;
          }> | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        } | null;
      }
  > | null;
};

export type GetProjectObjsShallowQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetProjectObjsShallowQuery = {
  __typename?: 'Query';
  getProjectObjs?: Array<{
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
  }> | null;
};

export type CreateProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateProjectInputType>>
    | InputMaybe<CreateProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreateProjectsMutation = {
  __typename?: 'Mutation';
  createProjects?: Array<{
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UpdateProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateProjectInputType>>
    | InputMaybe<UpdateProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateProjectsMutation = {
  __typename?: 'Mutation';
  updateProjects?: Array<{
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UpsertProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertProjectInputType>>
    | InputMaybe<UpsertProjectInputType>
  >;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertProjectsMutation = {
  __typename?: 'Mutation';
  upsertProjects?: Array<{
    __typename: 'ProjectObj';
    id: string;
    name?: string | null;
    type?: string | null;
    holdings?: Array<
      | {
          __typename: 'BankNoteHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bankNote?: {
            __typename: 'BankNote';
            _type: string;
            id: string;
          } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'BondHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          bond?: { __typename: 'Bond'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          bondPositions?: Array<{
            __typename?: 'BondPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
          }> | null;
          bondTrades?: Array<{
            __typename?: 'BondTrade';
            id: string;
            shares: number;
            sharePrice: number;
            bondHolding?: { __typename?: 'BondHoldingBase'; id: string } | null;
            position?: { __typename?: 'BondPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'CryptoHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          crypto?: { __typename: 'Crypto'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'FundHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          fund?: { __typename: 'Fund'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          fundPositions?: Array<{
            __typename?: 'FundPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
          }> | null;
          fundTrades?: Array<{
            __typename?: 'FundTrade';
            id: string;
            shares: number;
            sharePrice: number;
            fundHolding?: { __typename?: 'FundHoldingBase'; id: string } | null;
            position?: { __typename?: 'FundPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'LoanHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          loan?: { __typename: 'Loan'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
      | {
          __typename: 'StockHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          stock?: { __typename: 'Stock'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
          stockPositions?: Array<{
            __typename?: 'StockPosition';
            id: string;
            shares?: number | null;
            date?: any | null;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
          stockTrades?: Array<{
            __typename?: 'StockTrade';
            id: string;
            shares: number;
            sharePrice: number;
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
            position?: { __typename?: 'StockPosition'; id: string } | null;
            transfer?: {
              __typename?: 'Transfer';
              id: string;
              hash: string;
            } | null;
          }> | null;
          stockGoalRules?: Array<{
            __typename?: 'StockGoalRule';
            id: string;
            goalRule: {
              __typename?: 'GoalRule';
              name: string;
              formula: string;
            };
            stockHolding?: {
              __typename?: 'StockHoldingBase';
              id: string;
            } | null;
          }> | null;
        }
      | {
          __typename: 'WarrantHolding';
          id: string;
          valuations?: Array<{
            __typename?: 'Valuation';
            id: string;
            date?: any | null;
            value?: number | null;
            ownershipPercentage?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          commitments?: Array<{
            __typename?: 'Commitment';
            id: string;
            commitmentDate?: any | null;
            startDate?: any | null;
            endDate?: any | null;
            value?: number | null;
            type?: string | null;
            description?: string | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
            }> | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
          }> | null;
          transfers?: Array<{
            __typename?: 'Transfer';
            id: string;
            hash: string;
            date?: any | null;
            type?: string | null;
            subtype?: string | null;
            description?: string | null;
            value?: number | null;
            cost?: number | null;
            status?: number | null;
            holding?: { __typename?: 'HoldingObj'; id: string } | null;
            transaction?: { __typename?: 'Transaction'; id: string } | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            commitment?: { __typename?: 'Commitment'; id: string } | null;
          }> | null;
          warrant?: { __typename: 'Warrant'; _type: string; id: string } | null;
          holding: {
            __typename: 'HoldingObj';
            id: string;
            type?: string | null;
            name?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
          };
        }
    > | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id: string;
      title?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      transfers?: Array<{
        __typename?: 'Transfer';
        id: string;
        hash: string;
        date?: any | null;
        type?: string | null;
        subtype?: string | null;
        description?: string | null;
        value?: number | null;
        cost?: number | null;
        status?: number | null;
        holding?: { __typename?: 'HoldingObj'; id: string } | null;
        transaction?: { __typename?: 'Transaction'; id: string } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
        commitment?: { __typename?: 'Commitment'; id: string } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type DeleteProjectsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteProjectInputType>>
    | InputMaybe<DeleteProjectInputType>
  >;
}>;

export type DeleteProjectsMutation = {
  __typename?: 'Mutation';
  deleteProjects?: Array<string> | null;
};

export type GeneratedReportFragment = {
  __typename?: 'GeneratedReport';
  id: string;
  objectKey: string;
  name?: string | null;
  description?: string | null;
  date?: string | null;
  project?: { __typename?: 'ProjectObj'; id: string } | null;
  holding?: { __typename?: 'HoldingObj'; id: string } | null;
  report?: {
    __typename?: 'Report';
    id: string;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
    users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
  } | null;
};

export type GetGeneratedReportsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetGeneratedReportsQuery = {
  __typename?: 'Query';
  getGeneratedReports?: Array<{
    __typename?: 'GeneratedReport';
    id: string;
    objectKey: string;
    name?: string | null;
    description?: string | null;
    date?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    report?: {
      __typename?: 'Report';
      id: string;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
      users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
    } | null;
  }> | null;
};

export type GetGeneratedReportsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type GetGeneratedReportsByProjectQuery = {
  __typename?: 'Query';
  getGeneratedReportsByProject?: Array<{
    __typename?: 'GeneratedReport';
    id: string;
    objectKey: string;
    name?: string | null;
    description?: string | null;
    date?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    report?: {
      __typename?: 'Report';
      id: string;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
      users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
    } | null;
  }> | null;
};

export type CreateGeneratedReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateGeneratedReportInputType>>
    | InputMaybe<CreateGeneratedReportInputType>
  >;
}>;

export type CreateGeneratedReportsMutation = {
  __typename?: 'Mutation';
  createGeneratedReports?: Array<{
    __typename?: 'GeneratedReport';
    id: string;
    objectKey: string;
    name?: string | null;
    description?: string | null;
    date?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    report?: {
      __typename?: 'Report';
      id: string;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
      users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
    } | null;
  }> | null;
};

export type UpdateGeneratedReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateGeneratedReportInputType>>
    | InputMaybe<UpdateGeneratedReportInputType>
  >;
}>;

export type UpdateGeneratedReportsMutation = {
  __typename?: 'Mutation';
  updateGeneratedReports?: Array<{
    __typename?: 'GeneratedReport';
    id: string;
    objectKey: string;
    name?: string | null;
    description?: string | null;
    date?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    report?: {
      __typename?: 'Report';
      id: string;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
      users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
    } | null;
  }> | null;
};

export type UpsertGeneratedReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertGeneratedReportInputType>>
    | InputMaybe<UpsertGeneratedReportInputType>
  >;
}>;

export type UpsertGeneratedReportsMutation = {
  __typename?: 'Mutation';
  upsertGeneratedReports?: Array<{
    __typename?: 'GeneratedReport';
    id: string;
    objectKey: string;
    name?: string | null;
    description?: string | null;
    date?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    report?: {
      __typename?: 'Report';
      id: string;
      name?: string | null;
      description?: string | null;
      project?: { __typename?: 'ProjectObj'; id: string } | null;
      holding?: { __typename?: 'HoldingObj'; id: string } | null;
      scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
      users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
    } | null;
  }> | null;
};

export type DeleteGeneratedReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteGeneratedReportInputType>>
    | InputMaybe<DeleteGeneratedReportInputType>
  >;
}>;

export type DeleteGeneratedReportsMutation = {
  __typename?: 'Mutation';
  deleteGeneratedReports?: Array<string> | null;
};

export type ProjectReportFragment = {
  __typename?: 'ProjectReport';
  id: string;
  name?: string | null;
  periodStart?: any | null;
  periodEnd?: any | null;
  published?: boolean | null;
  compactType?: string | null;
  layouts?: string | null;
  project?: { __typename?: 'ProjectObj'; id: string } | null;
  elements?: Array<{
    __typename?: 'DashboardElement';
    id: string;
    name: string;
    type: string;
    config: string;
    media?: {
      __typename?: 'DashboardMedia';
      id: string;
      file?: { __typename?: 'File'; id: string } | null;
    } | null;
  }> | null;
};

export type GetProjectReportsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetProjectReportsQuery = {
  __typename?: 'Query';
  getProjectReports?: Array<{
    __typename?: 'ProjectReport';
    id: string;
    name?: string | null;
    periodStart?: any | null;
    periodEnd?: any | null;
    published?: boolean | null;
    compactType?: string | null;
    layouts?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type GetProjectReportsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type GetProjectReportsByProjectQuery = {
  __typename?: 'Query';
  getProjectReportsByProject?: Array<{
    __typename?: 'ProjectReport';
    id: string;
    name?: string | null;
    periodStart?: any | null;
    periodEnd?: any | null;
    published?: boolean | null;
    compactType?: string | null;
    layouts?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type CreateProjectReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateProjectReportInputType>>
    | InputMaybe<CreateProjectReportInputType>
  >;
}>;

export type CreateProjectReportsMutation = {
  __typename?: 'Mutation';
  createProjectReports?: Array<{
    __typename?: 'ProjectReport';
    id: string;
    name?: string | null;
    periodStart?: any | null;
    periodEnd?: any | null;
    published?: boolean | null;
    compactType?: string | null;
    layouts?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type UpdateProjectReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateProjectReportInputType>>
    | InputMaybe<UpdateProjectReportInputType>
  >;
}>;

export type UpdateProjectReportsMutation = {
  __typename?: 'Mutation';
  updateProjectReports?: Array<{
    __typename?: 'ProjectReport';
    id: string;
    name?: string | null;
    periodStart?: any | null;
    periodEnd?: any | null;
    published?: boolean | null;
    compactType?: string | null;
    layouts?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type UpsertProjectReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertProjectReportInputType>>
    | InputMaybe<UpsertProjectReportInputType>
  >;
}>;

export type UpsertProjectReportsMutation = {
  __typename?: 'Mutation';
  upsertProjectReports?: Array<{
    __typename?: 'ProjectReport';
    id: string;
    name?: string | null;
    periodStart?: any | null;
    periodEnd?: any | null;
    published?: boolean | null;
    compactType?: string | null;
    layouts?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    elements?: Array<{
      __typename?: 'DashboardElement';
      id: string;
      name: string;
      type: string;
      config: string;
      media?: {
        __typename?: 'DashboardMedia';
        id: string;
        file?: { __typename?: 'File'; id: string } | null;
      } | null;
    }> | null;
  }> | null;
};

export type DeleteProjectReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteProjectReportInputType>>
    | InputMaybe<DeleteProjectReportInputType>
  >;
}>;

export type DeleteProjectReportsMutation = {
  __typename?: 'Mutation';
  deleteProjectReports?: Array<string> | null;
};

export type ReportFragment = {
  __typename?: 'Report';
  id: string;
  name?: string | null;
  description?: string | null;
  project?: { __typename?: 'ProjectObj'; id: string } | null;
  holding?: { __typename?: 'HoldingObj'; id: string } | null;
  scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
  users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
};

export type GetReportsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetReportsQuery = {
  __typename?: 'Query';
  getReports?: Array<{
    __typename?: 'Report';
    id: string;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
    users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
  }> | null;
};

export type GetReportsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type GetReportsByProjectQuery = {
  __typename?: 'Query';
  getReportsByProject?: Array<{
    __typename?: 'Report';
    id: string;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
    users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
  }> | null;
};

export type CreateReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateReportInputType>> | InputMaybe<CreateReportInputType>
  >;
}>;

export type CreateReportsMutation = {
  __typename?: 'Mutation';
  createReports?: Array<{
    __typename?: 'Report';
    id: string;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
    users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
  }> | null;
};

export type UpdateReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateReportInputType>> | InputMaybe<UpdateReportInputType>
  >;
}>;

export type UpdateReportsMutation = {
  __typename?: 'Mutation';
  updateReports?: Array<{
    __typename?: 'Report';
    id: string;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
    users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
  }> | null;
};

export type UpsertReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertReportInputType>> | InputMaybe<UpsertReportInputType>
  >;
}>;

export type UpsertReportsMutation = {
  __typename?: 'Mutation';
  upsertReports?: Array<{
    __typename?: 'Report';
    id: string;
    name?: string | null;
    description?: string | null;
    project?: { __typename?: 'ProjectObj'; id: string } | null;
    holding?: { __typename?: 'HoldingObj'; id: string } | null;
    scheduler?: { __typename?: 'Scheduler'; id: string; cron: string } | null;
    users?: Array<{ __typename?: 'User'; id?: number | null }> | null;
  }> | null;
};

export type DeleteReportsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteReportInputType>> | InputMaybe<DeleteReportInputType>
  >;
}>;

export type DeleteReportsMutation = {
  __typename?: 'Mutation';
  deleteReports?: Array<string> | null;
};

export type UserFragment = {
  __typename?: 'User';
  id?: number | null;
  name?: string | null;
  email?: string | null;
};

export type WorkspacePermissionFragment = {
  __typename?: 'WorkspacePermission';
  addPortfolios?: boolean | null;
  editPortfolios?: boolean | null;
  removePortfolios?: boolean | null;
  viewPortfolios?: boolean | null;
  viewWorkspaceProperties?: boolean | null;
  editWorkspaceProperties?: boolean | null;
  addWorkspaceRoles?: boolean | null;
  updateWorkspaceRoles?: boolean | null;
  removeWorkspaceRoles?: boolean | null;
  addInvitations?: boolean | null;
  updateInvitations?: boolean | null;
  removeInvitations?: boolean | null;
  remove?: boolean | null;
};

export type WorkspaceRoleFragment = {
  __typename?: 'WorkspaceRole';
  id: number;
  name?: string | null;
  permission?: {
    __typename?: 'WorkspacePermission';
    addPortfolios?: boolean | null;
    editPortfolios?: boolean | null;
    removePortfolios?: boolean | null;
    viewPortfolios?: boolean | null;
    viewWorkspaceProperties?: boolean | null;
    editWorkspaceProperties?: boolean | null;
    addWorkspaceRoles?: boolean | null;
    updateWorkspaceRoles?: boolean | null;
    removeWorkspaceRoles?: boolean | null;
    addInvitations?: boolean | null;
    updateInvitations?: boolean | null;
    removeInvitations?: boolean | null;
    remove?: boolean | null;
  } | null;
  group?: {
    __typename?: 'Group';
    id?: number | null;
    name?: string | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
};

export type WorkspaceFragment = {
  __typename: 'Workspace';
  id: string;
  name?: string | null;
  projects?: Array<
    | {
        __typename: 'PortfolioProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bankNote?: {
                  __typename: 'BankNote';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'BondHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bond?: { __typename: 'Bond'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                bondPositions?: Array<{
                  __typename?: 'BondPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                bondTrades?: Array<{
                  __typename?: 'BondTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'BondPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'CryptoHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                crypto?: {
                  __typename: 'Crypto';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'FundHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                fund?: { __typename: 'Fund'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                fundPositions?: Array<{
                  __typename?: 'FundPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                fundTrades?: Array<{
                  __typename?: 'FundTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'FundPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'LoanHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                loan?: { __typename: 'Loan'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'StockHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                stock?: {
                  __typename: 'Stock';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                stockPositions?: Array<{
                  __typename?: 'StockPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                stockTrades?: Array<{
                  __typename?: 'StockTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                  position?: {
                    __typename?: 'StockPosition';
                    id: string;
                  } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
                stockGoalRules?: Array<{
                  __typename?: 'StockGoalRule';
                  id: string;
                  goalRule: {
                    __typename?: 'GoalRule';
                    name: string;
                    formula: string;
                  };
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'WarrantHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                warrant?: {
                  __typename: 'Warrant';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
          > | null;
          transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            title?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
          }> | null;
        };
        portfolio?: {
          __typename?: 'Portfolio';
          id: string;
          name?: string | null;
          description?: string | null;
          portfolios?: Array<{
            __typename?: 'Portfolio';
            id: string;
            name?: string | null;
            description?: string | null;
            portfolios?: Array<{
              __typename?: 'Portfolio';
              id: string;
              name?: string | null;
              description?: string | null;
              portfolios?: Array<{
                __typename?: 'Portfolio';
                id: string;
              }> | null;
              parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
              allocations?: Array<{
                __typename?: 'Allocation';
                id: string;
                min?: number | null;
                max?: number | null;
                assetType?: string | null;
                assetClass?: string | null;
                category?: string | null;
                sector?: string | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                region?: { __typename?: 'Region'; id: string } | null;
              }> | null;
              holdings?: Array<
                | {
                    __typename: 'BankNoteHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'BondHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'CryptoHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'FundHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'LoanHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'StockHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'WarrantHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
              > | null;
            }> | null;
            parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
            allocations?: Array<{
              __typename?: 'Allocation';
              id: string;
              min?: number | null;
              max?: number | null;
              assetType?: string | null;
              assetClass?: string | null;
              category?: string | null;
              sector?: string | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              region?: { __typename?: 'Region'; id: string } | null;
            }> | null;
            holdings?: Array<
              | {
                  __typename: 'BankNoteHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'BondHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'CryptoHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'FundHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'LoanHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'StockHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'WarrantHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
            > | null;
          }> | null;
          parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
          allocations?: Array<{
            __typename?: 'Allocation';
            id: string;
            min?: number | null;
            max?: number | null;
            assetType?: string | null;
            assetClass?: string | null;
            category?: string | null;
            sector?: string | null;
            currency?: { __typename?: 'Currency'; id: string } | null;
            region?: { __typename?: 'Region'; id: string } | null;
          }> | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'BondHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'CryptoHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'FundHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'LoanHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'StockHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
            | {
                __typename: 'WarrantHolding';
                holding: { __typename?: 'HoldingObj'; id: string };
              }
          > | null;
        } | null;
      }
    | {
        __typename: 'PrivateEquityFundProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
          holdings?: Array<
            | {
                __typename: 'BankNoteHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bankNote?: {
                  __typename: 'BankNote';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'BondHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                bond?: { __typename: 'Bond'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                bondPositions?: Array<{
                  __typename?: 'BondPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                bondTrades?: Array<{
                  __typename?: 'BondTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  bondHolding?: {
                    __typename?: 'BondHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'BondPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'CryptoHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                crypto?: {
                  __typename: 'Crypto';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'FundHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                fund?: { __typename: 'Fund'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                fundPositions?: Array<{
                  __typename?: 'FundPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                fundTrades?: Array<{
                  __typename?: 'FundTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  fundHolding?: {
                    __typename?: 'FundHoldingBase';
                    id: string;
                  } | null;
                  position?: { __typename?: 'FundPosition'; id: string } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'LoanHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                loan?: { __typename: 'Loan'; _type: string; id: string } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
            | {
                __typename: 'StockHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                stock?: {
                  __typename: 'Stock';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
                stockPositions?: Array<{
                  __typename?: 'StockPosition';
                  id: string;
                  shares?: number | null;
                  date?: any | null;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
                stockTrades?: Array<{
                  __typename?: 'StockTrade';
                  id: string;
                  shares: number;
                  sharePrice: number;
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                  position?: {
                    __typename?: 'StockPosition';
                    id: string;
                  } | null;
                  transfer?: {
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  } | null;
                }> | null;
                stockGoalRules?: Array<{
                  __typename?: 'StockGoalRule';
                  id: string;
                  goalRule: {
                    __typename?: 'GoalRule';
                    name: string;
                    formula: string;
                  };
                  stockHolding?: {
                    __typename?: 'StockHoldingBase';
                    id: string;
                  } | null;
                }> | null;
              }
            | {
                __typename: 'WarrantHolding';
                id: string;
                valuations?: Array<{
                  __typename?: 'Valuation';
                  id: string;
                  date?: any | null;
                  value?: number | null;
                  ownershipPercentage?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                commitments?: Array<{
                  __typename?: 'Commitment';
                  id: string;
                  commitmentDate?: any | null;
                  startDate?: any | null;
                  endDate?: any | null;
                  value?: number | null;
                  type?: string | null;
                  description?: string | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                  }> | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                }> | null;
                transfers?: Array<{
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                }> | null;
                warrant?: {
                  __typename: 'Warrant';
                  _type: string;
                  id: string;
                } | null;
                holding: {
                  __typename: 'HoldingObj';
                  id: string;
                  type?: string | null;
                  name?: string | null;
                  description?: string | null;
                  project?: { __typename?: 'ProjectObj'; id: string } | null;
                };
              }
          > | null;
          transactions?: Array<{
            __typename?: 'Transaction';
            id: string;
            title?: string | null;
            description?: string | null;
            project?: { __typename?: 'ProjectObj'; id: string } | null;
            transfers?: Array<{
              __typename?: 'Transfer';
              id: string;
              hash: string;
              date?: any | null;
              type?: string | null;
              subtype?: string | null;
              description?: string | null;
              value?: number | null;
              cost?: number | null;
              status?: number | null;
              holding?: { __typename?: 'HoldingObj'; id: string } | null;
              transaction?: { __typename?: 'Transaction'; id: string } | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              commitment?: { __typename?: 'Commitment'; id: string } | null;
            }> | null;
          }> | null;
        };
        privateEquityFund?: {
          __typename: 'PrivateEquityFund';
          _type: string;
          id: string;
          description?: string | null;
          fundSetup?: {
            __typename?: 'PrivateEquityFundSetup';
            id: string;
            name: string;
            description: string;
            managementFee: number;
            hurdleRate: number;
            carry: number;
            carriedInterestType: string;
            startDate: any;
            endDate: any;
            endInvestmentDate: any;
          } | null;
          investors?: Array<{
            __typename?: 'PrivateEquityFundInvestor';
            id: string;
            name?: string | null;
            description?: string | null;
            commitments?: Array<{
              __typename?: 'PrivateEquityFundCapitalCommitment';
              id: string;
              date?: any | null;
              value?: number | null;
              investor?: {
                __typename?: 'PrivateEquityFundInvestor';
                id: string;
              } | null;
            }> | null;
            capitalCallTransfer?: Array<{
              __typename?: 'PrivateEquityFundCapitalCallTransfer';
              id: string;
              capitalCall?: {
                __typename?: 'PrivateEquityFundCapitalCall';
                id: string;
                name?: string | null;
                description?: string | null;
                date?: any | null;
                value?: number | null;
              } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              } | null;
              investor?: {
                __typename?: 'PrivateEquityFundInvestor';
                id: string;
              } | null;
            }> | null;
            capitalDistributionTransfer?: Array<{
              __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
              id: string;
              capitalDistribution?: {
                __typename?: 'PrivateEquityFundCapitalDistribution';
                id: string;
                name?: string | null;
                description?: string | null;
                date?: any | null;
                value?: number | null;
              } | null;
              transfer?: {
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              } | null;
              investor?: {
                __typename?: 'PrivateEquityFundInvestor';
                id: string;
              } | null;
            }> | null;
            contacts?: Array<{
              __typename?: 'PrivateEquityFundContact';
              id: string;
              name?: string | null;
              email?: string | null;
            }> | null;
            users?: Array<{
              __typename?: 'User';
              id?: number | null;
              name?: string | null;
              email?: string | null;
            }> | null;
          }> | null;
          investments?: Array<{
            __typename?: 'PrivateEquityFundInvestment';
            id: string;
            name: string;
            description: string;
            exit?: any | null;
            image?: string | null;
            holding:
              | {
                  __typename: 'BankNoteHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bankNote?: {
                    __typename: 'BankNote';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'BondHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bond?: {
                    __typename: 'Bond';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  bondPositions?: Array<{
                    __typename?: 'BondPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  bondTrades?: Array<{
                    __typename?: 'BondTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'BondPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'CryptoHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  crypto?: {
                    __typename: 'Crypto';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'FundHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  fund?: {
                    __typename: 'Fund';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  fundPositions?: Array<{
                    __typename?: 'FundPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  fundTrades?: Array<{
                    __typename?: 'FundTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'FundPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'LoanHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  loan?: {
                    __typename: 'Loan';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'StockHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  stock?: {
                    __typename: 'Stock';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  stockPositions?: Array<{
                    __typename?: 'StockPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  stockTrades?: Array<{
                    __typename?: 'StockTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'StockPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                  stockGoalRules?: Array<{
                    __typename?: 'StockGoalRule';
                    id: string;
                    goalRule: {
                      __typename?: 'GoalRule';
                      name: string;
                      formula: string;
                    };
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'WarrantHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  warrant?: {
                    __typename: 'Warrant';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                };
          }> | null;
          capitalCalls?: Array<{
            __typename?: 'PrivateEquityFundCapitalCall';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          }> | null;
          capitalDistributions?: Array<{
            __typename?: 'PrivateEquityFundCapitalDistribution';
            id: string;
            name?: string | null;
            description?: string | null;
            date?: any | null;
            value?: number | null;
          }> | null;
          team?: Array<{
            __typename?: 'PrivateEquityFundTeamMember';
            id: string;
            name?: string | null;
            email?: string | null;
            tel?: string | null;
            title?: string | null;
            description?: string | null;
            education?: string | null;
          }> | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        } | null;
      }
  > | null;
  assets?: Array<
    | {
        __typename: 'BankNote';
        _type: string;
        id: string;
        symbol?: string | null;
        accountNumber?: string | null;
        bank?: string | null;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
      }
    | {
        __typename: 'Bond';
        _type: string;
        id: string;
        paperId?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Crypto';
        _type: string;
        id: string;
        symbol?: string | null;
        accountNumber?: string | null;
        bank?: string | null;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
        currency?: { __typename?: 'Currency'; id: string } | null;
      }
    | {
        __typename: 'Fund';
        _type: string;
        id: string;
        paperId?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Loan';
        _type: string;
        id: string;
        interest?: number | null;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'PrivateEquityFund';
        _type: string;
        id: string;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Stock';
        _type: string;
        id: string;
        paperId?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
    | {
        __typename: 'Warrant';
        _type: string;
        id: string;
        description?: string | null;
        asset?: {
          __typename?: 'AssetObj';
          id: string;
          type: string;
          name?: string | null;
          description?: string | null;
          types?: Array<string> | null;
          assetClass?: string | null;
          ISIN?: string | null;
          VPS?: string | null;
          lookup?: string | null;
          category?: { __typename?: 'AssetCategory'; id: string } | null;
          entity?: { __typename?: 'Entity'; id: string } | null;
        } | null;
      }
  > | null;
  entities?: Array<{
    __typename: 'Entity';
    id: string;
    name?: string | null;
    description?: string | null;
    sector?: string | null;
    website?: string | null;
    regionAllocations?: Array<{
      __typename?: 'RegionAllocation';
      id: string;
      date?: any | null;
      allocation?: number | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
      region?: { __typename?: 'Region'; id: string } | null;
    }> | null;
    media?: Array<{
      __typename?: 'EntityMedia';
      id: string;
      file?: {
        __typename?: 'File';
        id: string;
        key?: string | null;
        name?: string | null;
        type?: string | null;
        description?: string | null;
      } | null;
      entity?: { __typename?: 'Entity'; id: string } | null;
    }> | null;
  }> | null;
  people?: Array<{
    __typename?: 'Person';
    id: string;
    name?: string | null;
    description?: string | null;
    roles?: Array<{
      __typename?: 'Role';
      id: string;
      name?: string | null;
      description?: string | null;
      holding?: {
        __typename?: 'HoldingObj';
        id: string;
        project?: { __typename?: 'ProjectObj'; id: string } | null;
      } | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    phones?: Array<{
      __typename?: 'Phone';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    emails?: Array<{
      __typename?: 'Email';
      id: string;
      value?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
    addresses?: Array<{
      __typename?: 'Address';
      id: string;
      streetAddress?: string | null;
      streetNumber?: string | null;
      administrativeAreaLevel1?: string | null;
      administrativeAreaLevel2?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      person?: { __typename?: 'Person'; id: string } | null;
    }> | null;
  }> | null;
};

export type WorkspaceShallowFragment = {
  __typename: 'Workspace';
  id: string;
  name?: string | null;
  projects?: Array<
    | {
        __typename: 'PortfolioProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
        };
      }
    | {
        __typename: 'PrivateEquityFundProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
        };
      }
  > | null;
};

export type WorkspaceBaseWithRolesFragment = {
  __typename: 'WorkspaceBaseWithRoles';
  id: string;
  name?: string | null;
  workspaceRoles?: Array<{
    __typename?: 'WorkspaceRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'WorkspacePermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewWorkspaceProperties?: boolean | null;
      editWorkspaceProperties?: boolean | null;
      addWorkspaceRoles?: boolean | null;
      updateWorkspaceRoles?: boolean | null;
      removeWorkspaceRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
  }> | null;
};

export type WorkspaceWithRolesFragment = {
  __typename: 'Workspace';
  id: string;
  name?: string | null;
  projects?: Array<
    | {
        __typename: 'PortfolioProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
        };
      }
    | {
        __typename: 'PrivateEquityFundProject';
        id: string;
        project: {
          __typename: 'ProjectObj';
          id: string;
          name?: string | null;
          type?: string | null;
        };
      }
  > | null;
  workspaceRoles?: Array<{
    __typename?: 'WorkspaceRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'WorkspacePermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewWorkspaceProperties?: boolean | null;
      editWorkspaceProperties?: boolean | null;
      addWorkspaceRoles?: boolean | null;
      updateWorkspaceRoles?: boolean | null;
      removeWorkspaceRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
  }> | null;
};

export type GetWorkspaceQueryVariables = Exact<{
  withAssets?: InputMaybe<Scalars['Boolean']>;
  withEntities?: InputMaybe<Scalars['Boolean']>;
  withPeople?: InputMaybe<Scalars['Boolean']>;
  withTransfers?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetWorkspaceQuery = {
  __typename?: 'Query';
  getWorkspace?: {
    __typename: 'Workspace';
    id: string;
    name?: string | null;
    projects?: Array<
      | {
          __typename: 'PortfolioProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
            holdings?: Array<
              | {
                  __typename: 'BankNoteHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bankNote?: {
                    __typename: 'BankNote';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'BondHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bond?: {
                    __typename: 'Bond';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  bondPositions?: Array<{
                    __typename?: 'BondPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  bondTrades?: Array<{
                    __typename?: 'BondTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'BondPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'CryptoHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  crypto?: {
                    __typename: 'Crypto';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'FundHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  fund?: {
                    __typename: 'Fund';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  fundPositions?: Array<{
                    __typename?: 'FundPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  fundTrades?: Array<{
                    __typename?: 'FundTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'FundPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'LoanHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  loan?: {
                    __typename: 'Loan';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'StockHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  stock?: {
                    __typename: 'Stock';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  stockPositions?: Array<{
                    __typename?: 'StockPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  stockTrades?: Array<{
                    __typename?: 'StockTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'StockPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                  stockGoalRules?: Array<{
                    __typename?: 'StockGoalRule';
                    id: string;
                    goalRule: {
                      __typename?: 'GoalRule';
                      name: string;
                      formula: string;
                    };
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'WarrantHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  warrant?: {
                    __typename: 'Warrant';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
            > | null;
            transactions?: Array<{
              __typename?: 'Transaction';
              id: string;
              title?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
            }> | null;
          };
          portfolio?: {
            __typename?: 'Portfolio';
            id: string;
            name?: string | null;
            description?: string | null;
            portfolios?: Array<{
              __typename?: 'Portfolio';
              id: string;
              name?: string | null;
              description?: string | null;
              portfolios?: Array<{
                __typename?: 'Portfolio';
                id: string;
                name?: string | null;
                description?: string | null;
                portfolios?: Array<{
                  __typename?: 'Portfolio';
                  id: string;
                }> | null;
                parentPortfolio?: {
                  __typename?: 'Portfolio';
                  id: string;
                } | null;
                allocations?: Array<{
                  __typename?: 'Allocation';
                  id: string;
                  min?: number | null;
                  max?: number | null;
                  assetType?: string | null;
                  assetClass?: string | null;
                  category?: string | null;
                  sector?: string | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  region?: { __typename?: 'Region'; id: string } | null;
                }> | null;
                holdings?: Array<
                  | {
                      __typename: 'BankNoteHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                  | {
                      __typename: 'BondHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                  | {
                      __typename: 'CryptoHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                  | {
                      __typename: 'FundHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                  | {
                      __typename: 'LoanHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                  | {
                      __typename: 'StockHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                  | {
                      __typename: 'WarrantHolding';
                      holding: { __typename?: 'HoldingObj'; id: string };
                    }
                > | null;
              }> | null;
              parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
              allocations?: Array<{
                __typename?: 'Allocation';
                id: string;
                min?: number | null;
                max?: number | null;
                assetType?: string | null;
                assetClass?: string | null;
                category?: string | null;
                sector?: string | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                region?: { __typename?: 'Region'; id: string } | null;
              }> | null;
              holdings?: Array<
                | {
                    __typename: 'BankNoteHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'BondHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'CryptoHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'FundHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'LoanHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'StockHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
                | {
                    __typename: 'WarrantHolding';
                    holding: { __typename?: 'HoldingObj'; id: string };
                  }
              > | null;
            }> | null;
            parentPortfolio?: { __typename?: 'Portfolio'; id: string } | null;
            allocations?: Array<{
              __typename?: 'Allocation';
              id: string;
              min?: number | null;
              max?: number | null;
              assetType?: string | null;
              assetClass?: string | null;
              category?: string | null;
              sector?: string | null;
              currency?: { __typename?: 'Currency'; id: string } | null;
              region?: { __typename?: 'Region'; id: string } | null;
            }> | null;
            holdings?: Array<
              | {
                  __typename: 'BankNoteHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'BondHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'CryptoHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'FundHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'LoanHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'StockHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
              | {
                  __typename: 'WarrantHolding';
                  holding: { __typename?: 'HoldingObj'; id: string };
                }
            > | null;
          } | null;
        }
      | {
          __typename: 'PrivateEquityFundProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
            holdings?: Array<
              | {
                  __typename: 'BankNoteHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bankNote?: {
                    __typename: 'BankNote';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'BondHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  bond?: {
                    __typename: 'Bond';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  bondPositions?: Array<{
                    __typename?: 'BondPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  bondTrades?: Array<{
                    __typename?: 'BondTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    bondHolding?: {
                      __typename?: 'BondHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'BondPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'CryptoHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  crypto?: {
                    __typename: 'Crypto';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'FundHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  fund?: {
                    __typename: 'Fund';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  fundPositions?: Array<{
                    __typename?: 'FundPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  fundTrades?: Array<{
                    __typename?: 'FundTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    fundHolding?: {
                      __typename?: 'FundHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'FundPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'LoanHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  loan?: {
                    __typename: 'Loan';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
              | {
                  __typename: 'StockHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  stock?: {
                    __typename: 'Stock';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                  stockPositions?: Array<{
                    __typename?: 'StockPosition';
                    id: string;
                    shares?: number | null;
                    date?: any | null;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                  stockTrades?: Array<{
                    __typename?: 'StockTrade';
                    id: string;
                    shares: number;
                    sharePrice: number;
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                    position?: {
                      __typename?: 'StockPosition';
                      id: string;
                    } | null;
                    transfer?: {
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    } | null;
                  }> | null;
                  stockGoalRules?: Array<{
                    __typename?: 'StockGoalRule';
                    id: string;
                    goalRule: {
                      __typename?: 'GoalRule';
                      name: string;
                      formula: string;
                    };
                    stockHolding?: {
                      __typename?: 'StockHoldingBase';
                      id: string;
                    } | null;
                  }> | null;
                }
              | {
                  __typename: 'WarrantHolding';
                  id: string;
                  valuations?: Array<{
                    __typename?: 'Valuation';
                    id: string;
                    date?: any | null;
                    value?: number | null;
                    ownershipPercentage?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  commitments?: Array<{
                    __typename?: 'Commitment';
                    id: string;
                    commitmentDate?: any | null;
                    startDate?: any | null;
                    endDate?: any | null;
                    value?: number | null;
                    type?: string | null;
                    description?: string | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                    }> | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                  }> | null;
                  transfers?: Array<{
                    __typename?: 'Transfer';
                    id: string;
                    hash: string;
                    date?: any | null;
                    type?: string | null;
                    subtype?: string | null;
                    description?: string | null;
                    value?: number | null;
                    cost?: number | null;
                    status?: number | null;
                    holding?: { __typename?: 'HoldingObj'; id: string } | null;
                    transaction?: {
                      __typename?: 'Transaction';
                      id: string;
                    } | null;
                    currency?: { __typename?: 'Currency'; id: string } | null;
                    commitment?: {
                      __typename?: 'Commitment';
                      id: string;
                    } | null;
                  }> | null;
                  warrant?: {
                    __typename: 'Warrant';
                    _type: string;
                    id: string;
                  } | null;
                  holding: {
                    __typename: 'HoldingObj';
                    id: string;
                    type?: string | null;
                    name?: string | null;
                    description?: string | null;
                    project?: { __typename?: 'ProjectObj'; id: string } | null;
                  };
                }
            > | null;
            transactions?: Array<{
              __typename?: 'Transaction';
              id: string;
              title?: string | null;
              description?: string | null;
              project?: { __typename?: 'ProjectObj'; id: string } | null;
              transfers?: Array<{
                __typename?: 'Transfer';
                id: string;
                hash: string;
                date?: any | null;
                type?: string | null;
                subtype?: string | null;
                description?: string | null;
                value?: number | null;
                cost?: number | null;
                status?: number | null;
                holding?: { __typename?: 'HoldingObj'; id: string } | null;
                transaction?: { __typename?: 'Transaction'; id: string } | null;
                currency?: { __typename?: 'Currency'; id: string } | null;
                commitment?: { __typename?: 'Commitment'; id: string } | null;
              }> | null;
            }> | null;
          };
          privateEquityFund?: {
            __typename: 'PrivateEquityFund';
            _type: string;
            id: string;
            description?: string | null;
            fundSetup?: {
              __typename?: 'PrivateEquityFundSetup';
              id: string;
              name: string;
              description: string;
              managementFee: number;
              hurdleRate: number;
              carry: number;
              carriedInterestType: string;
              startDate: any;
              endDate: any;
              endInvestmentDate: any;
            } | null;
            investors?: Array<{
              __typename?: 'PrivateEquityFundInvestor';
              id: string;
              name?: string | null;
              description?: string | null;
              commitments?: Array<{
                __typename?: 'PrivateEquityFundCapitalCommitment';
                id: string;
                date?: any | null;
                value?: number | null;
                investor?: {
                  __typename?: 'PrivateEquityFundInvestor';
                  id: string;
                } | null;
              }> | null;
              capitalCallTransfer?: Array<{
                __typename?: 'PrivateEquityFundCapitalCallTransfer';
                id: string;
                capitalCall?: {
                  __typename?: 'PrivateEquityFundCapitalCall';
                  id: string;
                  name?: string | null;
                  description?: string | null;
                  date?: any | null;
                  value?: number | null;
                } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                } | null;
                investor?: {
                  __typename?: 'PrivateEquityFundInvestor';
                  id: string;
                } | null;
              }> | null;
              capitalDistributionTransfer?: Array<{
                __typename?: 'PrivateEquityFundCapitalDistributionTransfer';
                id: string;
                capitalDistribution?: {
                  __typename?: 'PrivateEquityFundCapitalDistribution';
                  id: string;
                  name?: string | null;
                  description?: string | null;
                  date?: any | null;
                  value?: number | null;
                } | null;
                transfer?: {
                  __typename?: 'Transfer';
                  id: string;
                  hash: string;
                  date?: any | null;
                  type?: string | null;
                  subtype?: string | null;
                  description?: string | null;
                  value?: number | null;
                  cost?: number | null;
                  status?: number | null;
                  holding?: { __typename?: 'HoldingObj'; id: string } | null;
                  transaction?: {
                    __typename?: 'Transaction';
                    id: string;
                  } | null;
                  currency?: { __typename?: 'Currency'; id: string } | null;
                  commitment?: { __typename?: 'Commitment'; id: string } | null;
                } | null;
                investor?: {
                  __typename?: 'PrivateEquityFundInvestor';
                  id: string;
                } | null;
              }> | null;
              contacts?: Array<{
                __typename?: 'PrivateEquityFundContact';
                id: string;
                name?: string | null;
                email?: string | null;
              }> | null;
              users?: Array<{
                __typename?: 'User';
                id?: number | null;
                name?: string | null;
                email?: string | null;
              }> | null;
            }> | null;
            investments?: Array<{
              __typename?: 'PrivateEquityFundInvestment';
              id: string;
              name: string;
              description: string;
              exit?: any | null;
              image?: string | null;
              holding:
                | {
                    __typename: 'BankNoteHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    bankNote?: {
                      __typename: 'BankNote';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                  }
                | {
                    __typename: 'BondHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    bond?: {
                      __typename: 'Bond';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                    bondPositions?: Array<{
                      __typename?: 'BondPosition';
                      id: string;
                      shares?: number | null;
                      date?: any | null;
                      bondHolding?: {
                        __typename?: 'BondHoldingBase';
                        id: string;
                      } | null;
                    }> | null;
                    bondTrades?: Array<{
                      __typename?: 'BondTrade';
                      id: string;
                      shares: number;
                      sharePrice: number;
                      bondHolding?: {
                        __typename?: 'BondHoldingBase';
                        id: string;
                      } | null;
                      position?: {
                        __typename?: 'BondPosition';
                        id: string;
                      } | null;
                      transfer?: {
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      } | null;
                    }> | null;
                  }
                | {
                    __typename: 'CryptoHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    crypto?: {
                      __typename: 'Crypto';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                  }
                | {
                    __typename: 'FundHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    fund?: {
                      __typename: 'Fund';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                    fundPositions?: Array<{
                      __typename?: 'FundPosition';
                      id: string;
                      shares?: number | null;
                      date?: any | null;
                      fundHolding?: {
                        __typename?: 'FundHoldingBase';
                        id: string;
                      } | null;
                    }> | null;
                    fundTrades?: Array<{
                      __typename?: 'FundTrade';
                      id: string;
                      shares: number;
                      sharePrice: number;
                      fundHolding?: {
                        __typename?: 'FundHoldingBase';
                        id: string;
                      } | null;
                      position?: {
                        __typename?: 'FundPosition';
                        id: string;
                      } | null;
                      transfer?: {
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      } | null;
                    }> | null;
                  }
                | {
                    __typename: 'LoanHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    loan?: {
                      __typename: 'Loan';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                  }
                | {
                    __typename: 'StockHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    stock?: {
                      __typename: 'Stock';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                    stockPositions?: Array<{
                      __typename?: 'StockPosition';
                      id: string;
                      shares?: number | null;
                      date?: any | null;
                      stockHolding?: {
                        __typename?: 'StockHoldingBase';
                        id: string;
                      } | null;
                    }> | null;
                    stockTrades?: Array<{
                      __typename?: 'StockTrade';
                      id: string;
                      shares: number;
                      sharePrice: number;
                      stockHolding?: {
                        __typename?: 'StockHoldingBase';
                        id: string;
                      } | null;
                      position?: {
                        __typename?: 'StockPosition';
                        id: string;
                      } | null;
                      transfer?: {
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      } | null;
                    }> | null;
                    stockGoalRules?: Array<{
                      __typename?: 'StockGoalRule';
                      id: string;
                      goalRule: {
                        __typename?: 'GoalRule';
                        name: string;
                        formula: string;
                      };
                      stockHolding?: {
                        __typename?: 'StockHoldingBase';
                        id: string;
                      } | null;
                    }> | null;
                  }
                | {
                    __typename: 'WarrantHolding';
                    id: string;
                    valuations?: Array<{
                      __typename?: 'Valuation';
                      id: string;
                      date?: any | null;
                      value?: number | null;
                      ownershipPercentage?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    commitments?: Array<{
                      __typename?: 'Commitment';
                      id: string;
                      commitmentDate?: any | null;
                      startDate?: any | null;
                      endDate?: any | null;
                      value?: number | null;
                      type?: string | null;
                      description?: string | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transfers?: Array<{
                        __typename?: 'Transfer';
                        id: string;
                        hash: string;
                      }> | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                    }> | null;
                    transfers?: Array<{
                      __typename?: 'Transfer';
                      id: string;
                      hash: string;
                      date?: any | null;
                      type?: string | null;
                      subtype?: string | null;
                      description?: string | null;
                      value?: number | null;
                      cost?: number | null;
                      status?: number | null;
                      holding?: {
                        __typename?: 'HoldingObj';
                        id: string;
                      } | null;
                      transaction?: {
                        __typename?: 'Transaction';
                        id: string;
                      } | null;
                      currency?: { __typename?: 'Currency'; id: string } | null;
                      commitment?: {
                        __typename?: 'Commitment';
                        id: string;
                      } | null;
                    }> | null;
                    warrant?: {
                      __typename: 'Warrant';
                      _type: string;
                      id: string;
                    } | null;
                    holding: {
                      __typename: 'HoldingObj';
                      id: string;
                      type?: string | null;
                      name?: string | null;
                      description?: string | null;
                      project?: {
                        __typename?: 'ProjectObj';
                        id: string;
                      } | null;
                    };
                  };
            }> | null;
            capitalCalls?: Array<{
              __typename?: 'PrivateEquityFundCapitalCall';
              id: string;
              name?: string | null;
              description?: string | null;
              date?: any | null;
              value?: number | null;
            }> | null;
            capitalDistributions?: Array<{
              __typename?: 'PrivateEquityFundCapitalDistribution';
              id: string;
              name?: string | null;
              description?: string | null;
              date?: any | null;
              value?: number | null;
            }> | null;
            team?: Array<{
              __typename?: 'PrivateEquityFundTeamMember';
              id: string;
              name?: string | null;
              email?: string | null;
              tel?: string | null;
              title?: string | null;
              description?: string | null;
              education?: string | null;
            }> | null;
            asset?: {
              __typename?: 'AssetObj';
              id: string;
              type: string;
              name?: string | null;
              description?: string | null;
              types?: Array<string> | null;
              assetClass?: string | null;
              ISIN?: string | null;
              VPS?: string | null;
              lookup?: string | null;
              category?: { __typename?: 'AssetCategory'; id: string } | null;
              entity?: { __typename?: 'Entity'; id: string } | null;
            } | null;
          } | null;
        }
    > | null;
    assets?: Array<
      | {
          __typename: 'BankNote';
          _type: string;
          id: string;
          symbol?: string | null;
          accountNumber?: string | null;
          bank?: string | null;
          description?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }
      | {
          __typename: 'Bond';
          _type: string;
          id: string;
          paperId?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        }
      | {
          __typename: 'Crypto';
          _type: string;
          id: string;
          symbol?: string | null;
          accountNumber?: string | null;
          bank?: string | null;
          description?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
          currency?: { __typename?: 'Currency'; id: string } | null;
        }
      | {
          __typename: 'Fund';
          _type: string;
          id: string;
          paperId?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        }
      | {
          __typename: 'Loan';
          _type: string;
          id: string;
          interest?: number | null;
          description?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        }
      | {
          __typename: 'PrivateEquityFund';
          _type: string;
          id: string;
          description?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        }
      | {
          __typename: 'Stock';
          _type: string;
          id: string;
          paperId?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        }
      | {
          __typename: 'Warrant';
          _type: string;
          id: string;
          description?: string | null;
          asset?: {
            __typename?: 'AssetObj';
            id: string;
            type: string;
            name?: string | null;
            description?: string | null;
            types?: Array<string> | null;
            assetClass?: string | null;
            ISIN?: string | null;
            VPS?: string | null;
            lookup?: string | null;
            category?: { __typename?: 'AssetCategory'; id: string } | null;
            entity?: { __typename?: 'Entity'; id: string } | null;
          } | null;
        }
    > | null;
    entities?: Array<{
      __typename: 'Entity';
      id: string;
      name?: string | null;
      description?: string | null;
      sector?: string | null;
      website?: string | null;
      regionAllocations?: Array<{
        __typename?: 'RegionAllocation';
        id: string;
        date?: any | null;
        allocation?: number | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
        region?: { __typename?: 'Region'; id: string } | null;
      }> | null;
      media?: Array<{
        __typename?: 'EntityMedia';
        id: string;
        file?: {
          __typename?: 'File';
          id: string;
          key?: string | null;
          name?: string | null;
          type?: string | null;
          description?: string | null;
        } | null;
        entity?: { __typename?: 'Entity'; id: string } | null;
      }> | null;
    }> | null;
    people?: Array<{
      __typename?: 'Person';
      id: string;
      name?: string | null;
      description?: string | null;
      roles?: Array<{
        __typename?: 'Role';
        id: string;
        name?: string | null;
        description?: string | null;
        holding?: {
          __typename?: 'HoldingObj';
          id: string;
          project?: { __typename?: 'ProjectObj'; id: string } | null;
        } | null;
        person?: { __typename?: 'Person'; id: string } | null;
      }> | null;
      phones?: Array<{
        __typename?: 'Phone';
        id: string;
        value?: string | null;
        person?: { __typename?: 'Person'; id: string } | null;
      }> | null;
      emails?: Array<{
        __typename?: 'Email';
        id: string;
        value?: string | null;
        person?: { __typename?: 'Person'; id: string } | null;
      }> | null;
      addresses?: Array<{
        __typename?: 'Address';
        id: string;
        streetAddress?: string | null;
        streetNumber?: string | null;
        administrativeAreaLevel1?: string | null;
        administrativeAreaLevel2?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        person?: { __typename?: 'Person'; id: string } | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetWorkspaceShallowQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkspaceShallowQuery = {
  __typename?: 'Query';
  getWorkspace?: {
    __typename: 'Workspace';
    id: string;
    name?: string | null;
    projects?: Array<
      | {
          __typename: 'PortfolioProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
          };
        }
      | {
          __typename: 'PrivateEquityFundProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
          };
        }
    > | null;
  } | null;
};

export type CreateWorkspaceMutationVariables = Exact<{
  input?: InputMaybe<CreateWorkspaceInputType>;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation';
  createWorkspace?: {
    __typename: 'WorkspaceBaseWithRoles';
    id: string;
    name?: string | null;
    workspaceRoles?: Array<{
      __typename?: 'WorkspaceRole';
      id: number;
      name?: string | null;
      permission?: {
        __typename?: 'WorkspacePermission';
        addPortfolios?: boolean | null;
        editPortfolios?: boolean | null;
        removePortfolios?: boolean | null;
        viewPortfolios?: boolean | null;
        viewWorkspaceProperties?: boolean | null;
        editWorkspaceProperties?: boolean | null;
        addWorkspaceRoles?: boolean | null;
        updateWorkspaceRoles?: boolean | null;
        removeWorkspaceRoles?: boolean | null;
        addInvitations?: boolean | null;
        updateInvitations?: boolean | null;
        removeInvitations?: boolean | null;
        remove?: boolean | null;
      } | null;
      group?: {
        __typename?: 'Group';
        id?: number | null;
        name?: string | null;
        users?: Array<{
          __typename?: 'User';
          id?: number | null;
          name?: string | null;
          email?: string | null;
        } | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdateWorkspaceMutationVariables = Exact<{
  input?: InputMaybe<UpdateWorkspaceInputType>;
}>;

export type UpdateWorkspaceMutation = {
  __typename?: 'Mutation';
  updateWorkspace: {
    __typename: 'Workspace';
    id: string;
    name?: string | null;
    projects?: Array<
      | {
          __typename: 'PortfolioProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
          };
        }
      | {
          __typename: 'PrivateEquityFundProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
          };
        }
    > | null;
  };
};

export type DeleteWorkspaceMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  deleteWorkspace: string;
};

export type ApplyWorkspaceSnapshotMutationVariables = Exact<{
  input?: InputMaybe<WorkspaceSnapshotInputType>;
}>;

export type ApplyWorkspaceSnapshotMutation = {
  __typename?: 'Mutation';
  applyWorkspaceSnapshot: {
    __typename: 'Workspace';
    id: string;
    name?: string | null;
    projects?: Array<
      | {
          __typename: 'PortfolioProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
          };
        }
      | {
          __typename: 'PrivateEquityFundProject';
          id: string;
          project: {
            __typename: 'ProjectObj';
            id: string;
            name?: string | null;
            type?: string | null;
          };
        }
    > | null;
  };
};

export const AssetCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetCategoryFragment, unknown>;
export const EventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventFragment, unknown>;
export const DashboardMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardMediaFragment, unknown>;
export const DashboardElementFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardElementFragment, unknown>;
export const HoldingDashboardConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HoldingDashboardConfigFragment, unknown>;
export const HoldingConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdingDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HoldingConfigFragment, unknown>;
export const FavoriteHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FavoriteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteHoldingFragment, unknown>;
export const AllocationWithPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationWithPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllocationWithPortfolioFragment, unknown>;
export const PortfolioDashboardConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioDashboardConfigFragment, unknown>;
export const PortfolioConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioConfigFragment, unknown>;
export const SchedulerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SchedulerFragment, unknown>;
export const ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportFragment, unknown>;
export const GeneratedReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GeneratedReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeneratedReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeneratedReportFragment, unknown>;
export const ProjectReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectReportFragment, unknown>;
export const HoldingObjFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HoldingObjFragment, unknown>;
export const StockPositionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockPositionFragment, unknown>;
export const StockTradeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockTradeFragment, unknown>;
export const GoalRuleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoalRuleFragment, unknown>;
export const StockGoalRuleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockGoalRuleFragment, unknown>;
export const StockHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockHoldingFragment, unknown>;
export const BondPositionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BondPositionFragment, unknown>;
export const BondTradeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BondTradeFragment, unknown>;
export const BondHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BondHoldingFragment, unknown>;
export const LoanHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanHoldingFragment, unknown>;
export const WarrantHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarrantHoldingFragment, unknown>;
export const FundPositionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundPositionFragment, unknown>;
export const FundTradeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundTradeFragment, unknown>;
export const FundHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundHoldingFragment, unknown>;
export const BankNoteHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankNoteHoldingFragment, unknown>;
export const CryptoHoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CryptoHoldingFragment, unknown>;
export const ValuationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValuationFragment, unknown>;
export const CommitmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommitmentFragment, unknown>;
export const TransferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransferFragment, unknown>;
export const HoldingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HoldingFragment, unknown>;
export const TransactionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionFragment, unknown>;
export const ProjectObjFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectObjFragment, unknown>;
export const AllocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllocationFragment, unknown>;
export const PortfolioBaseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioBaseFragment, unknown>;
export const PortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioFragment, unknown>;
export const PortfolioProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioProjectFragment, unknown>;
export const PrivateEquityFundSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundSetupFragment, unknown>;
export const PrivateEquityFundCapitalCommitmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PrivateEquityFundCapitalCommitmentFragment,
  unknown
>;
export const PrivateEquityFundCapitalCallFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundCapitalCallFragment, unknown>;
export const PrivateEquityFundCapitalCallTransferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PrivateEquityFundCapitalCallTransferFragment,
  unknown
>;
export const PrivateEquityFundCapitalDistributionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PrivateEquityFundCapitalDistributionFragment,
  unknown
>;
export const PrivateEquityFundCapitalDistributionTransferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PrivateEquityFundCapitalDistributionTransferFragment,
  unknown
>;
export const PrivateEquityFundContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundContactFragment, unknown>;
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>;
export const PrivateEquityFundInvestorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundInvestorFragment, unknown>;
export const PrivateEquityFundInvestmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundInvestmentFragment, unknown>;
export const PrivateEquityFundTeamMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundTeamMemberFragment, unknown>;
export const AssetObjFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetObjFragment, unknown>;
export const PrivateEquityFundFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundFragment, unknown>;
export const PrivateEquityFundProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundProjectFragment, unknown>;
export const ProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectFragment, unknown>;
export const StockFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockFragment, unknown>;
export const BondFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BondFragment, unknown>;
export const LoanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanFragment, unknown>;
export const WarrantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarrantFragment, unknown>;
export const FundFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FundFragment, unknown>;
export const BankNoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BankNoteFragment, unknown>;
export const CryptoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CryptoFragment, unknown>;
export const PrivateEquityFundShallowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundShallowFragment, unknown>;
export const AssetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Asset' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Stock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Bond' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Loan' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Warrant' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Fund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNote' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Crypto' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetFragment, unknown>;
export const RegionAllocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegionAllocationFragment, unknown>;
export const FileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileFragment, unknown>;
export const EntityMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityMediaFragment, unknown>;
export const EntityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityFragment, unknown>;
export const RoleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoleFragment, unknown>;
export const PhoneFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhoneFragment, unknown>;
export const EmailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailFragment, unknown>;
export const AddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressFragment, unknown>;
export const PersonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PersonFragment, unknown>;
export const WorkspaceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Workspace' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withAssets' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withEntities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Entity' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'people' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withPeople' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Person' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Asset' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Stock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Bond' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Loan' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Warrant' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Fund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNote' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Crypto' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceFragment, unknown>;
export const ProjectObjShallowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectObjShallowFragment, unknown>;
export const PortfolioProjectShallowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfolioProjectShallowFragment, unknown>;
export const PrivateEquityFundProjectShallowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateEquityFundProjectShallowFragment, unknown>;
export const ProjectShallowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundProjectShallow',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectShallowFragment, unknown>;
export const WorkspaceShallowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundProjectShallow',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceShallowFragment, unknown>;
export const WorkspacePermissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspacePermissionFragment, unknown>;
export const GroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupFragment, unknown>;
export const WorkspaceRoleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceRoleFragment, unknown>;
export const WorkspaceBaseWithRolesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceBaseWithRoles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceBaseWithRoles' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceBaseWithRolesFragment, unknown>;
export const WorkspaceWithRolesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceWithRoles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundProjectShallow',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkspaceWithRolesFragment, unknown>;
export const GetBankNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBankNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBankNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBankNotesQuery, GetBankNotesQueryVariables>;
export const CreateBankNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBankNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBankNoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBankNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBankNotesMutation,
  CreateBankNotesMutationVariables
>;
export const UpdateBankNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBankNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBankNoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBankNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBankNotesMutation,
  UpdateBankNotesMutationVariables
>;
export const UpsertBankNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBankNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertBankNoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBankNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertBankNotesMutation,
  UpsertBankNotesMutationVariables
>;
export const DeleteBankNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBankNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBankNoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBankNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBankNotesMutation,
  DeleteBankNotesMutationVariables
>;
export const GetBondsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBonds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBonds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBondsQuery, GetBondsQueryVariables>;
export const CreateBondsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBonds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBondInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBonds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBondsMutation, CreateBondsMutationVariables>;
export const UpdateBondsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBonds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBondInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBonds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBondsMutation, UpdateBondsMutationVariables>;
export const UpsertBondsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBonds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertBondInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBonds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertBondsMutation, UpsertBondsMutationVariables>;
export const DeleteBondsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBonds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBondInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBonds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBondsMutation, DeleteBondsMutationVariables>;
export const GetCryptosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCryptos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCryptos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCryptosQuery, GetCryptosQueryVariables>;
export const CreateCryptosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCryptos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCryptoInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCryptos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCryptosMutation,
  CreateCryptosMutationVariables
>;
export const UpdateCryptosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCryptos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCryptoInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCryptos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCryptosMutation,
  UpdateCryptosMutationVariables
>;
export const UpsertCryptosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertCryptos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertCryptoInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCryptos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertCryptosMutation,
  UpsertCryptosMutationVariables
>;
export const DeleteCryptosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCryptos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCryptoInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCryptos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCryptosMutation,
  DeleteCryptosMutationVariables
>;
export const GetFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFundsQuery, GetFundsQueryVariables>;
export const CreateFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFundInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFundsMutation, CreateFundsMutationVariables>;
export const UpdateFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFundInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFundsMutation, UpdateFundsMutationVariables>;
export const UpsertFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertFundInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertFundsMutation, UpsertFundsMutationVariables>;
export const DeleteFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFundInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFundsMutation, DeleteFundsMutationVariables>;
export const GetLoansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLoans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLoans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLoansQuery, GetLoansQueryVariables>;
export const CreateLoansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLoans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateLoanInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLoans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLoansMutation, CreateLoansMutationVariables>;
export const UpdateLoansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLoans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLoanInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLoans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLoansMutation, UpdateLoansMutationVariables>;
export const UpsertLoansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertLoans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertLoanInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertLoans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertLoansMutation, UpsertLoansMutationVariables>;
export const DeleteLoansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLoans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteLoanInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLoans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLoansMutation, DeleteLoansMutationVariables>;
export const GetPrivateEquityFundCapitalCallsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundCapitalCalls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFundCapitalCalls' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundCapitalCallsQuery,
  GetPrivateEquityFundCapitalCallsQueryVariables
>;
export const CreatePrivateEquityFundCapitalCallsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFundCapitalCalls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePrivateEquityFundCapitalCallInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createPrivateEquityFundCapitalCalls',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundCapitalCallsMutation,
  CreatePrivateEquityFundCapitalCallsMutationVariables
>;
export const UpdatePrivateEquityFundCapitalCallsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFundCapitalCalls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePrivateEquityFundCapitalCallInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updatePrivateEquityFundCapitalCalls',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundCapitalCallsMutation,
  UpdatePrivateEquityFundCapitalCallsMutationVariables
>;
export const UpsertPrivateEquityFundCapitalCallsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFundCapitalCalls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPrivateEquityFundCapitalCallInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'upsertPrivateEquityFundCapitalCalls',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundCapitalCallsMutation,
  UpsertPrivateEquityFundCapitalCallsMutationVariables
>;
export const DeletePrivateEquityFundCapitalCallsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFundCapitalCalls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePrivateEquityFundCapitalCallInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'deletePrivateEquityFundCapitalCalls',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundCapitalCallsMutation,
  DeletePrivateEquityFundCapitalCallsMutationVariables
>;
export const GetPrivateEquityFundContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFundContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundContactsQuery,
  GetPrivateEquityFundContactsQueryVariables
>;
export const CreatePrivateEquityFundContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFundContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePrivateEquityFundContactInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPrivateEquityFundContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundContactsMutation,
  CreatePrivateEquityFundContactsMutationVariables
>;
export const UpdatePrivateEquityFundContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFundContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePrivateEquityFundContactInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrivateEquityFundContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundContactsMutation,
  UpdatePrivateEquityFundContactsMutationVariables
>;
export const UpsertPrivateEquityFundContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFundContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPrivateEquityFundContactInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPrivateEquityFundContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundContactsMutation,
  UpsertPrivateEquityFundContactsMutationVariables
>;
export const DeletePrivateEquityFundContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFundContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePrivateEquityFundContactInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePrivateEquityFundContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundContactsMutation,
  DeletePrivateEquityFundContactsMutationVariables
>;
export const GetPrivateEquityFundInvestmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundInvestments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFundInvestments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundInvestmentsQuery,
  GetPrivateEquityFundInvestmentsQueryVariables
>;
export const CreatePrivateEquityFundInvestmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFundInvestments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePrivateEquityFundInvestmentInputType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPrivateEquityFundInvestments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundInvestmentsMutation,
  CreatePrivateEquityFundInvestmentsMutationVariables
>;
export const UpdatePrivateEquityFundInvestmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFundInvestments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePrivateEquityFundInvestmentInputType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrivateEquityFundInvestments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundInvestmentsMutation,
  UpdatePrivateEquityFundInvestmentsMutationVariables
>;
export const UpsertPrivateEquityFundInvestmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFundInvestments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPrivateEquityFundInvestmentInputType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPrivateEquityFundInvestments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundInvestmentsMutation,
  UpsertPrivateEquityFundInvestmentsMutationVariables
>;
export const DeletePrivateEquityFundInvestmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFundInvestments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePrivateEquityFundInvestmentInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePrivateEquityFundInvestments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundInvestmentsMutation,
  DeletePrivateEquityFundInvestmentsMutationVariables
>;
export const GetPrivateEquityFundInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFundInvestors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundInvestorsQuery,
  GetPrivateEquityFundInvestorsQueryVariables
>;
export const CreatePrivateEquityFundInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFundInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePrivateEquityFundInvestorInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPrivateEquityFundInvestors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundInvestorsMutation,
  CreatePrivateEquityFundInvestorsMutationVariables
>;
export const UpdatePrivateEquityFundInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFundInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePrivateEquityFundInvestorInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrivateEquityFundInvestors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundInvestorsMutation,
  UpdatePrivateEquityFundInvestorsMutationVariables
>;
export const UpsertPrivateEquityFundInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFundInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPrivateEquityFundInvestorInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPrivateEquityFundInvestors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundInvestorsMutation,
  UpsertPrivateEquityFundInvestorsMutationVariables
>;
export const DeletePrivateEquityFundInvestorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFundInvestors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePrivateEquityFundInvestorInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePrivateEquityFundInvestors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundInvestorsMutation,
  DeletePrivateEquityFundInvestorsMutationVariables
>;
export const GetPrivateEquityFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundsQuery,
  GetPrivateEquityFundsQueryVariables
>;
export const CreatePrivateEquityFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePrivateEquityFundInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPrivateEquityFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundsMutation,
  CreatePrivateEquityFundsMutationVariables
>;
export const UpdatePrivateEquityFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePrivateEquityFundInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrivateEquityFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundsMutation,
  UpdatePrivateEquityFundsMutationVariables
>;
export const UpsertPrivateEquityFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPrivateEquityFundInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPrivateEquityFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundsMutation,
  UpsertPrivateEquityFundsMutationVariables
>;
export const DeletePrivateEquityFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePrivateEquityFundInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePrivateEquityFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundsMutation,
  DeletePrivateEquityFundsMutationVariables
>;
export const GetPrivateEquityFundTeamMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundTeamMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFundTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundTeamMembersQuery,
  GetPrivateEquityFundTeamMembersQueryVariables
>;
export const CreatePrivateEquityFundTeamMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFundTeamMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePrivateEquityFundTeamMemberInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPrivateEquityFundTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundTeamMembersMutation,
  CreatePrivateEquityFundTeamMembersMutationVariables
>;
export const UpdatePrivateEquityFundTeamMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFundTeamMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePrivateEquityFundTeamMemberInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrivateEquityFundTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundTeamMembersMutation,
  UpdatePrivateEquityFundTeamMembersMutationVariables
>;
export const UpsertPrivateEquityFundTeamMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFundTeamMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPrivateEquityFundTeamMemberInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPrivateEquityFundTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundTeamMembersMutation,
  UpsertPrivateEquityFundTeamMembersMutationVariables
>;
export const DeletePrivateEquityFundTeamMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFundTeamMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePrivateEquityFundTeamMemberInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePrivateEquityFundTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundTeamMembersMutation,
  DeletePrivateEquityFundTeamMembersMutationVariables
>;
export const GetStocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStocksQuery, GetStocksQueryVariables>;
export const CreateStocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateStockInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStocksMutation,
  CreateStocksMutationVariables
>;
export const UpdateStocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateStockInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStocksMutation,
  UpdateStocksMutationVariables
>;
export const UpsertStocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertStocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertStockInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertStocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertStocksMutation,
  UpsertStocksMutationVariables
>;
export const DeleteStocksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStocks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteStockInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteStocksMutation,
  DeleteStocksMutationVariables
>;
export const GetWarrantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWarrants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWarrants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWarrantsQuery, GetWarrantsQueryVariables>;
export const CreateWarrantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWarrants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateWarrantInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWarrants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWarrantsMutation,
  CreateWarrantsMutationVariables
>;
export const UpdateWarrantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWarrants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateWarrantInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarrants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarrantsMutation,
  UpdateWarrantsMutationVariables
>;
export const UpsertWarrantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertWarrants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertWarrantInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertWarrants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertWarrantsMutation,
  UpsertWarrantsMutationVariables
>;
export const DeleteWarrantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWarrants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteWarrantInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWarrants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWarrantsMutation,
  DeleteWarrantsMutationVariables
>;
export const GetAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Asset' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Stock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Bond' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Loan' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Warrant' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Fund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNote' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Crypto' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetsQuery, GetAssetsQueryVariables>;
export const GetAssetObjsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssetObjs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAssetObjs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssetObjsQuery, GetAssetObjsQueryVariables>;
export const CreateAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAssetInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAssetsMutation,
  CreateAssetsMutationVariables
>;
export const UpdateAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAssetInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAssetsMutation,
  UpdateAssetsMutationVariables
>;
export const UpsertAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertAssetInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertAssetsMutation,
  UpsertAssetsMutationVariables
>;
export const DeleteAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteAssetInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAssetsMutation,
  DeleteAssetsMutationVariables
>;
export const GetAssetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAssetCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetCategory' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAssetCategoriesQuery,
  GetAssetCategoriesQueryVariables
>;
export const CreateAssetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAssetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAssetCategoryInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAssetCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetCategory' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAssetCategoriesMutation,
  CreateAssetCategoriesMutationVariables
>;
export const UpdateAssetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAssetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAssetCategoryInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAssetCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetCategory' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAssetCategoriesMutation,
  UpdateAssetCategoriesMutationVariables
>;
export const UpsertAssetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertAssetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertAssetCategoryInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAssetCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetCategory' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertAssetCategoriesMutation,
  UpsertAssetCategoriesMutationVariables
>;
export const DeleteAssetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAssetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteAssetCategoryInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAssetCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAssetCategoriesMutation,
  DeleteAssetCategoriesMutationVariables
>;
export const GetDashboardElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDashboardElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDashboardElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDashboardElementsQuery,
  GetDashboardElementsQueryVariables
>;
export const CreateDashboardElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDashboardElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDashboardElementInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDashboardElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDashboardElementsMutation,
  CreateDashboardElementsMutationVariables
>;
export const UpdateDashboardElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDashboardElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDashboardElementInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDashboardElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDashboardElementsMutation,
  UpdateDashboardElementsMutationVariables
>;
export const UpsertDashboardElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertDashboardElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertDashboardElementInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDashboardElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertDashboardElementsMutation,
  UpsertDashboardElementsMutationVariables
>;
export const DeleteDashboardElementsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDashboardElements' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDashboardElements' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDashboardElementsMutation,
  DeleteDashboardElementsMutationVariables
>;
export const GetDashboardMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDashboardMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDashboardMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDashboardMediasQuery,
  GetDashboardMediasQueryVariables
>;
export const CreateDashboardMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDashboardMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDashboardMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDashboardMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDashboardMediasMutation,
  CreateDashboardMediasMutationVariables
>;
export const UpdateDashboardMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDashboardMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDashboardMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDashboardMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDashboardMediasMutation,
  UpdateDashboardMediasMutationVariables
>;
export const UpsertDashboardMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertDashboardMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertDashboardMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDashboardMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertDashboardMediasMutation,
  UpsertDashboardMediasMutationVariables
>;
export const DeleteDashboardMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDashboardMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDashboardMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDashboardMediasMutation,
  DeleteDashboardMediasMutationVariables
>;
export const GetSchedulersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSchedulers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSchedulers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSchedulersQuery, GetSchedulersQueryVariables>;
export const CreateSchedulersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSchedulers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSchedulerInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSchedulers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSchedulersMutation,
  CreateSchedulersMutationVariables
>;
export const UpdateSchedulersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSchedulers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSchedulerInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSchedulers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSchedulersMutation,
  UpdateSchedulersMutationVariables
>;
export const UpsertSchedulersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertSchedulers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertSchedulerInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertSchedulers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertSchedulersMutation,
  UpsertSchedulersMutationVariables
>;
export const DeleteSchedulersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSchedulers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSchedulerInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSchedulers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSchedulersMutation,
  DeleteSchedulersMutationVariables
>;
export const GetEntitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Entity' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntitiesQuery, GetEntitiesQueryVariables>;
export const CreateEntitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEntities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateEntityInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Entity' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEntitiesMutation,
  CreateEntitiesMutationVariables
>;
export const UpdateEntitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEntities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEntityInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Entity' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEntitiesMutation,
  UpdateEntitiesMutationVariables
>;
export const UpsertEntitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertEntities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertEntityInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Entity' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertEntitiesMutation,
  UpsertEntitiesMutationVariables
>;
export const DeleteEntitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEntities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteEntityInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEntitiesMutation,
  DeleteEntitiesMutationVariables
>;
export const GetEntityMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEntityMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEntityMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEntityMediasQuery,
  GetEntityMediasQueryVariables
>;
export const CreateEntityMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEntityMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateEntityMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEntityMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEntityMediasMutation,
  CreateEntityMediasMutationVariables
>;
export const UpdateEntityMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEntityMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEntityMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEntityMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEntityMediasMutation,
  UpdateEntityMediasMutationVariables
>;
export const UpsertEntityMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertEntityMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertEntityMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertEntityMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertEntityMediasMutation,
  UpsertEntityMediasMutationVariables
>;
export const DeleteEntityMediasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEntityMedias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteEntityMediaInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntityMedias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEntityMediasMutation,
  DeleteEntityMediasMutationVariables
>;
export const GetRegionAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRegionAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRegionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRegionAllocationsQuery,
  GetRegionAllocationsQueryVariables
>;
export const CreateRegionAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createRegionAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateRegionAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRegionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRegionAllocationsMutation,
  CreateRegionAllocationsMutationVariables
>;
export const UpdateRegionAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRegionAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRegionAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRegionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRegionAllocationsMutation,
  UpdateRegionAllocationsMutationVariables
>;
export const UpsertRegionAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertRegionAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertRegionAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRegionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertRegionAllocationsMutation,
  UpsertRegionAllocationsMutationVariables
>;
export const DeleteRegionAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRegionAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteRegionAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRegionAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRegionAllocationsMutation,
  DeleteRegionAllocationsMutationVariables
>;
export const GetEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventsQuery, GetEventsQueryVariables>;
export const GetEventsByProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEventsByProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEventsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEventsByProjectQuery,
  GetEventsByProjectQueryVariables
>;
export const CreateEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateEventInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEventsMutation,
  CreateEventsMutationVariables
>;
export const UpdateEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEventInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEventsMutation,
  UpdateEventsMutationVariables
>;
export const UpsertEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertEventInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Event' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Event' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Event' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertEventsMutation,
  UpsertEventsMutationVariables
>;
export const DeleteEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteEventInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEventsMutation,
  DeleteEventsMutationVariables
>;
export const GetFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFilesQuery, GetFilesQueryVariables>;
export const CreateFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFileInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFilesMutation, CreateFilesMutationVariables>;
export const UpdateFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFileInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFilesMutation, UpdateFilesMutationVariables>;
export const UpsertFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertFileInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertFilesMutation, UpsertFilesMutationVariables>;
export const DeleteFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFileInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFilesMutation, DeleteFilesMutationVariables>;
export const HelloDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Hello' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hello' } }],
      },
    },
  ],
} as unknown as DocumentNode<HelloQuery, HelloQueryVariables>;
export const GetCommitmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCommitments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCommitments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCommitmentsQuery, GetCommitmentsQueryVariables>;
export const CreateCommitmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCommitments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCommitmentInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCommitments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCommitmentsMutation,
  CreateCommitmentsMutationVariables
>;
export const UpdateCommitmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCommitments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCommitmentInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCommitments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCommitmentsMutation,
  UpdateCommitmentsMutationVariables
>;
export const UpsertCommitmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertCommitments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertCommitmentInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCommitments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertCommitmentsMutation,
  UpsertCommitmentsMutationVariables
>;
export const DeleteCommitmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCommitments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCommitmentInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCommitments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCommitmentsMutation,
  DeleteCommitmentsMutationVariables
>;
export const GetHoldingConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHoldingConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'holdingId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getHoldingConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'holdingId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'holdingId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdingDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHoldingConfigsQuery,
  GetHoldingConfigsQueryVariables
>;
export const CreateHoldingConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createHoldingConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'holdingId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateHoldingConfigInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHoldingConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'holdingId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'holdingId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdingDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHoldingConfigsMutation,
  CreateHoldingConfigsMutationVariables
>;
export const UpdateHoldingConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateHoldingConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'holdingId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateHoldingConfigInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHoldingConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'holdingId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'holdingId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdingDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHoldingConfigsMutation,
  UpdateHoldingConfigsMutationVariables
>;
export const UpsertHoldingConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertHoldingConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'holdingId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertHoldingConfigInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertHoldingConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'holdingId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'holdingId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdingDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertHoldingConfigsMutation,
  UpsertHoldingConfigsMutationVariables
>;
export const DeleteHoldingConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteHoldingConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'holdingId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHoldingConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'holdingId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'holdingId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteHoldingConfigsMutation,
  DeleteHoldingConfigsMutationVariables
>;
export const CreateHoldingDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createHoldingDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateHoldingDashboardConfigInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHoldingDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHoldingDashboardConfigsMutation,
  CreateHoldingDashboardConfigsMutationVariables
>;
export const UpdateHoldingDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateHoldingDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateHoldingDashboardConfigInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHoldingDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHoldingDashboardConfigsMutation,
  UpdateHoldingDashboardConfigsMutationVariables
>;
export const UpsertHoldingDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertHoldingDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertHoldingDashboardConfigInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertHoldingDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertHoldingDashboardConfigsMutation,
  UpsertHoldingDashboardConfigsMutationVariables
>;
export const DeleteHoldingDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteHoldingDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHoldingDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteHoldingDashboardConfigsMutation,
  DeleteHoldingDashboardConfigsMutationVariables
>;
export const GetFavoriteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFavoriteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFavoriteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FavoriteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FavoriteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFavoriteHoldingsQuery,
  GetFavoriteHoldingsQueryVariables
>;
export const CreateFavoriteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFavoriteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFavoriteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFavoriteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FavoriteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FavoriteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFavoriteHoldingsMutation,
  CreateFavoriteHoldingsMutationVariables
>;
export const UpdateFavoriteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFavoriteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFavoriteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFavoriteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FavoriteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FavoriteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFavoriteHoldingsMutation,
  UpdateFavoriteHoldingsMutationVariables
>;
export const UpsertFavoriteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertFavoriteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertFavoriteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertFavoriteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FavoriteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FavoriteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FavoriteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertFavoriteHoldingsMutation,
  UpsertFavoriteHoldingsMutationVariables
>;
export const DeleteFavoriteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFavoriteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFavoriteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFavoriteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFavoriteHoldingsMutation,
  DeleteFavoriteHoldingsMutationVariables
>;
export const GetBankNoteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBankNoteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBankNoteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBankNoteHoldingsQuery,
  GetBankNoteHoldingsQueryVariables
>;
export const CreateBankNoteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBankNoteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBankNoteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBankNoteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBankNoteHoldingsMutation,
  CreateBankNoteHoldingsMutationVariables
>;
export const UpdateBankNoteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBankNoteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBankNoteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBankNoteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBankNoteHoldingsMutation,
  UpdateBankNoteHoldingsMutationVariables
>;
export const UpsertBankNoteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBankNoteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertBankNoteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBankNoteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertBankNoteHoldingsMutation,
  UpsertBankNoteHoldingsMutationVariables
>;
export const DeleteBankNoteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBankNoteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBankNoteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBankNoteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBankNoteHoldingsMutation,
  DeleteBankNoteHoldingsMutationVariables
>;
export const GetBondHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBondHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBondHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBondHoldingsQuery,
  GetBondHoldingsQueryVariables
>;
export const CreateBondHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBondHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBondHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBondHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBondHoldingsMutation,
  CreateBondHoldingsMutationVariables
>;
export const UpdateBondHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBondHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBondHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBondHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBondHoldingsMutation,
  UpdateBondHoldingsMutationVariables
>;
export const UpsertBondHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBondHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertBondHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBondHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertBondHoldingsMutation,
  UpsertBondHoldingsMutationVariables
>;
export const DeleteBondHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBondHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBondHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBondHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBondHoldingsMutation,
  DeleteBondHoldingsMutationVariables
>;
export const GetBondPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBondPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBondPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBondPositionsQuery,
  GetBondPositionsQueryVariables
>;
export const CreateBondPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBondPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBondPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBondPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBondPositionsMutation,
  CreateBondPositionsMutationVariables
>;
export const UpdateBondPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBondPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBondPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBondPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBondPositionsMutation,
  UpdateBondPositionsMutationVariables
>;
export const UpsertBondPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBondPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertBondPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBondPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertBondPositionsMutation,
  UpsertBondPositionsMutationVariables
>;
export const DeleteBondPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBondPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBondPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBondPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBondPositionsMutation,
  DeleteBondPositionsMutationVariables
>;
export const GetBondTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBondTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBondTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBondTradesQuery, GetBondTradesQueryVariables>;
export const CreateBondTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBondTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBondTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBondTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBondTradesMutation,
  CreateBondTradesMutationVariables
>;
export const UpdateBondTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBondTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBondTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBondTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBondTradesMutation,
  UpdateBondTradesMutationVariables
>;
export const UpsertBondTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertBondTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertBondTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertBondTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertBondTradesMutation,
  UpsertBondTradesMutationVariables
>;
export const DeleteBondTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBondTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBondTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBondTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBondTradesMutation,
  DeleteBondTradesMutationVariables
>;
export const GetCryptoHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCryptoHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCryptoHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCryptoHoldingsQuery,
  GetCryptoHoldingsQueryVariables
>;
export const CreateCryptoHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCryptoHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCryptoHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCryptoHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCryptoHoldingsMutation,
  CreateCryptoHoldingsMutationVariables
>;
export const UpdateCryptoHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCryptoHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCryptoHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCryptoHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCryptoHoldingsMutation,
  UpdateCryptoHoldingsMutationVariables
>;
export const UpsertCryptoHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertCryptoHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertCryptoHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCryptoHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertCryptoHoldingsMutation,
  UpsertCryptoHoldingsMutationVariables
>;
export const DeleteCryptoHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCryptoHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCryptoHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCryptoHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCryptoHoldingsMutation,
  DeleteCryptoHoldingsMutationVariables
>;
export const GetFundHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFundHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFundHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFundHoldingsQuery,
  GetFundHoldingsQueryVariables
>;
export const CreateFundHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFundHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFundHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFundHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFundHoldingsMutation,
  CreateFundHoldingsMutationVariables
>;
export const UpdateFundHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFundHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFundHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFundHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFundHoldingsMutation,
  UpdateFundHoldingsMutationVariables
>;
export const UpsertFundHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertFundHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertFundHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertFundHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertFundHoldingsMutation,
  UpsertFundHoldingsMutationVariables
>;
export const DeleteFundHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFundHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFundHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFundHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFundHoldingsMutation,
  DeleteFundHoldingsMutationVariables
>;
export const GetFundPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFundPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFundPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFundPositionsQuery,
  GetFundPositionsQueryVariables
>;
export const CreateFundPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFundPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFundPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFundPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFundPositionsMutation,
  CreateFundPositionsMutationVariables
>;
export const UpdateFundPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFundPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFundPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFundPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFundPositionsMutation,
  UpdateFundPositionsMutationVariables
>;
export const UpsertFundPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertFundPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertFundPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertFundPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertFundPositionsMutation,
  UpsertFundPositionsMutationVariables
>;
export const DeleteFundPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFundPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFundPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFundPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFundPositionsMutation,
  DeleteFundPositionsMutationVariables
>;
export const GetFundTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFundTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFundTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFundTradesQuery, GetFundTradesQueryVariables>;
export const CreateFundTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFundTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFundTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFundTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFundTradesMutation,
  CreateFundTradesMutationVariables
>;
export const UpdateFundTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFundTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFundTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFundTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFundTradesMutation,
  UpdateFundTradesMutationVariables
>;
export const UpsertFundTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertFundTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertFundTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertFundTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertFundTradesMutation,
  UpsertFundTradesMutationVariables
>;
export const DeleteFundTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFundTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteFundTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFundTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFundTradesMutation,
  DeleteFundTradesMutationVariables
>;
export const GetLoanHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLoanHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLoanHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLoanHoldingsQuery,
  GetLoanHoldingsQueryVariables
>;
export const CreateLoanHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createLoanHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateLoanHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLoanHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateLoanHoldingsMutation,
  CreateLoanHoldingsMutationVariables
>;
export const UpdateLoanHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLoanHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLoanHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLoanHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateLoanHoldingsMutation,
  UpdateLoanHoldingsMutationVariables
>;
export const UpsertLoanHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertLoanHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertLoanHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertLoanHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertLoanHoldingsMutation,
  UpsertLoanHoldingsMutationVariables
>;
export const DeleteLoanHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteLoanHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteLoanHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLoanHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLoanHoldingsMutation,
  DeleteLoanHoldingsMutationVariables
>;
export const GetStockGoalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStockGoalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStockGoalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStockGoalRulesQuery,
  GetStockGoalRulesQueryVariables
>;
export const CreateStockGoalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStockGoalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateStockGoalRuleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStockGoalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStockGoalRulesMutation,
  CreateStockGoalRulesMutationVariables
>;
export const UpdateStockGoalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStockGoalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateStockGoalRuleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStockGoalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStockGoalRulesMutation,
  UpdateStockGoalRulesMutationVariables
>;
export const UpsertStockGoalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertStockGoalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertStockGoalRuleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertStockGoalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertStockGoalRulesMutation,
  UpsertStockGoalRulesMutationVariables
>;
export const DeleteStockGoalRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStockGoalRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteStockGoalRuleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStockGoalRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteStockGoalRulesMutation,
  DeleteStockGoalRulesMutationVariables
>;
export const GetStockHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStockHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStockHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStockHoldingsQuery,
  GetStockHoldingsQueryVariables
>;
export const CreateStockHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStockHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateStockHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStockHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStockHoldingsMutation,
  CreateStockHoldingsMutationVariables
>;
export const UpdateStockHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStockHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateStockHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStockHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStockHoldingsMutation,
  UpdateStockHoldingsMutationVariables
>;
export const UpsertStockHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertStockHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertStockHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertStockHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertStockHoldingsMutation,
  UpsertStockHoldingsMutationVariables
>;
export const DeleteStockHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStockHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteStockHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStockHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteStockHoldingsMutation,
  DeleteStockHoldingsMutationVariables
>;
export const GetStockPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStockPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStockPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetStockPositionsQuery,
  GetStockPositionsQueryVariables
>;
export const CreateStockPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStockPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateStockPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStockPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStockPositionsMutation,
  CreateStockPositionsMutationVariables
>;
export const UpdateStockPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStockPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateStockPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStockPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStockPositionsMutation,
  UpdateStockPositionsMutationVariables
>;
export const UpsertStockPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertStockPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertStockPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertStockPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertStockPositionsMutation,
  UpsertStockPositionsMutationVariables
>;
export const DeleteStockPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStockPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteStockPositionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStockPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteStockPositionsMutation,
  DeleteStockPositionsMutationVariables
>;
export const GetStockTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStockTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStockTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStockTradesQuery, GetStockTradesQueryVariables>;
export const CreateStockTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStockTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateStockTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStockTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStockTradesMutation,
  CreateStockTradesMutationVariables
>;
export const UpdateStockTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStockTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateStockTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStockTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStockTradesMutation,
  UpdateStockTradesMutationVariables
>;
export const UpsertStockTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertStockTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertStockTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertStockTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertStockTradesMutation,
  UpsertStockTradesMutationVariables
>;
export const DeleteStockTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStockTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteStockTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStockTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteStockTradesMutation,
  DeleteStockTradesMutationVariables
>;
export const GetWarrantHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWarrantHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWarrantHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWarrantHoldingsQuery,
  GetWarrantHoldingsQueryVariables
>;
export const CreateWarrantHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWarrantHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateWarrantHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWarrantHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWarrantHoldingsMutation,
  CreateWarrantHoldingsMutationVariables
>;
export const UpdateWarrantHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWarrantHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateWarrantHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarrantHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarrantHoldingsMutation,
  UpdateWarrantHoldingsMutationVariables
>;
export const UpsertWarrantHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertWarrantHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertWarrantHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertWarrantHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertWarrantHoldingsMutation,
  UpsertWarrantHoldingsMutationVariables
>;
export const DeleteWarrantHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWarrantHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteWarrantHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWarrantHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWarrantHoldingsMutation,
  DeleteWarrantHoldingsMutationVariables
>;
export const GetHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHoldingsQuery, GetHoldingsQueryVariables>;
export const GetHoldingObjsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHoldingObjs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getHoldingObjs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHoldingObjsQuery, GetHoldingObjsQueryVariables>;
export const CreateHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHoldingsMutation,
  CreateHoldingsMutationVariables
>;
export const UpdateHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHoldingsMutation,
  UpdateHoldingsMutationVariables
>;
export const UpsertHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertHoldingsMutation,
  UpsertHoldingsMutationVariables
>;
export const DeleteHoldingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteHoldings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteHoldingInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHoldings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteHoldingsMutation,
  DeleteHoldingsMutationVariables
>;
export const GetTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransactionsQuery,
  GetTransactionsQueryVariables
>;
export const CreateTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTransactionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransactionsMutation,
  CreateTransactionsMutationVariables
>;
export const UpdateTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransactionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransactionsMutation,
  UpdateTransactionsMutationVariables
>;
export const UpsertTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertTransactionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertTransactionsMutation,
  UpsertTransactionsMutationVariables
>;
export const DeleteTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteTransactionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTransactionsMutation,
  DeleteTransactionsMutationVariables
>;
export const GetTransfersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTransfers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransfers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransfersQuery, GetTransfersQueryVariables>;
export const CreateTransfersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTransfers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTransferInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransfers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTransfersMutation,
  CreateTransfersMutationVariables
>;
export const UpdateTransfersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTransfers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTransferInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTransfers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTransfersMutation,
  UpdateTransfersMutationVariables
>;
export const UpsertTransfersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertTransfers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertTransferInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertTransfers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertTransfersMutation,
  UpsertTransfersMutationVariables
>;
export const DeleteTransfersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTransfers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteTransferInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTransfers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTransfersMutation,
  DeleteTransfersMutationVariables
>;
export const GetValuationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getValuations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getValuations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetValuationsQuery, GetValuationsQueryVariables>;
export const CreateValuationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createValuations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateValuationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createValuations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateValuationsMutation,
  CreateValuationsMutationVariables
>;
export const UpdateValuationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateValuations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateValuationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateValuations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateValuationsMutation,
  UpdateValuationsMutationVariables
>;
export const UpsertValuationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertValuations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertValuationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertValuations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertValuationsMutation,
  UpsertValuationsMutationVariables
>;
export const DeleteValuationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteValuations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteValuationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteValuations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteValuationsMutation,
  DeleteValuationsMutationVariables
>;
export const GetAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAddressesQuery, GetAddressesQueryVariables>;
export const CreateAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAddressInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAddressesMutation,
  CreateAddressesMutationVariables
>;
export const UpdateAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAddressInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAddressesMutation,
  UpdateAddressesMutationVariables
>;
export const UpsertAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertAddressInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertAddressesMutation,
  UpsertAddressesMutationVariables
>;
export const DeleteAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteAddressInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAddressesMutation,
  DeleteAddressesMutationVariables
>;
export const GetEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmailsQuery, GetEmailsQueryVariables>;
export const CreateEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateEmailInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEmailsMutation,
  CreateEmailsMutationVariables
>;
export const UpdateEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEmailInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEmailsMutation,
  UpdateEmailsMutationVariables
>;
export const UpsertEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertEmailInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertEmailsMutation,
  UpsertEmailsMutationVariables
>;
export const DeleteEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteEmailInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEmailsMutation,
  DeleteEmailsMutationVariables
>;
export const GetPeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPeople' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Person' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPeopleQuery, GetPeopleQueryVariables>;
export const CreatePeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePersonInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPeople' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Person' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePeopleMutation,
  CreatePeopleMutationVariables
>;
export const UpdatePeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePersonInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePeople' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Person' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePeopleMutation,
  UpdatePeopleMutationVariables
>;
export const UpsertPeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPersonInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPeople' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Person' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPeopleMutation,
  UpsertPeopleMutationVariables
>;
export const DeletePeopleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePeople' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePersonInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePeople' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePeopleMutation,
  DeletePeopleMutationVariables
>;
export const GetPhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhonesQuery, GetPhonesQueryVariables>;
export const CreatePhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePhoneInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePhonesMutation,
  CreatePhonesMutationVariables
>;
export const UpdatePhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePhoneInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePhonesMutation,
  UpdatePhonesMutationVariables
>;
export const UpsertPhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPhoneInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPhonesMutation,
  UpsertPhonesMutationVariables
>;
export const DeletePhonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePhones' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePhoneInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePhones' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePhonesMutation,
  DeletePhonesMutationVariables
>;
export const GetRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRolesQuery, GetRolesQueryVariables>;
export const CreateRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRolesMutation, CreateRolesMutationVariables>;
export const UpdateRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRolesMutation, UpdateRolesMutationVariables>;
export const UpsertRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertRolesMutation, UpsertRolesMutationVariables>;
export const DeleteRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRolesMutation, DeleteRolesMutationVariables>;
export const GetPortfolioProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolioProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPortfolioProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioProjectsQuery,
  GetPortfolioProjectsQueryVariables
>;
export const CreatePortfolioProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPortfolioProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePortfolioProjectInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPortfolioProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortfolioProjectsMutation,
  CreatePortfolioProjectsMutationVariables
>;
export const UpdatePortfolioProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePortfolioProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePortfolioProjectInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePortfolioProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePortfolioProjectsMutation,
  UpdatePortfolioProjectsMutationVariables
>;
export const UpsertPortfolioProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPortfolioProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPortfolioProjectInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPortfolioProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPortfolioProjectsMutation,
  UpsertPortfolioProjectsMutationVariables
>;
export const DeletePortfolioProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePortfolioProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePortfolioProjectInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePortfolioProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePortfolioProjectsMutation,
  DeletePortfolioProjectsMutationVariables
>;
export const GetPortfolioProjectByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolioProjectByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPortfolioProjectByProjectId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioProjectByProjectIdQuery,
  GetPortfolioProjectByProjectIdQueryVariables
>;
export const GetAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AllocationWithPortfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationWithPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllocationsQuery, GetAllocationsQueryVariables>;
export const CreateAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AllocationWithPortfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationWithPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAllocationsMutation,
  CreateAllocationsMutationVariables
>;
export const UpdateAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AllocationWithPortfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationWithPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAllocationsMutation,
  UpdateAllocationsMutationVariables
>;
export const UpsertAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AllocationWithPortfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AllocationWithPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertAllocationsMutation,
  UpsertAllocationsMutationVariables
>;
export const DeleteAllocationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAllocations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteAllocationInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAllocations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAllocationsMutation,
  DeleteAllocationsMutationVariables
>;
export const GetPortfolioConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolioConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPortfolioConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolioId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioConfigsQuery,
  GetPortfolioConfigsQueryVariables
>;
export const CreatePortfolioConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPortfolioConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePortfolioConfigInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPortfolioConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolioId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortfolioConfigsMutation,
  CreatePortfolioConfigsMutationVariables
>;
export const UpdatePortfolioConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePortfolioConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePortfolioConfigInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePortfolioConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolioId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePortfolioConfigsMutation,
  UpdatePortfolioConfigsMutationVariables
>;
export const UpsertPortfolioConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPortfolioConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPortfolioConfigInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPortfolioConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolioId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDashboardConfigId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolioDashboardConfigs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPortfolioConfigsMutation,
  UpsertPortfolioConfigsMutationVariables
>;
export const DeletePortfolioConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePortfolioConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DeletePortfolioConfigInputType' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePortfolioConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolioId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePortfolioConfigsMutation,
  DeletePortfolioConfigsMutationVariables
>;
export const CreatePortfolioDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPortfolioDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePortfolioDashboardConfigInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPortfolioDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortfolioDashboardConfigsMutation,
  CreatePortfolioDashboardConfigsMutationVariables
>;
export const UpdatePortfolioDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePortfolioDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePortfolioDashboardConfigInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePortfolioDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePortfolioDashboardConfigsMutation,
  UpdatePortfolioDashboardConfigsMutationVariables
>;
export const UpsertPortfolioDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPortfolioDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPortfolioDashboardConfigInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPortfolioDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPortfolioDashboardConfigsMutation,
  UpsertPortfolioDashboardConfigsMutationVariables
>;
export const DeletePortfolioDashboardConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePortfolioDashboardConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePortfolioDashboardConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePortfolioDashboardConfigsMutation,
  DeletePortfolioDashboardConfigsMutationVariables
>;
export const GetPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPortfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfoliosQuery, GetPortfoliosQueryVariables>;
export const CreatePortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePortfolioInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPortfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortfoliosMutation,
  CreatePortfoliosMutationVariables
>;
export const UpdatePortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePortfolioInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePortfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePortfoliosMutation,
  UpdatePortfoliosMutationVariables
>;
export const UpsertPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPortfolioInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPortfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPortfoliosMutation,
  UpsertPortfoliosMutationVariables
>;
export const DeletePortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePortfolioInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePortfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePortfoliosMutation,
  DeletePortfoliosMutationVariables
>;
export const GetPrivateEquityFundProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPrivateEquityFundProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundProjectsQuery,
  GetPrivateEquityFundProjectsQueryVariables
>;
export const CreatePrivateEquityFundProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPrivateEquityFundProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePrivateEquityFundProjectInputType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPrivateEquityFundProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePrivateEquityFundProjectsMutation,
  CreatePrivateEquityFundProjectsMutationVariables
>;
export const UpdatePrivateEquityFundProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePrivateEquityFundProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdatePrivateEquityFundProjectInputType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrivateEquityFundProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrivateEquityFundProjectsMutation,
  UpdatePrivateEquityFundProjectsMutationVariables
>;
export const UpsertPrivateEquityFundProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPrivateEquityFundProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpsertPrivateEquityFundProjectInputType',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPrivateEquityFundProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPrivateEquityFundProjectsMutation,
  UpsertPrivateEquityFundProjectsMutationVariables
>;
export const DeletePrivateEquityFundProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePrivateEquityFundProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePrivateEquityFundProjectInputType',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePrivateEquityFundProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePrivateEquityFundProjectsMutation,
  DeletePrivateEquityFundProjectsMutationVariables
>;
export const GetPrivateEquityFundProjectByProjectIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrivateEquityFundProjectByProjectId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getPrivateEquityFundProjectByProjectId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrivateEquityFundProjectByProjectIdQuery,
  GetPrivateEquityFundProjectByProjectIdQueryVariables
>;
export const GetProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectObjsShallowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectObjsShallow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectObjs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectObjsShallowQuery,
  GetProjectObjsShallowQueryVariables
>;
export const CreateProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProjectInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectsMutation,
  CreateProjectsMutationVariables
>;
export const UpdateProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProjectInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectsMutation,
  UpdateProjectsMutationVariables
>;
export const UpsertProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertProjectInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectsMutation,
  UpsertProjectsMutationVariables
>;
export const DeleteProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteProjectInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectsMutation,
  DeleteProjectsMutationVariables
>;
export const GetGeneratedReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGeneratedReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGeneratedReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GeneratedReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GeneratedReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeneratedReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGeneratedReportsQuery,
  GetGeneratedReportsQueryVariables
>;
export const GetGeneratedReportsByProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGeneratedReportsByProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGeneratedReportsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GeneratedReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GeneratedReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeneratedReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGeneratedReportsByProjectQuery,
  GetGeneratedReportsByProjectQueryVariables
>;
export const CreateGeneratedReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGeneratedReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateGeneratedReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGeneratedReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GeneratedReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GeneratedReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeneratedReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGeneratedReportsMutation,
  CreateGeneratedReportsMutationVariables
>;
export const UpdateGeneratedReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGeneratedReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateGeneratedReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGeneratedReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GeneratedReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GeneratedReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeneratedReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGeneratedReportsMutation,
  UpdateGeneratedReportsMutationVariables
>;
export const UpsertGeneratedReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertGeneratedReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertGeneratedReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertGeneratedReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GeneratedReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GeneratedReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeneratedReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertGeneratedReportsMutation,
  UpsertGeneratedReportsMutationVariables
>;
export const DeleteGeneratedReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGeneratedReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteGeneratedReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGeneratedReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGeneratedReportsMutation,
  DeleteGeneratedReportsMutationVariables
>;
export const GetProjectReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectReportsQuery,
  GetProjectReportsQueryVariables
>;
export const GetProjectReportsByProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectReportsByProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectReportsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectReportsByProjectQuery,
  GetProjectReportsByProjectQueryVariables
>;
export const CreateProjectReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProjectReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectReportsMutation,
  CreateProjectReportsMutationVariables
>;
export const UpdateProjectReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProjectReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectReportsMutation,
  UpdateProjectReportsMutationVariables
>;
export const UpsertProjectReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertProjectReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertProjectReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertProjectReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardElement' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'config' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compactType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'layouts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'elements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardElement' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectReportsMutation,
  UpsertProjectReportsMutationVariables
>;
export const DeleteProjectReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteProjectReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProjectReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectReportsMutation,
  DeleteProjectReportsMutationVariables
>;
export const GetReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportsQuery, GetReportsQueryVariables>;
export const GetReportsByProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReportsByProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReportsByProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReportsByProjectQuery,
  GetReportsByProjectQueryVariables
>;
export const CreateReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReportsMutation,
  CreateReportsMutationVariables
>;
export const UpdateReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReportsMutation,
  UpdateReportsMutationVariables
>;
export const UpsertReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Scheduler' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Scheduler' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cron' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduler' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Scheduler' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertReportsMutation,
  UpsertReportsMutationVariables
>;
export const DeleteReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteReportInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReportsMutation,
  DeleteReportsMutationVariables
>;
export const GetWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withAssets' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withEntities' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withPeople' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'withTransfers' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Workspace' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HoldingObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HoldingObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formula' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockGoalRule' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockGoalRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'goalRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GoalRule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stock' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockTrade' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockGoalRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockGoalRule' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BondHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BondHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bond' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bondTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoanHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoanHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WarrantHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warrant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundPosition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundPosition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundTrade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundTrade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shares' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundHolding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'position' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FundHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FundHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundPositions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundPosition' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundTrades' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundTrade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNoteHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNoteHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CryptoHolding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CryptoHolding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crypto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: '_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'HoldingObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Valuation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Valuation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownershipPercentage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Commitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Commitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commitmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transaction' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Holding' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Holding' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StockHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BondHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BondHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LoanHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoanHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarrantHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WarrantHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FundHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FundHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNoteHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNoteHolding' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CryptoHolding' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CryptoHolding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valuations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Valuation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Commitment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withTransfers' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Transaction' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Transaction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transaction' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Allocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Allocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioBase' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentPortfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Allocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holdings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PortfolioBase' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioBase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PortfolioBase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolios' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Portfolio' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'managementFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hurdleRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carry' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carriedInterestType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endInvestmentDate' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCommitment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalCallTransfer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCall' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundCapitalDistribution' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'PrivateEquityFundCapitalDistributionTransfer',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'PrivateEquityFundCapitalDistributionTransfer',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Transfer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundContact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commitments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCommitment',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCallTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalCallTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributionTransfer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistributionTransfer',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundContact' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Holding' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'education' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AssetObj' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AssetObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'types' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'category' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assetClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ISIN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'VPS' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookup' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fundSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundSetup' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestor' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundInvestment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalCalls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundCapitalCall' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'capitalDistributions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundCapitalDistribution',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundTeamMember' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privateEquityFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFund' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProject' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Stock' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Stock' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Bond' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Bond' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Loan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Loan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Warrant' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warrant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Fund' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Fund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paperId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BankNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Crypto' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Crypto' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bank' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFund' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AssetObj' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Asset' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: '_type' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Stock' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Stock' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Bond' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Bond' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Loan' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Loan' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Warrant' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Warrant' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Fund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Fund' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BankNote' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BankNote' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Crypto' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Crypto' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFund' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PrivateEquityFundShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RegionAllocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RegionAllocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'File' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'File' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EntityMedia' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'File' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Entity' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Entity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sector' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionAllocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RegionAllocation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EntityMedia' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Role' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Role' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Phone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Phone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Email' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Email' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Address' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel1' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'administrativeAreaLevel2' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'person' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Person' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Person' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Role' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Phone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Email' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Address' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Workspace' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withAssets' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withEntities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Entity' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'people' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withPeople' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Person' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkspaceQuery, GetWorkspaceQueryVariables>;
export const GetWorkspaceShallowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkspaceShallow' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkspace' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundProjectShallow',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectShallow' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWorkspaceShallowQuery,
  GetWorkspaceShallowQueryVariables
>;
export const CreateWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CreateWorkspaceInputType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceBaseWithRoles' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspacePermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspacePermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewWorkspaceProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editWorkspaceProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addWorkspaceRoles' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceRoles' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeWorkspaceRoles' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspacePermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceBaseWithRoles' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'WorkspaceBaseWithRoles' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceRole' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const UpdateWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UpdateWorkspaceInputType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundProjectShallow',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectShallow' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>;
export const DeleteWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspace' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deleteWorkspace' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;
export const ApplyWorkspaceSnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyWorkspaceSnapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WorkspaceSnapshotInputType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyWorkspaceSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'WorkspaceShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectObjShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectObj' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PrivateEquityFundProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateEquityFundProject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectObjShallow' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PortfolioProjectShallow' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PrivateEquityFundProject' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PrivateEquityFundProjectShallow',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WorkspaceShallow' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Workspace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectShallow' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyWorkspaceSnapshotMutation,
  ApplyWorkspaceSnapshotMutationVariables
>;
