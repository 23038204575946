import moment from 'moment';
import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getBondHoldingValuation } from '~src/data/store/selectors/finance/valuation/bond/bond-holding-valuation';
import { getFundHoldingValuation } from '~src/data/store/selectors/finance/valuation/fund/fund-holding-valuation';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { getFundHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/fund/fund-holding/selectors';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';

import { HoldingType } from '@pladdenico/models';

import { getBaseHoldingValuation } from './base-holding-valuation';
import { getStockHoldingValuation } from './stock/stock-holding-valuation';
import { createSelector } from 'reselect';

interface Props {
  workspaceId: string;
  holding: HoldingState;
  date: moment.Moment;
  currency: CurrencyState;
  useLiveValue: boolean;
}

export const getValuationTyped = (
  state: DataState,
  workspaceId: string,
  holding: HoldingState,
  date: moment.Moment,
  currency: CurrencyState,
  useLiveValue: boolean,
) => {
  if (holding.type === HoldingType.Stock) {
    const stockHolding = getStockHoldingByHoldingId(
      selectWorkspaceDataFromData(state),
      holding.id,
    );
    if (stockHolding) {
      return getStockHoldingValuation(state, {
        workspaceId,
        holdingId: holding.id,
        stockHoldingId: stockHolding.id,
        stockId: stockHolding.stockId,
        date,
        currency,
        useLiveValue,
      });
    }
  } else if (holding.type === HoldingType.Fund) {
    const fundHolding = getFundHoldingByHoldingId(
      selectWorkspaceDataFromData(state),
      holding.id,
    );
    if (fundHolding) {
      return getFundHoldingValuation(state, {
        workspaceId,
        holdingId: holding.id,
        fundHoldingId: fundHolding.id,
        fundId: fundHolding.fundId,
        date,
        currency,
        useLiveValue,
      });
    }
  } else if (holding.type === HoldingType.Bond) {
    const bondHolding = getBondHoldingByHoldingId(
      selectWorkspaceDataFromData(state),
      holding.id,
    );
    if (bondHolding) {
      return getBondHoldingValuation(state, {
        workspaceId,
        holdingId: holding.id,
        bondHoldingId: bondHolding.id,
        bondId: bondHolding.bondId,
        date,
        currency,
        useLiveValue,
      });
    }
  }
  return getBaseHoldingValuation(state, {
    holding,
    date,
    currency,
  });
};

export const getHoldingValuation = createCachedSelector(
  (state: DataState, props: Props) =>
    getValuationTyped(
      state,
      props.workspaceId,
      props.holding,
      props.date,
      props.currency,
      props.useLiveValue,
    ),
  (valuation) => {
    return valuation;
  },
)({
  keySelector: (_state, props) =>
    `${props.holding.id}:${props.currency.id}:${props.date.unix()}:${
      props.useLiveValue
    }`,
  selectorCreator: createSelector,
});

// export const getHoldingValuation = createCachedSelector(
//   (state: DataState) => state,
//   (_state: DataState, props: Props) => props.workspaceId,
//   (_state: DataState, props: Props) => props.holding,
//   (_state: DataState, props: Props) => props.date,
//   (_state: DataState, props: Props) => props.currency,
//   (_state: DataState, props: Props) => props.useLiveValue,
//   (state, workspaceId, holding, date, currency, useLiveValue) => {
//     if (holding.type === HoldingType.Stock) {
//       const stockHolding = getStockHoldingByHoldingId(
//         selectWorkspaceDataFromData(state),
//         holding.id
//       );
//       if (stockHolding) {
//         return getStockHoldingValuation(state, {
//           workspaceId,
//           holdingId: holding.id,
//           stockHoldingId: stockHolding.id,
//           stockId: stockHolding.stockId,
//           date,
//           currency,
//           useLiveValue,
//         });
//       }
//     } else if (holding.type === HoldingType.Fund) {
//       const fundHolding = getFundHoldingByHoldingId(
//         selectWorkspaceDataFromData(state),
//         holding.id
//       );
//       if (fundHolding) {
//         return getFundHoldingValuation(state, {
//           workspaceId,
//           holdingId: holding.id,
//           fundHoldingId: fundHolding.id,
//           fundId: fundHolding.fundId,
//           date,
//           currency,
//           useLiveValue,
//         });
//       }
//     } else if (holding.type === HoldingType.Bond) {
//       const bondHolding = getBondHoldingByHoldingId(
//         selectWorkspaceDataFromData(state),
//         holding.id
//       );
//       if (bondHolding) {
//         return getBondHoldingValuation(state, {
//           workspaceId,
//           holdingId: holding.id,
//           bondHoldingId: bondHolding.id,
//           bondId: bondHolding.bondId,
//           date,
//           currency,
//           useLiveValue,
//         });
//       }
//     }
//     return getBaseHoldingValuation(state, {
//       holding,
//       date,
//       currency,
//     });
//   }
// )(
//   (_state, props) =>
//     `${props.holding.id}:${props.currency.id}:${props.date.unix()}:${
//       props.useLiveValue
//     }`
// );
