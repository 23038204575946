export const dataFromResearch = {
  H: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04, 0.048, 0.0192,
    0.0144, 0.0, 0.0132, 0.0072, 0.0028, 0.0, 0.0, 0.048, 0.09, 0.0288, 0.0252,
    0.0078, 0.0231, 0.0108, 0.0042, 0.0021, 0.0, 0.0192, 0.0288, 0.0256,
    0.00768, 0.0, 0.01232, 0.00576, 0.00112, 0.00336, 0.0, 0.0144, 0.0252,
    0.00768, 0.0144, 0.01248, 0.00792, 0.00324, -0.00084, 0.00084, 0.0, 0.0,
    0.0078, 0.0, 0.01248, 0.0676, 0.0, 0.0, 0.0, 0.00546, 0.0, 0.0132, 0.0231,
    0.01232, 0.00792, 0.0, 0.0121, 0.00495, 0.00154, 0.00231, 0.0, 0.0072,
    0.0108, 0.00576, 0.00324, 0.0, 0.00495, 0.0081, 0.00504, 0.00504, 0.0,
    0.0028, 0.0042, 0.00112, -0.00084, 0.0, 0.00154, 0.00504, 0.0049, 0.00784,
    0.0, 0.0, 0.0021, 0.00336, 0.00084, 0.00546, 0.00231, 0.00504, 0.00784,
    0.0049,
  ],
  expected_return: [
    0.0, 0.068, 0.093, 0.05, 0.02, 0.034, 0.031, 0.019, 0.01, 0.007,
  ],
  target_return: 0.05,
  lower_bounds: [0.15, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
  upper_bounds: [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0],
};

export interface PortfolioAsset {
  id: string;
  name: string;
  standardDeviation: number;
  expectedReturn: number;
}

export interface Covariance {
  portfolioAssetIdA: string;
  portfolioAssetIdB: string;
  value: number;
}
// {
//   "H": [
//     0.0, 0.0,      0.0,      0.0,      0.0,      0.0,      0.0,      0.0,      0.0,      0.0,
//     0.0, 0.04,     0.048,    0.0192,   0.0144,   0.0,      0.0132,   0.0072,   0.0028,   0.0,
//     0.0, 0.048,    0.09,     0.0288,   0.0252,   0.0078,   0.0231,   0.0108,   0.0042,   0.0021,
//     0.0, 0.0192,   0.0288,   0.0256,   0.00768,  0.0,      0.01232,  0.00576,  0.00112,  0.00336,
//     0.0, 0.0144,   0.0252,   0.00768,  0.0144,   0.01248,  0.00792,  0.00324, -0.00084,  0.00084,
//     0.0, 0.0,      0.0078,   0.0,      0.01248,  0.0676,   0.0,      0.0,      0.0,      0.00546,
//     0.0, 0.0132,   0.0231,   0.01232,  0.00792,  0.0,      0.0121,   0.00495,  0.00154,  0.00231,
//     0.0, 0.0072,   0.0108,   0.00576,  0.00324,  0.0,      0.00495,  0.0081,   0.00504,  0.00504,
//     0.0, 0.0028,   0.0042,   0.00112, -0.00084,  0.0,      0.00154,  0.00504,  0.0049,   0.00784,
//     0.0, 0.0,      0.0021,   0.00336,  0.00084,  0.00546,  0.00231,  0.00504,  0.00784,  0.0049 ],
//   "expected_return": [
//     0.0, 0.068, 0.093, 0.05, 0.02, 0.034, 0.031, 0.019, 0.01, 0.007
//   ],
//   "target_return": 0.05,
//   "lower_bounds": [
//     0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
//   ],
//   "upper_bounds": [
//     1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0
//   ]
// }

// {
//   "H": [0.105, -0.010, 0.004,
//         -0.010, 0.040, -0.002,
//         0.004, -0.002, 0.023],
//   "expected_return": [
//     0.1, 0.2, 0.15
//   ],
//   "target_return": 0.15
// }

export const portfolioAssetsFromResearch: PortfolioAsset[] = [
  { id: 'CashID', name: 'Cash', standardDeviation: 0.0, expectedReturn: 0.0 },
  {
    id: 'EquitiesID',
    name: 'Equities',
    standardDeviation: 0.2,
    expectedReturn: 0.068,
  },
  {
    id: 'PEID',
    name: 'Private Equity',
    standardDeviation: 0.3,
    expectedReturn: 0.093,
  },
  {
    id: 'RealEstateID',
    name: 'Real Estate',
    standardDeviation: 0.16,
    expectedReturn: 0.05,
  },
  {
    id: 'HedgefundsID',
    name: 'Hedge funds',
    standardDeviation: 0.12,
    expectedReturn: 0.02,
  },
  {
    id: 'CommoditiesID',
    name: 'Commodities',
    standardDeviation: 0.26,
    expectedReturn: 0.034,
  },
  {
    id: 'HighYieldID',
    name: 'High Yield',
    standardDeviation: 0.11,
    expectedReturn: 0.031,
  },
  {
    id: 'CreditsID',
    name: 'Credits',
    standardDeviation: 0.09,
    expectedReturn: 0.019,
  },
  {
    id: 'BondsID',
    name: 'Bonds',
    standardDeviation: 0.07,
    expectedReturn: 0.01,
  },
  {
    id: 'InflationlinkedBondsID',
    name: 'Inflation linked bonds',
    standardDeviation: 0.07,
    expectedReturn: 0.007,
  },
];

export const covariancesFromResearch: Covariance[] = [
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'EquitiesID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'PEID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'RealEstateID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'HedgefundsID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'CommoditiesID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'HighYieldID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'BondsID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CashID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.0,
  },

  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'PEID',
    value: 0.048,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'RealEstateID',
    value: 0.0192,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'HedgefundsID',
    value: 0.0144,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'CommoditiesID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'HighYieldID',
    value: 0.0132,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.0072,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'BondsID',
    value: 0.0028,
  },
  {
    portfolioAssetIdA: 'EquitiesID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.0,
  },

  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'RealEstateID',
    value: 0.0288,
  },
  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'HedgefundsID',
    value: 0.0252,
  },
  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'CommoditiesID',
    value: 0.0078,
  },
  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'HighYieldID',
    value: 0.0231,
  },
  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.0108,
  },
  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'BondsID',
    value: 0.0042,
  },
  {
    portfolioAssetIdA: 'PEID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.0021,
  },

  {
    portfolioAssetIdA: 'RealEstateID',
    portfolioAssetIdB: 'HedgefundsID',
    value: 0.00768,
  },
  {
    portfolioAssetIdA: 'RealEstateID',
    portfolioAssetIdB: 'CommoditiesID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'RealEstateID',
    portfolioAssetIdB: 'HighYieldID',
    value: 0.01232,
  },
  {
    portfolioAssetIdA: 'RealEstateID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.00576,
  },
  {
    portfolioAssetIdA: 'RealEstateID',
    portfolioAssetIdB: 'BondsID',
    value: 0.00112,
  },
  {
    portfolioAssetIdA: 'RealEstateID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.00336,
  },

  {
    portfolioAssetIdA: 'HedgefundsID',
    portfolioAssetIdB: 'CommoditiesID',
    value: 0.01248,
  },
  {
    portfolioAssetIdA: 'HedgefundsID',
    portfolioAssetIdB: 'HighYieldID',
    value: 0.00792,
  },
  {
    portfolioAssetIdA: 'HedgefundsID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.00324,
  },
  {
    portfolioAssetIdA: 'HedgefundsID',
    portfolioAssetIdB: 'BondsID',
    value: -0.00084,
  },
  {
    portfolioAssetIdA: 'HedgefundsID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.00084,
  },

  {
    portfolioAssetIdA: 'CommoditiesID',
    portfolioAssetIdB: 'HighYieldID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CommoditiesID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CommoditiesID',
    portfolioAssetIdB: 'BondsID',
    value: 0.0,
  },
  {
    portfolioAssetIdA: 'CommoditiesID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.00546,
  },

  {
    portfolioAssetIdA: 'HighYieldID',
    portfolioAssetIdB: 'CreditsID',
    value: 0.00495,
  },
  {
    portfolioAssetIdA: 'HighYieldID',
    portfolioAssetIdB: 'BondsID',
    value: 0.00154,
  },
  {
    portfolioAssetIdA: 'HighYieldID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.00231,
  },

  {
    portfolioAssetIdA: 'CreditsID',
    portfolioAssetIdB: 'BondsID',
    value: 0.00504,
  },
  {
    portfolioAssetIdA: 'CreditsID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.00504,
  },

  {
    portfolioAssetIdA: 'BondsID',
    portfolioAssetIdB: 'InflationlinkedBondsID',
    value: 0.00784,
  },
];
