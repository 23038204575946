import React from 'react';
import { useSelector } from 'react-redux';
import { SnackbarFailure } from '~src/components/snackbars/snackbar-failure';
import { SnackbarSuccess } from '~src/components/snackbars/snackbar-success';
import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getFundByAssetId } from '~src/data/store/selectors/asset/asset-types/funds/selectors';
import { getPaperByFund } from '~src/data/store/selectors/finance/quotes/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useAssetEditForm } from '~src/domain/workspace/components/asset/form/edit/use-asset-edit-form';
import { useEditFundForm } from '~src/domain/workspace/components/asset/types/funds/form/use-edit-fund-form';
import { UpdateState } from '~src/utils/common/update-state';
import { useUpdateState } from '~src/utils/common/use-update-state';

import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { PaperAssetComponent } from '~src/domain/workspace/components/asset/paper/paper.component';
import { useEntity } from '~src/domain/workspace/components/asset/entity/use-entity';

interface PropsFromAsset {
  workspace: WorkspaceState;
  entity: EntityState;
  asset: AssetState;
}

export const FundFromAssetComponent = React.memo((props: PropsFromAsset) => {
  const { workspace, entity, asset } = props;
  const fund = useSelector((state: RootState) =>
    getFundByAssetId(selectWorkspaceData(state), {
      assetId: asset.id,
      workspaceId: workspace.id,
    }),
  );
  if (!fund) {
    return <CircularProgress />;
  }

  return (
    <FundComponent
      workspace={workspace}
      entity={entity}
      asset={asset}
      fund={fund}
    />
  );
});

interface Props {
  workspace: WorkspaceState;
  entity: EntityState;
  asset: AssetState;
  fund: FundState;
}
export const FundComponent = React.memo((props: Props) => {
  const { workspace, asset, entity, fund } = props;

  const updateStateData = useUpdateState();

  const onSuccess = React.useCallback(() => {
    updateStateData.setUpdateState(UpdateState.success);
  }, [updateStateData]);
  const onFailure = React.useCallback(
    (_error: any) => {
      updateStateData.setUpdateState(UpdateState.failed);
    },
    [updateStateData],
  );

  const assetForm = useAssetEditForm({
    asset,
    workspace,
    inputData: {
      assetClass: asset.assetClass ?? undefined,
      categoryId: asset.categoryId ?? undefined,
      description: asset.description ?? undefined,
      entityId: asset.entityId,
      ISIN: asset.ISIN ?? undefined,
      lookup: asset.lookup ?? undefined,
      name: asset.name ?? undefined,
      type: asset.type,
      types: asset.types ?? undefined,
      VPS: asset.VPS ?? undefined,
    },
    callbacks: {
      onSuccess,
      onFailure,
    },
  });

  const fundForm = useEditFundForm({
    fund,
    workspace,
    callbacks: {
      onSuccess,
      onFailure,
    },
    inputData: {
      assetId: asset.id,
      paperId: fund.paperId ?? undefined,
    },
  });

  const paper = useSelector((state: RootState) =>
    getPaperByFund(selectFinancialData(state), fund),
  );
  const entityElements = useEntity({ entity, workspace });

  return (
    <Box ml={1} mr={1} pb={2}>
      <Grid2 container rowSpacing={2} mt={1}>
        <Grid2 xs={12}>
          <Paper>
            <Grid2 container p={1} xs={12} columnSpacing={1}>
              <Grid2 xs={12}>
                <Typography variant="overline">Asset</Typography>
              </Grid2>
              <Grid2 xs={6}>
                {assetForm.fields.nameField}
                {assetForm.fields.typeField}
                {fundForm.fields.paperField}
                {assetForm.fields.typesField}
              </Grid2>
              <Grid2 xs={6}>
                {assetForm.fields.categoryField}
                {assetForm.fields.isinField}
                {assetForm.fields.lookupField}
                {assetForm.fields.vpsField}
              </Grid2>
              <Grid2 xs={12}>{assetForm.fields.descriptionField}</Grid2>
            </Grid2>
          </Paper>
        </Grid2>
        <Grid2 xs={12}>
          <Paper>
            <Grid2 container m={1} xs={12} justifyContent="space-between">
              <Grid2 xs={12}>
                <Typography variant="overline">Entity</Typography>
                {entityElements.linkElement}
              </Grid2>
              <Grid2 xs={6}>{assetForm.fields.entityField}</Grid2>
            </Grid2>
            {entityElements.contentElement}
          </Paper>
        </Grid2>
        {paper && (
          <Grid2 xs={12}>
            <PaperAssetComponent workspace={workspace} paper={paper} />
          </Grid2>
        )}
      </Grid2>
      <SnackbarFailure
        updateState={updateStateData.updateState}
        setUpdateState={updateStateData.setUpdateState}
        message={'Update failed'}
      />
      <SnackbarSuccess
        updateState={updateStateData.updateState}
        setUpdateState={updateStateData.setUpdateState}
        message={'Updated'}
      />
    </Box>
  );
});
