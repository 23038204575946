import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertAssets } from '~src/data/store/modules/assets/assets/requests';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'assets';

export interface Asset {
  id: string;
  entityId: string;
  type: string;
  name?: Maybe<string>;
  description?: Maybe<string>;
  categoryId?: Maybe<string>;
  assetClass?: Maybe<string>;
  VPS?: Maybe<string>;
  lookup?: Maybe<string>;
  ISIN?: Maybe<string>;
  // types?: Maybe<string[]>;
  types?: Maybe<string>;
}

const header: Array<keyof Asset> = [
  'id',
  'entityId',
  'type',
  'name',
  'description',
  'categoryId',
  'assetClass',
  'VPS',
  'lookup',
  'ISIN',
  'types',
];

export const assetExportHandler = (
  data: AssetState[],
): ExportHandler<Asset> => {
  const rows = data.map((asset) => {
    return {
      ...asset,
      types: asset.types?.join(','),
    };
  });
  return {
    type: name,
    data: outputPicker(rows, header),
    header,
  };
};

export const assetImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Asset> => {
  return {
    type: name,
    header,
    handler: (ts) => {
      const inputs = ts.map((t) => {
        return {
          ...t,
          types: t.types?.split(','),
        };
      });
      return dispatch(upsertAssets(workspace.tenantId, workspace.id, inputs));
    },
  };
};
