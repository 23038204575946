import { parseStockGoalRule } from '~src/data/store/modules/holdings/stock/goal-rules/parser';
import { subscribeToStockGoalRules } from '~src/data/store/modules/holdings/stock/goal-rules/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  stockGoalRulesActions,
  StockGoalRuleState,
} from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import { StockGoalRule } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface StockGoalRuleVisitor {
  visit(goalRule: StockGoalRuleVisitable): StockGoalRuleState;
  post(): void;
}

export class StockGoalRuleVisitable implements Visitable<StockGoalRuleVisitor> {
  constructor(private _goalRule: StockGoalRule) {}
  public accept(visitor: StockGoalRuleVisitor) {
    return visitor.visit(this);
  }

  public parse(): StockGoalRuleState {
    return parseStockGoalRule(this._goalRule);
  }
}

export class StockGoalRuleStateVisitor implements StockGoalRuleVisitor {
  private _stockGoalRules: StockGoalRuleState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._stockGoalRules = [];
  }

  public visit(position: StockGoalRuleVisitable): StockGoalRuleState {
    // this._stockGoalRules.push(position.parse());
    const positionState = position.parse();
    this._stockGoalRules.push(positionState);
    return positionState;
  }

  post() {
    this._dispatch(
      stockGoalRulesActions.upsertManyElements(this._stockGoalRules),
    );
    subscribeToStockGoalRules(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._stockGoalRules,
      this._subscriptions,
    );
  }
}
