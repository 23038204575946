import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { StockGoalRuleDialogContentComponent } from './dialog-content.component';
import { updateStockGoalRules } from '~src/data/store/modules/holdings/stock/goal-rules/requests';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
  stockGoalRuleUpdateInputInit: graphqlWorkspaceTypes.UpdateStockGoalRuleInputType;
}

export const UpdateStockGoalRuleComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <StockGoalRuleDialogContentComponent
        title={'Update goalRule'}
        workspace={props.workspace}
        handleClose={props.handleClose}
        inputInit={props.stockGoalRuleUpdateInputInit}
        saveStockGoalRules={updateStockGoalRules}
      />
    </Dialog>
  );
});
