import React from 'react';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    minWidth: 150,
    flex: 1,
    // height: 95,
  },
  title: {
    fontSize: 14,
  },
  cardContent: {
    height: '100%',
  },
  pos: {
    // marginBottom: 12,
  },
});

interface Props {
  projectId: string;
  scenario: Scenario;
}

export const ScenarioCardComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.root} variant="outlined" raised>
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" component="p">
          {props.scenario.name}
        </Typography>
      </CardContent>
    </Card>
  );
});
