import { OwnerLink } from '@pladdenico/models';
import { combineReducers } from 'redux';

import { baseReducer } from '../../../../base/old/base-array-reducer';
import {
  DELETED_OWNER_LINK,
  OWNER_LINK,
  OWNER_LINK_SELECTED,
  OWNER_LINK_UNSELECTED,
  OWNER_LINKS,
  OWNER_LINKS_SELECTED,
  OWNER_LINKS_UNSELECTED,
} from './action';

const ownerLinksInitialState: OwnerLink[] = [];
const ownerLinksReducer = baseReducer<OwnerLink>(
  {
    single: OWNER_LINK,
    multiple: OWNER_LINKS,
    singleDeleted: DELETED_OWNER_LINK,
  },
  ownerLinksInitialState,
);

const initialOwnerLinksSelectedState: OwnerLink[] = [];
const selectedOwnerLinksReducer = baseReducer<OwnerLink>(
  {
    single: OWNER_LINK_SELECTED,
    multiple: OWNER_LINKS_SELECTED,
    singleDeleted: OWNER_LINK_UNSELECTED,
    allDeleted: OWNER_LINKS_UNSELECTED,
  },
  initialOwnerLinksSelectedState,
);

// export const ownerLinksInitialState: OwnerLink[] = [];
// function ownerLinksReducer(
//   state: OwnerLink[] = ownerLinksInitialState,
//   action: OwnerLinkAction): OwnerLink[] {
//   switch (action.type) {
//     case OWNER_LINK: {
//       if (action.payload && action.payload.ownerLink) {
//         return __OLDinsertSorted<OwnerLink, number | undefined>(state, action.payload.ownerLink,
//           (ol: OwnerLink) => ol.id);
//       }
//     }
//     case OWNER_LINKS: {
//       if (action.payload && action.payload.ownerLinks) {
//         return __OLDmergeByIdentityKeepRight<OwnerLink>(state, action.payload.ownerLinks);
//       }
//     }
//     case DELETED_OWNER_LINK: {
//       if (action.payload && action.payload.ownerLinkId) {
//         return __OLDremoveSortedByIdentity(state, action.payload.ownerLinkId);
//       }
//     }
//     default:
//       return state;
//   }
// }

// export const initialOwnerLinksSelectedState: OwnerLink[] = [];
// function selectedOwnerLinksReducer(
//   state: OwnerLink[] = initialOwnerLinksSelectedState,
//   action: OwnerLinkAction): OwnerLink[] {
//   switch (action.type) {
//     case OWNER_LINK_SELECTED: {
//       if (action.payload && action.payload.ownerLink) {
//         return __OLDinsertSorted<OwnerLink, number | undefined>(state, action.payload.ownerLink, (a) => a.id);
//       }
//     }
//     case OWNER_LINKS_SELECTED: {
//       if (action.payload && action.payload.ownerLinks) {
//        return __OLDmergeByIdentityKeepRight<OwnerLink>(state, action.payload.ownerLinks);
//       }
//     }
//     case OWNER_LINKS_UNSELECTED: {
//       return [];
//     }
//     case OWNER_LINK_UNSELECTED: {
//       if (action.payload && action.payload.ownerLinkId) {
//         return __OLDremoveSortedByIdentity(state, action.payload.ownerLinkId);
//       }
//     }
//     default:
//       return state;
//   }
// }

export const ownerLinkInitialState = {
  links: ownerLinksInitialState,
  selectedOwnerLinks: initialOwnerLinksSelectedState,
};

export const ownerLinkReducer = combineReducers({
  links: ownerLinksReducer,
  selectedOwnerLinks: selectedOwnerLinksReducer,
});
