import React from 'react';
import { useDispatch } from 'react-redux';
import { updateProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useDashboardElements } from '~src/domain/workspace/components/project/portfolio/dashboard/hooks/use-dashboard-element';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function useReportDashboardElements(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();

  const { addElements, removeElements, updateElements } =
    useDashboardElements(workspace);

  // const layouts = React.useMemo((projectReport: ProjectReportState) => {
  //   console.log(
  //     'use portfoliodashboardconfig layouts',
  //     projectReport,
  //     projectReport.layouts
  //   );
  //   return projectReport.layouts;
  // }, []);

  const addDashboardElements = React.useCallback(
    (
      projectReport: ProjectReportState,
      dashboardElements: graphqlWorkspaceTypes.DashboardElement[],
    ) => {
      return addElements(dashboardElements).then((elements) => {
        const projectReportInput: graphqlWorkspaceTypes.UpdateProjectReportInputType =
          {
            id: projectReport.id,
            elementOperations: elements.operations,
          };
        return dispatch(
          updateProjectReports(workspace.tenantId, workspace.id, [
            projectReportInput,
          ]),
        ).then(() => elements.elements);
      });
    },
    [addElements, dispatch, workspace.id, workspace.tenantId],
  );
  const updateDashboardElements = React.useCallback(
    (
      dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
    ) => {
      return updateElements(dashboardElements).then((elements) => elements);
    },
    [updateElements],
  );
  const removeDashboardElements = React.useCallback(
    (projectReport: ProjectReportState, dashboardElementIds: string[]) => {
      return removeElements(dashboardElementIds).then((elementOperations) => {
        const projectReportInput: graphqlWorkspaceTypes.UpdateProjectReportInputType =
          {
            id: projectReport.id,
            elementOperations,
          };
        return dispatch(
          updateProjectReports(workspace.tenantId, workspace.id, [
            projectReportInput,
          ]),
        ).then(() => {
          return dashboardElementIds;
        });
      });
    },
    [dispatch, removeElements, workspace.id, workspace.tenantId],
  );
  return {
    // layouts,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  };
}
