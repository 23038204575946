import {
  PersonStateVisitor,
  PersonVisitable,
} from '~src/data/store/visitors/person/person-visitor';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { Person } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export type HandlePeople = (
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  people: Person[],
) => PersonState[];

export function handlePeople(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  people: Person[],
) {
  const visitor = new PersonStateVisitor(dispatch);
  const res = people.map((person) => {
    const visitable = new PersonVisitable(tenantId, workspaceId, person);
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
