import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetsComponent } from '~src/domain/workspace/components/asset/assets.component';
import { EntitiesComponent } from '~src/domain/workspace/components/entity/entity/entities.component';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { workspaceTabPath } from '../../../../../navigation/paths/workspace/paths';
import { DataComponent } from '../../data/data-component';
import { PeopleComponent } from '../../people/people.component';
import { WorkspaceSettingsComponent } from '../setup/workspace-settings.component';

export enum WorkspaceTabs {
  Settings = 'settings',
  Entities = 'entities',
  Assets = 'assets',
  People = 'people',
  Data = 'data',
}

interface TabPanelProps {
  children?: React.ReactNode;
  tab: WorkspaceTabs;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tab, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== tab}
      id={`scrollable-auto-tabpanel-${tab}`}
      aria-labelledby={`scrollable-auto-tab-${tab}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  tabs: Tab<WorkspaceTabs>[];
  tab: Tab<WorkspaceTabs>;
}

interface PropsTab {
  tenantId: string;
  workspace: WorkspaceState;
  tabName: string;
}

const SubComponent = React.memo((props: PropsTab) => {
  if (props.tabName === WorkspaceTabs.Settings) {
    return <WorkspaceSettingsComponent workspace={props.workspace} />;
  } else if (props.tabName === WorkspaceTabs.Assets) {
    return <AssetsComponent workspace={props.workspace} />;
  } else if (props.tabName === WorkspaceTabs.Entities) {
    return <EntitiesComponent workspace={props.workspace} />;
  } else if (props.tabName === WorkspaceTabs.People) {
    return <PeopleComponent workspace={props.workspace} />;
  } else if (props.tabName === WorkspaceTabs.Data) {
    return <DataComponent workspace={props.workspace} />;
  }
  return <div>unknown</div>;
});

export const WorkspaceTabsComponent = React.memo((props: Props) => {
  const navigate = useNavigate();

  const goToTab = (
    tenantId: string,
    workspaceId: string,
    tab: WorkspaceTabs,
  ) => {
    navigate(workspaceTabPath(tenantId, workspaceId, tab));
  };

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newWorkspaceTab: WorkspaceTabs,
  ) => {
    goToTab(props.workspace.tenantId, props.workspace.id, newWorkspaceTab);
  };

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        return (
          <SubComponent
            key={index}
            tabName={tab.name}
            tenantId={props.tenantId}
            workspace={props.workspace}
          />
        );
      }),
    [props.tabs, props.tenantId, props.workspace],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel value={props.tab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });
  return (
    <>
      <Tabs
        value={props.tab.name}
        indicatorColor="primary"
        textColor="primary"
        // centered
        variant="scrollable"
        onChange={handleChange}
        aria-label="workspace tabs"
      >
        {tabElements}
      </Tabs>
      {tabPanelElements}
    </>
  );
});
