import { parsePortfolio } from '~src/data/store/modules/portfolios/portfolios/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  PortfolioConfigStateVisitor,
  PortfolioConfigVisitable,
  PortfolioConfigVisitor,
} from '~src/data/store/visitors/workspace/project/portfolio/config/portfolio-config-visitor';
import {
  PortfolioAllocationStateVisitor,
  PortfolioAllocationVisitable,
  PortfolioAllocationVisitor,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-allocation-visitor';
import {
  portfoliosActions,
  PortfolioState,
} from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { Portfolio } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface PortfolioVisitor {
  visit(portfolio: PortfolioVisitable): void;
  post(): void;
  allocationVisitor: PortfolioAllocationVisitor;
  portfolioConfigVisitor: PortfolioConfigVisitor;
  // portfolioVisitor: PortfolioVisitor;
}

export class PortfolioVisitable implements Visitable<PortfolioVisitor> {
  constructor(
    private _workspaceId: string,
    private _portfolio: Portfolio,
  ) {}
  public accept(visitor: PortfolioVisitor) {
    if (this._portfolio.allocations) {
      this._portfolio.allocations.forEach((allocation) => {
        const portfolioAllocationVisitable = new PortfolioAllocationVisitable(
          this._portfolio.id,
          allocation,
        );
        portfolioAllocationVisitable.accept(visitor.allocationVisitor);
      });
    }
    if (this._portfolio.portfolioConfigs) {
      this._portfolio.portfolioConfigs.forEach((config) => {
        const portfolioConfigVisitable = new PortfolioConfigVisitable(
          this._portfolio.id,
          config,
        );
        portfolioConfigVisitable.accept(visitor.portfolioConfigVisitor);
      });
    }
    if (this._portfolio.portfolios) {
      this._portfolio.portfolios.forEach((portfolio) => {
        const portfolioVisitable = new PortfolioVisitable(
          this._workspaceId,
          portfolio,
        );
        portfolioVisitable.accept(visitor);
      });
    }
    visitor.visit(this);
  }

  public parse(): PortfolioState {
    return parsePortfolio(this._workspaceId, this._portfolio);
  }
}

export class PortfolioStateVisitor implements PortfolioVisitor {
  private _portfolios: PortfolioState[];
  // public holdingVisitor: HoldingVisitor;
  public allocationVisitor: PortfolioAllocationVisitor;
  public portfolioConfigVisitor: PortfolioConfigVisitor;
  // public portfolioVisitor: PortfolioVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._portfolios = [];
    this.allocationVisitor = new PortfolioAllocationStateVisitor(
      this._dispatch,
    );
    this.portfolioConfigVisitor = new PortfolioConfigStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    // this.portfolioVisitor = new PortfolioStateVisitor(
    //   this._dispatch,
    //   this._tenantId,
    //   this._workspaceId,
    //   this._subscriptions
    // );
  }

  public visit(portfolio: PortfolioVisitable): void {
    this._portfolios.push(portfolio.parse());
  }

  post() {
    this._dispatch(portfoliosActions.upsertManyElements(this._portfolios));
    this.allocationVisitor.post();
    this.portfolioConfigVisitor.post();
    // this.portfolioVisitor.post();
    return this._portfolios;
  }
}
