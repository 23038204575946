import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BasePhoneId {
  id: string;
  personId: string;
}

export type PhoneState = Omit<
  graphqlWorkspaceTypes.Phone,
  'person' | 'workspace'
> & {
  workspaceId: string;
  personId: string;
};

export const initialPhonesState: RecordState<PhoneState, string>[] = [];

const selectKeyId = (phone: BasePhoneId) => phone.personId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BasePhoneId, b: BasePhoneId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BasePhoneId, PhoneState, string>(
  initialPhonesState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const phonesSlice = createBaseRecordSlice('phones', adapter);
export const phonesSelectors = adapter.selectors;

export const phonesReducer = phonesSlice.reducer;
export const phonesActions = phonesSlice.actions;
export const phonesSlices = phonesSlice.slices;
