import React, { memo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import Cron from '~src/utils/cron/Cron';
import { useCronReducer } from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/use-cron-reducer';

import { colors, Divider, Grid, Box, TextField } from '@mui/material';

export interface InputData {
  name: string;
  description: string;
  cronValue: string;
}

interface Props {
  handleClose: () => void;
  isShowing: boolean;
  handleCancel: () => void;
  onSave: (data: InputData, cronValue: string) => Promise<void>;
  inputData?: InputData;
}

export const ReportFormComponent = memo((props: Props) => {
  const { handleClose, isShowing, handleCancel, onSave, inputData } = props;
  const defaultValue = '* * * * * *';
  const [values, dispatchValues] = useCronReducer(
    inputData ? inputData.cronValue : defaultValue,
  );

  const {
    control,
    handleSubmit,
    //  setValue, handleSubmit
  } = useForm<InputData>({
    defaultValues: {
      ...inputData,
    },
  });

  const onSubmit: SubmitHandler<InputData> = (data) => {
    console.log('SAVING');
    return onSave(data, values.cronValue);
  };

  const handleOnSave = () => {
    return handleSubmit(onSubmit)();
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      dispatchValues({
        type: 'set_cron_value',
        value: values.inputValue,
      });
    }
  };

  return (
    <DialogComponent
      dialogProps={{ open: isShowing, onClose: handleClose, fullWidth: true }}
    >
      <DialogContentComponent
        handleCancel={handleCancel}
        handleOnSave={handleOnSave}
        title="Report"
      >
        <form autoComplete="off">
          <Grid
            container
            justifyContent="center"
            columnSpacing={1}
            rowSpacing={2}
          >
            <Grid item sm={6}>
              <Controller
                name={'name'} // {'data.stockTrade.shares'}
                rules={{ required: true }}
                control={control}
                defaultValue={''}
                render={({ field, fieldState }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    value={field.value}
                    label="Name"
                    type="text"
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name={'description'} // {'data.stockTrade.shares'}
                rules={{ required: true }}
                control={control}
                defaultValue={''}
                render={({ field, fieldState }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    value={field.value}
                    label="Description"
                    type="text"
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid container item>
              <Box
                sx={{ flex: 1, pt: 1, pb: 1, backgroundColor: colors.blue[50] }}
              >
                <Grid container item justifyContent="center" rowSpacing={2}>
                  <Grid container item sm={12} justifyContent="center">
                    <Grid item>
                      <Cron
                        className="reporting-cron"
                        value={values.cronValue}
                        setValue={(newValue: string) => {
                          dispatchValues({
                            type: 'set_values',
                            value: newValue,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider>OR</Divider>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      label="Cron"
                      fullWidth
                      value={values.inputValue}
                      onChange={(event) => {
                        dispatchValues({
                          type: 'set_input_value',
                          value: event.target.value,
                        });
                      }}
                      onBlur={() => {
                        dispatchValues({
                          type: 'set_cron_value',
                          value: values.inputValue,
                        });
                      }}
                      onKeyDown={(event) => keyPress(event)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContentComponent>
    </DialogComponent>
  );
});
