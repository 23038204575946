import { workspaceRolesActions } from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';
import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryTenantContext,
} from '@pladdenico/portfolio-api';

import { handleWorkspaceRoles } from './handler';

export function fetchWorkspaceRoles(tenantId: string) {
  return (dispatch: AppDispatch) => {
    const node = graphqlTenantTypes.GetWorkspaceRolesDocument;
    const variables: graphqlTenantTypes.GetWorkspaceRolesQueryVariables = {};
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getWorkspaceRoles) {
        return handleWorkspaceRoles(tenantId, dispatch, data.getWorkspaceRoles);
      }
      return [];
    });
  };
}

export function createWorkspaceRole(
  tenantId: string,
  input: graphqlTenantTypes.CreateWorkspaceRoleInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlTenantTypes.CreateWorkspaceRoleDocument;
    const variables: graphqlTenantTypes.CreateWorkspaceRoleMutationVariables = {
      input,
    };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createWorkspaceRole) {
        return handleWorkspaceRoles(tenantId, dispatch, [
          data.createWorkspaceRole,
        ]);
      }
      return undefined;
    });
  };
}

export function updateWorkspaceRole(
  tenantId: string,
  input: graphqlTenantTypes.UpdateWorkspaceRoleInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlTenantTypes.UpdateWorkspaceRoleDocument;
    const variables: graphqlTenantTypes.UpdateWorkspaceRoleMutationVariables = {
      input,
    };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateWorkspaceRole) {
        return handleWorkspaceRoles(tenantId, dispatch, [
          data.updateWorkspaceRole,
        ]);
      }
      return [];
    });
  };
}

export function deleteWorkspaceRole(tenantId: string, workspaceRoleId: number) {
  return (dispatch: AppDispatch) => {
    const node = graphqlTenantTypes.DeleteWorkspaceRoleDocument;
    const variables: graphqlTenantTypes.DeleteWorkspaceRoleMutationVariables = {
      input: workspaceRoleId,
    };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteWorkspaceRole) {
        dispatch(
          workspaceRolesActions.removeOne({ id: data.deleteWorkspaceRole }),
        );
      }
      return [];
    });
  };
}

// export function selectWorkspaceRole(workspaceRole: WorkspaceRoleState) {
//   return (dispatch: any) => {
//     if (workspaceRole.id) {
//       // dispatch(selectWorkspaceRoleIdAction(workspaceRole.id));
//       dispatch(selectedWorkspaceRolesActions.set(workspaceRole.id));
//     }
//     // dispatch(unselectWorkspaceRoleRoleAction());
//     dispatch(unselectPortfolioAction());
//   };
// }

// export function unselectWorkspaceRole() {
//   return (dispatch: any) => {
//     // dispatch(unselectWorkspaceRoleAction());
//     dispatch(selectedWorkspaceRolesActions.unset(undefined));
//     // dispatch(unselectWorkspaceRoleRoleAction());
//     dispatch(unselectPortfolioAction());
//   };
// }
