import React from 'react';
import * as uuid from 'uuid';
import { usePaperActions } from '~src/hooks/actions/finance/use-paper-actions';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';

import { PaperDialogComponent } from './dialog.component';
import { PaperInput, PaperFieldsComponent } from './fields.component';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const CreatePaperComponent = React.memo((props: Props) => {
  const [paperInput, setPaperInput] =
    React.useState<graphqlFinanceTypes.CreatePaperInputType>({
      id: uuid.v1(),
      symbol: '',
      exchange: '',
      currencyId: '',
      isin: '',
      market: '',
      name: '',
    });
  // const [paperDataInput, setPaperDataInput] = React.useState<
  //   PaperDataInput
  // >(undefined);
  const { onCreate } = usePaperActions();

  const setPaperInputTyped = (paperInput: PaperInput) => {
    setPaperInput({
      ...paperInput,
      exchange: paperInput.exchange ?? '',
      symbol: paperInput.symbol ?? '',
      currencyId: paperInput.currencyId ?? '',
      isin: paperInput.isin ?? '',
      market: paperInput.market ?? '',
      name: paperInput.name ?? '',
    });
  };

  return (
    <PaperDialogComponent
      title={'Create paper'}
      open={props.open}
      handleClose={props.handleClose}
      handleSavePaper={() =>
        onCreate(paperInput).then(() => props.handleClose())
      }
    >
      {/* {content} */}
      <form>
        <PaperFieldsComponent
          hasIdField={false}
          paperInput={paperInput}
          setPaperInput={setPaperInputTyped}
        />
      </form>
    </PaperDialogComponent>
  );
});
