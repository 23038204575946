import React from 'react';
// import { SelectDownshiftField } from '~src/components/inputs/select-downshift-field';
import { useSelector } from 'react-redux';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';

interface Props {
  currencyId: string;
}

export const ViewCurrencyField = React.memo((props: Props) => {
  const currency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), { id: props.currencyId }),
  );

  return <>{currency ? currency.symbol : ''}</>;
});
