import { LoanState } from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { Loan } from '~src/services/graphql/workspace/client/graphql';

export function parseLoan(
  workspaceId: string,
  assetId: string,
  loan: Loan,
): LoanState {
  return {
    workspaceId,
    assetId,
    id: loan.id,
    interest: loan.interest,
    description: loan.description,
  };
}
