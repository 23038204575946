import { HoldingType } from '@pladdenico/models';
import { parseWarrantHolding } from '~src/data/store/modules/holdings/warrant/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  warrantHoldingsActions,
  WarrantHoldingState,
} from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import { Holding } from '~src/services/graphql/user/client/graphql';
import { WarrantHolding } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export const isWarrantHolding = (
  holding: Holding,
): holding is WarrantHolding => {
  return holding.holding.type === HoldingType.Warrant;
};

export interface WarrantHoldingVisitor {
  visit(warrantHolding: WarrantHoldingVisitable): WarrantHoldingState;
  post(): void;
}

export class WarrantHoldingVisitable
  implements Visitable<WarrantHoldingVisitor>
{
  constructor(private _warrantHolding: WarrantHolding) {}
  public accept(visitor: WarrantHoldingVisitor) {
    return visitor.visit(this);
  }

  public parse(): WarrantHoldingState {
    return parseWarrantHolding(this._warrantHolding);
  }
}

export class WarrantHoldingStateVisitor implements WarrantHoldingVisitor {
  private _warrantHoldings: WarrantHoldingState[];
  constructor(private _dispatch: AppDispatch) {
    this._warrantHoldings = [];
  }
  public visit(holding: WarrantHoldingVisitable): WarrantHoldingState {
    const warrantHolding = holding.parse();
    this._warrantHoldings.push(warrantHolding);
    return warrantHolding;
  }
  post() {
    this._dispatch(
      warrantHoldingsActions.upsertManyElements(this._warrantHoldings, {
        shouldAutobatch: true,
      }),
    );
  }
}
