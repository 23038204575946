import React from 'react';
import { makeStyles } from 'tss-react/mui';
import * as uuid from 'uuid';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { useHandler } from '~src/hooks/utils/use-handler';
// import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';

import Autorenew from '@mui/icons-material/Autorenew';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';

// export type PaperInput =
//   | graphqlFinanceTypes.CreatePaperInputType
//   | graphqlFinanceTypes.UpsertPaperInputType
//   | graphqlFinanceTypes.UpdatePaperInputType;

interface Props<PI> {
  hasIdField: boolean;
  paperInput: PI;
  setPaperInput: (paperInput: PI) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export interface PaperInput {
  id: string;
  exchange?: string | null;
  symbol?: string | null;
  currencyId?: string | null;
  market?: string | null;
  isin?: string | null;
  name?: string | null;
}

export const PaperFieldsComponent = React.memo(function <PI extends PaperInput>(
  props: Props<PI>,
) {
  const { classes } = useStyles();
  const { handleUpdate, handleEvent } = useHandler(
    props.paperInput,
    props.setPaperInput,
  );
  // const handleChange =
  //   (name: keyof PI) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     props.setPaperInput({
  //       ...props.paperInput,
  //       [name]: event.target.value,
  //     });
  //   };

  const handleClickRegenerateId = () => {
    props.setPaperInput({ ...props.paperInput, id: uuid.v1() });
  };

  const handleMouseDownRegenerateId = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const idField = props.hasIdField ? (
    <FormControl
      fullWidth
      required
      className={classes.textField}
      margin="normal"
    >
      <InputLabel htmlFor="adornment-id">Id</InputLabel>
      <Input
        id="adornment-id"
        type={'text'}
        value={props.paperInput.id}
        onChange={handleEvent('id')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="id regeneration"
              onClick={handleClickRegenerateId}
              onMouseDown={handleMouseDownRegenerateId}
            >
              <Autorenew />
              {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  ) : undefined;

  return (
    <>
      {idField}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="exchange"
        value={props.paperInput.exchange}
        label="Exchange"
        type="text"
        onChange={handleEvent('exchange')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="symbol"
        value={props.paperInput.symbol}
        label="Symbol"
        type="text"
        onChange={handleEvent('symbol')}
      />
      <EditCurrencyField
        currencyId={
          props.paperInput.currencyId ? props.paperInput.currencyId : ''
        }
        handleValue={(currency) =>
          currency && handleUpdate('currencyId')(currency.id)
        }
        labelled={true}
      />
    </>
  );
});
