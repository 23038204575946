import { handleFundTrades } from '~src/data/store/modules/holdings/fund/trade/handler';
import { fundTradesActions } from '~src/data/store/reducers/holding/holding-types/fund/fund-trade/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchFundTrades(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetFundTradesDocument;
    const variables: graphqlWorkspaceTypes.GetFundTradesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getFundTrades) {
        return handleFundTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.getFundTrades,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createFundTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateFundTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateFundTradesDocument;
    const variables: graphqlWorkspaceTypes.CreateFundTradesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createFundTrades) {
        return handleFundTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.createFundTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateFundTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateFundTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateFundTradesDocument;
    const variables: graphqlWorkspaceTypes.UpdateFundTradesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateFundTrades) {
        return handleFundTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.updateFundTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertFundTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertFundTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertFundTradesDocument;
    const variables: graphqlWorkspaceTypes.UpsertFundTradesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertFundTrades) {
        return handleFundTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertFundTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteFundTrades(fundHoldingId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      fundTradesActions.removeManyElements(
        ids.map((id) => {
          return {
            fundHoldingId,
            id,
          };
        }),
      ),
    );
  };
}

export function deleteFundTrades(
  tenantId: string,
  workspaceId: string,
  fundHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteFundTradesDocument;
    const variables: graphqlWorkspaceTypes.DeleteFundTradesMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteFundTrades) {
        dispatch(handleDeleteFundTrades(fundHoldingId, data.deleteFundTrades));
      }
      return [];
    });
  };
}
