import { BankNoteHoldingState } from '~src/data/store/reducers/holding/holding-types/bank-note/bank-note-holding/reducer';
import { BankNoteHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseBankNoteHolding(
  bankNoteHolding: BankNoteHolding,
): BankNoteHoldingState {
  return {
    id: bankNoteHolding.id,
    bankNoteId: bankNoteHolding.bankNote?.id ?? '',
    holdingId: bankNoteHolding.holding?.id ?? '',
  };
}
