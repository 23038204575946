import 'reactflow/dist/style.css';
import '../../index.css';

import React from 'react';
import { Node } from 'reactflow';
import { OnNodeDataChanges } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-store';
import { OutputNodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Divider, TextField } from '@mui/material';

interface Props {
  scenario: Scenario;
  node: Node<OutputNodeData>;
  onNodesDataChange: OnNodeDataChanges;
}

export const OutputNodeConfig = (props: Props) => {
  const { node, onNodesDataChange } = props;

  const updateData = React.useCallback(
    (data: OutputNodeData) => {
      onNodesDataChange([
        {
          change: {
            id: node.id,
            data,
          },
        },
      ]);
    },
    [node, onNodesDataChange],
  );

  const nodeConfigElement = React.useMemo(() => {
    return (
      <div className="updatenode__controls">
        <TextField
          margin="normal"
          label="Label"
          variant="outlined"
          value={node.data.label}
          onChange={(evt) => {
            const data = {
              ...node.data,
              label: evt.target.value,
            };
            updateData(data);
          }}
        />
        <TextField
          margin="normal"
          label="Output"
          variant="outlined"
          value={node.data.name}
          onChange={(evt) => {
            const data = {
              ...node.data,
              name: evt.target.value,
            };
            updateData(data);
          }}
        />
        <Divider />
      </div>
    );
  }, [node.data, updateData]);

  return nodeConfigElement;
};
