import { BondTradeState } from '~src/data/store/reducers/holding/holding-types/bond/bond-trade/reducer';
import { BondTrade } from '~src/services/graphql/workspace/client/graphql';

export function parseBondTrade(bondTrade: BondTrade): BondTradeState {
  return {
    id: bondTrade.id,
    bondHoldingId: bondTrade.bondHolding?.id ?? '',
    shares: bondTrade.shares,
    sharePrice: bondTrade.sharePrice,
    transferId: bondTrade.transfer?.id ?? '',
    positionId: bondTrade.position?.id ?? '',
  };
}
