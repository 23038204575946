import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DashboardEditSubComponentComponent } from './dashboard-edit-sub-component.component';
import {
  DashboardElementModel,
  DashboardElementsBaseType,
  PortfolioDashboardElementsType,
} from '@pladdenico/models';

interface Props {
  components: Array<{
    id: string;
    t: PortfolioDashboardElementsType | DashboardElementsBaseType;
  }>;
  availableComponents: DashboardElementModel<
    PortfolioDashboardElementsType | DashboardElementsBaseType
  >[];
  addSubComponent: (
    type: PortfolioDashboardElementsType | DashboardElementsBaseType,
  ) => void;
  removeSubComponent: (id: string) => void;
  move: (fromId: string, toId: string) => void;
}

export function DashboardEditSubComponentsComponent(props: Props) {
  const [unusedComponents, setUnusedComponents] = useState(
    new Array<
      DashboardElementModel<
        PortfolioDashboardElementsType | DashboardElementsBaseType
      >
    >(),
  );
  const [modalOpened, setModalOpened] = useState(false);
  const [usedComponentsElement, setUsedComponentsElement] = useState<
    Array<React.ReactElement<unknown>>
  >([]);
  const [unusedComponentsElement, setUnusedComponentsElement] = useState<
    Array<React.ReactElement<unknown>>
  >([]);
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const unusedComponents = props.availableComponents.filter(
      (dashboardSubComponent) => {
        return props.components.every((component) => {
          return dashboardSubComponent.type !== component.t;
        });
      },
    );
    setUnusedComponents(unusedComponents);
  }, [props.components, props.availableComponents]);

  useEffect(() => {
    setUsedComponentsElement(
      props.components.map((component) => {
        const dashboardSubComponent = props.availableComponents.find(
          (dashboardSubComponent) => {
            return dashboardSubComponent.type === component.t;
          },
        );
        const name = dashboardSubComponent ? dashboardSubComponent.title : '';
        return (
          <DashboardEditSubComponentComponent
            key={component.id}
            type={component.t}
            unused={false}
            add={props.addSubComponent}
            remove={props.removeSubComponent}
            move={props.move}
            id={component.id}
            name={name}
            canBeMoved={true}
          />
        );
      }),
    );
  }, [
    props.components,
    props.addSubComponent,
    props.move,
    props.removeSubComponent,
    props.availableComponents,
  ]);

  useEffect(() => {
    setUnusedComponentsElement(
      unusedComponents.map((component) => {
        return (
          <DashboardEditSubComponentComponent
            key={component.title}
            type={component.type}
            unused={true}
            add={props.addSubComponent}
            remove={props.removeSubComponent}
            move={props.move}
            id={component.title}
            name={component.title}
            canBeMoved={false}
          />
        );
      }),
    );
  }, [
    unusedComponents,
    props.addSubComponent,
    props.move,
    props.removeSubComponent,
  ]);

  const content = (
    <div>
      {usedComponentsElement}
      {unusedComponentsElement}
    </div>
  );

  const handleOpen = () => {
    setModalOpened(true);
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  // const modalElement = (
  //   <Modal
  //     trigger={<Button className={style.editDashboardButton} onClick={handleOpen} icon='edit'/>}
  //     open={modalOpened}
  //     onClose={handleClose}
  //     closeIcon={true}
  //     // basic
  //     size='small'>
  //     <Header icon='edit' content='Edit dashboard' />
  //     <Modal.Content>
  //       {content}
  //     </Modal.Content>

  //   </Modal>
  // );

  const dialogElement = (
    <Dialog
      fullScreen={fullScreen}
      open={modalOpened}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit dashboard</DialogTitle>
      <DialogContent>
        {content}
        {/* <CreateStockHoldingFormComponent holdingInput={holdingInput} setHoldingInput={setHoldingInput}/> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        sx={{ marginLeft: theme.spacing(1) }}
        onClick={handleOpen}
      >
        Edit
      </Button>
      {dialogElement}
    </div>
  );
}
