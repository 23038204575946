import { parseAssetCategory } from '~src/data/store/modules/assets/categories/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  AssetCategoryState,
  categoriesActions,
} from '~src/data/store/reducers/asset/categories/reducer';
import { AssetCategory } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface AssetCategoryVisitor {
  visit(asset: AssetCategoryVisitable): AssetCategoryState | undefined;
}

export class AssetCategoryVisitable implements Visitable<AssetCategoryVisitor> {
  constructor(
    private _workspaceId: string,
    private _category: AssetCategory,
  ) {}
  public accept(visitor: AssetCategoryVisitor) {
    return visitor.visit(this);
  }

  public parse(): AssetCategoryState | undefined {
    if (this._category) {
      return parseAssetCategory(this._workspaceId, this._category);
    }
  }
}

export class AssetCategoryStateVisitor implements AssetCategoryVisitor {
  private _categories: AssetCategoryState[];
  constructor(private _dispatch: AppDispatch) {
    this._categories = [];
  }
  public visit(asset: AssetCategoryVisitable): AssetCategoryState | undefined {
    const assetParsed = asset.parse();
    if (assetParsed) {
      this._categories.push(assetParsed);
    }
    return assetParsed;
  }
  post() {
    this._dispatch(categoriesActions.upsertManyElements(this._categories));
  }
}
