import moment from 'moment';
import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CashFlow } from '~src/domain/workspace/components/project/scenario/models/cash-flow';
import { transferCashSign } from '~src/utils/finance/transfer-cash-sign';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  cashFlows: CashFlow[];
}

interface Data {
  date: number;
  cashFlow?: number;
  accumulatedCashFlow?: number;
}

const mergeData = (data: Data[]) => {
  const mergedData: Data[] = [];
  for (let i = 0; i < data.length; ++i) {
    const cashFlow = data[i];
    const unixTime = cashFlow.date;
    const prevCashFlow = mergedData.at(-1);
    const prevDate = prevCashFlow?.date;
    if (prevCashFlow == null || prevDate == null || prevDate < unixTime) {
      mergedData.push(cashFlow);
    } else {
      const prevCashFlowValue = prevCashFlow.cashFlow ?? 0;
      const cashFlowValue = cashFlow.cashFlow ?? 0;
      mergedData[mergedData.length - 1] = {
        date: prevCashFlow.date,
        cashFlow: prevCashFlowValue + cashFlowValue,
      };
    }
  }
  return mergedData;
};

const createData = (cashFlows: CashFlow[]) => {
  let cfs: Data[] = [
    ...cashFlows.map((cash) => {
      return {
        date: cash.date.unix(),
        cashFlow: cash.value * transferCashSign(cash.transferType),
      };
    }),
  ];
  cfs = cfs.sort((a, b) => a.date - b.date);
  cfs = mergeData(cfs);
  let accumulatedCashFlow = 0;
  const data: Data[] = cfs.map((v) => {
    accumulatedCashFlow += v.cashFlow ?? 0;
    return {
      date: v.date,
      cashFlow: v.cashFlow,
      accumulatedCashFlow,
    };
  });
  const lastData = data.at(-1);
  if (lastData) {
    data.push({
      date: lastData.date + 5 * 60 * 60 * 24, // 5 days
      accumulatedCashFlow: lastData.accumulatedCashFlow,
    });
  }
  return data;
};

export const CashFlowChartComponent = React.memo((props: Props) => {
  const { cashFlows } = props;
  const data = createData(cashFlows);
  const lastDate = data.at(data.length - 1);
  const maxDate = lastDate?.date ?? moment().add(1, 'day').unix();
  const gradientOffset = () => {
    const dataMax = data.reduce((prev, curr) => {
      const accumulatedCashFlow = curr.accumulatedCashFlow;
      if (accumulatedCashFlow == null) {
        return prev;
      }
      return Math.max(prev, accumulatedCashFlow);
    }, 0);
    const dataMin = data.reduce((prev, curr) => {
      const accumulatedCashFlow = curr.accumulatedCashFlow;
      if (accumulatedCashFlow == null) {
        return prev;
      }
      return Math.min(prev, accumulatedCashFlow);
    }, 0);

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();
  return (
    <ComposedChart
      width={500}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        dataKey="date"
        type="number"
        tickFormatter={(date: number) => {
          return moment.unix(date).format('DD.MM.YYYY');
        }}
        domain={[
          moment().add(-1, 'day').unix(),
          moment.unix(maxDate).add(1, 'day').unix(),
        ]}
      />
      <YAxis />
      <Tooltip />
      <Legend />
      <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
          <stop offset={off} stopColor="green" stopOpacity={1} />
          <stop offset={off} stopColor="red" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Area
        type="stepAfter"
        dataKey="accumulatedCashFlow"
        fill="url(#splitColor)"
        stroke="#8884d8"
      />
      <Scatter dataKey="cashFlow" fill="red" />
    </ComposedChart>
  );
});
