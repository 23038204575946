import { Operation } from '@pladdenico/portfolio-api';
import { parseAssetObj } from '~src/data/store/modules/assets/assets/parser';
import { subscribeToAsset } from '~src/data/store/modules/assets/assets/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  assetsActions,
  AssetState,
} from '~src/data/store/reducers/asset/assets/reducer';
import { AssetObj } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface AssetObjVisitor {
  visit(asset: AssetObjVisitable): void;
  post(): AssetState[];
  // run(visit: () => void): AssetState[];
}

export class AssetObjVisitable implements Visitable<AssetObjVisitor> {
  constructor(
    private _tenantId: string,
    private _workspaceId: string,
    private _asset: AssetObj,
  ) {}
  public accept(visitor: AssetObjVisitor) {
    return visitor.visit(this);
  }

  public parse(): AssetState | undefined {
    if (this._asset) {
      return parseAssetObj(this._tenantId, this._workspaceId, this._asset);
    }
  }
}

export class AssetObjStateVisitor implements AssetObjVisitor {
  private _assets: AssetState[];

  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._assets = [];
  }

  public visit(asset: AssetObjVisitable): void {
    const assetParsed = asset.parse();
    if (assetParsed) {
      this._assets.push(assetParsed);
    }
  }

  public post() {
    this._dispatch(
      assetsActions.upsertManyElements(this._assets, {
        shouldAutobatch: true,
      }),
    );
    subscribeToAsset(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._assets,
      this._subscriptions,
    );
    return this._assets;
  }

  // public run(accepter: (visitor: AssetObjVisitor) => void): AssetState[] {
  //   accepter(this);
  //   return this._post();
  // }
}
