import React from 'react';
import { useHandler } from '~src/hooks/utils/use-handler';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspaceInput:
    | graphqlWorkspaceTypes.CreateWorkspaceInputType
    | graphqlWorkspaceTypes.UpdateWorkspaceInputType;
  setWorkspaceInput: (
    workspaceInput:
      | graphqlWorkspaceTypes.CreateWorkspaceInputType
      | graphqlWorkspaceTypes.UpdateWorkspaceInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  types: {
    minWidth: 100,
  },
}));
export const FieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { handleEvent } = useHandler(
    props.workspaceInput,
    props.setWorkspaceInput,
  );
  return (
    <>
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.workspaceInput.name}
        label="Name"
        type="text"
        onChange={handleEvent('name')}
      />
    </>
  );
});
