import { holdingBasePath } from '../../paths';

export const stockPositionsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) => {
  return `${holdingBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/stockPositions`;
};

export const stockPositionBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  stockPositionId: string,
) => {
  return `${stockPositionsBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/${stockPositionId}`;
};

export const stockPositionTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  stockPositionId: string,
  tab: string,
) => {
  return `${stockPositionBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
    stockPositionId,
  )}/${tab}`;
};
