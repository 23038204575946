import React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { CreateValuationFormComponent } from './create-form.component';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  open: boolean;
  handleClose: () => void;
  valuationCreateInputInit: graphqlWorkspaceTypes.CreateValuationInputType;
}

export const CreateValuationComponent = React.memo((props: Props) => {
  return (
    <DialogComponent
      dialogProps={{
        open: props.open,
        onClose: props.handleClose,
        fullWidth: true,
      }}
    >
      <CreateValuationFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        handleClose={props.handleClose}
        valuationCreateInputInit={props.valuationCreateInputInit}
      />
    </DialogComponent>
  );
});
