import { parseCurrency } from '~src/data/store/modules/currencies/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  currenciesActions,
  CurrencyState,
} from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { Currency } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

interface CurrencyVisitor {
  visit(currencies: CurrencyVisitable): void;
  post(): void;
}

export class CurrencyVisitable implements Visitable<CurrencyVisitor> {
  constructor(private _currency: Currency) {}
  public accept(visitor: CurrencyVisitor) {
    visitor.visit(this);
  }

  public parse(): CurrencyState {
    return parseCurrency(this._currency);
  }
}

export class CurrencyStateVisitor implements CurrencyVisitor {
  private _currencies: CurrencyState[];
  constructor(private _dispatch: AppDispatch) {
    this._currencies = [];
  }
  public visit(currencies: CurrencyVisitable): void {
    this._currencies.push(currencies.parse());
  }
  post() {
    this._dispatch(
      currenciesActions.upsertManyElements(this._currencies, {
        shouldAutobatch: true,
      }),
    );
  }
}
