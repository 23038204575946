import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  holdingBasePath,
  holdingsBasePath,
} from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useProjectBreadcrumbs } from './project-breadcrumb';

function useHoldingBreadcrumbs(_last: boolean) {
  const { tenantId, workspaceId, projectId, holdingId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    holdingId: string;
  }>();
  const holdingsBreadcrumbs = useHoldingsBreadcrumbs(false);
  const holding = useSelector((state: RootState) => {
    if (projectId != null && holdingId != null) {
      return getHoldingById(selectWorkspaceData(state), {
        projectId,
        id: holdingId,
      });
    }
  });
  if (holding && tenantId != null && workspaceId != null) {
    return [
      holdingsBreadcrumbs,
      <Typography
        color="inherit"
        key={holdingBasePath(
          tenantId,
          workspaceId,
          holding.projectId,
          holding.id,
        )}
      >
        {holding.name}
      </Typography>,
    ];
  }
  return [];
}
function useHoldingsBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId, projectId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
  }>();
  const projectBreadcrumbs = useProjectBreadcrumbs(false);
  if (tenantId != null && workspaceId != null && projectId != null) {
    const holdingElement = last ? (
      <Typography
        color="inherit"
        key={holdingsBasePath(tenantId, workspaceId, projectId)}
      >
        holdings
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={holdingsBasePath(tenantId, workspaceId, projectId)}
        to={holdingsBasePath(tenantId, workspaceId, projectId)}
      >
        holdings
      </LinkRouter>
    );
    return [projectBreadcrumbs, holdingElement];
  }
  return [];
}
export function HoldingBreadcrumbComponent() {
  const holdingBreadcrumbs = useHoldingBreadcrumbs(true);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {holdingBreadcrumbs}
    </Breadcrumbs>
  );
}
export function HoldingsBreadcrumbComponent(props: { last: boolean }) {
  const holdingsBreadcrumbs = useHoldingsBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {holdingsBreadcrumbs}
    </Breadcrumbs>
  );
}
