import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { Valuation } from '~src/services/graphql/workspace/client/graphql';

export function parseValuation(
  holdingId: string,
  valuation: Valuation,
): ValuationState {
  return {
    id: valuation.id,
    holdingId,
    date: new Date(valuation.date),
    value: valuation.value,
    ownershipPercentage: valuation.ownershipPercentage,
    currencyId: valuation.currency ? valuation.currency.id : '',
    status: valuation.status,
  };
}
