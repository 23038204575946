import { HoldingType, TransferType } from '@pladdenico/models';

export const holdingTypeToTransferTypes = new Map([
  [
    HoldingType.BankNote,
    [
      TransferType.Borrow,
      TransferType.Cost,
      TransferType.Deposit,
      TransferType.Dividend,
      TransferType.Interest,
      TransferType.Lend,
      TransferType.Provision,
      TransferType.Repayment,
      TransferType.RevenueFee,
      TransferType.Withdrawal,
      TransferType.WrongPayment,
    ],
  ],
  [
    HoldingType.Bond,
    [
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Dividend,
      TransferType.Interest,
      TransferType.Sell,
    ],
  ],
  [
    HoldingType.Crypto,
    [
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Dividend,
      TransferType.Sell,
    ],
  ],
  [
    HoldingType.Fund,
    [
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Dividend,
      TransferType.Sell,
    ],
  ],
  [
    HoldingType.Loan,
    [TransferType.Lend, TransferType.Interest, TransferType.Repayment],
  ],
  [
    HoldingType.PrivateEquityFund,
    [
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Dividend,
      TransferType.Sell,
    ],
  ],
  [
    HoldingType.Stock,
    [
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Dividend,
      TransferType.Sell,
    ],
  ],
  [
    HoldingType.Unknown,
    [
      TransferType.Borrow,
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Deposit,
      TransferType.Dividend,
      TransferType.Interest,
      TransferType.Lend,
      TransferType.Provision,
      TransferType.Repayment,
      TransferType.RevenueFee,
      TransferType.Sell,
      TransferType.Withdrawal,
      TransferType.WrongPayment,
    ],
  ],
  [
    HoldingType.Warrant,
    [
      TransferType.Buy,
      TransferType.Cost,
      TransferType.Interest,
      TransferType.Dividend,
      TransferType.Sell,
    ],
  ],
]);

export const getTransferTypes = (holdingType: HoldingType) => {
  const transferTypes = holdingTypeToTransferTypes.get(holdingType);
  return transferTypes ?? [];
};
