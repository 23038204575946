import { compact, sortedUniq } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
// import * as uuid from 'uuid';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsDenormalized } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { getTransactionsByIds } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { getTransfersByHoldingIds } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  TransactionCardData,
  TransactionCardsListComponent,
} from '~src/domain/workspace/components/project/transaction/card/cards-list.component';
import { useFields } from '~src/domain/workspace/components/project/transaction/card/use-fields';
import { TransactionsToolbar } from '~src/domain/workspace/components/project/transaction/transactions-toolbar.component';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { strcmp } from '@pladdenico/common';
// import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableField, TableOperations } from '@pladdenico/table';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';

const PREFIX = 'TransactionsComponent';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  cardListContainer: `${PREFIX}-cardListContainer`,
  cardList: `${PREFIX}-cardList`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    // padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    // flex: 1,
    // justifyContent: 'flex-end',
    // marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // marginTop: theme.spacing(2),
  },

  [`& .${classes.cardListContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  [`& .${classes.cardList}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  portfolios?: PortfolioState[];
}

export const HoldingsTransactionsComponent = React.memo((props: Props) => {
  const { workspace, project, portfolios, holdings } = props;

  const holdingsDenormalized = useSelector((state: RootState) =>
    getHoldingsDenormalized(selectWorkspaceData(state), {
      holdings,
      workspaceId: workspace.id,
    }),
  );

  const holdingIds = React.useMemo(() => {
    return holdingsDenormalized
      .map((h) => h.holding.id)
      .sort((a, b) => strcmp(a, b));
  }, [holdingsDenormalized]);

  const transfers = useSelector((state: RootState) =>
    getTransfersByHoldingIds(selectWorkspaceData(state), holdingIds),
  );
  const transactionIds = React.useMemo(
    () =>
      sortedUniq(
        transfers.map((t) => t.transactionId).sort((a, b) => strcmp(a, b)),
      ),
    [transfers],
  );

  const transactions = useSelector((state: RootState) =>
    getTransactionsByIds(selectWorkspaceData(state), {
      ids: transactionIds,
      projectId: project.id,
    }),
  );

  const data = React.useMemo((): TransactionCardData[] => {
    return transactions.map((transaction) => {
      const ts = transfers
        .filter((t) => t.transactionId === transaction.id)
        .map((t) => {
          const holding = holdingsDenormalized.find(
            (h) => h.holding.id === t.holdingId,
          );
          return {
            transfer: t,
            holding,
          };
        });
      return {
        id: transaction.id,
        transaction: {
          id: transaction.id,
          transaction,
        },
        transfers: ts,
      };
    });
  }, [holdingsDenormalized, transactions, transfers]);

  const fields = useFields({ useHolding: true });

  // const transactionCreateInputInit: graphqlWorkspaceTypes.CreateTransactionInputType =
  //   React.useMemo(() => {
  //     return {
  //       id: uuid.v1(),
  //       projectId: project.id,
  //       title: '',
  //       description: '',
  //     };
  //   }, [project.id]);

  const initialSorters =
    React.useMemo((): TableField.Sorter<TransactionCardData>[] => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'id', false),
        TableOperations.createSorterFromFields(fields, 'title', false),
        TableOperations.createSorterFromFields(fields, 'holding', false),
        TableOperations.createSorterFromFields(fields, 'date', 'asc'),
      ]);
    }, [fields]);

  const initialFilters = React.useMemo(
    () =>
      fields.map((field) => {
        return {
          id: field.name,
          name: field.name,
          type: TableField.FilterTypes.string,
          objId: field.id,
          query: '',
          operation: TableOperations.createIncludeFilterOperation(
            field.iteratee,
          ),
        };
      }),
    [fields],
  );

  const transactionAlgoData = TableOperations.useDataAlgorithms(
    data,
    initialSorters,
    initialFilters,
  );

  return (
    <Root className={classes.root}>
      <Grid container className={classes.content}>
        <Grid item xs={9} className={classes.cardListContainer}>
          {/* <div className={classes.root}> */}
          <div className={classes.toolbar}>
            <TransactionsToolbar
              workspace={workspace}
              projectId={project.id}
              // transactionCreateInputInit={transactionCreateInputInit}
              fields={fields}
              transactionAlgoData={transactionAlgoData}
            />
          </div>
          <div className={classes.content}>
            <TransactionCardsListComponent
              workspace={workspace}
              projectId={project.id}
              portfolios={portfolios}
              data={transactionAlgoData.filtered}
            />
          </div>
          {/* </div> */}
        </Grid>
      </Grid>
    </Root>
  );
});
