import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { SelectItem } from '~src/components/fields/common/use-select';
import { EntityData } from '~src/domain/workspace/components/entity/entity/entities.component';
// import history from '~src/navigation/history';
import { workspaceEntityRootPath } from '~src/navigation/paths/workspace/entity/paths';
import { colorPalette } from '~src/utils/common/colors/chart-colors';
import { getInitials } from '~src/utils/common/get-initials';
import {
  EntitySector,
  EntitySectors,
  entitySectorToString,
} from '~src/utils/interfaces/entity-sectors';

import { Avatar, Link, TextField, Theme, Typography } from '@mui/material';
import { TableField } from '@pladdenico/table';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  description: {
    maxWidth: '15em',
  },
}));

// const getAvatarColor = (array: Array<[string, string]>, seed: string) => {
//   return array[seed.length % array.length][1];
// };
const getAvatarColor = (seed: string) => {
  return colorPalette[seed.length % colorPalette.length];
};

export function useEntityFields(
  tenantId: string,
  workspaceId: string,
): TableField.Field<EntityData>[] {
  const { classes } = useStyles();
  const navigate = useNavigate();

  function handleEntityClick(
    tenantId: string,
    workspaceId: string,
    entityId: string,
  ) {
    navigate(workspaceEntityRootPath(tenantId, workspaceId, entityId));
  }

  const sectorItems = Object.values(EntitySectors).map((sector) => {
    return {
      name: entitySectorToString(sector),
      value: sector,
      t: sector,
    };
  });

  const fields: TableField.Field<EntityData>[] = [
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.name,
      renderView: (editProps) => {
        const name = editProps.data.name ? editProps.data.name : '';
        return (
          <div className={classes.nameContainer}>
            <Avatar
              className={clsx(classes.avatar)}
              variant="square"
              sx={{
                width: 32,
                height: 32,
                fontSize: 16,
                backgroundColor: getAvatarColor(name),
              }}
            >
              {getInitials(name)}
            </Avatar>
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                handleEntityClick(tenantId, workspaceId, editProps.data.id)
              }
            >
              {name}
            </Link>
          </div>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="name"
            value={editProps.data.name}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.description,
      renderView: (editProps) => {
        return (
          <Typography variant="body1" className={classes.description} noWrap>
            {editProps.data.description}
          </Typography>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="description"
            value={editProps.data.description}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'sector',
      name: 'sector',
      field: 'sector',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.sector,
      update: (data, sector: EntitySector) => {
        return {
          ...data,
          sector,
        };
      },
      renderEdit: (editProps) => {
        const sectorElement = (
          <SelectItem
            items={sectorItems}
            handleT={editProps.onChange}
            t={editProps.data.sector}
            label="sector"
          />
        );
        return sectorElement;
      },
    },
    {
      id: 'website',
      name: 'website',
      field: 'website',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.website,
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="website"
            value={editProps.data.website}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
  ];
  return fields;
}
