import {
  EntityStateVisitor,
  EntityVisitable,
} from '~src/data/store/visitors/workspace/entity/entity-visitor';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function handleEntities(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  entities: graphqlWorkspaceTypes.Entity[],
  subscriptions: Operation[],
) {
  const entityVisitor = new EntityStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  const entitiesState = entities.map((entity) => {
    const assetVisitable = new EntityVisitable(tenantId, workspaceId, entity);
    return assetVisitable.accept(entityVisitor);
  });
  entityVisitor.post();
  return entitiesState;
  // return entities;
}
