import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BasePortfolioConfigId {
  portfolioId: string;
  id: number;
}
// export type PortfolioConfigState = PortfolioConfig;

export type PortfolioConfigState = Omit<
  graphqlWorkspaceTypes.PortfolioConfig,
  'portfolioDashboardConfigs'
> & {
  portfolioId: string;
  portfolioDashboardConfigIds: string[];
};

export const initialPortfolioConfigState = [];

const selectKeyId = (portfolioConfig: BasePortfolioConfigId) =>
  portfolioConfig.portfolioId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BasePortfolioConfigId, b: BasePortfolioConfigId) =>
  a.id - b.id;

const adapter = new EntityRecordAdapter<
  BasePortfolioConfigId,
  PortfolioConfigState,
  string
>(initialPortfolioConfigState, selectKeyId, keyComparator, tComparator);

export const portfolioConfigsSlice = createBaseRecordSlice(
  'portfolioConfigs',
  adapter,
);
export const portfolioConfigSelectors = adapter.selectors;

export const portfolioConfigsReducer = portfolioConfigsSlice.reducer;
export const portfolioConfigsActions = portfolioConfigsSlice.actions;
