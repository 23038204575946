import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EntityMediaComponent } from '~src/domain/workspace/components/entity/entity/entity-media.component';
import { RegionAllocationsComponent } from '~src/domain/workspace/components/entity/region-allocations/region-allocations.component';

import { Box, CircularProgress, Typography } from '@mui/material';

import { EntityMediaDropzoneComponent } from '~src/domain/workspace/components/entity/entity/forms/create/media-dropzone.component';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEntityForm } from '~src/domain/workspace/components/entity/entity/form/use-entity-edit-form';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';

interface Props {
  entityId: string;
  workspace: WorkspaceState;
}

export const EntityComponent = React.memo((props: Props) => {
  const { entityId, workspace } = props;
  const entity = useSelector((state: RootState) =>
    getEntityById(selectWorkspaceData(state), {
      id: entityId,
      workspaceId: workspace.id,
    }),
  );
  if (!entity) {
    return <CircularProgress />;
  }
  return <EntityComponentWithEntity entity={entity} workspace={workspace} />;
});

interface PropsWithEntity {
  entity: EntityState;
  workspace: WorkspaceState;
}

export const EntityComponentWithEntity = React.memo(
  (props: PropsWithEntity) => {
    const { entity, workspace } = props;

    const form = useEntityForm({
      entity,
      workspace,
      inputData: {
        description: entity.description ?? undefined,
        name: entity.name ?? undefined,
        sector: entity.sector ?? undefined,
        website: entity.website ?? undefined,
      },
    });

    if (!entity) {
      return <CircularProgress />;
    }

    return (
      <Box ml={1} mr={1}>
        <Grid2 container columnSpacing={1}>
          <Grid2 container xs={6}>
            <Grid2 container xs={12}>
              <Grid2 xs={12}>
                <Typography variant="overline">Entity</Typography>
                {form.nameField}
              </Grid2>
            </Grid2>
            <Grid2 xs={12}>{form.descriptionField}</Grid2>
            <Grid2 xs={12}>{form.websiteField}</Grid2>
            <Grid2 xs={12}>{form.sectorField}</Grid2>
          </Grid2>
          <Grid2 container xs={6}>
            <Grid2>
              <Typography variant="caption">Media</Typography>
              <EntityMediaComponent
                entityId={entity.id}
                workspace={workspace}
              />
              <EntityMediaDropzoneComponent
                workspace={workspace}
                entityId={entity.id}
              />
            </Grid2>
          </Grid2>
          <Grid2 xs={12}>
            <RegionAllocationsComponent workspace={workspace} entity={entity} />
          </Grid2>
        </Grid2>
      </Box>
    );
  },
);
