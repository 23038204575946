import { colors } from '@mui/material';

export const colorsRowsPrimary = {
  veryLight: colors.blue[50],
  light: colors.blue[100],
  medium: colors.blue[300],
  dark: colors.blue[700],
  veryDark: colors.blue[900],
};

export const colorsRowsSecondary = {
  veryLight: colors.grey[50],
  light: colors.grey[100],
  medium: colors.grey[300],
  dark: colors.grey[700],
  veryDark: colors.grey[900],
};
