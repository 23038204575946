import * as React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const useProjectReportSendDialogComponent = (
  _workspace: WorkspaceState,
  projectReports: ProjectReportState[],
) => {
  const modal = useVisible();

  const projectReportIds = React.useMemo(() => {
    return projectReports.map((h) => h.id);
  }, [projectReports]);

  const dialog = (
    <DialogComponent
      dialogProps={{
        open: modal.isShowing,
        onClose: modal.close,
        fullWidth: true,
      }}
    >
      <>
        <DialogTitle>Send report</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Chose users to send the projectReport to. $
            {JSON.stringify(projectReportIds)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={modal.close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </>
    </DialogComponent>
  );

  return {
    modal,
    dialog,
  };
};
