import {
  BankNoteStateVisitor,
  BankNoteVisitable,
} from '~src/data/store/visitors/asset/types/bank-note/bank-note-visitor';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function handleBankNotes(
  workspaceId: string,
  dispatch: AppDispatch,
  bankNotes: graphqlWorkspaceTypes.BankNote[],
) {
  const visitor = new BankNoteStateVisitor(dispatch);
  bankNotes.forEach((bankNote) => {
    const visitable = new BankNoteVisitable(workspaceId, bankNote);
    return visitable.accept(visitor);
  });
  return visitor.post();
}
