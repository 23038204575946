import React from 'react';
import * as uuid from 'uuid';
import { StockHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/create-action';
import { HoldingDataInput } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';

import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

const getInput = (
  holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType,
) => {
  if (holdingInput.type === HoldingType.Stock) {
    const holding: StockHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
    };
    return holding;
  } else if (holdingInput.type === HoldingType.BankNote) {
    const holding: graphqlWorkspaceTypes.CreateBankNoteHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
      bankNoteId: '',
    };
    return holding;
  } else if (holdingInput.type === HoldingType.Loan) {
    const holding: graphqlWorkspaceTypes.CreateLoanHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
      loanId: '',
    };
    return holding;
  } else if (holdingInput.type === HoldingType.Warrant) {
    const holding: graphqlWorkspaceTypes.CreateWarrantHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
      warrantId: '',
    };
    return holding;
  } else if (holdingInput.type === HoldingType.Fund) {
    const holding: graphqlWorkspaceTypes.CreateFundHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
      fundId: '',
    };
    return holding;
  } else if (holdingInput.type === HoldingType.Bond) {
    const holding: graphqlWorkspaceTypes.CreateBondHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
      bondId: '',
    };
    return holding;
  } else if (holdingInput.type === HoldingType.Crypto) {
    const holding: graphqlWorkspaceTypes.CreateCryptoHoldingInputType = {
      holdingId: holdingInput.id,
      id: uuid.v1(),
      cryptoId: '',
    };
    return holding;
  }
};

export const useHolding = (
  holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType,
) => {
  const [holdingDataInput, setHoldingDataInput] =
    React.useState<HoldingDataInput>(getInput(holdingInput));
  const [type, setType] = React.useState(holdingInput.type);

  React.useEffect(() => {
    const input = getInput(holdingInput);
    if (holdingInput.type !== type) {
      setHoldingDataInput(input);
      setType(holdingInput.type);
    }
  }, [holdingInput, holdingInput.id, holdingInput.type, type]);

  return {
    holdingDataInput,
    setHoldingDataInput,
  };
};
