import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createStocks } from '~src/data/store/modules/assets/stocks/requests';
import { StockState } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputStockData } from '~src/domain/workspace/components/asset/types/stocks/form/stock-input.data';
import { useStockFields } from '~src/domain/workspace/components/asset/types/stocks/form/use-stock-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: InputStockData;
  callbacks?: {
    onSuccess?: (stock: StockState) => void;
    onFailure?: (error: any) => void;
  };
}

export const useCreateStockForm = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (assetId: string, data: InputStockData) => {
      return dispatch(
        createStocks(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            assetId: assetId,
            paperId: data.paperId,
          },
        ]),
      )
        .then((stocks) => {
          const stock = stocks[0];
          callbacks?.onSuccess && callbacks.onSuccess(stock);
          return stock;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<InputStockData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useStockFields(control);

  const onSubmit = React.useCallback(
    (
      assetId: string,
      successCallback: (stock: StockState) => Promise<void>,
    ): SubmitHandler<InputStockData> =>
      (data) => {
        return onSave(assetId, data).then((stock) => successCallback(stock));
      },
    [onSave],
  );

  const submit = React.useCallback(
    (
      assetId: string,
      successCallback: (stock: StockState) => Promise<void>,
    ) => {
      return handleSubmit(onSubmit(assetId, successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
