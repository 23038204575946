import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { TotalValueComponent } from '~src/components/summary/total-value.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsValue } from '~src/data/store/selectors/finance/valuation/holdings-value';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  baseCurrency: CurrencyState;
  useLiveValue: boolean;
  subLabel?: string;
}

export const TotalValueHoldingsComponent = React.memo((props: Props) => {
  // useComponentUpdateDebug('SummaryComponent', props);
  const {
    baseCurrency,
    date,
    holdings,
    projectId,
    useLiveValue,
    workspaceId,
    subLabel,
  } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const totalValue = useSelector((state: RootState) =>
    getHoldingsValue(selectData(state), {
      workspaceId,
      projectId,
      holdings,
      date,
      filter,
      currency: baseCurrency,
      useLiveValue,
    }),
  );

  return (
    <TotalValueComponent
      baseCurrency={baseCurrency}
      totalValue={totalValue}
      useMarketValue={useLiveValue}
      subLabel={subLabel}
    />
  );
});
