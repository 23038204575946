import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteHoldings } from '~src/data/store/modules/holdings/base/requests';
import { deleteWarrantHoldings } from '~src/data/store/modules/holdings/warrant/requests';
import { WarrantHoldingState } from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

export const useActions = (
  workspace: WorkspaceState,
  projectId: string,
  holding: HoldingState,
  warrantHolding: WarrantHoldingState,
) => {
  const dispatch = useDispatch<AppDispatch>();

  const deleteHolding = React.useCallback(() => {
    return dispatch(
      deleteWarrantHoldings(workspace.tenantId, workspace.id, holding.id, [
        warrantHolding.id,
      ]),
    ).then(() => {
      return dispatch(
        deleteHoldings(workspace.tenantId, workspace.id, projectId, [
          holding.id,
        ]),
      );
    });
  }, [
    dispatch,
    holding.id,
    projectId,
    warrantHolding.id,
    workspace.id,
    workspace.tenantId,
  ]);

  return {
    deleteHolding,
  };
};
