import { portfolioDashboardConfigsActions } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handlePortfolioDashboardConfigs } from './handler';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
// export function fetchPortfolioDashboardConfigs(
//   workspaceId: string,
//   portfolioId: string
// ) {
//   return (dispatch: any) => {
//     const node = graphqlWorkspaceTypes.GetPortfolioDashboardConfigsDocument;
//     const variables: graphqlWorkspaceTypes.GetPortfolioDashboardConfigsQueryVariables = {
//       portfolioId,
//     };
//     const context: QueryWorkspaceContext = {
//       type: QueryContextTypes.workspace,
//       workspaceId,
//     };
//     return investRequest(node, variables, context).then((data) => {
//       if (data.getPortfolioDashboardConfigs) {
//         return handlePortfolioDashboardConfigs(
//           dispatch,
//           portfolioId,
//           data.getPortfolioDashboardConfigs
//         );
//       }
//       return [];
//     });
//   };
// }

export function createPortfolioDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioConfigId: number,
  inputs: graphqlWorkspaceTypes.CreatePortfolioDashboardConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreatePortfolioDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.CreatePortfolioDashboardConfigsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPortfolioDashboardConfigs) {
        return handlePortfolioDashboardConfigs(
          dispatch,
          portfolioConfigId,
          data.createPortfolioDashboardConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updatePortfolioDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioConfigId: number,
  inputs: graphqlWorkspaceTypes.UpdatePortfolioDashboardConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdatePortfolioDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePortfolioDashboardConfigsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePortfolioDashboardConfigs) {
        return handlePortfolioDashboardConfigs(
          dispatch,
          portfolioConfigId,
          data.updatePortfolioDashboardConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertPortfolioDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioConfigId: number,
  inputs: graphqlWorkspaceTypes.UpsertPortfolioDashboardConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertPortfolioDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPortfolioDashboardConfigsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPortfolioDashboardConfigs) {
        return handlePortfolioDashboardConfigs(
          dispatch,
          portfolioConfigId,
          data.upsertPortfolioDashboardConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deletePortfolioDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioConfigId: number,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeletePortfolioDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.DeletePortfolioDashboardConfigsMutationVariables =
      {
        inputs: ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePortfolioDashboardConfigs) {
        dispatch(
          portfolioDashboardConfigsActions.removeManyElements(
            data.deletePortfolioDashboardConfigs.map((p) => {
              return {
                portfolioConfigId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
