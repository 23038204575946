import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { useEditDialog } from '~src/components/fields/workspace/entity/use-edit-dialog';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntitiesByWorkspaceId } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

interface Props {
  handleValue: (ts: EntityState[]) => void;
  handlePersist?: () => void;
  entities: EntityState[];
  label?: string;
  workspace: WorkspaceState;
}

export const EditEntities = (props: Props) => {
  const { workspace, label, entities, handlePersist, handleValue } = props;
  const allEntities = useSelector((state: RootState) => {
    return getEntitiesByWorkspaceId(
      selectWorkspaceData(state),
      props.workspace.id,
    );
  });
  const getOptionLabel = React.useCallback(
    (t: EntityState) => {
      const entity = allEntities.find((entity) => entity.id === t.id);
      return entity?.name ? entity.name : '';
    },
    [allEntities],
  );

  const handleNewEntity = React.useCallback(
    (entity: EntityState) => {
      handleValue([...entities, entity]);
    },
    [handleValue, entities],
  );

  const { dialogElement, setOpen, setEntityInput } = useEditDialog({
    handleValue: handleNewEntity,
    workspace: workspace,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setEntityInput({
        id: uuid.v1(),
        name: value,
        description: '',
        sector: '',
        website: '',
        regionAllocationRelations: [],
      });
    },
    [setEntityInput],
  );

  const isOptionEqualToValue = (option: EntityState, value: EntityState) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={true}
      addOption={{ initDialog, setOpen, dialogElement }}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      options={allEntities}
      value={entities}
      handlePersist={handlePersist}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
