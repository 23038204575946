import React from 'react';
import { useDispatch } from 'react-redux';
import { updateAddresses } from '~src/data/store/modules/people/addresses/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { AddressState } from '~src/data/store/reducers/person/addresses/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { AddressDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
  savedCallback: () => void;
  address: AddressState;
}

export const EditAddressDialogComponent = React.memo((props: Props) => {
  const [addressInput, setAddressInput] =
    React.useState<graphqlWorkspaceTypes.UpdateAddressInputType>({
      id: props.address.id,
      personId: props.address.personId,
      administrativeAreaLevel1: props.address.administrativeAreaLevel1,
      administrativeAreaLevel2: props.address.administrativeAreaLevel2,
      locality: props.address.locality,
      postalCode: props.address.postalCode,
      streetAddress: props.address.streetAddress,
      streetNumber: props.address.streetNumber,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveAddress = (): Promise<AddressState> => {
    return dispatch(
      updateAddresses(props.tenantId, props.workspace.id, [addressInput]),
    )
      .then((addresses) => {
        return addresses[0];
      })
      .catch((err) => {
        console.info('FAILED', addressInput, JSON.stringify(err));
        throw err;
      });
  };

  return (
    <AddressDialogComponent
      title={'Edit address'}
      handleSaveAddress={handleSaveAddress}
      addressInput={addressInput}
      setAddressInput={setAddressInput}
      handleClose={props.handleClose}
      savedCallback={props.savedCallback}
      open={props.open}
    />
  );
});
