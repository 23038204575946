import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EntityTabComponent } from '~src/domain/workspace/components/project/portfolio/entities/tab/entity-tab.component';
import { entityTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';

import { EntityTabs } from './tab/entity-tabs.component';
import { useEntityTabs } from './tab/use-entity-tabs';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
}

interface PropsWithEntity extends Props {
  entity: EntityState;
}

const EntityRouterWrapperWithTab = React.memo((props: PropsWithEntity) => {
  const tabs = useEntityTabs(props.entity);
  const { tabId } = useParams<{ tabId: string }>();
  console.log(tabId);

  const tab = tabs.find((t) => t.name === tabId);

  return tab ? (
    <EntityTabComponent
      workspace={props.workspace}
      portfolioProject={props.portfolioProject}
      portfolio={props.portfolio}
      entity={props.entity}
      tab={tab}
      tabs={tabs}
    />
  ) : (
    <div>unknown tab</div>
  );
});

export const EntityRouterWrapper = React.memo((props: Props) => {
  const { entityId } = useParams<{ entityId: string }>();
  const entity = useSelector((state: RootState) =>
    getEntityById(selectWorkspaceData(state), {
      id: entityId,
      workspaceId: props.workspace.id,
    }),
  );

  if (!entity) {
    return <p>Not a valid entity id</p>;
  } else {
    return (
      <Routes>
        <Route
          path="*"
          // exact={true}
          element={
            <Navigate
              to={entityTabPath(
                props.workspace.tenantId,
                props.workspace.id,
                props.portfolioProject.projectId,
                entity.id,
                EntityTabs.Dashboard,
              )}
              replace
            />
          }
        />
        {/* <Route
          path="/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/entities/:entityId"
          exact={true}
        >
          <Redirect
            to={entityTabPath(
              props.workspace.tenantId,
              props.workspace.id,
              props.portfolioProject.projectId,
              entity.id,
              EntityTabs.Dashboard
            )}
          />
        </Route> */}
        <Route
          path={':tabId/*'}
          element={<EntityRouterWrapperWithTab {...props} entity={entity} />}
        />
      </Routes>
    );
  }
});
