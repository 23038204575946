import React from 'react';
import { DataOutput } from '~src/components/xlxs/data-output';

import { HoldingValue } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-view-table.component';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';

interface Props {
  name: string;
  data: HoldingValue[];
}

interface ExportValue {
  projectId: string;
  holdingId: string;
  holdingName: string;
  value: number;
}

const header: Array<keyof ExportValue> = [
  'projectId',
  'holdingId',
  'holdingName',
  'value',
];

export const exportHandler = (
  name: string,
  data: HoldingValue[],
): ExportHandler<ExportValue> => {
  const rows = data.map((value) => {
    return {
      projectId: value.holding.projectId,
      holdingId: value.holding.id,
      holdingName: value.holding.name ?? '',
      value: value.value,
    };
  });
  return {
    type: name,
    data: outputPicker(rows, header),
    header,
  };
};

export const ExportComponent = React.memo((props: Props) => {
  const { name, data } = props;

  const handler = exportHandler(name, data);

  return <DataOutput handlers={[handler]} />;
});
