import { workspaceProjectRootPath } from '../../paths';

export const entitiesRootPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/entities`;
};

export const entityRootPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  entityId: string,
) => {
  return `${entitiesRootPath(tenantId, workspaceId, projectId)}/${entityId}`;
};

export const entityTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  entityId: string,
  tab: string,
) => {
  return `${entityRootPath(tenantId, workspaceId, projectId, entityId)}/${tab}`;
};
