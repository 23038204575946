import { useMenu } from '~src/hooks/utils/use-menu.hook';

import { Menu } from '@mui/material';

export const useElementMenu = (content: JSX.Element[]) => {
  const { anchorEl, handleClose, handleOpen, isOpen } = useMenu();

  const menu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      keepMounted
      open={isOpen}
      onClose={handleClose}
      MenuListProps={{ dense: true }}
    >
      {content}
    </Menu>
  );

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
