import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getCategoryById } from '~src/data/store/selectors/asset/categories/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

interface Props {
  workspaceId: string;
  categoryId: string;
}

export const ViewCategoryField = React.memo((props: Props) => {
  const category = useSelector((state: RootState) =>
    getCategoryById(selectWorkspaceData(state), {
      id: props.categoryId,
      workspaceId: props.workspaceId,
    }),
  );

  return <>{category ? category.name : ''}</>;
});
