import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getVpsAccounts } from '~src/data/store/selectors/data-import/adapter/vps/transactions/selectors';
import { selectDataImportData } from '~src/data/store/selectors/selectors';
import { VpsImportComponent } from '~src/domain/workspace/components/data/adapters/vps/import.component';
// import { VpsImportedTransactionsComponent } from '~src/domain/workspace/components/data/adapters/vps/imported-transactions.component';
import {
  VpsAccount,
  SelectAccount,
} from '~src/domain/workspace/components/project/transaction/importer/adapter/vps/select-account';
import { vpsImporter } from '~src/services/xlsx/handlers/adapters/vps/importer';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

export const VpsTransactionImporterComponent = React.memo((props: Props) => {
  const { workspace } = props;
  const [account, setAccount] = React.useState<VpsAccount>();
  const accountIds = useSelector((state: RootState) =>
    getVpsAccounts(selectDataImportData(state)),
  );

  const accountIdsOptions: VpsAccount[] = React.useMemo(() => {
    return [
      {
        id: null,
        name: 'All',
      },
      ...accountIds.map((account) => {
        return {
          id: account,
          name: account,
        };
      }),
    ];
  }, [accountIds]);

  const importElement = (
    <VpsImportComponent workspace={workspace} dataImporter={vpsImporter()} />
  );

  // const importedTransactionsElement = account && (
  //   <VpsImportedTransactionsComponent
  //     workspace={workspace}
  //     projectId={projectId}
  //     accountId={account.id}
  //   />
  // );

  return (
    <>
      {importElement}
      <SelectAccount
        handleValue={setAccount}
        accounts={accountIdsOptions}
        account={account}
        label={'Account'}
      />
      {/* {importedTransactionsElement} */}
    </>
  );
});
