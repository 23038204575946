import React from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import {
  ColorType,
  useColorIcon,
} from '~src/components/dashboard/use-color-icon';
import { usePopover } from '~src/hooks/utils/use-popover.hook';

import { IconButton, MenuItem, Popover } from '@mui/material';
import { debounce } from 'lodash';

interface Props {
  color: string;
  setColor: (color: string) => void;
  label: string;
  type: ColorType;
}

export const ColorItemComponent = React.memo(
  ({ color, setColor, label, type }: Props) => {
    const { close, open, isOpen, anchorEl, id } = usePopover();
    const icon = useColorIcon(type);

    const onChange = React.useMemo(
      () =>
        debounce((color: string) => {
          return setColor(color);
        }, 100),
      [setColor],
    );

    return (
      <>
        <MenuItem aria-describedby={id} onClick={open}>
          <IconButton disableRipple size={'small'} sx={{ mr: 1.5 }}>
            {icon}
          </IconButton>
          {label}
        </MenuItem>
        <Popover
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={close}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <HexColorPicker color={color} onChange={onChange} />
          <HexColorInput
            color={color}
            onChange={onChange}
            placeholder="Type a color"
            prefixed
          />
        </Popover>
      </>
    );
  },
);
