import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchSignedUrls } from '~src/data/store/modules/obj-storage/objs/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { AppDispatch } from '~src/store/store';

import { ImageList, ImageListItem, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { graphqlObjStorageOperations } from '@pladdenico/obj-storage';
import { ImageComponent } from '~src/components/utils/image/image.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

interface Props {
  files: FileState[];
  deleteFile?: (file: FileState) => void;
  selectFile?: (file: FileState) => void;
  editFile?: (file: FileState) => void;
}

export const HorizontalImages = (props: Props) => {
  const { files, deleteFile, selectFile, editFile } = props;
  const { classes } = useStyles();

  const [urls, setUrls] = React.useState<{ id: string; url: string }[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    const keys = files.map((m) => m.id);
    dispatch(
      fetchSignedUrls(keys, graphqlObjStorageOperations.ObjOperationType.Get),
    ).then((urls) => {
      if (urls) {
        setUrls(urls);
      }
    });
  }, [dispatch, files]);

  const imageElements = React.useMemo(() => {
    return files.map((f) => {
      const url = urls.find((url) => url.id === f.id);
      const actions = {
        deleteFile: deleteFile ? () => deleteFile(f) : undefined,
        selectFile: selectFile ? () => selectFile(f) : undefined,
        editFile: editFile ? () => editFile(f) : undefined,
      };
      return (
        <ImageListItem key={f.id}>
          <ImageComponent file={f} url={url} actions={actions} />
        </ImageListItem>
      );
    });
  }, [deleteFile, editFile, files, selectFile, urls]);

  return (
    <div className={classes.root}>
      <ImageList
        rowHeight={150}
        // columnWidth={200}
        className={classes.imageList}
        cols={Math.floor(Math.min(imageElements.length, 4.5))}
        // sx={{ height: 200 }}
      >
        {imageElements}
      </ImageList>
    </div>
  );
};
