import React from 'react';

import { Box, styled } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  tab: string;
  value: string;
}

const TabPanelDiv = styled('div')(() => ({
  flex: 1,
  flexDirection: 'column',
}));

export function TabPanel(props: TabPanelProps) {
  const { children, value, tab, ...other } = props;

  return (
    <TabPanelDiv
      role="tabpanel"
      style={{ display: value !== tab ? 'none' : 'flex' }}
      // hidden={value !== tab}
      id={`scrollable-auto-tabpanel-${tab}`}
      aria-labelledby={`scrollable-auto-tab-${tab}`}
      {...other}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {children}
      </Box>
    </TabPanelDiv>
  );
}
