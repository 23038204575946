import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
// import {
//   CustomEditor,
//   TextMarkTypes,
// } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/text-editor.component';

import ToggleButton from '@mui/material/ToggleButton';
import {
  CustomEditor,
  TextMarkTypes,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/slate/slate-editor';

const isMarkActive = (editor: CustomEditor, format: TextMarkTypes) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: CustomEditor, format: TextMarkTypes) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

interface Props {
  icon: React.ReactNode;
  format: TextMarkTypes;
  selected: boolean;
  setSelected: (value: boolean) => void;
}

const MarkToggleButton = (props: Props) => {
  const editor = useSlate();
  const { icon, format, selected, setSelected } = props;

  const isActive = isMarkActive(editor, format);

  return (
    <ToggleButton
      size="small"
      value={format}
      selected={isActive}
      onMouseDown={(event) => {
        setSelected(!selected);
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

export const useMarkToggleButton = (
  format: TextMarkTypes,
  icon: React.ReactNode,
) => {
  const [selected, setSelected] = React.useState(false);
  const button = (
    <MarkToggleButton
      format={format}
      icon={icon}
      selected={selected}
      setSelected={setSelected}
    />
  );
  return {
    selected,
    setSelected,
    button,
  };
};
