import React from 'react';

import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  value: string;
  title: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  statistic: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },

  statisticLabel: {
    textTransform: 'uppercase',
  },
}));

export const StatisticComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  return (
    <Grid item xs={6} sm={3} className={classes.statistic}>
      <Typography variant="h4" noWrap>
        {props.value}
      </Typography>
      <Typography component="p" className={classes.statisticLabel}>
        {props.title}
      </Typography>
    </Grid>
  );
});
