import React from 'react';
import { DataOutput } from '~src/components/xlxs/data-output';
import {
  HoldingStateVisitor,
  HoldingVisitable,
} from '~src/data/store/visitors/holding/holding-visitor';
import { ExportState } from '~src/data/store/reducers/workspace/exports/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { exportWorkspaceStore } from '~src/domain/workspace/components/data/workspace/export-workspace-store';
import { exportHandlers as holdingExportHandlers } from '~src/services/xlsx/handlers/workspaces/projects/holdings/export-handlers';
import { fetcher as holdingsFetcher } from '~src/services/xlsx/handlers/workspaces/projects/holdings/fetcher';
import { useExport } from '~src/services/xlsx/hooks/use-export';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  holdingIds: string[] | null;
}

const getHandlers = (
  workspace: WorkspaceState,
  state: ExportState | undefined,
) => {
  if (!state) {
    return undefined;
  }
  const blobObj = JSON.parse(state.blob) as graphqlWorkspaceTypes.Holding[];
  const visitor = new HoldingStateVisitor(
    exportWorkspaceStore.dispatch,
    workspace.tenantId,
    workspace.tenantId,
    [],
  );

  blobObj.forEach((holding) => {
    const visitable = new HoldingVisitable(holding);
    visitable.accept(visitor);
  });

  return holdingExportHandlers(exportWorkspaceStore);
};

export const ExportComponent = React.memo((props: Props) => {
  const { holdingIds } = props;
  const holdingsFetcherWithIds = React.useCallback(
    (workspace: WorkspaceState, fetcherId: string) => {
      return holdingsFetcher(workspace, fetcherId, holdingIds);
    },
    [holdingIds],
  );
  const { handlers, fetcher } = useExport(
    props.workspace,
    holdingsFetcherWithIds,
    getHandlers,
  );
  return <DataOutput getData={fetcher} handlers={handlers} />;
});
