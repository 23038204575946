import { Visitable } from '~src/data/store/visitors/visitable';
import { parseCommitment } from '~src/data/store/modules/holdings/commitments/commitments/parser';
import {
  commitmentsActions,
  CommitmentState,
} from '~src/data/store/reducers/holding/commitment/commitments/reducer';
import { Commitment } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { subscribeToCommitments } from '~src/data/store/modules/holdings/commitments/commitments/subscription';
import { Operation } from '@pladdenico/portfolio-api';

export interface CommitmentVisitor {
  visit(visitable: CommitmentVisitable): void;
  post(): void;
}

export class CommitmentVisitable implements Visitable<CommitmentVisitor> {
  constructor(
    private _holdingId: string,
    private _commitment: Commitment,
  ) {}
  public accept(visitor: CommitmentVisitor) {
    visitor.visit(this);
  }

  public parse(): CommitmentState {
    const commitment = this._commitment;
    return parseCommitment(this._holdingId, commitment);
  }
}

export class CommitmentHandlerVisitor implements CommitmentVisitor {
  constructor(private _handle: (commitment: CommitmentState) => void) {}
  visit(visitable: CommitmentVisitable): void {
    this._handle(visitable.parse());
  }
  post() {
    return;
  }
}

export class CommitmentStateVisitor implements CommitmentVisitor {
  private _commitments: CommitmentState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._commitments = [];
  }

  visit(visitable: CommitmentVisitable): CommitmentState {
    const commitment = visitable.parse();
    this._commitments.push(commitment);
    return commitment;
  }

  post() {
    this._dispatch(
      commitmentsActions.upsertManyElements(this._commitments, {
        shouldAutobatch: true,
      }),
    );
    subscribeToCommitments(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._commitments,
      this._subscriptions,
    );
  }
}
