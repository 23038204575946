import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';

export const rootEventPath =
  '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/events/:eventId';

export const eventsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(tenantId, workspaceId, projectId)}/events`;
};

export const eventBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  eventId: string,
) => {
  return `${eventsBasePath(tenantId, workspaceId, projectId)}/${eventId}`;
};
