import {
  EventStateVisitor,
  EventVisitable,
} from '~src/data/store/visitors/workspace/project/event/event-visitor';
import { Event } from '~src/services/graphql/workspace/client/graphql';

export function handleEvents(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Event[],
) {
  const eventVisitor = new EventStateVisitor(dispatch);

  outputs.forEach((event) => {
    const eventVisitable = new EventVisitable(workspaceId, event);
    eventVisitable.accept(eventVisitor);
  });
  eventVisitor.post();
  return outputs;
}
