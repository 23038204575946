import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { selectors as holdingDashboardConfigsSelectors } from '~src/data/store/reducers/holding/config/dashboard-configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import { RecordState } from '@pladdenico/common';

const getHoldingDashboardConfigState = (state: WorkspaceDataState) =>
  state.holding.holding.dashboardConfigs;

export const selectHoldingDashboardConfigsByHoldingConfigId =
  createCachedSelector(
    (
      state: RecordState<HoldingDashboardConfigState, number>[],
      holdingConfigId: number,
    ) =>
      holdingDashboardConfigsSelectors.selectElementsByKey(
        state,
        holdingConfigId,
      ),
    (
      holdings: HoldingDashboardConfigState[] | undefined,
    ): HoldingDashboardConfigState[] => {
      return holdings ? holdings : [];
    },
  )({
    keySelector: (_state, holdingConfigId) => holdingConfigId,
    selectorCreator: createSelector,
  });

export const getHoldingDashboardConfigsByHoldingConfigId = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingDashboardConfigState(state),
  (_state: WorkspaceDataState, holdingConfigId: number) => holdingConfigId,
  (holdingState, holdingConfigId): HoldingDashboardConfigState[] => {
    return selectHoldingDashboardConfigsByHoldingConfigId(
      holdingState,
      holdingConfigId,
    );
  },
)({
  keySelector: (_state, holdingConfigId) => holdingConfigId,
  selectorCreator: createSelector,
});

export const getHoldingDashboardConfigs = createSelector(
  (state: WorkspaceDataState) => getHoldingDashboardConfigState(state),
  (holdings) => {
    return holdingDashboardConfigsSelectors.selectAllElements(holdings);
  },
);
