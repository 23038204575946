import { parseHoldingDashboardConfig } from '~src/data/store/modules/holdings/configs/dashboard-configs/parser';
import {
  DashboardElementStateVisitor,
  DashboardElementVisitable,
  DashboardElementVisitor,
} from '~src/data/store/visitors/common/dashboard/element-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import { holdingDashboardConfigsActions } from '~src/data/store/reducers/holding/config/dashboard-configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingDashboardConfig } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface HoldingDashboardConfigVisitor {
  visit(holding: HoldingDashboardConfigVisitable): HoldingDashboardConfigState;
  post(): void;
  holdingDashboardElementVisitor: DashboardElementVisitor;
}

export class HoldingDashboardConfigVisitable
  implements Visitable<HoldingDashboardConfigVisitor>
{
  constructor(
    private _holdingDashboardConfigId: number,
    private _holdingDashboardConfig: HoldingDashboardConfig,
  ) {}
  public accept(visitor: HoldingDashboardConfigVisitor) {
    if (this._holdingDashboardConfig.elements) {
      this._holdingDashboardConfig.elements.forEach((dashboardElement) => {
        const holdingDashboardElementVisitable = new DashboardElementVisitable(
          dashboardElement,
        );
        holdingDashboardElementVisitable.accept(
          visitor.holdingDashboardElementVisitor,
        );
      });
    }
    return visitor.visit(this);
  }

  public parse(): HoldingDashboardConfigState {
    const holdingDashboardConfig = this._holdingDashboardConfig;
    return parseHoldingDashboardConfig(
      this._holdingDashboardConfigId,
      holdingDashboardConfig,
    );
  }
}

// export class HoldingDashboardConfigHandlerVisitor
//   implements HoldingDashboardConfigVisitor
// {
//   constructor(
//     private _handle: (holding: HoldingDashboardConfigState) => void,
//     public holdingDashboardElementVisitor: DashboardElementVisitor
//   ) {}
//   public visit(
//     holdingDashboardConfig: HoldingDashboardConfigVisitable
//   ): HoldingDashboardConfigState {
//     const holdingDashboardConfigState = holdingDashboardConfig.parse();
//     this._handle(holdingDashboardConfigState);
//     return holdingDashboardConfigState;
//   }
// }

// export class HoldingDashboardConfigStateVisitor extends HoldingDashboardConfigHandlerVisitor {
//   constructor(
//     dispatch: AppDispatch,
//     tenantId: string,
//     workspaceId: string,
//     subscriptions: Operation[]
//   ) {
//     super(
//       (holdingDashboardConfig) =>
//         dispatch(
//           holdingDashboardConfigsActions.upsertOneElement(
//             holdingDashboardConfig
//           )
//         ),
//       new DashboardElementStateVisitor(
//         dispatch,
//         tenantId,
//         workspaceId,
//         subscriptions
//       )
//     );
//   }
// }

export class HoldingDashboardConfigStateVisitor
  implements HoldingDashboardConfigVisitor
{
  private _holdingDashboardConfigs: HoldingDashboardConfigState[];
  public holdingDashboardElementVisitor: DashboardElementVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._holdingDashboardConfigs = [];
    this.holdingDashboardElementVisitor = new DashboardElementStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }

  public visit(
    holdingDashboardConfig: HoldingDashboardConfigVisitable,
  ): HoldingDashboardConfigState {
    const holdingDashboardConfigState = holdingDashboardConfig.parse();
    this._holdingDashboardConfigs.push(holdingDashboardConfigState);
    return holdingDashboardConfigState;
  }

  post() {
    this._dispatch(
      holdingDashboardConfigsActions.upsertManyElements(
        this._holdingDashboardConfigs,
      ),
    );
    this.holdingDashboardElementVisitor.post();
  }
}
