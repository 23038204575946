import React from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  FormContext,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { initializeBondTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/bond/initialize-transfer-data';
import { initializeFundTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/fund/initialize-transfer-data';
import { initializeStockTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/stock/initialize-transfer-data';
import { TransferTypeData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

import { HoldingType } from '@pladdenico/models';

export const useTransferInputData = (
  workspace: WorkspaceState,
  holding: HoldingState | undefined,
  control: Control<FormValues, FormContext>,
  setValue: UseFormSetValue<FormValues>,
  index: number,
  date: Date,
) => {
  const holdingType = useWatch({
    control,
    name: `holdings.${index}.transferInputData.data.holdingType`,
  });
  const transferBase = useWatch({
    control,
    name: `holdings.${index}.transferInputData.base`,
  });
  const data = useWatch({
    control,
    name: `holdings.${index}.transferInputData.data`,
  });

  React.useEffect(() => {
    if (holding) {
      if (holdingType !== holding.type) {
        let transferData: TransferTypeData;
        if (holding.type === HoldingType.Stock) {
          transferData = initializeStockTransferData(
            workspace,
            holding,
            transferBase.id,
            date,
          );

          if (transferData && data != null) {
            if (
              data.holdingType === HoldingType.Fund ||
              data.holdingType === HoldingType.Bond
            ) {
              transferData.trade.shares = data.trade.shares;
              transferData.trade.sharePrice = data.trade.sharePrice;
            }
          }
        } else if (holding.type === HoldingType.BankNote) {
          transferData = undefined;
        } else if (holding.type === HoldingType.Crypto) {
          transferData = undefined;
        } else if (holding.type === HoldingType.Bond) {
          transferData = initializeBondTransferData(
            workspace,
            holding,
            transferBase.id,
            date,
          );
          if (transferData && data != null) {
            if (
              data.holdingType === HoldingType.Stock ||
              data.holdingType === HoldingType.Fund
            ) {
              transferData.trade.shares = data.trade.shares;
              transferData.trade.sharePrice = data.trade.sharePrice;
            }
          }
        } else if (holding.type === HoldingType.Fund) {
          transferData = initializeFundTransferData(
            workspace,
            holding,
            transferBase.id,
            date,
          );
          if (transferData && data != null) {
            if (
              data.holdingType === HoldingType.Stock ||
              data.holdingType === HoldingType.Bond
            ) {
              transferData.trade.shares = data.trade.shares;
              transferData.trade.sharePrice = data.trade.sharePrice;
            }
          }
        } else if (holding.type === HoldingType.Loan) {
          transferData = undefined;
        } else if (holding.type === HoldingType.PrivateEquityFund) {
          transferData = undefined;
        } else if (holding.type === HoldingType.Warrant) {
          transferData = undefined;
        } else if (holding.type === HoldingType.Unknown) {
          transferData = undefined;
        }
        setValue(`holdings.${index}.transferInputData.data`, transferData);
      }
    }
  }, [
    data,
    date,
    holding,
    holdingType,
    index,
    setValue,
    transferBase,
    workspace,
  ]);
};
