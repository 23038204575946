import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { BankNoteState } from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';
import { useCreateForm } from '~src/domain/workspace/components/asset/types/bank-notes/form/use-create-form';

interface Props {
  workspace: WorkspaceState;
  title: string;
  handleClose: () => void;
  assetForm: AssetForm;
  onFormCallbacks: {
    onSuccess: (bankNote: BankNoteState) => void;
    onFailure: (_error: any) => void;
  };
  createdCallback: (bankNote: BankNoteState) => void;
}

export const BankNoteCreateDialogContentComponent = React.memo(
  (props: Props) => {
    const {
      workspace,
      handleClose,
      createdCallback,
      title,
      assetForm,
      onFormCallbacks,
    } = props;
    const { form, submit } = useCreateForm({
      workspace,
      assetForm,
      onFormCallbacks,
    });
    const handleCreateBankNote = React.useCallback(() => {
      return submit((bankNote) => {
        handleClose();
        createdCallback(bankNote);
        return Promise.resolve();
      });
    }, [createdCallback, handleClose, submit]);

    return (
      <DialogContentComponent
        title={title}
        handleCancel={handleClose}
        handleOnSave={handleCreateBankNote}
      >
        {form}
      </DialogContentComponent>
    );
  },
);
