import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { LoanState } from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';
import { useCreateLoanForm } from '~src/domain/workspace/components/asset/types/loans/form/use-loan-create-form';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  assetForm: AssetForm;
  onFormCallbacks: {
    onSuccess: () => void;
    onFailure: (_error: any) => void;
  };
}
const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const useCreateForm = (props: Props) => {
  const { classes } = useStyles();
  const { workspace, assetForm, onFormCallbacks } = props;

  const loanForm = useCreateLoanForm({
    workspace,
    callbacks: onFormCallbacks,
    inputData: undefined,
  });

  const submit = React.useCallback(
    (successCallback: (loan: LoanState) => Promise<void>) => {
      return assetForm.submit((asset) =>
        loanForm.submit(asset.id, successCallback),
      );
    },
    [assetForm, loanForm],
  );

  const form = React.useMemo(
    () => (
      <form className={classes.container} noValidate autoComplete="off">
        <Grid2 container xs={12} columnSpacing={1}>
          <Grid2 xs={6}>
            {assetForm.fields.nameField}
            {assetForm.fields.typeField}
            {loanForm.fields.interestField}
            {assetForm.fields.typesField}
          </Grid2>
          <Grid2 xs={6}>
            {assetForm.fields.categoryField}
            {assetForm.fields.isinField}
            {assetForm.fields.lookupField}
            {assetForm.fields.vpsField}
          </Grid2>
          <Grid2 xs={12}>
            {assetForm.fields.descriptionField}
            {assetForm.fields.entityField}
          </Grid2>
        </Grid2>
      </form>
    ),
    [
      assetForm.fields.categoryField,
      assetForm.fields.descriptionField,
      assetForm.fields.entityField,
      assetForm.fields.isinField,
      assetForm.fields.lookupField,
      assetForm.fields.nameField,
      assetForm.fields.typeField,
      assetForm.fields.typesField,
      assetForm.fields.vpsField,
      classes.container,
      loanForm.fields.interestField,
    ],
  );
  return {
    form,
    submit,
  };
};
