import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';

import {
  BaseRoleId,
  rolesSelectors,
  RoleState,
} from '../../../reducers/person/roles/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';

const getRoleState = (state: WorkspaceDataState) => state.person.roles;

export const getRolesByPersonId = createCachedSelector(
  (state: WorkspaceDataState) => getRoleState(state),
  (_state: WorkspaceDataState, personId: string | null) => personId,
  (personState, personId): RoleState[] => {
    if (!personId) {
      return [];
    }
    return rolesSelectors.selectElementsByKey(personState, personId) ?? [];
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getRolesByPeopleIds = createCachedSelector(
  (state: WorkspaceDataState) => getRoleState(state),
  (_state: WorkspaceDataState, ids: string[]) => ids,
  (personState, ids): RoleState[] => {
    if (ids == null) {
      return [];
    }
    return rolesSelectors.selectElementsByKeys(personState, ids) ?? [];
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getRoles = createSelector(
  (state: WorkspaceDataState) => getRoleState(state),
  (roles) => {
    return rolesSelectors.selectAllElements(roles);
  },
);

export const getRoleById = createCachedSelector(
  (state: WorkspaceDataState) => getRoleState(state),
  (_state: WorkspaceDataState, baseId: BaseRoleId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseRoleId) => baseId.personId,
  (roles, id, personId): RoleState | undefined => {
    return rolesSelectors.selectElementByT(roles, {
      id,
      personId,
    });
  },
)({
  keySelector: (_state, personBaseId) =>
    `${personBaseId.personId}:${personBaseId.id}`,
  selectorCreator: createSelector,
});
