import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseWorkspaceId {
  id: string;
  tenantId: string;
}

export type WorkspaceState = Omit<
  graphqlWorkspaceTypes.Workspace,
  'projects' | 'tenant'
> & {
  tenantId: string;
};

export function workspaceToWorkspaceState(workspace: WorkspaceState) {
  return workspace;
}
export const initialWorkspacesState: RecordState<WorkspaceState, string>[] = [];

const selectKeyId = (workspace: BaseWorkspaceId) => workspace.tenantId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseWorkspaceId, b: BaseWorkspaceId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseWorkspaceId,
  WorkspaceState,
  string
>(initialWorkspacesState, selectKeyId, keyComparator, tComparator);

export const workspacesSlice = createBaseRecordSlice('workspaces', adapter);
export const workspacesSelectors = adapter.selectors;

export const workspacesReducer = workspacesSlice.reducer;
export const workspacesActions = workspacesSlice.actions;
export const workspacesSlices = workspacesSlice.slices;
