import { Typography } from '@mui/material';
import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { DistributionComponent } from '../distribution';

export const VariableNode = React.memo(({ data, isConnectable }: NodeProps) => {
  return (
    <>
      <Typography variant="caption">{data.label}</Typography>
      <Typography variant="caption">{data.value}</Typography>
      {data.distribution && (
        <DistributionComponent distribution={data.distribution} />
      )}
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
    </>
  );
});
