import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseEntityMediumId {
  id: string;
  entityId: string;
}

export type EntityMediumState = Omit<
  graphqlWorkspaceTypes.EntityMedia,
  'file' | 'entity'
> & {
  fileId: string;
  entityId: string;
};

export const initialState: RecordState<EntityMediumState, string>[] = [];

const selectKeyId = (media: BaseEntityMediumId) => media.entityId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseEntityMediumId, b: BaseEntityMediumId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseEntityMediumId,
  EntityMediumState,
  string
>(initialState, selectKeyId, keyComparator, tComparator);

export const entityMediasSlice = createBaseRecordSlice('entityMedias', adapter);
export const entityMediasSelectors = adapter.selectors;

export const entityMediasReducer = entityMediasSlice.reducer;
export const entityMediasActions = entityMediasSlice.actions;
