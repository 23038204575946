import * as React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EditFormComponent as HoldingEditFormComponent } from '~src/domain/workspace/components/project/holding/form/edit-form.component';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

import { CreateCryptoHoldingFieldsComponent } from './fields.component';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  setPortfolios: (portfolios: PortfolioState[]) => void;
  holdingInput: graphqlWorkspaceOperations.UpdateHoldingInputType;
  setHoldingInput: (
    holdingInput: graphqlWorkspaceOperations.UpdateHoldingInputType,
  ) => void;
  cryptoHoldingInput: graphqlWorkspaceOperations.UpdateCryptoHoldingInputType;
  setCryptoHoldingInput: (
    holdingInput: graphqlWorkspaceOperations.UpdateCryptoHoldingInputType,
  ) => void;
}

export const EditFormComponent = React.memo((props: Props) => {
  const dataFields = (
    <CreateCryptoHoldingFieldsComponent
      workspace={props.workspace}
      setCryptoHoldingInput={props.setCryptoHoldingInput}
      cryptoHoldingInput={props.cryptoHoldingInput}
    />
  );

  return (
    <HoldingEditFormComponent
      workspace={props.workspace}
      projectId={props.projectId}
      holding={props.holding}
      holdingInput={props.holdingInput}
      setHoldingInput={props.setHoldingInput}
      portfolios={props.portfolios}
      setPortfolios={props.setPortfolios}
      holdingTypeFieldsComponent={dataFields}
    />
  );
});
