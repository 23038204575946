import { EntityMediumState } from '~src/data/store/reducers/entity/medias/reducer';
import { EntityMedia } from '~src/services/graphql/workspace/client/graphql';

export function parseEntityMedia(
  entityId: string,
  entityMedia: EntityMedia,
): EntityMediumState {
  return {
    id: entityMedia.id,
    entityId: entityId,
    fileId: entityMedia.file?.id ?? '',
  };
}
