import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { InvestmentState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getInvestmentsByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/investment/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useInvestmentActions } from '~src/hooks/actions/workspace/project/private-equity/investment/use-investment-actions';
import { useInvestmentFields } from '~src/hooks/fields/workspace/project/private-equity/investment/use-investment-fields';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { InvestmentsToolbar } from './investments-toolbar.component';

// import { useInvestmentTableActions } from '~src/hooks/actions/workspace/project/holding/Investments/use-Investment-table-actions';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
}

type InvestmentData = InvestmentState;

const useStyles = makeStyles()((theme: Theme) => ({
  // root: {
  //   padding: theme.spacing(3),
  // },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export const InvestmentsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [data, setData] = React.useState<InvestmentData[]>([]);
  const { onUpdate, onDelete } = useInvestmentActions(
    props.workspace,
    props.privateEquityFund.id,
  );
  const investments = useSelector((state: RootState) =>
    getInvestmentsByFundId(
      selectWorkspaceData(state),
      props.privateEquityFund.id,
    ),
  );

  React.useEffect(() => {
    setData(investments);
  }, [investments]);

  const createInputInit: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType =
    {
      id: uuid.v1(),
      name: '',
      description: '',
      holdingId: '',
      fundId: props.privateEquityFund.id,
      exit: undefined,
      image: undefined,
    };

  const fields = useInvestmentFields(props.workspace, props.project.id);

  const [initialSorters] = React.useState<TableField.Sorter<InvestmentData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<InvestmentData>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        editable={{ onRowUpdate: onUpdate, onRowDelete: onDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        // handleSetUpdateState={setUpdateState}
        isSelected={isSelected}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onDelete,
    onUpdate,
    selectedData,
    sorters,
  ]);

  return (
    <div className={classes.root}>
      <InvestmentsToolbar
        workspace={props.workspace}
        projectId={props.project.id}
        fund={props.privateEquityFund}
        createInputInit={createInputInit}
        fields={fields}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
      />
      <div className={classes.content}>{tableComponent}</div>
    </div>
  );
});
