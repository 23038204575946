import * as React from 'react';
import { useSelector } from 'react-redux';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getRolesByPersonId } from '~src/data/store/selectors/person/role/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CreateRoleDialogComponent } from './form/create-dialog.component';
import { RoleComponent } from './role.component';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
  },
  header: {
    // backgroundColor: colors.blue[100],
  },
  addButton: {
    float: 'right',
    right: theme.spacing(2),
  },
  roleIcon: {
    textAlign: 'center',
  },
}));

export const RolesComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const roles = useSelector((state: RootState) =>
    getRolesByPersonId(selectWorkspaceData(state), props.person.id),
  );
  const [createRoleDialogOpened, setCreateRoleDialogOpened] =
    React.useState(false);

  const roleElements =
    roles &&
    roles.map((role, index) => {
      const divider =
        index < roles.length - 1 ? (
          <Divider variant="middle" component="li" />
        ) : undefined;
      return (
        <Box key={index}>
          <ListItem>
            <RoleComponent
              workspace={props.workspace}
              person={props.person}
              role={role}
            />
          </ListItem>
          {divider}
        </Box>
      );
    });

  function handleAddRole() {
    setCreateRoleDialogOpened(true);
  }
  function handleCloseCreateRoleDialog() {
    setCreateRoleDialogOpened(false);
  }

  return (
    <Paper className={classes.root}>
      <Box>
        <Grid container alignItems="center" className={classes.header}>
          <Grid item xs={10} md={10}>
            <Typography variant="body1" align="center">
              Roles
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label="add"
              onClick={handleAddRole}
              className={classes.addButton}
            >
              <AddIcon />
            </IconButton>
            <CreateRoleDialogComponent
              open={createRoleDialogOpened}
              handleClose={handleCloseCreateRoleDialog}
              tenantId={props.workspace.tenantId}
              workspace={props.workspace}
              person={props.person}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2} md={2} className={classes.roleIcon}>
            <AccountTreeIcon />
          </Grid>
          <Grid item xs={10} md={10}>
            {/* <div>Add role number</div> */}
            <List dense={true}>{roleElements}</List>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
