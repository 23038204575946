import * as React from 'react';
import { useSelector } from 'react-redux';
import { EditWarrant } from '~src/components/fields/workspace/asset/edit/asset-types/edit-warrant';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectFavoriteHoldingByHoldingId } from '~src/data/store/selectors/holding/favorite-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Grid, IconButton, TextField } from '@mui/material';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

import { FavoriteHoldingComponent } from '../../../favorite-holdings/favorite-holding.component';

interface Props {
  workspace: WorkspaceState;
  holding: HoldingState;
  projectId: string;
  holdingInput: graphqlWorkspaceOperations.UpdateHoldingInputType;
  setHoldingInput: (
    holdingInput: graphqlWorkspaceOperations.UpdateHoldingInputType,
  ) => void;
  warrantHoldingInput: graphqlWorkspaceTypes.CreateWarrantHoldingInputType;
  setWarrantHoldingInput: (
    holdingInput: graphqlWorkspaceTypes.CreateWarrantHoldingInputType,
  ) => void;
}

export const EditFormComponent = React.memo((props: Props) => {
  const favorited = useSelector((state: RootState) =>
    selectFavoriteHoldingByHoldingId(selectWorkspaceData(state), {
      projectId: props.projectId,
      holdingId: props.holding.id,
    }),
  );

  const renderFavoriteHoldingButton = (
    handleToggleFavoriteHolding: () => void,
  ) => {
    const icon = favorited ? <FavoriteIcon /> : <FavoriteBorderIcon />;
    return (
      <IconButton aria-label="favorite" onClick={handleToggleFavoriteHolding}>
        {icon}
      </IconButton>
    );
  };

  const handleChange =
    (name: keyof graphqlWorkspaceOperations.UpdateHoldingInputType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.setHoldingInput({
        ...props.holdingInput,
        [name]: event.target.value,
      });
    };

  const handleWarrantChange = (warrantId: string) => {
    props.setWarrantHoldingInput({
      ...props.warrantHoldingInput,
      warrantId,
    });
  };

  return (
    <>
      <Grid>
        <Grid item xs={12}>
          <div>
            <TextField
              margin="normal"
              required
              id="name"
              value={props.holdingInput.name}
              type="text"
              onChange={handleChange('name')}
            />
            <FavoriteHoldingComponent
              tenantId={props.workspace.tenantId}
              workspaceId={props.workspace.id}
              projectId={props.projectId}
              render={renderFavoriteHoldingButton}
              holding={props.holding}
              favorited={favorited}
            />
          </div>
          <TextField
            margin="normal"
            required
            id="description"
            value={props.holdingInput.description}
            type="text"
            onChange={handleChange('description')}
          />
          <EditWarrant
            handleValue={handleWarrantChange}
            warrantId={props.warrantHoldingInput.warrantId}
            workspace={props.workspace}
          />
        </Grid>
      </Grid>
    </>
  );
});
