import { flatten } from 'lodash';
import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { EntityData } from '~src/domain/workspace/components/project/portfolio/entities/entities.component';
import { useExportHoldingDialogComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/use-export-holding-dialog.component';
import { CreateHoldingComponent } from '~src/domain/workspace/components/project/portfolio/holdings/create.component';

import FilterListIcon from '@mui/icons-material/FilterList';
import MoreIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';

// import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from 'tss-react/mui';

import { TableDrawers, TableOperations } from '@pladdenico/table';

const useStyles = makeStyles()((theme: Theme) => ({
  root: { flex: 1 },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    // marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    // marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;

  // fields: EntityFieldsWithDetails;
  entityAlgoData: TableOperations.AlgorithmData<EntityData>;
  // holdingsFields: TableField.Field<HoldingDenormalizedWithEntity>[];
  holdingAlgoData: TableOperations.AlgorithmData<HoldingDenormalizedWithEntity>;

  // filters: TableField.Filter<EntityData>[];
  // dispatchFilters: React.Dispatch<TableActions.FilterAction<EntityData>>;
  // sorters: TableField.Sorter<EntityData>[];
  // dispatchSorters: React.Dispatch<TableActions.SorterAction<EntityData>>;

  // holdingsFilters: TableField.Filter<HoldingDenormalizedWithEntity>[];
  // dispatchHoldingsFilters: React.Dispatch<
  //   TableActions.FilterAction<HoldingDenormalizedWithEntity>
  // >;
  // holdingsSorters: TableField.Sorter<HoldingDenormalizedWithEntity>[];
  // dispatchHoldingsSorters: React.Dispatch<
  //   TableActions.SorterAction<HoldingDenormalizedWithEntity>
  // >;
}

export const Toolbar = (props: Props) => {
  const { entityAlgoData, holdingAlgoData, workspace } = props;
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);
  const menuId = 'entity-menu';

  const { classes } = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [createHoldingDialogOpened, setCreateHoldingDialogOpened] =
    React.useState(false);

  const holdings = React.useMemo(
    () =>
      flatten(
        entityAlgoData.filtered.map((e) => e.holdings.map((h) => h.holding)),
      ),
    [entityAlgoData.filtered],
  );

  const { modal: exportModal, dialog: exportDialog } =
    useExportHoldingDialogComponent(workspace, holdings);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleAddHolding = () => {
    setCreateHoldingDialogOpened(true);
    handleMenuClose();
  };

  function handleCloseCreateHoldingDialog() {
    setCreateHoldingDialogOpened(false);
  }

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const entityMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleAddHolding}>Add holding</MenuItem>
      <MenuItem onClick={exportModal.open}>Export</MenuItem>
      {exportDialog}
    </Menu>
  );

  const filtersComponent = entityAlgoData.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={entityAlgoData.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });

  const holdingsFiltersComponent = holdingAlgoData.filters.map(
    (filter, index) => {
      return (
        <TableDrawers.FilterComponent
          key={index}
          dispatchFilter={holdingAlgoData.dispatchFilters}
          filter={filter}
          textFieldProps={{ sx: { marginBottom: 1 } }}
        />
      );
    },
  );

  const sortersComponent = entityAlgoData.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={entityAlgoData.dispatchSorters}
        sorter={sorter}
      />
    );
  });

  const holdingsSortersComponent = holdingAlgoData.sorters.map(
    (sorter, index) => {
      return (
        <TableDrawers.SorterComponent
          key={index}
          dispatchSorter={holdingAlgoData.dispatchSorters}
          sorter={sorter}
        />
      );
    },
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {/* <IconButton onClick={handlePropertiesDrawerOpen}>
          <ViewListIcon />
        </IconButton> */}
        <span className={classes.spacer} />
        <IconButton onClick={handleFilterDrawerOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton onClick={handleSortDrawerOpen}>
          <SortIcon />
        </IconButton>
        <IconButton onClick={handleMenuOpen} color="inherit">
          <MoreIcon />
        </IconButton>
      </div>
      <CreateHoldingComponent
        workspace={props.workspace}
        project={props.project}
        // portfolio={props.portfolio}
        open={createHoldingDialogOpened}
        handleClose={handleCloseCreateHoldingDialog}
      />
      {entityMenu}
      <TableDrawers.FilterDrawerComponent
        open={filterOpen}
        closeDrawer={() => setFilterOpen(false)}
        filtersComponent={
          <Box>
            <Typography variant="overline">Holding</Typography>
            {holdingsFiltersComponent}
            <Divider />
            <Typography variant="overline">Entity</Typography>
            {filtersComponent}
          </Box>
        }
      />
      <TableDrawers.SorterDrawerComponent
        open={sortOpen}
        closeDrawer={() => setSortOpen(false)}
        sortersComponent={
          <>
            {holdingsSortersComponent}
            {sortersComponent}
          </>
        }
      />
    </div>
  );
};
