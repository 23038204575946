import { Account, AccountAction } from '~src/data/store/actions/account';
import {
  LOGGED_IN,
  LOGGED_OUT,
  RESET,
} from '~src/data/store/modules/account/index';

export const accountInitialState: Account = {
  user: undefined,
  token: undefined,
  // netfondsAccount: undefined,
};

export function accountReducer(
  state: Account = accountInitialState,
  action: AccountAction,
) {
  // console.info('accountReducer', state, action);
  switch (action.type) {
    case LOGGED_IN:
      return {
        user: action.user,
        token: action.token,
        // netfondsAccount: state.netfondsAccount,
      };

    case LOGGED_OUT:
      return {
        user: undefined,
        token: undefined,
        // netfondsAccount: state.netfondsAccount,
      };
    case RESET:
      return {
        user: action.user,
        token: action.token,
        // netfondsAccount: state.netfondsAccount,
      };
    // case GET_NETFONDS_ACCOUNT_SUCCESS:
    //   return {
    //     user: state.user,
    //     token: state.token,
    //     netfondsAccount: action.netfondsAccount,
    //   };

    // case ACCOUNT_ROLE: {
    //   const user = state.user;
    //   if (user && action.role && user.roles) {
    //     const existingRole = user.roles.find((role) => {
    //       return !!action.role && role.id === action.role.id && role.userId === user.id;
    //     });
    //     if (existingRole) {
    //       return state;
    //     }
    //     user.roles.push(action.role);
    //     return {
    //       user,
    //       token: state.token,
    //       // netfondsAccount: state.netfondsAccount,
    //     };
    //   }
    // }
    // case ACCOUNT_DELETED_ROLE: {
    //   const user = state.user;
    //   if (user && action.roleId && user.roles) {
    //     const filteredRoles = user.roles.filter((role) => {
    //       return role.id !== action.roleId;
    //     });
    //     user.roles = filteredRoles;
    //     return {
    //       user,
    //       token: state.token,
    //       // netfondsAccount: state.netfondsAccount,
    //     };
    //   }
    // }
    // case ACCOUNT_DELETE_WORKSPACE_ROLES: {
    //   const user = state.user;
    //   if (user && user.roles) {
    //     const filteredRoles = user.roles.filter((role) => {
    //       return role.workspaceId !== action.workspaceId;
    //     });
    //     user.roles = filteredRoles;
    //     return {
    //       user,
    //       token: state.token,
    //       // netfondsAccount: state.netfondsAccount,
    //     };
    //   }
    // }
    // case ACCOUNT_ROLES: {
    //   const user = state.user;
    //   if (user) {
    //     let roles = user.roles;

    //     if (action.roles) {
    //       action.roles.forEach((role) => {
    //         if (roles) {
    //           const roleIdx: number = roles.findIndex((r) => {
    //             return r.id === role.id;
    //           });
    //           if (roleIdx === -1) {
    //             roles = [...roles, role];
    //           } else {
    //             roles = [...roles.slice(0, roleIdx),
    //               role,
    //               ...roles.slice(roleIdx + 1)];
    //           }
    //         }
    //       });
    //     }
    //     user.roles = roles;
    //     return {
    //       user,
    //       token: state.token,
    //       // netfondsAccount: state.netfondsAccount,
    //     };
    //   }
    // }
    default:
      return state;
  }
}
