import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateFunds } from '~src/data/store/modules/assets/funds/requests';
import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputFundData } from '~src/domain/workspace/components/asset/types/funds/form/fund-input.data';
import { useFundFields } from '~src/domain/workspace/components/asset/types/funds/form/use-fund-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  fund: FundState;
  inputData?: InputFundData;
  callbacks?: {
    onSuccess?: (funds: FundState[]) => void;
    onFailure?: (error: any) => void;
  };
}

export const useEditFundForm = (props: Props) => {
  const { fund, workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputFundData) => {
      return dispatch(
        updateFunds(workspace.tenantId, workspace.id, [
          {
            id: fund.id,
            assetId: data.assetId,
            paperId: data.paperId,
          },
        ]),
      )
        .then((funds) => callbacks?.onSuccess && callbacks.onSuccess(funds))
        .catch((err) => callbacks?.onFailure && callbacks.onFailure(err));
    },
    [dispatch, workspace.tenantId, workspace.id, fund.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<InputFundData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useFundFields(control);

  const onSubmit: SubmitHandler<InputFundData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields };
};
