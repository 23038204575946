import React from 'react';
import {
  Covariance,
  PortfolioAsset,
} from '~src/domain/workspace/components/finance/tools/pareto/covariances';

interface Props {
  covariances: Covariance[];
  portfolioAssets: PortfolioAsset[];
}

export const useCovarianceMatrix = (props: Props) => {
  const { covariances, portfolioAssets } = props;

  const n = portfolioAssets.length;

  const indexLookup = React.useMemo(() => {
    const map = new Map<string, number>();
    for (let i = 0; i < portfolioAssets.length; ++i) {
      const asset = portfolioAssets[i];
      map.set(asset.id, i);
    }
    return map;
  }, [portfolioAssets]);

  const [hessian, setHessian] = React.useState<number[]>(() => {
    const hessian = new Array(n * n);
    for (let i = 0; i < n; ++i) {
      hessian[i + i * n] = Math.pow(portfolioAssets[i].standardDeviation, 2);
    }
    for (let i = 0; i < covariances.length; ++i) {
      const covariance = covariances[i];
      const idxA = indexLookup.get(covariance.portfolioAssetIdA);
      const idxB = indexLookup.get(covariance.portfolioAssetIdB);
      if (idxA != null && idxB != null) {
        hessian[idxA + idxB * n] = covariance.value;
        hessian[idxB + idxA * n] = covariance.value;
      }
    }
    return hessian;
  });

  const handleCovarianceChange = (row: number, col: number, value: number) => {
    setHessian((hessian) => {
      const newHessian = [...hessian];
      newHessian[row + col * n] = value;
      newHessian[col + row * n] = value;
      return newHessian;
    });
  };

  return {
    hessian,
    handleCovarianceChange,
  };
};
