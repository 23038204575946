import moment from 'moment';
import { createSelector } from 'reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import {
  getFilterFor,
  getHoldingShareById,
} from '~src/data/store/selectors/holding/filter/selectors';
import { BaseValuation } from '~src/utils/finance/base-valuation';

import { Filter } from '../../../../../utils/common/filter';
import { getHoldingValuation } from './holding-valuation';

export interface HoldingValuationWithShare {
  valuation: BaseValuation | undefined;
  share: number;
  holding: HoldingState;
}

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsValuation = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    filter,
    useLiveValue,
  ): HoldingValuationWithShare[] => {
    return holdings.map((holding) => {
      const filtered = getFilterFor(state, {
        filter,
        workspaceId,
        projectId,
        holding,
        date,
      });
      if (!filtered) {
        return {
          valuation: undefined,
          share: 0,
          holding,
        };
      }

      const valuation = getHoldingValuation(state, {
        workspaceId,
        holding,
        date,
        currency,
        useLiveValue,
      });
      const share = getHoldingShareById(state, {
        workspaceId,
        projectId,
        holding,
        filter,
        date,
      });
      return {
        valuation,
        share,
        holding,
      };
    });
  },
);
