import numbro from 'numbro';
import * as React from 'react';

import { Typography } from '@mui/material';

import { useStyles } from './styles';

interface Props {
  volatility: number;
  subLabel?: string;
}

export const VolatilityComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { volatility, subLabel } = props;
  const subLabelElement = React.useMemo(() => {
    if (subLabel) {
      return <Typography variant="caption">{subLabel}</Typography>;
    }
  }, [subLabel]);

  return (
    <>
      <div className={classes.statistic}>
        {subLabelElement}
        <Typography variant="h4" component="h4" noWrap>
          {numbro(volatility).format({
            thousandSeparated: true,
            mantissa: 3,
          })}
        </Typography>
        <Typography
          component="p"
          className={classes.statisticLabel}
          variant="body2"
        >
          Volatility (stddev)
        </Typography>
      </div>
    </>
  );
});
