import React from 'react';
import { SearchInput } from '~src/components/search/search-input';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
// import { TransferBaseData } from './form/fields/transfer-data-types';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// import { CreateTransferComponent } from './create.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding?: HoldingState;
  // transferCreateInputInit: TransferBaseData;
}

export const TransfersToolbar = (_props: Props) => {
  // const { className, ...rest } = props;
  // const [createTransferDialogOpened, setCreateTransferDialogeOpened] =
  //   React.useState(false);

  const { classes } = useStyles();

  // const handleAddTransfer = () => {
  //   setCreateTransferDialogeOpened(true);
  // };

  // function handleCloseCreateTransferDialog() {
  //   setCreateTransferDialogeOpened(false);
  // }

  return (
    <div
      // {...rest}
      // className={clsx(classes.root, className)}
      className={classes.root}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        {/* <Button color="primary" variant="contained" onClick={handleAddTransfer}>
          Add transfer
        </Button> */}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search transfer"
        />
      </div>
      {/* <CreateTransferComponent
        workspace={props.workspace}
        projectId={props.projectId}
        holding={props.holding}
        open={createTransferDialogOpened}
        handleClose={handleCloseCreateTransferDialog}
        // transferCreateInputInit={props.transferCreateInputInit}
      /> */}
    </div>
  );
};
