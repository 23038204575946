// import moment from 'moment';
import React from 'react';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { EditHoldingForecastExpressionElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/edit-forecast-expression-element.component';
import { EditForecastFlowComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/edit-forecast-flow-element.component';
import { EditHoldingForecastManualElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/manual/edit-forecast-manual-element.component';
import { ForecastTypes } from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';
import { useHoldingForecastElementType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/use-forecast-type';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useScenarioForecastFieldsComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/use-flow-fields.component';
import { ForecastElementBase } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-element-state';

interface Props {
  forecast: ForecastState;
  setForecast: (forecast: ForecastState) => void;
  // forecastElement: ForecastElementInput | undefined;
  // setForecastElement: (element: ForecastElementInput) => void;
}

export const EditForecastComponent = React.memo((props: Props) => {
  const { forecast, setForecast } = props;
  const { element } = useHoldingForecastElementType(
    forecast,
    setForecast,
    // forecastElement,
    // setForecastElement
  );

  const { nameElement, descriptionElement } =
    useScenarioForecastFieldsComponent(forecast, setForecast);
  // return (
  //   <Grid container>
  //     <Grid xs={6}>{nameElement}</Grid>
  //     <Grid xs={6}>{descriptionElement}</Grid>
  //   </Grid>
  // );

  const setForecastElement = React.useCallback(
    (element: ForecastElementBase) => {
      setForecast({
        ...forecast,
        element,
      });
    },
    [forecast, setForecast],
  );

  let typedElement = null;
  if (forecast.element?.type === ForecastTypes.Expression) {
    typedElement = (
      <EditHoldingForecastExpressionElementComponent
        forecastElement={forecast.element as ExpressionElementState}
        setForecastElement={setForecastElement}
      />
    );
  } else if (forecast.element?.type === ForecastTypes.Manual) {
    typedElement = (
      <EditHoldingForecastManualElementComponent
        forecastElement={forecast.element as ManualElementState}
        setForecastElement={setForecastElement}
      />
    );
  } else if (forecast.element?.type === ForecastTypes.Flow) {
    typedElement = (
      <EditForecastFlowComponent
        forecastElement={forecast.element as FlowElementState}
        setForecastElement={setForecastElement}
      />
    );
  }

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>
        The available forecast subtypes depend on the holding type.
      </Typography>
      {element}
      {nameElement}
      {descriptionElement}
      {typedElement}
    </Box>
  );
});
