import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { deleteProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useProjectReportDuplicateDialogComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/menu/use-duplicate-dialog.component';
import { useProjectReportSendDialogComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/menu/use-send-dialog.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { projectReportBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/report/project-report/paths';
import { AppDispatch } from '~src/store/store';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

export const useProjectReportMenu = (
  workspace: WorkspaceState,
  projectId: string,
  projectReport: ProjectReportState,
) => {
  const [editing, setEditing] = React.useState(false);
  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();
  const { open, isShowing, close } = useVisible();
  const { dialog: sendDialog, modal: sendModal } =
    useProjectReportSendDialogComponent(workspace, [projectReport]);
  const { dialog: duplicateDialog, modal: duplicateModal } =
    useProjectReportDuplicateDialogComponent(
      workspace,
      projectId,
      projectReport,
    );
  const dispatch = useDispatch<AppDispatch>();
  const deleteProjectReport = React.useCallback(() => {
    return dispatch(
      deleteProjectReports(workspace.tenantId, workspace.id, projectId, [
        projectReport.id,
      ]),
    );
  }, [dispatch, projectId, projectReport.id, workspace.id, workspace.tenantId]);
  const navigate = useNavigate();

  const menuElement = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEditing(true);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(
              projectReportBasePath(
                workspace.tenantId,
                workspace.id,
                projectId,
                projectReport.id,
              ),
            );
            handleClose();
          }}
        >
          View
        </MenuItem>
        <MenuItem onClick={open}>Delete</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            duplicateModal.open();
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem onClick={sendModal.open}>Send</MenuItem>
        <ConfirmDialogComponent
          isOpen={isShowing}
          handler={() => {
            deleteProjectReport().then(() => handleClose());
          }}
          close={close}
          objectString="project report"
        />
        {sendDialog}
        {duplicateDialog}
      </Menu>
    ),
    [
      anchorEl,
      isOpen,
      handleClose,
      open,
      sendModal.open,
      isShowing,
      close,
      sendDialog,
      duplicateDialog,
      navigate,
      workspace.tenantId,
      workspace.id,
      projectId,
      projectReport.id,
      duplicateModal,
      deleteProjectReport,
    ],
  );

  const menuButtonElement = React.useMemo(
    () => (
      <IconButton onClick={handleOpen} color="inherit">
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOpen],
  );

  return {
    menuElement,
    menuButtonElement,
    editing,
    setEditing,
    handleOpen,
    handleClose,
  };
};
