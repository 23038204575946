import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

import { strcmp } from '@pladdenico/common';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

export interface BaseFileId {
  id: string;
}

export type FileState = graphqlWorkspaceOperations.File;

export const initialState = [];

const comparator = (a: BaseFileId, b: BaseFileId) => strcmp(a.id, b.id);
const merger = (_a: FileState, b: FileState) => b;

const adapter = new EntityArrayAdapter<BaseFileId, FileState>(
  initialState,
  comparator,
  merger,
);

export const filesSlice = createBaseArraySlice('files', adapter);
export const filesSelectors = adapter.selectors;

export const filesReducer = filesSlice.reducer;
export const filesActions = filesSlice.actions;
