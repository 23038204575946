import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventInput } from '~src/domain/workspace/components/project/event/form/event-input';
import { useEventFieldsComponent } from '~src/domain/workspace/components/project/event/form/use-fields.component';

import { Grid, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  eventInput: EventInput;
  setEventInput: (eventInput: EventInput) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const EventFormComponent = React.memo((props: Props) => {
  const { eventInput, project, setEventInput, workspace } = props;
  const { classes } = useStyles();
  const {
    nameElement,
    descriptionElement,
    dateElement,
    holdingsElement,
    entitiesElement,
    portfoliosElement,
    transactionsElement,
  } = useEventFieldsComponent({
    workspace,
    project,
    eventInput: eventInput,
    setEventInput: setEventInput,
  });

  return (
    <form className={classes.container}>
      <Grid container columnSpacing={1}>
        <Grid item xs={12}>
          {nameElement}
        </Grid>
        <Grid item xs={12}>
          {descriptionElement}
        </Grid>
        <Grid item xs={12}>
          {dateElement}
        </Grid>
        <Grid item xs={6}>
          {holdingsElement}
        </Grid>
        <Grid item xs={6}>
          {entitiesElement}
        </Grid>
        <Grid item xs={6}>
          {portfoliosElement}
        </Grid>
        <Grid item xs={6}>
          {transactionsElement}
        </Grid>
      </Grid>
    </form>
  );
});
