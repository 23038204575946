export const isValidDate = (date: Date) => {
  return !isNaN(date.valueOf());
};

export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const compareDay = (d1: Date, d2: Date) => {
  if (d1.getFullYear() < d2.getFullYear()) {
    return -1;
  } else if (d1.getFullYear() > d2.getFullYear()) {
    return 1;
  } else if (d1.getMonth() < d2.getMonth()) {
    return -1;
  } else if (d1.getMonth() > d2.getMonth()) {
    return 1;
  } else if (d1.getDate() < d2.getDate()) {
    return -1;
  } else if (d1.getDate() > d2.getDate()) {
    return 1;
  }
  return 0;
};

export const msInDay = 1000 * 60 * 60 * 24;
