import { handleStockTrades } from '~src/data/store/modules/holdings/stock/trade/handler';
import { stockTradesActions } from '~src/data/store/reducers/holding/holding-types/stock/stock-trade/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchStockTrades(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetStockTradesDocument;
    const variables: graphqlWorkspaceTypes.GetStockTradesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getStockTrades) {
        return handleStockTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.getStockTrades,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createStockTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateStockTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateStockTradesDocument;
    const variables: graphqlWorkspaceTypes.CreateStockTradesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createStockTrades) {
        return handleStockTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.createStockTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateStockTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateStockTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateStockTradesDocument;
    const variables: graphqlWorkspaceTypes.UpdateStockTradesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateStockTrades) {
        return handleStockTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.updateStockTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertStockTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertStockTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertStockTradesDocument;
    const variables: graphqlWorkspaceTypes.UpsertStockTradesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertStockTrades) {
        return handleStockTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertStockTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteStockTrades(stockHoldingId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      stockTradesActions.removeManyElements(
        ids.map((id) => {
          return {
            stockHoldingId,
            id,
          };
        }),
      ),
    );
  };
}

export function deleteStockTrades(
  tenantId: string,
  workspaceId: string,
  stockHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteStockTradesDocument;
    const variables: graphqlWorkspaceTypes.DeleteStockTradesMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteStockTrades) {
        dispatch(
          handleDeleteStockTrades(stockHoldingId, data.deleteStockTrades),
        );
      }
      return [];
    });
  };
}
