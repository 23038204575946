import numbro from 'numbro';
import React from 'react';

import { Box, Typography } from '@mui/material';

import style from './style.module.css';

interface Props {
  type?: string;
  payload?: any[];
  label?: string;
  active?: boolean;
}

export class ProjectValuationTooltipComponent extends React.PureComponent<Props> {
  public render() {
    const { active } = this.props;

    if (active && this.props.payload && this.props.payload[0]) {
      const name = this.props.payload[0].payload.tooltipName;
      const payload = this.props.payload;
      const values = payload.map((value, index) => {
        return (
          <div key={index}>
            <p
              // className="label"
              style={{ float: 'left' }}
            >{`${value.name}:`}</p>
            <p className="label" style={{ float: 'right' }}>
              {numbro(value.value).format({
                thousandSeparated: true,
                mantissa: 1,
              })}
            </p>
            <div style={{ clear: 'both' }} />
          </div>
        );
      });
      return (
        <Box className={style.tooltip}>
          <Typography variant="h5">{`${name}`}</Typography>
          {values}
        </Box>
      );
    }

    return null;
  }
}
