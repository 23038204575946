import {
  FundTradeStateVisitor,
  FundTradeVisitable,
} from '~src/data/store/visitors/holding/types/fund/fund-trade-visitor';
import { FundTrade } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Operation } from '@pladdenico/portfolio-api';

export function handleFundTrades(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: FundTrade[],
  subscriptions: Operation[],
) {
  const fundTradeVisitor = new FundTradeStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((fundHolding) => {
    const fundHoldingVisitable = new FundTradeVisitable(fundHolding);
    fundHoldingVisitable.accept(fundTradeVisitor);
  });
  fundTradeVisitor.post();
  return outputs;
}
