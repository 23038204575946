import { combineReducers } from 'redux';
import { baseReducer } from '~src/data/base/old/base-array-reducer';
import { GroupState } from '~src/data/store/modules/groups/state';
import { RequestStatus } from '~src/services/request/request-status';

import {
  DELETED_GROUP,
  GROUP,
  GROUP_FETCH_STATUS,
  GROUPS,
} from '../../../modules/groups/action';

const initialGroupState: GroupState[] = [];
const groupsReducer = baseReducer<GroupState>(
  { single: GROUP, multiple: GROUPS, singleDeleted: DELETED_GROUP },
  initialGroupState,
);

const initialGroupsFetchReducer: RequestStatus[] = [];
const groupsFetchReducer = baseReducer<RequestStatus>(
  { single: GROUP_FETCH_STATUS },
  initialGroupsFetchReducer,
);

export const groupInitialState = {
  groups: initialGroupState,
  groupsFetch: initialGroupsFetchReducer,
};

export const groupReducer = combineReducers({
  groups: groupsReducer,
  groupsFetch: groupsFetchReducer,
});
