import React from 'react';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  stockGoalRuleInput: graphqlWorkspaceTypes.CreateStockGoalRuleInputType;
  setStockGoalRuleInput: (
    stockGoalRuleInput: graphqlWorkspaceTypes.CreateStockGoalRuleInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const FormulaFieldComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formula = eventParser(e) as string;
    props.setStockGoalRuleInput({
      ...props.stockGoalRuleInput,
      goalRule: {
        ...props.stockGoalRuleInput.goalRule,
        formula,
      },
    });
  };

  return (
    <>
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="formula"
        value={props.stockGoalRuleInput.goalRule.formula}
        label="Formula"
        type="text"
        onChange={handleChange}
      />
    </>
  );
});
