import React from 'react';
import { UpdateState } from '~src/utils/common/update-state';

export function useUpdateState() {
  const [updateState, setUpdateState] = React.useState<UpdateState>(
    UpdateState.undefined,
  );
  const [updateStateTimeout, setUpdatestateTimeout] = React.useState<
    NodeJS.Timeout | undefined
  >(undefined);

  const handleSetUpdateState = React.useCallback(
    (newUpdateState: UpdateState) => {
      updateStateTimeout && clearTimeout(updateStateTimeout);
      const newUpdateStateTimeout = setTimeout(() => {
        setUpdateState(UpdateState.undefined);
      }, 2500);
      setUpdateState(newUpdateState);
      setUpdatestateTimeout(newUpdateStateTimeout);
      // props.setUpdateState(newUpdateState);
    },
    [updateStateTimeout, setUpdatestateTimeout, setUpdateState],
  );
  return {
    updateState,
    setUpdateState: handleSetUpdateState,
  };
}
