import * as _ from 'lodash';
import Mousetrap from 'mousetrap';
import * as React from 'react';

import {
  ClickAwayListener,
  colors,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Popper,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { SearchInputComponent } from './search-input.component';

interface Props {
  search: (query: string) => Map<string, SearchResult[]>;
}

export interface SearchResult {
  id: number | string;
  title: string;
  description: string;
  onClick: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.modal + 2,
  },

  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: colors.grey[100],
    color: colors.common.black,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '90vh',
    padding: 0,
  },

  listSubHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: colors.common.white,
  },

  listItem: {
    '&:hover': {
      backgroundColor: colors.grey[400],
      color: colors.common.black,
    },
  },

  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export const SearchComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  // const [isLoading, setIsLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState(
    new Map<string, SearchResult[]>(),
  );
  // const [input, setInput] = React.useState<any>(undefined);
  const [input, setInput] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    Mousetrap.bind(
      '/',
      () => {
        console.log('keyup..');
        input && input.focus();
      },
      'keyup',
    );
  }, [input]);

  const { search } = props;
  React.useEffect(() => {
    setTimeout(() => {
      if (query.length === 0) {
        setQuery('');
        // setIsLoading(false);
        // setOpen(false);
        setResults(new Map<string, SearchResult[]>());
      } else {
        const results = search(query);
        // setIsLoading(false);
        // setOpen(true);
        setResults(results);
      }
    }, 300);
  }, [search, query]);

  const handleSearchChange = (event: any) => {
    const query = event.target.value;
    setInput(event.target);
    setQuery(query);
    // setIsLoading(true);
  };

  const handleOpen = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInput(event.currentTarget);
      setOpen(true);
    },
    [setOpen],
  );

  const handleKeyDownResult = (result: SearchResult) => {
    return (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        result.onClick();
        setOpen(false);
      }
    };
  };

  const handleOnClick = (result: SearchResult) => {
    result.onClick();
    setOpen(false);
  };

  const resultsItems: React.ReactElement<unknown>[] = [];
  results.forEach((results, category) => {
    const categoryResultsElements = results.map((result, index) => {
      return (
        <ListItem
          className={classes.listItem}
          button
          key={index}
          onClick={() => handleOnClick(result)}
          onKeyDown={handleKeyDownResult(result)}
        >
          <ListItemText primary={`${result.title}`} />
        </ListItem>
      );
    });
    resultsItems.push(
      <List className={classes.ul} dense key={category}>
        <ListSubheader
          className={classes.listSubHeader}
        >{`${category}`}</ListSubheader>
        {categoryResultsElements}
      </List>,
    );
  });

  const resultsItem = (
    <Popper
      id="customized-menu"
      anchorEl={input}
      open={open}
      placement="bottom"
      className={classes.popper}
    >
      <List className={classes.root}>{resultsItems}</List>
    </Popper>
  );
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <SearchInputComponent
          onChange={_.debounce(handleSearchChange, 500, { leading: true })}
          onOpen={(event) => handleOpen(event)}
          onClose={() => setOpen(false)}
        />
        {resultsItem}
      </div>
    </ClickAwayListener>
  );
});
