import { upsertStockGoalRules } from '~src/data/store/modules/holdings/stock/goal-rules/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'stockGoalRules';

export interface StockGoalRule {
  id: string;
  stockHoldingId: string;
  goalRule: {
    name: string;
    formula: string;
  };
}

type HeaderType = keyof (Omit<StockGoalRule, 'goalRule'> & {
  name: string;
  formula: string;
});

interface StockGoalRuleRow {
  id: string;
  stockHoldingId: string;
  name: string;
  formula: string;
}

const header: HeaderType[] = ['id', 'stockHoldingId', 'name', 'formula'];

export const stockGoalRuleExportHandler = (
  data: StockGoalRule[],
): ExportHandler<StockGoalRuleRow> => {
  const rows = data.map((stockGoalRule) => {
    return {
      id: stockGoalRule.id,
      stockHoldingId: stockGoalRule.stockHoldingId,
      name: stockGoalRule.goalRule.name,
      formula: stockGoalRule.goalRule.formula,
    };
  });
  return {
    type: name,
    data: outputPicker(rows, header),
    header,
  };
};

export const stockGoalRuleImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<StockGoalRuleRow> => {
  return {
    type: name,
    header,
    handler: (ts) => {
      const inputs = ts.map((t): StockGoalRule => {
        return {
          goalRule: {
            name: t.name,
            formula: t.formula,
          },
          id: t.id,
          stockHoldingId: t.stockHoldingId,
        };
      });
      return dispatch(
        upsertStockGoalRules(workspace.tenantId, workspace.id, inputs),
      );
    },
  };
};
