import {
  ValuationStateVisitor,
  ValuationVisitable,
} from '~src/data/store/visitors/holding/valuation-visitor';
import { Valuation } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleValuations(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Valuation[],
  subscriptions: Operation[],
) {
  const valuationVisitor = new ValuationStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  const valuations = outputs.map((valuation) => {
    const valuationVisitable = new ValuationVisitable(
      valuation.holding ? valuation.holding.id : '',
      valuation,
    );
    return valuationVisitable.accept(valuationVisitor);
  });
  valuationVisitor.post();
  return valuations;
}
