import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { File } from '~src/services/graphql/workspace/client/graphql';

export function parseFile(file: File): FileState {
  return {
    id: file.id,
    key: file.key,
    name: file.name,
    type: file.type,
    description: file.description ?? '',
  };
}
