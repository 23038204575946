import React from 'react';
import { EditTypeField } from '~src/components/fields/workspace/holding/type/edit-type-field';
import { IdComponent } from '~src/components/utils/id.component';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { useHandler } from '~src/hooks/utils/use-handler';
import { Identity } from '~src/utils/interfaces/identity';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

type InputTypeT =
  | graphqlWorkspaceTypes.CreateHoldingInputType
  | graphqlWorkspaceTypes.UpdateHoldingInputType;

export interface HoldingData extends Identity {
  holding: HoldingState;
}

interface Props<InputType extends InputTypeT> {
  holdingInput: InputType;
  setHoldingInput: (holdingInput: InputType) => void;
  holdingTypes?: HoldingType[];
  disableTypeField?: boolean;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
  },
}));

export const useFieldsComponent = <InputType extends InputTypeT>(
  props: Props<InputType>,
) => {
  const { classes } = useStyles();

  const { handleEvent, handleUpdate } = useHandler(
    props.holdingInput,
    props.setHoldingInput,
  );
  const idElement = React.useMemo(
    () => <IdComponent id={props.holdingInput.id} setId={handleUpdate('id')} />,
    [handleUpdate, props.holdingInput.id],
  );

  const nameElement = React.useMemo(
    () => (
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.holdingInput.name}
        label="Name"
        type="text"
        onChange={handleEvent('name')}
      />
    ),
    [classes.textField, handleEvent, props.holdingInput.name],
  );
  const descriptionElement = React.useMemo(
    () => (
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="description"
        value={props.holdingInput.description}
        label="Description"
        type="text"
        onChange={handleEvent('description')}
      />
    ),
    [classes.textField, handleEvent, props.holdingInput.description],
  );

  const typeElement = React.useMemo(
    () => (
      <EditTypeField
        label={'type'}
        type={props.holdingInput.type as HoldingType}
        handleValue={handleUpdate('type')}
        holdingTypes={props.holdingTypes}
        disabled={props.disableTypeField}
      />
    ),
    [
      handleUpdate,
      props.disableTypeField,
      props.holdingInput.type,
      props.holdingTypes,
    ],
  );

  return {
    idElement,
    nameElement,
    descriptionElement,
    typeElement,
  };
};
