import React from 'react';
import { useDispatch } from 'react-redux';
import { createFilter } from '~src/data/store/modules/holdings/filter/index';
import { Data } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/data';
import { useAllocationPieChartMenu } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-allocation-pie-chart-menu';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';
import { AppDispatch } from '~src/store/store';
import {
  clearFilter,
  FilterType,
  getFilterSets,
  isIn,
  Filter,
  resetFilter,
  toggleFilter,
} from '~src/utils/common/filter';

import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { sortBy } from 'lodash';

export const usePieChartMenuItems = (
  data: Data[],
  filter: Filter,
  filterKey: string,
  showFilteredOut: boolean,
  setShowFilteredOut: (value: boolean) => void,
) => {
  // useComponentUpdateDebug(`usePieChartMenuItems ${data.length}`, {
  //   data,
  //   filter,
  //   filterKey,
  //   showFilteredOut,
  //   setShowFilteredOut,
  // });
  const dispatch = useDispatch<AppDispatch>();

  const onItemClick = React.useCallback(
    (propertyFilter: { key: string; value: string }) => {
      console.log(propertyFilter);
      toggleFilter(filter, propertyFilter);
      dispatch(createFilter(0, filter));
    },
    [filter, dispatch],
  );

  const filterList = React.useMemo(() => {
    return sortBy(data, [(d) => d.id]);
  }, [data]);

  const renderFilterList = React.useCallback(() => {
    const listItems = filterList.map((d) => {
      const propertyFilter = { key: filterKey, value: d.id };
      const checked = isIn(filter, propertyFilter);
      const labelId = `pie-chart-label-${d.id}`;
      return (
        <ListItem
          key={d.id}
          role={undefined}
          dense
          button
          onClick={() => onItemClick(propertyFilter)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              size="small"
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={d.name} />
        </ListItem>
      );
    });
    return (
      // <List className={style.FilterList} dense>
      <List dense>{listItems}</List>
    );
  }, [onItemClick, filterList, filter, filterKey]);

  const handleClearFilter = React.useCallback(() => {
    clearFilter(filter, filterKey, FilterType.include);
    clearFilter(filter, filterKey, FilterType.exclude);
    dispatch(createFilter(0, filter));
  }, [dispatch, filter, filterKey]);

  const handleResetFilter = React.useCallback(() => {
    resetFilter(filter);
    dispatch(createFilter(0, filter));
  }, [dispatch, filter]);

  const toggleShowFilteredOut = React.useCallback(() => {
    setShowFilteredOut(!showFilteredOut);
  }, [showFilteredOut, setShowFilteredOut]);

  const activeFilter = React.useMemo(() => {
    const sets = getFilterSets(filter, filterKey);
    let activeFilter = false;
    if (sets) {
      activeFilter = sets.exclude.size > 0 || sets.include.size > 0;
    }
    return activeFilter;
  }, [filter, filterKey]);
  const menuItems = useAllocationPieChartMenu(
    handleClearFilter,
    handleResetFilter,
    toggleShowFilteredOut,
    renderFilterList,
    filterKey,
    showFilteredOut,
    activeFilter,
  );

  return menuItems;
};
