import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceGraphQLBaseName = 'graph-ql';

export const workspaceGraphQLRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(
    tenantId,
    workspaceId,
  )}/${workspaceGraphQLBaseName}`;
};

export const workspaceGraphQLBasePath = workspaceGraphQLRootPath(
  tenantBaseId,
  workspaceBaseId,
);
