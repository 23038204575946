import { RecordState } from '@pladdenico/common';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { WorkspaceDataState } from '../../../reducers/reducers';
import {
  BaseEmailId,
  EmailState,
  emailsSelectors,
} from '../../../reducers/person/emails/reducer';

const getEmailState = (state: WorkspaceDataState) => state.person.emails;

export const selectEmailsByPersonId = createCachedSelector(
  (state: RecordState<EmailState, string>[], personId: string) =>
    emailsSelectors.selectElementsByKey(state, personId),
  (emails: EmailState[] | undefined): EmailState[] => {
    return emails ? emails : [];
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getEmailsByPersonId = createCachedSelector(
  (state: WorkspaceDataState) => getEmailState(state),
  (_state: WorkspaceDataState, personId: string | null) => personId,
  (personState, personId): EmailState[] => {
    if (!personId) {
      return [];
    }
    return selectEmailsByPersonId(personState, personId);
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getEmails = createSelector(
  (state: WorkspaceDataState) => getEmailState(state),
  (emails) => {
    return emailsSelectors.selectAllElements(emails);
  },
);

export const getEmailById = createCachedSelector(
  (state: WorkspaceDataState) => getEmailState(state),
  (_state: WorkspaceDataState, baseId: BaseEmailId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseEmailId) => baseId.personId,
  (emails, id, personId): EmailState | undefined => {
    return emailsSelectors.selectElementByT(emails, {
      id,
      personId,
    });
  },
)({
  keySelector: (_state, personBaseId) =>
    `${personBaseId.personId}:${personBaseId.id}`,
  selectorCreator: createSelector,
});
