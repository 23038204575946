import numbro from 'numbro';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Data } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/data';

import { makeStyles } from 'tss-react/mui';
// import { colorLinesAndPoints } from '@pladdenico/common';

import { AllocationPieChartTooltipComponent } from './allocation-pie-chart-tooltip.component';
import { colorPalette as colorsPieChart } from '~src/utils/common/colors/chart-colors';

interface Props {
  data: Data[];
  clickOnArea: any;
  getLabelName: (index: number) => string;
  getItem: (index: number) => Data;
  getColorIndex: (id: string) => number;
  cx: number;
  outerRadius: number;
}

const preventContextMenu = (event: any) => {
  console.log(event);
  event.preventDefault();
};

const useStyles = makeStyles()(() => ({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
}));

const renderCustomizedLabel =
  (getItem: (index: number) => Data) =>
  ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    if (percent < 0.0) {
      return <></>;
    } else if (percent >= 0.15) {
      return renderCustomizedLabel1({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
        getItem,
      });
    } else {
      return renderCustomizedLabel2({
        cx,
        cy,
        midAngle,
        outerRadius,
        percent,
        index,
        getItem,
      });
    }
  };

const renderCustomizedLabel1 = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  getItem,
}: any) => {
  const name = getItem(index).name;
  const value = getItem(index).value;
  // console.log(index, percent);
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fontSize={12}
      fill="#FFF"
      // textAnchor={x > cx ? 'start' : 'end'}
      textAnchor={'middle'}
      dominantBaseline="central"
    >
      <tspan>{name}</tspan>
      <tspan x={x} dy="14">{`${numbro(value).format({
        thousandSeparated: true,
        mantissa: 2,
      })}`}</tspan>
      <tspan x={x} dy="14">{`(${(percent * 100).toFixed(0)}%)`}</tspan>
    </text>
  );
};

const renderCustomizedLabel2 = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  index,
  getItem,
}: any) => {
  const name = getItem(index).name;
  const value = getItem(index).value;
  const fill = '#AAAAAA';
  const RADIAN = Math.PI / 180;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 11;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fontSize={12}
        fill={fill}
      >
        {name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={12}
        textAnchor={textAnchor}
        fontSize={12}
        fill="#AAAAAA"
      >
        {`${numbro(value).format({
          thousandSeparated: true,
          mantissa: 2,
        })} (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export const AllocationPieChartComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const totalValue = props.data.reduce((totalValue, data) => {
    return totalValue + data.value;
  }, 0);

  const cellElements = props.data.map((value, index) => {
    const colorIndex = props.getColorIndex(value.id); // value.index ? value.index : index;
    const stroke =
      props.data.length === 1
        ? colorsPieChart[colorIndex % colorsPieChart.length]
        : '#fff';
    const fill = value.filteredOut
      ? '#8884d8'
      : colorsPieChart[colorIndex % colorsPieChart.length];
    // const stroke =
    //   props.data.length === 1
    //     ? colorLinesAndPoints[colorIndex % colorLinesAndPoints.length]
    //     : '#fff';
    // const fill = value.filteredOut
    //   ? '#8884d8'
    //   : colorLinesAndPoints[colorIndex % colorLinesAndPoints.length];
    return (
      <Cell
        key={index}
        fill={fill}
        stroke={stroke}
        style={{ outline: 'none' }}
      />
    );
  });

  const pieChart = (
    <PieChart>
      <Pie
        data={props.data}
        dataKey={'value'}
        cx={`${props.cx}%`}
        cy={'50%'}
        outerRadius={`${props.outerRadius}%`}
        // outerRadius={'70%'}
        fill="#8884d8"
        isAnimationActive={false}
        labelLine={false}
        label={renderCustomizedLabel(props.getItem) as any}
        onMouseUp={props.clickOnArea}
      >
        {cellElements}
      </Pie>
      <Tooltip
        content={<AllocationPieChartTooltipComponent totalValue={totalValue} />}
      />
    </PieChart>
  );

  return (
    // <PieChart width={1000} height={550}>
    // <div onContextMenu={preventContextMenu}>
    <div onContextMenu={preventContextMenu} className={classes.container}>
      <ResponsiveContainer
        minWidth={undefined}
        minHeight={undefined}
        // width="100%"
        // height="100%"
        // height={350}
      >
        {pieChart}
      </ResponsiveContainer>
    </div>
  );
});
