import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { TransferState } from '~src/data/store/reducers/workspace/projects/scenario/transfers/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getHoldingsTransfers } from '~src/data/store/selectors/finance/transfers/holdings-transfers';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { getHoldingsByIdsForProject } from '~src/data/store/selectors/holding/holdings/selectors';
import {
  selectData,
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getTransfersByBudgetIdAndHoldingIds } from '~src/data/store/selectors/workspace/projects/scenario/transfers/selectors';
import { CashFlowChartComponent } from '~src/domain/workspace/components/project/scenario/charts/cash-flow-chart.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { CashFlow } from '~src/domain/workspace/components/project/scenario/models/cash-flow';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { transferSign } from '~src/utils/finance/transfer-sign';
import { Config } from '~src/utils/interfaces/config';

import { strcmp } from '@pladdenico/common';
import { HoldingType, TransferType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  budget: Budget;
  config: Config;
  useLiveValue: boolean;
}

const transferToCashFlow = (transfer: TransferState): CashFlow => {
  const sign = transferSign(transfer.type as TransferType);
  const value = transfer.value ?? 0;
  return {
    holdingId: transfer.holdingId,
    date: moment(transfer.date),
    transferType: transfer.type,
    value: sign * value,
    description: 'description',
    name: 'name',
  };
};

export const HoldingScenarioCashFlowComponent = React.memo((props: Props) => {
  const { workspace, project, budget, config, useLiveValue } = props;

  // const { config: holdingConfig, setup } = useScenarioConfig({
  //   scenario,
  //   workspace,
  // });

  const baseCurrencyId = useSelector((state: RootState) =>
    getSelectedCurrencyState(selectFinancialData(state)),
  );
  const baseCurrency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), {
      id: baseCurrencyId,
    }),
  );
  const currency = baseCurrency ?? defaultCurrency;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const holdings = useSelector((state: RootState) => {
    const holdingIds = budget?.holdingIds ?? [];
    const hs = getHoldingsByIdsForProject(selectWorkspaceData(state), {
      projectId: project.id,
      ids: holdingIds,
    });
    return hs.sort((a, b) => strcmp(a.id, b.id));
  });

  const bankNoteHoldings = React.useMemo(() => {
    return holdings.filter((h) => h.type === HoldingType.BankNote);
  }, [holdings]);

  const transfers = useSelector((state: RootState) =>
    getHoldingsTransfers(selectData(state), {
      workspaceId: workspace.id,
      projectId: project.id,
      filter,
      holdings: bankNoteHoldings,
      currency,
      date: config.date,
      startDate: config.period.start,
      endDate: config.period.end,
      useLiveValue,
    }),
  );

  const allTransfers = React.useMemo((): TransferState[] => {
    return [
      ...transfers.map((t): TransferState => {
        return {
          ...t,
          value: t.value ?? 0,
          budgetId: budget?.id ?? '',
        };
      }),
    ];
  }, [budget?.id, transfers]);

  const cashFlowsFromTransfers = React.useMemo(
    () => allTransfers.map((transfer) => transferToCashFlow(transfer)),
    [allTransfers],
  );

  const budgetTransfers = useSelector((state: RootState) =>
    getTransfersByBudgetIdAndHoldingIds(selectWorkspaceData(state), {
      budgetId: budget.id,
      holdingIds: budget.holdingIds,
    }),
  );

  // const budgetTransfers = useSelector((state: RootState) =>
  //   getTransfersByBudgetId(selectWorkspaceData(state), budget.id)
  // );
  // const cashFlowTransfers = React.useMemo(() => {
  //   return budgetTransfers?.filter(
  //     (t) =>
  //       t.type === TransferType.Deposit || t.type === TransferType.Withdrawal
  //   );
  // }, [budgetTransfers]);
  const transferCashFlows = budgetTransfers?.map((transfer): CashFlow => {
    return {
      date: transfer.date,
      holdingId: transfer.holdingId,
      transferType: transfer.type,
      value: transfer.value,
    };
  });
  const cashFlowsBudgeted = [
    ...cashFlowsFromTransfers,
    ...(transferCashFlows ?? []),
  ];
  cashFlowsBudgeted.sort((a, b) => a.date.unix() - b.date.unix());
  // const cashFlowsElement = cashFlowsBudgeted.map((source, idx) => {
  //   return (
  //     <div key={idx}>
  //       {source.date.format('DD.MM.YYYY')} {source.transferType}{' '}
  //       {source.value * transferSign(source.transferType)}
  //     </div>
  //   );
  // });
  // const cashElement = <>{cashFlowsElement}</>;

  const container = (
    <>
      <CashFlowChartComponent
        workspace={workspace}
        projectId={project.id}
        cashFlows={cashFlowsBudgeted}
      />
      {/* {cashElement} */}
    </>
  );
  return container;
});
