import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingConfig } from '~src/services/graphql/workspace/client/graphql';

export function parseHoldingConfig(
  holdingId: string,
  config: HoldingConfig,
): HoldingConfigState {
  return {
    currentDashboardConfigId: config.currentDashboardConfigId
      ? config.currentDashboardConfigId
      : null,
    id: config.id,
    holdingDashboardConfigIds: config.holdingDashboardConfigs
      ? config.holdingDashboardConfigs.map((c) => c.id)
      : [],
    holdingId,
  };
}
