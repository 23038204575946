import {
  PrivateEquityFundStateVisitor,
  PrivateEquityFundVisitable,
} from '~src/data/store/visitors/asset/types/private-equity-fund/private-equity-fund-visitor';
import { PrivateEquityFund } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handlePrivateEquityFunds(
  workspaceId: string,
  dispatch: AppDispatch,
  privateEquityFunds: PrivateEquityFund[],
) {
  const visitor = new PrivateEquityFundStateVisitor(dispatch);
  const res = privateEquityFunds.map((privateEquityFund) => {
    const assetVisitable = new PrivateEquityFundVisitable(
      workspaceId,
      privateEquityFund,
    );
    return assetVisitable.accept(visitor);
  });
  visitor.post();
  return res;
}
