import { isEqual } from 'lodash';
import './grid.css';

// import _ from 'lodash';
import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as uuid from 'uuid';
import { deepLodashPick } from '~src/utils/common/deep-pick';

type Cols = { [P: string]: number } | undefined;

const cols: Cols = {
  lg: 12,
  md: 10,
  sm: 6,
  xs: 4,
  xxs: 2,
};

const rowHeight = 30;

export function saveToLS(key: string, value: any) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({
        [key]: value,
      }),
    );
  }
}

export function getFromLS(key: string) {
  let ls = undefined;
  if (global.localStorage) {
    try {
      const item = global.localStorage.getItem('rgl-8');
      if (item != null) {
        ls = JSON.parse(item) || {};
      }
    } catch (e) {
      /*Ignore*/
    }
  }
  if (ls) {
    return ls[key];
  }
}

interface Props {
  isResizable: boolean;
  compactType: CompactType;
  createGridElements: () => JSX.Element[];
  existingLayouts: ReactGridLayout.Layouts | undefined;
  onNewItem: () => Promise<{ id: string }>;
  saveLayouts: (
    layouts: ReactGridLayout.Layouts,
  ) => Promise<ReactGridLayout.Layouts | undefined>;
  droppableElement: boolean;
  showDebugInfo?: boolean;
}

export type CompactType = 'vertical' | 'horizontal' | null;

export const DashboardGrid = (props: Props) => {
  const {
    isResizable,
    compactType,
    createGridElements,
    existingLayouts,
    onNewItem,
    saveLayouts,
    droppableElement,
    showDebugInfo,
  } = props;
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState('lg');
  // const [compactType, setCompactType] = React.useState<
  //   'vertical' | 'horizontal' | null
  // >('vertical');
  const [mounted, setMounted] = React.useState(false);
  const [layouts, setLayouts] = React.useState<
    ReactGridLayout.Layouts | undefined
  >(() => {
    return existingLayouts;
  });

  React.useEffect(() => {
    setLayouts(existingLayouts);
  }, [existingLayouts]);

  const ResponsiveReactGridLayout = React.useMemo(
    () => WidthProvider(Responsive),
    [],
  );

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const onBreakpointChange = React.useCallback((breakpoint: string) => {
    setCurrentBreakpoint(breakpoint);
  }, []);

  const [dragging, setDragging] = React.useState(false);

  const onLayoutChange = React.useCallback(
    (
      _currentLayout: ReactGridLayout.Layout[],
      allLayouts: ReactGridLayout.Layouts,
    ) => {
      // console.log(
      //   'onLayoutChange',
      //   allLayouts,
      //   layouts,
      //   currentBreakpoint,
      //   currentLayout,
      // );

      const compactedLayouts = deepLodashPick(
        allLayouts,
        (_key, value) => value !== undefined,
      );
      if (!dragging && !isEqual(layouts, compactedLayouts)) {
        saveLayouts(compactedLayouts);
        setLayouts(compactedLayouts);
      }
    },
    [dragging, layouts, saveLayouts],
  );
  // onLayoutChange;

  const onNewLayout = React.useCallback(() => {
    setLayouts({});
  }, []);

  const onDrop = React.useCallback(
    async (layout: ReactGridLayout.Layout[], layoutItem: any, _event: any) => {
      console.log('onDrop', layout, layoutItem);
      if (layoutItem == null) {
        return;
      }
      const newLayout = layout.filter((item) => {
        return item.i !== '__dropping-elem__';
      });
      // const itemType = event.dataTransfer.getData(
      //   'application/react-grid-item'
      // ) as string;

      const item = await onNewItem();

      const newItem = {
        i: item.id,
        h: layoutItem.h,
        w: layoutItem.w,
        x: layoutItem.x,
        y: layoutItem.y,
      };
      newLayout.push(newItem);
      setLayouts((layouts) => {
        const newLayouts = {
          ...layouts,
          [currentBreakpoint]: [...newLayout],
        };
        console.log('newLayouts', newLayouts);
        return newLayouts;
      });
      console.log(layout, layoutItem, newLayout);
    },
    [currentBreakpoint, onNewItem],
  );

  const getDroppingItem = React.useCallback(() => {
    return { i: uuid.v1(), w: 2, h: 4 };
  }, []);

  const droppableNewElement = React.useMemo(() => {
    if (droppableElement) {
      return (
        <div
          className="droppable-element"
          draggable={true}
          // unselectable="on"
          // this is a hack for firefox
          // Firefox requires some kind of initialization
          // which we can do by adding this attribute
          // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
          onDragStart={(event) => {
            event.dataTransfer.setData('text/plain', '');
            // event.dataTransfer.setData('application/react-grid-item', 'header');
            setDragging(true);
          }}
          onDragEnd={(_e) => {
            setDragging(false);
          }}
        >
          Droppable Element (Drag me!)
        </div>
      );
    }
  }, [droppableElement]);

  const debugInformation = React.useMemo(() => {
    return (
      <div>
        <div>
          Current Breakpoint: {currentBreakpoint} ({cols[currentBreakpoint]}
          columns)
        </div>
        {/* <div>
          Compaction type:{' '}
          {_.capitalize(compactType ?? undefined) || 'No Compaction'}
        </div> */}
        <button onClick={onNewLayout}>Generate New Layout</button>
        {/* <button onClick={onCompactTypeChange}>Change Compaction Type</button> */}
      </div>
    );
  }, [currentBreakpoint, onNewLayout]);

  return (
    <div>
      {showDebugInfo && debugInformation}
      {droppableNewElement}
      <ResponsiveReactGridLayout
        // {...props}
        className={'layout'}
        cols={cols}
        draggableHandle=".dashboard-element-drag-handle"
        rowHeight={rowHeight}
        layouts={layouts}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        onDrop={onDrop}
        // onDropDragOver={droppedItem}
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
        isDroppable={true}
        isResizable={isResizable}
        droppingItem={getDroppingItem()}
      >
        {createGridElements()}
      </ResponsiveReactGridLayout>
    </div>
  );
};
