import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { CreateCommitmentFormComponent } from './form/create-form.component';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  open: boolean;
  handleClose: () => void;
  holding?: HoldingState;
}

export const CreateCommitmentComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <CreateCommitmentFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        handleClose={props.handleClose}
        holding={props.holding}
      />
    </Dialog>
  );
});
