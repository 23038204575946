import { RecordState } from '@pladdenico/common';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseTransactionId,
  transactionsSelectors,
  TransactionState,
} from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

const selectTransactionState = (state: WorkspaceDataState) =>
  state.holding.holding.transaction.transactions;

export const getTransactions = createSelector(
  (state: WorkspaceDataState) =>
    transactionsSelectors.selectAllElements(selectTransactionState(state)),
  (transactions): TransactionState[] => {
    return transactions;
  },
);

export const getTransactionById = createCachedSelector(
  (state: WorkspaceDataState) => selectTransactionState(state),
  (_state: WorkspaceDataState, baseId: BaseTransactionId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseTransactionId) => baseId.projectId,
  (transactions, id, projectId): TransactionState | undefined => {
    return transactionsSelectors.selectElementByT(transactions, {
      id: id,
      projectId,
    });
  },
)({
  keySelector: (_state, projectId, holdingId) => `${projectId}:${holdingId}`,
  selectorCreator: createSelector,
});

interface ProjectMultipleIds {
  projectId: string;
  ids: string[];
}

export const getTransactionsByIds = createCachedSelector(
  (state: WorkspaceDataState) => selectTransactionState(state),
  (_state: WorkspaceDataState, ids: ProjectMultipleIds) => ids.projectId,
  (_state: WorkspaceDataState, ids: ProjectMultipleIds) => ids.ids,
  (transactions, projectId, ids): TransactionState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        projectId,
      };
    });
    return transactionsSelectors.selectElementsByTs(transactions, baseIds);
  },
)({
  keySelector: (_state, ids) => `${ids.projectId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

export const selectTransactionsByProjectId = createCachedSelector(
  (state: RecordState<TransactionState, string>[], projectId: string) =>
    transactionsSelectors.selectElementsByKey(state, projectId),
  (transactions: TransactionState[] | undefined): TransactionState[] => {
    return transactions ? transactions : [];
  },
)({
  keySelector: (_state, projectId) => projectId,
  selectorCreator: createSelector,
});

export const getTransactionsByProjectId = createCachedSelector(
  (state: WorkspaceDataState) => selectTransactionState(state),
  (_state: WorkspaceDataState, projectId: string) => projectId,
  (transactionState, projectId): TransactionState[] => {
    return selectTransactionsByProjectId(transactionState, projectId);
  },
)({
  keySelector: (_state, projectId) => projectId,
  selectorCreator: createSelector,
});

export const getTransactionsByProjectIds = createCachedSelector(
  (state: WorkspaceDataState) => selectTransactionState(state),
  (_state: WorkspaceDataState, projectIds: string[]) => projectIds,
  (transactionState, projectIds: string[]): TransactionState[] => {
    return projectIds.reduce<TransactionState[]>((prev, curr) => {
      const transactions = selectTransactionsByProjectId(
        transactionState,
        curr,
      );
      return [...prev, ...transactions];
    }, []);
  },
)({
  keySelector: (_state, projectIds) => projectIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
