import React from 'react';
import { useSelector } from 'react-redux';
import { FundHoldingState } from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getFundHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/fund/fund-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { FundPositionsComponent } from '~src/domain/workspace/components/project/holding/holding-types/fund-holdings/fund-positions/fund-positions.component';
import { HoldingTabs } from '~src/domain/workspace/components/project/portfolio/holdings/tab/holding-tabs';
import {
  PropsTab,
  UseHoldingTabsProps,
  useHoldingTabs,
} from '~src/domain/workspace/components/project/portfolio/holdings/tab/use-holding-tabs';

interface PropsWithFundHolding extends UseHoldingTabsProps {
  fundHolding: FundHoldingState;
}

export const FundHoldingTabsComponent = React.memo(
  (props: UseHoldingTabsProps) => {
    const { holding } = props;
    const fundHolding = useSelector((state: RootState) =>
      getFundHoldingByHoldingId(selectWorkspaceData(state), holding.id),
    );
    if (!fundHolding) {
      return <div>No fund holding for holding {holding.name}</div>;
    }
    return (
      <FundHoldingTabsWithFundHoldingComponent
        {...props}
        fundHolding={fundHolding}
      />
    );
  },
);

const FundHoldingTabsWithFundHoldingComponent = React.memo(
  (props: PropsWithFundHolding) => {
    const {
      fundHolding,
      holding,
      portfolio,
      portfolioProject,
      project,
      tab,
      tabs,
      workspace,
    } = props;
    const specificTab = React.useCallback(
      (props: PropsTab) => {
        if (props.tabName === HoldingTabs.Positions) {
          return (
            <FundPositionsComponent
              workspace={workspace}
              projectId={portfolioProject.projectId}
              holding={holding}
              fundHolding={fundHolding}
            />
          );
        }
      },
      [holding, portfolioProject.projectId, fundHolding, workspace],
    );
    return useHoldingTabs(
      workspace,
      portfolioProject,
      project,
      portfolio,
      holding,
      tabs,
      tab,
      specificTab,
    );
  },
);
