import { handleCapitalCalls } from '~src/data/store/modules/assets/private-equity-fund/capital-calls/handler';
import { capitalCallsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/capital-calls/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchCapitalCalls(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPrivateEquityFundCapitalCallsDocument;
    const variables: graphqlWorkspaceTypes.GetPrivateEquityFundCapitalCallsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPrivateEquityFundCapitalCalls) {
        return handleCapitalCalls(
          workspaceId,
          fundId,
          dispatch,
          data.getPrivateEquityFundCapitalCalls,
        );
      }
      return [];
    });
  };
}

export function createCapitalCalls(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallInputType[],
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallsDocument;
    const variables: graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPrivateEquityFundCapitalCalls) {
        return handleCapitalCalls(
          workspaceId,
          fundId,
          dispatch,
          data.createPrivateEquityFundCapitalCalls,
        );
      }
      return [];
    });
  };
}

export function updateCapitalCalls(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.UpdatePrivateEquityFundCapitalCallInputType[],
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.UpdatePrivateEquityFundCapitalCallsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePrivateEquityFundCapitalCallsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePrivateEquityFundCapitalCalls) {
        return handleCapitalCalls(
          workspaceId,
          fundId,
          dispatch,
          data.updatePrivateEquityFundCapitalCalls,
        );
      }
      return [];
    });
  };
}

export function upsertCapitalCalls(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.UpsertPrivateEquityFundCapitalCallInputType[],
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.UpsertPrivateEquityFundCapitalCallsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPrivateEquityFundCapitalCallsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPrivateEquityFundCapitalCalls) {
        return handleCapitalCalls(
          workspaceId,
          fundId,
          dispatch,
          data.upsertPrivateEquityFundCapitalCalls,
        );
      }
      return [];
    });
  };
}

export function deleteCapitalCalls(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  capitalCalls: Array<{ id: string; fundId: string }>,
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.DeletePrivateEquityFundCapitalCallsDocument;
    capitalCalls.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeletePrivateEquityFundCapitalCallInputType[] =
      capitalCalls.map((privateEquityFund) => {
        return {
          id: privateEquityFund.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeletePrivateEquityFundCapitalCallsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePrivateEquityFundCapitalCalls) {
        const deletedCapitalCalls = intersectionSorted(
          capitalCalls,
          data.deletePrivateEquityFundCapitalCalls,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, fundId };
          },
        );
        return dispatch(
          capitalCallsActions.removeManyElements(deletedCapitalCalls),
        );
      }
      return [];
    });
  };
}
