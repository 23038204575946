import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '~src/data/store/IStore';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPortfoliosByHoldingId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { BankNoteHoldingComponent } from '~src/domain/workspace/components/project/holding/holding-types/bank-note-holdings/bank-note-holding.component';
import { BondHoldingComponent } from '~src/domain/workspace/components/project/holding/holding-types/bond-holdings/bond-holding.component';
import { FundHoldingComponent } from '~src/domain/workspace/components/project/holding/holding-types/fund-holdings/fund-holding.component';
import { LoanHoldingComponent } from '~src/domain/workspace/components/project/holding/holding-types/loan-holdings/loan-holding.component';
import { StockHoldingComponent } from '~src/domain/workspace/components/project/holding/holding-types/stock-holdings/stock-holding.component';
import { Config } from '~src/utils/interfaces/config';

import { HoldingType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

export const DashboardDescriptionComponent = React.memo((props: Props) => {
  const { holding, workspace, project } = props;

  const portfolios = useSelector((state: IStore) =>
    getPortfoliosByHoldingId(selectWorkspaceData(state), {
      id: holding.id,
      workspaceId: workspace.id,
    }),
  );

  switch (holding.type) {
    case HoldingType.BankNote:
      return (
        <BankNoteHoldingComponent
          holding={holding}
          portfolios={portfolios}
          projectId={project.id}
          workspace={workspace}
        />
      );
    case HoldingType.Bond:
      return (
        <BondHoldingComponent
          holding={holding}
          portfolios={portfolios}
          projectId={project.id}
          workspace={workspace}
        />
      );
    case HoldingType.Stock:
      return (
        <StockHoldingComponent
          holding={holding}
          portfolios={portfolios}
          projectId={project.id}
          workspace={workspace}
        />
      );
    case HoldingType.Loan:
      return (
        <LoanHoldingComponent
          holding={holding}
          portfolios={portfolios}
          projectId={project.id}
          workspace={workspace}
        />
      );

    case HoldingType.Fund:
      return (
        <FundHoldingComponent
          holding={holding}
          portfolios={portfolios}
          projectId={project.id}
          workspace={workspace}
        />
      );

    default:
      return <div>Unknown</div>;
  }
});
