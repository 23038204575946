import * as React from 'react';
import { useSelector } from 'react-redux';
// import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCommitmentsByHoldingIds } from '~src/data/store/selectors/holding/commitment/commitments/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useCommitmentsAlgorithm } from '~src/domain/workspace/components/project/commitment/algorithm/use-algorithm';
import { CommitmentCardsListComponent } from '~src/domain/workspace/components/project/commitment/card/commitment-cards-list.component';
import { useUpdateState } from '~src/utils/common/use-update-state';
// import { Period } from '~src/utils/period/period';

import { Box, Grid } from '@mui/material';

import { CommitmentsToolbar } from './commitments-toolbar.component';
import { CommitmentData } from './commitment-data';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  // date: moment.Moment;
  // period: Period;
  // baseCurrency: CurrencyState;
}

export const HoldingsCommitmentsComponent = React.memo((props: Props) => {
  const { holdings, project, workspace } = props;
  const holdingIds = React.useMemo(() => holdings.map((h) => h.id), [holdings]);
  const commitments = useSelector((state: RootState) =>
    getCommitmentsByHoldingIds(selectWorkspaceData(state), holdingIds),
  );

  const data: CommitmentData[] = React.useMemo(() => {
    return commitments.map((commitment) => {
      return { id: commitment.id, commitment };
    });
  }, [commitments]);
  const algo = useCommitmentsAlgorithm(workspace, project, data);
  const updateStateData = useUpdateState();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ml: 1,
        mt: 1,
        // p: 1,
        // pt: 3,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={9}
          sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
          <CommitmentsToolbar
            workspace={workspace}
            projectId={project.id}
            algoData={algo.algoData}
          />
          <CommitmentCardsListComponent
            workspace={workspace}
            projectId={project.id}
            // portfolios={portfolios}
            data={algo.algoData.filtered}
            setUpdateState={updateStateData.setUpdateState}
          />
        </Grid>
      </Grid>
    </Box>
    // <div className={classes.root}>
    //   <CommitmentsToolbar workspace={workspace} projectId={project.id} />
    //   <div className={classes.content}>
    //     <CommitmentCardsListComponent
    //       workspace={workspace}
    //       projectId={project.id}
    //       data={algo.algoData.filtered}
    //       setUpdateState={updateStateData.setUpdateState}
    //     />
    //   </div>
    // </div>
  );
});
