import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EntityRegionalComponent } from '~src/domain/workspace/components/entity/region-allocations/allocations-regional-map.component';
import { workspaceEntityRootPath } from '~src/navigation/paths/workspace/entity/paths';

import LinkIcon from '@mui/icons-material/Link';
import { IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  entity: EntityState;
}

export const useEntity = (props: Props) => {
  const { entity, workspace } = props;

  const navigate = useNavigate();

  const linkElement = (
    <IconButton
      onClick={() =>
        navigate(
          workspaceEntityRootPath(workspace.tenantId, workspace.id, entity.id),
        )
      }
    >
      <LinkIcon />
    </IconButton>
  );

  const contentElement = (
    <>
      <Grid2 xs={12}>
        <Typography variant="caption">Description</Typography>
        <Typography variant="body1">{entity.description}</Typography>
      </Grid2>
      <Grid2 xs={12}>
        <Typography variant="caption">Website</Typography>
        <Typography variant="body1">{entity.website}</Typography>
      </Grid2>
      <Grid2 xs={12}>
        <Typography variant="caption">Sector</Typography>
        <Typography variant="body1">{entity.sector}</Typography>
      </Grid2>
    </>
  );

  const regionalElement = <EntityRegionalComponent entity={entity} />;
  return {
    linkElement,
    contentElement,
    regionalElement,
  };
};
