import { handleFundPositions } from '~src/data/store/modules/holdings/fund/position/handler';
import { fundPositionsActions } from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchFundPositions(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetFundPositionsDocument;
    const variables: graphqlWorkspaceTypes.GetFundPositionsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getFundPositions) {
        return handleFundPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.getFundPositions,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createFundPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateFundPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateFundPositionsDocument;
    const variables: graphqlWorkspaceTypes.CreateFundPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createFundPositions) {
        return handleFundPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.createFundPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateFundPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateFundPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateFundPositionsDocument;
    const variables: graphqlWorkspaceTypes.UpdateFundPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateFundPositions) {
        return handleFundPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.updateFundPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertFundPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertFundPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertFundPositionsDocument;
    const variables: graphqlWorkspaceTypes.UpsertFundPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertFundPositions) {
        return handleFundPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertFundPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteFundPositions(
  fundHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      fundPositionsActions.removeManyElements(
        ids.map((id) => {
          return {
            fundHoldingId,
            id,
          };
        }),
      ),
    );
  };
}

export function deleteFundPositions(
  tenantId: string,
  workspaceId: string,
  fundHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteFundPositionsDocument;
    const variables: graphqlWorkspaceTypes.DeleteFundPositionsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteFundPositions) {
        dispatch(
          handleDeleteFundPositions(fundHoldingId, data.deleteFundPositions),
        );
      }
      return [];
    });
  };
}
