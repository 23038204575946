import { TransactionCardData } from '~src/domain/workspace/components/project/transaction/card/cards-list.component';

import { TableField } from '@pladdenico/table';
import { compact } from 'lodash';

interface Config {
  useHolding?: boolean;
}

function createHoldingField(): TableField.Field<TransactionCardData> {
  return {
    id: 'holding',
    name: 'holding',
    field: 'holding',
    type: TableField.FieldTypes.numeric,
    iteratee: (data) =>
      compact(data.transfers.map((t) => t.holding?.holding.name)),
  };
}

export function useFields(
  config?: Config,
): TableField.Field<TransactionCardData>[] {
  const holdingField = config?.useHolding ? createHoldingField() : undefined;
  const fields: TableField.Field<TransactionCardData>[] = compact([
    {
      id: 'id',
      name: 'id',
      field: 'id',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.transaction.transaction.id,
    },
    {
      id: 'title',
      name: 'title',
      field: 'title',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.transaction.transaction.title,
    },
    {
      id: 'date',
      name: 'date',
      field: 'date',
      type: TableField.FieldTypes.date,
      iteratee: (data) => data.transfers.map((t) => t.transfer.date),
    },
    {
      id: 'cost',
      name: 'cost',
      field: 'cost',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => compact(data.transfers.map((t) => t.transfer.cost)),
    },
    {
      id: 'type',
      name: 'type',
      field: 'type',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.transfers.map((t) => t.transfer.type),
    },
    {
      id: 'subtype',
      name: 'subtype',
      field: 'subtype',
      type: TableField.FieldTypes.string,
      iteratee: (data) =>
        compact(data.transfers.map((t) => t.transfer.subtype)),
    },
    {
      id: 'value',
      name: 'value',
      field: 'value',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => compact(data.transfers.map((t) => t.transfer.value)),
    },
    holdingField,
  ]);
  return fields;
}
