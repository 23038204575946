import * as uuid from 'uuid';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
// import { HoldingConfigListener } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-input-data';
import { getHoldingTypes } from '~src/utils/finance/transfer-to-holding-types';

import { TransferType } from '@pladdenico/models';

export const getHoldingConfigWithdrawal = (
  holdingId?: string,
  transferTypes?: TransferType[],
): HoldingConfig => {
  const transferType = TransferType.Withdrawal;
  const possibleTransferTypes = transferTypes ?? [transferType];
  return {
    id: uuid.v1(),
    transferType,
    component: {
      name: 'Paying holding',
      deleteIcon: false,
      useValuation: true,
      possibleHoldingTypes: getHoldingTypes(transferType),
      possibleTransferTypes,
    },
    holdingId,
    // listeners,
  };
};
