import { PortfolioAsset } from '~src/domain/workspace/components/finance/tools/pareto/covariances';

export const ParetoFrontierChartTooltip = ({
  active,
  payload,
  assets,
}: {
  active?: boolean;
  payload?: any[];
  assets: PortfolioAsset[];
}) => {
  if (active && payload && payload.length && assets) {
    const data = payload[0].payload;
    const weights = [];
    for (let i = 0; i < assets.length; ++i) {
      weights.push(
        <p key={assets[i].id}>
          {assets[i].name}: {data.weights[i]}
        </p>,
      );
    }

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '5px',
          border: '1px solid #ccc',
        }}
      >
        <p>Expected Return: {data.expectedReturn}</p>
        <p>Volatility: {data.volatility}</p>
        {/* <p>Weights: {data.weights.join(', ')}</p> */}
        {weights}
      </div>
    );
  }

  return null;
};
