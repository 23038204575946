import moment from 'moment';
import createCachedSelector from 're-reselect';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { HoldingGroup } from '~src/data/store/selectors/finance/holding-groups';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';

import {
  getCurrencyState,
  selectCurrencyById,
} from '../../finance/currencies/selectors';
import { getCurrencyIdByHoldingId } from '../valuation/valuations/selectors';
import { createSelector } from 'reselect';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { AssetCategoryState } from '~src/data/store/reducers/asset/categories/reducer';
import { RecordState } from '@pladdenico/common';
import {
  getRegionHoldingProperties,
  selectRegionHoldingProperties,
} from './region-holding-properties';
import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';
import {
  getAssetCategoryHoldingProperties,
  selectAssetCategoryHoldingProperties,
} from './asset-category-holding-properties';
import {
  getAssetHoldingProperties,
  selectAssetHoldingProperties,
} from './asset-holding-properties';
import {
  getEntityHoldingProperties,
  selectEntityHoldingProperties,
} from './entity-holding-properties';

interface CurrencyByHoldingIdProps {
  holding: HoldingState;
  date: moment.Moment;
}

export const getCurrencyByHoldingId = createCachedSelector(
  (state: DataState, props: CurrencyByHoldingIdProps) =>
    getCurrencyIdByHoldingId(selectWorkspaceDataFromData(state), {
      holdingId: props.holding.id,
      date: props.date,
    }),
  (state: DataState, _props: CurrencyByHoldingIdProps) =>
    getCurrencyState(selectFinancialDataFromData(state)),
  (currencyId, currencyState) => {
    if (currencyId) {
      return selectCurrencyById(currencyState, { id: currencyId });
    }
  },
)({
  keySelector: (_state, props) => `:${props.holding.id}:${props.date.unix()}`,
  selectorCreator: createSelector,
});

export const getCurrencyHoldingProperties = createCachedSelector(
  (state: DataState, props: CurrencyByHoldingIdProps) =>
    getCurrencyByHoldingId(state, {
      holding: props.holding,
      date: props.date,
    }),
  (_state: DataState, props: CurrencyByHoldingIdProps) => props.holding.id,
  (currency, _holdingId): HoldingGroup[] => {
    if (currency) {
      return [
        {
          id: currency.id,
          name: currency.symbol,
          share: 1.0,
        },
      ];
    }
    // const unknownId = `unknown-${holdingId}`;
    const unknownId = 'unknown';
    return [{ id: unknownId, name: unknownId, share: 1.0 }];
  },
)({
  keySelector: (_state, props) => `${props.holding.id}:${props.date.unix()}`,
  selectorCreator: createSelector,
});

export interface RegionByHoldingIdProps {
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
}

interface HoldingPropertiesProps {
  filterKey: string;
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
  date: moment.Moment;
}

export const getHoldingProperties = createCachedSelector(
  (state: DataState, _props: HoldingPropertiesProps) => state,
  (_state: DataState, props: HoldingPropertiesProps) => props.filterKey,
  (_state: DataState, props: HoldingPropertiesProps) => props.workspaceId,
  (_state: DataState, props: HoldingPropertiesProps) => props.projectId,
  (_state: DataState, props: HoldingPropertiesProps) => props.holding,
  (_state: DataState, props: HoldingPropertiesProps) => props.date,
  (state, filterKey, workspaceId, projectId, holding, date): HoldingGroup[] => {
    if (filterKey === 'id') {
      return [
        {
          id: holding.id,
          name: holding.name ?? '',
          share: 1.0,
        },
      ];
    } else if (filterKey === 'currency') {
      return getCurrencyHoldingProperties(state, { holding, date });
    } else if (filterKey === 'region') {
      return getRegionHoldingProperties(state, {
        holding,
        workspaceId,
        projectId,
      });
    } else if (filterKey === 'category') {
      return getAssetCategoryHoldingProperties(state, {
        holding,
        workspaceId,
        projectId,
      });
    } else if ((filterKey as keyof AssetState) && filterKey !== 'sector') {
      return getAssetHoldingProperties(state, {
        holding,
        workspaceId,
        projectId,
        filterKey: filterKey as keyof AssetState,
      });
    } else if (filterKey as keyof EntityState) {
      return getEntityHoldingProperties(state, {
        holding,
        workspaceId,
        projectId,
        filterKey: filterKey as keyof EntityState,
      });
    }
    return [{ id: 'unknown', name: 'unknown', share: 1.0 }];
  },
)({
  keySelector: (_state, props) =>
    `${props.filterKey}:${props.holding.id}:${props.date.unix()}`,
  selectorCreator: createSelector,
});

export const selectHoldingProperties = (
  filterKey: string,
  workspaceId: string,
  holding: HoldingDenormalizedWithEntity,
  date: moment.Moment,
  categories: AssetCategoryState[],
  regions: RegionState[],
  regionAllocations: RegionAllocationState[],
  currencies: RecordState<CurrencyState, string>[],
): HoldingGroup[] => {
  if (filterKey === 'id') {
    return [
      {
        id: holding.id,
        name: holding.holding.name ?? '',
        share: 1.0,
      },
    ];
  } else if (filterKey === 'currency') {
    const currencyId = holding.valuation?.currencyId;
    if (currencyId != null) {
      const currency = selectCurrencyById(currencies, { id: currencyId });
      if (currency) {
        return [
          {
            id: currency.id,
            name: currency.symbol,
            share: 1.0,
          },
        ];
      }
    }
    const unknownId = 'unknown';
    return [{ id: unknownId, name: unknownId, share: 1.0 }];
  } else if (filterKey === 'region') {
    if (holding.entity) {
      return selectRegionHoldingProperties(
        regions,
        regionAllocations,
        holding.entity.id,
      );
    }
  } else if (filterKey === 'category') {
    if (holding.asset) {
      return selectAssetCategoryHoldingProperties(
        categories,
        workspaceId,
        holding.asset.categoryId,
      );
    }
  } else if ((filterKey as keyof AssetState) && filterKey !== 'sector') {
    if (holding.asset) {
      return selectAssetHoldingProperties(
        holding.asset,
        filterKey as keyof AssetState,
      );
    }
  } else if (filterKey as keyof EntityState) {
    if (holding.entity) {
      return selectEntityHoldingProperties(
        holding.entity,
        filterKey as keyof EntityState,
      );
    }
  }
  return [{ id: 'unknown', name: 'unknown', share: 1.0 }];
};

interface MapperProps {
  workspaceId: string;
  projectId: string;
  date: moment.Moment;
}

type Mapper = (key: string, holding: HoldingState) => HoldingGroup[];

export const getMapper = createCachedSelector(
  (state: DataState, _props: MapperProps) => state,
  (_state: DataState, props: MapperProps) => props.workspaceId,
  (_state: DataState, props: MapperProps) => props.projectId,
  (_state: DataState, props: MapperProps) => props.date,
  (state, workspaceId, projectId, date): Mapper => {
    return (filterKey: string, holding: HoldingState) => {
      return getHoldingProperties(state, {
        filterKey,
        workspaceId,
        projectId,
        holding,
        date,
      });
    };
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}-${props.projectId}-${props.date.unix()}`,
  selectorCreator: createSelector,
});
