import React from 'react';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { workspaceEntityRootPath } from '~src/navigation/paths/workspace/entity/paths';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  FileRelation,
  isDashboardRelation,
  isEntityRelation,
} from '~src/domain/workspace/components/file/file-relation';
import { File } from '~src/services/graphql/workspace/client/graphql';

const Overlay = styled(Button)(() => ({
  position: 'absolute',
  top: '25%',
  // background: 'rgba(255, 255, 255, 0.1)',
  color: '#f1f1f1',
  // width: '100%',
  transition: '.5s ease',
  opacity: 0,
  fontSize: '20px',
  padding: '20px',
  textAlign: 'center',
  zIndex: 10,
}));

interface Props {
  workspace: WorkspaceState;
  file: File;
  relations: FileRelation[];
  // url: { id: string; url: string } | undefined;
  deleteFile?: (file: FileState) => void;
  downloadFile?: (file: FileState) => void;
  editFile?: (file: FileState) => void;
}

interface FileListItemContainerProps extends React.PropsWithChildren<unknown> {
  selectFile?: () => void;
}

export const FileListItemContainerComponent = (
  props: FileListItemContainerProps,
) => {
  const [overlayStyle, setOverlayStyle] = React.useState({ opacity: 0 });
  const useElement = props.selectFile && (
    <Overlay style={{ ...overlayStyle }} variant="outlined">
      Use
    </Overlay>
  );
  return (
    <Box
      sx={{
        position: 'relative',
        justifyContent: 'flex-start',
        display: 'flex',
        height: '100%',
      }}
      onMouseEnter={(_e) => {
        setOverlayStyle({ opacity: 1 });
      }}
      onMouseLeave={(_e) => {
        setOverlayStyle({ opacity: 0 });
      }}
      onClick={(e) => {
        props.selectFile && props.selectFile();
        e.stopPropagation();
      }}
    >
      {useElement}
      {props.children}
    </Box>
  );
};

export const WorkspaceFileComponent = (props: Props) => {
  const { file, editFile, downloadFile, deleteFile, workspace, relations } =
    props;

  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();
  const deleteModal = useVisible();

  const deleteElement = React.useMemo(() => {
    if (deleteFile) {
      return (
        <MenuItem
          onClick={() => {
            deleteModal.open();
            handleClose();
          }}
        >
          Delete file
          <IconButton aria-label={`star ${file.name}`}>
            <DeleteIcon />
          </IconButton>
        </MenuItem>
      );
    }
  }, [deleteFile, file.name, deleteModal, handleClose]);
  const confirmElement = React.useMemo(() => {
    if (deleteFile) {
      return (
        <ConfirmDialogComponent
          isOpen={deleteModal.isShowing}
          handler={() => {
            deleteFile(file);
            deleteModal.close();
          }}
          close={deleteModal.close}
          objectString="file"
        />
      );
    }
  }, [deleteFile, deleteModal, file]);

  const menuElement = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
      >
        {editFile ? (
          <MenuItem onClick={() => editFile(file)}>Edit</MenuItem>
        ) : undefined}
        {downloadFile ? (
          <MenuItem onClick={() => downloadFile(file)}>Download</MenuItem>
        ) : undefined}
        {deleteElement}
        {confirmElement}
      </Menu>
    ),
    [
      anchorEl,
      isOpen,
      handleClose,
      deleteElement,
      confirmElement,
      editFile,
      file,
      downloadFile,
    ],
  );

  const menuButtonElement = React.useMemo(
    () => (
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOpen],
  );

  const navigate = useNavigate();

  const relationsElement = React.useMemo(() => {
    return relations.map((relation) => {
      if (relation) {
        if (isEntityRelation(relation)) {
          return (
            <>
              <Chip
                variant="outlined"
                size="small"
                avatar={<Avatar>E</Avatar>}
                label={relation.item.name}
                onClick={() =>
                  navigate(
                    workspaceEntityRootPath(
                      workspace.tenantId,
                      workspace.id,
                      relation.item.id,
                    ),
                  )
                }
              />
            </>
          );
        } else if (isDashboardRelation(relation)) {
          return (
            <>
              <Chip
                variant="outlined"
                size="small"
                avatar={<Avatar>D</Avatar>}
                label={relation.item.elementId}
              />
            </>
          );
        }
      }
    });
  }, [navigate, relations, workspace.id, workspace.tenantId]);

  return (
    <>
      <FileListItemContainerComponent>
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Typography variant="body1">{file.name}</Typography>
              <Typography variant="caption">{file.description}</Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {relationsElement}
          </Grid>
          <Grid item xs={1}>
            {menuButtonElement}
          </Grid>
        </Grid>
        {menuElement}
      </FileListItemContainerComponent>
    </>
  );
};
