import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { IndexedState } from '~src/data/base/indexed/indexed-entity-adapter';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  BaseWarrantHoldingId,
  warrantHoldingsSelectors,
  WarrantHoldingState,
} from '../../../../../reducers/holding/holding-types/warrant/warrant-holding/reducer';

const getWarrantHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.warrantHolding.warrantHoldings;

export const selectWarrantHoldingByHoldingId = createCachedSelector(
  (
    state: IndexedState<BaseWarrantHoldingId, WarrantHoldingState>,
    holdingId: string,
  ) => warrantHoldingsSelectors.selectElementByIdx(state, holdingId),
  (warrantHolding): WarrantHoldingState | undefined => {
    return warrantHolding;
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getWarrantHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getWarrantHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (warrantHoldingState, holdingId): WarrantHoldingState | undefined => {
    return selectWarrantHoldingByHoldingId(warrantHoldingState, holdingId);
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getWarrantHoldings = createSelector(
  (state: WorkspaceDataState) => getWarrantHoldingState(state),
  (warrantHoldings) => {
    return warrantHoldingsSelectors.selectAllElements(warrantHoldings);
  },
);

export const getWarrantHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getWarrantHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (warrantHoldings, id): WarrantHoldingState | undefined => {
    return warrantHoldingsSelectors.selectElementByI(warrantHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getWarrantHoldingsByWarrantId = createCachedSelector(
  (state: WorkspaceDataState) => getWarrantHoldings(state),
  (_state: WorkspaceDataState, warrantId: string) => warrantId,
  (warrantHoldings, warrantId): WarrantHoldingState[] => {
    return warrantHoldings.filter((h) => h.warrantId === warrantId);
  },
)({
  keySelector: (_state, warrantId) => `${warrantId}`,
  selectorCreator: createSelector,
});
