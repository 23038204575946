import React from 'react';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Box, Grid } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  projectReport: ProjectReportState;
}

export const ProjectReportSharingComponent = React.memo((_props: Props) => {
  return (
    <Box>
      <Grid container>
        <Grid item sx={{ ml: 1 }} xs={6}>
          Users
        </Grid>
      </Grid>
    </Box>
  );
});
