export enum RequestStatuses {
  requesting,
  failed,
  success,
  done,
}

export interface RequestStatus {
  requestStatus: RequestStatuses;
  err?: any;
  id: string;
}
