import React from 'react';
import { CreateWorkspaceComponent } from '~src/domain/workspace/components/workspace/create-workspace.component';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';

import { Box, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getWorkspacesByTenantId } from '~src/data/store/selectors/workspace/workspaces/selectors';
import { WorkspaceCardComponent } from '~src/domain/workspace/components/workspace/list/workspace-card.component';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  tenantId: string;
}

export const WorkspacesComponent = React.memo((props: Props) => {
  const { tenantId } = props;
  const workspaces = useSelector((state: RootState) =>
    getWorkspacesByTenantId(selectWorkspaceData(state), props.tenantId),
  );

  const workspaceElements = React.useMemo(() => {
    return workspaces.map((workspace) => {
      return (
        <Grid2 key={workspace.id} xs={4} sx={{ display: 'flex' }}>
          <Paper square sx={{ flex: 1 }}>
            <WorkspaceCardComponent workspace={workspace} />
          </Paper>
        </Grid2>
      );
    });
  }, [workspaces]);

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      sx={{ backgroundColor: primaryBackgroundColor.veryLight }}
    >
      <Grid2 container m={1} rowSpacing={2} columnSpacing={2}>
        <Grid2 xs={4} sx={{ display: 'flex', minHeight: '20em' }}>
          <Paper
            square
            sx={{
              p: 2,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              backgroundColor: primaryBackgroundColor.veryDark,
            }}
          >
            <CreateWorkspaceComponent tenantId={tenantId} />
          </Paper>
        </Grid2>
        {workspaceElements}
      </Grid2>
    </Box>
  );
});
