import {
  DashboardElementStateVisitor,
  DashboardElementVisitable,
} from '~src/data/store/visitors/common/dashboard/element-visitor';
import { AppDispatch } from '~src/store/store';
import { DashboardElement } from '~src/services/graphql/workspace/client/graphql';
import { Operation } from '@pladdenico/portfolio-api';
import { dashboardElementsActions } from '~src/data/store/reducers/common/dashboard/elements/reducer';

export function handleDashboardElements(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: DashboardElement[],
  subscriptions: Operation[],
) {
  const dashboardElementVisitor = new DashboardElementStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  outputs.forEach((dashboardElement) => {
    const assetVisitable = new DashboardElementVisitable(dashboardElement);
    assetVisitable.accept(dashboardElementVisitor);
  });
  dashboardElementVisitor.post();
  return outputs;
}

export function handleDeleteDashboardElements(
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      dashboardElementsActions.removeMany(
        ids.map((p) => {
          return {
            workspaceId,
            id: p,
          };
        }),
      ),
    );
  };
}
