import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';
import { AddMediaComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/image/add-media.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import { Divider, MenuItem } from '@mui/material';

export const useImageMenu = (
  element: DashboardElementState,
  workspace: WorkspaceState,
  project: ProjectState,
  holding: HoldingState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const setFileId = React.useCallback(
    (fileId: string) => {
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            mediaId: fileId,
          },
        });
    },
    [element, updated],
  );
  const { close, isShowing, open } = useVisible();
  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);
  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-1" />,
      <MenuItem
        key="menu-item-1"
        sx={{ justifyContent: 'space-between' }}
        onClick={open}
      >
        <AddMediaComponent
          holding={holding}
          project={project}
          workspace={workspace}
          setFileId={setFileId}
          close={close}
          isShowing={isShowing}
        />
      </MenuItem>,
      <Divider key="divider-delete" />,
      deleteMenuItem,
      confirmDeleteElement,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    close,
    element,
    holding,
    isShowing,
    open,
    project,
    setFileId,
    updated,
    workspace,
  ]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
