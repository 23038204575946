import { parseEntity } from '~src/data/store/modules/entities/entities/parser';
import { subscribeToEntities } from '~src/data/store/modules/entities/entities/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  EntityMediumStateVisitor,
  EntityMediumVisitable,
  EntityMediumVisitor,
} from '~src/data/store/visitors/workspace/entity/entity-medium-visitor';
import {
  RegionAllocationStateVisitor,
  RegionAllocationVisitable,
  RegionAllocationVisitor,
} from '~src/data/store/visitors/workspace/entity/region-allocation-visitor';
import {
  entitiesActions,
  EntityState,
} from '~src/data/store/reducers/entity/entities/reducer';
import { Entity } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface EntityVisitor {
  visit(entity: EntityVisitable): EntityState;
  post(): void;
  mediumVisitor: EntityMediumVisitor;
  regionAllocationVisitor: RegionAllocationVisitor;
}

export class EntityVisitable implements Visitable<EntityVisitor> {
  constructor(
    private _tenantId: string,
    private _workspaceId: string,
    private _entity: Entity,
  ) {}
  public accept(visitor: EntityVisitor) {
    if (this._entity.media) {
      this._entity.media.forEach((medium) => {
        const mediumVisitable = new EntityMediumVisitable(
          this._entity.id,
          medium,
        );
        mediumVisitable.accept(visitor.mediumVisitor);
      });
    }
    if (this._entity.regionAllocations) {
      this._entity.regionAllocations.forEach((regionAllocation) => {
        const regionAllocationVisitable = new RegionAllocationVisitable(
          this._entity.id,
          regionAllocation,
        );
        regionAllocationVisitable.accept(visitor.regionAllocationVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): EntityState {
    return parseEntity(this._tenantId, this._workspaceId, this._entity);
  }
}

export class EntityStateVisitor implements EntityVisitor {
  private _entities: EntityState[];
  public mediumVisitor: EntityMediumVisitor;
  public regionAllocationVisitor: RegionAllocationVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._entities = [];
    this.mediumVisitor = new EntityMediumStateVisitor(this._dispatch);
    this.regionAllocationVisitor = new RegionAllocationStateVisitor(
      this._dispatch,
    );
  }

  public visit(medium: EntityVisitable): EntityState {
    const entityState = medium.parse();
    this._entities.push(entityState);
    return entityState;
  }

  post() {
    this._dispatch(
      entitiesActions.upsertManyElements(this._entities, {
        shouldAutobatch: true,
      }),
    );
    subscribeToEntities(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._entities,
      this._subscriptions,
    );
    this.mediumVisitor.post();
    this.regionAllocationVisitor.post();
  }
}
