import { isEqual } from 'lodash';
import React from 'react';
import { ColorType } from '~src/components/dashboard/use-color-icon';
import { BarChartFormat } from '~src/components/irr/bar-chart-format';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { ColorItemComponent } from '~src/domain/workspace/components/common/dashboard/element/color-item.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import { IconButton, MenuItem } from '@mui/material';

interface Props {
  update?: (barChartFormat: BarChartFormat) => void;
  barChartFormat: BarChartFormat;
  baseBarChartFormat: BarChartFormat;
}

export const FormatBarChartComponent = React.memo(
  ({ barChartFormat, update, baseBarChartFormat }: Props) => {
    const [_barChartFormat, _setBarChartFormat] = React.useState(() => {
      return { ...barChartFormat };
    });
    const confirmDialog = useVisible();

    const updateBarChartFormat = React.useCallback(
      (barChartFormat: BarChartFormat) => {
        if (!isEqual(barChartFormat, _barChartFormat)) {
          _setBarChartFormat(barChartFormat);
          update && update(barChartFormat);
        }
      },
      [_barChartFormat, update],
    );

    React.useEffect(() => {
      if (!isEqual(barChartFormat, _barChartFormat)) {
        _setBarChartFormat(barChartFormat);
      }
    }, [_barChartFormat, barChartFormat]);

    const updateAxisLineStrokeColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        axis: {
          ..._barChartFormat.axis,
          lineStroke: color,
        },
      });
    };

    const updateAxisTickColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        axis: {
          ..._barChartFormat.axis,
          tick: color,
        },
      });
    };

    const updateAxisLabelColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        axis: {
          ..._barChartFormat.axis,
          label: {
            ..._barChartFormat.axis.label,
            fill: color,
          },
        },
      });
    };

    const updateLabelListFillColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        labelList: {
          ..._barChartFormat.labelList,
          fill: color,
        },
      });
    };

    const updateTooltipFillColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        tooltip: {
          ..._barChartFormat.tooltip,
          fill: color,
        },
      });
    };

    const updateTooltipStrokeColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        tooltip: {
          ..._barChartFormat.tooltip,
          stroke: color,
        },
      });
    };

    const updateTooltipContentFillColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        tooltip: {
          ..._barChartFormat.tooltip,
          content: {
            ..._barChartFormat.tooltip.content,
            fill: color,
          },
        },
      });
    };

    const updateBarColor = (color: string) => {
      updateBarChartFormat({
        ..._barChartFormat,
        bar: color,
      });
    };

    const resetFormat = () => {
      updateBarChartFormat({
        ...baseBarChartFormat,
      });
    };

    return (
      <>
        <ColorItemComponent
          color={_barChartFormat.axis.label.fill}
          setColor={updateAxisLabelColor}
          type={ColorType.text}
          label="Label color"
        />
        <ColorItemComponent
          color={_barChartFormat.axis.lineStroke}
          setColor={updateAxisLineStrokeColor}
          type={ColorType.border}
          label="Axis line color"
        />
        <ColorItemComponent
          color={_barChartFormat.axis.tick}
          setColor={updateAxisTickColor}
          type={ColorType.text}
          label="Axis tick color"
        />
        <ColorItemComponent
          color={_barChartFormat.labelList.fill}
          setColor={updateLabelListFillColor}
          type={ColorType.text}
          label="Label list fill color"
        />
        <ColorItemComponent
          color={_barChartFormat.tooltip.fill}
          setColor={updateTooltipFillColor}
          type={ColorType.text}
          label="Tooltip fill color"
        />
        <ColorItemComponent
          color={_barChartFormat.tooltip.stroke}
          setColor={updateTooltipStrokeColor}
          type={ColorType.border}
          label="Tooltip stroke color"
        />
        <ColorItemComponent
          color={_barChartFormat.tooltip.content?.fill ?? 'red'}
          setColor={updateTooltipContentFillColor}
          type={ColorType.text}
          label="Tooltip content fill color"
        />
        <ColorItemComponent
          color={_barChartFormat.bar}
          setColor={updateBarColor}
          type={ColorType.paint}
          label="Bar color"
        />
        <MenuItem
          key="reset-button"
          onClick={() => {
            confirmDialog.setIsShowing(true);
          }}
        >
          <IconButton disableRipple size="small" sx={{ mr: 1.5 }}>
            <FormatColorResetIcon />
          </IconButton>
          Reset format
        </MenuItem>
        <ConfirmDialogComponent
          close={confirmDialog.close}
          handler={() => {
            resetFormat();
            confirmDialog.close();
          }}
          isOpen={confirmDialog.isShowing}
          text={{
            description: 'Are you sure you want to reset the format?',
            title: 'Confirm format reset',
          }}
        />
      </>
    );
  },
);
