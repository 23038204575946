import {
  StockHoldingStateVisitor,
  StockHoldingVisitable,
} from '~src/data/store/visitors/holding/types/stock/stock-holding-visitor';
import { Operation } from '@pladdenico/portfolio-api';
import { StockHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleStockHoldings(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: StockHolding[],
  subscriptions: Operation[],
) {
  const stockHoldingVisitor = new StockHoldingStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((stockHolding) => {
    const stockHoldingVisitable = new StockHoldingVisitable(stockHolding);
    stockHoldingVisitable.accept(stockHoldingVisitor);
  });
  stockHoldingVisitor.post();
  return outputs;
}
