import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { handleInputData } from '~src/services/xlsx/handlers/iinput-handler';
import { DataImporter } from '~src/services/xlsx/hooks/use-import';
import { StatusUpdater } from '~src/services/xlsx/hooks/use-update-status';
import { addressImportHandler } from '~src/services/xlsx/handlers/workspaces/people/addresses/mapper';
import { emailImportHandler } from '~src/services/xlsx/handlers/workspaces/people/emails/mapper';
import { personImportHandler } from '~src/services/xlsx/handlers/workspaces/people/mapper';
import { phoneImportHandler } from '~src/services/xlsx/handlers/workspaces/people/phones/mapper';
import { roleImportHandler } from '~src/services/xlsx/handlers/workspaces/people/roles/mapper';
import { AppDispatch } from '~src/store/store';

export const importer: DataImporter = (
  workspace: WorkspaceState,
  data: Map<string, any[]>,
  statusUpdater: StatusUpdater,
) => {
  return (dispatch: AppDispatch) => {
    const personPromises: Promise<boolean>[] = [];
    personPromises.push(
      handleInputData(
        data,
        personImportHandler(workspace, dispatch),
        statusUpdater,
      ).then(() => true),
    );
    return Promise.all(personPromises).then((_people) => {
      const personEntityPromises: Promise<boolean>[] = [];
      personEntityPromises.push(
        handleInputData(
          data,
          addressImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      personEntityPromises.push(
        handleInputData(
          data,
          emailImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      personEntityPromises.push(
        handleInputData(
          data,
          phoneImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      personEntityPromises.push(
        handleInputData(
          data,
          roleImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true),
      );
      return Promise.all(personEntityPromises).then(() => true);
    });
  };
};
