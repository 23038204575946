import { bankNoteHoldingsActions } from '~src/data/store/reducers/holding/holding-types/bank-note/bank-note-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleBankNoteHoldings } from './handler';

export function fetchBankNoteHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetBankNoteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetBankNoteHoldingsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getBankNoteHoldings) {
        return handleBankNoteHoldings(dispatch, data.getBankNoteHoldings);
      }
      return [];
    });
  };
}

export function createBankNoteHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateBankNoteHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateBankNoteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateBankNoteHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createBankNoteHoldings) {
        return handleBankNoteHoldings(dispatch, data.createBankNoteHoldings);
      }
      return [];
    });
  };
}

export function updateBankNoteHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateBankNoteHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateBankNoteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateBankNoteHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateBankNoteHoldings) {
        return handleBankNoteHoldings(dispatch, data.updateBankNoteHoldings);
      }
      return [];
    });
  };
}

export function upsertBankNoteHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertBankNoteHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertBankNoteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertBankNoteHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertBankNoteHoldings) {
        return handleBankNoteHoldings(dispatch, data.upsertBankNoteHoldings);
      }
      return [];
    });
  };
}

export function deleteBankNoteHoldings(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteBankNoteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteBankNoteHoldingsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteBankNoteHoldings) {
        dispatch(
          bankNoteHoldingsActions.removeManyElements(
            data.deleteBankNoteHoldings.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
