import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { EditSubtypeField } from '~src/components/fields/workspace/transfer/subtype/edit-subtype-field';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

// import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

export const useTransferSubtypeField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  fieldConfig?: FieldConfig,
  subtypes?: string[],
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<
        TFieldValues,
        TName // 'data.stockTrade.shares'
      >,
      fieldState: ControllerFieldState,
      disabled?: boolean,
    ) => (
      <EditSubtypeField
        handleValue={field.onChange}
        label={'Subtype'}
        subtype={field.value}
        subtypes={subtypes ?? []}
        disabled={disabled ?? false}
        error={!!fieldState.error}
        helperText={fieldState.error ? fieldState.error.message : null}
      />
    ),
    [subtypes],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName} // {'data.stockTrade.shares'}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
