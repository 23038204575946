import { combineReducers } from 'redux';

import {
  initialCryptoHoldingState,
  cryptoHoldingsReducer,
} from './crypto-holding/reducer';

export const cryptoHoldingInitialState = {
  cryptoHoldings: initialCryptoHoldingState,
  // cryptoHoldingsFetch: initialCryptoHoldingsFetchReducer,
  // cryptoPosition: cryptoPositionInitialState,
};

export const cryptoHoldingReducer = combineReducers({
  cryptoHoldings: cryptoHoldingsReducer,
  // cryptoHoldingsFetch: cryptoHoldingsFetchReducer,
  // cryptoPosition: cryptoPositionReducer,
});
