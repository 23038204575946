import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { CommitmentInputData } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-data-types';
import { CommitmentFieldConfig } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-field-config';
import { FieldsComponent } from '~src/domain/workspace/components/project/commitment/form/fields/fields.component';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

import { colors, Theme, Typography } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    // maxWidth: 500,
  },
  row: {
    display: 'flex',
    // alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  subPartElement: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.blue[100],
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface Props<T extends FieldValues>
  extends React.PropsWithChildren<unknown> {
  projectId: string;
  holdingId: string;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: CommitmentFieldConfig;
  getName: (name: Path<CommitmentInputData>) => Path<T>;
  commitmentTypes: CommitmentType[] | undefined;
}

export const CommitmentFormComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { classes } = useStyles();
  const {
    holdingId,
    projectId,
    control,
    setValue,
    // index,
    fieldConfig,
    getName,
    commitmentTypes,
  } = props;
  const holding = useSelector((root: RootState) => {
    return getHoldingById(selectWorkspaceData(root), {
      id: holdingId,
      projectId,
    });
  });

  const commitmentId = useWatch({
    control,
    name: getName('base.id'),
  });
  const commitmentElement = holding ? (
    <div className={classes.subPartElement}>
      <div className={classes.row}>
        <Typography variant="body1" className={classes.title}>
          Commitment
        </Typography>
        <span className={classes.spacer} />
        <Typography variant="caption" className={classes.title}>
          {commitmentId}
        </Typography>
      </div>
      <FieldsComponent
        projectId={props.projectId}
        holding={holding}
        control={control}
        setValue={setValue}
        // index={index}
        fieldConfig={fieldConfig}
        getName={getName}
        commitmentTypes={commitmentTypes}
      />
    </div>
  ) : (
    <></>
  );

  return commitmentElement;
};
