import { parseStockHolding } from '~src/data/store/modules/holdings/stock/parser';
import {
  StockGoalRuleStateVisitor,
  StockGoalRuleVisitable,
  StockGoalRuleVisitor,
} from '~src/data/store/visitors/holding/types/stock/stock-goal-rule-visitor';
import {
  StockPositionStateVisitor,
  StockPositionVisitable,
  StockPositionVisitor,
} from '~src/data/store/visitors/holding/types/stock/stock-position-visitor';
import {
  StockTradeStateVisitor,
  StockTradeVisitable,
  StockTradeVisitor,
} from '~src/data/store/visitors/holding/types/stock/stock-trade-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  stockHoldingsActions,
  StockHoldingState,
} from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import {
  Holding,
  StockHolding,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';
import { Operation } from '@pladdenico/portfolio-api';

export const isStockHolding = (holding: Holding): holding is StockHolding => {
  return holding.holding.type === HoldingType.Stock;
};

export interface StockHoldingVisitor {
  visit(stockHolding: StockHoldingVisitable): StockHoldingState;
  post(): void;
  positionVisitor: StockPositionVisitor;
  tradeVisitor: StockTradeVisitor;
  goalRuleVisitor: StockGoalRuleVisitor;
}

export class StockHoldingVisitable implements Visitable<StockHoldingVisitor> {
  constructor(private _stockHolding: StockHolding) {}
  public accept(visitor: StockHoldingVisitor) {
    if (this._stockHolding.stockPositions) {
      this._stockHolding.stockPositions.forEach((position) => {
        const positionVisitable = new StockPositionVisitable(position);
        positionVisitable.accept(visitor.positionVisitor);
      });
    }
    if (this._stockHolding.stockTrades) {
      this._stockHolding.stockTrades.forEach((trade) => {
        const tradeVisitable = new StockTradeVisitable(trade);
        tradeVisitable.accept(visitor.tradeVisitor);
      });
    }
    if (this._stockHolding.stockGoalRules) {
      this._stockHolding.stockGoalRules.forEach((goalRule) => {
        const positionVisitable = new StockGoalRuleVisitable(goalRule);
        positionVisitable.accept(visitor.goalRuleVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): StockHoldingState {
    return parseStockHolding(this._stockHolding);
  }
}

export class StockHoldingStateVisitor implements StockHoldingVisitor {
  private _stockHoldings: StockHoldingState[];
  public positionVisitor: StockPositionVisitor;
  public tradeVisitor: StockTradeVisitor;
  public goalRuleVisitor: StockGoalRuleVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._stockHoldings = [];
    this.positionVisitor = new StockPositionStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    this.tradeVisitor = new StockTradeStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    this.goalRuleVisitor = new StockGoalRuleStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(holding: StockHoldingVisitable): StockHoldingState {
    const stockHolding = holding.parse();
    this._stockHoldings.push(stockHolding);
    return stockHolding;
  }
  post() {
    this._dispatch(
      stockHoldingsActions.upsertManyElements(this._stockHoldings, {
        shouldAutobatch: true,
      }),
    );
    this.positionVisitor.post();
    this.tradeVisitor.post();
    this.goalRuleVisitor.post();
  }
}
