import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DnbAccount } from '~src/services/xlsx/handlers/adapters/dnb/account/account';
import {
  dnbTransactionsActions,
  DnbTransactionState,
} from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import { AppDispatch } from '~src/store/store';

import { DnbTransaction } from './transaction';

export const handlerType = 'transactions';

const header: Array<keyof DnbTransaction> = [
  'name',
  'description',
  'archiveRef',
  'date',
  'in',
  'out',
];

const headerMapper = new Map<string, keyof DnbTransaction>([
  ['Bokført dato', 'date'],
  ['Transaksjonstype', 'description'],
  ['Arkivref.', 'archiveRef'],
  ['Inn', 'in'],
  ['Ut', 'out'],
  ['Forklarende tekst', 'name'],
]);

// TODO: Need to sort the transactions because of "expectedValuation"
export const dnbTransactionImportHandler = (
  workspace: WorkspaceState,
  holdingId: string,
  account: DnbAccount,
  dispatch: AppDispatch,
): ImportHandler<DnbTransaction> => {
  return {
    type: handlerType,
    header,
    handler: (ts) => {
      console.log(workspace.tenantId, workspace.id, ts);
      let valuation = account.valuationStart;
      // XX: Need to calculate expected value as transactions on same date might be saved in a random order
      let previousDate: Date | null = null;
      let counter = 1;
      const tsCleaned: DnbTransactionState[] = ts
        .filter((t) => t.archiveRef !== '')
        .map((t) => {
          const value =
            typeof t.in === 'number'
              ? t.in
              : typeof t.out === 'number'
                ? t.out
                : 0;
          let date = t.date;
          if (
            previousDate != null &&
            date.getTime() === previousDate.getTime()
          ) {
            date = new Date(date.getTime() + counter * 1000);
            ++counter;
          } else {
            counter = 1;
            previousDate = date;
          }
          valuation += value;
          return {
            id: `${t.archiveRef}-${date.getTime()}`,
            adapterId: 'dnb',
            holdingId,
            archiveRef: t.archiveRef,
            date,
            description: t.description,
            name: t.name,
            value,
            expectedValuation: valuation,
            handled: false,
            transactionId: null,
            groupId: uuid.v1(),
          };
        });
      dispatch(dnbTransactionsActions.upsertManyElements(tsCleaned));
      return Promise.resolve();
    },
    headerMapper,
  };
};
