import json2mq from 'json2mq';
import moment from 'moment';
import numbro from 'numbro';
import React from 'react';
import {
  Bar,
  BarChart,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Period } from '~src/utils/period/period';

import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IrrTooltipComponent } from './irr-tooltip.component';
import { Filter } from '~src/utils/common/filter';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { useSelector } from 'react-redux';
import { getHoldingsXIRR } from '~src/data/store/selectors/finance/xirr/holdings-xirr';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState, DataState } from '~src/data/store/reducers/reducers';
import {
  selectWorkspaceData,
  selectData,
} from '~src/data/store/selectors/selectors';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getHoverColor } from '~src/utils/common/colors/color-utils';
import { createSelector } from 'reselect';
import { defaultBarChartFormat } from '~src/components/irr/bar-chart-format';

interface Props {
  workspaceId: string;
  projectId: string;
  date: moment.Moment;
  period: Period;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
  title: string;
  pivot: string;
  currency: CurrencyState;
}

interface DataProps {
  group: string;
  value: number;
}

interface SelectIRRsProps {
  workspaceId: string;
  projectId: string;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
  filter: Filter;
  date: moment.Moment;
  currency: CurrencyState;
  period: Period;
}

const selectIRRs = createCachedSelector(
  (state: DataState, _props: SelectIRRsProps) => state,
  (_state: DataState, props: SelectIRRsProps) => props.workspaceId,
  (_state: DataState, props: SelectIRRsProps) => props.projectId,
  (_state: DataState, props: SelectIRRsProps) => props.holdingGroups,
  (_state: DataState, props: SelectIRRsProps) => props.filter,
  (_state: DataState, props: SelectIRRsProps) => props.date,
  (_state: DataState, props: SelectIRRsProps) => props.period,
  (_state: DataState, props: SelectIRRsProps) => props.currency,
  (
    state,
    workspaceId,
    projectId,
    holdingGroups,
    filter,
    date,
    period,
    currency,
  ) => {
    const irrs: DataProps[] = [];
    holdingGroups.forEach((group, _groupId) => {
      const value = getHoldingsXIRR(state, {
        workspaceId,
        projectId,
        currency,
        date,
        filter,
        holdings: group.holdings,
        useLiveValue: true, // XXX: Should come from config.
        startDate: period.start,
        endDate: period.end,
      });
      irrs.push({
        value,
        group: group.name,
      });
    });

    const data = irrs.map((irr) => {
      const label = `${numbro(irr.value).format({
        output: 'percent',
        thousandSeparated: true,
        mantissa: 2,
      })}`;
      const name = irr.group;
      const cutName = name.length < 14 ? name : name.substr(0, 11) + '...';
      return {
        name,
        cutName,
        label,
        irr: irr.value,
      };
    });
    return data;
  },
)({
  keySelector: (_state, props) => props.holdingGroups,
  cacheObject: new FlatMapCache(),
  selectorCreator: createSelector,
});

// function calculateIrrs(
//   portfolios: Map<string, Holdings>,
//   date: Date,
//   period: Period,
//   baseCurrency: CurrencyState,
//   exchangeRateState: ExchangeRateState
// ): DataProps[] {
//   const startDate = period.start.toDate();
//   const endDate = period.end.toDate();
//   const irrs: DataProps[] = [];
//   portfolios.forEach((portfolio, group) => {
//     const irr = portfolio.xirr(
//       startDate,
//       endDate,
//       date,
//       exchangeRateState,
//       baseCurrency
//     );
//     irrs.push({ group, value: irr });
//   });
//   return irrs;
// }

export const HoldingGroupIrrComponent = React.memo((props: Props) => {
  const matches = useMediaQuery(
    json2mq({
      minWidth: 500,
    }),
  );
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const data = useSelector((state: RootState) =>
    selectIRRs(selectData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      currency: props.currency,
      date: props.date,
      period: props.period,
      filter,
      holdingGroups: props.holdingGroups,
    }),
  );

  const format = defaultBarChartFormat;

  const formatYAxis = (irrValue: number) => {
    return numbro(irrValue).format({
      output: 'percent',
      thousandSeparated: true,
      mantissa: 2,
    });
  };
  const barChart = (
    <BarChart data={data} margin={{ top: 15, right: 30, left: 20, bottom: 35 }}>
      <XAxis
        dataKey="cutName"
        interval={0}
        axisLine={{ stroke: format?.axis?.lineStroke ?? '#FFFFFF' }}
        tickLine={false}
        tick={{ stroke: format?.axis?.tick ?? '#AAAAAA' }}
      >
        <Label
          value={`${props.title}`}
          position="bottom"
          fill={format?.axis?.label?.fill ?? '#FFFFFF'}
        />
      </XAxis>
      <YAxis
        axisLine={{ stroke: format?.axis?.lineStroke ?? '#FFFFFF' }}
        tick={{ stroke: format?.axis?.tick ?? '#AAAAAA' }}
        tickFormatter={formatYAxis}
      >
        <Label
          value="IRR"
          offset={-10}
          position="insideLeft"
          fill={format?.axis?.label?.fill ?? '#FFFFFF'}
        />
      </YAxis>
      {/* <CartesianGrid strokeDasharray="3 3"/> */}
      <Tooltip
        content={
          <IrrTooltipComponent
            style={{ color: format?.tooltip?.content?.fill ?? 'red' }}
          />
        }
        cursor={{
          stroke: format?.tooltip?.stroke ?? 'red',
          fill: getHoverColor(format?.tooltip?.fill ?? '#180C4F', 30),
          strokeWidth: 2,
        }}
      />
      {/* <Legend /> */}
      {/* <ReferenceLine y={0} stroke="#000"/> */}
      <Bar dataKey="irr" fill="url(#colorUv)">
        <LabelList
          dataKey="label"
          position="top"
          stroke={format?.labelList?.fill ?? '#AAAAAA'}
        />
      </Bar>
    </BarChart>
  );

  const barChartElement = matches ? (
    <ResponsiveContainer height={400} width="100%">
      {barChart}
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer height={550} width="100%">
      {barChart}
    </ResponsiveContainer>
  );

  return (
    <Box p={1}>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        IRR per {props.title}
      </Typography>
      {barChartElement}
    </Box>
  );
});
