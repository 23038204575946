import { stockPositionPath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchStockPositions,
  handleDeleteStockPositions,
} from '~src/data/store/modules/holdings/stock/position/requests';
import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToStockPositions(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  positions: StockPositionState[],
  subscriptions: Operation[],
) {
  positions.forEach((position) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: stockPositionPath(tenantId, workspaceId).objectPath(
              position.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchStockPositions(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to position', operation, position.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: stockPositionPath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchStockPositions(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to position', operation, position.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: stockPositionPath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteStockPositions(position.stockHoldingId, [res.id]),
              );
            },
          },
          () => {
            // console.log('subscribed to position', operation, position.id);
          },
        );
      }
    });
  });
}
