import { Visitable } from '~src/data/store/visitors/visitable';
import {
  WorkspaceRoleStateVisitor,
  WorkspaceRoleVisitable,
  WorkspaceRoleVisitor,
} from '~src/data/store/visitors/workspace/role/workspace-role-visitor';
import { parseWorkspaceInvitation } from '~src/data/store/modules/workspaces/workspace-invitations/parser';
import {
  workspaceInvitationsActions,
  WorkspaceInvitationState,
} from '~src/data/store/reducers/workspace/workspace-invitations/reducer';
import { WorkspaceInvitation } from '~src/services/graphql/tenant/client/graphql';
import { AppDispatch } from '~src/store/store';

interface WorkspaceInvitationVisitor {
  visit(workspaceInvitation: WorkspaceInvitationVisitable): void;
  workspaceRoleVisitor: WorkspaceRoleVisitor;
}

export class WorkspaceInvitationVisitable
  implements Visitable<WorkspaceInvitationVisitor>
{
  constructor(
    private _tenantId: string,
    private _workspaceInvitation: WorkspaceInvitation,
  ) {}
  public accept(visitor: WorkspaceInvitationVisitor) {
    visitor.visit(this);
    if (this._workspaceInvitation.workspaceRole) {
      const workspaceRoleVisitable = new WorkspaceRoleVisitable(
        this._tenantId,
        this._workspaceInvitation.workspaceRole,
      );
      workspaceRoleVisitable.accept(visitor.workspaceRoleVisitor);
    }
  }

  public parse(): WorkspaceInvitationState {
    return parseWorkspaceInvitation(this._tenantId, this._workspaceInvitation);
  }
}

export class WorkspaceInvitationStateVisitor
  implements WorkspaceInvitationVisitor
{
  private _invitations: WorkspaceInvitationState[];
  public workspaceRoleVisitor: WorkspaceRoleVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._invitations = [];
    this.workspaceRoleVisitor = new WorkspaceRoleStateVisitor(this._dispatch);
  }
  public visit(workspaceInvitation: WorkspaceInvitationVisitable): void {
    this._invitations.push(workspaceInvitation.parse());
  }
  post() {
    this._dispatch(workspaceInvitationsActions.addMany(this._invitations));
  }
}
