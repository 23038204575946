import * as React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReportState } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';

export const useReportDialogComponent = (
  _workspace: WorkspaceState,
  reports: ReportState[],
) => {
  const modal = useVisible();

  const reportIds = React.useMemo(() => {
    return reports.map((h) => h.id);
  }, [reports]);
  console.log(reportIds);

  const dialog = (
    <DialogComponent
      dialogProps={{
        open: modal.isShowing,
        onClose: modal.close,
        fullWidth: true,
      }}
    >
      <>
        <DialogTitle>Export report</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Chose filename and file type to export data to.
          </DialogContentText>
          {/* <ExportComponent workspace={workspace} reportIds={reportIds} /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={modal.close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </>
    </DialogComponent>
  );

  return {
    modal,
    dialog,
  };
};
