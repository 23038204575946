import React from 'react';
import {
  DashboardElementConfig,
  DashboardElementState,
} from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { ColorItemComponent } from '~src/domain/workspace/components/common/dashboard/element/color-item.component';
import { ColorType } from '~src/components/dashboard/use-color-icon';
import { IconButton, MenuItem, TextField } from '@mui/material';
import PaddingIcon from '@mui/icons-material/Padding';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { isEqual } from 'lodash';
interface Props {
  updated?: (id: string, element: Partial<DashboardElementState>) => void;
  element: DashboardElementState;
}

export const FormatConfigComponent = React.memo(
  ({ element, updated }: Props) => {
    const [_config, _setConfig] = React.useState(() => {
      return { ...element.config };
    });
    const confirmDialog = useVisible();

    React.useEffect(() => {
      if (!isEqual(element.config, _config)) {
        _setConfig(_config);
      }
    }, [_config, element.config]);

    // React.useEffect(() => {
    //   console.log('FormatConfigComponent', _config);
    //   updated &&
    //     updated(element.id, {
    //       config: {
    //         ..._config,
    //       },
    //     });
    // }, [_config, element.id, updated]);

    const updateFormat = React.useCallback(
      (config: DashboardElementConfig) => {
        if (!isEqual(config, _config)) {
          _setConfig(config);
          updated &&
            updated(element.id, {
              config: {
                ...config,
              },
            });
        }
      },
      [_config, element.id, updated],
    );

    const updateBackgroundColor = (backgroundColor: string) => {
      updateFormat({
        ..._config,
        backgroundColor,
      });
    };

    const updateColor = (color: string) => {
      updateFormat({
        ..._config,
        color,
      });
    };

    const updateBorder = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFormat({
          ..._config,
          border: event.target.value,
        });
      },
      [_config, updateFormat],
    );

    const updatePadding = (event: React.ChangeEvent<HTMLInputElement>) => {
      updateFormat({
        ..._config,
        padding: +event.target.value,
      });
    };

    const resetFormat = () => {
      updateFormat({
        ..._config,
        backgroundColor: undefined,
        color: undefined,
        padding: 0,
        border: null,
      });
    };

    const borderField = React.useMemo(() => {
      return (
        <TextField
          label="Border"
          type="text"
          variant="standard"
          value={_config.border ?? ''}
          onChange={updateBorder}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }, [_config.border, updateBorder]);

    return (
      <>
        <ColorItemComponent
          color={_config.color ?? '#000000'}
          setColor={updateColor}
          type={ColorType.text}
          label="Font color"
        />
        <ColorItemComponent
          color={_config.backgroundColor ?? '#ffffff'}
          setColor={updateBackgroundColor}
          type={ColorType.background}
          label="Background color"
        />
        <MenuItem key="menu-item-border">
          <IconButton disableRipple size="small" sx={{ mr: 1.5 }}>
            <PaddingIcon />
          </IconButton>
          {borderField}
        </MenuItem>
        <MenuItem key="menu-item-2">
          <IconButton disableRipple size="small" sx={{ mr: 1.5 }}>
            <PaddingIcon />
          </IconButton>
          <TextField
            label="Padding"
            type="number"
            variant="standard"
            value={_config.padding ?? 0}
            onChange={updatePadding}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MenuItem>
        <MenuItem
          key="reset-button"
          onClick={() => {
            confirmDialog.setIsShowing(true);
          }}
        >
          <IconButton disableRipple size="small" sx={{ mr: 1.5 }}>
            <FormatColorResetIcon />
          </IconButton>
          Reset format
        </MenuItem>
        <ConfirmDialogComponent
          close={confirmDialog.close}
          handler={() => {
            resetFormat();
            confirmDialog.close();
          }}
          isOpen={confirmDialog.isShowing}
          text={{
            description: 'Are you sure you want to reset the format?',
            title: 'Confirm format reset',
          }}
        />
      </>
    );
  },
);
