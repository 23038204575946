import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuotes } from '~src/data/store/modules/finance/quotes/requests';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getAllPapers } from '~src/data/store/selectors/finance/papers/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
  Grid,
  TextField,
} from '@mui/material';

interface Props {
  paperId: string | null;
  setPaperId: (id: string | null) => void;
  label?: string;
}
const filter = () => createFilterOptions<PaperState>();

const getOptionLabel = (paper: PaperState) => {
  return `${paper.name} : ${paper.exchange}-${paper.symbol}`;
};

export const PaperEditFieldComponent = React.memo((props: Props) => {
  const { paperId, setPaperId, label } = props;
  const papers = useSelector((state: RootState) =>
    getAllPapers(selectFinancialData(state)),
  );

  const paper = papers.find((paper) => paper.id === paperId);

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (paper) {
      // const fromDate = moment().add(-5, 'year').toDate();
      // const toDate = moment().toDate();
      dispatch(fetchQuotes(paper.id, null));
      // apis.invest.listenToQuoteStart(paper, fromDate, toDate, () => {
      //   console.log('listenToQuote done');
      // });
      // dispatch(fetchQuotes(paper.id, null));
    }
  }, [dispatch, paper]);

  const isOptionEqualToValue = React.useMemo(() => {
    return (option: PaperState, value: PaperState) => {
      return option.id === value.id;
    };
  }, []);

  return (
    <>
      <Grid item>
        <Autocomplete
          options={papers}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          // style={{ width: 300 }}
          fullWidth
          autoSelect
          value={paper ?? null}
          filterOptions={(options, params) => {
            const filtered = filter()(options, params);
            console.log(filtered);
            return filtered;
          }}
          onChange={(
            _event: React.ChangeEvent<unknown>,
            newValue: PaperState | null,
          ) => {
            // if (newValue) {
            setPaperId(newValue ? newValue.id : null);
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={label ?? 'Paper'}
              fullWidth
              margin="dense"
            />
          )}
        />
      </Grid>
    </>
  );
});
