import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EntityComponent } from '~src/domain/workspace/components/entity/entity/entity.component';

interface Props {
  entityId: string;
  workspace: WorkspaceState;
}

export const ProjectEntityComponent = React.memo((props: Props) => {
  return <EntityComponent {...props} />;
});
