import {
  PhoneStateVisitor,
  PhoneVisitable,
} from '~src/data/store/visitors/person/phone-visitor';
import { Phone } from '~src/services/graphql/workspace/client/graphql';

export function handlePhones(
  dispatch: any,
  _tenantId: string,
  workspaceId: string,
  phones: Phone[],
) {
  const visitor = new PhoneStateVisitor(dispatch);
  const res = phones.map((phone) => {
    const visitable = new PhoneVisitable(
      workspaceId,
      phone.person?.id ?? '',
      phone,
    );
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
