import moment from 'moment';
import React from 'react';
import * as uuid from 'uuid';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { eventParser } from '~src/utils/form/event-parser';

import Autorenew from '@mui/icons-material/Autorenew';
import { DatePicker } from '@mui/x-date-pickers';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { RegionField } from './region-field';

interface Props {
  regionAllocationInput: graphqlWorkspaceTypes.CreateRegionAllocationInputType;
  setRegionAllocationInput: (
    regionAllocationInput: graphqlWorkspaceTypes.CreateRegionAllocationInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  idField: {
    marginRight: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 355,
    // flexBasis: 200,
  },
}));

export const FieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof graphqlWorkspaceTypes.CreateRegionAllocationInputType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.setRegionAllocationInput({
        ...props.regionAllocationInput,
        [name]: eventParser(event),
      });
    };

  // const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  //   props.setRegionAllocationInput({ ...props.regionAllocationInput, [event.target.name as string]: event.target.value });
  // };

  const handleRegionChange = (region: RegionState) => {
    props.setRegionAllocationInput({
      ...props.regionAllocationInput,
      regionId: region.id,
    });
  };

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      props.setRegionAllocationInput({
        ...props.regionAllocationInput,
        date: date.toDate(),
      });
    }
  };

  const handleClickRegenerateId = () => {
    props.setRegionAllocationInput({
      ...props.regionAllocationInput,
      id: uuid.v1(),
    });
  };

  const handleMouseDownRegenerateId = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl required className={classes.idField} margin="normal">
        <InputLabel htmlFor="adornment-id">Id</InputLabel>
        <Input
          id="adornment-id"
          type={'text'}
          value={props.regionAllocationInput.id}
          onChange={handleChange('id')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="id regeneration"
                onClick={handleClickRegenerateId}
                onMouseDown={handleMouseDownRegenerateId}
              >
                <Autorenew />
                {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="allocation"
        value={props.regionAllocationInput.allocation}
        label="Allocation"
        type="number"
        onChange={handleChange('allocation')}
      />
      <DatePicker
        className={classes.textField}
        showToolbar={false}
        inputFormat="DD/MM/YYYY"
        label="Date picker inline"
        value={moment(props.regionAllocationInput.date)}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} />}
      />
      <RegionField
        regionId={props.regionAllocationInput.regionId}
        handleValue={handleRegionChange}
      />
    </>
  );
});
