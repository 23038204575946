import { all, BlockNode, create } from 'mathjs';
import * as uuid from 'uuid';
import { EvaluatorState } from '~src/data/store/state/workspace/project/scenario/forecast/evaluator-state';
import { ExpressionVariables } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ForecastType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';
import { transferCashSign } from '~src/utils/finance/transfer-cash-sign';

import { TransferType } from '@pladdenico/models';

import { ForecastElement } from './forecast-element';
// import { Value } from './value';
import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';

const math = create(all);
math.import(
  {
    transferSign: function (type: TransferType) {
      return transferCashSign(type);
    },
    uuidV1: function () {
      return uuid.v1();
    },
  },
  {},
);
// math.import(uuid, { wrap: true, silent: true });

// enum ExpressionType {
//   variable,
// }

export class ExpressionElement<Value> implements ForecastElement<Value> {
  readonly type: ForecastType;
  // _parser: math.Parser | undefined;
  _code: math.EvalFunction | undefined;
  _node: math.MathNode | undefined;

  constructor(
    private _id: string,
    private _name: string,
    private _expressionsJS: Expression[],
    private _variables: ExpressionVariables, // private _correspondHoldingId: string | undefined
  ) {
    this.type = 'expression';

    try {
      // this._parser = math.parser();
      // this._expressionsJS.forEach((expression) => {
      //   this._parser?.evaluate(expression.value);
      // });

      const inResultExpression = this._expressionsJS
        .filter((expression) => expression.inResult)
        .map((expression) => expression.value)
        .join(',');
      const notInResultExpression = this._expressionsJS
        .filter((expression) => !expression.inResult)
        .map((expression) => expression.value)
        .join(';');
      const expressionsAsString = `${notInResultExpression};{${inResultExpression}}`;

      this._node = math.parse(expressionsAsString);
      console.log(this._node);
      this._code = this._node.compile();
    } catch (_e) {
      console.log('err');
    }
  }

  public getId() {
    return this._id;
  }

  public getName() {
    return this._name;
  }

  public getExpressionsJS() {
    return this._expressionsJS;
  }

  public getLatex() {
    // const customLaTex = (node: math.MathNode) => {
    //   console.log(node.type, node.toString());
    //   if (node.type === 'BlockNode') {
    //     node.blocks.map((block) => )
    //     return '\\\\';
    //   }
    // };
    if (this._node?.type === 'BlockNode') {
      const node = this._node as BlockNode;
      return node.blocks.map((block) => {
        return `$${block.node.toTex({
          parenthesis: 'auto',
          implicit: 'hide',
          // handler: customLaTex,
        })}$`;
      });
    }
    return [
      this._node?.toTex({
        parenthesis: 'auto',
        implicit: 'hide',
        // handler: customLaTex,
      }),
    ];
  }

  public evaluate(state: EvaluatorState<Value>): Value | undefined {
    if (this._code == null) {
      return undefined;
    }
    const resultSet = this._code.evaluate({
      date: state.date,
      values: state.values,
      variables: this._variables,
      idx: state.idx,
    });
    return resultSet.entries[0] as Value;
    // const { transfer, valuation } = resultSet.entries[0];
    // return {
    //   date: state.date,
    //   transfer,
    //   valuation,
    // };
  }
}
