import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { EditHoldingById } from '~src/components/fields/workspace/holding/holding/edit-holding-by-id';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

// import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

export const useTransferHoldingField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  workspace: WorkspaceState,
  projectId: string,
  fieldConfig?: FieldConfig,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<
        TFieldValues,
        TName // 'data.stockTrade.shares'
      >,
      _fieldState: ControllerFieldState,
      _disabled?: boolean,
    ) => (
      <EditHoldingById
        handleValue={(holding) => field.onChange(holding.id)}
        holdingId={field.value ?? null}
        projectId={projectId}
        workspace={workspace}
      />
    ),
    [projectId, workspace],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName} // {'data.stockTrade.shares'}
        rules={{ required: false }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
