import { holdingBasePath } from '../../paths';

export const fundPositionsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) => {
  return `${holdingBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/fundPositions`;
};

export const fundPositionBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  fundPositionId: string,
) => {
  return `${fundPositionsBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/${fundPositionId}`;
};

export const fundPositionTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  fundPositionId: string,
  tab: string,
) => {
  return `${fundPositionBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
    fundPositionId,
  )}/${tab}`;
};
