import { parseBond } from '~src/data/store/modules/assets/bonds/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  bondsActions,
  BondState,
} from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { Asset, Bond } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { AssetType } from '@pladdenico/models';

export const isBond = (asset: Asset): asset is Bond => {
  return asset._type === AssetType.Bond;
};

export interface BondVisitor {
  visit(bond: BondVisitable): BondState;
  post(): void;
}

export class BondVisitable implements Visitable<BondVisitor> {
  constructor(
    private _workspaceId: string,
    private _bond: Bond,
  ) {}
  public accept(visitor: BondVisitor) {
    return visitor.visit(this);
  }

  public parse(): BondState {
    const assetId = this._bond.asset?.id ?? '';
    return parseBond(this._workspaceId, assetId, this._bond);
  }
}

export class BondStateVisitor implements BondVisitor {
  private _bonds: BondState[];
  constructor(private _dispatch: AppDispatch) {
    this._bonds = [];
  }

  public visit(bond: BondVisitable): BondState {
    const bondParsed = bond.parse();
    this._bonds.push(bondParsed);
    return bondParsed;
  }

  post() {
    this._dispatch(
      bondsActions.upsertManyElements(this._bonds, {
        shouldAutobatch: true,
      }),
    );
  }
}
