import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { StockPosition } from '~src/services/graphql/workspace/client/graphql';

export function parseStockPosition(
  stockPosition: StockPosition,
): StockPositionState {
  return {
    id: stockPosition.id,
    stockHoldingId: stockPosition.stockHolding?.id ?? '',
    date: new Date(stockPosition.date),
    shares: stockPosition.shares ?? 0,
  };
}
