import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseAssetId {
  id: string;
  workspaceId: string;
}

export type AssetState = Omit<
  graphqlWorkspaceTypes.AssetObj,
  'workspace' | 'entity' | 'category'
> & {
  workspaceId: string;
  tenantId: string;
  entityId: string;
  categoryId: string | null;
};

export function assetToAssetState(asset: AssetState) {
  return asset;
}

export const initialAssetsState: RecordState<AssetState, string>[] = [];

const selectKeyId = (asset: BaseAssetId) => asset.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseAssetId, b: BaseAssetId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseAssetId, AssetState, string>(
  initialAssetsState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const assetsSlice = createBaseRecordSlice('assets', adapter);
export const assetsSelectors = adapter.selectors;

export const assetsReducer = assetsSlice.reducer;
export const assetsActions = assetsSlice.actions;
export const assetsSlices = assetsSlice.slices;
