import { combineReducers } from 'redux';
import {
  dashboardElementsReducer,
  initialDashboardElementState,
} from '~src/data/store/reducers/common/dashboard/elements/reducer';
import {
  dashboardMediasReducer,
  initialDashboardMediaState,
} from '~src/data/store/reducers/common/dashboard/media/reducer';
import {
  initialReportingState,
  ReportingReducer,
} from '~src/data/store/reducers/common/dashboard/reporting/reducer';

export const dashboardInitialState = {
  dashboardElements: initialDashboardElementState,
  media: initialDashboardMediaState,
  reporting: initialReportingState,
};

export const dashboardReducer = combineReducers({
  dashboardElements: dashboardElementsReducer,
  media: dashboardMediasReducer,
  reporting: ReportingReducer,
});
