import React from 'react';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useEditDialog } from '~src/domain/workspace/components/project/portfolio/config/use-edit-dialog';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  dashboardConfig: PortfolioDashboardConfigState;
}

export const EditDashboardConfig = (props: Props) => {
  const { workspace, dashboardConfig } = props;

  const { dialogElement, setOpen } = useEditDialog({
    workspace,
    projectId: props.projectId,
    config: dashboardConfig,
  });

  return (
    <>
      <IconButton
        // color="primary"
        aria-label="edit config"
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </IconButton>

      {dialogElement}
    </>
  );
};
