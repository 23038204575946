import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { Transaction } from '~src/services/graphql/workspace/client/graphql';

export function parseTransaction(
  projectId: string,
  transaction: Transaction,
): TransactionState {
  return {
    id: transaction.id,
    projectId,
    description: transaction.description,
    title: transaction.title,
  };
}
