import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertRegionAllocations } from '~src/data/store/modules/entities/region-allocations/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'regionAllocations';

export interface RegionAllocation {
  id: string;
  entityId: string;
  regionId?: Maybe<string>;
  date?: Maybe<Date>;
  allocation?: Maybe<number>;
}

const header: Array<keyof RegionAllocation> = [
  'id',
  'entityId',
  'regionId',
  'date',
  'allocation',
];

export const regionAllocationExportHandler = (
  data: RegionAllocation[],
): ExportHandler<RegionAllocation> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const regionAllocationImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<RegionAllocation> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertRegionAllocations(workspace.tenantId, workspace.id, ts)),
  };
};
