import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { createStockPositions } from '~src/data/store/modules/holdings/stock/position/requests';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { CreateStockPositionFormComponent } from './create-form.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  handleClose: () => void;
  stockPositionCreateInputInit: graphqlWorkspaceTypes.CreateStockPositionInputType;
}

export const CreateStockPositionDialogContentComponent = React.memo(
  (props: Props) => {
    const [stockPositionInput, setStockPositionInput] =
      React.useState<graphqlWorkspaceTypes.CreateStockPositionInputType>({
        ...props.stockPositionCreateInputInit,
      });
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      setStockPositionInput({ ...props.stockPositionCreateInputInit });
    }, [props.stockPositionCreateInputInit]);

    const handleCreatePosition = (): void => {
      dispatch(
        createStockPositions(props.workspace.tenantId, props.workspace.id, [
          stockPositionInput,
        ]),
      )
        .then((_stockPositions) => {
          props.handleClose();
        })
        .catch((err) => {
          console.info('FAILED', stockPositionInput, JSON.stringify(err));
        });
    };

    return (
      <>
        <DialogTitle id="form-dialog-title">Create valuation</DialogTitle>
        <DialogContent>
          <CreateStockPositionFormComponent
            stockPositionInput={stockPositionInput}
            setStockPositionInput={setStockPositionInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreatePosition} color="primary">
            Save
          </Button>
        </DialogActions>
      </>
    );
  },
);
