/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment AssetObj on AssetObj {\n    id\n    type\n    name\n    description\n    types\n    category {\n      id\n    }\n    assetClass\n    ISIN\n    VPS\n    lookup\n    entity {\n      id\n    }\n  }\n':
    types.AssetObjFragmentDoc,
  '\n  fragment Asset on Asset {\n    __typename\n    _type\n    ... on Stock {\n      ...Stock\n    }\n    ... on Bond {\n      ...Bond\n    }\n    ... on Loan {\n      ...Loan\n    }\n    ... on Warrant {\n      ...Warrant\n    }\n    ... on Fund {\n      ...Fund\n    }\n    ... on BankNote {\n      ...BankNote\n    }\n    ... on Crypto {\n      ...Crypto\n    }\n    ... on PrivateEquityFund {\n      ...PrivateEquityFundShallow\n    }\n  }\n':
    types.AssetFragmentDoc,
  '\n  fragment BankNote on BankNote {\n    __typename\n    _type\n    id\n    symbol\n    accountNumber\n    bank\n    description\n    asset {\n      ...AssetObj\n    }\n    currency {\n      id\n    }\n  }\n':
    types.BankNoteFragmentDoc,
  '\n  query getBankNotes($ids: [String!]) {\n    getBankNotes(ids: $ids) {\n      ...BankNote\n    }\n  }\n':
    types.GetBankNotesDocument,
  '\n  mutation createBankNotes($inputs: [CreateBankNoteInputType]) {\n    createBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n':
    types.CreateBankNotesDocument,
  '\n  mutation updateBankNotes($inputs: [UpdateBankNoteInputType]) {\n    updateBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n':
    types.UpdateBankNotesDocument,
  '\n  mutation upsertBankNotes($inputs: [UpsertBankNoteInputType]) {\n    upsertBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n':
    types.UpsertBankNotesDocument,
  '\n  mutation deleteBankNotes($inputs: [DeleteBankNoteInputType]) {\n    deleteBankNotes(inputs: $inputs)\n  }\n':
    types.DeleteBankNotesDocument,
  '\n  fragment Bond on Bond {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n':
    types.BondFragmentDoc,
  '\n  query getBonds($ids: [String!]) {\n    getBonds(ids: $ids) {\n      ...Bond\n    }\n  }\n':
    types.GetBondsDocument,
  '\n  mutation createBonds($inputs: [CreateBondInputType]) {\n    createBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n':
    types.CreateBondsDocument,
  '\n  mutation updateBonds($inputs: [UpdateBondInputType]) {\n    updateBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n':
    types.UpdateBondsDocument,
  '\n  mutation upsertBonds($inputs: [UpsertBondInputType]) {\n    upsertBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n':
    types.UpsertBondsDocument,
  '\n  mutation deleteBonds($inputs: [DeleteBondInputType]) {\n    deleteBonds(inputs: $inputs)\n  }\n':
    types.DeleteBondsDocument,
  '\n  fragment Crypto on Crypto {\n    __typename\n    _type\n    id\n    symbol\n    accountNumber\n    bank\n    description\n    asset {\n      ...AssetObj\n    }\n    currency {\n      id\n    }\n  }\n':
    types.CryptoFragmentDoc,
  '\n  query getCryptos($ids: [String!]) {\n    getCryptos(ids: $ids) {\n      ...Crypto\n    }\n  }\n':
    types.GetCryptosDocument,
  '\n  mutation createCryptos($inputs: [CreateCryptoInputType]) {\n    createCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n':
    types.CreateCryptosDocument,
  '\n  mutation updateCryptos($inputs: [UpdateCryptoInputType]) {\n    updateCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n':
    types.UpdateCryptosDocument,
  '\n  mutation upsertCryptos($inputs: [UpsertCryptoInputType]) {\n    upsertCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n':
    types.UpsertCryptosDocument,
  '\n  mutation deleteCryptos($inputs: [DeleteCryptoInputType]) {\n    deleteCryptos(inputs: $inputs)\n  }\n':
    types.DeleteCryptosDocument,
  '\n  fragment Fund on Fund {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n':
    types.FundFragmentDoc,
  '\n  query getFunds($ids: [String!]) {\n    getFunds(ids: $ids) {\n      ...Fund\n    }\n  }\n':
    types.GetFundsDocument,
  '\n  mutation createFunds($inputs: [CreateFundInputType]) {\n    createFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n':
    types.CreateFundsDocument,
  '\n  mutation updateFunds($inputs: [UpdateFundInputType]) {\n    updateFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n':
    types.UpdateFundsDocument,
  '\n  mutation upsertFunds($inputs: [UpsertFundInputType]) {\n    upsertFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n':
    types.UpsertFundsDocument,
  '\n  mutation deleteFunds($inputs: [DeleteFundInputType]) {\n    deleteFunds(inputs: $inputs)\n  }\n':
    types.DeleteFundsDocument,
  '\n  fragment Loan on Loan {\n    __typename\n    _type\n    id\n    interest\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n':
    types.LoanFragmentDoc,
  '\n  query getLoans($ids: [String!]) {\n    getLoans(ids: $ids) {\n      ...Loan\n    }\n  }\n':
    types.GetLoansDocument,
  '\n  mutation createLoans($inputs: [CreateLoanInputType]) {\n    createLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n':
    types.CreateLoansDocument,
  '\n  mutation updateLoans($inputs: [UpdateLoanInputType]) {\n    updateLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n':
    types.UpdateLoansDocument,
  '\n  mutation upsertLoans($inputs: [UpsertLoanInputType]) {\n    upsertLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n':
    types.UpsertLoansDocument,
  '\n  mutation deleteLoans($inputs: [DeleteLoanInputType]) {\n    deleteLoans(inputs: $inputs)\n  }\n':
    types.DeleteLoansDocument,
  '\n  fragment PrivateEquityFundCapitalCallTransfer on PrivateEquityFundCapitalCallTransfer {\n    id\n    capitalCall {\n      ...PrivateEquityFundCapitalCall\n    }\n    transfer {\n      ...Transfer\n    }\n    investor {\n      id\n    }\n  }\n':
    types.PrivateEquityFundCapitalCallTransferFragmentDoc,
  '\n  fragment PrivateEquityFundCapitalCall on PrivateEquityFundCapitalCall {\n    id\n    name\n    description\n    date\n    value\n  }\n':
    types.PrivateEquityFundCapitalCallFragmentDoc,
  '\n  query getPrivateEquityFundCapitalCalls($ids: [String!]) {\n    getPrivateEquityFundCapitalCalls(ids: $ids) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n':
    types.GetPrivateEquityFundCapitalCallsDocument,
  '\n  mutation createPrivateEquityFundCapitalCalls(\n    $inputs: [CreatePrivateEquityFundCapitalCallInputType]\n  ) {\n    createPrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n':
    types.CreatePrivateEquityFundCapitalCallsDocument,
  '\n  mutation updatePrivateEquityFundCapitalCalls(\n    $inputs: [UpdatePrivateEquityFundCapitalCallInputType]\n  ) {\n    updatePrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n':
    types.UpdatePrivateEquityFundCapitalCallsDocument,
  '\n  mutation upsertPrivateEquityFundCapitalCalls(\n    $inputs: [UpsertPrivateEquityFundCapitalCallInputType]\n  ) {\n    upsertPrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n':
    types.UpsertPrivateEquityFundCapitalCallsDocument,
  '\n  mutation deletePrivateEquityFundCapitalCalls(\n    $inputs: [DeletePrivateEquityFundCapitalCallInputType]\n  ) {\n    deletePrivateEquityFundCapitalCalls(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundCapitalCallsDocument,
  '\n  fragment PrivateEquityFundCapitalCommitment on PrivateEquityFundCapitalCommitment {\n    id\n    date\n    value\n    investor {\n      id\n    }\n  }\n':
    types.PrivateEquityFundCapitalCommitmentFragmentDoc,
  '\n  fragment PrivateEquityFundCapitalDistribution on PrivateEquityFundCapitalDistribution {\n    id\n    name\n    description\n    date\n    value\n  }\n':
    types.PrivateEquityFundCapitalDistributionFragmentDoc,
  '\n  fragment PrivateEquityFundCapitalDistributionTransfer on PrivateEquityFundCapitalDistributionTransfer {\n    id\n    capitalDistribution {\n      ...PrivateEquityFundCapitalDistribution\n    }\n    transfer {\n      ...Transfer\n    }\n    investor {\n      id\n    }\n  }\n':
    types.PrivateEquityFundCapitalDistributionTransferFragmentDoc,
  '\n  fragment PrivateEquityFundContact on PrivateEquityFundContact {\n    id\n    name\n    email\n  }\n':
    types.PrivateEquityFundContactFragmentDoc,
  '\n  query getPrivateEquityFundContacts($ids: [String!]) {\n    getPrivateEquityFundContacts(ids: $ids) {\n      ...PrivateEquityFundContact\n    }\n  }\n':
    types.GetPrivateEquityFundContactsDocument,
  '\n  mutation createPrivateEquityFundContacts(\n    $inputs: [CreatePrivateEquityFundContactInputType]\n  ) {\n    createPrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n':
    types.CreatePrivateEquityFundContactsDocument,
  '\n  mutation updatePrivateEquityFundContacts(\n    $inputs: [UpdatePrivateEquityFundContactInputType]\n  ) {\n    updatePrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n':
    types.UpdatePrivateEquityFundContactsDocument,
  '\n  mutation upsertPrivateEquityFundContacts(\n    $inputs: [UpsertPrivateEquityFundContactInputType]\n  ) {\n    upsertPrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n':
    types.UpsertPrivateEquityFundContactsDocument,
  '\n  mutation deletePrivateEquityFundContacts(\n    $inputs: [DeletePrivateEquityFundContactInputType]\n  ) {\n    deletePrivateEquityFundContacts(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundContactsDocument,
  '\n  fragment PrivateEquityFundInvestment on PrivateEquityFundInvestment {\n    id\n    name\n    description\n    exit\n    image\n    holding {\n      ...Holding\n    }\n  }\n':
    types.PrivateEquityFundInvestmentFragmentDoc,
  '\n  query getPrivateEquityFundInvestments(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundInvestments(ids: $ids) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n':
    types.GetPrivateEquityFundInvestmentsDocument,
  '\n  mutation createPrivateEquityFundInvestments(\n    $inputs: [CreatePrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n':
    types.CreatePrivateEquityFundInvestmentsDocument,
  '\n  mutation updatePrivateEquityFundInvestments(\n    $inputs: [UpdatePrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n':
    types.UpdatePrivateEquityFundInvestmentsDocument,
  '\n  mutation upsertPrivateEquityFundInvestments(\n    $inputs: [UpsertPrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n':
    types.UpsertPrivateEquityFundInvestmentsDocument,
  '\n  mutation deletePrivateEquityFundInvestments(\n    $inputs: [DeletePrivateEquityFundInvestmentInputType]\n  ) {\n    deletePrivateEquityFundInvestments(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundInvestmentsDocument,
  '\n  fragment PrivateEquityFundInvestor on PrivateEquityFundInvestor {\n    id\n    name\n    description\n    commitments {\n      ...PrivateEquityFundCapitalCommitment\n    }\n    capitalCallTransfer {\n      ...PrivateEquityFundCapitalCallTransfer\n    }\n    capitalDistributionTransfer {\n      ...PrivateEquityFundCapitalDistributionTransfer\n    }\n    contacts {\n      ...PrivateEquityFundContact\n    }\n    users {\n      ...User\n    }\n  }\n':
    types.PrivateEquityFundInvestorFragmentDoc,
  '\n  query getPrivateEquityFundInvestors($ids: [String!]) {\n    getPrivateEquityFundInvestors(ids: $ids) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n':
    types.GetPrivateEquityFundInvestorsDocument,
  '\n  mutation createPrivateEquityFundInvestors(\n    $inputs: [CreatePrivateEquityFundInvestorInputType]\n  ) {\n    createPrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n':
    types.CreatePrivateEquityFundInvestorsDocument,
  '\n  mutation updatePrivateEquityFundInvestors(\n    $inputs: [UpdatePrivateEquityFundInvestorInputType]\n  ) {\n    updatePrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n':
    types.UpdatePrivateEquityFundInvestorsDocument,
  '\n  mutation upsertPrivateEquityFundInvestors(\n    $inputs: [UpsertPrivateEquityFundInvestorInputType]\n  ) {\n    upsertPrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n':
    types.UpsertPrivateEquityFundInvestorsDocument,
  '\n  mutation deletePrivateEquityFundInvestors(\n    $inputs: [DeletePrivateEquityFundInvestorInputType]\n  ) {\n    deletePrivateEquityFundInvestors(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundInvestorsDocument,
  '\n  fragment PrivateEquityFundShallow on PrivateEquityFund {\n    __typename\n    _type\n    id\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n  \n':
    types.PrivateEquityFundShallowFragmentDoc,
  '\n  fragment PrivateEquityFund on PrivateEquityFund {\n    __typename\n    _type\n    id\n    description\n    fundSetup {\n      ...PrivateEquityFundSetup\n    }\n    investors {\n      ...PrivateEquityFundInvestor\n    }\n    investments {\n      ...PrivateEquityFundInvestment\n    }\n    capitalCalls {\n      ...PrivateEquityFundCapitalCall\n    }\n    capitalDistributions {\n      ...PrivateEquityFundCapitalDistribution\n    }\n    team {\n      ...PrivateEquityFundTeamMember\n    }\n    asset {\n      ...AssetObj\n    }\n  }\n':
    types.PrivateEquityFundFragmentDoc,
  '\n  query getPrivateEquityFunds(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFunds(ids: $ids) {\n      ...PrivateEquityFund\n    }\n  }\n':
    types.GetPrivateEquityFundsDocument,
  '\n  mutation createPrivateEquityFunds(\n    $inputs: [CreatePrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n':
    types.CreatePrivateEquityFundsDocument,
  '\n  mutation updatePrivateEquityFunds(\n    $inputs: [UpdatePrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n':
    types.UpdatePrivateEquityFundsDocument,
  '\n  mutation upsertPrivateEquityFunds(\n    $inputs: [UpsertPrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n':
    types.UpsertPrivateEquityFundsDocument,
  '\n  mutation deletePrivateEquityFunds(\n    $inputs: [DeletePrivateEquityFundInputType]\n  ) {\n    deletePrivateEquityFunds(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundsDocument,
  '\n  fragment PrivateEquityFundSetup on PrivateEquityFundSetup {\n    id\n    name\n    description\n    managementFee\n    hurdleRate\n    carry\n    carriedInterestType\n    startDate\n    endDate\n    endInvestmentDate\n  }\n':
    types.PrivateEquityFundSetupFragmentDoc,
  '\n  fragment PrivateEquityFundTeamMember on PrivateEquityFundTeamMember {\n    id\n    name\n    email\n    tel\n    title\n    description\n    education\n  }\n':
    types.PrivateEquityFundTeamMemberFragmentDoc,
  '\n  query getPrivateEquityFundTeamMembers($ids: [String!]) {\n    getPrivateEquityFundTeamMembers(ids: $ids) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n':
    types.GetPrivateEquityFundTeamMembersDocument,
  '\n  mutation createPrivateEquityFundTeamMembers(\n    $inputs: [CreatePrivateEquityFundTeamMemberInputType]\n  ) {\n    createPrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n':
    types.CreatePrivateEquityFundTeamMembersDocument,
  '\n  mutation updatePrivateEquityFundTeamMembers(\n    $inputs: [UpdatePrivateEquityFundTeamMemberInputType]\n  ) {\n    updatePrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n':
    types.UpdatePrivateEquityFundTeamMembersDocument,
  '\n  mutation upsertPrivateEquityFundTeamMembers(\n    $inputs: [UpsertPrivateEquityFundTeamMemberInputType]\n  ) {\n    upsertPrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n':
    types.UpsertPrivateEquityFundTeamMembersDocument,
  '\n  mutation deletePrivateEquityFundTeamMembers(\n    $inputs: [DeletePrivateEquityFundTeamMemberInputType]\n  ) {\n    deletePrivateEquityFundTeamMembers(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundTeamMembersDocument,
  '\n  fragment Stock on Stock {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n':
    types.StockFragmentDoc,
  '\n  query getStocks($ids: [String!]) {\n    getStocks(ids: $ids) {\n      ...Stock\n    }\n  }\n':
    types.GetStocksDocument,
  '\n  mutation createStocks($inputs: [CreateStockInputType]) {\n    createStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n':
    types.CreateStocksDocument,
  '\n  mutation updateStocks($inputs: [UpdateStockInputType]) {\n    updateStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n':
    types.UpdateStocksDocument,
  '\n  mutation upsertStocks($inputs: [UpsertStockInputType]) {\n    upsertStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n':
    types.UpsertStocksDocument,
  '\n  mutation deleteStocks($inputs: [DeleteStockInputType]) {\n    deleteStocks(inputs: $inputs)\n  }\n':
    types.DeleteStocksDocument,
  '\n  fragment Warrant on Warrant {\n    __typename\n    _type\n    id\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n':
    types.WarrantFragmentDoc,
  '\n  query getWarrants($ids: [String!]) {\n    getWarrants(ids: $ids) {\n      ...Warrant\n    }\n  }\n':
    types.GetWarrantsDocument,
  '\n  mutation createWarrants($inputs: [CreateWarrantInputType]) {\n    createWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n':
    types.CreateWarrantsDocument,
  '\n  mutation updateWarrants($inputs: [UpdateWarrantInputType]) {\n    updateWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n':
    types.UpdateWarrantsDocument,
  '\n  mutation upsertWarrants($inputs: [UpsertWarrantInputType]) {\n    upsertWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n':
    types.UpsertWarrantsDocument,
  '\n  mutation deleteWarrants($inputs: [DeleteWarrantInputType]) {\n    deleteWarrants(inputs: $inputs)\n  }\n':
    types.DeleteWarrantsDocument,
  '\n  query getAssets($ids: [String!]) {\n    getAssets(ids: $ids) {\n      ...Asset\n    }\n  }\n':
    types.GetAssetsDocument,
  '\n  query getAssetObjs($ids: [String!]) {\n    getAssetObjs(ids: $ids) {\n      ...AssetObj\n    }\n  }\n':
    types.GetAssetObjsDocument,
  '\n  mutation createAssets($inputs: [CreateAssetInputType]) {\n    createAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n':
    types.CreateAssetsDocument,
  '\n  mutation updateAssets($inputs: [UpdateAssetInputType]) {\n    updateAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n':
    types.UpdateAssetsDocument,
  '\n  mutation upsertAssets($inputs: [UpsertAssetInputType]) {\n    upsertAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n':
    types.UpsertAssetsDocument,
  '\n  mutation deleteAssets($inputs: [DeleteAssetInputType]) {\n    deleteAssets(inputs: $inputs)\n  }\n':
    types.DeleteAssetsDocument,
  '\n  query getAssetCategories($ids: [String!]) {\n    getAssetCategories(ids: $ids) {\n      ...AssetCategory\n    }\n  }\n':
    types.GetAssetCategoriesDocument,
  '\n  mutation createAssetCategories($inputs: [CreateAssetCategoryInputType]) {\n    createAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n':
    types.CreateAssetCategoriesDocument,
  '\n  mutation updateAssetCategories($inputs: [UpdateAssetCategoryInputType]) {\n    updateAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n':
    types.UpdateAssetCategoriesDocument,
  '\n  mutation upsertAssetCategories($inputs: [UpsertAssetCategoryInputType]) {\n    upsertAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n':
    types.UpsertAssetCategoriesDocument,
  '\n  mutation deleteAssetCategories($inputs: [DeleteAssetCategoryInputType]) {\n    deleteAssetCategories(inputs: $inputs)\n  }\n':
    types.DeleteAssetCategoriesDocument,
  '\n  fragment AssetCategory on AssetCategory {\n    id\n    name\n  }\n':
    types.AssetCategoryFragmentDoc,
  '\n  fragment DashboardElement on DashboardElement {\n    id\n    name\n    type\n    config\n    media {\n      ...DashboardMedia\n    }\n  }\n':
    types.DashboardElementFragmentDoc,
  '\n  query getDashboardElements($ids: [String!]) {\n    getDashboardElements(ids: $ids) {\n      ...DashboardElement\n    }\n  }\n':
    types.GetDashboardElementsDocument,
  '\n  mutation createDashboardElements($inputs: [CreateDashboardElementInputType]) {\n    createDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n':
    types.CreateDashboardElementsDocument,
  '\n  mutation updateDashboardElements($inputs: [UpdateDashboardElementInputType]) {\n    updateDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n':
    types.UpdateDashboardElementsDocument,
  '\n  mutation upsertDashboardElements($inputs: [UpsertDashboardElementInputType]) {\n    upsertDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n':
    types.UpsertDashboardElementsDocument,
  '\n  mutation deleteDashboardElements($inputs: [String!]) {\n    deleteDashboardElements(inputs: $inputs)\n  }\n':
    types.DeleteDashboardElementsDocument,
  '\n  query getDashboardMedias($ids: [String!]) {\n    getDashboardMedias(ids: $ids) {\n      ...DashboardMedia\n    }\n  }\n':
    types.GetDashboardMediasDocument,
  '\n  mutation createDashboardMedias($inputs: [CreateDashboardMediaInputType]) {\n    createDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n':
    types.CreateDashboardMediasDocument,
  '\n  mutation updateDashboardMedias($inputs: [UpdateDashboardMediaInputType]) {\n    updateDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n':
    types.UpdateDashboardMediasDocument,
  '\n  mutation upsertDashboardMedias($inputs: [UpsertDashboardMediaInputType]) {\n    upsertDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n':
    types.UpsertDashboardMediasDocument,
  '\n  mutation deleteDashboardMedias($inputs: [String!]) {\n    deleteDashboardMedias(inputs: $inputs)\n  }\n':
    types.DeleteDashboardMediasDocument,
  '\n  fragment DashboardMedia on DashboardMedia {\n    id\n    file {\n      id\n    }\n  }\n':
    types.DashboardMediaFragmentDoc,
  '\n  fragment Scheduler on Scheduler {\n    id\n    cron\n  }\n':
    types.SchedulerFragmentDoc,
  '\n  query getSchedulers($ids: [String!]) {\n    getSchedulers(ids: $ids) {\n      ...Scheduler\n    }\n  }\n':
    types.GetSchedulersDocument,
  '\n  mutation createSchedulers($inputs: [CreateSchedulerInputType]) {\n    createSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n':
    types.CreateSchedulersDocument,
  '\n  mutation updateSchedulers($inputs: [UpdateSchedulerInputType]) {\n    updateSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n':
    types.UpdateSchedulersDocument,
  '\n  mutation upsertSchedulers($inputs: [UpsertSchedulerInputType]) {\n    upsertSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n':
    types.UpsertSchedulersDocument,
  '\n  mutation deleteSchedulers($inputs: [DeleteSchedulerInputType]) {\n    deleteSchedulers(inputs: $inputs)\n  }\n':
    types.DeleteSchedulersDocument,
  '\n  query getEntities($ids: [String!]) {\n    getEntities(ids: $ids) {\n      ...Entity\n    }\n  }\n':
    types.GetEntitiesDocument,
  '\n  mutation createEntities($inputs: [CreateEntityInputType]) {\n    createEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n':
    types.CreateEntitiesDocument,
  '\n  mutation updateEntities($inputs: [UpdateEntityInputType]) {\n    updateEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n':
    types.UpdateEntitiesDocument,
  '\n  mutation upsertEntities($inputs: [UpsertEntityInputType]) {\n    upsertEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n':
    types.UpsertEntitiesDocument,
  '\n  mutation deleteEntities($inputs: [DeleteEntityInputType]) {\n    deleteEntities(inputs: $inputs)\n  }\n':
    types.DeleteEntitiesDocument,
  '\n  fragment Entity on Entity {\n    __typename\n    id\n    name\n    description\n    sector\n    website\n    regionAllocations {\n      ...RegionAllocation\n    }\n    media {\n      ...EntityMedia\n    }\n  }\n':
    types.EntityFragmentDoc,
  '\n  fragment EntityMedia on EntityMedia {\n    id\n    file {\n      ...File\n    }\n    entity {\n      id\n    }\n  }\n':
    types.EntityMediaFragmentDoc,
  '\n  query getEntityMedias($ids: [String!]) {\n    getEntityMedias(ids: $ids) {\n      ...EntityMedia\n    }\n  }\n':
    types.GetEntityMediasDocument,
  '\n  mutation createEntityMedias($inputs: [CreateEntityMediaInputType]) {\n    createEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n':
    types.CreateEntityMediasDocument,
  '\n  mutation updateEntityMedias($inputs: [UpdateEntityMediaInputType]) {\n    updateEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n':
    types.UpdateEntityMediasDocument,
  '\n  mutation upsertEntityMedias($inputs: [UpsertEntityMediaInputType]) {\n    upsertEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n':
    types.UpsertEntityMediasDocument,
  '\n  mutation deleteEntityMedias($inputs: [DeleteEntityMediaInputType]) {\n    deleteEntityMedias(inputs: $inputs)\n  }\n':
    types.DeleteEntityMediasDocument,
  '\n  fragment RegionAllocation on RegionAllocation {\n    id\n    entity {\n      id\n    }\n    region {\n      id\n    }\n    date\n    allocation\n  }\n':
    types.RegionAllocationFragmentDoc,
  '\n  query getRegionAllocations($ids: [String!]) {\n    getRegionAllocations(ids: $ids) {\n      ...RegionAllocation\n    }\n  }\n':
    types.GetRegionAllocationsDocument,
  '\n  mutation createRegionAllocations($inputs: [CreateRegionAllocationInputType]) {\n    createRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n':
    types.CreateRegionAllocationsDocument,
  '\n  mutation updateRegionAllocations($inputs: [UpdateRegionAllocationInputType]) {\n    updateRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n':
    types.UpdateRegionAllocationsDocument,
  '\n  mutation upsertRegionAllocations($inputs: [UpsertRegionAllocationInputType]) {\n    upsertRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n':
    types.UpsertRegionAllocationsDocument,
  '\n  mutation deleteRegionAllocations($inputs: [DeleteRegionAllocationInputType]) {\n    deleteRegionAllocations(inputs: $inputs)\n  }\n':
    types.DeleteRegionAllocationsDocument,
  '\n  fragment Event on Event {\n    id\n    name\n    description\n    date\n    project {\n      id\n    }\n    portfolios {\n      id\n    }\n    entities {\n      id\n    }\n    holdings {\n      id\n    }\n    transactions {\n      id\n    }\n    files {\n      id\n    }\n  }\n':
    types.EventFragmentDoc,
  '\n  query getEvents($ids: [String!]) {\n    getEvents(ids: $ids) {\n      ...Event\n    }\n  }\n':
    types.GetEventsDocument,
  '\n  query getEventsByProject($projectId: String!) {\n    getEventsByProject(projectId: $projectId) {\n      ...Event\n    }\n  }\n':
    types.GetEventsByProjectDocument,
  '\n  mutation createEvents($inputs: [CreateEventInputType]) {\n    createEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n':
    types.CreateEventsDocument,
  '\n  mutation updateEvents($inputs: [UpdateEventInputType]) {\n    updateEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n':
    types.UpdateEventsDocument,
  '\n  mutation upsertEvents($inputs: [UpsertEventInputType]) {\n    upsertEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n':
    types.UpsertEventsDocument,
  '\n  mutation deleteEvents($inputs: [DeleteEventInputType]) {\n    deleteEvents(inputs: $inputs)\n  }\n':
    types.DeleteEventsDocument,
  '\n  fragment File on File {\n    id\n    key\n    name\n    type\n    description\n  }\n':
    types.FileFragmentDoc,
  '\n  query getFiles($ids: [String!]) {\n    getFiles(ids: $ids) {\n      ...File\n    }\n  }\n':
    types.GetFilesDocument,
  '\n  mutation createFiles($inputs: [CreateFileInputType]) {\n    createFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n':
    types.CreateFilesDocument,
  '\n  mutation updateFiles($inputs: [UpdateFileInputType]) {\n    updateFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n':
    types.UpdateFilesDocument,
  '\n  mutation upsertFiles($inputs: [UpsertFileInputType]) {\n    upsertFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n':
    types.UpsertFilesDocument,
  '\n  mutation deleteFiles($inputs: [DeleteFileInputType]) {\n    deleteFiles(inputs: $inputs)\n  }\n':
    types.DeleteFilesDocument,
  '\n  fragment Group on Group {\n    id\n    name\n    users {\n      ...User\n    }\n  }\n  \n':
    types.GroupFragmentDoc,
  '\n  query Hello {\n    hello\n  }\n': types.HelloDocument,
  '\n  fragment Commitment on Commitment {\n    id\n    commitmentDate\n    startDate\n    endDate\n    value\n    type\n    description\n    holding {\n      id\n    }\n    transfers {\n      id\n      hash\n    }\n    currency {\n      id\n    }\n  }\n':
    types.CommitmentFragmentDoc,
  '\n  query getCommitments($ids: [String!]) {\n    getCommitments(ids: $ids) {\n      ...Commitment\n    }\n  }\n  \n':
    types.GetCommitmentsDocument,
  '\n  mutation createCommitments($inputs: [CreateCommitmentInputType]) {\n    createCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n':
    types.CreateCommitmentsDocument,
  '\n  mutation updateCommitments($inputs: [UpdateCommitmentInputType]) {\n    updateCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n':
    types.UpdateCommitmentsDocument,
  '\n  mutation upsertCommitments($inputs: [UpsertCommitmentInputType]) {\n    upsertCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n':
    types.UpsertCommitmentsDocument,
  '\n  mutation deleteCommitments($inputs: [DeleteCommitmentInputType]) {\n    deleteCommitments(inputs: $inputs)\n  }\n':
    types.DeleteCommitmentsDocument,
  '\n  fragment HoldingConfig on HoldingConfig {\n    id\n    currentDashboardConfigId\n    holdingDashboardConfigs {\n      ...HoldingDashboardConfig\n    }\n  }\n':
    types.HoldingConfigFragmentDoc,
  '\n  query getHoldingConfigs($holdingId: String!) {\n    getHoldingConfigs(holdingId: $holdingId) {\n      ...HoldingConfig\n    }\n  }\n':
    types.GetHoldingConfigsDocument,
  '\n  mutation createHoldingConfigs(\n    $holdingId: String!\n    $inputs: [CreateHoldingConfigInputType]\n  ) {\n    createHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n':
    types.CreateHoldingConfigsDocument,
  '\n  mutation updateHoldingConfigs(\n    $holdingId: String!\n    $inputs: [UpdateHoldingConfigInputType]\n  ) {\n    updateHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n':
    types.UpdateHoldingConfigsDocument,
  '\n  mutation upsertHoldingConfigs(\n    $holdingId: String!\n    $inputs: [UpsertHoldingConfigInputType]\n  ) {\n    upsertHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n':
    types.UpsertHoldingConfigsDocument,
  '\n  mutation deleteHoldingConfigs($holdingId: String!, $inputs: [Int!]) {\n    deleteHoldingConfigs(holdingId: $holdingId, inputs: $inputs)\n  }\n':
    types.DeleteHoldingConfigsDocument,
  '\n  fragment HoldingDashboardConfig on HoldingDashboardConfig {\n    id\n    name\n    layouts\n    compactType\n    elements {\n      ...DashboardElement\n    }\n  }\n':
    types.HoldingDashboardConfigFragmentDoc,
  '\n  mutation createHoldingDashboardConfigs(\n    $inputs: [CreateHoldingDashboardConfigInputType]\n  ) {\n    createHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n':
    types.CreateHoldingDashboardConfigsDocument,
  '\n  mutation updateHoldingDashboardConfigs(\n    $inputs: [UpdateHoldingDashboardConfigInputType]\n  ) {\n    updateHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n':
    types.UpdateHoldingDashboardConfigsDocument,
  '\n  mutation upsertHoldingDashboardConfigs(\n    $inputs: [UpsertHoldingDashboardConfigInputType]\n  ) {\n    upsertHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n':
    types.UpsertHoldingDashboardConfigsDocument,
  '\n  mutation deleteHoldingDashboardConfigs($inputs: [String!]) {\n    deleteHoldingDashboardConfigs(inputs: $inputs)\n  }\n':
    types.DeleteHoldingDashboardConfigsDocument,
  '\n  fragment FavoriteHolding on FavoriteHolding {\n    id\n    holding {\n      id\n    }\n  }\n':
    types.FavoriteHoldingFragmentDoc,
  '\n  query getFavoriteHoldings($projectId: String!, $ids: [String!]) {\n    getFavoriteHoldings(projectId: $projectId, ids: $ids) {\n      ...FavoriteHolding\n    }\n  }\n':
    types.GetFavoriteHoldingsDocument,
  '\n  mutation createFavoriteHoldings(\n    $projectId: String!\n    $inputs: [CreateFavoriteHoldingInputType]\n  ) {\n    createFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n':
    types.CreateFavoriteHoldingsDocument,
  '\n  mutation updateFavoriteHoldings(\n    $projectId: String!\n    $inputs: [UpdateFavoriteHoldingInputType]\n  ) {\n    updateFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n':
    types.UpdateFavoriteHoldingsDocument,
  '\n  mutation upsertFavoriteHoldings(\n    $projectId: String!\n    $inputs: [UpsertFavoriteHoldingInputType]\n  ) {\n    upsertFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n':
    types.UpsertFavoriteHoldingsDocument,
  '\n  mutation deleteFavoriteHoldings(\n    $projectId: String!\n    $inputs: [DeleteFavoriteHoldingInputType]\n  ) {\n    deleteFavoriteHoldings(projectId: $projectId, inputs: $inputs)\n  }\n':
    types.DeleteFavoriteHoldingsDocument,
  '\n  fragment BankNoteHolding on BankNoteHolding {\n    __typename\n    id\n    bankNote {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n':
    types.BankNoteHoldingFragmentDoc,
  '\n  query getBankNoteHoldings($ids: [String!]) {\n    getBankNoteHoldings(ids: $ids) {\n      ...BankNoteHolding\n    }\n  }\n':
    types.GetBankNoteHoldingsDocument,
  '\n  mutation createBankNoteHoldings($inputs: [CreateBankNoteHoldingInputType]) {\n    createBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n':
    types.CreateBankNoteHoldingsDocument,
  '\n  mutation updateBankNoteHoldings($inputs: [UpdateBankNoteHoldingInputType]) {\n    updateBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n':
    types.UpdateBankNoteHoldingsDocument,
  '\n  mutation upsertBankNoteHoldings($inputs: [UpsertBankNoteHoldingInputType]) {\n    upsertBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n':
    types.UpsertBankNoteHoldingsDocument,
  '\n  mutation deleteBankNoteHoldings($inputs: [DeleteBankNoteHoldingInputType]) {\n    deleteBankNoteHoldings(inputs: $inputs)\n  }\n':
    types.DeleteBankNoteHoldingsDocument,
  '\n  fragment BondHolding on BondHolding {\n    __typename\n    id\n    bond {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    bondPositions {\n      ...BondPosition\n    }\n    bondTrades {\n      ...BondTrade\n    }\n  }\n':
    types.BondHoldingFragmentDoc,
  '\n  fragment BondPosition on BondPosition {\n    id\n    shares\n    date\n    bondHolding {\n      id\n    }\n  }\n':
    types.BondPositionFragmentDoc,
  '\n  fragment BondTrade on BondTrade {\n    id\n    shares\n    sharePrice\n    bondHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n':
    types.BondTradeFragmentDoc,
  '\n  query getBondHoldings($ids: [String!]) {\n    getBondHoldings(ids: $ids) {\n      ...BondHolding\n    }\n  }\n':
    types.GetBondHoldingsDocument,
  '\n  mutation createBondHoldings($inputs: [CreateBondHoldingInputType]) {\n    createBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n':
    types.CreateBondHoldingsDocument,
  '\n  mutation updateBondHoldings($inputs: [UpdateBondHoldingInputType]) {\n    updateBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n':
    types.UpdateBondHoldingsDocument,
  '\n  mutation upsertBondHoldings($inputs: [UpsertBondHoldingInputType]) {\n    upsertBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n':
    types.UpsertBondHoldingsDocument,
  '\n  mutation deleteBondHoldings($inputs: [DeleteBondHoldingInputType]) {\n    deleteBondHoldings(inputs: $inputs)\n  }\n':
    types.DeleteBondHoldingsDocument,
  '\n  query getBondPositions($ids: [String!]) {\n    getBondPositions(ids: $ids) {\n      ...BondPosition\n    }\n  }\n':
    types.GetBondPositionsDocument,
  '\n  mutation createBondPositions($inputs: [CreateBondPositionInputType]) {\n    createBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n':
    types.CreateBondPositionsDocument,
  '\n  mutation updateBondPositions($inputs: [UpdateBondPositionInputType]) {\n    updateBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n':
    types.UpdateBondPositionsDocument,
  '\n  mutation upsertBondPositions($inputs: [UpsertBondPositionInputType]) {\n    upsertBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n':
    types.UpsertBondPositionsDocument,
  '\n  mutation deleteBondPositions($inputs: [DeleteBondPositionInputType]) {\n    deleteBondPositions(inputs: $inputs)\n  }\n':
    types.DeleteBondPositionsDocument,
  '\n  query getBondTrades($ids: [String!]) {\n    getBondTrades(ids: $ids) {\n      ...BondTrade\n    }\n  }\n':
    types.GetBondTradesDocument,
  '\n  mutation createBondTrades($inputs: [CreateBondTradeInputType]) {\n    createBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n':
    types.CreateBondTradesDocument,
  '\n  mutation updateBondTrades($inputs: [UpdateBondTradeInputType]) {\n    updateBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n':
    types.UpdateBondTradesDocument,
  '\n  mutation upsertBondTrades($inputs: [UpsertBondTradeInputType]) {\n    upsertBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n':
    types.UpsertBondTradesDocument,
  '\n  mutation deleteBondTrades($inputs: [DeleteBondTradeInputType]) {\n    deleteBondTrades(inputs: $inputs)\n  }\n':
    types.DeleteBondTradesDocument,
  '\n  fragment CryptoHolding on CryptoHolding {\n    __typename\n    id\n    crypto {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n':
    types.CryptoHoldingFragmentDoc,
  '\n  query getCryptoHoldings($ids: [String!]) {\n    getCryptoHoldings(ids: $ids) {\n      ...CryptoHolding\n    }\n  }\n':
    types.GetCryptoHoldingsDocument,
  '\n  mutation createCryptoHoldings($inputs: [CreateCryptoHoldingInputType]) {\n    createCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n':
    types.CreateCryptoHoldingsDocument,
  '\n  mutation updateCryptoHoldings($inputs: [UpdateCryptoHoldingInputType]) {\n    updateCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n':
    types.UpdateCryptoHoldingsDocument,
  '\n  mutation upsertCryptoHoldings($inputs: [UpsertCryptoHoldingInputType]) {\n    upsertCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n':
    types.UpsertCryptoHoldingsDocument,
  '\n  mutation deleteCryptoHoldings($inputs: [DeleteCryptoHoldingInputType]) {\n    deleteCryptoHoldings(inputs: $inputs)\n  }\n':
    types.DeleteCryptoHoldingsDocument,
  '\n  fragment FundHolding on FundHolding {\n    __typename\n    id\n    fund {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    fundPositions {\n      ...FundPosition\n    }\n    fundTrades {\n      ...FundTrade\n    }\n  }\n':
    types.FundHoldingFragmentDoc,
  '\n  fragment FundPosition on FundPosition {\n    id\n    shares\n    date\n    fundHolding {\n      id\n    }\n  }\n':
    types.FundPositionFragmentDoc,
  '\n  fragment FundTrade on FundTrade {\n    id\n    shares\n    sharePrice\n    fundHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n':
    types.FundTradeFragmentDoc,
  '\n  query getFundHoldings($ids: [String!]) {\n    getFundHoldings(ids: $ids) {\n      ...FundHolding\n    }\n  }\n':
    types.GetFundHoldingsDocument,
  '\n  mutation createFundHoldings($inputs: [CreateFundHoldingInputType]) {\n    createFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n':
    types.CreateFundHoldingsDocument,
  '\n  mutation updateFundHoldings($inputs: [UpdateFundHoldingInputType]) {\n    updateFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n':
    types.UpdateFundHoldingsDocument,
  '\n  mutation upsertFundHoldings($inputs: [UpsertFundHoldingInputType]) {\n    upsertFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n':
    types.UpsertFundHoldingsDocument,
  '\n  mutation deleteFundHoldings($inputs: [DeleteFundHoldingInputType]) {\n    deleteFundHoldings(inputs: $inputs)\n  }\n':
    types.DeleteFundHoldingsDocument,
  '\n  query getFundPositions($ids: [String!]) {\n    getFundPositions(ids: $ids) {\n      ...FundPosition\n    }\n  }\n':
    types.GetFundPositionsDocument,
  '\n  mutation createFundPositions($inputs: [CreateFundPositionInputType]) {\n    createFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n':
    types.CreateFundPositionsDocument,
  '\n  mutation updateFundPositions($inputs: [UpdateFundPositionInputType]) {\n    updateFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n':
    types.UpdateFundPositionsDocument,
  '\n  mutation upsertFundPositions($inputs: [UpsertFundPositionInputType]) {\n    upsertFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n':
    types.UpsertFundPositionsDocument,
  '\n  mutation deleteFundPositions($inputs: [DeleteFundPositionInputType]) {\n    deleteFundPositions(inputs: $inputs)\n  }\n':
    types.DeleteFundPositionsDocument,
  '\n  query getFundTrades($ids: [String!]) {\n    getFundTrades(ids: $ids) {\n      ...FundTrade\n    }\n  }\n':
    types.GetFundTradesDocument,
  '\n  mutation createFundTrades($inputs: [CreateFundTradeInputType]) {\n    createFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n':
    types.CreateFundTradesDocument,
  '\n  mutation updateFundTrades($inputs: [UpdateFundTradeInputType]) {\n    updateFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n':
    types.UpdateFundTradesDocument,
  '\n  mutation upsertFundTrades($inputs: [UpsertFundTradeInputType]) {\n    upsertFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n':
    types.UpsertFundTradesDocument,
  '\n  mutation deleteFundTrades($inputs: [DeleteFundTradeInputType]) {\n    deleteFundTrades(inputs: $inputs)\n  }\n':
    types.DeleteFundTradesDocument,
  '\n  fragment LoanHolding on LoanHolding {\n    __typename\n    id\n    loan {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n':
    types.LoanHoldingFragmentDoc,
  '\n  query getLoanHoldings($ids: [String!]) {\n    getLoanHoldings(ids: $ids) {\n      ...LoanHolding\n    }\n  }\n':
    types.GetLoanHoldingsDocument,
  '\n  mutation createLoanHoldings($inputs: [CreateLoanHoldingInputType]) {\n    createLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n':
    types.CreateLoanHoldingsDocument,
  '\n  mutation updateLoanHoldings($inputs: [UpdateLoanHoldingInputType]) {\n    updateLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n':
    types.UpdateLoanHoldingsDocument,
  '\n  mutation upsertLoanHoldings($inputs: [UpsertLoanHoldingInputType]) {\n    upsertLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n':
    types.UpsertLoanHoldingsDocument,
  '\n  mutation deleteLoanHoldings($inputs: [DeleteLoanHoldingInputType]) {\n    deleteLoanHoldings(inputs: $inputs)\n  }\n':
    types.DeleteLoanHoldingsDocument,
  '\n  fragment GoalRule on GoalRule {\n    name\n    formula\n  }\n':
    types.GoalRuleFragmentDoc,
  '\n  fragment StockGoalRule on StockGoalRule {\n    id\n    goalRule {\n      ...GoalRule\n    }\n    stockHolding {\n      id\n    }\n  }\n':
    types.StockGoalRuleFragmentDoc,
  '\n  fragment StockHolding on StockHolding {\n    __typename\n    id\n    stock {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    stockPositions {\n      ...StockPosition\n    }\n    stockTrades {\n      ...StockTrade\n    }\n    stockGoalRules {\n      ...StockGoalRule\n    }\n  }\n':
    types.StockHoldingFragmentDoc,
  '\n  fragment StockPosition on StockPosition {\n    id\n    shares\n    date\n    stockHolding {\n      id\n    }\n  }\n':
    types.StockPositionFragmentDoc,
  '\n  fragment StockTrade on StockTrade {\n    id\n    shares\n    sharePrice\n    stockHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n':
    types.StockTradeFragmentDoc,
  '\n  query getStockGoalRules($ids: [String!]) {\n    getStockGoalRules(ids: $ids) {\n      ...StockGoalRule\n    }\n  }\n':
    types.GetStockGoalRulesDocument,
  '\n  mutation createStockGoalRules($inputs: [CreateStockGoalRuleInputType]) {\n    createStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n':
    types.CreateStockGoalRulesDocument,
  '\n  mutation updateStockGoalRules($inputs: [UpdateStockGoalRuleInputType]) {\n    updateStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n':
    types.UpdateStockGoalRulesDocument,
  '\n  mutation upsertStockGoalRules($inputs: [UpsertStockGoalRuleInputType]) {\n    upsertStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n':
    types.UpsertStockGoalRulesDocument,
  '\n  mutation deleteStockGoalRules($inputs: [DeleteStockGoalRuleInputType]) {\n    deleteStockGoalRules(inputs: $inputs)\n  }\n':
    types.DeleteStockGoalRulesDocument,
  '\n  query getStockHoldings($ids: [String!]) {\n    getStockHoldings(ids: $ids) {\n      ...StockHolding\n    }\n  }\n':
    types.GetStockHoldingsDocument,
  '\n  mutation createStockHoldings($inputs: [CreateStockHoldingInputType]) {\n    createStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n':
    types.CreateStockHoldingsDocument,
  '\n  mutation updateStockHoldings($inputs: [UpdateStockHoldingInputType]) {\n    updateStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n':
    types.UpdateStockHoldingsDocument,
  '\n  mutation upsertStockHoldings($inputs: [UpsertStockHoldingInputType]) {\n    upsertStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n':
    types.UpsertStockHoldingsDocument,
  '\n  mutation deleteStockHoldings($inputs: [DeleteStockHoldingInputType]) {\n    deleteStockHoldings(inputs: $inputs)\n  }\n':
    types.DeleteStockHoldingsDocument,
  '\n  query getStockPositions($ids: [String!]) {\n    getStockPositions(ids: $ids) {\n      ...StockPosition\n    }\n  }\n':
    types.GetStockPositionsDocument,
  '\n  mutation createStockPositions($inputs: [CreateStockPositionInputType]) {\n    createStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n':
    types.CreateStockPositionsDocument,
  '\n  mutation updateStockPositions($inputs: [UpdateStockPositionInputType]) {\n    updateStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n':
    types.UpdateStockPositionsDocument,
  '\n  mutation upsertStockPositions($inputs: [UpsertStockPositionInputType]) {\n    upsertStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n':
    types.UpsertStockPositionsDocument,
  '\n  mutation deleteStockPositions($inputs: [DeleteStockPositionInputType]) {\n    deleteStockPositions(inputs: $inputs)\n  }\n':
    types.DeleteStockPositionsDocument,
  '\n  query getStockTrades($ids: [String!]) {\n    getStockTrades(ids: $ids) {\n      ...StockTrade\n    }\n  }\n':
    types.GetStockTradesDocument,
  '\n  mutation createStockTrades($inputs: [CreateStockTradeInputType]) {\n    createStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n':
    types.CreateStockTradesDocument,
  '\n  mutation updateStockTrades($inputs: [UpdateStockTradeInputType]) {\n    updateStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n':
    types.UpdateStockTradesDocument,
  '\n  mutation upsertStockTrades($inputs: [UpsertStockTradeInputType]) {\n    upsertStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n':
    types.UpsertStockTradesDocument,
  '\n  mutation deleteStockTrades($inputs: [DeleteStockTradeInputType]) {\n    deleteStockTrades(inputs: $inputs)\n  }\n':
    types.DeleteStockTradesDocument,
  '\n  fragment WarrantHolding on WarrantHolding {\n    __typename\n    id\n    warrant {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n':
    types.WarrantHoldingFragmentDoc,
  '\n  query getWarrantHoldings($ids: [String!]) {\n    getWarrantHoldings(ids: $ids) {\n      ...WarrantHolding\n    }\n  }\n':
    types.GetWarrantHoldingsDocument,
  '\n  mutation createWarrantHoldings($inputs: [CreateWarrantHoldingInputType]) {\n    createWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n':
    types.CreateWarrantHoldingsDocument,
  '\n  mutation updateWarrantHoldings($inputs: [UpdateWarrantHoldingInputType]) {\n    updateWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n':
    types.UpdateWarrantHoldingsDocument,
  '\n  mutation upsertWarrantHoldings($inputs: [UpsertWarrantHoldingInputType]) {\n    upsertWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n':
    types.UpsertWarrantHoldingsDocument,
  '\n  mutation deleteWarrantHoldings($inputs: [DeleteWarrantHoldingInputType]) {\n    deleteWarrantHoldings(inputs: $inputs)\n  }\n':
    types.DeleteWarrantHoldingsDocument,
  '\n  fragment HoldingObj on HoldingObj {\n    __typename\n    id\n    type\n    name\n    description\n    project {\n      id\n    }\n  }\n':
    types.HoldingObjFragmentDoc,
  '\n  fragment Holding on Holding {\n    __typename\n    ... on StockHolding {\n      ...StockHolding\n    }\n    ... on BondHolding {\n      ...BondHolding\n    }\n    ... on LoanHolding {\n      ...LoanHolding\n    }\n    ... on WarrantHolding {\n      ...WarrantHolding\n    }\n    ... on FundHolding {\n      ...FundHolding\n    }\n    ... on BankNoteHolding {\n      ...BankNoteHolding\n    }\n    ... on CryptoHolding {\n      ...CryptoHolding\n    }\n    valuations {\n      ...Valuation\n    }\n    commitments {\n      ...Commitment\n    }\n    transfers @include(if: $withTransfers) {\n      ...Transfer\n    }\n  }\n':
    types.HoldingFragmentDoc,
  '\n  query getHoldings($ids: [String!], $withTransfers: Boolean = false) {\n    getHoldings(ids: $ids) {\n      ...Holding\n    }\n  }\n':
    types.GetHoldingsDocument,
  '\n  query getHoldingObjs($ids: [String!]) {\n    getHoldingObjs(ids: $ids) {\n      ...HoldingObj\n    }\n  }\n':
    types.GetHoldingObjsDocument,
  '\n  mutation createHoldings($inputs: [CreateHoldingInputType]) {\n    createHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n':
    types.CreateHoldingsDocument,
  '\n  mutation updateHoldings($inputs: [UpdateHoldingInputType]) {\n    updateHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n':
    types.UpdateHoldingsDocument,
  '\n  mutation upsertHoldings($inputs: [UpsertHoldingInputType]) {\n    upsertHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n':
    types.UpsertHoldingsDocument,
  '\n  mutation deleteHoldings($inputs: [DeleteHoldingInputType]) {\n    deleteHoldings(inputs: $inputs)\n  }\n':
    types.DeleteHoldingsDocument,
  '\n  fragment Transaction on Transaction {\n    id\n    title\n    description\n    project {\n      id\n    }\n    transfers {\n      ...Transfer\n    }\n  }\n':
    types.TransactionFragmentDoc,
  '\n  query getTransactions($ids: [String!]) {\n    getTransactions(ids: $ids) {\n      ...Transaction\n    }\n  }\n':
    types.GetTransactionsDocument,
  '\n  mutation createTransactions($inputs: [CreateTransactionInputType]) {\n    createTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n':
    types.CreateTransactionsDocument,
  '\n  mutation updateTransactions($inputs: [UpdateTransactionInputType]) {\n    updateTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n':
    types.UpdateTransactionsDocument,
  '\n  mutation upsertTransactions($inputs: [UpsertTransactionInputType]) {\n    upsertTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n':
    types.UpsertTransactionsDocument,
  '\n  mutation deleteTransactions($inputs: [DeleteTransactionInputType]) {\n    deleteTransactions(inputs: $inputs)\n  }\n':
    types.DeleteTransactionsDocument,
  '\n  fragment Transfer on Transfer {\n    id\n    hash\n    date\n    type\n    subtype\n    description\n    value\n    cost\n    status\n    holding {\n      id\n    }\n    transaction {\n      id\n    }\n    currency {\n      id\n    }\n    commitment {\n      id\n    }\n  }\n':
    types.TransferFragmentDoc,
  '\n  query getTransfers($ids: [String!]) {\n    getTransfers(ids: $ids) {\n      ...Transfer\n    }\n  }\n':
    types.GetTransfersDocument,
  '\n  mutation createTransfers($inputs: [CreateTransferInputType]) {\n    createTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n':
    types.CreateTransfersDocument,
  '\n  mutation updateTransfers($inputs: [UpdateTransferInputType]) {\n    updateTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n':
    types.UpdateTransfersDocument,
  '\n  mutation upsertTransfers($inputs: [UpsertTransferInputType]) {\n    upsertTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n':
    types.UpsertTransfersDocument,
  '\n  mutation deleteTransfers($inputs: [DeleteTransferInputType]) {\n    deleteTransfers(inputs: $inputs)\n  }\n':
    types.DeleteTransfersDocument,
  '\n  fragment Valuation on Valuation {\n    id\n    holding {\n      id\n    }\n    date\n    value\n    ownershipPercentage\n    currency {\n      id\n    }\n    status\n  }\n':
    types.ValuationFragmentDoc,
  '\n  query getValuations($ids: [String!]) {\n    getValuations(ids: $ids) {\n      ...Valuation\n    }\n  }\n':
    types.GetValuationsDocument,
  '\n  mutation createValuations($inputs: [CreateValuationInputType]) {\n    createValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n':
    types.CreateValuationsDocument,
  '\n  mutation updateValuations($inputs: [UpdateValuationInputType]) {\n    updateValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n':
    types.UpdateValuationsDocument,
  '\n  mutation upsertValuations($inputs: [UpsertValuationInputType]) {\n    upsertValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n':
    types.UpsertValuationsDocument,
  '\n  mutation deleteValuations($inputs: [DeleteValuationInputType]) {\n    deleteValuations(inputs: $inputs)\n  }\n':
    types.DeleteValuationsDocument,
  '\n  fragment Address on Address {\n    id\n    streetAddress\n    streetNumber\n    administrativeAreaLevel1\n    administrativeAreaLevel2\n    locality\n    postalCode\n    person {\n      id\n    }\n  }\n':
    types.AddressFragmentDoc,
  '\n  query getAddresses($ids: [String!]) {\n    getAddresses(ids: $ids) {\n      ...Address\n    }\n  }\n':
    types.GetAddressesDocument,
  '\n  mutation createAddresses($inputs: [CreateAddressInputType]) {\n    createAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n':
    types.CreateAddressesDocument,
  '\n  mutation updateAddresses($inputs: [UpdateAddressInputType]) {\n    updateAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n':
    types.UpdateAddressesDocument,
  '\n  mutation upsertAddresses($inputs: [UpsertAddressInputType]) {\n    upsertAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n':
    types.UpsertAddressesDocument,
  '\n  mutation deleteAddresses($inputs: [DeleteAddressInputType]) {\n    deleteAddresses(inputs: $inputs)\n  }\n':
    types.DeleteAddressesDocument,
  '\n  fragment Email on Email {\n    id\n    value\n    person {\n      id\n    }\n  }\n':
    types.EmailFragmentDoc,
  '\n  query getEmails($ids: [String!]) {\n    getEmails(ids: $ids) {\n      ...Email\n    }\n  }\n':
    types.GetEmailsDocument,
  '\n  mutation createEmails($inputs: [CreateEmailInputType]) {\n    createEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n':
    types.CreateEmailsDocument,
  '\n  mutation updateEmails($inputs: [UpdateEmailInputType]) {\n    updateEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n':
    types.UpdateEmailsDocument,
  '\n  mutation upsertEmails($inputs: [UpsertEmailInputType]) {\n    upsertEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n':
    types.UpsertEmailsDocument,
  '\n  mutation deleteEmails($inputs: [DeleteEmailInputType]) {\n    deleteEmails(inputs: $inputs)\n  }\n':
    types.DeleteEmailsDocument,
  '\n  query getPeople($ids: [String!]) {\n    getPeople(ids: $ids) {\n      ...Person\n    }\n  }\n':
    types.GetPeopleDocument,
  '\n  mutation createPeople($inputs: [CreatePersonInputType]) {\n    createPeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n':
    types.CreatePeopleDocument,
  '\n  mutation updatePeople($inputs: [UpdatePersonInputType]) {\n    updatePeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n':
    types.UpdatePeopleDocument,
  '\n  mutation upsertPeople($inputs: [UpsertPersonInputType]) {\n    upsertPeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n':
    types.UpsertPeopleDocument,
  '\n  mutation deletePeople($inputs: [DeletePersonInputType]) {\n    deletePeople(inputs: $inputs)\n  }\n':
    types.DeletePeopleDocument,
  '\n  fragment Person on Person {\n    id\n    name\n    description\n    roles {\n      ...Role\n    }\n    phones {\n      ...Phone\n    }\n    emails {\n      ...Email\n    }\n    addresses {\n      ...Address\n    }\n  }\n':
    types.PersonFragmentDoc,
  '\n  fragment Phone on Phone {\n    id\n    value\n    person {\n      id\n    }\n  }\n':
    types.PhoneFragmentDoc,
  '\n  query getPhones($ids: [String!]) {\n    getPhones(ids: $ids) {\n      ...Phone\n    }\n  }\n':
    types.GetPhonesDocument,
  '\n  mutation createPhones($inputs: [CreatePhoneInputType]) {\n    createPhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n':
    types.CreatePhonesDocument,
  '\n  mutation updatePhones($inputs: [UpdatePhoneInputType]) {\n    updatePhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n':
    types.UpdatePhonesDocument,
  '\n  mutation upsertPhones($inputs: [UpsertPhoneInputType]) {\n    upsertPhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n':
    types.UpsertPhonesDocument,
  '\n  mutation deletePhones($inputs: [DeletePhoneInputType]) {\n    deletePhones(inputs: $inputs)\n  }\n':
    types.DeletePhonesDocument,
  '\n  fragment Role on Role {\n    id\n    name\n    description\n    holding {\n      id\n      project {\n        id\n      }\n    }\n    person {\n      id\n    }\n  }\n':
    types.RoleFragmentDoc,
  '\n  query getRoles($ids: [String!]) {\n    getRoles(ids: $ids) {\n      ...Role\n    }\n  }\n':
    types.GetRolesDocument,
  '\n  mutation createRoles($inputs: [CreateRoleInputType]) {\n    createRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n':
    types.CreateRolesDocument,
  '\n  mutation updateRoles($inputs: [UpdateRoleInputType]) {\n    updateRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n':
    types.UpdateRolesDocument,
  '\n  mutation upsertRoles($inputs: [UpsertRoleInputType]) {\n    upsertRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n':
    types.UpsertRolesDocument,
  '\n  mutation deleteRoles($inputs: [DeleteRoleInputType]) {\n    deleteRoles(inputs: $inputs)\n  }\n':
    types.DeleteRolesDocument,
  '\n  fragment PortfolioProject on PortfolioProject {\n    id\n    project {\n      ...ProjectObj\n    }\n    portfolio {\n      ...Portfolio\n    }\n  }\n':
    types.PortfolioProjectFragmentDoc,
  '\n  fragment PortfolioProjectShallow on PortfolioProject {\n    id\n    project {\n      ...ProjectObjShallow\n    }\n  }\n':
    types.PortfolioProjectShallowFragmentDoc,
  '\n  query getPortfolioProjects($ids: [String!], $withTransfers: Boolean = false) {\n    getPortfolioProjects(ids: $ids) {\n      ...PortfolioProject\n    }\n  }\n':
    types.GetPortfolioProjectsDocument,
  '\n  mutation createPortfolioProjects(\n    $inputs: [CreatePortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n':
    types.CreatePortfolioProjectsDocument,
  '\n  mutation updatePortfolioProjects(\n    $inputs: [UpdatePortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n':
    types.UpdatePortfolioProjectsDocument,
  '\n  mutation upsertPortfolioProjects(\n    $inputs: [UpsertPortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n':
    types.UpsertPortfolioProjectsDocument,
  '\n  mutation deletePortfolioProjects($inputs: [DeletePortfolioProjectInputType]) {\n    deletePortfolioProjects(inputs: $inputs)\n  }\n':
    types.DeletePortfolioProjectsDocument,
  '\n  query getPortfolioProjectByProjectId(\n    $projectId: String!\n    $withTransfers: Boolean = false\n  ) {\n    getPortfolioProjectByProjectId(projectId: $projectId) {\n      ...PortfolioProject\n    }\n  }\n':
    types.GetPortfolioProjectByProjectIdDocument,
  '\n  fragment Allocation on Allocation {\n    id\n    min\n    max\n    assetType\n    assetClass\n    category\n    sector\n    currency {\n      id\n    }\n    region {\n      id\n    }\n  }\n':
    types.AllocationFragmentDoc,
  '\n  fragment AllocationWithPortfolio on Allocation {\n    id\n    min\n    max\n    assetType\n    assetClass\n    category\n    sector\n    currency {\n      id\n    }\n    region {\n      id\n    }\n    portfolio {\n      id\n    }\n  }\n':
    types.AllocationWithPortfolioFragmentDoc,
  '\n  query getAllocations($ids: [String!]) {\n    getAllocations(ids: $ids) {\n      ...AllocationWithPortfolio\n    }\n  }\n':
    types.GetAllocationsDocument,
  '\n  mutation createAllocations($inputs: [CreateAllocationInputType]) {\n    createAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n':
    types.CreateAllocationsDocument,
  '\n  mutation updateAllocations($inputs: [UpdateAllocationInputType]) {\n    updateAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n':
    types.UpdateAllocationsDocument,
  '\n  mutation upsertAllocations($inputs: [UpsertAllocationInputType]) {\n    upsertAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n':
    types.UpsertAllocationsDocument,
  '\n  mutation deleteAllocations($inputs: [DeleteAllocationInputType]) {\n    deleteAllocations(inputs: $inputs)\n  }\n':
    types.DeleteAllocationsDocument,
  '\n  fragment PortfolioConfig on PortfolioConfig {\n    id\n    currentDashboardConfigId\n    portfolioDashboardConfigs {\n      ...PortfolioDashboardConfig\n    }\n  }\n':
    types.PortfolioConfigFragmentDoc,
  '\n  query getPortfolioConfigs($portfolioId: String!) {\n    getPortfolioConfigs(portfolioId: $portfolioId) {\n      ...PortfolioConfig\n    }\n  }\n':
    types.GetPortfolioConfigsDocument,
  '\n  mutation createPortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [CreatePortfolioConfigInputType]\n  ) {\n    createPortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n':
    types.CreatePortfolioConfigsDocument,
  '\n  mutation updatePortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [UpdatePortfolioConfigInputType]\n  ) {\n    updatePortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n':
    types.UpdatePortfolioConfigsDocument,
  '\n  mutation upsertPortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [UpsertPortfolioConfigInputType]\n  ) {\n    upsertPortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n':
    types.UpsertPortfolioConfigsDocument,
  '\n  mutation deletePortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [DeletePortfolioConfigInputType!]\n  ) {\n    deletePortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs)\n  }\n':
    types.DeletePortfolioConfigsDocument,
  '\n  fragment PortfolioDashboardConfig on PortfolioDashboardConfig {\n    id\n    name\n    layouts\n    compactType\n    elements {\n      ...DashboardElement\n    }\n  }\n':
    types.PortfolioDashboardConfigFragmentDoc,
  '\n  mutation createPortfolioDashboardConfigs(\n    $inputs: [CreatePortfolioDashboardConfigInputType]\n  ) {\n    createPortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n':
    types.CreatePortfolioDashboardConfigsDocument,
  '\n  mutation updatePortfolioDashboardConfigs(\n    $inputs: [UpdatePortfolioDashboardConfigInputType]\n  ) {\n    updatePortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n':
    types.UpdatePortfolioDashboardConfigsDocument,
  '\n  mutation upsertPortfolioDashboardConfigs(\n    $inputs: [UpsertPortfolioDashboardConfigInputType]\n  ) {\n    upsertPortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n':
    types.UpsertPortfolioDashboardConfigsDocument,
  '\n  mutation deletePortfolioDashboardConfigs($inputs: [String!]) {\n    deletePortfolioDashboardConfigs(inputs: $inputs)\n  }\n':
    types.DeletePortfolioDashboardConfigsDocument,
  '\n  fragment PortfolioBase on Portfolio {\n    id\n    name\n    description\n    parentPortfolio {\n      id\n    }\n    allocations {\n      ...Allocation\n    }\n    holdings {\n      __typename\n      holding {\n        id\n      }\n    }\n  }\n':
    types.PortfolioBaseFragmentDoc,
  '\n  fragment Portfolio on Portfolio {\n    ...PortfolioBase\n    portfolios {\n      ...PortfolioBase\n      portfolios {\n        ...PortfolioBase\n        portfolios {\n          id\n        }\n      }\n    }\n  }\n':
    types.PortfolioFragmentDoc,
  '\n  query getPortfolios($ids: [String!], $withTransfers: Boolean = false) {\n    getPortfolios(ids: $ids) {\n      ...Portfolio\n    }\n  }\n':
    types.GetPortfoliosDocument,
  '\n  mutation createPortfolios($inputs: [CreatePortfolioInputType]) {\n    createPortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n':
    types.CreatePortfoliosDocument,
  '\n  mutation updatePortfolios($inputs: [UpdatePortfolioInputType]) {\n    updatePortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n':
    types.UpdatePortfoliosDocument,
  '\n  mutation upsertPortfolios($inputs: [UpsertPortfolioInputType]) {\n    upsertPortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n':
    types.UpsertPortfoliosDocument,
  '\n  mutation deletePortfolios($inputs: [DeletePortfolioInputType]) {\n    deletePortfolios(inputs: $inputs)\n  }\n':
    types.DeletePortfoliosDocument,
  '\n  fragment PrivateEquityFundProject on PrivateEquityFundProject {\n    id\n    project {\n      ...ProjectObj\n    }\n    privateEquityFund {\n      ...PrivateEquityFund\n    }\n  }\n':
    types.PrivateEquityFundProjectFragmentDoc,
  '\n  fragment PrivateEquityFundProjectShallow on PrivateEquityFundProject {\n    id\n    project {\n      ...ProjectObjShallow\n    }\n  }\n':
    types.PrivateEquityFundProjectShallowFragmentDoc,
  '\n  query getPrivateEquityFundProjects(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundProjects(ids: $ids) {\n      ...PrivateEquityFundProject\n    }\n  }\n':
    types.GetPrivateEquityFundProjectsDocument,
  '\n  mutation createPrivateEquityFundProjects(\n    $inputs: [CreatePrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n':
    types.CreatePrivateEquityFundProjectsDocument,
  '\n  mutation updatePrivateEquityFundProjects(\n    $inputs: [UpdatePrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n':
    types.UpdatePrivateEquityFundProjectsDocument,
  '\n  mutation upsertPrivateEquityFundProjects(\n    $inputs: [UpsertPrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n':
    types.UpsertPrivateEquityFundProjectsDocument,
  '\n  mutation deletePrivateEquityFundProjects(\n    $inputs: [DeletePrivateEquityFundProjectInputType]\n  ) {\n    deletePrivateEquityFundProjects(inputs: $inputs)\n  }\n':
    types.DeletePrivateEquityFundProjectsDocument,
  '\n  query getPrivateEquityFundProjectByProjectId(\n    $projectId: String!\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundProjectByProjectId(projectId: $projectId) {\n      ...PrivateEquityFundProject\n    }\n  }\n':
    types.GetPrivateEquityFundProjectByProjectIdDocument,
  '\n  fragment ProjectObjShallow on ProjectObj {\n    __typename\n    id\n    name\n    type\n  }\n':
    types.ProjectObjShallowFragmentDoc,
  '\n  fragment ProjectObj on ProjectObj {\n    __typename\n    id\n    name\n    type\n    holdings {\n      ...Holding\n    }\n    transactions {\n      ...Transaction\n    }\n  }\n':
    types.ProjectObjFragmentDoc,
  '\n  fragment ProjectShallow on Project {\n    __typename\n    ... on PortfolioProject {\n      ...PortfolioProjectShallow\n    }\n    ... on PrivateEquityFundProject {\n      ...PrivateEquityFundProjectShallow\n    }\n  }\n':
    types.ProjectShallowFragmentDoc,
  '\n  fragment Project on Project {\n    __typename\n    ... on PortfolioProject {\n      ...PortfolioProject\n    }\n    ... on PrivateEquityFundProject {\n      ...PrivateEquityFundProject\n    }\n  }\n':
    types.ProjectFragmentDoc,
  '\n  query getProjects($ids: [String!], $withTransfers: Boolean = false) {\n    getProjects(ids: $ids) {\n      ...Project\n    }\n  }\n':
    types.GetProjectsDocument,
  '\n  query getProjectObjsShallow($ids: [String!]) {\n    getProjectObjs(ids: $ids) {\n      ...ProjectObjShallow\n    }\n  }\n':
    types.GetProjectObjsShallowDocument,
  '\n  mutation createProjects(\n    $inputs: [CreateProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n':
    types.CreateProjectsDocument,
  '\n  mutation updateProjects(\n    $inputs: [UpdateProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updateProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n':
    types.UpdateProjectsDocument,
  '\n  mutation upsertProjects(\n    $inputs: [UpsertProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n':
    types.UpsertProjectsDocument,
  '\n  mutation deleteProjects($inputs: [DeleteProjectInputType]) {\n    deleteProjects(inputs: $inputs)\n  }\n':
    types.DeleteProjectsDocument,
  '\n  fragment GeneratedReport on GeneratedReport {\n    id\n    objectKey\n    name\n    description\n    date\n    project {\n      id\n    }\n    holding {\n      id\n    }\n    report {\n      ...Report\n    }\n  }\n':
    types.GeneratedReportFragmentDoc,
  '\n  query getGeneratedReports($ids: [String!]) {\n    getGeneratedReports(ids: $ids) {\n      ...GeneratedReport\n    }\n  }\n':
    types.GetGeneratedReportsDocument,
  '\n  query getGeneratedReportsByProject($projectId: String!) {\n    getGeneratedReportsByProject(projectId: $projectId) {\n      ...GeneratedReport\n    }\n  }\n':
    types.GetGeneratedReportsByProjectDocument,
  '\n  mutation createGeneratedReports($inputs: [CreateGeneratedReportInputType]) {\n    createGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n':
    types.CreateGeneratedReportsDocument,
  '\n  mutation updateGeneratedReports($inputs: [UpdateGeneratedReportInputType]) {\n    updateGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n':
    types.UpdateGeneratedReportsDocument,
  '\n  mutation upsertGeneratedReports($inputs: [UpsertGeneratedReportInputType]) {\n    upsertGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n':
    types.UpsertGeneratedReportsDocument,
  '\n  mutation deleteGeneratedReports($inputs: [DeleteGeneratedReportInputType]) {\n    deleteGeneratedReports(inputs: $inputs)\n  }\n':
    types.DeleteGeneratedReportsDocument,
  '\n  fragment ProjectReport on ProjectReport {\n    id\n    name\n    periodStart\n    periodEnd\n    published\n    compactType\n    project {\n      id\n    }\n    layouts\n    elements {\n      ...DashboardElement\n    }\n  }\n':
    types.ProjectReportFragmentDoc,
  '\n  query getProjectReports($ids: [String!]) {\n    getProjectReports(ids: $ids) {\n      ...ProjectReport\n    }\n  }\n':
    types.GetProjectReportsDocument,
  '\n  query getProjectReportsByProject($projectId: String!) {\n    getProjectReportsByProject(projectId: $projectId) {\n      ...ProjectReport\n    }\n  }\n':
    types.GetProjectReportsByProjectDocument,
  '\n  mutation createProjectReports($inputs: [CreateProjectReportInputType]) {\n    createProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n':
    types.CreateProjectReportsDocument,
  '\n  mutation updateProjectReports($inputs: [UpdateProjectReportInputType]) {\n    updateProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n':
    types.UpdateProjectReportsDocument,
  '\n  mutation upsertProjectReports($inputs: [UpsertProjectReportInputType]) {\n    upsertProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n':
    types.UpsertProjectReportsDocument,
  '\n  mutation deleteProjectReports($inputs: [DeleteProjectReportInputType]) {\n    deleteProjectReports(inputs: $inputs)\n  }\n':
    types.DeleteProjectReportsDocument,
  '\n  fragment Report on Report {\n    id\n    name\n    description\n    project {\n      id\n    }\n    holding {\n      id\n    }\n    scheduler {\n      ...Scheduler\n    }\n    users {\n      id\n    }\n  }\n':
    types.ReportFragmentDoc,
  '\n  query getReports($ids: [String!]) {\n    getReports(ids: $ids) {\n      ...Report\n    }\n  }\n':
    types.GetReportsDocument,
  '\n  query getReportsByProject($projectId: String!) {\n    getReportsByProject(projectId: $projectId) {\n      ...Report\n    }\n  }\n':
    types.GetReportsByProjectDocument,
  '\n  mutation createReports($inputs: [CreateReportInputType]) {\n    createReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n':
    types.CreateReportsDocument,
  '\n  mutation updateReports($inputs: [UpdateReportInputType]) {\n    updateReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n':
    types.UpdateReportsDocument,
  '\n  mutation upsertReports($inputs: [UpsertReportInputType]) {\n    upsertReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n':
    types.UpsertReportsDocument,
  '\n  mutation deleteReports($inputs: [DeleteReportInputType]) {\n    deleteReports(inputs: $inputs)\n  }\n':
    types.DeleteReportsDocument,
  '\n  fragment User on User {\n    id\n    name\n    email\n  }\n':
    types.UserFragmentDoc,
  '\n  fragment WorkspacePermission on WorkspacePermission {\n    addPortfolios\n    editPortfolios\n    removePortfolios\n    viewPortfolios\n    viewWorkspaceProperties\n    editWorkspaceProperties\n    addWorkspaceRoles\n    updateWorkspaceRoles\n    removeWorkspaceRoles\n    addInvitations\n    updateInvitations\n    removeInvitations\n    remove\n  }\n':
    types.WorkspacePermissionFragmentDoc,
  '\n  fragment WorkspaceRole on WorkspaceRole {\n    id\n    name\n    permission {\n      ...WorkspacePermission\n    }\n    group {\n      ...Group\n    }\n  }\n':
    types.WorkspaceRoleFragmentDoc,
  '\n  fragment Workspace on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...Project\n    }\n    assets @include(if: $withAssets) {\n      ...Asset\n    }\n    entities @include(if: $withEntities) {\n      ...Entity\n    }\n    people @include(if: $withPeople) {\n      ...Person\n    }\n  }\n':
    types.WorkspaceFragmentDoc,
  '\n  fragment WorkspaceShallow on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...ProjectShallow\n    }\n  }\n':
    types.WorkspaceShallowFragmentDoc,
  '\n  fragment WorkspaceBaseWithRoles on WorkspaceBaseWithRoles {\n    __typename\n    id\n    name\n    workspaceRoles {\n      ...WorkspaceRole\n    }\n  }\n':
    types.WorkspaceBaseWithRolesFragmentDoc,
  '\n  fragment WorkspaceWithRoles on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...ProjectShallow\n    }\n    workspaceRoles {\n      ...WorkspaceRole\n    }\n  }\n':
    types.WorkspaceWithRolesFragmentDoc,
  '\n  query getWorkspace(\n    $withAssets: Boolean = false\n    $withEntities: Boolean = false\n    $withPeople: Boolean = false\n    $withTransfers: Boolean = false\n  ) {\n    getWorkspace {\n      ...Workspace\n    }\n  }\n':
    types.GetWorkspaceDocument,
  '\n  query getWorkspaceShallow {\n    getWorkspace {\n      ...WorkspaceShallow\n    }\n  }\n':
    types.GetWorkspaceShallowDocument,
  '\n  mutation createWorkspace($input: CreateWorkspaceInputType) {\n    createWorkspace(input: $input) {\n      ...WorkspaceBaseWithRoles\n    }\n  }\n':
    types.CreateWorkspaceDocument,
  '\n  mutation updateWorkspace($input: UpdateWorkspaceInputType) {\n    updateWorkspace(input: $input) {\n      ...WorkspaceShallow\n    }\n  }\n':
    types.UpdateWorkspaceDocument,
  '\n  mutation deleteWorkspace {\n    deleteWorkspace\n  }\n':
    types.DeleteWorkspaceDocument,
  '\n  mutation applyWorkspaceSnapshot($input: WorkspaceSnapshotInputType) {\n    applyWorkspaceSnapshot(input: $input) {\n      ...WorkspaceShallow\n    }\n  }\n':
    types.ApplyWorkspaceSnapshotDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AssetObj on AssetObj {\n    id\n    type\n    name\n    description\n    types\n    category {\n      id\n    }\n    assetClass\n    ISIN\n    VPS\n    lookup\n    entity {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment AssetObj on AssetObj {\n    id\n    type\n    name\n    description\n    types\n    category {\n      id\n    }\n    assetClass\n    ISIN\n    VPS\n    lookup\n    entity {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Asset on Asset {\n    __typename\n    _type\n    ... on Stock {\n      ...Stock\n    }\n    ... on Bond {\n      ...Bond\n    }\n    ... on Loan {\n      ...Loan\n    }\n    ... on Warrant {\n      ...Warrant\n    }\n    ... on Fund {\n      ...Fund\n    }\n    ... on BankNote {\n      ...BankNote\n    }\n    ... on Crypto {\n      ...Crypto\n    }\n    ... on PrivateEquityFund {\n      ...PrivateEquityFundShallow\n    }\n  }\n',
): (typeof documents)['\n  fragment Asset on Asset {\n    __typename\n    _type\n    ... on Stock {\n      ...Stock\n    }\n    ... on Bond {\n      ...Bond\n    }\n    ... on Loan {\n      ...Loan\n    }\n    ... on Warrant {\n      ...Warrant\n    }\n    ... on Fund {\n      ...Fund\n    }\n    ... on BankNote {\n      ...BankNote\n    }\n    ... on Crypto {\n      ...Crypto\n    }\n    ... on PrivateEquityFund {\n      ...PrivateEquityFundShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BankNote on BankNote {\n    __typename\n    _type\n    id\n    symbol\n    accountNumber\n    bank\n    description\n    asset {\n      ...AssetObj\n    }\n    currency {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment BankNote on BankNote {\n    __typename\n    _type\n    id\n    symbol\n    accountNumber\n    bank\n    description\n    asset {\n      ...AssetObj\n    }\n    currency {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBankNotes($ids: [String!]) {\n    getBankNotes(ids: $ids) {\n      ...BankNote\n    }\n  }\n',
): (typeof documents)['\n  query getBankNotes($ids: [String!]) {\n    getBankNotes(ids: $ids) {\n      ...BankNote\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBankNotes($inputs: [CreateBankNoteInputType]) {\n    createBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n',
): (typeof documents)['\n  mutation createBankNotes($inputs: [CreateBankNoteInputType]) {\n    createBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBankNotes($inputs: [UpdateBankNoteInputType]) {\n    updateBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBankNotes($inputs: [UpdateBankNoteInputType]) {\n    updateBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertBankNotes($inputs: [UpsertBankNoteInputType]) {\n    upsertBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertBankNotes($inputs: [UpsertBankNoteInputType]) {\n    upsertBankNotes(inputs: $inputs) {\n      ...BankNote\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteBankNotes($inputs: [DeleteBankNoteInputType]) {\n    deleteBankNotes(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteBankNotes($inputs: [DeleteBankNoteInputType]) {\n    deleteBankNotes(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Bond on Bond {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  fragment Bond on Bond {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBonds($ids: [String!]) {\n    getBonds(ids: $ids) {\n      ...Bond\n    }\n  }\n',
): (typeof documents)['\n  query getBonds($ids: [String!]) {\n    getBonds(ids: $ids) {\n      ...Bond\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBonds($inputs: [CreateBondInputType]) {\n    createBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n',
): (typeof documents)['\n  mutation createBonds($inputs: [CreateBondInputType]) {\n    createBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBonds($inputs: [UpdateBondInputType]) {\n    updateBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBonds($inputs: [UpdateBondInputType]) {\n    updateBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertBonds($inputs: [UpsertBondInputType]) {\n    upsertBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertBonds($inputs: [UpsertBondInputType]) {\n    upsertBonds(inputs: $inputs) {\n      ...Bond\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteBonds($inputs: [DeleteBondInputType]) {\n    deleteBonds(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteBonds($inputs: [DeleteBondInputType]) {\n    deleteBonds(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Crypto on Crypto {\n    __typename\n    _type\n    id\n    symbol\n    accountNumber\n    bank\n    description\n    asset {\n      ...AssetObj\n    }\n    currency {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Crypto on Crypto {\n    __typename\n    _type\n    id\n    symbol\n    accountNumber\n    bank\n    description\n    asset {\n      ...AssetObj\n    }\n    currency {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCryptos($ids: [String!]) {\n    getCryptos(ids: $ids) {\n      ...Crypto\n    }\n  }\n',
): (typeof documents)['\n  query getCryptos($ids: [String!]) {\n    getCryptos(ids: $ids) {\n      ...Crypto\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCryptos($inputs: [CreateCryptoInputType]) {\n    createCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n',
): (typeof documents)['\n  mutation createCryptos($inputs: [CreateCryptoInputType]) {\n    createCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCryptos($inputs: [UpdateCryptoInputType]) {\n    updateCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n',
): (typeof documents)['\n  mutation updateCryptos($inputs: [UpdateCryptoInputType]) {\n    updateCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertCryptos($inputs: [UpsertCryptoInputType]) {\n    upsertCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertCryptos($inputs: [UpsertCryptoInputType]) {\n    upsertCryptos(inputs: $inputs) {\n      ...Crypto\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteCryptos($inputs: [DeleteCryptoInputType]) {\n    deleteCryptos(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteCryptos($inputs: [DeleteCryptoInputType]) {\n    deleteCryptos(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Fund on Fund {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  fragment Fund on Fund {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFunds($ids: [String!]) {\n    getFunds(ids: $ids) {\n      ...Fund\n    }\n  }\n',
): (typeof documents)['\n  query getFunds($ids: [String!]) {\n    getFunds(ids: $ids) {\n      ...Fund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFunds($inputs: [CreateFundInputType]) {\n    createFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n',
): (typeof documents)['\n  mutation createFunds($inputs: [CreateFundInputType]) {\n    createFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFunds($inputs: [UpdateFundInputType]) {\n    updateFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFunds($inputs: [UpdateFundInputType]) {\n    updateFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertFunds($inputs: [UpsertFundInputType]) {\n    upsertFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertFunds($inputs: [UpsertFundInputType]) {\n    upsertFunds(inputs: $inputs) {\n      ...Fund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFunds($inputs: [DeleteFundInputType]) {\n    deleteFunds(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteFunds($inputs: [DeleteFundInputType]) {\n    deleteFunds(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Loan on Loan {\n    __typename\n    _type\n    id\n    interest\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  fragment Loan on Loan {\n    __typename\n    _type\n    id\n    interest\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLoans($ids: [String!]) {\n    getLoans(ids: $ids) {\n      ...Loan\n    }\n  }\n',
): (typeof documents)['\n  query getLoans($ids: [String!]) {\n    getLoans(ids: $ids) {\n      ...Loan\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createLoans($inputs: [CreateLoanInputType]) {\n    createLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n',
): (typeof documents)['\n  mutation createLoans($inputs: [CreateLoanInputType]) {\n    createLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateLoans($inputs: [UpdateLoanInputType]) {\n    updateLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n',
): (typeof documents)['\n  mutation updateLoans($inputs: [UpdateLoanInputType]) {\n    updateLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertLoans($inputs: [UpsertLoanInputType]) {\n    upsertLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertLoans($inputs: [UpsertLoanInputType]) {\n    upsertLoans(inputs: $inputs) {\n      ...Loan\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteLoans($inputs: [DeleteLoanInputType]) {\n    deleteLoans(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteLoans($inputs: [DeleteLoanInputType]) {\n    deleteLoans(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundCapitalCallTransfer on PrivateEquityFundCapitalCallTransfer {\n    id\n    capitalCall {\n      ...PrivateEquityFundCapitalCall\n    }\n    transfer {\n      ...Transfer\n    }\n    investor {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundCapitalCallTransfer on PrivateEquityFundCapitalCallTransfer {\n    id\n    capitalCall {\n      ...PrivateEquityFundCapitalCall\n    }\n    transfer {\n      ...Transfer\n    }\n    investor {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundCapitalCall on PrivateEquityFundCapitalCall {\n    id\n    name\n    description\n    date\n    value\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundCapitalCall on PrivateEquityFundCapitalCall {\n    id\n    name\n    description\n    date\n    value\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundCapitalCalls($ids: [String!]) {\n    getPrivateEquityFundCapitalCalls(ids: $ids) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundCapitalCalls($ids: [String!]) {\n    getPrivateEquityFundCapitalCalls(ids: $ids) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFundCapitalCalls(\n    $inputs: [CreatePrivateEquityFundCapitalCallInputType]\n  ) {\n    createPrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFundCapitalCalls(\n    $inputs: [CreatePrivateEquityFundCapitalCallInputType]\n  ) {\n    createPrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFundCapitalCalls(\n    $inputs: [UpdatePrivateEquityFundCapitalCallInputType]\n  ) {\n    updatePrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFundCapitalCalls(\n    $inputs: [UpdatePrivateEquityFundCapitalCallInputType]\n  ) {\n    updatePrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFundCapitalCalls(\n    $inputs: [UpsertPrivateEquityFundCapitalCallInputType]\n  ) {\n    upsertPrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFundCapitalCalls(\n    $inputs: [UpsertPrivateEquityFundCapitalCallInputType]\n  ) {\n    upsertPrivateEquityFundCapitalCalls(inputs: $inputs) {\n      ...PrivateEquityFundCapitalCall\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFundCapitalCalls(\n    $inputs: [DeletePrivateEquityFundCapitalCallInputType]\n  ) {\n    deletePrivateEquityFundCapitalCalls(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFundCapitalCalls(\n    $inputs: [DeletePrivateEquityFundCapitalCallInputType]\n  ) {\n    deletePrivateEquityFundCapitalCalls(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundCapitalCommitment on PrivateEquityFundCapitalCommitment {\n    id\n    date\n    value\n    investor {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundCapitalCommitment on PrivateEquityFundCapitalCommitment {\n    id\n    date\n    value\n    investor {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundCapitalDistribution on PrivateEquityFundCapitalDistribution {\n    id\n    name\n    description\n    date\n    value\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundCapitalDistribution on PrivateEquityFundCapitalDistribution {\n    id\n    name\n    description\n    date\n    value\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundCapitalDistributionTransfer on PrivateEquityFundCapitalDistributionTransfer {\n    id\n    capitalDistribution {\n      ...PrivateEquityFundCapitalDistribution\n    }\n    transfer {\n      ...Transfer\n    }\n    investor {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundCapitalDistributionTransfer on PrivateEquityFundCapitalDistributionTransfer {\n    id\n    capitalDistribution {\n      ...PrivateEquityFundCapitalDistribution\n    }\n    transfer {\n      ...Transfer\n    }\n    investor {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundContact on PrivateEquityFundContact {\n    id\n    name\n    email\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundContact on PrivateEquityFundContact {\n    id\n    name\n    email\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundContacts($ids: [String!]) {\n    getPrivateEquityFundContacts(ids: $ids) {\n      ...PrivateEquityFundContact\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundContacts($ids: [String!]) {\n    getPrivateEquityFundContacts(ids: $ids) {\n      ...PrivateEquityFundContact\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFundContacts(\n    $inputs: [CreatePrivateEquityFundContactInputType]\n  ) {\n    createPrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFundContacts(\n    $inputs: [CreatePrivateEquityFundContactInputType]\n  ) {\n    createPrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFundContacts(\n    $inputs: [UpdatePrivateEquityFundContactInputType]\n  ) {\n    updatePrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFundContacts(\n    $inputs: [UpdatePrivateEquityFundContactInputType]\n  ) {\n    updatePrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFundContacts(\n    $inputs: [UpsertPrivateEquityFundContactInputType]\n  ) {\n    upsertPrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFundContacts(\n    $inputs: [UpsertPrivateEquityFundContactInputType]\n  ) {\n    upsertPrivateEquityFundContacts(inputs: $inputs) {\n      ...PrivateEquityFundContact\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFundContacts(\n    $inputs: [DeletePrivateEquityFundContactInputType]\n  ) {\n    deletePrivateEquityFundContacts(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFundContacts(\n    $inputs: [DeletePrivateEquityFundContactInputType]\n  ) {\n    deletePrivateEquityFundContacts(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundInvestment on PrivateEquityFundInvestment {\n    id\n    name\n    description\n    exit\n    image\n    holding {\n      ...Holding\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundInvestment on PrivateEquityFundInvestment {\n    id\n    name\n    description\n    exit\n    image\n    holding {\n      ...Holding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundInvestments(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundInvestments(ids: $ids) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundInvestments(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundInvestments(ids: $ids) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFundInvestments(\n    $inputs: [CreatePrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFundInvestments(\n    $inputs: [CreatePrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFundInvestments(\n    $inputs: [UpdatePrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFundInvestments(\n    $inputs: [UpdatePrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFundInvestments(\n    $inputs: [UpsertPrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFundInvestments(\n    $inputs: [UpsertPrivateEquityFundInvestmentInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFundInvestments(inputs: $inputs) {\n      ...PrivateEquityFundInvestment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFundInvestments(\n    $inputs: [DeletePrivateEquityFundInvestmentInputType]\n  ) {\n    deletePrivateEquityFundInvestments(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFundInvestments(\n    $inputs: [DeletePrivateEquityFundInvestmentInputType]\n  ) {\n    deletePrivateEquityFundInvestments(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundInvestor on PrivateEquityFundInvestor {\n    id\n    name\n    description\n    commitments {\n      ...PrivateEquityFundCapitalCommitment\n    }\n    capitalCallTransfer {\n      ...PrivateEquityFundCapitalCallTransfer\n    }\n    capitalDistributionTransfer {\n      ...PrivateEquityFundCapitalDistributionTransfer\n    }\n    contacts {\n      ...PrivateEquityFundContact\n    }\n    users {\n      ...User\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundInvestor on PrivateEquityFundInvestor {\n    id\n    name\n    description\n    commitments {\n      ...PrivateEquityFundCapitalCommitment\n    }\n    capitalCallTransfer {\n      ...PrivateEquityFundCapitalCallTransfer\n    }\n    capitalDistributionTransfer {\n      ...PrivateEquityFundCapitalDistributionTransfer\n    }\n    contacts {\n      ...PrivateEquityFundContact\n    }\n    users {\n      ...User\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundInvestors($ids: [String!]) {\n    getPrivateEquityFundInvestors(ids: $ids) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundInvestors($ids: [String!]) {\n    getPrivateEquityFundInvestors(ids: $ids) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFundInvestors(\n    $inputs: [CreatePrivateEquityFundInvestorInputType]\n  ) {\n    createPrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFundInvestors(\n    $inputs: [CreatePrivateEquityFundInvestorInputType]\n  ) {\n    createPrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFundInvestors(\n    $inputs: [UpdatePrivateEquityFundInvestorInputType]\n  ) {\n    updatePrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFundInvestors(\n    $inputs: [UpdatePrivateEquityFundInvestorInputType]\n  ) {\n    updatePrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFundInvestors(\n    $inputs: [UpsertPrivateEquityFundInvestorInputType]\n  ) {\n    upsertPrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFundInvestors(\n    $inputs: [UpsertPrivateEquityFundInvestorInputType]\n  ) {\n    upsertPrivateEquityFundInvestors(inputs: $inputs) {\n      ...PrivateEquityFundInvestor\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFundInvestors(\n    $inputs: [DeletePrivateEquityFundInvestorInputType]\n  ) {\n    deletePrivateEquityFundInvestors(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFundInvestors(\n    $inputs: [DeletePrivateEquityFundInvestorInputType]\n  ) {\n    deletePrivateEquityFundInvestors(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundShallow on PrivateEquityFund {\n    __typename\n    _type\n    id\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n  \n',
): (typeof documents)['\n  fragment PrivateEquityFundShallow on PrivateEquityFund {\n    __typename\n    _type\n    id\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFund on PrivateEquityFund {\n    __typename\n    _type\n    id\n    description\n    fundSetup {\n      ...PrivateEquityFundSetup\n    }\n    investors {\n      ...PrivateEquityFundInvestor\n    }\n    investments {\n      ...PrivateEquityFundInvestment\n    }\n    capitalCalls {\n      ...PrivateEquityFundCapitalCall\n    }\n    capitalDistributions {\n      ...PrivateEquityFundCapitalDistribution\n    }\n    team {\n      ...PrivateEquityFundTeamMember\n    }\n    asset {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFund on PrivateEquityFund {\n    __typename\n    _type\n    id\n    description\n    fundSetup {\n      ...PrivateEquityFundSetup\n    }\n    investors {\n      ...PrivateEquityFundInvestor\n    }\n    investments {\n      ...PrivateEquityFundInvestment\n    }\n    capitalCalls {\n      ...PrivateEquityFundCapitalCall\n    }\n    capitalDistributions {\n      ...PrivateEquityFundCapitalDistribution\n    }\n    team {\n      ...PrivateEquityFundTeamMember\n    }\n    asset {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFunds(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFunds(ids: $ids) {\n      ...PrivateEquityFund\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFunds(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFunds(ids: $ids) {\n      ...PrivateEquityFund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFunds(\n    $inputs: [CreatePrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFunds(\n    $inputs: [CreatePrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFunds(\n    $inputs: [UpdatePrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFunds(\n    $inputs: [UpdatePrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFunds(\n    $inputs: [UpsertPrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFunds(\n    $inputs: [UpsertPrivateEquityFundInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFunds(inputs: $inputs) {\n      ...PrivateEquityFund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFunds(\n    $inputs: [DeletePrivateEquityFundInputType]\n  ) {\n    deletePrivateEquityFunds(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFunds(\n    $inputs: [DeletePrivateEquityFundInputType]\n  ) {\n    deletePrivateEquityFunds(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundSetup on PrivateEquityFundSetup {\n    id\n    name\n    description\n    managementFee\n    hurdleRate\n    carry\n    carriedInterestType\n    startDate\n    endDate\n    endInvestmentDate\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundSetup on PrivateEquityFundSetup {\n    id\n    name\n    description\n    managementFee\n    hurdleRate\n    carry\n    carriedInterestType\n    startDate\n    endDate\n    endInvestmentDate\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundTeamMember on PrivateEquityFundTeamMember {\n    id\n    name\n    email\n    tel\n    title\n    description\n    education\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundTeamMember on PrivateEquityFundTeamMember {\n    id\n    name\n    email\n    tel\n    title\n    description\n    education\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundTeamMembers($ids: [String!]) {\n    getPrivateEquityFundTeamMembers(ids: $ids) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundTeamMembers($ids: [String!]) {\n    getPrivateEquityFundTeamMembers(ids: $ids) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFundTeamMembers(\n    $inputs: [CreatePrivateEquityFundTeamMemberInputType]\n  ) {\n    createPrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFundTeamMembers(\n    $inputs: [CreatePrivateEquityFundTeamMemberInputType]\n  ) {\n    createPrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFundTeamMembers(\n    $inputs: [UpdatePrivateEquityFundTeamMemberInputType]\n  ) {\n    updatePrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFundTeamMembers(\n    $inputs: [UpdatePrivateEquityFundTeamMemberInputType]\n  ) {\n    updatePrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFundTeamMembers(\n    $inputs: [UpsertPrivateEquityFundTeamMemberInputType]\n  ) {\n    upsertPrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFundTeamMembers(\n    $inputs: [UpsertPrivateEquityFundTeamMemberInputType]\n  ) {\n    upsertPrivateEquityFundTeamMembers(inputs: $inputs) {\n      ...PrivateEquityFundTeamMember\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFundTeamMembers(\n    $inputs: [DeletePrivateEquityFundTeamMemberInputType]\n  ) {\n    deletePrivateEquityFundTeamMembers(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFundTeamMembers(\n    $inputs: [DeletePrivateEquityFundTeamMemberInputType]\n  ) {\n    deletePrivateEquityFundTeamMembers(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Stock on Stock {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  fragment Stock on Stock {\n    __typename\n    _type\n    id\n    paperId\n    asset {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStocks($ids: [String!]) {\n    getStocks(ids: $ids) {\n      ...Stock\n    }\n  }\n',
): (typeof documents)['\n  query getStocks($ids: [String!]) {\n    getStocks(ids: $ids) {\n      ...Stock\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createStocks($inputs: [CreateStockInputType]) {\n    createStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n',
): (typeof documents)['\n  mutation createStocks($inputs: [CreateStockInputType]) {\n    createStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStocks($inputs: [UpdateStockInputType]) {\n    updateStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStocks($inputs: [UpdateStockInputType]) {\n    updateStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertStocks($inputs: [UpsertStockInputType]) {\n    upsertStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertStocks($inputs: [UpsertStockInputType]) {\n    upsertStocks(inputs: $inputs) {\n      ...Stock\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteStocks($inputs: [DeleteStockInputType]) {\n    deleteStocks(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteStocks($inputs: [DeleteStockInputType]) {\n    deleteStocks(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Warrant on Warrant {\n    __typename\n    _type\n    id\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  fragment Warrant on Warrant {\n    __typename\n    _type\n    id\n    description\n    asset {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWarrants($ids: [String!]) {\n    getWarrants(ids: $ids) {\n      ...Warrant\n    }\n  }\n',
): (typeof documents)['\n  query getWarrants($ids: [String!]) {\n    getWarrants(ids: $ids) {\n      ...Warrant\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createWarrants($inputs: [CreateWarrantInputType]) {\n    createWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n',
): (typeof documents)['\n  mutation createWarrants($inputs: [CreateWarrantInputType]) {\n    createWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateWarrants($inputs: [UpdateWarrantInputType]) {\n    updateWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n',
): (typeof documents)['\n  mutation updateWarrants($inputs: [UpdateWarrantInputType]) {\n    updateWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertWarrants($inputs: [UpsertWarrantInputType]) {\n    upsertWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertWarrants($inputs: [UpsertWarrantInputType]) {\n    upsertWarrants(inputs: $inputs) {\n      ...Warrant\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteWarrants($inputs: [DeleteWarrantInputType]) {\n    deleteWarrants(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteWarrants($inputs: [DeleteWarrantInputType]) {\n    deleteWarrants(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAssets($ids: [String!]) {\n    getAssets(ids: $ids) {\n      ...Asset\n    }\n  }\n',
): (typeof documents)['\n  query getAssets($ids: [String!]) {\n    getAssets(ids: $ids) {\n      ...Asset\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAssetObjs($ids: [String!]) {\n    getAssetObjs(ids: $ids) {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  query getAssetObjs($ids: [String!]) {\n    getAssetObjs(ids: $ids) {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createAssets($inputs: [CreateAssetInputType]) {\n    createAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  mutation createAssets($inputs: [CreateAssetInputType]) {\n    createAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateAssets($inputs: [UpdateAssetInputType]) {\n    updateAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  mutation updateAssets($inputs: [UpdateAssetInputType]) {\n    updateAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertAssets($inputs: [UpsertAssetInputType]) {\n    upsertAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertAssets($inputs: [UpsertAssetInputType]) {\n    upsertAssets(inputs: $inputs) {\n      ...AssetObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteAssets($inputs: [DeleteAssetInputType]) {\n    deleteAssets(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteAssets($inputs: [DeleteAssetInputType]) {\n    deleteAssets(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAssetCategories($ids: [String!]) {\n    getAssetCategories(ids: $ids) {\n      ...AssetCategory\n    }\n  }\n',
): (typeof documents)['\n  query getAssetCategories($ids: [String!]) {\n    getAssetCategories(ids: $ids) {\n      ...AssetCategory\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createAssetCategories($inputs: [CreateAssetCategoryInputType]) {\n    createAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n',
): (typeof documents)['\n  mutation createAssetCategories($inputs: [CreateAssetCategoryInputType]) {\n    createAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateAssetCategories($inputs: [UpdateAssetCategoryInputType]) {\n    updateAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n',
): (typeof documents)['\n  mutation updateAssetCategories($inputs: [UpdateAssetCategoryInputType]) {\n    updateAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertAssetCategories($inputs: [UpsertAssetCategoryInputType]) {\n    upsertAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertAssetCategories($inputs: [UpsertAssetCategoryInputType]) {\n    upsertAssetCategories(inputs: $inputs) {\n      ...AssetCategory\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteAssetCategories($inputs: [DeleteAssetCategoryInputType]) {\n    deleteAssetCategories(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteAssetCategories($inputs: [DeleteAssetCategoryInputType]) {\n    deleteAssetCategories(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AssetCategory on AssetCategory {\n    id\n    name\n  }\n',
): (typeof documents)['\n  fragment AssetCategory on AssetCategory {\n    id\n    name\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DashboardElement on DashboardElement {\n    id\n    name\n    type\n    config\n    media {\n      ...DashboardMedia\n    }\n  }\n',
): (typeof documents)['\n  fragment DashboardElement on DashboardElement {\n    id\n    name\n    type\n    config\n    media {\n      ...DashboardMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDashboardElements($ids: [String!]) {\n    getDashboardElements(ids: $ids) {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  query getDashboardElements($ids: [String!]) {\n    getDashboardElements(ids: $ids) {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createDashboardElements($inputs: [CreateDashboardElementInputType]) {\n    createDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  mutation createDashboardElements($inputs: [CreateDashboardElementInputType]) {\n    createDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDashboardElements($inputs: [UpdateDashboardElementInputType]) {\n    updateDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDashboardElements($inputs: [UpdateDashboardElementInputType]) {\n    updateDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertDashboardElements($inputs: [UpsertDashboardElementInputType]) {\n    upsertDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertDashboardElements($inputs: [UpsertDashboardElementInputType]) {\n    upsertDashboardElements(inputs: $inputs) {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteDashboardElements($inputs: [String!]) {\n    deleteDashboardElements(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteDashboardElements($inputs: [String!]) {\n    deleteDashboardElements(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDashboardMedias($ids: [String!]) {\n    getDashboardMedias(ids: $ids) {\n      ...DashboardMedia\n    }\n  }\n',
): (typeof documents)['\n  query getDashboardMedias($ids: [String!]) {\n    getDashboardMedias(ids: $ids) {\n      ...DashboardMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createDashboardMedias($inputs: [CreateDashboardMediaInputType]) {\n    createDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n',
): (typeof documents)['\n  mutation createDashboardMedias($inputs: [CreateDashboardMediaInputType]) {\n    createDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDashboardMedias($inputs: [UpdateDashboardMediaInputType]) {\n    updateDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDashboardMedias($inputs: [UpdateDashboardMediaInputType]) {\n    updateDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertDashboardMedias($inputs: [UpsertDashboardMediaInputType]) {\n    upsertDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertDashboardMedias($inputs: [UpsertDashboardMediaInputType]) {\n    upsertDashboardMedias(inputs: $inputs) {\n      ...DashboardMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteDashboardMedias($inputs: [String!]) {\n    deleteDashboardMedias(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteDashboardMedias($inputs: [String!]) {\n    deleteDashboardMedias(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DashboardMedia on DashboardMedia {\n    id\n    file {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment DashboardMedia on DashboardMedia {\n    id\n    file {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Scheduler on Scheduler {\n    id\n    cron\n  }\n',
): (typeof documents)['\n  fragment Scheduler on Scheduler {\n    id\n    cron\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getSchedulers($ids: [String!]) {\n    getSchedulers(ids: $ids) {\n      ...Scheduler\n    }\n  }\n',
): (typeof documents)['\n  query getSchedulers($ids: [String!]) {\n    getSchedulers(ids: $ids) {\n      ...Scheduler\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createSchedulers($inputs: [CreateSchedulerInputType]) {\n    createSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n',
): (typeof documents)['\n  mutation createSchedulers($inputs: [CreateSchedulerInputType]) {\n    createSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateSchedulers($inputs: [UpdateSchedulerInputType]) {\n    updateSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n',
): (typeof documents)['\n  mutation updateSchedulers($inputs: [UpdateSchedulerInputType]) {\n    updateSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertSchedulers($inputs: [UpsertSchedulerInputType]) {\n    upsertSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertSchedulers($inputs: [UpsertSchedulerInputType]) {\n    upsertSchedulers(inputs: $inputs) {\n      ...Scheduler\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteSchedulers($inputs: [DeleteSchedulerInputType]) {\n    deleteSchedulers(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteSchedulers($inputs: [DeleteSchedulerInputType]) {\n    deleteSchedulers(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntities($ids: [String!]) {\n    getEntities(ids: $ids) {\n      ...Entity\n    }\n  }\n',
): (typeof documents)['\n  query getEntities($ids: [String!]) {\n    getEntities(ids: $ids) {\n      ...Entity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEntities($inputs: [CreateEntityInputType]) {\n    createEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n',
): (typeof documents)['\n  mutation createEntities($inputs: [CreateEntityInputType]) {\n    createEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEntities($inputs: [UpdateEntityInputType]) {\n    updateEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateEntities($inputs: [UpdateEntityInputType]) {\n    updateEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertEntities($inputs: [UpsertEntityInputType]) {\n    upsertEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertEntities($inputs: [UpsertEntityInputType]) {\n    upsertEntities(inputs: $inputs) {\n      ...Entity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEntities($inputs: [DeleteEntityInputType]) {\n    deleteEntities(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteEntities($inputs: [DeleteEntityInputType]) {\n    deleteEntities(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Entity on Entity {\n    __typename\n    id\n    name\n    description\n    sector\n    website\n    regionAllocations {\n      ...RegionAllocation\n    }\n    media {\n      ...EntityMedia\n    }\n  }\n',
): (typeof documents)['\n  fragment Entity on Entity {\n    __typename\n    id\n    name\n    description\n    sector\n    website\n    regionAllocations {\n      ...RegionAllocation\n    }\n    media {\n      ...EntityMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EntityMedia on EntityMedia {\n    id\n    file {\n      ...File\n    }\n    entity {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment EntityMedia on EntityMedia {\n    id\n    file {\n      ...File\n    }\n    entity {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityMedias($ids: [String!]) {\n    getEntityMedias(ids: $ids) {\n      ...EntityMedia\n    }\n  }\n',
): (typeof documents)['\n  query getEntityMedias($ids: [String!]) {\n    getEntityMedias(ids: $ids) {\n      ...EntityMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEntityMedias($inputs: [CreateEntityMediaInputType]) {\n    createEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n',
): (typeof documents)['\n  mutation createEntityMedias($inputs: [CreateEntityMediaInputType]) {\n    createEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEntityMedias($inputs: [UpdateEntityMediaInputType]) {\n    updateEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n',
): (typeof documents)['\n  mutation updateEntityMedias($inputs: [UpdateEntityMediaInputType]) {\n    updateEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertEntityMedias($inputs: [UpsertEntityMediaInputType]) {\n    upsertEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertEntityMedias($inputs: [UpsertEntityMediaInputType]) {\n    upsertEntityMedias(inputs: $inputs) {\n      ...EntityMedia\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEntityMedias($inputs: [DeleteEntityMediaInputType]) {\n    deleteEntityMedias(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteEntityMedias($inputs: [DeleteEntityMediaInputType]) {\n    deleteEntityMedias(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment RegionAllocation on RegionAllocation {\n    id\n    entity {\n      id\n    }\n    region {\n      id\n    }\n    date\n    allocation\n  }\n',
): (typeof documents)['\n  fragment RegionAllocation on RegionAllocation {\n    id\n    entity {\n      id\n    }\n    region {\n      id\n    }\n    date\n    allocation\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRegionAllocations($ids: [String!]) {\n    getRegionAllocations(ids: $ids) {\n      ...RegionAllocation\n    }\n  }\n',
): (typeof documents)['\n  query getRegionAllocations($ids: [String!]) {\n    getRegionAllocations(ids: $ids) {\n      ...RegionAllocation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createRegionAllocations($inputs: [CreateRegionAllocationInputType]) {\n    createRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n',
): (typeof documents)['\n  mutation createRegionAllocations($inputs: [CreateRegionAllocationInputType]) {\n    createRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateRegionAllocations($inputs: [UpdateRegionAllocationInputType]) {\n    updateRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n',
): (typeof documents)['\n  mutation updateRegionAllocations($inputs: [UpdateRegionAllocationInputType]) {\n    updateRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertRegionAllocations($inputs: [UpsertRegionAllocationInputType]) {\n    upsertRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertRegionAllocations($inputs: [UpsertRegionAllocationInputType]) {\n    upsertRegionAllocations(inputs: $inputs) {\n      ...RegionAllocation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteRegionAllocations($inputs: [DeleteRegionAllocationInputType]) {\n    deleteRegionAllocations(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteRegionAllocations($inputs: [DeleteRegionAllocationInputType]) {\n    deleteRegionAllocations(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Event on Event {\n    id\n    name\n    description\n    date\n    project {\n      id\n    }\n    portfolios {\n      id\n    }\n    entities {\n      id\n    }\n    holdings {\n      id\n    }\n    transactions {\n      id\n    }\n    files {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Event on Event {\n    id\n    name\n    description\n    date\n    project {\n      id\n    }\n    portfolios {\n      id\n    }\n    entities {\n      id\n    }\n    holdings {\n      id\n    }\n    transactions {\n      id\n    }\n    files {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEvents($ids: [String!]) {\n    getEvents(ids: $ids) {\n      ...Event\n    }\n  }\n',
): (typeof documents)['\n  query getEvents($ids: [String!]) {\n    getEvents(ids: $ids) {\n      ...Event\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEventsByProject($projectId: String!) {\n    getEventsByProject(projectId: $projectId) {\n      ...Event\n    }\n  }\n',
): (typeof documents)['\n  query getEventsByProject($projectId: String!) {\n    getEventsByProject(projectId: $projectId) {\n      ...Event\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEvents($inputs: [CreateEventInputType]) {\n    createEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n',
): (typeof documents)['\n  mutation createEvents($inputs: [CreateEventInputType]) {\n    createEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEvents($inputs: [UpdateEventInputType]) {\n    updateEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n',
): (typeof documents)['\n  mutation updateEvents($inputs: [UpdateEventInputType]) {\n    updateEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertEvents($inputs: [UpsertEventInputType]) {\n    upsertEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertEvents($inputs: [UpsertEventInputType]) {\n    upsertEvents(inputs: $inputs) {\n      ...Event\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEvents($inputs: [DeleteEventInputType]) {\n    deleteEvents(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteEvents($inputs: [DeleteEventInputType]) {\n    deleteEvents(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment File on File {\n    id\n    key\n    name\n    type\n    description\n  }\n',
): (typeof documents)['\n  fragment File on File {\n    id\n    key\n    name\n    type\n    description\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFiles($ids: [String!]) {\n    getFiles(ids: $ids) {\n      ...File\n    }\n  }\n',
): (typeof documents)['\n  query getFiles($ids: [String!]) {\n    getFiles(ids: $ids) {\n      ...File\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFiles($inputs: [CreateFileInputType]) {\n    createFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n',
): (typeof documents)['\n  mutation createFiles($inputs: [CreateFileInputType]) {\n    createFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFiles($inputs: [UpdateFileInputType]) {\n    updateFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFiles($inputs: [UpdateFileInputType]) {\n    updateFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertFiles($inputs: [UpsertFileInputType]) {\n    upsertFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertFiles($inputs: [UpsertFileInputType]) {\n    upsertFiles(inputs: $inputs) {\n      ...File\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFiles($inputs: [DeleteFileInputType]) {\n    deleteFiles(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteFiles($inputs: [DeleteFileInputType]) {\n    deleteFiles(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Group on Group {\n    id\n    name\n    users {\n      ...User\n    }\n  }\n  \n',
): (typeof documents)['\n  fragment Group on Group {\n    id\n    name\n    users {\n      ...User\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Hello {\n    hello\n  }\n',
): (typeof documents)['\n  query Hello {\n    hello\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Commitment on Commitment {\n    id\n    commitmentDate\n    startDate\n    endDate\n    value\n    type\n    description\n    holding {\n      id\n    }\n    transfers {\n      id\n      hash\n    }\n    currency {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Commitment on Commitment {\n    id\n    commitmentDate\n    startDate\n    endDate\n    value\n    type\n    description\n    holding {\n      id\n    }\n    transfers {\n      id\n      hash\n    }\n    currency {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCommitments($ids: [String!]) {\n    getCommitments(ids: $ids) {\n      ...Commitment\n    }\n  }\n  \n',
): (typeof documents)['\n  query getCommitments($ids: [String!]) {\n    getCommitments(ids: $ids) {\n      ...Commitment\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCommitments($inputs: [CreateCommitmentInputType]) {\n    createCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n',
): (typeof documents)['\n  mutation createCommitments($inputs: [CreateCommitmentInputType]) {\n    createCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCommitments($inputs: [UpdateCommitmentInputType]) {\n    updateCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n',
): (typeof documents)['\n  mutation updateCommitments($inputs: [UpdateCommitmentInputType]) {\n    updateCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertCommitments($inputs: [UpsertCommitmentInputType]) {\n    upsertCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n',
): (typeof documents)['\n  mutation upsertCommitments($inputs: [UpsertCommitmentInputType]) {\n    upsertCommitments(inputs: $inputs) {\n      ...Commitment\n    }\n  }\n  \n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteCommitments($inputs: [DeleteCommitmentInputType]) {\n    deleteCommitments(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteCommitments($inputs: [DeleteCommitmentInputType]) {\n    deleteCommitments(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HoldingConfig on HoldingConfig {\n    id\n    currentDashboardConfigId\n    holdingDashboardConfigs {\n      ...HoldingDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  fragment HoldingConfig on HoldingConfig {\n    id\n    currentDashboardConfigId\n    holdingDashboardConfigs {\n      ...HoldingDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getHoldingConfigs($holdingId: String!) {\n    getHoldingConfigs(holdingId: $holdingId) {\n      ...HoldingConfig\n    }\n  }\n',
): (typeof documents)['\n  query getHoldingConfigs($holdingId: String!) {\n    getHoldingConfigs(holdingId: $holdingId) {\n      ...HoldingConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createHoldingConfigs(\n    $holdingId: String!\n    $inputs: [CreateHoldingConfigInputType]\n  ) {\n    createHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation createHoldingConfigs(\n    $holdingId: String!\n    $inputs: [CreateHoldingConfigInputType]\n  ) {\n    createHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateHoldingConfigs(\n    $holdingId: String!\n    $inputs: [UpdateHoldingConfigInputType]\n  ) {\n    updateHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation updateHoldingConfigs(\n    $holdingId: String!\n    $inputs: [UpdateHoldingConfigInputType]\n  ) {\n    updateHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertHoldingConfigs(\n    $holdingId: String!\n    $inputs: [UpsertHoldingConfigInputType]\n  ) {\n    upsertHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertHoldingConfigs(\n    $holdingId: String!\n    $inputs: [UpsertHoldingConfigInputType]\n  ) {\n    upsertHoldingConfigs(holdingId: $holdingId, inputs: $inputs) {\n      ...HoldingConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteHoldingConfigs($holdingId: String!, $inputs: [Int!]) {\n    deleteHoldingConfigs(holdingId: $holdingId, inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteHoldingConfigs($holdingId: String!, $inputs: [Int!]) {\n    deleteHoldingConfigs(holdingId: $holdingId, inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HoldingDashboardConfig on HoldingDashboardConfig {\n    id\n    name\n    layouts\n    compactType\n    elements {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  fragment HoldingDashboardConfig on HoldingDashboardConfig {\n    id\n    name\n    layouts\n    compactType\n    elements {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createHoldingDashboardConfigs(\n    $inputs: [CreateHoldingDashboardConfigInputType]\n  ) {\n    createHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation createHoldingDashboardConfigs(\n    $inputs: [CreateHoldingDashboardConfigInputType]\n  ) {\n    createHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateHoldingDashboardConfigs(\n    $inputs: [UpdateHoldingDashboardConfigInputType]\n  ) {\n    updateHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation updateHoldingDashboardConfigs(\n    $inputs: [UpdateHoldingDashboardConfigInputType]\n  ) {\n    updateHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertHoldingDashboardConfigs(\n    $inputs: [UpsertHoldingDashboardConfigInputType]\n  ) {\n    upsertHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertHoldingDashboardConfigs(\n    $inputs: [UpsertHoldingDashboardConfigInputType]\n  ) {\n    upsertHoldingDashboardConfigs(inputs: $inputs) {\n      ...HoldingDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteHoldingDashboardConfigs($inputs: [String!]) {\n    deleteHoldingDashboardConfigs(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteHoldingDashboardConfigs($inputs: [String!]) {\n    deleteHoldingDashboardConfigs(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FavoriteHolding on FavoriteHolding {\n    id\n    holding {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment FavoriteHolding on FavoriteHolding {\n    id\n    holding {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFavoriteHoldings($projectId: String!, $ids: [String!]) {\n    getFavoriteHoldings(projectId: $projectId, ids: $ids) {\n      ...FavoriteHolding\n    }\n  }\n',
): (typeof documents)['\n  query getFavoriteHoldings($projectId: String!, $ids: [String!]) {\n    getFavoriteHoldings(projectId: $projectId, ids: $ids) {\n      ...FavoriteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFavoriteHoldings(\n    $projectId: String!\n    $inputs: [CreateFavoriteHoldingInputType]\n  ) {\n    createFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createFavoriteHoldings(\n    $projectId: String!\n    $inputs: [CreateFavoriteHoldingInputType]\n  ) {\n    createFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFavoriteHoldings(\n    $projectId: String!\n    $inputs: [UpdateFavoriteHoldingInputType]\n  ) {\n    updateFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFavoriteHoldings(\n    $projectId: String!\n    $inputs: [UpdateFavoriteHoldingInputType]\n  ) {\n    updateFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertFavoriteHoldings(\n    $projectId: String!\n    $inputs: [UpsertFavoriteHoldingInputType]\n  ) {\n    upsertFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertFavoriteHoldings(\n    $projectId: String!\n    $inputs: [UpsertFavoriteHoldingInputType]\n  ) {\n    upsertFavoriteHoldings(projectId: $projectId, inputs: $inputs) {\n      ...FavoriteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFavoriteHoldings(\n    $projectId: String!\n    $inputs: [DeleteFavoriteHoldingInputType]\n  ) {\n    deleteFavoriteHoldings(projectId: $projectId, inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteFavoriteHoldings(\n    $projectId: String!\n    $inputs: [DeleteFavoriteHoldingInputType]\n  ) {\n    deleteFavoriteHoldings(projectId: $projectId, inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BankNoteHolding on BankNoteHolding {\n    __typename\n    id\n    bankNote {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  fragment BankNoteHolding on BankNoteHolding {\n    __typename\n    id\n    bankNote {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBankNoteHoldings($ids: [String!]) {\n    getBankNoteHoldings(ids: $ids) {\n      ...BankNoteHolding\n    }\n  }\n',
): (typeof documents)['\n  query getBankNoteHoldings($ids: [String!]) {\n    getBankNoteHoldings(ids: $ids) {\n      ...BankNoteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBankNoteHoldings($inputs: [CreateBankNoteHoldingInputType]) {\n    createBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createBankNoteHoldings($inputs: [CreateBankNoteHoldingInputType]) {\n    createBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBankNoteHoldings($inputs: [UpdateBankNoteHoldingInputType]) {\n    updateBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBankNoteHoldings($inputs: [UpdateBankNoteHoldingInputType]) {\n    updateBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertBankNoteHoldings($inputs: [UpsertBankNoteHoldingInputType]) {\n    upsertBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertBankNoteHoldings($inputs: [UpsertBankNoteHoldingInputType]) {\n    upsertBankNoteHoldings(inputs: $inputs) {\n      ...BankNoteHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteBankNoteHoldings($inputs: [DeleteBankNoteHoldingInputType]) {\n    deleteBankNoteHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteBankNoteHoldings($inputs: [DeleteBankNoteHoldingInputType]) {\n    deleteBankNoteHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BondHolding on BondHolding {\n    __typename\n    id\n    bond {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    bondPositions {\n      ...BondPosition\n    }\n    bondTrades {\n      ...BondTrade\n    }\n  }\n',
): (typeof documents)['\n  fragment BondHolding on BondHolding {\n    __typename\n    id\n    bond {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    bondPositions {\n      ...BondPosition\n    }\n    bondTrades {\n      ...BondTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BondPosition on BondPosition {\n    id\n    shares\n    date\n    bondHolding {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment BondPosition on BondPosition {\n    id\n    shares\n    date\n    bondHolding {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BondTrade on BondTrade {\n    id\n    shares\n    sharePrice\n    bondHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n',
): (typeof documents)['\n  fragment BondTrade on BondTrade {\n    id\n    shares\n    sharePrice\n    bondHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBondHoldings($ids: [String!]) {\n    getBondHoldings(ids: $ids) {\n      ...BondHolding\n    }\n  }\n',
): (typeof documents)['\n  query getBondHoldings($ids: [String!]) {\n    getBondHoldings(ids: $ids) {\n      ...BondHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBondHoldings($inputs: [CreateBondHoldingInputType]) {\n    createBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createBondHoldings($inputs: [CreateBondHoldingInputType]) {\n    createBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBondHoldings($inputs: [UpdateBondHoldingInputType]) {\n    updateBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBondHoldings($inputs: [UpdateBondHoldingInputType]) {\n    updateBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertBondHoldings($inputs: [UpsertBondHoldingInputType]) {\n    upsertBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertBondHoldings($inputs: [UpsertBondHoldingInputType]) {\n    upsertBondHoldings(inputs: $inputs) {\n      ...BondHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteBondHoldings($inputs: [DeleteBondHoldingInputType]) {\n    deleteBondHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteBondHoldings($inputs: [DeleteBondHoldingInputType]) {\n    deleteBondHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBondPositions($ids: [String!]) {\n    getBondPositions(ids: $ids) {\n      ...BondPosition\n    }\n  }\n',
): (typeof documents)['\n  query getBondPositions($ids: [String!]) {\n    getBondPositions(ids: $ids) {\n      ...BondPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBondPositions($inputs: [CreateBondPositionInputType]) {\n    createBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation createBondPositions($inputs: [CreateBondPositionInputType]) {\n    createBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBondPositions($inputs: [UpdateBondPositionInputType]) {\n    updateBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBondPositions($inputs: [UpdateBondPositionInputType]) {\n    updateBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertBondPositions($inputs: [UpsertBondPositionInputType]) {\n    upsertBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertBondPositions($inputs: [UpsertBondPositionInputType]) {\n    upsertBondPositions(inputs: $inputs) {\n      ...BondPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteBondPositions($inputs: [DeleteBondPositionInputType]) {\n    deleteBondPositions(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteBondPositions($inputs: [DeleteBondPositionInputType]) {\n    deleteBondPositions(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBondTrades($ids: [String!]) {\n    getBondTrades(ids: $ids) {\n      ...BondTrade\n    }\n  }\n',
): (typeof documents)['\n  query getBondTrades($ids: [String!]) {\n    getBondTrades(ids: $ids) {\n      ...BondTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBondTrades($inputs: [CreateBondTradeInputType]) {\n    createBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation createBondTrades($inputs: [CreateBondTradeInputType]) {\n    createBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBondTrades($inputs: [UpdateBondTradeInputType]) {\n    updateBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBondTrades($inputs: [UpdateBondTradeInputType]) {\n    updateBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertBondTrades($inputs: [UpsertBondTradeInputType]) {\n    upsertBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertBondTrades($inputs: [UpsertBondTradeInputType]) {\n    upsertBondTrades(inputs: $inputs) {\n      ...BondTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteBondTrades($inputs: [DeleteBondTradeInputType]) {\n    deleteBondTrades(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteBondTrades($inputs: [DeleteBondTradeInputType]) {\n    deleteBondTrades(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CryptoHolding on CryptoHolding {\n    __typename\n    id\n    crypto {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  fragment CryptoHolding on CryptoHolding {\n    __typename\n    id\n    crypto {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCryptoHoldings($ids: [String!]) {\n    getCryptoHoldings(ids: $ids) {\n      ...CryptoHolding\n    }\n  }\n',
): (typeof documents)['\n  query getCryptoHoldings($ids: [String!]) {\n    getCryptoHoldings(ids: $ids) {\n      ...CryptoHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCryptoHoldings($inputs: [CreateCryptoHoldingInputType]) {\n    createCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createCryptoHoldings($inputs: [CreateCryptoHoldingInputType]) {\n    createCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCryptoHoldings($inputs: [UpdateCryptoHoldingInputType]) {\n    updateCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateCryptoHoldings($inputs: [UpdateCryptoHoldingInputType]) {\n    updateCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertCryptoHoldings($inputs: [UpsertCryptoHoldingInputType]) {\n    upsertCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertCryptoHoldings($inputs: [UpsertCryptoHoldingInputType]) {\n    upsertCryptoHoldings(inputs: $inputs) {\n      ...CryptoHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteCryptoHoldings($inputs: [DeleteCryptoHoldingInputType]) {\n    deleteCryptoHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteCryptoHoldings($inputs: [DeleteCryptoHoldingInputType]) {\n    deleteCryptoHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FundHolding on FundHolding {\n    __typename\n    id\n    fund {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    fundPositions {\n      ...FundPosition\n    }\n    fundTrades {\n      ...FundTrade\n    }\n  }\n',
): (typeof documents)['\n  fragment FundHolding on FundHolding {\n    __typename\n    id\n    fund {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    fundPositions {\n      ...FundPosition\n    }\n    fundTrades {\n      ...FundTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FundPosition on FundPosition {\n    id\n    shares\n    date\n    fundHolding {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment FundPosition on FundPosition {\n    id\n    shares\n    date\n    fundHolding {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FundTrade on FundTrade {\n    id\n    shares\n    sharePrice\n    fundHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n',
): (typeof documents)['\n  fragment FundTrade on FundTrade {\n    id\n    shares\n    sharePrice\n    fundHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFundHoldings($ids: [String!]) {\n    getFundHoldings(ids: $ids) {\n      ...FundHolding\n    }\n  }\n',
): (typeof documents)['\n  query getFundHoldings($ids: [String!]) {\n    getFundHoldings(ids: $ids) {\n      ...FundHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFundHoldings($inputs: [CreateFundHoldingInputType]) {\n    createFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createFundHoldings($inputs: [CreateFundHoldingInputType]) {\n    createFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFundHoldings($inputs: [UpdateFundHoldingInputType]) {\n    updateFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFundHoldings($inputs: [UpdateFundHoldingInputType]) {\n    updateFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertFundHoldings($inputs: [UpsertFundHoldingInputType]) {\n    upsertFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertFundHoldings($inputs: [UpsertFundHoldingInputType]) {\n    upsertFundHoldings(inputs: $inputs) {\n      ...FundHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFundHoldings($inputs: [DeleteFundHoldingInputType]) {\n    deleteFundHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteFundHoldings($inputs: [DeleteFundHoldingInputType]) {\n    deleteFundHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFundPositions($ids: [String!]) {\n    getFundPositions(ids: $ids) {\n      ...FundPosition\n    }\n  }\n',
): (typeof documents)['\n  query getFundPositions($ids: [String!]) {\n    getFundPositions(ids: $ids) {\n      ...FundPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFundPositions($inputs: [CreateFundPositionInputType]) {\n    createFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation createFundPositions($inputs: [CreateFundPositionInputType]) {\n    createFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFundPositions($inputs: [UpdateFundPositionInputType]) {\n    updateFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFundPositions($inputs: [UpdateFundPositionInputType]) {\n    updateFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertFundPositions($inputs: [UpsertFundPositionInputType]) {\n    upsertFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertFundPositions($inputs: [UpsertFundPositionInputType]) {\n    upsertFundPositions(inputs: $inputs) {\n      ...FundPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFundPositions($inputs: [DeleteFundPositionInputType]) {\n    deleteFundPositions(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteFundPositions($inputs: [DeleteFundPositionInputType]) {\n    deleteFundPositions(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFundTrades($ids: [String!]) {\n    getFundTrades(ids: $ids) {\n      ...FundTrade\n    }\n  }\n',
): (typeof documents)['\n  query getFundTrades($ids: [String!]) {\n    getFundTrades(ids: $ids) {\n      ...FundTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFundTrades($inputs: [CreateFundTradeInputType]) {\n    createFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation createFundTrades($inputs: [CreateFundTradeInputType]) {\n    createFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFundTrades($inputs: [UpdateFundTradeInputType]) {\n    updateFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFundTrades($inputs: [UpdateFundTradeInputType]) {\n    updateFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertFundTrades($inputs: [UpsertFundTradeInputType]) {\n    upsertFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertFundTrades($inputs: [UpsertFundTradeInputType]) {\n    upsertFundTrades(inputs: $inputs) {\n      ...FundTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFundTrades($inputs: [DeleteFundTradeInputType]) {\n    deleteFundTrades(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteFundTrades($inputs: [DeleteFundTradeInputType]) {\n    deleteFundTrades(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LoanHolding on LoanHolding {\n    __typename\n    id\n    loan {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  fragment LoanHolding on LoanHolding {\n    __typename\n    id\n    loan {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLoanHoldings($ids: [String!]) {\n    getLoanHoldings(ids: $ids) {\n      ...LoanHolding\n    }\n  }\n',
): (typeof documents)['\n  query getLoanHoldings($ids: [String!]) {\n    getLoanHoldings(ids: $ids) {\n      ...LoanHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createLoanHoldings($inputs: [CreateLoanHoldingInputType]) {\n    createLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createLoanHoldings($inputs: [CreateLoanHoldingInputType]) {\n    createLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateLoanHoldings($inputs: [UpdateLoanHoldingInputType]) {\n    updateLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateLoanHoldings($inputs: [UpdateLoanHoldingInputType]) {\n    updateLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertLoanHoldings($inputs: [UpsertLoanHoldingInputType]) {\n    upsertLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertLoanHoldings($inputs: [UpsertLoanHoldingInputType]) {\n    upsertLoanHoldings(inputs: $inputs) {\n      ...LoanHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteLoanHoldings($inputs: [DeleteLoanHoldingInputType]) {\n    deleteLoanHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteLoanHoldings($inputs: [DeleteLoanHoldingInputType]) {\n    deleteLoanHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment GoalRule on GoalRule {\n    name\n    formula\n  }\n',
): (typeof documents)['\n  fragment GoalRule on GoalRule {\n    name\n    formula\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockGoalRule on StockGoalRule {\n    id\n    goalRule {\n      ...GoalRule\n    }\n    stockHolding {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment StockGoalRule on StockGoalRule {\n    id\n    goalRule {\n      ...GoalRule\n    }\n    stockHolding {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockHolding on StockHolding {\n    __typename\n    id\n    stock {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    stockPositions {\n      ...StockPosition\n    }\n    stockTrades {\n      ...StockTrade\n    }\n    stockGoalRules {\n      ...StockGoalRule\n    }\n  }\n',
): (typeof documents)['\n  fragment StockHolding on StockHolding {\n    __typename\n    id\n    stock {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n    stockPositions {\n      ...StockPosition\n    }\n    stockTrades {\n      ...StockTrade\n    }\n    stockGoalRules {\n      ...StockGoalRule\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockPosition on StockPosition {\n    id\n    shares\n    date\n    stockHolding {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment StockPosition on StockPosition {\n    id\n    shares\n    date\n    stockHolding {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockTrade on StockTrade {\n    id\n    shares\n    sharePrice\n    stockHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n',
): (typeof documents)['\n  fragment StockTrade on StockTrade {\n    id\n    shares\n    sharePrice\n    stockHolding {\n      id\n    }\n    position {\n      id\n    }\n    transfer {\n      id\n      hash\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStockGoalRules($ids: [String!]) {\n    getStockGoalRules(ids: $ids) {\n      ...StockGoalRule\n    }\n  }\n',
): (typeof documents)['\n  query getStockGoalRules($ids: [String!]) {\n    getStockGoalRules(ids: $ids) {\n      ...StockGoalRule\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createStockGoalRules($inputs: [CreateStockGoalRuleInputType]) {\n    createStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n',
): (typeof documents)['\n  mutation createStockGoalRules($inputs: [CreateStockGoalRuleInputType]) {\n    createStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStockGoalRules($inputs: [UpdateStockGoalRuleInputType]) {\n    updateStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStockGoalRules($inputs: [UpdateStockGoalRuleInputType]) {\n    updateStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertStockGoalRules($inputs: [UpsertStockGoalRuleInputType]) {\n    upsertStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertStockGoalRules($inputs: [UpsertStockGoalRuleInputType]) {\n    upsertStockGoalRules(inputs: $inputs) {\n      ...StockGoalRule\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteStockGoalRules($inputs: [DeleteStockGoalRuleInputType]) {\n    deleteStockGoalRules(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteStockGoalRules($inputs: [DeleteStockGoalRuleInputType]) {\n    deleteStockGoalRules(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStockHoldings($ids: [String!]) {\n    getStockHoldings(ids: $ids) {\n      ...StockHolding\n    }\n  }\n',
): (typeof documents)['\n  query getStockHoldings($ids: [String!]) {\n    getStockHoldings(ids: $ids) {\n      ...StockHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createStockHoldings($inputs: [CreateStockHoldingInputType]) {\n    createStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createStockHoldings($inputs: [CreateStockHoldingInputType]) {\n    createStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStockHoldings($inputs: [UpdateStockHoldingInputType]) {\n    updateStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStockHoldings($inputs: [UpdateStockHoldingInputType]) {\n    updateStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertStockHoldings($inputs: [UpsertStockHoldingInputType]) {\n    upsertStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertStockHoldings($inputs: [UpsertStockHoldingInputType]) {\n    upsertStockHoldings(inputs: $inputs) {\n      ...StockHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteStockHoldings($inputs: [DeleteStockHoldingInputType]) {\n    deleteStockHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteStockHoldings($inputs: [DeleteStockHoldingInputType]) {\n    deleteStockHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStockPositions($ids: [String!]) {\n    getStockPositions(ids: $ids) {\n      ...StockPosition\n    }\n  }\n',
): (typeof documents)['\n  query getStockPositions($ids: [String!]) {\n    getStockPositions(ids: $ids) {\n      ...StockPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createStockPositions($inputs: [CreateStockPositionInputType]) {\n    createStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation createStockPositions($inputs: [CreateStockPositionInputType]) {\n    createStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStockPositions($inputs: [UpdateStockPositionInputType]) {\n    updateStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStockPositions($inputs: [UpdateStockPositionInputType]) {\n    updateStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertStockPositions($inputs: [UpsertStockPositionInputType]) {\n    upsertStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertStockPositions($inputs: [UpsertStockPositionInputType]) {\n    upsertStockPositions(inputs: $inputs) {\n      ...StockPosition\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteStockPositions($inputs: [DeleteStockPositionInputType]) {\n    deleteStockPositions(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteStockPositions($inputs: [DeleteStockPositionInputType]) {\n    deleteStockPositions(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStockTrades($ids: [String!]) {\n    getStockTrades(ids: $ids) {\n      ...StockTrade\n    }\n  }\n',
): (typeof documents)['\n  query getStockTrades($ids: [String!]) {\n    getStockTrades(ids: $ids) {\n      ...StockTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createStockTrades($inputs: [CreateStockTradeInputType]) {\n    createStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation createStockTrades($inputs: [CreateStockTradeInputType]) {\n    createStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStockTrades($inputs: [UpdateStockTradeInputType]) {\n    updateStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStockTrades($inputs: [UpdateStockTradeInputType]) {\n    updateStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertStockTrades($inputs: [UpsertStockTradeInputType]) {\n    upsertStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertStockTrades($inputs: [UpsertStockTradeInputType]) {\n    upsertStockTrades(inputs: $inputs) {\n      ...StockTrade\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteStockTrades($inputs: [DeleteStockTradeInputType]) {\n    deleteStockTrades(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteStockTrades($inputs: [DeleteStockTradeInputType]) {\n    deleteStockTrades(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WarrantHolding on WarrantHolding {\n    __typename\n    id\n    warrant {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  fragment WarrantHolding on WarrantHolding {\n    __typename\n    id\n    warrant {\n      __typename\n      _type\n      id\n    }\n    holding {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWarrantHoldings($ids: [String!]) {\n    getWarrantHoldings(ids: $ids) {\n      ...WarrantHolding\n    }\n  }\n',
): (typeof documents)['\n  query getWarrantHoldings($ids: [String!]) {\n    getWarrantHoldings(ids: $ids) {\n      ...WarrantHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createWarrantHoldings($inputs: [CreateWarrantHoldingInputType]) {\n    createWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation createWarrantHoldings($inputs: [CreateWarrantHoldingInputType]) {\n    createWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateWarrantHoldings($inputs: [UpdateWarrantHoldingInputType]) {\n    updateWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation updateWarrantHoldings($inputs: [UpdateWarrantHoldingInputType]) {\n    updateWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertWarrantHoldings($inputs: [UpsertWarrantHoldingInputType]) {\n    upsertWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertWarrantHoldings($inputs: [UpsertWarrantHoldingInputType]) {\n    upsertWarrantHoldings(inputs: $inputs) {\n      ...WarrantHolding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteWarrantHoldings($inputs: [DeleteWarrantHoldingInputType]) {\n    deleteWarrantHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteWarrantHoldings($inputs: [DeleteWarrantHoldingInputType]) {\n    deleteWarrantHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HoldingObj on HoldingObj {\n    __typename\n    id\n    type\n    name\n    description\n    project {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment HoldingObj on HoldingObj {\n    __typename\n    id\n    type\n    name\n    description\n    project {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Holding on Holding {\n    __typename\n    ... on StockHolding {\n      ...StockHolding\n    }\n    ... on BondHolding {\n      ...BondHolding\n    }\n    ... on LoanHolding {\n      ...LoanHolding\n    }\n    ... on WarrantHolding {\n      ...WarrantHolding\n    }\n    ... on FundHolding {\n      ...FundHolding\n    }\n    ... on BankNoteHolding {\n      ...BankNoteHolding\n    }\n    ... on CryptoHolding {\n      ...CryptoHolding\n    }\n    valuations {\n      ...Valuation\n    }\n    commitments {\n      ...Commitment\n    }\n    transfers @include(if: $withTransfers) {\n      ...Transfer\n    }\n  }\n',
): (typeof documents)['\n  fragment Holding on Holding {\n    __typename\n    ... on StockHolding {\n      ...StockHolding\n    }\n    ... on BondHolding {\n      ...BondHolding\n    }\n    ... on LoanHolding {\n      ...LoanHolding\n    }\n    ... on WarrantHolding {\n      ...WarrantHolding\n    }\n    ... on FundHolding {\n      ...FundHolding\n    }\n    ... on BankNoteHolding {\n      ...BankNoteHolding\n    }\n    ... on CryptoHolding {\n      ...CryptoHolding\n    }\n    valuations {\n      ...Valuation\n    }\n    commitments {\n      ...Commitment\n    }\n    transfers @include(if: $withTransfers) {\n      ...Transfer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getHoldings($ids: [String!], $withTransfers: Boolean = false) {\n    getHoldings(ids: $ids) {\n      ...Holding\n    }\n  }\n',
): (typeof documents)['\n  query getHoldings($ids: [String!], $withTransfers: Boolean = false) {\n    getHoldings(ids: $ids) {\n      ...Holding\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getHoldingObjs($ids: [String!]) {\n    getHoldingObjs(ids: $ids) {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  query getHoldingObjs($ids: [String!]) {\n    getHoldingObjs(ids: $ids) {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createHoldings($inputs: [CreateHoldingInputType]) {\n    createHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  mutation createHoldings($inputs: [CreateHoldingInputType]) {\n    createHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateHoldings($inputs: [UpdateHoldingInputType]) {\n    updateHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  mutation updateHoldings($inputs: [UpdateHoldingInputType]) {\n    updateHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertHoldings($inputs: [UpsertHoldingInputType]) {\n    upsertHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertHoldings($inputs: [UpsertHoldingInputType]) {\n    upsertHoldings(inputs: $inputs) {\n      ...HoldingObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteHoldings($inputs: [DeleteHoldingInputType]) {\n    deleteHoldings(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteHoldings($inputs: [DeleteHoldingInputType]) {\n    deleteHoldings(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Transaction on Transaction {\n    id\n    title\n    description\n    project {\n      id\n    }\n    transfers {\n      ...Transfer\n    }\n  }\n',
): (typeof documents)['\n  fragment Transaction on Transaction {\n    id\n    title\n    description\n    project {\n      id\n    }\n    transfers {\n      ...Transfer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getTransactions($ids: [String!]) {\n    getTransactions(ids: $ids) {\n      ...Transaction\n    }\n  }\n',
): (typeof documents)['\n  query getTransactions($ids: [String!]) {\n    getTransactions(ids: $ids) {\n      ...Transaction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createTransactions($inputs: [CreateTransactionInputType]) {\n    createTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n',
): (typeof documents)['\n  mutation createTransactions($inputs: [CreateTransactionInputType]) {\n    createTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateTransactions($inputs: [UpdateTransactionInputType]) {\n    updateTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n',
): (typeof documents)['\n  mutation updateTransactions($inputs: [UpdateTransactionInputType]) {\n    updateTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertTransactions($inputs: [UpsertTransactionInputType]) {\n    upsertTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertTransactions($inputs: [UpsertTransactionInputType]) {\n    upsertTransactions(inputs: $inputs) {\n      ...Transaction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteTransactions($inputs: [DeleteTransactionInputType]) {\n    deleteTransactions(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteTransactions($inputs: [DeleteTransactionInputType]) {\n    deleteTransactions(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Transfer on Transfer {\n    id\n    hash\n    date\n    type\n    subtype\n    description\n    value\n    cost\n    status\n    holding {\n      id\n    }\n    transaction {\n      id\n    }\n    currency {\n      id\n    }\n    commitment {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Transfer on Transfer {\n    id\n    hash\n    date\n    type\n    subtype\n    description\n    value\n    cost\n    status\n    holding {\n      id\n    }\n    transaction {\n      id\n    }\n    currency {\n      id\n    }\n    commitment {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getTransfers($ids: [String!]) {\n    getTransfers(ids: $ids) {\n      ...Transfer\n    }\n  }\n',
): (typeof documents)['\n  query getTransfers($ids: [String!]) {\n    getTransfers(ids: $ids) {\n      ...Transfer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createTransfers($inputs: [CreateTransferInputType]) {\n    createTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n',
): (typeof documents)['\n  mutation createTransfers($inputs: [CreateTransferInputType]) {\n    createTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateTransfers($inputs: [UpdateTransferInputType]) {\n    updateTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n',
): (typeof documents)['\n  mutation updateTransfers($inputs: [UpdateTransferInputType]) {\n    updateTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertTransfers($inputs: [UpsertTransferInputType]) {\n    upsertTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertTransfers($inputs: [UpsertTransferInputType]) {\n    upsertTransfers(inputs: $inputs) {\n      ...Transfer\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteTransfers($inputs: [DeleteTransferInputType]) {\n    deleteTransfers(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteTransfers($inputs: [DeleteTransferInputType]) {\n    deleteTransfers(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Valuation on Valuation {\n    id\n    holding {\n      id\n    }\n    date\n    value\n    ownershipPercentage\n    currency {\n      id\n    }\n    status\n  }\n',
): (typeof documents)['\n  fragment Valuation on Valuation {\n    id\n    holding {\n      id\n    }\n    date\n    value\n    ownershipPercentage\n    currency {\n      id\n    }\n    status\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getValuations($ids: [String!]) {\n    getValuations(ids: $ids) {\n      ...Valuation\n    }\n  }\n',
): (typeof documents)['\n  query getValuations($ids: [String!]) {\n    getValuations(ids: $ids) {\n      ...Valuation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createValuations($inputs: [CreateValuationInputType]) {\n    createValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n',
): (typeof documents)['\n  mutation createValuations($inputs: [CreateValuationInputType]) {\n    createValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateValuations($inputs: [UpdateValuationInputType]) {\n    updateValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n',
): (typeof documents)['\n  mutation updateValuations($inputs: [UpdateValuationInputType]) {\n    updateValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertValuations($inputs: [UpsertValuationInputType]) {\n    upsertValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertValuations($inputs: [UpsertValuationInputType]) {\n    upsertValuations(inputs: $inputs) {\n      ...Valuation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteValuations($inputs: [DeleteValuationInputType]) {\n    deleteValuations(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteValuations($inputs: [DeleteValuationInputType]) {\n    deleteValuations(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Address on Address {\n    id\n    streetAddress\n    streetNumber\n    administrativeAreaLevel1\n    administrativeAreaLevel2\n    locality\n    postalCode\n    person {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Address on Address {\n    id\n    streetAddress\n    streetNumber\n    administrativeAreaLevel1\n    administrativeAreaLevel2\n    locality\n    postalCode\n    person {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAddresses($ids: [String!]) {\n    getAddresses(ids: $ids) {\n      ...Address\n    }\n  }\n',
): (typeof documents)['\n  query getAddresses($ids: [String!]) {\n    getAddresses(ids: $ids) {\n      ...Address\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createAddresses($inputs: [CreateAddressInputType]) {\n    createAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n',
): (typeof documents)['\n  mutation createAddresses($inputs: [CreateAddressInputType]) {\n    createAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateAddresses($inputs: [UpdateAddressInputType]) {\n    updateAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n',
): (typeof documents)['\n  mutation updateAddresses($inputs: [UpdateAddressInputType]) {\n    updateAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertAddresses($inputs: [UpsertAddressInputType]) {\n    upsertAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertAddresses($inputs: [UpsertAddressInputType]) {\n    upsertAddresses(inputs: $inputs) {\n      ...Address\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteAddresses($inputs: [DeleteAddressInputType]) {\n    deleteAddresses(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteAddresses($inputs: [DeleteAddressInputType]) {\n    deleteAddresses(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Email on Email {\n    id\n    value\n    person {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Email on Email {\n    id\n    value\n    person {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEmails($ids: [String!]) {\n    getEmails(ids: $ids) {\n      ...Email\n    }\n  }\n',
): (typeof documents)['\n  query getEmails($ids: [String!]) {\n    getEmails(ids: $ids) {\n      ...Email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEmails($inputs: [CreateEmailInputType]) {\n    createEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n',
): (typeof documents)['\n  mutation createEmails($inputs: [CreateEmailInputType]) {\n    createEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEmails($inputs: [UpdateEmailInputType]) {\n    updateEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n',
): (typeof documents)['\n  mutation updateEmails($inputs: [UpdateEmailInputType]) {\n    updateEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertEmails($inputs: [UpsertEmailInputType]) {\n    upsertEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertEmails($inputs: [UpsertEmailInputType]) {\n    upsertEmails(inputs: $inputs) {\n      ...Email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEmails($inputs: [DeleteEmailInputType]) {\n    deleteEmails(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteEmails($inputs: [DeleteEmailInputType]) {\n    deleteEmails(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPeople($ids: [String!]) {\n    getPeople(ids: $ids) {\n      ...Person\n    }\n  }\n',
): (typeof documents)['\n  query getPeople($ids: [String!]) {\n    getPeople(ids: $ids) {\n      ...Person\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPeople($inputs: [CreatePersonInputType]) {\n    createPeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n',
): (typeof documents)['\n  mutation createPeople($inputs: [CreatePersonInputType]) {\n    createPeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePeople($inputs: [UpdatePersonInputType]) {\n    updatePeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePeople($inputs: [UpdatePersonInputType]) {\n    updatePeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPeople($inputs: [UpsertPersonInputType]) {\n    upsertPeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPeople($inputs: [UpsertPersonInputType]) {\n    upsertPeople(inputs: $inputs) {\n      ...Person\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePeople($inputs: [DeletePersonInputType]) {\n    deletePeople(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePeople($inputs: [DeletePersonInputType]) {\n    deletePeople(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Person on Person {\n    id\n    name\n    description\n    roles {\n      ...Role\n    }\n    phones {\n      ...Phone\n    }\n    emails {\n      ...Email\n    }\n    addresses {\n      ...Address\n    }\n  }\n',
): (typeof documents)['\n  fragment Person on Person {\n    id\n    name\n    description\n    roles {\n      ...Role\n    }\n    phones {\n      ...Phone\n    }\n    emails {\n      ...Email\n    }\n    addresses {\n      ...Address\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Phone on Phone {\n    id\n    value\n    person {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Phone on Phone {\n    id\n    value\n    person {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhones($ids: [String!]) {\n    getPhones(ids: $ids) {\n      ...Phone\n    }\n  }\n',
): (typeof documents)['\n  query getPhones($ids: [String!]) {\n    getPhones(ids: $ids) {\n      ...Phone\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPhones($inputs: [CreatePhoneInputType]) {\n    createPhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n',
): (typeof documents)['\n  mutation createPhones($inputs: [CreatePhoneInputType]) {\n    createPhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePhones($inputs: [UpdatePhoneInputType]) {\n    updatePhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePhones($inputs: [UpdatePhoneInputType]) {\n    updatePhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPhones($inputs: [UpsertPhoneInputType]) {\n    upsertPhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPhones($inputs: [UpsertPhoneInputType]) {\n    upsertPhones(inputs: $inputs) {\n      ...Phone\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePhones($inputs: [DeletePhoneInputType]) {\n    deletePhones(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePhones($inputs: [DeletePhoneInputType]) {\n    deletePhones(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Role on Role {\n    id\n    name\n    description\n    holding {\n      id\n      project {\n        id\n      }\n    }\n    person {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Role on Role {\n    id\n    name\n    description\n    holding {\n      id\n      project {\n        id\n      }\n    }\n    person {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRoles($ids: [String!]) {\n    getRoles(ids: $ids) {\n      ...Role\n    }\n  }\n',
): (typeof documents)['\n  query getRoles($ids: [String!]) {\n    getRoles(ids: $ids) {\n      ...Role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createRoles($inputs: [CreateRoleInputType]) {\n    createRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n',
): (typeof documents)['\n  mutation createRoles($inputs: [CreateRoleInputType]) {\n    createRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateRoles($inputs: [UpdateRoleInputType]) {\n    updateRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n',
): (typeof documents)['\n  mutation updateRoles($inputs: [UpdateRoleInputType]) {\n    updateRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertRoles($inputs: [UpsertRoleInputType]) {\n    upsertRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertRoles($inputs: [UpsertRoleInputType]) {\n    upsertRoles(inputs: $inputs) {\n      ...Role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteRoles($inputs: [DeleteRoleInputType]) {\n    deleteRoles(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteRoles($inputs: [DeleteRoleInputType]) {\n    deleteRoles(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PortfolioProject on PortfolioProject {\n    id\n    project {\n      ...ProjectObj\n    }\n    portfolio {\n      ...Portfolio\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioProject on PortfolioProject {\n    id\n    project {\n      ...ProjectObj\n    }\n    portfolio {\n      ...Portfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PortfolioProjectShallow on PortfolioProject {\n    id\n    project {\n      ...ProjectObjShallow\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioProjectShallow on PortfolioProject {\n    id\n    project {\n      ...ProjectObjShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPortfolioProjects($ids: [String!], $withTransfers: Boolean = false) {\n    getPortfolioProjects(ids: $ids) {\n      ...PortfolioProject\n    }\n  }\n',
): (typeof documents)['\n  query getPortfolioProjects($ids: [String!], $withTransfers: Boolean = false) {\n    getPortfolioProjects(ids: $ids) {\n      ...PortfolioProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPortfolioProjects(\n    $inputs: [CreatePortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n',
): (typeof documents)['\n  mutation createPortfolioProjects(\n    $inputs: [CreatePortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePortfolioProjects(\n    $inputs: [UpdatePortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePortfolioProjects(\n    $inputs: [UpdatePortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPortfolioProjects(\n    $inputs: [UpsertPortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPortfolioProjects(\n    $inputs: [UpsertPortfolioProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPortfolioProjects(inputs: $inputs) {\n      ...PortfolioProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePortfolioProjects($inputs: [DeletePortfolioProjectInputType]) {\n    deletePortfolioProjects(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePortfolioProjects($inputs: [DeletePortfolioProjectInputType]) {\n    deletePortfolioProjects(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPortfolioProjectByProjectId(\n    $projectId: String!\n    $withTransfers: Boolean = false\n  ) {\n    getPortfolioProjectByProjectId(projectId: $projectId) {\n      ...PortfolioProject\n    }\n  }\n',
): (typeof documents)['\n  query getPortfolioProjectByProjectId(\n    $projectId: String!\n    $withTransfers: Boolean = false\n  ) {\n    getPortfolioProjectByProjectId(projectId: $projectId) {\n      ...PortfolioProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Allocation on Allocation {\n    id\n    min\n    max\n    assetType\n    assetClass\n    category\n    sector\n    currency {\n      id\n    }\n    region {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Allocation on Allocation {\n    id\n    min\n    max\n    assetType\n    assetClass\n    category\n    sector\n    currency {\n      id\n    }\n    region {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AllocationWithPortfolio on Allocation {\n    id\n    min\n    max\n    assetType\n    assetClass\n    category\n    sector\n    currency {\n      id\n    }\n    region {\n      id\n    }\n    portfolio {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment AllocationWithPortfolio on Allocation {\n    id\n    min\n    max\n    assetType\n    assetClass\n    category\n    sector\n    currency {\n      id\n    }\n    region {\n      id\n    }\n    portfolio {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllocations($ids: [String!]) {\n    getAllocations(ids: $ids) {\n      ...AllocationWithPortfolio\n    }\n  }\n',
): (typeof documents)['\n  query getAllocations($ids: [String!]) {\n    getAllocations(ids: $ids) {\n      ...AllocationWithPortfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createAllocations($inputs: [CreateAllocationInputType]) {\n    createAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n',
): (typeof documents)['\n  mutation createAllocations($inputs: [CreateAllocationInputType]) {\n    createAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateAllocations($inputs: [UpdateAllocationInputType]) {\n    updateAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n',
): (typeof documents)['\n  mutation updateAllocations($inputs: [UpdateAllocationInputType]) {\n    updateAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertAllocations($inputs: [UpsertAllocationInputType]) {\n    upsertAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertAllocations($inputs: [UpsertAllocationInputType]) {\n    upsertAllocations(inputs: $inputs) {\n      ...AllocationWithPortfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteAllocations($inputs: [DeleteAllocationInputType]) {\n    deleteAllocations(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteAllocations($inputs: [DeleteAllocationInputType]) {\n    deleteAllocations(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PortfolioConfig on PortfolioConfig {\n    id\n    currentDashboardConfigId\n    portfolioDashboardConfigs {\n      ...PortfolioDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioConfig on PortfolioConfig {\n    id\n    currentDashboardConfigId\n    portfolioDashboardConfigs {\n      ...PortfolioDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPortfolioConfigs($portfolioId: String!) {\n    getPortfolioConfigs(portfolioId: $portfolioId) {\n      ...PortfolioConfig\n    }\n  }\n',
): (typeof documents)['\n  query getPortfolioConfigs($portfolioId: String!) {\n    getPortfolioConfigs(portfolioId: $portfolioId) {\n      ...PortfolioConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [CreatePortfolioConfigInputType]\n  ) {\n    createPortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation createPortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [CreatePortfolioConfigInputType]\n  ) {\n    createPortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [UpdatePortfolioConfigInputType]\n  ) {\n    updatePortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [UpdatePortfolioConfigInputType]\n  ) {\n    updatePortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [UpsertPortfolioConfigInputType]\n  ) {\n    upsertPortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [UpsertPortfolioConfigInputType]\n  ) {\n    upsertPortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs) {\n      ...PortfolioConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [DeletePortfolioConfigInputType!]\n  ) {\n    deletePortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePortfolioConfigs(\n    $portfolioId: String!\n    $inputs: [DeletePortfolioConfigInputType!]\n  ) {\n    deletePortfolioConfigs(portfolioId: $portfolioId, inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PortfolioDashboardConfig on PortfolioDashboardConfig {\n    id\n    name\n    layouts\n    compactType\n    elements {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioDashboardConfig on PortfolioDashboardConfig {\n    id\n    name\n    layouts\n    compactType\n    elements {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPortfolioDashboardConfigs(\n    $inputs: [CreatePortfolioDashboardConfigInputType]\n  ) {\n    createPortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation createPortfolioDashboardConfigs(\n    $inputs: [CreatePortfolioDashboardConfigInputType]\n  ) {\n    createPortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePortfolioDashboardConfigs(\n    $inputs: [UpdatePortfolioDashboardConfigInputType]\n  ) {\n    updatePortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePortfolioDashboardConfigs(\n    $inputs: [UpdatePortfolioDashboardConfigInputType]\n  ) {\n    updatePortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPortfolioDashboardConfigs(\n    $inputs: [UpsertPortfolioDashboardConfigInputType]\n  ) {\n    upsertPortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPortfolioDashboardConfigs(\n    $inputs: [UpsertPortfolioDashboardConfigInputType]\n  ) {\n    upsertPortfolioDashboardConfigs(inputs: $inputs) {\n      ...PortfolioDashboardConfig\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePortfolioDashboardConfigs($inputs: [String!]) {\n    deletePortfolioDashboardConfigs(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePortfolioDashboardConfigs($inputs: [String!]) {\n    deletePortfolioDashboardConfigs(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PortfolioBase on Portfolio {\n    id\n    name\n    description\n    parentPortfolio {\n      id\n    }\n    allocations {\n      ...Allocation\n    }\n    holdings {\n      __typename\n      holding {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioBase on Portfolio {\n    id\n    name\n    description\n    parentPortfolio {\n      id\n    }\n    allocations {\n      ...Allocation\n    }\n    holdings {\n      __typename\n      holding {\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Portfolio on Portfolio {\n    ...PortfolioBase\n    portfolios {\n      ...PortfolioBase\n      portfolios {\n        ...PortfolioBase\n        portfolios {\n          id\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Portfolio on Portfolio {\n    ...PortfolioBase\n    portfolios {\n      ...PortfolioBase\n      portfolios {\n        ...PortfolioBase\n        portfolios {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPortfolios($ids: [String!], $withTransfers: Boolean = false) {\n    getPortfolios(ids: $ids) {\n      ...Portfolio\n    }\n  }\n',
): (typeof documents)['\n  query getPortfolios($ids: [String!], $withTransfers: Boolean = false) {\n    getPortfolios(ids: $ids) {\n      ...Portfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPortfolios($inputs: [CreatePortfolioInputType]) {\n    createPortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n',
): (typeof documents)['\n  mutation createPortfolios($inputs: [CreatePortfolioInputType]) {\n    createPortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePortfolios($inputs: [UpdatePortfolioInputType]) {\n    updatePortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePortfolios($inputs: [UpdatePortfolioInputType]) {\n    updatePortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPortfolios($inputs: [UpsertPortfolioInputType]) {\n    upsertPortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPortfolios($inputs: [UpsertPortfolioInputType]) {\n    upsertPortfolios(inputs: $inputs) {\n      ...Portfolio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePortfolios($inputs: [DeletePortfolioInputType]) {\n    deletePortfolios(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePortfolios($inputs: [DeletePortfolioInputType]) {\n    deletePortfolios(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundProject on PrivateEquityFundProject {\n    id\n    project {\n      ...ProjectObj\n    }\n    privateEquityFund {\n      ...PrivateEquityFund\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundProject on PrivateEquityFundProject {\n    id\n    project {\n      ...ProjectObj\n    }\n    privateEquityFund {\n      ...PrivateEquityFund\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PrivateEquityFundProjectShallow on PrivateEquityFundProject {\n    id\n    project {\n      ...ProjectObjShallow\n    }\n  }\n',
): (typeof documents)['\n  fragment PrivateEquityFundProjectShallow on PrivateEquityFundProject {\n    id\n    project {\n      ...ProjectObjShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundProjects(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundProjects(ids: $ids) {\n      ...PrivateEquityFundProject\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundProjects(\n    $ids: [String!]\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundProjects(ids: $ids) {\n      ...PrivateEquityFundProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPrivateEquityFundProjects(\n    $inputs: [CreatePrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n',
): (typeof documents)['\n  mutation createPrivateEquityFundProjects(\n    $inputs: [CreatePrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createPrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePrivateEquityFundProjects(\n    $inputs: [UpdatePrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePrivateEquityFundProjects(\n    $inputs: [UpdatePrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updatePrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertPrivateEquityFundProjects(\n    $inputs: [UpsertPrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertPrivateEquityFundProjects(\n    $inputs: [UpsertPrivateEquityFundProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertPrivateEquityFundProjects(inputs: $inputs) {\n      ...PrivateEquityFundProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deletePrivateEquityFundProjects(\n    $inputs: [DeletePrivateEquityFundProjectInputType]\n  ) {\n    deletePrivateEquityFundProjects(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deletePrivateEquityFundProjects(\n    $inputs: [DeletePrivateEquityFundProjectInputType]\n  ) {\n    deletePrivateEquityFundProjects(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPrivateEquityFundProjectByProjectId(\n    $projectId: String!\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundProjectByProjectId(projectId: $projectId) {\n      ...PrivateEquityFundProject\n    }\n  }\n',
): (typeof documents)['\n  query getPrivateEquityFundProjectByProjectId(\n    $projectId: String!\n    $withTransfers: Boolean = false\n  ) {\n    getPrivateEquityFundProjectByProjectId(projectId: $projectId) {\n      ...PrivateEquityFundProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProjectObjShallow on ProjectObj {\n    __typename\n    id\n    name\n    type\n  }\n',
): (typeof documents)['\n  fragment ProjectObjShallow on ProjectObj {\n    __typename\n    id\n    name\n    type\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProjectObj on ProjectObj {\n    __typename\n    id\n    name\n    type\n    holdings {\n      ...Holding\n    }\n    transactions {\n      ...Transaction\n    }\n  }\n',
): (typeof documents)['\n  fragment ProjectObj on ProjectObj {\n    __typename\n    id\n    name\n    type\n    holdings {\n      ...Holding\n    }\n    transactions {\n      ...Transaction\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProjectShallow on Project {\n    __typename\n    ... on PortfolioProject {\n      ...PortfolioProjectShallow\n    }\n    ... on PrivateEquityFundProject {\n      ...PrivateEquityFundProjectShallow\n    }\n  }\n',
): (typeof documents)['\n  fragment ProjectShallow on Project {\n    __typename\n    ... on PortfolioProject {\n      ...PortfolioProjectShallow\n    }\n    ... on PrivateEquityFundProject {\n      ...PrivateEquityFundProjectShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Project on Project {\n    __typename\n    ... on PortfolioProject {\n      ...PortfolioProject\n    }\n    ... on PrivateEquityFundProject {\n      ...PrivateEquityFundProject\n    }\n  }\n',
): (typeof documents)['\n  fragment Project on Project {\n    __typename\n    ... on PortfolioProject {\n      ...PortfolioProject\n    }\n    ... on PrivateEquityFundProject {\n      ...PrivateEquityFundProject\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjects($ids: [String!], $withTransfers: Boolean = false) {\n    getProjects(ids: $ids) {\n      ...Project\n    }\n  }\n',
): (typeof documents)['\n  query getProjects($ids: [String!], $withTransfers: Boolean = false) {\n    getProjects(ids: $ids) {\n      ...Project\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectObjsShallow($ids: [String!]) {\n    getProjectObjs(ids: $ids) {\n      ...ProjectObjShallow\n    }\n  }\n',
): (typeof documents)['\n  query getProjectObjsShallow($ids: [String!]) {\n    getProjectObjs(ids: $ids) {\n      ...ProjectObjShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createProjects(\n    $inputs: [CreateProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n',
): (typeof documents)['\n  mutation createProjects(\n    $inputs: [CreateProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    createProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateProjects(\n    $inputs: [UpdateProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updateProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n',
): (typeof documents)['\n  mutation updateProjects(\n    $inputs: [UpdateProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    updateProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertProjects(\n    $inputs: [UpsertProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertProjects(\n    $inputs: [UpsertProjectInputType]\n    $withTransfers: Boolean = false\n  ) {\n    upsertProjects(inputs: $inputs) {\n      ...ProjectObj\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteProjects($inputs: [DeleteProjectInputType]) {\n    deleteProjects(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteProjects($inputs: [DeleteProjectInputType]) {\n    deleteProjects(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment GeneratedReport on GeneratedReport {\n    id\n    objectKey\n    name\n    description\n    date\n    project {\n      id\n    }\n    holding {\n      id\n    }\n    report {\n      ...Report\n    }\n  }\n',
): (typeof documents)['\n  fragment GeneratedReport on GeneratedReport {\n    id\n    objectKey\n    name\n    description\n    date\n    project {\n      id\n    }\n    holding {\n      id\n    }\n    report {\n      ...Report\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getGeneratedReports($ids: [String!]) {\n    getGeneratedReports(ids: $ids) {\n      ...GeneratedReport\n    }\n  }\n',
): (typeof documents)['\n  query getGeneratedReports($ids: [String!]) {\n    getGeneratedReports(ids: $ids) {\n      ...GeneratedReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getGeneratedReportsByProject($projectId: String!) {\n    getGeneratedReportsByProject(projectId: $projectId) {\n      ...GeneratedReport\n    }\n  }\n',
): (typeof documents)['\n  query getGeneratedReportsByProject($projectId: String!) {\n    getGeneratedReportsByProject(projectId: $projectId) {\n      ...GeneratedReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createGeneratedReports($inputs: [CreateGeneratedReportInputType]) {\n    createGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n',
): (typeof documents)['\n  mutation createGeneratedReports($inputs: [CreateGeneratedReportInputType]) {\n    createGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateGeneratedReports($inputs: [UpdateGeneratedReportInputType]) {\n    updateGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n',
): (typeof documents)['\n  mutation updateGeneratedReports($inputs: [UpdateGeneratedReportInputType]) {\n    updateGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertGeneratedReports($inputs: [UpsertGeneratedReportInputType]) {\n    upsertGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertGeneratedReports($inputs: [UpsertGeneratedReportInputType]) {\n    upsertGeneratedReports(inputs: $inputs) {\n      ...GeneratedReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteGeneratedReports($inputs: [DeleteGeneratedReportInputType]) {\n    deleteGeneratedReports(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteGeneratedReports($inputs: [DeleteGeneratedReportInputType]) {\n    deleteGeneratedReports(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProjectReport on ProjectReport {\n    id\n    name\n    periodStart\n    periodEnd\n    published\n    compactType\n    project {\n      id\n    }\n    layouts\n    elements {\n      ...DashboardElement\n    }\n  }\n',
): (typeof documents)['\n  fragment ProjectReport on ProjectReport {\n    id\n    name\n    periodStart\n    periodEnd\n    published\n    compactType\n    project {\n      id\n    }\n    layouts\n    elements {\n      ...DashboardElement\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectReports($ids: [String!]) {\n    getProjectReports(ids: $ids) {\n      ...ProjectReport\n    }\n  }\n',
): (typeof documents)['\n  query getProjectReports($ids: [String!]) {\n    getProjectReports(ids: $ids) {\n      ...ProjectReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectReportsByProject($projectId: String!) {\n    getProjectReportsByProject(projectId: $projectId) {\n      ...ProjectReport\n    }\n  }\n',
): (typeof documents)['\n  query getProjectReportsByProject($projectId: String!) {\n    getProjectReportsByProject(projectId: $projectId) {\n      ...ProjectReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createProjectReports($inputs: [CreateProjectReportInputType]) {\n    createProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n',
): (typeof documents)['\n  mutation createProjectReports($inputs: [CreateProjectReportInputType]) {\n    createProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateProjectReports($inputs: [UpdateProjectReportInputType]) {\n    updateProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n',
): (typeof documents)['\n  mutation updateProjectReports($inputs: [UpdateProjectReportInputType]) {\n    updateProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertProjectReports($inputs: [UpsertProjectReportInputType]) {\n    upsertProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertProjectReports($inputs: [UpsertProjectReportInputType]) {\n    upsertProjectReports(inputs: $inputs) {\n      ...ProjectReport\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteProjectReports($inputs: [DeleteProjectReportInputType]) {\n    deleteProjectReports(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteProjectReports($inputs: [DeleteProjectReportInputType]) {\n    deleteProjectReports(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Report on Report {\n    id\n    name\n    description\n    project {\n      id\n    }\n    holding {\n      id\n    }\n    scheduler {\n      ...Scheduler\n    }\n    users {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment Report on Report {\n    id\n    name\n    description\n    project {\n      id\n    }\n    holding {\n      id\n    }\n    scheduler {\n      ...Scheduler\n    }\n    users {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReports($ids: [String!]) {\n    getReports(ids: $ids) {\n      ...Report\n    }\n  }\n',
): (typeof documents)['\n  query getReports($ids: [String!]) {\n    getReports(ids: $ids) {\n      ...Report\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReportsByProject($projectId: String!) {\n    getReportsByProject(projectId: $projectId) {\n      ...Report\n    }\n  }\n',
): (typeof documents)['\n  query getReportsByProject($projectId: String!) {\n    getReportsByProject(projectId: $projectId) {\n      ...Report\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createReports($inputs: [CreateReportInputType]) {\n    createReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n',
): (typeof documents)['\n  mutation createReports($inputs: [CreateReportInputType]) {\n    createReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateReports($inputs: [UpdateReportInputType]) {\n    updateReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n',
): (typeof documents)['\n  mutation updateReports($inputs: [UpdateReportInputType]) {\n    updateReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertReports($inputs: [UpsertReportInputType]) {\n    upsertReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertReports($inputs: [UpsertReportInputType]) {\n    upsertReports(inputs: $inputs) {\n      ...Report\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteReports($inputs: [DeleteReportInputType]) {\n    deleteReports(inputs: $inputs)\n  }\n',
): (typeof documents)['\n  mutation deleteReports($inputs: [DeleteReportInputType]) {\n    deleteReports(inputs: $inputs)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment User on User {\n    id\n    name\n    email\n  }\n',
): (typeof documents)['\n  fragment User on User {\n    id\n    name\n    email\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspacePermission on WorkspacePermission {\n    addPortfolios\n    editPortfolios\n    removePortfolios\n    viewPortfolios\n    viewWorkspaceProperties\n    editWorkspaceProperties\n    addWorkspaceRoles\n    updateWorkspaceRoles\n    removeWorkspaceRoles\n    addInvitations\n    updateInvitations\n    removeInvitations\n    remove\n  }\n',
): (typeof documents)['\n  fragment WorkspacePermission on WorkspacePermission {\n    addPortfolios\n    editPortfolios\n    removePortfolios\n    viewPortfolios\n    viewWorkspaceProperties\n    editWorkspaceProperties\n    addWorkspaceRoles\n    updateWorkspaceRoles\n    removeWorkspaceRoles\n    addInvitations\n    updateInvitations\n    removeInvitations\n    remove\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspaceRole on WorkspaceRole {\n    id\n    name\n    permission {\n      ...WorkspacePermission\n    }\n    group {\n      ...Group\n    }\n  }\n',
): (typeof documents)['\n  fragment WorkspaceRole on WorkspaceRole {\n    id\n    name\n    permission {\n      ...WorkspacePermission\n    }\n    group {\n      ...Group\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Workspace on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...Project\n    }\n    assets @include(if: $withAssets) {\n      ...Asset\n    }\n    entities @include(if: $withEntities) {\n      ...Entity\n    }\n    people @include(if: $withPeople) {\n      ...Person\n    }\n  }\n',
): (typeof documents)['\n  fragment Workspace on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...Project\n    }\n    assets @include(if: $withAssets) {\n      ...Asset\n    }\n    entities @include(if: $withEntities) {\n      ...Entity\n    }\n    people @include(if: $withPeople) {\n      ...Person\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspaceShallow on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...ProjectShallow\n    }\n  }\n',
): (typeof documents)['\n  fragment WorkspaceShallow on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...ProjectShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspaceBaseWithRoles on WorkspaceBaseWithRoles {\n    __typename\n    id\n    name\n    workspaceRoles {\n      ...WorkspaceRole\n    }\n  }\n',
): (typeof documents)['\n  fragment WorkspaceBaseWithRoles on WorkspaceBaseWithRoles {\n    __typename\n    id\n    name\n    workspaceRoles {\n      ...WorkspaceRole\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspaceWithRoles on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...ProjectShallow\n    }\n    workspaceRoles {\n      ...WorkspaceRole\n    }\n  }\n',
): (typeof documents)['\n  fragment WorkspaceWithRoles on Workspace {\n    __typename\n    id\n    name\n    projects {\n      ...ProjectShallow\n    }\n    workspaceRoles {\n      ...WorkspaceRole\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspace(\n    $withAssets: Boolean = false\n    $withEntities: Boolean = false\n    $withPeople: Boolean = false\n    $withTransfers: Boolean = false\n  ) {\n    getWorkspace {\n      ...Workspace\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspace(\n    $withAssets: Boolean = false\n    $withEntities: Boolean = false\n    $withPeople: Boolean = false\n    $withTransfers: Boolean = false\n  ) {\n    getWorkspace {\n      ...Workspace\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceShallow {\n    getWorkspace {\n      ...WorkspaceShallow\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceShallow {\n    getWorkspace {\n      ...WorkspaceShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createWorkspace($input: CreateWorkspaceInputType) {\n    createWorkspace(input: $input) {\n      ...WorkspaceBaseWithRoles\n    }\n  }\n',
): (typeof documents)['\n  mutation createWorkspace($input: CreateWorkspaceInputType) {\n    createWorkspace(input: $input) {\n      ...WorkspaceBaseWithRoles\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateWorkspace($input: UpdateWorkspaceInputType) {\n    updateWorkspace(input: $input) {\n      ...WorkspaceShallow\n    }\n  }\n',
): (typeof documents)['\n  mutation updateWorkspace($input: UpdateWorkspaceInputType) {\n    updateWorkspace(input: $input) {\n      ...WorkspaceShallow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteWorkspace {\n    deleteWorkspace\n  }\n',
): (typeof documents)['\n  mutation deleteWorkspace {\n    deleteWorkspace\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation applyWorkspaceSnapshot($input: WorkspaceSnapshotInputType) {\n    applyWorkspaceSnapshot(input: $input) {\n      ...WorkspaceShallow\n    }\n  }\n',
): (typeof documents)['\n  mutation applyWorkspaceSnapshot($input: WorkspaceSnapshotInputType) {\n    applyWorkspaceSnapshot(input: $input) {\n      ...WorkspaceShallow\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
