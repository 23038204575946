import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { CreateStockPositionDialogContentComponent } from './create-dialog-content.component';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
  stockPositionCreateInputInit: graphqlWorkspaceTypes.CreateStockPositionInputType;
}

export const CreateStockPositionComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <CreateStockPositionDialogContentComponent
        workspace={props.workspace}
        handleClose={props.handleClose}
        stockPositionCreateInputInit={props.stockPositionCreateInputInit}
      />
    </Dialog>
  );
});
