import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createAssetCategories } from '~src/data/store/modules/assets/categories/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export const useCreateAssetCategory = (workspace: WorkspaceState) => {
  const [categoryInput, setCategoryInput] =
    React.useState<graphqlWorkspaceTypes.CreateAssetCategoryInputType>({
      id: uuid.v1(),
      name: '',
    });

  const dispatch = useDispatch<AppDispatch>();

  const handleCreateCategory = React.useCallback(() => {
    return dispatch(
      createAssetCategories(workspace.tenantId, workspace.id, [categoryInput]),
    ).then((categories) => categories[0]);
  }, [categoryInput, dispatch, workspace]);
  return { categoryInput, setCategoryInput, handleCreateCategory };
};
