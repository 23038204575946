import React from 'react';
// import { SelectDownshiftField } from '~src/components/inputs/select-downshift-field';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsByProjectIds } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectsByWorkspaceId } from '~src/data/store/selectors/workspace/projects/base/selectors';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Popper,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { findSorted, strcmp } from '@pladdenico/common';

interface Props {
  workspaceId: string;
  // projectId?: string;
  holdingId?: string;
  handleValue: (holding: HoldingState) => void;
  labelled: boolean;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 150,
    // flexBasis: 200,
  },

  popper: {
    // width: 'fit-content',
    width: 250,
  },
}));

export const EditHoldingFromWorkspaceField = React.memo((props: Props) => {
  const { classes } = useStyles();
  const projects = useSelector((state: RootState) =>
    getProjectsByWorkspaceId(selectWorkspaceData(state), props.workspaceId),
  );
  const projectIds = React.useMemo(
    () => projects.map((project) => project.id),
    [projects],
  );
  const holdings = useSelector((state: RootState) =>
    getHoldingsByProjectIds(selectWorkspaceData(state), projectIds),
  );
  const holding = React.useMemo(() => {
    if (props.holdingId) {
      const item = findSorted(
        holdings,
        props.holdingId,
        (a, b) => strcmp(a.id, b),
        0,
      );
      return item.item;
    }
    return undefined;
  }, [holdings, props.holdingId]);

  // const styles = (_theme: Theme) => ({
  //   popper: {
  //     width: 'fit-content',
  //   },
  // });

  const PopperMy = function (props: any) {
    return (
      <Popper
        {...props}
        // classes={classes.popper}
        style={{ width: 'fit-content' }}
        // placement="bottom-start"
      />
    );
  };

  return (
    <Autocomplete
      options={holdings}
      // classes={{
      //   popper: classes.popper,
      // }}
      PopperComponent={PopperMy}
      getOptionLabel={(holding: HoldingState) => holding.name ?? ''}
      // style={{ width: 210 }}
      value={holding ? holding : null}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: HoldingState | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          className={classes.textField}
          label={props.labelled ? 'Holding' : undefined}
          fullWidth
          margin="normal"
        />
      )}
    />
  );

  // return <SelectDownshiftField value={props.holding} label='holding' matcher={getMatcher} suggestions={holdings}
  //   toName={(holding: Holding) => holding ? toName(holding) : ''} handleValue={props.handleValue}/>
});
