import { User, UserCreateInput } from '@pladdenico/models';
import React from 'react';
import { useDispatch } from 'react-redux';
// import history from '~src/navigation/history';
import { AppDispatch } from '~src/store/store';
import { signup } from '~src/data/store/modules/account';

import { userLoginPath } from '../../../../navigation/paths/user/paths';
import { useNavigate } from 'react-router-dom';

export function useCreateUser(redirectUrl?: string) {
  const [userInput, setUserInput] = React.useState<UserCreateInput>({
    name: '',
    email: '',
    password: '',
    groupRelations: [],
  });
  const [signupError, setSignupError] = React.useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const saveUser = () => {
    dispatch(
      signup(
        userInput.name,
        userInput.email,
        userInput.password,
        (_user?: User, err?: any) => {
          if (err) {
            setSignupError(err);
            setSignupError(err);
          } else {
            setSignupError(undefined);
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              navigate(userLoginPath);
            }
          }
        },
      ),
    );
  };

  return {
    userInput,
    setUserInput,
    signupError,
    setSignupError,
    saveUser,
  };
}
