import { Maybe } from 'graphql/jsutils/Maybe';
import { pick } from 'lodash';
import { upsertRoles } from '~src/data/store/modules/people/roles/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'peopleRoles';

export interface Role {
  id: string;
  name?: Maybe<string>;
  description?: Maybe<string>;
  personId: string;
  holdingId: string;
}

const header: Array<keyof Role> = [
  'id',
  'personId',
  'holdingId',
  'name',
  'description',
];

export const picker = (one: any): Role => {
  return pick(one, header);
};

export const roleExportHandler = (data: Role[]): ExportHandler<Role> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const roleImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Role> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertRoles(workspace.tenantId, workspace.id, ts)),
  };
};
