import React from 'react';
import { useSelector } from 'react-redux';
import { EditAsset } from '~src/components/fields/workspace/asset/edit/edit-asset';
import { AssetTypedInterface } from '~src/data/store/reducers/asset/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getCryptoById,
  getCryptosByWorkspaceId,
} from '~src/data/store/selectors/asset/asset-types/cryptos/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';
import { DialogContentComponent } from '~src/domain/workspace/components/asset/form/create/dialog-content.component';

import { AssetType } from '@pladdenico/models';

interface Props {
  handleValue: (cryptoId: string) => void;
  handlePersist?: () => void;
  cryptoId?: string;
  workspace: WorkspaceState;
}

export const EditCrypto = React.memo((props: Props) => {
  const { handleValue, workspace, cryptoId, handlePersist } = props;
  const cryptos = useSelector((state: RootState) =>
    getCryptosByWorkspaceId(selectWorkspaceData(state), workspace.id),
  );
  const crypto = useSelector((state: RootState) =>
    getCryptoById(selectWorkspaceData(state), {
      id: cryptoId,
      workspaceId: workspace.id,
    }),
  );

  const getDialogContent = React.useCallback(
    (
      assetInput: AssetCreateInputData,
      callback: (assetTyped: AssetTypedInterface) => void,
      setOpen: (open: boolean) => void,
    ) => {
      return (
        <DialogContentComponent
          handleClose={() => setOpen(false)}
          workspace={workspace}
          assetInput={assetInput}
          fieldsConfig={{
            disableAssetType: true,
          }}
          createdCallback={callback}
        />
      );
    },
    [workspace],
  );

  return (
    <EditAsset<AssetTypedInterface>
      handleValue={(t) => handleValue(t.id)}
      value={crypto}
      options={cryptos}
      workspace={workspace}
      label={'Crypto'}
      getDialogContent={getDialogContent}
      assetType={AssetType.Crypto}
      handlePersist={handlePersist}
    />
  );
});
