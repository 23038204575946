import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  regionsSelectors,
  RegionState,
} from '~src/data/store/reducers/common/regions/reducer';
import { HoldingData } from '~src/domain/workspace/components/project/holding/form/use-fields.component';

import { RecordState } from '@pladdenico/common';

export type HoldingDataWithRegionInfo = HoldingData & {
  regions: RegionState[];
};

export const getRegionState = (state: WorkspaceDataState) =>
  state.common.regions;

export const selectRegionsByTenantId = createCachedSelector(
  (state: RecordState<RegionState, string>[], tenantId: string) =>
    regionsSelectors.selectElementsByKey(state, tenantId),
  (regions: RegionState[] | undefined): RegionState[] => {
    return regions ? regions : [];
  },
)({
  keySelector: (_state, tenantId) => tenantId,
  selectorCreator: createSelector,
});

export const getRegionsByTenantId = createCachedSelector(
  (state: WorkspaceDataState) => getRegionState(state),
  (_state: WorkspaceDataState, tenantId: string | null) => tenantId,
  (regionState, tenantId): RegionState[] => {
    if (!tenantId) {
      return [];
    }
    return selectRegionsByTenantId(regionState, tenantId);
  },
)({
  keySelector: (_state, tenantId) => tenantId,
  selectorCreator: createSelector,
});

export const getRegions = createSelector(
  (state: WorkspaceDataState) => getRegionState(state),
  (regions) => {
    return regionsSelectors.selectAllElements(regions);
  },
);

interface NullableRegionId {
  id: string | null;
}

export const getRegionById = createCachedSelector(
  (state: WorkspaceDataState) => getRegionState(state),
  (_state: WorkspaceDataState, baseId: NullableRegionId) => baseId.id,
  (regions, id): RegionState | undefined => {
    if (id !== null) {
      return regionsSelectors.selectElementByT(regions, {
        id,
      });
    }
  },
)({
  keySelector: (_state, baseId) => `:${baseId.id}`,
  selectorCreator: createSelector,
});

export const OLD_selectRegionById = createCachedSelector(
  (state: RecordState<RegionState, string>[]) => state,
  (_state: RecordState<RegionState, string>[], baseId: NullableRegionId) =>
    baseId.id,
  (regions, id): RegionState | undefined => {
    if (id !== null) {
      return regionsSelectors.selectElementByT(regions, {
        id,
      });
    }
  },
)({
  keySelector: (_state, baseId) => `:${baseId.id}`,
  selectorCreator: createSelector,
});

export const selectRegionById = createCachedSelector(
  (state: RegionState[]) => state,
  (_state: RegionState[], baseId: NullableRegionId) => baseId.id,
  (regions, id): RegionState | undefined => {
    if (id !== null) {
      return regionsSelectors.selectElementInRecordById(regions, {
        id,
      });
    }
  },
)({
  keySelector: (_state, baseId) => `:${baseId.id}`,
  selectorCreator: createSelector,
});
