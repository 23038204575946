import { difference, isEqual } from 'lodash';
import React from 'react';
import { updatePortfolios } from '~src/data/store/modules/portfolios/portfolios/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';

import { MultipleRelationOperationType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

function createUpdatePortfolioInputs(
  holding: HoldingState,
  addedPortfolios: PortfolioState[],
  removedPortfolios: PortfolioState[],
) {
  const portfolioUpdateInputs: graphqlWorkspaceTypes.UpdatePortfolioInputType[] =
    [];
  addedPortfolios.forEach((portfolio) => {
    portfolioUpdateInputs.push({
      id: portfolio.id,
      holdingRelations: [
        {
          id: holding.id,
          type: MultipleRelationOperationType.add,
        },
      ],
    });
  });
  removedPortfolios.forEach((portfolio) => {
    portfolioUpdateInputs.push({
      id: portfolio.id,
      holdingRelations: [
        {
          id: holding.id,
          type: MultipleRelationOperationType.remove,
        },
      ],
    });
  });
  return portfolioUpdateInputs;
}

export function usePortfolioHoldings(
  workspace: WorkspaceState,
  existingPortfolios: PortfolioState[],
) {
  const [portfolios, setPortfolios] = React.useState([...existingPortfolios]);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    setPortfolios([...existingPortfolios]);
  }, [existingPortfolios, setPortfolios]);

  const update = React.useCallback(
    (holding: HoldingState) => {
      if (!isEqual(existingPortfolios, portfolios)) {
        const addedPortfolios = difference(portfolios, existingPortfolios);
        const removedPortfolios = difference(existingPortfolios, portfolios);

        const portfolioUpdateInputs = createUpdatePortfolioInputs(
          holding,
          addedPortfolios,
          removedPortfolios,
        );
        return dispatch(
          updatePortfolios(
            workspace.tenantId,
            workspace.id,
            portfolioUpdateInputs,
          ),
        );
      }
      return Promise.resolve([]);
    },
    [
      dispatch,
      existingPortfolios,
      portfolios,
      workspace.id,
      workspace.tenantId,
    ],
  );

  return {
    setPortfolios,
    portfolios,
    updatePortfolios: update,
  };
}
