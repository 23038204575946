import { compact } from 'lodash';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  AdapterTransaction,
  getAdapterTransactions,
} from '~src/data/store/selectors/data-import/adapter/selectors';
import { selectDataImportData } from '~src/data/store/selectors/selectors';
import { ImportedTransactionComponent } from '~src/domain/workspace/components/data/adapters/imported-transaction.component';
import {
  dnbTransactionsActions,
  DnbTransactionState,
} from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';
import {
  nordnetTransactionsActions,
  NordnetTransactionState,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import {
  vpsTransactionsActions,
  VpsTransactionState,
} from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';
import { AppDispatch } from '~src/store/store';
import { isSameDay } from '~src/utils/common/date';

// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, colors, Grid, Paper, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

export const ImportedTransactionsComponent = React.memo((props: Props) => {
  const { workspace, projectId } = props;
  const dispatch = useDispatch<AppDispatch>();
  const transactions = useSelector((state: RootState) =>
    getAdapterTransactions(selectDataImportData(state)),
  );

  const vpsTransactions = React.useMemo(
    () =>
      compact(
        transactions.map((t) => {
          if (t.adapterId === 'vps') {
            return t as VpsTransactionState;
          }
        }),
      ),
    [transactions],
  );
  const nordnetTransactions = React.useMemo(
    () =>
      compact(
        transactions.map((t) => {
          if (t.adapterId === 'nordnet') {
            return t as NordnetTransactionState;
          }
        }),
      ),
    [transactions],
  );

  const [activeGroup, setActiveGroup] = React.useState<string | null>(null);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  const updateGroupId = React.useCallback(
    (ids: string[], groupId: string) => {
      // const groupId = uuid.v1();
      batch(() => {
        ids.forEach((id) => {
          const t = transactions.find((t) => t.id === id);
          if (t) {
            if (t.adapterId === 'dnb' && t.groupId !== groupId) {
              const updatedT = { ...(t as DnbTransactionState), groupId };
              dispatch(dnbTransactionsActions.upsertOneElement(updatedT));
            } else if (t.adapterId === 'vps') {
              const updatedT = { ...(t as VpsTransactionState), groupId };
              dispatch(vpsTransactionsActions.upsertOneElement(updatedT));
            } else if (t.adapterId === 'nordnet') {
              const updatedT = { ...(t as NordnetTransactionState), groupId };
              dispatch(nordnetTransactionsActions.upsertOneElement(updatedT));
            }
          }
        });
      });
    },
    [dispatch, transactions],
  );

  const handleClickTransaction = React.useCallback(
    (ctrlKey: boolean, transaction: AdapterTransaction) => {
      if (!ctrlKey) {
        setActiveGroup(transaction.groupId);
        setSelectedGroup(null);
      } else {
        if (selectedGroup === null) {
          setSelectedGroup(transaction.groupId);
        } else if (selectedGroup === transaction.groupId) {
          updateGroupId([transaction.id], uuid.v1());
        } else {
          const ids = transactions
            .filter((t) => t.groupId === transaction.groupId)
            .map((t) => t.id);
          updateGroupId(ids, selectedGroup);
        }
      }
    },
    [selectedGroup, transactions, updateGroupId],
  );

  const getPotentialMatches = React.useCallback(() => {
    let currentDate: Date | null = null;
    let idx = 0;
    const matches = new Array<number[] | null | undefined>(transactions.length);
    for (let i = 0; i < transactions.length; ++i) {
      const transaction = transactions[i];
      if (currentDate == null || !isSameDay(currentDate, transaction.date)) {
        for (let j = idx; j < i; ++j) {
          for (let k = j + 1; k < i; ++k) {
            const valueJ = transactions[j].value;
            const valueK = transactions[k].value;
            if (valueK === -valueJ) {
              if (matches[j] == null) {
                matches[j] = null;
              } else {
                matches[j]?.push(k);
              }
              if (matches[k] == null) {
                matches[k] = [j];
              } else {
                matches[k]?.push(j);
              }
            }
          }
        }

        currentDate = transaction.date;
        idx = i;
      }
    }
    return matches;
  }, [transactions]);

  const potentialMatches = React.useMemo(() => {
    return getPotentialMatches();
  }, [getPotentialMatches]);

  const groupByPotentialMatches = React.useCallback(() => {
    for (let i = 0; i < transactions.length; ++i) {
      const transaction = transactions[i];
      const pMs = potentialMatches[i];
      if (pMs && pMs.length > 0) {
        const ms = pMs.map((pm) => transactions[pm]);
        updateGroupId([transaction.id, ...ms.map((m) => m.id)], uuid.v1());
      } else if (pMs !== null) {
        updateGroupId([transaction.id], uuid.v1());
      }
    }
  }, [potentialMatches, transactions, updateGroupId]);

  type TransactionGroup = {
    id: string | null;
    transactions: AdapterTransaction[];
  };
  const transactionsGrouped = React.useMemo(() => {
    const grouped: TransactionGroup[] = [];
    for (let i = 0; i < transactions.length; ++i) {
      const transaction = transactions[i];
      if (transaction.groupId != null) {
        const groupIdx = grouped.findIndex((g) => g.id === transaction.groupId);
        if (groupIdx === -1) {
          grouped.push({
            id: transaction.groupId,
            transactions: [transaction],
          });
        } else {
          grouped[groupIdx].transactions = [
            ...grouped[groupIdx].transactions,
            transaction,
          ];
        }
      } else {
        const pMs = potentialMatches[i];
        if (pMs && pMs.length > 0) {
          const ms = pMs.map((pm) => transactions[pm]);
          grouped.push({ id: null, transactions: [transaction, ...ms] });
        } else if (pMs !== null) {
          grouped.push({ id: null, transactions: [transaction] });
        }
      }
    }
    return grouped;
  }, [potentialMatches, transactions]);

  const setHoldingIdForSimilarTransactions = React.useCallback(
    (transaction: AdapterTransaction) => {
      if (transaction.adapterId === 'vps') {
        const vpsTransaction = transaction as VpsTransactionState;
        const updatedTransactions = vpsTransactions
          .filter((t) => t.ISIN === vpsTransaction.ISIN)
          .map((t) => {
            t.holdingId = vpsTransaction.holdingId;
            return t;
          });
        dispatch(
          vpsTransactionsActions.upsertManyElements(updatedTransactions),
        );
      } else if (transaction.adapterId === 'nordnet') {
        const nordnetTransaction = transaction as NordnetTransactionState;
        const updatedTransactions: NordnetTransactionState[] = [];
        for (let i = 0; i < nordnetTransactions.length; ++i) {
          const t = nordnetTransactions[i];
          let updated = false;
          if (
            t.ISIN === nordnetTransaction.ISIN &&
            nordnetTransaction.holdingId != null
          ) {
            t.holdingId = nordnetTransaction.holdingId;
            updated = true;
          }
          if (
            t.account === nordnetTransaction.account &&
            t.bankNoteCurrencyId === nordnetTransaction.bankNoteCurrencyId &&
            nordnetTransaction.bankNoteHoldingId != null
          ) {
            t.bankNoteHoldingId = nordnetTransaction.bankNoteHoldingId;
            updated = true;
          }
          if (updated) {
            updatedTransactions.push(t);
          }
        }
        dispatch(
          nordnetTransactionsActions.upsertManyElements(updatedTransactions),
        );
      }
    },
    [dispatch, nordnetTransactions, vpsTransactions],
  );

  const transactionElements = transactionsGrouped.map((tG) => {
    const active = activeGroup === tG.id;
    const selected = tG.id === selectedGroup;
    // selectedTransactionIds.length > 0 &&
    // selectedTransactionIds.every((t) => t.groupId === tG.id);
    return (
      <Paper
        key={tG.transactions[0].id}
        sx={{
          backgroundColor: selected
            ? colors.purple[900]
            : active
              ? colors.blue[900]
              : colors.blue[50],
          color: active ? colors.common.white : 'inherit',
          mb: 2,
        }}
      >
        <ImportedTransactionComponent
          active={active}
          workspace={workspace}
          projectId={projectId}
          transactions={tG.transactions}
          handleSuccess={setHoldingIdForSimilarTransactions}
          onClick={handleClickTransaction}
        />
        {/* <Divider /> */}
      </Paper>
    );
  });

  // const transactionElementsOLD = transactions.map(
  //   (adapterTransaction, index) => {
  //     let transactionElement;

  //     if (adapterTransaction.adapterId === 'dnb') {
  //       const transaction =
  //         adapterTransaction.transaction as DnbTransactionState;
  //       transactionElement = (
  //         <DnbImportedTransactionComponent
  //           dnbTransaction={transaction}
  //           workspace={workspace}
  //           projectId={projectId}
  //           active={activeTransaction === adapterTransaction.transaction.id}
  //         />
  //       );
  //     } else if (adapterTransaction.adapterId === 'vps') {
  //       const transaction =
  //         adapterTransaction.transaction as VpsTransactionState;
  //       transactionElement = (
  //         <VpsImportedTransactionComponent
  //           vpsTransaction={transaction}
  //           workspace={workspace}
  //           projectId={projectId}
  //           active={activeTransaction === adapterTransaction.transaction.id}
  //         />
  //       );
  //     }

  //     let createTransactionElement;
  //     let matchElement;
  //     if (activeTransaction === adapterTransaction.transaction.id) {
  //       const matches = potentialMatches[index];
  //       if (matches != null && matches.length > 0) {
  //         matchElement = matches.map((i) => {
  //           return <div key={i}>{i}</div>;
  //         });
  //       }
  //       createTransactionElement = (
  //         <InitiateTransactionComponent
  //           workspace={workspace}
  //           projectId={projectId}
  //           transactions={getMatchingTransactions(index)}
  //           close={dialog.close}
  //           isShowing={dialog.isShowing}
  //           handleSuccess={setHoldingIdForSimilarVpsTransactions}
  //         />
  //       );
  //     }

  //     return (
  //       <Box
  //         key={adapterTransaction.transaction.id}
  //         onClick={(event) =>
  //           handleClickTransaction(
  //             event.ctrlKey,
  //             adapterTransaction.transaction.id
  //           )
  //         }
  //       >
  //         {transactionElement}
  //         {matchElement}
  //         {createTransactionElement}
  //         <Divider />
  //       </Box>
  //     );
  //   }
  // );
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={1} sx={{ pl: 1 }}>
          <Typography variant="subtitle1">Adapter</Typography>
        </Grid>
        <Grid item xs={1} sx={{ pl: 1 }}>
          <Typography variant="subtitle1">Date</Typography>
        </Grid>
        <Grid item xs={2} sx={{ pl: 1 }}>
          <Typography variant="subtitle1">Name</Typography>
        </Grid>
        <Grid item xs={2} sx={{ pl: 1 }}>
          <Typography variant="subtitle1">Description</Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="subtitle1" margin={1}>
            Value
          </Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="subtitle1" margin={1}>
            Volume
          </Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="subtitle1" margin={1}>
            Price
          </Typography>
        </Grid>
        <Grid container item xs={2} sx={{ pl: 1 }}>
          <Typography variant="subtitle1">Transaction id</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle1">Actions</Typography>
        </Grid>
      </Grid>
      {/* <IconButton sx={{ position: 'fixed' }} onClick={dialog.open}>
        <AddCircleIcon />
      </IconButton> */}
      <Button onClick={groupByPotentialMatches}>
        Group by potential match
      </Button>
      {transactionElements}
    </>
  );
});
