import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  cryptoHoldingsSelectors,
  CryptoHoldingState,
} from '../../../../../reducers/holding/holding-types/crypto/crypto-holding/reducer';

const getCryptoHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.cryptoHolding.cryptoHoldings;

export const getCryptoHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getCryptoHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (cryptoHoldingState, holdingId): CryptoHoldingState | undefined => {
    return cryptoHoldingsSelectors.selectElementByIdx(
      cryptoHoldingState,
      holdingId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getCryptoHoldings = createSelector(
  (state: WorkspaceDataState) => getCryptoHoldingState(state),
  (cryptoHoldings) => {
    return cryptoHoldingsSelectors.selectAllElements(cryptoHoldings);
  },
);

export const getCryptoHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getCryptoHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (cryptoHoldings, id): CryptoHoldingState | undefined => {
    return cryptoHoldingsSelectors.selectElementByI(cryptoHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getCryptoHoldingsByCryptoId = createCachedSelector(
  (state: WorkspaceDataState) => getCryptoHoldings(state),
  (_state: WorkspaceDataState, cryptoId: string) => cryptoId,
  (cryptoHoldings, cryptoId): CryptoHoldingState[] => {
    return cryptoHoldings.filter((h) => h.cryptoId === cryptoId);
  },
)({
  keySelector: (_state, cryptoId) => `${cryptoId}`,
  selectorCreator: createSelector,
});
