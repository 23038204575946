import { workspaceProjectRootPath } from '../../paths';

export const holdingsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/holdings`;
};

export const holdingBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) => {
  return `${holdingsBasePath(tenantId, workspaceId, projectId)}/${holdingId}`;
};

export const holdingTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  tab: string,
) => {
  return `${holdingBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/${tab}`;
};
