import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';

export const useIrrMenu = (
  element: DashboardElementState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
    ];
  }, [element, updated]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
