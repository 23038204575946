import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectTenantData,
  selectUserData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
// import history from '~src/navigation/history';
import { userBasePath, userSignupPath } from '~src/navigation/paths/user/paths';
// import { myBasePath } from '~src/navigation/paths/home/paths';
import { workspaceRootPath } from '~src/navigation/paths/workspace/paths';
import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';

import NavigationIcon from '@mui/icons-material/Navigation';
import { Container, Fab, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { getTenantById } from '~src/data/store/selectors/tenant/tenants/selectors';
import { tenantRootPath } from '~src/navigation/paths/tenant/paths';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: '12em',
    paddingBottom: '3em',
    // background: 'black',
    background: theme.palette.grey[900],
    textAlign: 'center',
    minHeight: '30em',
    // maxHeight: 200,
  },

  header: {
    color: theme.palette.common.white,
  },

  subHeader: {
    marginTop: '1em',
    color: theme.palette.common.white,
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const HeaderComponent = React.memo(() => {
  const { classes } = useStyles();
  const account = useSelector(
    (state: RootState) => selectUserData(state).account,
  );
  const tenant = useSelector((state: RootState) =>
    getTenantById(
      selectTenantData(state),
      selectTenantData(state).tenant.selectedTenantId,
    ),
  );
  const workspace = useSelector((state: RootState) =>
    getWorkspaceById(selectWorkspaceData(state), {
      id: selectWorkspaceData(state).workspace.selectedWorkspaceId,
      tenantId: selectTenantData(state).tenant.selectedTenantId,
    }),
  );
  const selectedProject = useSelector(
    (state: RootState) =>
      selectWorkspaceData(state).workspace.project.selectedProject,
  );
  const project = useSelector((state: RootState) =>
    getProjectById(selectWorkspaceData(state), {
      id: selectedProject ? selectedProject : '',
      workspaceId: workspace ? workspace.id : '',
    }),
  );
  const navigate = useNavigate();
  const loggedIn = account && account.user !== undefined;
  let quickActionElement;
  if (!loggedIn) {
    const handleGoTo = () => {
      navigate(userSignupPath);
    };
    quickActionElement = (
      <Fab
        variant="extended"
        color="primary"
        sx={{ mt: (theme) => theme.spacing(3) }}
        onClick={handleGoTo}
      >
        <NavigationIcon className={classes.extendedIcon} />
        Get started
      </Fab>
    );
    // <Link to="/signup">
    //   <Button primary={true} size="huge">
    //     Get Started
    //     <Icon name="arrow right" />
    //   </Button>
    // </Link>);
  } else if (workspace && project) {
    const handleGoTo = () => {
      navigate(
        workspaceProjectRootPath(workspace.tenantId, workspace.id, project.id),
      );
    };
    quickActionElement = (
      <Fab
        variant="extended"
        color="primary"
        sx={{ mt: (theme) => theme.spacing(3) }}
        onClick={handleGoTo}
      >
        <NavigationIcon className={classes.extendedIcon} />
        Go to {project.name} project
      </Fab>
    );
  } else if (workspace) {
    const handleGoTo = () => {
      navigate(workspaceRootPath(workspace.tenantId, workspace.id));
    };

    quickActionElement = (
      <Fab
        variant="extended"
        color="primary"
        sx={{ mt: (theme) => theme.spacing(3) }}
        onClick={handleGoTo}
      >
        <NavigationIcon className={classes.extendedIcon} />
        Go to {workspace.name} workspace
      </Fab>
    );
  } else if (tenant) {
    const handleGoTo = () => {
      navigate(tenantRootPath(tenant.id));
    };

    quickActionElement = (
      <Fab
        variant="extended"
        color="primary"
        sx={{ mt: (theme) => theme.spacing(3) }}
        onClick={handleGoTo}
      >
        <NavigationIcon className={classes.extendedIcon} />
        Go to {tenant.name} tenant
      </Fab>
    );
  } else {
    const handleGoTo = () => {
      navigate(userBasePath);
    };

    quickActionElement = (
      <Fab
        variant="extended"
        color="primary"
        sx={{ mt: (theme) => theme.spacing(3) }}
        onClick={handleGoTo}
      >
        <NavigationIcon className={classes.extendedIcon} />
        Setup workspace
      </Fab>
    );
  }

  return (
    <Container maxWidth={false} className={classes.root}>
      <Typography variant="h2" align="center" className={classes.header}>
        Portfolio investing
      </Typography>
      <Typography variant="h5" align="center" className={classes.subHeader}>
        Enables professional portfolio management.
      </Typography>
      {quickActionElement}
    </Container>
  );
});
