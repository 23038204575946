import { transferPath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchTransfers,
  handleDeleteTransfers,
} from '~src/data/store/modules/holdings/transfers/transfers/requests';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToTransfers(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  transfers: TransferState[],
  subscriptions: Operation[],
) {
  subscriptions.forEach((operation) => {
    if (operation === Operation.create) {
      apis.invest.subscribe(
        {
          path: transferPath(tenantId, workspaceId).domainPath(operation),
          handle: (res: { id: string }) => {
            dispatch(fetchTransfers(tenantId, workspaceId, [res.id]));
          },
        },
        () => {
          // console.log('subscribed to transfer', operation, transfer.id);
        },
      );
    }
    transfers.forEach((transfer) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: transferPath(tenantId, workspaceId).objectPath(
              transfer.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchTransfers(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to transfer', operation, transfer.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: transferPath(tenantId, workspaceId).objectPath(
              transfer.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteTransfers([
                  { id: res.id, holdingId: transfer.holdingId },
                ]),
              );
            },
          },
          () => {
            // console.log('subscribed to transfer', operation, transfer.id);
          },
        );
      }
    });
  });
}
