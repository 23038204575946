import { addressesSelectors } from '~src/data/store/reducers/person/addresses/reducer';
import { emailsSelectors } from '~src/data/store/reducers/person/emails/reducer';
import { peopleSelectors } from '~src/data/store/reducers/person/people/reducer';
import { phonesSelectors } from '~src/data/store/reducers/person/phones/reducer';
import { rolesSelectors } from '~src/data/store/reducers/person/roles/reducer';
import { ExportWorkspaceStore } from '~src/domain/workspace/components/data/workspace/export-workspace-store';
import { ExportHandler } from '~src/services/xlsx/handlers/ioutput-handler';
import { addressExportHandler } from '~src/services/xlsx/handlers/workspaces/people/addresses/mapper';
import { emailExportHandler } from '~src/services/xlsx/handlers/workspaces/people/emails/mapper';
import { personExportHandler } from '~src/services/xlsx/handlers/workspaces/people/mapper';
import { phoneExportHandler } from '~src/services/xlsx/handlers/workspaces/people/phones/mapper';
import { roleExportHandler } from '~src/services/xlsx/handlers/workspaces/people/roles/mapper';

export const exportHandlers = (store: ExportWorkspaceStore) => {
  const state = store.getState();
  const handlers: ExportHandler<any>[] = [];

  const people = peopleSelectors.selectAllElements(state.person.people);
  handlers.push(personExportHandler(people));

  const addresses = addressesSelectors.selectAllElements(
    state.person.addresses,
  );
  handlers.push(addressExportHandler(addresses));

  const emails = emailsSelectors.selectAllElements(state.person.emails);
  handlers.push(emailExportHandler(emails));

  const phones = phonesSelectors.selectAllElements(state.person.phones);
  handlers.push(phoneExportHandler(phones));

  const roles = rolesSelectors.selectAllElements(state.person.roles);
  handlers.push(roleExportHandler(roles));

  return handlers;
};
