import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import { ProjectReport } from '~src/services/graphql/workspace/client/graphql';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';

export interface BaseProjectReportId {
  projectId: string;
  id: string;
}

export type ProjectReportState = Omit<
  ProjectReport,
  'project' | 'elements' | 'layouts' | 'compactType'
> & {
  projectId: string;
  elementIds: string[];
  layouts: ReactGridLayout.Layouts | undefined;
  compactType: CompactType;
};

export const initialProjectReportState = [];

const selectKeyId = (projectReport: BaseProjectReportId) =>
  projectReport.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseProjectReportId, b: BaseProjectReportId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseProjectReportId,
  ProjectReportState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const projectReportsSlice = createBaseRecordSlice(
  'projectReports',
  adapter,
);
export const selectors = adapter.selectors;

export const projectReportsReducer = projectReportsSlice.reducer;
export const projectReportsActions = projectReportsSlice.actions;
