import moment from 'moment';
import React from 'react';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useNordnetTransactionFields } from '~src/domain/workspace/components/data/adapters/nordnet/form/use-fields.component';
import { useStyles as useTransactionStyles } from '~src/domain/workspace/components/project/transaction/form/transaction.styles';
import { FieldConfig } from '~src/hooks/fields/common/field-config';
import {
  needsBankNoteHolding,
  needsHolding,
  needsPaper,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/get-transaction-type';
import {
  nordnetTransactionsActions,
  NordnetTransactionState,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import { AppDispatch } from '~src/store/store';

import { DatePicker } from '@mui/x-date-pickers';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface NordnetTransactionFieldConfig {
  paper: FieldConfig;
  holding: FieldConfig;
}

export type FormValues = NordnetTransactionState;

export interface FormContext {}

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  handleClose: () => void;
  initialFormValues: FormValues;
  handleSuccess: (nordnetTransaction: NordnetTransactionState) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  divContainer: {
    maxWidth: theme.breakpoints.values['lg'],
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'none',
    },
  },
}));

export const NordnetEditTransactionFormComponent = React.memo(
  (props: Props) => {
    const {
      initialFormValues,
      handleClose,
      handleSuccess,
      workspace,
      projectId,
    } = props;
    const { classes, cx } = useStyles();
    const transactionStyles = useTransactionStyles();
    const [fieldConfig] = React.useState<NordnetTransactionFieldConfig>(() => {
      const holdingVisible = needsHolding(initialFormValues);
      const paperVisible = needsPaper(initialFormValues);
      return {
        paper: {
          visible: holdingVisible,
        },
        holding: {
          visible: paperVisible,
        },
      };
    });

    const dispatch = useDispatch<AppDispatch>();

    const context: FormContext = {};

    const { control, handleSubmit } = useForm<FormValues, FormContext>({
      context,
      defaultValues: initialFormValues,
    });

    const onHandleUpdateTransaction: SubmitHandler<FormValues> =
      React.useCallback(
        (formValues) => {
          const ret = dispatch(
            nordnetTransactionsActions.upsertOneElement(formValues),
          );
          handleSuccess(ret.payload);
        },
        [dispatch, handleSuccess],
      );

    const onHandleError: SubmitErrorHandler<FormValues> = React.useCallback(
      (errors) => {
        console.log(JSON.stringify(errors));
      },
      [],
    );

    const {
      valueElement,
      paperElement: paperElementField,
      holdingElement: holdingElementField,
      bankNoteHoldingElement,
    } = useNordnetTransactionFields();

    const generalElements = (
      <>
        <Grid item xs={6}>
          <Controller
            name="date"
            rules={{ required: true }}
            control={control}
            defaultValue={new Date()}
            render={({ field, fieldState }) => (
              <DatePicker
                showToolbar={false}
                inputFormat="DD/MM/YYYY"
                label="Date"
                value={field.value ?? null}
                onChange={(date: moment.Moment | null) =>
                  field.onChange(date?.toDate())
                }
                renderInput={(params) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    {...params}
                    error={!!fieldState.error}
                  />
                )}
              />
            )}
          />
        </Grid>
      </>
    );

    const bankNoteHolding = needsBankNoteHolding(initialFormValues) && (
      <Grid item xs={12}>
        <Controller
          name="bankNoteHoldingId"
          control={control}
          render={({ field, fieldState }) =>
            bankNoteHoldingElement(field, fieldState, workspace, projectId)
          }
        />
      </Grid>
    );

    const paperElement = fieldConfig.paper.visible && (
      <Grid item xs={12}>
        <Controller
          name="paper"
          rules={{ required: true }}
          control={control}
          render={({ field, fieldState }) =>
            paperElementField(field, fieldState)
          }
        />
      </Grid>
    );

    const holdingElement = fieldConfig.holding.visible && (
      <Grid item xs={12}>
        <Controller
          name="holdingId"
          control={control}
          render={({ field, fieldState }) =>
            holdingElementField(field, fieldState, workspace, projectId)
          }
        />
      </Grid>
    );

    return (
      <>
        <DialogTitle id="form-dialog-title">
          Edit Nordnet transaction
        </DialogTitle>
        <DialogContent>
          <form className={classes.container} noValidate autoComplete="off">
            <div
              className={cx(
                transactionStyles.classes.partElements,
                classes.divContainer,
              )}
            >
              <Grid container rowSpacing={1}>
                <Grid item container columnSpacing={1}>
                  {paperElement}
                  <Grid item xs={12}>
                    <Controller
                      name="value"
                      control={control}
                      render={({ field, fieldState }) =>
                        valueElement(field, fieldState)
                      }
                    />
                  </Grid>
                  {holdingElement}
                  {bankNoteHolding}
                  {generalElements}
                </Grid>
              </Grid>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onHandleUpdateTransaction, onHandleError)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </>
    );
  },
);
