import React from 'react';
import { FileState } from '~src/data/store/reducers/file/files/reducer';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, MenuItem } from '@mui/material';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

interface Props {
  file: FileState;
  deleteFile?: () => void;
}

export const useDeleteImage = (props: Props) => {
  const { file, deleteFile } = props;
  const deleteModal = useVisible();
  const deleteElement = React.useMemo(() => {
    if (deleteFile) {
      return (
        <MenuItem onClick={deleteModal.open}>
          Delete image
          <IconButton aria-label={`star ${file.name}`}>
            <DeleteIcon />
          </IconButton>
        </MenuItem>
      );
    }
  }, [deleteFile, deleteModal.open, file.name]);

  const confirmElement = React.useMemo(() => {
    if (deleteFile) {
      return (
        <ConfirmDialogComponent
          isOpen={deleteModal.isShowing}
          handler={() => {
            deleteFile();
            deleteModal.close();
            // handleClose();
          }}
          close={() => {
            deleteModal.close();
          }}
          objectString="image"
        />
      );
    }
  }, [deleteFile, deleteModal]);
  return {
    deleteElement,
    confirmElement,
  };
};
