// export function eventParser(e: React.ChangeEvent<HTMLInputElement>) {
export type TargetType = {
  name?: string | undefined;
  value: unknown;
  type?: string;
};

export type EventType = {
  target: TargetType;
};

export function eventParser(e: EventType) {
  // export function eventParser(e: React.ChangeEvent<EventType>) {
  // const valueAsDate = e.target.valueAsDate;
  // if (valueAsDate !== null) {
  if (e.target.type === 'date') {
    // return valueAsDate;
    return new Date(e.target.value as string);
  } else if (e.target.type === 'number') {
    return parseFloat(e.target.value as string);
  } else {
    return e.target.value;
  }
}
