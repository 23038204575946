import {
  __OLDinsertSortedMultiple,
  __OLDmergeMultipleKeepRight,
  __OLDremoveSortedByIdentity,
  __OLDsortedFind,
} from '@pladdenico/common';

export interface BaseEntity {
  id?: number | string | undefined;
}

export interface BaseStateAction<T extends BaseEntity> {
  type: string;
  payload?: {
    single?: T;
    multiple?: T[];
    id?: number | string;
    ids?: Array<number | string>;
    updater?: (t: T) => T;
  };
}

type actionTypes = {
  single?: string;
  multiple?: string;
  singleDeleted?: string;
  multipleDeleted?: string;
  allDeleted?: string;
  updater?: string;
};
export function baseReducer<T extends BaseEntity>(
  types: actionTypes,
  initialState: T[],
  transforms: Array<(a: T) => number | string | undefined> = [(f) => f.id],
) {
  return (state: T[] = initialState, action: BaseStateAction<T>) => {
    switch (action.type) {
      case types.single: {
        if (
          action.payload &&
          action.payload.single &&
          action.payload.single.id !== undefined
        ) {
          const res = __OLDremoveSortedByIdentity(
            [...state],
            action.payload.single.id,
          );
          return __OLDinsertSortedMultiple<T, number | string | undefined>(
            res,
            action.payload.single,
            transforms,
          );
        }
        return state;
      }
      case types.multiple: {
        if (action.payload && action.payload.multiple) {
          const ret = __OLDmergeMultipleKeepRight<T>(
            state,
            action.payload.multiple,
            transforms,
          );
          return ret;
        }
        return state;
      }
      case types.singleDeleted: {
        if (action.payload && action.payload.id) {
          return __OLDremoveSortedByIdentity([...state], action.payload.id);
        }
        return state;
      }
      case types.multipleDeleted: {
        // TODO: can be optimized
        if (action.payload && action.payload.ids) {
          const ids = action.payload.ids;
          let ts = [...state];
          ids.forEach((id) => {
            ts = __OLDremoveSortedByIdentity(ts, id);
          });
          return ts;
        }
        return state;
      }
      case types.allDeleted: {
        return [];
      }
      case types.updater: {
        if (action.payload && action.payload.id && action.payload.updater) {
          console.log(action.type, 'updater');
          const t = __OLDsortedFind(
            state,
            { id: action.payload.id } as any,
            (t: T) => t.id,
          );
          if (t) {
            const res = __OLDremoveSortedByIdentity(
              [...state],
              action.payload.id,
            );
            return __OLDinsertSortedMultiple<T, number | string | undefined>(
              res,
              { ...action.payload.updater(t) },
              transforms,
            );
          }
        }
        return state;
      }
      default:
        return state;
    }
  };
}
