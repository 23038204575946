import React from 'react';
import { useDispatch } from 'react-redux';
import { updateEmails } from '~src/data/store/modules/people/emails/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { EmailState } from '~src/data/store/reducers/person/emails/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { EmailDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
  savedCallback: () => void;
  email: EmailState;
}

export const EditEmailDialogComponent = React.memo((props: Props) => {
  const [emailInput, setEmailInput] =
    React.useState<graphqlWorkspaceTypes.UpdateEmailInputType>({
      id: props.email.id,
      personId: props.email.personId,
      value: props.email.value,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveEmail = (): Promise<EmailState> => {
    return dispatch(
      updateEmails(props.tenantId, props.workspace.id, props.person.id, [
        emailInput,
      ]),
    )
      .then((emails) => {
        return emails[0];
      })
      .catch((err) => {
        console.info('FAILED', emailInput, JSON.stringify(err));
        throw err;
      });
  };

  return (
    <EmailDialogComponent
      title={'Edit email'}
      handleSaveEmail={handleSaveEmail}
      emailInput={emailInput}
      setEmailInput={setEmailInput}
      handleClose={props.handleClose}
      savedCallback={props.savedCallback}
      open={props.open}
    />
  );
});
