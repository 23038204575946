import * as React from 'react';

import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  configButton: {},
}));

export const useConfigButton = () => {
  const [open, setOpen] = React.useState(false);
  const { classes } = useStyles();

  const toggle = () => {
    setOpen(!open);
  };
  const button = (
    <Button
      sx={{
        visibility: open ? 'hidden' : 'visible',
        mr: 1,
        textDecoration: 'uppercase',
      }}
      variant="contained"
      color="primary"
      className={classes.configButton}
      onClick={toggle}
    >
      configuration
    </Button>
  );

  return {
    button,
    open,
    toggle,
  };
};
