import { sortBy } from 'lodash';
import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
// import { getStockPositionsByStockHoldingId } from '~src/store/modules/holdings/stock/position';
import { DataState } from '~src/data/store/reducers/reducers';
import { getPaperByStockId } from '~src/data/store/selectors/finance/papers/selectors';
import {
  ValueStock,
  ValuationType,
} from '~src/data/store/selectors/finance/valuation/valuation-types';
import { getStockPositionsByStockHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

import { intersectionSorted } from '@pladdenico/common';

import { getExchangeRateState } from '../../exchange-rates/selectors';
import { getQuotesByStockId } from '../../quotes/stock/stock-quotes';
import { compareDay } from '~src/utils/common/date';

interface Props {
  workspaceId: string;
  holdingId: string;
  stockHoldingId: string;
  stockId: string;
  currency: CurrencyState;
}

export const getStockHoldingValuations = createCachedSelector(
  (state: DataState, props: Props) =>
    getStockPositionsByStockHoldingId(
      selectWorkspaceDataFromData(state),
      props.stockHoldingId,
    ),
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.holdingId,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (state: DataState, props: Props) =>
    getQuotesByStockId(state, {
      stockId: props.stockId,
      workspaceId: props.workspaceId,
    }),
  (state: DataState, props: Props) =>
    getPaperByStockId(state, {
      stockId: props.stockId,
      workspaceId: props.workspaceId,
    }),
  (
    stockPositions,
    currency,
    holdingId,
    exchangeRateState,
    quotes,
    paper,
  ): ValueStock[] => {
    const sortedStockPositions = sortBy(stockPositions, [
      (a) => a.date.getTime(),
    ]);
    const stockPositionsWithLastQuote = [...sortedStockPositions];
    if (
      quotes != null &&
      quotes.length > 1 &&
      sortedStockPositions.length > 1
    ) {
      const lastStockPosition = sortedStockPositions.at(-1);
      const lastQuote = quotes.at(-1);
      console.log(
        'DATES',
        lastStockPosition?.date.getTime(),
        lastQuote?.date.getTime(),
      );
      if (
        lastStockPosition &&
        lastQuote &&
        compareDay(lastStockPosition.date, lastQuote.date) < 0
      ) {
        stockPositionsWithLastQuote.push({
          ...lastStockPosition,
          date: lastQuote.date,
        });
      }
    }
    const positions = quotes
      ? stockPositionsWithQuote(stockPositionsWithLastQuote, quotes)
      : [];
    return positions.map((position) => {
      const currencyId = paper ? paper.currencyId : currency.id;
      const exchangeRate = convert(
        exchangeRateState,
        currencyId,
        currency.id,
        position.quote.date,
      );
      return {
        type: ValuationType.stock,
        currencyId: currency.id,
        holdingId,
        id: position.position.id,
        date: position.position.date,
        marketValue:
          exchangeRate *
          ((position.position.shares ?? 0) * (position.quote.close ?? 0)),
        position: position.position,
        quote: position.quote,
      };
    });
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holdingId}:${props.stockHoldingId}:${props.stockId}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});

export function stockPositionsWithQuote(
  sortedStockPositions: StockPositionState[],
  quotes: QuoteState[],
) {
  return intersectionSorted(
    quotes,
    sortedStockPositions,
    [
      (quote, position) => {
        if (position.date.getTime() <= quote.date.getTime()) {
          return 0;
        } else {
          return -1;
        }
      },
    ],
    (quote, position) => {
      return { position, quote };
    },
  );
}
