import { combineReducers } from 'redux';
import {
  initialTransactionsState,
  transactionsReducer,
} from '~src/data/store/reducers/holding/transaction/transactions/reducer';

export const transactionInitialState = {
  transactions: initialTransactionsState,
};

export const transactionReducer = combineReducers({
  transactions: transactionsReducer,
});
