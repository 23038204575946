import * as React from 'react';
import { useSelector } from 'react-redux';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { FundHoldingState } from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getFundById } from '~src/data/store/selectors/asset/asset-types/funds/selectors';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { selectFavoriteHoldingByHoldingId } from '~src/data/store/selectors/holding/favorite-holding/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import history from '~src/navigation/history';

import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Chip, Divider, IconButton, Link, Typography } from '@mui/material';

import { FavoriteHoldingComponent } from '../../favorite-holdings/favorite-holding.component';
import { workspaceAssetRootPath } from '~src/navigation/paths/workspace/asset/paths';
import { getPaperByFund } from '~src/data/store/selectors/finance/quotes/selectors';
import { paperRootPath } from '~src/navigation/paths/workspace/finance/paper/paths';
import { Box } from '@mui/system';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { portfolioBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/portfolio/paths';
import { useNavigate } from 'react-router-dom';

interface Props {
  workspace: WorkspaceState;
  fundHolding: FundHoldingState;
  holding: HoldingState;
  portfolios: PortfolioState[];
  asset?: AssetState;
  projectId: string;
  startEditing: () => void;
}

export const ViewComponent = React.memo((props: Props) => {
  const {
    workspace,
    holding,
    fundHolding,
    portfolios,
    projectId,
    asset,
    startEditing,
  } = props;
  const favorited = useSelector((state: RootState) =>
    selectFavoriteHoldingByHoldingId(selectWorkspaceData(state), {
      projectId: projectId,
      holdingId: holding.id,
    }),
  );
  const fund = useSelector((state: RootState) =>
    getFundById(selectWorkspaceData(state), {
      id: fundHolding.fundId,
      workspaceId: workspace.id,
    }),
  );
  const paper = useSelector((state: RootState) => {
    return getPaperByFund(selectFinancialData(state), fund);
  });
  const entity = useSelector((state: RootState) =>
    getEntityById(selectWorkspaceData(state), {
      id: asset?.entityId,
      workspaceId: workspace.id,
    }),
  );

  const navigate = useNavigate();
  const handleClickPortfolio = (
    workspace: WorkspaceState,
    projectId: string,
    portfolio: PortfolioState,
  ) => {
    navigate(
      portfolioBasePath(
        workspace.tenantId,
        workspace.id,
        projectId,
        portfolio.id,
      ),
    );
  };

  const renderFavoriteHoldingButton = (
    handleToggleFavoriteHolding: () => void,
  ) => {
    const icon = favorited ? <FavoriteIcon /> : <FavoriteBorderIcon />;
    return (
      <IconButton aria-label="favorite" onClick={handleToggleFavoriteHolding}>
        {icon}
      </IconButton>
    );
  };

  const iholding = holding;
  let typesElement;
  if (asset && asset.types) {
    typesElement = asset.types.map((type: string) => {
      return (
        <Typography variant="body1" key={type}>
          {type}
        </Typography>
      );
    });
  }
  let assetElement;
  if (asset) {
    assetElement = (
      <>
        <Link
          variant="h6"
          onClick={() =>
            navigate(
              workspaceAssetRootPath(
                workspace.tenantId,
                workspace.id,
                asset.id,
              ),
            )
          }
        >
          {asset.name}
        </Link>
        <Typography variant="body1">{asset.description}</Typography>
      </>
    );
  }
  const editActionElement = (
    <IconButton aria-label="edit" onClick={() => startEditing()}>
      <EditIcon />
    </IconButton>
  );

  const paperElement = paper ? (
    <Link
      component="button"
      variant="body2"
      onClick={() =>
        navigate(paperRootPath(workspace.tenantId, workspace.id, paper.id))
      }
    >
      {paper ? `${paper.exchange}.${paper.symbol}` : ''}
    </Link>
  ) : undefined;

  const portfoliosElement = portfolios.map((portfolio) => {
    return (
      <Chip
        label={portfolio.name}
        key={portfolio.id}
        variant="outlined"
        sx={{ mr: 1 }}
        onClick={() => handleClickPortfolio(workspace, projectId, portfolio)}
      />
    );
  });
  const holdingElement = (
    <>
      <Typography variant="body1">{iholding.description}</Typography>
      <Box sx={{ mt: 1 }}>{portfoliosElement}</Box>
    </>
  );

  return (
    <>
      <Box sx={{ my: 1, mx: 2, mb: 2 }}>
        <Typography variant="h4">
          {iholding.name}
          <FavoriteHoldingComponent
            tenantId={workspace.tenantId}
            workspaceId={workspace.id}
            projectId={projectId}
            render={renderFavoriteHoldingButton}
            holding={holding}
            favorited={favorited}
          />
          {editActionElement}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {entity?.description}
        </Typography>
      </Box>
      <Divider variant="fullWidth" sx={{ mx: 2 }} />
      <Box sx={{ my: 2, mx: 2 }}>
        {assetElement}
        {typesElement}
        <Typography variant="body1">Paper {paperElement}</Typography>
      </Box>
      <Divider variant="fullWidth" sx={{ mx: 2 }} />
      <Box sx={{ mt: 2, mx: 2 }}>{holdingElement}</Box>
    </>
  );
});
