import * as uuid from 'uuid';
// import { HoldingConfigListener } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-input-data';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
import { getHoldingTypes } from '~src/utils/finance/transfer-to-holding-types';

import { TransferType } from '@pladdenico/models';

export const getHoldingConfigSell = (
  holdingId?: string,
  // listeners?: HoldingConfigListener[]
): HoldingConfig => {
  return {
    id: uuid.v1(),
    transferType: TransferType.Sell,
    component: {
      name: 'Sold holding',
      deleteIcon: false,
      useValuation: false,
      // editAll: false,
      // fieldConfig: {
      //   data: {
      //     stock: { position: { disabled: true, visible: true } },
      //   },
      //   transfer: {
      //     cost: {
      //       visible: true,
      //       disabled: false,
      //     },
      //     currency: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     date: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     value: {
      //       visible: true,
      //       disabled: true,
      //     },
      //   },
      //   valuation: {
      //     currency: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     date: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     value: {
      //       visible: true,
      //       disabled: true,
      //     },
      //   },
      // },
      possibleHoldingTypes: getHoldingTypes(TransferType.Sell),
      possibleTransferTypes: [TransferType.Sell],
    },
    holdingId,
    // listeners,
  };
};
