import { useDispatch } from 'react-redux';
import {
  deleteAllocations,
  updateAllocations,
} from '~src/data/store/modules/portfolios/allocations/requests';
import { AllocationData } from '~src/domain/workspace/components/project/portfolio/allocations/portfolio-allocations.component';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export function useAllocationActions(
  workspace: WorkspaceState,
  portfolioId: string,
) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (oldData: AllocationData, newData: AllocationData) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.CreateAllocationInputType = {
          id: newData.allocation.id,
          portfolioId,
          regionId: newData.allocation.regionId,
          currencyId: newData.allocation.currencyId,
          min: newData.allocation.min,
          max: newData.allocation.max,
          assetType: newData.allocation.assetType ?? '',
          assetClass: newData.allocation.assetClass ?? '',
          category: newData.allocation.category ?? '',
        };
        dispatch(updateAllocations(workspace.tenantId, workspace.id, [input]))
          .then((_allocations) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (oldData: AllocationData) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        deleteAllocations(workspace.tenantId, workspace.id, portfolioId, [
          oldData.allocation.id,
        ]),
      )
        .then((_numberOfDeletes) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onRowUpdate, onRowDelete };
}
