import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createAddresses } from '~src/data/store/modules/people/addresses/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { AddressState } from '~src/data/store/reducers/person/addresses/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { AddressDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
}

export const CreateAddressDialogComponent = React.memo((props: Props) => {
  const [addressInput, setAddressInput] =
    React.useState<graphqlWorkspaceTypes.CreateAddressInputType>({
      // id: uuid.v1(),
      id: '',
      personId: props.person.id,
      administrativeAreaLevel1: '',
      administrativeAreaLevel2: '',
      locality: '',
      postalCode: '',
      streetAddress: '',
      streetNumber: '',
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveAddress = (): Promise<AddressState> => {
    const address = {
      ...addressInput,
      id: uuid.v1(),
    };
    return dispatch(
      createAddresses(props.tenantId, props.workspace.id, [address]),
    )
      .then((addresses) => {
        return addresses[0];
      })
      .catch((err) => {
        console.info('FAILED', addressInput, JSON.stringify(err));
        throw err;
      });
  };

  const savedCallback = () => {
    return;
  };

  return (
    <AddressDialogComponent
      title={'Create address'}
      handleSaveAddress={handleSaveAddress}
      addressInput={addressInput}
      setAddressInput={setAddressInput}
      handleClose={props.handleClose}
      savedCallback={savedCallback}
      open={props.open}
    />
  );
});
