import { stockGoalRulePath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchStockGoalRules,
  handleDeleteStockGoalRules,
} from '~src/data/store/modules/holdings/stock/goal-rules/requests';
import { StockGoalRuleState } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToStockGoalRules(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  goalRules: StockGoalRuleState[],
  subscriptions: Operation[],
) {
  goalRules.forEach((goalRule) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: stockGoalRulePath(tenantId, workspaceId).objectPath(
              goalRule.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchStockGoalRules(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to goalRule', operation, goalRule.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: stockGoalRulePath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchStockGoalRules(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to goalRule', operation, goalRule.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: stockGoalRulePath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteStockGoalRules(goalRule.stockHoldingId, [res.id]),
              );
            },
          },
          () => {
            // console.log('subscribed to goalRule', operation, goalRule.id);
          },
        );
      }
    });
  });
}
