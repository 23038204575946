import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { FormValues } from '~src/domain/workspace/components/project/transaction/form/create-form.component';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {},
}));

export const useTransactionFields = () => {
  const { classes } = useStyles();

  const titleElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'title'>,
      _fieldState: ControllerFieldState,
    ) => (
      <TextField
        margin="normal"
        className={classes.textField}
        fullWidth
        // required
        id="title"
        label="Title"
        type="text"
        onChange={field.onChange}
        value={field.value ?? ''}
        autoFocus
        // error={!!fieldState.error}
        // helperText={fieldState.error?.type ? 'Title is required' : undefined}
      />
    ),
    [classes.textField],
  );
  const descriptionElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'description'>,
      _fieldState: ControllerFieldState,
    ) => (
      <TextField
        multiline
        margin="normal"
        className={classes.textField}
        fullWidth
        // required
        id="description"
        // value={transactionInput.description ?? ''}
        value={field.value ?? ''}
        label="Description"
        type="text"
        // multiline
        onChange={field.onChange}
        // onChange={handleEvent('description')}
        // error={!!fieldState.error}
        // helperText={
        //   fieldState.error?.type ? 'Description is required' : undefined
        // }
      />
    ),
    [classes.textField],
  );

  return {
    // idElement,
    titleElement,
    descriptionElement,
  };
};
