import React from 'react';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ExpressionTypes } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/expression-type';
import { createStockPriceReturnExpression } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/types/stock-price-return';
import { createStockReturnValuationExpression } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/types/stock-return-valuation';
import { useExpressionType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/use-expression-type';
import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';
import { useHandler } from '~src/hooks/utils/use-handler';
import { eventParser } from '~src/utils/form/event-parser';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
  },
}));

export const useScenarioHoldingForecastExpressionElementFieldsComponent = (
  input: ExpressionElementState,
  setInput: (element: ExpressionElementState) => void,
) => {
  const { classes } = useStyles();

  const { handleUpdate } = useHandler(input, setInput);

  const { type: expressionType, element: expressionTypeElement } =
    useExpressionType(input.expressionType);
  React.useEffect(() => {
    if (input.expressionType !== expressionType) {
      let expressions: Expression[] = [];
      if (expressionType === ExpressionTypes.StockPrice) {
        expressions = createStockPriceReturnExpression();
      } else if (expressionType === ExpressionTypes.StockTransfer) {
        expressions = createStockReturnValuationExpression();
      } else if (expressionType === ExpressionTypes.StockValuation) {
        expressions = createStockReturnValuationExpression();
      }
      setInput({
        ...input,
        expressions,
        expressionType,
      });
    }
  });

  const expressionsElement = React.useMemo(() => {
    const elements = input.expressions.map((expression, idx) => {
      return (
        <Grid container key={idx} alignItems="center">
          <Grid item xs={8}>
            <TextField
              multiline
              fullWidth
              // rows={2}
              margin="normal"
              className={classes.textField}
              required
              // id="expression"
              value={expression.value}
              label="Expression"
              type="text"
              onChange={(event) => {
                const expressions = [
                  ...input.expressions.slice(0, idx),
                  { ...expression, value: eventParser(event) },
                  ...input.expressions.slice(idx + 1),
                ];
                handleUpdate('expressions')(expressions);
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="In result"
              control={
                <Checkbox
                  checked={expression.inResult}
                  onChange={(_event, checked) => {
                    const expressions = [
                      ...input.expressions.slice(0, idx),
                      { ...expression, inResult: checked },
                      ...input.expressions.slice(idx + 1),
                    ];
                    handleUpdate('expressions')(expressions);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      );
    });

    const addExpressionElement = (
      <Button
        onClick={() =>
          setInput({
            ...input,
            expressions: [...input.expressions, { inResult: false, value: '' }],
          })
        }
      >
        Add expression
      </Button>
    );
    return (
      <Box>
        {elements}
        {addExpressionElement}
      </Box>
    );
  }, [classes.textField, handleUpdate, input, setInput]);

  return {
    expressionsElement,
    expressionTypeElement,
  };
};
