import { BondPositionState } from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import { BondPosition } from '~src/services/graphql/workspace/client/graphql';

export function parseBondPosition(
  bondPosition: BondPosition,
): BondPositionState {
  return {
    id: bondPosition.id,
    bondHoldingId: bondPosition.bondHolding?.id ?? '',
    date: new Date(bondPosition.date),
    shares: bondPosition.shares ?? 0,
  };
}
