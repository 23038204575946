import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';

export interface BaseStockPositionId {
  stockHoldingId: string;
  id: string;
}

export type StockPositionState = {
  id: string;
  stockHoldingId: string;
  date: Date;
  shares: number;
};

export const initialStockPositionState = [];

const selectKeyId = (stockPosition: BaseStockPositionId) =>
  stockPosition.stockHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseStockPositionId, b: BaseStockPositionId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseStockPositionId,
  StockPositionState,
  string
>(initialStockPositionState, selectKeyId, keyComparator, tComparator);

export const stockPositionsSlice = createBaseRecordSlice(
  'stockPositions',
  adapter,
);
export const stockPositionsSelectors = adapter.selectors;

export const stockPositionsReducer = stockPositionsSlice.reducer;
export const stockPositionsActions = stockPositionsSlice.actions;
