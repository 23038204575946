import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { StockGoalRuleFormComponent } from './form.component';
import { StockGoalRuleState } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';

export type InputType =
  | graphqlWorkspaceTypes.CreateStockGoalRuleInputType
  | graphqlWorkspaceTypes.UpdateStockGoalRuleInputType;

interface Props extends React.PropsWithChildren<unknown> {
  title: string;
  workspace: WorkspaceState;
  handleClose: () => void;
  inputInit: InputType;
  saveStockGoalRules: (
    tenantId: string,
    workspaceId: string,
    inputs: graphqlWorkspaceTypes.CreateStockGoalRuleInputType[],
  ) => (dispatch: AppDispatch) => Promise<StockGoalRuleState[]>;
}

export const StockGoalRuleDialogContentComponent = React.memo(
  (props: Props) => {
    const [stockGoalRuleInput, setStockGoalRuleInput] =
      React.useState<InputType>({ ...props.inputInit });
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      setStockGoalRuleInput({ ...props.inputInit });
    }, [props.inputInit]);

    const handleGoalRuleSave = (): void => {
      dispatch(
        props.saveStockGoalRules(props.workspace.tenantId, props.workspace.id, [
          stockGoalRuleInput,
        ]),
      )
        .then((_stockGoalRules) => {
          props.handleClose();
        })
        .catch((err) => {
          console.info('FAILED', stockGoalRuleInput, JSON.stringify(err));
        });
    };

    return (
      <>
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <StockGoalRuleFormComponent
            stockGoalRuleInput={stockGoalRuleInput}
            setStockGoalRuleInput={setStockGoalRuleInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleGoalRuleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </>
    );
  },
);
