import React from 'react';
import * as uuid from 'uuid';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHolding } from '~src/domain/workspace/components/project/portfolio/holdings/use-holding';
import { useHoldingActions } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';
import { useInvestmentActions } from '~src/hooks/actions/workspace/project/private-equity/investment/use-investment-actions';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { CreateInvestmentFieldsComponent } from './create-fields.component';

// import { EditHoldingField } from '../../dashboard/components/allocation/allocation-filter-field';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  fund: PrivateEquityFundState;
  createInputInit: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType;
  handleClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateInvestmentFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { onCreate } = useInvestmentActions(props.workspace, props.fund.id);
  const { createHoldingWithData } = useHoldingActions(props.workspace);
  const [investmentInput, setInvestmentInput] =
    React.useState<graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType>(
      () => props.createInputInit,
    );

  const [holdingInput, setHoldingInput] =
    React.useState<graphqlWorkspaceTypes.CreateHoldingInputType>({
      id: uuid.v1(),
      projectId: props.projectId,
      name: '',
      description: '',
      type: HoldingType.Stock,
    });
  const { holdingDataInput, setHoldingDataInput } = useHolding(holdingInput);

  const handleOnCreate = React.useCallback(() => {
    createHoldingWithData(holdingInput, holdingDataInput, undefined).then(
      (holding) => {
        onCreate({
          ...investmentInput,
          holdingId: holding.id,
        }).then(() => props.handleClose());
      },
    );
  }, [
    createHoldingWithData,
    holdingDataInput,
    holdingInput,
    investmentInput,
    onCreate,
    props,
  ]);

  return (
    <>
      <DialogTitle id="form-dialog-title">Create investment</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <CreateInvestmentFieldsComponent
            workspace={props.workspace}
            tenantId={props.workspace.tenantId}
            projectId={props.projectId}
            investmentInput={investmentInput}
            setInvestmentInput={setInvestmentInput}
            holdingInput={holdingInput}
            setHoldingInput={setHoldingInput}
            holdingDataInput={holdingDataInput}
            setHoldingDataInput={setHoldingDataInput}
            showId={true}
            showConfirmed={true}
            showHolding={true}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOnCreate} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
});
