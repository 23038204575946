import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  fundsSelectors,
  BaseFundId,
} from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getFunds = (state: WorkspaceDataState) => {
  return state.holding.asset.fund.funds;
};

export const getFundsByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getFunds(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (fundsState, workspaceId) => {
    const funds = fundsSelectors.selectElementsByKey(fundsState, workspaceId);
    return funds ? funds : [];
  },
)({ keySelector: (_state, fundId) => fundId, selectorCreator: createSelector });

export const getFundById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseFundId>) => getFunds(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseFundId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseFundId>) => baseId.id,
  (funds, workspaceId, fundId) => {
    if (!workspaceId || !fundId) {
      return undefined;
    }
    return fundsSelectors.selectElementByT(funds, {
      workspaceId,
      id: fundId,
    });
  },
)({
  keySelector: (_state, workspaceId, fundId) => `${workspaceId}-${fundId}`,
  selectorCreator: createSelector,
});

interface FundAssetId {
  workspaceId: string;
  assetId: string;
}

export const getFundByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<FundAssetId>) => getFunds(state),
  (_state: WorkspaceDataState, baseId: Partial<FundAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<FundAssetId>) => baseId.assetId,
  (funds, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceFunds = fundsSelectors.selectElementsByKey(
      funds,
      workspaceId,
    );
    return workspaceFunds?.find((fund) => fund.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
