import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseContactId {
  id: string;
  investorId: string;
}

export type ContactState = graphqlWorkspaceTypes.PrivateEquityFundContact & {
  investorId: string;
};

export const initialState: RecordState<ContactState, string>[] = [];

const selectKeyId = (contact: BaseContactId) => contact.investorId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseContactId, b: BaseContactId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseContactId, ContactState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const contactsSlice = createBaseRecordSlice('contacts', adapter);
export const contactsSelectors = adapter.selectors;

export const contactsReducer = contactsSlice.reducer;
export const contactsActions = contactsSlice.actions;
