import React from 'react';
import { useDispatch } from 'react-redux';
import { useLogout } from '~src/data/store/modules/account';
import { userBasePath } from '~src/navigation/paths/user/paths';
import { AppDispatch } from '~src/store/store';

import {
  Divider,
  ListItemButton,
  ListItemText,
  // ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';
import { User } from '@pladdenico/models';
import { useNavigate } from 'react-router-dom';
import { useMenu } from '~src/hooks/utils/use-menu.hook';

export function useProfileMenu(
  user: User | undefined,
  menuId: string,
  closeCallback?: () => void,
) {
  const menu = useMenu();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const logout = useLogout();

  const handleMenuClose = React.useCallback(() => {
    menu.handleClose();
    closeCallback && closeCallback();
  }, [closeCallback, menu]);

  const handleLogout = React.useCallback(() => {
    logout(dispatch);
  }, [dispatch, logout]);

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      menu.handleOpen(event);
    },
    [menu],
  );

  const handleProfile = React.useCallback(() => {
    navigate(userBasePath);
    handleMenuClose();
  }, [handleMenuClose, navigate]);

  const profileMenu = React.useMemo(() => {
    console.log('user', user);

    return user ? (
      <Menu
        anchorEl={menu.anchorEl}
        // classes={classes.menu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={menu.isOpen}
        onClose={handleMenuClose}
      >
        <ListItemText primary={user.name} sx={{ ml: 2 }} />
        {/* <ListSubheader>{user.name}</ListSubheader> */}
        <Divider />
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleLogout}>Sign out</MenuItem> */}
        <ListItemButton onClick={handleLogout}>Sign out</ListItemButton>
      </Menu>
    ) : undefined;
  }, [
    handleLogout,
    handleMenuClose,
    handleProfile,
    menu.anchorEl,
    menu.isOpen,
    menuId,
    user,
  ]);

  return {
    profileMenu,
    handleOpen,
  };
}
