import React from 'react';
// import { SelectDownshiftField } from '~src/components/inputs/select-downshift-field';
import { useSelector } from 'react-redux';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  getCurrencies,
  getCurrencyById,
} from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FieldError } from 'react-hook-form';

interface Props {
  // workspaceId: string;
  handleValue: (currency: CurrencyState) => void;
  handlePersist?: () => void;
  // currency: Currency | undefined;
  currencyId: string | null;
  labelled: boolean;
  error?: FieldError;
}

export const EditCurrencyField = React.memo((props: Props) => {
  const currencies = useSelector((state: RootState) =>
    getCurrencies(selectFinancialData(state)),
  );
  const currency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), { id: props.currencyId }),
  );

  return (
    <Autocomplete
      options={currencies}
      autoSelect
      getOptionLabel={(currency: CurrencyState) => currency.symbol}
      style={{ minWidth: 100 }}
      value={currency ? currency : null}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: CurrencyState | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      onBlur={() => {
        props.handlePersist && props.handlePersist();
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <>
          <TextField
            {...params}
            label={props.labelled ? 'Currency' : undefined}
            fullWidth
            margin="normal"
            error={!!props.error}
            helperText={props.error?.type ? 'Currency is required' : undefined}
          />
        </>
      )}
    />
  );
});
