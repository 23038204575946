import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { SummaryComponent } from '~src/domain/workspace/components/project/holding/common/summary.component';
import {
  defaultBetaBackgroundColor,
  defaultBetaFontColor,
  defaultIrrBackgroundColor,
  defaultIrrFontColor,
  defaultTotalValueBackgroundColor,
  defaultTotalValueFontColor,
  defaultVolatilityBackgroundColor,
  defaultVolatilityFontColor,
  defaultCommitmentBackgroundColor,
  defaultCommitmentFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/summary/default-colors';
import { useSummaryMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/summary/menu.component';
import { Config } from '~src/utils/interfaces/config';
import { SummaryType, SummaryTypes } from '~src/utils/interfaces/summary-type';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  period: Period;
}

const getDefaultFontColor = (type: SummaryType) => {
  if (type === SummaryTypes.TotalValue) {
    return defaultTotalValueFontColor;
  } else if (type === SummaryTypes.Irr) {
    return defaultIrrFontColor;
  } else if (type === SummaryTypes.Volatility) {
    return defaultVolatilityFontColor;
  } else if (type === SummaryTypes.Beta) {
    return defaultBetaFontColor;
  } else if (type === SummaryTypes.TotalCommitments) {
    return defaultCommitmentFontColor;
  }
  return '#FFFFFF';
};

const getDefaultBackgroundColor = (type: SummaryType) => {
  if (type === SummaryTypes.TotalValue) {
    return defaultTotalValueBackgroundColor;
  } else if (type === SummaryTypes.Irr) {
    return defaultIrrBackgroundColor;
  } else if (type === SummaryTypes.Volatility) {
    return defaultVolatilityBackgroundColor;
  } else if (type === SummaryTypes.Beta) {
    return defaultBetaBackgroundColor;
  } else if (type === SummaryTypes.TotalCommitments) {
    return defaultCommitmentBackgroundColor;
  }
  return '#FFFFFF';
};

export const SummaryElement = React.memo(
  ({
    workspace,
    projectId,
    holdings,
    config,
    period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useSummaryMenu(
      workspace,
      projectId,
      holdings,
      element,
      remove,
      updated,
    );

    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), {
        id: baseCurrencyId,
      }),
    );

    const type = React.useMemo(
      () => (element.config.type as SummaryType) ?? SummaryTypes.TotalValue,
      [element.config.type],
    );

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? getDefaultFontColor(type),
          backgroundColor:
            element.config.backgroundColor ?? getDefaultBackgroundColor(type),
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
      type,
    ]);

    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }

    const filteredHoldings = React.useMemo(() => {
      const holdingIds = (element.config.data as string[]) ?? undefined;
      if (holdingIds != null && holdingIds.length !== 0) {
        return holdings.filter((h) => holdingIds.some((id) => h.id === id));
      }
      return holdings;
    }, [element.config.data, holdings]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <SummaryComponent
            workspaceId={workspace.id}
            projectId={projectId}
            holdings={filteredHoldings}
            date={config.date}
            period={period}
            baseCurrency={baseCurrency}
            indexPaper={config.indexPaper}
            useLiveValue={(element.config.useMarketValue as boolean) ?? true}
            type={
              (element.config.type as SummaryType) ?? SummaryTypes.TotalValue
            }
            useSubLabel={filteredHoldings.length !== holdings.length}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
