import React from 'react';
// import { useDispatch } from 'react-redux';
// import { updatePortfolioDashboardConfigs } from '~src/data/store/modules/portfolio-configs/portfolio-dashboard-configs/requests';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useComponents } from '~src/domain/workspace/components/common/dashboard/hooks/use-components.hook';
import { portfolioDashboardElements } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/hooks/portfolio-dashboard-elements';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
// import { usePortfolioDashboardConfig } from '~src/domain/workspace/components/project/portfolio/dashboard/hooks/use-portfolio-dashboard-config.hook';
// import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
// import { AppDispatch } from '~src/store';
// import { Position } from '~src/utils/common/position';
// import { Size } from '~src/utils/common/size';
import { Config } from '~src/utils/interfaces/config';

// import { DashboardComponentComponent } from '../dashboard-component.component';
import { DashboardElementComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/dashboard-element.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  dashboardElements: DashboardElementState[];
  holdings: HoldingState[];
  config: Config;
  layouts: ReactGridLayout.Layouts | undefined;
  updateLayouts:
    | ((
        layouts: ReactGridLayout.Layouts,
      ) => Promise<ReactGridLayout.Layouts | undefined>)
    | undefined;
  loading: boolean;
  // height: Maybe<number>;
  addDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.DashboardElement[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>;
  removeDashboardElements?: (
    dashboardElementIds: string[],
  ) => Promise<string[]>;
  updateDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>;
}
export const useDashboardComponents = (props: Props) => {
  const {
    holdings,
    loading,
    portfolio,
    portfolioProject,
    project,
    workspace,
    config,
    // height,
    layouts,
    updateLayouts,
    dashboardElements,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  } = props;

  // const inputInit = React.useCallback(() => {
  //   return {
  //     id: uuid.v1(),
  //     type: 'header',
  //     name: '',
  //     config: {},
  //     // x: 0,
  //     // y: 0,
  //     // w: 3,
  //     // h: height ? height / 3 : 100,
  //   };
  // }, []);

  const dashboardComponent = React.useCallback(
    (
      active: boolean,
      setActive: (id: string) => void,
      setInactive: (id: string) => void,
      remove: (id: string) => void,
      update: (
        id: string,
        element: Partial<DashboardElementState>,
      ) => Promise<{ id: string }>,
      // containerPos: Position | undefined,
      // containerSize: Size | undefined,
      element: DashboardElementState,
    ) => {
      return (
        <DashboardElementComponent
          workspace={workspace}
          project={project}
          portfolioProject={portfolioProject}
          portfolio={portfolio}
          holdings={holdings}
          key={element.id}
          id={element.id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          remove={remove}
          // containerPos={containerPos}
          // containerSize={containerSize}
          element={element}
          update={update}
          config={config}
        />
      );
    },
    [
      config,
      holdings,
      loading,
      portfolio,
      portfolioProject,
      project,
      workspace,
    ],
  );

  const { container, dialog, open } = useComponents(
    // height,
    // updateDashboardConfigHeight,
    config.compactType,
    config.usageMode,
    dashboardElements,
    dashboardComponent,
    layouts,
    updateLayouts,
    // inputInit,
    portfolioDashboardElements,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  );

  return {
    container,
    dialog,
    open,
  };
};
