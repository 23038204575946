import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import {
  workspaceFilesRootPath,
  workspaceFileRootPath,
} from '~src/navigation/paths/workspace/file/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useWorkspaceBreadcrumbs } from './workspace-breadcrumbs';
import { getFileById } from '~src/data/store/selectors/file/selectors';

export function useFilesBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();

  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);

  if (tenantId != null && workspaceId != null) {
    const fileElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={workspaceFilesRootPath(tenantId, workspaceId)}
      >
        files
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceFilesRootPath(tenantId, workspaceId)}
        to={workspaceFilesRootPath(tenantId, workspaceId)}
      >
        files
      </LinkRouter>
    );
    return [...workspaceBreadcrumb, fileElement];
  }
  return [];
}

export function useFileBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId, fileId } = useParams<{
    tenantId: string;
    workspaceId: string;
    fileId: string;
  }>();
  const filesBreadcrumbs = useFilesBreadcrumbs(false);
  const workspace = useSelector((state: RootState) => {
    if (workspaceId != null && tenantId != null)
      return getWorkspaceById(selectWorkspaceData(state), {
        id: workspaceId,
        tenantId,
      });
  });
  const file = useSelector((state: RootState) => {
    if (workspaceId != null && fileId != null) {
      return getFileById(selectWorkspaceData(state), {
        id: fileId,
      });
    }
  });
  if (workspace && file) {
    const fileElement = last ? (
      <Typography
        color="inherit"
        key={workspaceFileRootPath(workspace.tenantId, workspace.id, file.id)}
      >
        {file.name}
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceFileRootPath(workspace.tenantId, workspace.id, file.id)}
        to={workspaceFileRootPath(workspace.tenantId, workspace.id, file.id)}
      >
        {file.name}
      </LinkRouter>
    );
    return [...filesBreadcrumbs, fileElement];
  }
  return [];
}
export function FileBreadcrumbComponent() {
  const filesBreadcrumbs = useFileBreadcrumbs(true);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {filesBreadcrumbs}
    </Breadcrumbs>
  );
}
export function FilesBreadcrumbComponent(props: { last: boolean }) {
  const filesBreadcrumbs = useFilesBreadcrumbs(props.last);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {filesBreadcrumbs}
    </Breadcrumbs>
  );
}
