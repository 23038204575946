import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import { useTenantBreadcrumbs } from '~src/domain/tenant/components/top-bar/breadcrumb/tenant-breadcrumb';
import { workspaceRootPath } from '~src/navigation/paths/workspace/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';

export function useWorkspaceBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  const tenantBreadcrump = useTenantBreadcrumbs(false);
  const workspace = useSelector((state: RootState) => {
    if (workspaceId != null && tenantId != null)
      return getWorkspaceById(selectWorkspaceData(state), {
        id: workspaceId,
        tenantId,
      });
  });
  if (workspace) {
    const workspaceElement = last ? (
      <Typography
        color="inherit"
        key={workspaceRootPath(workspace.tenantId, workspace.id)}
      >
        {workspace.name}
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceRootPath(workspace.tenantId, workspace.id)}
        to={workspaceRootPath(workspace.tenantId, workspace.id)}
      >
        {workspace.name}
      </LinkRouter>
    );
    return [tenantBreadcrump, workspaceElement];
  }
  return [];
}
export function WorkspaceBreadcrumbComponent() {
  const workspaceCrumbs = useWorkspaceBreadcrumbs(true);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {workspaceCrumbs}
    </Breadcrumbs>
  );
}
