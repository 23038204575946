import { Unsubscribe } from 'redux';
import { loggedin } from '~src/data/store/modules/account/index';
import { authenticationService } from '~src/services/authentication/authentication-service';
import { AppStore } from '~src/store/store';

import { apis } from '../../../services/request/apis';
import { selectUserData } from '../selectors/selectors';

export class AuthenticationListener {
  private unsubscribeUserAuthenticated: Unsubscribe;

  constructor(private store: AppStore) {
    this.store = store;
    if (authenticationService.isUserAuthenticated()) {
      const token = authenticationService.getToken();
      const user = authenticationService.getUser();
      if (user && token) {
        // apis.public.connect(user, token);
        apis.invest.connect(user, token);
        apis.objStorage.connect(user, token);
        apis.finance.connect(user, token);
        apis.portfolioOptimization.connect(user, token);
        store.dispatch(loggedin(token, user));
      }
    }
    this.unsubscribeUserAuthenticated = store.subscribe(
      this.handleUserAuthenticated,
    );
  }

  public unsubscribe() {
    this.unsubscribeUserAuthenticated();
  }

  private handleUserAuthenticated = () => {
    const state = this.store.getState();
    const user = selectUserData(state).account.user;
    const token = selectUserData(state).account.token;
    const existingUser = authenticationService.getUser();
    const existingToken = authenticationService.getToken();
    if (!user && !existingUser) {
      return;
    }

    if (
      (user && !existingUser) ||
      (!user && existingUser) ||
      (user && existingUser && user.id !== existingUser.id) ||
      token !== existingToken
      // || (user && existingUser && !deepEqual(user.roles, existingUser.roles))
    ) {
      // compareRoles(user.roles, existingUser.roles))) { // ||
      // user.roles !== existingUser.roles)) {
      if (user && token) {
        authenticationService.authenticateUser(user, token);
        // apis.public.connect(user, token);
        apis.invest.connect(user, token);
        apis.objStorage.connect(user, token);
        apis.finance.connect(user, token);
        apis.portfolioOptimization.connect(user, token);
      } else {
        authenticationService.deauthenticateUser();
      }
    }
  };
}
