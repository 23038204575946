import { combineReducers } from 'redux';

// import {
//   dashboardElementsReducer,
//   initialDashboardElementState,
// } from '~src/data/store/reducers/common/dashboard-elements/reducer';
import {
  allocationsReducer,
  initialAllocationState,
} from './allocations/reducer';
import {
  initialPortfolioConfigState,
  portfolioConfigsReducer,
} from './portfolio-config/portfolio-configs/reducer';
import {
  initialPortfolioDashboardConfigState,
  portfolioDashboardConfigsReducer,
} from './portfolio-config/portfolio-dashboard-configs/reducer';
import { initialPortfolioState, portfoliosReducer } from './portfolios/reducer';
import {
  initialSelectedPortfolioState,
  selectedPortfoliosReducer,
} from './selected/reducer';

export const portfolioInitialState = {
  portfolios: initialPortfolioState,
  allocations: initialAllocationState,
  portfolioConfigs: initialPortfolioConfigState,
  portfolioDashboardConfigs: initialPortfolioDashboardConfigState,
  // dashboardElements: initialDashboardElementState,
  selectedPortfolioId: initialSelectedPortfolioState,
};

export const portfolioReducer = combineReducers({
  portfolios: portfoliosReducer,
  allocations: allocationsReducer,
  portfolioConfigs: portfolioConfigsReducer,
  portfolioDashboardConfigs: portfolioDashboardConfigsReducer,
  // dashboardElements: dashboardElementsReducer,
  selectedPortfolioId: selectedPortfoliosReducer,
});
