import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  bankNoteHoldingsSelectors,
  BankNoteHoldingState,
} from '../../../../../reducers/holding/holding-types/bank-note/bank-note-holding/reducer';

const getBankNoteHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.bankNoteHolding.bankNoteHoldings;

export const getBankNoteHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getBankNoteHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (bankNoteHoldingState, holdingId): BankNoteHoldingState | undefined => {
    return bankNoteHoldingsSelectors.selectElementByIdx(
      bankNoteHoldingState,
      holdingId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getBankNoteHoldings = createSelector(
  (state: WorkspaceDataState) => getBankNoteHoldingState(state),
  (bankNoteHoldings) => {
    return bankNoteHoldingsSelectors.selectAllElements(bankNoteHoldings);
  },
);

export const getBankNoteHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getBankNoteHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (bankNoteHoldings, id): BankNoteHoldingState | undefined => {
    return bankNoteHoldingsSelectors.selectElementByI(bankNoteHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getBankNoteHoldingsByBankNoteId = createCachedSelector(
  (state: WorkspaceDataState) => getBankNoteHoldings(state),
  (_state: WorkspaceDataState, bankNoteId: string) => bankNoteId,
  (bankNoteHoldings, bankNoteId): BankNoteHoldingState[] => {
    return bankNoteHoldings.filter((h) => h.bankNoteId === bankNoteId);
  },
)({
  keySelector: (_state, bankNoteId) => `${bankNoteId}`,
  selectorCreator: createSelector,
});
