export const ForecastTypes = {
  Expression: 'expression',
  Manual: 'manual',
  Flow: 'flow',
  // Expression: 'expression',
  // Manual: 'manual',
  // Manual: 'manual',
  // Manual: 'manual',
  // Manual: 'manual',
  // Manual: 'manual',
  // Manual: 'manual',
  // Manual: 'manual',
  // Manual: 'manual',
  // Import: 'import',
} as const;

export type ForecastType = (typeof ForecastTypes)[keyof typeof ForecastTypes];
export function typeToString(type: ForecastType) {
  if (type === ForecastTypes.Expression) {
    return 'Expression';
  } else if (type === ForecastTypes.Manual) {
    return 'Manual';
  } else if (type === ForecastTypes.Flow) {
    return 'Flow';
    // } else if (type === ForecastTypes.Import) {
    //   return 'Import';
  }
  return 'undefined';
}

// TODO: import from excel is in case of manual, either write values or import from excel
