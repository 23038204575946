import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ExportComponent } from '~src/domain/workspace/components/data/workspace/export.component';
import { ImportComponent } from '~src/domain/workspace/components/data/workspace/import.component';
import { importer as workspaceImporter } from '~src/services/xlsx/handlers/workspaces/importer';
import { Box, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  workspace: WorkspaceState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  gridItemRoot: {
    display: 'flex',
  },

  paperRoot: {
    flex: 1,
    padding: theme.spacing(3),
  },

  input: {
    display: 'none',
  },
}));

export const DataComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={2} alignItems="stretch" p={2}>
      <Grid item xs={6} classes={{ root: classes.gridItemRoot }}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Typography variant="overline">Import data</Typography>
          {/* <DataImportComponent workspace={props.workspace} /> */}
          <Box mt={2}>
            <ImportComponent
              workspace={props.workspace}
              dataImporter={workspaceImporter}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Typography variant="overline">Export data</Typography>
          <Box mt={2}>
            <ExportComponent workspace={props.workspace} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
});
