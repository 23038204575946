import { PortfolioProjectTabs } from '~src/domain/workspace/components/project/types/portfolio-project/tabs.component';

import { workspaceProjectRootPath } from '../paths';

export const portfolioProjectTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  tab: PortfolioProjectTabs,
) => {
  return `${workspaceProjectRootPath(tenantId, workspaceId, projectId)}/${tab}`;
};
