import { PrivateEquityFundProject } from '~src/services/graphql/workspace/client/graphql';

export function parsePrivateEquityFundProject(
  workspaceId: string,
  privateEquityFundProject: PrivateEquityFundProject,
) {
  return {
    id: privateEquityFundProject.id,
    workspaceId,
    projectId: privateEquityFundProject.project
      ? privateEquityFundProject.project.id
      : '',
    privateEquityFundId: privateEquityFundProject.privateEquityFund
      ? privateEquityFundProject.privateEquityFund.id
      : '',
  };
}
