// import {
//   quotesActions,
//   QuoteState,
// } from '~src/data/store/reducers/asset/asset-types/quotes/reducer';
import {
  BaseQuoteId,
  quotesActions,
} from '~src/data/store/reducers/finance/quote/reducer';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';
import { financeRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  Operation,
  QueryContextTypes,
  QueryFinanceContext,
} from '@pladdenico/portfolio-api';

import { handleQuotes } from './handler';

export function fetchQuotes(paperId: string, ids: string[] | null) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.GetQuotesDocument;
    const variables: graphqlFinanceTypes.GetQuotesQueryVariables = {
      paperId,
      ids,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.getQuotes) {
        return handleQuotes(paperId, dispatch, data.getQuotes, [
          Operation.create,
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function createQuotes(
  paperId: string,
  inputs: graphqlFinanceTypes.CreateQuoteInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.CreateQuotesDocument;
    const variables: graphqlFinanceTypes.CreateQuotesMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.createQuotes) {
        return handleQuotes(paperId, dispatch, data.createQuotes, [
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function updateQuotes(
  paperId: string,
  inputs: graphqlFinanceTypes.UpdateQuoteInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.UpdateQuotesDocument;
    const variables: graphqlFinanceTypes.UpdateQuotesMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.updateQuotes) {
        return handleQuotes(paperId, dispatch, data.updateQuotes, [
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function upsertQuotes(
  paperId: string,
  inputs: graphqlFinanceTypes.UpsertQuoteInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.UpsertQuotesDocument;
    const variables: graphqlFinanceTypes.UpsertQuotesMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.upsertQuotes) {
        return handleQuotes(paperId, dispatch, data.upsertQuotes, [
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function handleDeleteQuotes(quoteIds: BaseQuoteId[]) {
  return (dispatch: AppDispatch) => {
    return dispatch(quotesActions.removeManyElements(quoteIds));
  };
}

export function deleteQuotes(
  paperId: string,
  quotes: Array<{ id: string; date: Date }>,
) {
  return (dispatch: any) => {
    const node = graphqlFinanceTypes.DeleteQuotesDocument;
    quotes.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlFinanceTypes.DeleteQuoteInputType[] = quotes.map(
      (quote) => {
        return {
          id: quote.id,
        };
      },
    );
    const variables: graphqlFinanceTypes.DeleteQuotesMutationVariables = {
      inputs,
    };
    const context: QueryFinanceContext = {
      type: QueryContextTypes.finance,
    };
    return financeRequest(node, variables, context).then((data) => {
      if (data.deleteQuotes) {
        const deletedQuotes = intersectionSorted(
          quotes,
          data.deleteQuotes,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, paperId, date: a.date };
          },
        );
        return dispatch(handleDeleteQuotes(deletedQuotes));
        // return dispatch(quotesActions.removeManyElements(deletedQuotes));
      }
      return [];
    });
  };
}
