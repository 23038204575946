import React from 'react';
import { useSelector } from 'react-redux';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getExchangeRateState } from '~src/data/store/selectors/finance/exchange-rates/selectors';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { HoldingIrrComponent } from '~src/domain/workspace/components/project/holding/irr/holding-irr.component';
import { useIrrMenu } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/irr/menu.component';
import { Config } from '~src/utils/interfaces/config';
import { ElementProps } from '~src/components/dashboard/element';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

export const IrrElement = React.memo(
  ({
    holding,
    config,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useIrrMenu(element, remove, updated);

    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), {
        id: baseCurrencyId,
      }),
    );
    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }

    const valuations = useSelector((state: RootState) =>
      getValuationsByHoldingId(selectWorkspaceData(state), holding.id),
    );
    const transfers = useSelector((state: RootState) =>
      getTransfersByHoldingId(selectWorkspaceData(state), holding.id),
    );
    const exchangeRateState = useSelector((state: RootState) =>
      getExchangeRateState(selectFinancialData(state)),
    );

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? '#000000',
          backgroundColor: element.config.backgroundColor ?? '#ffffff',
          padding: element.config.padding,
        },
        hideOverflow: true,
        minHeight: 200,
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <HoldingIrrComponent
            baseCurrency={baseCurrency}
            exchangeRateState={exchangeRateState}
            date={config.date}
            holding={holding}
            valuations={valuations}
            transfers={transfers}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
