import { authenticationService } from '~src/services/authentication/authentication-service';

export class DataApi {
  public static get<T>(
    url: string,
    headers: any,
    successCallback: (t: T) => Promise<T>,
    failureCallback: (any: any) => Promise<never>,
    body?: string,
  ): Promise<any> {
    return this.run(
      url,
      headers,
      'GET',
      body,
      successCallback,
      failureCallback,
    );
  }

  public static post<T>(
    url: string,
    headers: any,
    body: string | undefined,
    successCallback: (t: T) => Promise<T>,
    failureCallback: (any: any) => Promise<never>,
  ): Promise<any> {
    return this.run(
      url,
      headers,
      'POST',
      body,
      successCallback,
      failureCallback,
    );
  }

  public static patch<T>(
    url: string,
    headers: any,
    body: string,
    successCallback: (t: T) => Promise<T>,
    failureCallback: (any: any) => Promise<never>,
  ): Promise<any> {
    return this.run(
      url,
      headers,
      'PATCH',
      body,
      successCallback,
      failureCallback,
    );
  }

  public static delete<T>(
    url: string,
    headers: any,
    successCallback: (t: T) => Promise<T>,
    failureCallback: (any: any) => Promise<never>,
  ): Promise<any> {
    return this.run(
      url,
      headers,
      'DELETE',
      undefined,
      successCallback,
      failureCallback,
    );
  }

  private static run<T>(
    url: string,
    headers: any,
    method: string,
    body: string | undefined,
    successCallback: (t: T) => Promise<T>,
    failureCallback: (any: any) => Promise<never>,
  ): Promise<any> {
    return fetch(url, {
      method,
      body,
      headers: new Headers(headers),
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((res) => {
            return successCallback(res);
          });
        } else {
          return res.json().then((res) => {
            return Promise.reject(res);
          });
        }
      })
      .catch((err) => {
        return failureCallback(err);
      });
  }

  public static baseHeaders(): any {
    return {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    };
  }

  public static authHeaders(): any {
    return {
      authorization: `Bearer ${authenticationService.getToken()}`,
    };
  }

  public static workspaceHeaders(workspaceId?: string): any {
    return {
      workspaceId,
    };
  }

  public static portfolioHeaders(portfolioId?: string): any {
    return {
      portfolioId,
    };
  }
}
