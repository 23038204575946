import { upsertBankNoteHoldings } from '~src/data/store/modules/holdings/bank-note/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'bankNoteHoldings';

export interface BankNoteHolding {
  id: string;
  holdingId: string;
  bankNoteId: string;
}

const header: Array<keyof BankNoteHolding> = ['id', 'holdingId', 'bankNoteId'];

export const bankNoteHoldingExportHandler = (
  data: BankNoteHolding[],
): ExportHandler<BankNoteHolding> => {
  const rows = data.map((bankNoteHolding) => {
    return {
      id: bankNoteHolding.id,
      holdingId: bankNoteHolding.holdingId,
      bankNoteId: bankNoteHolding.bankNoteId,
    };
  });
  return {
    type: name,
    data: outputPicker(rows, header),
    header,
  };
};

export const bankNoteHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<BankNoteHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertBankNoteHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
