import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';

import { strcmp } from '@pladdenico/common';

export interface BaseTenantId {
  id: string;
}

// interface Tenant {
//   id: string;
//   name: string;
//   description: string;
// }

export type TenantState = graphqlTenantTypes.Tenant;

export function tenantToTenantState(tenant: TenantState) {
  return tenant;
}
export const initialTenantsState = [];

const comparator = (a: BaseTenantId, b: BaseTenantId) => strcmp(a.id, b.id);
const merger = (_a: TenantState, b: TenantState) => b;

const adapter = new EntityArrayAdapter<BaseTenantId, TenantState>(
  initialTenantsState,
  comparator,
  merger,
);

export const tenantsSlice = createBaseArraySlice('tenants', adapter);
export const tenantsSelectors = adapter.selectors;

export const tenantsReducer = tenantsSlice.reducer;
export const tenantsActions = tenantsSlice.actions;
