import React from 'react';

import { TransferType } from '@pladdenico/models';

export const useCalculatePositionShares = (
  oldShares: number,
  tradeShares: number,
  transferType: TransferType,
) => {
  const newShares = React.useMemo(() => {
    let newSharesSign = 0;
    if (transferType === TransferType.Buy) {
      newSharesSign = 1;
    } else if (transferType === TransferType.Sell) {
      newSharesSign = -1;
    }
    const newShares = tradeShares
      ? newSharesSign * tradeShares + oldShares
      : oldShares;
    return newShares;
  }, [tradeShares, transferType, oldShares]);
  return newShares;
};
