export const getLastDate = <T>(
  array: T[],
  iteratee: (t: T) => Date | undefined,
) => {
  if (array.length > 0) {
    return array.reduce((prev, cur) => {
      const prevDate = iteratee(prev);
      const curDate = iteratee(cur);
      if (prevDate == null) {
        return cur;
      }
      if (curDate == null) {
        return prev;
      }
      if (prevDate.getTime() < curDate.getTime()) {
        return cur;
      }
      return prev;
    }, array[0]);
  }
};

export const getPreviousDate = <T>(
  array: T[],
  iteratee: (t: T) => Date | undefined,
  date: Date,
) => {
  if (array.length > 0) {
    return array.reduce((prev, cur) => {
      const prevDate = iteratee(prev);
      const curDate = iteratee(cur);
      if (prevDate == null) {
        return cur;
      }
      if (curDate == null) {
        return prev;
      }
      if (
        prevDate.getTime() < curDate.getTime() &&
        curDate.getTime() < date.getTime()
      ) {
        return cur;
      }
      return prev;
    }, array[0]);
  }
};
