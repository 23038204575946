import { Maybe } from 'graphql/jsutils/Maybe';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

// import { WorkspaceBase } from '~src/services/graphql/tenant/client/graphql';

export type WorkspaceBase = {
  id: string;
  name?: Maybe<string>;
  type?: Maybe<string>;
};

export function parseWorkspace(
  tenantId: string,
  workspace: WorkspaceBase,
): WorkspaceState {
  return {
    id: workspace.id,
    name: workspace.name,
    type: workspace.type,
    tenantId,
  };
}
