import { handleReports } from '~src/data/store/modules/workspaces/reports/report/handler';
import { reportsActions } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchReports(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null = null,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetReportsDocument;
    const variables: graphqlWorkspaceTypes.GetReportsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context)
      .then((data) => {
        if (data.getReports) {
          return handleReports(
            dispatch,
            tenantId,
            workspaceId,
            data.getReports,
            [],
          );
        }
        return undefined;
      })
      .catch((err) =>
        console.error(`Error getReports: ', ${JSON.stringify(err)}`),
      );
  };
}

export function createReports(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateReportInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateReportsDocument;
    const variables: graphqlWorkspaceTypes.CreateReportsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createReports) {
        return handleReports(
          dispatch,
          tenantId,
          workspaceId,
          data.createReports,
          [],
        );
      }
      throw new Error('Could not create report');
    });
  };
}

export function updateReports(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateReportInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateReportsDocument;
    const variables: graphqlWorkspaceTypes.UpdateReportsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateReports) {
        return handleReports(
          dispatch,
          tenantId,
          workspaceId,
          data.updateReports,
          [],
        );
      }
      return [];
    });
  };
}

export function upsertReports(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertReportInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertReportsDocument;
    const variables: graphqlWorkspaceTypes.UpsertReportsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertReports) {
        return handleReports(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertReports,
          [],
        );
      }
      return [];
    });
  };
}

export function handleDeleteReports(projectId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      reportsActions.removeManyElements(
        ids.map((p) => {
          return {
            projectId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteReports(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteReportsDocument;
    const variables: graphqlWorkspaceTypes.DeleteReportsMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteReports) {
        dispatch(handleDeleteReports(projectId, data.deleteReports));
      }
      return [];
    });
  };
}
