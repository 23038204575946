import React from 'react';
import { IdComponent } from '~src/components/utils/id.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHandler } from '~src/hooks/utils/use-handler';

import { Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  categoryInput: graphqlWorkspaceTypes.CreateAssetCategoryInputType;
  setCategoryInput: (
    categoryInput: graphqlWorkspaceTypes.CreateAssetCategoryInputType,
  ) => void;
  showId?: boolean;
}
const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  formControl: {
    minWidth: 100,
  },
}));

export const FieldsComponent = React.memo((props: Props) => {
  const { categoryInput, setCategoryInput, showId } = props;
  const { classes } = useStyles();

  const { handleEvent } = useHandler(categoryInput, setCategoryInput);

  const setId = (id: string) => {
    setCategoryInput({ ...categoryInput, id });
  };

  const idElement = showId ? (
    <Grid item sx={{ ml: 0, mr: 1 }}>
      <IdComponent id={categoryInput.id} setId={setId} />
    </Grid>
  ) : undefined;

  return (
    <>
      {idElement}
      <Grid item sx={{ ml: 0, mr: 1 }}>
        <TextField
          autoFocus
          margin="normal"
          className={classes.textField}
          required
          id="name"
          value={categoryInput.name}
          label="Name"
          type="text"
          onChange={handleEvent('name')}
        />
      </Grid>
    </>
  );
});
