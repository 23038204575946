import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { updateFiles } from '~src/data/store/modules/file/file/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FieldsComponent } from '~src/domain/workspace/components/file/file/fields.component';
import { AppDispatch } from '~src/store/store';

import { Grid, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  title: string;
  fileInput: graphqlWorkspaceTypes.UpdateFileInputType;
  setFileInput: (input: graphqlWorkspaceTypes.UpdateFileInputType) => void;
  handleUpdateFile: (file: FileState) => Promise<FileState>;
  handleClose: () => void;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
export const EditDialogComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const { fileInput, handleClose, workspace, setFileInput, handleUpdateFile } =
    props;

  const handleEditFile = React.useCallback(() => {
    return dispatch(updateFiles(workspace.tenantId, workspace.id, [fileInput]))
      .then((files) => {
        const file = files[0];
        handleClose();
        return handleUpdateFile(file);
      })
      .catch((err) => {
        console.info('FAILED', fileInput, JSON.stringify(err));
      });
  }, [
    dispatch,
    fileInput,
    handleClose,
    handleUpdateFile,
    workspace.id,
    workspace.tenantId,
  ]);

  const form = (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container justifyContent="space-between">
        <FieldsComponent
          workspace={props.workspace}
          fileInput={fileInput}
          setFileInput={setFileInput}
        />
      </Grid>
    </form>
  );

  return (
    <DialogContentComponent
      title={props.title}
      handleCancel={props.handleClose}
      handleOnSave={handleEditFile}
    >
      {form}
    </DialogContentComponent>
  );
});
