import React from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import {
  CustomEditor,
  LIST_TYPES,
  TEXT_ALIGN_TYPES,
  TextAlignTypes,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/slate/slate-editor';

import ToggleButton from '@mui/material/ToggleButton';

const isAlignActive = (editor: CustomEditor, format: TextAlignTypes) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.align === format,
    }),
  );

  return !!match;
};
const toggleAlign = (editor: CustomEditor, format: TextAlignTypes) => {
  const isActive = isAlignActive(editor, format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    align: isActive ? undefined : format,
  };
  Transforms.setNodes<SlateElement>(editor, newProperties);
};
interface Props {
  icon: React.ReactNode;
  format: TextAlignTypes;
  selected: boolean;
  setSelected: (value: boolean) => void;
}

const AlignToggleButton = (props: Props) => {
  const editor = useSlate();
  const { icon, format, selected, setSelected } = props;

  const isActive = isAlignActive(editor, format);

  return (
    <ToggleButton
      size="small"
      value={format}
      selected={isActive}
      onMouseDown={(event) => {
        setSelected(!selected);
        event.preventDefault();
        toggleAlign(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

export const useAlignToggleButton = (
  format: TextAlignTypes,
  icon: React.ReactNode,
) => {
  const [selected, setSelected] = React.useState(false);
  const button = (
    <AlignToggleButton
      format={format}
      icon={icon}
      selected={selected}
      setSelected={setSelected}
    />
  );
  return {
    selected,
    setSelected,
    button,
  };
};
