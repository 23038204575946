import {
  StockStateVisitor,
  StockVisitable,
} from '~src/data/store/visitors/asset/types/stock/stock-visitor';
import { Stock } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleStocks(
  workspaceId: string,
  dispatch: AppDispatch,
  stocks: Stock[],
) {
  const stockVisitor = new StockStateVisitor(dispatch);
  const res = stocks.map((stock) => {
    const assetVisitable = new StockVisitable(workspaceId, stock);
    return assetVisitable.accept(stockVisitor);
  });
  stockVisitor.post();
  return res;
}
