import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { nordnetTransactionImportHandler } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/transaction-import-handler';
import { handleInputData } from '~src/services/xlsx/handlers/iinput-handler';
import { DataImporter } from '~src/services/xlsx/hooks/use-import';
import { StatusUpdater } from '~src/services/xlsx/hooks/use-update-status';
import { AppDispatch } from '~src/store/store';

export const nordnetImporter =
  (): DataImporter =>
  (
    workspace: WorkspaceState,

    data: Map<string, any[]>,
    statusUpdater: StatusUpdater,
  ) => {
    return (dispatch: AppDispatch) => {
      return handleInputData(
        data,
        nordnetTransactionImportHandler(workspace, dispatch),
        statusUpdater,
      ).then(() => {
        return true;
      });
    };
  };
