import { handleProjectObjs } from '~src/data/store/modules/workspaces/projects/base/handler';
import { projectsActions } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { selectedProjectsActions } from '~src/data/store/reducers/workspace/projects/selected/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchProjects(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
  handler: (
    dispatch: any,
    tenantId: string,
    workspaceId: string,
    outputs: graphqlWorkspaceTypes.Project[],
    subscriptions: Operation[],
  ) => graphqlWorkspaceTypes.Project[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetProjectsDocument;
    const variables: graphqlWorkspaceTypes.GetProjectsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context)
      .then((data) => {
        if (data.getProjects) {
          return handler(dispatch, tenantId, workspaceId, data.getProjects, [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ]);
          // return handleProjects(
          //   dispatch,
          //   tenantId,
          //   workspaceId,
          //   data.getProjects,
          //   [
          //     Operation.create,
          //     Operation.delete,
          //     Operation.update,
          //     Operation.upsert,
          //   ]
          // );
        }
        return undefined;
      })
      .catch((err) =>
        console.error(`Error getProjects: ', ${JSON.stringify(err)}`),
      );
  };
}

export function fetchProjectObjShallows(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetProjectObjsShallowDocument;
    const variables: graphqlWorkspaceTypes.GetProjectObjsShallowQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getProjectObjs) {
        return handleProjectObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.getProjectObjs,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return undefined;
    });
  };
}

export function createProjects(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateProjectInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateProjectsDocument;
    const variables: graphqlWorkspaceTypes.CreateProjectsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createProjects) {
        return handleProjectObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.createProjects,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      throw new Error('Could not create project');
    });
  };
}

export function updateProjects(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateProjectInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateProjectsDocument;
    const variables: graphqlWorkspaceTypes.UpdateProjectsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateProjects) {
        return handleProjectObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.updateProjects,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertProjects(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertProjectInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertProjectsDocument;
    const variables: graphqlWorkspaceTypes.UpsertProjectsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertProjects) {
        return handleProjectObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertProjects,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteProjects(workspaceId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      projectsActions.removeManyElements(
        ids.map((p) => {
          return {
            workspaceId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteProjects(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteProjectsDocument;
    const variables: graphqlWorkspaceTypes.DeleteProjectsMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteProjects) {
        dispatch(handleDeleteProjects(workspaceId, data.deleteProjects));
      }
      return [];
    });
  };
}

export function selectProject(projectId: string) {
  return (dispatch: any) => {
    // dispatch(selectProjectIdAction(project.id));
    dispatch(selectedProjectsActions.set(projectId));
    // dispatch(unselectProjectRoleAction());
    // dispatch(unselectPortfolioAction());
  };
}

export function unselectProject() {
  return (dispatch: any) => {
    // dispatch(unselectProjectAction());
    dispatch(selectedProjectsActions.unset(undefined));
    // dispatch(unselectProjectRoleAction());
    // dispatch(unselectPortfolioAction());
  };
}
