import { BankNoteState } from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import { BankNote } from '~src/services/graphql/workspace/client/graphql';

export function parseBankNote(
  workspaceId: string,
  assetId: string,
  bankNote: BankNote,
): BankNoteState {
  return {
    workspaceId,
    assetId,
    id: bankNote.id,
    accountNumber: bankNote.accountNumber,
    currencyId:
      bankNote.currency && bankNote.currency.id ? bankNote.currency.id : '',
    bank: bankNote.bank,
    symbol: bankNote.symbol,
  };
}
