import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  BaseVariableId,
  variablesSelectors,
  VariableState,
} from '~src/data/store/reducers/workspace/projects/scenario/variables/reducer';

const selectVariableState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.variables;

export const getVariables = createSelector(
  (state: WorkspaceDataState) => selectVariableState(state),
  (variables): VariableState[] => {
    return variablesSelectors.selectAllElements(variables);
  },
);

export const getVariableById = createCachedSelector(
  (state: WorkspaceDataState) => selectVariableState(state),
  (_state: WorkspaceDataState, id: BaseVariableId) => id,
  (variables, id): VariableState | undefined => {
    return variablesSelectors.selectElementByT(variables, id);
  },
)({
  keySelector: (_state, id) => `${id.id}-${id.scenarioId}`,
  selectorCreator: createSelector,
});

export const getVariablesByScenarioId = createCachedSelector(
  (state: WorkspaceDataState) => selectVariableState(state),
  (_state: WorkspaceDataState, scenarioId: string) => scenarioId,
  (variables, scenarioId): VariableState[] | undefined => {
    return variablesSelectors.selectElementsByKey(variables, scenarioId);
  },
)({
  keySelector: (_state, scenarioId) => scenarioId,
  selectorCreator: createSelector,
});
