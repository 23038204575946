import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { EditTypeField } from '~src/components/fields/workspace/commitment/type/edit-type-field';
import { FieldConfig } from '~src/hooks/fields/common/field-config';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

export const useCommitmentTypeField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  fieldConfig?: FieldConfig,
  commitmentTypes?: CommitmentType[],
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<TFieldValues, TName>,
      fieldState: ControllerFieldState,
      disabled?: boolean,
    ) => (
      <EditTypeField
        handleValue={field.onChange}
        label={'Type'}
        type={field.value as CommitmentType}
        commitmentTypes={commitmentTypes}
        disabled={disabled}
      />
    ),
    [commitmentTypes],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
