import { StockGoalRuleState } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import { StockGoalRule } from '~src/services/graphql/workspace/client/graphql';

export function parseStockGoalRule(
  stockGoalRule: StockGoalRule,
): StockGoalRuleState {
  return {
    id: stockGoalRule.id,
    stockHoldingId: stockGoalRule.stockHolding?.id ?? '',
    goalRule: stockGoalRule.goalRule,
  };
}
