import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acceptWorkspaceInvitation } from '~src/data/store/modules/workspaces/workspace-invitations/requests';
import { fetchWorkspaceShallow } from '~src/data/store/modules/workspaces/workspaces/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceInvitationState } from '~src/data/store/reducers/workspace/workspace-invitations/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getWorkspaceRoleById } from '~src/data/store/selectors/workspace/workspace-roles/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import { useWorkspaceInvitationMenu } from '~src/domain/user/components/messages/workspace-invitation-message-menu.component';
import { AppDispatch } from '~src/store/store';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: 1,
    margin: theme.spacing(1),
  },
  cardContentRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

interface Props {
  invitation: WorkspaceInvitationState;
}

export const WorkspaceInvitationMessageComponent = React.memo(
  (props: Props) => {
    const { classes } = useStyles();
    const { invitation } = props;
    const workspaceRole = useSelector((state: RootState) => {
      return getWorkspaceRoleById(
        selectWorkspaceData(state),
        invitation.workspaceRoleId,
      );
    });
    const workspace = useSelector((state: RootState) => {
      return getWorkspaceById(selectWorkspaceData(state), {
        id: workspaceRole?.workspaceId ?? null,
        tenantId: workspaceRole?.tenantId ?? null,
      });
    });
    const { handleOpen: handleOpenMenu, menu } = useWorkspaceInvitationMenu();

    const dispatch = useDispatch<AppDispatch>();
    React.useEffect(() => {
      if (workspaceRole && workspaceRole.workspaceId) {
        dispatch(
          fetchWorkspaceShallow(
            workspaceRole.tenantId,
            workspaceRole.workspaceId,
          ),
        );
      }
    }, [dispatch, workspaceRole]);

    const handleClick = (invitation: WorkspaceInvitationState) => {
      dispatch(
        acceptWorkspaceInvitation(invitation.tenantId, invitation.id),
      ).then((a) => {
        console.log('accepted', a);
      });
    };
    return (
      <>
        <Card className={classes.root} variant="outlined" raised>
          <CardHeader
            action={
              <IconButton onClick={handleOpenMenu} color="inherit">
                <MoreVertIcon />
              </IconButton>
            }
            title="Workspace invitation"
            subheader={workspace?.name}
          />
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Button variant="outlined" onClick={() => handleClick(invitation)}>
              Accept
            </Button>
            {/* id: {invitation.id} */}
            Invitation for: {invitation.email}
          </CardContent>
        </Card>
        {menu}
      </>
    );
  },
);
