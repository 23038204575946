import {
  OrganizationEntity,
  OrganizationPerson,
  OrganizationRole,
  OrganizationRoleGroup,
  OrganizationRoleGroupsState,
  OrganizationType,
  organizationRoleGroupsActions,
} from '~src/data/store/modules/organizations/roles/reducer';

function parsePerson(person: any): OrganizationPerson {
  return {
    _type: 'person',
    birthDate: person.fodselsdato,
    firstName: person.navn.fornavn,
    lastName: person.navn.etternavn,
    isDead: person.erDoed,
  };
}

function parseEntity(entity: any): OrganizationEntity {
  let type: OrganizationType | undefined;
  if (entity.type) {
    type = {
      code: entity.type.kode,
      description: entity.type.beskrivelse,
    };
  }

  return {
    _type: 'entity',
    form: {
      code: entity.organisasjonsform.kode,
      description: entity.organisasjonsform.beskrivelse,
    },
    name: entity.navn,
    organizationNumber: entity.organisasjonsnummer,
    resigned: entity.fratraadt,
    order: entity.rekkefolge,
    isDeleted: entity.erSlettet,
    type,
  };
}

function parseRole(role: any): OrganizationRole {
  let personOrEntity: OrganizationPerson | OrganizationEntity | undefined;
  if (role.person != null) {
    personOrEntity = parsePerson(role.person);
  } else if (role.enhet != null) {
    personOrEntity = parseEntity(role.enhet);
  }
  return {
    code: role.type.kode,
    description: role.type.beskrivelse,
    personOrEntity,
    resigned: role.fratraadt,
  };
}
function parseRoles(roles: any[]): OrganizationRole[] {
  return roles.map((role) => {
    return parseRole(role);
  });
}

function parseRoleGroup(roleGroup: any): OrganizationRoleGroup {
  return {
    lastChanged: new Date(roleGroup.sistEndret),
    roles: parseRoles(roleGroup.roller),
    type: {
      code: roleGroup.type.kode,
      description: roleGroup.type.beskrivelse,
    },
  };
}

function parseRoleGroups(roleGroups: any[]): OrganizationRoleGroup[] {
  return roleGroups.map((roleGroup) => {
    return parseRoleGroup(roleGroup);
  });
}

function mapBrregRoleGroups(
  id: string,
  brregGroupRoles: any,
): OrganizationRoleGroupsState {
  return {
    id,
    groups: parseRoleGroups(brregGroupRoles.rollegrupper),
  };
}

const baseBrregEnheterUrl =
  'https://data.brreg.no/enhetsregisteret/api/enheter';

function getOrganizationNumber(id: string | null) {
  if (!id) {
    return null;
  }
  const split = id.split('-');
  let country = '';
  let organizationNumber = '';
  if (split.length === 2) {
    country = split[0];
    organizationNumber = split[1];
  }
  if (
    country.length === 0 ||
    organizationNumber.length === 0 ||
    country !== 'NO' // only Norway supported...
  ) {
    return null;
  }
  return organizationNumber;
}

export function fetchOrganizationRoleGroups(
  id: string | null,
  callback?: (roleGroupsState?: OrganizationRoleGroupsState, err?: any) => void,
) {
  return (dispatch: any) => {
    if (id == null) {
      return;
    }
    const organizationNumber = getOrganizationNumber(id);
    if (organizationNumber == null) {
      return;
    }

    const url = `${baseBrregEnheterUrl}/${organizationNumber}/roller`;
    fetch(url, {
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((res) => {
            const org = mapBrregRoleGroups(id, res);
            dispatch(organizationRoleGroupsActions.addOne(org));
            callback && callback(org);
          });
        } else {
          return res.json().then((res) => {
            callback && callback(undefined, res);
            return Promise.reject(res);
          });
        }
      })
      .catch((err) => {
        console.log('Could not get organization info', err);
      });
  };
}
