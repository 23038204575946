import React from 'react';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';

export interface VpsAccount {
  id: string | null;
  name: string;
}

interface Props {
  handleValue: (value: VpsAccount) => void;
  accounts: VpsAccount[];
  account: VpsAccount | undefined;
  label?: string;
}

export const SelectAccount = (props: Props) => {
  const { account, handleValue, label, accounts } = props;
  const getOptionLabel = React.useCallback(
    (t: VpsAccount) => {
      const accountId = accounts.find((account) => account?.id === t.id);
      return accountId?.name ?? '';
    },
    [accounts],
  );

  const isOptionEqualToValue = (option: VpsAccount, value: VpsAccount) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={false}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      options={accounts}
      value={account === null ? undefined : account}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
