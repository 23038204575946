import { CommitmentState } from '~src/data/store/reducers/holding/commitment/commitments/reducer';
import { Commitment } from '~src/services/graphql/workspace/client/graphql';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

export function parseCommitment(
  holdingId: string,
  commitment: Commitment,
): CommitmentState {
  return {
    id: commitment.id,
    holdingId,
    commitmentDate: new Date(commitment.commitmentDate),
    startDate: new Date(commitment.startDate),
    endDate: new Date(commitment.endDate),
    value: commitment.value,
    currencyId: commitment.currency ? commitment.currency.id : '',
    description: commitment.description,
    transferIds: commitment.transfers
      ? commitment.transfers.map((t) => t.id)
      : [],
    type: commitment.type as CommitmentType,
  };
}
