import React from 'react';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
// import { PrivateEquityFundHoldingsComponent } from '../../private-equity-fund/holdings/private-equity-fund-holdings.component';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { SettingsComponent } from '~src/domain/workspace/components/project/private-equity-fund/settings/settings.component';
import { ProjectTransactionsComponent } from '~src/domain/workspace/components/project/transaction/project-transactions.component';
import { ProjectTransfersComponent } from '~src/domain/workspace/components/project/transfer/project-transfers.component';
// import history from '~src/navigation/history';
import { privateEquityFundProjectTabPath } from '~src/navigation/paths/workspace/project/private-equity-fund-project/paths';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from 'tss-react/mui';

import { DashboardComponent } from '../../../asset/types/private-equity-fund/dashboard.component';
import { CapitalCallsComponent } from './capital-call/capital-calls.component';
import { InvestmentsComponent } from './investment/investments.component';
import { InvestorsComponent } from './investors/investors.component';
import { InvestorPortalComponent } from './investors/portal/investor-portal.component';
import { SetupComponent } from './setup.component';
import { useNavigate } from 'react-router-dom';

export enum PrivateEquityFundProjectTabs {
  Dashboard = 'dashboard',
  Setup = 'setup',
  Investments = 'investments',
  Investors = 'investors',
  Transactions = 'transactions',
  Transfers = 'transfers',
  CapitalCalls = 'capital-calls',
  InvestorPortal = 'investor-portal',
}

const useStyles = makeStyles()({
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tabBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  tab: PrivateEquityFundProjectTabs;
  value: PrivateEquityFundProjectTabs;
  classes: Record<'tabPanel' | 'tabBox', string>;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tab, classes, ...other } = props;

  const hidden = value !== tab;

  return (
    <Typography
      component="div"
      role="tabpanel"
      className={!hidden ? classes.tabPanel : ''}
      hidden={hidden}
      id={`scrollable-auto-tabpanel-${tab}`}
      aria-labelledby={`scrollable-auto-tab-${tab}`}
      {...other}
    >
      <Box className={classes.tabBox}>{children}</Box>
    </Typography>
  );
}

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
  // tab: PrivateEquityFundProjectTabs;
  tabs: Tab<PrivateEquityFundProjectTabs>[];
  tab: Tab<PrivateEquityFundProjectTabs>;
}

interface PropsTab {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
  tabName: string;
}

const SubComponent = React.memo((props: PropsTab) => {
  if (props.tabName === PrivateEquityFundProjectTabs.Dashboard) {
    return (
      <DashboardComponent
        fund={props.privateEquityFund}
        fundProject={props.privateEquityFundProject}
        project={props.project}
        workspace={props.workspace}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.Setup) {
    return (
      <SetupComponent
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
        project={props.project}
        workspace={props.workspace}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.Transactions) {
    return (
      <ProjectTransactionsComponent
        workspace={props.workspace}
        project={props.project}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.Transfers) {
    return (
      <ProjectTransfersComponent
        workspace={props.workspace}
        projectId={props.project.id}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.CapitalCalls) {
    return (
      <CapitalCallsComponent
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
        project={props.project}
        workspace={props.workspace}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.Investments) {
    return (
      <InvestmentsComponent
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
        project={props.project}
        workspace={props.workspace}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.Investors) {
    return (
      <InvestorsComponent
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
        project={props.project}
        workspace={props.workspace}
      />
    );
  } else if (props.tabName === PrivateEquityFundProjectTabs.InvestorPortal) {
    return (
      <InvestorPortalComponent
        workspace={props.workspace}
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
        project={props.project}
      />
    );
  }
  return <div>unknown</div>;
});

export const TabsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newTab: PrivateEquityFundProjectTabs,
  ) {
    navigate(
      privateEquityFundProjectTabPath(
        props.workspace.tenantId,
        props.workspace.id,
        props.privateEquityFundProject.projectId,
        newTab,
      ),
    );
  }

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        return (
          <SubComponent
            key={index}
            privateEquityFund={props.privateEquityFund}
            privateEquityFundProject={props.privateEquityFundProject}
            tabName={tab.name}
            workspace={props.workspace}
            project={props.project}
          />
        );
      }),
    [
      props.privateEquityFund,
      props.privateEquityFundProject,
      props.project,
      props.tabs,
      props.workspace,
    ],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel
        value={props.tab.name}
        tab={tab.name}
        key={index}
        classes={classes}
      >
        {components[index]}
      </TabPanel>
    );
  });

  return (
    <>
      <Tabs
        value={props.tab.name}
        indicatorColor="primary"
        textColor="primary"
        // centered
        variant="scrollable"
        onChange={handleChange}
        aria-label="privateEquityFund project tabs"
      >
        {tabElements}
      </Tabs>
      {tabPanelElements}
    </>
  );
});
