// import axios from 'axios';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileList } from '~src/components/utils/files/file-list';
import {
  deleteFiles as deleteFilesRequest,
  fetchFiles,
} from '~src/data/store/modules/file/file/requests';
import { fetchSignedUrls } from '~src/data/store/modules/obj-storage/objs/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getFilesByIds } from '~src/data/store/selectors/file/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { EditEventComponent } from '~src/domain/workspace/components/project/event/form/edit.component';
import { EventFileDropzoneComponent } from '~src/domain/workspace/components/project/event/form/file-dropzone.component';
import { ProjectEventEntitiesComponent } from '~src/domain/workspace/components/project/event/relations/entities/entities.component';
import { ProjectEventHoldingsComponent } from '~src/domain/workspace/components/project/event/relations/holdings/holdings.component';
import { ProjectEventPortfoliosComponent } from '~src/domain/workspace/components/project/event/relations/portfolios/portfolios.component';
import { ProjectEventTransactionsComponent } from '~src/domain/workspace/components/project/event/relations/transactions/transactions.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { graphqlObjStorageOperations } from '@pladdenico/obj-storage';
import { downloadFile } from '~src/utils/file/download-file';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  event: EventState;
}

export const ProjectEventComponent = React.memo((props: Props) => {
  const { workspace, project, event } = props;

  const editEventModal = useVisible();
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(fetchFiles(workspace.tenantId, workspace.id, event.fileIds));
  }, [dispatch, event.fileIds, workspace.id, workspace.tenantId]);

  const files = useSelector((state: RootState) =>
    getFilesByIds(selectWorkspaceData(state), { ids: event.fileIds }),
  );

  const deleteFiles = React.useCallback(
    (files: FileState[]) =>
      dispatch(
        deleteFilesRequest(
          workspace.tenantId,
          workspace.id,
          files.map((m) => {
            return {
              id: m.id,
            };
          }),
        ),
      ),
    [dispatch, workspace.id, workspace.tenantId],
  );

  const downloadFiles = React.useCallback(
    (files: FileState[]) => {
      const keys = files.map((m) => m.id);
      return dispatch(
        fetchSignedUrls(keys, graphqlObjStorageOperations.ObjOperationType.Get),
      ).then((urls) => {
        if (urls) {
          urls.forEach((url) => {
            const file = files.find((f) => f.id === url.id);
            if (file) {
              downloadFile(url.url, file);
            }
          });
        }
        return urls?.map((url) => url.id);
      });
    },
    [dispatch],
  );

  return (
    <Box m={1}>
      <Grid container alignItems="center">
        <Grid>
          <Typography variant="body1">{event.name}</Typography>
          <Typography variant="caption">{event.description}</Typography>
        </Grid>
        <Grid>
          <IconButton onClick={editEventModal.toggle} size="small">
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container mt={1} rowSpacing={1}>
        <Grid xs={12}>
          <Typography variant="h6">Relations</Typography>
        </Grid>
        <Grid xs={12}>
          <ProjectEventHoldingsComponent
            workspace={workspace}
            project={project}
            event={event}
          />
        </Grid>
        <Grid xs={6}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <ProjectEventPortfoliosComponent
            workspace={workspace}
            project={project}
            event={event}
          />
        </Grid>
        <Grid xs={6}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <ProjectEventEntitiesComponent
            workspace={workspace}
            project={project}
            event={event}
          />
        </Grid>
        <Grid xs={6}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <ProjectEventTransactionsComponent
            workspace={workspace}
            project={project}
            event={event}
          />
        </Grid>
        <Grid xs={6}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container mt={1} rowSpacing={1}>
        <Grid xs={12}>
          <Typography variant="h6">Files</Typography>
        </Grid>
        <Grid xs={6}>
          <FileList
            files={files}
            deleteFiles={deleteFiles}
            downloadFiles={downloadFiles}
          />
        </Grid>
        <Grid container xs={6} sx={{ p: 2 }}>
          <EventFileDropzoneComponent workspace={workspace} event={event} />
        </Grid>
      </Grid>
      <EditEventComponent
        workspace={workspace}
        project={project}
        open={editEventModal.isShowing}
        handleClose={editEventModal.close}
        event={event}
      />
    </Box>
  );
});
