import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { CreateEventComponent } from '~src/domain/workspace/components/project/event/form/create.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import FilterListIcon from '@mui/icons-material/FilterList';
import MoreIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';

// import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from 'tss-react/mui';

import { TableDrawers, TableOperations } from '@pladdenico/table';

const useStyles = makeStyles()((theme: Theme) => ({
  root: { flex: 1 },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {},
}));

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  algoData: TableOperations.AlgorithmData<EventState>;
}

export const Toolbar = (props: Props) => {
  const { algoData } = props;
  const menuId = 'event-menu';

  const { classes } = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  // const [createHoldingDialogOpened, setCreateHoldingDialogOpened] =
  //   React.useState(false);
  const createEventModal = useVisible();

  // const { modal: exportModal, dialog: exportDialog } =
  //   useExportEventDialogComponent(workspace, algoData.filtered);

  const menu = useMenu();

  const handleAddEvent = () => {
    createEventModal.open();
    menu.handleClose();
  };

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const eventMenu = (
    <Menu
      anchorEl={menu.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={menu.isOpen}
      onClose={menu.handleClose}
    >
      <MenuItem onClick={handleAddEvent}>Add event</MenuItem>
    </Menu>
  );

  const filtersComponent = algoData.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={algoData.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });

  const sortersComponent = algoData.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={algoData.dispatchSorters}
        sorter={sorter}
      />
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {/* <IconButton onClick={handlePropertiesDrawerOpen}>
          <ViewListIcon />
        </IconButton> */}
        <span className={classes.spacer} />
        <IconButton onClick={handleFilterDrawerOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton onClick={handleSortDrawerOpen}>
          <SortIcon />
        </IconButton>
        <IconButton onClick={menu.handleOpen} color="inherit">
          <MoreIcon />
        </IconButton>
      </div>
      <CreateEventComponent
        workspace={props.workspace}
        project={props.project}
        open={createEventModal.isShowing}
        handleClose={createEventModal.close}
      />
      {eventMenu}
      <TableDrawers.FilterDrawerComponent
        open={filterOpen}
        closeDrawer={() => setFilterOpen(false)}
        filtersComponent={
          <Box>
            <Typography variant="overline">Event</Typography>
            {filtersComponent}
          </Box>
        }
      />
      <TableDrawers.SorterDrawerComponent
        open={sortOpen}
        closeDrawer={() => setSortOpen(false)}
        sortersComponent={<>{sortersComponent}</>}
      />
    </div>
  );
};
