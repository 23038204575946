import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { personRootPath } from '~src/navigation/paths/workspace/people/paths';

import { Link, TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';

export function usePersonFields(tenantId: string) {
  const navigate = useNavigate();
  const handlePersonClick = React.useCallback(
    (tenantId: string, workspaceId: string, personId: string) => {
      navigate(personRootPath(tenantId, workspaceId, personId));
    },
    [navigate],
  );

  const nameField: TableField.Field<PersonState> = React.useMemo(() => {
    return {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data: PersonState) => data.name,
      renderView: (viewProps) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handlePersonClick(
                tenantId,
                viewProps.data.workspaceId,
                viewProps.data.id,
              )
            }
          >
            {viewProps.data.name}
          </Link>
        );
        // return <>{viewProps.data.name ? viewProps.data.name : ''}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="name"
            value={editProps.data.name}
            onChange={editProps.onChange}
          />
        );
      },
    };
  }, [handlePersonClick, tenantId]);

  const descriptionField: TableField.Field<PersonState> = React.useMemo(() => {
    return {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data) => (data.description ? data.description : ''),
      renderView: (viewProps) => {
        return (
          <>{viewProps.data.description ? viewProps.data.description : ''}</>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="description"
            value={editProps.data.description}
            onChange={editProps.onChange}
          />
        );
      },
    };
  }, []);

  const fields = React.useMemo(() => {
    return {
      name: nameField,
      description: descriptionField,
    };
  }, [descriptionField, nameField]);
  return fields;
}
