import * as React from 'react';
import { EventState } from '~src/data/store/state/workspace/project/event/event';

import { TableField } from '@pladdenico/table';

export function useEventFields() {
  const fields = React.useMemo(() => {
    const name: TableField.Field<EventState> = {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data: EventState) => data.name,
    };
    const description: TableField.Field<EventState> = {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data: EventState) => data.description,
    };
    const date: TableField.Field<EventState> = {
      id: 'date',
      name: 'date',
      field: 'date',
      type: TableField.FieldTypes.date,
      iteratee: (data: EventState) => data.date,
    };
    return {
      name,
      description,
      date,
    };
  }, []);
  return fields;
}

export type EventFields = ReturnType<typeof useEventFields>;
