import { currencyTimeSpans } from './currency.listener';

export class CurrencyStorage {
  private static storage =
    typeof localStorage === 'undefined' ? null : localStorage;

  public static setCurrencyTimeSpans(currencyTimeSpans: currencyTimeSpans) {
    if (this.storage) {
      this.storage.setItem(
        'currencyTimeSpans',
        JSON.stringify(Array.from(currencyTimeSpans)),
      );
    }
  }

  public static getCurrencyTimeSpans(): currencyTimeSpans | undefined {
    if (this.storage) {
      const currencyTimeSpansString = this.storage.getItem('currencyTimeSpans');
      if (currencyTimeSpansString) {
        return new Map(JSON.parse(currencyTimeSpansString));
      }
    }
    return undefined;
  }
}
