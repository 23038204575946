import React from 'react';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useCapitalCallActions } from '~src/hooks/actions/workspace/project/private-equity/capital-call/use-capital-call-actions';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { CreateCapitalCallFieldsComponent } from './create-fields.component';

// import { EditHoldingField } from '../../dashboard/components/allocation/allocation-filter-field';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  fund: PrivateEquityFundState;
  createInputInit: graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallInputType;
  handleClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateCapitalCallFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { onCreate } = useCapitalCallActions(props.workspace, props.fund.id);
  const [capitalCallInput, setCapitalCallInput] =
    React.useState<graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallInputType>(
      () => props.createInputInit,
    );

  const handleOnCreate = React.useCallback(() => {
    onCreate({
      ...capitalCallInput,
    }).then(() => props.handleClose());
  }, [capitalCallInput, onCreate, props]);

  return (
    <>
      <DialogTitle id="form-dialog-title">Create capitalCall</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <CreateCapitalCallFieldsComponent
            workspace={props.workspace}
            tenantId={props.workspace.tenantId}
            projectId={props.projectId}
            capitalCallInput={capitalCallInput}
            setCapitalCallInput={setCapitalCallInput}
            showId={true}
            showConfirmed={true}
            showHolding={true}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOnCreate} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
});
