import { selectedWorkspacesActions } from '~src/data/store/reducers/workspace/selected-workspace/reducer';
import { AppDispatch } from '~src/store/store';

import { unselectProject } from '../projects/base/requests';

export function selectWorkspace(workspaceId: string) {
  return (dispatch: AppDispatch) => {
    dispatch(selectedWorkspacesActions.set(workspaceId));
    dispatch(unselectProject());
  };
}

export function unselectWorkspace() {
  return (dispatch: AppDispatch) => {
    dispatch(selectedWorkspacesActions.unset(undefined));
    dispatch(unselectProject());
  };
}
