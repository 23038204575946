import * as React from 'react';
import { useSelector } from 'react-redux';
import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { FundHoldingState } from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPaperById } from '~src/data/store/selectors/finance/papers/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { BaseHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/base-holding-card.component';

import { Typography } from '@mui/material';

import { EditDialogComponent } from '../../../../holding/holding-types/fund-holdings/form/edit-dialog.component';
import { useActions } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/fund/use-actions';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  fundHolding: FundHoldingState;
  fund: FundState | undefined;
  asset: AssetState | undefined;
}

export const FundHoldingCardComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holding,
    portfolios,
    fundHolding,
    fund,
    asset,
  } = props;
  const paper = useSelector((state: RootState) =>
    getPaperById(selectFinancialData(state), fund?.paperId),
  );

  const getEditDialogComponent = React.useCallback(
    (editing: boolean, handleClose: () => void) => {
      return (
        <EditDialogComponent
          open={editing}
          handleClose={handleClose}
          holding={holding}
          fundHolding={fundHolding}
          workspace={workspace}
          projectId={projectId}
          portfolios={portfolios}
        />
      );
    },
    [holding, portfolios, projectId, workspace, fundHolding],
  );

  const { deleteHolding } = useActions(
    workspace,
    projectId,
    holding,
    fundHolding,
  );

  const paperString =
    fund && paper ? `Paper: ${paper?.exchange}-${paper?.symbol}` : undefined;
  const paperElement = paperString ? (
    <Typography variant="subtitle2">{paperString}</Typography>
  ) : (
    <></>
  );

  return (
    <BaseHoldingCardComponent
      asset={asset}
      projectId={projectId}
      assetDetailElement={paperElement}
      getDialogComponent={getEditDialogComponent}
      holding={holding}
      portfolios={portfolios}
      workspace={workspace}
      deleteHolding={deleteHolding}
    />
  );
});
