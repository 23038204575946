import { eventParser, EventType } from '~src/utils/form/event-parser';

export function useHandler<T>(t: T, setT: (t: T) => void) {
  const handleUpdate = (name: keyof T) => (value: unknown) => {
    setT({ ...t, [name]: value });
  };

  const handleEvent = (name: keyof T) => (event: EventType) => {
    // console.log('handleEvent', event.target);
    handleUpdate(name)(eventParser(event));
  };

  return {
    handleUpdate,
    handleEvent,
  };
}
