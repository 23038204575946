import { parsePortfolioDashboardConfig } from '~src/data/store/modules/portfolio-configs/portfolio-dashboard-configs/parser';
import {
  portfolioDashboardConfigsActions,
  PortfolioDashboardConfigState,
} from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import {
  DashboardElementStateVisitor,
  DashboardElementVisitable,
} from '~src/data/store/visitors/common/dashboard/element-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import { PortfolioDashboardConfig } from '~src/services/graphql/user/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface PortfolioDashboardConfigVisitor {
  visit(
    portfolioDashboardConfig: PortfolioDashboardConfigVisitable,
  ): PortfolioDashboardConfigState;
  post(): void;
  portfolioDashboardElementVisitor: DashboardElementStateVisitor;
}

export class PortfolioDashboardConfigVisitable
  implements Visitable<PortfolioDashboardConfigVisitor>
{
  constructor(
    private _portfolioDashboardConfigId: number,
    private _portfolioDashboardConfig: PortfolioDashboardConfig,
  ) {}
  public accept(visitor: PortfolioDashboardConfigVisitor) {
    if (this._portfolioDashboardConfig.elements) {
      this._portfolioDashboardConfig.elements.forEach((dashboardElement) => {
        const portfolioDashboardElementVisitable =
          new DashboardElementVisitable(dashboardElement);
        portfolioDashboardElementVisitable.accept(
          visitor.portfolioDashboardElementVisitor,
        );
      });
    }
    return visitor.visit(this);
  }

  public parse(): PortfolioDashboardConfigState {
    return parsePortfolioDashboardConfig(
      this._portfolioDashboardConfigId,
      this._portfolioDashboardConfig,
    );
  }
}

export class PortfolioDashboardConfigStateVisitor
  implements PortfolioDashboardConfigVisitor
{
  private _portfolioDashboardConfigs: PortfolioDashboardConfigState[];
  public portfolioDashboardElementVisitor: DashboardElementStateVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._portfolioDashboardConfigs = [];
    this.portfolioDashboardElementVisitor = new DashboardElementStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }

  public visit(
    visitable: PortfolioDashboardConfigVisitable,
  ): PortfolioDashboardConfigState {
    const config = visitable.parse();
    this._portfolioDashboardConfigs.push(config);
    return config;
  }

  post() {
    this._dispatch(
      portfolioDashboardConfigsActions.upsertManyElements(
        this._portfolioDashboardConfigs,
      ),
    );
    this.portfolioDashboardElementVisitor.post();
  }
}
