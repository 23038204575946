import { sortedIndexBy } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  ExchangeRate,
  exchangeRatesSelectors,
  ExchangeRateState,
} from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { FinancialDataState } from '~src/data/store/reducers/reducers';

export const getExchangeRateState = (
  state: FinancialDataState,
): ExchangeRateState => state.finance.exchangeRates;

interface NullableExchangeRateId {
  // id: string; // | null;
  baseCurrencyId: string; // | null;
  quoteCurrencyId: string; // | null;
}

export const getExchangeRatesById = createCachedSelector(
  (state: FinancialDataState) => getExchangeRateState(state),
  (_state: FinancialDataState, id: NullableExchangeRateId | null) => id,
  (exchangeRateState, id): ExchangeRate[] => {
    if (!id) {
      return [];
    }
    return (
      exchangeRatesSelectors.selectElementsByKey(exchangeRateState, id) ?? []
    );
  },
)({
  keySelector: (_state, id) =>
    id ? `${id.baseCurrencyId}.${id.quoteCurrencyId}` : '',
  selectorCreator: createSelector,
});

export const getExchangeRates = createSelector(
  (state: FinancialDataState) => getExchangeRateState(state),
  (exchangeRates) => {
    return exchangeRatesSelectors.selectAllElements(exchangeRates);
  },
);

interface NullableExchangeRateIdAndDate {
  id: NullableExchangeRateId;
  date: Date;
}

export const getExchangeRatesByDate = createCachedSelector(
  (state: ExchangeRateState, baseId: NullableExchangeRateIdAndDate) =>
    exchangeRatesSelectors.selectElementsByKey(state, baseId.id),
  (_state: ExchangeRateState, baseId: NullableExchangeRateIdAndDate) =>
    baseId.date,
  (exchangeRates, date): ExchangeRate | undefined => {
    if (exchangeRates != null && exchangeRates.length > 0) {
      const idx = sortedIndexBy(exchangeRates, { date } as any, (e) => {
        return e.id;
      });
      if (idx === 0) {
        return exchangeRates[0];
      }
      return exchangeRates[idx - 1];
    }
    return undefined;
  },
)({
  keySelector: (_state, baseId) =>
    `${baseId.id.baseCurrencyId}:${
      baseId.id.quoteCurrencyId
    }.${baseId.date.getTime()}`,
  selectorCreator: createSelector,
});
