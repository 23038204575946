import { compact } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
// import history from '~src/navigation/history';
import { personRootPath } from '~src/navigation/paths/workspace/people/paths';
import { Index } from '~src/services/search/index';

import { SearchResult } from '../../components/search/search-component';

function createPeopleIndex(people: PersonState[]) {
  const peopleIndex = new Index();
  const fields = [
    'name',
    'description',
    'addresses',
    'emails',
    'phones',
    'roles',
  ];
  const documents = people.map((person) => {
    return {
      id: person.id,
      name: person.name,
      description: person.description,
    };
  });
  peopleIndex.build(fields, documents);
  return peopleIndex;
}

const peopleSearch =
  (
    tenantId: string,
    workspaceId: string,
    people: PersonState[],
    peopleIndex: Index,
    onClick: (person: PersonState) => void,
  ) =>
  (query: string): SearchResult[] => {
    const idx = peopleIndex.idx();
    if (!idx) {
      return [];
    }
    const personSearchResult = idx.search(query);
    const personsHits: PersonState[] = compact(
      personSearchResult.map((hit: any) => {
        return people.find((person) => {
          return person.id === String(hit.ref);
        });
      }),
    );
    const personResults = personsHits.map((person) => {
      return {
        id: person.id ? person.id : -1,
        title: person.name ? person.name : '',
        description: person.description ? person.description : '',
        onClick: () => onClick(person),
      };
    });
    return personResults;
  };

export function usePeopleIndex(
  tenantId: string,
  workspaceId: string,
  people: PersonState[],
) {
  const [peopleIndex, setPeopleIndex] = React.useState<Index>(
    () => new Index(),
  );

  React.useEffect(() => {
    setPeopleIndex(createPeopleIndex(people));
  }, [people]);

  const navigate = useNavigate();

  const onClick = React.useCallback(
    (person: PersonState) => {
      return navigate(personRootPath(tenantId, workspaceId, person.id));
    },
    [navigate, tenantId, workspaceId],
  );

  return {
    peopleSearch: peopleSearch(
      tenantId,
      workspaceId,
      people,
      peopleIndex,
      onClick,
    ),
  };
}
