import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getBondById } from '~src/data/store/selectors/asset/asset-types/bonds/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useBondHoldingInfo(workspaceId: string, holdingId: string) {
  const bondHolding = useSelector((state: RootState) =>
    getBondHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const bond = useSelector((state: RootState) =>
    getBondById(selectWorkspaceData(state), {
      id: bondHolding ? bondHolding.bondId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: bond ? bond.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    bondHolding,
    asset,
    bond,
  };
}
