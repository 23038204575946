import React from 'react';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useProjectReportForm } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/use-project-report-form';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  projectReport: ProjectReportState;
}

export const ProjectReportConfigComponent = React.memo((props: Props) => {
  const { projectReport, workspace } = props;

  const fields = useProjectReportForm({
    workspace,
    projectReport,
    inputData: {
      name: projectReport.name ?? '',
      published: projectReport.published ?? false,
      periodStart: projectReport.periodStart ?? new Date(),
      periodEnd: projectReport.periodEnd ?? new Date(),
      compactType: projectReport.compactType,
    },
  });

  return (
    <Grid2 container columnSpacing={1} rowSpacing={2}>
      <Grid2 sm={12}>{fields.nameField}</Grid2>
      <Grid2 sm={12}>{fields.publishedField}</Grid2>
      <Grid2 sm={6}>{fields.periodFields.periodStartField.element}</Grid2>
      <Grid2 sm={6}>{fields.periodFields.periodEndField.element}</Grid2>
      <Grid2 sm={6}>{fields.compactTypeField}</Grid2>
    </Grid2>
  );
});
