import { sortBy, sortedIndexBy } from 'lodash';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { ExchangeRateState } from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { BaseValuation } from '~src/utils/finance/base-valuation';
import { convert } from '~src/utils/finance/currency-converter';
import { Finance } from '~src/utils/finance/holding';

import { Trade } from '@pladdenico/models';

interface Position {
  date: Date;
  shares: number;
}

export function quoteHoldingValuation(
  date: Date,
  currency: CurrencyState,
  exchangeRateState: ExchangeRateState,
  valuations: ValuationState[],
  positions: Position[],
  paper: PaperState | undefined,
  quotes: QuoteState[],
  trades: Trade[],
  useLiveValue: boolean,
): BaseValuation {
  const valuation = Finance.findClosestValuation(valuations, date);

  let valuationByMarket = undefined;
  if (useLiveValue && positions.length > 0) {
    const sortedStockPositions = sortBy(positions, [(a) => a.date.getTime()]);
    const positionIdx = sortedIndexBy(
      sortedStockPositions,
      { date: date } as any,
      (p) => p.date.getTime(),
    );
    if (positionIdx > 0) {
      const position = sortedStockPositions[positionIdx - 1];

      const quote = Finance.quoteFromDate(date, quotes);
      const trade = Finance.tradeFromDate(date, trades);

      const quoteTimeDiff = quote
        ? Math.abs(quote.date.getTime() - date.getTime())
        : Number.MAX_SAFE_INTEGER;
      const tradeTimeDiff = trade
        ? Math.abs(trade.time.getTime() - date.getTime())
        : Number.MAX_SAFE_INTEGER;
      const valuationTimeDiff = valuation
        ? Math.abs(valuation.date.getTime() - date.getTime())
        : Number.MAX_SAFE_INTEGER;
      if (quoteTimeDiff < tradeTimeDiff) {
        if (quote && quoteTimeDiff < valuationTimeDiff && position.shares) {
          valuationByMarket = {
            value: (quote.close ?? 0) * position.shares,
            date: quote.date,
            paper,
            // currencyId: quote.
          };
        }
      } else {
        if (trade && tradeTimeDiff < valuationTimeDiff && position.shares) {
          valuationByMarket = {
            value: trade.price * position.shares,
            date: trade.time,
            paper,
          };
        }
      }
    }
  }

  let value = 0;
  let valuationDate: Date;
  let baseCurrencyId = '';
  let baseValue = 0;
  if (paper && valuationByMarket) {
    value =
      valuationByMarket.value *
      convert(exchangeRateState, paper.currencyId, currency.id, date);
    valuationDate = valuationByMarket.date;
    baseCurrencyId = paper.currencyId;
    baseValue = valuationByMarket.value;
  } else if (valuation && valuation.value && valuation.currencyId) {
    value =
      valuation.value *
      convert(exchangeRateState, valuation.currencyId, currency.id, date);
    valuationDate = valuation.date;
    baseCurrencyId = valuation.currencyId;
    baseValue = valuation.value;
  } else {
    value = 0;
    valuationDate = date;
    baseCurrencyId = '';
    baseValue = 0;
  }

  return {
    value,
    date: valuationDate,
    currencyId: currency.id,
    baseCurrencyId,
    baseValue,
  };
}
