// import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useSelector } from 'react-redux';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getHoldingsByPortfolioId,
  getSelectedPortfolio,
} from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
// import { AddElementButtonComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/add-element-button.component';
import { useDashboardComponents } from '~src/domain/workspace/components/project/portfolio/dashboard/hooks/use-dashboard-components';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { Config } from '~src/utils/interfaces/config';
// import { UsageMode } from '~src/utils/interfaces/usage-mode';

export const useElements = (
  workspace: WorkspaceState,
  project: ProjectState,
  portfolioProject: PortfolioProjectState,
  portfolio: PortfolioState,
  config: Config,
  layouts: ReactGridLayout.Layouts | undefined,
  updateLayouts:
    | ((
        layouts: ReactGridLayout.Layouts,
      ) => Promise<ReactGridLayout.Layouts | undefined>)
    | undefined,
  dashboardElements: DashboardElementState[],
  addDashboardElements?: (
    dashboardElement: graphqlWorkspaceTypes.DashboardElement[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>,
  removeDashboardElements?: (
    dashboardElementIds: string[],
  ) => Promise<string[]>,
  updateDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>,
) => {
  const selectedPortfolio = useSelector((state: RootState) => {
    const p = getSelectedPortfolio(selectWorkspaceData(state), workspace.id);
    if (!p) {
      return portfolio;
    }
    return p;
  });
  const holdings = useSelector((state: RootState) =>
    getHoldingsByPortfolioId(selectWorkspaceData(state), {
      id: selectedPortfolio.id,
      projectId: portfolioProject.projectId,
      workspaceId: workspace.id,
    }),
  );

  const [loading] = React.useState(false);

  const {
    dialog,
    open,
    container: components,
  } = useDashboardComponents({
    dashboardElements,
    holdings,
    loading,
    portfolio: selectedPortfolio,
    portfolioProject,
    project,
    workspace,
    config,
    layouts,
    updateLayouts,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  });

  // const addElement = React.useMemo(() => {
  //   if (config.usageMode === UsageMode.edit) {
  //     return (
  //       <>
  //         <AddElementButtonComponent open={open} />
  //         {dialog}
  //       </>
  //     );
  //   }
  // }, [config.usageMode, dialog, open]);
  return { components, dialog, open };
};
