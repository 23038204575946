import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { IndexedState } from '~src/data/base/indexed/indexed-entity-adapter';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  BaseSetupId,
  SetupState,
  setupsSelectors,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/setups/reducer';

const getSetupState = (state: WorkspaceDataState) =>
  state.holding.asset.privateEquityFund.setups;

export const selectSetupByFundId = createCachedSelector(
  (state: IndexedState<BaseSetupId, SetupState>, fundId: string) =>
    setupsSelectors.selectElementByIdx(state, fundId),
  (setup): SetupState | undefined => {
    return setup;
  },
)({ keySelector: (_state, fundId) => fundId, selectorCreator: createSelector });

export const getSetupByFundId = createCachedSelector(
  (state: WorkspaceDataState) => getSetupState(state),
  (_state: WorkspaceDataState, fundId: string) => fundId,
  (setupState, fundId): SetupState | undefined => {
    return selectSetupByFundId(setupState, fundId);
  },
)({ keySelector: (_state, fundId) => fundId, selectorCreator: createSelector });

export const getSetups = createSelector(
  (state: WorkspaceDataState) => getSetupState(state),
  (setups) => {
    return setupsSelectors.selectAllElements(setups);
  },
);

export const getSetupById = createCachedSelector(
  (state: WorkspaceDataState) => getSetupState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (setups, id): SetupState | undefined => {
    return setupsSelectors.selectElementByI(setups, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });
