import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  PortfolioConfigState,
  portfolioConfigSelectors as portfolioConfigsSelectors,
} from '../../../../reducers/portfolio/portfolio-config/portfolio-configs/reducer';

const getPortfolioConfigState = (state: WorkspaceDataState) =>
  state.portfolio.portfolioConfigs;

// export const selectPortfolioConfigsByPortfolioId = createCachedSelector(
//   (state: RecordState<PortfolioConfigState, string>[], portfolioId: string) =>
//     portfolioConfigsSelectors.selectElementsByKey(state, portfolioId),
//   (portfolios: PortfolioConfigState[] | undefined): PortfolioConfigState[] => {
//     return portfolios ? portfolios : [];
//   },
// )({
//   keySelector: (_state, portfolioId) => portfolioId,
//   selectorCreator: createSelector,
// });

export const getPortfolioConfigsByPortfolioId = createCachedSelector(
  (state: WorkspaceDataState) => getPortfolioConfigState(state),
  (_state: WorkspaceDataState, portfolioId: string) => portfolioId,
  (portfolioState, portfolioId): PortfolioConfigState[] => {
    return (
      portfolioConfigsSelectors.selectElementsByKey(
        portfolioState,
        portfolioId,
      ) ?? []
    );
    // return selectPortfolioConfigsByPortfolioId(portfolioState, portfolioId);
  },
)({
  keySelector: (_state, portfolioId) => portfolioId,
  selectorCreator: createSelector,
});

export const getPortfolioConfigByPortfolioId = createCachedSelector(
  (state: WorkspaceDataState, portfolioId: string) =>
    getPortfolioConfigsByPortfolioId(state, portfolioId),
  (configs): PortfolioConfigState | undefined => {
    if (configs.length > 0) {
      return configs[0];
    }
    return undefined;
  },
)({
  keySelector: (_state, portfolioId) => portfolioId,
  selectorCreator: createSelector,
});

export const getPortfolioConfigs = createSelector(
  (state: WorkspaceDataState) => getPortfolioConfigState(state),
  (portfolios) => {
    return portfolioConfigsSelectors.selectAllElements(portfolios);
  },
);
