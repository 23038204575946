import numbro from 'numbro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { useLastValuation } from '~src/utils/finance/valuation';

import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  holding: HoldingState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  colorBase: {
    // background: colors.blue[600],
    // color: colors.common.white,
  },
  label: {
    textTransform: 'uppercase',
  },
  valueRoot: {
    // paddingTop: 8,
  },
  labelRoot: {
    // paddingBottom: 8,
  },
}));

export const HoldingValuationComponent = React.memo((props: Props) => {
  const { classes, cx } = useStyles();
  const { holding } = props;

  const valuation = useLastValuation(holding.id);
  const currency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), {
      id: valuation?.currencyId ?? null,
    }),
  );

  const currencyElement = currency ? (
    <Grid item xs={12} className={cx(classes.colorBase, classes.labelRoot)}>
      <Typography
        component="p"
        variant="caption"
        className={classes.label}
        align="center"
      >
        ({currency?.symbol})
      </Typography>
    </Grid>
  ) : undefined;

  return (
    <Grid container>
      <Grid item xs={12} className={cx(classes.colorBase, classes.valueRoot)}>
        <Typography variant="body1" noWrap align="center">
          {numbro(valuation?.value ?? 0).format({
            thousandSeparated: true,
            // mantissa: 1,
            totalLength: 5,
            average: true,
          })}
        </Typography>
      </Grid>
      {currencyElement}
    </Grid>
  );
});
