import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { upsertOne } from '~src/data/store/modules/config/reducer';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { selectedCurrenciesActions } from '~src/data/store/reducers/finance/currency/selected/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';
import { Config } from '~src/utils/interfaces/config';

import { DatePicker } from '@mui/x-date-pickers';
import { Grid, FormControlLabel, Switch, TextField, Box } from '@mui/material';
import { DashboardConfigInput } from '~src/domain/workspace/components/project/holding/config/dashboard-config.input';
import { EditDashboardConfig } from '~src/domain/workspace/components/project/holding/config/edit-dashboard-config';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { DrawerHeader } from '~src/components/dashboard/drawer-header';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  holdingConfig: HoldingConfigState;
  dashboardConfig?: HoldingDashboardConfigState;
}
export const HoldingConfigComponent = React.memo((props: Props) => {
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );

  if (config) {
    return <HoldingConfigComponentWithConfig {...props} config={config} />;
  }
  return <div>No valid config</div>;
});

interface PropsWithConfig extends Props {
  config: Config;
}

export const HoldingConfigComponentWithConfig = React.memo(
  (props: PropsWithConfig) => {
    const { dashboardConfig, config, workspace, project, holdingConfig } =
      props;
    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    const [config_, setConfig_] = React.useState(config);
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      setConfig_(config);
    }, [config]);

    const handleConfigUpdate = () => {
      updateConfig(config_);
    };

    const handleConfigIndexPaperChanged = (event: any) => {
      console.log(event);
      const config = { ...config_ };
      const value = event.target.value;
      const separatorIdx = value.lastIndexOf('.');
      config.indexPaper = {
        id: '',
        symbol: separatorIdx === -1 ? value : value.slice(0, separatorIdx),
        exchange: separatorIdx === -1 ? '' : value.slice(separatorIdx + 1),
        currencyId: '__CURRENCY__NOK',
      };
      setConfig_(config);
    };

    const handleConfigMinimumValuationForAssetChanged = (event: any) => {
      console.log(event);
      const config = { ...config_ };
      const value = +event.target.value;
      config.minimumValuationForHolding = value;
      setConfig_(config);
    };

    const onDateChange = (date: any) => {
      const config = { ...config_ };
      config.date.set(date.toObject());
      setConfig_(config);
      updateConfig(config);
    };

    const onPeriodStartChange = (date: any) => {
      const config = { ...config_ };
      const period = config.period;
      const newDate = moment(date);
      period.start = moment(newDate);
      if (period.end <= newDate) {
        period.end = newDate.add(1, 'year');
      }
      setConfig_(config);
      updateConfig(config);
    };

    const onPeriodEndChange = (date: any) => {
      const config = { ...config_ };
      const period = config.period;
      const newDate = moment(date);
      period.end = moment(newDate);
      if (period.start >= newDate) {
        period.start = newDate.add(-1, 'year');
      }
      setConfig_(config);
      updateConfig(config);
    };

    const handleOnChangeBaseCurrency = (currency: CurrencyState): void => {
      dispatch(selectedCurrenciesActions.set(currency.id));
    };

    const handleConfigUseLiveValueChanged = (
      event: React.ChangeEvent<unknown>,
      checked: boolean,
    ) => {
      const config = { ...config_ };
      config.useLiveValue = checked;
      setConfig_(config);
      updateConfig(config);
    };

    const updateConfig = (config: Config) => {
      dispatch(upsertOne({ id: 'default', config }));
    };

    const dashabordConfigElement = dashboardConfig && (
      <Box sx={{ marginBottom: 1 }}>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={10}>
            <DashboardConfigInput
              workspace={workspace}
              projectId={project.id}
              holdingConfig={holdingConfig}
              dashboardConfig={dashboardConfig}
              label="Dashboard config"
            />
          </Grid>
          <Grid item xs={2} justifyContent="center">
            <EditDashboardConfig
              workspace={workspace}
              projectId={project.id}
              dashboardConfig={dashboardConfig}
            />
          </Grid>
        </Grid>
      </Box>
    );

    return (
      <Box ml={2} mr={2} mt={2}>
        <DrawerHeader />
        <Box sx={{ marginBottom: 1 }}>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label={'Valuation date'}
            value={config_.date}
            onChange={onDateChange}
            renderInput={(params) => <TextField margin="normal" {...params} />}
          />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label={'Start date'}
            value={config_.period.start}
            minDate={config_.minDate}
            onChange={onPeriodStartChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label={'End date'}
            value={config_.period.end}
            onChange={onPeriodEndChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <EditCurrencyField
            currencyId={baseCurrencyId}
            handleValue={handleOnChangeBaseCurrency}
            labelled={true}
            handlePersist={handleConfigUpdate}
          />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <TextField
            margin="normal"
            label="indexPaper"
            required
            id="indexPaper"
            value={`${config_.indexPaper.symbol}.${config_.indexPaper.exchange}`}
            type="text"
            onChange={handleConfigIndexPaperChanged}
            onBlur={handleConfigUpdate}
          />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <TextField
            margin="normal"
            label="Minimum valuation"
            required
            id="indexPaper"
            value={`${config_.minimumValuationForHolding}`}
            type="number"
            onChange={handleConfigMinimumValuationForAssetChanged}
            onBlur={handleConfigUpdate}
          />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <FormControlLabel
            value="useLiveValue"
            checked={config_.useLiveValue}
            onChange={handleConfigUseLiveValueChanged}
            control={<Switch color="primary" />}
            label="Use live value"
            labelPlacement="start"
          />
        </Box>
        {dashabordConfigElement}
      </Box>
    );
  },
);
