import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

export type SelectedWorkspaceState = string;

export function selectedWorkspaceToSelectedWorkspaceState(
  selectedWorkspace: SelectedWorkspaceState,
) {
  return selectedWorkspace;
}
export const initialSelectedWorkspaceState = null;

const adapter = new EntityAdapter<string>(initialSelectedWorkspaceState);

export const selectedWorkspacesSlice = createBaseSlice(
  'selectedWorkspaces',
  adapter,
);
export const selectedWorkspacesSelectors = adapter.selectors;

export const selectedWorkspacesReducer = selectedWorkspacesSlice.reducer;
export const selectedWorkspacesActions = selectedWorkspacesSlice.actions;
