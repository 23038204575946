import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseContactId,
  contactsSelectors,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/contacts/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getContacts = (state: WorkspaceDataState) => {
  return state.holding.asset.privateEquityFund.contacts;
};

export const getContactsByInvestorId = createCachedSelector(
  (state: WorkspaceDataState) => getContacts(state),
  (_state: WorkspaceDataState, investorId: string) => investorId,
  (contactsState, investorId) => {
    const privateEquityFunds = contactsSelectors.selectElementsByKey(
      contactsState,
      investorId,
    );
    return privateEquityFunds ? privateEquityFunds : [];
  },
)({
  keySelector: (_state, privateEquityInvestorId) => privateEquityInvestorId,
  selectorCreator: createSelector,
});

// export const privateEquityFundByIdSelector = (privateEquityFunds: IContact[], privateEquityInvestorId: string) => {
//   return __OLDsortedFind(privateEquityFunds, { id: privateEquityInvestorId } as IContact, (a) => a.id);
// };

export const getContactById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseContactId>) =>
    getContacts(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseContactId>) =>
    baseId.investorId,
  (_state: WorkspaceDataState, baseId: Partial<BaseContactId>) => baseId.id,
  (contacts, investorId, privateEquityInvestorId) => {
    if (!investorId || !privateEquityInvestorId) {
      return undefined;
    }
    return contactsSelectors.selectElementByT(contacts, {
      investorId,
      id: privateEquityInvestorId,
    });
  },
)({
  keySelector: (_state, investorId, privateEquityInvestorId) =>
    `${investorId}-${privateEquityInvestorId}`,
  selectorCreator: createSelector,
});
