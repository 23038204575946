import { useDispatch } from 'react-redux';
import {
  deleteTransfers,
  updateTransfers,
} from '~src/data/store/modules/holdings/transfers/transfers/requests';
import { AppDispatch } from '~src/store/store';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TransferData } from '~src/domain/workspace/components/project/transfer/project-transfers.component';

export function useTransferActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (oldData: TransferData, newData: TransferData) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceOperations.UpdateTransferInputType = {
          id: newData.transfer.id,
          holdingId: newData.transfer.holdingId,
          transactionId: newData.transfer.transactionId,
          currencyId: newData.transfer.currencyId,
          date: newData.transfer.date,
          description: newData.transfer.description,
          // holdingType: newData.transfer.holdingType,
          cost: newData.transfer.cost,
          type: newData.transfer.type,
          subtype: newData.transfer.subtype,
          status: newData.transfer.status,
          value: newData.transfer.value,
        };
        dispatch(updateTransfers(workspace.tenantId, workspace.id, [input]))
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (oldData: TransferData) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        deleteTransfers(workspace.tenantId, workspace.id, [oldData.transfer]),
      )
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
      if (oldData) {
        resolve();
      } else {
        reject();
      }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onRowUpdate, onRowDelete };
}
