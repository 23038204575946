import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updateWorkspace } from '~src/data/store/modules/workspaces/workspaces/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { EditFormComponent } from './edit-form.component';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
}

export const EditDialogComponent = React.memo((props: Props) => {
  const { handleClose, workspace } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [workspaceInput, setWorkspaceInput] =
    React.useState<graphqlWorkspaceTypes.UpdateWorkspaceInputType>({
      // id: workspace.id,
      name: workspace.name,
    });

  const updateHolding = () => {
    dispatch(
      updateWorkspace(workspace.tenantId, workspace.id, workspaceInput),
    ).then(() => handleClose());
  };

  return (
    <DialogComponent
      dialogProps={{ open: props.open, onClose: handleClose, fullWidth: true }}
    >
      <DialogTitle id="form-dialog-title">Edit workspace</DialogTitle>
      <DialogContent>
        <EditFormComponent
          workspace={workspace}
          workspaceInput={workspaceInput}
          setWorkspaceInput={setWorkspaceInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={updateHolding} color="primary">
          Save
        </Button>
      </DialogActions>
    </DialogComponent>
  );
});
