import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getCryptoById } from '~src/data/store/selectors/asset/asset-types/cryptos/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getCryptoHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/crypto/crypto-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useCryptoHoldingInfo(workspaceId: string, holdingId: string) {
  const cryptoHolding = useSelector((state: RootState) =>
    getCryptoHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const crypto = useSelector((state: RootState) =>
    getCryptoById(selectWorkspaceData(state), {
      id: cryptoHolding ? cryptoHolding.cryptoId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: crypto ? crypto.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    cryptoHolding,
    asset,
    crypto,
  };
}
