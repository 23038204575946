import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  defaultBackgroundColor,
  defaultFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/default-colors';
import { SlateEditor } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/slate/slate-editor';
// import { DashboardTextEditorComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/plate/editor';
import { useTextEditorMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/use-text-editor-menu.hook';
import { Config } from '~src/utils/interfaces/config';
import { UsageMode } from '~src/utils/interfaces/usage-mode';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  config: Config;
}

export const TextEditorElement = React.memo(
  ({
    active,
    config,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useTextEditorMenu(
      element,
      remove,
      updated,
    );
    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? defaultFontColor,
          backgroundColor:
            element.config.backgroundColor ?? defaultBackgroundColor,
          padding: element.config.padding,
        },
        useDragButton: true,
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    const editorUpdate = React.useCallback(
      (data: string) => {
        if (updated) {
          return updated(element.id, {
            config: {
              ...element.config,
              data,
            },
          });
        } else {
          return Promise.resolve({ id: element.id });
        }
      },
      [element.config, element.id, updated],
    );

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <SlateEditor
            update={editorUpdate}
            dataAsString={element.config.data as string}
            readOnly={config.usageMode === UsageMode.view}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
