import moment from 'moment';
import React from 'react';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { useForecastMenu } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-menu.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { VariableValue } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/variable-value';
import { useEvaluationState } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/use-evaluation-state';
import { useForecastExpressionComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/use-forecast-expression.component';
import { SequentialForecastEvaluation } from '~src/domain/workspace/components/project/scenario/models/forecast/sequential-forecast-evaluation';

interface Props {
  scenario: Scenario;
  budget: Budget;
  forecast: ForecastState;
  forecastElement: ExpressionElementState;
}

export const ScenarioVariableNumberExpressionComponent = React.memo(
  (props: Props) => {
    const { scenario, forecastElement: forecastElementState, forecast } = props;
    const menu = useForecastMenu(forecast);

    const { element: evaluationStateElement, setEvaluationState } =
      useEvaluationState();

    const { expressionComponent, forecastElement } =
      useForecastExpressionComponent<VariableValue>(
        scenario,
        forecast,
        forecastElementState,
      );

    const evaluate = React.useCallback(() => {
      try {
        const startDate = moment();
        const endDate = moment(startDate).add(1, 'year');
        const evaluator = new SequentialForecastEvaluation<VariableValue>([
          forecastElement,
        ]);
        const values = evaluator.evaluate(startDate, endDate);
        setEvaluationState(values);
      } catch (e) {
        console.log('error evaluate', e);
      }
    }, [forecastElement, setEvaluationState]);

    return (
      <Grid container>
        <Grid xs={12} sx={{ mb: 1, ml: 1 }}>
          <Button variant="outlined" onClick={evaluate}>
            Evaluate
          </Button>
          {menu.menuButtonElement}
          {menu.menuElement}
        </Grid>
        <Grid xs={12}>{expressionComponent}</Grid>
        {evaluationStateElement}
      </Grid>
    );
  },
);
