import * as React from 'react';
import { useSelector } from 'react-redux';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPhonesByPersonId } from '~src/data/store/selectors/person/phone/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import AddIcon from '@mui/icons-material/Add';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CreatePhoneDialogComponent } from './form/create-dialog.component';
import { PhoneComponent } from './phone.component';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
  },
  addButton: {
    float: 'right',
    right: theme.spacing(2),
  },
  phoneIcon: {
    textAlign: 'center',
  },
}));

export const PhonesComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const phones = useSelector((state: RootState) =>
    getPhonesByPersonId(selectWorkspaceData(state), props.person.id),
  );
  const [createPhoneDialogOpened, setCreatePhoneDialogOpened] =
    React.useState(false);

  const phoneElements =
    phones &&
    phones.map((phone) => {
      return (
        <ListItem key={phone.id}>
          <PhoneComponent
            workspace={props.workspace}
            person={props.person}
            phone={phone}
          />
        </ListItem>
      );
    });

  function handleAddPhone() {
    setCreatePhoneDialogOpened(true);
  }
  function handleCloseCreatePhoneDialog() {
    setCreatePhoneDialogOpened(false);
  }

  return (
    <Paper className={classes.root}>
      <Box>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label="add"
              onClick={handleAddPhone}
              className={classes.addButton}
            >
              <AddIcon />
            </IconButton>
            <CreatePhoneDialogComponent
              open={createPhoneDialogOpened}
              handleClose={handleCloseCreatePhoneDialog}
              tenantId={props.workspace.tenantId}
              workspace={props.workspace}
              person={props.person}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2} md={2} className={classes.phoneIcon}>
            <PhoneIcon />
          </Grid>
          <Grid item xs={10} md={10}>
            {/* <div>Add phone number</div> */}
            <List dense={true}>{phoneElements}</List>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
