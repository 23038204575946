import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { EditEntities } from '~src/components/fields/workspace/entity/entity/edit-entities';
import { EditHoldings } from '~src/components/fields/workspace/holding/holding/edit-holdings';
import { EditTransactions } from '~src/components/fields/workspace/holding/transaction/edit-transactions';
import { EditPortfolios } from '~src/components/fields/workspace/project/portfolio/edit-portfolios';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntitiesByIds } from '~src/data/store/selectors/entities/entities/selectors';
import { getHoldingsByIdsForProject } from '~src/data/store/selectors/holding/holdings/selectors';
import { getTransactionsByIds } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { getPortfoliosByIds } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EventInput } from '~src/domain/workspace/components/project/event/form/event-input';
import { useHandler } from '~src/hooks/utils/use-handler';

import { TextField, Theme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  eventInput: EventInput;
  setEventInput: (eventInput: EventInput) => void;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
  },
}));

export const useEventFieldsComponent = (props: Props) => {
  const { workspace, project, eventInput, setEventInput } = props;
  const { classes } = useStyles();

  const { handleEvent, handleUpdate } = useHandler(eventInput, setEventInput);
  const nameElement = React.useMemo(
    () => (
      <TextField
        autoFocus
        margin="normal"
        fullWidth
        className={classes.textField}
        required
        id="name"
        value={eventInput.name}
        label="Name"
        type="text"
        onChange={handleEvent('name')}
      />
    ),
    [classes.textField, handleEvent, eventInput.name],
  );
  const descriptionElement = React.useMemo(
    () => (
      <TextField
        margin="normal"
        multiline
        fullWidth
        className={classes.textField}
        required
        id="description"
        value={eventInput.description}
        label="Description"
        type="text"
        onChange={handleEvent('description')}
      />
    ),
    [classes.textField, handleEvent, eventInput.description],
  );

  const dateElement = React.useMemo(
    () => (
      <DatePicker
        showToolbar={false}
        inputFormat="DD/MM/YYYY"
        label="Date"
        value={eventInput.date}
        // value={null}
        onChange={
          (date: moment.Moment | null) => handleUpdate('date')(date?.toDate())
          // field.onChange(date?.toDate())
        }
        // disabled={disabled}
        renderInput={(params) => (
          <TextField
            margin="normal"
            {...params}
            fullWidth
            // error={!!fieldState.error}
            // helperText={fieldState.error ? fieldState.error.message : null}
          />
        )}
      />
    ),
    [eventInput.date, handleUpdate],
  );
  const holdings = useSelector((state: RootState) =>
    getHoldingsByIdsForProject(selectWorkspaceData(state), {
      projectId: project.id,
      ids: eventInput.holdingIds,
    }),
  );
  const holdingsElement = React.useMemo(() => {
    return (
      <EditHoldings
        handleValue={(holdings) =>
          handleUpdate('holdingIds')(holdings.map((h) => h.id))
        }
        holdings={holdings}
        projectId={project.id}
        workspace={workspace}
        label="Holdings"
      />
    );
  }, [handleUpdate, holdings, project.id, workspace]);

  const entities = useSelector((state: RootState) =>
    getEntitiesByIds(
      selectWorkspaceData(state),
      eventInput.entityIds.map((id) => {
        return {
          id,
          workspaceId: workspace.id,
        };
      }),
    ),
  );

  const entitiesElement = React.useMemo(() => {
    return (
      <EditEntities
        handleValue={(entities) =>
          handleUpdate('entityIds')(entities.map((h) => h.id))
        }
        entities={entities}
        workspace={workspace}
        label="Entities"
      />
    );
  }, [handleUpdate, entities, workspace]);

  const portfolios = useSelector((state: RootState) =>
    getPortfoliosByIds(
      selectWorkspaceData(state),
      eventInput.portfolioIds.map((id) => {
        return {
          id,
          workspaceId: workspace.id,
        };
      }),
    ),
  );

  const portfoliosElement = React.useMemo(() => {
    return (
      <EditPortfolios
        handleValue={(portfolios) =>
          handleUpdate('portfolioIds')(portfolios.map((h) => h.id))
        }
        portfolios={portfolios}
        workspace={workspace}
        labelled
        projectId={project.id}
      />
    );
  }, [handleUpdate, portfolios, project.id, workspace]);

  const transactions = useSelector((state: RootState) =>
    getTransactionsByIds(selectWorkspaceData(state), {
      projectId: project.id,
      ids: eventInput.transactionIds,
    }),
  );

  const transactionsElement = React.useMemo(() => {
    return (
      <EditTransactions
        handleValue={(transactions) =>
          handleUpdate('transactionIds')(transactions.map((h) => h.id))
        }
        transactions={transactions}
        workspace={workspace}
        project={project}
        label="Transactions"
      />
    );
  }, [handleUpdate, transactions, project, workspace]);

  return {
    nameElement,
    descriptionElement,
    dateElement,
    holdingsElement,
    entitiesElement,
    portfoliosElement,
    transactionsElement,
  };
};
