import { selectedTenantIdActions } from '~src/data/store/reducers/tenant/selected-tenant/reducer';
import { tenantsActions } from '~src/data/store/reducers/tenant/tenants/reducer';
import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryTenantContext,
} from '@pladdenico/portfolio-api';

import { unselectWorkspace } from '../../workspaces/workspaces/actions';
import { handleTenant } from './handler';

export function fetchTenant(tenantId: string) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.GetTenantDocument;
    const variables: graphqlTenantTypes.GetTenantQueryVariables = {};
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getTenant) {
        return handleTenant(dispatch, data.getTenant);
      }
      return undefined;
    });
  };
}

export function createTenant(
  tenantId: string,
  input: graphqlTenantTypes.CreateTenantInputType,
) {
  input.id = tenantId;
  return (dispatch: any) => {
    const node = graphqlTenantTypes.CreateTenantDocument;
    const variables: graphqlTenantTypes.CreateTenantMutationVariables = {
      input,
    };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createTenant) {
        return handleTenant(dispatch, data.createTenant);
      }
      return undefined;
    });
  };
}

export function updateTenant(
  tenantId: string,
  input: graphqlTenantTypes.UpdateTenantInputType,
) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.UpdateTenantDocument;
    const variables: graphqlTenantTypes.UpdateTenantMutationVariables = {
      input,
    };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateTenant) {
        return handleTenant(dispatch, data.updateTenant);
      }
    });
  };
}

export function deleteTenant(tenantId: string) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.DeleteTenantDocument;
    const variables: graphqlTenantTypes.DeleteTenantMutationVariables = {};
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteTenant) {
        dispatch(tenantsActions.removeOne({ id: data.deleteTenant }));
      }
      return [];
    });
  };
}

export function selectTenant(tenantId: string) {
  return (dispatch: any) => {
    // dispatch(selectTenantIdAction(tenant.id));
    dispatch(selectedTenantIdActions.set(tenantId));
    // dispatch(unselectTenantRoleAction());
    dispatch(unselectWorkspace());
  };
}

export function unselectTenant() {
  return (dispatch: any) => {
    // dispatch(unselectTenantAction());
    dispatch(selectedTenantIdActions.unset(undefined));
    // dispatch(unselectTenantRoleAction());
    dispatch(unselectWorkspace());
  };
}
