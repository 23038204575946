import * as React from 'react';
import { useSelector } from 'react-redux';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEmailsByPersonId } from '~src/data/store/selectors/person/email/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { EmailComponent } from './email.component';
import { CreateEmailDialogComponent } from './form/create-dialog.component';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
  },
  addButton: {
    float: 'right',
    right: theme.spacing(2),
  },
  emailIcon: {
    textAlign: 'center',
  },
}));

export const EmailsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const emails = useSelector((state: RootState) =>
    getEmailsByPersonId(selectWorkspaceData(state), props.person.id),
  );
  const [createEmailDialogOpened, setCreateEmailDialogOpened] =
    React.useState(false);

  const emailElements =
    emails &&
    emails.map((email, index) => {
      return (
        <ListItem key={index}>
          <EmailComponent
            workspace={props.workspace}
            person={props.person}
            email={email}
          />
        </ListItem>
      );
    });

  function handleAddEmail() {
    setCreateEmailDialogOpened(true);
  }
  function handleCloseCreateEmailDialog() {
    setCreateEmailDialogOpened(false);
  }

  return (
    <Paper className={classes.root}>
      <Box>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label="add"
              onClick={handleAddEmail}
              className={classes.addButton}
            >
              <AddIcon />
            </IconButton>
            <CreateEmailDialogComponent
              open={createEmailDialogOpened}
              handleClose={handleCloseCreateEmailDialog}
              tenantId={props.workspace.tenantId}
              workspace={props.workspace}
              person={props.person}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2} md={2} className={classes.emailIcon}>
            <EmailIcon />
          </Grid>
          <Grid item xs={10} md={10}>
            {/* <div>Add email number</div> */}
            <List dense={true}>{emailElements}</List>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
