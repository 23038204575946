import * as React from 'react';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { WorkspaceDrawerAssetsItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-assets-item.component';
import { WorkspaceDrawerDataItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-data-item.component';
import { WorkspaceDrawerEntitiesItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-entities-item.component';
import { WorkspaceFinanceItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-finance-item.component';
import { WorkspaceGraphQLItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-graph-ql-item.component';
import { WorkspaceFileItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-file-item.component';
import { WorkspaceDrawerMembersItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-members-item.component';
import { WorkspaceDrawerPeopleItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-people-item.component';
import { WorkspaceDrawerSettingsItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-settings-item.component';
import {} from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';
import { DrawerType } from '~src/utils/common/use-drawer';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, colors, CSSObject, Theme } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { WorkspaceDrawerProjectsItemComponent } from '~src/domain/workspace/components/drawer/items/drawer-projects-item.component';

export const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const PREFIX = 'Drawer';
const classes = {
  listItem: `${PREFIX}-listItem`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};
const DrawerContentList = styled(List)(({ theme }) => ({
  [`&.${classes.listItem}`]: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  [`&.${classes.button}`]: {
    color: colors.blueGrey[800],
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  [`&.${classes.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface Props {
  drawer: DrawerType;
  tenant: TenantState;
  workspace: WorkspaceState;
}

export const WorkspaceDrawerComponent = React.memo((props: Props) => {
  const { drawer, tenant, workspace } = props;

  const drawerContent = (
    <>
      <DrawerContentList sx={{ pt: 0 }}>
        <WorkspaceDrawerProjectsItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <WorkspaceDrawerMembersItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <WorkspaceDrawerEntitiesItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <WorkspaceDrawerAssetsItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <WorkspaceDrawerPeopleItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <WorkspaceDrawerSettingsItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <WorkspaceDrawerDataItemComponent
          tenant={tenant}
          workspace={workspace}
        />
        <Divider />
        <WorkspaceFinanceItemComponent tenant={tenant} workspace={workspace} />
        <WorkspaceGraphQLItemComponent tenant={tenant} workspace={workspace} />
        <WorkspaceFileItemComponent tenant={tenant} workspace={workspace} />
      </DrawerContentList>
      <Divider />
    </>
  );

  const drawerButton = (
    <IconButton
      onClick={drawer.toggleDrawer}
      sx={{
        ml: 2,
        transition: (theme) =>
          theme.transitions.create('all', {
            duration: theme.transitions.duration.standard,
          }),
      }}
    >
      {drawer.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );

  return (
    <Drawer
      variant="permanent"
      open={drawer.open}
      sx={{
        background: primaryBackgroundColor.veryLight,
        // '&& .Mui-selected': {
        //   backgroundColor: 'primaryBackgroundColor.medium',
        // },
      }}
      // classes={{ paper: styles.classes.paper }}
    >
      <Box sx={{ position: 'relative' }}>
        <DrawerHeader />
        {drawerButton}
        <Divider />
        {drawerContent}
      </Box>
    </Drawer>
  );
});
