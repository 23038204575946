import { fetchAndHandleWorkspace } from '~src/data/store/modules/workspaces/workspaces/requests';
import { exportsActions } from '~src/data/store/reducers/workspace/exports/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export const fetcher = (workspace: WorkspaceState, id: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      fetchAndHandleWorkspace(
        workspace.tenantId,
        workspace.id,
        (
          dispatch: any,
          _tenantId: string,
          outputs: graphqlWorkspaceTypes.Workspace,
          _subscriptions: Operation[],
        ) => {
          return dispatch(
            exportsActions.addOne({
              id,
              blob: `${JSON.stringify(outputs)}`,
            }),
          );
        },
      ),
    );
  };
};
