import { EntityData } from '~src/domain/workspace/components/project/portfolio/entities/entities.component';

import { TableField } from '@pladdenico/table';

export function useEntityFieldsWithDetails(
  _tenantId: string,
  _workspaceId: string,
) {
  const fields = {
    name: {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data: EntityData) => data.entity?.name,
    },
    description: {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data: EntityData) => data.entity?.description,
    },
    sector: {
      id: 'sector',
      name: 'sector',
      field: 'sector',
      type: TableField.FieldTypes.string,
      iteratee: (data: EntityData) => data.entity?.sector,
    },
    website: {
      id: 'website',
      name: 'website',
      field: 'website',
      type: TableField.FieldTypes.string,
      iteratee: (data: EntityData) => data.entity?.website,
    },
  };
  return fields;
}

export type EntityFieldsWithDetails = ReturnType<
  typeof useEntityFieldsWithDetails
>;
