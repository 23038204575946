import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { AllocationState } from '~src/data/store/reducers/portfolio/allocations/reducer';
import { getAllocationsByPortfolioId } from '~src/data/store/selectors/portfolio/allocations/selectors';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { AllocationsComponent } from './allocations.component';
import { Identity } from '~src/utils/interfaces/identity';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  portfolio: PortfolioState;
}

export interface AllocationData extends Identity {
  allocation: AllocationState;
  share: number;
}

export const PortfolioAllocationsComponent = React.memo((props: Props) => {
  const allocations = useSelector((state: RootState) =>
    getAllocationsByPortfolioId(selectWorkspaceData(state), props.portfolio.id),
  );

  const allocationCreateInputInit: graphqlWorkspaceTypes.CreateAllocationInputType =
    {
      id: uuid.v1(),
      currencyId: '',
      portfolioId: props.portfolio.id,
      assetClass: '',
      assetType: '',
      category: '',
      max: 1,
      min: 0,
      regionId: '',
    };
  return (
    <AllocationsComponent
      projectId={props.projectId}
      allocationCreateInputInit={allocationCreateInputInit}
      allocations={allocations}
      workspace={props.workspace}
      portfolio={props.portfolio}
    />
  );
});
