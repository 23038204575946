import { uniqueId } from 'lodash';
import { portfolioProjectRequestStatusActions } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-project-fetch/reducer';
import { portfolioProjectsActions } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { GraphqlResultStatusHandler } from '~src/services/request/graphql-result-status-handler';
import { ReducerActionRequestStatusHandler } from '~src/services/request/request-status-handler';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handlePortfolioProjects } from './handler';

function createResultHandler<Q, V>(dispatch: AppDispatch, id: string) {
  return new GraphqlResultStatusHandler<Q, V>(
    id,
    new ReducerActionRequestStatusHandler(
      dispatch,
      portfolioProjectRequestStatusActions,
    ),
  );
}

export function fetchPortfolioProjects(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPortfolioProjectsDocument;
    const variables: graphqlWorkspaceTypes.GetPortfolioProjectsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler = createResultHandler<
      graphqlWorkspaceTypes.GetPortfolioProjectsQuery,
      graphqlWorkspaceTypes.GetPortfolioProjectsQueryVariables
    >(dispatch, uniqueId());
    return investRequest(node, variables, context, resultHandler).then(
      (data) => {
        if (data.getPortfolioProjects) {
          return handlePortfolioProjects(
            dispatch,
            tenantId,
            workspaceId,
            data.getPortfolioProjects,
            [
              Operation.create,
              Operation.delete,
              Operation.update,
              Operation.upsert,
            ],
          );
        }
        return undefined;
      },
    );
  };
}

export function fetchPortfolioProjectByProjectId(
  tenantId: string,
  workspaceId: string,
  projectId: string,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPortfolioProjectByProjectIdDocument;
    const variables: graphqlWorkspaceTypes.GetPortfolioProjectByProjectIdQueryVariables =
      {
        projectId,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler = createResultHandler<
      graphqlWorkspaceTypes.GetPortfolioProjectByProjectIdQuery,
      graphqlWorkspaceTypes.GetPortfolioProjectByProjectIdQueryVariables
    >(dispatch, projectId);
    return investRequest(node, variables, context, resultHandler).then(
      (data) => {
        if (data.getPortfolioProjectByProjectId) {
          return handlePortfolioProjects(
            dispatch,
            tenantId,
            workspaceId,
            [data.getPortfolioProjectByProjectId],
            [
              Operation.create,
              Operation.delete,
              Operation.update,
              Operation.upsert,
            ],
          );
        }
        return undefined;
      },
    );
  };
}

export function createPortfolioProjects(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreatePortfolioProjectInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreatePortfolioProjectsDocument;
    const variables: graphqlWorkspaceTypes.CreatePortfolioProjectsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler = createResultHandler<
      graphqlWorkspaceTypes.CreatePortfolioProjectsMutation,
      graphqlWorkspaceTypes.CreatePortfolioProjectsMutationVariables
    >(dispatch, uniqueId());
    return investRequest(node, variables, context, resultHandler).then(
      (data) => {
        if (data.createPortfolioProjects) {
          return handlePortfolioProjects(
            dispatch,
            tenantId,
            workspaceId,
            data.createPortfolioProjects,
            [Operation.delete, Operation.update, Operation.upsert],
          );
        }
        return [];
      },
    );
  };
}

export function updatePortfolioProjects(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdatePortfolioProjectInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdatePortfolioProjectsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePortfolioProjectsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler = createResultHandler<
      graphqlWorkspaceTypes.UpdatePortfolioProjectsMutation,
      graphqlWorkspaceTypes.UpdatePortfolioProjectsMutationVariables
    >(dispatch, uniqueId());
    return investRequest(node, variables, context, resultHandler).then(
      (data) => {
        if (data.updatePortfolioProjects) {
          return handlePortfolioProjects(
            dispatch,
            tenantId,
            workspaceId,
            data.updatePortfolioProjects,
            [Operation.delete, Operation.update, Operation.upsert],
          );
        }
        return [];
      },
    );
  };
}

export function upsertPortfolioProjects(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertPortfolioProjectInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertPortfolioProjectsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPortfolioProjectsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler = createResultHandler<
      graphqlWorkspaceTypes.UpsertPortfolioProjectsMutation,
      graphqlWorkspaceTypes.UpsertPortfolioProjectsMutationVariables
    >(dispatch, uniqueId());
    return investRequest(node, variables, context, resultHandler).then(
      (data) => {
        if (data.upsertPortfolioProjects) {
          return handlePortfolioProjects(
            dispatch,
            tenantId,
            workspaceId,
            data.upsertPortfolioProjects,
            [Operation.delete, Operation.update, Operation.upsert],
          );
        }
        return [];
      },
    );
  };
}

export function deletePortfolioProjects(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeletePortfolioProjectsDocument;
    const variables: graphqlWorkspaceTypes.DeletePortfolioProjectsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler = createResultHandler<
      graphqlWorkspaceTypes.DeletePortfolioProjectsMutation,
      graphqlWorkspaceTypes.DeletePortfolioProjectsMutationVariables
    >(dispatch, uniqueId());
    return investRequest(node, variables, context, resultHandler).then(
      (data) => {
        if (data.deletePortfolioProjects) {
          dispatch(
            portfolioProjectsActions.removeManyElements(
              data.deletePortfolioProjects.map((p) => {
                return {
                  workspaceId,
                  id: p,
                };
              }),
            ),
          );
        }
        return [];
      },
    );
  };
}
