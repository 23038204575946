import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseEntityId {
  id: string;
  workspaceId: string;
}

export type EntityState = Omit<
  graphqlWorkspaceTypes.Entity,
  'workspace' | 'regionAllocations' | 'media'
> & { workspaceId: string; tenantId: string };

export function entityToEntityState(entity: EntityState) {
  return entity;
}

export const initialEntitysState: RecordState<EntityState, string>[] = [];

const selectKeyId = (entity: BaseEntityId) => entity.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseEntityId, b: BaseEntityId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseEntityId, EntityState, string>(
  initialEntitysState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const entitiesSlice = createBaseRecordSlice('entities', adapter);
export const entitiesSelectors = adapter.selectors;

export const entitiesReducer = entitiesSlice.reducer;
export const entitiesActions = entitiesSlice.actions;
export const entitiesSlices = entitiesSlice.slices;
