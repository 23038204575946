import { combineReducers } from 'redux';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { RecordState, strcmp } from '@pladdenico/common';

interface TCategoryId {
  id: string;
}

export interface BaseCategoryId extends TCategoryId {
  workspaceId: string;
}

export type AssetCategoryState = graphqlWorkspaceTypes.AssetCategory & {
  workspaceId: string;
};

export function categoryToCategoryState(category: AssetCategoryState) {
  return category;
}
const initialState: RecordState<AssetCategoryState, string>[] = [];

const selectKeyId = (category: BaseCategoryId) => category.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: TCategoryId, b: TCategoryId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseCategoryId,
  AssetCategoryState,
  string,
  TCategoryId
>(initialState, selectKeyId, keyComparator, tComparator);

export const categoriesSlice = createBaseRecordSlice('categories', adapter);
export const categoriesSelectors = adapter.selectors;

export const categoriesReducer = categoriesSlice.reducer;
export const categoriesActions = categoriesSlice.actions;

export const initialCategoryState = {
  categories: initialState,
};

export const categoryReducer = combineReducers({
  categories: categoriesReducer,
});
