import { Tab } from '~src/utils/interfaces/tab';

import { PrivateEquityFundProjectTabs } from './tabs.component';

function createTabs(): Tab<PrivateEquityFundProjectTabs>[] {
  const tabs: Tab<PrivateEquityFundProjectTabs>[] = [];

  {
    const path = {
      tab: PrivateEquityFundProjectTabs.Dashboard,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/dashboard',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.Setup,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/setup',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.Investments,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/investments',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.Investors,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/investors',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.CapitalCalls,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/capitalCalls',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.Transactions,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/transactions',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.Transfers,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/transfers',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PrivateEquityFundProjectTabs.InvestorPortal,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/investor-portal',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  return tabs;
}

export function usePrivateEquityFundProjectTabs() {
  return createTabs();
}
