import * as React from 'react';
import { ExportHandler } from '~src/services/xlsx/handlers/ioutput-handler';
import { xlsxTypes } from '~src/services/xlsx/xlsx-types';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  // handlers: Map<string, IExcelExportHandler> | undefined;
  handlers: ExportHandler<any>[] | undefined;
  getData?: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  // placeholder: {
  //   height: 40,
  // },
}));

const useAlertStyles = makeStyles()((_theme) => ({
  root: {
    // flex: 1,
  },
  message: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 40,
  },
}));

const xlsxTypeOptions = xlsxTypes.map((xlsxType) => {
  return {
    key: xlsxType,
    value: xlsxType,
    text: xlsxType,
  };
});

enum ExportStatus {
  notStarted,
  exporting,
  exported,
}

export const DataOutput = (props: Props) => {
  const { classes } = useStyles();
  const { classes: alertClasses } = useAlertStyles();
  const [file, setFile] = React.useState({
    name: 'export',
    type: 'xlsx',
  });
  const [exportStatus, setExportStatus] = React.useState<ExportStatus>(
    ExportStatus.notStarted,
  );

  const createFile = React.useCallback(
    (handlers: ExportHandler<any>[]) => {
      return import('xlsx').then((XLSX) => {
        const wb = XLSX.utils.book_new();
        handlers.forEach((handler) => {
          const rows = handler.data;
          const header = handler.header;
          const ws = XLSX.utils.json_to_sheet(rows, {
            header: header as string[],
            cellDates: true,
          });
          XLSX.utils.book_append_sheet(wb, ws, handler.type);
        });
        XLSX.writeFile(wb, `${file.name}.${file.type}`);
      });
    },
    [file.name, file.type],
  );

  React.useEffect(() => {
    if (props.handlers && exportStatus === ExportStatus.exporting) {
      setExportStatus(ExportStatus.exported);
      createFile(props.handlers);
    }
  }, [createFile, exportStatus, props.handlers]);

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setFile((file) => {
      return { ...file, name };
    });
  };

  const handleOnClick = (_e: any) => {
    props.getData && props.getData();
    setExportStatus(ExportStatus.exporting);
  };

  const handleOnDropdownChange = (event: SelectChangeEvent<string>) => {
    const type = event.target.value as string;
    setFile((file) => {
      return { ...file, type };
    });
  };
  const menuItems = xlsxTypeOptions.map((option: any) => {
    return (
      <MenuItem key={option.key} value={option.value}>
        {option.text}
      </MenuItem>
    );
  });
  const exportingStatus = (
    <div className={classes.root}>
      {/* {exportStatus === ExportStatus.notStarted && (
        <Alert severity="success" color="info" classes={alertClasses}>
          <Typography className={classes.placeholder}>Exporting</Typography>
          <CircularProgress size={20} />
        </Alert>
      )} */}
      {exportStatus === ExportStatus.exporting && (
        <Alert severity="success" color="info" classes={alertClasses}>
          <Typography>Exporting</Typography>
          <CircularProgress size={20} />
        </Alert>
      )}
      {exportStatus === ExportStatus.exported && (
        <Alert
          onClose={() => setExportStatus(ExportStatus.notStarted)}
          classes={alertClasses}
        >
          <Typography>Export finished</Typography>
        </Alert>
      )}
    </div>
  );

  return (
    <Box sx={{ mt: 1 }}>
      <form>
        <Grid2 container justifyContent="space-between" columnSpacing={1}>
          <Grid2 xs={6}>
            <TextField
              fullWidth
              label="Filename"
              value={file.name}
              placeholder="name..."
              onChange={handleOnChange}
              sx={{ mr: 1 }}
            />
          </Grid2>
          <Grid2 xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">File type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="File type"
                id="demo-simple-select"
                value={file.type}
                onChange={handleOnDropdownChange}
                sx={{ mr: 1 }}
              >
                {menuItems}
              </Select>
            </FormControl>
          </Grid2>
        </Grid2>
        <Grid2 container mt={1}>
          <Grid2>
            <Button onClick={handleOnClick} variant="contained" color="primary">
              Export
            </Button>
          </Grid2>
        </Grid2>
        <Grid2 container>
          <Grid2 xs={12}>{exportingStatus}</Grid2>
        </Grid2>
      </form>
    </Box>
  );
};
