import React from 'react';
import {
  ExpressionType,
  ExpressionTypes,
  typeToString,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/expression-type';
// import { createStockReturnExpression } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/types/stock-return';
// import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export const useExpressionType = (expressionType: ExpressionType) =>
  // updateExpressions: (expressions: Expression[]) => void
  {
    const [type, setType] = React.useState<ExpressionType>(expressionType);
    // const [expressions, setExpressions] = React.useState<Expression[]>([]);

    const handleExpressionTypeChange = (
      event: SelectChangeEvent<ExpressionType>,
    ) => {
      const type = event.target.value as ExpressionType;
      setType(type);
      // let expressions: Expression[] = [];
      // if (type === ExpressionTypes.StockReturn) {
      //   expressions = createStockReturnExpression();
      // }

      // updateExpressions(expressions);
    };

    const menuItems = Object.values(ExpressionTypes).map((type) => {
      return (
        <MenuItem key={type} value={type}>
          {typeToString(type)}
        </MenuItem>
      );
    });

    const element = (
      <FormControl fullWidth margin="normal">
        <InputLabel id="expression-type">Expression type</InputLabel>
        <Select
          labelId="expression-type"
          value={type}
          label="Expression type"
          onChange={handleExpressionTypeChange}
        >
          {menuItems}
        </Select>
      </FormControl>
    );

    return {
      element,
      type,
    };
  };
