import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateEntities } from '~src/data/store/modules/entities/entities/requests';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputData } from '~src/domain/workspace/components/entity/entity/form/entity-input.data';
import { useEntityFields } from '~src/domain/workspace/components/entity/entity/form/use-entity-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  entity: EntityState;
  inputData?: InputData;
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  };
}

export const useEntityForm = (props: Props) => {
  const { entity, workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputData) => {
      return dispatch(
        updateEntities(workspace.tenantId, workspace.id, [
          {
            id: entity.id,
            name: data.name,
            description: data.description,
            sector: data.sector,
            website: data.website,
          },
        ]),
      )
        .then(() => callbacks?.onSuccess && callbacks.onSuccess())
        .catch((err) => callbacks?.onFailure && callbacks.onFailure(err));
    },
    [dispatch, workspace.tenantId, workspace.id, entity.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<InputData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useEntityFields(workspace, control);

  const onSubmit: SubmitHandler<InputData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return fields;
};
