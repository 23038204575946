import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  FundPositionState,
  BaseFundPositionId,
  selectors as fundPositionsSelectors,
} from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';
import { getLastDate } from '~src/utils/common/date-utils';

const getFundPositionState = (state: WorkspaceDataState) =>
  state.holding.holding.fundHolding.fundPositions;

export const getFundPositionsByFundHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getFundPositionState(state),
  (_state: WorkspaceDataState, fundHoldingId: string) => fundHoldingId,
  (fundPositionState, fundHoldingId): FundPositionState[] => {
    return (
      fundPositionsSelectors.selectElementsByKey(
        fundPositionState,
        fundHoldingId,
      ) ?? []
    );
  },
)({
  keySelector: (_state, fundHoldingId) => fundHoldingId,
  selectorCreator: createSelector,
});

export const getFundPositions = createSelector(
  (state: WorkspaceDataState) => getFundPositionState(state),
  (fundPositions) => {
    return fundPositionsSelectors.selectAllElements(fundPositions);
  },
);

export const getFundPositionById = createCachedSelector(
  (state: WorkspaceDataState) => getFundPositionState(state),
  (_state: WorkspaceDataState, baseId: BaseFundPositionId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseFundPositionId) =>
    baseId.fundHoldingId,
  (fundPositions, id, fundHoldingId): FundPositionState | undefined => {
    return fundPositionsSelectors.selectElementByT(fundPositions, {
      id: id,
      fundHoldingId,
    });
  },
)({
  keySelector: (_state, fundHoldingId, fundPositionId) =>
    `${fundHoldingId}:${fundPositionId}`,
  selectorCreator: createSelector,
});

interface FundHoldingMultipleIds {
  fundHoldingId: string;
  ids: string[];
}

export const getFundPositionsByIds = createCachedSelector(
  (state: WorkspaceDataState) => getFundPositionState(state),
  (_state: WorkspaceDataState, ids: FundHoldingMultipleIds) =>
    ids.fundHoldingId,
  (_state: WorkspaceDataState, ids: FundHoldingMultipleIds) => ids.ids,
  (fundPositions, fundHoldingId, ids): FundPositionState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        fundHoldingId,
      };
    });
    return fundPositionsSelectors.selectElementsByTs(fundPositions, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.fundHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

export const getLastPosition = (fundHoldingId: string) => {
  const fundPositions = getFundPositionsByFundHoldingId(
    selectWorkspaceData(store.getState()),
    fundHoldingId,
  );
  let lastFundPosition: FundPositionState | undefined = undefined;
  if (fundPositions.length > 0) {
    lastFundPosition = fundPositions.reduce((prev, cur) => {
      if (prev.date.getTime() < cur.date.getTime()) {
        return cur;
      }
      return prev;
    }, fundPositions[0]);
  }
  return lastFundPosition;
};

export const selectLastPosition = createCachedSelector(
  (state: WorkspaceDataState, FundHoldingId: string) =>
    getFundPositionsByFundHoldingId(state, FundHoldingId),
  (FundPositions): FundPositionState | undefined => {
    return getLastDate(FundPositions, (position) => position.date);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });
