// import { sortedUniq } from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import React from 'react';
import { useSelector } from 'react-redux';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState, RootState } from '~src/data/store/reducers/reducers';
import { getHoldingGroups } from '~src/data/store/selectors/finance/holding-groups';
import { getHoldingGroupValue } from '~src/data/store/selectors/finance/valuation/holding-group-value';
import { selectData } from '~src/data/store/selectors/selectors';
import { Filter, filterToString, isIn } from '~src/utils/common/filter';

import { Data } from './data';
import { createSelector } from 'reselect';

interface DataProps {
  workspaceId: string;
  projectId: string;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
  filter: Filter;
  showFilteredOut: boolean;
  filterKey: string;
  date: moment.Moment;
  currency: CurrencyState;
  minValuationPercent: number;
  useMarketValue: boolean;
}

const getData = createCachedSelector(
  (state: DataState) => state,
  (_state: DataState, props: DataProps) => props.workspaceId,
  (_state: DataState, props: DataProps) => props.projectId,
  (_state: DataState, props: DataProps) => props.holdingGroups,
  (_state: DataState, props: DataProps) => props.filter,
  (_state: DataState, props: DataProps) => props.showFilteredOut,
  (_state: DataState, props: DataProps) => props.filterKey,
  (_state: DataState, props: DataProps) => props.date,
  (_state: DataState, props: DataProps) => props.currency,
  (_state: DataState, props: DataProps) => props.minValuationPercent,
  (_state: DataState, props: DataProps) => props.useMarketValue,
  (
    state,
    workspaceId,
    projectId,
    holdingGroups,
    filter,
    showFilteredOut,
    filterKey,
    date,
    baseCurrency,
    _minValuationPercent,
    useMarketValue,
  ) => {
    return createData(
      state,
      workspaceId,
      projectId,
      holdingGroups,
      filter,
      showFilteredOut,
      filterKey,
      date,
      baseCurrency,
      useMarketValue,
    );
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}-${props.projectId}-${filterToString(props.filter)}-${
      props.showFilteredOut
    }-${props.filterKey}`,
  selectorCreator: createSelector,
});

function createData(
  state: DataState,
  workspaceId: string,
  projectId: string,
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>,
  filter: Filter,
  showFilteredOut: boolean,
  filterKey: string,
  date: moment.Moment,
  baseCurrency: CurrencyState,
  useMarketValue: boolean,
): Data[] {
  const data: Data[] = [];
  holdingGroups.forEach((group, filterValue) => {
    const filteredOut = !isIn(filter, {
      key: filterKey,
      value: filterValue,
    });
    if (!filteredOut || showFilteredOut) {
      const value = getHoldingGroupValue(state, {
        workspaceId,
        projectId,
        currency: baseCurrency,
        date,
        filter,
        holdings: group.holdings,
        useMarketValue, // XXX: Should come from config.
        filterValue,
        filterKey,
      });
      data.push({
        key: filterKey,
        name: group.name,
        id: filterValue,
        value, // : holdings.value(date.toDate(), exchangeRateState, baseCurrency),
        filteredOut,
      });
    }
  });
  return data;
}

export function useAllocationData(
  workspaceId: string,
  projectId: string,
  filterKey: string,
  date: moment.Moment,
  holdings: HoldingState[],
  baseCurrency: CurrencyState,
  useMarketValue: boolean,
  filter: Filter,
  showFilteredOut: boolean,
) {
  // const [showFilteredOut, setShowFilteredOut] = React.useState(true);
  const [minValuationPercent, setMinValuationPercent] = React.useState(5);

  const holdingGroupsProps = React.useMemo(() => {
    return {
      workspaceId,
      projectId,
      date,
      filterKey,
      holdings,
    };
  }, [date, filterKey, holdings, projectId, workspaceId]);

  const holdingGroups = useSelector((state: RootState) => {
    return getHoldingGroups(selectData(state), holdingGroupsProps);
  });

  const data = useSelector((state: RootState) => {
    return getData(selectData(state), {
      workspaceId,
      projectId,
      holdingGroups,
      filter,
      showFilteredOut,
      filterKey,
      date,
      currency: baseCurrency,
      minValuationPercent,
      useMarketValue,
    });
  });

  return {
    data,
    filter,
    minValuationPercent,
    setMinValuationPercent,
  };
}
