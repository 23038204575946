import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseBondId,
  bondsSelectors,
} from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getBonds = (state: WorkspaceDataState) => {
  return state.holding.asset.bond.bonds;
};

export const getBondsByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getBonds(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (bondsState, workspaceId) => {
    const bonds = bondsSelectors.selectElementsByKey(bondsState, workspaceId);
    return bonds ? bonds : [];
  },
)({ keySelector: (_state, bondId) => bondId, selectorCreator: createSelector });

// export const bondByIdSelector = (bonds: Bond[], bondId: string) => {
//   return __OLDsortedFind(bonds, { id: bondId } as Bond, (a) => a.id);
// };

export const getBondById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseBondId>) => getBonds(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseBondId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseBondId>) => baseId.id,
  (bonds, workspaceId, bondId) => {
    if (!workspaceId || !bondId) {
      return undefined;
    }
    return bondsSelectors.selectElementByT(bonds, {
      workspaceId,
      id: bondId,
    });
  },
)({
  keySelector: (_state, workspaceId, bondId) => `${workspaceId}-${bondId}`,
  selectorCreator: createSelector,
});

interface BondAssetId {
  workspaceId: string;
  assetId: string;
}

export const getBondByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BondAssetId>) => getBonds(state),
  (_state: WorkspaceDataState, baseId: Partial<BondAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BondAssetId>) => baseId.assetId,
  (bonds, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceBonds = bondsSelectors.selectElementsByKey(
      bonds,
      workspaceId,
    );
    return workspaceBonds?.find((bond) => bond.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
