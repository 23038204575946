import React from 'react';
import { useDispatch } from 'react-redux';
import { updateRoles } from '~src/data/store/modules/people/roles/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RoleState } from '~src/data/store/reducers/person/roles/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { RoleDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
  savedCallback: () => void;
  role: RoleState;
}

export const EditRoleDialogComponent = React.memo((props: Props) => {
  const [roleInput, setRoleInput] =
    React.useState<graphqlWorkspaceTypes.UpdateRoleInputType>({
      id: props.role.id,
      personId: props.role.personId,
      holdingId: props.role.holdingId,
      description: props.role.description,
      name: props.role.name,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveRole = (): Promise<RoleState> => {
    return dispatch(
      updateRoles(props.tenantId, props.workspace.id, props.person.id, [
        roleInput,
      ]),
    )
      .then((roles) => {
        return roles[0];
      })
      .catch((err) => {
        console.info('FAILED', roleInput, JSON.stringify(err));
        throw err;
      });
  };

  return (
    <RoleDialogComponent
      workspaceId={props.workspace.id}
      title={'Edit role'}
      handleSaveRole={handleSaveRole}
      roleInput={roleInput}
      setRoleInput={setRoleInput}
      handleClose={props.handleClose}
      savedCallback={props.savedCallback}
      open={props.open}
    />
  );
});
