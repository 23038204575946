export enum LocalStorageValueType {
  string,
  number,
  object,
}

export class LocalStorage {
  private _storage: Storage | null;

  constructor(private _prefix: string = '') {
    this._storage = typeof localStorage === 'undefined' ? null : localStorage;
  }

  private _toStorageId(id: string) {
    return `${this._prefix}${id}`;
  }

  private _stringify(value: any, type: LocalStorageValueType) {
    if (type === LocalStorageValueType.object) {
      return JSON.stringify(value);
    }
    return value;
  }

  private _parse(valueAsString: any, type: LocalStorageValueType) {
    try {
      if (type === LocalStorageValueType.object) {
        return JSON.parse(valueAsString);
      } else if (type === LocalStorageValueType.number) {
        return +valueAsString;
      }
      return valueAsString;
    } catch (error) {
      return undefined;
    }
  }

  public set(id: string, value: any, type: LocalStorageValueType) {
    if (this._storage) {
      this._storage.setItem(
        this._toStorageId(id),
        this._stringify(value, type),
      );
    }
  }

  public unset(id: string) {
    if (this._storage) {
      this._storage.removeItem(this._toStorageId(id));
    }
  }

  public get(id: string, type: LocalStorageValueType): any {
    if (this._storage) {
      const valueAsString = this._storage.getItem(this._toStorageId(id));
      if (valueAsString) {
        return this._parse(valueAsString, type);
      }
    }
    return undefined;
  }
}
