import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getPersonById } from '~src/data/store/selectors/person/person/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  workpacePeopleRootPath,
  personRootPath,
} from '~src/navigation/paths/workspace/people/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { useWorkspaceBreadcrumbs } from './workspace-breadcrumbs';
import { LinkRouter, maxItems } from './breadcrumbs.component';

function usePeopleBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);

  if (tenantId != null && workspaceId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={workpacePeopleRootPath(tenantId, workspaceId)}
      >
        people
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workpacePeopleRootPath(tenantId, workspaceId)}
        to={workpacePeopleRootPath(tenantId, workspaceId)}
      >
        people
      </LinkRouter>
    );
    return [workspaceBreadcrumb, projectElement];
  }
  return [];
}
function usePersonBreadcrumb() {
  const { tenantId, workspaceId, personId } = useParams<{
    tenantId: string;
    workspaceId: string;
    personId: string;
  }>();

  const peopleBreadcrumbs = usePeopleBreadcrumbs(false);

  const person = useSelector((state: RootState) => {
    if (workspaceId != null && personId != null) {
      return getPersonById(selectWorkspaceData(state), {
        id: personId,
        workspaceId,
      });
    }
  });
  if (person && tenantId != null && workspaceId != null) {
    return [
      peopleBreadcrumbs,
      <Typography
        color="inherit"
        key={personRootPath(tenantId, workspaceId, person.id)}
      >
        {person.name}
      </Typography>,
    ];
  }
  return [];
}
export function PersonBreadcrumbComponent() {
  const personBreadcrumbs = usePersonBreadcrumb();
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {personBreadcrumbs}
    </Breadcrumbs>
  );
}
export function PeopleBreadcrumbComponent(props: { last: boolean }) {
  const peopleBreadcrumbs = usePeopleBreadcrumbs(props.last);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {peopleBreadcrumbs}
    </Breadcrumbs>
  );
}
