import * as React from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuDiv } from '~src/components/dashboard/dashboard-menu-div';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedPortfolio } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { PortfolioConfigComponent } from '~src/domain/workspace/components/project/portfolio/config/portfolio-config.component';
import { useDashboardConfig } from '~src/domain/workspace/components/project/portfolio/dashboard/use-dashboard-config';
import { ConfigButtonComponent } from '~src/domain/workspace/components/project/types/portfolio-project/config-button.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import { Box, Drawer, Theme } from '@mui/material';

// import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { EntitiesComponent } from './entities.component';

// import { drawerWidth } from '~src/components/drawers/drawer.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  configContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  root: {
    // marginTop: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
  },
}));

export const PortfolioEntitiesComponent = React.memo((props: Props) => {
  const { workspace, portfolioProject, portfolio } = props;
  const { classes } = useStyles();
  const selectedPortfolio = useSelector((state: RootState) => {
    const p = getSelectedPortfolio(selectWorkspaceData(state), workspace.id);
    if (!p) {
      return portfolio;
    }
    return p;
  });
  const { portfolioConfig, portfolioDashboardConfig } = useDashboardConfig({
    workspace,
    portfolio,
  });

  const { isShowing: configOpen, toggle: toggleConfig } = useVisible();
  const configButton = (
    <ConfigButtonComponent
      isVisible={configOpen}
      toggleConfig={toggleConfig}
      text="configuration"
      variant="contained"
    />
  );

  return (
    <>
      <DashboardMenuDiv config={{ open: configOpen, alignSelf: 'flex-start' }}>
        <Box sx={{ mt: 1, ml: 1 }} className={classes.configContainer}>
          {configButton}
        </Box>
      </DashboardMenuDiv>
      <div className={classes.root}>
        <EntitiesComponent
          workspace={props.workspace}
          project={props.project}
          portfolio={selectedPortfolio}
        />
        <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
          <PortfolioConfigComponent
            workspace={workspace}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            portfolioConfig={portfolioConfig}
            dashboardConfig={portfolioDashboardConfig}
          />
        </Drawer>
      </div>
    </>
  );
});
