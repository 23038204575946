import numbro from 'numbro';
import React from 'react';
import { useSelector } from 'react-redux';
import { StatisticLabel, useStyles } from '~src/components/summary/styles';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getStockById } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import { getPaperByStock } from '~src/data/store/selectors/finance/quotes/selectors';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { selectLastPosition } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useContainerSize } from '~src/domain/workspace/components/common/dashboard/hooks/use-container-size.hook';
import { Config } from '~src/utils/interfaces/config';

import { Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

const numberToString = (value: number) => {
  return numbro(value).format({
    thousandSeparated: true,
    // mantissa: 1,
    // totalLength: 5,
    average: true,
  });
};

export const DashboardStockComponent = React.memo((props: Props) => {
  const { workspace, holding } = props;
  const stockHolding = useSelector((state: RootState) =>
    getStockHoldingByHoldingId(selectWorkspaceData(state), holding.id),
  );
  const stock = useSelector((state: RootState) =>
    getStockById(selectWorkspaceData(state), {
      id: stockHolding ? stockHolding.stockId : '',
      workspaceId: workspace.id,
    }),
  );
  const position = useSelector((state: RootState) =>
    stockHolding
      ? selectLastPosition(selectWorkspaceData(state), stockHolding.id)
      : undefined,
  );
  const paper = useSelector((state: RootState) => {
    return getPaperByStock(selectFinancialData(state), stock);
  });

  const sharesAsString = position?.shares
    ? numberToString(position?.shares)
    : '';

  const { size, ref } = useContainerSize();
  const styles = useStyles();
  return (
    <>
      <div ref={ref} className={styles.classes.statistic}>
        <Typography variant="h2" component="h2" noWrap>
          {sharesAsString}
        </Typography>
        <StatisticLabel
          // component="p"
          config={{ containerSize: size?.width }}
          className={styles.classes.statisticLabel}
        >
          # shares
        </StatisticLabel>
        <Typography variant="caption">
          {paper?.exchange}.{paper?.symbol}
        </Typography>
      </div>
    </>
  );
});
