import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseLoanId,
  loansSelectors,
} from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getLoans = (state: WorkspaceDataState) => {
  return state.holding.asset.loan.loans;
};

export const getLoansByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getLoans(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (loansState, workspaceId) => {
    const loans = loansSelectors.selectElementsByKey(loansState, workspaceId);
    return loans ? loans : [];
  },
)({ keySelector: (_state, loanId) => loanId, selectorCreator: createSelector });

export const getLoanById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseLoanId>) => getLoans(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseLoanId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseLoanId>) => baseId.id,
  (loans, workspaceId, loanId) => {
    if (!workspaceId || !loanId) {
      return undefined;
    }
    return loansSelectors.selectElementByT(loans, {
      workspaceId,
      id: loanId,
    });
  },
)({
  keySelector: (_state, workspaceId, loanId) => `${workspaceId}-${loanId}`,
  selectorCreator: createSelector,
});

interface LoanAssetId {
  workspaceId: string;
  assetId: string;
}

export const getLoanByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<LoanAssetId>) => getLoans(state),
  (_state: WorkspaceDataState, baseId: Partial<LoanAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<LoanAssetId>) => baseId.assetId,
  (loans, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceLoans = loansSelectors.selectElementsByKey(
      loans,
      workspaceId,
    );
    return workspaceLoans?.find((loan) => loan.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
