import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getScenarioById } from '~src/data/store/selectors/workspace/projects/scenario/scenarios/selectors';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { PortfolioProjectScenarioTabs } from '~src/domain/workspace/components/project/scenario/tabs/tabs';
import { ScenarioTabsComponent } from '~src/domain/workspace/components/project/scenario/tabs/tabs.component';
import { usePortfolioProjectScenarioTabs } from '~src/domain/workspace/components/project/scenario/tabs/use-tabs';
import {
  scenariosBasePath,
  scenarioTabPath,
} from '~src/navigation/paths/workspace/project/portfolio-project/scenario/paths';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  project: ProjectState;
}

interface PropsWithScenario extends Props {
  scenario: Scenario;
}

const ScenarioRouterWrapperWithTab = React.memo((props: PropsWithScenario) => {
  const tabs = usePortfolioProjectScenarioTabs();
  const { tabId } = useParams<{ tabId: string }>();
  console.log(tabId);

  const tab = tabs.find((t) => t.name === tabId);

  return tab ? (
    <ScenarioTabsComponent
      workspace={props.workspace}
      portfolioProject={props.portfolioProject}
      project={props.project}
      portfolio={props.portfolio}
      scenario={props.scenario}
      tab={tab}
      tabs={tabs}
    />
  ) : (
    <div>unknown tab</div>
  );
});

export const ScenarioRouterWrapper = React.memo((props: Props) => {
  const { workspace, project } = props;
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const scenario = useSelector((state: RootState) => {
    if (scenarioId != null) {
      return getScenarioById(selectWorkspaceData(state), {
        id: scenarioId,
        projectId: props.portfolioProject.projectId,
      });
    }
  });
  if (!scenario) {
    return (
      <Navigate
        to={`${scenariosBasePath(
          workspace.tenantId,
          workspace.id,
          project.id,
        )}`}
      />
    );
  } else {
    return (
      <Routes>
        <Route
          path="*"
          // exact={true}
          element={
            <Navigate
              to={scenarioTabPath(
                props.workspace.tenantId,
                props.workspace.id,
                props.portfolioProject.projectId,
                scenario.id,
                PortfolioProjectScenarioTabs.Dashboard,
              )}
              replace
            />
          }
        />
        {/* <Route path={rootScenarioPath} exact={true}>
          <Redirect
            to={scenarioTabPath(
              props.workspace.tenantId,
              props.workspace.id,
              props.portfolioProject.projectId,
              scenario.id,
              PortfolioProjectScenarioTabs.Dashboard
            )}
          />
        </Route> */}
        <Route
          path=":tabId/*"
          element={
            <ScenarioRouterWrapperWithTab {...props} scenario={scenario} />
          }
        />
      </Routes>
    );
  }
});
