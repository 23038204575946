import * as React from 'react';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import { EditStatusField } from '~src/components/fields/workspace/holding/transfer/status/edit-status-field';
import { ViewStatusField } from '~src/components/fields/workspace/holding/transfer/status/view-status-field';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TransferData } from '~src/domain/workspace/components/project/transfer/project-transfers.component';
import { useCurrencyField } from '~src/hooks/fields/financial/currency/use-currency-field';
import { useHoldingField } from '~src/hooks/fields/workspace/project/holding/use-holding-field';
import { useTransactionField } from '~src/hooks/fields/workspace/project/use-transaction-field';
import { transferBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/transfer/paths';
import { eventParser } from '~src/utils/form/event-parser';

import { Link, TextField } from '@mui/material';
import { TransferStatus, TransferType } from '@pladdenico/models';
import { TableField } from '@pladdenico/table';
import { EditTypeField } from '~src/components/fields/workspace/transfer/type/edit-type-field';
import { useNavigate } from 'react-router-dom';

function getCurrencyId(data: TransferData) {
  return data.transfer.currencyId;
}

function updateWithCurrency(data: TransferData, currency: CurrencyState) {
  const transfer = { ...data.transfer, currencyId: currency.id };
  return {
    ...data,
    transfer,
  };
}

function getHoldingId(data: TransferData) {
  return data.transfer.holdingId;
}

function updateWithHolding(data: TransferData, holding: HoldingState) {
  const transfer = { ...data.transfer, holdingId: holding.id };
  return {
    ...data,
    transfer,
  };
}

function getTransactionId(data: TransferData) {
  return data.transfer.transactionId;
}
// function getTransaction(data: TransferData) {
//   return data.transaction;
// }

function updateWithTransaction(
  data: TransferData,
  transaction: TransactionState,
) {
  const transfer = { ...data.transfer, transactionId: transaction.id };
  return {
    ...data,
    transfer,
  };
}

export function useTransferFields(
  tenantId: string,
  workspace: WorkspaceState,
  projectId: string,
): TableField.Field<TransferData>[] {
  const holdingField = useHoldingField(
    getHoldingId,
    updateWithHolding,
    workspace,
    projectId,
  );
  const currencyField = useCurrencyField(getCurrencyId, updateWithCurrency);
  const transactionField = useTransactionField(
    projectId,
    getTransactionId,
    updateWithTransaction,
  );
  const navigate = useNavigate();

  function handleTransferClick(
    tenantId: string,
    workspaceId: string,
    projectId: string,
    holdingId: string,
    transferId: string,
  ) {
    navigate(
      transferBasePath(tenantId, workspaceId, projectId, holdingId, transferId),
    );
  }
  const fields: TableField.Field<TransferData>[] = [
    {
      id: 'id',
      name: 'id',
      field: 'id',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.transfer.id,
      renderView: (viewProps) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handleTransferClick(
                tenantId,
                workspace.id,
                projectId,
                viewProps.data.transfer.holdingId,
                viewProps.data.transfer.id,
              )
            }
          >
            {viewProps.data.transfer.id}
          </Link>
        );
      },
    },
    {
      id: 'date',
      name: 'date',
      field: 'date',
      type: TableField.FieldTypes.date,
      iteratee: (data) => data.transfer.date,
      update: (data, date: Date) => {
        const transfer = { ...data.transfer, date };
        return {
          ...data,
          transfer,
        };
      },
      renderEdit: (editProps) => {
        return (
          <EditDateField
            handleValue={editProps.onChange}
            date={editProps.data.transfer.date}
            labelled={false}
          />
        );
      },
    },
    { ...holdingField },
    { ...transactionField },
    {
      id: 'cost',
      name: 'cost',
      field: 'cost',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.transfer.cost,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const transfer = {
          ...data.transfer,
          cost: eventParser(event) as number,
        };
        return {
          ...data,
          transfer,
        };
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="cost"
            value={editProps.data.transfer.cost}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'type',
      name: 'type',
      field: 'type',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.transfer.type,
      update: (data, type: TransferType) => {
        const transfer = { ...data.transfer, type };
        return {
          ...data,
          transfer,
        };
      },
      renderView: (viewProps) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handleTransferClick(
                tenantId,
                workspace.id,
                projectId,
                viewProps.data.transfer.holdingId,
                viewProps.data.transfer.id,
              )
            }
          >
            {viewProps.data.transfer.type}
          </Link>
        );
      },
      renderEdit: (editProps) => {
        return (
          <EditTypeField
            type={editProps.data.transfer.type}
            label={undefined}
            handleValue={editProps.onChange}
          />
        );
        // return (
        //   <TextField
        //     margin="normal"
        //     required
        //     id="type"
        //     value={editProps.data.transfer.type}
        //     onChange={editProps.onChange}
        //   />
        // );
      },
    },
    {
      id: 'subtype',
      name: 'subtype',
      field: 'subtype',
      type: TableField.FieldTypes.string,
      iteratee: (data) => (data.transfer.subtype ? data.transfer.subtype : ''),
      renderView: (viewProps) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handleTransferClick(
                tenantId,
                workspace.id,
                projectId,
                viewProps.data.transfer.holdingId,
                viewProps.data.transfer.id,
              )
            }
          >
            {viewProps.data.transfer.subtype
              ? viewProps.data.transfer.subtype
              : ''}
          </Link>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="subtype"
            value={editProps.data.transfer.subtype}
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'status',
      name: 'status',
      field: 'status',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.transfer.status,
      update: (data, status: TransferStatus) => {
        const transfer = { ...data.transfer, status };
        return {
          ...data,
          transfer,
        };
      },
      renderView: (viewProps) => {
        const status: TransferStatus = viewProps.data.transfer.status
          ? viewProps.data.transfer.status
          : TransferStatus.confirmed;
        return <ViewStatusField status={status} />;
      },
      renderEdit: (editProps) => {
        const status: TransferStatus = editProps.data.transfer.status
          ? editProps.data.transfer.status
          : TransferStatus.confirmed;
        return (
          <EditStatusField
            handleValue={editProps.onChange}
            status={status}
            labelled={false}
          />
        );
      },
    },
    {
      ...currencyField,
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'value',
      name: 'value',
      field: 'value',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.transfer.value,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const transfer = {
          ...data.transfer,
          value: eventParser(event) as number,
        };
        return {
          ...data,
          transfer,
        };
      },
      renderView: (viewProps) => {
        return <>{viewProps.data.transfer.value}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="value"
            value={editProps.data.transfer.value}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
    },
  ];
  return fields;
}
