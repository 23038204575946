import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { AdapterId } from '~src/services/xlsx/handlers/adapters/adapter';
import { TransactionType } from '~src/utils/finance/transaction-type';

import { strcmp } from '@pladdenico/common';

export interface NordnetTransactionId {
  id: string;
  account: string;
  holdingId: string | null;
}

export type NordnetTransactionState = {
  id: string;
  account: string;
  holdingId: string | null;
  bankNoteHoldingId: string | null | undefined;

  adapterId: AdapterId;

  nordnetId: string;
  date: Date;
  tradeDate: Date;
  settledDate: Date;
  transactionType: TransactionType;
  transactionString: string;
  paper: string;
  holdingType: string;
  ISIN: string;
  volume: number;
  price: number;
  interest: number;
  fees: number;
  currencyId: string;
  bankNoteCurrencyId: string;
  value: number;
  endValue: number;
  endVolume: number;
  endBankNoteValue: number;
  exchangeRate: number;
  description: string;
  completedNumber: number;
  verificationNumber: number;

  handled: boolean;
  transactionId: string | null;
  groupId: string | null;
};

export const initialNordnetTransactionState = [];

const selectKeyId = (nordnetTransaction: NordnetTransactionId) =>
  nordnetTransaction.account;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: NordnetTransactionId, b: NordnetTransactionId) => {
  return strcmp(a.id, b.id);
};

const adapter = new EntityRecordAdapter<
  NordnetTransactionId,
  NordnetTransactionState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const nordnetTransactionsSlice = createBaseRecordSlice(
  'nordnetTransactions',
  adapter,
);
export const nordnetTransactionsSelectors = adapter.selectors;

export const nordnetTransactionsReducer = nordnetTransactionsSlice.reducer;
export const nordnetTransactionsActions = nordnetTransactionsSlice.actions;
