export enum AssetType {
  Fund = 'Fund',
  PrivateEquityFund = 'PrivateEquityFund',
  BankNote = 'BankNote',
  Crypto = 'Crypto',
  Stock = 'Stock',
  Bond = 'Bond',
  Loan = 'Loan',
  Warrant = 'Warrant',
  Unknown = 'Unknown',
}
