import moment from 'moment';
import createCachedSelector from 're-reselect';
// import { getHoldingValue } from './holding-value';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getHoldingValuation } from '~src/data/store/selectors/finance/valuation/holding-valuation';
// import { createSelector } from 'reselect';
import {
  getFilterFor,
  getHoldingShareById,
} from '~src/data/store/selectors/holding/filter/selectors';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

import { Filter } from '../../../../../utils/common/filter';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsValues = createCachedSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    filter,
    useLiveValue,
  ) => {
    return holdings.map((holding) => {
      const filtered = getFilterFor(state, {
        filter,
        workspaceId,
        projectId,
        holding,
        date,
      });
      if (!filtered) {
        return {
          value: 0,
          holding,
        };
      }

      const valuation = getHoldingValuation(state, {
        workspaceId,
        holding,
        date,
        currency,
        useLiveValue,
      });
      const share = getHoldingShareById(state, {
        workspaceId,
        projectId,
        holding,
        filter,
        date,
      });
      return {
        value: valuation ? valuation.value * share : 0,
        holding,
      };
    });
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${
      props.projectId
    }:${props.date.unix()}:${JSON.stringify(props.holdings.map((h) => h.id))}`,
  selectorCreator: makeCreateSpecificSelector(),
});
