import * as React from 'react';
// import { PaperQuotesGraphComponent } from '~src/components/paper/paper-quotes-graph.component';
// import { PaperQuotesComponent } from '~src/components/paper/paper-quotes.component';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { PaperQuotesGraphComponent } from '~src/domain/workspace/components/finance/papers/paper/paper-quotes-graph.component';
import { PaperQuotesComponent } from '~src/domain/workspace/components/finance/papers/paper/paper-quotes.component';

import AddIcon from '@mui/icons-material/Add';
// import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
// import { PaperTradesComponent } from '~src/components/paper/paper-trades.component';
import { Grid, IconButton, Typography } from '@mui/material';

import { CreateQuoteComponent } from './quote/form/create.component';

// import { CreatePaperQuoteComponent } from './create-paper.component';

// import style from './style.module.css';

interface Props {
  paper: PaperState | undefined;
  // quotes?: QuoteState[];
}

export const PaperComponent = React.memo((props: Props) => {
  const [open, setOpen] = React.useState(false);

  const createQuoteElement = props.paper ? (
    <CreateQuoteComponent
      paper={props.paper}
      handleClose={() => setOpen(false)}
      open={open}
    />
  ) : undefined;
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="caption">Paper</Typography>
          <Typography variant="h5">
            {props.paper?.exchange} {props.paper?.symbol}
          </Typography>
          {/* <Button style={{ float: 'right' }} onClick={toggleShow}> */}
          {/* <Icon size="large" name={this.state.numberOfQuotesToggled ? 'angle up' : 'angle down'}/> */}
          {/* up or down
            </Button> */}
          <IconButton
            style={{ float: 'right' }}
            aria-label="add"
            onClick={() => setOpen(true)}
          >
            <AddIcon />
          </IconButton>
          {createQuoteElement}
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12}>
          {props.paper ? (
            <PaperQuotesComponent paper={props.paper} />
          ) : undefined}
        </Grid>
        <Grid item xs={12}>
          {props.paper ? (
            <PaperQuotesGraphComponent paper={props.paper} />
          ) : undefined}
        </Grid>
      </Grid>
    </Grid>
  );
});
