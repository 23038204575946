import { BondHoldingState } from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import { BondHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseBondHolding(bondHolding: BondHolding): BondHoldingState {
  return {
    id: bondHolding.id,
    bondId: bondHolding.bond?.id ?? '',
    holdingId: bondHolding.holding?.id ?? '',
  };
}
