import { dashboardMediasActions } from '~src/data/store/reducers/common/dashboard/media/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleDashboardMedias } from './handler';

export function fetchDashboardMedias(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetDashboardMediasDocument;
    const variables: graphqlWorkspaceTypes.GetDashboardMediasQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getDashboardMedias) {
        return handleDashboardMedias(
          dispatch,
          tenantId,
          workspaceId,
          data.getDashboardMedias,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createDashboardMedias(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateDashboardMediaInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateDashboardMediasDocument;
    const variables: graphqlWorkspaceTypes.CreateDashboardMediasMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createDashboardMedias) {
        return handleDashboardMedias(
          dispatch,
          tenantId,
          workspaceId,
          data.createDashboardMedias,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function updateDashboardMedias(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateDashboardMediaInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateDashboardMediasDocument;
    const variables: graphqlWorkspaceTypes.UpdateDashboardMediasMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateDashboardMedias) {
        return handleDashboardMedias(
          dispatch,
          tenantId,
          workspaceId,
          data.updateDashboardMedias,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function upsertDashboardMedias(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertDashboardMediaInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertDashboardMediasDocument;
    const variables: graphqlWorkspaceTypes.UpsertDashboardMediasMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertDashboardMedias) {
        return handleDashboardMedias(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertDashboardMedias,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function deleteDashboardMedias(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteDashboardMediasDocument;
    const variables: graphqlWorkspaceTypes.DeleteDashboardMediasMutationVariables =
      {
        inputs: ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteDashboardMedias) {
        return dispatch(
          dashboardMediasActions.removeMany(
            data.deleteDashboardMedias.map((p) => {
              return {
                id: p,
              };
            }),
          ),
        ).payload;
      }
      return [];
    });
  };
}
