import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { WorkspaceInvitationRouterWrapper } from '~src/domain/invitations/components/workspaces/root';
import { TabPanel } from '~src/domain/workspace/components/project/portfolio/holdings/tab/tab-panel.component';
import { WorkspacesComponent } from '~src/domain/workspace/components/workspace/workspaces.component';
import { tenantTabPath } from '~src/navigation/paths/tenant/paths';
import { secondaryBackgroundColor } from '~src/utils/common/colors/base-colors';
import { Tab } from '~src/utils/interfaces/tab';

import { Box } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TenantSettingsComponent } from '~src/domain/tenant/components/settings/settings.component';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';

export enum TenantTabs {
  Workspaces = 'workspaces',
  Members = 'members',
  Settings = 'settings',
  Invitations = 'invitations',
}

interface Props {
  tenant: TenantState;
  tabs: Tab<TenantTabs>[];
  tab: Tab<TenantTabs>;
}

interface PropsTab {
  tenant: TenantState;
  tabName: string;
}

const SubComponent = React.memo((props: PropsTab) => {
  const { tenant, tabName } = props;
  if (tabName === TenantTabs.Members) {
    return <div>MEMBERS</div>;
  } else if (tabName === TenantTabs.Workspaces) {
    return <WorkspacesComponent tenantId={tenant.id} />;
  } else if (tabName === TenantTabs.Settings) {
    return <TenantSettingsComponent tenant={tenant} />;
  } else if (tabName === TenantTabs.Invitations) {
    return (
      <Routes>
        <Route
          path=":invitationId"
          element={<WorkspaceInvitationRouterWrapper />}
        />
        <Route path="*" element={<div>INVITATIONS</div>} />
      </Routes>
    );
  }
  return <div>unknown</div>;
});

export const TenantTabsComponent = React.memo((props: Props) => {
  const { tenant } = props;
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newTenantTab: TenantTabs,
  ) {
    navigate(tenantTabPath(tenant.id, newTenantTab));
  }

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        return <SubComponent key={index} tabName={tab.name} tenant={tenant} />;
      }),
    [props.tabs, tenant],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel value={props.tab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });
  return (
    <>
      <Box sx={{ backgroundColor: secondaryBackgroundColor.light }}>
        <Tabs
          value={props.tab.name}
          indicatorColor="primary"
          textColor="primary"
          // centered
          variant="scrollable"
          onChange={handleChange}
          aria-label="tenant tabs"
        >
          {tabElements}
        </Tabs>
      </Box>
      {tabPanelElements}
    </>
  );
});
