import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import history from '~src/navigation/history';
import { entityTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';
// import { EntityTransactionsComponent } from '~src/domain/workspace/components/project/transaction/entity-transactions.component';
// import { EntityTransfersComponent } from '~src/domain/workspace/components/project/transfer/entity-transfers.component';
// import { EntityValuationsComponent } from '~src/domain/workspace/components/project/valuation/entity-valuations.component';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Tab as MuiTab, Tabs, Typography } from '@mui/material';

import { ProjectEntityComponent } from '../entity.component';

export enum EntityTabs {
  Dashboard = 'dashboard',
  Valuations = 'valuations',
  Transactions = 'transactions',
  Transfers = 'transfers',
  Positions = 'positions',
}

interface TabPanelProps {
  children?: React.ReactNode;
  // tab: EntityTabs;
  tab: string;
  // value: EntityTabs;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tab, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== tab}
      id={`scrollable-auto-tabpanel-${tab}`}
      aria-labelledby={`scrollable-auto-tab-${tab}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  entity: EntityState;
  // tab: EntityTabs;
  tabs: Tab<string>[];
  tab: Tab<string>;
}

export const EntityTabsComponent = React.memo((props: Props) => {
  // const [value, setValue] = React.useState(2);
  const navigate = useNavigate();
  function handleChange(_event: React.ChangeEvent<unknown>, newTab: string) {
    // setValue(newValue);
    navigate(
      entityTabPath(
        props.workspace.tenantId,
        props.workspace.id,
        props.portfolioProject.projectId,
        props.entity.id,
        newTab,
      ),
    );
  }

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  return (
    <>
      <Tabs
        value={props.tab.name}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="entity tabs"
      >
        {tabElements}
      </Tabs>
      <TabPanel value={props.tab.name} tab={EntityTabs.Dashboard}>
        <ProjectEntityComponent
          entityId={props.entity.id}
          // projectId={props.portfolioProject.projectId}
          workspace={props.workspace}
        />
      </TabPanel>
      {/* <TabPanel value={props.tab.name} tab={EntityTabs.Valuations}>
        <EntityValuationsComponent
          workspace={props.workspace}
          projectId={props.portfolioProject.projectId}
          entityId={props.entity.id}
        />
      </TabPanel>
      <TabPanel value={props.tab.name} tab={EntityTabs.Transactions}>
        <EntityTransactionsComponent
          workspace={props.workspace}
          projectId={props.portfolioProject.projectId}
          entityId={props.entity.id}
        />
      </TabPanel>
      <TabPanel value={props.tab.name} tab={EntityTabs.Transfers}>
        <EntityTransfersComponent
          workspace={props.workspace}
          projectId={props.portfolioProject.projectId}
          entity={props.entity}
        />
      </TabPanel> */}
    </>
  );
});
