import { Visitable } from '~src/data/store/visitors/visitable';
import {
  ReportStateVisitor,
  ReportVisitable,
  ReportVisitor,
} from '~src/data/store/visitors/workspace/project/report/report-visitor';
import { parseGeneratedReport } from '~src/data/store/modules/workspaces/reports/generated-reports/parser';
import {
  generatedReportsActions,
  GeneratedReportState,
} from '~src/data/store/reducers/workspace/projects/reports/generated-report/reducer';
import { GeneratedReport } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

interface GeneratedReportVisitor {
  visit(generatedReport: GeneratedReportVisitable): void;
  post(): void;
  reportVisitor: ReportVisitor;
}

export class GeneratedReportVisitable
  implements Visitable<GeneratedReportVisitor>
{
  constructor(
    private _workspaceId: string,
    private _generatedReport: GeneratedReport,
  ) {}
  public accept(visitor: GeneratedReportVisitor) {
    visitor.visit(this);
    if (this._generatedReport.report) {
      const reportVisitable = new ReportVisitable(
        this._workspaceId,
        this._generatedReport.report,
      );
      reportVisitable.accept(visitor.reportVisitor);
    }
  }

  public parse(): GeneratedReportState {
    return parseGeneratedReport(this._generatedReport);
  }
}

export class GeneratedReportStateVisitor implements GeneratedReportVisitor {
  private _generatedReports: GeneratedReportState[];
  public reportVisitor: ReportVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._generatedReports = [];
    this.reportVisitor = new ReportStateVisitor(this._dispatch);
  }
  public visit(generatedReport: GeneratedReportVisitable): void {
    this._generatedReports.push(generatedReport.parse());
  }
  post() {
    this._dispatch(
      generatedReportsActions.upsertManyElements(this._generatedReports),
    );
    this.reportVisitor.post();
  }
}
