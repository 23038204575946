import React from 'react';
import { useSelector } from 'react-redux';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { AddElementButtonComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/add-element-button.component';
import { useConfiguration } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/menu/use-configuration';
import { useDateFilter } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/menu/use-date-filter';
import { useElements } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/menu/use-elements';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const useDashboard = (
  workspace: WorkspaceState,
  project: ProjectState,
  holding: HoldingState,
  holdingConfig: HoldingConfigState,
  dashboardConfig: HoldingDashboardConfigState,
  dashboardElements: DashboardElementState[],
) => {
  const { configOpen, configuration, toggleConfig } = useConfiguration();

  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );
  const { dateFilter } = useDateFilter(config, configOpen);
  const { dialog, open, components } = useElements(
    workspace,
    project,
    holding,
    holdingConfig,
    dashboardConfig,
    dashboardElements,
    // configOpen
  );

  const addElement = React.useMemo(
    () => (
      <>
        <AddElementButtonComponent open={open} />
        {dialog}
      </>
    ),
    [dialog, open],
  );
  const menu = React.useMemo(
    () => (
      <Grid2
        container
        justifyContent="space-between"
        sx={{
          position: 'sticky',
          top: 64 + 8, // height of AppBar
          zIndex: (theme) => (configOpen ? 0 : theme.zIndex.modal),
        }}
      >
        <Grid2>{configuration}</Grid2>
        <Grid2>{dateFilter}</Grid2>
        {!configOpen && <Grid2>{addElement}</Grid2>}
      </Grid2>
    ),
    [addElement, configOpen, configuration, dateFilter],
  );
  return { menu, components, configOpen, toggleConfig };
};
