import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseWarrantHoldingId {
  holdingId: string;
  id: string;
}

export type WarrantHoldingState = Omit<
  graphqlWorkspaceTypes.WarrantHolding,
  'holding' | 'warrant'
> & {
  holdingId: string;
  warrantId: string;
};

export const initialWarrantHoldingState = { ts: [], idxs: [] };

const selectId = (warrantHolding: BaseWarrantHoldingId) => warrantHolding.id;
const selectIdx = (warrantHolding: BaseWarrantHoldingId) =>
  warrantHolding.holdingId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: WarrantHoldingState, b: WarrantHoldingState) => b;

const adapter = new EntityIndexedAdapter<
  BaseWarrantHoldingId,
  WarrantHoldingState,
  string,
  string
>(
  initialWarrantHoldingState,
  selectId,
  selectIdx,
  merger,
  iComparator,
  idxComparator,
);

export const warrantHoldingsSlice = createBaseIndexedSlice(
  'warrantHoldings',
  adapter,
);
export const warrantHoldingsSelectors = adapter.selectors;
export const warrantHoldingsReducer = warrantHoldingsSlice.reducer;
export const warrantHoldingsActions = warrantHoldingsSlice.actions;
