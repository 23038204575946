import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import {
  BarChartFormat,
  defaultBarChartFormat,
} from '~src/components/irr/bar-chart-format';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FormatBarChartComponent } from '~src/domain/workspace/components/common/dashboard/element/format-bar-chart.component';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';
import { PeriodTypeField } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/irr/period-type.component';
import { PeriodType, PeriodTypes } from '~src/utils/period/period-type';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material';

export interface IrrConfigData {
  barChartFormat: BarChartFormat;
}

export const useIrrMenu = (
  element: DashboardElementState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const updatePeriodType = React.useCallback(
    (type: PeriodType) => {
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            type,
          },
        });
    },
    [element, updated],
  );

  const updateBarChartFormat = React.useCallback(
    (barChartFormat: BarChartFormat) => {
      const data = element.config.data as IrrConfigData | undefined;
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            data: {
              ...data,
              barChartFormat,
            },
          },
        });
    },
    [element, updated],
  );

  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);
  const content = React.useMemo(() => {
    const data = element.config.data as IrrConfigData | undefined;
    const barChartFormat = data?.barChartFormat ?? defaultBarChartFormat;

    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-header" />,
      // <ListSubheader key="barChartFormHeader">Bar chart format</ListSubheader>,
      <Accordion
        key="barChartFormAccordion"
        square
        disableGutters
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2">Bar chart format</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormatBarChartComponent
            key="barChartFormat"
            barChartFormat={barChartFormat}
            update={updateBarChartFormat}
            baseBarChartFormat={defaultBarChartFormat}
          />
        </AccordionDetails>
      </Accordion>,

      <Divider key="divider-irr" />,
      <MenuItem key="menu-item-1">
        <PeriodTypeField
          handleValue={updatePeriodType}
          label={'Irr type'}
          periodType={(element.config.type as PeriodType) ?? PeriodTypes.Year}
        />
      </MenuItem>,

      deleteMenuItem,
      confirmDeleteElement,
    ];
  }, [
    element,
    updated,
    updateBarChartFormat,
    updatePeriodType,
    deleteMenuItem,
    confirmDeleteElement,
  ]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
