import { upsertWarrantHoldings } from '~src/data/store/modules/holdings/warrant/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'warrantHoldings';

export interface WarrantHolding {
  id: string;
  holdingId: string;
  warrantId: string;
}

const header: Array<keyof WarrantHolding> = ['id', 'holdingId', 'warrantId'];

export const warrantHoldingExportHandler = (
  data: WarrantHolding[],
): ExportHandler<WarrantHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const warrantHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<WarrantHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertWarrantHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
