import {
  WorkspaceInvitationStateVisitor,
  WorkspaceInvitationVisitable,
} from '~src/data/store/visitors/workspace/invitation/invitation-visitor';

import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';

export function handleWorkspaceInvitations(
  tenantId: string,
  dispatch: any,
  outputs: graphqlTenantTypes.WorkspaceInvitation[],
) {
  const workspaceInvitationVisitor = new WorkspaceInvitationStateVisitor(
    dispatch,
  );
  outputs.forEach((workspaceInvitation) => {
    const visitable = new WorkspaceInvitationVisitable(
      tenantId,
      workspaceInvitation,
    );
    visitable.accept(workspaceInvitationVisitor);
  });
  workspaceInvitationVisitor.post();
  return outputs;
}
