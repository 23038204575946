import moment from 'moment';
import { PeriodType } from '~src/utils/period/period-type';

export class PeriodMapper {
  public static map = PeriodMapper.initMap();

  private static initMap() {
    const map = new Map<
      PeriodType,
      {
        name: moment.unitOfTime.StartOf;
        diffName: moment.unitOfTime.Diff;
        formaterBase: (date: moment.Moment) => string;
        formaterDiff: (
          startDate: moment.Moment,
          endDate: moment.Moment,
        ) => string;
      }
    >();

    map.set('year', {
      name: 'year',
      diffName: 'years',
      formaterBase: (date: moment.Moment) => date.format('YYYY'),
      formaterDiff: (startDate: moment.Moment, endDate: moment.Moment) => {
        return `${startDate.format('YYYY')} - ${endDate.format('DD-MM-YYYY')}`;
      },
    });
    map.set('quarter', {
      name: 'quarter',
      diffName: 'quarters',
      formaterBase: (date: moment.Moment) => `Q${date.format('Q-YY')}`,
      formaterDiff: (startDate: moment.Moment, endDate: moment.Moment) => {
        return `Q${startDate.format('Q-YY')} - ${endDate.format('DD-MM-YYYY')}`;
      },
    });
    map.set('month', {
      name: 'month',
      diffName: 'months',
      formaterBase: (date: moment.Moment) => `${date.format('MMM-YY')}`,
      formaterDiff: (startDate: moment.Moment, endDate: moment.Moment) => {
        return `${startDate.format('MMM-YY')} - ${endDate.format(
          'DD-MM-YYYY',
        )}`;
      },
    });
    return map;
  }
}
