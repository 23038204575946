import React from 'react';
import { useSelector } from 'react-redux';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export const getLastValuation = (valuations: ValuationState[]) => {
  let lastValuation: ValuationState | undefined = undefined;
  if (valuations.length > 0) {
    lastValuation = valuations.reduce((prev, cur) => {
      if (prev.date.getTime() < cur.date.getTime()) {
        return cur;
      }
      return prev;
    }, valuations[0]);
  }
  return lastValuation;
};

export const useLastValuation = (holdingId: string | undefined) => {
  const valuations = useSelector((state: RootState) => {
    if (holdingId) {
      return getValuationsByHoldingId(selectWorkspaceData(state), holdingId);
    }
    return [];
  });
  const lastValuation = React.useMemo(() => {
    return getLastValuation(valuations);
  }, [valuations]);
  return lastValuation;
};

export function getLastValuationIdx(valuations: ValuationState[]) {
  return valuations.reduce((prevValuation, currentValuation, idx) => {
    if (
      prevValuation === -1 ||
      valuations[idx].date.getTime() < currentValuation.date.getTime()
    ) {
      return idx;
    }
    return prevValuation;
  }, -1);
}
