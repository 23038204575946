import { Visitable } from '~src/data/store/visitors/visitable';
import {
  parseWorkspace,
  WorkspaceBase,
} from '~src/data/store/modules/workspaces/workspaces/parser';
import {
  workspacesActions,
  WorkspaceState,
} from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { WorkspaceBase } from '~src/services/graphql/tenant/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface WorkspaceBaseVisitor {
  visit(workspace: WorkspaceBaseVisitable): void;
  post(): void;
}

export class WorkspaceBaseVisitable implements Visitable<WorkspaceBaseVisitor> {
  constructor(
    private _tenantId: string,
    private _workspace: WorkspaceBase,
  ) {}
  public accept(visitor: WorkspaceBaseVisitor) {
    visitor.visit(this);
  }

  public parse(): WorkspaceState {
    return parseWorkspace(this._tenantId, this._workspace);
  }
}

export class WorkspaceBaseStateVisitor implements WorkspaceBaseVisitor {
  private _workspaces: WorkspaceState[];
  constructor(private _dispatch: AppDispatch) {
    this._workspaces = [];
  }
  public visit(project: WorkspaceBaseVisitable): void {
    this._workspaces.push(project.parse());
  }
  post() {
    this._dispatch(workspacesActions.upsertManyElements(this._workspaces));
  }
}
