import { parseInvestor } from '~src/data/store/modules/assets/private-equity-fund/investors/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  investorsActions,
  InvestorState,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { PrivateEquityFundInvestor } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

interface PrivateEquityFundInvestorVisitor {
  visit(privateEquityFundInvestor: PrivateEquityFundInvestorVisitable): void;
  post(): void;
}

export class PrivateEquityFundInvestorVisitable
  implements Visitable<PrivateEquityFundInvestorVisitor>
{
  constructor(
    private _workspaceId: string,
    private _fundId: string,
    private _privateEquityFundInvestor: PrivateEquityFundInvestor,
  ) {}
  public accept(visitor: PrivateEquityFundInvestorVisitor) {
    visitor.visit(this);
  }

  public parse(): InvestorState {
    return parseInvestor(
      this._workspaceId,
      this._fundId,
      this._privateEquityFundInvestor,
    );
  }
}

export class PrivateEquityFundInvestorStateVisitor
  implements PrivateEquityFundInvestorVisitor
{
  private _investors: InvestorState[];
  constructor(private _dispatch: AppDispatch) {
    this._investors = [];
  }
  public visit(investor: PrivateEquityFundInvestorVisitable): void {
    this._investors.push(investor.parse());
  }
  post() {
    this._dispatch(investorsActions.upsertManyElements(this._investors));
  }
}
