import * as graphql from 'graphql';
import { apis } from '~src/services/request/apis';

import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { GraphqlResultHandler } from './graphql-result-handler';
import { GraphqlResultSimpleHandler } from './graphql-result-simple-handler';

interface QueryExecutor {
  executeQuery: <Context>(
    query: graphql.DocumentNode,
    variables: unknown,
    context: Context,
    callback: (result: any) => void,
  ) => void;
}

function execute<TQuery, TQueryVariables, TContext>(
  executor: QueryExecutor,
  node: TypedDocumentNode<TQuery, TQueryVariables>,
  variables: TQueryVariables,
  context: TContext,
  resultHandler: GraphqlResultHandler<
    TQuery,
    TQueryVariables
  > = new GraphqlResultSimpleHandler(),
): Promise<TQuery> {
  const promise = new Promise<TQuery>((resolve, reject) => {
    const queryCallback = (result: graphql.ExecutionResult<TQuery>) => {
      try {
        // resolve(resultHandler.post(result));
        if (!result) {
          throw new Error('GraphqlRequest: No result');
        }
        if (result.errors) {
          return reject(resultHandler.handleErrors(result.errors));
        } else if (result.data) {
          return resolve(resultHandler.handleSuccess(result.data));
        } else {
          // return resolve(resultHandler.handleEmpty());
          return reject(resultHandler.handleEmpty());
        }
      } catch (error) {
        return reject(error);
      }
    };
    resultHandler.pre(node, variables);
    return executor.executeQuery(node, variables, context, queryCallback);
    // return apis.invest.executeQuery(node, variables, context, queryCallback);
  });
  return promise;
}

export function investRequest<TQuery, TQueryVariables, TContext>(
  node: TypedDocumentNode<TQuery, TQueryVariables>,
  variables: TQueryVariables,
  context: TContext,
  resultHandler: GraphqlResultHandler<
    TQuery,
    TQueryVariables
  > = new GraphqlResultSimpleHandler(),
): Promise<TQuery> {
  return execute(apis.invest, node, variables, context, resultHandler);
}

export function financeRequest<TQuery, TQueryVariables, TContext>(
  node: TypedDocumentNode<TQuery, TQueryVariables>,
  variables: TQueryVariables,
  context: TContext,
  resultHandler: GraphqlResultHandler<
    TQuery,
    TQueryVariables
  > = new GraphqlResultSimpleHandler(),
): Promise<TQuery> {
  return execute(apis.finance, node, variables, context, resultHandler);
}

export function objStorageRequest<TQuery, TQueryVariables, TContext>(
  node: TypedDocumentNode<TQuery, TQueryVariables>,
  variables: TQueryVariables,
  context: TContext,
  resultHandler: GraphqlResultHandler<
    TQuery,
    TQueryVariables
  > = new GraphqlResultSimpleHandler(),
): Promise<TQuery> {
  return execute(apis.objStorage, node, variables, context, resultHandler);
}
