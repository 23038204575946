import {
  BondPositionStateVisitor,
  BondPositionVisitable,
} from '~src/data/store/visitors/holding/types/bond/bond-position-visitor';
import { BondPosition } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Operation } from '@pladdenico/portfolio-api';

export function handleBondPositions(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: BondPosition[],
  subscriptions: Operation[],
) {
  const bondPositionVisitor = new BondPositionStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  const bondPositions = outputs.map((bondHolding) => {
    const bondHoldingVisitable = new BondPositionVisitable(bondHolding);
    return bondHoldingVisitable.accept(bondPositionVisitor);
  });

  bondPositionVisitor.post();
  return bondPositions;
}
