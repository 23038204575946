import { sortBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { ElementFormatConfig } from '~src/components/dashboard/element';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsXIRRS } from '~src/data/store/selectors/finance/xirr/holdings-xirrs';
import { selectData } from '~src/data/store/selectors/selectors';
import { Filter } from '~src/utils/common/filter';
import { SortOrder } from '~src/utils/interfaces/sort-order';
import { Period } from '~src/utils/period/period';

import { HoldingValue } from './holdings-view-table.component';

interface Props {
  workspace: WorkspaceState;
  valuationDate: moment.Moment;
  projectId: string;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
  sortOrder: SortOrder;
  minimumValuationForHolding: number | undefined;
  filter: Filter;
  format: ElementFormatConfig;
  period: Period;
}

export const useHoldingsIRR = (props: Props) => {
  const {
    workspace,
    projectId,
    holdings,
    valuationDate,
    period,
    baseCurrency,
    filter,
  } = props;

  const unsortedIrrs = useSelector((state: RootState) =>
    getHoldingsXIRRS(selectData(state), {
      workspaceId: workspace.id,
      projectId,
      holdings,
      date: valuationDate,
      period,
      currency: baseCurrency,
      filter,
      useLiveValue: true,
    }),
  );

  const [irrs, setIrrs] = React.useState<HoldingValue[]>([]);
  React.useEffect(() => {
    setIrrs(sortBy(unsortedIrrs, (a) => a.value));
  }, [unsortedIrrs]);

  return irrs;
};
