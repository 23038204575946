import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

export type SelectedTenantIdState = string;

export function selectedTenantToSelectedTenantIdState(
  selectedTenantId: SelectedTenantIdState,
) {
  return selectedTenantId;
}
export const initialSelectedTenantIdState = null;

const adapter = new EntityAdapter<string>(initialSelectedTenantIdState);

export const selectedTenantIdSlice = createBaseSlice('selectedTenant', adapter);
export const selectedTenantIdSelectors = adapter.selectors;

export const selectedTenantIdReducer = selectedTenantIdSlice.reducer;
export const selectedTenantIdActions = selectedTenantIdSlice.actions;
