import {
  HoldingDashboardConfigStateVisitor,
  HoldingDashboardConfigVisitable,
} from '~src/data/store/visitors/holding/config/holding-dashboard-config-visitor';
import { HoldingDashboardConfig } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleHoldingDashboardConfigs(
  dispatch: any,
  holdingConfigId: number,
  outputs: HoldingDashboardConfig[],
  tenantId: string,
  workspaceId: string,
  subscriptions: Operation[],
) {
  const holdingDashboardConfigVisitor = new HoldingDashboardConfigStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  const ret = outputs.map((config) => {
    const visitable = new HoldingDashboardConfigVisitable(
      holdingConfigId,
      config,
    );
    return visitable.accept(holdingDashboardConfigVisitor);
  });
  holdingDashboardConfigVisitor.post();
  return ret;
}
