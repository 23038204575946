import { categoriesActions } from '~src/data/store/reducers/asset/categories/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleAssetCategories } from './handler';

export function fetchAssetCategories(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetAssetCategoriesDocument;
    const variables: graphqlWorkspaceTypes.GetAssetCategoriesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getAssetCategories) {
        return handleAssetCategories(
          workspaceId,
          dispatch,
          data.getAssetCategories,
        );
      }
      return [];
    });
  };
}

export function createAssetCategories(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateAssetCategoryInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateAssetCategoriesDocument;
    const variables: graphqlWorkspaceTypes.CreateAssetCategoriesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createAssetCategories) {
        return handleAssetCategories(
          workspaceId,
          dispatch,
          data.createAssetCategories,
        );
      }
      return [];
    });
  };
}

export function updateAssetCategories(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateAssetCategoryInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateAssetCategoriesDocument;
    const variables: graphqlWorkspaceTypes.UpdateAssetCategoriesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateAssetCategories) {
        return handleAssetCategories(
          workspaceId,
          dispatch,
          data.updateAssetCategories,
        );
      }
      return [];
    });
  };
}

export function upsertAssetCategories(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertAssetCategoryInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertAssetCategoriesDocument;
    const variables: graphqlWorkspaceTypes.UpsertAssetCategoriesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertAssetCategories) {
        return handleAssetCategories(
          workspaceId,
          dispatch,
          data.upsertAssetCategories,
        );
      }
      return [];
    });
  };
}

export function deleteAssetCategories(
  tenantId: string,
  workspaceId: string,
  assetCategories: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteAssetCategoriesDocument;
    assetCategories.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteAssetCategoryInputType[] =
      assetCategories.map((warrant) => {
        return {
          id: warrant.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeleteAssetCategoriesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteAssetCategories) {
        const deletedAssetCategories = intersectionSorted(
          assetCategories,
          data.deleteAssetCategories,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(
          categoriesActions.removeManyElements(deletedAssetCategories),
        );
      }
      return [];
    });
  };
}
