import React from 'react';
import { EditHoldingFromWorkspaceField } from '~src/components/fields/workspace/holding/holding/edit-holding-from-workspace.field';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type RoleInput =
  | graphqlWorkspaceTypes.CreateRoleInputType
  | graphqlWorkspaceTypes.UpdateRoleInputType;

interface Props {
  // open: boolean;
  workspaceId: string;
  roleInput: RoleInput;
  setRoleInput: (roleInput: RoleInput) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const RoleFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange = (name: keyof RoleInput) => (value: any) => {
    props.setRoleInput({ ...props.roleInput, [name]: value });
  };

  const handleEventChange =
    (name: keyof RoleInput) => (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(name)(event.target.value);
      // props.setRoleInput({ ...props.roleInput, [name]: event.target.value });
    };

  const handleHolding = (holding: HoldingState) => {
    props.setRoleInput({ ...props.roleInput, holdingId: holding.id });
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {/* <EditIdField
        className={classes.textField}
        handleChange={handleChange('id')}
        value={props.roleInput.id}
      /> */}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.roleInput.name ?? ''}
        label="Name"
        type="text"
        onChange={handleEventChange('name')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="description"
        value={props.roleInput.description ?? ''}
        label="Description"
        type="text"
        onChange={handleEventChange('description')}
      />
      <EditHoldingFromWorkspaceField
        workspaceId={props.workspaceId}
        labelled={true}
        handleValue={(holding) => handleHolding(holding)}
        holdingId={props.roleInput.holdingId}
      />
    </form>
  );
});
