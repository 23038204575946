import React from 'react';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  defaultBackgroundColor,
  defaultFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/header/default-colors';
import { DashboardHeaderComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/header/header.component';
import { useHeaderMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/header/use-header-menu.hook';
import { Config } from '~src/utils/interfaces/config';

interface Props {
  element: DashboardElementState;
  workspace: WorkspaceState;
  portfolio: PortfolioState;
  config: Config;
  id: string;
  remove: (id: string) => void;
  loading: boolean;
  active: boolean;
  setActive: () => void;
  setInactive: () => void;
  updated?: (
    id: string,
    element: Partial<DashboardElementState>,
  ) => Promise<{ id: string }>;
}

export const HeaderElement = (props: Props) => {
  const {
    active,
    config,
    element,
    id,
    loading,
    portfolio,
    remove,
    setActive,
    setInactive,
    workspace,
    updated,
  } = props;
  const { menu, handleOpen, isOpen } = useHeaderMenu(element, remove, updated);
  const elementConfig = React.useMemo(() => {
    return {
      dragDisabled: isOpen,
      format: {
        color: element.config.color ?? defaultFontColor,
        backgroundColor:
          element.config.backgroundColor ?? defaultBackgroundColor,
        padding: element.config.padding,
        border: element.config.border,
      },
    };
  }, [
    element.config.backgroundColor,
    element.config.border,
    element.config.color,
    element.config.padding,
    isOpen,
  ]);

  return (
    <>
      <ElementComponent
        remove={remove}
        id={id}
        active={active}
        setActive={setActive}
        setInactive={setInactive}
        loading={loading}
        usageMode={config.usageMode}
        openOptionsMenu={handleOpen}
        elementConfig={elementConfig}
      >
        <DashboardHeaderComponent
          workspace={workspace}
          portfolio={portfolio}
          config={config}
        />
      </ElementComponent>
      {menu}
    </>
  );
};
