import moment from 'moment';
import { createSelector } from 'reselect';
import { getSharesForHoldingForFilter } from '~src/data/store/selectors/holding/filter/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { getHoldingValue } from './holding-value';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  filter: Filter;
  useLiveValue: boolean;
  filterKey: string;
  filterValue: string;
}

export const getHoldingGroupValuations = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (_state: DataState, props: Props) => props.filterKey,
  (_state: DataState, props: Props) => props.filterValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    filter,
    useLiveValue,
    filterKey,
    filterValue,
  ) => {
    return holdings.map((holding) => {
      // const filtered = filterFor(state, {
      //   holdingFilter,
      //   id: holding.id,
      //   date,
      // });
      // if (!filtered) {
      //   return {
      //     value: 0,
      //     holding,
      //   };
      // }

      const valuation = getHoldingValue(state, {
        workspaceId,
        holding,
        date,
        currency,
        useLiveValue,
      });
      const share = getSharesForHoldingForFilter(state, {
        workspaceId,
        projectId,
        holding,
        filter,
        date,
        filterKey,
        filterValue,
      });
      return {
        value: valuation ? valuation.value * share : 0,
        holding,
      };
    });
  },
);
