import * as React from 'react';
import { useSelector } from 'react-redux';
import { EditPortfolios } from '~src/components/fields/workspace/project/portfolio/edit-portfolios';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectFavoriteHoldingByHoldingId } from '~src/data/store/selectors/holding/favorite-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { FavoriteHoldingComponent } from '~src/domain/workspace/components/project/holding/favorite-holdings/favorite-holding.component';
import { useFieldsComponent } from '~src/domain/workspace/components/project/holding/form/use-fields.component';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  holdingInput: graphqlWorkspaceOperations.UpdateHoldingInputType;
  setHoldingInput: (
    holdingInput: graphqlWorkspaceOperations.UpdateHoldingInputType,
  ) => void;
  holdingTypeFieldsComponent: JSX.Element | undefined;
  portfolios: PortfolioState[];
  setPortfolios: (portfolios: PortfolioState[]) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const EditFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const favorited = useSelector((state: RootState) =>
    selectFavoriteHoldingByHoldingId(selectWorkspaceData(state), {
      projectId: props.projectId,
      holdingId: props.holding.id,
    }),
  );
  const { nameElement, descriptionElement, typeElement } = useFieldsComponent({
    holdingInput: props.holdingInput,
    setHoldingInput: props.setHoldingInput,
    disableTypeField: true,
  });
  const renderFavoriteHoldingButton = (
    handleToggleFavoriteHolding: () => void,
  ) => {
    const icon = favorited ? <FavoriteIcon /> : <FavoriteBorderIcon />;
    return (
      <IconButton aria-label="favorite" onClick={handleToggleFavoriteHolding}>
        {icon}
      </IconButton>
    );
  };

  const handlePortfolios = (portfolios: PortfolioState[]) => {
    console.log(JSON.stringify(portfolios));
    props.setPortfolios(portfolios);
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={4}>
          {nameElement}
        </Grid>
        <Grid item xs={5}>
          {descriptionElement}
        </Grid>
        <Grid item xs={2}>
          {typeElement}
        </Grid>
        <Grid item xs={1}>
          <FavoriteHoldingComponent
            tenantId={props.workspace.tenantId}
            workspaceId={props.workspace.id}
            projectId={props.projectId}
            render={renderFavoriteHoldingButton}
            holding={props.holding}
            favorited={favorited}
          />
        </Grid>
        <Grid item xs={12}>
          {props.holdingTypeFieldsComponent}
        </Grid>
        <Grid item xs={6}>
          <EditPortfolios
            workspace={props.workspace}
            projectId={props.projectId}
            handleValue={handlePortfolios}
            labelled={true}
            portfolios={props.portfolios}
          />
        </Grid>
      </Grid>
    </form>
  );
});
