import React from 'react';
import { PivotOption } from '~src/utils/interfaces/pivot-option';

import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  pivots: string[];
  setPivots: (pivots: string[]) => void;
  pivotOptions: PivotOption[];
}

export const PivotsComponent = (props: Props) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    props.setPivots(event.target.value as string[]);
  };

  return (
    <FormControl>
      <InputLabel id="portfolio-holding-group-irr-pivots-label">
        Pivots
      </InputLabel>
      <Select
        labelId="portfolio-holding-group-irr-pivots-label"
        id="portfolio-holding-group-irr-pivots"
        multiple
        value={props.pivots}
        onChange={handleChange}
        input={<Input />}
        // MenuProps={MenuProps}
      >
        {props.pivotOptions.map((pivot) => (
          <MenuItem key={pivot.key} value={pivot.value}>
            {pivot.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
