import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { RegionalMapComponent } from '~src/components/utils/regional-map.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsRegionalValuations } from '~src/data/store/selectors/finance/valuation/holdings-regional-valuations';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  active?: boolean;
  format?: {
    fontColor?: string;
  };
}

export const HoldingsValuationRegionalComponent = React.memo((props: Props) => {
  const { format, active } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );
  const holdingsRegionalValuations = useSelector((state: RootState) =>
    getHoldingsRegionalValuations(selectData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      holdings: props.holdings,
      date: props.date,
      currency: props.currency,
      filter,
      useLiveValue: true, // XXX: Should come from config
    }),
  );

  return (
    <RegionalMapComponent
      regionalValues={holdingsRegionalValuations}
      active={active}
      format={format}
    />
  );
});
