import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseStockId,
  stocksSelectors,
} from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getStocks = (state: WorkspaceDataState) => {
  return state.holding.asset.stock.stocks;
};

export const getStocksByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getStocks(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (stocksState, workspaceId) => {
    const stocks = stocksSelectors.selectElementsByKey(
      stocksState,
      workspaceId,
    );
    return stocks ? stocks : [];
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

export const getStockById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseStockId>) =>
    getStocks(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseStockId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseStockId>) => baseId.id,
  (stocks, workspaceId, stockId) => {
    if (!workspaceId || !stockId) {
      return undefined;
    }
    return stocksSelectors.selectElementByT(stocks, {
      workspaceId,
      id: stockId,
    });
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.id}`,
  selectorCreator: createSelector,
});

interface StockAssetId {
  workspaceId: string;
  assetId: string;
}

export const getStockByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<StockAssetId>) =>
    getStocks(state),
  (_state: WorkspaceDataState, baseId: Partial<StockAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<StockAssetId>) => baseId.assetId,
  (stocks, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceStocks = stocksSelectors.selectElementsByKey(
      stocks,
      workspaceId,
    );
    return workspaceStocks?.find((stock) => stock.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
