import createCachedSelector from 're-reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getAssetByHoldingId } from '~src/data/store/selectors/asset/assets/selectors';
import { getCategoryById } from '~src/data/store/selectors/asset/categories/selectors';
import { HoldingGroup } from '~src/data/store/selectors/finance/holding-groups';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';
import { createSelector } from 'reselect';
import {
  AssetCategoryState,
  categoriesSelectors,
} from '~src/data/store/reducers/asset/categories/reducer';

interface AssetCategoryByHoldingIdProps {
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
}

export const getAssetCategoryHoldingProperties = createCachedSelector(
  (state: DataState, props: AssetCategoryByHoldingIdProps) =>
    getAssetByHoldingId(selectWorkspaceDataFromData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      holdingId: props.holding.id,
    }),
  (state: DataState, _props: AssetCategoryByHoldingIdProps) =>
    selectWorkspaceDataFromData(state),
  (_state: DataState, props: AssetCategoryByHoldingIdProps) =>
    props.workspaceId,
  (asset, state, workspaceId): HoldingGroup[] => {
    if (!asset) {
      return [];
    }
    const category = getCategoryById(state, {
      id: asset.categoryId ?? undefined,
      workspaceId,
    });
    if (category) {
      return [{ id: category.id, name: category.name ?? 'unknown', share: 1 }];
    }
    return [{ id: 'unknown', name: 'unknown', share: 1 }];
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${props.projectId}:${props.holding.id}}`,
  selectorCreator: createSelector,
});

export const selectAssetCategoryHoldingProperties = createCachedSelector(
  (categories: AssetCategoryState[], categoryId: string) =>
    categoriesSelectors.selectElementInRecordById(categories, {
      id: categoryId,
    }),
  (category): HoldingGroup[] => {
    if (category) {
      return [{ id: category.id, name: category.name ?? 'unknown', share: 1 }];
    }
    return [{ id: 'unknown', name: 'unknown', share: 1 }];
  },
)({
  keySelector: (_state, workspaceId, categoryId) =>
    `${workspaceId}:${categoryId}`,
  selectorCreator: createSelector,
});
