import {
  PrivateEquityFundInvestorStateVisitor,
  PrivateEquityFundInvestorVisitable,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-investor-visitor';
import { PrivateEquityFundInvestor } from '~src/services/graphql/workspace/client/graphql';

export function handleInvestors(
  _tenantId: string,
  workspaceId: string,
  fundId: string,
  dispatch: any,
  outputs: PrivateEquityFundInvestor[],
) {
  const visitor = new PrivateEquityFundInvestorStateVisitor(dispatch);
  outputs.forEach((investor) => {
    const visitable = new PrivateEquityFundInvestorVisitable(
      workspaceId,
      fundId,
      investor,
    );
    visitable.accept(visitor);
  });
  visitor.post();
  return outputs;
}
