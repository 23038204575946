import React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectDialogContentComponent } from '~src/domain/workspace/components/project/dialog-content.component';

import { ProjectType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
}

export interface ProjectInputType {
  name: string;
  type: ProjectType;
}

export const CreateProjectComponent = React.memo((props: Props) => {
  const { workspace, handleClose, open } = props;
  // const [projectInput, setProjectInput] =
  //   React.useState<graphqlWorkspaceTypes.CreateProjectInputType>({
  //     id: uuid.v1(),
  //     name: '',
  //     type: ProjectType.Portfolio,
  //   });
  // const [onDataCreate, setOnDataCreate] = React.useState<() => void>();

  // const { onCreate } = useProjectActions(props.workspace);

  // let content;
  // if (projectInput.type === ProjectType.Portfolio) {
  //   content = (
  //     <CreatePortfolioProjectFormComponent
  //       workspace={props.workspace}
  //       projectForm={projectForm}
  //     />
  //   );
  // } else if (projectInput.type === ProjectType.Fund) {
  //   content = (
  //     <CreatePortfolioProjectFormComponent
  //       workspace={props.workspace}
  //       projectInput={projectInput}
  //       setProjectInput={setProjectInput}
  //       setOnCreate={setOnDataCreate}
  //     />
  //   );
  // } else if (projectInput.type === ProjectType.PrivateEquityFund) {
  //   content = (
  //     <CreatePrivateEquityFundProjectFormComponent
  //       workspace={props.workspace}
  //       projectInput={projectInput}
  //       setProjectInput={setProjectInput}
  //       setOnCreate={setOnDataCreate}
  //     />
  //   );
  // } else {
  //   content = <div>Unknown project type</div>;
  // }
  return (
    <DialogComponent
      dialogProps={{
        open,
        onClose: handleClose,
        fullWidth: true,
      }}
    >
      <ProjectDialogContentComponent
        handleClose={handleClose}
        workspace={workspace}
      />
    </DialogComponent>
    // <CreateProjectDialogComponent
    //   open={props.open}
    //   handleClose={props.handleClose}
    //   handleCreateProject={() =>
    //     onCreate(projectInput, onDataCreate, props.handleClose)
    //   }
    // >
    //   {content}
    // </CreateProjectDialogComponent>
  );
});
