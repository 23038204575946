import { commitmentPath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchCommitments,
  handleDeleteCommitments,
} from '~src/data/store/modules/holdings/commitments/commitments/requests';
import { CommitmentState } from '~src/data/store/reducers/holding/commitment/commitments/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToCommitments(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  commitments: CommitmentState[],
  subscriptions: Operation[],
) {
  subscriptions.forEach((operation) => {
    if (operation === Operation.create) {
      apis.invest.subscribe(
        {
          path: commitmentPath(tenantId, workspaceId).domainPath(operation),
          handle: (res: { id: string }) => {
            dispatch(fetchCommitments(tenantId, workspaceId, [res.id]));
          },
        },
        () => {
          // console.log('subscribed to commitment', operation, commitment.id);
        },
      );
    }
    commitments.forEach((commitment) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: commitmentPath(tenantId, workspaceId).objectPath(
              commitment.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchCommitments(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to commitment', operation, commitment.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: commitmentPath(tenantId, workspaceId).objectPath(
              commitment.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteCommitments([
                  { id: res.id, holdingId: commitment.holdingId },
                ]),
              );
            },
          },
          () => {
            // console.log('subscribed to commitment', operation, commitment.id);
          },
        );
      }
    });
  });
}
