import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { CreateInvestmentFormComponent } from './create-form.component';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  fund: PrivateEquityFundState;
  open: boolean;
  handleClose: () => void;
  createInputInit: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType;
}

export const CreateInvestmentComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <CreateInvestmentFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        fund={props.fund}
        handleClose={props.handleClose}
        createInputInit={props.createInputInit}
      />
    </Dialog>
  );
});
