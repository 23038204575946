import { AssetType } from '@pladdenico/models';
import { parseLoan } from '~src/data/store/modules/assets/loans/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  loansActions,
  LoanState,
} from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { Asset, Loan } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface LoanVisitor {
  visit(loan: LoanVisitable): LoanState;
  post(): void;
}

export const isLoan = (asset: Asset): asset is Loan => {
  return asset._type === AssetType.Loan;
};

export class LoanVisitable implements Visitable<LoanVisitor> {
  constructor(
    private _workspaceId: string,
    private _loan: Loan,
  ) {}
  public accept(visitor: LoanVisitor) {
    return visitor.visit(this);
  }

  public parse(): LoanState {
    const assetId = this._loan.asset?.id ?? '';
    return parseLoan(this._workspaceId, assetId, this._loan);
  }
}

export class LoanStateVisitor implements LoanVisitor {
  private _loans: LoanState[];
  constructor(private _dispatch: AppDispatch) {
    this._loans = [];
  }

  public visit(loan: LoanVisitable): LoanState {
    const loanParsed = loan.parse();
    this._loans.push(loanParsed);
    return loanParsed;
  }

  post() {
    this._dispatch(
      loansActions.upsertManyElements(this._loans, {
        shouldAutobatch: true,
      }),
    );
  }
}
