import React from 'react';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useDashboardComponents } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/hooks/use-dashboard-components.hook';

export const useElements = (
  workspace: WorkspaceState,
  project: ProjectState,
  holding: HoldingState,
  holdingConfig: HoldingConfigState,
  dashboardConfig: HoldingDashboardConfigState,
  dashboardElements: DashboardElementState[],
  // configOpen: boolean
) => {
  const [loading] = React.useState(false);
  const {
    dialog,
    open,
    container: components,
  } = useDashboardComponents({
    dashboardElements,
    holding,
    loading,
    config: holdingConfig,
    dashboardConfig,
    project,
    workspace,
  });

  // const addElement = React.useMemo(
  //   () => (
  //     <>
  //       <AddElementButtonComponent open={open} />
  //       {dialog}
  //     </>
  //   ),
  //   [dialog, open]
  // );
  return { dialog, open, components };
};
