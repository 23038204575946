import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFavoriteHolding,
  unsetFavoriteHolding,
} from '~src/data/store/modules/holdings/favorite/requests';
import { FavoriteHoldingState } from '~src/data/store/reducers/holding/favorite-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectUserData } from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';

interface Props {
  tenantId: string;
  workspaceId: string;
  projectId: string;
  render: (
    handleToggleFavoriteHolding: () => void,
    favorited: boolean,
  ) => JSX.Element;
  holding: HoldingState;
  favorited: FavoriteHoldingState | undefined;
}

export const FavoriteHoldingComponent = React.memo((props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(
    (state: RootState) => selectUserData(state).account.user,
  );

  const handleToggleFavoriteHolding = () => {
    if (!user) {
      return;
    }
    if (props.favorited) {
      dispatch(
        unsetFavoriteHolding(
          props.tenantId,
          props.workspaceId,
          props.projectId,
          props.holding.id,
        ),
      ).then(() => {
        console.log('CALLBACK' + props.holding.id);
      });
    } else {
      dispatch(
        setFavoriteHolding(
          props.tenantId,
          props.workspaceId,
          props.projectId,
          props.holding.id,
        ),
      ).then(() => {
        console.log('CALLBACK' + props.holding.id);
      });
    }
  };
  return props.render(handleToggleFavoriteHolding, !!props.favorited);
});
