import { compact } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AdapterTransaction } from '~src/data/store/selectors/data-import/adapter/selectors';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
// import { CreateComponent } from '~src/domain/workspace/components/project/transaction/create.component';
import {
  CreateTransactionFormComponent,
  FormHoldingFields,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
import { getHoldingConfigBuy } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-buy';
import { getHoldingConfigCost } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-cost';
import { getHoldingConfigDeposit } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-deposit';
import { getHoldingConfigDividend } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-dividend';
import { getHoldingConfigInterest } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-interest';
import { getHoldingConfigSell } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-sell';
import { getHoldingConfigWithdrawal } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-withdrawal';
import { createHoldingInputDataFromConfig } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-input-data';
import { initializeBondTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/bond/initialize-transfer-data';
import { initializeFundTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/fund/initialize-transfer-data';
import { initializeStockTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/stock/initialize-transfer-data';
import {
  BondTransferData,
  FundTransferData,
  StockTransferData,
  TransferTypeData,
} from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';
import {
  dnbTransactionsActions,
  DnbTransactionState,
} from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';
import {
  nordnetTransactionsActions,
  NordnetTransactionState,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import {
  vpsTransactionsActions,
  VpsTransactionState,
} from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';
import { AppDispatch, store } from '~src/store/store';
import { TransactionType } from '~src/utils/finance/transaction-type';

import { HoldingType } from '@pladdenico/models';
import { CreateTransferInputType } from '~src/services/graphql/workspace/client/graphql';
// import { CreateTransferInputType } from '@pladdenico/portfolio-api/dist/generated/graphql.workspace';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  transactions: AdapterTransaction[];
}

const createTransactionType = (
  dnbTransactions: DnbTransactionState[],
  _vpsTransactions: VpsTransactionState[],
  nordnetTransactions: NordnetTransactionState[],
) => {
  if (dnbTransactions.length === 1) {
    const dnbTransaction = dnbTransactions[0];
    if (
      dnbTransaction.description === 'Lønn' ||
      dnbTransaction.description === 'Omkostninger'
    ) {
      return TransactionType.Cost;
    } else if (dnbTransaction.description === 'Utbytte') {
      return TransactionType.Dividend;
    } else if (dnbTransaction.description === 'Renter') {
      return TransactionType.Interest;
    } else if (dnbTransaction.value < 0) {
      return TransactionType.Buy;
    } else {
      return TransactionType.Sell;
    }
  } else if (nordnetTransactions.length >= 1) {
    const nordnetTransaction = nordnetTransactions[0];
    return nordnetTransaction.transactionType;
  }
  return TransactionType.Custom;
};

const vpsTransactionToTransferData = (
  vpsTransaction: VpsTransactionState | undefined,
  transferData:
    | StockTransferData
    | FundTransferData
    | BondTransferData
    | undefined,
) => {
  if (transferData && vpsTransaction) {
    transferData.trade.shares = vpsTransaction.volume;
    transferData.trade.sharePrice = vpsTransaction.averagePrice;
  }
};

const nordnetTransactionToTransferData = (
  nordnetTransaction: NordnetTransactionState | undefined,
  transferData:
    | StockTransferData
    | FundTransferData
    | BondTransferData
    | undefined,
) => {
  if (transferData && nordnetTransaction) {
    transferData.trade.shares = nordnetTransaction.volume;
    transferData.trade.sharePrice = nordnetTransaction.price;
  }
};

const getInputTransferData = (
  workspace: WorkspaceState,
  holding: HoldingState,
  transferInput: CreateTransferInputType,
) => {
  let data: TransferTypeData = undefined;
  if (holding.type === HoldingType.Stock) {
    data = initializeStockTransferData(
      workspace,
      holding,
      transferInput.id,
      transferInput.date,
    );
  } else if (holding.type === HoldingType.Fund) {
    data = initializeFundTransferData(
      workspace,
      holding,
      transferInput.id,
      transferInput.date,
    );
  } else if (holding.type === HoldingType.Bond) {
    data = initializeBondTransferData(
      workspace,
      holding,
      transferInput.id,
      transferInput.date,
    );
  }
  return data;
};

const handleTransactionType = (
  transactionType: TransactionType,
  h1: string | undefined,
  h2: string | undefined,
) => {
  let holdingConfig1: HoldingConfig | undefined = undefined;
  let holdingConfig2: HoldingConfig | undefined = undefined;
  if (transactionType === TransactionType.Buy) {
    holdingConfig1 = getHoldingConfigWithdrawal(h1);
    holdingConfig2 = getHoldingConfigBuy(h2);
  } else if (transactionType === TransactionType.Sell) {
    holdingConfig1 = getHoldingConfigDeposit(h1);
    holdingConfig2 = getHoldingConfigSell(h2);
  } else if (transactionType === TransactionType.Dividend) {
    holdingConfig1 = getHoldingConfigDeposit(h1);
    holdingConfig2 = getHoldingConfigDividend(h2);
  } else if (transactionType === TransactionType.Interest) {
    holdingConfig1 = getHoldingConfigDeposit(h1);
    holdingConfig2 = getHoldingConfigInterest(h2);
  } else if (transactionType === TransactionType.Cost) {
    holdingConfig1 = getHoldingConfigCost(h1);
  } else if (transactionType === TransactionType.Account) {
    holdingConfig1 = getHoldingConfigDeposit(h1);
    holdingConfig2 = getHoldingConfigWithdrawal(h2);
  } else {
    // TODO: Or undefined transfer type?
    if (h1) {
      holdingConfig1 = getHoldingConfigCost(h1);
    }
    if (h2) {
      holdingConfig2 = getHoldingConfigCost(h2);
    }
  }
  return {
    holdingConfig1,
    holdingConfig2,
  };
};

const getInitialFormValues = (
  workspace: WorkspaceState,
  transactionType: TransactionType,
  projectId: string,
  dnbTransactions: DnbTransactionState[],
  vpsTransactions: VpsTransactionState[],
  nordnetTransactions: NordnetTransactionState[],
): FormValues | undefined => {
  const holdings: FormHoldingFields[] = [];
  if (nordnetTransactions.length === 1) {
    const nordnetTransaction = nordnetTransactions[0];
    const { holdingConfig1, holdingConfig2 } = handleTransactionType(
      transactionType,
      nordnetTransaction.bankNoteHoldingId ?? undefined,
      nordnetTransaction.holdingId ?? undefined,
    );
    if (holdingConfig1) {
      const bankNoteInputData = createHoldingInputDataFromConfig(
        holdingConfig1,
        nordnetTransaction.date,
      );
      bankNoteInputData.transferInputData.base.value = Math.abs(
        nordnetTransaction.value,
      );
      bankNoteInputData.expectedResultingValue =
        nordnetTransaction.endBankNoteValue;
      bankNoteInputData.transferInputData.base.currencyId =
        nordnetTransaction.bankNoteCurrencyId;
      holdings.push(bankNoteInputData);
    }
    if (holdingConfig2 != null) {
      const nordnetInputData = createHoldingInputDataFromConfig(
        holdingConfig2,
        nordnetTransaction.date,
      );
      nordnetInputData.transferInputData.base.value = Math.abs(
        nordnetTransaction.value,
      );
      if (nordnetInputData.holdingId != null) {
        const holding = getHoldingById(selectWorkspaceData(store.getState()), {
          id: nordnetInputData.holdingId,
          projectId,
        });
        if (holding) {
          nordnetInputData.transferInputData.data = getInputTransferData(
            workspace,
            holding,
            nordnetInputData.transferInputData.base,
          );
          nordnetTransactionToTransferData(
            nordnetTransaction,
            nordnetInputData.transferInputData.data,
          );
          nordnetInputData.transferInputData.base.value =
            nordnetTransaction.value;
          nordnetInputData.transferInputData.base.currencyId =
            nordnetTransaction.currencyId;
          nordnetInputData.transferInputData.base.cost =
            nordnetTransaction.fees;

          nordnetInputData.valuationInputData.currencyId =
            nordnetTransaction.currencyId;
        }
      }
      holdings.push(nordnetInputData);
    }
    return {
      title: '',
      description: nordnetTransaction.description,
      date: nordnetTransaction.date,
      holdings,
      type: transactionType,
    };
  } else if (nordnetTransactions.length === 2) {
    const nordnetTransaction1 = nordnetTransactions[0];
    const nordnetTransaction2 = nordnetTransactions[1];
    let h1;
    let h2;

    if (transactionType === TransactionType.Account) {
      if (nordnetTransaction1.value > 0) {
        h1 = nordnetTransaction1.bankNoteHoldingId;
        h2 = nordnetTransaction2.bankNoteHoldingId;
      } else {
        h1 = nordnetTransaction2.bankNoteHoldingId;
        h2 = nordnetTransaction1.bankNoteHoldingId;
      }
    } else {
      h1 = nordnetTransaction1.bankNoteHoldingId;
      h2 = nordnetTransaction2.holdingId;
    }
    const { holdingConfig1, holdingConfig2 } = handleTransactionType(
      transactionType,
      h1 ?? undefined,
      h2 ?? undefined,
    );
    if (holdingConfig1) {
      const bankNoteInputData = createHoldingInputDataFromConfig(
        holdingConfig1,
        nordnetTransaction1.date,
      );
      bankNoteInputData.transferInputData.base.value = Math.abs(
        nordnetTransaction1.value,
      );
      bankNoteInputData.expectedResultingValue =
        nordnetTransaction1.endBankNoteValue;
      bankNoteInputData.transferInputData.base.currencyId =
        nordnetTransaction1.bankNoteCurrencyId;
      holdings.push(bankNoteInputData);
    }
    if (holdingConfig2 != null) {
      const nordnetInputData = createHoldingInputDataFromConfig(
        holdingConfig2,
        nordnetTransaction2.date,
      );
      nordnetInputData.transferInputData.base.value = Math.abs(
        nordnetTransaction2.value,
      );
      if (nordnetInputData.holdingId != null) {
        const holding = getHoldingById(selectWorkspaceData(store.getState()), {
          id: nordnetInputData.holdingId,
          projectId,
        });
        if (holding) {
          nordnetInputData.transferInputData.data = getInputTransferData(
            workspace,
            holding,
            nordnetInputData.transferInputData.base,
          );
          nordnetInputData.expectedResultingValue =
            nordnetTransaction2.endBankNoteValue;
          nordnetTransactionToTransferData(
            nordnetTransaction2,
            nordnetInputData.transferInputData.data,
          );
          nordnetInputData.transferInputData.base.value = Math.abs(
            nordnetTransaction2.value,
          );
          nordnetInputData.transferInputData.base.currencyId =
            nordnetTransaction2.currencyId;
          nordnetInputData.transferInputData.base.cost =
            nordnetTransaction2.fees;

          nordnetInputData.valuationInputData.currencyId =
            nordnetTransaction2.currencyId;
        }
      }
      holdings.push(nordnetInputData);
    }
    return {
      title: '',
      description: `${nordnetTransaction1.description}-${nordnetTransaction2.description}`,
      date: nordnetTransaction1.date,
      holdings,
      type: transactionType,
    };
  } else if (dnbTransactions.length === 1 && vpsTransactions.length <= 1) {
    const dnbTransaction = dnbTransactions[0];
    const vpsTransaction = vpsTransactions.at(0);
    const { holdingConfig1, holdingConfig2 } = handleTransactionType(
      transactionType,
      dnbTransaction?.holdingId ?? undefined,
      vpsTransaction?.holdingId ?? undefined,
    );
    if (holdingConfig1) {
      const dnbInputData = createHoldingInputDataFromConfig(
        holdingConfig1,
        dnbTransaction.date,
      );
      dnbInputData.transferInputData.base.value = Math.abs(
        dnbTransaction.value,
      );
      dnbInputData.expectedResultingValue = dnbTransaction.expectedValuation;
      holdings.push(dnbInputData);
    }
    if (holdingConfig2 != null) {
      const vpsInputData = createHoldingInputDataFromConfig(
        holdingConfig2,
        vpsTransaction?.date ?? dnbTransaction.date,
      );
      vpsInputData.transferInputData.base.value = Math.abs(
        vpsTransaction?.value ?? -dnbTransaction.value,
      );
      if (vpsInputData.holdingId != null) {
        const holding = getHoldingById(selectWorkspaceData(store.getState()), {
          id: vpsInputData.holdingId,
          projectId,
        });
        if (holding) {
          vpsInputData.transferInputData.data = getInputTransferData(
            workspace,
            holding,
            vpsInputData.transferInputData.base,
          );
          vpsTransactionToTransferData(
            vpsTransaction,
            vpsInputData.transferInputData.data,
          );

          vpsInputData.transferInputData.base.value = vpsTransaction?.value;
        }
      }
      holdings.push(vpsInputData);
    }
    return {
      title: '',
      description: dnbTransaction.name,
      date: dnbTransaction.date,
      holdings,
      type: transactionType,
    };
  }
  return undefined;
};

export const CreateTransactionComponent = React.memo((props: Props) => {
  const { workspace, projectId, transactions } = props;
  const dispatch = useDispatch<AppDispatch>();
  const dnbTransactions = React.useMemo(
    () =>
      compact(
        transactions.map((t) => {
          if (t.adapterId === 'dnb') {
            return t as DnbTransactionState;
          }
        }),
      ),
    [transactions],
  );
  const vpsTransactions = React.useMemo(
    () =>
      compact(
        transactions.map((t) => {
          if (t.adapterId === 'vps') {
            return t as VpsTransactionState;
          }
        }),
      ),
    [transactions],
  );
  const nordnetTransactions = React.useMemo(
    () =>
      compact(
        transactions.map((t) => {
          if (t.adapterId === 'nordnet') {
            return t as NordnetTransactionState;
          }
        }),
      ),
    [transactions],
  );

  const transactionType: TransactionType = React.useMemo(() => {
    return createTransactionType(
      dnbTransactions,
      vpsTransactions,
      nordnetTransactions,
    );
  }, [dnbTransactions, nordnetTransactions, vpsTransactions]);

  const setHandled = React.useCallback(
    (transaction: TransactionState) => {
      const dnbTs = dnbTransactions.map((t) => {
        return { ...t, handled: true, transactionId: transaction.id };
      });
      dispatch(dnbTransactionsActions.upsertManyElements(dnbTs));
      const vpsTs = vpsTransactions.map((t) => {
        return { ...t, handled: true, transactionId: transaction.id };
      });
      dispatch(vpsTransactionsActions.upsertManyElements(vpsTs));
      const nordnetTs = nordnetTransactions.map((t) => {
        return { ...t, handled: true, transactionId: transaction.id };
      });
      dispatch(nordnetTransactionsActions.upsertManyElements(nordnetTs));
    },
    [dispatch, dnbTransactions, nordnetTransactions, vpsTransactions],
  );

  const handleSuccess = React.useCallback(
    (transaction: TransactionState) => {
      setHandled(transaction);
      // close();
    },
    [setHandled],
  );

  const initialFormValues = React.useMemo(
    () =>
      getInitialFormValues(
        workspace,
        transactionType,
        projectId,
        dnbTransactions,
        vpsTransactions,
        nordnetTransactions,
      ),
    [
      dnbTransactions,
      nordnetTransactions,
      projectId,
      transactionType,
      vpsTransactions,
      workspace,
    ],
  );

  return (
    <>
      <CreateTransactionFormComponent
        workspace={workspace}
        projectId={projectId}
        handleSuccess={handleSuccess}
        handleClose={close}
        initialFormValues={initialFormValues}
      />
    </>
  );
});
