import React from 'react';
import { useSelector } from 'react-redux';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

interface Props {
  projectId: string;
  holdingId: string;
}

export const ViewHoldingField = React.memo((props: Props) => {
  const holding = useSelector((state: RootState) =>
    getHoldingById(selectWorkspaceData(state), {
      id: props.holdingId,
      projectId: props.projectId,
    }),
  );

  return <>{holding ? holding.name : ''}</>;
});
