import { TransferType } from '@pladdenico/models';

export function transferSign(transferType: TransferType) {
  if (
    transferType === TransferType.Buy ||
    transferType === TransferType.Withdrawal ||
    transferType === TransferType.RevenueFee
  ) {
    return -1;
  } else if (
    transferType === TransferType.Sell ||
    transferType === TransferType.Provision ||
    transferType === TransferType.Dividend ||
    transferType === TransferType.Interest ||
    transferType === TransferType.Deposit ||
    transferType === TransferType.Cost ||
    transferType === TransferType.WrongPayment
  ) {
    return 1;
    // } else if (transferType === TransferType.Interest) {
    //   return 0;
  } else {
    return 0;
    // throw new Error('Should not happen');
  }
}
