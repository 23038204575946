import { Tab } from '~src/utils/interfaces/tab';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';

import { EntityTabs } from './entity-tabs.component';

function createTabs(): Tab<string>[] {
  const tabs: Tab<string>[] = [];

  {
    const path = {
      tab: EntityTabs.Dashboard,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/entities/:entityId/dashboard',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: EntityTabs.Valuations,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/entities/:entityId/valuations',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: EntityTabs.Transactions,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/entities/:entityId/transactions',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: EntityTabs.Transfers,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/entities/:entityId/transfers',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }

  return tabs;
}

export function useEntityTabs(_entity: EntityState) {
  return createTabs();
}
