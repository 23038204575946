import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TabPanel } from '~src/domain/workspace/components/project/portfolio/holdings/tab/tab-panel.component';
import { useGenericTab } from '~src/domain/workspace/components/project/portfolio/holdings/tab/use-generic-tab';
// import history from '~src/navigation/history';
import { holdingTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';

export interface PropsTab {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  project: ProjectState;
  holding: HoldingState;
  tabName: string;
  specificTab?: (props: PropsTab) => JSX.Element | undefined;
}

export interface UseHoldingTabsProps {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  project: ProjectState;
  portfolio: PortfolioState;
  holding: HoldingState;
  tabs: Tab<string>[];
  tab: Tab<string>;
  specificTab?: (props: PropsTab) => JSX.Element | undefined;
}

const SubComponent = (props: PropsTab) => {
  let tab = useGenericTab(props);
  if (tab) {
    return tab;
  }
  if (props.specificTab) {
    tab = props.specificTab(props);
    if (tab) {
      return tab;
    }
  }
  return <div>unknown</div>;
};

export const useHoldingTabs = (
  workspace: WorkspaceState,
  portfolioProject: PortfolioProjectState,
  project: ProjectState,
  portfolio: PortfolioState,
  holding: HoldingState,
  tabs: Tab<string>[],
  currentTab: Tab<string>,
  specificTab?: (props: PropsTab) => JSX.Element | undefined,
) => {
  const navigate = useNavigate();
  const handleChange = React.useCallback(
    (_event: React.ChangeEvent<unknown>, newTab: string) => {
      navigate(
        holdingTabPath(
          workspace.tenantId,
          workspace.id,
          portfolioProject.projectId,
          holding.id,
          newTab,
        ),
      );
    },
    [
      navigate,
      workspace.tenantId,
      workspace.id,
      portfolioProject.projectId,
      holding.id,
    ],
  );

  const tabElements = tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      tabs.map((tab, index) => {
        return (
          <SubComponent
            key={index}
            holding={holding}
            portfolio={portfolio}
            project={project}
            portfolioProject={portfolioProject}
            tabName={tab.name}
            workspace={workspace}
            specificTab={specificTab}
          />
        );
      }),
    [
      holding,
      portfolio,
      portfolioProject,
      project,
      specificTab,
      tabs,
      workspace,
    ],
  );

  const tabPanelElements = tabs.map((tab, index) => {
    return (
      <TabPanel value={currentTab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: primaryBackgroundColor.veryLight,
        }}
      >
        <Box pl={2}>
          <Typography variant="h6">{holding.name}</Typography>
        </Box>
        <Tabs
          value={currentTab.name}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="holding tabs"
        >
          {tabElements}
        </Tabs>
      </Box>
      {tabPanelElements}
    </>
  );
};
