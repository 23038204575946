import { useNavigate } from 'react-router-dom';
// import history from '~src/navigation/history';

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

export function NotificationsBadgeComponent() {
  const navigate = useNavigate();
  function handleGoToNotifications() {
    navigate('/notifications');
  }

  return (
    <IconButton
      aria-label="show 17 new notifications"
      color="inherit"
      onClick={handleGoToNotifications}
    >
      <Badge badgeContent={17} color="secondary">
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
}
