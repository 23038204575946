import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { UpdateState } from '~src/utils/common/update-state';

import { List, ListItem, TablePagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableOperations } from '@pladdenico/table';

import { EntityCardComponent } from './entity-card.component';
import { EntityData } from '~src/domain/workspace/components/project/portfolio/entities/entities.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  data: EntityData[];
  setUpdateState: (newUpdateState: UpdateState) => void;
}

const useStyles = makeStyles()({
  row: {
    // paddingTop: 5,
    // marginTop: 5,
    // marginBottom: 5,
    // paddingBottom: 5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // marginTop: theme.spacing(2),
  },
  pagination: {
    overflow: 'hidden',
  },
  listItem: {
    flex: 1,
  },
});

export const EntityCardsListComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { paginationPosition, updateRowsPerPage, updatePage } =
    TableOperations.usePagination(props.data, 10);

  const handleRowsPerPageChange = React.useCallback(
    (event: any) => {
      updateRowsPerPage(+event.target.value);
    },
    [updateRowsPerPage],
  );

  const handlePageChange = React.useCallback(
    (_event: any, page: number) => {
      updatePage(page);
    },
    [updatePage],
  );

  const listContent = props.data
    .slice(paginationPosition.startIdx, paginationPosition.endIdx)
    .map((entityData) => {
      return (
        <ListItem
          key={entityData.id}
          classes={{ root: classes.listItem }}
          disableGutters
        >
          <EntityCardComponent
            entity={entityData}
            workspace={props.workspace}
            projectId={props.projectId}
          />
        </ListItem>
      );
    });

  const pagination = (
    <div className={classes.pagination}>
      <TablePagination
        component="div"
        count={props.data.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={paginationPosition.page}
        rowsPerPage={paginationPosition.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </div>
  );

  return (
    <div className={classes.content}>
      <List dense>{listContent}</List>
      {pagination}
    </div>
  );
});
