import { Button } from '@mui/material';

interface Props {
  open: () => void;
}

export const AddElementButtonComponent = (props: Props) => {
  const { open: openConfig } = props;
  return (
    <Button
      sx={{
        // visibility: isVisible ? 'hidden' : 'visible',
        mr: 2,
        mt: 1,
        textDecoration: 'uppercase',
      }}
      variant="contained"
      color="secondary"
      onClick={openConfig}
    >
      add element
    </Button>
  );
};
