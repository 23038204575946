import {
  BaseEntity,
  BaseStateAction,
} from '~src/data/base/old/base-array-reducer';

export function singleAction<V extends BaseEntity>(
  v: V,
  type: string,
): BaseStateAction<V> {
  return {
    type,
    payload: {
      single: v,
    },
  };
}

export function deleteAction<V extends BaseEntity>(
  id: number | string,
  type: string,
): BaseStateAction<V> {
  return {
    type,
    payload: {
      id,
    },
  };
}

export function multipleDeleteAction<V extends BaseEntity>(
  ids: Array<number | string>,
  type: string,
): BaseStateAction<V> {
  return {
    type,
    payload: {
      ids,
    },
  };
}

export function multipleAction<V extends BaseEntity>(
  v: V[],
  type: string,
): BaseStateAction<V> {
  return {
    type,
    payload: {
      multiple: v,
    },
  };
}

export function deleteAllAction<V extends BaseEntity>(
  type: string,
): BaseStateAction<V> {
  return {
    type,
  };
}

export function updaterAction<V extends BaseEntity>(
  id: number | string,
  updater: (v: V) => V,
  type: string,
): BaseStateAction<V> {
  return {
    type,
    payload: {
      id,
      updater,
    },
  };
}
