import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectWorkspace } from '~src/data/store/modules/workspaces/workspaces/actions';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { workspaceTabPath } from '~src/navigation/paths/workspace/paths';
import { AppDispatch } from '~src/store/store';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';

import { CreateProjectComponent } from '../../project/create.component';
import { WorkspaceTabs } from '../tab/workspace-tabs.component';

interface Props {
  workspace: WorkspaceState;
}

export const WorkspaceCardMenuComponent = React.memo((props: Props) => {
  const projectDialog = useVisible();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const menu = useMenu();

  function handleManageWorkspace(_event: React.MouseEvent<HTMLElement>) {
    dispatch(selectWorkspace(props.workspace.id));
    menu.handleClose();
    navigate(
      workspaceTabPath(
        props.workspace.tenantId,
        props.workspace.id,
        WorkspaceTabs.Settings,
      ),
    );
  }

  function handleAddProject(_event: React.MouseEvent<HTMLElement>) {
    menu.handleClose();
    projectDialog.open();
  }

  function handleCloseCreateProjectDialog() {
    projectDialog.close();
  }

  return (
    <div>
      <IconButton onClick={menu.handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menu.anchorEl}
        keepMounted={false}
        open={menu.isOpen}
        onClose={menu.handleClose}
      >
        <MenuItem onClick={handleManageWorkspace}>
          <ListItemText primary="Manage workspace" />
        </MenuItem>
        <MenuItem onClick={handleAddProject}>
          <ListItemText primary="Add project" />
        </MenuItem>
      </Menu>
      <CreateProjectComponent
        workspace={props.workspace}
        open={projectDialog.isShowing}
        handleClose={handleCloseCreateProjectDialog}
      />
    </div>
  );
});
