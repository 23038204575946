import * as d3 from 'd3';

// export const toString = (d) => {
//   let s = d.labels ? d.labels[0] : d.type;

//   s += ` (<id>:  ${d.id}`;

//   Object.keys(d.properties).forEach((property) => {
//     s += `, ${property} : ${JSON.stringify(d.properties[property])}`;
//   });

//   s += ')';

//   return s;
// };

export const truncateText = (str = '', length = 100) => {
  const ending = '...';

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }

  return str;
};

export const rotate = (
  cx: number,
  cy: number,
  x: number,
  y: number,
  angle: number,
) => {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;

  return { x: nx, y: ny };
};

export interface Vector {
  x: number;
  y: number;
}

export const unitaryVector = (
  source: Vector,
  target: Vector,
  newLength = 1,
) => {
  const length =
    Math.sqrt((target.x - source.x) ** 2 + (target.y - source.y) ** 2) /
    Math.sqrt(newLength);

  return {
    x: (target.x - source.x) / length,
    y: (target.y - source.y) / length,
  };
};

export const darkenColor = (color: string) => d3.rgb(color).darker(1);

export const invertColor = (hexColor: string) => {
  let color = hexColor;

  if (hexColor.indexOf('#') === 0) {
    color = hexColor.slice(1);
  }

  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  if (color.length !== 6) {
    throw new Error('Invalid HEX color');
  }

  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
};

export function wrap<Datum>(
  text: d3.Selection<SVGTextElement, Datum, SVGGElement, undefined>,
  width: number,
  dyAdjust: number,
  lineHeightEms: number,
  lineHeightSquishFactor: number,
  splitOnHyphen: boolean,
  centreVertically: boolean,
) {
  // Use default values for the last three parameters if values are not provided.
  if (!lineHeightEms) lineHeightEms = 1.05;
  if (!lineHeightSquishFactor) lineHeightSquishFactor = 1;
  if (splitOnHyphen == null) splitOnHyphen = true;
  if (centreVertically == null) centreVertically = true;

  text.each(function () {
    const text = d3.select(this),
      x = text.attr('x'),
      y = text.attr('y');

    const words: string[] = [];
    text
      .text()
      .split(/\s+/)
      .forEach(function (w) {
        if (splitOnHyphen) {
          const subWords = w.split('-');
          for (let i = 0; i < subWords.length - 1; i++)
            words.push(subWords[i] + '-');
          words.push(subWords[subWords.length - 1] + ' ');
        } else {
          words.push(w + ' ');
        }
      });

    text.text(null); // Empty the text element

    // `tspan` is the tspan element that is currently being added to
    let tspan = text.append('tspan');

    let line = ''; // The current value of the line
    let prevLine = ''; // The value of the line before the last word (or sub-word) was added
    let nWordsInLine = 0; // Number of words in the line
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      prevLine = line;
      line = line + word;
      ++nWordsInLine;
      tspan.text(line.trim());
      // const computedTextLength = tspan.node()?.getComputedTextLength() ?? 0;
      const computedTextLength = line.length;
      if (computedTextLength > width && nWordsInLine > 1) {
        // The tspan is too long, and it contains more than one word.
        // Remove the last word and add it to a new tspan.
        tspan.text(prevLine.trim());
        prevLine = '';
        line = word;
        nWordsInLine = 1;
        tspan = text.append('tspan').text(word.trim());
      }
    }

    const tspans = text.selectAll('tspan');

    let h = lineHeightEms;
    // Reduce the line height a bit if there are more than 2 lines.
    if (tspans.size() > 2)
      for (let i = 0; i < tspans.size(); i++) h *= lineHeightSquishFactor;

    tspans.each(function (d, i) {
      // Calculate the y offset (dy) for each tspan so that the vertical centre
      // of the tspans roughly aligns with the text element's y position.
      let dy = i * h + dyAdjust;
      if (centreVertically) dy -= ((tspans.size() - 1) * h) / 2;
      d3.select(this)
        .attr('y', y)
        .attr('x', x)
        .attr('dy', dy + 'em');
    });
  });
}
