import { handleWarrantHoldings } from '~src/data/store/modules/holdings/warrant/handler';
import { warrantHoldingsActions } from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchWarrantHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetWarrantHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetWarrantHoldingsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getWarrantHoldings) {
        return handleWarrantHoldings(dispatch, data.getWarrantHoldings);
      }
      return [];
    });
  };
}

export function createWarrantHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateWarrantHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateWarrantHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateWarrantHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createWarrantHoldings) {
        return handleWarrantHoldings(dispatch, data.createWarrantHoldings);
      }
      return [];
    });
  };
}

export function updateWarrantHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateWarrantHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateWarrantHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateWarrantHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateWarrantHoldings) {
        return handleWarrantHoldings(dispatch, data.updateWarrantHoldings);
      }
      return [];
    });
  };
}

export function upsertWarrantHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertWarrantHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertWarrantHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertWarrantHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertWarrantHoldings) {
        return handleWarrantHoldings(dispatch, data.upsertWarrantHoldings);
      }
      return [];
    });
  };
}

export function deleteWarrantHoldings(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteWarrantHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteWarrantHoldingsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteWarrantHoldings) {
        dispatch(
          warrantHoldingsActions.removeManyElements(
            data.deleteWarrantHoldings.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
