import React from 'react';
import { NumericFormat } from 'react-number-format';

export const NumberFormatCustomFunction = (
  props: any,
  inputRef: React.ForwardedRef<unknown>,
) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: +values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={5}
      prefix=""
    />
  );
};

export const NumberFormatCustom = React.forwardRef(NumberFormatCustomFunction);
