import { StockHoldingState } from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import { StockHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseStockHolding(
  stockHolding: StockHolding,
): StockHoldingState {
  return {
    id: stockHolding.id,
    stockId: stockHolding.stock?.id ?? '',
    holdingId: stockHolding.holding?.id ?? '',
  };
}
