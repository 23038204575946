import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useTransfersAlgorithm } from '~src/domain/workspace/components/project/transfer/algorithm/use-algorithm';
import { TransferCardsListComponent } from '~src/domain/workspace/components/project/transfer/card/transfer-cards-list.component';
import { useUpdateState } from '~src/utils/common/use-update-state';

import { Theme } from '@mui/material';

import { TransfersToolbar } from './transfers-toolbar.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export const HoldingTransfersComponent = React.memo((props: Props) => {
  const { holding, project, workspace } = props;
  const transfers = useSelector((state: RootState) =>
    getTransfersByHoldingId(selectWorkspaceData(state), holding.id),
  );

  const { classes } = useStyles();
  const algo = useTransfersAlgorithm(workspace, project, transfers);
  // const viewFields = useTransferViewFields(
  //   workspace,
  //   project.id,
  //   holding,
  //   algo.fields
  // );
  const updateStateData = useUpdateState();

  // const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
  //   TableOperations.useSelectData(algo.algoData.filtered);

  return (
    <div className={classes.root}>
      <TransfersToolbar
        workspace={workspace}
        projectId={project.id}
        holding={holding}
      />
      <div className={classes.content}>
        <TransferCardsListComponent
          workspace={workspace}
          projectId={project.id}
          data={algo.algoData.filtered}
          setUpdateState={updateStateData.setUpdateState}
        />

        {/* <TableComponent<TransferDetailed>
          data={algo.algoData.filtered}
          fields={viewFields}
          selectedData={selectedData}
          selectable={false}
          filters={algo.algoData.filters}
          dispatchFilters={algo.algoData.dispatchFilters}
          sorters={algo.algoData.sorters}
          dispatchSorters={algo.algoData.dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        /> */}
      </div>
    </div>
  );
});
