import { differenceWith } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updateReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { ReportState } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  selectData,
  selectUserData,
} from '~src/data/store/selectors/selectors';
import {
  getUsersByIds,
  getUsersByWorkspaceId,
} from '~src/data/store/selectors/user/user/selectors';
import { AppDispatch } from '~src/store/store';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Checkbox,
  TextField,
} from '@mui/material';
import { User, MultipleRelationOperationType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  report: ReportState;
  handleClose: () => void;
  handleCancel: () => void;
  isShowing: boolean;
}

export const EditReportUsersComponent = React.memo((props: Props) => {
  const { workspace, report, handleClose, handleCancel, isShowing } = props;
  const existingUsers = useSelector((state: RootState) =>
    getUsersByIds(selectUserData(state), report.userIds),
  );
  const usersInWorkspace = useSelector((state: RootState) =>
    getUsersByWorkspaceId(selectData(state), workspace.id),
  );
  const dispatch = useDispatch<AppDispatch>();
  const handleAdd = () => {
    const newUsers = differenceWith(
      users,
      existingUsers,
      (a, b) => a.id === b.id,
    );
    const removedUsers = differenceWith(
      existingUsers,
      users,
      (a, b) => a.id === b.id,
    );

    const userOperations: graphqlWorkspaceTypes.MultipleRelationOperationIntInputType[] =
      [];
    newUsers.map((value) => {
      userOperations.push({
        id: value.id,
        type: MultipleRelationOperationType.add,
      });
    });
    removedUsers.map((value) => {
      userOperations.push({
        id: value.id,
        type: MultipleRelationOperationType.remove,
      });
    });
    return dispatch(
      updateReports(workspace.tenantId, workspace.id, [
        {
          id: report.id,
          users: userOperations,
        },
      ]),
    ).then(() => handleClose());
  };
  const [users, setUsers] = React.useState(existingUsers);
  React.useEffect(() => setUsers(existingUsers), [existingUsers]);
  const onChange = (newValue: User[]) => {
    setUsers(newValue);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <DialogComponent
      dialogProps={{ open: isShowing, onClose: handleClose, fullWidth: true }}
      closeOnBackDrop={false}
    >
      <DialogContentComponent
        handleCancel={handleCancel}
        handleOnSave={handleAdd}
        title="Edit users"
      >
        <Autocomplete
          sx={{ mt: 1 }}
          multiple
          // id="cheDAkboxes-tags-demo"
          options={usersInWorkspace}
          disableCloseOnSelect
          value={users}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onChange={(
            _event: React.ChangeEvent<unknown>,
            newValue: User[],
            _reason: AutocompleteChangeReason,
          ) => {
            onChange(newValue);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Users" placeholder="User" />
          )}
        />
      </DialogContentComponent>
    </DialogComponent>
  );
});
