import { useDrag, useDrop } from 'react-dnd';

export interface DragDropSubComponentProps {
  id: string;
  move: (fromId: string, toId: string) => void;
  canBeMoved: boolean;
}

const ItemTypes = {
  DASHBOARDSUBCOMPONENT: 'dashboardSubComponent',
};

export function useDragDropSubComponent(props: DragDropSubComponentProps) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.DASHBOARDSUBCOMPONENT,
    item: { id: props.id },
    canDrag: () => props.canBeMoved,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.DASHBOARDSUBCOMPONENT,
    drop: (item) => props.move((item as any).id, props.id),
    canDrop: () => props.canBeMoved,
    // canDrop: () => canMoveSubComponent(x, y),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  return {
    drag,
    isDragging,
    drop,
    isOver,
  };
}
