import { Typography } from '@mui/material';
import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

export const ValuationNode = React.memo(
  ({ data, isConnectable }: NodeProps) => {
    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
        <div>Valuation</div>
        <Typography variant="caption">{JSON.stringify(data)}</Typography>
      </>
    );
  },
);
