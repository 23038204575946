import './cron-style.css';

import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedulers } from '~src/data/store/modules/schedulers/requests';
import { updateReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { ReportState } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getSchedulerById } from '~src/data/store/selectors/workspace/projects/report/scheduler/selectors';
import {
  InputData,
  ReportFormComponent,
} from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/form/report-form.component';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  report: ReportState;
  handleClose: () => void;
  isShowing: boolean;
  handleCancel: () => void;
}

export const EditReportComponent = memo((props: Props) => {
  const { handleClose, isShowing, handleCancel, workspace, report } = props;

  const scheduler = useSelector((state: RootState) =>
    getSchedulerById(selectWorkspaceData(state), { id: report.schedulerId }),
  );

  const dispatch = useDispatch<AppDispatch>();

  const updateScheduler = React.useCallback(
    (cronValue: string) => {
      if (scheduler?.cron !== cronValue) {
        return dispatch(
          updateSchedulers(workspace.tenantId, workspace.id, [
            { cron: cronValue, id: report.schedulerId },
          ]),
        ).then(() => true);
      }
      return Promise.resolve(true);
    },
    [
      dispatch,
      report.schedulerId,
      scheduler?.cron,
      workspace.id,
      workspace.tenantId,
    ],
  );

  const onSave = React.useCallback(
    (data: InputData, cronValue: string) => {
      console.log('SAVING');
      return updateScheduler(cronValue).then(() => {
        return dispatch(
          updateReports(workspace.tenantId, workspace.id, [
            {
              id: report.id,
              // schedulerId: scheduler.id,
              // projectId,
              description: data.description,
              name: data.name,
              // users,
            },
          ]),
        ).then(() => handleClose());
      });
    },
    [
      dispatch,
      handleClose,
      report.id,
      updateScheduler,
      workspace.id,
      workspace.tenantId,
    ],
  );

  return (
    <ReportFormComponent
      handleCancel={handleCancel}
      handleClose={handleClose}
      isShowing={isShowing}
      onSave={onSave}
      inputData={{
        cronValue: scheduler?.cron ?? '* * * * * *',
        description: report.description ?? '',
        name: report.name ?? '',
      }}
    />
  );
});
