import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteDashboardElements as deleteDashboardElementsRequest,
  updateDashboardElements as updateDashboardElementsRequest,
  upsertDashboardElements as upsertDashboardElementsRequest,
} from '~src/data/store/modules/common/dashboard/element/requests';
import { updateHoldingDashboardConfigs } from '~src/data/store/modules/holdings/configs/dashboard-configs/requests';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';

export function useHoldingDashboardConfig(
  workspace: WorkspaceState,
  holdingConfig: HoldingConfigState,
  holdingDashboardConfig: HoldingDashboardConfigState,
) {
  const dispatch = useDispatch<AppDispatch>();

  const layouts = React.useMemo(() => {
    return holdingDashboardConfig.layouts;
  }, [holdingDashboardConfig.layouts]);

  const updateLayouts = React.useCallback(
    (layouts: ReactGridLayout.Layouts) => {
      const holdingDashboardConfigInput: graphqlWorkspaceTypes.UpdateHoldingDashboardConfigInputType =
        {
          id: holdingDashboardConfig.id,
          name: holdingDashboardConfig.name,
          layouts: JSON.stringify(layouts),
        };
      return dispatch(
        updateHoldingDashboardConfigs(
          workspace.tenantId,
          workspace.id,
          holdingConfig.id,
          [holdingDashboardConfigInput],
        ),
      ).then((dashboardConfigs) => {
        const layouts = dashboardConfigs[0].layouts;
        if (layouts) {
          return layouts;
        }
      });
    },
    [
      dispatch,
      holdingConfig.id,
      holdingDashboardConfig.id,
      holdingDashboardConfig.name,
      workspace.id,
      workspace.tenantId,
    ],
  );

  const addDashboardElements = React.useCallback(
    (dashboardElements: graphqlWorkspaceTypes.DashboardElement[]) => {
      // !==
      return dispatch(
        upsertDashboardElementsRequest(
          workspace.tenantId,
          workspace.id,
          dashboardElements,
        ),
      ).then((elements) => {
        const elementOperations: graphqlWorkspaceTypes.MultipleRelationOperationStringInputType[] =
          dashboardElements.map((dashboardElement) => {
            return {
              id: dashboardElement.id,
              type: MultipleRelationOperationType.add,
            };
          });

        const holdingDashboardConfigInput: graphqlWorkspaceTypes.UpdateHoldingDashboardConfigInputType =
          {
            id: holdingDashboardConfig.id,
            name: holdingDashboardConfig.name,
            elementOperations,
          };
        return dispatch(
          updateHoldingDashboardConfigs(
            workspace.tenantId,
            workspace.id,
            holdingConfig.id,
            [holdingDashboardConfigInput],
          ),
        ).then(() => elements);
      });
    },
    [
      dispatch,
      holdingConfig.id,
      holdingDashboardConfig.id,
      holdingDashboardConfig.name,
      workspace.id,
      workspace.tenantId,
    ],
  );
  const updateDashboardElements = React.useCallback(
    (
      dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
    ) => {
      return dispatch(
        updateDashboardElementsRequest(
          workspace.tenantId,
          workspace.id,
          dashboardElements,
        ),
      ).then((elements) => elements);
    },
    [dispatch, workspace.id, workspace.tenantId],
  );
  const removeDashboardElements = React.useCallback(
    (dashboardElementIds: string[]) => {
      // !==
      return dispatch(
        deleteDashboardElementsRequest(
          workspace.tenantId,
          workspace.id,
          dashboardElementIds,
        ),
      ).then((_elements) => {
        const elementOperations: graphqlWorkspaceTypes.MultipleRelationOperationStringInputType[] =
          dashboardElementIds.map((dashboardElementId) => {
            return {
              id: dashboardElementId,
              type: MultipleRelationOperationType.remove,
            };
          });

        const holdingDashboardConfigInput: graphqlWorkspaceTypes.UpdateHoldingDashboardConfigInputType =
          {
            id: holdingDashboardConfig.id,
            name: holdingDashboardConfig.name,
            elementOperations,
          };
        return dispatch(
          updateHoldingDashboardConfigs(
            workspace.tenantId,
            workspace.id,
            holdingConfig.id,
            [holdingDashboardConfigInput],
          ),
        ).then(() => {
          return dashboardElementIds;
        });
      });
    },
    [
      dispatch,
      holdingConfig.id,
      holdingDashboardConfig.id,
      holdingDashboardConfig.name,
      workspace.id,
      workspace.tenantId,
    ],
  );
  return {
    layouts,
    updateLayouts,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  };
}
