import React from 'react';
import { EditWarrant } from '~src/components/fields/workspace/asset/edit/asset-types/edit-warrant';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { WarrantHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/warrant/create-action';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  warrantHoldingInput: WarrantHoldingInputType;
  setWarrantHoldingInput: (
    warrantHoldingInput: graphqlWorkspaceTypes.CreateWarrantHoldingInputType,
  ) => void;
}

export const CreateWarrantHoldingFieldsComponent = React.memo(
  (props: Props) => {
    const handleWarrantChange = (warrantId: string) => {
      props.setWarrantHoldingInput({
        ...props.warrantHoldingInput,
        warrantId,
      });
    };

    return (
      <EditWarrant
        handleValue={handleWarrantChange}
        warrantId={props.warrantHoldingInput.warrantId}
        workspace={props.workspace}
      />
    );
  },
);
