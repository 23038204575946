// import { evaluate, parse, parser, Parser } from 'mathjs';
import * as math from 'mathjs';
import * as React from 'react';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { StockGoalRuleState } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Alert, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { GoalRuleMenuComponent } from './menu.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  quotes: QuoteState[];
  stockHoldingId: string;
  goalRule: StockGoalRuleState;
}

function maxQuote(quotes: QuoteState[]): QuoteState | undefined {
  const max = quotes.reduce((prev, curr) => {
    return (prev.close ?? 0) < (curr.close ?? 0) ? curr : prev;
  }, quotes[0]);
  return max;
}

function newestQuote(quotes: QuoteState[]): QuoteState | undefined {
  const newest = quotes.reduce(
    (prev, curr) => (prev.date.getTime() <= curr.date.getTime() ? curr : prev),
    quotes[0],
  );
  return newest;
}

function isMarked(
  quotes: QuoteState[],
  node: math.MathNode | undefined,
): boolean {
  if (!node) {
    return true;
  }
  // let marked = false;
  const newest = newestQuote(quotes);
  const highest = maxQuote(quotes);
  const scope = {
    newest: newest?.value ?? -1,
    highest: highest?.value ?? -1,
  };
  let marked = false;
  try {
    const code = node.compile();
    marked = code.evaluate(scope);
  } catch (err) {
    console.log('ERROR', err);
  }
  // const value = 0; // goalRule.value;
  // if (newest && value) {
  //   if ((newest.value ?? 0) < value) {
  //     marked = true;
  //   }
  // }
  return marked;
}

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    margin: theme.spacing(1),
    borderStyle: 'solid',
  },
  invalid: {
    margin: theme.spacing(1),
  },
  unmarked: {
    borderColor: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
  },
  marked: {
    borderColor: theme.palette.error.main,
  },
  name: {
    marginLeft: theme.spacing(2),
  },
  formula: {
    marginLeft: theme.spacing(2),
    fontStyle: 'italic',
  },
  menu: {
    // float: 'right',
  },
}));

export const GoalRuleComponent = React.memo((props: Props) => {
  const { classes, cx } = useStyles();
  const [formulaError, setFormulaError] = React.useState<string | undefined>();
  const formulaAsNode = React.useMemo(() => {
    try {
      const node = math.parse(props.goalRule.goalRule.formula);
      setFormulaError(undefined);
      return node;
    } catch (err) {
      if (err instanceof SyntaxError) {
        setFormulaError(err.message);
      } else {
        setFormulaError('unknown error');
      }
      return undefined;
    }
  }, [props.goalRule.goalRule.formula]);
  const marked = isMarked(props.quotes, formulaAsNode);
  const invalidFormulaElement = formulaError ? (
    <Alert severity="error">Invalid formula: {formulaError}</Alert>
  ) : undefined;
  return (
    <div>
      <div className={classes.invalid}>{invalidFormulaElement}</div>
      <div
        className={cx(classes.box, marked ? classes.marked : classes.unmarked)}
      >
        <Grid container justifyContent={'space-between'}>
          <Grid item alignItems="center" className={classes.header}>
            <Typography variant="body1" className={classes.name}>
              {props.goalRule.goalRule.name}
            </Typography>
          </Grid>
          <Grid item className={classes.menu}>
            <GoalRuleMenuComponent
              projectId={props.projectId}
              stockHoldingId={props.stockHoldingId}
              stockGoalRule={props.goalRule}
              workspace={props.workspace}
            />
          </Grid>
          <Grid item>
            <Typography variant="caption" className={classes.formula}>
              {props.goalRule.goalRule.formula}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
});
