import * as uuid from 'uuid';
import { createBondHoldings } from '~src/data/store/modules/holdings/bond/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateBondHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/bond-holdings/form/fields.component';
import { AppDispatch } from '~src/store/store';
import { PartialBy } from '~src/utils/common/typescript-utils';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type BondHoldingInputType = PartialBy<
  graphqlWorkspaceTypes.CreateBondHoldingInputType,
  'bondId'
>;

export const createAction = (
  workspace: WorkspaceState,
  input: BondHoldingInputType,
  setHoldingDataInput: (data: BondHoldingInputType) => void,
) => {
  const fields = (
    <CreateBondHoldingFieldsComponent
      workspace={workspace}
      setBondHoldingInput={setHoldingDataInput}
      bondHoldingInput={input}
    />
  );
  const valid = () => {
    return input.bondId !== undefined;
  };

  const action = (holding: HoldingState) => {
    return (dispatch: AppDispatch) => {
      const bondId = input.bondId;
      if (bondId) {
        return dispatch(
          createBondHoldings(workspace.tenantId, workspace.id, [
            {
              id: uuid.v1(),
              holdingId: holding.id,
              bondId,
            },
          ]),
        );
      }
      throw 'Not valid, need to set bondId';
    };
  };
  return { action, valid, fields };
};
