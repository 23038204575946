import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import {
  workspaceProjectRootPath,
  workspaceProjectsRootPath,
} from '~src/navigation/paths/workspace/project/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useWorkspaceBreadcrumbs } from './workspace-breadcrumbs';

export function useProjectsBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();

  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);

  if (tenantId != null && workspaceId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={workspaceProjectsRootPath(tenantId, workspaceId)}
      >
        projects
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceProjectsRootPath(tenantId, workspaceId)}
        to={workspaceProjectsRootPath(tenantId, workspaceId)}
      >
        projects
      </LinkRouter>
    );
    return [...workspaceBreadcrumb, projectElement];
  }
  return [];
}

export function useProjectBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId, projectId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
  }>();
  const projectsBreadcrumbs = useProjectsBreadcrumbs(false);
  const workspace = useSelector((state: RootState) => {
    if (workspaceId != null && tenantId != null)
      return getWorkspaceById(selectWorkspaceData(state), {
        id: workspaceId,
        tenantId,
      });
  });
  const project = useSelector((state: RootState) => {
    if (workspaceId != null && projectId != null) {
      return getProjectById(selectWorkspaceData(state), {
        workspaceId,
        id: projectId,
      });
    }
  });
  if (workspace && project) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        key={workspaceProjectRootPath(
          workspace.tenantId,
          project.workspaceId,
          project.id,
        )}
      >
        {project.name}
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceProjectRootPath(
          workspace.tenantId,
          project.workspaceId,
          project.id,
        )}
        to={workspaceProjectRootPath(
          workspace.tenantId,
          project.workspaceId,
          project.id,
        )}
      >
        {project.name}
      </LinkRouter>
    );
    return [...projectsBreadcrumbs, projectElement];
  }
  return [];
}
export function ProjectBreadcrumbComponent() {
  const projectsBreadcrumbs = useProjectBreadcrumbs(true);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {projectsBreadcrumbs}
    </Breadcrumbs>
  );
}
export function ProjectsBreadcrumbComponent(props: { last: boolean }) {
  const projectsBreadcrumbs = useProjectsBreadcrumbs(props.last);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {projectsBreadcrumbs}
    </Breadcrumbs>
  );
}
