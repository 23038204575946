import { MultipleRelationOperation } from '../relation';

export type UserIdType = number;

export interface User {
  id: UserIdType;
  name: string;
  email: string;
  password?: string;
  // groupIds: number[];
  // roles: IUserUserRole[] | null;
  // investorRoles: IInvestorUserRole[] | null;
  // role: string;
}

export interface UserCreateInput {
  name: string;
  email: string;
  password: string;
  groupRelations: MultipleRelationOperation[];
  // groupRelations: IRelation[];
}

export interface UserUpdateInput {
  id: UserIdType;
  name?: string;
  email?: string;
  password?: string;
  groupRelations?: MultipleRelationOperation[];
}

export interface UserProperties {
  id?: boolean;
  name?: boolean;
  email?: boolean;
  password?: boolean;
  // groupIds?: boolean;
}

// type IUserRelationProperties = {};
// interface IUserRelationProperties {
//   groups?: IGroupPropertiesConfig;
// }

export interface UserPropertiesConfig {
  properties: UserProperties;
  relationProperties?: {}; // IUserRelationProperties;
}

export interface UserDeleteInput {
  id: UserIdType;
}

export function defaultUserProperties(): UserProperties {
  return {
    id: true,
    name: true,
    email: true,
    password: false,
    // groupIds: true,
  };
}

// export function defaultUserRelationProperties(): IUserRelationProperties {
//   return {
//     groups: defaultGroupPropertiesConfig(),
//   };
// }

export function defaultUserPropertiesConfig(): UserPropertiesConfig {
  return {
    properties: defaultUserProperties(),
    relationProperties: {}, // defaultUserRelationProperties(),
  };
}
