import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useProjectReportFields } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/use-project-report-fields';
import { AppDispatch } from '~src/store/store';

import { InputData } from './project-report-input.data';

interface Props {
  workspace: WorkspaceState;
  projectReport: ProjectReportState;
  inputData?: InputData;
}

export const useProjectReportForm = (props: Props) => {
  const { projectReport, workspace, inputData } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputData) => {
      return dispatch(
        updateProjectReports(workspace.tenantId, workspace.id, [
          {
            id: projectReport.id,
            name: data.name,
            elementOperations: [],
            periodStart: data.periodStart,
            periodEnd: data.periodEnd,
            published: data.published,
            compactType: data.compactType,
          },
        ]),
      );
    },
    [dispatch, projectReport, workspace.id, workspace.tenantId],
  );

  const { control, handleSubmit, watch, setValue } = useForm<InputData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useProjectReportFields(control, setValue);

  const onSubmit: SubmitHandler<InputData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return fields;
};
