import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  bondHoldingsSelectors,
  BondHoldingState,
} from '../../../../../reducers/holding/holding-types/bond/bond-holding/reducer';

const getBondHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.bondHolding.bondHoldings;

export const getBondHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getBondHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (bondHoldingState, holdingId): BondHoldingState | undefined => {
    return bondHoldingsSelectors.selectElementByIdx(
      bondHoldingState,
      holdingId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getBondHoldings = createSelector(
  (state: WorkspaceDataState) => getBondHoldingState(state),
  (bondHoldings) => {
    return bondHoldingsSelectors.selectAllElements(bondHoldings);
  },
);

export const getBondHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getBondHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (bondHoldings, id): BondHoldingState | undefined => {
    return bondHoldingsSelectors.selectElementByI(bondHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getBondHoldingsByBondId = createCachedSelector(
  (state: WorkspaceDataState) => getBondHoldings(state),
  (_state: WorkspaceDataState, bondId: string) => bondId,
  (bondHoldings, bondId): BondHoldingState[] => {
    return bondHoldings.filter((h) => h.bondId === bondId);
  },
)({
  keySelector: (_state, bondId) => `${bondId}`,
  selectorCreator: createSelector,
});
