import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseCryptoId {
  id: string;
  workspaceId: string;
}

export type CryptoState = Omit<
  graphqlWorkspaceTypes.Crypto,
  'asset' | 'currency' | '_type'
> & {
  workspaceId: string;
  assetId: string;
  currencyId: string;
};

export function cryptoToCryptoState(crypto: CryptoState) {
  return crypto;
}
const initialState: RecordState<CryptoState, string>[] = [];

const selectKeyId = (crypto: BaseCryptoId) => crypto.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseCryptoId, b: BaseCryptoId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseCryptoId, CryptoState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const cryptosSlice = createBaseRecordSlice('cryptos', adapter);
export const cryptosSelectors = adapter.selectors;

export const cryptosReducer = cryptosSlice.reducer;
export const cryptosActions = cryptosSlice.actions;

export const initialCryptoState = {
  cryptos: initialState,
};

export const cryptoReducer = combineReducers({
  cryptos: cryptosReducer,
});
