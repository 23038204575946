import { holdingsActions } from '~src/data/store/reducers/holding/holdings/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleHoldingObjs, HoldingHandler } from './handler';

export function fetchHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
  handler: HoldingHandler,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetHoldingsQueryVariables = {
      ids,
      withTransfers: true,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getHoldings) {
        return handler(dispatch, tenantId, workspaceId, data.getHoldings, [
          Operation.create,
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function fetchHoldingObjs(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetHoldingObjsDocument;
    const variables: graphqlWorkspaceTypes.GetHoldingObjsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getHoldingObjs) {
        return handleHoldingObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.getHoldingObjs,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateHoldingInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateHoldingsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createHoldings) {
        return handleHoldingObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.createHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateHoldingInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateHoldingsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateHoldings) {
        return handleHoldingObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.updateHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertHoldingInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertHoldingsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertHoldings) {
        return handleHoldingObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteHoldings(projectId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    return dispatch(
      holdingsActions.removeManyElements(
        ids.map((p) => {
          return {
            projectId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteHoldings(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteHoldingsMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context)
      .then((data) => {
        if (data.deleteHoldings) {
          return dispatch(handleDeleteHoldings(projectId, data.deleteHoldings));
        }
      })
      .then((value) => {
        return value?.payload;
      });
  };
}
