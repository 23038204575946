import React, { memo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectWorkspace } from '~src/data/store/modules/workspaces/workspaces/actions';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { selectedProjectsActions } from '~src/data/store/reducers/workspace/projects/selected/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectsByWorkspaceId } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { WorkspaceCardMenuComponent } from '~src/domain/workspace/components/workspace/list/workspace-card-menu.component';
import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';
import { AppDispatch } from '~src/store/store';

import { Button, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
}

export const WorkspaceCardComponent = memo((props: Props) => {
  const { workspace } = props;
  const projects = useSelector((state: RootState) =>
    getProjectsByWorkspaceId(selectWorkspaceData(state), workspace.id),
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSelectProject = React.useCallback(
    (workspace: WorkspaceState, project: ProjectState) => {
      batch(() => {
        dispatch(selectWorkspace(workspace.id));
        dispatch(selectedProjectsActions.set(project.id));
      });
      navigate(
        workspaceProjectRootPath(workspace.tenantId, workspace.id, project.id),
      );
    },
    [dispatch, navigate],
  );

  const projectsElement = React.useMemo(() => {
    return projects.map((project) => (
      <Grid2 key={project.id} xs={4}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => handleSelectProject(workspace, project)}
        >
          {project.name}
        </Button>
      </Grid2>
    ));
  }, [handleSelectProject, projects, workspace]);

  return (
    <Grid2 container xs={12}>
      <Grid2 container xs={12} pt={2} pl={2} pr={2} pb={1}>
        <Grid2 xs={11}>
          <Typography variant="h6">{workspace.name}</Typography>
        </Grid2>
        <Grid2 xs={1}>
          <WorkspaceCardMenuComponent workspace={workspace} />
        </Grid2>
      </Grid2>
      <Grid2 xs={12}>
        <Divider />
      </Grid2>
      <Grid2 container xs={12} rowSpacing={1} columnSpacing={1} mt={1} p={2}>
        {projectsElement}
      </Grid2>
    </Grid2>
  );
});
