import moment from 'moment';
import { createSelector } from 'reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { getHoldingsValues } from './holdings-values';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsValue = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    filter,
    useLiveValue,
  ) => {
    const valuations = getHoldingsValues(state, {
      workspaceId,
      projectId,
      holdings,
      date,
      currency,
      filter,
      useLiveValue,
    });
    return valuations.reduce((prev, curr) => {
      if (curr) {
        prev += curr.value;
      }
      return prev;
    }, 0);
  },
);
