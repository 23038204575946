import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { updateProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardElementsByIds } from '~src/data/store/selectors/common/dashboard/element/selectors';
import {
  selectViewData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useReportDashboardElements } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/use-report-dashboard-elements';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';

import { Box, Drawer } from '@mui/material';
import { DrawerHeader } from '~src/components/dashboard/drawer-header';
import { ProjectReportConfigComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/project-report-config.component';
import { useDashboard } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/use-dashboard';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  projectReport: ProjectReportState;
}

export const ProjectReportEditorComponent = React.memo((props: Props) => {
  const { portfolioProject, project, portfolio, workspace, projectReport } =
    props;
  const {
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  } = useReportDashboardElements(workspace);

  const layouts = React.useMemo(() => {
    return projectReport.layouts;
  }, [projectReport.layouts]);

  const dispatch = useDispatch<AppDispatch>();
  const updateLayouts = React.useCallback(
    (layouts: ReactGridLayout.Layouts) => {
      const projectReportInput: graphqlWorkspaceTypes.UpdateProjectReportInputType =
        {
          id: projectReport.id,
          layouts: JSON.stringify(layouts),
          elementOperations: [],
        };
      return dispatch(
        updateProjectReports(workspace.tenantId, workspace.id, [
          projectReportInput,
        ]),
      ).then((reports) => {
        const layouts = reports[0].layouts;
        if (layouts) {
          return layouts;
        }
      });
    },
    [dispatch, projectReport.id, workspace.id, workspace.tenantId],
  );

  const dashboardElements = useSelector((state: RootState) => {
    return getDashboardElementsByIds(
      selectViewData(state),
      projectReport.elementIds,
    );
  });
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'edit' }),
  );

  const reportConfig = React.useMemo((): Config => {
    return {
      ...config,
      period: new Period(
        moment(projectReport.periodStart),
        moment(projectReport.periodEnd),
      ),
      date: moment(projectReport.periodEnd),
      compactType: projectReport.compactType,
    };
  }, [
    config,
    projectReport.compactType,
    projectReport.periodEnd,
    projectReport.periodStart,
  ]);

  const addDashboardElementsToReport = React.useCallback(
    (dashboardElements: graphqlWorkspaceTypes.DashboardElement[]) => {
      return addDashboardElements(projectReport, dashboardElements);
    },
    [addDashboardElements, projectReport],
  );
  const updateDashboardElementsToReport = React.useCallback(
    (
      dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
    ) => {
      return updateDashboardElements(dashboardElements);
    },
    [updateDashboardElements],
  );
  const removeDashboardElementsToReport = React.useCallback(
    (dashboardElementIds: string[]) => {
      return removeDashboardElements(projectReport, dashboardElementIds);
    },
    [projectReport, removeDashboardElements],
  );

  const { menu, components, configOpen, toggleConfig } = useDashboard(
    workspace,
    project,
    portfolioProject,
    portfolio,
    reportConfig,
    layouts,
    updateLayouts,
    dashboardElements,
    addDashboardElementsToReport,
    removeDashboardElementsToReport,
    updateDashboardElementsToReport,
  );
  return (
    <Box>
      {menu}
      {components}
      <Drawer
        anchor="right"
        open={configOpen}
        onClose={toggleConfig}
        PaperProps={{ sx: { width: '40%' } }}
      >
        <DrawerHeader />
        <Box ml={2} mr={2} mt={2}>
          <ProjectReportConfigComponent
            projectReport={projectReport}
            workspace={workspace}
          />
        </Box>
      </Drawer>
    </Box>
  );
});
