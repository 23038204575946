import * as React from 'react';
import { useParams } from 'react-router-dom';
import { EntityComponent } from '~src/domain/workspace/components/entity/entity/entity.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

interface Props {
  workspace: WorkspaceState;
}

export const EntityRoot = React.memo((props: Props) => {
  const { entityId } = useParams<{ entityId: string }>();
  if (entityId) {
    return <EntityComponent workspace={props.workspace} entityId={entityId} />;
  }
  return <div>Not a valid entity Id</div>;
});
