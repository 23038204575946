import { parseEntityMedia } from '~src/data/store/modules/entities/media/parser';
import {
  FileStateVisitor,
  FileVisitable,
  FileVisitor,
} from '~src/data/store/visitors/common/file-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  entityMediasActions,
  EntityMediumState as EntityMediumState,
} from '~src/data/store/reducers/entity/medias/reducer';
import { EntityMedia } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface EntityMediumVisitor {
  visit(medium: EntityMediumVisitable): void;
  post(): void;
  fileVisitor: FileVisitor;
}

export class EntityMediumVisitable implements Visitable<EntityMediumVisitor> {
  constructor(
    private _entityId: string,
    private _medium: EntityMedia,
  ) {}
  public accept(visitor: EntityMediumVisitor) {
    visitor.visit(this);
    if (this._medium.file) {
      const fileVisitable = new FileVisitable(this._medium.file);
      fileVisitable.accept(visitor.fileVisitor);
    }
  }

  public parse(): EntityMediumState {
    return parseEntityMedia(this._entityId, this._medium);
  }
}

export class EntityMediumStateVisitor implements EntityMediumVisitor {
  private _mediums: EntityMediumState[];
  public fileVisitor: FileVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._mediums = [];
    this.fileVisitor = new FileStateVisitor(this._dispatch);
  }
  public visit(medium: EntityMediumVisitable): void {
    this._mediums.push(medium.parse());
  }
  post() {
    this._dispatch(entityMediasActions.upsertManyElements(this._mediums));
    this.fileVisitor.post();
  }
}
