import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getAllPapers } from '~src/data/store/selectors/finance/papers/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { PapersToolbar } from '~src/domain/workspace/components/finance/papers/papers-toolbar.component';
import { usePaperTableFields } from '~src/domain/workspace/components/finance/papers/use-paper-fields';
import { usePaperActions } from '~src/hooks/actions/finance/use-paper-actions';

import { Theme } from '@mui/material';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

interface Props {
  tenantId: string;
  workspaceId: string;
}

export type PaperData = PaperState;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export const PapersComponent = React.memo((props: Props) => {
  const { tenantId, workspaceId } = props;
  const { classes } = useStyles();
  const papers = useSelector((state: RootState) =>
    getAllPapers(selectFinancialData(state)),
  );

  const fields = usePaperTableFields(tenantId, workspaceId);

  const [initialSorters] = React.useState<TableField.Sorter<PaperData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );
  const { onUpdate, onDelete } = usePaperActions();

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(papers, initialSorters, initialFilters);

  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<PaperData>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        editable={{ onRowUpdate: onUpdate, onRowDelete: onDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        // handleSetUpdateState={setUpdateState}
        isSelected={isSelected}
        paginationOptions={{
          initialNumberOfRows: 10,
          rowsPerPageOptions: [10, 100, 1000],
        }}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onDelete,
    onUpdate,
    selectedData,
    sorters,
  ]);

  return (
    <div className={classes.root}>
      <PapersToolbar />
      <div className={classes.content}>{tableComponent}</div>
    </div>
  );
});
