import React from 'react';
import { NumberFormatCustom } from '~src/domain/workspace/components/finance/tools/number-format-custom';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export const useTargetReturn = () => {
  const [targetReturn, setTargetReturn] = React.useState(0.5);

  const targetReturnElement = (
    <Grid2 container>
      <Grid2 xs={3}>
        <Typography variant="body1">Target Return</Typography>
      </Grid2>
      <TextField
        value={targetReturn}
        onChange={(e) => {
          const value = eventParser(e) as number;
          setTargetReturn(value);
        }}
        InputProps={{ inputComponent: NumberFormatCustom }}
        inputProps={{
          style: {
            textAlign: 'right',
            margin: 0,
            paddingTop: 1,
            paddingBottom: 1,
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent', // Hide border by default
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)', // Show border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main', // Show border when focused
              borderWidth: '2px', // Make border thicker when focused
            },
          },
        }}
      />
    </Grid2>
  );
  return {
    targetReturnElement,
    targetReturn,
  };
};
