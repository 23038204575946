import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseInvestmentId {
  id: string;
  fundId: string;
}

export type InvestmentState = Omit<
  graphqlWorkspaceTypes.PrivateEquityFundInvestment,
  'holding'
> & {
  fundId: string;
  holdingId: string;
};

export const initialState: RecordState<InvestmentState, string>[] = [];

const selectKeyId = (investment: BaseInvestmentId) => investment.fundId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseInvestmentId, b: BaseInvestmentId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseInvestmentId,
  InvestmentState,
  string
>(initialState, selectKeyId, keyComparator, tComparator);

export const investmentsSlice = createBaseRecordSlice('investments', adapter);
export const investmentsSelectors = adapter.selectors;

export const investmentsReducer = investmentsSlice.reducer;
export const investmentsActions = investmentsSlice.actions;
