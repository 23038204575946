import { Control, Controller } from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputData } from '~src/domain/workspace/components/entity/entity/form/entity-input.data';

import {
  EntitySectors,
  entitySectorToString,
} from '~src/utils/interfaces/entity-sectors';
import { SelectItem } from '~src/components/fields/common/use-select';
import { useTextField } from '~src/utils/form/fields/text-field';

const items = Object.values(EntitySectors).map((sector) => {
  return { name: entitySectorToString(sector), value: sector, t: sector };
});

export const useEntityFields = (
  _workspace: WorkspaceState,
  control: Control<InputData, any>,
) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const descriptionField = useTextField(
    control,
    'description',
    'Description',
    '',
  );

  const sectorField = (
    <Controller
      name={'sector'}
      control={control}
      defaultValue={''}
      render={({ field }) => (
        <SelectItem
          items={items}
          handleT={field.onChange}
          t={field.value}
          label="sector"
        />
      )}
    />
  );

  const websiteField = useTextField(control, 'website', 'Website', '');

  return {
    nameField,
    descriptionField,
    sectorField,
    websiteField,
  };
};
