import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceFilesBaseName = 'files';

export const workspaceFilesRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(
    tenantId,
    workspaceId,
  )}/${workspaceFilesBaseName}`;
};

export const workspaceFileRootPath = (
  tenantId: string,
  workspaceId: string,
  id: string,
) => {
  return `${workspaceFilesRootPath(tenantId, workspaceId)}/${id}`;
};

export const workspaceFilesBasePath = workspaceFilesRootPath(
  tenantBaseId,
  workspaceBaseId,
);
