import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';

export interface BaseSelectedForecastId {
  objectType: ForecastObjectType;
  objectId: string;
  budgetId: string;
  // id: string;
}

export interface SelectedForecastState {
  id: string;
  objectType: ForecastObjectType;
  objectId: string;
  budgetId: string;
}

export const initialSelectedForecastsState = [];

const selectKeyId = (forecast: BaseSelectedForecastId) => forecast.budgetId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseSelectedForecastId, b: BaseSelectedForecastId) => {
  const diffObjectType = strcmp(a.objectType, b.objectType);
  if (diffObjectType !== 0) {
    return diffObjectType;
  }
  return strcmp(a.objectId, b.objectId);
};

const adapter = new EntityRecordAdapter<
  BaseSelectedForecastId,
  SelectedForecastState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const selectedForecastsSlice = createBaseRecordSlice(
  'scenario:selected',
  adapter,
);
export const selectedForecastsSelectors = adapter.selectors;

export const selectedForecastsReducer = selectedForecastsSlice.reducer;
export const selectedForecastsActions = selectedForecastsSlice.actions;
