import { combineReducers } from 'redux';
import {
  bankNoteReducer,
  BankNoteState,
  initialBankNoteState,
} from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import {
  bondReducer,
  BondState,
  initialBondState,
} from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import {
  fundReducer,
  FundState,
  initialFundState,
} from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import {
  initialLoanState,
  loanReducer,
  LoanState,
} from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import {
  initialWarrantState,
  warrantReducer,
  WarrantState,
} from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import {
  initialPrivateEquityFundState,
  privateEquityFundReducer,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/reducer';
import {
  initialStockState,
  stockReducer,
  StockState,
} from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import {
  assetsReducer,
  // AssetState,
  initialAssetsState,
} from '~src/data/store/reducers/asset/assets/reducer';
import { QuoteState } from '../finance/quote/reducer';
import { StockHoldingState } from '../holding/holding-types/stock/stock-holding/reducer';
import { StockPositionState } from '../holding/holding-types/stock/stock-position/reducer';
import { StockGoalRuleState } from '../holding/holding-types/stock/stock-goal-rule/reducer';

import { PrivateEquityFundState } from './asset-types/private-equity-fund/private-equity-funds/reducer';
import {
  cryptoReducer,
  initialCryptoState,
  CryptoState,
} from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import {
  categoryReducer,
  initialCategoryState,
} from '~src/data/store/reducers/asset/categories/reducer';

export const assetInitialState = {
  assets: initialAssetsState,
  stock: initialStockState,
  fund: initialFundState,
  loan: initialLoanState,
  warrant: initialWarrantState,
  privateEquityFund: initialPrivateEquityFundState,
  bankNote: initialBankNoteState,
  crypto: initialCryptoState,
  bond: initialBondState,
  category: initialCategoryState,
};

export const assetReducer = combineReducers({
  assets: assetsReducer,
  stock: stockReducer,
  fund: fundReducer,
  loan: loanReducer,
  warrant: warrantReducer,
  privateEquityFund: privateEquityFundReducer,
  bankNote: bankNoteReducer,
  crypto: cryptoReducer,
  bond: bondReducer,
  category: categoryReducer,
});

interface StockWithDetails extends StockState {
  quotes: QuoteState[];
  positions: StockPositionState[];
  goalRules: StockGoalRuleState[];
  stockHolding: StockHoldingState | undefined;
}

export interface AssetTypedInterface {
  workspaceId: string;
  assetId: string;
  id: string;
}

export type AssetTyped =
  | StockState
  | FundState
  | LoanState
  | WarrantState
  | PrivateEquityFundState
  | BankNoteState
  | CryptoState
  | BondState;

export type AssetTypedDetails =
  | FundState
  | LoanState
  | WarrantState
  | PrivateEquityFundState
  | BankNoteState
  | CryptoState
  | BondState
  | StockWithDetails;

export type AssetWithDetails = AssetTypedDetails;
//   asset: AssetState;
//   details: AssetTypedDetails;
// };
