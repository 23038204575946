import { strcmp } from '@pladdenico/common';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseTransactionId {
  projectId: string;
  id: string;
}

export type TransactionState = graphqlWorkspaceTypes.Transaction & {
  projectId: string;
};

export const initialTransactionsState = [];

const selectKeyId = (transaction: BaseTransactionId) => transaction.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseTransactionId, b: BaseTransactionId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseTransactionId,
  TransactionState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const transactionsSlice = createBaseRecordSlice('transactions', adapter);
export const transactionsSelectors = adapter.selectors;

export const transactionsReducer = transactionsSlice.reducer;
export const transactionsActions = transactionsSlice.actions;
