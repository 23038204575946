import { compact } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';
// import history from '~src/navigation/history';
import { Index } from '~src/services/search/index';
import { store } from '~src/store/store';

import { SearchResult } from '../../components/search/search-component';

function createValuationsIndex(
  holdings: HoldingState[],
  valuations: ValuationState[],
) {
  const valuationsIndex = new Index();
  const fields = ['holdingName', 'holdingDescription', 'value'];
  const documents = valuations.map((valuation) => {
    const holding = holdings.find((h) => h.id === valuation.holdingId);
    return {
      id: valuation.id,
      holdingName: holding ? holding.name : '',
      holdingDescription: holding ? holding.description : '',
      value: valuation.value,
    };
  });
  valuationsIndex.build(fields, documents);
  return valuationsIndex;
}

const valuationsSearch =
  (
    tenantId: string,
    workspaceId: string,
    holdings: HoldingState[],
    valuations: ValuationState[],
    valuationsIndex: Index,
    onClick: (holding: HoldingState) => void,
  ) =>
  (query: string): SearchResult[] => {
    const idx = valuationsIndex.idx();
    if (!idx) {
      return [];
    }
    const valuationSearchResult = idx.search(query);
    const valuationsHits: ValuationState[] = compact(
      valuationSearchResult.map((hit: any) => {
        return valuations.find((valuation) => {
          return valuation.id === hit.ref;
        });
      }),
    );
    const valuationResults = valuationsHits.map((valuation: ValuationState) => {
      const holding = holdings.find((h) => h.id === valuation.holdingId);
      return {
        id: valuation.id ? valuation.id : -1,
        title: `${moment(valuation.date).format('DD/MM/YYY')} - ${
          valuation.value
        }`,
        description: holding && holding.name ? holding.name : '',
        onClick: () => holding && onClick(holding),
      };
    });
    return valuationResults;
  };

export function useValuationsIndex(
  tenantId: string,
  workspaceId: string,
  holdings: HoldingState[],
  valuations: ValuationState[],
) {
  const [valuationsIndex, setValuationsIndex] = React.useState<Index>(
    () => new Index(),
  );

  React.useEffect(() => {
    setValuationsIndex(createValuationsIndex(holdings, valuations));
  }, [holdings, valuations]);

  const navigate = useNavigate();

  const onClick = React.useCallback(
    (holding: HoldingState) => {
      if (holding) {
        const project = getProjectById(selectWorkspaceData(store.getState()), {
          id: holding.projectId,
          workspaceId,
        });
        // const project = getProjectById(projects, holding.projectId);
        if (project) {
          return navigate(
            holdingBasePath(
              tenantId,
              project.workspaceId,
              holding.projectId,
              holding.id,
            ),
          );
        }
      }
    },
    [navigate, tenantId, workspaceId],
  );

  return {
    valuationsSearch: valuationsSearch(
      tenantId,
      workspaceId,
      holdings,
      valuations,
      valuationsIndex,
      onClick,
    ),
  };
}
