import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserState } from '~src/data/store/reducers/user/user/reducer';
import { MessageComponent } from '~src/domain/user/components/messages/message.component';
import { UserInvitationComponent } from '~src/domain/user/components/invitations/invitation';
import { TenantsComponent } from '~src/domain/user/components/tenants/tenants.component';
import { ProfileComponent } from '~src/domain/user/components/user/profile.component';
import { userTabPath } from '~src/navigation/paths/user/paths';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// import { UserTenantRouterWrapper } from '~src/domain/user/tenant/root';

export enum UserTabs {
  Profile = 'profile',
  Tenants = 'tenants',
  Messages = 'messages',
  Invitations = 'invitations',
}

interface TabPanelProps {
  children?: React.ReactNode;
  tab: UserTabs;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tab, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== tab}
      id={`scrollable-auto-tabpanel-${tab}`}
      aria-labelledby={`scrollable-auto-tab-${tab}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

interface Props {
  user: UserState;
  tabs: Tab<UserTabs>[];
  tab: Tab<UserTabs>;
}

interface PropsTab {
  user: UserState;
  tabName: string;
}

const SubComponent = React.memo((props: PropsTab) => {
  if (props.tabName === UserTabs.Profile) {
    return <ProfileComponent user={props.user} />;
  } else if (props.tabName === UserTabs.Tenants) {
    //   return <UserTenantRouterWrapper />;
    return <TenantsComponent />;
  } else if (props.tabName === UserTabs.Messages) {
    return <MessageComponent />;
  } else if (props.tabName === UserTabs.Invitations) {
    return <UserInvitationComponent />;
  }
  return <div>unknown</div>;
});

export const UserTabsComponent = React.memo((props: Props) => {
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newUserTab: UserTabs,
  ) {
    navigate(userTabPath(newUserTab));
  }

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        return (
          <SubComponent key={index} tabName={tab.name} user={props.user} />
        );
      }),
    [props.tabs, props.user],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel value={props.tab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });
  return (
    <>
      <Tabs
        value={props.tab.name}
        indicatorColor="primary"
        textColor="primary"
        // centered
        variant="scrollable"
        onChange={handleChange}
        aria-label="user tabs"
      >
        {tabElements}
      </Tabs>
      {tabPanelElements}
    </>
  );
});
