import React from 'react';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Typography } from '@mui/material';
import { ScenarioVariableNumber } from '~src/domain/workspace/components/project/scenario/models/variable';
// import { useSelector } from 'react-redux';
// import { RootState } from '~src/data/store/reducers/reducers';
// import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
// import { getForecastElementsByForecastId } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/element/selectors';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import {
  ForecastState,
  isExpressionType,
  isFlowType,
} from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { ScenarioVariableNumberFlowComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/variable-flow.component';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { ScenarioVariableNumberExpressionComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/variable-expression.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  variable: ScenarioVariableNumber;
  budget: Budget;
  forecast: ForecastState;
}

export const ScenarioVariableNumberComponent = React.memo((props: Props) => {
  const { scenario, budget, variable, forecast } = props;

  const forecastElement = React.useMemo(() => {
    if (forecast == null) {
      return undefined;
    }
    if (isFlowType(forecast.element)) {
      return (
        <ScenarioVariableNumberFlowComponent
          forecast={forecast}
          forecastElement={forecast.element}
          scenario={scenario}
          budget={budget}
        />
      );
    } else if (isExpressionType(forecast.element)) {
      return (
        <ScenarioVariableNumberExpressionComponent
          forecast={forecast}
          forecastElement={forecast.element}
          scenario={scenario}
          budget={budget}
        />
      );
    } else {
      return <>{JSON.stringify(forecast.element)}</>;
    }
  }, [forecast, scenario, budget]);

  return (
    <Grid container>
      <Grid xs={12}>
        <>
          <Typography variant="body2">{variable.value}</Typography>
          {forecastElement}
        </>
      </Grid>
    </Grid>
  );
});
