import moment from 'moment';
import numbro from 'numbro';
import React from 'react';
import { ElementFormatConfig } from '~src/components/dashboard/element';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHoldingsValueChange } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/use-holdings-value-change';
import { Filter } from '~src/utils/common/filter';
import { SortOrder, SortOrders } from '~src/utils/interfaces/sort-order';
import { Period } from '~src/utils/period/period';

import { Typography } from '@mui/material';

import { HoldingsViewTableComponent } from './holdings-view-table.component';
import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';

interface Props {
  workspace: WorkspaceState;
  valuationDate: moment.Moment;
  projectId: string;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
  period: Period;
  sortOrder: SortOrder;
  minimumValuationForHolding: number | undefined;
  filter: Filter;
  format: ElementFormatConfig;
}

export const HoldingsValueChangeViewComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    baseCurrency,
    minimumValuationForHolding,
    sortOrder,
    format,
  } = props;

  useComponentUpdateDebug('HoldingsValueChangeViewComponent', props);
  const valuationContributions = useHoldingsValueChange(props);

  const renderValuationContributionValue = React.useCallback(
    (value: number) => {
      return `${baseCurrency.symbol} ${numbro(value).format({
        thousandSeparated: true,
        mantissa: 1,
      })}`;
    },
    [baseCurrency],
  );

  const title = React.useMemo(() => {
    const orderString = sortOrder === SortOrders.Decreasing ? 'Top' : 'Bottom';
    return `${orderString} valuation changes`;
  }, [sortOrder]);

  return (
    <>
      <Typography variant="body2" textAlign="center">
        {title}
      </Typography>
      <HoldingsViewTableComponent
        workspace={workspace}
        baseCurrency={baseCurrency}
        projectId={projectId}
        data={valuationContributions}
        renderValue={renderValuationContributionValue}
        reverse={sortOrder === SortOrders.Decreasing}
        minimumValue={minimumValuationForHolding}
        format={format}
      />
    </>
  );
});
