import { Unsubscribe } from 'redux';
import { fetchQuotes } from '~src/data/store/modules/finance/quotes/requests';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { AppStore } from '~src/store/store';
import { Config } from '~src/utils/interfaces/config';

// import { fetchQuotes } from '~src/data/store/modules/quotes';
import { getConfigById } from '../selectors/config/selectors';
import { fetchPapersBySymbols } from '../modules/finance/papers/requests';
// import { fetchQuotes } from '../modules/finance/quotes/requests';
import { selectWorkspaceData } from '../selectors/selectors';

export class ConfigListener {
  private unsubscribeConfig: Unsubscribe;
  private config: Config | undefined;

  constructor(private store: AppStore) {
    this.store = store;
    // this.config = store.getState().config;
    this.handleConfig();
    this.unsubscribeConfig = store.subscribe(this.handleConfig);
  }

  public unsubscribe() {
    this.unsubscribeConfig();
  }

  private _fetchIndexPaper = (indexPaper: PaperState) => {
    indexPaper.symbol &&
      indexPaper.exchange &&
      this.store
        .dispatch(
          fetchPapersBySymbols(
            [
              {
                symbol: indexPaper.symbol,
                exchange: indexPaper.exchange,
              },
            ],
            // period.start.toDate(),
            // period.end.toDate()
          ),
        )
        .then((papers) => {
          const paper = papers[0];
          if (paper) {
            // const fromDate = moment().add(-5, 'year').toDate();
            // const toDate = moment().toDate();
            this.store.dispatch(fetchQuotes(indexPaper.id, null));
            // apis.invest.listenToQuoteStart(paper, fromDate, toDate, () => {
            //   console.log('listenToQuote done');
            // });
            // store.dispatch(
            //   fetchQuotes(
            //     paper.id,
            //     []
            //   )
            // );
          }
        });
  };

  private handleConfig = () => {
    const store = this.store;
    const state = store.getState();
    const config = getConfigById(selectWorkspaceData(state), { id: 'default' });

    if (!this.config) {
      //  || config.indexPaper !== this.config.indexPaper
      this.config = config;

      this._fetchIndexPaper(this.config.indexPaper);
      // fetchQuotes(this.config.indexPaper, moment().add(-5, 'year').toDate(), new Date())(store.dispatch);
      // const period = this.config.period;
    }
  };
}
