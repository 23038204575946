import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { AssetState } from '../../../reducers/asset/assets/reducer';

export function parseAssetObj(
  tenantId: string,
  workspaceId: string,
  asset: graphqlWorkspaceTypes.AssetObj,
  entityId?: string,
): AssetState {
  console.log('ASSET', asset);
  return {
    tenantId,
    workspaceId,
    entityId: entityId ?? asset.entity?.id ?? '',
    id: asset.id,
    ISIN: asset.ISIN,
    VPS: asset.VPS,
    categoryId: asset.category?.id ?? null,
    assetClass: asset.assetClass,
    description: asset.description,
    lookup: asset.lookup,
    name: asset.name,
    types: asset.types,
    type: asset.type,
  };
}
