import { handleStockPositions } from '~src/data/store/modules/holdings/stock/position/handler';
import { stockPositionsActions } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchStockPositions(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetStockPositionsDocument;
    const variables: graphqlWorkspaceTypes.GetStockPositionsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getStockPositions) {
        return handleStockPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.getStockPositions,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createStockPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateStockPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateStockPositionsDocument;
    const variables: graphqlWorkspaceTypes.CreateStockPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createStockPositions) {
        return handleStockPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.createStockPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateStockPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateStockPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateStockPositionsDocument;
    const variables: graphqlWorkspaceTypes.UpdateStockPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateStockPositions) {
        return handleStockPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.updateStockPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertStockPositions(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertStockPositionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertStockPositionsDocument;
    const variables: graphqlWorkspaceTypes.UpsertStockPositionsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertStockPositions) {
        return handleStockPositions(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertStockPositions,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteStockPositions(
  stockHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      stockPositionsActions.removeManyElements(
        ids.map((id) => {
          return {
            stockHoldingId,
            id,
          };
        }),
      ),
    );
  };
}

export function deleteStockPositions(
  tenantId: string,
  workspaceId: string,
  stockHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteStockPositionsDocument;
    const variables: graphqlWorkspaceTypes.DeleteStockPositionsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteStockPositions) {
        dispatch(
          handleDeleteStockPositions(stockHoldingId, data.deleteStockPositions),
        );
      }
      return [];
    });
  };
}
