import { groupBy } from 'lodash';
import moment from 'moment';
import numbro from 'numbro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getExchangeRateState } from '~src/data/store/selectors/finance/exchange-rates/selectors';
import { getFilteredHoldings } from '~src/data/store/selectors/holding/filter/holdings-filtered';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { selectHoldingsCostTransfers } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import {
  selectData,
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import { Period } from '~src/utils/period/period';

import { Box, Typography } from '@mui/material';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  period: Period;
  currency: CurrencyState;
}

export const CostsComponent = React.memo((props: Props) => {
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );
  const filteredHoldings = useSelector((state: RootState) =>
    getFilteredHoldings(selectData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      date: props.date,
      filter,
      holdings: props.holdings,
    }),
  );

  const holdingIds = React.useMemo(
    () => filteredHoldings.map((h) => h.id),
    [filteredHoldings],
  );

  const transfers = useSelector((state: RootState) =>
    selectHoldingsCostTransfers(selectWorkspaceData(state), {
      holdingIds,
      period: props.period,
    }),
  );
  const exchangeRates = useSelector((state: RootState) =>
    getExchangeRateState(selectFinancialData(state)),
  );

  const groupCostTransfer = groupBy(transfers, (transfer) => {
    if (transfer.type === 'cost') {
      return transfer.subtype;
    } else if (transfer.cost && transfer.cost > 0) {
      return `transaction cost: ${transfer.type}`;
    }
    return '';
  });
  const costTransferElement = Object.entries(groupCostTransfer).map(
    ([key, transfer]) => {
      const value = transfer.reduce((cost, transfer) => {
        let baseValue = 0;
        if (transfer.type === 'cost') {
          if (transfer.value) {
            baseValue = transfer.value;
          }
        } else if (transfer.cost) {
          baseValue = transfer.cost;
        }
        const valueInBaseCurrency =
          baseValue *
          convert(
            exchangeRates,
            transfer.currencyId,
            props.currency.id,
            props.date.toDate(),
          );
        return cost + valueInBaseCurrency;
      }, 0);
      const valueAsString = numbro(value).format({
        thousandSeparated: true,
        mantissa: 1,
      });
      return (
        <div key={key}>
          {key}: {valueAsString}
        </div>
      );
    },
  );

  return (
    <Box p={1}>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        Costs
      </Typography>
      {costTransferElement}
    </Box>
  );
});
