import {
  TenantStateVisitor,
  TenantVisitable,
} from '~src/data/store/visitors/tenant/tenant-visitor';
import { Tenant } from '~src/services/graphql/tenant/client/graphql';

export function handleTenant(dispatch: any, tenant: Tenant) {
  const visitor = new TenantStateVisitor(dispatch);
  const visitable = new TenantVisitable(tenant);
  visitable.accept(visitor);
  visitor.post();
  return tenant;
}
