import { StockState } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { Stock } from '~src/services/graphql/workspace/client/graphql';

export function parseStock(
  workspaceId: string,
  assetId: string,
  stock: Stock,
): StockState {
  return {
    workspaceId,
    assetId,
    id: stock.id,
    // stockExchange: stock.stockExchange,
    // symbol: stock.symbol,
    paperId: stock.paperId,
  };
}
