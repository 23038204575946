import { Owner } from '@pladdenico/models';
import { createSelector } from 'reselect';
import { appConfig } from '~src/app-config';
import { DataApi } from '~src/data/store/modules/data-api';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import {
  deleteAction,
  deleteAllAction,
  multipleAction,
  singleAction,
} from '../../../base/old/action';
import {
  DELETED_OWNER,
  OWNER,
  OWNER_SELECTED,
  OWNER_UNSELECTED,
  ownerFailureAction,
  ownerRequestAction,
  OWNERS,
  OWNERS_SELECTED,
  OWNERS_UNSELECTED,
  ownersFailureAction,
  ownersRequestAction,
  ownerSuccessAction,
} from './action';

const headers = (workspaceId: string) => {
  return {
    ...DataApi.baseHeaders(),
    ...DataApi.authHeaders(),
    ...DataApi.workspaceHeaders(workspaceId),
    // ...DataApi.portfolioHeaders(portfolio.id),
  };
};

function parseOwner(owner: Owner): Owner {
  owner.id = owner.id ? +owner.id : owner.id;
  owner.group = owner.group ? +owner.group : owner.group;
  return owner;
}

export function fetchOwners(workspaceId: string) {
  return (dispatch: any) => {
    dispatch(ownersRequestAction());
    return DataApi.get(
      `${appConfig.apiBaseUrl}/api/v1/owners`,
      headers(workspaceId),
      (owners: Owner[]) => {
        owners.forEach((owner: Owner) => {
          // owner =
          parseOwner(owner);
        });
        // dispatch(ownersAction(owners));
        dispatch(multipleAction(owners, OWNERS));
        return Promise.resolve(owners);
      },
      (err) => {
        dispatch(ownersFailureAction(err));
        return Promise.reject(err);
      },
    );
  };
}

export function getOwner(workspace: WorkspaceState, id: number) {
  return (dispatch: any) => {
    dispatch(ownerRequestAction(id));
    return DataApi.get(
      `${appConfig.apiBaseUrl}/api/v1/owners/${id}`,
      headers(workspace.id),
      (owner: Owner) => {
        owner = parseOwner(owner);
        dispatch(singleAction(owner, OWNER));
        // dispatch(ownerAction(owner));
        dispatch(ownerSuccessAction(owner));
        return Promise.resolve(owner);
      },
      (err) => {
        dispatch(ownerFailureAction(id, err));
        return Promise.reject(err);
      },
    );
  };
}

export function createOwners(
  workspace: WorkspaceState,
  owners: Owner[],
  callback: (owners: Owner[], error?: any) => void,
) {
  return (dispatch: any) => {
    return DataApi.post(
      `${appConfig.apiBaseUrl}/api/v1/owners/owners`,
      headers(workspace.id),
      JSON.stringify(owners),
      (owners: Owner[]) => {
        owners = owners.map((owner) => {
          return parseOwner(owner);
        });
        // dispatch(ownersAction(owners));
        dispatch(multipleAction(owners, OWNERS));
        callback(owners);
        return Promise.resolve(owners);
      },
      (err) => {
        console.log(err);
        callback(owners, err);
        return Promise.reject(err);
      },
    );
  };
}

export function createOwner(
  workspace: WorkspaceState,
  owner: Owner,
  callback: (owner: Owner, error?: any) => void,
) {
  return (dispatch: any) => {
    return DataApi.post(
      `${appConfig.apiBaseUrl}/api/v1/owners/create`,
      headers(workspace.id),
      JSON.stringify(owner),
      (owner: Owner) => {
        owner = parseOwner(owner);
        // dispatch(ownerAction(owner));
        dispatch(singleAction(owner, OWNER));
        dispatch(ownerSuccessAction(owner));
        callback(owner);
        return Promise.resolve(owner);
      },
      (err) => {
        console.log(err);
        callback(owner, err);
        return Promise.reject(err);
      },
    );
  };
}

export function updateOwner(
  workspace: WorkspaceState,
  owner: Owner,
  partialEntity: any,
  callback: (owner: Owner, error?: any) => void,
) {
  return (dispatch: any) => {
    return DataApi.patch(
      `${appConfig.apiBaseUrl}/api/v1/owners/${owner.id}`,
      headers(workspace.id),
      JSON.stringify(partialEntity),
      (owner: Owner) => {
        owner = parseOwner(owner);
        // dispatch(ownerAction(owner));
        dispatch(singleAction(owner, OWNER));
        dispatch(ownerSuccessAction(owner));
        callback(owner);
        return Promise.resolve(owner);
      },
      (err) => {
        console.log(err);
        callback(owner, err);
        return Promise.reject(err);
      },
    );
  };
}

export function deleteOwner(
  workspace: WorkspaceState,
  ownerId: number,
  callback: (ownerId: number, error?: any) => void,
) {
  return (dispatch: any) => {
    return DataApi.delete(
      `${appConfig.apiBaseUrl}/api/v1/owners/${ownerId}`,
      headers(workspace.id),
      (ownerId: number) => {
        ownerId = +ownerId;
        // dispatch(deletedOwnerAction(ownerId));
        dispatch(deleteAction(ownerId, DELETED_OWNER));
        callback(ownerId);
        return Promise.resolve(ownerId);
      },
      (err) => {
        console.log(err);
        callback(ownerId, err);
        return Promise.reject(err);
      },
    );
  };
}

export function selectOwner(owner: Owner) {
  return (dispatch: any) => {
    // dispatch(selectOwnerAction(owner));
    dispatch(singleAction(owner, OWNER_SELECTED));
  };
}

export function unselectOwner(owner: Owner) {
  return (dispatch: any) => {
    // dispatch(unselectOwnerAction(owner));
    owner.id && dispatch(deleteAction(owner.id, OWNER_UNSELECTED));
  };
}

export function selectOwners(owners: Owner[]) {
  return (dispatch: any) => {
    dispatch(multipleAction(owners, OWNERS_SELECTED));
    // dispatch(selectOwnersAction(owners));
  };
}

export function unselectOwners() {
  return (dispatch: any) => {
    dispatch(deleteAllAction(OWNERS_UNSELECTED));
    // dispatch(unselectOwnersAction());
  };
}

export const ownersSelector = (owners: Owner[], workspaceId: string | null) => {
  return owners.filter((owner) => {
    return owner.workspaceId === workspaceId;
  });
};

export const getOwners = createSelector(ownersSelector, (owners) => {
  return owners;
});
