import numbro from 'numbro';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  type?: string;
  payload?: any[];
  active?: boolean;
  totalValue?: number;
}

const useStyles = makeStyles()((theme: Theme) => ({
  customTooltip: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export function AllocationPieChartTooltipComponent(props: Props) {
  const { classes } = useStyles();
  const { active } = props;

  if (active) {
    const { payload, totalValue } = props;
    if (payload && totalValue) {
      const value = payload[0].value;
      const percent = value / totalValue;
      return (
        <div className={classes.customTooltip}>
          <p className="label">{`${payload[0].name}`}</p>
          <p className="label">{`${numbro(value).format({
            thousandSeparated: true,
            mantissa: 2,
          })}`}</p>
          <p className="label">{`${numbro(percent).format({
            output: 'percent',
            thousandSeparated: true,
            mantissa: 2,
          })}`}</p>
        </div>
      );
    }
  }

  return null;
}
