import { holdingBasePath } from '../paths';

export const commitmentsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) => {
  return `${holdingBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/commitments`;
};

export const commitmentBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  commitmentId: string,
) => {
  return `${commitmentsBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/${commitmentId}`;
};
