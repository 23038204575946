import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { upsertOne } from '~src/data/store/modules/config/reducer';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';

import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import {
  IconButton,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box, styled } from '@mui/system';

enum TimePeriod {
  day,
  month,
  year,
}

interface PeriodData {
  value: number;
  period: TimePeriod;
  text: string;
}

const periods: PeriodData[] = [
  {
    value: 0,
    period: TimePeriod.year,
    text: 'This year',
  },
  {
    value: 1,
    period: TimePeriod.month,
    text: '1m',
  },
  {
    value: 3,
    period: TimePeriod.month,
    text: '3m',
  },
  {
    value: 6,
    period: TimePeriod.month,
    text: '6m',
  },
  {
    value: 1,
    period: TimePeriod.year,
    text: '1y',
  },
  {
    value: 3,
    period: TimePeriod.year,
    text: '3y',
  },
  {
    value: 5,
    period: TimePeriod.year,
    text: '5y',
  },
];

interface Props {
  config: Config;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  // [`& .${toggleButtonGroupClasses.root}`]: {},
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const PeriodConfigComponent = (props: Props) => {
  const { config } = props;
  const theme: Theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const updateConfig = React.useCallback(
    (config: Config) => {
      dispatch(upsertOne({ id: 'default', config }));
    },
    [dispatch],
  );

  const [period, setPeriod] = React.useState<PeriodData>(periods[0]);

  const handlePeriod = React.useCallback(
    (_event: React.MouseEvent<HTMLElement>, newPeriod: PeriodData) => {
      if (!newPeriod) {
        return;
      }
      setPeriod(newPeriod);

      let start = moment();
      const end = moment(); // Always today
      if (newPeriod.period === TimePeriod.month) {
        if (newPeriod.value > 0) {
          start = moment().add(-newPeriod.value, 'month');
        } else if (newPeriod.value === 0) {
          start = moment().startOf('month');
        }
      } else if (newPeriod.period === TimePeriod.year) {
        if (newPeriod.value > 0) {
          start = moment().add(-newPeriod.value, 'year');
        } else if (newPeriod.value === 0) {
          start = moment().startOf('year');
        }
      }

      const period = new Period(start, end);
      updateConfig({
        ...config,
        period,
      });
    },
    [config, updateConfig],
  );

  const periodButtons = periods.map((period) => {
    return (
      <ToggleButton
        key={period.text}
        value={period}
        aria-label={period.text}
        // sx={{ margin: 1 }}
      >
        {period.text}
      </ToggleButton>
    );
  });

  const buttonGroup = React.useMemo(
    () => (
      <StyledToggleButtonGroup
        size="small"
        value={period}
        exclusive
        onChange={handlePeriod}
        aria-label="period"
        orientation={isMd ? 'vertical' : 'horizontal'}
      >
        {periodButtons}
      </StyledToggleButtonGroup>
    ),
    [handlePeriod, isMd, period, periodButtons],
  );
  const dialog = useVisible();

  const container = React.useMemo(() => {
    if (isMd) {
      return (
        <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center' }}>
          <IconButton size={'small'} onClick={dialog.toggle}>
            <ManageHistoryIcon />
          </IconButton>
          <DialogComponent
            dialogProps={{
              open: dialog.isShowing,
              onClose: dialog.close,
            }}
          >
            <DialogContentComponent
              title="Pick period"
              handleClose={dialog.close}
            >
              {buttonGroup}
            </DialogContentComponent>
          </DialogComponent>
        </Box>
      );
    } else {
      return (
        // <Box sx={{ height: 50, display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {buttonGroup}
        </Box>
      );
    }
  }, [buttonGroup, dialog.close, dialog.isShowing, dialog.toggle, isMd]);

  return container;
};
