import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateHoldingDialogComponent } from '~src/domain/workspace/components/project/holding/form/create-dialog.component';
import { useHolding } from '~src/domain/workspace/components/project/portfolio/holdings/use-holding';
import { useCreateHoldingForm } from '~src/domain/workspace/components/project/portfolio/holdings/use-create-holding-form';
import { useHoldingActions } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';
import { usePortfolioHoldings } from '~src/hooks/actions/workspace/project/portfolio/holding/use-portfolio-holdings';
import { useCreateHolding } from '~src/hooks/fields/workspace/project/holding/use-create-holding';

import { HoldingType } from '@pladdenico/models';

interface Props {
  handleValue: (t: HoldingState) => void;
  workspace: WorkspaceState;
  projectId: string;
  holdingTypes?: HoldingType[];
}

const initialPortfolios: PortfolioState[] = [];

export const useCreateDialog = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const { holdingInput, setHoldingInput } = useCreateHolding(
    props.workspace,
    props.projectId,
    HoldingType.Unknown,
  );
  const { createHoldingWithData } = useHoldingActions(props.workspace);

  const { handleValue } = props;

  const { holdingDataInput, setHoldingDataInput } = useHolding(holdingInput);
  const { portfolios, setPortfolios, updatePortfolios } = usePortfolioHoldings(
    props.workspace,
    initialPortfolios,
  );

  const createHolding = React.useCallback(() => {
    return createHoldingWithData(holdingInput, holdingDataInput, () => {
      setOpen(false);
    }).then((holding) => {
      handleValue(holding);
      return updatePortfolios(holding);
    });
  }, [
    createHoldingWithData,
    handleValue,
    holdingDataInput,
    holdingInput,
    updatePortfolios,
  ]);

  const { content } = useCreateHoldingForm(
    props.workspace,
    props.projectId,
    holdingInput,
    setHoldingInput,
    holdingDataInput,
    setHoldingDataInput,
    portfolios,
    setPortfolios,
    props.holdingTypes,
  );
  const dialogElement = React.useMemo(() => {
    return (
      <CreateHoldingDialogComponent
        open={open}
        handleClose={() => setOpen(false)}
        handleCreateHolding={createHolding}
        valid={() => true}
      >
        {content}
      </CreateHoldingDialogComponent>
    );
  }, [content, createHolding, open]);

  return { dialogElement, setOpen, setHoldingInput };
};
