import { Edge, Node } from 'reactflow';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { NodeType } from '../../../nodes/node-type';

export const interestRateInitialNodes: Node<NodeData, NodeType>[] = [
  {
    id: 'initial',
    type: 'initial',
    data: {
      label: 'Initial',
      initialization: '{"initial":{"valuation":{"value":1221}}}',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value:
              'prevValuation: (idx > 0 ? values[idx].valuation : initial.valuation)',
          },
        ],
      },
    },
    position: { x: 180, y: 0 },
  },
  {
    id: 'value',
    type: 'variable',
    data: {
      label: 'Value',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value:
              'valuation: {value:Initial.prevValuation.value * random(0.99, 1.01)}',
          },
        ],
      },
    },
    position: { x: 300, y: 100 },
  },
  {
    id: 'valuation',
    type: 'output',
    data: {
      label: 'valuation',
      name: 'Value',
    },
    position: { x: 100, y: 400 },
  },
];

export const interestRateInitialEdges: Edge[] = [
  {
    id: '1',
    source: 'initial',
    target: 'value',
  },
  {
    id: '2',
    source: 'value',
    target: 'valuation',
  },
];

export const initialInterestRateFlow = {
  nodes: interestRateInitialNodes,
  edges: interestRateInitialEdges,
};
