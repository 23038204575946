import { combineReducers } from 'redux';
import { initialQuoteState, quoteReducer } from './quote/reducer';
import { initialPaperState, paperReducer } from './paper/reducer';
import { currencyReducer, currencyInitialState } from './currency/reducer';
import {
  exchangeRatesReducer,
  initialState as initialExchangeRateState,
} from './exchange-rates/reducer';

export const initialFinanceState = {
  quotes: initialQuoteState,
  papers: initialPaperState,
  currency: currencyInitialState,
  exchangeRates: initialExchangeRateState,
  // quotesFetch: quotesFetchInitialState,
};

export const financeReducer = combineReducers({
  quotes: quoteReducer,
  papers: paperReducer,
  currency: currencyReducer,
  exchangeRates: exchangeRatesReducer,
  // quotesFetch: quotesFetchReducer,
});
