import { compact } from 'lodash';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersAndInvitationsByWorkspaceId } from '~src/data/store/selectors/user/user/selectors';
import { fetchWorkspaceInvitations } from '~src/data/store/modules/workspaces/workspace-invitations/requests';
import { fetchWorkspaceRoles } from '~src/data/store/modules/workspaces/workspace-roles/workspace-roles/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectData } from '~src/data/store/selectors/selectors';
import { WorkspaceUsersToolbar } from '~src/domain/workspace/components/user/workspace-users-toolbar.component';
import {
  UserData,
  useUserFields,
} from '~src/hooks/fields/user/use-user-fields';
import { AppDispatch } from '~src/store/store';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

interface Props {
  // users: any;
  tenantId: string;
  workspace: WorkspaceState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  inner: {
    minWidth: 440,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

export const WorkspaceUsersComponent = memo((props: Props) => {
  // export const WorkspacePortfoliosComponent = (props: Props) => {
  const { classes } = useStyles();
  const users = useSelector((state: RootState) =>
    getUsersAndInvitationsByWorkspaceId(selectData(state), props.workspace.id),
  );
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(fetchWorkspaceRoles(props.tenantId));
    dispatch(fetchWorkspaceInvitations(props.tenantId));
  }, [dispatch, props.tenantId, props.workspace]);
  const fields = useUserFields(props.workspace.id);

  const [initialSorters] = React.useState<TableField.Sorter<UserData>[]>(() => {
    return compact([
      TableOperations.createSorterFromFields(fields, 'date', 'desc'),
    ]);
  });

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(users, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);
  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<UserData>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        // editable={{ onRowUpdate, onRowDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        // handleSetUpdateState={setUpdateState}
        isSelected={isSelected}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    // onRowDelete,
    // onRowUpdate,
    selectedData,
    sorters,
  ]);

  // const columns = React.useMemo((): IColumn<UserData>[] => {
  //   const columns: IColumn<UserData>[] = [
  //     // {
  //     //   direction: 'asc',
  //     //   sortable: false,
  //     //   sorted: false,
  //     //   name: '',
  //     // },
  //     {
  //       direction: 'asc',
  //       sortable: true,
  //       sorted: false,
  //       name: 'name',
  //       field: 'name',
  //       type: ColumnType.string,
  //       iteratee: (data) => data.name,
  //       filter: {
  //         query: '',
  //         valueMapper: (data: UserData) => {
  //           return String(data.name);
  //         },
  //       },
  //       viewComponent: (editProps) => {
  //         return (
  //           <div className={classes.nameContainer}>
  //             <Avatar
  //               className={clsx(
  //                 classes.avatar,
  //                 getAvatarColor(avatarColorClassesArray, editProps.data.name)
  //               )}
  //             >
  //               {/* src={user.avatarUrl} */}
  //               {getInitials(editProps.data.name)}
  //             </Avatar>
  //             <Typography variant="body1">{editProps.data.name}</Typography>
  //           </div>
  //         );
  //       },
  //     },
  //   ];
  //   return columns;
  // }, [avatarColorClassesArray, classes.avatar, classes.nameContainer]);

  return (
    <div className={classes.root}>
      <WorkspaceUsersToolbar workspace={props.workspace} />
      <div className={classes.content}>
        {/* <WorkspacePortfoliosTableComponent users={props.users} /> */}
        {tableComponent}
      </div>
    </div>
  );
});
