import { useDispatch } from 'react-redux';
// import { QuoteState } from '~src/hooks/fields/finance/asset/use-quotes-fields';
import {
  createQuotes,
  deleteQuotes,
  updateQuotes,
} from '~src/data/store/modules/finance/quotes/requests';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';
import { AppDispatch } from '~src/store/store';

export function useQuoteActions() {
  const dispatch = useDispatch<AppDispatch>();
  const onUpdate = (oldData: QuoteState, newData: QuoteState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlFinanceTypes.UpdateQuoteInputType = {
          id: newData.id,
          paperId: newData.paperId,
          close: newData.close,
          date: newData.date,
          high: newData.high,
          low: newData.low,
          open: newData.open,
          value: newData.value,
          volume: newData.volume,
        };
        dispatch(updateQuotes(newData.paperId, [input]))
          .then((_quotes) => {
            resolve();
          })
          .catch((_err) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: QuoteState) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deleteQuotes(oldData.paperId, [{ id: oldData.id, date: oldData.date }]),
      )
        .then((_quoteIds) => {
          resolve();
        })
        .catch((_err) => {
          reject();
        });
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };

  const onCreate = (quoteInput: graphqlFinanceTypes.CreateQuoteInputType) => {
    return dispatch(createQuotes(quoteInput.paperId, [quoteInput]));
  };
  return { onUpdate, onDelete, onCreate };
}
