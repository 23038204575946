import {
  FavoriteHoldingStateVisitor,
  FavoriteHoldingVisitable,
} from '~src/data/store/visitors/holding/favorite/favorite-holding-visitor';
import { FavoriteHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleFavoriteHoldings(
  dispatch: any,
  projectId: string,
  outputs: FavoriteHolding[],
) {
  const favoriteHoldingVisitor = new FavoriteHoldingStateVisitor(dispatch);

  outputs.forEach((favoriteHolding) => {
    const favoriteHoldingVisitable = new FavoriteHoldingVisitable(
      projectId,
      favoriteHolding,
    );
    favoriteHoldingVisitable.accept(favoriteHoldingVisitor);
  });
  favoriteHoldingVisitor.post();
  return outputs;
}
