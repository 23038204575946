import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseWarrantId,
  warrantsSelectors,
} from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getWarrants = (state: WorkspaceDataState) => {
  return state.holding.asset.warrant.warrants;
};

export const getWarrantsByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getWarrants(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (warrantsState, workspaceId) => {
    const warrants = warrantsSelectors.selectElementsByKey(
      warrantsState,
      workspaceId,
    );
    return warrants ? warrants : [];
  },
)({
  keySelector: (_state, warrantId) => warrantId,
  selectorCreator: createSelector,
});

export const getWarrantById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseWarrantId>) =>
    getWarrants(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseWarrantId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseWarrantId>) => baseId.id,
  (warrants, workspaceId, warrantId) => {
    if (!workspaceId || !warrantId) {
      return undefined;
    }
    return warrantsSelectors.selectElementByT(warrants, {
      workspaceId,
      id: warrantId,
    });
  },
)({
  keySelector: (_state, workspaceId, warrantId) =>
    `${workspaceId}-${warrantId}`,
  selectorCreator: createSelector,
});

interface WarrantAssetId {
  workspaceId: string;
  assetId: string;
}

export const getWarrantByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<WarrantAssetId>) =>
    getWarrants(state),
  (_state: WorkspaceDataState, baseId: Partial<WarrantAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<WarrantAssetId>) =>
    baseId.assetId,
  (warrants, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceWarrants = warrantsSelectors.selectElementsByKey(
      warrants,
      workspaceId,
    );
    return workspaceWarrants?.find((warrant) => warrant.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
