import * as React from 'react';
import { useSelector } from 'react-redux';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getRegions } from '~src/data/store/selectors/common/regions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { AllocationData } from '~src/domain/workspace/components/project/portfolio/allocations/portfolio-allocations.component';
import { useRegionField } from '~src/hooks/fields/common/use-region-field';
import { useCurrencyField } from '~src/hooks/fields/financial/currency/use-currency-field';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';

function getCurrencyId(data: AllocationData) {
  return data.allocation.currencyId ?? '';
}

function updateWithCurrency(
  data: AllocationData,
  currency: CurrencyState,
): AllocationData {
  const allocation = { ...data.allocation, currencyId: currency.id };
  return {
    ...data,
    allocation,
  };
}

function getRegionId(data: AllocationData) {
  return data.allocation.regionId ?? '';
}

function updateWithRegion(
  data: AllocationData,
  region: RegionState,
): AllocationData {
  const allocation = { ...data.allocation, regionId: region.id };
  return {
    ...data,
    allocation,
  };
}

export function useAllocationFields(
  _workspaceId: string,
  _projectId: string,
): TableField.Field<AllocationData>[] {
  const regions = useSelector((state: RootState) =>
    getRegions(selectWorkspaceData(state)),
  );
  const regionField = useRegionField(regions, getRegionId, updateWithRegion);
  const currencyField = useCurrencyField(getCurrencyId, updateWithCurrency);
  const fields: TableField.Field<AllocationData>[] = [
    {
      ...currencyField,
      style: {
        minWidth: 140,
      },
    },
    {
      ...regionField,
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'min',
      name: 'min',
      field: 'min',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.allocation.min,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const allocation = {
          ...data.allocation,
          min: eventParser(event) as number,
        };
        return {
          ...data,
          allocation,
        };
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="min"
            value={editProps.data.allocation.min}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'max',
      name: 'max',
      field: 'max',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.allocation.max,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const allocation = {
          ...data.allocation,
          max: eventParser(event) as number,
        };
        return {
          ...data,
          allocation,
        };
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="max"
            value={editProps.data.allocation.min}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'category',
      name: 'category',
      field: 'category',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.allocation.category,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const allocation = {
          ...data.allocation,
          category: eventParser(event) as string,
        };
        return {
          ...data,
          allocation,
        };
      },
      renderView: (viewProps) => {
        return <>{viewProps.data.allocation.category}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="category"
            value={editProps.data.allocation.category}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'assetClass',
      name: 'assetClass',
      field: 'assetClass',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.allocation.assetClass,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const allocation = {
          ...data.allocation,
          assetClass: eventParser(event) as string,
        };
        return {
          ...data,
          allocation,
        };
      },
      renderView: (viewProps) => {
        return <>{viewProps.data.allocation.assetClass}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="assetClass"
            value={editProps.data.allocation.assetClass}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'assetType',
      name: 'assetType',
      field: 'assetType',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.allocation.assetType,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const allocation = {
          ...data.allocation,
          assetType: eventParser(event) as string,
        };
        return {
          ...data,
          allocation,
        };
      },
      renderView: (viewProps) => {
        return <>{viewProps.data.allocation.assetType}</>;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="assetType"
            value={editProps.data.allocation.assetType}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return fields;
}
