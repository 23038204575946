import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { Quote } from '~src/services/graphql/finance/client/graphql';

export function parseQuote(paperId: string, quote: Quote): QuoteState {
  return {
    id: quote.id,
    date: new Date(quote.date),
    paperId,
    close: +(quote.close ?? 0),
    high: +(quote.high ?? 0),
    low: +(quote.low ?? 0),
    open: +(quote.open ?? 0),
    value: +(quote.value ?? 0),
    volume: +(quote.volume ?? 0),
  };
}
