import * as React from 'react';

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget);
  }

  return {
    isOpen,
    anchorEl,
    handleClose,
    handleOpen,
  };
};
