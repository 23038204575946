import {
  LoanStateVisitor,
  LoanVisitable,
} from '~src/data/store/visitors/asset/types/loan/loan-visitor';
import { Loan } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleLoans(
  workspaceId: string,
  dispatch: AppDispatch,
  loans: Loan[],
) {
  const visitor = new LoanStateVisitor(dispatch);
  const res = loans.map((loan) => {
    const assetVisitable = new LoanVisitable(workspaceId, loan);
    return assetVisitable.accept(visitor);
  });
  visitor.post();
  return res;
}
