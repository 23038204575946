import {
  PortfolioAllocationStateVisitor,
  PortfolioAllocationVisitable,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-allocation-visitor';
import { Allocation } from '~src/services/graphql/workspace/client/graphql';

export function handleAllocations(
  dispatch: any,
  _workspaceId: string,
  outputs: Allocation[],
) {
  const visitor = new PortfolioAllocationStateVisitor(dispatch);
  const res = outputs.map((allocation) => {
    const visitable = new PortfolioAllocationVisitable(
      allocation.portfolio?.id ?? '',
      allocation,
    );
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
