import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { HoldingTabComponent } from '~src/domain/workspace/components/project/portfolio/holdings/tab/holding-tab.component';
import { HoldingTabs } from '~src/domain/workspace/components/project/portfolio/holdings/tab/holding-tabs';
import { holdingTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';

import { useTabs } from './tab/use-tabs';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  project: ProjectState;
}

interface PropsWithHolding extends Props {
  holding: HoldingState;
}

const HoldingRouterWrapperWithTab = React.memo((props: PropsWithHolding) => {
  const tabs = useTabs(props.holding);
  const { tabId } = useParams<{ tabId: string }>();
  console.log('TAB', tabId);

  const tab = tabs.find((t) => t.name === tabId);

  return tab ? (
    <HoldingTabComponent
      workspace={props.workspace}
      portfolioProject={props.portfolioProject}
      project={props.project}
      portfolio={props.portfolio}
      holding={props.holding}
      tab={tab}
      tabs={tabs}
    />
  ) : (
    <div>unknown tab</div>
  );
});

export const HoldingRouterWrapper = React.memo((props: Props) => {
  const { holdingId } = useParams<{ holdingId: string }>();
  const holding = useSelector((state: RootState) => {
    if (holdingId != null) {
      return getHoldingById(selectWorkspaceData(state), {
        id: holdingId,
        projectId: props.portfolioProject.projectId,
      });
    }
  });
  if (!holding) {
    return <p>Not a valid holding id</p>;
  } else {
    return (
      <Routes>
        <Route
          path="*"
          // exact={true}
          element={
            <Navigate
              to={holdingTabPath(
                props.workspace.tenantId,
                props.workspace.id,
                props.portfolioProject.projectId,
                holding.id,
                HoldingTabs.Dashboard,
              )}
              replace
            />
          }
        />
        {/* <Route
          path="/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId"
          exact={true}
        >
          <Redirect
            to={holdingTabPath(
              props.workspace.tenantId,
              props.workspace.id,
              props.portfolioProject.projectId,
              holding.id,
              HoldingTabs.Dashboard
            )}
          />
        </Route> */}
        <Route
          path={':tabId/*'}
          element={<HoldingRouterWrapperWithTab {...props} holding={holding} />}
        />
      </Routes>
    );
  }
});
