import { createSelector } from 'reselect';
import {
  AssetTyped,
  AssetWithDetails,
} from '~src/data/store/reducers/asset/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import {
  DataState,
  WorkspaceDataState,
} from '~src/data/store/reducers/reducers';

import {
  getAssetById,
  getAssetTypedByHoldingId,
} from '../../asset/assets/selectors';
import { getStockHoldingByHoldingId } from '../../holding/holding-types/stock/stock-holding/selectors';
import { getStockPositionsByStockHoldingId } from '../../holding/holding-types/stock/stock-position/selectors';
import { getStockGoalRulesByStockHoldingId } from '../../holding/holding-types/stock/stock-goal-rules/selectors';
import { getQuotesByStock } from '../quotes/selectors';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { getPortfoliosByHoldingId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { getLastValuation } from '~src/utils/finance/valuation';
import createCachedSelector from 're-reselect';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';

export interface HoldingAsset {
  holding: HoldingState;
  asset: AssetTyped | undefined;
}

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
}

export const getHoldingsAssets = createSelector(
  (state: WorkspaceDataState) => state,
  (_state: WorkspaceDataState, props: Props) => props.workspaceId,
  (_state: WorkspaceDataState, props: Props) => props.projectId,
  (_state: WorkspaceDataState, props: Props) => props.holdings,
  (state, workspaceId, projectId, holdings): HoldingAsset[] => {
    return holdings.map((holding) => {
      const asset = getAssetTypedByHoldingId(state, {
        workspaceId,
        projectId,
        holdingId: holding.id,
      });
      return {
        holding,
        asset,
      };
    });
  },
);

export interface HoldingAssetWithDetails {
  holding: HoldingState;
  asset: AssetWithDetails | undefined;
}

export const getHoldingsAssetsWithDetails = createSelector(
  (state: DataState) => state.financial,
  (state: DataState) => state.workspace,
  (state: DataState, props: Props) => getHoldingsAssets(state.workspace, props),
  (financialState, workspaceData, holdingAssets): HoldingAssetWithDetails[] => {
    return holdingAssets.map((holdingAsset) => {
      const { holding, asset } = holdingAsset;
      if (asset?.__typename === 'Stock') {
        const quotes = getQuotesByStock(financialState, asset) ?? [];
        const stockHolding = getStockHoldingByHoldingId(
          workspaceData,
          holding.id,
        );
        const positions = stockHolding
          ? getStockPositionsByStockHoldingId(workspaceData, stockHolding.id)
          : [];
        const goalRules = stockHolding
          ? getStockGoalRulesByStockHoldingId(workspaceData, stockHolding.id)
          : [];
        return {
          holding,
          asset: {
            ...asset,
            quotes,
            positions,
            goalRules,
            stockHolding,
          },
        };
      } else {
        // const xa = holdingAsset.asset;
        return {
          ...holdingAsset,
          asset: undefined,
        };
      }
    });
  },
);

export interface HoldingDenormalized {
  holding: HoldingState;
  assetTyped: AssetTyped | undefined;
  asset: AssetState | undefined;
  portfolios: PortfolioState[];
  valuation: ValuationState | undefined;
}

export interface HoldingDenormalizedWithEntity extends HoldingDenormalized {
  id: string;
  entity: EntityState | undefined;
}

interface HoldingsDenormalizedProps {
  workspaceId: string;
  holdings: HoldingState[];
}

export const getHoldingsDenormalized = createCachedSelector(
  (state: WorkspaceDataState) => state,
  (_state: WorkspaceDataState, props: HoldingsDenormalizedProps) =>
    props.workspaceId,
  (_state: WorkspaceDataState, props: HoldingsDenormalizedProps) =>
    props.holdings,
  (state, workspaceId, holdings): HoldingDenormalizedWithEntity[] => {
    return holdings.map((holding) => {
      const assetTyped = getAssetTypedByHoldingId(state, {
        workspaceId,
        projectId: holding.projectId,
        holdingId: holding.id,
      });
      const asset = getAssetById(state, {
        id: assetTyped?.assetId,
        workspaceId,
      });
      const entity = getEntityById(state, {
        id: asset?.entityId,
        workspaceId,
      });
      const portfolios = getPortfoliosByHoldingId(state, {
        id: holding.id,
        workspaceId,
      });
      const valuations = getValuationsByHoldingId(state, holding.id);
      const valuation = getLastValuation(valuations);
      return {
        id: holding.id,
        holding,
        assetTyped,
        asset,
        entity,
        portfolios,
        valuation,
      };
    });
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${JSON.stringify(props.holdings.map((h) => h.id))}`,
  selectorCreator: createSelector,
});
