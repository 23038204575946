import { WarrantHoldingState } from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import { WarrantHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseWarrantHolding(
  warrantHolding: WarrantHolding,
): WarrantHoldingState {
  return {
    id: warrantHolding.id,
    warrantId: warrantHolding.warrant?.id ?? '',
    holdingId: warrantHolding.holding?.id ?? '',
  };
}
