import { GroupCreateInput } from '@pladdenico/models';
import React, { memo } from 'react';

import { Input } from '@mui/material';

interface Props {
  group: GroupCreateInput;
  index: number;
  updateGroup: (group: GroupCreateInput, index: number) => void;
}

export const GroupFormComponent = memo((props: Props) => {
  const handleGroupNameOnChange = (event: any): void => {
    console.log(event.target);
    const group = props.group;
    group.name = event.target.value;
    props.updateGroup(group, props.index);
  };

  return (
    <form>
      <Input
        value={props.group.name}
        name="name"
        onChange={handleGroupNameOnChange}
      />
    </form>
  );
});
