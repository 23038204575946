import { sortedUniqBy } from 'lodash';
import moment from 'moment';
import { PeriodMapper } from '~src/utils/period/period-mapper';
import { PeriodType } from '~src/utils/period/period-type';

// export interface PeriodBase {
//   startDate: Date;
//   endDate: Date;
// }

// export interface PeriodBase {
//   name: string;
// }

export class Period {
  public start: moment.Moment;
  public end: moment.Moment;
  public name: string;

  constructor(start: moment.Moment, end: moment.Moment, name?: string) {
    this.start = start;
    this.end = end;
    this.name = name ? name : '';
  }

  public id(): string {
    return `${this.start.unix()}_${this.end.unix()}`;
  }

  public within(date: moment.Moment): boolean {
    return this.start <= date && date <= this.end;
  }

  public numberOfDays(): number {
    return this.start.diff(this.end, 'days');
  }
}

export function createSubperiodDates(
  basePeriod: Period,
  periodName: PeriodType,
): moment.Moment[] {
  const dates: moment.Moment[] = [];
  dates.push(moment(basePeriod.start));

  const period = PeriodMapper.map.get(periodName);
  if (period) {
    const { diffName } = period; // name,

    const numberOfDates = Math.ceil(
      basePeriod.end.diff(basePeriod.start, diffName, true),
    );
    for (let i = 1; i < numberOfDates; ++i) {
      // const date = moment(basePeriod.start).endOf(name).add(i, diffName);
      const date = moment(basePeriod.start).add(i, diffName);
      // dates.push(moment(date).endOf(name));
      dates.push(moment(date));
    }
  }
  dates.push(moment(basePeriod.end));

  dates.sort((a, b) => {
    return a.unix() - b.unix();
  });

  return sortedUniqBy(dates, (date) => date.startOf('day').unix());
}

export function createSubperiods(
  basePeriod: Period,
  periodName: PeriodType,
): Period[] {
  const dates = createSubperiodDates(basePeriod, periodName);
  const period = PeriodMapper.map.get(periodName);
  if (!period) {
    return [];
  }
  const { formaterBase, formaterDiff } = period;

  const periods: Period[] = [];
  const numberOfDates = dates.length;
  for (let i = 0; i <= numberOfDates - 2; ++i) {
    let startDate = dates[i];
    let endDate = dates[i + 1];
    let periodName;
    if (basePeriod.end < endDate) {
      endDate = basePeriod.end;
      periodName = formaterDiff(startDate, endDate);
    } else if (startDate < basePeriod.start) {
      startDate = basePeriod.start;
      periodName = formaterDiff(startDate, endDate);
    } else {
      periodName = formaterBase(startDate);
    }
    periods.push(new Period(startDate, endDate, periodName));
  }

  return periods;
}
