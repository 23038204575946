import React from 'react';
import * as uuid from 'uuid';

export const usePopover = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const id = uuid.v1();

  const open = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return {
    id,
    close,
    open,
    anchorEl,
    isOpen,
  };
};
