import {
  AssetStateVisitor,
  AssetVisitable,
  AssetVisitor,
} from '~src/data/store/visitors/asset/asset-visitor';
import {
  PersonStateVisitor,
  PersonVisitable,
  PersonVisitor,
} from '~src/data/store/visitors/person/person-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  EntityStateVisitor,
  EntityVisitable,
  EntityVisitor,
} from '~src/data/store/visitors/workspace/entity/entity-visitor';
import {
  PortfolioStateVisitor,
  PortfolioVisitable,
  PortfolioVisitor,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-visitor';
import {
  ProjectStateVisitor,
  ProjectVisitable,
  ProjectVisitor,
} from '~src/data/store/visitors/workspace/project/project-visitor';
import {
  WorkspaceRoleStateVisitor,
  WorkspaceRoleVisitable,
  WorkspaceRoleVisitor,
} from '~src/data/store/visitors/workspace/role/workspace-role-visitor';
import {
  WorkspaceBaseStateVisitor,
  WorkspaceBaseVisitable,
  WorkspaceBaseVisitor,
} from '~src/data/store/visitors/workspace/workspace-base-visitor';
// import { parseWorkspace } from '~src/data/store/modules/workspaces/workspaces/parser';

import { Workspace } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

interface WorkspaceVisitor {
  // visit(workspace: WorkspaceVisitable): void;
  post(): void;
  workspaceBaseVisitor: WorkspaceBaseVisitor;
  projectVisitor: ProjectVisitor;
  portfolioVisitor: PortfolioVisitor;
  assetVisitor: AssetVisitor;
  personVisitor: PersonVisitor;
  entityVisitor: EntityVisitor;
  workspaceRoleVisitor: WorkspaceRoleVisitor;
}

export class WorkspaceVisitable implements Visitable<WorkspaceVisitor> {
  constructor(
    private _tenantId: string,
    private _workspace: Workspace,
  ) {}
  public accept(visitor: WorkspaceVisitor) {
    const workspaceBaseVisitable = new WorkspaceBaseVisitable(
      this._tenantId,
      this._workspace,
    );
    workspaceBaseVisitable.accept(visitor.workspaceBaseVisitor);
    if (this._workspace.projects) {
      this._workspace.projects.forEach((project) => {
        const projectVisitable = new ProjectVisitable(
          this._workspace.id,
          project,
        );
        projectVisitable.accept(visitor.projectVisitor);
      });
    }
    if (this._workspace.portfolios) {
      this._workspace.portfolios.forEach((portfolio) => {
        const portfolioVisitable = new PortfolioVisitable(
          this._workspace.id,
          portfolio,
        );
        portfolioVisitable.accept(visitor.portfolioVisitor);
      });
    }
    if (this._workspace.assets) {
      this._workspace.assets.forEach((asset) => {
        const assetVisitable = new AssetVisitable(
          this._tenantId,
          this._workspace.id,
          asset,
        );
        assetVisitable.accept(visitor.assetVisitor);
      });
    }
    if (this._workspace.people) {
      this._workspace.people.forEach((person) => {
        const personVisitable = new PersonVisitable(
          this._tenantId,
          this._workspace.id,
          person,
        );
        personVisitable.accept(visitor.personVisitor);
      });
    }
    if (this._workspace.entities) {
      this._workspace.entities.forEach((entity) => {
        const entityVisitable = new EntityVisitable(
          this._tenantId,
          this._workspace.id,
          entity,
        );
        entityVisitable.accept(visitor.entityVisitor);
      });
    }
    if (this._workspace.workspaceRoles) {
      this._workspace.workspaceRoles.forEach((workspaceRole) => {
        const entityVisitable = new WorkspaceRoleVisitable(
          this._tenantId,
          workspaceRole,
        );
        entityVisitable.accept(visitor.workspaceRoleVisitor);
      });
    }
    // visitor.visit(this);
  }

  // public parse(): WorkspaceState {
  //   return parseWorkspace(this._tenantId, this._workspace);
  // }
}

export class WorkspaceStateVisitor implements WorkspaceVisitor {
  public workspaceBaseVisitor: WorkspaceBaseVisitor;
  public projectVisitor: ProjectVisitor;
  public portfolioVisitor: PortfolioVisitor;
  public assetVisitor: AssetVisitor;
  public personVisitor: PersonVisitor;
  public entityVisitor: EntityVisitor;
  public workspaceRoleVisitor: WorkspaceRoleVisitor;
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    this.workspaceBaseVisitor = new WorkspaceBaseStateVisitor(dispatch);
    this.projectVisitor = new ProjectStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.portfolioVisitor = new PortfolioStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.assetVisitor = new AssetStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.personVisitor = new PersonStateVisitor(dispatch);
    this.entityVisitor = new EntityStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.workspaceRoleVisitor = new WorkspaceRoleStateVisitor(dispatch);
  }

  post() {
    this.assetVisitor.post();
    this.entityVisitor.post();
    this.personVisitor.post();
    this.portfolioVisitor.post();
    this.projectVisitor.post();
    this.workspaceBaseVisitor.post();
    this.workspaceRoleVisitor.post();
  }
}
