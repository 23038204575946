import React from 'react';

export function useActiveElement() {
  const [active, setActive] = React.useState<string>();

  const setInactive = React.useCallback((id: string) => {
    setActive((active) => {
      if (id === active) {
        return undefined;
      }
      return active;
    });
  }, []);

  return { active, setActive, setInactive };
}
