import * as React from 'react';
import { useDispatch } from 'react-redux';
import { reset } from '~src/data/store/modules/account';
// import history from '~src/navigation/history';
import { userBasePath } from '~src/navigation/paths/user/paths';
import { AppDispatch } from '~src/store/store';

import {
  Alert,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { User } from '@pladdenico/models';
import { useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const useStyles = makeStyles()((_theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  grow: {
    flexGrow: 1,
  },

  paper: {
    padding: '3%',
  },
}));

export const ResetPasswordComponent = React.memo(() => {
  const { classes } = useStyles();

  const [existingPassword, setExistingPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  // const [password, setPassword] = React.useState('');
  const [resetError, setResetError] = React.useState<any>(undefined);
  const [resetSuccess, setResetSuccess] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleDismissFailedToReset = () => {
    setResetError(undefined);
  };

  const handleDismissSuccessToReset = () => {
    setResetSuccess(false);
  };

  const handleOnChangeExistingPassword = (event: any): void => {
    setExistingPassword(event.target.value);
  };

  const handleOnChangeNewPassword = (event: any): void => {
    setNewPassword(event.target.value);
  };

  const handleOnSubmit = (event: any): void => {
    event.preventDefault();
    dispatch(
      reset(
        existingPassword,
        newPassword,
        (user: User | undefined, err: any) => {
          if (err) {
            setResetError(err);
            setResetSuccess(false);
          } else {
            setResetError(undefined);
            setResetSuccess(true);
            navigate(userBasePath);
          }
        },
      ),
    );
  };

  let failedToResetElement: React.ReactElement<unknown> | undefined = undefined;
  if (resetError) {
    const message = resetError.message;
    failedToResetElement = (
      <Alert severity="error" onClose={handleDismissFailedToReset}>
        <Typography>Failed to reset: {message}</Typography>
      </Alert>
    );
  }

  let successToResetElement: React.ReactElement<unknown> | undefined =
    undefined;
  if (resetSuccess) {
    successToResetElement = (
      <Alert severity="success" onClose={handleDismissSuccessToReset}>
        <Typography>Password reset</Typography>
      </Alert>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.grow}
    >
      <Paper className={classes.paper}>
        <Typography variant="h5">Reset password</Typography>
        <form
          className={classes.container}
          // action="/api/v1/account/reset"
          // method="post"
          // onSubmit={handleOnSubmit}
        >
          <Grid2 container columnSpacing={1}>
            <Grid2>
              <TextField
                margin="normal"
                required
                id="password"
                label="Existing password"
                type="password"
                onChange={handleOnChangeExistingPassword}
              />
            </Grid2>
            <Grid2>
              <TextField
                margin="normal"
                required
                id="newPassword"
                label="New password"
                type="password"
                onChange={handleOnChangeNewPassword}
              />
            </Grid2>
          </Grid2>
          {failedToResetElement}
          {successToResetElement}
        </form>
        <Button
          type="submit"
          variant="contained"
          onClick={handleOnSubmit}
          color="primary"
        >
          Confirm
        </Button>
      </Paper>
    </Grid>
  );
});
