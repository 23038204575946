import { configureStore } from '@reduxjs/toolkit';
import { workspaceDataReducer } from '~src/data/store/reducers/reducers';

export const exportWorkspaceStore = configureStore({
  // preloadState: initialReducer,
  reducer: workspaceDataReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  // middleware: customizedMiddleware,
  devTools: {
    // actionSanitizer,
    // stateSanitizer: stateSanitizer as any,
  },
});
export type ExportWorkspaceDispatch = typeof exportWorkspaceStore.dispatch;
export type ExportWorkspaceStore = typeof exportWorkspaceStore;
