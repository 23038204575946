import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createBankNotes } from '~src/data/store/modules/assets/bank-notes/requests';
import { BankNoteState } from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputBankNoteData } from '~src/domain/workspace/components/asset/types/bank-notes/form/bank-note-input.data';
import { useBankNoteFields } from '~src/domain/workspace/components/asset/types/bank-notes/form/use-bank-note-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: InputBankNoteData;
  callbacks?: {
    onSuccess?: (bankNote: BankNoteState) => void;
    onFailure?: (error: any) => void;
  };
}

export const useCreateBankNoteForm = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (assetId: string, data: InputBankNoteData) => {
      return dispatch(
        createBankNotes(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            assetId: assetId,
            accountNumber: data.accountNumber,
            bank: data.bank,
            currencyId: data.currencyId,
            description: data.description,
          },
        ]),
      )
        .then((bankNotes) => {
          const bankNote = bankNotes[0];
          callbacks?.onSuccess && callbacks.onSuccess(bankNote);
          return bankNote;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<InputBankNoteData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useBankNoteFields(workspace, control);

  const onSubmit = React.useCallback(
    (
      assetId: string,
      successCallback: (bankNote: BankNoteState) => Promise<void>,
    ): SubmitHandler<InputBankNoteData> =>
      (data) => {
        return onSave(assetId, data).then((bankNote) =>
          successCallback(bankNote),
        );
      },
    [onSave],
  );

  const submit = React.useCallback(
    (
      assetId: string,
      successCallback: (bankNote: BankNoteState) => Promise<void>,
    ) => {
      return handleSubmit(onSubmit(assetId, successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
