import {
  TransactionStateVisitor,
  TransactionVisitable,
} from '~src/data/store/visitors/holding/transaction-visitor';
import { Transaction } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleTransactions(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Transaction[],
  subscriptions: Operation[],
) {
  const transactionVisitor = new TransactionStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  const res = outputs.map((transaction) => {
    const transactionVisitable = new TransactionVisitable(
      transaction.project ? transaction.project.id : '',
      transaction,
    );
    return transactionVisitable.accept(transactionVisitor);
  });
  transactionVisitor.post();
  return res;
}
