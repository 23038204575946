import React from 'react';
import { useSelector } from 'react-redux';
// import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
// import { DialogComponent } from '~src/components/utils/dialog.component';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingGroups } from '~src/data/store/selectors/finance/holding-groups';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { SubFilterComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/filter/subfilter.component';
// import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { PivotTypes } from '~src/utils/interfaces/pivot-type';

// import { Button } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  holdings: HoldingState[];
  projectId: string;
  workspace: WorkspaceState;
  date: moment.Moment;
}

export const FilterComponent = (props: Props) => {
  const { workspace, projectId, date, holdings } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );
  const pivots = Object.values(PivotTypes).map((type) => type);
  const pivotGroups = useSelector((state: RootState) => {
    return pivots.map((pivot) => {
      return {
        pivot,
        groups: getHoldingGroups(selectData(state), {
          workspaceId: workspace.id,
          projectId,
          date,
          filterKey: pivot,
          holdings,
        }),
      };
    });
  });

  // const dialog = useVisible();

  const renderFilterList = React.useCallback(() => {
    const elements: JSX.Element[] = [];
    pivotGroups.map((pivotGroup) => {
      elements.push(
        <SubFilterComponent
          key={pivotGroup.pivot}
          filter={filter}
          groups={pivotGroup.groups}
          pivot={pivotGroup.pivot}
        />,
      );
    });
    return elements;
  }, [filter, pivotGroups]);

  return <Box>{renderFilterList()}</Box>;

  // return (
  //   <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  //     {/* <Button
  //       variant="outlined"
  //       onClick={dialog.open}
  //       sx={{ mt: 1, ml: 1, backgroundColor: 'rgba(255,255,255,0.8)' }}
  //     >
  //       Filter
  //     </Button> */}
  //     <DialogComponent
  //       dialogProps={{
  //         open: dialog.isShowing,
  //         onClose: dialog.close,
  //         fullWidth: true,
  //         maxWidth: 'lg',
  //       }}
  //     >
  //       <DialogContentComponent handleClose={dialog.close} title={'Filter'}>
  //         <Box>{renderFilterList()}</Box>
  //       </DialogContentComponent>
  //     </DialogComponent>
  //   </Box>
  // );
};
