import { Edge, Node, ReactFlowInstance } from 'reactflow';
// import {
//   OnEdgesSet,
//   OnNodesSet,
// } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-store';

export const saveFlowToJson = (reactFlowInstance: ReactFlowInstance) => {
  const flow = reactFlowInstance.toObject();
  return JSON.stringify(flow);
};

export const restoreFlowFromJson = <Data, U extends string | undefined>(
  flowJson: string,
) => {
  const flow = JSON.parse(flowJson);
  if (flow) {
    const { x = 0, y = 0, zoom = 1 } = flow.viewport;
    const nodes = flow.nodes as Node<Data, U>[];
    const edges = flow.edges as Edge[];
    return {
      nodes,
      edges,
      viewport: { x, y, zoom },
    };
  }
  return {
    nodes: [],
    edges: [],
    viewport: { x: 0, y: 0, zoom: 1 },
  };
};
