// import { compact } from 'lodash';
import {
  AssetObjStateVisitor,
  AssetObjVisitable,
} from '~src/data/store/visitors/asset/asset-obj-visitor';
import {
  AssetStateVisitor,
  AssetVisitable,
} from '~src/data/store/visitors/asset/asset-visitor';
import {
  Asset,
  AssetObj,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export function handleAssets(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  assets: Asset[],
  subscriptions: Operation[],
) {
  const assetVisitor = new AssetStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  assets.forEach((asset) => {
    const assetVisitable = new AssetVisitable(tenantId, workspaceId, asset);
    assetVisitable.accept(assetVisitor);
  });
  assetVisitor.post();
}

export function handleAssetObjs(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  assets: AssetObj[],
  subscriptions: Operation[],
) {
  const visitor = new AssetObjStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  assets.forEach((asset) => {
    const assetVisitable = new AssetObjVisitable(tenantId, workspaceId, asset);
    assetVisitable.accept(visitor);
  });

  return visitor.post();
  // runVisitor(assetVisitor, accepter(assetVisitor));
  // return assetVisitor.run(accepter);
}
