import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGeneratedReports } from '~src/data/store/modules/workspaces/reports/generated-reports/requests';
import { fetchProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { fetchReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getGeneratedReportsByProjectId } from '~src/data/store/selectors/workspace/projects/report/generated-report/selectors';
import { getProjectReportsByProjectId } from '~src/data/store/selectors/workspace/projects/report/project-report/selectors';
import { getReportsByProjectId } from '~src/data/store/selectors/workspace/projects/report/report/selectors';
import { CreateProjectReportComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/create-project-report.component';
import { ProjectReportItemComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/project-report-item.component';
import { CreateScheduledReportComponent } from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/form/create-scheduled-report.component';
import { ScheduledReportComponent } from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/scheduled-report.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, colors, Grid, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

export const ReportingComponent = memo((props: Props) => {
  const { workspace, projectId } = props;
  const reports = useSelector((state: RootState) =>
    getReportsByProjectId(selectWorkspaceData(state), {
      projectId,
    }),
  );
  const projectReports = useSelector((state: RootState) =>
    getProjectReportsByProjectId(selectWorkspaceData(state), {
      projectId,
    }),
  );
  const generatedReports = useSelector((state: RootState) =>
    getGeneratedReportsByProjectId(selectWorkspaceData(state), {
      projectId,
    }),
  );

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    dispatch(fetchReports(workspace.tenantId, workspace.id));
    dispatch(fetchProjectReports(workspace.tenantId, workspace.id));
    dispatch(fetchGeneratedReports(workspace.tenantId, workspace.id));
  }, [dispatch, workspace.id, workspace.tenantId]);

  const createSchedulerReportDialogVisible = useVisible();
  const createProjectReportDialogVisible = useVisible();

  const reportElements = reports?.map((report) => (
    <Grid item key={report.id} xs={4}>
      <ScheduledReportComponent
        workspace={workspace}
        projectId={projectId}
        report={report}
      />
    </Grid>
  ));

  const projectReportElements = projectReports?.map((report) => (
    <ProjectReportItemComponent
      key={report.id}
      workspace={workspace}
      projectId={projectId}
      projectReport={report}
    />
  ));

  const generatedReportElements = generatedReports?.map((report) => (
    <Grid item key={report.id} xs={4}>
      {report.name}
    </Grid>
  ));

  return (
    <Box sx={{ ml: 1 }}>
      <Typography variant="h5">Reports</Typography>
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid container>
          {reportElements}
          <Grid
            item
            xs={6}
            rowSpacing={1}
            sx={{ ml: 1, mt: 1, background: colors.blueGrey[50] }}
          >
            <Box>{projectReportElements}</Box>
          </Grid>
          {generatedReportElements}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', flex: 1 }}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={createSchedulerReportDialogVisible.open}
          >
            Schedule report
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', flex: 1 }}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={createProjectReportDialogVisible.open}
          >
            Create new report
          </Button>
        </Grid>
      </Grid>
      <CreateScheduledReportComponent
        workspace={workspace}
        projectId={projectId}
        isShowing={createSchedulerReportDialogVisible.isShowing}
        handleCancel={createSchedulerReportDialogVisible.close}
        handleClose={createSchedulerReportDialogVisible.close}
      />
      <CreateProjectReportComponent
        workspace={workspace}
        projectId={projectId}
        isShowing={createProjectReportDialogVisible.isShowing}
        handleCancel={createProjectReportDialogVisible.close}
        handleClose={createProjectReportDialogVisible.close}
      />
    </Box>
  );
});
