import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseStockGoalRuleId {
  stockHoldingId: string;
  id: string;
}

export type StockGoalRuleState = Omit<
  graphqlWorkspaceTypes.StockGoalRule,
  'stockHolding'
> & {
  stockHoldingId: string;
};

export const initialStockGoalRuleState = [];

const selectKeyId = (stockGoalRule: BaseStockGoalRuleId) =>
  stockGoalRule.stockHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseStockGoalRuleId, b: BaseStockGoalRuleId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseStockGoalRuleId,
  StockGoalRuleState,
  string
>(initialStockGoalRuleState, selectKeyId, keyComparator, tComparator);

export const stockGoalRulesSlice = createBaseRecordSlice(
  'stockGoalRules',
  adapter,
);
export const stockGoalRulesSelectors = adapter.selectors;

export const stockGoalRulesReducer = stockGoalRulesSlice.reducer;
export const stockGoalRulesActions = stockGoalRulesSlice.actions;
