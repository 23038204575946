export interface Quote {
  date: Date;
  paper: string;
  exchange: string;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  value: number;
}

export interface QuoteProperties {
  date?: boolean;
  paper?: boolean;
  exchange?: boolean;
  open?: boolean;
  high?: boolean;
  low?: boolean;
  close?: boolean;
  volume?: boolean;
  value?: boolean;
}

export function parseQuote(quote: Quote): Quote {
  return {
    // date: moment(quote.date, 'YYYYMMDD').toDate(),
    date: new Date(quote.date),
    paper: quote.paper,
    exchange: quote.exchange,
    open: +quote.open,
    high: +quote.high,
    low: +quote.low,
    close: +quote.close,
    volume: +quote.volume,
    value: +quote.value,
  };
}
