import { StockTradeState } from '~src/data/store/reducers/holding/holding-types/stock/stock-trade/reducer';
import { StockTrade } from '~src/services/graphql/workspace/client/graphql';

export function parseStockTrade(stockTrade: StockTrade): StockTradeState {
  return {
    id: stockTrade.id,
    stockHoldingId: stockTrade.stockHolding?.id ?? '',
    shares: stockTrade.shares,
    sharePrice: stockTrade.sharePrice,
    transferId: stockTrade.transfer?.id ?? '',
    positionId: stockTrade.position?.id ?? '',
  };
}
