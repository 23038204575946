import axios from 'axios';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import * as uuid from 'uuid';
import { ImageComponent } from '~src/components/utils/image/image.component';
import { fetchSignedUrls } from '~src/data/store/modules/obj-storage/objs/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { ImageList, ImageListItem, Theme } from '@mui/material';
import { graphqlObjStorageOperations } from '@pladdenico/obj-storage';

const useStyles = makeStyles()((_theme: Theme) => ({
  base: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  activeStyle: {
    borderColor: '#2196f3',
  },
  acceptStyle: {
    borderColor: '#00e676',
  },
  rejectStyle: {
    borderColor: '#ff1744',
  },
}));

interface Props {
  baseId: string;
  callback: (
    id: string,
    name: string,
    description: string,
    url: string,
    type: string,
  ) => Promise<graphqlWorkspaceTypes.File[]>;
}

export const ImgDropzoneComponent = (props: Props) => {
  const [files, setFiles] = React.useState<
    Array<{ file: FileState; img: string }>
  >([]);
  const dispatch = useDispatch<AppDispatch>();
  const { callback } = props;
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          if (reader) {
            const imgAsBuffer = reader.result;
            console.log(reader.result);
            if (imgAsBuffer) {
              const blob = new Blob([imgAsBuffer]);
              const img = URL.createObjectURL(blob);
              const id = `${props.baseId}${uuid.v1()}`;
              dispatch(
                fetchSignedUrls(
                  [id],
                  graphqlObjStorageOperations.ObjOperationType.Put,
                ),
              ).then((urls: Array<{ id: string; url: string }> | undefined) => {
                if (urls) {
                  const url = urls[0].url;
                  return axios
                    .put(url, blob)
                    .then(() => callback(id, '', 'description', url, 'image'))
                    .then((files) => {
                      const file = files[0];
                      if (file) {
                        setFiles((files) => [...files, { file, img }]);
                      }
                    });
                }
              });
            }
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [callback, dispatch, props.baseId],
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const { classes, cx } = useStyles();

  const className = React.useMemo(() => {
    return cx(classes.base, {
      [classes.activeStyle]: isDragActive,
      [classes.acceptStyle]: isDragAccept,
      [classes.rejectStyle]: isDragReject,
    });
  }, [
    cx,
    classes.base,
    classes.activeStyle,
    classes.acceptStyle,
    classes.rejectStyle,
    isDragActive,
    isDragAccept,
    isDragReject,
  ]);

  const imageList = React.useMemo(() => {
    if (files.length) {
      const imgElements = files.map((file, idx) => {
        return (
          <ImageListItem key={idx}>
            <ImageComponent
              file={file.file}
              url={{ url: file.img, id: file.file.id }}
              actions={{}}
            />
          </ImageListItem>
        );
      });

      return (
        <ImageList
          rowHeight={150}
          // columnWidth={200}
          cols={Math.floor(Math.min(imgElements.length, 4))}
          // sx={{ height: 200 }}
        >
          {imgElements}
        </ImageList>
      );
    }
  }, [files]);

  return (
    <div
      {...getRootProps({
        // style,
        className,
      })}
    >
      <input {...getInputProps()} />
      <p>Drag n drop some files here, or click to select files</p>
      {imageList}
    </div>
  );
};
