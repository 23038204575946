import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseAddressId {
  id: string;
  personId: string;
}

export type AddressState = Omit<
  graphqlWorkspaceTypes.Address,
  'person' | 'workspace'
> & {
  workspaceId: string;
  personId: string;
};

export const initialAddressesState: RecordState<AddressState, string>[] = [];

const selectKeyId = (address: BaseAddressId) => address.personId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseAddressId, b: BaseAddressId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseAddressId, AddressState, string>(
  initialAddressesState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const addressesSlice = createBaseRecordSlice('addresses', adapter);
export const addressesSelectors = adapter.selectors;

export const addressesReducer = addressesSlice.reducer;
export const addressesActions = addressesSlice.actions;
export const addressesSlices = addressesSlice.slices;
