import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  workspace: WorkspaceState;
  transfer: TransferState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  root: {
    // padding: theme.spacing(3),
  },
}));

export const TransferComponent = React.memo((props: Props) => {
  const { transfer } = props;
  const { classes } = useStyles();
  const currency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), {
      id: transfer.currencyId,
    }),
  );
  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom>
        {transfer.description}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        {transfer.type}
      </Typography>
      <Typography color="textPrimary" variant="body1">
        {moment(transfer.date).format('DD.MM.YY')}
      </Typography>
      <Typography color="textPrimary" variant="body1">
        {currency?.symbol} {transfer.value}
      </Typography>
    </div>
  );
});
