import { useDispatch } from 'react-redux';
import { InvestmentState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import {
  createInvestments,
  deleteInvestments,
  updateInvestments,
} from '~src/data/store/modules/assets/private-equity-fund/investments/requests';

export function useInvestmentActions(
  workspace: WorkspaceState,
  fundId: string,
) {
  const dispatch = useDispatch<AppDispatch>();
  const onCreate = (
    investmentInput: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType,
  ) => {
    return dispatch(
      createInvestments(workspace.tenantId, workspace.id, fundId, [
        investmentInput,
      ]),
    );
  };
  const onUpdate = (oldData: InvestmentState, newData: InvestmentState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestmentInputType =
          {
            id: newData.id,
            holdingId: newData.holdingId,
            fundId: newData.fundId,
            exit: newData.exit,
            description: newData.description,
            name: newData.name,
            image: newData.image,
          };
        dispatch(
          updateInvestments(workspace.tenantId, workspace.id, fundId, [input]),
        )
          .then((_investments) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: InvestmentState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        deleteInvestments(workspace.tenantId, workspace.id, fundId, [oldData]),
      )
        .then((_numberOfDeletes) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return { onCreate, onUpdate, onDelete };
}
