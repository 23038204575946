import { combineReducers } from 'redux';
import {
  dnbAdapterInitialState,
  dnbAdapterReducer,
} from '~src/services/xlsx/handlers/adapters/dnb/reducer';
import {
  nordnetAdapterInitialState,
  nordnetAdapterReducer,
} from '~src/services/xlsx/handlers/adapters/nordnet/reducer';
import {
  vpsAdapterInitialState,
  vpsAdapterReducer,
} from '~src/services/xlsx/handlers/adapters/vps/reducer';

export const initialAdapterState = {
  dnb: dnbAdapterInitialState,
  vps: vpsAdapterInitialState,
  nordnet: nordnetAdapterInitialState,
};

export const adapterReducer = combineReducers({
  dnb: dnbAdapterReducer,
  vps: vpsAdapterReducer,
  nordnet: nordnetAdapterReducer,
});
