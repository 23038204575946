import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { UserState } from '~src/data/store/reducers/user/user/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectUserData } from '~src/data/store/selectors/selectors';

import { useUserTabs } from './tab/use-user-tabs';
import { UserTabsComponent } from './tab/user-tabs.component';

export const UserWrapper = React.memo(() => {
  const user = useSelector(
    (state: RootState) => selectUserData(state).account.user,
  );
  if (user) {
    return <UserWrapperWithUser user={user} />;
  }
  return <Navigate to="/" />;
});

interface PropsWithUser {
  user: UserState;
}

export const UserWrapperWithUser = React.memo((props: PropsWithUser) => {
  const tabs = useUserTabs();
  const { tabId } = useParams<{ tabId: string }>();
  console.log(tabId);
  const tab = tabs.find((t) => t.name === tabId);

  return tab ? (
    <UserTabsComponent user={props.user} tab={tab} tabs={tabs} />
  ) : (
    <div>unknown tab</div>
  );
});
