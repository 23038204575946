import { parseTenantRole } from '~src/data/store/modules/tenant/tenant-roles/tenant-roles/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  GroupStateVisitor,
  GroupVisitable,
  GroupVisitor,
} from '~src/data/store/visitors/workspace/role/group-visitor';
import {
  tenantRolesActions,
  TenantRoleState,
} from '~src/data/store/reducers/tenant/tenant-role/tenant-roles/reducer';
import { TenantRole } from '~src/services/graphql/tenant/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface TenantRoleVisitor {
  visit(tenantRole: TenantRoleVisitable): TenantRoleState;
  post(): void;
  groupVisitor: GroupVisitor;
}

export class TenantRoleVisitable implements Visitable<TenantRoleVisitor> {
  constructor(private _tenantRole: TenantRole) {}
  public accept(visitor: TenantRoleVisitor) {
    if (this._tenantRole.group) {
      const groupVisitable = new GroupVisitable(this._tenantRole.group);
      groupVisitable.accept(visitor.groupVisitor);
    }
    return visitor.visit(this);
  }

  public parse(): TenantRoleState {
    return parseTenantRole(this._tenantRole);
  }
}

export class TenantRoleStateVisitor implements TenantRoleVisitor {
  private _tenantRoles: TenantRoleState[];
  public groupVisitor: GroupVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._tenantRoles = [];
    this.groupVisitor = new GroupStateVisitor(this._dispatch);
  }
  public visit(role: TenantRoleVisitable): TenantRoleState {
    const roleState = role.parse();
    this._tenantRoles.push(roleState);
    return roleState;
  }
  post() {
    this._dispatch(tenantRolesActions.addMany(this._tenantRoles));
    this.groupVisitor.post();
  }
}
