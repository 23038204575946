import {
  HoldingConfigStateVisitor,
  HoldingConfigVisitable,
} from '~src/data/store/visitors/holding/config/holding-config-visitor';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleHoldingConfigs(
  dispatch: any,
  holdingId: string,
  outputs: graphqlWorkspaceTypes.HoldingConfig[],
  tenantId: string,
  workspaceId: string,
  subscriptions: Operation[],
) {
  const holdingDashboardConfigVisitor = new HoldingConfigStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  const ret = outputs.map((config) => {
    const visitable = new HoldingConfigVisitable(holdingId, config);
    return visitable.accept(holdingDashboardConfigVisitor);
  });
  holdingDashboardConfigVisitor.post();
  return ret;
}
