import {
  PrivateEquityFundCapitalCallStateVisitor,
  PrivateEquityFundCapitalCallVisitable,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-capital-call-visitor';
import { PrivateEquityFundCapitalCall } from '~src/services/graphql/workspace/client/graphql';

export function handleCapitalCalls(
  _workspaceId: string,
  investorId: string,
  dispatch: any,
  outputs: PrivateEquityFundCapitalCall[],
) {
  const visitor = new PrivateEquityFundCapitalCallStateVisitor(dispatch);
  outputs.forEach((entity) => {
    const visitable = new PrivateEquityFundCapitalCallVisitable(
      investorId,
      entity,
    );
    visitable.accept(visitor);
  });
  visitor.post();
  return outputs;
}
