import * as React from 'react';
import { useParams } from 'react-router-dom';
import { FinanceTabsComponent } from '~src/domain/workspace/components/finance/tab/finance-tabs.component';

import { useFinanceTabs } from './tab/use-finance-tabs';

export const FinanceTabWrapper = React.memo(() => {
  const tabs = useFinanceTabs();
  const { tenantId, workspaceId, tabId } = useParams<{
    tenantId: string;
    workspaceId: string;
    tabId: string;
  }>();
  console.log(tabId);
  const tab = tabs.find((t) => t.name === tabId);

  return tab && tenantId && workspaceId ? (
    <FinanceTabsComponent
      tenantId={tenantId}
      workspaceId={workspaceId}
      tab={tab}
      tabs={tabs}
    />
  ) : (
    <div>unknown finance tab</div>
  );
});

// export const FinanceRouterWrapper = React.memo(() => {
//   useFetchFinanceData();
//   return (
//     <Routes>
//       {/* <Route path="/finance" exact={true}>
//         <Redirect to={financeTabPath(FinanceTabs.Papers)} />
//       </Route> */}
//       {/* <Route path="/finance/papers/:paperId">
//         <PaperRouterWrapper
//           workspace={props.workspace}
//           portfolioProject={props.portfolioProject}
//           portfolio={portfolio}
//         />
//       </Route> */}
//       <Route path=":tabId/*" element={<FinanceRouterWrapperWithTab />} />
//       <Route
//         path="*"
//         // exact={true}
//         element={<Navigate to={financeTabPath(FinanceTabs.Papers)} replace />}
//       />
//     </Routes>
//   );
// });
