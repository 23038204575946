import { Box, Typography } from '@mui/material';
import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { InputNodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';

export const InputNode = React.memo(
  ({ data, isConnectable }: NodeProps<InputNodeData>) => {
    const valid = React.useMemo(() => {
      if (data.variableId == null) {
        return false;
      }
      return true;
    }, [data.variableId]);
    return (
      <Box
        sx={{
          p: 1.5,
          border: '1px solid #555',
          borderRadius: '5px',
          backgroundColor: valid ? 'white' : 'red',
        }}
      >
        <Typography variant="caption">{data.label}</Typography>
        {/* <Typography variant="caption">{data.value}</Typography> */}
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </Box>
    );
  },
);
