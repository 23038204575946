import * as React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { ScenarioData } from '~src/domain/workspace/components/project/scenario/scenarios.component';
// import history from '~src/navigation/history';
import { scenarioBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/scenario/paths';
import { eventParser } from '~src/utils/form/event-parser';

import { Link, TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';
import { useNavigate } from 'react-router-dom';

export function useScenarioFields(
  workspace: WorkspaceState,
  projectId: string,
): TableField.Field<ScenarioData>[] {
  const navigate = useNavigate();
  const handleScenarioClick = (
    tenantId: string,
    workspaceId: string,
    projectId: string,
    scenario: Scenario,
  ) => {
    navigate(scenarioBasePath(tenantId, workspaceId, projectId, scenario.id));
  };

  const fields: TableField.Field<ScenarioData>[] = [
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.scenario.name,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const scenario = {
          ...data.scenario,
          name: eventParser(event) as string,
        };
        return {
          ...data,
          scenario,
        };
      },
      renderView: (viewProps) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handleScenarioClick(
                workspace.tenantId,
                workspace.id,
                projectId,
                viewProps.data.scenario,
              )
            }
          >
            {viewProps.data.scenario.name}
          </Link>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="name"
            value={editProps.data.scenario.name}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return fields;
}
