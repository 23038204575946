import { makeStyles } from 'tss-react/mui';
import { colorPalette } from '~src/utils/common/colors/chart-colors';
import { getInitials } from '~src/utils/common/get-initials';

import { Avatar, Theme, Typography } from '@mui/material';
import { User } from '@pladdenico/models';
import { TableField } from '@pladdenico/table';

const useStyles = makeStyles()((theme: Theme) => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export type UserData = User;

const getAvatarColor = (seed: string) => {
  return colorPalette[seed.length % colorPalette.length];
};

export function useUserFields(
  _workspaceId: string,
): TableField.Field<UserData>[] {
  const { classes, cx } = useStyles();

  const fields: TableField.Field<UserData>[] = [
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.name,
      renderView: (editProps) => {
        return (
          <div className={classes.nameContainer}>
            <Avatar
              className={cx(classes.avatar)}
              sx={{
                width: 32,
                height: 32,
                fontSize: 16,
                backgroundColor: getAvatarColor(editProps.data.name),
              }}
            >
              {/* src={user.avatarUrl} */}
              {getInitials(editProps.data.name)}
            </Avatar>
            <Typography variant="body1">{editProps.data.name}</Typography>
          </div>
        );
      },
    },
  ];
  return fields;
}
