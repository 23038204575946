import * as React from 'react';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';
import { InvestorState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';
import { getContactsByInvestorId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/contacts/selectors';

export function useInvestorFields(
  _workspaceId: string,
  _projectId: string,
  _privateEquityFundId: string,
): TableField.Field<InvestorState>[] {
  const fields: TableField.Field<InvestorState>[] = [
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (investor) => investor.name,
      update: (investor, event: React.ChangeEvent<HTMLInputElement>) => {
        return {
          ...investor,
          name: String(eventParser(event)),
        };
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="name"
            value={editProps.data.name}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (investor) => investor.description,
      update: (investor, event: React.ChangeEvent<HTMLInputElement>) => {
        return {
          ...investor,
          description: String(eventParser(event)),
        };
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="description"
            value={editProps.data.description}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'contacts',
      name: 'contacts',
      field: 'contacts',
      type: TableField.FieldTypes.string,
      iteratee: (investor) => {
        const contacts = getContactsByInvestorId(
          selectWorkspaceData(store.getState()),
          investor.id,
        );
        return contacts.map((contact) => contact.name).join(',');
      },
      update: (investor, event: React.ChangeEvent<HTMLInputElement>) => {
        return {
          ...investor,
          description: String(eventParser(event)),
        };
      },
      renderEdit: (editProps) => {
        const contacts = getContactsByInvestorId(
          selectWorkspaceData(store.getState()),
          editProps.data.id,
        );
        const contactElements = contacts.map((contact) => {
          <TextField
            margin="normal"
            required
            id="contacts"
            value={contact.name}
            type="text"
            onChange={editProps.onChange}
          />;
        });
        return <>{contactElements}</>;
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return fields;
}
