import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { useHoldingForecastFlowElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/use-forecast-flow-element.component';
import { HoldingForecastToolbarComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/holding/holding-forecast-toolbar.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { ReactFlowProvider } from 'reactflow';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  scenario: Scenario;
  budget: Budget;
  forecast: ForecastState;
  forecastFlowElement: FlowElementState;
}

export const HoldingForecastFlowElementComponent = React.memo(
  (props: Props) => {
    return (
      <ReactFlowProvider>
        <_HoldingForecastFlowElementComponent {...props} />
      </ReactFlowProvider>
    );
  },
);

const _HoldingForecastFlowElementComponent = React.memo((props: Props) => {
  const {
    workspace,
    project,
    holding,
    scenario,
    budget,
    forecast,
    forecastFlowElement,
  } = props;

  const { component, evaluate } = useHoldingForecastFlowElementComponent(
    workspace,
    project,
    holding,
    scenario,
    budget,
    forecast,
    forecastFlowElement,
  );

  return (
    <Grid>
      <HoldingForecastToolbarComponent
        scenario={scenario}
        budget={budget}
        forecast={forecast}
        holding={holding}
        project={project}
        workspace={workspace}
        evaluate={evaluate}
      />
      {component}
    </Grid>
  );
});
