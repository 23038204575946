import { RequestStatus } from '~src/services/request/request-status';
import { RequestStatusHandler } from '~src/services/request/graphql-result-status-handler';
import { AppDispatch } from '~src/store/store';

export abstract class ActionHandler {
  public abstract addOne(requestStatus: RequestStatus): any;
  public abstract addMany(requestStatuses: RequestStatus[]): any;
}

export class ReducerActionRequestStatusHandler<A extends ActionHandler>
  implements RequestStatusHandler
{
  constructor(
    private dispatch: AppDispatch,
    private a: A,
  ) {
    this.dispatch = dispatch;
    this.a = a;
  }

  public handleOne(requestStatus: RequestStatus) {
    return this.dispatch(this.a.addOne(requestStatus));
  }
  public handleMany(requestStatuses: RequestStatus[]) {
    return this.dispatch(this.a.addMany(requestStatuses));
  }
}
