import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  createAction as bankNoteCreateAction,
  BankNoteHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bank-note/create-action';
import {
  createAction as bondCreateAction,
  BondHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/create-action';
import {
  createAction as cryptoCreateAction,
  CryptoHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/crypto/create-action';
import {
  createAction as fundCreateAction,
  FundHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/fund/create-action';
import {
  createAction as loanCreateAction,
  LoanHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/loan/create-action';
import {
  createAction as stockCreateAction,
  StockHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/create-action';
import {
  createAction as warrantCreateAction,
  WarrantHoldingInputType,
} from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/warrant/create-action';
import { HoldingDataInput } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';

import { HoldingType } from '@pladdenico/models';

export const useCreateTypeHolding = (
  workspace: WorkspaceState,
  holdingType: HoldingType,
  holdingDataInput: HoldingDataInput,
  setHoldingDataInput: (data: HoldingDataInput) => void,
) => {
  const { fields, action, valid } = React.useMemo(() => {
    if (holdingType === HoldingType.Stock) {
      console.log('holdingDataInput', holdingDataInput);
      const input = holdingDataInput as StockHoldingInputType;
      return stockCreateAction(workspace, input, setHoldingDataInput);
    } else if (holdingType === HoldingType.BankNote) {
      const input = holdingDataInput as BankNoteHoldingInputType;
      return bankNoteCreateAction(workspace, input, setHoldingDataInput);
    } else if (holdingType === HoldingType.Loan) {
      const input = holdingDataInput as LoanHoldingInputType;
      return loanCreateAction(workspace, input, setHoldingDataInput);
    } else if (holdingType === HoldingType.Bond) {
      const input = holdingDataInput as BondHoldingInputType;
      return bondCreateAction(workspace, input, setHoldingDataInput);
    } else if (holdingType === HoldingType.Warrant) {
      const input = holdingDataInput as WarrantHoldingInputType;
      return warrantCreateAction(workspace, input, setHoldingDataInput);
    } else if (holdingType === HoldingType.Crypto) {
      const input = holdingDataInput as CryptoHoldingInputType;
      return cryptoCreateAction(workspace, input, setHoldingDataInput);
    } else if (holdingType === HoldingType.Fund) {
      const input = holdingDataInput as FundHoldingInputType;
      return fundCreateAction(workspace, input, setHoldingDataInput);
    } else {
      throw 'Not a valid holding type' + holdingType;
    }
  }, [holdingType, holdingDataInput, workspace, setHoldingDataInput]);

  return {
    fields,
    action,
    valid,
  };
};
