import React from 'react';
import { DataOutput } from '~src/components/xlxs/data-output';
import {
  WorkspaceStateVisitor,
  WorkspaceVisitable,
} from '~src/data/store/visitors/workspace/workspace-visitor';
import { ExportState } from '~src/data/store/reducers/workspace/exports/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { workspaceExportHandlers } from '~src/services/xlsx/handlers/workspaces/export-handlers';
import { fetcher as workspaceFetcher } from '~src/services/xlsx/handlers/workspaces/fetcher';
import { useExport } from '~src/services/xlsx/hooks/use-export';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { exportWorkspaceStore } from './export-workspace-store';

interface Props {
  workspace: WorkspaceState;
}

const getHandlers = (
  workspace: WorkspaceState,
  state: ExportState | undefined,
) => {
  if (!state) {
    return undefined;
  }
  const blobObj = JSON.parse(state.blob) as graphqlWorkspaceTypes.Workspace;
  const visitor = new WorkspaceStateVisitor(
    exportWorkspaceStore.dispatch,
    workspace.tenantId,
    workspace.id,
    [],
  );
  const visitable = new WorkspaceVisitable(workspace.tenantId, blobObj);
  visitable.accept(visitor);
  visitor.post();
  // const normalizedWorkspace = normalizeWorkspace(workspace.tenantId, blobObj);
  return workspaceExportHandlers(exportWorkspaceStore);
};

export const ExportComponent = React.memo((props: Props) => {
  const { handlers, fetcher } = useExport(
    props.workspace,
    workspaceFetcher,
    getHandlers,
  );
  return (
    <div>
      <DataOutput getData={fetcher} handlers={handlers} />
    </div>
  );
});
