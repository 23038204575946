import { parseExchangeRate } from '~src/data/store/modules/exchange-rates/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  ExchangeRate,
  exchangeRatesActions,
} from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { ExchangeRate as ExchangeRateIn } from '~src/services/graphql/user/client/graphql';
import { AppDispatch } from '~src/store/store';

interface ExchangeRateVisitor {
  visit(exchangeRates: ExchangeRateVisitable): void;
  post(): void;
}

export class ExchangeRateVisitable implements Visitable<ExchangeRateVisitor> {
  constructor(private _exchangeRate: ExchangeRateIn) {}
  public accept(visitor: ExchangeRateVisitor) {
    return visitor.visit(this);
  }

  public parse(): ExchangeRate {
    return parseExchangeRate(this._exchangeRate);
  }
}

export class ExchangeRateStateVisitor implements ExchangeRateVisitor {
  private _exchangeRates: ExchangeRate[];
  constructor(private _dispatch: AppDispatch) {
    this._exchangeRates = [];
  }
  public visit(exchangeRates: ExchangeRateVisitable): void {
    const exchangeRatesState = exchangeRates.parse();
    this._exchangeRates.push(exchangeRatesState);
    // return exchangeRatesState;
  }
  post() {
    this._dispatch(
      exchangeRatesActions.upsertManyElements(this._exchangeRates, {
        shouldAutobatch: true,
      }),
    );
  }
}
