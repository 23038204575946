import { handleRoles } from '~src/data/store/modules/people/roles/handlers';
import {
  BaseRoleId,
  rolesActions,
} from '~src/data/store/reducers/person/roles/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchRoles(
  tenantId: string,
  workspaceId: string,
  personId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetRolesDocument;
    const variables: graphqlWorkspaceTypes.GetRolesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getRoles) {
        return handleRoles(dispatch, tenantId, workspaceId, data.getRoles);
      }
      return undefined;
    });
  };
}

export function createRoles(
  tenantId: string,
  workspaceId: string,
  personId: string,
  inputs: graphqlWorkspaceTypes.CreateRoleInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateRolesDocument;
    const variables: graphqlWorkspaceTypes.CreateRolesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createRoles) {
        return handleRoles(dispatch, tenantId, workspaceId, data.createRoles);
      }
      throw new Error('Could not create role');
      // return undefined;
    });
  };
}

export function updateRoles(
  tenantId: string,
  workspaceId: string,
  personId: string,
  inputs: graphqlWorkspaceTypes.UpdateRoleInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateRolesDocument;
    const variables: graphqlWorkspaceTypes.UpdateRolesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateRoles) {
        return handleRoles(dispatch, tenantId, workspaceId, data.updateRoles);
      }
      return [];
    });
  };
}

export function upsertRoles(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertRoleInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertRolesDocument;
    const variables: graphqlWorkspaceTypes.UpsertRolesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertRoles) {
        return handleRoles(dispatch, tenantId, workspaceId, data.upsertRoles);
      }
      return [];
    });
  };
}

export function deleteRoles(
  tenantId: string,
  workspaceId: string,
  ids: BaseRoleId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteRolesDocument;
    const variables: graphqlWorkspaceTypes.DeleteRolesMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteRoles) {
        const deletedIds = data.deleteRoles;
        dispatch(
          rolesActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
