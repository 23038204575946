import React from 'react';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useAllocationCreateAction } from '~src/hooks/actions/workspace/project/portfolio/allocations/use-allocation-create-action';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { CreateAllocationFieldsComponent } from './fields/create-fields.component';

// import { EditHoldingField } from '../../dashboard/components/allocation/allocation-filter-field';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  portfolio: PortfolioState;
  allocationCreateInputInit: graphqlWorkspaceTypes.CreateAllocationInputType;
  handleClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateAllocationFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { allocationInput, setAllocationInput, createAllocation } =
    useAllocationCreateAction(props.workspace, props.portfolio.id);
  return (
    <>
      <DialogTitle id="form-dialog-title">Create allocation</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <CreateAllocationFieldsComponent
            projectId={props.projectId}
            portfolio={props.portfolio}
            allocationInput={allocationInput}
            setAllocationInput={setAllocationInput}
            showId={true}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={createAllocation(props.handleClose)} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
});
