import React from 'react';
import { useSelector } from 'react-redux';
import { RegionalMapComponent } from '~src/components/utils/regional-map.component';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getRegions } from '~src/data/store/selectors/common/regions/selectors';
import { getRegionAllocationsByEntityId } from '~src/data/store/selectors/entities/region-allocations/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { updateCountriesMap } from '~src/utils/map/map-region-value';

interface Props {
  entity: EntityState;
  active?: boolean;
  format?: {
    fontColor?: string;
  };
}

export const EntityRegionalComponent = React.memo((props: Props) => {
  const { format, active, entity } = props;
  const regionAllocations = useSelector((state: RootState) =>
    getRegionAllocationsByEntityId(selectWorkspaceData(state), entity.id),
  );
  const regions = useSelector((state: RootState) =>
    getRegions(selectWorkspaceData(state)),
  );
  const [countriesMap, setCountriesMap] = React.useState(
    () => new Map<string, number>(),
  );

  React.useEffect(() => {
    const countriesMap = new Map<string, number>();
    updateCountriesMap(countriesMap, regions, regionAllocations, 1);
    setCountriesMap(countriesMap);
  }, [regionAllocations, regions]);

  return (
    <RegionalMapComponent
      regionalValues={countriesMap}
      active={active}
      format={format}
    />
  );
});
