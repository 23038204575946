import { PortfolioProject } from '~src/services/graphql/workspace/client/graphql';

export function parsePortfolioProject(
  workspaceId: string,
  portfolioProject: PortfolioProject,
) {
  return {
    id: portfolioProject.id,
    workspaceId,
    projectId: portfolioProject.project ? portfolioProject.project.id : '',
    portfolioId: portfolioProject.portfolio
      ? portfolioProject.portfolio.id
      : '',
  };
}
