import { HoldingType } from '@pladdenico/models';
import { parseLoanHolding } from '~src/data/store/modules/holdings/loan/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  loanHoldingsActions,
  LoanHoldingState,
} from '~src/data/store/reducers/holding/holding-types/loan/loan-holding/reducer';
import {
  LoanHolding,
  Holding,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export const isLoanHolding = (holding: Holding): holding is LoanHolding => {
  return holding.holding.type === HoldingType.Loan;
};

export interface LoanHoldingVisitor {
  visit(loanHolding: LoanHoldingVisitable): LoanHoldingState;
  post(): void;
}

export class LoanHoldingVisitable implements Visitable<LoanHoldingVisitor> {
  constructor(private _loanHolding: LoanHolding) {}
  public accept(visitor: LoanHoldingVisitor) {
    return visitor.visit(this);
  }

  public parse(): LoanHoldingState {
    return parseLoanHolding(this._loanHolding);
  }
}

export class LoanHoldingStateVisitor implements LoanHoldingVisitor {
  private _loanHoldings: LoanHoldingState[];
  constructor(private _dispatch: AppDispatch) {
    this._loanHoldings = [];
  }
  public visit(holding: LoanHoldingVisitable): LoanHoldingState {
    const loanHolding = holding.parse();
    this._loanHoldings.push(loanHolding);
    return loanHolding;
  }
  post() {
    this._dispatch(
      loanHoldingsActions.upsertManyElements(this._loanHoldings, {
        shouldAutobatch: true,
      }),
    );
  }
}
