import { ExchangeRateState } from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { getExchangeRatesByDate } from '~src/data/store/selectors/finance/exchange-rates/selectors';

export function convert(
  exchangeRateState: ExchangeRateState,
  currencyId: string,
  quoteCurrencyId: string,
  date: Date,
) {
  const exchangeRate = getExchangeRatesByDate(exchangeRateState, {
    date,
    id: { baseCurrencyId: quoteCurrencyId, quoteCurrencyId: currencyId },
  });
  if (exchangeRate) {
    if (typeof exchangeRate.value === 'number') {
      return exchangeRate.value;
    } else {
      return 1;
    }
  }
  return 1;
}
