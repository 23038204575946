import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import {
  useForecastElement,
  useSelectForecast,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/use-select-forecast.component';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';

interface Props {
  budget: Budget;
  scenario: Scenario;
  variableId: string;
}
export const SelectVariableForecastComponent = (props: Props) => {
  const { budget, variableId } = props;
  const forecast = useSelectForecast(
    budget,
    ForecastObjectType.variable,
    variableId,
  );

  const selectForecastElement = useForecastElement(
    budget,
    forecast,
    ForecastObjectType.variable,
    variableId,
  );
  return selectForecastElement.element;
};
