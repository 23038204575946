import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';

import {
  regionAllocationSelectors as regionAllocationsSelectors,
  RegionAllocationState,
} from '../../../reducers/entity/region-allocations/reducer';
import { getEntityByHoldingId } from '~src/data/store/selectors/entities/entities/selectors';
import { WorkspaceDataState } from '../../../reducers/reducers';
import { RecordState } from '@pladdenico/common';

const getRegionAllocationState = (state: WorkspaceDataState) =>
  state.holding.entity.regionAllocation.regionAllocations;

export const getRegionAllocations = createSelector(
  (state: WorkspaceDataState) =>
    regionAllocationsSelectors.selectAllElements(
      getRegionAllocationState(state),
    ),
  (regionAllocations): RegionAllocationState[] => {
    return regionAllocations;
  },
);

export const getRegionAllocationsByEntityId = createCachedSelector(
  (state: WorkspaceDataState) => getRegionAllocationState(state),
  (_state: WorkspaceDataState, entityId: string) => entityId,
  (regionAllocationState, entityId): RegionAllocationState[] => {
    return (
      regionAllocationsSelectors.selectElementsByKey(
        regionAllocationState,
        entityId,
      ) ?? []
    );
  },
)({
  keySelector: (_state, entityId) => entityId,
  selectorCreator: createSelector,
});

export const getRegionAllocationsByEntityIds = createCachedSelector(
  (state: WorkspaceDataState) => getRegionAllocationState(state),
  (_state: WorkspaceDataState, entityIds: string[]) => entityIds,
  (regionAllocationState, entityIds: string[]): RegionAllocationState[] => {
    return (
      regionAllocationsSelectors.selectElementsByKeys(
        regionAllocationState,
        entityIds,
      ) ?? []
    );
  },
)({
  keySelector: (_state, entityIds) => entityIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

interface RegionAllocationByHoldingId {
  workspaceId: string;
  projectId: string;
  holdingId: string;
}

export const getRegionAllocationsByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getRegionAllocationState(state),
  (state: WorkspaceDataState, baseId: RegionAllocationByHoldingId) =>
    getEntityByHoldingId(state, baseId),
  (regionAllocations, entity) => {
    if (entity) {
      return regionAllocationsSelectors.selectElementsByKey(
        regionAllocations,
        entity.id,
      );
      // return selectRegionAllocationsByEntityId(regionAllocations, entity.id);
    }
    return undefined;
  },
)({
  keySelector: (_state, id) =>
    `${id.workspaceId}-${id.projectId}-${id.holdingId}`,
  selectorCreator: createSelector,
});

export const selectRegionAllocationsByEntityId = createCachedSelector(
  (
    regionAllocations: RecordState<RegionAllocationState, string>[],
    _entityId: string,
  ) => regionAllocations,
  (
    _regionAllocations: RecordState<RegionAllocationState, string>[],
    entityId: string,
  ) => entityId,
  (regionAllocations, entityId) => {
    return regionAllocationsSelectors.selectElementsByKey(
      regionAllocations,
      entityId,
    );
  },
)({
  keySelector: (_state, entityId) => `${entityId}`,
  selectorCreator: createSelector,
});
