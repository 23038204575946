import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import React from 'react';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { CreateAllocationFormComponent } from './form/create-form.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  portfolio: PortfolioState;
  open: boolean;
  handleClose: () => void;
  allocationCreateInputInit: graphqlWorkspaceTypes.CreateAllocationInputType;
}

export const CreateAllocationComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <CreateAllocationFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        portfolio={props.portfolio}
        handleClose={props.handleClose}
        allocationCreateInputInit={props.allocationCreateInputInit}
      />
    </Dialog>
  );
});
