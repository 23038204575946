import React from 'react';
import { PhoneState } from '~src/data/store/reducers/person/phones/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { PhoneFormComponent, PhoneInput } from './form.component';

interface Props {
  title: string;
  open: boolean;
  phoneInput: PhoneInput;
  setPhoneInput: (phoneInput: PhoneInput) => void;

  handleSavePhone: () => Promise<PhoneState>;
  handleClose: () => void;
  savedCallback: () => void;
}

export const PhoneDialogComponent = React.memo((props: Props) => {
  const handleSavePhone = (): void => {
    props
      .handleSavePhone()
      .then((_value) => {
        props.savedCallback();
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', props.phoneInput, JSON.stringify(err));
      });
  };

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <PhoneFormComponent
          phoneInput={props.phoneInput}
          setPhoneInput={props.setPhoneInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSavePhone} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
