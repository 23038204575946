import {
  fetchAssetObjs,
  fetchAndHandleAssets,
  handleDeleteAssets,
} from '~src/data/store/modules/assets/assets/requests';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { apis } from '~src/services/request/apis';

import { assetPath, Operation } from '@pladdenico/portfolio-api';

export function subscribeToAsset(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  assets: AssetState[],
  subscriptions: Operation[],
) {
  assets.forEach((asset) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: assetPath(tenantId, workspaceId).objectPath(
              asset.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchAndHandleAssets(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to asset', operation, asset.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: assetPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchAssetObjs(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to asset', operation, asset.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: assetPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteAssets(asset.workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to asset', operation, asset.id);
          },
        );
      }
    });
  });
}
