import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface StyleConfig {
  open: boolean;
  alignSelf: React.CSSProperties['alignSelf'];
}
interface DashboardMenuDivProps {
  config: StyleConfig;
}
export const DashboardMenuDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'config',
})<DashboardMenuDivProps>(({ theme, config }) => ({
  // position: 'sticky',
  // alignSelf: config.alignSelf,
  // top: 0,
  [theme.breakpoints.down('sm')]: {
    // top: 63,
  },
  [theme.breakpoints.up('sm')]: {
    // top: 80,
  },
  // position: 'fixed',
  position: 'sticky',
  top: 70,
  zIndex: config.open ? 0 : theme.zIndex.modal,
  width: 'fit-content',
}));
