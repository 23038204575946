import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertWarrants } from '~src/data/store/modules/assets/warrants/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'warrants';

export interface Warrant {
  id: string;
  assetId: string;
  description?: Maybe<string>;
}

const header: Array<keyof Warrant> = ['id', 'assetId', 'description'];

export const warrantExportHandler = (
  data: Warrant[],
): ExportHandler<Warrant> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const warrantImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Warrant> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertWarrants(workspace.tenantId, workspace.id, ts)),
  };
};
