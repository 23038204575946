import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getScenariosByProjectId } from '~src/data/store/selectors/workspace/projects/scenario/scenarios/selectors';
import { ScenarioCardsListComponent } from '~src/domain/workspace/components/project/scenario/card/cards-list.component';
import { Toolbar } from '~src/domain/workspace/components/project/scenario/toolbar.component';
import { useScenarioFields } from '~src/domain/workspace/components/project/scenario/form/use-scenario-fields';
import { Identity } from '~src/utils/interfaces/identity';

import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { Scenario } from './models/scenario';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
}

export interface ScenarioData extends Identity {
  scenario: Scenario;
  share: number;
}

function scenariosToData(scenarios: Scenario[]) {
  return scenarios.map((scenario) => {
    return {
      id: scenario.id,
      scenario,
      share: 1.0,
    };
  });
}

export const ScenariosComponent = React.memo((props: Props) => {
  const { workspace, project } = props;
  const theme: Theme = useTheme();
  const displayAsList = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = React.useState<ScenarioData[]>([]);
  const scenarios = useSelector((state: RootState) =>
    getScenariosByProjectId(selectWorkspaceData(state), project.id),
  );

  React.useEffect(() => {
    setData(scenariosToData(scenarios ?? []));
  }, [scenarios]);

  const fields = useScenarioFields(workspace, project.id);

  const [initialSorters] = React.useState<TableField.Sorter<ScenarioData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  // const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
  const algoData = TableOperations.useDataAlgorithms(
    data,
    initialSorters,
    initialFilters,
  );
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(algoData.filtered);
  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<ScenarioData>
        data={algoData.filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={algoData.filters}
        dispatchFilters={algoData.dispatchFilters}
        sorters={algoData.sorters}
        dispatchSorters={algoData.dispatchSorters}
        // editable={{ onRowUpdate, onRowDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        isSelected={isSelected}
      />
    );
  }, [
    algoData.dispatchFilters,
    algoData.dispatchSorters,
    algoData.filtered,
    algoData.filters,
    algoData.sorters,
    fields,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    selectedData,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar workspace={workspace} project={project} algoData={algoData} />
        {displayAsList ? (
          <ScenarioCardsListComponent
            projectId={project.id}
            data={algoData.filtered}
          />
        ) : (
          tableComponent
        )}
      </Grid>
    </Grid>
  );
});
