import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  workspaceAssetRootPath,
  workspaceAssetsRootPath,
} from '~src/navigation/paths/workspace/asset/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { useWorkspaceBreadcrumbs } from './workspace-breadcrumbs';
import { maxItems, LinkRouter } from './breadcrumbs.component';

function useAssetBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId, assetId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    assetId: string;
  }>();

  const assetsBreadcrumbs = useAssetsBreadcrumbs(last);
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), { id: assetId, workspaceId }),
  );
  if (asset && tenantId != null && workspaceId != null) {
    return [
      assetsBreadcrumbs,
      <Typography
        color="inherit"
        key={workspaceAssetRootPath(tenantId, workspaceId, asset.id)}
      >
        {asset.name}
      </Typography>,
    ];
  }
  return [];
}
export function AssetBreadcrumbComponent() {
  const assetBreadcrumbs = useAssetBreadcrumbs(false);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {assetBreadcrumbs}
    </Breadcrumbs>
  );
}
function useAssetsBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);

  if (tenantId != null && workspaceId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={workspaceAssetsRootPath(tenantId, workspaceId)}
      >
        assets
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceAssetsRootPath(tenantId, workspaceId)}
        to={workspaceAssetsRootPath(tenantId, workspaceId)}
      >
        assets
      </LinkRouter>
    );
    return [workspaceBreadcrumb, projectElement];
  }
  return [];
}
export function AssetsBreadcrumbComponent(props: { last: boolean }) {
  const assetsBreadcrumbs = useAssetsBreadcrumbs(props.last);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {assetsBreadcrumbs}
    </Breadcrumbs>
  );
}
