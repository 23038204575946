import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateTenant } from '~src/data/store/modules/tenant/tenants/requests';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { AppDispatch } from '~src/store/store';

import { TenantInputData } from './tenant-input-data';
import { useTenantFields } from '~src/domain/tenant/components/form/use-fields';

export const useTenantEdit = (tenant: TenantState) => {
  const { control, handleSubmit, watch } = useForm<TenantInputData>({
    defaultValues: {
      name: tenant.name ?? '',
    },
  });
  const tenantFields = useTenantFields(control);

  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: TenantInputData) => {
      return dispatch(updateTenant(tenant.id, { id: tenant.id, ...data }));
    },
    [dispatch, tenant.id],
  );

  const onSubmit: SubmitHandler<TenantInputData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { tenantFields };
};
