import React from 'react';
import { useSelector } from 'react-redux';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { CommitmentsElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/commitments/element';
import { DescriptionElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/description/element';
import { HeaderElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/header/element';
import { ImageElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/image/element';
import { IrrElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/irr/irr-element';
import { PaperElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/paper/element';
import { RegionalValuationElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/regional-valuation/regional-valuation-element';
import { StockElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/stock/element';
import { SummaryElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/summary/element';

export const DashboardComponentComponent = React.memo(
  ({
    workspace,
    project,
    holding,
    dashboardConfig,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: {
    workspace: WorkspaceState;
    project: ProjectState;
    holding: HoldingState;
    dashboardConfig: HoldingDashboardConfigState;
    active: boolean;
    setActive: () => void;
    setInactive: () => void;
    loading: boolean;
    id: string;
    remove: (id: string) => void;
    updated: (
      id: string,
      element: Partial<DashboardElementState>,
    ) => Promise<{ id: string }>;
    element: DashboardElementState;
  }) => {
    const config = useSelector((state: RootState) =>
      getConfigById(selectWorkspaceData(state), { id: 'default' }),
    );
    const { period } = config;

    switch (element.type) {
      case 'header':
        return (
          <HeaderElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            config={config}
            dashboardConfig={dashboardConfig}
            holding={holding}
          />
        );
      case 'commitments':
        return (
          <CommitmentsElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            config={config}
            holdings={[holding]}
            period={period}
            project={project}
          />
        );
      case 'summary':
        return (
          <SummaryElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            config={config}
            holdings={[holding]}
            period={period}
            projectId={project.id}
          />
        );
      case 'valuationGeographical':
        return (
          <RegionalValuationElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            config={config}
            holding={holding}
            project={project}
          />
        );
      case 'irr':
        return (
          <IrrElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            config={config}
            holding={holding}
            project={project}
          />
        );
      case 'detailedDescription':
        return (
          <DescriptionElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
            holding={holding}
          />
        );
      case 'stock':
        return (
          <StockElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
            holding={holding}
          />
        );
      case 'paper':
        return (
          <PaperElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
            holding={holding}
          />
        );
      case 'image':
        return (
          <ImageElement
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
            holding={holding}
          />
        );
      default:
        return <div>{element.type}</div>;
    }
  },
);
