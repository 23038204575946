import { compact } from 'lodash';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { CashFlow } from '~src/utils/finance/cash-flow';

import { getHoldingValue } from '../valuation/holding-value';
import { getHoldingTransfers } from './holding-transfers';
import { createSelector } from 'reselect';

interface Props {
  workspaceId: string;
  holding: HoldingState;
  currency: CurrencyState;
  date: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  useLiveValue: boolean;
}

export const getCashFlowsWithStartAndEnd = createCachedSelector(
  (state: DataState, props: Props) => getHoldingTransfers(state, props),
  (state: DataState, props: Props) =>
    getHoldingValue(state, {
      workspaceId: props.workspaceId,
      holding: props.holding,
      date: props.startDate,
      currency: props.currency,
      useLiveValue: props.useLiveValue,
    }),
  (state: DataState, props: Props) =>
    getHoldingValue(state, {
      workspaceId: props.workspaceId,
      holding: props.holding,
      date: props.endDate,
      currency: props.currency,
      useLiveValue: props.useLiveValue,
    }),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.startDate,
  (_state: DataState, props: Props) => props.endDate,
  (cfs, startValuation, endValuation, date, startDate, endDate): CashFlow[] => {
    const startCashFlow = {
      value: startValuation ? -startValuation.value : 0,
      date: startDate.toDate(),
    };
    const asCashFlows = compact(
      cfs.map((cf) => {
        if (date != null && cf.value != null) {
          return {
            date: cf.date,
            value: cf.value,
          };
        }
      }),
    );
    const endCashFlow = {
      value: endValuation ? endValuation.value : 0,
      date: endDate.toDate(),
    };
    return [startCashFlow, ...asCashFlows, endCashFlow];
  },
)({
  keySelector: (_state: DataState, props: Props) =>
    `${props.holding.id}:${
      props.currency.id
    }:${props.date.unix()}:${props.startDate.unix()}:${props.endDate.unix()}:${
      props.useLiveValue
    }`,
  selectorCreator: createSelector,
});
