import {
  fetchValuations,
  handleDeleteValuations,
} from '~src/data/store/modules/holdings/valuation/valuations/requests';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { apis } from '~src/services/request/apis';

import { Operation, valuationPath } from '@pladdenico/portfolio-api';

export function subscribeToValuations(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  valuations: ValuationState[],
  subscriptions: Operation[],
) {
  valuations.forEach((valuation) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: valuationPath(tenantId, workspaceId).objectPath(
              valuation.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchValuations(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to valuation', operation, valuation.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: valuationPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchValuations(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to valuation', operation, valuation.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: valuationPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteValuations([
                  { id: res.id, holdingId: valuation.holdingId },
                ]),
              );
            },
          },
          () => {
            // console.log('subscribed to valuation', operation, valuation.id);
          },
        );
      }
    });
  });
}
