import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { AssetCategoryState } from '~src/data/store/reducers/asset/categories/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FieldsComponent } from '~src/hooks/fields/workspace/asset/category/fields.component';
import { useCreateAssetCategory } from '~src/hooks/fields/workspace/asset/category/use-create';

// import { FieldsComponent } from '~src/domain/workspace/components/category/category/forms/create/fields.component';
// import { useCreateCategory } from '~src/hooks/fields/workspace/category/use-create-category';
import { Grid } from '@mui/material';

interface Props {
  handleValue: (t: AssetCategoryState) => void;
  workspace: WorkspaceState;
}

export const useEditDialog = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const { categoryInput, setCategoryInput, handleCreateCategory } =
    useCreateAssetCategory(props.workspace);

  const { handleValue } = props;

  const handleOnSave = React.useCallback(() => {
    return handleCreateCategory().then((category) => {
      handleValue(category);
      setOpen(false);
    });
  }, [handleCreateCategory, handleValue]);

  const dialogElement = React.useMemo(() => {
    return (
      <DialogComponent
        dialogProps={{ open, onClose: () => setOpen(false), fullWidth: true }}
      >
        <DialogContentComponent
          handleCancel={() => setOpen(false)}
          handleOnSave={handleOnSave}
          title="Create category"
        >
          <Grid container justifyContent="space-between">
            <FieldsComponent
              categoryInput={categoryInput}
              setCategoryInput={setCategoryInput}
              workspace={props.workspace}
            />
          </Grid>
        </DialogContentComponent>
        {/* {getDialogContent(
          categoryInput,
          setCategoryInput,
          handleCreateCategory,
          callback,
          setOpen
        )} */}
      </DialogComponent>
    );
  }, [open, handleOnSave, categoryInput, setCategoryInput, props.workspace]);

  return { dialogElement, setOpen, setCategoryInput };
};
