import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { SearchInput } from '~src/components/search/search-input';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
// import { CommitmentBaseData } from './form/fields/commitment-data-types';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateCommitmentComponent } from '~src/domain/workspace/components/project/commitment/create.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import Grid from '@mui/material/Unstable_Grid2';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import { TableDrawers, TableOperations } from '@pladdenico/table';

import { CommitmentData } from './commitment-data';

// import { CreateCommitmentComponent } from './create.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding?: HoldingState;

  algoData: TableOperations.AlgorithmData<CommitmentData>;
}

export const CommitmentsToolbar = (props: Props) => {
  const { workspace, projectId, holding, algoData } = props;

  const { classes } = useStyles();
  const createDialogVisible = useVisible();
  const menuVisible = useVisible();
  const filterVisible = useVisible();
  const sortVisible = useVisible();

  const menuAnchorEl = React.useRef(null);
  function handleMenuOpen(_event: React.MouseEvent<HTMLElement>) {
    menuVisible.setIsShowing(true);
  }

  function handleMenuClose() {
    menuVisible.setIsShowing(false);
  }
  const handleAddCommitment = () => {
    createDialogVisible.setIsShowing(true);
    handleMenuClose();
  };

  function handleCloseCreateCommitmentDialog() {
    createDialogVisible.setIsShowing(false);
  }

  const handleFilterDrawerOpen = () => {
    filterVisible.setIsShowing(true);
    sortVisible.setIsShowing(false);
  };

  const handleSortDrawerOpen = () => {
    filterVisible.setIsShowing(false);
    sortVisible.setIsShowing(true);
  };

  const menu = (
    <Menu
      anchorEl={menuAnchorEl.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={menuVisible.isShowing}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleAddCommitment}>Add commitment</MenuItem>
    </Menu>
  );

  const filtersComponent = algoData.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={algoData.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });

  const sortersComponent = algoData.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={algoData.dispatchSorters}
        sorter={sorter}
      />
    );
  });
  return (
    <>
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search commitment"
          />
        </Grid>
        <Grid xsOffset="auto">
          {/* <IconButton onClick={handlePropertiesDrawerOpen}>
          <ViewListIcon />
        </IconButton> */}
          <span className={classes.spacer} />
          <IconButton onClick={handleFilterDrawerOpen}>
            <FilterListIcon />
          </IconButton>
          <IconButton onClick={handleSortDrawerOpen}>
            <SortIcon />
          </IconButton>
          <IconButton
            ref={menuAnchorEl}
            onClick={handleMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      <CreateCommitmentComponent
        workspace={workspace}
        projectId={projectId}
        holding={holding}
        open={createDialogVisible.isShowing}
        handleClose={handleCloseCreateCommitmentDialog}
        // commitmentCreateInputInit={props.commitmentCreateInputInit}
      />
      {menu}
      <TableDrawers.FilterDrawerComponent
        open={filterVisible.isShowing}
        closeDrawer={() => filterVisible.setIsShowing(false)}
        filtersComponent={
          <Box>
            <Typography variant="overline">Commitment</Typography>
            {filtersComponent}
          </Box>
        }
      />
      <TableDrawers.SorterDrawerComponent
        open={sortVisible.isShowing}
        closeDrawer={() => sortVisible.setIsShowing(false)}
        sortersComponent={<>{sortersComponent}</>}
      />
    </>
  );
};
