import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import { workspaceBaseId, workspaceRootPath } from '../paths';

export const workspaceEntitiesRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/entities`;
};
export const workspaceEntityRootPath = (
  tenantId: string,
  workspaceId: string,
  entityId: string,
) => {
  return `${workspaceEntitiesRootPath(tenantId, workspaceId)}/${entityId}`;
};

export const workspaceEntitiesBasePath = workspaceEntitiesRootPath(
  tenantBaseId,
  workspaceBaseId,
);
