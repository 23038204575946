// import { createSelector } from 'reselect';
import { UserDataState } from '~src/data/store/reducers/reducers';

// export const getAccount = createSelector(
//   (state: UserDataState) => state.account,
//   (account) => {
//     return account;
//   },
// );

export const getAccount = (state: UserDataState) => state.account;
