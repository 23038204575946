import { handleStocks } from '~src/data/store/modules/assets/stocks/handler';
import { stocksActions } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchStocks(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetStocksDocument;
    const variables: graphqlWorkspaceTypes.GetStocksQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getStocks) {
        return handleStocks(workspaceId, dispatch, data.getStocks);
      }
      return [];
    });
  };
}

export function createStocks(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateStockInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateStocksDocument;
    const variables: graphqlWorkspaceTypes.CreateStocksMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createStocks) {
        return handleStocks(workspaceId, dispatch, data.createStocks);
      }
      return [];
    });
  };
}

export function updateStocks(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateStockInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateStocksDocument;
    const variables: graphqlWorkspaceTypes.UpdateStocksMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateStocks) {
        return handleStocks(workspaceId, dispatch, data.updateStocks);
      }
      return [];
    });
  };
}

export function upsertStocks(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertStockInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertStocksDocument;
    const variables: graphqlWorkspaceTypes.UpsertStocksMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertStocks) {
        return handleStocks(workspaceId, dispatch, data.upsertStocks);
      }
      return [];
    });
  };
}

export function deleteStocks(
  tenantId: string,
  workspaceId: string,
  stocks: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteStocksDocument;
    stocks.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteStockInputType[] = stocks.map(
      (stock) => {
        return {
          id: stock.id,
        };
      },
    );
    const variables: graphqlWorkspaceTypes.DeleteStocksMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteStocks) {
        const deletedStocks = intersectionSorted(
          stocks,
          data.deleteStocks,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(stocksActions.removeManyElements(deletedStocks));
      }
      return [];
    });
  };
}
