import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/asset-create-input.data';
import { AssetForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';
import { useCreateFundForm } from '~src/domain/workspace/components/asset/types/funds/form/use-fund-create-form';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  // assetInputData: AssetCreateInputData;
  assetForm: AssetForm;
  onFormCallbacks: {
    onSuccess: () => void;
    onFailure: (_error: any) => void;
  };
}
const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const useCreateForm = (props: Props) => {
  const { classes } = useStyles();
  const { workspace, assetForm, onFormCallbacks } = props;

  // const updateStateData = useUpdateState();
  // const onSuccess = React.useCallback(() => {
  //   updateStateData.setUpdateState(UpdateState.success);
  // }, [updateStateData]);
  // const onFailure = React.useCallback(
  //   (_error: any) => {
  //     updateStateData.setUpdateState(UpdateState.failed);
  //   },
  //   [updateStateData],
  // );

  // const assetForm = useAssetCreateForm({
  //   workspace,
  //   callbacks: { onSuccess, onFailure },
  //   inputData: {
  //     ...assetInputData,
  //   },
  // });

  const fundForm = useCreateFundForm({
    workspace,
    callbacks: onFormCallbacks,
    inputData: undefined,
  });

  const submit = React.useCallback(
    (successCallback: (fund: FundState) => Promise<void>) => {
      return assetForm.submit((asset) =>
        fundForm.submit(asset.id, successCallback),
      );
    },
    [assetForm, fundForm],
  );

  const form = React.useMemo(
    () => (
      <form className={classes.container} noValidate autoComplete="off">
        <Grid2 container xs={12} columnSpacing={1}>
          {/* <Grid2 xs={12}>
            <Typography variant="overline">Asset</Typography>
          </Grid2> */}
          <Grid2 xs={6}>
            {assetForm.fields.nameField}
            {assetForm.fields.typeField}
            {fundForm.fields.paperField}
            {assetForm.fields.typesField}
          </Grid2>
          <Grid2 xs={6}>
            {assetForm.fields.categoryField}
            {assetForm.fields.isinField}
            {assetForm.fields.lookupField}
            {assetForm.fields.vpsField}
          </Grid2>
          <Grid2 xs={12}>
            {assetForm.fields.descriptionField}
            {assetForm.fields.entityField}
          </Grid2>
        </Grid2>
      </form>
    ),
    [
      assetForm.fields.categoryField,
      assetForm.fields.descriptionField,
      assetForm.fields.entityField,
      assetForm.fields.isinField,
      assetForm.fields.lookupField,
      assetForm.fields.nameField,
      assetForm.fields.typeField,
      assetForm.fields.typesField,
      assetForm.fields.vpsField,
      classes.container,
      fundForm.fields.paperField,
    ],
  );
  return {
    form,
    submit,
  };
};
