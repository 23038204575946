// import { graphql } from '~src/services/graphql/workspace/client';
import { graphql } from '~src/services/graphql/workspace/client';

export const getBondTradesQuery = graphql(`
  query getBondTrades($ids: [String!]) {
    getBondTrades(ids: $ids) {
      ...BondTrade
    }
  }
`);
// # ${bondTradeFragment}

export const createBondTradesQuery = graphql(`
  mutation createBondTrades($inputs: [CreateBondTradeInputType]) {
    createBondTrades(inputs: $inputs) {
      ...BondTrade
    }
  }
`);

export const updateBondTradesQuery = graphql(`
  mutation updateBondTrades($inputs: [UpdateBondTradeInputType]) {
    updateBondTrades(inputs: $inputs) {
      ...BondTrade
    }
  }
`);

export const upsertBondTradesQuery = graphql(`
  mutation upsertBondTrades($inputs: [UpsertBondTradeInputType]) {
    upsertBondTrades(inputs: $inputs) {
      ...BondTrade
    }
  }
`);

export const deleteBondTradesQuery = graphql(`
  mutation deleteBondTrades($inputs: [DeleteBondTradeInputType]) {
    deleteBondTrades(inputs: $inputs)
  }
`);
