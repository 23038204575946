import moment from 'moment';
import numbro from 'numbro';
import * as React from 'react';
// import { useSelector } from 'react-redux';
import { StockState } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
// import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { GoalRuleComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/goal-rule.component';
import { GoalRulesMenuComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/goal-rules-menu.component';
import { GoalRuleData } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/goal-rules.component';
import { store } from '~src/store/store';

import { Typography } from '@mui/material';
import { TableField } from '@pladdenico/table';

function formatValue(value: number) {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0,
    optionalMantissa: true,
  });
}

function formatShareValue(value: number) {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 2,
    optionalMantissa: true,
    output: 'percent',
  });
}

function maxQuote(quotes: QuoteState[]): QuoteState | undefined {
  const max = quotes.reduce((prev, curr) => {
    return (prev.close ?? 0) < (curr.close ?? 0) ? curr : prev;
  }, quotes[0]);

  return max;
}

function newestQuote(quotes: QuoteState[]): QuoteState | undefined {
  const newest = quotes.reduce(
    (prev, curr) => (prev.date.getTime() <= curr.date.getTime() ? curr : prev),
    quotes[0],
  );
  return newest;
}

// const useStyles = makeStyles((theme: Theme) => ({
//   marked: {
//     background: 'red',
//     color: colors.common.white,
//     paddingBottom: theme.spacing(2),
//     paddingTop: theme.spacing(2),
//   },
//   markedText: {
//     marginLeft: theme.spacing(1),
//   },
// }));

export function useGoalRuleFields(
  workspace: WorkspaceState,
  projectId: string,
  totalValue: number,
) {
  // const classes = useStyles();
  // const currencies = useSelector((state: RootState) =>
  //   getCurrencies(selectFinancialData(state))
  // );
  // const elements = <>{goalRuleMenu}</>;

  const fields: TableField.Field<GoalRuleData>[] = [
    // {
    //   id: 'date',
    //   name: 'date',
    //   field: 'date',
    //   type: TableField.FieldTypes.date,
    //   iteratee: (data) => data.valuation.date,
    //   update: (data, date: Date) => {
    //     const valuation = { ...data.valuation, date };
    //     return {
    //       ...data,
    //       valuation,
    //     };
    //   },
    //   renderEdit: (editProps) => {
    //     return (
    //       <EditDateField
    //         handleValue={editProps.onChange}
    //         date={editProps.data.valuation.date}
    //         labelled={false}
    //       />
    //     );
    //   },
    //   style: {
    //     minWidth: 140,
    //   },
    // },
    {
      id: 'menu',
      name: 'menu',
      field: 'menu',
      type: TableField.FieldTypes.string,
      iteratee: (_data) => {
        // if (data.asset.__typename === 'Stock') {
        //   const goalRules = data.asset.goalRules;
        //   const goalRulesString = goalRules.join('.');
        //   return goalRulesString;
        // }
        return '';
      },
      renderView: (viewProps) => {
        let t;
        if (viewProps.data.asset.__typename === 'Stock') {
          t = (
            <GoalRulesMenuComponent
              projectId={projectId}
              stockHoldingId={viewProps.data.asset.stockHolding?.id ?? ''}
              workspace={workspace}
            />
          );
        }
        return <>{t}</>;
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'holding',
      name: 'holding',
      field: 'holding',
      type: TableField.FieldTypes.string,
      iteratee: (data) => {
        const holdingName = data.holding.name;
        if (data.asset.__typename === 'Stock') {
          const asset = data.asset as StockState;
          return `${holdingName} ${asset.paperId}`;
        }
        return holdingName ?? '';
      },
      renderView: (viewProps) => {
        const holdingName = viewProps.data.holding.name;
        let t;
        if (viewProps.data.asset.__typename === 'Stock') {
          const asset = viewProps.data.asset;
          // TODO: use paper, not only the id.
          t = `${asset.paperId}`;
        }
        return (
          <>
            <Typography variant="body1">
              {holdingName}: {t}
            </Typography>
          </>
        );
        // return (
        //   <ViewHoldingField
        //     projectId={projectId}
        //     holdingId={editProps.data.holding.id}
        //   />
        // );
      },
    },
    {
      id: 'value',
      name: 'value',
      field: 'value',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.valuation.value,
      renderView: (viewProps) => {
        let baseValueElement;
        if (
          viewProps.data.valuation.baseCurrencyId !=
          viewProps.data.valuation.currencyId
        ) {
          const baseCurrency = getCurrencyById(
            selectFinancialData(store.getState()),
            {
              id: viewProps.data.valuation.baseCurrencyId,
            },
          );
          baseValueElement = (
            <Typography variant="body2">
              {formatValue(viewProps.data.valuation.baseValue)} (
              {baseCurrency?.symbol})
            </Typography>
          );
        }
        const currency = getCurrencyById(
          selectFinancialData(store.getState()),
          {
            id: viewProps.data.valuation.currencyId,
          },
        );

        return (
          <>
            <Typography variant="body1">
              {formatValue(viewProps.data.valuation.value)} ({currency?.symbol})
            </Typography>
            {baseValueElement}
          </>
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'share',
      name: 'share',
      field: 'share',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.valuation.value / totalValue,
      renderView: (viewProps) => {
        return (
          <>
            <Typography variant="body1">
              {formatShareValue(viewProps.data.valuation.value / totalValue)}
            </Typography>
          </>
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'goalRule',
      name: 'goalRule',
      field: 'goalRule',
      type: TableField.FieldTypes.string,
      iteratee: (data) => {
        if (data.asset.__typename === 'Stock') {
          const goalRules = data.asset.goalRules;
          const goalRulesString = goalRules.join('.');
          return goalRulesString;
        }
      },
      renderView: (viewProps) => {
        let t;
        if (viewProps.data.asset.__typename === 'Stock') {
          const goalRules = viewProps.data.asset.goalRules;
          const quotes = viewProps.data.asset.quotes;
          t = goalRules.map((goalRule, index) => {
            return (
              <GoalRuleComponent
                key={index}
                goalRule={goalRule}
                workspace={workspace}
                projectId={projectId}
                stockHoldingId={goalRule.stockHoldingId}
                quotes={quotes}
              />
            );
          });
        }
        return <>{t}</>;
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'highest',
      name: 'highest',
      field: 'highest',
      type: TableField.FieldTypes.string,
      iteratee: (data) => {
        if (data.asset.__typename === 'Stock') {
          const quotes = data.asset.quotes;
          const max = maxQuote(quotes);
          if (max) {
            return max.date.getTime();
          }
          return '';
        }
      },
      renderView: (viewProps) => {
        let t;
        if (viewProps.data.asset.__typename === 'Stock') {
          const quotes = viewProps.data.asset.quotes;
          const max = maxQuote(quotes);
          if (max) {
            t = `${moment(max.date).format('DD/MM/YYYY')} ${max.value}`;
          }
        }
        return (
          <>
            <Typography variant="body1">{t}</Typography>
          </>
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'newest',
      name: 'newest',
      field: 'newest',
      type: TableField.FieldTypes.string,
      iteratee: (data) => {
        if (data.asset.__typename === 'Stock') {
          const quotes = data.asset.quotes;
          const newest = newestQuote(quotes);
          if (newest) {
            return newest.date.getTime();
          }
          return '';
        }
      },
      renderView: (viewProps) => {
        let newestElement;
        let highestElement;
        if (viewProps.data.asset.__typename === 'Stock') {
          const quotes = viewProps.data.asset.quotes;
          const newest = newestQuote(quotes);
          if (newest) {
            newestElement = (
              <Typography variant="body1">
                {moment(newest.date).format('DD/MM/YYYY')} {newest.value}
              </Typography>
            );
          }
          const highest = maxQuote(quotes);
          if (newest?.value && highest?.value) {
            highestElement = (
              <Typography variant="caption">
                Ratio to highest{' '}
                {formatShareValue(newest.value / highest.value)}
              </Typography>
            );
          }
        }
        return (
          <>
            {newestElement}
            {highestElement}
          </>
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'position',
      name: 'position',
      field: 'position',
      type: TableField.FieldTypes.string,
      iteratee: (data) => {
        if (data.asset.__typename === 'Stock') {
          const positions = data.asset.positions;
          const max = positions.reduce(
            (prev, curr) =>
              prev.date.getTime() < curr.date.getTime() ? curr : prev,
            positions[0],
          );
          if (max) {
            return max.date.getTime();
          }
          return '';
        }
      },
      renderView: (viewProps) => {
        let t;
        if (viewProps.data.asset.__typename === 'Stock') {
          const positions = viewProps.data.asset.positions;
          const max = positions.reduce(
            (prev, curr) =>
              prev.date.getTime() <= curr.date.getTime() ? curr : prev,
            positions[0],
          );
          if (max) {
            t = `${moment(max.date).format('DD/MM/YYYY')} ${max.shares}`;
          }
        }
        return (
          <>
            <Typography variant="body1">{t}</Typography>
          </>
        );
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return {
    fields,
  };
}
