import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectType } from '@pladdenico/models';
import { PrivateEquityFundPortalComponent } from './private-equity-fund/home.component';
import { fetchProjects } from '~src/data/store/modules/workspaces/projects/base/requests';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { handleProjects } from '~src/data/store/modules/workspaces/projects/base/handler';
import { AppDispatch } from '~src/store/store';
// import { selectUserData } from '~src/data/store/selectors/selectors';

export const PortalComponent = React.memo(() => {
  const { tenantId, workspaceId, projectId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
  }>();
  const project = useSelector((state: RootState) => {
    if (projectId != null && workspaceId != null) {
      return getProjectById(selectWorkspaceData(state), {
        id: projectId,
        workspaceId,
      });
    }
  });
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (project != null && tenantId != null) {
      dispatch(
        fetchProjects(
          tenantId,
          project.workspaceId,
          [project.id],
          handleProjects,
        ),
      );
    }
  }, [dispatch, project, tenantId, workspaceId]);

  if (
    project &&
    tenantId != null &&
    project.type === ProjectType.PrivateEquityFund
  ) {
    return (
      <PrivateEquityFundPortalComponent
        tenantId={tenantId}
        workspaceId={project.workspaceId}
        project={project}
      />
    );
  }
  return <ProgressComponent />;
});
