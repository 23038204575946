export const PeriodTypes = {
  Year: 'year',
  Quarter: 'quarter',
  Month: 'month',
} as const;

export type PeriodType = (typeof PeriodTypes)[keyof typeof PeriodTypes];
export function typeToString(type: PeriodType) {
  if (type === PeriodTypes.Year) {
    return 'Year';
  } else if (type === PeriodTypes.Quarter) {
    return 'Quarter';
  } else if (type === PeriodTypes.Month) {
    return 'Month';
  }
  return 'undefined';
}
