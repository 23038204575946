import React from 'react';
import * as uuid from 'uuid';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHolding } from '~src/domain/workspace/components/project/portfolio/holdings/use-holding';
import { useHoldingActions } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';

import { HoldingType } from '@pladdenico/models';

import { CreateHoldingDialogComponent } from '../../holding/form/create-dialog.component';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { usePortfolioHoldings } from '~src/hooks/actions/workspace/project/portfolio/holding/use-portfolio-holdings';
import { useCreateTypeHolding } from '~src/domain/workspace/components/project/portfolio/holdings/use-create-type-holding';
import { CreateFormComponent } from '~src/domain/workspace/components/project/holding/form/create-form.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  open: boolean;
  handleClose: () => void;
}
const initialPortfolios: PortfolioState[] = [];

export const CreateHoldingComponent = React.memo((props: Props) => {
  const { workspace, project, open, handleClose } = props;
  const { onCreate } = useHoldingActions(workspace);
  const { portfolios, setPortfolios, updatePortfolios } = usePortfolioHoldings(
    workspace,
    initialPortfolios,
  );

  const [holdingInput, setHoldingInput] =
    React.useState<graphqlWorkspaceTypes.CreateHoldingInputType>({
      id: uuid.v1(),
      projectId: project.id,
      name: '',
      description: '',
      type: HoldingType.Stock,
    });

  const { holdingDataInput, setHoldingDataInput } = useHolding(holdingInput);

  const { action, fields, valid } = useCreateTypeHolding(
    workspace,
    holdingInput.type as HoldingType,
    holdingDataInput,
    setHoldingDataInput,
  );

  const createHolding = React.useCallback(() => {
    return onCreate(holdingInput, action, handleClose).then((holding) => {
      return updatePortfolios(holding);
    });
  }, [action, handleClose, holdingInput, onCreate, updatePortfolios]);

  const content = React.useMemo(() => {
    return (
      <CreateFormComponent
        holdingInput={holdingInput}
        setPortfolios={setPortfolios}
        portfolios={portfolios}
        projectId={project.id}
        holdingTypeFieldsComponent={fields}
        setHoldingInput={setHoldingInput}
        workspace={workspace}
      />
    );
  }, [fields, holdingInput, portfolios, project.id, setPortfolios, workspace]);

  const handleCreateHolding = React.useCallback(() => {
    return createHolding().then(() =>
      setHoldingInput({
        ...holdingInput,
        id: uuid.v1(),
      }),
    );
  }, [createHolding, holdingInput]);

  return (
    <CreateHoldingDialogComponent
      open={open}
      handleClose={handleClose}
      handleCreateHolding={handleCreateHolding}
      valid={valid}
    >
      {content}
    </CreateHoldingDialogComponent>
  );
});
