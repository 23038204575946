export enum RelationType {
  single,
  multiple,
}

export enum SingleRelationOperationType {
  set,
  unset,
}

export enum MultipleRelationOperationType {
  add,
  remove,
}

export interface SingleRelationOperation {
  id: number | string;
  type: SingleRelationOperationType;
}

export interface MultipleRelationOperation {
  id: number | string;
  type: MultipleRelationOperationType;
}

export type IRelationOperations =
  | SingleRelationOperation
  | MultipleRelationOperation[];

export interface BaseRelation {
  type: RelationType;
  property: string;
}

export interface Relation extends BaseRelation {
  singleOperation?: SingleRelationOperation;
  multipleOperations?: MultipleRelationOperation[];
}

export interface SingleRelation extends BaseRelation {
  operation: SingleRelationOperation;
}

export interface MultipleRelation extends BaseRelation {
  operations: MultipleRelationOperation[];
}
