import { Owner } from '@pladdenico/models';
import { combineReducers } from 'redux';
import {
  ownerLinkInitialState,
  ownerLinkReducer,
} from '~src/data/store/modules/owners/links/reducer';

import { baseReducer } from '../../../base/old/base-array-reducer';
import {
  DELETED_OWNER,
  OWNER,
  OWNER_SELECTED,
  OWNER_UNSELECTED,
  OWNERS,
  OWNERS_SELECTED,
  OWNERS_UNSELECTED,
} from './action';

const ownersInitialState: Owner[] = [];
const ownersReducer = baseReducer<Owner>(
  { single: OWNER, multiple: OWNERS, singleDeleted: DELETED_OWNER },
  ownersInitialState,
);

const initialOwnersSelectedState: Owner[] = [];
const selectedOwnersReducer = baseReducer<Owner>(
  {
    single: OWNER_SELECTED,
    multiple: OWNERS_SELECTED,
    singleDeleted: OWNER_UNSELECTED,
    allDeleted: OWNERS_UNSELECTED,
  },
  initialOwnersSelectedState,
);

// const ownersInitialState: Owner[] = [];
// function ownersReducer(state: Owner[] = ownersInitialState, action: OwnerAction): Owner[] {
//   switch (action.type) {
//     case OWNER: {
//       if (action.payload && action.payload.owner) {
//         return __OLDinsertSorted<Owner, number | undefined>(state, action.payload.owner, (o) => o.id);
//       }
//     }
//     case OWNERS: {
//       if (action.payload && action.payload.owners) {
//         return __OLDmergeByIdentityKeepRight<Owner>(state, action.payload.owners);
//       }
//     }
//     case DELETED_OWNER: {
//       if (action.payload && action.payload.ownerId) {
//         return __OLDremoveSortedByIdentity(state, action.payload.ownerId);
//       }
//     }
//     default:
//       return state;
//   }
// }

// const initialOwnersSelectedState: Owner[] = [];
// function selectedOwnersReducer(
//   state: Owner[] = initialOwnersSelectedState,
//   action: OwnerAction): Owner[] {
//   switch (action.type) {
//     case OWNER_SELECTED: {
//       if (action.payload && action.payload.owner) {
//         return __OLDinsertSorted<Owner, number | undefined>(state, action.payload.owner, (o) => o.id);
//       }
//       // const ownerIdx: number = state.findIndex((t) => {
//       //   return t.id === action.payload.ownerId;
//       // });
//       // if (ownerIdx === -1) {
//       //   return [...state,
//       //     action.payload.owner];
//       // } else {
//       //   return [...state.slice(0, ownerIdx),
//       //     action.payload.owner,
//       //     ...state.slice(ownerIdx + 1)];
//       // }
//     }
//     case OWNERS_SELECTED: {
//       if (action.payload && action.payload.owners) {
//         return __OLDmergeByIdentityKeepRight<Owner>(state, action.payload.owners);
//       }
//       // return action.payload.owners;
//     }
//     case OWNERS_UNSELECTED: {
//       return [];
//     }
//     case OWNER_UNSELECTED: {
//       if (action.payload && action.payload.ownerId) {
//         return __OLDremoveSortedByIdentity(state, action.payload.ownerId);
//       }
//       // const ownerIdx: number = state.findIndex((t) => {
//       //   return t.id === action.payload.ownerId;
//       // });
//       // if (ownerIdx !== -1) {
//       //   return [...state.slice(0, ownerIdx),
//       //     ...state.slice(ownerIdx + 1)];
//       // }
//       // return state;
//     }
//     default:
//       return state;
//   }
// }

export const ownerInitialState = {
  owners: ownersInitialState,
  link: ownerLinkInitialState,
  selectedOwners: initialOwnersSelectedState,
};

export const ownerReducer = combineReducers({
  owners: ownersReducer,
  link: ownerLinkReducer,
  selectedOwners: selectedOwnersReducer,
});
