import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseHoldingConfigId {
  holdingId: string;
  id: number;
}
// export type HoldingConfigState = HoldingConfig;

export type HoldingConfigState = Omit<
  graphqlWorkspaceTypes.HoldingConfig,
  'holdingDashboardConfigs'
> & {
  holdingId: string;
  holdingDashboardConfigIds: string[];
};

export const initialHoldingConfigState = [];

const selectKeyId = (holdingConfig: BaseHoldingConfigId) =>
  holdingConfig.holdingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseHoldingConfigId, b: BaseHoldingConfigId) =>
  a.id - b.id;

const adapter = new EntityRecordAdapter<
  BaseHoldingConfigId,
  HoldingConfigState,
  string
>(initialHoldingConfigState, selectKeyId, keyComparator, tComparator);

export const holdingConfigsSlice = createBaseRecordSlice(
  'holdingConfigs',
  adapter,
);
export const selectors = adapter.selectors;

export const holdingConfigsReducer = holdingConfigsSlice.reducer;
export const holdingConfigsActions = holdingConfigsSlice.actions;
