import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import {
  TransferFieldConfig,
  TransferFieldDataConfig,
} from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { HoldingType } from '@pladdenico/models';

export const getDividendTransferFieldConfig = (
  holding: HoldingState,
  editAll: boolean,
): TransferFieldConfig => {
  let valueDisabled = true;
  let transferData: TransferFieldDataConfig | undefined;
  if (holding.type === HoldingType.Stock) {
    transferData = {
      stock: {
        sharePrice: { disabled: false, visible: true },
        tradeShares: { disabled: false, visible: true },
        position: { disabled: !editAll, visible: true },
      },
    };
  } else if (holding.type === HoldingType.Bond) {
    transferData = {
      bond: {
        sharePrice: { disabled: false, visible: true },
        tradeShares: { disabled: false, visible: true },
        position: { disabled: !editAll, visible: true },
      },
    };
  } else if (holding.type === HoldingType.Fund) {
    transferData = {
      fund: {
        sharePrice: { disabled: false, visible: true },
        tradeShares: { disabled: false, visible: true },
        position: { disabled: !editAll, visible: true },
      },
    };
  } else {
    valueDisabled = false;
  }
  return {
    base: {
      type: {
        visible: true,
        disabled: true,
      },
      cost: {
        visible: true,
        disabled: !editAll,
      },
      currency: {
        visible: true,
        disabled: !editAll,
      },
      holding: {
        visible: true,
        disabled: !editAll,
      },
      date: {
        visible: true,
        disabled: !editAll,
      },
      value: {
        visible: true,
        disabled: !editAll && valueDisabled,
      },
      commitment: {
        visible: true,
        disabled: false,
      },
    },
    data: transferData,
  };
};
