import { Operation } from '@pladdenico/portfolio-api';
import {
  PrivateEquityFundProjectStateVisitor,
  PrivateEquityFundProjectVisitable,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-project-visitor';
import { PrivateEquityFundProject } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handlePrivateEquityFundProjects(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  privateEquityFundProjects: PrivateEquityFundProject[],
  subscriptions: Operation[],
) {
  const visitor = new PrivateEquityFundProjectStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  privateEquityFundProjects.forEach((privateEquityFundProject) => {
    const visitable = new PrivateEquityFundProjectVisitable(
      workspaceId,
      privateEquityFundProject,
    );
    visitable.accept(visitor);
  });
  visitor.post();
  return privateEquityFundProjects;
}
