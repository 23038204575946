import React from 'react';
import { useSelector } from 'react-redux';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardElementsByIds } from '~src/data/store/selectors/common/dashboard/element/selectors';
import {
  selectViewData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useDashboard } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/use-dashboard';

import { Box } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  projectReport: ProjectReportState;
}

export const ProjectReportViewerComponent = React.memo((props: Props) => {
  const { portfolioProject, project, portfolio, workspace, projectReport } =
    props;

  const layouts = React.useMemo(() => {
    return projectReport.layouts;
  }, [projectReport.layouts]);

  const dashboardElements = useSelector((state: RootState) => {
    return getDashboardElementsByIds(
      selectViewData(state),
      projectReport.elementIds,
    );
  });
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'view' }),
  );
  const { menu, components } = useDashboard(
    workspace,
    project,
    portfolioProject,
    portfolio,
    config,
    layouts,
    undefined,
    dashboardElements,
  );

  return (
    <Box>
      {menu}
      {components}
    </Box>
  );
});
