import * as uuid from 'uuid';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
import { getHoldingTypes } from '~src/utils/finance/transfer-to-holding-types';

import { TransferType } from '@pladdenico/models';

// import { HoldingConfigListener } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-input-data';

export const getHoldingConfigLend = (
  holdingId?: string,
  // listeners?: HoldingConfigListener[]
): HoldingConfig => {
  const transferType = TransferType.Lend;
  return {
    id: uuid.v1(),
    transferType,
    component: {
      name: 'Lend holding',
      deleteIcon: false,
      useValuation: true,
      // editAll: false,
      // fieldConfig: {
      //   transfer: {
      //     cost: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     currency: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     date: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     value: {
      //       visible: true,
      //       disabled: true,
      //     },
      //   },
      //   valuation: {
      //     currency: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     date: {
      //       visible: true,
      //       disabled: true,
      //     },
      //     value: {
      //       visible: true,
      //       disabled: true,
      //     },
      //   },
      // },
      possibleHoldingTypes: getHoldingTypes(transferType),
      possibleTransferTypes: [transferType],
    },
    holdingId,
    // listeners,
  };
};
