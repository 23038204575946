import {
  RegionAllocationStateVisitor,
  RegionAllocationVisitable,
} from '~src/data/store/visitors/workspace/entity/region-allocation-visitor';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function handleRegionAllocations(
  dispatch: any,
  outputs: graphqlWorkspaceTypes.RegionAllocation[],
) {
  const regionAllocationVisitor = new RegionAllocationStateVisitor(dispatch);
  outputs.forEach((regionAllocation) => {
    const visitable = new RegionAllocationVisitable(
      regionAllocation.entity?.id ? regionAllocation.entity?.id : '',
      regionAllocation,
    );
    visitable.accept(regionAllocationVisitor);
  });
  regionAllocationVisitor.post();
  return outputs;
}
