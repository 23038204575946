import { portfoliosActions } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { selectedPortfoliosActions } from '~src/data/store/reducers/portfolio/selected/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handlePortfolios } from './handler';

export function fetchPortfolios(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPortfoliosDocument;
    const variables: graphqlWorkspaceTypes.GetPortfoliosQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPortfolios) {
        return handlePortfolios(
          dispatch,
          tenantId,
          workspaceId,
          data.getPortfolios,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createPortfolios(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreatePortfolioInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreatePortfoliosDocument;
    const variables: graphqlWorkspaceTypes.CreatePortfoliosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPortfolios) {
        return handlePortfolios(
          dispatch,
          tenantId,
          workspaceId,
          data.createPortfolios,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updatePortfolios(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdatePortfolioInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdatePortfoliosDocument;
    const variables: graphqlWorkspaceTypes.UpdatePortfoliosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePortfolios) {
        return handlePortfolios(
          dispatch,
          tenantId,
          workspaceId,
          data.updatePortfolios,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertPortfolios(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertPortfolioInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertPortfoliosDocument;
    const variables: graphqlWorkspaceTypes.UpsertPortfoliosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPortfolios) {
        return handlePortfolios(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertPortfolios,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deletePortfolios(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeletePortfoliosDocument;
    const variables: graphqlWorkspaceTypes.DeletePortfoliosMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePortfolios) {
        dispatch(
          portfoliosActions.removeManyElements(
            data.deletePortfolios.map((p) => {
              return {
                workspaceId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}

export function selectPortfolio(portfolioId: string) {
  return (dispatch: any) => {
    // dispatch(selectPortfolioIdAction(portfolio.id));
    dispatch(selectedPortfoliosActions.set(portfolioId));
    // dispatch(unselectPortfolioRoleAction());
    // dispatch(unselectPortfolioAction());
  };
}

export function unselectPortfolio() {
  return (dispatch: any) => {
    // dispatch(unselectPortfolioAction());
    dispatch(selectedPortfoliosActions.unset(undefined));
    // dispatch(unselectPortfolioRoleAction());
    // dispatch(unselectPortfolioAction());
  };
}
