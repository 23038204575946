import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';

import {
  BaseHoldingDashboardConfigId,
  HoldingDashboardConfigState,
} from './state';

export const initialHoldingDashboardConfigState = [];

const selectKeyId = (holdingDashboardConfig: BaseHoldingDashboardConfigId) =>
  holdingDashboardConfig.holdingConfigId;
const keyComparator = (a: number, b: number) => a - b;
const tComparator = (
  a: BaseHoldingDashboardConfigId,
  b: BaseHoldingDashboardConfigId,
) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseHoldingDashboardConfigId,
  HoldingDashboardConfigState,
  number
>(initialHoldingDashboardConfigState, selectKeyId, keyComparator, tComparator);

export const holdingDashboardConfigsSlice = createBaseRecordSlice(
  'holdingDashboardConfigs',
  adapter,
);
export const selectors = adapter.selectors;

export const holdingDashboardConfigsReducer =
  holdingDashboardConfigsSlice.reducer;
export const holdingDashboardConfigsActions =
  holdingDashboardConfigsSlice.actions;
