import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getEntityByHoldingId } from '~src/data/store/selectors/entities/entities/selectors';
import { HoldingGroup } from '~src/data/store/selectors/finance/holding-groups';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';

interface EntityByHoldingIdProps {
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
  filterKey: keyof EntityState;
}

export const getEntityHoldingProperties = createCachedSelector(
  (state: DataState, props: EntityByHoldingIdProps) =>
    getEntityByHoldingId(selectWorkspaceDataFromData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      holdingId: props.holding.id,
    }),
  (_state: DataState, props: EntityByHoldingIdProps) => props.filterKey,
  (entity, filterKey): HoldingGroup[] => {
    if (entity) {
      const entityProp = (entity as any)[filterKey];
      if (entityProp) {
        return [{ id: entityProp, name: entityProp, share: 1 }];
      }
    }
    return [];
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${props.projectId}:${props.holding.id}}`,
  selectorCreator: createSelector,
});

export const selectEntityHoldingProperties = createCachedSelector(
  (entity: EntityState, _filterKey: keyof EntityState) => entity,
  (_entity: EntityState, filterKey: keyof EntityState) => filterKey,
  (entity, filterKey): HoldingGroup[] => {
    if (entity) {
      const entityProp = (entity as any)[filterKey];
      if (entityProp) {
        return [{ id: entityProp, name: entityProp, share: 1 }];
      }
    }
    return [];
  },
)({
  keySelector: (entity, filterKey) => `${entity.id}:${filterKey}`,
  selectorCreator: createSelector,
});
