import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseCryptoHoldingId {
  holdingId: string;
  id: string;
}

export type CryptoHoldingState = Omit<
  graphqlWorkspaceTypes.CryptoHolding,
  'holding' | 'crypto'
> & {
  holdingId: string;
  cryptoId: string;
};

export const initialCryptoHoldingState = { ts: [], idxs: [] };

const selectId = (cryptoHolding: BaseCryptoHoldingId) => cryptoHolding.id;
const selectIdx = (cryptoHolding: BaseCryptoHoldingId) =>
  cryptoHolding.holdingId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: CryptoHoldingState, b: CryptoHoldingState) => b;

const adapter = new EntityIndexedAdapter<
  BaseCryptoHoldingId,
  CryptoHoldingState,
  string,
  string
>(
  initialCryptoHoldingState,
  selectId,
  selectIdx,
  merger,
  iComparator,
  idxComparator,
);

export const cryptoHoldingsSlice = createBaseIndexedSlice(
  'cryptoHoldings',
  adapter,
);
export const cryptoHoldingsSelectors = adapter.selectors;
export const cryptoHoldingsReducer = cryptoHoldingsSlice.reducer;
export const cryptoHoldingsActions = cryptoHoldingsSlice.actions;
