import React from 'react';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getStockById } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import { getPaperByStock } from '~src/data/store/selectors/finance/quotes/selectors';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { PaperComponent } from '~src/domain/workspace/components/finance/papers/paper/paper.component';
import { Config } from '~src/utils/interfaces/config';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

export const DashboardPaperStockHoldingComponent = React.memo(
  (props: Props) => {
    const { workspace, holding } = props;
    const stockHolding = useSelector((state: RootState) =>
      getStockHoldingByHoldingId(selectWorkspaceData(state), holding.id),
    );
    const stock = useSelector((state: RootState) =>
      getStockById(selectWorkspaceData(state), {
        id: stockHolding ? stockHolding.stockId : '',
        workspaceId: workspace.id,
      }),
    );
    const paper = useSelector((state: RootState) => {
      return getPaperByStock(selectFinancialData(state), stock);
    });

    return <PaperComponent paper={paper} />;
  },
);
