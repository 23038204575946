import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { CommitmentsSummaryComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/commitments/commitments-summary.component';

import { useCommitmentsMenu } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/commitments/menu.component';

import { Config } from '~src/utils/interfaces/config';

import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  config: Config;
  period: Period;
}

export const CommitmentsElement = React.memo(
  ({
    workspace,
    project,
    holdings,
    config,
    // period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useCommitmentsMenu(
      element,
      remove,
      updated,
    );

    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), {
        id: baseCurrencyId,
      }),
    );
    const elementConfig = React.useMemo(() => {
      console.log('element.config', element.config);
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? '#000000',
          backgroundColor: element.config.backgroundColor ?? '#ffffff',
          padding: element.config.padding,
        },
      };
    }, [element.config, isOpen]);

    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <CommitmentsSummaryComponent
            workspace={workspace}
            project={project}
            holdings={holdings}
            date={config.date}
            // period={period}
            currency={baseCurrency}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
