import { createSelector, weakMapMemoize } from 'reselect';
import { tenantsSelectors } from '../../../reducers/tenant/tenants/reducer';
import { TenantDataState } from '../../../reducers/reducers';

export const selectTenants = (state: TenantDataState) => {
  return state.tenant.tenants;
};

export const getTenants = createSelector(selectTenants, (tenants) => {
  return tenants;
});

export const getTenantById = createSelector(
  (state: TenantDataState) => selectTenants(state),
  (_state: TenantDataState, tenantId: string | undefined | null) => tenantId,
  (tenants, tenantId) => {
    if (tenantId) {
      return tenantsSelectors.selectElement(tenants, { id: tenantId });
    }
    return undefined;
  },
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);
