import { useSelector } from 'react-redux';
import { EditHolding } from '~src/components/fields/workspace/holding/holding/edit-holding';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { HoldingType } from '@pladdenico/models';

interface Props {
  handleValue: (t: HoldingState) => void;
  handlePersist?: () => void;
  holdingId: string | null;
  label?: string;
  workspace: WorkspaceState;
  projectId: string;
  holdingTypes?: HoldingType[];
}

export const EditHoldingById = (props: Props) => {
  const holding = useSelector((root: RootState) => {
    if (props.holdingId != null) {
      return getHoldingById(selectWorkspaceData(root), {
        id: props.holdingId,
        projectId: props.projectId,
      });
    }
  });
  return <EditHolding {...props} holding={holding} />;
};
