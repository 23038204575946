import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { createEvents } from '~src/data/store/modules/workspaces/projects/events/requests';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventInput } from '~src/domain/workspace/components/project/event/form/event-input';
import { EventFormComponent } from '~src/domain/workspace/components/project/event/form/form.component';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  open: boolean;
  handleClose: () => void;
}

const initializeEventInput = (projectId: string): EventInput => {
  return {
    projectId,
    name: '',
    date: new Date(),
    description: '',
    entityIds: [],
    holdingIds: [],
    portfolioIds: [],
    transactionIds: [],
  };
};

export const CreateEventComponent = React.memo((props: Props) => {
  const { workspace, project, open, handleClose } = props;

  const [eventInput, setEventInput] = React.useState<EventInput>(() =>
    initializeEventInput(project.id),
  );

  const dispatch = useDispatch<AppDispatch>();

  const createEvent = React.useCallback(() => {
    const holdingIds: graphqlWorkspaceOperations.MultipleRelationOperationStringInputType[] =
      eventInput.holdingIds.map((id) => {
        return {
          id,
          type: MultipleRelationOperationType.add,
        };
      });
    const entityIds: graphqlWorkspaceOperations.MultipleRelationOperationStringInputType[] =
      eventInput.entityIds.map((id) => {
        return {
          id,
          type: MultipleRelationOperationType.add,
        };
      });
    const portfolioIds: graphqlWorkspaceOperations.MultipleRelationOperationStringInputType[] =
      eventInput.portfolioIds.map((id) => {
        return {
          id,
          type: MultipleRelationOperationType.add,
        };
      });
    const transactionIds: graphqlWorkspaceOperations.MultipleRelationOperationStringInputType[] =
      eventInput.transactionIds.map((id) => {
        return {
          id,
          type: MultipleRelationOperationType.add,
        };
      });
    const event: graphqlWorkspaceOperations.CreateEventInputType = {
      id: uuid.v1(),
      ...eventInput,
      holdingIds,
      entityIds,
      portfolioIds,
      transactionIds,
    };
    return dispatch(createEvents(workspace.tenantId, workspace.id, [event]))
      .then(() => {
        handleClose();
        setEventInput(initializeEventInput(project.id));
        return Promise.resolve();
      })
      .catch((reason) => {
        console.log('Could not create event', reason);
      });
  }, [
    eventInput,
    dispatch,
    workspace.tenantId,
    workspace.id,
    handleClose,
    project.id,
  ]);

  const form = React.useMemo(() => {
    return (
      <EventFormComponent
        eventInput={eventInput}
        project={project}
        setEventInput={setEventInput}
        workspace={workspace}
      />
    );
  }, [eventInput, project, workspace]);

  return (
    <DialogComponent dialogProps={{ open, onClose: handleClose }}>
      <DialogContentComponent
        handleOnSave={createEvent}
        title="Create event"
        handleClose={handleClose}
      >
        {form}
      </DialogContentComponent>
    </DialogComponent>
  );
});
