import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { useCreateDialog } from '~src/components/fields/workspace/holding/holding/use-create-dialog';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsByProjectId } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { HoldingType } from '@pladdenico/models';

interface Props {
  handleValue: (ts: HoldingState[]) => void;
  handlePersist?: () => void;
  holdings: HoldingState[];
  label?: string;
  workspace: WorkspaceState;
  projectId: string;
}

export const EditHoldings = (props: Props) => {
  const { workspace, projectId, label, holdings, handlePersist, handleValue } =
    props;
  const allHoldings = useSelector((state: RootState) =>
    getHoldingsByProjectId(selectWorkspaceData(state), projectId),
  );
  const getOptionLabel = React.useCallback(
    (t: HoldingState) => {
      const holding = allHoldings.find((holding) => holding.id === t.id);
      return holding?.name ? holding.name : '';
    },
    [allHoldings],
  );

  const handleNewHolding = React.useCallback(
    (holding: HoldingState) => {
      handleValue([...holdings, holding]);
    },
    [handleValue, holdings],
  );

  const { dialogElement, setOpen, setHoldingInput } = useCreateDialog({
    handleValue: handleNewHolding,
    workspace: workspace,
    projectId: projectId,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setHoldingInput({
        id: uuid.v1(),
        type: HoldingType.Unknown,
        name: value,
        projectId: projectId,
        description: '',
      });
    },
    [projectId, setHoldingInput],
  );

  const isOptionEqualToValue = (option: HoldingState, value: HoldingState) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={true}
      addOption={{ initDialog, setOpen, dialogElement }}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      options={allHoldings}
      value={holdings}
      handlePersist={handlePersist}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
