import * as React from 'react';
import { useDispatch } from 'react-redux';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { forecastsActions } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import { selectedForecastsActions } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/selected/reducer';
// import { ForecastElementBase } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { EditForecastComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/edit-forecast-element.component';
// import { ForecastElementInput } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/forecast-element-input';
// import { useArrayReducer } from '~src/hooks/utils/use-array-reducer';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

export const useEditForecastElementDialogComponent = (
  forecast: ForecastState,
) => {
  const modal = useVisible();

  const [forecastInput, setForecastInput] =
    React.useState<ForecastState>(forecast);

  React.useEffect(() => {
    setForecastInput(forecast);
  }, [forecast]);

  const dispatch = useDispatch<AppDispatch>();

  const saveForecast = React.useCallback(
    (forecast: ForecastState) => {
      dispatch(forecastsActions.upsertOneElement(forecast));
      dispatch(
        selectedForecastsActions.upsertOneElement({
          budgetId: forecast.budgetId,
          objectType: forecast.objectType,
          objectId: forecast.objectId,
          id: forecast.id,
        }),
      );
    },
    [dispatch],
  );

  const dialog = (
    <DialogComponent
      dialogProps={{
        open: modal.isShowing,
        onClose: modal.close,
        fullWidth: true,
      }}
    >
      <DialogContentComponent
        title="Forecast"
        handleOnSave={() => {
          if (forecastInput) {
            saveForecast(forecastInput);
            modal.close();
          }
          return Promise.resolve();
        }}
        handleCancel={modal.close}
      >
        <EditForecastComponent
          forecast={forecastInput}
          setForecast={setForecastInput}
        />
      </DialogContentComponent>
    </DialogComponent>
  );

  return {
    modal,
    dialog,
  };
};
