import { useParams } from 'react-router-dom';
import { useWorkspaceBreadcrumbs } from '~src/domain/workspace/components/top-bar/breadcrumb/workspace-breadcrumbs';
import { workspaceSettingsRootPath } from '~src/navigation/paths/workspace/settings/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';

function useSettingsBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);
  if (tenantId != null && workspaceId != null) {
    const settingElement = last ? (
      <Typography
        color="inherit"
        key={workspaceSettingsRootPath(tenantId, workspaceId)}
      >
        settings
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceSettingsRootPath(tenantId, workspaceId)}
        to={workspaceSettingsRootPath(tenantId, workspaceId)}
      >
        settings
      </LinkRouter>
    );
    return [workspaceBreadcrumb, settingElement];
  }
  return [];
}

export function FinanceSettingsBreadcrumbComponent(props: { last: boolean }) {
  const settingsBreadcrumbs = useSettingsBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {settingsBreadcrumbs}
    </Breadcrumbs>
  );
}
