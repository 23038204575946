import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { createSchedulers } from '~src/data/store/modules/schedulers/requests';
import { createReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectUserData } from '~src/data/store/selectors/selectors';
import { getAccount } from '~src/data/store/selectors/tenant/account/selectors';
import {
  InputData,
  ReportFormComponent,
} from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/form/report-form.component';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  handleClose: () => void;
  isShowing: boolean;
  handleCancel: () => void;
}

export const CreateScheduledReportComponent = memo((props: Props) => {
  const { handleClose, isShowing, handleCancel, workspace, projectId } = props;

  const account = useSelector((state: RootState) =>
    getAccount(selectUserData(state)),
  );
  const user = account.user;

  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputData, cronValue: string) => {
      console.log('SAVING');
      return dispatch(
        createSchedulers(workspace.tenantId, workspace.id, [
          { cron: cronValue, id: uuid.v1() },
        ]),
      ).then((schedulers) => {
        const scheduler = schedulers[0];
        const users: graphqlWorkspaceTypes.MultipleRelationOperationIntInputType[] =
          user
            ? [{ id: user?.id, type: MultipleRelationOperationType.add }]
            : [];
        return dispatch(
          createReports(workspace.tenantId, workspace.id, [
            {
              id: uuid.v1(),
              schedulerId: scheduler.id,
              projectId,
              description: data.description,
              name: data.name,
              users,
            },
          ]),
        ).then(() => handleClose());
      });
    },
    [dispatch, handleClose, projectId, user, workspace.id, workspace.tenantId],
  );

  return (
    <ReportFormComponent
      handleCancel={handleCancel}
      handleClose={handleClose}
      isShowing={isShowing}
      onSave={onSave}
    />
  );
});
