import { workspaceFinanceRootPath } from '~src/navigation/paths/workspace/finance/paths';

export const papersRootPath = (tenantId: string, workspaceId: string) => {
  return `${workspaceFinanceRootPath(tenantId, workspaceId)}/papers`;
};
export const paperRootPath = (
  tenantId: string,
  workspaceId: string,
  paperId: string,
) => {
  return `${papersRootPath(tenantId, workspaceId)}/${paperId}`;
};
