import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingGroups } from '~src/data/store/selectors/finance/holding-groups';
import { selectData } from '~src/data/store/selectors/selectors';
import { ProjectValuationComponent } from '~src/domain/workspace/components/project/holding/valuation/project-valuation.component';
import {
  defaultAssetClassBackgroundColor,
  defaultAssetClassFontColor,
  defaultCategoryBackgroundColor,
  defaultCategoryFontColor,
  defaultCurrencyBackgroundColor,
  defaultCurrencyFontColor,
  defaultIdBackgroundColor,
  defaultIdFontColor,
  defaultRegionBackgroundColor,
  defaultRegionFontColor,
  defaultSectorBackgroundColor,
  defaultSectorFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/grouped-valuation/default-colors';
import { useGroupedValuationMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/grouped-valuation/menu.component';
import { Config } from '~src/utils/interfaces/config';
import { PivotType, typeToString } from '~src/utils/interfaces/pivot-type';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  portfolio: PortfolioState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  currency: CurrencyState;
  date: moment.Moment;
  period: Period;
  // pivot: PivotType;
}

const getDefaultFontColor = (type: PivotType) => {
  if (type === 'id') {
    return defaultIdFontColor;
  } else if (type === 'category') {
    return defaultCategoryFontColor;
  } else if (type === 'assetClass') {
    return defaultAssetClassFontColor;
  } else if (type === 'sector') {
    return defaultSectorFontColor;
  } else if (type === 'region') {
    return defaultRegionFontColor;
  } else if (type === 'currency') {
    return defaultCurrencyFontColor;
  }
  return '#FFFFFF';
};

const getDefaultBackgroundColor = (type: PivotType) => {
  if (type === 'id') {
    return defaultIdBackgroundColor;
  } else if (type === 'category') {
    return defaultCategoryBackgroundColor;
  } else if (type === 'assetClass') {
    return defaultAssetClassBackgroundColor;
  } else if (type === 'sector') {
    return defaultSectorBackgroundColor;
  } else if (type === 'region') {
    return defaultRegionBackgroundColor;
  } else if (type === 'currency') {
    return defaultCurrencyBackgroundColor;
  }
  return '#FFFFFF';
};

export const GroupedValuationElement = React.memo(
  ({
    workspace,
    // portfolio,
    projectId,
    holdings,
    config,
    // pivot,
    date,
    period,
    currency,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const pivot = (element.config.type as PivotType) ?? 'category';
    // const type = pivot;
    const { menu, handleOpen, isOpen } = useGroupedValuationMenu(
      element,
      remove,
      updated,
    );

    const data = useSelector((state: RootState) =>
      getHoldingGroups(selectData(state), {
        workspaceId: workspace.id,
        projectId,
        date,
        filterKey: pivot,
        holdings,
      }),
    );

    const content = (
      <ProjectValuationComponent
        workspaceId={workspace.id}
        projectId={projectId}
        date={date}
        period={period}
        title={typeToString(pivot)}
        pivot={pivot}
        holdingGroups={data}
        currency={currency}
      />
    );

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? getDefaultFontColor(pivot),
          backgroundColor:
            element.config.backgroundColor ?? getDefaultBackgroundColor(pivot),
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
      pivot,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          {content}
        </ElementComponent>
        {menu}
      </>
    );
  },
);
