import React from 'react';
import { useDispatch } from 'react-redux';
import {
  createHoldingConfigs,
  updateHoldingConfigs,
} from '~src/data/store/modules/holdings/configs/configs/requests';
import { upsertHoldingDashboardConfigs } from '~src/data/store/modules/holdings/configs/dashboard-configs/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import * as uuid from 'uuid';

export function useConfig() {
  const dispatch = useDispatch<AppDispatch>();
  const create = React.useCallback(
    (workspace: WorkspaceState, holdingId: string) => {
      const workspaceId = workspace.id;
      const holdingConfigInput: graphqlWorkspaceTypes.CreateHoldingConfigInputType =
        {
          // id: 1,
        };

      return dispatch(
        createHoldingConfigs(workspace.tenantId, workspaceId, holdingId, [
          holdingConfigInput,
        ]),
      ).then((configs) => {
        const config = configs[0];
        const initDashboardConfig: graphqlWorkspaceTypes.CreateHoldingDashboardConfigInputType =
          {
            id: uuid.v1(),
            elementOperations: [],
            name: 'default',
            layouts: JSON.stringify({}),
            // height: 500,
          };
        return dispatch(
          upsertHoldingDashboardConfigs(
            workspace.tenantId,
            workspaceId,
            config.id,
            [initDashboardConfig],
          ),
        ).then((dashboardConfigs) => {
          const dashboardConfig = dashboardConfigs
            ? dashboardConfigs[0]
            : undefined;
          const dashboardConfigId = dashboardConfig?.id;

          if (dashboardConfigId) {
            const holdingConfigInput: graphqlWorkspaceTypes.UpdateHoldingConfigInputType =
              {
                id: config.id,
                currentDashboardConfigId: dashboardConfigId,
                holdingDashboardConfigOperations: [
                  {
                    type: MultipleRelationOperationType.add,
                    id: dashboardConfigId,
                  },
                ],
              };
            dispatch(
              updateHoldingConfigs(workspace.tenantId, workspaceId, holdingId, [
                holdingConfigInput,
              ]),
            );
          }
        });
      });
    },
    [dispatch],
  );

  const update = React.useCallback(
    (
      workspace: WorkspaceState,
      holdingId: string,
      holdingConfig: graphqlWorkspaceTypes.UpdateHoldingConfigInputType,
    ) => {
      const workspaceId = workspace.id;

      return dispatch(
        updateHoldingConfigs(workspace.tenantId, workspaceId, holdingId, [
          holdingConfig,
        ]),
      );
    },
    [dispatch],
  );
  return { create, update };
}
