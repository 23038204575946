import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { PortfolioConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPortfolioDashboardConfigsByPortfolioConfigId } from '~src/data/store/selectors/portfolio/portfolio-config/portfolio-dashboard-configs/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useCreateDialog } from '~src/domain/workspace/components/project/portfolio/config/use-create-dialog';
import { usePortfolioConfigActions } from '~src/hooks/actions/workspace/project/portfolio/portfolio-config/use-portfolio-config-actions.hook';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  handlePersist?: () => void;
  label?: string;
  workspace: WorkspaceState;
  projectId: string;
  portfolioConfig: PortfolioConfigState;
  dashboardConfig: PortfolioDashboardConfigState;
}

export const DashboardConfigInput = (props: Props) => {
  const { workspace, portfolioConfig } = props;
  const dashboardConfigs = useSelector((state: RootState) =>
    getPortfolioDashboardConfigsByPortfolioConfigId(
      selectWorkspaceData(state),
      portfolioConfig.id,
    ),
  );
  const getOptionLabel = React.useCallback(
    (t: PortfolioDashboardConfigState) => {
      const holding = dashboardConfigs.find((config) => config.id === t.id);
      return holding?.name ? holding.name : '';
    },
    [dashboardConfigs],
  );
  const { update } = usePortfolioConfigActions();
  const { dialogElement, setOpen, setInput } = useCreateDialog({
    workspace,
    projectId: props.projectId,
    portfolioConfig,
    // getDialogContent,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setInput(uuid.v1(), value);
    },
    [setInput],
  );

  const handleValue = (dashboardConfig: PortfolioDashboardConfigState) => {
    const portfolioConfigInput: graphqlWorkspaceTypes.UpdatePortfolioConfigInputType =
      {
        id: portfolioConfig.id,
        currentDashboardConfigId: dashboardConfig.id,
      };
    update(workspace, portfolioConfig.portfolioId, portfolioConfigInput);
  };

  return (
    <EditAutocomplete
      multiple={false}
      addOption={{ initDialog, setOpen, dialogElement }}
      // dialogElement={dialogElement}
      getOptionLabel={getOptionLabel}
      label={props.label}
      handleValue={handleValue}
      // initDialog={initDialog}
      options={dashboardConfigs}
      value={props.dashboardConfig}
      // setOpen={setOpen}
      handlePersist={props.handlePersist}
    />
  );
};
