import { useDispatch } from 'react-redux';
import {
  createPapers,
  deletePapers,
  updatePapers,
} from '~src/data/store/modules/finance/papers/requests';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';
import { AppDispatch } from '~src/store/store';

export function usePaperActions() {
  const dispatch = useDispatch<AppDispatch>();
  const onUpdate = (oldData: PaperState, newData: PaperState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlFinanceTypes.UpdatePaperInputType = {
          id: newData.id,
          exchange: newData.exchange ?? '',
          symbol: newData.symbol ?? '',
        };
        dispatch(updatePapers([input]))
          .then((_papers) => {
            resolve();
          })
          .catch((_err) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: PaperState) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(deletePapers([{ id: oldData.id }]))
        .then((_paperIds) => {
          resolve();
        })
        .catch((_err) => {
          reject();
        });
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };

  const onCreate = (paperInput: graphqlFinanceTypes.CreatePaperInputType) => {
    return dispatch(createPapers([paperInput]));
  };
  return { onUpdate, onDelete, onCreate };
}
