import { compact } from 'lodash';
import React from 'react';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';
// import history from '~src/navigation/history';
import { Index } from '~src/services/search/index';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';

import { SearchResult } from '../../components/search/search-component';
import { store } from '~src/store/store';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useNavigate } from 'react-router-dom';

function createHoldingsIndex(holdings: HoldingState[]) {
  const holdingsIndex = new Index();
  const fields = ['name', 'description'];
  const documents = holdings.map((holding) => {
    return {
      id: holding.id,
      name: holding.name,
      description: holding.description,
    };
  });
  holdingsIndex.build(fields, documents);
  return holdingsIndex;
}

const holdingsSearch =
  (
    tenantId: string,
    workspaceId: string,
    holdings: HoldingState[],
    holdingsIndex: Index,
    onClick: (holding: HoldingState) => void,
  ) =>
  (query: string): SearchResult[] => {
    const idx = holdingsIndex.idx();
    if (!idx) {
      return [];
    }
    const holdingSearchResult = idx.search(query);
    const holdingsHits: HoldingState[] = compact(
      holdingSearchResult.map((hit: any) => {
        return holdings.find((holding) => {
          return holding.id === hit.ref;
        });
      }),
    );
    const holdingResults = holdingsHits.map((holding: HoldingState) => {
      return {
        id: holding.id ? holding.id : -1,
        title: holding.name ? holding.name : '-',
        description: holding.description ? holding.description : '-',
        onClick: () => onClick(holding),
      };
    });
    return holdingResults;
  };

export function useHoldingsIndex(
  tenantId: string,
  workspaceId: string,
  holdings: HoldingState[],
) {
  const [holdingsIndex, setHoldingsIndex] = React.useState<Index>(
    () => new Index(),
  );

  React.useEffect(() => {
    setHoldingsIndex(createHoldingsIndex(holdings));
  }, [holdings]);

  const navigate = useNavigate();

  const onClick = React.useCallback(
    (holding: HoldingState) => {
      const project = getProjectById(selectWorkspaceData(store.getState()), {
        id: holding.projectId,
        workspaceId,
      });
      if (project) {
        return navigate(
          holdingBasePath(
            tenantId,
            project.workspaceId,
            holding.projectId,
            holding.id,
          ),
        );
      }
    },
    [navigate, tenantId, workspaceId],
  );

  return {
    holdingsSearch: holdingsSearch(
      tenantId,
      workspaceId,
      holdings,
      holdingsIndex,
      onClick,
    ),
  };
}
