import React, { memo } from 'react';

import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useProjectReportForm } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/use-project-report-form';
import { InputData } from './project-report-input.data';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

interface Props {
  workspace: WorkspaceState;
  projectReport: ProjectReportState;
  inputData?: InputData;
}

export const ProjectReportFormComponent = memo((props: Props) => {
  const { projectReport, workspace, inputData } = props;

  const fields = useProjectReportForm({
    projectReport,
    workspace,
    inputData,
  });

  return (
    <Grid2 container columnSpacing={1} rowSpacing={2}>
      <Grid2 sm={12}>{fields.nameField}</Grid2>
      <Grid2 sm={12}>{fields.publishedField}</Grid2>
      <Grid2 sm={6}>{fields.periodFields.periodStartField.element}</Grid2>
      <Grid2 sm={6}>{fields.periodFields.periodEndField.element}</Grid2>
      <Grid2 sm={6}>{fields.compactTypeField}</Grid2>
    </Grid2>
  );
});
