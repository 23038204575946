import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseBondId {
  id: string;
  workspaceId: string;
}

export type BondState = Omit<graphqlWorkspaceTypes.Bond, 'asset' | '_type'> & {
  workspaceId: string;
  assetId: string;
};

export function bondToBondState(bond: BondState) {
  return bond;
}
const initialState: RecordState<BondState, string>[] = [];

const selectKeyId = (bond: BaseBondId) => bond.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseBondId, b: BaseBondId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseBondId, BondState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const bondsSlice = createBaseRecordSlice('bonds', adapter);
export const bondsSelectors = adapter.selectors;

export const bondsReducer = bondsSlice.reducer;
export const bondsActions = bondsSlice.actions;

export const initialBondState = {
  bonds: initialState,
};

export const bondReducer = combineReducers({
  bonds: bondsReducer,
});
