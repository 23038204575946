import { compact } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { ValuationState } from '~src/data/store/reducers/workspace/projects/scenario/valuations/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getHoldingsValuation } from '~src/data/store/selectors/finance/valuation/holdings-valuation';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { getHoldingsByIdsForProject } from '~src/data/store/selectors/holding/holdings/selectors';
import {
  selectData,
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import {
  // getValuationsByBudgetId,
  getValuationsByBudgetIdAndHoldingIds,
} from '~src/data/store/selectors/workspace/projects/scenario/valuations/selectors';
import { ValuationChartComponent } from '~src/domain/workspace/components/project/scenario/charts/valuation-chart.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { Valuation } from '~src/domain/workspace/components/project/scenario/models/valuation';
import { Config } from '~src/utils/interfaces/config';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  budget: Budget;
  config: Config;
  useLiveValue: boolean;
}

const createValuations = (
  initialValuations: ValuationState[],
  valuationsBudgeted: Valuation[],
) => {
  let currentValuations: Valuation[] = compact(
    initialValuations.map((valuation) => {
      const v = valuation;
      if (v && v?.value != null) {
        return {
          id: valuation.id,
          holdingId: valuation.holdingId,
          date: moment(v?.date),
          value: v.value,
        };
      }
    }),
  );

  const valuations: Valuation[] = [...currentValuations];
  let currentDate: moment.Moment | null = null;
  for (let i = 0; i < valuationsBudgeted.length; ++i) {
    const valuation = valuationsBudgeted[i];
    if (currentDate == null || currentDate.unix() < valuation.date.unix()) {
      if (currentDate != null) {
        valuations.push(...currentValuations);
      }
      currentDate = valuation.date;
      currentValuations = currentValuations.map((v) => {
        return {
          ...v,
          date: valuation.date,
        };
      });
    }
    const idx = currentValuations.findIndex(
      (v) => v.holdingId === valuation.holdingId,
    );
    if (idx !== -1) {
      const existingValuation = currentValuations[idx];
      existingValuation.value = valuation.value;
    } else {
      currentValuations.push({
        id: uuid.v1(),
        date: valuation.date,
        holdingId: valuation.holdingId,
        value: valuation.value,
      });
    }
    if (i === valuationsBudgeted.length - 1) {
      valuations.push(...currentValuations);
    }
  }
  return valuations;
};

export const HoldingScenarioValuationComponent = React.memo((props: Props) => {
  const { workspace, project, budget, config } = props;

  // const budgetValuations = useSelector((state: RootState) =>
  //   getValuationsByBudgetId(selectWorkspaceData(state), budget.id)
  // );

  const baseCurrencyId = useSelector((state: RootState) =>
    getSelectedCurrencyState(selectFinancialData(state)),
  );
  const baseCurrency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), {
      id: baseCurrencyId,
    }),
  );
  const currency = baseCurrency ?? defaultCurrency;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const holdings = useSelector((state: RootState) => {
    return getHoldingsByIdsForProject(selectWorkspaceData(state), {
      projectId: project.id,
      ids: budget.holdingIds,
    });
  });

  const budgetValuations = useSelector((state: RootState) =>
    getValuationsByBudgetIdAndHoldingIds(selectWorkspaceData(state), {
      budgetId: budget.id,
      holdingIds: budget.holdingIds,
    }),
  );

  const holdingValuations = useSelector((state: RootState) =>
    getHoldingsValuation(selectData(state), {
      date: config.date,
      filter,
      holdings,
      projectId: project.id,
      useLiveValue: true,
      workspaceId: workspace.id,
      currency,
    }),
  );

  const initialValuations: ValuationState[] = React.useMemo(() => {
    return compact(
      holdingValuations.map((v) => {
        const valuation = v.valuation;
        if (valuation) {
          // set date for all valuations
          return {
            ...valuation,
            id: uuid.v1(),
            budgetId: budget?.id ?? '',
            date: config.date,
            holdingId: v.holding.id,
          };
        }
      }),
    );
  }, [budget?.id, config.date, holdingValuations]);

  const valuationsBudgeted = React.useMemo(() => {
    const valuationsBudgeted = [...(budgetValuations ?? [])];
    valuationsBudgeted.sort((a, b) => a.date.unix() - b.date.unix());
    return valuationsBudgeted;
  }, [budgetValuations]);

  const valuations = React.useMemo(
    () => createValuations(initialValuations, valuationsBudgeted),
    [initialValuations, valuationsBudgeted],
  );

  // const valuationsElement = valuations.map((source, idx) => {
  //   return (
  //     <div key={idx}>
  //       {source.date.format('DD.MM.YYYY')} {source.value}
  //     </div>
  //   );
  // });
  // const cashElement = <>{valuationsElement}</>;

  const container = (
    <>
      <ValuationChartComponent
        workspace={workspace}
        projectId={project.id}
        valuations={valuations}
      />
      {/* {cashElement} */}
    </>
  );
  return container;
});
