import React from 'react';
import { useSelector } from 'react-redux';
import { BetaComponent } from '~src/components/summary/beta.component';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsBeta } from '~src/data/store/selectors/finance/beta/holdings-beta';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';
import { Period } from '~src/utils/period/period';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  period: Period;
  useLiveValue: boolean;
  indexPaper: PaperState | undefined;
  subLabel?: string;
}

export const BetaHoldingsComponent = React.memo((props: Props) => {
  // useComponentUpdateDebug('SummaryComponent', props);
  const {
    holdings,
    indexPaper,
    period,
    projectId,
    useLiveValue,
    workspaceId,
    subLabel,
  } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const beta = useSelector((state: RootState) =>
    getHoldingsBeta(selectData(state), {
      workspaceId,
      projectId,
      holdings,
      period,
      indexPaper,
      filter,
      useLiveValue,
    }),
  );
  return (
    <BetaComponent beta={beta} indexPaper={indexPaper} subLabel={subLabel} />
  );
});
