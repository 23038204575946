import { colors } from '@mui/material';

export interface Color {
  veryLight: string;
  light: string;
  medium: string;
  dark: string;
  veryDark: string;
  hoverLight: string;
  hoverMedium: string;
  hoverDark: string;
}

export const primaryBackgroundColor: Color = {
  veryLight: colors.blue[50],
  light: colors.blue[100],
  medium: colors.blue[300],
  dark: colors.blue[700],
  veryDark: colors.blue[900],
  hoverLight: colors.blueGrey[50],
  hoverMedium: colors.blueGrey[300],
  hoverDark: colors.blueGrey[700],
};

export const secondaryBackgroundColor: Color = {
  veryLight: colors.grey[50],
  light: colors.grey[100],
  medium: colors.grey[300],
  dark: colors.grey[700],
  veryDark: colors.grey[900],
  hoverLight: colors.blueGrey[50],
  hoverMedium: colors.blueGrey[300],
  hoverDark: colors.blueGrey[700],
};

export const primaryFrontColor: Color = {
  veryLight: colors.grey[50],
  light: colors.grey[100],
  medium: colors.grey[500],
  dark: colors.grey[700],
  veryDark: colors.grey[900],
  hoverLight: colors.grey[100],
  hoverMedium: colors.grey[300],
  hoverDark: colors.grey[700],
};
