import React from 'react';
import { useDispatch } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { budgetsActions } from '~src/data/store/reducers/workspace/projects/scenario/budgets/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/holding-card.component';
import { ScenarioHoldingForecastComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast.component';
import {
  useForecastElement,
  useSelectForecast,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/use-select-forecast.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { AppDispatch } from '~src/store/store';

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Checkbox, Grid } from '@mui/material';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  scenario: Scenario;
  budget: Budget;
  inBudget: boolean;
}

export const ScenarioHoldingCardComponent = React.memo((props: Props) => {
  const { workspace, project, holding, scenario, budget, inBudget } = props;

  const forecast = useSelectForecast(
    budget,
    ForecastObjectType.holding,
    holding.id,
  );

  const {
    element: selectForecastElement,
    // addForecastElement,
  } = useForecastElement(
    budget,
    forecast,
    ForecastObjectType.holding,
    holding.id,
  );

  // const dialog = useVisible();
  // const elementsVisible = useVisible();

  const dispatch = useDispatch<AppDispatch>();
  const setInBudget = React.useCallback(
    (inBudget: boolean) => {
      const holdingIds = budget.holdingIds;
      if (inBudget) {
        holdingIds.push(holding.id);
      } else {
        const idx = holdingIds.findIndex((h) => h === holding.id);
        if (idx !== -1) {
          holdingIds.splice(idx, 1);
        }
      }

      return dispatch(
        budgetsActions.upsertOneElement({
          ...budget,
          holdingIds,
        }),
      );
    },
    [budget, dispatch, holding.id],
  );

  // const addForecastElementWithCallback = React.useCallback(
  //   (forecastElement: IForecastElementState) => {
  //     addForecastElement(forecastElement);
  //     elementsVisible.setIsShowing(true);
  //   },
  //   [addForecastElement, elementsVisible]
  // );

  // const editForecastElement = forecast && (
  //   <>
  //     <IconButton onClick={dialog.open} color="inherit">
  //       <EditIcon />
  //     </IconButton>
  //     <DialogComponent
  //       dialogProps={{ open: dialog.isShowing, onClose: dialog.close }}
  //     >
  //       <DialogContentComponent title="Forecast element">
  //         <AddHoldingForecastElementComponent
  //           holding={holding}
  //           project={project}
  //           workspace={workspace}
  //           addForecastElement={addForecastElementWithCallback}
  //         />
  //       </DialogContentComponent>
  //     </DialogComponent>
  //   </>
  // );

  // const forecastElementsElement = elementsVisible.isShowing && (
  //   <Grid
  //     item
  //     xs={12}
  //     sx={{
  //       pt: 1,
  //     }}
  //   >
  //     <Box>
  //       {forecast?.elements?.map((element) => (
  //         <HoldingForecastElementComponent
  //           key={element.id}
  //           forecast={forecast}
  //           forecastElementState={element}
  //           workspace={workspace}
  //           project={project}
  //           holding={holding}
  //         />
  //       ))}
  //     </Box>
  //   </Grid>
  // );
  // const accordionButton = forecast && (
  //   <IconButton onClick={elementsVisible.toggle}>
  //     {elementsVisible.isShowing ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
  //   </IconButton>
  // );
  return (
    <Grid container alignItems="center">
      <Grid item xs={1}>
        <Checkbox onChange={() => setInBudget(!inBudget)} checked={inBudget} />
      </Grid>
      <Grid item xs={6}>
        <HoldingCardComponent
          holding={holding}
          portfolios={[]}
          projectId={project.id}
          workspace={workspace}
        />
      </Grid>
      <Grid item xs={3} sx={{ pl: 1 }}>
        {selectForecastElement}
      </Grid>
      {forecast && (
        <ScenarioHoldingForecastComponent
          forecast={forecast}
          holding={holding}
          project={project}
          scenario={scenario}
          budget={budget}
          workspace={workspace}
        />
      )}
      {/* <Grid item xs={2} sx={{ pl: 1 }}>
        {editForecastElement}
        {accordionButton}
      </Grid>
      {forecastElementsElement} */}
    </Grid>
  );
});
