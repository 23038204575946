import { SHOULD_AUTOBATCH } from '@reduxjs/toolkit';

export interface Action<T> {
  type: string;
  payload: T;
  meta: {
    [SHOULD_AUTOBATCH]: boolean | undefined;
  };
}

function createAction<T>(
  type: string,
  payload: T,
  options?: ActionOptions,
): Action<T> {
  return {
    type,
    payload,
    meta: {
      [SHOULD_AUTOBATCH]: options?.shouldAutobatch,
    },
  };
}

interface ActionOptions {
  shouldAutobatch?: boolean;
}

interface Slice<S, T> {
  type: string;
  reducer: (state: S, action: { payload: T }) => S;
  action: (t: T, options?: ActionOptions) => Action<T>;
}

export function createSlice<S, T>(
  domain: string,
  name: string,
  f: (state: S, action: { payload: T }) => S,
): Slice<S, T> {
  const type = `${domain}/${name}`;
  return {
    type,
    reducer: (state: S, action: { payload: T }) => f(state, action),
    action: (t: T, options?: ActionOptions) => createAction(type, t, options),
  };
}

export function createReducer<S>(
  initialState: S,
  slices: { [key: string]: Slice<S, any> },
) {
  return (state: S = initialState, action: any) => {
    for (const slice of Object.values(slices)) {
      if (reducerSwitch(action.type, slice.type)) {
        return slice.reducer(state, action);
      }
    }
    return state;
  };
}

function reducerSwitch(actionType: string, sliceType: string) {
  return actionType === sliceType;
}
