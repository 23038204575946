import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getExchangeRateState } from '~src/data/store/selectors/finance/exchange-rates/selectors';
import { getBaseValuations } from '~src/data/store/selectors/finance/valuation/base/base-valuations';
import { getStockHoldingValuations } from '~src/data/store/selectors/finance/valuation/stock/stock-holding-valuations';
import {
  TypedValue,
  Value,
  ValueBase,
} from '~src/data/store/selectors/finance/valuation/valuation-types';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

import { unionSorted } from '@pladdenico/common';
import { HoldingType } from '@pladdenico/models';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { getFundHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/fund/fund-holding/selectors';
import { getFundHoldingValuations } from '~src/data/store/selectors/finance/valuation/fund/fund-holding-valuations';
import { getBondHoldingValuations } from '~src/data/store/selectors/finance/valuation/bond/bond-holding-valuations';

interface Props {
  workspaceId: string;
  holding: HoldingState;
  currency: CurrencyState;
}

export const getHoldingValuations = createCachedSelector(
  (state: DataState, props: Props) =>
    getBaseValuations(state, {
      workspaceId: props.workspaceId,
      currency: props.currency,
      holding: props.holding,
    }),
  (state: DataState, props: Props) => {
    if (props.holding.type === HoldingType.Stock) {
      return _getStockHoldingValuations(state, {
        workspaceId: props.workspaceId,
        currency: props.currency,
        holding: props.holding,
      });
    } else if (props.holding.type === HoldingType.Bond) {
      return _getBondHoldingValuations(state, {
        workspaceId: props.workspaceId,
        currency: props.currency,
        holding: props.holding,
      });
    } else if (props.holding.type === HoldingType.Fund) {
      return _getFundHoldingValuations(state, {
        workspaceId: props.workspaceId,
        currency: props.currency,
        holding: props.holding,
      });
    }
    return null;
  },
  (baseValues, typedValues): TypedValue[] => {
    if (typedValues == null) {
      return baseValues.map((value) => {
        return { id: value.id, base: value };
      });
    }
    return unionSorted<ValueBase, Value, TypedValue>(
      baseValues,
      typedValues,
      [
        (baseValue, typedValue) => {
          return baseValue.date.getTime() - typedValue.date.getTime();
        },
      ],
      (baseValue, typedValue) => {
        return { id: baseValue.id, base: baseValue, typed: typedValue };
      },
      (baseValue) => {
        return { id: baseValue.id, base: baseValue };
      },
      (typedValue) => {
        return { id: typedValue.id, typed: typedValue };
      },
    );
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.workspaceId}:${props.holding.id}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});

const _getStockHoldingValuations = createCachedSelector(
  (state: DataState, _props: Props) => state,
  (state: DataState, props: Props) =>
    getStockHoldingByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (state, stockHolding, workspaceId, currency, holding) => {
    if (stockHolding) {
      return getStockHoldingValuations(state, {
        workspaceId,
        currency,
        holdingId: holding.id,
        stockHoldingId: stockHolding.id,
        stockId: stockHolding.stockId,
      });
    }
    return [];
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holding.id}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});

const _getBondHoldingValuations = createCachedSelector(
  (state: DataState, _props: Props) => state,
  (state: DataState, props: Props) =>
    getBondHoldingByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (state, bondHolding, workspaceId, currency, holding) => {
    if (bondHolding) {
      return getBondHoldingValuations(state, {
        workspaceId,
        currency,
        holdingId: holding.id,
        bondHoldingId: bondHolding.id,
        bondId: bondHolding.bondId,
      });
    }
    return [];
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holding.id}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});

const _getFundHoldingValuations = createCachedSelector(
  (state: DataState, _props: Props) => state,
  (state: DataState, props: Props) =>
    getFundHoldingByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (state, fundHolding, workspaceId, currency, holding) => {
    if (fundHolding) {
      return getFundHoldingValuations(state, {
        workspaceId,
        currency,
        holdingId: holding.id,
        fundHoldingId: fundHolding.id,
        fundId: fundHolding.fundId,
      });
    }
    return [];
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holding.id}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});
