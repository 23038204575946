import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectUserData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import {
  workspaceMembersRootPath,
  workspaceMemberRootPath,
} from '~src/navigation/paths/workspace/members/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useWorkspaceBreadcrumbs } from './workspace-breadcrumbs';
import { getUserById } from '~src/data/store/selectors/user/user/selectors';

export function useMembersBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();

  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);

  if (tenantId != null && workspaceId != null) {
    const memberElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={workspaceMembersRootPath(tenantId, workspaceId)}
      >
        members
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceMembersRootPath(tenantId, workspaceId)}
        to={workspaceMembersRootPath(tenantId, workspaceId)}
      >
        members
      </LinkRouter>
    );
    return [...workspaceBreadcrumb, memberElement];
  }
  return [];
}

export function useMemberBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId, memberId } = useParams<{
    tenantId: string;
    workspaceId: string;
    memberId: string;
  }>();
  const membersBreadcrumbs = useMembersBreadcrumbs(false);
  const workspace = useSelector((state: RootState) => {
    if (workspaceId != null && tenantId != null)
      return getWorkspaceById(selectWorkspaceData(state), {
        id: workspaceId,
        tenantId,
      });
  });
  const member = useSelector((state: RootState) => {
    if (workspaceId != null && memberId != null) {
      return getUserById(selectUserData(state), +memberId);
    }
  });
  if (workspace && member) {
    const memberElement = last ? (
      <Typography
        color="inherit"
        key={workspaceMemberRootPath(
          workspace.tenantId,
          workspace.id,
          member.id,
        )}
      >
        {member.name}
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceMemberRootPath(
          workspace.tenantId,
          workspace.id,
          member.id,
        )}
        to={workspaceMemberRootPath(
          workspace.tenantId,
          workspace.id,
          member.id,
        )}
      >
        {member.name}
      </LinkRouter>
    );
    return [...membersBreadcrumbs, memberElement];
  }
  return [];
}
export function MemberBreadcrumbComponent() {
  const membersBreadcrumbs = useMemberBreadcrumbs(true);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {membersBreadcrumbs}
    </Breadcrumbs>
  );
}
export function MembersBreadcrumbComponent(props: { last: boolean }) {
  const membersBreadcrumbs = useMembersBreadcrumbs(props.last);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {membersBreadcrumbs}
    </Breadcrumbs>
  );
}
