import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { Fund } from '~src/services/graphql/workspace/client/graphql';

export function parseFund(
  workspaceId: string,
  assetId: string,
  fund: Fund,
): FundState {
  return {
    workspaceId,
    assetId,
    id: fund.id,
    // fundExchange: fund.fundExchange,
    // symbol: fund.symbol,
    paperId: fund.paperId,
  };
}
