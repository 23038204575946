import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { dnbAccountsActions } from '~src/services/xlsx/handlers/adapters/dnb/account/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import { AppDispatch } from '~src/store/store';
import { DnbAccount } from './account';

export const handlerType = 'account';

const header: Array<keyof DnbAccount> = [
  'accountNumber',
  'accountName',
  'valuationStart',
  'valuationEnd',
  'inflow',
  'outflow',
];

const headerMapper = new Map<string, keyof DnbAccount>([
  ['Konto', 'accountNumber'],
  ['Kontonavn', 'accountName'],
  ['Inngående saldo', 'valuationStart'],
  ['Sum inn på konto', 'inflow'],
  ['Sum ut av konto', 'outflow'],
  ['Disponibelt beløp', 'valuationEnd'],
]);

export const dnbAccountImportHandler = (
  workspace: WorkspaceState,
  holdingId: string,
  dispatch: AppDispatch,
): ImportHandler<DnbAccount> => {
  return {
    type: handlerType,
    header,
    handler: (ts) => {
      console.log(workspace.tenantId, workspace.id, ts);
      const tsWithAccount = {
        ...ts[0],
        holdingId,
      };
      dispatch(dnbAccountsActions.addOne(tsWithAccount));
      return Promise.resolve();
    },
    headerMapper,
  };
};
