import React from 'react';
import { EditStock } from '~src/components/fields/workspace/asset/edit/asset-types/edit-stock';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { StockHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/create-action';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  stockHoldingInput: StockHoldingInputType;
  setStockHoldingInput: (
    stockHoldingInput: graphqlWorkspaceTypes.CreateStockHoldingInputType,
  ) => void;
}

export const CreateStockHoldingFieldsComponent = React.memo((props: Props) => {
  const { setStockHoldingInput, stockHoldingInput, workspace } = props;
  const handleStockChange = React.useCallback(
    (stockId: string) => {
      setStockHoldingInput({
        ...stockHoldingInput,
        stockId,
      });
    },
    [setStockHoldingInput, stockHoldingInput],
  );

  return (
    <EditStock
      handleValue={handleStockChange}
      stockId={stockHoldingInput.stockId}
      workspace={workspace}
    />
  );
});
