import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {} from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';

import InventoryIcon from '@mui/icons-material/Inventory';
import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  workspaceEntitiesBasePath,
  workspaceEntitiesRootPath,
} from '~src/navigation/paths/workspace/entity/paths';
import { CustomRouterLink } from '~src/domain/workspace/components/drawer/items/custom-router';
import { drawerListItemButtonSX } from '~src/domain/workspace/components/drawer/items/drawer-list-item-button-sx';

export const drawerWidth = 240;

interface Props {
  tenant: TenantState;
  workspace: WorkspaceState;
}

export const WorkspaceDrawerEntitiesItemComponent = React.memo(
  (props: Props) => {
    const { tenant, workspace } = props;

    const match = useMatch({ path: workspaceEntitiesBasePath, end: false });

    return (
      <ListItemButton
        component={CustomRouterLink}
        selected={match != null}
        to={workspaceEntitiesRootPath(tenant.id, workspace.id)}
        sx={drawerListItemButtonSX}
      >
        <ListItemIcon>
          <InventoryIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={'Entities'} />
      </ListItemButton>
    );
  },
);
