import { LoanHoldingState } from '~src/data/store/reducers/holding/holding-types/loan/loan-holding/reducer';
import { LoanHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseLoanHolding(loanHolding: LoanHolding): LoanHoldingState {
  return {
    id: loanHolding.id,
    loanId: loanHolding.loan?.id ?? '',
    holdingId: loanHolding.holding?.id ?? '',
  };
}
