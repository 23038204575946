import React from 'react';

import { Snackbar } from '@mui/material';
import { UpdateState } from '~src/utils/common/update-state';

interface Props {
  message: string;
  updateState: UpdateState;
  setUpdateState: (updateState: UpdateState) => void;
}

export const SnackbarFailure = React.memo((props: Props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={props.updateState === UpdateState.failed}
      autoHideDuration={6000}
      onClose={() => props.setUpdateState(UpdateState.undefined)}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{props.message}</span>}
    />
  );
});
