import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getScenarioById } from '~src/data/store/selectors/workspace/projects/scenario/scenarios/selectors';
import {
  scenarioBasePath,
  scenariosBasePath,
} from '~src/navigation/paths/workspace/project/portfolio-project/scenario/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { useProjectsBreadcrumbs } from './project-breadcrumb';
import { LinkRouter, maxItems } from './breadcrumbs.component';

function useScenarioBreadcrumbs(_last: boolean) {
  const { tenantId, workspaceId, projectId, scenarioId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    scenarioId: string;
  }>();

  const scenariosBreadcrumbs = useScenariosBreadcrumbs(false);

  const scenario = useSelector((state: RootState) => {
    if (scenarioId != null && projectId != null) {
      return getScenarioById(selectWorkspaceData(state), {
        id: scenarioId,
        projectId,
      });
    }
  });
  if (scenario && tenantId != null && workspaceId != null) {
    return [
      scenariosBreadcrumbs,
      <Typography
        color="inherit"
        key={scenarioBasePath(
          tenantId,
          workspaceId,
          scenario.projectId,
          scenario.id,
        )}
      >
        {scenario.name}
      </Typography>,
    ];
  }
  return [];
}
function useScenariosBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId, projectId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
  }>();
  const projectBreadCrumbs = useProjectsBreadcrumbs(false);
  if (tenantId != null && workspaceId != null && projectId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        key={scenariosBasePath(tenantId, workspaceId, projectId)}
      >
        scenarios
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={scenariosBasePath(tenantId, workspaceId, projectId)}
        to={scenariosBasePath(tenantId, workspaceId, projectId)}
      >
        scenarios
      </LinkRouter>
    );
    return [...projectBreadCrumbs, projectElement];
  }
  return [];
}
export function ScenarioBreadcrumbComponent() {
  const scenarioBreadcrumbs = useScenarioBreadcrumbs(true);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {scenarioBreadcrumbs}
    </Breadcrumbs>
  );
}
export function ScenariosBreadcrumbComponent(props: { last: boolean }) {
  const scenariosBreadcrumbs = useScenariosBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {scenariosBreadcrumbs}
    </Breadcrumbs>
  );
}
