import {
  BondStateVisitor,
  BondVisitable,
} from '~src/data/store/visitors/asset/types/bond/bond-visitor';
import { Bond } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleBonds(
  workspaceId: string,
  dispatch: AppDispatch,
  bonds: Bond[],
) {
  const visitor = new BondStateVisitor(dispatch);
  const res = bonds.map((bond) => {
    const assetVisitable = new BondVisitable(workspaceId, bond);
    return assetVisitable.accept(visitor);
  });
  visitor.post();
  return res;
}
