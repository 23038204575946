import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';

import { Quote } from '@pladdenico/models';

import { Finance } from '../../../../../utils/finance/holding';
import { Period } from '../../../../../utils/period/period';

interface Props {
  holdingId: string;
  period: Period;
  useLiveValue: boolean;
}

export const getHoldingAbsoluteReturns = createCachedSelector(
  (state: WorkspaceDataState, props: Props) =>
    getValuationsByHoldingId(state, props.holdingId),
  (state: WorkspaceDataState, props: Props) =>
    getTransfersByHoldingId(state, props.holdingId),
  (_state: WorkspaceDataState, props: Props) => props.period,
  (valuations, transfers, period) => {
    const quotes: Quote[] = [];
    return Finance.absoluteReturns(
      valuations,
      transfers,
      period.start.toDate(),
      period.end.toDate(),
      quotes,
    );
  },
)({
  keySelector: (_state: WorkspaceDataState, props: Props) =>
    `${props.holdingId}:${props.period.id()}:${props.useLiveValue}`,
  selectorCreator: createSelector,
});
