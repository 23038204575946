import { useParams } from 'react-router-dom';
import React from 'react';
import { WorkspaceSearchComponent } from '~src/domain/workspace/components/search/workspace-search-component';

export const TopBarSearchComponent = React.memo(() => {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();

  const searchComponent =
    tenantId && workspaceId ? (
      <WorkspaceSearchComponent tenantId={tenantId} workspaceId={workspaceId} />
    ) : (
      <></>
    );

  return searchComponent;
});
