import {
  RegionStateVisitor,
  RegionVisitable,
} from '~src/data/store/visitors/common/region-visitor';
import { Region } from '~src/services/graphql/user/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleRegions(dispatch: AppDispatch, outputs: Region[]) {
  const visitor = new RegionStateVisitor(dispatch);
  outputs.forEach((region) => {
    const visitable = new RegionVisitable(region);
    visitable.accept(visitor);
  });
  visitor.post();
  return outputs;
}
