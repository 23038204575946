import React from 'react';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { ElementProps } from '~src/components/dashboard/element';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { OrganizationComponent } from '~src/domain/workspace/components/project/organization/organization.component';
import { useMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/organization/use-menu.hook';
import { Config } from '~src/utils/interfaces/config';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  config: Config;
}

export interface OrganizationData {
  organizationId: string;
}

export const OrganizationElement = React.memo(
  ({
    workspace,
    project,
    config,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const organizationData = element.config.data
      ? (element.config.data as OrganizationData)
      : undefined;

    const { menu, handleOpen, isOpen } = useMenu(element, remove, updated);
    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? '#000000',
          backgroundColor: element.config.backgroundColor ?? '#ffffff',
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <OrganizationComponent
            workspace={workspace}
            project={project}
            organizationId={organizationData?.organizationId}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
