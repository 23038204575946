import {
  fetchQuotes,
  handleDeleteQuotes,
} from '~src/data/store/modules/finance/quotes/requests';
import { apis } from '~src/services/request/apis';

import { Operation, paperQuotePath } from '@pladdenico/portfolio-api';

export function subscribeToPaperQuotes(
  dispatch: any,
  paperId: string,
  subscriptions: Operation[],
) {
  subscriptions.forEach((operation) => {
    if (operation === Operation.update || operation === Operation.upsert) {
      apis.finance.subscribe(
        {
          path: paperQuotePath().objectPath(paperId, operation),
          handle: (res: { id: string }) => {
            dispatch(fetchQuotes(paperId, [res.id]));
          },
        },
        () => {
          // console.log('subscribed to goalRule', operation, paperId);
        },
      );
    } else if (operation === Operation.create) {
      apis.finance.subscribe(
        {
          path: paperQuotePath().domainPath(operation),
          handle: (res: { id: string }) => {
            dispatch(fetchQuotes(paperId, [res.id]));
          },
        },
        () => {
          // console.log('subscribed to create quote', operation, paperId);
        },
      );
    } else if (operation === Operation.delete) {
      apis.finance.subscribe(
        {
          path: paperQuotePath().domainPath(operation),
          handle: (res: { id: string; date: Date }) => {
            const quoteId = { id: res.id, paperId, date: res.date };
            dispatch(handleDeleteQuotes([quoteId]));
          },
        },
        () => {
          // console.log('subscribed to goalRule', operation, paperId);
        },
      );
    }
  });
}
