// import { PhoneType } from '@pladdenico/models';
import { Maybe } from 'graphql/jsutils/Maybe';
import { pick } from 'lodash';
import { upsertPhones } from '~src/data/store/modules/people/phones/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'peoplePhones';

export interface Phone {
  id: string;
  value?: Maybe<string>;
  personId: string;
}
const header: Array<keyof Phone> = ['id', 'value', 'personId'];

export const picker = (one: any): Phone => {
  return pick(one, header);
};

export const phoneExportHandler = (data: Phone[]): ExportHandler<Phone> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const phoneImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Phone> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertPhones(workspace.tenantId, workspace.id, ts)),
  };
};
