import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';

import {
  defaultBackgroundColor,
  defaultFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/efficient-frontier/default-colors';
import { EfficientFrontierComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/efficient-frontier/efficient-frontier.component';
import { useEfficientFrontierMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/efficient-frontier/menu.component';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  period: Period;
}

const getDefaultFontColor = () => {
  return defaultFontColor;
};

const getDefaultBackgroundColor = () => {
  return defaultBackgroundColor;
};

export const EfficientFrontierElement = React.memo(
  ({
    workspace,
    portfolioProject,
    holdings,
    config,
    period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useEfficientFrontierMenu(
      element,
      remove,
      updated,
    );

    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), {
        id: baseCurrencyId,
      }),
    );
    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }
    const color = element.config.color ?? getDefaultFontColor();
    const backgroundColor =
      element.config.backgroundColor ?? getDefaultBackgroundColor();

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color,
          backgroundColor,
          padding: element.config.padding,
        },
      };
    }, [backgroundColor, color, element.config.padding, isOpen]);

    const format = React.useMemo(() => {
      return {
        color,
        backgroundColor,
        // textColor,
      };
    }, [backgroundColor, color]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <EfficientFrontierComponent
            workspace={workspace}
            portfolioProject={portfolioProject}
            period={period}
            valuationDate={config.date}
            holdings={holdings}
            baseCurrency={baseCurrency}
            format={format}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
