import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { ValuationState } from '~src/data/store/reducers/workspace/projects/scenario/valuations/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getHoldingsValuation } from '~src/data/store/selectors/finance/valuation/holdings-valuation';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { getHoldingsByIdsForProject } from '~src/data/store/selectors/holding/holdings/selectors';
import {
  selectData,
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getTransfersByBudgetIdAndHoldingIds } from '~src/data/store/selectors/workspace/projects/scenario/transfers/selectors';
import { LiquidityChartComponent } from '~src/domain/workspace/components/project/scenario/charts/liquidity-chart.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { CashFlow } from '~src/domain/workspace/components/project/scenario/models/cash-flow';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { transferSign } from '~src/utils/finance/transfer-sign';
import { Config } from '~src/utils/interfaces/config';

import { HoldingType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  budget: Budget;
  config: Config;
  useLiveValue: boolean;
  // valuations: ValuationState[];
}

export const HoldingScenarioLiquidityComponent = React.memo((props: Props) => {
  const { workspace, project, config, budget } = props;

  const baseCurrencyId = useSelector((state: RootState) =>
    getSelectedCurrencyState(selectFinancialData(state)),
  );
  const baseCurrency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), {
      id: baseCurrencyId,
    }),
  );
  const currency = baseCurrency ?? defaultCurrency;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const holdings = useSelector((state: RootState) => {
    return getHoldingsByIdsForProject(selectWorkspaceData(state), {
      projectId: project.id,
      ids: budget.holdingIds,
    });
  });

  const bankNoteHoldings = React.useMemo(() => {
    return holdings.filter((h) => h.type === HoldingType.BankNote);
  }, [holdings]);

  // const budgetTransfers = useSelector((state: RootState) =>
  //   getTransfersByBudgetId(selectWorkspaceData(state), budget.id)
  // );

  const budgetTransfers = useSelector((state: RootState) =>
    getTransfersByBudgetIdAndHoldingIds(selectWorkspaceData(state), {
      budgetId: budget.id,
      holdingIds: budget.holdingIds,
    }),
  );

  const bankNoteHoldingValuations = useSelector((state: RootState) =>
    getHoldingsValuation(selectData(state), {
      date: config.date,
      filter,
      holdings: bankNoteHoldings,
      projectId: project.id,
      useLiveValue: true,
      workspaceId: workspace.id,
      currency,
    }),
  );

  const valuations: ValuationState[] = React.useMemo(() => {
    return compact(
      bankNoteHoldingValuations.map((v) => {
        const valuation = v.valuation;
        if (valuation) {
          // set date for all valuations
          return {
            ...valuation,
            id: uuid.v1(),
            budgetId: budget?.id ?? '',
            date: config.date,
            holdingId: v.holding.id,
          };
        }
      }),
    );
  }, [budget?.id, config.date, bankNoteHoldingValuations]);

  const initialValue = React.useMemo(() => {
    const value = valuations.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    return {
      value,
      date: config.date,
    };
  }, [config.date, valuations]);

  // const scenarioTransfers = budgetTransfers;
  // const filteredTransfers = scenarioTransfers?.filter((transfer) => {
  //   const holding = budgetHoldings?.find((h) => h.id === transfer.holdingId);
  //   if (holding && holding.type === HoldingType.BankNote) {
  //     return true;
  //   }
  //   return false;
  // });
  // const transferCashFlows = filteredTransfers?.map((transfer): ICashFlow => {
  const transferCashFlows = budgetTransfers?.map((transfer): CashFlow => {
    return {
      date: transfer.date,
      holdingId: transfer.holdingId,
      transferType: transfer.type,
      value: transfer.value,
    };
  });
  const cashFlowsBudgeted = [...(transferCashFlows ?? [])];
  cashFlowsBudgeted.sort((a, b) => a.date.unix() - b.date.unix());

  const values = [initialValue];
  for (let i = 0; i < cashFlowsBudgeted.length; ++i) {
    const lastValue = values.at(-1);
    const cashFlow = cashFlowsBudgeted[i];
    console.log(
      'NICO2',
      cashFlow.value,
      cashFlow.transferType,
      transferSign(cashFlow.transferType),
    );
    const value = transferSign(cashFlow.transferType) * cashFlow.value;
    if (lastValue) {
      if (lastValue.date.unix() < cashFlow.date.unix()) {
        values.push({
          value: lastValue.value + value,
          date: cashFlow.date,
        });
      } else {
        lastValue.value = lastValue.value + value;
      }
    }
  }

  // const cashFlowsElement = cashFlowsBudgeted.map((source, idx) => {
  //   return (
  //     <div key={idx}>
  //       {source.date.format('DD.MM.YYYY')} {source.transferType}{' '}
  //       {source.value * transferSign(source.transferType)}
  //     </div>
  //   );
  // });
  // const cashElement = transferCashFlows && <>{cashFlowsElement}</>;

  const container = (
    <>
      <LiquidityChartComponent
        workspace={workspace}
        projectId={project.id}
        liquidity={values}
      />
      {/* {cashElement} */}
    </>
  );
  return container;
});
