import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { TextField } from '@mui/material';

export const useNumberField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  label: string,
  defaultValue: PathValue<TFieldValues, TName>,
  rules?: { required?: boolean },
) => {
  return (
    <Controller
      name={tname}
      rules={{ required: rules?.required ?? false }}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        console.log('fieldState.error', fieldState.error);

        return (
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            required={rules?.required ?? false}
            value={field.value}
            label={label}
            type="number"
            onChange={field.onChange}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
          />
        );
      }}
    />
  );
};
