import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

export type SelectedCurrencyState = string;

export function selectedCurrencyToSelectedCurrencyState(
  selectedCurrency: SelectedCurrencyState,
) {
  return selectedCurrency;
}
export const initialSelectedCurrencyState = null;

const adapter = new EntityAdapter<string>(initialSelectedCurrencyState);

export const selectedCurrenciesSlice = createBaseSlice(
  'selectedCurrencies',
  adapter,
);
export const selectedCurrenciesSelectors = adapter.selectors;

export const selectedCurrenciesReducer = selectedCurrenciesSlice.reducer;
export const selectedCurrenciesActions = selectedCurrenciesSlice.actions;

// export const selectedCurrencyInitialState = {
//   selectedCurrencies: initialState,
// };
