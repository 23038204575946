import { workspaceProjectRootPath } from '../../paths';

export const transactionsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/transactions`;
};

export const transactionBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  transactionId: string,
) => {
  return `${transactionsBasePath(
    tenantId,
    workspaceId,
    projectId,
  )}/${transactionId}`;
};
