import { memo } from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { SetupGroupsComponent } from '~src/domain/workspace/components/group/setup-groups.component';
import { WorkspaceRolesComponent } from '~src/domain/workspace/components/workspace-role/workspace-roles.component';
import { EditDialogComponent } from '~src/domain/workspace/components/workspace/form/edit-dialog.component';
import { useWorkspaceSettingsMenu } from '~src/domain/workspace/components/workspace/setup/use-menu';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import MoreIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
}

export const WorkspaceSettingsComponent = memo((props: Props) => {
  const { workspace } = props;
  const { close, open, isShowing } = useVisible();

  const menu = useWorkspaceSettingsMenu(workspace);

  const currentWorkspaceElement = (
    <>
      <Button onClick={open}>Edit workspace</Button>
      <IconButton
        size={'small'}
        // ref={drag}
        onClick={menu.handleOpen}
        // onTouchEnd={openOptionsMenu}
        // sx={{
        //   visibility: hover || active ? 'visible' : 'hidden',
        //   color: config?.format?.color,
        // }}
      >
        <MoreIcon />
      </IconButton>
      {menu.menu}
      <EditDialogComponent
        handleClose={close}
        open={isShowing}
        workspace={props.workspace}
      />

      <Typography variant="h4">Roles</Typography>
      <WorkspaceRolesComponent />
      <Typography variant="h4">Portfolios</Typography>
      <SetupGroupsComponent />
    </>
  );

  return <div>{currentWorkspaceElement}</div>;
});
