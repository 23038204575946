import { upsertStockTrades } from '~src/data/store/modules/holdings/stock/trade/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

export interface StockTrade {
  id: string;
  transferId: string;
  positionId: string;
  stockHoldingId: string;
  shares: number;
  sharePrice: number;
  date?: Date;
}

const header: Array<keyof StockTrade> = [
  'id',
  'transferId',
  'positionId',
  'stockHoldingId',
  'date',
  'shares',
  'sharePrice',
];

const name = 'stockTrades';

export const stockTradeExportHandler = (
  data: StockTrade[],
): ExportHandler<StockTrade> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const stockTradeImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<StockTrade> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertStockTrades(workspace.tenantId, workspace.id, ts)),
  };
};
