import * as d3 from 'd3';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
  width: number;
  height: number;
  chartId: string;
  data: any;
}

interface State {
  width: number;
  height: number;
  data: any;
  initialized: boolean;
  zoomTransform: any;
  groupItemWidth: number;
  groupHeight: number;
}

export class Graph extends React.Component<Props, State> {
  private svgRef: any;

  constructor(props: Props) {
    super(props);

    const groupItemWidth = 150;
    const groupHeight = 150;

    const data = this.updateData(props.data, groupItemWidth, groupHeight);

    this.state = {
      width: props.width,
      height: props.height,
      data,
      groupItemWidth,
      groupHeight,
      initialized: false,
      zoomTransform: d3.zoomIdentity,
    };
  }

  private updateData(data: any, groupItemWidth: number, groupHeight: number) {
    data.nodes = data.nodes.sort((a: any, b: any) => {
      return a.group - b.group;
    });

    const groupSizes: number[] = [];

    let lastGroupId: any;
    data.nodes.forEach((node: any) => {
      if (lastGroupId !== node.group) {
        lastGroupId = node.group;
        groupSizes.push(0);
      }
      ++groupSizes[groupSizes.length - 1];
    });

    const maxGroupSize = groupSizes.reduce((maxGroupSize, currentValue) => {
      return Math.max(currentValue, maxGroupSize);
    }, 0);

    let fy = 0;
    let idx = 0;
    for (const groupSize of groupSizes) {
      let startFx = 0;
      startFx = (groupItemWidth * (maxGroupSize - groupSize)) / 2;
      fy += groupHeight;
      let groupIndex = 0;
      for (let j = 0; j < groupSize; ++j) {
        ++groupIndex;
        data.nodes[idx].fx = startFx + groupIndex * groupItemWidth;
        data.nodes[idx].fy = fy;
        ++idx;
      }
    }
    return data;
  }

  public UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.data !== this.props.data) {
      const data = this.updateData(
        props.data,
        this.state.groupItemWidth,
        this.state.groupHeight,
      );
      this.setState({ data, initialized: false });
    }
  }

  private setupDrag = () => {
    const drag = d3.drag<any, any, any>().on('drag', (event: any, d: any) => {
      const nodes = this.state.data.nodes;
      const nodeIdx = nodes.findIndex((node: any) => {
        return node.id === d.id;
      });

      const node = nodes[nodeIdx];
      node.fx += event.dx;
      node.fy += event.dy;

      this.setState({
        data: {
          nodes: [
            ...nodes.slice(0, nodeIdx),
            node,
            ...nodes.slice(nodeIdx + 1),
          ],
          links: this.state.data.links,
        },
      });
    });
    // eslint-disable-next-line react/no-find-dom-node
    const svg = d3.select(ReactDOM.findDOMNode(this.svgRef) as d3.BaseType);
    svg.call(
      d3
        .zoom<any, any>()
        .scaleExtent([0.5, 40])
        .on('zoom', (event) => {
          this.setState({ zoomTransform: event.transform });
        }),
    );
    svg.selectAll('.node').data(this.state.data.nodes).call(drag);
  };

  // mixins:[resizeMixin]
  public componentDidUpdate() {
    if (!this.state.initialized) {
      this.setupDrag();
      this.setState({ initialized: true });
    }
  }

  public componentDidMount() {
    if (!this.state.initialized) {
      this.setupDrag();
      this.setState({ initialized: true });
    }
  }

  public render() {
    const graphNodes = this.state.data.nodes.map((node: any, index: number) => {
      const hasChildren = this.state.data.links.some((link: any) => {
        return link.source === node.id;
      });

      const circle = hasChildren ? (
        <circle
          className="dot"
          r="7"
          cx={node.fx}
          cy={node.fy + 25}
          fill="#7dc7f4"
          stroke="#3f5175"
          strokeWidth="5px"
        />
      ) : undefined;

      const nodeElement = (
        <g key={index}>
          <g className="node">
            <rect
              fill="#6391da"
              opacity=".9"
              x={node.fx - 65}
              y={node.fy - 25}
              width="130"
              height="50"
            />
            <text
              x={node.fx}
              y={node.fy}
              fontSize={14}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {node.name}
            </text>
          </g>
          {circle}
        </g>
      );
      return nodeElement;
    });
    const graphLinks = this.state.data.links.map((link: any, index: number) => {
      const sourceNode = this.state.data.nodes.find((node: any) => {
        return node.id === link.source;
      });
      const targetNode = this.state.data.nodes.find((node: any) => {
        return node.id === link.target;
      });
      if (!sourceNode || !targetNode) {
        return undefined;
      }
      return (
        <g key={index}>
          <line
            key={index}
            x1={sourceNode.fx}
            x2={targetNode.fx}
            y1={sourceNode.fy + 25}
            y2={targetNode.fy - 25}
            strokeWidth={1}
            stroke={'black'}
          />
          <text
            x={(sourceNode.fx + targetNode.fx) / 2}
            y={(sourceNode.fy + targetNode.fy) / 2}
            fontSize={14}
          >
            {link.value}
          </text>
        </g>
      );
    });

    const ref = (n: any) => (this.svgRef = n);

    return (
      <div>
        <svg
          id={this.props.chartId}
          width={this.state.width}
          height={this.state.height}
          ref={ref}
        >
          <g transform={this.state.zoomTransform}>
            {graphLinks}
            {graphNodes}
          </g>
        </svg>
      </div>
    );
  }
}
