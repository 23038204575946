import {
  HoldingStateVisitor,
  HoldingVisitable,
  HoldingVisitor,
} from '~src/data/store/visitors/holding/holding-visitor';
import {
  TransactionStateVisitor,
  TransactionVisitable,
  TransactionVisitor,
} from '~src/data/store/visitors/holding/transaction-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import { parseProjectObj } from '~src/data/store/modules/workspaces/projects/base/project-obj.parser';
import { subscribeToProjects } from '~src/data/store/modules/workspaces/projects/base/subscription';
import {
  projectsActions,
  ProjectState,
} from '~src/data/store/reducers/workspace/projects/base/reducer';
import { ProjectObj } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface ProjectObjVisitor {
  visit(project: ProjectObjVisitable): void;
  post(): void;
  holdingVisitor: HoldingVisitor;
  transactionVisitor: TransactionVisitor;
}

export class ProjectObjVisitable implements Visitable<ProjectObjVisitor> {
  constructor(
    private _workspaceId: string,
    private _project: ProjectObj,
  ) {}
  public accept(visitor: ProjectObjVisitor) {
    if (this._project.holdings) {
      this._project.holdings.forEach((holding) => {
        const holdingVisitable = new HoldingVisitable(holding);
        holdingVisitable.accept(visitor.holdingVisitor);
      });
    }
    if (this._project.transactions) {
      this._project.transactions.forEach((transaction) => {
        const transactionVisitable = new TransactionVisitable(
          this._project.id,
          transaction,
        );
        transactionVisitable.accept(visitor.transactionVisitor);
      });
    }
    visitor.visit(this);
  }

  public parse(): ProjectState {
    return parseProjectObj(this._workspaceId, this._project);
  }
}

export class ProjectObjHandlerVisitor implements ProjectObjVisitor {
  constructor(
    private _handle: (holding: ProjectState) => void,
    public holdingVisitor: HoldingVisitor,
    public transactionVisitor: TransactionVisitor,
  ) {}
  public visit(holding: ProjectObjVisitable): void {
    this._handle(holding.parse());
  }
  public post() {
    return;
  }
}

export class ProjectObjStateVisitor implements ProjectObjVisitor {
  private _projects: ProjectState[];
  public holdingVisitor: HoldingVisitor;
  public transactionVisitor: TransactionVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._projects = [];
    this.holdingVisitor = new HoldingStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    this.transactionVisitor = new TransactionStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }

  public visit(holding: ProjectObjVisitable): void {
    this._projects.push(holding.parse());
  }
  post() {
    this._dispatch(projectsActions.upsertManyElements(this._projects));
    subscribeToProjects(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._projects,
      this._subscriptions,
    );
    this.transactionVisitor.post();
    this.holdingVisitor.post();
    return this._projects;
  }
}
