import { parseRegion } from '~src/data/store/modules/regions/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  regionsActions,
  RegionState,
} from '~src/data/store/reducers/common/regions/reducer';
import { Region } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

interface RegionVisitor {
  visit(region: RegionVisitable): void;
}

export class RegionVisitable implements Visitable<RegionVisitor> {
  constructor(private _region: Region) {}
  public accept(visitor: RegionVisitor) {
    visitor.visit(this);
  }

  public parse(): RegionState {
    return parseRegion(this._region);
  }
}

export class RegionStateVisitor implements RegionVisitor {
  private _regions: RegionState[];
  constructor(private _dispatch: AppDispatch) {
    this._regions = [];
  }
  public visit(medium: RegionVisitable): void {
    this._regions.push(medium.parse());
  }
  post() {
    this._dispatch(
      regionsActions.upsertManyElements(this._regions, {
        shouldAutobatch: true,
      }),
    );
  }
}
