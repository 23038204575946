import { useDispatch } from 'react-redux';
import {
  deleteInvestors,
  updateInvestors,
} from '~src/data/store/modules/assets/private-equity-fund/investors/requests';
import { InvestorState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

export function useInvestorActions(
  workspace: WorkspaceState,
  fund: PrivateEquityFundState,
) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (oldData: InvestorState, newData: InvestorState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceOperations.UpdatePrivateEquityFundInvestorInputType =
          {
            id: newData.id,
            description: newData.description ?? '',
            name: newData.name ?? '',
          };
        dispatch(
          updateInvestors(workspace.tenantId, workspace.id, fund.id, [input]),
        )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (oldData: InvestorState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        deleteInvestors(workspace.tenantId, workspace.id, fund.id, [oldData]),
      )
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
      if (oldData) {
        resolve();
      } else {
        reject();
      }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onRowUpdate, onRowDelete };
}
