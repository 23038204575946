import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

// export interface BaseSelectedWorkspaceRoleId {
//   id: number;
// }

export type SelectedWorkspaceRoleState = number;

export function selectedWorkspaceRoleToSelectedWorkspaceRoleState(
  selectedWorkspaceRole: SelectedWorkspaceRoleState,
) {
  return selectedWorkspaceRole;
}
export const initialSelectedWorkspaceRolesState = null;

const adapter = new EntityAdapter<number>(initialSelectedWorkspaceRolesState);

export const selectedWorkspaceRolesSlice = createBaseSlice(
  'selectedWorkspaceRoles',
  adapter,
);
export const selectedWorkspaceRolesSelectors = adapter.selectors;

export const selectedWorkspaceRolesReducer =
  selectedWorkspaceRolesSlice.reducer;
export const selectedWorkspaceRolesActions =
  selectedWorkspaceRolesSlice.actions;
