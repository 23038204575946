import {
  WorkspaceRoleStateVisitor,
  WorkspaceRoleVisitable,
} from '~src/data/store/visitors/workspace/role/workspace-role-visitor';
import { WorkspaceRole } from '~src/services/graphql/tenant/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleWorkspaceRoles(
  tenantId: string,
  dispatch: AppDispatch,
  outputs: WorkspaceRole[],
) {
  const workspaceRoleVisitor = new WorkspaceRoleStateVisitor(dispatch);
  const res = outputs.map((workspaceRole) => {
    const visitable = new WorkspaceRoleVisitable(tenantId, workspaceRole);
    return visitable.accept(workspaceRoleVisitor);
  });
  workspaceRoleVisitor.post();
  return res;
}
