import { parseUser } from '~src/data/store/modules/users/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  usersActions,
  UserState,
} from '~src/data/store/reducers/user/user/reducer';
import { User } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface UserVisitor {
  visit(user: UserVisitable): void;
  post(): void;
}

export class UserVisitable implements Visitable<UserVisitor> {
  constructor(private _user: User) {}
  public accept(visitor: UserVisitor) {
    visitor.visit(this);
  }

  public parse(): UserState {
    return parseUser(this._user);
  }
}

export class UserStateVisitor implements UserVisitor {
  private _users: UserState[];
  constructor(private _dispatch: AppDispatch) {
    this._users = [];
  }

  public visit(user: UserVisitable): void {
    this._users.push(user.parse());
  }

  post() {
    this._dispatch(usersActions.addMany(this._users));
  }
}
