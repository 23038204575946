import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useValuationCreateAction } from '~src/hooks/actions/workspace/project/holding/valuations/use-valuation-create-action';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CreateValuationFieldsComponent } from './fields/create-fields.component';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  valuationCreateInputInit: graphqlWorkspaceTypes.CreateValuationInputType;
  handleClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateValuationFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { valuationInput, setValuationInput, createValuation } =
    useValuationCreateAction(props.workspace, '');
  return (
    <>
      <DialogTitle id="form-dialog-title">Create valuation</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <CreateValuationFieldsComponent
            workspace={props.workspace}
            projectId={props.projectId}
            valuationInput={valuationInput}
            setValuationInput={setValuationInput}
            showId={true}
            showConfirmed={true}
            showHolding={true}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={createValuation(props.handleClose)} color="primary">
          Save
        </Button>
      </DialogActions>
    </>
  );
});
