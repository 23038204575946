import { variance } from 'mathjs';
// import { SMALast } from '@pladdenico/common';
import { createSelector } from 'reselect';
import { sampleCovariance, SMALast } from '@pladdenico/common';

import { Period } from '../../../../../utils/period/period';
import { getHoldingsReturns } from '../returns/holdings-returns';
import { getQuoteReturns } from '../returns/quote-returns';
import { Filter } from '../../../../../utils/common/filter';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { selectFinancialDataFromData } from '~src/data/store/selectors/selectors';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  period: Period;
  indexPaper: PaperState | undefined;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsBeta = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.indexPaper,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    period,
    indexPaper,
    filter,
    useLiveValue,
  ) => {
    const returns = getHoldingsReturns(state, {
      workspaceId,
      projectId,
      holdings,
      period,
      filter,
      useLiveValue,
    });

    const values = returns.map((value) => {
      return value.value;
    });
    const times = returns.map((value) => {
      return value.date.getTime();
    });
    const tau = 1;
    const res = SMALast(values, times, tau);
    if (res.length === 0) {
      return 0;
    }

    const dates = [
      period.start.toDate(),
      ...returns.map((ireturn) => ireturn.date),
    ];
    // const indexReturns = this.indexReturns(dates, indexPaperQuotes);
    const indexReturns = indexPaper
      ? getQuoteReturns(selectFinancialDataFromData(state), {
          dates,
          paper: indexPaper,
        })
      : [];

    if (indexReturns.length !== 0) {
      const indexVariance = variance(indexReturns, 'unbiased');
      if (indexVariance === 0) {
        return 0;
      }
      const covariance = sampleCovariance(indexReturns, res);
      const beta = covariance / (indexVariance as number);
      // console.log(indexVariance, covariance, beta);
      return beta;
    } else {
      return 0;
    }
  },
);
