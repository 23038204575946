import React from 'react';

import {
  // Breakpoint,
  Dialog,
  DialogProps as MuiDialogProps,
  ModalProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface DialogProps extends MuiDialogProps {
  onClose: () => void;
}

interface Props extends React.PropsWithChildren<unknown> {
  // onClose: () => void;
  closeOnBackDrop?: boolean;
  dialogProps: DialogProps;
}

export const DialogComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    // maxWidth,
    closeOnBackDrop,
    // onClose,
    // open,
    // fullWidth,
    children,
    dialogProps,
  } = props;

  const onCloseWithBackdropHandling: ModalProps['onClose'] = (
    _event,
    reason,
  ) => {
    if (closeOnBackDrop === false && reason && reason == 'backdropClick') {
      return;
    }
    dialogProps.onClose && dialogProps.onClose();
    // if (dialogProps?.onClose) {
    //   onClose();
    // }
    // handleClose();
  };
  return (
    <Dialog
      {...dialogProps}
      fullScreen={fullScreen}
      // fullWidth={fullWidth ?? true}
      // maxWidth={maxWidth == null ? 'md' : maxWidth}
      // open={open}
      onClose={onCloseWithBackdropHandling}
      aria-labelledby="form-dialog-title"
    >
      {children}
    </Dialog>
  );
});
