import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateProjects } from '~src/data/store/modules/workspaces/projects/base/requests';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectInputType } from '~src/domain/workspace/components/project/create.component';
import { useProjectFields } from '~src/domain/workspace/components/project/form/use-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  // inputData?: ProjectInputType;
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  };
  successCallback?: (project: ProjectState) => Promise<void>;
}

export type ProjectEdit = ReturnType<typeof useProjectEdit>;

export const useProjectEdit = (props: Props) => {
  const { workspace, project, callbacks, successCallback } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (projectInput: ProjectInputType) => {
      return dispatch(
        updateProjects(workspace.tenantId, workspace.id, [
          {
            id: project.id,
            ...projectInput,
          },
        ]),
      )
        .then((projects) => {
          const project = projects[0];
          callbacks?.onSuccess && callbacks.onSuccess();
          return project;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, project.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<ProjectInputType>({
    defaultValues: {
      name: project.name ?? undefined,
    },
  });

  const fields = useProjectFields(workspace, control);

  const onSubmit: SubmitHandler<ProjectInputType> = React.useCallback(
    (data) => {
      return onSave(data).then((project) => {
        if (successCallback) {
          return successCallback(project);
        }
      });
    },
    [onSave, successCallback],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields, control };
};
