import copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { EmailState } from '~src/data/store/reducers/person/emails/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailIcon from '@mui/icons-material/Email';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { deleteEmails } from '~src/data/store/modules/people/emails/requests';
import { EditEmailDialogComponent } from './form/edit-dialog.component';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
  email: EmailState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  link: {
    textDecoration: 'none',
  },
}));

export const EmailComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);
  const [snackbarInfo, setSnackbarInfo] = React.useState('');
  const [editEmailDialogOpened, setEditEmailDialogOpened] =
    React.useState(false);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [openRemove, setOpenRemove] = React.useState(false);

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setSnackbarInfo('Email removed');
    setOpenRemove(false);
    setOpen(true);
    handleMenuClose();
  };

  const handleSaveEdit = () => {
    setSnackbarInfo('Email saved');
    setOpen(true);
    handleMenuClose();
  };

  const snackBar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      message={snackbarInfo}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );

  const copy = () => {
    if (props.email.value) {
      if (copyToClipboard(props.email.value)) {
        setSnackbarInfo('Email copied');
        setOpen(true);
      }
    }
  };

  const remove = () => {
    dispatch(
      deleteEmails(props.workspace.tenantId, props.workspace.id, [
        {
          personId: props.email.personId,
          id: props.email.id,
        },
      ]),
    ).then(() => handleCloseRemove());
  };

  function handleCloseEditEmailDialog() {
    setEditEmailDialogOpened(false);
  }

  const removeDialog = (
    <Dialog
      open={openRemove}
      onClose={handleCloseRemove}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm removal</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove the email number?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRemove} color="primary">
          Disagree
        </Button>
        <Button onClick={remove} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );

  const emailMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={copy}>
        <ListItemIcon>
          <SaveAltIcon />
        </ListItemIcon>
        <ListItemText primary="Copy"></ListItemText>
      </MenuItem>
      <MenuItem
        component={Link}
        className={classes.link}
        // style={{ textDecoration: 'none' }}
        href={`tel:+${props.email.value}`}
        color="inherit"
      >
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Call"></ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary="Edit"
          onClick={() => setEditEmailDialogOpened(true)}
        ></ListItemText>
        <EditEmailDialogComponent
          open={editEmailDialogOpened}
          handleClose={handleCloseEditEmailDialog}
          savedCallback={handleSaveEdit}
          tenantId={props.workspace.tenantId}
          workspace={props.workspace}
          person={props.person}
          email={props.email}
        />
      </MenuItem>
      <MenuItem onClick={handleClickOpenRemove}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete"></ListItemText>
      </MenuItem>
      {/* <MenuItem onClick={handleAddHolding}>Call</MenuItem> */}
    </Menu>
  );

  return (
    <>
      <ListItemText primary={props.email.value} secondary={null} />
      <ListItemSecondaryAction>
        <IconButton onClick={handleMenuOpen} aria-label="email">
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
      {snackBar}
      {emailMenu}
      {removeDialog}
    </>
  );
});
