import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseFundHoldingId {
  holdingId: string;
  id: string;
}

export type FundHoldingState = Omit<
  graphqlWorkspaceTypes.FundHolding,
  'holding' | 'fund'
> & {
  holdingId: string;
  fundId: string;
};

export const initialFundHoldingState = { ts: [], idxs: [] };

const selectId = (fundHolding: BaseFundHoldingId) => fundHolding.id;
const selectIdx = (fundHolding: BaseFundHoldingId) => fundHolding.holdingId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: FundHoldingState, b: FundHoldingState) => b;

const adapter = new EntityIndexedAdapter<
  BaseFundHoldingId,
  FundHoldingState,
  string,
  string
>(
  initialFundHoldingState,
  selectId,
  selectIdx,
  merger,
  iComparator,
  idxComparator,
);

export const fundHoldingsSlice = createBaseIndexedSlice(
  'fundHoldings',
  adapter,
);
export const fundHoldingsSelectors = adapter.selectors;
export const fundHoldingsReducer = fundHoldingsSlice.reducer;
export const fundHoldingsActions = fundHoldingsSlice.actions;
