import { compact, sortedUniq } from 'lodash';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { GroupState } from '~src/data/store/modules/groups/state';
import { DataState, UserDataState } from '~src/data/store/reducers/reducers';
import {
  selectUserDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { getWorkspaceInvitationsByWorkspaceId } from '~src/data/store/selectors/workspace/workspace-invitations/selectors';

import { intersectionSorted } from '@pladdenico/common';
import { User } from '@pladdenico/models';

import { usersSelectors, UserState } from '../../../reducers/user/user/reducer';
import { getWorkspaceRolesByWorkspaceId } from '../../workspace/workspace-roles/selectors';
import { selectGroups } from '../group/selectors';

export const selectUsers = (state: UserDataState) => state.user.users;

export const getUserById = createCachedSelector(
  (state: UserDataState, _userId: number) => selectUsers(state),
  (_state: UserDataState, userId: number) => userId,
  (users, userId) => {
    return usersSelectors.selectElement(users, { id: userId });
  },
)({ keySelector: (_state, userId) => userId, selectorCreator: createSelector });

export const getUsersByIds = createCachedSelector(
  (state: UserDataState, _userIds: number[]) => selectUsers(state),
  (_state: UserDataState, userIds: number[]) => userIds,
  (users, userIds) => {
    return usersSelectors.selectElements(
      users,
      userIds.map((id) => {
        return { id };
      }),
    );
  },
)({
  keySelector: (_state, userIds) => userIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

// export const selectUserByName = createCachedSelector(
//   (state: UserDataState, _name: string) => selectUsers(state),
//   (_state: UserDataState, name: string) => name,
//   (users, name) => {
//     return users.find((user) => {
//       if (user.name) {
//         return user.name.replace(' ', '-') === name;
//       }
//       return false;
//     });
//   }
// )((state, name) => name);

const usersByGroupsSelector = (
  groups: GroupState[],
  users: UserState[],
): User[] => {
  let userIds = groups.reduce((prev: number[], next) => {
    prev.push(...next.userIds);
    return prev;
  }, []);
  userIds = sortedUniq(userIds.sort());
  return intersectionSorted(
    users,
    userIds,
    [(user, userId) => user.id - userId],
    (user, _userId) => user,
  );
};

export const getUsersByGroups = createCachedSelector(
  (state: UserDataState) => selectUsers(state),
  (state: UserDataState, groups: GroupState[]) => groups,
  (users, groups) => {
    return usersByGroupsSelector(groups, users);
  },
)({
  keySelector: (_state, groups) => groups,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getUsersByWorkspaceId = createCachedSelector(
  (state: DataState) => selectUsers(selectUserDataFromData(state)),
  (state: DataState, workspaceId: string) =>
    getWorkspaceRolesByWorkspaceId(
      selectWorkspaceDataFromData(state),
      workspaceId,
    ),
  (state: DataState) => selectGroups(selectUserDataFromData(state)),
  (users, workspaceRoles, groups) => {
    const groupsForRoles = compact(workspaceRoles.map((r) => r.groupId));
    const groupIds = groupsForRoles.sort((a, b) => a - b);
    const workspaceGroups = intersectionSorted(
      groupIds,
      groups,
      [(id, g) => id - g.id],
      (a, b) => b,
    );
    return usersByGroupsSelector(workspaceGroups, users);
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getUsersAndInvitationsByWorkspaceId = createCachedSelector(
  (state: DataState, workspaceId: string) =>
    getUsersByWorkspaceId(state, workspaceId),
  (state: DataState, workspaceId: string) =>
    getWorkspaceInvitationsByWorkspaceId(
      selectWorkspaceDataFromData(state),
      workspaceId,
    ),
  (users, invitations) => {
    const usersInvited = invitations.map((i) => {
      return {
        id: 0,
        email: i.email,
        name: i.email,
      };
    });
    return [...users, ...usersInvited];
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
