/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Address = {
  __typename?: 'Address';
  administrativeAreaLevel1?: Maybe<Scalars['String']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  postalCode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Allocation = {
  __typename?: 'Allocation';
  assetClass?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  id: Scalars['String'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  portfolio?: Maybe<Portfolio>;
  region?: Maybe<Region>;
  sector?: Maybe<Scalars['String']>;
};

export type Asset = {
  _type: Scalars['String'];
  asset?: Maybe<AssetObj>;
};

export type AssetCategory = {
  __typename?: 'AssetCategory';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type AssetObj = {
  __typename?: 'AssetObj';
  ISIN?: Maybe<Scalars['String']>;
  VPS?: Maybe<Scalars['String']>;
  assetClass?: Maybe<Scalars['String']>;
  category?: Maybe<AssetCategory>;
  description?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  id: Scalars['String'];
  lookup?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  types?: Maybe<Array<Scalars['String']>>;
};

export type Commitment = {
  __typename?: 'Commitment';
  commitmentDate?: Maybe<Scalars['Date']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  transfers?: Maybe<Array<Transfer>>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CreateCurrencyInputType = {
  country: Scalars['String'];
  id: Scalars['String'];
  symbol: Scalars['String'];
};

export type CreateRegionInputType = {
  id: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  type: Scalars['String'];
};

export type CreateTenantRoleInputType = {
  groupId: Scalars['Int'];
  name: Scalars['String'];
  permission: TenantPermissionInputType;
  tenantId: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type DashboardElement = {
  __typename?: 'DashboardElement';
  config: Scalars['String'];
  id: Scalars['String'];
  media?: Maybe<DashboardMedia>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type DashboardMedia = {
  __typename?: 'DashboardMedia';
  element?: Maybe<DashboardElement>;
  file?: Maybe<File>;
  id: Scalars['String'];
};

export type DeleteCurrencyInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteRegionInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  id: Scalars['String'];
  person?: Maybe<Person>;
  value?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Entity = {
  __typename?: 'Entity';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  media?: Maybe<Array<EntityMedia>>;
  name?: Maybe<Scalars['String']>;
  regionAllocations?: Maybe<Array<RegionAllocation>>;
  sector?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type EntityMedia = {
  __typename?: 'EntityMedia';
  entity?: Maybe<Entity>;
  file?: Maybe<File>;
  id: Scalars['String'];
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  baseCurrencyId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  quoteCurrencyId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
};

export type File = {
  __typename?: 'File';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type Holding = {
  commitments?: Maybe<Array<Commitment>>;
  holding: HoldingObj;
  transfers?: Maybe<Array<Transfer>>;
  valuations?: Maybe<Array<Valuation>>;
};

export type HoldingConfig = {
  __typename?: 'HoldingConfig';
  currentDashboardConfigId?: Maybe<Scalars['String']>;
  holdingDashboardConfigs?: Maybe<Array<HoldingDashboardConfig>>;
  id: Scalars['Int'];
};

export type HoldingDashboardConfig = {
  __typename?: 'HoldingDashboardConfig';
  compactType?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HoldingObj = {
  __typename?: 'HoldingObj';
  configs?: Maybe<Array<HoldingConfig>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectObj>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCurrencies?: Maybe<Array<Currency>>;
  createRegions?: Maybe<Array<Region>>;
  createTenantRole?: Maybe<TenantRole>;
  deleteCurrencies?: Maybe<Array<Scalars['String']>>;
  deleteRegions?: Maybe<Array<Scalars['String']>>;
  deleteTenantRole: Scalars['Int'];
  updateCurrencies?: Maybe<Array<Currency>>;
  updateRegions?: Maybe<Array<Region>>;
  updateTenantRole: TenantRole;
  upsertCurrencies?: Maybe<Array<Currency>>;
  upsertRegions?: Maybe<Array<Region>>;
  upsertTenantRole: TenantRole;
};

export type MutationCreateCurrenciesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateCurrencyInputType>>>;
};

export type MutationCreateRegionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateRegionInputType>>>;
};

export type MutationCreateTenantRoleArgs = {
  input?: InputMaybe<CreateTenantRoleInputType>;
};

export type MutationDeleteCurrenciesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteCurrencyInputType>>>;
};

export type MutationDeleteRegionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteRegionInputType>>>;
};

export type MutationDeleteTenantRoleArgs = {
  input?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateCurrenciesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateCurrencyInputType>>>;
};

export type MutationUpdateRegionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateRegionInputType>>>;
};

export type MutationUpdateTenantRoleArgs = {
  input?: InputMaybe<UpdateTenantRoleInputType>;
};

export type MutationUpsertCurrenciesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertCurrencyInputType>>>;
};

export type MutationUpsertRegionsArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertRegionInputType>>>;
};

export type MutationUpsertTenantRoleArgs = {
  input?: InputMaybe<UpsertTenantRoleInputType>;
};

export type Person = {
  __typename?: 'Person';
  addresses?: Maybe<Array<Address>>;
  description?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Email>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Phone>>;
  roles?: Maybe<Array<Role>>;
  workspace?: Maybe<Workspace>;
};

export type Phone = {
  __typename?: 'Phone';
  id: Scalars['String'];
  person?: Maybe<Person>;
  value?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  allocations?: Maybe<Array<Allocation>>;
  description?: Maybe<Scalars['String']>;
  holdings?: Maybe<Array<Holding>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  parentPortfolio?: Maybe<Portfolio>;
  portfolioConfigs?: Maybe<Array<PortfolioConfig>>;
  portfolios?: Maybe<Array<Portfolio>>;
};

export type PortfolioConfig = {
  __typename?: 'PortfolioConfig';
  currentDashboardConfigId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  portfolioDashboardConfigs?: Maybe<Array<PortfolioDashboardConfig>>;
};

export type PortfolioDashboardConfig = {
  __typename?: 'PortfolioDashboardConfig';
  compactType?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<DashboardElement>>;
  id: Scalars['String'];
  layouts?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Project = {
  project: ProjectObj;
};

export type ProjectObj = {
  __typename?: 'ProjectObj';
  holdings?: Maybe<Array<Holding>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Transaction>>;
  type?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type Query = {
  __typename?: 'Query';
  getCurrencies?: Maybe<Array<Currency>>;
  getExchangeRates?: Maybe<Array<ExchangeRate>>;
  getGroups?: Maybe<Array<Maybe<Group>>>;
  getRegions?: Maybe<Array<Region>>;
  getTenantRoles?: Maybe<Array<TenantRole>>;
  getUserWorkspaceRoles?: Maybe<Array<Maybe<WorkspaceRole>>>;
  hello?: Maybe<Scalars['String']>;
};

export type QueryGetCurrenciesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetExchangeRatesArgs = {
  baseCurrencyId: Scalars['String'];
  endPeriod: Scalars['Date'];
  frequency: Scalars['String'];
  quoteCurrencyId: Scalars['String'];
  startPeriod: Scalars['Date'];
};

export type QueryGetRegionsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetTenantRolesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RegionAllocation = {
  __typename?: 'RegionAllocation';
  allocation?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  entity?: Maybe<Entity>;
  id: Scalars['String'];
  region?: Maybe<Region>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  workspace?: Maybe<Workspace>;
};

export type TenantBase = {
  __typename?: 'TenantBase';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TenantPermission = {
  __typename?: 'TenantPermission';
  addInvitations?: Maybe<Scalars['Boolean']>;
  addPortfolios?: Maybe<Scalars['Boolean']>;
  addTenantRoles?: Maybe<Scalars['Boolean']>;
  editPortfolios?: Maybe<Scalars['Boolean']>;
  editTenantProperties?: Maybe<Scalars['Boolean']>;
  remove?: Maybe<Scalars['Boolean']>;
  removeInvitations?: Maybe<Scalars['Boolean']>;
  removePortfolios?: Maybe<Scalars['Boolean']>;
  removeTenantRoles?: Maybe<Scalars['Boolean']>;
  updateInvitations?: Maybe<Scalars['Boolean']>;
  updateTenantRoles?: Maybe<Scalars['Boolean']>;
  viewPortfolios?: Maybe<Scalars['Boolean']>;
  viewTenantProperties?: Maybe<Scalars['Boolean']>;
};

export type TenantPermissionInputType = {
  addAssets: Scalars['Boolean'];
  addInvitations: Scalars['Boolean'];
  addPortfolios: Scalars['Boolean'];
  addProjects: Scalars['Boolean'];
  addTenantRoles: Scalars['Boolean'];
  editAssets: Scalars['Boolean'];
  editPortfolios: Scalars['Boolean'];
  editTenantProperties: Scalars['Boolean'];
  remove: Scalars['Boolean'];
  removeAssets: Scalars['Boolean'];
  removeInvitations: Scalars['Boolean'];
  removePortfolios: Scalars['Boolean'];
  removeProjects: Scalars['Boolean'];
  removeTenantRoles: Scalars['Boolean'];
  updateInvitations: Scalars['Boolean'];
  updateProjects: Scalars['Boolean'];
  updateTenantRoles: Scalars['Boolean'];
  viewAssets: Scalars['Boolean'];
  viewPortfolios: Scalars['Boolean'];
  viewProjects: Scalars['Boolean'];
  viewTenantProperties: Scalars['Boolean'];
  viewTenantRoles: Scalars['Boolean'];
};

export type TenantRole = {
  __typename?: 'TenantRole';
  group?: Maybe<Group>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<TenantPermission>;
  tenant?: Maybe<TenantBase>;
};

export type Transaction = {
  __typename?: 'Transaction';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  project?: Maybe<ProjectObj>;
  title?: Maybe<Scalars['String']>;
  transfers?: Maybe<Array<Transfer>>;
};

export type Transfer = {
  __typename?: 'Transfer';
  commitment?: Maybe<Commitment>;
  cost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  status?: Maybe<Scalars['Int']>;
  subtype?: Maybe<Scalars['String']>;
  transaction?: Maybe<Transaction>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type UpdateCurrencyInputType = {
  country?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
};

export type UpdateRegionInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateTenantRoleInputType = {
  groupId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<TenantPermissionInputType>;
  tenantId?: InputMaybe<Scalars['String']>;
};

export type UpsertCurrencyInputType = {
  country?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
};

export type UpsertRegionInputType = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpsertTenantRoleInputType = {
  groupId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permission?: InputMaybe<TenantPermissionInputType>;
  tenantId?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Valuation = {
  __typename?: 'Valuation';
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['Date']>;
  holding?: Maybe<HoldingObj>;
  id: Scalars['String'];
  ownershipPercentage?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  assets?: Maybe<Array<Asset>>;
  entities?: Maybe<Array<Entity>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Person>>;
  portfolios?: Maybe<Array<Portfolio>>;
  projects?: Maybe<Array<Project>>;
  tenant?: Maybe<TenantBase>;
  type?: Maybe<Scalars['String']>;
  workspaceRoles?: Maybe<Array<WorkspaceRole>>;
};

export type WorkspacePermission = {
  __typename?: 'WorkspacePermission';
  addInvitations?: Maybe<Scalars['Boolean']>;
  addPortfolios?: Maybe<Scalars['Boolean']>;
  addWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  editPortfolios?: Maybe<Scalars['Boolean']>;
  editWorkspaceProperties?: Maybe<Scalars['Boolean']>;
  remove?: Maybe<Scalars['Boolean']>;
  removeInvitations?: Maybe<Scalars['Boolean']>;
  removePortfolios?: Maybe<Scalars['Boolean']>;
  removeWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  updateInvitations?: Maybe<Scalars['Boolean']>;
  updateWorkspaceRoles?: Maybe<Scalars['Boolean']>;
  viewPortfolios?: Maybe<Scalars['Boolean']>;
  viewWorkspaceProperties?: Maybe<Scalars['Boolean']>;
};

export type WorkspaceRole = {
  __typename?: 'WorkspaceRole';
  group?: Maybe<Group>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<WorkspacePermission>;
  workspace?: Maybe<Workspace>;
};

export type GetCurrenciesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCurrenciesQuery = {
  __typename?: 'Query';
  getCurrencies?: Array<{
    __typename?: 'Currency';
    id: string;
    symbol?: string | null;
    country?: string | null;
  }> | null;
};

export type CreateCurrenciesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<CreateCurrencyInputType>>
    | InputMaybe<CreateCurrencyInputType>
  >;
}>;

export type CreateCurrenciesMutation = {
  __typename?: 'Mutation';
  createCurrencies?: Array<{
    __typename?: 'Currency';
    id: string;
    symbol?: string | null;
    country?: string | null;
  }> | null;
};

export type UpdateCurrenciesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpdateCurrencyInputType>>
    | InputMaybe<UpdateCurrencyInputType>
  >;
}>;

export type UpdateCurrenciesMutation = {
  __typename?: 'Mutation';
  updateCurrencies?: Array<{
    __typename?: 'Currency';
    id: string;
    symbol?: string | null;
    country?: string | null;
  }> | null;
};

export type UpsertCurrenciesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<UpsertCurrencyInputType>>
    | InputMaybe<UpsertCurrencyInputType>
  >;
}>;

export type UpsertCurrenciesMutation = {
  __typename?: 'Mutation';
  upsertCurrencies?: Array<{
    __typename?: 'Currency';
    id: string;
    symbol?: string | null;
    country?: string | null;
  }> | null;
};

export type DeleteCurrenciesMutationVariables = Exact<{
  inputs?: InputMaybe<
    | Array<InputMaybe<DeleteCurrencyInputType>>
    | InputMaybe<DeleteCurrencyInputType>
  >;
}>;

export type DeleteCurrenciesMutation = {
  __typename?: 'Mutation';
  deleteCurrencies?: Array<string> | null;
};

export type CurrencyFragment = {
  __typename?: 'Currency';
  id: string;
  symbol?: string | null;
  country?: string | null;
};

export type ExchangeRateFragment = {
  __typename?: 'ExchangeRate';
  id: string;
  value?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  baseCurrencyId?: string | null;
  quoteCurrencyId?: string | null;
};

export type GetExchangeRatesQueryVariables = Exact<{
  baseCurrencyId: Scalars['String'];
  quoteCurrencyId: Scalars['String'];
  startPeriod: Scalars['Date'];
  endPeriod: Scalars['Date'];
  frequency: Scalars['String'];
}>;

export type GetExchangeRatesQuery = {
  __typename?: 'Query';
  getExchangeRates?: Array<{
    __typename?: 'ExchangeRate';
    id: string;
    value?: number | null;
    startDate?: any | null;
    endDate?: any | null;
    baseCurrencyId?: string | null;
    quoteCurrencyId?: string | null;
  }> | null;
};

export type RegionFragment = {
  __typename?: 'Region';
  id: string;
  symbol?: string | null;
  name?: string | null;
  type?: string | null;
};

export type GetRegionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetRegionsQuery = {
  __typename?: 'Query';
  getRegions?: Array<{
    __typename?: 'Region';
    id: string;
    symbol?: string | null;
    name?: string | null;
    type?: string | null;
  }> | null;
};

export type CreateRegionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateRegionInputType>> | InputMaybe<CreateRegionInputType>
  >;
}>;

export type CreateRegionsMutation = {
  __typename?: 'Mutation';
  createRegions?: Array<{
    __typename?: 'Region';
    id: string;
    symbol?: string | null;
    name?: string | null;
    type?: string | null;
  }> | null;
};

export type UpdateRegionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateRegionInputType>> | InputMaybe<UpdateRegionInputType>
  >;
}>;

export type UpdateRegionsMutation = {
  __typename?: 'Mutation';
  updateRegions?: Array<{
    __typename?: 'Region';
    id: string;
    symbol?: string | null;
    name?: string | null;
    type?: string | null;
  }> | null;
};

export type UpsertRegionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertRegionInputType>> | InputMaybe<UpsertRegionInputType>
  >;
}>;

export type UpsertRegionsMutation = {
  __typename?: 'Mutation';
  upsertRegions?: Array<{
    __typename?: 'Region';
    id: string;
    symbol?: string | null;
    name?: string | null;
    type?: string | null;
  }> | null;
};

export type DeleteRegionsMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteRegionInputType>> | InputMaybe<DeleteRegionInputType>
  >;
}>;

export type DeleteRegionsMutation = {
  __typename?: 'Mutation';
  deleteRegions?: Array<string> | null;
};

export type GroupFragment = {
  __typename?: 'Group';
  id?: number | null;
  name?: string | null;
  users?: Array<{
    __typename?: 'User';
    id?: number | null;
    name?: string | null;
    email?: string | null;
  } | null> | null;
};

export type GetGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGroupsQuery = {
  __typename?: 'Query';
  getGroups?: Array<{
    __typename?: 'Group';
    id?: number | null;
    name?: string | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    } | null> | null;
  } | null> | null;
};

export type HelloQueryVariables = Exact<{ [key: string]: never }>;

export type HelloQuery = { __typename?: 'Query'; hello?: string | null };

export type TenantPermissionFragment = {
  __typename?: 'TenantPermission';
  addPortfolios?: boolean | null;
  editPortfolios?: boolean | null;
  removePortfolios?: boolean | null;
  viewPortfolios?: boolean | null;
  viewTenantProperties?: boolean | null;
  editTenantProperties?: boolean | null;
  addTenantRoles?: boolean | null;
  updateTenantRoles?: boolean | null;
  removeTenantRoles?: boolean | null;
  addInvitations?: boolean | null;
  updateInvitations?: boolean | null;
  removeInvitations?: boolean | null;
  remove?: boolean | null;
};

export type TenantRoleFragment = {
  __typename?: 'TenantRole';
  id: number;
  name?: string | null;
  permission?: {
    __typename?: 'TenantPermission';
    addPortfolios?: boolean | null;
    editPortfolios?: boolean | null;
    removePortfolios?: boolean | null;
    viewPortfolios?: boolean | null;
    viewTenantProperties?: boolean | null;
    editTenantProperties?: boolean | null;
    addTenantRoles?: boolean | null;
    updateTenantRoles?: boolean | null;
    removeTenantRoles?: boolean | null;
    addInvitations?: boolean | null;
    updateInvitations?: boolean | null;
    removeInvitations?: boolean | null;
    remove?: boolean | null;
  } | null;
  group?: {
    __typename?: 'Group';
    id?: number | null;
    name?: string | null;
    users?: Array<{
      __typename?: 'User';
      id?: number | null;
      name?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
  tenant?: { __typename?: 'TenantBase'; id: string } | null;
};

export type GetTenantRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantRolesQuery = {
  __typename?: 'Query';
  getTenantRoles?: Array<{
    __typename?: 'TenantRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'TenantPermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewTenantProperties?: boolean | null;
      editTenantProperties?: boolean | null;
      addTenantRoles?: boolean | null;
      updateTenantRoles?: boolean | null;
      removeTenantRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    tenant?: { __typename?: 'TenantBase'; id: string } | null;
  }> | null;
};

export type CreateTenantRoleMutationVariables = Exact<{
  input: CreateTenantRoleInputType;
}>;

export type CreateTenantRoleMutation = {
  __typename?: 'Mutation';
  createTenantRole?: {
    __typename?: 'TenantRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'TenantPermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewTenantProperties?: boolean | null;
      editTenantProperties?: boolean | null;
      addTenantRoles?: boolean | null;
      updateTenantRoles?: boolean | null;
      removeTenantRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    tenant?: { __typename?: 'TenantBase'; id: string } | null;
  } | null;
};

export type UpdateTenantRoleMutationVariables = Exact<{
  input: UpdateTenantRoleInputType;
}>;

export type UpdateTenantRoleMutation = {
  __typename?: 'Mutation';
  updateTenantRole: {
    __typename?: 'TenantRole';
    id: number;
    name?: string | null;
    permission?: {
      __typename?: 'TenantPermission';
      addPortfolios?: boolean | null;
      editPortfolios?: boolean | null;
      removePortfolios?: boolean | null;
      viewPortfolios?: boolean | null;
      viewTenantProperties?: boolean | null;
      editTenantProperties?: boolean | null;
      addTenantRoles?: boolean | null;
      updateTenantRoles?: boolean | null;
      removeTenantRoles?: boolean | null;
      addInvitations?: boolean | null;
      updateInvitations?: boolean | null;
      removeInvitations?: boolean | null;
      remove?: boolean | null;
    } | null;
    group?: {
      __typename?: 'Group';
      id?: number | null;
      name?: string | null;
      users?: Array<{
        __typename?: 'User';
        id?: number | null;
        name?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
    tenant?: { __typename?: 'TenantBase'; id: string } | null;
  };
};

export type DeleteTenantRoleMutationVariables = Exact<{
  input: Scalars['Int'];
}>;

export type DeleteTenantRoleMutation = {
  __typename?: 'Mutation';
  deleteTenantRole: number;
};

export type UserFragment = {
  __typename?: 'User';
  id?: number | null;
  name?: string | null;
  email?: string | null;
};

export const CurrencyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Currency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Currency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrencyFragment, unknown>;
export const ExchangeRateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExchangeRate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExchangeRate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseCurrencyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quoteCurrencyId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExchangeRateFragment, unknown>;
export const RegionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Region' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Region' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegionFragment, unknown>;
export const TenantPermissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantPermissionFragment, unknown>;
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>;
export const GroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupFragment, unknown>;
export const TenantRoleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantRoleFragment, unknown>;
export const GetCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Currency' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Currency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Currency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const CreateCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCurrencyInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Currency' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Currency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Currency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCurrenciesMutation,
  CreateCurrenciesMutationVariables
>;
export const UpdateCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCurrencyInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Currency' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Currency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Currency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCurrenciesMutation,
  UpdateCurrenciesMutationVariables
>;
export const UpsertCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertCurrencyInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Currency' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Currency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Currency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertCurrenciesMutation,
  UpsertCurrenciesMutationVariables
>;
export const DeleteCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCurrencyInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCurrenciesMutation,
  DeleteCurrenciesMutationVariables
>;
export const GetExchangeRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExchangeRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'baseCurrencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteCurrencyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startPeriod' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endPeriod' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'frequency' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getExchangeRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseCurrencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'baseCurrencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteCurrencyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteCurrencyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startPeriod' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startPeriod' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endPeriod' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endPeriod' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'frequency' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'frequency' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExchangeRate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExchangeRate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExchangeRate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseCurrencyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quoteCurrencyId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetExchangeRatesQuery,
  GetExchangeRatesQueryVariables
>;
export const GetRegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRegions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRegions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Region' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Region' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Region' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRegionsQuery, GetRegionsQueryVariables>;
export const CreateRegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createRegions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateRegionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRegions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Region' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Region' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Region' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRegionsMutation,
  CreateRegionsMutationVariables
>;
export const UpdateRegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRegions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRegionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRegions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Region' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Region' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Region' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRegionsMutation,
  UpdateRegionsMutationVariables
>;
export const UpsertRegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertRegions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertRegionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRegions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Region' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Region' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Region' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertRegionsMutation,
  UpsertRegionsMutationVariables
>;
export const DeleteRegionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRegions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteRegionInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRegions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRegionsMutation,
  DeleteRegionsMutationVariables
>;
export const GetGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGroupsQuery, GetGroupsQueryVariables>;
export const HelloDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Hello' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hello' } }],
      },
    },
  ],
} as unknown as DocumentNode<HelloQuery, HelloQueryVariables>;
export const GetTenantRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTenantRoles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTenantRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTenantRolesQuery, GetTenantRolesQueryVariables>;
export const CreateTenantRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTenantRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTenantRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTenantRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTenantRoleMutation,
  CreateTenantRoleMutationVariables
>;
export const UpdateTenantRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTenantRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTenantRoleInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTenantRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantRole' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantPermission' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantPermission' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editPortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removePortfolios' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewPortfolios' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewTenantProperties' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editTenantProperties' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'addTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeTenantRoles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeInvitations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remove' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Group' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantRole' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TenantRole' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TenantPermission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Group' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTenantRoleMutation,
  UpdateTenantRoleMutationVariables
>;
export const DeleteTenantRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTenantRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTenantRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTenantRoleMutation,
  DeleteTenantRoleMutationVariables
>;
