import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';

export const drawerListItemButtonSX = {
  '&.Mui-selected': {
    backgroundColor: primaryBackgroundColor.light,
    '&:hover': {
      backgroundColor: primaryBackgroundColor.hoverMedium,
    },
  },
  '&:hover': {
    backgroundColor: primaryBackgroundColor.hoverLight,
  },
};
