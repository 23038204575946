import * as React from 'react';
import { EditHolding } from '~src/components/fields/workspace/holding/holding/edit-holding';
import { ViewHoldingField } from '~src/components/fields/workspace/holding/holding/view-holding-field';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';

import { TableField } from '@pladdenico/table';

export function useHoldingField<T>(
  getHoldingId: (data: T) => string,
  update: ((data: T, holding: HoldingState) => T) | undefined,
  workspace: WorkspaceState,
  projectId: string,
): TableField.Field<T> {
  return {
    id: 'holding',
    // filterable: true,
    // direction: 'asc',
    // sorted: false,
    name: 'holding',
    field: 'holdingId',
    type: TableField.FieldTypes.string,
    iteratee: (data) => {
      const holding = getHoldingById(selectWorkspaceData(store.getState()), {
        projectId,
        id: getHoldingId(data),
      });
      return holding && holding.name ? holding.name : '';
    },
    update: update
      ? (data, holding: HoldingState) => {
          return update(data, holding);
        }
      : undefined,
    renderView: (editProps) => {
      return (
        <ViewHoldingField
          projectId={projectId}
          holdingId={getHoldingId(editProps.data)}
        />
      );
    },
    renderEdit: update
      ? (editProps) => {
          const holding = getHoldingById(
            selectWorkspaceData(store.getState()),
            {
              projectId,
              id: getHoldingId(editProps.data),
            },
          );
          return (
            <EditHolding
              handleValue={editProps.onChange}
              projectId={projectId}
              holding={holding}
              // holdingTypes={}
              workspace={workspace}
            />
          );
        }
      : undefined,
  };
}
