import React from 'react';
import { FileComponent } from '~src/components/utils/files/file.component';
import { FileState } from '~src/data/store/reducers/file/files/reducer';

import { Box, Divider } from '@mui/material';

interface Props {
  files: FileState[];
  deleteFiles?: (files: FileState[]) => Promise<string[]>;
  downloadFiles?: (files: FileState[]) => Promise<string[] | undefined>;
  editFile?: (file: FileState) => Promise<void>;
}

export const FileList = (props: Props) => {
  const { files, deleteFiles, downloadFiles, editFile } = props;
  const deleteFile = React.useCallback(
    (file: FileState) => {
      deleteFiles && deleteFiles([file]);
    },
    [deleteFiles],
  );

  const downloadFile = React.useCallback(
    (file: FileState) => {
      downloadFiles && downloadFiles([file]);
    },
    [downloadFiles],
  );

  const fileElements = files.map((file, idx) => {
    return (
      <Box key={idx}>
        <FileComponent
          file={file}
          deleteFile={deleteFiles ? () => deleteFile(file) : undefined}
          downloadFile={downloadFiles ? () => downloadFile(file) : undefined}
          editFile={editFile ? () => editFile(file) : undefined}
        />
        <Divider />
      </Box>
    );
  });

  return <Box>{fileElements}</Box>;
};
