import { strcmp } from '@pladdenico/common';
import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import { graphqlObjStorageTypes } from '@pladdenico/obj-storage';

export interface BaseObjId {
  id: string;
}

export type ObjState = graphqlObjStorageTypes.Obj;

export function objToObjState(obj: ObjState) {
  return obj;
}
export const initialObjsState = [];

const comparator = (a: BaseObjId, b: BaseObjId) => strcmp(a.id, b.id);
const merger = (_a: ObjState, b: ObjState) => b;

const adapter = new EntityArrayAdapter<BaseObjId, ObjState>(
  initialObjsState,
  comparator,
  merger,
);

export const objsSlice = createBaseArraySlice('objs', adapter);
export const objsSelectors = adapter.selectors;

export const objsReducer = objsSlice.reducer;
export const objsActions = objsSlice.actions;
