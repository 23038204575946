import { sortBy } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileDropzoneComponent } from '~src/components/utils/files/files-dropzone';
import {
  createFiles,
  deleteFiles as deleteFilesRequest,
  fetchFiles,
} from '~src/data/store/modules/file/file/requests';
import { fetchSignedUrls } from '~src/data/store/modules/obj-storage/objs/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardMedias } from '~src/data/store/selectors/common/dashboard/media/selectors';
import { getEntitiesByWorkspaceId } from '~src/data/store/selectors/entities/entities/selectors';
import { getEntityMedias } from '~src/data/store/selectors/entities/media/selectors';
import { getFiles } from '~src/data/store/selectors/file/selectors';
import {
  selectViewData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { FileRelation } from '~src/domain/workspace/components/file/file-relation';
import { EditFileComponent } from '~src/domain/workspace/components/file/file/edit.component';
import { WorkspaceFileList } from '~src/domain/workspace/components/file/workspace-file-list';
import { WorkspaceFileComponent } from '~src/domain/workspace/components/file/workspace-file.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { File } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { downloadFile } from '~src/utils/file/download-file';

import { findSorted, strcmp } from '@pladdenico/common';
import { graphqlObjStorageOperations } from '@pladdenico/obj-storage';
import { Box } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
}

export type FileWithRelations = {
  file: File;
  relations: FileRelation[];
};

const baseFileId = (tenantId: string, workspaceId: string) => {
  return `${tenantId}/${workspaceId}/`;
};
export const WorkspaceFilesComponent = React.memo((props: Props) => {
  const { workspace } = props;
  const files = useSelector((state: RootState) =>
    getFiles(selectWorkspaceData(state)),
  );

  const entityMedias = useSelector((state: RootState) => {
    return getEntityMedias(selectWorkspaceData(state));
  });

  const entities = useSelector((state: RootState) => {
    return getEntitiesByWorkspaceId(selectWorkspaceData(state), workspace.id);
  });

  const dashboardMedias = useSelector((state: RootState) => {
    return getDashboardMedias(selectViewData(state));
  });

  const filesWithRelations = React.useMemo(() => {
    const sortedEntityMedias = sortBy(entityMedias, [(a) => a.fileId]);
    const sortedDashboardMedias = sortBy(dashboardMedias, [(a) => a.fileId]);
    let entityMediaIdx = 0;
    let dashboardMediaIdx = 0;
    return files.map((file) => {
      const relations: FileRelation[] = [];
      for (; entityMediaIdx < sortedEntityMedias.length; ++entityMediaIdx) {
        const medium = sortedEntityMedias[entityMediaIdx];
        if (medium.fileId === file.id) {
          const idx = findSorted(entities, medium, (entity, medium) =>
            strcmp(entity.id, medium.entityId),
          );
          if (idx.item != null) {
            relations.push({ type: 'entity', item: idx.item });
          }
        } else {
          break;
        }
      }
      for (
        ;
        dashboardMediaIdx < sortedDashboardMedias.length;
        ++dashboardMediaIdx
      ) {
        const dashboardMedia = sortedDashboardMedias[dashboardMediaIdx];
        if (dashboardMedia.fileId === file.id) {
          relations.push({ type: 'dashboard', item: dashboardMedia });
        } else {
          break;
        }
      }
      return {
        file,
        relations,
      };
    });
  }, [dashboardMedias, entities, entityMedias, files]);

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    dispatch(fetchFiles(workspace.tenantId, workspace.id, null));
  }, [dispatch, workspace.id, workspace.tenantId]);

  const imageIdBase = React.useMemo(
    () => baseFileId(workspace.tenantId, workspace.id),
    [workspace.id, workspace.tenantId],
  );
  const callback = React.useCallback(
    (
      id: string,
      name: string,
      description: string,
      url: string,
      type: string,
    ) => {
      return dispatch(
        createFiles(workspace.tenantId, workspace.id, [
          {
            id,
            key: url,
            name,
            type,
            description,
          },
        ]),
      );
    },
    [dispatch, workspace.id, workspace.tenantId],
  );

  const deleteFiles = React.useCallback(
    (files: FileState[]) =>
      dispatch(
        deleteFilesRequest(
          workspace.tenantId,
          workspace.id,
          files.map((m) => {
            return {
              id: m.id,
            };
          }),
        ),
      ),
    [dispatch, workspace.id, workspace.tenantId],
  );

  const downloadFiles = React.useCallback(
    (files: FileState[]) => {
      const keys = files.map((m) => m.id);
      return dispatch(
        fetchSignedUrls(keys, graphqlObjStorageOperations.ObjOperationType.Get),
      ).then((urls) => {
        if (urls) {
          urls.forEach((url) => {
            const file = files.find((f) => f.id === url.id);
            if (file) {
              downloadFile(url.url, file);
            }
          });
        }
        return urls?.map((url) => url.id);
      });
    },
    [dispatch],
  );

  const [file, setFile] = React.useState<File>();
  const editModal = useVisible();
  const editFile = React.useCallback(
    (file: File) => {
      setFile(file);
      editModal.open();
      return Promise.resolve();
    },
    [editModal],
  );
  const editMediaComponent = React.useMemo(() => {
    if (file) {
      return (
        <EditFileComponent
          tenantId={workspace.tenantId}
          workspace={workspace}
          handleClose={editModal.close}
          isShowing={editModal.isShowing}
          file={file}
        />
      );
    }
  }, [editModal.close, editModal.isShowing, file, workspace]);

  const renderFile = React.useCallback(
    (
      file: File,
      relations: FileRelation[],
      deleteFile?: (file: FileState) => void,
      downloadFile?: (file: FileState) => void,
    ) => {
      return (
        <WorkspaceFileComponent
          workspace={workspace}
          file={file}
          relations={relations}
          deleteFile={deleteFile}
          downloadFile={downloadFile}
          editFile={editFile}
        />
      );
    },
    [editFile, workspace],
  );

  return (
    <Box sx={{ p: 1 }}>
      <WorkspaceFileList
        files={filesWithRelations}
        deleteFiles={deleteFiles}
        downloadFiles={downloadFiles}
        editFile={editFile}
        renderFile={renderFile}
      />
      {editMediaComponent}
      <FileDropzoneComponent baseId={imageIdBase} callback={callback} />
    </Box>
  );
});
