import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';
import { getRegionAllocationsByEntityId } from '~src/data/store/selectors/entities/region-allocations/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { useRegionAllocationTableActions } from './use-region-allocation-table-actions';
import { useRegionAllocationFields } from '~src/hooks/fields/workspace/entity/use-region-allocation-fields';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';

interface Props {
  tenantId: string;
  workspaceId: string;
  entity: EntityState;
}

export type RegionAllocationData = RegionAllocationState;

function regionAllocationsToData(
  regionAllocations: RegionAllocationState[],
): RegionAllocationData[] {
  return [...regionAllocations];
}

export const RegionAllocationsTableComponent = React.memo((props: Props) => {
  const { entity } = props;
  const regionAllocations = useSelector((state: RootState) =>
    getRegionAllocationsByEntityId(selectWorkspaceData(state), entity.id),
  );
  const [data, setData] = React.useState<RegionAllocationData[]>(
    regionAllocationsToData(regionAllocations),
  );

  const fields = useRegionAllocationFields(props.workspaceId);

  // const columns = useRegionAllocationColumns();
  const { onRowUpdate, onRowDelete } = useRegionAllocationTableActions(
    props.tenantId,
    entity.workspaceId,
  );
  React.useEffect(() => {
    setData(regionAllocationsToData(regionAllocations));
  }, [regionAllocations]);

  const [initialSorters] = React.useState<
    TableField.Sorter<RegionAllocationData>[]
  >(() => {
    return compact([
      TableOperations.createSorterFromFields(fields, 'date', 'desc'),
    ]);
  });

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);

  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const content =
    regionAllocations.length > 0 ? (
      <div>
        <TableComponent<RegionAllocationData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </div>
    ) : (
      <></>
    );
  return content;
});
