import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { fetchOrganization } from '~src/data/store/modules/organizations/organization';
import { getOrganizationById } from '~src/data/store/modules/organizations/organization/selectors';
import { fetchOrganizationRoleGroups } from '~src/data/store/modules/organizations/roles/parser';
import { getOrganizationRoleGroupsById } from '~src/data/store/modules/organizations/roles/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';

import { Box, Grid, Theme, Typography } from '@mui/material';

import { OrganizationAddressComponent } from './organization-address.component';
import { OrganizationRoleGroupComponent } from '~src/domain/workspace/components/project/organization/organization-role-group.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  organizationId: string | undefined;
}

const useStyles = makeStyles()((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
export const OrganizationComponent = React.memo((props: Props) => {
  const { workspace, project, organizationId } = props;
  const { classes } = useStyles();
  const org = useSelector((state: RootState) =>
    getOrganizationById(selectFinancialData(state), organizationId),
  );
  const orgRoleGroups = useSelector((state: RootState) =>
    getOrganizationRoleGroupsById(selectFinancialData(state), organizationId),
  );
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(fetchOrganization(organizationId ?? null));
    dispatch(fetchOrganizationRoleGroups(organizationId ?? null));
  }, [dispatch, organizationId]);

  const roleGroups = React.useMemo(() => {
    return orgRoleGroups?.groups.map((group, index) => {
      return (
        <Grid item key={index} display="flex">
          <OrganizationRoleGroupComponent
            key={index}
            workspace={workspace}
            project={project}
            orgRoleGroup={group}
          />
        </Grid>
      );
    });
  }, [orgRoleGroups?.groups, project, workspace]);

  if (!org) {
    return (
      <Box
        display="flex"
        sx={{ justifyCcontent: 'center', alignItems: 'center', flex: 1 }}
      >
        <Typography variant="overline">Set organization identifier</Typography>
      </Box>
    );
  }

  return (
    <Box p={1}>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        Organization - {org.name} - {org.organizationNumber}
      </Typography>
      <Typography variant="caption">
        {org.organizationType.description}
      </Typography>
      <Typography variant="body2">
        {org.businessCode.description}, {org.organizationType.description},{' '}
        {org.institutionalSectorCode.description}
      </Typography>
      <div className={classes.divider} />
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="subtitle2">Employees</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{org.numberOfEmployees}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="subtitle2">Creation date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {org.creationDate.toDateString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="subtitle2">Registration date</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            {org.registrationDate.toDateString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="subtitle2">Business address</Typography>
        </Grid>
        <Grid item xs={6}>
          <OrganizationAddressComponent address={org.businessAddress} />
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        sx={{ mt: 1 }}
        justifyContent="flex-start"
      >
        {roleGroups}
      </Grid>
    </Box>
  );
});
