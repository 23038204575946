import moment from 'moment';
import React from 'react';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useStyles as useTransactionStyles } from '~src/domain/workspace/components/project/transaction/form/transaction.styles';
import {
  dnbTransactionsActions,
  DnbTransactionState,
} from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';
import { AppDispatch } from '~src/store/store';

import { DatePicker } from '@mui/x-date-pickers';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDnbTransactionFields } from '~src/domain/workspace/components/data/adapters/dnb/form/use-fields.component';

export type FormValues = DnbTransactionState;

export interface FormContext {}

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  handleClose: () => void;
  initialFormValues?: FormValues;
  handleSuccess: (dnbTransaction: DnbTransactionState) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  divContainer: {
    maxWidth: theme.breakpoints.values['lg'],
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'none',
    },
  },
}));

export const DnbEditTransactionFormComponent = React.memo((props: Props) => {
  const {
    initialFormValues,
    handleClose,
    handleSuccess,
    workspace,
    projectId,
  } = props;
  const { classes, cx } = useStyles();
  const transactionStyles = useTransactionStyles();

  const dispatch = useDispatch<AppDispatch>();

  const context: FormContext = {};

  const { control, handleSubmit } = useForm<FormValues, FormContext>({
    context,
    defaultValues: initialFormValues,
  });

  const onHandleUpdateTransaction: SubmitHandler<FormValues> =
    React.useCallback(
      (formValues) => {
        const ret = dispatch(
          dnbTransactionsActions.upsertOneElement(formValues),
        );
        handleSuccess(ret.payload);
      },
      [dispatch, handleSuccess],
    );

  const onHandleError: SubmitErrorHandler<FormValues> = React.useCallback(
    (errors) => {
      console.log(JSON.stringify(errors));
    },
    [],
  );

  const { valueElement, nameElement, holdingElement } =
    useDnbTransactionFields();

  const generalElements = (
    <>
      <Grid item xs={6}>
        <Controller
          name="date"
          rules={{ required: true }}
          control={control}
          defaultValue={new Date()}
          render={({ field, fieldState }) => (
            <DatePicker
              showToolbar={false}
              inputFormat="DD/MM/YYYY"
              label="Date"
              value={field.value ?? null}
              onChange={(date: moment.Moment | null) =>
                field.onChange(date?.toDate())
              }
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  fullWidth
                  {...params}
                  error={!!fieldState.error}
                />
              )}
            />
          )}
        />
      </Grid>
    </>
  );

  return (
    <>
      <DialogTitle id="form-dialog-title">Edit Dnb transaction</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <div
            className={cx(
              transactionStyles.classes.partElements,
              classes.divContainer,
            )}
          >
            <Grid container rowSpacing={1}>
              <Grid item container columnSpacing={1}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field, fieldState }) =>
                      nameElement(field, fieldState)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="value"
                    control={control}
                    render={({ field, fieldState }) =>
                      valueElement(field, fieldState)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="holdingId"
                    control={control}
                    render={({ field, fieldState }) =>
                      holdingElement(field, fieldState, workspace, projectId)
                    }
                  />
                </Grid>

                {generalElements}
              </Grid>
            </Grid>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onHandleUpdateTransaction, onHandleError)}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
});
