import {
  ProjectObjStateVisitor,
  ProjectObjVisitable,
} from '~src/data/store/visitors/workspace/project/project-obj-visitor';
import {
  ProjectStateVisitor,
  ProjectVisitable,
} from '~src/data/store/visitors/workspace/project/project-visitor';

import { Operation } from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function handleProjectObjs(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: graphqlWorkspaceTypes.ProjectObj[],
  subscriptions: Operation[],
) {
  const projectObjVisitor = new ProjectObjStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((project) => {
    const projectObjVisitable = new ProjectObjVisitable(workspaceId, project);
    projectObjVisitable.accept(projectObjVisitor);
  });
  return projectObjVisitor.post();
  // return outputs;
}

export function handleProjects(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: graphqlWorkspaceTypes.Project[],
  subscriptions: Operation[],
) {
  const projectVisitor = new ProjectStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((project) => {
    const projectVisitable = new ProjectVisitable(tenantId, project);
    projectVisitable.accept(projectVisitor);
  });
  projectVisitor.post();
  return outputs;
}
