import { AuthenticationListener } from '~src/data/store/listeners/authentication.listener';
import { ConfigListener } from '~src/data/store/listeners/config.listener';
import { CurrencyListener } from '~src/data/store/listeners/currency.listener';

import { WorkspaceListener } from './workspace.listener';
import { AppStore } from '~src/store/store';

// import { PortfolioListener } from '~src/store/listeners/portfolio.listener';
// import { WorkspaceListener } from '~src/store/listeners/workspace.listener';

export function createPreRenderListeners(store: AppStore) {
  const listeners = [];
  const authenticationListener = new AuthenticationListener(store);
  const currencyListener = new CurrencyListener(store);
  listeners.push(authenticationListener);
  listeners.push(currencyListener);
  return listeners;
}

export function createPostRenderListeners(store: AppStore) {
  const listeners = [];
  const workspaceListener = new WorkspaceListener(store);
  // const portfolioListener = new PortfolioListener(store);
  const configListener = new ConfigListener(store);
  listeners.push(workspaceListener);
  // listeners.push(portfolioListener);
  listeners.push(configListener);
  return listeners;
}
