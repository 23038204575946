import React from 'react';
import { BondHoldingTabsComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/bond-holding-tabs.component';
import { FundHoldingTabsComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/fund/fund-holding-tabs.component';
import { UseHoldingTabsProps } from '~src/domain/workspace/components/project/portfolio/holdings/tab/use-holding-tabs';

import { HoldingType } from '@pladdenico/models';

import { StockHoldingTabsComponent } from '../holding-types/stock/stock-holding-tabs.component';
import { HoldingTabsComponent } from './holding-tabs.component';

export const HoldingTabComponent = React.memo((props: UseHoldingTabsProps) => {
  if (props.holding.type === HoldingType.Stock) {
    return <StockHoldingTabsComponent {...props} />;
  } else if (props.holding.type === HoldingType.Bond) {
    return <BondHoldingTabsComponent {...props} />;
  } else if (props.holding.type === HoldingType.Fund) {
    return <FundHoldingTabsComponent {...props} />;
  }
  return <HoldingTabsComponent {...props} />;
});
