import { parseBondTrade } from '~src/data/store/modules/holdings/bond/trade/parser';
import { subscribeToBondTrades } from '~src/data/store/modules/holdings/bond/trade/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  bondTradesActions,
  BondTradeState,
} from '~src/data/store/reducers/holding/holding-types/bond/bond-trade/reducer';
import { BondTrade } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface BondTradeVisitor {
  visit(trade: BondTradeVisitable): BondTradeState;
  post(): void;
}

export class BondTradeVisitable implements Visitable<BondTradeVisitor> {
  constructor(private _trade: BondTrade) {}
  public accept(visitor: BondTradeVisitor) {
    return visitor.visit(this);
  }

  public parse(): BondTradeState {
    return parseBondTrade(this._trade);
  }
}

// export class BondTradeHandlerVisitor implements BondTradeVisitor {
//   constructor(private _handle: (trades: BondTradeState) => void) {}
//   public visit(trade: BondTradeVisitable): BondTradeState {
//     const tradeState = trade.parse();
//     this._handle(tradeState);
//     return tradeState;
//   }
// }

export class BondTradeStateVisitor implements BondTradeVisitor {
  private _bondTrades: BondTradeState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._bondTrades = [];
  }

  public visit(trade: BondTradeVisitable): BondTradeState {
    const tradeState = trade.parse();
    this._bondTrades.push(tradeState);
    return tradeState;
  }

  post() {
    this._dispatch(
      bondTradesActions.upsertManyElements(this._bondTrades, {
        shouldAutobatch: true,
      }),
    );
    subscribeToBondTrades(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._bondTrades,
      this._subscriptions,
    );
  }
}
