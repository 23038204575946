import React from 'react';
import { AddressState } from '~src/data/store/reducers/person/addresses/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { AddressFormComponent, AddressInput } from './form.component';

interface Props {
  title: string;
  open: boolean;
  addressInput: AddressInput;
  setAddressInput: (addressInput: AddressInput) => void;

  handleSaveAddress: () => Promise<AddressState>;
  handleClose: () => void;
  savedCallback: () => void;
}

export const AddressDialogComponent = React.memo((props: Props) => {
  const handleSaveAddress = (): void => {
    props
      .handleSaveAddress()
      .then((_value) => {
        props.savedCallback();
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', props.addressInput, JSON.stringify(err));
      });
  };

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <AddressFormComponent
          addressInput={props.addressInput}
          setAddressInput={props.setAddressInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAddress} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
