import {
  AddressStateVisitor,
  AddressVisitable,
} from '~src/data/store/visitors/person/address-visitor';
import { Address } from '~src/services/graphql/workspace/client/graphql';

export function handleAddresses(
  dispatch: any,
  _tenantId: string,
  workspaceId: string,
  addresses: Address[],
) {
  const visitor = new AddressStateVisitor(dispatch);
  const res = addresses.map((address) => {
    const visitable = new AddressVisitable(
      workspaceId,
      address.person?.id ?? '',
      address,
    );
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
