import {
  AssetObjStateVisitor,
  AssetObjVisitable,
  AssetObjVisitor,
} from '~src/data/store/visitors/asset/asset-obj-visitor';
import {
  BankNoteStateVisitor,
  BankNoteVisitable,
  BankNoteVisitor,
  isBankNote,
} from '~src/data/store/visitors/asset/types/bank-note/bank-note-visitor';
import {
  BondStateVisitor,
  BondVisitable,
  BondVisitor,
  isBond,
} from '~src/data/store/visitors/asset/types/bond/bond-visitor';
import {
  CryptoStateVisitor,
  CryptoVisitable,
  CryptoVisitor,
  isCrypto,
} from '~src/data/store/visitors/asset/types/crypto/crypto-visitor';
import {
  FundStateVisitor,
  FundVisitable,
  FundVisitor,
  isFund,
} from '~src/data/store/visitors/asset/types/fund/fund-visitor';
import {
  isLoan,
  LoanStateVisitor,
  LoanVisitable,
  LoanVisitor,
} from '~src/data/store/visitors/asset/types/loan/loan-visitor';
import {
  isPrivateEquityFund,
  PrivateEquityFundStateVisitor,
  PrivateEquityFundVisitable,
  PrivateEquityFundVisitor,
} from '~src/data/store/visitors/asset/types/private-equity-fund/private-equity-fund-visitor';
import {
  isStock,
  StockStateVisitor,
  StockVisitable,
  StockVisitor,
} from '~src/data/store/visitors/asset/types/stock/stock-visitor';
import {
  isWarrant,
  WarrantStateVisitor,
  WarrantVisitable,
  WarrantVisitor,
} from '~src/data/store/visitors/asset/types/warrant/warrant-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import { Asset } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface AssetVisitor {
  post(): void;
  assetObjVisitor: AssetObjVisitor;
  bankNoteVisitor: BankNoteVisitor;
  bondVisitor: BondVisitor;
  cryptoVisitor: CryptoVisitor;
  fundVisitor: FundVisitor;
  loanVisitor: LoanVisitor;
  stockVisitor: StockVisitor;
  warrantVisitor: WarrantVisitor;
  privateEquityFundVisitor: PrivateEquityFundVisitor;
}

export class AssetVisitable implements Visitable<AssetVisitor> {
  constructor(
    private _tenantId: string,
    private _workspaceId: string,
    private _asset: Asset,
  ) {}
  public accept(visitor: AssetVisitor) {
    const asset = this._asset;
    if (asset != null) {
      if (asset.asset) {
        const assetObjVisitable = new AssetObjVisitable(
          this._tenantId,
          this._workspaceId,
          asset.asset,
        );
        assetObjVisitable.accept(visitor.assetObjVisitor);
      }
      if (isBond(asset)) {
        const bondVisitable = new BondVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        bondVisitable.accept(visitor.bondVisitor);
      } else if (isBankNote(asset)) {
        const bankNoteVisitable = new BankNoteVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        bankNoteVisitable.accept(visitor.bankNoteVisitor);
      } else if (isCrypto(asset)) {
        const cryptoVisitable = new CryptoVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        cryptoVisitable.accept(visitor.cryptoVisitor);
      } else if (isFund(asset)) {
        const fundVisitable = new FundVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        fundVisitable.accept(visitor.fundVisitor);
      } else if (isLoan(asset)) {
        const loanVisitable = new LoanVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        loanVisitable.accept(visitor.loanVisitor);
      } else if (isPrivateEquityFund(asset)) {
        const bankNoteVisitable = new PrivateEquityFundVisitable(
          this._workspaceId,
          asset,
        );
        bankNoteVisitable.accept(visitor.privateEquityFundVisitor);
      } else if (isStock(asset)) {
        const stockVisitable = new StockVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        stockVisitable.accept(visitor.stockVisitor);
      } else if (isWarrant(asset)) {
        const warrantVisitable = new WarrantVisitable(
          this._workspaceId,
          // asset.id,
          asset,
        );
        warrantVisitable.accept(visitor.warrantVisitor);
      }
    }
  }
}

export class AssetStateVisitor implements AssetVisitor {
  public assetObjVisitor: AssetObjVisitor;
  public bankNoteVisitor: BankNoteVisitor;
  public bondVisitor: BondVisitor;
  public cryptoVisitor: CryptoVisitor;
  public fundVisitor: FundVisitor;
  public loanVisitor: LoanVisitor;
  public privateEquityFundVisitor: PrivateEquityFundVisitor;
  public stockVisitor: StockVisitor;
  public warrantVisitor: WarrantVisitor;

  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    this.assetObjVisitor = new AssetObjStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.bankNoteVisitor = new BankNoteStateVisitor(dispatch);
    this.bondVisitor = new BondStateVisitor(dispatch);
    this.cryptoVisitor = new CryptoStateVisitor(dispatch);
    this.fundVisitor = new FundStateVisitor(dispatch);
    this.loanVisitor = new LoanStateVisitor(dispatch);
    this.privateEquityFundVisitor = new PrivateEquityFundStateVisitor(dispatch);
    this.stockVisitor = new StockStateVisitor(dispatch);
    this.warrantVisitor = new WarrantStateVisitor(dispatch);
  }

  post() {
    this.assetObjVisitor.post();
    this.bankNoteVisitor.post();
    this.bondVisitor.post();
    this.cryptoVisitor.post();
    this.fundVisitor.post();
    this.loanVisitor.post();
    this.privateEquityFundVisitor.post();
    this.stockVisitor.post();
    this.warrantVisitor.post();
  }
}
