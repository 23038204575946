import moment from 'moment';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  valuationsSelectors,
  ValuationState,
} from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { Finance } from '~src/utils/finance/holding';

const getValuationState = (state: WorkspaceDataState) => {
  return state.holding.holding.valuation.valuations;
};

export const getValuations = createSelector(
  (state: WorkspaceDataState) => getValuationState(state),
  (valuationState): ValuationState[] => {
    return valuationsSelectors.selectAllElements(valuationState);
  },
);

export const getValuationsByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getValuationState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (valuationState, holdingId): ValuationState[] => {
    return (
      valuationsSelectors.selectElementsByKey(valuationState, holdingId) ?? []
    );
    // return selectValuationsByHoldingId(valuationState, holdingId);
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getValuationsByHoldingIds = createCachedSelector(
  (state: WorkspaceDataState) => getValuationState(state),
  (_state: WorkspaceDataState, holdingIds: string[]) => holdingIds,
  (valuationState, holdingIds: string[]): ValuationState[] => {
    return (
      valuationsSelectors.selectElementsByKeys(valuationState, holdingIds) ?? []
    );
    // return holdingIds.reduce<ValuationState[]>((prev, curr) => {
    //   const valuations = selectValuationsByHoldingId(valuationState, curr);
    //   return [...prev, ...valuations];
    // }, []);
  },
)({
  keySelector: (_state, holdingIds) => holdingIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

interface ClosestValuationProps {
  holdingId: string;
  date: moment.Moment;
}

const selectClosestValuationByHoldingId = createCachedSelector(
  (valuations: ValuationState[], _date: moment.Moment) => valuations,
  (_valuations: ValuationState[], date: moment.Moment) => date,
  (valuations, date) => {
    return Finance.findClosestValuation(valuations, date.toDate());
  },
)({
  keySelector: (_valuations: ValuationState[], date: moment.Moment) =>
    date.unix(),
  selectorCreator: createSelector,
});

export const getClosestValuationByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, props: ClosestValuationProps) =>
    getValuationsByHoldingId(state, props.holdingId),
  (_state: WorkspaceDataState, props: ClosestValuationProps) => props.date,
  (valuations, date) => {
    return selectClosestValuationByHoldingId(valuations, date);
  },
)({
  keySelector: (_state, props) => `${props.holdingId}:${props.date.unix()}`,
  selectorCreator: createSelector,
});

export const getCurrencyIdByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, props: ClosestValuationProps) =>
    getClosestValuationByHoldingId(state, props),
  (valuation) => {
    return valuation ? valuation.currencyId : undefined;
  },
)({
  keySelector: (_state, props) => `${props.holdingId}:${props.date.unix()}`,
  selectorCreator: createSelector,
});
