import { compact } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { getFilterFor } from '~src/data/store/selectors/holding/filter/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { Period } from '../../../../../utils/period/period';
import { getHoldingXIRR } from './holding-xirr';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  period: Period;
  currency: CurrencyState;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsXIRRS = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    period,
    currency,
    filter,
    useLiveValue,
  ) => {
    return compact(
      holdings.map((holding) => {
        const filtered = getFilterFor(state, {
          filter,
          workspaceId,
          projectId,
          holding,
          date,
        });
        if (!filtered) {
          return undefined;
        }

        const xirr = getHoldingXIRR(state, {
          holdingId: holding.id,
          date,
          period,
          currency,
          useLiveValue,
        });
        return {
          value: xirr,
          holding,
        };
      }),
    );
  },
);
