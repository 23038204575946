import { handleObjs } from '~src/data/store/modules/obj-storage/objs/handler';
import { objsActions } from '~src/data/store/reducers/obj-storage/objs/reducer';
import { objStorageRequest } from '~src/services/request/graphql-request';

import {
  graphqlObjStorageTypes,
  QueryContextTypes,
  QueryObjStorageContext,
} from '@pladdenico/obj-storage';

function handleObjUrls(dispatch: any, output: graphqlObjStorageTypes.ObjUrl[]) {
  const objUrls = output.map((output) => output.location);
  return objUrls;
  // batch(() => {
  //   dispatch(objsActions.addMany(objUrls));
  // });
  // return output;
}

function handleSignedUrls(
  dispatch: any,
  output: graphqlObjStorageTypes.SignedUrl[],
) {
  const signedUrls = output.map((output) => {
    return {
      id: output.id,
      url: output.location,
    };
  });
  return signedUrls;
  // batch(() => {
  //   dispatch(signedUrlsActions.addMany(signedUrls));
  // });
  // return output;
}

export function fetchObjs(ids: string[]) {
  return (dispatch: any) => {
    const node = graphqlObjStorageTypes.GetObjsDocument;
    const variables: graphqlObjStorageTypes.GetObjsQueryVariables = {
      ids,
    };
    const context: QueryObjStorageContext = {
      type: QueryContextTypes.objStorage,
    };
    return objStorageRequest(node, variables, context).then((data) => {
      if (data.getObjs) {
        return handleObjs(dispatch, data.getObjs);
      }
      return undefined;
    });
  };
}

function convertToObjOperationType_HACK(
  operation: graphqlObjStorageTypes.ObjOperationType,
) {
  if (operation === graphqlObjStorageTypes.ObjOperationType.Put) {
    return 'PUT' as unknown as graphqlObjStorageTypes.ObjOperationType;
  } else if (operation === graphqlObjStorageTypes.ObjOperationType.Get) {
    return 'GET' as unknown as graphqlObjStorageTypes.ObjOperationType;
  } else {
    throw 'Not a valid operation';
  }
}

export function fetchSignedUrls(
  ids: string[],
  operation: graphqlObjStorageTypes.ObjOperationType,
) {
  return (dispatch: any) => {
    const node = graphqlObjStorageTypes.GetSignedUrlsDocument;
    const variables: graphqlObjStorageTypes.GetSignedUrlsQueryVariables = {
      ids,
      // operation,
      operation: convertToObjOperationType_HACK(operation),
    };
    const context: QueryObjStorageContext = {
      type: QueryContextTypes.objStorage,
    };
    return objStorageRequest(node, variables, context).then((data) => {
      if (data.getSignedUrls) {
        return handleSignedUrls(dispatch, data.getSignedUrls);
      }
      return undefined;
    });
  };
}

export function createObjs(
  inputs: graphqlObjStorageTypes.CreateObjInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlObjStorageTypes.CreateObjsDocument;
    const variables: graphqlObjStorageTypes.CreateObjsMutationVariables = {
      inputs,
    };
    const context: QueryObjStorageContext = {
      type: QueryContextTypes.objStorage,
    };
    return objStorageRequest(node, variables, context).then((data) => {
      if (data.createObjs) {
        return handleObjUrls(dispatch, data.createObjs);
      }
      return undefined;
    });
  };
}

export function updateObjs(
  inputs: graphqlObjStorageTypes.UpdateObjInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlObjStorageTypes.UpdateObjsDocument;
    const variables: graphqlObjStorageTypes.UpdateObjsMutationVariables = {
      inputs,
    };
    const context: QueryObjStorageContext = {
      type: QueryContextTypes.objStorage,
    };
    return objStorageRequest(node, variables, context).then((data) => {
      if (data.updateObjs) {
        return handleObjUrls(dispatch, data.updateObjs);
      }
      return [];
    });
  };
}

export function deleteObjs(ids: string[]) {
  return (dispatch: any) => {
    const node = graphqlObjStorageTypes.DeleteObjsDocument;
    const variables: graphqlObjStorageTypes.DeleteObjsMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryObjStorageContext = {
      type: QueryContextTypes.objStorage,
    };
    return objStorageRequest(node, variables, context).then((data) => {
      if (data.deleteObjs) {
        dispatch(
          objsActions.removeMany(
            data.deleteObjs.map((id) => {
              return {
                id,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
