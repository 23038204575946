import { compact } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import {
  // getFilterFor,
  selectFilterFor,
  getHoldingShareById,
} from '~src/data/store/selectors/holding/filter/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { Period } from '../../../../../utils/period/period';
import { getHoldingCashOnCash } from './holding-cash-on-cash';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
// import { getAssetsByHoldingIds } from '~src/data/store/selectors/asset/assets/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
// import createCachedSelector, { FlatMapCache } from 're-reselect';
import { getHoldingsDenormalized } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { getCategoriesByWorkspaceId } from '~src/data/store/selectors/asset/categories/selectors';
import { getRegionsByTenantId } from '~src/data/store/selectors/common/regions/selectors';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCurrencyState } from '~src/data/store/selectors/finance/currencies/selectors';
import { getRegionAllocations } from '~src/data/store/selectors/entities/region-allocations/selectors';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holdings: HoldingState[];
  currency: CurrencyState;
  date: moment.Moment;
  period: Period;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsCashOnCash = createSelector(
  (state: DataState) => state,
  (state: DataState, props: Props) =>
    getHoldingsDenormalized(selectWorkspaceDataFromData(state), {
      holdings: props.holdings,
      workspaceId: props.workspace.id,
    }),
  (_state: DataState, props: Props) => props.workspace.id,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (state: DataState, props: Props) =>
    getCategoriesByWorkspaceId(
      selectWorkspaceDataFromData(state),
      props.workspace.id,
    ),
  (state: DataState, props: Props) =>
    getRegionsByTenantId(
      selectWorkspaceDataFromData(state),
      props.workspace.tenantId,
    ),
  (state: DataState, _props: Props) =>
    getRegionAllocations(selectWorkspaceDataFromData(state)),
  (state: DataState, _props: Props) =>
    getCurrencyState(selectFinancialDataFromData(state)),

  (
    state,
    holdingsDenormalized,
    workspaceId,
    projectId,
    currency,
    date,
    period,
    filter,
    useLiveValue,
    categories,
    regions,
    regionAllocations,
    currencies,
  ) => {
    // filter: Filter,
    //   workspaceId: string,
    //   holding: HoldingDenormalizedWithEntity,
    //   date: moment.Moment,
    //   categories: RecordState<AssetCategoryState, string>[],
    //   regions: RecordState<RegionState, string>[],
    //   regionAllocations: RecordState<RegionAllocationState, string>[],
    //   currencies: RecordState<CurrencyState, string>[],

    return compact(
      holdingsDenormalized.map((holding) => {
        const filtered = selectFilterFor(
          filter,
          workspaceId,
          holding,
          date,
          categories,
          regions,
          regionAllocations,
          currencies,
        );
        if (!filtered) {
          return undefined;
        }
        const cashOnCash = getHoldingCashOnCash(state, {
          workspaceId,
          holding: holding.holding,
          currency,
          date,
          period,
          useLiveValue,
        });
        const share = getHoldingShareById(state, {
          workspaceId,
          projectId,
          holding: holding.holding,
          filter,
          date,
        });
        return {
          holding: holding.holding,
          share,
          cashOnCash,
          // value: value * share,
        };
      }),
    );
  },
);
