import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { EditBankNote } from '~src/components/fields/workspace/asset/edit/asset-types/edit-bank-note';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { BankNoteHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bank-note/create-action';

interface Props {
  workspace: WorkspaceState;
  bankNoteHoldingInput: BankNoteHoldingInputType;
  setBankNoteHoldingInput: (
    bankNoteHoldingInput: graphqlWorkspaceTypes.CreateBankNoteHoldingInputType,
  ) => void;
}

export const CreateBankNoteHoldingFieldsComponent = React.memo(
  (props: Props) => {
    const handleBankNoteChange = (bankNoteId: string) => {
      props.setBankNoteHoldingInput({
        ...props.bankNoteHoldingInput,
        bankNoteId,
      });
    };

    return (
      <EditBankNote
        handleValue={handleBankNoteChange}
        bankNoteId={props.bankNoteHoldingInput.bankNoteId}
        workspace={props.workspace}
      />
    );
  },
);
