import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BondPositionState,
  BaseBondPositionId,
  selectors as bondPositionsSelectors,
} from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';
import { getLastDate } from '~src/utils/common/date-utils';

const getBondPositionState = (state: WorkspaceDataState) =>
  state.holding.holding.bondHolding.bondPositions;

export const getBondPositionsByBondHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getBondPositionState(state),
  (_state: WorkspaceDataState, bondHoldingId: string) => bondHoldingId,
  (bondPositionState, bondHoldingId): BondPositionState[] => {
    return (
      bondPositionsSelectors.selectElementsByKey(
        bondPositionState,
        bondHoldingId,
      ) ?? []
    );
  },
)({
  keySelector: (_state, bondHoldingId) => bondHoldingId,
  selectorCreator: createSelector,
});

export const getBondPositions = createSelector(
  (state: WorkspaceDataState) => getBondPositionState(state),
  (bondPositions) => {
    return bondPositionsSelectors.selectAllElements(bondPositions);
  },
);

export const getBondPositionById = createCachedSelector(
  (state: WorkspaceDataState) => getBondPositionState(state),
  (_state: WorkspaceDataState, baseId: BaseBondPositionId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseBondPositionId) =>
    baseId.bondHoldingId,
  (bondPositions, id, bondHoldingId): BondPositionState | undefined => {
    return bondPositionsSelectors.selectElementByT(bondPositions, {
      id: id,
      bondHoldingId,
    });
  },
)({
  keySelector: (_state, bondHoldingId, bondPositionId) =>
    `${bondHoldingId}:${bondPositionId}`,
  selectorCreator: createSelector,
});

interface BondHoldingMultipleIds {
  bondHoldingId: string;
  ids: string[];
}

export const getBondPositionsByIds = createCachedSelector(
  (state: WorkspaceDataState) => getBondPositionState(state),
  (_state: WorkspaceDataState, ids: BondHoldingMultipleIds) =>
    ids.bondHoldingId,
  (_state: WorkspaceDataState, ids: BondHoldingMultipleIds) => ids.ids,
  (bondPositions, bondHoldingId, ids): BondPositionState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        bondHoldingId,
      };
    });
    return bondPositionsSelectors.selectElementsByTs(bondPositions, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.bondHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

export const getLastPosition = (stockHoldingId: string) => {
  const stockPositions = getBondPositionsByBondHoldingId(
    selectWorkspaceData(store.getState()),
    stockHoldingId,
  );
  return getLastDate(stockPositions, (position) => position.date);
};

export const selectLastPosition = createCachedSelector(
  (state: WorkspaceDataState, stockHoldingId: string) =>
    getBondPositionsByBondHoldingId(state, stockHoldingId),
  (stockPositions): BondPositionState | undefined => {
    return getLastDate(stockPositions, (position) => position.date);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });
