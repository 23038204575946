import { FundHoldingState } from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import { FundHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseFundHolding(fundHolding: FundHolding): FundHoldingState {
  return {
    id: fundHolding.id,
    fundId: fundHolding.fund?.id ?? '',
    holdingId: fundHolding.holding?.id ?? '',
  };
}
