import * as React from 'react';
import { useSelector } from 'react-redux';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getAddressesByPersonId } from '~src/data/store/selectors/person/address/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import AddIcon from '@mui/icons-material/Add';
import HouseIcon from '@mui/icons-material/House';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { AddressComponent } from './address.component';
import { CreateAddressDialogComponent } from './form/create-dialog.component';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100%',
  },
  addButton: {
    float: 'right',
    right: theme.spacing(2),
  },
  addressIcon: {
    textAlign: 'center',
  },
}));

export const AddressesComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const addresses = useSelector((state: RootState) =>
    getAddressesByPersonId(selectWorkspaceData(state), props.person.id),
  );
  const [createAddressDialogOpened, setCreateAddressDialogOpened] =
    React.useState(false);

  const addressElements =
    addresses &&
    addresses.map((address, index) => {
      return (
        <ListItem key={index}>
          <AddressComponent
            workspace={props.workspace}
            person={props.person}
            address={address}
          />
        </ListItem>
      );
    });

  function handleAddAddress() {
    setCreateAddressDialogOpened(true);
  }
  function handleCloseCreateAddressDialog() {
    setCreateAddressDialogOpened(false);
  }

  return (
    <Paper className={classes.root}>
      <Box>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item xs={2} md={2}>
            <IconButton
              aria-label="add"
              onClick={handleAddAddress}
              className={classes.addButton}
            >
              <AddIcon />
            </IconButton>
            <CreateAddressDialogComponent
              open={createAddressDialogOpened}
              handleClose={handleCloseCreateAddressDialog}
              tenantId={props.workspace.tenantId}
              workspace={props.workspace}
              person={props.person}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={2} md={2} className={classes.addressIcon}>
            <HouseIcon />
          </Grid>
          <Grid item xs={10} md={10}>
            {/* <div>Add address number</div> */}
            <List dense={true}>{addressElements}</List>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});
