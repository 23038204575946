import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectTenantData } from '~src/data/store/selectors/selectors';
import { selectTenants } from '~src/data/store/selectors/tenant/tenants/selectors';
import {
  tenantCreateRootPath,
  tenantRootPath,
} from '~src/navigation/paths/tenant/paths';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    margin: theme.spacing(2),
  },

  cardContainer: {
    marginBottom: theme.spacing(1),
  },

  card: {
    minWidth: 275,
  },

  title: {
    fontSize: 14,
  },

  pos: {
    // marginBottom: theme.spacing(1),
  },

  container: {
    padding: theme.spacing(2),
  },
}));

export const TenantsComponent = memo(() => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const tenants = useSelector((state: RootState) =>
    selectTenants(selectTenantData(state)),
  );

  // const [createTenantDialogOpened, setCreateTenantDialogeOpened] =
  //   React.useState(false);

  // function handleAddTenant() {
  //   setCreateTenantDialogeOpened(true);
  // }
  // function handleCloseCreateTenantDialog() {
  //   setCreateTenantDialogeOpened(false);
  // }

  function handleGotoTenant(tenantId: string) {
    navigate(tenantRootPath(tenantId));
  }

  const tenantElements = tenants.map((tenant) => {
    return (
      <Grid item key={tenant.id} className={classes.cardContainer}>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {tenant.name}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Tenant description
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleGotoTenant(tenant.id)}
            >{`Go to ${tenant.name}`}</Button>
          </CardActions>
        </Card>
      </Grid>
    );
  });

  // const createTenant = (
  //   <div>
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       className={classes.button}
  //       startIcon={<AddIcon />}
  //       onClick={handleAddTenant}
  //     >
  //       Create tenant
  //     </Button>
  //     <CreateTenantDialogComponent
  //       open={createTenantDialogOpened}
  //       handleClose={handleCloseCreateTenantDialog}
  //     />
  //   </div>
  // );

  return (
    <Grid2
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '90vh' }}
    >
      <Grid2 xs={6}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          // startIcon={<AddIcon />}
          onClick={() => navigate(tenantCreateRootPath)}
        >
          Create tenant
        </Button>
        <Grid
          container
          // spacing={2}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.container}
        >
          {tenantElements}
        </Grid>
      </Grid2>
    </Grid2>
  );
});
