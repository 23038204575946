import { strcmp } from '@pladdenico/common';
import { Organization } from '@pladdenico/models';
import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

export interface BaseOrganizationId {
  id: string;
}

export type OrganizationState = Organization;

export function organizationToOrganizationState(
  organization: OrganizationState,
) {
  return organization;
}
export const initialOrganizationsState = [];

const comparator = (a: BaseOrganizationId, b: BaseOrganizationId) =>
  strcmp(a.id, b.id);
const merger = (_a: OrganizationState, b: OrganizationState) => b;

const adapter = new EntityArrayAdapter<BaseOrganizationId, OrganizationState>(
  initialOrganizationsState,
  comparator,
  merger,
);

export const organizationsSlice = createBaseArraySlice(
  'organizations',
  adapter,
);
export const organizationsSelectors = adapter.selectors;

export const organizationsReducer = organizationsSlice.reducer;
export const organizationsActions = organizationsSlice.actions;
