import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useCommitmentsAlgorithm } from '~src/domain/workspace/components/project/commitment/algorithm/use-algorithm';
import { CommitmentCardsListComponent } from '~src/domain/workspace/components/project/commitment/card/commitment-cards-list.component';
import { useUpdateState } from '~src/utils/common/use-update-state';

import { Theme } from '@mui/material';

import { CommitmentsToolbar } from './commitments-toolbar.component';
import { getCommitmentsByHoldingId } from '~src/data/store/selectors/holding/commitment/commitments/selectors';
import { CommitmentData } from '~src/domain/workspace/components/project/commitment/commitment-data';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export const HoldingCommitmentsComponent = React.memo((props: Props) => {
  const { holding, project, workspace } = props;
  const commitments = useSelector((state: RootState) =>
    getCommitmentsByHoldingId(selectWorkspaceData(state), holding.id),
  );

  const { classes } = useStyles();
  const data: CommitmentData[] = React.useMemo(() => {
    return commitments.map((commitment) => {
      return { id: commitment.id, commitment };
    });
  }, [commitments]);
  const algo = useCommitmentsAlgorithm(workspace, project, data);
  const updateStateData = useUpdateState();

  return (
    <div className={classes.root}>
      <CommitmentsToolbar
        workspace={workspace}
        projectId={project.id}
        holding={holding}
        algoData={algo.algoData}
      />
      <div className={classes.content}>
        <CommitmentCardsListComponent
          workspace={workspace}
          projectId={project.id}
          data={algo.algoData.filtered}
          setUpdateState={updateStateData.setUpdateState}
        />
      </div>
    </div>
  );
});
