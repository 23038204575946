import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  FundTradeState,
  selectors as fundTradesSelectors,
  BaseFundTradeId,
} from '~src/data/store/reducers/holding/holding-types/fund/fund-trade/reducer';

const getFundTradeState = (state: WorkspaceDataState) =>
  state.holding.holding.fundHolding.fundTrades;

export const getFundTradesByFundHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getFundTradeState(state),
  (_state: WorkspaceDataState, fundHoldingId: string) => fundHoldingId,
  (fundTradeState, fundHoldingId): FundTradeState[] => {
    return (
      fundTradesSelectors.selectElementsByKey(fundTradeState, fundHoldingId) ??
      []
    );
  },
)({
  keySelector: (_state, fundHoldingId) => fundHoldingId,
  selectorCreator: createSelector,
});

export const getFundTrades = createSelector(
  (state: WorkspaceDataState) => getFundTradeState(state),
  (fundTrades) => {
    return fundTradesSelectors.selectAllElements(fundTrades);
  },
);

export const getFundTradeById = createCachedSelector(
  (state: WorkspaceDataState) => getFundTradeState(state),
  (_state: WorkspaceDataState, baseId: BaseFundTradeId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseFundTradeId) => baseId.fundHoldingId,
  (fundTrades, id, fundHoldingId): FundTradeState | undefined => {
    return fundTradesSelectors.selectElementByT(fundTrades, {
      id: id,
      fundHoldingId,
    });
  },
)({
  keySelector: (_state, fundHoldingId, fundTradeId) =>
    `${fundHoldingId}:${fundTradeId}`,
  selectorCreator: createSelector,
});

interface FundHoldingMultipleIds {
  fundHoldingId: string;
  ids: string[];
}

export const getFundTradesByIds = createCachedSelector(
  (state: WorkspaceDataState) => getFundTradeState(state),
  (_state: WorkspaceDataState, ids: FundHoldingMultipleIds) =>
    ids.fundHoldingId,
  (_state: WorkspaceDataState, ids: FundHoldingMultipleIds) => ids.ids,
  (fundTrades, fundHoldingId, ids): FundTradeState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        fundHoldingId,
      };
    });
    return fundTradesSelectors.selectElementsByTs(fundTrades, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.fundHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

interface FundTradeTransferId {
  fundHoldingId: string;
  transferId: string;
}

export const getFundTradeByTransferId = createCachedSelector(
  (state: WorkspaceDataState, baseId: FundTradeTransferId) =>
    getFundTradesByFundHoldingId(state, baseId.fundHoldingId),
  (_state: WorkspaceDataState, baseId: FundTradeTransferId) =>
    baseId.transferId,
  (fundTrades, transferId): FundTradeState | undefined => {
    return fundTrades.find((t) => t.transferId === transferId);
  },
)({
  keySelector: (_state, fundHoldingId, fundTradeId) =>
    `${fundHoldingId}:${fundTradeId}`,
  selectorCreator: createSelector,
});
