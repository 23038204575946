import { upsertAllocations } from '~src/data/store/modules/portfolios/allocations/requests';
import { Maybe } from 'graphql/jsutils/Maybe';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import { AppDispatch } from '~src/store/store';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';

const name = 'portfolioAllocations';

export interface PortfolioAllocation {
  id: string;
  portfolioId: string;
  currencyId?: Maybe<string>;
  regionId?: Maybe<string>;
  assetClass?: Maybe<string>;
  sector?: Maybe<string>;
  category?: Maybe<string>;
  min?: Maybe<number>;
  max?: Maybe<number>;
}

const header: Array<keyof PortfolioAllocation> = [
  'id',
  'portfolioId',
  'assetClass',
  'category',
  'sector',
  'currencyId',
  'regionId',
  'min',
  'max',
];

export const portfolioAllocationExportHandler = (
  data: PortfolioAllocation[],
): ExportHandler<PortfolioAllocation> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const portfolioAllocationImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<PortfolioAllocation> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertAllocations(workspace.tenantId, workspace.id, ts)),
  };
};
