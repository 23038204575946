import * as uuid from 'uuid';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { getLastQuoteByBondId } from '~src/data/store/selectors/finance/quotes/bond/bond-quotes';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { getLastPosition } from '~src/data/store/selectors/holding/holding-types/bond/bond-position/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { BondTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';
import { store } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export const initializeBondTransferData = (
  workspace: WorkspaceState,
  holding: HoldingState,
  transferId: string,
  date: Date,
) => {
  const bondHolding = getBondHoldingByHoldingId(
    selectWorkspaceData(store.getState()),
    holding.id,
  );
  if (bondHolding) {
    const lastBondPrice = getLastQuoteByBondId(selectData(store.getState()), {
      workspaceId: workspace.id,
      bondId: bondHolding.bondId,
    });
    const lastBondPosition = getLastPosition(bondHolding.id);
    const positionId = uuid.v1();
    const bondData: BondTransferData = {
      holdingType: HoldingType.Bond,
      position: {
        id: positionId,
        date,
        shares: lastBondPosition?.shares ?? 0,
        bondHoldingId: bondHolding.id,
      },
      trade: {
        id: uuid.v1(),
        sharePrice: lastBondPrice?.close ?? 0,
        positionId,
        transferId,
        shares: 0,
        bondHoldingId: bondHolding.id,
      },
    };
    return bondData;
  }
  return undefined;
};
