import { useDispatch } from 'react-redux';
import {
  createEvents,
  deleteEvents,
  updateEvents,
} from '~src/data/store/modules/workspaces/projects/events/requests';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function useEventActions(tenantId: string, workspaceId: string) {
  const dispatch = useDispatch<AppDispatch>();
  const onUpdate = (oldData: EventState, newData: EventState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.UpdateEventInputType = {
          id: newData.id,
          description: newData.description ?? '',
          name: newData.name ?? '',
          projectId: newData.projectId,
        };
        dispatch(updateEvents(tenantId, workspaceId, [input]))
          .then((_events) => {
            resolve();
          })
          .catch((_err) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: EventState) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deleteEvents(tenantId, workspaceId, oldData.projectId, [oldData.id]),
      )
        .then((_eventIds) => {
          resolve();
        })
        .catch((_err) => {
          reject();
        });
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };

  const onCreate = (eventInput: graphqlWorkspaceTypes.CreateEventInputType) => {
    return dispatch(createEvents(tenantId, workspaceId, [eventInput]));
  };
  return { onUpdate, onDelete, onCreate };
}
