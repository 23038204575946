// import { parseHoldingObj } from '~src/data/store/modules/holdings/base/holding.parser';
// import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import {
  CommitmentStateVisitor,
  CommitmentVisitable,
  CommitmentVisitor,
} from '~src/data/store/visitors/holding/commitment-visitor';
import {
  HoldingObjStateVisitor,
  HoldingObjVisitable,
  HoldingObjVisitor,
} from '~src/data/store/visitors/holding/holding-obj-visitor';
import {
  TransferStateVisitor,
  TransferVisitable,
  TransferVisitor,
} from '~src/data/store/visitors/holding/transfer-visitor';
import {
  BankNoteHoldingStateVisitor,
  BankNoteHoldingVisitable,
  BankNoteHoldingVisitor,
  isBankNoteHolding,
} from '~src/data/store/visitors/holding/types/bank-note/bank-note-holding-visitor';
import {
  BondHoldingStateVisitor,
  BondHoldingVisitable,
  BondHoldingVisitor,
  isBondHolding,
} from '~src/data/store/visitors/holding/types/bond/bond-holding-visitor';
import {
  CryptoHoldingStateVisitor,
  CryptoHoldingVisitable,
  CryptoHoldingVisitor,
  isCryptoHolding,
} from '~src/data/store/visitors/holding/types/crypto/crypto-holding-visitor';
import {
  FundHoldingStateVisitor,
  FundHoldingVisitable,
  FundHoldingVisitor,
  isFundHolding,
} from '~src/data/store/visitors/holding/types/fund/fund-holding-visitor';
import {
  isLoanHolding,
  LoanHoldingStateVisitor,
  LoanHoldingVisitable,
  LoanHoldingVisitor,
} from '~src/data/store/visitors/holding/types/loan/loan-holding-visitor';
import {
  isStockHolding,
  StockHoldingStateVisitor,
  StockHoldingVisitable,
  StockHoldingVisitor,
} from '~src/data/store/visitors/holding/types/stock/stock-holding-visitor';
import {
  isWarrantHolding,
  WarrantHoldingStateVisitor,
  WarrantHoldingVisitable,
  WarrantHoldingVisitor,
} from '~src/data/store/visitors/holding/types/warrant/warrant-holding-visitor';
import {
  ValuationStateVisitor,
  ValuationVisitable,
  ValuationVisitor,
} from '~src/data/store/visitors/holding/valuation-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import { Holding } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface HoldingVisitor {
  post(): void;
  transferVisitor: TransferVisitor;
  commitmentVisitor: CommitmentVisitor;
  valuationVisitor: ValuationVisitor;
  holdingObjVisitor: HoldingObjVisitor;

  bankNoteHoldingVisitor: BankNoteHoldingVisitor;
  bondHoldingVisitor: BondHoldingVisitor;
  cryptoHoldingVisitor: CryptoHoldingVisitor;
  fundHoldingVisitor: FundHoldingVisitor;
  loanHoldingVisitor: LoanHoldingVisitor;
  stockHoldingVisitor: StockHoldingVisitor;
  warrantHoldingVisitor: WarrantHoldingVisitor;
}

export class HoldingVisitable implements Visitable<HoldingVisitor> {
  constructor(private _holding: Holding) {}
  public accept(visitor: HoldingVisitor) {
    const holdingObjVisitable = new HoldingObjVisitable(this._holding.holding);
    holdingObjVisitable.accept(visitor.holdingObjVisitor);
    const holding = this._holding;
    if (this._holding.commitments) {
      this._holding.commitments.forEach((commitment) => {
        const commitmentVisitable = new CommitmentVisitable(
          this._holding.holding.id,
          commitment,
        );
        commitmentVisitable.accept(visitor.commitmentVisitor);
      });
    }
    if (this._holding.transfers) {
      this._holding.transfers.forEach((transfer) => {
        const transferVisitable = new TransferVisitable(
          this._holding.holding.id,
          transfer,
        );
        transferVisitable.accept(visitor.transferVisitor);
      });
    }
    if (this._holding.valuations) {
      this._holding.valuations.forEach((valuation) => {
        const valuationVisitable = new ValuationVisitable(
          this._holding.holding.id,
          valuation,
        );
        valuationVisitable.accept(visitor.valuationVisitor);
      });
    }
    if (holding != null) {
      if (isBondHolding(holding)) {
        const bondHoldingVisitable = new BondHoldingVisitable(holding);
        bondHoldingVisitable.accept(visitor.bondHoldingVisitor);
      } else if (isBankNoteHolding(holding)) {
        const bankNoteHoldingVisitable = new BankNoteHoldingVisitable(holding);
        bankNoteHoldingVisitable.accept(visitor.bankNoteHoldingVisitor);
      } else if (isCryptoHolding(holding)) {
        const cryptoHoldingVisitable = new CryptoHoldingVisitable(holding);
        cryptoHoldingVisitable.accept(visitor.cryptoHoldingVisitor);
      } else if (isFundHolding(holding)) {
        const fundHoldingVisitable = new FundHoldingVisitable(holding);
        fundHoldingVisitable.accept(visitor.fundHoldingVisitor);
      } else if (isLoanHolding(holding)) {
        const loanHoldingVisitable = new LoanHoldingVisitable(holding);
        loanHoldingVisitable.accept(visitor.loanHoldingVisitor);
      } else if (isStockHolding(holding)) {
        const stockHoldingVisitable = new StockHoldingVisitable(holding);
        stockHoldingVisitable.accept(visitor.stockHoldingVisitor);
      } else if (isWarrantHolding(holding)) {
        const warrantHoldingVisitable = new WarrantHoldingVisitable(holding);
        warrantHoldingVisitable.accept(visitor.warrantHoldingVisitor);
      }
    }
  }

  // public parse(): HoldingState {
  //   const holding = this._holding;
  //   return parseHoldingObj(holding.holding);
  // }
}

export class HoldingStateVisitor implements HoldingVisitor {
  public holdingObjVisitor: HoldingObjVisitor;
  public commitmentVisitor: CommitmentVisitor;
  public transferVisitor: TransferVisitor;
  public valuationVisitor: ValuationVisitor;
  public bondHoldingVisitor: BondHoldingVisitor;
  public bankNoteHoldingVisitor: BankNoteHoldingVisitor;
  public cryptoHoldingVisitor: CryptoHoldingVisitor;
  public fundHoldingVisitor: FundHoldingVisitor;
  public loanHoldingVisitor: LoanHoldingVisitor;
  public stockHoldingVisitor: StockHoldingVisitor;
  public warrantHoldingVisitor: WarrantHoldingVisitor;
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    this.holdingObjVisitor = new HoldingObjStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.commitmentVisitor = new CommitmentStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.transferVisitor = new TransferStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.valuationVisitor = new ValuationStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.bondHoldingVisitor = new BondHoldingStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.bankNoteHoldingVisitor = new BankNoteHoldingStateVisitor(dispatch);
    this.cryptoHoldingVisitor = new CryptoHoldingStateVisitor(dispatch);
    this.fundHoldingVisitor = new FundHoldingStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.loanHoldingVisitor = new LoanHoldingStateVisitor(dispatch);
    this.stockHoldingVisitor = new StockHoldingStateVisitor(
      dispatch,
      tenantId,
      workspaceId,
      subscriptions,
    );
    this.warrantHoldingVisitor = new WarrantHoldingStateVisitor(dispatch);
  }

  post() {
    this.bankNoteHoldingVisitor.post();
    this.bondHoldingVisitor.post();
    this.cryptoHoldingVisitor.post();
    this.fundHoldingVisitor.post();
    this.holdingObjVisitor.post();
    this.loanHoldingVisitor.post();
    this.stockHoldingVisitor.post();
    this.transferVisitor.post();
    this.commitmentVisitor.post();
    this.valuationVisitor.post();
    this.warrantHoldingVisitor.post();
  }
}
