import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function parsePrivateEquityFund(
  workspaceId: string,
  assetId: string,
  privateEquityFund: graphqlWorkspaceTypes.PrivateEquityFund,
): PrivateEquityFundState {
  return {
    workspaceId,
    assetId,
    id: privateEquityFund.id,
    description: privateEquityFund.description,
  };
}
