import { combineReducers } from 'redux';
import {
  initialCommitmentsState,
  commitmentsReducer,
} from '~src/data/store/reducers/holding/commitment/commitments/reducer';

export const commitmentInitialState = {
  commitments: initialCommitmentsState,
};

export const commitmentReducer = combineReducers({
  commitments: commitmentsReducer,
});
