import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import FilterListIcon from '@mui/icons-material/FilterList';
import MoreIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import { IconButton, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableDrawers, TableField, TableOperations } from '@pladdenico/table';

import { CreateComponent } from './create.component';
import { Identity } from '~src/utils/interfaces/identity';
import { Box } from '@mui/system';
import { TransactionCardData } from '~src/domain/workspace/components/project/transaction/card/cards-list.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { TransactionImporterComponent } from '~src/domain/workspace/components/project/transaction/importer/importer.component';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { FormValues } from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { TransactionType } from '~src/utils/finance/transaction-type';

const useStyles = makeStyles()((theme: Theme) => ({
  root: { flex: 1 },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    // marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    // marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding?: HoldingState;
  // transactionCreateInputInit: graphqlWorkspaceTypes.CreateTransactionInputType;

  fields: TableField.Field<TransactionCardData>[];

  transactionAlgoData: TableOperations.AlgorithmData<TransactionCardData>;
}

export const useFilterComponents = <Data extends Identity>(
  data?: TableOperations.AlgorithmData<Data>,
) => {
  return data?.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={data.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });
};

export const useSorterComponents = <Data extends Identity>(
  data?: TableOperations.AlgorithmData<Data>,
) => {
  return data?.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={data.dispatchSorters}
        sorter={sorter}
      />
    );
  });
};

export const TransactionsToolbar = (props: Props) => {
  const { transactionAlgoData } = props;
  const [createTransactionDialogOpened, setCreateTransactionDialogOpened] =
    React.useState(false);

  const { classes } = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );

  const transactionsFilterComponents = useFilterComponents(transactionAlgoData);

  const transactionsSorterComponents = useSorterComponents(transactionAlgoData);

  const isMenuOpen = Boolean(moreAnchorEl);
  // const menuId = 'transaction-menu';

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleAddTransaction = () => {
    setCreateTransactionDialogOpened(true);
    handleMenuClose();
  };

  function handleCloseCreateTransactionDialog() {
    setCreateTransactionDialogOpened(false);
  }

  function handleSuccessCreateTransactionDialog(
    _transaction: TransactionState,
  ) {
    setCreateTransactionDialogOpened(false);
  }

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const importTransactionModal = useVisible();

  const transactionsMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleAddTransaction}>Create transaction</MenuItem>
      <MenuItem
        onClick={() => {
          importTransactionModal.open();
          handleMenuClose();
        }}
      >
        Import transactions
      </MenuItem>
    </Menu>
  );

  const initialFormValues: FormValues = React.useMemo(() => {
    return {
      title: '',
      description: '',
      type: TransactionType.Custom,
      date: new Date(),
      holdings: [],
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <IconButton onClick={handleFilterDrawerOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton onClick={handleSortDrawerOpen}>
          <SortIcon />
        </IconButton>
        <IconButton onClick={handleMenuOpen} color="inherit">
          <MoreIcon />
        </IconButton>
        {/* <Button
          color="primary"
          variant="contained"
          onClick={handleAddTransaction}
        >
          Add transaction
        </Button> */}
      </div>
      <CreateComponent
        workspace={props.workspace}
        projectId={props.projectId}
        open={createTransactionDialogOpened}
        handleClose={handleCloseCreateTransactionDialog}
        handleSuccess={handleSuccessCreateTransactionDialog}
        initialFormValues={initialFormValues}
      />
      <TransactionImporterComponent
        workspace={props.workspace}
        projectId={props.projectId}
        open={importTransactionModal.isShowing}
        handleClose={importTransactionModal.close}
      />
      {transactionsMenu}
      <TableDrawers.FilterDrawerComponent
        open={filterOpen}
        closeDrawer={() => setFilterOpen(false)}
        filtersComponent={
          <Box>
            <Typography variant="overline">Transaction</Typography>
            {transactionsFilterComponents}
          </Box>
        }
      />
      <TableDrawers.SorterDrawerComponent
        open={sortOpen}
        closeDrawer={() => setSortOpen(false)}
        sortersComponent={
          <Box>
            <Typography variant="overline">Transaction</Typography>
            {transactionsSorterComponents}
          </Box>
        }
      />
    </div>
  );
};
