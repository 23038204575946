import { bankNotesActions } from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { handleBankNotes } from './handler';

export function fetchBankNotes(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetBankNotesDocument;
    const variables: graphqlWorkspaceTypes.GetBankNotesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getBankNotes) {
        return handleBankNotes(workspaceId, dispatch, data.getBankNotes);
      }
      return [];
    });
  };
}

export function createBankNotes(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateBankNoteInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateBankNotesDocument;
    const variables: graphqlWorkspaceTypes.CreateBankNotesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createBankNotes) {
        return handleBankNotes(workspaceId, dispatch, data.createBankNotes);
      }
      return [];
    });
  };
}

export function updateBankNotes(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateBankNoteInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateBankNotesDocument;
    const variables: graphqlWorkspaceTypes.UpdateBankNotesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateBankNotes) {
        return handleBankNotes(workspaceId, dispatch, data.updateBankNotes);
      }
      return [];
    });
  };
}

export function upsertBankNotes(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertBankNoteInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertBankNotesDocument;
    const variables: graphqlWorkspaceTypes.UpsertBankNotesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertBankNotes) {
        return handleBankNotes(workspaceId, dispatch, data.upsertBankNotes);
      }
      return [];
    });
  };
}

export function deleteBankNotes(
  tenantId: string,
  workspaceId: string,
  bankNotes: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteBankNotesDocument;
    bankNotes.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteBankNoteInputType[] =
      bankNotes.map((bankNote) => {
        return {
          id: bankNote.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeleteBankNotesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteBankNotes) {
        const deletedBankNotes = intersectionSorted(
          bankNotes,
          data.deleteBankNotes,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(bankNotesActions.removeManyElements(deletedBankNotes));
      }
      return [];
    });
  };
}
