import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { appConfig } from '~src/app-config';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { usePopover } from '~src/hooks/utils/use-popover.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { invitationsRootPath } from '~src/navigation/paths/tenant/invitations/paths';
import { tenantMembersRootPath } from '~src/navigation/paths/tenant/members/paths';
import { tenantCreateRootPath } from '~src/navigation/paths/tenant/paths';
import { userTenantsPath } from '~src/navigation/paths/user/paths';
import { workspacesRootPath } from '~src/navigation/paths/workspace/paths';
import { getInitials } from '~src/utils/common/get-initials';
import { stringToColor } from '~src/utils/common/get-string-color';
import { getUrlFromString } from '~src/utils/common/get-url-from-string';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Button,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { tenantSettingsRootPath } from '~src/navigation/paths/tenant/settings/paths';
import { CreateWorkspaceDialogComponent } from '~src/domain/workspace/components/workspace/form/create-workspace-dialog.component';

interface Props {
  tenant: TenantState;
}

export const TenantMenuComponent = React.memo((props: Props) => {
  const { tenant } = props;

  const createHandleMenuClick = (menuItem: string) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };
  const { anchorEl, close, id, isOpen, open } = usePopover();
  const handleClick = React.useCallback(
    (event: any) => {
      open(event);
    },
    [open],
  );
  const workspaceCreateDialog = useVisible();

  const navigate = useNavigate();

  const tenantDropdown = React.useMemo(() => {
    return (
      <>
        <Button
          id={id}
          onClick={handleClick}
          sx={{ ml: 1, mt: 1, mb: 1, color: 'white' }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <Avatar
            variant="square"
            sx={{
              fontSize: 12,
              width: 24,
              height: 24,
              bgcolor: stringToColor(tenant.name ?? ''),
            }}
          >
            {getInitials(tenant.name ?? '')}
          </Avatar>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={close}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <ListItem>
            <Grid2 container>
              <Grid2 alignSelf="center">
                <Avatar
                  variant="square"
                  sx={{
                    bgcolor: stringToColor(tenant.name ?? ''),
                  }}
                >
                  {getInitials(tenant.name ?? '')}
                </Avatar>
              </Grid2>
              <Grid2 sx={{ ml: 2 }}>
                <Typography variant="h6">{tenant.name}</Typography>
                <Typography variant="body2">
                  {getUrlFromString(tenant.name ?? '')}.{appConfig.urlPostfix}
                </Typography>
              </Grid2>
            </Grid2>
          </ListItem>
          <Divider variant="fullWidth" />
          <MenuItem
            onClick={() => {
              navigate(workspacesRootPath(tenant.id));
              close();
            }}
          >
            All workspaces
          </MenuItem>
          <MenuItem
            onClick={() => {
              workspaceCreateDialog.open();
              close();
            }}
          >
            Create workspace
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(tenantSettingsRootPath(tenant.id));
              close();
            }}
          >
            Settings
          </MenuItem>
          <Divider variant="fullWidth" />
          <MenuItem onClick={createHandleMenuClick('Save as...')}>
            Invite people to {tenant.name}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(invitationsRootPath(tenant.id));
              close();
            }}
          >
            Invitations
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(tenantMembersRootPath(tenant.id));
              close();
            }}
          >
            Members
          </MenuItem>
          <Divider variant="fullWidth" />
          <MenuItem
            onClick={() => {
              navigate(userTenantsPath);
              close();
            }}
          >
            Switch tenant
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(tenantCreateRootPath);
              close();
            }}
          >
            Create tenant
          </MenuItem>
          <Divider />
        </Menu>
        <CreateWorkspaceDialogComponent
          open={workspaceCreateDialog.isShowing}
          handleClose={workspaceCreateDialog.close}
          tenantId={tenant.id}
        />
      </>
    );
  }, [
    anchorEl,
    close,
    handleClick,
    id,
    isOpen,
    navigate,
    tenant.id,
    tenant.name,
    workspaceCreateDialog,
  ]);

  return tenantDropdown;
});
