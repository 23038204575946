import {
  FundPositionStateVisitor,
  FundPositionVisitable,
} from '~src/data/store/visitors/holding/types/fund/fund-position-visitor';
import { FundPosition } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Operation } from '@pladdenico/portfolio-api';

export function handleFundPositions(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: FundPosition[],
  subscriptions: Operation[],
) {
  const fundPositionVisitor = new FundPositionStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  const res = outputs.map((fundHolding) => {
    const fundHoldingVisitable = new FundPositionVisitable(fundHolding);
    return fundHoldingVisitable.accept(fundPositionVisitor);
  });
  fundPositionVisitor.post();
  return res;
}
