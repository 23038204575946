import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { HoldingsCashOnCashRatioViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-cash-on-cash-ratio-view.component';
import { HoldingsValueViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-value-view.component';
import {
  HoldingsViewType,
  HoldingsViewTypes,
} from '~src/utils/interfaces/holdings-view-type';
import { SortOrder } from '~src/utils/interfaces/sort-order';
import { Period } from '~src/utils/period/period';

import { Box } from '@mui/material';
import { HoldingsCashContributionValueViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-cash-contribution-value-view.component';
import { HoldingsValueChangeViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-value-change-view.component';
import { HoldingsIrrViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-irr-view.component';
import { ElementFormatConfig } from '~src/components/dashboard/element';

interface Props {
  workspace: WorkspaceState;
  valuationDate: moment.Moment;
  projectId: string;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
  period: Period;
  minimumValuationForHolding: number | undefined;
  type: HoldingsViewType;
  sortOrder: SortOrder;
  format: ElementFormatConfig;
}

export const HoldingsViewComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holdings,
    baseCurrency,
    period,
    valuationDate,
    minimumValuationForHolding,
    sortOrder,
    type,
    format,
  } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  if (type === HoldingsViewTypes.Value) {
    return (
      <Box p={1}>
        <HoldingsValueViewComponent
          workspace={workspace}
          holdings={holdings}
          period={period}
          valuationDate={valuationDate}
          baseCurrency={baseCurrency}
          projectId={projectId}
          minimumValuationForHolding={minimumValuationForHolding}
          filter={filter}
          sortOrder={sortOrder}
          format={format}
        />
      </Box>
    );
  } else if (type === HoldingsViewTypes.CashOnCashRatio) {
    return (
      <Box p={1}>
        <HoldingsCashOnCashRatioViewComponent
          workspace={workspace}
          holdings={holdings}
          period={period}
          valuationDate={valuationDate}
          baseCurrency={baseCurrency}
          projectId={projectId}
          minimumValuationForHolding={minimumValuationForHolding}
          filter={filter}
          sortOrder={sortOrder}
          format={format}
        />
      </Box>
    );
  } else if (type === HoldingsViewTypes.CashFlow) {
    return (
      <Box p={1}>
        <HoldingsCashContributionValueViewComponent
          workspace={workspace}
          holdings={holdings}
          period={period}
          valuationDate={valuationDate}
          baseCurrency={baseCurrency}
          projectId={projectId}
          minimumValuationForHolding={minimumValuationForHolding}
          filter={filter}
          sortOrder={sortOrder}
          format={format}
        />
      </Box>
    );
  } else if (type === HoldingsViewTypes.ValueChange) {
    return (
      <Box p={1}>
        <HoldingsValueChangeViewComponent
          workspace={workspace}
          holdings={holdings}
          period={period}
          valuationDate={valuationDate}
          baseCurrency={baseCurrency}
          projectId={projectId}
          minimumValuationForHolding={minimumValuationForHolding}
          filter={filter}
          sortOrder={sortOrder}
          format={format}
        />
      </Box>
    );
  } else if (type === HoldingsViewTypes.Irr) {
    return (
      <Box p={1}>
        <HoldingsIrrViewComponent
          workspace={workspace}
          holdings={holdings}
          period={period}
          valuationDate={valuationDate}
          baseCurrency={baseCurrency}
          projectId={projectId}
          minimumValuationForHolding={minimumValuationForHolding}
          filter={filter}
          sortOrder={sortOrder}
          format={format}
        />
      </Box>
    );
  }
  return <></>;
});
