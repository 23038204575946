import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import { SchedulerState } from '~src/data/store/reducers/common/scheduler-state';

import { strcmp } from '@pladdenico/common';

export interface BaseSchedulerId {
  id: string;
}

export const initialSchedulerState = [];

const comparator = (a: BaseSchedulerId, b: BaseSchedulerId) =>
  strcmp(a.id, b.id);
const merger = (_a: SchedulerState, b: SchedulerState) => b;

const adapter = new EntityArrayAdapter<BaseSchedulerId, SchedulerState>(
  initialSchedulerState,
  comparator,
  merger,
);

export const schedulersSlice = createBaseArraySlice('schedulers', adapter);
export const schedulersSelectors = adapter.selectors;

export const schedulersReducer = schedulersSlice.reducer;
export const schedulersActions = schedulersSlice.actions;

export const schedulerInitialState = {
  schedulers: initialSchedulerState,
};
