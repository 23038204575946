import moment from 'moment';
import React from 'react';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useEditTypeField } from '~src/components/fields/workspace/holding/transaction/type/edit-type-field';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useTransactionFields } from '~src/domain/workspace/components/project/transaction/form/fields/use-fields.component';
import { handleCreateTransaction } from '~src/domain/workspace/components/project/transaction/form/handle-create-transaction';
import { HoldingComponentConfig } from '~src/domain/workspace/components/project/transaction/form/holding/holding-config';
import { TransactionComponent } from '~src/domain/workspace/components/project/transaction/form/transaction.component';
import { useStyles as useTransactionStyles } from '~src/domain/workspace/components/project/transaction/form/transaction.styles';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';
import { AppDispatch } from '~src/store/store';
import { TransactionType } from '~src/utils/finance/transaction-type';

import { DatePicker } from '@mui/x-date-pickers';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface FormHoldingFields {
  id: string;
  holdingId: string | null;
  transferInputData: TransferInputData;
  valuationInputData: graphqlWorkspaceTypes.CreateValuationInputType;
  config: HoldingComponentConfig;
  expectedResultingValue?: number;
}

export interface FormHoldingsValues {
  holdings: FormHoldingFields[];
}

export interface FormValues extends FormHoldingsValues {
  title: string;
  description: string;
  type: string;
  date: Date;
}

export interface FormContext {}

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  handleClose: () => void;
  handleSuccess: (transaction: TransactionState) => void;
  initialFormValues?: FormValues;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  divContainer: {
    maxWidth: theme.breakpoints.values['lg'],
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'none',
    },
  },
}));

export const CreateTransactionFormComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    initialFormValues,
    handleClose,
    handleSuccess,
  } = props;
  const { classes, cx } = useStyles();
  const transactionStyles = useTransactionStyles();

  const transactionTypes = Object.values(TransactionType);
  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = React.useState<any[]>([]);

  const onHandleCreateTransaction: SubmitHandler<FormValues> =
    React.useCallback(
      (formValues) => {
        return dispatch(
          handleCreateTransaction(workspace, projectId, formValues),
        )
          .then((value) => handleSuccess(value.transactions[0]))
          .catch((err) => {
            setErrors(err);
          });
      },
      [dispatch, handleSuccess, projectId, workspace],
    );

  const onHandleError: SubmitErrorHandler<FormValues> = React.useCallback(
    (errors) => {
      console.log(JSON.stringify(errors));
      setErrors([errors]);
    },
    [],
  );

  const context: FormContext = {};

  const {
    control,
    // setValue: setValueInit,
    setValue,
    handleSubmit,
    watch,
    getValues,
  } = useForm<FormValues, FormContext>({
    context,
    defaultValues: initialFormValues,
  });

  // type myType = Parameters<typeof setValueInit>;
  // const setValue: UseFormSetValue<FormValues> = React.useCallback(
  //   (name: myType[0], value: myType[1], options?: myType[2]) => {
  //     setValueInit(name, value, options);
  //   },
  //   [setValueInit]
  // );

  const transactionType = useWatch({
    name: 'type',
    control,
    // defaultValue: undefined,
  }) as TransactionType;

  const date = useWatch({
    name: 'date',
    control,
    // defaultValue: new Date(),
  });

  const { titleElement, descriptionElement } = useTransactionFields();

  const errorElements = React.useMemo(
    () =>
      errors.map((err, idx) => {
        return (
          <Alert key={idx} severity="error">
            {err.message}
          </Alert>
        );
      }),
    [errors],
  );

  const transactionTypeElement = useEditTypeField(
    'Transaction type',
    transactionTypes,
  );

  const generalElements = (
    <>
      <Grid item xs={6}>
        <Controller
          name="date"
          rules={{ required: true }}
          control={control}
          defaultValue={new Date()}
          render={({ field, fieldState }) => (
            <DatePicker
              showToolbar={false}
              inputFormat="DD/MM/YYYY"
              label="Date"
              value={field.value ?? null}
              onChange={(date: moment.Moment | null) =>
                field.onChange(date?.toDate())
              }
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  fullWidth
                  {...params}
                  error={!!fieldState.error}
                />
              )}
            />
          )}
        />
      </Grid>
    </>
  );

  return (
    <>
      <DialogTitle id="create-transaction-dialog">
        Create transaction
      </DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <div
            className={cx(
              transactionStyles.classes.partElements,
              classes.divContainer,
            )}
          >
            <Grid container rowSpacing={1}>
              <Grid item container columnSpacing={1}>
                <Grid item xs={12}>
                  <Controller
                    name="title"
                    // rules={{ required: true }}
                    control={control}
                    render={({ field, fieldState }) =>
                      titleElement(field, fieldState)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) =>
                      descriptionElement(field, fieldState)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="type"
                    rules={{ required: true }}
                    control={control}
                    render={({ field, fieldState }) =>
                      transactionTypeElement(field, fieldState)
                    }
                  />
                </Grid>
                {generalElements}
              </Grid>
              <TransactionComponent
                workspace={workspace}
                projectId={projectId}
                control={control}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                date={date}
                transactionType={transactionType}
              />
            </Grid>
          </div>
        </form>
        {errorElements}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onHandleCreateTransaction, onHandleError)}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
});
