import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/valuation-field-config';

export const getDefaultValuationFieldConfig = (
  _holding: HoldingState,
  editAll: boolean,
): ValuationFieldConfig => {
  return {
    base: {
      currency: {
        visible: true,
        disabled: !editAll,
      },
      date: {
        visible: true,
        disabled: !editAll,
      },
      value: {
        visible: true,
        disabled: !editAll,
      },
    },
  };
};
