import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useDashboardConfig } from '~src/domain/workspace/components/project/portfolio/dashboard/use-dashboard-config';

import { DashboardWithConfigComponent } from './dashboard-with-config.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
}

export const DashboardComponent = (props: Props) => {
  const { workspace, project, portfolioProject, portfolio } = props;
  const {
    portfolioConfig,
    dashboardElements,
    createDashboardConfigElement,
    portfolioDashboardConfig,
  } = useDashboardConfig({ workspace, portfolio });

  if (!portfolioConfig || !portfolioDashboardConfig) {
    return createDashboardConfigElement;
  }
  return (
    <DashboardWithConfigComponent
      workspace={workspace}
      project={project}
      portfolioProject={portfolioProject}
      portfolio={portfolio}
      portfolioConfig={portfolioConfig}
      portfolioDashboardConfig={portfolioDashboardConfig}
      dashboardElements={dashboardElements}
    />
  );
};
