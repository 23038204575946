import {
  Organization,
  OrganizationAddress,
  InstitutionalSectorCode,
  OrganizationBusinessCode,
  OrganizationType,
} from '@pladdenico/models';

// import { queryGetOrganizationsByIds } from './queries';
import { organizationsActions } from './reducer';

function mapBrregAddress(address: any): OrganizationAddress {
  return {
    country: address.land,
    countryCode: address.landkode,
    postCode: address.postnummer,
    postLocation: address.poststed,
    address: address.adresse,
  };
}

function mapBrregInstitutionalSectorCode(code: any): InstitutionalSectorCode {
  return {
    code: code.kode,
    description: code.beskrivelse,
  };
}

function mapBrregBusinessCode(code: any): OrganizationBusinessCode {
  return {
    code: code.kode,
    description: code.beskrivelse,
  };
}

function mapBrregOrganizationType(code: any): OrganizationType {
  return {
    code: code.kode,
    description: code.beskrivelse,
  };
}

function mapBrregEnhetToOrganization(
  id: string,
  breegEnhet: any,
): Organization {
  return {
    id,
    name: breegEnhet.navn,
    organizationNumber: breegEnhet.organisasjonsnummer,
    numberOfEmployees: breegEnhet.antallAnsatte,
    businessAddress: mapBrregAddress(breegEnhet.forretningsadresse),
    // postAddress: mapBrregAddress(breegEnhet.postadresse),
    institutionalSectorCode: mapBrregInstitutionalSectorCode(
      breegEnhet.institusjonellSektorkode,
    ),
    bancrupt: breegEnhet.konkurs,
    businessCode: mapBrregBusinessCode(breegEnhet.naeringskode1),
    organizationType: mapBrregOrganizationType(breegEnhet.organisasjonsform),
    registrationDate: new Date(breegEnhet.registreringsdatoEnhetsregisteret),
    creationDate: new Date(breegEnhet.stiftelsesdato),
  };
}

// antallAnsatte: 2
// forretningsadresse: {land: "Norge", landkode: "NO", postnummer: "0250", poststed: "OSLO", adresse: Array(2), …}
// institusjonellSektorkode: {kode: "2100", beskrivelse: "Private aksjeselskaper mv."}
// konkurs: false
// maalform: "Bokmål"
// naeringskode1: {beskrivelse: "Bedriftsrådgivning og annen administrativ rådgivning", kode: "70.220"}
// navn: "BODDCO AS"
// organisasjonsform: {kode: "AS", beskrivelse: "Aksjeselskap", _links: {…}}
// organisasjonsnummer: "919211334"
// postadresse: {land: "Norge", landkode: "NO", postnummer: "0122", poststed: "OSLO", adresse: Array(1), …}
// registreringsdatoEnhetsregisteret: "2017-06-28"
// registrertIForetaksregisteret: true
// registrertIFrivillighetsregisteret: false
// registrertIMvaregisteret: false
// registrertIStiftelsesregisteret: false
// sisteInnsendteAarsregnskap: "2018"
// stiftelsesdato: "2017-05-23"
// underAvvikling: false
// underTvangsavviklingEllerTvangsopplosning: false

const baseBrregEnheterUrl =
  'https://data.brreg.no/enhetsregisteret/api/enheter';

// interface OrganizationId {
//   baseRegisterId: string;
//   organizationNumber: string;
// }

function getOrganizationNumber(id: string | null) {
  if (!id) {
    return null;
  }
  const split = id.split('-');
  let country = '';
  let organizationNumber = '';
  if (split.length === 2) {
    country = split[0];
    organizationNumber = split[1];
  }
  if (
    country.length === 0 ||
    organizationNumber.length === 0 ||
    country !== 'NO' // only Norway supported...
  ) {
    return null;
  }
  return organizationNumber;
}

export function fetchOrganization(
  id: string | null,
  callback?: (organization?: Organization, err?: any) => void,
) {
  return (dispatch: any) => {
    if (id == null) {
      return;
    }
    const organizationNumber = getOrganizationNumber(id);
    if (organizationNumber == null) {
      return;
    }

    const url = `${baseBrregEnheterUrl}/${organizationNumber}`;
    fetch(url, {
      method: 'GET',
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((res) => {
            const org = mapBrregEnhetToOrganization(id, res);
            dispatch(organizationsActions.addOne(org));
            callback && callback(org);
            console.log(res);
          });
        } else {
          return res.json().then((res) => {
            callback && callback(undefined, res);
            return Promise.reject(res);
          });
        }
      })
      .catch((err) => {
        console.log('Could not get organization info', err);
      });
  };
}
