import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import { AdapterId } from '~src/services/xlsx/handlers/adapters/adapter';

export interface VpsTransactionId {
  id: string;
  account: string;
  holdingId: string | null;
}

export type VpsTransactionState = {
  id: string;
  adapterId: AdapterId;
  account: string;
  holdingId: string | null;
  date: Date;
  ISIN: string;
  security: string;
  securityGroup: string;
  transactionType: string;
  transferType: string;
  volume: number;
  value: number;
  averagePrice: number;
  fees: number;
  currency: string;
  settled: boolean;
  settledDate: Date;
  handled: boolean;
  transactionId: string | null;
  groupId: string | null;
};

export const initialVpsTransactionState = [];

const selectKeyId = (vpsTransaction: VpsTransactionId) =>
  vpsTransaction.account;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: VpsTransactionId, b: VpsTransactionId) => {
  return strcmp(a.id, b.id);
};

const adapter = new EntityRecordAdapter<
  VpsTransactionId,
  VpsTransactionState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const vpsTransactionsSlice = createBaseRecordSlice(
  'vpsTransactions',
  adapter,
);
export const vpsTransactionsSelectors = adapter.selectors;

export const vpsTransactionsReducer = vpsTransactionsSlice.reducer;
export const vpsTransactionsActions = vpsTransactionsSlice.actions;
