import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from 'moment';
import numbro from 'numbro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
// import { deleteQuotes } from '~src/data/store/modules/finance/quotes/requests';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getQuotesByPaperId } from '~src/data/store/selectors/finance/quotes/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';

import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface Props {
  paper: PaperState;
  // quotes: QuoteState[];
  // numberOfQuotes?: number;
}

function useSortedQuotes(quotes: QuoteState[] | undefined) {
  if (!quotes) return [];
  return quotes?.sort((a, b) => {
    return b.date.getTime() - a.date.getTime();
  });
}

export const PaperQuotesComponent = React.memo((props: Props) => {
  const unsortedQuotes = useSelector((state: RootState) =>
    getQuotesByPaperId(selectFinancialData(state), props.paper.id),
  );
  const quotes = useSortedQuotes(unsortedQuotes);
  const [numberOfQuotesToggled, setNumberOfQuotesToggled] =
    React.useState(false);

  const numberOfQuotes = (toggled: boolean) => {
    return toggled ? quotes.length : 5;
  };

  // const deleteQuote = (date: Date, paper: string, exchange: string) => {
  //   this.props.deleteQuote && this.props.deleteQuote({ paper, exchange }, date);
  // };
  const toggleShow = () => {
    setNumberOfQuotesToggled(!numberOfQuotesToggled);
  };

  const slicedQuotes = quotes.slice(0, numberOfQuotes(numberOfQuotesToggled));

  const quoteElements = slicedQuotes.map((quote, index) => {
    return (
      <TableRow key={index}>
        <TableCell>
          <Button
          // onClick={this.deleteQuote.bind(
          //   this,
          //   quote.date,
          //   quote.paper,
          //   quote.exchange
          // )}
          ></Button>
        </TableCell>
        <TableCell>{moment(quote.date).format('DD/MM/YYYY')}</TableCell>
        <TableCell>
          {numbro(quote.value).format({
            thousandSeparated: true,
            mantissa: 4,
            optionalMantissa: true,
          })}
        </TableCell>
        <TableCell>{quote.volume}</TableCell>
        <TableCell>
          {numbro(quote.open).format({
            thousandSeparated: true,
            mantissa: 4,
            optionalMantissa: true,
          })}
        </TableCell>
        <TableCell>
          {numbro(quote.close).format({
            thousandSeparated: true,
            mantissa: 4,
            optionalMantissa: true,
          })}
        </TableCell>
        <TableCell>
          {numbro(quote.low).format({
            thousandSeparated: true,
            mantissa: 4,
            optionalMantissa: true,
          })}
        </TableCell>
        <TableCell>
          {numbro(quote.high).format({
            thousandSeparated: true,
            mantissa: 4,
            optionalMantissa: true,
          })}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer>
      <Table className="ui table collapsing">
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton onClick={toggleShow}>
                {numberOfQuotesToggled ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Volume</TableCell>
            <TableCell>Open</TableCell>
            <TableCell>Close</TableCell>
            <TableCell>Low</TableCell>
            <TableCell>High</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{quoteElements}</TableBody>
      </Table>
    </TableContainer>
  );
});
