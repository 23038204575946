import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getTransfersDetailed } from '~src/data/store/selectors/finance/transfers/holding-transfer-detailed';
import { selectData } from '~src/data/store/selectors/selectors';
import { TransferDetailed } from '~src/domain/workspace/components/project/transfer/algorithm/transfer-detailed';
import { useTransferFields } from '~src/domain/workspace/components/project/transfer/algorithm/use-fields';

import { TableField, TableOperations } from '@pladdenico/table';

export const useTransfersAlgorithm = (
  workspace: WorkspaceState,
  project: ProjectState,
  transfers: TransferState[],
) => {
  const transfersDetailed = useSelector((state: RootState) =>
    getTransfersDetailed(selectData(state), {
      transfers,
      projectId: project.id,
      workspaceId: workspace.id,
    }),
  );

  const fields = useTransferFields();

  const initialTransferSorters = React.useMemo(() => {
    const sorters: TableField.Sorter<TransferDetailed>[] = compact([
      TableOperations.createSorter(fields.name, 'asc'),
      TableOperations.createSorter(fields.description, 'asc'),
      TableOperations.createSorter(fields.type, 'asc'),
      TableOperations.createSorter(fields.date, 'asc'),
    ]);
    return sorters;
  }, [fields.date, fields.description, fields.name, fields.type]);

  const initialTransferFilters: TableField.Filter<TransferDetailed>[] =
    React.useMemo(
      () => [
        TableOperations.createFilter(fields.name),
        TableOperations.createFilter(fields.description),
        TableOperations.createFilter(fields.type),
        TableOperations.createFilter(fields.date),
        // TableOperations.createFilter(fields.position),
        TableOperations.createFilter(fields.sharePrice),
        TableOperations.createFilter(fields.shares),
      ],
      [
        fields.date,
        fields.description,
        fields.name,
        fields.sharePrice,
        fields.shares,
        fields.type,
      ],
    );

  const algoData = TableOperations.useDataAlgorithms(
    transfersDetailed,
    initialTransferSorters,
    initialTransferFilters,
  );

  return {
    algoData,
    fields,
  };
};
