import moment from 'moment';
import { Quote, Trade } from '@pladdenico/models';
import createCachedSelector from 're-reselect';
import { Finance } from '~src/utils/finance/holding';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import { DataState } from '~src/data/store/reducers/reducers';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getExchangeRateState } from '../exchange-rates/selectors';
import { createSelector } from 'reselect';

interface Props {
  holdingId: string;
  date: moment.Moment;
  currency: CurrencyState;
  useLiveValue: boolean;
}

export const getHoldingXIRRs = createCachedSelector(
  (state: DataState, props: Props) => {
    return getValuationsByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holdingId,
    );
  },
  (state: DataState, props: Props) =>
    getTransfersByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holdingId,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (valuations, transfers, date, currency, exchangeRateState) => {
    const quotes: Quote[] = [];
    const trades: Trade[] = [];
    const data = [];
    for (let i = 0; i < valuations.length - 1; ++i) {
      const startDate = valuations[i].date;
      const endDate = valuations[i + 1].date;
      if (startDate && endDate) {
        const xirr = Finance.xirr(
          valuations,
          transfers,
          startDate,
          endDate,
          date.toDate(),
          exchangeRateState,
          currency,
          quotes,
          trades,
        );
        data.push({
          name: `${moment(startDate).format('YYYY-MM-DD')}-${moment(
            endDate,
          ).format('YYYY-MM-DD')}`,
          irr: xirr,
        });
      }
    }
    return data;
  },
)({
  keySelector: (_state: DataState, props: Props) =>
    `${props.holdingId}:${props.date.unix()}:${props.currency.id}`,
  selectorCreator: createSelector,
});
