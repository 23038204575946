import { parsePrivateEquityFund } from '~src/data/store/modules/assets/private-equity-fund/private-equity-funds/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  PrivateEquityFundInvestmentHandlerVisitor,
  PrivateEquityFundInvestmentStateVisitor,
  PrivateEquityFundInvestmentVisitable,
  PrivateEquityFundInvestmentVisitor,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-investment-visitor';
import {
  privateEquityFundsActions,
  PrivateEquityFundState,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { PrivateEquityFund } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface PrivateEquityFundVisitor {
  visit(privateEquityFund: PrivateEquityFundVisitable): void;
  post(): void;
  // capitalCallVisitor: PrivateEquityFundCapitalCallVisitor;
  investmentVisitor: PrivateEquityFundInvestmentVisitor;
}

export class PrivateEquityFundVisitable
  implements Visitable<PrivateEquityFundVisitor>
{
  constructor(
    private _workspaceId: string,
    private _privateEquityFund: PrivateEquityFund,
  ) {}
  public accept(visitor: PrivateEquityFundVisitor) {
    if (this._privateEquityFund.investments) {
      this._privateEquityFund.investments.forEach((investment) => {
        const privateEquityFundInvestmentVisitable =
          new PrivateEquityFundInvestmentVisitable(
            this._privateEquityFund.id,
            investment,
          );
        privateEquityFundInvestmentVisitable.accept(visitor.investmentVisitor);
      });
    }

    // if (this._privateEquityFund.capitalCalls) {
    //   this._privateEquityFund.capitalCalls.forEach((capitalCall) => {
    //     const privateEquityFundCapitalCallVisitable =
    //       new PrivateEquityFundCapitalCallVisitable(
    //         this._privateEquityFund.id,
    //         capitalCall
    //       );
    //     privateEquityFundCapitalCallVisitable.accept(
    //       visitor.capitalCallVisitor
    //     );
    //   });
    // }
    // TODO : the other members as well
    visitor.visit(this);
  }

  public parse(): PrivateEquityFundState {
    return parsePrivateEquityFund(
      this._workspaceId,
      this._privateEquityFund.asset?.id ?? '',
      this._privateEquityFund,
    );
  }
}

export class PrivateEquityFundHandlerVisitor
  implements PrivateEquityFundVisitor
{
  constructor(
    private _handle: (holding: PrivateEquityFundState) => void,
    public investmentVisitor: PrivateEquityFundInvestmentHandlerVisitor,
  ) {}
  public visit(holding: PrivateEquityFundVisitable): void {
    this._handle(holding.parse());
  }
  public post() {
    return;
  }
}

export class PrivateEquityFundStateVisitor extends PrivateEquityFundHandlerVisitor {
  constructor(
    dispatch: AppDispatch,
    tenantId: string,
    workspaceId: string,
    subscriptions: Operation[],
  ) {
    super(
      (privateEquityFund) =>
        dispatch(privateEquityFundsActions.upsertOneElement(privateEquityFund)),
      new PrivateEquityFundInvestmentStateVisitor(
        dispatch,
        tenantId,
        workspaceId,
        subscriptions,
      ),
      // new PrivateEquityFundCapitalCallStateVisitor(dispatch)
    );
  }

  post() {
    this.investmentVisitor.post();
  }
}
