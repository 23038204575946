import React from 'react';
import { useDispatch } from 'react-redux';
import { updateHoldings } from '~src/data/store/modules/holdings/base/requests';
import { updateWarrantHoldings } from '~src/data/store/modules/holdings/warrant/requests';
import { WarrantHoldingState } from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

import { EditFormComponent } from './edit-form.component';

interface Props extends React.PropsWithChildren<unknown> {
  holding: HoldingState;
  warrantHolding: WarrantHoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  open: boolean;
  handleClose: () => void;
}

export const EditDialogComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch<AppDispatch>();

  const [holdingInput, setHoldingInput] =
    React.useState<graphqlWorkspaceOperations.UpdateHoldingInputType>({
      id: props.holding.id,
      projectId: props.projectId,
      description: props.holding.description,
      name: props.holding.name,
      type: props.holding.type ?? '',
    });
  const [warrantHoldingInput, setWarrantHoldingInput] =
    React.useState<graphqlWorkspaceTypes.CreateWarrantHoldingInputType>({
      id: props.warrantHolding.id,
      warrantId: props.warrantHolding.warrantId,
      holdingId: props.warrantHolding.holdingId,
    });

  const updateHolding = () => {
    dispatch(
      updateHoldings(props.workspace.tenantId, props.workspace.id, [
        holdingInput,
      ]),
    )
      .then((_holdings) => {
        dispatch(
          updateWarrantHoldings(props.workspace.tenantId, props.workspace.id, [
            warrantHoldingInput,
          ]),
        )
          .then((_holdings) => {
            props.handleClose();
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit holding</DialogTitle>
      <DialogContent>
        <EditFormComponent
          workspace={props.workspace}
          holding={props.holding}
          projectId={props.projectId}
          holdingInput={holdingInput}
          setHoldingInput={setHoldingInput}
          warrantHoldingInput={warrantHoldingInput}
          setWarrantHoldingInput={setWarrantHoldingInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={updateHolding} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
