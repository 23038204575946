import React from 'react';
import { useSelector } from 'react-redux';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { CommitmentState } from '~src/data/store/reducers/holding/commitment/commitments/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getCommitmentById,
  getCommitmentsByHoldingId,
} from '~src/data/store/selectors/holding/commitment/commitments/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

interface Props {
  handleValue: (t: CommitmentState | null) => void;
  handlePersist?: () => void;
  label?: string;
  workspace: WorkspaceState;
  projectId: string;
  holdingId: string;
  commitmentId: string | null;
  commitmentTypes?: CommitmentType[];
}

export const SelectCommitment = (props: Props) => {
  const { holdingId, commitmentId, handleValue, handlePersist, label } = props;
  const commitment = useSelector((state: RootState) => {
    if (commitmentId != null) {
      return getCommitmentById(selectWorkspaceData(state), commitmentId);
    }
  });
  const commitments = useSelector((state: RootState) =>
    getCommitmentsByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const getOptionLabel = React.useCallback(
    (t: CommitmentState) => {
      const commitment = commitments.find(
        (commitment) => commitment.id === t.id,
      );
      return commitment?.id ? commitment.id : '';
    },
    [commitments],
  );

  const isOptionEqualToValue = (
    option: CommitmentState,
    value: CommitmentState,
  ) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={false}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      handleResetValue={() => handleValue(null)}
      options={commitments}
      value={commitment}
      handlePersist={handlePersist}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
