import {
  BondTradeStateVisitor,
  BondTradeVisitable,
} from '~src/data/store/visitors/holding/types/bond/bond-trade-visitor';
import { BondTrade } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Operation } from '@pladdenico/portfolio-api';

export function handleBondTrades(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: BondTrade[],
  subscriptions: Operation[],
) {
  const bondTradeVisitor = new BondTradeStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((bondHolding) => {
    const bondHoldingVisitable = new BondTradeVisitable(bondHolding);
    bondHoldingVisitable.accept(bondTradeVisitor);
  });
  bondTradeVisitor.post();
  return outputs;
}
