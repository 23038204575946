import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  GeneratedReportState,
  selectors,
} from '~src/data/store/reducers/workspace/projects/reports/generated-report/reducer';

const getGeneratedReportState = (state: WorkspaceDataState) =>
  state.workspace.project.report.generatedReports;

interface Id {
  projectId: string;
  id: string;
}

export const getGeneratedReportById = createCachedSelector(
  (state: WorkspaceDataState, _id: Id) => getGeneratedReportState(state),
  (_state: WorkspaceDataState, id: Id) => id,
  (reports, id): GeneratedReportState | undefined => {
    return selectors.selectElementByT(reports, id);
  },
)({
  keySelector: (_state, id) => `${id.projectId}:${id.id}`,
  selectorCreator: createSelector,
});

interface Ids {
  projectId: string;
  ids: string[];
}

export const getGeneratedReportsByIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: Ids) => getGeneratedReportState(state),
  (_state: WorkspaceDataState, ids: Ids) => ids,
  (reports, ids): GeneratedReportState[] => {
    return selectors.selectElementsByTs(
      reports,
      ids.ids.map((id) => {
        return { projectId: ids.projectId, id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
interface ProjectId {
  projectId: string;
}

export const getGeneratedReportsByProjectId = createCachedSelector(
  (state: WorkspaceDataState, _projectId: ProjectId) =>
    getGeneratedReportState(state),
  (_state: WorkspaceDataState, projectId: ProjectId) => projectId.projectId,
  (reports, projectId): GeneratedReportState[] | undefined => {
    return selectors.selectElementsByKey(reports, projectId);
  },
)({
  keySelector: (_state, projectId) => projectId.projectId,
  selectorCreator: createSelector,
});
