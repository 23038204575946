import moment from 'moment';
import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { useDateField } from '~src/utils/form/fields/use-date-field';

export const usePeriodFields = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  periodStartName: TName,
  periodEndName: TName,
  periodStartLabel: string,
  periodEndLabel: string,
  setValue: UseFormSetValue<TFieldValues>,
) => {
  const periodStart = useWatch({ name: periodStartName, control });
  const periodEnd = useWatch({ name: periodEndName, control });

  const onPeriodStartChange = React.useCallback(
    (date: moment.Moment | null) => {
      if (date != null && date.unix() > moment(periodEnd).unix()) {
        setValue(
          periodEndName,
          date.toDate() as PathValue<TFieldValues, Path<TFieldValues>>,
        );
      }
    },
    [periodEnd, periodEndName, setValue],
  );

  const onPeriodEndChange = React.useCallback(
    (date: moment.Moment | null) => {
      if (date != null && date.unix() < moment(periodStart).unix()) {
        setValue(
          periodStartName,
          date.toDate() as PathValue<TFieldValues, Path<TFieldValues>>,
        );
      }
    },
    [periodStart, periodStartName, setValue],
  );

  const periodStartField = useDateField(
    control,
    periodStartName,
    periodStartLabel,
    new Date() as PathValue<TFieldValues, Path<TFieldValues>>,
    onPeriodStartChange,
  );
  const periodEndField = useDateField(
    control,
    periodEndName,
    periodEndLabel,
    new Date() as PathValue<TFieldValues, Path<TFieldValues>>,
    onPeriodEndChange,
  );

  return {
    periodStartField,
    periodEndField,
  };
};
