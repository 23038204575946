import React from 'react';

import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  TextField,
} from '@mui/material';

interface Props {
  handleValue: (subtype: string) => void;
  subtype: string | undefined;
  label: string | undefined;
  subtypes: string[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string | null;
}

interface FilmOptionType {
  title: string;
  inputValue: string;
}

export const EditSubtypeField = React.memo((props: Props) => {
  // const classes = useStyles();
  // const handleSelectChange = (event: SelectChangeEvent<string>) => {
  //   props.handleValue(event.target.value);
  // };

  // const label = props.label ? (
  //   <InputLabel htmlFor="Type">{props.label}</InputLabel>
  // ) : undefined;

  // const menuItems = React.useMemo(() => {
  //   return props.subtypes.map((type) => {
  //     return (
  //       <MenuItem key={type} value={type} disabled={props.disabled}>
  //         {typeToString(type)}
  //       </MenuItem>
  //     );
  //   });
  // }, [props.disabled, props.subtypes]);

  // const disabled =
  //   props.subtypes && props.subtype && props.subtypes.length === 1
  //     ? props.subtypes.some((t) => t === props.subtype)
  //     : false;

  const filter = createFilterOptions<FilmOptionType>();

  const value: FilmOptionType = {
    inputValue: props.subtype ?? '',
    title: props.subtype ?? '',
  };

  const options: FilmOptionType[] = props.subtypes.map((s) => {
    return {
      inputValue: s,
      title: s,
    };
  });

  return (
    <FormControl margin="normal" fullWidth>
      <Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            props.handleValue(newValue);
          } else if (newValue && newValue.inputValue) {
            props.handleValue(newValue.inputValue);
            // // Create a new value from the user input
            // setValue({
            //   title: newValue.inputValue,
            // });
          } else {
            // props.handleValue(newValue?.inputValue);
            // setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title,
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="subtype-autocomplete"
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        sx={{ flex: 1 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Subtype"
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </FormControl>
  );
});
