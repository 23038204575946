import moment from 'moment';
import React from 'react';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { BetaHoldingsComponent } from '~src/domain/workspace/components/project/holding/common/beta-holdings.component';
import { IRRHoldingsComponent } from '~src/domain/workspace/components/project/holding/common/irr-holdings.component';
import { TotalCommitmentsHoldingsComponent } from '~src/domain/workspace/components/project/holding/common/total-commitments.component';
import { TotalValueHoldingsComponent } from '~src/domain/workspace/components/project/holding/common/total-value-holdings.component';
import { VolatilityHoldingsComponent } from '~src/domain/workspace/components/project/holding/common/volatility-holdings.component';
import { SummaryType, SummaryTypes } from '~src/utils/interfaces/summary-type';
import { Period } from '~src/utils/period/period';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  period: Period;
  date: moment.Moment;
  baseCurrency: CurrencyState;
  indexPaper: PaperState;
  useLiveValue: boolean;
  type: SummaryType;
  useSubLabel?: boolean;
}

export const SummaryComponent = React.memo((props: Props) => {
  const { holdings, useSubLabel } = props;
  const subLabel = React.useMemo(() => {
    if (useSubLabel) {
      if (holdings.length <= 2) {
        return holdings.map((holding) => holding.name).join(';');
      }
      return `${holdings.length} holdings`;
    }
  }, [holdings, useSubLabel]);

  if (props.type === SummaryTypes.TotalValue) {
    return <TotalValueHoldingsComponent {...props} subLabel={subLabel} />;
  } else if (props.type === SummaryTypes.Irr) {
    return <IRRHoldingsComponent {...props} subLabel={subLabel} />;
  } else if (props.type === SummaryTypes.Beta) {
    return <BetaHoldingsComponent {...props} subLabel={subLabel} />;
  } else if (props.type === SummaryTypes.Volatility) {
    return <VolatilityHoldingsComponent {...props} subLabel={subLabel} />;
  } else if (props.type === SummaryTypes.TotalCommitments) {
    return <TotalCommitmentsHoldingsComponent {...props} subLabel={subLabel} />;
  }
  return <></>;
});
