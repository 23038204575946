import React from 'react';
import { useDispatch } from 'react-redux';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  RunStatus,
  StatusUpdater,
  useUpdateStatus,
} from '~src/services/xlsx/hooks/use-update-status';
import { AppDispatch } from '~src/store/store';

export type DataImporter = (
  workspace: WorkspaceState,
  data: Map<string, any[]>,
  statusUpdater: StatusUpdater,
) => (dispatch: AppDispatch) => Promise<boolean>;

export const useImport = (
  workspace: WorkspaceState,
  dataImporter: DataImporter,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    clearStatus,
    runStatus: running,
    status,
    setShowStatus,
    showStatus,
    updateStatus,
  } = useUpdateStatus();
  const importer = React.useCallback(
    (data: Map<string, any[]>) => {
      clearStatus();
      setShowStatus(true);
      updateStatus('Started', RunStatus.running, undefined);
      return dispatch(dataImporter(workspace, data, updateStatus))
        .then(() => {
          updateStatus('Success importing', RunStatus.success, undefined);
          return true;
        })
        .catch((err) => {
          updateStatus('Failure importing', RunStatus.failed, err);
          return Promise.resolve(false);
        });
    },
    [
      clearStatus,
      dataImporter,
      dispatch,
      setShowStatus,
      updateStatus,
      workspace,
    ],
  );

  return {
    importer,
    importingData: running,
    importingStatus: status,
    showImportingStatus: showStatus,
    setShowImportingStatus: setShowStatus,
  };
};
