import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { selectWorkspace } from '~src/data/store/modules/workspaces/workspaces/actions';
import { createWorkspace } from '~src/data/store/modules/workspaces/workspaces/requests';
import { CreateWorkspaceFormComponent } from '~src/domain/workspace/components/workspace/form/create-workspace-form.component';
import { AppDispatch } from '~src/store/store';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  open: boolean;
  handleClose: () => void;
  tenantId: string;
}

export const CreateWorkspaceDialogComponent = React.memo((props: Props) => {
  const [workspaceInput, setWorkspaceInput] =
    React.useState<graphqlWorkspaceTypes.CreateWorkspaceInputType>({
      // id: uuid.v1(),
      name: '',
      type: '', // maybe should be null in start?
      // type: WorkspaceType.Portfolio,
      // portfolioRelations: [],
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleCreateWorkspace = (): void => {
    dispatch(createWorkspace(props.tenantId, uuid.v1(), workspaceInput))
      .then((value) => {
        value && dispatch(selectWorkspace(value.id));
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', workspaceInput, JSON.stringify(err));
      });
  };

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create workspace</DialogTitle>
      <DialogContent>
        <CreateWorkspaceFormComponent
          workspaceInput={workspaceInput}
          setWorkspaceInput={setWorkspaceInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateWorkspace} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
