import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  vpsTransactionsActions,
  VpsTransactionState,
} from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import { AppDispatch } from '~src/store/store';

import { VpsTransaction } from './transaction';

export const handlerType = 'transactions';

const header: Array<keyof VpsTransaction> = [
  'date',
  'account',
  'ISIN',
  'security',
  'securityGroup',
  'transactionType',
  'transferType',
  'volume',
  'value',
  'averagePrice',
  'fees',
  'currency',
  'settled',
  'settledDate',
];

const headerMapper = new Map<string, keyof VpsTransaction>([
  ['Trade date', 'date'],
  ['CSD account', 'account'],
  ['ISIN', 'ISIN'],
  ['Security', 'security'],
  ['Security group', 'securityGroup'],
  ['Transaction type', 'transactionType'],
  ['Transfer type', 'transferType'],
  ['Volume', 'volume'],
  ['Amount', 'value'],
  ['Average price', 'averagePrice'],
  ['Fees', 'fees'],
  ['Currency code', 'currency'],
  ['Settled', 'settled'],
  ['Settled date', 'settledDate'],
]);

export const vpsTransactionImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<VpsTransaction> => {
  return {
    type: handlerType,
    header,
    handler: (ts) => {
      console.log(workspace.tenantId, workspace.id, ts);
      const vpsTransactions: VpsTransactionState[] = ts.map((t, index) => {
        return {
          ...t,
          settled: t.settled === 'Yes' ? true : false,
          id: `${t.account}-${t.ISIN}-${t.date.getTime()}-${index}`,
          adapterId: 'vps',
          handled: false,
          transactionId: null,
          holdingId: null,
          groupId: uuid.v1(),
        };
      });
      dispatch(vpsTransactionsActions.upsertManyElements(vpsTransactions));
      return Promise.resolve();
    },
    headerMapper,
  };
};
