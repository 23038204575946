import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import * as isEmail from 'isemail';
import isEmail from 'validator/lib/isEmail';
import { createWorkspaceInvitation } from '~src/data/store/modules/workspaces/workspace-invitations/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getWorkspaceRolesByWorkspaceId } from '~src/data/store/selectors/workspace/workspace-roles/selectors';
import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Error } from '~src/utils/common/error';

import { Alert, Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { CreateWorkspaceInvitationFormComponent } from './create-workspace-invitation-form.component';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
}

export interface WorkspaceInvitationInputForForm {
  workspaceRoleId: number;
  // roleType: string;
  email: string;
}

// function userFromName(users: User[], name: string) {
//   return users.find((user) => {
//     if (user.name) {
//       return user.name.replace(' ', '-') === name;
//     }
//     return false;
//   });
// }

export const CreateUserDialogComponent = React.memo((props: Props) => {
  // const users = useSelector((state: RootState) => selectUsers(state));
  const workspaceRoles = useSelector((state: RootState) =>
    getWorkspaceRolesByWorkspaceId(
      selectWorkspaceData(state),
      props.workspace.id,
    ),
  );
  const [workspaceInvitationInput, setWorkspaceInvitationInput] =
    React.useState<WorkspaceInvitationInputForForm>(() => {
      return {
        // roleType: 'user',
        workspaceRoleId: workspaceRoles.length > 0 ? workspaceRoles[0].id : -1,
        email: '',
      };
    });
  const [errors, setErrors] = React.useState<Error[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const handleCreateWorkspaceInvitation = async (): Promise<void> => {
    let input: graphqlTenantTypes.CreateWorkspaceInvitationInputType;
    if (isEmail(workspaceInvitationInput.email)) {
      input = {
        // roleType: workspaceInvitationInput.roleType,
        workspaceRoleId: workspaceInvitationInput.workspaceRoleId,
        // invitationType: InvitationType.external,
        email: workspaceInvitationInput.email,
        accepted: false,
      };
    } else {
      setErrors((errors) => [
        ...errors,
        {
          message: 'Not an email',
        },
      ]);
      return;
      // } else {
      //   let user = userFromName(users, workspaceInvitationInput.userIdOrEmail);
      //   if (!user) {
      //     user = await fetchUserByEmail(workspaceInvitationInput.userIdOrEmail)(
      //       dispatch
      //     );
      //     if (!user) {
      //       console.log('Not a valid user');
      //       return;
      //     }
      //   }
      //   input = {
      //     // roleType: workspaceInvitationInput.roleType,
      //     workspaceRoleId: workspaceInvitationInput.workspaceRoleId,
      //     invitationType: InvitationType.internal,
      //     userId: user.id,
      //   };
    }
    dispatch(createWorkspaceInvitation(props.workspace.tenantId, input))
      .then((_workspaceInvitation) => {
        props.handleClose();
      })
      .catch((errs: Error[]) => {
        setErrors((errors) => [...errors, ...errs]);
        console.info('FAILED', input, JSON.stringify(errs));
      });
  };

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const errorsElement = errors.map((error, idx) => {
    return (
      <Alert
        key={idx}
        severity="error"
        onClose={() => {
          setErrors((errors) => {
            const idx = errors.findIndex((e) => e.message === error.message);
            return [...errors.slice(0, idx), ...errors.slice(idx + 1)];
          });
        }}
      >
        {error.message}
      </Alert>
    );
  });
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Create workspaceInvitation
      </DialogTitle>
      <DialogContent>
        <CreateWorkspaceInvitationFormComponent
          workspaceInvitationInput={workspaceInvitationInput}
          setWorkspaceInvitationInput={setWorkspaceInvitationInput}
          workspace={props.workspace}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateWorkspaceInvitation} color="primary">
          Save
        </Button>
      </DialogActions>
      {errorsElement}
    </Dialog>
  );
});
