import { Config } from '~src/utils/interfaces/config';
import { configsSelectors } from '../../modules/config/reducer';
import { configFromConfigNormalized } from '../../modules/config/config-normalizer';
import { createInitializeConfig } from '../../modules/config/config-initial';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { createSelector } from 'reselect';

const selectConfig = (state: WorkspaceDataState) => state.config;

interface Props {
  id: string;
}

export const getConfigById = createSelector(
  (state: WorkspaceDataState, props: Props) =>
    configsSelectors.selectById(selectConfig(state), props.id),
  (config): Config => {
    if (config) {
      return configFromConfigNormalized(config);
    }
    return createInitializeConfig();
  },
);
