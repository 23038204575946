import numbro from 'numbro';
import * as React from 'react';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { useContainerSize } from '~src/domain/workspace/components/common/dashboard/hooks/use-container-size.hook';

import { Typography } from '@mui/material';

import { StatisticLabel, useStyles } from './styles';

interface Props {
  totalCommitment: number;
  baseCurrency: CurrencyState;
  subLabel?: string;
}

export const TotalCommitmentComponent = React.memo((props: Props) => {
  const { size, ref } = useContainerSize();
  const { classes } = useStyles();
  const { subLabel, baseCurrency, totalCommitment } = props;

  const subLabelElement = React.useMemo(() => {
    if (subLabel) {
      return <Typography variant="caption">{subLabel}</Typography>;
    }
  }, [subLabel]);

  return (
    <>
      <div ref={ref} className={classes.statistic}>
        {subLabelElement}
        <Typography variant="h4" component="h4" noWrap>
          {numbro(totalCommitment).format({
            thousandSeparated: true,
            // mantissa: 1,
            totalLength: 5,
            average: true,
          })}
        </Typography>
        <StatisticLabel
          // component="p"
          config={{ containerSize: size?.width }}
          className={classes.statisticLabel}
          variant="body2"
        >
          Commitments ({baseCurrency.symbol})
        </StatisticLabel>
      </div>
    </>
  );
});
