import * as React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { FieldsComponent } from './fields.component';

interface Props {
  workspace: WorkspaceState;
  workspaceInput: graphqlWorkspaceTypes.UpdateWorkspaceInputType;
  setWorkspaceInput: (
    workspaceInput: graphqlWorkspaceTypes.UpdateWorkspaceInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const EditFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  return (
    <form className={classes.container} noValidate autoComplete="off">
      <FieldsComponent
        setWorkspaceInput={props.setWorkspaceInput}
        workspaceInput={props.workspaceInput}
      />
    </form>
  );
});
