import { compact } from 'lodash';
import * as React from 'react';
import { AllocationState } from '~src/data/store/reducers/portfolio/allocations/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useAllocationFields } from '~src/hooks/fields/workspace/project/portfolio/use-allocation-fields';
import { Identity } from '~src/utils/interfaces/identity';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { useAllocationActions } from '~src/hooks/actions/workspace/project/portfolio/allocations/use-allocation-actions';
import { AllocationsToolbar } from './allocations-toolbar.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  portfolio: PortfolioState;
  allocations: AllocationState[];
  allocationCreateInputInit: graphqlWorkspaceTypes.CreateAllocationInputType;
}

const useStyles = makeStyles()((theme: Theme) => ({
  // root: {
  //   padding: theme.spacing(3),
  // },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export interface AllocationData extends Identity {
  allocation: AllocationState;
  share: number;
}

function allocationsToData(allocations: AllocationState[]) {
  return allocations.map((allocation) => {
    return {
      id: allocation.id,
      allocation,
      share: 1.0,
    };
  });
}

export const AllocationsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [data, setData] = React.useState<AllocationData[]>([]);
  const { onRowUpdate, onRowDelete } = useAllocationActions(
    props.workspace,
    props.portfolio.id,
  );
  React.useEffect(() => {
    setData(allocationsToData(props.allocations));
  }, [props.allocations]);

  const fields = useAllocationFields(props.workspace.id, props.projectId);

  const [initialSorters] = React.useState<TableField.Sorter<AllocationData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'currency', false),
        TableOperations.createSorterFromFields(fields, 'region', false),
        TableOperations.createSorterFromFields(fields, 'assetClass', false),
        TableOperations.createSorterFromFields(fields, 'assetType', false),
        TableOperations.createSorterFromFields(fields, 'min', false),
        TableOperations.createSorterFromFields(fields, 'max', false),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<AllocationData>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        editable={{ onRowUpdate, onRowDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        isSelected={isSelected}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onRowDelete,
    onRowUpdate,
    selectedData,
    sorters,
  ]);

  return (
    <div className={classes.root}>
      <AllocationsToolbar
        workspace={props.workspace}
        projectId={props.projectId}
        portfolio={props.portfolio}
        allocationCreateInputInit={props.allocationCreateInputInit}
        fields={fields}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
      />
      <div className={classes.content}>{tableComponent}</div>
    </div>
  );
});
