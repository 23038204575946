import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import {
  HoldingState,
  BaseHoldingId,
} from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useExportHoldingDialogComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/use-export-holding-dialog.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
// import history from '~src/navigation/history';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

export const useHoldingCardMenuComponent = (
  workspace: WorkspaceState,
  projectId: string,
  holding: HoldingState,
  deleteHolding: () => Promise<BaseHoldingId[] | undefined>,
) => {
  const [editing, setEditing] = React.useState(false);
  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();
  const { open, isShowing, close } = useVisible();
  const { dialog: exportDialog, modal: exportModal } =
    useExportHoldingDialogComponent(workspace, [holding]);
  const navigate = useNavigate();
  const gotoHolding = React.useCallback(
    (
      tenantId: string,
      workspaceId: string,
      projectId: string,
      holding: HoldingState,
    ) => {
      navigate(holdingBasePath(tenantId, workspaceId, projectId, holding.id));
    },
    [navigate],
  );

  const menuElement = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            gotoHolding(workspace.tenantId, workspace.id, projectId, holding);
          }}
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditing(true);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={open}>Delete</MenuItem>
        <MenuItem onClick={exportModal.open}>Export</MenuItem>
        <ConfirmDialogComponent
          isOpen={isShowing}
          handler={() => {
            deleteHolding().then(() => handleClose());
          }}
          close={close}
          objectString="holding"
        />
        {exportDialog}
      </Menu>
    ),
    [
      anchorEl,
      isOpen,
      handleClose,
      open,
      exportModal.open,
      isShowing,
      close,
      exportDialog,
      gotoHolding,
      workspace.tenantId,
      workspace.id,
      projectId,
      holding,
      deleteHolding,
    ],
  );

  const menuButtonElement = React.useMemo(
    () => (
      <IconButton onClick={handleOpen} color="inherit">
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOpen],
  );

  return {
    menuElement,
    menuButtonElement,
    editing,
    setEditing,
    handleOpen,
    handleClose,
  };
};
