import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { assetTypes } from '@pladdenico/models';

export const useAssetTypesField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  label: string,
  defaultValue: PathValue<TFieldValues, TName>,
) => {
  return (
    <Controller
      name={tname}
      // rules={{ required: true }}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>{label}</InputLabel>
          <Select
            // variant="outlined"
            multiple
            value={field.value ?? []}
            onChange={field.onChange}
            // input={<Input />}
            input={<OutlinedInput label={label} />}
            displayEmpty
            renderValue={(selected) => {
              return selected ? (selected as string[]).join(', ') : '';
            }}
            // label="Types"
          >
            {assetTypes.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={field.value ? field.value.indexOf(name) > -1 : false}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
