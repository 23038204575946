import { handleLoanHoldings } from '~src/data/store/modules/holdings/loan/handler';
import { loanHoldingsActions } from '~src/data/store/reducers/holding/holding-types/loan/loan-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchLoanHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetLoanHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetLoanHoldingsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getLoanHoldings) {
        return handleLoanHoldings(dispatch, data.getLoanHoldings);
      }
      return [];
    });
  };
}

export function createLoanHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateLoanHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateLoanHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateLoanHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createLoanHoldings) {
        return handleLoanHoldings(dispatch, data.createLoanHoldings);
      }
      return [];
    });
  };
}

export function updateLoanHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateLoanHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateLoanHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateLoanHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateLoanHoldings) {
        return handleLoanHoldings(dispatch, data.updateLoanHoldings);
      }
      return [];
    });
  };
}

export function upsertLoanHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertLoanHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertLoanHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertLoanHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertLoanHoldings) {
        return handleLoanHoldings(dispatch, data.upsertLoanHoldings);
      }
      return [];
    });
  };
}

export function deleteLoanHoldings(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteLoanHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteLoanHoldingsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteLoanHoldings) {
        dispatch(
          loanHoldingsActions.removeManyElements(
            data.deleteLoanHoldings.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
