import {
  vpsTransactionsReducer,
  initialVpsTransactionState,
} from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';

import { combineReducers } from '@reduxjs/toolkit';

export const vpsAdapterInitialState = {
  vpsTransactions: initialVpsTransactionState,
};

export const vpsAdapterReducer = combineReducers({
  vpsTransactions: vpsTransactionsReducer,
});
