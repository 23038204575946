import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertCryptos } from '~src/data/store/modules/assets/cryptos/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'cryptos';

export interface Crypto {
  id: string;
  assetId: string;
  accountNumber?: Maybe<string>;
  currencyId?: Maybe<string>;
  bank?: Maybe<string>;
  description?: Maybe<string>;
}

const header: Array<keyof Crypto> = [
  'id',
  'assetId',
  'accountNumber',
  'currencyId',
  'bank',
  'description',
];

export const cryptoExportHandler = (data: Crypto[]): ExportHandler<Crypto> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const cryptoImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Crypto> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertCryptos(workspace.tenantId, workspace.id, ts)),
  };
};
