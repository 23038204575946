import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseFundTradeId {
  fundHoldingId: string;
  id: string;
}

export type FundTradeState = Omit<
  graphqlWorkspaceTypes.FundTrade,
  'fundHolding' | 'transfer' | 'position'
> & {
  fundHoldingId: string;
  transferId: string;
  positionId: string;
};

export const initialFundTradeState = [];

const selectKeyId = (fundTrade: BaseFundTradeId) => fundTrade.fundHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseFundTradeId, b: BaseFundTradeId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseFundTradeId,
  FundTradeState,
  string
>(initialFundTradeState, selectKeyId, keyComparator, tComparator);

export const fundTradesSlice = createBaseRecordSlice('fundTrades', adapter);
export const selectors = adapter.selectors;

export const fundTradesReducer = fundTradesSlice.reducer;
export const fundTradesActions = fundTradesSlice.actions;
