import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertProjects } from '~src/data/store/modules/workspaces/projects/base/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'projects';

export interface Project {
  id: string;
  name?: Maybe<string>;
  type?: Maybe<string>;
}

const header: Array<keyof Project> = ['id', 'name', 'type'];

export const projectExportHandler = (
  data: Project[],
): ExportHandler<Project> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const projectImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Project> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertProjects(workspace.tenantId, workspace.id, ts)),
  };
};
