import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { EditBond } from '~src/components/fields/workspace/asset/edit/asset-types/edit-bond';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { BondHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/create-action';

interface Props {
  workspace: WorkspaceState;
  bondHoldingInput: BondHoldingInputType;
  setBondHoldingInput: (
    bondHoldingInput: graphqlWorkspaceTypes.CreateBondHoldingInputType,
  ) => void;
}

export const CreateBondHoldingFieldsComponent = React.memo((props: Props) => {
  const handleBondChange = (bondId: string) => {
    props.setBondHoldingInput({
      ...props.bondHoldingInput,
      bondId,
    });
  };

  return (
    <EditBond
      handleValue={handleBondChange}
      bondId={props.bondHoldingInput.bondId}
      workspace={props.workspace}
    />
  );
});
