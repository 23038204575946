import { multipleAction } from '~src/data/base/old/action';
import { GROUPS } from '~src/data/store/modules/groups/action';
import { parseGroup } from '~src/data/store/modules/groups/parser';
import { GroupState } from '~src/data/store/modules/groups/state';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  UserStateVisitor,
  UserVisitable,
  UserVisitor,
} from '~src/data/store/visitors/workspace/role/user-visitor';
import { Group } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface GroupVisitor {
  visit(group: GroupVisitable): void;
  post(): void;
  userVisitor: UserVisitor;
}

export class GroupVisitable implements Visitable<GroupVisitor> {
  constructor(private _group: Group) {}
  public accept(visitor: GroupVisitor) {
    if (this._group.users) {
      this._group.users.forEach((user) => {
        if (user) {
          const userVisitable = new UserVisitable(user);
          userVisitable.accept(visitor.userVisitor);
        }
      });
    }
    visitor.visit(this);
  }

  public parse(): GroupState {
    return parseGroup(this._group);
  }
}

export class GroupStateVisitor implements GroupVisitor {
  private _groups: GroupState[];
  public userVisitor: UserVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._groups = [];
    this.userVisitor = new UserStateVisitor(this._dispatch);
  }

  public visit(visitable: GroupVisitable): void {
    this._groups.push(visitable.parse());
  }

  post() {
    this._dispatch(multipleAction(this._groups, GROUPS));
    this.userVisitor.post();
  }
}
