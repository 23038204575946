import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  requestStatusSelectors,
  RequestStatusState,
} from '~src/data/store/reducers/common/request-status/reducer';
import { RequestDataState } from '~src/data/store/reducers/reducers';

const getRequestStatusState = (state: RequestDataState) => state.requestStatus;

export const getRequestStatusById = createCachedSelector(
  (state: RequestDataState, _id: string) => getRequestStatusState(state),
  (__state: RequestDataState, id: string) => id,
  (requestStatuses, id): RequestStatusState | undefined => {
    return requestStatusSelectors.selectElement(requestStatuses, {
      id,
    });
  },
)({
  keySelector: (_state, workspaceId, projectId) =>
    `${workspaceId}:${projectId}`,
  selectorCreator: createSelector,
});
