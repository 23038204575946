import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { commitmentBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/commitment/paths';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  colors,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { CommitmentInputData } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-data-types';
import { Path, useForm } from 'react-hook-form';
import { getCommitmentFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/commitment/get-field-config';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { CommitmentFormComponent } from '~src/domain/workspace/components/project/commitment/form/commitment-form.component';
import { CommitmentData } from '~src/domain/workspace/components/project/commitment/commitment-data';

const PREFIX = 'CommitmentCardComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  commitment: `${PREFIX}-commitment`,
  detailed: `${PREFIX}-detailed`,
  description: `${PREFIX}-description`,
  menuButton: `${PREFIX}-menuButton`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    flex: 1,
    backgroundColor: colors.blue[50],
    // minWidth: 150,
    // height: 165,
    // width: 160,
    // maxWidth: 450,
  },

  [`& .${classes.commitment}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.detailed}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.description}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 4,
    overflow: 'hidden',
  },

  [`& .${classes.menuButton}`]: {
    textAlign: 'center',
  },
}));

interface Props {
  commitment: CommitmentData;
  workspace: WorkspaceState;
  projectId: string;
}

export const CommitmentCardComponent = React.memo((props: Props) => {
  const { commitment, projectId, workspace } = props;
  const editDialog = useVisible();
  const menu = useMenu();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(
      commitmentBasePath(
        workspace.tenantId,
        workspace.id,
        projectId,
        commitment.commitment.holdingId,
        commitment.commitment.id,
      ),
    );
  };
  const getName = React.useCallback(
    (name: Path<CommitmentInputData>): Path<CommitmentInputData> => {
      return name;
    },
    [],
  );

  const {
    control,
    // setValue: setValueInit,
    setValue,
    // handleSubmit,
    // watch,
    // getValues,
  } = useForm<CommitmentInputData>({
    // context,
    defaultValues: {
      base: {
        ...commitment.commitment,
        transferIds: [],
      },
      data: undefined,
    },
  });

  const fieldConfig = getCommitmentFieldConfig();

  const editElement = (
    <DialogComponent
      dialogProps={{ open: editDialog.isShowing, onClose: editDialog.close }}
    >
      <DialogContentComponent
        title="Edit commitment"
        handleOnSave={() => {
          console.log('save commitment');
          return Promise.resolve();
        }}
        handleCancel={editDialog.close}
      >
        <CommitmentFormComponent
          projectId={projectId}
          holdingId={commitment.commitment.holdingId}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          commitmentTypes={[]}
          // commitmentTypes={commitmentTypes}
        />
      </DialogContentComponent>
    </DialogComponent>
  );

  const commitmentMenu = (
    <>
      <Menu
        anchorEl={menu.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={menu.isOpen}
        onClose={menu.handleClose}
      >
        <MenuItem onClick={() => handleOnClick()}>View</MenuItem>
        <MenuItem onClick={() => editDialog.open()}>Edit</MenuItem>
      </Menu>
      {editElement}
    </>
  );

  const holding = useSelector((state: RootState) => {
    return getHoldingById(selectWorkspaceData(state), {
      id: commitment.commitment.holdingId,
      projectId,
    });
  });

  const holdingElement = React.useMemo(() => {
    if (holding) {
      return (
        <>
          <Typography variant="caption" color="textSecondary">
            Holding: {holding.id}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {holding.name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {holding.description}
          </Typography>
        </>
      );
    }
  }, [holding]);

  return (
    <StyledPaper className={classes.paper} variant="outlined">
      <Grid container>
        <Grid container item xs={8} justifyContent="space-between">
          <Grid item className={classes.menuButton} alignSelf="center">
            <IconButton onClick={menu.handleOpen} color="inherit">
              <MoreVertIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary" component="p">
              {commitment.commitment.id}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.description}
            >
              {commitment.commitment.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" color="textSecondary" component="p">
              {moment(commitment.commitment.commitmentDate).format(
                'DD.MM.YYYY',
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {commitment.commitment.type}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {commitment.commitment.value}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={4}>
          <Grid item xs={12}>
            {holdingElement}
          </Grid>
        </Grid>
      </Grid>
      {commitmentMenu}
    </StyledPaper>
  );
});
