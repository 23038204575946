import moment from 'moment';
import createCachedSelector from 're-reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getPaperByFundId } from '~src/data/store/selectors/finance/papers/selectors';
import { quoteHoldingValuation } from '~src/data/store/selectors/finance/valuation/quote-holding-valuation';
import { getFundPositionsByFundHoldingId } from '~src/data/store/selectors/holding/holding-types/fund/fund-position/selectors';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

import { Trade } from '@pladdenico/models';

import { getExchangeRateState } from '../../exchange-rates/selectors';
import { getQuotesByFundId } from '../../quotes/fund/fund-quotes';

interface Props {
  workspaceId: string;
  holdingId: string;
  fundHoldingId: string;
  fundId: string;
  date: moment.Moment;
  currency: CurrencyState;
  useLiveValue: boolean;
}

export const getFundHoldingValuation = createCachedSelector(
  (state: DataState, props: Props) =>
    getValuationsByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holdingId,
    ),
  (state: DataState, props: Props) =>
    getFundPositionsByFundHoldingId(
      selectWorkspaceDataFromData(state),
      props.fundHoldingId,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (state: DataState, props: Props) =>
    getPaperByFundId(state, {
      workspaceId: props.workspaceId,
      fundId: props.fundId,
    }),
  (state: DataState, props: Props) =>
    getQuotesByFundId(state, {
      fundId: props.fundId,
      workspaceId: props.workspaceId,
    }),
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    valuations,
    fundPositions,
    date,
    currency,
    exchangeRateState,
    paper,
    quotes,
    useLiveValue,
  ) => {
    const trades: Trade[] = [];

    return quoteHoldingValuation(
      date.toDate(),
      currency,
      exchangeRateState,
      valuations,
      fundPositions,
      paper,
      quotes ?? [],
      trades,
      useLiveValue,
    );
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holdingId}:${props.fundHoldingId}:${
      props.fundId
    }:${props.date.unix()}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});
