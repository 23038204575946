import { EmailState } from '~src/data/store/reducers/person/emails/reducer';
import { Email } from '~src/services/graphql/workspace/client/graphql';

export function parseEmail(workspaceId: string, email: Email): EmailState {
  const personId = email.person?.id ?? '';
  return {
    id: email.id,
    workspaceId,
    personId,
    value: email.value,
  };
}
