import moment from 'moment';

// time,price,quantity,source,buyer,seller,initiator
export interface Trade {
  time: Date;
  price: number;
  quantity: number;
  source: string;
  buyer: string;
  seller: string;
  initiator: string;
}

export interface TradeProperties {
  time?: boolean;
  price?: boolean;
  quantity?: boolean;
  source?: boolean;
  buyer?: boolean;
  seller?: boolean;
  initiator?: boolean;
}

export function parseTrade(trade: Trade) {
  return {
    time: moment(trade.time).toDate(),
    price: +trade.price,
    quantity: +trade.quantity,
    source: trade.source,
    buyer: trade.buyer,
    seller: trade.seller,
    initiator: trade.initiator,
  };
}
