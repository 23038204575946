import 'reactflow/dist/style.css';
import './index.css';

import React from 'react';
import { useDispatch } from 'react-redux';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  Node,
  NodeTypes,
  ReactFlowInstance,
  useOnSelectionChange,
} from 'reactflow';
import { forecastsActions } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { createDropItem } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-drop-item';
import { createFlowStore } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-store';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { NodeType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/node-type';
import { NodeConfig } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/node-config';
import { Sidebar } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/sidebar';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { AppDispatch } from '~src/store/store';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';

import { Box, Button, Grid } from '@mui/material';

import { restoreFlowFromJson, saveFlowToJson } from './flow-save-restore';

const snapGrid: [number, number] = [20, 20];

export const useFlowDrag = (
  scenario: Scenario,
  budget: Budget,
  forecast: ForecastState,
  flowElement: FlowElementState,
  nodeTypes: NodeTypes,
  initialFlow: {
    nodes: Node<NodeData, NodeType>[];
    edges: Edge[];
  },
) => {
  const [nodes, setNodes] = React.useState<Node<NodeData, NodeType>[]>(
    initialFlow.nodes,
  );
  const [edges, setEdges] = React.useState(initialFlow.edges);
  const useStore = React.useMemo(
    () => createFlowStore(initialFlow.nodes, initialFlow.edges),
    [initialFlow.edges, initialFlow.nodes],
  );
  const {
    onConnect,
    onEdgesChange,
    onNodesChange,
    onEdgesSet,
    onNodesSet,
    onNodesDataChange,
  } = useStore.getState();

  React.useEffect(() => {
    const unsubNodes = useStore.subscribe((state) => setNodes(state.nodes));
    const unsubEdges = useStore.subscribe((state) => setEdges(state.edges));
    return () => {
      unsubNodes();
      unsubEdges();
    };
  }, [useStore]);

  const reactFlowWrapper = React.useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    React.useState<ReactFlowInstance | null>(null);

  // const evaluate = React.useCallback(() => {
  //   evaluateFlow && evaluateFlow(nodes, edges);
  // }, [edges, evaluateFlow, nodes]);

  const dispatch = useDispatch<AppDispatch>();

  const save = React.useCallback(() => {
    if (reactFlowInstance) {
      const flowElementState: FlowElementState = {
        ...flowElement,
        flow: saveFlowToJson(reactFlowInstance),
      };
      dispatch(
        forecastsActions.upsertOneElement({
          ...forecast,
          element: flowElementState,
        }),
      );
    }
  }, [dispatch, flowElement, forecast, reactFlowInstance]);

  const restore = React.useCallback(() => {
    if (flowElement.flow) {
      const { edges, nodes, viewport } = restoreFlowFromJson<
        NodeData,
        NodeType
      >(flowElement.flow);
      onNodesSet(nodes);
      onEdgesSet(edges);
      reactFlowInstance?.setViewport(viewport);
    }
  }, [flowElement.flow, onEdgesSet, onNodesSet, reactFlowInstance]);

  React.useEffect(() => {
    restore();
  }, [restore]);

  const onDragOver = React.useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const [currentNodeId, setCurrentNodeId] = React.useState<string | null>(null);
  useOnSelectionChange({
    onChange: ({ nodes }) => {
      if (nodes && nodes.length > 0) {
        setCurrentNodeId(nodes[0].id);
      }
    },
  });

  const onDrop = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const cur = reactFlowWrapper.current;
      if (
        cur != null &&
        reactFlowInstance != null &&
        event.dataTransfer != null
      ) {
        const reactFlowBounds = cur.getBoundingClientRect();
        const type = event.dataTransfer.getData(
          'application/reactflow',
        ) as NodeType;

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
          return;
        }

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        console.log(event.clientX, reactFlowBounds.left);
        console.log(event.clientY, reactFlowBounds.top);

        const newNode = createDropItem(type, position);

        onNodesChange([
          {
            item: newNode,
            type: 'add',
          },
        ]);
      }
    },
    [onNodesChange, reactFlowInstance],
  );

  const flowComponent = (
    <Box
      className="dndflow"
      sx={{ height: '100%', background: primaryBackgroundColor.light }}
    >
      <Grid container>
        <Grid item xs={2} sx={{ overflow: 'auto', height: '100%' }}>
          <Button variant="text" onClick={save}>
            save
          </Button>
          <Button variant="text" onClick={() => restore()}>
            restore
          </Button>
          {/* <button onClick={() => evaluate()}>evaluate</button> */}
          <Sidebar />
        </Grid>
        <Grid item xs={7}>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              proOptions={{ hideAttribution: true }}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              nodeTypes={nodeTypes}
              onDrop={onDrop}
              onDragOver={onDragOver}
              snapToGrid={true}
              snapGrid={snapGrid}
              multiSelectionKeyCode={'ControlLeft'}
              fitView
            >
              <Controls />
              <Background variant={BackgroundVariant.Dots} gap={12} size={2} />
            </ReactFlow>
          </div>
        </Grid>
        <Grid item xs={3} sx={{ overflow: 'auto', height: '100%' }}>
          <NodeConfig
            scenario={scenario}
            budget={budget}
            currentNodeId={currentNodeId}
            nodes={nodes}
            edges={edges}
            onNodesDataChange={onNodesDataChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
  return { flowComponent, nodes, edges };
};
