import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { PeriodicIrrComponent } from '~src/components/irr/periodic-irr.component';
import { Filter } from '~src/utils/common/filter';
import { getHoldingsXIRR } from '~src/data/store/selectors/finance/xirr/holdings-xirr';
import { createSubperiods, Period } from '~src/utils/period/period';
import { Irr } from '~src/utils/finance/irr';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState, RootState } from '~src/data/store/reducers/reducers';
import {
  selectWorkspaceData,
  selectData,
} from '~src/data/store/selectors/selectors';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { PeriodType } from '~src/utils/period/period-type';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { IrrConfigData } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/irr/menu.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  date: moment.Moment;
  period: Period;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
  periodType: PeriodType;
  irrConfigData: IrrConfigData;
}

interface IrrsProps {
  workspaceId: string;
  projectId: string;
  periods: Period[];
  holdings: HoldingState[];
  currency: CurrencyState;
  date: moment.Moment;
  filter: Filter;
}

const selectIrrsForPeriods = createSelector(
  (state: DataState, _props: IrrsProps) => state,
  (_state: DataState, props: IrrsProps) => props.workspaceId,
  (_state: DataState, props: IrrsProps) => props.projectId,
  (_state: DataState, props: IrrsProps) => props.periods,
  (_state: DataState, props: IrrsProps) => props.holdings,
  (_state: DataState, props: IrrsProps) => props.currency,
  (_state: DataState, props: IrrsProps) => props.date,
  (_state: DataState, props: IrrsProps) => props.filter,
  (
    state,
    workspaceId,
    projectId,
    periods,
    holdings,
    currency,
    date,
    filter,
  ) => {
    const irrs: Irr[] = [];
    periods.forEach((period) => {
      // const startDate = period.startDate;
      // const endDate = period.endDate;
      irrs.push({
        value: getHoldingsXIRR(state, {
          workspaceId,
          projectId,
          currency,
          date,
          holdings,
          startDate: period.start,
          endDate: period.end,
          filter,
          useLiveValue: true,
        }),
        period,
      });
    });
    return irrs;
  },
);

const dateLevelTitles = new Map<
  PeriodType,
  {
    name: string;
    value: string;
  }
>();

dateLevelTitles.set('month', {
  name: 'Monthly IRR',
  value: 'month',
});
dateLevelTitles.set('quarter', {
  name: 'Quarterly IRR',
  value: 'quarter',
});
dateLevelTitles.set('year', {
  name: 'Yearly IRR',
  value: 'year',
});

export const ProjectIrrComponent = React.memo((props: Props) => {
  const {
    baseCurrency,
    date,
    holdings,
    period,
    project,
    periodType,
    workspace,
    irrConfigData,
  } = props;
  // const [dateLevel, setDateLevel] = React.useState('year');
  const [periods, setPeriods] = React.useState<Period[]>([]);
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) => {
    return getHoldingFilterById(selectWorkspaceData(state), holdingFilterId);
  });
  React.useEffect(() => {
    setPeriods(createSubperiods(period, periodType));
  }, [period, periodType]);
  const irrs = useSelector((state: RootState) =>
    selectIrrsForPeriods(selectData(state), {
      workspaceId: workspace.id,
      projectId: project.id,
      currency: baseCurrency,
      date: date,
      filter,
      holdings: holdings,
      periods,
    }),
  );

  const dateLevelTitle = dateLevelTitles.get(periodType);
  // const format: BarChartFormat = {
  //   axis: {
  //     lineStroke: '#FFFFFF',
  //     tickStroke: '#AAAAAA',
  //     label: {
  //       fill: '#FFFFFF',
  //     },
  //   },
  //   labelList: {
  //     fill: '#AAAAAA',
  //   },
  //   tooltip: {
  //     stroke: '#0000AA',
  //     fill: '#180C4F',
  //   },
  //   bar: '#8884d8',
  // };
  const format = irrConfigData.barChartFormat;
  return (
    <PeriodicIrrComponent
      title={
        dateLevelTitle !== undefined
          ? dateLevelTitle.name
          : '#NodateLevelTitle#'
      }
      irrs={irrs}
      // zoomHandler={changeDateLevel}
      format={format}
    />
  );
});
