import React from 'react';
// import { useDispatch } from 'react-redux';
// import { updateHoldingDashboardConfigs } from '~src/data/store/modules/holdings/configs/dashboard-configs/requests';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useComponents } from '~src/domain/workspace/components/common/dashboard/hooks/use-components.hook';
import { holdingDashboardElements } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/hooks/holding-dashboard-elements';
import { useHoldingDashboardConfig } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/hooks/use-holding-dashboard-config.hook';
import { UsageMode } from '~src/utils/interfaces/usage-mode';

import { HoldingType } from '@pladdenico/models';

import { DashboardComponentComponent } from '../dashboard-component.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  dashboardConfig: HoldingDashboardConfigState;
  config: HoldingConfigState;
  dashboardElements: DashboardElementState[];
  holding: HoldingState;
  loading: boolean;
}
export const useDashboardComponents = (props: Props) => {
  const {
    workspace,
    project,
    dashboardConfig,
    config,
    dashboardElements,
    holding,
    loading,
  } = props;
  const {
    layouts,
    updateLayouts,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  } = useHoldingDashboardConfig(workspace, config, dashboardConfig);

  const dashboardComponentsForHolding = React.useMemo(
    () =>
      holdingDashboardElements.filter((component) => {
        if (
          (component.type === 'paper' || component.type === 'stock') &&
          holding.type !== HoldingType.Stock
        ) {
          return false;
        } else if (
          component.type === 'bond' &&
          holding.type !== HoldingType.Bond
        ) {
          return false;
        } else if (
          component.type === 'fund' &&
          holding.type !== HoldingType.Fund
        ) {
          return false;
        }
        return true;
      }),
    [holding.type],
  );

  const dashboardElement = React.useCallback(
    (
      active: boolean,
      setActive: (id: string) => void,
      setInactive: (id: string) => void,
      remove: (id: string) => void,
      updated: (
        id: string,
        element: Partial<DashboardElementState>,
      ) => Promise<{ id: string }>,
      element: DashboardElementState,
    ) => (
      <DashboardComponentComponent
        workspace={workspace}
        project={project}
        holding={holding}
        dashboardConfig={dashboardConfig}
        key={element.id}
        id={element.id}
        active={active}
        setActive={() => setActive(element.id)}
        setInactive={() => setInactive(element.id)}
        loading={loading}
        remove={remove}
        element={element}
        updated={updated}
      />
    ),
    [dashboardConfig, holding, loading, project, workspace],
  );

  const { container, dialog, open } = useComponents(
    dashboardConfig.compactType,
    UsageMode.edit,
    dashboardElements,
    dashboardElement,
    layouts,
    updateLayouts,
    dashboardComponentsForHolding,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  );

  return {
    container,
    dialog,
    open,
  };
};
