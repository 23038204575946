// import * as React from 'react';
// import history from '~src/navigation/history';

import { useNavigate } from 'react-router-dom';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { paperRootPath } from '~src/navigation/paths/workspace/finance/paper/paths';

import { Link, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableField } from '@pladdenico/table';

// import { sortBy } from 'lodash';

// function handlePaperClick(paperId: string) {
//   navigate(paperBasePath(paperId));
// }

const useStyles = makeStyles()((_theme: Theme) => ({
  symbol: {
    maxWidth: '15em',
  },
}));

export function usePaperTableFields(
  tenantId: string,
  workspaceId: string,
): TableField.Field<PaperState>[] {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const fields: TableField.Field<PaperState>[] = [
    {
      id: 'exchange',
      name: 'exchange',
      field: 'exchange',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.exchange,
      renderView: (editProps) => {
        return (
          <Typography variant="body1" className={classes.symbol} noWrap>
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                navigate(
                  paperRootPath(tenantId, workspaceId, editProps.data.id),
                )
              }
            >
              {editProps.data.exchange}
            </Link>
          </Typography>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="symbol"
            value={editProps.data.exchange}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'symbol',
      name: 'symbol',
      field: 'symbol',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.symbol,
      renderView: (editProps) => {
        return (
          <Typography variant="body1" className={classes.symbol} noWrap>
            {editProps.data.symbol}
          </Typography>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="symbol"
            value={editProps.data.symbol}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
  ];
  return fields;
}
