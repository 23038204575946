import { handleAddresses } from '~src/data/store/modules/people/addresses/handlers';
import {
  addressesActions,
  BaseAddressId,
} from '~src/data/store/reducers/person/addresses/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchAddresses(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetAddressesDocument;
    const variables: graphqlWorkspaceTypes.GetAddressesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getAddresses) {
        return handleAddresses(
          dispatch,
          tenantId,
          workspaceId,
          data.getAddresses,
        );
      }
      return undefined;
    });
  };
}

export function createAddresses(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateAddressInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateAddressesDocument;
    const variables: graphqlWorkspaceTypes.CreateAddressesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createAddresses) {
        return handleAddresses(
          dispatch,
          tenantId,
          workspaceId,
          data.createAddresses,
        );
      }
      throw new Error('Could not create address');
      // return undefined;
    });
  };
}

export function updateAddresses(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateAddressInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateAddressesDocument;
    const variables: graphqlWorkspaceTypes.UpdateAddressesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateAddresses) {
        return handleAddresses(
          dispatch,
          tenantId,
          workspaceId,
          data.updateAddresses,
        );
      }
      return [];
    });
  };
}

export function upsertAddresses(
  tenantId: string,
  workspaceId: string,
  // personId: string,
  inputs: graphqlWorkspaceTypes.UpsertAddressInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertAddressesDocument;
    const variables: graphqlWorkspaceTypes.UpsertAddressesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertAddresses) {
        return handleAddresses(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertAddresses,
        );
      }
      return [];
    });
  };
}

export function deleteAddresses(
  tenantId: string,
  workspaceId: string,
  ids: BaseAddressId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteAddressesDocument;
    const variables: graphqlWorkspaceTypes.DeleteAddressesMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteAddresses) {
        const deletedIds = data.deleteAddresses;
        dispatch(
          addressesActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
