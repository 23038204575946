import React, { useState } from 'react';

export const useVisible = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = React.useCallback(() => {
    setIsShowing(!isShowing);
  }, [isShowing]);

  const close = React.useCallback(() => {
    setIsShowing(false);
    // console.log('closed!');
  }, []);

  const open = React.useCallback(() => {
    setIsShowing(true);
    // console.log('opened');
  }, []);

  return {
    isShowing,
    toggle,
    close,
    open,
    setIsShowing,
  };
};
