import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getNordnetAccounts } from '~src/data/store/selectors/data-import/adapter/nordnet/transactions/selectors';
import { selectDataImportData } from '~src/data/store/selectors/selectors';
import { NordnetImportComponent } from '~src/domain/workspace/components/data/adapters/nordnet/import.component';
// import { NordnetImportedTransactionsComponent } from '~src/domain/workspace/components/data/adapters/nordnet/imported-transactions.component';
import {
  NordnetAccount,
  SelectAccount,
} from '~src/domain/workspace/components/project/transaction/importer/adapter/nordnet/select-account';
import { nordnetImporter } from '~src/services/xlsx/handlers/adapters/nordnet/importer';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

export const NordnetTransactionImporterComponent = React.memo(
  (props: Props) => {
    const { workspace } = props;
    const [account, setAccount] = React.useState<NordnetAccount>();
    const accountIds = useSelector((state: RootState) =>
      getNordnetAccounts(selectDataImportData(state)),
    );

    const accountIdsOptions: NordnetAccount[] = React.useMemo(() => {
      return [
        {
          id: null,
          name: 'All',
        },
        ...accountIds.map((account) => {
          return {
            id: account,
            name: account,
          };
        }),
      ];
    }, [accountIds]);

    const importElement = (
      <NordnetImportComponent
        workspace={workspace}
        dataImporter={nordnetImporter()}
      />
    );

    // const importedTransactionsElement = account && (
    //   <NordnetImportedTransactionsComponent
    //     workspace={workspace}
    //     projectId={projectId}
    //     accountId={account.id}
    //   />
    // );

    return (
      <>
        {importElement}
        <SelectAccount
          handleValue={setAccount}
          accounts={accountIdsOptions}
          account={account}
          label={'Account'}
        />
        {/* {importedTransactionsElement} */}
      </>
    );
  },
);
