import {
  DashboardElementModel,
  DashboardElementsBaseType,
  PortfolioDashboardElementsType,
} from '@pladdenico/models';

export const portfolioDashboardElements: DashboardElementModel<
  PortfolioDashboardElementsType | DashboardElementsBaseType
>[] = [
  {
    type: 'header',
    title: 'Header',
    description: '',
  },
  {
    type: 'summary',
    title: 'Summary',
    description: '',
  },
  {
    type: 'favoriteHoldings',
    title: 'Favorite holdings',
    description: '',
  },
  {
    type: 'valuationGeographical',
    title: 'Holdings valuation map',
    description: '',
  },
  {
    type: 'allocation',
    title: 'Allocation',
    description: '',
  },
  {
    type: 'allocationGoal',
    title: 'Allocation goal',
    description: '',
  },
  {
    type: 'contributors',
    title: 'Portfolio contributors',
    description: '',
  },
  {
    type: 'irr',
    title: 'Portfolio IRR',
    description: '',
  },
  {
    type: 'portfolioAssetGroupsIrr',
    title: 'Portfolio asset grouped IRRs',
    description: '',
  },
  {
    type: 'portfolioAssetGroupValuation',
    title: 'Portfolio asset grouped valuations',
    description: '',
  },
  {
    type: 'costs',
    title: 'Costs',
    description: '',
  },
  {
    type: 'events',
    title: 'Events',
    description: '',
  },
  {
    type: 'ownership',
    title: 'Ownership',
    description: '',
  },
  {
    type: 'organization',
    title: 'Organization',
    description: '',
  },
  {
    type: 'goal',
    title: 'Goal',
    description: '',
  },
  {
    type: 'waterfall',
    title: 'Waterfall',
    description: '',
  },
  {
    type: 'text',
    title: 'Text',
    description: '',
  },
  {
    type: 'image',
    title: 'Image',
    description: '',
  },
  {
    type: 'commitments',
    title: 'Commitments',
    description: '',
  },
  {
    type: 'efficientFrontier',
    title: 'Efficient Frontier',
    description: '',
  },
  {
    type: 'custom',
    title: 'Custom',
    description: '',
  },
];
