export const defaultIdBackgroundColor = '#FFFFFF';
export const defaultIdFontColor = '#000000';

export const defaultCategoryBackgroundColor = '#FFFFFF';
export const defaultCategoryFontColor = '#000000';

export const defaultAssetClassBackgroundColor = '#FFFFFF';
export const defaultAssetClassFontColor = '#000000';

export const defaultSectorBackgroundColor = '#FFFFFF';
export const defaultSectorFontColor = '#000000';

export const defaultRegionBackgroundColor = '#FFFFFF';
export const defaultRegionFontColor = '#000000';

export const defaultCurrencyBackgroundColor = '#FFFFFF';
export const defaultCurrencyFontColor = '#000000';

// export const defaultIdBackgroundColor = '#1E2965';
// export const defaultIdFontColor = '#ABD2DC';

// export const defaultCategoryBackgroundColor = '#2E265F';
// export const defaultCategoryFontColor = '#ABD2DC';

// export const defaultAssetClassBackgroundColor = '#2D2280';
// export const defaultAssetClassFontColor = '#ABD2DC';

// export const defaultSectorBackgroundColor = '#433991';
// export const defaultSectorFontColor = '#ABD2DC';

// export const defaultRegionBackgroundColor = '#5E569F';
// export const defaultRegionFontColor = '#ABD2DC';

// export const defaultCurrencyBackgroundColor = '#6A639F';
// export const defaultCurrencyFontColor = '#ABD2DC';
