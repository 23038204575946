import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { Paper } from '~src/services/graphql/finance/client/graphql';

export function parsePaper(paper: Paper): PaperState {
  return {
    id: paper.id,
    exchange: paper.exchange,
    symbol: paper.symbol,
    name: paper.name,
    market: paper.market,
    isin: paper.isin,
    currencyId: paper.currency?.id ?? '',
  };
}
