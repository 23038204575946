import { handleCryptoHoldings } from '~src/data/store/modules/holdings/crypto/handler';
import { cryptoHoldingsActions } from '~src/data/store/reducers/holding/holding-types/crypto/crypto-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchCryptoHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetCryptoHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetCryptoHoldingsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getCryptoHoldings) {
        return handleCryptoHoldings(dispatch, data.getCryptoHoldings);
      }
      return [];
    });
  };
}

export function createCryptoHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateCryptoHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateCryptoHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateCryptoHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createCryptoHoldings) {
        return handleCryptoHoldings(dispatch, data.createCryptoHoldings);
      }
      return [];
    });
  };
}

export function updateCryptoHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateCryptoHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateCryptoHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateCryptoHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateCryptoHoldings) {
        return handleCryptoHoldings(dispatch, data.updateCryptoHoldings);
      }
      return [];
    });
  };
}

export function upsertCryptoHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertCryptoHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertCryptoHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertCryptoHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertCryptoHoldings) {
        return handleCryptoHoldings(dispatch, data.upsertCryptoHoldings);
      }
      return [];
    });
  };
}

export function deleteCryptoHoldings(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteCryptoHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteCryptoHoldingsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteCryptoHoldings) {
        dispatch(
          cryptoHoldingsActions.removeManyElements(
            data.deleteCryptoHoldings.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
