import moment from 'moment';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { Config } from '~src/utils/interfaces/config';
import { PivotOption } from '~src/utils/interfaces/pivot-option';
import { UsageMode } from '~src/utils/interfaces/usage-mode';
import { Period } from '~src/utils/period/period';

export interface ConfigNormalized {
  id: string;
  date: number;
  // currencyOptions: any;
  indexPaper: PaperState;
  period: { startDate: number; endDate: number };
  minimumValuationForHolding: number;
  minDate: number;
  pivotOptions: PivotOption[];
  useLiveValue: boolean;
  baseCurrencyId: string;
  usageMode: UsageMode;
  compactType: CompactType;
}

export function configToConfigState(
  id: string,
  config: Config,
): ConfigNormalized {
  return {
    id,
    date: config.date.unix(),
    // currencyOptions: config.currencyOptions,
    indexPaper: config.indexPaper,
    period: {
      startDate: config.period.start.unix(),
      endDate: config.period.end.unix(),
    },
    minimumValuationForHolding: config.minimumValuationForHolding,
    minDate: config.minDate.unix(),
    pivotOptions: [...config.pivotOptions],
    useLiveValue: config.useLiveValue,
    baseCurrencyId: config.baseCurrencyId,
    usageMode: config.usageMode,
    compactType: config.compactType,
  };
}

export function configFromConfigNormalized(config: ConfigNormalized) {
  return {
    // currencyOptions: config.currencyOptions,
    date: moment.unix(config.date),
    indexPaper: config.indexPaper,
    minDate: moment.unix(config.minDate),
    minimumValuationForHolding: config.minimumValuationForHolding,
    period: new Period(
      moment.unix(config.period.startDate),
      moment.unix(config.period.endDate),
    ),
    pivotOptions: config.pivotOptions,
    useLiveValue: config.useLiveValue,
    baseCurrencyId: config.baseCurrencyId,
    usageMode: config.usageMode,
    compactType: config.compactType,
  };
}
