import moment from 'moment';
import numbro from 'numbro';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
// import history from '~src/navigation/history';

import { colors, Grid, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'BaseHoldingCardComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  container: `${PREFIX}-holding`,
  transfer: `${PREFIX}-asset`,
  description: `${PREFIX}-description`,
  currency: `${PREFIX}-currency`,
  date: `${PREFIX}-date`,
  value: `${PREFIX}-value`,
  transferType: `${PREFIX}-transferType`,
  holding: `${PREFIX}-holdingName`,
};

const Root = styled('div')(({ theme }) => ({
  //{ theme }
  [`& .${classes.paper}`]: {
    flex: 1,
    // minWidth: 150,
    // height: 165,
    // width: 160,
    // maxWidth: 450,
  },

  [`& .${classes.container}`]: {
    // margin: theme.spacing(1),
  },

  [`& .${classes.transfer}`]: {
    // flex: 1,
    // display: 'inline-block',
    // textAlign: 'right',
    // marginRight: theme.spacing(1),
  },

  [`& .${classes.description}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 4,
    overflow: 'hidden',
  },

  [`& .${classes.currency}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.date}`]: {
    // padding: theme.spacing(2),
    // textAlign: 'center',
  },

  [`& .${classes.value}`]: {
    // textTransform: 'uppercase',
  },

  [`& .${classes.transferType}`]: {},
  [`& .${classes.holding}`]: {},
}));

interface Props {
  transfer: TransferState;
  holding: HoldingDenormalizedWithEntity | undefined;
  workspace: WorkspaceState;
  projectId: string;
}

export const TransferCardComponent = React.memo((props: Props) => {
  const { workspace, transfer, projectId, holding } = props;

  const currency = useSelector((state: RootState) =>
    getCurrencyById(selectFinancialData(state), { id: transfer.currencyId }),
  );

  const navigate = useNavigate();
  const costElement =
    transfer.cost && transfer.cost > 0 ? (
      <Typography
        variant="caption"
        display="inline"
        className={classes.transferType}
      >
        (cost {currency?.symbol} {transfer.cost})
      </Typography>
    ) : undefined;
  let typeString = transfer.type.toString();
  if (transfer.subtype) {
    typeString = `${typeString} (${transfer.subtype})`;
  }

  const dateString = moment(transfer.date).format('YYYY-MM-DD');

  const valueElement = (
    <Typography variant="body1" display="inline" className={classes.value}>
      {numbro(transfer.value).format({
        thousandSeparated: true,
        mantissa: 2,
        optionalMantissa: true,
      })}
    </Typography>
  );

  const holdingElement = holding ? (
    <Link
      component="button"
      variant="body2"
      onClick={() =>
        navigate(
          holdingBasePath(
            workspace.tenantId,
            workspace.id,
            projectId,
            holding.holding.id,
          ),
        )
      }
    >
      {holding?.holding.name}
    </Link>
  ) : undefined;

  return (
    <Root>
      <Paper className={classes.paper} variant="outlined">
        <Grid
          container
          className={classes.container}
          // justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid
            container
            item
            xs={3}
            alignItems="center"
            justifyContent="center"
            sx={{ p: 1, backgroundColor: colors.blue[100] }}
          >
            {holdingElement}
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ p: 1 }}
          >
            <Typography variant="body1" className={classes.transferType}>
              {typeString}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ m: 1 }}
          >
            <div className={classes.transfer}>
              <Typography
                variant="caption"
                display="inline"
                className={classes.currency}
              >
                {currency?.symbol}
              </Typography>
              {valueElement} {costElement}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ m: 1 }}
          >
            <Typography variant="body1" className={classes.date}>
              {dateString}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Root>
  );
});
