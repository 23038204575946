import { CommitmentType } from '~src/utils/interfaces/commitment-type';

import { HoldingType } from '@pladdenico/models';

export const holdingTypeToCommitmentTypes = new Map([
  [HoldingType.BankNote, [CommitmentType.normal]],
  [HoldingType.Bond, [CommitmentType.normal]],
  [HoldingType.Crypto, [CommitmentType.normal]],
  [HoldingType.Fund, [CommitmentType.normal]],
  [HoldingType.Loan, [CommitmentType.normal]],
  [HoldingType.PrivateEquityFund, [CommitmentType.normal]],
  [HoldingType.Stock, [CommitmentType.normal]],
  [HoldingType.Unknown, [CommitmentType.normal]],
  [HoldingType.Warrant, [CommitmentType.normal]],
]);

export const getCommitmentTypes = (holdingType: HoldingType) => {
  const transferTypes = holdingTypeToCommitmentTypes.get(holdingType);
  return transferTypes ?? [];
};
