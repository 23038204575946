import React from 'react';
import { EditPortfolios } from '~src/components/fields/workspace/project/portfolio/edit-portfolios';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useFieldsComponent } from '~src/domain/workspace/components/project/holding/form/use-fields.component';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType;
  setHoldingInput: (
    holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType,
  ) => void;
  holdingTypeFieldsComponent: JSX.Element | undefined;
  portfolios: PortfolioState[];
  setPortfolios: (portfolios: PortfolioState[]) => void;
  holdingTypes?: HoldingType[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { nameElement, descriptionElement, typeElement } = useFieldsComponent({
    holdingInput: props.holdingInput,
    setHoldingInput: props.setHoldingInput,
    holdingTypes: props.holdingTypes,
  });
  const { setPortfolios } = props;

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {nameElement}
      {descriptionElement}
      {typeElement}
      {props.holdingTypeFieldsComponent}
      <EditPortfolios
        workspace={props.workspace}
        projectId={props.projectId}
        handleValue={setPortfolios}
        labelled={true}
        portfolios={props.portfolios}
      />
    </form>
  );
});
