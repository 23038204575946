import {
  WarrantHoldingStateVisitor,
  WarrantHoldingVisitable,
} from '~src/data/store/visitors/holding/types/warrant/warrant-holding-visitor';
import { WarrantHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleWarrantHoldings(
  dispatch: any,
  outputs: WarrantHolding[],
) {
  const warrantHoldingVisitor = new WarrantHoldingStateVisitor(dispatch);

  outputs.forEach((warrantHolding) => {
    const warrantHoldingVisitable = new WarrantHoldingVisitable(warrantHolding);
    warrantHoldingVisitable.accept(warrantHoldingVisitor);
  });
  warrantHoldingVisitor.post();
  return outputs;
}
