import { workspaceInvitationsActions } from '~src/data/store/reducers/workspace/workspace-invitations/reducer';
import * as graphqlTenantTypes from '~src/services/graphql/tenant/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryTenantContext,
} from '@pladdenico/portfolio-api';

import { handleWorkspaceInvitations } from './handler';

export function fetchWorkspaceInvitations(
  tenantId: string,
  userIds: number[] | null = null,
) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.GetWorkspaceInvitationsDocument;
    const variables: graphqlTenantTypes.GetWorkspaceInvitationsQueryVariables =
      {
        userIds,
      };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getWorkspaceInvitations) {
        return handleWorkspaceInvitations(
          tenantId,
          dispatch,
          data.getWorkspaceInvitations,
        );
      }
      return undefined;
    });
  };
}

export function createWorkspaceInvitation(
  tenantId: string,
  input: graphqlTenantTypes.CreateWorkspaceInvitationInputType,
) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.CreateWorkspaceInvitationDocument;
    const variables: graphqlTenantTypes.CreateWorkspaceInvitationMutationVariables =
      {
        input,
      };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createWorkspaceInvitation) {
        return handleWorkspaceInvitations(tenantId, dispatch, [
          data.createWorkspaceInvitation,
        ]);
      }
      throw new Error('Could not create workspaceInvitation');
      // return undefined;
    });
  };
}

export function updateWorkspaceInvitation(
  tenantId: string,
  input: graphqlTenantTypes.UpdateWorkspaceInvitationInputType,
) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.UpdateWorkspaceInvitationDocument;
    const variables: graphqlTenantTypes.UpdateWorkspaceInvitationMutationVariables =
      {
        input,
      };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateWorkspaceInvitation) {
        return handleWorkspaceInvitations(tenantId, dispatch, [
          data.updateWorkspaceInvitation,
        ]);
      }
      throw new Error('Could not update workspaceInvitation');
    });
  };
}

export function deleteWorkspaceInvitations(id: string, tenantId: string) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.DeleteWorkspaceInvitationDocument;
    const variables: graphqlTenantTypes.DeleteWorkspaceInvitationMutationVariables =
      {
        input: id,
      };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteWorkspaceInvitation) {
        dispatch(workspaceInvitationsActions.removeOne({ id }));
      }
      return undefined;
    });
  };
}

export function acceptWorkspaceInvitation(tenantId: string, id: string) {
  return (dispatch: any) => {
    const node = graphqlTenantTypes.AcceptWorkspaceInvitationDocument;
    const variables: graphqlTenantTypes.AcceptWorkspaceInvitationMutationVariables =
      {
        input: id,
      };
    const context: QueryTenantContext = {
      type: QueryContextTypes.tenant,
      tenantId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.acceptWorkspaceInvitation) {
        return handleWorkspaceInvitations(tenantId, dispatch, [
          data.acceptWorkspaceInvitation,
        ]);
      }
      return undefined;
    });
  };
}
