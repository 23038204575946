import React from 'react';
import { useSelector } from 'react-redux';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  getTransactionById,
  getTransactionsByProjectId,
} from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';

interface Props {
  handleValue: (transaction: TransactionState) => void;
  transactionId: string;
  labelled: boolean;
  projectId: string;
}

export const EditTransactionField = React.memo((props: Props) => {
  const transactions = useSelector((state: RootState) =>
    getTransactionsByProjectId(selectWorkspaceData(state), props.projectId),
  );
  const transaction = useSelector((state: RootState) =>
    getTransactionById(selectWorkspaceData(state), {
      id: props.transactionId,
      projectId: props.projectId,
    }),
  );

  return (
    <Autocomplete
      options={transactions}
      getOptionLabel={(transaction: TransactionState) =>
        transaction.title ? transaction.title : ''
      }
      style={{ width: 300 }}
      value={transaction}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: TransactionState | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.labelled ? 'Transaction' : undefined}
          fullWidth
          margin="normal"
        />
      )}
    />
  );
});
