import { Operation } from '@pladdenico/portfolio-api';
import { parseBondPosition } from '~src/data/store/modules/holdings/bond/position/parser';
import { subscribeToBondPositions } from '~src/data/store/modules/holdings/bond/position/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  bondPositionsActions,
  BondPositionState,
} from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import { BondPosition } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface BondPositionVisitor {
  visit(position: BondPositionVisitable): BondPositionState;
  post(): void;
}

export class BondPositionVisitable implements Visitable<BondPositionVisitor> {
  constructor(private _position: BondPosition) {}
  public accept(visitor: BondPositionVisitor) {
    return visitor.visit(this);
  }

  public parse(): BondPositionState {
    return parseBondPosition(this._position);
  }
}

export class BondPositionStateVisitor implements BondPositionVisitor {
  private _bondPositions: BondPositionState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._bondPositions = [];
  }

  public visit(position: BondPositionVisitable): BondPositionState {
    // this._bondPositions.push(position.parse());
    const positionState = position.parse();
    this._bondPositions.push(positionState);
    return positionState;
  }

  post() {
    this._dispatch(
      bondPositionsActions.upsertManyElements(this._bondPositions, {
        shouldAutobatch: true,
      }),
    );
    subscribeToBondPositions(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._bondPositions,
      this._subscriptions,
    );
  }
}
