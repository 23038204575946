import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AdapterTransaction } from '~src/data/store/selectors/data-import/adapter/selectors';
import { CreateTransactionComponent } from '~src/domain/workspace/components/data/adapters/create-transaction.component';
import { NordnetEditTransactionFormComponent } from '~src/domain/workspace/components/data/adapters/nordnet/form/edit-form.component';
import { VpsEditTransactionFormComponent } from '~src/domain/workspace/components/data/adapters/vps/form/edit-form.component';
import { TransactionByIdComponent } from '~src/domain/workspace/components/project/transaction/transaction.component';
import {
  needsBankNoteHolding,
  needsHolding,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/get-transaction-type';
import { NordnetTransactionState } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import { VpsTransactionState } from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';

import { Box, colors, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  transactions: AdapterTransaction[];
  handleSuccess: (transaction: AdapterTransaction) => void;
}

export const InitiateTransactionComponent = React.memo((props: Props) => {
  const { workspace, projectId, transactions, handleSuccess } = props;

  const transactionsWithoutHolding = React.useMemo(() => {
    return transactions.filter((t) => {
      if (t.adapterId === 'dnb' || t.adapterId === 'vps') {
        return t.holdingId == null;
      } else if (t.adapterId === 'nordnet') {
        const nordnetTransaction = t as NordnetTransactionState;
        if (needsHolding(nordnetTransaction) && t.holdingId == null) {
          return true;
        }
        return (
          needsBankNoteHolding(nordnetTransaction) &&
          nordnetTransaction.bankNoteHoldingId == null
        );
      }
    });
  }, [transactions]);

  if (transactions.some((t) => t.transactionId != null)) {
    const transactionId = transactions[0].transactionId;
    if (transactionId) {
      return (
        <Box sx={{ backgroundColor: colors.blue[100], width: '100%' }}>
          <TransactionByIdComponent
            workspace={workspace}
            projectId={projectId}
            transactionId={transactionId}
          />
        </Box>
      );
    }
  }

  if (transactionsWithoutHolding.length > 0) {
    const missingHoldingElement = transactionsWithoutHolding.map(
      (transaction) => {
        if (transaction.adapterId === 'vps') {
          const vpsTransaction = transaction as VpsTransactionState;
          return (
            <VpsEditTransactionFormComponent
              key={transaction.id}
              workspace={props.workspace}
              projectId={props.projectId}
              handleSuccess={handleSuccess}
              handleClose={() => console.log('close')}
              initialFormValues={vpsTransaction}
            />
          );
        } else if (transaction.adapterId === 'nordnet') {
          const nordnetTransaction = transaction as NordnetTransactionState;
          return (
            <NordnetEditTransactionFormComponent
              key={transaction.id}
              workspace={props.workspace}
              projectId={props.projectId}
              handleSuccess={handleSuccess}
              handleClose={() => console.log('close')}
              initialFormValues={nordnetTransaction}
            />
          );
        }
      },
    );
    return (
      <Box sx={{ backgroundColor: colors.blue[100], width: '100%' }}>
        <Typography variant="h5">Provide holding for transactions</Typography>
        {missingHoldingElement}
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: colors.blue[100], width: '100%' }}>
      <CreateTransactionComponent
        workspace={workspace}
        projectId={projectId}
        transactions={transactions}
      />
    </Box>
  );
});
