import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseInvestmentId,
  investmentsSelectors,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getInvestments = (state: WorkspaceDataState) => {
  return state.holding.asset.privateEquityFund.investments;
};

export const getInvestmentsByFundId = createCachedSelector(
  (state: WorkspaceDataState) => getInvestments(state),
  (_state: WorkspaceDataState, fundId: string) => fundId,
  (investmentsState, fundId) => {
    const privateEquityFunds = investmentsSelectors.selectElementsByKey(
      investmentsState,
      fundId,
    );
    return privateEquityFunds ? privateEquityFunds : [];
  },
)({
  keySelector: (_state, privateEquityFundId) => privateEquityFundId,
  selectorCreator: createSelector,
});

// export const privateEquityFundByIdSelector = (privateEquityFunds: IInvestment[], privateEquityFundId: string) => {
//   return __OLDsortedFind(privateEquityFunds, { id: privateEquityFundId } as IInvestment, (a) => a.id);
// };

export const getInvestmentById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseInvestmentId>) =>
    getInvestments(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseInvestmentId>) =>
    baseId.fundId,
  (_state: WorkspaceDataState, baseId: Partial<BaseInvestmentId>) => baseId.id,
  (investments, fundId, privateEquityFundId) => {
    if (!fundId || !privateEquityFundId) {
      return undefined;
    }
    return investmentsSelectors.selectElementByT(investments, {
      fundId,
      id: privateEquityFundId,
    });
  },
)({
  keySelector: (_state, fundId, privateEquityFundId) =>
    `${fundId}-${privateEquityFundId}`,
  selectorCreator: createSelector,
});
