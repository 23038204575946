import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { DashboardElement } from '~src/services/graphql/workspace/client/graphql';

export function parseDashboardElement(
  element: DashboardElement,
): DashboardElementState {
  return {
    id: element.id,
    name: element.name,
    type: element.type,
    config: JSON.parse(element.config),
  };
}
