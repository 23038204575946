import { Navigate, RouteObject } from 'react-router-dom';
import { MainComponent } from '~src/domain/portal/app/main.component';
import { PortalComponent } from '~src/domain/portal/components/portal.component';

export const useRoot = (): RouteObject[] => {
  return [
    {
      path: 'portal/*',
      element: <Navigate to="/" replace />,
    },
    {
      path: 'portal/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/*',
      element: (
        <MainComponent>
          <PortalComponent />
        </MainComponent>
      ),
    },
  ];
};
