import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { TransferStatus } from '@pladdenico/models';

export const useTransferStatusField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  fieldConfig?: FieldConfig,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<
        TFieldValues,
        TName // 'data.stockTrade.shares'
      >,
      fieldState: ControllerFieldState,
      disabled?: boolean,
    ) => (
      <FormControl fullWidth margin="normal" disabled={disabled}>
        <InputLabel htmlFor="Type">Status</InputLabel>
        <Select
          value={field.value}
          onChange={field.onChange}
          inputProps={{
            name: 'status',
            id: 'status',
          }}
        >
          <MenuItem value={TransferStatus.confirmed}>Confirmed</MenuItem>
          <MenuItem value={TransferStatus.expected}>Expected</MenuItem>
          <MenuItem value={TransferStatus.cancelled}>Cancelled</MenuItem>
        </Select>
      </FormControl>
    ),
    [],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName} // {'data.stockTrade.shares'}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
