import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectPreviousPosition } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { useCalculatePositionShares } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/use-calculate-position-shares';
import { useTransferCostField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-cost-field';
import { useTransferCurrencyField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-currency-field';
import { useTransferDateField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-date-field';
import { useTransferStatusField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-status-field';
import { useTransferTypeField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-type-field';
import { useTransferValueField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-value-field';
import { useTransferStockPositionSharesField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/stock/use-position-shares-field';
import { useTransferStockTradeSharePriceField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/stock/use-trade-share-price-field';
import { useTransferStockTradeSharesField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/stock/use-trade-shares-field';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

import { Grid } from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { useTransferCommitmentField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-commitment-field';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

interface Props<T extends FieldValues> {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState;
  control: Control<T>;
  getName: (name: Path<TransferInputData>) => Path<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: TransferFieldConfig;
  transferTypes?: TransferType[];
}

export const StockTransferFieldsControlledComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const {
    control,
    setValue,
    fieldConfig,
    getName,
    transferTypes,
    workspace,
    holding,
    projectId,
  } = props;
  const stockHolding = useSelector((state: RootState) =>
    getStockHoldingByHoldingId(selectWorkspaceData(state), holding.id),
  );

  // const holdingPrefix = `holdings.${index}` as const;
  // const transferDataPrefix = `${holdingPrefix}.transferInputData` as const;

  // const transferTypes = useWatch({
  //   control,
  //   name: getName('config.possibleTransferTypes'),
  //   // name: `${holdingPrefix}.config.possibleTransferTypes`,
  // });

  const tradeShares = useWatch({
    control,
    name: getName('data.trade.shares'),
  });

  const transferType = useWatch({
    control,
    name: getName('base.type'),
  }) as TransferType;

  // const transferValue = useWatch({
  //   control,
  //   name: `${transferDataPrefix}.base.value`,
  // });

  const sharePrice = useWatch({
    control,
    name: getName('data.trade.sharePrice'),
  });

  const transferDate = useWatch({
    control,
    name: getName('base.date'),
  });

  const lastStockPosition: StockPositionState | undefined = useSelector(
    (state: RootState) =>
      stockHolding &&
      selectPreviousPosition(selectWorkspaceData(state), {
        stockHoldingId: stockHolding.id,
        date: transferDate,
      }),
  ) as StockPositionState;

  const positionShares = useWatch({
    control,
    name: getName('data.position.shares'),
  });

  const stockPositionDate = useWatch({
    control,
    name: getName('data.position.date'),
  });

  const newShares = useCalculatePositionShares(
    lastStockPosition?.shares ?? 0,
    tradeShares ?? 0,
    transferType,
  );

  React.useEffect(() => {
    if (newShares !== positionShares) {
      setValue(
        getName('data.position.shares'),
        newShares as PathValue<T, Path<T>>,
      );
    }
  }, [setValue, positionShares, newShares, getName]);

  React.useEffect(() => {
    const value =
      tradeShares != null && sharePrice != null
        ? tradeShares * sharePrice
        : null;
    if (value != null) {
      setValue(getName('base.value'), value as PathValue<T, Path<T>>);
    }
  }, [getName, setValue, sharePrice, tradeShares]);

  React.useEffect(() => {
    if (
      transferDate &&
      (stockPositionDate == null ||
        transferDate.getTime() !== stockPositionDate.getTime())
    ) {
      setValue(getName('data.position.date'), transferDate);
    }
  }, [setValue, transferDate, getName, stockPositionDate]);
  const { element: stockTradeSharesElement, visible: stockTradeSharesVisible } =
    useTransferStockTradeSharesField(
      control,
      getName('data.trade.shares'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.data?.stock?.tradeShares,
    );

  const {
    element: stockTradeSharePriceElement,
    visible: stockTradeSharePriceVisible,
  } = useTransferStockTradeSharePriceField(
    control,
    getName('data.trade.sharePrice'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.data?.stock?.sharePrice,
  );
  const { element: stockPositionElement, visible: stockPositionVisible } =
    useTransferStockPositionSharesField(
      control,
      getName('data.position.shares'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.data?.stock?.position,
    );
  const { element: typeElement, visible: typeVisible } = useTransferTypeField(
    control,
    getName('base.type'),
    undefined,
    fieldConfig?.base.type,
    transferTypes,
  );
  // const { element: typeElement, visible: typeVisible } = useTransferTypeField(
  //   control,
  //   index,
  //   fieldConfig?.'transfer'?.type,
  //   transferTypes
  // );
  const { element: dateElement, visible: dateVisible } = useTransferDateField(
    control,
    getName('base.date'),
    new Date() as PathValue<T, Path<T>>,
    fieldConfig?.base.date,
  );
  const { element: currencyElement, visible: currencyVisible } =
    useTransferCurrencyField(
      control,
      getName('base.currencyId'),
      undefined,
      fieldConfig?.base.currency,
    );
  const { element: commitmentElement, visible: commitmentVisible } =
    useTransferCommitmentField(
      control,
      getName('base.commitmentId'),
      undefined,
      workspace,
      projectId,
      holding.id,
      fieldConfig?.base.commitment,
    );
  const { element: valueElement, visible: valueVisible } =
    useTransferValueField(
      control,
      getName('base.value'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.base.value,
    );
  const { element: costElement, visible: costVisible } = useTransferCostField(
    control,
    getName('base.cost'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.base.cost,
  );
  const { element: statusElement, visible: statusVisible } =
    useTransferStatusField(
      control,
      getName('base.status'),
      undefined,
      fieldConfig?.base.status,
    );

  return (
    <>
      <Grid container columnSpacing={1}>
        {typeVisible && (
          <Grid item sm={4}>
            {typeElement}
          </Grid>
        )}
        {dateVisible && (
          <Grid item sm={4}>
            {dateElement}
          </Grid>
        )}
        {currencyVisible && (
          <Grid item sm={4}>
            {currencyElement}
          </Grid>
        )}
        {stockTradeSharesVisible && (
          <Grid item sm={4}>
            {stockTradeSharesElement}
          </Grid>
        )}
        {stockTradeSharePriceVisible && (
          <Grid item sm={4}>
            {stockTradeSharePriceElement}
          </Grid>
        )}
        {costVisible && (
          <Grid item sm={4}>
            {costElement}
          </Grid>
        )}
        {stockPositionVisible && (
          <Grid item sm={4}>
            {stockPositionElement}
          </Grid>
        )}
        {valueVisible && (
          <Grid item sm={4}>
            {valueElement}
          </Grid>
        )}
        {statusVisible && (
          <Grid item sm={4}>
            {statusElement}
          </Grid>
        )}{' '}
        {commitmentVisible && (
          <Grid item sm={4}>
            {commitmentElement}
          </Grid>
        )}
      </Grid>
    </>
  );
};
