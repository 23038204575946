import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { useExportHoldingDialogComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/use-export-holding-dialog.component';

import FilterListIcon from '@mui/icons-material/FilterList';
import MoreIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
// import ViewListIcon from '@mui/icons-material/ViewList';
import { TableDrawers, TableOperations } from '@pladdenico/table';

export const useToolbar = (
  workspace: WorkspaceState,
  _project: ProjectState,
  holdingAlgoData: TableOperations.AlgorithmData<HoldingDenormalizedWithEntity>,
) => {
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);
  const menuId = 'entity-menu';

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const holdings = React.useMemo(
    () => holdingAlgoData.filtered.map((h) => h.holding),
    [holdingAlgoData.filtered],
  );

  const { modal: exportModal, dialog: exportDialog } =
    useExportHoldingDialogComponent(workspace, holdings);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const entityMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={exportModal.open}>Export</MenuItem>
      {exportDialog}
    </Menu>
  );

  const holdingsFiltersComponent = holdingAlgoData.filters.map(
    (filter, index) => {
      return (
        <TableDrawers.FilterComponent
          key={index}
          dispatchFilter={holdingAlgoData.dispatchFilters}
          filter={filter}
          textFieldProps={{ sx: { marginBottom: 1 } }}
        />
      );
    },
  );

  const holdingsSortersComponent = holdingAlgoData.sorters.map(
    (sorter, index) => {
      return (
        <TableDrawers.SorterComponent
          key={index}
          dispatchSorter={holdingAlgoData.dispatchSorters}
          sorter={sorter}
        />
      );
    },
  );

  const actions = (
    <>
      <IconButton onClick={handleFilterDrawerOpen}>
        <FilterListIcon />
      </IconButton>
      <IconButton onClick={handleSortDrawerOpen}>
        <SortIcon />
      </IconButton>
      <IconButton onClick={handleMenuOpen} color="inherit">
        <MoreIcon />
      </IconButton>
      {entityMenu}
    </>
  );

  const filterDrawer = (
    <TableDrawers.FilterDrawerComponent
      open={filterOpen}
      closeDrawer={() => setFilterOpen(false)}
      filtersComponent={
        <Box>
          <Typography variant="overline">Holding</Typography>
          {holdingsFiltersComponent}
        </Box>
      }
    />
  );

  const sorterDrawer = (
    <TableDrawers.SorterDrawerComponent
      open={sortOpen}
      closeDrawer={() => setSortOpen(false)}
      sortersComponent={<>{holdingsSortersComponent}</>}
    />
  );

  return { actions, filterDrawer, sorterDrawer };
};
