import React from 'react';
import { useSelector } from 'react-redux';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  getRegionById,
  getRegions,
} from '~src/data/store/selectors/common/regions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import TextField from '@mui/material/TextField';

interface Props {
  handleValue: (region: RegionState) => void;
  regionId: string | undefined;
  labelled: boolean;
}

export const EditRegionField = React.memo((props: Props) => {
  const regions = useSelector((state: RootState) =>
    getRegions(selectWorkspaceData(state)),
  );
  const region = useSelector((state: RootState) =>
    getRegionById(selectWorkspaceData(state), { id: props.regionId ?? null }),
  );

  return (
    <Autocomplete
      // multiple
      options={regions}
      getOptionLabel={(region: RegionState) => region.name}
      // style={{ width: 300 }}
      value={region ? region : null}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: RegionState | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.labelled ? 'Region' : undefined}
          fullWidth
        />
      )}
    />
  );
});
