import React from 'react';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Overlay = styled(Button)(() => ({
  position: 'absolute',
  top: '25%',
  // background: 'rgba(255, 255, 255, 0.1)',
  color: '#f1f1f1',
  // width: '100%',
  transition: '.5s ease',
  opacity: 0,
  fontSize: '20px',
  padding: '20px',
  textAlign: 'center',
  zIndex: 10,
}));

interface Props {
  file: FileState;
  // url: { id: string; url: string } | undefined;
  deleteFile?: () => void;
  downloadFile?: () => void;
  selectFile?: () => void;
  editFile?: () => void;
}

interface FileListItemContainerProps extends React.PropsWithChildren<unknown> {
  selectFile?: () => void;
}

export const FileListItemContainerComponent = (
  props: FileListItemContainerProps,
) => {
  const [overlayStyle, setOverlayStyle] = React.useState({ opacity: 0 });
  const useElement = props.selectFile && (
    <Overlay style={{ ...overlayStyle }} variant="outlined">
      Use
    </Overlay>
  );
  return (
    <Box
      sx={{
        position: 'relative',
        justifyContent: 'flex-start',
        display: 'flex',
        height: '100%',
      }}
      onMouseEnter={(_e) => {
        setOverlayStyle({ opacity: 1 });
      }}
      onMouseLeave={(_e) => {
        setOverlayStyle({ opacity: 0 });
      }}
      onClick={(e) => {
        props.selectFile && props.selectFile();
        e.stopPropagation();
      }}
    >
      {useElement}
      {props.children}
    </Box>
  );
};

export const FileComponent = (props: Props) => {
  const { file, selectFile, editFile, downloadFile, deleteFile } = props;

  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();
  const deleteModal = useVisible();

  const deleteElement = React.useMemo(() => {
    if (deleteFile) {
      return (
        <MenuItem
          onClick={() => {
            deleteModal.open();
            handleClose();
          }}
        >
          Delete file
          <IconButton aria-label={`star ${file.name}`}>
            <DeleteIcon />
          </IconButton>
        </MenuItem>
      );
    }
  }, [deleteFile, deleteModal, handleClose, file.name]);
  const confirmElement = React.useMemo(() => {
    if (deleteFile) {
      return (
        <ConfirmDialogComponent
          isOpen={deleteModal.isShowing}
          handler={() => {
            deleteFile();
            deleteModal.close();
          }}
          close={deleteModal.close}
          objectString="file"
        />
      );
    }
  }, [deleteFile, deleteModal]);

  const menuElement = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={editFile}>Edit</MenuItem>
        <MenuItem onClick={downloadFile}>Download</MenuItem>
        {deleteElement}
        {confirmElement}
      </Menu>
    ),
    [
      anchorEl,
      isOpen,
      handleClose,
      editFile,
      downloadFile,
      deleteElement,
      confirmElement,
    ],
  );

  const menuButtonElement = React.useMemo(
    () => (
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOpen],
  );

  return (
    <>
      <FileListItemContainerComponent selectFile={selectFile}>
        <Grid container>
          <Grid item xs={11}>
            <Box>
              <Typography variant="body1">{file.name}</Typography>
              <Typography variant="caption">{file.description}</Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            {menuButtonElement}
          </Grid>
        </Grid>
        {menuElement}
      </FileListItemContainerComponent>
    </>
  );
};
