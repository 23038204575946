import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createPhones } from '~src/data/store/modules/people/phones/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { PhoneState } from '~src/data/store/reducers/person/phones/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { PhoneDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
}

export const CreatePhoneDialogComponent = React.memo((props: Props) => {
  const [phoneInput, setPhoneInput] =
    React.useState<graphqlWorkspaceTypes.CreatePhoneInputType>({
      // id: uuid.v1(),
      id: '',
      value: '',
      personId: props.person.id,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSavePhone = (): Promise<PhoneState> => {
    const phone = {
      ...phoneInput,
      id: uuid.v1(),
    };
    return dispatch(createPhones(props.tenantId, props.workspace.id, [phone]))
      .then((phones) => {
        return phones[0];
      })
      .catch((err) => {
        console.info('FAILED', phoneInput, JSON.stringify(err));
        throw err;
      });
  };

  const savedCallback = () => {
    return;
  };

  return (
    <PhoneDialogComponent
      title={'Create phone'}
      handleSavePhone={handleSavePhone}
      phoneInput={phoneInput}
      setPhoneInput={setPhoneInput}
      handleClose={props.handleClose}
      savedCallback={savedCallback}
      open={props.open}
    />
  );
});
