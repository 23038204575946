// import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
// import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { AdapterId } from '~src/services/xlsx/handlers/adapters/adapter';

import { strcmp } from '@pladdenico/common';

export interface DnbTransactionId {
  id: string;
  holdingId: string;
  // archiveRef: string;
  // date: Date;
}

export type DnbTransactionState = {
  id: string;
  adapterId: AdapterId;
  holdingId: string;
  name: string;
  date: Date;
  description: string;
  archiveRef: string;
  value: number;
  expectedValuation: number;
  handled: boolean;
  transactionId: string | null;
  groupId: string | null;
};

export const initialDnbTransactionState = [];

const selectKeyId = (dnbTransaction: DnbTransactionId) =>
  dnbTransaction.holdingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: DnbTransactionId, b: DnbTransactionId) => {
  return strcmp(a.id, b.id);
};

const adapter = new EntityRecordAdapter<
  DnbTransactionId,
  DnbTransactionState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const dnbTransactionsSlice = createBaseRecordSlice(
  'dnbTransactions',
  adapter,
);
export const dnbTransactionsSelectors = adapter.selectors;

export const dnbTransactionsReducer = dnbTransactionsSlice.reducer;
export const dnbTransactionsActions = dnbTransactionsSlice.actions;
