import { WarrantState } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { Warrant } from '~src/services/graphql/workspace/client/graphql';

export function parseWarrant(
  workspaceId: string,
  assetId: string,
  warrant: Warrant,
): WarrantState {
  return {
    workspaceId,
    assetId,
    id: warrant.id,
    description: warrant.description,
  };
}
