import React from 'react';
// import { DashboardMenuDiv } from '~src/components/dashboard/dashboard-menu-div';
import { ConfigButtonComponent } from '~src/domain/workspace/components/project/types/portfolio-project/config-button.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';

export const useConfiguration = () =>
  // workspace: WorkspaceState,
  // project: ProjectState,
  // portfolioProject: PortfolioProjectState,
  // portfolio: PortfolioState
  {
    // const selectedPortfolio = useSelector((state: RootState) => {
    //   const p = getSelectedPortfolio(selectWorkspaceData(state), workspace.id);
    //   if (!p) {
    //     return portfolio;
    //   }
    //   return p;
    // });
    // const holdings = useSelector((state: RootState) =>
    //   getHoldingsByPortfolioId(selectWorkspaceData(state), {
    //     id: selectedPortfolio.id,
    //     projectId: portfolioProject.projectId,
    //     workspaceId: workspace.id,
    //   })
    // );

    const { isShowing: configOpen, toggle: toggleConfig } = useVisible();
    // const { isShowing: filterOpen, toggle: toggleFilter } = useVisible();
    const theme: Theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const configButton = React.useMemo(() => {
      if (isMd) {
        return (
          <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center' }}>
            <IconButton size={'small'} onClick={toggleConfig}>
              <SettingsIcon />
            </IconButton>
          </Box>
        );
      } else {
        return (
          <Box sx={{ mt: 1, ml: 1 }}>
            <ConfigButtonComponent
              isVisible={configOpen}
              toggleConfig={toggleConfig}
              text="configuration"
              variant="contained"
            />
          </Box>
        );
      }
    }, [configOpen, isMd, toggleConfig]);

    // const filterButton = React.useMemo(() => {
    //   if (isMd) {
    //     return (
    //       <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center' }}>
    //         <IconButton size={'small'} onClick={toggleFilter}>
    //           <FilterListIcon />
    //         </IconButton>
    //       </Box>
    //     );
    //   } else {
    //     // TODO: need to brek up FilterComponent!
    //     return (
    //       <Box sx={{ mt: 1, ml: 1 }}>
    //         <ConfigButtonComponent
    //           isVisible={configOpen}
    //           toggleConfig={toggleFilter}
    //           text="filter"
    //         />
    //         <Box>
    //           <FilterComponent
    //             date={moment()}
    //             holdings={holdings}
    //             projectId={project.id}
    //             workspace={workspace}
    //           />
    //         </Box>
    //       </Box>
    //     );
    //   }
    // }, [configOpen, holdings, isMd, project.id, toggleFilter, workspace]);

    // const { filter, filterButton } = useFilter(
    //   workspace,
    //   project,
    //   portfolioProject,
    //   portfolio
    // );

    return { configButton, configOpen, toggleConfig };
  };
