import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DashboardStockComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/stock/holding-types/stock/stock.component';
import { useStockMenu } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/stock/use-menu.hook';
import { Config } from '~src/utils/interfaces/config';

import { HoldingType } from '@pladdenico/models';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

export const StockElement = React.memo(
  ({
    workspace,
    project,
    holding,
    config,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useStockMenu(element, remove, updated);
    const content = React.useMemo(() => {
      switch (holding.type) {
        case HoldingType.Stock:
          return (
            <DashboardStockComponent
              workspace={workspace}
              project={project}
              holding={holding}
              config={config}
            />
          );
        default:
          return <div>Paper not implement for {holding.type}</div>;
      }
    }, [config, holding, project, workspace]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={{
            dragDisabled: isOpen,
            format: {
              color: element.config.color ?? '#000000',
              backgroundColor: element.config.backgroundColor ?? '#ffffff',
              padding: element.config.padding,
            },
            hideOverflow: true,
          }}
        >
          {content}
        </ElementComponent>
        {menu}
      </>
    );
  },
);
