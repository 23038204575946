export const sectors: string[] = [
  'Insurance',
  'Telecom',
  'Health Care',
  'Energy',
  'Financial',
  'Diversified',
  'IT',
  'Shipping',
  'Real Estate',
  'Cash',
];
