import { pick } from 'lodash';

export interface ExcelExportData<T> {
  header: Array<keyof T>;
  rows: T[];
}

export interface ExportHandler<T> {
  type: string;
  header: Array<keyof T>;
  data: T[];
}

export type IExcelExportHandler = () => ExcelExportData<any>;

export function outputPicker<T>(data: T[], header: Array<keyof T>) {
  const ts = data.map((rawT) => pick(rawT, header) as T);
  return ts;
}
