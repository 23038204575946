import React, { memo } from 'react';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import { Button } from '@mui/material';

import { CreateWorkspaceDialogComponent } from './form/create-workspace-dialog.component';

interface Props {
  tenantId: string;
}

export const CreateWorkspaceComponent = memo((props: Props) => {
  const dialog = useVisible();
  return (
    <>
      <Button
        variant="outlined"
        color="white"
        sx={{ borderStyle: 'dashed', padding: 2 }}
        onClick={dialog.open}
      >
        Create workspace
      </Button>
      <CreateWorkspaceDialogComponent
        open={dialog.isShowing}
        handleClose={dialog.close}
        tenantId={props.tenantId}
      />
    </>
  );
});
