import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseStockTradeId {
  stockHoldingId: string;
  id: string;
}

export type StockTradeState = Omit<
  graphqlWorkspaceTypes.StockTrade,
  'stockHolding' | 'transfer' | 'position'
> & {
  stockHoldingId: string;
  transferId: string;
  positionId: string;
};

export const initialStockTradeState = [];

const selectKeyId = (stockTrade: BaseStockTradeId) => stockTrade.stockHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseStockTradeId, b: BaseStockTradeId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseStockTradeId,
  StockTradeState,
  string
>(initialStockTradeState, selectKeyId, keyComparator, tComparator);

export const stockTradesSlice = createBaseRecordSlice('stockTrades', adapter);
export const stockTradesSelectors = adapter.selectors;

export const stockTradesReducer = stockTradesSlice.reducer;
export const stockTradesActions = stockTradesSlice.actions;
