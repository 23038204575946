import { compact } from 'lodash';
import * as React from 'react';

import { TableField, TableOperations } from '@pladdenico/table';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { usePersonFields } from '~src/domain/workspace/components/people/algorithm/use-fields';

export const usePeopleAlgorithm = (tenantId: string, people: PersonState[]) => {
  const fields = usePersonFields(tenantId);

  const initialPersonSorters = React.useMemo(() => {
    const sorters: TableField.Sorter<PersonState>[] = compact([
      TableOperations.createSorter(fields.name, 'asc'),
      TableOperations.createSorter(fields.description, 'asc'),
    ]);
    return sorters;
  }, [fields.description, fields.name]);

  const initialPersonFilters: TableField.Filter<PersonState>[] = React.useMemo(
    () => [
      TableOperations.createFilter(fields.name),
      TableOperations.createFilter(fields.description),
    ],
    [fields.description, fields.name],
  );

  const algoData = TableOperations.useDataAlgorithms(
    people,
    initialPersonSorters,
    initialPersonFilters,
  );

  return {
    algoData,
    fields,
  };
};
