import { parseCapitalCall } from '~src/data/store/modules/assets/private-equity-fund/capital-calls/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  capitalCallsActions,
  CapitalCallState,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/capital-calls/reducer';
import { PrivateEquityFundCapitalCall } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

interface PrivateEquityFundCapitalCallVisitor {
  visit(
    privateEquityFundCapitalCall: PrivateEquityFundCapitalCallVisitable,
  ): void;
  post(): void;
}

export class PrivateEquityFundCapitalCallVisitable
  implements Visitable<PrivateEquityFundCapitalCallVisitor>
{
  constructor(
    private _investorId: string,
    private _privateEquityFundCapitalCall: PrivateEquityFundCapitalCall,
  ) {}
  public accept(visitor: PrivateEquityFundCapitalCallVisitor) {
    visitor.visit(this);
  }

  public parse(): CapitalCallState {
    return parseCapitalCall(
      this._investorId,
      this._privateEquityFundCapitalCall,
    );
  }
}

export class PrivateEquityFundCapitalCallStateVisitor
  implements PrivateEquityFundCapitalCallVisitor
{
  private _capitalCalls: CapitalCallState[];
  constructor(private _dispatch: AppDispatch) {
    this._capitalCalls = [];
  }
  public visit(capitalCall: PrivateEquityFundCapitalCallVisitable): void {
    this._capitalCalls.push(capitalCall.parse());
  }

  post() {
    this._dispatch(capitalCallsActions.upsertManyElements(this._capitalCalls));
  }
}
