import React from 'react';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import AddIcon from '@mui/icons-material/Add';
import { Grid, IconButton, colors } from '@mui/material';
import { TableOperations } from '@pladdenico/table';
import { useCreateVariableDialogComponent } from '~src/domain/workspace/components/project/scenario/settings/use-create-dialog.component';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getVariablesByScenarioId } from '~src/data/store/selectors/workspace/projects/scenario/variables/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { ScenarioVariableComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/variable.component';
import { getBudgetById } from '~src/data/store/selectors/workspace/projects/scenario/budgets/selectors';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  scenario: Scenario;
  algoData: TableOperations.AlgorithmData<HoldingDenormalizedWithEntity>;
}

export const ProjectScenarioSettingsComponent = React.memo((props: Props) => {
  const { workspace, project, scenario } = props;
  const variables = useSelector((state: RootState) =>
    getVariablesByScenarioId(selectWorkspaceData(state), scenario.id),
  );
  const budget = useSelector((state: RootState) =>
    getBudgetById(selectWorkspaceData(state), {
      id: scenario.budgetId,
      scenarioId: scenario.id,
    }),
  );
  const variableElements = React.useMemo(() => {
    if (variables == null) {
      return [];
    }
    return (
      budget &&
      variables.map((variable) => {
        return (
          <Grid
            item
            key={variable.id}
            xs={12}
            sx={{ m: 1, backgroundColor: colors.blue[50] }}
          >
            <ScenarioVariableComponent
              workspace={workspace}
              project={project}
              scenario={scenario}
              variable={variable}
              budget={budget}
            />
          </Grid>
        );
      })
    );
  }, [budget, project, scenario, variables, workspace]);

  const { dialog: variableElementDialog, modal: variableElementModal } =
    useCreateVariableDialogComponent(scenario.id);

  const addVariableElement = (
    <>
      <IconButton onClick={variableElementModal.open} color="inherit">
        <AddIcon />
      </IconButton>
      {variableElementDialog}
    </>
  );
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {variableElements}
          {addVariableElement}
        </Grid>
      </Grid>
    </>
  );
});
