import createCachedSelector from 're-reselect';
import { DataState } from '~src/data/store/reducers/reducers';
import { HoldingGroup } from '~src/data/store/selectors/finance/holding-groups';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';
import {
  getRegionState,
  OLD_selectRegionById,
  selectRegionById,
} from '../../common/regions/selectors';
import { getRegionAllocationsByHoldingId } from '../../entities/region-allocations/selectors';
import { createSelector } from 'reselect';
import { RegionByHoldingIdProps } from './filter-mapper';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';

export const getRegionHoldingProperties = createCachedSelector(
  (state: DataState, props: RegionByHoldingIdProps) =>
    getRegionAllocationsByHoldingId(selectWorkspaceDataFromData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      holdingId: props.holding.id,
    }),
  (state: DataState, _props: RegionByHoldingIdProps) =>
    getRegionState(selectWorkspaceDataFromData(state)),
  (regionAllocations, regions): HoldingGroup[] => {
    if (!regionAllocations || regionAllocations.length === 0) {
      return [{ id: 'unknown', name: 'unknown', share: 1 }];
    }
    return regionAllocations.map((regionAllocation) => {
      if (regionAllocation.regionId) {
        const region = OLD_selectRegionById(regions, {
          id: regionAllocation.regionId,
        });
        if (region && regionAllocation.allocation) {
          return {
            // id: regionAllocation.symbol ? regionAllocation.symbol : regionAllocation.name,
            id: regionAllocation ? regionAllocation.regionId : 'unknown',
            name: region.name,
            share: regionAllocation.allocation / 100,
          };
        }
      }
      const unknownId = `unknown-${regionAllocation.id}`;
      return { id: unknownId, name: unknownId, share: 1 };
    });
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${props.projectId}:${props.holding.id}}`,
  selectorCreator: createSelector,
});

export const selectRegionHoldingProperties = createCachedSelector(
  (regions: RegionState[], _regionAllocations: RegionAllocationState[]) =>
    regions,
  (_regions: RegionState[], regionAllocations: RegionAllocationState[]) =>
    regionAllocations,
  (regions, regionAllocations): HoldingGroup[] => {
    if (!regionAllocations || regionAllocations.length === 0) {
      return [{ id: 'unknown', name: 'unknown', share: 1 }];
    }
    return regionAllocations.map((regionAllocation) => {
      if (regionAllocation.regionId) {
        const region = selectRegionById(regions, {
          id: regionAllocation.regionId,
        });
        if (region && regionAllocation.allocation) {
          return {
            // id: regionAllocation.symbol ? regionAllocation.symbol : regionAllocation.name,
            id: regionAllocation ? regionAllocation.regionId : 'unknown',
            name: region.name,
            share: regionAllocation.allocation / 100,
          };
        }
      }
      const unknownId = `unknown-${regionAllocation.id}`;
      return { id: unknownId, name: unknownId, share: 1 };
    });
  },
)({
  keySelector: (_regions, _regionAllocations, entityId) => `${entityId}`,
  selectorCreator: createSelector,
});
