import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

export const useEditCompactTypeField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tName: TName,
  defaultValue: PathValue<TFieldValues, Path<TFieldValues>> | undefined,
) => {
  const menuItems = React.useMemo(() => {
    return [
      <MenuItem key={'null'} value="">
        No compaction
      </MenuItem>,
      <MenuItem key={'vertical'} value={'vertical'}>
        Vertical
      </MenuItem>,
      <MenuItem key={'horizontal'} value={'horizontal'}>
        Horizontal
      </MenuItem>,
    ];
  }, []);

  return (
    <Controller
      name={tName}
      // rules={{ required: true }}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="Type">Compact type</InputLabel>
          <Select<CompactType>
            value={field.value ?? ''}
            onChange={(event) => {
              console.log('HERE1', event.target.value);
              let value = event.target.value;
              if (event.target.value === '') {
                value = null;
              }
              field.onChange(value);
            }}
            label={'Compact type'}
            error={!!fieldState.error}
            // helperText={fieldState.error ? fieldState.error.message : null}
          >
            {menuItems}
          </Select>
          <FormHelperText>
            How elements are placed on the dashboard
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
