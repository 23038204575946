import { holdingBasePath } from '../../paths';

export const bondPositionsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) => {
  return `${holdingBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/bondPositions`;
};

export const bondPositionBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  bondPositionId: string,
) => {
  return `${bondPositionsBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/${bondPositionId}`;
};

export const bondPositionTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  bondPositionId: string,
  tab: string,
) => {
  return `${bondPositionBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
    bondPositionId,
  )}/${tab}`;
};
