import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchFavoriteHoldings } from '~src/data/store/modules/holdings/favorite/requests';
// import { fetchProjects } from '~src/data/store/modules/workspaces/projects/base/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { AppDispatch } from '~src/store/store';

import { ProjectType } from '@pladdenico/models';

import { PortfolioProjectRouterWrapper } from './types/portfolio-project/root';
import { PrivateEquityFundProjectRouterWrapper } from './types/private-equity-project/root';

// import { handleProjects } from '~src/data/store/modules/workspaces/projects/base/actions';

interface Props {
  workspace: WorkspaceState;
}

export const WorkspaceProjectRouterWrapper = React.memo((props: Props) => {
  const { workspace } = props;
  const { projectId } = useParams<{ projectId: string }>();
  const project = useSelector((state: RootState) =>
    getProjectById(selectWorkspaceData(state), {
      id: projectId ? projectId : '',
      workspaceId: workspace.id,
    }),
  );

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    // dispatch(
    //   fetchProjects(
    //     props.workspace.tenantId,
    //     props.workspace.id,
    //     [projectId],
    //     handleProjects
    //   )
    // ).then((_project) => {
    if (projectId) {
      dispatch(
        fetchFavoriteHoldings(workspace.tenantId, workspace.id, projectId, []),
      );
    }
    // });
  }, [dispatch, projectId, workspace.id, workspace.tenantId]);

  if (!project) {
    return <p>Not a valid project id</p>;
  } else {
    if (project.type === ProjectType.Portfolio) {
      return (
        <PortfolioProjectRouterWrapper
          workspace={props.workspace}
          project={project}
        />
      );
    } else if (project.type === ProjectType.PrivateEquityFund) {
      return (
        <PrivateEquityFundProjectRouterWrapper
          workspace={props.workspace}
          project={project}
        />
      );
    }
    return <div>Unknown project type?</div>;
  }
});
