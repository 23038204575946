export const WaterfallTypes = {
  Absolute: 'absolute',
  Diff: 'diff',
} as const;

export type WaterfallType =
  (typeof WaterfallTypes)[keyof typeof WaterfallTypes];
export function typeToString(type: WaterfallType) {
  if (type === WaterfallTypes.Absolute) {
    return 'Absolute';
  } else if (type === WaterfallTypes.Diff) {
    return 'Diff';
  }
  return 'undefined';
}
