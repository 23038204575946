import {
  StockPositionStateVisitor,
  StockPositionVisitable,
} from '~src/data/store/visitors/holding/types/stock/stock-position-visitor';
import { StockPosition } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Operation } from '@pladdenico/portfolio-api';

export function handleStockPositions(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: StockPosition[],
  subscriptions: Operation[],
) {
  const stockPositionVisitor = new StockPositionStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  const res = outputs.map((stockHolding) => {
    const stockHoldingVisitable = new StockPositionVisitable(stockHolding);
    return stockHoldingVisitable.accept(stockPositionVisitor);
  });
  stockPositionVisitor.post();
  return res;
}
