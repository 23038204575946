import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';
import { SummaryTypeField } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/summary/type.component';
import { SummaryType, SummaryTypes } from '~src/utils/interfaces/summary-type';

import { Checkbox, Divider, ListItemText, MenuItem } from '@mui/material';

export const useSummaryMenu = (
  element: DashboardElementState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const updateType = React.useCallback(
    (type: SummaryType) => {
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            type,
          },
        });
    },
    [element, updated],
  );

  const updateUseMarketValue = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, useMarketValue: boolean) => {
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            useMarketValue,
          },
        });
    },
    [element, updated],
  );
  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);
  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-1" />,
      <MenuItem key="menu-item-1">
        <SummaryTypeField
          handleValue={updateType}
          label={'Summary type'}
          type={(element.config.type as SummaryType) ?? SummaryTypes.TotalValue}
        />
      </MenuItem>,
      <MenuItem key="menu-item-2">
        <Checkbox
          onChange={updateUseMarketValue}
          checked={element.config.useMarketValue as boolean}
        />
        <ListItemText primary="Use market value" />
      </MenuItem>,
      <Divider key="divider-delete" />,
      deleteMenuItem,
      confirmDeleteElement,
    ];
  }, [
    confirmDeleteElement,
    deleteMenuItem,
    element,
    updateType,
    updateUseMarketValue,
    updated,
  ]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
