import React from 'react';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { OrganizationData } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/organization/element';
import { useOrganizationForm } from '~src/utils/form/use-organization-form';

export const useOrganizationElementForm = (
  element: DashboardElementState,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
) => {
  const updateOrganization = React.useCallback(
    (organizationData: OrganizationData) => {
      console.log('element.config', element.config, organizationData);
      updated &&
        updated(element.id, {
          config: {
            ...element.config,
            data: {
              ...organizationData,
            },
          },
        });
    },
    [element.config, element.id, updated],
  );

  const organizationData = React.useMemo(() => {
    return element.config.data
      ? (element.config.data as OrganizationData)
      : undefined;
  }, [element.config.data]);
  const organizationForm = useOrganizationForm(
    organizationData,
    updateOrganization,
  );

  return organizationForm;
};
