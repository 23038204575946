import copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { PhoneState } from '~src/data/store/reducers/person/phones/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { deletePhones } from '~src/data/store/modules/people/phones/requests';
import { EditPhoneDialogComponent } from './form/edit-dialog.component';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
  phone: PhoneState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  link: {
    textDecoration: 'none',
  },
}));

export const PhoneComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);
  const [snackbarInfo, setSnackbarInfo] = React.useState('');
  const [editPhoneDialogOpened, setEditPhoneDialogOpened] =
    React.useState(false);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [openRemove, setOpenRemove] = React.useState(false);

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setSnackbarInfo('Phone removed');
    setOpenRemove(false);
    setOpen(true);
    handleMenuClose();
  };

  const handleSaveEdit = () => {
    setSnackbarInfo('Phone saved');
    setOpen(true);
    handleMenuClose();
  };

  const snackBar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      message={snackbarInfo}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );

  const copy = () => {
    if (props.phone.value) {
      if (copyToClipboard(props.phone.value)) {
        setSnackbarInfo('Phone copied');
        setOpen(true);
      }
    }
  };

  const remove = () => {
    dispatch(
      deletePhones(props.workspace.tenantId, props.workspace.id, [
        {
          personId: props.phone.personId,
          id: props.phone.id,
        },
      ]),
    ).then(() => handleCloseRemove());
  };

  function handleCloseEditPhoneDialog() {
    setEditPhoneDialogOpened(false);
  }

  const removeDialog = (
    <Dialog
      open={openRemove}
      onClose={handleCloseRemove}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm removal</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove the phone number?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRemove} color="primary">
          Disagree
        </Button>
        <Button onClick={remove} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );

  const phoneMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={copy}>
        <ListItemIcon>
          <SaveAltIcon />
        </ListItemIcon>
        <ListItemText primary="Copy"></ListItemText>
      </MenuItem>
      <MenuItem
        component={Link}
        className={classes.link}
        // style={{ textDecoration: 'none' }}
        href={`tel:+${props.phone.value}`}
        color="inherit"
      >
        <ListItemIcon>
          <PhoneIcon />
        </ListItemIcon>
        <ListItemText primary="Call"></ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary="Edit"
          onClick={() => setEditPhoneDialogOpened(true)}
        ></ListItemText>
        <EditPhoneDialogComponent
          open={editPhoneDialogOpened}
          handleClose={handleCloseEditPhoneDialog}
          savedCallback={handleSaveEdit}
          tenantId={props.workspace.tenantId}
          workspace={props.workspace}
          person={props.person}
          phone={props.phone}
        />
      </MenuItem>
      <MenuItem onClick={handleClickOpenRemove}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete"></ListItemText>
      </MenuItem>
      {/* <MenuItem onClick={handleAddHolding}>Call</MenuItem> */}
    </Menu>
  );

  return (
    <>
      <ListItemText primary={props.phone.value} secondary={null} />
      <ListItemSecondaryAction>
        <IconButton onClick={handleMenuOpen} aria-label="phone">
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
      {snackBar}
      {phoneMenu}
      {removeDialog}
    </>
  );
});
