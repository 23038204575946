import React from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  // SelectChangeEvent,
} from '@mui/material';
import { typeToString } from '~src/components/fields/workspace/holding/transaction/type/view-type-field';
import { TransactionType } from '~src/utils/finance/transaction-type';
import {
  ControllerRenderProps,
  // FieldValues,
  ControllerFieldState,
} from 'react-hook-form';
import { FormValues } from '~src/domain/workspace/components/project/transaction/form/create-form.component';

// interface Props {
//   handleValue: (type: TransactionType) => void;
//   type: TransactionType | undefined;
//   label: string | undefined;
//   types: TransactionType[];
// }

export const useEditTypeField = (
  // handleValue: (type: TransactionType) => void,
  // type: TransactionType | undefined,
  label: string | undefined,
  types: TransactionType[],
) => {
  // const handleSelectChange = (
  //   event: SelectChangeEvent<TransactionType | ''>
  // ) => {
  //   handleValue(event.target.value as TransactionType);
  // };

  const inputLabel = React.useMemo(
    () => (label ? <InputLabel htmlFor="Type">{label}</InputLabel> : undefined),
    [label],
  );

  const menuItems = React.useMemo(() => {
    return types.map((type) => {
      // return Object.values(TransactionType).map((type) => {
      return (
        <MenuItem key={type} value={type}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, [types]);

  const field = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'type'>,
      fieldState: ControllerFieldState,
    ) => {
      const formHelper = fieldState.error?.type ? (
        <FormHelperText error>Type is required</FormHelperText>
      ) : undefined;
      return (
        <FormControl fullWidth margin="normal">
          {inputLabel}
          <Select
            // value={type ?? ''}
            // onChange={handleSelectChange}
            defaultValue={undefined}
            label={label}
            value={field.value ?? ''}
            onChange={field.onChange}
            error={!!fieldState.error}
            // helperText={fieldState.error?.type ? 'Type is required' : undefined}
          >
            {menuItems}
          </Select>
          {formHelper}
        </FormControl>
      );
    },
    [inputLabel, label, menuItems],
  );
  return field;
};
