import React from 'react';
import { useSelector } from 'react-redux';
import { useHoldingsAlgorithm } from '~src/components/fields/workspace/holding/algorithm/use-algorithm';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { getHoldingsByIdsForProject } from '~src/data/store/selectors/holding/holdings/selectors';
import { getHoldingsByPortfolioId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getBudgetById } from '~src/data/store/selectors/workspace/projects/scenario/budgets/selectors';
import { ScenarioHoldingCardComponent } from '~src/domain/workspace/components/project/scenario/holdings/card.component';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { colors, Grid, Paper } from '@mui/material';
import { strcmp, unionSorted } from '@pladdenico/common';
import { TableOperations } from '@pladdenico/table';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  scenario: Scenario;
  algoData: TableOperations.AlgorithmData<HoldingDenormalizedWithEntity>;
}

export const ProjectScenarioHoldingsComponent = React.memo((props: Props) => {
  const { workspace, project, portfolio, scenario } = props;
  // const [showAll, setShowAll] = React.useState(false);

  const budget = useSelector((state: RootState) =>
    getBudgetById(selectWorkspaceData(state), {
      id: scenario.budgetId,
      scenarioId: scenario.id,
    }),
  );
  const portfolioHoldings = useSelector((state: RootState) =>
    getHoldingsByPortfolioId(selectWorkspaceData(state), {
      workspaceId: workspace.id,
      projectId: project.id,
      id: portfolio.id,
    }),
  );
  const { algoData } = useHoldingsAlgorithm(workspace, portfolioHoldings);
  const allHoldings = React.useMemo(() => {
    const hs = algoData.filtered.map((h) => h.holding);
    return hs.sort((a, b) => strcmp(a.id, b.id));
  }, [algoData.filtered]);

  const budgetHoldings = useSelector((state: RootState) => {
    const holdingIds = budget?.holdingIds ?? [];
    const hs = getHoldingsByIdsForProject(selectWorkspaceData(state), {
      projectId: project.id,
      ids: holdingIds,
    });
    return hs.sort((a, b) => strcmp(a.id, b.id));
  });

  const holdings = React.useMemo(() => {
    return unionSorted(
      budgetHoldings,
      allHoldings,
      [(a, b) => strcmp(a.id, b.id)],
      (a, _b) => {
        return {
          inBudget: true,
          holding: a,
        };
      },
      (a) => {
        return {
          inBudget: true,
          holding: a,
        };
      },
      (a) => {
        return {
          inBudget: false,
          holding: a,
        };
      },
    );
  }, [budgetHoldings, allHoldings]);

  const holdingsElement =
    budget &&
    holdings.map((h) => (
      <Paper
        key={h.holding.id}
        sx={{ p: 1, m: 1, backgroundColor: colors.blue[50] }}
      >
        <ScenarioHoldingCardComponent
          holding={h.holding}
          inBudget={h.inBudget}
          project={project}
          workspace={workspace}
          scenario={scenario}
          budget={budget}
        />
      </Paper>
    ));

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {holdingsElement}
        </Grid>
      </Grid>
    </>
  );
});
