const port = process.env.PORT || 8889;
const apiPort = process.env.PORT || 5001;

const production = process.env.NODE_ENV === 'production';

const urlPrefix = process.env.HOST || production ? 'https://' : 'http://';
const urlPostfix =
  process.env.HOST || production
    ? 'invest-portfolio-service-layer.herokuapp.com'
    : 'localhost:' + port;
const apiUrlPostfix =
  process.env.HOST || production
    ? 'invest-portfolio-service-layer.herokuapp.com'
    : 'localhost:' + apiPort;

const baseurl = urlPrefix + urlPostfix;
const apiBaseUrl = urlPrefix + apiUrlPostfix;

// baseUrl = process.env.HOST || production
//   ? 'https://invest-portfolio-service-layer.herokuapp.com'
//   : 'http://localhost:' + port;
// const apiBaseUrl =
//   process.env.HOST || production
//     ? 'https://invest-portfolio-service-layer.herokuapp.com'
//     : 'http://localhost:' + apiPort;

const host = process.env.HOST || '0.0.0.0';

export const appConfig = {
  env: process.env.NODE_ENV || 'development',
  host,
  public: '/public',
  urlPrefix,
  urlPostfix,
  baseurl,
  apiBaseUrl,
  port,
  apiPort,
  karmaPort: 9876,
  karmaHost: 'localhost',
  jwtSecret: '8ujf89j89sdfjj8904cra948mc5jm9874cm894!98u=3y9n',

  // This part goes to React-Helmet for Head of our HTML
  app: {
    head: {
      title: 'global-invest',
      titleTemplate: 'global-invest: %s',
      meta: [
        { charset: 'utf-8' },
        { 'http-equiv': 'x-ua-compatible', content: 'ie=edge' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Global investing' },
      ],
    },
  },
};
