import { FileState } from '~src/data/store/reducers/file/files/reducer';

export const downloadFile = (url: string, file: FileState) =>
  fetch(url, {
    method: 'get',
    // mode: 'no-cors',
    // referrerPolicy: 'no-referrer',
  })
    .then((res) => res.blob())
    .then((res) => {
      console.log('blob', res);
      console.log('url', url);
      const aElement = document.createElement('a');
      aElement.setAttribute('download', file.name ?? 'unknownFileName');
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
