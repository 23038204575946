import { Operation } from '@pladdenico/portfolio-api';
import {
  GeneratedReportStateVisitor,
  GeneratedReportVisitable,
} from '~src/data/store/visitors/workspace/project/report/generated-report-visitor';
import { GeneratedReport } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleGeneratedReports(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  generatedReports: GeneratedReport[],
  _subscriptions: Operation[],
) {
  const generatedReportVisitor = new GeneratedReportStateVisitor(dispatch);
  generatedReports.forEach((generatedReport) => {
    const assetVisitable = new GeneratedReportVisitable(
      workspaceId,
      generatedReport,
    );
    assetVisitable.accept(generatedReportVisitor);
  });
  generatedReportVisitor.post();
  return generatedReports;
}
