import { combineReducers } from 'redux';
import {
  projectsReducer,
  initialState as projectsInitialState,
} from '~src/data/store/reducers/workspace/projects/base/reducer';
import {
  initialPortfolioProjectState,
  portfolioProjectReducer,
} from '~src/data/store/reducers/workspace/projects/portfolio-project/reducer';
import {
  selectedProjectsReducer,
  initialState as selectedProjectsInitialState,
} from './selected/reducer';
import {
  privateEquityFundProjectReducer,
  initialPrivateEquityFundProjectState,
} from './private-equity-fund-project/reducer';
import {
  scenarioInitialState,
  scenarioReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/reducer';
import {
  reportInitialState,
  reportReducer,
} from '~src/data/store/reducers/workspace/projects/reports/reducer';
import {
  eventInitialState,
  eventReducer,
} from '~src/data/store/reducers/workspace/projects/events/reducer';
export const projectInitialState = {
  projects: projectsInitialState,
  scenario: scenarioInitialState,
  event: eventInitialState,
  report: reportInitialState,
  selectedProject: selectedProjectsInitialState,
  portfolioProject: initialPortfolioProjectState,
  privateEquityFundProject: initialPrivateEquityFundProjectState,
};

export const projectReducer = combineReducers({
  projects: projectsReducer,
  scenario: scenarioReducer,
  report: reportReducer,
  event: eventReducer,
  selectedProject: selectedProjectsReducer,
  portfolioProject: portfolioProjectReducer,
  privateEquityFundProject: privateEquityFundProjectReducer,
});
