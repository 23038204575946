import { xlsxTypes } from '~src/services/xlsx/xlsx-types';

import { Button, Theme } from '@mui/material';

// import { IExcelImportData } from '~src/services/xlxs/iinput-handler';
import { makeStyles } from 'tss-react/mui';

import { Sheet2JSONOpts } from 'xlsx';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  input: {
    display: 'none',
  },
}));

const sheetJSFT = xlsxTypes
  .map((x) => {
    return '.' + x;
  })
  .join(',');

export interface OptionType {
  name: string;
  option?: Sheet2JSONOpts;
}

interface Props {
  // handlers: Map<string, IExcelImportData> | undefined;
  handleData: (data: Map<string, any[]>) => void;
  options?: Map<string, OptionType[]>;
  // opts?: Sheet2JSONOpts;
}

export function DataInput(props: Props) {
  const { handleData, options } = props;
  const { classes } = useStyles();

  const handleOnClick = (e: any) => {
    e.target.value = null;
  };

  const handleFile = (file: any /*:File*/) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const bstr = e.target.result;
      // const handlers = props.handlers;
      // if (!handlers) {
      //   return;
      // }
      return import('xlsx').then((XLSX) => {
        const wb = XLSX.read(bstr, { type: 'binary', cellDates: true });
        const data = new Map<string, any[]>();
        wb.SheetNames.forEach((sheetName) => {
          // const handler = handlers.get(sheetName);
          // if (!handler) {
          //   return;
          // }
          // handlers.forEach((data, key) => {
          // const wsname = wb.SheetNames.find((sheetName: string) => {
          //   return key === sheetName;
          // });
          // if (!wsname) {
          //   return;
          // }
          const ws = wb.Sheets[sheetName];
          /* Convert array of arrays */
          // const data = XLSX.utils.sheet_to_json(ws, {header: 1});
          const sheetOptions = options?.get(sheetName);
          if (sheetOptions) {
            sheetOptions.forEach((option) => {
              data.set(
                option.name,
                XLSX.utils.sheet_to_json(ws, option.option),
              );
            });
          } else {
            data.set(sheetName, XLSX.utils.sheet_to_json(ws));
          }

          // data.set(sheet, XLSX.utils.sheet_to_json(ws));
          // const data = XLSX.utils.sheet_to_json(ws);
          /* Update state */
        });
        handleData(data);
      });
    };
    reader.readAsBinaryString(file);
  };

  const handleChange = (e: any) => {
    const files = e.target.files;
    if (files && files[0]) {
      handleFile(files[0]);
    }
  };

  return (
    <>
      <input
        accept={sheetJSFT}
        className={classes.input}
        id="data-input"
        // multiple
        type="file"
        onClick={handleOnClick}
        onChange={handleChange}
      />
      <label htmlFor="data-input">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
    </>
  );
  // <Form>
  //   <Form.Field inline={true}>
  //     <label htmlFor={'file'} className="ui icon button">
  //       <i className="upload icon"/>
  //       Import data
  //     </label>
  //     <input style={{display: 'none'}}
  //       type="file" className="form-control" id="file" accept={sheetJSFT} onChange={this.handleChange}
  //       onClick={this.handleOnClick}/>
  //   </Form.Field>
  // </Form>);
}
