import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseReportId {
  projectId: string;
  id: string;
}

export type ReportState = Omit<
  graphqlWorkspaceTypes.Report,
  'project' | 'holding' | 'scheduler' | 'users'
> & {
  schedulerId: string;
  projectId: string;
  holdingId?: string;
  userIds: number[];
};

export const initialReportState = [];

const selectKeyId = (report: BaseReportId) => report.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseReportId, b: BaseReportId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseReportId, ReportState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const reportsSlice = createBaseRecordSlice('reports', adapter);
export const selectors = adapter.selectors;

export const reportsReducer = reportsSlice.reducer;
export const reportsActions = reportsSlice.actions;
