import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { EditHolding } from '~src/components/fields/workspace/holding/holding/edit-holding';
import { IdComponent } from '~src/components/utils/id.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { eventParser } from '~src/utils/form/event-parser';
import { numberToNumberInput } from '~src/utils/form/input-util';

import { DatePicker } from '@mui/x-date-pickers';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ValuationStatus } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { ValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/valuation-field-config';

// interface ValuationFields {
//   holding: boolean;
//   date: boolean;
//   currency: boolean;
//   value: boolean;
// }

// interface ValuationFieldConfig {
//   disabled?: Partial<ValuationFields>;
//   visible?: Partial<ValuationFields>;
// }

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  valuationInput: graphqlWorkspaceTypes.CreateValuationInputType;
  setValuationInput: (
    valuationInput: graphqlWorkspaceTypes.CreateValuationInputType,
  ) => void;
  // holding: Holding | undefined;
  showId: boolean;
  showConfirmed: boolean;
  showHolding: boolean;
  config?: ValuationFieldConfig;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const CreateValuationFieldsComponent = React.memo((props: Props) => {
  const { config } = props;
  const { classes } = useStyles();
  const holding = useSelector((root: RootState) => {
    return getHoldingById(selectWorkspaceData(root), {
      id: props.valuationInput.holdingId,
      projectId: props.projectId,
    });
  });

  const handleChange =
    (name: keyof graphqlWorkspaceTypes.CreateValuationInputType) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.setValuationInput({
        ...props.valuationInput,
        [name]: eventParser(e),
      });
    };

  // const handleDateChange = (moment: Date | null) => {
  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      props.setValuationInput({ ...props.valuationInput, date: date.toDate() });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    props.setValuationInput({
      ...props.valuationInput,
      [event.target.name as string]: event.target.value,
    });
  };

  const handleIdChange = (id: string) => {
    props.setValuationInput({ ...props.valuationInput, id });
  };

  const handleHoldingChange = (holding: HoldingState) => {
    if (holding) {
      props.setValuationInput({
        ...props.valuationInput,
        holdingId: holding.id,
        // holdingType: holding.type,
        // holding,
      });
    }
  };

  const handleCurrencyChange = (currency: CurrencyState) => {
    if (currency) {
      props.setValuationInput({
        ...props.valuationInput,
        currencyId: currency.id,
        // holdingType: holding.type,
        // holding,
      });
    }
  };

  const idElement = props.showId ? (
    <IdComponent id={props.valuationInput.id} setId={handleIdChange} />
  ) : undefined;

  const holdingElement = props.showHolding ? (
    <Grid item sm={4}>
      <EditHolding
        handleValue={handleHoldingChange}
        projectId={props.projectId}
        holding={holding}
        // holdingTypes={}
        workspace={props.workspace}
        label={'Holding'}
      />
    </Grid>
  ) : undefined;

  const confirmedElement = props.showConfirmed ? (
    <Grid item sm={4}>
      <FormControl className={classes.textField} margin="normal">
        <InputLabel htmlFor="Type">Status</InputLabel>
        <Select
          value={props.valuationInput.status ?? undefined}
          onChange={handleSelectChange}
          inputProps={{
            name: 'status',
            id: 'status',
          }}
        >
          <MenuItem value={ValuationStatus.confirmed}>Confirmed</MenuItem>
          <MenuItem value={ValuationStatus.expected}>Expected</MenuItem>
          <MenuItem value={ValuationStatus.cancelled}>Cancelled</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  ) : undefined;

  return (
    <>
      <Grid container columnSpacing={1}>
        {idElement}
        {holdingElement}
        <Grid item sm={4}>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY hh:mm"
            mask="__/__/____ __:__"
            label="Date"
            value={moment(props.valuationInput.date)}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField margin="normal" fullWidth {...params} />
            )}
            disabled={config?.base.date?.disabled}
          />
        </Grid>
        <Grid item sm={4}>
          <EditCurrencyField
            handleValue={handleCurrencyChange}
            currencyId={
              props.valuationInput.currencyId
                ? props.valuationInput.currencyId
                : null
            }
            labelled={true}
          />
        </Grid>
        {/* <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="ownershipPercentage"
            value={props.valuationInput.ownershipPercentage}
            label="Ownership %"
            type="number"
            onChange={handleChange('ownershipPercentage')}
          />
        </Grid> */}
        <Grid item sm={4}>
          <TextField
            margin="normal"
            fullWidth
            className={classes.textField}
            required
            id="value"
            value={numberToNumberInput(props.valuationInput.value)}
            label="Value"
            type="number"
            onChange={handleChange('value')}
            disabled={config?.base.value?.disabled}
          />
        </Grid>
        {confirmedElement}
      </Grid>
    </>
  );
});
