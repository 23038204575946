import { WorkspaceInvitationState } from '~src/data/store/reducers/workspace/workspace-invitations/reducer';
import { WorkspaceInvitation } from '~src/services/graphql/tenant/client/graphql';

export function parseWorkspaceInvitation(
  tenantId: string,
  workspaceInvitation: WorkspaceInvitation,
): WorkspaceInvitationState {
  return {
    id: workspaceInvitation.id,
    workspaceRoleId: workspaceInvitation.workspaceRole
      ? workspaceInvitation.workspaceRole.id
      : -1,
    email: workspaceInvitation.email,
    accepted: workspaceInvitation.accepted,
    tenantId,
  };
}

export function parseWorkspaceInvitations(
  tenantId: string,
  workspaceInvitations: WorkspaceInvitation[] | null | undefined,
) {
  return workspaceInvitations
    ? workspaceInvitations.map((workspaceInvitation) => {
        return parseWorkspaceInvitation(tenantId, workspaceInvitation);
      })
    : [];
}
