import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectReportById } from '~src/data/store/selectors/workspace/projects/report/project-report/selectors';
import { useProjectReportMenu } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/menu/project-report-menu.component';

import { Box, Grid, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  reportId: string;
}

export const ProjectReportItemComponentById = React.memo((props: Props) => {
  const { workspace, projectId, reportId } = props;
  const projectReport = useSelector((state: RootState) =>
    getProjectReportById(selectWorkspaceData(state), {
      projectId,
      id: reportId,
    }),
  );
  if (!projectReport) {
    return <div>Could not find projectReport {reportId}</div>;
  }
  return (
    <ProjectReportItemComponent
      workspace={workspace}
      projectId={projectId}
      projectReport={projectReport}
    />
  );
});

interface PropsImpl {
  workspace: WorkspaceState;
  projectId: string;
  projectReport: ProjectReportState;
}

export const ProjectReportItemComponent = React.memo((props: PropsImpl) => {
  const { workspace, projectId, projectReport } = props;

  const {
    // editing,
    menuButtonElement,
    menuElement,
    // setEditing,
  } = useProjectReportMenu(workspace, projectId, projectReport);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={{ ml: 1 }}>
          <Typography variant="body1">{projectReport.name}</Typography>
        </Grid>
        {menuButtonElement}
      </Grid>
      {menuElement}
      <Typography variant="body2" sx={{ ml: 1 }}>
        {projectReport.description}
      </Typography>
    </Box>
  );
});
