import { stockHoldingsActions } from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleStockHoldings } from './handler';

export function fetchStockHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetStockHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetStockHoldingsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getStockHoldings) {
        return handleStockHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.getStockHoldings,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createStockHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateStockHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateStockHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateStockHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createStockHoldings) {
        return handleStockHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.createStockHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateStockHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateStockHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateStockHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateStockHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateStockHoldings) {
        return handleStockHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.updateStockHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertStockHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertStockHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertStockHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertStockHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertStockHoldings) {
        return handleStockHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertStockHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deleteStockHoldings(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteStockHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteStockHoldingsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteStockHoldings) {
        dispatch(
          stockHoldingsActions.removeManyElements(
            data.deleteStockHoldings.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
