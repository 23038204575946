import React from 'react';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import {
  dnbAdapter,
  Adapter,
  nordnetAdapter,
  vpsAdapter,
} from '~src/services/xlsx/handlers/adapters/adapter';

const adapters = [dnbAdapter, vpsAdapter, nordnetAdapter];

interface Props {
  handleValue: (value: Adapter) => void;
  adapter: Adapter | undefined;
  label?: string;
}

export const SelectAdapter = (props: Props) => {
  const { adapter, handleValue, label } = props;
  const getOptionLabel = React.useCallback((t: Adapter) => {
    const holding = adapters.find((holding) => holding.id === t.id);
    return holding?.name ? holding.name : '';
  }, []);

  const isOptionEqualToValue = (option: Adapter, value: Adapter) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={false}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      options={adapters}
      value={adapter}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
