import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { createFundPositions } from '~src/data/store/modules/holdings/fund/position/requests';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { CreateFundPositionFormComponent } from './create-form.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  handleClose: () => void;
  fundPositionCreateInputInit: graphqlWorkspaceTypes.CreateFundPositionInputType;
}

export const CreateFundPositionDialogContentComponent = React.memo(
  (props: Props) => {
    const [fundPositionInput, setFundPositionInput] =
      React.useState<graphqlWorkspaceTypes.CreateFundPositionInputType>({
        ...props.fundPositionCreateInputInit,
      });
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      setFundPositionInput({ ...props.fundPositionCreateInputInit });
    }, [props.fundPositionCreateInputInit]);

    const handleCreatePosition = (): void => {
      dispatch(
        createFundPositions(props.workspace.tenantId, props.workspace.id, [
          fundPositionInput,
        ]),
      )
        .then((_fundPositions) => {
          props.handleClose();
        })
        .catch((err) => {
          console.info('FAILED', fundPositionInput, JSON.stringify(err));
        });
    };

    return (
      <>
        <DialogTitle id="form-dialog-title">Create valuation</DialogTitle>
        <DialogContent>
          <CreateFundPositionFormComponent
            fundPositionInput={fundPositionInput}
            setFundPositionInput={setFundPositionInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreatePosition} color="primary">
            Save
          </Button>
        </DialogActions>
      </>
    );
  },
);
