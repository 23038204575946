import { compact } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { InvestorState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getInvestorsByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/investor/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useInvestorActions } from '~src/hooks/actions/workspace/asset/private-equity-fund/investor/use-investor-actions';
import { useInvestorFields } from '~src/hooks/fields/workspace/asset/private-equity-fund/use-investor-fields';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { InvestorsToolbar } from './investors-toolbar.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));
interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
}

export const InvestorsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const investors = useSelector((state: RootState) => {
    return getInvestorsByFundId(
      selectWorkspaceData(state),
      props.privateEquityFund.id,
    );
  });
  const { onRowUpdate, onRowDelete } = useInvestorActions(
    props.workspace,
    props.privateEquityFund,
  );
  const fields = useInvestorFields(
    props.workspace.id,
    props.project.id,
    props.privateEquityFund.id,
  );

  const [initialSorters] = React.useState<TableField.Sorter<InvestorState>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const investorCreateInputInit: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestorInputType =
    {
      id: uuid.v1(),
      description: '',
      name: '',
    };

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(
      investors,
      initialSorters,
      initialFilters,
    );
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<InvestorState>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        editable={{ onRowUpdate, onRowDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        // handleSetUpdateState={setUpdateState}
        isSelected={isSelected}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onRowDelete,
    onRowUpdate,
    selectedData,
    sorters,
  ]);

  return (
    <div className={classes.root}>
      <InvestorsToolbar
        workspace={props.workspace}
        projectId={props.project.id}
        investorCreateInputInit={investorCreateInputInit}
        fields={fields}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
      />
      <div className={classes.content}>{tableComponent}</div>
    </div>
  );
});
