import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseAllocationId {
  portfolioId: string;
  id: string;
}

export type AllocationState = Omit<
  graphqlWorkspaceTypes.Allocation,
  'portfolio' | 'currency' | 'region'
> & {
  portfolioId: string;
  regionId: string | undefined;
  currencyId: string | undefined;
};

export const initialAllocationState = [];

const selectKeyId = (allocation: BaseAllocationId) => allocation.portfolioId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseAllocationId, b: BaseAllocationId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseAllocationId,
  AllocationState,
  string
>(initialAllocationState, selectKeyId, keyComparator, tComparator);

export const allocationsSlice = createBaseRecordSlice('allocation', adapter);
export const allocationSelectors = adapter.selectors;

export const allocationsReducer = allocationsSlice.reducer;
export const allocationsActions = allocationsSlice.actions;
