import { compact } from 'lodash';
import * as React from 'react';
import { useHoldingsAlgorithm } from '~src/components/fields/workspace/holding/algorithm/use-algorithm';
import { SnackbarFailure } from '~src/components/snackbars/snackbar-failure';
import { SnackbarSuccess } from '~src/components/snackbars/snackbar-success';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalized } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { EntityCardsListComponent } from '~src/domain/workspace/components/project/portfolio/entities/card/entity-cards-list.component';
import { Toolbar } from '~src/domain/workspace/components/project/portfolio/entities/toolbar.component';
import { useEntityFieldsWithDetails } from '~src/hooks/fields/workspace/entity/use-entity-with-details.fields';
import { useUpdateState } from '~src/utils/common/use-update-state';
import { Identity } from '~src/utils/interfaces/identity';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { strcmp } from '@pladdenico/common';
import { TableField, TableOperations } from '@pladdenico/table';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsByPortfolioId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

const PREFIX = 'EntitiesComponent';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  cardListContainer: `${PREFIX}-cardListContainer`,
  cardList: `${PREFIX}-cardList`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    // padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    // flex: 1,
    // justifyContent: 'flex-end',
    // marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // marginTop: theme.spacing(2),
  },

  [`& .${classes.cardListContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  [`& .${classes.cardList}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolio: PortfolioState;
}

export interface EntityData extends Identity {
  // id: string;
  entity: EntityState;
  holdings: HoldingDenormalized[];
}

export const EntitiesComponent = React.memo((props: Props) => {
  const { workspace, project, portfolio } = props;

  const holdings = useSelector((state: RootState) =>
    getHoldingsByPortfolioId(selectWorkspaceData(state), {
      workspaceId: workspace.id,
      projectId: project.id,
      id: portfolio.id,
    }),
  );
  const { algoData: holdingAlgoData } = useHoldingsAlgorithm(
    workspace,
    holdings,
    // portfolio
  );

  const entities = React.useMemo(() => {
    const sortedHoldingByEntity = holdingAlgoData.filtered.sort((a, b) => {
      if (a.entity && b.entity) {
        return strcmp(a.entity.id, b.entity.id);
      } else if (a.entity) {
        return -1;
      } else {
        return 1;
      }
    });

    const entities: EntityData[] = [];
    let j = -1;
    for (let i = 0; i < sortedHoldingByEntity.length; ++i) {
      const holding = sortedHoldingByEntity[i];
      const entityId = holding.entity?.id;
      if (j >= 0 && entities[j].entity?.id === entityId) {
        entities[j].holdings.push(holding);
      } else {
        let entity: EntityState | undefined = holding.entity;
        if (!entity) {
          entity = {
            id: holding.holding.name ?? holding.holding.id,
            workspaceId: workspace.id,
            tenantId: workspace.tenantId,
          };
        }
        entities.push({
          id: entity.id,
          entity,
          holdings: [holding],
        });
        ++j;
      }
    }
    return entities;
  }, [holdingAlgoData.filtered, workspace.id, workspace.tenantId]);

  const fields = useEntityFieldsWithDetails(workspace.tenantId, workspace.id);

  const initialSorters = React.useMemo(() => {
    const sorters: TableField.Sorter<EntityData>[] = compact([
      TableOperations.createSorter(fields.name, 'asc'),
      TableOperations.createSorter(fields.description, false),
      TableOperations.createSorter(fields.sector, false),
    ]);
    return sorters;
  }, [fields]);

  const initialFilters: TableField.Filter<EntityData>[] = React.useMemo(
    () => [
      TableOperations.createFilter(fields.name),
      TableOperations.createFilter(fields.description),
      TableOperations.createFilter(fields.sector),
      TableOperations.createFilter(fields.website),
    ],
    [fields],
  );

  const entityAlgoData = TableOperations.useDataAlgorithms(
    entities,
    initialSorters,
    initialFilters,
  );

  const updateStateData = useUpdateState();

  return (
    <Root className={classes.root}>
      <Grid container className={classes.content}>
        <Grid item xs={9} className={classes.cardListContainer}>
          <div className={classes.toolbar}>
            <Toolbar
              workspace={workspace}
              project={project}
              entityAlgoData={entityAlgoData}
              holdingAlgoData={holdingAlgoData}
            />
          </div>
          <div className={classes.content}>
            <SnackbarFailure
              updateState={updateStateData.updateState}
              setUpdateState={updateStateData.setUpdateState}
              message={'Failed'}
            />
            <SnackbarSuccess
              updateState={updateStateData.updateState}
              setUpdateState={updateStateData.setUpdateState}
              message={'Updated'}
            />
            <EntityCardsListComponent
              workspace={workspace}
              projectId={project.id}
              data={entityAlgoData.filtered}
              setUpdateState={updateStateData.setUpdateState}
            />
          </div>
        </Grid>
      </Grid>
    </Root>
  );
});
