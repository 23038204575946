import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { SetupState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/setups/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSetupByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/setup/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // flex: 1,
  },
});

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
}

export const SetupComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const setup = useSelector((state: RootState) =>
    getSetupByFundId(selectWorkspaceData(state), props.privateEquityFund.id),
  );

  if (setup) {
    return <SetupComponentWithSetup {...props} setup={setup} />;
  }
  return <div className={classes.root}>Missing Setup</div>;
});

interface PropsWithSetup {
  workspace: WorkspaceState;
  project: ProjectState;
  privateEquityFundProject: PrivateEquityFundProjectState;
  privateEquityFund: PrivateEquityFundState;
  setup: SetupState;
}

export const SetupComponentWithSetup = React.memo((props: PropsWithSetup) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5">{props.setup.name}</Typography>
      <Typography variant="body1">{props.setup.description}</Typography>
      <Typography variant="body1">{props.setup.carriedInterestType}</Typography>
      <Typography variant="body1">{props.setup.carry}</Typography>
      <Typography variant="body1">{props.setup.hurdleRate}</Typography>
      <Typography variant="body1">{props.setup.managementFee}</Typography>
      <Typography variant="body1">
        {moment(props.setup.startDate).format('DD/MM/YYYY')}
      </Typography>
      <Typography variant="body1">
        {moment(props.setup.endDate).format('DD/MM/YYYY')}
      </Typography>
      <Typography variant="body1">
        {moment(props.setup.endInvestmentDate).format('DD/MM/YYYY')}
      </Typography>
    </div>
  );
});
