import React from 'react';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type PhoneInput =
  | graphqlWorkspaceTypes.CreatePhoneInputType
  | graphqlWorkspaceTypes.UpdatePhoneInputType;

interface Props {
  // open: boolean;
  phoneInput: PhoneInput;
  setPhoneInput: (phoneInput: PhoneInput) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const PhoneFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange = (name: keyof PhoneInput) => (value: any) => {
    props.setPhoneInput({ ...props.phoneInput, [name]: value });
  };

  const handleEventChange =
    (name: keyof PhoneInput) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(name)(event.target.value);
      // props.setPhoneInput({ ...props.phoneInput, [name]: event.target.value });
    };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {/* <EditIdField
        className={classes.textField}
        handleChange={handleChange('id')}
        value={props.phoneInput.id}
      /> */}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="value"
        value={props.phoneInput.value}
        label="Phone number"
        type="text"
        onChange={handleEventChange('value')}
      />
    </form>
  );
});
