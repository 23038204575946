import moment from 'moment';
import numbro from 'numbro';
import React from 'react';
import { ElementFormatConfig } from '~src/components/dashboard/element';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHoldingsCashOnCashRatio } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/use-holdings-cash-on-cash-ratio';
import { Filter } from '~src/utils/common/filter';
import { SortOrder, SortOrders } from '~src/utils/interfaces/sort-order';
import { Period } from '~src/utils/period/period';

import { Typography } from '@mui/material';

import { HoldingsViewTableComponent } from './holdings-view-table.component';

interface Props {
  workspace: WorkspaceState;
  valuationDate: moment.Moment;
  projectId: string;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
  period: Period;
  sortOrder: SortOrder;
  minimumValuationForHolding: number | undefined;
  filter: Filter;
  format: ElementFormatConfig;
}

export const HoldingsCashOnCashRatioViewComponent = React.memo(
  (props: Props) => {
    const {
      workspace,
      projectId,
      baseCurrency,
      minimumValuationForHolding,
      sortOrder,
      format,
    } = props;

    const renderCashOnCashValue = React.useCallback((value: number) => {
      return `${numbro(value).format({
        thousandSeparated: true,
        mantissa: 2,
      })}`;
    }, []);

    const cashOnCashRatio = useHoldingsCashOnCashRatio(props);

    const title = React.useMemo(() => {
      const orderString =
        sortOrder === SortOrders.Decreasing ? 'Top' : 'Bottom';
      return `${orderString} cash on cash ratio`;
    }, [sortOrder]);

    return (
      <>
        <Typography variant="body2" textAlign="center">
          {title}
        </Typography>
        <HoldingsViewTableComponent
          workspace={workspace}
          baseCurrency={baseCurrency}
          projectId={projectId}
          data={cashOnCashRatio}
          renderValue={renderCashOnCashValue}
          reverse={sortOrder === SortOrders.Decreasing}
          minimumValue={minimumValuationForHolding}
          format={format}
        />
      </>
    );
  },
);
