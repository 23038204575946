import { MathJax } from 'better-react-mathjax';
import React from 'react';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ExpressionElement } from '~src/domain/workspace/components/project/scenario/models/forecast/expression-element';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Box, colors, Grid, Typography } from '@mui/material';

export const useForecastExpressionComponent = <Value extends any>(
  _scenario: Scenario,
  forecast: ForecastState,
  forecastElementState: ExpressionElementState,
) => {
  const forecastElement = React.useMemo(() => {
    const element = forecastElementState as ExpressionElementState;
    return new ExpressionElement<Value>(
      forecast.id,
      forecast.name,
      element.expressions,
      element.variables,
      // element.correspondingHoldingId
    );
  }, [forecast.id, forecast.name, forecastElementState]);

  const metaString = React.useMemo(() => {
    const latex = forecastElement.getLatex();
    return latex.map((v, idx) => {
      return (
        <Box key={idx} sx={{ m: 3 }}>
          <MathJax>{v}</MathJax>
        </Box>
      );
    });
  }, [forecastElement]);

  const expressionComponent = React.useMemo(
    () => (
      <Box sx={{ mb: 1, background: colors.blue[200] }}>
        <Grid container padding={1}>
          <Grid item xs={3}>
            <Typography>{forecast.name}</Typography>
            <Typography variant="caption">
              <MathJax>{metaString}</MathJax>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ),
    [forecast.name, metaString],
  );

  return {
    expressionComponent,
    forecastElement,
  };
};
