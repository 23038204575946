import React from 'react';
import { useWatch } from 'react-hook-form';
import { SnackbarFailure } from '~src/components/snackbars/snackbar-failure';
import { SnackbarSuccess } from '~src/components/snackbars/snackbar-success';
import { AssetTypedInterface } from '~src/data/store/reducers/asset/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';
import { useAssetCreateForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';
import { AssetBaseCreateDialogContentComponent } from '~src/domain/workspace/components/asset/form/create/asset-base-create-dialog-content';
import { FieldsConfig } from '~src/domain/workspace/components/asset/form/fields/fields.config';
import { BankNoteCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/bank-notes/form/bank-note-create-dialog.component';
import { BondCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/bonds/form/bond-create-dialog.component';
import { CryptoCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/cryptos/form/crypto-create-dialog.component';
import { FundCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/funds/form/fund-create-dialog.component';
import { LoanCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/loans/form/loan-create-dialog.component';
import { StockCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/stocks/form/stock-create-dialog.component';
import { WarrantCreateDialogContentComponent } from '~src/domain/workspace/components/asset/types/warrants/form/warrant-create-dialog.component';
import { UpdateState } from '~src/utils/common/update-state';
import { useUpdateState } from '~src/utils/common/use-update-state';

import { AssetType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  handleClose: () => void;
  assetInput: AssetCreateInputData;
  fieldsConfig: FieldsConfig;
  createdCallback: (typed: AssetTypedInterface) => void;
}
export const DialogContentComponent = React.memo((props: Props) => {
  const {
    assetInput: assetInputData,
    handleClose,
    workspace,
    fieldsConfig,
    createdCallback,
  } = props;
  const updateStateData = useUpdateState();

  const onSuccess = React.useCallback(() => {
    updateStateData.setUpdateState(UpdateState.success);
  }, [updateStateData]);

  const onFailure = React.useCallback(
    (_error: any) => {
      updateStateData.setUpdateState(UpdateState.failed);
    },
    [updateStateData],
  );

  const assetForm = useAssetCreateForm({
    workspace,
    callbacks: { onSuccess, onFailure },
    inputData: {
      ...assetInputData,
    },
    fieldsConfig,
  });

  const assetType = useWatch({
    control: assetForm.control,
    name: 'type',
  });

  const content = React.useMemo(() => {
    let content;
    if (assetType === AssetType.Stock) {
      content = (
        <StockCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (assetType === AssetType.BankNote) {
      content = (
        <BankNoteCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (assetType === AssetType.Crypto) {
      content = (
        <CryptoCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (assetType === AssetType.Bond) {
      content = (
        <BondCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (assetType === AssetType.Fund) {
      content = (
        <FundCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (assetType === AssetType.Loan) {
      content = (
        <LoanCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else if (assetType === AssetType.Warrant) {
      content = (
        <WarrantCreateDialogContentComponent
          title={'Create asset'}
          workspace={workspace}
          assetForm={assetForm}
          onFormCallbacks={{ onFailure, onSuccess }}
          createdCallback={createdCallback}
          handleClose={handleClose}
        />
      );
    } else {
      content = (
        <AssetBaseCreateDialogContentComponent
          assetForm={assetForm}
          handleClose={handleClose}
        />
      );
    }
    return content;
  }, [
    assetForm,
    assetType,
    createdCallback,
    handleClose,
    onFailure,
    onSuccess,
    workspace,
  ]);

  return (
    <>
      {content}
      <SnackbarFailure
        updateState={updateStateData.updateState}
        setUpdateState={updateStateData.setUpdateState}
        message={'Update failed'}
      />
      <SnackbarSuccess
        updateState={updateStateData.updateState}
        setUpdateState={updateStateData.setUpdateState}
        message={'Updated'}
      />
    </>
  );
});
