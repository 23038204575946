import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export interface Item<T> {
  t: T;
  value: string;
  name: string;
}

interface Props<T, I extends Item<T>> {
  items: I[];
  handleT: (t: T) => void;
  t: T;
  label: string | undefined;
}

export const SelectItem = <T, I extends Item<T>>(props: Props<T, I>) => {
  const { t, handleT, items, label } = props;
  const handleEvent = (event: SelectChangeEvent<T>) => {
    const value = event.target.value as string;
    const item = items.find((item) => item.value === value);
    if (item) {
      handleT(item.t);
    }
  };

  const labelElement = label ? <InputLabel>{label}</InputLabel> : undefined;

  const itemsElement = React.useMemo(() => {
    return Object.values(items).map((element) => {
      return (
        <MenuItem key={element.name} value={element.value}>
          {element.name}
        </MenuItem>
      );
    });
  }, [items]);

  return (
    <FormControl margin="normal" fullWidth={true} sx={{ minWidth: 100 }}>
      {labelElement}
      <Select value={t} onChange={handleEvent} label={props.label}>
        {itemsElement}
      </Select>
    </FormControl>
  );
};
