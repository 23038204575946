import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updatePortfolioDashboardConfigs } from '~src/data/store/modules/portfolio-configs/portfolio-dashboard-configs/requests';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useNameField } from '~src/domain/workspace/components/project/portfolio/config/use-name-field.component';
import { useEditCompactTypeField } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/edit-compact-type-field';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  config: PortfolioDashboardConfigState;
}

export interface InputData {
  id: string;
  name: string;
  compactType: CompactType;
}

export const useEditDialog = (props: Props) => {
  const { workspace, config } = props;
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { control, handleSubmit, watch } = useForm<InputData>({
    defaultValues: {
      id: config.id,
      compactType: config.compactType,
      name: config.name ?? '',
    },
  });

  const onSave = React.useCallback(
    (data: InputData) => {
      return dispatch(
        updatePortfolioDashboardConfigs(
          workspace.tenantId,
          workspace.id,
          config.portfolioConfigId,
          [data],
        ),
      );
    },
    [config.portfolioConfigId, dispatch, workspace.id, workspace.tenantId],
  );

  const onSubmit: SubmitHandler<InputData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  const nameField = useNameField(control, 'name', '');
  const compactTypeField = useEditCompactTypeField(
    control,
    'compactType',
    'vertical',
  );

  const content = React.useMemo(() => {
    return (
      <form noValidate autoComplete="off">
        {nameField}
        {compactTypeField}
      </form>
    );
  }, [compactTypeField, nameField]);

  const dialogElement = React.useMemo(() => {
    return (
      <DialogComponent
        dialogProps={{ open, onClose: () => setOpen(false), fullWidth: true }}
      >
        <DialogContentComponent
          handleCancel={() => setOpen(false)}
          title={'Edit config'}
        >
          {content}
        </DialogContentComponent>
      </DialogComponent>
    );
  }, [content, open]);

  return { dialogElement, setOpen };
};
