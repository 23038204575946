import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

import { TextField } from '@mui/material';
// import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

export const useTransferStockPositionSharesField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  fieldConfig?: FieldConfig,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<
        TFieldValues,
        TName // 'data.stockTrade.shares'
      >,
      fieldState: ControllerFieldState,
      disabled?: boolean,
    ) => (
      <TextField
        margin="normal"
        required
        id="shares-position"
        value={field.value}
        label="Share position"
        type="number"
        onChange={(event) => field.onChange(+event.target.value)}
        disabled={disabled}
        error={!!fieldState.error}
        helperText={fieldState.error ? fieldState.error.message : null}
      />
    ),
    [],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName} // {'data.stockTrade.shares'}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
