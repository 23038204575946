import { baseReducer } from '~src/data/base/old/base-array-reducer';

import { DELETED_FILTER, FILTER } from './action';
import { Filter } from '~src/utils/common/filter';

export interface HoldingFilterState {
  id: number;
  filter: Filter;
}

export const filtersInitialState: HoldingFilterState[] = [];
export const filtersReducer = baseReducer<HoldingFilterState>(
  { single: FILTER, singleDeleted: DELETED_FILTER },
  filtersInitialState,
);
