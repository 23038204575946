import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseBondHoldingId {
  holdingId: string;
  id: string;
}

export type BondHoldingState = Omit<
  graphqlWorkspaceTypes.BondHolding,
  'holding' | 'bond'
> & {
  holdingId: string;
  bondId: string;
};

export const initialBondHoldingState = { ts: [], idxs: [] };

const selectId = (bondHolding: BaseBondHoldingId) => bondHolding.id;
const selectIdx = (bondHolding: BaseBondHoldingId) => bondHolding.holdingId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: BondHoldingState, b: BondHoldingState) => b;

const adapter = new EntityIndexedAdapter<
  BaseBondHoldingId,
  BondHoldingState,
  string,
  string
>(
  initialBondHoldingState,
  selectId,
  selectIdx,
  merger,
  iComparator,
  idxComparator,
);

export const bondHoldingsSlice = createBaseIndexedSlice(
  'bondHoldings',
  adapter,
);
export const bondHoldingsSelectors = adapter.selectors;
export const bondHoldingsReducer = bondHoldingsSlice.reducer;
export const bondHoldingsActions = bondHoldingsSlice.actions;
