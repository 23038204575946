import React from 'react';
import { useSelector } from 'react-redux';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getTransactionsByProjectId } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

interface Props {
  handleValue: (ts: TransactionState[]) => void;
  handlePersist?: () => void;
  transactions: TransactionState[];
  label?: string;
  workspace: WorkspaceState;
  project: ProjectState;
}

export const EditTransactions = (props: Props) => {
  const { project, label, transactions, handlePersist, handleValue } = props;
  const allTransactions = useSelector((state: RootState) =>
    getTransactionsByProjectId(selectWorkspaceData(state), project.id),
  );
  const getOptionLabel = React.useCallback(
    (t: TransactionState) => {
      const transaction = allTransactions.find(
        (transaction) => transaction.id === t.id,
      );
      return transaction?.title ? transaction.title : '';
    },
    [allTransactions],
  );
  const isOptionEqualToValue = (
    option: TransactionState,
    value: TransactionState,
  ) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={true}
      getOptionLabel={getOptionLabel}
      label={label}
      handleValue={handleValue}
      options={allTransactions}
      value={transactions}
      handlePersist={handlePersist}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
