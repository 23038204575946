import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createAssets } from '~src/data/store/modules/assets/assets/requests';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';
import { useAssetCreateFields } from '~src/domain/workspace/components/asset/form/create/use-asset-create-fields';
import { FieldsConfig } from '~src/domain/workspace/components/asset/form/fields/fields.config';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: AssetCreateInputData;
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  };
  fieldsConfig: FieldsConfig;
}

export type AssetForm = ReturnType<typeof useAssetCreateForm>;

export const useAssetCreateForm = (props: Props) => {
  const { workspace, inputData, callbacks, fieldsConfig } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: AssetCreateInputData) => {
      if (
        data.entityId == null ||
        data.categoryId == null ||
        data.type == null
      ) {
        callbacks?.onFailure && callbacks.onFailure('Missing data');
        return Promise.reject();
      }
      return dispatch(
        createAssets(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            name: data.name,
            entityId: data.entityId,
            assetClass: data.assetClass,
            categoryId: data.categoryId,
            description: data.description,
            ISIN: data.ISIN,
            lookup: data.lookup,
            type: data.type,
            types: data.types,
            VPS: data.VPS,
          },
        ]),
      )
        .then((assets) => {
          const asset = assets[0];
          callbacks?.onSuccess && callbacks.onSuccess();
          return asset;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<AssetCreateInputData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useAssetCreateFields(workspace, control, fieldsConfig);

  const onSubmit = React.useCallback(
    (
      successCallback: (asset: AssetState) => Promise<void>,
    ): SubmitHandler<AssetCreateInputData> =>
      (data) => {
        return onSave(data).then((asset) => {
          return successCallback(asset);
        });
      },
    [onSave],
  );

  const submit = React.useCallback(
    (successCallback: (asset: AssetState) => Promise<void>) => {
      return handleSubmit(onSubmit(successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit, control };
};
