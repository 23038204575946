import { regionAllocationsActions } from '~src/data/store/reducers/entity/region-allocations/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleRegionAllocations } from './handler';

export function fetchRegionAllocations(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetRegionAllocationsDocument;
    const variables: graphqlWorkspaceTypes.GetRegionAllocationsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getRegionAllocations) {
        return handleRegionAllocations(dispatch, data.getRegionAllocations);
      }
      return [];
    });
  };
}

export function createRegionAllocations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateRegionAllocationInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateRegionAllocationsDocument;
    const variables: graphqlWorkspaceTypes.CreateRegionAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createRegionAllocations) {
        return handleRegionAllocations(dispatch, data.createRegionAllocations);
      }
      return [];
    });
  };
}

export function updateRegionAllocations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateRegionAllocationInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateRegionAllocationsDocument;
    const variables: graphqlWorkspaceTypes.UpdateRegionAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateRegionAllocations) {
        return handleRegionAllocations(dispatch, data.updateRegionAllocations);
      }
      return [];
    });
  };
}

export function upsertRegionAllocations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertRegionAllocationInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertRegionAllocationsDocument;
    const variables: graphqlWorkspaceTypes.UpsertRegionAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertRegionAllocations) {
        return handleRegionAllocations(dispatch, data.upsertRegionAllocations);
      }
      return [];
    });
  };
}

export function deleteRegionAllocations(
  tenantId: string,
  workspaceId: string,
  regionAllocations: Array<{ id: string; entityId: string }>,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteRegionAllocationsDocument;
    regionAllocations.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteRegionAllocationInputType[] =
      regionAllocations.map((regionAllocation) => {
        return {
          id: regionAllocation.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeleteRegionAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteRegionAllocations) {
        const deletedRegionAllocations = intersectionSorted(
          regionAllocations,
          data.deleteRegionAllocations,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, entityId: a.entityId };
          },
        );
        return dispatch(
          regionAllocationsActions.removeManyElements(deletedRegionAllocations),
        );
      }
      return [];
    });
  };
}
