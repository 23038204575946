import { useDispatch } from 'react-redux';
import {
  deleteAssets,
  updateAssets,
} from '~src/data/store/modules/assets/assets/requests';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';
import { AppDispatch } from '~src/store/store';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';

export function useAssetActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onUpdate = (oldData: AssetState, newData: AssetState) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceOperations.CreateAssetInputType = {
          id: newData.id,
          entityId: newData.entityId,
          name: newData.name,
          type: newData.type,
          description: newData.description,
          types: newData.types,
          categoryId: newData.categoryId ?? '',
          assetClass: newData.assetClass,
          ISIN: newData.ISIN,
          VPS: newData.VPS,
          lookup: newData.lookup,
        };
        dispatch(updateAssets(workspace.tenantId, workspace.id, [input]))
          .then((_assets) => {
            resolve();
          })
          .catch((_err) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onDelete = (oldData: AssetState) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deleteAssets(workspace.tenantId, workspace.id, [{ id: oldData.id }]),
      )
        .then((_assetIds) => {
          resolve();
        })
        .catch((_err) => {
          reject();
        });
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onUpdate, onDelete };
}
