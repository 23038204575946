import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseBankNoteId {
  id: string;
  workspaceId: string;
}

export type BankNoteState = Omit<
  graphqlWorkspaceTypes.BankNote,
  'asset' | 'currency' | '_type'
> & {
  workspaceId: string;
  assetId: string;
  currencyId: string;
};

export function bankNoteToBankNoteState(bankNote: BankNoteState) {
  return bankNote;
}
const initialState: RecordState<BankNoteState, string>[] = [];

const selectKeyId = (bankNote: BaseBankNoteId) => bankNote.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseBankNoteId, b: BaseBankNoteId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseBankNoteId, BankNoteState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const bankNotesSlice = createBaseRecordSlice('bankNotes', adapter);
export const bankNotesSelectors = adapter.selectors;

export const bankNotesReducer = bankNotesSlice.reducer;
export const bankNotesActions = bankNotesSlice.actions;

export const initialBankNoteState = {
  bankNotes: initialState,
};

export const bankNoteReducer = combineReducers({
  bankNotes: bankNotesReducer,
});
