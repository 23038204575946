import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateCryptos } from '~src/data/store/modules/assets/cryptos/requests';
import { CryptoState } from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputCryptoData } from '~src/domain/workspace/components/asset/types/cryptos/form/crypto-input.data';
import { useCryptoFields } from '~src/domain/workspace/components/asset/types/cryptos/form/use-crypto-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  crypto: CryptoState;
  inputData?: InputCryptoData;
  callbacks?: {
    onSuccess?: (cryptos: CryptoState[]) => void;
    onFailure?: (error: any) => void;
  };
}

export const useEditCryptoForm = (props: Props) => {
  const { crypto, workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputCryptoData) => {
      return dispatch(
        updateCryptos(workspace.tenantId, workspace.id, [
          {
            id: crypto.id,
            assetId: data.assetId,
            accountNumber: data.accountNumber,
            bank: data.bank,
            currencyId: data.currencyId,
            description: data.description,
          },
        ]),
      )
        .then((cryptos) => callbacks?.onSuccess && callbacks.onSuccess(cryptos))
        .catch((err) => callbacks?.onFailure && callbacks.onFailure(err));
    },
    [dispatch, workspace.tenantId, workspace.id, crypto.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<InputCryptoData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useCryptoFields(workspace, control);

  const onSubmit: SubmitHandler<InputCryptoData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields };
};
