import * as graphql from 'graphql';
import * as uuid from 'uuid';
import { Api } from '~src/services/request/api';
import { Subscription } from '~src/services/request/subscription';

import { Logger, LogLevel } from '@pladdenico/common';
import { User } from '@pladdenico/models';

import { SocketListener } from './socket-listener';

export class InvestApi {
  private _logger: Logger;
  private _api: Api;
  private _id: string;

  private _listeners: SocketListener[];

  constructor() {
    this._logger = new Logger('InvestApi', LogLevel.INFO);
    this._id = uuid.v1();

    this._listeners = this._setupListeners();
    this._api = new Api('auth/invest');
  }

  private _setupListeners() {
    return [
      // {
      //   name: 'quote',
      //   ev: (res: {
      //     paper: graphqlFinanceTypes.Paper;
      //     quotes: graphqlFinanceTypes.Quote[];
      //   }) => {
      //     console.log('Quote received', res.paper.symbol);
      //     const paperQuotes: {
      //       paper: graphqlFinanceTypes.Paper;
      //       quotes: graphqlFinanceTypes.Quote[];
      //     } = res;
      //     this.listenToQuoteStop(paperQuotes.paper.id);
      //     if (paperQuotes.quotes) {
      //       const quotes = paperQuotes.quotes.map((quote) =>
      //         parseQuote(res.paper.id, quote)
      //       );
      //       this._store.dispatch(quotesActions.upsertManyElements(quotes));
      //       // console.log('Quotes', paperQuotes.paper, JSON.stringify(paperQuotes.quotes));
      //       // this.store.dispatch(quotesAction([paperQuotes]));
      //     }
      //   },
      // },
    ];
  }

  public connect(user: User, token: string) {
    this._api.connect(user, token, this._listeners);
  }

  public subscribe(subscription: Subscription, callback: () => void) {
    this._api.subscribe(subscription, callback);
  }

  public unsubscribe(subscription: Subscription) {
    this._api.unsubscribe(subscription);
  }

  // public listenToQuoteStart(
  //   paper: PaperState,
  //   fromDate: Date,
  //   toDate: Date,
  //   callback: () => void
  // ) {
  //   this._api.emit({
  //     name: 'quote',
  //     args: { id: this._id, paper, fromDate, toDate },
  //     callback,
  //   });
  // }

  // public listenToQuoteStop(paperId: string) {
  //   this._api.emit({
  //     name: 'quote.stop',
  //     args: { id: this._id, paperId },
  //   });
  // }

  public executeQuery<Context>(
    query: graphql.DocumentNode,
    variables: unknown,
    context: Context,
    callback: (result: any) => void,
  ) {
    this._api.executeGraphQLQuery(
      'graphQLQuery',
      query,
      variables,
      context,
      callback,
    );
  }
}
