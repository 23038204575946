import { createSelector, weakMapMemoize } from 'reselect';
import { workspacesSelectors } from '../../../reducers/workspace/workspaces/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';

export const selectWorkspaces = (state: WorkspaceDataState) => {
  return state.workspace.workspaces;
};

// export const getWorkspaces = createSelector(selectWorkspaces, (workspaces) => {
//   return workspaces;
// });

interface BaseId {
  id: string | null;
  tenantId: string | null;
}

export const getWorkspaceById = createSelector(
  (state: WorkspaceDataState) => selectWorkspaces(state),
  (_state: WorkspaceDataState, baseWorkspaceId: BaseId) =>
    baseWorkspaceId.tenantId,
  (_state: WorkspaceDataState, baseWorkspaceId: BaseId) => baseWorkspaceId.id,
  (workspaces, tenantId, workspaceId) => {
    if (tenantId && workspaceId) {
      return workspacesSelectors.selectElementByT(workspaces, {
        id: workspaceId,
        tenantId,
      });
    }
    return undefined;
  },
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);

export const getWorkspacesByTenantId = createSelector(
  (state: WorkspaceDataState) => selectWorkspaces(state),
  (_state: WorkspaceDataState, tenantId: string) => tenantId,
  (workspaces, tenantId) => {
    const res = workspacesSelectors.selectElementsByKey(workspaces, tenantId);
    return res ? res : [];
  },
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);
