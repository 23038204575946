import { portfolioConfigsActions } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handlePortfolioConfigs } from './handler';

export function fetchPortfolioConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioId: string,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPortfolioConfigsDocument;
    const variables: graphqlWorkspaceTypes.GetPortfolioConfigsQueryVariables = {
      portfolioId,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPortfolioConfigs) {
        return handlePortfolioConfigs(
          dispatch,
          portfolioId,
          data.getPortfolioConfigs,
          tenantId,
          workspaceId,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createPortfolioConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioId: string,
  inputs: graphqlWorkspaceTypes.CreatePortfolioConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreatePortfolioConfigsDocument;
    const variables: graphqlWorkspaceTypes.CreatePortfolioConfigsMutationVariables =
      {
        portfolioId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPortfolioConfigs) {
        return handlePortfolioConfigs(
          dispatch,
          portfolioId,
          data.createPortfolioConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updatePortfolioConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioId: string,
  inputs: graphqlWorkspaceTypes.UpdatePortfolioConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdatePortfolioConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePortfolioConfigsMutationVariables =
      {
        portfolioId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePortfolioConfigs) {
        return handlePortfolioConfigs(
          dispatch,
          portfolioId,
          data.updatePortfolioConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertPortfolioConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioId: string,
  inputs: graphqlWorkspaceTypes.UpsertPortfolioConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertPortfolioConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPortfolioConfigsMutationVariables =
      {
        portfolioId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPortfolioConfigs) {
        return handlePortfolioConfigs(
          dispatch,
          portfolioId,
          data.upsertPortfolioConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deletePortfolioConfigs(
  tenantId: string,
  workspaceId: string,
  portfolioId: string,
  ids: graphqlWorkspaceTypes.DeletePortfolioConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeletePortfolioConfigsDocument;
    const variables: graphqlWorkspaceTypes.DeletePortfolioConfigsMutationVariables =
      {
        portfolioId,
        inputs: ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePortfolioConfigs) {
        dispatch(
          portfolioConfigsActions.removeManyElements(
            data.deletePortfolioConfigs.map((p) => {
              return {
                portfolioId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
