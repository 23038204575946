import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectUserData } from '~src/data/store/selectors/selectors';
import { WorkspaceSearchComponent } from '~src/domain/workspace/components/search/workspace-search-component';
// import history from '~src/navigation/history';
import { homeBasePath } from '~src/navigation/paths/home/paths';
import {
  userLoginPath,
  userSignupPath,
} from '~src/navigation/paths/user/paths';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';

import { MessagesBadgeComponent } from '../../../../components/top-bar/messages-badge.component';
import { NotificationsBadgeComponent } from '../../../../components/top-bar/notifications-badge.component';
import { useProfileMenu } from '../../../../components/top-bar/use-profile-menu';
import { DrawerType } from '~src/utils/common/use-drawer';

// import { WorkspacesComponent } from './workspaces.component';

interface Props {
  drawer?: DrawerType;
}

const useStyles = makeStyles()((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  menuButton: {
    marginRight: 36,
  },

  hide: {
    display: 'none',
  },
}));

export const TopBarMobileComponent = React.memo((props: Props) => {
  const { classes, cx } = useStyles();
  const account = useSelector(
    (state: RootState) => selectUserData(state).account,
  );

  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = 'primary-search-account-menu';
  const { profileMenu, handleOpen } = useProfileMenu(
    account.user,
    menuId,
    handleMobileMenuClose,
  );
  const loggedIn = account.user !== undefined;

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  // const workspaces = loggedIn ? (
  //   <MenuItem>
  //     <WorkspacesComponent />
  //     <p>Workspaces</p>
  //   </MenuItem>
  // ) : undefined;

  const messagesBadge = loggedIn ? (
    <MenuItem>
      <MessagesBadgeComponent />
      <p>Messages</p>
    </MenuItem>
  ) : undefined;

  const loginComponent = !loggedIn ? (
    <MenuItem onClick={handleGoToLogin}>Sign in</MenuItem>
  ) : undefined;

  const signupComponent = !loggedIn ? (
    <MenuItem onClick={handleGoToSignup}>Sign up</MenuItem>
  ) : undefined;

  const notificationBadge = loggedIn ? (
    <MenuItem>
      <NotificationsBadgeComponent />
      <p>Notifications</p>
    </MenuItem>
  ) : undefined;

  function handleGoToSignup() {
    navigate(userSignupPath);
  }

  function handleGoToLogin() {
    navigate(userLoginPath);
  }

  const profileButton = loggedIn ? (
    <MenuItem onClick={handleOpen}>
      <IconButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <p>Profile</p>
    </MenuItem>
  ) : undefined;

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const mobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* {workspaces} */}
      {messagesBadge}
      {notificationBadge}
      {profileButton}
      {loginComponent}
      {signupComponent}
    </Menu>
  );

  const drawerIcon = props.drawer?.handleDrawerOpen ? (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={props.drawer.handleDrawerOpen}
      edge="start"
      className={cx(classes.menuButton, {
        [classes.hide]: props.drawer.open,
      })}
    >
      <MenuIcon />
    </IconButton>
  ) : undefined;

  const handleGoToHome = () => {
    navigate(homeBasePath);
  };

  const searchComponent =
    tenantId && workspaceId ? (
      <WorkspaceSearchComponent tenantId={tenantId} workspaceId={workspaceId} />
    ) : undefined;

  return (
    <>
      <AppBar position="fixed" className={cx(classes.appBar)}>
        <Toolbar>
          {drawerIcon}
          <IconButton
            aria-label="home"
            onClick={handleGoToHome}
            color="inherit"
          >
            <HomeIcon />
          </IconButton>
          {searchComponent}
          <div className={classes.grow} />
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenu}
      {profileMenu}
    </>
  );
});
