import { strcmp } from '@pladdenico/common';
import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

export interface BaseOrganizationRoleGroupsId {
  id: string;
}

export interface OrganizationType {
  code: string;
  description: string;
}
export interface OrganizationPerson {
  _type: 'person';
  birthDate: Date;
  firstName: string;
  lastName: string;
  isDead: boolean;
}

export interface OrganizationEntityForm {
  code: string;
  description: string;
}

export interface OrganizationEntity {
  _type: 'entity';
  isDeleted: boolean;
  name: string;
  form: OrganizationEntityForm;
  organizationNumber: string;
  resigned: boolean;
  order: number;
  type: OrganizationType | undefined;
}

export function isPerson(
  personOrEntity: OrganizationPerson | OrganizationEntity,
): personOrEntity is OrganizationPerson {
  return personOrEntity._type === 'person';
}

export function isEntity(
  personOrEntity: OrganizationPerson | OrganizationEntity,
): personOrEntity is OrganizationEntity {
  return personOrEntity._type === 'entity';
}

export interface OrganizationRole {
  code: string;
  description: string;
  personOrEntity: OrganizationPerson | OrganizationEntity | undefined;
  resigned: string;
}

export interface OrganizationRoleGroup {
  type: OrganizationType;
  lastChanged: Date;
  roles: OrganizationRole[];
}

export interface OrganizationRoleGroupsState {
  id: string;
  groups: OrganizationRoleGroup[];
}

export function organizationToOrganizationRoleState(
  organizationRoleGroups: OrganizationRoleGroupsState,
) {
  return organizationRoleGroups;
}
export const initialOrganizationRolesState = [];

const comparator = (
  a: BaseOrganizationRoleGroupsId,
  b: BaseOrganizationRoleGroupsId,
) => strcmp(a.id, b.id);
const merger = (
  _a: OrganizationRoleGroupsState,
  b: OrganizationRoleGroupsState,
) => b;

const adapter = new EntityArrayAdapter<
  BaseOrganizationRoleGroupsId,
  OrganizationRoleGroupsState
>(initialOrganizationRolesState, comparator, merger);

export const organizationRoleGroupsSlice = createBaseArraySlice(
  'organizationRoleGroups',
  adapter,
);
export const organizationRoleGroupsSelectors = adapter.selectors;

export const organizationRoleGroupsReducer =
  organizationRoleGroupsSlice.reducer;
export const organizationRoleGroupsActions =
  organizationRoleGroupsSlice.actions;
