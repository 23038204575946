import React from 'react';
import * as uuid from 'uuid';
import { EditRegionField } from '~src/components/fields/common/edit-region-field.component';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { eventParser } from '~src/utils/form/event-parser';

import Autorenew from '@mui/icons-material/Autorenew';
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  projectId: string;
  portfolio: PortfolioState;
  allocationInput: graphqlWorkspaceTypes.CreateAllocationInputType;
  setAllocationInput: (
    allocationInput: graphqlWorkspaceTypes.CreateAllocationInputType,
  ) => void;
  showId: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const CreateAllocationFieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof graphqlWorkspaceTypes.CreateAllocationInputType) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.setAllocationInput({
        ...props.allocationInput,
        [name]: eventParser(e),
      });
    };

  const handleClickRegenerateId = () => {
    props.setAllocationInput({ ...props.allocationInput, id: uuid.v1() });
  };

  const handleMouseDownRegenerateId = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleRegionChange = (region: RegionState) => {
    if (region) {
      props.setAllocationInput({
        ...props.allocationInput,
        regionId: region.id,
      });
    }
  };

  const handleCurrencyChange = (currency: CurrencyState) => {
    if (currency) {
      props.setAllocationInput({
        ...props.allocationInput,
        currencyId: currency.id,
        // regionType: region.type,
        // region,
      });
    }
  };

  const idElement = props.showId ? (
    <Grid item sm={4}>
      <FormControl
        fullWidth
        required
        className={classes.textField}
        margin="normal"
      >
        <InputLabel htmlFor="adornment-id">Id</InputLabel>
        <Input
          id="adornment-id"
          type={'text'}
          value={props.allocationInput.id}
          onChange={handleChange('id')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="id regeneration"
                onClick={handleClickRegenerateId}
                onMouseDown={handleMouseDownRegenerateId}
              >
                <Autorenew />
                {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>
  ) : undefined;

  return (
    <>
      <Grid container spacing={1}>
        {idElement}
        <Grid item sm={4}>
          <EditCurrencyField
            handleValue={handleCurrencyChange}
            currencyId={props.allocationInput.currencyId ?? ''}
            labelled={true}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="assetClass"
            value={props.allocationInput.assetClass}
            label="Asset class"
            type="text"
            onChange={handleChange('assetClass')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="assetType"
            value={props.allocationInput.assetType}
            label="Asset type"
            type="text"
            onChange={handleChange('assetType')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="category"
            value={props.allocationInput.category}
            label="Category"
            type="text"
            onChange={handleChange('category')}
          />
        </Grid>
        <Grid item sm={4}>
          <EditRegionField
            handleValue={handleRegionChange}
            regionId={props.allocationInput.regionId ?? ''}
            labelled={true}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="min"
            value={props.allocationInput.min}
            label="Min"
            type="number"
            onChange={handleChange('min')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="max"
            value={props.allocationInput.max}
            label="Max"
            type="number"
            onChange={handleChange('max')}
          />
        </Grid>
      </Grid>
    </>
  );
});
