import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { strcmp } from '@pladdenico/common';

export interface BaseScenarioId {
  projectId: string;
  id: string;
}

export type ScenarioState = Scenario;

export const initialScenariosState = [];

const selectKeyId = (scenario: BaseScenarioId) => scenario.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseScenarioId, b: BaseScenarioId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseScenarioId, ScenarioState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const scenariosSlice = createBaseRecordSlice('scenarios', adapter);
export const scenariosSelectors = adapter.selectors;

export const scenariosReducer = scenariosSlice.reducer;
export const scenariosActions = scenariosSlice.actions;
