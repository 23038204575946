import { RecordState } from '@pladdenico/common';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import {
  StockGoalRuleState,
  stockGoalRulesSelectors as stockGoalRulesSelectors,
  BaseStockGoalRuleId,
} from '../../../../../reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

const getStockGoalRuleState = (state: WorkspaceDataState) =>
  state.holding.holding.stockHolding.stockGoalRules;

export const selectStockGoalRulesByStockHoldingId = createCachedSelector(
  (state: RecordState<StockGoalRuleState, string>[], stockHoldingId: string) =>
    stockGoalRulesSelectors.selectElementsByKey(state, stockHoldingId),
  (stockGoalRules: StockGoalRuleState[] | undefined): StockGoalRuleState[] => {
    return stockGoalRules ? stockGoalRules : [];
  },
)({
  keySelector: (_state, stockHoldingId) => stockHoldingId,
  selectorCreator: createSelector,
});

export const getStockGoalRulesByStockHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getStockGoalRuleState(state),
  (_state: WorkspaceDataState, stockHoldingId: string) => stockHoldingId,
  (stockGoalRuleState, stockHoldingId): StockGoalRuleState[] => {
    return selectStockGoalRulesByStockHoldingId(
      stockGoalRuleState,
      stockHoldingId,
    );
  },
)({
  keySelector: (_state, stockHoldingId) => stockHoldingId,
  selectorCreator: createSelector,
});

export const getStockGoalRules = createSelector(
  (state: WorkspaceDataState) => getStockGoalRuleState(state),
  (stockGoalRules) => {
    return stockGoalRulesSelectors.selectAllElements(stockGoalRules);
  },
);

export const getStockGoalRuleById = createCachedSelector(
  (state: WorkspaceDataState) => getStockGoalRuleState(state),
  (_state: WorkspaceDataState, baseId: BaseStockGoalRuleId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseStockGoalRuleId) =>
    baseId.stockHoldingId,
  (stockGoalRules, id, stockHoldingId): StockGoalRuleState | undefined => {
    return stockGoalRulesSelectors.selectElementByT(stockGoalRules, {
      id,
      stockHoldingId,
    });
  },
)({
  keySelector: (_state, stockHoldingId, stockGoalRuleId) =>
    `${stockHoldingId}:${stockGoalRuleId}`,
  selectorCreator: createSelector,
});

interface StockHoldingMultipleIds {
  stockHoldingId: string;
  ids: string[];
}

export const getStockGoalRulesByIds = createCachedSelector(
  (state: WorkspaceDataState) => getStockGoalRuleState(state),
  (_state: WorkspaceDataState, ids: StockHoldingMultipleIds) =>
    ids.stockHoldingId,
  (_state: WorkspaceDataState, ids: StockHoldingMultipleIds) => ids.ids,
  (stockGoalRules, stockHoldingId, ids): StockGoalRuleState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        stockHoldingId,
      };
    });
    return stockGoalRulesSelectors.selectElementsByTs(stockGoalRules, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.stockHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});
