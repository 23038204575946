import * as React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { EventFields } from '~src/domain/workspace/components/project/event/algorithm/use-fields';
// import history from '~src/navigation/history';
import { eventBasePath } from '~src/navigation/paths/workspace/project/event/paths';
import { eventParser } from '~src/utils/form/event-parser';

import { Link, TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';
import { useNavigate } from 'react-router-dom';

export function useEventViewFields(
  workspace: WorkspaceState,
  projectId: string,
  fields: EventFields,
): TableField.Field<EventState>[] {
  const navigate = useNavigate();
  const viewFields: TableField.Field<EventState>[] = React.useMemo(
    () => [
      {
        ...fields.name,
        update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
          return {
            ...data,
            name: eventParser(event) as string,
          };
        },
        renderView: (viewProps) => {
          return (
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                navigate(
                  eventBasePath(
                    workspace.tenantId,
                    workspace.id,
                    projectId,
                    viewProps.data.id,
                  ),
                )
              }
            >
              {viewProps.data.name}
            </Link>
          );
        },
        renderEdit: (editProps) => {
          return (
            <TextField
              margin="normal"
              // className={classes.textField}
              required
              id="name"
              value={editProps.data.name}
              type="text"
              onChange={editProps.onChange}
            />
          );
        },
        style: {
          minWidth: 140,
        },
      },
      {
        ...fields.description,
        update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
          return {
            ...data,
            description: eventParser(event) as string,
          };
        },
        renderEdit: (editProps) => {
          return (
            <TextField
              margin="normal"
              required
              id="description"
              value={editProps.data.description}
              type="text"
              onChange={editProps.onChange}
            />
          );
        },
        style: {
          minWidth: 140,
        },
      },
      {
        ...fields.date,
        update: (data, date: Date) => {
          return {
            ...data,
            date,
          };
        },
        renderEdit: (editProps) => {
          return (
            <TextField
              margin="normal"
              required
              id="date"
              value={editProps.data.date}
              type="text"
              onChange={editProps.onChange}
            />
          );
        },
        style: {
          minWidth: 140,
        },
      },
    ],
    [
      fields.date,
      fields.description,
      fields.name,
      navigate,
      projectId,
      workspace.id,
      workspace.tenantId,
    ],
  );
  return viewFields;
}
