import React from 'react';
import { typeToString } from '~src/components/fields/workspace/transfer/type/view-type-field';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TransferType } from '@pladdenico/models';

const useStyles = makeStyles()(() => ({
  types: {
    minWidth: 100,
  },
}));
interface Props {
  handleValue: (type: TransferType) => void;
  type: TransferType | undefined;
  label: string | undefined;
  transferTypes?: TransferType[];
  disabled?: boolean;
}

export const EditTypeField = React.memo((props: Props) => {
  const { classes } = useStyles();
  const handleSelectChange = (event: SelectChangeEvent<TransferType>) => {
    props.handleValue(event.target.value as TransferType);
  };

  const label = props.label ? (
    <InputLabel htmlFor="Type">{props.label}</InputLabel>
  ) : undefined;

  const menuItems = React.useMemo(() => {
    return Object.values(TransferType).map((type) => {
      const disabled =
        props.transferTypes && !props.transferTypes?.some((t) => t === type);
      return (
        <MenuItem key={type} value={type} disabled={disabled}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, [props.transferTypes]);

  const disabled =
    props.transferTypes && props.type && props.transferTypes.length === 1
      ? props.transferTypes.some((t) => t === props.type)
      : false;

  return (
    <FormControl margin="normal" className={classes.types} fullWidth>
      {label}
      <Select
        value={props.type}
        disabled={disabled}
        onChange={handleSelectChange}
        label={props.label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
