import { parseHoldingObj } from '~src/data/store/modules/holdings/base/holding.parser';
import { subscribeToHoldings } from '~src/data/store/modules/holdings/base/subscription';
import {
  HoldingConfigStateVisitor,
  HoldingConfigVisitable,
  HoldingConfigVisitor,
} from '~src/data/store/visitors/holding/config/holding-config-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  holdingsActions,
  HoldingState,
} from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingObj } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';
import { holdingActions } from '~src/data/store/reducers/holding/new-reducer';
// import { holdingActions } from '~src/data/store/reducers/holding/new-reducer';

export interface HoldingObjVisitor {
  visit(holding: HoldingObjVisitable): HoldingState;
  post(): void;
  holdingConfigVisitor: HoldingConfigVisitor;
}

export class HoldingObjVisitable implements Visitable<HoldingObjVisitor> {
  constructor(private _holding: HoldingObj) {}
  public accept(visitor: HoldingObjVisitor) {
    const holding = this._holding;
    if (holding.configs) {
      holding.configs.forEach((config) => {
        const holdingConfigVisitable = new HoldingConfigVisitable(
          this._holding.id,
          config,
        );
        holdingConfigVisitable.accept(visitor.holdingConfigVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): HoldingState {
    const holding = this._holding;
    return parseHoldingObj(holding);
  }
}

// export class HoldingObjHandlerVisitor implements HoldingObjVisitor {
//   constructor(
//     private _handle: (holding: HoldingState) => void,
//     public holdingConfigVisitor: HoldingConfigVisitor
//   ) {}
//   public visit(holding: HoldingObjVisitable): HoldingState {
//     const holdingState = holding.parse();
//     this._handle(holdingState);
//     return holdingState;
//   }
// }

// export class HoldingObjStateVisitor extends HoldingObjHandlerVisitor {
//   constructor(
//     dispatch: AppDispatch,
//     tenantId: string,
//     workspaceId: string,
//     subscriptions: Operation[]
//   ) {
//     super((holding) => {
//       dispatch(holdingsActions.upsertOneElement(holding));
//       subscribeToHoldings(
//         dispatch,
//         tenantId,
//         workspaceId,
//         [holding],
//         subscriptions
//       );
//     }, new HoldingConfigStateVisitor(dispatch, tenantId, workspaceId, subscriptions));
//   }
// }

export class HoldingObjStateVisitor implements HoldingObjVisitor {
  private _holdingObjs: HoldingState[];
  public holdingConfigVisitor: HoldingConfigVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._holdingObjs = [];
    this.holdingConfigVisitor = new HoldingConfigStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(holding: HoldingObjVisitable): HoldingState {
    const holdingObj = holding.parse();
    this._holdingObjs.push(holdingObj);
    return holdingObj;
  }
  post() {
    this._dispatch(
      holdingsActions.upsertManyElements(this._holdingObjs, {
        shouldAutobatch: true,
      }),
    );
    this._holdingObjs.forEach((holdingObj) =>
      this._dispatch({ id: holdingObj.id, ...holdingActions.set(holdingObj) }),
    );

    subscribeToHoldings(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._holdingObjs,
      this._subscriptions,
    );
    this.holdingConfigVisitor.post();
  }
}
