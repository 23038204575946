import { combineReducers } from 'redux';

import {
  initialLoanHoldingState,
  loanHoldingsReducer,
} from './loan-holding/reducer';

export const loanHoldingInitialState = {
  loanHoldings: initialLoanHoldingState,
  // loanHoldingsFetch: initialLoanHoldingsFetchReducer,
  // loanPosition: loanPositionInitialState,
};

export const loanHoldingReducer = combineReducers({
  loanHoldings: loanHoldingsReducer,
  // loanHoldingsFetch: loanHoldingsFetchReducer,
  // loanPosition: loanPositionReducer,
});
