import { intersectionSorted, RecordState, strcmp } from '@pladdenico/common';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  BasePortfolioProjectId,
  PortfolioProjectState,
  portfolioProjectSelectors as portfolioProjectsSelectors,
} from '../../../../../reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';

const getPortfolioProjectState = (state: WorkspaceDataState) =>
  state.workspace.project.portfolioProject.portfolioProjects;

export const getPortfolioProjects = createSelector(
  (state: WorkspaceDataState) => getPortfolioProjectState(state),
  (portfolioProjects) => {
    return portfolioProjectsSelectors.selectAllElements(portfolioProjects);
  },
);

export const getPortfolioProjectsByWorkspaceId = createCachedSelector(
  (state: RecordState<PortfolioProjectState, string>[], _workspaceId: string) =>
    state,
  (_state: RecordState<PortfolioProjectState, string>[], workspaceId: string) =>
    workspaceId,
  (state, workspaceId): PortfolioProjectState[] => {
    return (
      portfolioProjectsSelectors.selectElementsByKey(state, workspaceId) ?? []
    );
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

export const getPortfolioProjectByProjectId = createCachedSelector(
  (state: WorkspaceDataState, workspaceId: string, _projectId: string) =>
    getPortfolioProjectsByWorkspaceId(
      getPortfolioProjectState(state),
      workspaceId,
    ),
  (_state: WorkspaceDataState, _workspaceId: string, projectId: string) =>
    projectId,
  (portfolioProjects, projectId): PortfolioProjectState | undefined => {
    return portfolioProjects.find((p) => p.projectId === projectId);
  },
)({
  keySelector: (_state, workspaceId, projectId) =>
    `${workspaceId}:${projectId}`,
  selectorCreator: createSelector,
});

export const getPortfolioProjectsByProjectIds = createCachedSelector(
  (state: WorkspaceDataState, workspaceId: string, _projectIds: string[]) =>
    getPortfolioProjectsByWorkspaceId(
      getPortfolioProjectState(state),
      workspaceId,
    ),
  (_state: WorkspaceDataState, _workspaceId: string, projectIds: string[]) =>
    projectIds,
  (portfolioProjects, projectIds): PortfolioProjectState[] => {
    // return portfolioProjects.find((p) => p.projectId === projectId);
    const sortedPortfolioProjects = [...portfolioProjects].sort((a, b) =>
      strcmp(a.projectId, b.projectId),
    );
    return intersectionSorted(
      sortedPortfolioProjects,
      projectIds,
      [(a, b) => strcmp(a.projectId, b)],
      (a, _b) => a,
    );
  },
)({
  keySelector: (_state, workspaceId, projectId) =>
    `${workspaceId}:${projectId}`,
  selectorCreator: createSelector,
});

export const getPortfolioProjectById = createCachedSelector(
  (state: WorkspaceDataState) => getPortfolioProjectState(state),
  (_state: WorkspaceDataState, baseId: BasePortfolioProjectId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    baseId.workspaceId,
  (portfolioProjects, id, workspaceId): PortfolioProjectState | undefined => {
    return portfolioProjectsSelectors.selectElementByT(portfolioProjects, {
      id,
      workspaceId,
    });
  },
)({
  keySelector: (_state, workspaceId, portfolioProjectId) =>
    `${workspaceId}:${portfolioProjectId}`,
  selectorCreator: createSelector,
});
