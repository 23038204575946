import { ExchangeRate } from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { ExchangeRate as ExchangeRateIn } from '~src/services/graphql/user/client/graphql';

export function parseExchangeRate(exchangeRate: ExchangeRateIn): ExchangeRate {
  return {
    id: exchangeRate.id,
    baseCurrencyId: exchangeRate.baseCurrencyId ?? '',
    endDate: new Date(exchangeRate.endDate),
    quoteCurrencyId: exchangeRate.quoteCurrencyId ?? '',
    startDate: new Date(exchangeRate.startDate),
    value: exchangeRate.value ?? 0,
  };
}
