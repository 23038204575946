import { Theme } from '@emotion/react';

const convertToRGB = (hex: string) => {
  if (hex.length != 6) {
    throw 'Only six-digit hex colors are allowed.';
  }

  const aRgbHex = hex.match(/.{1,2}/g);
  if (aRgbHex) {
    const aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
    ];
    return aRgb;
  }
  return [];
};
const subColorToHex = (subColor: number, diff?: number) => {
  if (diff != null) {
    subColor = Math.max(0, subColor + diff);
  }
  return subColor.toString(16);
};

export const getHoverColor = (
  color?: string,
  diff = -20,
  inverted?: boolean,
  _theme?: Theme,
) => {
  if (color) {
    const colorRGB = convertToRGB(color.slice(1));
    if (colorRGB.length === 3)
      return `#${subColorToHex(colorRGB[0], diff)}${subColorToHex(
        colorRGB[1],
        diff,
      )}${subColorToHex(colorRGB[2], diff)}`;
  } else if (inverted) {
    return 'white';
  } else {
    return 'black';
  }
};

// export const getHoverColor = (config: StyleConfig, _theme: Theme) => {
//   if (config.backgroundColor) {
//     const diff = -20;
//     const colorRGB = convertToRGB(config.backgroundColor.substr(1));
//     if (colorRGB.length === 3)
//       return `#${subColorToHex(colorRGB[0], diff)}${subColorToHex(
//         colorRGB[1],
//         diff
//       )}${subColorToHex(colorRGB[2], diff)}`;
//   } else if (config.inverted) {
//     return 'white';
//   } else {
//     return 'black';
//   }
// };
