import {
  StockTradeStateVisitor,
  StockTradeVisitable,
} from '~src/data/store/visitors/holding/types/stock/stock-trade-visitor';
import { StockTrade } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { Operation } from '@pladdenico/portfolio-api';

export function handleStockTrades(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  outputs: StockTrade[],
  subscriptions: Operation[],
) {
  const stockTradeVisitor = new StockTradeStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((stockHolding) => {
    const stockHoldingVisitable = new StockTradeVisitable(stockHolding);
    stockHoldingVisitable.accept(stockTradeVisitor);
  });
  stockTradeVisitor.post();
  return outputs;
}
