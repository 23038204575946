export type AdapterId = 'dnb' | 'vps' | 'nordnet';

export interface Adapter {
  id: AdapterId;
  name: string;
}
export const dnbAdapter: Adapter = {
  id: 'dnb',
  name: 'dnb',
};
export const vpsAdapter: Adapter = {
  id: 'vps',
  name: 'vps',
};
export const nordnetAdapter: Adapter = {
  id: 'nordnet',
  name: 'nordnet',
};
