import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';

export const getLastTransfer = (holding: HoldingState) => {
  const transfers = getTransfersByHoldingId(
    selectWorkspaceData(store.getState()),
    holding.id,
  );
  let lastTransfer: TransferState | undefined = undefined;
  if (transfers.length > 0) {
    lastTransfer = transfers.reduce((prev, cur) => {
      if (prev.date.getTime() < cur.date.getTime()) {
        return cur;
      }
      return prev;
    }, transfers[0]);
  }
  return lastTransfer;
};
