import React from 'react';
import { makeStyles } from 'tss-react/mui';
import * as uuid from 'uuid';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';
import { eventParser } from '~src/utils/form/event-parser';

import Autorenew from '@mui/icons-material/Autorenew';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';

export type QuoteInput =
  | graphqlFinanceTypes.CreateQuoteInputType
  | graphqlFinanceTypes.UpsertQuoteInputType
  | graphqlFinanceTypes.UpdateQuoteInputType;

interface Props<QI> {
  hasIdField: boolean;
  quoteInput: QI;
  setQuoteInput: (quoteInput: QI) => void;
}

export type IQI = Pick<
  graphqlFinanceTypes.UpsertQuoteInputType,
  | 'close'
  | 'open'
  | 'date'
  | 'high'
  | 'id'
  | 'low'
  | 'paperId'
  | 'value'
  | 'volume'
>;

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const QuoteFieldsComponent = React.memo(function <QI extends IQI>(
  props: Props<QI>,
) {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof QI) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.setQuoteInput({
        ...props.quoteInput,
        [name]: eventParser(event),
      });
    };

  const handleDateChange = (date: Date) => {
    props.setQuoteInput({
      ...props.quoteInput,
      date,
    });
  };

  const handleClickRegenerateId = () => {
    props.setQuoteInput({ ...props.quoteInput, id: uuid.v1() });
  };

  const handleMouseDownRegenerateId = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const idField = props.hasIdField ? (
    <FormControl
      fullWidth
      required
      className={classes.textField}
      margin="normal"
    >
      <InputLabel htmlFor="adornment-id">Id</InputLabel>
      <Input
        id="adornment-id"
        type={'text'}
        value={props.quoteInput.id}
        onChange={handleChange('id')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="id regeneration"
              onClick={handleClickRegenerateId}
              onMouseDown={handleMouseDownRegenerateId}
            >
              <Autorenew />
              {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  ) : undefined;

  return (
    <>
      {idField}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="close"
        value={props.quoteInput.close}
        label="Close"
        type="number"
        onChange={handleChange('close')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="open"
        value={props.quoteInput.open}
        label="Open"
        type="number"
        onChange={handleChange('open')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="high"
        value={props.quoteInput.high}
        label="High"
        type="number"
        onChange={handleChange('high')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="low"
        value={props.quoteInput.low}
        label="Low"
        type="number"
        onChange={handleChange('low')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="volume"
        value={props.quoteInput.volume}
        label="Volume"
        type="number"
        onChange={handleChange('volume')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="value"
        value={props.quoteInput.value}
        label="Value"
        type="number"
        onChange={handleChange('value')}
      />
      <EditDateField
        date={props.quoteInput.date}
        handleValue={handleDateChange}
        labelled={true}
      />
    </>
  );
});
