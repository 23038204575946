import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updateEvents } from '~src/data/store/modules/workspaces/projects/events/requests';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { getDiffRelationInput } from '~src/domain/workspace/components/project/event/form/diff-to-added-and-removed';
import { EventInput } from '~src/domain/workspace/components/project/event/form/event-input';
import { EventFormComponent } from '~src/domain/workspace/components/project/event/form/form.component';
import { AppDispatch } from '~src/store/store';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  event: EventState;
  open: boolean;
  handleClose: () => void;
}

export const EditEventComponent = React.memo((props: Props) => {
  const { workspace, project, event, open, handleClose } = props;

  const [eventInput, setEventInput] = React.useState<EventInput>({
    projectId: event.projectId,
    name: event.name,
    date: event.date,
    description: event.description,
    entityIds: event.entityIds,
    holdingIds: event.holdingIds,
    portfolioIds: event.portfolioIds,
    transactionIds: event.transactionIds,
  });

  const dispatch = useDispatch<AppDispatch>();

  const createEvent = React.useCallback(() => {
    const holdingIds = getDiffRelationInput(
      event.holdingIds,
      eventInput.holdingIds,
      (t) => t,
    );
    const entityIds = getDiffRelationInput(
      event.entityIds,
      eventInput.entityIds,
      (t) => t,
    );
    const portfolioIds = getDiffRelationInput(
      event.portfolioIds,
      eventInput.portfolioIds,
      (t) => t,
    );
    const transactionIds = getDiffRelationInput(
      event.transactionIds,
      eventInput.transactionIds,
      (t) => t,
    );

    const updatedEvent: graphqlWorkspaceOperations.UpsertEventInputType = {
      id: event.id,
      ...eventInput,
      holdingIds,
      entityIds,
      portfolioIds,
      transactionIds,
    };
    dispatch(updateEvents(workspace.tenantId, workspace.id, [updatedEvent]));

    handleClose();
    return Promise.resolve();
  }, [
    event.holdingIds,
    event.entityIds,
    event.portfolioIds,
    event.transactionIds,
    event.id,
    eventInput,
    dispatch,
    workspace.tenantId,
    workspace.id,
    handleClose,
  ]);

  const form = React.useMemo(() => {
    return (
      <EventFormComponent
        eventInput={eventInput}
        project={project}
        setEventInput={setEventInput}
        workspace={workspace}
      />
    );
  }, [eventInput, project, workspace]);

  return (
    <DialogComponent dialogProps={{ open, onClose: handleClose }}>
      <DialogContentComponent
        handleOnSave={createEvent}
        title="Create event"
        handleClose={handleClose}
      >
        {form}
      </DialogContentComponent>
    </DialogComponent>
  );
});
