import { isEqual, uniqueId } from 'lodash';
import React from 'react';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { usePositioning } from '~src/hooks/utils/use-positioning.hook';

import { usePersistDashboardConfig } from './use-persist-dashboard-config.hook';
import {
  DashboardElementsBaseType,
  PortfolioDashboardElementsType,
} from '@pladdenico/models';

export interface DashboardComponentType {
  id: string;
  t: PortfolioDashboardElementsType | DashboardElementsBaseType;
}

export type DashboardConfigState = {
  configId: number;
  components: Array<PortfolioDashboardElementsType | DashboardElementsBaseType>;
};

export function createDefaultDashboardComponents() {
  const dashboardComponents: DashboardComponentType[] = [];
  dashboardComponents.push({
    id: uniqueId(),
    t: 'header',
  });
  dashboardComponents.push({
    id: uniqueId(),
    t: 'summary',
  });
  return dashboardComponents;
}

interface DashboardProps {
  workspace: WorkspaceState;
  fund: PrivateEquityFundState;
  dashboardConfig: DashboardConfigState;
}

function asArray(config: DashboardConfigState): DashboardComponentType[] {
  return config.components
    ? config.components.map((component) => {
        return { id: uniqueId(), t: component };
      })
    : [];
}

export function useDashboardComponents(props: DashboardProps) {
  const { persist } = usePersistDashboardConfig();

  const updateCallback = React.useCallback(
    (_array: DashboardComponentType[]) => {
      persist(props.workspace, props.fund.id);
    },
    [persist, props.fund.id, props.workspace],
  );

  const { array, set, add, remove, move } = usePositioning(updateCallback, () =>
    asArray(props.dashboardConfig),
  );

  React.useEffect(() => {
    if (
      isEqual(
        latestPortfolioDashboardConfig.current.components,
        props.dashboardConfig.components,
      )
    ) {
      set(asArray(props.dashboardConfig));
    }
    // }
    // }, [props.portfolioDashboardConfig, array, set]);
  }, [props.dashboardConfig, set]);

  const latestPortfolioDashboardConfig = React.useRef(props.dashboardConfig);
  React.useEffect(() => {
    latestPortfolioDashboardConfig.current = props.dashboardConfig;
  }, [props.dashboardConfig]);

  return {
    dashboardComponents: array,
    add,
    remove,
    move,
  };
}
