import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import {
  BaseEventId,
  EventState,
} from '~src/data/store/state/workspace/project/event/event';

export const initialEventState = [];

const selectKeyId = (event: BaseEventId) => event.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseEventId, b: BaseEventId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseEventId, EventState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const eventsSlice = createBaseRecordSlice('events', adapter);
export const eventSelectors = adapter.selectors;

export const eventsReducer = eventsSlice.reducer;
export const eventsActions = eventsSlice.actions;
