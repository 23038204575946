import * as React from 'react';
import { OrganizationRoleGroup } from '~src/data/store/modules/organizations/roles/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Box, Paper, Typography } from '@mui/material';
import { PersonOrEntityComponent } from './person-or-entity.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  orgRoleGroup: OrganizationRoleGroup;
}

export const OrganizationRoleGroupComponent = React.memo((props: Props) => {
  const { orgRoleGroup } = props;

  const roles = React.useMemo(() => {
    const numberOfRoles = orgRoleGroup.roles.length;
    return orgRoleGroup.roles.map((role, idx) => (
      <Box key={idx}>
        {numberOfRoles > 1 && (
          <>
            <Typography variant="caption">
              {role.description} ({role.code})
            </Typography>
          </>
        )}

        {role.personOrEntity && (
          <PersonOrEntityComponent personOrEntity={role.personOrEntity} />
        )}
        {role.resigned && <Typography variant="body2">Resigned</Typography>}
      </Box>
    ));
  }, [orgRoleGroup.roles]);

  return (
    <Paper variant="outlined">
      <Box p={1}>
        <Typography variant="overline">
          {orgRoleGroup.type.description} ({orgRoleGroup.type.code})
        </Typography>
        {roles}
      </Box>
    </Paper>
  );
});
