export const GROUP = 'groups/GROUP';
export const GROUPS = 'groups/GROUPS';
export const DELETED_GROUP = 'groups/DELETED_GROUP';

export const GET_GROUPS_REQUEST = 'groups/GET_GROUPS_REQUEST';
export const GET_GROUPS_FAILURE = 'groups/GET_GROUPS_FAILURE';

export const GROUP_FETCH_STATUS = 'groups/GROUP_FETCH_STATUS';
export const GROUPS_FETCH_STATUS = 'groups/GROUPS_FETCH_STATUS';
export const GROUPS_CREATE_STATUS = 'groups/GROUPS_CREATE_STATUS';
export const GROUPS_DELETE_STATUS = 'groups/GROUPS_DELETE_STATUS';
