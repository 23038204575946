import * as uuid from 'uuid';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
// import { HoldingConfigListener } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-input-data';
import { getHoldingTypes } from '~src/utils/finance/transfer-to-holding-types';

import { TransferType } from '@pladdenico/models';

export const getHoldingConfigBuy = (
  holdingId?: string,
  // listeners?: HoldingConfigListener[]
): HoldingConfig => {
  return {
    id: uuid.v1(),
    transferType: TransferType.Buy,
    component: {
      name: 'Bought holding',
      deleteIcon: false,
      useValuation: true,
      // editAll: false,

      possibleHoldingTypes: getHoldingTypes(TransferType.Buy),
      possibleTransferTypes: [TransferType.Buy],
    },
    holdingId,
    // listeners,
  };
};
