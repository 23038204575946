import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { getConfigById } from '~src/data/store/modules/config/selectors';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { getPortfoliosByWorkspaceId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Box, Typography } from '@mui/material';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { Config } from '~src/utils/interfaces/config';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  workspace: WorkspaceState;
  portfolio: PortfolioState;
  config: Config;
}

export const DashboardHeaderComponent = React.memo((props: Props) => {
  const { config } = props;
  const iPortfolios = useSelector((state: RootState) =>
    getPortfoliosByWorkspaceId(selectWorkspaceData(state), props.workspace.id),
  );
  const portfolioHierarchyName = useMemo(() => {
    let portfolioHierarchyName = '';
    let portfolio = props.portfolio;
    const portfolios = iPortfolios;
    if (portfolio && portfolios) {
      portfolioHierarchyName = portfolio.name ? portfolio.name : 'unknown';
      while (portfolio.parentPortfolioId) {
        const p = portfolios.find((p) => {
          if (!portfolio) {
            return false;
          }
          return p.id === portfolio.parentPortfolioId;
        });
        if (p) {
          portfolioHierarchyName = `${p.name} - ${portfolioHierarchyName}`;
          portfolio = p;
        } else {
          break;
        }
      }
    }
    return portfolioHierarchyName;
  }, [props.portfolio, iPortfolios]);
  const period = config.period;
  return (
    <Box p={1}>
      {/* <Box
      sx={{
        p: 1,
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    > */}
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        Portfolio - {portfolioHierarchyName}
      </Typography>
      <Grid2 container sx={{ pt: 1 }}>
        <Grid2 xs={6}>
          <Typography variant="subtitle2">Valuation date</Typography>
        </Grid2>
        <Grid2 container xs={6} justifyContent="flex-start">
          <Typography variant="body2">
            {config.date.format('DD.MM.YY')}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 container>
        <Grid2 xs={6}>
          <Typography variant="subtitle2">Period</Typography>
        </Grid2>
        <Grid2 container xs={6} justifyContent="flex-start">
          <Typography variant="body2">
            {period.start.format('DD.MM.YY')} - {period.end.format('DD.MM.YY')}
          </Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
});
