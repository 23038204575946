import React from 'react';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ForecastElementBase } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-element-state';
import { useScenarioHoldingForecastExpressionElementFieldsComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/use-expression-fields.component';

// import moment from 'moment';
import { Grid } from '@mui/material';

interface Props {
  forecastElement: ExpressionElementState;
  setForecastElement: (element: ForecastElementBase) => void;
}

export const EditHoldingForecastExpressionElementComponent = React.memo(
  (props: Props) => {
    const { forecastElement, setForecastElement } = props;
    const { expressionsElement, expressionTypeElement } =
      useScenarioHoldingForecastExpressionElementFieldsComponent(
        forecastElement,
        setForecastElement,
      );
    return (
      <Grid container mr={1}>
        <Grid item xs={6}>
          {expressionTypeElement}
        </Grid>
        <Grid item xs={12}>
          {expressionsElement}
        </Grid>
      </Grid>
    );
  },
);
