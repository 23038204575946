import { createSelector } from 'reselect';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { getBondPositionById } from '~src/data/store/selectors/holding/holding-types/bond/bond-position/selectors';
import { getBondTradeByTransferId } from '~src/data/store/selectors/holding/holding-types/bond/bond-trade/selectors';
import {
  PositionTyped,
  TradeTyped,
  BondTransferTyped,
} from '~src/domain/workspace/components/project/transfer/algorithm/transfer-detailed';

interface Props {
  projectId: string;
  transfer: TransferState;
}

export const getBondHoldingTransfersDetailed = createSelector(
  (state: WorkspaceDataState, _props: Props) => state,
  (_state: WorkspaceDataState, props: Props) => props.projectId,
  (_state: WorkspaceDataState, props: Props) => props.transfer,
  (workspaceData, _projectId, transfer): BondTransferTyped | undefined => {
    const bondHolding = getBondHoldingByHoldingId(
      workspaceData,
      transfer.holdingId,
    );
    if (bondHolding != null) {
      const trade = getBondTradeByTransferId(workspaceData, {
        bondHoldingId: bondHolding.id,
        transferId: transfer.id,
      });
      if (trade) {
        const position = getBondPositionById(workspaceData, {
          bondHoldingId: bondHolding.id,
          id: trade.positionId,
        });
        const tradeTyped: TradeTyped = {
          id: trade.id,
          price: trade.sharePrice,
          shares: trade.shares,
        };
        const positionTyped: PositionTyped = {
          id: position?.id ?? '',
          shares: position?.shares ?? 0,
          date: position?.date ?? new Date(),
        };
        const transferTyped: BondTransferTyped = {
          bondHoldingId: bondHolding.id,
          trade: tradeTyped,
          position: positionTyped,
        };
        return transferTyped;
      }
    }
  },
);
