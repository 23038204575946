import { EvaluatorState } from '~src/data/store/state/workspace/project/scenario/forecast/evaluator-state';
import { ForecastType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';

import { ForecastElement } from './forecast-element';
// import { Value } from './value';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { NodeType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/node-type';
import { restoreFlowFromJson } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-save-restore';
import { FlowEvaluator } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-evaluator';
import { VariableState } from '~src/data/store/reducers/workspace/projects/scenario/variables/reducer';

export class FlowElement<Value> implements ForecastElement<Value> {
  readonly type: ForecastType;

  private _flowEvaluator: FlowEvaluator | undefined;

  constructor(
    private _id: string,
    private _name: string,
    private _flowString: string | null,
    private _variables: VariableState[] | undefined,
    private _getVariableValue?: (
      objectId: string,
      idx: number,
    ) => any | undefined,
  ) {
    this.type = 'manual';

    const flow = this._initFlow();

    if (flow) {
      this._flowEvaluator = new FlowEvaluator(flow.edges, flow.nodes);
      this._flowEvaluator.prepare();
    }
  }

  private _initFlow() {
    if (this._flowString) {
      return restoreFlowFromJson<NodeData, NodeType>(this._flowString);
    }
  }

  public getId() {
    return this._id;
  }

  public getName() {
    return this._name;
  }

  public evaluate(state: EvaluatorState<Value>): Value | undefined {
    console.log('flowelement:evaluate', this._flowString);
    if (this._flowEvaluator) {
      const value = this._flowEvaluator.evaluate(
        state.values,
        state.date,
        state.idx,
        this._getVariableValue,
        // this._variables
      );
      return {
        ...value,
        date: state.date,
      };
    }
  }
}
