import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { useFetchWorkspaceData } from '~src/data/store/modules/workspaces/data/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  selectTenantData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getTenantById } from '~src/data/store/selectors/tenant/tenants/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import { MainComponent } from '~src/domain/workspace/app/main.component';
import { AssetRoot } from '~src/domain/workspace/components/asset/root';
import { EntityRoot } from '~src/domain/workspace/components/entity/entity/root';
import { PaperRouterWrapper } from '~src/domain/workspace/components/finance/papers/paper/root';
import { FinanceTabWrapper } from '~src/domain/workspace/components/finance/tab-wrapper';
import { FinanceTabs } from '~src/domain/workspace/components/finance/tab/finance-tabs.component';
import { GraphQLComponent } from '~src/domain/workspace/components/graph-ql/graph-ql.component';
import { PersonRouterWrapper } from '~src/domain/workspace/components/people/root';
import { WorkspaceSettingsComponent } from '~src/domain/workspace/components/workspace/setup/workspace-settings.component';
import { workspaceFinanceTabPath } from '~src/navigation/paths/workspace/finance/paths';

import { WorkspaceProjectRouterWrapper } from '../components/project/root';
import { WorkspaceUsersComponent } from '~src/domain/workspace/components/user/workspace-users.component';
import { ProjectsComponent } from '~src/domain/workspace/components/project/projects.component';
import { workspaceProjectsRootPath } from '~src/navigation/paths/workspace/project/paths';
import { AssetsComponent } from '~src/domain/workspace/components/asset/assets.component';
import { EntitiesComponent } from '~src/domain/workspace/components/entity/entity/entities.component';
import { PeopleComponent } from '~src/domain/workspace/components/people/people.component';
import { DataComponent } from '~src/domain/workspace/components/data/data-component';
import { WorkspaceFilesComponent } from '~src/domain/workspace/components/file/workspace-files.component';

interface Props {
  workspace: WorkspaceState;
}

const RedirectToFinanceBase = () => {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  if (tenantId != null && workspaceId != null) {
    return (
      <Navigate
        to={workspaceFinanceTabPath(tenantId, workspaceId, FinanceTabs.Papers)}
        replace
      />
    );
  }
  return <ProgressComponent />;
};

export const WorkspaceRouterWrapperWithWorkspace = React.memo(
  (props: Props) => {
    useFetchWorkspaceData(props.workspace.tenantId, props.workspace.id);

    return (
      <Routes>
        <Route
          path="settings/*"
          element={<WorkspaceSettingsComponent workspace={props.workspace} />}
        />
        <Route
          path="files/*"
          element={<WorkspaceFilesComponent workspace={props.workspace} />}
        />
        <Route
          path="projects/:projectId/*"
          element={
            <WorkspaceProjectRouterWrapper workspace={props.workspace} />
          }
        />
        <Route
          path="projects/*"
          element={<ProjectsComponent workspace={props.workspace} />}
        />
        <Route
          path="assets/*"
          element={<AssetsComponent workspace={props.workspace} />}
        />
        <Route
          path="assets/:assetId/*"
          element={<AssetRoot workspace={props.workspace} />}
        />

        <Route
          path="entities/*"
          element={<EntitiesComponent workspace={props.workspace} />}
        />
        <Route
          path="entities/:entityId/*"
          element={<EntityRoot workspace={props.workspace} />}
        />
        <Route
          path="people/*"
          element={<PeopleComponent workspace={props.workspace} />}
        />
        <Route
          path="people/:personId/*"
          element={<PersonRouterWrapper workspace={props.workspace} />}
        />

        <Route
          path="data/*"
          element={<DataComponent workspace={props.workspace} />}
        />
        <Route
          path="members/*"
          element={
            <WorkspaceUsersComponent
              tenantId={props.workspace.tenantId}
              workspace={props.workspace}
            />
          }
        />

        <Route
          path="finance/papers/:paperId"
          element={<PaperRouterWrapper />}
        />
        <Route path="finance/:tabId" element={<FinanceTabWrapper />} />
        <Route path="finance/*" element={<RedirectToFinanceBase />} />
        <Route path="graph-ql/*" element={<GraphQLComponent />} />

        <Route
          path="*"
          element={
            <Navigate
              to={workspaceProjectsRootPath(
                props.workspace.tenantId,
                props.workspace.id,
              )}
              replace
            />
          }
        />
      </Routes>
    );
  },
);

export const WorkspaceRouterWrapper = React.memo(() => {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();

  const tenant = useSelector((state: RootState) =>
    getTenantById(selectTenantData(state), tenantId),
  );
  const workspace = useSelector((state: RootState) => {
    if (workspaceId != null && tenantId != null) {
      return getWorkspaceById(selectWorkspaceData(state), {
        id: workspaceId,
        tenantId,
      });
    }
  });
  if (tenant == null || workspace == null) {
    return <ProgressComponent />;
  }
  return (
    <MainComponent tenant={tenant} workspace={workspace}>
      <WorkspaceRouterWrapperWithWorkspace workspace={workspace} />
    </MainComponent>
  );
});
