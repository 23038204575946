import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { Entity } from '~src/services/graphql/workspace/client/graphql';

export function parseEntity(
  tenantId: string,
  workspaceId: string,
  entity: Entity,
): EntityState {
  return {
    tenantId,
    workspaceId,
    id: entity.id,
    description: entity.description,
    name: entity.name,
    sector: entity.sector,
    website: entity.website,
  };
}
