import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDashboardMedias,
  upsertDashboardMedias,
} from '~src/data/store/modules/common/dashboard/media/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardMediumById } from '~src/data/store/selectors/common/dashboard/media/selectors';
import { selectViewData } from '~src/data/store/selectors/selectors';
import { Config } from '~src/utils/interfaces/config';

import { Box, Typography } from '@mui/material';
import { WorkspaceImageComponent } from '~src/domain/workspace/components/file/image/image.component';
import { AppDispatch } from '~src/store/store';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FileState } from '~src/data/store/reducers/file/files/reducer';

interface Props {
  workspace: WorkspaceState;
  config: Config;
  mediaId: string | undefined;
  dashboardElement: DashboardElementState;
}

export const DashboardImageComponent = React.memo((props: Props) => {
  const { workspace, mediaId, config, dashboardElement } = props;

  React.useEffect(() => {
    if (mediaId) {
      fetchDashboardMedias(workspace.tenantId, workspace.id, [mediaId]);
    }
  });

  const media = useSelector((state: RootState) => {
    if (mediaId) {
      return getDashboardMediumById(selectViewData(state), mediaId);
    }
  });

  // const editModal = useVisible();
  // const editComponent = React.useMemo(() => {
  //   if (file) {
  //     return (
  //       <EditFileComponent
  //         handleClose={editModal.close}
  //         isShowing={editModal.isShowing}
  //         file={file}
  //         tenantId={workspace.tenantId}
  //         workspace={workspace}
  //       />
  //     );
  //   }
  // }, [editModal.close, editModal.isShowing, file, workspace]);
  const dispatch = useDispatch<AppDispatch>();

  const updateFileCallback = React.useCallback(
    (file: FileState) => {
      if (media) {
        return dispatch(
          upsertDashboardMedias(workspace.tenantId, workspace.id, [
            {
              dashboardElementId: dashboardElement.id,
              fileId: file.id,
              id: media.id,
            },
          ]),
        ).then(() => Promise.resolve());
      }
      return Promise.resolve();
    },
    [dashboardElement.id, dispatch, media, workspace.id, workspace.tenantId],
  );

  if (media && media.fileId) {
    return (
      <>
        <WorkspaceImageComponent
          fileId={media.fileId}
          config={config}
          workspace={workspace}
          updateFileCallback={updateFileCallback}
        />
        {/* {editComponent} */}
      </>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>No media set</Typography>
    </Box>
  );
});
