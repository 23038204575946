import React from 'react';
import { DataInput, OptionType } from '~src/components/xlxs/data-input';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { name } from '~src/services/xlxs/handlers/adapters/nordnet/transaction';
import { DataImporter, useImport } from '~src/services/xlsx/hooks/use-import';
import { RunStatus } from '~src/services/xlsx/hooks/use-update-status';

import { Alert, CircularProgress, Paper } from '@mui/material';
import { sheetName } from '~src/services/xlsx/handlers/adapters/nordnet/sheet-name';
import { handlerType as nordnetTransactionHandlerType } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/transaction-import-handler';

interface Props {
  workspace: WorkspaceState;
  dataImporter: DataImporter;
}

export const NordnetImportComponent = React.memo((props: Props) => {
  const {
    importer,
    importingData,
    importingStatus,
    showImportingStatus,
    setShowImportingStatus,
  } = useImport(props.workspace, props.dataImporter);

  const options = new Map<string, OptionType[]>([
    [
      sheetName,
      [{ name: nordnetTransactionHandlerType, option: { range: 0 } }],
    ],
  ]);

  let importStatusElement;
  if (showImportingStatus) {
    const importStatusElements = importingStatus.map((importStatus, index) => {
      return <p key={index}>{importStatus.status}</p>;
    });

    importStatusElement = (
      <Alert
        severity={
          importingStatus.some((status) => status.error) ? 'error' : 'success'
        }
        onClose={() => setShowImportingStatus(false)}
      >
        {importStatusElements}
        {importingData === RunStatus.running ? <CircularProgress /> : undefined}
      </Alert>
    );
  }

  return (
    <div>
      <DataInput handleData={importer} options={options} />
      <Paper sx={{ mt: 1, mb: 1 }}>{importStatusElement}</Paper>
    </div>
  );
});
