import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { useEditDialog } from '~src/components/fields/workspace/entity/use-edit-dialog';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getEntitiesByWorkspaceId,
  getEntityById,
} from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { FieldError } from '~src/utils/form/fields/field-error';

interface Props {
  handleValue: (t: EntityState) => void;
  handlePersist?: () => void;
  entityId: string | undefined;
  label?: string;
  workspace: WorkspaceState;
  error?: FieldError;
}

export const EditEntity = (props: Props) => {
  const entities = useSelector((state: RootState) => {
    return getEntitiesByWorkspaceId(
      selectWorkspaceData(state),
      props.workspace.id,
    );
  });
  const entity = useSelector((state: RootState) => {
    return getEntityById(selectWorkspaceData(state), {
      id: props.entityId,
      workspaceId: props.workspace.id,
    });
  });

  const getOptionLabel = React.useCallback(
    (t: EntityState) => {
      const entity = entities.find((entity) => entity.id === t.id);
      return entity?.name ? entity.name : '';
    },
    [entities],
  );
  const { handleValue, error } = props;

  const { dialogElement, setOpen, setEntityInput } = useEditDialog({
    handleValue,
    workspace: props.workspace,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setEntityInput({
        id: uuid.v1(),
        name: value,
        description: '',
        sector: '',
        website: '',
        regionAllocationRelations: [],
      });
    },
    [setEntityInput],
  );

  return (
    <EditAutocomplete
      multiple={false}
      addOption={{ initDialog, setOpen, dialogElement }}
      // dialogElement={dialogElement}
      getOptionLabel={getOptionLabel}
      label={props.label}
      handleValue={handleValue}
      // initDialog={initDialog}
      options={entities}
      value={entity}
      // setOpen={setOpen}
      handlePersist={props.handlePersist}
      error={error}
    />
  );
};
