import React from 'react';
import { useSelector } from 'react-redux';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useAllocationData } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-allocation-data';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  holdings: HoldingState[];
  valuationDate: moment.Moment;
  baseCurrency: CurrencyState;
  format: {
    color: string;
    backgroundColor: string;
  };
  useMarketValue: boolean;
  showFilteredOut: boolean;
}

export const useHoldingsWeights = (props: Props) => {
  const {
    baseCurrency,
    useMarketValue,
    holdings,
    portfolioProject,
    valuationDate,
    workspace,
    showFilteredOut,
  } = props;
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );
  const filterKey = 'category';
  const { data } = useAllocationData(
    workspace.id,
    portfolioProject.projectId,
    filterKey,
    valuationDate,
    holdings,
    baseCurrency,
    useMarketValue,
    filter,
    showFilteredOut,
  );

  const totalValue = React.useMemo(
    () =>
      data.reduce((prev, curr) => {
        return prev + curr.value;
      }, 0),
    [data],
  );

  const weights = React.useMemo(() => {
    return data.map((d) => {
      return {
        id: d.name,
        weight: d.value / totalValue,
      };
    });
  }, [data, totalValue]);

  return weights;
};
