import { Owner } from '@pladdenico/models';
import { OwnerAction } from '~src/data/store/actions/owner';

export const OWNER = 'owners/OWNER';
export const OWNERS = 'owners/OWNERS';
export const DELETED_OWNER = 'owners/DELETED_OWNER';

export const GET_OWNERS_REQUEST = 'owners/GET_OWNERS_REQUEST';
export const GET_OWNERS_FAILURE = 'owners/GET_OWNERS_FAILURE';

export const GET_OWNER_REQUEST = 'owners/GET_OWNER_REQUEST';
export const GET_OWNER_SUCCESS = 'owners/GET_OWNER_SUCCESS';
export const GET_OWNER_FAILURE = 'owners/GET_OWNER_FAILURE';

export const OWNER_SELECTED = 'owners/OWNER_SELECTED';
export const OWNER_UNSELECTED = 'owners/OWNER_UNSELECTED';
export const OWNERS_SELECTED = 'owners/OWNERS_SELECTED';
export const OWNERS_UNSELECTED = 'owners/OWNERS_UNSELECTED';

// export function selectOwnerAction(owner: Owner): OwnerAction {
//   return {
//     type: OWNER_SELECTED,
//     payload: {
//       ownerId: owner.id,
//       owner,
//     },
//   };
// }

// export function unselectOwnerAction(owner: Owner): OwnerAction {
//   return {
//     type: OWNER_UNSELECTED,
//     payload: {
//       ownerId: owner.id,
//     },
//   };
// }

// export function selectOwnersAction(owners: Owner[]): OwnerAction {
//   return {
//     type: OWNERS_SELECTED,
//     payload: {
//       owners,
//     },
//   };
// }

// export function unselectOwnersAction(): OwnerAction {
//   return {
//     type: OWNERS_UNSELECTED,
//     payload: {
//     },
//   };
// }

// export function ownerAction(owner: Owner): OwnerAction {
//   return {
//     type: OWNER,
//     payload: {
//       ownerId: owner.id,
//       owner,
//     },
//   };
// }

// export function ownersAction(owners: Owner[]): OwnerAction {
//   return {
//     type: OWNERS,
//     payload: {
//       owners,
//     },
//   };
// }

// export function deletedOwnerAction(ownerId: number): OwnerAction {
//   return {
//     type: DELETED_OWNER,
//     payload: {ownerId},
//   };
// }

export function ownersRequestAction(): OwnerAction {
  return {
    type: GET_OWNERS_REQUEST,
    payload: undefined,
  };
}

export function ownersFailureAction(message: any): OwnerAction {
  return {
    type: GET_OWNERS_FAILURE,
    payload: {
      message,
    },
  };
}

export function ownerRequestAction(ownerId: number): OwnerAction {
  return {
    type: GET_OWNER_REQUEST,
    payload: { ownerId },
  };
}

export function ownerSuccessAction(owner: Owner): OwnerAction {
  return {
    type: GET_OWNER_SUCCESS,
    payload: {
      owner,
    },
  };
}

export function ownerFailureAction(ownerId: number, message: any): OwnerAction {
  return {
    type: GET_OWNER_FAILURE,
    payload: {
      ownerId,
      message,
    },
  };
}
