import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { AppDispatch } from '~src/store/store';
import { AllocationState } from '~src/data/store/reducers/portfolio/allocations/reducer';
import { createAllocations } from '~src/data/store/modules/portfolios/allocations/requests';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export function useAllocationCreateAction(
  workspace: WorkspaceState,
  portfolioId: string,
) {
  const [allocationInput, setAllocationInput] =
    React.useState<graphqlWorkspaceTypes.CreateAllocationInputType>(() => {
      return {
        id: uuid.v1(),
        portfolioId,
        regionId: '',
        currencyId: '',
        min: 0,
        max: 0,
        assetClass: '',
        assetType: '',
        category: '',
      };
    });
  const dispatch = useDispatch<AppDispatch>();

  const createAllocation =
    (callback: (allocations: AllocationState[]) => void) => (): void => {
      dispatch(
        createAllocations(workspace.tenantId, workspace.id, [allocationInput]),
      )
        .then((allocations) => {
          return callback(allocations);
        })
        .catch((err) => {
          console.info('FAILED', allocationInput, JSON.stringify(err));
          throw err;
        });
    };
  return { allocationInput, setAllocationInput, createAllocation };
}
