export const ExpressionTypes = {
  custom: 'custom',
  StockReturn: 'stockReturn',
  StockPrice: 'stockPrice',
  StockTransfer: 'stockTransfer',
  StockValuation: 'stockValuation',
  Linear: 'linear',
} as const;

export type ExpressionType =
  (typeof ExpressionTypes)[keyof typeof ExpressionTypes];
export function typeToString(type: ExpressionType) {
  if (type === ExpressionTypes.custom) {
    return 'Custom';
  } else if (type === ExpressionTypes.StockReturn) {
    return 'Stock return';
  } else if (type === ExpressionTypes.Linear) {
    return 'Linear';
  } else if (type === ExpressionTypes.StockPrice) {
    return 'StockPrice';
  } else if (type === ExpressionTypes.StockTransfer) {
    return 'StockTransfer';
  } else if (type === ExpressionTypes.StockValuation) {
    return 'StockValuation';
  }
  return 'undefined';
}
