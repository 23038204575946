import {
  PortfolioProjectStateVisitor,
  PortfolioProjectVisitable,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-project-visitor';
import { Operation } from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function handlePortfolioProjects(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: graphqlWorkspaceTypes.PortfolioProject[],
  subscriptions: Operation[],
) {
  const portfolioVisitor = new PortfolioProjectStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((portfolioProject) => {
    const portfolioVisitable = new PortfolioProjectVisitable(
      workspaceId,
      portfolioProject,
    );
    portfolioVisitable.accept(portfolioVisitor);
  });
  return portfolioVisitor.post();
}
