import 'reactflow/dist/style.css';
import '../index.css';

import React from 'react';
import { Edge, Node } from 'reactflow';
import {
  BaseNodeData,
  InitialNodeData,
  InputNodeData,
  OutputNodeData,
  VariableNodeData,
} from '../node-data';
import { Divider } from '@mui/material';
import { VariableNodeConfig } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/nodes/variable-node-config';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { OnNodeDataChanges } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-store';
import { InputNodeConfig } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/nodes/input-node-config';
import { NodeType } from '../nodes/node-type';
import { OutputNodeConfig } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/nodes/output-node-config';
import { InitialNodeConfig } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/sidebar/nodes/initial-node-config';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';

interface Props {
  scenario: Scenario;
  budget: Budget;
  nodes: Node<BaseNodeData, NodeType>[];
  edges: Edge[];
  onNodesDataChange: OnNodeDataChanges;
  currentNodeId: string | null;
}

export const NodeConfig = (props: Props) => {
  const { scenario, budget, currentNodeId, nodes, edges, onNodesDataChange } =
    props;

  const node = React.useMemo(() => {
    return nodes.find((node) => node.id === currentNodeId);
  }, [currentNodeId, nodes]);

  if (node == null) {
    return <></>;
  }

  if (node.type === 'variable') {
    const variableNode = node as Node<VariableNodeData>;
    return (
      <VariableNodeConfig
        node={variableNode}
        edges={edges}
        scenario={scenario}
        onNodesDataChange={onNodesDataChange}
      />
    );
  } else if (node.type === 'inputType') {
    const inputNode = node as Node<InputNodeData>;
    return (
      <InputNodeConfig
        budget={budget}
        node={inputNode}
        scenario={scenario}
        onNodesDataChange={onNodesDataChange}
      />
    );
  } else if (node.type === 'initial') {
    const inputNode = node as Node<InitialNodeData>;
    return (
      <InitialNodeConfig
        node={inputNode}
        scenario={scenario}
        onNodesDataChange={onNodesDataChange}
      />
    );
  } else if (node.type === 'output') {
    const outputNode = node as Node<OutputNodeData>;
    return (
      <OutputNodeConfig
        node={outputNode}
        scenario={scenario}
        onNodesDataChange={onNodesDataChange}
      />
    );
  }
  const nodeConfigElement = (
    <div className="updatenode__controls">
      <label>label</label>
      <input
        value={node.data.label}
        onChange={(evt) => {
          const data = {
            ...node.data,
            label: evt.target.value,
          };
          onNodesDataChange([
            {
              change: {
                data,
                id: node.id,
              },
            },
          ]);
        }}
      />
      <Divider />
    </div>
  );
  return nodeConfigElement;
};
