import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  DashboardMediaState,
  selectors,
} from '~src/data/store/reducers/common/dashboard/media/reducer';
import { ViewDataState } from '~src/data/store/reducers/reducers';

const getDashboardMediaState = (state: ViewDataState) =>
  state.view.dashboard.media;

export const getDashboardMedias = (state: ViewDataState) =>
  getDashboardMediaState(state);

export const getDashboardMediumById = createCachedSelector(
  (state: ViewDataState, _id: string | undefined) =>
    getDashboardMediaState(state),
  (_state: ViewDataState, id: string | undefined) => id,
  (dashboardMedia, id): DashboardMediaState | undefined => {
    if (id == null) {
      return undefined;
    }
    return selectors.selectElement(dashboardMedia, { id });
  },
)({
  keySelector: (_state, id) => `${id}`,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getDashboardMediaByIds = createCachedSelector(
  (state: ViewDataState, _ids: string[] | undefined) =>
    getDashboardMediaState(state),
  (_state: ViewDataState, ids: string[] | undefined) => ids,
  (dashboardMedia, ids): DashboardMediaState[] => {
    if (ids == null) {
      return [];
    }
    return selectors.selectElements(
      dashboardMedia,
      ids.map((id) => {
        return { id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => `${ids ? JSON.stringify(ids) : undefined}`,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
