import { strcmp } from '@pladdenico/common';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import {
  ScenarioValueType,
  ScenarioVariable,
} from '~src/domain/workspace/components/project/scenario/models/variable';

export interface BaseVariableId {
  id: string;
  scenarioId: string;
}

export type VariableState = ScenarioVariable<ScenarioValueType> & {
  scenarioId: string;
};

export const initialVariablesState = [];

const selectKeyId = (variable: BaseVariableId) => variable.scenarioId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseVariableId, b: BaseVariableId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseVariableId, VariableState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const variablesSlice = createBaseRecordSlice(
  'scenario:variables',
  adapter,
);
export const variablesSelectors = adapter.selectors;

export const variablesReducer = variablesSlice.reducer;
export const variablesActions = variablesSlice.actions;
