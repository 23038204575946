import { AllocationState } from '~src/data/store/reducers/portfolio/allocations/reducer';
import { Allocation } from '~src/services/graphql/workspace/client/graphql';

export function parseAllocation(
  portfolioId: string,
  allocation: Allocation,
): AllocationState {
  return {
    id: allocation.id,
    portfolioId,
    currencyId: allocation.currency?.id,
    regionId: allocation.region?.id,
    min: allocation.min,
    max: allocation.max,
    assetClass: allocation.assetClass,
    assetType: allocation.assetType,
    category: allocation.category,
    sector: allocation.sector,
  };
}
