import * as React from 'react';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingConfigComponent } from '~src/domain/workspace/components/project/holding/config/holding-config.component';
import { useDashboard } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/menu/use-dashboard';

import { Drawer, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  project: ProjectState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  configContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    // zIndex: theme.zIndex.drawer,
  },

  configButton: {
    // position: 'absolute',
    // marginTop: -46,
    // right: theme.spacing(1),
    // zIndex: theme.zIndex.drawer,
  },

  addElementButton: {
    // position: 'absolute',
    // marginRight: theme.spacing(1),
    right: theme.spacing(1),
  },

  root: {
    // marginTop: theme.spacing(2),
    // paddingTop: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
  },
}));

interface PropsWithConfig extends Props {
  holdingConfig: HoldingConfigState;
  dashboardConfig: HoldingDashboardConfigState;
  dashboardElements: DashboardElementState[];
}

export const HoldingWithConfigComponent = React.memo(
  (props: PropsWithConfig) => {
    const {
      workspace,
      project,
      holding,
      holdingConfig,
      dashboardConfig,
      // portfolioProject,
      // portfolioDashboardConfig,
      // portfolioConfig,
      // portfolio,
      dashboardElements,
    } = props;
    const { classes } = useStyles();

    const { menu, components, configOpen, toggleConfig } = useDashboard(
      workspace,
      project,
      holding,
      holdingConfig,
      dashboardConfig,
      dashboardElements,
    );

    return (
      <>
        {menu}
        <div className={classes.root}>
          {components}
          <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
            <HoldingConfigComponent
              workspace={workspace}
              project={project}
              holding={holding}
              holdingConfig={holdingConfig}
              dashboardConfig={dashboardConfig}
            />
          </Drawer>
        </div>
      </>
    );
  },
);
