import { intersectionSorted, RecordState, strcmp } from '@pladdenico/common';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  BasePrivateEquityFundProjectId,
  PrivateEquityFundProjectState,
  privateEquityFundProjectsSelectors as privateEquityFundProjectsSelectors,
} from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';

const getPrivateEquityFundProjectState = (state: WorkspaceDataState) =>
  state.workspace.project.privateEquityFundProject.privateEquityFundProjects;

export const getPrivateEquityFundProjectsByWorkspaceId = createCachedSelector(
  (
    state: RecordState<PrivateEquityFundProjectState, string>[],
    workspaceId: string,
  ) =>
    privateEquityFundProjectsSelectors.selectElementsByKey(state, workspaceId),
  (
    privateEquityFundProjects: PrivateEquityFundProjectState[] | undefined,
  ): PrivateEquityFundProjectState[] => {
    return privateEquityFundProjects ? privateEquityFundProjects : [];
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

export const getPrivateEquityFundProjects = createSelector(
  (state: WorkspaceDataState) => getPrivateEquityFundProjectState(state),
  (privateEquityFundProjects) => {
    return privateEquityFundProjectsSelectors.selectAllElements(
      privateEquityFundProjects,
    );
  },
);

interface ProjectBaseId {
  workspaceId: string;
  projectId: string;
}

export const getPrivateEquityFundProjectByProjectId = createCachedSelector(
  (state: WorkspaceDataState, baseId: ProjectBaseId) =>
    getPrivateEquityFundProjectsByWorkspaceId(
      getPrivateEquityFundProjectState(state),
      baseId.workspaceId,
    ),
  (_state: WorkspaceDataState, baseId: ProjectBaseId) => baseId.projectId,
  (
    privateEquityFundProjects,
    projectId,
  ): PrivateEquityFundProjectState | undefined => {
    return privateEquityFundProjects.find((p) => p.projectId === projectId);
  },
)({
  keySelector: (_state, workspaceId, projectId) =>
    `${workspaceId}:${projectId}`,
  selectorCreator: createSelector,
});

export const getPrivateEquityFundProjectsByProjectIds = createCachedSelector(
  (state: WorkspaceDataState, workspaceId: string, _projectIds: string[]) =>
    getPrivateEquityFundProjectsByWorkspaceId(
      getPrivateEquityFundProjectState(state),
      workspaceId,
    ),
  (_state: WorkspaceDataState, _workspaceId: string, projectIds: string[]) =>
    projectIds,
  (privateEquityFundProjects, projectIds): PrivateEquityFundProjectState[] => {
    // return privateEquityFundProjects.find((p) => p.projectId === projectId);
    const sortedPrivateEquityFundProjects = [...privateEquityFundProjects].sort(
      (a, b) => strcmp(a.projectId, b.projectId),
    );
    return intersectionSorted(
      sortedPrivateEquityFundProjects,
      projectIds,
      [(a, b) => strcmp(a.projectId, b)],
      (a, _b) => a,
    );
  },
)({
  keySelector: (_state, workspaceId, projectId) =>
    `${workspaceId}:${projectId}`,
  selectorCreator: createSelector,
});

export const getPrivateEquityFundProjectById = createCachedSelector(
  (state: WorkspaceDataState) => getPrivateEquityFundProjectState(state),
  (_state: WorkspaceDataState, baseId: BasePrivateEquityFundProjectId) =>
    baseId.id,
  (_state: WorkspaceDataState, baseId: BasePrivateEquityFundProjectId) =>
    baseId.workspaceId,
  (
    privateEquityFundProjects,
    id,
    workspaceId,
  ): PrivateEquityFundProjectState | undefined => {
    return privateEquityFundProjectsSelectors.selectElementByT(
      privateEquityFundProjects,
      {
        id,
        workspaceId,
      },
    );
  },
)({
  keySelector: (_state, workspaceId, privateEquityFundProjectId) =>
    `${workspaceId}:${privateEquityFundProjectId}`,
  selectorCreator: createSelector,
});
