import React from 'react';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { FileRelation } from '~src/domain/workspace/components/file/file-relation';
import { FileWithRelations } from '~src/domain/workspace/components/file/workspace-files.component';
import { File } from '~src/services/graphql/workspace/client/graphql';

import { Box, Divider } from '@mui/material';

interface Props {
  files: FileWithRelations[];
  deleteFiles?: (files: FileState[]) => Promise<string[]>;
  downloadFiles?: (files: FileState[]) => Promise<string[] | undefined>;
  editFile?: (file: FileState) => Promise<void>;
  renderFile: (
    file: File,
    relations: FileRelation[],
    deleteFile?: (file: FileState) => void,
    downloadFile?: (file: FileState) => void,
    selectFile?: () => void,
  ) => JSX.Element;
}

export const WorkspaceFileList = (props: Props) => {
  const { files, deleteFiles, downloadFiles, renderFile } = props;
  const deleteFile = React.useCallback(
    (file: FileState) => {
      deleteFiles && deleteFiles([file]);
    },
    [deleteFiles],
  );

  const downloadFile = React.useCallback(
    (file: FileState) => {
      downloadFiles && downloadFiles([file]);
    },
    [downloadFiles],
  );

  const fileElements = React.useMemo(() => {
    return files.map((file, idx) => {
      return (
        <Box key={idx}>
          {renderFile(file.file, file.relations, deleteFile, downloadFile)}
          <Divider />
        </Box>
      );
    });
  }, [deleteFile, downloadFile, files, renderFile]);

  return <Box>{fileElements}</Box>;
};
