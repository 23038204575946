import * as uuid from 'uuid';
import { createWarrantHoldings } from '~src/data/store/modules/holdings/warrant/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateWarrantHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/warrant-holdings/form/fields.component';
import { AppDispatch } from '~src/store/store';
import { PartialBy } from '~src/utils/common/typescript-utils';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type WarrantHoldingInputType = PartialBy<
  graphqlWorkspaceTypes.CreateWarrantHoldingInputType,
  'warrantId'
>;

export const createAction = (
  workspace: WorkspaceState,
  input: WarrantHoldingInputType,
  setHoldingDataInput: (data: WarrantHoldingInputType) => void,
) => {
  const fields = (
    <CreateWarrantHoldingFieldsComponent
      workspace={workspace}
      setWarrantHoldingInput={setHoldingDataInput}
      warrantHoldingInput={input}
    />
  );
  const valid = () => {
    return input.warrantId !== undefined;
  };

  const action = (holding: HoldingState) => {
    return (dispatch: AppDispatch) => {
      const warrantId = input.warrantId;
      if (warrantId) {
        return dispatch(
          createWarrantHoldings(workspace.tenantId, workspace.id, [
            {
              id: uuid.v1(),
              holdingId: holding.id,
              warrantId,
            },
          ]),
        );
      }
      throw 'Not valid, need to set warrantId';
    };
  };
  return { action, valid, fields };
};
