import React from 'react';
import { useSelector } from 'react-redux';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPortfolioAndDescendantsByPortfolioId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getPortfolioProjectByProjectId } from '~src/data/store/selectors/workspace/projects/portfolio-project/portfolio-projects/selectors';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  handleValue: (portfolios: PortfolioState[]) => void;
  portfolios: PortfolioState[];
  labelled: boolean;
}

export const EditPortfolios = React.memo((props: Props) => {
  const portfolioProject = useSelector((state: RootState) =>
    getPortfolioProjectByProjectId(
      selectWorkspaceData(state),
      props.workspace.id,
      props.projectId,
    ),
  );

  const allPortfolios = useSelector((state: RootState) =>
    getPortfolioAndDescendantsByPortfolioId(selectWorkspaceData(state), {
      id: portfolioProject?.portfolioId ?? '',
      workspaceId: props.workspace.id,
    }),
  );
  return (
    <Autocomplete
      options={allPortfolios}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(portfolio: PortfolioState) => portfolio.name ?? ''}
      style={{ flex: 1 }}
      multiple
      value={props.portfolios}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: PortfolioState[] | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      // onBlur={() => {
      //   props.handlePersist && props.handlePersist();
      // }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.labelled ? 'Portfolios' : undefined}
          fullWidth
          margin="normal"
        />
      )}
    />
  );
});
