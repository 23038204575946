import { RecordState } from '@pladdenico/common';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  HoldingConfigState,
  selectors as holdingConfigsSelectors,
} from '~src/data/store/reducers/holding/config/configs/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

const getHoldingConfigState = (state: WorkspaceDataState) =>
  state.holding.holding.configs;

export const selectHoldingConfigsByHoldingId = createCachedSelector(
  (state: RecordState<HoldingConfigState, string>[], holdingId: string) =>
    holdingConfigsSelectors.selectElementsByKey(state, holdingId),
  (holdings: HoldingConfigState[] | undefined): HoldingConfigState[] => {
    return holdings ? holdings : [];
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getHoldingConfigsByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingConfigState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (holdingState, holdingId): HoldingConfigState[] => {
    return selectHoldingConfigsByHoldingId(holdingState, holdingId);
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getHoldingConfigByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, holdingId: string) =>
    getHoldingConfigsByHoldingId(state, holdingId),
  (configs): HoldingConfigState | undefined => {
    if (configs.length > 0) {
      return configs[0];
    }
    return undefined;
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getHoldingConfigs = createSelector(
  (state: WorkspaceDataState) => getHoldingConfigState(state),
  (holdings) => {
    return holdingConfigsSelectors.selectAllElements(holdings);
  },
);
