import moment from 'moment';
import { createSelectorCreator, lruMemoize } from 'reselect';
// import { isEqual } from 'lodash';

const momentEqualityCheck = (v1: any, v2: any) => {
  if (v1 instanceof moment && v2 instanceof moment) {
    return (v1 as moment.Moment).isSame(v2 as moment.Moment);
  }
  return v1 === v2;
};

// create a "selector creator" that uses lodash.isEqual instead of ===
export const makeCreateSpecificSelector = () =>
  createSelectorCreator(lruMemoize, momentEqualityCheck);
