import { workspaceProjectRootPath } from '../../paths';

export const portfoliosBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/portfolios`;
};

export const portfolioBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  portfolioId: string,
) => {
  return `${portfoliosBasePath(
    tenantId,
    workspaceId,
    projectId,
  )}/${portfolioId}`;
};
