import { parseProjectReport } from '~src/data/store/modules/workspaces/reports/project-report/parser';
import {
  projectReportsActions,
  ProjectReportState,
} from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import {
  DashboardElementStateVisitor,
  DashboardElementVisitable,
  DashboardElementVisitor,
} from '~src/data/store/visitors/common/dashboard/element-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import { ProjectReport } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

interface ProjectReportVisitor {
  visit(projectReport: ProjectReportVisitable): ProjectReportState;
  dashboardElementVisitor: DashboardElementVisitor;
}

export class ProjectReportVisitable implements Visitable<ProjectReportVisitor> {
  constructor(
    private _workspaceId: string,
    private _projectReport: ProjectReport,
  ) {}
  public accept(visitor: ProjectReportVisitor) {
    if (this._projectReport.elements) {
      this._projectReport.elements.forEach((element) => {
        const dashboardElementVisitable = new DashboardElementVisitable(
          element,
        );
        dashboardElementVisitable.accept(visitor.dashboardElementVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): ProjectReportState {
    return parseProjectReport(this._projectReport);
  }
}

export class ProjectReportStateVisitor implements ProjectReportVisitor {
  private _projectReports: ProjectReportState[];
  public dashboardElementVisitor: DashboardElementVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._projectReports = [];
    this.dashboardElementVisitor = new DashboardElementStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(projectReport: ProjectReportVisitable): ProjectReportState {
    const report = projectReport.parse();
    this._projectReports.push(report);
    return report;
  }
  post() {
    this._dispatch(
      projectReportsActions.upsertManyElements(this._projectReports),
    );
    this.dashboardElementVisitor.post();
  }
}
