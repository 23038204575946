import React from 'react';
import * as uuid from 'uuid';

import Autorenew from '@mui/icons-material/Autorenew';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type PersonInput =
  | graphqlWorkspaceTypes.CreatePersonInputType
  | graphqlWorkspaceTypes.UpdatePersonInputType;

interface Props {
  hasIdField: boolean;
  personInput: PersonInput;
  setPersonInput: (personInput: PersonInput) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const PersonFieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof PersonInput) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.setPersonInput({
        ...props.personInput,
        [name]: event.target.value,
      });
    };

  const handleClickRegenerateId = () => {
    props.setPersonInput({ ...props.personInput, id: uuid.v1() });
  };

  const handleMouseDownRegenerateId = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const idField = props.hasIdField ? (
    <FormControl
      fullWidth
      required
      className={classes.textField}
      margin="normal"
    >
      <InputLabel htmlFor="adornment-id">Id</InputLabel>
      <Input
        id="adornment-id"
        type={'text'}
        value={props.personInput.id}
        onChange={handleChange('id')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="id regeneration"
              onClick={handleClickRegenerateId}
              onMouseDown={handleMouseDownRegenerateId}
            >
              <Autorenew />
              {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  ) : undefined;

  return (
    <>
      {idField}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.personInput.name}
        label="Name"
        type="text"
        onChange={handleChange('name')}
      />
    </>
  );
});
