import { combineReducers } from 'redux';

import {
  bondHoldingsReducer,
  initialBondHoldingState,
} from './bond-holding/reducer';
import {
  bondPositionsReducer,
  initialBondPositionState,
} from './bond-position/reducer';
import { bondTradesReducer, initialBondTradeState } from './bond-trade/reducer';

export const bondHoldingInitialState = {
  bondHoldings: initialBondHoldingState,
  bondPositions: initialBondPositionState,
  bondTrades: initialBondTradeState,
};

export const bondHoldingReducer = combineReducers({
  bondHoldings: bondHoldingsReducer,
  bondPositions: bondPositionsReducer,
  bondTrades: bondTradesReducer,
});
