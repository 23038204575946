import * as React from 'react';
import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateStockGoalRuleComponent } from '~src/domain/workspace/components/project/holding/holding-types/stock-holdings/stock-goal-rules/form/create.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import MoreIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  stockHoldingId: string;
}

export const GoalRulesMenuComponent = React.memo((props: Props) => {
  const {
    isShowing: isShowingModal,
    open: openModal,
    close: closeModal,
  } = useVisible();
  const {
    handleClose: handleCloseMenu,
    handleOpen: handleOpenMenu,
    isOpen: isOpenMenu,
    anchorEl: anchorElMenu,
  } = useMenu();

  const handleCloseAll = React.useCallback(() => {
    closeModal();
    handleCloseMenu();
  }, [closeModal, handleCloseMenu]);

  const handleOpenModal = React.useCallback(() => {
    handleCloseMenu();
    openModal();
  }, [handleCloseMenu, openModal]);

  const menu = (
    <Menu
      anchorEl={anchorElMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpenMenu}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleOpenModal}>Add goal rule</MenuItem>
      {/* {workspaces} */}
      {/* {messagesBadge}
      {notificationBadge}
      {profileButton}
      {loginComponent}
      {signupComponent} */}
    </Menu>
  );
  return (
    <>
      <IconButton onClick={handleOpenMenu} color="inherit">
        <MoreIcon />
      </IconButton>
      {menu}
      <CreateStockGoalRuleComponent
        open={isShowingModal}
        workspace={props.workspace}
        handleClose={handleCloseAll}
        stockGoalRuleCreateInputInit={{
          id: uuid.v1(),
          stockHoldingId: props.stockHoldingId,
          goalRule: {
            name: '',
            formula: '',
          },
        }}
      />
    </>
  );
});
