import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const _fontFamilyType1 = [
  'Source Sans Pro',
  'Helvetica',
  'Arial',
  'sans-serif',
];
_fontFamilyType1;

const _fontFamilyType2 = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];
_fontFamilyType2;

const _fontFamilyType3 = ['Poppins', 'Lato', 'sans-serif'];
_fontFamilyType3;

export const appBarColors = {
  backgroundColor: 'black',
  color: 'white',
};

// Augment the palette to include a salmon color
declare module '@mui/material/styles' {
  interface Palette {
    salmon: Palette['primary'];
    white: Palette['primary'];
  }

  interface PaletteOptions {
    salmon?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include a salmon option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    salmon: true;
    white: true;
  }
}

const setupTheme = () => {
  let theme = createTheme({
    typography: {
      h3: {
        fontSize: 12,
      },
      fontFamily: _fontFamilyType3.join(','),
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: appBarColors.backgroundColor,
            color: appBarColors.color,
            boxShadow: 'none',
          },
        },
      },
    },
  });

  theme = createTheme(theme, {
    palette: {
      salmon: theme.palette.augmentColor({
        color: {
          main: '#FF5733',
        },
        name: 'salmon',
      }),
      white: theme.palette.augmentColor({
        color: {
          main: '#FFFFFF',
        },
        name: 'white',
      }),
    },
  });

  theme = responsiveFontSizes(theme, { factor: 100 });
  return theme;
};

export const theme = setupTheme();
