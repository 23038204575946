import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { Person } from '~src/services/graphql/workspace/client/graphql';

export function parsePerson(
  tenantId: string,
  workspaceId: string,
  person: Person,
): PersonState {
  return {
    id: person.id,
    workspaceId,
    tenantId,
    name: person.name,
    description: person.description,
  };
}
