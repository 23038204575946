import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  defaultAbsoluteBackgroundColor,
  defaultAbsoluteFontColor,
  defaultDiffBackgroundColor,
  defaultDiffFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/waterfall/default-colors';
import { useWaterfallMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/waterfall/menu.component';
import { WaterfallComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/waterfall/waterfall-component';
import { Config } from '~src/utils/interfaces/config';
import {
  WaterfallType,
  WaterfallTypes,
} from '~src/utils/interfaces/waterfall-type';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  baseCurrency: CurrencyState;
  period: Period;
}

const getDefaultFontColor = (type: WaterfallType) => {
  if (type === WaterfallTypes.Absolute) {
    return defaultAbsoluteFontColor;
  } else if (type === WaterfallTypes.Diff) {
    return defaultDiffFontColor;
  }
  return '#FFFFFF';
};

const getDefaultBackgroundColor = (type: WaterfallType) => {
  if (type === WaterfallTypes.Absolute) {
    return defaultAbsoluteBackgroundColor;
  } else if (type === WaterfallTypes.Diff) {
    return defaultDiffBackgroundColor;
  }
  return '#FFFFFF';
};

export const WaterfallElement = React.memo(
  ({
    workspace,
    projectId,
    holdings,
    config,
    baseCurrency,
    period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useWaterfallMenu(
      element,
      remove,
      updated,
    );
    const type =
      (element.config.type as WaterfallType) ?? WaterfallTypes.Absolute;
    const color = element.config.color ?? getDefaultFontColor(type);
    const backgroundColor =
      element.config.backgroundColor ?? getDefaultBackgroundColor(type);
    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color,
          backgroundColor,
          padding: element.config.padding,
        },
      };
    }, [backgroundColor, color, element.config.padding, isOpen]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <WaterfallComponent
            type={type}
            filterKey={(element.config.pivotType as string) ?? 'category'}
            workspace={workspace}
            projectId={projectId}
            holdings={holdings}
            date={config.date}
            period={period}
            baseCurrency={baseCurrency}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
