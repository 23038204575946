import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  ProjectReportState,
  selectors,
} from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';

const getProjectReportState = (state: WorkspaceDataState) =>
  state.workspace.project.report.projectReports;

interface Id {
  projectId: string;
  id: string;
}

export const getProjectReportById = createCachedSelector(
  (state: WorkspaceDataState, _id: Id) => getProjectReportState(state),
  (_state: WorkspaceDataState, id: Id) => id,
  (projectReports, id): ProjectReportState | undefined => {
    return selectors.selectElementByT(projectReports, id);
  },
)({
  keySelector: (_state, id) => `${id.projectId}:${id.id}`,
  selectorCreator: createSelector,
});

interface Ids {
  projectId: string;
  ids: string[];
}

export const getProjectReportsByIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: Ids) => getProjectReportState(state),
  (_state: WorkspaceDataState, ids: Ids) => ids,
  (projectReports, ids): ProjectReportState[] => {
    return selectors.selectElementsByTs(
      projectReports,
      ids.ids.map((id) => {
        return { projectId: ids.projectId, id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
interface ProjectId {
  projectId: string;
}

export const getProjectReportsByProjectId = createCachedSelector(
  (state: WorkspaceDataState, _projectId: ProjectId) =>
    getProjectReportState(state),
  (_state: WorkspaceDataState, projectId: ProjectId) => projectId.projectId,
  (projectReports, projectId): ProjectReportState[] | undefined => {
    return selectors.selectElementsByKey(projectReports, projectId);
  },
)({
  keySelector: (_state, projectId) => projectId.projectId,
  selectorCreator: createSelector,
});
