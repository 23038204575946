import * as React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getHoldingValuations } from '~src/data/store/selectors/finance/valuation/holding-valuations';
import {
  selectData,
  selectFinancialData,
} from '~src/data/store/selectors/selectors';
import { ValuationsComponent } from '~src/domain/workspace/components/project/valuation/valuations.component';
import { getLastDate } from '~src/utils/common/date-utils';

import { ValuationStatus } from '@pladdenico/models';
import { CreateValuationInputType } from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState;
}

export const HoldingValuationsComponent = React.memo((props: Props) => {
  // const valuations = useSelector((state: RootState) =>
  //   getValuationsByHoldingId(selectWorkspaceData(state), props.holding.id)
  // );
  const { workspace, holding } = props;
  const baseCurrencyId = useSelector((state: RootState) =>
    getSelectedCurrencyState(selectFinancialData(state)),
  );
  const currency = useSelector((state: RootState) => {
    const currency = getCurrencyById(selectFinancialData(state), {
      id: baseCurrencyId,
    });
    if (!currency) {
      return defaultCurrency;
    }
    return currency;
  });
  const valuations = useSelector((state: RootState) =>
    getHoldingValuations(selectData(state), {
      workspaceId: workspace.id,
      currency,
      holding,
    }),
  );

  // TODO: Could be last date at all (with stock and everything)
  // XXX: Should be fixed
  const lastValuation = getLastDate(valuations, (value) => value.base?.date);

  let valuationCreateInputInit: CreateValuationInputType;
  if (lastValuation != null) {
    // const lastValuation = valuations[lastValuationIdx];
    valuationCreateInputInit = {
      id: uuid.v1(),
      currencyId: lastValuation.base?.valuation?.currencyId ?? '',
      date: new Date(),
      holdingId: props.holding.id,
      ownershipPercentage:
        lastValuation.base?.valuation?.ownershipPercentage ?? 0,
      status:
        lastValuation.base?.valuation?.status ?? ValuationStatus.confirmed,
      value: lastValuation.base?.valuation?.value ?? 0,
    };
  } else {
    valuationCreateInputInit = {
      id: uuid.v1(),
      currencyId: '',
      date: new Date(),
      holdingId: props.holding.id,
      ownershipPercentage: 0,
      status: ValuationStatus.confirmed,
      value: 0,
    };
  }
  return (
    <ValuationsComponent
      projectId={props.projectId}
      valuations={valuations}
      workspace={props.workspace}
      valuationCreateInputInit={valuationCreateInputInit}
    />
  );
});
