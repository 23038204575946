import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { HoldingsViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-view.component';

import {
  defaultCashFlowBackgroundColor,
  defaultCashFlowFontColor,
  defaultCashOnCashRatioBackgroundColor,
  defaultCashOnCashRatioFontColor,
  defaultIrrBackgroundColor,
  defaultIrrFontColor,
  defaultValueBackgroundColor,
  defaultValueChangeBackgroundColor,
  defaultValueChangeFontColor,
  defaultValueFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/holdings-view/default-colors';
import { useHoldingsViewMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/holdings-view/menu.component';
import { Config } from '~src/utils/interfaces/config';
import {
  HoldingsViewType,
  HoldingsViewTypes,
} from '~src/utils/interfaces/holdings-view-type';
import { SortOrder, SortOrders } from '~src/utils/interfaces/sort-order';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  period: Period;
}

const getDefaultFontColor = (type: HoldingsViewType) => {
  if (type === HoldingsViewTypes.Value) {
    return defaultValueFontColor;
  } else if (type === HoldingsViewTypes.Irr) {
    return defaultIrrFontColor;
  } else if (type === HoldingsViewTypes.CashOnCashRatio) {
    return defaultCashOnCashRatioFontColor;
  } else if (type === HoldingsViewTypes.CashFlow) {
    return defaultCashFlowFontColor;
  } else if (type === HoldingsViewTypes.ValueChange) {
    return defaultValueChangeFontColor;
  }
  return '#FFFFFF';
};

const getDefaultBackgroundColor = (type: HoldingsViewType) => {
  if (type === HoldingsViewTypes.Value) {
    return defaultValueBackgroundColor;
  } else if (type === HoldingsViewTypes.Irr) {
    return defaultIrrBackgroundColor;
  } else if (type === HoldingsViewTypes.CashOnCashRatio) {
    return defaultCashOnCashRatioBackgroundColor;
  } else if (type === HoldingsViewTypes.CashFlow) {
    return defaultCashFlowBackgroundColor;
  } else if (type === HoldingsViewTypes.ValueChange) {
    return defaultValueChangeBackgroundColor;
  }
  return '#FFFFFF';
};

export const HoldingsViewElement = React.memo(
  ({
    workspace,
    portfolioProject,
    holdings,
    config,
    period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useHoldingsViewMenu(
      element,
      remove,
      updated,
    );

    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), {
        id: baseCurrencyId,
      }),
    );
    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }
    const type =
      (element.config.type as HoldingsViewType) ?? HoldingsViewTypes.Value;
    const color = element.config.color ?? getDefaultFontColor(type);
    const backgroundColor =
      element.config.backgroundColor ?? getDefaultBackgroundColor(type);

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color,
          backgroundColor,
          padding: element.config.padding,
        },
      };
    }, [backgroundColor, color, element.config.padding, isOpen]);

    const format = React.useMemo(() => {
      return {
        color,
        backgroundColor,
        // textColor,
      };
    }, [backgroundColor, color]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <HoldingsViewComponent
            workspace={workspace}
            projectId={portfolioProject.projectId}
            period={period}
            valuationDate={config.date}
            holdings={holdings}
            minimumValuationForHolding={
              (element.config.minimumValue as number) ?? Number.MIN_SAFE_INTEGER
            }
            baseCurrency={baseCurrency}
            type={type}
            sortOrder={
              (element.config.sortOrder as SortOrder) ?? SortOrders.Decreasing
            }
            format={format}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
