import {
  TenantRoleStateVisitor,
  TenantRoleVisitable,
} from '~src/data/store/visitors/tenant/tenant-role-visitor';
import { TenantRole } from '~src/services/graphql/tenant/client/graphql';

export function handleTenantRoles(dispatch: any, tenantRoles: TenantRole[]) {
  const visitor = new TenantRoleStateVisitor(dispatch);
  const res = tenantRoles.map((tenantRole) => {
    const visitable = new TenantRoleVisitable(tenantRole);
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
