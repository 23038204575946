import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsByProjectId } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { HoldingsTransactionsComponent } from '~src/domain/workspace/components/project/transaction/holdings-transactions.component';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  configContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  root: {
    // marginTop: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
  },
}));

export const ProjectTransactionsComponent = React.memo((props: Props) => {
  const { project } = props;
  const { classes } = useStyles();
  const holdings = useSelector((state: RootState) =>
    getHoldingsByProjectId(selectWorkspaceData(state), project.id),
  );

  return (
    <>
      <div className={classes.root}>
        <HoldingsTransactionsComponent
          workspace={props.workspace}
          project={props.project}
          holdings={holdings}
        />
      </div>
    </>
  );
});
