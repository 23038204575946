import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getEventById } from '~src/data/store/selectors/workspace/projects/event/event/selectors';
import { ProjectEventComponent } from '~src/domain/workspace/components/project/event/event.component';
import { eventsBasePath } from '~src/navigation/paths/workspace/project/event/paths';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  project: ProjectState;
}

export const EventRouterWrapper = React.memo((props: Props) => {
  const { workspace, project, portfolio, portfolioProject } = props;
  const { eventId } = useParams<{ eventId: string }>();
  const event = useSelector((state: RootState) => {
    if (eventId != null) {
      return getEventById(selectWorkspaceData(state), {
        id: eventId,
        projectId: portfolioProject.projectId,
      });
    }
  });
  if (!event) {
    return (
      <Navigate
        to={`${eventsBasePath(workspace.tenantId, workspace.id, project.id)}`}
      />
    );
  } else {
    return (
      <ProjectEventComponent
        workspace={workspace}
        project={project}
        portfolio={portfolio}
        portfolioProject={portfolioProject}
        event={event}
      />
    );
  }
});
