import { compact, sortBy } from 'lodash';
import { ReportState } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type Report = graphqlWorkspaceTypes.Report;

export function parseReport(report: graphqlWorkspaceTypes.Report): ReportState {
  return {
    id: report.id,
    name: report.name,
    projectId: report.project?.id ?? '',
    schedulerId: report.scheduler?.id ?? '',
    description: report.description,
    holdingId: report.holding?.id,
    userIds: sortBy(compact(report.users?.map((user) => user.id)) ?? []),
  };
}
