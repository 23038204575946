import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { List, ListItem, TablePagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableOperations } from '@pladdenico/table';

import { TransactionCardComponent } from './card.component';
import { TransactionData } from '~src/domain/workspace/components/project/transaction/transaction-data';
// import { TransferData } from '~src/domain/workspace/components/project/transfer/project-transfers.component';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { Identity } from '~src/utils/interfaces/identity';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { compact } from 'lodash';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  portfolios?: PortfolioState[];
  data: TransactionCardData[];
}

const useStyles = makeStyles()({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // marginTop: theme.spacing(2),
  },
  pagination: {
    overflow: 'hidden',
  },
  listItem: {
    flex: 1,
  },
});

export interface TransactionCardData extends Identity {
  transaction: TransactionData;
  transfers: {
    // transfer: TransferData;
    transfer: TransferState;
    holding: HoldingDenormalizedWithEntity | undefined;
  }[];
}

export const TransactionCardsListComponent = React.memo((props: Props) => {
  const { workspace, projectId, portfolios, data } = props;
  const { classes } = useStyles();
  const { paginationPosition, updateRowsPerPage, updatePage } =
    TableOperations.usePagination(data, 10);

  const handleRowsPerPageChange = React.useCallback(
    (event: any) => {
      updateRowsPerPage(+event.target.value);
    },
    [updateRowsPerPage],
  );

  const handlePageChange = React.useCallback(
    (_event: any, page: number) => {
      updatePage(page);
    },
    [updatePage],
  );

  const filteredDataOnPortfolio = React.useMemo(() => {
    return compact(
      data.map((transactionData) => {
        const someTransferWithinPortfolio = portfolios
          ? transactionData.transfers.some(
              (transfer) =>
                transfer.holding?.portfolios.some((p) =>
                  portfolios.some((p2) => p2.id === p.id),
                ),
            )
          : true;

        if (!someTransferWithinPortfolio) {
          return undefined;
        }
        return transactionData;
      }),
    );
  }, [data, portfolios]);

  const listContent = filteredDataOnPortfolio
    .slice(paginationPosition.startIdx, paginationPosition.endIdx)
    .map((transactionData) => {
      return (
        <ListItem
          key={transactionData.transaction.transaction.id}
          classes={{ root: classes.listItem }}
          disableGutters
        >
          <TransactionCardComponent
            transaction={transactionData}
            workspace={workspace}
            projectId={projectId}
            portfolios={portfolios}
          />
        </ListItem>
      );
    });

  const pagination = (
    <div className={classes.pagination}>
      <TablePagination
        component="div"
        count={filteredDataOnPortfolio.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={paginationPosition.page}
        rowsPerPage={paginationPosition.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </div>
  );

  return (
    <div className={classes.content}>
      <List dense>{listContent}</List>
      {pagination}
    </div>
  );
});
