import { combineReducers } from 'redux';

import { projectInitialState, projectReducer } from './projects/reducer';
import {
  workspaceRoleInitialState,
  workspaceRoleReducer,
} from './workspace-role/reducer';
import {
  workspaceInvitationsReducer,
  workspaceInvitationsInitialState,
} from './workspace-invitations/reducer';
import {
  workspacesReducer,
  initialWorkspacesState,
} from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  selectedWorkspacesReducer,
  initialSelectedWorkspaceState,
} from '~src/data/store/reducers/workspace/selected-workspace/reducer';
import {
  exportInitialState,
  exportReducer,
} from '~src/data/store/reducers/workspace/exports/reducer';

export const workspaceInitialState = {
  workspaces: initialWorkspacesState,
  selectedWorkspaceId: initialSelectedWorkspaceState,
  project: projectInitialState,
  workspaceRole: workspaceRoleInitialState,
  workspaceInvitations: workspaceInvitationsInitialState,
  exports: exportInitialState,
};

export const workspaceReducer = combineReducers({
  workspaces: workspacesReducer,
  selectedWorkspaceId: selectedWorkspacesReducer,
  project: projectReducer,
  workspaceRole: workspaceRoleReducer,
  workspaceInvitations: workspaceInvitationsReducer,
  exports: exportReducer,
});
