import { compact } from 'lodash';
import {
  AssetCategoryStateVisitor,
  AssetCategoryVisitable,
} from '~src/data/store/visitors/asset/category/asset-category-visitor';
import { AssetCategory } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleAssetCategories(
  workspaceId: string,
  dispatch: AppDispatch,
  outputs: AssetCategory[],
) {
  const visitor = new AssetCategoryStateVisitor(dispatch);
  const res = compact(
    outputs.map((crypto) => {
      const assetVisitable = new AssetCategoryVisitable(workspaceId, crypto);
      return assetVisitable.accept(visitor);
    }),
  );
  visitor.post();
  return res;
}
