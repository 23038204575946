import { User } from '@pladdenico/models';
import React from 'react';
import { useDispatch } from 'react-redux';
import { UserLogin } from '~src/domain/user/components/login/user-login';
// import history from '~src/navigation/history';
import { AppDispatch } from '~src/store/store';
import { login } from '~src/data/store/modules/account';

import { userBasePath } from '../../../../navigation/paths/user/paths';
import { useNavigate } from 'react-router-dom';

export function useLoginUser(redirectUrl?: string) {
  const [userInput, setUserInput] = React.useState<UserLogin>({
    email: '',
    password: '',
  });
  const [loginError, setLoginError] = React.useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const saveUser = () => {
    dispatch(
      login(userInput.email, userInput.password, (_user?: User, err?: any) => {
        if (err) {
          // setLoginError(err);
          setLoginError(err);
        } else {
          setLoginError(undefined);
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate(userBasePath);
          }
        }
      }),
    );
  };

  return {
    userInput,
    setUserInput,
    loginError,
    setLoginError,
    saveUser,
  };
}
