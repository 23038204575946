import { RoleState } from '~src/data/store/reducers/person/roles/reducer';
import { Role } from '~src/services/graphql/workspace/client/graphql';

export function parseRole(workspaceId: string, role: Role): RoleState {
  const personId = role.person?.id ?? '';
  return {
    id: role.id,
    workspaceId,
    personId,
    name: role.name,
    description: role.description,
    holdingId: role.holding?.id ?? '',
    projectId: role.holding?.project?.id ?? '',
  };
}
