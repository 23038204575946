import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getHoldingsByPortfolioId,
  getSelectedPortfolio,
} from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { FilterComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/filter/filter.component';
import { ConfigButtonComponent } from '~src/domain/workspace/components/project/types/portfolio-project/config-button.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';

export const useFilter = (
  workspace: WorkspaceState,
  project: ProjectState,
  portfolioProject: PortfolioProjectState,
  portfolio: PortfolioState,
) => {
  const selectedPortfolio = useSelector((state: RootState) => {
    const p = getSelectedPortfolio(selectWorkspaceData(state), workspace.id);
    if (!p) {
      return portfolio;
    }
    return p;
  });
  const holdings = useSelector((state: RootState) =>
    getHoldingsByPortfolioId(selectWorkspaceData(state), {
      id: selectedPortfolio.id,
      projectId: portfolioProject.projectId,
      workspaceId: workspace.id,
    }),
  );

  const { isShowing: filterOpen, toggle: toggleFilter, close } = useVisible();
  const theme: Theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const filter = React.useMemo(() => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <DialogComponent
          dialogProps={{
            open: filterOpen,
            onClose: close,
            fullWidth: true,
            maxWidth: 'lg',
          }}
        >
          <DialogContentComponent handleClose={close} title={'Filter'}>
            <FilterComponent
              date={moment()}
              holdings={holdings}
              projectId={project.id}
              workspace={workspace}
            />
          </DialogContentComponent>
        </DialogComponent>
      </Box>
    );
  }, [close, filterOpen, holdings, project.id, workspace]);

  const filterButton = React.useMemo(() => {
    if (isMd) {
      return (
        <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center' }}>
          <IconButton size={'small'} onClick={toggleFilter}>
            <FilterListIcon />
          </IconButton>
        </Box>
      );
    } else {
      // TODO: need to brek up FilterComponent!
      return (
        <Box sx={{ mt: 1, ml: 1 }}>
          <ConfigButtonComponent
            isVisible={filterOpen}
            toggleConfig={toggleFilter}
            text="filter"
            variant="outlined"
          />
        </Box>
      );
    }
  }, [filterOpen, isMd, toggleFilter]);

  return { filterButton, filter };
};
