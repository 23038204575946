import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { StockHoldingState } from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import { StockPositionState } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getStockPositionsByStockHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useStockPositionTableActions } from '~src/hooks/actions/workspace/project/holding/types/stocks/use-stock-position-table-actions';
import {
  StockPositionData,
  useStockPositionFields,
} from '~src/hooks/fields/workspace/project/holding/use-stock-position-fields';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { StockPositionsToolbar } from './stock-positions-toolbar.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState;
  stockHolding: StockHoldingState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function stockPositionsToData(stockPositions: StockPositionState[]) {
  return [...stockPositions];
}

export const StockPositionsComponent = React.memo((props: Props) => {
  const stockPositions = useSelector((state: RootState) =>
    getStockPositionsByStockHoldingId(
      selectWorkspaceData(state),
      props.stockHolding.id,
    ),
  );
  const { classes } = useStyles();
  const [data, setData] = React.useState<StockPositionData[]>(
    stockPositionsToData(stockPositions),
  );
  const { onRowUpdate, onRowDelete } = useStockPositionTableActions(
    props.workspace,
  );
  const fields = useStockPositionFields(
    props.workspace.tenantId,
    props.workspace,
    props.projectId,
    props.holding.id,
  );

  React.useEffect(() => {
    setData(stockPositionsToData(stockPositions));
  }, [stockPositions]);

  const lastStockPositionIdx = stockPositions.reduce(
    (prevStockPosition, currentStockPosition, idx) => {
      if (
        !prevStockPosition ||
        stockPositions[idx].date.getTime() < currentStockPosition.date.getTime()
      ) {
        return idx;
      }
      return prevStockPosition;
    },
    -1,
  );

  let stockPositionCreateInputInit: graphqlWorkspaceTypes.CreateStockPositionInputType;
  if (lastStockPositionIdx !== -1) {
    const lastStockPosition = stockPositions[lastStockPositionIdx];
    stockPositionCreateInputInit = {
      id: uuid.v1(),
      date: lastStockPosition.date ?? new Date(),
      shares: lastStockPosition.shares ?? 0,
      stockHoldingId: props.stockHolding.id,
    };
  } else {
    stockPositionCreateInputInit = {
      id: uuid.v1(),
      date: new Date(),
      shares: 0,
      stockHoldingId: props.stockHolding.id,
    };
  }

  const [initialSorters] = React.useState<
    TableField.Sorter<StockPositionData>[]
  >(() => {
    return compact([
      TableOperations.createSorterFromFields(fields, 'date', 'desc'),
    ]);
  });

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  return (
    <div className={classes.root}>
      <StockPositionsToolbar
        workspace={props.workspace}
        stockPositionCreateInputInit={stockPositionCreateInputInit}
      />
      <div className={classes.content}>
        <TableComponent<StockPositionData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
});
