import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { PaperEditFieldComponent } from '~src/domain/workspace/components/common/paper/edit-field.component';

export const usePaperField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  label: string,
  defaultValue: PathValue<TFieldValues, TName>,
) => {
  return (
    <Controller
      name={tname}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <PaperEditFieldComponent
          paperId={field.value ?? null}
          setPaperId={field.onChange}
          label={label}
        />
      )}
    />
  );
};
