import React from 'react';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, MenuItem } from '@mui/material';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';

export const useDeleteDashboardElementMenuItem = (
  element: DashboardElementState,
  remove: (id: string) => void,
) => {
  const deleteModal = useVisible();

  const confirmElement = React.useMemo(() => {
    return (
      <ConfirmDialogComponent
        key={`confirm-delete-${element.id}`}
        isOpen={deleteModal.isShowing}
        handler={() => {
          remove(element.id);
          deleteModal.close();
        }}
        close={deleteModal.close}
        objectString="element"
      />
    );
  }, [deleteModal, element.id, remove]);

  const menuItem = React.useMemo(() => {
    return (
      <MenuItem
        key="deleteMenuDeleteButton"
        onClick={() => {
          deleteModal.open();
          // remove(element.id);
        }}
        onTouchEnd={() => {
          deleteModal.open();
          // remove(element.id);
        }}
      >
        <IconButton
          disableRipple
          size={'small'}
          sx={{ mr: 1.5, color: (theme) => theme.palette.warning.main }}
        >
          <DeleteIcon />
        </IconButton>
        Delete
      </MenuItem>
    );
  }, [deleteModal]);

  return {
    menuItem,
    confirmElement,
  };
};
