import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseTransferId,
  transfersSelectors,
  TransferState,
} from '~src/data/store/reducers/workspace/projects/scenario/transfers/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

const selectTransferState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.transfers;

export const getTransfers = createSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (transfers): TransferState[] => {
    return transfersSelectors.selectAllElements(transfers);
  },
);

export const getTransferById = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, id: BaseTransferId) => id,
  (transfers, id): TransferState | undefined => {
    return transfersSelectors.selectElementByT(transfers, id);
  },
)({
  keySelector: (_state, id) => `${id.id}-${id.holdingId}`,
  selectorCreator: createSelector,
});

export const getTransfersByBudgetId = createCachedSelector(
  (state: WorkspaceDataState) => selectTransferState(state),
  (_state: WorkspaceDataState, budgetId: string) => budgetId,
  (transfers, budgetId): TransferState[] | undefined => {
    return transfersSelectors.selectElementsByKey(transfers, budgetId);
  },
)({
  keySelector: (_state, budgetId) => budgetId,
  selectorCreator: createSelector,
});

interface BudgetIdHoldingIds {
  budgetId: string;
  holdingIds: string[];
}

export const getTransfersByBudgetIdAndHoldingIds = createCachedSelector(
  (state: WorkspaceDataState, id: BudgetIdHoldingIds) =>
    getTransfersByBudgetId(state, id.budgetId),
  (_state: WorkspaceDataState, id: BudgetIdHoldingIds) => id.holdingIds,
  (transfers, holdingIds): TransferState[] | undefined => {
    if (transfers == null) {
      return [];
    }
    return transfers.filter((transfer) =>
      holdingIds.some((id) => id === transfer.holdingId),
    );
  },
)({
  keySelector: (_state, id) =>
    `${id.budgetId}-${JSON.stringify(id.holdingIds)}`,
  selectorCreator: createSelector,
});
