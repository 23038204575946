import { parseDashboardMedia } from '~src/data/store/modules/common/dashboard/media/parser';
import { subscribeToDashboardMedias } from '~src/data/store/modules/common/dashboard/media/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  dashboardMediasActions,
  DashboardMediaState,
} from '~src/data/store/reducers/common/dashboard/media/reducer';
import { DashboardMedia } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface DashboardMediaVisitor {
  visit(media: DashboardMediaVisitable): void;
  post(): void;
}

export class DashboardMediaVisitable
  implements Visitable<DashboardMediaVisitor>
{
  constructor(
    private _dashboardElementId: string | null,
    private _media: DashboardMedia,
  ) {}
  public accept(visitor: DashboardMediaVisitor) {
    visitor.visit(this);
  }

  public parse(): DashboardMediaState {
    const media = this._media;
    const dashboardElementId = this._dashboardElementId;
    return parseDashboardMedia(media, dashboardElementId);
  }
}

export class DashboardMediaStateVisitor implements DashboardMediaVisitor {
  private _dashboardMedias: DashboardMediaState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._dashboardMedias = [];
  }
  public visit(mediaVisitable: DashboardMediaVisitable): void {
    this._dashboardMedias.push(mediaVisitable.parse());
  }

  post() {
    this._dispatch(dashboardMediasActions.addMany(this._dashboardMedias));
    subscribeToDashboardMedias(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._dashboardMedias,
      this._subscriptions,
    );
  }
}
