import { NordnetTransactionState } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import { TransactionType } from '~src/utils/finance/transaction-type';

export const getTransactionType = (transactionString: string) => {
  if (transactionString === 'UTBYTTE') {
    return TransactionType.Dividend;
  } else if (transactionString === 'BYTTE INNLEGG VP') {
    return TransactionType.Custom;
  } else if (transactionString === 'BYTTE UTTAK VP') {
    return TransactionType.Custom;
  } else if (transactionString === 'KJØPT') {
    return TransactionType.Buy;
  } else if (transactionString === 'SALG') {
    return TransactionType.Sell;
  } else if (transactionString === 'SALG VALUTA') {
    return TransactionType.Account;
  } else if (transactionString === 'KJØP VALUTA') {
    return TransactionType.Account;
  } else if (transactionString === 'AVGIFT') {
    return TransactionType.Cost;
  } else if (transactionString === 'KUPONGSKATT') {
    return TransactionType.Cost;
  } else if (transactionString === 'PLATTFORMAVGIFT') {
    return TransactionType.Cost;
  } else if (transactionString === 'UTTAK KONTANTER') {
    return TransactionType.Custom;
  } else {
    return TransactionType.Custom;
  }
};

export const needsBankNoteHolding = (transaction: NordnetTransactionState) => {
  if (
    transaction.transactionType === TransactionType.Account ||
    transaction.transactionType === TransactionType.Buy ||
    transaction.transactionType === TransactionType.Cost ||
    transaction.transactionType === TransactionType.Dividend ||
    transaction.transactionType === TransactionType.Interest ||
    transaction.transactionType === TransactionType.Sell
    // transaction.transactionType === TransactionType.Custom
  ) {
    return true;
  } else {
    return false;
  }
};

export const needsHolding = (transaction: NordnetTransactionState) => {
  if (
    transaction.transactionType === TransactionType.Cost ||
    transaction.transactionType === TransactionType.Account ||
    transaction.transactionType === TransactionType.Custom
  ) {
    return false;
  } else {
    return true;
  }
};

export const needsPaper = (transaction: NordnetTransactionState) => {
  if (
    transaction.transactionType === TransactionType.Cost ||
    transaction.transactionType === TransactionType.Account ||
    transaction.transactionType === TransactionType.Custom
  ) {
    return false;
  } else {
    return true;
  }
};
