import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseRoleId {
  id: string;
  personId: string;
}

export type RoleState = Omit<
  graphqlWorkspaceTypes.Role,
  'person' | 'workspace' | 'holding'
> & {
  workspaceId: string;
  personId: string;
  holdingId: string;
  projectId: string;
};

export const initialRolesState: RecordState<RoleState, string>[] = [];

const selectKeyId = (role: BaseRoleId) => role.personId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseRoleId, b: BaseRoleId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseRoleId, RoleState, string>(
  initialRolesState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const rolesSlice = createBaseRecordSlice('roles', adapter);
export const rolesSelectors = adapter.selectors;

export const rolesReducer = rolesSlice.reducer;
export const rolesActions = rolesSlice.actions;
export const rolesSlices = rolesSlice.slices;
