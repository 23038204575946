import { PortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs';
import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';

export const rootProjectReportPath =
  '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/reporting/projectReports/:reportId';

export const projectReportsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/reporting/projectReports`;
};

export const projectReportBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  reportId: string,
) => {
  return `${projectReportsBasePath(
    tenantId,
    workspaceId,
    projectId,
  )}/${reportId}`;
};

export const projectReportTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  reportId: string,
  tab: PortfolioProjectReportTabs,
) => {
  return `${projectReportBasePath(
    tenantId,
    workspaceId,
    projectId,
    reportId,
  )}/${tab}`;
};
