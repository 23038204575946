import { CryptoHoldingState } from '~src/data/store/reducers/holding/holding-types/crypto/crypto-holding/reducer';
import { CryptoHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseCryptoHolding(
  cryptoHolding: CryptoHolding,
): CryptoHoldingState {
  return {
    id: cryptoHolding.id,
    cryptoId: cryptoHolding.crypto?.id ?? '',
    holdingId: cryptoHolding.holding?.id ?? '',
  };
}
