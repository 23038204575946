import copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RoleState } from '~src/data/store/reducers/person/roles/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { deleteRoles } from '~src/data/store/modules/people/roles/requests';
import { EditRoleDialogComponent } from './form/edit-dialog.component';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';

interface Props {
  workspace: WorkspaceState;
  person: PersonState;
  role: RoleState;
}

export const RoleComponent = React.memo((props: Props) => {
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(false);
  const [snackbarInfo, setSnackbarInfo] = React.useState('');
  const [editRoleDialogOpened, setEditRoleDialogOpened] = React.useState(false);
  const holding = useSelector((state: RootState) =>
    getHoldingById(selectWorkspaceData(state), {
      id: props.role.holdingId,
      projectId: props.role.projectId,
    }),
  );

  const openEditRoleDialog = () => {
    setEditRoleDialogOpened(true);
    handleMenuClose();
  };

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [openRemove, setOpenRemove] = React.useState(false);

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setSnackbarInfo('Role removed');
    setOpenRemove(false);
    setOpen(true);
    handleMenuClose();
  };

  const handleSaveEdit = () => {
    setSnackbarInfo('Role saved');
    setOpen(true);
    handleMenuClose();
  };

  const snackBar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      message={snackbarInfo}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );

  const copy = () => {
    if (props.role.name) {
      if (copyToClipboard(props.role.name)) {
        setSnackbarInfo('Role name copied');
        setOpen(true);
      }
    }
  };

  const remove = () => {
    dispatch(
      deleteRoles(props.workspace.tenantId, props.workspace.id, [
        {
          personId: props.role.personId,
          id: props.role.id,
        },
      ]),
    ).then(() => handleCloseRemove());
  };

  function handleCloseEditRoleDialog() {
    setEditRoleDialogOpened(false);
  }

  const removeDialog = (
    <Dialog
      open={openRemove}
      onClose={handleCloseRemove}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm removal</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove the role?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRemove} color="primary">
          Disagree
        </Button>
        <Button onClick={remove} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );

  const roleMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={copy}>
        <ListItemIcon>
          <SaveAltIcon />
        </ListItemIcon>
        <ListItemText primary="Copy"></ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary="Edit"
          onClick={() => openEditRoleDialog()}
        ></ListItemText>
        <EditRoleDialogComponent
          open={editRoleDialogOpened}
          handleClose={handleCloseEditRoleDialog}
          savedCallback={handleSaveEdit}
          tenantId={props.workspace.tenantId}
          workspace={props.workspace}
          person={props.person}
          role={props.role}
        />
      </MenuItem>
      <MenuItem onClick={handleClickOpenRemove}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete"></ListItemText>
      </MenuItem>
      {/* <MenuItem onClick={handleAddHolding}>Call</MenuItem> */}
    </Menu>
  );

  const secondary = (
    <>
      <Typography component="span" variant="body2">
        {props.role.description}
      </Typography>
      {holding?.name}
    </>
  );

  return (
    <>
      <ListItemText primary={props.role.name} secondary={secondary} />
      <ListItemSecondaryAction>
        <IconButton onClick={handleMenuOpen} aria-label="role">
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
      {snackBar}
      {roleMenu}
      {removeDialog}
    </>
  );
});
