import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EditCategory } from '~src/hooks/fields/workspace/asset/category/edit';

export const useAssetCategoryField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  label: string,
  defaultValue: PathValue<TFieldValues, TName>,
  workspace: WorkspaceState,
) => {
  return (
    <Controller
      name={tname}
      rules={{ required: true }}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <EditCategory
          categoryId={field.value}
          handleValue={(category) => field.onChange(category.id)}
          workspace={workspace}
          label={label}
          error={
            fieldState.error ? { message: fieldState.error.message } : undefined
          }
        />
      )}
    />
  );
};
