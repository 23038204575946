import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

interface Props {}

export const ImageElementDescriptionComponent = (_props: Props) => {
  return (
    <Box>
      <Grid2 container>
        <Grid2 sm={4}>
          <Typography variant="body1">Image</Typography>
        </Grid2>
        <Grid2 sm={8}>
          <Typography variant="body2">This element shows an image.</Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
};
