export interface FilterTypes {
  include: Set<string>;
  exclude: Set<string>;
}

export enum FilterType {
  include,
  exclude,
}

export interface Filter {
  filters: Map<string, FilterTypes>;
}

export function filterToString(filter: Filter) {
  return JSON.stringify(filter.filters);
}

interface PropertyShare {
  id: string;
  share: number;
}

interface PropertyValue {
  key: string;
  value: string;
}

interface PropertyValues {
  key: string;
  values: string[];
}

export function createFilter(): Filter {
  return {
    filters: new Map(),
  };
}

export function filterFor<T>(
  filter: Filter,
  t: T,
  mapper: (key: string, t: T) => PropertyShare[],
) {
  const included = (set: Set<string>, properties: PropertyShare[]) => {
    if (set.size === 0) {
      return true;
    }
    return properties.some((p) => {
      return set.has(p.id) && p.share > 0;
    });
  };
  const excluded = (set: Set<string>, properties: PropertyShare[]) => {
    return properties.some((p) => {
      return set.has(p.id) || p.share === 0;
    });
  };

  for (const [key, sets] of filter.filters) {
    const properties = mapper(key, t);
    const isIncluded = included(sets.include, properties);
    const isExcluded = excluded(sets.exclude, properties);

    if (!isIncluded || isExcluded) {
      return false;
    }
  }
  return true;
}

export function getShareFromSets(
  sets: FilterTypes,
  properties: PropertyShare[],
) {
  const shareFromSet = (set: Set<string>, properties: PropertyShare[]) => {
    let share = 0;
    for (let i = 0; i < properties.length; ++i) {
      const prop = properties[i];
      if (set.has(prop.id)) {
        share += prop.share;
      }
    }
    return share;
  };
  const includedShare =
    sets.include.size > 0 ? shareFromSet(sets.include, properties) : 1;
  const excludedShare =
    sets.exclude.size > 0 ? shareFromSet(sets.exclude, properties) : 0;
  return includedShare - excludedShare;
}

export function getShare<T>(
  filter: Filter,
  t: T,
  mapper: (key: string, t: T) => PropertyShare[],
) {
  let totalShare = 1;
  for (const [key, sets] of filter.filters) {
    const properties = mapper(key, t);
    const share = getShareFromSets(sets, properties);
    totalShare *= share;
  }
  return totalShare;
}

export function getShareExceptKey<T>(
  filter: Filter,
  t: T,
  mapper: (key: string, t: T) => PropertyShare[],
  exceptKey: string,
) {
  let totalShare = 1;
  for (const [key, sets] of filter.filters) {
    if (key !== exceptKey) {
      const properties = mapper(key, t);
      const share = getShareFromSets(sets, properties);
      totalShare *= share;
    }
  }
  return totalShare;
}

export function getShareForKeyValue<T>(
  filter: Filter,
  t: T,
  mapper: (key: string, t: T) => PropertyShare[],
  key: string,
) {
  const sets = filter.filters.get(key);
  if (sets) {
    const properties = mapper(key, t);
    return getShareFromSets(sets, properties);
  }
  return 1;
}

export function hasFilter(filter: Filter, key: string): boolean {
  const sets = filter.filters.get(key);
  if (sets && (sets.include.size > 0 || sets.exclude.size > 0)) {
    return true;
  }
  return false;
}

export function setFilter(
  filter: Filter,
  key: string,
  values: string[],
  filterType: FilterType,
) {
  const set = new Set<string>();
  values.forEach((value) => {
    set.add(value);
  });

  let sets = filter.filters.get(key);
  if (filterType === FilterType.include) {
    sets = {
      exclude: sets ? sets.exclude : new Set<string>(),
      include: set,
    };
  } else {
    sets = {
      include: sets ? sets.include : new Set<string>(),
      exclude: set,
    };
  }
  filter.filters.set(key, sets);
}

export function getFilterSets(filter: Filter, key: string) {
  return filter.filters.get(key);
}

function _isIncluded(sets: FilterTypes | undefined, propertyValue: string) {
  return !sets || sets.include.size === 0 || sets.include.has(propertyValue);
}

function _isExcluded(sets: FilterTypes | undefined, propertyValue: string) {
  return sets && sets.exclude.size !== 0 && sets.exclude.has(propertyValue);
}

function _isIncludedExplicitly(
  sets: FilterTypes | undefined,
  propertyValue: string,
) {
  return sets && sets.include.size !== 0 && sets.include.has(propertyValue);
}

function _isExcludedExplicitly(
  sets: FilterTypes | undefined,
  propertyValue: string,
) {
  return sets && sets.exclude.size !== 0 && sets.exclude.has(propertyValue);
}

export function isIn(filter: Filter, propertyFilter: PropertyValue) {
  const sets = filter.filters.get(propertyFilter.key);
  const included = _isIncluded(sets, propertyFilter.value);
  const excluded = _isExcluded(sets, propertyFilter.value);
  return included && !excluded;
}

export function isIncluded(filter: Filter, propertyFilter: PropertyValue) {
  const sets = filter.filters.get(propertyFilter.key);
  return _isIncluded(sets, propertyFilter.value);
}

export function isExcluded(filter: Filter, propertyFilter: PropertyValue) {
  const sets = filter.filters.get(propertyFilter.key);
  return _isExcluded(sets, propertyFilter.value);
}

export function isIncludedExplicitly(
  filter: Filter,
  propertyFilter: PropertyValue,
) {
  const sets = filter.filters.get(propertyFilter.key);
  return _isIncludedExplicitly(sets, propertyFilter.value);
}

export function isExcludedExplicitly(
  filter: Filter,
  propertyFilter: PropertyValue,
) {
  const sets = filter.filters.get(propertyFilter.key);
  return _isExcludedExplicitly(sets, propertyFilter.value);
}

export function get(filter: Filter, propertyFilter: PropertyValue) {
  const sets = filter.filters.get(propertyFilter.key);
  const included = _isIncluded(sets, propertyFilter.value);
  const excluded = _isExcluded(sets, propertyFilter.value);
  return included && !excluded;
}

export function toggleFilter(filter: Filter, propertyFilter: PropertyValue) {
  const included = isIn(filter, propertyFilter);
  if (included) {
    setExcludeOne(filter, propertyFilter);
  } else {
    setIncludeOne(filter, propertyFilter);
  }
}

export function setIncludeOne(
  filter: Filter,
  propertyValue: PropertyValue,
  explicitly?: boolean,
) {
  let sets = getFilterSets(filter, propertyValue.key);
  if (!sets) {
    sets = {
      exclude: new Set<string>(),
      include: new Set<string>([propertyValue.value]),
    };
  } else {
    if (sets.exclude.has(propertyValue.value)) {
      sets.exclude.delete(propertyValue.value);
      if (explicitly) {
        sets.include.add(propertyValue.value);
      }
      // wasExcluded = true;
    } else {
      sets.include.add(propertyValue.value);
    }
  }
  filter.filters.set(propertyValue.key, sets);
}

export function setExcludeOne(
  filter: Filter,
  propertyValue: PropertyValue,
  explicitly?: boolean,
) {
  let sets = getFilterSets(filter, propertyValue.key);
  if (!sets) {
    sets = {
      exclude: new Set<string>([propertyValue.value]),
      include: new Set<string>(),
    };
  } else {
    if (sets.include.has(propertyValue.value)) {
      sets.include.delete(propertyValue.value);
      if (explicitly) {
        sets.exclude.add(propertyValue.value);
      }
    } else {
      sets.exclude.add(propertyValue.value);
    }
  }
  filter.filters.set(propertyValue.key, sets);
}

export function setIncludeMany(filter: Filter, propertyValues: PropertyValues) {
  let sets = getFilterSets(filter, propertyValues.key);
  if (!sets) {
    sets = {
      exclude: new Set<string>(),
      include: new Set<string>(propertyValues.values),
    };
  } else {
    for (let i = 0; i < propertyValues.values.length; ++i) {
      const value = propertyValues.values[i];
      if (sets.exclude.has(value)) {
        sets.exclude.delete(value);
      } else {
        sets.include.add(value);
      }
    }
  }
  filter.filters.set(propertyValues.key, sets);
}

export function setExcludeMany(filter: Filter, propertyValues: PropertyValues) {
  let sets = getFilterSets(filter, propertyValues.key);
  if (!sets) {
    sets = {
      exclude: new Set<string>(propertyValues.values),
      include: new Set<string>(),
    };
  } else {
    // let wasIncluded = false;
    for (let i = 0; i < propertyValues.values.length; ++i) {
      const value = propertyValues.values[i];
      if (sets.include.has(value)) {
        sets.include.delete(value);
      } else {
        sets.exclude.add(value);
      }
    }
  }
  filter.filters.set(propertyValues.key, sets);
}

export function resetFilter(filter: Filter) {
  for (const v of filter.filters) {
    v[1].include.clear();
    v[1].exclude.clear();
  }
}

export function clearFilter(
  filter: Filter,
  key: string,
  filterType: FilterType,
) {
  const sets = filter.filters.get(key);
  if (sets) {
    if (filterType === FilterType.include) {
      sets.include.clear();
    } else {
      sets.exclude.clear();
    }
  }
}
