import * as React from 'react';
import { FavoriteHoldingState } from '~src/data/store/reducers/holding/favorite-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';

import { IconButton, Menu, MenuItem } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { FavoriteHoldingComponent } from './favorite-holding.component';

interface Props {
  favoriteHolding: FavoriteHoldingState | undefined;
  tenantId: string;
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
}

export const HoldingsActionsComponent = React.memo((props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFavoriteHoldingButton = React.useCallback(
    (handleToggleFavoriteHolding: () => void) => {
      const icon = props.favoriteHolding ? (
        <FavoriteIcon />
      ) : (
        <FavoriteBorderIcon />
      );
      return (
        <IconButton aria-label="favorite" onClick={handleToggleFavoriteHolding}>
          {icon}
        </IconButton>
      );
    },
    [props.favoriteHolding],
  );

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <FavoriteHoldingComponent
            tenantId={props.tenantId}
            workspaceId={props.workspaceId}
            projectId={props.projectId}
            render={renderFavoriteHoldingButton}
            holding={props.holding}
            favorited={props.favoriteHolding}
          />
        </MenuItem>
      </Menu>
    </div>
  );
});
