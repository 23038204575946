import moment from 'moment';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';

export interface BaseValuationId {
  holdingId: string;
  budgetId: string;
  id: string;
}

export type ValuationState = {
  id: string;
  date: moment.Moment;
  value: number;
  holdingId: string;
  budgetId: string;
  currencyId: string;
};

export const initialValuationsState = [];

const selectKeyId = (valuation: BaseValuationId) => valuation.budgetId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseValuationId, b: BaseValuationId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseValuationId,
  ValuationState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const valuationsSlice = createBaseRecordSlice(
  'scenario:valuations',
  adapter,
);
export const valuationsSelectors = adapter.selectors;

export const valuationsReducer = valuationsSlice.reducer;
export const valuationsActions = valuationsSlice.actions;
