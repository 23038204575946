import { upsertPrivateEquityFundProjects } from '~src/data/store/modules/workspaces/projects/private-equity-fund-projects/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'privateEquityFundProject';

export interface PrivateEquityFundProject {
  id: string;
  projectId: string;
  privateEquityFundId: string;
}

interface Out {
  header: Array<keyof PrivateEquityFundProject>;
  rows: PrivateEquityFundProject[];
}

const header: Array<keyof PrivateEquityFundProject> = [
  'id',
  'projectId',
  'privateEquityFundId',
];

export const exportHandler =
  (privateEquityFundProjects: PrivateEquityFundProject[]) => (): Out => {
    return {
      header,
      rows: outputPicker(privateEquityFundProjects, header),
    };
  };

export const privateEquityFundProjectExportHandler = (
  data: PrivateEquityFundProject[],
): ExportHandler<PrivateEquityFundProject> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const privateEquityFundProjectImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<PrivateEquityFundProject> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(
        upsertPrivateEquityFundProjects(workspace.tenantId, workspace.id, ts),
      ),
  };
};
