import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { PersonDialogComponent } from './person-dialog.component';
import { PersonFieldsComponent } from './fields.component';
import { updatePeople } from '~src/data/store/modules/people/people/requests';
import { AppDispatch } from '~src/store/store';
import { useDispatch } from 'react-redux';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
  person: PersonState;
}

export const EditPersonComponent = React.memo((props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [personInput, setPersonInput] =
    React.useState<graphqlWorkspaceTypes.UpdatePersonInputType>(() => {
      return {
        id: props.person.id,
        description: props.person.description,
        name: props.person.name,
      };
    });
  const { handleClose } = props;
  const handleSavePerson = React.useCallback(() => {
    return dispatch(
      updatePeople(props.workspace.tenantId, props.workspace.id, [personInput]),
    ).then(() => handleClose());
  }, [
    dispatch,
    handleClose,
    personInput,
    props.workspace.id,
    props.workspace.tenantId,
  ]);

  return (
    <PersonDialogComponent
      title={'Edit person'}
      open={props.open}
      handleClose={props.handleClose}
      handleSavePerson={handleSavePerson}
    >
      {/* {content} */}
      <form>
        <PersonFieldsComponent
          hasIdField={false}
          personInput={personInput}
          setPersonInput={setPersonInput}
        />
      </form>
    </PersonDialogComponent>
  );
});
