import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { CryptoState } from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';
import { useCreateForm } from '~src/domain/workspace/components/asset/types/cryptos/form/use-create-form';

interface Props {
  workspace: WorkspaceState;
  title: string;
  handleClose: () => void;
  assetForm: AssetForm;
  onFormCallbacks: {
    onSuccess: (crypto: CryptoState) => void;
    onFailure: (_error: any) => void;
  };
  createdCallback: (crypto: CryptoState) => void;
}

export const CryptoCreateDialogContentComponent = React.memo((props: Props) => {
  const {
    workspace,
    handleClose,
    createdCallback,
    title,
    assetForm,
    onFormCallbacks,
  } = props;
  const { form, submit } = useCreateForm({
    workspace,
    assetForm,
    onFormCallbacks,
  });
  const handleCreateCrypto = React.useCallback(() => {
    return submit((crypto) => {
      handleClose();
      createdCallback(crypto);
      return Promise.resolve();
    });
  }, [createdCallback, handleClose, submit]);

  return (
    <DialogContentComponent
      title={title}
      handleCancel={handleClose}
      handleOnSave={handleCreateCrypto}
    >
      {form}
    </DialogContentComponent>
  );
});
