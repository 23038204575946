import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { FinancialDataState } from '~src/data/store/reducers/reducers';
import {
  currenciesSelectors,
  CurrencyState,
} from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingData } from '~src/domain/workspace/components/project/holding/form/use-fields.component';

import { RecordState } from '@pladdenico/common';

export type HoldingDataWithCurrencyInfo = HoldingData & {
  currencies: CurrencyState[];
};

export const getCurrencyState = (state: FinancialDataState) =>
  state.finance.currency.currencies;

export const getCurrencies = createSelector(
  (state: FinancialDataState) => getCurrencyState(state),
  (currencies) => {
    return currenciesSelectors.selectAllElements(currencies);
  },
);

export interface NullableCurrencyId {
  id: string | null;
}

export const getCurrencyById = createCachedSelector(
  (state: FinancialDataState) => getCurrencyState(state),
  (_state: FinancialDataState, baseId: NullableCurrencyId) => baseId.id,
  (currencies, id): CurrencyState | undefined => {
    if (id !== null) {
      return currenciesSelectors.selectElementByT(currencies, {
        id,
      });
    }
  },
)({
  keySelector: (_state, baseId) => `${baseId.id}`,
  selectorCreator: createSelector,
});

export const selectCurrencyById = createCachedSelector(
  (state: RecordState<CurrencyState, string>[], _baseId: NullableCurrencyId) =>
    state,
  (_state: RecordState<CurrencyState, string>[], baseId: NullableCurrencyId) =>
    baseId.id,
  (currencies, id): CurrencyState | undefined => {
    if (id !== null) {
      return currenciesSelectors.selectElementByT(currencies, {
        id,
      });
    }
  },
)({
  keySelector: (_state, baseId) => `${baseId.id}`,
  selectorCreator: createSelector,
});
