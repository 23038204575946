import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getFundById } from '~src/data/store/selectors/asset/asset-types/funds/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getFundHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/fund/fund-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useFundHoldingInfo(workspaceId: string, holdingId: string) {
  const fundHolding = useSelector((state: RootState) =>
    getFundHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const fund = useSelector((state: RootState) =>
    getFundById(selectWorkspaceData(state), {
      id: fundHolding ? fundHolding.fundId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: fund ? fund.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    fundHolding,
    asset,
    fund,
  };
}
