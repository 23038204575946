import { MultipleRelationOperationType } from '@pladdenico/models';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createPortfolios } from '~src/data/store/modules/portfolios/portfolios/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { usePortfolioFields } from '~src/domain/workspace/components/project/portfolio/form/use-fields';
import { PortfolioInput } from '~src/domain/workspace/components/project/types/portfolio-project/form/create-form.component';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: PortfolioInput;
}

export const usePortfolioCreate = (props: Props) => {
  const { workspace, inputData } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (_projectId: string, portfolioInput: PortfolioInput) => {
      return dispatch(
        createPortfolios(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            description: portfolioInput.description,
            holdingRelations: portfolioInput.holdingIds?.map((holdingId) => {
              return {
                id: holdingId,
                type: MultipleRelationOperationType.add,
              };
            }),
            name: portfolioInput.name,
            parentPortfolioId: portfolioInput.parentPortfolioId,
            portfoliosIds: portfolioInput.portfoliosIds,
          },
        ]),
      )
        .then((portfolios) => {
          const portfolio = portfolios[0];
          return portfolio;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id],
  );

  const { control, handleSubmit } = useForm<PortfolioInput>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = usePortfolioFields(workspace, control);

  const onSubmit = React.useCallback(
    (
      projectId: string,
      successCallback: (portfolio: PortfolioState) => Promise<void>,
      onFailure: (error: any) => void,
    ): SubmitHandler<PortfolioInput> =>
      (data) => {
        return onSave(projectId, data)
          .then((portfolio) => successCallback(portfolio))
          .catch((err) => onFailure(err));
      },
    [onSave],
  );

  const submit = React.useCallback(
    (
      projectId: string,
      successCallback: (portfolio: PortfolioState) => Promise<void>,
      onFailure: (error: any) => void,
    ) => {
      return handleSubmit(onSubmit(projectId, successCallback, onFailure))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
