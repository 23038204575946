import moment from 'moment';
import numbro from 'numbro';
import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { VpsEditTransactionComponent } from '~src/domain/workspace/components/data/adapters/vps/form/edit-transaction.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { VpsTransactionState } from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';

import EditIcon from '@mui/icons-material/Edit';
import { colors, Grid, IconButton, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  vpsTransaction: VpsTransactionState;
  active: boolean;
  onClick: (ctrlKey: boolean, transaction: VpsTransactionState) => void;
}

export const VpsImportedTransactionComponent = React.memo((props: Props) => {
  const { workspace, projectId, vpsTransaction, active, onClick } = props;
  // const dispatch = useDispatch<AppDispatch>();
  // const dialog = useModal();
  const editDialog = useVisible();

  // const transactionType: TransactionType = React.useMemo(() => {
  //   if (vpsTransaction.transactionType === 'XXX') {
  //     return TransactionType.Sell;
  //   } else {
  //     return TransactionType.Sell;
  //   }
  // }, [vpsTransaction.transactionType]);
  // const initialFormValues: FormValues = React.useMemo(() => {
  //   const holdingConfig: HoldingConfig = getHoldingConfigDeposit();

  //   const holdingInputData = createHoldingInputDataFromConfig(
  //     holdingConfig,
  //     vpsTransaction.date
  //   );
  //   holdingInputData.transferInputData.base.date = vpsTransaction.date;
  //   holdingInputData.valuationInputData.date = vpsTransaction.date;
  //   holdingInputData.transferInputData.base.value = Math.abs(
  //     vpsTransaction.value
  //   );
  //   return {
  //     id: uuid.v1(),
  //     projectId,
  //     title: '',
  //     description: vpsTransaction.security,
  //     date: vpsTransaction.date,
  //     holdings: [
  //       holdingInputData,
  //       // buyHoldingInputData
  //     ],
  //     type: transactionType,
  //     // type: '',
  //   };
  // }, [
  //   transactionType,
  //   vpsTransaction.date,
  //   vpsTransaction.value,
  //   vpsTransaction.security,
  //   projectId,
  // ]);

  // const setHandled = React.useCallback(
  //   (transaction: TransactionState) => {
  //     dispatch(
  //       vpsTransactionsActions.upsertOneElement({
  //         ...vpsTransaction,
  //         handled: true,
  //         transactionId: transaction.id,
  //       })
  //     );
  //   },
  //   [dispatch, vpsTransaction]
  // );

  // const handleSkipped = React.useCallback(() => {
  //   dispatch(
  //     vpsTransactionsActions.upsertOneElement({
  //       ...vpsTransaction,
  //       handled: true,
  //     })
  //   );
  // }, [dispatch, vpsTransaction]);

  // const setUnhandled = React.useCallback(() => {
  //   transactionPrevious();
  // }, [transactionPrevious]);

  // const handleSuccess = React.useCallback(
  //   (transaction: TransactionState) => {
  //     setHandled(transaction);
  //     dialog.close();
  //   },
  //   [dialog, setHandled]
  // );

  const handleEditSuccess = React.useCallback(() => {
    editDialog.close();
  }, [editDialog]);

  const backgroundColor = React.useMemo(() => {
    if (vpsTransaction.handled) {
      if (vpsTransaction.transactionId == null) {
        return colors.deepPurple[50];
      } else if (active) {
        return colors.green[800];
      } else {
        return colors.green[300];
      }
    } else if (active) {
      return colors.purple[500];
    }
    return colors.purple[200];
  }, [active, vpsTransaction.handled, vpsTransaction.transactionId]);

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      onClick={(event) => event && onClick(event.ctrlKey, vpsTransaction)}
      // columnSpacing={1}
    >
      <Grid
        container
        item
        xs={1}
        alignSelf="stretch"
        // justifyContent="center"
        alignItems="center"
        sx={{ pl: 1, color: 'white', backgroundColor }}
      >
        <Typography variant="body2">Vps</Typography>
      </Grid>
      <Grid item xs={1} sx={{ pl: 1 }}>
        <Typography variant="body2">
          {moment(vpsTransaction.date).format('YYYY-MM-DD')}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ pl: 1 }}>
        <Typography variant="body2">{vpsTransaction.ISIN}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ pl: 1 }}>
        <Typography variant="body2">{vpsTransaction.security}</Typography>
      </Grid>
      <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
        <Typography variant="body2" margin={1}>
          {numbro(vpsTransaction.value).format('0,0')}
        </Typography>
      </Grid>
      <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
        <Typography variant="body2" margin={1}>
          {numbro(vpsTransaction.volume).format('0,0')}
        </Typography>
      </Grid>
      <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
        <Typography variant="body2" margin={1}>
          {numbro(vpsTransaction.averagePrice).format('0,0')}
        </Typography>
      </Grid>
      <Grid container item xs={2} sx={{ pl: 1 }}>
        <Typography variant="body2">{vpsTransaction.transactionId}</Typography>
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end">
        {/* <IconButton
            onClick={dialog.open}
            disabled={!active || vpsTransaction.handled}
          >
            <AddCircleIcon />
          </IconButton> */}
        <IconButton
          onClick={editDialog.open}
          disabled={!active || vpsTransaction.handled}
        >
          <EditIcon />
        </IconButton>
        {/* <IconButton
            onClick={setUnhandled}
            disabled={!active || vpsTransaction.handled}
          >
            <SkipPreviousIcon />
          </IconButton>
          <IconButton
            onClick={handleSkipped}
            disabled={!active || vpsTransaction.handled}
          >
            <SkipNextIcon />
          </IconButton> */}
      </Grid>
      {/* <CreateComponent
        workspace={workspace}
        projectId={projectId}
        // holding={holding}
        open={dialog.isShowing}
        handleClose={dialog.close}
        handleSuccess={handleSuccess}
        initialFormValues={initialFormValues}
      /> */}
      <VpsEditTransactionComponent
        workspace={workspace}
        projectId={projectId}
        open={editDialog.isShowing}
        handleClose={editDialog.close}
        handleSuccess={handleEditSuccess}
        transaction={vpsTransaction}
      />
    </Grid>
  );
});
