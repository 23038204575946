import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

export type ReportingState = boolean;

export const initialReportingState = null;

const adapter = new EntityAdapter<boolean>(initialReportingState);

export const ReportingSlice = createBaseSlice('Reporting', adapter);
export const ReportingSelectors = adapter.selectors;

export const ReportingReducer = ReportingSlice.reducer;
export const ReportingActions = ReportingSlice.actions;
