import { combineReducers } from 'redux';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';
import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import {
  commitmentsReducer,
  initialCommitmentsState,
} from '~src/data/store/reducers/holding/commitment/commitments/reducer';
import {
  holdingConfigsReducer,
  initialHoldingConfigState,
} from '~src/data/store/reducers/holding/config/configs/reducer';
import {
  holdingDashboardConfigsReducer,
  initialHoldingDashboardConfigState,
} from '~src/data/store/reducers/holding/config/dashboard-configs/reducer';
import {
  initialTransfersState,
  transfersReducer,
} from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import {
  initialValuationsState,
  valuationsReducer,
} from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { PayloadAction } from '@reduxjs/toolkit';

export const holdingInitialState = {
  holding: null,
  valuations: initialValuationsState,
  transfers: initialTransfersState,
  commitments: initialCommitmentsState,
  configs: initialHoldingConfigState,
  dashboardConfigs: initialHoldingDashboardConfigState,
};

export type Holding = Omit<graphqlWorkspaceTypes.HoldingObj, 'project'> & {
  projectId: string;
};

const adapter = new EntityAdapter<Holding>(null);

export const holdingSlice = createBaseSlice('holding', adapter);
export const holdingSelectors = adapter.selectors;

export const holdingStateReducer = holdingSlice.reducer;
export const holdingActions = holdingSlice.actions;

export const holdingReducer = combineReducers({
  holding: holdingStateReducer,
  valuations: valuationsReducer,
  transfers: transfersReducer,
  commitments: commitmentsReducer,
  configs: holdingConfigsReducer,
  dashboardConfigs: holdingDashboardConfigsReducer,
});

export type HoldingState = ReturnType<typeof holdingReducer>;

export type HoldingsState = { [x: string]: HoldingState };

export type HoldingActionType = 'holding' | 'transfers';

export const holdingsReducer = (
  state: HoldingsState = {},
  action: {
    type: HoldingActionType;
    id: string;
    payload: { action: PayloadAction };
  },
): HoldingsState => {
  const holdingId = action.id;
  const holdingState = state[holdingId];

  // XXX: Missing the use of a holdingActionType
  state[holdingId] = holdingReducer(holdingState, action);
  return state;
};
export const initialHoldingsState = {};

export const holdingSelector = (state: HoldingsState, holdingId: string) =>
  state[holdingId];
