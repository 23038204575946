import React from 'react';
import {
  Control,
  Path,
  // Controller,
  // ControllerFieldState,
  // ControllerRenderProps,
  useController,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { EditHoldingById } from '~src/components/fields/workspace/holding/holding/edit-holding-by-id';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  FormContext,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { HoldingFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/field-config';
import { getFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/get-holding-config';
import { HoldingSubFormMenuComponent } from '~src/domain/workspace/components/project/transaction/form/holding/holding-sub-form-menu.component';
import { ValuationSubFormComponent } from '~src/domain/workspace/components/project/transaction/form/holding/valuation-sub-form.component';
import { TransferFormComponent } from '~src/domain/workspace/components/project/transfer/form/transfer-form.component';
import { useTransferInputData } from '~src/domain/workspace/components/project/transaction/form/transfer/use-transfer-input-data';
import { useDate } from '~src/domain/workspace/components/project/transaction/form/use-date';
import { getLastTransfer } from '~src/utils/finance/transfer';
import { transferCashSign } from '~src/utils/finance/transfer-cash-sign';
import {
  // getLastValuation,
  useLastValuation,
} from '~src/utils/finance/valuation';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  colors,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  deleteHolding?: (index: number) => void;
  control: Control<FormValues, FormContext>;
  setValue: UseFormSetValue<FormValues>;
  index: number;
  date: Date;
}

export const HoldingSubFormComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    deleteHolding,
    control,
    index,
    setValue,
    date,
  } = props;
  const prevDate = React.useRef<Date>();
  const [fieldConfig, setFieldConfig] = React.useState<HoldingFieldConfig>();
  const [editAll, setEditAll] = React.useState<boolean>(false);
  const _date = useDate(date);
  const holdingId: string | null = useWatch({
    control,
    name: `holdings.${index}.holdingId`,
  });
  // const fieldId: string = useWatch({
  //   control,
  //   name: `holdings.${index}.fieldId`,
  // });
  const holding = useSelector((root: RootState) => {
    if (holdingId != null) {
      return getHoldingById(selectWorkspaceData(root), {
        id: holdingId,
        projectId,
      });
    }
  });
  // const { update } = useFieldArray({
  //   name: 'holdings',
  //   control,
  // });

  const transferType = useWatch({
    control,
    name: `holdings.${index}.transferInputData.base.type`,
  }) as TransferType;

  React.useEffect(() => {
    if (holding && transferType) {
      const fieldConfig = getFieldConfig(transferType, holding, editAll);
      if (fieldConfig.transfer?.base?.holding?.visible) {
        fieldConfig.transfer.base.holding.visible = false;
      }
      setFieldConfig(fieldConfig);
    }
  }, [editAll, holding, transferType]);

  const transferValue = useWatch({
    control,
    name: `holdings.${index}.transferInputData.base.value`,
  });

  const transferCurrencyId = useWatch({
    control,
    name: `holdings.${index}.transferInputData.base.currencyId`,
  });

  const lastValuation = useLastValuation(holding?.id);

  React.useEffect(() => {
    let value = transferValue ?? 0;
    if (lastValuation) {
      if (transferValue != null) {
        const sign = transferType
          ? -transferCashSign(transferType as TransferType)
          : 1;
        value = transferValue * sign + (lastValuation.value ?? 0);
        setValue(`holdings.${index}.valuationInputData.value`, value);
      }
      setValue(
        `holdings.${index}.valuationInputData.currencyId`,
        lastValuation.currencyId,
      );
      // }
    } else {
      const sign = transferType
        ? -transferCashSign(transferType as TransferType)
        : 1;
      value *= sign;

      setValue(`holdings.${index}.valuationInputData.value`, value);
      setValue(
        `holdings.${index}.valuationInputData.currencyId`,
        transferCurrencyId,
      );
    }
  }, [
    transferValue,
    transferType,
    setValue,
    index,
    transferCurrencyId,
    lastValuation,
  ]);

  React.useEffect(() => {
    if (holding) {
      const lastTransfer = getLastTransfer(holding);
      if (lastTransfer) {
        setValue(
          `holdings.${index}.transferInputData.base.currencyId`,
          lastTransfer.currencyId,
        );
      }
    }
  }, [holding, index, setValue]);

  React.useEffect(() => {
    if (prevDate.current !== _date) {
      if (_date && fieldConfig?.transfer?.base.date?.disabled) {
        setValue(`holdings.${index}.transferInputData.base.date`, date);
      }
      if (_date && fieldConfig?.valuation?.base.date?.disabled) {
        setValue(`holdings.${index}.valuationInputData.date`, date);
      }
    }
  }, [
    _date,
    date,
    fieldConfig?.transfer?.base.date?.disabled,
    fieldConfig?.valuation?.base.date?.disabled,
    index,
    setValue,
  ]);

  React.useEffect(() => {
    prevDate.current = _date;
  }, [_date]);

  useTransferInputData(workspace, holding, control, setValue, index, date);

  const possibleHoldingTypes = useWatch({
    control,
    name: `holdings.${index}.config.possibleHoldingTypes`,
  });

  // const holdingElement2 = React.useCallback(
  //   (
  //     f: ControllerRenderProps<FormValues, `holdings.${number}.holdingId`>,
  //     fieldState: ControllerFieldState
  //   ) => {
  //     const formHelper = fieldState.error?.type ? (
  //       <FormHelperText error>Holding is required</FormHelperText>
  //     ) : undefined;
  //     return (
  //       <FormControl fullWidth margin="none">
  //         <EditHoldingById
  //           handleValue={(holding) => f.onChange(holding.id)}
  //           projectId={props.projectId}
  //           holdingId={f.value}
  //           holdingTypes={possibleHoldingTypes}
  //           workspace={props.workspace}
  //           label={'Holding'}
  //         />
  //         {formHelper}
  //       </FormControl>
  //     );
  //   },
  //   [possibleHoldingTypes, props.projectId, props.workspace]
  // );

  const { field, fieldState } = useController({
    name: `holdings.${index}.holdingId`,
    control,
    rules: { required: true },
    defaultValue: null,
  });

  const formHelper = fieldState.error?.type ? (
    <FormHelperText error>Holding is required</FormHelperText>
  ) : undefined;
  const holdingElement = (
    <FormControl fullWidth margin="none">
      <EditHoldingById
        handleValue={(holding) => field.onChange(holding.id)}
        projectId={props.projectId}
        holdingId={field.value}
        holdingTypes={possibleHoldingTypes}
        workspace={props.workspace}
        label={'Holding'}
      />
      {formHelper}
    </FormControl>
  );

  const useValuation = useWatch({
    control,
    name: `holdings.${index}.config.useValuation`,
  });

  const deleteIcon = useWatch({
    control,
    name: `holdings.${index}.config.deleteIcon`,
  });

  const title = useWatch({
    control,
    name: `holdings.${index}.config.name`,
  });

  const getName = React.useCallback(
    (name: Path<TransferInputData>): Path<FormValues> => {
      const holdingPrefix = `holdings.${index}` as const;
      const transferDataPrefix = `${holdingPrefix}.transferInputData` as const;
      return `${transferDataPrefix}.${name}`;
    },
    [index],
  );
  const transferTypes = useWatch({
    control,
    name: `holdings.${index}.config.possibleTransferTypes`,
  });

  // if (fieldId == null) {
  //   return <></>;
  // }

  const deleteElement =
    deleteHolding && deleteIcon ? (
      <IconButton
        aria-label="delete"
        onClick={() => deleteHolding && deleteHolding(index)}
      >
        <DeleteIcon />
      </IconButton>
    ) : undefined;

  const valuationElement =
    holdingId != null && useValuation ? (
      <ValuationSubFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        holdingId={holdingId}
        control={control}
        index={index}
        fieldConfig={fieldConfig?.valuation}
      />
    ) : undefined;

  const transferElement = holdingId != null && (
    <TransferFormComponent
      workspace={workspace}
      projectId={props.projectId}
      holdingId={holdingId}
      control={control}
      setValue={setValue}
      fieldConfig={fieldConfig?.transfer}
      getName={getName}
      transferTypes={transferTypes}
    />
  );

  const holdingSubFormElement = (
    <Paper
      sx={{
        backgroundColor: colors.blue[50],
        padding: (theme) => theme.spacing(1),
      }}
    >
      <Grid container>
        <Grid container item xs={8}>
          <Grid item xs={11}>
            {holdingElement}
            {/* <Controller
              name={`holdings.${index}.holdingId`}
              rules={{ required: true }}
              // shouldUnregister={true}
              control={control}
              defaultValue={null}
              render={({ field, fieldState }) =>
                holdingElement(field, fieldState)
              }
            /> */}
            <Grid item xs={1}>
              {deleteElement}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={3} alignContent="center">
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid container item xs={1} alignContent="center">
          <HoldingSubFormMenuComponent
            control={control}
            index={index}
            setValue={setValue}
            editAll={editAll}
            setEditAll={setEditAll}
            deleteHolding={deleteHolding}
          />
        </Grid>
      </Grid>
      {transferElement}
      {valuationElement}
    </Paper>
  );

  return holdingSubFormElement;
});
