import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TypedValue } from '~src/data/store/selectors/finance/valuation/valuation-types';

import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { Button, IconButton, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableActions, TableDrawers, TableField } from '@pladdenico/table';

import { CreateValuationComponent } from './create.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  valuationCreateInputInit: graphqlWorkspaceTypes.CreateValuationInputType;

  fields: TableField.Field<TypedValue>[];

  filters: TableField.Filter<TypedValue>[];
  dispatchFilters: React.Dispatch<TableActions.FilterAction<TypedValue>>;

  sorters: TableField.Sorter<TypedValue>[];
  dispatchSorters: React.Dispatch<TableActions.SorterAction<TypedValue>>;
}

export const ValuationsToolbar = (props: Props) => {
  // const { className, ...rest } = props;
  const [createValuationDialogOpened, setCreateValuationDialogeOpened] =
    React.useState(false);

  const { classes } = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);

  const handleAddValuation = () => {
    setCreateValuationDialogeOpened(true);
  };

  function handleCloseCreateValuationDialog() {
    setCreateValuationDialogeOpened(false);
  }

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const filtersComponent = props.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={props.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });

  const sortersComponent = props.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={props.dispatchSorters}
        sorter={sorter}
      />
    );
  });

  return (
    <div
      // {...rest}
      // className={clsx(classes.root, className)}
      className={classes.root}
    >
      <div className={classes.row}>
        <IconButton onClick={handleFilterDrawerOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton onClick={handleSortDrawerOpen}>
          <SortIcon />
        </IconButton>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddValuation}
        >
          Add valuation
        </Button>
      </div>
      <CreateValuationComponent
        workspace={props.workspace}
        projectId={props.projectId}
        open={createValuationDialogOpened}
        handleClose={handleCloseCreateValuationDialog}
        valuationCreateInputInit={props.valuationCreateInputInit}
      />
      <TableDrawers.FilterDrawerComponent
        open={filterOpen}
        closeDrawer={() => setFilterOpen(false)}
        filtersComponent={<>{filtersComponent}</>}
      />
      <TableDrawers.SorterDrawerComponent
        open={sortOpen}
        closeDrawer={() => setSortOpen(false)}
        sortersComponent={<>{sortersComponent}</>}
      />
    </div>
  );
};
