import moment from 'moment';
import createCachedSelector from 're-reselect';
import { getHoldingProperties } from '~src/data/store/selectors/holding/filter/filter-mapper';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  filterKey: string;
  date: moment.Moment;
}

// interface GroupProps {
//   workspaceId: string;
//   projectId: string;
//   holding: HoldingState;
//   filterKey: string;
//   date: moment.Moment;
// }

export interface HoldingGroup {
  id: string;
  name: string;
  share: number;
}

// const getGroups = createCachedSelector(
//   (state: DataState, _props: GroupProps) => state,
//   (_state: DataState, props: GroupProps) => props.workspaceId,
//   (_state: DataState, props: GroupProps) => props.projectId,
//   (_state: DataState, props: GroupProps) => props.holding,
//   (_state: DataState, props: GroupProps) => props.filterKey,
//   (_state: DataState, props: GroupProps) => props.date,
//   (
//     state,
//     workspaceId,
//     projectId,
//     holding,
//     filterKey,
//     date
//   ): HoldingGroup[] => {
//     return getHoldingProperties(state, {
//       workspaceId,
//       projectId,
//       filterKey,
//       holding,
//       date,
//     });
//     // if (!properties) {
//     // const asset = getAssetByHoldingId(state, holding.id);
//     // if (asset) {
//     //   return [
//     //     {
//     //       id: (asset as any)[filterKey],
//     //       name: (asset as any)[filterKey],
//     //       share: 1.0,
//     //     },
//     //   ];
//     // } else {
//     // return [
//     //   {
//     //     id: 'unknown',
//     //     name: 'unknown',
//     //     share: 1.0,
//     //   },
//     // ];
//     // }
//     // } else {
//     //   return properties;
//     // }
//   }
// )(
//   (_state: DataState, props: GroupProps) =>
//     `${props.filterKey}:${props.holding.id}:${props.date.unix()}`
// );

export const getHoldingGroups = createCachedSelector(
  (state: DataState, _props: Props) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.filterKey,
  (_state: DataState, props: Props) => props.date,
  (state, workspaceId, projectId, holdings, filterKey, date) => {
    return createHoldingGroups(
      state,
      workspaceId,
      projectId,
      holdings,
      filterKey,
      date,
    );
  },
)({
  keySelector: (_state, props) => props.filterKey,
  selectorCreator: makeCreateSpecificSelector(),
});

function createHoldingGroups(
  state: DataState,
  workspaceId: string,
  projectId: string,
  holdings: HoldingState[],
  filterKey: string,
  date: moment.Moment,
): Map<string, { name: string; holdings: HoldingState[] }> {
  const holdingGroups: Map<string, { name: string; holdings: HoldingState[] }> =
    new Map();
  holdings.forEach((holding) => {
    const groups = getHoldingProperties(state, {
      workspaceId,
      projectId,
      holding,
      filterKey,
      date,
    });
    groups.forEach((group) => {
      const groupId = group.id ?? 'unknown';
      if (!holdingGroups.has(groupId)) {
        holdingGroups.set(groupId, { name: group.name, holdings: [] });
      }
      const holdingGroup = holdingGroups.get(groupId);
      if (holdingGroup) {
        holdingGroup.holdings.push(holding);
      }
    });
  });
  return holdingGroups;
}
