import { compact } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  CartesianGrid,
  ErrorBar,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getQuotesByPaperId } from '~src/data/store/selectors/finance/quotes/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';

interface Props {
  paper: PaperState;
}

function useSortedQuotes(quotes: QuoteState[] | undefined) {
  if (!quotes) return [];
  return compact(
    quotes
      ?.sort((a, b) => {
        return a.date.getTime() - b.date.getTime();
      })
      .map((quote) => {
        const value = quote.value;
        const low = quote.low;
        const high = quote.high;
        if (value != null && low != null && high != null) {
          return {
            date: moment(quote.date).unix(),
            value: quote.value,
            errorY: [value - low, value - high],
          };
        }
      }),
  );
}

export const PaperQuotesGraphComponent = (props: Props) => {
  const unsortedQuotes = useSelector((state: RootState) =>
    getQuotesByPaperId(selectFinancialData(state), props.paper.id),
  );
  const data = useSortedQuotes(unsortedQuotes);
  const [showIntervalBar, setShowIntervalBar] = React.useState(false);

  if (!data) {
    return <CircularProgress />;
  }
  if (data.length < 2) {
    return (
      <Typography variant="caption">
        Too few data points for graph ({data.length})
      </Typography>
    );
  }
  const minDate = data[0].date;
  const maxDate = data[data.length - 1].date;
  // console.log(minDate, maxDate);
  return (
    <Grid container sx={{ height: 400 }}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              value={showIntervalBar}
              onClick={() => setShowIntervalBar((value) => !value)}
            />
          }
          label="Show interval bar"
        />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveContainer>
          <LineChart
            // width={730}
            // height={250}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              type="number"
              tickFormatter={(date: number) => {
                // console.log(JSON.stringify(date));
                return moment.unix(date).format('DD.MM.YYYY');
              }}
              domain={[
                moment.unix(minDate).add(-1, 'day').unix(),
                moment.unix(maxDate).add(1, 'day').unix(),
              ]}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false}>
              {showIntervalBar && (
                <ErrorBar
                  dataKey="errorY"
                  width={4}
                  strokeWidth={2}
                  stroke="green"
                  direction="y"
                />
              )}
            </Line>
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};
