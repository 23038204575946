import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import {
  VariableState,
  variablesActions,
} from '~src/data/store/reducers/workspace/projects/scenario/variables/reducer';
import { EditScenarioVariableComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/edit-variable.component';
import { ScenarioVariableInput } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/variable-element-input';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

const initVariable = (): ScenarioVariableInput => {
  return {
    description: '',
    name: '',
    type: 'number',
  };
};

export const useCreateVariableDialogComponent = (scenarioId: string) => {
  const modal = useVisible();

  const [variableInput, setScenarioVariableInput] =
    React.useState<ScenarioVariableInput>(() => initVariable());
  const dispatch = useDispatch<AppDispatch>();

  const saveVariable = React.useCallback(
    (variableInput: ScenarioVariableInput) => {
      const variable: VariableState = {
        ...variableInput,
        id: uuid.v1(),
        scenarioId,
      };
      dispatch(variablesActions.upsertOneElement(variable));
      setScenarioVariableInput(initVariable());
    },
    [dispatch, scenarioId],
  );

  const dialog = (
    <DialogComponent
      dialogProps={{
        open: modal.isShowing,
        onClose: modal.close,
        fullWidth: true,
      }}
    >
      <DialogContentComponent
        title="Create variable"
        handleOnSave={() => {
          if (variableInput) {
            saveVariable(variableInput);
            modal.close();
          }
          return Promise.resolve();
        }}
        handleCancel={modal.close}
      >
        <EditScenarioVariableComponent
          variable={variableInput}
          setVariable={setScenarioVariableInput}
        />
      </DialogContentComponent>
    </DialogComponent>
  );

  return {
    modal,
    dialog,
  };
};
