import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updatePortfolios } from '~src/data/store/modules/portfolios/portfolios/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { usePortfolioFields } from '~src/domain/workspace/components/project/portfolio/form/use-fields';
import { PortfolioInput } from '~src/domain/workspace/components/project/types/portfolio-project/form/create-form.component';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  portfolio: PortfolioState;
  // inputData?: PortfolioInputType;
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  };
  successCallback?: (portfolio: PortfolioState) => Promise<void>;
}

export type PortfolioEdit = ReturnType<typeof usePortfolioEdit>;

export const usePortfolioEdit = (props: Props) => {
  const { workspace, portfolio, callbacks, successCallback } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (portfolioInput: PortfolioInput) => {
      return dispatch(
        updatePortfolios(workspace.tenantId, workspace.id, [
          {
            id: portfolio.id,
            ...portfolioInput,
          },
        ]),
      )
        .then((portfolios) => {
          const portfolio = portfolios[0];
          callbacks?.onSuccess && callbacks.onSuccess();
          return portfolio;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, portfolio.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<PortfolioInput>({
    defaultValues: {
      name: portfolio.name ?? undefined,
    },
  });

  const fields = usePortfolioFields(workspace, control);

  const onSubmit: SubmitHandler<PortfolioInput> = React.useCallback(
    (data) => {
      return onSave(data).then((portfolio) => {
        if (successCallback) {
          return successCallback(portfolio);
        }
      });
    },
    [onSave, successCallback],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields, control };
};
