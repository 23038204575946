import { Control } from 'react-hook-form';
import { InputLoanData } from '~src/domain/workspace/components/asset/types/loans/form/loan-input.data';
import { useNumberField } from '~src/utils/form/fields/number-field';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useLoanFields = (control: Control<InputLoanData, any>) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const descriptionField = useTextField(
    control,
    'description',
    'Description',
    '',
  );
  const interestField = useNumberField(control, 'interest', 'Interest', 0);
  return {
    nameField,
    descriptionField,
    interestField,
  };
};
