import { handleEvents } from '~src/data/store/modules/workspaces/projects/events/handler';
import { eventsActions } from '~src/data/store/reducers/workspace/projects/events/event/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchEvents(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null = null,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetEventsDocument;
    const variables: graphqlWorkspaceTypes.GetEventsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context)
      .then((data) => {
        if (data.getEvents) {
          return handleEvents(dispatch, tenantId, workspaceId, data.getEvents);
        }
        return undefined;
      })
      .catch((err) =>
        console.error(`Error getEvents: ', ${JSON.stringify(err)}`),
      );
  };
}

export function createEvents(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateEventInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateEventsDocument;
    const variables: graphqlWorkspaceTypes.CreateEventsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createEvents) {
        return handleEvents(dispatch, tenantId, workspaceId, data.createEvents);
      }
      throw new Error('Could not create event');
    });
  };
}

export function updateEvents(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateEventInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateEventsDocument;
    const variables: graphqlWorkspaceTypes.UpdateEventsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateEvents) {
        return handleEvents(dispatch, tenantId, workspaceId, data.updateEvents);
      }
      return [];
    });
  };
}

export function upsertEvents(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertEventInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertEventsDocument;
    const variables: graphqlWorkspaceTypes.UpsertEventsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertEvents) {
        return handleEvents(dispatch, tenantId, workspaceId, data.upsertEvents);
      }
      return [];
    });
  };
}

export function handleDeleteEvents(projectId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      eventsActions.removeManyElements(
        ids.map((p) => {
          return {
            projectId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteEvents(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteEventsDocument;
    const variables: graphqlWorkspaceTypes.DeleteEventsMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteEvents) {
        dispatch(handleDeleteEvents(projectId, data.deleteEvents));
      }
      return [];
    });
  };
}
