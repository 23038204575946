import { createSelector } from 'reselect';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { getStockPositionById } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import { getStockTradeByTransferId } from '~src/data/store/selectors/holding/holding-types/stock/stock-trade/selectors';
import {
  PositionTyped,
  TradeTyped,
  StockTransferTyped,
} from '~src/domain/workspace/components/project/transfer/algorithm/transfer-detailed';

interface Props {
  projectId: string;
  transfer: TransferState;
}

export const getStockHoldingTransfersDetailed = createSelector(
  (state: WorkspaceDataState, _props: Props) => state,
  (_state: WorkspaceDataState, props: Props) => props.projectId,
  (_state: WorkspaceDataState, props: Props) => props.transfer,
  (workspaceData, _projectId, transfer): StockTransferTyped | undefined => {
    const stockHolding = getStockHoldingByHoldingId(
      workspaceData,
      transfer.holdingId,
    );
    if (stockHolding != null) {
      const trade = getStockTradeByTransferId(workspaceData, {
        stockHoldingId: stockHolding.id,
        transferId: transfer.id,
      });
      if (trade) {
        const position = getStockPositionById(workspaceData, {
          stockHoldingId: stockHolding.id,
          id: trade.positionId,
        });
        const tradeTyped: TradeTyped = {
          id: trade.id,
          price: trade.sharePrice,
          shares: trade.shares,
        };
        const positionTyped: PositionTyped = {
          id: position?.id ?? '',
          shares: position?.shares ?? 0,
          date: position?.date ?? new Date(),
        };
        const transferTyped: StockTransferTyped = {
          stockHoldingId: stockHolding.id,
          trade: tradeTyped,
          position: positionTyped,
        };
        return transferTyped;
      }
    }
  },
);
