import * as React from 'react';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getStockById } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
// import { getPaperByStock } from '~src/data/store/selectors/finance/quotes/selectors';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import {
  // selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { PaperComponent } from '~src/domain/workspace/components/finance/papers/paper/paper.component';

import { CircularProgress } from '@mui/material';

import { EditDialogComponent } from './form/edit-dialog.component';
import { ViewComponent } from './view.component';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const StockHoldingComponent = React.memo((props: Props) => {
  const stockHolding = useSelector((state: RootState) =>
    getStockHoldingByHoldingId(selectWorkspaceData(state), props.holding.id),
  );
  const stock = useSelector((state: RootState) =>
    getStockById(selectWorkspaceData(state), {
      id: stockHolding ? stockHolding.stockId : '',
      workspaceId: props.workspace.id,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: stock ? stock.assetId : undefined,
      workspaceId: props.workspace.id,
    }),
  );
  const [editing, setEditing] = React.useState(false);

  if (!stockHolding) {
    return <CircularProgress />;
  }

  const holdingElement = (
    <ViewComponent
      workspace={props.workspace}
      holding={props.holding}
      stockHolding={stockHolding}
      projectId={props.projectId}
      asset={asset}
      startEditing={() => setEditing(true)}
      portfolios={props.portfolios}
    />
  );

  const editDialogComponent = (
    <EditDialogComponent
      open={editing}
      handleClose={() => setEditing(false)}
      holding={props.holding}
      stockHolding={stockHolding}
      workspace={props.workspace}
      projectId={props.projectId}
      portfolios={props.portfolios}
    />
  );

  return (
    <>
      {holdingElement}
      {editDialogComponent}
    </>
  );
});
