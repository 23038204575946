import { EntityArrayAdapter } from './array-entity-adapter';
import { createSlice, createReducer } from '../slice';

export function createBaseArraySlice<B, T extends B>(
  domain: string,
  adapter: EntityArrayAdapter<B, T>,
) {
  const slices = {
    addOne: createSlice(domain, 'addOne', adapter.upsertOneElementReducer()),
    addMany: createSlice(
      domain,
      'addMany',
      adapter.upsertManyElementsReducer(),
    ),
    setAll: createSlice(domain, 'setAll', adapter.setAllReducer()),
    removeOne: createSlice(
      domain,
      'removeOne',
      adapter.removeOneElementReducer(),
    ),
    removeMany: createSlice(
      domain,
      'removeMany',
      adapter.removeManyElementsReducer(),
    ),
    clear: createSlice<T[], undefined>(domain, 'clear', adapter.clearReducer()),
    updateOne: createSlice(
      domain,
      'updateOne',
      adapter.upsertOneElementReducer(),
    ),
    updateMany: createSlice(
      domain,
      'updateMany',
      adapter.upsertManyElementsReducer(),
    ),
    // case actionTypes.updateOne: {
    //   if (action.updater) {
    //     console.log(action.type, 'updater');
    //     const id = action.payload as TId;
    //     const t = __OLDsortedFind(state, { id } as any, (t: T) => t.id);
    //     if (t) {
    //       const res = __OLDremoveSortedByIdentity([...state], id);
    //       return __OLDinsertSortedMultiple<T, number | string | undefined>(
    //         res,
    //         { ...action.updater(t) },
    //         transforms
    //       );
    //     }
    //   }
    // }
  };

  const actions = {
    addOne: slices.addOne.action,
    addMany: slices.addMany.action,
    clear: slices.clear.action,
    removeMany: slices.removeMany.action,
    removeOne: slices.removeOne.action,
    setAll: slices.setAll.action,
    updateOne: slices.updateOne.action,
    updateMany: slices.updateMany.action,
  };

  const reducer = createReducer(adapter.initialState, slices);

  return {
    actions,
    reducer,
  };
}

/*
const adapter = new EntityAdapter<number, number, string>(
  [],
  (a: number, b: number) => a - b
);
const baseSlice = createBaseSlice('sdf', adapter);
export const b = baseSlice.actions.addOne(1);
*/
