import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';

import { strcmp } from '@pladdenico/common';

export enum ForecastObjectType {
  holding = 'h',
  variable = 'v',
}

export interface BaseForecastId {
  objectType: ForecastObjectType;
  objectId: string;
  // budgetId: string;
}

export interface ForecastId extends BaseForecastId {
  id: string;
}

export const initialForecastsState = [];

const selectKeyId = (forecast: ForecastId): BaseForecastId => {
  return (({ objectType, objectId }) => ({
    objectType,
    objectId,
    // budgetId,
  }))(forecast);
};

const keyComparator = (a: BaseForecastId, b: BaseForecastId) => {
  const diffObjectType = strcmp(a.objectType, b.objectType);
  if (diffObjectType !== 0) {
    return diffObjectType;
  }
  const diffObjectId = strcmp(a.objectId, b.objectId);
  return diffObjectId;
};
const tComparator = (a: ForecastId, b: ForecastId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  ForecastId,
  ForecastState,
  BaseForecastId
>([], selectKeyId, keyComparator, tComparator);

export const forecastsSlice = createBaseRecordSlice(
  'scenario:forecasts',
  adapter,
);
export const forecastsSelectors = adapter.selectors;

export const forecastsReducer = forecastsSlice.reducer;
export const forecastsActions = forecastsSlice.actions;
