import React from 'react';
import { useSelector } from 'react-redux';
import { BondHoldingState } from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { BondPositionsComponent } from '~src/domain/workspace/components/project/holding/holding-types/bond-holdings/bond-positions/bond-positions.component';
import { HoldingTabs } from '~src/domain/workspace/components/project/portfolio/holdings/tab/holding-tabs';
import {
  PropsTab,
  UseHoldingTabsProps,
  useHoldingTabs,
} from '~src/domain/workspace/components/project/portfolio/holdings/tab/use-holding-tabs';

interface PropsWithBondHolding extends UseHoldingTabsProps {
  bondHolding: BondHoldingState;
}

export const BondHoldingTabsComponent = React.memo(
  (props: UseHoldingTabsProps) => {
    const { holding } = props;
    const bondHolding = useSelector((state: RootState) =>
      getBondHoldingByHoldingId(selectWorkspaceData(state), holding.id),
    );
    if (!bondHolding) {
      return <div>No bond holding for holding {holding.name}</div>;
    }
    return (
      <BondHoldingTabsWithBondHoldingComponent
        {...props}
        bondHolding={bondHolding}
      />
    );
  },
);

const BondHoldingTabsWithBondHoldingComponent = React.memo(
  (props: PropsWithBondHolding) => {
    const {
      bondHolding,
      holding,
      portfolio,
      portfolioProject,
      project,
      tab,
      tabs,
      workspace,
    } = props;
    const specificTab = React.useCallback(
      (props: PropsTab) => {
        if (props.tabName === HoldingTabs.Positions) {
          return (
            <BondPositionsComponent
              workspace={workspace}
              projectId={portfolioProject.projectId}
              holding={holding}
              bondHolding={bondHolding}
            />
          );
        }
      },
      [holding, portfolioProject.projectId, bondHolding, workspace],
    );
    return useHoldingTabs(
      workspace,
      portfolioProject,
      project,
      portfolio,
      holding,
      tabs,
      tab,
      specificTab,
    );
  },
);
