import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createBonds } from '~src/data/store/modules/assets/bonds/requests';
import { BondState } from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputBondData } from '~src/domain/workspace/components/asset/types/bonds/form/bond-input.data';
import { useBondFields } from '~src/domain/workspace/components/asset/types/bonds/form/use-bond-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: InputBondData;
  callbacks?: {
    onSuccess?: (bond: BondState) => void;
    onFailure?: (error: any) => void;
  };
}

export const useCreateBondForm = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (assetId: string, data: InputBondData) => {
      return dispatch(
        createBonds(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            assetId: assetId,
            paperId: data.paperId,
          },
        ]),
      )
        .then((bonds) => {
          const bond = bonds[0];
          callbacks?.onSuccess && callbacks.onSuccess(bond);
          return bond;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<InputBondData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useBondFields(control);

  const onSubmit = React.useCallback(
    (
      assetId: string,
      successCallback: (bond: BondState) => Promise<void>,
    ): SubmitHandler<InputBondData> =>
      (data) => {
        return onSave(assetId, data).then((bond) => successCallback(bond));
      },
    [onSave],
  );

  const submit = React.useCallback(
    (assetId: string, successCallback: (bond: BondState) => Promise<void>) => {
      return handleSubmit(onSubmit(assetId, successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
