import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getWarrantById } from '~src/data/store/selectors/asset/asset-types/warrants/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getWarrantHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/warrant/warrant-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useWarrantHoldingInfo(workspaceId: string, holdingId: string) {
  const warrantHolding = useSelector((state: RootState) =>
    getWarrantHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const warrant = useSelector((state: RootState) =>
    getWarrantById(selectWorkspaceData(state), {
      id: warrantHolding ? warrantHolding.warrantId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: warrant ? warrant.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    warrantHolding,
    asset,
    warrant,
  };
}
