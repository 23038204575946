import { GraphQLError } from 'graphql';

import { TypedDocumentNode } from '@graphql-typed-document-node/core';

export abstract class GraphqlResultHandler<TQuery, TQueryVariables> {
  public abstract pre(
    node: TypedDocumentNode<TQuery, TQueryVariables>,
    variables: TQueryVariables,
  ): void;
  public abstract handleErrors(errors: readonly GraphQLError[]): GraphQLError[];
  public abstract handleSuccess(data: TQuery): TQuery;
  public abstract handleDone(): void;
  public abstract handleEmpty(): Error;
}
