import { upsertLoanHoldings } from '~src/data/store/modules/holdings/loan/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'loanHoldings';

export interface LoanHolding {
  id: string;
  holdingId: string;
  loanId: string;
}

const header: Array<keyof LoanHolding> = ['id', 'holdingId', 'loanId'];

export const loanHoldingExportHandler = (
  data: LoanHolding[],
): ExportHandler<LoanHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const loanHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<LoanHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertLoanHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
