import { compact } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AssetWithDetails } from '~src/data/store/reducers/asset/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getHoldingsAssetsWithDetails,
  HoldingAssetWithDetails,
} from '~src/data/store/selectors/finance/valuation/holdings-assets';
import {
  getHoldingsValuation,
  HoldingValuationWithShare,
} from '~src/data/store/selectors/finance/valuation/holdings-valuation';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useGoalRuleFields } from '~src/hooks/fields/workspace/project/holding/use-goal-rule-fields';
import { BaseValuation } from '~src/utils/finance/base-valuation';
// import { HoldingsToolbar } from '~src/domain/workspace/components/project/holding/holdings-toolbar.component';
// import { useHoldingTableActions } from '~src/hooks/actions/workspace/project/holding/holdings/use-holding-table-actions';
import { Identity } from '~src/utils/interfaces/identity';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  // holdings: HoldingState[];
  // holdingCreateInputInit: HoldingCreateInput;
}

const useStyles = makeStyles()((theme: Theme) => ({
  // root: {
  //   padding: theme.spacing(3),
  // },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export interface GoalRuleData extends Identity {
  holding: HoldingState;
  asset: AssetWithDetails;
  valuation: BaseValuation;
  share: number;
}

function useGoalRuleData(
  valuations: HoldingValuationWithShare[],
  holdingsAssets: HoldingAssetWithDetails[],
): GoalRuleData[] {
  // const quotes = useSelector((state: RootState) =>
  //   getQuotesByStock(selectFinancialData(state), stock)
  // );
  const [data, setData] = React.useState<GoalRuleData[]>([]);
  React.useEffect(
    () =>
      setData(
        compact(
          valuations.map((valuation) => {
            if (!valuation.valuation) {
              return undefined;
            }
            const asset = holdingsAssets.find(
              (v) => v.holding.id === valuation.holding.id,
            );
            if (!asset?.asset) {
              return undefined;
            }
            if (asset.asset.__typename === 'Stock') {
              return {
                id: valuation.holding.id,
                holding: valuation.holding,
                valuation: valuation.valuation,
                asset: asset.asset,
                share: 1.0,
              };
            }
          }),
        ),
      ),
    [holdingsAssets, valuations],
  );
  return data;
}

export const GoalRulesComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );
  const [totalValue, setTotalValue] = React.useState(0);
  const valuations = useSelector((state: RootState) =>
    getHoldingsValuation(selectData(state), {
      date: props.date,
      filter,
      holdings: props.holdings,
      projectId: props.projectId,
      useLiveValue: true,
      workspaceId: props.workspace.id,
      currency: props.currency,
    }),
  );
  const holdingsAssets = useSelector((state: RootState) =>
    getHoldingsAssetsWithDetails(selectData(state), {
      workspaceId: props.workspace.id,
      projectId: props.projectId,
      holdings: props.holdings,
    }),
  );
  // const quotes = useSelector((state: RootState) =>
  //   getQuotes(selectWorkspaceData(state), {
  //     workspaceId: props.workspace.id,
  //     projectId: props.projectId,
  //     holdings: props.holdings,
  //   })
  // );
  const data = useGoalRuleData(valuations, holdingsAssets);

  React.useEffect(() => {
    setTotalValue(
      data.reduce((prev, curr) => {
        return prev + curr.valuation.value;
      }, 0),
    );
  }, [data]);

  const { fields } = useGoalRuleFields(
    props.workspace,
    props.projectId,
    totalValue,
  );

  const [initialSorters] = React.useState<TableField.Sorter<GoalRuleData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'holding', 'desc'),
        TableOperations.createSorterFromFields(fields, 'value', 'desc'),
        TableOperations.createSorterFromFields(fields, 'share', 'desc'),
        TableOperations.createSorterFromFields(fields, 'highest', 'desc'),
        TableOperations.createSorterFromFields(fields, 'newest', 'desc'),
        TableOperations.createSorterFromFields(fields, 'position', 'desc'),
      ]);
    },
  );

  const initialFilters = compact(
    fields.map((field) => {
      if (field.id !== 'menu') {
        return {
          id: field.name,
          name: field.name,
          type: TableField.FilterTypes.string,
          objId: field.id,
          query: '',
          operation: TableOperations.createIncludeFilterOperation(
            field.iteratee,
          ),
        };
      }
    }),
  );

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  return (
    <div className={classes.root}>
      {/* <HoldingsToolbar
        workspace={props.workspace}
        projectId={props.projectId}
        fields={fields}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
      /> */}
      <div className={classes.content}>
        <TableComponent<GoalRuleData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          // handleSetUpdateState={setUpdateState}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
});
