import React from 'react';
import { EditCrypto } from '~src/components/fields/workspace/asset/edit/asset-types/edit-crypto';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { CryptoHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/crypto/create-action';

interface Props {
  workspace: WorkspaceState;
  cryptoHoldingInput: CryptoHoldingInputType;
  setCryptoHoldingInput: (
    cryptoHoldingInput: graphqlWorkspaceTypes.CreateCryptoHoldingInputType,
  ) => void;
}

export const CreateCryptoHoldingFieldsComponent = React.memo((props: Props) => {
  const handleCryptoChange = (cryptoId: string) => {
    props.setCryptoHoldingInput({
      ...props.cryptoHoldingInput,
      cryptoId,
    });
  };

  return (
    <>
      <EditCrypto
        workspace={props.workspace}
        handleValue={handleCryptoChange}
        cryptoId={props.cryptoHoldingInput.cryptoId}
      />
    </>
  );
});
