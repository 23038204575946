import { Divider } from '@mui/material';
import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';

export const usePaperMenu = (
  element: DashboardElementState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);
  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-delete" />,
      deleteMenuItem,
      confirmDeleteElement,
    ];
  }, [confirmDeleteElement, deleteMenuItem, element, updated]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
