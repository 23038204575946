import React from 'react';
import { useSelector } from 'react-redux';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getRegions } from '~src/data/store/selectors/common/regions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import TextField from '@mui/material/TextField';
import { __OLDsortedFind } from '@pladdenico/common';

interface Props {
  handleValue: (region: RegionState) => void;
  regionId: string;
}

export const RegionField = React.memo((props: Props) => {
  const regions = useSelector((state: RootState) =>
    getRegions(selectWorkspaceData(state)),
  );

  const [region, setRegion] = React.useState<RegionState>();
  React.useEffect(() => {
    setRegion(
      __OLDsortedFind(
        regions,
        { id: props.regionId } as RegionState,
        (region) => region.id,
      ),
    );
  }, [props.regionId, regions]);

  return (
    <Autocomplete
      // multiple
      options={regions}
      getOptionLabel={(region: RegionState) => region.name}
      style={{ width: 300 }}
      value={region ? region : null}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: RegionState | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label="Region" fullWidth margin="normal" />
      )}
    />
  );
});
