import * as React from 'react';
import { HoldingDenormalized } from '~src/data/store/selectors/finance/valuation/holdings-assets';

import { TableField } from '@pladdenico/table';

export function useHoldingFields() {
  const fields = React.useMemo(() => {
    return {
      name: {
        id: 'name',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'name',
        field: 'name',
        type: TableField.FieldTypes.string,
        iteratee: (data: HoldingDenormalized) => data.holding.name,
      },
      description: {
        id: 'description',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'description',
        field: 'description',
        type: TableField.FieldTypes.string,
        iteratee: (data: HoldingDenormalized) => data.holding.description,
      },
      type: {
        id: 'type',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'type',
        field: 'type',
        type: TableField.FieldTypes.string,
        iteratee: (data: HoldingDenormalized) => data.holding.type,
      },
      portfolios: {
        id: 'portfolios',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'portfolios',
        field: 'portfolios',
        type: TableField.FieldTypes.string,
        iteratee: (data: HoldingDenormalized) =>
          data.portfolios.map((portfolio) => portfolio.name).join(','),
      },
      valuation: {
        id: 'valuation',
        direction: 'desc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'valuation',
        field: 'valuation',
        type: TableField.FieldTypes.numeric,
        iteratee: (data: HoldingDenormalized) => data.valuation?.value,
      },
    };
  }, []);
  return fields;
}
