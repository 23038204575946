import { compact } from 'lodash';
import { UnpackNestedValue } from 'react-hook-form';
import * as uuid from 'uuid';
import { createStockPositions } from '~src/data/store/modules/holdings/stock/position/requests';
import { createTransactions } from '~src/data/store/modules/holdings/transactions/transactions/requests';
import { createTransfers } from '~src/data/store/modules/holdings/transfers/transfers/requests';
import { createValuations } from '~src/data/store/modules/holdings/valuation/valuations/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FormValues } from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import {
  BondTransferData,
  FundTransferData,
  StockTransferData,
} from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';
import { AppDispatch } from '~src/store/store';

import { groupBy } from '@pladdenico/common';
import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createStockTrades } from '~src/data/store/modules/holdings/stock/trade/requests';
import { createFundPositions } from '~src/data/store/modules/holdings/fund/position/requests';
import { createFundTrades } from '~src/data/store/modules/holdings/fund/trade/requests';
import { createBondPositions } from '~src/data/store/modules/holdings/bond/position/requests';
import { createBondTrades } from '~src/data/store/modules/holdings/bond/trade/requests';

export const handleCreateTransaction = (
  workspace: WorkspaceState,
  projectId: string,
  value: UnpackNestedValue<FormValues>,
) => {
  return (dispatch: AppDispatch) => {
    const transactionId = uuid.v1();
    const transactionInput: graphqlWorkspaceTypes.CreateTransactionInputType = {
      id: transactionId,
      projectId,
      description: value.description,
      title: value.title,
    };
    return dispatch(
      createTransactions(workspace.tenantId, workspace.id, [
        {
          ...transactionInput,
          projectId: projectId,
        },
      ]),
    )
      .then((transactions) => {
        const valuationInputs: graphqlWorkspaceTypes.CreateValuationInputType[] =
          compact(
            value.holdings.map((input) => {
              return input.config?.useValuation && input.holdingId != null
                ? { ...input.valuationInputData, holdingId: input.holdingId }
                : undefined;
            }),
          );
        const valuationPromise = dispatch(
          createValuations(workspace.tenantId, workspace.id, valuationInputs),
        );
        const transferInputs: graphqlWorkspaceTypes.CreateTransferInputType[] =
          compact(
            value.holdings.map((input) => {
              return input.holdingId != null
                ? {
                    ...input.transferInputData.base,
                    transactionId,
                    holdingId: input.holdingId,
                  }
                : undefined;
            }),
          );
        return valuationPromise.then((valuations) =>
          dispatch(
            createTransfers(workspace.tenantId, workspace.id, transferInputs),
          )
            .then((transfers) => {
              console.log('TRANSFERS', transfers);
              const dataInputs = groupBy(
                value.holdings,
                (input) => input.transferInputData.data?.holdingType,
              );
              const dataPromises = [];
              const stockInputsData = dataInputs.get(HoldingType.Stock);
              if (stockInputsData) {
                const inputs = stockInputsData.map(
                  (input) => input.transferInputData.data,
                ) as StockTransferData[];
                dataPromises.push(
                  dispatch(
                    createStockPositions(
                      workspace.tenantId,
                      workspace.id,
                      inputs.map((input) => input.position),
                    ),
                  ).then((stockPositions) => {
                    console.log('stockPositions', stockPositions);
                    // return stockPositions;
                    console.log('inputs', inputs);
                    dispatch(
                      createStockTrades(
                        workspace.tenantId,
                        workspace.id,
                        inputs.map((input) => input.trade),
                      ),
                    ).then((stockTrades) => {
                      console.log('stockTrades', stockTrades);
                      // return stockPositions;
                    });
                  }),
                );
              }
              const fundInputsData = dataInputs.get(HoldingType.Fund);
              if (fundInputsData) {
                const inputs = fundInputsData.map(
                  (input) => input.transferInputData.data,
                ) as FundTransferData[];
                dataPromises.push(
                  dispatch(
                    createFundPositions(
                      workspace.tenantId,
                      workspace.id,
                      inputs.map((input) => input.position),
                    ),
                  ).then((fundPositions) => {
                    console.log('fundPositions', fundPositions);
                    // return fundPositions;
                    console.log('inputs', inputs);
                    dispatch(
                      createFundTrades(
                        workspace.tenantId,
                        workspace.id,
                        inputs.map((input) => input.trade),
                      ),
                    ).then((fundTrades) => {
                      console.log('fundTrades', fundTrades);
                      // return fundPositions;
                    });
                  }),
                );
              }
              const bondInputsData = dataInputs.get(HoldingType.Bond);
              if (bondInputsData) {
                const inputs = bondInputsData.map(
                  (input) => input.transferInputData.data,
                ) as BondTransferData[];
                dataPromises.push(
                  dispatch(
                    createBondPositions(
                      workspace.tenantId,
                      workspace.id,
                      inputs.map((input) => input.position),
                    ),
                  ).then((bondPositions) => {
                    console.log('bondPositions', bondPositions);
                    // return bondPositions;
                    console.log('inputs', inputs);
                    dispatch(
                      createBondTrades(
                        workspace.tenantId,
                        workspace.id,
                        inputs.map((input) => input.trade),
                      ),
                    ).then((bondTrades) => {
                      console.log('bondTrades', bondTrades);
                      // return bondPositions;
                    });
                  }),
                );
              }
              return Promise.all(dataPromises).then(() => {
                return {
                  transactions,
                  transfers,
                  valuations,
                };
              });
            })
            .catch((err) => {
              console.info('FAILED', transferInputs, JSON.stringify(err));
              throw err;
            }),
        );
      })
      .catch((err) => {
        console.info('FAILED', transactionInput, JSON.stringify(err));
        throw err;
      });
  };
};
