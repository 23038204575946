import createCachedSelector from 're-reselect';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getAssetByHoldingId } from '~src/data/store/selectors/asset/assets/selectors';
import { HoldingGroup } from '~src/data/store/selectors/finance/holding-groups';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';
import { createSelector } from 'reselect';

interface AssetByHoldingIdProps {
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
  filterKey: keyof AssetState;
}

export const getAssetHoldingProperties = createCachedSelector(
  (state: DataState, props: AssetByHoldingIdProps) =>
    getAssetByHoldingId(selectWorkspaceDataFromData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      holdingId: props.holding.id,
    }),
  (_state: DataState, props: AssetByHoldingIdProps) => props.filterKey,
  (asset, filterKey): HoldingGroup[] => {
    if (!asset) {
      return [];
    }
    const assetProp = asset[filterKey];
    if (assetProp) {
      if (Array.isArray(assetProp)) {
        return [
          { id: assetProp.join(':'), name: assetProp.join(':'), share: 1 },
        ];
      } else {
        return [{ id: assetProp, name: assetProp, share: 1 }];
      }
    }
    return [];
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${props.projectId}:${props.holding.id}}`,
  selectorCreator: createSelector,
});

export const selectAssetHoldingProperties = createCachedSelector(
  (asset: AssetState, _filterKey: keyof AssetState) => asset,
  (_asset: AssetState, filterKey: keyof AssetState) => filterKey,
  (asset, filterKey): HoldingGroup[] => {
    const assetProp = asset[filterKey];
    if (assetProp) {
      if (Array.isArray(assetProp)) {
        return [
          { id: assetProp.join(':'), name: assetProp.join(':'), share: 1 },
        ];
      } else {
        return [{ id: assetProp, name: assetProp, share: 1 }];
      }
    }
    return [];
  },
)({
  keySelector: (asset, filterKey) => `${asset.id}:${filterKey}`,
  selectorCreator: createSelector,
});
