import React from 'react';
import { SearchInput } from '~src/components/search/search-input';

import { Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CreateUserDialogComponent } from './create-user-dialog.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
}

export const WorkspaceUsersToolbar = (props: Props) => {
  // const { className, ...rest } = props;
  const [createUserDialogOpened, setCreateUserDialogeOpened] =
    React.useState(false);

  const { classes } = useStyles();

  const handleAddUser = () => {
    setCreateUserDialogeOpened(true);
  };

  function handleCloseCreateUserDialog() {
    setCreateUserDialogeOpened(false);
  }

  return (
    <div
      // {...rest}
      // className={clsx(classes.root, className)}
      className={classes.root}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button color="primary" variant="contained" onClick={handleAddUser}>
          Invite user
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search users"
        />
      </div>
      <CreateUserDialogComponent
        workspace={props.workspace}
        open={createUserDialogOpened}
        handleClose={handleCloseCreateUserDialog}
      />
    </div>
  );
};
