import {
  handleAssetObjs,
  handleAssets,
} from '~src/data/store/modules/assets/assets/handler';
import { createQueryWorkspaceContext } from '~src/data/store/modules/query-contexts';
import { assetsActions } from '~src/data/store/reducers/asset/assets/reducer';
import {
  CreateAssetInputType,
  CreateAssetsDocument,
  DeleteAssetInputType,
  DeleteAssetsDocument,
  GetAssetObjsDocument,
  GetAssetsDocument,
  UpdateAssetInputType,
  UpdateAssetsDocument,
  UpsertAssetInputType,
  UpsertAssetsDocument,
} from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export function fetchAndHandleAssets(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  return (dispatch: AppDispatch) => {
    return fetchAssets(tenantId, workspaceId, ids).then((assets) => {
      return handleAssets(dispatch, tenantId, workspaceId, assets, [
        Operation.create,
        Operation.delete,
        Operation.update,
        Operation.upsert,
      ]);
    });
  };
}

export function fetchAssets(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  return investRequest(
    GetAssetsDocument,
    { ids },
    createQueryWorkspaceContext(tenantId, workspaceId),
  ).then((data) => {
    if (data.getAssets) {
      return data.getAssets;
    }
    return [];
  });
}

export function fetchAssetObjs(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    return investRequest(
      GetAssetObjsDocument,
      { ids },
      createQueryWorkspaceContext(tenantId, workspaceId),
    ).then((data) => {
      if (data.getAssetObjs) {
        return handleAssetObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.getAssetObjs,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createAssets(
  tenantId: string,
  workspaceId: string,
  inputs: CreateAssetInputType[],
) {
  return (dispatch: AppDispatch) => {
    return investRequest(
      CreateAssetsDocument,
      { inputs },
      createQueryWorkspaceContext(tenantId, workspaceId),
    ).then((data) => {
      if (data.createAssets) {
        return handleAssetObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.createAssets,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function updateAssets(
  tenantId: string,
  workspaceId: string,
  inputs: UpdateAssetInputType[],
) {
  return (dispatch: AppDispatch) => {
    return investRequest(
      UpdateAssetsDocument,
      { inputs },
      createQueryWorkspaceContext(tenantId, workspaceId),
    ).then((data) => {
      if (data.updateAssets) {
        return handleAssetObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.updateAssets,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function upsertAssets(
  tenantId: string,
  workspaceId: string,
  inputs: UpsertAssetInputType[],
) {
  return (dispatch: AppDispatch) => {
    return investRequest(
      UpsertAssetsDocument,
      { inputs },
      createQueryWorkspaceContext(tenantId, workspaceId),
    ).then((data) => {
      if (data.upsertAssets) {
        return handleAssetObjs(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertAssets,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function handleDeleteAssets(workspaceId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      assetsActions.removeManyElements(
        ids.map((p) => {
          return {
            workspaceId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteAssets(
  tenantId: string,
  workspaceId: string,
  inputs: DeleteAssetInputType[],
) {
  return (dispatch: AppDispatch) => {
    return investRequest(
      DeleteAssetsDocument,
      { inputs },
      createQueryWorkspaceContext(tenantId, workspaceId),
    ).then((data) => {
      if (data.deleteAssets) {
        dispatch(
          assetsActions.removeManyElements(
            data.deleteAssets.map((id) => {
              return { id, workspaceId };
            }),
          ),
        );
      }
      return [];
    });
  };
}
