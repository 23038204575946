import { parsePortfolioConfig } from '~src/data/store/modules/portfolio-configs/portfolio-configs/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  PortfolioDashboardConfigStateVisitor,
  PortfolioDashboardConfigVisitable,
  PortfolioDashboardConfigVisitor,
} from '~src/data/store/visitors/workspace/project/portfolio/config/portfolio-dashboard-config-visitor';
import {
  portfolioConfigsActions,
  PortfolioConfigState,
} from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioConfig } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface PortfolioConfigVisitor {
  visit(portfolioConfig: PortfolioConfigVisitable): void;
  post(): void;
  portfolioDashboardConfigVisitor: PortfolioDashboardConfigVisitor;
}

export class PortfolioConfigVisitable
  implements Visitable<PortfolioConfigVisitor>
{
  constructor(
    private _portfolioId: string,
    private _portfolioConfig: PortfolioConfig,
  ) {}
  public accept(visitor: PortfolioConfigVisitor) {
    if (this._portfolioConfig.portfolioDashboardConfigs) {
      this._portfolioConfig.portfolioDashboardConfigs.forEach(
        (portfolioDashboardConfig) => {
          const portfolioDashboardConfigVisitable =
            new PortfolioDashboardConfigVisitable(
              this._portfolioConfig.id,
              portfolioDashboardConfig,
            );
          portfolioDashboardConfigVisitable.accept(
            visitor.portfolioDashboardConfigVisitor,
          );
        },
      );
    }
    visitor.visit(this);
  }

  public parse(): PortfolioConfigState {
    return parsePortfolioConfig(this._portfolioId, this._portfolioConfig);
  }
}

export class PortfolioConfigStateVisitor implements PortfolioConfigVisitor {
  private _portfolioConfigs: PortfolioConfigState[];
  public portfolioDashboardConfigVisitor: PortfolioDashboardConfigVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._portfolioConfigs = [];
    this.portfolioDashboardConfigVisitor =
      new PortfolioDashboardConfigStateVisitor(
        this._dispatch,
        this._tenantId,
        this._workspaceId,
        this._subscriptions,
      );
  }

  public visit(holding: PortfolioConfigVisitable): void {
    this._portfolioConfigs.push(holding.parse());
  }

  post() {
    this._dispatch(
      portfolioConfigsActions.upsertManyElements(this._portfolioConfigs),
    );
    this.portfolioDashboardConfigVisitor.post();
  }
}
