import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  filesSelectors,
  FileState,
  BaseFileId,
} from '~src/data/store/reducers/file/files/reducer';

const selectMediaState = (state: WorkspaceDataState) => state.media;

export const getFiles = (state: WorkspaceDataState) =>
  filesSelectors.selectAllElements(selectMediaState(state));

export const getFileById = createCachedSelector(
  (state: WorkspaceDataState) => selectMediaState(state),
  (_state: WorkspaceDataState, baseId: BaseFileId) => baseId.id,
  (medias, id): FileState | undefined => {
    return filesSelectors.selectElement(medias, {
      id,
    });
  },
)({
  keySelector: (_state, mediaId) => `${mediaId.id}`,
  selectorCreator: createSelector,
});

interface MultipleIds {
  ids: string[];
}

export const getFilesByIds = createCachedSelector(
  (state: WorkspaceDataState) => selectMediaState(state),
  (_state: WorkspaceDataState, ids: MultipleIds) => ids.ids,
  (medias, ids): FileState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
      };
    });
    return filesSelectors.selectElements(medias, baseIds);
  },
)({
  keySelector: (_state, ids) => ids.ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
