import {
  PortfolioStateVisitor,
  PortfolioVisitable,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-visitor';
import { Operation } from '@pladdenico/portfolio-api';
import { Portfolio } from '~src/services/graphql/workspace/client/graphql';

export function handlePortfolios(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Portfolio[],
  subscriptions: Operation[],
) {
  const portfolioVisitor = new PortfolioStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((portfolio) => {
    const portfolioVisitable = new PortfolioVisitable(workspaceId, portfolio);
    portfolioVisitable.accept(portfolioVisitor);
  });
  return portfolioVisitor.post();
}
