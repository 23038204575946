import * as uuid from 'uuid';
import { createCryptoHoldings } from '~src/data/store/modules/holdings/crypto/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateCryptoHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/crypto-holdings/form/fields.component';
import { AppDispatch } from '~src/store/store';
import { PartialBy } from '~src/utils/common/typescript-utils';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type CryptoHoldingInputType = PartialBy<
  graphqlWorkspaceTypes.CreateCryptoHoldingInputType,
  'cryptoId'
>;

export const createAction = (
  workspace: WorkspaceState,
  input: CryptoHoldingInputType,
  setHoldingDataInput: (data: CryptoHoldingInputType) => void,
) => {
  const fields = (
    <CreateCryptoHoldingFieldsComponent
      workspace={workspace}
      setCryptoHoldingInput={setHoldingDataInput}
      cryptoHoldingInput={input}
    />
  );
  const valid = () => {
    return input.cryptoId !== undefined;
  };

  const action = (holding: HoldingState) => {
    return (dispatch: AppDispatch) => {
      const cryptoId = input.cryptoId;
      if (cryptoId) {
        return dispatch(
          createCryptoHoldings(workspace.tenantId, workspace.id, [
            {
              id: uuid.v1(),
              holdingId: holding.id,
              cryptoId,
            },
          ]),
        );
      }
      throw 'Not valid, need to set cryptoId';
    };
  };
  return { action, valid, fields };
};
