import moment from 'moment';
import { ForecastElement } from './forecast-element';
import { ForecastEvaluation } from './evaluation';
import { compact } from 'lodash';
// import { VariableState } from '~src/data/store/reducers/workspace/projects/scenario/variables/reducer';

export class SequentialForecastEvaluation<Value>
  implements ForecastEvaluation<Value>
{
  constructor(
    // private _variables: VariableState[],
    private _elements: ForecastElement<Value>[],
  ) {}
  evaluate(startDate: moment.Moment, endDate: moment.Moment): Value[] {
    const numberOfDaysInPeriod = endDate.diff(startDate, 'days') + 1;
    const values = new Array<Value>(numberOfDaysInPeriod);
    for (let i = 0; i < numberOfDaysInPeriod; ++i) {
      const date = moment(startDate).add(i, 'day');
      let lastUpdatedIdx = i;

      // for (let j = 0; j < this._variables.length; ++j) {
      //   const element = this._variables[j];
      //   const value = element.evaluate({
      //     date,
      //     values,
      //     idx: lastUpdatedIdx,
      //   });
      //   // console.log('ELEMENT', JSON.stringify(element), value);
      //   if (value != null) {
      //     values[i] = value;
      //     lastUpdatedIdx = i + 1;
      //   }
      // }

      for (let j = 0; j < this._elements.length; ++j) {
        const element = this._elements[j];
        const value = element.evaluate({
          date,
          values,
          idx: lastUpdatedIdx,
        });
        // console.log('ELEMENT', JSON.stringify(element), value);
        if (value != null) {
          values[i] = value;
          lastUpdatedIdx = i + 1;
        }
      }
    }
    return compact(values);
  }
}
