import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

export const useDateField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  label: string,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  onChangeCallback?: (momentDate: moment.Moment | null) => void,
  fieldConfig?: FieldConfig,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<TFieldValues, TName>,
      fieldState: ControllerFieldState,
      disabled?: boolean,
    ) => (
      <DatePicker
        showToolbar={false}
        inputFormat="DD/MM/YYYY"
        label={label}
        value={field.value ?? null}
        onChange={(momentDate: moment.Moment | null) => {
          field.onChange(momentDate?.toDate());
          onChangeCallback && onChangeCallback(momentDate);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            margin="normal"
            {...params}
            fullWidth
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
          />
        )}
      />
    ),
    [label, onChangeCallback],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
