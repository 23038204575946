import React from 'react';
import { DataInput } from '~src/components/xlxs/data-input';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DataImporter, useImport } from '~src/services/xlsx/hooks/use-import';

import { Alert, CircularProgress } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  dataImporter: DataImporter;
}

export const ImportComponent = React.memo((props: Props) => {
  const { importer, importingData, importingStatus, showImportingStatus } =
    useImport(props.workspace, props.dataImporter);

  let importStatusElement;
  if (showImportingStatus) {
    const importStatusElements = importingStatus.map((importStatus, index) => {
      return <p key={index}>{importStatus.status}</p>;
    });

    importStatusElement = (
      <Alert
        severity={
          importingStatus.some((status) => status.error) ? 'error' : 'success'
        }
      >
        {importStatusElements}
        {importingData ? <CircularProgress /> : undefined}
      </Alert>
    );
  }

  return (
    <div>
      <DataInput handleData={importer} />
      {importStatusElement}
    </div>
  );
});
