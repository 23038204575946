import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import BorderColorIcon from '@mui/icons-material/BorderColor';

export enum ColorType {
  text,
  background,
  border,
  paint,
}

export const useColorIcon = (type: ColorType) => {
  if (type === ColorType.text) {
    return <FormatColorTextIcon />;
  } else if (type === ColorType.background) {
    return <FormatColorFillIcon />;
  } else if (type === ColorType.border) {
    return <BorderColorIcon />;
  } else if (type === ColorType.paint) {
    return <FormatPaintIcon />;
  }
};
