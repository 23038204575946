import { combineReducers } from 'redux';
import {
  selectedWorkspaceRolesReducer,
  initialSelectedWorkspaceRolesState,
} from './select-workspace-roles/reducer';
import {
  workspaceRolesReducer,
  initialWorkspaceRolesState,
} from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';

export const workspaceRoleInitialState = {
  workspaceRoles: initialWorkspaceRolesState,
  selectedWorkspaceRole: initialSelectedWorkspaceRolesState,
};

export const workspaceRoleReducer = combineReducers({
  workspaceRoles: workspaceRolesReducer,
  selectedWorkspaceRole: selectedWorkspaceRolesReducer,
});
