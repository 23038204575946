import moment from 'moment';
import * as React from 'react';
import {
  isEntity,
  isPerson,
  OrganizationEntity,
  OrganizationPerson,
} from '~src/data/store/modules/organizations/roles/reducer';
import { Grid, Typography } from '@mui/material';

export const PersonOrEntityComponent = (props: {
  personOrEntity: OrganizationPerson | OrganizationEntity;
}) => {
  const { personOrEntity } = props;
  if (isPerson(personOrEntity)) {
    const person = personOrEntity;
    return (
      <Grid container>
        <Grid item>
          <Typography variant="body2">
            {person.firstName} {person.lastName}
          </Typography>
          <Typography variant="body2">
            {moment(person.birthDate).format('DD/MM/YYYY')}
          </Typography>
          {/* <Typography variant="body2">{person.isDead}</Typography> */}
        </Grid>
      </Grid>
    );
  } else if (isEntity(personOrEntity)) {
    const entity = personOrEntity;
    if (!entity.resigned) {
      return (
        <Grid container>
          <Grid item>
            <Typography variant="body2">{entity.name}</Typography>

            <Typography variant="caption">
              {entity.form.description} ({entity.form.code})
            </Typography>
            <Typography variant="body2">{entity.organizationNumber}</Typography>
            {entity.type && (
              <Typography variant="caption">
                {entity.type?.description} ({entity.type?.code})
              </Typography>
            )}
            {/* <Typography variant="body2">{person.isDead}</Typography> */}
          </Grid>
        </Grid>
      );
    }
  }
};
