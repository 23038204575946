import lunr from 'lunr';

// export namespace search {
export class Index {
  private _idx: lunr.Index | undefined;
  constructor() {
    this._idx = undefined;
  }

  public idx() {
    return this._idx;
  }

  public build(fields: string[], documents: any[]) {
    this._idx = lunr((config: lunr.Builder) => {
      fields.forEach((field) => {
        config.field(field);
      });
      // config.field('title');
      // config.field('body');

      documents.forEach((document) => {
        config.add(document);
      });

      // config.add({
      //   title: 'Twelfth-Night',
      //   body: 'If music be the food of love, play on: Give me excess of it…',
      //   author: 'William Shakespeare',
      //   id: '1',
      // });

      // config.add({
      //   title: 'Twelfth-Night',
      //   body: 'Nico…',
      //   author: 'William Shakespeare',
      //   id: '2',
      // });

      // config.add({
      //   title: 'Twelfth-Night',
      //   body: 'Panie music…',
      //   author: 'William Shakespeare',
      //   id: '3',
      // });

      // config.add({
      //   title: 'Twelfth-Night',
      //   body: 'Natha',
      //   author: 'William Shakespeare',
      //   id: '4',
      // });
      return config.build();
    });
  }
}
// }
