import {
  uniqSorted,
  findSorted,
  intersectionSorted,
  upsertOneSorted,
  unionSorted,
  removeOneSorted,
  removeManySorted,
} from '@pladdenico/common';
import { compact } from 'lodash';

export interface IndexedState<B, T extends B> {
  ts: T[];
  idxs: B[];
}

export interface EntitySelectors<B, T extends B, Id, Idx> {
  selectAllElements: (state: IndexedState<B, T>) => T[];
  selectElementByIdx: (state: IndexedState<B, T>, idx: Idx) => T | undefined;
  selectElementsByIdxs: (
    state: IndexedState<B, T>,
    idx: Idx[],
  ) => T[] | undefined;
  selectElementByI: (state: IndexedState<B, T>, t: Id) => T | undefined;
  selectElementsByIs: (state: IndexedState<B, T>, t: Id[]) => T[];
}

export class EntityIndexedAdapter<B, T extends B, Id, Idx> {
  private _initialState: IndexedState<B, T>;
  private _selectId: (t: B) => Id;
  private _selectIdx: (t: B) => Idx;
  private _iComparator: (a: Id, b: Id) => number;
  private _idxComparator: (a: Idx, b: Idx) => number;
  private _merger: (a: T, b: T) => T;
  private _ensureUniqueness: boolean;
  private _selectors: EntitySelectors<B, T, Id, Idx>;
  constructor(
    initialState: IndexedState<B, T>,
    selectId: (t: B) => Id,
    selectIdx: (t: B) => Idx,
    merger: (a: T, b: T) => T,
    iComparator: (a: Id, b: Id) => number,
    idxComparator: (a: Idx, b: Idx) => number,
    ensureUniqueness = true,
  ) {
    this._initialState = initialState;
    this._selectId = selectId;
    this._selectIdx = selectIdx;
    this._merger = merger;
    this._idxComparator = idxComparator;
    this._iComparator = iComparator;
    this._selectors = {
      selectAllElements: this.selectAllElements,
      selectElementByIdx: this.selectElementByIdx,
      selectElementsByIdxs: this.selectElementsByIdxs,
      selectElementByI: this.selectElementByI,
      selectElementsByIs: this.selectElementsByIs,
    };
    this._ensureUniqueness = ensureUniqueness;
  }

  get initialState() {
    return this._initialState;
  }

  get selectIdx() {
    return this._selectIdx;
  }

  get idxComparator() {
    return this._idxComparator;
  }

  get tComparator() {
    return this._iComparator;
  }

  get selectors(): EntitySelectors<B, T, Id, Idx> {
    return this._selectors;
  }

  // private _sortElements = (ts: B[]) => {
  //   ts.sort(this._tComparator);
  // };

  // private _uniqSorted = (ts: T[]) => {
  //   return uniqSorted(ts, [this._tComparator]);
  // };

  private selectAllElements = (state: IndexedState<B, T>) => {
    return state.ts;
  };

  private _bComparator = (a: B, b: B) => {
    return this._iComparator(this._selectId(a), this._selectId(b));
  };

  private selectElementByIdx = (state: IndexedState<B, T>, idx: Idx) => {
    const b = findSorted(state.idxs, idx, (a, b) => {
      return this.idxComparator(this._selectIdx(a), b);
    }).item;
    if (b !== undefined) {
      return findSorted(state.ts, b, this._bComparator).item;
    }
    return undefined;
  };

  private selectElementsByIdxs = (state: IndexedState<B, T>, idxs: Idx[]) => {
    return compact(
      intersectionSorted(
        state.idxs,
        idxs,
        [
          (a, b) => {
            return this._idxComparator(this._selectIdx(a), b);
          },
        ],
        (a, _b) => a,
      ).map((b) => {
        return findSorted(state.ts, b, this._bComparator).item;
      }),
    );
  };

  private selectElementByI = (state: IndexedState<B, T>, t: Id) => {
    return findSorted(state.ts, t, (a, b) =>
      this._iComparator(this._selectId(a), b),
    ).item;
  };

  private selectElementsByIs = (state: IndexedState<B, T>, ts: Id[]) => {
    return intersectionSorted(
      state.ts,
      ts,
      [(a, b) => this._iComparator(this._selectId(a), b)],
      (a, _b) => a,
    );
  };

  public upsertOneElementReducer = () => {
    return (state: IndexedState<B, T>, action: { payload: T }) => {
      const t = action.payload;
      let idxs = [...state.idxs];
      let ts = [...state.ts];
      idxs = upsertOneSorted(
        idxs,
        t,
        (a, b) => {
          return this._idxComparator(this._selectIdx(a), this._selectIdx(b));
        },
        (a, _b) => a,
        (a, _idx) => a,
      ).items;
      ts = upsertOneSorted(
        ts,
        t,
        this._bComparator,
        this._merger,
        (a) => a,
      ).items;
      return {
        ts,
        idxs,
      };
    };
  };

  public upsertManyElementsReducer = () => {
    return (state: IndexedState<B, T>, action: { payload: T[] }) => {
      let newTs = action.payload;
      newTs = newTs.sort(this._bComparator);
      if (this._ensureUniqueness) {
        newTs = uniqSorted(newTs, [this._bComparator]);
      }
      let ts = [...state.ts];
      ts = unionSorted(
        ts,
        newTs,
        [this._bComparator],
        (_a: T, b: T) => b,
        // this._merger, XXX : should be this one??
        (t: T) => t,
        (t: T) => t,
      );
      newTs = newTs.sort((a, b) => {
        return this._idxComparator(this._selectIdx(a), this._selectIdx(b));
      });
      let idxs = [...state.idxs];
      idxs = unionSorted(
        idxs,
        newTs,
        [
          (a, b) => {
            return this._idxComparator(this._selectIdx(a), this._selectIdx(b));
          },
        ],
        (_a, b) => b,
        (t) => t,
        (t) => t,
      );
      return {
        ts,
        idxs,
      };
    };
  };

  public removeOneElementReducer = () => {
    return (state: IndexedState<B, T>, action: { payload: B }) => {
      let ts = [...state.ts];
      let idxs = [...state.idxs];
      const t = action.payload;
      ts = removeOneSorted(ts, t, this._bComparator).items;
      idxs = removeOneSorted(idxs, t, (a, b) => {
        return this._idxComparator(this._selectIdx(a), this._selectIdx(b));
      }).items;
      return {
        ts,
        idxs,
      };
    };
  };

  public removeManyElementsReducer = () => {
    return (state: IndexedState<B, T>, action: { payload: B[] }) => {
      // const res = [...state];
      let newTs = action.payload;
      newTs = newTs.sort(this._bComparator);
      let ts = [...state.ts];
      let idxs = [...state.idxs];
      ts = removeManySorted(ts, newTs, this._bComparator).items;
      idxs = removeManySorted(idxs, newTs, (a, b) => {
        return this._idxComparator(this._selectIdx(a), this._selectIdx(b));
      }).items;
      return {
        ts,
        idxs,
      };
    };
  };

  public clearReducer = () => {
    return (_state: IndexedState<B, T>, _action: { payload: undefined }) => {
      return {
        ts: [],
        idxs: [],
      };
    };
  };
}
