import { upsertFundHoldings } from '~src/data/store/modules/holdings/fund/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'fundHoldings';

export interface FundHolding {
  id: string;
  holdingId: string;
  fundId: string;
}

const header: Array<keyof FundHolding> = ['id', 'holdingId', 'fundId'];

export const fundHoldingExportHandler = (
  data: FundHolding[],
): ExportHandler<FundHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const fundHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<FundHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertFundHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
