import React from 'react';
import ReactGridLayout from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import { updatePortfolioDashboardConfigs } from '~src/data/store/modules/portfolio-configs/portfolio-dashboard-configs/requests';
import { PortfolioConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useDashboardElements } from '~src/domain/workspace/components/project/portfolio/dashboard/hooks/use-dashboard-element';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function usePortfolioDashboardConfig(
  workspace: WorkspaceState,
  portfolioConfig: PortfolioConfigState,
  portfolioDashboardConfig: PortfolioDashboardConfigState,
) {
  const dispatch = useDispatch<AppDispatch>();

  const { addElements, removeElements, updateElements } =
    useDashboardElements(workspace);

  const layouts = React.useMemo(() => {
    console.log(
      'use portfoliodashboardconfig layouts',
      portfolioDashboardConfig,
      portfolioDashboardConfig.layouts,
    );
    return portfolioDashboardConfig.layouts;
  }, [portfolioDashboardConfig]);

  const updateLayouts = React.useCallback(
    (layouts: ReactGridLayout.Layouts) => {
      console.log('usePortfolioDashboardConfig: updateLayouts', layouts);
      const portfolioDashboardConfigInput: graphqlWorkspaceTypes.UpdatePortfolioDashboardConfigInputType =
        {
          id: portfolioDashboardConfig.id,
          layouts: JSON.stringify(layouts),
        };
      return dispatch(
        updatePortfolioDashboardConfigs(
          workspace.tenantId,
          workspace.id,
          portfolioConfig.id,
          [portfolioDashboardConfigInput],
        ),
      ).then((dashboardConfigs) => {
        const layouts = dashboardConfigs[0].layouts;
        if (layouts) {
          return layouts;
        }
      });
    },
    [
      dispatch,
      portfolioConfig.id,
      portfolioDashboardConfig.id,
      workspace.id,
      workspace.tenantId,
    ],
  );

  const addDashboardElements = React.useCallback(
    (dashboardElements: graphqlWorkspaceTypes.DashboardElement[]) => {
      return addElements(dashboardElements).then((elements) => {
        const portfolioDashboardConfigInput: graphqlWorkspaceTypes.UpdatePortfolioDashboardConfigInputType =
          {
            id: portfolioDashboardConfig.id,
            name: portfolioDashboardConfig.name,
            elementOperations: elements.operations,
            compactType: portfolioDashboardConfig.compactType,
          };
        return dispatch(
          updatePortfolioDashboardConfigs(
            workspace.tenantId,
            workspace.id,
            portfolioConfig.id,
            [portfolioDashboardConfigInput],
          ),
        ).then(() => elements.elements);
      });
    },
    [
      addElements,
      dispatch,
      portfolioConfig.id,
      portfolioDashboardConfig.compactType,
      portfolioDashboardConfig.id,
      portfolioDashboardConfig.name,
      workspace.id,
      workspace.tenantId,
    ],
  );
  const updateDashboardElements = React.useCallback(
    (
      dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
    ) => {
      return updateElements(dashboardElements).then((elements) => elements);
    },
    [updateElements],
  );
  const removeDashboardElements = React.useCallback(
    (dashboardElementIds: string[]) => {
      return removeElements(dashboardElementIds).then((elementOperations) => {
        const portfolioDashboardConfigInput: graphqlWorkspaceTypes.UpdatePortfolioDashboardConfigInputType =
          {
            id: portfolioDashboardConfig.id,
            name: portfolioDashboardConfig.name,
            elementOperations,
          };
        return dispatch(
          updatePortfolioDashboardConfigs(
            workspace.tenantId,
            workspace.id,
            portfolioConfig.id,
            [portfolioDashboardConfigInput],
          ),
        ).then(() => {
          return dashboardElementIds;
        });
      });
    },
    [
      dispatch,
      portfolioConfig.id,
      portfolioDashboardConfig.id,
      portfolioDashboardConfig.name,
      removeElements,
      workspace.id,
      workspace.tenantId,
    ],
  );
  return {
    layouts,
    updateLayouts,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  };
}
