import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { BondHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/bond-holding-card.component';

import { useBondHoldingInfo } from './use-bond-holding-info';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const BondHoldingCardContainer = React.memo((props: Props) => {
  const { bondHolding, bond, asset } = useBondHoldingInfo(
    props.workspace.id,
    props.holding.id,
  );
  const { holding, projectId, portfolios, workspace } = props;

  if (!bondHolding) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <BondHoldingCardComponent
      asset={asset}
      holding={holding}
      portfolios={portfolios}
      projectId={projectId}
      bond={bond}
      bondHolding={bondHolding}
      workspace={workspace}
    />
  );
});
