import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { TextField } from '@mui/material';

export const useNameField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tName: TName,
  defaultValue: PathValue<TFieldValues, Path<TFieldValues>> | undefined,
) => {
  return (
    <Controller
      name={tName}
      rules={{ required: true }}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <TextField
          margin="normal"
          required
          id="name"
          value={field.value}
          label="Name"
          type="text"
          onChange={(event) => field.onChange(event.target.value)}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : null}
        />
      )}
    />
  );
};
