import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateWarrants } from '~src/data/store/modules/assets/warrants/requests';
import { WarrantState } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputWarrantData } from '~src/domain/workspace/components/asset/types/warrants/form/warrant-input.data';
import { useWarrantFields } from '~src/domain/workspace/components/asset/types/warrants/form/use-warrant-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  warrant: WarrantState;
  inputData?: InputWarrantData;
  callbacks?: {
    onSuccess?: (warrants: WarrantState[]) => void;
    onFailure?: (error: any) => void;
  };
}

export const useEditWarrantForm = (props: Props) => {
  const { warrant, workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputWarrantData) => {
      return dispatch(
        updateWarrants(workspace.tenantId, workspace.id, [
          {
            id: warrant.id,
            assetId: data.assetId,
            description: data.description,
          },
        ]),
      )
        .then(
          (warrants) => callbacks?.onSuccess && callbacks.onSuccess(warrants),
        )
        .catch((err) => callbacks?.onFailure && callbacks.onFailure(err));
    },
    [dispatch, workspace.tenantId, workspace.id, warrant.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<InputWarrantData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useWarrantFields(workspace, control);

  const onSubmit: SubmitHandler<InputWarrantData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields };
};
