import { Visitable } from '~src/data/store/visitors/visitable';
import {
  PortfolioStateVisitor,
  PortfolioVisitable,
  PortfolioVisitor,
} from '~src/data/store/visitors/workspace/project/portfolio/portfolio-visitor';
import {
  ProjectObjStateVisitor,
  ProjectObjVisitable,
  ProjectObjVisitor,
} from '~src/data/store/visitors/workspace/project/project-obj-visitor';
import { parsePortfolioProject } from '~src/data/store/modules/workspaces/projects/portfolio-projects/parser';
import {
  portfolioProjectsActions,
  PortfolioProjectState,
} from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import {
  PortfolioProject,
  Project,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { ProjectType } from '@pladdenico/models';
import { Operation } from '@pladdenico/portfolio-api';

export const isPortfolioProject = (
  project: Project,
): project is PortfolioProject => {
  return project.project.type === ProjectType.Portfolio;
};

export interface PortfolioProjectVisitor {
  visit(portfolioProject: PortfolioProjectVisitable): void;
  post(): void;
  projectObjVisitor: ProjectObjVisitor;
  portfolioVisitor: PortfolioVisitor;
}

export class PortfolioProjectVisitable
  implements Visitable<PortfolioProjectVisitor>
{
  constructor(
    private _workspaceId: string,
    private _portfolioProject: PortfolioProject,
  ) {}
  public accept(visitor: PortfolioProjectVisitor) {
    if (this._portfolioProject.portfolio) {
      const portfolioVisitable = new PortfolioVisitable(
        this._workspaceId,
        this._portfolioProject.portfolio,
      );
      portfolioVisitable.accept(visitor.portfolioVisitor);
    }
    if (this._portfolioProject.project) {
      const projectObjVisitable = new ProjectObjVisitable(
        this._workspaceId,
        this._portfolioProject.project,
      );
      projectObjVisitable.accept(visitor.projectObjVisitor);
    }
    visitor.visit(this);
  }

  public parse(): PortfolioProjectState {
    return parsePortfolioProject(this._workspaceId, this._portfolioProject);
  }
}

export class PortfolioProjectStateVisitor implements PortfolioProjectVisitor {
  private _portfolioProjects: PortfolioProjectState[];
  public projectObjVisitor: ProjectObjVisitor;
  public portfolioVisitor: PortfolioVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._portfolioProjects = [];
    this.projectObjVisitor = new ProjectObjStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    this.portfolioVisitor = new PortfolioStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(holding: PortfolioProjectVisitable): void {
    this._portfolioProjects.push(holding.parse());
  }
  post() {
    this._dispatch(
      portfolioProjectsActions.upsertManyElements(this._portfolioProjects),
    );
    this.projectObjVisitor.post();
    this.portfolioVisitor.post();
    return this._portfolioProjects;
  }
}
