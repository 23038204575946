import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseEmailId {
  id: string;
  personId: string;
}

export type EmailState = Omit<
  graphqlWorkspaceTypes.Email,
  'person' | 'workspace'
> & {
  workspaceId: string;
  personId: string;
};

export const initialEmailsState: RecordState<EmailState, string>[] = [];

const selectKeyId = (email: BaseEmailId) => email.personId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseEmailId, b: BaseEmailId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseEmailId, EmailState, string>(
  initialEmailsState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const emailsSlice = createBaseRecordSlice('emails', adapter);
export const emailsSelectors = adapter.selectors;

export const emailsReducer = emailsSlice.reducer;
export const emailsActions = emailsSlice.actions;
export const emailsSlices = emailsSlice.slices;
