import {
  fetchBondTrades,
  handleDeleteBondTrades,
} from '~src/data/store/modules/holdings/bond/trade/requests';
import { BondTradeState } from '~src/data/store/reducers/holding/holding-types/bond/bond-trade/reducer';
import { apis } from '~src/services/request/apis';

import { bondTradePath, Operation } from '@pladdenico/portfolio-api';

export function subscribeToBondTrades(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  trades: BondTradeState[],
  subscriptions: Operation[],
) {
  trades.forEach((trade) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: bondTradePath(tenantId, workspaceId).objectPath(
              trade.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchBondTrades(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: bondTradePath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchBondTrades(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: bondTradePath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteBondTrades(trade.bondHoldingId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      }
    });
  });
}
