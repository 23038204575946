import {
  Link as RouterLink,
  Route,
  Routes,
  useMatches,
} from 'react-router-dom';
import {
  FileBreadcrumbComponent,
  FilesBreadcrumbComponent,
} from '~src/domain/workspace/components/top-bar/breadcrumb/files-breadcrumb';
import { FinanceSettingsBreadcrumbComponent } from '~src/domain/workspace/components/top-bar/breadcrumb/finance-settings-breadcrumb';

import Link, { LinkProps } from '@mui/material/Link';

import {
  AssetBreadcrumbComponent,
  AssetsBreadcrumbComponent,
} from './asset-breadcrumb';
import {
  EntitiesBreadcrumbComponent,
  EntityBreadcrumbComponent,
} from './entity-breadcrumb';
import {
  EventBreadcrumbComponent,
  EventsBreadcrumbComponent,
} from './event-breadcrumb';
import {
  HoldingBreadcrumbComponent,
  HoldingsBreadcrumbComponent,
} from './holding-breadcrumb';
import {
  PaperBreadcrumbComponent,
  PapersBreadcrumbComponent,
} from './paper-breadcrumb';
import {
  PeopleBreadcrumbComponent,
  PersonBreadcrumbComponent,
} from './person-breadcrumb';
import {
  ProjectBreadcrumbComponent,
  ProjectsBreadcrumbComponent,
} from './project-breadcrumb';
import { ProjectEntityBreadcrumbComponent } from './project-entity-breadcrumb';
import {
  ProjectReportBreadcrumbComponent,
  ProjectReportsBreadcrumbComponent,
} from './project-report-breadcrumb';
import {
  ScenarioBreadcrumbComponent,
  ScenariosBreadcrumbComponent,
} from './scenario-breadcrumb';
import { TransactionBreadcrumbComponent } from './transaction-breadcrumb';
// import { TenantBreadcrumbComponent } from './tenant-breadcrumb';
import { WorkspaceBreadcrumbComponent } from './workspace-breadcrumbs';
import {
  MemberBreadcrumbComponent,
  MembersBreadcrumbComponent,
} from '~src/domain/workspace/components/top-bar/breadcrumb/members-breadcrumb';

export const maxItems = 4;

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export function BreadcrumbsComponent() {
  const matches = useMatches();
  console.log(matches);

  return (
    <Routes>
      <Route
        path="projects/:projectId/holdings/:holdingId/*"
        element={<HoldingBreadcrumbComponent />}
      />
      <Route
        path="projects/:projectId/holdings/*"
        element={<HoldingsBreadcrumbComponent last={true} />}
      />
      <Route
        path="projects/:projectId/scenarios/:scenarioId/*"
        element={<ScenarioBreadcrumbComponent />}
      />
      <Route
        path="projects/:projectId/scenarios/*"
        element={<ScenariosBreadcrumbComponent last={true} />}
      />
      <Route
        path="projects/:projectId/reporting/projectReports/:reportId/*"
        element={<ProjectReportBreadcrumbComponent />}
      />
      <Route
        path="projects/:projectId/reporting/*"
        element={<ProjectReportsBreadcrumbComponent last={true} />}
      />
      <Route
        path="projects/:projectId/events/:eventId/*"
        element={<EventBreadcrumbComponent />}
      />
      <Route
        path="projects/:projectId/events/*"
        element={<EventsBreadcrumbComponent last={true} />}
      />
      <Route
        path="projects/:projectId/entities/:entityId/*"
        element={<ProjectEntityBreadcrumbComponent />}
      />
      <Route
        path="projects/:projectId/transactions/:transactionId/*"
        element={<TransactionBreadcrumbComponent />}
      />
      <Route
        path="entities/:entityId/*"
        element={<EntityBreadcrumbComponent />}
      />
      <Route
        path="entities/*"
        element={<EntitiesBreadcrumbComponent last={true} />}
      />
      <Route path="assets/:assetId/*" element={<AssetBreadcrumbComponent />} />
      <Route
        path="assets/*"
        element={<AssetsBreadcrumbComponent last={true} />}
      />
      <Route
        path="people/:personId/*"
        element={<PersonBreadcrumbComponent />}
      />
      <Route
        path="people/*"
        element={<PeopleBreadcrumbComponent last={true} />}
      />
      <Route
        path="projects/:projectId/*"
        element={<ProjectBreadcrumbComponent />}
      />
      <Route
        path="projects/*"
        element={<ProjectsBreadcrumbComponent last={true} />}
      />
      <Route path="files/:fileId/*" element={<FileBreadcrumbComponent />} />
      <Route
        path="files/*"
        element={<FilesBreadcrumbComponent last={true} />}
      />
      <Route
        path="members/:memberId/*"
        element={<MemberBreadcrumbComponent />}
      />
      <Route
        path="members/*"
        element={<MembersBreadcrumbComponent last={true} />}
      />
      <Route
        path="finance/papers/:paperId/*"
        element={<PaperBreadcrumbComponent />}
      />
      <Route
        path="finance/papers/*"
        element={<PapersBreadcrumbComponent last={true} />}
      />
      <Route
        path="finance/settings/*"
        element={<FinanceSettingsBreadcrumbComponent last={true} />}
      />
      <Route path="*" element={<WorkspaceBreadcrumbComponent />} />
      {/* <Route path="/*" element={<TenantBreadcrumbComponent last={true} />} /> */}
    </Routes>
  );
}
