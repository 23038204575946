import { handleTransfers } from '~src/data/store/modules/holdings/transfers/transfers/handler';
import {
  BaseTransferId,
  transfersActions,
} from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function fetchTransfers(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetTransfersDocument;
    const variables: graphqlWorkspaceTypes.GetTransfersQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getTransfers) {
        return handleTransfers(
          dispatch,
          tenantId,
          workspaceId,
          data.getTransfers,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return undefined;
    });
  };
}

export function createTransfers(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateTransferInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateTransfersDocument;
    const variables: graphqlWorkspaceTypes.CreateTransfersMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createTransfers) {
        return handleTransfers(
          dispatch,
          tenantId,
          workspaceId,
          data.createTransfers,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      throw new Error('Could not create transfer');
      // return undefined;
    });
  };
}

export function updateTransfers(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateTransferInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateTransfersDocument;
    const variables: graphqlWorkspaceTypes.UpdateTransfersMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateTransfers) {
        return handleTransfers(
          dispatch,
          tenantId,
          workspaceId,
          data.updateTransfers,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertTransfers(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertTransferInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertTransfersDocument;
    const variables: graphqlWorkspaceTypes.UpsertTransfersMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertTransfers) {
        return handleTransfers(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertTransfers,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteTransfers(ids: BaseTransferId[]) {
  return (dispatch: AppDispatch) => {
    dispatch(transfersActions.removeManyElements(ids));
  };
}

export function deleteTransfers(
  tenantId: string,
  workspaceId: string,
  ids: BaseTransferId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteTransfersDocument;
    const variables: graphqlWorkspaceTypes.DeleteTransfersMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteTransfers) {
        const deletedIds = data.deleteTransfers;
        dispatch(
          transfersActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
