import { parseStockTrade } from '~src/data/store/modules/holdings/stock/trade/parser';
import { subscribeToStockTrades } from '~src/data/store/modules/holdings/stock/trade/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  stockTradesActions,
  StockTradeState,
} from '~src/data/store/reducers/holding/holding-types/stock/stock-trade/reducer';
import { StockTrade } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export interface StockTradeVisitor {
  visit(trade: StockTradeVisitable): StockTradeState;
  post(): void;
}

export class StockTradeVisitable implements Visitable<StockTradeVisitor> {
  constructor(private _trade: StockTrade) {}
  public accept(visitor: StockTradeVisitor) {
    return visitor.visit(this);
  }

  public parse(): StockTradeState {
    return parseStockTrade(this._trade);
  }
}

export class StockTradeStateVisitor implements StockTradeVisitor {
  private _stockTrades: StockTradeState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._stockTrades = [];
  }

  public visit(trade: StockTradeVisitable): StockTradeState {
    const tradeState = trade.parse();
    this._stockTrades.push(tradeState);
    return tradeState;
  }

  post() {
    this._dispatch(
      stockTradesActions.upsertManyElements(this._stockTrades, {
        shouldAutobatch: true,
      }),
    );
    subscribeToStockTrades(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._stockTrades,
      this._subscriptions,
    );
  }
}
