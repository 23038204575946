import { sortBy } from 'lodash';
import { DataImportDataState } from '~src/data/store/reducers/reducers';
import { getNordnetTransactions } from '~src/data/store/selectors/data-import/adapter/nordnet/transactions/selectors';
import { getVpsTransactions } from '~src/data/store/selectors/data-import/adapter/vps/transactions/selectors';
import { DnbTransactionState } from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';
import { NordnetTransactionState } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import { VpsTransactionState } from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';
// import { msInDay } from '~src/utils/common/date';

import { mergeSorted } from '@pladdenico/common';
import { createSelector } from '@reduxjs/toolkit';

import { getSortedDnbTransactions } from './dnb/transactions/selectors';

type IAdapterTransactionType =
  | DnbTransactionState
  | VpsTransactionState
  | NordnetTransactionState;

export type AdapterTransaction =
  | DnbTransactionState
  | VpsTransactionState
  | NordnetTransactionState;

const mergeTransactions = (
  ts1: IAdapterTransactionType[],
  ts2: IAdapterTransactionType[],
) => {
  return mergeSorted(
    ts1,
    ts2,
    [
      (a, b) => {
        return a.date.getTime() - b.date.getTime();
        // const ret =
        //   Math.floor(a.date.getTime() / msInDay) -
        //   Math.floor(b.date.getTime() / msInDay);
        // if (ret !== 0) {
        //   return ret;
        // }
        // return a.value - b.value;
      },
    ],
    (t): AdapterTransaction => {
      return t;
    },
    (t): AdapterTransaction => {
      return t;
    },
  );
};

export const getAdapterTransactions = createSelector(
  (state: DataImportDataState) => getSortedDnbTransactions(state, null),
  (state: DataImportDataState) => getVpsTransactions(state, null),
  (state: DataImportDataState) => getNordnetTransactions(state, null),
  (dnbTransactions, vpsTransactions, nordnetTransactions) => {
    const sortedDnbTransactions = sortBy(dnbTransactions, [
      (a) => a.date.getTime(),
      // (a) => Math.floor(a.date.getTime() / msInDay),
      // (a) => a.value,
    ]);
    const sortedVpsTransactions = sortBy(vpsTransactions, [
      (a) => a.date.getTime(),
      // (a) => Math.floor(a.date.getTime() / msInDay),
      // (a) => a.value,
    ]);
    const sortedNordnetTransactions = sortBy(nordnetTransactions, [
      (a) => a.date.getTime(),
      // (a) => Math.floor(a.date.getTime() / msInDay),
      // (a) => a.value,
    ]);
    const res1 = mergeTransactions(
      sortedDnbTransactions,
      sortedVpsTransactions,
    );
    return mergeTransactions(res1, sortedNordnetTransactions);
  },
);
