import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { fetchTransactions } from '~src/data/store/modules/holdings/transactions/transactions/requests';
import { fetchPrivateEquityFundProjectByProjectId } from '~src/data/store/modules/workspaces/projects/private-equity-fund-projects/requests';
import { contactsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/contacts/reducer';
import { investorsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { setupsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/setups/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { privateEquityFundProjectRequestStatusSelectors } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/fetch/reducer';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPrivateEquityFundById } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getPrivateEquityFundProjectByProjectId } from '~src/data/store/selectors/workspace/projects/private-equity-fund-project/private-equity-fund-projects/selectors';
// import { HoldingRouterWrapper } from '~src/domain/workspace/components/project/private-equity-fund/holdings/root';
import { TransactionRouterWrapper } from '~src/domain/workspace/components/project/transaction/root';
import { privateEquityFundProjectTabPath } from '~src/navigation/paths/workspace/project/private-equity-fund-project/paths';
import { RequestStatuses } from '~src/services/request/request-status';
import { AppDispatch } from '~src/store/store';

import { PrivateEquityFundProjectTabs, TabsComponent } from './tabs.component';
import { usePrivateEquityFundProjectTabs } from './use-tabs';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
}

interface PropsWithPrivateEquityFundProject extends Props {
  privateEquityFundProject: PrivateEquityFundProjectState;
}

interface PropsWithPrivateEquityFundProjectAndPrivateEquityFund
  extends PropsWithPrivateEquityFundProject {
  privateEquityFund: PrivateEquityFundState;
}

export const PrivateEquityFundProjectRouterWrapper = React.memo(
  (props: Props) => {
    const privateEquityFundProject = useSelector((state: RootState) =>
      getPrivateEquityFundProjectByProjectId(selectWorkspaceData(state), {
        workspaceId: props.workspace.id,
        projectId: props.project.id,
      }),
    );

    const privateEquityFundProjectFetchStatus = useSelector(
      (state: RootState) =>
        privateEquityFundProjectRequestStatusSelectors.selectElement(
          selectWorkspaceData(state).workspace.project.privateEquityFundProject
            .privateEquityFundProjectFetch,
          { id: props.project.id },
        ),
    );
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
      setLoading(
        privateEquityFundProjectFetchStatus
          ? privateEquityFundProjectFetchStatus.requestStatus ===
              RequestStatuses.requesting
          : true,
      );
    }, [privateEquityFundProjectFetchStatus]);

    const dispatch = useDispatch<AppDispatch>();
    React.useEffect(() => {
      dispatch(
        fetchPrivateEquityFundProjectByProjectId(
          props.workspace.tenantId,
          props.workspace.id,
          props.project.id,
        ),
      ).then((privateEquityFundProject) => {
        if (privateEquityFundProject) {
          dispatch(
            fetchTransactions(props.workspace.tenantId, props.workspace.id, []),
          );
        }
      });
    }, [
      dispatch,
      props.project.id,
      props.workspace.id,
      props.workspace.tenantId,
    ]);

    if (loading) {
      return <ProgressComponent />;
    } else if (!privateEquityFundProject) {
      return <p>Not a valid privateEquityFundProject id</p>;
    }
    return (
      <PrivateEquityFundProjectRouterWrapperWithPrivateEquityFundProject
        {...props}
        privateEquityFundProject={privateEquityFundProject}
      />
    );
  },
);

const PrivateEquityFundProjectRouterWrapperWithPrivateEquityFund = React.memo(
  (props: PropsWithPrivateEquityFundProjectAndPrivateEquityFund) => {
    return (
      <PrivateEquityFundProjectRouterWrapperWithPrivateEquityFundAndConfig
        workspace={props.workspace}
        project={props.project}
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
      />
    );
  },
);
const PrivateEquityFundProjectRouterWrapperWithPrivateEquityFundAndConfig =
  React.memo((props: PropsWithPrivateEquityFundProjectAndPrivateEquityFund) => {
    const tabs = usePrivateEquityFundProjectTabs();
    // const tab = useFindTab(tabs);
    const { tabId } = useParams<{ tabId: string }>();
    const dispatch = useDispatch<AppDispatch>();
    React.useEffect(() => {
      dispatch(
        setupsActions.upsertOneElement({
          carriedInterestType: 'claw back',
          carry: 2,
          description:
            'Private equity IT fund investing in a lot of cool stuff',
          endDate: new Date(),
          endInvestmentDate: new Date(),
          fundId: props.privateEquityFund.id,
          hurdleRate: 3,
          id: '1',
          managementFee: 4,
          name: 'Private equity IT',
          startDate: new Date(),
        }),
      );
      dispatch(
        investorsActions.upsertOneElement({
          id: '1',
          description: 'family office investor, mostly real estate',
          name: 'Investor 1',
          fundId: props.privateEquityFund.id,
        }),
      );
      dispatch(
        contactsActions.upsertOneElement({
          id: '1',
          name: 'Investor 1',
          investorId: '1',
        }),
      );
    });

    console.log(tabId);

    const tab = tabs.find((t) => t.name === tabId);
    return tab ? (
      <TabsComponent
        workspace={props.workspace}
        project={props.project}
        privateEquityFund={props.privateEquityFund}
        privateEquityFundProject={props.privateEquityFundProject}
        tab={tab}
        tabs={tabs}
      />
    ) : (
      <div>unknown tab</div>
    );
  });

const PrivateEquityFundProjectRouterWrapperWithPrivateEquityFundProject =
  React.memo((props: PropsWithPrivateEquityFundProject) => {
    const privateEquityFund = useSelector((state: RootState) =>
      getPrivateEquityFundById(selectWorkspaceData(state), {
        workspaceId: props.workspace.id,
        id: props.privateEquityFundProject.privateEquityFundId,
      }),
    );

    if (!privateEquityFund) {
      return <p>Not a valid privateEquityFund id</p>;
    } else {
      return (
        <Routes>
          <Route
            path="transactions/:transactionId"
            element={
              <TransactionRouterWrapper
                workspace={props.workspace}
                projectId={props.project.id}
              />
            }
          />
          <Route
            path="*"
            // exact={true}
            element={
              <Navigate
                to={privateEquityFundProjectTabPath(
                  props.workspace.tenantId,
                  props.workspace.id,
                  props.project.id,
                  PrivateEquityFundProjectTabs.Dashboard,
                )}
                replace
              />
            }
          />
          {/* <Route
            path="/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId"
            exact={true}
          >
            <Redirect
              to={privateEquityFundProjectTabPath(
                props.workspace.tenantId,
                props.workspace.id,
                props.project.id,
                PrivateEquityFundProjectTabs.Dashboard
              )}
            />
          </Route> */}
          <Route
            path=":tabId/*"
            element={
              <PrivateEquityFundProjectRouterWrapperWithPrivateEquityFund
                workspace={props.workspace}
                privateEquityFund={privateEquityFund}
                privateEquityFundProject={props.privateEquityFundProject}
                project={props.project}
              />
            }
          />
        </Routes>
      );
    }
  });
