import * as React from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CustomRouterLink } from '~src/domain/workspace/components/drawer/items/custom-router';
import {
  workspaceFinanceBasePath,
  workspaceFinanceRootPath,
} from '~src/navigation/paths/workspace/finance/paths';
import {} from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { drawerListItemButtonSX } from '~src/domain/workspace/components/drawer/items/drawer-list-item-button-sx';

export const drawerWidth = 240;

interface Props {
  tenant: TenantState;
  workspace: WorkspaceState;
}

export const WorkspaceFinanceItemComponent = React.memo((props: Props) => {
  const { tenant, workspace } = props;

  const location = useLocation();
  const match = useMatch({ path: workspaceFinanceBasePath, end: false });
  console.log(location);

  return (
    <ListItemButton
      component={CustomRouterLink}
      selected={match != null}
      to={workspaceFinanceRootPath(tenant.id, workspace.id)}
      sx={drawerListItemButtonSX}
    >
      <ListItemIcon>
        <ShowChartIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={'Finance'} />
    </ListItemButton>
  );
});
