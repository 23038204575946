import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Size } from '~src/utils/common/size';

export function useContainerSize() {
  const [size, setSize] = React.useState<Size>(); // { width: 600, height: 100 }
  const onResize = React.useCallback((width?: number, height?: number) => {
    if (width != null && height != null) {
      setSize({ width, height });
    }
  }, []);
  const { ref } = useResizeDetector({ onResize });
  return { size, setSize, ref };
}
