import * as uuid from 'uuid';

import { Data, GroupedData } from './data';

export function createDataGroups(data: Data[], minValuationPercent: number) {
  data = data.sort((a, b) => {
    return b.value - a.value;
  });
  const totalValue = data.reduce((prev, curr) => {
    return curr.value + prev;
  }, 0);

  const other: GroupedData[] = data
    .filter((value) => {
      return value.value / totalValue < minValuationPercent / 100.0;
    })
    .map((v) => {
      return {
        key: v.key,
        name: v.name,
        value: v.value,
        filteredOut: v.filteredOut,
        ids: [v.id],
        id: v.id,
      };
    });

  const main = data
    .filter((value) => {
      return value.value / totalValue >= minValuationPercent / 100.0;
    })
    .map((v) => {
      return {
        key: v.key,
        name: v.name,
        value: v.value,
        filteredOut: v.filteredOut,
        ids: [v.id],
        id: v.id,
      };
    });

  const spliceOtherGroup = (filteredOut: boolean, other: GroupedData[]) => {
    other = other.filter((o) => o.filteredOut === filteredOut);
    const totalValueOther = other.reduce((currentValue, value) => {
      if (filteredOut === value.filteredOut) {
        return currentValue + value.value;
      }
      return currentValue;
    }, 0);

    if (totalValueOther > 0) {
      const key = uuid.v1();
      main.push({
        key,
        name: filteredOut ? 'Other (filtered out)' : 'Other',
        filteredOut,
        value: other.reduce<number>((prev, curr) => prev + curr.value, 0),
        ids: other.reduce<string[]>((prev, curr) => [...prev, ...curr.ids], []),
        id: '__other__',
      });
    }
  };

  spliceOtherGroup(true, other);
  spliceOtherGroup(false, other);

  return main;
}
