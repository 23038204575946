import { DataImportDataState } from '~src/data/store/reducers/reducers';
import { vpsTransactionsSelectors } from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';

import { createSelector } from '@reduxjs/toolkit';

export const selectVpsTransactions = (state: DataImportDataState) => {
  return state.adapter.vps.vpsTransactions;
};

export const getVpsTransactions = createSelector(
  (state: DataImportDataState, _id: string | null) =>
    selectVpsTransactions(state),
  (_state: DataImportDataState, id: string | null) => id,
  (transactions, id) => {
    if (id !== null) {
      return vpsTransactionsSelectors.selectElementsByKey(transactions, id);
    }
    return vpsTransactionsSelectors.selectAllElements(transactions);
  },
);

export const getVpsAccounts = createSelector(
  (state: DataImportDataState) => selectVpsTransactions(state),
  (transactions) => {
    return vpsTransactionsSelectors
      .selectAllRecords(transactions)
      .map((v) => v.id);
  },
);
