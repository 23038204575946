import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDataInput } from '~src/hooks/actions/workspace/project/holding/use-holding-actions';

import { HoldingType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

// import { CreateStockHoldingFormComponent } from '../../holding/holding-types/stock-holdings/form/create-form.component';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { CreateStockHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/stock-holdings/form/fields.component';
import { CreateFormComponent } from '~src/domain/workspace/components/project/holding/form/create-form.component';
import { CreateBankNoteHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/bank-note-holdings/form/fields.component';
import { CreateLoanHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/loan-holdings/form/fields.component';
import { CreateBondHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/bond-holdings/form/fields.component';
import { CreateWarrantHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/warrant-holdings/form/fields.component';
import { CreateCryptoHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/crypto-holdings/form/fields.component';
import { CreateFundHoldingFieldsComponent } from '~src/domain/workspace/components/project/holding/holding-types/fund-holdings/form/fields.component';
import { StockHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/create-action';

export const useCreateHoldingForm = (
  workspace: WorkspaceState,
  projectId: string,
  holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType,
  setHoldingInput: (
    holding: graphqlWorkspaceTypes.CreateHoldingInputType,
  ) => void,
  holdingDataInput: HoldingDataInput,
  setHoldingDataInput: (data: HoldingDataInput) => void,
  portfolios: PortfolioState[],
  setPortfolios: (portfolios: PortfolioState[]) => void,
  holdingTypes?: HoldingType[],
) => {
  const dataFields = React.useMemo(() => {
    if (holdingInput.type === HoldingType.Stock) {
      const input = holdingDataInput as StockHoldingInputType;
      if (input) {
        return (
          <CreateStockHoldingFieldsComponent
            workspace={workspace}
            setStockHoldingInput={setHoldingDataInput}
            stockHoldingInput={input}
          />
        );
      }
    } else if (holdingInput.type === HoldingType.BankNote) {
      const input =
        holdingDataInput as graphqlWorkspaceTypes.CreateBankNoteHoldingInputType;
      if (input) {
        return (
          <CreateBankNoteHoldingFieldsComponent
            workspace={workspace}
            setBankNoteHoldingInput={setHoldingDataInput}
            bankNoteHoldingInput={input}
          />
        );
      }
    } else if (holdingInput.type === HoldingType.Loan) {
      const input =
        holdingDataInput as graphqlWorkspaceTypes.CreateLoanHoldingInputType;
      if (input) {
        return (
          <CreateLoanHoldingFieldsComponent
            workspace={workspace}
            setLoanHoldingInput={setHoldingDataInput}
            loanHoldingInput={input}
          />
        );
      }
    } else if (holdingInput.type === HoldingType.Bond) {
      const input =
        holdingDataInput as graphqlWorkspaceTypes.CreateBondHoldingInputType;
      if (input) {
        return (
          <CreateBondHoldingFieldsComponent
            workspace={workspace}
            setBondHoldingInput={setHoldingDataInput}
            bondHoldingInput={input}
          />
        );
      }
    } else if (holdingInput.type === HoldingType.Warrant) {
      const input =
        holdingDataInput as graphqlWorkspaceTypes.CreateWarrantHoldingInputType;
      if (input) {
        return (
          <CreateWarrantHoldingFieldsComponent
            workspace={workspace}
            setWarrantHoldingInput={setHoldingDataInput}
            warrantHoldingInput={input}
          />
        );
      }
    } else if (holdingInput.type === HoldingType.Crypto) {
      const input =
        holdingDataInput as graphqlWorkspaceTypes.CreateCryptoHoldingInputType;
      if (input) {
        return (
          <CreateCryptoHoldingFieldsComponent
            workspace={workspace}
            setCryptoHoldingInput={setHoldingDataInput}
            cryptoHoldingInput={input}
          />
        );
      }
    } else if (holdingInput.type === HoldingType.Fund) {
      const input =
        holdingDataInput as graphqlWorkspaceTypes.CreateFundHoldingInputType;
      if (input) {
        return (
          <CreateFundHoldingFieldsComponent
            workspace={workspace}
            setFundHoldingInput={setHoldingDataInput}
            fundHoldingInput={input}
          />
        );
      }
      // } else if (holdingInput.type === HoldingType.PrivateEquityFund) {
      //   return <div>Missing for Private Equity Fund</div>;
    } else {
      return <></>;
    }
  }, [holdingInput, holdingDataInput, workspace, setHoldingDataInput]);

  const content = React.useMemo(() => {
    return (
      <CreateFormComponent
        holdingInput={holdingInput}
        setPortfolios={setPortfolios}
        portfolios={portfolios}
        projectId={projectId}
        holdingTypeFieldsComponent={dataFields}
        setHoldingInput={setHoldingInput}
        workspace={workspace}
        holdingTypes={holdingTypes}
      />
    );
  }, [
    dataFields,
    holdingInput,
    holdingTypes,
    portfolios,
    projectId,
    setHoldingInput,
    setPortfolios,
    workspace,
  ]);

  return {
    content,
  };
};
