import React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EditDialogComponent } from '~src/domain/workspace/components/file/file/edit-dialog-content.component';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  file: FileState;
  isShowing: boolean;
  handleClose: () => void;
  updateFileCallback?: (file: FileState) => Promise<void>;
}

export const EditFileComponent = React.memo((props: Props) => {
  const { workspace, file, isShowing, handleClose, updateFileCallback } = props;
  const [fileInput, setFileInput] =
    React.useState<graphqlWorkspaceTypes.UpdateFileInputType>({
      id: file.id,
      name: file.name,
      description: file.description,
    });

  React.useEffect(() => {
    setFileInput({
      id: file.id,
      name: file.name,
      description: file.description,
    });
  }, [file]);

  // const dispatch = useDispatch<AppDispatch>();

  const handleUpdateFile = React.useCallback(
    (file: FileState) => {
      // return dispatch(
      //   updateFiles(workspace.tenantId, workspace.id, [fileInput]),
      // ).then((files) => {
      //   const file = files[0];
      console.log(file);
      if (updateFileCallback) {
        console.log('updateFileCallback', file);
        return updateFileCallback(file).then(() => file);
      }
      return Promise.resolve(file);
      // });
    },
    [updateFileCallback],
  );
  return (
    <DialogComponent
      dialogProps={{ open: isShowing, onClose: handleClose, fullWidth: true }}
    >
      <EditDialogComponent
        workspace={workspace}
        handleClose={handleClose}
        fileInput={fileInput}
        setFileInput={setFileInput}
        title="Edit file"
        handleUpdateFile={handleUpdateFile}
      />
    </DialogComponent>
  );
});
