import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import {
  ValuationFieldConfig,
  ValuationFieldDataConfig,
} from '~src/domain/workspace/components/project/transaction/form/fields/valuation-field-config';
import { HoldingType } from '@pladdenico/models';

export const getBuyValuationFieldConfig = (
  holding: HoldingState,
  editAll: boolean,
): ValuationFieldConfig => {
  let valuationData: ValuationFieldDataConfig | undefined;
  if (holding.type === HoldingType.Stock) {
    valuationData = {
      stock: {
        position: { disabled: !editAll, visible: true },
      },
    };
  } else if (holding.type === HoldingType.Bond) {
    valuationData = {
      bond: {
        position: { disabled: !editAll, visible: true },
      },
    };
  } else if (holding.type === HoldingType.Fund) {
    valuationData = {
      fund: {
        position: { disabled: !editAll, visible: true },
      },
    };
  }
  return {
    base: {
      currency: {
        visible: true,
        disabled: !editAll,
      },
      date: {
        visible: true,
        disabled: !editAll,
      },
      value: {
        visible: true,
        disabled: !editAll,
      },
    },
    data: valuationData,
  };
};
