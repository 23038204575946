import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, RouteObject, useParams } from 'react-router-dom';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { selectTenant } from '~src/data/store/modules/tenant/tenants/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectTenantData } from '~src/data/store/selectors/selectors';
import { getTenantById } from '~src/data/store/selectors/tenant/tenants/selectors';
import { MainComponent } from '~src/domain/tenant/app/main.component';
import { TenantWrapperWithTab } from '~src/domain/tenant/app/tenant/tenant-wrapper-with-tab';
import { FinanceTabWrapper } from '~src/domain/workspace/components/finance/tab-wrapper';
import { TenantTabs } from '~src/domain/tenant/components/tab/tenant-tabs.component';
import { WorkspaceRouterWrapper } from '~src/domain/workspace/app/root';
import { GraphQLComponent } from '~src/domain/workspace/components/graph-ql/graph-ql.component';
import { tenantTabPath } from '~src/navigation/paths/tenant/paths';
import { AppDispatch } from '~src/store/store';

const TenantRoot = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (tenantId != null) {
      dispatch(selectTenant(tenantId));
    }
  });

  return (
    <MainComponent>
      <Outlet />
    </MainComponent>
  );
};

const RedirectToBase = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  if (tenantId != null) {
    return (
      <Navigate to={tenantTabPath(tenantId, TenantTabs.Workspaces)} replace />
    );
  }
  return <ProgressComponent />;
};

// const RedirectToFinanceBase = () => {
//   const { tenantId } = useParams<{ tenantId: string }>();
//   if (tenantId != null) {
//     return (
//       <Navigate
//         to={tenantFinanceTabPath(tenantId, FinanceTabs.Papers)}
//         replace
//       />
//     );
//   }
//   return <ProgressComponent />;
// };

const TenantTab = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const tenant = useSelector((state: RootState) =>
    getTenantById(selectTenantData(state), tenantId ? tenantId : null),
  );
  if (tenant != null) {
    return <TenantWrapperWithTab tenant={tenant} />;
  }
  return <ProgressComponent />;
};

export const useTenantRoot = (): RouteObject[] => {
  const routes = React.useMemo(() => {
    return [
      {
        path: '/tenants/:tenantId/*',
        Component: TenantRoot,
        children: [
          {
            path: '*',
            element: <RedirectToBase />,
          },
          // {
          //   path: 'finance/*',
          //   element: <RedirectToFinanceBase />,
          // },
          {
            path: 'finance/:tabId/*',
            element: <FinanceTabWrapper />,
          },
          {
            path: 'graph-ql/*',
            element: <GraphQLComponent />,
          },
          {},
          // {
          //   path: 'people/:personId/*',
          //   element: <PersonRouterWrapper />,
          // },
          {
            path: ':tabId/*',
            element: <TenantTab />,
          },
        ],
      },
      {
        path: '/tenants/:tenantId/workspaces/:workspaceId/*',
        element: <WorkspaceRouterWrapper />,
      },
    ];
  }, []);

  return routes;
};
