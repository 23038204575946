import { memo } from 'react';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectReportFormComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/project-report-form.component';

interface Props {
  workspace: WorkspaceState;
  projectReport: ProjectReportState;
}

export const EditProjectReportComponent = memo((props: Props) => {
  const { workspace, projectReport } = props;

  return (
    <ProjectReportFormComponent
      workspace={workspace}
      projectReport={projectReport}
      inputData={{
        name: projectReport.name ?? '',
        published: projectReport.published ?? false,
        periodStart: projectReport.periodStart ?? new Date(),
        periodEnd: projectReport.periodEnd ?? new Date(),
        compactType: projectReport.compactType,
      }}
    />
  );
});
