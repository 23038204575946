import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updateHoldingConfigs } from '~src/data/store/modules/holdings/configs/configs/requests';
import { createHoldingDashboardConfigs } from '~src/data/store/modules/holdings/configs/dashboard-configs/requests';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useNameField } from '~src/domain/workspace/components/project/holding/config/use-name-field.component';
import { useEditCompactTypeField } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/edit-compact-type-field';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';

type InputData = {
  id: string;
  name: string;
  compactType: CompactType;
  elementOperations: graphqlWorkspaceTypes.MultipleRelationOperationStringInputType[];
  layouts: string;
};

interface Props {
  handleValue?: (t: HoldingDashboardConfigState) => void;
  workspace: WorkspaceState;
  projectId: string;
  holdingConfig: HoldingConfigState;
}
const defaultValues: InputData = {
  id: '',
  elementOperations: [],
  name: '',
  layouts: '{}',
  compactType: 'vertical',
};
export const useCreateDialog = (props: Props) => {
  const { workspace, holdingConfig } = props;
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { control, handleSubmit, reset } = useForm<InputData>({
    defaultValues,
  });

  const initialize = React.useCallback(
    (id: string, name: string) => {
      reset({
        ...defaultValues,
        id,
        name,
      });
    },
    [reset],
  );

  const onSave = React.useCallback(
    (data: InputData) => {
      return dispatch(
        createHoldingDashboardConfigs(
          workspace.tenantId,
          workspace.id,
          holdingConfig.id,
          [data],
        ),
      ).then((configs) => {
        const config = configs[0];
        const holdingConfigInput: graphqlWorkspaceTypes.UpdateHoldingConfigInputType =
          {
            id: holdingConfig.id,
            currentDashboardConfigId: config.id,
            holdingDashboardConfigOperations: [
              {
                type: MultipleRelationOperationType.add,
                id: config.id,
              },
            ],
          };
        return dispatch(
          updateHoldingConfigs(
            workspace.tenantId,
            workspace.id,
            holdingConfig.holdingId,
            [holdingConfigInput],
          ),
        ).then(() => config);
      });
    },
    [
      dispatch,
      holdingConfig.holdingId,
      holdingConfig.id,
      workspace.id,
      workspace.tenantId,
    ],
  );

  const onSubmit: SubmitHandler<InputData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  const nameField = useNameField(control, 'name', '');
  const compactTypeField = useEditCompactTypeField(
    control,
    'compactType',
    'vertical',
  );
  const content = React.useMemo(() => {
    return (
      <form noValidate autoComplete="off">
        {nameField}
        {compactTypeField}
      </form>
    );
  }, [compactTypeField, nameField]);

  const dialogElement = React.useMemo(() => {
    return (
      <DialogComponent
        dialogProps={{ open, onClose: () => setOpen(false), fullWidth: true }}
      >
        <DialogContentComponent
          handleCancel={() => setOpen(false)}
          handleOnSave={() => {
            return handleSubmit(onSubmit)();
          }}
          title={'Dashboard config'}
        >
          {content}
        </DialogContentComponent>
      </DialogComponent>
    );
  }, [content, handleSubmit, onSubmit, open]);

  return { dialogElement, setOpen, setInput: initialize };
};
