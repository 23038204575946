import React, { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TopBarDesktopComponent } from '~src/domain/portal/components/top-bar/top-bar.desktop.component';
import { TopBarMobileComponent } from '~src/domain/portal/components/top-bar/top-bar.mobile.component';

import CssBaseline from '@mui/material/CssBaseline';
import { Theme } from '@mui/material/styles';

import {
  TopBarComponent,
  useToolbarStyles,
} from '../../../components/top-bar/top-bar.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
  },

  content: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100vh',
    // padding: theme.spacing(3),
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...(theme.mixins.toolbar as CSSProperties),
  },
}));

export const MainComponent = React.memo(
  (props: React.PropsWithChildren<unknown>) => {
    const { classes } = useStyles();
    const styles = useToolbarStyles();

    const desktopTopBar = React.useMemo(() => {
      return <TopBarDesktopComponent />;
    }, []);

    const mobileTopBar = React.useMemo(() => {
      return <TopBarMobileComponent />;
    }, []);
    return (
      <div className={classes.root}>
        <CssBaseline />
        <TopBarComponent
          desktopTopBar={desktopTopBar}
          mobileTopBar={mobileTopBar}
        />
        <main className={classes.content}>
          <div className={styles.classes.toolbar} />
          {props.children}
        </main>
      </div>
    );
  },
);
