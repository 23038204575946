// import data from './data.json';
import { SimulationLinkDatum, SimulationNodeDatum } from 'd3';
import * as d3 from 'd3';
import { wrap } from '~src/domain/workspace/components/people/d3/utils';

export interface Node extends SimulationNodeDatum {
  id: string;
  name?: string;
  group: string;
  radius?: number | undefined;
  citing_patents_count?: number | undefined;
}

export interface Link extends SimulationLinkDatum<Node> {
  id: string;
  description: string;
  value: number;
}

// const a: Link = {};

export interface PeopleGraphData {
  links: Link[];
  nodes: Node[];
}

export const peopleGraph = (
  // data: PeopleGraphData,
  radius: number,
  width: number,
  height: number,
  onClick?: (nodeId: string, group: string) => void,
  // activeNodeId: string | null = null
) => {
  // Specify the dimensions of the chart.

  // Specify the color scale.
  const color = d3.scaleOrdinal(d3.schemeCategory10);

  // The force simulation mutates links and nodes, so create a copy
  // so that re-evaluating this cell produces the same result.
  let links: Link[] = [];
  let nodes: Node[] = [];

  function boxingForce() {
    for (const node of nodes) {
      // Of the positions exceed the box, set them to the boundary position.
      // You may want to include your nodes width to not overlap with the box.
      if (node.x != null) {
        node.x = Math.max(
          -width / 2 + radius,
          Math.min(width / 2 - radius, node.x),
        );
      }
      if (node.y != null) {
        node.y = Math.max(
          -height / 2 + radius,
          Math.min(height / 2 - radius, node.y),
        );
      }
    }
  }

  // Create a simulation with several forces.
  const simulation = d3
    // .forceSimulation<Node, SimulationLinkDatum<Node>>(nodes)
    .forceSimulation<Node, SimulationLinkDatum<Node>>();
  // simulation
  // .force(
  //   'link',
  //   d3
  //     // .forceLink<Node, SimulationLinkDatum<Node>>(links)
  //     .forceLink<Node, SimulationLinkDatum<Node>>()
  //     .distance(100)
  //     .id((d) => d.id)
  // )
  // .force('charge', d3.forceManyBody().strength(-100))
  // .force('bounds', boxingForce)
  // .force(
  //   'collide',
  //   d3.forceCollide<Node>().radius((d) => {
  //     if (d.group === 'people') {
  //       return 5 * 15;
  //     } else {
  //       return 5 * 15;
  //     }
  //   })
  // );

  const linkForce = d3
    // .forceLink<Node, SimulationLinkDatum<Node>>(links)
    .forceLink<Node, SimulationLinkDatum<Node>>()
    .distance(100)
    .id((d) => d.id);
  simulation.force('link', linkForce);

  // XXX: need to update nodes
  simulation.force('bounds', boxingForce).force(
    'collide',
    d3.forceCollide<Node>().radius((d) => {
      if (d.group === 'people') {
        return 5 * 15;
      } else {
        return 5 * 15;
      }
    }),
  );
  simulation.force('x', d3.forceX().strength(0.05));
  simulation.force('y', d3.forceY().strength(0.05));

  // const zoom = d3.zoom<SVGGElement, undefined>();

  // Create the SVG container.
  const svg = d3
    .create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [-width / 2, -height / 2, width, height])
    .attr('style', 'width: 100%; height: auto; background: lightblue');

  // svg.call(zoom.translateBy, 50, 0);
  const g = svg.append('g');

  function handleZoom(e: any) {
    g.attr('transform', e.transform);
  }

  const zoom = d3
    .zoom<SVGSVGElement, undefined>()
    .scaleExtent([1, 10])
    .translateExtent([
      [-width / 2, -height / 2],
      [width / 2, height / 2],
    ])
    .on('zoom', handleZoom);

  function initZoom() {
    svg.call(zoom);
  }
  initZoom();

  let node: d3.Selection<SVGGElement, Node, SVGGElement, undefined> =
    g.selectAll<SVGGElement, Node>('g');
  let line: d3.Selection<
    SVGGElement | SVGLineElement,
    Link,
    SVGGElement,
    undefined
  > = g.append('g').selectAll<SVGGElement, Link>('line');
  let linkText: d3.Selection<
    SVGTextElement | SVGGElement,
    Link,
    SVGGElement,
    undefined
  > = g.append('g').selectAll<SVGGElement, Link>('text');
  let dragBehavior: d3.DragBehavior<any, any, Node>;

  const createCircle = (
    node: d3.Selection<SVGGElement, Node, SVGGElement, undefined>,
  ) => {
    const circle = node
      .attr('stroke', '#fff')
      .attr('stroke-width', 1.5)
      .append('circle')
      .attr('r', radius)
      .attr('fill', (d) => color(d.group))
      .attr('fill-opacity', 1.0);

    circle.append('title').text((d) => d.id);
    return circle;
  };

  const createText = (
    node: d3.Selection<SVGGElement, Node, SVGGElement, undefined>,
  ) => {
    const text = node
      .append('text')
      .attr('x', 0)
      // .attr('y', radius + 15)
      .attr('y', 0)
      .text((d) => `${d.name}`) // ${d.group} -
      .style('text-anchor', 'middle')
      .attr('fill', 'black')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .call(wrap, 40, 0.35, 1, 1, true, true);
    return text;
  };

  const updateNode = (activeNodeId: string | undefined) => {
    node = node
      .data(nodes, (node) => node.id)
      .join(
        (enter) => {
          const node = enter.append('g');
          node.on('click', (_e, d) => {
            onClick && onClick(d.id, d.group);
          });
          node.style('opacity', 0.2);
          createCircle(node);
          createText(node);

          return node;
        },
        (update) =>
          update.attr('stroke', (d) =>
            d.id === activeNodeId ? 'red' : '#fff',
          ),
        (exit) => {
          return exit.transition().duration(500).style('opacity', 0).remove();
        },
      );
    node.transition().duration(500).style('opacity', 1);

    // Add a drag behavior.
    dragBehavior = d3
      .drag<any, any, Node>()
      .on('start', dragstarted)
      .on('drag', dragged)
      .on('end', dragended);
    node.call(dragBehavior);

    return node;
  };

  // Add a line for each link, and a circle for each node.
  const createLine = () => {
    line = line
      .data(links, (link) => link.id)
      .join(
        (enter) =>
          enter
            .append('line')
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.6)
            .attr('stroke-width', (d) => Math.sqrt(d.value)),
        (update) => update,
        (exit) => exit.transition().duration(500).style('opacity', 0).remove(),
      );

    return line;
  };

  const createLinkText = () => {
    console.log('links', links);
    return linkText
      .data(links, (link) => link.id)
      .join(
        (enter) =>
          enter
            .append('text')
            .attr('fill', 'grey')
            .attr('stroke', 'grey')
            .attr('text-anchor', 'middle')
            .attr('stroke-width', 1)
            .text((d) => d.description),
        (update) => update,
        (exit) => exit.transition().duration(500).style('opacity', 0).remove(),
      );
  };

  const update = (
    newNodes: Node[],
    newLinks: Link[],
    activeNodeId: string | undefined,
  ) => {
    nodes = newNodes.map((newNode) => {
      const existingNode = nodes.find((node) => node.id === newNode.id);
      if (existingNode != null) {
        return existingNode;
      } else {
        return { ...newNode };
      }
    });
    console.log('LINKS', links, newLinks);
    links = newLinks.map((newLink) => {
      const existingLink = links.find((link) => link.id === newLink.id);
      if (existingLink != null) {
        return existingLink;
      } else {
        return { ...newLink };
      }
    });
    simulation.nodes(nodes);
    linkForce.links(links);
    simulation.alpha(0.1).restart();

    updateNode(activeNodeId);
    line = createLine();
    linkText = createLinkText();
  };

  // Set the position attributes of links and nodes each time the simulation ticks.
  simulation.on('tick', () => {
    // console.log('tick');
    line
      .attr('x1', (d) => {
        return (d.source as Node).x as number;
      })
      .attr('y1', (d) => {
        return (d.source as Node).y as number;
      })
      .attr('x2', (d) => {
        return (d.target as Node).x as number;
      })
      .attr('y2', (d) => {
        return (d.target as Node).y as number;
      });

    linkText
      .attr('x', (d) => {
        const sourceX = (d.source as Node).x as number;
        const targetX = (d.target as Node).x as number;
        return (sourceX + targetX) / 2.0;
      })
      .attr('y', (d) => {
        const sourceY = (d.source as Node).y as number;
        const targetY = (d.target as Node).y as number;
        return (sourceY + targetY) / 2.0;
      });
    node
      .selectAll<SVGCircleElement, Node>('circle')
      .attr('cx', (d) => d.x ?? 0)
      .attr('cy', (d) => d.y ?? 0);

    node.selectAll<SVGTextElement, Node>('text').attr('transform', (d) => {
      // console.log('d.x, d.y', d.name, d.x, d.y);
      return `translate(${d.x}, ${d.y})`;
    });
  });

  // Reheat the simulation when drag starts, and fix the subject position.
  function dragstarted(event: any) {
    // console.log('dragstarted');
    if (!event.active) simulation.alphaTarget(0.1).restart();
    // console.log('subject', event.subject);
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  // Update the subject (dragged node) position during drag.
  function dragged(event: any) {
    // event.subject.fx = event.x;
    // event.subject.fy = event.y;
    // console.log('dragged');
    event.subject.fx = Math.max(
      -width / 2 + radius,
      Math.min(width / 2 - radius, event.x),
    );
    event.subject.fy = Math.max(
      -height / 2 + radius,
      Math.min(height / 2 - radius, event.y),
    );
  }

  // Restore the target alpha so the simulation cools after dragging ends.
  // Unfix the subject position now that it’s no longer being dragged.
  function dragended(event: any) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  const center = () => {
    g.attr('transform', 'translate(0,0)');
  };

  // When this cell is re-run, stop the previous simulation. (This doesn’t
  // really matter since the target alpha is zero and the simulation will
  // stop naturally, but it’s a good practice.)
  // invalidation.then(() => simulation.stop());

  // simulation.stop();

  return { node: svg.node(), center, update };
};
