import { Unsubscribe } from 'redux';
import { authenticationService } from '~src/services/authentication/authentication-service';
// import { selectProject } from '../modules/projects/projects/requests';
import { AppStore } from '~src/store/store';
import {
  selectTenantData,
  selectUserData,
  selectWorkspaceData,
} from '../selectors/selectors';
import {
  // fetchWorkspaceShallow,
  selectWorkspace,
} from '../modules/workspaces/workspaces/actions';
import {
  // fetchProjects,
  selectProject,
} from '../modules/workspaces/projects/base/requests';
import { selectTenant } from '../modules/tenant/tenants/requests';
// import { handleProjects } from '~src/data/store/modules/workspaces/projects/base/actions';

export class WorkspaceListener {
  private unsubscribeSelectedTenant: Unsubscribe;
  private unsubscribeSelectedWorkspace: Unsubscribe;
  private unsubscribeSelectedProject: Unsubscribe;

  constructor(private store: AppStore) {
    this.store = store;
    const state = this.store.getState();
    const user = selectUserData(state).account.user;
    if (user) {
      // authenticationService.unsetWorkspaceId();
      const tenantId = authenticationService.getTenantId();
      const workspaceId = authenticationService.getWorkspaceId();
      const projectId = authenticationService.getProjectId();
      // const projectId = authenticationService.getProjectId();
      if (tenantId) {
        store.dispatch(selectTenant(tenantId));
        if (workspaceId) {
          store.dispatch(selectWorkspace(workspaceId));
          if (projectId) {
            store.dispatch(selectProject(projectId));
            // store.dispatch(fetchWorkspaceShallow(tenantId, workspaceId));
            // store.dispatch(
            //   fetchProjects(tenantId, workspaceId, [projectId], handleProjects)
            // );
          }
        }
      }
    }
    this.unsubscribeSelectedTenant = store.subscribe(this.handleSelectedTenant);
    this.unsubscribeSelectedWorkspace = store.subscribe(
      this.handleSelectedWorkspace,
    );
    this.unsubscribeSelectedProject = store.subscribe(
      this.handleSelectedProject,
    );
  }

  public unsubscribe() {
    this.unsubscribeSelectedTenant();
    this.unsubscribeSelectedWorkspace();
    this.unsubscribeSelectedProject();
  }

  private handleSelectedTenant = () => {
    const store = this.store;
    const state = store.getState();
    const selectedTenantId = selectTenantData(state).tenant.selectedTenantId;
    const existingSelectedTenantId = authenticationService.getTenantId();

    // console.log('WORKSPACE IDS: ', existingSelectedTenantId, selectedTenantId);
    if (selectedTenantId === null && existingSelectedTenantId === null) {
      return;
    }

    if (
      existingSelectedTenantId === null ||
      selectedTenantId === null ||
      selectedTenantId !== existingSelectedTenantId
    ) {
      if (selectedTenantId !== null) {
        authenticationService.setTenantId(selectedTenantId);
        // this.fetchTenantData(store, selectedTenantId);
        // authenticationService.unsetProjectId();
      } else {
        authenticationService.unsetTenantId();
        authenticationService.unsetWorkspaceId();
        authenticationService.unsetWorkspaceRoleId();
        authenticationService.unsetProjectId();
      }
    }
  };

  private handleSelectedWorkspace = () => {
    const store = this.store;
    const state = store.getState();
    const selectedWorkspaceId =
      selectWorkspaceData(state).workspace.selectedWorkspaceId;
    const existingSelectedWorkspaceId = authenticationService.getWorkspaceId();

    // console.log('WORKSPACE IDS: ', existingSelectedWorkspaceId, selectedWorkspaceId);
    if (selectedWorkspaceId === null && existingSelectedWorkspaceId === null) {
      return;
    }

    if (
      existingSelectedWorkspaceId === null ||
      selectedWorkspaceId === null ||
      selectedWorkspaceId !== existingSelectedWorkspaceId
    ) {
      if (selectedWorkspaceId !== null) {
        authenticationService.setWorkspaceId(selectedWorkspaceId);
        authenticationService.unsetWorkspaceRoleId();
        // this.fetchWorkspaceData(store, selectedWorkspaceId);
      } else {
        authenticationService.unsetWorkspaceId();
        authenticationService.unsetWorkspaceRoleId();
        authenticationService.unsetProjectId();
      }
    }
  };

  private handleSelectedProject = () => {
    const store = this.store;
    const state = store.getState();
    const selectedProjectId =
      selectWorkspaceData(state).workspace.project.selectedProject;
    // const existingSelectedProjectId = authenticationService.getProjectId();
    const existingSelectedProjectId = authenticationService.getProjectId();

    if (selectedProjectId === null && existingSelectedProjectId === null) {
      return;
    }

    if (
      existingSelectedProjectId === null ||
      selectedProjectId === null ||
      selectedProjectId !== existingSelectedProjectId
    ) {
      if (selectedProjectId !== null) {
        authenticationService.setProjectId(selectedProjectId);
      } else {
        authenticationService.unsetProjectId();
      }
    }
  };
}
