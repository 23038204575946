import moment from 'moment';
import createCachedSelector from 're-reselect';
import { Finance } from '~src/utils/finance/holding';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { DataState } from '~src/data/store/reducers/reducers';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getExchangeRateState } from '../exchange-rates/selectors';

interface Props {
  holding: HoldingState;
  date: moment.Moment;
  currency: CurrencyState;
}

export const getBaseHoldingValuation = createCachedSelector(
  (state: DataState, props: Props) =>
    getValuationsByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (valuations, date, currency, exchangeRateState) => {
    return Finance.valuation(
      valuations,
      date.toDate(),
      currency,
      exchangeRateState,
    );
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holding.id}:${props.date.unix()}:${props.currency.id}`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});
