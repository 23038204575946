import React from 'react';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EditProjectReportComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/edit-project-report.component';

import { Box, Grid } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  projectReport: ProjectReportState;
}

export const ProjectReportSettingsComponent = React.memo((props: Props) => {
  const { workspace, projectReport } = props;

  return (
    <Box>
      <Grid container>
        <Grid item sx={{ ml: 1 }}>
          <EditProjectReportComponent
            projectReport={projectReport}
            workspace={workspace}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
