import { Config } from '~src/utils/interfaces/config';

import { strcmp } from '@pladdenico/common';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  initialDefaultConfig,
  initialEditConfig,
  initialViewConfig,
} from './config-initial';
import {
  ConfigNormalized,
  configToConfigState,
} from '~src/data/store/modules/config/config-normalizer';

export type ConfigState = ConfigNormalized;

const configsAdapter = createEntityAdapter<ConfigState>({
  // selectId: (config: ConfigState) => config.id,
  sortComparer: (a, b) => strcmp(a.id, b.id),
});

const emptyInitialState = configsAdapter.getInitialState();
export const initialState = configsAdapter.addMany(emptyInitialState, [
  initialDefaultConfig(),
  initialViewConfig(),
  initialEditConfig(),
]);

interface ConfigPayload {
  id: string;
  config: Config;
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    addOne: {
      reducer: configsAdapter.addOne,
      prepare: (payload: ConfigPayload) => {
        return {
          payload: configToConfigState(payload.id, payload.config),
        };
      },
    },
    addMany: {
      reducer: configsAdapter.addMany,
      prepare: (payload: ConfigPayload[]) => {
        return {
          payload: payload.map((c) => {
            return configToConfigState(c.id, c.config);
          }),
        };
      },
    },
    upsertOne: {
      reducer: configsAdapter.upsertOne,
      prepare: (payload: ConfigPayload) => {
        return {
          payload: configToConfigState(payload.id, payload.config),
        };
      },
    },
    removeOne: configsAdapter.removeOne,
  },
});

export const { addOne, upsertOne, removeOne } = configSlice.actions;
export const configReducer = configSlice.reducer;

export const configsSelectors = configsAdapter.getSelectors();
