import React from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { EditHoldingById } from '~src/components/fields/workspace/holding/holding/edit-holding-by-id';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FormValues } from '~src/domain/workspace/components/data/adapters/nordnet/form/edit-form.component';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {},
}));

export const useNordnetTransactionFields = () => {
  const { classes } = useStyles();

  const paperElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'paper'>,
      fieldState: ControllerFieldState,
    ) => (
      <TextField
        margin="normal"
        className={classes.textField}
        fullWidth
        required
        id="paper"
        label="paper"
        type="text"
        onChange={field.onChange}
        value={field.value ?? ''}
        autoFocus
        error={!!fieldState.error}
        helperText={fieldState.error?.type ? 'paper is required' : undefined}
      />
    ),
    [classes.textField],
  );
  const holdingElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'holdingId'>,
      _fieldState: ControllerFieldState,
      workspace: WorkspaceState,
      projectId: string,
    ) => (
      <EditHoldingById
        workspace={workspace}
        projectId={projectId}
        handleValue={(holding: HoldingState) => field.onChange(holding.id)}
        holdingId={field.value ?? ''}
        label="holding"
      />
      // <TextField
      //   margin="normal"
      //   className={classes.textField}
      //   fullWidth
      //   required
      //   id="paper"
      //   label="paper"
      //   type="text"
      //   onChange={field.onChange}
      //   value={field.value ?? ''}
      //   autoFocus
      //   error={!!fieldState.error}
      //   helperText={fieldState.error?.type ? 'paper is required' : undefined}
      // />
    ),
    [],
  );

  const bankNoteHoldingElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'bankNoteHoldingId'>,
      _fieldState: ControllerFieldState,
      workspace: WorkspaceState,
      projectId: string,
    ) => (
      <EditHoldingById
        workspace={workspace}
        projectId={projectId}
        handleValue={(holding: HoldingState) => field.onChange(holding.id)}
        holdingId={field.value ?? ''}
        label="bank note holding"
      />
    ),
    [],
  );
  const valueElement = React.useCallback(
    (
      field: ControllerRenderProps<FormValues, 'value'>,
      _fieldState: ControllerFieldState,
    ) => (
      <TextField
        multiline
        margin="normal"
        className={classes.textField}
        fullWidth
        // required
        id="value"
        // value={transactionInput.description ?? ''}
        value={field.value ?? ''}
        label="Value"
        type="text"
        // multiline
        onChange={field.onChange}
        // onChange={handleEvent('description')}
        // error={!!fieldState.error}
        // helperText={
        //   fieldState.error?.type ? 'Description is required' : undefined
        // }
      />
    ),
    [classes.textField],
  );

  return {
    paperElement,
    valueElement,
    holdingElement,
    bankNoteHoldingElement,
  };
};
