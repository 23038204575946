import { OwnerLink } from '@pladdenico/models';
import { OwnerLinkAction } from '~src/data/store/actions/owner';

export const OWNER_LINK = 'ownerLinks/OWNER_LINK';
export const OWNER_LINKS = 'ownerLinks/OWNER_LINKS';
export const DELETED_OWNER_LINK = 'ownerLinks/DELETED_OWNER_LINK';

export const GET_OWNER_LINKS_REQUEST = 'ownerLinks/GET_OWNER_LINKS_REQUEST';
export const GET_OWNER_LINKS_FAILURE = 'ownerLinks/GET_OWNER_LINKS_FAILURE';

export const GET_OWNER_LINK_REQUEST = 'ownerLinks/GET_OWNER_LINK_REQUEST';
export const GET_OWNER_LINK_SUCCESS = 'ownerLinks/GET_OWNER_LINK_SUCCESS';
export const GET_OWNER_LINK_FAILURE = 'ownerLinks/GET_OWNER_LINK_FAILURE';

export const OWNER_LINK_SELECTED = 'ownerLinks/OWNER_LINK_SELECTED';
export const OWNER_LINK_UNSELECTED = 'ownerLinks/OWNER_LINK_UNSELECTED';
export const OWNER_LINKS_SELECTED = 'ownerLinks/OWNER_LINKS_SELECTED';
export const OWNER_LINKS_UNSELECTED = 'ownerLinks/OWNER_LINKS_UNSELECTED';

// export function selectOwnerLinkAction(ownerLink: OwnerLink): OwnerLinkAction {
//   return {
//     type: OWNER_LINK_SELECTED,
//     payload: {
//       ownerLinkId: ownerLink.id,
//       ownerLink,
//     },
//   };
// }

// export function unselectOwnerLinkAction(ownerLink: OwnerLink): OwnerLinkAction {
//   return {
//     type: OWNER_LINK_UNSELECTED,
//     payload: {
//       ownerLinkId: ownerLink.id,
//     },
//   };
// }

// export function selectOwnerLinksAction(ownerLinks: OwnerLink[]): OwnerLinkAction {
//   return {
//     type: OWNER_LINKS_SELECTED,
//     payload: {
//       ownerLinks,
//     },
//   };
// }

// export function unselectOwnerLinksAction(): OwnerLinkAction {
//   return {
//     type: OWNER_LINKS_UNSELECTED,
//     payload: {
//     },
//   };
// }

// export function ownerLinkAction(ownerLink: OwnerLink): OwnerLinkAction {
//   return {
//     type: OWNER_LINK,
//     payload: {
//       ownerLinkId: ownerLink.id,
//       ownerLink,
//     },
//   };
// }

// export function ownerLinksAction(ownerLinks: OwnerLink[]): OwnerLinkAction {
//   return {
//     type: OWNER_LINKS,
//     payload: {
//       ownerLinks,
//     },
//   };
// }

// export function deletedOwnerLinkAction(ownerLinkId: number): OwnerLinkAction {
//   return {
//     type: DELETED_OWNER_LINK,
//     payload: {ownerLinkId},
//   };
// }

export function ownerLinksRequestAction(): OwnerLinkAction {
  return {
    type: GET_OWNER_LINKS_REQUEST,
    payload: undefined,
  };
}

export function ownerLinksFailureAction(message: any): OwnerLinkAction {
  return {
    type: GET_OWNER_LINKS_FAILURE,
    payload: {
      message,
    },
  };
}

export function ownerLinkRequestAction(ownerLinkId: number): OwnerLinkAction {
  return {
    type: GET_OWNER_LINK_REQUEST,
    payload: { ownerLinkId },
  };
}

export function ownerLinkSuccessAction(ownerLink: OwnerLink): OwnerLinkAction {
  return {
    type: GET_OWNER_LINK_SUCCESS,
    payload: {
      ownerLink,
    },
  };
}

export function ownerLinkFailureAction(
  ownerLinkId: number,
  message: any,
): OwnerLinkAction {
  return {
    type: GET_OWNER_LINK_FAILURE,
    payload: {
      ownerLinkId,
      message,
    },
  };
}
