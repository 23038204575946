import moment from 'moment';
import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { useForecastMenu } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-menu.component';
import { ManualElement } from '~src/domain/workspace/components/project/scenario/models/forecast/manual-element';
import { SequentialForecastEvaluation } from '~src/domain/workspace/components/project/scenario/models/forecast/sequential-forecast-evaluation';
import { Value } from '~src/domain/workspace/components/project/scenario/models/forecast/value';

import { Box, Button, colors, Grid, Paper, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  forecast: ForecastState;
  forecastManualElement: ManualElementState;
}

export const ForecastManualElementComponent = React.memo((props: Props) => {
  const { forecast, forecastManualElement } = props;
  const { menuElement, menuButtonElement } = useForecastMenu(forecast);

  const forecastElement = React.useMemo(() => {
    const element = forecastManualElement as ManualElementState;
    return new ManualElement(
      forecast.id,
      forecast.name,
      element.values.map((v) => {
        return {
          date: moment.unix(v.unixDate),
          transfer: v.transfer,
          valuation: v.valuation,
          // correspondingHoldingId: element.correspondingHoldingId,
        };
      }),
    );
  }, [forecast.id, forecast.name, forecastManualElement]);

  const metaString = React.useMemo(() => {
    return JSON.stringify(forecastManualElement.values);
  }, [forecastManualElement]);

  const [values, setValues] = React.useState<Value[]>([]);

  return (
    <Box sx={{ mb: 1, background: colors.blue[200] }}>
      <Grid container padding={1}>
        <Grid item xs={3}>
          <Typography>{forecast.name}</Typography>
          <Typography variant="caption">{metaString}</Typography>
        </Grid>
        <Grid item xs={3}>
          {menuButtonElement}
          {menuElement}
          <Button
            variant="outlined"
            sx={{ mt: 1, mr: 2 }}
            onClick={() => {
              if (forecastElement == null) {
                return;
              }
              const evaluator = new SequentialForecastEvaluation([
                forecastElement,
              ]);
              const startDate = moment();
              const endDate = moment(startDate).add(1, 'year');

              const values = evaluator.evaluate(startDate, endDate);
              setValues(values);
              console.log(values);
            }}
          >
            Evaluate element
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Grid container sx={{ m: 1 }}>
              <Grid item xs={4}>
                <Typography>Date</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Transfer</Typography>
              </Grid>
              <Grid item xs={4}>
                Valuation
              </Grid>
            </Grid>
            {values.map((value, idx) => {
              return (
                <Box key={idx}>
                  <Grid container sx={{ m: 1 }}>
                    <Grid item xs={4}>
                      <Typography>{value.date.format('DD.MM.YYYY')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        {value.transfer?.type}: {value.transfer?.value}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{value.valuation.value}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});
