import * as React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TransactionCardData } from '~src/domain/workspace/components/project/transaction/card/cards-list.component';
// import history from '~src/navigation/history';
import { transactionBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/transaction/paths';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  colors,
  Grid,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransferCardComponent } from '~src/domain/workspace/components/project/transaction/card/transfer/card.component';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { deleteTransactions } from '~src/data/store/modules/holdings/transactions/transactions/requests';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'TransactionCardComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  transaction: `${PREFIX}-transaction`,
  transfer: `${PREFIX}-holding`,
  description: `${PREFIX}-description`,
  menuButton: `${PREFIX}-menuButton`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    flex: 1,
    backgroundColor: colors.blue[50],
    // minWidth: 150,
    // height: 165,
    // width: 160,
    // maxWidth: 450,
  },

  [`& .${classes.transaction}`]: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.transfer}`]: {
    margin: theme.spacing(1),
    justifyContent: 'center',
  },

  [`& .${classes.description}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 4,
    overflow: 'hidden',
  },

  [`& .${classes.menuButton}`]: {
    textAlign: 'center',
  },
}));

interface Props {
  transaction: TransactionCardData;
  workspace: WorkspaceState;
  projectId: string;
  portfolios?: PortfolioState[];
}

export const TransactionCardComponent = React.memo((props: Props) => {
  const { transaction, projectId, workspace } = props;

  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMoreAnchorEl(null);
  }

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { open, isShowing, close } = useVisible();

  const handleOnClick = (
    tenantId: string,
    workspaceId: string,
    projectId: string,
    transaction: TransactionCardData,
  ) => {
    navigate(
      transactionBasePath(
        tenantId,
        workspaceId,
        projectId,
        transaction.transaction.transaction.id,
      ),
    );
  };

  const handleDeleteTransaction = (
    tenantId: string,
    workspaceId: string,
    projectId: string,
    transactionId: string,
  ) => {
    return dispatch(
      deleteTransactions(tenantId, workspaceId, [
        { projectId, id: transactionId },
      ]),
    );
  };

  const transactionMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() =>
          handleOnClick(
            workspace.tenantId,
            workspace.id,
            props.projectId,
            transaction,
          )
        }
      >
        View
      </MenuItem>
      <MenuItem onClick={open}>Delete</MenuItem>
      <ConfirmDialogComponent
        isOpen={isShowing}
        handler={() => {
          handleDeleteTransaction(
            workspace.tenantId,
            workspace.id,
            props.projectId,
            transaction.transaction.transaction.id,
          ).then(() => handleMenuClose());
        }}
        close={close}
        objectString="transaction"
      />
    </Menu>
  );

  const transferElements = transaction.transfers.map((transfer) => {
    const transferElement = (
      <TransferCardComponent
        key={transfer.transfer.id}
        workspace={workspace}
        projectId={projectId}
        // portfolio={portfolio}
        transfer={transfer.transfer}
        holding={transfer.holding}
      />
    );
    return (
      <Box className={classes.transfer} key={transfer.transfer.id}>
        {transferElement}
      </Box>
    );
  });

  return (
    <StyledPaper className={classes.paper} variant="outlined">
      <Grid container>
        <Grid
          container
          item
          xs={5}
          // justifyContent="space-between"
          className={classes.transaction}
        >
          <Grid item xs={10}>
            <Typography variant="body1">
              {props.transaction.transaction.transaction.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.description}
            >
              {props.transaction.transaction.transaction.description}
            </Typography>
          </Grid>
          <Grid item xs className={classes.menuButton}>
            <IconButton onClick={handleMenuOpen} color="inherit">
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={7}>
          {transferElements}
        </Grid>
      </Grid>
      {transactionMenu}
    </StyledPaper>
  );
});
