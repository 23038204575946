import { Box } from '@mui/material';
import { NodeType } from '../nodes/node-type';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';

export const Sidebar = () => {
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: NodeType,
  ) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Box sx={{ background: primaryBackgroundColor.light }}>
      <div
        className="dndnode input"
        onDragStart={(event) => onDragStart(event, 'inputType')}
        draggable
      >
        Input Node
      </div>
      <div
        className="dndnode output"
        onDragStart={(event) => onDragStart(event, 'output')}
        draggable
      >
        Output Node
      </div>
      <div
        className="dndnode output"
        onDragStart={(event) => onDragStart(event, 'initial')}
        draggable
      >
        Initial Node
      </div>
      <div
        className="dndnode output"
        onDragStart={(event) => onDragStart(event, 'valuation')}
        draggable
      >
        Valuation Node
      </div>
      <div
        className="dndnode output"
        onDragStart={(event) => onDragStart(event, 'transfer')}
        draggable
      >
        Transfer Node
      </div>
      <div
        className="dndnode output"
        onDragStart={(event) => onDragStart(event, 'stockPrice')}
        draggable
      >
        Stock Price Node
      </div>
      <div
        className="dndnode"
        onDragStart={(event) => onDragStart(event, 'variable')}
        draggable
      >
        Variable Node
      </div>
    </Box>
  );
};
