import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DnbEditTransactionFormComponent } from '~src/domain/workspace/components/data/adapters/dnb/form/edit-form.component';
import { DnbTransactionState } from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';

import { Grow, Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  transaction: DnbTransactionState;
  open: boolean;
  handleClose: () => void;
  handleSuccess: (transaction: DnbTransactionState) => void;
}

export const DnbEditTransactionComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      maxWidth={false}
      onClose={props.handleClose}
      TransitionComponent={Grow}
    >
      <DnbEditTransactionFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        handleSuccess={props.handleSuccess}
        handleClose={props.handleClose}
        initialFormValues={props.transaction}
      />
    </Dialog>
  );
});
