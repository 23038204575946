import React from 'react';

export const useDate = (date: Date | undefined) => {
  const [_date, _setDate] = React.useState<Date | undefined>(date);
  React.useEffect(() => {
    if (date && date.getTime() !== _date?.getTime()) {
      _setDate(date);
    }
  }, [_date, date]);
  return _date;
};
