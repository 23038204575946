import React from 'react';
import { typeToString } from '~src/components/fields/workspace/commitment/type/view-type-field';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

const useStyles = makeStyles()(() => ({
  types: {
    minWidth: 100,
  },
}));
interface Props {
  handleValue: (type: CommitmentType) => void;
  type: CommitmentType | undefined;
  label: string | undefined;
  commitmentTypes?: CommitmentType[];
  disabled?: boolean;
}

export const EditTypeField = React.memo((props: Props) => {
  const { classes } = useStyles();
  const handleSelectChange = (event: SelectChangeEvent<CommitmentType>) => {
    props.handleValue(event.target.value as CommitmentType);
  };

  const label = props.label ? (
    <InputLabel htmlFor="Type">{props.label}</InputLabel>
  ) : undefined;

  const menuItems = React.useMemo(() => {
    return Object.values(CommitmentType).map((type) => {
      const disabled =
        props.commitmentTypes &&
        !props.commitmentTypes?.some((t) => t === type);
      return (
        <MenuItem key={type} value={type} disabled={disabled}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, [props.commitmentTypes]);

  const disabled =
    props.commitmentTypes && props.type && props.commitmentTypes.length === 1
      ? props.commitmentTypes.some((t) => t === props.type)
      : false;

  return (
    <FormControl margin="normal" className={classes.types} fullWidth>
      {label}
      <Select
        value={props.type ?? ''}
        disabled={disabled}
        onChange={handleSelectChange}
        label={props.label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
