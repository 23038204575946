import { useDispatch } from 'react-redux';
import { FundPositionData } from '~src/hooks/fields/workspace/project/holding/use-fund-position-fields';
import { AppDispatch } from '~src/store/store';
import {
  deleteFundPositions,
  updateFundPositions,
} from '~src/data/store/modules/holdings/fund/position/requests';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export function useFundPositionTableActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (
    oldData: FundPositionData,
    newData: FundPositionData,
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.UpdateFundPositionInputType = {
          id: newData.id,
          fundHoldingId: newData.fundHoldingId,
          date: newData.date,
          shares: newData.shares ?? 0,
        };
        dispatch(updateFundPositions(workspace.tenantId, workspace.id, [input]))
          .then(() => resolve())
          .catch((err) => reject(err));
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (oldData: FundPositionData) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        deleteFundPositions(
          workspace.tenantId,
          workspace.id,
          oldData.fundHoldingId,
          [oldData.id],
        ),
      )
        .then(() => resolve())
        .catch((err) => reject(err));
      // if (oldData) {
      //   resolve();
      // } else {
      //   reject();
      // }
      // handleUpdateRegionAllocations(newData, resolve, reject);
    });
  };
  return { onRowUpdate, onRowDelete };
}
