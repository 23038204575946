import {
  nordnetTransactionsReducer,
  initialNordnetTransactionState,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';

import { combineReducers } from '@reduxjs/toolkit';

export const nordnetAdapterInitialState = {
  nordnetTransactions: initialNordnetTransactionState,
};

export const nordnetAdapterReducer = combineReducers({
  nordnetTransactions: nordnetTransactionsReducer,
});
