import { sortedIndex, sortedUniq } from 'lodash';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingData } from '~src/domain/workspace/components/project/holding/form/use-fields.component';

import {
  createNode,
  intersectionSorted,
  Node,
  strcmp,
  unionSorted,
} from '@pladdenico/common';

import {
  BasePortfolioId,
  portfolioSelectors as portfoliosSelectors,
  PortfolioState,
} from '../../../reducers/portfolio/portfolios/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';
import { getHoldingsByProjectId } from '../../holding/holdings/selectors';

interface BasePortfolioProjectId {
  workspaceId: string;
  projectId: string;
  id: string;
}

export type HoldingDataWithPortfolioInfo = HoldingData & {
  portfolios: PortfolioState[];
};

const getPortfolioState = (state: WorkspaceDataState) =>
  state.portfolio.portfolios;

export const getPortfoliosByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getPortfolioState(state),
  (_state: WorkspaceDataState, workspaceId: string | null) => workspaceId,
  (portfolioState, workspaceId): PortfolioState[] => {
    if (!workspaceId) {
      return [];
    }
    return (
      portfoliosSelectors.selectElementsByKey(portfolioState, workspaceId) ?? []
    );
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

export const getPortfolios = createSelector(
  (state: WorkspaceDataState) => getPortfolioState(state),
  (portfolios) => {
    return portfoliosSelectors.selectAllElements(portfolios);
  },
);

export const getSelectedPortfolio = createCachedSelector(
  (state: WorkspaceDataState) => getPortfolioState(state),
  (_state: WorkspaceDataState, workspaceId: string | null) => workspaceId,
  (state: WorkspaceDataState) => state.portfolio.selectedPortfolioId,
  (portfolios, workspaceId, id): PortfolioState | undefined => {
    if (!id || !workspaceId) {
      return undefined;
    }
    return portfoliosSelectors.selectElementByT(portfolios, {
      id,
      workspaceId,
    });
  },
)({
  keySelector: (_state, workspaceId) => `${workspaceId}`,
  selectorCreator: createSelector,
});

export const getPortfolioById = createCachedSelector(
  (state: WorkspaceDataState) => getPortfolioState(state),
  (_state: WorkspaceDataState, baseId: BasePortfolioId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BasePortfolioId) => baseId.workspaceId,
  (portfolios, id, workspaceId): PortfolioState | undefined => {
    return portfoliosSelectors.selectElementByT(portfolios, {
      id,
      workspaceId,
    });
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}:${baseId.id}`,
  selectorCreator: createSelector,
});

export const getPortfoliosByIds = createCachedSelector(
  (state: WorkspaceDataState) => getPortfolioState(state),
  (_state: WorkspaceDataState, ids: BasePortfolioId[]) => ids,
  (portfolios, ids): PortfolioState[] => {
    return portfoliosSelectors.selectElementsByTs(portfolios, ids);
  },
)({
  keySelector: (_state, ids) => `${JSON.stringify(ids)}`,
  selectorCreator: createSelector,
});

function findChildren(portfolios: PortfolioState[], portfolio: PortfolioState) {
  return portfolios.filter((p) => p.parentPortfolioId === portfolio.id);
}

export const getPortfolioChildrenTreeByPortfolioId = createCachedSelector(
  (state: WorkspaceDataState, baseId: BasePortfolioId) =>
    getPortfoliosByWorkspaceId(state, baseId.workspaceId),
  (state: WorkspaceDataState, baseId: BasePortfolioId) =>
    getPortfolioById(state, baseId),
  (portfolios, portfolio): Node<PortfolioState> | undefined => {
    if (portfolio) {
      return createNode(portfolios, portfolio, findChildren);
    }
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}:${baseId.id}`,
  selectorCreator: createSelector,
});

// function findChildren(portfolios: PortfolioState[], portfolio: PortfolioState) {
//   return portfolios.filter((p) => p.parentPortfolioId === portfolio.id);
// }

function findDescendants(
  portfolios: PortfolioState[],
  portfolio: PortfolioState,
): PortfolioState[] {
  const children = findChildren(portfolios, portfolio);
  return children.reduce<PortfolioState[]>(
    (prev, curr) => {
      const descendants = findDescendants(portfolios, curr);
      return [...prev, ...descendants];
    },
    [...children],
  );
}

export const getPortfolioAndDescendantsByPortfolioId = createCachedSelector(
  (state: WorkspaceDataState, baseId: BasePortfolioId) =>
    getPortfoliosByWorkspaceId(state, baseId.workspaceId),
  (state: WorkspaceDataState, baseId: BasePortfolioId) =>
    getPortfolioById(state, baseId),
  (portfolios, portfolio): PortfolioState[] => {
    if (!portfolio) {
      return [];
    }
    const descendants = findDescendants(portfolios, portfolio);
    return [portfolio, ...descendants];
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}.${baseId.id}`,
  selectorCreator: createSelector,
});

export const getHoldingsByPortfolioId = createCachedSelector(
  (state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    getPortfolioAndDescendantsByPortfolioId(state, {
      id: baseId.id,
      workspaceId: baseId.workspaceId,
    }),
  (state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    getHoldingsByProjectId(state, baseId.projectId),
  (portfolios, holdings): HoldingState[] => {
    const holdingIds = sortedUniq(
      portfolios
        .reduce<string[]>((prev, curr) => {
          return [...prev, ...curr.holdingIds];
        }, [])
        .sort((a, b) => strcmp(a, b)),
    );
    if (holdingIds) {
      return intersectionSorted(
        holdings,
        holdingIds,
        [(a, b) => strcmp(a.id, b)],
        (a, _b) => a,
      );
    }
    return [];
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}.${baseId.id}`,
  selectorCreator: createSelector,
});

export const getHoldingsByPortfolioIdWithPortfolioInfo = createCachedSelector(
  (state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    getPortfolioAndDescendantsByPortfolioId(state, {
      id: baseId.id,
      workspaceId: baseId.workspaceId,
    }),
  (state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    getHoldingsByProjectId(state, baseId.projectId),
  (portfolios, holdings): HoldingDataWithPortfolioInfo[] => {
    const holdingsWithPortfolioInfo: HoldingDataWithPortfolioInfo[] = holdings
      .map((holding) => {
        return {
          id: holding.id,
          holding,
          portfolios: [],
        };
      })
      .sort((a, b) => strcmp(a.id, b.id));
    return portfolios.reduce<HoldingDataWithPortfolioInfo[]>((prev, curr) => {
      const holdingIds = curr.holdingIds;
      const holdingsForPortfolio: HoldingDataWithPortfolioInfo[] =
        intersectionSorted(
          holdingsWithPortfolioInfo,
          holdingIds,
          [(a, b) => strcmp(a.holding.id, b)],
          (a, _b) => {
            return {
              id: a.holding.id,
              holding: a.holding,
              portfolios: [curr],
            };
          },
        );
      const holdingsMerged = unionSorted(
        prev,
        holdingsForPortfolio,
        [(a, b) => strcmp(a.holding.id, b.holding.id)],
        (a, b) => {
          return {
            id: a.holding.id,
            holding: a.holding,
            portfolios: [...a.portfolios, ...b.portfolios],
          };
        },
        (a) => a,
        (a) => a,
      );
      return holdingsMerged;
    }, []);
    // .sort((a, b) => strcmp(a, b));
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}.${baseId.id}`,
  selectorCreator: createSelector,
});

const _getPortfoliosForHoldingId = (
  portfolios: PortfolioState[],
  holdingId: string,
) => {
  return portfolios.reduce<PortfolioState[]>((prev, curr) => {
    const holdingIds = curr.holdingIds;
    const idx = sortedIndex(holdingIds, holdingId);
    if (idx < holdingIds.length) {
      if (holdingId === holdingIds[idx]) {
        return [...prev, curr];
      }
    }
    return prev;
  }, []);
};

export const getHoldingsByProjectIdWithPortfolioInfo = createCachedSelector(
  (state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    getPortfolioAndDescendantsByPortfolioId(state, {
      id: baseId.id,
      workspaceId: baseId.workspaceId,
    }),
  (state: WorkspaceDataState, baseId: BasePortfolioProjectId) =>
    getHoldingsByProjectId(state, baseId.projectId),
  (portfolios, holdings): HoldingDataWithPortfolioInfo[] => {
    return holdings
      .map((holding) => {
        const holdingPortfolios = _getPortfoliosForHoldingId(
          portfolios,
          holding.id,
        );
        return {
          id: holding.id,
          holding,
          portfolios: holdingPortfolios,
        };
      })
      .sort((a, b) => strcmp(a.id, b.id));
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}.${baseId.id}`,
  selectorCreator: createSelector,
});

interface PortfoliosByHoldingIdProps {
  workspaceId: string;
  id: string;
}

export const getPortfoliosByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, baseId: PortfoliosByHoldingIdProps) =>
    getPortfoliosByWorkspaceId(state, baseId.workspaceId),
  (_state: WorkspaceDataState, baseId: PortfoliosByHoldingIdProps) => baseId.id,
  (portfolios, id): PortfolioState[] => {
    return _getPortfoliosForHoldingId(portfolios, id);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}.${baseId.id}`,
  selectorCreator: createSelector,
});

interface BasePortfolioHoldingsProps {
  workspaceId: string;
  holdingIds: string[];
}

export const getPortfoliosByHoldingIds = createCachedSelector(
  (state: WorkspaceDataState, baseId: BasePortfolioHoldingsProps) =>
    getPortfoliosByWorkspaceId(state, baseId.workspaceId),
  (_state: WorkspaceDataState, baseId: BasePortfolioHoldingsProps) =>
    baseId.holdingIds,
  (allPortfolios, holdingIds) => {
    return holdingIds.map((holdingId) => {
      const portfolios = _getPortfoliosForHoldingId(allPortfolios, holdingId);
      return {
        portfolios,
        holdingId,
      };
    });
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
