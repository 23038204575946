import createCachedSelector from 're-reselect';
import { BondState } from '~src/data/store/reducers/asset/asset-types/bonds/reducer';
import { FundState } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { StockState } from '~src/data/store/reducers/asset/asset-types/stocks/reducer';
import { quotesSelectors } from '~src/data/store/reducers/finance/quote/reducer';
import { FinancialDataState } from '~src/data/store/reducers/reducers';

import { getPaperById } from '../papers/selectors';
import { createSelector } from 'reselect';

export const quotesSelector = (state: FinancialDataState) => {
  return state.finance.quotes.quotes;
};

export const getQuotes = (state: FinancialDataState) => {
  return quotesSelector(state);
};

export const getQuotesByPaperId = createCachedSelector(
  (state: FinancialDataState) => getQuotes(state),
  (_state: FinancialDataState, paperId: string | undefined) => paperId,
  (quotesState, paperId) => {
    if (!paperId) {
      return undefined;
    }
    const quotes = quotesSelectors.selectElementsByKey(quotesState, paperId);
    return quotes ? quotes : [];
  },
)({
  keySelector: (_state, quoteId) => quoteId ?? '',
  selectorCreator: createSelector,
});

// export const quoteByPaperSelector = (
//   quotes: Quote[],
//   quoteExchange: string,
//   paper: string
// ) => {
//   if (!quoteExchange || !paper) {
//     return undefined;
//   }
//   return quotes.find((quote) => {
//     if (quote.exchange && quote.paper) {
//       return quote.exchange === quoteExchange && quote.paper === paper;
//     }
//     return false;
//   });
// };

export const getPaperByStock = (
  state: FinancialDataState,
  stock: StockState | undefined,
) => getPaperById(state, stock?.paperId);

export const getPaperByFund = (
  state: FinancialDataState,
  fund: FundState | undefined,
) => getPaperById(state, fund?.paperId);

export const getPaperByBond = (
  state: FinancialDataState,
  bond: BondState | undefined,
) => getPaperById(state, bond?.paperId);

export const getQuotesByStock = createCachedSelector(
  (state: FinancialDataState) => getQuotes(state),
  (state: FinancialDataState, stock: StockState | undefined) =>
    getPaperByStock(state, stock),
  (quotes, paper) => {
    if (!paper) {
      return undefined;
    }
    return quotesSelectors.selectElementsByKey(quotes, paper.id);
  },
)({
  keySelector: (_state, stock) => `${stock?.id}`,
  selectorCreator: createSelector,
});

export const getQuotesByFund = createCachedSelector(
  (state: FinancialDataState) => getQuotes(state),
  (state: FinancialDataState, fund: FundState | undefined) =>
    getPaperByFund(state, fund),
  (quotes, paper) => {
    if (!paper) {
      return undefined;
    }
    return quotesSelectors.selectElementsByKey(quotes, paper.id);
  },
)({
  keySelector: (_state, fund) => `${fund?.id}`,
  selectorCreator: createSelector,
});

export const getQuotesByBond = createCachedSelector(
  (state: FinancialDataState) => getQuotes(state),
  (state: FinancialDataState, bond: BondState | undefined) =>
    getPaperByBond(state, bond),
  (quotes, paper) => {
    if (!paper) {
      return undefined;
    }
    return quotesSelectors.selectElementsByKey(quotes, paper.id);
  },
)({
  keySelector: (_state, bond) => `${bond?.id}`,
  selectorCreator: createSelector,
});
