import { handleCryptos } from '~src/data/store/modules/assets/cryptos/handler';
import { cryptosActions } from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchCryptos(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetCryptosDocument;
    const variables: graphqlWorkspaceTypes.GetCryptosQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getCryptos) {
        return handleCryptos(workspaceId, dispatch, data.getCryptos);
      }
      return [];
    });
  };
}

export function createCryptos(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateCryptoInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateCryptosDocument;
    const variables: graphqlWorkspaceTypes.CreateCryptosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createCryptos) {
        return handleCryptos(workspaceId, dispatch, data.createCryptos);
      }
      return [];
    });
  };
}

export function updateCryptos(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateCryptoInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateCryptosDocument;
    const variables: graphqlWorkspaceTypes.UpdateCryptosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateCryptos) {
        return handleCryptos(workspaceId, dispatch, data.updateCryptos);
      }
      return [];
    });
  };
}

export function upsertCryptos(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertCryptoInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertCryptosDocument;
    const variables: graphqlWorkspaceTypes.UpsertCryptosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertCryptos) {
        return handleCryptos(workspaceId, dispatch, data.upsertCryptos);
      }
      return [];
    });
  };
}

export function deleteCryptos(
  tenantId: string,
  workspaceId: string,
  cryptos: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteCryptosDocument;
    cryptos.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteCryptoInputType[] = cryptos.map(
      (crypto) => {
        return {
          id: crypto.id,
        };
      },
    );
    const variables: graphqlWorkspaceTypes.DeleteCryptosMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteCryptos) {
        const deletedCryptos = intersectionSorted(
          cryptos,
          data.deleteCryptos,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(cryptosActions.removeManyElements(deletedCryptos));
      }
      return [];
    });
  };
}
