import * as graphql from 'graphql';
import * as retry from 'retry';
import * as socketio from 'socket.io-client';
import * as uuid from 'uuid';
import { appConfig } from '~src/app-config';
import { SocketEmitter } from '~src/services/request/socket-emitter';
import { SocketListener } from '~src/services/request/socket-listener';

import { Logger, LogLevel } from '@pladdenico/common';
import { User } from '@pladdenico/models';

import { ApiError } from './api-error';
import { Subscription } from './subscription';

export class Api {
  private _socket: socketio.Socket | undefined;
  private _base: string;
  private _id: string;
  private _logger: Logger;
  private _connectTimeout: NodeJS.Timeout | undefined;
  // private _subscriptions: Array<{
  //   subscription: Subscription;
  //   listener: (o: unknown) => void;
  // }>;
  private _subscriptions: Map<
    string,
    { subscription: Subscription; listener: (o: unknown) => void }
  >;

  constructor(base: string) {
    this._logger = new Logger('Api', LogLevel.INFO);
    this._base = base;
    this._id = uuid.v1();
    // this._subscriptions = [];
    this._subscriptions = new Map();
  }

  public connect(user: User, token: string, listeners?: SocketListener[]) {
    try {
      this._socket = socketio.io(`${appConfig.apiBaseUrl}/${this._base}`, {
        query: {
          token: `${token}`,
          user: JSON.stringify(user),
        },
        transports: ['websocket'],
      });
    } catch (err) {
      console.log('ERROR in init of socketio');
      throw err;
    }
    this._socket.on('connect', () => {
      console.log('Connected', this._socket?.id);
      this.resubscribeAll();
    });
    this._socket.on('disconnect', (reason: any) => {
      console.log('Api - Disconnected', JSON.stringify(reason));
      if (
        reason === 'io server disconnect' ||
        reason === 'io client disconnect'
      ) {
        this._connectTimeout = setTimeout(() => {
          // this.connect(user, token);
          this._socket?.connect();
          this._connectTimeout = undefined;
        }, 3000);
      }
    });
    this._socket.on('error', (err: any) => {
      console.log('Api - error', JSON.stringify(err));
      // if (this._socket) {
      // this._socket?.close();
      // }
      if (!this._connectTimeout) {
        this._connectTimeout = setTimeout(() => {
          // this.connect(user, token);
          this._socket?.connect();
          this._connectTimeout = undefined;
        }, 3000);
      }
    });
    this._socket.on('connect_error', (err: any) => {
      console.log('Api - connect_error', JSON.stringify(err));
    });
    this._socket.on('connect_timeout', () => {
      console.log('Api - connect_timeout');
    });
    this._socket.on('reconnect', () => {
      console.log('Api - reconnect');
    });
    this._socket.on('reconnect_error', (err: any) => {
      console.log('Api - reconnect_error', JSON.stringify(err));
    });
    // this._socket.on('event', (data: any) => {
    //   console.log('Event', data);
    // });
    // this._socket.onAny((event) => {
    //   console.log(`got ${event}`);
    // });

    if (listeners) {
      for (let i = 0; i < listeners.length; ++i) {
        this._socket.on(listeners[i].name, listeners[i].ev);
      }
    }
  }

  public emit(emitter: SocketEmitter) {
    const cb = (error: Error | null, socket: socketio.Socket | undefined) => {
      if (error) {
        throw error;
      } else if (socket) {
        socket.emit(
          emitter.name,
          // 'quote',
          emitter.args,
          // { id: this._id, paper, fromDate, toDate },
          emitter.callback,
        );
      }
    };
    const operation = retry.operation({
      forever: true,
    });
    this._faultTolerantSocketResolve(cb, operation);
  }

  public executeQuery(
    ev: string,
    input: unknown,
    callback: (result: any) => void,
  ) {
    const cb = (error: Error | null, socket: socketio.Socket | undefined) => {
      if (error) {
        throw error;
      } else if (socket) {
        this._logger.debug(() => `input: ${JSON.stringify(input)}`);
        socket.emit(
          ev,
          {
            input: JSON.stringify(input),
          },
          callback,
        );
      }
    };
    const operation = retry.operation({
      retries: 5,
    });
    this._faultTolerantSocketResolve(cb, operation);
  }

  public executeGraphQLQuery<Context>(
    ev: string,
    query: graphql.DocumentNode,
    variables: unknown,
    context: Context,
    callback: (result: any) => void,
  ) {
    const cb = (error: Error | null, socket: socketio.Socket | undefined) => {
      if (error) {
        throw error;
      } else if (socket) {
        this._logger.debug(
          () =>
            `query: ${JSON.stringify(query)}, variables: ${JSON.stringify(
              variables,
            )}, context: ${JSON.stringify(context)}`,
        );
        socket.emit(
          ev,
          {
            query: JSON.stringify(query),
            variables: JSON.stringify(variables),
            context,
          },
          callback,
        );
      }
    };
    const operation = retry.operation({
      retries: 5,
    });
    this._faultTolerantSocketResolve(cb, operation);
  }

  private _faultTolerantSocketResolve<R>(
    callback: (error: Error | null, socket: socketio.Socket | undefined) => R,
    operation: retry.RetryOperation,
  ) {
    operation.attempt((_currentAttempt) => {
      let error: ApiError | undefined;
      if (!this._socket) {
        error = new ApiError('Not initialized (connect)');
      }
      if (operation.retry(error)) {
        return;
      }
      return callback(error ? operation.mainError() : null, this._socket);
    });
  }

  private _subscribe(
    socket: socketio.Socket,
    subscription: Subscription,
    callback: () => void,
  ) {
    const listener = (o: unknown) => {
      subscription.handle(o);
    };
    const existingSubscription = this._subscriptions.get(subscription.path);
    if (existingSubscription != null) {
      socket.off(existingSubscription.subscription.path);
    }
    this._subscriptions.set(subscription.path, { subscription, listener });
    socket.on(subscription.path, listener);
    socket.emit('subscribe', { subscription }, callback);
  }

  private _unsubscribe(socket: socketio.Socket, subscription: Subscription) {
    const existingSubscription = this._subscriptions.get(subscription.path);
    if (existingSubscription != null) {
      socket.off(
        existingSubscription.subscription.path,
        existingSubscription.listener,
      );
      this._subscriptions.delete(existingSubscription.subscription.path);
    }
    socket.emit('unsubscribe', { subscription });
  }

  public subscribe(subscription: Subscription, callback: () => void) {
    const cb = (error: Error | null, socket: socketio.Socket | undefined) => {
      if (error) {
        throw error;
      } else if (socket) {
        this._subscribe(socket, subscription, callback);
      }
    };
    const operation = retry.operation({
      forever: true,
    });
    this._faultTolerantSocketResolve(cb, operation);
  }

  public unsubscribe(subscription: Subscription) {
    const cb = (error: Error | null, socket: socketio.Socket | undefined) => {
      if (error) {
        throw error;
      } else if (socket) {
        this._unsubscribe(socket, subscription);
      }
    };
    const operation = retry.operation({
      forever: true,
    });
    this._faultTolerantSocketResolve(cb, operation);
  }

  private resubscribeAll() {
    this._subscriptions.forEach((subscription) => {
      // this.unsubscribe(subscription.subscription);
      this.subscribe(subscription.subscription, () => {
        this._logger.debug(
          () => `Reconnected ${subscription.subscription.path}`,
        );
      });
    });
  }
}
