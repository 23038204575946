import { useParams } from 'react-router-dom';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useTenantBreadcrumbs } from '~src/domain/tenant/components/top-bar/breadcrumb/tenant-breadcrumb';
import { invitationsRootPath } from '~src/navigation/paths/tenant/invitations/paths';

function useInvitationsBreadcrumbs(last: boolean) {
  const { tenantId } = useParams<{
    tenantId: string;
  }>();
  const tenantBreadcrumbs = useTenantBreadcrumbs(false);
  if (tenantId != null) {
    const holdingElement = last ? (
      <Typography color="inherit" key={tenantId}>
        invitations
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={invitationsRootPath(tenantId)}
        to={invitationsRootPath(tenantId)}
      >
        invitations
      </LinkRouter>
    );
    return [tenantBreadcrumbs, holdingElement];
  }
  return [];
}
export function InvitationsBreadcrumbComponent(props: { last: boolean }) {
  const invitationsBreadcrumbs = useInvitationsBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {invitationsBreadcrumbs}
    </Breadcrumbs>
  );
}
