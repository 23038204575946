import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getPaperById } from '~src/data/store/selectors/finance/papers/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import {
  paperRootPath,
  papersRootPath,
} from '~src/navigation/paths/workspace/finance/paper/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useWorkspaceBreadcrumbs } from '~src/domain/workspace/components/top-bar/breadcrumb/workspace-breadcrumbs';

function usePaperBreadcrumbs(_last: boolean) {
  const { tenantId, workspaceId, paperId } = useParams<{
    tenantId: string;
    workspaceId: string;
    paperId: string;
  }>();
  const papersBreadcrumbs = usePapersBreadcrumbs(false);
  const paper = useSelector((state: RootState) => {
    if (paperId != null) {
      return getPaperById(selectFinancialData(state), paperId);
    }
  });
  if (paper && tenantId != null && workspaceId != null) {
    return [
      papersBreadcrumbs,
      <Typography
        color="inherit"
        key={paperRootPath(tenantId, workspaceId, paper.id)}
      >
        {paper.name}
      </Typography>,
    ];
  }
  return [];
}
function usePapersBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);
  if (tenantId != null && workspaceId != null) {
    const paperElement = last ? (
      <Typography color="inherit" key={papersRootPath(tenantId, workspaceId)}>
        papers
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={papersRootPath(tenantId, workspaceId)}
        to={papersRootPath(tenantId, workspaceId)}
      >
        papers
      </LinkRouter>
    );
    return [workspaceBreadcrumb, paperElement];
  }
  return [];
}
export function PaperBreadcrumbComponent() {
  const paperBreadcrumbs = usePaperBreadcrumbs(true);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {paperBreadcrumbs}
    </Breadcrumbs>
  );
}
export function PapersBreadcrumbComponent(props: { last: boolean }) {
  const papersBreadcrumbs = usePapersBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {papersBreadcrumbs}
    </Breadcrumbs>
  );
}
