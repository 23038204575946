// import history from '~src/navigation/history';
import { userMessagesPath } from '~src/navigation/paths/user/paths';

import MailIcon from '@mui/icons-material/Mail';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

export function MessagesBadgeComponent() {
  const navigate = useNavigate();
  function handleGoToMessages() {
    navigate(userMessagesPath);
  }

  return (
    <IconButton
      aria-label="show 4 new mails"
      color="inherit"
      onClick={handleGoToMessages}
    >
      <Badge badgeContent={4} color="secondary">
        <MailIcon />
      </Badge>
    </IconButton>
  );
}
