import { combineReducers } from 'redux';
// import {
//   forecastElementsReducer,
//   initialForecastElementsState,
// } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/element/reducer';
import {
  forecastsReducer,
  initialForecastsState,
} from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import {
  initialSelectedForecastsState,
  selectedForecastsReducer,
} from '~src/data/store/reducers/workspace/projects/scenario/forecasts/selected/reducer';

export const forecastInitialState = {
  forecasts: initialForecastsState,
  // elements: initialForecastElementsState,
  selectedForecasts: initialSelectedForecastsState,
};

export const forecastReducer = combineReducers({
  forecasts: forecastsReducer,
  // elements: forecastElementsReducer,
  selectedForecasts: selectedForecastsReducer,
});
