import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updateHoldings } from '~src/data/store/modules/holdings/base/requests';
import { updateStockHoldings } from '~src/data/store/modules/holdings/stock/requests';
import { StockHoldingState } from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { usePortfolioHoldings } from '~src/hooks/actions/workspace/project/portfolio/holding/use-portfolio-holdings';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

import { EditFormComponent } from './edit-form.component';

interface Props extends React.PropsWithChildren<unknown> {
  holding: HoldingState;
  stockHolding: StockHoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  open: boolean;
  handleClose: () => void;
}

export const EditDialogComponent = React.memo((props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [holdingInput, setHoldingInput] =
    React.useState<graphqlWorkspaceOperations.UpdateHoldingInputType>({
      id: props.holding.id,
      projectId: props.projectId,
      description: props.holding.description,
      name: props.holding.name,
      type: props.holding.type ?? '',
    });
  const [stockHoldingInput, setStockHoldingInput] =
    React.useState<graphqlWorkspaceTypes.UpdateStockHoldingInputType>({
      id: props.stockHolding.id,
      stockId: props.stockHolding.stockId,
      holdingId: props.stockHolding.holdingId,
    });
  const { portfolios, setPortfolios, updatePortfolios } = usePortfolioHoldings(
    props.workspace,
    props.portfolios,
  );

  const updateHolding = () => {
    dispatch(
      updateHoldings(props.workspace.tenantId, props.workspace.id, [
        holdingInput,
      ]),
    )
      .then((holdings) => {
        const holding = holdings[0];
        dispatch(
          updateStockHoldings(props.workspace.tenantId, props.workspace.id, [
            stockHoldingInput,
          ]),
        )
          .then((_holdings) => {
            return updatePortfolios(holding)
              .then(() => props.handleClose())
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <DialogComponent
      dialogProps={{
        open: props.open,
        onClose: props.handleClose,
        fullWidth: true,
      }}
    >
      <DialogTitle id="form-dialog-title">Edit holding</DialogTitle>
      <DialogContent>
        <EditFormComponent
          workspace={props.workspace}
          holding={props.holding}
          projectId={props.projectId}
          portfolios={portfolios}
          setPortfolios={setPortfolios}
          holdingInput={holdingInput}
          setHoldingInput={setHoldingInput}
          stockHoldingInput={stockHoldingInput}
          setStockHoldingInput={setStockHoldingInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={updateHolding} color="primary">
          Save
        </Button>
      </DialogActions>
    </DialogComponent>
  );
});
