import { combineReducers } from 'redux';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';

import { RecordState, strcmp } from '@pladdenico/common';

export interface BaseQuoteId {
  id: string;
  paperId: string;
  date: Date;
}

export type QuoteState = Omit<graphqlFinanceTypes.Quote, 'paper'> & {
  paperId: string;
  date: Date;
};

const initialState: RecordState<QuoteState, string>[] = [];

const selectKeyId = (quote: BaseQuoteId) => quote.paperId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseQuoteId, b: BaseQuoteId) => {
  return a.date.getTime() - b.date.getTime();
  // strcmp(a.id, b.id);
};

const adapter = new EntityRecordAdapter<BaseQuoteId, QuoteState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const quotesSlice = createBaseRecordSlice('quotes', adapter);
export const quotesSelectors = adapter.selectors;

export const quotesReducer = quotesSlice.reducer;
export const quotesActions = quotesSlice.actions;

export const initialQuoteState = {
  quotes: initialState,
};

export const quoteReducer = combineReducers({
  quotes: quotesReducer,
});
