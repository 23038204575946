import { GraphQLError } from 'graphql';
import {
  RequestStatus,
  RequestStatuses,
} from '~src/services/request/request-status';

import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { GraphqlResultHandler } from './graphql-result-handler';

export abstract class RequestStatusHandler {
  public abstract handleOne(requestStatus: RequestStatus): any;
  public abstract handleMany(requestStatuses: RequestStatus[]): any;
}

export class GraphqlResultStatusHandler<
  TQuery,
  TQueryVariables,
> extends GraphqlResultHandler<TQuery, TQueryVariables> {
  private _handler: RequestStatusHandler;
  private _queryId: string | string[];
  constructor(queryId: string | string[], handler: RequestStatusHandler) {
    super();
    this._queryId = queryId;
    this._handler = handler;
  }

  public pre(
    _node: TypedDocumentNode<TQuery, TQueryVariables>,
    _variables: TQueryVariables,
  ): void {
    this._requestAction(RequestStatuses.requesting);
  }

  public handleErrors(errors: readonly GraphQLError[]): GraphQLError[] {
    this._requestAction(RequestStatuses.failed, errors);
    throw new Error(
      'GraphqlResultStatusHandler: errors' + JSON.stringify(errors),
    );
  }

  public handleSuccess(data: TQuery): TQuery {
    this._requestAction(RequestStatuses.success);
    return data;
  }

  public handleDone(): void {
    this._requestAction(RequestStatuses.done);
  }

  public handleEmpty(): Error {
    console.log('NO ERRORS AND NO DATA');
    // return undefined;
    return { name: 'EmptyError', message: 'No errors and no data' };
  }

  private _requestAction(actionType: RequestStatuses, err?: any) {
    const idToRequest = (id: string): RequestStatus => {
      return {
        requestStatus: actionType,
        id,
        err,
      };
    };
    if (this._queryId instanceof Array) {
      const requestStatuses = this._queryId.map((id) => {
        return idToRequest(id);
      });
      this._handler.handleMany(requestStatuses);
      // if (this._handler) {
      //   this._dispatch(this._handler.addMany(requestStatuses));
      // }
      // this._dispatch(multipleAction(requestStatuses, this._type));
    } else {
      const requestStatus = idToRequest(this._queryId);
      this._handler.handleOne(requestStatus);
      // if (this._handler) {
      //   this._dispatch(this._handler.addOne(requestStatus));
      // }
      // this._dispatch(singleAction(requestStatus, this._type));
    }
  }
}
