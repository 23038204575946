import React from 'react';
import { AllocationElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/allocation-element-description.component';
import { AllocationGoalElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/allocation-goal-element-description.component';
import { BondElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/bond-element-description.component';
import { CommitmentsElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/commitments-element-description.component';
import { CostElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/cost-element-description.component';
import { DetailedDescriptionElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/detailed-description-element-description.component';
import { EfficientFrontierDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/efficient-frontier-description.component';
import { EventsElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/events-element-description.component';
import { FavoriteHoldingsElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/favorite-holdings-description.component';
import { FundElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/fund-element-description.component';
import { GoalRuleElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/goal-rule-element-description.component';
import { GroupedIRRElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/grouped-irr-element-description.component';
import { GroupedValuationElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/grouped-valuation-element-description.component';
import { HeaderElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/header-element-description.component';
import { HoldingsViewElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/holdings-view-element-description.component';
import { ImageElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/image-element-description.component';
import { IrrElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/irr-element-description.component';
import { OrganizationElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/organization-element-description.component';
import { OwnershipElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/ownership-element-description.component';
import { PaperElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/paper-element-description.component';
import { RegionalValuationElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/regional-valuation-element-description.component';
import { SummaryElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/summary-element-description.component';
import { TextEditorElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/text-editor-element-description.component';
import { WaterfallElementDescriptionComponent } from '~src/domain/workspace/components/common/dashboard/element/descriptions/types/waterfall-element-description.component';

interface Props extends React.PropsWithChildren<unknown> {
  type: string;
  isSelected: boolean;
}

export const DashboardElementDescriptionComponent = (props: Props) => {
  const { type } = props;

  switch (type) {
    case 'header':
      return <HeaderElementDescriptionComponent />;
    case 'summary':
      return <SummaryElementDescriptionComponent />;
    case 'image':
      return <ImageElementDescriptionComponent />;
    case 'text':
      return <TextEditorElementDescriptionComponent />;
    case 'favoriteHoldings':
      return <FavoriteHoldingsElementDescriptionComponent />;
    case 'efficientFrontier':
      return <EfficientFrontierDescriptionComponent />;
    case 'events':
      return <EventsElementDescriptionComponent />;
    case 'valuationGeographical':
      return <RegionalValuationElementDescriptionComponent />;
    case 'allocation':
      return <AllocationElementDescriptionComponent />;
    case 'allocationGoal':
      return <AllocationGoalElementDescriptionComponent />;
    case 'waterfall':
      return <WaterfallElementDescriptionComponent />;
    case 'contributors':
      return <HoldingsViewElementDescriptionComponent />;
    case 'irr':
      return <IrrElementDescriptionComponent />;
    case 'portfolioAssetGroupsIrr':
      return <GroupedIRRElementDescriptionComponent />;
    case 'costs':
      return <CostElementDescriptionComponent />;
    case 'organization':
      return <OrganizationElementDescriptionComponent />;
    case 'portfolioAssetGroupValuation':
      return <GroupedValuationElementDescriptionComponent />;
    case 'ownership':
      return <OwnershipElementDescriptionComponent />;
    case 'goal':
      return <GoalRuleElementDescriptionComponent />;
    case 'commitments':
      return <CommitmentsElementDescriptionComponent />;
    case 'detailedDescription':
      return <DetailedDescriptionElementDescriptionComponent />;
    case 'bond':
      return <BondElementDescriptionComponent />;
    case 'fund':
      return <FundElementDescriptionComponent />;
    case 'paper':
      return <PaperElementDescriptionComponent />;
  }
  return <>{type}</>;
};
