import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { Event } from '~src/services/graphql/workspace/client/graphql';

import { strcmp } from '@pladdenico/common';

export function parseEvent(event: Event): EventState {
  return {
    id: event.id,
    name: event.name ?? '',
    projectId: event.project?.id ?? '',
    description: event.description ?? '',
    date: new Date(event.date),
    portfolioIds: (event.portfolios?.map((p) => p.id) ?? []).sort((a, b) =>
      strcmp(a, b),
    ),
    holdingIds: (event.holdings?.map((p) => p.id) ?? []).sort((a, b) =>
      strcmp(a, b),
    ),
    entityIds: (event.entities?.map((p) => p.id) ?? []).sort((a, b) =>
      strcmp(a, b),
    ),
    transactionIds: (event.transactions?.map((p) => p.id) ?? []).sort((a, b) =>
      strcmp(a, b),
    ),
    fileIds: (event.files?.map((p) => p.id) ?? []).sort((a, b) => strcmp(a, b)),
  };
}
