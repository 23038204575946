import moment from 'moment';
import numbro from 'numbro';
import React from 'react';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { DnbEditTransactionComponent } from '~src/domain/workspace/components/data/adapters/dnb/form/edit-transaction.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { DnbTransactionState } from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';

import EditIcon from '@mui/icons-material/Edit';
import { colors, Grid, IconButton, Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  dnbTransaction: DnbTransactionState;
  active: boolean;
  onClick: (ctrlKey: boolean, transaction: DnbTransactionState) => void;
}

export const DnbImportedTransactionComponent = React.memo((props: Props) => {
  const { projectId, dnbTransaction } = props;

  const holding = useSelector((state: RootState) =>
    getHoldingById(selectWorkspaceData(state), {
      id: dnbTransaction.holdingId,
      projectId,
    }),
  );
  if (holding) {
    return (
      <DnbImportedTransactionWithHoldingComponent
        {...props}
        holding={holding}
      />
    );
  }
  return <>Missing holding</>;
});

interface PropsWithHolding extends Props {
  holding: HoldingState;
}

export const DnbImportedTransactionWithHoldingComponent = React.memo(
  (props: PropsWithHolding) => {
    const {
      workspace,
      projectId,
      dnbTransaction,
      // holding,
      active,
      onClick,
      // valueOfHolding,
      // transactionPrevious,
    } = props;
    const editDialog = useVisible();
    // const transactions = useSelector((state: RootState) =>
    //   getSortedDnbTransactions(selectDataImportData(state), holding.id)
    // );
    // const account = useSelector((state: RootState) =>
    //   getDnbAccount(selectDataImportData(state), { holdingId: holding.id })
    // );

    // const valueOfHolding = React.useMemo(() => {
    //   let value = account?.valuationStart ?? 0;
    //   for (
    //     let i = 0;
    //     i < transactions.length && dnbTransaction.id !== transactions[i].id;
    //     ++i
    //   ) {
    //     value += transactions[i].value;
    //   }
    //   return value;
    // }, [account?.valuationStart, dnbTransaction.id, transactions]);

    // const dispatch = useDispatch<AppDispatch>();
    // const dialog = useModal();

    // const transactionType: TransactionType = React.useMemo(() => {
    //   if (
    //     dnbTransaction.description === 'Lønn' ||
    //     dnbTransaction.description === 'Omkostninger'
    //   ) {
    //     return TransactionType.Cost;
    //   } else if (dnbTransaction.description === 'Utbytte') {
    //     return TransactionType.Dividend;
    //   } else if (dnbTransaction.description === 'Renter') {
    //     return TransactionType.Interest;
    //   } else if (dnbTransaction.value < 0) {
    //     return TransactionType.Buy;
    //   } else {
    //     return TransactionType.Sell;
    //   }
    // }, [dnbTransaction.description, dnbTransaction.value]);
    // const initialFormValues: FormValues = React.useMemo(() => {
    //   let holdingConfig: HoldingConfig;
    //   if (transactionType === TransactionType.Buy) {
    //     holdingConfig = getHoldingConfigWithdrawal(holding.id);
    //   } else if (
    //     transactionType === TransactionType.Sell ||
    //     transactionType === TransactionType.Dividend ||
    //     transactionType === TransactionType.Interest
    //   ) {
    //     holdingConfig = getHoldingConfigDeposit(holding.id);
    //   } else if (transactionType === TransactionType.Cost) {
    //     holdingConfig = getHoldingConfigCost(holding.id);
    //     // } else {
    //     //   holdingConfig = getHoldingConfigCustom(holding);
    //   } else {
    //     holdingConfig = getHoldingConfigCost(holding.id);
    //   }

    //   const holdingInputData = createHoldingInputDataFromConfig(
    //     holdingConfig,
    //     dnbTransaction.date
    //   );
    //   holdingInputData.transferInputData.base.date = dnbTransaction.date;
    //   holdingInputData.valuationInputData.date = dnbTransaction.date;
    //   holdingInputData.transferInputData.base.value = Math.abs(
    //     dnbTransaction.value
    //   );
    //   holdingInputData.expectedResultingValue =
    //     valueOfHolding + dnbTransaction.value;

    //   return {
    //     id: uuid.v1(),
    //     projectId,
    //     title: '',
    //     description: dnbTransaction.name,
    //     date: dnbTransaction.date,
    //     holdings: [
    //       holdingInputData,
    //       // buyHoldingInputData
    //     ],
    //     type: transactionType,
    //     // type: '',
    //   };
    // }, [
    //   transactionType,
    //   dnbTransaction.date,
    //   dnbTransaction.value,
    //   dnbTransaction.name,
    //   valueOfHolding,
    //   projectId,
    //   holding,
    // ]);

    // const setHandled = React.useCallback(
    //   (transaction: TransactionState) => {
    //     dispatch(
    //       dnbTransactionsActions.upsertOneElement({
    //         ...dnbTransaction,
    //         handled: true,
    //         transactionId: transaction.id,
    //       })
    //     );
    //   },
    //   [dispatch, dnbTransaction]
    // );

    // const handleSkipped = React.useCallback(() => {
    //   dispatch(
    //     dnbTransactionsActions.upsertOneElement({
    //       ...dnbTransaction,
    //       handled: true,
    //     })
    //   );
    // }, [dispatch, dnbTransaction]);

    // const setUnhandled = React.useCallback(() => {
    //   transactionPrevious();
    // }, [transactionPrevious]);

    // const handleSuccess = React.useCallback(
    //   (transaction: TransactionState) => {
    //     setHandled(transaction);
    //     dialog.close();
    //   },
    //   [dialog, setHandled]
    // );

    const handleEditSuccess = React.useCallback(() => {
      editDialog.close();
    }, [editDialog]);

    const backgroundColor = React.useMemo(() => {
      if (dnbTransaction.handled) {
        if (dnbTransaction.transactionId == null) {
          return colors.deepPurple[50];
        } else if (active) {
          return colors.green[800];
        } else {
          return colors.green[300];
        }
      } else if (active) {
        return colors.blue[700];
      }
      return colors.blue[200];
    }, [active, dnbTransaction.handled, dnbTransaction.transactionId]);

    const openEditDialog: React.MouseEventHandler<HTMLButtonElement> =
      React.useCallback(
        (event) => {
          event.stopPropagation();
          editDialog.open();
        },
        [editDialog],
      );

    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        onClick={(event) => event && onClick(event.ctrlKey, dnbTransaction)}
      >
        <Grid
          container
          item
          xs={1}
          alignSelf="stretch"
          // justifyContent="center"
          alignItems="center"
          sx={{ pl: 1, color: 'white', backgroundColor }}
        >
          <Typography variant="body2">Dnb</Typography>
        </Grid>
        <Grid item xs={1} sx={{ pl: 1 }}>
          <Typography variant="body2">
            {moment(dnbTransaction.date).format('YYYY-MM-DD')}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ pl: 1 }}>
          <Typography variant="body2">{dnbTransaction.name}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ pl: 1 }}>
          <Typography variant="body2">{dnbTransaction.description}</Typography>
        </Grid>
        <Grid container item xs={1} sx={{ pr: 1 }} justifyContent="flex-end">
          <Typography variant="body2" margin={1}>
            {numbro(dnbTransaction.value).format('0,0')}
          </Typography>
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end" />
        <Grid container item xs={1} justifyContent="flex-end" />
        <Grid container item xs={2} sx={{ pl: 1 }}>
          <Typography variant="body2">
            {dnbTransaction.transactionId}
          </Typography>
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          {/* <IconButton
            onClick={dialog.open}
            disabled={!active || dnbTransaction.handled}
          >
            <AddCircleIcon />
          </IconButton> */}
          <IconButton
            onClick={openEditDialog}
            disabled={!active || dnbTransaction.handled}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton
              onClick={setUnhandled}
              disabled={!active || dnbTransaction.handled}
            >
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              onClick={handleSkipped}
              disabled={!active || dnbTransaction.handled}
            >
              <SkipNextIcon />
            </IconButton> */}
        </Grid>
        {/* <CreateComponent
          workspace={workspace}
          projectId={projectId}
          // holding={holding}
          open={dialog.isShowing}
          handleClose={dialog.close}
          handleSuccess={handleSuccess}
          initialFormValues={initialFormValues}
        /> */}
        <DnbEditTransactionComponent
          workspace={workspace}
          projectId={projectId}
          open={editDialog.isShowing}
          handleClose={editDialog.close}
          handleSuccess={handleEditSuccess}
          transaction={dnbTransaction}
        />
      </Grid>
    );
  },
);
