import React from 'react';
// import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
// import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
// import { getForecastElementsByForecastId } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/element/selectors';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { HoldingForecastElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/holding/holding-forecast-element.component';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

// import AddIcon from '@mui/icons-material/Add';
import { Box, Grid } from '@mui/material';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
// import { useCreateForecastElementDialogComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-create-dialog.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  scenario: Scenario;
  budget: Budget;
  forecast: ForecastState;
}

export const ScenarioHoldingForecastElementComponent = React.memo(
  (props: Props) => {
    const { workspace, project, scenario, budget, holding, forecast } = props;

    // const { dialog: forecastElementDialog, modal: forecastElementModal } =
    //   useCreateForecastElementDialogComponent(forecast, undefined);

    //   const elements = useSelector((state: RootState) =>
    //   getForecastElementsByForecastId(selectWorkspaceData(state), forecast.id)
    // );
    // const element = useSelector((state: RootState) =>
    //   getForecastElementByForecastId(selectWorkspaceData(state), forecast.id)
    // );

    // const addForecastElement = forecast && (
    //   <>
    //     <IconButton onClick={forecastElementModal.open} color="inherit">
    //       <AddIcon />
    //     </IconButton>
    //     {forecastElementDialog}
    //   </>
    // );

    const forecastElementsElement = (
      <Grid
        item
        xs={12}
        sx={{
          pt: 1,
        }}
      >
        <Box>
          <HoldingForecastElementComponent
            scenario={scenario}
            budget={budget}
            forecast={forecast}
            forecastElement={forecast.element}
            workspace={workspace}
            project={project}
            holding={holding}
          />
          {/* {addForecastElement} */}
        </Box>
      </Grid>
    );
    return <>{forecastElementsElement}</>;
  },
);
