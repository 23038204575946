import * as React from 'react';
import { useDispatch } from 'react-redux';
// import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { DashboardMenuDiv } from '~src/components/dashboard/dashboard-menu-div';
import { fetchEvents } from '~src/data/store/modules/workspaces/projects/events/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { useEventsAlgorithm } from '~src/domain/workspace/components/project/event/algorithm/use-algorithm';
import { useEventViewFields } from '~src/domain/workspace/components/project/event/form/use-event-view-fields';
import { Toolbar } from '~src/domain/workspace/components/project/event/toolbar.component';
import { PortfolioConfigComponent } from '~src/domain/workspace/components/project/portfolio/config/portfolio-config.component';
import { useDashboardConfig } from '~src/domain/workspace/components/project/portfolio/dashboard/use-dashboard-config';
import { ConfigButtonComponent } from '~src/domain/workspace/components/project/types/portfolio-project/config-button.component';
import { useEventActions } from '~src/hooks/actions/workspace/project/event/use-event-actions';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import { Box, Drawer, Theme } from '@mui/material';
import { TableComponent, TableOperations } from '@pladdenico/table';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  configContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  root: {
    // marginTop: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
  },
}));

export const ProjectEventsComponent = React.memo((props: Props) => {
  const { workspace, portfolioProject, portfolio, project } = props;
  const { classes } = useStyles();

  const { portfolioConfig, portfolioDashboardConfig } = useDashboardConfig({
    workspace,
    portfolio,
  });

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    dispatch(fetchEvents(workspace.tenantId, workspace.id));
  }, [dispatch, workspace.id, workspace.tenantId]);

  const { isShowing: configOpen, toggle: toggleConfig } = useVisible();

  const algo = useEventsAlgorithm(workspace, project);
  const viewFields = useEventViewFields(workspace, project.id, algo.fields);

  const { onDelete, onUpdate } = useEventActions(
    workspace.tenantId,
    workspace.id,
  );

  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(algo.algoData.filtered);
  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<EventState>
        data={algo.algoData.filtered}
        fields={viewFields}
        selectedData={selectedData}
        selectable={false}
        filters={algo.algoData.filters}
        dispatchFilters={algo.algoData.dispatchFilters}
        sorters={algo.algoData.sorters}
        dispatchSorters={algo.algoData.dispatchSorters}
        editable={{ onRowUpdate: onUpdate, onRowDelete: onDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        isSelected={isSelected}
      />
    );
  }, [
    algo.algoData.dispatchFilters,
    algo.algoData.dispatchSorters,
    algo.algoData.filtered,
    algo.algoData.filters,
    algo.algoData.sorters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onDelete,
    onUpdate,
    selectedData,
    viewFields,
  ]);

  return (
    <Box>
      <DashboardMenuDiv config={{ open: configOpen, alignSelf: 'flex-start' }}>
        <Box sx={{ mt: 1, ml: 1 }} className={classes.configContainer}>
          <ConfigButtonComponent
            isVisible={configOpen}
            toggleConfig={toggleConfig}
            text="configuration"
            variant="contained"
          />
        </Box>
      </DashboardMenuDiv>
      <div className={classes.root}>
        <Toolbar
          workspace={workspace}
          project={project}
          algoData={algo.algoData}
        />
        {tableComponent}
        <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
          <PortfolioConfigComponent
            workspace={workspace}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            portfolioConfig={portfolioConfig}
            dashboardConfig={portfolioDashboardConfig}
          />
        </Drawer>
      </div>
    </Box>
  );
});
