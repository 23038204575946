import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { getHoldingsByProjectId } from '~src/data/store/selectors/holding/holdings/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { useActiveElement } from '~src/domain/workspace/components/common/dashboard/hooks/use-active-element.hook';
import { OwnershipComponent } from '~src/domain/workspace/components/ownership/ownership.component';
import { CostsComponent } from '~src/domain/workspace/components/project/costs/costs.component';
import { SummaryComponent } from '~src/domain/workspace/components/project/holding/common/summary.component';
// import { ProjectHoldingGroupsIrrComponent } from '~src/domain/workspace/components/project/holding/irr/project-holding-group-irr.component';
import { ProjectIrrComponent } from '~src/domain/workspace/components/project/holding/irr/project-irr.component';
import { HoldingsValuationRegionalComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/regional-valuation/holdings-valuation-regional.component';
import { HoldingsViewComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/holdings-view.component';
import { portfolioDashboardElements } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/hooks/portfolio-dashboard-elements';
import {
  HoldingsViewType,
  HoldingsViewTypes,
} from '~src/utils/interfaces/holdings-view-type';
import { SortOrder, SortOrders } from '~src/utils/interfaces/sort-order';
import { SummaryTypes } from '~src/utils/interfaces/summary-type';

import { Button, Drawer, Theme } from '@mui/material';
import {
  DashboardElementsBaseType,
  PortfolioDashboardElementsType,
} from '@pladdenico/models';

// import { DashboardEditSubComponentsComponent } from '../../../../../components/dashboard/dashboard-edit-sub-components.component';
// import { ProjectHoldingGroupValuationComponent } from '../../project/holding/valuation/project-holding-group-valuation.component';
import { ConfigComponent } from './config/config.component';
import {
  DashboardConfigState,
  useDashboardComponents,
} from './config/hooks/use-dashboard-components.hook';
import { DashboardHeaderComponent } from './header/dashboard-header.component';
import { ProjectHoldingGroupValuationComponent } from '~src/domain/workspace/components/project/holding/valuation/project-holding-group-valuation.component';
import { DashboardEditSubComponentsComponent } from '~src/components/dashboard/dashboard-edit-sub-components.component';
import { PeriodTypes } from '~src/utils/period/period-type';
import { IrrConfigData } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/irr/menu.component';
import { defaultBarChartFormat } from '~src/components/irr/bar-chart-format';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  fund: PrivateEquityFundState;
  dashboardConfig: DashboardConfigState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  configButton: {
    position: 'absolute',
    // marginTop: -46,
    right: theme.spacing(1),
    // zIndex: theme.zIndex.drawer,
  },

  root: {
    marginTop: theme.spacing(2),
  },

  components: {
    // height:
    // paddingTop: theme.spacing(6),
  },

  dashboardMenu: {
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      top: 63,
    },
    [theme.breakpoints.up('sm')]: {
      top: 80,
    },
    zIndex: theme.zIndex.modal,
  },
}));

const SubComponent = React.memo(
  ({
    workspace,
    project,
    fund,
    holdings,
    loading,
    active,
    setActive,
    setInactive,
    id,
    dashboardSubComponentType,
    remove,
  }: {
    workspace: WorkspaceState;
    project: ProjectState;
    fund: PrivateEquityFundState;
    holdings: HoldingState[];
    active: boolean;
    setActive: () => void;
    setInactive: () => void;
    loading: boolean;
    id: string;
    dashboardSubComponentType:
      | PortfolioDashboardElementsType
      | DashboardElementsBaseType;
    remove: (id: string) => void;
  }) => {
    const config = useSelector((state: RootState) =>
      getConfigById(selectWorkspaceData(state), { id: 'default' }),
    );
    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), { id: baseCurrencyId }),
    );
    const { period } = config;
    const updated = () => {
      return Promise.resolve({ id: '' });
    };
    const element: DashboardElementState = {
      id: '',
      name: '',
      type: 'header',
      config: {},
    };

    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }
    switch (dashboardSubComponentType) {
      case 'header':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <DashboardHeaderComponent workspace={workspace} fund={fund} />
          </ElementComponent>
        );
      case 'summary':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            inverted={true}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <SummaryComponent
              type={SummaryTypes.TotalValue}
              workspaceId={workspace.id}
              projectId={project.id}
              holdings={holdings}
              date={config.date}
              period={period}
              baseCurrency={baseCurrency}
              indexPaper={config.indexPaper}
              useLiveValue={config.useLiveValue}
            />
          </ElementComponent>
        );
      case 'valuationGeographical':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            segment={false}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <HoldingsValuationRegionalComponent
              workspaceId={workspace.id}
              projectId={project.id}
              holdings={holdings}
              date={config.date}
              currency={baseCurrency}
              format={{ fontColor: element.config.color }}
            />
          </ElementComponent>
        );
      case 'contributors':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <HoldingsViewComponent
              workspace={workspace}
              projectId={project.id}
              period={period}
              valuationDate={config.date}
              holdings={holdings}
              minimumValuationForHolding={
                (element.config.minimumValue as number) ??
                Number.MIN_SAFE_INTEGER
              }
              baseCurrency={baseCurrency}
              type={
                (element.config.type as HoldingsViewType) ??
                HoldingsViewTypes.Value
              }
              sortOrder={
                (element.config.sortOrder as SortOrder) ?? SortOrders.Decreasing
              }
              format={{}}
            />
          </ElementComponent>
        );
      case 'irr': {
        const irrConfigData: IrrConfigData = {
          barChartFormat: defaultBarChartFormat,
        };
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <ProjectIrrComponent
              workspace={workspace}
              project={project}
              holdings={holdings}
              date={config.date}
              period={period}
              baseCurrency={baseCurrency}
              periodType={PeriodTypes.Year}
              irrConfigData={irrConfigData}
            />
          </ElementComponent>
        );
      }
      case 'portfolioAssetGroupsIrr':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            {/* <ProjectHoldingGroupsIrrComponent
              workspaceId={workspace.id}
              projectId={project.id}
              holdings={holdings}
              date={config.date}
              pivotOptions={config.pivotOptions}
              period={period}
              currency={baseCurrency}
            /> */}
            <>Use element instead</>
          </ElementComponent>
        );
      case 'costs':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <CostsComponent
              workspaceId={workspace.id}
              projectId={project.id}
              holdings={holdings}
              date={config.date}
              period={period}
              currency={baseCurrency}
            />
          </ElementComponent>
        );
      case 'portfolioAssetGroupValuation':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <ProjectHoldingGroupValuationComponent
              workspaceId={workspace.id}
              projectId={project.id}
              date={config.date}
              period={period}
              pivotOptions={config.pivotOptions}
              holdings={holdings}
              currency={baseCurrency}
            />
          </ElementComponent>
        );
      case 'ownership':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={setActive}
            setInactive={setInactive}
            loading={loading}
            updated={updated}
            usageMode={config.usageMode}
          >
            <OwnershipComponent />
          </ElementComponent>
        );

      default:
        return <div>Unknown</div>;
    }
  },
);

export const DashboardWithConfigComponent = (props: Props) => {
  const holdings = useSelector((state: RootState) =>
    getHoldingsByProjectId(selectWorkspaceData(state), props.project.id),
  );
  const [configOpen, setConfigOpen] = React.useState(false);

  const [loading] = React.useState(false);
  const { active, setActive, setInactive } = useActiveElement();
  const { classes } = useStyles();

  const { dashboardComponents, add, remove, move } = useDashboardComponents({
    dashboardConfig: props.dashboardConfig,
    workspace: props.workspace,
    fund: props.fund,
  });

  const components = dashboardComponents.map((component) => {
    const isActive = component.id === active;
    return (
      <SubComponent
        workspace={props.workspace}
        project={props.project}
        fund={props.fund}
        holdings={holdings}
        key={component.id}
        id={component.id}
        dashboardSubComponentType={component.t}
        loading={loading}
        active={isActive}
        setActive={() => setActive(component.id)}
        setInactive={() => setInactive(component.id)}
        remove={remove}
      />
    );
  });

  const toggleConfig = () => {
    setConfigOpen(!configOpen);
  };
  const configButton = !configOpen ? (
    <Button
      variant="outlined"
      color="secondary"
      className={classes.configButton}
      onClick={toggleConfig}
    >
      CONFIGURATION
    </Button>
  ) : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.dashboardMenu}>
        {configButton}
        <DashboardEditSubComponentsComponent
          availableComponents={portfolioDashboardElements}
          removeSubComponent={remove}
          addSubComponent={add}
          move={move}
          components={dashboardComponents}
        />
      </div>
      <div
        className={classes.components}
        // ref={containerSize as React.RefObject<HTMLDivElement>}
      >
        {components}
      </div>
      <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
        <ConfigComponent
          workspace={props.workspace}
          projectId={props.project.id}
        />
      </Drawer>
    </div>
  );
};
