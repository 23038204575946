import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { Tenant } from '~src/services/graphql/tenant/client/graphql';

export function parseTenant(tenant: Tenant): TenantState {
  return {
    id: tenant.id,
    name: tenant.name,
    type: tenant.type,
  };
}
