// import { debounce } from 'lodash';
// import { batchedSubscribe } from 'redux-batched-subscribe';
// import { QUOTES } from './data/store/modules/quotes/action';
import { quotesSlice } from '~src/data/store/reducers/finance/quote/reducer';
// import {
// Action,
// applyMiddleware, compose,
// createStore
// } from 'redux';
// import thunk from 'redux-thunk';
// import history from '~src/navigation/history';
// import rootReducer from '~src/reducers';
import { appReducer, RootState } from '~src/data/store/reducers/reducers';

import { Action, configureStore } from '@reduxjs/toolkit';

import { assetsSlices } from '../data/store/reducers/asset/assets/reducer';
// import { loggerMiddleware } from '~src/store/logger-middleware';

const actionSanitizer = <A extends Action<any>>(action: A, _id: number): A => {
  // console.log(action.type, quotesSlice.slices.upsertManyElements.type);
  if (action.type === assetsSlices.upsertManyElements.type) {
    // && action.payload
    return { ...action, payload: '<<LONG_BLOB>>' };
  } else if (action.type === quotesSlice.slices.upsertManyElements.type) {
    // console.log(action);
    return { ...action, payload: '<<LONG_BLOB>>' };
    // } else if (action.type === QUOTES) {
    //   return { ...action, payload: '<<LONG_BLOB>>' };
  }
  return action;
};

const stateSanitizer = (state: RootState): RootState => {
  return {
    ...state,
    data: {
      ...state.data,
      financial: {
        ...state.data.financial,
        finance: {
          ...state.data.financial.finance,
          quotes: {
            quotes: [],
          },
          exchangeRates: [],
          // trade: {
          //   trades: [],
          //   tradesFetch: [],
          // },
          // },
        },
      },
    },
  };
};

const reducer = appReducer();
// type State = ReturnType<typeof reducer>;

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers({
      autoBatch: { type: 'tick' },
    }),
  // enhancers: [batchedSubscribe(debounceNotify)],
  // enhancers: (ex) => [reduxBatch, ...ex, reduxBatch],
  // middleware: customizedMiddleware,
  devTools: {
    actionSanitizer,
    stateSanitizer: stateSanitizer as any,
  },
});
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
