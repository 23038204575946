import { MultipleRelationOperationType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { each, groupBy } from 'lodash';
import { upsertPortfolios } from '~src/data/store/modules/portfolios/portfolios/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'portfolioHoldings';

export interface PortfolioHolding {
  // id: string;
  portfolioId: string;
  holdingId: string;
}

const header: Array<keyof PortfolioHolding> = [
  // 'id',
  'portfolioId',
  'holdingId',
];

export const portfolioHoldingExportHandler = (
  data: PortfolioHolding[],
): ExportHandler<PortfolioHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};
export const portfolioHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<PortfolioHolding> => {
  return {
    type: name,
    header,
    handler: (ts) => {
      const groupedPortfolioHoldings = groupBy(
        ts,
        (portfolioHolding) => portfolioHolding.portfolioId,
      );
      const inputs: graphqlWorkspaceTypes.UpsertPortfolioInputType[] = [];
      each(groupedPortfolioHoldings, (portfolioHoldings, portfolioId) => {
        inputs.push({
          id: portfolioId,
          holdingRelations: portfolioHoldings.map((portfolioHolding) => {
            return {
              id: portfolioHolding.holdingId,
              type: MultipleRelationOperationType.add,
            };
          }),
        });
      });

      // const inputs = ts.map((ph) => {
      //   return {
      //     id: ph.portfolioId,
      //     holdingRelations: ts.map((portfolioHolding) => {
      //       return {
      //         id: portfolioHolding.holdingId,
      //         type: MultipleRelationOperationType.add,
      //       };
      //     }),
      //   };
      // });
      return dispatch(
        upsertPortfolios(workspace.tenantId, workspace.id, inputs),
      );
    },
  };
};
