import React from 'react';
// import { SelectDownshiftField } from '~src/components/inputs/select-downshift-field';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getRegionById } from '~src/data/store/selectors/common/regions/selectors';

interface Props {
  regionId: string | undefined;
}

export const ViewRegionField = React.memo((props: Props) => {
  const region = useSelector((state: RootState) =>
    getRegionById(selectWorkspaceData(state), { id: props.regionId ?? null }),
  );

  return <p>{region ? region.name : ''}</p>;
});
