import moment from 'moment';
import React from 'react';
import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { eventParser } from '~src/utils/form/event-parser';

import Autorenew from '@mui/icons-material/Autorenew';
import { DatePicker } from '@mui/x-date-pickers';
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  projectId: string;
  capitalCallInput: graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallInputType;
  setCapitalCallInput: (
    input: graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallInputType,
  ) => void;

  showId: boolean;
  showConfirmed: boolean;
  showHolding: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const CreateCapitalCallFieldsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const handleChange =
    (
      name: keyof graphqlWorkspaceTypes.CreatePrivateEquityFundCapitalCallInputType,
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.setCapitalCallInput({
        ...props.capitalCallInput,
        [name]: eventParser(e),
      });
    };

  const handleClickRegenerateId = () => {
    props.setCapitalCallInput({ ...props.capitalCallInput, id: uuid.v1() });
  };

  const handleExitDateChange = (date: moment.Moment | null) => {
    if (date) {
      props.setCapitalCallInput({
        ...props.capitalCallInput,
        date: date.toDate(),
      });
    }
  };

  const handleMouseDownRegenerateId = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const idElement = props.showId ? (
    <Grid item sm={4}>
      <FormControl
        fullWidth
        required
        className={classes.textField}
        margin="normal"
      >
        <InputLabel htmlFor="adornment-id">Id</InputLabel>
        <Input
          id="adornment-id"
          type={'text'}
          value={props.capitalCallInput.id}
          onChange={handleChange('id')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="id regeneration"
                onClick={handleClickRegenerateId}
                onMouseDown={handleMouseDownRegenerateId}
              >
                <Autorenew />
                {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>
  ) : undefined;

  // const holdingElement = props.showHolding ? (
  //   <Grid item sm={4}>
  //     <EditHoldingField
  //       handleValue={handleHoldingChange}
  //       projectId={props.projectId}
  //       holdingId={props.capitalCallInput.holdingId}
  //       labelled={true}
  //     />
  //   </Grid>
  // ) : undefined;

  return (
    <>
      <Grid container spacing={1}>
        {idElement}
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="name"
            value={props.capitalCallInput.name}
            label="Name"
            type="text"
            onChange={handleChange('name')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="description"
            value={props.capitalCallInput.description}
            label="Description"
            type="text"
            onChange={handleChange('description')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            className={classes.textField}
            required
            id="value"
            value={props.capitalCallInput.value}
            label="Value"
            type="number"
            onChange={handleChange('value')}
          />
        </Grid>
        <Grid item sm={4}>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label="Date"
            value={
              props.capitalCallInput.date
                ? moment(props.capitalCallInput.date)
                : undefined
            }
            onChange={handleExitDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </>
  );
});
