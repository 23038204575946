import moment from 'moment';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { getHoldingGroupValuations } from './holding-group-valuations';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  filter: Filter;
  useMarketValue: boolean;
  filterValue: string;
  filterKey: string;
}

export const getHoldingGroupValue = createCachedSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useMarketValue,
  (_state: DataState, props: Props) => props.filterValue,
  (_state: DataState, props: Props) => props.filterKey,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    filter,
    useLiveValue,
    filterValue,
    filterKey,
  ) => {
    // let totalValue = 0;
    const valuations = getHoldingGroupValuations(state, {
      workspaceId,
      projectId,
      holdings,
      date,
      currency,
      filter,
      useLiveValue,
      filterValue,
      filterKey,
    });
    return valuations.reduce((prev, curr) => {
      if (curr) {
        prev += curr.value;
      }
      return prev;
    }, 0);
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${props.projectId}:${props.date.unix()}:${
      props.currency
    }:${props.useMarketValue}:${props.filterValue}:${props.filterKey}`,
  selectorCreator: createSelector,
});
