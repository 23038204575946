import { compact } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  ProjectData,
  useProjectFields,
} from '~src/hooks/fields/workspace/project/use-project-fields';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectsByWorkspaceId } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { useProjectActions } from '~src/hooks/actions/workspace/project/use-project-actions';
import { ProjectsToolbar } from './projects-toolbar.component';

interface Props {
  workspace: WorkspaceState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

// export interface ProjectData extends ProjectState {}

export const ProjectsComponent = memo((props: Props) => {
  const projects = useSelector((state: RootState) =>
    getProjectsByWorkspaceId(selectWorkspaceData(state), props.workspace.id),
  );

  const { classes } = useStyles();
  const { onRowUpdate, onRowDelete } = useProjectActions(props.workspace);

  const fields = useProjectFields(props.workspace.tenantId, props.workspace.id);

  const [initialSorters] = React.useState<TableField.Sorter<ProjectData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(projects, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);
  return (
    <div className={classes.root}>
      <ProjectsToolbar workspace={props.workspace} />
      <div className={classes.content}>
        <TableComponent<ProjectData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
});
