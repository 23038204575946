import { Operation } from '@pladdenico/portfolio-api';
import {
  ReportStateVisitor,
  ReportVisitable,
} from '~src/data/store/visitors/workspace/project/report/report-visitor';
import { Report } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleReports(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  reports: Report[],
  _subscriptions: Operation[],
) {
  const reportVisitor = new ReportStateVisitor(dispatch);
  reports.forEach((report) => {
    const assetVisitable = new ReportVisitable(workspaceId, report);
    assetVisitable.accept(reportVisitor);
  });
  reportVisitor.post();
  return reports;
}
