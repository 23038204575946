import {
  fetchStockTrades,
  handleDeleteStockTrades,
} from '~src/data/store/modules/holdings/stock/trade/requests';
import { StockTradeState } from '~src/data/store/reducers/holding/holding-types/stock/stock-trade/reducer';
import { apis } from '~src/services/request/apis';

import { Operation, stockTradePath } from '@pladdenico/portfolio-api';

export function subscribeToStockTrades(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  trades: StockTradeState[],
  subscriptions: Operation[],
) {
  trades.forEach((trade) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: stockTradePath(tenantId, workspaceId).objectPath(
              trade.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchStockTrades(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: stockTradePath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchStockTrades(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: stockTradePath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteStockTrades(trade.stockHoldingId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to trade', operation, trade.id);
          },
        );
      }
    });
  });
}
