import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { ElementFormatConfig } from '~src/components/dashboard/element';
import { getHoverColor } from '~src/utils/common/colors/color-utils';
import merge from 'deepmerge';

export const useElementTheme = (format: ElementFormatConfig | undefined) => {
  if (!format) {
    return (theme: Theme) => theme;
  }
  return (theme: Theme) => {
    // console.log('theme', theme);

    const newTheme: ThemeOptions = {
      palette: {
        text: {
          primary: format.color,
        },
        background: {
          paper: format.backgroundColor,
          default: format.backgroundColor,
        },
        action: {
          active: format.color,
          // activatedOpacity: 1,
          hover: getHoverColor(format.backgroundColor),
          hoverOpacity: 0.3,
          // focus: format.color,
          // focusOpacity: 1,
          // selected: format.color,
          // selectedOpacity: 1,
        },
      },
      typography: {
        // ...theme.typography,
        allVariants: {
          color: format.color,
        },
      },
      components: {
        // MuiIconButton: {
        //   styleOverrides: {
        //     root: {
        //       color: format.color,
        //     },
        //   },
        // },
        MuiPaper: {
          styleOverrides: {
            root: {
              // background: 'red',
              backgroundColor: format.backgroundColor,
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: format.color,
              textDecorationColor: format.color,
              ':hover': {
                color: getHoverColor(format.color, -60),
                textDecorationColor: getHoverColor(format.color, -60),
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              // color: format.color,
              // backgroundColor: format.backgroundColor,
              borderBottomColor: format.color,
            },
          },
        },
      },
    };

    return createTheme(merge(theme, newTheme));
  };
};
