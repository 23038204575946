import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseSetupId {
  fundId: string;
  id: string;
}

export type SetupState = graphqlWorkspaceTypes.PrivateEquityFundSetup & {
  fundId: string;
};

export const initialSetupState = { ts: [], idxs: [] };

const selectId = (setup: BaseSetupId) => setup.id;
const selectIdx = (setup: BaseSetupId) => setup.fundId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: SetupState, b: SetupState) => b;

const adapter = new EntityIndexedAdapter<
  BaseSetupId,
  SetupState,
  string,
  string
>(initialSetupState, selectId, selectIdx, merger, iComparator, idxComparator);

export const setupsSlice = createBaseIndexedSlice('setups', adapter);
export const setupsSelectors = adapter.selectors;
export const setupsReducer = setupsSlice.reducer;
export const setupsActions = setupsSlice.actions;
