import {
  ExchangeRateStateVisitor,
  ExchangeRateVisitable,
} from '~src/data/store/visitors/finance/exchange-rate-visitor';
import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleExchangeRates(
  dispatch: AppDispatch,
  outputs: graphqlUserTypes.ExchangeRate[],
) {
  const exchangeRateVisitor = new ExchangeRateStateVisitor(dispatch);
  outputs.forEach((exchangeRate) => {
    const visitable = new ExchangeRateVisitable(exchangeRate);
    visitable.accept(exchangeRateVisitor);
  });
  exchangeRateVisitor.post();
}
