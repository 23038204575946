import { holdingDashboardConfigsActions } from '~src/data/store/reducers/holding/config/dashboard-configs/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleHoldingDashboardConfigs } from './handler';

export function createHoldingDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  holdingConfigId: number,
  inputs: graphqlWorkspaceTypes.CreateHoldingDashboardConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateHoldingDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.CreateHoldingDashboardConfigsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createHoldingDashboardConfigs) {
        return handleHoldingDashboardConfigs(
          dispatch,
          holdingConfigId,
          data.createHoldingDashboardConfigs,
          tenantId,
          workspaceId,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function updateHoldingDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  holdingConfigId: number,
  inputs: graphqlWorkspaceTypes.UpdateHoldingDashboardConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateHoldingDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpdateHoldingDashboardConfigsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateHoldingDashboardConfigs) {
        return handleHoldingDashboardConfigs(
          dispatch,
          holdingConfigId,
          data.updateHoldingDashboardConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertHoldingDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  holdingConfigId: number,
  inputs: graphqlWorkspaceTypes.UpsertHoldingDashboardConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertHoldingDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpsertHoldingDashboardConfigsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertHoldingDashboardConfigs) {
        return handleHoldingDashboardConfigs(
          dispatch,
          holdingConfigId,
          data.upsertHoldingDashboardConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deleteHoldingDashboardConfigs(
  tenantId: string,
  workspaceId: string,
  holdingConfigId: number,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteHoldingDashboardConfigsDocument;
    const variables: graphqlWorkspaceTypes.DeleteHoldingDashboardConfigsMutationVariables =
      {
        inputs: ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteHoldingDashboardConfigs) {
        dispatch(
          holdingDashboardConfigsActions.removeManyElements(
            data.deleteHoldingDashboardConfigs.map((p) => {
              return {
                holdingConfigId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
