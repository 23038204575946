import React from 'react';
import { useSelector } from 'react-redux';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultCurrency } from '~src/data/store/modules/currencies/default-currency';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { OwnershipComponent } from '~src/domain/workspace/components/ownership/ownership.component';
import { AllocationGoalElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation-goals/element';
import { AllocationElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation/element';
import { CostElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/cost/element';
import { EfficientFrontierElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/efficient-frontier/element';
import { EventsElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/events/element';
import { FavoriteHoldingsElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/favorite-holdings/element';
import { GoalRuleElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/element';
import { GroupedIRRElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/grouped-irr/element';
import { GroupedValuationElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/grouped-valuation/element';
import { HeaderElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/header/element';
import { HoldingsViewElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/holdings-view/element';
import { ImageElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/image/element';
import { IrrElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/irr/element';
import { OrganizationElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/organization/element';
import { RegionalValuationElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/regional-valuation/element';
import { SummaryElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/summary/element';
import { TextEditorElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/element';
import { WaterfallElement } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/waterfall/element';
import { CommitmentsElement } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/commitments/element';
import { Config } from '~src/utils/interfaces/config';

import { Box } from '@mui/material';

export const DashboardElementComponent = React.memo(
  ({
    workspace,
    project,
    portfolioProject,
    portfolio,
    holdings,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    update,
    config,
  }: {
    workspace: WorkspaceState;
    project: ProjectState;
    portfolioProject: PortfolioProjectState;
    portfolio: PortfolioState;
    holdings: HoldingState[];
    active: boolean;
    setActive: (id: string) => void;
    setInactive: (id: string) => void;
    loading: boolean;
    id: string;
    remove: (id: string) => void;
    update: (
      id: string,
      element: Partial<DashboardElementState>,
    ) => Promise<{ id: string }>;
    element: DashboardElementState;
    config: Config;
  }) => {
    // useComponentUpdateDebug(`DashboardComponentComponent ${element.id}`, {
    //   workspace,
    //   project,
    //   portfolioProject,
    //   portfolio,
    //   holdings,
    //   active,
    //   setActive,
    //   setInactive,
    //   loading,
    //   id,
    //   remove,
    //   containerPos,
    //   containerSize,
    //   element,
    //   update,
    // });
    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    let baseCurrency = useSelector((state: RootState) =>
      getCurrencyById(selectFinancialData(state), {
        id: baseCurrencyId,
      }),
    );
    const { period } = config;

    if (!baseCurrency) {
      baseCurrency = defaultCurrency;
    }

    switch (element.type) {
      case 'header':
        return (
          <HeaderElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolio={portfolio}
            config={config}
          />
        );
      case 'summary':
        return (
          <SummaryElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
          />
        );
      case 'image':
        return (
          <ImageElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
          />
        );
      case 'text':
        return (
          <TextEditorElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            config={config}
          />
        );
      case 'favoriteHoldings':
        return (
          <FavoriteHoldingsElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            // containerPos={containerPos}
            // containerSize={containerSize}
            updated={update}
            element={element}
            workspace={workspace}
            config={config}
            holdings={holdings}
            period={period}
            project={project}
          />
        );

      case 'events':
        return (
          <EventsElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            config={config}
            period={period}
            project={project}
          />
        );
      case 'valuationGeographical':
        return (
          <RegionalValuationElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            baseCurrency={baseCurrency}
            config={config}
            holdings={holdings}
            project={project}
          />
        );
      case 'allocation':
        return (
          <AllocationElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolio={portfolio}
            baseCurrency={baseCurrency}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
          />
        );
      case 'allocationGoal':
        return (
          <AllocationGoalElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolio={portfolio}
            baseCurrency={baseCurrency}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
          />
        );
      case 'waterfall':
        return (
          <WaterfallElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            projectId={portfolioProject.projectId}
            period={period}
            config={config}
            holdings={holdings}
            baseCurrency={baseCurrency}
          />
        );
      case 'contributors':
        return (
          <HoldingsViewElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolioProject={portfolioProject}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
          />
        );
      case 'irr':
        return (
          <IrrElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            project={project}
            period={period}
            config={config}
            holdings={holdings}
            baseCurrency={baseCurrency}
          />
        );
      case 'efficientFrontier':
        return (
          <EfficientFrontierElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolioProject={portfolioProject}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
          />
        );
      case 'portfolioAssetGroupsIrr':
        return (
          <GroupedIRRElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolio={portfolio}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
            date={config.date}
            currency={baseCurrency}
          />
        );
      case 'costs':
        return (
          <CostElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            project={project}
            period={period}
            config={config}
            holdings={holdings}
          />
        );
      case 'organization':
        return (
          <OrganizationElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
          />
        );
      case 'portfolioAssetGroupValuation':
        return (
          <GroupedValuationElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolio={portfolio}
            config={config}
            holdings={holdings}
            period={period}
            projectId={project.id}
            date={config.date}
            currency={baseCurrency}
          />
          // <ElementComponent
          //   remove={remove}
          //   id={id}
          //   active={active}
          //   setActive={() => setActive(element.id)}
          //   setInactive={() => setInactive(element.id)}
          //   loading={loading}
          //   containerPos={containerPos}
          //   containerSize={containerSize}
          //   updated={update}
          //   // element={element}
          //   position={position}
          //   size={size}
          // >
          //   <ProjectHoldingGroupValuationComponent
          //     workspaceId={workspace.id}
          //     projectId={project.id}
          //     date={config.date}
          //     period={period}
          //     pivotOptions={config.pivotOptions}
          //     holdings={holdings}
          //     currency={baseCurrency}
          //   />
          // </ElementComponent>
        );
      case 'ownership':
        return (
          <ElementComponent
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            // element={element}
          >
            <Box>Ownership</Box>
            <OwnershipComponent />
          </ElementComponent>
        );
      case 'goal':
        return (
          <GoalRuleElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            project={project}
            date={config.date}
            config={config}
            holdings={holdings}
            currency={baseCurrency}
          />
        );
      case 'commitments':
        return (
          <CommitmentsElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            project={project}
            config={config}
            holdings={holdings}
            period={period}
          />
        );

      default:
        return (
          <HeaderElement
            remove={remove}
            id={id}
            active={active}
            setActive={() => setActive(element.id)}
            setInactive={() => setInactive(element.id)}
            loading={loading}
            updated={update}
            element={element}
            workspace={workspace}
            portfolio={portfolio}
            config={config}
          />
        );
    }
  },
);
