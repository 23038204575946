import { combineReducers } from 'redux';
import {
  initialOrganizationsState,
  organizationsReducer,
} from '~src/data/store/modules/organizations/organization/reducer';
import {
  initialOrganizationRolesState,
  organizationRoleGroupsReducer,
} from '~src/data/store/modules/organizations/roles/reducer';

export const organizationInitialState = {
  organizations: initialOrganizationsState,
  organizationRoleGroups: initialOrganizationRolesState,
};

export const organizationReducer = combineReducers({
  organizations: organizationsReducer,
  organizationRoleGroups: organizationRoleGroupsReducer,
});
