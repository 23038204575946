import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingObj } from '~src/services/graphql/workspace/client/graphql';

export function parseHoldingObj(holding: HoldingObj): HoldingState {
  return {
    id: holding.id,
    name: holding.name,
    description: holding.description,
    type: holding.type,
    projectId: holding.project?.id ?? '',
  };
}
