import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { organizationRoleGroupsSelectors } from '~src/data/store/modules/organizations/roles/reducer';
import { FinancialDataState } from '~src/data/store/reducers/reducers';

export const organizationRoleGroupsSelector = (state: FinancialDataState) => {
  return state.organization.organizationRoleGroups;
};

export const getOrganizationRoleGroups = (state: FinancialDataState) =>
  organizationRoleGroupsSelector(state);

export const getOrganizationRoleGroupsById = createCachedSelector(
  (state: FinancialDataState, _organizationId: string | undefined | null) =>
    organizationRoleGroupsSelector(state),
  (_state: FinancialDataState, organizationId: string | undefined | null) =>
    organizationId,
  (organizations, organizationId) => {
    if (!organizationId) {
      return undefined;
    }
    return organizationRoleGroupsSelectors.selectElement(organizations, {
      id: organizationId,
    });
  },
)({
  keySelector: (_state, organizationId) => organizationId,
  selectorCreator: createSelector,
});
