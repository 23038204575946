import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createEntities } from '~src/data/store/modules/entities/entities/requests';

export const useCreateEntity = (workspace: WorkspaceState) => {
  const [entityInput, setEntityInput] =
    React.useState<graphqlWorkspaceTypes.CreateEntityInputType>({
      id: uuid.v1(),
      name: '',
      description: '',
      sector: '',
      website: '',
      regionAllocationRelations: [],
    });

  const dispatch = useDispatch<AppDispatch>();

  const handleCreateEntity = React.useCallback(() => {
    return dispatch(
      createEntities(workspace.tenantId, workspace.id, [entityInput]),
    ).then((entities) => entities[0]);
  }, [entityInput, dispatch, workspace]);
  return { entityInput, setEntityInput, handleCreateEntity };
};
