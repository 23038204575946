import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { dnbAccountImportHandler } from '~src/services/xlsx/handlers/adapters/dnb/account/account-import-handler';
import { dnbTransactionImportHandler } from '~src/services/xlsx/handlers/adapters/dnb/transaction/transaction-import-handler';
import { handleInputData } from '~src/services/xlsx/handlers/iinput-handler';
import { DataImporter } from '~src/services/xlsx/hooks/use-import';
import { StatusUpdater } from '~src/services/xlsx/hooks/use-update-status';
import { AppDispatch } from '~src/store/store';

export const dnbImporter =
  (holdingId: string): DataImporter =>
  (
    workspace: WorkspaceState,
    data: Map<string, any[]>,
    statusUpdater: StatusUpdater,
  ) => {
    return (dispatch: AppDispatch) => {
      return handleInputData(
        data,
        dnbAccountImportHandler(workspace, holdingId, dispatch),
        statusUpdater,
      ).then((account) => {
        return handleInputData(
          data,
          dnbTransactionImportHandler(
            workspace,
            holdingId,
            account[0],
            dispatch,
          ),
          statusUpdater,
        ).then(() => {
          return true;
        });
      });
    };
  };
