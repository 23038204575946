import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  PeriodType,
  PeriodTypes,
  typeToString,
} from '~src/utils/period/period-type';

interface Props {
  handleValue: (periodType: PeriodType) => void;
  periodType: PeriodType | undefined;
  label: string | undefined;
}

export const PeriodTypeField = React.memo((props: Props) => {
  const handleSelectChange = (event: SelectChangeEvent<PeriodType>) => {
    props.handleValue(event.target.value as PeriodType);
  };

  const label = props.label ? (
    <InputLabel id="irrType">{props.label}</InputLabel>
  ) : undefined;

  const menuItems = React.useMemo(() => {
    return Object.values(PeriodTypes).map((type) => {
      return (
        <MenuItem key={type} value={type}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, []);

  return (
    <FormControl fullWidth margin="dense">
      {label}
      <Select
        value={props.periodType}
        labelId="irrType"
        onChange={handleSelectChange}
        defaultValue={undefined}
        label={props.label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
