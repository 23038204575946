import numbro from 'numbro';
import React from 'react';
import { useSelector } from 'react-redux';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getInvestorsByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/investor/selectors';
import { getSetupByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/setup/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { Box, Grid, Typography } from '@mui/material';

import { StatisticComponent } from './statistic.component';
import { getInvestmentsByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/investment/selectors';
import { getValuationsByHoldingIds } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { groupBy } from '@pladdenico/common';
import { getCapitalCallsByFundId } from '~src/data/store/selectors/asset/asset-types/private-equity-funds/capital-call/selectors';
import { getLastValuationIdx } from '~src/utils/finance/valuation';

interface Props {
  workspace: WorkspaceState;
  fund: PrivateEquityFundState;
}

export function getLastValuations(valuations: ValuationState[]) {
  const valuationsByHolding = groupBy(
    valuations,
    (valuation) => valuation.holdingId,
  );
  const lastValuations: ValuationState[] = [];
  valuationsByHolding.forEach((valuations) => {
    const idx = getLastValuationIdx(valuations);
    if (idx !== -1) {
      lastValuations.push(valuations[idx]);
    }
  });
  return lastValuations;
}

function useGetLastValuationForHoldings(holdingIds: string[]) {
  const valuations = useSelector((state: RootState) =>
    getValuationsByHoldingIds(selectWorkspaceData(state), holdingIds),
  );
  return getLastValuations(valuations);
}

export const DashboardHeaderComponent = React.memo((props: Props) => {
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );
  const investors = useSelector((state: RootState) => {
    return getInvestorsByFundId(selectWorkspaceData(state), props.fund.id);
  });
  const investments = useSelector((state: RootState) =>
    getInvestmentsByFundId(selectWorkspaceData(state), props.fund.id),
  );
  const capitalCalls = useSelector((state: RootState) =>
    getCapitalCallsByFundId(selectWorkspaceData(state), props.fund.id),
  );
  const holdingIds = React.useMemo(
    () => investments.map((i) => i.holdingId),
    [investments],
  );
  const valuations = useGetLastValuationForHoldings(holdingIds);

  const setup = useSelector((state: RootState) => {
    return getSetupByFundId(selectWorkspaceData(state), props.fund.id);
  });

  const period = config.period;

  const committedCapital = 580000000;
  const calledCapital = capitalCalls.reduce((prevValue, capitalCall) => {
    return capitalCall.value ? capitalCall.value + prevValue : prevValue;
  }, 0);
  const repayedCapital = 140000000;
  const outstandingCapital = committedCapital - calledCapital;
  const valuationOfInvestments = valuations.reduce((prevValue, valuation) => {
    return valuation.value ? valuation.value + prevValue : prevValue;
  }, 0);
  const cashOnCashMultiple =
    (valuationOfInvestments + repayedCapital) / calledCapital;
  const numberOfInvestors = investors.length;
  const numberOfInvestments = investments.filter(
    (investment) => !investment.exit,
  ).length;
  const numberOfDivestments = investments.filter(
    (investment) => !!investment.exit,
  ).length;
  const totalManagmentFees = 16000000;
  const currentCarry = setup ? (setup.carry * valuationOfInvestments) / 100 : 0;

  const numberToString = (value: number) => {
    return numbro(value).format({
      thousandSeparated: true,
      // mantissa: 1,
      totalLength: 5,
      average: true,
    });
  };

  return (
    <Box p={1}>
      <Typography variant="h5">Fund - {props.fund.description}</Typography>
      <Typography variant="subtitle1">
        <div>Date {config.date.format('DD.MM.YY')}</div>
        <div>
          Period {period.start.format('DD.MM.YY')}-
          {period.end.format('DD.MM.YY')}
        </div>
      </Typography>
      <Grid container>
        <StatisticComponent
          title="Commited capital"
          value={numberToString(committedCapital)}
        />
        <StatisticComponent
          title="Called capital"
          value={numberToString(calledCapital)}
        />
        <StatisticComponent
          title="Repayed capital"
          value={numberToString(repayedCapital)}
        />
        <StatisticComponent
          title="Outstanding Capital"
          value={numberToString(outstandingCapital)}
        />
        <StatisticComponent
          title="Value of investments"
          value={numberToString(valuationOfInvestments)}
        />
        <StatisticComponent
          title="Cash on cash multiple"
          value={numberToString(cashOnCashMultiple)}
        />
        <StatisticComponent
          title="# investors"
          value={numberToString(numberOfInvestors)}
        />
        <StatisticComponent
          title="# investments"
          value={numberToString(numberOfInvestments)}
        />
        <StatisticComponent
          title="# divestments"
          value={numberToString(numberOfDivestments)}
        />
        <StatisticComponent
          title="Total Managment Fees"
          value={numberToString(totalManagmentFees)}
        />
        <StatisticComponent
          title="Current carry"
          value={numberToString(currentCarry)}
        />
      </Grid>
    </Box>
  );
});
