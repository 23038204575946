import React from 'react';
import { useDispatch } from 'react-redux';
import { createFilter } from '~src/data/store/modules/holdings/filter/index';
import { AppDispatch } from '~src/store/store';
import {
  getFilterSets,
  Filter,
  setExcludeMany,
  setIncludeMany,
} from '~src/utils/common/filter';

import { Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { AllocationPieChartComponent } from './allocation-pie-chart.component';
import { createDataGroups } from './create-data-groups';
import { Data } from './data';

const useStyles = makeStyles()((_theme: Theme) => ({
  row: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

interface Props {
  data: Data[];
  filter: Filter;
  filterKey: string;
  showFilteredOut: boolean;
  setShowFilteredOut: (value: boolean) => void;
  getColorIndex: (id: string) => number;
}

function useDataGroups(data: Data[], minValuationPercent: number) {
  return createDataGroups(data, minValuationPercent);
}

export const PieChartComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [minValuationPercent] = React.useState(1.5);
  const dispatch = useDispatch<AppDispatch>();
  const data = useDataGroups(props.data, minValuationPercent);

  const clickOnPieChartArea = React.useCallback(
    (a: any, b: any, event: any) => {
      const clickOnHoldingArea = (a: any, b: any, event: any) => {
        console.log(a, b, event);
        const ids = a.ids;

        if (event.button === 0) {
          const sets = getFilterSets(props.filter, props.filterKey);
          if (sets && sets.exclude.size === 0) {
            setIncludeMany(props.filter, {
              key: props.filterKey,
              // values: d.ids,
              values: ids,
            });
          } else {
            setIncludeMany(props.filter, {
              key: props.filterKey,
              // values: d.ids,
              values: ids,
            });
          }
        } else if (event.button === 2) {
          setExcludeMany(props.filter, {
            key: props.filterKey,
            // values: d.ids,
            values: ids,
          });
        }
        dispatch(createFilter(0, props.filter));
        return false;
      };

      return clickOnHoldingArea(a, b, event);
    },
    [dispatch, props.filter, props.filterKey],
  );

  const getLabelName = React.useCallback(
    (index: number) => {
      return data[index].name;
    },
    [data],
  );

  const getItem = React.useCallback(
    (index: number) => {
      return data[index];
    },
    [data],
  );

  const assetsPieChart = (
    <AllocationPieChartComponent
      clickOnArea={clickOnPieChartArea}
      data={data}
      getLabelName={getLabelName}
      getItem={getItem}
      getColorIndex={props.getColorIndex}
      cx={50}
      outerRadius={70}
    />
  );
  return (
    <div className={classes.container}>
      <Typography variant="body2" textAlign="center">
        Allocation: {props.filterKey}
      </Typography>
      {assetsPieChart}
    </div>
  );
});
