import { compact } from 'lodash';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { AssetTyped } from '~src/data/store/reducers/asset/reducer';
import {
  getBankNoteByAssetId,
  getBankNoteById,
} from '~src/data/store/selectors/asset/asset-types/bank-notes/selectors';
import {
  getBondByAssetId,
  getBondById,
} from '~src/data/store/selectors/asset/asset-types/bonds/selectors';
import {
  getCryptoByAssetId,
  getCryptoById,
} from '~src/data/store/selectors/asset/asset-types/cryptos/selectors';
import {
  getLoanByAssetId,
  getLoanById,
} from '~src/data/store/selectors/asset/asset-types/loans/selectors';
import {
  getStockByAssetId,
  getStockById,
} from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import {
  getWarrantByAssetId,
  getWarrantById,
} from '~src/data/store/selectors/asset/asset-types/warrants/selectors';
import { getBondHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import { getCryptoHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/crypto/crypto-holding/selectors';
import { getLoanHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/loan/loan-holding/selectors';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { getWarrantHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/warrant/warrant-holding/selectors';

import { AssetType, HoldingType } from '@pladdenico/models';

import {
  assetsSelectors,
  AssetState,
  BaseAssetId,
} from '../../../reducers/asset/assets/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';
import { getBankNoteHoldingByHoldingId } from '../../holding/holding-types/bank-note/bank-note-holding/selectors';
import { getFundHoldingByHoldingId } from '../../holding/holding-types/fund/fund-holding/selectors';
import { getHoldingById } from '../../holding/holdings/selectors';
import { getFundByAssetId, getFundById } from '../asset-types/funds/selectors';

export const selectAssets = (state: WorkspaceDataState) => {
  return state.holding.asset.assets;
};

// export const getAssets = createSelector(selectAssets, (assets) => {
//   return assets;
// });

export const getAssetsByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState, _workspaceId: string | null) =>
    selectAssets(state),
  (_state: WorkspaceDataState, workspaceId: string | null) => workspaceId,
  (assetsState, workspaceId) => {
    if (!workspaceId) {
      return [];
    }
    const assets = assetsSelectors.selectElementsByKey(
      assetsState,
      workspaceId,
    );
    return assets ? assets : [];
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

export const getAssetById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseAssetId>) =>
    selectAssets(state),
  (state: WorkspaceDataState, baseId: Partial<BaseAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseAssetId>) => baseId.id,
  (assets, workspaceId, assetId) => {
    if (workspaceId && assetId) {
      return assetsSelectors.selectElementByT(assets, {
        id: assetId,
        workspaceId,
      });
    }
    return undefined;
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.id}`,
  selectorCreator: createSelector,
});

export const getAssetsByIds = createCachedSelector(
  (state: WorkspaceDataState, _baseIds: BaseAssetId[]) => selectAssets(state),
  (_state: WorkspaceDataState, baseIds: BaseAssetId[]) => baseIds,
  (assets, baseIds) => {
    return assetsSelectors.selectElementsByTs(assets, baseIds);
  },
)({
  keySelector: (_state, ids) => ids,
  cacheObject: new FlatMapCache(),
  selectorCreator: createSelector,
});

interface BaseAssetHoldingId {
  workspaceId: string;
  projectId: string;
  holdingId: string;
}

export const getAssetTypedByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: BaseAssetHoldingId) => state,
  (_state: WorkspaceDataState, baseId: BaseAssetHoldingId) =>
    baseId.workspaceId,
  (state: WorkspaceDataState, baseId: BaseAssetHoldingId) =>
    getHoldingById(state, {
      id: baseId.holdingId,
      projectId: baseId.projectId,
    }),
  (state, workspaceId, holding): AssetTyped | undefined => {
    if (!holding) {
      return undefined;
    }
    if (holding.type === HoldingType.Stock) {
      const stockHolding = getStockHoldingByHoldingId(state, holding.id);
      if (stockHolding) {
        const stock = getStockById(state, {
          id: stockHolding.stockId,
          workspaceId,
        });
        if (stock) {
          return {
            ...stock,
            __typename: 'Stock',
          };
        }
      }
    } else if (holding.type === HoldingType.BankNote) {
      const bankNoteHolding = getBankNoteHoldingByHoldingId(state, holding.id);
      if (bankNoteHolding) {
        const bankNote = getBankNoteById(state, {
          id: bankNoteHolding.bankNoteId,
          workspaceId,
        });
        if (bankNote) {
          return {
            ...bankNote,
            __typename: 'BankNote',
          };
        }
      }
    } else if (holding.type === HoldingType.Crypto) {
      const cryptoHolding = getCryptoHoldingByHoldingId(state, holding.id);
      if (cryptoHolding) {
        const crypto = getCryptoById(state, {
          id: cryptoHolding.cryptoId,
          workspaceId,
        });
        if (crypto) {
          return {
            ...crypto,
            __typename: 'BankNote',
          };
        }
      }
    } else if (holding.type === HoldingType.Bond) {
      const bondHolding = getBondHoldingByHoldingId(state, holding.id);
      if (bondHolding) {
        const bond = getBondById(state, {
          id: bondHolding.bondId,
          workspaceId,
        });
        if (bond) {
          return {
            ...bond,
            __typename: 'Bond',
          };
        }
      }
    } else if (holding.type === HoldingType.Fund) {
      const fundHolding = getFundHoldingByHoldingId(state, holding.id);
      if (fundHolding) {
        const fund = getFundById(state, {
          id: fundHolding.fundId,
          workspaceId,
        });
        if (fund) {
          return { ...fund, __typename: 'Fund' };
        }
      }
    } else if (holding.type === HoldingType.Loan) {
      const loanHolding = getLoanHoldingByHoldingId(state, holding.id);
      if (loanHolding) {
        const loan = getLoanById(state, {
          id: loanHolding.loanId,
          workspaceId,
        });
        if (loan) {
          return { ...loan, __typename: 'Loan' };
        }
      }
    } else if (holding.type === HoldingType.Warrant) {
      const warrantHolding = getWarrantHoldingByHoldingId(state, holding.id);
      if (warrantHolding) {
        const warrant = getWarrantById(state, {
          id: warrantHolding.warrantId,
          workspaceId,
        });
        if (warrant) {
          return { ...warrant, __typename: 'Warrant' };
        }
      }
    }
    return undefined;
  },
)({
  keySelector: (_state, workspaceId, projectId, holdingId) =>
    `${workspaceId}-${projectId}-${holdingId}`,
  selectorCreator: createSelector,
});

interface GetAssetTypedByAssetProps {
  workspaceId: string;
  asset: AssetState | undefined;
}

export const getAssetTypedByAsset = createCachedSelector(
  (state: WorkspaceDataState, _props: GetAssetTypedByAssetProps) => state,
  (_state: WorkspaceDataState, props: GetAssetTypedByAssetProps) =>
    props.workspaceId,
  (_state: WorkspaceDataState, props: GetAssetTypedByAssetProps) => props.asset,
  (state, workspaceId, asset): AssetTyped | undefined => {
    if (!asset) {
      return undefined;
    }
    if (asset.type === AssetType.Stock) {
      const stock = getStockByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (stock) {
        return {
          ...stock,
          __typename: 'Stock',
        };
      }
    } else if (asset.type === AssetType.BankNote) {
      const bankNote = getBankNoteByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (bankNote) {
        return {
          ...bankNote,
          __typename: 'BankNote',
        };
      }
    } else if (asset.type === AssetType.Crypto) {
      const crypto = getCryptoByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (crypto) {
        return {
          ...crypto,
          __typename: 'BankNote',
        };
      }
    } else if (asset.type === AssetType.Bond) {
      const bond = getBondByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (bond) {
        return {
          ...bond,
          __typename: 'Bond',
        };
      }
    } else if (asset.type === AssetType.Fund) {
      const fund = getFundByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (fund) {
        return { ...fund, __typename: 'Fund' };
      }
    } else if (asset.type === AssetType.Loan) {
      const loan = getLoanByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (loan) {
        return { ...loan, __typename: 'Loan' };
      }
    } else if (asset.type === AssetType.Warrant) {
      const warrant = getWarrantByAssetId(state, {
        assetId: asset.id,
        workspaceId,
      });
      if (warrant) {
        return { ...warrant, __typename: 'Warrant' };
      }
    }
    return undefined;
  },
)({
  keySelector: (_state, props) => `${props.workspaceId}-${props.asset?.id}`,
  selectorCreator: createSelector,
});

export const getAssetTypedByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: BaseAssetId) => state,
  (_state: WorkspaceDataState, baseId: BaseAssetId) => baseId.workspaceId,
  (state: WorkspaceDataState, baseId: BaseAssetId) =>
    getAssetById(state, baseId),
  (state, workspaceId, asset): AssetTyped | undefined => {
    if (!asset) {
      return undefined;
    }
    return getAssetTypedByAsset(state, { workspaceId, asset });
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.id}`,
  selectorCreator: createSelector,
});

export const getAssetIdByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, baseId: BaseAssetHoldingId) =>
    getAssetTypedByHoldingId(state, baseId),
  (asset): BaseAssetId | undefined => {
    if (!asset) {
      return undefined;
    }
    return {
      id: asset.assetId,
      workspaceId: asset.workspaceId,
    };
  },
)({
  keySelector: (_state, workspaceId, projectId, holdingId) =>
    `${workspaceId}-${projectId}-${holdingId}`,
  selectorCreator: createSelector,
});

export const getAssetIdsByHoldingIds = createCachedSelector(
  (
    state: WorkspaceDataState,
    _ids: {
      workspaceId: string;
      projectId: string;
      holdingId: string;
    }[],
  ) => state,
  (
    _state: WorkspaceDataState,
    ids: {
      workspaceId: string;
      projectId: string;
      holdingId: string;
    }[],
  ) => ids,
  (state, ids) => {
    return compact(ids.map((id) => getAssetIdByHoldingId(state, id)));
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getAssetByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: BaseAssetHoldingId) => state,
  (state: WorkspaceDataState, baseId: BaseAssetHoldingId) =>
    getAssetIdByHoldingId(state, baseId),
  (state, assetId): AssetState | undefined => {
    return getAssetById(state, {
      id: assetId?.id,
      workspaceId: assetId?.workspaceId,
    });
  },
)({
  keySelector: (_state, baseId) =>
    `${baseId.workspaceId}-${baseId.projectId}-${baseId.holdingId}`,
  selectorCreator: createSelector,
});

export const getAssetsByHoldingIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: BaseAssetHoldingId[]) => state,
  (state: WorkspaceDataState, ids: BaseAssetHoldingId[]) =>
    getAssetIdsByHoldingIds(state, ids),
  (state, assetIds) => {
    return getAssetsByIds(state, assetIds);
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getAssetsTypedByAssetIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: BaseAssetId[]) => state,
  (state: WorkspaceDataState, ids: BaseAssetId[]) => ids,
  (state, ids) => {
    return compact(ids.map((id) => getAssetTypedByAssetId(state, id)));
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

interface BaseAssetByEntityId {
  entityId: string;
  workspaceId: string;
}

export const getAssetsByEntityId = createCachedSelector(
  (state: WorkspaceDataState, baseId: BaseAssetByEntityId) =>
    getAssetsByWorkspaceId(state, baseId.workspaceId),
  (_state: WorkspaceDataState, baseId: BaseAssetByEntityId) => baseId.entityId,
  (assets, entityId) => {
    return assets.filter((asset) => asset.entityId === entityId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.entityId}`,
  selectorCreator: createSelector,
});
