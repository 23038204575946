import * as mathjs from 'mathjs';
import { SMALast, sampleCovariance } from './uneven-timeseries';
import { findIndex } from 'lodash';

export namespace finance {

  export interface IQuote {
    date: Date;
    paper: string;
    exchange: string;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
    value: number;
  }

  // Present Value (PV)
  export function PV(rate, cf1, numOfPeriod) {
    numOfPeriod = typeof numOfPeriod !== 'undefined' ? numOfPeriod : 1;
    rate = rate / 100;
    const pv = cf1 / Math.pow((1 + rate), numOfPeriod);
    return Math.round(pv * 100) / 100;
  }

  // Future Value (FV)
  export function FV(rate, cf0, numOfPeriod) {
    rate = rate / 100;
    const fv = cf0 * Math.pow((1 + rate), numOfPeriod);
    return Math.round(fv * 100) / 100;
  }

  // Net Present Value (NPV)
  export function NPV(rate, cfs: number[]) {
    if (cfs.length === 0) {
      return 0;
    }
    rate = rate / 100.0;
    let npv = cfs[0];
    for (let i = 1; i < cfs.length; ++i) {
      npv += (cfs[i] / Math.pow((1 + rate), i - 1));
    }
    return Math.round(npv * 100) / 100;
  }

  // seekZero seeks the zero point of the function fn(x),
  // accurate to within x \pm 0.01. fn(x) must be decreasing with x.
  function seekZero(fn) {
    let x = 1;
    while (fn(x) > 0) {
      x += 1;
    }
    while (fn(x) < 0) {
      x -= 0.01;
    }
    return x + 0.01;
  }

  // Internal Rate of Return (IRR)
  export function IRR(cfs) {
    // var args = arguments;
    let numberOfTries = 1;
    // Cash flow values must contain at least one positive value and one negative value
    let positive;
    let negative;
    cfs.forEach((value) => {
      if (value > 0) {
        positive = true;
      } else if (value < 0) {
        negative = true;
      }
    });
    if (!positive || !negative) {
      throw new Error('IRR requires at least one positive value and one negative value');
    }

    function npv(rate) {
      numberOfTries++;
      if (numberOfTries > 1000) {
        throw new Error('IRR can\'t find a result');
      }
      const rrate = (1 + rate / 100);
      let value = cfs[0];
      for (let i = 1; i < cfs.length; ++i) {
        value += (cfs[i] / Math.pow(rrate, i));
      }
      return value;
    }
    return Math.round(seekZero(npv) * 100) / 100;
  }

  // Payback Period (PP)
  export function PP(numOfPeriods, cfs) {
    // for even cash flows
    if (numOfPeriods === 0) {
      return Math.abs(cfs[0]) / cfs[1];
    }
    // for uneven cash flows
    let cumulativeCashFlow = cfs[0];
    let yearsCounter = 1;
    for (let i = 1; i < cfs.length; i++) {
      cumulativeCashFlow += cfs[i];
      if (cumulativeCashFlow > 0) {
        yearsCounter += (cumulativeCashFlow - cfs[i]) / cfs[i];
        return yearsCounter;
      } else {
        yearsCounter++;
      }
    }
  }

  // Return on Investment (ROI)
  export function ROI(cf0, earnings) {
    const roi = (earnings - Math.abs(cf0)) / Math.abs(cf0) * 100;
    return Math.round(roi * 100) / 100;
  }

  // Amortization
  export function AM(principal, rate, period, yearOrMonth, payAtBeginning) {
    let numerator;
    let denominator;
    let am;
    const ratePerPeriod = rate / 12 / 100;

    // for inputs in years
    if (!yearOrMonth) {
      numerator = buildNumerator(period * 12);
      denominator = Math.pow((1 + ratePerPeriod), period * 12) - 1;

    // for inputs in months
    } else if (yearOrMonth === 1) {
      numerator = buildNumerator(period);
      denominator = Math.pow((1 + ratePerPeriod), period) - 1;
    // } else {
      // console.log('not defined');
    }
    am = principal * (numerator / denominator);
    return Math.round(am * 100) / 100;

    function buildNumerator(numInterestAccruals) {
      if (payAtBeginning) {
        // if payments are made in the beginning of the period, then interest shouldn't be calculated for first period
        numInterestAccruals -= 1;
      }
      return ratePerPeriod * Math.pow((1 + ratePerPeriod), numInterestAccruals);
    }
  }

  // Profitability Index (PI)
  export function PI(rate, cfs) {
    let totalOfPVs = 0;
    let value;
    for (let i = 1; i < cfs.length; i++) {
      // calculate discount factor
      const discountFactor = 1 / Math.pow((1 + rate / 100), (i - 1));
      totalOfPVs += cfs[i] * discountFactor;
    }
    value = totalOfPVs / Math.abs(cfs[0]);
    return Math.round(value * 100) / 100;
  }

  // Discount Factor (DF)
  export function DF(rate, numOfPeriods) {
    const dfs = [];
    for (let i = 1; i < numOfPeriods; i++) {
      const discountFactor = 1 / Math.pow((1 + rate / 100), (i - 1));
      const roundedDiscountFactor = Math.ceil(discountFactor * 1000) / 1000;
      dfs.push(roundedDiscountFactor);
    }
    return dfs;
  }

  // Compound Interest (CI)
  export function CI(rate, numOfCompoundings, principal, numOfPeriods) {
    const value = principal * Math.pow((1 + ((rate / 100) / numOfCompoundings)), numOfCompoundings * numOfPeriods);
    return Math.round(value * 100) / 100;
  }

  // Compound Annual Growth Rate (CAGR)
  export function CAGR(beginningValue, endingValue, numOfPeriods) {
    const value = Math.pow((endingValue / beginningValue), 1 / numOfPeriods) - 1;
    return Math.round(value * 10000) / 100;
  }

  // Leverage Ratio (LR)
  export function LR(totalLiabilities, totalDebts, totalIncome) {
    return (totalLiabilities  + totalDebts) / totalIncome;
  }

  // Rule of 72
  export function R72(rate) {
    return 72 / rate;
  }

  // Weighted Average Cost of Capital (WACC)
  export function WACC(marketValueOfEquity, marketValueOfDebt, costOfEquity, costOfDebt, taxRate) {
    const E = marketValueOfEquity;
    const D = marketValueOfDebt;
    const V =  marketValueOfEquity + marketValueOfDebt;
    const re = costOfEquity;
    const rd = costOfDebt;
    const T = taxRate;

    const value = ((E / V) * re / 100) + (((D / V) * rd / 100) * (1 - T / 100));
    return Math.round(value * 1000) / 10;
  }

  // PMT calculates the payment for a loan based on constant payments and a constant interest rate
  export function PMT(fractionalRate, numOfPayments, principal) {
    return -principal * fractionalRate / (1 - Math.pow(1 + fractionalRate, -numOfPayments));
  }

  // IAR calculates the Inflation-adjusted return
  export function IAR(investmentReturn, inflationRate) {
    return 100 * (((1 + investmentReturn) / (1 + inflationRate)) - 1);
  }

  // XIRR - IRR for irregular intervals - annual return
  export function XIRR(cfs, dts, guess) {
    if (cfs.length !== dts.length) {
      throw new Error('Number of cash flows and dates should match');
    }

    let positive;
    let negative;
    cfs.forEach((value) => {
      if (value > 0) {
        positive = true;
      }
      if (value < 0) {
        negative = true;
      }
    });

    if (!positive || !negative) {
      throw new Error('XIRR requires at least one positive value and one negative value');
    }

    guess = !!guess ? guess : 0;

    let limit = 100; // loop limit
    let guessLast;
    const durs = [];

    durs.push(0);

    // Create Array of durations from First date
    for (let i = 1; i < dts.length; i++) {
      durs.push(Math.floor(durDays(dts[0], dts[i])));
    }
    do {
      guessLast = guess;
      guess = guessLast - sumEq(cfs, durs, guessLast);
      limit--;
    } while (guessLast.toFixed(5) !== guess.toFixed(5) && limit > 0);

    const xirr = guessLast.toFixed(5) !== guess.toFixed(5) ? null : guess;
    return Math.pow(xirr + 1, 365) - 1;
    // return Math.round(xirr * 100) / 100;
  }

  // Returns Sum of f(x)/f'(x)
  function sumEq(cfs, durs, guess) {
    let sumFx = 0;
    let sumFdx = 0;
    for (let i = 0; i < cfs.length; i++) {
      sumFx = sumFx + (cfs[i] / Math.pow(1 + guess, durs[i]));
    }
    for (let i = 0; i < cfs.length; ++i) {
      sumFdx = sumFdx + (-cfs[i] * durs[i] * Math.pow(1 + guess, -1 - durs[i]));
    }
    return sumFx / sumFdx;
  }

  // Returns duration in years between two dates
  function durYear(first, last) {
    return (Math.abs(last.getTime() - first.getTime()) / (1000 * 3600 * 24 * 365));
  }

  // Returns duration in years between two dates
  function durDays(first, last) {
    return (Math.abs(last.getTime() - first.getTime()) / (1000 * 3600 * 24));
  }

  export function STDDEV(returns) {
    // const returns = this.returns(startDate, endDate, quoteState);

    const values = returns.map((value) => {
      return value.value;
    });
    const times = returns.map((value) => {
      return value.date.getTime();
    });

    const tau = 24 * 60 * 60 * 1000 * 365 / 12 * 3; // 3 months
    const res = SMALast(values, times, tau);

    if (res.length === 0) {
      return 0;
    }

    const stddev = mathjs.std(res);
    return stddev;
  }

  export function beta(startDate: Date, returns, indexPaperQuotes: IQuote[]): number {
    if (!indexPaperQuotes || indexPaperQuotes.length === 0) {
      return 0;
    }
    const values = returns.map((value) => {
      return value.value;
    });
    const times = returns.map((value) => {
      return value.date.getTime();
    });
    const tau = 1;
    const res = SMALast(values, times, tau);
    if (res.length === 0) {
      return 0;
    }

    const dates = [startDate, ...returns.map((ireturn) => ireturn.date)];
    const indexReturns = calculateIndexReturns(dates, indexPaperQuotes);

    const indexVariance = mathjs.variance(indexReturns);
    const covariance = sampleCovariance(indexReturns, res);
    return covariance / indexVariance;
  }

  function calculateIndexReturns(dates: Date[], indexPaperQuotes: IQuote[]) {
    let indexReturns: number[] = new Array(dates.length);
    for (let i = 0; i < indexReturns.length; ++i) {
      const quoteIdx = findIndex(indexPaperQuotes, (quote) => {
        return quote.date.getFullYear() === dates[i].getFullYear() &&
          quote.date.getMonth() === dates[i].getMonth() &&
          quote.date.getDay() <= dates[i].getDay(); // quotes sorted from newest to oldest
      });
      if (quoteIdx !== -1) {
        indexReturns[i] = indexPaperQuotes[quoteIdx].close;
      } else {
        if (quoteIdx + 1 < indexPaperQuotes.length) {
          indexReturns[i] = indexPaperQuotes[quoteIdx + 1].close;
        } else {
          indexReturns[i] = undefined;
        }
      }
    }

    let prevvValuation = indexReturns.shift();
    indexReturns = indexReturns.map((value) => {
      const res = (prevvValuation - value) / prevvValuation;
      prevvValuation = value;
      return res;
    });
    return indexReturns;

  }
}
