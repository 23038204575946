import { compact } from 'lodash';
import React from 'react';
import { workspaceAssetRootPath } from '~src/navigation/paths/workspace/asset/paths';
// import history from '~src/navigation/history';
import { Index } from '~src/services/search/index';

import { SearchResult } from '../../components/search/search-component';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { store } from '~src/store/store';
import { getCategoryById } from '~src/data/store/selectors/asset/categories/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useNavigate } from 'react-router-dom';

function createAssetsIndex(
  workspaceId: string,
  assets: AssetState[],
  fields: string[],
): Index {
  const assetsIndex = new Index();
  const documents = assets.map((asset) => {
    const category = getCategoryById(selectWorkspaceData(store.getState()), {
      id: asset?.categoryId ?? undefined,
      workspaceId,
    });

    return {
      id: asset.id,
      name: asset.name,
      type: asset.type,
      description: asset.description,
      category: category?.name,
      types: asset.types,
    };
  });
  assetsIndex.build(fields, documents);
  return assetsIndex;
}

const assetsSearch =
  (
    assets: AssetState[],
    assetsIndex: Index,
    onClick: (asset: AssetState) => void,
  ) =>
  (query: string): SearchResult[] => {
    const idx = assetsIndex.idx();
    if (!idx) {
      return [];
    }
    const assetSearchResult = idx.search(query);
    const assetsHits: AssetState[] = compact(
      assetSearchResult.map((hit: any) => {
        return assets.find((asset) => {
          return asset.id === String(hit.ref);
        });
      }),
    );
    const assetResults = assetsHits.map((asset: AssetState) => {
      return {
        id: asset.id ? asset.id : -1,
        title: asset.name ? asset.name : '-',
        description: asset.description ? asset.description : '-',
        onClick: () => onClick(asset),
        // onClick: () => {
        //   return navigate(
        //     assetBasePath(asset.tenantId, asset.workspaceId, asset.id)
        //   );
        // },
      };
    });
    return assetResults;
  };

const fields = ['name', 'sector', 'category', 'type', 'types'];

export function useAssetsIndex(workspaceId: string, assets: AssetState[]) {
  const [assetsIndex, setAssetsIndex] = React.useState<Index>(
    () => new Index(),
  );
  const navigate = useNavigate();

  const onClick = React.useCallback(
    (asset: AssetState) => {
      return navigate(
        workspaceAssetRootPath(asset.tenantId, asset.workspaceId, asset.id),
      );
    },
    [navigate],
  );
  React.useEffect(() => {
    setAssetsIndex(createAssetsIndex(workspaceId, assets, fields));
  }, [assets, onClick, workspaceId]);

  return {
    assetsSearch: assetsSearch(assets, assetsIndex, onClick),
  };
}
