import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { EditTypeField } from '~src/components/fields/workspace/asset/edit/edit-type-field';
import { AssetType } from '@pladdenico/models';
import { FieldsConfig } from '~src/domain/workspace/components/asset/form/fields/fields.config';

export const useAssetTypeField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  label: string,
  defaultValue: PathValue<TFieldValues, TName>,
  config?: FieldsConfig,
) => {
  return (
    <Controller
      name={tname}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <EditTypeField
          handleValue={field.onChange}
          label={label}
          type={field.value as AssetType}
          // disabled={true}
          disabled={config?.disableAssetType ?? false}
        />
      )}
    />
  );
};
