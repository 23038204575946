import { combineReducers } from 'redux';
import {
  initialValuationsState,
  valuationsReducer,
} from '~src/data/store/reducers/holding/valuation/valuations/reducer';

export const valuationInitialState = {
  valuations: initialValuationsState,
};

export const valuationReducer = combineReducers({
  valuations: valuationsReducer,
});
