import { PrivateEquityFundProjectTabs } from '~src/domain/workspace/components/project/types/private-equity-project/tabs.component';

import { workspaceProjectRootPath } from '../paths';

export const privateEquityFundProjectTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  tab: PrivateEquityFundProjectTabs,
) => {
  return `${workspaceProjectRootPath(tenantId, workspaceId, projectId)}/${tab}`;
};
