import React from 'react';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { useHandler } from '~src/hooks/utils/use-handler';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
  },
}));

export const useScenarioHoldingForecastManualElementFieldsComponent = (
  input: ManualElementState,
  setInput: (element: ManualElementState) => void,
) => {
  const { classes } = useStyles();

  const { handleEvent } = useHandler(input, setInput);
  const valuesElement = React.useMemo(
    () => (
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="values"
        value={input.values}
        label="Values"
        type="text"
        onChange={handleEvent('values')}
      />
    ),
    [classes.textField, handleEvent, input.values],
  );

  return {
    valuesElement,
  };
};
