import { parseBankNoteHolding } from '~src/data/store/modules/holdings/bank-note/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  bankNoteHoldingsActions,
  BankNoteHoldingState,
} from '~src/data/store/reducers/holding/holding-types/bank-note/bank-note-holding/reducer';
import {
  BankNoteHolding,
  Holding,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';

export const isBankNoteHolding = (
  holding: Holding,
): holding is BankNoteHolding => {
  return holding.holding.type === HoldingType.BankNote;
};

export interface BankNoteHoldingVisitor {
  visit(bankNoteHolding: BankNoteHoldingVisitable): BankNoteHoldingState;
  post(): void;
}

export class BankNoteHoldingVisitable
  implements Visitable<BankNoteHoldingVisitor>
{
  constructor(private _bankNoteHolding: BankNoteHolding) {}
  public accept(visitor: BankNoteHoldingVisitor) {
    return visitor.visit(this);
  }

  public parse(): BankNoteHoldingState {
    return parseBankNoteHolding(this._bankNoteHolding);
  }
}

// export class BankNoteHoldingHandlerVisitor implements BankNoteHoldingVisitor {
//   constructor(private _handle: (holding: BankNoteHoldingState) => void) {}

//   public visit(holding: BankNoteHoldingVisitable): BankNoteHoldingState {
//     const holdingState = holding.parse();
//     this._handle(holdingState);
//     return holdingState;
//   }
//   public post() {
//     return;
//   }
// }

export class BankNoteHoldingStateVisitor implements BankNoteHoldingVisitor {
  private _bankNoteHoldings: BankNoteHoldingState[];
  constructor(private _dispatch: AppDispatch) {
    this._bankNoteHoldings = [];
  }
  public visit(holding: BankNoteHoldingVisitable): BankNoteHoldingState {
    const bankNoteHolding = holding.parse();
    this._bankNoteHoldings.push(bankNoteHolding);
    return bankNoteHolding;
  }
  post() {
    this._dispatch(
      bankNoteHoldingsActions.upsertManyElements(this._bankNoteHoldings, {
        shouldAutobatch: true,
      }),
    );
  }
}
