import { parseFile } from '~src/data/store/modules/file/file/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  filesActions,
  FileState,
} from '~src/data/store/reducers/file/files/reducer';
import { File } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface FileVisitor {
  visit(file: FileVisitable): FileState;
  post(): void;
}

export class FileVisitable implements Visitable<FileVisitor> {
  constructor(private _file: File) {}
  public accept(visitor: FileVisitor) {
    return visitor.visit(this);
  }

  public parse(): FileState {
    return parseFile(this._file);
  }
}

export class FileStateVisitor implements FileVisitor {
  private _files: FileState[];
  constructor(private _dispatch: AppDispatch) {
    this._files = [];
  }
  public visit(medium: FileVisitable): FileState {
    const file = medium.parse();
    this._files.push(file);
    return file;
  }
  post() {
    this._dispatch(filesActions.addMany(this._files));
  }
}
