import { combineReducers } from 'redux';
import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

import { User } from '@pladdenico/models';

export interface BaseUserId {
  id: number;
}

export type UserState = User;

export function userToUserState(user: UserState) {
  return user;
}
export const initialUsersState = [];

const comparator = (a: BaseUserId, b: BaseUserId) => a.id - b.id;
const merger = (_a: UserState, b: UserState) => b;

const adapter = new EntityArrayAdapter<BaseUserId, UserState>(
  initialUsersState,
  comparator,
  merger,
);

export const usersSlice = createBaseArraySlice('users', adapter);
export const usersSelectors = adapter.selectors;

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;

export const userInitialState = {
  users: initialUsersState,
};

export const userReducer = combineReducers({
  users: usersReducer,
});
