import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import * as uuid from 'uuid';
import { createPortfolioProjects } from '~src/data/store/modules/workspaces/projects/portfolio-projects/requests';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { usePortfolioCreate } from '~src/domain/workspace/components/project/portfolio/form/form/use-portfolio-create';
import { ProjectCreate } from '~src/domain/workspace/components/project/form/use-project-create';
import { AppDispatch } from '~src/store/store';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { selectProject } from '~src/data/store/modules/workspaces/projects/base/requests';
import { Typography } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectForm: ProjectCreate;
}
const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const useCreateForm = (props: Props) => {
  const { classes } = useStyles();
  const { workspace, projectForm } = props;

  const portfolioCreate = usePortfolioCreate({
    workspace,
    inputData: undefined,
  });

  const dispatch = useDispatch<AppDispatch>();

  const submit = React.useCallback(
    (
      successCallback: (
        portfolioProject: PortfolioProjectState,
      ) => Promise<void>,
      onFailure: (error: any) => void,
    ) => {
      return projectForm.submit((project) =>
        portfolioCreate.submit(
          project.id,
          (portfolio) => {
            return dispatch(
              createPortfolioProjects(workspace.tenantId, workspace.id, [
                {
                  id: uuid.v1(),
                  portfolioId: portfolio.id,
                  projectId: project.id,
                },
              ]),
            ).then((portfolioProjects: PortfolioProjectState[]) => {
              dispatch(selectProject(project.id));
              const portfolioProject = portfolioProjects[0];
              successCallback(portfolioProject);
              return;
            });
          },
          onFailure,
        ),
      );
    },
    [projectForm, portfolioCreate, dispatch, workspace.tenantId, workspace.id],
  );

  const form = React.useMemo(
    () => (
      <form className={classes.container} noValidate autoComplete="off">
        <Grid2 container xs={12} columnSpacing={1}>
          <Grid2 container xs={12}>
            <Grid2 xs={12}>
              <Typography variant="body2">Project</Typography>
            </Grid2>
            <Grid2 xs={6}>{projectForm.fields.nameField}</Grid2>
            <Grid2 xs={6}>{projectForm.fields.typeField}</Grid2>
          </Grid2>
          <Grid2 container xs={12}>
            <Grid2 xs={12} mt={2}>
              <Typography variant="body2">Portfolio</Typography>
            </Grid2>
            <Grid2 xs={6}> {portfolioCreate.fields.nameField}</Grid2>
            <Grid2 xs={6}>{portfolioCreate.fields.descriptionField}</Grid2>
          </Grid2>{' '}
        </Grid2>
      </form>
    ),
    [
      classes.container,
      projectForm.fields.nameField,
      projectForm.fields.typeField,
      portfolioCreate.fields.descriptionField,
      portfolioCreate.fields.nameField,
    ],
  );
  return {
    form,
    submit,
  };
};
