import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertPrivateEquityFunds } from '~src/data/store/modules/assets/private-equity-fund/private-equity-funds/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'privateEquityFunds';

export interface PrivateEquityFund {
  id: string;
  assetId: string;
  description?: Maybe<string>;
}

const header: Array<keyof PrivateEquityFund> = ['id', 'assetId', 'description'];

export const privateEquityFundExportHandler = (
  data: PrivateEquityFund[],
): ExportHandler<PrivateEquityFund> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const privateEquityFundImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<PrivateEquityFund> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertPrivateEquityFunds(workspace.tenantId, workspace.id, ts)),
  };
};
