export enum TransferType {
  Buy = 'buy',
  Lend = 'lend',
  Borrow = 'borrow',
  Repayment = 'repayment',
  Deposit = 'deposit',
  RevenueFee = 'revenueFee',
  Sell = 'sell',
  Provision = 'provision',
  Dividend = 'dividend',
  Withdrawal = 'withdrawal',
  Cost = 'cost',
  WrongPayment = 'wrongPayment',
  Interest = 'interest',
}
