import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  stockHoldingsSelectors,
  StockHoldingState,
} from '../../../../../reducers/holding/holding-types/stock/stock-holding/reducer';

const getStockHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.stockHolding.stockHoldings;

export const getStockHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getStockHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (stockHoldingState, holdingId): StockHoldingState | undefined => {
    return stockHoldingsSelectors.selectElementByIdx(
      stockHoldingState,
      holdingId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getStockHoldings = createSelector(
  (state: WorkspaceDataState) => getStockHoldingState(state),
  (stockHoldings) => {
    return stockHoldingsSelectors.selectAllElements(stockHoldings);
  },
);

export const getStockHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getStockHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (stockHoldings, id): StockHoldingState | undefined => {
    return stockHoldingsSelectors.selectElementByI(stockHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

interface BaseStockHoldingStockId {
  // projectId: string;
  stockId: string;
}

export const getStockHoldingsByStockId = createCachedSelector(
  (state: WorkspaceDataState) => getStockHoldings(state),
  (_state: WorkspaceDataState, baseId: BaseStockHoldingStockId) =>
    baseId.stockId,
  (stockHoldings, stockId): StockHoldingState[] => {
    return stockHoldings.filter((h) => h.stockId === stockId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.stockId}`,
  selectorCreator: createSelector,
});
