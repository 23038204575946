import { parseBankNote } from '~src/data/store/modules/assets/bank-notes/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  bankNotesActions,
  BankNoteState,
} from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import {
  Asset,
  BankNote,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { AssetType } from '@pladdenico/models';

export interface BankNoteVisitor {
  visit(bankNote: BankNoteVisitable): BankNoteState;
  post(): BankNoteState[];
}

export const isBankNote = (asset: Asset): asset is BankNote => {
  return asset._type === AssetType.BankNote;
};

export class BankNoteVisitable implements Visitable<BankNoteVisitor> {
  constructor(
    private _workspaceId: string,
    private _bankNote: BankNote,
  ) {}
  public accept(visitor: BankNoteVisitor) {
    return visitor.visit(this);
  }

  public parse(): BankNoteState {
    const assetId = this._bankNote.asset?.id ?? '';
    return parseBankNote(this._workspaceId, assetId, this._bankNote);
  }
}

export class BankNoteStateVisitor implements BankNoteVisitor {
  private _bankNotes: BankNoteState[];
  constructor(private _dispatch: AppDispatch) {
    this._bankNotes = [];
  }

  public visit(bankNote: BankNoteVisitable): BankNoteState {
    const bankNoteParsed = bankNote.parse();
    this._bankNotes.push(bankNoteParsed);
    return bankNoteParsed;
  }

  post() {
    this._dispatch(
      bankNotesActions.upsertManyElements(this._bankNotes, {
        shouldAutobatch: true,
      }),
    );
    return this._bankNotes;
  }
}
