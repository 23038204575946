import { parseRegionAllocation } from '~src/data/store/modules/entities/region-allocations/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  regionAllocationsActions,
  RegionAllocationState,
} from '~src/data/store/reducers/entity/region-allocations/reducer';
import { RegionAllocation } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface RegionAllocationVisitor {
  visit(regionAllocation: RegionAllocationVisitable): void;
  post(): void;
}

export class RegionAllocationVisitable
  implements Visitable<RegionAllocationVisitor>
{
  constructor(
    private _entityId: string,
    private _regionAllocation: RegionAllocation,
  ) {}
  public accept(visitor: RegionAllocationVisitor) {
    visitor.visit(this);
  }

  public parse(): RegionAllocationState {
    return parseRegionAllocation(this._entityId, this._regionAllocation);
  }
}

export class RegionAllocationStateVisitor implements RegionAllocationVisitor {
  private _regionAllocations: RegionAllocationState[];
  constructor(private _dispatch: AppDispatch) {
    this._regionAllocations = [];
  }
  public visit(medium: RegionAllocationVisitable): RegionAllocationState {
    const regionAllocation = medium.parse();
    this._regionAllocations.push(regionAllocation);
    return regionAllocation;
  }
  post() {
    this._dispatch(
      regionAllocationsActions.upsertManyElements(this._regionAllocations, {
        shouldAutobatch: true,
      }),
    );
  }
}
