export interface BarChartFormat {
  axis: {
    lineStroke: string;
    tick: string;
    label: {
      fill: string;
    };
  };
  labelList: {
    fill: string;
  };
  tooltip: {
    stroke: string;
    fill: string;
    content: {
      fill: string;
    };
  };
  bar: string;
}

export const defaultBarChartFormat: BarChartFormat = {
  axis: {
    lineStroke: '#9e98f9',
    tick: '#a1a1d8',
    label: {
      fill: '#a1a1d8',
    },
  },
  labelList: {
    fill: '#a1a1d8',
  },
  tooltip: {
    stroke: '#a1a1d8',
    fill: '#b6a2be',
    content: {
      fill: '#2b2727',
    },
  },
  bar: '#8884d8',
};
