import { RouteObject } from 'react-router-dom';
import { MainComponent } from '~src/domain/tenant/app/main.component';
import { CreateTenantComponent } from '~src/domain/tenant/components/create-tenant.component';

export const useTenantsRoutes = (): RouteObject[] => {
  return [
    {
      path: '/tenants/create',
      element: (
        <MainComponent>
          <CreateTenantComponent />
        </MainComponent>
      ),
    },
  ];
};
