import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { transactionsBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/transaction/paths';

import { TransactionByIdComponent } from './transaction.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

export const TransactionRouterWrapper = React.memo((props: Props) => {
  const { workspace, projectId } = props;
  const { transactionId } = useParams<{ transactionId: string }>();

  if (transactionId) {
    return (
      <TransactionByIdComponent
        workspace={props.workspace}
        projectId={props.projectId}
        transactionId={transactionId}
      />
    );
  }
  return (
    <Navigate
      to={transactionsBasePath(workspace.tenantId, workspace.id, projectId)}
    />
  );
});
