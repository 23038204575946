import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingTabs } from '~src/domain/workspace/components/project/portfolio/holdings/tab/holding-tabs';
import { Tab } from '~src/utils/interfaces/tab';

import { HoldingType } from '@pladdenico/models';

function createTabs(holdingType: HoldingType): Tab<string>[] {
  const tabs: Tab<string>[] = [];

  {
    const path = {
      tab: HoldingTabs.Dashboard,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/dashboard',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: HoldingTabs.Valuations,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/valuations',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  // {
  //   const path = {
  //     tab: HoldingTabs.Transactions,
  //     exact: true,
  //     path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/transactions',
  //   };
  //   tabs.push({
  //     name: path.tab,
  //     path: path.path,
  //   });
  // }
  {
    const path = {
      tab: HoldingTabs.Transfers,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/transfers',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: HoldingTabs.Commitment,
      exact: true,
      path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/commitments',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  if (
    holdingType === HoldingType.Stock ||
    holdingType === HoldingType.Bond ||
    holdingType === HoldingType.Fund
  ) {
    {
      const path = {
        tab: HoldingTabs.Positions,
        exact: true,
        path: '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/positions',
      };
      tabs.push({
        name: path.tab,
        path: path.path,
      });
    }
  }
  // {
  //   const path = {
  //     tab: 'dashboard',
  //     exact: true,
  //     path: '/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId'
  //   };
  // }
  return tabs;
}

export function useTabs(holding: HoldingState) {
  return createTabs(holding.type as HoldingType);
}
