import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import {
  createTenant,
  selectTenant,
} from '~src/data/store/modules/tenant/tenants/requests';
import { AppDispatch } from '~src/store/store';

import { TenantInputData } from './tenant-input-data';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { useTenantFields } from '~src/domain/tenant/components/form/use-fields';

export const useTenantCreate = () => {
  const form = useForm<TenantInputData>({
    defaultValues: {
      name: '',
    },
  });

  const tenantFields = useTenantFields(form.control);
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: TenantInputData, successCallback: (tenant: TenantState) => void) => {
      const tenantId = uuid.v1();
      return dispatch(createTenant(tenantId, { id: tenantId, ...data })).then(
        (tenant) => {
          if (tenant) {
            dispatch(selectTenant(tenant.id));
            successCallback(tenant);
            // handleGotoTenant(value.id);
          }
        },
      );
    },
    [dispatch],
  );

  const onSubmit = React.useCallback(
    (
      successCallback: (tenant: TenantState) => void,
    ): SubmitHandler<TenantInputData> =>
      (data) => {
        return onSave(data, successCallback);
      },
    [onSave],
  );

  const submit = React.useCallback(
    (successCallback: (tenant: TenantState) => void) => {
      form.handleSubmit(onSubmit(successCallback))();
    },
    [form, onSubmit],
  );

  return { tenantFields, submit };
};
