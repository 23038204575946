import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { PivotTypeField } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation/pivot-type.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';
import { WaterfallTypeField } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/waterfall/waterfall-type.component';
import { PivotType, PivotTypes } from '~src/utils/interfaces/pivot-type';
import {
  WaterfallType,
  WaterfallTypes,
} from '~src/utils/interfaces/waterfall-type';

import { Divider, MenuItem } from '@mui/material';

export const useWaterfallMenu = (
  element: DashboardElementState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const updateWaterfallType = React.useCallback(
    (type: WaterfallType) => {
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            type,
          },
        });
    },
    [element, updated],
  );

  const updatePivotType = React.useCallback(
    (type: PivotType) => {
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...element.config,
            pivotType: type,
          },
        });
    },
    [element, updated],
  );
  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);
  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-1" />,
      <MenuItem key="menu-item-1">
        <WaterfallTypeField
          handleValue={updateWaterfallType}
          label={'Waterfall type'}
          type={
            (element.config.type as WaterfallType) ?? WaterfallTypes.Absolute
          }
        />
      </MenuItem>,
      <MenuItem key="menu-item-2">
        <PivotTypeField
          handleValue={updatePivotType}
          label={'Pivot type'}
          type={(element.config.pivotType as PivotType) ?? PivotTypes.Category}
        />
      </MenuItem>,
      <Divider key="divider-delete" />,
      deleteMenuItem,
      confirmDeleteElement,
    ];
  }, [
    confirmDeleteElement,
    deleteMenuItem,
    element,
    updatePivotType,
    updateWaterfallType,
    updated,
  ]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
