import { parse, print, Source } from 'graphql';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { appConfig } from '~src/app-config';
import { DataApi } from '~src/data/store/modules/data-api';
import { RootState } from '~src/data/store/reducers/reducers';
import { getSelectedPortfolio } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { apis } from '~src/services/request/apis';

import {
  Button,
  ClickAwayListener,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from 'tss-react/mui';
import {
  QueryContextTypes,
  QueryTenantContext,
} from '@pladdenico/portfolio-api';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  header: {
    textAlign: 'center',
  },
  helpButton: {
    marginLeft: theme.spacing(2),
  },
  helperPaper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'pre',
  },
  json: {
    whiteSpace: 'pre',
  },
}));
// const { connect } = require('react-redux');

// interface Props {
//   selectedPortfolio?: Portfolio;
// }

// interface State {
//   query: string;
//   variables: string;
//   output: any;
//   queryType: 'mutation' | 'query' | 'quote';
//   apiType: 'rest' | 'websocket';
// }

const headers = (portfolioId?: string) => {
  return {
    ...DataApi.baseHeaders(),
    ...DataApi.authHeaders(),
    // ...portfolio ? DataApi.workspaceHeaders(portfolio.workspace.id) : [],
    ...(portfolioId ? DataApi.portfolioHeaders(portfolioId) : []),
  };
};

const exampleQuery =
  '{assets(workspaceId:17, portfolioId:16) {id,valuations {id,date,},}}';

export const GraphQLComponent = React.memo(() => {
  const { classes } = useStyles();
  const [query, setQuery] = React.useState('{hello}');
  const [variables, setVariables] = React.useState('');
  const [output, setOutput] = React.useState<any>({ hello: 'world' });
  const [queryType, setQueryType] = React.useState('query');
  const [apiType, setApiType] = React.useState('rest');
  const [apiVersion, setApiVersion] = React.useState<'old' | 'new'>('old');
  const portfolio = useSelector((state: RootState) =>
    getSelectedPortfolio(selectWorkspaceData(state), ''),
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'helper-popper' : undefined;

  const handleOnQueryChange = (event: any): void => {
    console.log(event);
    // console.log(data);
    console.log(event.target);
    console.log(event.target.value);
    setQuery(event.target.value);
  };

  const handleOnVariablesChange = (event: any): void => {
    console.log(event);
    // console.log(data);
    console.log(event.target);
    console.log(event.target.value);
    setVariables(event.target.value);
  };

  /*
query getQuotes{getQuotes(paper: {
	paper: "NANO",
	exchange: "OSE"
},fromDate: "2018-02-16T16:04:24.004Z",toDate: "2019-02-16T16:04:24.006Z"){date,paper,exchange,open,high,low,close,volume,value}}
  */

  const onClickQueryButton = () => {
    if (apiType === 'rest') {
      if (queryType === 'query') {
        return DataApi.get(
          `${
            appConfig.apiBaseUrl
          }/graphql?query=${query}&variables=${JSON.stringify(variables)}`,
          headers(portfolio?.id),
          (res: any) => {
            setOutput(res.data);
            return Promise.resolve();
          },
          (err) => {
            setOutput({ error: JSON.stringify(err) });
            return Promise.reject(err);
          },
        );
      } else if (queryType === 'mutation') {
        return DataApi.post(
          `${
            appConfig.apiBaseUrl
          }/graphql?query=${query}&variables=${JSON.stringify(variables)}`,
          headers(portfolio?.id),
          '',
          (res: any) => {
            setOutput(res.data);
            return Promise.resolve();
          },
          (err) => {
            setOutput({ error: JSON.stringify(err) });
            return Promise.reject(err);
          },
        );
      }
    } else if (apiType === 'api') {
      if (queryType === 'query' || queryType === 'mutation') {
        const handleData = (data: any) => {
          setOutput(data);
        };
        // const query = `query getAssets($ids: [String!]) {
        //   getAssets(ids: $ids) {
        //     name
        //   }
        // }`;
        const source = new Source(query);
        const document = parse(source);
        // const result = execute({
        //   schema,
        //   document,
        //   contextValue: context,
        //   variableValues: {
        //     workspaceId: 'w1',
        //     ids: ['1', '2'],
        //   },
        // });
        const context: QueryTenantContext = {
          type: QueryContextTypes.tenant,
          tenantId: '',
        };

        return apis.invest.executeQuery(
          document,
          variables,
          context,
          handleData,
        );
        // return Request.queryWithString(
        //   query,
        //   variables,
        //   {},
        //   queryType,
        //   JSON.stringify(query),
        //   () => {
        //     undefined;
        //   },
        //   handleData,
        //   () => {
        //     return;
        //   },
        //   apiVersion
        // ).catch((error) => {
        //   setOutput({ error: JSON.stringify(error) });
        // });
        // } else if (queryType === 'quote') {
        //   const callback = () => {
        //     console.log('CALLED');
        //   };
        //   const fromDate: Date = new Date(2015, 1, 1);
        //   const toDate: Date = new Date(2019, 2, 17);
        //   apis.invest.listenToQuoteStart(
        //     {
        //       id: '',
        //       symbol: 'NANO',
        //       exchange: 'OSE',
        //       currencyId: '__CURRENCY__NOK',
        //     },
        //     fromDate,
        //     toDate,
        //     callback
        //   );
      }
    }
  };

  const onQueryTypeButton = () => {
    if (queryType === 'query') {
      setQueryType('mutation');
      // this.setState({queryType: 'mutation'});
    } else if (queryType === 'mutation') {
      setQueryType('quote');
      // this.setState({queryType: 'quote'});
    } else if (queryType === 'quote') {
      setQueryType('query');
      // this.setState({queryType: 'query'});
    }
  };

  const onApiTypeButton = () => {
    setApiType(apiType === 'rest' ? 'api' : 'rest');
  };

  const onApiVersionButton = () => {
    setApiVersion(apiVersion === 'old' ? 'new' : 'old');
  };

  const handleHelperClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleHelperClickAway = () => {
    setAnchorEl(null);
  };

  const helper = (
    <>
      <IconButton
        className={classes.helpButton}
        aria-describedby={id}
        onClick={handleHelperClick}
        size="small"
      >
        <HelpIcon fontSize="inherit" />
      </IconButton>
      {/* <Button aria-describedby={id} onClick={handleHelperClick}>
        ?
      </Button> */}
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleHelperClickAway}>
          <Paper className={classes.helperPaper}>
            {print(parse(exampleQuery))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item>
          <Typography className={classes.header} variant="h4">
            GraphQL
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item key="input" xs={6}>
            <Typography variant="h5">
              Input
              {helper}
            </Typography>
            {/* <Alert severity="success" className={style.Json}>
              <AlertTitle>Example</AlertTitle>
              {print(parse(exampleQuery))}
            </Alert> */}
            <form>
              <Grid container>
                <Grid item key="input-query" xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Query
                    </InputLabel>
                    <OutlinedInput
                      multiline={true}
                      id="query-input"
                      value={query}
                      onChange={handleOnQueryChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      // labelWidth={60}
                    />
                  </FormControl>
                </Grid>
                <Grid item key="input-variables" xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Variables
                    </InputLabel>
                    <OutlinedInput
                      multiline={true}
                      id="variables-input"
                      value={variables}
                      onChange={handleOnVariablesChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <ListIcon />
                        </InputAdornment>
                      }
                      // labelWidth={60}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {/* <TextareaAutosize value={query} placeholder="query" aria-label="query" rowsMin={3} onChange={handleOnQueryChange}/> */}
              {/* <TextareaAutosize value={variables} placeholder="variables" aria-label="query" rowsMin={3} onChange={handleOnVariablesChange}/> */}
              {/* <TextArea value={query}
                autoHeight={true} placeholder="query" rows={2} onChange={this.handleOnQueryChange}/>
              <TextArea value={variables}
                autoHeight={true} placeholder="variables" rows={2} onChange={this.handleOnVariablesChange}/> */}
            </form>
            <div>
              <Button variant="outlined" onClick={onQueryTypeButton}>
                toggle query type: {queryType}
              </Button>
            </div>
            <div>
              <Button variant="outlined" onClick={onApiTypeButton}>
                toggle api type {apiType}
              </Button>
            </div>
            <div>
              <Button variant="outlined" onClick={onApiVersionButton}>
                toggle api version {apiVersion}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickQueryButton}
              >
                go
              </Button>
            </div>
          </Grid>
          <Grid item key="output" xs={6}>
            <Typography variant="h5">Result</Typography>
            <Grid container>
              <Grid item xs={12}>
                <Paper>
                  <p className={classes.json}>
                    {output ? JSON.stringify(output, null, 2) : undefined}
                  </p>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  // const handleChange = (name: keyof BankNoteInput) => (event: React.ChangeEvent<{value: unknown}>) => {
});
