export const defaultTotalValueBackgroundColor = '#FFFFFF';
export const defaultTotalValueFontColor = '#000000';

export const defaultIrrBackgroundColor = '#FFFFFF';
export const defaultIrrFontColor = '#000000';

export const defaultBetaBackgroundColor = '#FFFFFF';
export const defaultBetaFontColor = '#000000';

export const defaultVolatilityBackgroundColor = '#FFFFFF';
export const defaultVolatilityFontColor = '#000000';

export const defaultCommitmentBackgroundColor = '#FFFFFF';
export const defaultCommitmentFontColor = '#000000';

// export const defaultTotalValueBackgroundColor = '#4C1B75';
// export const defaultTotalValueFontColor = '#C1B2B2';

// export const defaultIrrBackgroundColor = '#6C2D95';
// export const defaultIrrFontColor = '#C1B2B2';

// export const defaultBetaBackgroundColor = '#813FB7';
// export const defaultBetaFontColor = '#C1B2B2';

// export const defaultVolatilityBackgroundColor = '#A977E1';
// export const defaultVolatilityFontColor = '#642E97';
