import moment from 'moment';
import { createSelector } from 'reselect';
import {
  getFilterFor,
  getHoldingShareById,
} from '~src/data/store/selectors/holding/filter/selectors';
import { getRegionAllocationsByHoldingId } from '~src/data/store/selectors/entities/region-allocations/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { getHoldingValue } from './holding-value';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getRegions } from '../../common/regions/selectors';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsRegionalValuations = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.filter,
  (state: DataState, _props: Props) =>
    getRegions(selectWorkspaceDataFromData(state)),
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    filter,
    regions,
    useLiveValue,
  ) => {
    const countriesMap = new Map<string, number>();
    holdings.forEach((holding) => {
      const filtered = getFilterFor(state, {
        filter,
        workspaceId,
        projectId,
        holding,
        date,
      });
      if (!filtered) {
        return {
          value: 0,
          holding,
        };
      }

      const valuation = getHoldingValue(state, {
        workspaceId,
        holding,
        date,
        currency,
        useLiveValue,
      });
      const share = getHoldingShareById(state, {
        workspaceId,
        projectId,
        holding,
        filter,
        date,
      });

      if (!valuation) {
        return undefined;
      }
      const value = (valuation.value * share) / 100;

      const regionAllocations = getRegionAllocationsByHoldingId(
        selectWorkspaceDataFromData(state),
        {
          workspaceId,
          projectId,
          holdingId: holding.id,
        },
      );
      if (regionAllocations && regionAllocations.length > 0) {
        regionAllocations.forEach((regionAllocation) => {
          const region = regions.find(
            (region) => region.id === regionAllocation.regionId,
          );
          if (region) {
            if (!countriesMap.has(region.name)) {
              countriesMap.set(region.name, 0.0);
            }
            const country = countriesMap.get(region.name);
            if (country !== undefined && regionAllocation.allocation) {
              countriesMap.set(
                region.name,
                country + regionAllocation.allocation * value,
              );
            }
          }
        });
      } else {
        const country = countriesMap.get('unknown');
        countriesMap.set('unknown', (country ?? 0) + value * 100);
      }
    });
    return countriesMap;
  },
);
