import { FundTradeState } from '~src/data/store/reducers/holding/holding-types/fund/fund-trade/reducer';
import { FundTrade } from '~src/services/graphql/workspace/client/graphql';

export function parseFundTrade(fundTrade: FundTrade): FundTradeState {
  return {
    id: fundTrade.id,
    fundHoldingId: fundTrade.fundHolding?.id ?? '',
    shares: fundTrade.shares,
    sharePrice: fundTrade.sharePrice,
    transferId: fundTrade.transfer?.id ?? '',
    positionId: fundTrade.position?.id ?? '',
  };
}
