import { holdingBasePath } from '../paths';

export const transfersBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) => {
  return `${holdingBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/transfers`;
};

export const transferBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
  transferId: string,
) => {
  return `${transfersBasePath(
    tenantId,
    workspaceId,
    projectId,
    holdingId,
  )}/${transferId}`;
};
