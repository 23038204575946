import React from 'react';
import { useSelector } from 'react-redux';
import { getTransactionById } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

interface Props {
  projectId: string;
  transactionId: string;
}

export const ViewTransactionField = React.memo((props: Props) => {
  const transaction = useSelector((state: RootState) =>
    getTransactionById(selectWorkspaceData(state), {
      id: props.transactionId,
      projectId: props.projectId,
    }),
  );
  return <>{transaction ? transaction.title : ''}</>;
});
