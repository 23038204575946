// import * as mathjs from 'mathjs';
import moment from 'moment';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { getHoldingGroups } from './holding-groups';
import { createSelector } from 'reselect';

export interface PivotHoldings {
  pivot: string;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
}

interface PivotHoldingsProps {
  workspaceId: string;
  projectId: string;
  pivots: string[];
  holdings: HoldingState[];
  date: moment.Moment;
}

export const selectPivotHoldings = createCachedSelector(
  (state: DataState, _props: PivotHoldingsProps) => state,
  (_state: DataState, props: PivotHoldingsProps) => props.workspaceId,
  (_state: DataState, props: PivotHoldingsProps) => props.projectId,
  (_state: DataState, props: PivotHoldingsProps) => props.pivots,
  (_state: DataState, props: PivotHoldingsProps) => props.holdings,
  (_state: DataState, props: PivotHoldingsProps) => props.date,
  (state, workspaceId, projectId, pivots, holdings, date): PivotHoldings[] => {
    return pivots.map((pivot) => {
      return {
        pivot,
        holdingGroups: getHoldingGroups(state, {
          workspaceId,
          projectId,
          date,
          filterKey: pivot,
          holdings,
        }),
      };
    });
  },
)({
  keySelector: (_state, props) => props.holdings,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
