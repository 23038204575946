import * as React from 'react';

import { TableField } from '@pladdenico/table';
import { TransferDetailed } from '~src/domain/workspace/components/project/transfer/algorithm/transfer-detailed';

export function useTransferFields() {
  const fields = React.useMemo(() => {
    return {
      name: {
        id: 'id',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'id',
        field: 'id',
        type: TableField.FieldTypes.string,
        iteratee: (data: TransferDetailed) => data.transfer.id,
      },
      description: {
        id: 'description',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'description',
        field: 'description',
        type: TableField.FieldTypes.string,
        iteratee: (data: TransferDetailed) => data.transfer.description,
      },
      date: {
        id: 'date',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'date',
        field: 'date',
        type: TableField.FieldTypes.date,
        iteratee: (data: TransferDetailed) => data.transfer.date,
      },
      type: {
        id: 'type',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'type',
        field: 'type',
        type: TableField.FieldTypes.string,
        iteratee: (data: TransferDetailed) => data.transfer.type,
      },
      sharePrice: {
        id: 'sharePrice',
        direction: 'desc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'sharePrice',
        field: 'sharePrice',
        type: TableField.FieldTypes.numeric,
        iteratee: (data: TransferDetailed) => data.transferTyped?.trade.price,
      },
      shares: {
        id: 'shares',
        direction: 'desc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'shares',
        field: 'shares',
        type: TableField.FieldTypes.numeric,
        iteratee: (data: TransferDetailed) => data.transferTyped?.trade.shares,
      },
    };
  }, []);
  return fields;
}
export type TransferFields = ReturnType<typeof useTransferFields>;
