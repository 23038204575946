import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { PortfolioConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { getSelectedPortfolio } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { PortfolioConfigComponent } from '~src/domain/workspace/components/project/portfolio/config/portfolio-config.component';
import { usePortfolioDashboardConfig } from '~src/domain/workspace/components/project/portfolio/dashboard/hooks/use-portfolio-dashboard-config.hook';
import { useDashboard } from '~src/domain/workspace/components/project/portfolio/dashboard/menu/use-dashboard';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';
import { Config } from '~src/utils/interfaces/config';

import { Box, Drawer, Theme } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  portfolioDashboardConfig: PortfolioDashboardConfigState;
  portfolioConfig: PortfolioConfigState;
  dashboardElements: DashboardElementState[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'relative',
    // marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    flex: 1,
    flexDirection: 'column',
  },
}));

export const DashboardWithConfigComponent = (props: Props) => {
  const {
    workspace,
    project,
    portfolioProject,
    portfolioDashboardConfig,
    portfolioConfig,
    portfolio,
    dashboardElements,
  } = props;
  const selectedPortfolio = useSelector((state: RootState) => {
    const p = getSelectedPortfolio(selectWorkspaceData(state), workspace.id);
    if (!p) {
      return portfolio;
    }
    return p;
  });

  const { classes } = useStyles();

  const {
    layouts,
    updateLayouts,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  } = usePortfolioDashboardConfig(
    workspace,
    portfolioConfig,
    portfolioDashboardConfig,
  );

  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );

  const dashboardConfig = React.useMemo((): Config => {
    return {
      ...config,
      compactType: portfolioDashboardConfig.compactType,
    };
  }, [config, portfolioDashboardConfig.compactType]);

  const { menu, components, configOpen, toggleConfig } = useDashboard(
    workspace,
    project,
    portfolioProject,
    portfolio,
    dashboardConfig,
    layouts,
    updateLayouts,
    dashboardElements,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  );

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      sx={{ backgroundColor: primaryBackgroundColor.veryLight }}
    >
      {menu}
      <div className={classes.root}>
        <Box id="project-dashboard">{components}</Box>
        <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
          <PortfolioConfigComponent
            workspace={workspace}
            portfolioProject={portfolioProject}
            portfolio={selectedPortfolio}
            portfolioConfig={portfolioConfig}
            dashboardConfig={portfolioDashboardConfig}
          />
        </Drawer>
      </div>
    </Box>
  );
};
