import React from 'react';
import { useSelector } from 'react-redux';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import { getAllocationsByPortfolioId } from '~src/data/store/selectors/portfolio/allocations/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { Data } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/data';
import { useAllocationGoalData } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-allocation-goal-data';
import { useGoalPieChart } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-goal-pie-chart';
import { usePieChartMenuItems } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/use-pie-chart-menu-items';
import {
  defaultAssetClassBackgroundColor,
  defaultAssetClassFontColor,
  defaultCategoryBackgroundColor,
  defaultCategoryFontColor,
  defaultCurrencyBackgroundColor,
  defaultCurrencyFontColor,
  defaultIdBackgroundColor,
  defaultIdFontColor,
  defaultRegionBackgroundColor,
  defaultRegionFontColor,
  defaultSectorBackgroundColor,
  defaultSectorFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation/default-colors';
import { useAllocationGoalsMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/allocation-goals/menu.component';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  portfolio: PortfolioState;
  projectId: string;
  holdings: HoldingState[];
  config: Config;
  baseCurrency: CurrencyState;
  period: Period;
}

const getDefaultFontColor = (type: string) => {
  if (type === 'id') {
    return defaultIdFontColor;
  } else if (type === 'category') {
    return defaultCategoryFontColor;
  } else if (type === 'assetClass') {
    return defaultAssetClassFontColor;
  } else if (type === 'sector') {
    return defaultSectorFontColor;
  } else if (type === 'region') {
    return defaultRegionFontColor;
  } else if (type === 'currency') {
    return defaultCurrencyFontColor;
  }
  return '#FFFFFF';
};

const getDefaultBackgroundColor = (type: string) => {
  if (type === 'id') {
    return defaultIdBackgroundColor;
  } else if (type === 'category') {
    return defaultCategoryBackgroundColor;
  } else if (type === 'assetClass') {
    return defaultAssetClassBackgroundColor;
  } else if (type === 'sector') {
    return defaultSectorBackgroundColor;
  } else if (type === 'region') {
    return defaultRegionBackgroundColor;
  } else if (type === 'currency') {
    return defaultCurrencyBackgroundColor;
  }
  return '#FFFFFF';
};

export const AllocationGoalElement = React.memo(
  ({
    // workspace,
    portfolio,
    // projectId,
    // holdings,
    config,
    // baseCurrency,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    // useComponentUpdateDebug(`AllocationElement ${id}`, {
    //   workspace,
    //   portfolio,
    //   projectId,
    //   holdings,
    //   config,
    //   baseCurrency,
    //   active,
    //   setActive,
    //   setInactive,
    //   loading,
    //   id,
    //   remove,
    //   element,
    //   updated,
    // });
    const allocations = useSelector((state: RootState) =>
      getAllocationsByPortfolioId(selectWorkspaceData(state), portfolio.id),
    );
    const [holdingFilterId] = React.useState(0);
    const filter = useSelector((state: RootState) =>
      getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
    );

    const [showFilteredOut, setShowFilteredOut] = React.useState(true);

    const filterKey = (element.config.type as string) ?? 'category';
    const type = filterKey;

    const allocationGoalData: Data[] = useAllocationGoalData(
      allocations,
      filter,
      showFilteredOut,
      filterKey,
    );

    const getColorIndex = React.useCallback(
      (id: string) => {
        const idx = allocationGoalData.findIndex((value) => value.id === id);
        if (idx !== -1) {
          return idx;
        }
        return 0;
      },
      [allocationGoalData],
    );

    const menuItems = usePieChartMenuItems(
      allocationGoalData,
      filter,
      filterKey,
      showFilteredOut,
      setShowFilteredOut,
    );
    const { menu, handleOpen, isOpen } = useAllocationGoalsMenu(
      element,
      menuItems,
      remove,
      updated,
    );

    const content = useGoalPieChart(
      filterKey,
      allocationGoalData,
      getColorIndex,
    );

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? getDefaultFontColor(type),
          backgroundColor:
            element.config.backgroundColor ?? getDefaultBackgroundColor(type),
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
      type,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          {content}
        </ElementComponent>
        {menu}
      </>
    );
  },
);
