import { fetchHoldings } from '~src/data/store/modules/holdings/base/requests';
import { exportsActions } from '~src/data/store/reducers/workspace/exports/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

export const fetcher = (
  workspace: WorkspaceState,
  fetcherId: string,
  holdingIds: string[] | null = null,
) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      fetchHoldings(
        workspace.tenantId,
        workspace.id,
        holdingIds,
        (
          dispatch: any,
          _tenantId: string,
          _workspaceId: string,
          outputs: graphqlWorkspaceTypes.Holding[],
          _subscriptions: Operation[],
        ) => {
          return dispatch(
            exportsActions.addOne({
              id: fetcherId,
              blob: `${JSON.stringify(outputs)}`,
            }),
          );
        },
      ),
    );
  };
};
