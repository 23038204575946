import { handleBondTrades } from '~src/data/store/modules/holdings/bond/trade/handler';
import { bondTradesActions } from '~src/data/store/reducers/holding/holding-types/bond/bond-trade/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { getBondTradesQuery } from '~src/services/graphql/workspace/schema/holdings/holding-types/bonds/bonds-trade.query';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchBondTrades(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    // const node = GetBondTradesDocument;
    // const node = graphqlWorkspaceTypes.GetBondTradesDocument;
    // const variables: GetBondTradesQueryVariables = {
    //   ids,
    // };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(getBondTradesQuery, { ids }, context).then((data) => {
      if (data.getBondTrades) {
        return handleBondTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.getBondTrades,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
    // return investRequest(GetBondTradesDocument, variables, context).then(
    //   (data) => {
    //     if (data.getBondTrades) {
    //       return handleBondTrades(dispatch, data.getBondTrades);
    //     }
    //     return [];
    //   }
    // );
  };
}

export function createBondTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateBondTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateBondTradesDocument;
    const variables: graphqlWorkspaceTypes.CreateBondTradesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createBondTrades) {
        return handleBondTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.createBondTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateBondTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateBondTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateBondTradesDocument;
    const variables: graphqlWorkspaceTypes.UpdateBondTradesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateBondTrades) {
        return handleBondTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.updateBondTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertBondTrades(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertBondTradeInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertBondTradesDocument;
    const variables: graphqlWorkspaceTypes.UpsertBondTradesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertBondTrades) {
        return handleBondTrades(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertBondTrades,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteBondTrades(bondHoldingId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      bondTradesActions.removeManyElements(
        ids.map((id) => {
          return {
            bondHoldingId,
            id,
          };
        }),
      ),
    );
  };
}

export function deleteBondTrades(
  tenantId: string,
  workspaceId: string,
  bondHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteBondTradesDocument;
    const variables: graphqlWorkspaceTypes.DeleteBondTradesMutationVariables = {
      inputs: ids.map((id) => {
        return { id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteBondTrades) {
        dispatch(handleDeleteBondTrades(bondHoldingId, data.deleteBondTrades));
      }
      return [];
    });
  };
}
