import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { DataState } from '~src/data/store/reducers/reducers';
import { getBondById } from '~src/data/store/selectors/asset/asset-types/bonds/selectors';
import { getQuotesByBond } from '~src/data/store/selectors/finance/quotes/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';

interface Props {
  workspaceId: string;
  bondId: string;
}

export const getQuotesByBondId = createCachedSelector(
  // (state: DataState) => state,
  (state: DataState) => selectFinancialDataFromData(state),
  (state: DataState, props: Props) =>
    getBondById(selectWorkspaceDataFromData(state), {
      id: props.bondId,
      workspaceId: props.workspaceId,
    }),
  (state, bond) => {
    return bond ? getQuotesByBond(state, bond) : [];
  },
)({
  keySelector: (_state, props: Props) => props.bondId,
  selectorCreator: createSelector,
});

export const getLastQuoteByBondId = createCachedSelector(
  (state: DataState, props: Props) => getQuotesByBondId(state, props),
  (quotes) => {
    if (quotes != null) {
      return quotes.length > 0 ? quotes[-1] : undefined;
    }
  },
)({
  keySelector: (_state, props: Props) => props.bondId,
  selectorCreator: createSelector,
});
