import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectTenantData } from '~src/data/store/selectors/selectors';
import { getTenantById } from '~src/data/store/selectors/tenant/tenants/selectors';
import {
  LinkRouter,
  maxItems,
} from '~src/domain/tenant/components/top-bar/breadcrumb/breadcrumbs.component';
import { tenantRootPath } from '~src/navigation/paths/tenant/paths';

import { Breadcrumbs, Typography } from '@mui/material';

export function useTenantBreadcrumbs(last: boolean) {
  const { tenantId } = useParams<{
    tenantId: string;
  }>();
  const tenant = useSelector((state: RootState) =>
    getTenantById(selectTenantData(state), tenantId),
  );
  console.log('tenant', tenantId, tenant);
  if (tenant) {
    const tenantElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={tenantRootPath(tenant.id)}
      >
        {tenant.name}
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={tenantRootPath(tenant.id)}
        to={tenantRootPath(tenant.id)}
      >
        {tenant.name}
      </LinkRouter>
    );
    return tenantElement;
  }
  return [];
}

export function TenantBreadcrumbComponent(props: { last: boolean }) {
  const tenantBreadcrumps = useTenantBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {tenantBreadcrumps}
    </Breadcrumbs>
  );
}
