import { upsertStockHoldings } from '~src/data/store/modules/holdings/stock/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'stockHoldings';

export interface StockHolding {
  id: string;
  holdingId: string;
  stockId: string;
}

const header: Array<keyof StockHolding> = ['id', 'holdingId', 'stockId'];

export const stockHoldingExportHandler = (
  data: StockHolding[],
): ExportHandler<StockHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const stockHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<StockHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertStockHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
