import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { createProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getDashboardElementsByIds } from '~src/data/store/selectors/common/dashboard/element/selectors';
import { selectViewData } from '~src/data/store/selectors/selectors';
import { ProjectReportEditDialogComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/project-report-edit-dialog.component';
import { InputData } from '../form/project-report-input.data';
import { useReportDashboardElements } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/use-report-dashboard-elements';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { DashboardElement } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export const useProjectReportDuplicateDialogComponent = (
  workspace: WorkspaceState,
  projectId: string,
  report: ProjectReportState,
) => {
  // const { handleClose, isShowing, handleCancel, workspace, projectId } = props;

  const dispatch = useDispatch<AppDispatch>();
  const modal = useVisible();

  const elements = useSelector((root: RootState) => {
    return getDashboardElementsByIds(selectViewData(root), report.elementIds);
  });

  const { addDashboardElements } = useReportDashboardElements(workspace);

  const onSave = React.useCallback(
    (data: InputData) => {
      console.log('SAVING');

      return dispatch(
        createProjectReports(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            projectId,
            name: data.name,
            elementOperations: [],
            layouts: '{}',
          },
        ]),
      )
        .then((projectReports) => {
          const projectReport = projectReports[0];
          const newElements: DashboardElement[] = elements.map((element) => {
            return {
              ...element,
              config: JSON.stringify(element.config),
              id: uuid.v1(),
            };
          });
          console.log(elements);
          return addDashboardElements(projectReport, newElements).then((a) => {
            console.log(a);
          });
        })
        .then(() => modal.close());
    },
    [
      addDashboardElements,
      dispatch,
      elements,
      modal,
      projectId,
      workspace.id,
      workspace.tenantId,
    ],
  );
  const dialog = (
    <ProjectReportEditDialogComponent
      handleCancel={modal.close}
      handleClose={modal.close}
      isShowing={modal.isShowing}
      onSave={onSave}
    />
  );

  return {
    modal,
    dialog,
  };
};
