import * as _ from 'lodash';
export function deepLodashPick(
  obj: any,
  predicate: (key: string | number | symbol, value: any) => boolean,
  newObj = {},
  path = new Array<string | number | symbol>(),
) {
  for (const k in obj) {
    const value = obj[k];
    if (_.isObject(value)) {
      path.push(k);
      deepLodashPick(value, predicate, newObj, path);
      path.pop();
    } else {
      if (predicate(k, value)) {
        _.set(newObj, [...path, k], value);
      }
    }
  }
  return newObj;
}
