import { bankNoteHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/bank-note/bank-note-holding/reducer';
import { bondHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import { cryptoHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/crypto/crypto-holding/reducer';
import { fundHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import { loanHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/loan/loan-holding/reducer';
import { stockGoalRulesSelectors } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import { stockHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/stock/stock-holding/reducer';
import { stockPositionsSelectors } from '~src/data/store/reducers/holding/holding-types/stock/stock-position/reducer';
import { stockTradesSelectors } from '~src/data/store/reducers/holding/holding-types/stock/stock-trade/reducer';
import { warrantHoldingsSelectors } from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import { holdingSelectors } from '~src/data/store/reducers/holding/holdings/reducer';
import { transfersSelectors } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { valuationsSelectors } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { ExportWorkspaceStore } from '~src/domain/workspace/components/data/workspace/export-workspace-store';
import { ExportHandler } from '~src/services/xlsx/handlers/ioutput-handler';
import { bankNoteHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/bank-note-holdings/mapper';
import { bondHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/bond-holdings/mapper';
import { cryptoHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/crypto-holdings/mapper';
import { fundHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/fund-holdings/mapper';
import { loanHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/loan-holdings/mapper';
import { holdingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/mapper';
import { stockHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/mapper';
import { stockGoalRuleExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/stock-goal-rules/mapper';
import { stockPositionExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/stock-positions/mapper';
import { stockTradeExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/stock-holdings/stock-trades/mapper';
import { transferExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/transfers/mapper';
import { valuationExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/valuations/mapper';
import { warrantHoldingExportHandler } from '~src/services/xlsx/handlers/workspaces/projects/holdings/warrant-holdings/mapper';

export const exportHandlers = (store: ExportWorkspaceStore) => {
  const handlers: ExportHandler<any>[] = [];
  const state = store.getState();

  const holdings = holdingSelectors.selectAllElements(
    state.holding.holding.holdings,
  );
  handlers.push(holdingExportHandler(holdings));
  const valuations = valuationsSelectors.selectAllElements(
    state.holding.holding.valuation.valuations,
  );
  handlers.push(valuationExportHandler(valuations));

  const transfers = transfersSelectors.selectAllElements(
    state.holding.holding.transfer.transfers,
  );
  handlers.push(transferExportHandler(transfers));

  const stockHoldings = stockHoldingsSelectors.selectAllElements(
    state.holding.holding.stockHolding.stockHoldings,
  );
  handlers.push(stockHoldingExportHandler(stockHoldings));

  const stockHoldingGoalRules = stockGoalRulesSelectors.selectAllElements(
    state.holding.holding.stockHolding.stockGoalRules,
  );
  handlers.push(stockGoalRuleExportHandler(stockHoldingGoalRules));

  const stockHoldingPositions = stockPositionsSelectors.selectAllElements(
    state.holding.holding.stockHolding.stockPositions,
  );
  handlers.push(stockPositionExportHandler(stockHoldingPositions));

  const stockHoldingTrades = stockTradesSelectors.selectAllElements(
    state.holding.holding.stockHolding.stockTrades,
  );
  handlers.push(stockTradeExportHandler(stockHoldingTrades));

  const bankNoteHoldings = bankNoteHoldingsSelectors.selectAllElements(
    state.holding.holding.bankNoteHolding.bankNoteHoldings,
  );
  handlers.push(bankNoteHoldingExportHandler(bankNoteHoldings));

  const bondHoldings = bondHoldingsSelectors.selectAllElements(
    state.holding.holding.bondHolding.bondHoldings,
  );
  handlers.push(bondHoldingExportHandler(bondHoldings));

  const cryptoHoldings = cryptoHoldingsSelectors.selectAllElements(
    state.holding.holding.cryptoHolding.cryptoHoldings,
  );
  handlers.push(cryptoHoldingExportHandler(cryptoHoldings));

  const fundHoldings = fundHoldingsSelectors.selectAllElements(
    state.holding.holding.fundHolding.fundHoldings,
  );
  handlers.push(fundHoldingExportHandler(fundHoldings));

  const loanHoldings = loanHoldingsSelectors.selectAllElements(
    state.holding.holding.loanHolding.loanHoldings,
  );
  handlers.push(loanHoldingExportHandler(loanHoldings));

  const warrantHoldings = warrantHoldingsSelectors.selectAllElements(
    state.holding.holding.warrantHolding.warrantHoldings,
  );
  handlers.push(warrantHoldingExportHandler(warrantHoldings));

  return handlers;
};
