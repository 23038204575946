import { combineReducers } from 'redux';

import {
  initialWarrantHoldingState,
  warrantHoldingsReducer,
} from './warrant-holding/reducer';

export const warrantHoldingInitialState = {
  warrantHoldings: initialWarrantHoldingState,
  // warrantHoldingsFetch: initialWarrantHoldingsFetchReducer,
  // warrantPosition: warrantPositionInitialState,
};

export const warrantHoldingReducer = combineReducers({
  warrantHoldings: warrantHoldingsReducer,
  // warrantHoldingsFetch: warrantHoldingsFetchReducer,
  // warrantPosition: warrantPositionReducer,
});
