import * as React from 'react';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { ViewCurrencyField } from '~src/components/fields/financial/currency/view-currency-field';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';

import { TableField } from '@pladdenico/table';

export function useCurrencyField<T>(
  getCurrencyId: (data: T) => string,
  update: (data: T, currency: CurrencyState) => T,
): TableField.Field<T> {
  return {
    id: 'currency',
    name: 'currency',
    field: 'currencyId',
    type: TableField.FieldTypes.string,
    iteratee: (data) => {
      // const selectGetCurrencyById = makeGetCurrencyById();
      const currency = getCurrencyById(selectFinancialData(store.getState()), {
        id: getCurrencyId(data),
      });
      return currency ? currency.symbol : '';
    },
    update: (data, currency: CurrencyState) => {
      return update(data, currency);
    },
    renderView: (editProps) => {
      return <ViewCurrencyField currencyId={getCurrencyId(editProps.data)} />;
    },
    renderEdit: (editProps) => {
      return (
        <EditCurrencyField
          handleValue={editProps.onChange}
          currencyId={getCurrencyId(editProps.data)}
          labelled={false}
        />
      );
    },
  };
}
