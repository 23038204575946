import { deleteAction, singleAction } from '~src/data/base/old/action';
import { HoldingFilterState } from '~src/data/store/modules/holdings/filter/reducer';

import { DELETED_FILTER, FILTER } from './action';
import { Filter } from '~src/utils/common/filter';

export function createFilter(id: number, filter: Filter) {
  return (dispatch: any) => {
    const holdingFilterState: HoldingFilterState = {
      id,
      filter: { ...filter },
    };
    dispatch(singleAction(holdingFilterState, FILTER));
    // dispatch(holdingFilterAction(id, holdingFilter));
  };
}

export function deleteHoldingFilter(id: number) {
  return (dispatch: any) => {
    dispatch(deleteAction(id, DELETED_FILTER));
    // dispatch(deletedHoldingFilterAction(id));
  };
}
