import { holdingConfigsActions } from '~src/data/store/reducers/holding/config/configs/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleHoldingConfigs } from './handler';

export function fetchHoldingConfigs(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetHoldingConfigsDocument;
    const variables: graphqlWorkspaceTypes.GetHoldingConfigsQueryVariables = {
      holdingId,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getHoldingConfigs) {
        return handleHoldingConfigs(
          dispatch,
          holdingId,
          data.getHoldingConfigs,
          tenantId,
          workspaceId,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createHoldingConfigs(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  inputs: graphqlWorkspaceTypes.CreateHoldingConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateHoldingConfigsDocument;
    const variables: graphqlWorkspaceTypes.CreateHoldingConfigsMutationVariables =
      {
        holdingId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createHoldingConfigs) {
        return handleHoldingConfigs(
          dispatch,
          holdingId,
          data.createHoldingConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateHoldingConfigs(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  inputs: graphqlWorkspaceTypes.UpdateHoldingConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateHoldingConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpdateHoldingConfigsMutationVariables =
      {
        holdingId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateHoldingConfigs) {
        return handleHoldingConfigs(
          dispatch,
          holdingId,
          data.updateHoldingConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertHoldingConfigs(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  inputs: graphqlWorkspaceTypes.UpsertHoldingConfigInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertHoldingConfigsDocument;
    const variables: graphqlWorkspaceTypes.UpsertHoldingConfigsMutationVariables =
      {
        holdingId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertHoldingConfigs) {
        return handleHoldingConfigs(
          dispatch,
          holdingId,
          data.upsertHoldingConfigs,
          tenantId,
          workspaceId,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deleteHoldingConfigs(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: number[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteHoldingConfigsDocument;
    const variables: graphqlWorkspaceTypes.DeleteHoldingConfigsMutationVariables =
      {
        holdingId,
        inputs: ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteHoldingConfigs) {
        dispatch(
          holdingConfigsActions.removeManyElements(
            data.deleteHoldingConfigs.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
