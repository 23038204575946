import { compact } from 'lodash';
import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { getCommitmentsDetailed } from '~src/data/store/selectors/finance/commitments/holding-commitment-detailed';
import { useCommitmentFields } from '~src/domain/workspace/components/project/commitment/algorithm/use-fields';

import { TableField, TableOperations } from '@pladdenico/table';
import { CommitmentData } from '../commitment-data';

export const useCommitmentsAlgorithm = (
  workspace: WorkspaceState,
  project: ProjectState,
  commitments: CommitmentData[],
) => {
  const fields = useCommitmentFields();

  const initialCommitmentSorters = React.useMemo(() => {
    const sorters: TableField.Sorter<CommitmentData>[] = compact([
      TableOperations.createSorter(fields.name, 'asc'),
      TableOperations.createSorter(fields.description, 'asc'),
      TableOperations.createSorter(fields.type, 'asc'),
      TableOperations.createSorter(fields.commitmentDate, 'asc'),
    ]);
    return sorters;
  }, [fields.commitmentDate, fields.description, fields.name, fields.type]);

  const initialCommitmentFilters: TableField.Filter<CommitmentData>[] =
    React.useMemo(
      () => [
        TableOperations.createFilter(fields.name),
        TableOperations.createFilter(fields.description),
        TableOperations.createFilter(fields.type),
        TableOperations.createFilter(fields.commitmentDate),
        // TableOperations.createFilter(fields.position),
      ],
      [fields.commitmentDate, fields.description, fields.name, fields.type],
    );

  const algoData = TableOperations.useDataAlgorithms(
    commitments,
    initialCommitmentSorters,
    initialCommitmentFilters,
  );

  return {
    algoData,
    fields,
  };
};
