import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
// import { Holding } from '~src/models/classes/holding';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { ExchangeRateState } from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingXIRRs } from '~src/data/store/selectors/finance/xirr/holding-xirrs';
import { selectData } from '~src/data/store/selectors/selectors';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  holding: HoldingState;
  valuations: ValuationState[];
  date: moment.Moment;
  transfers: TransferState[];
  baseCurrency: CurrencyState;
  exchangeRateState: ExchangeRateState;
}

const useStyles = makeStyles()(() => ({
  container: {
    flex: 1,
    overflow: 'hidden',
    // height: '100%',
  },
}));

export const HoldingIrrComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const data = useSelector((state: RootState) =>
    getHoldingXIRRs(selectData(state), {
      holdingId: props.holding.id,
      date: props.date,
      currency: props.baseCurrency,
      useLiveValue: false,
    }),
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div className={classes.container}>
        <ResponsiveContainer>
          <BarChart
            // width={600}
            // height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="irr" fill="#8884d8" label={true} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
});
