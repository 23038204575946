import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { EditEntity } from '~src/components/fields/workspace/entity/entity/edit-entity';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export const useAssetEntityField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  label: string,
  defaultValue: PathValue<TFieldValues, TName>,
  workspace: WorkspaceState,
) => {
  return (
    <Controller
      name={tname}
      rules={{ required: true }}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        console.log('fieldState.error', fieldState.error);
        return (
          <EditEntity
            workspace={workspace}
            label={label}
            handleValue={(entity) => field.onChange(entity.id)}
            entityId={field.value}
            error={
              fieldState.error
                ? { message: fieldState.error.message }
                : undefined
            }
          />
        );
      }}
    />
  );
};
