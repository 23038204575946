import { allocationsActions } from '~src/data/store/reducers/portfolio/allocations/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleAllocations } from './handler';

export function fetchAllocations(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetAllocationsDocument;
    const variables: graphqlWorkspaceTypes.GetAllocationsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getAllocations) {
        return handleAllocations(dispatch, workspaceId, data.getAllocations);
      }
      return [];
    });
  };
}

export function createAllocations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateAllocationInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateAllocationsDocument;
    const variables: graphqlWorkspaceTypes.CreateAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createAllocations) {
        return handleAllocations(dispatch, workspaceId, data.createAllocations);
      }
      return [];
    });
  };
}

export function updateAllocations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateAllocationInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateAllocationsDocument;
    const variables: graphqlWorkspaceTypes.UpdateAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateAllocations) {
        return handleAllocations(dispatch, workspaceId, data.updateAllocations);
      }
      return [];
    });
  };
}

export function upsertAllocations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertAllocationInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertAllocationsDocument;
    const variables: graphqlWorkspaceTypes.UpsertAllocationsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertAllocations) {
        return handleAllocations(dispatch, workspaceId, data.upsertAllocations);
      }
      return [];
    });
  };
}

export function deleteAllocations(
  tenantId: string,
  workspaceId: string,
  portfolioId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteAllocationsDocument;
    const variables: graphqlWorkspaceTypes.DeleteAllocationsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteAllocations) {
        dispatch(
          allocationsActions.removeManyElements(
            data.deleteAllocations.map((p) => {
              return {
                portfolioId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
