import {
  Checkbox,
  FormControlLabel,
  ListSubheader,
  MenuItem,
} from '@mui/material';

export function useAllocationPieChartMenu(
  clearFilter: () => void,
  resetFilter: () => void,
  toggleShowFilteredOut: () => void,
  renderFilterList: () => JSX.Element,
  filterKey: string,
  showFilteredOut: boolean,
  _activeFilter: boolean,
) {
  return [
    <ListSubheader key="filter">Filter</ListSubheader>,
    <MenuItem key="filterList">{renderFilterList()}</MenuItem>,
    <MenuItem
      key="clearFilter"
      onClick={clearFilter}
    >{`Clear ${filterKey} filter`}</MenuItem>,
    <MenuItem key="resetFilter" onClick={resetFilter}>
      Reset all filters
    </MenuItem>,
    <MenuItem key="toggle">
      <FormControlLabel
        control={
          <Checkbox
            checked={showFilteredOut}
            onClick={toggleShowFilteredOut}
            value="showFilteredOut"
            color="primary"
          />
        }
        label={`Show filtered out ${filterKey}`}
      />
    </MenuItem>,
  ];
}
