import { Operation } from '@pladdenico/portfolio-api';
import { parseHoldingConfig } from '~src/data/store/modules/holdings/configs/configs/parser';
import {
  HoldingDashboardConfigStateVisitor,
  HoldingDashboardConfigVisitable,
  HoldingDashboardConfigVisitor,
} from '~src/data/store/visitors/holding/config/holding-dashboard-config-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  holdingConfigsActions,
  HoldingConfigState,
} from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingConfig } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface HoldingConfigVisitor {
  visit(holding: HoldingConfigVisitable): HoldingConfigState;
  post(): void;
  holdingDashboardConfigVisitor: HoldingDashboardConfigVisitor;
}

export class HoldingConfigVisitable implements Visitable<HoldingConfigVisitor> {
  constructor(
    private _holdingId: string,
    private _holdingConfig: HoldingConfig,
  ) {}
  public accept(visitor: HoldingConfigVisitor) {
    if (this._holdingConfig.holdingDashboardConfigs) {
      this._holdingConfig.holdingDashboardConfigs.forEach((dashboardConfig) => {
        const holdingDashboardConfigVisitable =
          new HoldingDashboardConfigVisitable(
            this._holdingConfig.id,
            dashboardConfig,
          );
        holdingDashboardConfigVisitable.accept(
          visitor.holdingDashboardConfigVisitor,
        );
      });
    }
    return visitor.visit(this);
  }

  public parse(): HoldingConfigState {
    const holdingConfig = this._holdingConfig;
    return parseHoldingConfig(this._holdingId, holdingConfig);
  }
}

export class HoldingConfigStateVisitor implements HoldingConfigVisitor {
  private _holdingConfigs: HoldingConfigState[];
  holdingDashboardConfigVisitor: HoldingDashboardConfigVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._holdingConfigs = [];
    this.holdingDashboardConfigVisitor = new HoldingDashboardConfigStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }

  public visit(holdingConfig: HoldingConfigVisitable): HoldingConfigState {
    const holdingConfigState = holdingConfig.parse();
    this._holdingConfigs.push(holdingConfigState);
    return holdingConfigState;
  }

  post() {
    this._dispatch(
      holdingConfigsActions.upsertManyElements(this._holdingConfigs),
    );
    this.holdingDashboardConfigVisitor.post();
  }
}
