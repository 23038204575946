import { Tab } from '~src/utils/interfaces/tab';

import { UserTabs } from './user-tabs.component';

function createTabs(): Tab<UserTabs>[] {
  const tabs: Tab<UserTabs>[] = [];

  {
    const path = {
      tab: UserTabs.Profile,
      exact: true,
      path: '/user/profile',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: UserTabs.Tenants,
      exact: true,
      path: '/user/tenants',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: UserTabs.Messages,
      exact: true,
      path: '/user/messages',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: UserTabs.Invitations,
      exact: true,
      path: '/user/invitations',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  return tabs;
}

export function useUserTabs() {
  return createTabs();
}
