import React from 'react';
import { DeepPartial, FieldPath, UseFormSetValue } from 'react-hook-form';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { FormValues } from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
import { getHoldingConfigBuy } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-buy';
import { getHoldingConfigCost } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-cost';
import { getHoldingConfigDeposit } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-deposit';
import { getHoldingConfigDividend } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-dividend';
import { getHoldingConfigInterest } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-interest';
import { getHoldingConfigLend } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-lend';
import { getHoldingConfigRepayment } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-repayment';
import { getHoldingConfigSell } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-sell';
import { getHoldingConfigWithdrawal } from '~src/domain/workspace/components/project/transaction/form/holding/config/holding-config-withdrawal';
import { HoldingFieldListener } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-field-listener';
import { TransactionType } from '~src/utils/finance/transaction-type';

const createTransferValueListener = (
  srcId: string,
  destId: string,
): HoldingFieldListener<FieldPath<FormValues>, FormValues> => {
  return {
    srcId,
    destId,
    paths: (index: number) => [
      `holdings.${index}.transferInputData.base.value`,
      `holdings.${index}.transferInputData.base.cost`,
    ],
    callback: (
      index: number,
      formValue: DeepPartial<FormValues>,
      setValue: UseFormSetValue<FormValues>,
    ): Promise<boolean> => {
      // const value = path && getValues(path);
      const holding = formValue.holdings?.find(
        (holding) => holding?.id === srcId,
      );
      if (holding) {
        const transferValue = holding.transferInputData?.base?.value ?? 0;
        const costValue = holding.transferInputData?.base?.cost ?? 0;
        const value = transferValue + costValue;
        const valuePathDest: FieldPath<FormValues> = `holdings.${index}.transferInputData.base.value`;
        setValue(valuePathDest, value);
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    },
  };
};

export interface HoldingConfigsListeners {
  holdings: HoldingConfig[];
  listeners: HoldingFieldListener<FieldPath<FormValues>, FormValues>[];
}

export const useHoldingConfigs = (
  transactionType: TransactionType,
  holding?: HoldingState | undefined,
): HoldingConfigsListeners => {
  const holdingConfigListeners = React.useMemo(() => {
    if (transactionType === TransactionType.Account) {
      const depositConfig = getHoldingConfigDeposit();
      const withdrawalConfig = getHoldingConfigWithdrawal(holding?.id);
      const listeners = [
        createTransferValueListener(withdrawalConfig.id, depositConfig.id),
      ];
      return { holdings: [depositConfig, withdrawalConfig], listeners };
    } else if (transactionType === TransactionType.Borrow) {
      const withdrawalConfig = getHoldingConfigWithdrawal();
      const buyConfig = getHoldingConfigBuy(holding?.id);
      const listeners = [
        createTransferValueListener(buyConfig.id, withdrawalConfig.id),
      ];
      return { holdings: [buyConfig, withdrawalConfig], listeners };
    } else if (transactionType === TransactionType.Buy) {
      const withdrawalConfig = getHoldingConfigWithdrawal();
      const buyConfig = getHoldingConfigBuy(holding?.id);

      const listeners = [
        createTransferValueListener(buyConfig.id, withdrawalConfig.id),
      ];
      return { holdings: [buyConfig, withdrawalConfig], listeners };
    } else if (transactionType === TransactionType.Cost) {
      const costConfig = getHoldingConfigCost();
      return { holdings: [costConfig], listeners: [] };
    } else if (transactionType === TransactionType.Custom) {
      return { holdings: [], listeners: [] };
    } else if (transactionType === TransactionType.Dividend) {
      const depositConfig = getHoldingConfigDeposit();
      const dividendConfig = getHoldingConfigDividend(holding?.id);
      const listeners = [
        createTransferValueListener(dividendConfig.id, depositConfig.id),
      ];
      return { holdings: [depositConfig, dividendConfig], listeners };
    } else if (transactionType === TransactionType.Interest) {
      const depositConfig = getHoldingConfigDeposit();
      const interestConfig = getHoldingConfigInterest(holding?.id);
      const listeners = [
        createTransferValueListener(interestConfig.id, depositConfig.id),
      ];
      return { holdings: [interestConfig, depositConfig], listeners };
    } else if (transactionType === TransactionType.Lend) {
      const withdrawalConfig = getHoldingConfigWithdrawal();
      const buyConfig = getHoldingConfigLend(holding?.id);
      const listeners = [
        createTransferValueListener(buyConfig.id, withdrawalConfig.id),
      ];
      return { holdings: [buyConfig, withdrawalConfig], listeners };
    } else if (transactionType === TransactionType.Repayment) {
      const withdrawalConfig = getHoldingConfigWithdrawal();
      const buyConfig = getHoldingConfigRepayment(holding?.id);
      const listeners = [
        createTransferValueListener(buyConfig.id, withdrawalConfig.id),
      ];
      return { holdings: [buyConfig, withdrawalConfig], listeners };
    } else if (transactionType === TransactionType.Sell) {
      const depositConfig = getHoldingConfigDeposit();
      const sellConfig = getHoldingConfigSell(holding?.id);
      const listeners = [
        createTransferValueListener(sellConfig.id, depositConfig.id),
      ];
      return { holdings: [sellConfig, depositConfig], listeners };
    }
    return { holdings: [], listeners: [] };
  }, [holding, transactionType]);
  return holdingConfigListeners;
};
