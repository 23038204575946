import React from 'react';
import { SearchInput } from '~src/components/search/search-input';

import { Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { CreatePaperComponent } from '~src/domain/workspace/components/finance/papers/paper/form/create.component';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {}

export const PapersToolbar = (_props: Props) => {
  const { open, close, isShowing } = useVisible();

  const { classes } = useStyles();

  const handleAddPaper = () => {
    open();
  };

  return (
    <div
      // {...rest}
      // className={clsx(classes.root, className)}
      className={classes.root}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button color="primary" variant="contained" onClick={handleAddPaper}>
          Add paper
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search paper"
        />
      </div>
      <CreatePaperComponent open={isShowing} handleClose={close} />
    </div>
  );
};
