import React from 'react';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { useArrayReducer } from '~src/hooks/utils/use-array-reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface PortfolioDashboardProps {
  dashboardElements: DashboardElementState[];
  addDashboardElements?: (
    dashboardElements: graphqlWorkspaceTypes.DashboardElement[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>;
  removeDashboardElements?: (
    dashboardElementIds: string[],
  ) => Promise<string[]>;
  updateDashboardElements?: (
    dashboardElement: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
  ) => Promise<graphqlWorkspaceTypes.DashboardElement[]>;
}

function initElements(elements: DashboardElementState[]) {
  return elements;
}

export function useDashboardElements(props: PortfolioDashboardProps) {
  const {
    dashboardElements,
    addDashboardElements,
    removeDashboardElements,
    updateDashboardElements,
  } = props;

  const elementReducer = useArrayReducer<DashboardElementState, string>(
    (data) => data.id,
  );
  const [_elements, _dispatchElements] = React.useReducer(
    elementReducer,
    dashboardElements,
    initElements,
  );

  const stateToElement = React.useCallback(
    <T extends Record<string, unknown>>(element: T) => {
      return {
        ...element,
        config: JSON.stringify(element.config),
      };
    },
    [],
  );

  React.useEffect(() => {
    _dispatchElements({
      type: 'set',
      data: dashboardElements,
    });
  }, [dashboardElements]);

  const add = React.useCallback(
    (
      elementStates: DashboardElementState[],
    ): Promise<graphqlWorkspaceTypes.DashboardElement[]> => {
      const elements = elementStates.map((elementState) =>
        stateToElement(elementState),
      );
      if (addDashboardElements) {
        return addDashboardElements(elements).then((elements) => {
          elementStates.forEach((elementState) => {
            _dispatchElements({ type: 'add', data: elementState });
          });
          return elements;
        });
      }
      return Promise.resolve<graphqlWorkspaceTypes.DashboardElement[]>([]);
    },
    [addDashboardElements, stateToElement],
  );
  const update = React.useCallback(
    (
      elementStates: Array<{
        id: string;
        data: Partial<DashboardElementState>;
      }>,
    ) => {
      const elements = elementStates.map((elementState) =>
        stateToElement({ id: elementState.id, ...elementState.data }),
      );
      if (updateDashboardElements) {
        return updateDashboardElements(elements).then((elements) => {
          elementStates.forEach((elementState) => {
            _dispatchElements({
              type: 'update',
              id: elementState.id,
              partialData: elementState.data,
            });
          });
          return elements;
        });
        // } else {
        // return Promise.resolve(element);
      }
      return Promise.resolve<graphqlWorkspaceTypes.DashboardElement[]>([]);
    },
    [stateToElement, updateDashboardElements],
  );
  // const update = React.useCallback(
  //   (elementState: DashboardElementState) => {
  //     return updateDashboardElement(stateToElement(elementState)).then(
  //       (element) => {
  //         _dispatchElements({ type: 'update', data: elementState });
  //         return element;
  //       }
  //     );
  //   },
  //   [stateToElement, updateDashboardElement]
  // );
  const remove = React.useCallback(
    (ids: string[]): Promise<string[]> => {
      if (removeDashboardElements) {
        return removeDashboardElements(ids).then(() => {
          ids.forEach((id) => {
            _dispatchElements({ type: 'delete', id });
          });
          return ids;
        });
      }
      return Promise.resolve([]);
    },
    [removeDashboardElements],
  );

  return {
    elements: _elements,
    add,
    update,
    remove,
  };
}
