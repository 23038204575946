import React from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BondPositionState } from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectLastPosition } from '~src/data/store/selectors/holding/holding-types/bond/bond-position/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { useCalculatePositionShares } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/use-calculate-position-shares';
import { useTransferCostField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-cost-field';
import { useTransferCurrencyField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-currency-field';
import { useTransferDateField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-date-field';
import { useTransferStatusField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-status-field';
import { useTransferTypeField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-type-field';
import { useTransferValueField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-value-field';
import { useTransferBondPositionSharesField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/bond/use-position-shares-field';
import { useTransferBondTradeSharePriceField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/bond/use-trade-share-price-field';
import { useTransferBondTradeSharesField } from '~src/domain/workspace/components/project/transfer/form/fields/holding-types/bond/use-trade-shares-field';

import { Grid } from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

interface Props<T extends FieldValues> {
  projectId: string;
  holding: HoldingState;
  control: Control<T>;
  getName: (name: Path<TransferInputData>) => Path<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: TransferFieldConfig;
  transferTypes?: TransferType[];
}

export const BondTransferFieldsControlledComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { control, setValue, fieldConfig, getName, transferTypes } = props;

  const tradeShares = useWatch({
    control,
    name: getName('data.trade.shares'),
  });

  const transferType = useWatch({
    control,
    name: getName('base.type'),
  }) as TransferType;

  // const transferValue = useWatch({
  //   control,
  //   name: getName('base.value'),
  // });

  const sharePrice = useWatch({
    control,
    name: getName('data.trade.sharePrice'),
  });

  const transferDate = useWatch({
    control,
    name: getName('base.date'),
  });

  const bondHoldingId = useWatch({
    control,
    name: getName('data.position.bondHoldingId'),
  });

  const lastBondPosition: BondPositionState | undefined = useSelector(
    (state: RootState) =>
      bondHoldingId &&
      selectLastPosition(selectWorkspaceData(state), bondHoldingId),
  ) as BondPositionState;

  const positionShares = useWatch({
    control,
    name: getName('data.position.shares'),
  });

  const bondPositionDate = useWatch({
    control,
    name: getName('data.position.date'),
  });

  const newShares = useCalculatePositionShares(
    lastBondPosition?.shares ?? 0,
    tradeShares ?? 0,
    transferType,
  );

  React.useEffect(() => {
    if (newShares !== positionShares) {
      setValue(
        getName('data.position.shares'),
        newShares as PathValue<T, Path<T>>,
      );
    }
  }, [setValue, positionShares, newShares, getName]);

  React.useEffect(() => {
    const value =
      tradeShares != null && sharePrice != null
        ? tradeShares * sharePrice
        : null;
    if (value != null) {
      setValue(getName('base.value'), value as PathValue<T, Path<T>>);
    }
  }, [getName, setValue, sharePrice, tradeShares]);

  // React.useEffect(() => {
  //   let oldShares = 0;
  //   if (lastBondPosition) {
  //     oldShares = lastBondPosition.shares ?? 0;
  //   }
  //   const newShares = tradeShares ? tradeShares + oldShares : oldShares;
  //   if (newShares !== positionShares) {
  //     setValue(getName('data.position.shares'), newShares);
  //   }
  //   const value =
  //     tradeShares != null && sharePrice != null
  //       ? tradeShares * sharePrice
  //       : transferValue;
  //   setValue(getName('base.value'), value);
  // }, [
  //   setValue,
  //   sharePrice,
  //   tradeShares,
  //   transferDataPrefix,
  //   transferValue,
  //   lastBondPosition,
  //   positionShares,
  // ]);

  React.useEffect(() => {
    if (
      transferDate &&
      (bondPositionDate == null ||
        transferDate.getTime() !== bondPositionDate.getTime())
    ) {
      setValue(getName('data.position.date'), transferDate);
    }
  }, [setValue, transferDate, bondPositionDate, getName]);
  const { element: bondTradeSharesElement, visible: bondTradeSharesVisible } =
    useTransferBondTradeSharesField(
      control,
      getName('data.trade.shares'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.data?.bond?.tradeShares,
    );

  const {
    element: bondTradeSharePriceElement,
    visible: bondTradeSharePriceVisible,
  } = useTransferBondTradeSharePriceField(
    control,
    getName('data.trade.sharePrice'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.data?.bond?.sharePrice,
  );
  const { element: bondPositionElement, visible: bondPositionVisible } =
    useTransferBondPositionSharesField(
      control,
      getName('data.position.shares'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.data?.bond?.position,
    );
  const { element: typeElement, visible: typeVisible } = useTransferTypeField(
    control,
    getName('base.type'),
    undefined,
    fieldConfig?.base.type,
    transferTypes,
  );
  const { element: dateElement, visible: dateVisible } = useTransferDateField(
    control,
    getName('base.date'),
    new Date() as PathValue<T, Path<T>>,
    fieldConfig?.base.date,
  );
  const { element: currencyElement, visible: currencyVisible } =
    useTransferCurrencyField(
      control,
      getName('base.currencyId'),
      undefined,
      fieldConfig?.base.currency,
    );
  const { element: valueElement, visible: valueVisible } =
    useTransferValueField(
      control,
      getName('base.value'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.base.value,
    );
  const { element: costElement, visible: costVisible } = useTransferCostField(
    control,
    getName('base.cost'),
    0 as PathValue<T, Path<T>>,
    fieldConfig?.base.cost,
  );
  const { element: statusElement, visible: statusVisible } =
    useTransferStatusField(
      control,
      getName('base.status'),
      undefined,
      fieldConfig?.base.status,
    );

  return (
    <>
      <Grid container columnSpacing={1}>
        {typeVisible && (
          <Grid item sm={4}>
            {typeElement}
          </Grid>
        )}
        {dateVisible && (
          <Grid item sm={4}>
            {dateElement}
          </Grid>
        )}
        {currencyVisible && (
          <Grid item sm={4}>
            {currencyElement}
          </Grid>
        )}

        {bondTradeSharesVisible && (
          <Grid item sm={4}>
            {bondTradeSharesElement}
          </Grid>
        )}
        {bondTradeSharePriceVisible && (
          <Grid item sm={4}>
            {bondTradeSharePriceElement}
          </Grid>
        )}

        {costVisible && (
          <Grid item sm={4}>
            {costElement}
          </Grid>
        )}
        {bondPositionVisible && (
          <Grid item sm={4}>
            {bondPositionElement}
          </Grid>
        )}
        {valueVisible && (
          <Grid item sm={4}>
            {valueElement}
          </Grid>
        )}
        {statusVisible && (
          <Grid item sm={4}>
            {statusElement}
          </Grid>
        )}
      </Grid>
    </>
  );
};
