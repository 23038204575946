import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseRegionAllocationId {
  id: string;
  entityId: string;
}

export type RegionAllocationState = Omit<
  graphqlWorkspaceTypes.RegionAllocation,
  'region' | 'entity'
> & { entityId: string; regionId?: string };

export function regionAllocationToRegionAllocationState(
  regionAllocation: RegionAllocationState,
) {
  return regionAllocation;
}
const initialState: RecordState<RegionAllocationState, string>[] = [];

const selectKeyId = (regionAllocation: BaseRegionAllocationId) =>
  regionAllocation.entityId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseRegionAllocationId, b: BaseRegionAllocationId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseRegionAllocationId,
  RegionAllocationState,
  string
>(initialState, selectKeyId, keyComparator, tComparator);

export const regionAllocationsSlice = createBaseRecordSlice(
  'regionAllocations',
  adapter,
);
export const regionAllocationSelectors = adapter.selectors;

export const regionAllocationsReducer = regionAllocationsSlice.reducer;
export const regionAllocationsActions = regionAllocationsSlice.actions;

export const initialRegionAllocationState = {
  regionAllocations: initialState,
};

export const regionAllocationReducer = combineReducers({
  regionAllocations: regionAllocationsReducer,
});
