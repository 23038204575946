// import { RecordState } from '~src/utils/algorithms/record-algorithms';
import { EntityRecordAdapter } from './record-entity-adapter';
import { createSlice, createReducer } from '../slice';
import { RecordState } from '@pladdenico/common';

export function createBaseRecordSlice<B, T extends B, K>(
  domain: string,
  adapter: EntityRecordAdapter<B, T, K>,
) {
  const slices = {
    upsertOneElement: createSlice(
      domain,
      'upsertOneElement',
      adapter.upsertOneElementReducer(),
    ),
    upsertManyElements: createSlice(
      domain,
      'upsertManyElements',
      adapter.upsertManyElementsReducer(),
    ),
    removeOneElement: createSlice(
      domain,
      'removeOneElement',
      adapter.removeOneElementReducer(),
    ),
    removeManyElements: createSlice(
      domain,
      'removeManyElements',
      adapter.removeManyElementsReducer(),
    ),
    upsertRecordReducer: createSlice(
      domain,
      'upsertRecordReducer',
      adapter.upsertRecordReducer(),
    ),
    clearRecord: createSlice(
      domain,
      'clearRecord',
      adapter.clearRecordReducer(),
    ),
    clearRecords: createSlice(
      domain,
      'clearRecords',
      adapter.clearRecordsReducer(),
    ),
    clear: createSlice<RecordState<T, K>[], undefined>(
      domain,
      'clear',
      adapter.clearReducer(),
    ),
  };

  const actions = {
    clearRecord: slices.clearRecord.action,
    clearRecords: slices.clearRecords.action,
    removeManyElements: slices.removeManyElements.action,
    removeOneElement: slices.removeOneElement.action,
    upsertManyElements: slices.upsertManyElements.action,
    upsertOneElement: slices.upsertOneElement.action,
    upsertRecordReducer: slices.upsertRecordReducer.action,
    clear: slices.clear.action,
  };

  const reducer: (
    state: RecordState<T, K>[] | undefined,
    action: any,
  ) => RecordState<T, K>[] = createReducer(adapter.initialState, slices);

  return {
    actions,
    reducer,
    slices,
  };
}
