import { sortBy } from 'lodash';
import { EvaluatorState } from '~src/data/store/state/workspace/project/scenario/forecast/evaluator-state';
import { ForecastType } from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';

import { ForecastElement } from './forecast-element';
// import { Value } from './value';

export class ManualElement<Value extends { date: moment.Moment }>
  implements ForecastElement<Value>
{
  readonly type: ForecastType;

  constructor(
    private _id: string,
    private _name: string,
    private _values: Value[], // private _correspondHoldingId: string | undefined
  ) {
    this.type = 'manual';
    this._values = sortBy(_values, [(v1) => v1.date.unix()]);
  }

  public getId() {
    return this._id;
  }

  // public getCorrespondHoldingId() {
  //   return this._correspondHoldingId;
  // }

  public getName() {
    return this._name;
  }

  public evaluate(state: EvaluatorState<Value>): Value | undefined {
    console.log('this._values', this._values);
    if (this._values.length === 0) {
      return undefined;
    }
    const idx = this._values.findIndex(
      (v) => v.date.diff(state.date, 'days') === 0,
    );
    if (idx !== -1) {
      return this._values[idx];
    }

    // If daily, but only some values, and take the one it would be right before.
    // let idx = binarySearch(this._values, state.date, (value, date) => {
    //   return value.date.unix() - date.unix();
    // });
    // console.log('IDX', idx);
    // if (idx >= 0) {
    //   return this._values[idx];
    // } else {
    //   idx = Math.max(0, ~idx - 1);
    //   return this._values[idx];
    // }
  }
}
