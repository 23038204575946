import { PortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs';
import { rootReportPath } from '~src/navigation/paths/workspace/project/portfolio-project/report/report/paths';
import { Tab } from '~src/utils/interfaces/tab';

function createTabs(): Tab<PortfolioProjectReportTabs>[] {
  const tabs: Tab<PortfolioProjectReportTabs>[] = [];

  {
    const path = {
      tab: PortfolioProjectReportTabs.Editor,
      exact: true,
      path: `${rootReportPath}/editor`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectReportTabs.Viewer,
      exact: true,
      path: `${rootReportPath}/viewer`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectReportTabs.Sharing,
      exact: true,
      path: `${rootReportPath}/sharing`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectReportTabs.Settings,
      exact: true,
      path: `${rootReportPath}/settings`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }

  return tabs;
}

export function usePortfolioProjectReportTabs() {
  return createTabs();
}
