import moment from 'moment';
import { BudgetState } from '~src/data/store/reducers/workspace/projects/scenario/budgets/reducer';
import { TransactionState } from '~src/data/store/reducers/workspace/projects/scenario/transactions/reducer';
import { TransferState } from '~src/data/store/reducers/workspace/projects/scenario/transfers/reducer';
import { ValuationState } from '~src/data/store/reducers/workspace/projects/scenario/valuations/reducer';

import { TransferType } from '@pladdenico/models';

import { Scenario } from './models/scenario';

export const createTransfersDummy1 = (
  budgetId: string,
  currencyId: string,
  holdingId1: string,
  holdingId2: string,
): TransferState[] => {
  return [
    {
      id: '1.1',
      holdingId: holdingId1,
      date: moment().add(1, 'day'),
      value: 10,
      type: TransferType.Buy,
      budgetId,
      currencyId,
      transactionId: '1',
    },
    {
      id: '1.2',
      holdingId: holdingId2,
      date: moment().add(1, 'day'),
      value: 10,
      type: TransferType.Withdrawal,
      budgetId,
      currencyId,
      transactionId: '1',
    },
    {
      id: '2.1',
      holdingId: holdingId1,
      date: moment().add(5, 'day'),
      value: 11,
      type: TransferType.Sell,
      budgetId,
      currencyId,
      transactionId: '2',
    },
    {
      id: '2.2',
      holdingId: holdingId2,
      date: moment().add(5, 'day'),
      value: 11,
      type: TransferType.Deposit,
      budgetId,
      currencyId,
      transactionId: '2',
    },
    {
      id: '3.1',
      holdingId: holdingId1,
      date: moment().add(12, 'day'),
      value: 11,
      type: TransferType.Buy,
      budgetId,
      currencyId,
      transactionId: '3',
    },
    {
      id: '3.2',
      holdingId: holdingId2,
      date: moment().add(12, 'day'),
      value: 11,
      type: TransferType.Withdrawal,
      budgetId,
      currencyId,
      transactionId: '3',
    },
    {
      id: '4.1',
      holdingId: holdingId1,
      date: moment().add(18, 'day'),
      value: 5,
      type: TransferType.Dividend,
      budgetId,
      currencyId,
      transactionId: '4',
    },
    {
      id: '4.2',
      holdingId: holdingId2,
      date: moment().add(18, 'day'),
      value: 5,
      type: TransferType.Deposit,
      budgetId,
      currencyId,
      transactionId: '4',
    },
    {
      id: '5.1',
      holdingId: holdingId1,
      date: moment().add(23, 'day'),
      value: 70,
      type: TransferType.Sell,
      budgetId,
      currencyId,
      transactionId: '5',
    },
    {
      id: '5.2',
      holdingId: holdingId2,
      date: moment().add(23, 'day'),
      value: 70,
      type: TransferType.Deposit,
      budgetId,
      currencyId,
      transactionId: '5',
    },
  ];
};

export const createTransactionsDummy1 = (
  budgetId: string,
): TransactionState[] => {
  return [
    {
      id: '1',
      description: 'd1',
      title: 't1',
      transferIds: ['1.1', '1.2'],
      budgetId,
    },
    {
      id: '2',
      description: 'd2',
      title: 't2',
      transferIds: ['2.1', '2.2'],
      budgetId,
    },
    {
      id: '3',
      description: 'd3',
      title: 't3',
      transferIds: ['3.1', '3.2'],
      budgetId,
    },
    {
      id: '4',
      description: 'd4',
      title: 't4',
      transferIds: ['4.1', '4.2'],
      budgetId,
    },
    {
      id: '5',
      description: 'd5',
      title: 't5',
      transferIds: ['5.1', '5.2'],
      budgetId,
    },
  ];
};

export const createValuationsDummy1 = (
  budgetId: string,
  currencyId: string,
  holdingId: string,
): ValuationState[] => {
  return [
    {
      id: '1',
      holdingId,
      date: moment().add(1, 'day'),
      value: 30,
      budgetId,
      currencyId,
    },
    {
      id: '2',
      holdingId,
      date: moment().add(5, 'day'),
      value: 42,
      budgetId,
      currencyId,
    },
    {
      id: '3',
      holdingId,
      date: moment().add(12, 'day'),
      value: 34,
      budgetId,
      currencyId,
    },
    {
      id: '4',
      holdingId,
      date: moment().add(18, 'day'),
      value: 55,
      budgetId,
      currencyId,
    },
    {
      id: '5',
      holdingId,
      date: moment().add(23, 'day'),
      value: 5,
      budgetId,
      currencyId,
    },
  ];
};

export const createBudgetDummy1 = (
  id: string,
  name: string,
  scenarioId: string,
  holdingIds: string[],
  transactionIds: string[],
  transferIds: string[],
  valuationIds: string[],
): BudgetState => {
  return {
    holdingIds,
    id,
    name,
    scenarioId,
    transactionIds,
    transferIds,
    valuationIds,
  };
};

export const createScenariosDummy1 = (projectId: string): Scenario => {
  return {
    id: 'dummyId1',
    projectId,
    name: 'dummyName1',
    budgetId: 'dummyIdBudget1',
    variableIds: [],
  };
};

export const createScenariosDummy2 = (projectId: string): Scenario => {
  return {
    id: 'dummyId2',
    projectId,
    name: 'dummyName2',
    budgetId: 'dummyIdBudget2',
    variableIds: [],
  };
};

// export const createBudgetsDummy1_OLD = (
//   holding1: HoldingState,
//   holding2: HoldingState
// ): IBudget => {
//   return {
//     // scenarioId: 'dummyId1',
//     id: 'dummyIdBudget1',
//     name: 'dummyIdBudget1',
//     // cashBudget: {
//     //   period: new Period(moment(), moment().add(1, 'month')),
//     //   beginningCash: 0,
//     //   cashFlows: createCashFlowsDummy1(holdingId),
//     // },
//     holdingIds: [holding1.id, holding2.id],
//     valuations: createValuationsDummy1(holding1.id),
//     transactions: createTransactionsDummy1(),
//     transfers: createTransfersDummy1(holding1.id, holding2.id),
//   };
// };

// export const createBudgetsDummy1 = (
//   holdingIds: string[]
// ): IBudget => {
//   return {
//     // scenarioId: 'dummyId1',
//     id: 'dummyIdBudget1',
//     name: 'dummyIdBudget1',
//     // cashBudget: {
//     //   period: new Period(moment(), moment().add(1, 'month')),
//     //   beginningCash: 0,
//     //   cashFlows: createCashFlowsDummy1(holdingId),
//     // },
//     holdingIds: [holding1.id, holding2.id],
//     valuations: createValuationsDummy1(holding1.id),
//     transactions: createTransactionsDummy1(),
//     transfers: createTransfersDummy1(holding1.id, holding2.id),
//   };
// };
