import moment from 'moment';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import { TransferType } from '@pladdenico/models';

export interface BaseTransferId {
  holdingId: string;
  budgetId: string;
  id: string;
}

export type TransferState = {
  id: string;
  holdingId: string;
  budgetId: string;
  currencyId: string;
  transactionId: string;
  date: moment.Moment;
  type: TransferType;
  value: number;
};

export const initialTransfersState = [];

const selectKeyId = (transfer: BaseTransferId) => transfer.budgetId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseTransferId, b: BaseTransferId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseTransferId, TransferState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const transfersSlice = createBaseRecordSlice(
  'scenario:transfers',
  adapter,
);
export const transfersSelectors = adapter.selectors;

export const transfersReducer = transfersSlice.reducer;
export const transfersActions = transfersSlice.actions;
