import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField, Theme } from '@mui/material';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  stockPositionInput: graphqlWorkspaceTypes.CreateStockPositionInputType;
  setStockPositionInput: (
    stockPositionInput: graphqlWorkspaceTypes.CreateStockPositionInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const CreateStockPositionInfoFieldsComponent = React.memo(
  (props: Props) => {
    const { classes } = useStyles();

    const handleChange =
      (name: keyof graphqlWorkspaceTypes.CreateStockPositionInputType) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setStockPositionInput({
          ...props.stockPositionInput,
          [name]: eventParser(e),
        });
      };

    const handleDateChange = (date: Date) => {
      props.setStockPositionInput({ ...props.stockPositionInput, date });
    };

    return (
      <>
        {/* <TextField
        margin='normal'
        className={classes.textField}
        required
        id='sharePrice'
        value={props.stockPositionInfoInput.sharePrice}
        label='Share price'
        type='number'
        onChange={handleChange('sharePrice')}/> */}
        <TextField
          margin="normal"
          className={classes.textField}
          required
          id="shares"
          value={props.stockPositionInput.shares}
          label="Shares"
          type="number"
          onChange={handleChange('shares')}
        />
        <EditDateField
          handleValue={handleDateChange}
          date={props.stockPositionInput.date}
          labelled={true}
        />
        {/* <TextField
        margin='normal'
        className={classes.textField}
        required
        id='totalShares'
        value={props.stockPositionInfoInput.totalShares}
        label='TotalShares'
        type='number'
        onChange={handleChange('totalShares')}/> */}
      </>
    );
  },
);
