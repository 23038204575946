import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { eventParser } from '~src/utils/form/event-parser';
import { NumberFormatCustom } from './number-format-custom';
import { colorsRowsPrimary } from '~src/utils/common/colors/table-colors';

interface Props<T extends string | number> {
  data: T[][];
  title?: React.ReactElement;
  columnHeaders?: React.ReactElement[];
  rowHeaders?: React.ReactElement[];
  handleChange: (row: number, col: number, value: T) => void;
}

export const EditableMatrix = <T extends string | number>(props: Props<T>) => {
  const { title, columnHeaders, rowHeaders, data, handleChange } = props;

  const tableColumnHead = React.useMemo(() => {
    const titleElement = <TableCell>{title}</TableCell>;
    const columnHeadersElement = columnHeaders
      ? Array.from({ length: columnHeaders.length }, (_, index) => (
          <TableCell key={`${index}`}>
            <Typography variant="h6" textAlign="right">
              {columnHeaders[index]}
            </Typography>
          </TableCell>
        ))
      : undefined;

    if (title != null || columnHeaders != null) {
      return (
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: colorsRowsPrimary.medium,
            }}
          >
            {titleElement}
            {columnHeadersElement}
          </TableRow>
        </TableHead>
      );
    }
    return undefined;
  }, [columnHeaders, title]);

  return (
    <TableContainer>
      <Table aria-label="editable matrix" size="small">
        {tableColumnHead}
        <TableBody>
          {data.map((row: any, rowIndex: number) => (
            <TableRow
              key={`row-${rowIndex}`}
              sx={{
                backgroundColor:
                  rowIndex % 2 == 0
                    ? colorsRowsPrimary.light
                    : colorsRowsPrimary.veryLight,
              }}
            >
              {rowHeaders ? (
                <TableCell padding="none" sx={{ borderBottom: 'none' }}>
                  <Typography variant="h6" textAlign="right">
                    {rowHeaders[rowIndex]}
                  </Typography>
                </TableCell>
              ) : (
                <TableCell
                  padding="none"
                  sx={{ borderBottom: 'none' }}
                ></TableCell>
              )}
              {row.map((value: T, colIndex: number) => (
                <TableCell
                  key={`cell-${rowIndex}-${colIndex}`}
                  padding="none"
                  sx={{ borderBottom: 'none' }}
                >
                  <TextField
                    value={value}
                    // type="number"
                    onChange={(e) => {
                      const value = eventParser(e) as T;
                      handleChange(rowIndex, colIndex, value);
                    }}
                    // variant="outlined"
                    // size="small"
                    // margin="none"
                    InputProps={{ inputComponent: NumberFormatCustom }}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                        // appearance: 'none',
                        // WebkitAppearance: 'none',
                        margin: 0,
                        paddingTop: 1,
                        paddingBottom: 1,
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent', // Hide border by default
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0.23)', // Show border on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main', // Show border when focused
                          borderWidth: '2px', // Make border thicker when focused
                        },
                        // 'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                        //   {
                        //     WebkitAppearance: 'none',
                        //     margin: 0,
                        //   },
                        // 'input[type=number]': {
                        //   MozAppearance: 'textfield',
                        // },
                      },
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
