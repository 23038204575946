import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceProjectsRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/projects`;
};

export const workspaceProjectRootPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectsRootPath(tenantId, workspaceId)}/${projectId}`;
};

export const workspaceProjectsBasePath = workspaceProjectsRootPath(
  tenantBaseId,
  workspaceBaseId,
);
