import * as mathjs from 'mathjs';

function covarianceBase(data1: number[], data2: number[]): number {
  if (data1.length !== data2.length) {
    throw({error: 'covarianceBase: data1 and data2 of different length'});
  }
  let meanx = 0;
  let meany = 0;
  let c = 0;
  let n = 0;
  for (let i = 0; i < data1.length; ++i) {
    // x, y in zip(data1, data2):
    const x = data1[i];
    const y = data2[i];
    n += 1;
    const dx = x - meanx;
    meanx += dx / n;
    meany += (y - meany) / n;
    c += dx * (y - meany);
  }
  return c;
}

export function populationCovariance(data1: number[], data2: number[]) {
  return covarianceBase(data1, data2) / data1.length;
}

export function sampleCovariance(data1: number[], data2: number[]) {
  // Bessel's correction for sample variance
  return covarianceBase(data1, data2) / (data1.length - 1);
}

export function SMALast(values: number[], times: number[], tau: number): number[] {
  if (values.length === 0) {
    return [];
  }
  const out = new Array(values.length);
  let left = 0;
  let rollArea = values[0] * tau;
  let leftArea = rollArea;
  out[0] = values[0];
  for (let right = 1; right < values.length; ++right) {
    // Expand interval on right end
    rollArea = rollArea + values[right] * (times[right] - times[right - 1]);
    // Remove truncated area on left end
    rollArea = rollArea - leftArea;
    // Shrink interval on left end
    const tLeftNew = times[right] - tau;
    while (times[left] <= tLeftNew) {
      rollArea = rollArea - values[left + 1] * (times[left + 1] - times[left]);
      left = left + 1;
    }
    // Add truncated area on left end
    leftArea = values[Math.max(0, left)] * (times[left] - tLeftNew);
    rollArea = rollArea + leftArea;
    // Save SMA value for current time window
    out[right] = rollArea / tau;
  }
  return out;
}

export function rollingVariance(values: number[], times: number[], tau): number[] {
  const mXTau1 = SMALast(values, times, tau) as number[];
  const mxTau1Squared = mathjs.square(mXTau1) as number[];
  const valuesSquared = mathjs.square(values) as number[];
  const mXTau2 = SMALast(valuesSquared, times, tau) as number[];
  return mathjs.subtract(mXTau2, mxTau1Squared) as number[];
}
