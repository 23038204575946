import { combineReducers } from 'redux';

import {
  initialBankNoteHoldingState,
  bankNoteHoldingsReducer,
} from './bank-note-holding/reducer';

export const bankNoteHoldingInitialState = {
  bankNoteHoldings: initialBankNoteHoldingState,
  // bankNoteHoldingsFetch: initialBankNoteHoldingsFetchReducer,
  // bankNotePosition: bankNotePositionInitialState,
};

export const bankNoteHoldingReducer = combineReducers({
  bankNoteHoldings: bankNoteHoldingsReducer,
  // bankNoteHoldingsFetch: bankNoteHoldingsFetchReducer,
  // bankNotePosition: bankNotePositionReducer,
});
