import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { SelectCommitment } from '~src/domain/workspace/components/project/transfer/form/fields/base/select-commitment';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

// import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

export const useTransferCommitmentField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  workspace: WorkspaceState,
  projectId: string,
  holdingId: string,
  fieldConfig?: FieldConfig,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<
        TFieldValues,
        TName // 'data.stockTrade.shares'
      >,
      _fieldState: ControllerFieldState,
      _disabled?: boolean,
    ) => (
      <SelectCommitment
        handleValue={(commitment) => field.onChange(commitment?.id ?? null)}
        commitmentId={field.value ?? null}
        holdingId={holdingId}
        projectId={projectId}
        workspace={workspace}
        label="Commitment"

        // error={fieldState.error}
      />
    ),
    [holdingId, projectId, workspace],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName}
        // rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
