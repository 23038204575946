import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TabPanel } from '~src/domain/workspace/components/project/portfolio/holdings/tab/tab-panel.component';
import { ProjectReportTabComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/report-tab.component';
import { PortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs';
import { projectReportTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/report/project-report/paths';
import { primaryBackgroundColor } from '~src/utils/common/colors/base-colors';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  projectReport: ProjectReportState;
  tabs: Tab<PortfolioProjectReportTabs>[];
  tab: Tab<PortfolioProjectReportTabs>;
}

export const ProjectReportTabsComponent = React.memo((props: Props) => {
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newTab: PortfolioProjectReportTabs,
  ) {
    navigate(
      projectReportTabPath(
        props.workspace.tenantId,
        props.workspace.id,
        props.portfolioProject.projectId,
        props.projectReport.id,
        newTab,
      ),
    );
  }

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        // if (tab.name === props.tab.name) {
        return (
          <ProjectReportTabComponent
            key={index}
            portfolio={props.portfolio}
            portfolioProject={props.portfolioProject}
            tabName={tab.name}
            workspace={props.workspace}
            project={props.project}
          />
        );
        // }
        // return <></>;
      }),
    [
      props.portfolio,
      props.portfolioProject,
      props.project,
      props.tabs,
      props.workspace,
    ],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel value={props.tab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: primaryBackgroundColor.veryLight,
        }}
      >
        <Box pl={2}>
          <Typography variant="h6">{props.projectReport.name}</Typography>
        </Box>
        <Tabs
          value={props.tab.name}
          indicatorColor="primary"
          textColor="primary"
          // centered
          variant="scrollable"
          onChange={handleChange}
          aria-label="portfolio project tabs"
          sx={{ ml: 1, zIndex: (theme) => theme.zIndex.appBar }}
        >
          {tabElements}
        </Tabs>
      </Box>
      {tabPanelElements}
    </>
  );
});
