import { combineReducers } from 'redux';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';

import { strcmp } from '@pladdenico/common';

export interface BasePaperIdx {
  exchange?: string | null;
  symbol?: string | null;
}

export interface BasePaperId extends BasePaperIdx {
  id: string;
  exchange?: string | null;
  symbol?: string | null;
}

export type PaperState = Omit<
  graphqlFinanceTypes.Paper,
  'quotes' | 'currency'
> & {
  currencyId: string;
};

export const initialState = { ts: [], idxs: [] };

const selectId = (paper: BasePaperId) => paper.id;
export const selectIdx = (paper: BasePaperIdx) =>
  `${paper.exchange}.${paper.symbol}`;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: PaperState, b: PaperState) => b;

const adapter = new EntityIndexedAdapter<
  BasePaperId,
  PaperState,
  string,
  string
>(initialState, selectId, selectIdx, merger, iComparator, idxComparator);

export const papersSlice = createBaseIndexedSlice('papers', adapter);
export const papersSelectors = adapter.selectors;
export const papersReducer = papersSlice.reducer;
export const papersActions = papersSlice.actions;

export const initialPaperState = {
  papers: initialState,
};

export const paperReducer = combineReducers({
  papers: papersReducer,
});
