import React from 'react';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TabPanel } from '~src/domain/workspace/components/project/portfolio/holdings/tab/tab-panel.component';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { PortfolioProjectScenarioTabs } from '~src/domain/workspace/components/project/scenario/tabs/tabs';
// import history from '~src/navigation/history';
import { scenarioTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/scenario/paths';
import { Tab } from '~src/utils/interfaces/tab';

import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ScenarioTabComponent } from '~src/domain/workspace/components/project/scenario/tabs/scenario-tab.component';
import { useNavigate } from 'react-router-dom';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  scenario: Scenario;
  tabs: Tab<PortfolioProjectScenarioTabs>[];
  tab: Tab<PortfolioProjectScenarioTabs>;
}

// interface PropsTab {
//   workspace: WorkspaceState;
//   project: ProjectState;
//   portfolioProject: PortfolioProjectState;
//   portfolio: PortfolioState;
//   tabName: string;
// }

// const SubComponent = React.memo((props: PropsTab) => {
//   if (props.tabName === PortfolioProjectScenarioTabs.Dashboard) {
//     return (
//       <ProjectScenarioComponent
//         workspace={props.workspace}
//         project={props.project}
//         portfolioProject={props.portfolioProject}
//         portfolio={props.portfolio}
//       />
//     );
//     // } else if (props.tabName === PortfolioProjectScenarioTabs.Settings) {
//     //   return (
//     //     <SettingsComponent
//     //       workspace={props.workspace}
//     //       projectId={props.project.id}
//     //       portfolio={props.portfolio}
//     //     />
//     //   );
//   } else if (props.tabName === PortfolioProjectScenarioTabs.Holdings) {
//     return (
//       <ProjectScenarioComponent
//         workspace={props.workspace}
//         project={props.project}
//         portfolioProject={props.portfolioProject}
//         portfolio={props.portfolio}
//       />
//     );
//   }
//   return <div>unknown</div>;
// });

export const ScenarioTabsComponent = React.memo((props: Props) => {
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newTab: PortfolioProjectScenarioTabs,
  ) {
    navigate(
      scenarioTabPath(
        props.workspace.tenantId,
        props.workspace.id,
        props.portfolioProject.projectId,
        props.scenario.id,
        newTab,
      ),
    );
  }

  const tabElements = props.tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      props.tabs.map((tab, index) => {
        return (
          <ScenarioTabComponent
            key={index}
            portfolio={props.portfolio}
            portfolioProject={props.portfolioProject}
            tabName={tab.name}
            workspace={props.workspace}
            project={props.project}
          />
        );
      }),
    [
      props.portfolio,
      props.portfolioProject,
      props.project,
      props.tabs,
      props.workspace,
    ],
  );

  const tabPanelElements = props.tabs.map((tab, index) => {
    return (
      <TabPanel value={props.tab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });

  return (
    <>
      <Tabs
        value={props.tab.name}
        indicatorColor="primary"
        textColor="primary"
        // centered
        variant="scrollable"
        onChange={handleChange}
        aria-label="portfolio project tabs"
        sx={{ ml: 1, zIndex: (theme) => theme.zIndex.appBar }}
      >
        {tabElements}
      </Tabs>
      {tabPanelElements}
    </>
  );
});
