import moment from 'moment';
import React from 'react';
import * as uuid from 'uuid';
import {
  ExpressionValuationStock,
  ExpressionVariables,
} from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { ForecastElementInput } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/forecast-element-input';
import {
  ForecastType,
  ForecastTypes,
  typeToString,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/forecast-type';
import { ValuationBase } from '~src/domain/workspace/components/project/scenario/models/valuation';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TransferType } from '@pladdenico/models';
import { ForecastExpressionElementInput } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/expression-element-input';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';

const initializeForecastElement = (
  forecast: ForecastState,
  type: ForecastType,
): ForecastElementInput | undefined => {
  const date = moment();
  if (type === ForecastTypes.Expression) {
    const initialValuation: ExpressionValuationStock = {
      type: 'stock',
      value: 1000 * 500,
      stock: {
        position: 1000,
        price: 500,
      },
    };
    const variables: ExpressionVariables = {
      initialValuation,
      transfer: {
        value: 100,
        type: TransferType.Sell,
      },
    };

    const expressionElement: ForecastExpressionElementInput = {
      // forecastId: forecast.id,
      type: ForecastTypes.Expression,
      expressions: [],
      // name: 'f1',
      // description: '',
      variables,
    };
    return expressionElement;
  } else if (type === ForecastTypes.Manual) {
    const valuation: ValuationBase = {
      id: 'id1_v',
      value: 20000,
    };

    const manualElement: ManualElementState = {
      // id: 'id1',
      // forecastId: forecast.id,
      type: ForecastTypes.Manual,
      // name: 'f1',
      // description: '',
      values: [
        {
          unixDate: moment(date).add(1, 'day').unix(),
          transfer: { id: uuid.v1(), type: TransferType.Withdrawal, value: 11 },
          valuation,
        },
        {
          unixDate: moment(date).add(3, 'day').unix(),
          transfer: { id: uuid.v1(), type: TransferType.Withdrawal, value: 13 },
          valuation,
        },
        {
          unixDate: moment(date).add(5, 'day').unix(),
          transfer: { id: uuid.v1(), type: TransferType.Withdrawal, value: 15 },
          valuation,
        },
        {
          unixDate: moment(date).add(10, 'day').unix(),
          transfer: { id: uuid.v1(), type: TransferType.Withdrawal, value: 17 },
          valuation,
        },
      ],
    };
    return manualElement;
  } else if (type === ForecastTypes.Flow) {
    const flowElement: FlowElementState = {
      // id: 'id1',
      // forecastId: forecast.id,
      type: ForecastTypes.Flow,
      // name: 'f-flow',
      // description: '',
      flow: null,
    };
    return flowElement;
  }
  return undefined;
};

export const useHoldingForecastElementType = (
  forecast: ForecastState,
  // element: ForecastElementInput | undefined,
  setForecast: (forecast: ForecastState) => void,
) => {
  const [type, setType] = React.useState<ForecastType>(forecast.element.type);

  const handleForecastTypeChange = (event: SelectChangeEvent<ForecastType>) => {
    const type = event.target.value as ForecastType;
    setType(type);
  };

  React.useEffect(() => {
    if (forecast.element.type !== type) {
      const element = initializeForecastElement(forecast, type);
      if (element) {
        setForecast({
          ...forecast,
          element,
        });
        // setElement(element);
      }
    }
  }, [forecast, setForecast, type]);

  const menuItems = Object.values(ForecastTypes).map((type) => {
    return (
      <MenuItem key={type} value={type}>
        {typeToString(type)}
      </MenuItem>
    );
  });

  const elementType = (
    <FormControl fullWidth>
      <InputLabel id="forecast-type-label">Forecast type</InputLabel>
      <Select
        labelId="forecast-type-label"
        value={type}
        label="Forecast element type"
        onChange={handleForecastTypeChange}
      >
        {menuItems}
      </Select>
    </FormControl>
  );

  return {
    element: elementType,
    type,
  };
};
