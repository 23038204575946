import {
  WorkspaceOutput,
  handleWorkspace,
} from '~src/data/store/modules/workspaces/workspaces/handler';
import { workspacesActions } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function fetchAndHandleWorkspace(
  tenantId: string,
  workspaceId: string,
  handleWorkspace: (
    dispatch: AppDispatch,
    tenantId: string,
    output: graphqlWorkspaceTypes.Workspace,
    subscriptions: Operation[],
  ) => graphqlWorkspaceTypes.Workspace,
) {
  return (dispatch: AppDispatch) => {
    return fetchWorkspace(tenantId, workspaceId, {
      withAssets: true,
      withEntities: true,
      withPeople: true,
      withTransfers: true,
    })
      .then((workspace) => {
        if (workspace) {
          return handleWorkspace(dispatch, tenantId, workspace, [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ]);
        }
      })
      .catch((err) =>
        console.error(`Error fetchWorkspace: ', ${JSON.stringify(err)}`),
      );
  };
}

interface WithWorkspaceConfig {
  withTransfers: boolean;
  withAssets: boolean;
  withEntities: boolean;
  withPeople: boolean;
}

export function fetchWorkspace(
  tenantId: string,
  workspaceId: string,
  withWorkspaceConfig: WithWorkspaceConfig,
) {
  const doc = graphqlWorkspaceTypes.GetWorkspaceDocument;
  const context: QueryWorkspaceContext = {
    type: QueryContextTypes.workspace,
    tenantId,
    workspaceId,
  };
  const variables: graphqlWorkspaceTypes.GetWorkspaceQueryVariables = {
    ...withWorkspaceConfig,
  };
  return investRequest(doc, variables, context)
    .then((data) => {
      return data.getWorkspace;
    })
    .catch((err) => {
      console.error(`Error fetchWorkspace: ', ${JSON.stringify(err)}`);
      return undefined;
    });
}

export function fetchWorkspaceShallowNEW(
  tenantId: string,
  workspaceId: string,
): Promise<WorkspaceOutput | null | undefined> {
  const node = graphqlWorkspaceTypes.GetWorkspaceShallowDocument;
  const variables: graphqlWorkspaceTypes.GetWorkspaceShallowQueryVariables = {};
  const context: QueryWorkspaceContext = {
    type: QueryContextTypes.workspace,
    tenantId,
    workspaceId,
  };
  return investRequest(node, variables, context)
    .then((data) => {
      return data.getWorkspace;
    })
    .catch((err) => {
      console.error(`Error fetchWorkspace: ', ${JSON.stringify(err)}`);
      return undefined;
    });
}

export function fetchWorkspaceShallow(tenantId: string, workspaceId: string) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetWorkspaceShallowDocument;
    const variables: graphqlWorkspaceTypes.GetWorkspaceShallowQueryVariables =
      {};
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getWorkspace) {
        return handleWorkspace(dispatch, tenantId, data.getWorkspace, [
          Operation.create,
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return undefined;
    });
  };
}

export function createWorkspace(
  tenantId: string,
  workspaceId: string,
  input: graphqlWorkspaceTypes.CreateWorkspaceInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateWorkspaceDocument;
    const variables: graphqlWorkspaceTypes.CreateWorkspaceMutationVariables = {
      input,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createWorkspace) {
        return handleWorkspace(dispatch, tenantId, data.createWorkspace, [
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return undefined;
    });
  };
}

export function updateWorkspace(
  tenantId: string,
  workspaceId: string,
  input: graphqlWorkspaceTypes.UpdateWorkspaceInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateWorkspaceDocument;
    const variables: graphqlWorkspaceTypes.UpdateWorkspaceMutationVariables = {
      input,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateWorkspace) {
        return handleWorkspace(dispatch, tenantId, data.updateWorkspace, [
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function applyWorkspaceSnapshot(
  tenantId: string,
  workspaceId: string,
  input: graphqlWorkspaceTypes.WorkspaceSnapshotInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateWorkspaceDocument;
    const variables: graphqlWorkspaceTypes.ApplyWorkspaceSnapshotMutationVariables =
      {
        input,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateWorkspace) {
        return handleWorkspace(dispatch, tenantId, data.updateWorkspace, [
          Operation.delete,
          Operation.update,
          Operation.upsert,
        ]);
      }
      return [];
    });
  };
}

export function deleteWorkspace(tenantId: string, workspaceId: string) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteWorkspaceDocument;
    const variables: graphqlWorkspaceTypes.DeleteWorkspaceMutationVariables =
      {};
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteWorkspace) {
        dispatch(
          workspacesActions.removeOneElement({
            tenantId,
            id: data.deleteWorkspace,
          }),
        );
      }
      return [];
    });
  };
}
