import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { HoldingDashboardConfig } from '~src/services/graphql/workspace/client/graphql';

export function parseHoldingDashboardConfig(
  holdingConfigId: number,
  config: HoldingDashboardConfig,
): HoldingDashboardConfigState {
  return {
    id: config.id,
    holdingConfigId,
    elementIds: config.elements ? config.elements.map((el) => el.id) : [],
    name: config.name,
    compactType: config.compactType as CompactType,
    layouts: config.layouts
      ? (JSON.parse(config.layouts) as ReactGridLayout.Layouts)
      : undefined,
  };
}
