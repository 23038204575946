import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { Config } from '~src/utils/interfaces/config';

import { Box, Typography } from '@mui/material';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';

interface Props {
  workspace: WorkspaceState;
  holding: HoldingState;
  config: Config;
  dashboardConfig: HoldingDashboardConfigState;
}

export const DashboardHeaderComponent = React.memo((props: Props) => {
  const { config } = props;
  const period = config.period;
  // console.log('DASHBOARDHEADER render');
  return (
    <Box p={1}>
      <Typography variant="h5">
        Config - {props.dashboardConfig.name}
      </Typography>
      <Typography variant="subtitle1">
        <div>Valuation date {config.date.format('DD.MM.YY')}</div>
        <div>
          Period {period.start.format('DD.MM.YY')}-
          {period.end.format('DD.MM.YY')}
        </div>
      </Typography>
    </Box>
  );
});
