import { handlePhones } from '~src/data/store/modules/people/phones/handlers';
import {
  BasePhoneId,
  phonesActions,
} from '~src/data/store/reducers/person/phones/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchPhones(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetPhonesDocument;
    const variables: graphqlWorkspaceTypes.GetPhonesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPhones) {
        return handlePhones(dispatch, tenantId, workspaceId, data.getPhones);
      }
      return undefined;
    });
  };
}

export function createPhones(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreatePhoneInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreatePhonesDocument;
    const variables: graphqlWorkspaceTypes.CreatePhonesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPhones) {
        return handlePhones(dispatch, tenantId, workspaceId, data.createPhones);
      }
      throw new Error('Could not create phone');
      // return undefined;
    });
  };
}

export function updatePhones(
  tenantId: string,
  workspaceId: string,
  personId: string,
  inputs: graphqlWorkspaceTypes.UpdatePhoneInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdatePhonesDocument;
    const variables: graphqlWorkspaceTypes.UpdatePhonesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePhones) {
        return handlePhones(dispatch, tenantId, workspaceId, data.updatePhones);
      }
      return [];
    });
  };
}

export function upsertPhones(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertPhoneInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertPhonesDocument;
    const variables: graphqlWorkspaceTypes.UpsertPhonesMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPhones) {
        return handlePhones(dispatch, tenantId, workspaceId, data.upsertPhones);
      }
      return [];
    });
  };
}

export function deletePhones(
  tenantId: string,
  workspaceId: string,
  ids: BasePhoneId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeletePhonesDocument;
    const variables: graphqlWorkspaceTypes.DeletePhonesMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePhones) {
        const deletedIds = data.deletePhones;
        dispatch(
          phonesActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
