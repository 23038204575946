import React from 'react';
import { SelectItem } from '~src/components/fields/common/use-select';
import { IdComponent } from '~src/components/utils/id.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useHandler } from '~src/hooks/utils/use-handler';
import {
  EntitySectors,
  entitySectorToString,
} from '~src/utils/interfaces/entity-sectors';

import { Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspace: WorkspaceState;
  entityInput: graphqlWorkspaceTypes.CreateEntityInputType;
  setEntityInput: (
    entityInput: graphqlWorkspaceTypes.CreateEntityInputType,
  ) => void;
  showId?: boolean;
}
const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  formControl: {
    minWidth: 100,
  },
}));

export const FieldsComponent = React.memo((props: Props) => {
  const { entityInput, setEntityInput, showId } = props;
  const { classes } = useStyles();

  const { handleUpdate } = useHandler(entityInput, setEntityInput);

  const handleChange =
    (name: keyof graphqlWorkspaceTypes.CreateEntityInputType) =>
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setEntityInput({
        ...entityInput,
        [name]: event.target.value,
      });
    };

  const setId = (id: string) => {
    setEntityInput({ ...entityInput, id });
  };

  const items = Object.values(EntitySectors).map((sector) => {
    return { name: entitySectorToString(sector), value: sector, t: sector };
  });
  const sectorElement = (
    <SelectItem
      items={items}
      handleT={handleUpdate('sector')}
      t={entityInput.sector}
      label="sector"
    />
  );

  const idElement = showId ? (
    <Grid item sx={{ ml: 0, mr: 1 }}>
      <IdComponent id={entityInput.id} setId={setId} />
    </Grid>
  ) : undefined;

  return (
    <>
      {idElement}
      <Grid item sx={{ ml: 0, mr: 1 }}>
        <TextField
          autoFocus
          margin="normal"
          className={classes.textField}
          required
          id="name"
          value={entityInput.name}
          label="Name"
          type="text"
          onChange={handleChange('name')}
        />
      </Grid>
      <Grid item sx={{ ml: 0, mr: 1 }}>
        <TextField
          margin="normal"
          className={classes.textField}
          required
          id="description"
          value={entityInput.description}
          label="Description"
          type="text"
          onChange={handleChange('description')}
        />
      </Grid>
      <Grid item sx={{ ml: 0, mr: 1 }}>
        <TextField
          margin="normal"
          className={classes.textField}
          required
          id="website"
          value={entityInput.website}
          label="Website"
          type="text"
          onChange={handleChange('website')}
        />
      </Grid>
      <Grid item sx={{ ml: 0, mr: 1 }}>
        {sectorElement}
      </Grid>
      {/* <Grid item sx={{ ml: 0, mr: 1 }}>
        <EntityMediaDropzoneComponent
          workspace={workspace}
          entityId={entityInput.id}
        />
      </Grid> */}
    </>
  );
});
