import { FavoriteHoldingState } from '~src/data/store/reducers/holding/favorite-holding/reducer';
import { FavoriteHolding } from '~src/services/graphql/workspace/client/graphql';

export function parseFavoriteHolding(
  projectId: string,
  favoriteHolding: FavoriteHolding,
): FavoriteHoldingState {
  return {
    id: favoriteHolding.id,
    holdingId: favoriteHolding.holding?.id ?? '',
    projectId,
    // projectId: favoriteHolding.project?.id ?? '',
  };
}
