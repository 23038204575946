import {
  CryptoHoldingStateVisitor,
  CryptoHoldingVisitable,
} from '~src/data/store/visitors/holding/types/crypto/crypto-holding-visitor';
import { CryptoHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleCryptoHoldings(dispatch: any, outputs: CryptoHolding[]) {
  const cryptoHoldingVisitor = new CryptoHoldingStateVisitor(dispatch);

  outputs.forEach((cryptoHolding) => {
    const cryptoHoldingVisitable = new CryptoHoldingVisitable(cryptoHolding);
    cryptoHoldingVisitable.accept(cryptoHoldingVisitor);
  });
  cryptoHoldingVisitor.post();
  return outputs;
}
