import moment from 'moment';
import { Period } from '~src/utils/period/period';
import { Config } from '~src/utils/interfaces/config';
import { PivotOption } from '~src/utils/interfaces/pivot-option';
import { configToConfigState } from './config-normalizer';
import { UsageMode } from '~src/utils/interfaces/usage-mode';

export const createInitializeConfig = (): Config => {
  const date = moment();

  const start = moment().endOf('year').add(-1, 'year');
  const end = moment();
  const period = new Period(start, end);

  // const currencyOptions = currencyTypes.map((sector: string) => {
  //   return { key: sector, value: sector, text: sector };
  // });

  const pivotOptions: PivotOption[] = [
    // { key: 'assetClass', text: 'Asset class', value: 'assetClass' },
    { key: 'assetClass', text: 'AssetClass', value: 'assetClass' },
    { key: 'category', text: 'Category', value: 'category' },
    { key: 'sector', text: 'Sector', value: 'sector' },
    { key: 'region', text: 'Region', value: 'region' },
    { key: 'currency', text: 'Currency', value: 'currency' },
    { key: 'id', text: 'Id', value: 'id' },
  ];

  return {
    date,
    // currencyOptions,
    indexPaper: {
      id: '',
      symbol: 'URTH',
      exchange: '',
      currencyId: '__CURRENCY__NOK',
    },
    period,
    minimumValuationForHolding: 0.0001,
    minDate: moment(new Date(2017, 12, 31)),
    pivotOptions,
    useLiveValue: true,
    baseCurrencyId: '__CURRENCY__NOK',
    usageMode: UsageMode.edit,
    compactType: 'vertical',
  };
};

const initialConfig = createInitializeConfig();

export const initialDefaultConfig = () => {
  return configToConfigState('default', {
    ...initialConfig,
  });
};

export const initialViewConfig = () => {
  return configToConfigState('view', {
    ...initialConfig,
    usageMode: UsageMode.view,
  });
};

export const initialEditConfig = () => {
  return configToConfigState('edit', {
    ...initialConfig,
    usageMode: UsageMode.edit,
  });
};
