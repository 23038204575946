import moment from 'moment';
import React from 'react';
import { NodeTypes } from 'reactflow';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { FlowEvaluator } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-evaluator';
import { defaultInitialNodes } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/initialization/holding/default-initial-nodes';
import { initialInterestRateFlow } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/initialization/holding/interest/interest-rate';
import { initialStockFlow } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/initialization/holding/stock/stock';
import { InitialNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/initial-node';
import { InputNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/input-node';
import { OutputNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/output-node';
import { StockPriceNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/stock-price-node';
import { TransferNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/transfer-node';
import { ValuationNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/valuation-node';
import { VariableNode } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/nodes/variable-node';
import { useFlowDrag } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/use-flow-drag';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Value } from '~src/domain/workspace/components/project/scenario/models/forecast/value';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';

import { Box, colors, Typography } from '@mui/material';
import { HoldingType } from '@pladdenico/models';
import { useVariables } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-variables';

export const useHoldingForecastFlowElementComponent = (
  _workspace: WorkspaceState,
  _project: ProjectState,
  holding: HoldingState,
  scenario: Scenario,
  budget: Budget,
  forecast: ForecastState,
  forecastFlowElement: FlowElementState,
) => {
  const initialFlow = React.useMemo(() => {
    if (holding.type === HoldingType.BankNote) {
      return initialInterestRateFlow;
    } else if (holding.type === HoldingType.Stock) {
      return initialStockFlow;
    } else {
      return { nodes: defaultInitialNodes, edges: [] };
    }
  }, [holding.type]);

  const [evaluationState, setEvaluationState] = React.useState(
    () => new Array<Value>(),
  );

  const period = React.useMemo(() => {
    const startDate = moment();
    const endDate = moment(startDate).add(1, 'year');
    const numberOfDaysInPeriod = endDate.diff(startDate, 'days') + 1;
    return {
      startDate,
      endDate,
      numberOfDaysInPeriod,
    };
  }, []);

  const { evaluate: evaluateVariables, getVariableValue } = useVariables(
    scenario,
    budget,
    period.numberOfDaysInPeriod,
  );

  // const variables = useSelector((state: RootState) => {
  //   return getVariablesByScenarioId(selectWorkspaceData(state), scenario.id);
  // });

  // const variableForecastIds = React.useMemo((): BaseSelectedForecastId[] => {
  //   return (
  //     variables?.map((v): BaseSelectedForecastId => {
  //       return {
  //         objectId: v.id,
  //         objectType: ForecastObjectType.variable,
  //         budgetId: budget.id,
  //       };
  //     }) ?? []
  //   );
  // }, [budget.id, variables]);

  // const selectedVariableForecasts = useSelector((state: RootState) => {
  //   return getSelectedForecastsByIds(
  //     selectWorkspaceData(state),
  //     variableForecastIds
  //   );
  // });

  // const variableForecasts = useSelector((state: RootState) => {
  //   if (budget && selectedVariableForecasts) {
  //     return getForecastsByIds(
  //       selectWorkspaceData(state),
  //       selectedVariableForecasts
  //     );
  //   }
  //   return [];
  // });

  // const variableForecastElements = React.useMemo(() => {
  //   return compact(
  //     variableForecasts.map((forecast) => {
  //       const element = getForecastElement(forecast, undefined);
  //       if (element) {
  //         return { element, variableId: forecast.objectId };
  //       }
  //     })
  //   );
  // }, [variableForecasts]);

  // const variableForecastEvaluators = React.useMemo(() => {
  //   return variableForecastElements.map((forecastElement) => {
  //     const evaluator = new ForecastEvaluator(
  //       forecastElement.element,
  //       period.numberOfDaysInPeriod
  //     );
  //     return { evaluator, variableId: forecastElement.variableId };
  //   });
  // }, [period.numberOfDaysInPeriod, variableForecastElements]);

  // const getVariableValue = React.useCallback(
  //   (variable: VariableState, idx: number) => {
  //     const evaluator = variableForecastEvaluators.find((evaluator) => {
  //       return evaluator.variableId === variable.id;
  //     });
  //     if (evaluator) {
  //       return evaluator.evaluator.values()[idx];
  //     }
  //   },
  //   [variableForecastEvaluators]
  // );

  const nodeTypes: NodeTypes = React.useMemo(() => {
    return {
      initial: InitialNode,
      output: OutputNode,
      inputType: InputNode,
      valuation: ValuationNode,
      transfer: TransferNode,
      stockPrice: StockPriceNode,
      variable: VariableNode,
    };
  }, []);

  const evaluationStateElement = React.useMemo(() => {
    if (evaluationState.length > 0) {
      const elements = [];
      for (let idx = 0; idx < evaluationState.length; ++idx) {
        const element = (
          <Box key={idx}>
            <Typography variant="body2">
              {evaluationState[idx].date.format('DD/MM/YYYY')}{' '}
              {evaluationState[idx].valuation.value} Transfer:{' '}
              {evaluationState[idx].transfer?.type} -
              {evaluationState[idx].transfer?.value}
            </Typography>
          </Box>
        );
        elements.push(element);
      }
      return elements;
    } else {
      return <div>No elements</div>;
    }
  }, [evaluationState]);

  const { edges, flowComponent, nodes } = useFlowDrag(
    scenario,
    budget,
    forecast,
    forecastFlowElement,
    nodeTypes,
    initialFlow,
  );

  const evaluate = React.useCallback(() => {
    try {
      const flowEvaluator = new FlowEvaluator(edges, nodes);
      flowEvaluator.prepare();
      const values = new Array<Value>(period.numberOfDaysInPeriod);
      for (let i = 0; i < period.numberOfDaysInPeriod; ++i) {
        const date = moment(period.startDate).add(i, 'day');

        evaluateVariables(date, i);
        // for (let j = 0; j < variableForecastEvaluators.length; ++j) {
        //   variableForecastEvaluators[j].evaluator.evaluate(date, i);
        // }

        const value = flowEvaluator.evaluate(
          values,
          date,
          i,
          // variables,
          getVariableValue,
        );
        if (value) {
          // values[i] = value;
          values[i] = { ...(value as Value), date };
        }
        console.log(i + 1, period.numberOfDaysInPeriod);
      }
      // const stateValues = evaluateFlow(dates, edges, nodes, variables);
      setEvaluationState(values);
    } catch (e) {
      console.log('error evaluate', e);
    }
  }, [
    edges,
    evaluateVariables,
    getVariableValue,
    nodes,
    period.numberOfDaysInPeriod,
    period.startDate,
  ]);

  const component = React.useMemo(
    () => (
      <Box sx={{ mb: 1, background: colors.blue[200] }}>
        <Box sx={{ display: 'flex', height: 500, width: '100%' }}>
          {flowComponent}
        </Box>
        {evaluationStateElement}
      </Box>
    ),
    [evaluationStateElement, flowComponent],
  );

  return {
    component,
    evaluate,
  };
};
