import { parseScheduler } from '~src/data/store/modules/schedulers/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import { SchedulerState } from '~src/data/store/reducers/common/scheduler-state';
import { schedulersActions } from '~src/data/store/reducers/workspace/projects/reports/schedulers/reducer';
import { Scheduler } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface SchedulerVisitor {
  visit(scheduler: SchedulerVisitable): void;
  post(): void;
}

export class SchedulerVisitable implements Visitable<SchedulerVisitor> {
  constructor(
    private _workspaceId: string,
    private _scheduler: Scheduler,
  ) {}
  public accept(visitor: SchedulerVisitor) {
    visitor.visit(this);
  }

  public parse(): SchedulerState {
    return parseScheduler(this._scheduler);
  }
}

export class SchedulerStateVisitor implements SchedulerVisitor {
  private _schedulers: SchedulerState[];
  constructor(private _dispatch: AppDispatch) {
    this._schedulers = [];
  }
  public visit(scheduler: SchedulerVisitable): void {
    this._schedulers.push(scheduler.parse());
  }
  post() {
    this._dispatch(schedulersActions.addMany(this._schedulers));
  }
}
