import { AssetType } from '@pladdenico/models';
import { parseFund } from '~src/data/store/modules/assets/funds/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  fundsActions,
  FundState,
} from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import { Asset, Fund } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface FundVisitor {
  visit(fund: FundVisitable): FundState;
  post(): void;
}

export const isFund = (asset: Asset): asset is Fund => {
  return asset._type === AssetType.Fund;
};

export class FundVisitable implements Visitable<FundVisitor> {
  constructor(
    private _workspaceId: string,
    private _fund: Fund,
  ) {}
  public accept(visitor: FundVisitor) {
    return visitor.visit(this);
  }

  public parse(): FundState {
    const assetId = this._fund.asset?.id ?? '';
    return parseFund(this._workspaceId, assetId, this._fund);
  }
}

export class FundStateVisitor implements FundVisitor {
  private _funds: FundState[];
  constructor(private _dispatch: AppDispatch) {
    this._funds = [];
  }

  public visit(fund: FundVisitable): FundState {
    const fundParsed = fund.parse();
    this._funds.push(fundParsed);
    return fundParsed;
  }

  post() {
    this._dispatch(
      fundsActions.upsertManyElements(this._funds, {
        shouldAutobatch: true,
      }),
    );
  }
}
