import { Edge, Node } from 'reactflow';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';

import { NodeType } from '../../../nodes/node-type';

export const stockInitialNodes: Node<NodeData, NodeType>[] = [
  {
    id: 'initial',
    type: 'initial',
    data: {
      label: 'Initial',
      initialization:
        '{"initial":{"valuation":{"value":1221},"stock":{"position":11}}}',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value:
              'prevPosition: (idx > 0 ? values[idx].stock.position : initial.stock.position)',
          },
        ],
      },
    },
    position: { x: 250, y: 0 },
  },
  {
    id: 'stock',
    type: 'inputType',
    data: {
      label: 'Stock',
      variableId: null,
    },
    position: { x: 100, y: 80 },
  },
  {
    id: 'value',
    type: 'variable',
    data: {
      label: 'value',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value:
              'valuation: { value: Stock.valuation.value*Initial.prevPosition }',
          },
        ],
      },
    },
    position: { x: 100, y: 200 },
  },
  {
    id: 'stockPosition',
    type: 'variable',
    data: {
      label: 'stockPosition',
      evaluator: {
        expressions: [
          {
            inResult: true,
            value: 'stock: { position: Initial.prevPosition }',
          },
        ],
      },
    },
    position: { x: 400, y: 200 },
  },
  {
    id: 'valuation',
    type: 'output',
    data: {
      label: 'valuation',
      name: 'value',
    },
    position: { x: 100, y: 400 },
  },
  {
    id: 'position',
    type: 'output',
    data: {
      label: 'stock',
      name: 'stockPosition',
    },
    position: { x: 400, y: 400 },
  },
];

export const stockInitialEdges: Edge[] = [
  {
    id: '1',
    source: 'initial',
    target: 'value',
  },
  {
    id: '2',
    source: 'stock',
    target: 'value',
  },
  {
    id: '3',
    source: 'value',
    target: 'valuation',
  },
  {
    id: '4',
    source: 'initial',
    target: 'stockPosition',
  },
  {
    id: '5',
    source: 'stockPosition',
    target: 'position',
  },
];

export const initialStockFlow = {
  nodes: stockInitialNodes,
  edges: stockInitialEdges,
};
