import React from 'react';
import { TransactionType } from '~src/utils/finance/transaction-type';

interface Props {
  type: TransactionType;
}

export function typeToString(type: string) {
  if (type === TransactionType.Buy) {
    return 'Buy';
  } else if (type === TransactionType.Cost) {
    return 'Cost';
  } else if (type === TransactionType.Account) {
    return 'Account';
  } else if (type === TransactionType.Lend) {
    return 'Lend';
  } else if (type === TransactionType.Borrow) {
    return 'Borrow';
  } else if (type === TransactionType.Repayment) {
    return 'Repayment';
  } else if (type === TransactionType.Custom) {
    return 'Custom';
  } else if (type === TransactionType.Dividend) {
    return 'Dividend';
  } else if (type === TransactionType.Interest) {
    return 'Interest';
  } else if (type === TransactionType.Sell) {
    return 'Sell';
  }
  return 'undefined';
}

export const ViewTypeField = React.memo((props: Props) => {
  return <>{typeToString(props.type)}</>;
});
