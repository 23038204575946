import * as React from 'react';
import { AssetData } from '~src/domain/workspace/components/asset/assets.component';
// import history from '~src/navigation/history';
import { workspaceAssetRootPath } from '~src/navigation/paths/workspace/asset/paths';
import { getInitials } from '~src/utils/common/get-initials';

import {
  Avatar,
  colors,
  Link,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableField } from '@pladdenico/table';
// import // AssetCategories,
// // AssetCategory,
// // assetCategoryToString,
// '~src/utils/interfaces/asset-categories';
import { EditCategory } from '~src/hooks/fields/workspace/asset/category/edit';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetCategoryState } from '~src/data/store/reducers/asset/categories/reducer';
import { ViewCategoryField } from '~src/hooks/fields/workspace/asset/category/view-field';
import { useNavigate } from 'react-router-dom';

// import { sortBy } from 'lodash';

const useStyles = makeStyles()((theme: Theme) => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  description: {
    maxWidth: '15em',
  },
  category: {
    maxWidth: '15em',
  },
}));

const useAvatarColorStyles = makeStyles()((theme: Theme) => ({
  color1: {
    backgroundColor: colors.green[100],
    contrastText: theme.palette.getContrastText(colors.green[100]),
  },

  color2: {
    backgroundColor: colors.red[100],
    contrastText: theme.palette.getContrastText(colors.red[100]),
  },

  color3: {
    backgroundColor: colors.blue[100],
    contrastText: theme.palette.getContrastText(colors.blue[100]),
  },

  color4: {
    backgroundColor: colors.yellow[100],
    contrastText: theme.palette.getContrastText(colors.green[100]),
  },
}));

const getAvatarColor = (array: Array<[string, string]>, seed: string) => {
  return array[seed.length % array.length][1];
};

export function useAssetTableFields(
  tenantId: string,
  workspace: WorkspaceState,
): TableField.Field<AssetData>[] {
  const { classes, cx } = useStyles();
  const { classes: avatarColorClasses } = useAvatarColorStyles();
  const avatarColorClassesArray = Object.entries(avatarColorClasses);
  const navigate = useNavigate();

  function handleAssetClick(
    tenantId: string,
    workspaceId: string,
    assetId: string,
  ) {
    navigate(workspaceAssetRootPath(tenantId, workspaceId, assetId));
  }

  // const categoryItems = Object.values(AssetCategories).map((category) => {
  //   return {
  //     name: assetCategoryToString(category),
  //     value: category,
  //     t: category,
  //   };
  // });

  const fields: TableField.Field<AssetData>[] = [
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.name,
      renderView: (editProps) => {
        const name = editProps.data.name ? editProps.data.name : '';
        return (
          <div className={classes.nameContainer}>
            <Avatar
              className={cx(
                classes.avatar,
                getAvatarColor(avatarColorClassesArray, name),
              )}
            >
              {/* src={user.avatarUrl} */}
              {getInitials(name)}
            </Avatar>
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                handleAssetClick(tenantId, workspace.id, editProps.data.id)
              }
            >
              {name}
            </Link>
          </div>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="name"
            value={editProps.data.name}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'type',
      name: 'type',
      field: 'type',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.type,
    },
    {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.description,
      renderView: (editProps) => {
        return (
          <Typography variant="body1" className={classes.description} noWrap>
            {editProps.data.description}
          </Typography>
        );
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            required
            id="description"
            value={editProps.data.description}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
    },
    {
      id: 'category',
      name: 'category',
      field: 'category',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.categoryId,
      renderView: (editProps) => {
        const categoryId = editProps.data.categoryId;
        if (!categoryId) {
          return <></>;
        }
        return (
          <Typography variant="body1" className={classes.category} noWrap>
            <ViewCategoryField
              categoryId={categoryId}
              workspaceId={workspace.id}
            />
            {/* {editProps.data.category} */}
          </Typography>
        );
      },
      update: (data, category: AssetCategoryState) => {
        return {
          ...data,
          categoryId: category.id,
        };
      },
      renderEdit: (editProps) => {
        return (
          <EditCategory
            categoryId={editProps.data.categoryId ?? undefined}
            handleValue={editProps.onChange}
            workspace={workspace}
          />
        );
        // const categoryElement = (
        //   <SelectItem
        //     items={categoryItems}
        //     handleT={editProps.onChange}
        //     t={editProps.data.category}
        //     label="category"
        //   />
        // );
        // return categoryElement;
      },
    },
  ];
  return fields;
}
