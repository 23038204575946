import * as graphql from 'graphql';
import * as uuid from 'uuid';
import { Api } from '~src/services/request/api';
import { Subscription } from '~src/services/request/subscription';

import { Logger, LogLevel } from '@pladdenico/common';
import { User } from '@pladdenico/models';

export class FinanceApi {
  private _logger: Logger;
  private _api: Api;
  private _id: string;

  constructor() {
    this._logger = new Logger('FinanceApi', LogLevel.INFO);
    this._id = uuid.v1();

    this._api = new Api('auth/finance');
  }

  public connect(user: User, token: string) {
    this._api.connect(user, token);
  }

  public subscribe(subscription: Subscription, callback: () => void) {
    this._api.subscribe(subscription, callback);
  }

  public unsubscribe(subscription: Subscription) {
    this._api.unsubscribe(subscription);
  }

  // public listenToQuoteStart(
  //   paper: PaperState,
  //   fromDate: Date,
  //   toDate: Date,
  //   callback: () => void
  // ) {
  //   this._api.emit({
  //     name: 'quote',
  //     args: { id: this._id, paper, fromDate, toDate },
  //     callback,
  //   });
  // }

  // public listenToQuoteStop(paperId: string) {
  //   this._api.emit({
  //     name: 'quote.stop',
  //     args: { id: this._id, paperId },
  //   });
  // }

  public executeQuery<Context>(
    query: graphql.DocumentNode,
    variables: unknown,
    context: Context,
    callback: (result: any) => void,
  ) {
    this._api.executeGraphQLQuery(
      'graphQLQuery',
      query,
      variables,
      context,
      callback,
    );
  }
}
