import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CustomRouterLink } from '~src/domain/workspace/components/drawer/items/custom-router';
import {
  workspaceProjectsBasePath,
  workspaceProjectsRootPath,
} from '~src/navigation/paths/workspace/project/paths';
import {} from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';

import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import { drawerListItemButtonSX } from './drawer-list-item-button-sx';

export const drawerWidth = 240;

interface Props {
  tenant: TenantState;
  workspace: WorkspaceState;
}

export const WorkspaceDrawerProjectsItemComponent = React.memo(
  (props: Props) => {
    const { tenant, workspace } = props;

    const match = useMatch({ path: workspaceProjectsBasePath, end: false });

    return (
      <ListItemButton
        component={CustomRouterLink}
        selected={match != null}
        to={workspaceProjectsRootPath(tenant.id, workspace.id)}
        sx={drawerListItemButtonSX}
      >
        <ListItemIcon>
          <WorkspacesIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={'Projects'} />
      </ListItemButton>
    );
  },
);
