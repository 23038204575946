import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { Region } from '~src/services/graphql/user/client/graphql';

export function parseRegion(region: Region): RegionState {
  return {
    id: region.id,
    name: region.name ?? '',
    symbol: region.symbol ?? '',
    type: region.type ?? '',
  };
}
