// import { colors } from '@mui/material';

// export const colorLinesAndPoints = [
//   colors.indigo[300],
//   colors.blue[300],
//   colors.cyan[300],
//   colors.deepPurple[300],
//   colors.green[300],
//   colors.deepOrange[300],
//   colors.grey[300],
//   colors.lightGreen[300],
//   colors.lime[300],
//   colors.orange[300],
//   colors.pink[300],
//   colors.purple[300],
//   colors.red[300],
//   colors.teal[300],
//   colors.yellow[300],
//   // colors.lightBlue[300],
//   // colors.amber[300],
//   // colors.blueGrey[300],
// ];

export const colorLinesAndPoints = [
  '#f8b319',
  '#fcd46a',
  '#0f3d66',
  '#51779e',
  '#e0d4b8',
  '#3a5054',
];

export const colorsBarChart = [
  '#2bccf9',
  '#6edef8',
  '#283864',
  '#6e7c95',
  '#b6ccc9',
  '#404f77',
];

export const colorsPieChart = [
  '#20b2aa',
  '#008b8b',
  '#d1e0de',
  '#e0e4e7',
  '#404040',
];

export const colorsAreaChart = [
  '#20b2aa',
  '#008b8b',
  '#d1e0de',
  '#e0e4e7',
  '#404040',
];

// export const colorPalette = [
//   '#1c9233',
//   '#bdf611',
//   '#a88146',
//   '#48d198',
//   '#a793ab',
//   '#344301',
//   '#120191',
//   '#96d23c',
//   '#5274a1',
//   '#9c2a6b',
//   '#976a88',
//   '#7f89c7',
//   '#35641d',
//   '#719fb2',
//   '#4a74d0',
//   '#6e5130',
//   '#3075a0',
//   '#c67c67',
//   '#6498bb',
//   '#2b5351',
//   '#36fdbd',
//   '#1c779d',
//   '#8a475b',
//   '#446a56',
//   '#b3a5f8',
//   '#f5af2e',
//   '#ff5d30',
//   '#5b58c0',
//   '#a7757d',
//   '#f4b3ce',
// ];
export const colorPalette = [
  '#0D47A1', // Deep Royal Blue
  '#1565C0', // Bright Cobalt Blue
  '#42A5F5', // Sky Blue
  '#90CAF9', // Soft Azure
  '#BBDEFB', // Pale Blue
  '#1A237E', // Indigo
  '#3949AB', // Medium Indigo
  '#5C6BC0', // Periwinkle
  '#7986CB', // Lavender Blue
  '#9FA8DA', // Pastel Blue
  '#E3F2FD', // Lightest Blue
  '#263238', // Blue Grey
  '#546E7A', // Slate Grey
  '#B0BEC5', // Light Blue Grey
  '#CFD8DC', // Very Light Blue Grey
  '#ECEFF1', // Off White Blue
  '#212121', // Almost Black
  '#37474F', // Charcoal Blue
  '#607D8B', // Cool Grey Blue
  '#B3E5FC', // Bright Light Blue
];

export const greyBasedPalette = [
  '#212121', // Dark Grey
  '#424242', // Charcoal Grey
  '#616161', // Medium Grey
  '#757575', // Ash Grey
  '#9E9E9E', // Silver Grey
  '#BDBDBD', // Light Grey
  '#E0E0E0', // Pale Grey
  '#EEEEEE', // Off White Grey
  '#F5F5F5', // Almost White
  '#263238', // Blue Grey
  '#455A64', // Steel Grey
  '#607D8B', // Cadet Grey
  '#78909C', // Sky Grey
  '#90A4AE', // Misty Grey
  '#B0BEC5', // Cloud Grey
  '#CFD8DC', // Pearl Grey
  '#000000', // Black
  '#FFFFFF', // White
  '#37474F', // Deep Blue Grey
  '#455A64', // Slate Grey
];
