import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export const createQueryWorkspaceContext = (
  tenantId: string,
  workspaceId: string,
): QueryWorkspaceContext => {
  const context: QueryWorkspaceContext = {
    type: QueryContextTypes.workspace,
    tenantId,
    workspaceId,
  };
  return context;
};
