import { compact, sortedIndexBy, sortedLastIndexBy } from 'lodash';
import { ExchangeRateState } from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { ValuationState } from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import { BaseValue } from '~src/utils/finance/base-value';
import { CashFlow } from '~src/utils/finance/cash-flow';
import { Return } from '~src/utils/finance/return';
import { convert } from '~src/utils/finance/currency-converter';
import { transferCashSign } from '~src/utils/finance/transfer-cash-sign';

import { finance } from '@pladdenico/finance';
import { Quote, Trade, TransferType } from '@pladdenico/models';
import { BaseValuation } from './base-valuation';
import { QuoteState } from '~src/data/store/reducers/finance/quote/reducer';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { Maybe } from 'graphql/jsutils/Maybe';

export class Finance {
  public static value(
    valuations: ValuationState[] | undefined,
    date: Date,
    baseCurrency: CurrencyState,
    exchangeRateState: ExchangeRateState,
  ): BaseValue | undefined {
    const valuation = Finance.findClosestValuation(valuations, date);
    if (valuation && valuation.value && valuation.currencyId) {
      const value =
        valuation.value *
        convert(exchangeRateState, valuation.currencyId, baseCurrency.id, date);
      return {
        value,
        date: valuation.date,
        currencyId: baseCurrency.id,
        valuation,
      };
    }
    return undefined;
  }
  public static valuation(
    valuations: ValuationState[] | undefined,
    date: Date,
    baseCurrency: CurrencyState,
    exchangeRateState: ExchangeRateState,
  ): BaseValuation | undefined {
    const valuation = Finance.findClosestValuation(valuations, date);
    if (valuation && valuation.value && valuation.currencyId) {
      const value =
        valuation.value *
        convert(exchangeRateState, valuation.currencyId, baseCurrency.id, date);
      return {
        value,
        date: valuation.date,
        baseCurrencyId: valuation.currencyId,
        baseValue: valuation.value,
        currencyId: baseCurrency.id,
      };
    }
    return undefined;
  }

  public static findClosestValuation(
    valuations: ValuationState[] | undefined,
    date: Date,
  ): ValuationState | undefined {
    if (!valuations || valuations.length === 0) {
      return undefined;
    }
    let valuationDate = valuations[0].date;
    let maxBeforeStartDiff = valuationDate
      ? valuationDate.getTime()
      : undefined;
    // let maxAfterStartDiff = -this.asset.valuations[0].date.getTime();
    let beforeIdx = -1;
    // let afterIdx = -1;
    valuations.forEach((valuation, index) => {
      valuationDate = valuation.date;
      if (valuationDate) {
        const diff = date.getTime() - valuationDate.getTime();
        if (0 <= diff) {
          if (!maxBeforeStartDiff || diff < maxBeforeStartDiff) {
            beforeIdx = index;
            maxBeforeStartDiff = diff;
          }
          // } else {
          //   if (diff > maxAfterStartDiff) {
          //     afterIdx = index;
          //     maxAfterStartDiff = diff;
          //   }
        }
      }
    });
    if (beforeIdx === -1) {
      // if (afterIdx === -1) {
      return undefined;
      // }
      // return this.valuations[afterIdx];
    }
    const valuation = valuations[beforeIdx];
    return { ...valuation };
    // return Holding.valuationFromStock({...valuation}, date, quotes, trades);
  }

  public static quoteFromDate(date: Date, quotes: QuoteState[]) {
    const quoteIdx = sortedIndexBy(quotes, { date: date } as any, (quote) =>
      quote.date.getTime(),
    );
    return quotes.length > 0 ? quotes[Math.max(0, quoteIdx - 1)] : undefined;
  }

  public static tradeFromDate(date: Date, trades: Trade[]) {
    const quoteIdx = sortedIndexBy(trades, { date: date } as any, (quote) =>
      quote.date.getTime(),
    );
    return trades.length > 0 ? trades[Math.max(0, quoteIdx - 1)] : undefined;
  }

  public static cashFlows(
    valuations: ValuationState[] | undefined,
    transfers: TransferState[] | undefined,
    startDate: Date,
    endDate: Date,
    date: Date,
    exchangeRateState: ExchangeRateState,
    quoteCurrency?: CurrencyState,
    _quotes?: Quote[],
    _trades?: Trade[],
  ): CashFlow[] {
    if (!valuations || valuations.length === 0) {
      return [];
    }

    const startValuation = Finance.findClosestValuation(valuations, startDate);
    const endValuation = Finance.findClosestValuation(valuations, endDate);

    const startCashFlow = {
      value:
        startValuation && startValuation.value && startValuation.currencyId
          ? -startValuation.value *
            convert(
              exchangeRateState,
              startValuation.currencyId,
              quoteCurrency ? quoteCurrency.id : '',
              date,
            )
          : 0,
      date:
        startValuation && startValuation.date ? startValuation.date : startDate,
    };

    // if (endValuation) {
    //   endDate = endValuation.date;
    // }

    let filteredTransfers: TransferState[] = [];

    if (transfers) {
      filteredTransfers = transfers.filter((value) => {
        if (!value.date) {
          return false;
        }
        return startDate < value.date && value.date < endDate;
      });
    }

    let cashFlows = [];

    cashFlows = [
      startCashFlow,
      ...compact(
        filteredTransfers.map((transfer) => {
          if (transfer.value && transfer.currencyId && transfer.date) {
            const sign = transfer.type
              ? transferCashSign(transfer.type as TransferType)
              : 1;
            return {
              value:
                transfer.value *
                convert(
                  exchangeRateState,
                  transfer.currencyId,
                  quoteCurrency ? quoteCurrency.id : '',
                  date,
                ) *
                sign,
              date: transfer.date,
            };
          }
          return undefined;
        }),
      ),
    ];

    if (endValuation && endValuation.value && endValuation.currencyId) {
      cashFlows.push({
        value:
          endValuation.value *
          convert(
            exchangeRateState,
            endValuation.currencyId,
            quoteCurrency ? quoteCurrency.id : '',
            date,
          ),
        // date: endValuation.date,
        date: endDate,
      });
    }
    return cashFlows;
  }

  public static xirr(
    valuations: ValuationState[] | undefined,
    transfers: TransferState[] | undefined,
    startDate: Date,
    endDate: Date,
    date: Date,
    exchangeRateState: ExchangeRateState,
    quoteCurrency?: CurrencyState,
    quotes?: Quote[],
    trades?: Trade[],
  ): number {
    const cashFlows = Finance.cashFlows(
      valuations,
      transfers,
      startDate,
      endDate,
      date,
      exchangeRateState,
      quoteCurrency,
      quotes,
      trades,
    );
    if (
      cashFlows.every((cashFlow) => cashFlow.value === 0) ||
      cashFlows.every((cashFlow) => cashFlow.value >= 0) ||
      cashFlows.every((cashFlow) => cashFlow.value <= 0) ||
      (cashFlows.length === 2 && cashFlows[0].date === cashFlows[1].date)
    ) {
      return 0;
    }

    const res = finance.XIRR(
      cashFlows.map((cashFlow) => {
        return cashFlow.value;
      }),
      cashFlows.map((cashFlow) => {
        return cashFlow.date;
      }),
      0,
    );
    return res;
  }

  private static _filterOperationByDates(
    date: Date,
    startDate: Date,
    endDate: Date,
  ) {
    return (
      startDate.getTime() <= date.getTime() &&
      date.getTime() <= endDate.getTime()
    );
  }

  private static _filterByDates<T extends { date?: Maybe<Date> }>(
    ts: T[],
    startDate: Date,
    endDate: Date,
  ) {
    return ts.filter((t) => {
      if (t.date) {
        return this._filterOperationByDates(t.date, startDate, endDate);
      }
      return false;
    });
  }

  public static absoluteReturns(
    valuations: ValuationState[] | undefined,
    transfers: TransferState[] | undefined,
    startDate: Date,
    endDate: Date,
    quotes?: Quote[],
  ) {
    if (!valuations || valuations.length === 0) {
      return [];
    }
    const valuationsWithQuotes = Finance.getValuationsWithQuotes(
      valuations,
      startDate,
      endDate,
      quotes,
    );
    if (valuationsWithQuotes.length > 0 && transfers) {
      const filteredTransfers = this._filterByDates(
        transfers,
        valuationsWithQuotes[0].date,
        valuationsWithQuotes[valuationsWithQuotes.length - 1].date,
      );
      const returnsForHolding: Return[] = [];

      let i = 1;
      let j = 0;
      let previousValueRebalanced = valuationsWithQuotes[0].value;
      while (i < valuationsWithQuotes.length || j < filteredTransfers.length) {
        while (j < filteredTransfers.length) {
          const transfer = filteredTransfers[j];
          if (
            i === valuationsWithQuotes.length ||
            (transfer.date &&
              transfer.date.getTime() < valuationsWithQuotes[i].date.getTime())
          ) {
            if (transfer.value) {
              previousValueRebalanced -= transfer.value;
            }
            ++j;
          } else {
            break;
          }
        }

        while (i < valuationsWithQuotes.length) {
          const transaction = filteredTransfers[j];
          if (
            j === filteredTransfers.length ||
            (transaction.date &&
              valuationsWithQuotes[i].date.getTime() <=
                transaction.date.getTime())
          ) {
            returnsForHolding.push({
              date: valuationsWithQuotes[i].date,
              value: valuationsWithQuotes[i].value - previousValueRebalanced,
            });
            previousValueRebalanced = valuationsWithQuotes[i].value;
            ++i;
          } else {
            break;
          }
        }
      }
      return returnsForHolding;
    }
    return [];
  }

  private static getValuationsWithQuotes(
    valuations: ValuationState[] | undefined,
    startDate: Date,
    endDate: Date,
    quotes?: Quote[],
  ): BaseValue[] {
    const valuationsWithQuotes: ValuationState[] = [];
    if (valuations) {
      const startIdx = sortedLastIndexBy<{ date?: Date | null }>(
        valuations,
        { date: startDate },
        (valuation: { date?: Date | null }) => {
          return valuation.date ? valuation.date.getTime() : undefined;
        },
      );
      const endIdx = sortedLastIndexBy<{ date?: Date | null }>(
        valuations,
        { date: endDate },
        (valuation: { date?: Date | null }) => {
          return valuation.date ? valuation.date.getTime() : undefined;
        },
      );
      valuationsWithQuotes.push(
        ...valuations.slice(Math.max(startIdx - 1, 0), endIdx),
      );
    }
    let quoteIdx = 0;
    let valuationIdx = 0;
    const quoteValuations: BaseValue[] = [];
    while (quotes && valuationIdx < valuationsWithQuotes.length) {
      const quoteStartDate = valuationsWithQuotes[valuationIdx].date;
      const quoteEndDate =
        valuationIdx + 1 < valuationsWithQuotes.length
          ? valuationsWithQuotes[valuationIdx + 1].date
          : endDate;

      while (
        quoteStartDate &&
        quoteIdx < quotes.length &&
        quotes[quoteIdx].date.getTime() <= quoteStartDate.getTime()
      ) {
        ++quoteIdx;
      }
      while (
        quoteEndDate &&
        quoteIdx < quotes.length &&
        quotes[quoteIdx].date.getTime() < quoteEndDate.getTime()
      ) {
        // XXX: Needs to take into account quotes, and uncomment lines below
        // const valuation = valuationsWithQuotes[valuationIdx];
        // if (valuation.shares) {
        //   quoteValuations.push({
        //     date: quotes[quoteIdx].date,
        //     value: valuation.shares * quotes[quoteIdx].close,
        //   });
        // }
        ++quoteIdx;
      }

      ++valuationIdx;
    }
    const baseValuations: BaseValue[] = compact(
      valuationsWithQuotes.map((valuation) => {
        if (valuation.date && valuation.value) {
          return {
            date: valuation.date,
            value: valuation.value,
            currencyId: valuation.currencyId,
            valuation,
          };
        }
        return undefined;
      }),
    );
    baseValuations.push(...quoteValuations);
    return baseValuations.sort(
      (v1, v2) => v1.date.getTime() - v2.date.getTime(),
    );
  }
}
