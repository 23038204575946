import * as React from 'react';
import { useSelector } from 'react-redux';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { LoanHoldingState } from '~src/data/store/reducers/holding/holding-types/loan/loan-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getLoanById } from '~src/data/store/selectors/asset/asset-types/loans/selectors';
import { selectFavoriteHoldingByHoldingId } from '~src/data/store/selectors/holding/favorite-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
// import history from '~src/navigation/history';
import { workspaceAssetRootPath } from '~src/navigation/paths/workspace/asset/paths';
import { portfolioBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/portfolio/paths';

import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Chip, Divider, IconButton, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { FavoriteHoldingComponent } from '../../favorite-holdings/favorite-holding.component';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { useNavigate } from 'react-router-dom';

interface Props {
  workspace: WorkspaceState;
  loanHolding: LoanHoldingState;
  holding: HoldingState;
  portfolios: PortfolioState[];
  asset?: AssetState;
  projectId: string;
  startEditing: () => void;
}

export const ViewComponent = React.memo((props: Props) => {
  const {
    workspace,
    holding,
    loanHolding,
    portfolios,
    projectId,
    asset,
    startEditing,
  } = props;
  const favorited = useSelector((state: RootState) =>
    selectFavoriteHoldingByHoldingId(selectWorkspaceData(state), {
      projectId: projectId,
      holdingId: holding.id,
    }),
  );
  const loan = useSelector((state: RootState) =>
    getLoanById(selectWorkspaceData(state), {
      id: loanHolding.loanId,
      workspaceId: workspace.id,
    }),
  );
  const entity = useSelector((state: RootState) =>
    getEntityById(selectWorkspaceData(state), {
      id: asset?.entityId,
      workspaceId: workspace.id,
    }),
  );
  const navigate = useNavigate();
  const handleClickPortfolio = (
    workspace: WorkspaceState,
    projectId: string,
    portfolio: PortfolioState,
  ) => {
    navigate(
      portfolioBasePath(
        workspace.tenantId,
        workspace.id,
        projectId,
        portfolio.id,
      ),
    );
  };

  const renderFavoriteHoldingButton = (
    handleToggleFavoriteHolding: () => void,
  ) => {
    const icon = favorited ? <FavoriteIcon /> : <FavoriteBorderIcon />;
    return (
      <IconButton aria-label="favorite" onClick={handleToggleFavoriteHolding}>
        {icon}
      </IconButton>
    );
  };

  const typesElement =
    asset && asset.types
      ? asset.types.map((type: string) => {
          return (
            <Typography variant="body1" key={type}>
              {type}
            </Typography>
          );
        })
      : undefined;

  let assetElement;
  if (asset) {
    assetElement = (
      <>
        <Link
          variant="h6"
          onClick={() =>
            navigate(
              workspaceAssetRootPath(
                workspace.tenantId,
                workspace.id,
                asset.id,
              ),
            )
          }
        >
          {asset.name}
        </Link>
        <Typography variant="body1">{asset.description}</Typography>
        <Typography variant="body1">{loan?.description}</Typography>
      </>
    );
  }
  const editActionElement = (
    <IconButton aria-label="edit" onClick={() => startEditing()}>
      <EditIcon />
    </IconButton>
  );

  const portfoliosElement = portfolios.map((portfolio) => {
    return (
      <Chip
        label={portfolio.name}
        key={portfolio.id}
        variant="outlined"
        sx={{ mr: 1 }}
        onClick={() => handleClickPortfolio(workspace, projectId, portfolio)}
      />
    );
  });
  const holdingElement = (
    <>
      <Typography variant="body1">{holding.description}</Typography>
      <Box sx={{ mt: 1 }}>{portfoliosElement}</Box>
    </>
  );

  return (
    <>
      <Box sx={{ my: 1, mx: 2, mb: 2 }}>
        <Typography variant="h4">
          {holding.name}
          <FavoriteHoldingComponent
            tenantId={workspace.tenantId}
            workspaceId={workspace.id}
            projectId={projectId}
            render={renderFavoriteHoldingButton}
            holding={holding}
            favorited={favorited}
          />
          {editActionElement}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {entity?.description}
        </Typography>
      </Box>
      <Divider variant="fullWidth" sx={{ mx: 2 }} />
      <Box sx={{ my: 2, mx: 2 }}>
        {assetElement}
        {typesElement}
      </Box>
      <Divider variant="fullWidth" sx={{ mx: 2 }} />
      <Box sx={{ mt: 2, mx: 2 }}>{holdingElement}</Box>
    </>
  );
});
