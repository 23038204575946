import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BasePrivateEquityFundProjectId {
  workspaceId: string;
  id: string;
}
// export type PrivateEquityFundProjectState = PrivateEquityFundProject;

export type PrivateEquityFundProjectState = Omit<
  graphqlWorkspaceTypes.PrivateEquityFundProject,
  'project' | 'privateEquityFund'
> & {
  workspaceId: string;
  privateEquityFundId: string;
  projectId: string;
};

export const initialPrivateEquityFundProjectsState = [];

const selectKeyId = (
  privateEquityFundProject: BasePrivateEquityFundProjectId,
) => privateEquityFundProject.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (
  a: BasePrivateEquityFundProjectId,
  b: BasePrivateEquityFundProjectId,
) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BasePrivateEquityFundProjectId,
  PrivateEquityFundProjectState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const privateEquityFundProjectsSlice = createBaseRecordSlice(
  'privateEquityFundProjects',
  adapter,
);
export const privateEquityFundProjectsSelectors = adapter.selectors;

export const privateEquityFundProjectsReducer =
  privateEquityFundProjectsSlice.reducer;
export const privateEquityFundProjectsActions =
  privateEquityFundProjectsSlice.actions;
