import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';

export const useCurrencyField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues, any>,
  tname: TName,
  _label: string,
  defaultValue: PathValue<TFieldValues, TName>,
) => {
  return (
    <Controller
      name={tname}
      rules={{ required: true }}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        console.log('fieldState.error', fieldState.error);
        return (
          <EditCurrencyField
            currencyId={field.value}
            handleValue={(entity) => field.onChange(entity.id)}
            labelled={true}
            // error={
            //   fieldState.error
            //     ? { message: fieldState.error.message }
            //     : undefined
            // }
          />
        );
      }}
    />
  );
};
