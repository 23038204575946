import React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { AssetTyped } from '~src/data/store/reducers/asset/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';
import { DialogContentComponent as BaseInfoFormComponent } from '~src/domain/workspace/components/asset/form/create/dialog-content.component';

import { AssetType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
}

export const CreateDialogComponent = React.memo((props: Props) => {
  const [assetInput] = React.useState<AssetCreateInputData>({
    entityId: '',
    name: '',
    type: AssetType.Unknown,
    categoryId: null,
    description: '',
    types: [],
    VPS: '',
    lookup: '',
    ISIN: '',
  });

  const createdCallback = (assetTyped: AssetTyped) => {
    console.log('assetTyped: AssetTyped', assetTyped);
    return Promise.resolve();
  };

  return (
    <DialogComponent
      dialogProps={{
        open: props.open,
        onClose: props.handleClose,
        fullWidth: true,
      }}
    >
      <BaseInfoFormComponent
        workspace={props.workspace}
        handleClose={props.handleClose}
        assetInput={assetInput}
        fieldsConfig={{
          disableAssetType: false,
        }}
        createdCallback={createdCallback}
      />
    </DialogComponent>
  );
});
