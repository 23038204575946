// import {ResponsiveContainer} from 'recharts';
import numbro from 'numbro';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Graph as ChartGraph } from '~src/components/charts/graph';
import { getOwners } from '~src/data/store/modules/owners';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { CircularProgress } from '@mui/material';
import { Owner, OwnerLink } from '@pladdenico/models';

interface Node {
  id: number | undefined;
  name: string | null;
  group: number | null;
  isWorkspace: boolean | null;
}

interface Link {
  source: number | null;
  target: number | null;
  value: string;
}

interface Graph {
  nodes: Node[];
  links: Link[];
}

function createData(owners?: Owner[], ownerLinks?: OwnerLink[]): Graph {
  const nodes: Node[] = owners
    ? owners.map((owner) => {
        return {
          id: owner.id,
          name: owner.name,
          group: owner.group,
          isWorkspace: owner.isWorkspace,
        };
      })
    : [];

  const links: Link[] = ownerLinks
    ? ownerLinks.map((ownerLink) => {
        return {
          source: ownerLink.fromId,
          target: ownerLink.toId,
          value: numbro(ownerLink.value).format({
            output: 'percent',
            thousandSeparated: true,
            mantissa: 2,
          }),
        };
      })
    : [];

  const data = {
    nodes,
    links,
    // nodes: [
    //   {id: 0, name: 'Egil Bodd', group: 1, fx: 100, fy: 100},
    //   {id: 1, name: 'Caroline Bodd', group: 1, fx: 250, fy: 100},
    //   {id: 2, name: 'Michael Bodd', group: 1, fx: 400, fy: 100},
    //   {id: 3, name: 'Nicolai Bodd', group: 1, fx: 550, fy: 100},
    //   {id: 4, name: 'Nathalie Bodd', group: 1, fx: 700, fy: 100},
    //   {id: 5, name: 'Stephanie Bodd', group: 1, fx: 850, fy: 100},
    //   {id: 6, name: 'Boddco Holding AS', group: 2, fx: 300, fy: 200},
    //   {id: 8, name: 'Mininaste AS', group: 2, fx: 700, fy: 200},
    //   {id: 7, name: 'Boddco AS', group: 3, fx: 300, fy: 300},
    //   {id: 9, name: 'Grønland 4 AS', group: 4, fx: 200, fy: 500},
    // ],
    // links: [
    //   {source: 0, target: 6, value: '20%'},
    //   {source: 2, target: 6, value: '20%'},
    //   {source: 3, target: 6, value: '20%'},
    //   {source: 4, target: 6, value: '20%'},
    //   {source: 5, target: 6, value: '20%'},
    //   {source: 6, target: 7, value: '100%'},
    //   {source: 0, target: 8, value: '20%'},
    //   {source: 2, target: 8, value: '20%'},
    //   {source: 3, target: 8, value: '20%'},
    //   {source: 4, target: 8, value: '20%'},
    //   {source: 5, target: 8, value: '20%'},
    //   {source: 0, target: 9, value: '9.99%'},
    //   {source: 7, target: 9, value: '90.01%'},
    // ],
  };
  return data;
}

export function OwnershipComponent() {
  const owners = useSelector((state: RootState) =>
    getOwners(
      selectWorkspaceData(state).owner.owners,
      selectWorkspaceData(state).workspace.selectedWorkspaceId,
    ),
  );
  const ownerLinks = useSelector(
    (state: RootState) => selectWorkspaceData(state).owner.link.links,
  );

  const [data, setData] = useState<Graph>();
  useEffect(() => {
    setData(createData(owners, ownerLinks));
  }, [owners, ownerLinks]);

  if (!owners || !ownerLinks || owners.length === 0) {
    return <CircularProgress />;
  }

  const graph = (
    <ChartGraph chartId="id2" width={1500} height={800} data={data} />
  );

  return (
    <div>
      {graph}
      {/* <Responsive {...Responsive.onlyMobile}>
        <ResponsiveContainer height={400} width="100%">
          {graph}
        </ResponsiveContainer>
      </Responsive>
      <Responsive minWidth={Responsive.onlyMobile.maxWidth}>
        <ResponsiveContainer height={550} width="100%">
          {graph}
        </ResponsiveContainer>
      </Responsive> */}
    </div>
  );
}
