import {
  dnbAccountsReducer,
  initialDnbAccountsState,
} from '~src/services/xlsx/handlers/adapters/dnb/account/reducer';
import {
  dnbTransactionsReducer,
  initialDnbTransactionState,
} from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';

import { combineReducers } from '@reduxjs/toolkit';

export const dnbAdapterInitialState = {
  dnbTransactions: initialDnbTransactionState,
  dnbAccounts: initialDnbAccountsState,
};

export const dnbAdapterReducer = combineReducers({
  dnbTransactions: dnbTransactionsReducer,
  dnbAccounts: dnbAccountsReducer,
});
