import React from 'react';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DashboardDescriptionComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/description/description.component';
import { useDescriptionMenu } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/description/use-description-menu.hook';
import { Config } from '~src/utils/interfaces/config';
import { ElementProps } from '~src/components/dashboard/element';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

export const DescriptionElement = React.memo(
  ({
    workspace,
    project,
    holding,
    config,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useDescriptionMenu(
      element,
      remove,
      updated,
    );

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={{
            dragDisabled: isOpen,
            format: {
              color: element.config.color ?? '#000000',
              backgroundColor: element.config.backgroundColor ?? '#ffffff',
              padding: element.config.padding,
            },
            // hideOverflow: true,
            minHeight: 200,
          }}
        >
          <DashboardDescriptionComponent
            workspace={workspace}
            project={project}
            holding={holding}
            config={config}
          />
          {menu}
        </ElementComponent>
      </>
    );
  },
);
