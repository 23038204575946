import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
// import { getPortfolioConfigsByPortfolioId } from '~src/data/store/selectors/portfolio/portfolio-config/portfolio-configs/selectors';
// import { getPortfolioDashboardConfigsByPortfolioConfigId } from '~src/data/store/selectors/portfolio/portfolio-config/portfolio-dashboard-configs/selectors';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PrivateEquityFundProjectState } from '~src/data/store/reducers/workspace/projects/private-equity-fund-project/private-equity-fund-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { PortfolioDashboardElementsType } from '@pladdenico/models';

import { DashboardConfigState } from './config/hooks/use-dashboard-components.hook';
import { DashboardWithConfigComponent } from './dashboard-with-config.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  fundProject: PrivateEquityFundProjectState;
  fund: PrivateEquityFundState;
}

export const DashboardComponent = (props: Props) => {
  const components: PortfolioDashboardElementsType[] = ['header', 'summary'];
  const dashboardConfig: DashboardConfigState = {
    configId: 1,
    components,
  };

  return (
    <DashboardWithConfigComponent
      workspace={props.workspace}
      project={props.project}
      dashboardConfig={dashboardConfig}
      fund={props.fund}
    />
  );
};
