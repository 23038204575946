import {
  CommitmentStateVisitor,
  CommitmentVisitable,
} from '~src/data/store/visitors/holding/commitment-visitor';
import { Commitment } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleCommitments(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: Commitment[],
  subscriptions: Operation[],
) {
  const commitmentVisitor = new CommitmentStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((commitment) => {
    const commitmentVisitable = new CommitmentVisitable(
      commitment.holding ? commitment.holding.id : '',
      commitment,
    );
    commitmentVisitable.accept(commitmentVisitor);
  });
  commitmentVisitor.post();
  return outputs;
}
