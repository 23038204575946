import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { maxItems } from './breadcrumbs.component';
import { useProjectBreadcrumbs } from './project-breadcrumb';

export function ProjectEntityBreadcrumbComponent() {
  const { tenantId, workspaceId, projectId, entityId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    entityId: string;
  }>();
  const projectBreadCrumbs = useProjectBreadcrumbs(false);
  const entity = useSelector((state: RootState) =>
    getEntityById(selectWorkspaceData(state), { workspaceId, id: entityId }),
  );
  if (entity && tenantId != null && projectId != null) {
    return (
      <Breadcrumbs
        maxItems={maxItems}
        color="inherit"
        separator="›"
        aria-label="breadcrumb"
      >
        {projectBreadCrumbs}
        <Typography
          color="inherit"
          key={holdingBasePath(
            entity.tenantId,
            entity.workspaceId,
            projectId,
            entity.id,
          )}
        >
          {entity.name}
        </Typography>
      </Breadcrumbs>
    );
  }
  return <></>;
}
