import { fetchDashboardElements } from '~src/data/store/modules/common/dashboard/element/requests';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { apis } from '~src/services/request/apis';

import { dashboardElementPath, Operation } from '@pladdenico/portfolio-api';
import { handleDeleteDashboardElements } from '~src/data/store/modules/common/dashboard/element/handler';

export function subscribeToDashboardElements(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  dashboardElements: DashboardElementState[],
  subscriptions: Operation[],
) {
  dashboardElements.forEach((dashboardElement) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: dashboardElementPath(tenantId, workspaceId).objectPath(
              dashboardElement.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchDashboardElements(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to dashboardElement', operation, dashboardElement.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: dashboardElementPath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchDashboardElements(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to dashboardElement', operation, dashboardElement.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: dashboardElementPath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteDashboardElements(workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to dashboardElement', operation, dashboardElement.id);
          },
        );
      }
    });
  });
}
