import {
  FundStateVisitor,
  FundVisitable,
} from '~src/data/store/visitors/asset/types/fund/fund-visitor';
import { Fund } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleFunds(
  workspaceId: string,
  dispatch: AppDispatch,
  funds: Fund[],
) {
  const visitor = new FundStateVisitor(dispatch);
  const res = funds.map((fund) => {
    const assetVisitable = new FundVisitable(workspaceId, fund);
    return assetVisitable.accept(visitor);
  });
  visitor.post();
  return res;
}
