import { CircularProgress } from '@mui/material';
import { uniqueId } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { fetchGeneratedReports } from '~src/data/store/modules/workspaces/reports/generated-reports/requests';
import { fetchProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { fetchReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getRequestStatusById } from '~src/data/store/selectors/common/request/selectors';
import {
  selectRequestData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { getProjectReportById } from '~src/data/store/selectors/workspace/projects/report/project-report/selectors';
import { PortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs';
import { ProjectReportTabsComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs.component';
import { usePortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/use-tabs';
import {
  projectReportsBasePath,
  projectReportTabPath,
} from '~src/navigation/paths/workspace/project/portfolio-project/report/project-report/paths';
import { RequestStatuses } from '~src/services/request/request-status';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  project: ProjectState;
}

interface PropsWithProjectReport extends Props {
  projectReport: ProjectReportState;
}

const ProjectReportRouterWrapperWithTab = React.memo(
  (props: PropsWithProjectReport) => {
    const tabs = usePortfolioProjectReportTabs();
    const { tabId } = useParams<{ tabId: string }>();
    console.log(tabId);

    const tab = tabs.find((t) => t.name === tabId);

    return tab ? (
      <ProjectReportTabsComponent
        workspace={props.workspace}
        portfolioProject={props.portfolioProject}
        project={props.project}
        portfolio={props.portfolio}
        projectReport={props.projectReport}
        tab={tab}
        tabs={tabs}
      />
    ) : (
      <div>unknown tab in project report</div>
    );
  },
);

export const ProjectReportRouterWrapper = React.memo((props: Props) => {
  const { workspace, project } = props;
  const [requestId] = React.useState(() => uniqueId());
  const reportStatus = useSelector((state: RootState) =>
    getRequestStatusById(selectRequestData(state), requestId),
  );
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    dispatch(fetchReports(workspace.tenantId, workspace.id));
    dispatch(
      fetchProjectReports(workspace.tenantId, workspace.id, null, requestId),
    );
    dispatch(fetchGeneratedReports(workspace.tenantId, workspace.id));
  }, [dispatch, requestId, workspace.id, workspace.tenantId]);

  console.log('reportStatus', reportStatus);
  if (
    reportStatus == null ||
    reportStatus?.requestStatus !== RequestStatuses.done
  ) {
    return <CircularProgress />;
  }
  if (reportStatus?.err != null) {
    return (
      <Navigate
        to={`${projectReportsBasePath(
          workspace.tenantId,
          workspace.id,
          project.id,
        )}`}
      />
    );
  }
  return <ProjectReportRouterWrapperWithReport {...props} />;
});

export const ProjectReportRouterWrapperWithReport = React.memo(
  (props: Props) => {
    const { workspace, project } = props;
    const { reportId } = useParams<{ reportId: string }>();
    const projectReport = useSelector((state: RootState) => {
      if (reportId != null) {
        return getProjectReportById(selectWorkspaceData(state), {
          id: reportId,
          projectId: props.portfolioProject.projectId,
        });
      }
    });
    if (!projectReport) {
      return (
        <Navigate
          to={`${projectReportsBasePath(
            workspace.tenantId,
            workspace.id,
            project.id,
          )}`}
        />
      );
    } else {
      return (
        <Routes>
          <Route
            path="*"
            // exact={true}
            element={
              <Navigate
                to={projectReportTabPath(
                  props.workspace.tenantId,
                  props.workspace.id,
                  props.portfolioProject.projectId,
                  projectReport.id,
                  PortfolioProjectReportTabs.Editor,
                )}
                replace
              />
            }
          />
          <Route
            path=":tabId/*"
            element={
              <ProjectReportRouterWrapperWithTab
                {...props}
                projectReport={projectReport}
              />
            }
          />
        </Routes>
      );
    }
  },
);
