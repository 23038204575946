import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createBankNoteHoldings } from '~src/data/store/modules/holdings/bank-note/requests';
import {
  createHoldings,
  deleteHoldings,
  updateHoldings,
} from '~src/data/store/modules/holdings/base/requests';
import { createBondHoldings } from '~src/data/store/modules/holdings/bond/requests';
import { createCryptoHoldings } from '~src/data/store/modules/holdings/crypto/requests';
import { createFundHoldings } from '~src/data/store/modules/holdings/fund/requests';
import { createLoanHoldings } from '~src/data/store/modules/holdings/loan/requests';
import { createStockHoldings } from '~src/data/store/modules/holdings/stock/requests';
import { createWarrantHoldings } from '~src/data/store/modules/holdings/warrant/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingData } from '~src/domain/workspace/components/project/holding/form/use-fields.component';
import { BankNoteHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bank-note/create-action';
import { BondHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/create-action';
import { CryptoHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/crypto/create-action';
import { FundHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/fund/create-action';
import { LoanHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/loan/create-action';
import { StockHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/create-action';
import { WarrantHoldingInputType } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/warrant/create-action';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';
import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';

export type HoldingDataInput =
  | StockHoldingInputType
  | CryptoHoldingInputType
  | BondHoldingInputType
  | LoanHoldingInputType
  | BankNoteHoldingInputType
  | FundHoldingInputType
  | WarrantHoldingInputType
  | undefined;

export function useHoldingActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (oldData: HoldingData, newData: HoldingData) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceOperations.UpdateHoldingInputType = {
          id: newData.holding.id,
          description: newData.holding.description,
          name: newData.holding.name,
          projectId: newData.holding.projectId,
          type: newData.holding.type ?? '',
        };
        dispatch(updateHoldings(workspace.tenantId, workspace.id, [input]))
          .then((_holdings) => {
            resolve();
          })
          .catch((_error) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (data: HoldingData) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        deleteHoldings(
          workspace.tenantId,
          workspace.id,
          data.holding.projectId,
          [data.holding.id],
        ),
      )
        .then((_holdingIds) => {
          resolve();
        })
        .catch((_error) => {
          reject();
        });
    });
  };

  const onCreateHoldings = (
    data: graphqlWorkspaceTypes.CreateHoldingInputType[],
  ): Promise<HoldingState[]> => {
    return dispatch(createHoldings(workspace.tenantId, workspace.id, data));
  };

  const createHoldingWithData = (
    holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType,
    data: HoldingDataInput,
    handleClose: (() => void) | undefined = undefined,
  ) =>
    onCreateHoldings([holdingInput]).then((holdings) => {
      const holding = holdings[0];
      if (holding.type === HoldingType.Stock) {
        const stockHoldingData = data as StockHoldingInputType;
        const stockId = stockHoldingData.stockId;
        if (stockId) {
          return dispatch(
            createStockHoldings(workspace.tenantId, workspace.id, [
              {
                id: uuid.v1(),
                holdingId: holding.id,
                stockId,
              },
            ]),
          )
            .then((_stockHoldings) => {
              handleClose && handleClose();
              return holding;
            })
            .catch((err) => {
              throw err;
            });
        }
      } else if (holding.type === HoldingType.BankNote) {
        const bankNoteHoldingData =
          data as graphqlWorkspaceTypes.CreateBankNoteHoldingInputType;
        return dispatch(
          createBankNoteHoldings(workspace.tenantId, workspace.id, [
            {
              ...bankNoteHoldingData,
              id: uuid.v1(),
              holdingId: holding.id,
            },
          ]),
        )
          .then((_bankNoteHoldings) => {
            handleClose && handleClose();
            return holding;
          })
          .catch((err) => {
            throw err;
          });
      } else if (holding.type === HoldingType.Crypto) {
        const cryptoHoldingData =
          data as graphqlWorkspaceTypes.CreateCryptoHoldingInputType;
        return dispatch(
          createCryptoHoldings(workspace.tenantId, workspace.id, [
            {
              ...cryptoHoldingData,
              id: uuid.v1(),
              holdingId: holding.id,
            },
          ]),
        )
          .then((_cryptoHoldings) => {
            handleClose && handleClose();
            return holding;
          })
          .catch((err) => {
            throw err;
          });
      } else if (holding.type === HoldingType.Loan) {
        const loanHoldingData =
          data as graphqlWorkspaceTypes.CreateLoanHoldingInputType;
        return dispatch(
          createLoanHoldings(workspace.tenantId, workspace.id, [
            {
              ...loanHoldingData,
              id: uuid.v1(),
              holdingId: holding.id,
            },
          ]),
        )
          .then((_loanHoldings) => {
            handleClose && handleClose();
            return holding;
          })
          .catch((err) => {
            throw err;
          });
      } else if (holding.type === HoldingType.Fund) {
        const fundHoldingData =
          data as graphqlWorkspaceTypes.CreateFundHoldingInputType;
        return dispatch(
          createFundHoldings(workspace.tenantId, workspace.id, [
            {
              ...fundHoldingData,
              id: uuid.v1(),
              holdingId: holding.id,
            },
          ]),
        )
          .then((_fundHoldings) => {
            handleClose && handleClose();
            return holding;
          })
          .catch((err) => {
            throw err;
          });
      } else if (holding.type === HoldingType.Warrant) {
        const warrantHoldingData =
          data as graphqlWorkspaceTypes.CreateWarrantHoldingInputType;
        return dispatch(
          createWarrantHoldings(workspace.tenantId, workspace.id, [
            {
              ...warrantHoldingData,
              id: uuid.v1(),
              holdingId: holding.id,
            },
          ]),
        )
          .then((_fundHoldings) => {
            handleClose && handleClose();
            return holding;
          })
          .catch((err) => {
            throw err;
          });
      } else if (holding.type === HoldingType.Bond) {
        const bondHoldingData =
          data as graphqlWorkspaceTypes.CreateBondHoldingInputType;
        return dispatch(
          createBondHoldings(workspace.tenantId, workspace.id, [
            {
              ...bondHoldingData,
              id: uuid.v1(),
              holdingId: holding.id,
            },
          ]),
        )
          .then((_bondHoldings) => {
            handleClose && handleClose();
            return holding;
          })
          .catch((err) => {
            throw err;
          });
      }
      throw new Error('Could not create holding, no implementation');
    });

  const onCreate = (
    holdingInput: graphqlWorkspaceTypes.CreateHoldingInputType,
    action: (holding: HoldingState) => (dispatch: AppDispatch) => Promise<any>,
    handleClose: (() => void) | undefined = undefined,
  ) =>
    onCreateHoldings([holdingInput]).then((holdings) => {
      const holding = holdings[0];
      return dispatch(action(holding))
        .then(() => {
          handleClose && handleClose();
          return holding;
        })
        .catch((err) => {
          throw err;
        });
    });

  return { onRowUpdate, onRowDelete, onCreate, createHoldingWithData };
}
