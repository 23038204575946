import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import {
  forecastsSelectors,
  BaseForecastId,
  ForecastId,
} from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';

const selectForecastState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.forecasts.forecasts;

export const getForecasts = createSelector(
  (state: WorkspaceDataState) => selectForecastState(state),
  (forecasts): ForecastState[] => {
    return forecastsSelectors.selectAllElements(forecasts);
  },
);

export const getForecastById = createCachedSelector(
  (state: WorkspaceDataState) => selectForecastState(state),
  (_state: WorkspaceDataState, id: ForecastId) => id,
  (forecasts, id): ForecastState | undefined => {
    return forecastsSelectors.selectElementByT(forecasts, id);
  },
)({
  keySelector: (_state, id) => `${id.id}-${id.objectId}`,
  selectorCreator: createSelector,
});

export const getForecastsByIds = createCachedSelector(
  (state: WorkspaceDataState) => selectForecastState(state),
  (_state: WorkspaceDataState, ids: ForecastId[]) => ids,
  (forecasts, ids): ForecastState[] => {
    return forecastsSelectors.selectElementsByTs(forecasts, ids);
  },
)({
  keySelector: (_state, ids) => `${JSON.stringify(ids)}`,
  selectorCreator: createSelector,
});

export const getForecastsByObjectId = createCachedSelector(
  (state: WorkspaceDataState, id: BaseForecastId) =>
    forecastsSelectors.selectElementsByKey(selectForecastState(state), id),
  (_state: WorkspaceDataState, id: BaseForecastId) => id.objectId,
  (forecasts, holdingId): ForecastState[] => {
    return forecasts?.filter((f) => f.objectId === holdingId) ?? [];
  },
)({
  keySelector: (_state, ids) => `${JSON.stringify(ids)}`,
  selectorCreator: createSelector,
});
