import {
  CommitmentFieldConfig,
  CommitmentFieldDataConfig,
} from '~src/domain/workspace/components/project/commitment/form/fields/commitment-field-config';

export const getCommitmentFieldConfig = (): CommitmentFieldConfig => {
  let transferData: CommitmentFieldDataConfig | undefined;
  return {
    base: {
      type: {
        visible: true,
        disabled: true,
      },
      currency: {
        visible: true,
        disabled: false,
      },
      commitmentDate: {
        visible: true,
        disabled: false,
      },
      startDate: {
        visible: true,
        disabled: false,
      },
      endDate: {
        visible: true,
        disabled: false,
      },
      value: {
        visible: true,
        disabled: false,
      },
    },
    data: transferData,
  };
};
