import React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DialogFormComponent } from '~src/domain/workspace/components/entity/region-allocations/form/dialog-form.component';

import { Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  entity: EntityState;
}

export const RegionAllocationsToolbar = (props: Props) => {
  const [
    createRegionAllocationsDialogOpened,
    setCreateRegionAllocationsDialogOpened,
  ] = React.useState(false);

  const { classes } = useStyles();

  const handleAddRegionAllocations = () => {
    setCreateRegionAllocationsDialogOpened(true);
  };

  function handleCloseRegionAllocationsDialog() {
    setCreateRegionAllocationsDialogOpened(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="outlined"
          onClick={handleAddRegionAllocations}
        >
          Add region allocations
        </Button>
      </div>
      <DialogComponent
        dialogProps={{
          open: createRegionAllocationsDialogOpened,
          onClose: handleCloseRegionAllocationsDialog,
          fullWidth: true,
        }}
      >
        <DialogFormComponent
          tenantId={props.workspace.tenantId}
          entity={props.entity}
          open={createRegionAllocationsDialogOpened}
          handleClose={handleCloseRegionAllocationsDialog}
        />
      </DialogComponent>
    </div>
  );
};
