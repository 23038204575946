import { sortBy } from 'lodash';
import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AdapterTransaction } from '~src/data/store/selectors/data-import/adapter/selectors';
import { DnbImportedTransactionComponent } from '~src/domain/workspace/components/data/adapters/dnb/imported-transaction.component';
import { InitiateTransactionComponent } from '~src/domain/workspace/components/data/adapters/initiate-transaction.component';
import { NordnetImportedTransactionComponent } from '~src/domain/workspace/components/data/adapters/nordnet/imported-transaction.component';
import { VpsImportedTransactionComponent } from '~src/domain/workspace/components/data/adapters/vps/imported-transaction.component';
import { DnbTransactionState } from '~src/services/xlsx/handlers/adapters/dnb/transaction/reducer';
import { NordnetTransactionState } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import { VpsTransactionState } from '~src/services/xlsx/handlers/adapters/vps/transaction/reducer';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Grid, IconButton } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  transactions: AdapterTransaction[];
  active: boolean;
  handleSuccess: (transaction: AdapterTransaction) => void;
  onClick: (ctrlKey: boolean, transaction: AdapterTransaction) => void;
}

export const ImportedTransactionComponent = React.memo((props: Props) => {
  const { workspace, projectId, transactions, active, handleSuccess, onClick } =
    props;
  const [isShowing, setIsShowing] = React.useState(false);

  // React.useEffect(() => {
  //   if (active) {
  //     setIsShowing(true);
  //   }
  // }, [active]);

  const toggleIsShowing: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback((event) => {
      setIsShowing((isShowing) => !isShowing);
      event.stopPropagation();
    }, []);

  const transactionElements = React.useMemo(() => {
    const ts = sortBy(transactions, (a) => a.adapterId);
    return ts.map((adapterTransaction) => {
      if (adapterTransaction.adapterId === 'dnb') {
        const transaction = adapterTransaction as DnbTransactionState;
        return (
          <DnbImportedTransactionComponent
            key={adapterTransaction.id}
            dnbTransaction={transaction}
            workspace={workspace}
            projectId={projectId}
            active={active}
            onClick={onClick}
          />
        );
      } else if (adapterTransaction.adapterId === 'vps') {
        const transaction = adapterTransaction as VpsTransactionState;
        return (
          <VpsImportedTransactionComponent
            key={adapterTransaction.id}
            vpsTransaction={transaction}
            workspace={workspace}
            projectId={projectId}
            active={active}
            onClick={onClick}
          />
        );
      } else if (adapterTransaction.adapterId === 'nordnet') {
        const transaction = adapterTransaction as NordnetTransactionState;
        return (
          <NordnetImportedTransactionComponent
            key={adapterTransaction.id}
            nordnetTransaction={transaction}
            workspace={workspace}
            projectId={projectId}
            active={active}
            onClick={onClick}
          />
        );
      }
    });
  }, [active, onClick, projectId, transactions, workspace]);

  const initiateTransactionElement = isShowing && (
    <InitiateTransactionComponent
      workspace={workspace}
      projectId={projectId}
      transactions={transactions}
      handleSuccess={handleSuccess}
    />
  );
  return (
    <Grid container>
      <Grid container item xs>
        {transactionElements}
      </Grid>
      <Grid container item justifyContent="flex-end" alignItems="center" xs={1}>
        <IconButton onClick={toggleIsShowing}>
          {isShowing ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </Grid>
      {initiateTransactionElement}
    </Grid>
  );
});
