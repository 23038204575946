import {
  initialPortfolioProjectsState,
  portfolioProjectsReducer,
} from './portfolio-projects/reducer';
import { combineReducers } from 'redux';
import {
  portfolioProjectRequestStatusReducer,
  initialPortfolioProjectRequestStatusState,
} from './portfolio-project-fetch/reducer';

export const initialPortfolioProjectState = {
  portfolioProjects: initialPortfolioProjectsState,
  portfolioProjectFetch: initialPortfolioProjectRequestStatusState,
};

export const portfolioProjectReducer = combineReducers({
  portfolioProjects: portfolioProjectsReducer,
  portfolioProjectFetch: portfolioProjectRequestStatusReducer,
});
