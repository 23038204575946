import * as uuid from 'uuid';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
import { getHoldingTypes } from '~src/utils/finance/transfer-to-holding-types';

import { TransferType } from '@pladdenico/models';

export const getHoldingConfigDividend = (holdingId?: string): HoldingConfig => {
  const transferType = TransferType.Dividend;
  return {
    id: uuid.v1(),
    transferType,
    component: {
      name: 'Dividend holding',
      deleteIcon: false,
      useValuation: true,
      possibleHoldingTypes: getHoldingTypes(transferType),
      possibleTransferTypes: [transferType],
    },
    holdingId,
  };
};
