import { handleWarrants } from '~src/data/store/modules/assets/warrants/handler';
import { warrantsActions } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchWarrants(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetWarrantsDocument;
    const variables: graphqlWorkspaceTypes.GetWarrantsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getWarrants) {
        return handleWarrants(workspaceId, dispatch, data.getWarrants);
      }
      return [];
    });
  };
}

export function createWarrants(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateWarrantInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateWarrantsDocument;
    const variables: graphqlWorkspaceTypes.CreateWarrantsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createWarrants) {
        return handleWarrants(workspaceId, dispatch, data.createWarrants);
      }
      return [];
    });
  };
}

export function updateWarrants(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateWarrantInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateWarrantsDocument;
    const variables: graphqlWorkspaceTypes.UpdateWarrantsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateWarrants) {
        return handleWarrants(workspaceId, dispatch, data.updateWarrants);
      }
      return [];
    });
  };
}

export function upsertWarrants(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertWarrantInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertWarrantsDocument;
    const variables: graphqlWorkspaceTypes.UpsertWarrantsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertWarrants) {
        return handleWarrants(workspaceId, dispatch, data.upsertWarrants);
      }
      return [];
    });
  };
}

export function deleteWarrants(
  tenantId: string,
  workspaceId: string,
  warrants: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteWarrantsDocument;
    warrants.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteWarrantInputType[] = warrants.map(
      (warrant) => {
        return {
          id: warrant.id,
        };
      },
    );
    const variables: graphqlWorkspaceTypes.DeleteWarrantsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteWarrants) {
        const deletedWarrants = intersectionSorted(
          warrants,
          data.deleteWarrants,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(warrantsActions.removeManyElements(deletedWarrants));
      }
      return [];
    });
  };
}
