export const EntitySectors = {
  IT: 'IT',
  RealEstate: 'Real Estate',
  Cash: 'Cash',
  Energy: 'Energy',
  Diversified: 'Diversified',
  Finance: 'Finance',
  Seafood: 'Seafood',
  HealthCare: 'Healthcare',
  Equity: 'Equity',
  Insurance: 'Insurance',
  Telecom: 'Telecom',
  Hospitality: 'Hospitality',
} as const;

export type EntitySector = (typeof EntitySectors)[keyof typeof EntitySectors];
export function entitySectorToString(type: EntitySector) {
  if (type === EntitySectors.IT) {
    return 'IT';
  } else if (type === EntitySectors.RealEstate) {
    return 'Real Estate';
  } else if (type === EntitySectors.Cash) {
    return 'Cash';
  } else if (type === EntitySectors.Energy) {
    return 'Energy';
  } else if (type === EntitySectors.Diversified) {
    return 'Diversified';
  } else if (type === EntitySectors.Finance) {
    return 'Finance';
  } else if (type === EntitySectors.Seafood) {
    return 'Seafood';
  } else if (type === EntitySectors.HealthCare) {
    return 'HealthCare';
  } else if (type === EntitySectors.Equity) {
    return 'Equity';
  } else if (type === EntitySectors.Insurance) {
    return 'Insurance';
  } else if (type === EntitySectors.Telecom) {
    return 'Telecom';
  } else if (type === EntitySectors.Hospitality) {
    return 'Hospitality';
  }
  return 'undefined';
}
