import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FieldsComponent } from '~src/domain/workspace/components/entity/entity/forms/create/fields.component';
import { useCreateEntity } from '~src/hooks/fields/workspace/entity/use-create-entity';

import { Grid } from '@mui/material';

interface Props {
  handleValue: (t: EntityState) => void;
  workspace: WorkspaceState;
}

export const useEditDialog = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const { entityInput, setEntityInput, handleCreateEntity } = useCreateEntity(
    props.workspace,
  );

  const { handleValue } = props;

  const handleOnSave = React.useCallback(() => {
    return handleCreateEntity().then((entity) => {
      handleValue(entity);
      setOpen(false);
    });
  }, [handleCreateEntity, handleValue]);

  const dialogElement = React.useMemo(() => {
    return (
      <DialogComponent
        dialogProps={{ open, onClose: () => setOpen(false), fullWidth: true }}
      >
        <DialogContentComponent
          handleCancel={() => setOpen(false)}
          handleOnSave={handleOnSave}
          title="Create entity"
        >
          <Grid container justifyContent="space-between">
            {' '}
            <FieldsComponent
              entityInput={entityInput}
              setEntityInput={setEntityInput}
              workspace={props.workspace}
            />
          </Grid>
        </DialogContentComponent>
        {/* {getDialogContent(
          entityInput,
          setEntityInput,
          handleCreateEntity,
          callback,
          setOpen
        )} */}
      </DialogComponent>
    );
  }, [open, handleOnSave, entityInput, setEntityInput, props.workspace]);

  return { dialogElement, setOpen, setEntityInput };
};
