import { fundPositionPath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchFundPositions,
  handleDeleteFundPositions,
} from '~src/data/store/modules/holdings/fund/position/requests';
import { FundPositionState } from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToFundPositions(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  positions: FundPositionState[],
  subscriptions: Operation[],
) {
  positions.forEach((position) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: fundPositionPath(tenantId, workspaceId).objectPath(
              position.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchFundPositions(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to position', operation, position.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: fundPositionPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchFundPositions(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to position', operation, position.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: fundPositionPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(
                handleDeleteFundPositions(position.fundHoldingId, [res.id]),
              );
            },
          },
          () => {
            // console.log('subscribed to position', operation, position.id);
          },
        );
      }
    });
  });
}
