import {
  tenantBaseId,
  tenantRootPath,
} from '~src/navigation/paths/tenant/paths';

export const invitationsRootPath = (tenantId: string) => {
  return `${tenantRootPath(tenantId)}/invitations`;
};

export const invitationRootPath = (tenantId: string, invitationId: string) => {
  return `${invitationsRootPath(tenantId)}/${invitationId}`;
};

const invitationBaseId = ':invitationId';

export const tenantInvitationsBasePath = invitationsRootPath(tenantBaseId);
export const tenantInvitationBasePath = invitationRootPath(
  tenantBaseId,
  invitationBaseId,
);
