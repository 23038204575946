import React from 'react';
import { useDispatch } from 'react-redux';
import { FileDropzoneComponent } from '~src/components/utils/files/files-dropzone';
import { createFiles } from '~src/data/store/modules/file/file/requests';
import { updateEvents } from '~src/data/store/modules/workspaces/projects/events/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { AppDispatch } from '~src/store/store';

import { graphqlWorkspaceOperations } from '@pladdenico/portfolio-api';
import { MultipleRelationOperationType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  event: EventState;
}

const fileId = (tenantId: string, workspaceId: string) => {
  return `${tenantId}/${workspaceId}/documents/`;
};

export const EventFileDropzoneComponent = React.memo((props: Props) => {
  const { workspace, event } = props;
  const fileIdBase = React.useMemo(
    () => fileId(workspace.tenantId, workspace.id),
    [workspace.id, workspace.tenantId],
  );
  const dispatch = useDispatch<AppDispatch>();
  const fileCallback = React.useCallback(
    (
      id: string,
      name: string,
      description: string,
      url: string,
      type: string,
    ) => {
      return dispatch(
        createFiles(workspace.tenantId, workspace.id, [
          {
            id,
            key: url,
            name,
            type,
            description,
          },
        ]),
      ).then((files) => {
        const file = files[0];

        const fileId: graphqlWorkspaceOperations.MultipleRelationOperationStringInputType =
          {
            id: file.id,
            type: MultipleRelationOperationType.add,
          };
        const updatedEvent: graphqlWorkspaceOperations.UpdateEventInputType = {
          id: event.id,
          fileIds: [fileId],
        };
        return dispatch(
          updateEvents(workspace.tenantId, workspace.id, [updatedEvent]),
        ).then(() => files);
      });
    },
    [dispatch, event.id, workspace.id, workspace.tenantId],
  );
  return <FileDropzoneComponent baseId={fileIdBase} callback={fileCallback} />;
});
