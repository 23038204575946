import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  DashboardElementState,
  selectors,
} from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { ViewDataState } from '~src/data/store/reducers/reducers';

const getDashboardElementState = (state: ViewDataState) =>
  state.view.dashboard.dashboardElements;

export const getDashboardElementsByIds = createCachedSelector(
  (state: ViewDataState, _ids: string[] | undefined) =>
    getDashboardElementState(state),
  (_state: ViewDataState, ids: string[] | undefined) => ids,
  (dashboardElements, ids): DashboardElementState[] => {
    if (ids == null) {
      return [];
    }
    return selectors.selectElements(
      dashboardElements,
      ids.map((id) => {
        return { id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => `${ids ? JSON.stringify(ids) : undefined}`,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
