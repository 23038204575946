import React from 'react';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { Tab } from '~src/utils/interfaces/tab';

import { EntityTabsComponent } from './entity-tabs.component';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  entity: EntityState;
  tab: Tab<string>;
  tabs: Tab<string>[];
}

export const EntityTabComponent = React.memo((props: Props) => {
  return <EntityTabsComponent {...props} />;
});
