import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  BondTradeState,
  selectors as bondTradesSelectors,
  BaseBondTradeId,
} from '~src/data/store/reducers/holding/holding-types/bond/bond-trade/reducer';

const getBondTradeState = (state: WorkspaceDataState) =>
  state.holding.holding.bondHolding.bondTrades;

export const getBondTradesByBondHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getBondTradeState(state),
  (_state: WorkspaceDataState, bondHoldingId: string) => bondHoldingId,
  (bondTradeState, bondHoldingId): BondTradeState[] => {
    return (
      bondTradesSelectors.selectElementsByKey(bondTradeState, bondHoldingId) ??
      []
    );
  },
)({
  keySelector: (_state, bondHoldingId) => bondHoldingId,
  selectorCreator: createSelector,
});

export const getBondTrades = createSelector(
  (state: WorkspaceDataState) => getBondTradeState(state),
  (bondTrades) => {
    return bondTradesSelectors.selectAllElements(bondTrades);
  },
);

export const getBondTradeById = createCachedSelector(
  (state: WorkspaceDataState) => getBondTradeState(state),
  (_state: WorkspaceDataState, baseId: BaseBondTradeId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseBondTradeId) => baseId.bondHoldingId,
  (bondTrades, id, bondHoldingId): BondTradeState | undefined => {
    return bondTradesSelectors.selectElementByT(bondTrades, {
      id: id,
      bondHoldingId,
    });
  },
)({
  keySelector: (_state, bondHoldingId, bondTradeId) =>
    `${bondHoldingId}:${bondTradeId}`,
  selectorCreator: createSelector,
});

interface BondHoldingMultipleIds {
  bondHoldingId: string;
  ids: string[];
}

export const getBondTradesByIds = createCachedSelector(
  (state: WorkspaceDataState) => getBondTradeState(state),
  (_state: WorkspaceDataState, ids: BondHoldingMultipleIds) =>
    ids.bondHoldingId,
  (_state: WorkspaceDataState, ids: BondHoldingMultipleIds) => ids.ids,
  (bondTrades, bondHoldingId, ids): BondTradeState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        bondHoldingId,
      };
    });
    return bondTradesSelectors.selectElementsByTs(bondTrades, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.bondHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

interface BondTradeTransferId {
  bondHoldingId: string;
  transferId: string;
}

export const getBondTradeByTransferId = createCachedSelector(
  (state: WorkspaceDataState, baseId: BondTradeTransferId) =>
    getBondTradesByBondHoldingId(state, baseId.bondHoldingId),
  (_state: WorkspaceDataState, baseId: BondTradeTransferId) =>
    baseId.transferId,
  (bondTrades, transferId): BondTradeState | undefined => {
    return bondTrades.find((t) => t.transferId === transferId);
  },
)({
  keySelector: (_state, bondHoldingId, bondTradeId) =>
    `${bondHoldingId}:${bondTradeId}`,
  selectorCreator: createSelector,
});
