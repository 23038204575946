import React from 'react';
import { ElementBaseProps } from '~src/components/dashboard/element';
import { useElementTheme } from '~src/components/dashboard/use-element-theme';
import { UsageMode } from '~src/utils/interfaces/usage-mode';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Box, CircularProgress, colors, Fade, IconButton } from '@mui/material';
// import {
//   //  MuiPaper,
//   PaperProps as MuiPaperProps,
// } from '@mui/material/Paper';
import {
  //  styled,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';

export interface StyleConfig {
  active: boolean;
  inverted: boolean;
  hideOverflow: boolean;
  color?: string;
  backgroundColor?: string;
  padding?: number;
}
const defaultPadding = 0;

// interface PaperProps extends MuiPaperProps {
//   config: StyleConfig;
// }

// const Paper = styled(MuiPaper, {
//   shouldForwardProp: (prop) => prop !== 'config',
// })<PaperProps>(({ theme, config }) => ({
//   height: '100%',
//   backgroundColor: getBackgroundColor(config, theme),
//   overflow: 'auto',
//   outline: config.active ? 'solid' : 'none',
//   outlineColor: config.active ? '#555555' : 'none',
//   outlineWidth: 'thin',
// }));

function getBackgroundColor(config: StyleConfig, theme: Theme) {
  if (config.backgroundColor) {
    return config.backgroundColor;
  } else if (config.inverted) {
    return 'black';
  } else {
    return theme.palette.background.paper;
  }
}

export const ElementComponent = React.memo(
  ({
    id,
    inverted = false,
    active,
    loading = false,
    segment = true,
    children,
    openOptionsMenu,
    elementConfig: config,
    usageMode = UsageMode.edit,
  }: ElementBaseProps) => {
    const themeContext = useElementTheme(config?.format);
    const [hover, setHover] = React.useState(false);

    // const actionBox = useResizeDetector();

    const styledConfig = React.useMemo(() => {
      return {
        active,
        inverted,
        hideOverflow: config?.hideOverflow ?? false,
        padding: config?.format?.padding ?? defaultPadding,
      };
    }, [active, config?.format?.padding, config?.hideOverflow, inverted]);

    const optionsMenu = React.useMemo(
      () =>
        openOptionsMenu ? (
          <IconButton
            size={'small'}
            // ref={drag}
            onClick={openOptionsMenu}
            onTouchEnd={openOptionsMenu}
            sx={{
              visibility: hover || active ? 'visible' : 'hidden',
              color: config?.format?.color,
            }}
          >
            <MoreIcon />
          </IconButton>
        ) : undefined,
      [active, config?.format?.color, hover, openOptionsMenu],
    );

    const menu = React.useMemo(() => {
      if (usageMode === UsageMode.edit) {
        const top = config?.format?.padding ?? defaultPadding;
        const right = config?.format?.padding ?? defaultPadding;
        return (
          <Fade in={hover}>
            <Box
              sx={{
                position: 'absolute',
                color: colors.common.white,
                top: `${top}px`,
                right: `${right}px`,
                // visibility: hover || active ? 'visible' : 'hidden',
              }}
            >
              {optionsMenu}
            </Box>
          </Fade>
        );
      } else {
        return <></>;
      }
    }, [config?.format?.padding, hover, optionsMenu, usageMode]);

    const dragHandler = React.useMemo(() => {
      if (usageMode === UsageMode.edit) {
        const top = config?.format?.padding ?? defaultPadding;
        const left = config?.format?.padding ?? defaultPadding;
        return (
          <Fade in={hover}>
            <Box
              className="dashboard-element-drag-handle"
              sx={{
                position: 'absolute',
                top: `${top}px`,
                left: `${left}px`,
                // visibility: hover || active ? 'visible' : 'hidden',
              }}
            >
              <DragHandleIcon />
            </Box>
          </Fade>
        );
      }
      return undefined;
    }, [config?.format?.padding, hover, usageMode]);

    const contentInner = React.useMemo(() => {
      if (segment) {
        {
          /* <Paper elevation={0} variant="outlined" config={styledConfig} square> */
        }
        {
          /* </Paper> */
        }

        return (
          <Box
            sx={{
              height: '100%',
              overflow: 'auto',
              backgroundColor: (theme) =>
                getBackgroundColor(styledConfig, theme),
            }}
          >
            {children}
            {dragHandler}
          </Box>
        );
      } else {
        return (
          <Box>
            {dragHandler}
            {children}
          </Box>
        );
      }
    }, [segment, children, dragHandler, styledConfig]);

    const content = React.useMemo(() => {
      return (
        <Box
          key={id}
          sx={{ height: '100%' }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ThemeProvider theme={themeContext}>{contentInner}</ThemeProvider>
          {menu}
        </Box>
      );
    }, [contentInner, id, menu, themeContext]);

    if (loading) {
      return <CircularProgress />;
    }

    return content;
  },
);
