import { PortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs';
import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';

export const rootReportPath =
  '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/reporting/reports/:reportId';

export const reportsBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/reporting/reports`;
};

export const reportBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  reportId: string,
) => {
  return `${reportsBasePath(tenantId, workspaceId, projectId)}/${reportId}`;
};

export const reportTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  reportId: string,
  tab: PortfolioProjectReportTabs,
) => {
  return `${reportBasePath(tenantId, workspaceId, projectId, reportId)}/${tab}`;
};
