import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { defaultBarChartFormat } from '~src/components/irr/bar-chart-format';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectIrrComponent } from '~src/domain/workspace/components/project/holding/irr/project-irr.component';
import {
  IrrConfigData,
  useIrrMenu,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/irr/menu.component';
import { Config } from '~src/utils/interfaces/config';
import { Period } from '~src/utils/period/period';
import { PeriodType, PeriodTypes } from '~src/utils/period/period-type';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  config: Config;
  baseCurrency: CurrencyState;
  period: Period;
}

export const IrrElement = React.memo(
  ({
    workspace,
    project,
    holdings,
    config,
    baseCurrency,
    period,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useIrrMenu(element, remove, updated);
    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? '#000000',
          backgroundColor: element.config.backgroundColor ?? '#ffffff',
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    const irrConfigData = React.useMemo(
      () =>
        (element.config.data as IrrConfigData) ?? {
          barChartFormat: defaultBarChartFormat,
        },
      [element.config.data],
    );

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <ProjectIrrComponent
            periodType={(element.config.type as PeriodType) ?? PeriodTypes.Year}
            // filterKey={(element.config.pivotType as string) ?? 'category'}
            workspace={workspace}
            project={project}
            holdings={holdings}
            date={config.date}
            period={period}
            baseCurrency={baseCurrency}
            irrConfigData={irrConfigData}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
