import { parseAllocation } from '~src/data/store/modules/portfolios/allocations/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  allocationsActions,
  AllocationState,
} from '~src/data/store/reducers/portfolio/allocations/reducer';
import { Allocation } from '~src/services/graphql/user/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface PortfolioAllocationVisitor {
  visit(portfolioAllocation: PortfolioAllocationVisitable): AllocationState;
  post(): void;
}

export class PortfolioAllocationVisitable
  implements Visitable<PortfolioAllocationVisitor>
{
  constructor(
    private _portfolioId: string,
    private _portfolioAllocation: Allocation,
  ) {}
  public accept(visitor: PortfolioAllocationVisitor) {
    return visitor.visit(this);
  }

  public parse(): AllocationState {
    return parseAllocation(this._portfolioId, this._portfolioAllocation);
  }
}

export class PortfolioAllocationStateVisitor
  implements PortfolioAllocationVisitor
{
  private _portfolioAllocations: AllocationState[];
  constructor(private _dispatch: AppDispatch) {
    this._portfolioAllocations = [];
  }
  public visit(allocation: PortfolioAllocationVisitable): AllocationState {
    const allocationState = allocation.parse();
    this._portfolioAllocations.push(allocationState);
    return allocationState;
  }
  post() {
    this._dispatch(
      allocationsActions.upsertManyElements(this._portfolioAllocations),
    );
  }
}
