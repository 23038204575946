import React, { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  TopBarComponent,
  useToolbarStyles,
} from '~src/components/top-bar/top-bar.component';
import { TenantState } from '~src/data/store/reducers/tenant/tenants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TopBarDesktopComponent } from '~src/domain/workspace/components/top-bar/top-bar.desktop.component';
import { TopBarMobileComponent } from '~src/domain/workspace/components/top-bar/top-bar.mobile.component';
import {
  drawerWidth,
  WorkspaceDrawerComponent,
} from '~src/domain/workspace/components/drawer/workspace-drawer.component';
import { useDrawer } from '~src/utils/common/use-drawer';

import CssBaseline from '@mui/material/CssBaseline';
import { styled, Theme } from '@mui/material/styles';

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(() => ({
  //theme, open
  width: `calc(100% - ${drawerWidth}px)`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  // transition: theme.transitions.create('margin', {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  // marginLeft: `-${drawerWidth}px`,
  // ...(open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginLeft: 0,
  // }),
}));

// flexDirection: 'column',
// display: 'flex',
// minHeight: '100vh',

// padding: theme.spacing(3),

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: '100vw',
    display: 'flex',
    flex: 1,
    height: '100%',
  },
  content: {
    width: `calc(100% - ${drawerWidth}px)`,
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100vh',
    // padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...(theme.mixins.toolbar as CSSProperties),
  },
}));

interface Props extends React.PropsWithChildren<unknown> {
  tenant: TenantState;
  workspace: WorkspaceState;
}

export const MainComponent = React.memo((props: Props) => {
  const { tenant, workspace } = props;
  const { classes } = useStyles();
  const toolbarStyles = useToolbarStyles();
  const drawer = useDrawer();

  const desktopTopBar = React.useMemo(() => {
    return <TopBarDesktopComponent />;
  }, []);

  const mobileTopBar = React.useMemo(() => {
    return <TopBarMobileComponent />;
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBarComponent
        desktopTopBar={desktopTopBar}
        mobileTopBar={mobileTopBar}
      />
      {
        <WorkspaceDrawerComponent
          tenant={tenant}
          workspace={workspace}
          drawer={drawer}
        />
      }
      <Main open={drawer.open}>
        {/* <main className={classes.content}> */}
        <div className={toolbarStyles.classes.toolbar} />
        {props.children}
        {/* </main> */}
      </Main>
    </div>
  );
});
