import { FinanceApi } from '~src/services/request/finance-api';
import { InvestApi } from '~src/services/request/invest-api';
import { ObjStorageApi } from '~src/services/request/obj-storage-api';
import { PortfolioOptimizationApi } from '~src/services/request/portfolio-optimization-api';

export let apis: {
  invest: InvestApi;
  finance: FinanceApi;
  objStorage: ObjStorageApi;
  portfolioOptimization: PortfolioOptimizationApi;
};

export function initializeApis() {
  apis = {
    objStorage: new ObjStorageApi(),
    invest: new InvestApi(),
    finance: new FinanceApi(),
    portfolioOptimization: new PortfolioOptimizationApi(),
  };
  return apis;
}
