import * as React from 'react';

import { Alert, AlertTitle, TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { UserCreateInput } from '@pladdenico/models';

interface Props {
  userInput: UserCreateInput;
  setUserInput: (userInput: UserCreateInput) => void;
  signupError: any;
  setSignupError: (signupError: any) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    // width: 300,
    // flexBasis: 200,
  },
}));

export function SignupFieldsComponent(props: Props) {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof UserCreateInput) =>
    (event: React.ChangeEvent<{ value: unknown }>) => {
      props.setUserInput({ ...props.userInput, [name]: event.target.value });
    };

  const handleDismissFailedToSignup = () => {
    props.setSignupError(undefined);
  };

  let failedToSignupElement: React.ReactElement<unknown> | undefined;
  if (props.signupError) {
    failedToSignupElement = (
      <Alert severity="error" onClose={handleDismissFailedToSignup}>
        <AlertTitle>Signup</AlertTitle>
        Failed to signup: {props.signupError.message}
      </Alert>
    );
  }

  return (
    <>
      {failedToSignupElement}
      <TextField
        autoComplete="off"
        margin="normal"
        className={classes.textField}
        required
        id="input-name"
        value={props.userInput.name}
        label="Username"
        type="text"
        autoFocus
        onChange={handleChange('name')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="input-email"
        value={props.userInput.email}
        label="Email"
        type="text"
        onChange={handleChange('email')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="password"
        value={props.userInput.password}
        label="Password"
        type="password"
        onChange={handleChange('password')}
        autoComplete="new-password"
      />
      {/* <Button type="submit" color="primary">
        Save
      </Button> */}
    </>
  );
}
