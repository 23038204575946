import { parseFundPosition } from '~src/data/store/modules/holdings/fund/position/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  fundPositionsActions,
  FundPositionState,
} from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import { FundPosition } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';
import { subscribeToFundPositions } from '~src/data/store/modules/holdings/fund/position/subscription';

export interface FundPositionVisitor {
  visit(position: FundPositionVisitable): FundPositionState;
  post(): void;
}

export class FundPositionVisitable implements Visitable<FundPositionVisitor> {
  constructor(private _position: FundPosition) {}
  public accept(visitor: FundPositionVisitor) {
    return visitor.visit(this);
  }

  public parse(): FundPositionState {
    return parseFundPosition(this._position);
  }
}

export class FundPositionStateVisitor implements FundPositionVisitor {
  private _fundPositions: FundPositionState[];
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._fundPositions = [];
  }

  public visit(position: FundPositionVisitable): FundPositionState {
    // this._fundPositions.push(position.parse());
    const positionState = position.parse();
    this._fundPositions.push(positionState);
    return positionState;
  }

  post() {
    this._dispatch(
      fundPositionsActions.upsertManyElements(this._fundPositions, {
        shouldAutobatch: true,
      }),
    );
    subscribeToFundPositions(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._fundPositions,
      this._subscriptions,
    );
  }
}
