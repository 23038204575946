export enum TransactionType {
  Buy = 'buy',
  Cost = 'cost',
  Lend = 'lend',
  Sell = 'sell',
  Account = 'account',
  Repayment = 'Repayment',
  Borrow = 'Borrow',
  Interest = 'interest',
  Dividend = 'dividend',
  Custom = 'custom',

  // RevenueFee = 'revenueFee',
  // Provision = 'provision',
  // Cost = 'cost',
  // WrongPayment = 'wrongPayment',
}
