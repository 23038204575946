import { RecordState, strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BasePersonId {
  id: string;
  workspaceId: string;
}

export type PersonState = Omit<
  graphqlWorkspaceTypes.Person,
  'workspace' | 'addresses' | 'roles' | 'phones' | 'emails'
> & {
  workspaceId: string;
  tenantId: string;
};

export function personToPersonState(person: PersonState) {
  return person;
}

export const initialPeopleState: RecordState<PersonState, string>[] = [];

const selectKeyId = (person: BasePersonId) => person.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BasePersonId, b: BasePersonId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BasePersonId, PersonState, string>(
  initialPeopleState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const peopleSlice = createBaseRecordSlice('people', adapter);
export const peopleSelectors = adapter.selectors;

export const peopleReducer = peopleSlice.reducer;
export const peopleActions = peopleSlice.actions;
export const peopleSlices = peopleSlice.slices;
