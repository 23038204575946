import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';

export interface BaseBudgetId {
  scenarioId: string;
  id: string;
}

export type BudgetState = {
  id: string;
  name: string;
  scenarioId: string;

  holdingIds: string[];
  transactionIds: string[];
  transferIds: string[];
  valuationIds: string[];
};

export const initialBudgetsState = [];

const selectKeyId = (budget: BaseBudgetId) => budget.scenarioId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseBudgetId, b: BaseBudgetId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseBudgetId, BudgetState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const budgetsSlice = createBaseRecordSlice('scenario:budgets', adapter);
export const budgetsSelectors = adapter.selectors;

export const budgetsReducer = budgetsSlice.reducer;
export const budgetsActions = budgetsSlice.actions;
