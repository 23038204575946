import React from 'react';
import { ScenarioCardComponent } from '~src/domain/workspace/components/project/scenario/card/card.component';

import { List, ListItem, TablePagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableOperations } from '@pladdenico/table';
import { ScenarioData } from '~src/domain/workspace/components/project/scenario/scenarios.component';

interface Props {
  projectId: string;
  data: ScenarioData[];
}

const useStyles = makeStyles()({
  row: {
    // paddingTop: 5,
    // marginTop: 5,
    // marginBottom: 5,
    // paddingBottom: 5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // marginTop: theme.spacing(2),
  },
  pagination: {
    overflow: 'hidden',
  },
  listItem: {
    flex: 1,
  },
});

export const ScenarioCardsListComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { paginationPosition, updateRowsPerPage, updatePage } =
    TableOperations.usePagination(props.data, 10);

  const handleRowsPerPageChange = React.useCallback(
    (event: any) => {
      updateRowsPerPage(+event.target.value);
    },
    [updateRowsPerPage],
  );

  const handlePageChange = React.useCallback(
    (_event: any, page: number) => {
      updatePage(page);
    },
    [updatePage],
  );

  const listContent = props.data
    .slice(paginationPosition.startIdx, paginationPosition.endIdx)
    .map((scenarioData, index) => {
      return (
        <ListItem key={index} classes={{ root: classes.listItem }}>
          <ScenarioCardComponent
            projectId={props.projectId}
            scenario={scenarioData.scenario}
          />
        </ListItem>
      );
    });

  const pagination = (
    <div className={classes.pagination}>
      <TablePagination
        component="div"
        count={props.data.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={paginationPosition.page}
        rowsPerPage={paginationPosition.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );

  return (
    <div className={classes.content}>
      <List>{listContent}</List>
      {pagination}
    </div>
  );
});
