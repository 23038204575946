import { User } from '@pladdenico/models';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { getWorkspaceRolesByWorkspaceId } from '~src/data/store/selectors/workspace/workspace-roles/selectors';

import { WorkspaceDataState } from '../../../reducers/reducers';
import { workspaceInvitationSelectors } from '../../../reducers/workspace/workspace-invitations/reducer';
import { createSelector } from 'reselect';

const getWorkspaceInvitationsState = (state: WorkspaceDataState) => {
  return state.workspace.workspaceInvitations;
};

export const getWorkspaceInvitationsByWorkspaceId = createCachedSelector(
  getWorkspaceInvitationsState,
  (state: WorkspaceDataState, id: string) =>
    getWorkspaceRolesByWorkspaceId(state, id),
  (allInvitations, roles) => {
    return allInvitations.filter((invitation) => {
      return roles.some((role) => role.id === invitation.workspaceRoleId);
    });
  },
)({
  keySelector: (_state, user) => user,
  cacheObject: new FlatMapCache(),
  selectorCreator: createSelector,
});

export const selectWorkspaceInvitationsByUser = createCachedSelector(
  getWorkspaceInvitationsState,
  (_workspaceInvitationsState: WorkspaceDataState, user: User | undefined) =>
    user,
  (workspaceInvitationsState, user) => {
    if (user) {
      const invitations = workspaceInvitationSelectors.selectAllElements(
        workspaceInvitationsState,
      );
      return invitations.filter((invitation) => {
        return invitation.email === user.email;
      });
    }
    return [];
  },
)({
  keySelector: (_state, user) => user?.id ?? '',
  cacheObject: new FlatMapCache(),
  selectorCreator: createSelector,
});

export const getWorkspaceInvitationById = createCachedSelector(
  getWorkspaceInvitationsState,
  (_workspaceInvitationsState: WorkspaceDataState, id: string) => id,
  (workspaceInvitationsState, id) => {
    const invitation = workspaceInvitationSelectors.selectElement(
      workspaceInvitationsState,
      { id },
    );
    return invitation;
  },
)({
  keySelector: (_state, user) => user,
  cacheObject: new FlatMapCache(),
  selectorCreator: createSelector,
});
