import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Typography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
// import { Property } from 'csstype';

export interface StyleConfig {
  containerSize: number | undefined;
}
interface TypographyProps extends MuiTypographyProps {
  config: StyleConfig;
}

// const isNumber = (
//   value: Property.FontSize<string | number> | undefined
// ): value is number => {
//   return typeof value === 'number';
// };

// const isString = (
//   value: Property.FontSize<string | number> | undefined
// ): value is string => {
//   return typeof value === 'string';
// };

// const getFontSize = (containerSize: number | undefined, theme: Theme) => {
//   const fontSize = theme.typography.body1.fontSize;
//   console.log(theme.typography.body1);
//   if (containerSize && isNumber(fontSize)) {
//     return Math.min(fontSize, containerSize / 16);
//   } else if (containerSize && isString(fontSize)) {
//     return Math.min(fontSize, containerSize / 16);
//   } else if (containerSize) {
//     return containerSize / 16;
//   }
//   return fontSize;
// };

export const StatisticLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'config',
})<TypographyProps>(() => ({
  // theme, config
  // fontSize: getFontSize(config.containerSize, theme),
}));

export const useStyles = makeStyles()((_theme: Theme) => ({
  statistic: {
    // padding: theme.spacing(1),
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  statisticLabel: {
    textTransform: 'uppercase',
    // fontSize: '1vw',
  },
}));
