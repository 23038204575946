import React from 'react';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastElementBase } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-element-state';

interface Props {
  forecastElement: FlowElementState;
  setForecastElement: (element: ForecastElementBase) => void;
}

export const EditForecastFlowComponent = React.memo((_props: Props) => {
  return <></>;
});
