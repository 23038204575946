import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectReportById } from '~src/data/store/selectors/workspace/projects/report/project-report/selectors';
import { ProjectReportSettingsComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/project-report-settings.component';
import { ProjectReportSharingComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/project-report-sharing.component';
import { ProjectReportEditorComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/report-editor.component';
import { ProjectReportViewerComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/report-viewer.component';
import { PortfolioProjectReportTabs } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/tabs/tabs';

import { Theme } from '@mui/material';

const useStyles = makeStyles()((_theme: Theme) => ({
  root: {
    position: 'relative',
    // top: 52,
    flex: 1,
    flexDirection: 'column',
  },
}));

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  tabName: PortfolioProjectReportTabs;
}

export const ProjectReportTabComponent = React.memo((props: Props) => {
  const { project } = props;
  const { reportId } = useParams<{ reportId: string }>();
  const projectReport = useSelector((state: RootState) => {
    if (reportId != null) {
      return getProjectReportById(selectWorkspaceData(state), {
        id: reportId,
        projectId: project.id,
      });
    }
  });

  if (!projectReport) {
    return <div>Did not find projectReport</div>;
  }

  return (
    <ProjectReportTabComponentWithProjectReport
      projectReport={projectReport}
      {...props}
    />
  );
});

interface PropsWithProjectReport extends Props {
  projectReport: ProjectReportState;
}

export const ProjectReportTabComponentWithProjectReport = React.memo(
  (props: PropsWithProjectReport) => {
    const {
      workspace,
      project,
      portfolio,
      portfolioProject,
      projectReport,
      tabName,
    } = props;
    const { classes } = useStyles();

    // const {
    //   button: configButton,
    //   open: configOpen,
    //   toggle: toggleConfig,
    // } = useConfigButton();

    const tab = React.useMemo(() => {
      if (tabName === PortfolioProjectReportTabs.Editor) {
        return (
          <ProjectReportEditorComponent
            workspace={workspace}
            project={project}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            projectReport={projectReport}
          />
        );
      } else if (tabName === PortfolioProjectReportTabs.Viewer) {
        return (
          <ProjectReportViewerComponent
            workspace={workspace}
            project={project}
            portfolioProject={portfolioProject}
            portfolio={portfolio}
            projectReport={projectReport}
          />
        );
      } else if (tabName === PortfolioProjectReportTabs.Sharing) {
        return (
          <ProjectReportSharingComponent
            workspace={workspace}
            projectId={project.id}
            projectReport={projectReport}
          />
        );
      } else if (tabName === PortfolioProjectReportTabs.Settings) {
        return (
          <ProjectReportSettingsComponent
            workspace={workspace}
            projectId={project.id}
            projectReport={projectReport}
          />
        );
      }
      return undefined;
    }, [
      tabName,
      workspace,
      project,
      portfolioProject,
      portfolio,
      projectReport,
    ]);

    return (
      <>
        {/* <DashboardMenuDiv
          config={{ open: configOpen, alignSelf: 'flex-start' }}
        >
          <Box sx={{ mt: 1, ml: 1 }}>{configButton}</Box>
        </DashboardMenuDiv> */}
        <div className={classes.root}>
          {tab}
          {/* <Drawer anchor="right" open={configOpen} onClose={toggleConfig}>
            <PortfolioConfigComponent
              workspace={workspace}
              portfolioProject={portfolioProject}
              portfolio={portfolio}
              portfolioConfig={undefined}
              dashboardConfig={undefined}
            />
          </Drawer> */}
        </div>
      </>
    );
  },
);
