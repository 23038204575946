import { handleFunds } from '~src/data/store/modules/assets/funds/handler';
import { fundsActions } from '~src/data/store/reducers/asset/asset-types/funds/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchFunds(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetFundsDocument;
    const variables: graphqlWorkspaceTypes.GetFundsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getFunds) {
        return handleFunds(workspaceId, dispatch, data.getFunds);
      }
      return [];
    });
  };
}

export function createFunds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateFundInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateFundsDocument;
    const variables: graphqlWorkspaceTypes.CreateFundsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createFunds) {
        return handleFunds(workspaceId, dispatch, data.createFunds);
      }
      return [];
    });
  };
}

export function updateFunds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateFundInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateFundsDocument;
    const variables: graphqlWorkspaceTypes.UpdateFundsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateFunds) {
        return handleFunds(workspaceId, dispatch, data.updateFunds);
      }
      return [];
    });
  };
}

export function upsertFunds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertFundInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertFundsDocument;
    const variables: graphqlWorkspaceTypes.UpsertFundsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertFunds) {
        return handleFunds(workspaceId, dispatch, data.upsertFunds);
      }
      return [];
    });
  };
}

export function deleteFunds(
  tenantId: string,
  workspaceId: string,
  funds: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteFundsDocument;
    funds.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteFundInputType[] = funds.map(
      (fund) => {
        return {
          id: fund.id,
        };
      },
    );
    const variables: graphqlWorkspaceTypes.DeleteFundsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteFunds) {
        const deletedFunds = intersectionSorted(
          funds,
          data.deleteFunds,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(fundsActions.removeManyElements(deletedFunds));
      }
      return [];
    });
  };
}
