import 'reactflow/dist/style.css';
import '../../index.css';

import React from 'react';
import { useSelector } from 'react-redux';
import { Node } from 'reactflow';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getVariablesByScenarioId } from '~src/data/store/selectors/workspace/projects/scenario/variables/selectors';
import { OnNodeDataChanges } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/flow-store';
import { InputNodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { useHandler } from '~src/hooks/utils/use-handler';

import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { SelectVariableForecastComponent } from './select-variable-forecast-component';

interface Props {
  budget: Budget;
  scenario: Scenario;
  node: Node<InputNodeData>;
  onNodesDataChange: OnNodeDataChanges;
}

export const InputNodeConfig = (props: Props) => {
  const { budget, scenario, node, onNodesDataChange } = props;

  const updateData = React.useCallback(
    (data: InputNodeData) => {
      onNodesDataChange([
        {
          change: {
            id: node.id,
            data,
          },
        },
      ]);
    },
    [node, onNodesDataChange],
  );
  const { handleEvent } = useHandler(node.data, updateData);
  const variables = useSelector((state: RootState) =>
    getVariablesByScenarioId(selectWorkspaceData(state), scenario.id),
  );
  const variableItems = React.useMemo(() => {
    return variables?.map((variable) => {
      return (
        <MenuItem key={variable.id} value={variable.id}>
          {variable.name}
        </MenuItem>
      );
    });
  }, [variables]);

  const variableElement = React.useMemo(
    () => (
      <FormControl fullWidth margin="normal">
        Variable
        <Select
          // defaultValue="number"
          label="variable"
          value={node.data.variableId ?? ''}
          onChange={handleEvent('variableId')}
        >
          {variableItems}
        </Select>
      </FormControl>
    ),
    [handleEvent, node.data.variableId, variableItems],
  );

  const nodeConfigElement = React.useMemo(() => {
    return (
      <div className="updatenode__controls">
        <TextField
          margin="normal"
          label="Label"
          variant="outlined"
          value={node.data.label}
          onChange={(evt) => {
            const data = {
              ...node.data,
              label: evt.target.value,
            };
            updateData(data);
          }}
        />
        <Divider />
        {variableElement}
        {node.data.variableId != null && (
          <>
            <SelectVariableForecastComponent
              scenario={scenario}
              budget={budget}
              variableId={node.data.variableId}
            />
          </>
        )}
      </div>
    );
  }, [budget, node.data, scenario, updateData, variableElement]);

  return nodeConfigElement;
};
