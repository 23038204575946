import * as React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import {
  FormContext,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

interface Props {
  index: number;
  control: Control<FormValues, FormContext>;
  setValue: UseFormSetValue<FormValues>;
  editAll: boolean;
  setEditAll: (editAll: boolean) => void;
  deleteHolding: ((index: number) => void) | undefined;
}

export const HoldingSubFormMenuComponent = React.memo((props: Props) => {
  const {
    handleClose: handleCloseMenu,
    handleOpen: handleOpenMenu,
    isOpen: isOpenMenu,
    anchorEl: anchorElMenu,
  } = useMenu();

  const { index, control, editAll, setEditAll, deleteHolding } = props;

  const deleteElement = React.useMemo(
    () =>
      deleteHolding ? (
        <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton>
      ) : undefined,
    [deleteHolding],
  );

  const menu = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorElMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpenMenu}
        onClose={handleCloseMenu}
      >
        <Controller
          name={`holdings.${index}.config.useValuation`}
          rules={{ required: false }}
          control={control}
          // shouldUnregister={true}
          render={({ field }) => (
            <MenuItem>
              <Checkbox checked={field.value} {...field} />
              <ListItemText primary="Use valuation" />
            </MenuItem>
          )}
        />
        <MenuItem>
          <Checkbox
            checked={editAll}
            value={editAll}
            onClick={() => setEditAll(!editAll)}
          />
          <ListItemText primary="Edit all fields" />
        </MenuItem>
        <MenuItem onClick={() => deleteHolding && deleteHolding(index)}>
          {deleteElement}
          <ListItemText primary="Remove from transaction" />
        </MenuItem>
      </Menu>
    ),
    [
      anchorElMenu,
      control,
      deleteElement,
      deleteHolding,
      editAll,
      handleCloseMenu,
      index,
      isOpenMenu,
      setEditAll,
    ],
  );
  return (
    <>
      <IconButton onClick={handleOpenMenu} color="inherit">
        <MoreIcon />
      </IconButton>
      {menu}
    </>
  );
});
