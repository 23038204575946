import { combineReducers } from 'redux';

import {
  currenciesReducer,
  initialState as initialCurrencyState,
} from './currencies/reducer';
import {
  initialSelectedCurrencyState,
  selectedCurrenciesReducer,
} from './selected/reducer';

export const currencyInitialState = {
  currencies: initialCurrencyState,
  selected: initialSelectedCurrencyState,
};

export const currencyReducer = combineReducers({
  currencies: currenciesReducer,
  selected: selectedCurrenciesReducer,
});
