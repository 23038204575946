import { HoldingType, TransferType } from '@pladdenico/models';
import { holdingTypeToTransferTypes } from '~src/utils/finance/holding-to-transfer-types';

const transferTypeToHoldingTypes = new Map<TransferType, Set<HoldingType>>();
for (const entry of holdingTypeToTransferTypes.entries()) {
  const holdingType = entry[0];
  const transferTypes = entry[1];
  for (const transferType of transferTypes) {
    let holdingTypes = transferTypeToHoldingTypes.get(transferType);
    if (!holdingTypes) {
      holdingTypes = new Set<HoldingType>();
    }
    holdingTypes.add(holdingType);
    transferTypeToHoldingTypes.set(transferType, holdingTypes);
  }
}

export const getHoldingTypes = (transferType: TransferType) => {
  const holdingTypes = transferTypeToHoldingTypes.get(transferType);
  return holdingTypes ? Array.from(holdingTypes) : [];
  // return holdingTypes ?? new Set<HoldingType>();
};
