// import { PersonType } from '@pladdenico/models';
import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertPeople } from '~src/data/store/modules/people/people/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'people';

export interface Person {
  id: string;
  name?: Maybe<string>;
  description?: Maybe<string>;
}

const header: Array<keyof Person> = ['id', 'name', 'description'];

export const personExportHandler = (data: Person[]): ExportHandler<Person> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const personImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Person> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertPeople(workspace.tenantId, workspace.id, ts)),
  };
};
