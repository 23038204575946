import { combineReducers } from 'redux';

import {
  initialState as initialRegionState,
  regionsReducer,
} from './regions/reducer';

export const commonInitialState = {
  regions: initialRegionState,
};

export const commonReducer = combineReducers({
  regions: regionsReducer,
});
