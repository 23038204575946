import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseValuationId,
  valuationsSelectors,
  ValuationState,
} from '~src/data/store/reducers/workspace/projects/scenario/valuations/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

const selectValuationState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.valuations;

export const getValuations = createSelector(
  (state: WorkspaceDataState) => selectValuationState(state),
  (valuations): ValuationState[] => {
    return valuationsSelectors.selectAllElements(valuations);
  },
);

export const getValuationById = createCachedSelector(
  (state: WorkspaceDataState) => selectValuationState(state),
  (_state: WorkspaceDataState, id: BaseValuationId) => id,
  (valuations, id): ValuationState | undefined => {
    return valuationsSelectors.selectElementByT(valuations, id);
  },
)({
  keySelector: (_state, id) => `${id.id}-${id.holdingId}`,
  selectorCreator: createSelector,
});

export const getValuationsByBudgetId = createCachedSelector(
  (state: WorkspaceDataState) => selectValuationState(state),
  (_state: WorkspaceDataState, budgetId: string) => budgetId,
  (valuations, budgetId): ValuationState[] | undefined => {
    return valuationsSelectors.selectElementsByKey(valuations, budgetId);
  },
)({
  keySelector: (_state, budgetId) => budgetId,
  selectorCreator: createSelector,
});

interface BudgetIdHoldingIds {
  budgetId: string;
  holdingIds: string[];
}

export const getValuationsByBudgetIdAndHoldingIds = createCachedSelector(
  (state: WorkspaceDataState, id: BudgetIdHoldingIds) =>
    getValuationsByBudgetId(state, id.budgetId),
  (_state: WorkspaceDataState, id: BudgetIdHoldingIds) => id.holdingIds,
  (valuations, holdingIds): ValuationState[] | undefined => {
    if (valuations == null) {
      return [];
    }
    return valuations.filter((valuation) =>
      holdingIds.some((id) => id === valuation.holdingId),
    );
  },
)({
  keySelector: (_state, id) =>
    `${id.budgetId}-${JSON.stringify(id.holdingIds)}`,
  selectorCreator: createSelector,
});
