import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingCommitmentsComponent } from '~src/domain/workspace/components/project/commitment/holding-commitments.component';
import { HoldingComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding.component';
import { HoldingTabs } from '~src/domain/workspace/components/project/portfolio/holdings/tab/holding-tabs';
import { HoldingTransfersComponent } from '~src/domain/workspace/components/project/transfer/holding-transfers.component';
import { HoldingValuationsComponent } from '~src/domain/workspace/components/project/valuation/holding-valuations.component';

export interface PropsGenericTab {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  project: ProjectState;
  holding: HoldingState;
  tabName: string;
}

export const useGenericTab = (props: PropsGenericTab) => {
  let content = undefined;
  if (props.tabName === HoldingTabs.Dashboard) {
    content = (
      <HoldingComponent
        holding={props.holding}
        project={props.project}
        workspace={props.workspace}
      />
    );
  } else if (props.tabName === HoldingTabs.Valuations) {
    content = (
      <HoldingValuationsComponent
        workspace={props.workspace}
        projectId={props.portfolioProject.projectId}
        holding={props.holding}
      />
    );
    // } else if (props.tabName === HoldingTabs.Transactions) {
    //   content = (
    //     <HoldingTransactionsComponent
    //       workspace={props.workspace}
    //       project={props.project}
    //       holding={props.holding}
    //     />
    //   );
  } else if (props.tabName === HoldingTabs.Transfers) {
    content = (
      <HoldingTransfersComponent
        workspace={props.workspace}
        project={props.project}
        holding={props.holding}
      />
    );
  } else if (props.tabName === HoldingTabs.Commitment) {
    content = (
      <HoldingCommitmentsComponent
        workspace={props.workspace}
        project={props.project}
        holding={props.holding}
      />
    );
    // } else if (props.tabName === HoldingTabs.Scenario) {
    //   content = (
    //     <Switch>
    //       <Route
    //         path="/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/scenarios"
    //         exact={true}
    //       >
    //         <ScenariosComponent
    //           workspace={props.workspace}
    //           projectId={props.portfolioProject.projectId}
    //           holding={props.holding}
    //         />
    //       </Route>
    //       <Route
    //         path={
    //           '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/holdings/:holdingId/:tabId/:scenarioId'
    //         }
    //       >
    //         <HoldingScenarioComponent
    //           workspace={props.workspace}
    //           project={props.project}
    //           holding={props.holding}
    //         />
    //       </Route>
    //     </Switch>
    //   );
  }
  return content;
};
