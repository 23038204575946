import { compact } from 'lodash';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { Portfolio } from '~src/services/graphql/workspace/client/graphql';

import { strcmp } from '@pladdenico/common';

export function parsePortfolio(
  workspaceId: string,
  portfolio: Portfolio,
): PortfolioState {
  return {
    id: portfolio.id,
    name: portfolio?.name,
    description: portfolio?.description,
    workspaceId,
    holdingIds: portfolio.holdings
      ? compact(
          portfolio.holdings.map((h) => {
            if (h.holding) {
              return h.holding.id;
            }
            return undefined;
          }),
        ).sort((a, b) => strcmp(a, b))
      : [],
    parentPortfolioId: portfolio.parentPortfolio
      ? portfolio.parentPortfolio.id
      : undefined,
  };
}
