import {
  CurrencyStateVisitor,
  CurrencyVisitable,
} from '~src/data/store/visitors/finance/currency-visitor';
import { AppDispatch } from '~src/store/store';

import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';

export function handleCurrencies(
  dispatch: AppDispatch,
  outputs: graphqlUserTypes.Currency[],
) {
  const currencyVisitor = new CurrencyStateVisitor(dispatch);
  outputs.forEach((currency) => {
    const assetVisitable = new CurrencyVisitable(currency);
    assetVisitable.accept(currencyVisitor);
  });
  currencyVisitor.post();
  return outputs;
}
