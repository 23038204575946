import { dashboardElementsActions } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleDashboardElements } from './handler';

export function fetchDashboardElements(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetDashboardElementsDocument;
    const variables: graphqlWorkspaceTypes.GetDashboardElementsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getDashboardElements) {
        return handleDashboardElements(
          dispatch,
          tenantId,
          workspaceId,
          data.getDashboardElements,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createDashboardElements(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateDashboardElementInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateDashboardElementsDocument;
    const variables: graphqlWorkspaceTypes.CreateDashboardElementsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createDashboardElements) {
        return handleDashboardElements(
          dispatch,
          tenantId,
          workspaceId,
          data.createDashboardElements,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function updateDashboardElements(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateDashboardElementsDocument;
    const variables: graphqlWorkspaceTypes.UpdateDashboardElementsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateDashboardElements) {
        return handleDashboardElements(
          dispatch,
          tenantId,
          workspaceId,
          data.updateDashboardElements,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function upsertDashboardElements(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertDashboardElementInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertDashboardElementsDocument;
    const variables: graphqlWorkspaceTypes.UpsertDashboardElementsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertDashboardElements) {
        return handleDashboardElements(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertDashboardElements,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function deleteDashboardElements(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteDashboardElementsDocument;
    const variables: graphqlWorkspaceTypes.DeleteDashboardElementsMutationVariables =
      {
        inputs: ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteDashboardElements) {
        return dispatch(
          dashboardElementsActions.removeMany(
            data.deleteDashboardElements.map((p) => {
              return {
                id: p,
              };
            }),
          ),
        ).payload;
      }
      return [];
    });
  };
}
