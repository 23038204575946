import { ProjectType } from '@pladdenico/models';
import { Control } from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectInputType } from '~src/domain/workspace/components/project/create.component';
import { useProjectTypeField } from '~src/domain/workspace/components/project/form/use-type-field';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useProjectFields = (
  _workspace: WorkspaceState,
  control: Control<ProjectInputType, any>,
) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const typeField = useProjectTypeField(
    control,
    'type',
    [ProjectType.Fund, ProjectType.Portfolio, ProjectType.PrivateEquityFund],
    ProjectType.Unknown,
  );
  return {
    nameField,
    typeField,
  };
};
