import * as uuid from 'uuid';
import { handleFavoriteHoldings } from '~src/data/store/modules/holdings/favorite/handler';
import { favoriteHoldingsActions } from '~src/data/store/reducers/holding/favorite-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchFavoriteHoldings(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetFavoriteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetFavoriteHoldingsQueryVariables = {
      projectId,
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getFavoriteHoldings) {
        return handleFavoriteHoldings(
          dispatch,
          projectId,
          data.getFavoriteHoldings,
        );
      }
      return [];
    });
  };
}

export function createFavoriteHoldings(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  inputs: graphqlWorkspaceTypes.CreateFavoriteHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateFavoriteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateFavoriteHoldingsMutationVariables =
      {
        projectId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createFavoriteHoldings) {
        return handleFavoriteHoldings(
          dispatch,
          projectId,
          data.createFavoriteHoldings,
        );
      }
      return [];
    });
  };
}

export function updateFavoriteHoldings(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  inputs: graphqlWorkspaceTypes.UpdateFavoriteHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateFavoriteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateFavoriteHoldingsMutationVariables =
      {
        projectId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateFavoriteHoldings) {
        return handleFavoriteHoldings(
          dispatch,
          projectId,
          data.updateFavoriteHoldings,
        );
      }
      return [];
    });
  };
}

export function upsertFavoriteHoldings(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  inputs: graphqlWorkspaceTypes.UpsertFavoriteHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertFavoriteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertFavoriteHoldingsMutationVariables =
      {
        projectId,
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertFavoriteHoldings) {
        return handleFavoriteHoldings(
          dispatch,
          projectId,
          data.upsertFavoriteHoldings,
        );
      }
      return [];
    });
  };
}

export function deleteFavoriteHoldings(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingIds: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteFavoriteHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteFavoriteHoldingsMutationVariables =
      {
        projectId,
        inputs: holdingIds.map((holdingId) => {
          return { holdingId };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteFavoriteHoldings) {
        dispatch(
          favoriteHoldingsActions.removeManyElements(
            data.deleteFavoriteHoldings.map((p) => {
              return {
                projectId,
                holdingId: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}

export function setFavoriteHolding(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) {
  const input: graphqlWorkspaceTypes.CreateFavoriteHoldingInputType = {
    id: uuid.v1(),
    holdingId,
    // projectId,
  };
  return createFavoriteHoldings(tenantId, workspaceId, projectId, [input]);
  // if (favoriteHoldings) {
  //   callback(favoriteHoldings[0].id, error);
  // } else {
  //   callback(undefined, error);
  // }
}

export function unsetFavoriteHolding(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  holdingId: string,
) {
  return deleteFavoriteHoldings(tenantId, workspaceId, projectId, [holdingId]);
  // (_numberOfDeletes, error) => {
  //   if (!error) {
  //     callback(props.id);
  //   } else {
  //     callback(undefined, error);
  //   }
  // }
}
