import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseBondTradeId {
  bondHoldingId: string;
  id: string;
}

export type BondTradeState = Omit<
  graphqlWorkspaceTypes.BondTrade,
  'bondHolding' | 'transfer' | 'position'
> & {
  bondHoldingId: string;
  transferId: string;
  positionId: string;
};

export const initialBondTradeState = [];

const selectKeyId = (bondTrade: BaseBondTradeId) => bondTrade.bondHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseBondTradeId, b: BaseBondTradeId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseBondTradeId,
  BondTradeState,
  string
>(initialBondTradeState, selectKeyId, keyComparator, tComparator);

export const bondTradesSlice = createBaseRecordSlice('bondTrades', adapter);
export const selectors = adapter.selectors;

export const bondTradesReducer = bondTradesSlice.reducer;
export const bondTradesActions = bondTradesSlice.actions;
