import * as React from 'react';
import { useParams } from 'react-router-dom';
import { AssetComponent } from '~src/domain/workspace/components/asset/asset.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

interface Props {
  workspace: WorkspaceState;
}

export const AssetRoot = React.memo((props: Props) => {
  const { assetId } = useParams<{ assetId: string }>();
  if (assetId) {
    return <AssetComponent workspace={props.workspace} assetId={assetId} />;
  }
  return <div>Not a valid asset Id</div>;
});
