import { upsertCryptoHoldings } from '~src/data/store/modules/holdings/crypto/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'cryptoHoldings';

export interface CryptoHolding {
  id: string;
  holdingId: string;
  cryptoId: string;
}

const header: Array<keyof CryptoHolding> = ['id', 'holdingId', 'cryptoId'];

export const cryptoHoldingExportHandler = (
  data: CryptoHolding[],
): ExportHandler<CryptoHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const cryptoHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<CryptoHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertCryptoHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
