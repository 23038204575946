import {
  WarrantStateVisitor,
  WarrantVisitable,
} from '~src/data/store/visitors/asset/types/warrant/warrant-visitor';
import { Warrant } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handleWarrants(
  workspaceId: string,
  dispatch: AppDispatch,
  warrants: Warrant[],
) {
  const warrantVisitor = new WarrantStateVisitor(dispatch);
  const res = warrants.map((warrant) => {
    const assetVisitable = new WarrantVisitable(workspaceId, warrant);
    return assetVisitable.accept(warrantVisitor);
  });
  warrantVisitor.post();
  return res;
}
