import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import {
  loanHoldingsSelectors,
  LoanHoldingState,
} from '../../../../../reducers/holding/holding-types/loan/loan-holding/reducer';

const getLoanHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.loanHolding.loanHoldings;

export const getLoanHoldingByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getLoanHoldingState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (loanHoldingState, holdingId): LoanHoldingState | undefined => {
    return loanHoldingsSelectors.selectElementByIdx(
      loanHoldingState,
      holdingId,
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getLoanHoldings = createSelector(
  (state: WorkspaceDataState) => getLoanHoldingState(state),
  (loanHoldings) => {
    return loanHoldingsSelectors.selectAllElements(loanHoldings);
  },
);

export const getLoanHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getLoanHoldingState(state),
  (_state: WorkspaceDataState, id: string) => id,
  (loanHoldings, id): LoanHoldingState | undefined => {
    return loanHoldingsSelectors.selectElementByI(loanHoldings, id);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

export const getLoanHoldingsByLoanId = createCachedSelector(
  (state: WorkspaceDataState) => getLoanHoldings(state),
  (_state: WorkspaceDataState, loanId: string) => loanId,
  (loanHoldings, loanId): LoanHoldingState[] => {
    return loanHoldings.filter((h) => h.loanId === loanId);
  },
)({
  keySelector: (_state, loanId) => `${loanId}`,
  selectorCreator: createSelector,
});
