import { strcmp } from '@pladdenico/common';
import { RequestStatus } from '~src/services/request/request-status';
import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

export interface RequestStatusId {
  id: string;
}

export type PortoflioProjectRequestStatusState = RequestStatus;

export function portfolioProjectRequestStatusToPortoflioProjectRequestStatusState(
  portfolioProjectRequestStatus: PortoflioProjectRequestStatusState,
) {
  return portfolioProjectRequestStatus;
}
export const initialPortfolioProjectRequestStatusState = [];

const comparator = (a: RequestStatusId, b: RequestStatusId) =>
  strcmp(a.id, b.id);
const merger = (
  _a: PortoflioProjectRequestStatusState,
  b: PortoflioProjectRequestStatusState,
) => b;

const adapter = new EntityArrayAdapter<
  RequestStatusId,
  PortoflioProjectRequestStatusState
>(initialPortfolioProjectRequestStatusState, comparator, merger);

export const portfolioProjectRequestStatusSlice = createBaseArraySlice(
  'portfolioProjectRequestStatus',
  adapter,
);
export const portfolioProjectRequestStatusSelectors = adapter.selectors;

export const portfolioProjectRequestStatusReducer =
  portfolioProjectRequestStatusSlice.reducer;
export const portfolioProjectRequestStatusActions =
  portfolioProjectRequestStatusSlice.actions;
