import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';

export const createStockPriceReturnExpression = (
  diffDailyDown = 0.99,
  diffDailyUp = 1.01,
): Expression[] => {
  const previousPrice: Expression = {
    value:
      'previousPrice = (idx > 0 ? values[idx].valuation.stock.price : variables.initialValuation.stock.price)',
    inResult: false,
  };
  const price: Expression = {
    value: `price = random(${diffDailyDown}, ${diffDailyUp}) * previousPrice`,
    inResult: false,
  };
  const expressions = [previousPrice, price];
  return expressions;
};
