import { max, min } from 'lodash';
import React from 'react';
import {
  covariancesFromResearch,
  PortfolioAsset,
  portfolioAssetsFromResearch,
} from '~src/domain/workspace/components/finance/tools/pareto/covariances';
import { ParetoFrontierChartComponent } from '~src/domain/workspace/components/finance/tools/pareto/pareto-frontier-chart.component';
import { useCovarianceInput } from '~src/domain/workspace/components/finance/tools/pareto/use-covariance-input';
import { useCovarianceMatrix } from '~src/domain/workspace/components/finance/tools/pareto/use-covariance-matrix';
import { useInputVectors } from '~src/domain/workspace/components/finance/tools/pareto/use-input-vectors';
import { useTargetReturn } from '~src/domain/workspace/components/finance/tools/pareto/use-target-return';
import { useVectorsInput } from '~src/domain/workspace/components/finance/tools/pareto/use-vectors-input';
import { apis } from '~src/services/request/apis';
import { ParetoOptimalOutput } from '~src/services/request/portfolio-optimization-api';

import { Box, Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {}

export const ParetoComponent = (_props: Props) => {
  const [res, setRes] = React.useState<any>();

  const [portfolioAssets] = React.useState<PortfolioAsset[]>(
    portfolioAssetsFromResearch,
  );

  const { hessian, handleCovarianceChange } = useCovarianceMatrix({
    covariances: covariancesFromResearch,
    portfolioAssets,
  });

  const {
    handleInputVectorsChange,
    expectedReturns,
    lowerBounds,
    upperBounds,
  } = useInputVectors({
    portfolioAssets,
  });

  const [targetReturnsInterval] = React.useState<[number, number]>([
    min(expectedReturns) ?? 0,
    max(expectedReturns) ?? Infinity,
  ]);

  const vectorsInputElement = useVectorsInput({
    expectedReturns,
    handleInputVectorsChange,
    lowerBounds,
    portfolioAssets,
    upperBounds,
  });

  const covarianceInputElement = useCovarianceInput({
    handleCovarianceChange,
    hessian,
    portfolioAssets,
  });

  const { targetReturn, targetReturnElement } = useTargetReturn();

  const calculateParetoOptimal = React.useCallback(() => {
    apis.portfolioOptimization.executeParetoOptimalQuery(
      {
        expected_returns: expectedReturns,
        H: hessian,
        target_return: targetReturn,
        lower_bounds: lowerBounds,
        upper_bounds: upperBounds,
      },
      (result) => {
        setRes(result);
      },
    );
  }, [expectedReturns, hessian, lowerBounds, targetReturn, upperBounds]);

  const numberOfSteps = 50;
  const [results, setResults] = React.useState<ParetoOptimalOutput[]>(
    Array.from({ length: numberOfSteps }, () => {
      return {
        weights: Array.from({ length: portfolioAssets.length }),
        expectedReturn: 0,
        volatility: 0,
      };
    }),
  );

  const calculateParetoOptimalFrontier = React.useCallback(() => {
    apis.portfolioOptimization.executeParetoEfficientFrontierQuery(
      {
        target_return_minimum: targetReturnsInterval[0],
        target_return_maximum: targetReturnsInterval[1],
        expected_returns: expectedReturns,
        H: hessian,
        num_steps: numberOfSteps,
        lower_bounds: lowerBounds,
        upper_bounds: upperBounds,
      },
      (result) => {
        const results: ParetoOptimalOutput[] = result.weights.map((item) => {
          return {
            expectedReturn: item.expected_return,
            volatility: item.volatility,
            weights: item.weights,
          };
        });
        setResults(results);
      },
    );
  }, [
    expectedReturns,
    hessian,
    lowerBounds,
    targetReturnsInterval,
    upperBounds,
  ]);

  const paretoButton = (
    <Button onClick={() => calculateParetoOptimal()} variant="contained">
      Calculate weights
    </Button>
  );

  const paretoFrontierButton = (
    <Button
      onClick={() => calculateParetoOptimalFrontier()}
      variant="contained"
    >
      Calculate pareto frontier
    </Button>
  );

  return (
    <div>
      <Typography m={2} variant="h6">
        Pareto - Efficient frontier
      </Typography>
      <Box>
        <Grid2 container>
          {vectorsInputElement}
          {covarianceInputElement}
          <Grid2 xs={12} margin={2}>
            {targetReturnElement}
          </Grid2>
        </Grid2>
        {/* Data:
        {matrixData ? (
          <pre>{JSON.stringify(matrixData, null, 2)}</pre>
        ) : undefined} */}
      </Box>
      <Grid2 container>
        <Grid2 xs={12} margin={2}>
          {paretoButton}
          <Box>
            Result:{' '}
            {res ? <pre>{JSON.stringify(res, null, 2)}</pre> : undefined}
          </Box>
        </Grid2>
      </Grid2>
      <Grid2 container>
        <Grid2 xs={12} margin={2}>
          {paretoFrontierButton}
          <ParetoFrontierChartComponent
            assets={portfolioAssets}
            data={results}
            points={[]}
          />
          <Box>
            Results:{' '}
            {res ? <pre>{JSON.stringify(results, null, 2)}</pre> : undefined}
          </Box>
        </Grid2>
      </Grid2>
    </div>
  );
};
