import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';

export const getCostTransferFieldConfig = (
  _holding: HoldingState,
  editAll: boolean,
): TransferFieldConfig => {
  return {
    base: {
      type: {
        visible: true,
        disabled: true,
      },
      subtype: {
        visible: true,
        disabled: false,
      },
      cost: {
        visible: true,
        disabled: !editAll,
      },
      currency: {
        visible: true,
        disabled: !editAll,
      },
      holding: {
        visible: true,
        disabled: !editAll,
      },
      date: {
        visible: true,
        disabled: !editAll,
      },
      value: {
        visible: true,
        disabled: false,
      },
      commitment: {
        visible: true,
        disabled: false,
      },
    },
  };
};
