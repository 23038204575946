import { differenceWith } from 'lodash';
import React from 'react';
import {
  Control,
  FieldPath,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
  // useWatch,
} from 'react-hook-form';
import * as uuid from 'uuid';
import {
  FormContext,
  // FormHoldingFields,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { HoldingConfig } from '~src/domain/workspace/components/project/transaction/form/holding-config';
import { HoldingFieldListener } from '~src/domain/workspace/components/project/transaction/form/inputs/holding-field-listener';
import {
  createHoldingInputData,
  createHoldingInputDataFromConfig,
  HoldingInputData,
} from '~src/domain/workspace/components/project/transaction/form/inputs/holding-input-data';

import { strcmp } from '@pladdenico/common';
import { TransferType } from '@pladdenico/models';

export const useListeners = (
  watch: UseFormWatch<FormValues>,
  getValues: UseFormGetValues<FormValues>,
  setValue: UseFormSetValue<FormValues>,
  // fields: FormHoldingFields[],
  listeners?: HoldingFieldListener<FieldPath<FormValues>, FormValues>[],
) => {
  React.useEffect(() => {
    const fields = getValues().holdings;
    listeners?.map((listener) => {
      const indexSrc = fields.findIndex(
        (holding) => holding.id === listener.srcId,
      );
      const pathsSrc = listener.paths(indexSrc);

      const indexDest = fields.findIndex(
        (holding) => holding.id === listener.destId,
      );

      if (indexSrc !== -1) {
        const subscription = watch((value, { name }) => {
          if (pathsSrc.some((path) => path === name)) {
            listener.callback(indexDest, value, setValue);
          }
        });
        return () => subscription.unsubscribe();
      }
    });
  }, [getValues, listeners, setValue, watch]);
};

export const useTransactionHoldings = (
  control: Control<FormValues, FormContext>,
  getValues: UseFormGetValues<FormValues>,
  setValue: UseFormSetValue<FormValues>,
  watch: UseFormWatch<FormValues>,
  holdingConfigs: HoldingConfig[],
  listeners: HoldingFieldListener<FieldPath<FormValues>, FormValues>[],
  date: Date,
) => {
  const { replace, append, remove, fields } = useFieldArray({
    name: 'holdings',
    control,
  });

  const fieldsWatched = useWatch({
    name: 'holdings',
    control,
    // defaultValue: [],
  });

  const onDelete = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const holdingConfigsRef = React.useRef<HoldingConfig[]>(holdingConfigs);
  React.useEffect(() => {
    if (
      // fieldsWatched &&
      holdingConfigsRef &&
      holdingConfigsRef.current !== holdingConfigs
    ) {
      // Need to try and keep the index for a holding constant
      // XXX: logic for holdings fields should be rewritten entirely...
      // const holdings: HoldingInputData[] = fieldsWatched.filter(
      const holdings: HoldingInputData[] = fieldsWatched
        ? fieldsWatched.filter((f) => f.holdingId != null)
        : [];
      const additionalHoldings = differenceWith(
        holdingConfigs,
        holdings,
        (a, b) => {
          if (
            b.transferInputData.base.type &&
            strcmp(a.transferType, b.transferInputData.base.type) === 0
          ) {
            return true;
          }
          return false;
        },
      );

      holdings.push(
        ...additionalHoldings.map((hc) =>
          createHoldingInputDataFromConfig(hc, date),
        ),
      );
      // holdingConfigsRef.current = holdingConfigs;
      replace(holdings);
      // const sortedHoldingConfigs = sortBy(
      //   holdingConfigs,
      //   (hc) => hc.transferType
      // );
      // const sortedFields = sortBy(
      //   fieldsWatched,
      //   (f) => f.transferInputData.base.type
      // ).filter((f) => f.holdingId != null);

      // const holdings = unionSorted<
      //   HoldingConfig,
      //   FormHoldingFields,
      //   HoldingInputData
      // >(
      //   sortedHoldingConfigs,
      //   sortedFields,
      //   [
      //     (hc, f) => {
      //       if (f.transferInputData.base.type == null) {
      //         return -1;
      //       }
      //       return strcmp(hc.transferType, f.transferInputData.base.type);
      //     },
      //   ],
      //   (_hc, f) => {
      //     f.config.deleteIcon = false;
      //     if (f.transferInputData.base.type != null) {
      //       f.config.possibleTransferTypes = [
      //         f.transferInputData.base.type as TransferType,
      //       ];
      //     }
      //     return f;
      //   },
      //   (hc) => createHoldingInputDataFromConfig(hc, date),
      //   (f) => {
      //     f.config.deleteIcon = true;
      //     f.config.possibleTransferTypes = undefined;
      //     return f;
      //   }
      // );
    }
  }, [date, fieldsWatched, holdingConfigs, replace]);

  React.useEffect(() => {
    holdingConfigsRef.current = holdingConfigs;
  });

  // useListeners(watch, getValues, setValue, fields, listeners);
  useListeners(watch, getValues, setValue, listeners);

  const _onAdd = React.useCallback(() => {
    const holding = createHoldingInputData(uuid.v1(), TransferType.Buy, {
      name: '',
      deleteIcon: true,
      useValuation: true,
    });
    append(holding);
  }, [append]);

  return {
    onAddHolding: _onAdd,
    onDeleteHolding: onDelete,
    fields,
  };
};
