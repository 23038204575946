import { combineReducers } from 'redux';

import {
  initialStockPositionState,
  stockPositionsReducer,
} from './stock-position/reducer';
import {
  initialStockTradeState,
  stockTradesReducer,
} from './stock-trade/reducer';
import {
  initialStockHoldingState,
  stockHoldingsReducer,
} from './stock-holding/reducer';
import {
  initialStockGoalRuleState,
  stockGoalRulesReducer,
} from './stock-goal-rule/reducer';

export const stockHoldingInitialState = {
  stockHoldings: initialStockHoldingState,
  stockPositions: initialStockPositionState,
  stockTrades: initialStockTradeState,
  stockGoalRules: initialStockGoalRuleState,
};

export const stockHoldingReducer = combineReducers({
  stockHoldings: stockHoldingsReducer,
  stockPositions: stockPositionsReducer,
  stockTrades: stockTradesReducer,
  stockGoalRules: stockGoalRulesReducer,
});
