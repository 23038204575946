import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createProjects } from '~src/data/store/modules/workspaces/projects/base/requests';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectInputType } from '~src/domain/workspace/components/project/create.component';
import { useProjectFields } from '~src/domain/workspace/components/project/form/use-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: ProjectInputType;
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  };
}

export type ProjectCreate = ReturnType<typeof useProjectCreate>;

export const useProjectCreate = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (projectInput: ProjectInputType) => {
      return dispatch(
        createProjects(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            ...projectInput,
          },
        ]),
      )
        .then((projects) => {
          const project = projects[0];
          callbacks?.onSuccess && callbacks.onSuccess();
          return project;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<ProjectInputType>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useProjectFields(workspace, control);

  const onSubmit = React.useCallback(
    (
      successCallback: (project: ProjectState) => Promise<void>,
    ): SubmitHandler<ProjectInputType> =>
      (data) => {
        return onSave(data).then((project) => {
          return successCallback(project);
        });
      },
    [onSave],
  );

  const submit = React.useCallback(
    (successCallback: (project: ProjectState) => Promise<void>) => {
      return handleSubmit(onSubmit(successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit, control };
};
