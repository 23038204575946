import { combineReducers } from 'redux';
import {
  generatedReportsReducer,
  initialGeneratedReportState,
} from '~src/data/store/reducers/workspace/projects/reports/generated-report/reducer';
import {
  projectReportsReducer,
  initialProjectReportState,
} from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import {
  initialReportState,
  reportsReducer,
} from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import {
  initialSchedulerState,
  schedulersReducer,
} from '~src/data/store/reducers/workspace/projects/reports/schedulers/reducer';

export const reportInitialState = {
  reports: initialReportState,
  projectReports: initialProjectReportState,
  generatedReports: initialGeneratedReportState,
  schedulers: initialSchedulerState,
};

export const reportReducer = combineReducers({
  reports: reportsReducer,
  projectReports: projectReportsReducer,
  generatedReports: generatedReportsReducer,
  schedulers: schedulersReducer,
});
