import { PortfolioProjectScenarioTabs } from '~src/domain/workspace/components/project/scenario/tabs/tabs';
import { rootScenarioPath } from '~src/navigation/paths/workspace/project/portfolio-project/scenario/paths';
import { Tab } from '~src/utils/interfaces/tab';

function createTabs(): Tab<PortfolioProjectScenarioTabs>[] {
  const tabs: Tab<PortfolioProjectScenarioTabs>[] = [];

  {
    const path = {
      tab: PortfolioProjectScenarioTabs.Dashboard,
      exact: true,
      path: `${rootScenarioPath}/dashboard`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectScenarioTabs.Holdings,
      exact: true,
      path: `${rootScenarioPath}/holdings`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: PortfolioProjectScenarioTabs.Settings,
      exact: true,
      path: `${rootScenarioPath}/settings`,
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }

  return tabs;
}

export function usePortfolioProjectScenarioTabs() {
  return createTabs();
}
