import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  BaseScenarioId,
  scenariosSelectors,
  ScenarioState,
} from '~src/data/store/reducers/workspace/projects/scenario/scenarios/reducer';

const selectScenarioState = (state: WorkspaceDataState) =>
  state.workspace.project.scenario.scenarios;

export const getScenarios = createSelector(
  (state: WorkspaceDataState) => selectScenarioState(state),
  (scenarios): ScenarioState[] => {
    return scenariosSelectors.selectAllElements(scenarios);
  },
);

export const getScenarioById = createCachedSelector(
  (state: WorkspaceDataState) => selectScenarioState(state),
  (_state: WorkspaceDataState, id: BaseScenarioId) => id,
  (scenarios, id): ScenarioState | undefined => {
    return scenariosSelectors.selectElementByT(scenarios, id);
  },
)({
  keySelector: (_state, id) => `${id.id}-${id.projectId}`,
  selectorCreator: createSelector,
});

export const getScenariosByProjectId = createCachedSelector(
  (state: WorkspaceDataState) => selectScenarioState(state),
  (_state: WorkspaceDataState, projectId: string) => projectId,
  (scenarios, projectId): ScenarioState[] | undefined => {
    return scenariosSelectors.selectElementsByKey(scenarios, projectId);
  },
)({
  keySelector: (_state, projectId) => projectId,
  selectorCreator: createSelector,
});
