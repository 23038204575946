import { Tab } from '~src/utils/interfaces/tab';

import { FinanceTabs } from './finance-tabs.component';

function createTabs(): Tab<FinanceTabs>[] {
  const tabs: Tab<FinanceTabs>[] = [];

  {
    const path = {
      tab: FinanceTabs.Papers,
      exact: true,
      path: '/finance/papers',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: FinanceTabs.Tools,
      exact: true,
      path: '/finance/tools',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  {
    const path = {
      tab: FinanceTabs.Settings,
      exact: true,
      path: '/finance/settings',
    };
    tabs.push({
      name: path.tab,
      path: path.path,
    });
  }
  return tabs;
}

export function useFinanceTabs() {
  return createTabs();
}
