import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { WarrantState } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';
import { useCreateForm } from '~src/domain/workspace/components/asset/types/warrants/form/use-create-form';

interface Props {
  workspace: WorkspaceState;
  title: string;
  handleClose: () => void;
  assetForm: AssetForm;
  onFormCallbacks: {
    onSuccess: () => void;
    onFailure: (_error: any) => void;
  };
  createdCallback: (warrant: WarrantState) => void;
}

export const WarrantCreateDialogContentComponent = React.memo(
  (props: Props) => {
    const { workspace, handleClose, title, assetForm, onFormCallbacks } = props;
    const { form, submit } = useCreateForm({
      workspace,
      assetForm,
      onFormCallbacks,
    });
    const handleCreateWarrant = React.useCallback(() => {
      return submit((_warrant) => {
        handleClose();
        return Promise.resolve();
      });
    }, [handleClose, submit]);

    return (
      <DialogContentComponent
        title={title}
        handleCancel={handleClose}
        handleOnSave={handleCreateWarrant}
      >
        {form}
      </DialogContentComponent>
    );
  },
);
