import { combineReducers } from 'redux';
import {
  initialPeopleState,
  peopleReducer,
} from '~src/data/store/reducers/person/people/reducer';

import { addressesReducer, initialAddressesState } from './addresses/reducer';
import { emailsReducer, initialEmailsState } from './emails/reducer';
import { phonesReducer, initialPhonesState } from './phones/reducer';
import { rolesReducer, initialRolesState } from './roles/reducer';

export const personInitialState = {
  people: initialPeopleState,
  roles: initialRolesState,
  emails: initialEmailsState,
  addresses: initialAddressesState,
  phones: initialPhonesState,
};

export const personReducer = combineReducers({
  people: peopleReducer,
  roles: rolesReducer,
  emails: emailsReducer,
  addresses: addressesReducer,
  phones: phonesReducer,
});
