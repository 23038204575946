import React from 'react';
import { EditableMatrix } from '~src/domain/workspace/components/finance/tools/editable-matrix';
import { PortfolioAsset } from '~src/domain/workspace/components/finance/tools/pareto/covariances';

import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  portfolioAssets: PortfolioAsset[];
  hessian: number[];
  handleCovarianceChange: (row: number, col: number, value: number) => void;
}

export const useCovarianceInput = (props: Props) => {
  const { portfolioAssets, hessian, handleCovarianceChange } = props;
  const n = portfolioAssets.length;

  const covarianceMatrix = React.useMemo(() => {
    const matrix: number[][] = Array.from({ length: n }, () =>
      Array.from({ length: n }),
    );
    for (let i = 0; i < n; ++i) {
      for (let j = 0; j < n; ++j) {
        matrix[i][j] = hessian[i + j * n];
      }
    }
    return matrix;
  }, [hessian, n]);

  const assetHeaders = React.useMemo(() => {
    return portfolioAssets.map((asset) => {
      return (
        <Typography key={asset.id} variant="body2">
          {asset.name}
        </Typography>
      );
    });
  }, [portfolioAssets]);

  const covarianceRowHeaders = React.useMemo(() => {
    return portfolioAssets.map((asset) => {
      return (
        <Typography key={asset.id} variant="body2">
          {asset.name}
        </Typography>
      );
    });
  }, [portfolioAssets]);

  return (
    <Grid2 xs={12} margin={2}>
      <EditableMatrix
        title={<Typography variant="body1">Covariance</Typography>}
        columnHeaders={assetHeaders}
        rowHeaders={covarianceRowHeaders}
        data={covarianceMatrix}
        handleChange={handleCovarianceChange}
      />
    </Grid2>
  );
};
