import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { fetchQuotes } from '~src/data/store/modules/finance/quotes/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { getPaperById } from '~src/data/store/selectors/finance/papers/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { PaperComponent } from '~src/domain/workspace/components/finance/papers/paper/paper.component';
import { AppDispatch } from '~src/store/store';

export const PaperRouterWrapper = React.memo(() => {
  const { paperId } = useParams<{ paperId: string }>();
  const paper = useSelector((state: RootState) =>
    getPaperById(selectFinancialData(state), paperId ? paperId : null),
  );
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    if (paper) {
      dispatch(fetchQuotes(paper.id, null));
    }
  }, [dispatch, paper]);

  if (!paper) {
    return <ProgressComponent />;
  }
  return <PaperComponent paper={paper} />;
});
