import React from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { FieldConfig } from '~src/hooks/fields/common/field-config';

// import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

export const useTransferCurrencyField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  control: Control<TFieldValues>,
  tName: TName,
  defaultValue: UnpackNestedValue<PathValue<TFieldValues, TName>> | undefined,
  fieldConfig?: FieldConfig,
) => {
  const elementControlled = React.useCallback(
    (
      field: ControllerRenderProps<
        TFieldValues,
        TName // 'data.stockTrade.shares'
      >,
      fieldState: ControllerFieldState,
      _disabled?: boolean,
    ) => (
      <EditCurrencyField
        handleValue={(currency) => field.onChange(currency.id)}
        currencyId={field.value ?? null}
        labelled={true}
        error={fieldState.error}
      />
    ),
    [],
  );
  const element = React.useMemo(() => {
    return (
      <Controller
        name={tName} // {'data.stockTrade.shares'}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          elementControlled(field, fieldState, fieldConfig?.disabled)
        }
      />
    );
  }, [defaultValue, elementControlled, fieldConfig?.disabled, tName, control]);

  return {
    visible: fieldConfig?.visible,
    element,
  };
};
