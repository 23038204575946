import { stockGoalRulesActions } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleStockGoalRules } from './handler';

export function fetchStockGoalRules(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetStockGoalRulesDocument;
    const variables: graphqlWorkspaceTypes.GetStockGoalRulesQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getStockGoalRules) {
        return handleStockGoalRules(
          dispatch,
          tenantId,
          workspaceId,
          data.getStockGoalRules,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createStockGoalRules(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateStockGoalRuleInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateStockGoalRulesDocument;
    const variables: graphqlWorkspaceTypes.CreateStockGoalRulesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createStockGoalRules) {
        return handleStockGoalRules(
          dispatch,
          tenantId,
          workspaceId,
          data.createStockGoalRules,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateStockGoalRules(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateStockGoalRuleInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateStockGoalRulesDocument;
    const variables: graphqlWorkspaceTypes.UpdateStockGoalRulesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateStockGoalRules) {
        return handleStockGoalRules(
          dispatch,
          tenantId,
          workspaceId,
          data.updateStockGoalRules,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertStockGoalRules(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertStockGoalRuleInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertStockGoalRulesDocument;
    const variables: graphqlWorkspaceTypes.UpsertStockGoalRulesMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertStockGoalRules) {
        return handleStockGoalRules(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertStockGoalRules,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteStockGoalRules(
  stockHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    dispatch(
      stockGoalRulesActions.removeManyElements(
        ids.map((p) => {
          return {
            stockHoldingId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteStockGoalRules(
  tenantId: string,
  workspaceId: string,
  stockHoldingId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteStockGoalRulesDocument;
    const variables: graphqlWorkspaceTypes.DeleteStockGoalRulesMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteStockGoalRules) {
        dispatch(
          handleDeleteStockGoalRules(stockHoldingId, data.deleteStockGoalRules),
        );
        // dispatch(
        //   stockGoalRulesActions.removeManyElements(
        //     data.deleteStockGoalRules.map((p) => {
        //       return {
        //         stockHoldingId,
        //         id: p,
        //       };
        //     })
        //   )
        // );
      }
      return [];
    });
  };
}
