import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { useFetchWorkspaceData } from '~src/data/store/modules/workspaces/data/requests';
import { fetchPrivateEquityFundProjectByProjectId } from '~src/data/store/modules/workspaces/projects/private-equity-fund-projects/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getPrivateEquityFundProjectByProjectId } from '~src/data/store/selectors/workspace/projects/private-equity-fund-project/private-equity-fund-projects/selectors';
import { MainComponent } from '~src/domain/home/app/main.component';
import { AppDispatch } from '~src/store/store';

import { Investment } from '../data/investment';
import { Person } from '../data/person';
import { InvestmentsComponent } from './investments.component';

interface Props {
  tenantId: string;
  workspaceId: string;
  project: ProjectState;
}

export interface Fund {
  investments: Investment[];
  people: Person[];
}

// function getPrivateEquityFund(_state: PortalDataState, _portal: PortalState) {
//   return {
//     investments,
//     people,
//   };
// }

// function fetchPrivateEquityFund(_portal: PortalState) {
//   return ;
// }

export const PrivateEquityFundPortalComponent = React.memo((props: Props) => {
  const fundProject = useSelector((state: RootState) =>
    getPrivateEquityFundProjectByProjectId(selectWorkspaceData(state), {
      workspaceId: props.workspaceId,
      projectId: props.project.id,
    }),
  );
  const dispatch = useDispatch<AppDispatch>();
  useFetchWorkspaceData(props.tenantId, props.workspaceId);

  React.useEffect(() => {
    dispatch(
      fetchPrivateEquityFundProjectByProjectId(
        props.tenantId,
        props.workspaceId,
        props.project.id,
      ),
    );
  }, [dispatch, props.project.id, props.tenantId, props.workspaceId]);

  if (!fundProject) {
    return <ProgressComponent />;
  }

  return (
    <MainComponent>
      <InvestmentsComponent fundProject={fundProject} />
    </MainComponent>
  );
});
