import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPortfoliosByIds } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
// import history from '~src/navigation/history';
import { eventBasePath } from '~src/navigation/paths/workspace/project/event/paths';

import AttachmentIcon from '@mui/icons-material/Attachment';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  event: EventState;
}

export const ProjectEventCardComponent = React.memo((props: Props) => {
  const { workspace, project, event } = props;
  const navigate = useNavigate();
  const portfolioIds = React.useMemo(() => {
    return event.portfolioIds.map((id) => {
      return { workspaceId: workspace.id, id };
    });
  }, [event.portfolioIds, workspace.id]);
  const portfolios = useSelector((state: RootState) =>
    getPortfoliosByIds(selectWorkspaceData(state), portfolioIds),
  );
  const portfolioElements = portfolios.map((portfolio) => {
    return (
      <Box key={portfolio.id}>
        <Typography variant="body2">{portfolio.name}</Typography>
      </Box>
    );
  });

  return (
    <Box>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid
          container
          item
          xs={1}
          sx={{ ml: 1 }}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="body2" align="center">
            {moment(event.date).format('DD.MM.YYYY')}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={3}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box>
            <Link
              component="button"
              variant="body1"
              onClick={() =>
                navigate(
                  eventBasePath(
                    workspace.tenantId,
                    workspace.id,
                    project.id,
                    event.id,
                  ),
                )
              }
            >
              {event.name}
            </Link>
            {/* <Typography variant="body1">{event.name}</Typography> */}
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={4}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="caption" align="center">
            {event.description}
          </Typography>
        </Grid>
        <Grid item xs={2} alignItems="center" justifyContent="flex-start">
          {portfolioElements}
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="Attachment">
            <AttachmentIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
});
