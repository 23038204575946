import { AuthPayload, User } from '@pladdenico/models';
import { appConfig } from '~src/app-config';
import { homeBasePath } from '~src/navigation/paths/home/paths';
// import history from '~src/navigation/history';
import { AccountAction } from '~src/data/store/actions/account';
// import { Authentication } from '~src/redux/modules/account/authentication';
// import { Account as netfondsAccount} from '~src/models/netfonds/account';
import { DataApi } from '~src/data/store/modules/data-api';
import { selectedWorkspacesActions } from '~src/data/store/reducers/workspace/selected-workspace/reducer';
import { unselectProject } from '../workspaces/projects/base/requests';
import { useNavigate } from 'react-router-dom';
// import { unselectPortfolio } from '../portfolios/portfolios/requests';

export const LOGGED_IN = 'account/LOGGED_IN';
export const SIGNED_UP = 'account/SIGNED_UP';
export const RESET = 'account/RESET';
export const LOGGED_OUT = 'account/LOGGED_OUT';

export const ACCOUNT_ROLE = 'account/ACCOUNT_ROLE';
export const ACCOUNT_ROLES = 'account/ACCOUNT_ROLES';
export const ACCOUNT_DELETED_ROLE = 'account/ACCOUNT_DELETED_ROLE';
export const ACCOUNT_DELETE_WORKSPACE_ROLES =
  'account/ACCOUNT_DELETE_WORKSPACE_ROLES';

// export const NETFONDS_ACCOUNT = 'account/NETFONDS_ACCOUNT';
// export const GET_NETFONDS_ACCOUNT_REQUEST = 'asset/GET_NETFONDS_ACCOUNT_REQUEST';
// export const GET_NETFONDS_ACCOUNT_SUCCESS = 'asset/GET_NETFONDS_ACCOUNT_SUCCESS';
// export const GET_NETFONDS_ACCOUNT_FAILURE = 'asset/GET_NETFONDS_ACCOUNT_FAILURE';

export function loggedin(token: string, user: User): AccountAction {
  return {
    type: LOGGED_IN,
    token,
    user,
  };
}

export function signedup(user: User): AccountAction {
  return {
    type: SIGNED_UP,
    user,
    token: undefined,
  };
}

export function resetted(token: string, user: User): AccountAction {
  return {
    type: RESET,
    token,
    user,
  };
}

export function loggedout(): AccountAction {
  return {
    type: LOGGED_OUT,
    user: undefined,
    token: undefined,
  };
}

export function deleteWorkspaceRolesAction(workspaceId: string): AccountAction {
  return {
    type: ACCOUNT_DELETE_WORKSPACE_ROLES,
    workspaceId,
  };
}

export function login(
  name: string,
  password: string,
  callback: (user?: User, error?: any) => void,
) {
  return (dispatch: any) => {
    name = encodeURIComponent(name);
    password = encodeURIComponent(password);
    const formData = `name=${name}&password=${password}`;

    return DataApi.post(
      `${appConfig.apiBaseUrl}/api/v1/account/login`,
      {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      formData,
      (res: AuthPayload) => {
        console.log(res);
        dispatch(loggedin(res.token, res.user));
        console.log(appConfig.baseurl);
        // browserHistory.push(appConfig.baseurl);
        callback(res.user);
        return Promise.resolve(res);
      },
      (err) => {
        console.log('login failure 3', err);
        callback(undefined, err);
        return Promise.reject(err);
      },
    );
  };
}

export function signup(
  name: string,
  email: string,
  password: string,
  callback: (user?: User, error?: any) => void,
) {
  return (dispatch: any) => {
    name = encodeURIComponent(name);
    password = encodeURIComponent(password);
    const formData = `name=${name}&password=${password}&email=${email}`;
    return DataApi.post(
      `${appConfig.apiBaseUrl}/api/v1/account/signup`,
      {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      formData,
      (res: AuthPayload) => {
        console.log(res);
        dispatch(signedup(res.user));
        console.log(appConfig.baseurl);
        // browserHistory.push(`${appConfig.baseurl}/workspaces/setup`);
        // browserHistory.push(`${appConfig.baseurl}/login`);
        callback(res.user);
        return Promise.resolve(res);
      },
      (err) => {
        console.log('login failure 3', err);
        callback(undefined, err);
        return Promise.reject(err);
      },
    );
  };
}

const headers = () => {
  return {
    ...DataApi.baseHeaders(),
    ...DataApi.authHeaders(),
  };
};

export function reset(
  existingPassword: string,
  newPassword: string,
  callback: (user?: User, error?: any) => void,
) {
  return (dispatch: any) => {
    // password = encodeURIComponent(password);
    // const formData = `password=${password}`;
    return DataApi.post(
      `${appConfig.apiBaseUrl}/api/v1/account/reset-password`,
      headers(),
      // {
      //   'Accept': 'application/json, text/plain, */*',
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
      JSON.stringify({
        existingPassword,
        newPassword,
      }),
      // formData,
      (res: AuthPayload) => {
        console.log(res);
        dispatch(resetted(res.token, res.user));
        console.log(appConfig.baseurl);
        // browserHistory.push(appConfig.baseurl);
        callback(res.user);
        return Promise.resolve(res);
      },
      (err) => {
        console.log('login failure 3', err);
        callback(undefined, err);
        return Promise.reject(err);
      },
    );
  };
}

export function useLogout() {
  const navigate = useNavigate();
  return (dispatch: any) => {
    return DataApi.get(
      `${appConfig.apiBaseUrl}/api/v1/account/logout`,
      {
        Accept: 'application/json',
      },
      (res) => {
        console.log(res);
        dispatch(loggedout());
        // dispatch(unselectWorkspaceAction());
        dispatch(selectedWorkspacesActions.unset(undefined));
        dispatch(unselectProject());
        // dispatch(unselectPortfolio());
        console.log(appConfig.baseurl);
        // browserHistory.push(`${appConfig.baseurl}`);
        navigate(homeBasePath);
        return Promise.resolve(res);
      },
      (err) => {
        console.log('login failure 3', err);
        return Promise.reject(err);
      },
    );
  };
}

// export function getNetfondsAccount(workspaceId: string) {
//   console.log('getNetfondsAccount');

//   return (dispatch: any) => {

//     console.log('getNetfondsAccount 2', appConfig);

//     dispatch(netfondsAccountRequest());
//     return DataApi.get(`${appConfig.apibaseurl}/api/v1/netfonds/account`,
//       {
//         'Accept': 'application/json, text/plain, */*',
//         'Content-Type': 'application/json',
//         'authorization': `Bearer ${Authentication.getToken()}`,
//         'workspaceId': `${workspaceId}`,
//       },
//       (netfondsAccount: netfondsAccount) => {
//         dispatch(netfondsAccountSuccess(netfondsAccount));
//         return Promise.resolve(netfondsAccount);
//       },
//       (err) => {
//         console.log('getNetfondsAccount 3', err);
//         dispatch(netfondsAccountFailure(err));
//         return Promise.reject(err);
//       });
//   };
// }

// export function netfondsAccountRequest(): AccountAction {
//   return {
//     type: GET_NETFONDS_ACCOUNT_REQUEST,
//     netfondsAccount: undefined,
//   };
// }

// export function netfondsAccountSuccess(netfondsAccount: netfondsAccount): AccountAction {
//   return {
//     type: GET_NETFONDS_ACCOUNT_SUCCESS,
//     netfondsAccount,
//   };
// }

// export function netfondsAccountFailure(message: any): AccountAction {
//   return {
//     type: GET_NETFONDS_ACCOUNT_FAILURE,
//     message,
//   };
// }
