import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseWarrantId {
  id: string;
  workspaceId: string;
}

export type WarrantState = Omit<
  graphqlWorkspaceTypes.Warrant,
  'asset' | '_type'
> & {
  workspaceId: string;
  assetId: string;
};

export function warrantToWarrantState(warrant: WarrantState) {
  return warrant;
}
const initialState: RecordState<WarrantState, string>[] = [];

const selectKeyId = (warrant: BaseWarrantId) => warrant.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseWarrantId, b: BaseWarrantId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseWarrantId, WarrantState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const warrantsSlice = createBaseRecordSlice('warrants', adapter);
export const warrantsSelectors = adapter.selectors;

export const warrantsReducer = warrantsSlice.reducer;
export const warrantsActions = warrantsSlice.actions;

export const initialWarrantState = {
  warrants: initialState,
};

export const warrantReducer = combineReducers({
  warrants: warrantsReducer,
});
