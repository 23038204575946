import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import {
  allocationSelectors as allocationsSelectors,
  AllocationState,
  BaseAllocationId,
} from '../../../reducers/portfolio/allocations/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';

const getAllocationState = (state: WorkspaceDataState) =>
  state.portfolio.allocations;

export const getAllocationsByPortfolioId = createCachedSelector(
  (state: WorkspaceDataState) => getAllocationState(state),
  (_state: WorkspaceDataState, portfolioId: string) => portfolioId,
  (allocationState, portfolioId): AllocationState[] => {
    return (
      allocationsSelectors.selectElementsByKey(allocationState, portfolioId) ??
      []
    );
    // return selectAllocationsByPortfolioId(allocationState, portfolioId);
  },
)({
  keySelector: (_state, portfolioId) => portfolioId,
  selectorCreator: createSelector,
});

export const getAllocations = createSelector(
  (state: WorkspaceDataState) => getAllocationState(state),
  (allocations) => {
    return allocationsSelectors.selectAllElements(allocations);
  },
);

export const getAllocationById = createCachedSelector(
  (state: WorkspaceDataState) => getAllocationState(state),
  (_state: WorkspaceDataState, baseId: BaseAllocationId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseAllocationId) => baseId.portfolioId,
  (allocations, id, portfolioId): AllocationState | undefined => {
    return allocationsSelectors.selectElementByT(allocations, {
      id,
      portfolioId,
    });
  },
)({
  keySelector: (_state, portfolioId, allocationId) =>
    `${portfolioId}:${allocationId}`,
  selectorCreator: createSelector,
});
