import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import { upsertDashboardMedias } from '~src/data/store/modules/common/dashboard/media/requests';
import { DashboardElementState } from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';
import { SelectImageComponent } from '~src/domain/workspace/components/file/image/select-image.component';
import { AppDispatch } from '~src/store/store';

import { Divider, MenuItem } from '@mui/material';

export const useImageMenu = (
  element: DashboardElementState,
  workspace: WorkspaceState,
  project: ProjectState,
  mediaId: string | undefined,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const setFileId = React.useCallback(
    (fileId: string) => {
      return dispatch(
        upsertDashboardMedias(workspace.tenantId, workspace.id, [
          {
            dashboardElementId: element.id,
            fileId,
            id: mediaId ?? uuid.v1(),
          },
        ]),
      ).then((media) => {
        const medium = media[0];
        updated &&
          updated(element.id, {
            // ...element,
            config: {
              ...element.config,
              mediaId: medium.id,
            },
          });
      });
    },
    [
      dispatch,
      element.config,
      element.id,
      mediaId,
      updated,
      workspace.id,
      workspace.tenantId,
    ],
  );
  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);
  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-1" />,
      <MenuItem
        key="menu-item-1"
        sx={{ justifyContent: 'space-between' }}
        // onClick={open}
      >
        <SelectImageComponent workspace={workspace} updateFile={setFileId} />
      </MenuItem>,
      <Divider key="divider-delete" />,
      deleteMenuItem,
      confirmDeleteElement,
    ];
  }, [
    confirmDeleteElement,
    deleteMenuItem,
    element,
    setFileId,
    updated,
    workspace,
  ]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
