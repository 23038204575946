import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getEventsByProjectId } from '~src/data/store/selectors/workspace/projects/event/event/selectors';
import { EventState } from '~src/data/store/state/workspace/project/event/event';
import { useEventFields } from '~src/domain/workspace/components/project/event/algorithm/use-fields';

import { TableField, TableOperations } from '@pladdenico/table';

export const useEventsAlgorithm = (
  _workspace: WorkspaceState,
  project: ProjectState,
) => {
  const events = useSelector((state: RootState) =>
    getEventsByProjectId(selectWorkspaceData(state), {
      projectId: project.id,
    }),
  );

  const fields = useEventFields();

  const initialEventSorters = React.useMemo(() => {
    const sorters: TableField.Sorter<EventState>[] = compact([
      TableOperations.createSorter(fields.name, 'asc'),
      TableOperations.createSorter(fields.description, 'asc'),
      TableOperations.createSorter(fields.date, 'asc'),
    ]);
    return sorters;
  }, [fields.date, fields.description, fields.name]);

  const initialEventFilters: TableField.Filter<EventState>[] = React.useMemo(
    () => [
      TableOperations.createFilter(fields.name),
      TableOperations.createFilter(fields.description),
      TableOperations.createFilter(fields.date),
    ],
    [fields.date, fields.description, fields.name],
  );

  const algoData = TableOperations.useDataAlgorithms(
    events,
    initialEventSorters,
    initialEventFilters,
  );

  return {
    algoData,
    fields,
  };
};
