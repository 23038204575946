import { Visitable } from '~src/data/store/visitors/visitable';
import { FavoriteHolding } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import {
  favoriteHoldingsActions,
  FavoriteHoldingState,
} from '~src/data/store/reducers/holding/favorite-holding/reducer';
import { parseFavoriteHolding } from '~src/data/store/modules/holdings/favorite/parser';

export interface FavoriteHoldingVisitor {
  visit(favoriteHolding: FavoriteHoldingVisitable): void;
  post(): void;
}

export class FavoriteHoldingVisitable
  implements Visitable<FavoriteHoldingVisitor>
{
  constructor(
    private _projectId: string,
    private _favoriteHolding: FavoriteHolding,
  ) {}
  public accept(visitor: FavoriteHoldingVisitor) {
    visitor.visit(this);
  }

  public parse(): FavoriteHoldingState {
    const favoriteHolding = this._favoriteHolding;
    return parseFavoriteHolding(this._projectId, favoriteHolding);
  }
}

export class FavoriteHoldingStateVisitor implements FavoriteHoldingVisitor {
  private _favoriteHoldings: FavoriteHoldingState[];
  constructor(private _dispatch: AppDispatch) {
    this._favoriteHoldings = [];
  }
  visit(favoriteHolding: FavoriteHoldingVisitable): void {
    this._favoriteHoldings.push(favoriteHolding.parse());
  }
  post() {
    this._dispatch(
      favoriteHoldingsActions.upsertManyElements(this._favoriteHoldings),
    );
  }
}
