import React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export function usePersistDashboardConfig() {
  const persist = React.useCallback(
    (_workspace: WorkspaceState, _fundId: string) => {
      // to be implemented
    },
    [],
  );
  return { persist };
}
