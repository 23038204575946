import React from 'react';

import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  circularProgress: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const ProgressComponent = React.memo(() => {
  const { classes } = useStyles();
  return (
    <div className={classes.circularProgress}>
      <CircularProgress />
    </div>
  );
});
