import React from 'react';
import { useNavigate } from 'react-router-dom';
// import history from '~src/navigation/history';
import { workspaceFinanceTabPath } from '~src/navigation/paths/workspace/finance/paths';
import { Tab } from '~src/utils/interfaces/tab';

import { Box, Typography } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PapersComponent } from '~src/domain/workspace/components/finance/papers/papers.component';
import { ToolsComponent } from '~src/domain/workspace/components/finance/tools/tools.component';

// import { PapersComponent } from '~src/domain/workspace/components/finance/papers/papers.component';
// import { Root } from ''

export enum FinanceTabs {
  Papers = 'papers',
  Settings = 'settings',
  Tools = 'tools',
}

interface TabPanelProps {
  children?: React.ReactNode;
  tab: FinanceTabs;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tab, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== tab}
      id={`scrollable-auto-tabpanel-${tab}`}
      aria-labelledby={`scrollable-auto-tab-${tab}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

interface Props {
  tenantId: string;
  workspaceId: string;
  tabs: Tab<FinanceTabs>[];
  tab: Tab<FinanceTabs>;
}

interface PropsTab {
  tenantId: string;
  workspaceId: string;
  tabName: string;
}

const SubComponent = React.memo((props: PropsTab) => {
  const { tenantId, workspaceId, tabName } = props;
  if (tabName === FinanceTabs.Papers) {
    return (
      <PapersComponent tenantId={tenantId} workspaceId={workspaceId} />
      // <PapersRouterWrapper tenantId={tenantId} workspaceId={workspaceId} />
    );
  } else if (tabName === FinanceTabs.Settings) {
    return <div>SETTINGS</div>;
  } else if (tabName === FinanceTabs.Tools) {
    return <ToolsComponent />;
  }
  return <div>unknown</div>;
});

export const FinanceTabsComponent = React.memo((props: Props) => {
  const { tab: currentTab, tabs, tenantId, workspaceId } = props;
  const navigate = useNavigate();
  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newFinanceTab: FinanceTabs,
  ) {
    navigate(workspaceFinanceTabPath(tenantId, workspaceId, newFinanceTab));
  }

  const tabElements = tabs.map((tab) => {
    return <MuiTab key={tab.name} label={tab.name} value={tab.name} />;
  });

  const components = React.useMemo(
    () =>
      tabs.map((tab, index) => {
        return (
          <SubComponent
            key={index}
            tenantId={tenantId}
            workspaceId={workspaceId}
            tabName={tab.name}
          />
        );
      }),
    [tabs, tenantId, workspaceId],
  );

  const tabPanelElements = tabs.map((tab, index) => {
    return (
      <TabPanel value={currentTab.name} tab={tab.name} key={index}>
        {components[index]}
      </TabPanel>
    );
  });
  return (
    <>
      <Tabs
        value={currentTab.name}
        indicatorColor="primary"
        textColor="primary"
        // centered
        variant="scrollable"
        onChange={handleChange}
        aria-label="finance tabs"
      >
        {tabElements}
      </Tabs>
      {tabPanelElements}
    </>
  );
});
