// export type ScenarioValueType = string | number | string[] | number[] | null;
export type ScenarioValueType = 'string' | 'number';

export interface ScenarioVariable<ScenarioValueType> {
  id: string;
  name: string;
  description: string;
  type: ScenarioValueType;
  // value: unknown;
  // value: ScenarioValueType;
}

export interface ScenarioVariableNumber extends ScenarioVariable<'number'> {
  value: number;
}

export interface ScenarioVariableString extends ScenarioVariable<'string'> {
  value: string;
}

export const isNumberVariable = (
  variable: ScenarioVariable<ScenarioValueType>,
): variable is ScenarioVariableNumber => {
  return variable.type === 'number';
};

export const isStringVariable = (
  variable: ScenarioVariable<ScenarioValueType>,
): variable is ScenarioVariableString => {
  return variable.type === 'string';
};
