import { uniqueId } from 'lodash';
import React from 'react';

export function usePositioning<T>(
  updateCallback: (array: Array<{ id: string; t: T }>) => void,
  initialArray: () => Array<{ id: string; t: T }>,
) {
  const [_array, _setArray] = React.useState(initialArray());
  // const [_updated, _setUpdated] = React.useState(false);
  // const _latestArray = useRef(_array);
  // useEffect(() => {
  //   _latestArray.current = _array;
  // }, [_array]);
  const set = React.useCallback((array: Array<{ id: string; t: T }>) => {
    _setArray(array);
    // _setUpdated(true);
  }, []);

  React.useEffect(() => {
    // _setUpdated(true);
    updateCallback(_array);
  }, [_array, updateCallback]);

  const add = React.useCallback((t: T) => {
    // const array = _latestArray.current;
    const id = uniqueId();
    _setArray((array) => [...array, { id, t }]);
    // _setUpdated(true);
  }, []);
  const remove = React.useCallback((id: string) => {
    // const array = _latestArray.current;
    _setArray((array) => {
      const idx = array.findIndex((b) => {
        return id === b.id;
      });
      if (idx !== -1) {
        return [...array.slice(0, idx), ...array.slice(idx + 1)];
      }
      return array;
    });
    // _setUpdated(true);
  }, []);
  const move = React.useCallback((fromId: string, toId: string) => {
    _setArray((array) => {
      const fromIdx = array.findIndex((b) => {
        return fromId === b.id;
      });
      const toIdx = array.findIndex((b) => {
        return toId === b.id;
      });
      const fromV = array[fromIdx];
      array.splice(fromIdx, 1);

      return [...array.slice(0, toIdx), fromV, ...array.slice(toIdx)];
    });
    // _setUpdated(true);
  }, []);
  return {
    array: _array,
    set,
    add,
    remove,
    move,
  };
}
