import { handlePrivateEquityFunds } from '~src/data/store/modules/assets/private-equity-fund/private-equity-funds/handler';
import { privateEquityFundsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchPrivateEquityFunds(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetPrivateEquityFundsDocument;
    const variables: graphqlWorkspaceTypes.GetPrivateEquityFundsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPrivateEquityFunds) {
        return handlePrivateEquityFunds(
          workspaceId,
          dispatch,
          data.getPrivateEquityFunds,
        );
      }
      return [];
    });
  };
}

export function createPrivateEquityFunds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreatePrivateEquityFundInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreatePrivateEquityFundsDocument;
    const variables: graphqlWorkspaceTypes.CreatePrivateEquityFundsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPrivateEquityFunds) {
        return handlePrivateEquityFunds(
          workspaceId,
          dispatch,
          data.createPrivateEquityFunds,
        );
      }
      return [];
    });
  };
}

export function updatePrivateEquityFunds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdatePrivateEquityFundInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdatePrivateEquityFundsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePrivateEquityFundsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePrivateEquityFunds) {
        return handlePrivateEquityFunds(
          workspaceId,
          dispatch,
          data.updatePrivateEquityFunds,
        );
      }
      return [];
    });
  };
}

export function upsertPrivateEquityFunds(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertPrivateEquityFundInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertPrivateEquityFundsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPrivateEquityFundsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPrivateEquityFunds) {
        return handlePrivateEquityFunds(
          workspaceId,
          dispatch,
          data.upsertPrivateEquityFunds,
        );
      }
      return [];
    });
  };
}

export function deletePrivateEquityFunds(
  tenantId: string,
  workspaceId: string,
  privateEquityFunds: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeletePrivateEquityFundsDocument;
    privateEquityFunds.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeletePrivateEquityFundInputType[] =
      privateEquityFunds.map((privateEquityFund) => {
        return {
          id: privateEquityFund.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeletePrivateEquityFundsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePrivateEquityFunds) {
        const deletedPrivateEquityFunds = intersectionSorted(
          privateEquityFunds,
          data.deletePrivateEquityFunds,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(
          privateEquityFundsActions.removeManyElements(
            deletedPrivateEquityFunds,
          ),
        );
      }
      return [];
    });
  };
}
