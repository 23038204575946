import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { upsertOne } from '~src/data/store/modules/config/reducer';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { selectedCurrenciesActions } from '~src/data/store/reducers/finance/currency/selected/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import { selectFinancialData } from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';
import { Config } from '~src/utils/interfaces/config';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { PortfolioSelectComponent } from '../portfolio-select.component';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  config: Config;
}
export const PortfolioBaseConfigComponent = (props: Props) => {
  const { workspace, portfolioProject, config } = props;

  const baseCurrencyId = useSelector((state: RootState) =>
    getSelectedCurrencyState(selectFinancialData(state)),
  );
  const [_config, _setConfig] = React.useState(config);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    _setConfig(config);
  }, [config]);

  const updateConfig = (config: Config) => {
    dispatch(upsertOne({ id: 'default', config }));
  };

  const handleConfigUpdate = () => {
    updateConfig(_config);
  };

  const handleConfigIndexPaperChanged = (event: any) => {
    console.log(event);
    const config = { ..._config };
    const value = event.target.value;
    const separatorIdx = value.lastIndexOf('.');
    config.indexPaper = {
      id: '',
      symbol: separatorIdx === -1 ? value : value.slice(0, separatorIdx),
      exchange: separatorIdx === -1 ? '' : value.slice(separatorIdx + 1),
      currencyId: '__CURRENCY__NOK',
    };
    _setConfig(config);
  };

  const handleConfigMinimumValuationForAssetChanged = (event: any) => {
    // const handleConfigMinimumValuationForAssetChanged = (event: any, data: any) => {
    console.log(event);
    const config = { ..._config };
    const value = +event.target.value;
    config.minimumValuationForHolding = value;
    _setConfig(config);
  };

  const onDateChange = (date: any) => {
    const config = { ..._config };
    // config.date = moment(date);
    config.date.set(date.toObject());
    _setConfig(config);
    updateConfig(config);
  };

  const onPeriodStartChange = (date: any) => {
    const config = { ..._config };
    // const period = new Period(moment(config.period.start), moment(config.period.end));
    const period = config.period;
    const newDate = moment(date);
    period.start = moment(newDate);
    if (period.end <= newDate) {
      period.end = newDate.add(1, 'year');
    }
    // config.period = period;
    _setConfig(config);
    updateConfig(config);
  };

  const onPeriodEndChange = (date: any) => {
    const config = { ..._config };
    // const period = new Period(moment(config.period.start), moment(config.period.end));
    const period = config.period;
    const newDate = moment(date);
    period.end = moment(newDate);
    if (period.start >= newDate) {
      period.start = newDate.add(-1, 'year');
    }
    // config.period = period;
    _setConfig(config);
    updateConfig(config);
  };

  const handleOnChangeBaseCurrency = (currency: CurrencyState): void => {
    dispatch(selectedCurrenciesActions.set(currency.id));
  };

  const handleConfigUseLiveValueChanged = (
    event: React.ChangeEvent<unknown>,
    checked: boolean,
  ) => {
    const config = { ..._config };
    config.useLiveValue = checked;
    _setConfig(config);
    updateConfig(config);
  };

  return (
    <>
      <FormControl sx={{ marginBottom: 2 }}>
        <FormLabel>Select portfolio</FormLabel>
        <PortfolioSelectComponent
          workspace={workspace}
          portfolioProject={portfolioProject}
        />
      </FormControl>
      <Box sx={{ marginBottom: 1 }}>
        <DatePicker
          showToolbar={false}
          inputFormat="DD/MM/YYYY"
          label={'Valuation date'}
          value={_config.date}
          onChange={onDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <DatePicker
          showToolbar={false}
          inputFormat="DD/MM/YYYY"
          label={'Start date'}
          value={_config.period.start}
          minDate={_config.minDate}
          onChange={onPeriodStartChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <DatePicker
          showToolbar={false}
          inputFormat="DD/MM/YYYY"
          label={'End date'}
          value={_config.period.end}
          onChange={onPeriodEndChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <EditCurrencyField
          currencyId={baseCurrencyId ?? _config.baseCurrencyId}
          handleValue={handleOnChangeBaseCurrency}
          labelled={true}
          handlePersist={handleConfigUpdate}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <TextField
          margin="normal"
          label="indexPaper"
          required
          id="indexPaper"
          value={`${_config.indexPaper.symbol}.${_config.indexPaper.exchange}`}
          type="text"
          onChange={handleConfigIndexPaperChanged}
          onBlur={handleConfigUpdate}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <TextField
          margin="normal"
          label="Minimum valuation"
          // className={classes.textField}
          required
          id="indexPaper"
          value={`${_config.minimumValuationForHolding}`}
          type="number"
          onChange={handleConfigMinimumValuationForAssetChanged}
          onBlur={handleConfigUpdate}
        />
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <FormControlLabel
          value="useLiveValue"
          checked={_config.useLiveValue}
          onChange={handleConfigUseLiveValueChanged}
          control={<Switch color="primary" />}
          label="Use live value"
          labelPlacement="start"
        />
      </Box>
    </>
  );
};
