import React from 'react';
import {
  Path,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import * as uuid from 'uuid';
import { EditHolding } from '~src/components/fields/workspace/holding/holding/edit-holding';
import { createCommitments } from '~src/data/store/modules/holdings/commitments/commitments/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CommitmentFieldConfig } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-field-config';
import { FormContext } from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { getCommitmentFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/commitment/get-field-config';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';
import { getCommitmentTypes } from '~src/utils/finance/holding-to-commitment-types';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from '@mui/material';
// import { getCommitmentFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/commitment/get-holding-config';
import { HoldingType } from '@pladdenico/models';

import { CommitmentInputData } from './fields/commitment-data-types';
import { FieldsComponent } from './fields/fields.component';

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  // commitmentCreateInputInit: CommitmentBaseData;
  handleClose: () => void;
  holding?: HoldingState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateCommitmentFormComponent = React.memo((props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { classes } = useStyles();
  const [holding, setHolding] = React.useState<HoldingState | undefined>(
    props.holding,
  );
  const context: FormContext = {};

  const { control, setValue, handleSubmit } = useForm<
    CommitmentInputData,
    FormContext
  >({
    context,
  });
  const [fieldConfig, setFieldConfig] = React.useState<CommitmentFieldConfig>();
  const [editAll] = React.useState<boolean>(false);

  const commitmentTypes = React.useMemo(() => {
    if (holding && holding.type != null) {
      return getCommitmentTypes(holding.type as HoldingType);
    }
    return [];
  }, [holding]);

  const commitmentType = useWatch({
    control,
    name: 'base.type',
  }) as CommitmentType;

  React.useEffect(() => {
    setFieldConfig(getCommitmentFieldConfig());
  }, [editAll, holding, commitmentType]);

  const onHandleCreate: SubmitHandler<CommitmentInputData> = React.useCallback(
    (formValues) => {
      const commitmentInput: graphqlWorkspaceTypes.CreateCommitmentInputType = {
        id: uuid.v1(),
        // id: formValues.base.id,
        currencyId: formValues.base.currencyId,
        commitmentDate: formValues.base.commitmentDate,
        startDate: formValues.base.startDate,
        endDate: formValues.base.endDate,
        description: formValues.base.description,
        holdingId: holding ? holding.id : '',
        transferIds: [],
        type: formValues.base.type,
        value: formValues.base.value,
      };

      dispatch(
        createCommitments(props.workspace.tenantId, props.workspace.id, [
          commitmentInput,
        ]),
      )
        .then(() => {
          props.handleClose();
        })
        .catch((err) => {
          console.info('FAILED', commitmentInput, JSON.stringify(err));
        });
    },
    [dispatch, holding, props],
  );

  const onHandleError: SubmitErrorHandler<CommitmentInputData> =
    React.useCallback((errors) => {
      console.log(JSON.stringify(errors));
    }, []);
  const getName = React.useCallback(
    (name: Path<CommitmentInputData>): Path<CommitmentInputData> => {
      return name;
    },
    [],
  );
  return (
    <>
      <DialogTitle id="form-dialog-title">Create commitment</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <>
            <Grid container spacing={0} alignItems="stretch">
              <Grid item sm={12}>
                <EditHolding
                  handleValue={setHolding}
                  projectId={props.projectId}
                  holding={holding}
                  // holdingTypes={}
                  workspace={props.workspace}
                  label={'Holding'}
                />
              </Grid>
              <FieldsComponent
                projectId={props.projectId}
                holding={holding}
                control={control}
                setValue={setValue}
                fieldConfig={fieldConfig}
                commitmentTypes={commitmentTypes}
                getName={getName}
              />
            </Grid>
          </>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onHandleCreate, onHandleError)}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
});
