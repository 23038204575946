import { parseTenant } from '~src/data/store/modules/tenant/tenants/parser';
import {
  TenantRoleStateVisitor,
  TenantRoleVisitable,
  TenantRoleVisitor,
} from '~src/data/store/visitors/tenant/tenant-role-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  WorkspaceBaseStateVisitor,
  WorkspaceBaseVisitable,
  WorkspaceBaseVisitor,
} from '~src/data/store/visitors/workspace/workspace-base-visitor';
import {
  tenantsActions,
  TenantState,
} from '~src/data/store/reducers/tenant/tenants/reducer';
import { Tenant } from '~src/services/graphql/tenant/client/graphql';
import { AppDispatch } from '~src/store/store';

interface TenantVisitor {
  visit(tenant: TenantVisitable): void;
  post(): void;
  workspaceBaseVisitor: WorkspaceBaseVisitor;
  tenantRoleVisitor: TenantRoleVisitor;
}

export class TenantVisitable implements Visitable<TenantVisitor> {
  constructor(private _tenant: Tenant) {}
  public accept(visitor: TenantVisitor) {
    if (this._tenant.workspaces) {
      this._tenant.workspaces.forEach((workspace) => {
        const workspaceVisitable = new WorkspaceBaseVisitable(
          this._tenant.id,
          workspace,
        );
        workspaceVisitable.accept(visitor.workspaceBaseVisitor);
      });
    }
    if (this._tenant.tenantRoles) {
      this._tenant.tenantRoles.forEach((role) => {
        const tenantRoleVisitable = new TenantRoleVisitable(role);
        tenantRoleVisitable.accept(visitor.tenantRoleVisitor);
      });
    }
    visitor.visit(this);
  }

  public parse(): TenantState {
    return parseTenant(this._tenant);
  }
}

export class TenantStateVisitor implements TenantVisitor {
  private _tenants: TenantState[];
  public workspaceBaseVisitor: WorkspaceBaseVisitor;
  public tenantRoleVisitor: TenantRoleVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._tenants = [];
    this.workspaceBaseVisitor = new WorkspaceBaseStateVisitor(this._dispatch);
    this.tenantRoleVisitor = new TenantRoleStateVisitor(this._dispatch);
  }
  public visit(project: TenantVisitable): void {
    this._tenants.push(project.parse());
  }
  post() {
    this._dispatch(tenantsActions.addMany(this._tenants));
    this.workspaceBaseVisitor.post();
    this.tenantRoleVisitor.post();
  }
}
