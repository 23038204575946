import moment from 'moment';
import createCachedSelector from 're-reselect';
import { HoldingFilterState } from '../../../modules/holdings/filter/reducer';
import { selectHoldingProperties, getMapper } from './filter-mapper';
import {
  Filter,
  getShare,
  filterFor,
  createFilter,
  getShareExceptKey,
} from '~src/utils/common/filter';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import {
  WorkspaceDataState,
  DataState,
} from '~src/data/store/reducers/reducers';
import { createSelector } from 'reselect';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { AssetCategoryState } from '~src/data/store/reducers/asset/categories/reducer';
import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { RecordState } from '@pladdenico/common';
import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';

const getHoldingFilterState = (state: WorkspaceDataState) =>
  state.holding.holding.filters;

export const selectHoldingFilterById = createSelector(
  (holdingFilterTypes: HoldingFilterState[]) => holdingFilterTypes,
  (_holdingFilterTypes: HoldingFilterState[], id: number) => id,
  (holdingFilterTypes, id) => {
    if (!holdingFilterTypes) {
      return undefined;
    }
    const holdingFilter = holdingFilterTypes.find(({ id: holdingFilterId }) => {
      return holdingFilterId === id;
    });
    if (holdingFilter) {
      return holdingFilter.filter;
    }
    return undefined;
  },
);

export const getHoldingFilterById = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingFilterState(state),
  (_state: WorkspaceDataState, id: number) => id,
  (holdingFilters, id) => {
    const filter = selectHoldingFilterById(holdingFilters, id);
    if (!filter) {
      return createFilter();
    }
    return filter;
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

interface ShareForHoldingProps {
  filter: Filter;
  workspaceId: string;
  projectId: string;
  holding: HoldingState;
  date: moment.Moment;
}

interface ShareForHoldingByKeyProps extends ShareForHoldingProps {
  filterKey: string;
  filterValue: string;
}

export const getSharesForHoldingForFilter = createCachedSelector(
  (_state: DataState, props: ShareForHoldingByKeyProps) => props.filter,
  (state: DataState, props: ShareForHoldingByKeyProps) =>
    getMapper(state, {
      date: props.date,
      workspaceId: props.workspaceId,
      projectId: props.projectId,
    }),
  (_state: DataState, props: ShareForHoldingByKeyProps) => props.holding,
  (_state: DataState, props: ShareForHoldingByKeyProps) => props.filterKey,
  (_state: DataState, props: ShareForHoldingByKeyProps) => props.filterValue,
  (filter, mapper, holding, filterKey, filterValue) => {
    const properties = mapper(filterKey, holding);
    const propertyShare = properties.find((prop) => prop.id === filterValue);
    // console.log('PROPERTYSHARE', holding.name, filterValue, propertyShare);
    if (!propertyShare) {
      return 0;
    }
    const share = getShareExceptKey(filter, holding, mapper, filterKey);
    // const shareForKey = getShareForKeyValue(filter, id, mapper, filterKey);
    return share * propertyShare.share;
  },
)({
  keySelector: (_state, props) =>
    `${props.holding.id}:${props.date.unix()}:${props.filterKey}:${
      props.filterValue
    }`,
  selectorCreator: createSelector,
});

/* Share of holding in total given holding filter */
export const getHoldingShareById = createCachedSelector(
  (_state: DataState, props: ShareForHoldingProps) => props.filter,
  (state: DataState, props: ShareForHoldingProps) =>
    getMapper(state, {
      date: props.date,
      workspaceId: props.workspaceId,
      projectId: props.projectId,
    }),
  (_state: DataState, props: ShareForHoldingProps) => props.holding,
  (filter, mapper, holding) => {
    return getShare(filter, holding, mapper);
  },
)({
  keySelector: (_state, props) => `${props.holding.id}:${props.date.unix()}`,
  selectorCreator: createSelector,
});

interface FilterForProps {
  filter: Filter;
  holding: HoldingState;
  date: moment.Moment;
  propertyFilterKey?: string;
}

export const getFilterFor = createCachedSelector(
  (_state: DataState, props: FilterForProps) => props.filter,
  (state: DataState, props: ShareForHoldingProps) =>
    getMapper(state, {
      date: props.date,
      workspaceId: props.workspaceId,
      projectId: props.projectId,
    }),
  (_state: DataState, props: FilterForProps) => props.holding,
  (_state: DataState, props: FilterForProps) => props.date,
  (_state: DataState, props: FilterForProps) => props.propertyFilterKey,
  (filter, mapper, holding) => {
    return filterFor(filter, holding, mapper);
  },
)({
  keySelector: (_state, props) =>
    `${props.holding.id}:${props.date}:${props.propertyFilterKey}`,
  selectorCreator: createSelector,
});

export const selectFilterFor = (
  filter: Filter,
  workspaceId: string,
  holding: HoldingDenormalizedWithEntity,
  date: moment.Moment,
  categories: AssetCategoryState[],
  regions: RegionState[],
  regionAllocations: RegionAllocationState[],
  currencies: RecordState<CurrencyState, string>[],
) => {
  const mapper = (
    filterKey: string,
    holding: HoldingDenormalizedWithEntity,
  ) => {
    return selectHoldingProperties(
      filterKey,
      workspaceId,
      holding,
      date,
      categories,
      regions,
      regionAllocations,
      currencies,
    );
  };
  return filterFor(filter, holding, mapper);
};
