import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { entityMediasSelectors } from '~src/data/store/reducers/entity/medias/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const selectEntityMedias = (state: WorkspaceDataState) => {
  return state.holding.entity.media;
};

export const getEntityMedias = createSelector(
  (state: WorkspaceDataState) => selectEntityMedias(state),
  (entityMediasState) => {
    const entityMedias =
      entityMediasSelectors.selectAllElements(entityMediasState);
    return entityMedias ? entityMedias : [];
  },
);

export const getEntityMediasByEntityId = createCachedSelector(
  (state: WorkspaceDataState) => selectEntityMedias(state),
  (_state: WorkspaceDataState, entityId: string) => entityId,
  (entityMediasState, entityId) => {
    const entityMedias = entityMediasSelectors.selectElementsByKey(
      entityMediasState,
      entityId,
    );
    return entityMedias ? entityMedias : [];
  },
)({
  keySelector: (_state, entityId) => entityId,
  selectorCreator: createSelector,
});
