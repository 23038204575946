import { Control } from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { PortfolioInput } from '~src/domain/workspace/components/project/types/portfolio-project/form/create-form.component';
import { useTextField } from '~src/utils/form/fields/text-field';

export const usePortfolioFields = (
  _workspace: WorkspaceState,
  control: Control<PortfolioInput, any>,
) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const descriptionField = useTextField(
    control,
    'description',
    'Description',
    '',
  );

  return {
    nameField,
    descriptionField,
  };
};
