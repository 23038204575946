import * as React from 'react';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import history from '~src/navigation/history';
import { portfolioBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/portfolio/paths';

import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const HoldingCardPortfoliosComponent = React.memo((props: Props) => {
  const navigate = useNavigate();
  const portfolioElements = props.portfolios.map((portfolio) => {
    return (
      <Chip
        key={portfolio.id}
        label={portfolio.name}
        onClick={() =>
          navigate(
            portfolioBasePath(
              props.workspace.tenantId,
              props.workspace.id,
              props.projectId,
              portfolio.id,
            ),
          )
        }
        clickable
        variant="outlined"
      />
    );
  });

  return <>{portfolioElements}</>;
});
