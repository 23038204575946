import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handlePeople } from '~src/data/store/modules/people/people/handlers';
import { fetchPeople } from '~src/data/store/modules/people/people/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getPersonById } from '~src/data/store/selectors/person/person/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { AddressesComponent } from './address/addresses.component';
import { EmailsComponent } from './email/emails.component';
import { EditPersonComponent } from './form/edit.component';
import { PhonesComponent } from './phone/phones.component';
import { RolesComponent } from './role/roles.component';

interface Props {
  workspace: WorkspaceState;
  personId: string;
}

export const PersonComponent = React.memo((props: Props) => {
  const { workspace, personId } = props;
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(moreAnchorEl);

  const person = useSelector((state: RootState) =>
    getPersonById(selectWorkspaceData(state), {
      id: personId,
      workspaceId: workspace.id,
    }),
  );
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    // dispatch(
    fetchPeople(workspace.tenantId, workspace.id, [personId]).then((people) => {
      if (people != null) {
        return handlePeople(dispatch, workspace.tenantId, workspace.id, people);
      }
    });
    // );
  }, [dispatch, workspace, personId, workspace.tenantId, workspace.id]);

  function handleMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMoreAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setMoreAnchorEl(null);
  }
  const [editPersonDialogOpened, setEditPersonDialogOpened] =
    React.useState(false);

  if (!person) {
    return <CircularProgress />;
  }

  // // TODO: see below
  // const roleElements = roles
  //   ? roles.map((role, index) => {
  //       const holding = holdings.find(
  //         (holding) => role.id === holding.id /// XXX: Should be holding ID
  //       );
  //       const holdingName = holding ? holding.name : '';

  //       return (
  //         <ListItem key={index}>
  //           <Link to={`/people/roles/${role.id}`}>
  //             {role.name}: {role.description} - {holdingName}
  //           </Link>
  //         </ListItem>
  //       );
  //     })
  //   : undefined;

  const personMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary="Edit"
          onClick={() => setEditPersonDialogOpened(true)}
        ></ListItemText>
        <EditPersonComponent
          workspace={workspace}
          open={editPersonDialogOpened}
          handleClose={() => setEditPersonDialogOpened(false)}
          person={person}
        />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete"></ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <Box m={1}>
      <Grid container>
        <Grid item xs={10} md={10}>
          <Typography variant="h5">{person.name}</Typography>
          <Typography variant="body1">{person.description}</Typography>
        </Grid>
        <Grid container item xs={2} md={2} justifyContent="flex-end">
          <Grid item>
            <IconButton onClick={handleMenuOpen} aria-label="phone">
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="stretch">
        <Grid
          container
          item
          direction="column"
          xs={12}
          md={12}
          alignItems="stretch"
          spacing={1}
        >
          <Grid item>
            <PhonesComponent workspace={workspace} person={person} />
          </Grid>
          <Grid item>
            <EmailsComponent workspace={workspace} person={person} />
          </Grid>
          <Grid item>
            <AddressesComponent workspace={workspace} person={person} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          xs={12}
          md={12}
          alignItems="stretch"
        >
          <RolesComponent workspace={workspace} person={person} />
        </Grid>
      </Grid>
      {personMenu}
    </Box>
  );
});
