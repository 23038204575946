import { handleValuations } from '~src/data/store/modules/holdings/valuation/valuations/handler';
import {
  BaseValuationId,
  valuationsActions,
} from '~src/data/store/reducers/holding/valuation/valuations/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchValuations(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetValuationsDocument;
    const variables: graphqlWorkspaceTypes.GetValuationsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getValuations) {
        return handleValuations(
          dispatch,
          tenantId,
          workspaceId,
          data.getValuations,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return undefined;
    });
  };
}

export function createValuations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateValuationInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateValuationsDocument;
    const variables: graphqlWorkspaceTypes.CreateValuationsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createValuations) {
        return handleValuations(
          dispatch,
          tenantId,
          workspaceId,
          data.createValuations,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      throw new Error('Could not create valuation');
      // return undefined;
    });
  };
}

export function updateValuations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateValuationInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateValuationsDocument;
    const variables: graphqlWorkspaceTypes.UpdateValuationsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateValuations) {
        return handleValuations(
          dispatch,
          tenantId,
          workspaceId,
          data.updateValuations,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertValuations(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertValuationInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertValuationsDocument;
    const variables: graphqlWorkspaceTypes.UpsertValuationsMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertValuations) {
        return handleValuations(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertValuations,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteValuations(ids: BaseValuationId[]) {
  return (dispatch: AppDispatch) => {
    dispatch(valuationsActions.removeManyElements(ids));
  };
}

export function deleteValuations(
  tenantId: string,
  workspaceId: string,
  ids: BaseValuationId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteValuationsDocument;
    const variables: graphqlWorkspaceTypes.DeleteValuationsMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteValuations) {
        const deletedIds = data.deleteValuations;
        dispatch(
          handleDeleteValuations(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
