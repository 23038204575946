import {
  PrivateEquityFundInvestmentStateVisitor,
  PrivateEquityFundInvestmentVisitable,
} from '~src/data/store/visitors/workspace/project/private-equity/private-equity-fund-investment-visitor';
import { PrivateEquityFundInvestment } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleInvestments(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  dispatch: any,
  outputs: PrivateEquityFundInvestment[],
  subscriptions: Operation[],
) {
  const visitor = new PrivateEquityFundInvestmentStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  outputs.forEach((entity) => {
    const visitable = new PrivateEquityFundInvestmentVisitable(fundId, entity);
    visitable.accept(visitor);
  });
  visitor.post();
  return outputs;
}
