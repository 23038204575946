import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { SchedulerState } from '~src/data/store/reducers/common/scheduler-state';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import {
  BaseSchedulerId,
  schedulersSelectors,
} from '~src/data/store/reducers/workspace/projects/reports/schedulers/reducer';

const getSchedulerState = (state: WorkspaceDataState) =>
  state.workspace.project.report.schedulers;

interface Ids {
  ids: string[];
}

export const getSchedulerById = createCachedSelector(
  (state: WorkspaceDataState, _id: BaseSchedulerId) => getSchedulerState(state),
  (_state: WorkspaceDataState, id: BaseSchedulerId) => id,
  (schedulers, id): SchedulerState | undefined => {
    return schedulersSelectors.selectElement(schedulers, id);
  },
)({ keySelector: (_state, id) => id.id, selectorCreator: createSelector });

export const getSchedulersByIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: Ids) => getSchedulerState(state),
  (_state: WorkspaceDataState, ids: Ids) => ids,
  (schedulers, ids): SchedulerState[] => {
    return schedulersSelectors.selectElements(
      schedulers,
      ids.ids.map((id) => {
        return { id };
      }),
    );
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
