import moment from 'moment';
import React from 'react';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingsReturnWaterfallComponent } from '~src/domain/workspace/components/project/portfolio/contributors/waterfall/holdings-return-waterfall';
import { HoldingsValuationWaterfallComponent } from '~src/domain/workspace/components/project/portfolio/contributors/waterfall/holdings-valuation-waterfall';
import { Period } from '~src/utils/period/period';

import {
  WaterfallType,
  WaterfallTypes,
} from '~src/utils/interfaces/waterfall-type';

interface Props {
  type: WaterfallType;
  workspace: WorkspaceState;
  projectId: string;
  filterKey: string;
  period: Period;
  date: moment.Moment;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
}

export function WaterfallComponent(props: Props) {
  const {
    type,
    workspace,
    projectId,
    filterKey,
    period,
    date,
    holdings,
    baseCurrency,
  } = props;

  switch (type) {
    case WaterfallTypes.Absolute: {
      return (
        <HoldingsValuationWaterfallComponent
          workspace={workspace}
          projectId={projectId}
          period={period}
          date={date}
          holdings={holdings}
          baseCurrency={baseCurrency}
          filterKey={filterKey}
        />
      );
    }
    case WaterfallTypes.Diff: {
      return (
        <HoldingsReturnWaterfallComponent
          workspace={workspace}
          projectId={projectId}
          period={period}
          date={date}
          holdings={holdings}
          baseCurrency={baseCurrency}
          filterKey={filterKey}
        />
      );
    }
    default:
      return <></>;
  }
}
