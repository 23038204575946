import { findIndex } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { PaperState } from '~src/data/store/reducers/finance/paper/reducer';
import { FinancialDataState } from '~src/data/store/reducers/reducers';

import { getQuotesByPaperId } from '../quotes/selectors';

interface Props {
  dates: Date[];
  paper: PaperState;
}

export const getQuoteReturns = createCachedSelector(
  (state: FinancialDataState) => state,
  (_state: FinancialDataState, props: Props) => props.dates,
  (state: FinancialDataState, props: Props) => {
    return getQuotesByPaperId(state, props.paper.id) ?? [];
  },
  (state, dates, quotes) => {
    let indexReturns: number[] = new Array(dates.length);
    for (let i = 0; i < indexReturns.length; ++i) {
      const quoteIdx = findIndex(quotes, (quote) => {
        return (
          quote.date.getFullYear() === dates[i].getFullYear() &&
          quote.date.getMonth() === dates[i].getMonth() &&
          quote.date.getDay() <= dates[i].getDay()
        ); // quotes sorted from newest to oldest
      });
      if (quoteIdx !== -1) {
        indexReturns[i] = quotes[quoteIdx].close ?? 0;
      } else {
        if (quoteIdx + 1 < quotes.length) {
          indexReturns[i] = quotes[quoteIdx + 1].close ?? 0;
        } else {
          indexReturns[i] = 0; // XXX: Should this happen?
        }
      }
    }

    let prevValuation = indexReturns.shift();
    indexReturns = indexReturns.map((value) => {
      let res = value; /// XXX: Should this ever happen?
      if (prevValuation) {
        res = (prevValuation - value) / prevValuation;
      }
      prevValuation = value;
      return res;
    });
    return indexReturns;
  },
)({
  keySelector: (_state: FinancialDataState, props: Props) =>
    `${props.paper.exchange}:${props.paper.symbol}`,
  selectorCreator: createSelector,
});
