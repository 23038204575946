import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

export type SelectedProjectState = string;

export function selectedProjectToSelectedProjectState(
  selectedProject: SelectedProjectState,
) {
  return selectedProject;
}
export const initialState = null;

const adapter = new EntityAdapter<string>(initialState);

export const selectedProjectsSlice = createBaseSlice(
  'selectedProjects',
  adapter,
);
export const selectedProjectsSelectors = adapter.selectors;

export const selectedProjectsReducer = selectedProjectsSlice.reducer;
export const selectedProjectsActions = selectedProjectsSlice.actions;

// export const selectedProjectInitialState = {
//   selectedProjects: initialState,
// };
