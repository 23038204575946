import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/field-config';
import { getBuyFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/buy-field-config';
import { getCostFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/cost-field-config';
import { getDefaultFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/default-field-config';
import { getDividendFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/dividend-field-config';
import { getInterestFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/interest-field-config';
import { getSellFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/sell-field-config';
import { getWithdrawalFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/withdrawal-field-config';

import { TransferType } from '@pladdenico/models';

export const getFieldConfig = (
  transferType: TransferType,
  holding: HoldingState,
  editAll: boolean,
): HoldingFieldConfig => {
  if (transferType === TransferType.Borrow) {
    return getDefaultFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Buy) {
    return getBuyFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Cost) {
    return getCostFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Deposit) {
    return getDefaultFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Dividend) {
    return getDividendFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Interest) {
    return getInterestFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Lend) {
    return getDefaultFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Provision) {
    return getDefaultFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Repayment) {
    return getDefaultFieldConfig(holding, editAll);
  } else if (transferType === TransferType.RevenueFee) {
    return getDefaultFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Sell) {
    return getSellFieldConfig(holding, editAll);
  } else if (transferType === TransferType.Withdrawal) {
    return getWithdrawalFieldConfig(holding, editAll);
  } else if (transferType === TransferType.WrongPayment) {
    return getDefaultFieldConfig(holding, editAll);
  }
  return getDefaultFieldConfig(holding, editAll);
};
