import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import React from 'react';
import { TableActions, TableDrawers, TableField } from '@pladdenico/table';

import { Button, IconButton, Theme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { makeStyles } from 'tss-react/mui';

import { CreateInvestmentComponent } from './create.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InvestmentState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { PrivateEquityFundState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  fund: PrivateEquityFundState;
  createInputInit: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType;

  fields: TableField.Field<InvestmentState>[];

  filters: TableField.Filter<InvestmentState>[];
  dispatchFilters: React.Dispatch<TableActions.FilterAction<InvestmentState>>;

  sorters: TableField.Sorter<InvestmentState>[];
  dispatchSorters: React.Dispatch<TableActions.SorterAction<InvestmentState>>;
}

export const InvestmentsToolbar = (props: Props) => {
  const [createInvestmentDialogOpened, setCreateInvestmentDialogOpened] =
    React.useState(false);

  const { classes } = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);

  const handleAddInvestment = () => {
    setCreateInvestmentDialogOpened(true);
  };

  function handleCloseCreateInvestmentDialog() {
    setCreateInvestmentDialogOpened(false);
  }

  const handleFilterDrawerOpen = () => {
    setFilterOpen(true);
    setSortOpen(false);
  };

  const handleSortDrawerOpen = () => {
    setSortOpen(true);
    setFilterOpen(false);
  };

  const filtersComponent = props.filters.map((filter, index) => {
    return (
      <TableDrawers.FilterComponent
        key={index}
        dispatchFilter={props.dispatchFilters}
        filter={filter}
        textFieldProps={{ sx: { marginBottom: 1 } }}
      />
    );
  });

  const sortersComponent = props.sorters.map((sorter, index) => {
    return (
      <TableDrawers.SorterComponent
        key={index}
        dispatchSorter={props.dispatchSorters}
        sorter={sorter}
      />
    );
  });

  return (
    <div
      // {...rest}
      // className={clsx(classes.root, className)}
      className={classes.root}
    >
      <div className={classes.row}>
        <IconButton onClick={handleFilterDrawerOpen}>
          <FilterListIcon />
        </IconButton>
        <IconButton onClick={handleSortDrawerOpen}>
          <SortIcon />
        </IconButton>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddInvestment}
        >
          Add investment
        </Button>
      </div>
      <CreateInvestmentComponent
        workspace={props.workspace}
        projectId={props.projectId}
        fund={props.fund}
        open={createInvestmentDialogOpened}
        handleClose={handleCloseCreateInvestmentDialog}
        createInputInit={props.createInputInit}
      />
      <TableDrawers.FilterDrawerComponent
        open={filterOpen}
        closeDrawer={() => setFilterOpen(false)}
        filtersComponent={<>{filtersComponent}</>}
      />
      <TableDrawers.SorterDrawerComponent
        open={sortOpen}
        closeDrawer={() => setSortOpen(false)}
        sortersComponent={<>{sortersComponent}</>}
      />
    </div>
  );
};
