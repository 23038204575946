import React from 'react';
import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { PersonDialogComponent } from './person-dialog.component';
import { usePersonActions } from '~src/hooks/actions/workspace/person/use-person-actions';
import { PersonFieldsComponent } from './fields.component';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
}

export const CreatePersonComponent = React.memo((props: Props) => {
  const [personInput, setPersonInput] =
    React.useState<graphqlWorkspaceTypes.CreatePersonInputType>({
      id: uuid.v1(),
      name: '',
      description: '',
      roles: [],
    });
  // const [personDataInput, setPersonDataInput] = React.useState<
  //   PersonDataInput
  // >(undefined);
  const { onCreate } = usePersonActions(props.workspace);

  return (
    <PersonDialogComponent
      title={'Create person'}
      open={props.open}
      handleClose={props.handleClose}
      handleSavePerson={() =>
        onCreate(personInput).then(() => props.handleClose())
      }
    >
      {/* {content} */}
      <form>
        <PersonFieldsComponent
          hasIdField={false}
          personInput={personInput}
          setPersonInput={setPersonInput}
        />
      </form>
    </PersonDialogComponent>
  );
});
