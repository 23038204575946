import { InvestmentState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { PrivateEquityFundInvestment } from '~src/services/graphql/workspace/client/graphql';

export function parseInvestment(
  fundId: string,
  investment: PrivateEquityFundInvestment,
): InvestmentState {
  return {
    id: investment.id,
    fundId,
    description: investment.description,
    name: investment.name,
    exit: investment.exit,
    holdingId: investment.holding.holding.id,
  };
}
