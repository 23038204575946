import React from 'react';
import {} from 'tss-react/mui';
import { useHandler } from '~src/hooks/utils/use-handler';

import { TextField } from '@mui/material';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';

export const useScenarioForecastFieldsComponent = (
  input: ForecastState,
  setInput: (forecast: ForecastState) => void,
) => {
  const { handleEvent } = useHandler(input, setInput);
  const nameElement = React.useMemo(
    () => (
      <TextField
        sx={{ pr: 1 }}
        fullWidth
        autoFocus
        margin="normal"
        required
        id="name"
        value={input.name}
        label="Name"
        type="text"
        onChange={handleEvent('name')}
      />
    ),
    [handleEvent, input.name],
  );
  const descriptionElement = React.useMemo(
    () => (
      <TextField
        fullWidth
        margin="normal"
        required
        id="description"
        value={input.description}
        label="Description"
        type="text"
        onChange={handleEvent('description')}
      />
    ),
    [handleEvent, input.description],
  );
  return {
    nameElement,
    descriptionElement,
  };
};
