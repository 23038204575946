import { Control } from 'react-hook-form';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';
import { useAssetCategoryField } from '~src/domain/workspace/components/asset/form/fields/use-asset-category.field';
import { useAssetEntityField } from '~src/domain/workspace/components/asset/form/fields/use-asset-entity.field';
import { useAssetTypeField } from '~src/domain/workspace/components/asset/form/fields/use-asset-type.field';
import { useAssetTypesField } from '~src/domain/workspace/components/asset/form/fields/use-asset-types.field';
import { FieldsConfig } from '~src/domain/workspace/components/asset/form/fields/fields.config';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useAssetCreateFields = (
  workspace: WorkspaceState,
  control: Control<AssetCreateInputData, any>,
  config?: FieldsConfig,
) => {
  const nameField = useTextField(control, 'name', 'Name', '', {
    required: true,
  });
  const descriptionField = useTextField(
    control,
    'description',
    'Description',
    '',
  );
  const isinField = useTextField(control, 'ISIN', 'ISIN', '');
  const vpsField = useTextField(control, 'VPS', 'VPS', '');
  const lookupField = useTextField(control, 'lookup', 'Lookup', '');
  const typeField = useAssetTypeField(
    control,
    'type',
    'Asset type',
    '',
    config,
  );

  const entityField = useAssetEntityField(
    control,
    'entityId',
    'Entity',
    '',
    workspace,
  );

  const categoryField = useAssetCategoryField(
    control,
    'categoryId',
    'Category',
    '',
    workspace,
  );

  const typesField = useAssetTypesField(control, 'types', 'Types', []);

  return {
    nameField,
    descriptionField,
    isinField,
    vpsField,
    lookupField,
    entityField,
    categoryField,
    typeField,
    typesField,
  };
};
