import { RegionState } from '~src/data/store/reducers/common/regions/reducer';
import { RegionAllocationState } from '~src/data/store/reducers/entity/region-allocations/reducer';

export const getAllocationValue = (
  regions: RegionState[],
  regionAllocation: RegionAllocationState,
) => {
  const region = regions.find(
    (region) => region.id === regionAllocation.regionId,
  );
  if (region) {
    return {
      name: region.name,
      value: regionAllocation.allocation,
    };
  }
  return {
    name: 'unknown',
    value: regionAllocation.allocation,
  };
};

export const updateCountriesMap = (
  countriesMap: Map<string, number>,
  regions: RegionState[],
  regionAllocations: RegionAllocationState[],
  multiple: number,
) => {
  if (regionAllocations && regionAllocations.length > 0) {
    regionAllocations.forEach((regionAllocation) => {
      const region = regions.find(
        (region) => region.id === regionAllocation.regionId,
      );
      if (region) {
        if (!countriesMap.has(region.name)) {
          countriesMap.set(region.name, 0.0);
        }
        const country = countriesMap.get(region.name);
        if (country !== undefined && regionAllocation.allocation) {
          countriesMap.set(
            region.name,
            country + regionAllocation.allocation * multiple,
          );
        }
      }
    });
  } else {
    const country = countriesMap.get('unknown');
    countriesMap.set('unknown', country ?? 0 + multiple * 100);
  }
  return countriesMap;
};
