import { parsePhone } from '~src/data/store/modules/people/phones/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  phonesActions,
  PhoneState,
} from '~src/data/store/reducers/person/phones/reducer';
import { Phone } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface PhoneVisitor {
  visit(phone: PhoneVisitable): PhoneState;
  post(): void;
}

export class PhoneVisitable implements Visitable<PhoneVisitor> {
  constructor(
    private _workspaceId: string,
    private _personId: string,
    private _phone: Phone,
  ) {}
  public accept(visitor: PhoneVisitor) {
    return visitor.visit(this);
  }

  public parse(): PhoneState {
    return parsePhone(this._workspaceId, this._personId, this._phone);
  }
}

export class PhoneStateVisitor implements PhoneVisitor {
  private _phones: PhoneState[];
  constructor(private _dispatch: AppDispatch) {
    this._phones = [];
  }

  public visit(phone: PhoneVisitable): PhoneState {
    const phoneState = phone.parse();
    this._phones.push(phoneState);
    return phoneState;
  }

  post() {
    this._dispatch(phonesActions.upsertManyElements(this._phones));
  }
}
