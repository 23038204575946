import React from 'react';
import { FormComponent as BaseInfoFormComponent } from '~src/domain/workspace/components/entity/entity/forms/create/form.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import { Dialog, Theme, useMediaQuery, useTheme } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  open: boolean;
  handleClose: () => void;
}

export const CreateDialogComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <BaseInfoFormComponent
        workspace={props.workspace}
        handleClose={props.handleClose}
      />
    </Dialog>
  );
});
