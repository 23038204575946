import { createSelector } from 'reselect';
import { UserDataState } from '../../../reducers/reducers';
import { GroupState } from '../../../modules/groups/state';

export const groupByIdSelector = (groups: GroupState[], groupId: number) => {
  if (!groupId) {
    return undefined;
  }
  return groups.find((group) => {
    return group.id === groupId;
  });
};

export const getGroupById = createSelector(groupByIdSelector, (group) => {
  return group;
});

export const selectGroups = (state: UserDataState) => state.group.groups;
