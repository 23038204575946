import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertEmails } from '~src/data/store/modules/people/emails/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'peopleEmails';

export interface Email {
  id: string;
  personId: string;
  value?: Maybe<string>;
}

const header: Array<keyof Email> = ['id', 'personId', 'value'];

export const emailExportHandler = (data: Email[]): ExportHandler<Email> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const emailImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Email> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertEmails(workspace.tenantId, workspace.id, ts)),
  };
};
