import { WorkspaceRoleState } from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';
import { WorkspaceRole } from '~src/services/graphql/workspace/client/graphql';

export function parseWorkspaceRole(
  tenantId: string,
  workspaceRole: WorkspaceRole,
): WorkspaceRoleState {
  return {
    id: workspaceRole.id,
    tenantId,
    name: workspaceRole.name,
    permission: workspaceRole.permission,
    groupId: workspaceRole.group?.id,
    workspaceId: workspaceRole.workspace?.id,
  };
}
