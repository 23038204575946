import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { WarrantHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/warrant/warrant-holding-card.component';

import { useWarrantHoldingInfo } from './use-warrant-holding-info';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const WarrantHoldingCardContainer = React.memo((props: Props) => {
  const { warrantHolding, warrant, asset } = useWarrantHoldingInfo(
    props.workspace.id,
    props.holding.id,
  );
  const { holding, projectId, portfolios, workspace } = props;

  if (!warrantHolding) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <WarrantHoldingCardComponent
      asset={asset}
      holding={holding}
      portfolios={portfolios}
      projectId={projectId}
      warrant={warrant}
      warrantHolding={warrantHolding}
      workspace={workspace}
    />
  );
});
