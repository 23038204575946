import React from 'react';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  CreateTransactionFormComponent,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';

import { Grow, Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  open: boolean;
  handleClose: () => void;
  handleSuccess: (transaction: TransactionState) => void;
  initialFormValues: FormValues;
}

export const CreateComponent = React.memo((props: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      maxWidth={false}
      onClose={props.handleClose}
      TransitionComponent={Grow}
    >
      <CreateTransactionFormComponent
        workspace={props.workspace}
        projectId={props.projectId}
        handleSuccess={props.handleSuccess}
        handleClose={props.handleClose}
        initialFormValues={props.initialFormValues}
      />
    </Dialog>
  );
});
