import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';

export interface BaseWorkspaceRoleId {
  id: number;
}

export type WorkspaceRoleState = Omit<
  graphqlUserTypes.WorkspaceRole,
  'groups' | 'workspace'
> & {
  groupId: number | null | undefined;
  workspaceId: string | null | undefined;
  tenantId: string;
};

export function workspaceRoleToWorkspaceRoleState(
  workspaceRole: WorkspaceRoleState,
) {
  return workspaceRole;
}
export const initialWorkspaceRolesState = [];

const comparator = (a: BaseWorkspaceRoleId, b: BaseWorkspaceRoleId) =>
  a.id - b.id;
const merger = (_a: WorkspaceRoleState, b: WorkspaceRoleState) => b;

const adapter = new EntityArrayAdapter<BaseWorkspaceRoleId, WorkspaceRoleState>(
  initialWorkspaceRolesState,
  comparator,
  merger,
);

export const workspaceRolesSlice = createBaseArraySlice(
  'workspaceRoles',
  adapter,
);
export const workspaceRolesSelectors = adapter.selectors;

export const workspaceRolesReducer = workspaceRolesSlice.reducer;
export const workspaceRolesActions = workspaceRolesSlice.actions;
