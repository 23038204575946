import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  bankNotesSelectors,
  BankNoteState,
  BaseBankNoteId,
} from '~src/data/store/reducers/asset/asset-types/bank-notes/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getBankNotes = (state: WorkspaceDataState) => {
  return state.holding.asset.bankNote.bankNotes;
};

export const getBankNotesByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getBankNotes(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (bankNotesState, workspaceId) => {
    const bankNotes = bankNotesSelectors.selectElementsByKey(
      bankNotesState,
      workspaceId,
    );
    return bankNotes ? bankNotes : [];
  },
)({
  keySelector: (_state, bankNoteId) => bankNoteId,
  selectorCreator: createSelector,
});

// export const bankNoteByIdSelector = (bankNotes: BankNote[], bankNoteId: string) => {
//   return __OLDsortedFind(bankNotes, { id: bankNoteId } as BankNote, (a) => a.id);
// };

export const bankNoteByAccountSelector = (
  bankNotes: BankNoteState[],
  accountNumber: string,
) => {
  if (!accountNumber) {
    return undefined;
  }
  return bankNotes.find((bankNote) => {
    if (bankNote.accountNumber) {
      return bankNote.accountNumber === accountNumber;
    }
    return false;
  });
};

export const getBankNoteById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseBankNoteId>) =>
    getBankNotes(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseBankNoteId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BaseBankNoteId>) => baseId.id,
  (bankNotes, workspaceId, bankNoteId) => {
    if (!workspaceId || !bankNoteId) {
      return undefined;
    }
    return bankNotesSelectors.selectElementByT(bankNotes, {
      workspaceId,
      id: bankNoteId,
    });
  },
)({
  keySelector: (_state, workspaceId, bankNoteId) =>
    `${workspaceId}-${bankNoteId}`,
  selectorCreator: createSelector,
});

interface BankNoteAssetId {
  workspaceId: string;
  assetId: string;
}

export const getBankNoteByAssetId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BankNoteAssetId>) =>
    getBankNotes(state),
  (_state: WorkspaceDataState, baseId: Partial<BankNoteAssetId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BankNoteAssetId>) =>
    baseId.assetId,
  (bankNotes, workspaceId, assetId) => {
    if (!workspaceId || !assetId) {
      return undefined;
    }
    const workspaceBankNotes = bankNotesSelectors.selectElementsByKey(
      bankNotes,
      workspaceId,
    );
    return workspaceBankNotes?.find((bankNote) => bankNote.assetId === assetId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.assetId}`,
  selectorCreator: createSelector,
});
