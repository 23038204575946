import * as React from 'react';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import { InvestmentState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { useHoldingField } from '~src/hooks/fields/workspace/project/holding/use-holding-field';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

function getHoldingId(data: InvestmentState) {
  return data.holdingId;
}

function updateWithHolding(data: InvestmentState, holding: HoldingState) {
  const investment = { ...data, holdingId: holding.id };
  return investment;
}

export function useInvestmentFields(
  workspace: WorkspaceState,
  projectId: string,
): TableField.Field<InvestmentState>[] {
  const holdingField = useHoldingField(
    getHoldingId,
    updateWithHolding,
    workspace,
    projectId,
  );

  const fields: TableField.Field<InvestmentState>[] = [
    {
      id: 'exit',
      name: 'exit',
      field: 'exit',
      type: TableField.FieldTypes.date,
      iteratee: (data) => data.exit,
      update: (data, exit: Date) => {
        const investment = { ...data, exit };
        return investment;
      },
      renderEdit: (editProps) => {
        return (
          <EditDateField
            handleValue={editProps.onChange}
            date={editProps.data.exit}
            labelled={false}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      ...holdingField,
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.name,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const investment = {
          ...data,
          name: String(eventParser(event)),
        };
        return investment;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="name"
            value={editProps.data.name}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.description,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const investment = {
          ...data,
          description: String(eventParser(event)),
        };
        return investment;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="description"
            value={editProps.data.description}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return fields;
}
