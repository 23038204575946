import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SearchResult,
  SearchComponent,
} from '~src/components/search/search-component';
// import { getEvents } from '~src/data/store/modules/events.OLD';
import { getAssetsByWorkspaceId } from '~src/data/store/selectors/asset/assets/selectors';
import { getHoldings } from '~src/data/store/selectors/holding/holdings/selectors';
import { getValuations } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { useAssetsIndex } from '~src/hooks/search/use-assets-index';
// import { useEventsIndex } from '~src/hooks/search/use-events-index';
import { useHoldingsIndex } from '~src/hooks/search/use-holdings-index';
import { usePeopleIndex } from '~src/hooks/search/use-people-index';
import { useValuationsIndex } from '~src/hooks/search/use-valuations-index';
import { getPeopleByWorkspaceId } from '~src/data/store/selectors/person/person/selectors';

interface Props {
  tenantId: string;
  workspaceId: string;
}

export const WorkspaceSearchComponent = React.memo((props: Props) => {
  const assets = useSelector((state: RootState) =>
    getAssetsByWorkspaceId(selectWorkspaceData(state), props.workspaceId),
  );
  const { assetsSearch } = useAssetsIndex(props.workspaceId, assets);
  const holdings = useSelector((state: RootState) =>
    getHoldings(selectWorkspaceData(state)),
  );
  const valuations = useSelector((state: RootState) =>
    getValuations(selectWorkspaceData(state)),
  );
  // const projects = useSelector((state: RootState) => getProjects(state));
  // const holdings = useHoldingsWithProject();
  const { holdingsSearch } = useHoldingsIndex(
    props.tenantId,
    props.workspaceId,
    holdings,
  );
  const { valuationsSearch } = useValuationsIndex(
    props.tenantId,
    props.workspaceId,
    holdings,
    valuations,
  );
  // const events = useSelector((state: RootState) => getEvents(state));
  // const { eventsSearch } = useEventsIndex(props.workspaceId, events);
  const people = useSelector((state: RootState) =>
    getPeopleByWorkspaceId(selectWorkspaceData(state), props.workspaceId),
  );
  const { peopleSearch } = usePeopleIndex(
    props.tenantId,
    props.workspaceId,
    people,
  );

  const search = (query: string) => {
    const assetResults = assetsSearch(query);
    const holdingResults = holdingsSearch(query);
    const valuationResults = valuationsSearch(query);
    const personResults = peopleSearch(query);
    // const eventResults = eventsSearch(query);

    const results = new Map<string, SearchResult[]>();
    if (assetResults.length) {
      results.set('asset', assetResults);
    }
    if (holdingResults.length) {
      results.set('holding', holdingResults);
    }
    if (valuationResults.length) {
      results.set('valuation', valuationResults);
    }
    if (personResults.length) {
      results.set('person', personResults);
    }
    // if (eventResults.length) {
    //   results.set('event', eventResults);
    // }
    return results;
  };

  return (
    <SearchComponent
      search={search}
      // {...this.props}
    />
  );
});
