import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { Transfer } from '~src/services/graphql/workspace/client/graphql';

import { TransferType } from '@pladdenico/models';

export function parseTransfer(
  holdingId: string,
  transfer: Transfer,
): TransferState {
  return {
    id: transfer.id,
    hash: transfer.hash,
    holdingId,
    date: new Date(transfer.date),
    value: transfer.value,
    currencyId: transfer.currency ? transfer.currency.id : '',
    commitmentId: transfer.commitment ? transfer.commitment.id : '',
    cost: transfer.cost,
    description: transfer.description,
    status: transfer.status,
    subtype: transfer.subtype,
    transactionId: transfer.transaction ? transfer.transaction.id : '',
    type: transfer.type as TransferType,
  };
}
