import { PortfolioConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-configs/reducer';
import { PortfolioDashboardConfigState } from '~src/data/store/reducers/portfolio/portfolio-config/portfolio-dashboard-configs/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DashboardConfigInput } from '~src/domain/workspace/components/project/portfolio/config/dashboard/dashboard-config.input';
import { EditDashboardConfig } from '~src/domain/workspace/components/project/portfolio/config/dashboard/edit-dashboard-config';

import { Grid } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  portfolioConfig: PortfolioConfigState | undefined;
  dashboardConfig: PortfolioDashboardConfigState;
}

export const PortfolioDashboardConfigComponent = (props: Props) => {
  const { workspace, portfolioProject, portfolioConfig, dashboardConfig } =
    props;

  const dashboardConfigInput = (portfolioConfig && (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      direction="row"
    >
      <Grid item xs={10}>
        <DashboardConfigInput
          workspace={workspace}
          projectId={portfolioProject.projectId}
          portfolioConfig={portfolioConfig}
          dashboardConfig={dashboardConfig}
          label="Dashboard config"
        />
      </Grid>
      <Grid item xs={2} justifyContent="center">
        <EditDashboardConfig
          workspace={workspace}
          projectId={portfolioProject.projectId}
          dashboardConfig={dashboardConfig}
        />
      </Grid>
    </Grid>
  )) ?? <></>;

  // inverted={true}
  return dashboardConfigInput;
};
