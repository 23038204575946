import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';

export interface BaseTenantRoleId {
  id: number;
}

// export type TenantRoleState = TenantRole;

export type TenantRoleState = Omit<
  graphqlUserTypes.TenantRole,
  'groups' | 'tenant'
> & {
  groupId: number | null | undefined;
  tenantId: string | null | undefined;
};

export function tenantRoleToTenantRoleState(tenantRole: TenantRoleState) {
  return tenantRole;
}
export const initialTenantRolesState = [];

const comparator = (a: BaseTenantRoleId, b: BaseTenantRoleId) => a.id - b.id;
const merger = (_a: TenantRoleState, b: TenantRoleState) => b;

const adapter = new EntityArrayAdapter<BaseTenantRoleId, TenantRoleState>(
  initialTenantRolesState,
  comparator,
  merger,
);

export const tenantRolesSlice = createBaseArraySlice('tenantRoles', adapter);
export const tenantRolesSelectors = adapter.selectors;

export const tenantRolesReducer = tenantRolesSlice.reducer;
export const tenantRolesActions = tenantRolesSlice.actions;
