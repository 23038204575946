import React from 'react';
import { WorkspaceRoleState } from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';

interface Props {
  handleValue: (workspaceRole: WorkspaceRoleState) => void;
  workspaceRoles: WorkspaceRoleState[];
  workspaceRole: WorkspaceRoleState | undefined;
  labelled: boolean;
}

export const EditWorkspaceRoleField = React.memo((props: Props) => {
  return (
    <Autocomplete
      options={props.workspaceRoles}
      getOptionLabel={(workspaceRole: WorkspaceRoleState) =>
        workspaceRole.name ? workspaceRole.name : ''
      }
      style={{ width: 300 }}
      value={props.workspaceRole ? props.workspaceRole : null}
      onChange={(
        _event: React.ChangeEvent<unknown>,
        newValue: WorkspaceRoleState | null,
      ) => {
        if (newValue) {
          props.handleValue(newValue);
        }
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={props.labelled ? 'WorkspaceRole' : undefined}
          fullWidth
          margin="normal"
        />
      )}
    />
  );
});
