import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Button } from '@mui/material';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  budget: Budget;
  holding: HoldingState;
  forecast: ForecastState;
  evaluate: () => void;
}

export const HoldingForecastToolbarComponent = React.memo((props: Props) => {
  const { evaluate } = props;

  return (
    <Grid container>
      <Grid>
        <Button onClick={evaluate}>Evaluate</Button>
      </Grid>
    </Grid>
  );
});
