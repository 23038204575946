import moment from 'moment';
import { DataState } from '~src/data/store/reducers/reducers';

import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getCommitmentsByHoldingIds } from '~src/data/store/selectors/holding/commitment/commitments/selectors';
import { getExchangeRateState } from '~src/data/store/selectors/finance/exchange-rates/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import createCachedSelector from 're-reselect';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';

interface Props {
  holdingIds: string[];
  date: moment.Moment;
  currency: CurrencyState;
}

export const getHoldingsTotalCommitment = createCachedSelector(
  (state: DataState, props: Props) =>
    getCommitmentsByHoldingIds(
      selectWorkspaceDataFromData(state),
      props.holdingIds,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (commitments, date, currency, exchangeRates) => {
    return commitments.reduce((prev, curr) => {
      if (curr && curr.value) {
        const exchangeRate = convert(
          exchangeRates,
          curr.currencyId,
          currency.id,
          date.toDate(),
        );
        prev += curr.value * exchangeRate;
      }
      return prev;
    }, 0);
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.holdingIds.join(';')}:${props.date.unix()}:${
      props.currency.id
    }`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});

interface Props {
  holdingIds: string[];
  date: moment.Moment;
  currency: CurrencyState;
}
