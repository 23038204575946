import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { QueryContextTypes, QueryUserContext } from '@pladdenico/portfolio-api';

import {
  BaseRegionId,
  regionsActions,
} from '../../reducers/common/regions/reducer';
import { handleRegions } from './handler';

export function fetchRegions(ids: string[] | null) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.GetRegionsDocument;
    const variables: graphqlUserTypes.GetRegionsQueryVariables = {
      ids,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getRegions) {
        return handleRegions(dispatch, data.getRegions);
      }
      return undefined;
    });
  };
}

export function createRegions(
  inputs: graphqlUserTypes.CreateRegionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.CreateRegionsDocument;
    const variables: graphqlUserTypes.CreateRegionsMutationVariables = {
      inputs,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createRegions) {
        return handleRegions(dispatch, data.createRegions);
      }
      throw new Error('Could not create region');
      // return undefined;
    });
  };
}

export function updateRegions(
  inputs: graphqlUserTypes.UpdateRegionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.UpdateRegionsDocument;
    const variables: graphqlUserTypes.UpdateRegionsMutationVariables = {
      inputs,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateRegions) {
        return handleRegions(dispatch, data.updateRegions);
      }
      return [];
    });
  };
}

export function upsertRegions(
  inputs: graphqlUserTypes.UpsertRegionInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.UpsertRegionsDocument;
    const variables: graphqlUserTypes.UpsertRegionsMutationVariables = {
      inputs,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertRegions) {
        return handleRegions(dispatch, data.upsertRegions);
      }
      return [];
    });
  };
}

export function deleteRegions(ids: BaseRegionId[]) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.DeleteRegionsDocument;
    const variables: graphqlUserTypes.DeleteRegionsMutationVariables = {
      inputs: ids.map((id) => {
        return { id: id.id };
      }),
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteRegions) {
        const deletedIds = data.deleteRegions;
        dispatch(
          regionsActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
