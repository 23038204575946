export const defaultValueBackgroundColor = '#FFFFFF';
export const defaultValueFontColor = '#000000';

export const defaultCashOnCashRatioBackgroundColor = '#FFFFFF';
export const defaultCashOnCashRatioFontColor = '#000000';

export const defaultCashFlowBackgroundColor = '#FFFFFF';
export const defaultCashFlowFontColor = '#000000';

export const defaultIrrBackgroundColor = '#FFFFFF';
export const defaultIrrFontColor = '#000000';

export const defaultValueChangeBackgroundColor = '#FFFFFF';
export const defaultValueChangeFontColor = '#000000';

// export const defaultValueBackgroundColor = '#063044';
// export const defaultValueFontColor = '#61BBB1';

// export const defaultCashOnCashRatioBackgroundColor = '#063044';
// export const defaultCashOnCashRatioFontColor = '#61BBB1';

// export const defaultCashFlowBackgroundColor = '#063044';
// export const defaultCashFlowFontColor = '#61BBB1';

// export const defaultIrrBackgroundColor = '#063044';
// export const defaultIrrFontColor = '#61BBB1';

// export const defaultValueChangeBackgroundColor = '#063044';
// export const defaultValueChangeFontColor = '#61BBB1';
