import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseInvestorId {
  id: string;
  fundId: string;
}

export type InvestorState = graphqlWorkspaceTypes.PrivateEquityFundInvestor & {
  fundId: string;
};

export const initialState: RecordState<InvestorState, string>[] = [];

const selectKeyId = (investor: BaseInvestorId) => investor.fundId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseInvestorId, b: BaseInvestorId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseInvestorId, InvestorState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const investorsSlice = createBaseRecordSlice('investors', adapter);
export const investorsSelectors = adapter.selectors;

export const investorsReducer = investorsSlice.reducer;
export const investorsActions = investorsSlice.actions;
