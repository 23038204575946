export const SummaryTypes = {
  TotalValue: 'totalValue',
  Irr: 'irr',
  Beta: 'beta',
  Volatility: 'volatility',
  TotalCommitments: 'totalCommitments',
} as const;

export type SummaryType = (typeof SummaryTypes)[keyof typeof SummaryTypes];
export function typeToString(type: SummaryType) {
  if (type === SummaryTypes.TotalValue) {
    return 'Total value';
  } else if (type === SummaryTypes.Irr) {
    return 'IRR';
  } else if (type === SummaryTypes.Beta) {
    return 'Beta';
  } else if (type === SummaryTypes.Volatility) {
    return 'Volatility';
  } else if (type === SummaryTypes.TotalCommitments) {
    return 'Total commitments';
  }
  return 'undefined';
}
