import {
  RoleStateVisitor,
  RoleVisitable,
} from '~src/data/store/visitors/person/role-visitor';
import { Role } from '~src/services/graphql/workspace/client/graphql';

export function handleRoles(
  dispatch: any,
  _tenantId: string,
  workspaceId: string,
  roles: Role[],
) {
  const visitor = new RoleStateVisitor(dispatch);
  const res = roles.map((role) => {
    const visitable = new RoleVisitable(
      workspaceId,
      role.person?.id ?? '',
      role,
    );
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
