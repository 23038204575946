import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import { combineReducers } from 'redux';

export interface BaseFundId {
  id: string;
  workspaceId: string;
}

export type FundState = Omit<graphqlWorkspaceTypes.Fund, 'asset' | '_type'> & {
  workspaceId: string;
  assetId: string;
};

export function fundToFundState(fund: FundState) {
  return fund;
}
const initialState: RecordState<FundState, string>[] = [];

const selectKeyId = (fund: BaseFundId) => fund.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseFundId, b: BaseFundId) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseFundId, FundState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const fundsSlice = createBaseRecordSlice('funds', adapter);
export const fundsSelectors = adapter.selectors;

export const fundsReducer = fundsSlice.reducer;
export const fundsActions = fundsSlice.actions;

export const initialFundState = {
  funds: initialState,
};

export const fundReducer = combineReducers({
  funds: fundsReducer,
});
