import { strcmp } from '@pladdenico/common';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseFundPositionId {
  fundHoldingId: string;
  id: string;
}

export type FundPositionState = {
  id: string;
  fundHoldingId: string;
  date: Date;
  shares: number;
};

export const initialFundPositionState = [];

const selectKeyId = (fundPosition: BaseFundPositionId) =>
  fundPosition.fundHoldingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseFundPositionId, b: BaseFundPositionId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseFundPositionId,
  FundPositionState,
  string
>(initialFundPositionState, selectKeyId, keyComparator, tComparator);

export const fundPositionsSlice = createBaseRecordSlice(
  'fundPositions',
  adapter,
);
export const selectors = adapter.selectors;

export const fundPositionsReducer = fundPositionsSlice.reducer;
export const fundPositionsActions = fundPositionsSlice.actions;
