import * as React from 'react';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import {
  HoldingState,
  BaseHoldingId,
} from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingValuationComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/holding-valuation.component';
import { HoldingCardPortfoliosComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/portfolios.component';
import { useHoldingCardMenuComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/use-holding-card-menu.component';

import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getCategoryById } from '~src/data/store/selectors/asset/categories/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

const PREFIX = 'BaseHoldingCardComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  holding: `${PREFIX}-holding`,
  asset: `${PREFIX}-asset`,
  description: `${PREFIX}-description`,
  menuButton: `${PREFIX}-menuButton`,
  statistic: `${PREFIX}-statistic`,
  statisticLabel: `${PREFIX}-statisticLabel`,
  holdingName: `${PREFIX}-holdingName`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    flex: 1,
  },

  [`& .${classes.holding}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.asset}`]: {},

  [`& .${classes.description}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 4,
    overflow: 'hidden',
  },

  [`& .${classes.menuButton}`]: {
    textAlign: 'center',
  },

  [`& .${classes.statistic}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },

  [`& .${classes.statisticLabel}`]: {
    textTransform: 'uppercase',
  },

  [`& .${classes.holdingName}`]: {},
}));

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  asset: AssetState | undefined;
  portfolios: PortfolioState[];
  assetDetailElement: JSX.Element;
  getDialogComponent: (
    editing: boolean,
    handleClose: () => void,
  ) => JSX.Element;
  deleteHolding: () => Promise<BaseHoldingId[] | undefined>;
}

export const BaseHoldingCardComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holding,
    asset,
    assetDetailElement,
    getDialogComponent,
    deleteHolding,
  } = props;
  const {
    menuButtonElement,
    menuElement,
    editing,
    setEditing,
    // handleClose,
  } = useHoldingCardMenuComponent(workspace, projectId, holding, deleteHolding);
  const category = useSelector((state: RootState) =>
    getCategoryById(selectWorkspaceData(state), {
      id: props.asset?.categoryId ?? undefined,
      workspaceId: props.workspace.id,
    }),
  );

  const editDialogComponent = React.useMemo(() => {
    const closeEdit = () => setEditing(false);
    return getDialogComponent(editing, closeEdit);
  }, [editing, setEditing, getDialogComponent]);

  const assetElement = asset ? (
    <Typography variant="caption" align="center">
      {category?.name} - {asset.type}
    </Typography>
  ) : undefined;

  return (
    <Root>
      <Paper className={classes.paper} variant="outlined">
        <Grid
          container
          className={classes.holding}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={3}
            alignItems="center"
            justifyContent="center"
          >
            <div className={classes.asset}>
              <Typography variant="body1" className={classes.holdingName}>
                {props.holding.name}
              </Typography>
              {assetElement}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={3}
            alignItems="center"
            justifyContent="center"
          >
            <div className={classes.asset}>{assetDetailElement}</div>
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="center"
          >
            <HoldingValuationComponent holding={holding} />
          </Grid>
          <Grid
            container
            item
            xs={3}
            alignItems="center"
            justifyContent="center"
          >
            <HoldingCardPortfoliosComponent
              portfolios={props.portfolios}
              workspace={props.workspace}
              projectId={props.projectId}
            />
          </Grid>
          <Grid
            item
            container
            xs={1}
            alignItems="center"
            justifyContent="center"
            className={classes.menuButton}
          >
            {menuButtonElement}
          </Grid>
        </Grid>
      </Paper>
      {menuElement}
      {editDialogComponent}
    </Root>
  );
});
