import {
  fetchProjectObjShallows,
  handleDeleteProjects,
} from '~src/data/store/modules/workspaces/projects/base/requests';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { apis } from '~src/services/request/apis';
import { AppDispatch } from '~src/store/store';

import { Operation, projectPath } from '@pladdenico/portfolio-api';

export function subscribeToProjects(
  dispatch: AppDispatch,
  tenantId: string,
  workspaceId: string,
  projects: ProjectState[],
  subscriptions: Operation[],
) {
  projects.forEach((project) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: projectPath(tenantId, workspaceId).objectPath(
              project.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(
                fetchProjectObjShallows(tenantId, workspaceId, [res.id]),
              );
            },
          },
          () => {
            // console.log('subscribed to project', operation, project.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: projectPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(
                fetchProjectObjShallows(tenantId, workspaceId, [res.id]),
              );
            },
          },
          () => {
            // console.log('subscribed to project', operation, project.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: projectPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteProjects(project.workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to project', operation, project.id);
          },
        );
      }
    });
  });
}
