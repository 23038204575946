import moment from 'moment';
import React from 'react';
import { ElementProps } from '~src/components/dashboard/element';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { GoalRulesComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/goal-rules.component';
import { useGoalRuleMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/use-menu.hook';
import {
  defaultBackgroundColor,
  defaultFontColor,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/default-colors';
// import { useHeaderMenu } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/goal-rule/use-goal-rule-menu.hook';
import { Config } from '~src/utils/interfaces/config';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  config: Config;
}

export const GoalRuleElement = React.memo(
  ({
    workspace,
    project,
    holdings,
    date,
    currency,
    config,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = useGoalRuleMenu(
      element,
      remove,
      updated,
    );
    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? defaultFontColor,
          backgroundColor:
            element.config.backgroundColor ?? defaultBackgroundColor,
          padding: element.config.padding,
        },
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          <GoalRulesComponent
            workspace={workspace}
            currency={currency}
            date={date}
            holdings={holdings}
            projectId={project.id}
          />
        </ElementComponent>
        {menu}
      </>
    );
  },
);
