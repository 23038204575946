import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { AssetForm } from '~src/domain/workspace/components/asset/form/create/use-asset-create-form';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  assetForm: AssetForm;
  handleClose: () => void;
}
const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const AssetBaseCreateDialogContentComponent = (props: Props) => {
  const { classes } = useStyles();
  const { assetForm, handleClose } = props;

  return (
    <>
      <DialogTitle id="form-dialog-title">Create asset</DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid2 container xs={12} columnSpacing={1}>
            {/* <Grid2 xs={12}>
            <Typography variant="overline">Asset</Typography>
          </Grid2> */}
            <Grid2 xs={6}>
              {assetForm.fields.nameField}
              {assetForm.fields.typeField}
              {assetForm.fields.typesField}
            </Grid2>
            <Grid2 xs={6}>
              {assetForm.fields.categoryField}
              {assetForm.fields.isinField}
              {assetForm.fields.lookupField}
              {assetForm.fields.vpsField}
            </Grid2>
            <Grid2 xs={12}>
              {assetForm.fields.descriptionField}
              {assetForm.fields.entityField}
            </Grid2>
          </Grid2>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};
