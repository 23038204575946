import * as uuid from 'uuid';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCurrencyId } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/get-currency';
import { getTransactionType } from '~src/services/xlsx/handlers/adapters/nordnet/transaction/get-transaction-type';
import {
  nordnetTransactionsActions,
  NordnetTransactionState,
} from '~src/services/xlsx/handlers/adapters/nordnet/transaction/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import { AppDispatch } from '~src/store/store';

import { NordnetTransaction } from './transaction';

export const handlerType = 'transactions';

const header: Array<keyof NordnetTransaction> = [
  'nordnetId',
  'date',
  'tradeDate',
  'settledDate',
  'account',
  'transactionString',
  'paper',
  'holdingType',
  'ISIN',
  'volume',
  'price',
  'interest',
  'fees',
  'currency',
  'bankNoteCurrency',
  'value',
  'endValue',
  'endVolume',
  'endBankNoteValue',
  'exchangeRate',
  'description',
  'completedNumber',
  'verificationNumber',
];

const headerMapper = new Map<string, keyof NordnetTransaction>([
  ['Id', 'nordnetId'],
  ['Bokføringsdag', 'date'],
  ['Handelsdag', 'tradeDate'],
  ['Oppgjørsdag', 'settledDate'],
  ['Portefølje', 'account'],
  ['Transaksjonstype', 'transactionString'],
  ['Verdipapir', 'paper'],
  ['Type verdipapir', 'holdingType'],
  ['ISIN', 'ISIN'],
  ['Antall', 'volume'],
  ['Kurs', 'price'],
  ['Rente', 'interest'],
  ['Totale Avgifter', 'fees'],
  ['Valuta', 'bankNoteCurrency'],
  // ['Valuta_1', 'currency'],
  ['Valuta_2', 'currency'],
  // ['Valuta_3', 'currency'],
  // ['Valuta_4', 'currency'],
  ['Beløp', 'value'],
  ['Kjøpsverdi', 'endValue'],
  ['Totalt antall', 'endVolume'],
  ['Saldo', 'endBankNoteValue'],
  ['Vekslingskurs', 'exchangeRate'],
  ['Transaksjonstekst', 'description'],
  ['Sluttseddelnummer', 'completedNumber'],
  ['Verifikationsnummer', 'verificationNumber'],
]);

export const nordnetTransactionImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<NordnetTransaction> => {
  return {
    type: handlerType,
    header,
    handler: (ts) => {
      console.log(workspace.tenantId, workspace.id, ts);
      let previousDate: Date | null = null;
      let counter = 1;
      const nordnetTransactions: NordnetTransactionState[] = ts.map(
        (t, index) => {
          // Last transaction in excel is the first to be used for the saldo on the account
          let date = new Date(t.date.getTime() + 3600 * 1000);
          if (
            previousDate != null &&
            date.getTime() === previousDate.getTime()
          ) {
            date = new Date(date.getTime() - counter * 1000);
            ++counter;
          } else {
            counter = 1;
            previousDate = date;
          }
          return {
            ...t,
            id: `${t.account}-${t.ISIN}-${date.getTime()}-${index}`,
            date,
            adapterId: 'nordnet',
            handled: false,
            transactionId: null,
            holdingId: null,
            bankNoteHoldingId: null,
            transactionType: getTransactionType(t.transactionString),
            currencyId: getCurrencyId(t.currency),
            bankNoteCurrencyId: getCurrencyId(t.bankNoteCurrency),
            groupId: uuid.v1(),
          };
        },
      );
      dispatch(
        nordnetTransactionsActions.upsertManyElements(nordnetTransactions),
      );
      return Promise.resolve();
    },
    headerMapper,
  };
};
