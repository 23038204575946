import React from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { CreateBondPositionInfoFieldsComponent } from './create-info-fields.component';

interface Props {
  bondPositionInput: graphqlWorkspaceTypes.CreateBondPositionInputType;
  setBondPositionInput: (
    bondPositionInput: graphqlWorkspaceTypes.CreateBondPositionInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateBondPositionFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <CreateBondPositionInfoFieldsComponent
        setBondPositionInput={props.setBondPositionInput}
        bondPositionInput={props.bondPositionInput}
      />
    </form>
  );
});
