import { PortfolioProjectScenarioTabs } from '~src/domain/workspace/components/project/scenario/tabs/tabs';
import { workspaceProjectRootPath } from '~src/navigation/paths/workspace/project/paths';

export const rootScenarioPath =
  '/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId/scenarios/:scenarioId';

export const scenariosBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
) => {
  return `${workspaceProjectRootPath(
    tenantId,
    workspaceId,
    projectId,
  )}/scenarios`;
};

export const scenarioBasePath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  scenarioId: string,
) => {
  return `${scenariosBasePath(tenantId, workspaceId, projectId)}/${scenarioId}`;
};

export const scenarioTabPath = (
  tenantId: string,
  workspaceId: string,
  projectId: string,
  scenarioId: string,
  tab: PortfolioProjectScenarioTabs,
) => {
  return `${scenarioBasePath(
    tenantId,
    workspaceId,
    projectId,
    scenarioId,
  )}/${tab}`;
};
