// import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
// import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditCurrencyField } from '~src/components/fields/financial/currency/edit-currency-field';
import { upsertOne } from '~src/data/store/modules/config/reducer';
import { getConfigById } from '~src/data/store/selectors/config/selectors';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { selectedCurrenciesActions } from '~src/data/store/reducers/finance/currency/selected/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getSelectedCurrencyState } from '~src/data/store/selectors/finance/currencies/selected/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { AppDispatch } from '~src/store/store';
// import { Period } from '~src/models/classes/period';
import { Config } from '~src/utils/interfaces/config';

import { Container, FormControlLabel, Switch, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },

  formLabel: {
    marginBottom: theme.spacing(1),
  },
}));

export const ConfigComponent = React.memo((props: Props) => {
  const config = useSelector((state: RootState) =>
    getConfigById(selectWorkspaceData(state), { id: 'default' }),
  );

  if (config) {
    return <ConfigComponentWithConfig {...props} config={config} />;
  }
  return <div>No valid config</div>;
});

interface PropsWithConfig extends Props {
  config: Config;
}

export const ConfigComponentWithConfig = React.memo(
  (props: PropsWithConfig) => {
    const { classes } = useStyles();

    const baseCurrencyId = useSelector((state: RootState) =>
      getSelectedCurrencyState(selectFinancialData(state)),
    );
    const [_config, _setConfig] = React.useState(props.config);
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
      _setConfig(props.config);
    }, [props.config]);

    const handleConfigUpdate = () => {
      // console.log(event, data);
      updateConfig(_config);
    };

    // const handleConfigIndexPaperChanged = (event: any, data: any) => {
    const handleConfigIndexPaperChanged = (event: any) => {
      console.log(event);
      const config = { ..._config };
      const value = event.target.value;
      const separatorIdx = value.lastIndexOf('.');
      config.indexPaper = {
        id: '',
        symbol: separatorIdx === -1 ? value : value.slice(0, separatorIdx),
        exchange: separatorIdx === -1 ? '' : value.slice(separatorIdx + 1),
        currencyId: '__CURRENCY__NOK',
      };
      _setConfig(config);
    };

    const handleConfigMinimumValuationForAssetChanged = (event: any) => {
      // const handleConfigMinimumValuationForAssetChanged = (event: any, data: any) => {
      console.log(event);
      const config = { ..._config };
      const value = +event.target.value;
      config.minimumValuationForHolding = value;
      _setConfig(config);
    };

    const onDateChange = (date: any) => {
      const config = { ..._config };
      // config.date = moment(date);
      config.date.set(date.toObject());
      _setConfig(config);
      updateConfig(config);
    };

    const onPeriodStartChange = (date: any) => {
      const config = { ..._config };
      // const period = new Period(moment(config.period.start), moment(config.period.end));
      const period = config.period;
      const newDate = moment(date);
      period.start = moment(newDate);
      if (period.end <= newDate) {
        period.end = newDate.add(1, 'year');
      }
      // config.period = period;
      _setConfig(config);
      updateConfig(config);
    };

    const onPeriodEndChange = (date: any) => {
      const config = { ..._config };
      // const period = new Period(moment(config.period.start), moment(config.period.end));
      const period = config.period;
      const newDate = moment(date);
      period.end = moment(newDate);
      if (period.start >= newDate) {
        period.start = newDate.add(-1, 'year');
      }
      // config.period = period;
      _setConfig(config);
      updateConfig(config);
    };

    const handleOnChangeBaseCurrency = (currency: CurrencyState): void => {
      dispatch(selectedCurrenciesActions.set(currency.id));
    };

    const handleConfigUseLiveValueChanged = (
      event: React.ChangeEvent<unknown>,
      checked: boolean,
    ) => {
      const config = { ..._config };
      config.useLiveValue = checked;
      _setConfig(config);
      updateConfig(config);
    };

    const updateConfig = (config: Config) => {
      dispatch(upsertOne({ id: 'default', config }));
    };

    // inverted={true}
    return (
      <Container className={classes.container}>
        <div>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label={'Valuation date'}
            value={_config.date}
            onChange={onDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>{' '}
        <div>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label={'Start date'}
            value={_config.period.start}
            minDate={_config.minDate}
            onChange={onPeriodStartChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div>
          <DatePicker
            showToolbar={false}
            inputFormat="DD/MM/YYYY"
            label={'End date'}
            value={_config.period.end}
            onChange={onPeriodEndChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div>
          <EditCurrencyField
            currencyId={baseCurrencyId}
            handleValue={handleOnChangeBaseCurrency}
            labelled={true}
            handlePersist={handleConfigUpdate}
          />
        </div>
        {/* <Dropdown
        placeholder="Currency" name="currency" value={baseCurrencyId}
        fluid={true} multiple={false} search={true} selection={true} allowAdditions={true}
        options={config.currencyOptions}
        onChange={handleOnChangeBaseCurrency}
        onAddItem={handleOnAddCurrency}
        onBlur={handleConfigUpdate}/> */}
        <div>
          <TextField
            margin="normal"
            // className={classes.textField}
            label="indexPaper"
            required
            id="indexPaper"
            value={`${_config.indexPaper.symbol}.${_config.indexPaper.exchange}`}
            type="text"
            onChange={handleConfigIndexPaperChanged}
            onBlur={handleConfigUpdate}
          />
        </div>
        {/* <Input label="indexPaper" labelPosition="left"
        type="text" fluid={true}
        transparent={false} value={`${config.indexPaper.paper}.${config.indexPaper.exchange}`}
        onChange={handleConfigIndexPaperChanged}
        onBlur={handleConfigUpdate}/> */}
        <div>
          <TextField
            margin="normal"
            label="Minimum valuation"
            // className={classes.textField}
            required
            id="indexPaper"
            value={`${_config.minimumValuationForHolding}`}
            type="number"
            onChange={handleConfigMinimumValuationForAssetChanged}
            onBlur={handleConfigUpdate}
          />
        </div>
        <div>
          <FormControlLabel
            value="useLiveValue"
            checked={_config.useLiveValue}
            onChange={handleConfigUseLiveValueChanged}
            control={<Switch color="primary" />}
            label="Use live value"
            labelPlacement="start"
          />
        </div>
        {/* <Input label="minimumValuationForAsset" labelPosition="left"
        type="number" fluid={true}
        transparent={false} value={`${config.minimumValuationForHolding}`}
        onChange={handleConfigMinimumValuationForAssetChanged}
        onBlur={handleConfigUpdate}/> */}
      </Container>
    );
  },
);
