import React from 'react';
import { VariableValue } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/number/variable-value';

import { Box, Typography } from '@mui/material';

export const useEvaluationState = () => {
  const [evaluationState, setEvaluationState] = React.useState(
    () => new Array<VariableValue>(),
  );

  const element = React.useMemo(() => {
    if (evaluationState.length > 0) {
      const elements = [];
      for (let idx = 0; idx < evaluationState.length; ++idx) {
        const element = (
          <Box key={idx}>
            <Typography variant="body2">
              {evaluationState[idx].date.format('DD/MM/YYYY')}
            </Typography>
            <Typography variant="body2">
              {evaluationState[idx].valuation.value}
            </Typography>
          </Box>
        );
        elements.push(element);
      }
      return elements;
    } else {
      return <div>No elements</div>;
    }
  }, [evaluationState]);

  return {
    setEvaluationState,
    element,
  };
};
