import React from 'react';
import { RoleState } from '~src/data/store/reducers/person/roles/reducer';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { RoleFormComponent, RoleInput } from './form.component';

interface Props {
  title: string;
  open: boolean;
  workspaceId: string;
  roleInput: RoleInput;
  setRoleInput: (roleInput: RoleInput) => void;

  handleSaveRole: () => Promise<RoleState>;
  handleClose: () => void;
  savedCallback: () => void;
}

export const RoleDialogComponent = React.memo((props: Props) => {
  const handleSaveRole = (): void => {
    props
      .handleSaveRole()
      .then((_value) => {
        props.savedCallback();
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', props.roleInput, JSON.stringify(err));
      });
  };

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <RoleFormComponent
          workspaceId={props.workspaceId}
          roleInput={props.roleInput}
          setRoleInput={props.setRoleInput}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveRole} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
