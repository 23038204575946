import { compact } from 'lodash';
import * as React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { TypedValue } from '~src/data/store/selectors/finance/valuation/valuation-types';
import { useValuationActions } from '~src/domain/workspace/components/project/valuation/use-valuation-actions';
import { ValuationsToolbar } from '~src/domain/workspace/components/project/valuation/valuations-toolbar.component';
// import { ValuationsToolbar } from '~src/domain/workspace/components/project/valuation/valuations-toolbar.component';
import { useValuationFields } from '~src/hooks/fields/workspace/project/holding/use-valuation-fields';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  valuations: TypedValue[];
  valuationCreateInputInit: graphqlWorkspaceTypes.CreateValuationInputType;
}

const useStyles = makeStyles()((theme: Theme) => ({
  // root: {
  //   padding: theme.spacing(3),
  // },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export type ValuationData = TypedValue;

function valuationsToData(values: TypedValue[]): ValuationData[] {
  return values.map((value) => {
    return {
      // id: value.id,
      ...value,
    };
  });
}

export const ValuationsComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [data, setData] = React.useState<ValuationData[]>([]);
  const { onRowUpdate, onRowDelete } = useValuationActions(props.workspace);
  React.useEffect(() => {
    setData(valuationsToData(props.valuations));
    // setData(props.valuations);
  }, [props.valuations]);

  const fields = useValuationFields(props.workspace, props.projectId);

  const [initialSorters] = React.useState<TableField.Sorter<ValuationData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  const tableComponent = React.useMemo(() => {
    return (
      <TableComponent<ValuationData>
        data={filtered}
        fields={fields}
        selectedData={selectedData}
        selectable={false}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
        editable={{ onRowUpdate, onRowDelete }}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
        // handleSetUpdateState={setUpdateState}
        isSelected={isSelected}
      />
    );
  }, [
    dispatchFilters,
    dispatchSorters,
    fields,
    filtered,
    filters,
    handleSelectAll,
    handleSelectOne,
    isSelected,
    onRowDelete,
    onRowUpdate,
    selectedData,
    sorters,
  ]);

  return (
    <div className={classes.root}>
      <ValuationsToolbar
        workspace={props.workspace}
        projectId={props.projectId}
        valuationCreateInputInit={props.valuationCreateInputInit}
        fields={fields}
        filters={filters}
        dispatchFilters={dispatchFilters}
        sorters={sorters}
        dispatchSorters={dispatchSorters}
      />
      <div className={classes.content}>{tableComponent}</div>
    </div>
  );
});
