import 'reactflow/dist/style.css';
import '../../index.css';

import React from 'react';
import { Node } from 'reactflow';
import {
  BaseNodeData,
  NodeEvaluator,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import {
  ExpressionHandler,
  initMath,
} from '~src/domain/workspace/components/project/scenario/models/forecast/expression-handler';
import { MathJax } from 'better-react-mathjax';
import { eventParser } from '~src/utils/form/event-parser';
import { Expression } from '~src/domain/workspace/components/project/scenario/models/forecast/expression';

export const useExpressionFields = (
  node: Node<BaseNodeData>,
  updateData: (data: BaseNodeData) => void,
) => {
  const expressionHandler = React.useMemo(() => {
    const expressions = node.data.evaluator?.expressions ?? [];
    return new ExpressionHandler(initMath(), node.id, '', expressions, []);
  }, [node.data.evaluator?.expressions, node.id]);

  const metaString = React.useMemo(() => {
    const latex = expressionHandler.getLatex();
    return latex.map((v, idx) => {
      return (
        <Box key={idx} sx={{ m: 3 }}>
          <MathJax>{v}</MathJax>
        </Box>
      );
    });
  }, [expressionHandler]);

  const expressionsElement = React.useMemo(() => {
    const expressions = node.data.evaluator?.expressions;
    if (expressions == null || expressions.length === 0) {
      return undefined;
    }
    const elements = expressions.map((expression, idx) => {
      return (
        <Grid container key={idx} alignItems="center">
          <Grid item xs={8}>
            <TextField
              multiline
              fullWidth
              // rows={2}
              margin="normal"
              required
              // id="expression"
              value={expression.value}
              label="Expression"
              type="text"
              onChange={(event) => {
                const updatedExpressions: Expression[] = [
                  ...expressions.slice(0, idx),
                  { ...expression, value: eventParser(event) as string },
                  ...expressions.slice(idx + 1),
                ];
                const evaluator: NodeEvaluator = {
                  ...node.data.evaluator,
                  expressions: updatedExpressions,
                };
                const data = {
                  ...node.data,
                  evaluator,
                };
                updateData(data);
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="In result"
              control={
                <Checkbox
                  checked={expression.inResult}
                  onChange={(_event, checked) => {
                    const updatedExpressions: Expression[] = [
                      ...expressions.slice(0, idx),
                      { ...expression, inResult: checked },
                      ...expressions.slice(idx + 1),
                    ];
                    const evaluator: NodeEvaluator = {
                      ...node.data.evaluator,
                      expressions: updatedExpressions,
                    };
                    const data = {
                      ...node.data,
                      evaluator,
                    };
                    updateData(data);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      );
    });
    return elements;
  }, [node, updateData]);

  const addExpressionElement = React.useMemo(() => {
    const expressions = node.data.evaluator?.expressions;
    if (expressions == null) {
      return <></>;
    }
    return (
      <Button
        onClick={() => {
          const evaluator: NodeEvaluator = {
            ...node.data.evaluator,
            expressions: [...expressions, { inResult: false, value: '' }],
          };
          const data = {
            ...node.data,
            evaluator,
          };
          updateData(data);
        }}
      >
        Add expression
      </Button>
    );
  }, [node, updateData]);

  return {
    addExpressionElement,
    expressionsElement,
    metaString,
  };
};
