import { handleDeleteDashboardMedias } from '~src/data/store/modules/common/dashboard/media/handler';
import { fetchDashboardMedias } from '~src/data/store/modules/common/dashboard/media/requests';
import { DashboardMediaState } from '~src/data/store/reducers/common/dashboard/media/reducer';
import { apis } from '~src/services/request/apis';

import { dashboardMediaPath, Operation } from '@pladdenico/portfolio-api';

export function subscribeToDashboardMedias(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  dashboardMedias: DashboardMediaState[],
  subscriptions: Operation[],
) {
  dashboardMedias.forEach((dashboardMedia) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: dashboardMediaPath(tenantId, workspaceId).objectPath(
              dashboardMedia.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchDashboardMedias(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to dashboardMedia', operation, dashboardMedia.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: dashboardMediaPath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchDashboardMedias(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to dashboardMedia', operation, dashboardMedia.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: dashboardMediaPath(tenantId, workspaceId).domainPath(
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteDashboardMedias(workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to dashboardMedia', operation, dashboardMedia.id);
          },
        );
      }
    });
  });
}
