import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MessagesBadgeComponent } from '~src/components/top-bar/messages-badge.component';
import { NotificationsBadgeComponent } from '~src/components/top-bar/notifications-badge.component';
import { useProfileMenu } from '~src/components/top-bar/use-profile-menu';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectTenantData,
  selectUserData,
} from '~src/data/store/selectors/selectors';
import { getTenantById } from '~src/data/store/selectors/tenant/tenants/selectors';
import { TenantMenuComponent } from '~src/domain/tenant/components/tenant-menu.component';
import { homeBasePath } from '~src/navigation/paths/home/paths';
import {
  userLoginPath,
  userSignupPath,
} from '~src/navigation/paths/user/paths';
import { primaryFrontColor } from '~src/utils/common/colors/base-colors';
import { DrawerType } from '~src/utils/common/use-drawer';

import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppBar, Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

const PREFIX = 'TopBarDesktopComponent';

const classes = {
  grow: `${PREFIX}-grow`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
};

interface StyleProps {
  drawerWidth: number;
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.appBarShift}`]: {
    marginLeft: (props: StyleProps) => props.drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  },
}));

const TitleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: primaryFrontColor.dark,
  '&:hover': {
    backgroundColor: primaryFrontColor.medium,
  },
}));

interface Props {
  drawer?: DrawerType;
}

export const TopBarDesktopComponent = React.memo((_props: Props) => {
  const account = useSelector(
    (state: RootState) => selectUserData(state).account,
  );
  const navigate = useNavigate();
  const menuId = 'primary-account-menu.desktop';
  const { profileMenu, handleOpen } = useProfileMenu(account.user, menuId);
  const loggedIn = account.user !== undefined;

  function handleGoToSignup() {
    navigate(userSignupPath);
  }

  function handleGoToLogin() {
    navigate(userLoginPath);
  }

  const userActions = !loggedIn ? (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.menuButton}
        onClick={handleGoToSignup}
      >
        Sign up
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={classes.menuButton}
        onClick={handleGoToLogin}
      >
        Sign in
      </Button>
    </>
  ) : undefined;

  const profileIcon = loggedIn ? (
    <IconButton
      edge="end"
      aria-label="account of current user"
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={handleOpen}
      color="inherit"
    >
      <AccountCircle />
    </IconButton>
  ) : undefined;

  const messagesBadge = loggedIn ? <MessagesBadgeComponent /> : undefined;

  const notificationBadge = loggedIn ? (
    <NotificationsBadgeComponent />
  ) : undefined;

  const handleGoToHome = () => {
    navigate(homeBasePath);
  };

  const { tenantId } = useParams<{
    tenantId: string;
  }>();
  const tenant = useSelector((state: RootState) =>
    getTenantById(selectTenantData(state), tenantId),
  );

  return (
    <Root>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <TitleButton onClick={handleGoToHome}>
                Portfolio management
              </TitleButton>
            </Grid>
            <Grid item>
              {tenant && <TenantMenuComponent tenant={tenant} />}
            </Grid>
          </Grid>
          <div className={classes.grow} />
          {userActions}
          {messagesBadge}
          {notificationBadge}
          {profileIcon}
        </Toolbar>
      </AppBar>
      {profileMenu}
    </Root>
  );
});
