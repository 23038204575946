import {
  PaperStateVisitor,
  PaperVisitable,
} from '~src/data/store/visitors/finance/paper-visitor';
import { Paper } from '~src/services/graphql/finance/client/graphql';
import { AppDispatch } from '~src/store/store';

export function handlePapers(dispatch: AppDispatch, outputs: Paper[]) {
  const paperVisitor = new PaperStateVisitor(dispatch);
  const papers = outputs.map((paper) => {
    const visitable = new PaperVisitable(paper);
    return visitable.accept(paperVisitor);
  });
  paperVisitor.post();
  return papers;
}
