import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ExpressionElementState } from '~src/data/store/state/workspace/project/scenario/forecast/expression-element-state';
import { FlowElementState } from '~src/data/store/state/workspace/project/scenario/forecast/flow-element-state';
import { ForecastElementBase } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-element-state';
import {
  ForecastState,
  isExpressionType,
  isFlowType,
  isManualType,
} from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { ForecastExpressionElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/expression/forecast-expression-element.component';
import { HoldingForecastFlowElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/forecast-flow-element.component';
import { ForecastManualElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/manual/forecast-manual-element.component';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  budget: Budget;
  holding: HoldingState;
  forecast: ForecastState;
  forecastElement: ForecastElementBase;
}

export const HoldingForecastElementComponent = React.memo((props: Props) => {
  const {
    workspace,
    project,
    scenario,
    budget,
    holding,
    forecast,
    forecastElement: forecastElementState,
  } = props;

  const forecastElement = React.useMemo(() => {
    if (isExpressionType(forecastElementState)) {
      const forecastExpressionElement =
        forecastElementState as ExpressionElementState;
      return (
        <ForecastExpressionElementComponent
          scenario={scenario}
          forecast={forecast}
          forecastExpressionElement={forecastExpressionElement}
          project={project}
          workspace={workspace}
        />
      );
    } else if (isManualType(forecastElementState)) {
      const forecastManualElement = forecastElementState as ManualElementState;
      return (
        <ForecastManualElementComponent
          forecast={forecast}
          forecastManualElement={forecastManualElement}
          project={project}
          workspace={workspace}
        />
      );
    } else if (isFlowType(forecastElementState)) {
      const forecastFlowElement = forecastElementState as FlowElementState;
      return (
        <HoldingForecastFlowElementComponent
          scenario={scenario}
          budget={budget}
          forecast={forecast}
          forecastFlowElement={forecastFlowElement}
          holding={holding}
          project={project}
          workspace={workspace}
        />
      );
    } else {
      return <></>;
    }
  }, [
    budget,
    forecast,
    forecastElementState,
    holding,
    project,
    scenario,
    workspace,
  ]);
  return forecastElement;
});
