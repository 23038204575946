import moment from 'moment';
import numbro from 'numbro';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Area,
  AreaChart,
  Label,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState, RootState } from '~src/data/store/reducers/reducers';
import { getHoldingGroupValue } from '~src/data/store/selectors/finance/valuation/holding-group-value';
import { getHoldingFilterById } from '~src/data/store/selectors/holding/filter/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { ProjectValuationTooltipComponent } from '~src/domain/workspace/components/project/holding/valuation/project-valuation-tooltip.component';
import { colorPalette as colorsAreaChart } from '~src/utils/common/colors/chart-colors';
// import { getHoverColor } from '~src/utils/common/color-utils';
import { Filter } from '~src/utils/common/filter';
import { createSubperiodDates, Period } from '~src/utils/period/period';

import { Box, Typography } from '@mui/material';

// import { colorsAreChart } from '@pladdenico/common';
import style from './style.module.css';
import { createSelector } from 'reselect';

interface Props {
  workspaceId: string;
  projectId: string;
  date: moment.Moment;
  period: Period;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
  title: string;
  pivot: string;
  currency: CurrencyState;
}

interface SelectValuationsProps {
  workspaceId: string;
  projectId: string;
  holdingGroups: Map<string, { name: string; holdings: HoldingState[] }>;
  filter: Filter;
  date: moment.Moment;
  currency: CurrencyState;
  dates: moment.Moment[];
  filterKey: string;
}

const selectValuations = createCachedSelector(
  (state: DataState, _props: SelectValuationsProps) => state,
  (_state: DataState, props: SelectValuationsProps) => props.workspaceId,
  (_state: DataState, props: SelectValuationsProps) => props.projectId,
  (_state: DataState, props: SelectValuationsProps) => props.holdingGroups,
  (_state: DataState, props: SelectValuationsProps) => props.filter,
  (_state: DataState, props: SelectValuationsProps) => props.date,
  (_state: DataState, props: SelectValuationsProps) => props.currency,
  (_state: DataState, props: SelectValuationsProps) => props.dates,
  (_state: DataState, props: SelectValuationsProps) => props.filterKey,
  (
    state,
    workspaceId,
    projectId,
    holdingGroups,
    filter,
    _date,
    currency,
    dates,
    filterKey,
  ) => {
    const data: any[] = [];
    dates.forEach((date) => {
      const dateData: any = {};
      let totalValue = 0;
      holdingGroups.forEach((group, key) => {
        const value = getHoldingGroupValue(state, {
          workspaceId,
          projectId,
          currency,
          date,
          filter,
          holdings: group.holdings,
          useMarketValue: true, // XXX: Should come from config.
          filterValue: key,
          filterKey,
        });
        dateData[group.name] = value;
        totalValue += value;
      });
      dateData.name = `${date.format('DD.MM.YY')}`;
      dateData.totalValue = totalValue;
      dateData.tooltipName = `${date.format('DD.MM.YY')} - ${numbro(
        totalValue,
      ).format({
        thousandSeparated: true,
        mantissa: 1,
      })}`;
      data.push(dateData);
    });
    return data;
  },
)({
  keySelector: (_state, props) => props.holdingGroups,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const ProjectValuationComponent = React.memo((props: Props) => {
  const [dates, setDates] = React.useState<moment.Moment[]>([]);
  const [holdingFilterId] = React.useState(0);
  const filter = useSelector((state: RootState) =>
    getHoldingFilterById(selectWorkspaceData(state), holdingFilterId),
  );

  const valuations = useSelector((state: RootState) =>
    selectValuations(selectData(state), {
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      currency: props.currency,
      date: props.date,
      dates,
      filter,
      holdingGroups: props.holdingGroups,
      filterKey: props.pivot,
    }),
  );

  React.useEffect(() => {
    setDates(createSubperiodDates(props.period, 'month')); // , [moment()]
  }, [props.period]);

  const renderAreaChart = React.useCallback(() => {
    const areaElements: any[] = [];
    let index = 0;
    props.holdingGroups.forEach((value, key) => {
      // Array.from(props.holdingGroups.keys()).forEach((key, index) => {
      areaElements.push(
        <Area
          key={key}
          type={'linear'}
          dataKey={value.name}
          stackId="1"
          stroke={colorsAreaChart[index % colorsAreaChart.length]}
          fill={colorsAreaChart[index % colorsAreaChart.length]}
        />,
      );
      ++index;
    });

    const valueToTick = (decimal: number) => {
      return `${numbro(decimal).format('0,0')}`;
    };

    const interval = Math.floor(valuations.length / 12);

    const today = moment().format('DD.MM.YY');
    let lastDate = today;
    let highestValuation = 0;
    let lowestValuation = 0;
    if (valuations.length > 0) {
      lastDate = valuations[valuations.length - 1].name;
      highestValuation = valuations.reduce((prev, cur) => {
        return Math.max(prev, cur);
      }, valuations[0].totalValue);
      lowestValuation = valuations.reduce((prev, cur) => {
        return Math.min(prev, cur);
      }, valuations[0].totalValue);
    }

    return (
      <AreaChart
        width={1000}
        height={800}
        data={valuations}
        margin={{ top: 10, right: 30, left: 60, bottom: 0 }}
      >
        <XAxis dataKey="name" interval={interval} />
        <YAxis tickFormatter={valueToTick}>
          <Label value={`${props.currency.symbol}`} position="insideTopRight" />
        </YAxis>
        {/* <CartesianGrid strokeDasharray="3 3"/> */}
        <Tooltip
          content={<ProjectValuationTooltipComponent />}
          cursor={{
            stroke: '#FF0000',
            fill: '#FF0000',
            // fill: getHoverColor('#FF0000', 30),
            strokeWidth: 2,
          }}
        />
        <Line type="monotone" dataKey="uv" stroke="#ff7300" />
        <ReferenceArea
          x1={today}
          x2={lastDate}
          y1={lowestValuation}
          y2={highestValuation}
          stroke="red"
          strokeOpacity={0.3}
        />
        {/* <Tooltip/> */}
        <Legend
          iconType="rect"
          align="right"
          layout="vertical"
          verticalAlign="top"
          wrapperStyle={{
            right: '10px',
            top: '40px',
            maxHeight: '538px',
            overflowY: 'auto',
          }}
        />
        {areaElements}
      </AreaChart>
    );
  }, [props.holdingGroups, valuations, props.currency]);

  return (
    <Box>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        Portfolio valuation by {props.title}
      </Typography>
      <div className={`${style.graphContainer}`}>
        <ResponsiveContainer height={600}>
          {renderAreaChart()}
        </ResponsiveContainer>
      </div>
    </Box>
  );
});
