import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createRoles } from '~src/data/store/modules/people/roles/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { RoleState } from '~src/data/store/reducers/person/roles/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { RoleDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
}

export const CreateRoleDialogComponent = React.memo((props: Props) => {
  const [roleInput, setRoleInput] =
    React.useState<graphqlWorkspaceTypes.CreateRoleInputType>({
      // id: uuid.v1(),
      id: '',
      holdingId: '',
      description: '',
      name: '',
      personId: props.person.id,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveRole = (): Promise<RoleState> => {
    const role = {
      ...roleInput,
      id: uuid.v1(),
    };
    return dispatch(
      createRoles(props.tenantId, props.workspace.id, props.person.id, [role]),
    )
      .then((roles) => {
        return roles[0];
      })
      .catch((err) => {
        console.info('FAILED', roleInput, JSON.stringify(err));
        throw err;
      });
  };

  const savedCallback = () => {
    return;
  };

  return (
    <RoleDialogComponent
      workspaceId={props.workspace.id}
      title={'Create role'}
      handleSaveRole={handleSaveRole}
      roleInput={roleInput}
      setRoleInput={setRoleInput}
      handleClose={props.handleClose}
      savedCallback={savedCallback}
      open={props.open}
    />
  );
});
