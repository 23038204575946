import { AssetType } from '@pladdenico/models';
import React from 'react';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetCreateInputData } from '~src/domain/workspace/components/asset/form/create/asset-create-input.data';

interface WithAssetId {
  assetId: string;
}

interface Props<T extends WithAssetId> {
  handleValue: (t: T) => void;
  workspace: WorkspaceState;
  assetType: AssetType;
  getDialogContent: (
    assetInput: AssetCreateInputData,
    callback: (t: T) => void,
    setOpen: (open: boolean) => void,
  ) => JSX.Element;
}

export const useEditDialog = <T extends WithAssetId>(props: Props<T>) => {
  const { handleValue, getDialogContent, assetType } = props;
  const [open, setOpen] = React.useState(false);
  // const { assetInput } = useCreateAsset(props.workspace, AssetType.Loan);
  const [assetInput, setAssetInput] = React.useState<AssetCreateInputData>({
    // id: uuid.v1(),
    entityId: '',
    name: '',
    type: AssetType.Unknown,
    categoryId: null,
    description: '',
    types: [],
    VPS: '',
    lookup: '',
    ISIN: '',
  });

  const callback = React.useCallback(
    (t: T) => {
      handleValue(t);
      setOpen(false);
    },
    [handleValue],
  );

  const dialogElement = React.useMemo(() => {
    return (
      <DialogComponent
        dialogProps={{ open, onClose: () => setOpen(false), fullWidth: true }}
        // open={open}
      >
        {getDialogContent(assetInput, callback, setOpen)}
      </DialogComponent>
    );
  }, [assetInput, callback, getDialogContent, open]);

  const initDialog = React.useCallback(
    (value: string) => {
      setAssetInput({
        // id: uuid.v1(),
        type: assetType,
        name: value,
        entityId: '',
        categoryId: '',
        types: [],
      });
    },
    [assetType, setAssetInput],
  );

  return { dialogElement, setOpen, initDialog };
};
