import { HoldingType } from '@pladdenico/models';
import React from 'react';
import { ElementComponent } from '~src/components/dashboard/element.component';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { DashboardPaperStockHoldingComponent } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/paper/holding-types/stock/paper.component';
import { usePaperMenu } from '~src/domain/workspace/components/project/portfolio/holdings/dashboard/elements/paper/use-paper-menu.hook';
import { Config } from '~src/utils/interfaces/config';
import { ElementProps } from '~src/components/dashboard/element';

interface Props extends ElementProps {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  config: Config;
}

export const PaperElement = React.memo(
  ({
    workspace,
    project,
    holding,
    config,
    active,
    setActive,
    setInactive,
    loading,
    id,
    remove,
    element,
    updated,
  }: Props) => {
    const { menu, handleOpen, isOpen } = usePaperMenu(element, remove, updated);

    const content = React.useMemo(() => {
      switch (holding.type) {
        case HoldingType.Stock:
          return (
            <DashboardPaperStockHoldingComponent
              workspace={workspace}
              project={project}
              holding={holding}
              config={config}
            />
          );
        default:
          return <div>Paper not implement for {holding.type}</div>;
      }
    }, [config, holding, project, workspace]);

    const elementConfig = React.useMemo(() => {
      return {
        dragDisabled: isOpen,
        format: {
          color: element.config.color ?? '#000000',
          backgroundColor: element.config.backgroundColor ?? '#ffffff',
          padding: element.config.padding,
        },
        hideOverflow: true,
        minHeight: 200,
      };
    }, [
      element.config.backgroundColor,
      element.config.color,
      element.config.padding,
      isOpen,
    ]);

    return (
      <>
        <ElementComponent
          remove={remove}
          id={id}
          active={active}
          setActive={setActive}
          setInactive={setInactive}
          loading={loading}
          updated={updated}
          usageMode={config.usageMode}
          openOptionsMenu={handleOpen}
          elementConfig={elementConfig}
        >
          {content}
        </ElementComponent>
        {menu}
      </>
    );
  },
);
