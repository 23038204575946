import * as React from 'react';
import { useDispatch } from 'react-redux';
import { fetchEvents } from '~src/data/store/modules/workspaces/projects/events/requests';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useEventsAlgorithm } from '~src/domain/workspace/components/project/event/algorithm/use-algorithm';
import { ProjectEventCardComponent } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/events/event-card.component';
import { AppDispatch } from '~src/store/store';
import { Config } from '~src/utils/interfaces/config';

import { Box, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  config: Config;
}

const useStyles = makeStyles()((_theme: Theme) => ({
  configContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  root: {
    flex: 1,
    flexDirection: 'column',
  },
}));

export const ProjectEventsDashboardComponent = React.memo((props: Props) => {
  const { workspace, project } = props;
  const { classes } = useStyles();

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    dispatch(fetchEvents(workspace.tenantId, workspace.id));
  }, [dispatch, workspace.id, workspace.tenantId]);

  const algo = useEventsAlgorithm(workspace, project);

  const eventElements = algo.algoData.filtered.map((event) => {
    return (
      <Box key={event.id}>
        <ProjectEventCardComponent
          workspace={workspace}
          project={project}
          event={event}
        />
        <Divider />
      </Box>
    );
  }, []);

  return (
    <Box p={1}>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        Events
      </Typography>
      <div className={classes.root}>{eventElements}</div>
    </Box>
  );
});
