import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { EditFileComponent } from '~src/domain/workspace/components/file/file/edit.component';
import { HorizontalImages } from '~src/components/utils/image/horizontal-images.component';
import { useImagePicker } from '~src/components/utils/image/image-picker';
import {
  createEntityMedias,
  deleteEntityMedias,
} from '~src/data/store/modules/entities/media/requests';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntityMediasByEntityId } from '~src/data/store/selectors/entities/media/selectors';
import { getFilesByIds } from '~src/data/store/selectors/file/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import { Button } from '@mui/material';
import { strcmp } from '@pladdenico/common';
import { File } from '@pladdenico/portfolio-api/dist/generated/graphql.workspace'; //TODO: Needs fix

interface Props {
  entityId: string;
  workspace: WorkspaceState;
}

export const EntityMediaComponent = React.memo((props: Props) => {
  const { entityId, workspace } = props;
  const entityMedia = useSelector((state: RootState) =>
    getEntityMediasByEntityId(selectWorkspaceData(state), entityId),
  );
  const fileIds = React.useMemo(
    () => entityMedia.map((m) => m.fileId).sort((a, b) => strcmp(a, b)),
    [entityMedia],
  );
  const medias = useSelector((state: RootState) =>
    getFilesByIds(selectWorkspaceData(state), { ids: fileIds }),
  );
  const dispatch = useDispatch<AppDispatch>();
  const deleteMedia = React.useCallback(
    (media: FileState) => {
      const em = entityMedia.find((em) => em.fileId === media.id);
      if (em) {
        dispatch(
          deleteEntityMedias(workspace.tenantId, workspace.id, [
            { entityId, id: em.id },
          ]),
        );
      }
    },
    [dispatch, entityId, entityMedia, workspace.id, workspace.tenantId],
  );
  const [file, setFile] = React.useState<File>();
  const editModal = useVisible();
  const editComponent = React.useMemo(() => {
    if (file) {
      return (
        <EditFileComponent
          handleClose={editModal.close}
          isShowing={editModal.isShowing}
          file={file}
          tenantId={workspace.tenantId}
          workspace={workspace}
        />
      );
    }
  }, [editModal.close, editModal.isShowing, file, workspace]);
  const editMedia = React.useCallback(
    (file: File) => {
      setFile(file);
      editModal.open();
    },
    [editModal],
  );

  const selectFile = React.useCallback(
    (file: File) => {
      return dispatch(
        createEntityMedias(workspace.tenantId, workspace.id, [
          {
            entityId,
            id: uuid.v1(),
            fileId: file.id,
          },
        ]),
      );
    },
    [dispatch, entityId, workspace.id, workspace.tenantId],
  );
  const imagePicker = useImagePicker({ workspace, selectFile });

  return (
    <div>
      <HorizontalImages
        files={medias}
        deleteFile={deleteMedia}
        editFile={editMedia}
      />
      <Button onClick={imagePicker.visible.open}>
        Pick image from library
      </Button>
      {imagePicker.dialogElement}
      {editComponent}
    </div>
  );
});
