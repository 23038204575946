import { combineReducers } from 'redux';
// import {
//   initialState as favoriteHoldingInitialState,
//   favoriteHoldingsReducer,
// } from '~src/data/store/modules/holdings/favorite/reducer';
import {
  filtersInitialState,
  filtersReducer,
} from '~src/data/store/modules/holdings/filter/reducer';
import {
  commitmentInitialState,
  commitmentReducer,
} from '~src/data/store/reducers/holding/commitment/reducer';
import {
  holdingConfigsReducer,
  initialHoldingConfigState,
} from '~src/data/store/reducers/holding/config/configs/reducer';
import {
  holdingDashboardConfigsReducer,
  initialHoldingDashboardConfigState,
} from '~src/data/store/reducers/holding/config/dashboard-configs/reducer';
import {
  cryptoHoldingInitialState,
  cryptoHoldingReducer,
} from '~src/data/store/reducers/holding/holding-types/crypto/reducer';
import {
  holdingsReducer,
  initialHoldingState,
} from '~src/data/store/reducers/holding/holdings/reducer';
import {
  transactionInitialState,
  transactionReducer,
} from '~src/data/store/reducers/holding/transaction/reducer';
import {
  transferInitialState,
  transferReducer,
} from '~src/data/store/reducers/holding/transfer/reducer';
import {
  valuationInitialState,
  valuationReducer,
} from '~src/data/store/reducers/holding/valuation/reducer';

import {
  favoriteHoldingsReducer,
  initialFavoriteHoldingState,
} from './favorite-holding/reducer';
import {
  bankNoteHoldingInitialState,
  bankNoteHoldingReducer,
} from './holding-types/bank-note/reducer';
import {
  bondHoldingInitialState,
  bondHoldingReducer,
} from './holding-types/bond/reducer';
import {
  fundHoldingInitialState,
  fundHoldingReducer,
} from './holding-types/fund/reducer';
import {
  loanHoldingInitialState,
  loanHoldingReducer,
} from './holding-types/loan/reducer';
import {
  stockHoldingInitialState,
  stockHoldingReducer,
} from './holding-types/stock/reducer';
import {
  warrantHoldingInitialState,
  warrantHoldingReducer,
} from './holding-types/warrant/reducer';

// import { stockHoldingReducer, stockHoldingInitialState } from './stock/reducer';

export const holdingInitialState = {
  holdings: initialHoldingState,
  // selectedHoldings: initialHoldingsSelectedState,
  filters: filtersInitialState,
  favorites: initialFavoriteHoldingState,
  // holdingsFetch: initialHoldingsFetchReducer,
  // holdingsPortfolioFetch: initialHoldingsPortfolioFetchReducer,
  transaction: transactionInitialState,
  valuation: valuationInitialState,
  transfer: transferInitialState,
  commitment: commitmentInitialState,
  stockHolding: stockHoldingInitialState,
  fundHolding: fundHoldingInitialState,
  loanHolding: loanHoldingInitialState,
  warrantHolding: warrantHoldingInitialState,
  bondHolding: bondHoldingInitialState,
  bankNoteHolding: bankNoteHoldingInitialState,
  cryptoHolding: cryptoHoldingInitialState,
  configs: initialHoldingConfigState,
  dashboardConfigs: initialHoldingDashboardConfigState,
};

export const holdingReducer = combineReducers({
  holdings: holdingsReducer,
  // selectedHoldings: selectedHoldingsReducer,
  filters: filtersReducer,
  favorites: favoriteHoldingsReducer,
  // holdingsFetch: holdingsFetchReducer,
  // holdingsPortfolioFetch: holdingsPortfolioFetchReducer,
  transaction: transactionReducer,
  valuation: valuationReducer,
  transfer: transferReducer,
  commitment: commitmentReducer,
  stockHolding: stockHoldingReducer,
  fundHolding: fundHoldingReducer,
  loanHolding: loanHoldingReducer,
  warrantHolding: warrantHoldingReducer,
  bondHolding: bondHoldingReducer,
  bankNoteHolding: bankNoteHoldingReducer,
  cryptoHolding: cryptoHoldingReducer,
  configs: holdingConfigsReducer,
  dashboardConfigs: holdingDashboardConfigsReducer,
});
