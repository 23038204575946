import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { TotalCommitmentComponent } from '~src/components/summary/total-commitment.component';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getHoldingsTotalCommitment } from '~src/data/store/selectors/finance/valuation/commitment/holdings-total-commitment';
import { selectData } from '~src/data/store/selectors/selectors';
// import { useComponentUpdateDebug } from '~src/hooks/utils/component-update-debug.hook';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  baseCurrency: CurrencyState;
  subLabel?: string;
}

export const TotalCommitmentsHoldingsComponent = React.memo((props: Props) => {
  // useComponentUpdateDebug('SummaryComponent', props);
  const { baseCurrency, date, holdings, subLabel } = props;

  const holdingIds = React.useMemo(() => {
    return holdings.map((h) => h.id);
  }, [holdings]);

  const totalValue = useSelector((state: RootState) =>
    getHoldingsTotalCommitment(selectData(state), {
      holdingIds,
      date,
      currency: baseCurrency,
    }),
  );

  return (
    <TotalCommitmentComponent
      baseCurrency={baseCurrency}
      totalCommitment={totalValue}
      subLabel={subLabel}
    />
  );
});
