import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { LoanHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/loan/loan-holding-card.component';

import { useLoanHoldingInfo } from './use-loan-holding-info';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const LoanHoldingCardContainer = React.memo((props: Props) => {
  const { loanHolding, loan, asset } = useLoanHoldingInfo(
    props.workspace.id,
    props.holding.id,
  );
  const { holding, projectId, portfolios, workspace } = props;

  if (!loanHolding) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <LoanHoldingCardComponent
      asset={asset}
      holding={holding}
      portfolios={portfolios}
      projectId={projectId}
      loan={loan}
      loanHolding={loanHolding}
      workspace={workspace}
    />
  );
});
