import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { getHoldingsByProjectId } from '~src/data/store/selectors/holding/holdings/selectors';
import { HoldingsCommitmentsComponent } from '~src/domain/workspace/components/project/commitment/holdings-commitments.component';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
}

export const ProjectCommitmentsComponent = React.memo((props: Props) => {
  const { project, workspace } = props;
  const holdings = useSelector((state: RootState) =>
    getHoldingsByProjectId(selectWorkspaceData(state), project.id),
  );
  return (
    <HoldingsCommitmentsComponent
      holdings={holdings}
      project={project}
      workspace={workspace}
    />
  );
});
