import {
  FundHoldingStateVisitor,
  FundHoldingVisitable,
} from '~src/data/store/visitors/holding/types/fund/fund-holding-visitor';
import { Operation } from '@pladdenico/portfolio-api';
import { FundHolding } from '~src/services/graphql/workspace/client/graphql';

export function handleFundHoldings(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: FundHolding[],
  subscriptions: Operation[],
) {
  const fundHoldingVisitor = new FundHoldingStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((fundHolding) => {
    const fundHoldingVisitable = new FundHoldingVisitable(fundHolding);
    fundHoldingVisitable.accept(fundHoldingVisitor);
  });
  fundHoldingVisitor.post();
  return outputs;
}
