import React from 'react';
import { useSelector } from 'react-redux';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingDenormalizedWithEntity } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getBudgetById } from '~src/data/store/selectors/workspace/projects/scenario/budgets/selectors';
import { HoldingScenarioCashFlowComponent } from '~src/domain/workspace/components/project/scenario/dashboard/scenario-cash-flow.component';
import { HoldingScenarioLiquidityComponent } from '~src/domain/workspace/components/project/scenario/dashboard/scenario-liquidity.component';
import { HoldingScenarioValuationComponent } from '~src/domain/workspace/components/project/scenario/dashboard/scenario-valuation.component';
import { useEvaluateForecast } from '~src/domain/workspace/components/project/scenario/dashboard/use-evaluate';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { Config } from '~src/utils/interfaces/config';

import { Button, Grid } from '@mui/material';
import { TableOperations } from '@pladdenico/table';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  portfolioProject: PortfolioProjectState;
  portfolio: PortfolioState;
  scenario: Scenario;
  config: Config;
  algoData: TableOperations.AlgorithmData<HoldingDenormalizedWithEntity>;
}

export const ProjectScenarioDashboardComponent = React.memo((props: Props) => {
  const { workspace, project, scenario, config } = props;

  const budget = useSelector((state: RootState) =>
    getBudgetById(selectWorkspaceData(state), {
      id: scenario.budgetId,
      scenarioId: scenario.id,
    }),
  );
  const useLiveValue = false;

  const { evaluate } = useEvaluateForecast(scenario);

  if (budget == null) {
    return <>No budget??</>;
  }
  return (
    <>
      <Grid container>
        <Grid item>
          <HoldingScenarioCashFlowComponent
            config={config}
            project={project}
            scenario={scenario}
            budget={budget}
            useLiveValue={useLiveValue}
            workspace={workspace}
          />
        </Grid>
        <Grid item>
          <HoldingScenarioLiquidityComponent
            config={config}
            project={project}
            scenario={scenario}
            budget={budget}
            useLiveValue={useLiveValue}
            workspace={workspace}
          />
        </Grid>
        <Grid item>
          <HoldingScenarioValuationComponent
            config={config}
            project={project}
            scenario={scenario}
            budget={budget}
            useLiveValue={useLiveValue}
            workspace={workspace}
          />
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" sx={{ mt: 1, mr: 2 }} onClick={evaluate}>
            Evaluate element
          </Button>
          {/* <Paper>
            {values.map((value, idx) => {
              return (
                <Box key={idx}>
                  <Grid container sx={{ m: 1 }}>
                    <Grid item xs={4}>
                      <Typography>{value.date.format('DD.MM.YYYY')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        Transfer: {value.transfer.type}: {value.transfer.value}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        Valuation: {value.valuation.value}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Paper> */}
        </Grid>
      </Grid>
    </>
  );
});
