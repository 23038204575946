import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { ProjectObj } from '~src/services/graphql/workspace/client/graphql';

export function parseProjectObj(
  workspaceId: string,
  project: ProjectObj,
): ProjectState {
  return {
    id: project.id,
    name: project.name,
    type: project.type,
    workspaceId,
  };
}
