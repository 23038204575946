import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsTyped } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { HoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/holding-card.component';
// import history from '~src/navigation/history';
import { entityRootPath } from '~src/navigation/paths/workspace/project/portfolio-project/entity/paths';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  colors,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { EntityData } from '~src/domain/workspace/components/project/portfolio/entities/entities.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'EntityCardComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  entity: `${PREFIX}-entity`,
  holding: `${PREFIX}-holding`,
  description: `${PREFIX}-description`,
  menuButton: `${PREFIX}-menuButton`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    flex: 1,
    backgroundColor: colors.blue[50],
    // minWidth: 150,
    // height: 165,
    // width: 160,
    // maxWidth: 450,
  },

  [`& .${classes.entity}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.holding}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.description}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 4,
    overflow: 'hidden',
  },

  [`& .${classes.menuButton}`]: {
    textAlign: 'center',
  },
}));

interface Props {
  entity: EntityData;
  workspace: WorkspaceState;
  projectId: string;
}

export const EntityCardComponent = React.memo((props: Props) => {
  const { entity, projectId, workspace } = props;

  const holdingsTyped = useSelector((state: RootState) =>
    getHoldingsTyped(selectWorkspaceData(state), {
      workspaceId: workspace.id,
      projectId,
      entity,
    }),
  );

  const menu = useMenu();
  const navigate = useNavigate();
  const handleOnClick = (
    tenantId: string,
    workspaceId: string,
    projectId: string,
    entity: EntityData,
  ) => {
    navigate(
      entityRootPath(tenantId, workspaceId, projectId, entity.entity.id),
    );
  };

  const entityMenu = (
    <Menu
      anchorEl={menu.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={menu.isOpen}
      onClose={menu.handleClose}
    >
      <MenuItem
        onClick={() =>
          handleOnClick(
            workspace.tenantId,
            workspace.id,
            props.projectId,
            props.entity,
          )
        }
      >
        View
      </MenuItem>
    </Menu>
  );

  const holdingElements = holdingsTyped.map((holding) => {
    return (
      <div className={classes.holding} key={holding.holding.id}>
        <HoldingCardComponent
          workspace={workspace}
          holding={holding.holding}
          portfolios={holding.portfolios}
          projectId={projectId}
        />
      </div>
    );
  });

  return (
    <StyledPaper className={classes.paper} variant="outlined">
      <Grid container>
        <Grid
          container
          item
          xs
          justifyContent="space-between"
          className={classes.entity}
        >
          <Grid item xs={10}>
            <Typography variant="h6">{props.entity.entity.name}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.description}
            >
              {props.entity.entity.description}
            </Typography>
          </Grid>
          <Grid item xs className={classes.menuButton}>
            <IconButton onClick={menu.handleOpen} color="inherit">
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs>
          {holdingElements}
        </Grid>
      </Grid>
      {entityMenu}
    </StyledPaper>
  );
});
