import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { HoldingWithConfigComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding.component-with-config';
import { useHoldingConfig } from '~src/domain/workspace/components/project/portfolio/holdings/use-holding-config';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  project: ProjectState;
}

export const HoldingComponent = React.memo((props: Props) => {
  const { holding, project, workspace } = props;
  console.log('HERE 1');

  const { config, dashboardConfig, dashboardElements } = useHoldingConfig({
    holding,
    workspace,
  });

  console.log('config');

  if (config && dashboardConfig) {
    return (
      <HoldingWithConfigComponent
        {...props}
        holdingConfig={config}
        dashboardConfig={dashboardConfig}
        dashboardElements={dashboardElements}
        project={project}
      />
    );
  } else {
    return <CircularProgress />;
  }
});
