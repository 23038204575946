import React from 'react';
import { EditDateField } from '~src/components/fields/common/edit-date-field';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  bondPositionInput: graphqlWorkspaceTypes.CreateBondPositionInputType;
  setBondPositionInput: (
    bondPositionInput: graphqlWorkspaceTypes.CreateBondPositionInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

export const CreateBondPositionInfoFieldsComponent = React.memo(
  (props: Props) => {
    const { classes } = useStyles();

    const handleChange =
      (name: keyof graphqlWorkspaceTypes.CreateBondPositionInputType) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setBondPositionInput({
          ...props.bondPositionInput,
          [name]: eventParser(e),
        });
      };

    const handleDateChange = (date: Date) => {
      props.setBondPositionInput({ ...props.bondPositionInput, date });
    };

    return (
      <>
        {/* <TextField
        margin='normal'
        className={classes.textField}
        required
        id='sharePrice'
        value={props.bondPositionInfoInput.sharePrice}
        label='Share price'
        type='number'
        onChange={handleChange('sharePrice')}/> */}
        <TextField
          margin="normal"
          className={classes.textField}
          required
          id="shares"
          value={props.bondPositionInput.shares}
          label="Shares"
          type="number"
          onChange={handleChange('shares')}
        />
        <EditDateField
          handleValue={handleDateChange}
          date={props.bondPositionInput.date}
          labelled={true}
        />
        {/* <TextField
        margin='normal'
        className={classes.textField}
        required
        id='totalShares'
        value={props.bondPositionInfoInput.totalShares}
        label='TotalShares'
        type='number'
        onChange={handleChange('totalShares')}/> */}
      </>
    );
  },
);
