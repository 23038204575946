import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  formControl: {
    minWidth: 100,
  },
}));

export interface Item {
  type: string;
  title: string;
}

interface Props<T, I extends Item> {
  items: I[];
  handleValue: (type: string) => void;
  type: T;
  label: string | undefined;
}

export const SelectType = <T extends string, I extends Item>(
  props: Props<T, I>,
) => {
  const { classes } = useStyles();
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const type = event.target.value as string;
    props.handleValue(type);
  };

  const label = props.label ? (
    <InputLabel htmlFor="Type">{props.label}</InputLabel>
  ) : undefined;

  const items = React.useMemo(() => {
    return Object.values(props.items).map((element) => {
      return (
        <MenuItem key={element.type} value={element.type}>
          {element.title}
        </MenuItem>
      );
    });
  }, [props.items]);

  return (
    <FormControl
      margin="dense"
      className={classes.formControl}
      fullWidth={true}
    >
      {label}
      <Select
        value={props.type}
        onChange={handleSelectChange}
        label={props.label}
      >
        {items}
      </Select>
    </FormControl>
  );
};
