import React from 'react';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  workspaceInput: graphqlWorkspaceTypes.CreateWorkspaceInputType;
  setWorkspaceInput: (
    workspaceInput: graphqlWorkspaceTypes.CreateWorkspaceInputType,
  ) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateWorkspaceFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange =
    (name: keyof graphqlWorkspaceTypes.CreateWorkspaceInputType) =>
    (value: any) => {
      props.setWorkspaceInput({ ...props.workspaceInput, [name]: value });
    };

  const handleEventChange =
    (name: keyof graphqlWorkspaceTypes.CreateWorkspaceInputType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(name)(event.target.value);
      // props.setWorkspaceInput({ ...props.workspaceInput, [name]: event.target.value });
    };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {/* <EditIdField
        className={classes.textField}
        handleChange={handleChange('id')}
        value={props.workspaceInput.id}
      /> */}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={props.workspaceInput.name}
        label="Name"
        type="text"
        onChange={handleEventChange('name')}
      />
    </form>
  );
});
