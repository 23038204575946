import { Location } from 'history';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { LoginFieldsComponent } from './login-fields.component';
import { useLoginUser } from './use-login-user.hook';

const useStyles = makeStyles()((_theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  grow: {
    flexGrow: 1,
  },

  paper: {
    padding: '3%',
  },
}));

function useRedirectUrlFromLocation(location: Location<any>) {
  return location.state ? location.state.redirectUrl : null;
}

export const LoginComponent = React.memo(() => {
  const { classes } = useStyles();
  const location = useLocation();
  const redirectUrl = useRedirectUrlFromLocation(location);

  const { userInput, setUserInput, loginError, setLoginError, saveUser } =
    useLoginUser(redirectUrl);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.grow}
    >
      <Paper className={classes.paper}>
        <Typography variant="h5">Login</Typography>
        <form className={classes.container} noValidate autoComplete="off">
          <LoginFieldsComponent
            userInput={userInput}
            setUserInput={setUserInput}
            loginError={loginError}
            setLoginError={setLoginError}
          />
        </form>
        <Button variant="contained" onClick={saveUser} color="primary">
          Log in
        </Button>
      </Paper>
    </Grid>
  );
});
