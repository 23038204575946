import React from 'react';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { RegionAllocationsToolbar } from '~src/domain/workspace/components/entity/region-allocations/regions-allocations-toolbar.component';

import { Box, Typography } from '@mui/material';

import { RegionAllocationsTableComponent } from './region-allocations-table.component';

interface Props {
  workspace: WorkspaceState;
  entity: EntityState;
}

export const RegionAllocationsComponent = React.memo((props: Props) => {
  const { entity } = props;

  return (
    <Box>
      <Typography variant="caption">Region Allocations</Typography>
      <RegionAllocationsToolbar
        workspace={props.workspace}
        entity={props.entity}
      />
      <RegionAllocationsTableComponent
        tenantId={props.workspace.tenantId}
        workspaceId={props.workspace.id}
        entity={entity}
      />
    </Box>
  );
});
