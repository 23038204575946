import React from 'react';
import { useDragDropSubComponent } from '~src/components/dashboard/use-drag-drop-dashboard-sub-component.hook';

import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ReorderIcon from '@mui/icons-material/Reorder';

import style from './style.module.css';

interface Props<DashboardComponentType> {
  id: string;
  type: DashboardComponentType;
  name: string;
  add: (dashboardSubComponentType: DashboardComponentType) => void;
  remove: (key: string) => void;
  move: (fromId: string, toId: string) => void;
  canBeMoved: boolean;
  unused: boolean;
}

export function DashboardEditSubComponentComponent<DashboardComponentType>(
  props: Props<DashboardComponentType>,
) {
  const { drag, isDragging, drop, isOver } = useDragDropSubComponent({
    id: props.id,
    move: props.move,
    canBeMoved: props.canBeMoved,
  });
  // const [hover, setHover] = useState(false);

  const addButtonElement = (
    <IconButton onClick={props.add.bind(props.add, props.type)}>
      <AddIcon />
    </IconButton>
  );

  const removeButtonElement = !props.unused ? (
    <IconButton onClick={props.remove.bind(props.remove, props.id)}>
      <RemoveIcon />
    </IconButton>
  ) : undefined;

  // const toggleHover = () => {
  //   setHover(!hover);
  // }

  if (isDragging) {
    console.log('IS DRAGGING');
  }

  return (
    // <div ref={drop}>
    <div
      ref={drop}
      className={
        isOver && props.canBeMoved
          ? style.dashboardSubComponentItemIsOver
          : style.dashboardSubComponentItemDroppable
      }
    >
      <div
        className={
          isDragging
            ? style.dashboardSubComponentItemIsDragging
            : props.canBeMoved
              ? style.dashboardSubComponentItemDraggable
              : style.dashboardSubComponentItemUndraggable
        }
      >
        <IconButton ref={drag}>
          <ReorderIcon />
        </IconButton>
        {props.name}
        {/* </Item.Content>
        <Item.Content floated='right'> */}
        <div style={{ float: 'right' }}>
          {addButtonElement}
          {removeButtonElement}
        </div>
      </div>
    </div>
    // </div>
  );
}
