import { combineReducers } from 'redux';
import {
  dashboardInitialState,
  dashboardReducer,
} from '~src/data/store/reducers/common/dashboard/reducer';

export const viewInitialState = {
  dashboard: dashboardInitialState,
};

export const viewReducer = combineReducers({
  dashboard: dashboardReducer,
});
