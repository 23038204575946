// import moment from 'moment';
import React from 'react';
import { useScenarioVariableFieldsComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/use-variable-fields.component';
import { ScenarioVariableInput } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/variable-element-input';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  variable: ScenarioVariableInput;
  setVariable: (element: ScenarioVariableInput) => void;
}

export const EditScenarioVariableComponent = React.memo((props: Props) => {
  const { variable, setVariable } = props;

  const { descriptionElement, nameElement, valueElement } =
    useScenarioVariableFieldsComponent(variable, setVariable);

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Typography sx={{ mb: 1 }}>
        These variables are global within each scenario, and can have their own
        forecast.
      </Typography>
      {nameElement}
      {descriptionElement}
      {valueElement}
    </Box>
  );
});
