import { __OLDsortedFind } from '@pladdenico/common';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupState } from '~src/data/store/modules/groups/state';
import { AppDispatch } from '~src/store/store'; // import { getGroupPermissionByWorkspaceId } from '~src/redux/modules/workspaces/workspace-roles/group-permissions';
import { selectedWorkspaceRolesActions } from '~src/data/store/reducers/workspace/workspace-role/select-workspace-roles/reducer';
import { selectWorkspaceRoleSelected } from '~src/data/store/modules/workspaces/workspace-roles/selected-workspace-role/selectors';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectUserData,
  selectWorkspaceData,
  selectTenantData,
} from '~src/data/store/selectors/selectors';
import { getWorkspaceRolesByWorkspaceId } from '~src/data/store/selectors/workspace/workspace-roles/selectors';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export const WorkspaceRolesComponent = memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const workspace = useSelector((state: RootState) =>
    getWorkspaceById(selectWorkspaceData(state), {
      id: selectWorkspaceData(state).workspace.selectedWorkspaceId,
      tenantId: selectTenantData(state).tenant.selectedTenantId,
    }),
  );
  const groups = useSelector(
    (state: RootState) => selectUserData(state).group.groups,
  );
  const selectedWorkspaceRole = useSelector((state: RootState) =>
    selectWorkspaceRoleSelected(selectWorkspaceData(state)),
  );
  const workspaceRoles = useSelector((state: RootState) =>
    getWorkspaceRolesByWorkspaceId(
      selectWorkspaceData(state),
      workspace ? workspace.id : undefined,
    ),
  );

  const existingWorkspaceRolesTableHeaders = (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Group</TableCell>
        <TableCell>View properties</TableCell>
        <TableCell>Edit properties</TableCell>
        <TableCell>View portfolios</TableCell>
        <TableCell>Add portfolio</TableCell>
        <TableCell>Edit portfolio</TableCell>
        <TableCell>Remove portfolio</TableCell>
        <TableCell>Add roles</TableCell>
        <TableCell>Remove roles</TableCell>
        <TableCell>Update roles</TableCell>
        <TableCell>Remove</TableCell>
      </TableRow>
    </TableHead>
  );

  const existingWorkspaceRolesTableBodyContent = workspaceRoles.map(
    (workspaceRole) => {
      const groupState = __OLDsortedFind(
        groups,
        { id: workspaceRole.groupId } as any,
        (group: GroupState) => group.id,
      );
      const getPermissionElement = (permission: boolean | undefined | null) => {
        return <p>{permission ? 'check circle' : 'ban'}</p>;
        // return <Icon ={permission ? 'check circle' : 'ban'}/>;
      };
      const selectWorkspaceRole = () => {
        dispatch(selectedWorkspaceRolesActions.set(workspaceRole.id));
      };
      const active =
        selectedWorkspaceRole !== null &&
        workspaceRole.id === selectedWorkspaceRole;
      return (
        <TableRow
          key={workspaceRole.id}
          selected={active}
          onClick={selectWorkspaceRole}
        >
          <TableCell>{workspaceRole.name}</TableCell>
          <TableCell>{groupState ? groupState.name : ''}</TableCell>
          <TableCell>
            {getPermissionElement(
              workspaceRole.permission?.viewWorkspaceProperties,
            )}
          </TableCell>
          <TableCell>
            {getPermissionElement(
              workspaceRole.permission?.editWorkspaceProperties,
            )}
          </TableCell>
          <TableCell>
            {getPermissionElement(workspaceRole.permission?.viewPortfolios)}
          </TableCell>
          <TableCell>
            {getPermissionElement(workspaceRole.permission?.addPortfolios)}
          </TableCell>
          <TableCell>
            {getPermissionElement(workspaceRole.permission?.editPortfolios)}
          </TableCell>
          <TableCell>
            {getPermissionElement(workspaceRole.permission?.removePortfolios)}
          </TableCell>
          <TableCell>
            {getPermissionElement(workspaceRole.permission?.addWorkspaceRoles)}
          </TableCell>
          <TableCell>
            {getPermissionElement(
              workspaceRole.permission?.removeWorkspaceRoles,
            )}
          </TableCell>
          <TableCell>
            {getPermissionElement(
              workspaceRole.permission?.updateWorkspaceRoles,
            )}
          </TableCell>
          <TableCell>
            {getPermissionElement(workspaceRole.permission?.remove)}
          </TableCell>
        </TableRow>
      );
    },
  );

  const existingWorkspaceRolesTableBody = (
    <TableBody>{existingWorkspaceRolesTableBodyContent}</TableBody>
  );

  const existingWorkspaceRoleTableElement = (
    <Table>
      {existingWorkspaceRolesTableHeaders}
      {existingWorkspaceRolesTableBody}
    </Table>
  );

  return existingWorkspaceRoleTableElement;
});
