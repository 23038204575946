import { createSelector } from 'reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getHoldingShareById } from '~src/data/store/selectors/holding/filter/selectors';
import { Filter } from '~src/utils/common/filter';
import { CashFlow } from '~src/utils/finance/cash-flow';

import { getCashFlowsWithStartAndEnd } from './holding-cash-flows';

export interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
  startDate: moment.Moment;
  endDate: moment.Moment;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsCashFlowsWithStartAndEnd = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.startDate,
  (_state: DataState, props: Props) => props.endDate,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (
    state,
    workspaceId,
    projectId,
    holdings,
    date,
    currency,
    startDate,
    endDate,
    filter,
    useLiveValue,
  ) => {
    const cashFlows: CashFlow[] = [];

    holdings.forEach((holding) => {
      const holdingCashFlows = getCashFlowsWithStartAndEnd(state, {
        workspaceId,
        holding,
        currency,
        date,
        startDate,
        endDate,
        useLiveValue,
      });
      if (
        holdingCashFlows.length !== 0 &&
        !holdingCashFlows.every((cashFlow) => cashFlow.value === 0)
      ) {
        // &&
        // !(assetCashFlows.length === 2 && assetCashFlows[0].date === assetCashFlows[1].date)) {
        const share = getHoldingShareById(state, {
          workspaceId,
          projectId,
          holding,
          filter,
          date,
        });

        // holdingCashFlows.forEach((cashFlow) => {
        //   cashFlow.value *= share;
        // });
        cashFlows.push(
          ...holdingCashFlows.map((cf) => {
            return {
              ...cf,
              value: cf.value * share,
            };
          }),
        );
      }
    });
    return cashFlows.sort((a: CashFlow, b: CashFlow) => {
      return a.date.getTime() - b.date.getTime();
    });
  },
);
