import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BasePortfolioId {
  workspaceId: string;
  id: string;
}

export type PortfolioState = Omit<
  graphqlWorkspaceTypes.Portfolio,
  'portfolios' | 'portfolioConfigs' | 'holdings' | 'allocations'
> & {
  workspaceId: string;
  parentPortfolioId: string | null | undefined;
  holdingIds: string[];
};

export const initialPortfolioState = [];

const selectKeyId = (portfolio: BasePortfolioId) => portfolio.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BasePortfolioId, b: BasePortfolioId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BasePortfolioId,
  PortfolioState,
  string
>(initialPortfolioState, selectKeyId, keyComparator, tComparator);

export const portfoliosSlice = createBaseRecordSlice('portfolios', adapter);
export const portfolioSelectors = adapter.selectors;

export const portfoliosReducer = portfoliosSlice.reducer;
export const portfoliosActions = portfoliosSlice.actions;
