import { Control, FieldValues, Path, UseFormSetValue } from 'react-hook-form';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { BankNoteTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/bank-note/fields.component';
import { BondTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/bond/fields.component';
import { CryptoTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/crypto/fields.component';
import { FundTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/fund/fields.component';
import { LoanTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/loan/fields.component';
import { StockTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/stock/fields.component';
import { WarrantTransferFieldsControlledComponent } from '~src/domain/workspace/components/project/transaction/form/transfer/fields/warrant/fields.component';
import { TransferInputData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';

import { HoldingType, TransferType } from '@pladdenico/models';
import { TransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/transfer-field-config';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useTransferHoldingField } from '~src/domain/workspace/components/project/transfer/form/fields/base/use-holding-field';
import { Grid } from '@mui/material';

interface Props<T extends FieldValues> {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState | undefined;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: TransferFieldConfig;
  getName: (name: Path<TransferInputData>) => Path<T>;
  transferTypes: TransferType[] | undefined;
}

export const FieldsComponent = <T extends FieldValues>(props: Props<T>) => {
  const {
    workspace,
    projectId,
    holding,
    control,
    setValue,
    // index,
    fieldConfig,
    getName,
    transferTypes,
  } = props;

  let dataElement = <></>;
  if (holding) {
    if (holding.type === HoldingType.Stock) {
      dataElement = (
        <StockTransferFieldsControlledComponent
          workspace={workspace}
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    } else if (holding.type === HoldingType.BankNote) {
      dataElement = (
        <BankNoteTransferFieldsControlledComponent
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    } else if (holding.type === HoldingType.Crypto) {
      dataElement = (
        <CryptoTransferFieldsControlledComponent
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    } else if (holding.type === HoldingType.Bond) {
      dataElement = (
        <BondTransferFieldsControlledComponent
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    } else if (holding.type === HoldingType.Fund) {
      dataElement = (
        <FundTransferFieldsControlledComponent
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    } else if (holding.type === HoldingType.Loan) {
      dataElement = (
        <LoanTransferFieldsControlledComponent
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    } else if (holding.type === HoldingType.Warrant) {
      dataElement = (
        <WarrantTransferFieldsControlledComponent
          projectId={projectId}
          holding={holding}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={transferTypes}
        />
      );
    }
  }

  const { element, visible } = useTransferHoldingField(
    control,
    getName('base.holdingId'),
    undefined,
    workspace,
    projectId,
    fieldConfig?.base.holding,
  );

  return (
    <>
      {visible && (
        <Grid item sm={4}>
          {element}
        </Grid>
      )}
      {dataElement}
    </>
  );
};
