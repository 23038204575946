import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { RootState } from '~src/data/store/reducers/reducers';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import { selectedForecastsActions } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/selected/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  getForecastById,
  getForecastsByObjectId,
} from '~src/data/store/selectors/workspace/projects/scenario/forecasts/forecast/selectors';
import { getSelectedForecastById } from '~src/data/store/selectors/workspace/projects/scenario/forecasts/selected/selectors';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { useEditForecastElementDialogComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-edit-dialog.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { AppDispatch } from '~src/store/store';

const manualElement: ManualElementState = {
  type: 'manual',
  values: [],
};

export const useSelectForecast = (
  budget: Budget,
  objectType: ForecastObjectType,
  objectId: string,
) => {
  const selectedForecastId = useSelector((state: RootState) =>
    getSelectedForecastById(selectWorkspaceData(state), {
      budgetId: budget.id,
      objectType,
      objectId,
    }),
  );
  const forecast = useSelector((state: RootState) => {
    if (selectedForecastId != null) {
      return getForecastById(selectWorkspaceData(state), {
        objectType,
        objectId: selectedForecastId.objectId,
        id: selectedForecastId.id,
      });
    }
  });
  return forecast;
};

export const useForecastElement = (
  budget: Budget,
  forecast: ForecastState | undefined,
  objectType: ForecastObjectType,
  objectId: string,
) => {
  const forecasts = useSelector((state: RootState) =>
    getForecastsByObjectId(selectWorkspaceData(state), {
      objectType,
      objectId,
    }),
  );

  const dispatch = useDispatch<AppDispatch>();

  const [forecastInput, setForecastInput] = React.useState<ForecastState>({
    id: uuid.v1(),
    name: '',
    description: '',
    budgetId: budget.id,
    objectId,
    objectType,
    element: manualElement,
  });

  const handleForecast = (forecast: ForecastState) => {
    dispatch(
      selectedForecastsActions.upsertOneElement({
        budgetId: forecast.budgetId,
        objectType: forecast.objectType,
        objectId: forecast.objectId,
        id: forecast.id,
      }),
    );
  };

  const getOptionLabel = React.useCallback((t: ForecastState) => {
    return t.name;
  }, []);

  const { dialog, modal } =
    useEditForecastElementDialogComponent(forecastInput);

  const initDialog = React.useCallback(
    (value: string) => {
      setForecastInput({
        id: uuid.v1(),
        name: value,
        description: '',
        budgetId: budget.id,
        objectId,
        objectType,
        element: manualElement,
      });
    },
    [budget.id, objectId, objectType],
  );

  const isOptionEqualToValue = (
    option: ForecastState,
    value: ForecastState,
  ) => {
    return option.id === value.id;
  };
  const element = (
    <EditAutocomplete
      multiple={false}
      addOption={{
        initDialog,
        setOpen: modal.setIsShowing,
        dialogElement: dialog,
      }}
      getOptionLabel={getOptionLabel}
      label="Forecast"
      handleValue={handleForecast}
      options={forecasts ?? []}
      value={forecast}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );

  return {
    element,
  };
};
