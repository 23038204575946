import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ProjectReportEditDialogComponent } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/project-report-edit-dialog.component';
import { InputData } from './project-report-input.data';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  handleClose: () => void;
  isShowing: boolean;
  handleCancel: () => void;
}

export const CreateProjectReportComponent = memo((props: Props) => {
  const { handleClose, isShowing, handleCancel, workspace, projectId } = props;

  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputData) => {
      console.log('SAVING');

      return dispatch(
        createProjectReports(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            projectId,
            name: data.name,
            elementOperations: [],
            layouts: '{}',
          },
        ]),
      ).then(() => handleClose());
    },
    [dispatch, handleClose, projectId, workspace.id, workspace.tenantId],
  );

  return (
    <ProjectReportEditDialogComponent
      handleCancel={handleCancel}
      handleClose={handleClose}
      isShowing={isShowing}
      onSave={onSave}
    />
  );
});
