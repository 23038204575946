import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertAddresses } from '~src/data/store/modules/people/addresses/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'peopleAddresses';

export interface Address {
  id: string;
  personId: string;
  streetAddress?: Maybe<string>;
  streetNumber?: Maybe<string>;
  administrativeAreaLevel1?: Maybe<string>;
  administrativeAreaLevel2?: Maybe<string>;
  locality?: Maybe<string>;
  postalCode?: Maybe<string>;
}

const header: Array<keyof Address> = [
  'id',
  'personId',
  'streetAddress',
  'streetNumber',
  'administrativeAreaLevel1',
  'administrativeAreaLevel2',
  'locality',
  'postalCode',
];

export const addressExportHandler = (
  data: Address[],
): ExportHandler<Address> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const addressImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Address> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertAddresses(workspace.tenantId, workspace.id, ts)),
  };
};
