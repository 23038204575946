import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseIndexedSlice } from '~src/data/base/indexed/base-indexed-slice';
import { EntityIndexedAdapter } from '~src/data/base/indexed/indexed-entity-adapter';

export interface BaseStockHoldingId {
  holdingId: string;
  id: string;
}

export type StockHoldingState = Omit<
  graphqlWorkspaceTypes.StockHolding,
  'holding' | 'stock'
> & {
  stockId: string;
  holdingId: string;
};

export const initialStockHoldingState = { ts: [], idxs: [] };

const selectId = (stockHolding: BaseStockHoldingId) => stockHolding.id;
const selectIdx = (stockHolding: BaseStockHoldingId) => stockHolding.holdingId;
const idxComparator = (a: string, b: string) => strcmp(a, b);
const iComparator = (a: string, b: string) => strcmp(a, b);
const merger = (a: StockHoldingState, b: StockHoldingState) => b;

const adapter = new EntityIndexedAdapter<
  BaseStockHoldingId,
  StockHoldingState,
  string,
  string
>(
  initialStockHoldingState,
  selectId,
  selectIdx,
  merger,
  iComparator,
  idxComparator,
);

export const stockHoldingsSlice = createBaseIndexedSlice(
  'stockHoldings',
  adapter,
);
export const stockHoldingsSelectors = adapter.selectors;
export const stockHoldingsReducer = stockHoldingsSlice.reducer;
export const stockHoldingsActions = stockHoldingsSlice.actions;
