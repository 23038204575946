import { compact } from 'lodash';
import { useSelector } from 'react-redux';
import { AllocationState } from '~src/data/store/reducers/portfolio/allocations/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { getRegionById } from '~src/data/store/selectors/common/regions/selectors';
import { getCurrencyById } from '~src/data/store/selectors/finance/currencies/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { isIn, hasFilter, Filter } from '~src/utils/common/filter';

import { createDataGroups } from './create-data-groups';
import { Data } from './data';

export function useAllocationGoalData(
  allocations: AllocationState[],
  filter: Filter,
  showFilteredOutAssetData: boolean,
  filterKey: string,
): Data[] {
  const financialData = useSelector((state: RootState) =>
    selectFinancialData(state),
  );
  const workspaceData = useSelector((state: RootState) =>
    selectWorkspaceData(state),
  );

  const keyMaps = [
    { key: 'category', prop: 'category' },
    { key: 'sector', prop: 'sector' },
    { key: 'assetClass', prop: 'assetClass' },
    { key: 'currency', prop: 'currencyId' },
    { key: 'region', prop: 'regionId' },
    { key: 'assetType', prop: 'assetType' },
    // 'id',
  ];

  const currentKey = keyMaps.find((key) => key.key === filterKey);
  if (!currentKey) {
    return [];
  }

  allocations = allocations.filter((allocation) => {
    let filteredOut = false;
    keyMaps.forEach((key) => {
      if (!(allocation as any)[currentKey.prop]) {
        // If the allocation doesn't have the property being filtered on it's out
        filteredOut = true;
        return false;
      } else if (hasFilter(filter, key.prop)) {
        // If the key has a filter and the allocation doesn't have the filter or it's not included it's out
        if (
          !(allocation as any)[key.prop] ||
          (!showFilteredOutAssetData &&
            !isIn(filter, {
              key: key.prop,
              value: (allocation as any)[key.prop],
            }))
        ) {
          filteredOut = true;
          return false;
        }
      } else if ((allocation as any)[key.prop] && key.key !== currentKey.key) {
        // If the key isn't filtered on yet the allocation has the property
        filteredOut = true;
        return false;
      }
    });
    return !filteredOut;
  });

  const calculateValue = (
    min: number | null | undefined,
    max: number | null | undefined,
  ) => {
    if (min && max) {
      return (min + max) / 2;
    } else if (min) {
      return min;
    } else if (max) {
      return max;
    }
    return 0;
  };

  let data = compact(
    allocations.map((allocation) => {
      const filterValue = (allocation as any)[currentKey.prop];
      const filteredOut = !isIn(filter, {
        key: currentKey.key,
        value: filterValue,
      });
      if (!filteredOut || showFilteredOutAssetData) {
        let name;
        if (currentKey.key === 'region') {
          const region = getRegionById(workspaceData, {
            id: (allocation as any)[currentKey.prop],
          });
          if (region) {
            name = region.name;
          }
        } else if (currentKey.key === 'currency') {
          const currency = getCurrencyById(financialData, {
            id: (allocation as any)[currentKey.prop],
          });
          if (currency) {
            name = currency.symbol;
          }
        } else {
          name = (allocation as any)[currentKey.prop];
        }
        return {
          key: currentKey.key,
          name,
          id: filterValue,
          value: calculateValue(allocation.min, allocation.max),
          filteredOut,
        };
      }
      return undefined;
    }),
  );

  data = data.sort((a, b) => {
    return a.value - b.value;
  });
  return createDataGroups(data, 5);
}
