import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import {
  commitmentsSelectors,
  CommitmentState,
} from '~src/data/store/reducers/holding/commitment/commitments/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import { RecordState } from '@pladdenico/common';

const selectCommitmentState = (state: WorkspaceDataState) =>
  state.holding.holding.commitment.commitments;

export const getCommitments = createSelector(
  (state: WorkspaceDataState) => selectCommitmentState(state),
  (commitments): CommitmentState[] => {
    return commitmentsSelectors.selectAllElements(commitments);
  },
);

export const getCommitmentById = createCachedSelector(
  (state: WorkspaceDataState) => getCommitments(state),
  (_state: WorkspaceDataState, id: string) => id,
  (commitments, id): CommitmentState | undefined => {
    return commitments.find((commitment) => commitment.id === id);
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const selectCommitmentsByHoldingId = createCachedSelector(
  (state: RecordState<CommitmentState, string>[], holdingId: string) =>
    commitmentsSelectors.selectElementsByKey(state, holdingId),
  (commitments: CommitmentState[] | undefined): CommitmentState[] => {
    return commitments ? commitments : [];
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getCommitmentsByHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => selectCommitmentState(state),
  (_state: WorkspaceDataState, holdingId: string) => holdingId,
  (commitmentState, holdingId): CommitmentState[] => {
    return selectCommitmentsByHoldingId(commitmentState, holdingId);
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

export const getCommitmentsByHoldingIds = createCachedSelector(
  (state: WorkspaceDataState) => selectCommitmentState(state),
  (_state: WorkspaceDataState, holdingIds: string[]) => holdingIds,
  (commitmentState, holdingIds: string[]): CommitmentState[] => {
    const commitments = commitmentsSelectors.selectElementsByKeys(
      commitmentState,
      holdingIds,
    );
    return commitments ? commitments : [];
  },
)({
  keySelector: (_state, holdingIds) => holdingIds,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getCommitmentsByTransferId = createCachedSelector(
  (state: WorkspaceDataState) => selectCommitmentState(state),
  (_state: WorkspaceDataState, transferId: string) => transferId,
  (commitmentState, transferId): CommitmentState[] => {
    const commitments = commitmentsSelectors.selectAllElements(commitmentState);
    return commitments.filter((commitment) =>
      commitment.transferIds.some((t) => t === transferId),
    );
  },
)({
  keySelector: (_state, holdingId) => holdingId,
  selectorCreator: createSelector,
});

// export const getCommitmentsByTransferIds = createCachedSelector(
//   (state: WorkspaceDataState) => selectCommitmentState(state),
//   (_state: WorkspaceDataState, transferIds: string[]) => transferIds,
//   (commitmentState, transferIds): CommitmentState[] => {
//     const commitments = commitmentsSelectors
//       .selectAllElements(commitmentState)
//       .sort((a, b) => strcmp(a.transferId, b.transferId));
//     transferIds = transferIds.sort((a, b) => strcmp(a, b));
//     return intersectionSorted(
//       commitments,
//       transferIds,
//       [(t, transferId) => strcmp(t.transferId, transferId)],
//       (t, _transferId) => t
//     );
//   }
// )((_state, holdingId) => holdingId);
