import React from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { useEditForecastElementDialogComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/use-edit-dialog.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { forecastsActions } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';

export const useForecastMenu = (forecast: ForecastState) => {
  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();
  const { open, isShowing, close } = useVisible();
  const { dialog: editDialog, modal: editModal } =
    useEditForecastElementDialogComponent(forecast);
  const dispatch = useDispatch<AppDispatch>();
  const deleteForecastElement = React.useCallback(() => {
    dispatch(
      forecastsActions.removeOneElement({
        id: forecast.id,
        objectType: forecast.objectType,
        objectId: forecast.objectId,
      }),
    );
    // }
    return Promise.resolve();
  }, [dispatch, forecast.id, forecast.objectType, forecast.objectId]);

  const menuElement = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            editModal.open();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={open}>Delete</MenuItem>
        <ConfirmDialogComponent
          isOpen={isShowing}
          handler={() => {
            deleteForecastElement().then(() => handleClose());
          }}
          close={close}
          objectString="forecastElement"
        />
        {editDialog}
      </Menu>
    ),
    [
      anchorEl,
      isOpen,
      handleClose,
      open,
      isShowing,
      close,
      editDialog,
      editModal,
      deleteForecastElement,
    ],
  );

  const menuButtonElement = React.useMemo(
    () => (
      <IconButton onClick={handleOpen} color="inherit">
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOpen],
  );

  return {
    menuElement,
    menuButtonElement,
    handleOpen,
    handleClose,
  };
};
