import React from 'react';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useCreateForm } from '~src/domain/workspace/components/project/types/portfolio-project/form/use-create-form';
import { ProjectCreate } from '~src/domain/workspace/components/project/form/use-project-create';

interface Props {
  workspace: WorkspaceState;
  handleClose: () => void;
  projectForm: ProjectCreate;
  onFormCallbacks: {
    onSuccess: (project: PortfolioProjectState) => void;
    onFailure: (_error: any) => void;
  };
}

export interface PortfolioInput {
  description: string;
  holdingIds: string[];
  name: string;
  parentPortfolioId: '';
  portfoliosIds: string[];
}

export const CreatePortfolioProjectFormComponent = React.memo(
  (props: Props) => {
    const { projectForm, workspace, handleClose, onFormCallbacks } = props;

    const { form, submit } = useCreateForm({
      workspace,
      projectForm,
    });
    const handleOnSave = React.useCallback(() => {
      return submit((portfolioProject) => {
        handleClose();
        onFormCallbacks.onSuccess(portfolioProject);
        return Promise.resolve();
      }, onFormCallbacks.onFailure);
    }, [handleClose, onFormCallbacks, submit]);

    return (
      <DialogContentComponent
        title="Create project"
        handleCancel={handleClose}
        handleOnSave={handleOnSave}
        confirmLabel="Create"
      >
        {form}
      </DialogContentComponent>
    );
  },
);
