import * as uuid from 'uuid';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getLastQuoteByStockId } from '~src/data/store/selectors/finance/quotes/stock/stock-quotes';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { getLastPosition } from '~src/data/store/selectors/holding/holding-types/stock/stock-position/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { StockTransferData } from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';
import { store } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';

export const initializeStockTransferData = (
  workspace: WorkspaceState,
  holding: HoldingState,
  transferId: string,
  date: Date,
) => {
  const stockHolding = getStockHoldingByHoldingId(
    selectWorkspaceData(store.getState()),
    holding.id,
  );
  if (stockHolding) {
    const lastStockPrice = getLastQuoteByStockId(selectData(store.getState()), {
      workspaceId: workspace.id,
      stockId: stockHolding.stockId,
    });
    const lastStockPosition = getLastPosition(stockHolding.id);
    const positionId = uuid.v1();
    const stockData: StockTransferData = {
      holdingType: HoldingType.Stock,
      position: {
        id: positionId,
        date,
        shares: lastStockPosition ? lastStockPosition.shares ?? 0 : 0,
        stockHoldingId: stockHolding.id,
      },
      trade: {
        id: uuid.v1(),
        sharePrice: lastStockPrice?.close ?? 0,
        positionId,
        transferId,
        shares: 0,
        stockHoldingId: stockHolding.id,
      },
    };
    return stockData;
  }
  return undefined;
};
