import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHoldingFields } from '~src/components/fields/workspace/holding/algorithm/use-fields';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  getHoldingsDenormalized,
  HoldingDenormalized,
} from '~src/data/store/selectors/finance/valuation/holdings-assets';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { TableField, TableOperations } from '@pladdenico/table';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';

export const useHoldingsAlgorithm = (
  workspace: WorkspaceState,
  holdings: HoldingState[],
  // portfolio: PortfolioState
) => {
  // const holdings = useSelector((state: RootState) =>
  //   getHoldingsByPortfolioId(selectWorkspaceData(state), {
  //     workspaceId: workspace.id,
  //     projectId: project.id,
  //     id: portfolio.id,
  //   })
  // );

  const holdingsDenormalized = useSelector((state: RootState) =>
    getHoldingsDenormalized(selectWorkspaceData(state), {
      holdings,
      workspaceId: workspace.id,
    }),
  );

  const fields = useHoldingFields();

  const initialHoldingSorters = React.useMemo(() => {
    const sorters: TableField.Sorter<HoldingDenormalized>[] = compact([
      TableOperations.createSorter(fields.name, 'asc'),
      TableOperations.createSorter(fields.description, 'asc'),
      TableOperations.createSorter(fields.type, 'asc'),
      TableOperations.createSorter(fields.valuation, 'asc'),
    ]);
    return sorters;
  }, [fields.description, fields.name, fields.type, fields.valuation]);

  const initialHoldingFilters: TableField.Filter<HoldingDenormalized>[] =
    React.useMemo(
      () => [
        TableOperations.createFilter(fields.name),
        TableOperations.createFilter(fields.description),
        TableOperations.createFilter(fields.type),
        TableOperations.createFilter(fields.portfolios),
        TableOperations.createFilter(fields.valuation),
        {
          id: 'valuationAbove_0',
          name: 'valuationAbove_0',
          type: TableField.FilterTypes.boolean,
          objId: fields.valuation.id,
          query: true,
          operation: (t: HoldingDenormalized, query: boolean) => {
            if (!query) {
              return true;
            }
            const value = fields.valuation.iteratee(t);
            if (value == null) {
              return true;
            }
            return value > 0;
          },
        },
      ],
      [
        fields.description,
        fields.name,
        fields.portfolios,
        fields.type,
        fields.valuation,
      ],
    );

  const algoData = TableOperations.useDataAlgorithms(
    holdingsDenormalized,
    initialHoldingSorters,
    initialHoldingFilters,
  );

  return {
    algoData,
    fields,
    holdingsDenormalized,
  };
};
