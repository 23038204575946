import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getTransactionById } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { transactionBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/transaction/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { maxItems } from './breadcrumbs.component';
import { useProjectBreadcrumbs } from './project-breadcrumb';

export function TransactionBreadcrumbComponent() {
  const { tenantId, workspaceId, projectId, transactionId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    transactionId: string;
  }>();
  const projectBreadCrumbs = useProjectBreadcrumbs(false);
  const transaction = useSelector((state: RootState) => {
    if (projectId != null && transactionId != null) {
      return getTransactionById(selectWorkspaceData(state), {
        projectId,
        id: transactionId,
      });
    }
  });
  if (transaction && tenantId != null && workspaceId != null) {
    return (
      <Breadcrumbs
        maxItems={maxItems}
        color="inherit"
        separator="›"
        aria-label="breadcrumb"
      >
        {projectBreadCrumbs}
        <Typography
          color="inherit"
          key={transactionBasePath(
            tenantId,
            workspaceId,
            transaction.projectId,
            transaction.id,
          )}
        >
          {transaction.title}
        </Typography>
      </Breadcrumbs>
    );
  }
  return <></>;
}
