import { Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useImagePicker } from '~src/components/utils/image/image-picker';
import { ImgDropzoneComponent } from '~src/components/utils/image/img-dropzone';
import { createFiles } from '~src/data/store/modules/file/file/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  updateFile: (fileId: string) => void;
}

const getImageIdRoot = (tenantId: string, workspaceId: string) => {
  return `${tenantId}/${workspaceId}/`;
};

export const SelectImageComponent = React.memo((props: Props) => {
  const { workspace, updateFile } = props;
  const imageIdBase = React.useMemo(
    () => getImageIdRoot(workspace.tenantId, workspace.id),
    [workspace.id, workspace.tenantId],
  );
  const dispatch = useDispatch<AppDispatch>();
  const imgCallback = React.useCallback(
    (
      id: string,
      name: string,
      description: string,
      url: string,
      type: string,
    ) => {
      return dispatch(
        createFiles(workspace.tenantId, workspace.id, [
          {
            id,
            key: url,
            name,
            type,
            description,
          },
        ]),
      ).then((files) => {
        updateFile(files[0].id);
        return files;
      });
    },
    [dispatch, updateFile, workspace.id, workspace.tenantId],
  );

  const imagePicker = useImagePicker({
    selectFile: (file) => updateFile(file.id),
    workspace,
  });
  return (
    <>
      <Button onClick={imagePicker.visible.open}>
        Pick image from library
      </Button>
      {imagePicker.dialogElement}
      <ImgDropzoneComponent baseId={imageIdBase} callback={imgCallback} />
    </>
  );
});
