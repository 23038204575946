import * as React from 'react';
import { CryptoState } from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { CryptoHoldingState } from '~src/data/store/reducers/holding/holding-types/crypto/crypto-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { BaseHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/base-holding-card.component';
import { useActions } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/crypto/use-actions';

import { Typography } from '@mui/material';

import { EditDialogComponent } from '../../../../holding/holding-types/crypto-holdings/form/edit-dialog.component';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  cryptoHolding: CryptoHoldingState;
  crypto: CryptoState | undefined;
  asset: AssetState | undefined;
}

export const CryptoHoldingCardComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holding,
    portfolios,
    cryptoHolding,
    crypto,
    asset,
  } = props;

  const getEditDialogComponent = React.useCallback(
    (editing: boolean, handleClose: () => void) => {
      return (
        <EditDialogComponent
          open={editing}
          handleClose={handleClose}
          holding={holding}
          cryptoHolding={cryptoHolding}
          workspace={workspace}
          projectId={projectId}
          portfolios={portfolios}
        />
      );
    },
    [holding, portfolios, projectId, workspace, cryptoHolding],
  );

  const { deleteHolding } = useActions(
    workspace,
    projectId,
    holding,
    cryptoHolding,
  );
  const subheader = crypto
    ? `${crypto.bank}-${crypto.accountNumber}`
    : undefined;

  const detailElement = subheader ? (
    <Typography variant="subtitle2" align="center">
      {subheader}
    </Typography>
  ) : (
    <></>
  );

  return (
    <BaseHoldingCardComponent
      asset={asset}
      projectId={projectId}
      assetDetailElement={detailElement}
      getDialogComponent={getEditDialogComponent}
      holding={holding}
      portfolios={portfolios}
      workspace={workspace}
      deleteHolding={deleteHolding}
    />
  );
});
