import React from 'react';
import { ForecastElementBase } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-element-state';
import { ManualElementState } from '~src/data/store/state/workspace/project/scenario/forecast/manual-element-state';
import { useScenarioHoldingForecastManualElementFieldsComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/manual/use-manual-fields.component';

// import moment from 'moment';
import { Grid } from '@mui/material';

interface Props {
  forecastElement: ManualElementState;
  setForecastElement: (element: ForecastElementBase) => void;
}

export const EditHoldingForecastManualElementComponent = React.memo(
  (props: Props) => {
    const { forecastElement, setForecastElement } = props;
    const { valuesElement } =
      useScenarioHoldingForecastManualElementFieldsComponent(
        forecastElement,
        setForecastElement,
      );
    return (
      <Grid container mr={1}>
        <Grid item xs={12}>
          {valuesElement}
        </Grid>
      </Grid>
    );
  },
);
