import { parseCrypto } from '~src/data/store/modules/assets/cryptos/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  cryptosActions,
  CryptoState,
} from '~src/data/store/reducers/asset/asset-types/cryptos/reducer';
import { Asset, Crypto } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { AssetType } from '@pladdenico/models';

export interface CryptoVisitor {
  visit(crypto: CryptoVisitable): CryptoState;
  post(): void;
}

export const isCrypto = (asset: Asset): asset is Crypto => {
  return asset._type === AssetType.Crypto;
};

export class CryptoVisitable implements Visitable<CryptoVisitor> {
  constructor(
    private _workspaceId: string,
    private _crypto: Crypto,
  ) {}
  public accept(visitor: CryptoVisitor) {
    return visitor.visit(this);
  }

  public parse(): CryptoState {
    const assetId = this._crypto.asset?.id ?? '';
    return parseCrypto(this._workspaceId, assetId, this._crypto);
  }
}

export class CryptoStateVisitor implements CryptoVisitor {
  private _cryptos: CryptoState[];
  constructor(private _dispatch: AppDispatch) {
    this._cryptos = [];
  }

  public visit(crypto: CryptoVisitable): CryptoState {
    const cryptoParsed = crypto.parse();
    this._cryptos.push(cryptoParsed);
    return cryptoParsed;
  }

  post() {
    this._dispatch(
      cryptosActions.upsertManyElements(this._cryptos, {
        shouldAutobatch: true,
      }),
    );
  }
}
