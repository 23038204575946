import { EntityAdapter } from '~src/data/base/simple/simple-entity-adapter';
import { createBaseSlice } from '~src/data/base/simple/base-simple-slice';

export type SelectedPortfolioState = string;

export function selectedPortfolioToSelectedPortfolioState(
  selectedPortfolio: SelectedPortfolioState,
) {
  return selectedPortfolio;
}
export const initialSelectedPortfolioState = null;

const adapter = new EntityAdapter<string>(initialSelectedPortfolioState);

export const selectedPortfoliosSlice = createBaseSlice(
  'selectedPortfolios',
  adapter,
);
export const selectedPortfoliosSelectors = adapter.selectors;

export const selectedPortfoliosReducer = selectedPortfoliosSlice.reducer;
export const selectedPortfoliosActions = selectedPortfoliosSlice.actions;

// export const selectedPortfolioInitialState = {
//   selectedPortfolios: initialState,
// };
