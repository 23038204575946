import { EntityIndexedAdapter, IndexedState } from './indexed-entity-adapter';
import { createSlice, createReducer } from '../slice';

export function createBaseIndexedSlice<B, T extends B, Id, Idx>(
  domain: string,
  adapter: EntityIndexedAdapter<B, T, Id, Idx>,
) {
  const slices = {
    upsertOneElement: createSlice(
      domain,
      'upsertOneElement',
      adapter.upsertOneElementReducer(),
    ),
    upsertManyElements: createSlice(
      domain,
      'upsertManyElements',
      adapter.upsertManyElementsReducer(),
    ),
    removeOneElement: createSlice(
      domain,
      'removeOneElement',
      adapter.removeOneElementReducer(),
    ),
    removeManyElements: createSlice(
      domain,
      'removeManyElements',
      adapter.removeManyElementsReducer(),
    ),
    clear: createSlice<IndexedState<B, T>, undefined>(
      domain,
      'clear',
      adapter.clearReducer(),
    ),
  };

  const actions = {
    removeManyElements: slices.removeManyElements.action,
    removeOneElement: slices.removeOneElement.action,
    upsertManyElements: slices.upsertManyElements.action,
    upsertOneElement: slices.upsertOneElement.action,
    clear: slices.clear.action,
  };

  const reducer: (
    state: IndexedState<B, T> | undefined,
    action: any,
  ) => IndexedState<B, T> = createReducer(adapter.initialState, slices);

  return {
    actions,
    reducer,
  };
}
