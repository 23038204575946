import moment from 'moment';
import createCachedSelector from 're-reselect';
import { DataState } from '~src/data/store/reducers/reducers';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { getValuationsByHoldingId } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { Finance } from '~src/utils/finance/holding';
import { Period } from '~src/utils/period/period';

import { Quote, Trade } from '@pladdenico/models';
import { getExchangeRateState } from '../exchange-rates/selectors';
import { createSelector } from 'reselect';

interface Props {
  holdingId: string;
  date: moment.Moment;
  period: Period;
  currency: CurrencyState;
  useLiveValue: boolean;
}

export const getHoldingXIRR = createCachedSelector(
  (state: DataState, props: Props) => {
    return getValuationsByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holdingId,
    );
  },
  (state: DataState, props: Props) =>
    getTransfersByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holdingId,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (valuations, transfers, date, period, currency, exchangeRateState) => {
    const quotes: Quote[] = [];
    const trades: Trade[] = [];
    return Finance.xirr(
      valuations,
      transfers,
      period.start.toDate(),
      period.end.toDate(),
      date.toDate(),
      exchangeRateState,
      currency,
      quotes,
      trades,
    );
  },
)({
  keySelector: (_state: DataState, props: Props) =>
    `${props.holdingId}:${props.date.unix()}:${
      props.currency.id
    }:${props.period.id()}`,
  selectorCreator: createSelector,
});
