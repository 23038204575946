import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateAssets } from '~src/data/store/modules/assets/assets/requests';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AssetEditInputData } from '~src/domain/workspace/components/asset/form/edit/asset-edit-input.data';
import { useAssetEditFields } from '~src/domain/workspace/components/asset/form/edit/use-edit-asset.fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  asset: AssetState;
  inputData?: AssetEditInputData;
  callbacks?: {
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  };
}

export const useAssetEditForm = (props: Props) => {
  const { asset, workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: AssetEditInputData) => {
      return dispatch(
        updateAssets(workspace.tenantId, workspace.id, [
          {
            id: asset.id,
            name: data.name,
            entityId: data.entityId,
            assetClass: data.assetClass,
            categoryId: data.categoryId,
            description: data.description,
            ISIN: data.ISIN,
            lookup: data.lookup,
            type: data.type,
            types: data.types,
            VPS: data.VPS,
          },
        ]),
      )
        .then(() => callbacks?.onSuccess && callbacks.onSuccess())
        .catch((err) => callbacks?.onFailure && callbacks.onFailure(err));
    },
    [dispatch, workspace.tenantId, workspace.id, asset.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<AssetEditInputData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useAssetEditFields(workspace, control, {
    disableAssetType: true,
  });

  const onSubmit: SubmitHandler<AssetEditInputData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields };
};
