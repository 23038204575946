import React from 'react';
import { drawerWidth } from '~src/domain/workspace/components/drawer/workspace-drawer.component';

export const useDrawer = () => {
  const [open, setOpen] = React.useState(false);
  function toggleDrawer() {
    setOpen((open) => !open);
  }
  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return {
    open,
    handleDrawerOpen,
    handleDrawerClose,
    toggleDrawer,
    width: drawerWidth,
  };
};

export type DrawerType = ReturnType<typeof useDrawer>;
