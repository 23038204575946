import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseCapitalCallId,
  capitalCallsSelectors,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/capital-calls/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getCapitalCalls = (state: WorkspaceDataState) => {
  return state.holding.asset.privateEquityFund.capitalCalls;
};

export const getCapitalCallsByFundId = createCachedSelector(
  (state: WorkspaceDataState) => getCapitalCalls(state),
  (_state: WorkspaceDataState, fundId: string) => fundId,
  (capitalCallsState, fundId) => {
    const privateEquityFunds = capitalCallsSelectors.selectElementsByKey(
      capitalCallsState,
      fundId,
    );
    return privateEquityFunds ? privateEquityFunds : [];
  },
)({
  keySelector: (_state, privateEquityFundId) => privateEquityFundId,
  selectorCreator: createSelector,
});

// export const privateEquityFundByIdSelector = (privateEquityFunds: ICapitalCall[], privateEquityFundId: string) => {
//   return __OLDsortedFind(privateEquityFunds, { id: privateEquityFundId } as ICapitalCall, (a) => a.id);
// };

export const getCapitalCallById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseCapitalCallId>) =>
    getCapitalCalls(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseCapitalCallId>) =>
    baseId.fundId,
  (_state: WorkspaceDataState, baseId: Partial<BaseCapitalCallId>) => baseId.id,
  (capitalCalls, fundId, privateEquityFundId) => {
    if (!fundId || !privateEquityFundId) {
      return undefined;
    }
    return capitalCallsSelectors.selectElementByT(capitalCalls, {
      fundId,
      id: privateEquityFundId,
    });
  },
)({
  keySelector: (_state, fundId, privateEquityFundId) =>
    `${fundId}-${privateEquityFundId}`,
  selectorCreator: createSelector,
});
