import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createRegionAllocations } from '~src/data/store/modules/entities/region-allocations/requests';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { AppDispatch } from '~src/store/store';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { FieldsComponent } from './fields.component';

interface Props {
  tenantId: string;
  entity: EntityState;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },
}));

function createRegionAllocationInput(entity: EntityState) {
  return {
    allocation: 100,
    entityId: entity.id,
    date: new Date(),
    id: uuid.v1(),
    regionId: '',
  };
}

export const DialogFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const [regionAllocationInputs, setRegionAllocationInputs] = React.useState<
    graphqlWorkspaceTypes.CreateRegionAllocationInputType[]
  >(() => [createRegionAllocationInput(props.entity)]);
  // const regionAllocationsElements = props.entityInput.regionAllocationsRelation.operations.map((operation) => {
  //   operation.
  // });

  const dispatch = useDispatch<AppDispatch>();

  const handlePersistRegionAllocations = (): void => {
    dispatch(
      createRegionAllocations(
        props.tenantId,
        props.entity.workspaceId,
        regionAllocationInputs,
      ),
    )
      .then(() => {
        props.handleClose();
      })
      .catch((err) => {
        console.info('FAILED', regionAllocationInputs, JSON.stringify(err));
      });
  };

  const setRegionAllocationInput = (
    regionAllocationInput: graphqlWorkspaceTypes.CreateRegionAllocationInputType,
    index: number,
  ) => {
    setRegionAllocationInputs([
      ...regionAllocationInputs.slice(0, index),
      regionAllocationInput,
      ...regionAllocationInputs.slice(index + 1),
    ]);
  };

  const addRegionAllocationInput = () => {
    setRegionAllocationInputs([
      ...regionAllocationInputs,
      createRegionAllocationInput(props.entity),
    ]);
  };

  const regionAllocationsElements = regionAllocationInputs.map(
    (regionAllocationInput, index) => {
      return (
        <FieldsComponent
          key={index}
          regionAllocationInput={regionAllocationInput}
          setRegionAllocationInput={(
            regionAllocationInput: graphqlWorkspaceTypes.CreateRegionAllocationInputType,
          ) => {
            setRegionAllocationInput(regionAllocationInput, index);
          }}
        />
      );
    },
  );

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Set up region allocations
      </DialogTitle>
      <DialogContent>
        <form className={classes.container} noValidate autoComplete="off">
          {regionAllocationsElements}
          <Button onClick={addRegionAllocationInput} color="primary">
            Add
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handlePersistRegionAllocations} color="primary">
          Next
        </Button>
      </DialogActions>
    </>
  );
});
