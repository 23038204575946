import { FinanceTabs } from '~src/domain/workspace/components/finance/tab/finance-tabs.component';
import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceFinanceBaseName = 'finance';

export const workspaceFinanceRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(
    tenantId,
    workspaceId,
  )}/${workspaceFinanceBaseName}`;
};

export const workspaceFinanceTabPath = (
  tenantId: string,
  workspaceId: string,
  tab: FinanceTabs,
) => {
  return `${workspaceFinanceRootPath(tenantId, workspaceId)}/${tab}`;
};

export const workspaceFinanceBasePath = workspaceFinanceRootPath(
  tenantBaseId,
  workspaceBaseId,
);
