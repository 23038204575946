import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { unselectWorkspace } from '~src/data/store/modules/workspaces/workspaces/actions';
import { deleteWorkspace } from '~src/data/store/modules/workspaces/workspaces/requests';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { workspacesRootPath } from '~src/navigation/paths/workspace/paths';
import { AppDispatch } from '~src/store/store';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Menu } from '@mui/material';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

export const useWorkspaceSettingsMenu = (workspace: WorkspaceState) => {
  const { anchorEl, handleClose, handleOpen, isOpen } = useMenu();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleDeleteWorkspace = React.useCallback(() => {
    dispatch(deleteWorkspace(workspace.tenantId, workspace.id)).then(() => {
      dispatch(unselectWorkspace());
      navigate(workspacesRootPath(workspace.tenantId));
    });
  }, [dispatch, navigate, workspace.id, workspace.tenantId]);

  const deleteModal = useVisible();

  const deleteElement = React.useMemo(() => {
    return (
      <Button variant="text" color="warning" onClick={deleteModal.open}>
        <DeleteIcon />
        Delete workspace
      </Button>
    );
  }, [deleteModal.open]);
  const confirmElement = React.useMemo(() => {
    return (
      <ConfirmDialogComponent
        isOpen={deleteModal.isShowing}
        handler={() => {
          handleDeleteWorkspace();
          deleteModal.close();
        }}
        close={deleteModal.close}
        objectString="workspace"
      />
    );
  }, [deleteModal, handleDeleteWorkspace]);

  const menu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      keepMounted
      open={isOpen}
      onClose={handleClose}
      MenuListProps={{ dense: true }}
    >
      {deleteElement}
      {confirmElement}
    </Menu>
  );

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
