import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { userSignupPath } from '~src/navigation/paths/user/paths';
import { AppDispatch } from '~src/store/store';
import { fetchWorkspaceInvitations } from '~src/data/store/modules/workspaces/workspace-invitations/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectUserData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { WorkspaceInvitation } from './components/invitation';
import { getWorkspaceInvitationById } from '~src/data/store/selectors/workspace/workspace-invitations/selectors';

import { CircularProgress } from '@mui/material';

import { WorkspaceInvitation } from './invitation';

// import { getInvitationById } from '~src/store/modules/workspaces/invitations/base/selectors';

interface Props {
  invitationId: string;
}

const WorkspaceInvitationRouterWrapperWithInvitationId = React.memo(
  (props: Props) => {
    const invitation = useSelector((state: RootState) =>
      getWorkspaceInvitationById(
        selectWorkspaceData(state),
        props.invitationId,
      ),
    );
    console.log('fetchWorkspaceInvitations 0', JSON.stringify(invitation));
    if (!invitation) {
      return (
        <div>
          INVITATION ID: {props.invitationId}
          <CircularProgress />
        </div>
      );
    } else {
      return <WorkspaceInvitation invitation={invitation} />;
    }
  },
);

export const WorkspaceInvitationLoggedInRouterWrapper = React.memo(() => {
  const { tenantId, invitationId } = useParams<{
    tenantId: string;
    invitationId: string;
  }>();
  const dispatch = useDispatch<AppDispatch>();

  console.log('fetchWorkspaceInvitations 0', invitationId);
  React.useEffect(() => {
    console.log('fetchWorkspaceInvitations?', invitationId);
    if (invitationId !== undefined && tenantId != null) {
      // dispatch(fetchWorkspaceInvitations([invitationId]));
      dispatch(fetchWorkspaceInvitations(tenantId));
    }
  }, [dispatch, invitationId, tenantId]);

  if (!invitationId) {
    return <p>Not a valid invitation id</p>;
  } else {
    return (
      <WorkspaceInvitationRouterWrapperWithInvitationId
        invitationId={invitationId}
      />
    );
  }
});

export const WorkspaceInvitationRouterWrapper = React.memo(() => {
  const user = useSelector(
    (state: RootState) => selectUserData(state).account.user,
  );
  const location = useLocation();
  const redirectUrl = location.pathname;
  if (!user) {
    return (
      // <Navigate to={{ pathname: userSignupPath, state: { redirectUrl } }} />
      <Navigate to={userSignupPath} state={redirectUrl} />
    );
  }
  return <WorkspaceInvitationLoggedInRouterWrapper />;
});
