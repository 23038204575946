import React from 'react';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export type AddressInput =
  | graphqlWorkspaceTypes.CreateAddressInputType
  | graphqlWorkspaceTypes.UpdateAddressInputType;

interface Props {
  // open: boolean;
  addressInput: AddressInput;
  setAddressInput: (addressInput: AddressInput) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const AddressFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();

  const handleChange = (name: keyof AddressInput) => (value: any) => {
    props.setAddressInput({ ...props.addressInput, [name]: value });
  };

  const handleEventChange =
    (name: keyof AddressInput) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(name)(event.target.value);
      // props.setAddressInput({ ...props.addressInput, [name]: event.target.value });
    };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {/* <EditIdField
        className={classes.textField}
        handleChange={handleChange('id')}
        value={props.addressInput.id}
      /> */}
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="streetAddress"
        value={props.addressInput.streetAddress}
        label="Street address"
        type="text"
        onChange={handleEventChange('streetAddress')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="streetNumber"
        value={props.addressInput.streetNumber}
        label="Street number"
        type="text"
        onChange={handleEventChange('streetNumber')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="locality"
        value={props.addressInput.locality}
        label="Locality"
        type="text"
        onChange={handleEventChange('locality')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="administrativeAreaLevel1"
        value={props.addressInput.administrativeAreaLevel1}
        label="City"
        type="text"
        onChange={handleEventChange('administrativeAreaLevel1')}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="administrativeAreaLevel2"
        value={props.addressInput.administrativeAreaLevel2}
        label="Country"
        type="text"
        onChange={handleEventChange('administrativeAreaLevel2')}
      />
    </form>
  );
});
