import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface DashboardElementConfig extends Record<string, unknown> {
  backgroundColor?: string;
  color?: string;
  padding?: number;
  border?: null | string | number;
  data?: unknown;
}

export interface BaseDashboardElementId {
  id: string;
}

export type DashboardElementState = Omit<
  graphqlWorkspaceTypes.DashboardElement,
  'config'
> & {
  config: DashboardElementConfig;
};

export const initialDashboardElementState = [];

const tComparator = (a: BaseDashboardElementId, b: BaseDashboardElementId) =>
  strcmp(a.id, b.id);
const merger = (_a: DashboardElementState, b: DashboardElementState) => b;

const adapter = new EntityArrayAdapter<
  BaseDashboardElementId,
  DashboardElementState
>(initialDashboardElementState, tComparator, merger);

export const dashboardElementsSlice = createBaseArraySlice(
  'dashboardElements',
  adapter,
);
export const selectors = adapter.selectors;

export const dashboardElementsReducer = dashboardElementsSlice.reducer;
export const dashboardElementsActions = dashboardElementsSlice.actions;
