import React from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props<T> extends React.PropsWithChildren<unknown> {
  title: string;
  handleOnSave?: () => Promise<T>;
  handleCancel?: () => void;
  handleClose?: () => void;
  confirmLabel?: string;
}

export const DialogContentComponent = React.memo(
  <T extends unknown>(props: Props<T>) => {
    const {
      handleCancel,
      handleOnSave,
      handleClose,
      title,
      confirmLabel = 'Save',
      children,
    } = props;
    return (
      <>
        <DialogTitle id="form-dialog-title" variant="overline">
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {handleCancel && (
            <Button onClick={handleCancel} color="secondary">
              Cancel
            </Button>
          )}
          {handleClose && (
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          )}
          {handleOnSave && (
            <Button onClick={handleOnSave} variant="contained" color="primary">
              {confirmLabel}
            </Button>
          )}
        </DialogActions>
      </>
    );
  },
);
