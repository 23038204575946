import { debounce } from 'lodash';
import React from 'react';
import { useElementMenu } from '~src/components/dashboard/use-element-menu';
import {
  DashboardElementConfig,
  DashboardElementState,
} from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { FormatConfigComponent } from '~src/domain/workspace/components/common/dashboard/element/format-config.component';
import { useDeleteDashboardElementMenuItem } from '~src/domain/workspace/components/common/dashboard/element/use-delete-dashboard-element-menu-item';
import { SortOrderField } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/holdings-view/sort-order-field.component';
import { HoldingsViewTypeField } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/holdings-view/type.component';
import { useHandler } from '~src/hooks/utils/use-handler';
import {
  HoldingsViewType,
  HoldingsViewTypes,
} from '~src/utils/interfaces/holdings-view-type';
import { SortOrder, SortOrders } from '~src/utils/interfaces/sort-order';

import { Divider, MenuItem, TextField } from '@mui/material';

export const useHoldingsViewMenu = (
  element: DashboardElementState,
  remove: (id: string) => void,
  updated?: (id: string, element: Partial<DashboardElementState>) => void,
  // updated?: (element: DashboardElementState) => void
) => {
  const [config, setConfig] = React.useState(element.config);

  // React.useEffect(() => {
  //   setConfig(element.config);
  // }, [element.config]);

  const debounceUpdated = React.useMemo(() => {
    return debounce((config: DashboardElementConfig) => {
      console.log('updated', config);
      updated &&
        updated(element.id, {
          // ...element,
          config: {
            ...config,
          },
        });
    }, 400);
  }, [element, updated]);

  const updateConfig = React.useCallback(
    (config: DashboardElementConfig) => {
      console.log('config', config);
      setConfig({ ...config });
      debounceUpdated(config);
    },
    [debounceUpdated],
  );
  const { handleUpdate, handleEvent } = useHandler(
    element.config,
    updateConfig,
  );
  const { confirmElement: confirmDeleteElement, menuItem: deleteMenuItem } =
    useDeleteDashboardElementMenuItem(element, remove);

  const content = React.useMemo(() => {
    return [
      <FormatConfigComponent
        key="formatConfig"
        element={element}
        updated={updated}
      />,
      <Divider key="divider-1" />,
      <MenuItem key="menu-item-1">
        <HoldingsViewTypeField
          handleValue={handleUpdate('type')}
          label={'HoldingsView type'}
          type={(config.type as HoldingsViewType) ?? HoldingsViewTypes.Value}
        />
      </MenuItem>,
      <MenuItem key="menu-item-2">
        <SortOrderField
          handleValue={handleUpdate('sortOrder')}
          label={'Sort order'}
          sortOrder={(config.sortOrder as SortOrder) ?? SortOrders.Decreasing}
        />
      </MenuItem>,
      <MenuItem key="menu-item-3">
        <TextField
          label="Minimum value"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          // onChange={debounce(handleEvent('minimumValue'), 400)}
          onChange={handleEvent('minimumValue')}
          value={
            (config.minimumValue || config.minimumValue === typeof NaN) ?? null
          }
        />
      </MenuItem>,
      <Divider key="divider-delete" />,
      deleteMenuItem,
      confirmDeleteElement,
    ];
  }, [
    config.minimumValue,
    config.sortOrder,
    config.type,
    confirmDeleteElement,
    deleteMenuItem,
    element,
    handleEvent,
    handleUpdate,
    updated,
  ]);
  const { isOpen, handleOpen, menu } = useElementMenu(content);

  return {
    menu,
    handleOpen,
    isOpen,
  };
};
