import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createWarrants } from '~src/data/store/modules/assets/warrants/requests';
import { WarrantState } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputWarrantData } from '~src/domain/workspace/components/asset/types/warrants/form/warrant-input.data';
import { useWarrantFields } from '~src/domain/workspace/components/asset/types/warrants/form/use-warrant-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  inputData?: InputWarrantData;
  callbacks?: {
    onSuccess?: (warrants: WarrantState) => void;
    onFailure?: (error: any) => void;
  };
}

export const useCreateWarrantForm = (props: Props) => {
  const { workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (assetId: string, data: InputWarrantData) => {
      return dispatch(
        createWarrants(workspace.tenantId, workspace.id, [
          {
            id: uuid.v1(),
            assetId: assetId,
            description: data.description,
          },
        ]),
      )
        .then((warrants) => {
          const warrant = warrants[0];
          callbacks?.onSuccess && callbacks.onSuccess(warrant);
          return warrant;
        })
        .catch((err) => {
          callbacks?.onFailure && callbacks.onFailure(err);
          return Promise.reject(err);
        });
    },
    [dispatch, workspace.tenantId, workspace.id, callbacks],
  );

  const { control, handleSubmit } = useForm<InputWarrantData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useWarrantFields(workspace, control);

  const onSubmit = React.useCallback(
    (
      assetId: string,
      successCallback: (warrant: WarrantState) => Promise<void>,
    ): SubmitHandler<InputWarrantData> =>
      (data) => {
        return onSave(assetId, data).then((warrant) =>
          successCallback(warrant),
        );
      },
    [onSave],
  );

  const submit = React.useCallback(
    (
      assetId: string,
      successCallback: (warrant: WarrantState) => Promise<void>,
    ) => {
      return handleSubmit(onSubmit(assetId, successCallback))();
    },
    [handleSubmit, onSubmit],
  );

  return { fields, submit };
};
