import React from 'react';
import { useSelector } from 'react-redux';
import { EditFileComponent } from '~src/domain/workspace/components/file/file/edit.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { HorizontalImages } from '~src/components/utils/image/horizontal-images.component';
import { EntityState } from '~src/data/store/reducers/entity/entities/reducer';
import { FileState } from '~src/data/store/reducers/file/files/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getEntityByHoldingId } from '~src/data/store/selectors/entities/entities/selectors';
import { getEntityMediasByEntityId } from '~src/data/store/selectors/entities/media/selectors';
import { getFilesByIds } from '~src/data/store/selectors/file/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { EntityMediaDropzoneComponent } from '~src/domain/workspace/components/entity/entity/forms/create/media-dropzone.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { File } from '@pladdenico/portfolio-api/dist/generated/graphql.workspace';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  setFileId: (fileId: string) => void;
  close: () => void;
  isShowing: boolean;
}

export const AddMediaComponent = React.memo((props: Props) => {
  const { holding, project, workspace } = props;
  const entity = useSelector((state: RootState) =>
    getEntityByHoldingId(selectWorkspaceData(state), {
      projectId: project.id,
      workspaceId: workspace.id,
      holdingId: holding.id,
    }),
  );
  if (entity) {
    return <AddMediaComponentWithEntity {...props} entity={entity} />;
  }
  return <div>No entity</div>;
});

interface PropsWithEntity extends Props {
  entity: EntityState;
}

export const AddMediaComponentWithEntity = React.memo(
  (props: PropsWithEntity) => {
    const { workspace, entity, isShowing, close, setFileId: setFileId } = props;
    const entityFile = useSelector((state: RootState) =>
      getEntityMediasByEntityId(selectWorkspaceData(state), entity.id),
    );
    const fileIds = React.useMemo(
      () => entityFile.map((m) => m.fileId),
      [entityFile],
    );
    const files = useSelector((state: RootState) =>
      getFilesByIds(selectWorkspaceData(state), { ids: fileIds }),
    );

    const selectFile = React.useCallback(
      (file: FileState) => {
        setFileId(file.id);
        close();
      },
      [close, setFileId],
    );
    const [file, setFile] = React.useState<File>();
    const editModal = useVisible();
    const editComponent = React.useMemo(() => {
      if (file) {
        return (
          <EditFileComponent
            handleClose={editModal.close}
            isShowing={editModal.isShowing}
            file={file}
            tenantId={workspace.tenantId}
            workspace={workspace}
          />
        );
      }
    }, [editModal.close, editModal.isShowing, file, workspace]);
    const editFile = React.useCallback(
      (file: File) => {
        setFile(file);
        editModal.open();
      },
      [editModal],
    );

    return (
      <>
        Set image
        <IconButton size={'small'} color="inherit">
          <EditIcon />
        </IconButton>
        <DialogComponent
          dialogProps={{ open: isShowing, onClose: close, fullWidth: true }}
        >
          <DialogTitle id="form-dialog-title">Pick an image</DialogTitle>
          <DialogContent>
            <HorizontalImages
              files={files}
              selectFile={selectFile}
              editFile={editFile}
            />
            {editComponent}
            <EntityMediaDropzoneComponent
              workspace={workspace}
              entityId={entity.id}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                close();
                e.stopPropagation();
              }}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogComponent>
      </>
    );
  },
);
