import { createSelector } from 'reselect';
import { Return } from '~src/utils/finance/return';
import { getHoldingShareById } from '~src/data/store/selectors/holding/filter/selectors';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { Period } from '../../../../../utils/period/period';
import { getHoldingAbsoluteReturns } from './holding-absolute-returns';
import { selectWorkspaceDataFromData } from '~src/data/store/selectors/selectors';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  period: Period;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsReturns = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (state, workspaceId, projectId, holdings, period, filter, useLiveValue) => {
    let returnsPerHolding = holdings.map((holding) => {
      const holdingReturns = getHoldingAbsoluteReturns(
        selectWorkspaceDataFromData(state),
        {
          holdingId: holding.id,
          period,
          useLiveValue,
        },
      );

      const share = getHoldingShareById(state, {
        workspaceId,
        projectId,
        holding,
        filter,
        date: period.end,
      });
      holdingReturns.forEach((holdingReturn) => {
        holdingReturn.value *= share;
      });
      return holdingReturns;
    });

    returnsPerHolding = returnsPerHolding.filter((returnsForHolding) => {
      return returnsForHolding.length > 0;
    });

    let previousValuation = returnsPerHolding.reduce(
      (sum, returnsForHolding) => {
        const currentReturnForHolding = returnsForHolding.shift();
        if (!currentReturnForHolding) {
          return sum;
        }
        return sum + currentReturnForHolding.value;
      },
      0,
    );

    let returns: Return[] = []; // TODO: calculate size
    returnsPerHolding.forEach((value) => {
      returns.push(...value);
    });
    returns = returns.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });

    let i = 0;
    let j = 0;
    while (i < returns.length) {
      let value = returns[i].value;
      const date = returns[i].date;
      while (
        ++i < returns.length &&
        date.getTime() === returns[i].date.getTime()
      ) {
        value += returns[i].value;
      }
      returns[j].value = value;
      returns[j].date = date;
      ++j;
    }
    returns.length = j;
    // if (j < returns.length) {
    //   returns = returns.slice(j);
    // }
    returns = returns.map((value) => {
      value.value = value.value / previousValuation;
      previousValuation = previousValuation + value.value;
      return value;
    });

    return returns;
  },
);
