import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { HoldingFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/field-config';
import { getDividendValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/valuation/dividend-field-config';
import { getDividendTransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/transfer/dividend-field-config';

export const getDividendFieldConfig = (
  holding: HoldingState,
  editAll: boolean,
): HoldingFieldConfig => {
  return {
    transfer: getDividendTransferFieldConfig(holding, editAll),
    valuation: getDividendValuationFieldConfig(holding, editAll),
  };
};
