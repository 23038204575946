import { CapitalCallState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/capital-calls/reducer';
import { PrivateEquityFundCapitalCall } from '~src/services/graphql/workspace/client/graphql';

export function parseCapitalCall(
  fundId: string,
  capitalCall: PrivateEquityFundCapitalCall,
): CapitalCallState {
  return {
    id: capitalCall.id,
    fundId,
    date: capitalCall.date,
    description: capitalCall.description,
    name: capitalCall.name,
    value: capitalCall.value,
  };
}
