import React, { memo } from 'react';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useProjectEdit } from '~src/domain/workspace/components/project/form/use-project-edit';
import { usePortfolioEdit } from '~src/domain/workspace/components/project/portfolio/form/form/use-portfolio-edit';
import { UpdateState } from '~src/utils/common/update-state';
import { useUpdateState } from '~src/utils/common/use-update-state';

import { Typography } from '@mui/material';

import { PortfolioAllocationsComponent } from '../allocations/portfolio-allocations.component';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  portfolio: PortfolioState;
  project: ProjectState;
  workspace: WorkspaceState;
}

export const SettingsComponent = memo((props: Props) => {
  const { portfolio, project, workspace } = props;

  const updateStateData = useUpdateState();

  const onSuccess = React.useCallback(() => {
    updateStateData.setUpdateState(UpdateState.success);
  }, [updateStateData]);

  const onFailure = React.useCallback(
    (_error: any) => {
      updateStateData.setUpdateState(UpdateState.failed);
    },
    [updateStateData],
  );

  const projectElement = useProjectEdit({
    workspace,
    project,
    callbacks: {
      onFailure,
      onSuccess,
    },
  });

  const portfolioElement = usePortfolioEdit({
    workspace,
    portfolio,
    callbacks: {
      onFailure,
      onSuccess,
    },
  });

  return (
    <Grid2 container p={1}>
      <Grid2 xs={6}>
        <Typography variant="overline">Project</Typography>
        {projectElement.fields.nameField}
        <Typography variant="overline">Portfolio</Typography>
        {portfolioElement.fields.nameField}
        {portfolioElement.fields.descriptionField}
      </Grid2>
      {/* <PortfolioComponent
        portfolio={portfolio}
        projectId={project.id}
        workspace={workspace}
      /> */}
      <PortfolioAllocationsComponent
        workspace={workspace}
        projectId={project.id}
        portfolio={portfolio}
      />
    </Grid2>
  );
});
