import { combineReducers } from 'redux';
import {
  initialTenantsState,
  tenantsReducer,
} from '~src/data/store/reducers/tenant/tenants/reducer';

import {
  initialSelectedTenantIdState,
  selectedTenantIdReducer,
} from './selected-tenant/reducer';
import {
  initialTenantRolesState,
  tenantRolesReducer,
} from './tenant-role/tenant-roles/reducer';

export const tenantInitialState = {
  tenants: initialTenantsState,
  selectedTenantId: initialSelectedTenantIdState,
  tenantRoles: initialTenantRolesState,
};

export const tenantReducer = combineReducers({
  tenants: tenantsReducer,
  selectedTenantId: selectedTenantIdReducer,
  tenantRoles: tenantRolesReducer,
});
