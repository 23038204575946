import {
  BondHoldingStateVisitor,
  BondHoldingVisitable,
} from '~src/data/store/visitors/holding/types/bond/bond-holding-visitor';
import { BondHolding } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handleBondHoldings(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  outputs: BondHolding[],
  subscriptions: Operation[],
) {
  const bondHoldingVisitor = new BondHoldingStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );

  outputs.forEach((bondHolding) => {
    const bondHoldingVisitable = new BondHoldingVisitable(bondHolding);
    bondHoldingVisitable.accept(bondHoldingVisitor);
  });
  bondHoldingVisitor.post();
  return outputs;
}
