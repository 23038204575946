import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { HoldingConfigState } from '~src/data/store/reducers/holding/config/configs/reducer';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingDashboardConfigsByHoldingConfigId } from '~src/data/store/selectors/holding/config/dashboard-configs/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useCreateDialog } from '~src/domain/workspace/components/project/holding/config/use-create-dialog';
import { useConfig } from '~src/hooks/actions/workspace/project/holding/config/use-config.hook';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

interface Props {
  handlePersist?: () => void;
  label?: string;
  workspace: WorkspaceState;
  projectId: string;
  holdingConfig: HoldingConfigState;
  dashboardConfig: HoldingDashboardConfigState;
}

export const DashboardConfigInput = (props: Props) => {
  const { workspace, holdingConfig } = props;
  const dashboardConfigs = useSelector((state: RootState) =>
    getHoldingDashboardConfigsByHoldingConfigId(
      selectWorkspaceData(state),
      holdingConfig.id,
    ),
  );
  const getOptionLabel = React.useCallback(
    (t: HoldingDashboardConfigState) => {
      const holding = dashboardConfigs.find((config) => config.id === t.id);
      return holding?.name ? holding.name : '';
    },
    [dashboardConfigs],
  );
  const { update } = useConfig();
  const { dialogElement, setOpen, setInput } = useCreateDialog({
    workspace,
    projectId: props.projectId,
    holdingConfig,
    // getDialogContent,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setInput(uuid.v1(), value);
    },
    [setInput],
  );

  const handleValue = (dashboardConfig: HoldingDashboardConfigState) => {
    const holdingConfigInput: graphqlWorkspaceTypes.UpdateHoldingConfigInputType =
      {
        id: holdingConfig.id,
        currentDashboardConfigId: dashboardConfig.id,
      };
    update(workspace, holdingConfig.holdingId, holdingConfigInput);
  };

  return (
    <EditAutocomplete
      multiple={false}
      addOption={{ initDialog, setOpen, dialogElement }}
      // dialogElement={dialogElement}
      getOptionLabel={getOptionLabel}
      label={props.label}
      handleValue={handleValue}
      // initDialog={initDialog}
      options={dashboardConfigs}
      value={props.dashboardConfig}
      // setOpen={setOpen}
      handlePersist={props.handlePersist}
    />
  );
};
