import {
  PortfolioDashboardConfigStateVisitor,
  PortfolioDashboardConfigVisitable,
} from '~src/data/store/visitors/workspace/project/portfolio/config/portfolio-dashboard-config-visitor';
import { PortfolioDashboardConfig } from '~src/services/graphql/workspace/client/graphql';

import { Operation } from '@pladdenico/portfolio-api';

export function handlePortfolioDashboardConfigs(
  dispatch: any,
  portfolioConfigId: number,
  outputs: PortfolioDashboardConfig[],
  tenantId: string,
  workspaceId: string,
  subscriptions: Operation[],
) {
  const visitor = new PortfolioDashboardConfigStateVisitor(
    dispatch,
    tenantId,
    workspaceId,
    subscriptions,
  );
  const res = outputs.map((config) => {
    const visitable = new PortfolioDashboardConfigVisitable(
      portfolioConfigId,
      config,
    );
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
