/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type CreatePaperInputType = {
  currencyId: Scalars['String'];
  exchange: Scalars['String'];
  id: Scalars['String'];
  isin?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol: Scalars['String'];
};

export type CreateQuoteInputType = {
  close?: InputMaybe<Scalars['Float']>;
  date: Scalars['Date'];
  high?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  low?: InputMaybe<Scalars['Float']>;
  open?: InputMaybe<Scalars['Float']>;
  paperId: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type CreateTradeInputType = {
  buyer?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  initiator?: InputMaybe<Scalars['String']>;
  paperId: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  time: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type DeletePaperInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteQuoteInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteTradeInputType = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createPapers?: Maybe<Array<Paper>>;
  createQuotes?: Maybe<Array<Quote>>;
  createTrades?: Maybe<Array<Trade>>;
  deletePapers?: Maybe<Array<Scalars['String']>>;
  deleteQuotes?: Maybe<Array<Scalars['String']>>;
  deleteTrades?: Maybe<Array<Scalars['String']>>;
  updatePapers?: Maybe<Array<Paper>>;
  updateQuotes?: Maybe<Array<Quote>>;
  updateTrades?: Maybe<Array<Trade>>;
  upsertPapers?: Maybe<Array<Paper>>;
  upsertQuotes?: Maybe<Array<Quote>>;
  upsertTrades?: Maybe<Array<Trade>>;
};

export type MutationCreatePapersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreatePaperInputType>>>;
};

export type MutationCreateQuotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateQuoteInputType>>>;
};

export type MutationCreateTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<CreateTradeInputType>>>;
};

export type MutationDeletePapersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeletePaperInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteQuotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteQuoteInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<DeleteTradeInputType>>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePapersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdatePaperInputType>>>;
};

export type MutationUpdateQuotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateQuoteInputType>>>;
};

export type MutationUpdateTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpdateTradeInputType>>>;
};

export type MutationUpsertPapersArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertPaperInputType>>>;
};

export type MutationUpsertQuotesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertQuoteInputType>>>;
};

export type MutationUpsertTradesArgs = {
  inputs?: InputMaybe<Array<InputMaybe<UpsertTradeInputType>>>;
};

export type Paper = {
  __typename?: 'Paper';
  currency?: Maybe<Currency>;
  exchange?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isin?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Quote>>;
  symbol?: Maybe<Scalars['String']>;
};

export type PaperQuotesArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PaperSymbolInputType = {
  exchange: Scalars['String'];
  symbol: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getPapers?: Maybe<Array<Paper>>;
  getPapersBySymbols?: Maybe<Array<Paper>>;
  getQuotes?: Maybe<Array<Quote>>;
  getTrades?: Maybe<Array<Trade>>;
  hello?: Maybe<Scalars['String']>;
};

export type QueryGetPapersArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type QueryGetPapersBySymbolsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  symbols?: InputMaybe<Array<PaperSymbolInputType>>;
};

export type QueryGetQuotesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  paperId?: InputMaybe<Scalars['String']>;
};

export type QueryGetTradesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Quote = {
  __typename?: 'Quote';
  close?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  high?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  low?: Maybe<Scalars['Float']>;
  open?: Maybe<Scalars['Float']>;
  paper?: Maybe<Paper>;
  value?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

export type Trade = {
  __typename?: 'Trade';
  buyer?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  initiator?: Maybe<Scalars['String']>;
  paper?: Maybe<Paper>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  time: Scalars['Date'];
};

export type UpdatePaperInputType = {
  currencyId?: InputMaybe<Scalars['String']>;
  exchange?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isin?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};

export type UpdateQuoteInputType = {
  close?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['Date']>;
  high?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  low?: InputMaybe<Scalars['Float']>;
  open?: InputMaybe<Scalars['Float']>;
  paperId: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type UpdateTradeInputType = {
  buyer?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  initiator?: InputMaybe<Scalars['String']>;
  paperId: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['String']>;
};

export type UpsertPaperInputType = {
  currencyId?: InputMaybe<Scalars['String']>;
  exchange?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isin?: InputMaybe<Scalars['String']>;
  market?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};

export type UpsertQuoteInputType = {
  close?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['Date']>;
  high?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  low?: InputMaybe<Scalars['Float']>;
  open?: InputMaybe<Scalars['Float']>;
  paperId: Scalars['String'];
  value?: InputMaybe<Scalars['Float']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type UpsertTradeInputType = {
  buyer?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  initiator?: InputMaybe<Scalars['String']>;
  paperId: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['String']>;
};

export type PaperFragment = {
  __typename?: 'Paper';
  id: string;
  exchange?: string | null;
  symbol?: string | null;
  name?: string | null;
  market?: string | null;
  isin?: string | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
};

export type PaperWithQuotesFragment = {
  __typename?: 'Paper';
  id: string;
  exchange?: string | null;
  symbol?: string | null;
  name?: string | null;
  market?: string | null;
  isin?: string | null;
  currency?: { __typename?: 'Currency'; id: string } | null;
  quotes?: Array<{
    __typename?: 'Quote';
    id: string;
    date: any;
    open?: number | null;
    high?: number | null;
    low?: number | null;
    close?: number | null;
    volume?: number | null;
    value?: number | null;
  }> | null;
};

export type GetPapersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type GetPapersQuery = {
  __typename?: 'Query';
  getPapers?: Array<{
    __typename?: 'Paper';
    id: string;
    exchange?: string | null;
    symbol?: string | null;
    name?: string | null;
    market?: string | null;
    isin?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type GetPapersWithQuotesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type GetPapersWithQuotesQuery = {
  __typename?: 'Query';
  getPapers?: Array<{
    __typename?: 'Paper';
    id: string;
    exchange?: string | null;
    symbol?: string | null;
    name?: string | null;
    market?: string | null;
    isin?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    quotes?: Array<{
      __typename?: 'Quote';
      id: string;
      date: any;
      open?: number | null;
      high?: number | null;
      low?: number | null;
      close?: number | null;
      volume?: number | null;
      value?: number | null;
    }> | null;
  }> | null;
};

export type GetPapersBySymbolsQueryVariables = Exact<{
  symbols?: InputMaybe<Array<PaperSymbolInputType> | PaperSymbolInputType>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type GetPapersBySymbolsQuery = {
  __typename?: 'Query';
  getPapersBySymbols?: Array<{
    __typename?: 'Paper';
    id: string;
    exchange?: string | null;
    symbol?: string | null;
    name?: string | null;
    market?: string | null;
    isin?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
    quotes?: Array<{
      __typename?: 'Quote';
      id: string;
      date: any;
      open?: number | null;
      high?: number | null;
      low?: number | null;
      close?: number | null;
      volume?: number | null;
      value?: number | null;
    }> | null;
  }> | null;
};

export type CreatePapersMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreatePaperInputType>> | InputMaybe<CreatePaperInputType>
  >;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type CreatePapersMutation = {
  __typename?: 'Mutation';
  createPapers?: Array<{
    __typename?: 'Paper';
    id: string;
    exchange?: string | null;
    symbol?: string | null;
    name?: string | null;
    market?: string | null;
    isin?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpdatePapersMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdatePaperInputType>> | InputMaybe<UpdatePaperInputType>
  >;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type UpdatePapersMutation = {
  __typename?: 'Mutation';
  updatePapers?: Array<{
    __typename?: 'Paper';
    id: string;
    exchange?: string | null;
    symbol?: string | null;
    name?: string | null;
    market?: string | null;
    isin?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type UpsertPapersMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertPaperInputType>> | InputMaybe<UpsertPaperInputType>
  >;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type UpsertPapersMutation = {
  __typename?: 'Mutation';
  upsertPapers?: Array<{
    __typename?: 'Paper';
    id: string;
    exchange?: string | null;
    symbol?: string | null;
    name?: string | null;
    market?: string | null;
    isin?: string | null;
    currency?: { __typename?: 'Currency'; id: string } | null;
  }> | null;
};

export type DeletePapersMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeletePaperInputType>> | InputMaybe<DeletePaperInputType>
  >;
}>;

export type DeletePapersMutation = {
  __typename?: 'Mutation';
  deletePapers?: Array<string> | null;
};

export type QuoteFragment = {
  __typename?: 'Quote';
  id: string;
  date: any;
  open?: number | null;
  high?: number | null;
  low?: number | null;
  close?: number | null;
  volume?: number | null;
  value?: number | null;
};

export type GetQuotesQueryVariables = Exact<{
  paperId: Scalars['String'];
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetQuotesQuery = {
  __typename?: 'Query';
  getQuotes?: Array<{
    __typename?: 'Quote';
    id: string;
    date: any;
    open?: number | null;
    high?: number | null;
    low?: number | null;
    close?: number | null;
    volume?: number | null;
    value?: number | null;
  }> | null;
};

export type CreateQuotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateQuoteInputType>> | InputMaybe<CreateQuoteInputType>
  >;
}>;

export type CreateQuotesMutation = {
  __typename?: 'Mutation';
  createQuotes?: Array<{
    __typename?: 'Quote';
    id: string;
    date: any;
    open?: number | null;
    high?: number | null;
    low?: number | null;
    close?: number | null;
    volume?: number | null;
    value?: number | null;
  }> | null;
};

export type UpdateQuotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateQuoteInputType>> | InputMaybe<UpdateQuoteInputType>
  >;
}>;

export type UpdateQuotesMutation = {
  __typename?: 'Mutation';
  updateQuotes?: Array<{
    __typename?: 'Quote';
    id: string;
    date: any;
    open?: number | null;
    high?: number | null;
    low?: number | null;
    close?: number | null;
    volume?: number | null;
    value?: number | null;
  }> | null;
};

export type UpsertQuotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertQuoteInputType>> | InputMaybe<UpsertQuoteInputType>
  >;
}>;

export type UpsertQuotesMutation = {
  __typename?: 'Mutation';
  upsertQuotes?: Array<{
    __typename?: 'Quote';
    id: string;
    date: any;
    open?: number | null;
    high?: number | null;
    low?: number | null;
    close?: number | null;
    volume?: number | null;
    value?: number | null;
  }> | null;
};

export type DeleteQuotesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteQuoteInputType>> | InputMaybe<DeleteQuoteInputType>
  >;
}>;

export type DeleteQuotesMutation = {
  __typename?: 'Mutation';
  deleteQuotes?: Array<string> | null;
};

export type TradeFragment = {
  __typename?: 'Trade';
  id: string;
  time: any;
  price?: number | null;
  quantity?: number | null;
  source?: string | null;
  buyer?: string | null;
  seller?: string | null;
  initiator?: string | null;
};

export type GetTradesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetTradesQuery = {
  __typename?: 'Query';
  getTrades?: Array<{
    __typename?: 'Trade';
    id: string;
    time: any;
    price?: number | null;
    quantity?: number | null;
    source?: string | null;
    buyer?: string | null;
    seller?: string | null;
    initiator?: string | null;
  }> | null;
};

export type CreateTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<CreateTradeInputType>> | InputMaybe<CreateTradeInputType>
  >;
}>;

export type CreateTradesMutation = {
  __typename?: 'Mutation';
  createTrades?: Array<{
    __typename?: 'Trade';
    id: string;
    time: any;
    price?: number | null;
    quantity?: number | null;
    source?: string | null;
    buyer?: string | null;
    seller?: string | null;
    initiator?: string | null;
  }> | null;
};

export type UpdateTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpdateTradeInputType>> | InputMaybe<UpdateTradeInputType>
  >;
}>;

export type UpdateTradesMutation = {
  __typename?: 'Mutation';
  updateTrades?: Array<{
    __typename?: 'Trade';
    id: string;
    time: any;
    price?: number | null;
    quantity?: number | null;
    source?: string | null;
    buyer?: string | null;
    seller?: string | null;
    initiator?: string | null;
  }> | null;
};

export type UpsertTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<UpsertTradeInputType>> | InputMaybe<UpsertTradeInputType>
  >;
}>;

export type UpsertTradesMutation = {
  __typename?: 'Mutation';
  upsertTrades?: Array<{
    __typename?: 'Trade';
    id: string;
    time: any;
    price?: number | null;
    quantity?: number | null;
    source?: string | null;
    buyer?: string | null;
    seller?: string | null;
    initiator?: string | null;
  }> | null;
};

export type DeleteTradesMutationVariables = Exact<{
  inputs?: InputMaybe<
    Array<InputMaybe<DeleteTradeInputType>> | InputMaybe<DeleteTradeInputType>
  >;
}>;

export type DeleteTradesMutation = {
  __typename?: 'Mutation';
  deleteTrades?: Array<string> | null;
};

export const PaperFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaperFragment, unknown>;
export const QuoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuoteFragment, unknown>;
export const PaperWithQuotesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperWithQuotes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaperWithQuotesFragment, unknown>;
export const TradeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Trade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Trade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TradeFragment, unknown>;
export const GetPapersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPapers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPapers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paper' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPapersQuery, GetPapersQueryVariables>;
export const GetPapersWithQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPapersWithQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPapers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperWithQuotes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperWithQuotes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPapersWithQuotesQuery,
  GetPapersWithQuotesQueryVariables
>;
export const GetPapersBySymbolsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPapersBySymbols' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'symbols' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PaperSymbolInputType' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPapersBySymbols' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'symbols' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'symbols' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PaperWithQuotes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaperWithQuotes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPapersBySymbolsQuery,
  GetPapersBySymbolsQueryVariables
>;
export const CreatePapersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPapers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePaperInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPapers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paper' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePapersMutation,
  CreatePapersMutationVariables
>;
export const UpdatePapersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePapers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePaperInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePapers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paper' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePapersMutation,
  UpdatePapersMutationVariables
>;
export const UpsertPapersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertPapers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertPaperInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPapers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Paper' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Paper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Paper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exchange' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'market' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPapersMutation,
  UpsertPapersMutationVariables
>;
export const DeletePapersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePapers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePaperInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePapers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePapersMutation,
  DeletePapersMutationVariables
>;
export const GetQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paperId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paperId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paperId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetQuotesQuery, GetQuotesQueryVariables>;
export const CreateQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateQuoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateQuotesMutation,
  CreateQuotesMutationVariables
>;
export const UpdateQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateQuoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateQuotesMutation,
  UpdateQuotesMutationVariables
>;
export const UpsertQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertQuoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Quote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Quote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'open' } },
          { kind: 'Field', name: { kind: 'Name', value: 'high' } },
          { kind: 'Field', name: { kind: 'Name', value: 'low' } },
          { kind: 'Field', name: { kind: 'Name', value: 'close' } },
          { kind: 'Field', name: { kind: 'Name', value: 'volume' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertQuotesMutation,
  UpsertQuotesMutationVariables
>;
export const DeleteQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteQuoteInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteQuotesMutation,
  DeleteQuotesMutationVariables
>;
export const GetTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Trade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Trade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Trade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTradesQuery, GetTradesQueryVariables>;
export const CreateTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Trade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Trade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Trade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTradesMutation,
  CreateTradesMutationVariables
>;
export const UpdateTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Trade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Trade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Trade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTradesMutation,
  UpdateTradesMutationVariables
>;
export const UpsertTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Trade' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Trade' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Trade' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiator' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertTradesMutation,
  UpsertTradesMutationVariables
>;
export const DeleteTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteTradeInputType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTradesMutation,
  DeleteTradesMutationVariables
>;
