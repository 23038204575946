import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateLoans } from '~src/data/store/modules/assets/loans/requests';
import { LoanState } from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { InputLoanData } from '~src/domain/workspace/components/asset/types/loans/form/loan-input.data';
import { useLoanFields } from '~src/domain/workspace/components/asset/types/loans/form/use-loan-fields';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  loan: LoanState;
  inputData?: InputLoanData;
  callbacks?: {
    onSuccess?: (loans: LoanState[]) => void;
    onFailure?: (error: any) => void;
  };
}

export const useEditLoanForm = (props: Props) => {
  const { loan, workspace, inputData, callbacks } = props;
  const dispatch = useDispatch<AppDispatch>();

  const onSave = React.useCallback(
    (data: InputLoanData) => {
      return dispatch(
        updateLoans(workspace.tenantId, workspace.id, [
          {
            id: loan.id,
            assetId: data.assetId,
            description: data.description,
            interest: data.interest,
          },
        ]),
      )
        .then((loans) => callbacks?.onSuccess && callbacks.onSuccess(loans))
        .catch((err) => callbacks?.onFailure && callbacks.onFailure(err));
    },
    [dispatch, workspace.tenantId, workspace.id, loan.id, callbacks],
  );

  const { control, handleSubmit, watch } = useForm<InputLoanData>({
    defaultValues: {
      ...inputData,
    },
  });

  const fields = useLoanFields(control);

  const onSubmit: SubmitHandler<InputLoanData> = React.useCallback(
    (data) => {
      return onSave(data);
    },
    [onSave],
  );

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return { fields };
};
