import { std } from 'mathjs';
import { SMALast } from '@pladdenico/common';
import { createSelector } from 'reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';

import { Filter } from '../../../../../utils/common/filter';
import { Period } from '../../../../../utils/period/period';
import { getHoldingsReturns } from '../returns/holdings-returns';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  period: Period;
  filter: Filter;
  useLiveValue: boolean;
}

export const getHoldingsSTDDEV = createSelector(
  (state: DataState) => state,
  (_state: DataState, props: Props) => props.workspaceId,
  (_state: DataState, props: Props) => props.projectId,
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.filter,
  (_state: DataState, props: Props) => props.useLiveValue,
  (state, workspaceId, projectId, holdings, period, filter, useLiveValue) => {
    const returns = getHoldingsReturns(state, {
      workspaceId,
      projectId,
      holdings,
      period,
      filter,
      useLiveValue,
    });

    const values = returns.map((value) => {
      return value.value;
    });
    const times = returns.map((value) => {
      return value.date.getTime();
    });

    const tau = ((24 * 60 * 60 * 1000 * 365) / 12) * 3; // 3 months
    const res = SMALast(values, times, tau);
    // console.log(res);

    if (res.length === 0) {
      return 0;
    }

    const stddev = std(res, 'unbiased') as number;
    return stddev;
  },
);
