import { EditTransactionField } from '~src/components/fields/workspace/transaction/edit-transaction-field';
import { ViewTransactionField } from '~src/components/fields/workspace/transaction/view-transaction-field';
import { TransactionState } from '~src/data/store/reducers/holding/transaction/transactions/reducer';

import { TableField } from '@pladdenico/table';

export function useTransactionField<T>(
  projectId: string,
  getTransactionId: (data: T) => string,
  update: (data: T, transaction: TransactionState) => T,
): TableField.Field<T> {
  return {
    id: 'transaction',
    name: 'transaction',
    field: 'transaction',
    type: TableField.FieldTypes.string,
    iteratee: (data) => getTransactionId(data),
    update: (data, transaction: TransactionState) => {
      return update(data, transaction);
    },
    renderView: (editProps) => {
      return (
        <ViewTransactionField
          projectId={projectId}
          transactionId={getTransactionId(editProps.data)}
        />
      );
    },
    renderEdit: (editProps) => {
      return (
        <EditTransactionField
          projectId={projectId}
          handleValue={editProps.onChange}
          transactionId={getTransactionId(editProps.data)}
          labelled={false}
        />
      );
    },
  };
}
