import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { BankNoteHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bank-note/bank-note-holding-card.container';
import { BondHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/bond/bond-holding-card.container';
import { CryptoHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/crypto/crypto-holding-card.container';
import { FundHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/fund/fund-holding-card.container';
import { LoanHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/loan/loan-holding-card.container';
import { StockHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/stock/stock-holding-card.container';
import { WarrantHoldingCardContainer } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/warrant/warrant-holding-card.container';

import { HoldingType } from '@pladdenico/models';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState;
  portfolios: PortfolioState[];
}

export const HoldingCardComponent = React.memo((props: Props) => {
  const { holding, portfolios } = props;
  let holdingElement = undefined;
  if (holding.type === HoldingType.Stock) {
    holdingElement = (
      <StockHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        portfolios={props.portfolios}
        holding={holding}
      />
    );
  } else if (holding.type === HoldingType.Loan) {
    holdingElement = (
      <LoanHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        holding={holding}
        portfolios={portfolios}
      />
    );
  } else if (holding.type === HoldingType.Warrant) {
    holdingElement = (
      <WarrantHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        holding={holding}
        portfolios={portfolios}
      />
    );
  } else if (holding.type === HoldingType.BankNote) {
    holdingElement = (
      <BankNoteHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        holding={holding}
        portfolios={portfolios}
      />
    );
  } else if (holding.type === HoldingType.Crypto) {
    holdingElement = (
      <CryptoHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        holding={holding}
        portfolios={portfolios}
      />
    );
  } else if (holding.type === HoldingType.Bond) {
    holdingElement = (
      <BondHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        holding={holding}
        portfolios={portfolios}
      />
    );
  } else if (holding.type === HoldingType.Fund) {
    holdingElement = (
      <FundHoldingCardContainer
        workspace={props.workspace}
        projectId={props.projectId}
        holding={holding}
        portfolios={portfolios}
      />
    );
  } else {
    holdingElement = <div>Holding of type {holding.type} needs card</div>;
  }

  return holdingElement;
});
