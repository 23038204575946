import { strcmp, RecordState } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

export interface BaseCurrencyId {
  id: string;
}

export type CurrencyState = graphqlWorkspaceTypes.Currency & {
  symbol: string;
  country: string;
};

export const initialState: RecordState<CurrencyState, string>[] = [];

const selectKeyId = (currency: BaseCurrencyId) => currency.id;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseCurrencyId, b: BaseCurrencyId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseCurrencyId, CurrencyState, string>(
  initialState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const currenciesSlice = createBaseRecordSlice('currencies', adapter);
export const currenciesSelectors = adapter.selectors;

export const currenciesReducer = currenciesSlice.reducer;
export const currenciesActions = currenciesSlice.actions;
