import numbro from 'numbro';
import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingById } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  FormContext,
  FormValues,
} from '~src/domain/workspace/components/project/transaction/form/create-form.component';
import { ValuationFieldConfig } from '~src/domain/workspace/components/project/transaction/form/fields/valuation-field-config';
import { useCreateValuationFieldsComponent } from '~src/domain/workspace/components/project/valuation/fields/use-fields.component';

import {
  Alert,
  AlertColor,
  colors,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    // maxWidth: 500,
  },
  row: {
    display: 'flex',
    // alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  subPartElement: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.blue[100],
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface Props extends React.PropsWithChildren<unknown> {
  workspace: WorkspaceState;
  projectId: string;
  holdingId: string;
  control: Control<FormValues, FormContext>;
  index: number;
  fieldConfig?: ValuationFieldConfig;
}

export const ValuationSubFormComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const { holdingId, workspace, projectId, control, index, fieldConfig } =
    props;
  const holding = useSelector((root: RootState) => {
    return getHoldingById(selectWorkspaceData(root), {
      id: holdingId,
      projectId,
    });
  });
  const { dateElement, valueElement, currencyElement } =
    useCreateValuationFieldsComponent(workspace, projectId, false, false);

  const valuationId = useWatch({
    control,
    name: `holdings.${index}.valuationInputData.id`,
  });

  const expectedResultingValue = useWatch({
    control,
    name: `holdings.${index}.expectedResultingValue`,
  });

  const value = useWatch({
    control,
    name: `holdings.${index}.valuationInputData.value`,
  });

  let alertColor: AlertColor = 'warning';
  let alertText;
  if (expectedResultingValue != null) {
    const diff = expectedResultingValue - (value ?? 0);
    if (value == null || Math.abs(diff) > 0.01) {
      alertColor = 'warning';
      const valueText = numbro(expectedResultingValue).format({
        thousandSeparated: true,
      });
      alertText = `Expected value is ${valueText}`;
    } else {
      alertColor = 'success';
      alertText = 'Value as expected';
    }
  }

  const expectedValueElement = (
    <Alert variant="outlined" severity={alertColor}>
      {alertText}
    </Alert>
  );
  const valuationElement = holding ? (
    <div className={classes.subPartElement}>
      <div className={classes.row}>
        <Typography variant="body1" className={classes.title}>
          Valuation
        </Typography>
        <span className={classes.spacer} />
        <Typography variant="caption" className={classes.title}>
          {valuationId}
        </Typography>
      </div>
      <Grid container columnSpacing={1}>
        {expectedResultingValue != null && (
          <Grid item xs={12}>
            {expectedValueElement}{' '}
          </Grid>
        )}
        <Grid item xs={4}>
          <Controller
            name={`holdings.${index}.valuationInputData.date`}
            rules={{ required: true }}
            control={control}
            defaultValue={new Date()}
            render={({ field, fieldState }) =>
              dateElement(
                field,
                fieldState,
                fieldConfig?.base.date?.disabled ?? false,
              )
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={`holdings.${index}.valuationInputData.currencyId`}
            rules={{ required: true }}
            control={control}
            defaultValue={null}
            render={({ field, fieldState }) =>
              currencyElement(field, fieldState)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={`holdings.${index}.valuationInputData.value`}
            rules={{ required: true }}
            control={control}
            defaultValue={0}
            render={({ field, fieldState }) =>
              valueElement(
                field,
                fieldState,
                fieldConfig?.base.value?.disabled ?? false,
              )
            }
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <></>
  );

  return valuationElement;
});
