export const defaultAbsoluteBackgroundColor = '#FFFFFF';
export const defaultAbsoluteFontColor = '#000000';

export const defaultDiffBackgroundColor = '#FFFFFF';
export const defaultDiffFontColor = '#000000';

// export const defaultAbsoluteBackgroundColor = '#A2A6DE';
// export const defaultAbsoluteFontColor = '#735E6D';

// export const defaultDiffBackgroundColor = '#C19ED4';
// export const defaultDiffFontColor = '#735E6D';
