import React from 'react';
import { useSelector } from 'react-redux';
import { EditWorkspaceRoleField } from '~src/components/fields/workspace/workspace-role/edit-workspace-role-field';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceRoleState } from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  getWorkspaceRoleById,
  getWorkspaceRolesByWorkspaceId,
} from '~src/data/store/selectors/workspace/workspace-roles/selectors';

import { TextField, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { WorkspaceInvitationInputForForm } from './create-user-dialog.component';

interface Props {
  workspaceInvitationInput: WorkspaceInvitationInputForForm;
  setWorkspaceInvitationInput: (
    workspaceInvitationInput: WorkspaceInvitationInputForForm,
  ) => void;
  workspace: WorkspaceState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 300,
    // flexBasis: 200,
  },

  dense: {
    marginTop: theme.spacing(2),
  },

  menu: {
    width: 200,
  },
}));

export const CreateWorkspaceInvitationFormComponent = React.memo(
  (props: Props) => {
    const { classes } = useStyles();
    const workspaceRoles = useSelector((state: RootState) =>
      getWorkspaceRolesByWorkspaceId(
        selectWorkspaceData(state),
        props.workspace.id,
      ),
    );
    const workspaceRole = useSelector((state: RootState) =>
      getWorkspaceRoleById(
        selectWorkspaceData(state),
        props.workspaceInvitationInput.workspaceRoleId,
      ),
    );

    const handleChange =
      (name: keyof WorkspaceInvitationInputForForm) => (value: any) => {
        props.setWorkspaceInvitationInput({
          ...props.workspaceInvitationInput,
          [name]: value,
        });
      };

    const handleEventChange =
      (name: keyof WorkspaceInvitationInputForForm) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(name)(event.target.value);
      };

    const handleWorkspaceRole = (workspaceRole: WorkspaceRoleState) => {
      handleChange('workspaceRoleId')(workspaceRole.id);
    };

    return (
      <form className={classes.container} noValidate autoComplete="off">
        <EditWorkspaceRoleField
          handleValue={handleWorkspaceRole}
          labelled={true}
          workspaceRole={workspaceRole}
          workspaceRoles={workspaceRoles}
        />
        <TextField
          margin="normal"
          className={classes.textField}
          required
          id="email"
          value={props.workspaceInvitationInput.email}
          label="Email"
          type="text"
          onChange={handleEventChange('email')}
        />
      </form>
    );
  },
);
