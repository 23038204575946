import React from 'react';
import { ConfigButtonComponent } from '~src/domain/workspace/components/project/types/portfolio-project/config-button.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((_theme: Theme) => ({
  configContainer: {
    position: 'relative',
    display: 'flex',
  },
}));

export const useConfiguration = () => {
  const { classes } = useStyles();

  const { isShowing: configOpen, toggle: toggleConfig } = useVisible();

  const configuration = React.useMemo(
    () => (
      <div className={classes.configContainer}>
        <Box sx={{ mt: 1, ml: 1 }}>
          <ConfigButtonComponent
            isVisible={configOpen}
            toggleConfig={toggleConfig}
            text="configuration"
            variant="contained"
          />
        </Box>
      </div>
    ),
    [classes.configContainer, configOpen, toggleConfig],
  );
  return { configuration, configOpen, toggleConfig };
};
