import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectReportById } from '~src/data/store/selectors/workspace/projects/report/project-report/selectors';
import {
  projectReportBasePath,
  projectReportsBasePath,
} from '~src/navigation/paths/workspace/project/portfolio-project/report/project-report/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { useProjectBreadcrumbs } from './project-breadcrumb';
import { LinkRouter, maxItems } from './breadcrumbs.component';

function useProjectReportBreadcrumb(_last: boolean) {
  const { tenantId, workspaceId, projectId, reportId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    reportId: string;
  }>();

  const projectReportsBreadcrumbs = useProjectReportsBreadcrumb(false);
  const projectReport = useSelector((state: RootState) => {
    if (reportId != null && projectId != null) {
      return getProjectReportById(selectWorkspaceData(state), {
        id: reportId,
        projectId,
      });
    }
  });
  if (projectReport && tenantId != null && workspaceId != null) {
    return [
      projectReportsBreadcrumbs,
      <Typography
        color="inherit"
        key={projectReportBasePath(
          tenantId,
          workspaceId,
          projectReport.projectId,
          projectReport.id,
        )}
      >
        {projectReport.name}
      </Typography>,
    ];
  }
  return [];
}
function useProjectReportsBreadcrumb(last: boolean) {
  const { tenantId, workspaceId, projectId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
  }>();
  const projectBreadcrumbs = useProjectBreadcrumbs(false);
  if (tenantId != null && workspaceId != null && projectId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        key={projectReportsBasePath(tenantId, workspaceId, projectId)}
      >
        reporting
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={projectReportsBasePath(tenantId, workspaceId, projectId)}
        to={projectReportsBasePath(tenantId, workspaceId, projectId)}
      >
        reporting
      </LinkRouter>
    );
    return [...projectBreadcrumbs, projectElement];
  }
  return <></>;
}
export function ProjectReportBreadcrumbComponent() {
  const projectReportBreadcrumbs = useProjectReportBreadcrumb(true);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {projectReportBreadcrumbs}
    </Breadcrumbs>
  );
}
export function ProjectReportsBreadcrumbComponent(props: { last: boolean }) {
  const projectReportsBreadcrumbs = useProjectReportsBreadcrumb(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {projectReportsBreadcrumbs}
    </Breadcrumbs>
  );
}
