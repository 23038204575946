import numbro from 'numbro';
import * as React from 'react';

interface Props {
  type?: string;
  payload?: any[];
  label?: string;
  active?: boolean;
  style: React.CSSProperties;
}

export class IrrTooltipComponent extends React.Component<Props, any> {
  public render() {
    const { active, style } = this.props;

    if (active) {
      const { payload } = this.props;
      if (payload && payload.length) {
        return (
          <div className="custom-tooltip" style={style}>
            <p className="label">{`${payload[0].payload.name}`}</p>
            <p className="label">{`IRR: ${numbro(payload[0].value).format({
              output: 'percent',
              thousandSeparated: true,
              mantissa: 2,
              totalLength: 3,
            })}`}</p>
          </div>
        );
      }
    }

    return null;
  }
}
