import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useHandler } from '~src/hooks/utils/use-handler';

import { FormControl, MenuItem, Select, TextField, Theme } from '@mui/material';
import { ScenarioVariableInput } from '~src/domain/workspace/components/project/scenario/holdings/forecast/object-type/variable/variable-element-input';

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    // marginLeft: theme.spacing(1),
  },
}));

export const useScenarioVariableFieldsComponent = (
  input: ScenarioVariableInput,
  setInput: (variable: ScenarioVariableInput) => void,
) => {
  const { classes } = useStyles();

  const { handleEvent } = useHandler(input, setInput);
  const nameElement = React.useMemo(
    () => (
      <TextField
        autoFocus
        margin="normal"
        className={classes.textField}
        required
        id="name"
        value={input.name}
        label="Name"
        type="text"
        onChange={handleEvent('name')}
      />
    ),
    [classes.textField, handleEvent, input.name],
  );
  const descriptionElement = React.useMemo(
    () => (
      <TextField
        margin="normal"
        className={classes.textField}
        required
        id="description"
        value={input.description}
        label="Description"
        type="text"
        onChange={handleEvent('description')}
      />
    ),
    [classes.textField, handleEvent, input.description],
  );

  const typeItems = React.useMemo(() => {
    return [
      <MenuItem key="number" value="number">
        Number
      </MenuItem>,
      <MenuItem key="string" value="string">
        String
      </MenuItem>,
    ];
  }, []);

  const valueElement = React.useMemo(
    () => (
      <FormControl fullWidth margin="normal">
        Type
        <Select
          // defaultValue="number"
          label="type"
          value={input.type}
          onChange={handleEvent('type')}
        >
          {typeItems}
        </Select>
      </FormControl>
    ),
    [handleEvent, input.type, typeItems],
  );

  return {
    nameElement,
    descriptionElement,
    valueElement,
  };
};
