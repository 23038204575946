import moment from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { ExchangeRateState } from '~src/data/store/reducers/finance/exchange-rates/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import { transferCashSign } from '~src/utils/finance/transfer-cash-sign';
import { Period } from '~src/utils/period/period';

import { TransferType } from '@pladdenico/models';

import { getExchangeRateState } from '../exchange-rates/selectors';

interface Props {
  holding: HoldingState;
  currency: CurrencyState;
  date: moment.Moment;
  period: Period;
  useLiveValue: boolean;
}

export const getHoldingTransferCashOnCash = createCachedSelector(
  (_state: DataState, props: Props) => props.holding,
  (_state: DataState, props: Props) => props.currency,
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.period,
  (_state: DataState, props: Props) => props.useLiveValue,
  // (_state: DataState, holding: HoldingState) => holding,
  // (
  //   _state: DataState,
  //   _holding: HoldingState,
  //   currency: Currency,
  //   _date: moment.Moment,
  //   _period: Period
  // ) => currency,
  // (
  //   _state: DataState,
  //   _holding: HoldingState,
  //   _currency: Currency,
  //   date: moment.Moment,
  //   _period: Period
  // ) => date,
  // (
  //   _state: DataState,
  //   _holding: HoldingState,
  //   _currency: Currency,
  //   _date: moment.Moment,
  //   period: Period
  // ) => period,
  // (
  //   _state: DataState,
  //   _holding: HoldingState,
  //   _currency: Currency,
  //   _date: moment.Moment,
  //   _period: Period,
  //   useLiveValue: boolean
  // ) => useLiveValue,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  // (state: DataState, holding: HoldingState) =>
  // getTransfersByHoldingId(state, holding.id),
  (state: DataState, props: Props) =>
    getTransfersByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (
    holding,
    currency,
    date,
    period,
    _useLiveValue,
    exchangeRateState,
    transfers,
  ) => {
    return calculateTransferCashOnCash(
      date,
      holding,
      transfers,
      period,
      exchangeRateState,
      currency,
    );
  },
)(
  {
    keySelector: (_state: DataState, props: Props) =>
      `${props.holding.id}:${
        props.currency.id
      }:${props.date.unix()}:${props.period.id()}`,
    selectorCreator: createSelector,
  },
  // holding: HoldingState,
  // currency: Currency,
  // date: moment.Moment,
  // period: Period,
  // _useLiveValue: boolean
  // ) => `${holding.id}:${currency.id}:${date.unix()}:${period.id()}`
);

function calculateTransferCashOnCash(
  date: moment.Moment,
  holding: HoldingState,
  transfers: TransferState[],
  period: Period,
  exchangeRateState: ExchangeRateState,
  currency: CurrencyState,
) {
  return transfers.reduce<{
    inflow: number;
    outflow: number;
  }>(
    (value, transfer) => {
      const transferTime = transfer.date.getTime();
      if (
        period.start.toDate().getTime() <= transferTime &&
        transferTime <= period.end.toDate().getTime()
      ) {
        const sign = transfer.type
          ? transferCashSign(transfer.type as TransferType)
          : 1;
        if (transfer.value !== undefined && transfer.value !== null) {
          if (sign > 0) {
            value.inflow +=
              // share *
              transfer.value *
              convert(
                exchangeRateState,
                transfer.currencyId,
                currency.id,
                date.toDate(),
              );
          } else if (sign < 0) {
            value.outflow +=
              // share *
              transfer.value *
              convert(
                exchangeRateState,
                transfer.currencyId,
                currency.id,
                date.toDate(),
              );
          }
        }
      }
      return value;
    },
    { inflow: 0, outflow: 0 },
  );
}
