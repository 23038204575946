import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { TransferState } from '~src/data/store/reducers/holding/transfer/transfers/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsByProjectId } from '~src/data/store/selectors/holding/holdings/selectors';
import { getTransfersByHoldingIds } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { TransfersToolbar } from '~src/domain/workspace/components/project/transfer/transfers-toolbar.component';
import { useTransferActions } from '~src/hooks/actions/workspace/project/holding/transfer/use-transfer-actions';
import { useTransferFields } from '~src/hooks/fields/workspace/project/holding/use-transfer-fields';
import { Identity } from '~src/utils/interfaces/identity';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export interface TransferData extends Identity {
  transfer: TransferState;
}

function transfersToData(transfers: TransferState[]) {
  return transfers.map((transfer) => {
    return {
      id: transfer.id,
      transfer,
      share: 1.0,
    };
  });
}

export const ProjectTransfersComponent = React.memo((props: Props) => {
  const holdings = useSelector((state: RootState) =>
    getHoldingsByProjectId(selectWorkspaceData(state), props.projectId),
  );
  const holdingIds = React.useMemo(() => holdings.map((h) => h.id), [holdings]);
  const transfers = useSelector((state: RootState) =>
    getTransfersByHoldingIds(selectWorkspaceData(state), holdingIds),
  );
  const { classes } = useStyles();
  const [data, setData] = React.useState<TransferData[]>(() =>
    transfersToData(transfers),
  );
  const { onRowUpdate, onRowDelete } = useTransferActions(props.workspace);
  React.useEffect(() => {
    setData(transfersToData(transfers));
  }, [transfers]);

  const fields = useTransferFields(
    props.workspace.tenantId,
    props.workspace,
    props.projectId,
  );

  // const transferCreateInputInit: TransferBaseData = {
  //   id: uuid.v1(),
  //   currencyId: '',
  //   date: new Date(),
  //   // holding: undefined,
  //   transactionId: '',
  //   holdingId: '',
  //   // holdingType: HoldingType.Stock,
  //   // holding: undefined,
  //   cost: 0,
  //   description: '',
  //   subtype: '',
  //   type: TransferType.Buy,
  //   status: TransferStatus.confirmed,
  //   value: 0,
  // };

  const [initialSorters] = React.useState<TableField.Sorter<TransferData>[]>(
    () => {
      return compact([
        TableOperations.createSorterFromFields(fields, 'date', 'desc'),
      ]);
    },
  );

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  return (
    <div className={classes.root}>
      <TransfersToolbar
        workspace={props.workspace}
        projectId={props.projectId}
        // transferCreateInputInit={transferCreateInputInit}
      />
      <div className={classes.content}>
        <TableComponent<TransferData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
});
