import {
  QuoteStateVisitor,
  QuoteVisitable,
} from '~src/data/store/visitors/finance/quote-visitor';
import { Operation } from '@pladdenico/portfolio-api';
import * as graphqlFinanceTypes from '~src/services/graphql/finance/client/graphql';

export function handleQuotes(
  paperId: string,
  dispatch: any,
  outputs: graphqlFinanceTypes.Quote[],
  subscriptions: Operation[],
) {
  const quoteVisitor = new QuoteStateVisitor(dispatch, paperId, subscriptions);
  const quotes = outputs.map((quote) => {
    const visitable = new QuoteVisitable(paperId, quote);
    return visitable.accept(quoteVisitor);
  });
  quoteVisitor.post();
  return quotes;
}
