import { Dag, dagConnect } from 'd3-dag';
import { Edge, Node } from 'reactflow';
import { NodeType } from './nodes/node-type';
import {
  InitialNodeData,
  InputNodeData,
  NodeData,
  OutputNodeData,
  VariableNodeData,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import {
  ExpressionHandler,
  initMath,
} from '~src/domain/workspace/components/project/scenario/models/forecast/expression-handler';
import { ConnectDatum } from 'd3-dag/dist/dag/create';
// import { Value } from '~src/domain/workspace/components/project/scenario/models/forecast/value';
// import { TransferBase } from '~src/domain/workspace/components/project/scenario/models/transfer';
// import { ValuationBase } from '~src/domain/workspace/components/project/scenario/models/valuation';

export class FlowEvaluator {
  private _dag: Dag<ConnectDatum, [string, string]> | undefined;
  constructor(
    private _edges: Edge[],
    private _nodes: Node<NodeData, NodeType>[],
  ) {}

  public prepare() {
    if (this._edges.length > 0) {
      const create = dagConnect();
      this._dag = create(
        this._edges.map<[string, string]>((e) => [e.source, e.target]),
      );
    }
  }

  private getNibor = () => {
    return 0.03;
  };

  public evaluate<ValueIn>(
    valuesIn: ValueIn[],
    _date: moment.Moment,
    idx: number,
    // variables: VariableState[] | undefined,
    getVariableValue?: (variableId: string, idx: number) => any | undefined,
  ): any | undefined {
    // let transfer: TransferBase | undefined;
    // let valuation: ValuationBase | undefined;

    const valueOut: any = {};

    // const niborId = 'niborId';
    // const transferKey = 'transfer';
    // const valuationKey = 'valuation';
    // const variablesValues = new Map<string, number[]>();
    // will be calculate separately per scenario run (or not)
    // variablesValues.set(niborId, [0.02, 0.03, 0.04, 0.05]);
    // const stateValues = new Map<string, unknown[]>();
    // initialize empty state to initial values of transfer and valuation
    // const initial = { valuation: { value: 1221 }, stock: { position: 11 } };
    // stateValues.set(transferKey, []);
    // stateValues.set(valuationKey, []);

    if (this._dag != null) {
      const variableOut = new Map<string, any>();
      for (const node of this._dag.idescendants('before')) {
        const flowNode = this._nodes.find((n) => n.id === node.data.id);
        if (flowNode) {
          if (flowNode.type === 'inputType') {
            const data = flowNode.data as InputNodeData;
            if (getVariableValue != null && data.variableId) {
              // const variable = variables.find((v) => v.id === data.variableId);
              // if (variable != null && getVariableValue) {
              // const value = this.getNibor();
              const value = getVariableValue(data.variableId, idx);
              variableOut.set(data.label, value);
              // }
            }
          } else if (flowNode.type === 'variable') {
            const data = flowNode.data as VariableNodeData;
            const expressions = data.evaluator?.expressions;
            if (expressions) {
              const expressionHandler = new ExpressionHandler(
                initMath(),
                data.label,
                data.label,
                expressions,
                [],
              );
              const value = expressionHandler.evaluate(
                Object.fromEntries(variableOut),
              );
              variableOut.set(data.label, value);
            }
          } else if (flowNode.type === 'initial') {
            const data = flowNode.data as InitialNodeData;
            const expressions = data.evaluator?.expressions;
            if (expressions) {
              const expressionHandler = new ExpressionHandler(
                initMath(),
                data.label,
                data.label,
                expressions,
                [],
              );
              const initialization = JSON.parse(data.initialization);
              const value = expressionHandler.evaluate({
                // initial,
                idx,
                values: valuesIn,
                ...initialization,
              });
              variableOut.set(data.label, value);
            }
          } else if (flowNode.type === 'output') {
            const data = flowNode.data as OutputNodeData;
            const value = Object.fromEntries(variableOut);

            valueOut[data.label] = value[data.name][data.label];
          }
        }
      }
    }

    return valueOut;
  }
}
