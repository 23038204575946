import { Control } from 'react-hook-form';
import { TenantInputData } from '~src/domain/tenant/components/form/tenant-input-data';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useTenantFields = (control: Control<TenantInputData, any>) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  return {
    nameField,
  };
};
