import { Location } from 'history';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { userLoginPath } from '../../../../navigation/paths/user/paths';
import { SignupFieldsComponent } from './signup-fields.component';
import { useCreateUser } from './use-signup-user.hook';

const useStyles = makeStyles()((_theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  grow: {
    flexGrow: 1,
  },

  paper: {
    padding: '3%',
  },

  login: {
    float: 'right',
  },
}));

function useRedirectUrlFromLocation(location: Location<any>) {
  return location.state ? location.state.redirectUrl : null;
}

export const SignupComponent = React.memo(() => {
  const { classes } = useStyles();
  const location = useLocation();
  const redirectUrl = useRedirectUrlFromLocation(location);
  const { userInput, setUserInput, signupError, setSignupError, saveUser } =
    useCreateUser(redirectUrl);

  // const handleCreateUser = (_event: any): void => {
  //   dispatch(signup(userInput.name, userInput.password, (_user?: User, err?: any) => {
  //     if (err) {
  //       setSignupError(err);
  //       setSignupError(err);
  //     } else {
  //       setSignupError(undefined);
  //       history.push(userLoginPath);
  //     }
  //   }));
  // };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.grow}
    >
      <Paper className={classes.paper}>
        <Typography variant="h5" display="inline">
          Sign up
        </Typography>
        <Typography
          variant="caption"
          color="inherit"
          noWrap
          display="inline"
          className={classes.login}
        >
          {/* <Link to={{ pathname: userLoginPath, state: { redirectUrl } }}> */}
          <Link to={userLoginPath} state={redirectUrl}>
            or login
          </Link>
        </Typography>
        <form className={classes.container} noValidate autoComplete="off">
          <SignupFieldsComponent
            userInput={userInput}
            setUserInput={setUserInput}
            signupError={signupError}
            setSignupError={setSignupError}
          />
        </form>
        <Button variant="contained" onClick={saveUser} color="primary">
          Save
        </Button>
      </Paper>
    </Grid>
  );
});
