import moment from 'moment';
import { DataState } from '~src/data/store/reducers/reducers';

import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getExchangeRateState } from '~src/data/store/selectors/finance/exchange-rates/selectors';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';
import { convert } from '~src/utils/finance/currency-converter';
import createCachedSelector from 're-reselect';
import { makeCreateSpecificSelector } from '~src/utils/selector/equality-checker';
import { getTransfersByCommitmentIds } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { transferSign } from '~src/utils/finance/transfer-sign';

interface Props {
  commitmentIds: string[];
  date: moment.Moment;
  currency: CurrencyState;
}

export const getHoldingsCommitmentPainIn = createCachedSelector(
  (state: DataState, props: Props) =>
    getTransfersByCommitmentIds(
      selectWorkspaceDataFromData(state),
      props.commitmentIds,
    ),
  (_state: DataState, props: Props) => props.date,
  (_state: DataState, props: Props) => props.currency,
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (transfers, date, currency, exchangeRates) => {
    return transfers.reduce((prev, curr) => {
      if (curr && curr.value) {
        const exchangeRate = convert(
          exchangeRates,
          curr.currencyId,
          currency.id,
          date.toDate(),
        );
        prev += curr.value * exchangeRate * -transferSign(curr.type);
      }
      return prev;
    }, 0);
  },
)({
  keySelector: (_state: DataState, props: Props) => {
    const id = `${props.commitmentIds.join(';')}:${props.date.unix()}:${
      props.currency.id
    }`;
    return id;
  },
  selectorCreator: makeCreateSpecificSelector(),
});
