import { uniqWith } from 'lodash';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import {
  getAssetByHoldingId,
  getAssetsByHoldingIds,
} from '~src/data/store/selectors/asset/assets/selectors';

import { RecordState } from '@pladdenico/common';

import {
  entitiesSelectors,
  EntityState,
  BaseEntityId,
} from '../../../reducers/entity/entities/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';
import { createSelector } from 'reselect';

export const selectEntities = (state: WorkspaceDataState) => {
  return state.holding.entity.entities;
};

export const getEntitiesByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState, _workspaceId: string | null) =>
    selectEntities(state),
  (_state: WorkspaceDataState, workspaceId: string | null) => workspaceId,
  (entitiesState, workspaceId) => {
    if (!workspaceId) {
      return [];
    }
    const entities = entitiesSelectors.selectElementsByKey(
      entitiesState,
      workspaceId,
    );
    return entities ? entities : [];
  },
)({
  keySelector: (_state, entityId) => `${entityId}`,
  selectorCreator: createSelector,
});

export const selectEntityById = createCachedSelector(
  (
    entities: RecordState<EntityState, string>[],
    _baseId: Partial<BaseEntityId>,
  ) => entities,
  (
    _entities: RecordState<EntityState, string>[],
    baseId: Partial<BaseEntityId>,
  ) => baseId.workspaceId,
  (
    _entities: RecordState<EntityState, string>[],
    baseId: Partial<BaseEntityId>,
  ) => baseId.id,
  (entities, workspaceId, entityId) => {
    if (workspaceId && entityId) {
      return entitiesSelectors.selectElementByT(entities, {
        id: entityId,
        workspaceId,
      });
    }
    return undefined;
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.id}`,
  selectorCreator: createSelector,
});

export const selectEntitiesByIds = createCachedSelector(
  (entities: RecordState<EntityState, string>[], _ids: BaseEntityId[]) =>
    entities,
  (_entities: RecordState<EntityState, string>[], _ids: BaseEntityId[]) => _ids,
  (entities, ids) => {
    return entitiesSelectors.selectElementsByTs(entities, ids);
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getEntitiesByIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: BaseEntityId[]) => selectEntities(state),
  (_state: WorkspaceDataState, ids: BaseEntityId[]) => ids,
  (entities, ids) => {
    return entitiesSelectors.selectElementsByTs(entities, ids);
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

export const getEntityById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseEntityId>) =>
    selectEntities(state),
  (state: WorkspaceDataState, baseId: Partial<BaseEntityId>) => baseId,
  (entities, baseId) => {
    return selectEntityById(entities, baseId);
  },
)({
  keySelector: (_state, baseId) => `${baseId.workspaceId}-${baseId.id}`,
  selectorCreator: createSelector,
});

interface BaseEntityWithHoldingId {
  workspaceId: string;
  projectId: string;
  holdingId: string;
}

export const getEntityByHoldingId = createCachedSelector(
  (state: WorkspaceDataState, _baseId: BaseEntityWithHoldingId) =>
    selectEntities(state),
  (state: WorkspaceDataState, baseId: BaseEntityWithHoldingId) =>
    getAssetByHoldingId(state, baseId),
  (entities, asset) => {
    if (!asset) {
      return undefined;
    }
    return selectEntityById(entities, {
      id: asset.entityId,
      workspaceId: asset.workspaceId,
    });
  },
)({
  keySelector: (_state, baseId) =>
    `${baseId.workspaceId}-${baseId.projectId}-${baseId.holdingId}`,
  selectorCreator: createSelector,
});

export const getEntitiesByHoldingIds = createCachedSelector(
  (state: WorkspaceDataState, _ids: BaseEntityWithHoldingId[]) =>
    selectEntities(state),
  (state: WorkspaceDataState, ids: BaseEntityWithHoldingId[]) =>
    getAssetsByHoldingIds(state, ids),
  (entities, assets) => {
    const ids = assets.map((asset) => {
      return {
        id: asset.entityId,
        workspaceId: asset.workspaceId,
      };
    });
    const uniqueIds = uniqWith(ids, (a, b) => {
      return a.id === b.id && a.workspaceId === b.workspaceId;
    });
    return selectEntitiesByIds(entities, uniqueIds);
  },
)({
  keySelector: (_state, ids) => ids,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});
