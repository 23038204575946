import * as uuid from 'uuid';
import { Api } from '~src/services/request/api';
import { Subscription } from '~src/services/request/subscription';

import { Logger, LogLevel } from '@pladdenico/common';
import { User } from '@pladdenico/models';

export interface ParetoOptimalInput {
  H: number[];
  lower_bounds: number[];
  upper_bounds: number[];
  expected_returns: number[];
  target_return: number;
}

export interface ParetoOptimalOutput {
  expectedReturn: number;
  volatility: number;
  weights: number[];
}

export interface ParetoEfficientFrontierInput {
  H: number[];
  lower_bounds: number[];
  upper_bounds: number[];
  expected_returns: number[];
  target_return_minimum: number;
  target_return_maximum: number;
  num_steps: number;
}

export interface ParetoEfficientFrontierItem {
  target_return: number;
  expected_return: number;
  volatility: number;
  weights: number[];
}

export interface ParetoEfficientFrontierOutput {
  weights: ParetoEfficientFrontierItem[];
}

export class PortfolioOptimizationApi {
  private _id: string;
  private _logger: Logger;
  private _api: Api;

  constructor() {
    this._id = uuid.v1();
    this._logger = new Logger('PortfolioOptimizationApi', LogLevel.DEBUG);
    this._api = new Api('auth/portfoliooptimization');
  }

  public connect(user: User, token: string) {
    this._logger.debug('CONNECT');
    this._api.connect(user, token);
  }

  public subscribe(subscription: Subscription, callback: () => void) {
    this._api.subscribe(subscription, callback);
  }

  public unsubscribe(subscription: Subscription) {
    this._api.unsubscribe(subscription);
  }

  public executeParetoOptimalQuery(
    input: ParetoOptimalInput,
    callback: (result: ParetoOptimalOutput) => void,
  ) {
    this._api.executeQuery('paretoOptimal', input, callback);
  }

  public executeParetoEfficientFrontierQuery(
    input: ParetoEfficientFrontierInput,
    callback: (result: ParetoEfficientFrontierOutput) => void,
  ) {
    this._api.executeQuery('paretoOptimal', input, callback);
  }
}
