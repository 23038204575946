import {
  clearRecord,
  clearRecords,
  getElementByT,
  getElementsByTs,
  getRecordByKey,
  getRecordsByKeys,
  RecordState,
  removeElement,
  removeElements,
  mergeElement,
  mergeElements,
  mergeRecord,
  uniqSorted,
  findSorted,
  intersectionSorted,
} from '@pladdenico/common';

export interface EntityRecordSelectors<S, B extends I, T extends B, K, I = B> {
  selectElementsByKey: (state: S[], key: K) => T[] | undefined;
  selectElementsByKeys: (state: S[], key: K[]) => T[] | undefined;

  selectRecordByKey: (state: S[], key: K) => S | undefined;
  selectRecordsByKeys: (state: S[], keys: K[]) => S[];

  selectAllRecords: (state: S[]) => S[];
  selectAllElements: (state: S[]) => T[];

  selectElementByT: (state: S[], t: B) => T | undefined;
  selectElementsByTs: (state: S[], t: B[]) => T[];

  selectElementInRecordById: (record: T[], t: I) => T | undefined;
  selectElementsInRecordByIds: (record: T[], ts: I[]) => T[];
}

export class EntityRecordAdapter<B extends I, T extends B, K, I = B> {
  private _initialState: RecordState<T, K>[];
  private _selectKey: (t: B) => K;
  private _keyComparator: (a: K, b: K) => number;
  private _tComparator: (a: I, b: I) => number;
  private _selectors: EntityRecordSelectors<RecordState<T, K>, B, T, K, I>;
  private _ensureUniqueness: boolean;
  constructor(
    initialState: RecordState<T, K>[],
    selectKey: (t: B) => K,
    keyComparator: (a: K, b: K) => number,
    tComparator: (a: I, b: I) => number,
    ensureUniqueness = true,
  ) {
    this._initialState = initialState;
    this._selectKey = selectKey;
    this._keyComparator = keyComparator;
    this._tComparator = tComparator;
    this._selectors = {
      selectAllRecords: this.selectAllRecords,
      selectAllElements: this.selectAllElements,
      selectElementsByKey: this.selectElementsByKey,
      selectElementsByKeys: this.selectElementsByKeys,
      selectRecordByKey: this.selectRecordByKey,
      selectRecordsByKeys: this.selectRecordsByKeys,
      selectElementByT: this.selectElementByT,
      selectElementsByTs: this.selectElementsByTs,
      selectElementInRecordById: this.selectElementInRecordById,
      selectElementsInRecordByIds: this.selectElementsInRecordByIds,
    };
    this._ensureUniqueness = ensureUniqueness;
  }

  get initialState() {
    return this._initialState;
  }

  get selectKey() {
    return this._selectKey;
  }

  get keyComparator() {
    return this._keyComparator;
  }

  get tComparator() {
    return this._tComparator;
  }

  get selectors(): EntityRecordSelectors<RecordState<T, K>, B, T, K, I> {
    return this._selectors;
  }

  private _combinedComparator = (a: B, b: B) => {
    const comp = this._keyComparator(this._selectKey(a), this._selectKey(b));
    if (comp === 0) {
      return this._tComparator(a, b);
    }
    return comp;
  };

  private _sortElements = (ts: B[]) => {
    ts.sort(this._combinedComparator);
  };

  private _sortIs = (ts: I[]) => {
    ts.sort(this._tComparator);
  };

  private _uniqSorted = (ts: T[]) => {
    return uniqSorted(ts, [this._combinedComparator]);
  };

  private selectAllRecords = (state: RecordState<T, K>[]) => {
    return state;
  };

  private selectAllElements = (state: RecordState<T, K>[]) => {
    return state
      .reduce<T[]>((prev, curr) => {
        return [...prev, ...curr.data];
      }, [])
      .sort(this._tComparator);
  };

  private selectElementsByKey = (state: RecordState<T, K>[], key: K) => {
    const record = getRecordByKey(state, key, this._keyComparator);
    return record ? record.data : undefined;
  };

  private selectRecordByKey = (state: RecordState<T, K>[], key: K) => {
    return getRecordByKey(state, key, this._keyComparator);
  };

  private selectRecordsByKeys = (state: RecordState<T, K>[], keys: K[]) => {
    keys = keys.sort(this._keyComparator);
    return getRecordsByKeys(state, keys, this._keyComparator);
  };

  private selectElementsByKeys = (state: RecordState<T, K>[], keys: K[]) => {
    const records = getRecordsByKeys(state, keys, this._keyComparator);
    return records
      .reduce<T[]>((prev, curr) => {
        return [...prev, ...curr.data];
      }, [])
      .sort(this._tComparator);
  };

  private selectElementByT = (state: RecordState<T, K>[], t: B) => {
    return getElementByT(
      state,
      t,
      this._selectKey,
      this._keyComparator,
      this._tComparator,
    );
  };

  private selectElementsByTs = (state: RecordState<T, K>[], ts: B[]) => {
    this._sortElements(ts);
    return getElementsByTs(
      state,
      ts,
      this._selectKey,
      this._keyComparator,
      this._tComparator,
    );
  };

  private selectElementInRecordById = (record: T[], t: I) => {
    return findSorted(record, t, this._tComparator).item;
  };

  private selectElementsInRecordByIds = (record: T[], ts: I[]) => {
    this._sortIs(ts);
    return intersectionSorted(record, ts, [this._tComparator], (a, _b) => a);
  };

  public upsertOneElementReducer = () => {
    return (state: RecordState<T, K>[], action: { payload: T }) => {
      const t = action.payload;
      const res = [...state];
      return mergeElement(
        res,
        t,
        this._selectKey,
        this._keyComparator,
        this._tComparator,
      );
    };
  };

  public upsertManyElementsReducer = () => {
    return (state: RecordState<T, K>[], action: { payload: T[] }) => {
      let ts = action.payload;
      this._sortElements(ts);
      if (this._ensureUniqueness) {
        ts = this._uniqSorted(ts);
      }

      const res = [...state];
      return mergeElements(
        res,
        ts,
        this._selectKey,
        this._keyComparator,
        this._tComparator,
      );
    };
  };

  public removeOneElementReducer = () => {
    return (state: RecordState<T, K>[], action: { payload: B }) => {
      const res = [...state];
      const t = action.payload;
      return removeElement(
        res,
        t,
        this._selectKey,
        this._keyComparator,
        this._tComparator,
      );
    };
  };

  public removeManyElementsReducer = () => {
    return (state: RecordState<T, K>[], action: { payload: B[] }) => {
      const res = [...state];
      const ts = action.payload;
      this._sortElements(ts);
      return removeElements(
        res,
        ts,
        this._selectKey,
        this._keyComparator,
        this._tComparator,
      );
    };
  };

  public upsertRecordReducer = () => {
    return (
      state: RecordState<T, K>[],
      action: { payload: RecordState<T, K> },
    ) => {
      // return [...action.payload];
      const record = action.payload;
      this._sortElements(record.data);
      if (this._ensureUniqueness) {
        record.data = this._uniqSorted(record.data);
      }
      return mergeRecord(
        state,
        record,
        this._keyComparator,
        this._tComparator,
        true,
      ).items;
    };
  };

  public clearRecordReducer = () => {
    return (state: RecordState<T, K>[], action: { payload: K }) => {
      // return [...action.payload];
      const key = action.payload;
      return clearRecord(state, key, this._keyComparator).items;
    };
  };

  public clearRecordsReducer = () => {
    return (state: RecordState<T, K>[], action: { payload: K[] }) => {
      // return [...action.payload];
      const keys = action.payload;
      return clearRecords(state, keys, this._keyComparator);
    };
  };

  public clearReducer = () => {
    return (_state: RecordState<T, K>[], _action: { payload: undefined }) => {
      return [];
    };
  };
}
