import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workpacePeopleRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/people`;
};
export const personRootPath = (
  tenantId: string,
  workspaceId: string,
  personId: string,
) => {
  return `${workpacePeopleRootPath(tenantId, workspaceId)}/${personId}`;
};

export const workpacePeopleBasePath = workpacePeopleRootPath(
  tenantBaseId,
  workspaceBaseId,
);
