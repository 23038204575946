import { DashboardMediaState } from '~src/data/store/reducers/common/dashboard/media/reducer';
import { DashboardMedia } from '~src/services/graphql/workspace/client/graphql';

export function parseDashboardMedia(
  media: DashboardMedia,
  elementId: string | null,
): DashboardMediaState {
  return {
    id: media.id,
    elementId, // : media.element ? media.element.id : null,
    fileId: media.file?.id ?? null,
  };
}
