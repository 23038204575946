import { handleBondHoldings } from '~src/data/store/modules/holdings/bond/handler';
import { bondHoldingsActions } from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchBondHoldings(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetBondHoldingsDocument;
    const variables: graphqlWorkspaceTypes.GetBondHoldingsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getBondHoldings) {
        return handleBondHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.getBondHoldings,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createBondHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateBondHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.CreateBondHoldingsDocument;
    const variables: graphqlWorkspaceTypes.CreateBondHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createBondHoldings) {
        return handleBondHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.createBondHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateBondHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateBondHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpdateBondHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpdateBondHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateBondHoldings) {
        return handleBondHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.updateBondHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertBondHoldings(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertBondHoldingInputType[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.UpsertBondHoldingsDocument;
    const variables: graphqlWorkspaceTypes.UpsertBondHoldingsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertBondHoldings) {
        return handleBondHoldings(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertBondHoldings,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deleteBondHoldings(
  tenantId: string,
  workspaceId: string,
  holdingId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.DeleteBondHoldingsDocument;
    const variables: graphqlWorkspaceTypes.DeleteBondHoldingsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteBondHoldings) {
        dispatch(
          bondHoldingsActions.removeManyElements(
            data.deleteBondHoldings.map((p) => {
              return {
                holdingId,
                id: p,
              };
            }),
          ),
        );
      }
      return [];
    });
  };
}
