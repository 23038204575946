import { LocalStorage } from '../storage/local-storage';

export const mapStorage = new LocalStorage('map:');

// export class Maps {
//   private static storage =
//     typeof localStorage === 'undefined' ? null : localStorage;

//   private static toStorageMapId(id: string) {
//     return `map-${id}`;
//   }

//   public static setMap(id: string, map: any) {
//     if (this.storage) {
//       this.storage.setItem(Maps.toStorageMapId(id), JSON.stringify(map));
//     }
//   }

//   public static unsetMap(id: string) {
//     if (this.storage) {
//       this.storage.removeItem(Maps.toStorageMapId(id));
//     }
//   }

//   public static getMap(id: string): any {
//     if (this.storage) {
//       const map = this.storage.getItem(Maps.toStorageMapId(id));
//       if (map) {
//         const a = JSON.parse(map);
//         return a;
//       }
//     }
//     return undefined;
//   }
// }
