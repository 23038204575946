import { OrganizationAddress } from '@pladdenico/models';
import * as React from 'react';

import { Typography } from '@mui/material';

interface Props {
  address: OrganizationAddress;
}

export const OrganizationAddressComponent = React.memo((props: Props) => {
  const baseAddress = props.address.address.join(' ');
  return (
    <div>
      <Typography variant="body2">
        {baseAddress}, {props.address.postCode}, {props.address.postLocation},{' '}
        {props.address.country}
      </Typography>
    </div>
  );
});
