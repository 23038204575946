import * as React from 'react';
import { useSelector } from 'react-redux';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCommitmentsByHoldingIds } from '~src/data/store/selectors/holding/commitment/commitments/selectors';
import {
  selectData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';

import { Box, Typography } from '@mui/material';
import { getHoldingsTotalCommitment } from '~src/data/store/selectors/finance/valuation/commitment/holdings-total-commitment';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getHoldingsCommitmentPainIn } from '~src/data/store/selectors/finance/valuation/commitment/holdings-commitment-paid-in';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import numbro from 'numbro';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holdings: HoldingState[];
  date: moment.Moment;
  currency: CurrencyState;
}

export const CommitmentsSummaryComponent = React.memo((props: Props) => {
  const { holdings, date, currency } = props;
  const holdingIds = React.useMemo(() => holdings.map((h) => h.id), [holdings]);
  const commitments = useSelector((state: RootState) =>
    getCommitmentsByHoldingIds(selectWorkspaceData(state), holdingIds),
  );
  const commitmentIds = React.useMemo(() => {
    return commitments.map((h) => h.id);
  }, [commitments]);
  const paidInCommited = useSelector((state: RootState) =>
    getHoldingsCommitmentPainIn(selectData(state), {
      commitmentIds,
      currency,
      date,
    }),
  );
  const totalCommitment = useSelector((state: RootState) =>
    getHoldingsTotalCommitment(selectData(state), {
      holdingIds,
      date,
      currency: currency,
    }),
  );
  const undrawnCommitment = totalCommitment - paidInCommited;

  return (
    <Box p={1}>
      <Typography
        variant="subtitle1"
        textTransform="uppercase"
        textAlign="center"
      >
        commitment
      </Typography>
      <Grid container>
        <Grid>
          <Typography variant="body2">Total ({currency.symbol})</Typography>
        </Grid>
        <Grid xsOffset="auto">
          <Typography variant="body2">
            {numbro(totalCommitment).format({
              thousandSeparated: true,
              // mantissa: 1,
              totalLength: 5,
              average: true,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid>
          <Typography variant="body2">Paid in ({currency.symbol})</Typography>
        </Grid>
        <Grid xsOffset="auto">
          <Typography variant="body2">
            {numbro(paidInCommited).format({
              thousandSeparated: true,
              // mantissa: 1,
              totalLength: 5,
              average: true,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid>
          <Typography variant="body2">Undrawn ({currency.symbol})</Typography>
        </Grid>
        <Grid xsOffset="auto">
          <Typography variant="body2">
            {numbro(undrawnCommitment).format({
              thousandSeparated: true,
              // mantissa: 1,
              totalLength: 5,
              average: true,
            })}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
});
