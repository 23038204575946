import { upsertBondHoldings } from '~src/data/store/modules/holdings/bond/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'bondHoldings';

export interface BondHolding {
  id: string;
  holdingId: string;
  bondId: string;
}

const header: Array<keyof BondHolding> = ['id', 'holdingId', 'bondId'];

export const bondHoldingExportHandler = (
  data: BondHolding[],
): ExportHandler<BondHolding> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const bondHoldingImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<BondHolding> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertBondHoldings(workspace.tenantId, workspace.id, ts)),
  };
};
