import { combineReducers } from 'redux';
import {
  initialTransfersState,
  transfersReducer,
} from '~src/data/store/reducers/holding/transfer/transfers/reducer';

export const transferInitialState = {
  transfers: initialTransfersState,
};

export const transferReducer = combineReducers({
  transfers: transfersReducer,
});
