import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BaseInvestorId,
  investorsSelectors,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investors/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getInvestors = (state: WorkspaceDataState) => {
  return state.holding.asset.privateEquityFund.investors;
};

export const getInvestorsByFundId = createCachedSelector(
  (state: WorkspaceDataState) => getInvestors(state),
  (_state: WorkspaceDataState, fundId: string) => fundId,
  (investorsState, fundId) => {
    const privateEquityFunds = investorsSelectors.selectElementsByKey(
      investorsState,
      fundId,
    );
    return privateEquityFunds ? privateEquityFunds : [];
  },
)({
  keySelector: (_state, privateEquityFundId) => privateEquityFundId,
  selectorCreator: createSelector,
});

// export const privateEquityFundByIdSelector = (privateEquityFunds: IInvestor[], privateEquityFundId: string) => {
//   return __OLDsortedFind(privateEquityFunds, { id: privateEquityFundId } as IInvestor, (a) => a.id);
// };

export const getInvestorById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BaseInvestorId>) =>
    getInvestors(state),
  (_state: WorkspaceDataState, baseId: Partial<BaseInvestorId>) =>
    baseId.fundId,
  (_state: WorkspaceDataState, baseId: Partial<BaseInvestorId>) => baseId.id,
  (investors, fundId, privateEquityFundId) => {
    if (!fundId || !privateEquityFundId) {
      return undefined;
    }
    return investorsSelectors.selectElementByT(investors, {
      fundId,
      id: privateEquityFundId,
    });
  },
)({
  keySelector: (_state, fundId, privateEquityFundId) =>
    `${fundId}-${privateEquityFundId}`,
  selectorCreator: createSelector,
});
