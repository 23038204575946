import { tenantBaseId } from '~src/navigation/paths/tenant/paths';
import {
  workspaceBaseId,
  workspaceRootPath,
} from '~src/navigation/paths/workspace/paths';

export const workspaceAssetsRootPath = (
  tenantId: string,
  workspaceId: string,
) => {
  return `${workspaceRootPath(tenantId, workspaceId)}/assets`;
};
export const workspaceAssetRootPath = (
  tenantId: string,
  workspaceId: string,
  assetId: string,
) => {
  return `${workspaceAssetsRootPath(tenantId, workspaceId)}/${assetId}`;
};

export const workspaceAssetsBasePath = workspaceAssetsRootPath(
  tenantBaseId,
  workspaceBaseId,
);
