import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { ProjectReportState } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import { ProjectReport } from '~src/services/graphql/workspace/client/graphql';

export function parseProjectReport(
  projectReport: ProjectReport,
): ProjectReportState {
  console.log('projectReport', projectReport);
  return {
    id: projectReport.id,
    name: projectReport.name,
    description: projectReport.description,
    periodStart: new Date(projectReport.periodStart),
    periodEnd: new Date(projectReport.periodEnd),
    published: projectReport.published,
    compactType: projectReport.compactType as CompactType,
    projectId: projectReport.project?.id ?? '',
    elementIds: projectReport.elements
      ? projectReport.elements.map((el) => el.id)
      : [],
    layouts: projectReport.layouts
      ? (JSON.parse(projectReport.layouts) as ReactGridLayout.Layouts)
      : undefined,
  };
}
