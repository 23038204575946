import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { createEmails } from '~src/data/store/modules/people/emails/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { EmailState } from '~src/data/store/reducers/person/emails/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { EmailDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
}

export const CreateEmailDialogComponent = React.memo((props: Props) => {
  const [emailInput, setEmailInput] =
    React.useState<graphqlWorkspaceTypes.CreateEmailInputType>({
      // id: uuid.v1(),
      id: '',
      value: '',
      personId: props.person.id,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSaveEmail = (): Promise<EmailState> => {
    const email = {
      ...emailInput,
      id: uuid.v1(),
    };
    return dispatch(
      createEmails(props.tenantId, props.workspace.id, props.person.id, [
        email,
      ]),
    )
      .then((emails) => {
        return emails[0];
      })
      .catch((err) => {
        console.info('FAILED', emailInput, JSON.stringify(err));
        throw err;
      });
  };

  const savedCallback = () => {
    return;
  };

  return (
    <EmailDialogComponent
      title={'Create email'}
      handleSaveEmail={handleSaveEmail}
      emailInput={emailInput}
      setEmailInput={setEmailInput}
      handleClose={props.handleClose}
      savedCallback={savedCallback}
      open={props.open}
    />
  );
});
