import {
  tenantBaseId,
  tenantRootPath,
} from '~src/navigation/paths/tenant/paths';

export const tenantMembersRootPath = (tenantId: string) => {
  return `${tenantRootPath(tenantId)}/members`;
};

export const tenantMembersBasePath = tenantMembersRootPath(tenantBaseId);
