import { FundPositionState } from '~src/data/store/reducers/holding/holding-types/fund/fund-position/reducer';
import { FundPosition } from '~src/services/graphql/workspace/client/graphql';

export function parseFundPosition(
  fundPosition: FundPosition,
): FundPositionState {
  return {
    id: fundPosition.id,
    fundHoldingId: fundPosition.fundHolding?.id ?? '',
    date: new Date(fundPosition.date),
    shares: fundPosition.shares ?? 0,
  };
}
