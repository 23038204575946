import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import {
  selectTenantData,
  selectUserData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
// import { createGroups } from '~src/data/store/modules/groups';
import { getWorkspaceById } from '~src/data/store/selectors/workspace/workspaces/selectors';
import { GroupFormComponent } from '~src/domain/workspace/components/group/group-form.component';

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import {
  Group,
  GroupCreateInput,
  MultipleRelationOperationType,
} from '@pladdenico/models';

export const SetupGroupsComponent = memo(() => {
  const user = useSelector(
    (state: RootState) => selectUserData(state).account.user,
  );
  const [groupCreateInputs, setGroupCreateInputs] = useState<
    GroupCreateInput[]
  >([]);
  const [addGroupErrors, setAddGroupErrors] = useState<any>([]);
  const [addedGroups, setAddedGroups] = useState<Group[]>([]);
  const workspace = useSelector((state: RootState) =>
    getWorkspaceById(selectWorkspaceData(state), {
      id: selectWorkspaceData(state).workspace.selectedWorkspaceId,
      tenantId: selectTenantData(state).tenant.selectedTenantId,
    }),
  );
  const currentWorkspaceRoleId = useSelector(
    (state: RootState) =>
      selectWorkspaceData(state).workspace.workspaceRole.selectedWorkspaceRole,
  );
  const groups = useSelector(
    (state: RootState) => selectUserData(state).group.groups,
  );

  // const removeGroup = (_event: any, data: any) => {
  //   const groupId = data.value;
  //   const group = props.selectedWorkspace && props.selectedWorkspace.groups.find((group) => group.id === groupId);
  //   if (group) {
  //     props.deleteGroup && props.deleteGroup(props.selectedWorkspace, groupId, (groupId: number, error: any) => {
  //       console.log(groupId, error);
  //       setRemoveGroupErrors(error);
  //       setRemovedGroups([...removedGroups, group]);
  //     });
  //   }
  // }

  // const removeGroup = (_event: any, data: any) => {
  //   const groupId = data.value;
  //   // TODO : use index?!?!?!?!
  //   // const role = props.selectedWorkspace && props.selectedWorkspace.roles.find((role) => role.id === roleId);
  //   const group = groups.find((group) => group.id === groupId);
  //   if (workspace && group) {
  //     dispatch(deleteGroup(workspace, group.id,
  //       (workspaceRoleId?: number, error?: any) => {
  //         console.log(workspaceRoleId, error);
  //         // setRemoveRoleErrors(error);
  //         // setRemovedRoles([...removedRoles, group]);
  //       }));
  //   }
  // };

  const existingGroupsElement = groups.map((group, index) => {
    // const removeButton = (
    //   <Button icon={true} onClick={removeGroup} value={group.id}>
    //     <Icon name="minus" />
    //   </Button>
    // );
    const groupString = `group: ${JSON.stringify(group)}`;

    return (
      <ListItem key={index}>
        {groupString}
        {/* <Popup
          hoverable={true}
          trigger={<ListItemText style={{width: 100}}>{groupString}</ListItemText>}
          content={removeButton}
          basic={true}/> */}
      </ListItem>
    );
  });
  const existingGroupsListElement = <List>{existingGroupsElement}</List>;

  const handleDismissFailedToAddGroup = (index: number) => {
    // const index = data.index;
    setAddGroupErrors([
      ...addGroupErrors.slice(0, index),
      ...addGroupErrors.slice(index + 1),
    ]);
  };

  const handleDismissAddedGroups = (_event: any) => {
    setAddedGroups([]);
  };

  const handleRemoveGroupToAdd = (index: number) => {
    // const index = data.index;
    setGroupCreateInputs([
      ...groupCreateInputs.slice(0, index),
      ...groupCreateInputs.slice(index + 1),
    ]);
  };

  const updateGroup = (group: GroupCreateInput, index: number) => {
    setGroupCreateInputs([
      ...groupCreateInputs.slice(0, index),
      group,
      ...groupCreateInputs.slice(index + 1),
    ]);
  };

  const persistGroups = () => {
    if (
      groupCreateInputs.length === 0 ||
      !workspace ||
      !currentWorkspaceRoleId
    ) {
      return;
    }
    // dispatch(
    //   createGroups(
    //     workspace.id,
    //     currentWorkspaceRoleId,
    //     groupCreateInputs,
    //     defaultGroupPropertiesConfig(),
    //     (groups?: Group[], errors?: any) => {
    //       console.log(groups, errors);

    //       if (!groups) {
    //         setAddGroupErrors(errors);
    //         setAddedGroups([]);
    //         setGroupCreateInputs(groupCreateInputs);
    //         // this.setState({addGroupErrors: errors, addedGroups: [], groups: groupsToAdd});
    //         return;
    //       }

    //       const groupsNotAdded = groupCreateInputs.filter((group) => {
    //         const added = groups.some((addedGroup) => {
    //           return addedGroup.name === group.name;
    //         });
    //         if (!added) {
    //           return true;
    //         }
    //         const hasError = errors
    //           ? errors.some((error: any) => {
    //               if (!error.group) {
    //                 return true;
    //               }
    //               return error.group.name === group.name;
    //             })
    //           : false;
    //         return hasError;
    //       });
    //       setAddGroupErrors(errors);
    //       setAddedGroups(groups);
    //       setGroupCreateInputs(groupsNotAdded);
    //       // this.setState({addGroupErrors: errors, addedGroups: groups, groups: groupsNotAdded});
    //     }
    //   )
    // );
  };

  const groupsForm = groupCreateInputs.map((group, index: number) => {
    return (
      <Grid container key={index}>
        <Grid item>
          <GroupFormComponent
            group={group}
            updateGroup={updateGroup}
            index={index}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => handleRemoveGroupToAdd(index)}>
            minus
            {/* <Icon name="minus" /> */}
          </Button>
        </Grid>
      </Grid>
    );
  });

  const handleAddGroup = () => {
    // const groups = this.state.groups;
    // const userRelations = user ? [{
    //   property: 'users',
    //   type: RelationType.multiple,
    //   multipleOperations: [{
    //     id: user.id,
    //     type: MultipleRelationOperationType.add,
    //   }],
    // }] : [];
    const userRelations = user
      ? [
          {
            id: user.id,
            type: MultipleRelationOperationType.add,
          },
        ]
      : [];
    const group: GroupCreateInput = {
      name: '',
      userRelations,
      // userIds: compact([user ? user.id : undefined]),
    };
    setGroupCreateInputs([...groupCreateInputs, group]);
  };

  const addGroupButton = (
    <Button color="primary" name="add" onClick={handleAddGroup}>
      add
    </Button>
  );

  const saveGroupsButton =
    groupCreateInputs.length > 0 ? (
      <Button color="primary" onClick={persistGroups}>
        Add groups
      </Button>
    ) : undefined;

  let failedToAddGroupsElement: React.ReactElement<unknown> | undefined;
  if (addGroupErrors && 0 < addGroupErrors.length) {
    const errorElements = addGroupErrors.map((error: any, index: number) => {
      return (
        <Alert
          key={index}
          severity={'error'}
          onClose={() => handleDismissFailedToAddGroup(index)}
        >
          <AlertTitle>Failed to add group: {error}</AlertTitle>
        </Alert>
      );
    });
    failedToAddGroupsElement = <Paper>{errorElements}</Paper>;
  }

  let addedGroupsElement: React.ReactElement<unknown> | undefined;
  if (addedGroups && 0 < addedGroups.length) {
    const addedGroupsJoined = addedGroups
      .map((group) => {
        return group.name;
      })
      .join(',');
    addedGroupsElement = (
      <Paper>
        {/* <Alert severity={'success'} action={handleDismissAddedGroups}> */}
        <Alert severity={'success'} onClose={handleDismissAddedGroups}>
          <AlertTitle>Added groups: {addedGroupsJoined}</AlertTitle>
        </Alert>
      </Paper>
    );
  }

  // const fetch = () => fetchWorkspaceRolePermissions([], [])(dispatch);
  // const fetchButton = (
  //   <Button onClick={fetch}>
  //     FETCH
  //   </Button>
  // );

  return (
    <>
      <div>
        {/* {fetchButton} */}
        {existingGroupsListElement}
      </div>
      <div>{addedGroupsElement}</div>
      <div>{failedToAddGroupsElement}</div>
      {/* <div> */}
      {groupsForm}
      {/* </div> */}
      <div>
        <Typography>{addGroupButton}</Typography>
      </div>
      <div>{saveGroupsButton}</div>
    </>
  );
});
