import { compact } from 'lodash';
import createCachedSelector, { FlatMapCache } from 're-reselect';
import { createSelector } from 'reselect';
import { AssetTyped } from '~src/data/store/reducers/asset/reducer';
import { getAssetTypedByAsset } from '~src/data/store/selectors/asset/assets/selectors';
import { HoldingDenormalized } from '~src/data/store/selectors/finance/valuation/holdings-assets';
import {
  getBankNoteHoldingByHoldingId,
  getBankNoteHoldingsByBankNoteId,
} from '~src/data/store/selectors/holding/holding-types/bank-note/bank-note-holding/selectors';
import {
  getBondHoldingByHoldingId,
  getBondHoldingsByBondId,
} from '~src/data/store/selectors/holding/holding-types/bond/bond-holding/selectors';
import {
  getCryptoHoldingByHoldingId,
  getCryptoHoldingsByCryptoId,
} from '~src/data/store/selectors/holding/holding-types/crypto/crypto-holding/selectors';
import {
  getFundHoldingByHoldingId,
  getFundHoldingsByFundId,
} from '~src/data/store/selectors/holding/holding-types/fund/fund-holding/selectors';
import {
  getLoanHoldingByHoldingId,
  getLoanHoldingsByLoanId,
} from '~src/data/store/selectors/holding/holding-types/loan/loan-holding/selectors';
import {
  getStockHoldingByHoldingId,
  getStockHoldingsByStockId,
} from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import {
  getWarrantHoldingByHoldingId,
  getWarrantHoldingsByWarrantId,
} from '~src/data/store/selectors/holding/holding-types/warrant/warrant-holding/selectors';
import { getPortfoliosByHoldingId } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { EntityData } from '~src/domain/workspace/components/project/portfolio/entities/entities.component';

import { RecordState } from '@pladdenico/common';
import { AssetType, HoldingType } from '@pladdenico/models';

import {
  HoldingState,
  BaseHoldingId,
  holdingSelectors as holdingsSelectors,
} from '../../../reducers/holding/holdings/reducer';
import { WorkspaceDataState } from '../../../reducers/reducers';

const getHoldingState = (state: WorkspaceDataState) =>
  state.holding.holding.holdings;

export const selectHoldingsByProjectId = createSelector(
  (state: RecordState<HoldingState, string>[], projectId: string) =>
    holdingsSelectors.selectElementsByKey(state, projectId),
  (holdings: HoldingState[] | undefined): HoldingState[] => {
    return holdings ? holdings : [];
  },
);

export const getHoldingsByProjectId = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingState(state),
  (_state: WorkspaceDataState, projectId: string) => projectId,
  (holdingState, projectId): HoldingState[] => {
    return holdingsSelectors.selectElementsByKey(holdingState, projectId) ?? [];
  },
)({
  keySelector: (_state, projectId) => projectId,
  selectorCreator: createSelector,
});

interface ProjectIdByFilter {
  projectId: string;
  holdingTypes: HoldingType[] | undefined;
}

export const getHoldingsByFilter = createCachedSelector(
  (state: WorkspaceDataState, baseId: ProjectIdByFilter) =>
    getHoldingsByProjectId(state, baseId.projectId),
  (_state: WorkspaceDataState, baseId: ProjectIdByFilter) =>
    baseId.holdingTypes,
  (holdings, holdingTypes): HoldingState[] => {
    if (!holdingTypes) {
      return holdings;
    }
    return holdings.filter((holding) => {
      if (holding.type) {
        const type = holding.type as HoldingType;
        return holdingTypes.some((t) => t === type);
      }
      return false;
    });
  },
)({
  keySelector: (_state, baseId) =>
    `${baseId.projectId}/${JSON.stringify(baseId.holdingTypes)}`,
  selectorCreator: createSelector,
});

export const selectHoldingsByProjectIds = createCachedSelector(
  (state: RecordState<HoldingState, string>[], projectIds: string[]) =>
    holdingsSelectors.selectElementsByKeys(state, projectIds),
  (holdings: HoldingState[] | undefined): HoldingState[] => {
    return holdings ? holdings : [];
  },
)({
  keySelector: (_state, projectIds) => projectIds.join(';'),
  selectorCreator: createSelector,
});

export const getHoldingsByProjectIds = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingState(state),
  (_state: WorkspaceDataState, projectIds: string[]) => projectIds,
  (holdingState, projectIds): HoldingState[] => {
    return selectHoldingsByProjectIds(holdingState, projectIds);
  },
)({
  keySelector: (_state, projectIds) => projectIds.join(';'),
  selectorCreator: createSelector,
});

// export const holdingsByTypeSelector = (
//   holdings: Holding[],
//   type: HoldingType
// ): Holding[] => {
//   return holdings.filter((holding) => holding.type === type);
// };

// export const makeGetHoldingsByType = () =>
//   createSelector(holdingsByTypeSelector, (holdings) => {
//     return holdings;
//   });

// const holdingsSelector = (state: WorkspaceDataState): HoldingState[] => {
//   return state.workspace.holding.holdings;
// };

export const getHoldings = createSelector(
  (state: WorkspaceDataState) => getHoldingState(state),
  (holdings) => {
    return holdingsSelectors.selectAllElements(holdings);
  },
);

export const getHoldingById = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingState(state),
  (_state: WorkspaceDataState, baseId: BaseHoldingId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseHoldingId) => baseId.projectId,
  (holdings, id, projectId): HoldingState | undefined => {
    return holdingsSelectors.selectElementByT(holdings, {
      id: id,
      projectId,
    });
  },
)({
  keySelector: (_state, projectId, holdingId) => `${projectId}:${holdingId}`,
  selectorCreator: createSelector,
});

interface ProjectMultipleIds {
  projectId: string;
  ids: string[];
}

export const getHoldingsByIdsForProject = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingState(state),
  (_state: WorkspaceDataState, ids: ProjectMultipleIds) => ids.projectId,
  (_state: WorkspaceDataState, ids: ProjectMultipleIds) => ids.ids,
  (holdings, projectId, ids): HoldingState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        projectId,
      };
    });
    return holdingsSelectors.selectElementsByTs(holdings, baseIds);
  },
)({
  keySelector: (_state, ids) => `${ids.projectId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

export const getHoldingsByIds = createCachedSelector(
  (state: WorkspaceDataState) => getHoldingState(state),
  (_state: WorkspaceDataState, ids: BaseHoldingId[]) => ids,
  (holdings, ids): HoldingState[] => {
    return holdingsSelectors.selectElementsByTs(holdings, ids);
  },
)({
  keySelector: (_state, ids) => `${JSON.stringify(ids)}`,
  selectorCreator: createSelector,
});

interface HoldingsByAssetTypedProps {
  workspaceId: string;
  projectId: string;
  asset: AssetTyped | undefined;
}

const fillInWithHoldings = <T extends { holdingId: string }>(
  state: WorkspaceDataState,
  ts: T[],
  workspaceId: string,
  projectId: string,
) => {
  return compact(
    ts.map((h) => {
      const holding = getHoldingById(state, { projectId, id: h.holdingId });
      if (holding) {
        const portfolios = getPortfoliosByHoldingId(state, {
          id: holding.id,
          workspaceId,
        });
        return {
          info: h,
          holding,
          portfolios,
        };
      }
      return undefined;
    }),
  );
};

interface HoldingDetailedProps {
  workspaceId: string;
  projectId: string;
  holding: HoldingDenormalized;
}

export const getHoldingDetailed = createCachedSelector(
  (state: WorkspaceDataState, _props: HoldingDetailedProps) => state,
  (_state: WorkspaceDataState, props: HoldingDetailedProps) => props.holding,
  (state: WorkspaceDataState, props: HoldingDetailedProps) =>
    getPortfoliosByHoldingId(state, {
      id: props.holding.holding.id,
      workspaceId: props.workspaceId,
    }),
  (state: WorkspaceDataState, props: HoldingDetailedProps) =>
    getAssetTypedByAsset(state, {
      workspaceId: props.workspaceId,
      asset: props.holding.asset,
    }),
  (state, holding, portfolios, assetTyped) => {
    if (!assetTyped) {
      return undefined;
    }
    if (assetTyped.__typename === AssetType.Stock) {
      const holdingsTyped = getStockHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    } else if (assetTyped.__typename === AssetType.BankNote) {
      const holdingsTyped = getBankNoteHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    } else if (assetTyped.__typename === AssetType.Crypto) {
      const holdingsTyped = getCryptoHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    } else if (assetTyped.__typename === AssetType.Bond) {
      const holdingsTyped = getBondHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    } else if (assetTyped.__typename === AssetType.Fund) {
      const holdingsTyped = getFundHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    } else if (assetTyped.__typename === AssetType.Loan) {
      const holdingsTyped = getLoanHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    } else if (assetTyped.__typename === AssetType.Warrant) {
      const holdingsTyped = getWarrantHoldingByHoldingId(
        state,
        holding.holding.id,
      );
      return {
        ...holding,
        holdingsTyped,
        assetTyped,
        portfolios,
      };
    }
    return undefined;
  },
)({
  keySelector: (_state, props) => `${props.holding.holding.id}`,
  selectorCreator: createSelector,
});

export const getHoldingsByAssetTyped = createCachedSelector(
  (state: WorkspaceDataState, _props: HoldingsByAssetTypedProps) => state,
  (_state: WorkspaceDataState, props: HoldingsByAssetTypedProps) => props.asset,
  (_state: WorkspaceDataState, props: HoldingsByAssetTypedProps) =>
    props.workspaceId,
  (_state: WorkspaceDataState, props: HoldingsByAssetTypedProps) =>
    props.projectId,
  (state, asset, workspaceId, projectId) => {
    if (!asset) {
      return undefined;
    }
    if (asset.__typename === AssetType.Stock) {
      const stockId = asset.id;
      const holdingsTyped = getStockHoldingsByStockId(state, { stockId });
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    } else if (asset.__typename === AssetType.BankNote) {
      const stockId = asset.id;
      const holdingsTyped = getBankNoteHoldingsByBankNoteId(state, stockId);
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    } else if (asset.__typename === AssetType.Crypto) {
      const stockId = asset.id;
      const holdingsTyped = getCryptoHoldingsByCryptoId(state, stockId);
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    } else if (asset.__typename === AssetType.Bond) {
      const stockId = asset.id;
      const holdingsTyped = getBondHoldingsByBondId(state, stockId);
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    } else if (asset.__typename === AssetType.Fund) {
      const stockId = asset.id;
      const holdingsTyped = getFundHoldingsByFundId(state, stockId);
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    } else if (asset.__typename === AssetType.Loan) {
      const stockId = asset.id;
      const holdingsTyped = getLoanHoldingsByLoanId(state, stockId);
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    } else if (asset.__typename === AssetType.Warrant) {
      const stockId = asset.id;
      const holdingsTyped = getWarrantHoldingsByWarrantId(state, stockId);
      return {
        asset,
        holdings: fillInWithHoldings(
          state,
          holdingsTyped,
          workspaceId,
          projectId,
        ),
      };
    }
    return undefined;
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}-${props.projectId}-${props.asset?.workspaceId}`,
  selectorCreator: createSelector,
});

interface HoldingsTypedByAssetsTypedProps {
  workspaceId: string;
  projectId: string;
  assets: AssetTyped[];
}
export const getHoldingsTypedByAssetsTyped = createCachedSelector(
  (state: WorkspaceDataState, _props: HoldingsTypedByAssetsTypedProps) => state,
  (_state: WorkspaceDataState, props: HoldingsTypedByAssetsTypedProps) =>
    props.workspaceId,
  (_state: WorkspaceDataState, props: HoldingsTypedByAssetsTypedProps) =>
    props.projectId,
  (_state: WorkspaceDataState, props: HoldingsTypedByAssetsTypedProps) =>
    props.assets,
  (state, workspaceId, projectId, assets) => {
    return compact(
      assets.map((asset) => {
        const ret = getHoldingsByAssetTyped(state, {
          workspaceId,
          projectId,
          asset,
        });
        if (ret?.holdings && ret.holdings.length === 0) {
          return undefined;
        }
        return ret;
      }),
    );
  },
)({
  keySelector: (_state, props) => props.assets,
  selectorCreator: createSelector,
  cacheObject: new FlatMapCache(),
});

interface HoldingsTypedProps {
  workspaceId: string;
  projectId: string;
  entity: EntityData;
}

export const getHoldingsTyped = createCachedSelector(
  (state: WorkspaceDataState, _props: HoldingsTypedProps) => state,
  (_state: WorkspaceDataState, props: HoldingsTypedProps) => props.workspaceId,
  (_state: WorkspaceDataState, props: HoldingsTypedProps) => props.projectId,
  (_state: WorkspaceDataState, props: HoldingsTypedProps) => props.entity,
  (state, workspaceId, projectId, entity) => {
    return compact(
      entity.holdings.map((holding) => {
        const ret = getHoldingDetailed(state, {
          workspaceId,
          projectId,
          holding,
        });
        return ret;
      }),
    );
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}-${props.projectId}-${props.entity.id}`,
  selectorCreator: createSelector,
});
