import { parseEmail } from '~src/data/store/modules/people/emails/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  emailsActions,
  EmailState,
} from '~src/data/store/reducers/person/emails/reducer';
import { Email } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface EmailVisitor {
  visit(email: EmailVisitable): EmailState;
  post(): void;
}

export class EmailVisitable implements Visitable<EmailVisitor> {
  constructor(
    private _workspaceId: string,
    private _personId: string,
    private _email: Email,
  ) {}
  public accept(visitor: EmailVisitor) {
    return visitor.visit(this);
  }

  public parse(): EmailState {
    return parseEmail(this._workspaceId, this._email);
  }
}

export class EmailStateVisitor implements EmailVisitor {
  private _emails: EmailState[];
  constructor(private _dispatch: AppDispatch) {
    this._emails = [];
  }

  public visit(email: EmailVisitable): EmailState {
    const emailState = email.parse();
    this._emails.push(emailState);
    return emailState;
  }

  post() {
    this._dispatch(emailsActions.upsertManyElements(this._emails));
  }
}
