import numbro from 'numbro';
import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { colorPalette as colorsBarChart } from '~src/utils/common/colors/chart-colors';

import { Box } from '@mui/material';
// import { colorLinesAndPoints } from '@pladdenico/common';

// import { colorsHistogram } from '~src/utils/common/chart-colors';

// import { getHoverColor } from '~src/utils/common/color-utils';

export interface WaterfallColumn {
  id: string;
  key: string;
  name: string;
  value: number;
  filteredOut: boolean;
  absolute?: boolean;
}

interface WaterfallData {
  name: string;
  value: number;
  startValue: number;
  color?: string;
}

interface Props {
  columns: WaterfallColumn[];
}

function useData(columns: WaterfallColumn[]) {
  const data: WaterfallData[] = [];
  let startValue = 0;
  for (let i = 0; i < columns.length; ++i) {
    if (columns[i].absolute) {
      data.push({
        value: columns[i].value,
        name: columns[i].name,
        startValue: 0,
        color: colorsBarChart[4],
      });
    } else {
      data.push({ value: columns[i].value, name: columns[i].name, startValue });
      startValue += columns[i].value;
    }
  }
  return data;
}
const valueToTick = (decimal: number) => {
  return `${numbro(decimal).format('0,0')}`;
};

interface TickProps {
  x: number;
  y: number;
  stroke: string;
  payload: any;
}

const WaterfallTickComponent = React.memo((props: any) => {
  const { x, y, payload } = props as TickProps;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={12}
        // textAnchor="end"
        fill="#666"
        textAnchor="middle"
        alignmentBaseline="central"
        transform="rotate(-25)"
      >
        {payload.value}
      </text>
    </g>
  );
});

export const WaterfallComponent = React.memo((props: Props) => {
  const { columns } = props;
  const data = useData(columns);
  return (
    // <ResponsiveContainer width="100%" height={500}>
    // <ResponsiveContainer height="100%">
    <Box sx={{ flex: 1, overflow: 'hidden' }}>
      <ResponsiveContainer>
        <BarChart
          // width={600}
          // height={600}
          // width="100%"
          data={data}
          margin={{ top: 5, right: 30, left: 60, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis dataKey="name" tick={{ fontSize: 10 }} interval={0} /> */}
          <XAxis
            dataKey="name"
            tick={<WaterfallTickComponent />}
            interval={0}
          />
          <YAxis tickFormatter={valueToTick} />
          <Tooltip
            cursor={{
              // stroke: format?.tooltip?.stroke ?? 'red',
              // fill: getHoverColor('#FFFFFF', 30),
              fill: '#EEEEEE',
              // strokeWidth: 2,
            }}
          />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="startValue" stackId="a" fill="transparent" />
          {/* <Bar dataKey="value" stackId="a" fill="#82ca9d"> */}
          <Bar dataKey="value" stackId="a" fill={colorsBarChart[2]}>
            {data.map((item, index) => {
              if (item.color) {
                return <Cell key={index} fill={item.color} />;
              }
              if (item.value < 0)
                return <Cell key={index} fill={colorsBarChart[1]} />;
              return <Cell key={index} fill={colorsBarChart[2]} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
});
