import {
  Control,
  FieldValues,
  Path,
  UseFormSetValue,
  PathValue,
} from 'react-hook-form';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { CommitmentFieldConfig } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-field-config';

import { Grid } from '@mui/material';
import { useCommitmentCurrencyField } from '~src/domain/workspace/components/project/commitment/form/fields/base/use-currency-field';
import { useCommitmentDateField } from '~src/domain/workspace/components/project/commitment/form/fields/base/use-date-field';
import { useCommitmentTypeField } from '~src/domain/workspace/components/project/commitment/form/fields/base/use-type-field';
import { useCommitmentValueField } from '~src/domain/workspace/components/project/commitment/form/fields/base/use-value-field';
import { CommitmentInputData } from '~src/domain/workspace/components/project/commitment/form/fields/commitment-data-types';
import { CommitmentType } from '~src/utils/interfaces/commitment-type';

// interface Props {
//   projectId: string;
//   holding: HoldingState;
//   control: Control<CommitmentInputData, FormContext>;
//   setValue: UseFormSetValue<CommitmentInputData>;
//   fieldConfig?: CommitmentFieldConfig;
//   commitmentTypes: CommitmentType[] | undefined;
// }

interface Props<T extends FieldValues> {
  projectId: string;
  holding: HoldingState;
  control: Control<T>;
  getName: (name: Path<CommitmentInputData>) => Path<T>;
  setValue: UseFormSetValue<T>;
  fieldConfig?: CommitmentFieldConfig;
  commitmentTypes?: CommitmentType[];
}

export const CommitmentFieldsComponent = <T extends FieldValues>(
  props: Props<T>,
) => {
  const { control, fieldConfig, commitmentTypes, getName } = props;

  const { element: typeElement, visible: typeVisible } = useCommitmentTypeField(
    control,
    getName('base.type'),
    undefined,
    fieldConfig?.base?.type,
    commitmentTypes,
  );
  // const { element: typeElement, visible: typeVisible } = useCommitmentTypeField(
  //   control,
  //   index,
  //   fieldConfig?.'commitment'?.type,
  //   commitmentTypes
  // );
  const { element: dateElement, visible: dateVisible } = useCommitmentDateField(
    control,
    'date',
    getName('base.commitmentDate'),
    new Date() as PathValue<T, Path<T>>,
    fieldConfig?.base?.commitmentDate,
  );
  const { element: startDateElement, visible: startDateVisible } =
    useCommitmentDateField(
      control,
      'startDate',
      getName('base.startDate'),
      new Date() as PathValue<T, Path<T>>,
      fieldConfig?.base?.startDate,
    );
  const { element: endDateElement, visible: endDateVisible } =
    useCommitmentDateField(
      control,
      'endDate',
      getName('base.endDate'),
      new Date() as PathValue<T, Path<T>>,
      fieldConfig?.base?.endDate,
    );
  const { element: currencyElement, visible: currencyVisible } =
    useCommitmentCurrencyField(
      control,
      getName('base.currencyId'),
      undefined,
      fieldConfig?.base?.currency,
    );
  const { element: valueElement, visible: valueVisible } =
    useCommitmentValueField(
      control,
      getName('base.value'),
      0 as PathValue<T, Path<T>>,
      fieldConfig?.base?.value,
    );

  return (
    <>
      <Grid container columnSpacing={1}>
        {typeVisible && (
          <Grid item sm={4}>
            {typeElement}
          </Grid>
        )}
        {dateVisible && (
          <Grid item sm={4}>
            {dateElement}
          </Grid>
        )}
        {startDateVisible && (
          <Grid item sm={4}>
            {startDateElement}
          </Grid>
        )}
        {endDateVisible && (
          <Grid item sm={4}>
            {endDateElement}
          </Grid>
        )}
        {currencyVisible && (
          <Grid item sm={4}>
            {currencyElement}
          </Grid>
        )}
        {valueVisible && (
          <Grid item sm={4}>
            {valueElement}
          </Grid>
        )}
      </Grid>
    </>
  );
};
