import React from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmDialogComponent } from '~src/components/utils/confirm-delete-dialog.component';
import { deleteReports } from '~src/data/store/modules/workspaces/reports/report/requests';
import { ReportState } from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useReportDialogComponent } from '~src/domain/workspace/components/project/portfolio/reporting/scheduled-reporting/use-report-dialog.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { AppDispatch } from '~src/store/store';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';

export const useReportMenu = (
  workspace: WorkspaceState,
  projectId: string,
  report: ReportState,
) => {
  const [editing, setEditing] = React.useState(false);
  const { anchorEl, handleClose, isOpen, handleOpen } = useMenu();
  const { open, isShowing, close } = useVisible();
  const { dialog: runDialog, modal: runModal } = useReportDialogComponent(
    workspace,
    [report],
  );
  const dispatch = useDispatch<AppDispatch>();
  const deleteReport = React.useCallback(() => {
    return dispatch(
      deleteReports(workspace.tenantId, workspace.id, projectId, [report.id]),
    );
  }, [dispatch, projectId, report.id, workspace.id, workspace.tenantId]);

  const menuElement = React.useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEditing(true);
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={open}>Delete</MenuItem>
        <MenuItem onClick={runModal.open}>Run</MenuItem>
        <ConfirmDialogComponent
          isOpen={isShowing}
          handler={() => {
            deleteReport().then(() => handleClose());
          }}
          close={close}
          objectString="report"
        />
        {runDialog}
      </Menu>
    ),
    [
      anchorEl,
      isOpen,
      handleClose,
      open,
      runModal.open,
      isShowing,
      close,
      runDialog,
      deleteReport,
    ],
  );

  const menuButtonElement = React.useMemo(
    () => (
      <IconButton onClick={handleOpen} color="inherit">
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOpen],
  );

  return {
    menuElement,
    menuButtonElement,
    editing,
    setEditing,
    handleOpen,
    handleClose,
  };
};
