import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getStockById } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getStockHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/stock/stock-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useStockHoldingInfo(workspaceId: string, holdingId: string) {
  const stockHolding = useSelector((state: RootState) =>
    getStockHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const stock = useSelector((state: RootState) =>
    getStockById(selectWorkspaceData(state), {
      id: stockHolding ? stockHolding.stockId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: stock ? stock.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    stockHolding,
    asset,
    stock,
  };
}
