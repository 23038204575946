import React from 'react';
import { HoldingDashboardConfigState } from '~src/data/store/reducers/holding/config/dashboard-configs/state';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useEditDialog } from '~src/domain/workspace/components/project/holding/config/use-edit-dialog';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  dashboardConfig: HoldingDashboardConfigState;
}

export const EditDashboardConfig = (props: Props) => {
  const { workspace, dashboardConfig } = props;

  const { dialogElement, setOpen } = useEditDialog({
    workspace,
    projectId: props.projectId,
    config: dashboardConfig,
  });

  return (
    <>
      <IconButton
        // color="primary"
        aria-label="edit config"
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </IconButton>

      {dialogElement}
    </>
  );
};
