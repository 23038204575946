export interface EntitySelectors<T> {
  selectElement: (state: T | null) => T | undefined;
}

export class EntityAdapter<T> {
  private _initialState: T | null;
  private _selectors: EntitySelectors<T>;

  constructor(initialState: T | null) {
    this._initialState = initialState;
    this._selectors = {
      selectElement: this.selectElement,
    };
  }

  get initialState() {
    return this._initialState;
  }

  get selectors(): EntitySelectors<T> {
    return this._selectors;
  }

  private selectElement = (state: T | null) => {
    return state === null ? undefined : state;
  };

  public setElementReducer = () => {
    return (_state: T | null, action: { payload: T }): T | null => {
      const t = action.payload;
      return t;
    };
  };

  public unsetElementReducer = () => {
    return (_state: T | null, _action: { payload: undefined }): T | null => {
      return null;
    };
  };

  public clearReducer = () => {
    return (_state: T[], _action: { payload: undefined }) => {
      return null;
    };
  };
}
