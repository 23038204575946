import React from 'react';
import {
  PivotType,
  PivotTypes,
  typeToString,
} from '~src/utils/interfaces/pivot-type';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  handleValue: (type: PivotType) => void;
  type: PivotType | undefined;
  label: string | undefined;
}

export const PivotTypeField = React.memo((props: Props) => {
  const handleSelectChange = (event: SelectChangeEvent<PivotType>) => {
    props.handleValue(event.target.value as PivotType);
  };

  const label = props.label ? (
    <InputLabel htmlFor="Type">{props.label}</InputLabel>
  ) : undefined;

  const menuItems = React.useMemo(() => {
    return Object.values(PivotTypes).map((type) => {
      return (
        <MenuItem key={type} value={type}>
          {typeToString(type)}
        </MenuItem>
      );
    });
  }, []);

  return (
    <FormControl fullWidth margin="dense">
      {label}
      <Select
        value={props.type}
        onChange={handleSelectChange}
        defaultValue={undefined}
        label={props.label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
