import { Control } from 'react-hook-form';
import { usePaperField } from '~src/domain/workspace/components/asset/form/fields/types/use-paper.field';
import { InputStockData } from '~src/domain/workspace/components/asset/types/stocks/form/stock-input.data';
import { useTextField } from '~src/utils/form/fields/text-field';

export const useStockFields = (control: Control<InputStockData, any>) => {
  const nameField = useTextField(control, 'name', 'Name', '');
  const paperField = usePaperField(control, 'paperId', 'Paper', '');
  return {
    nameField,
    paperField,
  };
};
