import { useSelector } from 'react-redux';
import { RootState } from '~src/data/store/reducers/reducers';
import { getBankNoteById } from '~src/data/store/selectors/asset/asset-types/bank-notes/selectors';
import { getAssetById } from '~src/data/store/selectors/asset/assets/selectors';
import { getBankNoteHoldingByHoldingId } from '~src/data/store/selectors/holding/holding-types/bank-note/bank-note-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

export function useBankNoteHoldingInfo(workspaceId: string, holdingId: string) {
  const bankNoteHolding = useSelector((state: RootState) =>
    getBankNoteHoldingByHoldingId(selectWorkspaceData(state), holdingId),
  );
  const bankNote = useSelector((state: RootState) =>
    getBankNoteById(selectWorkspaceData(state), {
      id: bankNoteHolding ? bankNoteHolding.bankNoteId : '',
      workspaceId,
    }),
  );
  const asset = useSelector((state: RootState) =>
    getAssetById(selectWorkspaceData(state), {
      id: bankNote ? bankNote.assetId : undefined,
      workspaceId,
    }),
  );
  return {
    bankNoteHolding,
    asset,
    bankNote,
  };
}
