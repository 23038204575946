import { handleLoans } from '~src/data/store/modules/assets/loans/handler';
import { loansActions } from '~src/data/store/reducers/asset/asset-types/loans/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

export function fetchLoans(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetLoansDocument;
    const variables: graphqlWorkspaceTypes.GetLoansQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getLoans) {
        return handleLoans(workspaceId, dispatch, data.getLoans);
      }
      return [];
    });
  };
}

export function createLoans(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateLoanInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateLoansDocument;
    const variables: graphqlWorkspaceTypes.CreateLoansMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createLoans) {
        return handleLoans(workspaceId, dispatch, data.createLoans);
      }
      return [];
    });
  };
}

export function updateLoans(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateLoanInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateLoansDocument;
    const variables: graphqlWorkspaceTypes.UpdateLoansMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateLoans) {
        return handleLoans(workspaceId, dispatch, data.updateLoans);
      }
      return [];
    });
  };
}

export function upsertLoans(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertLoanInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertLoansDocument;
    const variables: graphqlWorkspaceTypes.UpsertLoansMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertLoans) {
        return handleLoans(workspaceId, dispatch, data.upsertLoans);
      }
      return [];
    });
  };
}

export function deleteLoans(
  tenantId: string,
  workspaceId: string,
  loans: Array<{ id: string; assetId: string }>,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteLoansDocument;
    loans.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeleteLoanInputType[] = loans.map(
      (loan) => {
        return {
          id: loan.id,
        };
      },
    );
    const variables: graphqlWorkspaceTypes.DeleteLoansMutationVariables = {
      inputs,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteLoans) {
        const deletedLoans = intersectionSorted(
          loans,
          data.deleteLoans,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, workspaceId };
          },
        );
        return dispatch(loansActions.removeManyElements(deletedLoans));
      }
      return [];
    });
  };
}
