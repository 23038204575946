import { Typography } from '@mui/material';
import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { DistributionComponent } from '../distribution';

export const StockPriceNode = React.memo(
  ({ data, isConnectable }: NodeProps) => {
    return (
      <>
        <div>Stock price</div>
        <Typography variant="caption">{data.stockPrice}</Typography>
        <DistributionComponent distribution={data.distribution} />
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </>
    );
  },
);
