import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';
import { useEditCompactTypeField } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/form/edit-compact-type-field';
import { usePeriodFields } from '~src/utils/form/fields/use-period-fields';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import { InputData } from './project-report-input.data';

export interface Props {
  handleValue: (type: CompactType) => void;
  type: CompactType | undefined;
  label: string | undefined;
  disabled: boolean;
}

export const useProjectReportFields = (
  control: Control<InputData, any>,
  setValue: UseFormSetValue<InputData>,
) => {
  const nameField = (
    <Controller
      name={'name'}
      rules={{ required: true }}
      control={control}
      defaultValue={''}
      render={({ field, fieldState }) => (
        <TextField
          margin="normal"
          fullWidth
          required
          value={field.value}
          label="Name"
          type="text"
          onChange={field.onChange}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : null}
        />
      )}
    />
  );
  const publishedField = (
    <Controller
      name={'published'}
      // rules={{ required: true }}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              required
              checked={field.value}
              onChange={field.onChange}
            />
          }
          label="Published"
        />
      )}
    />
  );

  const compactTypeField = useEditCompactTypeField(
    control,
    'compactType',
    'vertical',
  );

  const periodFields = usePeriodFields(
    control,
    'periodStart',
    'periodEnd',
    'Period Start',
    'Period End',
    setValue,
  );
  return { nameField, publishedField, periodFields, compactTypeField };
};
