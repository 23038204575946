import { handleTenantRoles } from '~src/data/store/modules/tenant/tenant-roles/tenant-roles/handler';
import { tenantRolesActions } from '~src/data/store/reducers/tenant/tenant-role/tenant-roles/reducer';
import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import { QueryContextTypes, QueryUserContext } from '@pladdenico/portfolio-api';

export function fetchTenantRoles() {
  return (dispatch: AppDispatch) => {
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    const node = graphqlUserTypes.GetTenantRolesDocument;
    const variables: graphqlUserTypes.GetTenantRolesQueryVariables = {};
    // const context: QueryUserContext = {
    //   type: QueryContextTypes.user,
    // };
    return investRequest(node, variables, context).then((data) => {
      if (data.getTenantRoles) {
        return handleTenantRoles(dispatch, data.getTenantRoles);
      }
      return [];
    });
  };
}

export function createTenantRole(
  input: graphqlUserTypes.CreateTenantRoleInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.CreateTenantRoleDocument;
    const variables: graphqlUserTypes.CreateTenantRoleMutationVariables = {
      input,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createTenantRole) {
        return handleTenantRoles(dispatch, [data.createTenantRole]);
      }
      return undefined;
    });
  };
}

export function updateTenantRole(
  input: graphqlUserTypes.UpdateTenantRoleInputType,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.UpdateTenantRoleDocument;
    const variables: graphqlUserTypes.UpdateTenantRoleMutationVariables = {
      input,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateTenantRole) {
        return handleTenantRoles(dispatch, [data.updateTenantRole]);
      }
      return [];
    });
  };
}

export function deleteTenantRole(tenantRoleId: number) {
  return (dispatch: AppDispatch) => {
    const node = graphqlUserTypes.DeleteTenantRoleDocument;
    const variables: graphqlUserTypes.DeleteTenantRoleMutationVariables = {
      input: tenantRoleId,
    };
    const context: QueryUserContext = {
      type: QueryContextTypes.user,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteTenantRole) {
        dispatch(tenantRolesActions.removeOne({ id: data.deleteTenantRole }));
      }
      return [];
    });
  };
}

// export function selectTenantRole(tenantRole: TenantRoleState) {
//   return (dispatch: any) => {
//     if (tenantRole.id) {
//       // dispatch(selectTenantRoleIdAction(tenantRole.id));
//       dispatch(selectedTenantRolesActions.set(tenantRole.id));
//     }
//     // dispatch(unselectTenantRoleRoleAction());
//     dispatch(unselectPortfolioAction());
//   };
// }

// export function unselectTenantRole() {
//   return (dispatch: any) => {
//     // dispatch(unselectTenantRoleAction());
//     dispatch(selectedTenantRolesActions.unset(undefined));
//     // dispatch(unselectTenantRoleRoleAction());
//     dispatch(unselectPortfolioAction());
//   };
// }
