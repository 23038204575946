import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import { TransferType } from '@pladdenico/models';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseTransferId {
  holdingId: string;
  id: string;
}

export type TransferState = Omit<
  graphqlWorkspaceTypes.Transfer,
  'holding' | 'currency' | 'transaction' | 'commitment'
> & {
  holdingId: string;
  currencyId: string;
  commitmentId: string;
  transactionId: string;
  date: Date;
  type: TransferType;
};

export const initialTransfersState = [];

const selectKeyId = (transfer: BaseTransferId) => transfer.holdingId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseTransferId, b: BaseTransferId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<BaseTransferId, TransferState, string>(
  [],
  selectKeyId,
  keyComparator,
  tComparator,
);

export const transfersSlice = createBaseRecordSlice('transfers', adapter);
export const transfersSelectors = adapter.selectors;

export const transfersReducer = transfersSlice.reducer;
export const transfersActions = transfersSlice.actions;
