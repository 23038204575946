import { holdingPath, Operation } from '@pladdenico/portfolio-api';
import {
  fetchHoldingObjs,
  handleDeleteHoldings,
} from '~src/data/store/modules/holdings/base/requests';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';

import { apis } from '~src/services/request/apis';

export function subscribeToHoldings(
  dispatch: any,
  tenantId: string,
  workspaceId: string,
  holdings: HoldingState[],
  subscriptions: Operation[],
) {
  holdings.forEach((holding) => {
    subscriptions.forEach((operation) => {
      if (operation === Operation.update || operation === Operation.upsert) {
        apis.invest.subscribe(
          {
            path: holdingPath(tenantId, workspaceId).objectPath(
              holding.id,
              operation,
            ),
            handle: (res: { id: string }) => {
              dispatch(fetchHoldingObjs(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to holding', operation, holding.id);
          },
        );
      } else if (operation === Operation.create) {
        apis.invest.subscribe(
          {
            path: holdingPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(fetchHoldingObjs(tenantId, workspaceId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to holding', operation, holding.id);
          },
        );
      } else if (operation === Operation.delete) {
        apis.invest.subscribe(
          {
            path: holdingPath(tenantId, workspaceId).domainPath(operation),
            handle: (res: { id: string }) => {
              dispatch(handleDeleteHoldings(holding.projectId, [res.id]));
            },
          },
          () => {
            // console.log('subscribed to holding', operation, holding.id);
          },
        );
      }
    });
  });
}
