import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceRoleState } from '~src/data/store/reducers/workspace/workspace-role/workspace-roles/reducer';

import { WorkspaceDataState } from '../../../reducers/reducers';

export const selectWorkspaceRoles = (state: WorkspaceDataState) =>
  state.workspace.workspaceRole.workspaceRoles;

export const workspaceRoleSelector = (
  workspaceRoles: WorkspaceRoleState[],
  workspaceRoleId: number | null,
): WorkspaceRoleState | undefined => {
  if (!workspaceRoleId) {
    return undefined;
  }
  return workspaceRoles.find((workspaceRole) => {
    return workspaceRole.id === workspaceRoleId;
  });
};

export const getWorkspaceRoleById = createCachedSelector(
  (state: WorkspaceDataState, _workspaceRoleId: number) =>
    selectWorkspaceRoles(state),
  (_state: WorkspaceDataState, workspaceRoleId: number) => workspaceRoleId,
  (workspaceRoles, workspaceRoleId) => {
    return workspaceRoleSelector(workspaceRoles, workspaceRoleId);
  },
)({
  keySelector: (_state, workspaceRoleId) => workspaceRoleId,
  selectorCreator: createSelector,
});

export const getWorkspaceRolesByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => selectWorkspaceRoles(state),
  (_state: WorkspaceDataState, workspaceId: string | undefined) => workspaceId,
  (workspaceRoles, workspaceId) => {
    if (!workspaceId) {
      return [];
    }
    return workspaceRoles.filter((workspaceRole) => {
      return workspaceRole.workspaceId === workspaceId;
    });
  },
)({
  keySelector: (_state, workspaceId) => `${workspaceId}`,
  selectorCreator: createSelector,
});
