import { Node } from 'reactflow';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';
import { NodeType } from '../../nodes/node-type';

export const defaultInitialNodes: Node<NodeData, NodeType>[] = [
  {
    id: 'initial',
    type: 'initial',
    data: {
      label: 'Initial',
      evaluator: {
        expressions: [],
      },
      // onChange,
      // evaluate,
    },
    position: { x: 380, y: 0 },
  },
  {
    id: 'valuation',
    type: 'valuation',
    data: {
      label: 'valuation',
      // onChange,
      // evaluate,
    },
    position: { x: 240, y: 440 },
  },
  {
    id: 'transfer',
    type: 'transfer',
    data: {
      label: 'transfer',
      // onChange,
      // evaluate,
    },
    position: { x: 440, y: 440 },
  },
];
