import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BasePortfolioProjectId {
  workspaceId: string;
  id: string;
}
export type PortfolioProjectState = Omit<
  graphqlWorkspaceTypes.PortfolioProject,
  'project' | 'portfolio'
> & {
  workspaceId: string;
  portfolioId: string;
  projectId: string;
};

export const initialPortfolioProjectsState = [];

const selectKeyId = (portfolioProject: BasePortfolioProjectId) =>
  portfolioProject.workspaceId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BasePortfolioProjectId, b: BasePortfolioProjectId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BasePortfolioProjectId,
  PortfolioProjectState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const portfolioProjectsSlice = createBaseRecordSlice(
  'portfolioProjects',
  adapter,
);
export const portfolioProjectSelectors = adapter.selectors;

export const portfolioProjectsReducer = portfolioProjectsSlice.reducer;
export const portfolioProjectsActions = portfolioProjectsSlice.actions;
