import { Maybe } from 'graphql/jsutils/Maybe';
import { compact, isEqualWith, uniq } from 'lodash';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { handleAssets } from '~src/data/store/modules/assets/assets/handler';
import { fetchAssets } from '~src/data/store/modules/assets/assets/requests';
import { fetchAssetCategories } from '~src/data/store/modules/assets/categories/requests';
import { handleEntities } from '~src/data/store/modules/entities/entities/handler';
import { fetchEntities } from '~src/data/store/modules/entities/entities/requests';
import { handlePapers } from '~src/data/store/modules/finance/papers/handler';
import { fetchQuotes } from '~src/data/store/modules/finance/quotes/requests';
import { handlePeople } from '~src/data/store/modules/people/people/handlers';
import { fetchPeople } from '~src/data/store/modules/people/people/requests';
import { handleWorkspace } from '~src/data/store/modules/workspaces/workspaces/handler';
import { fetchWorkspace } from '~src/data/store/modules/workspaces/workspaces/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { getBondsByWorkspaceId } from '~src/data/store/selectors/asset/asset-types/bonds/selectors';
import { getFundsByWorkspaceId } from '~src/data/store/selectors/asset/asset-types/funds/selectors';
import { getStocksByWorkspaceId } from '~src/data/store/selectors/asset/asset-types/stocks/selectors';
import { getAllPapers } from '~src/data/store/selectors/finance/papers/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '~src/data/store/selectors/selectors';
import { Paper } from '~src/services/graphql/finance/client/graphql';
import {
  Asset,
  Entity,
  Person,
  Workspace,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';

import { fetchCurrencies } from '../../currencies/requests';
import { fetchPapers } from '../../finance/papers/requests';
import { fetchRegions } from '../../regions/requests';

function useFetchWorkspaceBaseData(tenantId: string, workspaceId: string) {
  const execute = React.useCallback(
    (dispatch: AppDispatch) => {
      const baseDataPromises = new Array<Promise<boolean>>();
      baseDataPromises.push(dispatch(fetchRegions(null)).then(() => true));
      baseDataPromises.push(dispatch(fetchCurrencies(null)).then(() => true));
      baseDataPromises.push(
        dispatch(fetchAssetCategories(tenantId, workspaceId, null)).then(
          () => true,
        ),
      );
      const dataPromises: [
        Promise<Maybe<Workspace>>,
        Promise<Maybe<Paper[]>>,
        Promise<Maybe<Asset[]>>,
        Promise<Maybe<Person[]>>,
        Promise<Maybe<Entity[]>>,
      ] = [
        fetchWorkspace(tenantId, workspaceId, {
          withAssets: false,
          withEntities: false,
          withPeople: false,
          withTransfers: false,
        }),
        fetchPapers(null),
        fetchAssets(tenantId, workspaceId, null),
        fetchPeople(tenantId, workspaceId, null),
        fetchEntities(tenantId, workspaceId, null),
      ];

      const dataPromise = Promise.all(dataPromises).then(
        ([workspace, papers, assets, people, entities]) => {
          batch(() => {
            if (workspace) {
              handleWorkspace(dispatch, tenantId, workspace, [
                Operation.create,
                Operation.delete,
                Operation.update,
                Operation.upsert,
              ]);
            }
            if (papers) {
              handlePapers(dispatch, papers);
            }
            if (assets) {
              handleAssets(dispatch, tenantId, workspaceId, assets, [
                Operation.create,
                Operation.delete,
                Operation.update,
                Operation.upsert,
              ]);
            }
            if (people) {
              handlePeople(dispatch, tenantId, workspaceId, people);
            }
            if (entities) {
              return handleEntities(dispatch, tenantId, workspaceId, entities, [
                Operation.create,
                Operation.delete,
                Operation.update,
                Operation.upsert,
              ]);
            }
          });
        },
      );
      // baseDataPromises.push(
      //   dispatch(fetchWorkspace(tenantId, workspaceId, handleWorkspace)).then(
      //     () => true
      //   )
      // );
      // baseDataPromises.push(dispatch(fetchPapers(null)).then(() => true));
      return Promise.all([dataPromise, baseDataPromises]);
    },
    [tenantId, workspaceId],
  );
  return { execute };
}

export function useFetchWorkspaceSubsequentData(
  _tenantId: string,
  workspaceId: string,
) {
  const papers = useSelector((state: RootState) =>
    getAllPapers(selectFinancialData(state)),
  );
  const stocks = useSelector((state: RootState) =>
    getStocksByWorkspaceId(selectWorkspaceData(state), workspaceId),
  );
  const funds = useSelector((state: RootState) =>
    getFundsByWorkspaceId(selectWorkspaceData(state), workspaceId),
  );
  const bonds = useSelector((state: RootState) =>
    getBondsByWorkspaceId(selectWorkspaceData(state), workspaceId),
  );
  const paperIds = React.useMemo(() => {
    return compact(
      uniq([
        ...stocks.map((stock) => stock.paperId),
        ...funds.map((fund) => fund.paperId),
        ...bonds.map((bond) => bond.paperId),
      ]),
    );
  }, [bonds, funds, stocks]);
  const prevPaperIdsRef = React.useRef<string[]>([]);

  const execute = React.useCallback(
    (dispatch: AppDispatch) => {
      if (
        paperIds.length !== prevPaperIdsRef.current.length ||
        !isEqualWith(paperIds, prevPaperIdsRef.current, (a, b) => a.id === b.id)
      ) {
        paperIds.map((paperId) => {
          const paperIdx = papers.findIndex((paper) => paper.id === paperId);
          if (paperIdx !== -1) {
            const paper = papers[paperIdx];
            if (paper.symbol !== '.') {
              return dispatch(fetchQuotes(paper.id, null));
            }
          }
        });
        prevPaperIdsRef.current = paperIds;
      }
    },
    [paperIds, papers],
  );

  // React.useEffect(() => {
  //   if (
  //     paperIds.length !== prevPaperIdsRef.current.length ||
  //     !isEqualWith(paperIds, prevPaperIdsRef.current, (a, b) => a.id === b.id)
  //   ) {
  //     prevPaperIdsRef.current = paperIds;
  //   }
  // }, [paperIds]);

  return {
    execute,
  };
}

export function useFetchWorkspaceData(tenantId: string, workspaceId: string) {
  const dispatch = useDispatch<AppDispatch>();
  const { execute: executeBaseData } = useFetchWorkspaceBaseData(
    tenantId,
    workspaceId,
  );
  const [baseDataFetched, setBaseDataFetched] = React.useState(false);
  const [subsequentDataFetched, setSubsequentDataFetched] =
    React.useState(false);
  const { execute: executeSubsequentData } = useFetchWorkspaceSubsequentData(
    tenantId,
    workspaceId,
  );
  React.useEffect(() => {
    setBaseDataFetched(false);
    dispatch(executeBaseData).then(() => {
      // dispatch(executeSubsequentData);
      setSubsequentDataFetched(false);
      setBaseDataFetched(true);
    });
  }, [dispatch, executeBaseData, setSubsequentDataFetched]);

  React.useEffect(() => {
    if (baseDataFetched && !subsequentDataFetched) {
      setSubsequentDataFetched(true);
      dispatch(executeSubsequentData);
    }
  }, [
    baseDataFetched,
    dispatch,
    executeSubsequentData,
    setSubsequentDataFetched,
    subsequentDataFetched,
  ]);
}
