import React from 'react';
import { AllocationPieChartComponent } from '~src/domain/workspace/components/project/portfolio/allocations/pie-chart/allocation-pie-chart.component';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Data } from './data';

export function useGoalPieChart(
  filterKey: string,
  allocationGoalData: Data[],
  // setShowFilteredOut: (value: boolean) => void,
  getColorIndex: (id: string) => number,
) {
  const getLabelAllocationGoalName = React.useCallback(
    (index: number) => {
      return allocationGoalData[index].name;
    },
    [allocationGoalData],
  );
  const getAllocationItem = React.useCallback(
    (index: number) => {
      return allocationGoalData[index];
    },
    [allocationGoalData],
  );

  const pieChartGoals = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Typography variant="body2" textAlign="center">
        Allocation goal: {filterKey}
      </Typography>
      <AllocationPieChartComponent
        clickOnArea={() => {
          console.log('click on area');
        }}
        data={allocationGoalData}
        getLabelName={getLabelAllocationGoalName}
        getItem={getAllocationItem}
        getColorIndex={getColorIndex}
        cx={50}
        outerRadius={50}
      />
    </Box>
  );
  return pieChartGoals;
}
