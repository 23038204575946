import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import { RequestStatus } from '~src/services/request/request-status';

import { strcmp } from '@pladdenico/common';

export interface RequestStatusId {
  id: string;
}

export type RequestStatusState = RequestStatus;

export function RequestStatusToRequestStatusState(
  RequestStatus: RequestStatusState,
) {
  return RequestStatus;
}
export const initialRequestStatusState = [];

const comparator = (a: RequestStatusId, b: RequestStatusId) =>
  strcmp(a.id, b.id);
const merger = (_a: RequestStatusState, b: RequestStatusState) => b;

const adapter = new EntityArrayAdapter<RequestStatusId, RequestStatusState>(
  initialRequestStatusState,
  comparator,
  merger,
);

export const RequestStatusSlice = createBaseArraySlice(
  'requestStatus',
  adapter,
);
export const requestStatusSelectors = adapter.selectors;

export const requestStatusReducer = RequestStatusSlice.reducer;
export const requestStatusActions = RequestStatusSlice.actions;
