import { investmentsActions } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/investments/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';

import { intersectionSorted, strcmp } from '@pladdenico/common';
import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleInvestments } from './handler';

export function fetchInvestments(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = graphqlWorkspaceTypes.GetPrivateEquityFundInvestmentsDocument;
    const variables: graphqlWorkspaceTypes.GetPrivateEquityFundInvestmentsQueryVariables =
      {
        ids,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getPrivateEquityFundInvestments) {
        return handleInvestments(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.getPrivateEquityFundInvestments,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return [];
    });
  };
}

export function createInvestments(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentInputType[],
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentsDocument;
    const variables: graphqlWorkspaceTypes.CreatePrivateEquityFundInvestmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createPrivateEquityFundInvestments) {
        return handleInvestments(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.createPrivateEquityFundInvestments,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function updateInvestments(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestmentInputType[],
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestmentsDocument;
    const variables: graphqlWorkspaceTypes.UpdatePrivateEquityFundInvestmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updatePrivateEquityFundInvestments) {
        return handleInvestments(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.updatePrivateEquityFundInvestments,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertInvestments(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  inputs: graphqlWorkspaceTypes.UpsertPrivateEquityFundInvestmentInputType[],
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.UpsertPrivateEquityFundInvestmentsDocument;
    const variables: graphqlWorkspaceTypes.UpsertPrivateEquityFundInvestmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertPrivateEquityFundInvestments) {
        return handleInvestments(
          tenantId,
          workspaceId,
          fundId,
          dispatch,
          data.upsertPrivateEquityFundInvestments,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function deleteInvestments(
  tenantId: string,
  workspaceId: string,
  fundId: string,
  investments: Array<{ id: string; fundId: string }>,
) {
  return (dispatch: any) => {
    const node =
      graphqlWorkspaceTypes.DeletePrivateEquityFundInvestmentsDocument;
    investments.sort((a, b) => strcmp(a.id, b.id));
    const inputs: graphqlWorkspaceTypes.DeletePrivateEquityFundInvestmentInputType[] =
      investments.map((privateEquityFund) => {
        return {
          id: privateEquityFund.id,
        };
      });
    const variables: graphqlWorkspaceTypes.DeletePrivateEquityFundInvestmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deletePrivateEquityFundInvestments) {
        const deletedInvestments = intersectionSorted(
          investments,
          data.deletePrivateEquityFundInvestments,
          [(a, b) => strcmp(a.id, b)],
          (a, b) => {
            return { id: b, fundId };
          },
        );
        return dispatch(
          investmentsActions.removeManyElements(deletedInvestments),
        );
      }
      return [];
    });
  };
}
