import { compact } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { BondHoldingState } from '~src/data/store/reducers/holding/holding-types/bond/bond-holding/reducer';
import { BondPositionState } from '~src/data/store/reducers/holding/holding-types/bond/bond-position/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getBondPositionsByBondHoldingId } from '~src/data/store/selectors/holding/holding-types/bond/bond-position/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { useBondPositionTableActions } from '~src/hooks/actions/workspace/project/holding/types/bonds/use-bond-position-table-actions';
import {
  BondPositionData,
  useBondPositionFields,
} from '~src/hooks/fields/workspace/project/holding/use-bond-position-fields';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { TableComponent, TableField, TableOperations } from '@pladdenico/table';

import { BondPositionsToolbar } from './bond-positions-toolbar.component';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  holding: HoldingState;
  bondHolding: BondHoldingState;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function bondPositionsToData(bondPositions: BondPositionState[]) {
  return bondPositions;
}

export const BondPositionsComponent = React.memo((props: Props) => {
  const bondPositions = useSelector((state: RootState) =>
    getBondPositionsByBondHoldingId(
      selectWorkspaceData(state),
      props.bondHolding.id,
    ),
  );
  const { classes } = useStyles();
  const [data, setData] = React.useState<BondPositionData[]>(
    bondPositionsToData(bondPositions),
  );
  const { onRowUpdate, onRowDelete } = useBondPositionTableActions(
    props.workspace,
  );
  const fields = useBondPositionFields(
    props.workspace.tenantId,
    props.workspace,
    props.projectId,
    props.holding.id,
  );

  React.useEffect(() => {
    setData(bondPositionsToData(bondPositions));
  }, [bondPositions]);

  const lastBondPositionIdx = bondPositions.reduce(
    (prevBondPosition, currentBondPosition, idx) => {
      if (
        !prevBondPosition ||
        bondPositions[idx].date.getTime() < currentBondPosition.date.getTime()
      ) {
        return idx;
      }
      return prevBondPosition;
    },
    -1,
  );

  let bondPositionCreateInputInit: graphqlWorkspaceTypes.CreateBondPositionInputType;
  if (lastBondPositionIdx !== -1) {
    const lastBondPosition = bondPositions[lastBondPositionIdx];
    bondPositionCreateInputInit = {
      id: uuid.v1(),
      date: lastBondPosition.date ?? new Date(),
      shares: lastBondPosition.shares ?? 0,
      bondHoldingId: props.bondHolding.id,
    };
  } else {
    bondPositionCreateInputInit = {
      id: uuid.v1(),
      date: new Date(),
      shares: 0,
      bondHoldingId: props.bondHolding.id,
    };
  }

  const [initialSorters] = React.useState<
    TableField.Sorter<BondPositionData>[]
  >(() => {
    return compact([
      TableOperations.createSorterFromFields(fields, 'date', 'desc'),
    ]);
  });

  const initialFilters = fields.map((field) => {
    return {
      id: field.name,
      name: field.name,
      type: TableField.FilterTypes.string,
      objId: field.id,
      query: '',
      operation: TableOperations.createIncludeFilterOperation(field.iteratee),
    };
  });

  const { filtered, filters, dispatchFilters, sorters, dispatchSorters } =
    TableOperations.useDataAlgorithms(data, initialSorters, initialFilters);
  const { isSelected, selectedData, handleSelectOne, handleSelectAll } =
    TableOperations.useSelectData(filtered);

  return (
    <div className={classes.root}>
      <BondPositionsToolbar
        workspace={props.workspace}
        bondPositionCreateInputInit={bondPositionCreateInputInit}
      />
      <div className={classes.content}>
        <TableComponent<BondPositionData>
          data={filtered}
          fields={fields}
          selectedData={selectedData}
          selectable={false}
          filters={filters}
          dispatchFilters={dispatchFilters}
          sorters={sorters}
          dispatchSorters={dispatchSorters}
          editable={{ onRowUpdate, onRowDelete }}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          isSelected={isSelected}
        />
      </div>
    </div>
  );
});
