import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseFavoriteHoldingId {
  projectId: string;
  holdingId: string;
}

export type FavoriteHoldingState = Omit<
  graphqlWorkspaceTypes.FavoriteHolding,
  'holding'
> & {
  holdingId: string;
  projectId: string;
};

export const initialFavoriteHoldingState = [];

const selectKeyId = (favoriteHolding: BaseFavoriteHoldingId) =>
  favoriteHolding.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseFavoriteHoldingId, b: BaseFavoriteHoldingId) =>
  strcmp(a.holdingId, b.holdingId);

const adapter = new EntityRecordAdapter<
  BaseFavoriteHoldingId,
  FavoriteHoldingState,
  string
>(initialFavoriteHoldingState, selectKeyId, keyComparator, tComparator);

export const favoriteHoldingsSlice = createBaseRecordSlice(
  'favoriteHoldings',
  adapter,
);
export const favoriteHoldingsSelectors = adapter.selectors;

export const favoriteHoldingsReducer = favoriteHoldingsSlice.reducer;
export const favoriteHoldingsActions = favoriteHoldingsSlice.actions;
