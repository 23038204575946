import React from 'react';
import { useDispatch } from 'react-redux';
import * as uuid from 'uuid';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { budgetsActions } from '~src/data/store/reducers/workspace/projects/scenario/budgets/reducer';
import { scenariosActions } from '~src/data/store/reducers/workspace/projects/scenario/scenarios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CreateScenarioFormComponent } from '~src/domain/workspace/components/project/scenario/form/create-form.component';
import { ScenarioInput } from '~src/domain/workspace/components/project/scenario/form/scenario-input';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { AppDispatch } from '~src/store/store';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  open: boolean;
  handleClose: () => void;
}

export const CreateScenarioComponent = React.memo((props: Props) => {
  const { workspace, project, open, handleClose } = props;

  const [scenarioInput, setScenarioInput] = React.useState<ScenarioInput>({
    projectId: project.id,
    name: '',
    variableIds: [],
  });

  const dispatch = useDispatch<AppDispatch>();

  const createScenario = React.useCallback(() => {
    const scenario = {
      id: uuid.v1(),
      budgetId: uuid.v1(),
      ...scenarioInput,
    };
    dispatch(scenariosActions.upsertOneElement(scenario));

    const budget: Budget = {
      id: scenario.budgetId,
      name: 'budgetDefault',
      scenarioId: scenario.id,
      holdingIds: [],
      transactionIds: [],
      transferIds: [],
      valuationIds: [],
    };
    dispatch(budgetsActions.upsertOneElement(budget));
    handleClose();
    return Promise.resolve();
  }, [dispatch, handleClose, scenarioInput]);

  const form = React.useMemo(() => {
    return (
      <CreateScenarioFormComponent
        scenarioInput={scenarioInput}
        projectId={project.id}
        setScenarioInput={setScenarioInput}
        workspace={workspace}
      />
    );
  }, [scenarioInput, project.id, workspace]);

  return (
    <DialogComponent dialogProps={{ open, onClose: handleClose }}>
      <DialogContentComponent
        handleOnSave={createScenario}
        title="Create scenario"
        handleClose={handleClose}
      >
        {form}
      </DialogContentComponent>
    </DialogComponent>
  );
});
