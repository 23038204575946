import { useDispatch } from 'react-redux';
import {
  createProjects,
  deleteProjects,
  updateProjects,
} from '~src/data/store/modules/workspaces/projects/base/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { ProjectData } from '~src/hooks/fields/workspace/project/use-project-fields';

// import { ProjectData } from '../../../../domain/workspace/components/project/projects.component';

export function useProjectActions(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const onRowUpdate = (oldData: ProjectData, newData: ProjectData) => {
    return new Promise<void>((resolve, reject) => {
      if (oldData !== newData) {
        const input: graphqlWorkspaceTypes.UpdateProjectInputType = {
          id: newData.id,
          type: newData.type,
          name: newData.name,
        };
        dispatch(updateProjects(workspace.tenantId, workspace.id, [input]))
          .then((_projects) => {
            resolve();
          })
          .catch((_error) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const onRowDelete = (oldData: ProjectData) => {
    return new Promise<void>((resolve, reject) => {
      return dispatch(
        deleteProjects(workspace.tenantId, workspace.id, [oldData.id]),
      )
        .then(() => {
          resolve();
        })
        .catch((_error) => {
          reject();
        });
    });
  };

  const onCreate = (
    projectInput: graphqlWorkspaceTypes.CreateProjectInputType,
    createProjectData:
      | ((
          dispatch: AppDispatch,
          workspace: WorkspaceState,
          projectId: string,
          handleClose: () => void,
        ) => void)
      | undefined,
    handleClose: () => void,
  ) =>
    dispatch(createProjects(workspace.tenantId, workspace.id, [projectInput]))
      .then((_projects) => {
        return (
          createProjectData &&
          createProjectData(dispatch, workspace, projectInput.id, handleClose)
        );
      })
      .catch((err) => {
        console.info('FAILED', projectInput, JSON.stringify(err));
      });

  return { onRowUpdate, onRowDelete, onCreate };
}
