import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';
import * as graphqlUserTypes from '~src/services/graphql/user/client/graphql';

import { RecordState, strcmp } from '@pladdenico/common';

export interface BaseExchangeRateId {
  // id: string;
  baseCurrencyId: string;
  quoteCurrencyId: string;
}

export interface BaseExchangeRateTId {
  // id: string;
  baseCurrencyId: string;
  quoteCurrencyId: string;
  endDate: Date;
}

export type ExchangeRate = graphqlUserTypes.ExchangeRate & {
  value: number;
  startDate: Date;
  endDate: Date;
  baseCurrencyId: string;
  quoteCurrencyId: string;
};

export type ExchangeRateState = RecordState<ExchangeRate, BaseExchangeRateId>[];

export const initialState: ExchangeRateState = [];

const selectKeyId = (exchangeRate: BaseExchangeRateId) => exchangeRate;

const keyComparator = (a: BaseExchangeRateId, b: BaseExchangeRateId) => {
  const baseCurrencyCmp = strcmp(a.baseCurrencyId, b.baseCurrencyId);
  if (baseCurrencyCmp !== 0) {
    return baseCurrencyCmp;
  }
  return strcmp(a.quoteCurrencyId, b.quoteCurrencyId);
};

const tComparator = (a: BaseExchangeRateTId, b: BaseExchangeRateTId) => {
  // return strcmp(a.id, b.id);
  const endDateCmp = b.endDate.getTime() - a.endDate.getTime();
  return endDateCmp;
};

const adapter = new EntityRecordAdapter<
  BaseExchangeRateTId,
  ExchangeRate,
  BaseExchangeRateId
>(initialState, selectKeyId, keyComparator, tComparator);

export const exchangeRatesSlice = createBaseRecordSlice(
  'exchangeRates',
  adapter,
);
export const exchangeRatesSelectors = adapter.selectors;

export const exchangeRatesReducer = exchangeRatesSlice.reducer;
export const exchangeRatesActions = exchangeRatesSlice.actions;
