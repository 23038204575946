import { Node, XYPosition } from 'reactflow';
import { probit } from 'simple-statistics';
import * as uuid from 'uuid';
import { NormalDistribution } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/distribution';
import { NodeType } from './nodes/node-type';
import { NodeData } from '~src/domain/workspace/components/project/scenario/holdings/forecast/element/typed/flow/flow/drag/node-data';

const initBgColor = '#1A192B';

export const createDropItem = (type: NodeType, position: XYPosition) => {
  let newNode: Node<NodeData>;
  if (type === 'initial') {
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type} node`,
        // onChange,
        color: initBgColor,
        // evaluate,
        evaluator: {
          expressions: [],
        },
      },
    };
  } else if (type === 'valuation') {
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type} node`,
        // onChange,
        color: initBgColor,
        // evaluate,
      },
    };
  } else if (type === 'transfer') {
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type} node`,
        // onChange,
        color: initBgColor,
        // evaluate,
      },
    };
  } else if (type === 'stockPrice') {
    const distribution: NormalDistribution = {
      mean: 0,
      stddev: 0.2,
      type: 'normal',
    };
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type} node`,
        // onChange,
        // state: evaluatorState,
        distribution,
        evaluate: (node: Node) => {
          console.log(node);
          const initialStockPrice = 50;
          const distribution = node.data.distribution;
          const rand = Math.random();
          const value =
            probit(rand) * distribution.stddev +
            distribution.mean +
            initialStockPrice;
          console.log('VALUE', value);
          return 9;
        },
      },
    };
  } else if (type === 'variable') {
    const distribution: NormalDistribution = {
      mean: 0,
      stddev: 0.2,
      type: 'normal',
    };
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type}`,
        // value: 0,
        // onChange,
        // state: evaluatorState,
        variableId: undefined,
        distribution,
        evaluate: (node: Node) => {
          console.log(node);
          return 9;
        },
        evaluator: {
          expressions: [],
        },
      },
    };
  } else if (type === 'inputType') {
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type}`,
        // value: 0,
        // onChange,
        // state: evaluatorState,
        variableId: undefined,
        evaluate: (node: Node) => {
          console.log(node);
          return 9;
        },
        evaluator: {
          expressions: [],
        },
      },
    };
  } else if (type === 'output') {
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type}`,
        // value: 0,
        // onChange,
        // state: evaluatorState,
        variableId: undefined,
        evaluate: (node: Node) => {
          console.log(node);
          return 9;
        },
        evaluator: {
          expressions: [],
        },
      },
    };
  } else {
    newNode = {
      id: uuid.v1(),
      type,
      position,
      data: {
        label: `${type} node`,
        // evaluate,
      },
    };
  }
  return newNode;
};
