import moment from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { DataState } from '~src/data/store/reducers/reducers';
import { getMapper } from '~src/data/store/selectors/holding/filter/filter-mapper';

import { filterFor, Filter } from '~src/utils/common/filter';

interface Props {
  workspaceId: string;
  projectId: string;
  holdings: HoldingState[];
  date: moment.Moment;
  filter: Filter;
}

export const getFilteredHoldings = createCachedSelector(
  (state: DataState, props: Props) =>
    getMapper(state, {
      date: props.date,
      workspaceId: props.workspaceId,
      projectId: props.projectId,
    }),
  // getMapper(state, {
  //   date: props.date,
  //   workspaceId: props.workspaceId,
  //   projectId: props.projectId,
  // }),
  (_state: DataState, props: Props) => props.holdings,
  (_state: DataState, props: Props) => props.filter,
  (mapper, holdings, filter) => {
    return holdings.filter((holding) => {
      const filtered = filterFor(filter, holding, mapper);
      return filtered;
    });
  },
)({
  keySelector: (_state, props) =>
    `${props.workspaceId}:${props.projectId}:${props.date.unix()}`,
  selectorCreator: createSelector,
});
