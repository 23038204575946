import { RouteObject } from 'react-router-dom';
import { HomeComponent } from '~src/domain/home/components/home.component';

export const useRoot = (): RouteObject[] => {
  return [
    {
      path: '*',
      element: <HomeComponent />,
    },
  ];
};
