import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';
import { combineReducers } from 'redux';
import { strcmp } from '@pladdenico/common';

export interface BaseExportId {
  id: string;
}

export type ExportState = {
  id: string;
  blob: string;
  // blobs: {
  //   name: string;
  //   // blob: Workspace[];
  // }[];
};

export function exportToExportState(exp: ExportState) {
  return exp;
}
export const initialExportsState = [];

const comparator = (a: BaseExportId, b: BaseExportId) => strcmp(a.id, b.id);
const merger = (_a: ExportState, b: ExportState) => b;

const adapter = new EntityArrayAdapter<BaseExportId, ExportState>(
  initialExportsState,
  comparator,
  merger,
);

export const exportsSlice = createBaseArraySlice('exports', adapter);
export const exportsSelectors = adapter.selectors;

export const exportsReducer = exportsSlice.reducer;
export const exportsActions = exportsSlice.actions;

export const exportInitialState = {
  exports: initialExportsState,
};

export const exportReducer = combineReducers({
  exports: exportsReducer,
});
