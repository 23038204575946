import isHotkey from 'is-hotkey';
import React from 'react';
import {
  BaseEditor,
  BaseSelection,
  createEditor,
  Descendant,
  Transforms,
} from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { useAlignToggleButton } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/slate/use-align-button.component';
import { useBlockToggleButton } from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/slate/use-block-button.component';
import {
  toggleMark,
  useMarkToggleButton,
} from '~src/domain/workspace/components/project/portfolio/dashboard/elements/text-editor/slate/use-mark-button.component';
import { usePopover } from '~src/hooks/utils/use-popover.hook';

import FormatCodeIcon from '@mui/icons-material/Code';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatUnderlineIcon from '@mui/icons-material/FormatUnderlined';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

export type FormattedText = {
  text: string;
  bold?: true;
  italic?: true;
  code?: true;
  underline?: true;
};
export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;
export type CustomText = FormattedText;

export type BlockFormat =
  | 'paragraph'
  | 'heading-one'
  | 'heading-two'
  | 'list-item'
  | 'block-quote'
  | 'numbered-list'
  | 'bulleted-list';

export type TextAlignTypes = 'left' | 'center' | 'right' | 'justify';
export type TextMarkTypes = 'bold' | 'italic' | 'code' | 'underline';

export type ParagraphElement = {
  type: 'paragraph';
  children: CustomText[];
  align?: TextAlignTypes;
};
export type HeadingOneElement = {
  type: 'heading-one';
  children: CustomText[];
  align?: TextAlignTypes;
};
export type HeadingTwoElement = {
  type: 'heading-two';
  children: CustomText[];
  align?: TextAlignTypes;
};
export type BlockQuoteElement = {
  type: 'block-quote';
  children: CustomText[];
  align?: TextAlignTypes;
};
export type ListItemElement = {
  type: 'list-item';
  children: CustomText[];
  align?: TextAlignTypes;
};
export type NumberedListElement = {
  type: 'numbered-list';
  children: CustomText[];
  align?: TextAlignTypes;
};
export type BulletedListElement = {
  type: 'bulleted-list';
  children: CustomText[];
  align?: TextAlignTypes;
};

export type CustomElement =
  | ParagraphElement
  | HeadingOneElement
  | HeadingTwoElement
  | BlockQuoteElement
  | ListItemElement
  | NumberedListElement
  | BulletedListElement;
// | LeftElement
// | CenterElement
// | RightElement
// | JustifyElement;
declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export const HOTKEYS: Record<string, TextMarkTypes> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const initialDefaultValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const onKeyDown = (editor: CustomEditor) => {
  let hotkey: keyof typeof HOTKEYS;
  for (hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event as any)) {
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
};

interface Props {
  update: (data: string) => Promise<{ id: string }>;
  dataAsString: string | undefined;
  readOnly?: boolean;
}

const serialize = (value: Descendant[]) => {
  return JSON.stringify(value);
};

const deserialize = (dataAsString: string | undefined) => {
  if (dataAsString == null) {
    return null;
  }
  return JSON.parse(dataAsString);
};

export const SlateEditor = React.memo((props: Props) => {
  const { update, dataAsString, readOnly } = props;

  const dataRef = React.useRef(dataAsString);
  const initialValue = React.useMemo(
    () => deserialize(dataRef.current) || initialDefaultValue,
    [],
  );

  React.useEffect(() => {
    if (dataRef.current !== dataAsString) {
      dataRef.current = dataAsString;
      // setKey(`key-${uniqueId()}`);
    }
  }, [dataAsString]);
  const onChange = (value: Descendant[]) => {
    const valueAsString = serialize(value);
    update(valueAsString).then(() => {
      dataRef.current = valueAsString;
    });
  };

  const renderElement = React.useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    [],
  );
  const renderLeaf = React.useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    [],
  );
  const editor = React.useMemo(
    () => withHistory(withReact(createEditor())),
    [],
  );

  const headingMenu = usePopover();

  const boldButton = useMarkToggleButton('bold', <FormatBoldIcon />);
  const italicButton = useMarkToggleButton('italic', <FormatItalicIcon />);
  const underlineButton = useMarkToggleButton(
    'underline',
    <FormatUnderlineIcon />,
  );
  const codeButton = useMarkToggleButton('code', <FormatCodeIcon />);

  const headingOne = useBlockToggleButton(
    'heading-one',
    <Typography>H1</Typography>,
  );
  const headingTwo = useBlockToggleButton(
    'heading-two',
    <Typography>H2</Typography>,
  );
  const blockQuote = useBlockToggleButton('block-quote', <FormatQuoteIcon />);
  const numberedList = useBlockToggleButton(
    'numbered-list',
    <FormatListNumberedIcon />,
  );
  const bulletedList = useBlockToggleButton(
    'bulleted-list',
    <FormatListBulletedIcon />,
  );

  const leftAlign = useAlignToggleButton('left', <FormatAlignLeftIcon />);
  const centerAlign = useAlignToggleButton('center', <FormatAlignCenterIcon />);
  const rightAlign = useAlignToggleButton('right', <FormatAlignRightIcon />);
  const justifyAlign = useAlignToggleButton(
    'justify',
    <FormatAlignJustifyIcon />,
  );

  const [selection, setSelection] = React.useState<BaseSelection>(null);

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => 'set_selection' !== op.type,
        );
        if (isAstChange) {
          onChange(value);
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          {boldButton.button}
          {italicButton.button}
          {underlineButton.button}
          {codeButton.button}
          <IconButton
            sx={{ borderRadius: 0 }}
            onMouseDown={(event) => {
              console.log(editor.selection);
              if (editor.selection) {
                setSelection({ ...editor.selection });
              }
              headingMenu.open(event);
            }}
          >
            <FormatSizeIcon />
            <ExpandMoreIcon sx={{ fontSize: 16 }} />
          </IconButton>
          <Menu
            open={headingMenu.isOpen}
            anchorEl={headingMenu.anchorEl}
            onClose={() => {
              headingMenu.close();
              console.log(selection);
              if (selection != null) {
                ReactEditor.focus(editor);
                Transforms.setSelection(editor, {
                  ...selection,
                });
              }
            }}
          >
            <MenuItem disableRipple>{headingOne.button}</MenuItem>
            <MenuItem disableRipple>{headingTwo.button}</MenuItem>
          </Menu>
          {blockQuote.button}
          {numberedList.button}
          {bulletedList.button}
          {leftAlign.button}
          {centerAlign.button}
          {rightAlign.button}
          {justifyAlign.button}
        </Box>
        <Editable
          // disableDefaultStyles
          style={{
            marginLeft: 8,
            marginRight: 8,
            outline: 'unset',
            flexGrow: 1,
            position: 'relative',
          }}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Write text here..."
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            let hotkey: keyof typeof HOTKEYS;
            for (hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Box>
    </Slate>
  );
});

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'paragraph':
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
