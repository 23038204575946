// import { ValuationType } from '@pladdenico/models';
import { Maybe } from 'graphql/jsutils/Maybe';
// import moment from 'moment';
import { upsertValuations } from '~src/data/store/modules/holdings/valuation/valuations/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'valuations';

export interface Valuation {
  // description?: Maybe<string>;
  // name?: Maybe<string>;
  // type?: Maybe<string>;
  // projectId?: Maybe<string>;

  id: string;
  currencyId: string;
  date?: Maybe<Date>;
  value?: Maybe<number>;
  ownershipPercentage?: Maybe<number>;
  status?: Maybe<number>;
  holdingId: string;
}

const header: Array<keyof Valuation> = [
  'id',
  'currencyId',
  'date',
  'value',
  'ownershipPercentage',
  'status',
  'holdingId',
];

export const valuationExportHandler = (
  data: Valuation[],
): ExportHandler<Valuation> => {
  const rows = data.map((valuation) => {
    return {
      id: valuation.id,
      currencyId: valuation.currencyId,
      date: valuation.date,
      // date: moment(holding.date).format('DD.MM.YY'),
      value: valuation.value,
      ownershipPercentage: valuation.ownershipPercentage,
      status: valuation.status,
      holdingId: valuation.holdingId,
    };
  });
  return {
    type: name,
    data: outputPicker(rows, header),
    header,
  };
};

export const valuationImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Valuation> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertValuations(workspace.tenantId, workspace.id, ts)),
  };
};
