import { Maybe } from 'graphql/jsutils/Maybe';
import { compact, flatten } from 'lodash';
import moment from 'moment';
import { Store, Unsubscribe } from 'redux';
import { handleExchangeRates } from '~src/data/store/modules/exchange-rates/handler';
import { fetchExchangeRates } from '~src/data/store/modules/exchange-rates/requests';
import { getValuations } from '~src/data/store/selectors/holding/valuation/valuations/selectors';
import { ExchangeRate } from '~src/services/graphql/user/client/graphql';

import { defaultCurrency } from '../modules/currencies/default-currency';
import { TransferState } from '../reducers/holding/transfer/transfers/reducer';
import { ValuationState } from '../reducers/holding/valuation/valuations/reducer';
import { RootState } from '../reducers/reducers';
import { getSelectedCurrencyState } from '../selectors/finance/currencies/selected/selectors';
import { getTransfers } from '../selectors/holding/transfer/transfers/selectors';
import {
  selectFinancialData,
  selectWorkspaceData,
} from '../selectors/selectors';
import { CurrencyStorage } from './currency-storage';

interface CurrencyTimeSpan {
  baseCurrencyId: string;
  quoteCurrencyId: string;
  startDate: Date;
  endDate: Date;
}

export type currencyTimeSpans = Map<string, CurrencyTimeSpan>;

export class CurrencyListener {
  private unsubscribeExchangeRate: Unsubscribe;

  private valuations: ValuationState[];
  private transfers: TransferState[];
  private baseCurrencyId: string;
  private frequency: string;
  private currencyTimeSpans: currencyTimeSpans;

  constructor(private store: Store<RootState>) {
    this.store = store;

    this.valuations = [];
    this.transfers = [];

    this.baseCurrencyId =
      getSelectedCurrencyState(selectFinancialData(this.store.getState())) ??
      defaultCurrency.id;
    this.frequency = 'B';

    this.currencyTimeSpans = this.handleExchangeRates();
    // const currencyTimeSpans = CurrencyStorage.getCurrencyTimeSpans();
    // if (currencyTimeSpans) {
    //   this.fetchExchangeRates(currencyTimeSpans);
    // }

    this.unsubscribeExchangeRate = store.subscribe(this.handleExchangeRates);
  }

  public unsubscribe() {
    this.unsubscribeExchangeRate();
  }

  private getCurrencyTimeSpans = (
    valuations: ValuationState[],
    transfers: TransferState[],
    baseCurrencyId: string,
  ): currencyTimeSpans => {
    let currencyTimeSpans = new Map<
      string,
      {
        baseCurrencyId: string;
        quoteCurrencyId: string;
        startDate: Date;
        endDate: Date;
      }
    >();
    // console.log(assets);
    // if (assets !== this.assets || baseCurrency !== this.baseCurrency) {
    //   currencyTimeSpans = CurrencyListener.currencyAssetsTimeSpans(assets, currencyTimeSpans, baseCurrency);
    // }
    if (
      valuations !== this.valuations ||
      baseCurrencyId !== this.baseCurrencyId
    ) {
      currencyTimeSpans = CurrencyListener.currencyValuationsTimeSpans(
        valuations,
        currencyTimeSpans,
        baseCurrencyId,
      );
    }
    if (
      transfers !== this.transfers ||
      baseCurrencyId !== this.baseCurrencyId
    ) {
      currencyTimeSpans = CurrencyListener.currencyTransfersTimeSpans(
        transfers,
        currencyTimeSpans,
        baseCurrencyId,
      );
    }
    return currencyTimeSpans;
  };

  private handleExchangeRates = (): currencyTimeSpans => {
    const state = this.store.getState();
    const valuations = getValuations(selectWorkspaceData(state));
    const transfers = getTransfers(selectWorkspaceData(state));
    const baseCurrencyId = getSelectedCurrencyState(selectFinancialData(state));

    const currencyTimeSpans = this.getCurrencyTimeSpans(
      valuations,
      transfers,
      baseCurrencyId ?? defaultCurrency.id,
    );

    this.valuations = getValuations(selectWorkspaceData(state));
    this.transfers = getTransfers(selectWorkspaceData(state));
    this.baseCurrencyId =
      getSelectedCurrencyState(selectFinancialData(state)) ??
      defaultCurrency.id;

    this.fetchExchangeRates(currencyTimeSpans);
    return currencyTimeSpans;
  };

  private fetchExchangeRates(currencyTimeSpans: currencyTimeSpans) {
    const exchangeRatePromises: Array<Promise<Maybe<ExchangeRate[]>>> = [];
    currencyTimeSpans.forEach((value, key) => {
      if (
        value.baseCurrencyId !== this.baseCurrencyId ||
        value.quoteCurrencyId !== this.baseCurrencyId
      ) {
        let startDate = moment(value.startDate);
        let endDate = moment(value.endDate);
        let fetch = true;
        if (this.currencyTimeSpans) {
          const existingTimeSpan = this.currencyTimeSpans.get(key);
          if (existingTimeSpan) {
            // do not have gaps
            const existingStartDate = moment(existingTimeSpan.startDate);
            const existingEndDate = moment(existingTimeSpan.endDate);
            if (existingStartDate <= startDate && endDate <= existingEndDate) {
              fetch = false;
            } else {
              if (existingEndDate < startDate) {
                startDate = existingEndDate.subtract(30, 'd');
                // existingTimeSpan.endDate = endDate.toDate();
              }
              if (endDate < existingStartDate) {
                endDate = existingStartDate.add(30, 'd');
                // existingTimeSpan.startDate = startDate.toDate();
              }
              existingTimeSpan.startDate = startDate.toDate();
              existingTimeSpan.endDate = endDate.toDate();
              this.currencyTimeSpans.set(key, existingTimeSpan);
            }
          } else {
            this.currencyTimeSpans.set(key, value);
          }
        }
        if (fetch) {
          if (this.currencyTimeSpans) {
            CurrencyStorage.setCurrencyTimeSpans(this.currencyTimeSpans);
          }

          exchangeRatePromises.push(
            fetchExchangeRates(
              value.baseCurrencyId,
              value.quoteCurrencyId,
              startDate.toDate(),
              endDate.toDate(),
              this.frequency,
            ),
          );
          // fetchExchangeRates(
          //   value.baseCurrencyId,
          //   value.quoteCurrencyId,
          //   startDate.toDate(),
          //   endDate.toDate(),
          //   this.frequency
          // )(this.store.dispatch);
        }
      }
    });
    Promise.all(exchangeRatePromises).then((exchangeRates) => {
      const flattened = flatten(compact(exchangeRates));
      if (flattened.length > 0) {
        handleExchangeRates(this.store.dispatch, flattened);
      }
    });
  }

  private static updateTimeSpan = (
    baseCurrencyId: string,
    quoteCurrencyId: string,
    timeSpan: CurrencyTimeSpan | undefined,
    date: Date,
  ) => {
    if (!timeSpan) {
      timeSpan = {
        baseCurrencyId,
        quoteCurrencyId,
        startDate: date,
        endDate: date,
      };
    } else {
      if (date.getTime() < timeSpan.startDate.getTime()) {
        timeSpan.startDate = date;
      }
      if (timeSpan.endDate.getTime() < date.getTime()) {
        timeSpan.endDate = date;
      }
    }
    return timeSpan;
  };

  private static updateTimeSpans = (
    timeSpans: currencyTimeSpans,
    date: Date,
    baseCurrencyId: string,
    currencyId: string,
  ) => {
    const key = `${baseCurrencyId}.${currencyId}`;
    let timeSpan = timeSpans.get(key);
    timeSpan = CurrencyListener.updateTimeSpan(
      baseCurrencyId,
      currencyId,
      timeSpan,
      date,
    );
    timeSpans.set(key, timeSpan);
    return timeSpans;
  };

  // private static currencyAssetsTimeSpans(assets: Asset[], timeSpans, baseCurrency: Currency): currencyTimeSpans {
  //   return assets.reduce(
  //     (previousTimeSpans: currencyTimeSpans, asset: Asset): currencyTimeSpans => {
  //       previousTimeSpans = CurrencyListener.currencyValuationsTimeSpans(asset.valuations, previousTimeSpans,
  //         baseCurrency);
  //       previousTimeSpans = CurrencyListener.currencyTransfersTimeSpans(asset.transfers, previousTimeSpans,
  //         baseCurrency);
  //       return previousTimeSpans;
  //   }, timeSpans);
  // }

  private static currencyValuationsTimeSpans(
    valuations: ValuationState[],
    currencyTimeSpans: any,
    baseCurrencyId: string,
  ): currencyTimeSpans {
    return valuations.reduce(
      (
        previousTimeSpans: currencyTimeSpans,
        valuation: ValuationState,
      ): currencyTimeSpans => {
        const date = valuation.date;
        const currencyId = valuation.currencyId;
        if (date && currencyId) {
          return CurrencyListener.updateTimeSpans(
            previousTimeSpans,
            date,
            baseCurrencyId,
            currencyId,
          );
        }
        return previousTimeSpans;
      },
      currencyTimeSpans,
    );
  }

  private static currencyTransfersTimeSpans(
    transfers: TransferState[],
    currencyTimeSpans: any,
    baseCurrencyId: string,
  ): currencyTimeSpans {
    return transfers.reduce(
      (
        previousTimeSpans: currencyTimeSpans,
        transfer: TransferState,
      ): currencyTimeSpans => {
        const date = transfer.date;
        const currencyId = transfer.currencyId;
        if (date && currencyId) {
          return CurrencyListener.updateTimeSpans(
            previousTimeSpans,
            date,
            baseCurrencyId,
            currencyId,
          );
        }
        return previousTimeSpans;
      },
      currencyTimeSpans,
    );
  }
}
