import * as React from 'react';
import { useParams } from 'react-router-dom';
import { PersonComponent } from '~src/domain/workspace/components/people/person.component';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';

interface Props {
  workspace: WorkspaceState;
  // match: any;
}

export const PersonRouterWrapper = React.memo((props: Props) => {
  const { personId } = useParams<{ personId: string }>();
  // const [personId] = React.useState(params.personId);
  if (personId) {
    return <PersonComponent workspace={props.workspace} personId={personId} />;
  }
  return <div>Not a valid person Id</div>;
});
