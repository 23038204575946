import React from 'react';

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { FieldError } from '~src/utils/form/fields/field-error';

interface Input {
  type: '__input';
  value: string;
}

function isInput(value: any): value is Input {
  if (Array.isArray(value)) {
    return value.some((v) => v.type === '__input');
  }
  return value.type === '__input';
}

function findInput(value: any): Input {
  if (Array.isArray(value)) {
    return value.find((v) => v.type === '__input');
  }
  return value;
}

interface Props<T extends unknown, TS extends T | T[]> {
  value: TS | undefined;
  handleValue: (t: TS) => void;
  handleResetValue?: () => void;
  handlePersist?: () => void;
  getOptionLabel: (t: T) => string;
  // initDialog: (value: string) => void;
  // setOpen: (open: boolean) => void;
  label?: string;
  options: T[];
  optionDisabled?: (t: T) => boolean;
  // dialogElement?: JSX.Element;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  // addOption?: boolean;
  addOption?: {
    initDialog: (value: string) => void;
    setOpen: (open: boolean) => void;
    dialogElement?: JSX.Element;
  };
  multiple: boolean;
  error?: FieldError;
}

const filter = <T extends unknown>() => createFilterOptions<T | Input>();

export const EditAutocomplete = <T extends unknown, TS extends T | T[]>(
  props: Props<T, TS>,
) => {
  const {
    handleValue,
    handleResetValue,
    getOptionLabel,
    addOption,
    multiple,
    error,
  } = props;

  const isOptionEqualToValue = React.useMemo(() => {
    const isOptionEqualToValue = props.isOptionEqualToValue;
    if (isOptionEqualToValue === undefined) {
      return undefined;
    }
    return (option: T | Input, value: T | Input) => {
      if (isInput(option) || isInput(value)) {
        return false;
      }
      return isOptionEqualToValue(option, value);
    };
  }, [props]);

  return (
    <>
      <Autocomplete<T | Input, boolean>
        multiple={multiple}
        options={props.options}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionDisabled={(option) => {
          if (isInput(option) || !props.optionDisabled) {
            return false;
          }
          return props.optionDisabled(option);
        }}
        autoSelect
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter<T>()(options, params);

          // Suggest the creation of a new value
          if (addOption != null && params.inputValue !== '') {
            filtered.push({
              type: '__input',
              value: params.inputValue,
            });
          }

          return filtered;
        }}
        getOptionLabel={(t) => {
          if (isInput(t)) {
            return `Add "${t.value}"`;
          }
          return getOptionLabel(t);
        }}
        // style={{ width: 300 }}
        style={{ flex: 1 }}
        value={props.value ? props.value : null}
        onChange={(
          _event: React.ChangeEvent<unknown>,
          newValue: any | Input | null,
        ) => {
          if (newValue) {
            if (addOption != null && isInput(newValue)) {
              addOption.setOpen(true);
              const input: Input = findInput(newValue);
              addOption.initDialog(input.value);
            } else {
              handleValue(newValue);
            }
          } else if (handleResetValue != null) {
            handleResetValue();
          }
        }}
        onBlur={() => {
          props.handlePersist && props.handlePersist();
        }}
        renderInput={(params: AutocompleteRenderInputParams) => {
          // console.log('errorMessage', error.em);
          return (
            <TextField
              {...params}
              label={props.label}
              // required
              fullWidth
              required
              margin="dense"
              helperText={error?.message}
              // error={!!errorMessage}
              error={!!error}
            />
          );
        }}
      />
      {addOption != null && addOption.dialogElement}
    </>
  );
};
