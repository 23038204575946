import React from 'react';
import { useDispatch } from 'react-redux';
import { updatePhones } from '~src/data/store/modules/people/phones/requests';
import { PersonState } from '~src/data/store/reducers/person/people/reducer';
import { PhoneState } from '~src/data/store/reducers/person/phones/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

import { PhoneDialogComponent } from './dialog.component';

interface Props {
  tenantId: string;
  workspace: WorkspaceState;
  person: PersonState;
  open: boolean;
  handleClose: () => void;
  savedCallback: () => void;
  phone: PhoneState;
}

export const EditPhoneDialogComponent = React.memo((props: Props) => {
  const [phoneInput, setPhoneInput] =
    React.useState<graphqlWorkspaceTypes.UpdatePhoneInputType>({
      id: props.phone.id,
      personId: props.phone.personId,
      value: props.phone.value,
    });
  const dispatch = useDispatch<AppDispatch>();

  const handleSavePhone = (): Promise<PhoneState> => {
    return dispatch(
      updatePhones(props.tenantId, props.workspace.id, props.person.id, [
        phoneInput,
      ]),
    )
      .then((phones) => {
        return phones[0];
      })
      .catch((err) => {
        console.info('FAILED', phoneInput, JSON.stringify(err));
        throw err;
      });
  };

  return (
    <PhoneDialogComponent
      title={'Edit phone'}
      handleSavePhone={handleSavePhone}
      phoneInput={phoneInput}
      setPhoneInput={setPhoneInput}
      handleClose={props.handleClose}
      savedCallback={props.savedCallback}
      open={props.open}
    />
  );
});
