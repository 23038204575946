import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { AppDispatch } from '~src/store/store';

export type Fetcher = (
  workspace: WorkspaceState,
  id: string,
) => (dispatch: AppDispatch) => void;

export const useFetcher = (workspace: WorkspaceState, dataFetcher: Fetcher) => {
  const dispatch = useDispatch<AppDispatch>();
  const [exportId, setExportId] = React.useState('');

  const fetcher = React.useCallback(() => {
    const id = `${moment.now()}`;
    setExportId(id);

    dispatch(dataFetcher(workspace, id));
  }, [dataFetcher, dispatch, workspace]);

  return {
    exportId,
    fetcher,
  };
};
