export const HoldingsViewTypes = {
  Value: 'value',
  CashOnCashRatio: 'cashOnCashRatio',
  CashFlow: 'cashFlow',
  Irr: 'irr',
  ValueChange: 'valueChange',
} as const;

export type HoldingsViewType =
  (typeof HoldingsViewTypes)[keyof typeof HoldingsViewTypes];
export function typeToString(type: HoldingsViewType) {
  if (type === HoldingsViewTypes.Value) {
    return 'Value';
  } else if (type === HoldingsViewTypes.CashOnCashRatio) {
    return 'Cash on cash ratio';
  } else if (type === HoldingsViewTypes.CashFlow) {
    return 'Cash flow';
  } else if (type === HoldingsViewTypes.Irr) {
    return 'Irr';
  } else if (type === HoldingsViewTypes.ValueChange) {
    return 'Value change';
  }
  return 'undefined';
}
