import createCachedSelector from 're-reselect';
import { createSelector, weakMapMemoize } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

import { ProjectType } from '@pladdenico/models';

import {
  BaseProjectId,
  projectSelectors as projectsSelectors,
  ProjectState,
} from '../../../../reducers/workspace/projects/base/reducer';

const getProjectState = (state: WorkspaceDataState) =>
  state.workspace.project.projects;

// export const selectProjectsByWorkspaceId = createCachedSelector(
//   (state: RecordState<ProjectState, string>[], workspaceId: string) =>
//     projectsSelectors.selectElementsByKey(state, workspaceId),
//   (projects: ProjectState[] | undefined): ProjectState[] => {
//     return projects ? projects : [];
//   },
// )({
//   keySelector: (_state, workspaceId) => workspaceId,
//   selectorCreator: createSelector,
// });

export const getProjectsByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getProjectState(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (projectState, workspaceId): ProjectState[] => {
    return (
      projectsSelectors.selectElementsByKey(projectState, workspaceId) ?? []
    );
  },
)({
  keySelector: (_state, workspaceId) => workspaceId,
  selectorCreator: createSelector,
});

// export const projectsByTypeSelector = (
//   projects: Project[],
//   type: ProjectType
// ): Project[] => {
//   return projects.filter((project) => project.type === type);
// };

// export const makeGetProjectsByType = () =>
//   createSelector(projectsByTypeSelector, (projects) => {
//     return projects;
//   });

// const projectsSelector = (state: WorkspaceDataState): ProjectState[] => {
//   return state.workspace.project.projects;
// };

interface BaseProjectTypeId {
  workspaceId: string;
  type: ProjectType;
}

export const getProjectsByType = createCachedSelector(
  (state: WorkspaceDataState, baseId: BaseProjectTypeId) =>
    getProjectsByWorkspaceId(state, baseId.workspaceId),
  (_state: WorkspaceDataState, baseId: BaseProjectTypeId) => baseId.type,
  (projects, type): ProjectState[] => {
    return projects.filter((p) => p.type === type);
  },
)({
  keySelector: (_state, workspaceId, projectId) =>
    `${workspaceId}:${projectId}`,
  selectorCreator: createSelector,
});

export const getProjects = createSelector(
  (state: WorkspaceDataState) => getProjectState(state),
  (projects) => {
    return projectsSelectors.selectAllElements(projects);
  },
);

export const getProjectById = createSelector(
  (state: WorkspaceDataState) => getProjectState(state),
  (_state: WorkspaceDataState, baseId: BaseProjectId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseProjectId) => baseId.workspaceId,
  (projects, id, workspaceId): ProjectState | undefined => {
    return projectsSelectors.selectElementByT(projects, { id, workspaceId });
  },
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);

// const projectSelectorById = (
//   projects: ProjectState[],
//   projectId: string | null
// ): ProjectState | undefined => {
//   return projects.find((project) => {
//     return projectId === project.id;
//   });
// };

// export const getProjectById = createSelector(
//   projectSelectorById,
//   (project: ProjectState | undefined) => {
//     return project;
//   }
// );
