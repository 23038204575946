import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import ReactGridLayout from 'react-grid-layout';
import { CompactType } from '~src/components/dashboard/dashboard-grid.component';

export interface BasePortfolioDashboardConfigId {
  portfolioConfigId: number;
  id: string;
}
// export type PortfolioDashboardConfigState = PortfolioDashboardConfig;

export type PortfolioDashboardConfigState = Omit<
  graphqlWorkspaceTypes.PortfolioDashboardConfig,
  'portfolioDashboardConfigs' | 'elements' | 'layouts'
> & {
  portfolioConfigId: number;
  elementIds: string[];
  layouts: ReactGridLayout.Layouts | undefined;
  compactType: CompactType;
};

export const initialPortfolioDashboardConfigState = [];

const selectKeyId = (
  portfolioDashboardConfig: BasePortfolioDashboardConfigId,
) => portfolioDashboardConfig.portfolioConfigId;
const keyComparator = (a: number, b: number) => a - b;
const tComparator = (
  a: BasePortfolioDashboardConfigId,
  b: BasePortfolioDashboardConfigId,
) => strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BasePortfolioDashboardConfigId,
  PortfolioDashboardConfigState,
  number
>(
  initialPortfolioDashboardConfigState,
  selectKeyId,
  keyComparator,
  tComparator,
);

export const portfolioDashboardConfigsSlice = createBaseRecordSlice(
  'portfolioDashboardConfigs',
  adapter,
);
export const selectors = adapter.selectors;

export const portfolioDashboardConfigsReducer =
  portfolioDashboardConfigsSlice.reducer;
export const portfolioDashboardConfigsActions =
  portfolioDashboardConfigsSlice.actions;
