import React from 'react';
import { useSelector } from 'react-redux';
import * as uuid from 'uuid';
import { useCreateDialog } from '~src/components/fields/workspace/holding/holding/use-create-dialog';
import { EditAutocomplete } from '~src/components/utils/edit-autocomplete';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsByFilter } from '~src/data/store/selectors/holding/holdings/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';

import { HoldingType } from '@pladdenico/models';

interface Props {
  handleValue: (t: HoldingState) => void;
  handlePersist?: () => void;
  holding: HoldingState | undefined;
  label?: string;
  workspace: WorkspaceState;
  projectId: string;
  holdingTypes?: HoldingType[];
}

export const EditHolding = (props: Props) => {
  const holdings = useSelector((state: RootState) =>
    getHoldingsByFilter(selectWorkspaceData(state), {
      projectId: props.projectId,
      holdingTypes: props.holdingTypes,
    }),
  );
  const getOptionLabel = React.useCallback(
    (t: HoldingState) => {
      const holding = holdings.find((holding) => holding.id === t.id);
      return holding?.name ? holding.name : '';
    },
    [holdings],
  );
  const { handleValue } = props;

  const { dialogElement, setOpen, setHoldingInput } = useCreateDialog({
    handleValue,
    workspace: props.workspace,
    projectId: props.projectId,
    holdingTypes: props.holdingTypes,
  });
  const initDialog = React.useCallback(
    (value: string) => {
      setHoldingInput({
        id: uuid.v1(),
        type: HoldingType.Unknown,
        name: value,
        projectId: props.projectId,
        description: '',
      });
    },
    [props.projectId, setHoldingInput],
  );

  const isOptionEqualToValue = (option: HoldingState, value: HoldingState) => {
    return option.id === value.id;
  };

  return (
    <EditAutocomplete
      multiple={false}
      addOption={{ initDialog, setOpen, dialogElement }}
      getOptionLabel={getOptionLabel}
      label={props.label}
      handleValue={handleValue}
      options={holdings}
      value={props.holding}
      handlePersist={props.handlePersist}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};
