import { createBaseRecordSlice } from '~src/data/base/record/base-record-slice';
import { EntityRecordAdapter } from '~src/data/base/record/record-entity-adapter';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseGeneratedReportId {
  projectId: string;
  id: string;
}

export type GeneratedReportState = Omit<
  graphqlWorkspaceTypes.GeneratedReport,
  'project' | 'holding' | 'report'
> & {
  reportId: string;
  projectId: string;
  holdingId?: string;
};

export const initialGeneratedReportState = [];

const selectKeyId = (report: BaseGeneratedReportId) => report.projectId;
const keyComparator = (a: string, b: string) => strcmp(a, b);
const tComparator = (a: BaseGeneratedReportId, b: BaseGeneratedReportId) =>
  strcmp(a.id, b.id);

const adapter = new EntityRecordAdapter<
  BaseGeneratedReportId,
  GeneratedReportState,
  string
>([], selectKeyId, keyComparator, tComparator);

export const generatedReportsSlice = createBaseRecordSlice(
  'generated_reports',
  adapter,
);
export const selectors = adapter.selectors;

export const generatedReportsReducer = generatedReportsSlice.reducer;
export const generatedReportsActions = generatedReportsSlice.actions;
