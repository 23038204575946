import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { store } from '~src/store/store';
import { getLastDate, getPreviousDate } from '~src/utils/common/date-utils';

// import { RecordState } from '@pladdenico/common';
import {
  BaseStockPositionId,
  stockPositionsSelectors as stockPositionsSelectors,
  StockPositionState,
} from '../../../../../reducers/holding/holding-types/stock/stock-position/reducer';

const getStockPositionState = (state: WorkspaceDataState) =>
  state.holding.holding.stockHolding.stockPositions;

export const getStockPositionsByStockHoldingId = createCachedSelector(
  (state: WorkspaceDataState) => getStockPositionState(state),
  (_state: WorkspaceDataState, stockHoldingId: string) => stockHoldingId,
  (stockPositionState, stockHoldingId): StockPositionState[] => {
    const stockPositions = stockPositionsSelectors.selectElementsByKey(
      stockPositionState,
      stockHoldingId,
    );
    if (stockPositions != null) {
      return stockPositions;
    }
    return [];
  },
)({
  keySelector: (_state, stockHoldingId) => stockHoldingId,
  selectorCreator: createSelector,
});

export const getStockPositions = createSelector(
  (state: WorkspaceDataState) => getStockPositionState(state),
  (stockPositions) => {
    return stockPositionsSelectors.selectAllElements(stockPositions);
  },
);

export const getStockPositionById = createCachedSelector(
  (state: WorkspaceDataState) => getStockPositionState(state),
  (_state: WorkspaceDataState, baseId: BaseStockPositionId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BaseStockPositionId) =>
    baseId.stockHoldingId,
  (stockPositions, id, stockHoldingId): StockPositionState | undefined => {
    return stockPositionsSelectors.selectElementByT(stockPositions, {
      id: id,
      stockHoldingId,
    });
  },
)({
  keySelector: (_state, stockHoldingId, stockPositionId) =>
    `${stockHoldingId}:${stockPositionId}`,
  selectorCreator: createSelector,
});

interface StockHoldingMultipleIds {
  stockHoldingId: string;
  ids: string[];
}

export const getStockPositionsByIds = createCachedSelector(
  (state: WorkspaceDataState) => getStockPositionState(state),
  (_state: WorkspaceDataState, ids: StockHoldingMultipleIds) =>
    ids.stockHoldingId,
  (_state: WorkspaceDataState, ids: StockHoldingMultipleIds) => ids.ids,
  (stockPositions, stockHoldingId, ids): StockPositionState[] => {
    const baseIds = ids.map((id) => {
      return {
        id,
        stockHoldingId,
      };
    });
    return stockPositionsSelectors.selectElementsByTs(stockPositions, baseIds);
  },
)({
  keySelector: (_state, ids) =>
    `${ids.stockHoldingId}${JSON.stringify(ids.ids)}`,
  selectorCreator: createSelector,
});

export const getLastPosition = (stockHoldingId: string) => {
  const stockPositions = getStockPositionsByStockHoldingId(
    selectWorkspaceData(store.getState()),
    stockHoldingId,
  );
  return getLastDate(stockPositions, (position) => position.date);
};

export const selectLastPosition = createCachedSelector(
  (state: WorkspaceDataState, stockHoldingId: string) =>
    getStockPositionsByStockHoldingId(state, stockHoldingId),
  (stockPositions): StockPositionState | undefined => {
    return getLastDate(stockPositions, (position) => position.date);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });

interface PreviousPositionProps {
  stockHoldingId: string;
  date: Date;
}

export const selectPreviousPosition = createCachedSelector(
  (state: WorkspaceDataState, props: PreviousPositionProps) =>
    getStockPositionsByStockHoldingId(state, props.stockHoldingId),
  (state: WorkspaceDataState, props: PreviousPositionProps) => props.date,
  (stockPositions, date): StockPositionState | undefined => {
    return getPreviousDate(stockPositions, (position) => position.date, date);
  },
)({ keySelector: (_state, id) => `${id}`, selectorCreator: createSelector });
