import { HoldingType } from '@pladdenico/models';
import React from 'react';

interface Props {
  type: HoldingType;
}

export function typeToString(type: string) {
  if (type === HoldingType.BankNote) {
    return 'BankNote';
  } else if (type === HoldingType.Bond) {
    return 'Bond';
  } else if (type === HoldingType.Crypto) {
    return 'Crypto';
  } else if (type === HoldingType.Fund) {
    return 'Fund';
  } else if (type === HoldingType.Loan) {
    return 'Loan';
  } else if (type === HoldingType.PrivateEquityFund) {
    return 'PrivateEquityFund';
  } else if (type === HoldingType.Stock) {
    return 'Stock';
  } else if (type === HoldingType.Warrant) {
    return 'Warrant';
  } else if (type === HoldingType.Unknown) {
    return 'Unknown';
  }
  return 'undefined';
}

export const ViewTypeField = React.memo((props: Props) => {
  return <>{typeToString(props.type)}</>;
});
