import { Visitable } from '~src/data/store/visitors/visitable';
import {
  SchedulerStateVisitor,
  SchedulerVisitable,
  SchedulerVisitor,
} from '~src/data/store/visitors/workspace/project/report/scheduler/scheduler-visitor';
import { parseReport } from '~src/data/store/modules/workspaces/reports/report/parser';
import {
  reportsActions,
  ReportState,
} from '~src/data/store/reducers/workspace/projects/reports/report/reducer';
import { Report } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

export interface ReportVisitor {
  visit(report: ReportVisitable): void;
  post(): void;
  schedulerVisitor: SchedulerVisitor;
}

export class ReportVisitable implements Visitable<ReportVisitor> {
  constructor(
    private _workspaceId: string,
    private _report: Report,
  ) {}
  public accept(visitor: ReportVisitor) {
    if (this._report.scheduler) {
      const schedulerVisitable = new SchedulerVisitable(
        this._workspaceId,
        this._report.scheduler,
      );
      schedulerVisitable.accept(visitor.schedulerVisitor);
    }
    visitor.visit(this);
  }

  public parse(): ReportState {
    return parseReport(this._report);
  }
}

export class ReportStateVisitor implements ReportVisitor {
  private _reports: ReportState[];
  public schedulerVisitor: SchedulerVisitor;
  constructor(private _dispatch: AppDispatch) {
    this._reports = [];
    this.schedulerVisitor = new SchedulerStateVisitor(this._dispatch);
  }
  public visit(report: ReportVisitable): void {
    this._reports.push(report.parse());
  }
  post() {
    this._dispatch(reportsActions.upsertManyElements(this._reports));
    this.schedulerVisitor.post();
  }
}
