import { TransferStatus } from '@pladdenico/models';
import React from 'react';

interface Props {
  status: TransferStatus;
}

function statusToString(status: TransferStatus) {
  if (status === TransferStatus.cancelled) {
    return 'Cancelled';
  } else if (status === TransferStatus.confirmed) {
    return 'Confirmed';
  } else if (status === TransferStatus.expected) {
    return 'Expected';
  }
  return 'undefined';
}

export const ViewStatusField = React.memo((props: Props) => {
  return <>{statusToString(props.status)}</>;
});
