import React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ForecastState } from '~src/data/store/state/workspace/project/scenario/forecast/forecast-state';
import { ScenarioHoldingForecastElementComponent } from '~src/domain/workspace/components/project/scenario/holdings/forecast-element.component';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IconButton } from '@mui/material';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  holding: HoldingState;
  scenario: Scenario;
  budget: Budget;
  forecast: ForecastState;
}

export const ScenarioHoldingForecastComponent = React.memo((props: Props) => {
  const { workspace, project, holding, forecast, scenario, budget } = props;

  const elementsVisible = useVisible();

  const accordionButton = (
    <IconButton onClick={elementsVisible.toggle}>
      {elementsVisible.isShowing ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </IconButton>
  );

  const forecastElementsElement = React.useMemo(
    () =>
      elementsVisible.isShowing && (
        <ScenarioHoldingForecastElementComponent
          workspace={workspace}
          project={project}
          holding={holding}
          scenario={scenario}
          budget={budget}
          forecast={forecast}
        />
      ),
    [
      budget,
      elementsVisible.isShowing,
      forecast,
      holding,
      project,
      scenario,
      workspace,
    ],
  );
  return (
    <>
      {/* {addForecastElement} */}
      {accordionButton}
      {forecastElementsElement}
    </>
  );
});
