import * as React from 'react';

import { TableField } from '@pladdenico/table';
import { CommitmentData } from '../commitment-data';

export function useCommitmentFields() {
  const fields = React.useMemo(() => {
    return {
      name: {
        id: 'id',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'id',
        field: 'id',
        type: TableField.FieldTypes.string,
        iteratee: (data: CommitmentData) => data.commitment.id,
      },
      description: {
        id: 'description',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'description',
        field: 'description',
        type: TableField.FieldTypes.string,
        iteratee: (data: CommitmentData) => data.commitment.description,
      },
      commitmentDate: {
        id: 'commitmentDate',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'commitmentDate',
        field: 'commitmentDate',
        type: TableField.FieldTypes.date,
        iteratee: (data: CommitmentData) => data.commitment.commitmentDate,
      },
      startDate: {
        id: 'startDate',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'startDate',
        field: 'startDate',
        type: TableField.FieldTypes.date,
        iteratee: (data: CommitmentData) => data.commitment.startDate,
      },
      endDate: {
        id: 'endDate',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'endDate',
        field: 'endDate',
        type: TableField.FieldTypes.date,
        iteratee: (data: CommitmentData) => data.commitment.endDate,
      },
      type: {
        id: 'type',
        direction: 'asc',
        sortable: true,
        filterable: true,
        sorted: false,
        name: 'type',
        field: 'type',
        type: TableField.FieldTypes.string,
        iteratee: (data: CommitmentData) => data.commitment.type,
      },
    };
  }, []);
  return fields;
}
export type CommitmentFields = ReturnType<typeof useCommitmentFields>;
