import { Operation } from '@pladdenico/portfolio-api';
import { uniq } from 'lodash';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchTenantRoles } from '~src/data/store/modules/tenant/tenant-roles/tenant-roles/requests';
import { fetchTenant } from '~src/data/store/modules/tenant/tenants/requests';
import { fetchWorkspaceRoles } from '~src/data/store/modules/workspaces/workspace-roles/workspace-roles/requests';
import { handleWorkspace } from '~src/data/store/modules/workspaces/workspaces/handler';
import { fetchWorkspaceShallowNEW } from '~src/data/store/modules/workspaces/workspaces/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { selectUserData } from '~src/data/store/selectors/selectors';
import { useRoot as useHomeRoot } from '~src/domain/home/app/use-root';
import { useRoot as useInvestorPortalRoot } from '~src/domain/portal/app/use-root';
import { useRoot as useTenantRoot } from '~src/domain/tenant/app/use-root';
import { useRoot as useUserRoot } from '~src/domain/user/app/use-root';
import { AppDispatch } from '~src/store/store';

export const useRootLoader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(
    (state: RootState) => selectUserData(state).account.user,
  );
  React.useEffect(() => {
    if (user) {
      batch(() => {
        dispatch(fetchTenantRoles()).then((tenantRoles) => {
          const tenantIds = uniq(tenantRoles.map((role) => role.tenantId));
          tenantIds.forEach(
            (tenantId) =>
              tenantId &&
              dispatch(fetchTenant(tenantId)).then((tenant) => {
                if (tenant) {
                  dispatch(fetchWorkspaceRoles(tenant.id)).then(
                    (workspaceRoles) => {
                      const workspaceIds = workspaceRoles.reduce<Set<string>>(
                        (prev, curr) => {
                          if (curr.workspaceId != null) {
                            prev.add(curr.workspaceId);
                          }
                          return prev;
                        },
                        new Set<string>(),
                      );
                      type retType = ReturnType<
                        typeof fetchWorkspaceShallowNEW
                      >;
                      const workspacePromises: retType[] = [];
                      workspaceIds.forEach((id) => {
                        workspacePromises.push(
                          fetchWorkspaceShallowNEW(tenant.id, id),
                        );
                      });
                      Promise.all(workspacePromises).then((workspace) => {
                        workspace.forEach((workspace) => {
                          if (workspace != null) {
                            return handleWorkspace(
                              dispatch,
                              tenantId,
                              workspace,
                              [
                                Operation.create,
                                Operation.delete,
                                Operation.update,
                                Operation.upsert,
                              ],
                            );
                          }
                        });
                      });
                      // dispatch(fetchWorkspaceShallow(tenant.id, id));
                    },
                  );
                }
              }),
          );
        });
      });
    }
  }, [dispatch, user]);
};

export const useRoot = () => {
  useRootLoader();
  const homeRoutes = useHomeRoot();
  const tenantRoutes = useTenantRoot();
  const userRoutes = useUserRoot();
  const investorPortalRoutes = useInvestorPortalRoot();
  // const messagesRoutes = useMessageRoot();
  return [
    ...homeRoutes,
    ...tenantRoutes,
    ...userRoutes,
    ...investorPortalRoutes,
  ];
};
