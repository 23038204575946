import { parseFundHolding } from '~src/data/store/modules/holdings/fund/parser';
import {
  FundPositionStateVisitor,
  FundPositionVisitable,
  FundPositionVisitor,
} from '~src/data/store/visitors/holding/types/fund/fund-position-visitor';
import {
  FundTradeStateVisitor,
  FundTradeVisitable,
  FundTradeVisitor,
} from '~src/data/store/visitors/holding/types/fund/fund-trade-visitor';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  fundHoldingsActions,
  FundHoldingState,
} from '~src/data/store/reducers/holding/holding-types/fund/fund-holding/reducer';
import {
  FundHolding,
  Holding,
} from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { HoldingType } from '@pladdenico/models';
import { Operation } from '@pladdenico/portfolio-api';

export const isFundHolding = (holding: Holding): holding is FundHolding => {
  return holding.holding.type === HoldingType.Fund;
};

export interface FundHoldingVisitor {
  visit(fundHolding: FundHoldingVisitable): FundHoldingState;
  post(): void;
  positionVisitor: FundPositionVisitor;
  tradeVisitor: FundTradeVisitor;
}

export class FundHoldingVisitable implements Visitable<FundHoldingVisitor> {
  constructor(private _fundHolding: FundHolding) {}
  public accept(visitor: FundHoldingVisitor) {
    if (this._fundHolding.fundPositions) {
      this._fundHolding.fundPositions.forEach((position) => {
        const positionVisitable = new FundPositionVisitable(position);
        positionVisitable.accept(visitor.positionVisitor);
      });
    }
    if (this._fundHolding.fundTrades) {
      this._fundHolding.fundTrades.forEach((trade) => {
        const tradeVisitable = new FundTradeVisitable(trade);
        tradeVisitable.accept(visitor.tradeVisitor);
      });
    }
    return visitor.visit(this);
  }

  public parse(): FundHoldingState {
    return parseFundHolding(this._fundHolding);
  }
}

export class FundHoldingStateVisitor implements FundHoldingVisitor {
  private _fundHoldings: FundHoldingState[];
  public positionVisitor: FundPositionVisitor;
  public tradeVisitor: FundTradeVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._fundHoldings = [];
    this.positionVisitor = new FundPositionStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
    this.tradeVisitor = new FundTradeStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(holding: FundHoldingVisitable): FundHoldingState {
    const fundHolding = holding.parse();
    this._fundHoldings.push(fundHolding);
    return fundHolding;
  }
  post() {
    this._dispatch(
      fundHoldingsActions.upsertManyElements(this._fundHoldings, {
        shouldAutobatch: true,
      }),
    );
    this.positionVisitor.post();
    this.tradeVisitor.post();
  }
}
