import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { CryptoHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/crypto/crypto-card.component';

import { useCryptoHoldingInfo } from './use-crypto-holding-info';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
}

export const CryptoHoldingCardContainer = React.memo((props: Props) => {
  const { cryptoHolding, crypto, asset } = useCryptoHoldingInfo(
    props.workspace.id,
    props.holding.id,
  );
  const { holding, projectId, portfolios, workspace } = props;

  if (!cryptoHolding) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <CryptoHoldingCardComponent
      asset={asset}
      holding={holding}
      portfolios={portfolios}
      projectId={projectId}
      crypto={crypto}
      cryptoHolding={cryptoHolding}
      workspace={workspace}
    />
  );
});
