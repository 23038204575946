import * as React from 'react';
import { WarrantState } from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { AssetState } from '~src/data/store/reducers/asset/assets/reducer';
import { WarrantHoldingState } from '~src/data/store/reducers/holding/holding-types/warrant/warrant-holding/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { BaseHoldingCardComponent } from '~src/domain/workspace/components/project/portfolio/holdings/card/base-holding-card.component';
import { useActions } from '~src/domain/workspace/components/project/portfolio/holdings/holding-types/warrant/use-actions';

import { Typography } from '@mui/material';

import { EditDialogComponent } from '../../../../holding/holding-types/warrant-holdings/form/edit-dialog.component';

interface Props {
  holding: HoldingState;
  workspace: WorkspaceState;
  projectId: string;
  portfolios: PortfolioState[];
  warrantHolding: WarrantHoldingState;
  warrant: WarrantState | undefined;
  asset: AssetState | undefined;
}

export const WarrantHoldingCardComponent = React.memo((props: Props) => {
  const {
    workspace,
    projectId,
    holding,
    portfolios,
    warrantHolding,
    warrant,
    asset,
  } = props;

  const getEditDialogComponent = React.useCallback(
    (editing: boolean, handleClose: () => void) => {
      return (
        <EditDialogComponent
          open={editing}
          handleClose={handleClose}
          holding={holding}
          warrantHolding={warrantHolding}
          workspace={workspace}
          projectId={projectId}
          portfolios={portfolios}
        />
      );
    },
    [holding, portfolios, projectId, workspace, warrantHolding],
  );

  const { deleteHolding } = useActions(
    workspace,
    projectId,
    holding,
    warrantHolding,
  );

  const warrantString = warrant ? `${warrant.description}` : undefined;
  const detailElement = warrantString ? (
    <Typography variant="subtitle2">{warrantString}</Typography>
  ) : (
    <></>
  );

  return (
    <BaseHoldingCardComponent
      asset={asset}
      projectId={projectId}
      assetDetailElement={detailElement}
      getDialogComponent={getEditDialogComponent}
      holding={holding}
      portfolios={portfolios}
      workspace={workspace}
      deleteHolding={deleteHolding}
    />
  );
});
