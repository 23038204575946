import { parseDashboardElement } from '~src/data/store/modules/common/dashboard/element/parser';
import { subscribeToDashboardElements } from '~src/data/store/modules/common/dashboard/element/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  dashboardElementsActions,
  DashboardElementState,
} from '~src/data/store/reducers/common/dashboard/elements/reducer';
import { DashboardElement } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { Operation } from '@pladdenico/portfolio-api';
import {
  DashboardMediaStateVisitor,
  DashboardMediaVisitable,
  DashboardMediaVisitor,
} from '~src/data/store/visitors/common/dashboard/media-visitor';

export interface DashboardElementVisitor {
  visit(element: DashboardElementVisitable): void;
  post(): void;
  mediaVisitor: DashboardMediaVisitor;
}

export class DashboardElementVisitable
  implements Visitable<DashboardElementVisitor>
{
  constructor(private _element: DashboardElement) {}
  public accept(visitor: DashboardElementVisitor) {
    visitor.visit(this);
    if (this._element.media) {
      const mediaVisitable = new DashboardMediaVisitable(
        this._element.id,
        this._element.media,
      );
      mediaVisitable.accept(visitor.mediaVisitor);
    }
  }

  public parse(): DashboardElementState {
    const holdingDashboardElement = this._element;
    return parseDashboardElement(holdingDashboardElement);
  }
}

export class DashboardElementStateVisitor implements DashboardElementVisitor {
  private _dashboardElements: DashboardElementState[];
  mediaVisitor: DashboardMediaVisitor;
  constructor(
    private _dispatch: AppDispatch,
    private _tenantId: string,
    private _workspaceId: string,
    private _subscriptions: Operation[],
  ) {
    this._dashboardElements = [];
    this.mediaVisitor = new DashboardMediaStateVisitor(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._subscriptions,
    );
  }
  public visit(holdingDashboardElement: DashboardElementVisitable): void {
    this._dashboardElements.push(holdingDashboardElement.parse());
  }

  post() {
    this._dispatch(dashboardElementsActions.addMany(this._dashboardElements));
    subscribeToDashboardElements(
      this._dispatch,
      this._tenantId,
      this._workspaceId,
      this._dashboardElements,
      this._subscriptions,
    );
    this.mediaVisitor.post();
  }
}
