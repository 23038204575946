import { Button } from '@mui/material';

interface Props {
  isVisible: boolean;
  toggleConfig: () => void;
  text: string;
  variant: 'text' | 'outlined' | 'contained';
}

export const ConfigButtonComponent = (props: Props) => {
  const { toggleConfig, isVisible, text, variant } = props;
  return (
    <Button
      variant={variant}
      color="primary"
      onClick={() => toggleConfig()}
      sx={{
        visibility: isVisible ? 'hidden' : 'visible',
        mr: 1,
        textDecoration: 'uppercase',
      }}
    >
      {text}
    </Button>
  );
};
