import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
// import * as uuid from 'uuid';
import { ProgressComponent } from '~src/components/utils/progress.component';
import { fetchPortfolioConfigs } from '~src/data/store/modules/portfolio-configs/portfolio-configs/requests';
import { selectPortfolio } from '~src/data/store/modules/portfolios/portfolios/requests';
import { PortfolioState } from '~src/data/store/reducers/portfolio/portfolios/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { PortfolioProjectState } from '~src/data/store/reducers/workspace/projects/portfolio-project/portfolio-projects/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
// import { getPortfolioConfigsByPortfolioId } from '~src/data/store/selectors/portfolio/portfolio-config/portfolio-configs/selectors';
import { getPortfolioById } from '~src/data/store/selectors/portfolio/portfolios/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getProjectById } from '~src/data/store/selectors/workspace/projects/base/selectors';
import { getPortfolioProjectByProjectId } from '~src/data/store/selectors/workspace/projects/portfolio-project/portfolio-projects/selectors';
import { EventRouterWrapper } from '~src/domain/workspace/components/project/event/routing/root';
import { EntityRouterWrapper } from '~src/domain/workspace/components/project/portfolio/entities/root';
import { HoldingRouterWrapper } from '~src/domain/workspace/components/project/portfolio/holdings/root';
import { ProjectReportRouterWrapper } from '~src/domain/workspace/components/project/portfolio/reporting/project-report/routing/root';
import { ScenarioRouterWrapper } from '~src/domain/workspace/components/project/scenario/routing/root';
import { TransactionRouterWrapper } from '~src/domain/workspace/components/project/transaction/root';
// import { usePortfolioConfigActions } from '~src/hooks/actions/workspace/project/portfolio/portfolio-config/use-portfolio-config-actions.hook';
import { portfolioProjectTabPath } from '~src/navigation/paths/workspace/project/portfolio-project/paths';
import { AppDispatch } from '~src/store/store';

// import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { PortfolioProjectTabs, TabsComponent } from './tabs.component';
import { usePortfolioProjectTabs } from './use-tabs';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
}

interface PropsWithPortfolioProject extends Props {
  portfolioProject: PortfolioProjectState;
}

interface PropsWithPortfolioProjectAndPortfolio
  extends PropsWithPortfolioProject {
  portfolio: PortfolioState;
}

export const PortfolioProjectRouterWrapper = React.memo((props: Props) => {
  const portfolioProject = useSelector((state: RootState) =>
    getPortfolioProjectByProjectId(
      selectWorkspaceData(state),
      props.workspace.id,
      props.project.id,
    ),
  );

  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (portfolioProject) {
      dispatch(selectPortfolio(portfolioProject.portfolioId));
    }
  }, [
    dispatch,
    portfolioProject,
    props.project.id,
    props.workspace.id,
    props.workspace.tenantId,
  ]);
  if (!portfolioProject) {
    return <ProgressComponent />;
  }
  return (
    <PortfolioProjectRouterWrapperWithPortfolioProject
      {...props}
      portfolioProject={portfolioProject}
    />
  );
});

const PortfolioProjectRouterWrapperWithPortfolio = React.memo(
  (props: PropsWithPortfolioProjectAndPortfolio) => {
    // const [initializing, setInitializing] = React.useState(false);
    // const portfolioConfigs = useSelector((state: RootState) =>
    //   getPortfolioConfigsByPortfolioId(
    //     selectWorkspaceData(state),
    //     props.portfolio.id
    //   )
    // );
    // const { create } = usePortfolioConfigActions();

    // React.useEffect(() => {
    //   if (!initializing && portfolioConfigs.length === 0) {
    //     setInitializing(true);
    //     const dashboardConfig: graphqlWorkspaceTypes.CreatePortfolioDashboardConfigInputType =
    //       {
    //         id: uuid.v1(),
    //         name: 'default',
    //         elementOperations: [],
    //         layouts: '{}',
    //         // height: 500,
    //       };
    //     create(props.workspace, props.portfolio.id, dashboardConfig).then(
    //       () => {
    //         setInitializing(false);
    //       }
    //     );
    //   }
    // }, [
    //   create,
    //   initializing,
    //   portfolioConfigs,
    //   props.portfolio.id,
    //   props.workspace,
    // ]);
    // if (initializing || portfolioConfigs.length === 0) {
    //   return <ProgressComponent />;
    // }
    return (
      <PortfolioProjectRouterWrapperWithPortfolioAndConfig
        workspace={props.workspace}
        project={props.project}
        portfolio={props.portfolio}
        portfolioProject={props.portfolioProject}
      />
    );
  },
);
const PortfolioProjectRouterWrapperWithPortfolioAndConfig = React.memo(
  (props: PropsWithPortfolioProjectAndPortfolio) => {
    const tabs = usePortfolioProjectTabs();
    const { tabId } = useParams<{ tabId: string }>();

    const tab = tabs.find((t) => t.name === tabId);
    return tab ? (
      <TabsComponent
        workspace={props.workspace}
        project={props.project}
        portfolio={props.portfolio}
        portfolioProject={props.portfolioProject}
        tab={tab}
        tabs={tabs}
      />
    ) : (
      <div>unknown tab in portfolio project</div>
    );
  },
);

const PortfolioProjectRouterWrapperWithPortfolioProject = React.memo(
  (props: PropsWithPortfolioProject) => {
    const { workspace } = props;
    const portfolio = useSelector((state: RootState) =>
      getPortfolioById(selectWorkspaceData(state), {
        workspaceId: props.workspace.id,
        id: props.portfolioProject.portfolioId,
        // id: selectedPortfolio?.id ?? '',
      }),
    );
    const [initialized, setInitialized] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>();
    React.useEffect(() => {
      if (portfolio) {
        dispatch(
          fetchPortfolioConfigs(workspace.tenantId, workspace.id, portfolio.id),
        ).then(() => {
          setInitialized(true);
        });
      }
    }, [dispatch, portfolio, workspace.id, workspace.tenantId]);

    const project = useSelector((state: RootState) =>
      getProjectById(selectWorkspaceData(state), {
        workspaceId: props.workspace.id,
        id: props.portfolioProject.projectId,
      }),
    );
    // const { create } = usePortfolioConfigActions();

    // const createDashboardConfigElement = React.useMemo(() => {
    //   if (!initialized || !portfolio) {
    //     return null;
    //   }

    //   return (
    //     <div>
    //       <Button
    //         onClick={() =>
    //           create(
    //             workspace,
    //             portfolio.id,
    //             // portfolioConfig,
    //             // dashboardConfig,
    //             // []
    //           )
    //         }
    //       >
    //         Create dashboard config
    //       </Button>
    //     </div>
    //   );
    // }, [create, initialized, portfolio, workspace]);

    if (!portfolio) {
      return <ProgressComponent />;
    } else if (!project) {
      return <ProgressComponent />;
    } else if (!initialized) {
      return <ProgressComponent />;
    } else {
      return (
        <Routes>
          <Route
            path="holdings/:holdingId/*"
            element={
              <HoldingRouterWrapper
                workspace={props.workspace}
                portfolioProject={props.portfolioProject}
                portfolio={portfolio}
                project={project}
              />
            }
          />
          <Route
            path="events/:eventId/*"
            element={
              <EventRouterWrapper
                workspace={props.workspace}
                portfolioProject={props.portfolioProject}
                portfolio={portfolio}
                project={project}
              />
            }
          />
          <Route
            path="scenarios/:scenarioId/*"
            element={
              <ScenarioRouterWrapper
                workspace={props.workspace}
                portfolioProject={props.portfolioProject}
                portfolio={portfolio}
                project={project}
              />
            }
          />
          <Route
            path="reporting/projectReports/:reportId/*"
            element={
              <ProjectReportRouterWrapper
                workspace={props.workspace}
                portfolioProject={props.portfolioProject}
                portfolio={portfolio}
                project={project}
              />
            }
          />
          <Route
            path="entities/:entityId/*"
            element={
              <EntityRouterWrapper
                workspace={props.workspace}
                portfolioProject={props.portfolioProject}
                portfolio={portfolio}
              />
            }
          />
          <Route
            path="transactions/:transactionId/*"
            element={
              <TransactionRouterWrapper
                workspace={props.workspace}
                projectId={props.project.id}
              />
            }
          />
          <Route
            path=""
            // exact={true}
            element={
              <Navigate
                to={portfolioProjectTabPath(
                  props.workspace.tenantId,
                  props.workspace.id,
                  props.project.id,
                  PortfolioProjectTabs.Dashboard,
                )}
                replace
              />
            }
          />
          {/* <Route
            path="/tenants/:tenantId/workspaces/:workspaceId/projects/:projectId"
            exact={true}
          >
            <Redirect
              to={portfolioProjectTabPath(
                props.workspace.tenantId,
                props.workspace.id,
                props.project.id,
                PortfolioProjectTabs.Dashboard
              )}
            />
          </Route> */}
          <Route
            path=":tabId/*"
            element={
              <PortfolioProjectRouterWrapperWithPortfolio
                workspace={props.workspace}
                portfolio={portfolio}
                portfolioProject={props.portfolioProject}
                project={props.project}
              />
            }
          />
        </Routes>
      );
    }
  },
);
