import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteDashboardElements,
  updateDashboardElements,
  upsertDashboardElements,
} from '~src/data/store/modules/common/dashboard/element/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { MultipleRelationOperationType } from '@pladdenico/models';

export function useDashboardElements(workspace: WorkspaceState) {
  const dispatch = useDispatch<AppDispatch>();
  const addElements = React.useCallback(
    (dashboardElements: graphqlWorkspaceTypes.DashboardElement[]) => {
      return dispatch(
        upsertDashboardElements(
          workspace.tenantId,
          workspace.id,
          dashboardElements,
        ),
      ).then((elements) => {
        const operations: graphqlWorkspaceTypes.MultipleRelationOperationStringInputType[] =
          elements.map((element) => {
            return {
              id: element.id,
              type: MultipleRelationOperationType.add,
            };
          });
        return { elements, operations };
      });
    },
    [dispatch, workspace.id, workspace.tenantId],
  );

  const updateElements = React.useCallback(
    (
      dashboardElements: graphqlWorkspaceTypes.UpdateDashboardElementInputType[],
    ) => {
      return dispatch(
        updateDashboardElements(
          workspace.tenantId,
          workspace.id,
          dashboardElements,
        ),
      );
    },
    [dispatch, workspace.id, workspace.tenantId],
  );
  const removeElements = React.useCallback(
    (dashboardElementIds: string[]) => {
      return dispatch(
        deleteDashboardElements(
          workspace.tenantId,
          workspace.id,
          dashboardElementIds,
        ),
      ).then((elements) => {
        const elementOperations: graphqlWorkspaceTypes.MultipleRelationOperationStringInputType[] =
          elements.map((element) => {
            return {
              id: element.id,
              type: MultipleRelationOperationType.remove,
            };
          });
        return elementOperations;
      });
    },
    [dispatch, workspace.id, workspace.tenantId],
  );
  return {
    addElements,
    removeElements,
    updateElements,
  };
}
