import { GeneratedReportState } from '~src/data/store/reducers/workspace/projects/reports/generated-report/reducer';
import { GeneratedReport } from '~src/services/graphql/workspace/client/graphql';

export function parseGeneratedReport(
  report: GeneratedReport,
): GeneratedReportState {
  return {
    id: report.id,
    name: report.name,
    projectId: report.project?.id ?? '',
    reportId: report.report?.id ?? '',
    description: report.description,
    holdingId: report.holding?.id,
    objectKey: report.objectKey,
  };
}
