import * as React from 'react';
import { CapitalCallState } from '~src/data/store/reducers/asset/asset-types/private-equity-fund/capital-calls/reducer';
import { eventParser } from '~src/utils/form/event-parser';

import { TextField } from '@mui/material';
import { TableField } from '@pladdenico/table';

export function useCapitalCallFields(
  _workspaceId: string,
  _projectId: string,
): TableField.Field<CapitalCallState>[] {
  const fields: TableField.Field<CapitalCallState>[] = [
    {
      id: 'value',
      name: 'value',
      field: 'value',
      type: TableField.FieldTypes.numeric,
      iteratee: (data) => data.value,
      update: (data, value: number) => {
        const capitalCall = { ...data, value };
        return capitalCall;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="value"
            value={editProps.data.value}
            type="number"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'name',
      name: 'name',
      field: 'name',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.name,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const capitalCall = {
          ...data,
          name: String(eventParser(event)),
        };
        return capitalCall;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="name"
            value={editProps.data.name}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
    {
      id: 'description',
      name: 'description',
      field: 'description',
      type: TableField.FieldTypes.string,
      iteratee: (data) => data.description,
      update: (data, event: React.ChangeEvent<HTMLInputElement>) => {
        const capitalCall = {
          ...data,
          description: String(eventParser(event)),
        };
        return capitalCall;
      },
      renderEdit: (editProps) => {
        return (
          <TextField
            margin="normal"
            // className={classes.textField}
            required
            id="description"
            value={editProps.data.description}
            type="text"
            onChange={editProps.onChange}
          />
        );
      },
      style: {
        minWidth: 140,
      },
    },
  ];
  return fields;
}
