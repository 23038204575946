import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  BasePrivateEquityFundId,
  privateEquityFundsSelectors,
} from '~src/data/store/reducers/asset/asset-types/private-equity-fund/private-equity-funds/reducer';
import { WorkspaceDataState } from '~src/data/store/reducers/reducers';

export const getPrivateEquityFunds = (state: WorkspaceDataState) => {
  return state.holding.asset.privateEquityFund.privateEquityFunds;
};

export const getPrivateEquityFundsByWorkspaceId = createCachedSelector(
  (state: WorkspaceDataState) => getPrivateEquityFunds(state),
  (_state: WorkspaceDataState, workspaceId: string) => workspaceId,
  (privateEquityFundsState, workspaceId) => {
    const privateEquityFunds = privateEquityFundsSelectors.selectElementsByKey(
      privateEquityFundsState,
      workspaceId,
    );
    return privateEquityFunds ? privateEquityFunds : [];
  },
)({
  keySelector: (_state, privateEquityFundId) => privateEquityFundId,
  selectorCreator: createSelector,
});

// export const privateEquityFundByIdSelector = (privateEquityFunds: PrivateEquityFund[], privateEquityFundId: string) => {
//   return __OLDsortedFind(privateEquityFunds, { id: privateEquityFundId } as PrivateEquityFund, (a) => a.id);
// };

export const getPrivateEquityFundById = createCachedSelector(
  (state: WorkspaceDataState, _baseId: Partial<BasePrivateEquityFundId>) =>
    getPrivateEquityFunds(state),
  (_state: WorkspaceDataState, baseId: Partial<BasePrivateEquityFundId>) =>
    baseId.workspaceId,
  (_state: WorkspaceDataState, baseId: Partial<BasePrivateEquityFundId>) =>
    baseId.id,
  (privateEquityFunds, workspaceId, privateEquityFundId) => {
    if (!workspaceId || !privateEquityFundId) {
      return undefined;
    }
    return privateEquityFundsSelectors.selectElementByT(privateEquityFunds, {
      workspaceId,
      id: privateEquityFundId,
    });
  },
)({
  keySelector: (_state, workspaceId, privateEquityFundId) =>
    `${workspaceId}-${privateEquityFundId}`,
  selectorCreator: createSelector,
});
