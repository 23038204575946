import * as React from 'react';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import { UpdateStockGoalRuleComponent } from '../../../../holding/holding-types/stock-holdings/stock-goal-rules/form/update.component';
import { StockGoalRuleState } from '~src/data/store/reducers/holding/holding-types/stock/stock-goal-rule/reducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~src/store/store';
import { deleteStockGoalRules } from '~src/data/store/modules/holdings/stock/goal-rules/requests';

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  stockHoldingId: string;
  stockGoalRule: StockGoalRuleState;
}

export const GoalRuleMenuComponent = React.memo((props: Props) => {
  const {
    isShowing: isShowingModal,
    open: openModal,
    close: closeModal,
  } = useVisible();
  const {
    handleClose: handleCloseMenu,
    handleOpen: handleOpenMenu,
    isOpen: isOpenMenu,
    anchorEl: anchorElMenu,
  } = useMenu();

  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = React.useCallback(() => {
    dispatch(
      deleteStockGoalRules(
        props.workspace.tenantId,
        props.workspace.id,
        props.stockHoldingId,
        [props.stockGoalRule.id],
      ),
    ).then(() => closeModal());
  }, [
    closeModal,
    dispatch,
    props.stockHoldingId,
    props.stockGoalRule.id,
    props.workspace.id,
    props.workspace.tenantId,
  ]);

  const handleCloseAll = React.useCallback(() => {
    closeModal();
    handleCloseMenu();
  }, [closeModal, handleCloseMenu]);

  const menu = (
    <Menu
      anchorEl={anchorElMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpenMenu}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={openModal}>Edit goal rule</MenuItem>
      <MenuItem onClick={handleDelete}>Delete goal rule</MenuItem>
      <UpdateStockGoalRuleComponent
        open={isShowingModal}
        workspace={props.workspace}
        handleClose={handleCloseAll}
        stockGoalRuleUpdateInputInit={props.stockGoalRule}
      />
      {/* {workspaces} */}
      {/* {messagesBadge}
      {notificationBadge}
      {profileButton}
      {loginComponent}
      {signupComponent} */}
    </Menu>
  );
  return (
    <>
      <IconButton onClick={handleOpenMenu} color="inherit">
        <MoreIcon />
      </IconButton>
      {menu}
    </>
  );
});
