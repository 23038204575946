import {
  EmailStateVisitor,
  EmailVisitable,
} from '~src/data/store/visitors/person/email-visitor';
import { Email } from '~src/services/graphql/workspace/client/graphql';

export function handleEmails(
  dispatch: any,
  _tenantId: string,
  workspaceId: string,
  emails: Email[],
) {
  const visitor = new EmailStateVisitor(dispatch);
  const res = emails.map((email) => {
    const visitable = new EmailVisitable(
      workspaceId,
      email.person?.id ?? '',
      email,
    );
    return visitable.accept(visitor);
  });
  visitor.post();
  return res;
}
