import {
  BaseEntityMediumId,
  entityMediasActions,
} from '~src/data/store/reducers/entity/medias/reducer';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

import { handleEntityMedias } from './handler';

export function fetchEntityMedias(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null,
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetEntityMediasDocument;
    const variables: graphqlWorkspaceTypes.GetEntityMediasQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getEntityMedias) {
        return handleEntityMedias(dispatch, data.getEntityMedias);
      }
      return undefined;
    });
  };
}

export function createEntityMedias(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateEntityMediaInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateEntityMediasDocument;
    const variables: graphqlWorkspaceTypes.CreateEntityMediasMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createEntityMedias) {
        return handleEntityMedias(dispatch, data.createEntityMedias);
      }
      throw new Error('Could not create entityMedia');
      // return undefined;
    });
  };
}

export function updateEntityMedias(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateEntityMediaInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateEntityMediasDocument;
    const variables: graphqlWorkspaceTypes.UpdateEntityMediasMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateEntityMedias) {
        return handleEntityMedias(dispatch, data.updateEntityMedias);
      }
      return [];
    });
  };
}

export function upsertEntityMedias(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertEntityMediaInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertEntityMediasDocument;
    const variables: graphqlWorkspaceTypes.UpsertEntityMediasMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertEntityMedias) {
        return handleEntityMedias(dispatch, data.upsertEntityMedias);
      }
      return [];
    });
  };
}

export function deleteEntityMedias(
  tenantId: string,
  workspaceId: string,
  ids: BaseEntityMediumId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteEntityMediasDocument;
    const variables: graphqlWorkspaceTypes.DeleteEntityMediasMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id: id.id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteEntityMedias) {
        const deletedIds = data.deleteEntityMedias;
        dispatch(
          entityMediasActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
