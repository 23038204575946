import { EntityArrayAdapter } from '~src/data/base/array/array-entity-adapter';
import { createBaseArraySlice } from '~src/data/base/array/base-array-slice';

import { strcmp } from '@pladdenico/common';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export interface BaseDashboardMediaId {
  id: string;
}

export type DashboardMediaState = Omit<
  graphqlWorkspaceTypes.DashboardMedia,
  'element' | 'file'
> & {
  elementId: string | null;
  fileId: string | null;
};

export const initialDashboardMediaState = [];

const tComparator = (a: BaseDashboardMediaId, b: BaseDashboardMediaId) =>
  strcmp(a.id, b.id);
const merger = (_a: DashboardMediaState, b: DashboardMediaState) => b;

const adapter = new EntityArrayAdapter<
  BaseDashboardMediaId,
  DashboardMediaState
>(initialDashboardMediaState, tComparator, merger);

export const dashboardMediasSlice = createBaseArraySlice(
  'dashboardMedias',
  adapter,
);
export const selectors = adapter.selectors;

export const dashboardMediasReducer = dashboardMediasSlice.reducer;
export const dashboardMediasActions = dashboardMediasSlice.actions;
