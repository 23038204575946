import { useParams } from 'react-router-dom';
import { workspacesRootPath } from '~src/navigation/paths/workspace/paths';

import { Breadcrumbs, Typography } from '@mui/material';

import { LinkRouter, maxItems } from './breadcrumbs.component';
import { useTenantBreadcrumbs } from '~src/domain/tenant/components/top-bar/breadcrumb/tenant-breadcrumb';

function useWorkspacesBreadcrumbs(last: boolean) {
  const { tenantId } = useParams<{
    tenantId: string;
  }>();
  const tenantBreadcrumbs = useTenantBreadcrumbs(false);
  if (tenantId != null) {
    const holdingElement = last ? (
      <Typography color="inherit" key={tenantId}>
        workspaces
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspacesRootPath(tenantId)}
        to={workspacesRootPath(tenantId)}
      >
        workspaces
      </LinkRouter>
    );
    return [tenantBreadcrumbs, holdingElement];
  }
  return [];
}
export function WorkspacesBreadcrumbComponent(props: { last: boolean }) {
  const workspacesBreadcrumbs = useWorkspacesBreadcrumbs(props.last);
  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {workspacesBreadcrumbs}
    </Breadcrumbs>
  );
}
