import { handleProjectReports } from '~src/data/store/modules/workspaces/reports/project-report/handler';
import { requestStatusActions } from '~src/data/store/reducers/common/request-status/reducer';
import { projectReportsActions } from '~src/data/store/reducers/workspace/projects/reports/project-report/reducer';
import * as WorkspaceClientGraphql from '~src/services/graphql/workspace/client/graphql';
import { investRequest } from '~src/services/request/graphql-request';
import { GraphqlResultStatusHandler } from '~src/services/request/graphql-result-status-handler';
import { ReducerActionRequestStatusHandler } from '~src/services/request/request-status-handler';
import { AppDispatch } from '~src/store/store';

import {
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';

function createResultHandler<Q, V>(dispatch: AppDispatch, id: string) {
  return new GraphqlResultStatusHandler<Q, V>(
    id,
    new ReducerActionRequestStatusHandler(dispatch, requestStatusActions),
  );
}

export function fetchProjectReports(
  tenantId: string,
  workspaceId: string,
  ids: string[] | null = null,
  requestId: string | null = null,
) {
  return (dispatch: any) => {
    const node = WorkspaceClientGraphql.GetProjectReportsDocument;
    const variables: WorkspaceClientGraphql.GetProjectReportsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    const resultHandler =
      requestId != null
        ? createResultHandler<
            WorkspaceClientGraphql.GetPortfolioProjectsQuery,
            WorkspaceClientGraphql.GetPortfolioProjectsQueryVariables
          >(dispatch, requestId)
        : undefined;
    return investRequest(node, variables, context, resultHandler)
      .then((data) => {
        if (data.getProjectReports) {
          const ret = handleProjectReports(
            dispatch,
            tenantId,
            workspaceId,
            data.getProjectReports,
            [],
          );
          resultHandler?.handleDone();
          return ret;
        }
        return undefined;
      })
      .catch((err) => {
        console.error(`Error getProjectReports: ', ${JSON.stringify(err)}`);
      })
      .finally(() => resultHandler?.handleDone());
  };
}

export function createProjectReports(
  tenantId: string,
  workspaceId: string,
  inputs: WorkspaceClientGraphql.CreateProjectReportInputType[],
) {
  return (dispatch: any) => {
    const node = WorkspaceClientGraphql.CreateProjectReportsDocument;
    const variables: WorkspaceClientGraphql.CreateProjectReportsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createProjectReports) {
        return handleProjectReports(
          dispatch,
          tenantId,
          workspaceId,
          data.createProjectReports,
          [],
        );
      }
      throw new Error('Could not create projectReport');
    });
  };
}

export function updateProjectReports(
  tenantId: string,
  workspaceId: string,
  inputs: WorkspaceClientGraphql.UpdateProjectReportInputType[],
) {
  return (dispatch: any) => {
    const node = WorkspaceClientGraphql.UpdateProjectReportsDocument;
    const variables: WorkspaceClientGraphql.UpdateProjectReportsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateProjectReports) {
        return handleProjectReports(
          dispatch,
          tenantId,
          workspaceId,
          data.updateProjectReports,
          [],
        );
      }
      return [];
    });
  };
}

export function upsertProjectReports(
  tenantId: string,
  workspaceId: string,
  inputs: WorkspaceClientGraphql.UpsertProjectReportInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = WorkspaceClientGraphql.UpsertProjectReportsDocument;
    const variables: WorkspaceClientGraphql.UpsertProjectReportsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertProjectReports) {
        return handleProjectReports(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertProjectReports,
          [],
        );
      }
      return [];
    });
  };
}

export function handleDeleteProjectReports(projectId: string, ids: string[]) {
  return (dispatch: AppDispatch) => {
    dispatch(
      projectReportsActions.removeManyElements(
        ids.map((p) => {
          return {
            projectId,
            id: p,
          };
        }),
      ),
    );
  };
}

export function deleteProjectReports(
  tenantId: string,
  workspaceId: string,
  projectId: string,
  ids: string[],
) {
  return (dispatch: any) => {
    const node = WorkspaceClientGraphql.DeleteProjectReportsDocument;
    const variables: WorkspaceClientGraphql.DeleteProjectReportsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteProjectReports) {
        dispatch(
          handleDeleteProjectReports(projectId, data.deleteProjectReports),
        );
      }
      return [];
    });
  };
}
