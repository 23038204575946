import React from 'react';
import { ProjectState } from '~src/data/store/reducers/workspace/projects/base/reducer';
import { ForecastObjectType } from '~src/data/store/reducers/workspace/projects/scenario/forecasts/forecast/reducer';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import {
  useForecastElement,
  useSelectForecast,
} from '~src/domain/workspace/components/project/scenario/holdings/forecast/use-select-forecast.component';
import { Budget } from '~src/domain/workspace/components/project/scenario/models/budget';
import { Scenario } from '~src/domain/workspace/components/project/scenario/models/scenario';
import {
  ScenarioValueType,
  ScenarioVariable,
} from '~src/domain/workspace/components/project/scenario/models/variable';
import { useVisible } from '~src/hooks/utils/use-visible.hook';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { ScenarioVariableElement } from './variable-element';

interface Props {
  workspace: WorkspaceState;
  project: ProjectState;
  scenario: Scenario;
  variable: ScenarioVariable<ScenarioValueType>;
  budget: Budget;
}

export const ScenarioVariableComponent = React.memo((props: Props) => {
  const { variable, budget } = props;
  const forecast = useSelectForecast(
    budget,
    ForecastObjectType.variable,
    variable.id,
  );

  const selectForecastElement = useForecastElement(
    budget,
    forecast,
    ForecastObjectType.variable,
    variable.id,
  );

  const elementsVisible = useVisible();

  const accordionButton = (
    <IconButton onClick={elementsVisible.toggle}>
      {elementsVisible.isShowing ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </IconButton>
  );

  const variableElement = React.useMemo(() => {
    if (forecast != null) {
      return <ScenarioVariableElement {...props} forecast={forecast} />;
    }
    return <></>;
  }, [forecast, props]);

  return (
    <>
      <Grid container alignItems="center">
        <Grid xs={2} display="flex" alignItems="center" sx={{ ml: 1 }}>
          <Typography variant="body1">{variable.name}</Typography>
          <Typography variant="body2">{variable.description}</Typography>
        </Grid>
        <Grid xs={3}>{selectForecastElement.element}</Grid>
        <Grid xs={1}>{accordionButton}</Grid>
      </Grid>
      {elementsVisible.isShowing && variableElement}
    </>
  );
});
