import { RecordState } from '@pladdenico/common';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { WorkspaceDataState } from '../../../reducers/reducers';
import {
  BasePhoneId,
  PhoneState,
  phonesSelectors,
} from '../../../reducers/person/phones/reducer';

const getPhoneState = (state: WorkspaceDataState) => state.person.phones;

export const selectPhonesByPersonId = createCachedSelector(
  (state: RecordState<PhoneState, string>[], personId: string) =>
    phonesSelectors.selectElementsByKey(state, personId),
  (phones: PhoneState[] | undefined): PhoneState[] => {
    return phones ? phones : [];
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getPhonesByPersonId = createCachedSelector(
  (state: WorkspaceDataState) => getPhoneState(state),
  (_state: WorkspaceDataState, personId: string | null) => personId,
  (personState, personId): PhoneState[] => {
    if (!personId) {
      return [];
    }
    return selectPhonesByPersonId(personState, personId);
  },
)({
  keySelector: (_state, personId) => personId,
  selectorCreator: createSelector,
});

export const getPhones = createSelector(
  (state: WorkspaceDataState) => getPhoneState(state),
  (phones) => {
    return phonesSelectors.selectAllElements(phones);
  },
);

export const getPhoneById = createCachedSelector(
  (state: WorkspaceDataState) => getPhoneState(state),
  (_state: WorkspaceDataState, baseId: BasePhoneId) => baseId.id,
  (_state: WorkspaceDataState, baseId: BasePhoneId) => baseId.personId,
  (phones, id, personId): PhoneState | undefined => {
    return phonesSelectors.selectElementByT(phones, {
      id,
      personId,
    });
  },
)({
  keySelector: (_state, personBaseId) =>
    `${personBaseId.personId}:${personBaseId.id}`,
  selectorCreator: createSelector,
});
