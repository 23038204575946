import { sortBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { ElementFormatConfig } from '~src/components/dashboard/element';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getHoldingsCashOnCash } from '~src/data/store/selectors/finance/cash-on-cash/holdings-cash-on-cash';
import { selectData } from '~src/data/store/selectors/selectors';
import { Filter } from '~src/utils/common/filter';
import { SortOrder } from '~src/utils/interfaces/sort-order';
import { Period } from '~src/utils/period/period';

import { HoldingValue } from './holdings-view-table.component';

interface Props {
  workspace: WorkspaceState;
  valuationDate: moment.Moment;
  projectId: string;
  holdings: HoldingState[];
  baseCurrency: CurrencyState;
  sortOrder: SortOrder;
  minimumValuationForHolding: number | undefined;
  filter: Filter;
  format: ElementFormatConfig;
  period: Period;
}

export const useHoldingsCashOnCashRatio = (props: Props) => {
  const {
    workspace,
    projectId,
    holdings,
    valuationDate,
    baseCurrency,
    period,
    filter,
  } = props;

  const cashOnCashes = useSelector((state: RootState) =>
    getHoldingsCashOnCash(selectData(state), {
      workspace,
      projectId: projectId,
      holdings: holdings,
      currency: baseCurrency,
      date: valuationDate,
      period,
      filter,
      useLiveValue: true,
    }),
  );
  const [cashOnCashRatio, setCashOnCashRatio] = React.useState<HoldingValue[]>(
    [],
  );
  React.useEffect(() => {
    const values = cashOnCashes.map((cashOnCash) => {
      const { startValue, endValue, inflow, outflow } = cashOnCash.cashOnCash;
      let value = 0;
      if (outflow + startValue !== 0) {
        value = (inflow + endValue) / (outflow + startValue);
      }
      return {
        value,
        holding: cashOnCash.holding,
      };
    });
    setCashOnCashRatio(sortBy(values, (a) => a.value));
  }, [cashOnCashes]);

  return cashOnCashRatio;
};
