import { parseWarrant } from '~src/data/store/modules/assets/warrants/parser';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  warrantsActions,
  WarrantState,
} from '~src/data/store/reducers/asset/asset-types/warrants/reducer';
import { Asset, Warrant } from '~src/services/graphql/workspace/client/graphql';
import { AppDispatch } from '~src/store/store';

import { AssetType } from '@pladdenico/models';

export interface WarrantVisitor {
  visit(warrant: WarrantVisitable): WarrantState;
  post(): void;
}

export const isWarrant = (asset: Asset): asset is Warrant => {
  return asset._type === AssetType.Warrant;
};

export class WarrantVisitable implements Visitable<WarrantVisitor> {
  constructor(
    private _workspaceId: string,
    private _warrant: Warrant,
  ) {}
  public accept(visitor: WarrantVisitor) {
    return visitor.visit(this);
  }

  public parse(): WarrantState {
    const assetId = this._warrant.asset?.id ?? '';
    return parseWarrant(this._workspaceId, assetId, this._warrant);
  }
}

export class WarrantStateVisitor implements WarrantVisitor {
  private _warrants: WarrantState[];
  constructor(private _dispatch: AppDispatch) {
    this._warrants = [];
  }

  public visit(warrant: WarrantVisitable): WarrantState {
    const warrantParsed = warrant.parse();
    this._warrants.push(warrantParsed);
    return warrantParsed;
  }

  post() {
    this._dispatch(
      warrantsActions.upsertManyElements(this._warrants, {
        shouldAutobatch: true,
      }),
    );
  }
}
