import { Operation } from '@pladdenico/portfolio-api';
import { parseQuote } from '~src/data/store/modules/finance/quotes/parser';
import { subscribeToPaperQuotes } from '~src/data/store/modules/finance/quotes/subscription';
import { Visitable } from '~src/data/store/visitors/visitable';
import {
  quotesActions,
  QuoteState,
} from '~src/data/store/reducers/finance/quote/reducer';
import { Quote } from '~src/services/graphql/finance/client/graphql';
import { AppDispatch } from '~src/store/store';

interface QuoteVisitor {
  visit(quotes: QuoteVisitable): QuoteState;
  post(): void;
}

export class QuoteVisitable implements Visitable<QuoteVisitor> {
  constructor(
    private _paperId: string,
    private _quote: Quote,
  ) {}
  public accept(visitor: QuoteVisitor) {
    return visitor.visit(this);
  }

  public parse(): QuoteState {
    return parseQuote(this._paperId, this._quote);
  }
}

// export class QuoteHandlerVisitor implements QuoteVisitor {
//   constructor(private _handle: (quotes: QuoteState) => void) {}
//   public visit(quotes: QuoteVisitable): QuoteState {
//     const quotesState = quotes.parse();
//     this._handle(quotesState);
//     return quotesState;
//   }
// }

export class QuoteStateVisitor implements QuoteVisitor {
  private _quotes: QuoteState[];
  constructor(
    private _dispatch: AppDispatch,
    private _paperId: string,
    private _subscriptions: Operation[],
  ) {
    this._quotes = [];
  }
  public visit(quotes: QuoteVisitable): QuoteState {
    const quotesState = quotes.parse();
    this._quotes.push(quotesState);
    return quotesState;
  }
  post() {
    this._dispatch(
      quotesActions.upsertManyElements(this._quotes, {
        shouldAutobatch: true,
      }),
    );
    subscribeToPaperQuotes(this._dispatch, this._paperId, this._subscriptions);
  }
}
