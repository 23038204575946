import { handleCommitments } from '~src/data/store/modules/holdings/commitments/commitments/handler';
import {
  BaseCommitmentId,
  commitmentsActions,
} from '~src/data/store/reducers/holding/commitment/commitments/reducer';
import { investRequest } from '~src/services/request/graphql-request';
import { AppDispatch } from '~src/store/store';

import {
  Operation,
  QueryContextTypes,
  QueryWorkspaceContext,
} from '@pladdenico/portfolio-api';
import * as graphqlWorkspaceTypes from '~src/services/graphql/workspace/client/graphql';

export function fetchCommitments(
  tenantId: string,
  workspaceId: string,
  ids: string[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.GetCommitmentsDocument;
    const variables: graphqlWorkspaceTypes.GetCommitmentsQueryVariables = {
      ids,
    };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.getCommitments) {
        return handleCommitments(
          dispatch,
          tenantId,
          workspaceId,
          data.getCommitments,
          [
            Operation.create,
            Operation.delete,
            Operation.update,
            Operation.upsert,
          ],
        );
      }
      return undefined;
    });
  };
}

export function createCommitments(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.CreateCommitmentInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.CreateCommitmentsDocument;
    const variables: graphqlWorkspaceTypes.CreateCommitmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.createCommitments) {
        return handleCommitments(
          dispatch,
          tenantId,
          workspaceId,
          data.createCommitments,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      throw new Error('Could not create commitment');
      // return undefined;
    });
  };
}

export function updateCommitments(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpdateCommitmentInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpdateCommitmentsDocument;
    const variables: graphqlWorkspaceTypes.UpdateCommitmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.updateCommitments) {
        return handleCommitments(
          dispatch,
          tenantId,
          workspaceId,
          data.updateCommitments,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function upsertCommitments(
  tenantId: string,
  workspaceId: string,
  inputs: graphqlWorkspaceTypes.UpsertCommitmentInputType[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.UpsertCommitmentsDocument;
    const variables: graphqlWorkspaceTypes.UpsertCommitmentsMutationVariables =
      {
        inputs,
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.upsertCommitments) {
        return handleCommitments(
          dispatch,
          tenantId,
          workspaceId,
          data.upsertCommitments,
          [Operation.delete, Operation.update, Operation.upsert],
        );
      }
      return [];
    });
  };
}

export function handleDeleteCommitments(ids: BaseCommitmentId[]) {
  return (dispatch: AppDispatch) => {
    dispatch(commitmentsActions.removeManyElements(ids));
  };
}

export function deleteCommitments(
  tenantId: string,
  workspaceId: string,
  ids: BaseCommitmentId[],
) {
  return (dispatch: AppDispatch) => {
    const node = graphqlWorkspaceTypes.DeleteCommitmentsDocument;
    const variables: graphqlWorkspaceTypes.DeleteCommitmentsMutationVariables =
      {
        inputs: ids.map((id) => {
          return { id: id.id };
        }),
      };
    const context: QueryWorkspaceContext = {
      type: QueryContextTypes.workspace,
      tenantId,
      workspaceId,
    };
    return investRequest(node, variables, context).then((data) => {
      if (data.deleteCommitments) {
        const deletedIds = data.deleteCommitments;
        dispatch(
          commitmentsActions.removeManyElements(
            ids.filter((baseId) => deletedIds.some((p) => p === baseId.id)),
          ),
        );
        return deletedIds;
      }
      return [];
    });
  };
}
