import { combineReducers } from 'redux';
import {
  entitiesReducer,
  initialEntitysState,
} from '~src/data/store/reducers/entity/entities/reducer';
import {
  entityMediasReducer,
  initialState as initialEntityMediaState,
} from '~src/data/store/reducers/entity/medias/reducer';
import {
  initialRegionAllocationState,
  regionAllocationReducer,
} from '~src/data/store/reducers/entity/region-allocations/reducer';

export const entityInitialState = {
  entities: initialEntitysState,
  regionAllocation: initialRegionAllocationState,
  media: initialEntityMediaState,
};

export const entityReducer = combineReducers({
  entities: entitiesReducer,
  regionAllocation: regionAllocationReducer,
  media: entityMediasReducer,
});
