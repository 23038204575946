import React from 'react';
import * as uuid from 'uuid';

import Autorenew from '@mui/icons-material/Autorenew';
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  id: string;
  setId: (id: string) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const IdComponent = React.memo((props: Props) => {
  const { classes } = useStyles();
  const handleClickRegenerateId = () => {
    props.setId(uuid.v1());
  };
  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    props.setId(event.target.value);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Grid item>
      <FormControl
        fullWidth
        required
        className={classes.textField}
        margin="normal"
      >
        <InputLabel htmlFor="adornment-id">Id</InputLabel>
        <Input
          id="adornment-id"
          type={'text'}
          value={props.id}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="id regeneration"
                onClick={handleClickRegenerateId}
                onMouseDown={handleMouseDown}
              >
                <Autorenew />
                {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>
  );
});
