import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { ElementFormatConfig } from '~src/components/dashboard/element';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { selectFavoriteHoldingsByProjectId } from '~src/data/store/selectors/holding/favorite-holding/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { holdingBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/paths';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Button,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableCellProps as MuiTableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { HoldingsActionsComponent } from '../../../holding/favorite-holdings/holdings-actions.component';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { ExportComponent } from '~src/domain/workspace/components/project/portfolio/contributors/holdings-view/export/export.component';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export interface TableCellStyleConfig {
  width?: string;
}

interface TableCellProps extends MuiTableCellProps {
  config: TableCellStyleConfig;
}

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'config',
})<TableCellProps>(({ config }) => ({
  [`&.${tableCellClasses.root}`]: {
    width: config.width ?? 'auto',
  },
}));

export interface HoldingValue {
  holding: HoldingState;
  value: number;
}

interface Props {
  workspace: WorkspaceState;
  projectId: string;
  baseCurrency: CurrencyState;
  reverse: boolean;
  data: HoldingValue[];
  minimumValue: number | undefined;
  renderValue: (value: number) => string;
  format: ElementFormatConfig;
}

export const HoldingsViewTableComponent = memo((props: Props) => {
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState<HoldingValue[]>([]);
  const favoriteHoldingForPortfolio = useSelector((state: RootState) =>
    selectFavoriteHoldingsByProjectId(
      selectWorkspaceData(state),
      props.projectId,
    ),
  );

  useEffect(() => {
    const numberOfHoldings = (toggle: boolean) => {
      return toggle ? props.data.length : 5;
    };

    const filteredData = props.data.filter((valuation) => {
      return !props.minimumValue || valuation.value >= props.minimumValue;
    });
    if (!props.reverse) {
      const topData = filteredData.slice(0, numberOfHoldings(toggle));
      setData(topData);
    } else {
      const bottomData = filteredData
        .slice(Math.max(0, filteredData.length - numberOfHoldings(toggle)))
        .reverse();
      setData(bottomData);
    }
  }, [props.data, props.reverse, props.minimumValue, toggle]);

  const icon = toggle ? <ArrowDropUp /> : <ArrowDropDown />;
  const buttonNumberOfHoldingsToggle = (
    <IconButton
      aria-label="buttonNumberOfHoldingToggle"
      onClick={() => setToggle(!toggle)}
    >
      {icon}
    </IconButton>
  );

  const elements = data.map((d) => {
    const favoriteHolding = favoriteHoldingForPortfolio.find(
      (favoriteHolding) => favoriteHolding.holdingId === d.holding.id,
    );
    return (
      <TableRow key={d.holding.id}>
        <StyledTableCell
          config={{
            width: '40%',
          }}
        >
          <Link
            component={RouterLink}
            to={holdingBasePath(
              props.workspace.tenantId,
              props.workspace.id,
              props.projectId,
              d.holding.id,
            )}
          >
            {d.holding.name}
          </Link>
        </StyledTableCell>
        <StyledTableCell
          config={{
            width: '50%',
          }}
        >
          {props.renderValue(d.value)}
        </StyledTableCell>
        <StyledTableCell
          config={{
            width: '10%',
          }}
        >
          <HoldingsActionsComponent
            tenantId={props.workspace.tenantId}
            workspaceId={props.workspace.id}
            projectId={props.projectId}
            holding={d.holding}
            favoriteHolding={favoriteHolding}
          />
        </StyledTableCell>
      </TableRow>
    );
  });

  const exportDialog = useVisible();

  const exportData = useCallback(() => {
    exportDialog.open();
  }, [exportDialog]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Holding</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>
              <Button
                // style={{ float: 'right' }}
                onClick={exportData}
                startIcon={<FileDownloadIcon />}
              >
                Export
              </Button>
              <DialogComponent
                dialogProps={{
                  onClose: exportDialog.close,
                  open: exportDialog.isShowing,
                }}
              >
                <DialogContentComponent title="Export data">
                  <ExportComponent name="Contributors" data={data} />
                </DialogContentComponent>
              </DialogComponent>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{elements}</TableBody>
      </Table>
      <Grid2 container justifyContent="center">
        {buttonNumberOfHoldingsToggle}
      </Grid2>
    </TableContainer>
  );
});
