import { UserTabs } from '~src/domain/user/components/user/tab/user-tabs.component';

export const userBasePath = '/user';
// export const userMyPath = '/my';
export const userSignupBasePath = 'signup';
export const userLoginBasePath = 'login';
export const userResetPasswordBasePath = 'reset-password';
export const userForgotPasswordBasePath = 'forgot-password';
export const userInvitationsBasePath = 'invitations';

export const userMessagesBasePath = 'messages';
export const userTenantsBasePath = 'tenants';

export const userSignupPath = `${userBasePath}/${userSignupBasePath}`;
export const userLoginPath = `${userBasePath}/${userLoginBasePath}`;
export const userResetPasswordPath = `${userBasePath}/${userResetPasswordBasePath}`;
export const userForgotPasswordPath = `${userBasePath}/${userForgotPasswordBasePath}`;

export const userMessagesPath = `${userBasePath}/${userMessagesBasePath}`;
export const userTenantsPath = `${userBasePath}/${userTenantsBasePath}`;
// export const userTenantCreatePath = `${userTenantsPath}/create`;

export const userInvitationsPath = `${userBasePath}/${userInvitationsBasePath}`;

export const userTabPath = (tab: UserTabs) => {
  return `${userBasePath}/${tab}`;
};
