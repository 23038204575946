import moment from 'moment';
import * as React from 'react';
import {
  Path,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DialogContentComponent } from '~src/components/utils/dialog-content.component';
import { DialogComponent } from '~src/components/utils/dialog.component';
import { updateTransfers } from '~src/data/store/modules/holdings/transfers/transfers/requests';
import { RootState } from '~src/data/store/reducers/reducers';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { getCommitmentById } from '~src/data/store/selectors/holding/commitment/commitments/selectors';
import { getTransactionById } from '~src/data/store/selectors/holding/transaction/transactions/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import { getAllTransferFieldConfig } from '~src/domain/workspace/components/project/transaction/form/holding/config/field/transfer/all-field-config';
import {
  StockTransferTyped,
  TransferDetailed,
} from '~src/domain/workspace/components/project/transfer/algorithm/transfer-detailed';
import {
  StockTransferData,
  TransferInputData,
  TransferTypeData,
} from '~src/domain/workspace/components/project/transfer/form/fields/transfer-data-types';
import { TransferFormComponent } from '~src/domain/workspace/components/project/transfer/form/transfer-form.component';
import { useMenu } from '~src/hooks/utils/use-menu.hook';
import { useVisible } from '~src/hooks/utils/use-visible.hook';
import { transferBasePath } from '~src/navigation/paths/workspace/project/portfolio-project/holding/transfer/paths';
import { AppDispatch } from '~src/store/store';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  colors,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { HoldingType } from '@pladdenico/models';
import { updateStockPositions } from '~src/data/store/modules/holdings/stock/position/requests';
import { updateStockTrades } from '~src/data/store/modules/holdings/stock/trade/requests';
import { omit } from 'lodash';
import { getTransferTypes } from '~src/utils/finance/holding-to-transfer-types';
import { transfersActions } from '~src/data/store/reducers/holding/transfer/transfers/reducer';

const PREFIX = 'TransferCardComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  transfer: `${PREFIX}-transfer`,
  detailed: `${PREFIX}-detailed`,
  description: `${PREFIX}-description`,
  menuButton: `${PREFIX}-menuButton`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    flex: 1,
    backgroundColor: colors.blue[50],
    // minWidth: 150,
    // height: 165,
    // width: 160,
    // maxWidth: 450,
  },

  [`& .${classes.transfer}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.detailed}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.description}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 4,
    overflow: 'hidden',
  },

  [`& .${classes.menuButton}`]: {
    textAlign: 'center',
  },
}));

interface Props {
  transfer: TransferDetailed;
  workspace: WorkspaceState;
  projectId: string;
}

export const TransferCardComponent = React.memo((props: Props) => {
  const { transfer, projectId, workspace } = props;
  const editDialog = useVisible();
  const menu = useMenu();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(
      transferBasePath(
        workspace.tenantId,
        workspace.id,
        projectId,
        transfer.holding.id,
        transfer.transfer.id,
      ),
    );
  };
  const getName = React.useCallback(
    (name: Path<TransferInputData>): Path<TransferInputData> => {
      return name;
    },
    [],
  );

  const commitment = useSelector((state: RootState) =>
    getCommitmentById(
      selectWorkspaceData(state),
      transfer.transfer.commitmentId,
    ),
  );
  const base = React.useMemo(
    () => omit(transfer.transfer, 'hash'),
    [transfer.transfer],
  );

  const defaultValues: TransferInputData = React.useMemo(() => {
    let data: TransferTypeData = undefined;
    // TODO: Needed also for Fund and Bond
    if (transfer.transferTyped) {
      if (transfer.holding.type === HoldingType.Stock) {
        const transferTyped = transfer.transferTyped as StockTransferTyped;
        const stockData: StockTransferData = {
          holdingType: HoldingType.Stock,
          position: {
            ...transferTyped.position,
            stockHoldingId: transferTyped.stockHoldingId,
          },
          trade: {
            id: transferTyped.trade.id,
            positionId: transferTyped.position.id,
            sharePrice: transferTyped.trade.price,
            shares: transferTyped.trade.shares,
            stockHoldingId: transferTyped.stockHoldingId,
            transferId: transfer.transfer.id,
          },
        };
        data = stockData;
      }
    }
    return {
      base,
      data,
    };
  }, [
    base,
    transfer.holding.type,
    transfer.transfer.id,
    transfer.transferTyped,
  ]);

  const {
    control,
    // setValue: setValueInit,
    setValue,
    handleSubmit,
    // watch,
    // getValues,
  } = useForm<TransferInputData>({
    defaultValues,
  });

  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = React.useState<any[]>([]);
  const errorElements = React.useMemo(
    () =>
      errors.map((err, idx) => {
        return (
          <Alert key={idx} severity="error">
            {err.message}
          </Alert>
        );
      }),
    [errors],
  );

  const save = React.useCallback(
    (formValues: TransferInputData) => {
      const existingHoldingId = transfer.holding.id;
      return dispatch(
        updateTransfers(workspace.tenantId, workspace.id, [formValues.base]),
      )
        .then((transfers) => {
          console.log('TRANSFERS', transfers, formValues.base);
          if (formValues.base.holdingId !== existingHoldingId) {
            dispatch(
              transfersActions.removeOneElement({
                holdingId: existingHoldingId,
                id: transfer.id,
              }),
            );
          }
          const dataPromises = [];
          // TODO: Needed also for Fund and Bond
          if (transfer.holding.type === HoldingType.Stock) {
            const input = formValues.data as StockTransferData;
            if (transfer.transferTyped) {
              // const transferTyped =
              //   transfer.transferTyped as StockTransferTyped;
              dataPromises.push(
                dispatch(
                  updateStockPositions(workspace.tenantId, workspace.id, [
                    {
                      ...input.position,
                      // stockHoldingId: transferTyped.stockHoldingId,
                    },
                  ]),
                ).then((stockPositions) => {
                  console.log('stockPositions', stockPositions);
                  console.log('inputs', input);
                  dataPromises.push(
                    dispatch(
                      updateStockTrades(workspace.tenantId, workspace.id, [
                        input.trade,
                      ]),
                    ).then((stockTrades) => {
                      console.log('stockTrades', stockTrades);
                    }),
                  );
                }),
              );
            }
          }
          return Promise.all(dataPromises).then(() => {
            return {
              transfers,
            };
          });
        })
        .catch((err) => {
          console.info('FAILED', formValues, JSON.stringify(err));
          throw err;
        });
    },
    [
      dispatch,
      transfer.holding.id,
      transfer.holding.type,
      transfer.id,
      transfer.transferTyped,
      workspace.id,
      workspace.tenantId,
    ],
  );

  const onHandleUpdateTransfer: SubmitHandler<TransferInputData> =
    React.useCallback(
      (formValues) => {
        return save(formValues)
          .then((value) => {
            editDialog.close();
            console.log(value);
          })
          .catch((err) => {
            setErrors(err);
          });
      },
      [editDialog, save],
    );

  const onHandleError: SubmitErrorHandler<TransferInputData> =
    React.useCallback((errors) => {
      console.log(JSON.stringify(errors));
    }, []);

  const fieldConfig = getAllTransferFieldConfig(transfer.holding);

  const editElement = (
    <DialogComponent
      dialogProps={{ open: editDialog.isShowing, onClose: editDialog.close }}
    >
      <DialogContentComponent
        title="Edit transfer"
        handleOnSave={() => {
          console.log('save transfer');
          handleSubmit(onHandleUpdateTransfer, onHandleError)();
          return Promise.resolve();
        }}
      >
        <TransferFormComponent
          workspace={workspace}
          projectId={projectId}
          holdingId={transfer.holding.id}
          control={control}
          setValue={setValue}
          fieldConfig={fieldConfig}
          getName={getName}
          transferTypes={
            transfer.holding.type
              ? getTransferTypes(transfer.holding.type as HoldingType)
              : []
          }
          // transferTypes={transferTypes}
        />
        {errorElements}
      </DialogContentComponent>
    </DialogComponent>
  );

  const transferMenu = (
    <>
      <Menu
        anchorEl={menu.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={menu.isOpen}
        onClose={menu.handleClose}
      >
        <MenuItem onClick={() => handleOnClick()}>View</MenuItem>
        <MenuItem onClick={() => editDialog.open()}>Edit</MenuItem>
      </Menu>
      {editElement}
    </>
  );

  const transaction = useSelector((state: RootState) => {
    return getTransactionById(selectWorkspaceData(state), {
      id: transfer.transfer.transactionId,
      projectId,
    });
  });

  const transactionElement = React.useMemo(() => {
    if (transaction) {
      return (
        <>
          <Typography variant="caption" color="textSecondary">
            Transaction: {transaction.id}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {transaction.title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {transaction.description}
          </Typography>
        </>
      );
    }
  }, [transaction]);

  const commitmentElement = React.useMemo(() => {
    if (commitment) {
      return (
        <>
          <Typography variant="caption" color="textSecondary">
            Commitment: {commitment.id}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {moment(commitment.startDate).format('DD.MM.YYYY')}
            {moment(commitment.endDate).format('DD.MM.YYYY')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {commitment.description}
          </Typography>
        </>
      );
    }
  }, [commitment]);

  const detailedElement = React.useMemo(() => {
    if (transfer.holding.type === HoldingType.Stock) {
      const stockTransferTyped = transfer.transferTyped as StockTransferTyped;
      if (!stockTransferTyped) {
        return <></>;
      }
      return (
        <div className={classes.detailed}>
          <Typography variant="body2" color="textSecondary">
            Shares: {stockTransferTyped.trade.shares}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Share price: {stockTransferTyped.trade.price}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Position: {stockTransferTyped.position.shares}
          </Typography>
        </div>
      );
    }
  }, [transfer.holding.type, transfer.transferTyped]);

  return (
    <StyledPaper className={classes.paper} variant="outlined">
      <Grid container>
        <Grid container item xs={8} justifyContent="space-between">
          <Grid item className={classes.menuButton} alignSelf="center">
            <IconButton onClick={menu.handleOpen} color="inherit">
              <MoreVertIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary" component="p">
              {transfer.transfer.id}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.description}
            >
              {transfer.transfer.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" color="textSecondary" component="p">
              {moment(transfer.transfer.date).format('DD.MM.YYYY')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {transfer.transfer.type}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {transfer.transfer.value}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {detailedElement}
          </Grid>
        </Grid>

        <Grid container item xs={4}>
          <Grid item xs={12}>
            {transactionElement}
            {commitmentElement}
          </Grid>
        </Grid>
      </Grid>
      {transferMenu}
    </StyledPaper>
  );
});
