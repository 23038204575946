import { Maybe } from 'graphql/jsutils/Maybe';
import { upsertEntities } from '~src/data/store/modules/entities/entities/requests';
import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { ImportHandler } from '~src/services/xlsx/handlers/iinput-handler';
import {
  ExportHandler,
  outputPicker,
} from '~src/services/xlsx/handlers/ioutput-handler';
import { AppDispatch } from '~src/store/store';

const name = 'entities';

export interface Entity {
  id: string;
  name?: Maybe<string>;
  description?: Maybe<string>;
  sector?: Maybe<string>;
  website?: Maybe<string>;
}

const header: Array<keyof Entity> = [
  'id',
  'name',
  'description',
  'sector',
  'website',
];

export const entityExportHandler = (data: Entity[]): ExportHandler<Entity> => {
  return {
    type: name,
    data: outputPicker(data, header),
    header,
  };
};

export const entityImportHandler = (
  workspace: WorkspaceState,
  dispatch: AppDispatch,
): ImportHandler<Entity> => {
  return {
    type: name,
    header,
    handler: (ts) =>
      dispatch(upsertEntities(workspace.tenantId, workspace.id, ts)),
  };
};
