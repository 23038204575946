import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '~src/data/store/reducers/reducers';
import { getEntityById } from '~src/data/store/selectors/entities/entities/selectors';
import { selectWorkspaceData } from '~src/data/store/selectors/selectors';
import {
  workspaceEntitiesRootPath,
  workspaceEntityRootPath,
} from '~src/navigation/paths/workspace/entity/paths';
import { Breadcrumbs, Typography } from '@mui/material';
import { useWorkspaceBreadcrumbs } from './workspace-breadcrumbs';
import { LinkRouter, maxItems } from './breadcrumbs.component';

function useEntitiesBreadcrumbs(last: boolean) {
  const { tenantId, workspaceId } = useParams<{
    tenantId: string;
    workspaceId: string;
  }>();
  const workspaceBreadcrumb = useWorkspaceBreadcrumbs(false);

  if (tenantId != null && workspaceId != null) {
    const projectElement = last ? (
      <Typography
        color="inherit"
        variant="body2"
        key={workspaceEntitiesRootPath(tenantId, workspaceId)}
      >
        entities
      </Typography>
    ) : (
      <LinkRouter
        color="inherit"
        variant="body2"
        key={workspaceEntitiesRootPath(tenantId, workspaceId)}
        to={workspaceEntitiesRootPath(tenantId, workspaceId)}
      >
        entities
      </LinkRouter>
    );
    return [workspaceBreadcrumb, projectElement];
  }
  return [];
}
function useEntityBreadcrumb() {
  const { tenantId, workspaceId, entityId } = useParams<{
    tenantId: string;
    workspaceId: string;
    projectId: string;
    entityId: string;
  }>();

  const entitesBreadcrumbs = useEntitiesBreadcrumbs(false);
  const entity = useSelector((state: RootState) =>
    getEntityById(selectWorkspaceData(state), { id: entityId, workspaceId }),
  );
  if (entity && tenantId != null && workspaceId != null) {
    return [
      entitesBreadcrumbs,
      <Typography
        color="inherit"
        key={workspaceEntityRootPath(tenantId, workspaceId, entity.id)}
      >
        {entity.name}
      </Typography>,
    ];
  }
  return [];
}
export function EntityBreadcrumbComponent() {
  const entityBreadcrumb = useEntityBreadcrumb();

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {entityBreadcrumb}
    </Breadcrumbs>
  );
}
export function EntitiesBreadcrumbComponent(props: { last: boolean }) {
  const entitiesBreadcrumbs = useEntitiesBreadcrumbs(props.last);

  return (
    <Breadcrumbs
      maxItems={maxItems}
      color="inherit"
      separator="›"
      aria-label="breadcrumb"
    >
      {entitiesBreadcrumbs}
    </Breadcrumbs>
  );
}
