import { WorkspaceState } from '~src/data/store/reducers/workspace/workspaces/reducer';
import { handleInputData } from '~src/services/xlsx/handlers/iinput-handler';
import { assetImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/assets/mapper';
import { bankNoteImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/bank-notes/mapper';
import { bondImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/bonds/mapper';
import { cryptoImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/cryptos/mapper';
import { fundImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/funds/mapper';
import { loanImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/loans/mapper';
import { privateEquityFundImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/private-equity-funds/mapper';
import { stockImportHandler } from '~src/services/xlsx/handlers/workspaces/assets/stocks/mapper';
import { entityImportHandler } from '~src/services/xlsx/handlers/workspaces/entities/entities/mapper';
import { regionAllocationImportHandler } from '~src/services/xlsx/handlers/workspaces/entities/region-allocations/mapper';
import { importer as personImporter } from '~src/services/xlsx/handlers/workspaces/people/importer';
import { portfolioAllocationImportHandler } from '~src/services/xlsx/handlers/workspaces/portfolios/allocations/mapper';
import { portfolioImportHandler } from '~src/services/xlsx/handlers/workspaces/portfolios/portfolios/mapper';
import { importer as projectImporter } from '~src/services/xlsx/handlers/workspaces/projects/importer';
import { DataImporter } from '~src/services/xlsx/hooks/use-import';
import { StatusUpdater } from '~src/services/xlsx/hooks/use-update-status';
import { AppDispatch } from '~src/store/store';

export const importer: DataImporter = (
  workspace: WorkspaceState,
  data: Map<string, any[]>,
  statusUpdater: StatusUpdater,
) => {
  return (dispatch: AppDispatch) => {
    const workspacePromises: Promise<boolean>[] = [];
    workspacePromises.push(
      handleInputData(
        data,
        entityImportHandler(workspace, dispatch),
        statusUpdater,
      ).then(() => {
        const entityPromises: Promise<boolean>[] = [];
        entityPromises.push(
          handleInputData(
            data,
            assetImportHandler(workspace, dispatch),
            statusUpdater,
          ).then(() => true),
        );
        entityPromises.push(
          handleInputData(
            data,
            regionAllocationImportHandler(workspace, dispatch),
            statusUpdater,
          ).then(() => true),
        );

        return Promise.all(entityPromises).then(() => {
          const assetPromises: Promise<boolean>[] = [];
          assetPromises.push(
            handleInputData(
              data,
              stockImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          assetPromises.push(
            handleInputData(
              data,
              bankNoteImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          assetPromises.push(
            handleInputData(
              data,
              cryptoImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          assetPromises.push(
            handleInputData(
              data,
              bondImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          assetPromises.push(
            handleInputData(
              data,
              loanImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          assetPromises.push(
            handleInputData(
              data,
              fundImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          assetPromises.push(
            handleInputData(
              data,
              privateEquityFundImportHandler(workspace, dispatch),
              statusUpdater,
            ).then(() => true),
          );
          return Promise.all(assetPromises).then(() => true);
        });
      }),
    );
    workspacePromises.push(
      handleInputData(
        data,
        portfolioImportHandler(workspace, dispatch),
        statusUpdater,
      ).then(() => {
        return handleInputData(
          data,
          portfolioAllocationImportHandler(workspace, dispatch),
          statusUpdater,
        ).then(() => true);
      }),
    );
    return Promise.all(workspacePromises).then(() => {
      return dispatch(projectImporter(workspace, data, statusUpdater)).then(
        () => {
          return dispatch(personImporter(workspace, data, statusUpdater));
        },
      );
    });
  };
};
