import moment from 'moment';
import createCachedSelector from 're-reselect';
import { CashOnCash } from '~src/utils/finance/cash-on-cash';
import { HoldingState } from '~src/data/store/reducers/holding/holdings/reducer';
import { getTransfersByHoldingId } from '~src/data/store/selectors/holding/transfer/transfers/selectors';
import { DataState } from '~src/data/store/reducers/reducers';
import {
  selectFinancialDataFromData,
  selectWorkspaceDataFromData,
} from '~src/data/store/selectors/selectors';

import { Period } from '../../../../../utils/period/period';
import { getHoldingTransferCashOnCash } from './holding-transfer-cash-on-cash';
import { getHoldingValue } from '../valuation/holding-value';
import { CurrencyState } from '~src/data/store/reducers/finance/currency/currencies/reducer';
import { getExchangeRateState } from '../exchange-rates/selectors';
import { createSelector } from 'reselect';

interface Props {
  workspaceId: string;
  holding: HoldingState;
  currency: CurrencyState;
  date: moment.Moment;
  period: Period;
  useLiveValue: boolean;
}

export const getHoldingCashOnCash = createCachedSelector(
  (state: DataState, props: Props) =>
    getHoldingTransferCashOnCash(state, props),
  (state: DataState, props: Props) =>
    getHoldingValue(state, {
      workspaceId: props.workspaceId,
      holding: props.holding,
      date: props.period.start,
      currency: props.currency,
      useLiveValue: props.useLiveValue,
    }),
  (state: DataState, props: Props) =>
    getHoldingValue(state, {
      workspaceId: props.workspaceId,
      holding: props.holding,
      date: props.period.end,
      currency: props.currency,
      useLiveValue: props.useLiveValue,
    }),
  (state: DataState) =>
    getExchangeRateState(selectFinancialDataFromData(state)),
  (state: DataState, props: Props) =>
    getTransfersByHoldingId(
      selectWorkspaceDataFromData(state),
      props.holding.id,
    ),
  (transferCashOnCash, startValuation, endValuation) => {
    const { inflow, outflow } = transferCashOnCash;
    const startValue = startValuation ? startValuation.value : 0;
    const endValue = endValuation ? endValuation.value : 0;
    const cashOnCash: CashOnCash = {
      startValue,
      endValue,
      inflow,
      outflow,
    };
    return cashOnCash;
    // return endValue - startValue + realized - invested;
  },
)({
  keySelector: (_state: DataState, props: Props) =>
    `${props.holding.id}:${
      props.currency.id
    }:${props.date.unix()}:${props.period.id()}`,
  selectorCreator: createSelector,
});
